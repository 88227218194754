import { GanttCanvasRow, GanttCanvasShift } from '../../../data-handler/data-structure/data-structure';
import { BestGantt } from '../../../main';
import { ETimeGradationRoundingType } from '../../../time-gradation-handler/time-gradation-handler';
import { GanttShiftEditLimiterAdapter } from '../../shift-edit-general/edit-restrictions/shift-edit-limiter-adapter';
import { GanttShiftTranslationAreaLimiter } from './shift-translation-area-limiter';
import { GanttShiftTranslationRowLimiter } from './shift-translation-row-limiter';

/**
 * Class which handles sticky restrictions for shift translation.
 * @keywords translation, translator, restriction
 */
export class GanttShiftTranslationLimiter extends GanttShiftEditLimiterAdapter {
  private _shiftTranslationAreaLimiter = new GanttShiftTranslationAreaLimiter();
  private _shiftTranslationRowLimiter = new GanttShiftTranslationRowLimiter();

  private _allowDragHorizontal = true;
  private _allowDragVertical = true;

  private _noDragIds: Map<string, Set<string>> = new Map<string, Set<string>>();

  private _stickyStartsHorizontal: { [id: string]: boolean } = {};
  private _stickyEndsHorizontal: { [id: string]: boolean } = {};

  constructor(ganttDiagram: BestGantt) {
    super(ganttDiagram);
  }

  //
  // GENERAL RESTRICTIONS
  //

  /**
   * Flag indicating if horizontal shift translation is currently allowed or not.
   */
  public get allowDragHorizontal(): boolean {
    return this._allowDragHorizontal;
  }

  /**
   * Flag indicating if horizontal shift translation is currently allowed or not.
   */
  public set allowDragHorizontal(value: boolean) {
    this._allowDragHorizontal = value;
  }

  /**
   * Flag indicating if vertical shift translation is currently allowed or not.
   */
  public get allowDragVertical(): boolean {
    return this._allowDragVertical;
  }

  /**
   * Flag indicating if vertical shift translation is currently allowed or not.
   */
  public set allowDragVertical(value: boolean) {
    this._allowDragVertical = value;
  }

  //
  // NO DRAG IDS
  //

  /**
   * Get drag restriction for shift by shift id.
   * @keywords shift, id, drag, translate, restriction, vertical, horizontal, allowed
   * @param shiftId Id of shift.
   * @return If true, shift dragging is allowed.
   */
  public hasNoDragIdByShiftId(shiftId: string): boolean {
    return !!this._noDragIds.get(shiftId)?.size;
  }

  /**
   * Add drag restriction for shift.
   * @keywords shift, id, no, drag, restriction
   * @param shiftId Id of shift.
   * @param noDragId Id of restriction.
   */
  public addNoDragIdByShiftId(shiftId: string, noDragId: string): void {
    if (!this._noDragIds.get(shiftId)) this._noDragIds.set(shiftId, new Set<string>());
    this._noDragIds.get(shiftId).add(noDragId);
  }

  /**
   * Delete drag restriction by id.
   * @keywords id, drag, translate, restriction, vertical, horizontal, delete, remove
   * @param noDragId Id of restriction.
   */
  public removeDragLimitById(noDragId: string): void {
    for (const noDragIds of this._noDragIds.values()) noDragIds.delete(noDragId);
  }

  /**
   * Delete drag restriction by shift id.
   * @keywords shift, id, drag, translate, restriction, vertical, horizontal, delete, remove
   * @param shiftId Id of shift.
   */
  public removeDragLimitByShiftId(shiftId: string): void {
    this._noDragIds.delete(shiftId);
  }

  //
  // STICKY RESTRICTIONS
  //

  /**
   * Checks if shift has any sticky restrictions.
   * @param shiftId Id of shift.
   * @return True if the specified shift has no sticky restrictions, false if it has.
   */
  public shiftHasNoStickyRestrictions(shiftId: string): boolean {
    return !(this.shiftHasStickyStart(shiftId) || this.shiftHasStickyEnd(shiftId));
  }

  /**
   * Checks if shift has sticky start.
   * @param shiftId Id of shift.
   * @return True if the specified shift has a sticky start, false if not.
   */
  public shiftHasStickyStart(shiftId: string): boolean {
    return !!this._stickyStartsHorizontal[shiftId];
  }

  /**
   * Checks if shift has sticky end.
   * @param shiftId Id of shift.
   * @return True if the specified shift has a sticky end, false if not.
   */
  public shiftHasStickyEnd(shiftId: string): boolean {
    return !!this._stickyEndsHorizontal[shiftId];
  }

  /**
   * Sets or removes restriciton for shift.
   * @param dataSet Restriction dataset.
   * @param shiftId Id of shift.
   * @param activateSticky Activate sticky restriction or removes it.
   */
  private _setRestricitons(dataSet: { [id: string]: boolean }, shiftId: string, activateSticky: boolean): void {
    if (activateSticky) dataSet[shiftId] = true;
    else if (dataSet[shiftId]) delete dataSet[shiftId];
  }

  /**
   * Sets or removes sticky start for shift.
   * @param shiftId Id of shift.
   * @param activateSticky Activate sticky restriction or removes it.
   */
  public setStickyStart(shiftId: string, activateSticky: boolean): void {
    this._setRestricitons(this._stickyStartsHorizontal, shiftId, activateSticky);
  }

  /**
   * Sets or removes sticky end for shift.
   * @param shiftId Id of shift.
   * @param activateSticky Activate sticky restriction or removes it.
   */
  public setStickyEnd(shiftId: string, activateSticky: boolean): void {
    this._setRestricitons(this._stickyEndsHorizontal, shiftId, activateSticky);
  }

  //
  // SHIFT DRAG BLOCKING
  //

  /**
   * Calculates if any of the moving shifts is blocked from dragging.
   * @param shiftsSelection
   * @param targetRow
   * @return True if shift was blocked, else false.
   */
  public isAnyMovingShiftBlocked(
    shiftsSelection: d3.Selection<SVGRectElement, GanttCanvasShift, d3.BaseType, undefined>[],
    targetRow: GanttCanvasRow
  ): boolean {
    let shiftBlocked = false;
    for (const shiftSelection of shiftsSelection) {
      // check if there is a blocked time interval (x-direction)
      shiftBlocked = this.isShiftBlocked(shiftSelection, targetRow);
      if (shiftBlocked) break;
    }
    return shiftBlocked;
  }

  /**
   * Checks on various limiter types if drag on the shift was allowed ot not.
   * @param shiftSelection
   * @param targetRow
   * @return False if drag was allowed, else true.
   */
  public isShiftBlocked(
    shiftSelection: d3.Selection<SVGRectElement, GanttCanvasShift, d3.BaseType, undefined>,
    targetRow: GanttCanvasRow
  ): boolean {
    const xAxisBuilder = this._ganttDiagram.getXAxisBuilder();
    const currentScale = xAxisBuilder.getCurrentScale();
    const shiftData = shiftSelection.data()[0];

    const roundedY = targetRow.y + this._ganttDiagram.getConfig().getLineTop();
    const finalShiftCoordinate = [parseFloat(shiftSelection.attr('x')), roundedY];
    const newStartDate = xAxisBuilder.pxToTime(finalShiftCoordinate[0], currentScale);
    const newEndDate = xAxisBuilder.pxToTime(
      finalShiftCoordinate[0] + parseFloat(shiftSelection.attr('width')),
      currentScale
    );

    // check if the drag target row is allowed
    if (targetRow) {
      return !(
        this._shiftTranslationAreaLimiter.checkAreaRestriction(shiftData.id, targetRow.id, newStartDate, newEndDate)
          .allowDrop &&
        this._shiftTranslationRowLimiter.isShiftDropAllowed(
          shiftData.entryTypes,
          this._ganttDiagram.getDataHandler().getYAxisDataset(),
          targetRow.id
        )
      );
    }
  }

  //
  // TIME GRADATION
  //

  /**
   * Manipulates the specified x coordinate to align to a time grid.
   * @param x X coordinate to manipulate.
   * @param roundingType Type of rounding which should be used to determine the grid step to which the x coordinate is assigned.
   */
  public getGradiatedXCoordinate(
    x: number,
    roundingType: ETimeGradationRoundingType = ETimeGradationRoundingType.HALF
  ): number {
    const xAxisBuilder = this._ganttDiagram.getXAxisBuilder();
    const currentScale = xAxisBuilder.getCurrentScale();

    return currentScale(
      this.timeGradationHandler.getAliginDateByDate(xAxisBuilder.pxToTime(x, currentScale), null, roundingType)
    );
  }

  //
  // GETTER & SETTER
  //

  /**
   * Returns the translation area limiter of this translation limiter.
   */
  public get translationAreaLimiter(): GanttShiftTranslationAreaLimiter {
    return this._shiftTranslationAreaLimiter;
  }

  /**
   * Returns the translation row limiter of this translation limiter.
   */
  public get translationRowLimiter(): GanttShiftTranslationRowLimiter {
    return this._shiftTranslationRowLimiter;
  }
}
