import { AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { UiService } from '@app-modeleditor/ui.service';
import { GlobalUtils } from 'frontend/src/dashboard/global-utils';
import { Observable, interval, of } from 'rxjs';
import { catchError, switchMap, take, takeWhile } from 'rxjs/operators';
import { Action } from '../button/action/action';
import { ButtonService } from '../button/button.service';
import { ProgressBar, ProgressBarValue } from './progressbar';

@Component({
  selector: 'app-template-progressbar',
  templateUrl: './template-progressbar.component.html',
  styleUrls: ['./template-progressbar.component.scss'],
})
export class TemplateProgressbarComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @Input() template: ProgressBar;
  protected uuidString = GlobalUtils.generateUUID();
  protected progressBarColor = '#1565c0';
  protected isIntervalRunning = true;
  protected progressValue = 0.01;
  private lastValue = 0;
  private executedActionKeys: number[] = [];

  constructor(private uiService: UiService, private buttonService: ButtonService) {}

  ngOnInit(): void {
    this.lastValue = this.template.getValue().value;
    if (this.template.isUpdateAutomatically() && this.template.getValue().value < 1) {
      this.startRefreshInterval();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.template) {
      this.changeColor();

      // update progress value if update element notification manipulates the template
      this.progressValue = Math.max(this.progressValue, this.template.getValue().value);
    }
  }

  ngAfterViewInit(): void {
    this.changeColor();
  }

  ngOnDestroy(): void {
    this.isIntervalRunning = false;
  }

  private changeColor() {
    this.progressBarColor = this.template.getValue().color ? this.template.getValue().color : this.progressBarColor;
  }

  private startRefreshInterval() {
    interval(this.template.getUpdateDuration())
      .pipe(
        takeWhile(() => this.isIntervalRunning),
        switchMap(() => this.uiService.getData(this.template.getRestUrl()) as Observable<ProgressBarValue>),
        catchError((error) => {
          this.isIntervalRunning = false;
          return of(null);
        })
      )
      .subscribe((result) => {
        if (result) {
          this.template.setValue(result);
          this.executeActions(this.lastValue, result.value);
          this.progressValue = Math.max(this.progressValue, result.value);
          if (result.value >= 1) {
            this.isIntervalRunning = false;
          }
        }
      });
  }

  private executeActions(lastValue: number, newValue: number) {
    const keys = Object.keys(this.template.getValueTriggeredActions()).map((key) => parseFloat(key));
    const foundKeys = keys.filter((key) => key > lastValue && key <= newValue);
    let actionsToExecute: Action[] = [];

    foundKeys.forEach((key) => {
      if (!this.executedActionKeys.includes(key)) {
        actionsToExecute = actionsToExecute.concat(this.template.getValueTriggeredActions()[key]);
        this.executedActionKeys.push(key);
      }
    });

    this.buttonService.executeActions(actionsToExecute).pipe(take(1)).subscribe();
  }
}
