import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { PatternHandler } from '@gantt/public-api';
import { ILegendEntry } from '../legend.interface';

/**
 * Represents a single entry in the legend.
 * It contains an indicator and a label.
 */
@Component({
  selector: 'app-legend-entry',
  templateUrl: './legend-entry.component.html',
  styleUrls: ['./legend-entry.component.scss'],
})
export class LegendEntryComponent implements AfterViewInit {
  @ViewChild('indicator') indicator: ElementRef;

  @Input() entryData: ILegendEntry = null;
  @Input() pointerInteraction: boolean;

  @Output() onDblClick = new EventEmitter<ILegendEntry>();
  @Output() onClick = new EventEmitter<ILegendEntry>();

  private timerId: NodeJS.Timeout;
  private clickCnt = 0;

  constructor() {}

  ngAfterViewInit(): void {
    this.loadPattern();
  }

  /**
   * Handles click interactions with the entry.
   * Click handling to check if this was an single or double click.
   * Called by click on this entry.
   */
  public click(): void {
    clearTimeout(this.timerId);

    this.clickCnt++;

    this.timerId = setTimeout(() => {
      if (this.clickCnt > 1) {
        // emit as double click event
        this.onDblClick.emit(this.entryData);
      } else {
        // emit as single click event
        this.onClick.emit(this.entryData);
      }
      this.clickCnt = 0;
    }, 300);
  }

  /**
   * Loads the pattern by JS PatternHandler.
   */
  private loadPattern(): void {
    const patternHandler = new PatternHandler();

    // set fill pattern
    if (this.entryData.pattern) {
      patternHandler
        .getPatternAsCssUrl(this.entryData.pattern, this.entryData.color, this.entryData.patternColor)
        .then((url) => {
          this.indicator.nativeElement.style.backgroundImage = url;
          this.indicator.nativeElement.style.backgroundRepeat = 'repeat';
        });
    }

    // set stroke pattern
    if (this.entryData.strokePattern && !this.entryData.pattern) {
      // dasharray not supported in div -> use background image
      const strokePattern = patternHandler.getStrokePatternStorage().getPatternById(this.entryData.strokePattern);
      if (strokePattern) {
        const url = `url("data:image/svg+xml,%3csvg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%' height='100%' fill='none' stroke='${this.entryData.strokeColor.replace(
          '#',
          '%23'
        )}' stroke-width='3' stroke-dasharray='${strokePattern.getFilledArea()} ${strokePattern.getUnfilledArea()}'/%3e%3c/svg%3e")`;
        this.indicator.nativeElement.style.backgroundImage = url;
      }
    }
  }

  public getLegendEntryClassname(): string {
    return this.entryData.className ? `indicator ${this.entryData.className}` : `indicator`;
  }
}
