import { Observable, Subject, takeUntil } from 'rxjs';
import { GanttConfig } from '../../config/gantt-config';
import { GanttCanvasRow, GanttCanvasShift } from '../../data-handler/data-structure/data-structure';
import { EGanttScrollContainer } from '../../html-structure/scroll-container.enum';
import { BestGantt } from '../../main';
import { ShiftBuilder } from '../../shifts/shift-builder';
import { IShiftDragEvent } from '../../shifts/shift-events.interface';
import { BestGanttPlugIn } from '../gantt-plug-in';

/**
 * Renders color rect on row shift / blocking interval will be dragged to.
 */
export class GanttShiftDragVisualizer extends BestGanttPlugIn {
  private _yAxisElements: d3.Selection<HTMLDivElement, unknown, d3.BaseType, undefined>[] = [];
  private _shiftCanvas: { [id: string]: d3.Selection<SVGGElement, undefined, d3.BaseType, undefined> } = {};
  private _usedByBlockingInterval = false;

  private _onDestroySubject: Subject<void> = new Subject<void>();

  constructor(private _shiftBuilder: { [id: string]: ShiftBuilder }, private _config: GanttConfig) {
    super(); // call super-constructor
  }

  public initPlugIn(ganttDiagram: BestGantt): void {
    this.ganttDiagram = ganttDiagram;
    if (!this._config) {
      this._config = this.ganttDiagram.getConfig();
    }

    this._initCanvas();
    this._initCallbacks();
  }

  public removePlugIn(): void {
    this._onDestroySubject.next();
    this._onDestroySubject.complete();

    this._clearCanvas();
    this._removeCanvas();
  }

  private _initCallbacks(): void {
    for (const scrollContainerId in this._shiftBuilder) {
      this._shiftBuilder[scrollContainerId]
        .shiftDragging()
        .pipe(takeUntil(this.onDestroy))
        .subscribe((event) => this._previewShiftDragTarget(event));
      this._shiftBuilder[scrollContainerId]
        .shiftDragEnd()
        .pipe(takeUntil(this.onDestroy))
        .subscribe(() => this._dragEnd());
    }
  }

  private _initCanvas(): void {
    for (const scrollContainerId in this._shiftBuilder) {
      this._shiftCanvas[scrollContainerId] = this.ganttDiagram
        .getShiftFacade()
        .getShiftBuilder(scrollContainerId as EGanttScrollContainer)
        .getCanvasBehindShifts()
        .append('g')
        .attr('class', 'preview-shift-drag-target ' + this.UUID);
      const insertBeforeNode = this.ganttDiagram
        .getShiftFacade()
        .getCanvasBehindShifts(scrollContainerId as EGanttScrollContainer)
        .node().firstChild.nextSibling;
      this.ganttDiagram
        .getShiftFacade()
        .getShiftBuilder(scrollContainerId as EGanttScrollContainer)
        .getCanvasBehindShifts()
        .node()
        .insertBefore(this._shiftCanvas[scrollContainerId].node(), insertBeforeNode);
    }
  }

  private _removeCanvas(): void {
    for (const scrollContainerId in this._shiftBuilder) {
      this._shiftCanvas[scrollContainerId].remove();
      while (this._yAxisElements.length > 0) {
        this._yAxisElements.pop().remove();
      }
    }
  }

  private _previewShiftDragTarget(eventWrapper: IShiftDragEvent): void {
    this._clearCanvas();

    if (
      !this.ganttDiagram.getShiftTranslator().shiftEditLimiterAdapter.allowDragVertical &&
      !this.ganttDiagram.getShiftTranslator().shiftEditLimiterAdapter.allowDragHorizontal
    ) {
      if (!this._usedByBlockingInterval) {
        // if used by blocking interval render regardless
        return;
      }
    }

    let foundRow: GanttCanvasRow = undefined;
    if (this.ganttDiagram.getShiftTranslator().shiftEditLimiterAdapter.allowDragVertical) {
      const mouseEvent = eventWrapper.event.sourceEvent;
      const shiftCanvasProportions = this.ganttDiagram
        .getHTMLStructureBuilder()
        .getVerticalScrollContainerWrapper()
        .node()
        .getBoundingClientRect();
      const viewportY = mouseEvent.pageY - shiftCanvasProportions.y;
      foundRow = this.ganttDiagram.getRenderDataHandler().getYAxisDataFinder().getRowByViewportY(viewportY);
    } else {
      foundRow = this.ganttDiagram
        .getDataHandler()
        .getYAxisDataset()
        .find((e) => this.ganttDiagram.getShiftTranslator().shiftEditState.dragStartRow.id === e.id);
    }

    if (!foundRow) {
      return;
    }

    // mark drag START
    if (eventWrapper?.event?.subject) {
      const subject = eventWrapper.event.subject as GanttCanvasShift;
      const subjectY = this.ganttDiagram.getRenderDataHandler().getStateStorage().getYPositionShift(subject.id);
      if (!isNaN(subjectY)) {
        this._renderRectForShiftArea(
          subjectY - this._config.getLineTop(),
          subject.height + this._config.getLineTop() + this._config.getLineBottom(),
          0.2,
          this.ganttDiagram.getRenderDataHandler().getStateStorage().getShiftScrollContainer(subject.id)
        );
        this._renderRectForYAxis(
          subjectY - this._config.getLineTop(),
          0.2,
          this.ganttDiagram.getRenderDataHandler().getStateStorage().getShiftScrollContainer(subject.id)
        );
      }
    }

    // mark drag CURRENTLY
    const foundRowY = this.ganttDiagram.getRenderDataHandler().getStateStorage().getYPositionRow(foundRow.id);
    this._renderRectForShiftArea(
      foundRowY,
      foundRow.height,
      0.4,
      this.ganttDiagram.getRenderDataHandler().getStateStorage().getRowScrollContainer(foundRow.id)
    );
    this._renderRectForYAxis(
      foundRowY,
      0.4,
      this.ganttDiagram.getRenderDataHandler().getStateStorage().getRowScrollContainer(foundRow.id)
    );
  }

  private _renderRectForYAxis(y: number, opacity: number, scrollContainerId: EGanttScrollContainer): void {
    const yAxisRect = this.ganttDiagram
      .getHTMLStructureBuilder()
      .getYAxisContainer(scrollContainerId)
      .select('div')
      .selectAll<HTMLDivElement, GanttCanvasRow>('.rowElement')
      .filter((d) => this.ganttDiagram.getRenderDataHandler().getStateStorage().getYPositionRow(d.id) === y)
      .insert('div', 'table')
      .style('height', '100%')
      .style('width', '100%')
      .style('background-color', '#7777AA')
      .style('position', 'absolute')
      .style('opacity', opacity);

    this._yAxisElements.push(yAxisRect);
  }

  private _renderRectForShiftArea(
    y: number,
    rowHeight: number,
    opacity: number,
    scrollContainerId: EGanttScrollContainer
  ): void {
    this._shiftCanvas[scrollContainerId]
      .append('rect')
      .attr('x', 0)
      .attr('y', y)
      .attr('height', rowHeight)
      .attr('width', '100%')
      .attr('fill', '#7777AA')
      .style('opacity', opacity);
  }

  private _clearCanvas(): void {
    for (const scrollContainerId in this._shiftBuilder) {
      this._shiftCanvas[scrollContainerId].selectAll('rect').remove();
      while (this._yAxisElements.length > 0) {
        this._yAxisElements.pop().remove();
      }
    }
  }

  private _dragEnd(): void {
    this._clearCanvas();
  }

  public setUsedByBlockingInterval(boolean: boolean): void {
    this._usedByBlockingInterval = boolean;
  }

  //
  // OBSERVABLES
  //

  /**
   * Observable which gets triggered when the instance gets destroyed.
   */
  private get onDestroy(): Observable<void> {
    return this._onDestroySubject.asObservable();
  }
}
