import * as d3 from 'd3';
import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { GanttDataRow, GanttDataShift } from '../../../data-handler/data-structure/data-structure';
import { DefaultOverlayContainer } from '../../../../../services/overlay-service/default-overlay-container';
import { BestGantt } from '../../../main';
import { CONTAINER_DATA } from '../../../../../services/overlay-service/container-data';
import {
  MatLegacyTooltipDefaultOptions as MatTooltipDefaultOptions,
  MAT_LEGACY_TOOLTIP_DEFAULT_OPTIONS as MAT_TOOLTIP_DEFAULT_OPTIONS,
} from '@angular/material/legacy-tooltip';

export const brokenConstraintsNavigatorTooltipSettings: MatTooltipDefaultOptions = {
  showDelay: 1000,
  hideDelay: 100,
  touchendHideDelay: 100,
};

@Component({
  selector: 'lib-gantt-plugin-navigator-overlay',
  templateUrl: './navigator-overlay.component.html',
  styleUrls: ['./navigator-overlay.component.scss'],
  providers: [{ provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: brokenConstraintsNavigatorTooltipSettings }],
})
export class GanttBrokenConstraintsNavigatorComponent
  extends DefaultOverlayContainer<any>
  implements OnInit, OnDestroy, AfterViewInit
{
  protected readonly allBrokenConstraints = {
    value: 'ALL_BROKEN_CONSTRAINTS',
    label: 'Alle Regelbrüche',
  };
  protected readonly noShifts = {
    value: 'NO_SHIFTS',
    label: '---',
  };
  protected _onNavigationButtonClick = new Subject<GanttDataShift>();
  private _afterViewInit = new Subject<void>();

  protected _showLeftRightButtons = false;
  protected _showNavigationButton = true;
  protected _showShiftCounter = false;
  protected _showTitle = false;
  protected _titleAsDragHandle = false;
  protected _automaticNavigation = false;

  protected _availableBrokenConstraints: string[];
  protected _availableShifts: Map<string, GanttDataShift[]>;
  protected _availableRows: GanttDataRow[];
  protected _selectedBrokenConstraint: string = this.allBrokenConstraints.value;
  protected _selectedShift: GanttDataShift | string = null;
  private _shiftDateTimeFormat: string;
  protected _dragPosition = { x: 0, y: 0 };
  protected _dragBoundary: HTMLElement;

  constructor(@Inject(CONTAINER_DATA) protected _ganttRef: BestGantt, private _elementRef: ElementRef) {
    super();
  }

  ngOnInit(): void {
    this._shiftDateTimeFormat = '%d.%m.%Y %H:%M:%S';
    this._dragBoundary = null;
  }

  ngAfterViewInit(): void {
    this._afterViewInit.next();
  }

  ngOnDestroy(): void {}

  /**
   * Reload selectable data from available rows.
   */
  public refreshData(): void {
    const oldAvailableShifts = this._availableShifts;
    this._availableBrokenConstraints = [];
    this._availableShifts = new Map();

    // build new broken constraints list
    for (const row of this._availableRows) {
      // iterate over rows
      for (const shift of row.shifts) {
        // iterate over shifts
        for (const id in shift.additionalData.additionalData.brokenConstraints) {
          // iterate over broken constraints
          // check if broken constraint is unknown
          const brokenConstraint = shift.additionalData.additionalData.brokenConstraints[id];
          let brokenConstraintName: string;
          if (brokenConstraint.t3) {
            brokenConstraintName = brokenConstraint.t3;
            if (!this._availableBrokenConstraints.find((element) => element === brokenConstraintName)) {
              const t2index = this._availableBrokenConstraints.indexOf(brokenConstraint.t2);
              if (t2index >= 0) {
                this._availableBrokenConstraints[t2index] = brokenConstraintName;
              } else {
                this._availableBrokenConstraints.push(brokenConstraintName);
              }
            }
          } else {
            brokenConstraintName = brokenConstraint.t2;
            if (!this._availableBrokenConstraints.find((element) => element === brokenConstraintName)) {
              this._availableBrokenConstraints.push(brokenConstraintName);
            }
          }
          // map shift to all broken constraints list
          if (!this._availableShifts.get(this.allBrokenConstraints.value)) {
            this._availableShifts.set(this.allBrokenConstraints.value, [shift]);
          } else if (
            !this._availableShifts.get(this.allBrokenConstraints.value).find((element) => element.id === shift.id)
          ) {
            this._availableShifts.get(this.allBrokenConstraints.value).push(shift);
          }
          // map shift to specific broken constraint list
          if (!this._availableShifts.get(brokenConstraintName)) {
            this._availableShifts.set(brokenConstraintName, [shift]);
          } else if (!this._availableShifts.get(brokenConstraintName).find((element) => element.id === shift.id)) {
            this._availableShifts.get(brokenConstraintName).push(shift);
          }
        }
      }
    }

    // sort shifts by their start date (ASC)
    for (const shifts of this._availableShifts.values()) {
      shifts.sort((a, b) => {
        return a.timePointStart.getTime() - b.timePointStart.getTime();
      });
    }

    // update selection if current selection does not exist anymore
    this._validateBrokenConstraintSelection();
    this._validateShiftSelection(oldAvailableShifts);
  }

  /**
   * Close overlay from outside.
   */
  public closeOverlay(): void {
    this._close('close');
  }

  /**
   * Generates a shift label ("Shift (Start - End)") by the given shift data.
   * @param shift Shift data to generate the label for.
   * @returns Shift label.
   */
  protected _generateShiftLabel(shift: GanttDataShift): string {
    const dateTimeFormat = d3.timeFormat(this._shiftDateTimeFormat);
    const shiftName = shift.name;
    const shiftStart = dateTimeFormat(shift.timePointStart);
    const shiftEnd = dateTimeFormat(shift.timePointEnd);
    return `${shiftName} (${shiftStart} - ${shiftEnd})`;
  }

  protected _generateBrokenConstraintTooltipText(brokenConstraint: string): string {
    if (brokenConstraint === this.allBrokenConstraints.value) return this.allBrokenConstraints.label;
    return brokenConstraint;
  }

  protected _generateShiftTooltipText(shift: GanttDataShift | string): string {
    if (typeof shift === 'string') return this.noShifts.label;
    return this._generateShiftLabel(shift);
  }

  protected _generateTitleText(): string {
    let titleText = '';
    const availableRowNames = this._getAvailableRowNames();
    if (availableRowNames.length > 0) {
      titleText = 'Regelbrüche für ';
      for (let i = 0; i < availableRowNames.length; i++) {
        titleText += `"${availableRowNames[i]}"`;
        if (i < availableRowNames.length - 1) titleText += ', ';
      }
    }
    return titleText;
  }

  protected _getNumberOfSelectedShift(): number {
    const availableShifts = this._availableShifts.get(this._selectedBrokenConstraint);
    if (!availableShifts || availableShifts.length <= 0 || typeof this._selectedShift === 'string') return 0;
    return availableShifts.indexOf(this._selectedShift) + 1;
  }

  protected _getTotalAvailableShiftCount(): number {
    const availableShifts = this._availableShifts.get(this._selectedBrokenConstraint);
    if (!availableShifts || availableShifts.length <= 0) return 0;
    return availableShifts.length;
  }

  private _getAvailableRowNames(): string[] {
    const availableRowNames = this._availableRows
      .map((row) => row.name)
      .filter((value, i, self) => {
        return value && self.indexOf(value) === i;
      });
    return availableRowNames;
  }

  /**
   * Checks if the selection element for the broken constraints has a valid value (sets value to default if not).
   */
  protected _validateBrokenConstraintSelection(): void {
    const currentBrokenConstraint = this._selectedBrokenConstraint;
    if (!this._availableBrokenConstraints.find((brokenConstraint) => currentBrokenConstraint === brokenConstraint)) {
      this._selectedBrokenConstraint = this.allBrokenConstraints.value;
    }
  }

  /**
   * Checks if the shift selection element has a valid value (set value to default if not).+
   * @param {Map<string, GanttDataShift[]>} [oldAvailableShiftsMap=null] Reference to old available value map to check if only the selected shift was removed (check will be skipped if null).
   */
  protected _validateShiftSelection(oldAvailableShiftsMap: Map<string, GanttDataShift[]> = null): void {
    const currentShift = this._selectedShift;
    const availableShifts = this._availableShifts.get(this._selectedBrokenConstraint);
    const oldAvailableShifts = oldAvailableShiftsMap ? oldAvailableShiftsMap.get(this._selectedBrokenConstraint) : null;

    // check if only selected shift removed -> select next shift if so
    if (
      oldAvailableShifts &&
      currentShift &&
      !availableShifts?.find((shift) => {
        if (typeof currentShift === 'string') return false;
        return currentShift.id === shift.id;
      })
    ) {
      const indexOfCurrentShift = oldAvailableShifts.indexOf(
        oldAvailableShifts.find((shift) => shift.id === (currentShift as GanttDataShift).id)
      );
      for (let i = indexOfCurrentShift + 1; i < oldAvailableShifts.length; i++) {
        const newSelectedShift = oldAvailableShifts[i];
        if (availableShifts.find((shift) => shift.id === newSelectedShift.id)) {
          this._selectedShift = newSelectedShift;
          this._triggerNavigationEvent(this._selectedShift);
          return; // only return when successful
        }
      }
      // no next shift selectable -> select previous shift
      for (let i = indexOfCurrentShift - 1; i >= 0; i--) {
        const newSelectedShift = oldAvailableShifts[i];
        if (availableShifts.find((shift) => shift.id === newSelectedShift.id)) {
          this._selectedShift = newSelectedShift;
          this._triggerNavigationEvent(this._selectedShift);
          return; // only return when successful
        }
      }
    }
    // every other case
    if (
      !currentShift ||
      !availableShifts?.find((shift) => {
        if (typeof currentShift === 'string') return false;
        return currentShift.id === shift.id;
      })
    ) {
      if (!availableShifts || availableShifts.length <= 0) this._selectedShift = this.noShifts.value;
      else this._selectedShift = availableShifts[0];
      this._triggerNavigationEvent(this._selectedShift);
    }
    // check if selected shift has new reference
    if (typeof this._selectedShift !== 'string' && !availableShifts.find((shift) => shift === this._selectedShift)) {
      this._selectedShift = availableShifts.find((shift) => shift.id === (this._selectedShift as GanttDataShift).id);
    }
  }

  /**
   * Triggers a navigation event and passes the given data (only if the value is of the type GanttDataShift)
   * @param value Value that should be passed to the subscribers.
   */
  protected _triggerNavigationEvent(value: GanttDataShift | string): void {
    if (typeof value === 'string') return;
    this._onNavigationButtonClick.next(value);
  }

  /**
   * Selects the previous shift in the selection list (and triggers navigation event when automatic navigation is activated).
   */
  protected _navigateToPreviousShift(): void {
    if (typeof this._selectedShift === 'string') return; // nothing to navigate to
    const currentShift = this._selectedShift;
    const availableShifts = this._availableShifts.get(this._selectedBrokenConstraint);

    const currentIndex = availableShifts.indexOf(currentShift);
    if (currentIndex < 1) return; // if at start of list -> do nothing
    this._selectedShift = availableShifts[currentIndex - 1];

    if (this._automaticNavigation) {
      this._triggerNavigationEvent(this._selectedShift);
    }
  }

  /**
   * Selects the next shift in the selection list (and triggers navigation event when automatic navigation is activated).
   */
  protected _navigateToNextShift(): void {
    if (typeof this._selectedShift === 'string') return; // nothing to navigate to
    const currentShift = this._selectedShift;
    const availableShifts = this._availableShifts.get(this._selectedBrokenConstraint);

    const currentIndex = availableShifts.indexOf(currentShift);
    if (currentIndex >= availableShifts.length - 1) return; // if at end of list -> do nothing
    this._selectedShift = availableShifts[currentIndex + 1];

    if (this._automaticNavigation) {
      this._triggerNavigationEvent(this._selectedShift);
    }
  }

  //
  // GETTER & SETTER
  //
  public setAvailableRows(rows: GanttDataRow[]): void {
    this._availableRows = rows;
    this.refreshData();
  }

  public setDateTimeFormat(format: string): void {
    this._shiftDateTimeFormat = format;
  }

  public setDragPosition(position: { x: number; y: number }): void {
    this._dragPosition = position;
  }

  public getNavigatorContainerProportions(): { width: number; height: number } {
    const navigatorContainerRect = this._elementRef.nativeElement
      .querySelector('#navigator-container')
      .getBoundingClientRect();
    return { width: navigatorContainerRect.width, height: navigatorContainerRect.height };
  }

  public setShowLeftRightButtons(value: boolean): void {
    this._showLeftRightButtons = value;
  }

  public setShowNavigationButton(value: boolean): void {
    this._showNavigationButton = value;
  }

  public setShowShiftCounter(value: boolean): void {
    this._showShiftCounter = value;
  }

  public setShowTitle(showTitle: boolean, titleAsDragHandle = false) {
    this._showTitle = showTitle;
    if (!this._showTitle) this._titleAsDragHandle = false;
    else this._titleAsDragHandle = titleAsDragHandle;
  }

  public setAutomaticNavigation(value: boolean): void {
    this._automaticNavigation = value;
  }

  /**
   * Sets the drag boundaries of the navigator overlay.
   * @param dragBoundary Reference element to set the boundaries to.
   */
  public setDragBoundary(dragBoundary: HTMLElement): void {
    this._dragBoundary = dragBoundary;
  }

  public get onNavigationButtonClick(): Observable<GanttDataShift> {
    return this._onNavigationButtonClick.asObservable();
  }

  public get afterViewInit(): Observable<void> {
    return this._afterViewInit.asObservable();
  }
}
