import { GanttAreaOverlayPlugIn } from './plugin-list/area-overlay/area-overlay.plugin';
import { GanttBlockAdditionalMarkersPlugIn } from './plugin-list/block-additional-markers/block-additional-markers';
import { GanttColorizerByAttributePlugIn } from './plugin-list/block-colorizer/by-attribute/colorizer-by-attribute';
import { GanttBlockConnectionsPlugIn } from './plugin-list/block-connections/block-connections';
import { GanttBlockFilterByConditionPlugIn } from './plugin-list/block-filter-by-condition/block-filter-by-condition';
import { GanttBlockFilterPlugIn } from './plugin-list/block-filter/block-filter';
import { GanttBlockFilterByAttributePlugIn } from './plugin-list/block-filter/block-filter-by-attribute';
import { GanttBlockHighlighterPlugIn } from './plugin-list/block-highlighter/block-highlighter';
import { GanttBlockLoadingPlugIn } from './plugin-list/block-loading/block-loading';
import { GanttBlockMoverPlugIn } from './plugin-list/block-mover/block-mover';
import { GanttBlockingIntervalsPlugIn } from './plugin-list/blocking-intervals/blocking-intervals.plugin';
import { GanttBrokenConstraintsNavigatorPlugIn } from './plugin-list/broken-constraints-navigator/broken-constraints-navigator.plugin';
import { GanttEarliestStartLatestEndPlugIn } from './plugin-list/earliest-start-latest-end-visualizer/earliest-start-latest-end-visualizer';
import { GanttRestrictBlockDragPlugIn } from './plugin-list/edit-block/restrictions/restrict-block-drag';
import { GanttGridLabelsPlugIn } from './plugin-list/grid-labels/grid-labels';
import { GanttIndexCardsPlugIn } from './plugin-list/index-cards/index-cards.plugin';
import { GanttOverlappingShiftsPlugIn } from './plugin-list/overlapping-shifts/overlapping-shifts';
import { GanttRowColorizerPlugIn } from './plugin-list/row-colorizer/row-colorizer.plugin';
import { GanttRowRestrictionPlugIn } from './plugin-list/row-restriction/row-restriction.plugin';
import { GanttShiftDragVisualizerPlugIn } from './plugin-list/shift-drag-visualizer/shift-drag-visualizer.plugin';
import { GanttStickyBlocksPlugIn } from './plugin-list/sticky-blocks/sticky-blocks.plugin';
import { GanttSuperBlocksPlugIn } from './plugin-list/superblocks/superblocks.plugin';
import { GanttXAxisFormatPlugIn } from './plugin-list/time-formatting/xaxis-formatter';
import { GanttXAxisManipulatorPlugIn } from './plugin-list/time-scaling/xaxis-manipulator';
import { GanttCurrentTimePointMarkerPlugIn } from './plugin-list/timepoint-marker/current-timepoint-marker.plugin';
import { GanttTimePointMarkerPlugIn } from './plugin-list/timepoint-marker/timepoint-marker.plugin';
import { GanttYAxisSearchPlugIn } from './plugin-list/y-axis-search/y-axis-search';
import { GanttYAxisTablePlugIn } from './plugin-list/y-axis-table/y-axis-table.plugin';

/**
 * All register types for essential plugins.
 */
export enum GanttEssentialPlugIns {
  SuperBlocksPlugIn = 'SuperBlocksPlugIn', // TODO: multi blocks
  XAxisManipulatorPlugIn = 'XAxisManipulatorPlugIn',
  YAxisTableBuilderPlugIn = 'YAxisBuilderPlugIn',
  YAxisSearchPlugIn = 'YAxisSearchPlugIn',
  IndexCardBuilderPlugIn = 'IndexCardBuilderPlugIn', // ? Done: multi blocks
  OverlappingShiftsPlugIn = 'OverlappingShiftsPlugIn',
  XAxisFormatterPlugIn = 'XAxisFormatterPlugIn',
  BlockColorizeByAttributePlugIn = 'BlockColorizeByAttributePlugIn',
  BlockAdditionalMarkersPlugIn = 'BlockAdditionalMarkersPlugIn', // ? Done: multi blocks
  BlockMoverPlugIn = 'BlockMoverPlugIn', // TODO: multi blocks because unused so far it seems
  RestrictBlockDragPlugIn = 'RestrictBlockDragPlugIn', // ? Done: multi blocks
  BlockFilterPlugIn = 'toggledefaultfilter',
  GanttBlockFilterByAttributePlugIn = 'GanttBlockFilterByAttributePlugIn',
  MultiselectPlugIn = 'MultiselectPlugIn',
  RowRestrictionPlugIn = 'RowRestrictionPlugIn',
  AreaOverlayPlugIn = 'AreaOverlayPlugIn',
  TimePointMarkerPlugIn = 'TimePointMarkerPlugIn',
  BlockConnectionsPlugIn = 'BlockConnectionsPlugIn', // ? DONE: multi blocks
  CurrentTimePointMarkerPlugIn = 'CurrentTimePointMarkerPlugIn',
  BlockingIntervalPlugIn = 'template.ganttplugin.blockinginterval',
  GanttShiftDragVisualizerPlugIn = 'GanttShiftDragVisualizerPlugIn',
  GanttBlockFilterByConditionPlugIn = 'GanttBlockFilterByConditionPlugIn',
  GanttStickyBlocksPlugIn = 'GanttStickyBlocksPlugIn',
  GanttRowColorizerPlugin = 'GanttRowColorizerPlugin',
  GanttBlockHighlighter = 'GanttBlockHighlighter',
  GanttEarliestStartLatestEndVisualizer = 'GanttEarliestStartLatestEndVisualizer',
  GanttBlockLoading = 'GanttBlockLoading',
  GanttGridLabels = 'GanttGridLabels',
  GanttBrokenConstraintsNavigator = 'GanttBrokenConstraintsNavigator',
}

export type PluginIdToInstanceMap<T> = T extends GanttEssentialPlugIns.SuperBlocksPlugIn
  ? GanttSuperBlocksPlugIn
  : T extends GanttEssentialPlugIns.XAxisManipulatorPlugIn
  ? GanttXAxisManipulatorPlugIn
  : T extends GanttEssentialPlugIns.YAxisTableBuilderPlugIn
  ? GanttYAxisTablePlugIn
  : T extends GanttEssentialPlugIns.YAxisSearchPlugIn
  ? GanttYAxisSearchPlugIn
  : T extends GanttEssentialPlugIns.IndexCardBuilderPlugIn
  ? GanttIndexCardsPlugIn
  : T extends GanttEssentialPlugIns.OverlappingShiftsPlugIn
  ? GanttOverlappingShiftsPlugIn
  : T extends GanttEssentialPlugIns.XAxisFormatterPlugIn
  ? GanttXAxisFormatPlugIn
  : T extends GanttEssentialPlugIns.BlockColorizeByAttributePlugIn
  ? GanttColorizerByAttributePlugIn
  : T extends GanttEssentialPlugIns.BlockAdditionalMarkersPlugIn
  ? GanttBlockAdditionalMarkersPlugIn
  : T extends GanttEssentialPlugIns.BlockMoverPlugIn
  ? GanttBlockMoverPlugIn
  : T extends GanttEssentialPlugIns.RestrictBlockDragPlugIn
  ? GanttRestrictBlockDragPlugIn
  : T extends GanttEssentialPlugIns.BlockFilterPlugIn
  ? GanttBlockFilterPlugIn
  : T extends GanttEssentialPlugIns.GanttBlockFilterByAttributePlugIn
  ? GanttBlockFilterByAttributePlugIn
  : T extends GanttEssentialPlugIns.RowRestrictionPlugIn
  ? GanttRowRestrictionPlugIn
  : T extends GanttEssentialPlugIns.AreaOverlayPlugIn
  ? GanttAreaOverlayPlugIn
  : T extends GanttEssentialPlugIns.TimePointMarkerPlugIn
  ? GanttTimePointMarkerPlugIn
  : T extends GanttEssentialPlugIns.BlockConnectionsPlugIn
  ? GanttBlockConnectionsPlugIn
  : T extends GanttEssentialPlugIns.CurrentTimePointMarkerPlugIn
  ? GanttCurrentTimePointMarkerPlugIn
  : T extends GanttEssentialPlugIns.BlockingIntervalPlugIn
  ? GanttBlockingIntervalsPlugIn
  : T extends GanttEssentialPlugIns.GanttShiftDragVisualizerPlugIn
  ? GanttShiftDragVisualizerPlugIn
  : T extends GanttEssentialPlugIns.GanttBlockFilterByConditionPlugIn
  ? GanttBlockFilterByConditionPlugIn
  : T extends GanttEssentialPlugIns.GanttStickyBlocksPlugIn
  ? GanttStickyBlocksPlugIn
  : T extends GanttEssentialPlugIns.GanttRowColorizerPlugin
  ? GanttRowColorizerPlugIn
  : T extends GanttEssentialPlugIns.GanttBlockHighlighter
  ? GanttBlockHighlighterPlugIn
  : T extends GanttEssentialPlugIns.GanttEarliestStartLatestEndVisualizer
  ? GanttEarliestStartLatestEndPlugIn
  : T extends GanttEssentialPlugIns.GanttBlockLoading
  ? GanttBlockLoadingPlugIn
  : T extends GanttEssentialPlugIns.GanttGridLabels
  ? GanttGridLabelsPlugIn
  : T extends GanttEssentialPlugIns.GanttBrokenConstraintsNavigator
  ? GanttBrokenConstraintsNavigatorPlugIn
  : never;
