import { Component, ComponentRef, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyTabGroup as MatTabGroup } from '@angular/material/legacy-tabs';
import { ContentElement } from '../content/content-element/content-element';
import { ContentPart } from '../content/content-part/content-part';
import { EntryCollection } from '../entry-collection/entry-collection';
import { TemplateComponent } from '../template/template.component';
import { IndexPage } from './index-page';
import { IndexRegister } from './index-register';

@Component({
  selector: 'app-index-register',
  templateUrl: './index-register.component.html',
  styleUrls: ['./index-register.component.scss'],
})
export class IndexRegisterComponent implements OnInit, OnDestroy {
  @Input() resourceId: string;
  @Input() templateNode: IndexRegister;
  @Input() root;

  afterSlotInit(instance: ComponentRef<TemplateComponent>, tab: IndexPage): void {
    instance.instance.root = this.root || this.elementRef.nativeElement;
    instance.instance.resourceId = this.resourceId || tab.getResourceId();
    tab.setPreventUnregister(true, true);

    this.setTabState(tab, true);
  }
  public selectedTabIndex = 0;

  constructor(public elementRef: ElementRef) {}

  private setTabState(tab: IndexPage, state = false): void {
    tab
      ?.getContent()
      ?.setPreventUnregister(state, true)
      .getContentParts()
      .forEach((p: ContentPart) => {
        p.getContentElements().forEach((ce: ContentElement) => {
          ce.setPreventUnregister(state, true);
          if (ce instanceof EntryCollection) {
            ce.getEntryElements().forEach((ee) => {
              ee.setPreventUnregister(state, true);
            });
          }
        });
      });
  }

  ngOnInit(): void {}

  onChangeTabIndex(tabIndex: number, element: MatTabGroup): void {}

  ngOnDestroy(): void {
    this.templateNode.getIndexPages().forEach((p: IndexPage) => {
      this.setTabState(p);
    });
  }
}
