import {
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { SplitComponent } from 'angular-split';
import { ColorpickerPopupComponent } from 'frontend/src/dashboard/popups/colorpickerpopup/colorpickerpopup.component';
import { FilePreviewDialogConfig, PopUpService } from 'frontend/src/dashboard/popups/popup.service';
import { ColorScheme, ColorValue } from './colorscheme';

@Component({
  selector: 'template-colorscheme-color-slider',
  templateUrl: './colorscheme-color-slider.component.html',
  styleUrls: ['./colorscheme-color-slider.component.scss'],
  providers: [],
})
export class ColorschemeColorSliderComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @ViewChild('colorSpiltElement') colorSpiltElement: SplitComponent;
  @Input() colorScheme: ColorScheme;
  @Output() afterColorValueChanges: EventEmitter<any> = new EventEmitter();

  constructor(private popUpService: PopUpService, public componentFactoryResolver: ComponentFactoryResolver) {}
  ngOnInit(): void {}

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {}

  ngAfterViewInit(): void {
    this.colorSpiltElement.dragProgress$.subscribe((event: { gutterNum: number; sizes: Array<number> }) => {
      this.colorScheme.calcNewColorValues(event.sizes);
      this.colorScheme.calcLinearColorString();
    });
  }

  getColorAreaSize(colorValue: ColorValue, index: number) {
    if (index === 0) {
      return (colorValue.getValue() / this.colorScheme.getMaxValue()) * 100;
    } else {
      return (
        ((colorValue.getValue() - this.colorScheme.getColoredValues()[index - 1].getValue()) /
          this.colorScheme.getMaxValue()) *
        100
      );
    }
  }

  onHandleDragEnd(event: { gutterNum: number; sizes: Array<number> }) {
    this.colorScheme.calcNewColorValues(event.sizes);
    this.colorScheme.calcLinearColorString();
    this.afterColorValueChanges.emit();
  }

  onExplictValueChange(e, colorValue: ColorValue) {
    const el: any = event.target;
    colorValue.setValue(parseInt(el.value));
    this.afterColorValueChanges.emit();
  }

  /** dirty hack */

  openColorPicker(event: any, colorValue: ColorValue) {
    event.preventDefault();

    const injector = Injector.create([]);
    const factory = this.componentFactoryResolver.resolveComponentFactory(ColorpickerPopupComponent);
    const componentRef = factory.create(injector);

    const popUpWidget = {
      componentRef: componentRef,
      chartColor: colorValue.getColor(),
      standartColor: colorValue.getColor(),
      closeButton: false,
    };

    const popUpConfig: FilePreviewDialogConfig = {
      hasBackdrop: true,
      backdropClass: 'dark-backdrop-colorpicker',
      panelClass: 'popup-overlay-panel-colorpicker',
      htmlTarget: new ElementRef(event.target),
      overlayLeft: true,
    };

    componentRef.instance.widget = popUpWidget;
    componentRef.instance.currentColor = colorValue.getColor();
    componentRef.instance.standartColor = colorValue.getColor();

    const subcription = componentRef.instance.onChangeEmitter.subscribe((color) => {
      if (color) {
        colorValue.setColor(color);
        this.colorScheme.calcLinearColorString();
        this.afterColorValueChanges.emit();
        // this.handleLegendClick.emit({ action: 'SaveColor', data: dataset });
      } else {
        subcription.unsubscribe();
      }
    });
    const dialogref = this.popUpService.open(popUpWidget, popUpConfig);
    componentRef.instance.widget = dialogref.widgetToken;
  }
}
