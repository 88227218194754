import { ConnectedPosition, Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector, TemplatePortal } from '@angular/cdk/portal';
import { ComponentRef, EventEmitter, Injectable, Injector } from '@angular/core';
import { PopupComponent } from './popup.component';
import { WIDGET_OVERLAY_DATA } from './popup.tokens';
import { PopUpOverlayRef } from './popupOverlayRef';

export interface WidgetToken {
  componentRef?;
  component?;
  widget?;
  data?;
  closeButton: boolean;
  experimentId?;
  chartColor?: any;
  standartColor?: any;
  close?: (color: any) => void;
  onCloseCallback?: (data: any) => void;
}
export interface FilePreviewDialogConfig {
  panelClass?: string;
  hasBackdrop?: boolean;
  backdropClass?: string;
  htmlTarget?: any;
  overlayLeft?: boolean;
  width?: number;
}

const DEFAULT_CONFIG: FilePreviewDialogConfig = {
  hasBackdrop: true,
  backdropClass: 'dark-backdrop',
  panelClass: 'popup-overlay-panel',
};

@Injectable()
export class PopUpService {
  public saveEmitter: EventEmitter<any> = new EventEmitter();
  public resetEmitter: EventEmitter<any> = new EventEmitter();

  constructor(private injector: Injector, private overlay: Overlay) {}

  open(widgetInfo: WidgetToken, config?: FilePreviewDialogConfig) {
    // Override default configuration

    const widgetToken: WidgetToken = {
      componentRef: widgetInfo.componentRef,
      component: widgetInfo.component,
      widget: widgetInfo.widget,
      data: widgetInfo.data,
      closeButton: widgetInfo.closeButton,
      experimentId: widgetInfo.experimentId,
      chartColor: widgetInfo.chartColor,
      standartColor: widgetInfo.standartColor,
      onCloseCallback: widgetInfo.onCloseCallback,
      close: (success) => {
        dialogRef.close();
      },
    };

    if (widgetInfo.hasOwnProperty('closeButton')) {
      widgetToken.closeButton = widgetInfo.closeButton;
    }

    const dialogConfig = { ...DEFAULT_CONFIG, ...config };

    // Returns an OverlayRef which is a PortalHost
    const overlayRef = this.createOverlay(dialogConfig);

    // Instantiate remote control
    const dialogRef = new PopUpOverlayRef(overlayRef);

    const dialogContainer = this.attachDialogContainer(overlayRef, dialogConfig, dialogRef, widgetToken);

    overlayRef.backdropClick().subscribe((_) => {
      // dialogRef.close();
      this.saveEmitter.emit(undefined);
    });

    return { overlayRef: dialogRef, overlayComponent: dialogContainer, widgetToken: widgetToken };
  }

  public closeDialog(overlayRef) {
    overlayRef.close();
  }

  attachDialogContent(componentRef: any, containerRef: any, overlayRef: any, config: any): any {
    containerRef.attachTemplatePortal(new TemplatePortal(componentRef, undefined));
  }

  private createOverlay(config: FilePreviewDialogConfig) {
    const overlayConfig: OverlayConfig = this.getOverlayConfig(config);

    return this.overlay.create(overlayConfig);
  }

  private attachDialogContainer(
    overlayRef: OverlayRef,
    config: FilePreviewDialogConfig,
    dialogRef: PopUpOverlayRef,
    widgetToken: WidgetToken
  ) {
    const injector = this.createInjector(widgetToken, config, dialogRef);

    const containerPortal = new ComponentPortal(PopupComponent, undefined, injector);
    const containerRef: ComponentRef<PopupComponent> = overlayRef.attach(containerPortal);

    return containerRef.instance;
  }

  private createInjector(
    widgetToken: WidgetToken,
    config: FilePreviewDialogConfig,
    dialogRef: PopUpOverlayRef
  ): PortalInjector {
    const injectionTokens = new WeakMap();

    injectionTokens.set(PopUpOverlayRef, dialogRef);
    injectionTokens.set(WIDGET_OVERLAY_DATA, widgetToken);

    return new PortalInjector(this.injector, injectionTokens);
  }

  private getOverlayConfig(config: FilePreviewDialogConfig): OverlayConfig {
    let positionStrategy;

    if (config.htmlTarget) {
      const connections: ConnectedPosition = {
        originX: config.overlayLeft ? 'start' : 'end',
        originY: 'bottom',
        overlayX: config.overlayLeft ? 'end' : 'start',
        overlayY: config.overlayLeft ? 'bottom' : 'top',
      };

      positionStrategy = this.overlay.position().flexibleConnectedTo(config.htmlTarget).withPositions([connections]);
    } else {
      positionStrategy = this.overlay.position().global().centerHorizontally().centerVertically();
    }

    const overlayConfig = new OverlayConfig({
      hasBackdrop: config.hasBackdrop,
      backdropClass: config.backdropClass,
      panelClass: config.panelClass,
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy,
    });

    if (config.width) {
      overlayConfig.width = config.width;
    }

    return overlayConfig;
  }
}
