import { Observable, Subject, takeUntil } from 'rxjs';
import { IGanttShiftTranslationEvent } from '../../edit-shifts/shift-translation/translation-events/translation-event.interface';
import { BestGantt } from '../../main';
import { BestGanttPlugIn } from '../gantt-plug-in';

/**
 * Handles shift drag restrictions for specific shifts.
 * @keywords executer, drag, translate, shift, limiter, restriciton, rule, limit
 * @plugin shift-drag-limiter
 * @class
 * @constructor
 * @extends BestGanttPlugIn
 *
 * @requires BestGanttPlugIn
 */
export class GanttShiftDragLimiter extends BestGanttPlugIn {
  private _oldRestrictions: { vertical: boolean; horizontal: boolean } = undefined;
  private _restrictions: { [shiftId: string]: string } = {};

  private _onDestroySubject: Subject<void> = new Subject<void>();

  constructor() {
    super(); //call super-constructor
  }

  public initPlugIn(ganttDiagram: BestGantt): void {
    this.ganttDiagram = ganttDiagram;
    // init callbacks
    this.ganttDiagram.getShiftEditLimiter().registerDragLimiterPlugIn(this.UUID, this);
    this.ganttDiagram
      .getShiftTranslator()
      .onShiftEditStart()
      .pipe(takeUntil(this.onDestroy))
      .subscribe((event) => this._restrictByShiftId(event));
    this.ganttDiagram
      .getShiftTranslator()
      .onShiftEditEnd()
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => this._resetRestrictions());
  }

  /**
   * Remove of plugin. Part of plugin lifecycle.
   */
  public removePlugIn(): void {
    // callback unsubscribe
    this.ganttDiagram.getShiftEditLimiter().unregisterDragLimiterPlugIn(this.UUID);
    this._onDestroySubject.next();
    this._onDestroySubject.complete();
  }

  /**
   * Adds drag restriction to given shift.
   * @param {string} shiftId Id of shift.
   * @param {"VERTICAL"|"HORIZONTAL"|"NODRAG"} dragRestriction Restriction.
   */
  addShiftDragRestriction(shiftId, dragRestriction) {
    this._restrictions[shiftId] = dragRestriction;
  }

  /**
   * Removes drag restriction by shift id.
   * @param {string} shiftId Id of shift.
   */
  removeShiftDragRestriction(shiftId) {
    delete this._restrictions[shiftId];
  }

  /**
   * Clears all shift restrictions.
   */
  removeAllShiftDragRestriction() {
    this._restrictions = {};
  }

  /**
   * Callback function which checks drag restrictions for currently dragged shift.
   * @param data Drag start event data.
   */
  private _restrictByShiftId(data: IGanttShiftTranslationEvent): void {
    const shiftTranslator = this.ganttDiagram.getShiftTranslator();

    this._oldRestrictions = {
      vertical: shiftTranslator.shiftEditLimiterAdapter.allowDragVertical,
      horizontal: shiftTranslator.shiftEditLimiterAdapter.allowDragHorizontal,
    };

    switch (this._restrictions[data.target.data()[0].id]) {
      case 'VERTICAL':
        this.ganttDiagram.getShiftTranslator().shiftEditLimiterAdapter.allowDragVertical = false;
        break;
      case 'HORIZONTAL':
        this.ganttDiagram.getShiftTranslator().shiftEditLimiterAdapter.allowDragHorizontal = false;
        break;
      case 'NODRAG':
        this.ganttDiagram.getShiftTranslator().shiftEditLimiterAdapter.allowDragVertical = false;
        this.ganttDiagram.getShiftTranslator().shiftEditLimiterAdapter.allowDragHorizontal = false;
        break;
    }
  }

  /**
   * Callback function which resets all restrictions on drag end.
   */
  private _resetRestrictions(): void {
    if (!this._oldRestrictions) return;
    const shiftTranslator = this.ganttDiagram.getShiftTranslator();
    shiftTranslator.shiftEditLimiterAdapter.allowDragVertical = this._oldRestrictions.vertical;
    shiftTranslator.shiftEditLimiterAdapter.allowDragHorizontal = this._oldRestrictions.horizontal;
  }

  public getRestrictions(): { [shiftId: string]: string } {
    return this._restrictions;
  }

  //
  // GETTER & SETTER
  //

  /**
   * Observable which gets triggered when the instance gets destroyed.
   */
  private get onDestroy(): Observable<void> {
    return this._onDestroySubject.asObservable();
  }
}
