import { HMI } from '../tree/tree-item';

export class ListSelectorElement extends HMI {
  private simpleElement: boolean;
  private resourceClazz: string;
  private floatLeft: string;
  private floatLeftLines: string[] = [];
  private floatRight: string;
  private floatRightLines: string[] = [];
  private floatFull: string;

  public getFloatLeft(): string {
    return this.floatLeft;
  }

  public setFloatLeft(floatLeft: string): this {
    this.floatLeft = floatLeft;
    return this;
  }

  public getFloatRight(): string {
    return this.floatRight;
  }

  public setFloatRight(floatRight: string): this {
    this.floatRight = floatRight;
    return this;
  }

  public getResourceClazz(): string {
    return this.resourceClazz;
  }

  public setResourceClazz(resourceClazz: string): this {
    this.resourceClazz = resourceClazz;
    return this;
  }

  public getFloatFull(): string {
    return this.floatFull;
  }

  public setFloatFull(floatFull: string): this {
    this.floatLeft = floatFull;
    return this;
  }

  public setFloatLeftLines(lines: string[]): this {
    this.floatLeftLines = lines;
    return this;
  }

  public getFloatLeftLines(): string[] {
    return this.floatLeftLines;
  }

  public setFloatRightLines(lines: string[]): this {
    this.floatRightLines = lines;
    return this;
  }

  public getFloatRightLines(): string[] {
    return this.floatRightLines;
  }

  public isSimpleElement(): boolean {
    return typeof this.simpleElement === 'boolean' ? this.simpleElement : false;
  }

  public setSimpleElement(simpleElement: boolean): this {
    this.simpleElement = simpleElement;
    return this;
  }
}
