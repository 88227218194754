import { EGanttInstance } from '@gantt/public-api';
import { GanttLibService } from 'frontend/src/dashboard/gantt/gantt/gantt-lib.service';
import { SaxMsBestGanttActiveSubmenuEntryElementSetting } from 'frontend/src/dashboard/gantt/gantt/saxms-best-gantt.settings';
import { GanttPluginHandlerService } from 'frontend/src/dashboard/gantt/general/plugin/gantt-plugin-handler.service';
import { GanttTemplateData } from 'frontend/src/dashboard/gantt/helper/gantt';
import { Observable, of } from 'rxjs';
import { GeneralGanttActionHandler } from '../../../action-handling/action-handler';
import { GanttExternalActionRegistration } from '../../../gantt-actions/external-action-registration';
import { IGanttTemplateValue } from '../../../generator/gantt-input.data';
import { GanttResponseHandler } from '../../../response/response-handler';
import { ExternalGanttPlugin } from '../../external-plugin';
import { GanttResourceChangeActionHandler } from './event-handling/resource-change-registration';
import { GanttResourceChangeMode } from './resource-change.mode';

export const GanttResourceChangeBackendType = 'template.ganttplugin.shiftSplit';

const GanttPlugInResourceChangeExecuter = 'gantt-plugin-resource-change-executer';

/**
 * Plugin which does not exist directly in JavaScript.
 * It uses the ShiftSplitByClick-PlugIn for the mouse based timepoint marker.
 */
export class GanttResourceChangePlugIn extends ExternalGanttPlugin {
  private resourceChangeData: any;

  constructor(
    protected _ganttPluginHandlerService: GanttPluginHandlerService,
    protected _ganttLibService: GanttLibService,
    private responseHandler: GanttResponseHandler,
    actionHandler: GeneralGanttActionHandler,
    backendPluginData: any[]
  ) {
    super(_ganttPluginHandlerService, _ganttLibService, actionHandler);
    this.resourceChangeData = this.findResourceChangeData(backendPluginData);
  }

  public shiftSplitter: any;
  private registration: GanttExternalActionRegistration;

  public onInit(templateData: GanttTemplateData, responseData: IGanttTemplateValue): void {
    this.shiftSplitter = this._ganttLibService.ganttInstanceService.getInstance(
      EGanttInstance.SHIFT_SPLIT_BY_CLICK,
      this._ganttLibService.bestGantt,
      this._ganttLibService.bestGantt
        .getShiftFacade()
        .getShiftWrapperOverlay()
        .select('.gantt_shifts-wrapper-overlay-svg')
    );
    if (
      responseData.hierarchicalPlan &&
      responseData.hierarchicalPlan.gridRef &&
      responseData.hierarchicalPlan.minStepWidth
    ) {
      const userTimezoneOffset: number = new Date(responseData.hierarchicalPlan.gridRef).getTimezoneOffset() * 60000;
      this.shiftSplitter.setCustomizedTimeGrid(
        new Date(responseData.hierarchicalPlan.gridRef - userTimezoneOffset + 1000),
        responseData.hierarchicalPlan.minStepWidth
      );
    }
    this.registration = new GanttResourceChangeActionHandler(
      this._ganttPluginHandlerService,
      this._ganttLibService,
      this.shiftSplitter,
      this.actionHandler,
      this.responseHandler,
      templateData,
      this.resourceChangeData
    );
    this.setAdditionalData(this.shiftSplitter);
  }

  public onDestroy(): void {}

  public onAction(action: any) {}

  /**
   * Propagates local action to the registrator to activate plugin.
   * @param action Local action to handle.
   */
  public executeAction(action: any): Observable<any> {
    if (!action.pluginId || action.pluginId != GanttResourceChangeBackendType) of(false);
    return this.registration.handleLocalAction(action);
  }

  /**
   * Extracts part of resource change data from backend data.
   * @param backendPluginData Backend plugin data.
   */
  private findResourceChangeData(backendPluginData: any[]): any {
    return backendPluginData.find((pluginData) => pluginData.id == GanttResourceChangeBackendType);
  }

  /**
   * Stores plugin state inside additional data container of JavaScript ShiftSplitByClick.
   * @param executer JS plugin.
   */
  private setAdditionalData(executer: any) {
    const additionalData: ShiftSplitterAdditionalData = {
      state: GanttResourceChangeMode.SLEEP,
    };
    executer.setAdditionalData(additionalData);
  }

  public injectSettings(submenuElements: SaxMsBestGanttActiveSubmenuEntryElementSetting[]): void {}
}

export interface ShiftSplitterAdditionalData {
  state: GanttResourceChangeMode;
}
