import { GanttXAxisManipulatorPlugIn } from 'frontend/src/dashboard/gantt/general/plugin/plugin-list/time-scaling/xaxis-manipulator';
import { GanttPredefinedSetting } from '../../predefined-setting';

export class GanttInitZoomLevelHandler extends GanttPredefinedSetting {
  public onInit(templateData: any, ganttResponseData: any): void {
    if (!ganttResponseData.hierarchicalPlan || !ganttResponseData.hierarchicalPlan.initialZoom) return;
    const startDate: number =
      ganttResponseData.hierarchicalPlan.initialZoom.startDate || ganttResponseData.hierarchicalPlan.startDate || 0;
    const endDate: number =
      ganttResponseData.hierarchicalPlan.initialZoom.endDate ||
      ganttResponseData.hierarchicalPlan.endDate ||
      Math.floor(new Date('2099.01.01').getTime());
    const xAxisManipulator: GanttXAxisManipulatorPlugIn = new GanttXAxisManipulatorPlugIn(
      this.ganttPluginHandlerService,
      this.ganttLibService,
      this.actionHandler
    );
    const ganttSettings = this.ganttPluginHandlerService.getGanttSettingsService().getGanttSettings();
    if (!ganttSettings.scrollStart || !ganttSettings.scrollEnd) {
      xAxisManipulator.changeTimeToInterval(new Date(startDate), new Date(endDate));
    }
  }
}
