import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import {
  AfterViewInit,
  Component,
  ElementRef,
  InjectionToken,
  Injector,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { EEntryElementEvents } from '@app-modeleditor/components/entry-collection/entry-element-events.enum';
import { EntryElementValue } from '@app-modeleditor/components/entry-collection/entry-element-value';
import { ChartDataGroup } from '@app-modeleditor/components/widget-modules/chart-module/chart/generalChart/chart.mapper';
import {
  CHART_TOOLTIP_ITEMS,
  CHART_TOOLTIP_LABEL,
  CHART_TOOLTIP_LABELINDEX,
  TooltipPopupComponent,
} from '@app-modeleditor/components/widget-modules/chart-module/chart/tooltip-popup/tooltip-popup.component';
import { Chart, ChartData, ChartOptions, ChartType } from 'chart.js';
import { ChartEvent } from 'chart.js/dist/core/core.plugins';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { GlobalUtils } from 'frontend/src/dashboard/global-utils';
import { GeneralPopUpService } from 'frontend/src/dashboard/popups-services/general-popup.service';
import { BaseChartDirective } from 'ng2-charts';
import { Subject, of } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';
import { PieChart } from './pie-chart';
import { PieChartEntry } from './pie-chart-entry';

@Component({
  selector: 'app-chart-new',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss'],
})
export class PieChartComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() chart: PieChart;
  @ViewChild(BaseChartDirective) chartComponent: BaseChartDirective;
  @ViewChild('chartRef') chartRef: ElementRef;
  @ViewChild('chartWrapper') chartWrapper: ElementRef;
  private componentId = GlobalUtils.generateUUID();

  private ngUnsubscribe: Subject<void> = new Subject<void>();
  public chartType: ChartType = 'doughnut';
  public chartHeight = 600;
  public chartLeft = 100;
  public chartWidth = 400;
  private tooltipTimeoutId: Subject<void> = new Subject<void>();

  public options: ChartOptions<'doughnut'>;

  public data: ChartData;
  public description: EntryElementValue[];

  constructor(public generalPopupService: GeneralPopUpService, public injector: Injector, private zone: NgZone) {
    this.getOption();
  }
  ngAfterViewInit(): void {
    of(null)
      .pipe(delay(0))
      .subscribe(() => {
        this.chartHeight = this.chartRef.nativeElement.offsetHeight;
        this.chartWidth = this.chartRef.nativeElement.offsetWidth;
        this.chartLeft = this.chartRef.nativeElement.offsetLeft;
      });
  }

  private getChartDataGroupForTooltip(index: number) {
    const from: number = this.getValueAtIndex(index).getStartValue();
    const to: number = this.getValueAtIndex(index).getEndValue();
    // `${((Math.abs(from - to)) * 100).toFixed(1)}%`;

    const dataGroup: ChartDataGroup = {
      id: GlobalUtils.generateUUID(),
      chartDataGroupId: GlobalUtils.generateUUID(),
      localID: GlobalUtils.generateUUID(),
      label: this.data.labels[index],
      data: [`${(Math.abs(from - to) * 100).toFixed(1)}%`],
      backgroundColor: this.getValueAtIndex(index).getColor(),
      backgroundHexColor: this.getValueAtIndex(index).getColor(),
      attributes: [],
      hidden: false,
      stacklessVisible: true,
      associatedResource: '',
    };
    return dataGroup;
  }

  private getOption() {
    this.options = {
      responsive: true,
      radius: '80%',
      onHover: (event: ChartEvent, activeElements: Array<any>, chart: Chart) => {
        const dataset: any = chart.getElementsAtEventForMode(event.native, 'dataset', { intersect: true }, false);
        if (
          !dataset ||
          chart.getElementsAtEventForMode(event.native, 'dataset', { intersect: true }, false).length === 0
        ) {
          this.closeTooltip();
          this.tooltipTimeoutId.next();
        } else {
          this.tooltipTimeoutId.next();
          of(null)
            .pipe(delay(2000), takeUntil(this.tooltipTimeoutId))
            .subscribe(() => {
              if (!dataset) {
                return;
              }
              const datasetIndex = activeElements[0].datasetIndex;
              const labelIndex = activeElements[0].index;
              //const dataConfig: any = chart.getDatasetMeta(dataset._datasetIndex).controller._config;
              const datagroups: ChartDataGroup[] = [this.getChartDataGroupForTooltip(labelIndex)];
              this.openTooltip(
                (event.native as MouseEvent).pageX,
                (event.native as MouseEvent).pageY,
                datagroups,
                datasetIndex
              );
            });
        }
      },
      plugins: {
        tooltip: {
          enabled: false,
          callbacks: {
            label: (tooltipItem) => {
              if (
                this.chart.getValue<EntryElementValue>() &&
                this.chart.getValue<EntryElementValue>().getValue<PieChartEntry>()
              ) {
                return this.getValueAtIndex(tooltipItem.datasetIndex).getHint();
              }
              return '';
            },
          },
        },
        legend: {
          position: 'bottom',
        },
        datalabels: {
          formatter: (value, ctx) => {
            if (
              this.chart.getValue<EntryElementValue>() &&
              this.chart.getValue<EntryElementValue>().getValue<PieChartEntry>()
            ) {
              const from: number = this.getValueAtIndex(ctx.dataIndex).getStartValue();
              const to: number = this.getValueAtIndex(ctx.dataIndex).getEndValue();
              return `${(Math.abs(from - to) * 100).toFixed(1)}%`;
            }
            return '';
          },
        },
      },
    };
  }

  private getValueAtIndex(index: number): EntryElementValue {
    return this.chart.getValue<EntryElementValue>().getValue<PieChartEntry>().getLegendValues()[index];
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.chart?.removeEventListener(this.componentId);
    this.tooltipTimeoutId.next();
    this.tooltipTimeoutId.complete();
  }

  height = '400px';

  ngOnInit(): void {
    Chart.register(ChartDataLabels);
    this.chart.addEventListener(this.componentId, EEntryElementEvents.VALUE_CHANGED, (c: CustomEvent) => {
      this.handlePieChartData();
    });
    this.handlePieChartData();
  }

  private handlePieChartData() {
    this.data = { labels: [], datasets: [{ data: [], backgroundColor: [] }] };
    if (!this.chart || !this.chart.getValue<PieChartEntry>()) {
      return;
    }
    if (this.chart.getValue<EntryElementValue>().getValue<PieChartEntry>().getDescriptions()) {
      this.description = this.chart.getValue<EntryElementValue>().getValue<PieChartEntry>().getDescriptions();
    }
    if (this.chart.getValue<EntryElementValue>().getValue<PieChartEntry>().getLegendValues()) {
      this.chart
        .getValue<EntryElementValue>()
        .getValue<PieChartEntry>()
        .getLegendValues()
        .forEach((v: EntryElementValue) => {
          this.data.datasets[0].data.push(v.getEndValue() - v.getStartValue());
          this.data.labels.push(v.getName());
        });

      this.data.datasets[0].backgroundColor = this.chart
        .getValue<EntryElementValue>()
        .getValue<PieChartEntry>()
        .getLegendValues()
        .map((v: EntryElementValue) => v.getColor());
      // hoverBackgroundColor: this.chart.getValue<EntryElementValue>().getValue<EntryElementValue[]>()
      //   .map((v: EntryElementValue) => `rgba(0,0,255,0.3)`),
    }
  }

  // events
  public chartClicked({ event, active }: { event: MouseEvent; active: any[] }): void {}

  public chartHovered({ event, active }: { event: MouseEvent; active: any[] }): void {}

  openTooltip(positionX: number, positionY: number, chartDataGroups: ChartDataGroup[], labelIndex: number) {
    this.zone.run(() => {
      const portalComponent = this.buildPortalComponent(chartDataGroups, labelIndex);
      this.generalPopupService.createPopupOnMouse(positionX, positionY, portalComponent);
    });
  }

  closeTooltip() {
    this.generalPopupService.close();
  }

  private buildPortalComponent(chartDataGroups: any[], labelIndex: number): ComponentPortal<TooltipPopupComponent> {
    const injector: PortalInjector = this.getPortalInjector(chartDataGroups, labelIndex);
    return new ComponentPortal(TooltipPopupComponent, null, injector);
  }

  private getPortalInjector(chartDataGroups: ChartDataGroup[], labelIndex: number): PortalInjector {
    const injectionTokens: WeakMap<InjectionToken<string>, any> = new WeakMap();
    injectionTokens.set(CHART_TOOLTIP_ITEMS, chartDataGroups);
    injectionTokens.set(CHART_TOOLTIP_LABEL, this.data.labels[labelIndex]);
    injectionTokens.set(CHART_TOOLTIP_LABELINDEX, labelIndex);
    return new PortalInjector(this.injector, injectionTokens);
  }
}
