import { EResizeMode } from './../../../model/resource/template-resize-mode.enum';
import { ELayoutType } from './layout-type.enum';

export class Layout {
  private layoutType: ELayoutType;
  private resizeMode: EResizeMode;
  private type: string;

  public getType(): string {
    return this.type;
  }

  public setType(type: string): this {
    this.type = type;
    return this;
  }

  public getResizeMode(): EResizeMode {
    return this.resizeMode;
  }

  public setResizeMode(resizeMode: EResizeMode): this {
    this.resizeMode = resizeMode;
    return this;
  }

  /**
   * get layout type
   * @returns ELayoutType
   */
  public getLayoutType(): ELayoutType {
    return this.layoutType;
  }

  /**
   * set layout type
   * layoutType ELayoutType
   * @returns this
   */
  public setLayoutType(layoutType: ELayoutType): this {
    this.layoutType = layoutType;
    return this;
  }
}
