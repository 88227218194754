import { RxPush } from '@rx-angular/template/push';
import { RxLet } from '@rx-angular/template/let';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { TranslateModule } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { RxState } from '@rx-angular/state';
import { timer } from 'rxjs';
import { finalize, map, takeWhile } from 'rxjs/operators';
import { NotificationActions } from '../../data-access/notification.actions';
import { NotificationType } from '../../data-access/notification.type';

interface LocalState {
  text: string;
  id: string;
  title: string;
  duration: number;
  close$: string;
  durationLeft: number;
  type: NotificationType;
  expanded: boolean;
  params: { [key: string]: string };
}

const TIMER_INTERVAL = 500;

const INITIAL_STATE: LocalState = {
  text: '',
  id: '',
  duration: 5000,
  durationLeft: 5000,
  title: '',
  expanded: false,
  close$: '',
  type: NotificationType.info,
  params: null,
};

@Component({
  selector: 'notification-item',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatButtonModule, TranslateModule, MatProgressBarModule, RxLet, RxPush],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './notification-item.component.html',
  styleUrls: ['./notification-item.component.scss'],
  providers: [RxState],
})
export class NotificationItemComponent {
  @Input() set text(text: string) {
    this.state.set({ text });
  }
  @Input() set type(type: NotificationType) {
    this.state.set({ type });
  }
  @Input() set id(id: string) {
    this.state.set({ id });
  }
  @Input() set duration(duration: number) {
    this.state.set({ duration, durationLeft: duration });
    this.patchDuration(duration);
  }
  @Input() set params(params: { [key: string]: string }) {
    this.state.set({ params });
  }
  // @HostListener('mouseenter', ['$events'])
  // mouseenter() {
  //   console.log('Enter');
  // }

  // @HostListener('mouseleave', ['$events'])
  // mouseleave() {
  //   console.log('Leave');
  // }

  private readonly store: Store = inject(Store);
  private readonly state: RxState<LocalState> = inject(RxState<LocalState>);

  state$ = this.state.select();

  constructor() {
    this.state.set(INITIAL_STATE);
    this.patchDuration(7500);
  }

  close(id: string): void {
    this.store.dispatch(new NotificationActions.Close(id));
  }

  ngOnInit() {}

  patchDuration(duration: number) {
    this.state.connect(
      'durationLeft',
      timer(0, TIMER_INTERVAL).pipe(
        takeWhile(() => this.state.get('durationLeft') > 0),
        map((x) => this.state.get('duration') - x * TIMER_INTERVAL),
        finalize(() => this.close(this.state.get('id')))
      )
    );
  }

  toggle() {
    this.state.set({ expanded: !this.state.get('expanded') });
  }
}
