import { Clipboard } from '@angular/cdk/clipboard';
import {
  AfterViewInit,
  Component,
  DoCheck,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { EntryElementValue } from '@app-modeleditor/components/entry-collection/entry-element-value';
import { EDatatype } from 'frontend/src/dashboard/model/entry/datatype.enum';
import { EUnit } from 'frontend/src/dashboard/model/entry/unit.enum';

import { EEntryElementEvents } from '@app-modeleditor/components/entry-collection/entry-element-events.enum';
import { DefaultEntryElement } from '../core/default-entry-element';
import { NumericInput } from './numeric-input';
import { FloatParsing, IntegerParsing, NumberParsing, PercentParsing } from './parsing-strategy';

@Component({
  selector: 'app-numeric-input',
  templateUrl: './numeric-input.component.html',
  styleUrls: ['./numeric-input.component.scss'],
})
export class NumericInputComponent
  extends DefaultEntryElement
  implements OnInit, OnChanges, AfterViewInit, OnDestroy, DoCheck
{
  @Input()
  public entryElement: NumericInput;
  @Input() isSpreadsheetContext = false;

  @ViewChild('ref')
  protected elementRef: ElementRef;

  @Output()
  protected changes = new EventEmitter<NumericInput>();

  @Input()
  protected autofocus: boolean;
  protected readonly hideRequiredMarker: boolean = true;

  private parsingStrategy?: NumberParsing;
  private _fallbackInput?: string;
  private _entryValueBuffer: number | undefined;
  private readonly ENTRY_CHANGE_ID = 'NUMERIC_INPUT_COMPONENT';

  constructor(_formBuilder: UntypedFormBuilder, private clipboard: Clipboard) {
    super(_formBuilder);
  }

  private init() {
    // parsing strategy
    let ParsingStrategyT: any;

    if ([EDatatype.DOUBLE, EDatatype.FLOAT].includes(this.entryElement?.getDatatype())) {
      ParsingStrategyT = this.entryElement.getUnit() === EUnit.PERCENTAGE ? PercentParsing : FloatParsing;
    } else {
      // dataType === INTEGER or LONG or fallback
      ParsingStrategyT = IntegerParsing;
    }

    this.parsingStrategy = new ParsingStrategyT();
    this.parsingStrategy.init({
      min: this.entryElement.getLowerBound(),
      max: this.entryElement.getUpperBound(),
      step:
        this.entryElement.getUnit() === EUnit.PERCENTAGE
          ? this.entryElement.getStepWidth() / 100
          : this.entryElement.getStepWidth(),
    });

    // control and validation
    super.control.setValidators(
      [
        (control: AbstractControl) => this.parsingStrategy.validate(control.value),
        this.entryElement?.isRequired() ? Validators.required : undefined,
      ].filter((validator) => !!validator)
    );
    super.control.updateValueAndValidity();
    super.control.markAsDirty();

    this.entryElement.addEventListener(this.ENTRY_CHANGE_ID, EEntryElementEvents.VALUE_CHANGED, () => {
      this.handleInitialValue();
      this.updateInputByEntryValue();
    });

    this.handleInitialValue();
    this.updateInputByEntryValue();

    // entry element
    if (!(this.entryElement instanceof NumericInput)) {
      console.error(`NumericInput was expected but you provided something else`, this.entryElement);
    }
  }

  private updateInputByEntryValue(isInitialCall = false) {
    if (this.entryValue !== undefined) {
      this.entryValueBuffer = this.entryValue;
      this.correctAndWriteBackInput(this.parsingStrategy.valueToInput(this.entryValue), isInitialCall);
    }
  }

  /**
   * Invoked by input, also used for updating input by entry value.
   * Will correct the value and rewrite the corrected value to the input (User will not see
   * incorrect value). Will also repose cursor again.
   * @param input input string
   * @returns void
   */
  private correctAndWriteBackInput(input: string, isInitialCall = false) {
    if (!this.parsingStrategy || input === undefined) {
      return;
    }
    const keepSelectionFromTo = this.selectedFromTo;

    const correctedInput = this.parsingStrategy.correctOnTheFly(input, this.fallbackInput, false);
    this.input = correctedInput;

    if (!isInitialCall) {
      // don't manipulate selection on initial call
      this.selectedFromTo = this.parsingStrategy.correctSelectionFromTo(keepSelectionFromTo, input, correctedInput);
    }
  }

  // live cycle //////////////////////////////////////////////////

  public ngOnInit(): void {
    super.ngOnInit();
    this.init();
    this.entryElement?.setEdited(false);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    this.init();
  }

  /**
   * handles each rendering cycle
   * @returns void
   */
  public ngDoCheck(): void {
    if (this.isDisabled && !super.control.disabled) {
      super.control.disable();
    } else if (!this.isDisabled && super.control.disabled) {
      super.control.enable();
    }
  }

  public ngAfterViewInit(): void {
    this.handleInitialValue();

    this.entryElement?.setValid(super.control.valid || !super.control.invalid); // initial validation
    this.updateInputByEntryValue(true);

    if (this.entryElement.isAutoFocused() || this.autofocus) {
      this.elementRef?.nativeElement.focus();
    }
  }

  /**
   * handle components destruction
   * @returns void
   */
  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this.entryElement?.removeEventListener(this.ENTRY_CHANGE_ID);
    if (this.entryValueBuffer !== this.entryValue) {
      // emit changed value before closing
      this.entryValue = this.entryValueBuffer;
    }
  }

  // handlers ////////////////////////////////////////////////////

  /**
   * Invoked whenever the user inputs a value, in/decreases the value by button or mouse wheel.
   * Will invoke correctAndWriteBackInput to correct input value on the fly.
   * Will also update control and entryValueBuffer.
   * @param input input string
   * @returns void
   */
  protected onInput(input: string) {
    if (!this.parsingStrategy || input === undefined) {
      return;
    }

    this.correctAndWriteBackInput(input);

    const entryValueBuffer = this.input !== '' ? this.parsingStrategy.inputToValue(this.input) : null; // null = update, undefined = parsing error and not update
    if (entryValueBuffer !== undefined && entryValueBuffer !== this.entryValueBuffer) {
      this.entryValueBuffer = entryValueBuffer;
    }
  }

  /**
   * Synchronizes entryValueBuffer and entryValue of entryElement. Invoked getter will also emit a change.
   */
  protected onSynchronize() {
    if (this.entryValueBuffer === this.entryValue) {
      return;
    } // no change
    super.setValue(this.input); // updating control
    this.entryValue = this.entryValueBuffer;
  }

  /**
   * Handles each change of the input mask.
   * Invoked by super.setValue in this.onInput
   * @param modelInput string
   * @retrurns void
   */
  public onModelChanged(modelInput?: string): void {
    if (modelInput === undefined) return;
    if (modelInput !== this.input) {
      this.correctAndWriteBackInput(modelInput);
    }
  }

  /**
   * handles mousescroll event
   * @param e WheelEvent
   * @returns void
   */
  protected onWheel(e: WheelEvent): void {
    if (super.isDisabled || this.elementRef.nativeElement !== document.activeElement) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    this.onIncrementDecrement(e.deltaY < 0);
  }

  protected onKeyDown(e: KeyboardEvent): void {
    const [correctedDeletionInput, selectedFromTo] = this.parsingStrategy.getCorrectedDeletionInput(
      e.key,
      this.selectedFromTo,
      this.input
    );

    if (correctedDeletionInput) {
      this.selectedFromTo = selectedFromTo;
      this.onInput(correctedDeletionInput);
      e.preventDefault();
    }

    if (e.key === 'Enter' && this.entryValueBuffer !== this.entryValue) {
      // emit changed value on enter
      this.entryValue = this.entryValueBuffer;
      e.stopPropagation(); // stop propagation to prevent spreadsheet event listener
    }
  }

  protected onKeyUp(e: KeyboardEvent): void {
    if (e.ctrlKey && e.key === 'c') {
      this.clipboard.copy(this.clipboardValue);
    }
  }

  protected onIncrementDecrement(isIncrement: boolean): void {
    const incremented = this.parsingStrategy.incrementDecrement(this.entryValueBuffer, isIncrement);
    if (incremented) {
      this.onInput(this.parsingStrategy.formatValue(this.parsingStrategy.valueToInput(incremented)));
    }
  }

  protected onFocused(): void {
    super.control.markAsTouched();
  }

  private handleInitialValue(): void {
    let initValue = '';

    if (this.parsingStrategy instanceof PercentParsing) {
      initValue = this.parsingStrategy.valueToInput(this.entryValue);
    } else {
      initValue = this.entryValue?.toString();
    }

    initValue = (initValue ?? '').replace('.', ',');

    if (!this.isSpreadsheetContext) {
      // if not in spreadsheet context, format value (spreadsheet context already formats value)
      initValue = this.parsingStrategy.formatValue(initValue);
    }

    super.control.setValue(initValue, { emitEvent: false }); // set initial value
  }

  // getters and setters and delegate getters and setters

  /**
   * Buffers entry value until it is written into entryElement by delegate setter "entryValue" below.
   */
  public get entryValueBuffer(): number | undefined {
    return this._entryValueBuffer;
  }

  public set entryValueBuffer(value: number | undefined) {
    this._entryValueBuffer = value;
  }

  /**
   * Delegate for value of entryElement where the parsed input is written to. Setter will also emit the change.
   */
  private get entryValue(): number | undefined {
    const buffer = this.entryElement?.getValue<EntryElementValue>()?.getValue<number | undefined>();
    return isFinite(buffer) ? buffer : undefined;
  }

  private set entryValue(value: number | undefined) {
    const entryElemVal = this.entryElement?.getValue<EntryElementValue>();
    if (!entryElemVal) return;
    entryElemVal.setValue(value);
    this.entryElement?.setValid(super.control.valid || !super.control.invalid);
    this.entryElement?.setEdited(true);
    this.changes.emit(this.entryElement);
  }

  /**
   * Delegate for value of input element.
   */
  private get input() {
    return this.elementRef?.nativeElement?.value;
  }

  private set input(value: string) {
    if (!this.elementRef?.nativeElement) {
      return;
    }

    this._fallbackInput = value;
    this.elementRef.nativeElement.value = value;
  }

  /**
   * Explicitly set input (and not input value of manually set input element).
   * Used as fallback for correctOnTheFly.
   */
  public get fallbackInput(): string {
    return this._fallbackInput;
  }

  /**
   * Value copied to clipboard pressing Ctrl+C omitting thousand points.
   * It is based on the selected substring or the whole string if the cursor has no dedicated selection.
   */
  public get clipboardValue() {
    const [from, to] = this.selectedFromTo;

    return this.parsingStrategy.inputToClipboardValue(
      from === to ? `${this.input}` : `${this.input}`.substring(from, to)
    );
  }

  /**
   * Selection start and end position.
   * Needed for buffering those positions when input is corrected. Otherwise cursor would jump to end on every input.
   */
  private get selectedFromTo() {
    return [this.elementRef?.nativeElement?.selectionStart, this.elementRef?.nativeElement?.selectionEnd];
  }

  private set selectedFromTo([from, to]: [number, number]) {
    if (!this.elementRef?.nativeElement) {
      return;
    }
    this.elementRef.nativeElement.selectionStart = from;
    this.elementRef.nativeElement.selectionEnd = to;
  }

  /**
   * whether up-button is disabled or not
   * @returns boolean
   */
  protected get upDisabled(): boolean {
    return !this.parsingStrategy.incrementDecrement(this.entryValueBuffer, true);
  }

  /**
   * whether down-button is disabled or not
   * @returns boolean
   */
  protected get downDisabled(): boolean {
    return !this.parsingStrategy.incrementDecrement(this.entryValueBuffer, false);
  }
}
