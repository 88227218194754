import { Injectable, Type } from '@angular/core';
import { IndexPage } from '@app-modeleditor/components/index-register/index-page';
import { IndexRegister } from '@app-modeleditor/components/index-register/index-register';
import { ContentElementAdapter } from '@app-modeleditor/components/structure/content-element-adapter.service';
import { TemplateAdapter } from '@app-modeleditor/utils/template-factory.service';
import { Adapter } from '../../../core/adapter';

@Injectable({
  providedIn: 'root',
})
export class IndexRegisterAdapter implements Adapter<IndexRegister> {
  constructor(private _contentElementAdapter: ContentElementAdapter) {}

  adapt(item: any): IndexRegister {
    throw new Error('Method not implemented.');
  }
  inherit?<T extends IndexRegister>(type: Type<T>, item: any): T {
    return this._getData(this._contentElementAdapter.inherit<T>(type, item), item);
  }

  inheritFrom?<T extends IndexRegister>(scope: TemplateAdapter, type: Type<T>, item: any): T {
    return this._getData(this._contentElementAdapter.inheritFrom<T>(scope, type, item), item, scope);
  }

  private _getData<T extends IndexRegister>(element: T, item, scope?: TemplateAdapter): T {
    if (scope) {
      element.setIndexPages(item.indexPages.map((page) => scope.adapt(page)));
    }
    return element;
  }

  applyValues<T>(scope: TemplateAdapter, item: any, values: any): T {
    throw new Error('Method not implemented.');
  }
}
