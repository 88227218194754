import { GanttPatternData } from '../../patternHandler';
import { Pattern } from '../pattern.base';

/**
 * Class which handles the generation of a circle pattern.
 */
export class CirclesPattern extends Pattern {
  /**
   * Generates an SVG image containing the pattern and returns it as base64-encoded URL.
   * @param backgroundColor Background color of the pattern.
   * @param patternColor Color of the pattern.
   * @param strokeColor Stroke color of the pattern.
   * @param cx x-position of a circle.
   * @param cy y-position of a circle.
   * @param r Radius of a circle.
   * @returns SVG image containing the pattern as base64-encoded URL.
   */
  protected _getPatternDataString(
    backgroundColor: string,
    patternColor: string,
    strokeColor: string,
    cx: number,
    cy: number,
    r: number
  ): string {
    const pattern = this._patternBuilder.getSvgElement(this._patternHeight, this._patternWidth);
    this._patternBuilder.appendBackground(pattern, backgroundColor);
    this._patternBuilder.appendCircle(pattern, r, cx, cy, patternColor, strokeColor);
    return this._patternBuilder.convertSvgNodeIntoDataString(pattern);
  }

  /**
   * Generates an SVG image containing the pattern and returns it as {@link GanttPatternData} object.
   * @param backgroundColor Background color of the pattern.
   * @param patternColor Color of the pattern.
   * @param strokeColor Stroke color of the pattern.
   * @param cx x-position of a circle.
   * @param cy y-position of a circle.
   * @param r Radius of a circle.
   * @returns SVG image containing the pattern as {@link GanttPatternData} object.
   */
  public getPatternData(
    backgroundColor: string,
    patternColor: string,
    strokeColor: string,
    cx: number,
    cy: number,
    r: number
  ): GanttPatternData {
    return new GanttPatternData(
      this._getPatternDataString(backgroundColor, patternColor, strokeColor, cx, cy, r),
      this._patternHeight,
      this._patternWidth
    );
  }
}
