import { EGanttInstance, GanttDataShift } from '@gantt/public-api';
import { GanttTemplateData } from 'frontend/src/dashboard/gantt/helper/gantt';
import { takeUntil, takeWhile } from 'rxjs/operators';
import { GanttPredefinedSetting } from '../../predefined-setting';

const GanttSelectBlockActionRegistration = 'GanttSelectBlockActionRegistration';

export class GanttSelectBlockActionHandler extends GanttPredefinedSetting {
  public onInit(templateData: any, ganttResponseData: any): void {
    const ganttDiagram = this.ganttLibService.bestGantt;
    ganttDiagram
      .getShiftFacade()
      .shiftOnClick()
      .pipe(takeWhile(() => this.ganttLibService.isAlive))
      .subscribe(() => {
        this.ganttLibService.ngZone.run(() =>
          this.saveTemplateData(ganttDiagram.getSelectionBoxFacade().getSelectedShifts(), templateData)
        );
      });

    ganttDiagram
      .getSelectionBoxFacade()
      .afterSelection.pipe(takeUntil(ganttDiagram.onDestroy))
      .subscribe(() => {
        this.ganttLibService.ngZone.run(() =>
          this.saveTemplateData(ganttDiagram.getSelectionBoxFacade().getSelectedShifts(), templateData)
        );
      });
  }

  private saveTemplateData(selectedCanvasShifts: any[], templateData: GanttTemplateData): void {
    templateData.setSelectedValues(false, this.ganttLibService.backendToGanttOriginInputMapper);

    if (selectedCanvasShifts.length) {
      // add selected block id
      templateData.setSelectedBlock(
        {
          id: selectedCanvasShifts[0].id,
        },
        this.ganttLibService.backendToGanttOriginInputMapper
      );
      // ? templateData.selectedBlock.id = selectedCanvasShifts[0].id;

      // add all shifts
      const selectedShifts: GanttDataShift[] = [];

      for (const canvasShift of selectedCanvasShifts) {
        const foundShift = this.ganttLibService.ganttInstanceService
          .getInstance(EGanttInstance.SHIFT_DATA_FINDER)
          .getShiftById(
            this.ganttLibService.bestGantt.getDataHandler().getOriginDataset().ganttEntries,
            canvasShift.id
          );
        if (!foundShift?.shift) continue;
        selectedShifts.push(foundShift.shift);
      }
      // ? templateData.selectedValues = selectedShifts;
      templateData.setSelectedValues(selectedShifts, this.ganttLibService.backendToGanttOriginInputMapper);
    } else {
      templateData.setSelectedBlock({}, this.ganttLibService.backendToGanttOriginInputMapper);
      templateData.setSelectedValues(false, this.ganttLibService.backendToGanttOriginInputMapper);
    }
  }
}
