import { GanttPluginHandlerService } from 'frontend/src/dashboard/gantt/general/plugin/gantt-plugin-handler.service';
import { IGanttResponse } from '../../../gantt-response';

export class GanttDeleteEntriesResponse {
  constructor(private _ganttDiagram: any, private _pluginHandlerService: GanttPluginHandlerService) {}

  /**
   * Extracts new row data from response and adds the new rows to gantt.
   * Pays attention to shift overlapping algorithm.
   * Returns false if response has not block deletion.
   * @param response Server response.
   */
  public handleResponse(response: IGanttResponse): boolean {
    if (!response.deletedGanttEntries) return false;
    const deletedGanttEntries: string[] = response.deletedGanttEntries;

    this._ganttDiagram.getDataHandler().getDataRemover().removeRowsByIds(deletedGanttEntries); // remove from js gantt
    this._pluginHandlerService.getTemplateData().removeEntriesByIdFromHierarchicalPlan(deletedGanttEntries); // remove from hierarchicalPlan

    return true;
  }
}
