<div class="borderBottom">
  <div class="resourceListHeader">
    <div class="collapseButton" matRipple (click)="toggleCollapse()">
      <div class="collapse">
        <app-image *ngIf="!isViewCollapsed()" [label]="'keyboard_arrow_down'"></app-image>
        <app-image *ngIf="isViewCollapsed()" [label]="'keyboard_arrow_right'"></app-image>
      </div>
      <div class="icon">
        <app-image [label]="resourceGroup.icon"></app-image>
      </div>
      <div class="title">{{ resourceGroup.name }}</div>
    </div>

    <div class="options">
      <button
        mat-icon-button
        [matMenuTriggerFor]="selectVisibleResourceAttributesMenu"
        matTooltip="Attribute in Ressourcen anzeigen"
      >
        <app-image [label]="'visibility'"></app-image>
      </button>
    </div>
    <div class="options">
      <button mat-icon-button [matMenuTriggerFor]="groupByMenu" matTooltip="Ressourcen gruppieren">
        <app-image [label]="'filter_list'"></app-image>
      </button>
    </div>
  </div>

  <div *ngIf="!getCurrentSelectedAttribute() && !isViewCollapsed()" class="resourcesContainer">
    <app-resource-element
      *ngFor="let element of filteredElements"
      [element]="element"
      [visibleAttributes]="visibleResourceAttributes"
    ></app-resource-element>
  </div>

  <div class="attributeListGroupWrapper" *ngIf="getCurrentSelectedAttribute() && !isViewCollapsed()">
    <ng-container *ngFor="let group of groupByAttributeList">
      <div class="attributeHeader" matRipple (click)="group.collapsed = !group.collapsed">
        <div class="collapseAttribute">
          <app-image *ngIf="!group.collapsed" [label]="'keyboard_arrow_down'"></app-image>
          <app-image *ngIf="group.collapsed" [label]="'keyboard_arrow_right'"></app-image>
        </div>
        <span class="attributeHeadline">{{ group.attributeValue }}</span>
      </div>
      <div class="resourcesContainer" *ngIf="!group.collapsed">
        <app-resource-element
          *ngFor="let element of group.elements"
          [element]="element"
          [visibleAttributes]="visibleResourceAttributes"
        ></app-resource-element>
      </div>
    </ng-container>
  </div>
</div>

<mat-menu #selectVisibleResourceAttributesMenu="matMenu">
  <div (click)="$event.stopPropagation()">
    <div class="menuHeader">Attribute anzeigen</div>
    <ul class="checkBoxList">
      <li>
        <mat-checkbox
          [checked]="allResourceAttributesVisible"
          (change)="onSelectAllVisibleResourceAttributes($event)"
          [indeterminate]="intermediateStateOfVisibleResourceAttributes"
        >
          Alle
        </mat-checkbox>
      </li>
    </ul>
    <mat-divider></mat-divider>
    <ul class="checkBoxList">
      <li *ngFor="let attribute of attributes">
        <mat-checkbox
          [checked]="isAttributeSelected(attribute)"
          [value]="attribute"
          (change)="onVisibleResourceAttributeChange($event)"
        >
          {{ attribute }}
        </mat-checkbox>
      </li>
    </ul>
  </div>
</mat-menu>

<mat-menu #groupByMenu="matMenu">
  <span class="menuHeader">Gruppieren nach</span>
  <button
    [class.selectedMenuItem]="!getCurrentSelectedAttribute()"
    (click)="sortByAttribute(null, $event)"
    mat-menu-item
  >
    <span>Nicht gruppieren</span>
  </button>
  <mat-divider></mat-divider>
  <button
    *ngFor="let attribute of attributes"
    [class.selectedMenuItem]="attribute === getCurrentSelectedAttribute()"
    (click)="sortByAttribute(attribute, $event)"
    mat-menu-item
  >
    <span>{{ attribute }}</span>
  </button>
</mat-menu>
