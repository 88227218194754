import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AngularSplitModule } from 'angular-split';
import { ColumnLayoutComponent } from './column-layout.component';

@NgModule({
  imports: [CommonModule, AngularSplitModule],
  declarations: [ColumnLayoutComponent],
  exports: [ColumnLayoutComponent],
})
export class ColumnLayoutModule {}
