import { Injectable, Type } from '@angular/core';
import { TemplateAdapter } from '@app-modeleditor/utils/template-factory.service';
import { GlobalUtils } from 'frontend/src/dashboard/global-utils';
import { Adapter } from '../../../core/adapter';
import { ContentElementAdapter } from '../structure/content-element-adapter.service';
import { EntryElementFactory } from './../entry-collection/entry-factory.service';
import { SaxMSCalendar } from './cal';
import { CalendarEvent } from './calendar-event';
@Injectable({
  providedIn: 'root',
})
export class CalendarAdapter implements Adapter<SaxMSCalendar> {
  constructor(private entryElementFactory: EntryElementFactory, private contentElementAdapter: ContentElementAdapter) {}

  applyValues<T extends SaxMSCalendar>(scope: TemplateAdapter, item: T, values: any): T {
    const mapped: CalendarEvent[] = (values || []).map((value) => {
      return this.entryElementFactory
        .parseEntryValue<CalendarEvent>(CalendarEvent, value)
        .setTitle(value.displayText)
        .setBackgroundColor(value.color)
        .setTextColor(value.textColor)
        .setEditRestUrl(value.editRestUrl)
        .setDirectEditRestUrl(value.directEditRestUrl)
        .setUuid(value.uuid)
        .setStart(new Date(value.start))
        .setDeleteRestUrl(value.deleteRestUrl)
        .setEnd(new Date(value.end))
        .setEventId(value.eventId || GlobalUtils.generateUUID());
    });
    item.setValue(mapped);
    return item;
  }

  adapt(item: any): SaxMSCalendar {
    throw new Error('Method not implemented.');
  }

  inherit<T extends SaxMSCalendar>(type: Type<T>, data: any): T {
    const c: T = this.contentElementAdapter
      .inherit<T>(type, data)
      .setAddNewCalendarEntry(data.addNewCalendarEntry)
      .setEndDate(new Date(data.endDate))
      .setStartDate(new Date(data.startDate))
      .setNameRequired(data.nameRequired)
      .setSearchable(data.searchable)
      .setUseLocalTime(data.useLocalTime)
      .setValuesRestUrl(data.valuesRestUrl)
      .setCreateCustomTitle(data.createCustomTitle)
      .setCreateRestUrls(data.createRestUrls);
    return c;
  }

  inheritFrom<T extends SaxMSCalendar>(scope: TemplateAdapter, type: Type<T>, data: any): T {
    const c: T = this.contentElementAdapter
      .inheritFrom<T>(scope, type, data)
      .setAddNewCalendarEntry(data.addNewCalendarEntry)
      .setEndDate(new Date(data.endDate))
      .setStartDate(new Date(data.startDate))
      .setSearchable(data.searchable)
      .setUseLocalTime(data.useLocalTime)
      .setNameRequired(data.nameRequired)
      .setValuesRestUrl(data.valuesRestUrl)
      .setCreateCustomTitle(data.createCustomTitle)
      .setCreateRestUrls(data.createRestUrls);
    return c;
  }
}
