import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { MatLegacySlideToggleChange as MatSlideToggleChange } from '@angular/material/legacy-slide-toggle';
import { MatLegacySliderChange as MatSliderChange } from '@angular/material/legacy-slider';
import { SaxMsBestGanttSettings } from 'frontend/src/dashboard/gantt/gantt/saxms-best-gantt.settings';
import { EdgesPluginSettings } from 'frontend/src/dashboard/gantt/general/plugin/plugin-list/edges/edges-plugin-settings.enum';

interface IBlockConnectionOption {
  value: EdgesPluginSettings;
  viewValue: string;
}
@Component({
  selector: 'app-blocks',
  templateUrl: './blocks.component.html',
  styleUrls: ['./blocks.component.scss'],
})
export class BlocksComponent implements OnInit {
  @Input() settings: SaxMsBestGanttSettings;
  @Input() blockAttributes: string[] = [];
  @Output() changeEvent: EventEmitter<null> = new EventEmitter();

  protected blockHeight = 0;
  protected blockHeightLabel = '';
  protected selectionTransparency = 0.5;
  protected selectionTransparencyLabel = '';
  protected blockConnectionOptions: IBlockConnectionOption[] = [
    { value: EdgesPluginSettings.NONE, viewValue: 'GANTT.EDGE_SETTINGS.showNoConnections' },
    { value: EdgesPluginSettings.ONMOUSEOVER, viewValue: 'GANTT.EDGE_SETTINGS.onlyMouseOver' },
    { value: EdgesPluginSettings.ONMOUSEOVERTRANSITIV, viewValue: 'GANTT.EDGE_SETTINGS.onlyMouseOverTransitive' },
    { value: EdgesPluginSettings.ALWAYS, viewValue: 'GANTT.EDGE_SETTINGS.always' },
  ];
  protected selectedBlockConnectionOption: EdgesPluginSettings;
  protected selectedBlockSortingOption: string;
  protected blockSortingOptions: string[];
  private readonly inactiveBlockSortingOption: string = '----';

  constructor(private cdRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.blockHeight = this.settings.rowHeight / 2 - this.settings.shiftPadding;
    this.blockHeightLabel = this.blockHeight * 2 + ' px';
    this.selectionTransparency = 1 - this.settings.selectOpacity;
    this.selectionTransparencyLabel = Math.round(this.selectionTransparency * 100) + ' %';
    this.selectedBlockConnectionOption =
      this.blockConnectionOptions.find((x) => x.value === this.settings.ganttPluginSettings.edgePluginRendering)
        ?.value || this.blockConnectionOptions[3].value;
    this.selectedBlockSortingOption = this.blockAttributes.includes(this.settings.blockSortingAttribute)
      ? this.settings.blockSortingAttribute
      : this.inactiveBlockSortingOption;
    this.blockSortingOptions = [this.inactiveBlockSortingOption, ...this.blockAttributes];
  }

  protected blockHeightSliderChange(event: MatSliderChange) {
    this.blockHeight = event.value;
    this.blockHeightLabel = this.blockHeight * 2 + ' px';
    this.settings.shiftPadding = this.settings.rowHeight / 2 - event.value;
    this.cdRef.detectChanges();
    this.changeEvent.emit();
  }

  protected selectionTransparencyChange(event: MatSliderChange) {
    this.selectionTransparency = event.value;
    this.settings.selectOpacity = 1 - this.selectionTransparency;
    this.selectionTransparencyLabel = Math.round(this.selectionTransparency * 100) + ' %';
    this.cdRef.detectChanges();
    this.changeEvent.emit();
  }

  protected handleRoundedCorners(event: MatSlideToggleChange): void {
    this.settings.roundCorners = event.checked;
    this.changeEvent.emit();
  }

  protected setSelectionColor(color: string) {
    this.settings.selectColor = color;
    this.changeEvent.emit();
  }

  protected onBlockConnectionOptionChange(event: MatSelectChange) {
    this.settings.ganttPluginSettings.edgePluginRendering = event.value;
    this.changeEvent.emit();
  }

  protected onBlockSortingAttributeChange(selectionValue: string): void {
    this.settings.blockSortingAttribute = selectionValue === this.inactiveBlockSortingOption ? '' : selectionValue;
    this.changeEvent.emit();
  }
}
