import { Component, ContentChild, DestroyRef, ElementRef, Input, TemplateRef, ViewChild, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { EntryElement } from '@app-modeleditor/components/entry-collection/entry-element';
import { EEntryElementEvents } from '@app-modeleditor/components/entry-collection/entry-element-events.enum';
import { TemplateUnregisterService } from '@app-modeleditor/components/template/template-unregister.service';
import { TemplateService } from '@app-modeleditor/utils/template.service';
import { Template } from 'frontend/src/dashboard/model/resource/template';
import { EResizeMode } from 'frontend/src/dashboard/model/resource/template-resize-mode.enum';
import * as _ from 'lodash';
import { Subject, debounceTime } from 'rxjs';
import { ColumnLayout, ILayoutColumnHeights } from './column-layout';

@Component({
  selector: 'column-layout',
  templateUrl: './column-layout.component.html',
  styleUrls: ['./column-layout.component.scss'],
})
export class ColumnLayoutComponent {
  public _templates: Template[];
  public _layout: ColumnLayout;
  public fullscreen: boolean;
  public _gridOptimizedLayout: ColumnLayout; // with #noPlaceHolder for different cell-height
  private entryValuesChanged$ = new Subject<void>();
  private destroyRef = inject(DestroyRef);
  private readonly VALUE_CHANGED_ID = 'colLayoutWatcher';

  @Input() set templates(value: Template[]) {
    // unregister old template
    this._templates?.forEach((t) => {
      if (t instanceof EntryElement) {
        t.removeEventListener(this.VALUE_CHANGED_ID, EEntryElementEvents.VALUE_CHANGED);
      }
      this.$unregisterApi.unregister(t, t.getResourceId());
    });

    this._templates = value;

    // register new templates
    this._templates?.forEach((t: Template) => {
      if (t instanceof EntryElement) {
        t.addEventListener(this.VALUE_CHANGED_ID, EEntryElementEvents.VALUE_CHANGED, () => {
          this.entryValuesChanged$.next();
        });
      }
      this.templateApi.registerTemplate(t.getResourceId(), t);
    });
    this.createLayoutContentsArray();
  }
  @Input() set layout(value: ColumnLayout) {
    if (
      value.getContent()[0] &&
      (value.getContent()[0][0] == 'template.button.opendispositionorderrequests' ||
        value.getContent()[0][0] == 'template.button.createDispositionOrderRequest' ||
        value.getContent()[0][0] == 'template.button.userSettings')
    ) {
      const colHeights = { 0: [6] };
      value.setColumnHeights(colHeights);
    }
    this.createGridOptimizedLayout(value);
    this._layout = value;
    this.createLayoutContentsArray();

    this.fullscreen = value?.getResizeMode() === EResizeMode.FIT_PARENT ? true : false;
  }

  // [row][column] - id string
  public _layoutContentsIDs: string[][];
  private _gridColumnWidth: Array<string> = ['100%'];

  private static resizeHandlerWidth = 8; // px width
  public currentDragHandlerNum = 0; // 0 is none

  @ContentChild('contentElement') contentElement: TemplateRef<any>;
  @ViewChild('gridContainer') gridContainer: ElementRef<HTMLDivElement>;

  constructor(private templateApi: TemplateService, private $unregisterApi: TemplateUnregisterService) {
    this.handleValueChanges();
  }

  ngOnInit(): void {
    this._gridColumnWidth = this.getGridColumnWidth();
  }
  /**
   * get template by id
   * @param id string
   * @returns Template
   */
  public getTemplateById(id: string): Template {
    return (this._templates || []).find((t: Template) => t.getId() === id);
  }

  public getT(colIndex: number, rowIndex: number): Template {
    if (!this._layout.getContent()[colIndex]) {
      return undefined;
    }
    return this._layout
      .getContent()
      [colIndex].map((id: string) => this._templates.find((t: Template) => t.getId() === id))
      .filter((t: Template) => {
        return t?.isShow() ? true : false;
      })[rowIndex];
  }

  /**
   * get 'grid-template-columns' value for column-width
   * as array
   * @returns Array<String>
   */
  public getGridColumnWidth(): Array<string> {
    const columnWidthsString = [];

    for (let index = 0; index < this._layout.getColumnCount(); index++) {
      if (index >= 1 && this._layout.isResizeable()) {
        // resize-handle
        columnWidthsString.push(`${ColumnLayoutComponent.resizeHandlerWidth}px`);
      }

      if (this._layout.getColumnWidths()) {
        // widths available
        columnWidthsString.push(`${this._layout.getColumnWidthAtIndex(index)}%`);
      } else {
        // columnWidthsString.push("1fr");
        columnWidthsString.push(`${100 / this._layout.getColumnCount()}%`);
      }
    }
    return columnWidthsString;
  }

  /**
   * places PlaceHolder for different cell-heights
   * to prevent wrong visualisation
   * @param value original ColumnLayout
   */
  public createGridOptimizedLayout(value: ColumnLayout): void {
    const layoutTemp: ColumnLayout = _.cloneDeep(value);

    const columnHeights: ILayoutColumnHeights = layoutTemp.getColumnHeights();
    const newColumnHeights = _.cloneDeep(layoutTemp.getColumnHeights());
    if (columnHeights) {
      for (const rowIdx in columnHeights) {
        let addedHeightTotal = 1;
        columnHeights[rowIdx].forEach((height, index) => {
          const layoutContentTemp = layoutTemp.getContent();
          for (let i = 1; i < height; i++) {
            layoutContentTemp[rowIdx].splice(index + addedHeightTotal, 0, '#noPlaceHolder');
            newColumnHeights[rowIdx].splice(index + addedHeightTotal, 0, 0);
            layoutTemp.setContent(layoutContentTemp);
            addedHeightTotal++;
          }
        });
      }
    }
    layoutTemp.setColumnHeights(newColumnHeights);
    this._gridOptimizedLayout = layoutTemp;
  }

  /**
   * create an "row x column"-String array
   * array contains final grid layout
   * - checks, if Content is available
   * @param layout Layout
   */
  public createLayoutContentsArray(): void {
    const layoutContentTemp = new Array<Array<string>>();
    const maxRows = this._maxRows();

    for (let rowIndex = 0; rowIndex < maxRows; rowIndex++) {
      const columnTemp = new Array<string>();
      for (let colIndex = 0; colIndex < this._gridOptimizedLayout.getColumnCount(); colIndex++) {
        try {
          const cellTemp = this.getT(colIndex, rowIndex);
          if (cellTemp) {
            columnTemp.push(cellTemp.getId());
          } else {
            if (!(this._gridOptimizedLayout.getContent()[colIndex][rowIndex] == '#noPlaceHolder')) {
              columnTemp.push(null);
            } else {
              // no Placeholder, top element uses space from top down
              columnTemp.push('#noPlaceHolder');
            }
          }
        } catch (error) {
          // no further elements in Column
        }
      }
      layoutContentTemp.push(columnTemp);
    }

    this.layoutContentsIDs = layoutContentTemp;
  }

  // RESIZE
  /**
   * starts dragging/resizing movement
   * @param handlerNr selected Handler
   */
  public startResizerDrag(handlerNr: number): void {
    this.currentDragHandlerNum = handlerNr;
    this.gridContainer.nativeElement.style.cursor = 'col-resize';
  }

  /**
   * ends dragging/resizing movement
   */
  public endDrag(): void {
    this.currentDragHandlerNum = 0;
    this.gridContainer.nativeElement.style.gridTemplateColumns = this.gridColumnWidth;
    this.gridContainer.nativeElement.style.cursor = 'auto';
  }

  /**
   * calculates column width while moving resize-handler
   */
  public onDrag(event: MouseEvent): void {
    if (this.currentDragHandlerNum == 0) return;
    event.preventDefault();
    const handlePos = this.currentDragHandlerNum + (this.currentDragHandlerNum - 1);

    const gridEle = this.gridContainer.nativeElement;

    const eleBefore = gridEle.children[handlePos - 1];
    const eleAfter = gridEle.children[handlePos + 1];

    const eleBeforeWidth =
      event.clientX - ColumnLayoutComponent.resizeHandlerWidth / 2 - eleBefore.getBoundingClientRect().left;
    const eleAfterWidth = eleBefore.clientWidth - eleBeforeWidth + eleAfter.clientWidth;

    if (eleBeforeWidth <= 0 || eleAfterWidth <= 0) return;
    this._gridColumnWidth[handlePos - 1] = eleBeforeWidth + 'px';
    this._gridColumnWidth[handlePos + 1] = eleAfterWidth + 'px';

    gridEle.style.gridTemplateColumns = this.gridColumnWidth;
  }

  _maxRows(log = false): number {
    let max = 0;
    for (let i = 0; i < this._layout.getColumnCount(); i++) {
      const l: number = this._layout.getContent()[i]?.filter((id: string) => {
        const t: EntryElement = ((this._templates as EntryElement[]) || []).find((t: EntryElement) => t.getId() === id);
        return t?.isShow() ? true : false;
      }).length;
      max = l > max ? l : max;
    }

    return max;
  }

  /**
   * Handles the value changes of the entry values.
   * Subscribes to the entryValuesChanged$ observable and triggers the creation of the layout contents array
   * Necessary, as it can happen that the arrangement of the EntryElements
   * in the layout changes due to the value change and the visibilityConditions.
   */
  private handleValueChanges(): void {
    this.entryValuesChanged$.pipe(takeUntilDestroyed(this.destroyRef), debounceTime(100)).subscribe(() => {
      this.createLayoutContentsArray();
    });
  }

  // GETTER & SETTER

  get rows(): number[] {
    return Array.from({ length: this._maxRows() });
  }

  get columns(): number[] {
    return Array.from({ length: this._gridOptimizedLayout.getColumnCount() });
  }
  get gridColumnWidth(): string {
    return this._gridColumnWidth.map((c) => c.toString()).join(' ');
  }

  set layoutContentsIDs(layout: string[][]) {
    this._layoutContentsIDs = layout;
  }
  get layoutContentsIDs(): string[][] {
    return this._layoutContentsIDs;
  }
}
