<div
  class="filterchips-list-wrapper"
  [ngStyle]="{
    width: twoRowLayout ? containerWidth + 'px' : '100%',
    height: twoRowLayout ? '36px' : 'auto'
  }"
  *ngIf="filterChips?.length > 0"
>
  <mat-chip-list>
    <ng-container *ngFor="let filterItem of filterChips; let i = index">
      <mat-chip
        [matTooltip]="filterItem.label"
        [ngStyle]="{
          'border-color': filterItem.borderColor ? filterItem.borderColor : 'grey',
          'background-color': filterItem.color ? filterItem.color : 'grey',
          'margin-bottom': '2px',
          'margin-top': twoRowLayout ? '-2px' : '0px'
        }"
        [matTooltipShowDelay]="500"
        [ngClass]="{ 'two-row-layout': twoRowLayout }"
        class="filter"
        removable="true"
        (removed)="removeFilter(node, filterItem)"
      >
        <div class="filterChip-content">
          <mat-icon *ngIf="filterItem.sortOrder === sortOrder.asc"> arrow_upward </mat-icon>
          <mat-icon *ngIf="filterItem.sortOrder === sortOrder.desc"> arrow_downward </mat-icon>
          *{{ filterItem.label }}
        </div>
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
    </ng-container>
  </mat-chip-list>
</div>
