import { Action } from '@app-modeleditor/components/button/action/action';
import { EntryElement } from '@app-modeleditor/components/entry-collection/entry-element';
import { GanttLibService } from 'frontend/src/dashboard/gantt/gantt/gantt-lib.service';
import { GeneralGanttActionHandler } from 'frontend/src/dashboard/gantt/general/action-handling/action-handler';
import { TriggerEventFactory } from 'frontend/src/dashboard/gantt/general/gantt-actions/event-factory';
import { GanttExternalActionRegistration } from 'frontend/src/dashboard/gantt/general/gantt-actions/external-action-registration';
import { GanttEventTrigger } from 'frontend/src/dashboard/gantt/general/gantt-actions/external-event-trigger';
import { GanttPluginHandlerService } from 'frontend/src/dashboard/gantt/general/plugin/gantt-plugin-handler.service';
import { GanttResponseHandler } from 'frontend/src/dashboard/gantt/general/response/response-handler';
import { MenuItem } from 'frontend/src/dashboard/view/template-toolbar/menu-item';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { TriggerEventShiftCreatorFactory } from './event-factory';

export class GanttShiftCreatorActionHandler implements GanttExternalActionRegistration {
  private readonly _indicatorIdentifier = 'GanttShiftCreatorActionHandler_IndicatorIdentifier';

  private currentActionTriggers: GanttEventTrigger[] = [];
  private triggerEventFactory: TriggerEventFactory;
  private blockCreationGroupIds: string[] = [
    // ids to find menu groups of block creation
    'template.submenugroup.createcarreservation',
    'template.submenugroup.blockcreator_shift_manage',
    'template.submenugroup.blockcreator',
  ];

  constructor(
    private _ganttPluginHandlerService: GanttPluginHandlerService,
    private _ganttLibService: GanttLibService,
    private shiftCreator: any,
    private actionHandler: GeneralGanttActionHandler,
    private responseHandler: GanttResponseHandler,
    templateData: any,
    shiftCreatorPluginData: any
  ) {
    this.triggerEventFactory = new TriggerEventShiftCreatorFactory(templateData);
    this.currentActionTriggers = this.extractEventTriggersByGanttActions(shiftCreatorPluginData.ganttActions);
    this.shiftCreator.onDragEndCallback('deselectButtons', () => {
      this._toggleCreateButtonByLocalAction(this.shiftCreator.isInCreationMode());
    });
  }

  public destroy(): void {
    this.shiftCreator.onDragEndCallbackRemove('deselectButtons');
  }

  /**
   * Local action handling to switch plugin state to (de-)activate edit mode.
   * @param localAction Local action which will be executed.
   * @param callBackParams
   */
  public handleLocalAction(localAction, callBackParams?: any): Observable<any> {
    if (localAction.actionType == 'PREDEFINED') {
      switch (localAction.id) {
        case 'togglepluginmodecreate':
          this.shiftCreator.toggleCreateShiftMode();
          const actionPlugInTargetId = localAction?.actionPlugInTargetIds?.length
            ? localAction.actionPlugInTargetIds[0]
            : null;
          this._ganttPluginHandlerService.getTemplateData().setCreatorCanonicalName(actionPlugInTargetId);
          this._toggleCreateButtonByLocalAction(this.shiftCreator.isInCreationMode(), localAction);
          return of(true);
      }
    }
    return of(null);
  }

  /**
   * Extracts and registers local and predefined actions by backend definition.
   * @param ganttIntervalActions Backend definiton of actions.
   */
  private extractEventTriggersByGanttActions(ganttShiftCreatorActions: any[]): GanttEventTrigger[] {
    let triggerList: GanttEventTrigger[] = [];

    triggerList = this.triggerEventFactory.getTriggerEventByActionTypes(
      this._ganttPluginHandlerService,
      this._ganttLibService,
      ganttShiftCreatorActions,
      this.shiftCreator,
      this.actionHandler,
      this.responseHandler
    );

    return triggerList;
  }

  /**
   * Activates or deactivates the visual selection of the button witch has triggered the given local action.
   */
  private _toggleCreateButtonByLocalAction(isActive: boolean, localAction?: Action) {
    // iterate over menu items
    for (const menuItem of this.actionHandler.newToolbar.getMenuItems()) {
      // iterate over menu groups
      for (const group of menuItem.getToolbarGroups()) {
        if (this.blockCreationGroupIds.includes(group.getId().toLowerCase())) {
          // if group is part of blockingIntervalMenuGroupIds array
          // iterate over menu group items
          for (const element of group.getEntryElements()) {
            const elem = element as any;
            const localActions = elem.localActions as Action[];
            for (const action of localActions) {
              if (localAction && JSON.stringify(localAction) === JSON.stringify(action)) {
                // check if local action parameter is also in button element
                of(null)
                  .pipe(delay(0))
                  .subscribe(() => {
                    element.setSelected(isActive); // set if active
                    this._registerIndicatorOnEntryElement(menuItem, element, isActive);
                  });
                break;
              } else if (!localAction) {
                // if no local action is defined
                of(null)
                  .pipe(delay(0))
                  .subscribe(() => {
                    element.setSelected(isActive); // set if active
                    this._registerIndicatorOnEntryElement(menuItem, element, isActive);
                  });
              }
            }
          }
        } else {
          continue;
        }
      }
    }
  }

  /**
   * (De-)Registers an indicator for a specific entry element in a specific menu item.
   * @param menuItem Menu item to register the indicator for (not neccesary for deregistration).
   * @param entryElement Entry element to (de-)register the indicator for.
   * @param register Specifies whether to register or to deregister the entry element (default = true).
   * @param updateIndicators Specifies wherther to update the indicators after applying the changes or not (default = true).
   */
  private _registerIndicatorOnEntryElement(
    menuItem: MenuItem,
    entryElement: EntryElement,
    register = true,
    updateIndicators = true
  ): void {
    if (register) {
      this._ganttPluginHandlerService.toolbarIndicatorService.addIndicatorRegistration(
        this._indicatorIdentifier,
        menuItem,
        entryElement.getId()
      );
    } else {
      this._ganttPluginHandlerService.toolbarIndicatorService.removeIndicatorRegistration(
        this._indicatorIdentifier,
        entryElement.getId()
      );
    }
    if (updateIndicators) this._ganttPluginHandlerService.toolbarIndicatorService.updateIndicatorRegistrations();
  }
}
