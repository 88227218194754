import { OnDestroy, ViewChild, HostListener, Component, Input, OnInit } from '@angular/core';
import { takeWhile } from 'rxjs/operators';
import { SaxMsSpreadSheetColumn } from '../saxms-spreadsheet';
import { SaxmsColumnHeaderTemplateService } from './saxms-column-header-template.service';
import { ESortDirection } from '../../model/table-sort-filter';
import { MatLegacyTooltip as MatTooltip } from '@angular/material/legacy-tooltip';

export interface SaxmsColumnHeaderIconSet {
  sortAsc: string;
  sortDesc: string;
  filter: string;
  filterAsc: string;
  filterDesc: string;
  svgIcons: boolean;
}

@Component({
  selector: 'app-saxms-column-header-template',
  templateUrl: './saxms-column-header-template.component.html',
  styleUrls: ['./saxms-column-header-template.component.css'],
})
export class SaxmsColumnHeaderTemplateComponent implements OnInit, OnDestroy {
  private alive;
  private enabledRemoveCallback = false;
  public columnIndex = -1;
  public tableId = '';
  public showTooltip = true;
  @Input() public column: SaxMsSpreadSheetColumn;
  @ViewChild('labelTooltip') labelTooltip: MatTooltip;

  // @HostListener('document:wheel', ['$event']) onwheel(event: Event) {
  //   this.hideTooltip();
  // }

  public iconSet: SaxmsColumnHeaderIconSet = {
    sortAsc: 'keyboard_arrow_down',
    sortDesc: 'keyboard_arrow_up',
    filter: 'filter_list',
    filterAsc: 'cloud_download',
    filterDesc: 'cloud_upload',
    svgIcons: false,
  };

  constructor(public filterIconService: SaxmsColumnHeaderTemplateService) {
    this.alive = true;
  }

  ngOnInit(): void {
    this.filterIconService
      .getCurrentSortFilterObjectByColumn()
      .pipe(takeWhile(() => this.alive))
      .subscribe((column) => {
        if (column && column.data === this.columnIndex && this.tableId === column.filterSortObject.getTableId()) {
          this.column = column;
        }
      });

    // this.tooltipService.getTooltipState().pipe(takeWhile(() => this.alive)).subscribe(showTooltip => this.showTooltip = showTooltip);
  }

  mouseDownSortFilterBtn() {
    this.enabledRemoveCallback = true;
  }

  ngOnDestroy(): void {
    this.alive = false;
    this.hideTooltip();
  }

  removeSortFilter() {
    if (!this.enabledRemoveCallback) {
      return;
    }
    this.column.filterSortObject.setFilterActive(false);
    this.column.filterSortObject.setSortActive(false);
    this.column.filterSortObject.setSortDirection(ESortDirection.NONE);
    this.filterIconService.removeSortFilterObject(this.column);
    this.enabledRemoveCallback = false;
  }

  hideTooltip() {
    if (this.labelTooltip) {
      this.labelTooltip.hide();
    }
  }

  onMouseLeave() {
    this.hideTooltip();
  }
}
