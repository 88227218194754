<ng-container *ngIf="!DISABLED && template?.getFileUploadActions()?.length > 0">
  <ng-container *ngIf="!DISABLED">
    <form class="upload-trigger" enctype="multipart/form-data">
      <input
        webkitdirectory
        mozdirectory
        #uploadFolderTrigger
        directory=""
        name="datei[]"
        type="file"
        multiple
        (change)="filesChanged($event)"
      />

      <input #uploadFileTrigger name="datei[]" type="file" multiple (change)="filesChanged($event)" />
    </form>

    <!-- <mat-icon *ngIf="displayIconOnly && !currentUploadStatus; else defaultBtnTemp" (click)="openFileDialog($event)"
      [matTooltip]="'Datei(en) hochladen'" [matTooltipShowDelay]="750" class="file-upload-action">file_upload
    </mat-icon> -->
    <template-slot *ngIf="displayButton && !currentUploadStatus" [source]="uploadCollection"></template-slot>
    <!-- <ng-template #uploadStatus>
      <span class="uploadStatus">{{currentUploadStatus}}</span>
    </ng-template> -->
  </ng-container>
</ng-container>
<div
  class="file-uploader"
  (dragover)="onDragOver($event)"
  (dragleave)="onDragLeave($event)"
  (dragenter)="onDragEnter($event)"
  (drop)="onDrop($event)"
  *ngIf="displayDragAndDrop && !DISABLED && (showDropzone || isProcessing)"
  #fileWrapper
  [class.drag-over]="dragOver"
>
  <div class="text-wrapper" [class.small]="fileWrapper.clientHeight < 50">
    <span class="text">
      <ng-container *ngIf="fileWrapper.clientHeight >= 50">
        {{ (isProcessing ? 'lädt...' : 'FILE.UPLOAD.drag') | translate }}
        <br />
      </ng-container>
      <mat-icon>{{ 'cloud_upload' }}</mat-icon>
    </span>
  </div>
</div>
