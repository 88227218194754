import { HttpErrorResponse, HttpEventType, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ERequestMethod, RequestOptions, RequestService } from '@app-modeleditor/request.service';
import { CloudMessagingService } from '@core/notification/cloud-messaging.service';
import { Notification } from '@core/notification/notification';
import { ENotificationType } from '@core/notification/notification-type.enum';
import { GlobalUtils } from 'frontend/src/dashboard/global-utils';
import { WindowService } from 'frontend/src/dashboard/view/window/window.service';
import { Observable, firstValueFrom } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { FileProgress } from '../file-progress/file-progress';
import { FileProgressService } from '../file-uploader/file-progress.service';
import { EFileLoadingType, EFileState } from '../file-uploader/file-state.enum';
import { FileData } from '../file-uploader/filte-data';
import { ProgressDialogService } from '../file-uploader/progress-dialog/progress-dialog.service';
import { getContentType, getFilename, saveFile } from './file-download-helper';

@Injectable({
  providedIn: 'root',
})
export class FileDownloadService {
  constructor(
    private fcm: CloudMessagingService,
    private requestApi: RequestService,
    private _fileProgressApi: FileProgressService,
    private progressDialogService: ProgressDialogService,
    private windowService: WindowService
  ) {
    this.fcm.getMessage().subscribe(async (message: Notification) => {
      if (message?.getType() === ENotificationType.FILE_DOWNLOAD_NOTIFICATION) {
        if (!this.windowService.isWindow() && !document.hasFocus()) {
          // this is the main window without focus
          const isAnyWindowActive = await firstValueFrom(this.windowService.checkIfAnyWindowHasFocus());
          if (!isAnyWindowActive) {
            // no window has focus, trigger download in main window
            this.downloadFile(message.getFileDownloadRestUrl()).subscribe();
          }
        } else if (document.hasFocus()) {
          // trigger download in active window
          this.downloadFile(message.getFileDownloadRestUrl()).subscribe();
        }
      }
    });
  }

  private updateFileInProgress(fileData: FileData): void {
    const fileProgress: FileProgress = this._fileProgressApi.getFileProgressObject();
    fileData.setLoadingType(EFileLoadingType.DOWNLOAD);
    fileProgress.addFile(fileData.getId(), fileData);
    fileProgress.setNumberOfFilesToTransfer(Object.keys(fileProgress.getFiles()).length);
    this._fileProgressApi.setFileProgress(fileProgress);
  }

  private removeFileBytes(fileData: FileData) {
    const fp = this._fileProgressApi.getFileProgressObject();
    fp.setNumberOfTransferedBytes(fp.getNumberOfTransferedBytes() - fileData.getTransferedBytes());
    fp.setNumberOfBytesToTransfer(fp.getNumberOfBytesToTransfer() - fileData.getSize());
    fp.setNumberOfFilesTransfered(fp.getNumberOfFilesTransfered() + 1);
    this._fileProgressApi.setFileProgress(fp);
  }

  /**
   * download file by url
   * @param uri url
   * @returns Observable<HttpResponse<any>
   */
  public downloadFile(
    uri: string,
    body = undefined,
    actionMethod: ERequestMethod = undefined
  ): Observable<HttpResponse<any>> {
    this.progressDialogService.openProgressDialog();

    const fileData: FileData = new FileData().setName('FILE.GENERAL.download').setId(GlobalUtils.generateUUID());

    this.updateFileInProgress(fileData);
    let transferedBytes = 0;

    const options: any = {
      responseType: 'blob' as 'json',
      observe: 'events',
      reportProgress: true,
      body,
    };
    // Process the file downloaded
    return new Observable<any>((observer) => {
      this.requestApi
        .call(actionMethod || ERequestMethod.GET, `rest/${uri}`, new RequestOptions().setHttpOptions(options))
        .pipe(
          catchError((e) => {
            fileData.setState(EFileState.FAILED);
            this.updateFileInProgress(fileData);

            this.removeFileBytes(fileData);
            return this.parseErrorBlob(e).pipe(
              tap((httpError) => {
                observer.error(httpError);
                observer.complete();
              })
            );
          })
        )
        .subscribe((document: any) => {
          if (document.type === HttpEventType.Response) {
            const finalDownloadSize = document.body?.size;
            const filename = getFilename(document);
            fileData.setName(filename);

            if (finalDownloadSize) {
              // set size only if it is known -> sometimes initial size is not known
              fileData.setSize(finalDownloadSize);
              fileData.setTransferedBytes(finalDownloadSize);
            }

            fileData.setState(EFileState.COMPLETED);
            const contentType = getContentType(document);
            saveFile(document.body, filename, contentType);

            observer.next(document.body);
            observer.complete();
          } else if (document.type === HttpEventType.ResponseHeader) {
            const filename = getFilename(document);
            fileData.setName(filename);
            fileData.setState(EFileState.LOADING);
            this.updateFileInProgress(fileData);
          } else if (document.type === HttpEventType.DownloadProgress) {
            const newBytes: number = document.loaded - transferedBytes;
            transferedBytes = document.loaded;
            fileData.setTransferedBytes(transferedBytes);
            if (isNaN(fileData.getSize())) {
              fileData.setSize(document.total || 0);
              this._fileProgressApi.setFileProgress(
                this._fileProgressApi
                  .getFileProgressObject()
                  .setNumberOfBytesToTransfer(
                    this._fileProgressApi.getFileProgressObject().getNumberOfBytesToTransfer() + (document.total || 0)
                  )
              );
            }
            fileData.setState(document.total === document.loaded ? EFileState.COMPLETED : EFileState.LOADING);
            this.updateFileInProgress(fileData);
            this._fileProgressApi.setFileProgress(
              this._fileProgressApi
                .getFileProgressObject()
                .setNumberOfTransferedBytes(
                  this._fileProgressApi.getFileProgressObject().getNumberOfTransferedBytes() + newBytes
                )
            );
          }
        });
    });
  }

  /**
   * parse error response with blob to human readable format
   * @param {HttpErrorResponse} err error to parse
   * @returns Observable<HttpErrorResponse>
   */
  public parseErrorBlob(err: HttpErrorResponse): Observable<HttpErrorResponse> {
    return new Observable((observer: any) => {
      const reader: FileReader = new FileReader();
      reader.onloadend = (e) => {
        const resultingError: HttpErrorResponse = new HttpErrorResponse({
          error: JSON.parse(reader.result as any),
        });

        observer.next(resultingError);
        observer.complete();
      };

      reader.readAsText(err.error);
    });
  }

  /**
   * load file into memory by url
   * @param uri url
   * @returns Observable<{ blob: any; filename: string }>
   */
  public loadFile(uri: string): Observable<{ blob: Blob; filename: string }> {
    const options: any = { responseType: 'blob', observe: 'response' };
    // Process the file downloaded
    return this.requestApi.call(ERequestMethod.GET, `rest/${uri}`, new RequestOptions().setHttpOptions(options)).pipe(
      map((document: any) => {
        const filename = getFilename(document);
        return { blob: document.body, filename: filename };
      })
    );
  }
}
