import { EGanttScrollContainer } from '../scroll-container.enum';
import { NodeProportionsState } from './node-proportion-state';
import { NodeProportionsStateConnector } from './node-proportion-state-connector';
import { INodeProportion } from './node-proportion.interface';

/**
 * Helper class to use {@link NodeProportionsState} without the need to specify the scroll container id for every method call.
 * Extends the functionality of {@link NodeProportionsStateConnector} with general calculation methods.
 */
export class NodeProportionsStateCalculator extends NodeProportionsStateConnector {
  /**
   * @param nodeProportionsState
   * @param scrollContainerId
   */
  constructor(
    nodeProportionsState: NodeProportionsState,
    scrollContainerId: EGanttScrollContainer = EGanttScrollContainer.DEFAULT
  ) {
    super(nodeProportionsState, scrollContainerId);
  }

  /**
   * Calculates the proportions of all shift viewports combined and returns them.
   * @returns Proportions of all shift viewports combined.
   */
  public getShiftViewportOverlayProportions(): INodeProportion {
    let height = 0;

    for (const scrollContainerId of Object.values(EGanttScrollContainer)) {
      height += this._nodeProportionsState.getShiftViewPortProportions(scrollContainerId).height;
    }

    return { width: this.getShiftViewPortProportions().width, height: height };
  }
}
