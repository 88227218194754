import { Component, OnInit } from '@angular/core';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { GanttLibService } from 'frontend/src/dashboard/gantt/gantt/gantt-lib.service';
import { GanttPluginHandlerService } from 'frontend/src/dashboard/gantt/general/plugin/gantt-plugin-handler.service';
import { ResourcesDataService } from '../resources-data.service';

@Component({
  selector: 'app-resources-toolbar',
  templateUrl: './resources-toolbar.component.html',
  styleUrls: ['./resources-toolbar.component.scss'],
})
export class ResourcesToolbarComponent implements OnInit {
  public searchInput = '';
  public selectedAttributeSearchValue = '';

  constructor(
    public resourcesDataService: ResourcesDataService,
    private _ganttLibService: GanttLibService,
    private _pluginHandlerService: GanttPluginHandlerService
  ) {}

  ngOnInit(): void {
    this.searchInput = this.resourcesDataService.currentActiveResourceFilter.query;
    this.selectedAttributeSearchValue = this.resourcesDataService.currentActiveResourceFilter.attribute;
  }

  public searchForEntry(event: KeyboardEvent): void {
    this.resourcesDataService.resourceFilterQuery = this.searchInput;
  }

  public isViewEnabled(groupId: string): boolean {
    return this.resourcesDataService.getCurrentListViewByGroupId(groupId)?.collapsed;
  }

  public onViewToggle(event: MatCheckboxChange, groupId: string): void {
    this.resourcesDataService.setCollapsedByGroupId(groupId, event.checked);
  }

  public onSearchAttributeSelectionChange(): void {
    this.resourcesDataService.resourceFilterAttribute = this.selectedAttributeSearchValue;
  }

  public clearSearch(): void {
    this.searchInput = '';
    this.resourcesDataService.resourceFilterQuery = '';
  }
}
