import { Injectable, Type } from '@angular/core';
import { StructuralAdapter } from '@app-modeleditor/components/structure/structural-factory.service';
import { TemplateAdapter } from '@app-modeleditor/utils/template-factory.service';
import { Adapter } from '@core/adapter';
import { ContentPart } from './content-part';

@Injectable({
  providedIn: 'root',
})
export class ContentPartAdapter implements Adapter<ContentPart> {
  constructor(private _structuralAdapter: StructuralAdapter) {}

  adapt(item: Record<string, any>): ContentPart {
    throw new Error('Method not implemented.');
  }
  inherit?<T extends ContentPart>(type: Type<T>, item: Record<string, any>): T {
    const t: T = this._getData(this._structuralAdapter.inherit<T>(type, item), item);
    return t;
  }

  inheritFrom?<T extends ContentPart>(scope: TemplateAdapter, type: Type<T>, item: Record<string, any>): T {
    const t: T = this._getData(this._structuralAdapter.inheritFrom<T>(scope, type, item), item, scope);
    return t;
  }
  applyValues<T>(scope: TemplateAdapter, item: Record<string, any>, values: Record<string, any>): T {
    throw new Error('Method not implemented.');
  }

  private _getData<T extends ContentPart>(element: T, item: Record<string, any>, scope?: TemplateAdapter): T {
    const t: T = element.setDisplayContentpartContainer(item.displayContentpartContainer).setExpanded(item.expanded);

    if (scope) {
      element.setContentElements((item.contentElement || item.contentElements || []).map((ce) => scope.adapt(ce)));
    }

    return t;
  }
}
