import { inject, Injectable, Injector, ProviderToken } from '@angular/core';
import { EPredefinedAction } from '@app-modeleditor/components/button/action/predefined-action.enum';
import { concat, from, Observable, ObservedValueOf, of, Subject, throwError } from 'rxjs';
import { finalize, switchMap, tap } from 'rxjs/operators';
import { Logger } from '../../utils/logger';
import { ActionClass, ExecutionParams, FunctionType, getAction, ID } from './actions';

export interface ActionTupel<T extends ID> {
  id: T;
  params: ExecutionParams<T>;
}

export type ExecuteResult<T extends ID> = Observable<ObservedValueOf<ReturnType<ActionClass<T>['execute']>>>;
export type ExecuteMultipleResult<T extends ID[]> = Observable<{
  [I in keyof T]: ObservedValueOf<ReturnType<ActionClass<T[I]>['execute']>>;
}>;

export type LengthOfTuple<T extends unknown[]> = T extends { length: infer L } ? L : never;
export type DropFirstInTuple<T extends unknown[]> = ((...args: T) => unknown) extends (
  arg: unknown,
  ...rest: infer U
) => unknown
  ? U
  : T;
export type LastInTuple<T extends any[]> = T[LengthOfTuple<DropFirstInTuple<T>>];
// type Last<T extends any[]> = T extends [...any[], infer R] ? R : never;

interface TypedResult {
  type: EPredefinedAction;
}

export type StrictActionTupel<C extends ID[]> = { [I in keyof C]: ActionTupel<C[I]> };

@Injectable({
  providedIn: 'root',
})
export class ActionService {
  private readonly logger = new Logger('actions');
  private readonly injector = inject(Injector);

  afterRequest$ = new Subject<ID>();
  beforeRequest$ = new Subject<ID>();

  customActionListener = new Subject<any>();

  /**
   * whether the id is known by the service or not
   * @param {ID} id unique id of the action
   * @returns boolean
   */
  public hasValidAction<T extends ID>(id: T): boolean {
    return getAction(id) ? true : false;
  }

  /**
   * executes multiple actions and returns last value in chain
   * @param {{ [I in keyof C]: ActionTupel<C[I]> }} actions list of actions
   * @returns ExecuteResult<LastInTuple<C>>
   */
  public executeActions<C extends ID[]>(...actions: { [I in keyof C]: ActionTupel<C[I]> }): ExecuteMultipleResult<C> {
    if (!actions || actions.length === 0) {
      return of(void 0);
    }
    const items = actions.map((a) => this.executeAction(a.id, ...a.params));
    const obs = concat(...items);
    return obs;
  }

  /**
   * executes a single action bei checking for necessary services
   * @param {ID} id unique id of the action
   * @returns Observable<T>
   */
  public executeAction<T extends ID>(id: T, ...args: ExecutionParams<T>): ExecuteResult<T> {
    // inform subscribers that a new action will be executed
    this.beforeRequest$.next(id);
    return from(this.getService(id)).pipe(
      tap((s) => this.logger.log(s.type, ...args)),
      switchMap((s) => s.execute.bind(s, ...args)() as FunctionType<T>),
      switchMap((r) => this.checkResult(r)),
      // inform observers that action was successfully executed
      finalize(() => this.afterRequest$.next(id))
    );
  }

  /**
   * checks result and throws error in some cases
   * @param {T} result generic result
   * @returns Observable<T>
   */
  private checkResult<T extends TypedResult>(result: T): Observable<T> {
    return result?.type === EPredefinedAction.CANCEL_LIGHTBOX ? throwError(() => result) : of(result);
  }

  /**
   * retreives service based on passed action id
   * @param {ID} id unique action id
   * @returns Promise<ActionClass<T>>
   */
  private async getService<T extends ID>(id: T): Promise<ActionClass<T>> {
    const actionSet = getAction(id);
    if (!actionSet) {
      return null;
    }

    const s = await actionSet.loadService();
    const token = s as ProviderToken<ActionClass<T>>;
    return this.injector.get<ActionClass<T>>(token);
  }
}
