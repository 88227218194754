import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { RxState } from '@rx-angular/state';
import { selectSlice } from '@rx-angular/state/selections';
import { RxLet } from '@rx-angular/template/let';
import { RxPush } from '@rx-angular/template/push';
import { map } from 'rxjs/operators';

interface LocalState {
  uri: SafeUrl;
  data: Error;
}

@Component({
  standalone: true,
  imports: [MatDialogModule, CommonModule, MatButtonModule, TranslateModule, RxLet, RxPush],
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styles: [``],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RxState],
})
export default class DialogComponent {
  protected data$ = this.state.select(
    selectSlice(['data']),
    map(({ data }) => data)
  );

  protected downloadUri$ = this.data$.pipe(map((data) => this.generateDownloadUri(data)));

  constructor(
    private sanitizer: DomSanitizer,
    private state: RxState<LocalState>,
    @Inject(MAT_DIALOG_DATA) protected data: Error
  ) {
    this.state.set({ data });
  }

  private generateDownloadUri(data: Error): SafeUrl {
    const json = JSON.stringify(data);
    const blob = new Blob([json], { type: 'text/json' });
    const url = window.URL.createObjectURL(blob);
    const uri: SafeUrl = this.sanitizer.bypassSecurityTrustUrl(url);
    return uri;
  }
}
