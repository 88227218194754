import { Component, ContentChild, ElementRef, Input, OnInit } from '@angular/core';
import { ContentPart } from './content-part';
@Component({
  selector: 'app-content-part',
  templateUrl: './content-part.component.html',
  styleUrls: ['./content-part.component.scss'],
})
export class ContentPartComponent implements OnInit {
  @Input('contentPart') cp: ContentPart;
  @ContentChild('elementsInner') elements: any;
  @Input() root: HTMLElement;
  @Input() contentId: string;
  @Input() resourceId: string;

  isExpanded = true;
  constructor(public elementRef: ElementRef) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}
}
