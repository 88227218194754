import { EntryElement } from '@app-modeleditor/components/entry-collection/entry-element';
import { EntryElementValue } from '@app-modeleditor/components/entry-collection/entry-element-value';
import { EFieldType } from '@app-modeleditor/components/entry-collection/field-type.enum';
import { takeUntil } from 'rxjs/operators';
import { SaxMsBestGanttSettings } from '../../../gantt/saxms-best-gantt.settings';
import { Gantt_General } from '../../general.gantt.component';
import { GanttEssentialPlugIns } from '../../plugin/e-gantt-essential-plugins';
import { GanttIndexCardsPlugIn } from '../../plugin/plugin-list/index-cards/index-cards.plugin';

export class ShowAttributeKeyOnIndexCardsElement extends EntryElement {
  constructor(private scope: Gantt_General) {
    super();
  }

  get(data: any): this {
    const indexCardPlugin = this.scope.ganttPluginHandlerService.getPlugInById(
      GanttEssentialPlugIns.IndexCardBuilderPlugIn
    ) as GanttIndexCardsPlugIn;
    const initialValue =
      this.scope.ganttSettingsService.getGanttSettings().showIndexCardAttributeNames ||
      indexCardPlugin?.isShowAttributeName() ||
      true;

    this.setName('Attributnamen einblenden')
      .setFieldType(EFieldType.SLIDE_TOGGLE)
      .setAlwaysEnabled(true)
      .onChanges((val: EntryElementValue) => {
        indexCardPlugin?.setShowAttributeName(val.getValue());
        this._saveValue(val.getValue());
      })
      .setValue(new EntryElementValue().setValue(initialValue));

    // listen to new backend settings
    this.scope.ganttSettingsService
      .onNewSettings()
      .pipe(takeUntil(this.scope.onDestroy))
      .subscribe((settings: SaxMsBestGanttSettings) => {
        if (!settings) return;
        const value = settings.showIndexCardAttributeNames;
        // apply new setting
        indexCardPlugin?.setShowAttributeName(value);
        this.setValue(new EntryElementValue().setValue(value));
      });

    return this;
  }

  /**
   * Saves the selected value into settings and to backend.
   */
  private _saveValue(value: boolean) {
    this.scope.ganttSettingsService.changeSettings({ showIndexCardAttributeNames: !!value });
    this.scope.ganttSettingsService.saveSettings().subscribe();
  }
}
