import { NgZone } from '@angular/core';
import {
  BestGantt,
  EGanttInstance,
  GanttDataContainer,
  GanttEditPermissions,
  GanttInstanceService,
} from '@gantt/public-api';
import { SaxMsBestGanttDiagramSettingsIntegrator } from '../general/gantt-settings/saxms-best-gantt-settings.integrator';
import { SaxMsBestGanttOutputHandler } from './saxms-best-gantt.handler.component';
import { SaxMsBestGanttInitialZoomHandler, SaxMsBestGanttSettings } from './saxms-best-gantt.settings';

/**
 * Class which handles BestGantt building and update.
 */
export class SaxMsBestGanttDiagramHandler {
  public ganttExistsFlag = false;
  public ganttBuilder: BestGantt;
  public initZoomHandler: SaxMsBestGanttInitialZoomHandler;
  public settingsIntegrator: SaxMsBestGanttDiagramSettingsIntegrator;

  constructor(ganttBuilder: BestGantt, private _ngZone: NgZone, private _ganttInstanceService: GanttInstanceService) {
    this.ganttBuilder = ganttBuilder;
    this.initZoomHandler = new SaxMsBestGanttInitialZoomHandler(ganttBuilder);
    this.settingsIntegrator = new SaxMsBestGanttDiagramSettingsIntegrator(ganttBuilder, this._ganttInstanceService);
  }

  /**
   * Builds gantt diagram.
   * If gantt does already exist, it will be updated with given data.
   * @param data Data to render gantt.
   * @param restrictions Edit restrictions.
   * @param ganttSettings Settings, which should be integrated into gantt.
   * @param outputHandler Handler to emit data outside of gantt component.
   * @param customPlugIns List of custom plugins to integrate into plugin handler.
   */
  public build(
    data: GanttDataContainer,
    restrictions: GanttEditPermissions,
    ganttSettings: SaxMsBestGanttSettings,
    outputHandler: SaxMsBestGanttOutputHandler
  ): void {
    if (!data || !this.ganttBuilder) return;

    if (this.ganttExistsFlag) {
      this.update(data);
    } else {
      this._ngZone.runOutsideAngular((_) => this.ganttBuilder.build(data)); // register events outside angular
      this.applyRestrictions(restrictions);

      // add initial zoom to gantt
      this.initZoomHandler.executeInitialZoom(ganttSettings);

      // add restriction data and marker plugin
      // this.pGanttBuilder.getShiftTranslator().getShiftTranslationAreaLimiter().setRowTranslationRestrictionFull(shiftDragRestrictions);
      this.ganttBuilder.addPlugIn(
        'rowLimiterMarker',
        this._ganttInstanceService.getInstance(EGanttInstance.GANTT_ROW_LIMITER_MARKER)
      );

      outputHandler.ganttBuilder.emit(this.ganttBuilder);

      this.ganttExistsFlag = true;
    }

    this.updateSettings(ganttSettings);
  }

  public applyRestrictions(restrictions: GanttEditPermissions): void {
    this.ganttBuilder.allowShiftDragDrop(restrictions.edit_allow_move || restrictions.edit_allow_changeRow || false);
    this.ganttBuilder.allowDraggingVertical(restrictions.edit_allow_changeRow || false);
    this.ganttBuilder.allowDraggingHorizontal(restrictions.edit_allow_move || false);
    this.ganttBuilder.allowShiftResizer(restrictions.edit_allow_resize || false);
    this.ganttBuilder.allowRowDrag(restrictions.edit_allow_row_drag || false);
    this.ganttBuilder.allowClicks(restrictions.edit_allow_clicks ?? true);
  }

  /**
   * Integrates gantt settings into current gantt.
   * @param ganttSettings Gantt settings container.
   */
  public updateSettings(ganttSettings: SaxMsBestGanttSettings): void {
    this.settingsIntegrator.integrateByStyleSettingsContainer(ganttSettings);
  }

  /**
   * Inserts given data into gantt.
   * @param data New gantt data set.
   */
  public update(data: GanttDataContainer): void {
    if (!this.ganttExistsFlag) return;
    this.ganttBuilder.updateAllShifts(data, true);
  }
}
