<ng-container [ngSwitch]="widgetType">
  <!-- EntryCollection -->
  <ng-container *ngSwitchCase="'CHARTS'">
    <charts [widget]="widget"></charts>
  </ng-container>
  <ng-container *ngSwitchCase="'GANTT'">
    <!-- use generalized gantt instead -->
  </ng-container>
  <ng-container *ngSwitchCase="'TABLES'">
    <widgetTable [widget]="widget"></widgetTable>
  </ng-container>
  <ng-container *ngSwitchCase="'COMBINE'">
    <!-- use generalized concept instead -->
  </ng-container>
  <ng-container *ngSwitchCase="'TEMPLATE'">
    <app-template-widget [widget]="widget"></app-template-widget>
  </ng-container>
  <!-- Default -->
  <ng-container *ngSwitchDefault>
    <strong>{{ widgetType }}</strong> {{ 'NOT_IMPLEMENTED.widget' | translate }}
  </ng-container>
</ng-container>
