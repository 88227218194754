import * as d3 from 'd3';
import { Observable, Subject, takeUntil } from 'rxjs';
import { EGanttTextStrategy } from '../../../data-handler/data-structure/data-structure-enums';
import { EGanttScrollContainer } from '../../../html-structure/scroll-container.enum';
import { BestGantt } from '../../../main';
import { GanttShiftsBuildingCanvasPrint } from '../../../shifts/shift-build-strategies/shift-build-canvas-print';
import { IndexCardExecuter } from '../../index-card/index-card-executer';
import { IndexCardTextOutsideBG } from '../../index-card/text-creator/text-creator-outside-bg';
import { GanttXAxisZoomTransformator } from '../../time-manipulator/x-axis-zoom-transformator-executer';
import { GanttTimePeriodGroupExecuter } from '../../timeperiod-marker/executer-timeperiod-marker-group';
import { GanttTimePeriodShiftBuildingPrintEFW } from '../../timeperiod-marker/period-building-strategies/period-building-print-efw';
import { IGanttPrintViewStrategie } from './printing-strategie-interface';

/**
 * @implements {IGanttPrintViewStrategie}
 * @class
 */
export class GanttPrintViewStrategieEFW implements IGanttPrintViewStrategie {
  ganttDiagram: BestGantt;
  printViewActive: boolean;
  dayWidth: number;
  UUID: string;

  private _onDestroySubject: Subject<void> = new Subject<void>();

  constructor() {
    /**
     * @type {BestGantt}
     */
    this.ganttDiagram = null;
    this.printViewActive = false;
  }

  /**
   * @override
   */
  update() {
    const s = this;
    if (s.ganttDiagram) {
      for (const scrollContainerId of Object.values(EGanttScrollContainer)) {
        const yAxis = s.ganttDiagram.getYAxisFacade().getYAxisBuilder(scrollContainerId);
        if (yAxis.getCanvas()) {
          const upd = yAxis.getCanvas().selectAll('.gantt_y-Axis-rows-bg');
          upd.attr('width', s.ganttDiagram.getNodeProportionsState().getYAxisProportions().width);
        }
      }
    }
  }

  /**
   * @override
   */
  printView(ganttDiagram) {
    const s = this;
    if (!ganttDiagram) return;
    s.ganttDiagram = ganttDiagram;
    if (s.printViewActive) s._transformEFWBack();
    else s._transformEFW();
  }

  public destroy(): void {
    this._onDestroySubject.next();
    this._onDestroySubject.complete();
  }

  private _transformEFWBack() {
    const s = this;
    return;
  }

  private _transformEFW() {
    const s = this;
    s.printViewActive = !s.printViewActive;
    s.applyCSSClasses();

    // update timeFormat
    s.ganttDiagram.getConfig().setXAxisScrollBarHeight(0);
    s.ganttDiagram.getConfig().setXAxisScrollBarPadding(0);
    s.ganttDiagram.getConfig().setTextOverlayShadowOffset(0);

    const zoomTransformatorPlugs = s.ganttDiagram.getPlugInHandler().getPlugInsOfType(GanttXAxisZoomTransformator);
    if (zoomTransformatorPlugs.length == 1) {
      zoomTransformatorPlugs[0].setFormatToTimeSpan(
        {
          value: 55,
          time: [
            {
              format: '%b',
              ticks: [d3.timeMonth, 1],
              cssText: 'x_axis_text_top',
              height: 24,
              fontSize: 15,
              tickSize: 24,
              tickSizeText: 6,
              renderVerticalLinesOnShifts: true,
              unit: 'MONTH',
            },
            {
              format: '%V',
              ticks: [d3.timeMonday, 1],
              cssText: 'x_axis_text_center',
              height: 20,
              fontSize: 11,
              tickSize: 24,
              tickSizeText: 6,
              unit: 'CALENDAR_WEEK',
            },
            {
              format: '%a',
              ticks: [d3.timeDay, 1],
              cssText: 'x_axis_text_bottom',
              height: 15,
              fontSize: 5.5,
              tickSize: 15,
              tickSizeText: 6,
              renderVerticalLinesOnShifts: true,
              unit: 'DAY',
            },
            {
              format: '%d',
              ticks: [d3.timeDay, 1],
              cssText: 'x_axis_text_bottom',
              css: 'invisible-horizontalLine',
              fontSize: 5.5,
              height: 15,
              tickSize: 0,
              tickSizeText: 1,
              unit: 'DAY',
            },
          ],
        },
        86400000,
        s.dayWidth
      );
    }
    s.ganttDiagram.getGlobalTimeSpanMarker().addDay(new Date().getTime(), 'tomato', 0, null, 1003);
    s.ganttDiagram.getShiftFacade().changeTextOverlayStrategy(EGanttTextStrategy.SHOW_ALWAYS_LABELS_BG);
    s.ganttDiagram.getConfig().setTextOverlayShadowEnabled(false);

    const indexCardPlugs = s.ganttDiagram.getPlugInHandler().getPlugInsOfType(IndexCardExecuter);
    for (let i = 0; i < indexCardPlugs.length; i++) {
      indexCardPlugs[i].changeBuildingStrategie(new IndexCardTextOutsideBG());
    }

    // move Vertical Lines Behind timePeriods.
    const lines = s.ganttDiagram
      .getShiftFacade()
      .getCanvasBehindShifts()
      .select<SVGGElement>('.gantt_vertical-line-group_print')
      .node();
    const secondChild = s.ganttDiagram.getShiftFacade().getCanvasBehindShifts().node().firstChild.nextSibling;
    s.ganttDiagram.getShiftFacade().getCanvasBehindShifts().node().insertBefore(lines, secondChild);

    // change Strategies of all the timePeriodBuilders
    const timePeriodExecuterCardPlugs = s.ganttDiagram
      .getPlugInHandler()
      .getPlugInsOfType(GanttTimePeriodGroupExecuter);
    if (timePeriodExecuterCardPlugs.length == 1) {
      const group = timePeriodExecuterCardPlugs[0];
      const allExecs = group.getAllIntervals();
      for (const key in allExecs) {
        const exec = allExecs[key];
        const builder = exec.getMarkedPeriodBuilder();
        builder.creator.changeShiftBuildingStrategy(new GanttTimePeriodShiftBuildingPrintEFW());
      }
    }

    const config = s.ganttDiagram.getConfig();
    config.setHorizontalLinesVisible(false);
    config.css.yAxis.text_item = 'gantt_y-Axis-text_item_print';

    // make shiftnames black
    s.ganttDiagram.getTextOverlay().removeAllText();
    config.css.shifts.text_overlay_item = 'gantt-text-overlay-item_print';

    // get rid of the brush
    const xAxisWrapper = s.ganttDiagram.getHTMLStructureBuilder().getXAxisContainer();
    xAxisWrapper.node().style.height = s.ganttDiagram.getConfig().xAxisContainerHeight() + 'px';

    // remove yAxisHead Buttons (set invisible), update Title, add x axis description in y AxisHead
    const yAxisHead = s.ganttDiagram.getYAxisHeadBuilder();
    yAxisHead.removeAll();

    // color y Axis BG White
    for (const scrollContainerId of Object.values(EGanttScrollContainer)) {
      const yAxis = s.ganttDiagram.getYAxisFacade().getYAxisBuilder(scrollContainerId);
      yAxis.setRowColor('#ffffff');
      yAxis.reBuildList(s.ganttDiagram.getDataHandler().getYAxisDataset());
    }
    s._addXAxisDescriptionToYAxisHead();

    // resize y Axis Width
    s.ganttDiagram.getHTMLStructureBuilder().getSizeHandler().changeDivSize(100);
    s.ganttDiagram.update();
    s.ganttDiagram.updateYAxis();
    s.ganttDiagram.update();
    s.ganttDiagram.updateYAxis(); // Redundancy neccessary, update changes stuff that updateY needs, and then we have to update again.

    s.ganttDiagram.getConfig().setYAxisWidth(100);

    // Add Black Background to y Axis for black Lines

    const canvasHeightMap = s.ganttDiagram.getRenderDataHandler().getYAxisDataFinder().getRowHeightsCombined();
    for (const scrollContainerId of Object.values(EGanttScrollContainer)) {
      const yAxis = s.ganttDiagram.getYAxisFacade().getYAxisBuilder(scrollContainerId);
      yAxis
        .getCanvas()
        .insert('rect', ':first-child')
        .attr('width', s.ganttDiagram.getNodeProportionsState().getYAxisProportions().width)
        .attr('height', canvasHeightMap.get(scrollContainerId))
        .attr('class', 'gantt_y-Axis-rows-bg');
    }

    s.ganttDiagram
      .getOpenRowHandler()
      .afterShiftRowOpen.pipe(takeUntil(this.onDestroy))
      .subscribe(() => s._reRenderBlackYAxisBar());
    // s.ganttDiagram.setCallbackAfterShiftRowOpen("reRenderBlackYAxisBackBarPrint" + this.UUID, s._reRenderBlackYAxisBar.bind(s))

    // pull timePeriods onto xAxis (weekends bottom 2, dayindicator all)
    s.ganttDiagram.getGlobalTimeSpanMarker().extendPeriodToXAxis(true);

    // build shifts efw style
    s.ganttDiagram.getShiftFacade().getShiftBuilder().changeShiftBuildingStrategy(new GanttShiftsBuildingCanvasPrint());
    s.ganttDiagram.update();

    // build vertical yAxis Border line
    d3.select(s.ganttDiagram.getShiftFacade().getShiftBuilder().getCanvasInFrontShifts().node())
      .append('line')
      .attr('x1', function () {
        return 0;
      })
      .attr('y1', 0)
      .attr('x2', function () {
        return 0;
      })
      .attr('y2', function () {
        return s.ganttDiagram
          .getShiftFacade()
          .getShiftBuilder()
          .getCanvasInFrontShifts()
          .node()
          .getBoundingClientRect().height;
      })
      .style('stroke-width', 2)
      .style('stroke', 'black');

    // update x axis labels as wished
  }

  /**
   * Rerender the yAxis Bg Bar after RowOpen.
   */
  private _reRenderBlackYAxisBar() {
    const canvasHeightMap = this.ganttDiagram.getRenderDataHandler().getYAxisDataFinder().getRowHeightsCombined();
    for (const scrollContainerId of Object.values(EGanttScrollContainer)) {
      const yAxis = this.ganttDiagram.getYAxisFacade().getYAxisBuilder(scrollContainerId);
      const upd = yAxis.getCanvas().selectAll('.gantt_y-Axis-rows-bg');
      upd.attr('height', canvasHeightMap.get(scrollContainerId));
    }
  }

  private _addXAxisDescriptionToYAxisHead() {
    const s = this;
    const yAxisHead = s.ganttDiagram.getYAxisHeadBuilder();
    yAxisHead.addTextLine('gantt_y-Axis-head-textLine', null, 'Month', null);
    yAxisHead.addTextLine('gantt_y-Axis-head-textLine', null, 'Week', null);
    yAxisHead.addTextLine('gantt_y-Axis-head-textLine', null, 'Day', null);
  }

  applyCSSClasses() {
    const s = this;
    const xAxisCSS: any = s.ganttDiagram.getParentNode().getElementsByClassName('gantt_x-axis');
    for (const element of xAxisCSS) {
      element.classList.add('gantt_x-axis_print');
      element.classList.remove('gantt_x-axis');
    }

    const horizontalLines: any = s.ganttDiagram.getParentNode().getElementsByClassName('gantt_horizontal-line-group');
    for (const element of horizontalLines) {
      element.classList.add('gantt_horizontal-line-group_print');
      element.classList.remove('gantt_horizontal-line-group');
    }

    const verticalLines: any = s.ganttDiagram.getParentNode().getElementsByClassName('gantt_vertical-line-group');
    for (const element of verticalLines) {
      element.classList.add('gantt_vertical-line-group_print');
      element.classList.remove('gantt_vertical-line-group');
    }
    return;
  }

  setOldClassesBack() {
    const s = this;
    const xAxisCSS: any = s.ganttDiagram.getParentNode().getElementsByClassName('gantt_x-axis_print');
    for (const element of xAxisCSS) {
      element.classList.remove('gantt_x-axis_print');
      element.classList.add('gantt_x-axis');
    }

    const yAxisText: any = s.ganttDiagram.getParentNode().getElementsByClassName('gantt_y-Axis-text_item_print');
    for (const element of yAxisText) {
      element.classList.remove('gantt_y-Axis-text_item_print');
      element.classList.add('gantt_y-Axis-text_item');
    }

    const horizontalLines: any = s.ganttDiagram
      .getParentNode()
      .getElementsByClassName('gantt_horizontal-line-group_print');
    for (const element of horizontalLines) {
      element.classList.remove('gantt_horizontal-line-group_print');
      element.classList.add('gantt_horizontal-line-group');
    }

    const verticalLines: any = s.ganttDiagram.getParentNode().getElementsByClassName('gantt_vertical-line-group_print');
    for (const element of verticalLines) {
      element.classList.remove('gantt_vertical-line-group_print');
      element.classList.add('gantt_vertical-line-group');
    }
  }

  //
  // OBSERVABLES
  //

  /**
   * Observable which gets triggered when the instance gets destroyed.
   */
  private get onDestroy(): Observable<void> {
    return this._onDestroySubject.asObservable();
  }
}
