<div class="chat-container" #wrapper>
  <!-- title -->
  <div class="headline">
    <mat-toolbar
      >{{ template.getName() }}
      <span class="spacer"></span>
      <button mat-icon-button class="close-btn" (click)="close()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar>
  </div>

  <!-- contains messages itself -->
  <div class="chat-wrapper" #messageContainer>
    <ng-container *ngIf="messages.length === 0">
      <div class="no-messages">{{ 'no entries' | translate }}</div>
    </ng-container>
    <app-message-item
      *ngFor="let message of messages"
      [message]="message"
      [showEdit]="!!template.getEditMessageURL()"
      [showDelete]="!!template.getDeleteMessageURL()"
      (onEdit)="editMessage($event)"
      (onDelete)="deleteMessage($event)"
    >
    </app-message-item>
    <div class="separator"></div>
  </div>
  <!-- area contains input to send message -->
  <div class="sendtext-input-area">
    <mat-form-field class="chat-form">
      <input matInput #chatField (keyup.enter)="sendMessage(chatField)" [placeholder]="'CHAT.MESSAGE' | translate" />
    </mat-form-field>
    <button
      [saxmsTooltip]="'BUTTON.confirm' | translate"
      mat-icon-button
      [disabled]="disabled"
      (click)="sendMessage(chatField)"
    >
      <mat-icon>send</mat-icon>
    </button>
  </div>
</div>
