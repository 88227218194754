import * as d3 from 'd3';
import { Observable, Subject } from 'rxjs';
import { GanttConfig } from '../config/gantt-config';
import { ShiftDataFinder } from '../data-handler/data-finder/shift-data-finder';
import { YAxisDataFinder } from '../data-handler/data-finder/yaxis-data-finder';
import { DataHandler } from '../data-handler/data-handler';
import { GanttCanvasShift, GanttDataRow } from '../data-handler/data-structure/data-structure';
import { DataManipulator } from '../data-handler/data-tools/data-manipulator';
import { GanttUtilities } from '../gantt-utilities/gantt-utilities';
import { NodeProportionsStateConnector } from '../html-structure/node-proportion-state/node-proportion-state-connector';
import { EGanttScrollContainer } from '../html-structure/scroll-container.enum';
import { BestGantt } from '../main';
import { TimeGradationHandler } from '../time-gradation-handler/time-gradation-handler';
import { VerticalScrollHandler } from '../vertical-scroll/vertical-scroll-handler';
import { GanttXAxis } from '../x-axis/x-axis';

/**
 * Handler for selecting shifts via drawing a box inside shift canvas.
 * @keywords selection, selector, shift, mark, choose, box, selectionbox
 */
export class GanttElementSelector {
  private _nodeProportionState: NodeProportionsStateConnector;

  private _selectionBoxStartGroup: d3.Selection<SVGGElement, undefined, d3.BaseType, undefined>;
  private _selectionBoxDraggingGroup: d3.Selection<SVGGElement, undefined, d3.BaseType, undefined>;
  private _selectionBox: d3.Selection<SVGPolygonElement, undefined, d3.BaseType, undefined>;

  private _selectedShifts: GanttCanvasShift[] = [];

  timeGradation: TimeGradationHandler;
  selectionBoxProportions: ElementSelectorProportions;
  boxRules: SelectionBoxParameters;
  enabled: boolean;
  scrollDuringDragTimeout: any;
  currentMouseX: number;
  currentMouseY: number;
  isInDragMode: boolean;

  private _onDragStartSubject = new Subject<SelectionBoxDragEvent>();
  private _beforeDragUpdateSubject = new Subject<ElementSelectorProportions>();
  private _onDragUpdateSubject = new Subject<ElementSelectorProportions>();
  private _afterSelectionSubject = new Subject<SelectionBoxDragEvent>();
  private _afterDeselectionSubject = new Subject<void>();
  private _afterSingleShiftSelectionSubject = new Subject<GanttCanvasShift>();
  private _afterSingleShiftDeselectionSubject = new Subject<GanttCanvasShift[]>();

  constructor(private _ganttDiagram: BestGantt, private readonly _scrollContainerId: EGanttScrollContainer) {
    this._nodeProportionState = new NodeProportionsStateConnector(
      this._ganttDiagram.getNodeProportionsState(),
      this._scrollContainerId
    );

    this._selectionBox = null;
    this.timeGradation = null;

    this.selectionBoxProportions = new ElementSelectorProportions();

    /** @type {SelectionBoxParameters} */
    this.boxRules = null;

    this.enabled = true;

    this.scrollDuringDragTimeout = null;
    this.currentMouseX = 0;
    this.currentMouseY = 0;
    this.isInDragMode = false;
  }

  setEnable(bool) {
    const s = this;
    s.enabled = bool;
  }

  /**
   * Initialize function to add a drag listener into the shift canvas and register drag events.
   * @keywords init, initial, selection, box, selectionbox, shifts
   */
  initSelectionBox() {
    const s = this;
    this.timeGradation = new TimeGradationHandler(s._xAxisBuilder);

    s._selectionBoxStartGroup = s._canvasInFront.append('g').attr('class', 'gantt-selection-box-start');

    s._selectionBoxStartGroup
      .append('rect')
      .attr('width', '100%')
      .attr('height', '100%')
      .call(
        d3
          .drag()
          .filter((event) => !event.button) // only left mouse button
          .on('start', function (event) {
            GanttUtilities.dispatchD3EventToOutside(d3.select(this), event);
            if (s.enabled) s._dragStart(event);
          })
          .on('drag', function (event) {
            GanttUtilities.dispatchD3EventToOutside(d3.select(this), event);
            if (s.enabled) s._dragging(event);
          })
          .on('end', function (event) {
            GanttUtilities.dispatchD3EventToOutside(d3.select(this), event);
            if (s.enabled) s._dragEnd(event);
          })
      );

    s._selectionBoxDraggingGroup = s._canvasInFront.append('g').attr('class', 'gantt-selection-box-dragging');

    s.clearSelectionBoxRules();
  }

  /**
   * Drag Start event. Draws selection box.
   * @private
   * @param {d3.D3DragEvent<SVGRectElement,unknown,unknown>} event: callback event
   */
  _dragStart(event: d3.D3DragEvent<SVGRectElement, unknown, unknown>) {
    const s = this;

    s._selectionBox = s._selectionBoxDraggingGroup
      .append('polygon')
      .attr('class', 'gantt-selection-box')
      .style('fill', function () {
        if (s.boxRules.color) {
          return s.boxRules.color;
        } else {
          return s._ganttConfig.colorSelect();
        }
      })
      .style('stroke', function () {
        if (s.boxRules.color) {
          return s.boxRules.color;
        } else {
          return s._ganttConfig.colorSelect();
        }
      });

    let yStart = event.y;

    if (s.boxRules.rasterRow) {
      const canvasRow = YAxisDataFinder.getRowByYPosition(s._dataHandler.getYAxisDataset(), yStart);
      yStart = canvasRow.y;
    }

    s.selectionBoxProportions.topLeft = [event.x, yStart];

    const onDragStartEvent = new SelectionBoxDragEvent(JSON.parse(JSON.stringify(s.selectionBoxProportions)), event);
    this._onDragStartSubject.next(onDragStartEvent);
  }
  /**
   * Dragging event. Update points of selection box by using mouse coordinates.
   * @private
   * @param {DragEvent} event: callback event
   */
  _dragging(event) {
    const s = this;
    this.currentMouseX = event.x;
    this.currentMouseY = event.y;
    let yEnd = event.y;

    if (!this.isInDragMode) {
      // on first dragging call
      s.isInDragMode = true;
      s._handleScrollDuringDrag();
    }

    if (s.boxRules.height) {
      if (s.boxRules.height.min) {
        if (Math.abs(s.selectionBoxProportions.topLeft[1] - yEnd) < s.boxRules.height.min)
          yEnd = s.selectionBoxProportions.topLeft[1] + s.boxRules.height.min;
      }
      if (s.boxRules.height.max) {
        if (Math.abs(s.selectionBoxProportions.topLeft[1] - yEnd) > s.boxRules.height.max)
          yEnd = s.selectionBoxProportions.topLeft[1] + s.boxRules.height.max;
      }
    }

    const xEnd = event.x;

    s.selectionBoxProportions.bottomRight = [xEnd, yEnd];
    s.setSelectionBoxProportions();

    const proportionsCpy = JSON.parse(JSON.stringify(s.selectionBoxProportions));
    this._beforeDragUpdateSubject.next(proportionsCpy);
    this._onDragUpdateSubject.next(proportionsCpy);
  }

  /**
   * Handles scrolling while dragging as soon as you move beyond the visible edge
   */
  _handleScrollDuringDrag() {
    const s = this;
    if (!s.isInDragMode) {
      return;
    }
    let horizontalDistance = null;
    let verticalDistance = null;

    // handle horizontal
    if (s.currentMouseX < 0 || s.currentMouseX > this._nodeProportionState.getShiftViewPortProportions().width) {
      horizontalDistance = s._calculateScrollDistanceByPos(
        s.currentMouseX,
        s._nodeProportionState.getShiftViewPortProportions().width
      );
      if (!s._xAxisBuilder.scrollHorizontal(horizontalDistance)) {
        horizontalDistance = 0;
      }
    }

    // handle vertical
    const relativeYPosition = s.currentMouseY - this._nodeProportionState.getScrollTopPosition();
    if (
      (!s.boxRules || !s.boxRules.rasterRow) &&
      (relativeYPosition < 0 || relativeYPosition > this._nodeProportionState.getShiftViewPortProportions().height)
    ) {
      verticalDistance = s._calculateScrollDistanceByPos(
        relativeYPosition,
        this._nodeProportionState.getShiftViewPortProportions().height
      );
      const newScrollTop = this._nodeProportionState.getScrollTopPosition() + verticalDistance;

      if (
        newScrollTop > 0 ||
        newScrollTop <
          this._nodeProportionState.getShiftCanvasProportions().height -
            this._nodeProportionState.getShiftViewPortProportions().height
      ) {
        s._verticalScrollHandler.setVerticalScrollPos(newScrollTop, this._scrollContainerId);
        s.currentMouseY += verticalDistance;
      }
    }
    if (horizontalDistance || verticalDistance) {
      // render if selection box update is needed
      s.setSelectionBoxProportions(null, horizontalDistance, verticalDistance);
    }
    const proportionsCpy = JSON.parse(JSON.stringify(s.selectionBoxProportions));
    this._beforeDragUpdateSubject.next(proportionsCpy);
    this._onDragUpdateSubject.next(proportionsCpy);

    s.scrollDuringDragTimeout = setTimeout(s._handleScrollDuringDrag.bind(s), 50);
  }

  /**
   * Calculates the scroll distance depending on the given position.
   * The further the position extends beyond the edge, the greater the calculated distance.
   * @param {number} pos x or y position
   * @param {number} containerSize max width or height of container
   * @returns {number} Distance to scroll
   */
  _calculateScrollDistanceByPos(pos, containerSize) {
    const s = this;
    const speedFactor = pos < 0 ? pos / 40 - 1 : 1 + (pos - containerSize) / 40;
    return speedFactor * 4;
  }

  /**
   * Drag end event to remove selection box after dragging.
   * @private
   * @param {d3.D3DragEvent<SVGRectElement,unknown,unknown>} event: callback event
   */
  _dragEnd(event: d3.D3DragEvent<SVGRectElement, unknown, unknown>) {
    const s = this;

    // to store the coordinates in case the user doesn't
    // hold the mouse, but only clicks
    s._dragging(event);
    s.isInDragMode = false;
    clearTimeout(s.scrollDuringDragTimeout);
    s._selectionBox.remove();

    const afterSelectionEvent = new SelectionBoxDragEvent(JSON.parse(JSON.stringify(s.selectionBoxProportions)), event);
    this._afterSelectionSubject.next(afterSelectionEvent);

    s.selectionBoxProportions.topLeft = [0, 0];
    s.selectionBoxProportions.bottomRight = [0, 0];
    s.selectionBoxProportions.timeSpan = undefined;
    s._xAxisBuilder.setZoomLevel(1);
  }

  /**
   * Removes "select" property from all canvas shifts.
   * @keywords secelet, select, shifts, canvas, clear, empty, remove, delete
   * @param {GanttCanvasShift[]} canvasDataSet
   * @param {GanttOriginShift[]} originShiftDataset
   */
  deselectAllShifts(canvasDataSet: GanttCanvasShift[], originShiftDataset: GanttDataRow[]) {
    const s = this;
    s.setSelectedShifts([]);

    for (let i = 0; i < canvasDataSet.length; i++) {
      canvasDataSet[i].selected = null; // unmark shift at canvas dataset
    }

    DataManipulator.iterateOverDataSet(originShiftDataset, {
      deselectAllShifts: function (child) {
        for (let i = 0; i < child.shifts.length; i++) {
          child.shifts[i].selected = null; // unmark shift at origin dataset
        }
      },
    });

    this._afterDeselectionSubject.next();
  }

  /**
   * Deselects shift by id.
   * @param {GanttCanvasShift[]} canvasDataSet
   * @param {GanttOriginShift[]} originShiftDataset
   * @param {string} shiftId Id of shift.
   */
  deselectShiftById(canvasDataSet, originShiftDataset, shiftId) {
    for (let i = 0; i < this._selectedShifts.length; i++) {
      if (this._selectedShifts[i].id == shiftId) {
        this._selectedShifts.splice(i, 1);
        break;
      }
    }
    const canvasShift = ShiftDataFinder.getCanvasShiftById(canvasDataSet, shiftId);
    if (canvasShift[0]) canvasShift[0].selected = null;

    const originShift = ShiftDataFinder.getShiftById(originShiftDataset, shiftId);
    if (originShift.shift) originShift.shift.selected = null;

    this._afterSingleShiftDeselectionSubject.next(canvasShift);
  }

  /**
   * Handles selection of shift by id.
   * @param {GanttCanvasShift[]} canvasDataSet
   * @param {GanttOriginShift[]} originShiftDataset
   * @param {string} shiftId Id of shift.
   */
  selectShiftById(canvasDataSet, originShiftDataset, shiftId) {
    const s = this;
    const canvasShift = ShiftDataFinder.getCanvasShiftById(canvasDataSet, shiftId);
    const originShift = ShiftDataFinder.getShiftById(originShiftDataset, shiftId);
    if (canvasShift[0]) canvasShift[0].selected = s._ganttConfig.colorSelect(); // mark canvas dataset
    if (originShift.shift) {
      originShift.shift.selected = s._ganttConfig.colorSelect(); // mark origin dataset
      if (canvasShift[0]) {
        s.addSelectedShifts(canvasShift[0]); // add to selected shifts array
      }
    }
  }

  /**
   * Join of rect points into SVG coordinate format
   * @private
   * @param {number} xTopLeft
   * @param {number} yTopLeft
   * @param {number} xBottomRight
   * @param {number} yBottomRight
   * @return {string} Coordinate string to draw SVG polygon
   */
  _getPolygonRectPoints(xTopLeft, yTopLeft, xBottomRight, yBottomRight) {
    return (
      xTopLeft +
      ',' +
      yTopLeft +
      ' ' +
      xBottomRight +
      ',' +
      yTopLeft +
      ' ' +
      xBottomRight +
      ',' +
      yBottomRight +
      ' ' +
      xTopLeft +
      ',' +
      yBottomRight
    );
  }

  /**
   * Updates selection box proportions if selection box does exist.
   * Overwrites data for selection box proportions.
   * @keywords selection, box, proportion, width, height, top, left, right, bottom
   * @param {ElementSelectorProportions} [elementSelectorProportions]
   * @param {number} [horizontalScrollOffset] horizontal scroll distance since last rendering
   * @param {number} [verticalScrollOffset] vertical scroll distance since last rendering
   */
  setSelectionBoxProportions(elementSelectorProportions = null, horizontalScrollOffset = 0, verticalScrollOffset = 0) {
    const s = this;
    if (elementSelectorProportions) {
      s.selectionBoxProportions = elementSelectorProportions;
    }
    // take into account that maybe scrolling has taken place
    s.selectionBoxProportions.topLeft[0] -= horizontalScrollOffset;
    s.selectionBoxProportions.bottomRight[1] += verticalScrollOffset;

    // handle time gradation from box rules if set
    if (s.boxRules && s.boxRules.timeGradation.unit) {
      const transformInformation = s._xAxisBuilder.getLastZoomEvent();
      const scaledTopLeftXCoordinate =
        (s.selectionBoxProportions.topLeft[0] - transformInformation.x) / transformInformation.k;
      const scaledBottomRightXCoordinate =
        (s.selectionBoxProportions.bottomRight[0] - transformInformation.x) / transformInformation.k;
      if (s.boxRules.timeGradation.unit === 'customized') {
        // take the reference date from the mouse position to avoid conflicts with the time shift
        const dateRef = s._xAxisBuilder.pxToTime(scaledTopLeftXCoordinate, s._xAxisBuilder.getGlobalScale());
        dateRef.setMilliseconds(0);
        dateRef.setSeconds(0);
        dateRef.setMinutes(0);
        dateRef.setHours(0);
        s.timeGradation.setCustomizedTimeGrid(dateRef, s.boxRules.timeGradation.customStepSize);
      }

      s.selectionBoxProportions.topLeft[0] = s.timeGradation.getAliginXCoordinateByXCoordinate(
        scaledTopLeftXCoordinate,
        s.boxRules.timeGradation.unit
      );
      s.selectionBoxProportions.bottomRight[0] = s.timeGradation.getAliginXCoordinateByXCoordinate(
        scaledBottomRightXCoordinate,
        s.boxRules.timeGradation.unit
      );
      s.selectionBoxProportions.timeSpan = [
        s.timeGradation.getAliginDateByXCoordinate(scaledTopLeftXCoordinate, s.boxRules.timeGradation.unit).getTime(),
        s.timeGradation
          .getAliginDateByXCoordinate(scaledBottomRightXCoordinate, s.boxRules.timeGradation.unit)
          .getTime(),
      ];
    }

    // if selectionbox does exist, add directly new proportions
    if (s._selectionBox && !s._selectionBox.empty()) {
      const selectionRectPoints = s._getPolygonRectPoints(
        s.selectionBoxProportions.topLeft[0],
        s.selectionBoxProportions.topLeft[1],
        s.selectionBoxProportions.bottomRight[0],
        s.selectionBoxProportions.bottomRight[1]
      );

      s._selectionBox.attr('points', selectionRectPoints);
    }
  }

  /**
   * Adds selected shifts to selectedShifts array.
   * @param {GanttCanvasShift[]|GanttCanvasShift} shifts Can be an array of shifts or a single shift object.
   */
  addSelectedShifts(shifts) {
    this._addSelectedShiftToDataset(shifts);
  }

  /**
   * Function which pushes shift into dataset only if it doesnt already exists.
   * @private
   * @param {GanttCanvasShift} shift
   */
  _addSelectedShiftToDataset(shift) {
    if (shift && shift.hasOwnProperty('noRender') && shift.noRender.length) return;
    if (
      !this._selectedShifts.find(function (selectedShift) {
        if (!selectedShift || !selectedShift.hasOwnProperty('id') || !shift || !shift.hasOwnProperty('id')) {
          return false;
        }
        return selectedShift.id == shift.id;
      })
    ) {
      if (Array.isArray(shift)) {
        this._selectedShifts.push(...shift);
      } else {
        this._selectedShifts.push(shift);
      }
      this._afterSingleShiftSelectionSubject.next(shift);
    }
  }

  clearSelectionBoxRules() {
    const s = this;
    const boxRules = new SelectionBoxParameters();
    s.setBoxRules(boxRules);
  }

  //
  //  GETTER & SETTER
  //

  /**
   * Helper getter which returns the gantt config of the current gantt.
   */
  private get _ganttConfig(): GanttConfig {
    return this._ganttDiagram.getConfig();
  }

  /**
   * Helper getter which returns the data handler of the current gantt.
   */
  private get _dataHandler(): DataHandler {
    return this._ganttDiagram.getDataHandler();
  }

  /**
   * Helper getter which returns the x axis builder of the current gantt.
   */
  private get _xAxisBuilder(): GanttXAxis {
    return this._ganttDiagram.getXAxisBuilder();
  }

  /**
   * Helper getter which returns the vertical scroll handler of the current gantt.
   */
  private get _verticalScrollHandler(): VerticalScrollHandler {
    return this._ganttDiagram.getVerticalScrollHandler();
  }

  private get _canvasInFront(): d3.Selection<SVGSVGElement, undefined, d3.BaseType, undefined> {
    return this._ganttDiagram.getShiftFacade().getCanvasInFrontShifts(this._scrollContainerId);
  }

  getSelectionBoxProportions() {
    return this.selectionBoxProportions;
  }

  setSelectedShifts(shifts: GanttCanvasShift[]) {
    this._selectedShifts = shifts;
  }

  getSelectedShifts() {
    return this._selectedShifts || [];
  }

  setBoxRules(selectionBoxParameters) {
    this.boxRules = selectionBoxParameters;
  }

  public getSelectionBoxStartGroup(): d3.Selection<SVGRectElement, undefined, d3.BaseType, undefined> {
    return this._selectionBoxStartGroup.select<SVGRectElement>('rect');
  }

  //
  // OBSERVABLES
  //

  public get onDragStart(): Observable<SelectionBoxDragEvent> {
    return this._onDragStartSubject.asObservable();
  }

  public get beforeDragUpdate(): Observable<ElementSelectorProportions> {
    return this._beforeDragUpdateSubject.asObservable();
  }

  public get onDragUpdate(): Observable<ElementSelectorProportions> {
    return this._onDragUpdateSubject.asObservable();
  }

  public get afterSelection(): Observable<SelectionBoxDragEvent> {
    return this._afterSelectionSubject.asObservable();
  }

  public get afterDeselection(): Observable<void> {
    return this._afterDeselectionSubject.asObservable();
  }

  public get afterSingleShiftSelection(): Observable<GanttCanvasShift> {
    return this._afterSingleShiftSelectionSubject.asObservable();
  }

  public get afterSingleShiftDeselection(): Observable<GanttCanvasShift[]> {
    return this._afterSingleShiftDeselectionSubject.asObservable();
  }
}

/**
 * Proportions of selection box.
 * @class
 * @constructor
 */
export class ElementSelectorProportions {
  topLeft: [number, number];
  bottomRight: [number, number];
  timeSpan?: [number, number];

  constructor() {
    this.topLeft = [0, 0];
    this.bottomRight = [0, 0];
  }
}

/**
 * Selection box settings.
 * @class
 * @constructor
 */
export class SelectionBoxParameters {
  height: any;
  color: string;
  rasterRow: boolean;
  timeGradation: any;

  constructor() {
    this.height = {
      min: null,
      max: null,
    };
    this.color = null;
    this.rasterRow = false;
    this.timeGradation = {
      unit: null,
      customStepSize: null,
    };
  }
}

/**
 * Data container for a selection box drag event.
 * @class
 * @constructor
 */
export class SelectionBoxDragEvent {
  selectionBoxProportions: ElementSelectorProportions;
  event: d3.D3DragEvent<SVGRectElement, unknown, unknown>;

  constructor(
    selectionBoxProportions: ElementSelectorProportions,
    event: d3.D3DragEvent<SVGRectElement, unknown, unknown>
  ) {
    this.selectionBoxProportions = selectionBoxProportions;
    this.event = event;
  }
}
