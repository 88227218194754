import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { ImageModule } from '@app-modeleditor/components/image/image.module';
import { TranslateModule } from '@ngx-translate/core';
import { EditmodeBarComponent } from './editmode-bar.component';

@NgModule({
  imports: [CommonModule, ImageModule, MatButtonModule, TranslateModule, MatIconModule],
  declarations: [EditmodeBarComponent],
  exports: [EditmodeBarComponent],
})
export class EditmodeBarModule {}
