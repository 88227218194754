import { SaxmsTooltipModule } from './../tooltip/tooltip.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { ImageModule } from '@app-modeleditor/components/image/image.module';
import { MySharedModule } from 'frontend/src/dashboard/shared/shared.module';
import { ButtonComponent } from './button.component';
@NgModule({
  imports: [CommonModule, MySharedModule, MatBadgeModule, ImageModule, SaxmsTooltipModule],
  declarations: [ButtonComponent],
  exports: [ButtonComponent],
})
export class TemplateButtonModule {}
