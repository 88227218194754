import { ConfigService } from '@core/config/config.service';
import { Notification } from '@core/notification/notification';
import { ENotificationType } from '@core/notification/notification-type.enum';
import { LegendCommunicationService } from 'frontend/src/dashboard/gantt/gantt/dock/views/legend/legend-communication.service';
import { GanttLibService } from 'frontend/src/dashboard/gantt/gantt/gantt-lib.service';
import { SaxMsBestGanttToolbarHandler } from 'frontend/src/dashboard/gantt/gantt/saxms-best-gantt-submenu-handler';
import { GanttPluginHandlerService } from 'frontend/src/dashboard/gantt/general/plugin/gantt-plugin-handler.service';
import { IGanttAttributeMapping } from '../generator/gantt-input.data';
import { GanttEssentialPlugIns } from '../plugin/e-gantt-essential-plugins';
import { GanttBlockHighlighterPlugIn } from '../plugin/plugin-list/block-highlighter/block-highlighter';
import { GanttOverlappingShiftsPlugIn } from '../plugin/plugin-list/overlapping-shifts/overlapping-shifts';
import {
  GanttPlugInSuperBlockHandler,
  GanttSuperBlocksPlugIn,
} from '../plugin/plugin-list/superblocks/superblocks.plugin';
import { GanttTemplateDataService } from '../template-data/gantt-template-data.service';
import { VisibilityStateService } from '../visibility-state.sevice';
import { EGanttChangeMode } from './gantt-change-mode.enum';
import { IGanttResponse } from './gantt-response';
import { GanttBlockSelectionUpdate } from './responses/block-selection-update/block-selection-update.response';
import { GanttResponse } from './responses/response';
import { GanttResponseRowUpdate } from './responses/row-update/row-update.response';
import { GanttUpdateNotificationBuffer } from './update-notification-buffer';

/**
 * Centralized response handler which will exist only one time per generalized gantt.
 * Handles all server responses and Push-Notifications.
 */
export class GanttResponseHandler {
  private allResponseTypes: GanttResponse[] = [];
  private updateNotificationBuffer: GanttUpdateNotificationBuffer = null;
  private forceBufferReleaseAfterNextResponse = false;
  private registeredEventIds = new Map<string, number>();

  constructor(
    private toolbarHandler: SaxMsBestGanttToolbarHandler,
    private _pluginHandlerService: GanttPluginHandlerService,
    private attributeMapping: IGanttAttributeMapping,
    private _ganttLibService: GanttLibService,
    private _legendCommunicationService: LegendCommunicationService,
    private _configService: ConfigService,
    private _ganttTemplateDataService: GanttTemplateDataService,
    private visibilityStateService: VisibilityStateService
  ) {
    this.addPredefinedResponses();
    this.initUpdateNotificationBuffer();
  }

  public destroy(): void {
    this.updateNotificationBuffer?.destroy();
    this.updateNotificationBuffer = null;
  }

  /**
   * Initializes predefined responses which do exist in all types of gantts.
   */
  private addPredefinedResponses(): void {
    this.addResponse(GanttResponseRowUpdate, this._ganttLibService.bestGantt);
    this.addResponse(GanttBlockSelectionUpdate, this._ganttLibService.bestGantt);
  }

  /**
   * Initializes the update notification buffer if neccesary (does nothing if not).
   */
  private initUpdateNotificationBuffer(): void {
    const bufferDelay = this._configService.access().templates?.Gantt?.notification?.updateNotificationBufferDelay || 0;
    const sleepModeEnabled = this.visibilityStateService.isEnabled();
    if (bufferDelay <= 0 && !sleepModeEnabled) return;
    this.updateNotificationBuffer = new GanttUpdateNotificationBuffer(bufferDelay, this.visibilityStateService);
    this.updateNotificationBuffer.onBufferRelease.subscribe((notification) => {
      this._handleUpdateNotification(notification);
    });
  }

  /**
   * Add new response handling.
   * @template T Type of executer which will be passed to the response handling item.
   * @param reponse ResponseClass name which will be instantiated and notified if e new response arrives into the gantt.
   * @param executer JS-PlugIn instance or Gantt which will be inserted into the ResponseClass instance.
   */
  public addResponse<T>(reponse: typeof GanttResponse<T>, executer: T): void {
    this.allResponseTypes.push(
      new reponse(
        this.toolbarHandler,
        executer,
        this._pluginHandlerService,
        this.attributeMapping,
        this._ganttLibService,
        this._legendCommunicationService,
        this._configService,
        this._ganttTemplateDataService
      )
    );
  }

  /**
   * Notifies all stored gantt responses and propagates response data.
   * @param event Response data from backend.
   * @param registerEventId If true, following notifications with the same event id will be ignored (default value is true).
   * @param useBuffer If true, a gantt update notification will be buffered instead of being handled immediately (default value is false).
   */
  public handleResponse(event: Notification, registerEventId = true, useBuffer = false): void {
    if (!event?.getType) {
      return;
    }
    switch (event.getType()) {
      case ENotificationType.GANTT_UPDATE_NOTIFICATION:
        const response = event.getBelongsToResource() as IGanttResponse;
        this.handleUpdateNotification(
          response,
          registerEventId,
          response.isMonthlyDataResponse() || !useBuffer ? false : true
        );
        break;
      case ENotificationType.HIGHLIGHT_GANTT_BLOCK_NOTIFICATION:
        const blockHighlighter: GanttBlockHighlighterPlugIn = this._pluginHandlerService.getEssentialPlugIn(
          GanttEssentialPlugIns.GanttBlockHighlighter
        );
        blockHighlighter.highlightBlocks(event.getHighlightBlockIds());
        break;
    }
  }

  /**
   * Wrapper method to handle gantt update notifications.
   * @param response Content of notification (belongsToResource property).
   * @param registerEventId If true, following notifications with the same event id will be ignored (default value is true).
   * @param useBuffer Specifies whether to handle the notification immediately (= false) or to buffer it first (= true), the default value is false.
   */
  public handleUpdateNotification(response: IGanttResponse, registerEventId = true, useBuffer = false): void {
    // if notification has already been received -> ignore it
    if (response?.eventId && this.registeredEventIds.has(response.eventId)) {
      this.registeredEventIds.delete(response.eventId);
      return;
    }
    if (registerEventId && response?.eventId) {
      this.registerIgnoreEventId(response.eventId);
    }

    // handle notification
    if (this.updateNotificationBuffer) {
      // check if response contains relevant data -> ignore it if not
      if (!this._containsRelevantData(response)) {
        this.forceBufferReleaseAfterNextResponse = !useBuffer;
        return;
      }
      this.updateNotificationBuffer.addNotification(response, !useBuffer || this.forceBufferReleaseAfterNextResponse);
      this.forceBufferReleaseAfterNextResponse = false;
      return;
    }
    if (this._containsRelevantData(response)) {
      this._handleUpdateNotification(response);
    }
  }

  /**
   * Checks if the specified response contains relevant data.
   * @param response Response to check.
   * @returns
   */
  private _containsRelevantData(response: IGanttResponse): boolean {
    if (
      response?.ganttChangeMode === EGanttChangeMode.REPLACE_BLOCKS ||
      response?.ganttChangeMode === EGanttChangeMode.REPLACE_GANTT_ENTRIES
    ) {
      return (
        (!!response.addedConnections && response.addedConnections.length > 0) ||
        (!!response.ganttEntries && response.ganttEntries.length > 0) ||
        (!!response.deletedBlocks && response.deletedBlocks.length > 0) ||
        (!!response.addedGanttEntries && response.addedGanttEntries.length > 0) ||
        (!!response.deletedGanttEntries && response.deletedGanttEntries.length > 0) ||
        !!response.updatedPluginData ||
        (!!response.ganttTimePeriodInputs && response.ganttTimePeriodInputs.length > 0) ||
        (!!response.deletedGanttTimePeriodInputs && response.deletedGanttTimePeriodInputs.length > 0) ||
        !!response.superBlockDataViews
      );
    }
    return true;
  }

  /**
   * Handles gantt update notifications.
   * @param response Content of notification (belongsToResource property).
   */
  private _handleUpdateNotification(response: IGanttResponse): void {
    if (!response || !this.activateResponseHandler(response)) {
      return;
    }
    const overlappingShiftUpdater: GanttOverlappingShiftsPlugIn = this._pluginHandlerService.getEssentialPlugIn(
      GanttEssentialPlugIns.OverlappingShiftsPlugIn
    );
    let superBlocksDeactivated = false;
    const superBlockPlugIn: GanttSuperBlocksPlugIn = this._pluginHandlerService.getEssentialPlugIn(
      GanttEssentialPlugIns.SuperBlocksPlugIn
    );
    const activeSuperBlockPlugIn =
      superBlockPlugIn && superBlockPlugIn.getPlugInById(GanttPlugInSuperBlockHandler)
        ? superBlockPlugIn.getPlugInById(GanttPlugInSuperBlockHandler).getActivePlugIn()
        : null;
    if (activeSuperBlockPlugIn && activeSuperBlockPlugIn.combinePlugIn.active) {
      activeSuperBlockPlugIn.combinePlugIn.splitCombinedShifts(null, false);
      superBlocksDeactivated = true;
    }

    // 1. reset splitting
    overlappingShiftUpdater.resetSplitOverlappingShifts(false);

    // 2. execute response handling
    this.allResponseTypes.forEach((responseType) => responseType.handleResponse(response));

    // 3. execute some stuff after response is handled
    if (activeSuperBlockPlugIn && superBlocksDeactivated)
      activeSuperBlockPlugIn.combinePlugIn.combineChainedShifts(false);

    // ignore shift selection handling if the response explicitly prevents shift selection handling
    if (!response.preventShiftSelectionCheck) {
      this.handleShiftSelection();
    }

    overlappingShiftUpdater.splitOverlappingShifts(true);
    this._ganttLibService.bestGantt.getShiftFacade().preloadPatterns();
  }

  /**
   * Handles the selection of shifts in the gantt diagram.
   */
  public handleShiftSelection() {
    const ganttDiagram = this._ganttLibService.bestGantt;

    const templateData = this._pluginHandlerService.getTemplateData();
    const selectedBlockIds: string[] = [];
    const selectedShifts = ganttDiagram.getSelectionBoxFacade().getSelectedShifts();
    ganttDiagram
      .getSelectionBoxFacade()
      .deselectAllShifts(
        ganttDiagram.getDataHandler().getCanvasShiftDataset(),
        ganttDiagram.getDataHandler().getOriginDataset().ganttEntries
      );

    // if nothing was selected
    if (!selectedShifts.length) {
      templateData.setSelectedBlock({}, this._ganttLibService.backendToGanttOriginInputMapper);
      templateData.setSelectedValues(false, this._ganttLibService.backendToGanttOriginInputMapper);
      return;
    }

    // check if selected values already exists
    selectedShifts.forEach((shift) => {
      const foundShift = ganttDiagram.getShiftById(shift.id);
      if (foundShift && foundShift.id) {
        selectedBlockIds.push(foundShift.id);
      }
    });

    // select shifts again
    if (selectedBlockIds.length) {
      ganttDiagram.selectShiftsByIDs(selectedBlockIds);
    }
  }

  /**
   * Checks if response handler should execute th response handling.
   * @param response Backend response.
   */
  private activateResponseHandler(response: IGanttResponse): boolean {
    if (!response.ganttChangeMode) return true;
    return response.ganttChangeMode != EGanttChangeMode.DO_NOTHING;
  }

  /**
   * Registers the specified event id to be ignored when a notification is assigned to this specific id.
   * @param eventId Event id to register.
   */
  private registerIgnoreEventId(eventId: string): void {
    const now = new Date().getTime();
    this.registeredEventIds.set(eventId, now);
    // clean up old (> 1h) event id registrations
    for (const key of this.registeredEventIds.keys()) {
      if (now - this.registeredEventIds.get(key) > 3600000) {
        this.registeredEventIds.delete(key);
      }
    }
  }
}
