<mat-list>
  <mat-list-item class="contentText">
    {{ 'GANTT.TOOLTIP.show' | translate }}
    <mat-slide-toggle (change)="handleTooltipVisibility($event)" [checked]="settings.showTooltip"></mat-slide-toggle>
  </mat-list-item>
  <mat-divider></mat-divider>
  <mat-list-item class="contentText">
    {{ 'TOOLTIP.delay' | translate }}
    <mat-form-field class="select" appearance="standard">
      <mat-select [(value)]="selectedDelayOption" (selectionChange)="onDelaySelectionChange($event)">
        <mat-option *ngFor="let delayOption of delayOptions" [value]="delayOption.value">
          {{ delayOption.viewValue | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </mat-list-item>
  <mat-divider></mat-divider>
  <mat-list-item class="contentText">
    {{ 'TOOLTIP.scrollableTooltips' | translate }}
    <mat-slide-toggle (change)="handleTooltipScrollable($event)" [checked]="settings.scrollableTooltip">
    </mat-slide-toggle>
  </mat-list-item>
  <mat-divider></mat-divider>
  <mat-list-item class="contentText">
    {{ 'GANTT.FONTS.fontsize' | translate }}
    <app-slider
      class="sliderContainer"
      [max]="22"
      [min]="4"
      [value]="settings.tooltipFontSize"
      [label]="settings.tooltipFontSize + ' px'"
      (changeEvent)="fontSizeSliderChange($event)"
    ></app-slider>
  </mat-list-item>
</mat-list>
