import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EntryElementModule } from '@app-modeleditor/components/entry-element/entry-element.module';
import { EntryElementComponent } from './entry-element.component';

@NgModule({
  imports: [CommonModule, EntryElementModule],
  declarations: [EntryElementComponent],
  exports: [EntryElementComponent],
})
export class TemplateEntryElementModule {}
