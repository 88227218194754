import { ZoomTransform } from 'd3';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GanttCanvasShift } from '../../data-handler/data-structure/data-structure';
import { DataManipulator } from '../../data-handler/data-tools/data-manipulator';
import { GanttFontSizeCalculator } from '../../font-tools/font-size';
import { GanttUtilities } from '../../gantt-utilities/gantt-utilities';
import { GanttSplitOverlappingShifts } from '../../plug-ins/split-overlapping-shifts/split-overlapping-shifts-executer';
import { TextOverlay } from '../text-overlay';
import { IGanttShiftTextOverlayBuilding } from './text-overlay-interface';

/**
 * Strategy that lets text (shiftnames) flow outside of them and if the text would collide with another shift the colliding shift ist moved to the next row.
 * This is achived by manipulating the method for determining shift overlapping in the split-overlapping-shifts plugin.
 * To achive this we had to add a few more dependencies to this strategy.
 * Use this strategie to show more information.
 * @implements {IGanttShiftTextOverlayBuilding}
 * @class
 */
export class TextOverlayOutsideSplit implements IGanttShiftTextOverlayBuilding {
  connectedToSplitPlugin: boolean;
  reference: TextOverlay;
  fontSizeCalculator: GanttFontSizeCalculator;
  callbackName: string;
  splitOverlappingShiftsPlugin: GanttSplitOverlappingShifts;
  textBoxWidthMap: Map<string, number>;

  private _onDestroySubject: Subject<void> = new Subject<void>();
  private _onRemoveResplitCbs: Subject<void> = new Subject<void>();

  constructor() {
    this.connectedToSplitPlugin = false;
  }

  /**
   * @override
   */
  init(reference) {
    const self = this;
    self.reference = reference;
    self.fontSizeCalculator = reference.getFontSizeCalculator();

    if (reference.splitOverlappingShiftsPlugin) {
      self.connectSplittingShiftsPlugin(reference.splitOverlappingShiftsPlugin);

      self._registerStopperCallbacks();
    } else {
      // No plugin yet available. Plugins are added later to the gantt, but if this text strategie ist set initial by user preference we fallback
      // to outside Text Strategie and go to the split as soon as possible.
      self.callbackName = `subscribeToSplitOverlappingShiftsPlugin + ${GanttUtilities.generateUniqueID()}`;
      reference.subscribeAfterSplitOverlappingShiftsConnection(self.callbackName, (splitOverlappingShiftsPlugin) => {
        self.connectSplittingShiftsPlugin(splitOverlappingShiftsPlugin);
        reference.unSubscribeAfterSplitOverlappingShiftsConnection(self.callbackName);
        self._registerStopperCallbacks();
      });
    }
  }

  private _registerStopperCallbacks() {
    const self = this;
    const s = self.reference;

    s.ganttDiagram
      .getShiftFacade()
      .shiftDragStart()
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        self.removeResplitCallbacks();
      });

    s.ganttDiagram
      .getShiftFacade()
      .shiftDragEnd()
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        self.registerResplitCallbacks();
      });

    self.registerResplitCallbacks();
  }

  registerResplitCallbacks() {
    const self = this;
    const s = self.reference;

    s.ganttDiagram.getXAxisBuilder().addToZoomEndCallback(`shiftNames_resplitOnZoomEnd`, function () {
      self.reSplitShifts();
    });

    s.ganttDiagram
      .getOpenRowHandler()
      .afterShiftRowOpen.pipe(takeUntil(this.onRemoveResplitCbs))
      .subscribe(() => self.reSplitShifts());
  }

  removeResplitCallbacks() {
    const self = this;
    const s = self.reference;
    s.ganttDiagram.getXAxisBuilder().removeZoomEndCallback(`shiftNames_resplitOnZoomEnd`);
    this._onRemoveResplitCbs.next();
  }

  reSplitShifts() {
    const self = this;
    const s = self.reference;

    self.removeResplitCallbacks();
    self.splitOverlappingShiftsPlugin.resetSplitOverlappingShifts(false);
    self.splitOverlappingShiftsPlugin.splitOverlappingShifts(null, false);
  }

  connectSplittingShiftsPlugin(plugin) {
    const self = this;
    const s = self.reference;

    self.splitOverlappingShiftsPlugin = plugin;

    self.splitOverlappingShiftsPlugin.addOverlapCompareMethod(
      `textOverlapShiftNameMethod`,
      self.overlapCompare.bind(self),
      1
    );
    self.splitOverlappingShiftsPlugin.subscribeAfterSplit(`shiftnames_reRender After Splits`, () => {
      s.ganttDiagram.update();
      self.registerResplitCallbacks();
    });
    s.ganttDiagram.subscribeOriginDataUpdate(`updateShiftNameTextMapOnNewData`, self.createTextBoxWidthMap.bind(self));

    if (!self.textBoxWidthMap) {
      self.createTextBoxWidthMap();
    }
    this.connectedToSplitPlugin = true;
  }

  overlapCompare(overlapData) {
    const self = this;
    const s = self.reference;

    let overlap = false;

    if (!overlapData.overlapping) {
      // check additionally the text of index cards for overlapping to decide about splitting
      const shiftStartMin =
        overlapData.scale(overlapData.minShift.timePointStart.getTime()) * overlapData.zoom.k + overlapData.zoom.x;
      const shiftStartMax =
        overlapData.scale(overlapData.maxShift.timePointStart.getTime()) * overlapData.zoom.k + overlapData.zoom.x;

      const endpointOfShiftWithText = shiftStartMin + (self.textBoxWidthMap.get(overlapData.minShift.id) | 0);

      overlap = endpointOfShiftWithText > shiftStartMax;

      overlapData.overlapping = overlap;
    }
  }

  /**
   * Creates a map with shift id and associated max width of text box in px.
   */
  createTextBoxWidthMap() {
    const self = this;
    const s = self.reference;

    self.textBoxWidthMap = new Map();

    const calculateShiftNameWidths = function (child, level, parent, index, abort) {
      for (let i = 0; i < child.shifts.length; i++) {
        const shift = child.shifts[i];
        const textBoxWidth = self.fontSizeCalculator.getTextWidth(shift.name) + 5;
        self.textBoxWidthMap.set(shift.id, textBoxWidth);
      }
    };
    DataManipulator.iterateOverDataSet(s.dataHandler.getOriginDataset().ganttEntries, {
      calculateShiftNameWidths: calculateShiftNameWidths,
    });
  }

  /**
   * @override
   */
  render(shiftDataset: GanttCanvasShift[], zoomTransformation: ZoomTransform, reference: TextOverlay) {
    const s = reference;
    const self = this;
    if (!shiftDataset) return;

    /* shiftDataset = shiftDataset.filter(function (d) {
      return 5 < zoomTransformation.k * d.width;
    }); */

    if (!shiftDataset) return;

    /* if (self.connectedToSplitPlugin) {
      // TODO
    } else {  // Fallback to normal outside text behaivior */

    const textOverlay = s.canvas
      .selectAll('dummy')
      .data(shiftDataset)
      .enter()
      .append('div')
      .style('top', (d) => {
        const y = s.ganttDiagram.getRenderDataHandler().getStateStorage().getYPositionShift(d.id);
        return y + 'px';
      })
      .style('height', (d) => s.config.getTextOverlayHeight() + 'px')
      .style('left', (d) => {
        let x = d.x * zoomTransformation.k + zoomTransformation.x + s.config.textOverflowLeft();
        if (x < 0) {
          x = s.config.textOverflowLeft();
        }
        return x + 'px';
      })
      .style('width', (d, i) => {
        let width = 0;
        let nextWidth: number;
        const x = d.x * zoomTransformation.k + zoomTransformation.x + s.config.textOverflowLeft();
        const neighbor = s.findNeighbor(shiftDataset, i, d, s.config);
        const next = neighbor.next;
        if (next) {
          if (s.isElemOverlapping(shiftDataset, d, s.config, 'next')) {
            nextWidth = 0;
          } else {
            nextWidth = next.x * zoomTransformation.k - d.x * zoomTransformation.k;
          }
        } else {
          nextWidth = 10000; // no restriction to the right as there is no right shift neighbor
        }

        let preWidth;
        const prev = neighbor.prev;
        if (prev) {
          if (s.isElemOverlapping(shiftDataset, d, s.config, 'prev')) {
            preWidth = 0; // if prev with higher priority is overlapping, we don't show the text
          } else {
            preWidth = d.x * zoomTransformation.k - prev.x * zoomTransformation.k;
          }
        }

        if (nextWidth !== 0 || d.renderPriority < next.renderPriority) {
          width = nextWidth;
        } else if (
          neighbor.nextNotSameStart &&
          neighbor.nextNotSameStart.id !== next.id &&
          s.isOverlapping(d, neighbor.nextNotSameStart)
        ) {
          width = neighbor.nextNotSameStart.x * zoomTransformation.k - d.x * zoomTransformation.k;
        } else {
          width = s.findNextNeighbor(shiftDataset, d, s.config) * zoomTransformation.k;
        }

        if (x < 0) {
          width = width - -1 * x; // compensate shift partly behind y Axis
        }
        if (width < 10 || (preWidth === 0 && d.renderPriority < prev.renderPriority)) {
          width = 0; // on very small widths the text-overflow is glitching, so we set a minimum to fix this
        }
        return width + 'px';
      })
      .attr('class', () => {
        return reference.config.css.shifts.text_overlay_item;
      })
      .text((d) => d.name)
      .style('font-size', function () {
        return s.config.getShiftFontSize() + 'px';
      })
      .style('color', s.config.getShiftTextColor())
      .style('text-shadow', (d) => s.getTextOverlayShadow(d));
  }

  /**
   * @override
   */
  cleanUp(reference) {
    const self = this;
    const s = self.reference;
    this.fontSizeCalculator = null;
    s.ganttDiagram.unSubscribeOriginDataUpdate(`updateShiftNameTextMapOnNewData`);

    if (self.connectedToSplitPlugin) {
      s.splitOverlappingShiftsPlugin.unSubscribeAfterSplit(`shiftnames_reRender After Splits`);
      self.splitOverlappingShiftsPlugin.removeOverlapCompareMethod(`textOverlapShiftNameMethod`);
      self.splitOverlappingShiftsPlugin.resetSplitOverlappingShifts(false);
      self.splitOverlappingShiftsPlugin.splitOverlappingShifts(null, false);
    } else {
      s.unSubscribeAfterSplitOverlappingShiftsConnection(self.callbackName);
    }

    this._onRemoveResplitCbs.next();
    this._onRemoveResplitCbs.complete();
    this._onDestroySubject.next();
    this._onDestroySubject.complete();
  }

  /**
   * @override
   */
  buildSmallerText(shiftDataSet, shiftData, textElement, zoomTransformation, executer) {
    const s = executer;
    if (!shiftData.name) return;

    if (textElement) {
      textElement.remove();
    }
    s.render([shiftData], zoomTransformation);
  }

  //
  // OBSERVABLES
  //

  /**
   * Observable which gets triggered when the instance gets destroyed.
   */
  public get onDestroy(): Observable<void> {
    return this._onDestroySubject.asObservable();
  }

  /**
   * Observable which gets triggered when the resplit callbacks have to be removed.
   */
  private get onRemoveResplitCbs(): Observable<void> {
    return this._onRemoveResplitCbs.asObservable();
  }
}
