import * as L from 'leaflet';
import 'leaflet-polylinedecorator';

export interface OnMarkerDownEvent {
  marker: MarkersTemplate;
  mapTemplate: MapTemplate;
}

export enum ETransitionLineStyleTypes {
  SOLID_LINE = 'SOLID_LINE',
  DASHED_LINE = 'DASHED_LINE',
  DASH_DOTTED_LINE = 'DASH_DOTTED_LINE',
  DOTTED_LINE = 'DOTTED_LINE',
}
export class MapTemplate {
  public id: string;
  public restUrl: string;
  private markers: MarkersTemplate[] = [];
  private transitionLines: TransitionLine[] = [];
  public zoom: number;
  public minZoom: number;
  public center: L.LatLng;
  public maxZoom: number;
  public mapLayers: any[] = [];
  public currentMapLayer: any;
  private mapRef: L.Map;
  public timedRangePicker = true;

  clearData() {
    this.markers.forEach((marker) => {
      marker.toogleState(this.mapRef);
    });
    this.transitionLines.forEach((line) => {
      line.toogleState(this.mapRef);
    });

    this.markers = [];
    this.transitionLines = [];
  }
  setCenter(lat: number, lng: number, alt?: number) {
    this.center = new L.LatLng(lat, lng, alt);
  }
  addMarker(markers) {
    this.markers.push(markers);
  }

  getMarkers(): MarkersTemplate[] {
    return this.markers;
  }

  getFakeMarkers(): MarkersTemplate[] {
    let result = [];
    for (let i = 0; i < 3; i++) {
      result = result.concat(this.markers);
    }
    return result;
  }

  setMarkers(markers: MarkersTemplate[]) {
    this.markers = markers.slice();
  }

  addTransitionLine(transitionLine) {
    this.transitionLines.push(transitionLine);
  }

  getTransitionLines(): TransitionLine[] {
    return this.transitionLines;
  }

  setTransitionLines(transitionLines: TransitionLine[]) {
    this.transitionLines = transitionLines.slice();
  }

  setMapRef(mapRef: L.Map) {
    this.mapRef = mapRef;
  }
  getMapRef() {
    return this.mapRef;
  }

  setChainLineOfMarker() {
    this.getTransitionLines().forEach((line) => {
      const startMarker: MarkersTemplate = this.getMarkers().find((element) => element.id === line.sourceLocationId);
      const endMarker: MarkersTemplate = this.getMarkers().find((element) => element.id === line.destinationLocationId);
      if (startMarker && endMarker) {
        startMarker.nextMarker = endMarker;
        startMarker.startPlayTime = new Date(line.sourceLocationTime);
        endMarker.prevMarker = startMarker;
        endMarker.endPlayTime = new Date(line.destinationLocationTime);
      }
    });
  }
}

export class MarkersTemplate {
  public nextMarker: MarkersTemplate;
  public prevMarker: MarkersTemplate;
  public postion: L.LatLng;
  private option: L.MarkerOptions = {};
  private tooltip: L.Tooltip;
  private label: L.Tooltip;
  private popup: L.Popup;
  public markerRef: L.Marker;
  public markerLabelRef: L.Marker;
  public icon: string;
  public color = '#583470';

  public onClickCallback: any;
  public canonicalName: string;
  public startPlayTime: Date;
  public endPlayTime: Date;
  private iconpath;
  private alive = false;
  private playMakerRef: L.Marker;
  private playTimeoutId;
  private onPlay = false;
  private playedFinish = false;

  constructor(public name: string, public id: string, public refId: string, public resourceId: string) {}

  isRunning() {
    return this.onPlay;
  }

  isPlayed() {
    return this.playedFinish;
  }
  setReady() {
    this.playedFinish = false;
    this.onPlay = false;
  }
  setIcon(iconpath) {
    this.iconpath = iconpath;
  }

  setPostion(lat: number, lng: number, alt?: number) {
    this.postion = new L.LatLng(lat, lng, alt);
  }
  updateOptionProperty(key: string, value: any) {
    this.option[key] = value;
  }

  public resetFocus() {
    this.markerRef.setOpacity(1);
  }

  public unsetFocus() {
    this.markerRef.setOpacity(0.2);
  }

  addPopup(content: string | HTMLElement) {
    this.popup = L.popup().setLatLng(this.postion).setContent(content);
  }

  addColor(color: string) {
    if (!color) {
      return;
    }
    this.color = color;
  }

  addTooltip(content: string | HTMLElement) {
    this.tooltip = L.tooltip({
      direction: 'right',
    }).setContent(content);
  }

  addLabel(content: string | HTMLElement) {
    this.label = L.tooltip({ permanent: true, direction: 'top' }).setContent(content);
  }

  buildMarker(map?: L.Map) {
    this.option.icon = this.getMarkerIcon();
    this.markerRef = L.marker(this.postion, this.option);

    if (this.tooltip) {
      this.markerRef.bindTooltip(this.tooltip);
    }
    if (this.label) {
      const option: L.MarkerOptions = {
        icon: L.divIcon({
          className: 'my-custom-pin',
          tooltipAnchor: [-12, -26],
          iconSize: [1, 1],
          html: ``,
        }),
      };

      this.markerLabelRef = L.marker(this.postion, option);
      this.markerLabelRef.bindTooltip(this.label);
      //  this.markerLabelRef.openTooltip();
    }
    if (this.popup) {
      this.markerRef.bindPopup(this.popup);
    }
    if (this.onClickCallback) {
      this.markerRef.on('click', this.onClickCallback);
    }

    // this.markerRef.on('mouseover', this.setFocus.bind(this));
    // this.markerRef.on('mouseout', this.resetFocus.bind(this));

    if (map) {
      if (this.markerLabelRef) {
        this.markerLabelRef.addTo(map);
      }
      this.markerRef.addTo(map);
      this.alive = true;
    }
  }

  toogleState(map: L.Map) {
    if (this.alive) {
      if (this.markerLabelRef) {
        this.markerLabelRef.closeTooltip();
        this.markerLabelRef.onRemove(map);
      }
      this.markerRef.onRemove(map);
      this.alive = !this.alive;
    } else {
      this.buildMarker(map);
    }
  }

  destroy(map: L.Map) {
    if (this.alive) {
      if (this.markerLabelRef) {
        this.markerLabelRef.closeTooltip();
        this.markerLabelRef.onRemove(map);
      }
      this.markerRef.onRemove(map);
      this.alive = false;
    }
  }

  rebuildWhenDead(map: L.Map) {
    if (!this.alive) {
      this.buildMarker(map);
    }
  }

  private getMarkerIcon() {
    if (this.iconpath) {
      return L.icon({
        iconUrl: this.iconpath,
        tooltipAnchor: [12, -12],
        popupAnchor: [0, 0],
        iconAnchor: [12, 26],
        iconSize: [24, 24], // size of the icon
      });
    } else {
      const markerHtmlStyles = `
          background-color: ${this.color};
          display: block;
          width: 22px;
          height: 22px;
          position: relative;
          border: 1px solid #FFFFFF;
          transform: rotateZ(45deg);
          top: -2px;
          border-top-left-radius: 12px;
          border-top-right-radius: 10px;
          border-bottom-left-radius: 10px;`;

      return L.divIcon({
        className: 'marker-default-icon',
        iconAnchor: [12, 26],
        tooltipAnchor: [12, -12],
        iconSize: [1, 1],
        html: `<span style="${markerHtmlStyles}"> </span>`,
      });
    }
  }

  public startFollowMovement(map: L.Map, tick: number) {
    if (!this.alive || !this.nextMarker || this.prevMarker || this.onPlay) {
      return;
    }
    this.markerRef.addTo(map);
    this.onPlay = true;
    this.playMakerRef = L.marker(this.postion, this.option).addTo(map);

    const nextMarker = this.nextMarker;
    nextMarker.markerRef.addTo(map);

    const startTime = this.startPlayTime.getTime();
    const endTime = nextMarker.endPlayTime.getTime();
    let duration = ((endTime - startTime) / tick) * 1000;
    duration = duration < 1001 ? 1000 : duration;
    this.moveToNextMarker(nextMarker, duration);

    this.playTimeoutId = setTimeout(() => {
      this.stopPlaying(map);
      this.playedFinish = true;
    }, duration + 50);
  }

  public stopPlaying(map: L.Map) {
    clearInterval(this.playTimeoutId);
    this.onPlay = false;
    this.playMakerRef.removeFrom(map);
  }

  private moveToNextMarker(nextMarker: MarkersTemplate, duration: number) {
    (this.playMakerRef as any).slideTo(nextMarker.postion, {
      duration,
      keepAtCenter: false,
    });
  }
}

export class TransitionLine {
  public postions: L.LatLng[] = [];
  public color = '#583470';
  public options: L.PolylineOptions = {
    stroke: false,
    fill: false,
  };
  public polylineDecoratorOptions: L.PolylineDecoratorOptions = {
    patterns: [
      {
        offset: '100%',
        repeat: 0,
        symbol: L.Symbol.arrowHead({
          pixelSize: 15,
          polygon: false,
          pathOptions: { stroke: true, color: this.color, opacity: 1 },
        }),
      },
    ],
  };
  public polyineRef: L.Polyline;
  public polyineDecoratorRef: L.PolylineDecorator;
  private tooltip: L.Tooltip;
  private popup: L.Popup;
  private lineStyle: ETransitionLineStyleTypes;
  public onClickCallback: any;
  public canonicalName: string;
  public resourceId: string;
  private alive = false;
  public sourceLocationId: string;
  public destinationLocationId: string;
  public sourceLocationTime: number;
  public destinationLocationTime: number;

  constructor(public name: string, public id: string, public refId: string) {}

  addPopup(content: string | HTMLElement) {
    this.popup = L.popup({}).setContent(content);
  }

  addTooltip(content: string | HTMLElement) {
    this.tooltip = L.tooltip().setContent(content);
  }

  addPostion(position: L.LatLng) {
    this.postions.push(position);
    if (this.polyineRef) {
      this.polyineRef.addLatLng(position);
    }
  }

  addColor(color: string) {
    if (!color) {
      return;
    }
    this.color = color;
    this.polylineDecoratorOptions.patterns = [
      {
        offset: '100%',
        repeat: 0,
        symbol: L.Symbol.arrowHead({
          pixelSize: 15,
          polygon: false,
          pathOptions: { stroke: true, color: this.color, opacity: 1, weight: 3 },
        }),
      },
    ];
    this.options.color = this.color;
  }

  setLineStyle(style: ETransitionLineStyleTypes) {
    this.lineStyle = style;
    switch (style) {
      case ETransitionLineStyleTypes.DASH_DOTTED_LINE:
        this.addDecoratorPattern({
          offset: 0,
          repeat: 25,
          symbol: L.Symbol.dash({ pixelSize: 10, pathOptions: { stroke: true, color: this.color } }),
        });
        this.addDecoratorPattern({
          offset: 12,
          repeat: 25,
          symbol: L.Symbol.dash({ pixelSize: 0, pathOptions: { color: this.color } }),
        });
        return;
      case ETransitionLineStyleTypes.DASHED_LINE:
        this.addDecoratorPattern({
          offset: 0,
          repeat: 15,
          symbol: L.Symbol.dash({ pixelSize: 10, pathOptions: { color: this.color } }),
        });
        return;
      case ETransitionLineStyleTypes.DOTTED_LINE:
        this.addDecoratorPattern({
          offset: 0,
          repeat: 10,
          symbol: L.Symbol.dash({ pixelSize: 0, pathOptions: { color: this.color } }),
        });
        return;
      case ETransitionLineStyleTypes.SOLID_LINE:
      default:
        this.options.stroke = true;
        this.options.fill = false;
        this.options.color = this.color;
    }
  }

  private addDecoratorPattern(pattern: L.Pattern) {
    if (!this.polylineDecoratorOptions.patterns) {
      this.polylineDecoratorOptions.patterns = [
        {
          offset: '100%',
          repeat: 0,
          symbol: L.Symbol.arrowHead({
            pixelSize: 15,
            polygon: false,
            pathOptions: { stroke: true, color: this.color, opacity: 1 },
          }),
        },
      ];
    }

    this.polylineDecoratorOptions.patterns.push(pattern);
  }

  reducePixelSizeOfPattern(pixelSize: number) {
    this.polylineDecoratorOptions.patterns = [
      {
        offset: '100%',
        repeat: 0,
        symbol: L.Symbol.arrowHead({
          pixelSize,
          polygon: false,
          pathOptions: { stroke: true, color: this.color, opacity: 1 },
        }),
      },
    ];
    this.setLineStyle(this.lineStyle);
    this.polyineRef.setStyle(this.options);
  }

  public resetFocus() {
    this.options.opacity = 1;
    this.polyineRef.setStyle(this.options);
    this.polyineDecoratorRef.setStyle(this.options);
  }

  public unsetFocus() {
    this.options.opacity = 0.2;
    this.polyineRef.setStyle(this.options);
    this.polyineDecoratorRef.setStyle(this.options);
  }

  buildLine(map?: L.Map) {
    this.polyineRef = L.polyline(this.postions, this.options);
    this.polyineDecoratorRef = L.polylineDecorator(this.polyineRef, this.polylineDecoratorOptions);
    if (this.tooltip) {
      this.polyineRef.bindTooltip(this.tooltip);
    }
    if (this.popup) {
      this.polyineRef.bindPopup(this.popup);
    }
    if (this.onClickCallback) {
      this.polyineRef.on('click', this.onClickCallback);
    }
    //  this.polyineRef.on('mouseover', this.setFocus.bind(this));
    // this.polyineDecoratorRef.on('mouseover', this.setFocus.bind(this));
    // this.polyineRef.on('mouseout', this.resetFocus.bind(this));
    // this.polyineDecoratorRef.on('mouseout', this.resetFocus.bind(this));

    if (map) {
      this.polyineRef.addTo(map);
      this.polyineDecoratorRef.addTo(map);
      this.alive = true;
    }
  }

  rebuildLine(map: L.Map) {
    this.polyineRef = L.polyline(this.postions, this.options).addTo(map);
  }

  replaceDecorator(map: L.Map) {
    // if(this.polyineDecoratorRef.on)
    if (this.alive) {
      this.polyineDecoratorRef.removeFrom(map);
      this.polyineDecoratorRef = L.polylineDecorator(this.polyineRef, this.polylineDecoratorOptions).addTo(map);
    }
  }

  toogleState(map: L.Map) {
    if (this.alive) {
      this.polyineRef.onRemove(map);
      this.polyineDecoratorRef.onRemove(map);
      this.alive = !this.alive;
    } else {
      this.buildLine(map);
    }
  }

  destroy(map: L.Map) {
    if (this.alive) {
      this.polyineRef.onRemove(map);
      this.polyineDecoratorRef.onRemove(map);
      this.alive = false;
    }
  }

  rebuildWhenDead(map: L.Map) {
    if (!this.alive) {
      this.buildLine(map);
    }
  }
}
