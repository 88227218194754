import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'nn-attribute-legend',
  templateUrl: './attribute-legend.component.html',
  styleUrls: ['./attribute-legend.scss'],
})

/**
 * Angular component which handels color legend for node network nodes.
 */
export class NodeNetworkAttributeLegend {
  @Input('data') public set value(val: any) {
    this.setColorLabelMap(val);
  }
  @Input('enableFilterByClick') enableFilter: boolean;
  @Output() filterNodes: EventEmitter<any> = new EventEmitter<any>();

  public colorLabelList: { label: string; color: string }[];
  public filteredNodes: string[];
  public showLegend = true;

  constructor() {
    this.colorLabelList = [];
    this.filteredNodes = [];
  }

  /**
   * Execution of active filters to hide nodes from nodenetwork.
   * Toggle given item to active filter list.
   * @param selectedItem Menu entry which has been clicked.
   */
  public filterNode(selectedItem: { label: string; color: string }) {
    if (!this.enableFilter === true) return;

    const filterAttribute = selectedItem.label;
    if (filterAttribute) {
      const index = this.filteredNodes.indexOf(filterAttribute);

      // add new filtered element to list
      if (index == -1) {
        this.filteredNodes.push(filterAttribute);
      }
      // remove filter-element from list
      else {
        this.filteredNodes.splice(index, 1);
      }
      this.filterNodes.emit(this.filteredNodes);
    }
  }

  /**
   * Sets colors for specified attribute to colorize nodes.
   * @param colorLabelMap JSON with attribute value as key and matching color as value.
   */
  public setColorLabelMap(colorLabelMap: any) {
    this.colorLabelList = [];

    for (const key in colorLabelMap) {
      const color = colorLabelMap[key];

      this.colorLabelList.push({
        label: key,
        color: color,
      });
    }
  }

  /**
   * Opens/closes node legend.
   */
  public toggleLegend(): void {
    this.showLegend = !this.showLegend;
  }
}
