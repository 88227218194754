import { GanttResponse } from 'frontend/src/dashboard/gantt/general/response/responses/response';
import { GanttEssentialPlugIns } from '../../../../e-gantt-essential-plugins';
import { GanttIndexCardsPlugIn } from '../../index-cards.plugin';

/**
 * Response handling for index cards.
 * It updates all changed rows for index card view.
 */
export class GanttResponseIndexCardUpdate extends GanttResponse {
  /**
   * Updates all changed rows by given gantt entries for index card view.
   * @param response Serverreponse.
   */
  public handleResponse(response: any): boolean {
    if (response.ganttEntries || response.addedGanttEntries) {
      const indexCardPlugIn: GanttIndexCardsPlugIn = this._pluginHandlerService.getEssentialPlugIn(
        GanttEssentialPlugIns.IndexCardBuilderPlugIn
      );
      const indexCardData = indexCardPlugIn.extractIndexCardData();
      this.executer.setDataSet(indexCardData);
      return true;
    } else {
      return false;
    }
  }
}
