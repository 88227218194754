import * as d3 from 'd3';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { GanttCallBackStackExecuter } from '../callback-tools/callback-stack-executer';
import { GanttConfig } from '../config/gantt-config';
import { ShiftDataFinder } from '../data-handler/data-finder/shift-data-finder';
import { DataHandler } from '../data-handler/data-handler';
import { EGanttTextStrategy } from '../data-handler/data-structure/data-structure-enums';
import { GanttHTMLStructureBuilder } from '../html-structure/html-structure-builder';
import { NodeProportionsState } from '../html-structure/node-proportion-state/node-proportion-state';
import { NodeProportionsStateConnector } from '../html-structure/node-proportion-state/node-proportion-state-connector';
import { INodeProportion } from '../html-structure/node-proportion-state/node-proportion.interface';
import { EGanttScrollContainer } from '../html-structure/scroll-container.enum';
import { BestGantt } from '../main';
import { PatternHandler } from '../pattern/patternHandler';
import {
  GanttRenderDataSetShifts,
  GanttRenderDataSetYAxis,
} from '../render-data-handler/data-structure/render-data-structure';
import { TooltipBuilder } from '../tooltip/tooltip-builder';
import { VerticalScrollHandler } from '../vertical-scroll/vertical-scroll-handler';
import { GanttShiftCanvasGenerator } from './canvas-generator';
import { GanttShiftsBuildingCanvas } from './shift-build-strategies/shift-build-canvas';
import { ShiftBuilder } from './shift-builder';
import { GanttBasicShiftBuilderFacade } from './shiftbuilder-facade.base';
import { TextOverlay } from './text-overlay';

/**
 * Facade for shift and milestone building and tooltip handling.
 * @keywords shift, builder, facade, render, handler, wrapper
 */
export class GanttShiftBuilderFacade extends GanttBasicShiftBuilderFacade {
  private _parentNode: { [id: string]: d3.Selection<HTMLDivElement, undefined, d3.BaseType, undefined> } = {};
  private _canvasInFrontShifts: { [id: string]: d3.Selection<SVGSVGElement, undefined, d3.BaseType, undefined> } = {};
  private _canvasBehindShifts: { [id: string]: d3.Selection<SVGSVGElement, undefined, d3.BaseType, undefined> } = {};
  private _shiftRenderCanvas: { [id: string]: d3.Selection<HTMLCanvasElement, undefined, d3.BaseType, undefined> } = {};
  private _shiftWrapperOverlay: d3.Selection<HTMLDivElement, undefined, d3.BaseType, undefined> = undefined;
  private _shiftGroupOverlay: d3.Selection<SVGGElement, undefined, d3.BaseType, undefined> = undefined;
  private _shiftGroupOverlayDefs: d3.Selection<SVGGElement, undefined, d3.BaseType, undefined> = undefined;

  private _textOverlay: { [id: string]: TextOverlay } = {};
  private _tooltipBuilder: TooltipBuilder = undefined;
  private _patternHandler: PatternHandler = undefined;
  private _canvasAnimationHandler: { [id: string]: GanttShiftCanvasGenerator } = {};

  private _onDestroySubject: Subject<void> = new Subject<void>();

  private _afterGanttVerticalResizeCbs: { [id: string]: (newHeight: number) => void } = {};

  /**
   * @param _ganttDiagram Reference to the gantt diagram to be used.
   */
  constructor(private _ganttDiagram: BestGantt) {
    super();

    this._initParentNode();

    this._listenToProportionChanges();
    this._listenToScrollPosition();
  }

  /**
   * Initializes the parent nodes for all scroll containers.
   */
  private _initParentNode(): void {
    for (const scrollContainerId of Object.values(EGanttScrollContainer)) {
      this._parentNode[scrollContainerId] = this._htmlStructureBuilder.getShiftContainer(scrollContainerId);
    }
  }

  /**
   * Initialize shift builder facade.
   * @keywords init, initialize, shift, facade
   */
  public init(): void {
    // init shift container overlay
    this._initShiftContainerOverlay();

    // init pattern handler
    if (!this._patternHandler) {
      this._patternHandler = new PatternHandler(
        this._shiftGroupOverlayDefs.select<SVGDefsElement>('defs').node(),
        this._ganttConfig
      );
    }

    // init shift builders for each scroll container
    this._initParentNodeProportions();
    for (const scrollContainerId of Object.values(EGanttScrollContainer)) {
      this._init(scrollContainerId);
    }

    // init callbacks
    this._addResizeCallback();
  }

  /**
   * Initializes a shift builder for the specified scroll container.
   * @param scrollContainerId Id of the scroll container to initialize the shift builder for.
   */
  private _init(scrollContainerId: EGanttScrollContainer): void {
    const defaultShiftRenderStrategy = new GanttShiftsBuildingCanvas(this.getShiftGroupOverlay());

    this._canvasBehindShifts[scrollContainerId] = this._parentNode[scrollContainerId]
      .append<SVGSVGElement>('svg')
      .attr('class', 'gantt_shifts_behind-canvas')
      .attr('width', this._nodeProportionsState.getShiftViewPortProportions(scrollContainerId).width)
      .attr('height', 10000)
      .style('background-color', this._ganttConfig.rowBackgroundColor(scrollContainerId));

    this._shiftRenderCanvas[scrollContainerId] = this._parentNode[scrollContainerId]
      .append('canvas')
      .attr('width', this._nodeProportionsState.getShiftViewPortProportions(scrollContainerId).width)
      .attr('height', this._nodeProportionsState.getShiftViewPortProportions(scrollContainerId).height)
      .attr('class', 'render-canvas');

    this._canvasInFrontShifts[scrollContainerId] = this._parentNode[scrollContainerId]
      .append<SVGSVGElement>('svg')
      .attr('class', 'gantt_shifts_in-front-canvas')
      .attr('width', this._nodeProportionsState.getShiftViewPortProportions(scrollContainerId).width)
      .attr('height', 10000);

    this._initTextOverlay(scrollContainerId);

    const shiftBuilder = new ShiftBuilder(
      this._parentNode[scrollContainerId],
      this._canvasInFrontShifts[scrollContainerId],
      this._canvasBehindShifts[scrollContainerId],
      this._shiftRenderCanvas[scrollContainerId],
      this._ganttDiagram,
      this._textOverlay[scrollContainerId],
      scrollContainerId
    );
    this._addShiftBuilder(scrollContainerId, shiftBuilder);

    this._ganttConfig.setShiftBuildingRoundedCorners(true);

    this.getShiftBuilder(scrollContainerId).init(defaultShiftRenderStrategy);

    this._canvasAnimationHandler[scrollContainerId] = new GanttShiftCanvasGenerator(
      this._parentNode[scrollContainerId].node(),
      this._ganttConfig,
      defaultShiftRenderStrategy,
      this.getShiftBuilder(scrollContainerId),
      this._nodeProportionsState,
      this._verticalScrollHandler,
      scrollContainerId
    );
    this._canvasAnimationHandler[scrollContainerId].init();

    this._addTooltipCallback(scrollContainerId);
  }

  /**
   * Initializes all global shift building layers.
   */
  private _initShiftContainerOverlay(): void {
    this._shiftWrapperOverlay = this._htmlStructureBuilder
      .getVerticalScrollContainerOverlay()
      .append('div')
      .attr('class', 'gantt_shifts-wrapper-overlay')
      .style('width', () => {
        const widthDiff =
          this._nodeProportionsState.getYAxisProportions().width + this._ganttConfig.verticalScrollbarWidth;
        return `calc(100% - ${widthDiff}px)`;
      })
      .style('height', '100%')
      .style('left', `${this._nodeProportionsState.getYAxisProportions().width}px`);

    const shiftWrapperOverlaySvg = this._shiftWrapperOverlay
      .append<SVGSVGElement>('svg')
      .attr('class', 'gantt_shifts-wrapper-overlay-svg')
      .attr('width', '100%')
      .attr('height', '100%');

    this._nodeProportionsState
      .select('yAxisProportions')
      .pipe(takeUntil(this._ganttDiagram.onDestroy))
      .subscribe((yAxisProportions) => {
        this._shiftWrapperOverlay
          .style('width', () => {
            const widthDiff =
              this._nodeProportionsState.getYAxisProportions().width + this._ganttConfig.verticalScrollbarWidth;
            return `calc(100% - ${widthDiff}px)`;
          })
          .style('left', `${yAxisProportions.width}px`);
      });

    this._shiftGroupOverlayDefs = shiftWrapperOverlaySvg.append('g').attr('class', 'gantt_defs');
    this._shiftGroupOverlayDefs.append('defs');

    this._shiftGroupOverlay = shiftWrapperOverlaySvg.append('g').attr('class', 'gantt_shift-group-overlay');
  }

  public destroy(): void {
    // destroy shift builder instances
    super.destroy();

    for (const scrollContainerId of this._ganttDiagram.getRenderDataHandler().getAllScrollContainerIds()) {
      this._canvasAnimationHandler[scrollContainerId].destroy();
    }

    // trigger unsubscriptions
    this._onDestroySubject.next();
    this._onDestroySubject.complete();
  }

  /**
   * Handles creation of text overlays.
   */
  private _initTextOverlay(scrollContainerId: EGanttScrollContainer) {
    const textOverlayContainer = this._getTextOverlayContainer(scrollContainerId);
    this._textOverlay[scrollContainerId] = new TextOverlay(
      textOverlayContainer,
      this._ganttConfig,
      this._nodeProportionsState,
      this._dataHandler,
      this._ganttDiagram,
      scrollContainerId
    );
    this._textOverlay[scrollContainerId].init();
  }

  /**
   * Adds tooltip callback to shifts.
   */
  private _addTooltipCallback(scrollContainerId: EGanttScrollContainer): void {
    this.getShiftBuilder(scrollContainerId)
      .shiftMouseOver()
      .subscribe((event) => this.openTooltip(event));
    this.getShiftBuilder(scrollContainerId)
      .enterCanvas()
      .subscribe(() => this.closeTooltip());
    this._htmlStructureBuilder
      .getShiftContainer(scrollContainerId)
      .node()
      .addEventListener('mouseleave', this.closeTooltip.bind(this)); // use default event listener here, d3 event listener does not work
  }

  private _addResizeCallback(): void {
    this._ganttConfig
      .onShiftHeightChanged()
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => this.updateSize());
  }

  /**
   * Move canvas to viewport
   */
  private _listenToScrollPosition(): void {
    for (const scrollContainerId of Object.values(EGanttScrollContainer)) {
      this._nodeProportionsState
        .select(
          scrollContainerId === EGanttScrollContainer.STICKY_ROWS ? 'scrollTopPositionSticky' : 'scrollTopPosition'
        )
        .pipe(takeUntil(this.onDestroy), distinctUntilChanged())
        .subscribe((scrollTop) => {
          this._shiftRenderCanvas[scrollContainerId]?.style('top', `${scrollTop}px`);
        });
    }
  }

  /**
   * Opens tooltip.
   * @keywords tooltip, open, build, show, description, shift
   * @param {MouseEvent} mouseOverEvent Event to get coordinates for tooltip position.
   */
  openTooltip(mouseOverEvent) {
    const mouseOffset = [mouseOverEvent.x, mouseOverEvent.y],
      target = d3.select(mouseOverEvent.target),
      targetData: any = target.data()[0],
      shiftData = ShiftDataFinder.getShiftById(this._dataHandler.getOriginDataset().ganttEntries, targetData.id).shift;

    if (shiftData && shiftData.tooltip) {
      this.getTooltipBuilder().addTooltipToHTMLBody(
        mouseOffset[0],
        mouseOffset[1],
        shiftData.tooltip,
        this._htmlStructureBuilder.getShiftContainer().node()
      );
    }
  }

  /**
   * Close all tooltips.
   * @keywords tooltip, close, remove, delete, clear, hide, description, shift
   */
  closeTooltip() {
    this.getTooltipBuilder().removeAllTooltips();
  }

  /**
   * Zooms shifts in horizontal direction.
   * @keywords scale, horizontal, zoom, width, time
   * @param zoom Zoom Event to scale shifts and milestone positions.
   */
  public scaleHorizontal(zoom: { transform: d3.ZoomTransform }): void {
    for (const scrollContainerId of Object.values(EGanttScrollContainer)) {
      this.getShiftBuilder(scrollContainerId).scaleHorizontal(zoom);
    }
  }

  /**
   * Creates a text overlay div container for displaying texts of shifts and time periods.
   */
  private _getTextOverlayContainer(
    scrollContainerId: EGanttScrollContainer
  ): d3.Selection<HTMLDivElement, undefined, d3.BaseType, undefined> {
    const nodeProportionsState = new NodeProportionsStateConnector(this._nodeProportionsState, scrollContainerId);

    const top = () =>
      scrollContainerId === EGanttScrollContainer.DEFAULT
        ? nodeProportionsState.getScrollTopPosition() +
          this._nodeProportionsState.getShiftViewPortProportions(EGanttScrollContainer.STICKY_ROWS).height +
          (this._nodeProportionsState.getShiftViewPortProportions(EGanttScrollContainer.STICKY_ROWS).height > 0 ? 1 : 0)
        : nodeProportionsState.getScrollTopPosition();
    const height = () =>
      scrollContainerId === EGanttScrollContainer.DEFAULT
        ? nodeProportionsState.getShiftViewPortProportions().height -
          this._nodeProportionsState.getShiftViewPortProportions(EGanttScrollContainer.STICKY_ROWS).height -
          (this._nodeProportionsState.getShiftViewPortProportions(EGanttScrollContainer.STICKY_ROWS).height > 0 ? 1 : 0)
        : nodeProportionsState.getShiftViewPortProportions().height;

    const textOverlayContainerWrapper = this._parentNode[scrollContainerId]
      .append('div')
      .attr('class', 'gantt_text_container_wrapper')
      .style('position', 'absolute')
      .style('top', `${top()}px`)
      .style('width', '100%')
      .style('height', `${height()}px`);
    const textOverlayContainer = textOverlayContainerWrapper
      .append('div')
      .attr('class', 'gantt_text_container')
      .style('position', 'absolute')
      .style('top', `${-top()}px`)
      .style('z-index', 1)
      .style('width', '100%')
      .style('height', '100%');

    this._nodeProportionsState
      .select(scrollContainerId === EGanttScrollContainer.STICKY_ROWS ? 'scrollTopPositionSticky' : 'scrollTopPosition')
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        textOverlayContainerWrapper.style('top', `${top()}px`);
        textOverlayContainer.style('top', `${-top()}px`);
      });
    this._nodeProportionsState
      .select(
        scrollContainerId === EGanttScrollContainer.STICKY_ROWS
          ? 'shiftViewPortProportionsSticky'
          : 'shiftViewPortProportions'
      )
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        textOverlayContainerWrapper.style('height', `${height()}px`).style('top', `${top()}px`);
        textOverlayContainer.style('top', `${-top()}px`);
      });
    if (scrollContainerId === EGanttScrollContainer.DEFAULT) {
      this._nodeProportionsState
        .select('shiftViewPortProportionsSticky')
        .pipe(takeUntil(this.onDestroy))
        .subscribe(() => {
          textOverlayContainerWrapper.style('height', `${height()}px`).style('top', `${top()}px`);
          textOverlayContainer.style('top', `${-top()}px`);
        });
    }

    return textOverlayContainer;
  }

  //
  // UPDATE SIZE
  //

  /**
   * Updates the sizes of the shift builder containers.
   */
  public updateSize(): void {
    this._initParentNodeProportions();
  }

  private _listenToProportionChanges(): void {
    for (const scrollContainerId of Object.values(EGanttScrollContainer)) {
      // listen to shift view port changes
      this._ganttDiagram
        .getNodeProportionsState()
        .select(
          scrollContainerId === EGanttScrollContainer.STICKY_ROWS
            ? 'shiftViewPortProportionsSticky'
            : 'shiftViewPortProportions'
        )
        .pipe(takeUntil(this.onDestroy), distinctUntilChanged(this.hasNodeProportionChanged))
        .subscribe((proportions) => {
          this._canvasBehindShifts[scrollContainerId]?.attr('width', proportions.width);
          this._canvasBehindShifts[scrollContainerId]?.attr('height', proportions.height);

          this._canvasInFrontShifts[scrollContainerId]?.attr('width', proportions.width);
          this._canvasInFrontShifts[scrollContainerId]?.attr('height', proportions.height);

          this._shiftRenderCanvas[scrollContainerId]?.attr('width', proportions.width);
          this._shiftRenderCanvas[scrollContainerId]?.attr('height', proportions.height);

          this._canvasAnimationHandler[scrollContainerId]?.refreshCanvasSize(proportions.width, proportions.height);
        });

      // listen to shift canvas changes
      this._ganttDiagram
        .getNodeProportionsState()
        .select(
          scrollContainerId === EGanttScrollContainer.STICKY_ROWS
            ? 'shiftCanvasProportionsSticky'
            : 'shiftCanvasProportions'
        )
        .pipe(takeUntil(this.onDestroy), distinctUntilChanged(this.hasNodeProportionChanged))
        .subscribe((proportions) => {
          this._htmlStructureBuilder?.getShiftContainer(scrollContainerId)?.style('height', proportions.height + 'px');
          this._htmlStructureBuilder?.getYAxisContainer(scrollContainerId)?.style('height', proportions.height + 'px');
        });
    }
  }

  private hasNodeProportionChanged(previous: INodeProportion, current: INodeProportion) {
    return !(previous.height !== current.height || previous.width !== current.width);
  }

  /**
   * Updates the color of  shift canvas layer.
   */
  public updateCanvasBackgroundColor(): void {
    for (const scrollContainerId of Object.values(EGanttScrollContainer)) {
      this._canvasBehindShifts[scrollContainerId].style(
        'background-color',
        this._ganttConfig.rowBackgroundColor(scrollContainerId)
      );
    }
  }

  /**
   * Initializes the parent node propotions for all scroll containers.
   */
  private _initParentNodeProportions(): void {
    const width = this._parentNode[EGanttScrollContainer.DEFAULT].node().clientWidth;

    for (const scrollContainerId of this._ganttDiagram.getRenderDataHandler().getActiveScrollContainerIds()) {
      const parentNodeProportions: INodeProportion = {
        width: width,
        height: this._htmlStructureBuilder.getVerticalScrollContainer(scrollContainerId).node().clientHeight,
      };
      this._nodeProportionsState.setShiftViewPortProportions(parentNodeProportions, scrollContainerId);
    }
  }

  public update(): void {
    for (const scrollContainerId of this._ganttDiagram.getRenderDataHandler().getActiveScrollContainerIds()) {
      this.getShiftBuilder(scrollContainerId).update();
    }
  }

  public renderShifts(renderDataSet: GanttRenderDataSetShifts): void {
    for (const scrollContainerId of this._ganttDiagram.getRenderDataHandler().getActiveScrollContainerIds()) {
      this.getShiftBuilder(scrollContainerId).renderShifts(renderDataSet[scrollContainerId], undefined);
    }
  }

  public removeAllShifts(): void {
    for (const scrollContainerId of this._ganttDiagram.getRenderDataHandler().getActiveScrollContainerIds()) {
      this.getShiftBuilder(scrollContainerId).removeAllShifts();
    }
  }

  public reBuildShifts(renderDataSet: GanttRenderDataSetShifts): void {
    for (const scrollContainerId of this._ganttDiagram.getRenderDataHandler().getActiveScrollContainerIds()) {
      this.getShiftBuilder(scrollContainerId).reBuildShifts(renderDataSet[scrollContainerId]);
    }
  }

  public renderHorizontalLines(renderDataSet: GanttRenderDataSetYAxis): void {
    for (const scrollContainerId of this._ganttDiagram.getRenderDataHandler().getActiveScrollContainerIds()) {
      this.getShiftBuilder(scrollContainerId).renderHorizontalLines(renderDataSet[scrollContainerId]);
    }
  }

  public checkHorizontalLinesVisibility(): void {
    for (const scrollContainerId of this._ganttDiagram.getRenderDataHandler().getActiveScrollContainerIds()) {
      this.getShiftBuilder(scrollContainerId).checkHorizontalLinesVisibility();
    }
  }

  public renderVerticalLines(xAxisGenerators: d3.Axis<Date>[]): void {
    for (const scrollContainerId of this._ganttDiagram.getRenderDataHandler().getActiveScrollContainerIds()) {
      this.getShiftBuilder(scrollContainerId).renderVerticalLines(xAxisGenerators);
    }
  }

  public checkVerticalLinesVisibility(): void {
    for (const scrollContainerId of this._ganttDiagram.getRenderDataHandler().getActiveScrollContainerIds()) {
      this.getShiftBuilder(scrollContainerId).checkVerticalLinesVisibility();
    }
  }

  public preloadPatterns(): void {
    for (const scrollContainerId of this._ganttDiagram.getRenderDataHandler().getActiveScrollContainerIds()) {
      this.getShiftBuilder(scrollContainerId).preloadPatterns();
    }
  }

  public clearTimeouts(): void {
    for (const scrollContainerId of this._ganttDiagram.getRenderDataHandler().getActiveScrollContainerIds()) {
      this.getShiftBuilder(scrollContainerId).clearTimeouts();
    }
  }

  public rerenderTextOverlay(renderDataSet: GanttRenderDataSetShifts): void {
    for (const scrollContainerId of this._ganttDiagram.getRenderDataHandler().getActiveScrollContainerIds()) {
      this._textOverlay[scrollContainerId].reRender(
        renderDataSet[scrollContainerId],
        this._ganttDiagram.getXAxisBuilder().getLastZoomEvent()
      );
    }
  }

  public changeTextOverlayStrategy(strategy: EGanttTextStrategy): void {
    for (const scrollContainerId of this._ganttDiagram.getRenderDataHandler().getAllScrollContainerIds()) {
      this._textOverlay[scrollContainerId].changeTextOverlayStrategy(strategy);
    }
  }

  public rerenderShiftArea(renderDataSet: GanttRenderDataSetShifts, zoomTransform: d3.ZoomTransform = undefined): void {
    for (const scrollContainerId of this._ganttDiagram.getRenderDataHandler().getActiveScrollContainerIds()) {
      this._canvasAnimationHandler[scrollContainerId].rerenderShiftArea(
        renderDataSet[scrollContainerId],
        undefined,
        zoomTransform || this._ganttDiagram.getXAxisBuilder().getLastZoomEvent()
      );
    }
  }

  //
  // RESTRICTIONS
  //

  public setShiftsAreResizing(bool: boolean): void {
    for (const scrollContainerId of this._ganttDiagram.getRenderDataHandler().getAllScrollContainerIds()) {
      this.getShiftBuilder(scrollContainerId).setShiftsAreResizing(bool);
    }
  }

  //
  // GETTER & SETTER
  //

  /**
   * Helper getter which returns the gantt config of the current gantt.
   */
  private get _ganttConfig(): GanttConfig {
    return this._ganttDiagram.getConfig();
  }

  /**
   * Helper getter which returns the data handler of the current gantt.
   */
  private get _dataHandler(): DataHandler {
    return this._ganttDiagram.getDataHandler();
  }

  /**
   * Helper getter which returns the node proportions state of the current gantt.
   */
  private get _nodeProportionsState(): NodeProportionsState {
    return this._ganttDiagram.getNodeProportionsState();
  }

  /**
   * Helper getter which returns the HTML structure builder of the current gantt.
   */
  private get _htmlStructureBuilder(): GanttHTMLStructureBuilder {
    return this._ganttDiagram.getHTMLStructureBuilder();
  }

  /**
   * Helper getter which returns the vertical scroll handler of the current gantt.
   */
  private get _verticalScrollHandler(): VerticalScrollHandler {
    return this._ganttDiagram.getVerticalScrollHandler();
  }

  /**
   * Helper getter which returns the default shift builder.
   */
  private get _shiftBuilderDefault(): ShiftBuilder {
    return this.getShiftBuilder(EGanttScrollContainer.DEFAULT);
  }

  /**
   * Helper getter which returns the sticky rows shift builder.
   */
  private get _shiftBuilderSticky(): ShiftBuilder {
    return this.getShiftBuilder(EGanttScrollContainer.STICKY_ROWS);
  }

  /**
   * @return D3 Selection of canvas group.
   */
  public getCanvasInFrontShifts(
    scrollContainerId: EGanttScrollContainer = EGanttScrollContainer.DEFAULT
  ): d3.Selection<SVGSVGElement, undefined, d3.BaseType, undefined> {
    return this._canvasInFrontShifts[scrollContainerId];
  }

  /**
   * @return D3 Selection of canvas group.
   */
  public getCanvasBehindShifts(
    scrollContainerId: EGanttScrollContainer = EGanttScrollContainer.DEFAULT
  ): d3.Selection<SVGSVGElement, undefined, d3.BaseType, undefined> {
    return this._canvasBehindShifts[scrollContainerId];
  }

  /**
   * @return D3 Selection of canvas group.
   */
  public getShiftCanvas(
    scrollContainerId: EGanttScrollContainer = EGanttScrollContainer.DEFAULT
  ): d3.Selection<HTMLCanvasElement, undefined, d3.BaseType, undefined> {
    return this._shiftRenderCanvas[scrollContainerId];
  }

  public getShiftWrapperOverlay(): d3.Selection<HTMLDivElement, undefined, d3.BaseType, undefined> {
    return this._shiftWrapperOverlay;
  }

  public getShiftGroupOverlay(): d3.Selection<SVGGElement, undefined, d3.BaseType, undefined> {
    return this._shiftGroupOverlay;
  }

  /**
   * Returns the d3 selection of the wrapper element containing the `defs` element in the scroll container-independent svg shifts layer.
   * @returns The d3 selection of the wrapper element containing the `defs` element in the scroll container-independent svg shifts layer.
   */
  public getShiftGroupOverlayDefs(): d3.Selection<SVGGElement, undefined, d3.BaseType, undefined> {
    return this._shiftGroupOverlayDefs;
  }

  /**
   * @return Parent node of canvas.
   */
  public getParentNode(
    scrollContainerId: EGanttScrollContainer = EGanttScrollContainer.DEFAULT
  ): d3.Selection<HTMLDivElement, undefined, d3.BaseType, undefined> {
    return this._parentNode[scrollContainerId];
  }

  /**
   * @param {TooltipBuilder} tooltipBuilder
   */
  setTooltipBuilder(tooltipBuilder) {
    this._tooltipBuilder = tooltipBuilder;
  }

  /**
   * @return {TooltipBuilder} Tooltip builder.
   */
  getTooltipBuilder() {
    return this._tooltipBuilder;
  }

  /**
   * Returns the {@link PatternHandler} of the gantt.
   * @returns The {@link PatternHandler} of the gantt.
   */
  public getPatternHandler(): PatternHandler {
    return this._patternHandler;
  }

  /**
   * Sets canvas height.
   * @param canvasHeightMap New height of canvas.
   */
  public setCanvasHeight(canvasHeightMap: Map<EGanttScrollContainer, number>): void {
    for (const scrollContainerId of canvasHeightMap.keys()) {
      this._canvasBehindShifts[scrollContainerId].attr('height', canvasHeightMap.get(scrollContainerId));
      this._canvasInFrontShifts[scrollContainerId].attr('height', canvasHeightMap.get(scrollContainerId));
    }
    GanttCallBackStackExecuter.execute(this._afterGanttVerticalResizeCbs, canvasHeightMap);
  }

  /**
   * Adds height to canvas.
   * @param height Height to add.
   */
  public addToCanvasHeight(
    height: number,
    scrollContainerId: EGanttScrollContainer = EGanttScrollContainer.DEFAULT
  ): void {
    const newHeight = height + parseInt(this._canvasBehindShifts[scrollContainerId].attr('height'));

    this._canvasBehindShifts[scrollContainerId].attr('height', newHeight);
    this._canvasInFrontShifts[scrollContainerId].attr('height', newHeight);
    GanttCallBackStackExecuter.execute(this._afterGanttVerticalResizeCbs, newHeight);
  }

  /**
   * @return {number} Vertical scroll position of parent node.
   */
  getVerticalScrollPos() {
    return this._nodeProportionsState.getScrollTopPosition();
  }

  public getCanvasAnimator(
    scrollContainerId: EGanttScrollContainer = EGanttScrollContainer.DEFAULT
  ): GanttShiftCanvasGenerator {
    return this._canvasAnimationHandler[scrollContainerId];
  }

  public getCanvasBehindShiftCanvasHeight(
    scrollContainerId: EGanttScrollContainer = EGanttScrollContainer.DEFAULT
  ): number {
    return parseInt(this._canvasBehindShifts[scrollContainerId].attr('height'));
  }

  public getTextOverlay(scrollContainerId: EGanttScrollContainer = EGanttScrollContainer.DEFAULT): TextOverlay {
    return this._textOverlay[scrollContainerId];
  }

  //
  // OBSERVABLES
  //

  /**
   * Observable which gets triggered when the instance gets destroyed.
   */
  private get onDestroy(): Observable<void> {
    return this._onDestroySubject.asObservable();
  }

  //
  //  CALLBACKS
  //

  public afterGanttVerticalResizeCallBack(id: string, func: (newHeight: number) => void): void {
    this._afterGanttVerticalResizeCbs[id] = func;
  }

  public removeAfterGanttVerticalResizeCallBack(id: string): void {
    delete this._afterGanttVerticalResizeCbs[id];
  }
}
