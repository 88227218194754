import { NgModule } from '@angular/core';
import { OperatorSelectorModule } from '@app-modeleditor/components/operator-selector/operator-selector.module';
import { SlotModule } from '@app-modeleditor/components/template/slot/slot.module';
import { TranslateModule } from '@ngx-translate/core';
import { MySharedModule } from '../../shared/shared.module';
import { TemplateDatepickerModule } from './../../modeleditor/components/template-datepicker/template-datepicker.module';
import { FreetextFilterComponent } from './freetext-filter.component';

@NgModule({
  imports: [TranslateModule, MySharedModule, OperatorSelectorModule, TemplateDatepickerModule, SlotModule],
  providers: [],
  exports: [FreetextFilterComponent],
  declarations: [FreetextFilterComponent],
})
export class FilterModule {}
