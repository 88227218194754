import { NgModule } from '@angular/core';
import { AutofocusDirective } from './autofocus.pipe';
import { CapitalizePipe } from './capitalize.pipe';
import { CutPipe } from './cut.pipe';
import { DatexPipe } from './datex.pipe';
import { DiffPipe } from './diff.pipe';
import { FilterPipe } from './filter.pipe';
import { KeysPipe } from './keys.pipe';
import { MarkPipe } from './mark.pipe';
import { MatchPipe } from './match.pipe';
import { MergePipe } from './merge.pipe';
import { MillisecondsToDatePipe } from './milliseconds.to.date.pipe';
import { SortPipe } from './sort.pipe';
import { TimePipe } from './time.pipe';
import { ToArrayPipe } from './toArray.pipe';

@NgModule({
  declarations: [
    DatexPipe,
    CapitalizePipe,
    DiffPipe,
    CutPipe,
    KeysPipe,
    MergePipe,
    MillisecondsToDatePipe,
    MatchPipe,
    MarkPipe,
    FilterPipe,
    SortPipe,
    ToArrayPipe,
    AutofocusDirective,
    TimePipe,
  ],
  exports: [
    TimePipe,
    DatexPipe,
    CapitalizePipe,
    DiffPipe,
    CutPipe,
    KeysPipe,
    MergePipe,
    MillisecondsToDatePipe,
    MatchPipe,
    MatchPipe,
    MarkPipe,
    FilterPipe,
    SortPipe,
    AutofocusDirective,
    ToArrayPipe,
  ],
})
export class PipeModule {}
