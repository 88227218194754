<div class="entry-element-wrapper-main" (contextmenu)="openCtx($event)" *ngIf="entryElement">
  <!-- area for uploading files-->
  <app-file-uploader [disabled]="disabled || isProcessing" [template]="entryElement"></app-file-uploader>

  <mat-icon [style.color]="'red'" *ngIf="highlightedKey === el.id" class="highlighted">priority_high</mat-icon>
  <ng-container [ngSwitch]="el.fieldType">
    <!-- TEXT -->
    <ng-container *ngSwitchCase="'TEXT'">
      <span
        class="default-element"
        #textElement
        [style.color]="el.getEntry()?.getColor() || el.getValue()?.getColor()"
        [style]="'line-height: 16px; display: flex; height: unset; ' + el.getStyle()"
      >
        {{ (el.getEntry() || el.getValue())?.getValue() | translate }}
      </span>
    </ng-container>
    <!-- PLACEHOLDER -->
    <ng-container *ngSwitchCase="'PLACEHOLDER'">
      <empty-element></empty-element>
    </ng-container>
    <!-- SPINNER -->
    <ng-container *ngSwitchCase="'SPINNER'">
      <app-loading-spinner [spinner]="el"></app-loading-spinner>
    </ng-container>
    <!-- SELECTION_BOX -->
    <ng-container *ngSwitchCase="'SELECTION_BOX'">
      <app-selection-box [template]="el" (selectionChanged)="onEmit(el, $event, template.restUrl)"> </app-selection-box>
    </ng-container>
    <!-- OBJECT_SELECTOR -->
    <ng-container *ngSwitchCase="'OBJECT_SELECTOR'">
      <app-selector
        [disabled]="disabled || isProcessing"
        [element]="el"
        *ngIf="el.getValue()"
        (changeEvent)="onEmit(el, $event, template.restUrl)"
      >
      </app-selector>
    </ng-container>
    <!-- GANTT_DATE_SELECTOR -->
    <ng-container *ngSwitchCase="'GANTT_DATE_SELECTOR'">
      <app-date-selector-element [data]="el"></app-date-selector-element>
    </ng-container>
    <!-- GANTT_SETTINGS -->
    <ng-container *ngSwitchCase="'GANTT_SETTINGS'">
      <app-gantt-settings [data]="el.getValue()?.getValue()" (changeEvent)="onEmit(el, $event, template.restUrl)">
      </app-gantt-settings>
    </ng-container>
    <!-- TRANSFER_GANTT_SETTINGS -->
    <ng-container *ngSwitchCase="'TRANSFER_GANTT_SETTINGS'">
      <app-transfer-gantt-settings
        (changeEvent)="onEmit(el, $event, template.restUrl)"
        [ownGanttID]="el.getValue()?.getValue()"
      >
      </app-transfer-gantt-settings>
    </ng-container>
    <!-- FILTER_ELEMENT -->
    <ng-container *ngSwitchCase="'FILTER_ELEMENT'">
      <app-filter-element [template]="el"></app-filter-element>
    </ng-container>
    <!-- MULTI_OBJECT_SELECTOR -->
    <ng-container *ngSwitchCase="'MULTI_OBJECT_SELECTOR'">
      <app-selector
        [disabled]="disabled || isProcessing"
        [element]="el"
        [multi]="true"
        *ngIf="el.getValue()"
        (changeEvent)="onEmit(el, $event, template.restUrl)"
      >
      </app-selector>
    </ng-container>
    <!-- FILE_UPLOAD -->
    <ng-container *ngSwitchCase="'FILE_UPLOAD'">
      <saxms-fileuploader [data]="el" (change)="onEmit(el, $event, template.restUrl)"> </saxms-fileuploader>
    </ng-container>
    <!-- IMAGE -->
    <ng-container *ngSwitchCase="'IMAGE'">
      <template-image [src]="el?.value?.value"></template-image>
    </ng-container>
    <!-- DATE_PICKER -->
    <ng-container *ngSwitchCase="'DATE_PICKER'">
      <ng-container *ngTemplateOutlet="tPicker; context: { $implicit: el, type: 'DATE' }"></ng-container>
    </ng-container>
    <!-- DATE_TIME_PICKER -->
    <ng-container *ngSwitchCase="'DATE_TIME_PICKER'">
      <ng-container *ngTemplateOutlet="tPicker; context: { $implicit: el, type: 'DATETIME' }"></ng-container>
    </ng-container>
    <!-- TIME_PICKER -->
    <ng-container *ngSwitchCase="'TIME_PICKER'">
      <ng-container
        *ngTemplateOutlet="tPicker; context: { $implicit: el, type: 'TIME', mode: 'single' }"
      ></ng-container>
    </ng-container>
    <!-- RANGE_PICKER -->
    <ng-container *ngSwitchCase="'RANGE_PICKER'">
      <ng-container
        *ngTemplateOutlet="tRangePicker; context: { $implicit: el, type: 'DATE', mode: 'range' }"
      ></ng-container>
    </ng-container>
    <!-- DURATION_PICKER -->
    <ng-container *ngSwitchCase="'DURATION_PICKER'">
      <template-duration-picker
        *ngIf="el.value"
        [id]="el.id"
        [title]="el.name"
        [disabled]="disabled || isProcessing"
        [displayed]="el.durationPickerValues"
        (millisChange)="onEmit(el, $event, template.restUrl, el)"
        [(millis)]="el.value.value"
        [simpleInput]="el.isSimple()"
        [entryElement]="entryElement"
      >
      </template-duration-picker>
    </ng-container>
    <!-- YEAR_PICKER -->
    <ng-container *ngSwitchCase="'YEAR_PICKER'">
      <ng-container *ngTemplateOutlet="templatePicker; context: { $implicit: el }"></ng-container>
    </ng-container>
    <!-- YEAR_PICKER -->
    <ng-container *ngSwitchCase="'CALENDERMONTH_PICKER'">
      <ng-container *ngTemplateOutlet="templatePicker; context: { $implicit: el }"></ng-container>
    </ng-container>

    <!-- CALENDARWEEK_PICKER -->
    <ng-container *ngSwitchCase="'CALENDERWEEK_PICKER'">
      <ng-container *ngTemplateOutlet="templatePicker; context: { $implicit: el }"></ng-container>
    </ng-container>

    <!-- PIE_CHART -->
    <ng-container *ngSwitchCase="'PIE_CHART'">
      <app-chart-new [chart]="el" *ngIf="el.getValue()"></app-chart-new>
    </ng-container>

    <!-- RichTextEditor -->
    <ng-container *ngSwitchCase="'TEXT_AREA_EDITOR'">
      <rich-text-editor
        [style.width]="'100%'"
        *ngIf="el.getValue()"
        [template]="el"
        (changeEvent)="onEmit(el, $event, template.restUrl, el)"
      >
      </rich-text-editor>
    </ng-container>

    <!-- BUTTON -->
    <ng-container *ngSwitchCase="'BUTTON'">
      <app-button
        [stretched]="layoutElement"
        [disabled]="disabled || isProcessing"
        [icon]="el.icon"
        [data]="el"
      ></app-button>
    </ng-container>

    <!-- COLOR_PICKER -->
    <ng-container *ngSwitchCase="'COLOR_PICKER'">
      <!-- <color-picker [template]="el" (colorChange)="onEmit(el,$event,template.restUrl,el);">
      </color-picker> -->
      <app-color-button
        [picker]="el"
        [disabled]="disabled || isProcessing"
        (colorChanged)="onEmit(el, $event, template.restUrl, el)"
      ></app-color-button>
    </ng-container>

    <!-- PREDEFINED -->
    <ng-container *ngSwitchCase="'PREDEFINED'">
      <ng-container [ngSwitch]="el.id">
        <experiment-selector
          *ngSwitchCase="'template.toolbarelement.experimentselector'"
          [advanced]="true"
          [showSelected]="true"
        >
        </experiment-selector>
        <model-selector *ngSwitchCase="'template.toolbarelement.modelselector'" [advanced]="true" [showSelected]="true">
        </model-selector>
        <horizon-selector
          *ngSwitchCase="'template.toolbarelement.horizonselector'"
          [advanced]="true"
          [showSelected]="true"
        >
        </horizon-selector>
        <theme-selector *ngSwitchCase="'template.toolbarelement.themeselector'" [advanced]="true" [showSelected]="true">
        </theme-selector>
      </ng-container>
    </ng-container>

    <!-- SAXMS_ELEMENTS -->
    <ng-container *ngSwitchDefault>
      <saxms-element
        [style.width]="el.fieldType === 'TEXT_AREA' || layoutElement ? '100%' : 'inherit'"
        [style.max-width]="el.fieldType === 'TEXT_AREA' || layoutElement ? '100%' : ''"
        [stretched]="layoutElement"
        [style.height]="layoutElement ? '100%' : 'auto'"
        [disabled]="disabled || isProcessing"
        (changeEvent)="onEmit(el, $event, template.restUrl)"
        [elementData]="el"
      >
      </saxms-element>
    </ng-container>
  </ng-container>
</div>

<ng-template #tPicker let-el let-type="type" let-mode="mode">
  <template-datepicker
    [selectedValues]="{ from: el.getValue()?.getValue() }"
    [entryElement]="el"
    [id]="el.id"
    [required]="el.required"
    [useLocalTime]="el.useLocalTime"
    (changes)="onEmit(el, $event, template.restUrl, el)"
    [placeholder]="el.name"
    [disabled]="disabled || isProcessing"
    [type]="type"
  >
  </template-datepicker>
</ng-template>

<ng-template #tRangePicker let-el let-type="type" let-mode="mode">
  <template-datepicker
    [entryElement]="el"
    [id]="entryElement.getId()"
    [required]="entryElement.isRequired()"
    [useLocalTime]="entryElement.isUseLocalTime()"
    (changes)="onEmit(entryElement, $event, template.restUrl, entryElement)"
    [placeholder]="entryElement.getName()"
    [selectedValues]="el.value?.value || { from: null, to: null }"
    [disabled]="disabled || isProcessing"
    [type]="type"
    [mode]="'range'"
    [minDate]="entryElement.getMinDate()"
    [maxDate]="entryElement.getMaxDate()"
  >
  </template-datepicker>
</ng-template>

<ng-template #templatePicker let-el>
  <app-template-picker
    *ngIf="el.getValue()"
    [placeholder]="el.name"
    [id]="el.id"
    (changeEvent)="onEmit(el, $event, template.restUrl, el)"
    [entryElement]="el"
  >
  </app-template-picker>
</ng-template>
