import { OverlayRef } from '@angular/cdk/overlay';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { OverlayCloseEvent } from './overlay-close.interface';

@Component({
  selector: 'default-overlay-container',
  template: '',
})
export class DefaultOverlayContainer<T> implements OnInit, OnDestroy {
  private overlayRef: OverlayRef;
  public afterClosed$ = new Subject<OverlayCloseEvent<any>>();
  public data: T;

  getRef(): OverlayRef {
    return this.overlayRef;
  }

  setRef(ref: OverlayRef): this {
    this.overlayRef = ref;
    return this;
  }

  public get afterClosed(): Observable<OverlayCloseEvent<any>> {
    return this.afterClosed$.asObservable();
  }

  protected _close<T>(type: 'backdropClick' | 'close' = 'backdropClick', data: T = null): void {
    this.afterClosed$.next({ type, data });
    this.afterClosed$.complete();
    this.overlayRef.dispose();
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this._close('backdropClick', this.data);
  }
}
