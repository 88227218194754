import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { TranslateModule } from '@ngx-translate/core';
import { ConditionsMenuModule } from '../elements/core/conditions-menu/conditions-menu.module';
import { TemplateDurationPickerComponent } from './template-duration-picker.component';

@NgModule({
  imports: [CommonModule, FormsModule, MatInputModule, TranslateModule, ConditionsMenuModule],
  declarations: [TemplateDurationPickerComponent],
  exports: [TemplateDurationPickerComponent],
})
export class TemplateDurationPickerModule {}
