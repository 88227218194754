import { Injectable, OnDestroy } from '@angular/core';
import { IGanttShiftTranslationEvent } from '@gantt/lib/best_gantt/script/edit-shifts/shift-translation/translation-events/translation-event.interface';
import { EGanttInstance, GanttDataRow, GanttDataShift, GanttInstanceService } from '@gantt/public-api';
import { ILegendDataEvent, ILegendEntry } from 'frontend/src/dashboard/legend/legend.interface';
import { EFilteredOutDisplayOption } from 'frontend/src/dashboard/saxms-best-gantt/legend/filtered-out-display-option.enum';
import { Observable, Subject } from 'rxjs';
import { debounceTime, takeUntil, takeWhile } from 'rxjs/operators';
import { GanttLibService } from '../../../gantt-lib.service';
import { GanttDockService } from '../../gantt-dock.service';
import { LegendCommunicationService } from './legend-communication.service';

@Injectable()
export class LegendFilterService implements OnDestroy {
  public legendId = 'GanttLegendDashboardID';
  public hideFilteredLegendEntriesNoRenderId = 'GanttLegendDashboardHideFilteredLegendEntriesNoRenderId';
  private alive = false;

  private searchNoRenderId = 'search_ganttLegendNoRenderID';
  private unusedNoRenderId = 'unused_ganttLegendNoRenderID';
  private currentSearchValue = '';
  private _dockComponentID = 'ganttLegend';
  private ignoredRowIds: string[] = [];
  private detectChangesSubject = new Subject();
  private validationSubject = new Subject();

  private _onDestroySubject: Subject<void> = new Subject<void>();

  constructor(
    private _ganttDockService: GanttDockService,
    private _ganttLibService: GanttLibService,
    private _ganttInstanceService: GanttInstanceService,
    private _legendCommunicationService: LegendCommunicationService
  ) {
    this._init();
  }

  /**
   * Initialization of the service.
   */
  private _init(): void {
    this.alive = true;

    this._listenToNewLegendData();

    this._ganttLibService.afterInit.pipe(takeWhile(() => this.alive)).subscribe((isInit) => {
      if (!isInit) {
        return;
      }
      this._ganttLibService.bestGantt
        .getDataHandler()
        .subscribeBeforeShiftDataMapping('legendValidation_' + this.legendId, (_) => this.validationSubject.next(null));
      this._ganttLibService.bestGantt
        .getVerticalScrollHandler()
        .onScrollVerticalEnd.pipe(takeUntil(this.onDestroy))
        .subscribe(() => {
          if (this._legendCommunicationService.showOnlyViewportEntries) {
            this.validationSubject.next(null);
          }
        });
      this._ganttLibService.bestGantt
        .getXAxisBuilder()
        .addToZoomEndCallback('legendValidation_' + this.legendId, (_) => {
          if (this._legendCommunicationService.showOnlyViewportEntries) {
            this.validationSubject.next(null);
          }
        });
      this._ganttLibService.bestGantt
        .getShiftTranslator()
        .onShiftEditEnd()
        .pipe(takeUntil(this.onDestroy))
        .subscribe((event) => this.handleIgnoredRows(event));
      this._ganttLibService.bestGantt.subscribeOriginDataUpdate(
        'FilterShiftsByConditionUpdate' + this.legendId,
        (_) => {
          if (this._legendCommunicationService.isDefaultLegendDataUsed) {
            this.update(); // trigger update for default legend data
          }
        }
      );
    });

    this.validationSubject
      .pipe(
        takeWhile(() => this.alive),
        debounceTime(1000)
      )
      .subscribe((_) => {
        this.validateLegendVisibility();
      });

    this._listenToResetFilter();
    this._listenToShowOnlyViewPortEntries();

    this._legendCommunicationService
      .listenToUIUpdates()
      .pipe(takeWhile(() => this.alive))
      .subscribe(() => this.update(true));
  }

  ngOnDestroy(): void {
    if (this._ganttLibService.bestGantt) {
      this._ganttLibService.bestGantt.unSubscribeOriginDataUpdate('FilterShiftsByConditionUpdate' + this.legendId);
      this._ganttLibService.bestGantt
        .getDataHandler()
        .unSubscribeBeforeShiftDataMapping('legendValidation_' + this.legendId);
      this._ganttLibService.bestGantt.getXAxisBuilder().removeZoomEndCallback('legendValidation_' + this.legendId);
    }
    this._onDestroySubject.next();
    this._onDestroySubject.complete();
    this.alive = false;
  }

  /**
   * Function which connects material search bar with legend.
   * @param event
   */
  public searchForEntry(searchQuery: string): void {
    this.currentSearchValue = searchQuery;
    this._legendCommunicationService.getLegendData().legendEntries.forEach((elem) => {
      if (elem.label.search(new RegExp(searchQuery, 'i')) != -1) {
        this._removeNoRenderIdFromLegendEntry(this.searchNoRenderId, elem);
      } else {
        this._addNoRenderIdToLegendEntry(this.searchNoRenderId, elem);
      }
    });
    this._handleCheckBox();
    this._handleFilterNotificationOfGanttDock();
    if (this._legendCommunicationService.hideSearchResults) {
      this._handleNoRenderShiftsOnFilteredOutEntries();
      this._legendCommunicationService.triggerOnChangeFilter();
    }
  }

  /**
   * Resets all legend filters.
   */
  public resetLegendInDashboard(): void {
    this._legendCommunicationService.isVisibilityChecked = true;
    this._legendCommunicationService.isVisibilityIndeterminateShow = false;
    this._legendCommunicationService.getLegendData().legendEntries.forEach((elem) => (elem.isActive = true));
    this.clearSearch();
    this._setAllShiftsNoRender(false, [
      this._legendCommunicationService.getShiftNoRenderId(),
      this.hideFilteredLegendEntriesNoRenderId,
    ]);
    this._ganttDockService.removeNotificationByDockComponentID(this._dockComponentID);
    this.validateLegendVisibility();
  }

  /**
   * Resets the current search.
   */
  public clearSearch(): void {
    this.currentSearchValue = '';
    this._legendCommunicationService
      .getLegendData()
      .legendEntries.forEach((elem) => this._removeNoRenderIdFromLegendEntry(this.searchNoRenderId, elem));
    this._handleCheckBox();
    this._handleFilterNotificationOfGanttDock();
    if (this._legendCommunicationService.hideSearchResults) {
      this._handleNoRenderShiftsOnFilteredOutEntries();
      this._legendCommunicationService.triggerOnChangeFilter();
    }
  }

  /**
   * Handles the visibilty checkbox item.
   * Handles the activation or deactivation of all legend entries and shift visibility.
   * @param visibilityChange
   */
  public toggleVisibility(visibilityChange: boolean): void {
    this._legendCommunicationService.isVisibilityChecked = visibilityChange;
    if (this._legendCommunicationService.isVisibilityChecked) {
      this._legendCommunicationService.getLegendData().legendEntries.forEach((elem) => (elem.isActive = true));
      this._setAllShiftsNoRender(false, [
        this._legendCommunicationService.getShiftNoRenderId(),
        this.hideFilteredLegendEntriesNoRenderId,
      ]);
    } else {
      this._legendCommunicationService.getLegendData().legendEntries.forEach((elem) => (elem.isActive = false));
      this._setAllShiftsNoRender(true, [
        this._legendCommunicationService.getShiftNoRenderId(),
        this.hideFilteredLegendEntriesNoRenderId,
      ]);
    }

    this._legendCommunicationService.isVisibilityIndeterminateShow = false;
    this._handleFilterNotificationOfGanttDock();
    this._legendCommunicationService.triggerOnChangeFilter();
  }

  /**
   * Validates whether legend entries exist or are visible in the Gantt.
   */
  public validateLegendVisibility(): void {
    const dataSet = this._ganttLibService.bestGantt.getDataHandler().getOriginDataset().ganttEntries;
    const ignoredNoRenderIds = this._legendCommunicationService.ignoredNoRenderIds;

    const isValid = (entries: GanttDataRow[], legendEntry: ILegendEntry) => {
      for (const entry of entries) {
        // check entries
        if (entry.noRender.length) {
          continue;
        }
        for (const shift of entry.shifts) {
          // check shifts
          if (
            (!shift.noRender.filter((e) => !ignoredNoRenderIds.has(e)).length ||
              this._isShiftNoRenderCausedByLegend(shift)) &&
            this._isShiftReachable(shift) &&
            this._checkOnlyViewPortEntries(shift) &&
            this._isAppearanceEqual(shift, legendEntry)
          ) {
            return true;
          }
        }
        if (isValid(entry.child, legendEntry)) {
          // nothing found -> go deeper
          return true;
        }
      }
      return false;
    };

    for (const legendItem of this._legendCommunicationService.getLegendData().legendEntries) {
      if (isValid(dataSet, legendItem)) {
        // start recursive run
        this._removeNoRenderIdFromLegendEntry(this.unusedNoRenderId, legendItem);
      } else {
        this._addNoRenderIdToLegendEntry(this.unusedNoRenderId, legendItem);
      }
    }
    this.detectChangesSubject.next(null);
  }

  /**
   * Decides if the specified shift is reachable/visible in the current gantt by checking if its dates are inside the date range of the gantt.
   * @param shiftData Data of the shift that should be checked.
   * @returns Is reachable or not.
   */
  private _isShiftReachable(shiftData: GanttDataShift): boolean {
    const ganttStartTime = this._ganttLibService.bestGantt.getDataHandler().getOriginDataset().minValue.getTime();
    const ganttEndTime = this._ganttLibService.bestGantt.getDataHandler().getOriginDataset().maxValue.getTime();

    if (shiftData.timePointEnd.getTime() >= ganttStartTime && shiftData.timePointStart.getTime() <= ganttEndTime) {
      return true;
    }

    return false;
  }

  /**
   * Decides if the apperance of shift is equal to the apperance legend entry.
   * @param shiftData Data of shift.
   * @param {ILegendEntry} legendItem ApperanceItem.
   * @retuns Is equal or not.
   */
  private _isAppearanceEqual(shiftData: GanttDataShift, legendItem: ILegendEntry): boolean {
    if (
      shiftData.color === legendItem.color ||
      (legendItem.pattern &&
        legendItem.patternColor &&
        shiftData.pattern === legendItem.pattern &&
        shiftData.patternColor === legendItem.patternColor) ||
      (legendItem.strokeColor && legendItem.strokeColor === shiftData.strokeColor) ||
      (shiftData.secondColor && shiftData.secondColor === legendItem.color) ||
      (shiftData.firstColor && shiftData.firstColor === legendItem.color)
    ) {
      return true;
    }
    return false;
  }

  private _checkOnlyViewPortEntries(shift: GanttDataShift): boolean {
    if (!this._legendCommunicationService.showOnlyViewportEntries) {
      return true;
    } else {
      const renderDataSetShifts = this._ganttLibService.bestGantt.getRenderDataSetShifts();
      let foundShift = false;
      for (const scrollContainerId in renderDataSetShifts) {
        if (renderDataSetShifts[scrollContainerId]?.find((elem) => elem.id === shift.id)) {
          foundShift = true;
          break;
        }
      }
      if (foundShift) return true;
    }
    return false;
  }

  /**
   * Returns true if the given shift is not visible only because of this legend
   */
  private _isShiftNoRenderCausedByLegend(shift: GanttDataShift): boolean {
    return (
      shift.noRender.length === 1 && shift.noRender.includes(this._legendCommunicationService.getShiftNoRenderId())
    );
  }

  /**
   * Updates the display option for all shifts.
   */
  public updateDisplayOption(): void {
    const noRenderId1 = this._legendCommunicationService.getShiftNoRenderId();
    const noRenderId2 = this.hideFilteredLegendEntriesNoRenderId;
    const updateNoRenderDeep = function (child: GanttDataRow) {
      for (const shift of child.shifts) {
        switch (this._legendCommunicationService.getFilteredOutDisplayOption()) {
          case EFilteredOutDisplayOption.HIDE:
            if (shift.weaken.includes(noRenderId1)) {
              this._ganttInstanceService.getInstance(EGanttInstance.GANTT_UTILITIES).removeWeakenId(shift, noRenderId1);
              this._ganttInstanceService
                .getInstance(EGanttInstance.GANTT_UTILITIES)
                .registerNoRenderId(shift, noRenderId1);
            }
            if (shift.weaken.includes(noRenderId2)) {
              this._ganttInstanceService.getInstance(EGanttInstance.GANTT_UTILITIES).removeWeakenId(shift, noRenderId2);
              this._ganttInstanceService
                .getInstance(EGanttInstance.GANTT_UTILITIES)
                .registerNoRenderId(shift, noRenderId2);
            }
            break;
          case EFilteredOutDisplayOption.WEAKENED:
            if (shift.noRender.includes(noRenderId1)) {
              this._ganttInstanceService
                .getInstance(EGanttInstance.GANTT_UTILITIES)
                .removeNoRenderId(shift, noRenderId1);
              this._ganttInstanceService
                .getInstance(EGanttInstance.GANTT_UTILITIES)
                .registerWeakenId(shift, noRenderId1);
            }
            if (shift.noRender.includes(noRenderId2)) {
              this._ganttInstanceService
                .getInstance(EGanttInstance.GANTT_UTILITIES)
                .removeNoRenderId(shift, noRenderId2);
              this._ganttInstanceService
                .getInstance(EGanttInstance.GANTT_UTILITIES)
                .registerWeakenId(shift, noRenderId2);
            }
            break;
        }
      }
    };
    this._ganttInstanceService
      .getInstance(EGanttInstance.DATA_MANIPULATOR)
      .iterateOverDataSet(this._ganttLibService.bestGantt.getDataHandler().getOriginDataset().ganttEntries, {
        updateNoRenderDeep: updateNoRenderDeep.bind(this),
      });
    this._legendCommunicationService.triggerOnChangeFilter();
  }

  /**
   * Is called by slider item and handles the option that only matched shifts attributes wich found in the search are visible.
   * @param event slider onChange event.
   */
  public toggleHideFilteredOutEntries(event: any): void {
    if (this._legendCommunicationService.hideSearchResults) {
      this._handleNoRenderShiftsOnFilteredOutEntries();
    } else {
      this._setAllShiftsNoRender(false, [this.hideFilteredLegendEntriesNoRenderId]);
    }
    this._handleFilterNotificationOfGanttDock();
    this._legendCommunicationService.triggerOnChangeFilter();
  }

  /**
   * Updates legend and gantt based on legend entries.
   */
  public update(updateSearchInput = false, isInit = false): void {
    this._handleCheckBox();
    this._handleShiftNoRender();
    this._handleFilterNotificationOfGanttDock();
    this._legendCommunicationService.triggerOnChangeFilter(!isInit);
    if (this.currentSearchValue && !updateSearchInput) {
      this.searchForEntry(this.currentSearchValue);
    }
    if (updateSearchInput) {
      this.searchForEntry(this._legendCommunicationService.searchInput);
    }
  }

  /**
   * Toggles the focus on one legend entry (deactivates/activates all others)
   * @param legendEntry Focused legend entry.
   */
  public toggleLegendEntryFocus(legendEntry: ILegendEntry): void {
    this._handleLegendEntryFocus(legendEntry);
    this._handleCheckBox();
    this._handleFilterNotificationOfGanttDock();
    this._legendCommunicationService.triggerOnChangeFilter();
  }

  /**
   * Handles render option of shifts in gantt origin data.
   */
  private _handleNoRenderShiftsOnFilteredOutEntries(): void {
    const noRenderId = this.hideFilteredLegendEntriesNoRenderId;
    const updateNoRenderDeep = function (child) {
      const isRowIgnored = this.ignoredRowIds.includes(child.id);
      for (const shift of child.shifts) {
        this._ganttInstanceService.getInstance(EGanttInstance.GANTT_UTILITIES).removeNoRenderId(shift, noRenderId);
        this._ganttInstanceService.getInstance(EGanttInstance.GANTT_UTILITIES).removeWeakenId(shift, noRenderId);
        if (isRowIgnored) {
          continue;
        }
        for (const legendItem of this._legendCommunicationService
          .getLegendData()
          .legendEntries.filter((elem) => elem.noRender.includes(this.searchNoRenderId))) {
          if (this._isAppearanceEqual(shift, legendItem)) {
            switch (this._legendCommunicationService.getFilteredOutDisplayOption()) {
              case EFilteredOutDisplayOption.HIDE:
                this._ganttInstanceService
                  .getInstance(EGanttInstance.GANTT_UTILITIES)
                  .registerNoRenderId(shift, noRenderId);
                break;
              case EFilteredOutDisplayOption.WEAKENED:
                this._ganttInstanceService
                  .getInstance(EGanttInstance.GANTT_UTILITIES)
                  .registerWeakenId(shift, noRenderId);
                break;
            }
            continue;
          }
        }
      }
    };
    this._ganttInstanceService
      .getInstance(EGanttInstance.DATA_MANIPULATOR)
      .iterateOverDataSet(this._ganttLibService.bestGantt.getDataHandler().getOriginDataset().ganttEntries, {
        updateNoRenderDeep: updateNoRenderDeep.bind(this),
      });
  }

  /**
   * Handles the functions wich are executed by focusing a legend enty.
   * @param legendItem Focused legend entry.
   */
  private _handleLegendEntryFocus(legendItem: ILegendEntry): void {
    const allActiveItems = this._legendCommunicationService
      .getLegendData()
      .legendEntries.filter((elem) => elem.isActive);
    if (allActiveItems.length === 1 && allActiveItems[0].id === legendItem.id) {
      this._legendCommunicationService.getLegendData().legendEntries.forEach((elem) => (elem.isActive = true));
      this._setAllShiftsNoRender(false, [
        this._legendCommunicationService.getShiftNoRenderId(),
        this.hideFilteredLegendEntriesNoRenderId,
      ]);
    } else {
      this._legendCommunicationService
        .getLegendData()
        .legendEntries.forEach((elem) => (elem.isActive = elem.id === legendItem.id ? true : false));
      this._searchInDatasetForShiftsByAppearanceAndFocusIt(legendItem);
    }
  }

  /**
   * Toggles noRender option in shift origin data set with toggle option.
   */
  private _handleShiftNoRender(): void {
    const noRenderId = this._legendCommunicationService.getShiftNoRenderId();
    const updateNoRenderDeep = (child) => {
      const isRowIgnored = this.ignoredRowIds.includes(child.id);

      for (const shift of child.shifts) {
        this._ganttInstanceService.getInstance(EGanttInstance.GANTT_UTILITIES).removeNoRenderId(shift, noRenderId);
        this._ganttInstanceService.getInstance(EGanttInstance.GANTT_UTILITIES).removeWeakenId(shift, noRenderId);

        if (isRowIgnored) {
          continue;
        }
        const isFilteredOut = this._legendCommunicationService
          .getLegendData()
          .legendEntries.find((entry) => !entry.isActive && this._isAppearanceEqual(shift, entry));
        if (isFilteredOut) {
          switch (this._legendCommunicationService.getFilteredOutDisplayOption()) {
            case EFilteredOutDisplayOption.HIDE:
              this._ganttInstanceService
                .getInstance(EGanttInstance.GANTT_UTILITIES)
                .registerNoRenderId(shift, noRenderId);
              break;
            case EFilteredOutDisplayOption.WEAKENED:
              this._ganttInstanceService
                .getInstance(EGanttInstance.GANTT_UTILITIES)
                .registerWeakenId(shift, noRenderId);
              break;
          }
        }
      }
    };
    this._ganttInstanceService
      .getInstance(EGanttInstance.DATA_MANIPULATOR)
      .iterateOverDataSet(this._ganttLibService.bestGantt?.getDataHandler().getOriginDataset().ganttEntries || [], {
        updateNoRenderDeep: updateNoRenderDeep,
      });
  }

  /**
   * Toggles noRender option in shift origin data set.
   * @param appearance Data structure of appearance attributes.
   */
  private _searchInDatasetForShiftsByAppearanceAndFocusIt(legendItem: ILegendEntry): void {
    const noRenderId = this._legendCommunicationService.getShiftNoRenderId();
    const updateNoRenderDeep = function (child) {
      const isRowIgnored = this.ignoredRowIds.includes(child.id);
      for (const shift of child.shifts) {
        if (this._isAppearanceEqual(shift, legendItem) || isRowIgnored) {
          this._ganttInstanceService.getInstance(EGanttInstance.GANTT_UTILITIES).removeNoRenderId(shift, noRenderId);
          this._ganttInstanceService.getInstance(EGanttInstance.GANTT_UTILITIES).removeWeakenId(shift, noRenderId);
        } else {
          switch (this._legendCommunicationService.getFilteredOutDisplayOption()) {
            case EFilteredOutDisplayOption.HIDE:
              this._ganttInstanceService
                .getInstance(EGanttInstance.GANTT_UTILITIES)
                .registerNoRenderId(shift, noRenderId);
              break;
            case EFilteredOutDisplayOption.WEAKENED:
              this._ganttInstanceService
                .getInstance(EGanttInstance.GANTT_UTILITIES)
                .registerWeakenId(shift, noRenderId);
              break;
          }
        }
      }
    };
    this._ganttInstanceService
      .getInstance(EGanttInstance.DATA_MANIPULATOR)
      .iterateOverDataSet(this._ganttLibService.bestGantt.getDataHandler().getOriginDataset().ganttEntries, {
        updateNoRenderDeep: updateNoRenderDeep.bind(this),
      });
  }

  /**
   * Handles the state of the checkbox (checked, unchecked, indeterminate)
   */
  private _handleCheckBox(): void {
    const currentEntrySize = this._legendCommunicationService.getLegendData().legendEntries.length;
    const amntOfVisibleEntries = this._legendCommunicationService
      .getLegendData()
      .legendEntries.filter((item) => item.isActive).length;
    if (amntOfVisibleEntries === 0 || currentEntrySize === amntOfVisibleEntries) {
      this._legendCommunicationService.isVisibilityIndeterminateShow = false;
    } else {
      this._legendCommunicationService.isVisibilityIndeterminateShow = true;
    }
    if (amntOfVisibleEntries === 0) {
      this._legendCommunicationService.isVisibilityChecked = false;
    }
    if (currentEntrySize === amntOfVisibleEntries) {
      this._legendCommunicationService.isVisibilityChecked = true;
    }
    this.detectChangesSubject.next(null);
  }

  private _handleFilterNotificationOfGanttDock() {
    if (
      !this._legendCommunicationService.isVisibilityChecked ||
      this._legendCommunicationService.isVisibilityIndeterminateShow ||
      (this._legendCommunicationService.searchInput.length && this._legendCommunicationService.hideSearchResults)
    ) {
      this._ganttDockService.registerNotificationByDockComponentID(this._dockComponentID);
    } else {
      this._ganttDockService.removeNotificationByDockComponentID(this._dockComponentID);
    }
  }

  /**
   * Sets all shifts no render in gantt origin data set by toggle bool.
   * @param toggle NoRender value.
   * @param noRenderIds Registered no render ids.
   */
  private _setAllShiftsNoRender(toggle: boolean, noRenderIds: string[]): void {
    const updateNoRenderDeep = function (child) {
      const isRowIgnored = this.ignoredRowIds.includes(child.id);
      for (const shift of child.shifts) {
        for (const noRenderId of noRenderIds) {
          if (toggle && !isRowIgnored) {
            switch (this._legendCommunicationService.getFilteredOutDisplayOption()) {
              case EFilteredOutDisplayOption.HIDE:
                this._ganttInstanceService
                  .getInstance(EGanttInstance.GANTT_UTILITIES)
                  .registerNoRenderId(shift, noRenderId);
                break;
              case EFilteredOutDisplayOption.WEAKENED:
                this._ganttInstanceService
                  .getInstance(EGanttInstance.GANTT_UTILITIES)
                  .registerWeakenId(shift, noRenderId);
                break;
            }
          } else {
            this._ganttInstanceService.getInstance(EGanttInstance.GANTT_UTILITIES).removeNoRenderId(shift, noRenderId);
            this._ganttInstanceService.getInstance(EGanttInstance.GANTT_UTILITIES).removeWeakenId(shift, noRenderId);
          }
        }
      }
    };
    this._ganttInstanceService
      .getInstance(EGanttInstance.DATA_MANIPULATOR)
      .iterateOverDataSet(this._ganttLibService.bestGantt.getDataHandler().getOriginDataset().ganttEntries, {
        updateNoRenderDeep: updateNoRenderDeep.bind(this),
      });
  }

  private _addNoRenderIdToLegendEntry(noRenderId: string, legendEntry: ILegendEntry): void {
    this._ganttInstanceService.getInstance(EGanttInstance.GANTT_UTILITIES).registerNoRenderId(legendEntry, noRenderId);
  }

  private _removeNoRenderIdFromLegendEntry(noRenderId: string, legendEntry: ILegendEntry): void {
    this._ganttInstanceService.getInstance(EGanttInstance.GANTT_UTILITIES).removeNoRenderId(legendEntry, noRenderId);
  }

  private _listenToResetFilter(): void {
    this._legendCommunicationService
      .listenToFilterReset()
      .pipe(takeWhile(() => this.alive))
      .subscribe((isReset) => {
        if (!isReset) {
          return;
        }
        this.toggleVisibility(true);
        this.resetLegendInDashboard();
        this._legendCommunicationService.searchInput = '';
        this.detectChangesSubject.next(null);
      });
  }

  private _listenToNewLegendData(): void {
    this._legendCommunicationService
      .listenToNewLegendData()
      .pipe(takeWhile(() => this.alive))
      .subscribe((event: ILegendDataEvent) => {
        if (!event.legendEntries || !event.legendEntries.length) return;
        if (event.hasUpdatedData) this.update(false, event.isInit);
        this.detectChangesSubject.next(null);
      });
  }

  private _listenToShowOnlyViewPortEntries(): void {
    this._legendCommunicationService
      .listenToShowOnlyViewPortEntries()
      .pipe(takeWhile(() => this.alive))
      .subscribe((isActive: boolean) => {
        if (isActive === null) return;
        this.validateLegendVisibility();
      });
  }

  private handleIgnoredRows(data: IGanttShiftTranslationEvent): void {
    const targetRowId = data.dragEndRowId;
    if (this._legendCommunicationService.ignoreEditedEntries && !this.ignoredRowIds.includes(targetRowId)) {
      this.ignoredRowIds.push(targetRowId);
    }
  }

  public onResetIgnoredRows() {
    this.ignoredRowIds = [];
    this.update();
  }

  public listenToDetectChangesEvents(): Observable<unknown> {
    return this.detectChangesSubject.asObservable();
  }

  /**
   * Observable which gets triggered when the instance gets destroyed.
   */
  private get onDestroy(): Observable<void> {
    return this._onDestroySubject.asObservable();
  }
}
