import { EntryElement } from '@app-modeleditor/components/entry-collection/entry-element';
import { ETemplateType } from 'frontend/src/dashboard/model/resource/template-type';
import { EFieldType } from '../entry-collection/field-type.enum';
import { EntryElementValue } from './../entry-collection/entry-element-value';

export class SelectionBox extends EntryElement {
  constructor() {
    super();
    this.setType(ETemplateType.ENTRY_ELEMENT);
    this.setFieldType(EFieldType.SELECTION_BOX);
  }

  getSelectedItems(): EntryElementValue[] {
    if (!this.getValue() || !Array.isArray(this.getValue<EntryElementValue>().getValue())) {
      return [];
    }
    return this.getValue<EntryElementValue>()
      .getValue<EntryElementValue[]>()
      .filter((i: EntryElementValue) => i.isChecked());
  }
}
