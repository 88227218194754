<div class="resource-component-container">
  <app-resources-toolbar *ngIf="menuOpen"></app-resources-toolbar>
  <div class="view" (click)="onBackgroundClick()" resourceSelectionBox>
    <!-- <app-status-legend [status]="resourcesDataService.statusLegendData"></app-status-legend> -->
    <app-resource-list *ngIf="favoritesGroup?.elements.length" [resourceGroup]="favoritesGroup"> </app-resource-list>
    <ng-container *ngFor="let resourceGroup of resourcesDataService.resources">
      <app-resource-list [resourceGroup]="resourceGroup"> </app-resource-list>
    </ng-container>
  </div>
</div>
