import { takeUntil } from 'rxjs';
import { IGanttShiftTranslationEvent } from '../translation-events/translation-event.interface';
import { GanttShiftsMultiDragStrategy } from './strategies/shift-multi-drag-strategy.interface';
import { BestGantt } from '../../../main';
import { GanttShiftsOneLineDragStrategy } from './strategies/one-line-drag-strategy';
import { GanttShiftTranslator } from '../shift-translator';
import { EGanttShiftsMultiDragStrategy } from './shifts-multi-drag-strategy.enum';

/**
 * Helper class for shift translator responsible for translating multiple shifts at once.
 * @keywords plugin, executer, multi, drag, move, translate, multiple, shift, block
 */
export class GanttShiftsMultiDragHandler {
  private _ganttDiagram: BestGantt;
  private _executer: GanttShiftTranslator;
  private _strategy: GanttShiftsMultiDragStrategy;

  private _dragStartEvent: IGanttShiftTranslationEvent = null;
  private _dragStartHasBeenExecuted = false;

  constructor(
    ganttDiagram: BestGantt,
    executer: GanttShiftTranslator,
    strategy: GanttShiftsMultiDragStrategy = undefined
  ) {
    this._ganttDiagram = ganttDiagram;
    this._executer = executer;
    this._strategy = strategy || new GanttShiftsOneLineDragStrategy(this._ganttDiagram);

    this._registerCallBacks();
  }

  /**
   * Contains all callbacks which will be registererd inside gantt.
   */
  private _registerCallBacks(): void {
    this._ganttDiagram
      .getConfig()
      .onShiftEditMultiDragStrategyChanged()
      .pipe(takeUntil(this._executer.onDestroy))
      .subscribe((strategy) => this.setMultiDragStrategy(strategy));

    this._executer
      .onShiftEditStart()
      .pipe(takeUntil(this._executer.onDestroy))
      .subscribe((event) => this._executeTranslationStart(event));
    this._executer
      .onShiftEditUpdate()
      .pipe(takeUntil(this._executer.onDestroy))
      .subscribe((event) => this._executeTranslationUpdate(event));
    this._executer
      .onShiftEditEnd()
      .pipe(takeUntil(this._executer.onDestroy))
      .subscribe((event) => this._executeTranslationEnd(event));
  }

  /**
   * Callback function which will executes translation start of current strategy.
   * @param dragStartEvent Event which triggered the execution of this callback.
   */
  private _executeTranslationStart(dragStartEvent: IGanttShiftTranslationEvent): void {
    if (!this._strategy) return;
    this._dragStartEvent = dragStartEvent;
  }

  /**
   * Callback function which will executes translation of current strategy.
   * @param dragUpdateEvent Event which triggered the execution of this callback.
   */
  private _executeTranslationUpdate(dragUpdateEvent: IGanttShiftTranslationEvent): void {
    if (this._strategy && !this._dragStartHasBeenExecuted) {
      this._strategy.executeTranslationStart(this._dragStartEvent);
      this._dragStartHasBeenExecuted = true;
    }
    if (!this._strategy) return;
    this._strategy.executeDuringTranslation(dragUpdateEvent);
  }

  /**
   * Callback function which will executes translation end of current strategy.
   * @param dragEndEvent Event which triggered the execution of this callback.
   */
  private _executeTranslationEnd(dragEndEvent: IGanttShiftTranslationEvent): void {
    if (!this._strategy) return;
    this._strategy.executeTranslationEnd(dragEndEvent);
    this._dragStartHasBeenExecuted = false;
  }

  //
  // GETTER & SETTER
  //

  /**
   * Sets the used multi drag strategy to the given instance.
   * @param strategy Strategy to be used.
   */
  public setMultiDragStrategy(strategy: EGanttShiftsMultiDragStrategy): void {
    let strategyInstance: GanttShiftsMultiDragStrategy = undefined;

    switch (strategy) {
      case EGanttShiftsMultiDragStrategy.ONE_LINE_DRAG:
      default:
        strategyInstance = new GanttShiftsOneLineDragStrategy(this._ganttDiagram);
    }

    this._strategy = strategyInstance;
  }
}
