import { DataManipulator } from '../../data-handler/data-tools/data-manipulator';
import { GanttUtilities } from '../../gantt-utilities/gantt-utilities';

/**
 * Executes all necessary operations.
 */
export class FilterShiftsByConditionOperator {
  UUID: string;
  attributeBackendMapping: any;
  attributeMap: any;
  attributeTypes: any;
  comparators: any;

  constructor() {
    this.UUID = '_PlugIn_' + GanttUtilities.generateUniqueID(11);
    this.attributeBackendMapping = {};
    this.attributeMap = {};
    this.attributeTypes = {};
    this.comparators = {
      eq: (a, b) => a == b,
      neq: (a, b) => a != b, // Not equal
      gte: (a, b) => a >= b, // Greater than or equal
      gt: (a, b) => a > b,
      lte: (a, b) => a <= b, // Less than or equal
      lt: (a, b) => a < b, // Less than
      empty: (a, b) => a == '',
      not_empty: (a, b) => a != '',
      contains: (a, b) => a.includes(b),
      not_contains: (a, b) => !a.includes(b),
      begins_with: (a, b) => a.startsWith(b),
      ends_with: (a, b) => a.endsWith(b),
      by_value: (a, b) => a == b,
      not_between: (a, b, c) => a <= b || a >= c,
      between: (a, b, c) => a >= b && a <= c,
      none: (a, b) => false, // None(no filter)
    };
  }

  /**
   * Filters the given gantt entries by a given condition.
   *
   * @param {*} ganttEntries Gantt dataset
   * @param {*} condition Filter condition
   */
  _filterByCondition(ganttEntries, dataHandler, condition, isCaseSensitive, filteredOutElementDisplayOption) {
    const filteredOutShifts = [];
    const filterShifts = function (child, level, parent, index, abort) {
      for (const shift of child.shifts) {
        if (!shift.additionalData.hasOwnProperty('additionalData')) break;
        if (!shift.additionalData.additionalData.hasOwnProperty('additionalDetails')) break;
        const resultBool = this._checkConditionsByShiftAttributes(
          shift.additionalData.additionalData.additionalDetails,
          condition,
          isCaseSensitive
        );
        switch (filteredOutElementDisplayOption) {
          case 'HIDE':
            resultBool
              ? GanttUtilities.removeNoRenderId(shift, this.UUID)
              : GanttUtilities.registerNoRenderId(shift, this.UUID);
            break;
          case 'WEAKEN':
            resultBool
              ? GanttUtilities.removeWeakenId(shift, this.UUID)
              : GanttUtilities.registerWeakenId(shift, this.UUID);
            break;
          default:
            resultBool
              ? GanttUtilities.removeNoRenderId(shift, this.UUID)
              : GanttUtilities.registerNoRenderId(shift, this.UUID);
            break;
        }
      }
    };
    DataManipulator.iterateOverDataSet(ganttEntries, { filterShifts: filterShifts.bind(this) });
  }

  /**
   * Checks the condition by attributes of a single shift.
   *
   * @param {*} shiftAttributes
   * @param {*} conditions
   * @returns {boolean}
   */
  _checkConditionsByShiftAttributes(shiftAttributes, conditions, isCaseSensitive) {
    const self = this;
    //iterate over conditions
    const iterateOverCondition = function (conArray, connectionType) {
      const bools = [];
      for (const con of conArray) {
        if (!Array.isArray(con)) {
          // its a connection
          if (con.OR) {
            bools.push(iterateOverCondition(con.OR, 'OR'));
          }
          if (con.AND) {
            bools.push(iterateOverCondition(con.AND, 'AND'));
          }
        } else {
          // its a condition
          bools.push(self._checkSingleCondition(shiftAttributes, con, isCaseSensitive));
        }
      }

      // check results by connection type
      if (connectionType === 'OR') {
        for (const bool of bools) {
          if (bool) return true;
        }
        return false;
      }

      if (connectionType === 'AND') {
        for (const bool of bools) {
          if (!bool) return false;
        }
        return true;
      }
    };

    return iterateOverCondition([conditions], 'AND');
  }

  /**
   * Checks shift attributes by a single condition.
   *
   * @param {*} shiftAttributes
   * @param {*} condition
   * @returns {boolean}
   */
  _checkSingleCondition(shiftAttributes, condition, isCaseSensitive) {
    if (condition.length != 4) return false; // return if condition not complete

    // extract attribute, comparator and value of condition
    const attribute = condition[0];
    const comparator = condition[1];
    let conditionValue = condition[2];
    let conditionValue2 = comparator == 'between' || comparator == 'not_between' ? condition[3] : null;

    // get value of shift attribute
    let shiftAttributeValue = this._getShiftAttributeValue(shiftAttributes, attribute);

    // return if shift attribute not exist
    if (!shiftAttributeValue) {
      // exception for empty comparator
      if (comparator === 'empty') {
        shiftAttributeValue = '';
      } else {
        return false;
      }
    }

    // parse right data types
    let tempDate: Date = null;
    switch (this.attributeTypes[attribute]) {
      case 'STRING':
        conditionValue = isCaseSensitive ? String(conditionValue) : String(conditionValue).toLocaleLowerCase();
        shiftAttributeValue = isCaseSensitive
          ? String(shiftAttributeValue)
          : String(shiftAttributeValue).toLocaleLowerCase();
        break;
      case 'DATE':
        // set first condition value
        tempDate = new Date(conditionValue);
        tempDate.setHours(0, 0, 0, 0);
        conditionValue = tempDate.getTime();
        // set second condition value if set
        if (conditionValue2) {
          tempDate = new Date(conditionValue2);
          tempDate.setHours(0, 0, 0, 0);
          conditionValue2 = tempDate.getTime();
        }
        // adapt shift attribute value
        if (typeof shiftAttributeValue != 'number') shiftAttributeValue = parseInt(shiftAttributeValue);
        tempDate = new Date(shiftAttributeValue);
        tempDate.setHours(0, 0, 0, 0);
        shiftAttributeValue = tempDate.getTime();
        break;
      case 'TIME':
        // set first condition value
        tempDate = new Date(conditionValue);
        conditionValue = tempDate.getHours() * 3600000 + tempDate.getMinutes() * 60000;
        // set second condition value if set
        if (conditionValue2) {
          tempDate = new Date(conditionValue2);
          conditionValue2 = tempDate.getHours() * 3600000 + tempDate.getMinutes() * 60000;
        }
        // set first condition value
        if (typeof shiftAttributeValue != 'number') shiftAttributeValue = parseInt(shiftAttributeValue);
        tempDate = new Date(shiftAttributeValue);
        shiftAttributeValue = tempDate.getHours() * 3600000 + tempDate.getMinutes() * 60000;
        break;
      case 'DATE_TIME':
        conditionValue = new Date(conditionValue).getTime();
        if (conditionValue2) conditionValue2 = new Date(conditionValue2).getTime();
        if (typeof shiftAttributeValue != 'number') shiftAttributeValue = parseInt(shiftAttributeValue);
        shiftAttributeValue = new Date(shiftAttributeValue).getTime();
        break;
      case 'NUMBER':
        conditionValue = Number(conditionValue);
        if (conditionValue2) {
          conditionValue2 = Number(conditionValue2);
        }
        shiftAttributeValue = Number(shiftAttributeValue);
        break;
      case 'BOOLEAN':
        conditionValue = !!conditionValue;
        shiftAttributeValue = !!shiftAttributeValue;
        break;
    }

    // compare
    return this.comparators[comparator](shiftAttributeValue, conditionValue, conditionValue2);
  }

  /**
   * Clears filter and removes noRender ID of shift.
   */
  _clearFilter(ganttEntries, dataHandler) {
    const clearFilter = function (child, level, parent, index, abort) {
      for (const shift of child.shifts) {
        GanttUtilities.removeNoRenderId(shift, this.UUID);
        GanttUtilities.removeWeakenId(shift, this.UUID);
      }
    };
    DataManipulator.iterateOverDataSet(ganttEntries, { clearFilter: clearFilter.bind(this) });
  }

  /**
   * Set attribute mapping of additionalDetails shift property.
   */
  _setAttributeMapping(attributeMapping) {
    this.attributeBackendMapping = attributeMapping;
    this.attributeMap = this._generateAttributeMap(attributeMapping);
    this.attributeTypes = this._extractShiftAttributeTypes(attributeMapping);
  }

  _getShiftAttributeValue(shiftAttributes, attribute) {
    if (!shiftAttributes[this.attributeMap[attribute]]) return;
    return shiftAttributes[this.attributeMap[attribute]].t2;
  }

  _extractShiftAttributeTypes(shiftAttributes) {
    const map = {};
    for (const attr in shiftAttributes) {
      const attrName = shiftAttributes[attr].localization;
      const dataType = shiftAttributes[attr].dataType;
      map[attrName] = dataType;
    }
    return map;
  }

  _generateAttributeMap(attributeMapping) {
    const map = {};
    // search attribute position
    for (const attr in attributeMapping) {
      const number = attr;
      const attrName = attributeMapping[attr].localization;
      map[attrName] = number;
    }
    return map;
  }
}

