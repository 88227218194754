import { ContentElement } from '@app-modeleditor/components/content/content-element/content-element';

export class FooterElement extends ContentElement {
  private contentElement: ContentElement;
  private footerElement: string;

  public getContentElement(): ContentElement {
    return this.contentElement;
  }

  public setContentElement(contentElement: ContentElement): this {
    this.contentElement = contentElement;
    return this;
  }

  public getFooterElement(): string {
    return this.footerElement;
  }

  public setFooterElement(footerElement: string): this {
    this.footerElement = footerElement;
    return this;
  }
}
