import { Injectable, Type } from '@angular/core';
import { Button } from '@app-modeleditor/components/button/button';
import { Wizard } from '@app-modeleditor/components/lightbox/predefined/wizard/wizard';
import { TemplateResourceAdapter } from '@app-modeleditor/utils/template-resource-factory.service';
import { GlobalUtils } from 'frontend/src/dashboard/global-utils';
import { ButtonAdapter } from '../../../button/button-adapter.service';
import { WizardContent } from './wizard-content';
import { WizardPage } from './wizard-page';

@Injectable({
  providedIn: 'root',
})
export class WizardAdapter {
  constructor(private templateResourceFactory: TemplateResourceAdapter, private buttonFactory: ButtonAdapter) {}

  public parseWizard<T extends Wizard>(type: Type<T>, data: any): T {
    if (!data) {
      return null;
    }

    const w: T = this.templateResourceFactory
      .inherit<T>(type, data)
      .setResourceId(data.resourceId || GlobalUtils.generateUUID())
      .setDisplayOrderDefault(data.displayOrderDefault);
    w.setContent(this.parseWizardContent<WizardContent>(WizardContent, data.content))
      .setHeadline(data.headline)
      .setDisableTopNavigation(data.disableTopNavigation)
      .setOnCustomCancelAction(this.buttonFactory.inherit<Button>(Button, data.onCustomCloseWizard))
      .setAbsoluteWidth(data.absoluteWidth)
      .setWidth(data.width);

    return w;
  }

  public parseWizardPage<T extends WizardPage>(type: Type<T>, data: any): T {
    if (!data) {
      return null;
    }
    const p: T = this.templateResourceFactory
      .inherit<T>(type, data)
      .setAdditionalButtons((data.additionalButtons || []).map((b) => this.buttonFactory.inherit<Button>(Button, b)))
      .setContentRestUrl(data.contentRestUrl)
      .setIndex(data.index)
      .setNextButton(this.buttonFactory.inherit<Button>(Button, data.nextButton))
      .setBackButton(this.buttonFactory.inherit<Button>(Button, data.backButton))
      .setNextPages(data.nextPages)
      .setNextPages(data.previousPages);
    return p;
  }

  public parseWizardContent<T extends WizardContent>(type: Type<T>, data: any): T {
    if (!data) {
      return null;
    }

    const c: T = new type()
      .setId(data.id)
      .setWizardPage((data.wizardPage || []).map((p) => this.parseWizardPage<WizardPage>(WizardPage, p)));

    return c;
  }
}
