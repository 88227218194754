<mat-form-field class="time-picker" [formGroup]="formGroup" [id]="id">
  <mat-label>{{ label | translate }}</mat-label>
  <input
    #dateField
    formControlName="start"
    type="text"
    (change)="afterChanged($event)"
    matInput
    [matAutocompleteDisabled]="disabled"
    [matAutocomplete]="auto"
    [required]="required"
  />
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onTimeChanged($event)">
    <mat-option
      *ngFor="let time of filteredOptions | async"
      [value]="time | timePipe : ['hours', 'minutes'] : twelveHours"
    >
      {{ time | timePipe : ['hours', 'minutes'] : twelveHours }}
    </mat-option>
  </mat-autocomplete>
  <!-- <app-conditions-menu matPrefix *ngIf="entryElement?.getAvailableFilterConditions().length > 0" [entryElement]="entryElement">
  </app-conditions-menu> -->
  <button *ngIf="!disabled" mat-icon-button matSuffix>
    <mat-icon>access_time</mat-icon>
  </button>
</mat-form-field>
