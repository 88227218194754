import { GanttDataRow } from '../../../data-handler/data-structure/data-structure';
import {
  EGanttDataSortingChildRowStrategy,
  EGanttDataSortingOrder,
  IGanttDataSortingRowOrderEntry,
  ShiftDataSorting,
} from '../../../data-handler/data-tools/data-sorting';
import { BestGantt } from '../../../main';
import { GanttSplitOverlappingShifts } from '../../../plug-ins/split-overlapping-shifts/split-overlapping-shifts-executer';
import { GanttYAxisHeadButtonData } from './head-y-axis-button';

/**
 * Class that represents the sort button on the top of y axis.
 * @keywords button, y axis, yaxis, top, headline, container, click, sorting, sort, row
 * @param {BestGantt} ganttRef Reference to gantt.
 * @param {string} [id=null] Button id.
 */
export class GanttYAxisHeadSortRowsButtonData extends GanttYAxisHeadButtonData {
  private _ganttRef: BestGantt;
  private _currentRowSortingOrder: EGanttDataSortingOrder;
  private _originDataOrder: IGanttDataSortingRowOrderEntry[];

  constructor(ganttRef: BestGantt, id: string = null) {
    super(id);

    this._ganttRef = ganttRef;
    this._currentRowSortingOrder = null;
    this._originDataOrder = null;

    this._initOriginDataOrder();
    this._initStates();
  }

  /**
   * Initializes the button states.
   */
  private _initStates(): void {
    // State 0: Sort ASC
    this.addState(
      'saxms-icon gantt_x-axis_placeholder-btn-sort-rows',
      this._sortRows.bind(this, EGanttDataSortingOrder.ASC),
      'sort_by_alpha_custom_unsorted',
      'Zeilensortierung: unsortiert'
    );

    // State 1: Sort DESC
    this.addState(
      'saxms-icon gantt_x-axis_placeholder-btn-sort-rows',
      this._sortRows.bind(this, EGanttDataSortingOrder.DESC),
      'sort_by_alpha_custom_asc',
      'Zeilensortierung: aufsteigend'
    );

    // State 2: Reset to original order
    this.addState(
      'saxms-icon gantt_x-axis_placeholder-btn-sort-rows',
      this._sortRows.bind(this, null),
      'sort_by_alpha_custom_desc',
      'Zeilensortierung: absteigend'
    );
  }

  /**
   * Initializes the origin data order (to restore backend order later) and subscribes to order updates.
   */
  private _initOriginDataOrder(): void {
    const getIdStructureRecursive = function (rows: GanttDataRow[]): IGanttDataSortingRowOrderEntry[] {
      const idStructure: IGanttDataSortingRowOrderEntry[] = [];
      for (let i = 0; i < rows.length; i++) {
        idStructure[i] = { id: rows[i].id, child: null };
        if (rows[i].child && rows[i].child.length > 0) {
          idStructure[i].child = getIdStructureRecursive(rows[i].child);
        }
      }
      return idStructure;
    };
    this._originDataOrder = getIdStructureRecursive(this._ganttRef.getDataHandler().getOriginDataset().ganttEntries);

    // listen to changes in original order
    this._ganttRef.subscribeOriginDataUpdate('GanttYAxisHeadSortRowsButton_' + new Date().getTime(), () => {
      const searchForRowChangesRecursive = function (
        rows: GanttDataRow[],
        currentIdStructure: IGanttDataSortingRowOrderEntry[]
      ): void {
        // check for new rows
        for (let i = 0; i < rows.length; i++) {
          const found = currentIdStructure.find((element) => element.id === rows[i].id);
          if (!found) {
            currentIdStructure.splice(i, 0, {
              id: rows[i].id,
              child: rows[i].child && rows[i].child.length > 0 ? getIdStructureRecursive(rows[i].child) : null,
            });
          }
        }
        // check for removed rows
        for (let i = 0; i < currentIdStructure.length; i++) {
          const found = rows.find((element) => element.id === currentIdStructure[i].id);
          if (!found) {
            currentIdStructure.splice(i, 1);
          }
        }
        // search next level
        for (const row of rows) {
          const rowOrderEntry = currentIdStructure.find((element) => element.id === row.id);
          if (row.child && row.child.length > 0) {
            if (!rowOrderEntry.child) rowOrderEntry.child = [];
            searchForRowChangesRecursive(row.child, rowOrderEntry.child);
          } else {
            rowOrderEntry.child = null;
          }
        }
      };
      searchForRowChangesRecursive(
        this._ganttRef.getDataHandler().getOriginDataset().ganttEntries,
        this._originDataOrder
      );

      // update row sorting
      if (this._currentRowSortingOrder) {
        this._sortRows(this._currentRowSortingOrder);
      }
    });
  }

  /**
   * Handles row sorting.
   * @param order Order in which the rows will be sorted (null for original order).
   */
  private _sortRows(order: EGanttDataSortingOrder): void {
    // deactivate GanttSplitOverlappingShifts (if plug-in existing & activated)
    const overlappingShiftsPlugins: GanttSplitOverlappingShifts[] = this._ganttRef
      .getPlugInHandler()
      .getPlugInsOfType(GanttSplitOverlappingShifts);
    const overlappingShiftsPlugin: GanttSplitOverlappingShifts = overlappingShiftsPlugins.length
      ? overlappingShiftsPlugins[0]
      : null;

    if (overlappingShiftsPlugin) {
      overlappingShiftsPlugin.resetSplitOverlappingShifts(false);
    }

    // sort rows
    this._currentRowSortingOrder = order;

    if (!this._currentRowSortingOrder) {
      ShiftDataSorting.sortRowsByCustomOrder(this._ganttRef.getDataHandler().getOriginDataset(), this._originDataOrder);
    } else {
      ShiftDataSorting.sortRowsAlphabeticallyByName(
        this._ganttRef.getDataHandler().getOriginDataset(),
        this._currentRowSortingOrder,
        1,
        EGanttDataSortingChildRowStrategy.GROUP_BOTTOM
      );
    }

    // activate GanttSplitOverlappingShifts (if plug-in existing & was activated before)
    if (overlappingShiftsPlugin) {
      overlappingShiftsPlugin.splitOverlappingShifts(null, true);
    } else {
      this._ganttRef.update();
    }
  }
}
