import { Action } from '@app-modeleditor/components/button/action/action';
import { Button } from '@app-modeleditor/components/button/button';
import { EButtonDisplayType } from '@app-modeleditor/components/button/button-display-type.enum';
import { ContextMenuItem } from '@app-modeleditor/components/contextmenu/context-menu-item';
import { ContextMenu } from '@app-modeleditor/components/contextmenu/contextmenu';
import { of } from 'rxjs';
import { FullSpreadsheetComponent } from '../../full-spreadsheet/full-spreadsheet.component';

export class HeaderVisibilityElement extends Button {
  constructor(private spreadsheetComponent: FullSpreadsheetComponent) {
    super();

    this.setId('header-visibility-manager')
      .setIcon('visibility')
      .setMenu(new ContextMenu().setClosedByClick(false).setContextMenuItems(this.getHeaderVisibilityItems()))
      .setName('header_visibility')
      .setDisplayType(EButtonDisplayType.ICON_WITH_LABEL_BELOW);
  }

  private getHeaderVisibilityItems(): ContextMenuItem[] {
    return this.spreadsheetComponent
      .getColumnUtil()
      .getColumns()
      .map((col) => {
        const found: boolean = this.spreadsheetComponent
          .getHiddenColumnFilterSet()
          .has(col.filterSortObject.getColumnIndex());

        const item = new ContextMenuItem().setId(`${col.filterSortObject.getColumnIndex()}`);
        item
          .setName(col.label)
          .setIcon(!found ? 'visibility' : 'visibility_off')
          .chainActions(
            new Action(() => {
              const foundInner: boolean = this.spreadsheetComponent
                .getHiddenColumnFilterSet()
                .has(col.filterSortObject.getColumnIndex());
              this.spreadsheetComponent.toggleVisibilityOfHeader(col.id, foundInner);
              item.setIcon(foundInner ? 'visibility' : 'visibility_off');
              return of(null);
            })
          );
        return item;
      });
  }

  public updateContextMenu() {
    this.getMenu()
      .getContextMenuItems()
      .forEach((item) => {
        const found: boolean = this.spreadsheetComponent.getHiddenColumnFilterSet().has(
          parseInt(item.getId())
        );
        item.setIcon(!found ? "visibility" : "visibility_off");
      });
  }
}
