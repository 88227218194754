import { Component, ComponentFactoryResolver, Input, OnInit, ViewContainerRef, OnDestroy } from '@angular/core';
import { UiService } from '@app-modeleditor/ui.service';
import { ExperimentService2, IExperiment } from 'frontend/src/dashboard/data/experiment/experiment.service';
import { Widget } from 'frontend/src/dashboard/data/grid/data/widget';
import { AbstractGridService } from 'frontend/src/dashboard/moving-grid/abstract-grid.service';
import { GridGloablService } from 'frontend/src/dashboard/moving-grid/grid-global.service';
import { GridService } from 'frontend/src/dashboard/moving-grid/grid.service';
import { Observable } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { WidgetContainer } from '../template-widget/widget-container';

@Component({
  selector: 'app-template-widgetcontainer',
  templateUrl: './template-widgetcontainer.component.html',
  styleUrls: ['./template-widgetcontainer.component.scss'],
})
export class TemplateWidgetContainerComponent implements OnInit, OnDestroy {
  @Input() contentId: string;
  @Input() widgetTemplate: WidgetContainer;
  widget: Widget = null;
  private experimentId: string = null;

  public gridService: AbstractGridService;
  public gridType: string;
  private alive = false;

  constructor(
    private experimentService2: ExperimentService2,
    private modelService: UiService,
    private gridService2: GridService,
    public globalGridService: GridGloablService,
    public componentFactoryResolver: ComponentFactoryResolver,
    public viewContainerRef: ViewContainerRef
  ) {}

  ngOnDestroy(): void {
    this.alive = false;
  }
  ngOnInit(): void {
    this.alive = true;
    this.experimentService2
      .getCurrentExperiment()
      .pipe(takeWhile(() => this.alive))
      .subscribe((experiment: IExperiment) => {
        this.experimentId = experiment ? experiment.id : '';
        if (experiment) {
          this.mappingTemplate();
        }
      });
    // this.mappingTemplate();
  }

  private getWidgetDataId(): Observable<any> {
    return this.modelService.getData(this.widgetTemplate.getGetWidgetRestUrl());
  }

  private mappingTemplate() {
    this.globalGridService.setGridService(this.gridService2, '');
    this.widget = this.create();
    // this.getWidgetDataId().pipe(takeWhile(() => this.alive)).subscribe((widgetInformation) => {
    //   this.widget = new Widget();
    //   this.widget.widget = { id: widgetInformation.id };
    //   this.widget.widgetBaseId = widgetInformation.basedOnWidgetTemplateRefId;
    //   this.widget.data = null;
    //   this.widget.experimentId = this.experimentId;
    //   this.widget.oldStoreWidgetId = widgetInformation.id;
    //   this.widget.templateLayout = this.widgetTemplate.layout;
    //   this.widget.contentId = this.contentId;
    //   this.widget.component = this.checkRepresentationType(
    //     this.widgetTemplate.value.availableVisualRepresentationTyps[0],
    //   );
    //   // this.createFactory();
    // });
  }

  /**
   * creates a widget with all neccessary properties
   * @returns Widget
   */
  public create(): Widget {
    const widget: Widget = new Widget();
    widget.widget = { id: this.experimentId + this.widgetTemplate.value.id };
    widget.widgetBaseId = this.widgetTemplate.value.id;
    widget.data = null;
    widget.experimentId = this.experimentId;
    widget.oldStoreWidgetId = this.widgetTemplate.value.id;
    widget.templateLayout = this.widgetTemplate.getLayout();
    widget.contentId = this.contentId;
    widget.templateRestUrl = this.widgetTemplate.getRestUrl();
    widget.dataRestUrl = this.widgetTemplate.getGetWidgetRestUrl();
    widget.compareRestUrl = this.widgetTemplate.getCompareRestUrl();
    widget.additionalSubMenus = this.widgetTemplate.getAdditionalSubMenus();
    widget.settings = this.widgetTemplate.value.settings;
    widget.parentId = this.widgetTemplate.getParentId();
    widget.component = this.checkRepresentationType(this.widgetTemplate.value.availableVisualRepresentationTyps[0]);
    return widget;
  }

  public checkRepresentationType(type: string): string {
    switch (type) {
      case 'LIST':
      case 'TABLE':
        return 'table';
      case 'KPITABLE':
        return 'kpi';
      case 'GANTT':
        return 'gantt';
      case 'PIECHART':
        return 'piechart';
      case 'BARCHART':
        return 'barchart';
      case 'LINECHART':
        return 'linechart';
      case 'TEMPLATE':
        return 'template';
      case 'COMBINED_TIMED_DATA':
        return 'combinedWidget';
      case 'ERROR':
        return 'error';
      default:
        return 'template';
    }
  }
}
