import { Action } from '@app-modeleditor/components/button/action/action';
import { Button } from '@app-modeleditor/components/button/button';
import { EButtonDisplayType } from '@app-modeleditor/components/button/button-display-type.enum';
import { ContextMenuItem } from '@app-modeleditor/components/contextmenu/context-menu-item';
import { ContextMenu } from '@app-modeleditor/components/contextmenu/contextmenu';
import { EntryElement } from '@app-modeleditor/components/entry-collection/entry-element';
import { EntryElementValue } from '@app-modeleditor/components/entry-collection/entry-element-value';
import { EFieldType } from '@app-modeleditor/components/entry-collection/field-type.enum';
import { MenuItem } from 'frontend/src/dashboard/view/template-toolbar/menu-item';
import { Toolbar } from 'frontend/src/dashboard/view/template-toolbar/toolbar';
import { ToolbarGroup } from 'frontend/src/dashboard/view/template-toolbar/toolbar-group';
import { of } from 'rxjs';
import { GeneralChartInformation } from '../general-chart-information';
import { ChartsComponent } from './../chart.component';

export class ExploreToolbar {
  constructor(private scope: ChartsComponent) {}

  private getIconButton(): Button {
    return new Button().setDisplayType(EButtonDisplayType.ICON_WITH_LABEL_BELOW);
  }

  /**
   * get ViewToolbar
   * @param t Toolbar
   * @param scope ChartsComponent
   * @returns MenuItem
   */
  public get(t: Toolbar): MenuItem {
    const chartInfo: GeneralChartInformation = this.scope.mainChart;

    const optionsEntries: EntryElement[] = [];
    const pagingEntries: EntryElement[] = [];
    if (this.scope.optionTree) {
      if (this.scope.optionTree.chartMathOption.length > 0) {
        const menueActions: ContextMenuItem[] = this.scope.optionTree.chartMathOption.map((option) =>
          new ContextMenuItem()
            .setName(option)
            .chainActions(new Action().setCb(() => of(this.scope.handleOptionsMenus('chartMathOption', option))))
        );

        optionsEntries.push(
          this.getIconButton()
            .setId(`${chartInfo.chartId}_function`)
            .setMenu(new ContextMenu().setContextMenuItems(menueActions))
            .setName('CHART.ACTION.EXPLORE.functions')
            .setIcon('functions')
        );
      }
      if (this.scope.optionTree.chartOptions.length > 0) {
        const menueActions: ContextMenuItem[] = this.scope.optionTree.chartOptions.map((option) =>
          new ContextMenuItem()
            .setName(option)
            .chainActions(new Action().setCb(() => of(this.scope.handleOptionsMenus('chartOption', option))))
        );

        optionsEntries.push(
          this.getIconButton()
            .setMenu(new ContextMenu().setContextMenuItems(menueActions))
            .setId(`${chartInfo.chartId}_interval`)
            .setName('CHART.ACTION.EXPLORE.interval')
            .setIcon('timeline')
        );
      }
      if (this.scope.optionTree.chartSorter.length > 0) {
        const menueActions: ContextMenuItem[] = this.scope.optionTree.chartSorter.map((option) =>
          new ContextMenuItem()
            .setName(option)
            .chainActions(new Action().setCb(() => of(this.scope.handleOptionsMenus('chartSorter', option))))
        );

        optionsEntries.push(
          this.getIconButton()
            .setMenu(new ContextMenu().setContextMenuItems(menueActions))
            .setId(`${chartInfo.chartId}_order`)
            .setName('CHART.ACTION.EXPLORE.order')
            .setIcon('merge_type')
        );
      }
      if (this.scope.optionTree.representation.length > 0) {
        const menueActions: ContextMenuItem[] = this.scope.optionTree.representation.map((option) =>
          new ContextMenuItem()
            .setName(option)
            .chainActions(new Action().setCb(() => of(this.scope.handleOptionsMenus('area', option))))
        );

        optionsEntries.push(
          this.getIconButton()
            .setMenu(new ContextMenu().setContextMenuItems(menueActions))
            .setId(`${chartInfo.chartId}_representation`)
            .setName('CHART.ACTION.EXPLORE.representation')
            .setIcon('merge_type')
        );
      }
      if (this.scope.optionTree.chartLimits.length > 0) {
      }
      if (this.scope.optionTree.chartUnits.length > 0) {
        const menueActions: ContextMenuItem[] = this.scope.optionTree.chartUnits.map((option) =>
          new ContextMenuItem()
            .setName(option)
            .chainActions(new Action().setCb(() => of(this.scope.handleOptionsMenus('chartUnit', option))))
        );

        optionsEntries.push(
          this.getIconButton()
            .setMenu(new ContextMenu().setContextMenuItems(menueActions))
            .setId(`${chartInfo.chartId}_units`)
            .setName('CHART.ACTION.EXPLORE.units')
            .setIcon('merge_type')
        );
      }

      //   pageRowElements.push(createInteractionElement(this, this.widget.widget.id + '_pagination', 'COMBO_BOX', "number", currentValue, "CHART.VIEW.paginationLabel", false, this.handlePageSize, false, false, this.createSelectionItemsForPages()));

      pagingEntries.push(
        new EntryElement()
          .setValue(
            new EntryElementValue().setAvailableValues(this.scope.pageSizeOptions).setValue(this.scope.pageSize)
          )
          .onChanges((e: EntryElementValue) => {
            // event.event.value.value
            this.scope.handlePageSize({ event: { value: { value: e.getValue<number>() } }, additionalArgs: null });
          })
          .setId(`${chartInfo.chartId}_pagination`)
          .setFieldType(EFieldType.COMBO_BOX)
          .setName('CHART.VIEW.paginationLabel')
      );
    }

    // create groups
    const optionsGroup: ToolbarGroup = new ToolbarGroup()
      .setName('CHART.EXPLORE.Options')
      .setId('chart-toolbar-view-compare')
      .setEntryElements(optionsEntries);

    const pagingGroup: ToolbarGroup = new ToolbarGroup()
      .setName('CHART.VIEW.pagination')
      .setId('chart-toolbar-view-type')
      .setEntryElements(pagingEntries);

    // create menu items
    const m: MenuItem = new MenuItem()
      .defaultGroup(t)
      .setName('data')
      .setId('chart-toolbar-explore')
      .setToolbarGroups([optionsGroup, pagingGroup]);

    return m;
  }
}
