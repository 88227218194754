import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { DialogComponent } from '@app-modeleditor/components/dialog/dialog.component';
import { Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TemplateDialogService {
  private _dialogRef: MatDialogRef<DialogComponent>;
  private _dialogEvents: Subject<any> = new Subject<any>();
  private dialogStack: MatDialogRef<DialogComponent>[] = [];
  private onDialogOpen: Subject<ETemplateDialogHook> = new Subject<ETemplateDialogHook>();

  constructor(private dialog: MatDialog, private http: HttpClient) {}

  setHook(event: ETemplateDialogHook) {
    this.onDialogOpen.next(event);
  }

  getHook(): Observable<ETemplateDialogHook> {
    return this.onDialogOpen.asObservable();
  }

  /**
   * close loader
   */
  public closeLoader() {
    if (this._dialogRef) {
      this._dialogRef.close();
    }
  }

  /**
   * open loader
   */
  public openLoader() {
    this._dialogRef = this.dialog.open(DialogComponent, {
      data: {
        node: {
          isLoader: true,
        },
      },
    });
  }

  /**
   * get template for dialog
   * @param template string
   */
  getDialogTemplate(template: string): Observable<any> {
    return this.http.get(`./assets/dialogs/${template}.json`);
  }

  /**
   * close whole stack of dialogs
   * @returns void
   */
  closeAll(): void {
    this.dialogStack.forEach((dialog: MatDialogRef<DialogComponent>) => {
      dialog.close();
    });
    this.dialogStack = [];
  }

  /**
   * close the top most dialog of the stack
   * @returns void
   */
  public close(result?): void {
    const dialog: MatDialogRef<DialogComponent> = this.dialogStack.pop();
    if (dialog) {
      dialog.close(result);
    }
  }

  /**
   * open dialog
   * @param data Data
   */
  public openDialog(data, options?: ITemplateDialogOptions): MatDialogRef<any> {
    console.warn('@deprecated', 'remove as soon as possible!', data, options);
    if (options && options.additionalButtons) {
      this.appendButtonsToDialog(data, options.delete);
    }

    this.setHook(ETemplateDialogHook.OPEN);
    const ref: MatDialogRef<any> = this.dialog.open(DialogComponent, {
      data: {
        node: data,
        options: options,
      },
      panelClass: options && options.fullscreen ? 'fullscreen-enabled' : '',

      maxWidth: options && options.fullscreen ? '100vw' : '',
      maxHeight: options && options.fullscreen ? '100vh' : '',
      height: options && options.fullscreen ? '100%' : '',
      width: options && options.fullscreen ? '100%' : '',
    });

    ref
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => {
        this.dialogStack = this.getStack().filter((_ref: MatDialogRef<DialogComponent>) => _ref.id !== ref.id);
      });

    this.dialogStack.push(ref);
    this._dialogRef = ref;
    return ref;
  }

  private appendButtonsToDialog(data, deleteButton?: boolean) {
    data.buttons = [];
    if (!data.disableSaveButton) {
      data = this.addBtn(data, '@save@', 'saveButton', { save: true, close: true });
    }

    if (!data.disableCancelButton) {
      data = this.addBtn(data, '@cancel@', 'cancelButton', { close: true });
    }

    if (deleteButton) {
      data = this.addBtn(data, '@delete@', 'deleteButton', { close: true });
    }
    return data;
  }

  private addBtn(data, label: string, id: string, options) {
    data.buttons = data.buttons.filter((btn) => btn.id !== id);
    data.buttons.push({
      id: id,
      label: label,
      close: options.close,
      save: options.save,
    });
    return data;
  }

  getStack() {
    return this.dialogStack || [];
  }
}
export interface ITemplateDialogOptions {
  fullscreen?: boolean;
  additionalButtons?: boolean;
  delete?: boolean;
}

export enum ETemplateDialogHook {
  OPEN = 'open',
}
