import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class LoadingStateService {
  private received = new BehaviorSubject<Record<string, number>>({});
  private expected = new BehaviorSubject<Record<string, number>>({});

  constructor() {}

  public afterReceivedChanged(): Observable<Record<string, number>> {
    return this.received.asObservable();
  }

  public afterExpectedChanged(): Observable<Record<string, number>> {
    return this.expected.asObservable();
  }

  public setExpected(key: string, value: number): void {
    this.expected.getValue()[key] = (this.expected.getValue()[key] || 0) + value;
    this.expected.next(this.expected.getValue());
  }

  public setReceived(key: string, value: number): void {
    this.received.getValue()[key] = (this.received.getValue()[key] || 0) + value;
    this.received.next(this.received.getValue());
  }

  public reset(): void {
    this.received.next({});
    this.expected.next({});
  }
}
