import {
  Component,
  ComponentRef,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { EntryElement } from '@app-modeleditor/components/entry-collection/entry-element';
import { EntryElementValue } from '@app-modeleditor/components/entry-collection/entry-element-value';
import { OverlayCloseEvent } from '@app-modeleditor/components/lightbox/overlay/overlay-close.interface';
import { IOverlayData } from '@app-modeleditor/components/lightbox/overlay/overlay-data.interface';
import { OverlayService } from '@app-modeleditor/components/lightbox/overlay/overlay.service';
import { OverlayContentComponent } from './content/overlay-content.component';

/**
 * Displays a textarea in an overlay.
 * The textarea is editable and the value is saved on close.
 */
@Component({
  selector: 'app-textarea-overlay',
  template: ``,
  styleUrls: [],
  standalone: true,
  imports: [],
})
export class TextareaOverlayComponent implements OnInit, OnDestroy {
  @Input() entryElement: EntryElement;
  @Output() changeEvent = new EventEmitter<EntryElement>();

  private elementRef = inject(ElementRef);
  private overlayService = inject(OverlayService);
  private overlayRef: IOverlayData;

  ngOnInit(): void {
    this.openOverlay();
  }

  ngOnDestroy(): void {
    if (this.overlayRef?.componentRef?.instance) {
      (this.overlayRef.componentRef as ComponentRef<OverlayContentComponent>).instance.close();
    }
  }

  private openOverlay(): void {
    this.overlayRef = this.overlayService.create(
      OverlayContentComponent,
      this.elementRef.nativeElement,
      this.entryElement,
      {}
    );
    const initialState: string | null = this.entryElement.getValue<EntryElementValue>().getValue();

    // on close handling
    this.overlayRef.componentRef.instance.afterClosed$.subscribe((d: OverlayCloseEvent<EntryElement>) => {
      if (
        initialState !== d.data.getValue<EntryElementValue>().getValue() && // value changed
        !(!initialState && !d.data.getValue<EntryElementValue>().getValue()) // value was not empty and is not empty
      ) {
        this.changeEvent.emit(this.entryElement);
      }
    });
  }
}
