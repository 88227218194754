import { GanttDataRow, GanttDataShift } from '../../../data-handler/data-structure/data-structure';
import { DataManipulator } from '../../../data-handler/data-tools/data-manipulator';
import { ShiftDataSorting } from '../../../data-handler/data-tools/data-sorting';
import { GanttUtilities } from '../../../gantt-utilities/gantt-utilities';
import { GanttSplitOverlappingShiftGroup, GanttSplitOverlappingShifts } from '../split-overlapping-shifts-executer';
import { BaseOverlappingStrategy } from './base';

export class GanttSplitOverlappingShiftsDefaultStrategy implements BaseOverlappingStrategy {
  public readonly strategyType = 'default';

  constructor() {}

  /**
   * @param {GanttSplitOverlappingShifts} scope Reference to GanttSplitOverlappingShifts to use calculation functions.
   * @param {string[]} [ganttRowIds] Affected Row Ids. If not set, all rows will be split.
   */
  public splitOverlappingShifts(scope: GanttSplitOverlappingShifts, ganttRowIds?: string[]): void {
    const s = this;
    scope.parentShiftHandler.clearParentShiftRowSpanData();

    const splitShifts = function (child: GanttDataRow, level, parent, index) {
      if (scope.ruleSet.notAffectedRows.includes(child.id) || (ganttRowIds?.length && !ganttRowIds.includes(child.id)))
        return;

      const noRenderShifts = [];
      const rowItem = new GanttSplitOverlappingShiftsDefaultStrategyDataItem();
      if (!child.shifts || child.shifts.length < 2) return;
      for (let i = 0; i < child.shifts.length; i++) {
        const shift = child.shifts[i];
        if (shift.hasOwnProperty('noRender') && shift.noRender.length) {
          noRenderShifts.push(shift);
          continue;
        }
        rowItem.shifts.push(shift);
      }
      // generates split rows with no overlapping shifts based on given row
      const allRows = s._calculateAllRowsWithoutShiftOverlapping(scope, child, rowItem, noRenderShifts);

      // save reset data
      const newRowEntry = new GanttSplitOverlappingShiftGroup(child.id);
      newRowEntry.groupedRowIds = allRows
        .filter(function (d, i) {
          return i > 0;
        })
        .map(function (d) {
          return d.id;
        });
      scope.shiftGroups.push(newRowEntry);
      // insert new rows
      const parentRow = level == 0 ? parent.ganttEntries : parent.child;
      parentRow.splice(index.index, 1, ...allRows);
      index.index += allRows.length - 1;
    };
    DataManipulator.iterateOverDataSet(
      scope.ganttDiagram.getDataHandler().getOriginDataset().ganttEntries,
      null,
      { defaultExtraction: splitShifts },
      null,
      scope.ganttDiagram.getDataHandler().getOriginDataset()
    );
  }

  /**
   * Generates a list of gantt rows which are based on given gantt row and have no overlapping shifts.
   * @private
   * @param {GanttSplitOverlappingShifts} scope Reference to GanttSplitOverlappingShifts to use calculation functions.
   * @param {GanttDataRow} row Original row data.
   * @param {GanttSplitOverlappingShiftsDefaultStrategyDataItem} rowInfo Container which stores shifts of given row.
   * @param {GanttDataShift[]} [noRenderShifts] List of shifts which will be not rendered.
   */
  private _calculateAllRowsWithoutShiftOverlapping(
    scope: GanttSplitOverlappingShifts,
    row: GanttDataRow,
    rowInfo: GanttSplitOverlappingShiftsDefaultStrategyDataItem,
    noRenderShifts: GanttDataShift[]
  ): GanttDataRow[] {
    // 0. Initialize map of linked parent shifts.
    const linkedParentShifts = scope.parentShiftHandler.getLinkedParentShifts(rowInfo.shifts);
    const parentShiftRowSpanMap = scope.parentShiftHandler.getParentShiftRowSpans(rowInfo.shifts);
    // 1. Empty all shifts of origin row to base all calculations of given rowInfo
    row.shifts = [];
    // 2. Start with one (given) row
    const allRows = [row];
    // 3. Check all given shifts iteratively if they do overlap.
    // Pay attention that all shifts are sorted by startdate.
    const shiftRowIndexMap = new Map<string, number>();
    const waitingChildsMap = new Map<string, GanttDataShift[]>();
    for (let j = 0; j < rowInfo.shifts.length; j++) {
      const shift = rowInfo.shifts[j];
      // if shift has linked parent shift -> push shift to same row as parent shift
      const linkedParentShift = linkedParentShifts.get(shift.id);
      if (linkedParentShift) {
        const shiftRowIndex = shiftRowIndexMap.get(linkedParentShift.id);
        // if parent shift already was pushed -> push shift
        if (!isNaN(shiftRowIndex)) {
          scope.parentShiftHandler.insertChildShiftIntoParentShift(
            shift,
            shiftRowIndex,
            allRows,
            row,
            shiftRowIndexMap
          );
        }
        // if pushing the parent shift is pending -> wait until parent shift is added
        else {
          if (!waitingChildsMap.get(linkedParentShift.id)) waitingChildsMap.set(linkedParentShift.id, []);
          waitingChildsMap.get(linkedParentShift.id).push(shift);
        }
        continue;
      }
      // Add new row if necessary.
      let rowFound = false;
      let rowInsertIndex = rowInfo.yIndex;
      while (!rowFound) {
        if (rowInsertIndex <= allRows.length - 1) {
          // Check for current row if there is shift overlapping.
          // If there is a overlapping, go to next row and repeat process.
          const overlappingShift = scope.overlapsInsideShiftList(
            shift,
            [
              ...allRows[rowInsertIndex].shifts,
              ...scope.parentShiftHandler.getVirtualParentShiftsForRow(
                rowInsertIndex,
                shiftRowIndexMap,
                parentShiftRowSpanMap
              ),
            ],
            waitingChildsMap
          );
          if (overlappingShift) {
            rowInsertIndex++;
          }
          // If there is no overlapping, add shift into row.
          else {
            const insertRow = allRows[rowInsertIndex];
            insertRow.shifts.push(shift);
            shiftRowIndexMap.set(shift.id, rowInsertIndex);
            rowFound = true;
          }
        } else {
          const overlappingVirtualParentShift = scope.overlapsInsideShiftList(
            shift,
            scope.parentShiftHandler.getVirtualParentShiftsForRow(
              rowInsertIndex,
              shiftRowIndexMap,
              parentShiftRowSpanMap
            )
          );
          // Add new row and insert shift into row.
          const rowIDSuffix = scope.UUID + '_' + rowInsertIndex;
          const insertRow = GanttUtilities.createNewRowFromRow(row, rowIDSuffix, 'MEMBER');
          allRows.push(insertRow);
          if (overlappingVirtualParentShift) {
            rowInsertIndex++;
            continue;
          }
          insertRow.shifts.push(shift);
          shiftRowIndexMap.set(shift.id, allRows.length - 1);
          rowFound = true;
        }
      }
      // if shift is parent shift that child shifts are waiting for -> add childs
      if (waitingChildsMap.get(shift.id)) {
        const shiftRowIndex = shiftRowIndexMap.get(shift.id);
        for (const childShift of waitingChildsMap.get(shift.id)) {
          scope.parentShiftHandler.insertChildShiftIntoParentShift(
            childShift,
            shiftRowIndex,
            allRows,
            row,
            shiftRowIndexMap
          );
        }
        waitingChildsMap.delete(shift.id);
      }
      // if shift is parent shift -> add row id to parent shift row span data
      if (parentShiftRowSpanMap.get(shift.id)) {
        parentShiftRowSpanMap.get(shift.id).rowId = allRows[shiftRowIndexMap.get(shift.id)].id;
      }
    }

    // Add the calculated parent shift row span data to the parent shift handler.
    scope.parentShiftHandler.addParentShiftRowSpanData(parentShiftRowSpanMap);

    // If the list with with not rendered shifts has been given, add them to first row.
    // The overlapping is not relevant here, because the user doesn't see them ;)
    if (noRenderShifts) {
      allRows[0].shifts = allRows[0].shifts.concat(noRenderShifts);
      ShiftDataSorting.sortJSONListByDate(allRows[0].shifts, 'timePointStart');
    }

    // Add grouping-row-layout if there are additional rows.
    if (allRows.length > 1) {
      const childs = allRows[0].child;
      allRows[0].group = allRows[0].child.length ? 'BASE-TREE' : 'BASE-LEAF';
      for (let i = 0; i < allRows.length - 1; i++) {
        allRows[i].child = [];
      }
      // Add origin childs.
      allRows[allRows.length - 1].child = childs;
    }
    return allRows;
  }
}

/**
 * Data structure for overlapping shifts calculation.
 * @constructor
 */
export class GanttSplitOverlappingShiftsDefaultStrategyDataItem {
  shifts: any[];
  yIndex: number;

  constructor() {
    this.shifts = [];
    this.yIndex = 0;
  }
}
