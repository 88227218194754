import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { isValidDate } from './date.util';

@Injectable({ providedIn: 'root' })
export class TimeFormatterService {
  constructor(private translate: TranslateService, private datePipe: DatePipe) {}

  public millisecondsToDateTimeString(milliseconds: number): string {
    if (milliseconds == undefined) {
      return;
    }
    const date = new Date(milliseconds);
    if (isValidDate(date)) {
      return this.dateToDateTimeString(date);
    } else {
      console.warn('Invalid date in ToDateTimeString detected');
      return milliseconds.toString();
    }
  }

  public dateToDateTimeString(newDate: Date): string {
    return this.dateToDateString(newDate) + ' ' + this.dateToTimeString(newDate);
  }

  public millisecondsToTimeString(milliseconds: number): string {
    if (milliseconds == undefined) {
      return;
    }
    const date = new Date(milliseconds);
    if (isValidDate(date)) {
      return this.dateToTimeString(new Date(milliseconds));
    } else {
      console.warn('Invalid date in ToTimeString detected');
      return milliseconds.toString();
    }
  }

  public dateToTimeString(newDate: Date): string {
    const hours = this.fillNumber(newDate.getHours(), 2);
    const minutes = this.fillNumber(newDate.getMinutes(), 2);
    return hours + ':' + minutes;
  }

  public millisecondsToDateString(milliseconds: number): string {
    if (milliseconds == undefined) {
      return;
    }
    const date = new Date(milliseconds);
    if (isValidDate(date)) {
      return this.dateToDateString(new Date(milliseconds));
    } else {
      console.warn('Invalid date in ToDateString detected');
      return milliseconds.toString();
    }
  }

  public dateToDateString(newDate: Date): string {
    return this.datePipe.transform(newDate.getTime(), this.translate.instant('DATE.FORMATS.DATE'));
  }

  public millisecondsToDurationString(milliseconds: number): string {
    const weeks = Math.trunc(milliseconds / TimeMillis.Week);
    const days = Math.trunc((milliseconds % TimeMillis.Week) / TimeMillis.Day);
    const hours = Math.trunc((milliseconds % TimeMillis.Day) / TimeMillis.Hour);
    const minutes = Math.trunc((milliseconds % TimeMillis.Hour) / TimeMillis.Minute);
    const seconds = Math.trunc((milliseconds % TimeMillis.Minute) / TimeMillis.Second);

    let resultString = '';

    if (weeks) {
      resultString += weeks + 'w ';
    }
    if (days) {
      resultString += days + 'd ';
    }
    if (hours) {
      resultString += hours + 'h ';
    }
    if (minutes) {
      resultString += minutes + 'm ';
    }
    if (seconds) {
      resultString += seconds + 's';
    }

    return resultString;
  }

  private fillNumber(number: number, digitCount: number): string {
    let count = this.numDigits(number);

    const isNegativ = number < 0;
    const tempNumber = isNegativ ? -number : number;

    let leadingZeroes = '';
    while (count < digitCount) {
      leadingZeroes = '0' + leadingZeroes;
      count++;
    }

    return (isNegativ ? '-' : '') + leadingZeroes + number;
  }

  private numDigits(number) {
    return (Math.log10((number ^ (number >> 31)) - (number >> 31)) | 0) + 1;
  }
}

enum TimeMillis {
  Week = 604800000,
  Day = 86400000,
  Hour = 3600000,
  Minute = 60000,
  Second = 1000,
}
