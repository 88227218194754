import { DragDropModule } from '@angular/cdk/drag-drop';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { TemplateButtonModule } from '../../modeleditor/components/button/button.module';
import { MessageComponent } from './message.component';

@NgModule({
  imports: [CommonModule, OverlayModule, TranslateModule, DragDropModule, TemplateButtonModule],
  declarations: [MessageComponent],
})
export class MessageModule {}
