import { ElementRef } from '@angular/core';
import { Vertex } from './vertex';

export interface IHelperPoint {
  x: number;
  y: number;
}
export class HotspotUtils {
  constructor() {}

  public createVertexFromPosition(x: number, y: number, elementRef: ElementRef) {
    const postion = this.transformToRelative(x, y, elementRef);
    const vertex = new Vertex(postion.x, postion.y);
    return vertex;
  }

  public convertMouseEventtoVertex(mouseEvent: MouseEvent, elementRef: ElementRef): Vertex {
    const relativeCoords = this.transformToRelative(mouseEvent.x, mouseEvent.y, elementRef, mouseEvent.target);
    return new Vertex(relativeCoords.x, relativeCoords.y);
  }

  public transformToRelative(
    xPosition,
    yPostion,
    elementRef: ElementRef,
    parentWrapper?: any
  ): { x: number; y: number } {
    if (parentWrapper) {
      // offset
      const rect = parentWrapper.getBoundingClientRect();
      xPosition = xPosition - rect.left;
      yPostion = yPostion - rect.top;
    }
    const yRelative: number = (yPostion / elementRef.nativeElement.clientHeight) * 100;
    const xRelative: number = (xPosition / elementRef.nativeElement.clientWidth) * 100;
    return {
      x: xRelative,
      y: yRelative,
    };
  }

  public getDistanceToOtherVertex(vertexA: Vertex, vertexB: Vertex, elementRef: ElementRef) {
    const vertexATranform = this.transformVertex(vertexA, elementRef);
    const vertexBTranform = this.transformVertex(vertexB, elementRef);
    const xD = Math.abs(vertexATranform.x - vertexBTranform.x);
    const yD = Math.abs(vertexATranform.y - vertexBTranform.y);

    return xD + yD;
  }

  public transformVertex(vert: Vertex, elementRef: ElementRef) {
    return {
      x: (elementRef.nativeElement.clientWidth / 100) * vert.getX(),
      y: (elementRef.nativeElement.clientHeight / 100) * vert.getY(),
    };
  }

  private sqr(x) {
    {
      return x * x;
    }
  }

  private dist2(lineStart: IHelperPoint, lineEnd: IHelperPoint) {
    return this.sqr(lineStart.x - lineEnd.x) + this.sqr(lineStart.y - lineEnd.y);
  }

  private distToSegmentSquared(newPoint: IHelperPoint, lineStart: IHelperPoint, lineEnd: IHelperPoint) {
    const l2 = this.dist2(lineStart, lineEnd);
    if (l2 == 0) return this.dist2(newPoint, lineStart);
    let t =
      ((newPoint.x - lineStart.x) * (lineEnd.x - lineStart.x) +
        (newPoint.y - lineStart.y) * (lineEnd.y - lineStart.y)) /
      l2;
    t = Math.max(0, Math.min(1, t));
    return this.dist2(newPoint, {
      x: lineStart.x + t * (lineEnd.x - lineStart.x),
      y: lineStart.y + t * (lineEnd.y - lineStart.y),
    });
  }

  public distToSegment(newPoint: Vertex, lineStart: Vertex, lineEnd: Vertex) {
    const helperPoint = this.getHelperPoint(newPoint);
    const lineStartHelper = this.getHelperPoint(lineStart);
    const lineEndHelper = this.getHelperPoint(lineEnd);
    return Math.sqrt(this.distToSegmentSquared(helperPoint, lineStartHelper, lineEndHelper));
  }

  private getHelperPoint(vertex: Vertex): IHelperPoint {
    return {
      x: vertex.getX(),
      y: vertex.getY(),
    };
  }
}
