import { GanttCallBackStackExecuter } from '../../callback-tools/callback-stack-executer';
import { DataHandler } from '../data-handler';
import { GanttCanvasShift } from '../data-structure/data-structure';

export class CanvasDataEditor {
  dataHandler: DataHandler;

  constructor(dataHandler) {
    this.dataHandler = dataHandler;
  }
  editCanvasShiftById(shiftObjects: Map<string, Partial<GanttCanvasShift>>, addUnknownProperties = false): boolean {
    let returnBool = true;
    const canvasShiftDataSet = this.dataHandler.getCanvasShiftDataset();
    const updatedShiftIds: string[] = [];
    const updatedProperties = [];
    const shiftMap = new Map(canvasShiftDataSet.map((shift) => [shift.id, shift]));

    for (const [id, updateData] of shiftObjects) {
      const canvasShift = shiftMap.get(id);
      if (!canvasShift) {
        console.error(`Shift with ID '${id}' not found.`);
        returnBool = false;
        continue;
      }

      const propertiesToUpdate = Object.getOwnPropertyNames(updateData);
      for (const property of propertiesToUpdate) {
        if (!addUnknownProperties && !canvasShift.hasOwnProperty(property)) {
          console.error(`Property '${property}' on shift '${canvasShift.id}' not found.`);
          returnBool = false;
          continue;
        }

        updatedShiftIds.push(id);
        canvasShift[property] = updateData[property];
        if (!updatedProperties.includes(property)) {
          updatedProperties.push(property);
        }
      }
    }

    GanttCallBackStackExecuter.execute(this.dataHandler.callBack.updateCanvasShiftData, shiftObjects);

    return returnBool;
  }
}
