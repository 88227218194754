import { Component } from '@angular/core';
import { MaterialModule } from '@core/material/material.module';
import { Subject } from 'rxjs';

@Component({
  standalone: true,
  imports: [MaterialModule],
  templateUrl: './password-input-toggle-button.html',
})
export class PasswordInputToggleButton {
  protected showPassword = false;
  private readonly _showPasswordChange$ = new Subject<boolean>();

  protected handlePasswordChange() {
    this.showPassword = !this.showPassword;
    this._showPasswordChange$.next(this.showPassword);
  }

  get showPasswordChange$() {
    return this._showPasswordChange$.asObservable();
  }
}
