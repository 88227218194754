import { Clipboard } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { ImageModule } from '@app-modeleditor/components/image/image.module';
import { TranslateModule } from '@ngx-translate/core';
import { ConditionsMenuModule } from '../core/conditions-menu/conditions-menu.module';
import { ErrorMenuModule } from '../core/error-menu/error-menu.module';
import { InfoMenuModule } from '../core/info-menu/info-menu.module';
import { SaxmsTooltipModule } from './../../tooltip/tooltip.module';
import { NumericInputComponent } from './numeric-input.component';

@NgModule({
  declarations: [NumericInputComponent],
  exports: [NumericInputComponent],
  imports: [
    TranslateModule,
    CommonModule,
    MatInputModule,
    ReactiveFormsModule,
    MatIconModule,
    MatSelectModule,
    MatButtonModule,
    SaxmsTooltipModule,
    MatMenuModule,
    ImageModule,
    ErrorMenuModule,
    InfoMenuModule,
    ConditionsMenuModule,
  ],
  providers: [Clipboard],
})
export class NumericInputModule {}
