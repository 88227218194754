import { GanttActionWrapper } from 'frontend/src/dashboard/gantt/general/gantt-actions/external-action-registration';
import { GanttEventTrigger } from 'frontend/src/dashboard/gantt/general/gantt-actions/external-event-trigger';
import { takeWhile } from 'rxjs/operators';
import { EGanttActionEvent } from '../../../../gantt-actions/gantt-action-handling/gantt-action-event.enum';

/**
 * Action handling of shift creator drag end event.
 * Drag end means the timepoint if user "drops" the mouse after creating a shift.
 */
export class ShiftCreatorDragEndEvent extends GanttEventTrigger {
  public readonly eventName: EGanttActionEvent = EGanttActionEvent.DRAG_END;

  /**
   * Adds dragEndMarkerAdding-callback to gantt to save template data and execute actions.
   * the callback will be registered by the (backend) given action id.
   * @param ganttDiagram BestGantt where callbacks will be registered inside.
   */
  public registerInGantt(): void {
    this.executer.onDragEndCallback(this.action.id, (data) => {
      if (!data) {
        return;
      }
      this.executer.endCreateShiftMode();
      this.saveDataToTemplateDragEndNewlyCreated(data.shiftData, data.rowData, this._ganttLibService.bestGantt);
      const actionWrapper: GanttActionWrapper = {
        actionScope: this.action.actionScope,
        actions: this.action.actions,
      };
      this._ganttLibService.ngZone.run(() => {
        this.actionHandler
          .executeActions(actionWrapper)
          .pipe(takeWhile(() => this.actionHandler.isAlive()))
          .subscribe((data) => {
            this.responseHandler.handleUpdateNotification(data as any);
          });
      });
    });
  }

  /**
   * Removes dragEndBeforeMarkerAdding callback from gantt.
   * @param ganttDiagram BestGantt where callbacks will be removed from.
   */
  public removeFromGantt(): void {
    super.removeFromGantt();
    this.executer.onDragEndCallbackRemove(this.action.id);
  }

  /**
   * Bundles functionality to store event data to template.
   * Necessary for global action handling.
   * @param callback Event callback.
   * @param ganttRowData Data of row in which the blocking interval has been created.
   * @param ganttDiagram BestGantt.
   */
  public saveDataToTemplateDragEndNewlyCreated(shiftData: any, ganttRowData: any, ganttDiagram: any): void {
    // set template data
    if (!ganttRowData.additionalData) console.warn('No additional data.');

    this.templateData.setSelectedRow({
      id: ganttRowData.additionalData.originalRowId,
      canonicalName: ganttRowData.additionalData.canonicalName,
    });

    // TODO: This is a wrong attribute -> take setSelectedBlock instead (with the capital letter 'B') -> must be corrected by backend first (16.06.21)
    this.templateData.setSelectedblock({
      // TODO: must be removed as soon as the backend uses the selectedTimePeriodInput attribute (14.06.21)
      start: shiftData.timePointStart.getTime(),
      end: shiftData.timePointEnd.getTime(),
    });
  }
}
