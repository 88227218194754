export class FileProgress {
  private numberOfFilesToTransfer: number;
  private numberOfFilesTransfered: number;
  private numberOfTransferedBytes: number;
  private numberOfBytesToTransfer: number;
  private tasks: { [key: string]: string };
  private files: { [key: string]: any };

  public setFiles(files: { [key: string]: any }): this {
    this.files = files;
    return this;
  }
  public addFile(fileId: string, file: any): this {
    if (!this.files) {
      this.files = {};
    }
    this.files[fileId] = file;
    return this;
  }

  public getFiles(): { [key: string]: any } {
    return this.files || {};
  }

  public removeFile(fileId: string): this {
    if (!this.files) {
      return this;
    }

    delete this.files[fileId];
    return this;
  }

  public addTask(taskId: string, task: string): this {
    if (!this.tasks) {
      this.tasks = {};
    }
    this.tasks[taskId] = task;
    return this;
  }

  public removeTask(taskId: string): this {
    if (!this.tasks) {
      return this;
    }

    delete this.tasks[taskId];
    return this;
  }

  public getTasks(): { [key: string]: string } {
    return this.tasks || {};
  }

  public getTaskKeys(): string[] {
    return Object.keys(this.tasks || {});
  }

  /**
   * get number of total bytes to transfer
   * @returns number
   */
  public getNumberOfBytesToTransfer(): number {
    return this.numberOfBytesToTransfer || 0;
  }

  /**
   * set number of totabl bytes to transfer
   * @param numberOfBytesToDownload number
   * @returns this
   */
  public setNumberOfBytesToTransfer(numberOfBytesToDownload: number): this {
    this.numberOfBytesToTransfer = numberOfBytesToDownload;
    return this;
  }

  /**
   * get number of already transfered bytes
   * @returns number
   */
  public getNumberOfTransferedBytes(): number {
    return this.numberOfTransferedBytes || 0;
  }

  /**
   * set number of already transfered bytes
   * @param numberOfTransferedBytes number
   * @returns this
   */
  public setNumberOfTransferedBytes(numberOfTransferedBytes: number): this {
    this.numberOfTransferedBytes = numberOfTransferedBytes;
    return this;
  }

  /**
   * get number of total files to transfer
   * @returns number
   */
  public getNumberOfFilesToTransfer(): number {
    return this.numberOfFilesToTransfer || 0;
  }

  /**
   * set number of total files to transfer
   * @param numberOfFilesToDownload number
   * @returns this
   */
  public setNumberOfFilesToTransfer(numberOfFilesToDownload: number): this {
    this.numberOfFilesToTransfer = numberOfFilesToDownload;
    return this;
  }

  /**
   * set number of of already transfered files
   * @param numberOfFilesDownloaded number
   * @returns this
   */
  public setNumberOfFilesTransfered(numberOfFilesDownloaded: number): this {
    this.numberOfFilesTransfered = numberOfFilesDownloaded;
    return this;
  }

  /**
   * get number of already transfered files
   * @returns number
   */
  public getNumberOfFilesTransfered(): number {
    return this.numberOfFilesTransfered || 0;
  }

  /**
   * get progress as fixed percentage
   * @returns string
   */
  public getProgressAsString(): string {
    return `${this.getProgress().toFixed(2)}%`;
  }

  /**
   * get current file progress from bytes as percentage
   * @returns number
   */
  public getProgress(): number {
    let percentage = (this.getNumberOfTransferedBytes() / this.getNumberOfBytesToTransfer()) || 0;
    percentage = isFinite(percentage) ? percentage : 1;
    percentage = Math.max(0, Math.min(1, percentage));
    return percentage * 100;
  }
}
