export class DateUtils {
  constructor() {}

  public static getTimeFromDate(d: Date): number {
    if (!d) {
      return 0;
    }
    return d.getHours() * 3600000 + d.getMinutes() * 60000 + d.getSeconds() * 1000 + d.getMilliseconds();
  }

  public static getMillisFromTime(d: string): number {
    if (!d) {
      return null;
    }

    let r = 0;
    const parts: string[] = d.split(':');
    r += (parseInt(parts[0], 10) || 0) * 1000 * 60 * 60;
    r += (parseInt(parts[1], 10) || 0) * 1000 * 60;

    return r;
  }

  /**
   * This function calculates the delta value for days on which a time changeover takes place. (summer to winter time and vice versa)
   * Returns the deviating time, starting from 24h, in milliseconds.
   * @param d - The date for which to calculate the offset time.
   * @returns The offset time in milliseconds.
   */
  public static getOffsetTime(d: Date): number {
    // Create two dates: one at the start of the day and one at the end
    const start = new Date(d.getFullYear(), d.getMonth(), d.getDate());
    const end = new Date(d.getFullYear(), d.getMonth(), d.getDate() + 1);

    // Calculate the difference in milliseconds
    const diff = end.getTime() - start.getTime();

    // Convert to hours
    const hours = diff / (1000 * 60 * 60);

    return (hours - 24) * 60 * 60 * 1000;
  }

  /**
   * returns a list of time steps to choose from
   * @param {number} step defines step-width of time
   * @returns number[]
   */
  public static getTimeSteps(step: number): number[] {
    if (!step) {
      return [];
    }
    const times: number[] = [];
    for (let i = 0; i < 86400000; i += step) {
      times.push(i);
    }
    return times;
  }

  /**
   * gets the first day of the week for a given date
   * @param {Date} date date
   * @returns Date
   */
  public static getFirstDay(date: Date): Date {
    const _date: Date = new Date(date);
    const day: number = _date.getDay();
    const diff: number = day - 1 < 0 ? 6 : day - 1;
    return new Date(_date.setDate(_date.getDate() - diff));
  }

  /**
   * gets number of week for a given date
   * @param {Date} date date
   * @returns number
   */
  public static getNumberOfWeek(date: Date): number {
    date = new Date(date);
    date.setHours(0, 0, 0, 0);
    // Thursday in current week decides the year.
    date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
    // January 4 is always in week 1.
    const week1: Date = new Date(date.getFullYear(), 0, 4);
    // Adjust to Thursday in week 1 and count number of weeks from date to week1.
    return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + ((week1.getDay() + 6) % 7)) / 7);
  }

  /**
   * get start and end Dates of a givven week number in a given year
   * @param {number} w number of week
   * @param {number} y full year
   * @returns {start:Date; end:Date}
   */
  public static getDateByWeek(w: number, y: number): { start: Date; end: Date } {
    const simple: Date = new Date(y, 0, 1 + (w - 1) * 7);
    const dow: number = simple.getDay();
    const ISOweekStart: Date = simple;
    if (dow <= 4) {
      ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    } else {
      ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    }
    const start = ISOweekStart;
    let end = new Date(start);
    end.setDate(start.getDate() + 7);
    end = new Date(end.getTime() - 1);
    return { end, start };
  }

  /**
   * clamp months between 0 and 11
   * @param month number
   * @returns number
   */
  public static clampMonth(month: number): number {
    return month > 11 ? month - 12 : month;
  }

  /**
   * set day of date to monday
   * @param d Date
   * @returns Date
   */
  public static setMonday(d: Date): Date {
    d = new Date(d);
    const day = d.getDay(),
      diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
    return new Date(d.setDate(diff));
  }
}

