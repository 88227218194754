import { Component, HostBinding, Inject } from '@angular/core';
import { NumericRangePicker } from '@app-modeleditor/components/button-slider/numeric-range-picker';
import { CONTAINER_DATA } from '@app-modeleditor/components/lightbox/overlay/container-data';
import { DefaultOverlayContainer } from '@app-modeleditor/components/lightbox/overlay/default-overlay-container';

@Component({
  selector: 'app-numeric-range-picker-overlay',
  templateUrl: './overlay.component.html',
  styleUrls: ['./overlay.component.scss'],
})
export class OverlayComponent extends DefaultOverlayContainer<NumericRangePicker> {
  @HostBinding('class.mat-elevation-z4') elevation = true;

  constructor(@Inject(CONTAINER_DATA) public data: NumericRangePicker) {
    super();
  }

  defaultClick(e: MouseEvent): void {
    e.stopImmediatePropagation();
    e.stopPropagation();
  }

  afterChanged(data): void {
    if (data.value < data.lowerBound) {
      data.value = data.lowerBound;
    }

    if (data.value > data.upperBound) {
      data.value = data.upperBound;
    }
  }

  increase(e: MouseEvent, data): void {
    this.defaultClick(e);
    data.value += data.stepWidth;
  }

  decrease(e: MouseEvent, data): void {
    this.defaultClick(e);
    data.value -= data.stepWidth;
  }

  public close(): void {
    this.getRef().dispose();
  }
}
