import { Action } from '@app-modeleditor/components/button/action/action';
import { EActionType } from '@app-modeleditor/components/button/action/action-type.enum';
import { EPredefinedAction } from '@app-modeleditor/components/button/action/predefined-action.enum';
import { Button } from '@app-modeleditor/components/button/button';
import { EEntryElementPosition } from '@app-modeleditor/components/entry-collection/entry-element-position.enum';
import { Observable } from 'rxjs';
import { Lightbox } from '../lightbox';

export class ConfirmLightbox extends Lightbox {
  private confirm: Button;
  private cancel: Button;
  private disableConfirmButton: boolean;

  public isDisableConfirmButton(): boolean {
    return typeof this.disableConfirmButton === 'boolean' ? this.disableConfirmButton : false;
  }

  public setDisableConfirmButton(disableConfirmButton: boolean): this {
    this.confirm.setShow(!disableConfirmButton);
    if (this.disableConfirmButton) {
      this.disableConfirmButton = disableConfirmButton;
      return this;
    }
  }

  public setConfirmLabel(confirmLabel: string): this {
    this.confirm.setName(confirmLabel);
    return this;
  }

  public setDisableCancelButton(state: boolean): this {
    super.setDisableCancelButton(state);
    if (this.cancel) {
      this.cancel.setShow(!state);
    }
    return this;
  }

  constructor(name: string) {
    super();
    this.setName(name)
      .setResizeable(false)
      .setCanOpenExternalWindow(false)
      .setDisableSaveButton(true)
      .setDisableCancelButton(true);

    this.setButtons([
      (this.cancel = new Button()
        .setName('BUTTONS.CANCEL')
        .setOrder(-999)
        .chainActions(new Action().setActionType(EActionType.PREDEFINED).setId(EPredefinedAction.CANCEL_LIGHTBOX))),
      (this.confirm = new Button()
        .setPosition(EEntryElementPosition.RIGHT)
        .setName('BUTTONS.CONFIRM')
        .setOrder(999)
        .chainActions(new Action().setActionType(EActionType.PREDEFINED).setId(EPredefinedAction.CLOSE_LIGHTBOX))),
    ]);
    this.setSimpleContent(true);
  }

  setCustomConfirmAction(cb: () => Observable<void>): this {
    this.confirm.chainActions(new Action().setCb(cb));
    return this;
  }

  setCustomCancelAction(cb: () => Observable<void>): this {
    this.cancel.chainActionsBefore(new Action().setCb(cb));
    this.setOnCustomCancelAction(this.cancel);
    return this;
  }
}
