import { Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { EntryElementValue } from '@app-modeleditor/components/entry-collection/entry-element-value';
import { ResizeEvent } from 'frontend/src/dashboard/view/resize/resize-event';
import { EResizeType } from 'frontend/src/dashboard/view/resize/resize-type.enum';
import { ResizeService } from 'frontend/src/dashboard/view/resize/resize.service';
import { LabeledText } from './labeled-text';

@Component({
  selector: 'app-labeled-text',
  templateUrl: './labeled-text.component.html',
  styleUrls: ['./labeled-text.component.scss'],
})
export class LabeledTextComponent implements OnDestroy {
  @Input() formElement: LabeledText;
  compact: boolean;
  private resizeId: string;
  @Input() disabled: boolean;
  @ViewChild('labeledText') set c1(labeledTextRef: ElementRef) {
    this._resizeApi.complete(this.resizeId);
    if (!labeledTextRef) {
      return;
    }
    this.resizeId = this._resizeApi.create(
      labeledTextRef.nativeElement,
      (event: ResizeEvent) => {
        this.compact = event.getNewHeight() <= 40 ? true : false;
      },
      { types: [EResizeType.HEIGHT] }
    );
  }

  constructor(private _resizeApi: ResizeService) {}

  get breakLine(): boolean {
    if (this.formElement && this.formElement instanceof LabeledText) {
      return this.formElement.isBreakLine();
    }

    return false;
  }

  ngOnDestroy(): void {
    this._resizeApi.complete(this.resizeId);
  }

  get Value(): EntryElementValue {
    return this.formElement.getValue<EntryElementValue>();
  }

  get StringValue(): string {
    if (!this.Value) {
      return null;
    }
    return this.Value.getValue<string>();
  }
}
