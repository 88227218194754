import { Injectable } from '@angular/core';
import { NumericRangePicker } from '@app-modeleditor/components/button-slider/numeric-range-picker';
import { NumericInput } from '@app-modeleditor/components/elements/numeric-input/numeric-input';
import { EntryElementValue } from '@app-modeleditor/components/entry-collection/entry-element-value';
import { EFieldType } from '@app-modeleditor/components/entry-collection/field-type.enum';
import { MultiObjectSelector } from '@app-modeleditor/components/selector/multi-object-selector';
import { EDatatype } from 'frontend/src/dashboard/model/entry/datatype.enum';
import { EUnit } from 'frontend/src/dashboard/model/entry/unit.enum';
import { ETemplateType } from 'frontend/src/dashboard/model/resource/template-type';
import { DetailedSettings } from 'handsontable/plugins/nestedHeaders';
import { SaxMsSpreadSheetColumn } from '../core/saxms-spreadsheet';
import { ESaxMsColumnUnits } from '../model/table-column-unity.enum';
import { ESpreadsheetDatatypes } from '../model/table-datatypes.enum';
import { TableHeader } from '../model/table-header';
import { SortFilterObject } from '../model/table-sort-filter';
import { ISpreadsheetSaveSettings } from '../spreadsheet';
import { SpreadsheetHelperUtil } from './spreadsheet-helper-util';

@Injectable()
export class ColumnUtil {
  private columns: SaxMsSpreadSheetColumn[] = [];
  private nestesColumnHeaders: DetailedSettings[][] = []; // DetailedSettings from NestedHeaders - up to v10: Handsontable.nestedHeader.NestedHeader
  private spreadsheetsettings: ISpreadsheetSaveSettings;
  private editableModeLocked = false;

  constructor(private spreadsheetHelperUtil: SpreadsheetHelperUtil) {}

  /**
   * @param index index of the column
   * @returns the default filter-sort-object for a column
   */
  private getDefaultFilterSortObject(index: number): SortFilterObject {
    return new SortFilterObject().setColumnIndex(index).setTableId(this.spreadsheetHelperUtil.getTableId());
  }

  public getColIndex(id: string): number {
    return this.getColumns().findIndex((c) => c.id === id);
  }

  /**
   * checks if the passed column has an order index in the column settings and returns it. In the negative case, the default index of the column is returned
   * @param column column information from the backend
   * @param defaultIndex default index of the column
   * @returns the column index from the column settings or the default index of the column
   */
  public getColumnIndex(column, defaultIndex) {
    if (!this.spreadsheetsettings) {
      return null;
    }
    const match = this.spreadsheetsettings.columnSettings.find(
      (col) => !isNaN(col.orderIdx) && column.id == col.columnID
    );
    const finalIndex = match?.orderIdx || defaultIndex;

    this.spreadsheetHelperUtil.addEntryToColumnIndexMap(
      this.spreadsheetHelperUtil.getTableId() + '.' + column.id,
      finalIndex
    );
    return finalIndex;
  }

  /**
   * checks if the passed column by the id has an width in the column settings and returns it. In the negative case the default width or preferred width of the column is returned
   * @param columnId id of the column
   * @param preferredWidth preferred width of the column
   * @returns the column width from the column settings, the width index or the preferred width of the column
   */
  private getColumnWidth(columnId: string, preferredWidth: number) {
    if (!this.spreadsheetsettings) {
      if (preferredWidth) {
        return preferredWidth;
      }
      return 150;
    }

    const columnSetting = this.spreadsheetsettings.columnSettings.find((col) => col.columnID === columnId);
    if (columnSetting && columnSetting.width) {
      return columnSetting.width;
    }

    if (preferredWidth) {
      return preferredWidth;
    }
    return 150;
  }

  /**
   * sort the columns by index
   * @returns the sorted columns array
   */
  private sortColumnsByIndex(): SaxMsSpreadSheetColumn[] {
    const data = this.columns.map((c) => {
      const match = this.spreadsheetsettings?.columnSettings.find((s) => c.id === s.columnID);
      if (match) {
        c.data = match.orderIdx;
      }
      return c;
    });

    const sortColumns: SaxMsSpreadSheetColumn[] = data.sort((a, b) => a.data - b.data);

    sortColumns.forEach((column, index) => {
      column.data = index;
    });
    return sortColumns;
  }

  /**
   * checks if a column is affected by font size scaling
   * @param column
   * @returns a flag whether a column is affected by font size scaling
   */
  private getListenToAutoFontSize(column) {
    if (this.spreadsheetHelperUtil.getTableTemplate().isFontSizeAutoScale()) {
      if (column.unit) {
        const unit: ESaxMsColumnUnits = this.getColumnUnit(column.unit);
        if (unit) {
          return false;
        }
      }

      const dataType: ESpreadsheetDatatypes = this.getDatatype(column.datatype);

      return dataType === ESpreadsheetDatatypes.float || dataType === ESpreadsheetDatatypes.number;
    }
    return false;
  }

  /**
   * mapping a backend column to a frontend column
   * @param column backend column
   * @param index index inside the list
   * @returns frontend column
   */
  private createColumn(column: TableHeader, index: number): SaxMsSpreadSheetColumn {
    if (column.getEntryElement()) {
      column.setFieldType(column.getEntryElement().getFieldType());
    }
    if (column.getDatatype().toLowerCase().indexOf('boolean') !== -1) {
      column.setFieldType(EFieldType.CHECK_BOX);
    }

    const spreadsheetColumn: SaxMsSpreadSheetColumn = {
      id: column.getId(),
      title: '',
      label: column.getValue(),
      data: this.getColumnIndex(column, index),
      editor:
        this.spreadsheetHelperUtil.getTableOptions()?.editable &&
        column?.isEditable() &&
        this.spreadsheetHelperUtil.getTableOptions().celleditable
          ? this.spreadsheetHelperUtil.getSpreadsheetCustomEditor()
          : false,
      isCustomEditor: true,
      datatype: this.getDatatype(column.getDatatype()),
      fieldType: column.getFieldType(),
      saveOrder: column.getSaveOrder(),
      timeValue: column.getTimeValue(),
      readOnly: this.getReadOnly(column),
      sortable: column.isSortable(),
      editable: column.isEditable(),
      windup: column.getWindUpOperation(),
      thousandSeparator: column.isThousandsSeparator(),
      autoSaveCell: column.isAutoSave(),
      filter: column.getFilter(),
      stepWidth: column.getStepWidth(),
      filterable: column.getFilter() ? true : false,
      useLocalTime: column.isUseLocalTime(),
      // width: (index === 0 && this.gridLayoutSize) ? this.gridLayoutSize : this.getHeaderWidth(header.id, header.preferredWidth),
      width: this.getColumnWidth(column.getId(), column.getPreferredWidth()),
      filterSortObject: this.getDefaultFilterSortObject(index),
      multiColumnSorting: {
        indicator: false,
        columnAction: false,
        sortEmptyCells: true,
      },
      invalidCellClassName: 'invalidCellValue',
      listenToAutoFontSize: this.getListenToAutoFontSize(column),
      preferedWidth: column.getPreferredWidth(),
      nullable: column.isNullable(),
      dateTimeFormat: column.getDateTimeFormat(),
    };
    // localIndexMap.set()
    if (column.getValidationRegex()) {
      spreadsheetColumn.validatorRegEx = new RegExp(column.getValidationRegex());
      spreadsheetColumn.validationRegexInfoText = column.getValidationRegexInfoText();
    }

    if (spreadsheetColumn.datatype === ESpreadsheetDatatypes.bool && column.getFieldType() === EFieldType.COMBO_BOX) {
      spreadsheetColumn.fieldType = EFieldType.CHECK_BOX;
    }

    if (column.getUnit()) {
      spreadsheetColumn.unit = this.getColumnUnit(column.getUnit());
    }

    if (column.getAvailableValues()) {
      spreadsheetColumn.selectOptions = column.getAvailableValues();
    }

    switch (spreadsheetColumn.fieldType) {
      case EFieldType.NUMERIC_RANGE_PICKER:
        spreadsheetColumn.entryElement = column.getEntryElement() as NumericRangePicker;
        break;
      case EFieldType.MULTI_OBJECT_SELECTOR:
      case EFieldType.OBJECT_SELECTOR:
        // if (column.entryElement) {
        spreadsheetColumn.entryElement = (column.getEntryElement() as MultiObjectSelector)?.setShowChipsBelow(false);
        // } else {
        //     spreadsheetColumn.entryElement = new MultiObjectSelector()
        //         .setFieldType(spreadsheetColumn.fieldType)
        //         .setShowChipsBelow(false)
        //         .setName(spreadsheetColumn.label);
        // }
        break;
      case EFieldType.COMBO_BOX:
        spreadsheetColumn.entryElement = new MultiObjectSelector()
          .setFieldType(EFieldType.OBJECT_SELECTOR)
          .setShowChipsBelow(false)
          .setName(spreadsheetColumn.label)
          .setAvailableOptions(this.getComboBoxValues(spreadsheetColumn));
        break;
      case EFieldType.TEXT_FIELD:
        spreadsheetColumn.entryElement = new NumericInput()
          .setAutoFocused(true)
          .setFieldType(EFieldType.TEXT_FIELD)
          .setStepWidth(spreadsheetColumn.stepWidth ? spreadsheetColumn.stepWidth : 0)
          .setDatatype(EDatatype.STRING);

        if (this.spreadsheetHelperUtil.isNumberField(spreadsheetColumn)) {
          spreadsheetColumn.entryElement.setDatatype(
            this.spreadsheetHelperUtil.getDatatypeForQuicksearch(spreadsheetColumn)
          );

          if (spreadsheetColumn.unit && spreadsheetColumn.unit === ESaxMsColumnUnits.percent) {
            spreadsheetColumn.entryElement.setUnit(EUnit.PERCENTAGE).setStepWidth(0);
          }
        }
        break;
      case EFieldType.TEXT_AREA:
        spreadsheetColumn.entryElement = new NumericInput()
          .setName(spreadsheetColumn.label)
          .setAutoFocused(true)
          .setFieldType(EFieldType.TEXT_AREA)
          .setDatatype(EDatatype.STRING);
        break;
      case EFieldType.COLOR_PICKER:
        spreadsheetColumn.entryElement = column.getEntryElement();
        break;
    }

    /* else {
      if (spreadsheetHeader.datatype === SpreadsheetDatatypes.bool && header.fieldType === 'COMBO_BOX') {
        spreadsheetHeader.selectOptions = [true, false];
      }
    }*/
    if (column.getDurationPickerValues()) {
      spreadsheetColumn.durationPickerValues = column.getDurationPickerValues();
    }

    if (column.getGeneralInfoText()) {
      spreadsheetColumn.generalInfoText = column.getGeneralInfoText();
    }

    return spreadsheetColumn;
  }

  /**
   * calculates the values for the combobox of a column
   * @param column
   * @returns values for the combobox
   */
  private getComboBoxValues(column: SaxMsSpreadSheetColumn): EntryElementValue[] {
    return column.selectOptions.map((option) =>
      new EntryElementValue().setValue(new EntryElementValue().setName(option).setValue(option))
    );
  }

  /**
   * translate a unit symbol to a unit-enum value
   * @param unit unit symbol
   * @returns unit-enum value or null
   */
  private getColumnUnit(unit: any): ESaxMsColumnUnits {
    switch (unit) {
      case '%':
        return ESaxMsColumnUnits.percent;
      case '€':
        return ESaxMsColumnUnits.euro;
    }
    return null;
  }

  /**
   * translate a java data type string to a datatype-enum value
   * @param datatype java data type string
   * @returns datatype-enum value
   */
  private getDatatype(datatype: string): ESpreadsheetDatatypes {
    if (datatype.toLowerCase().indexOf('string') !== -1) {
      return ESpreadsheetDatatypes.text;
    }

    if (datatype.toLowerCase().indexOf('integer') !== -1) {
      return ESpreadsheetDatatypes.number;
    }

    if (datatype.toLowerCase().indexOf('float') !== -1 || datatype.toLowerCase().indexOf('double') !== -1) {
      return ESpreadsheetDatatypes.float;
    }

    if (datatype.toLowerCase().indexOf('date') !== -1) {
      return ESpreadsheetDatatypes.date;
    }

    if (datatype.toLowerCase().indexOf('long') !== -1) {
      return ESpreadsheetDatatypes.long;
    }

    if (datatype.toLowerCase().indexOf('boolean') !== -1) {
      return ESpreadsheetDatatypes.bool;
    }

    return ESpreadsheetDatatypes.text;
  }

  /**
   * handle the mapping of column and column groups
   * @param tableColumns columns from the backend
   * @param index index of the column inside list
   */
  private buildColumns(tableColumns: TableHeader[], index) {
    for (const column of tableColumns || []) {
      const columnIndex = column.getColumnIndex() + index;
      if (column.getType() === ETemplateType.TABLE_HEADER_GROUP) {
        this.buildColumns(column.getTableHeaders(), columnIndex);
      } else {
        this.columns.push(this.createColumn(column, columnIndex));
      }
    }
  }

  /**
   * checks if a column is read-only
   * @param column information of the column
   * @returns readonly state of a column
   */
  public getReadOnly(column: SaxMsSpreadSheetColumn | TableHeader): boolean {
    return !(
      this.spreadsheetHelperUtil.getTableOptions()?.editable &&
      (column as any)?.editable &&
      this.spreadsheetHelperUtil.getTableOptions().celleditable &&
      !this.editableModeLocked
    );
  }

  /**
   * handle the mapping of the columns from the backend
   * @param tableColumns raw information of the columns
   */
  public generateColumns(tableColumns: any[]): void {
    this.columns = [];
    this.buildColumns(tableColumns, 0);
    this.columns = this.sortColumnsByIndex().slice();
  }

  /**
   * create the nested column headers for the table
   * @param tableColumns raw information of the columns
   */
  public buildNestedColumns(tableColumns: any[]): any[][] {
    const nestedColumnHeaders: DetailedSettings[] = [];
    const nestedColumnHeaderMap: Map<string, DetailedSettings> = new Map();

    for (const column of tableColumns) {
      const nestedColumnHeader = column.nestedTableHeader;
      if (!nestedColumnHeader) {
        continue;
      }

      if (nestedColumnHeaderMap.has(nestedColumnHeader.uuid)) {
        const value = nestedColumnHeaderMap.get(nestedColumnHeader.uuid);
        value.colspan++;
        nestedColumnHeaderMap.set(nestedColumnHeader.uuid, value);
      } else {
        const value: DetailedSettings = {
          label: nestedColumnHeader.value,
          colspan: 1,
        };
        nestedColumnHeaderMap.set(nestedColumnHeader.uuid, value);
      }
    }

    nestedColumnHeaderMap.forEach((value, key) => {
      nestedColumnHeaders.push(value);
    });

    if (nestedColumnHeaders.length > 0) {
      this.nestesColumnHeaders = [nestedColumnHeaders as any];
      // nestedColumnHeaders.map((nestedColumnHeader) => {
      //   return [nestedColumnHeader];
      // });
      this.nestesColumnHeaders.push(
        tableColumns.map((column) => {
          return { label: column.value, colspan: 1 };
        }) as any
      );
    }
    return this.nestesColumnHeaders;
  }

  /**
   * search a column by passed index
   * @param index column index for search
   * @param visual decides whether to search for the visual or the real index
   * @returns column
   */
  public getColumnByIndex(index: number, visual: boolean): SaxMsSpreadSheetColumn {
    return this.getColumns().find((col) => (visual ? col.data : col.filterSortObject.getColumnIndex()) === index);
  }

  /**
   * search a column by passed id
   * @param headeId id for search
   * @returns column
   */
  public getColumnById(headeId: string): SaxMsSpreadSheetColumn {
    return this.getColumns().find((col) => col.id === headeId);
  }

  /**
   * @returns the columns of the table
   */
  public getColumns(): SaxMsSpreadSheetColumn[] {
    return this.columns;
  }

  /**
   * @returns the nested column header of the table
   */
  public getNestedColumns(): DetailedSettings[][] {
    return this.nestesColumnHeaders;
  }

  /**
   * update the state of the lock state of the editmode
   * @param editableModeLocked state of the lock state of the editmode
   */
  public setEditableModeLocked(editableModeLocked: boolean): void {
    this.editableModeLocked = editableModeLocked;
  }

  /**
   * update the spreadsheet setting
   * @param spreadsheetsettings spreadsheetsettings
   */
  public setSpreadsheetsettings(spreadsheetsettings: ISpreadsheetSaveSettings): void {
    this.spreadsheetsettings = spreadsheetsettings;
  }
}
