import { IGanttBlock } from 'frontend/src/dashboard/gantt/general/generator/gantt-input.data';
import { GanttResponse } from 'frontend/src/dashboard/gantt/general/response/responses/response';
import { IBlockConnectionData } from '../block-connections';

/**
 * Handles the backend response to update block connections.
 */
export class GanttResponseBlockConnection extends GanttResponse {
  public handleResponse(response: any): boolean {
    let handledResponse = false;

    // delete connections to deleted blocks
    if (response.deletedBlocks) {
      const blocks: IGanttBlock[] = response.deletedBlocks;
      for (const block of blocks) {
        this.executer.removeEdgeByObjectId(block.id);
      }
      handledResponse = true;
    }

    // handle edge connection adding
    if (response && response.hasOwnProperty('addedConnections') && Array.isArray(response.addedConnections)) {
      const newConnections: IBlockConnectionData[] = response.addedConnections;

      for (const connection of newConnections) {
        this.executer.createEdgeByIds(
          connection.fromBlockID,
          connection.toBlockID,
          connection.color || '#000000',
          connection.fromLabel,
          connection.toLabel,
          connection.fromType,
          connection.toType,
          connection.style,
          connection.visualizationMode
        );
      }
      handledResponse = true;
    }

    return handledResponse;
  }
}
