import { AfterViewInit, Component, Inject, ViewChild, ViewContainerRef } from '@angular/core';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { AbstractGridService } from '../moving-grid/abstract-grid.service';
import { WIDGET_OVERLAY_DATA } from './popup.tokens';
import { PopUpOverlayRef } from './popupOverlayRef';

@Component({
  selector: 'popup-overlay',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss'],
})
export class PopupComponent implements AfterViewInit {
  internalService: AbstractGridService;
  public closeButton = false;
  @ViewChild('popupContent', { read: ViewContainerRef }) viewContainerRef: ViewContainerRef;

  constructor(public dialogRef: PopUpOverlayRef, @Inject(WIDGET_OVERLAY_DATA) public widget: any) {}

  ngAfterViewInit(): void {
    // this.viewContainerRef.clear();
    of(null)
      .pipe(delay(0))
      .subscribe(() => {
        this.viewContainerRef.clear();

        if (this.widget.hasOwnProperty('closeButton')) {
          this.closeButton = this.widget.closeButton;
        }

        if (this.widget.componentRef !== undefined && this.viewContainerRef) {
          this.viewContainerRef.insert(this.widget.componentRef.hostView);
        }
      });
  }

  public onChangeColor(color: any) {
    if (this.widget.callback) {
      this.widget.callback(color.value);
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  public onClose(success: boolean) {
    if (this.widget.onClose) {
      this.widget.onClose(success);
    }
  }
}
