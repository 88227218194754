import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import {
  Component,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { DateUtils } from '@app-modeleditor/utils/date-utils';
import { TemplateService } from '@app-modeleditor/utils/template.service';
import { TranslateService } from '@ngx-translate/core';
import { of, Subject } from 'rxjs';
import { delay, takeUntil, takeWhile } from 'rxjs/operators';
import { EEntryElementEvents } from '../entry-collection/entry-element-events.enum';
import { EFieldType } from '../entry-collection/field-type.enum';
import { ALink } from '../entry-collection/link/link';
import { StartAndEndTimeLink } from '../entry-collection/link/start-and-end-time-link';
import { AEntryElement } from '../entry-element/a-entry-element';
import { IResult, IWeek, OverlayComponent } from './overlay/overlay.component';
import { CONTAINER_DATA } from './picker';
import { TemplatePicker } from './template-picker';
import { TemplatePickerAdapter } from './template-picker-factory.service';
import { TemplatePickerValue } from './template-picker-value';
@Component({
  selector: 'app-template-picker',
  templateUrl: './template-picker.component.html',
  styleUrls: ['./template-picker.component.scss'],
  providers: [],
})
export class TemplatePickerComponent extends AEntryElement implements OnInit, OnChanges, OnDestroy {
  links?: ALink[];

  _afterLinksLoaded<T extends ALink>(links: T[]): void {
    this.links = links;
    this.entryElement.removeEventListener(this.getComponentId(), EEntryElementEvents.VALUE_CHANGED);
    this.entryElement.addEventListener(this.getComponentId(), EEntryElementEvents.VALUE_CHANGED, (ev: CustomEvent) =>
      this.init(false)
    );
  }

  @Input() entryElement: TemplatePicker;
  /*
  @Input() week: number;
  @Input() month: number;
  @Input() year: number;
  */
  @Input() placeholder: string;
  @Output() changeEvent: EventEmitter<IResult> = new EventEmitter<IResult>();
  @ViewChild('ref') ref: ElementRef;
  overlayRef: OverlayRef;
  private alive = true;
  displayedValue: string;
  private inputTimeout: Subject<void> = new Subject<void>();
  hint: IWeek;

  constructor(
    private overlay: Overlay,
    private injector: Injector,
    private translate: TranslateService,
    private pickerFactory: TemplatePickerAdapter,
    templateApi: TemplateService,
    zone: NgZone
  ) {
    super(templateApi, zone);
  }

  ngOnDestroy(): void {
    this.alive = false;
    super.ngOnDestroy();
    this.inputTimeout.next();
    this.inputTimeout.complete();
    this.entryElement.removeEventListener(this.getComponentId(), EEntryElementEvents.VALUE_CHANGED);
  }

  ngOnInit(): void {
    this.init(true);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.el) {
      this.init(true);
    }
  }

  private init(skipEmit = true, checkLinks = true) {
    if (this.entryElement && this.entryElement.getValue<TemplatePickerValue>()) {
      switch (this.entryElement.getFieldType()) {
        case EFieldType.CALENDAR_WEEK_PICKER:
          this.onInputChanged(this.entryElement.getValue<TemplatePickerValue>().getValue(), skipEmit, checkLinks);
          this.displayedValue = this.entryElement.isUseMillisForCalendarWeek()
            ? this.entryElement.getValue<TemplatePickerValue>().getValue()
              ? DateUtils.getNumberOfWeek(
                  new Date(this.entryElement.getValue<TemplatePickerValue>().getValue())
                )?.toString()
              : undefined
            : this.entryElement.getValue<TemplatePickerValue>().getValue()?.toString();
          break;
        case EFieldType.CALENDAR_MONTH_PICKER:
          if (this.entryElement.getValue<TemplatePickerValue>().getValue() instanceof Date) {
            const m: Date = this.entryElement.getValue<TemplatePickerValue>().getValue();
            this.entryElement.getValue<TemplatePickerValue>().setYear(m.getFullYear());
            this.entryElement.getValue<TemplatePickerValue>().setMonth(m.getMonth());
          }
          this.displayedValue = `${
            this.translate.instant('DATES.monthNames')[this.entryElement.getValue<TemplatePickerValue>().getMonth()] ||
            ''
          } ${this.entryElement.getValue<TemplatePickerValue>().getYear() || ''}`;
          this.onInputChanged(this.displayedValue, skipEmit, checkLinks);
          break;
        case EFieldType.CALENDAR_YEAR_PICKER:
          this.onInputChanged(this.entryElement.getValue<TemplatePickerValue>().getValue(), skipEmit, checkLinks);
          this.displayedValue = this.entryElement.getValue<TemplatePickerValue>().getValue().toString();
          break;
      }
    }
  }

  close(): void {
    this.overlayRef.dispose();
  }

  open(): void {
    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.ref)
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        },
      ]);

    const overlayRef: OverlayRef = this.overlay.create({
      hasBackdrop: true,
      positionStrategy,
      backdropClass: 'custom-backdrop',
    });

    const overlayPortal: ComponentPortal<OverlayComponent> = new ComponentPortal(
      OverlayComponent,
      null,
      this.createInjector({
        year: this.entryElement.getValue<TemplatePickerValue>().getYear(true),
        month: this.entryElement.getValue<TemplatePickerValue>().getMonth(),
        week: this.entryElement.getValue<TemplatePickerValue>().getWeek(),
        type: this.entryElement.getFieldType(),
      })
    );

    const cmpRef = overlayRef.attach(overlayPortal);
    cmpRef.instance.changeEvent.pipe(takeWhile(() => this.alive)).subscribe((outputs: IResult) => {
      this.hint = null;
      if (!outputs) {
        return;
      }
      if (!isNaN(outputs.week.number)) {
        this.entryElement.getValue<TemplatePickerValue>().setWeek(outputs.week.number);
      }
      if (!isNaN(outputs.selectedYear)) {
        this.entryElement.getValue<TemplatePickerValue>().setYear(outputs.selectedYear);
      }
      if (!isNaN(outputs.selectedMonth)) {
        this.entryElement.getValue<TemplatePickerValue>().setMonth(outputs.selectedMonth);
      }

      if (this.entryElement.getFieldType() === EFieldType.CALENDAR_WEEK_PICKER) {
        this.displayedValue = `${outputs.week.number}`;
        this.hint = outputs.week;
      } else if (this.entryElement.getFieldType() === EFieldType.CALENDAR_YEAR_PICKER) {
        this.displayedValue = `${outputs.selectedYear}`;
      } else if (this.entryElement.getFieldType() === EFieldType.CALENDAR_MONTH_PICKER) {
        this.displayedValue = `${this.translate.instant('DATES.monthNames')[outputs.selectedMonth]} ${
          outputs.selectedYear
        }`;
      }

      this.change(outputs);
    });

    this.overlayRef = overlayRef;

    this.overlayRef.backdropClick().subscribe(() => {
      this.overlayRef.dispose();
    });
  }

  getFirstDay(_date: Date): Date {
    const date: Date = new Date(_date.getTime());
    const day: number = date.getDay();
    return new Date(date.setDate(date.getDate() - (day - 1)));
  }

  afterChanged(value: string) {
    if (
      EFieldType.CALENDAR_WEEK_PICKER === this.entryElement.getFieldType() &&
      this.entryElement.isUseMillisForCalendarWeek()
    ) {
      const v = this.entryElement.getValue<TemplatePickerValue>();
      value = DateUtils.getDateByWeek(parseInt(value), v.getYear()).start.getTime().toString();
    }

    this.onInputChanged(value, false, true);
  }

  afterKeyUp(event: Record<string, any>): void {
    let value: string = event.target['value'];
    if (
      EFieldType.CALENDAR_WEEK_PICKER === this.entryElement.getFieldType() &&
      this.entryElement.isUseMillisForCalendarWeek()
    ) {
      const v = this.entryElement.getValue<TemplatePickerValue>();
      value = DateUtils.getDateByWeek(parseInt(value), v.getYear()).start.getTime().toString();
    }

    // this.onInputChanged(value, false, true);
  }

  onInputChanged(value: string, skipEmit = false, checkLinks = true): void {
    if (this.overlayRef && !isNaN(+value)) {
      this.overlayRef.dispose();
    }
    this.inputTimeout.next();

    if (isNaN(+value)) return;

    of(null)
      .pipe(delay(350), takeUntil(this.inputTimeout))
      .subscribe(() => {
        if (
          EFieldType.CALENDAR_WEEK_PICKER !== this.entryElement.getFieldType() ||
          this.entryElement.isUseMillisForCalendarWeek()
        ) {
          this.entryElement
            .getValue<TemplatePickerValue>()
            .setYear(
              new Date(this.entryElement.getValue<TemplatePickerValue>().getValue()).getFullYear() ||
                new Date().getFullYear()
            );
          this.entryElement
            .getValue<TemplatePickerValue>()
            .setMonth(
              new Date(this.entryElement.getValue<TemplatePickerValue>().getValue()).getMonth() || new Date().getMonth()
            );
        } else {
          this.entryElement
            .getValue<TemplatePickerValue>()
            .setYear(this.entryElement.getValue<TemplatePickerValue>().getYear() || new Date().getFullYear());
          this.entryElement
            .getValue<TemplatePickerValue>()
            .setMonth(this.entryElement.getValue<TemplatePickerValue>().getMonth() || new Date().getMonth());
        }

        switch (this.entryElement.getFieldType()) {
          case EFieldType.CALENDAR_WEEK_PICKER:
            const timestamp: number = typeof value === 'string' ? parseInt(value) : value;
            const week: number = this.entryElement.isUseMillisForCalendarWeek()
              ? DateUtils.getNumberOfWeek(new Date(timestamp))
              : timestamp;
            const year: number = this.entryElement.getValue<TemplatePickerValue>().getYear();
            this.entryElement.getValue<TemplatePickerValue>().setWeek(week);
            this.entryElement
              .getValue<TemplatePickerValue>()
              .setMonth(
                DateUtils.getDateByWeek(
                  this.entryElement.getValue<TemplatePickerValue>().getWeek(),
                  year
                ).start.getMonth()
              );

            const { start, end } = DateUtils.getDateByWeek(week, year || new Date().getFullYear());
            this.hint = {
              number: week,
              start: week ? start : null,
              end: week ? end : null,
            };

            break;
          case EFieldType.CALENDAR_YEAR_PICKER:
            this.entryElement.getValue<TemplatePickerValue>().setYear(parseInt(value));
            break;
          case EFieldType.CALENDAR_MONTH_PICKER:
            if (value) {
              const a: string[] = value.split(' ');
              this.entryElement
                .getValue<TemplatePickerValue>()
                .setYear(
                  a[1] && a[1] !== '' ? parseInt(a[1]) : this.entryElement.getValue<TemplatePickerValue>().getYear()
                );
              this.entryElement
                .getValue<TemplatePickerValue>()
                .setMonth(
                  a[0] && a[0] !== ''
                    ? this.translate.instant('DATES.monthNames').findIndex((n: string) => a[0] === n)
                    : this.entryElement.getValue<TemplatePickerValue>().getMonth()
                );
            }
            break;
        }
        // const date = new Date(this.el.getValue<TemplatePickerValue>().getYear(), this.el.getValue<TemplatePickerValue>().getMonth());
        const { start, end } = DateUtils.getDateByWeek(
          this.entryElement.getValue<TemplatePickerValue>().getWeek(),
          this.entryElement.getValue<TemplatePickerValue>().getYear()
        );
        const w: IWeek = { number: this.entryElement.getValue<TemplatePickerValue>().getWeek(), start, end };

        const out: IResult = {
          selectedYear: this.entryElement.getValue<TemplatePickerValue>().getYear(),
          selectedMonth: this.entryElement.getValue<TemplatePickerValue>().getMonth(),
          week: w,
        };

        // this.open();
        if (skipEmit === false) {
          this.change(out, checkLinks);
        }
      });
  }

  change(data, checkLinks = true): void {
    this.changeEvent.emit(data);

    if (!checkLinks) {
      return;
    }

    this.links?.forEach((l) => {
      const v = data.week
        ? new Date(data.week.start).getTime()
        : new Date(data.selectedYear, data.selectedMonth).getTime();
      if (l instanceof StartAndEndTimeLink) {
        if (l.getStartId() === this.entryElement.getId()) {
          // i am start
          const el = this.templateApi.getElementById<TemplatePicker>(l.getEndId());
          const value = el.getValue<TemplatePickerValue>();
          if (value.getValue<number>() < v) {
            el.setValue(value.setValue(v));
          }
        } else if (l.getEndId() === this.entryElement.getId()) {
          const el = this.templateApi.getElementById<TemplatePicker>(l.getStartId());
          const value = el.getValue<TemplatePickerValue>();
          if (value.getValue<number>() > v) {
            el.setValue(value.setValue(v));
          }
        }
      }
    });
  }

  createInjector(dataToPass: Record<string, any>): PortalInjector {
    const injectorTokens = new WeakMap();
    injectorTokens.set(CONTAINER_DATA, dataToPass);
    return new PortalInjector(this.injector, injectorTokens);
  }
}
