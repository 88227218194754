import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { ColorButtonComponent } from './color-button.component';

@NgModule({
  declarations: [ColorButtonComponent],
  exports: [ColorButtonComponent],
  imports: [CommonModule, MatButtonModule, TranslateModule, MatIconModule],
})
export class ColorButtonModule {}
