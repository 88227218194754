<div *ngIf="isLoading" class="spinnerWrapper">
  <div class="spinner"><mat-spinner [diameter]="40"></mat-spinner></div>
</div>

<ng-container *ngIf="!isLoading">
  <p>{{ 'GANTT.SETTINGS.TRANSFER_SETTINGS_INFO' | translate }}</p>

  <section>
    <div class="options">
      <span>
        <mat-checkbox [checked]="allComplete" [indeterminate]="someComplete()" (change)="setAll($event.checked)">
          {{ options.name }}
        </mat-checkbox>
      </span>
      <span>
        <ul>
          <li *ngFor="let option of options.options">
            <mat-checkbox class="optionElement" [(ngModel)]="option.completed" (ngModelChange)="updateAllComplete()">
              {{ option.name }}
            </mat-checkbox>
          </li>
        </ul>
      </span>
    </div>
  </section>
</ng-container>
