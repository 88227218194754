import { NodeNetworkEditService } from '@app-nodenetwork/service/node-network.edit.service';
import { Injectable } from '@angular/core';
import {
  StepUpdateItem,
  ENodeNetworkStepUpdateType,
  Edge,
} from '@app-nodenetwork/edit/node-network-server-save/new-objects';

/**
 * Save handler which informs backend about data changes.
 */
@Injectable()
export class NodeNetworkSaveToServer {
  constructor(public nodeNetworkEditService: NodeNetworkEditService) {}

  /**
   * Creates data item to describe new node.
   * Look at 'NodeNetworkEditDialog' for more information.
   * @param id Node id.
   * @param label Node label.
   * @param processName Process name.
   * @param stepClazz Selected step class.
   * @param processClazz Prozess class.
   * @param hierarchicalProcessName If the new node is hierarchical, add the name for hierarchical process.
   */
  private createNewStepNode(
    id: string,
    label: string,
    processName: string,
    stepClazz: string,
    processClazz: string,
    hierarchicalProcessName?: string
  ): StepUpdateItem {
    const newNode: StepUpdateItem = new StepUpdateItem();
    newNode.objectId = id;
    newNode.processClazz = processClazz;
    newNode.processName = processName;
    newNode.stepClazz = stepClazz;
    newNode.stepName = label;
    newNode.type = ENodeNetworkStepUpdateType.ADD_STEP;

    if (hierarchicalProcessName) newNode.hierarchicalProcessName = hierarchicalProcessName;
    return newNode;
  }

  /**
   * Creates delete action to remove given node.
   * @param id Id of node to delete.
   */
  private removeStepNode(id: string): StepUpdateItem {
    const removedNode: StepUpdateItem = new StepUpdateItem();
    removedNode.objectId = id;
    removedNode.type = ENodeNetworkStepUpdateType.DELETE_STEP;
    return removedNode;
  }

  /**
   * Creates data to edit a connection.
   * @param idFrom Start node id.
   * @param idTo End node id.
   * @param type Edit type of action.
   */
  private editNewEdgeStep(idFrom: string, idTo: string, type: ENodeNetworkStepUpdateType): StepUpdateItem {
    const newConneciton: StepUpdateItem = new StepUpdateItem();
    newConneciton.type = type;
    const edge: Edge = new Edge();
    edge.idFrom = idFrom;
    edge.idTo = idTo;
    newConneciton.edge = edge;

    return newConneciton;
  }

  /**
   * Extracts edit data by given data and send it to backend.
   * @param viewType View type of node network. Has to be "Workflow" to make save possible.
   * @param viewIds Id of view to save as Array.
   * @param data Edit data whcih includes edit type, data and timestamp.
   */
  public sendData(
    viewType: string,
    viewIds: string[],
    data: { data: any; editType: string; time: Date }[]
  ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const stepItemList: any[] = [];

      for (const editStep of data) {
        let item: any;
        let itemData: any;
        if (viewType == 'Workflow') {
          switch (editStep.editType) {
            case 'REMOVE_NODE':
              item = this.removeStepNode(editStep.data.id);
              break;
            case 'REMOVE_CONNECTION':
              itemData = editStep.data.info;
              item = this.editNewEdgeStep(
                itemData.startNodeId,
                itemData.endNodeId,
                ENodeNetworkStepUpdateType.REMOVE_EDGE
              );
              break;
            case 'ADD_NODE':
              itemData = editStep.data;
              item = this.createNewStepNode(
                itemData.id,
                itemData.label,
                itemData.additionalData.editProcessName,
                itemData.additionalData.editNodeType,
                itemData.additionalData.editProcessType,
                itemData.additionalData.editHierarchicalProcessName
              );
              break;
            case 'ADD_CONNECTION':
              itemData = editStep.data.info;
              item = this.editNewEdgeStep(
                itemData.startNodeId,
                itemData.endNodeId,
                ENodeNetworkStepUpdateType.ADD_EDGE
              );
              break;
          }
        }
        stepItemList.push(item);
      }

      if (viewType == 'Workflow') {
        this.nodeNetworkEditService.postWorkflow(viewIds[0], stepItemList).subscribe(
          (resp) => {
            resolve(null);
          },
          (err) => {
            console.error('error while trying to save node network');
            reject(err);
          }
        );
      }
    });
  }
}
