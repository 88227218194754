import { ToHexColorConverter } from '../../color/color-converter/to-hex-converter';
import { GanttCanvasShift, GanttDataShift } from '../data-structure/data-structure';

/**
 * Mapper class for shift data.
 * @keywords shift, creation, creator, mapper, data, canvas, helper
 * @class
 * @constructor
 * @static
 */
export class GanttShiftDataMapper {
  private constructor() {}

  /**
   * Maps origin shift data to canvas data.
   * @keywords shift, creation, creator, mapper, data, canvas, origin
   * @param {GanttDataShift} originShift Shift origin data.
   * @param {number} shiftHeight Height of shift.
   * @param {number} paddingTop Difference between vertical row and shift position.
   * @param {scale} d3XScale D3 scale of x axis.
   * @param {number} y Vertical position of shift.
   * @param {number} depth Depth level inside origin dataset.
   * @return {GanttCanvasShift}
   */
  static mapOriginToCanvas(
    originShift: GanttDataShift,
    shiftHeight: number,
    d3XScale: d3.ScaleTime<number, number>,
    y: number,
    depth: number
  ): GanttCanvasShift {
    const startScale = d3XScale(originShift.timePointStart);
    const endScale = d3XScale(originShift.timePointEnd);

    const newShift: GanttCanvasShift = {
      id: originShift.id,
      x: startScale,
      y: y,
      width: endScale - startScale,
      height: shiftHeight,
      name: originShift.name,
      layer: depth,
      color: ToHexColorConverter.convertColorToHex(originShift.color),
      highlighted: ToHexColorConverter.convertColorToHex(originShift.highlighted),
      selected: ToHexColorConverter.convertColorToHex(originShift.selected),
      symbols: originShift.symbols,
      noRender: originShift.noRender ? originShift.noRender.slice() : [],
      strokeColor: ToHexColorConverter.convertColorToHex(originShift.strokeColor),
      strokePattern: originShift.strokePattern,
      pattern: originShift.pattern,
      patternColor: ToHexColorConverter.convertColorToHex(originShift.patternColor),
      entryTypes: originShift.entryTypes,
      blockTypes: originShift.blockTypes,
      modificationRestriction: originShift.modificationRestriction,
      opacity: originShift.opacity,
      weaken: originShift.weaken,
      disableMove: originShift.disableMove,
      firstColor: originShift.firstColor,
      secondColor: originShift.secondColor,
      strokeWidth: originShift.strokeWidth,
      editable: originShift.editable,
      isFullHeight: originShift.isFullHeight,
      noRoundedCorners: originShift.noRoundedCorners,
      tooltip: null,
      renderPriority: originShift.linkedParentBlock ? 3 : 2,
    };
    return newShift;
  }
}
