<div
  class="color-slider"
  [ngStyle]="{
    'background-image': colorScheme?.getStyle() === 'linear' ? colorScheme?.getLinearColorString() : 'none',
    display: colorScheme?.getStyle() === 'explicit' ? 'none' : 'block'
  }"
>
  <as-split #colorSpiltElement useTransition="true" gutterSize="2" unit="percent" (dragEnd)="onHandleDragEnd($event)">
    <as-split-area
      *ngFor="let colorValue of colorScheme?.getColoredValues(); let i = index"
      [size]="getColorAreaSize(colorValue, i)"
      [order]="i"
    >
      <div
        class="color-slider-area"
        (contextmenu)="openColorPicker($event, colorValue)"
        [ngStyle]="{ background: colorScheme?.getStyle() === 'linear' ? 'transparent' : colorValue.getColor() }"
      ></div>
    </as-split-area>
  </as-split>
  <span
    class="color-area-value-label"
    *ngFor="let colorValue of colorScheme?.getColoredValues()"
    [ngStyle]="{ right: '100' - (colorValue?.getValue() / colorScheme?.getMaxValue()) * 100 + '%' }"
  >
    {{ colorValue.getValue() }}</span
  >
</div>

<div *ngIf="colorScheme?.getStyle() === 'explicit'" class="color-list">
  <mat-list>
    <mat-list-item *ngFor="let colorValue of colorScheme?.getColoredValues(); let i = index">
      <div
        class="color-bubble"
        (contextmenu)="openColorPicker($event, colorValue)"
        [ngStyle]="{ background: colorValue.getColor() }"
      ></div>
      <mat-form-field class="color-list-item-field">
        <input
          matInput
          [disabled]="colorScheme.checkStringValue()"
          [value]="colorValue.getValue()"
          (change)="onExplictValueChange($event, colorValue)"
        />
      </mat-form-field>
    </mat-list-item>
  </mat-list>
</div>
