import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { TemplateBackdropComponent } from './template-backdrop.component';

@Injectable({
  providedIn: 'root',
})
export class TemplateBackdropService {
  private _backdropRef: MatDialogRef<TemplateBackdropComponent>[] = [];

  constructor(private dialog: MatDialog) {}

  /**
   * show backdrop
   */
  public showBackdrop() {
    this.closeAll();
    const d: MatDialogRef<TemplateBackdropComponent> = this.dialog.open(TemplateBackdropComponent);
    return d;
  }

  closeAll() {
    this._backdropRef.forEach((ref: MatDialogRef<TemplateBackdropComponent>) => ref.close());
    this._backdropRef = [];
  }

  /**
   * hide backdrop
   */
  public hideBackdrop(id?: string) {
    this.closeAll();
  }
}
