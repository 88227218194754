import * as d3 from 'd3';
import { Observable, Subject } from 'rxjs';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';
import { GanttOverlayService } from '../../services/overlay-service/overlay.service';
import { GanttCallBackStackExecuter } from './callback-tools/callback-stack-executer';
import { GanttConfig } from './config/gantt-config';
import { ShiftDataFinder } from './data-handler/data-finder/shift-data-finder';
import { DataHandler } from './data-handler/data-handler';
import { GanttCanvasShift, GanttDataContainer, GanttDataShift } from './data-handler/data-structure/data-structure';
import { DataManipulator } from './data-handler/data-tools/data-manipulator';
import { GanttShiftEditLimiter } from './edit-shifts/shift-edit-general/edit-restrictions/shift-edit-limiter';
import { GanttShiftResizer } from './edit-shifts/shift-resizing/shift-resizer';
import { GanttShiftTranslationChain } from './edit-shifts/shift-translation/shift-translation-chain';
import { GanttShiftTranslator } from './edit-shifts/shift-translation/shift-translator';
import { FilterByColor } from './filter/filter-by-color';
import { GanttUtilities } from './gantt-utilities/gantt-utilities';
import { IBestGanttAPI } from './ganttAPI';
import { GanttHistory } from './history/gantt-history';
import { GanttHTMLStructureBuilder } from './html-structure/html-structure-builder';
import { NodeProportionsState } from './html-structure/node-proportion-state/node-proportion-state';
import { INodeProportion } from './html-structure/node-proportion-state/node-proportion.interface';
import { EGanttScrollContainer } from './html-structure/scroll-container.enum';
import { GanttScrollContainerSizeHandler } from './html-structure/size-handler/scroll-container-size-handler';
import { MainConfigChangeCallbacks } from './main/callback-registration/config-change-callbacks';
import { MainSelectionBoxCallbacks } from './main/callback-registration/selection-box-callbacks';
import { MainShiftCallbacks } from './main/callback-registration/shift-callbacks';
import { MainXAxisCallbacks } from './main/callback-registration/x-axis-callbacks';
import { MainYAxisCallbacks } from './main/callback-registration/y-axis-callbacks';
import { GanttSelectionBoxHandler } from './main/selection-box/selection-box-handler';
import { GanttYAxisHandler } from './main/y-axis/y-axis-handler';
import { PanningHandler } from './panning/panning-handler';
import { EPatternEventSource } from './pattern/events/pattern-event-source.enum';
import { BestGanttPluginHandler } from './plug-in-handler/plug-in-handler';
import { BestGanttPlugIn } from './plug-ins/gantt-plug-in';
import {
  GanttRenderDataSetShifts,
  GanttRenderDataSetYAxis,
} from './render-data-handler/data-structure/render-data-structure';
import { RenderDataHandler } from './render-data-handler/render-data-handler';
import { GanttAnimator } from './render/animator';
import { GanttElementSelectorFacade } from './selector/element-selector-facade';
import { GlobalTimeSpanMarker } from './shifts/global-timespan-marker';
import { GanttShiftBuilderFacade } from './shifts/shiftbuilder-facade';
import { TextOverlay } from './shifts/text-overlay';
import { ETimeGradationTimeGrid } from './time-gradation-handler/time-gradation-handler';
import { TooltipBuilder } from './tooltip/tooltip-builder';
import { GanttTrash } from './trash/gantt-trash';
import { VerticalScrollHandler } from './vertical-scroll/vertical-scroll-handler';
import { GanttYAxisHeadButtonData } from './x-axis/head-y-axis/buttons/head-y-axis-button';
import { GanttYAxisHeadRowSearchButtonData } from './x-axis/head-y-axis/buttons/head-y-axis-row-search-button';
import { GanttYAxisHeadSortRowsButtonData } from './x-axis/head-y-axis/buttons/head-y-axis-sort-rows-button';
import { GanttYAxisHead } from './x-axis/head-y-axis/head-y-axis';
import { ETimeMarkerAnchor, GanttXAxis, MarkerItem } from './x-axis/x-axis';
import { GanttOpenRowHandler } from './y-axis/open-row-handler';
import { GanttYAxisFacade } from './y-axis/y-axis-facade';

/**
 * Main execution class for Gantt Diagram.
 * Handles callback functions and combined functionality from different gantt components.
 * Influenced by idea of mediator pattern.
 * @keywords ganttdiagram, main, facade, executer, callback
 */
export class BestGantt implements IBestGanttAPI {
  private _isAlive = false;

  private _htmlStructureBuilder: GanttHTMLStructureBuilder = undefined;
  private _nodeProportionsState: NodeProportionsState;

  private _dataHandler: DataHandler = undefined;
  private _renderDataHandler: RenderDataHandler = undefined;

  private _yAxisFacade: GanttYAxisFacade = undefined;
  private _openRowHandler: GanttOpenRowHandler = undefined;

  private _selectionBoxFacade: GanttElementSelectorFacade = undefined;
  private _selectionBoxHandler: GanttSelectionBoxHandler = undefined;

  private _shiftEditLimiter: GanttShiftEditLimiter = undefined;
  private _shiftResizer: GanttShiftResizer = undefined;
  private _shiftTranslator: GanttShiftTranslator = undefined;
  private _chainHandler = new GanttShiftTranslationChain();

  creationStamp: number;
  plugInHandler: BestGanttPluginHandler;
  verticalScrollHandler: VerticalScrollHandler;
  shiftFacade: GanttShiftBuilderFacade;
  xAxisBuilder: GanttXAxis;
  yAxisHeadBuilder: GanttYAxisHead;
  panningHandler: PanningHandler;
  trash: GanttTrash;
  globalTimeSpanMarker: GlobalTimeSpanMarker;
  colorFilter: FilterByColor;
  ganttHistory: GanttHistory;
  eventLogging: boolean;
  parentNode: HTMLElement;
  noRenderId: string;
  config: GanttConfig;
  toolTipBuilder: TooltipBuilder;
  removedGanttHeight: number;
  timeOuts: any;
  callbacks: any;
  private _allowClicks: boolean;
  private _overlayApi: GanttOverlayService;

  private _onDestroySubject: Subject<void> = new Subject<void>();

  constructor(
    parentNode: HTMLElement,
    overlayApi: GanttOverlayService,
    nodeProportionsState: NodeProportionsState,
    eventLogging = true
  ) {
    this.creationStamp = new Date().getTime();
    this._nodeProportionsState = nodeProportionsState;
    this.plugInHandler = new BestGanttPluginHandler();
    this.verticalScrollHandler = new VerticalScrollHandler(this);

    this.shiftFacade = null;
    this.xAxisBuilder = null;
    this.yAxisHeadBuilder = null;
    this.panningHandler = null;
    this.trash = null;
    this.globalTimeSpanMarker = null;
    this.colorFilter = null;
    this.ganttHistory = null;
    this.eventLogging = eventLogging;
    this.parentNode = parentNode;
    this.noRenderId = GanttUtilities.generateUniqueID();

    this.config = new GanttConfig();

    this.toolTipBuilder = new TooltipBuilder(parentNode, this.config);
    this.removedGanttHeight = 0; // info: necessary for callback function
    // all timeouts
    this.timeOuts = {
      markDueDate: null,
    };

    this.callbacks = {
      afterInit: {},
      selectShiftsByRightClick: {},
      afterOriginDataUpdate: {},
      mouseLeaveShiftContainer: {},
    };

    this._overlayApi = overlayApi;
  }

  public build(dataSet: GanttDataContainer, parentNode: HTMLElement = this.parentNode): void {
    this._isAlive = true;

    if (parentNode) this.parentNode = parentNode;
    this.ganttHistory = new GanttHistory(this);

    // set time format
    d3.timeFormatDefaultLocale(this.config.timeFormat());

    this._htmlStructureBuilder?.destroy();
    this._htmlStructureBuilder = new GanttHTMLStructureBuilder(this);
    this._htmlStructureBuilder.init(
      this.parentNode,
      [this.updateYAxis.bind(this, false)],
      [this.update.bind(this), this.updateYAxis.bind(this)]
    );

    // init render data handler
    this._renderDataHandler = new RenderDataHandler(this);
    this._renderDataHandler.init();

    // init open row handler
    this._openRowHandler = new GanttOpenRowHandler(this);
    this._openRowHandler.addOpenRowAbility();

    const xAxisWidth = this.getHTMLStructureBuilder()
      .getXAxisContainer()
      .select<HTMLDivElement>('.gantt_x-axis')
      .node().clientWidth;
    const scale = d3
      .scaleTime()
      .range([0, xAxisWidth])
      .domain([new Date(dataSet.minValue), new Date(dataSet.maxValue)]);

    this._dataHandler = new DataHandler(dataSet, scale, this.config);
    this.initCanvasData();

    // initialize shift edit limiter
    this._shiftEditLimiter = new GanttShiftEditLimiter(this);

    // init all classes which are responsible for the GUI
    this._initGUI(dataSet, this.getRenderDataHandler().getYAxisDataFinder().getRowHeightsCombined());
    this._shiftEditLimiter.initTimeGradationHandler();

    this._initialRendering();

    // initialize shift resizer
    this._shiftResizer = new GanttShiftResizer(this);
    this._shiftResizer.build();

    // initialize shift translator
    this._shiftTranslator = new GanttShiftTranslator(this);
    this._shiftTranslator.build(
      this.getDataHandler().getOriginDataset().minStepWidth,
      this.getDataHandler().getOriginDataset().gridRef
    );

    this._selectionBoxFacade = new GanttElementSelectorFacade(this);
    this.trash = new GanttTrash();

    // init core callbacks
    new MainShiftCallbacks(this).initCallbacks();
    new MainXAxisCallbacks(this, this.getXAxisBuilder()).initCallbacks();
    new MainYAxisCallbacks(this).initCallbacks();
    this._selectionBoxHandler = new GanttSelectionBoxHandler(this);
    new MainSelectionBoxCallbacks(this, this._selectionBoxHandler).initCallbacks();
    new MainConfigChangeCallbacks(this, this.getConfig()).initCallbacks();

    this.getSelectionBoxFacade().init();
    this.panningHandler = new PanningHandler(
      this.getSelectionBoxFacade().getSelectionBoxStartGroup(),
      this.getXAxisBuilder(),
      this.getVerticalScrollHandler(),
      this.getNodeProportionsState(),
      this.getShiftFacade().getShiftBuilder()
    );
    this.ganttHistory.enableEventLogging();
  }

  /**
   * Initialize all GUI elements.
   * @keywords build, init, gui, canvas
   * @param dataSet
   * @param canvasHeightMap
   */
  private _initGUI(dataSet: GanttDataContainer, canvasHeightMap: Map<EGanttScrollContainer, number>): void {
    // init axis
    this._initYAxis(canvasHeightMap);
    this._initXAxis(
      this.getHTMLStructureBuilder().getXAxisContainer().select<HTMLDivElement>('.gantt_x-axis').node(),
      this.getHTMLStructureBuilder().getXAxisContainer().select<HTMLDivElement>('.gantt_x-axis_placeholder').node(),
      dataSet.minValue,
      dataSet.maxValue,
      dataSet.title
    );
    const lastAxis = -1 + this.getXAxisBuilder().getXAxisItems().length;
    this._initShiftBuilder(canvasHeightMap);
    this._initTooltipBuilder();
    this.getYAxisFacade().setTooltipBuilder(this.getTooltipBuilder());

    this._initColorFilter();
    this._initGlobalTimeSpanMarker(this.getXAxisBuilder().getGlobalScale());

    this.getVerticalScrollHandler().init();
  }

  /**
   * First rendering of shifts and y axis elements.
   */
  private _initialRendering(): void {
    this._generateRenderDataSets();
    this._updateStickyRowContainerByRenderData();
    this.renderShifts(this.getRenderDataSetShifts(), this.getRenderDataSetYAxis());
    this.getYAxisFacade().render(this.getRenderDataSetYAxis());
  }

  public update(openRows = true): void {
    this.getShiftFacade().updateSize();
    this.getXAxisBuilder().updateSize();
    this.getDataHandler().getScale().range([0, this.getNodeProportionsState().getXAxisProportions().width]);
    this.updateAllShifts(this.getDataHandler().getOriginDataset(), openRows, true, false);
    this.getYAxisHeadBuilder().updateHeight();
    this.rerenderYAxisByScroll();
    this.getHTMLStructureBuilder().getSizeHandler().updateSize();

    this.getGlobalTimeSpanMarker().update();

    // Fix resize not recalculating horizontal Lines
    this.getShiftFacade().renderHorizontalLines(this.getRenderDataSetYAxis());
    this.getShiftFacade().renderVerticalLines(this.getXAxisBuilder().getVerticalLinesGenerators());
    this.getShiftFacade().update();

    // update all plug ins
    this.getPlugInHandler().updatePlugIns();
  }

  /**
   * {@link IGanttAPI}
   * @override
   */
  public destroy(): void {
    this.getTooltipBuilder().removeAllTooltips();
    this.getPlugInHandler().removeAllPlugIns();
    this._shiftResizer.destroy();
    this.getShiftFacade().destroy();
    this.panningHandler.destroy();
    this._htmlStructureBuilder.destroy();
    this.getYAxisHeadBuilder().removeAll();

    this._onDestroySubject.next();
    this._onDestroySubject.complete();
  }

  /**
   * Updates gantt to new canvas height. Doesn't change dataset.
   * @keywords build, update, height, refresh, plugin
   */
  updateHeight(): void {
    const ganttHeight = this.getRenderDataHandler().getYAxisDataFinder().getRowHeightsCombined();
    this.setCanvasHeight(ganttHeight);
    this.initCanvasData();
    this.renderShifts();
    this.rerenderShiftsVertical();
    this.rerenderYAxisByScroll();
    this.getShiftFacade().renderHorizontalLines(this.getRenderDataSetYAxis());

    // update all plug ins
    this.getPlugInHandler().updatePlugInHeight();
  }

  /**
   * Updates size of yAxis, notifies plugins.
   * @keywords yaxis, y axis, update
   * @param updateStickyRowContainer
   */
  public updateYAxis(updateStickyRowContainer = true): void {
    if (updateStickyRowContainer) this._updateStickyRowContainerByRenderData();
    this.getYAxisFacade().updateSize(this.getRenderDataSetYAxis());
    this.getPlugInHandler().updateYAxis();
  }

  /**
   * Called by dashboard after Changes were made to the origin Dataset
   */
  updatedOriginData(): void {
    const s = this;
    GanttCallBackStackExecuter.execute(s.callbacks.afterOriginDataUpdate);
  }

  /**
   * @override {IBestGanttAPI}
   */
  updateAllShifts(
    newGanttDataset: GanttDataContainer,
    keepShiftsOpen = false,
    rerenderShifts = true,
    rerenderYAxis = true
  ): void {
    const s = this;
    let openChilds;
    if (keepShiftsOpen) {
      openChilds = s._dataHandler.getOpenChilds();
    }
    // refresh data
    if (newGanttDataset) {
      s.getDataHandler().setOriginDataset(newGanttDataset);
    }
    if (keepShiftsOpen) {
      s.getDataHandler().openRowsByIdList(openChilds);
    }
    s.initCanvasData();

    // refresh gantt height
    const canvasHeight = s.getRenderDataHandler().getYAxisDataFinder().getRowHeightsCombined();
    s.setCanvasHeight(canvasHeight);

    // correct scroll position because y axis dataset has changed
    s.getVerticalScrollHandler().validateVerticalScrollPosition();

    if (rerenderShifts) s.rerenderShiftsVertical();
    if (rerenderYAxis) s.rerenderYAxisByScroll();
  }

  /**
   * Updates the sticky row container and some of its child containers to fit to the current render data.
   */
  private _updateStickyRowContainerByRenderData(): void {
    if (!this.getConfig().showStickyRows()) return;

    // update scroll container for sticky rows
    this._getScrollContainerSizeHandler().updateByRenderData(this.getRenderDataSetYAxis());

    // update other containers
    const scrollContainerId = EGanttScrollContainer.STICKY_ROWS;
    const shiftBuilder = this.getShiftFacade().getShiftBuilder(scrollContainerId);

    this.getShiftFacade().updateSize();
    this._initShiftCanvasProportions([scrollContainerId]);
    this.getShiftFacade().setCanvasHeight(
      this.getRenderDataHandler().getYAxisDataFinder().getRowHeightsCombined([EGanttScrollContainer.STICKY_ROWS])
    );
    shiftBuilder.renderVerticalLines(this.getXAxisBuilder().getVerticalLinesGenerators());
    this.getGlobalTimeSpanMarker().update();
  }

  /**
   * Dehighlights all shifts.
   */
  deHighlightAllShifts(): void {
    const s = this;

    const deHighlightAllShifts = function (child, level, parent, index, abort) {
      child.shifts.forEach((shift) => (shift.highlighted = null));
    };
    DataManipulator.iterateOverDataSet(s.getDataHandler().getOriginDataset().ganttEntries, {
      deHighlightAllShifts: deHighlightAllShifts,
    });
  }

  /**
   * Iterates over all shifts in the Gantt chart and calls the provided callback function for each shift.
   * @param callback The function to call for each shift.
   */
  iterateOverShifts(callback: (shift: GanttDataShift) => void): void {
    DataManipulator.iterateOverDataSet(this.getDataHandler().getOriginDataset().ganttEntries, {
      iterateOverShifts: (child) => child.shifts.forEach((shift) => callback(shift)),
    });
  }

  /**
   * Called by dashboard after ganttEntries to replace gantt Entries
   * @param {GanttDataContainer} originData
   */
  refreshGanttByNewOriginData(originData: GanttDataContainer): void {
    const s = this;
    s.getDataHandler().getOriginDataset().ganttEntries = originData.ganttEntries;
    s.initCanvasData();
    s.update();
  }

  /**
   * Initializes canvas datasets for shifts and yaxis.
   */
  public initCanvasData(): void {
    this.getDataHandler().initCanvasYAxisData();
    this.getDataHandler().initCanvasShiftData();

    this._generateRenderDataSets();

    // init shift canvas proportions
    this._initShiftCanvasProportions();
  }

  /**
   * Initializes the canvas proportions of the gantt.
   * @param scrollContainerIds If specified, only the canvas proportions of the specified scroll containers will be initialized.
   */
  private _initShiftCanvasProportions(scrollContainerIds = Object.values(EGanttScrollContainer)): void {
    const canvasHeightMap = this.getRenderDataHandler().getYAxisDataFinder().getRowHeightsCombined(scrollContainerIds);

    for (const scrollContainerId of canvasHeightMap.keys()) {
      const shiftCanvasProportions: INodeProportion = {
        width: this.getNodeProportionsState().getShiftViewPortProportions().width,
        height: canvasHeightMap.get(scrollContainerId),
      };
      this.getNodeProportionsState().setShiftCanvasProportions(shiftCanvasProportions, scrollContainerId);
    }
  }

  /**
   * Generates the current render datasets for y axis, shifts and milestones.
   */
  private _generateRenderDataSets(): void {
    const shiftCanvasProportions = this.getNodeProportionsState().getShiftViewPortProportions();
    this._renderDataHandler.generateRenderDataSetYAxis(this.getDataHandler().getYAxisDataset());
    this._renderDataHandler.generateRenderDataSetShifts(
      this.getDataHandler().getCanvasShiftDataset(),
      0,
      shiftCanvasProportions.width,
      1,
      0,
      shiftCanvasProportions.height,
      1
    );
  }

  /**
   * Returns a shift from origin dataset by the given id.
   * @param {string} shiftId ID of shift to be searched for
   */
  getShiftById(shiftId: string): GanttDataShift {
    const s = this;
    const result = ShiftDataFinder.getShiftById(s._dataHandler.getOriginDataset().ganttEntries, shiftId);
    return result && result.shift ? result.shift : null;
  }

  /**
   * Returns shifts from origin dataset by the given ids.
   */
  getShiftsByIds(shiftIds: string[]): GanttDataShift[] {
    const s = this;
    const result = ShiftDataFinder.getShiftsByIds(s._dataHandler.getOriginDataset().ganttEntries, shiftIds);
    return result.map((r) => r.shift);
  }

  /**
   * Inits y axis, inits y axis translator for changing rows.
   * @param canvasHeightMap height of canvas in which y axis will be rendered
   */
  private _initYAxis(canvasHeightMap: Map<EGanttScrollContainer, number>): void {
    this._yAxisFacade = new GanttYAxisFacade(this);
    this._yAxisFacade.init();
    this._yAxisFacade.build();
    this._yAxisFacade.setCanvasHeight(canvasHeightMap);
  }

  /**
   * Inits x axis, adds gantt title container with title.
   * @private
   * @param {HTMLNode} parentNodeXAxis parent node in which the x axis will be rendered
   * @param {HTMLNode} parentNodeXAxisPlaceholder parent node in which the title container will be rendered
   * @param {Date} dateStart
   * @param {Date} dateEnd
   * @param {string} title title inside the title container
   */
  private _initXAxis(
    parentNodeXAxis: HTMLDivElement,
    parentNodeXAxisPlaceholder: HTMLDivElement,
    dateStart: Date,
    dateEnd: Date,
    title: string
  ): void {
    const s = this;
    s.xAxisBuilder = new GanttXAxis(parentNodeXAxis, s.config, s.getNodeProportionsState(), this.onDestroy);

    s.getXAxisBuilder().build(dateStart, dateEnd);

    s.yAxisHeadBuilder = new GanttYAxisHead(s.config, parentNodeXAxisPlaceholder, s.getTooltipBuilder());
    s.getYAxisHeadBuilder().init();
    s.setHeadline(title);

    // add zoom buttons
    const zoomInButton = new GanttYAxisHeadButtonData();
    zoomInButton.addState(
      'gantt_x-axis_placeholder-btn-zoom-in',
      s.getXAxisBuilder().zoomIn.bind(s.getXAxisBuilder()),
      null,
      'Hineinzoomen'
    );
    const zoomOutButton = new GanttYAxisHeadButtonData();
    zoomOutButton.addState(
      'gantt_x-axis_placeholder-btn-zoom-out',
      s.getXAxisBuilder().zoomOut.bind(s.getXAxisBuilder()),
      null,
      'Herauszoomen'
    );
    s.getYAxisHeadBuilder().addButton(zoomInButton);
    s.getYAxisHeadBuilder().addButton(zoomOutButton);

    // add sort button
    s.setSortRowsButton(true);

    // add y axis search button
    s.setYAxisSearchButton(true);

    s.config.addAfterXAxesConfigChangedCallback(
      'renderXAxisAfterConfigChanged',
      s._reBuildAndRenderXAxis.bind(s, {
        start: dateStart,
        end: dateEnd,
      })
    );

    this.config
      .onXAxisShowWeekdaysChanged()
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => this.getXAxisBuilder().reRenderAxis());
  }

  /**
   * Rebuilds the x axis.
   *
   * @param {Object} startEnd Contains start and end value
   * @property {Date} startEnd.start
   * @property {Date} startEnd.end
   * @callback
   */
  _reBuildAndRenderXAxis(startEnd: { start: Date; end: Date }): void {
    const s = this;
    s.getXAxisBuilder().removeAll();
    s.getXAxisBuilder().build(startEnd.start, startEnd.end);
  }

  /**
   * Inits shift builder.
   * @param canvasHeightMap height of canvas in which this shifts will be rendered
   */
  private _initShiftBuilder(canvasHeightMap: Map<EGanttScrollContainer, number>): void {
    this.shiftFacade = new GanttShiftBuilderFacade(this);

    this.getShiftFacade().init();
    this.getShiftFacade().setCanvasHeight(canvasHeightMap);
    this.getShiftFacade().renderVerticalLines(this.getXAxisBuilder().getVerticalLinesGenerators());

    this.shiftFacade
      .getPatternHandler()
      .onImageComplete$.pipe(takeUntil(this.onDestroy))
      .pipe(filter((event) => event.source === EPatternEventSource.GET_PATTERN_AS_SVG_IMAGE))
      .pipe(debounceTime(100))
      .subscribe(() => this.rerenderShiftsVertical());
  }

  /**
   * Initialization of tooltip builder.
   */
  private _initTooltipBuilder(): void {
    d3.select(this.getShiftFacade().getParentNode().node()).on('mouseleave', () => {
      this.getShiftFacade().clearTimeouts();
      GanttCallBackStackExecuter.execute(this.callbacks.mouseLeaveShiftContainer);
      this.getXAxisBuilder().highlightXAxis();
    });

    this.getHTMLStructureBuilder()
      .getParentNode()
      .on('mouseleave', () => {
        this.toolTipBuilder.removeAllTooltips();
      });
    this.getShiftFacade().setTooltipBuilder(this.toolTipBuilder);
  }

  /**
   * @private
   * @deprecated 11.08.2020
   */
  private _initColorFilter(): void {
    const s = this;
    s.colorFilter = new FilterByColor();
    s.getColorFilter().storeOriginColors(s.getDataHandler().getOriginDataset());
  }

  /**
   * Inits GlobalTimeSpanMarker to show colorized weekends inside shift canvas.
   * @param scale
   */
  private _initGlobalTimeSpanMarker(scale: d3.ScaleTime<number, number>): void {
    this.globalTimeSpanMarker = new GlobalTimeSpanMarker(this);
    this.getGlobalTimeSpanMarker().init(scale);
    if (this.getXAxisBuilder().getCurrentTimeFormat().value > this.config.getGlobalShift())
      this.getGlobalTimeSpanMarker().show();
  }

  /**
   * {@link IGanttAPI}
   * @override
   */
  removeAllNodes(): void {
    const s = this;
    s.getHTMLStructureBuilder().removeStructure();
  }

  public renderShifts(
    shiftDataset: GanttRenderDataSetShifts = undefined,
    yAxisDataset: GanttRenderDataSetYAxis = undefined
  ): void {
    if (!shiftDataset) {
      this.filterRenderDataSetsByCurrentView();
      shiftDataset = this.getRenderDataSetShifts();
    }
    if (!yAxisDataset) {
      yAxisDataset = this.getRenderDataSetYAxis();
    }

    this.getShiftFacade().renderShifts(shiftDataset);
    this.getShiftFacade().renderHorizontalLines(yAxisDataset);
  }

  /**
   * Increases and decreases given height to gantt y axis and shift canvas. Is used by open row handler for smooth container resizes.
   * @keywords height, size, change, add, canvas
   * @param addedHeight Height to add.
   */
  addToGanttHeight(addedHeight: number): void {
    this.getShiftFacade().addToCanvasHeight(addedHeight);
    this.getYAxisFacade().getYAxisBuilder().addToCanvasHeight(addedHeight);
    this.getGlobalTimeSpanMarker().addToCanvasHeight(addedHeight);
  }

  public setCanvasHeight(canvasHeightMap: Map<EGanttScrollContainer, number>): void {
    this.getShiftFacade().setCanvasHeight(canvasHeightMap);
    this.getYAxisFacade().setCanvasHeight(canvasHeightMap);
    this.getGlobalTimeSpanMarker().setCanvasHeight(canvasHeightMap);
  }

  /**
   * Filters render data set depending on current view.
   */
  public filterRenderDataSetsByCurrentView(): void {
    this._renderDataHandler.filterRenderDataSetsByCurrentView(this, 0);
  }

  /**
   * Rerenders yAxis.
   * @keywords build, update, render, y axis, canvas, dataset, vertical, scroll
   */
  public reRenderYAxisVertical(): void {
    for (const scrollContainerId of this._renderDataHandler.getAllScrollContainerIds()) {
      const scrollTop = this.getNodeProportionsState().getScrollTopPosition(scrollContainerId);
      this._renderDataHandler.getCanvasTransform().access(scrollContainerId).top = scrollTop;
    }

    this._renderDataHandler.generateRenderDataSetYAxis(this.getDataHandler().getYAxisDataset());
    this._getScrollContainerSizeHandler().updateByRenderData(this.getRenderDataSetYAxis());
    this._updateStickyRowContainerByRenderData();
    this.getYAxisFacade().render(this.getRenderDataSetYAxis());
  }

  /**
   * ! Not completly implemented yet!
   *
   * Finds shift and shifts visually by setting view and scroll position, flashing them for a moment, optionally selecting them afterwards.
   * @param {String|Array.<String>} shiftIds
   * @TODO: complete implementation
   *  - error handling
   *  - array handling
   *  - selecting of shifts with appropriate callbacks
   */
  viewShiftById(shiftIds: string[] = []): void {
    const s = this;
    return;

    function scrollTopTween(scrollTop) {
      return function () {
        const i = d3.interpolateNumber(this.scrollTop, scrollTop);
        return function (t) {
          s.getShiftFacade().getShiftBuilder().getParentNode().scrollTop = i(t);
        };
      };
    }
  }

  public rerenderShiftsVertical(): void {
    this.filterRenderDataSetsByCurrentView();
    this.getShiftFacade().renderShifts(this.getRenderDataSetShifts());
  }

  /**
   * Rebuilds shift text overlay to canvas by render dataset.
   * @keywords build, update, render, shift, text, overlay, canvas, dataset
   */
  rerenderShiftTextOverlay(): void {
    this.getShiftFacade().rerenderTextOverlay(this.getRenderDataSetShifts());
  }

  /**
   * Helper function to set two (start & end) markers to x axis by shift canvas data.
   * @keywords build, mark, xaxis, x axis, shift, timepoint
   * @param {GanttCanvasShift} shiftCanvasData Dataset of canvas shift.
   */
  markShiftXAxisByCanvasData(shiftCanvasData: GanttCanvasShift): void {
    const s = this;
    const foundShiftData = ShiftDataFinder.getShiftById(
      s.getDataHandler().getOriginDataset().ganttEntries,
      shiftCanvasData.id
    );
    if (!foundShiftData.shift) return;
    s.getXAxisBuilder().addMarkerByDate(new Date(foundShiftData.shift.timePointEnd), ETimeMarkerAnchor.START);
    s.getXAxisBuilder().addMarkerByDate(new Date(foundShiftData.shift.timePointStart), ETimeMarkerAnchor.END);
  }

  /**
   * Helper function to set two (start & end) markers to x axis by coordinates.
   * @param {number} x Horizontal position of first x axis marker.
   * @param {number} width Distance between x and seconds marker.
   */
  markShiftXAxisByPosition(x: number, width: number): MarkerItem[] {
    const s = this;
    const marker1 = s.getXAxisBuilder().addMarkerByPoints(x, ETimeMarkerAnchor.END);
    const marker2 = s.getXAxisBuilder().addMarkerByPoints(x + width, ETimeMarkerAnchor.START);
    return [marker1, marker2];
  }

  /**
   * Helper function to set due date markers to x axis.
   * Due Dates resemble earliest start and soonest end of process.
   * @param shiftId Id of shift to render due dates for.
   * @param isCalledByResizer True if addMarkerByPointsWhileResizing() is calling this function (to differentiate between resizing or normal event call).
   */
  public markShiftDueDates(shiftId: string, isCalledByResizer = false): void {
    const shiftOriginData = ShiftDataFinder.getShiftById(
      this.getDataHandler().getOriginDataset().ganttEntries,
      shiftId
    ).shift;
    if (!shiftOriginData) return;

    const modificationRestriction = shiftOriginData.modificationRestriction;
    if (!modificationRestriction) return;

    // mark latest end time
    const latestEndTime = modificationRestriction.latestEndTime;
    if (latestEndTime) {
      this.getXAxisBuilder().addMarkerByDate(
        latestEndTime,
        ETimeMarkerAnchor.START,
        null,
        '#FFA500',
        isCalledByResizer
      );
    }

    // mark earliest start time
    const earliestStartTime = modificationRestriction.earliestStartTime;
    if (earliestStartTime) {
      this.getXAxisBuilder().addMarkerByDate(
        earliestStartTime,
        ETimeMarkerAnchor.END,
        null,
        '#FFA500',
        isCalledByResizer
      );
    }
  }

  /**
   * @override {IBestGanttAPI}
   */
  colorizeByAdditionalData(additionalProperty: string): void {
    const s = this;
    s.getColorFilter().filter(s, additionalProperty);
  }

  /**
   * @TODO: Mit Verhalten absprechen. 12.08.2020
   * Mark all shifts which have broken due dates.
   * @keywords due date, duedate, broken, color, shift, change
   */
  markAllBrokenDueDateShifts(): void {
    const s = this;
    s.getColorFilter().brokenDueDate(this);
  }

  /**
   * Updates text entries in y axis.
   * Callback for rendering y axis by scroll.
   * @keywords callback, rerender, yaxis, y axis, scroll render, build, text, overlay, yaxis, y axis
   */
  public rerenderYAxisByScroll(): void {
    new GanttYAxisHandler(this).rerenderYAxisByScroll();
  }

  public removeShiftAreaSVGElements(): void {
    this.getShiftFacade().removeAllShifts();
  }

  /**
   * Callback function to clear duedate timeouts and remover x axis marks.
   * @keywords callback, markers, remove, duedate, due date
   */
  public removeDueDateMarks(): void {
    const s = this;
    clearTimeout(s.getTimeOuts().markDueDate);
    s.getXAxisBuilder().removeAllDateMarkers();
  }

  /**
   * Registers Callbacks to consider shift earliest Start on Resizing.
   * @private
   */
  private _addShiftResizeEarliestStart(): void {
    const s = this;
    clearTimeout(s.getTimeOuts().markDueDate);
    s.getXAxisBuilder().removeAllDateMarkers();
  }

  public deselectAllShifts(): void {
    const canvasDataSet = this.getDataHandler().getCanvasShiftDataset();
    const originDataSet = this.getDataHandler().getOriginDataset().ganttEntries;
    this.getSelectionBoxFacade().deselectAllShifts(canvasDataSet, originDataSet);
    this.rerenderShiftsVertical();
  }

  public selectShiftsByIDs(shiftIds: string[], render = false): void {
    this._selectionBoxFacade.selectShiftsByIds(shiftIds);
    if (render) this.rerenderShiftsVertical();
  }

  /**
   * Returns a canvas shift based on the mouse position.
   * @param mouseEvent
   */
  public getShiftByMousePosition(mouseEvent: MouseEvent): GanttCanvasShift {
    const scrollContainerId = this._renderDataHandler.getYAxisDataFinder().getScrollContainerByPageY(mouseEvent.pageY);
    const shiftCanvasProportions = this.getHTMLStructureBuilder()
      .getShiftContainer(scrollContainerId)
      .node()
      .getBoundingClientRect();
    const zoom = this.getXAxisBuilder().getLastZoomEvent();
    const absoluteShiftContainerPosX = mouseEvent.pageX - shiftCanvasProportions.x;
    const absoluteShiftContainerPosY = mouseEvent.pageY - shiftCanvasProportions.y;
    if (
      absoluteShiftContainerPosX < 0 ||
      absoluteShiftContainerPosX < 0 ||
      absoluteShiftContainerPosX > shiftCanvasProportions.width ||
      absoluteShiftContainerPosY > shiftCanvasProportions.height
    ) {
      console.warn('Position outside of shift container!');
      return null;
    }
    const shiftContainerPositionX = (absoluteShiftContainerPosX - zoom.x) / zoom.k;
    const shiftContainerPositionY = absoluteShiftContainerPosY; // + scrollTop;
    const canvasData = this._renderDataHandler.getRenderDataShifts(scrollContainerId);

    const target = canvasData.filter((shift) => {
      const shiftY = this._renderDataHandler.getStateStorage().getYPositionShift(shift.id);
      return (
        shiftContainerPositionX >= shift.x &&
        shiftContainerPositionX <= shift.x + shift.width &&
        shiftContainerPositionY >= shiftY &&
        shiftContainerPositionY <= shiftY + shift.height
      );
    });
    return target.length ? target[0] : null;
  }

  //
  // GETTER & SETTER
  //

  public get isAlive(): boolean {
    return this._isAlive;
  }

  /** @return {DataHandler} */
  public getDataHandler(): DataHandler {
    return this._dataHandler;
  }

  /** * @return {GanttShiftBuilderFacade} */
  getShiftFacade(): GanttShiftBuilderFacade {
    return this.shiftFacade;
  }

  /** @return {GlobalTimeSpanMarker} */
  getGlobalTimeSpanMarker(): GlobalTimeSpanMarker {
    return this.globalTimeSpanMarker;
  }

  /** @return {TextOverlay} */
  public getTextOverlay(scrollContainerId: EGanttScrollContainer = EGanttScrollContainer.DEFAULT): TextOverlay {
    return this.getShiftFacade().getTextOverlay(scrollContainerId);
  }

  /** @return {GanttXAxis}*/
  getXAxisBuilder(): GanttXAxis {
    return this.xAxisBuilder;
  }

  public getYAxisFacade(): GanttYAxisFacade {
    return this._yAxisFacade;
  }

  /** @return {GanttYAxisHead} */
  getYAxisHeadBuilder(): GanttYAxisHead {
    return this.yAxisHeadBuilder;
  }

  public getAnimator(): GanttAnimator {
    return this._openRowHandler.getAnimator();
  }

  public getOpenRowHandler(): GanttOpenRowHandler {
    return this._openRowHandler;
  }

  public getShiftTranslator(): GanttShiftTranslator {
    return this._shiftTranslator;
  }

  public getShiftResizer(): GanttShiftResizer {
    return this._shiftResizer;
  }

  public getShiftTranslationChain(): GanttShiftTranslationChain {
    return this._chainHandler;
  }

  public getShiftEditLimiter(): GanttShiftEditLimiter {
    return this._shiftEditLimiter;
  }

  /** @return {GanttConfig} */
  getConfig(): GanttConfig {
    return this.config;
  }

  /**
   * Returns the full render dataset for rows as stored in {@link RenderDataHandler}.
   * @returns Full render dataset for rows as stored in {@link RenderDataHandler}.
   */
  public getRenderDataSetYAxis(): GanttRenderDataSetYAxis {
    return this._renderDataHandler.getRenderDataSetYAxis();
  }

  /**
   * Returns the full render dataset for shifts as stored in {@link RenderDataHandler}.
   * @returns Full render dataset for shifts as stored in {@link RenderDataHandler}.
   */
  public getRenderDataSetShifts(): GanttRenderDataSetShifts {
    return this._renderDataHandler.getRenderDataSetShifts();
  }

  getColorFilter(): FilterByColor {
    return this.colorFilter;
  }

  public getHTMLStructureBuilder(): GanttHTMLStructureBuilder {
    return this._htmlStructureBuilder;
  }

  /**
   * Returns the current {@link GanttScrollContainerSizeHandler} of the gantt.
   * @returns Current {@link GanttScrollContainerSizeHandler} of the gantt.
   */
  private _getScrollContainerSizeHandler(): GanttScrollContainerSizeHandler {
    return this._htmlStructureBuilder.getScrollContainerSizeHandler();
  }

  public getSelectionBoxFacade(): GanttElementSelectorFacade {
    return this._selectionBoxFacade;
  }

  getTooltipBuilder(): TooltipBuilder {
    // return this.getShiftFacade().getTooltipBuilder()?.getTooltipBuilder();
    return this.toolTipBuilder;
  }

  getTrash(): GanttTrash {
    return this.trash;
  }

  /** @return {GanttHistory} */
  getHistory(): GanttHistory {
    return this.ganttHistory;
  }

  getTimeOuts(): any {
    return this.timeOuts;
  }

  getParentNode(): HTMLElement {
    return this.parentNode;
  }

  getCombinedCanvasHeight(): number {
    return (
      this.getShiftFacade().getCanvasBehindShiftCanvasHeight() +
      (this.getHTMLStructureBuilder().getXAxisContainer()
        ? this.getHTMLStructureBuilder().getXAxisContainer().node().clientHeight
        : 0)
    );
  }

  public getRenderDataHandler(): RenderDataHandler {
    return this._renderDataHandler;
  }

  getNodeProportionsState(): NodeProportionsState {
    return this._nodeProportionsState;
  }

  /** @return {VerticalScrollHandler} */
  getVerticalScrollHandler(): VerticalScrollHandler {
    return this.verticalScrollHandler;
  }

  getMainSelectionBoxHandler(): GanttSelectionBoxHandler {
    return this._selectionBoxHandler;
  }

  /**
   * @param {Date} from Start date of interval.
   * @param {Date} to End date of interval.
   */
  setCurrentZoomedTimeSpan(from: Date, to: Date): void {
    this.getXAxisBuilder().zoomToTimeSpan(from, to, false);
  }

  /**
   * Returns the currently visible time span of the gantt chart.
   * @returns {{from: Date, to: Date}}
   */
  getCurrentZoomedTimeSpan(): { from: Date; to: Date } {
    const s = this;
    const currentTimeSpan = s.getXAxisBuilder().getCurrentZoomedTimeSpan();
    return {
      from: currentTimeSpan[0],
      to: currentTimeSpan[1],
    };
  }

  /** @return {BestGanttPluginHandler} */
  getPlugInHandler(): BestGanttPluginHandler {
    return this.plugInHandler;
  }

  public getOverlayApi(): GanttOverlayService {
    return this._overlayApi;
  }

  /** Sets the headline in yAxisHead
   *  @param {String} headline
   */
  setHeadline(headline: string): void {
    const s = this;
    if (!s.getYAxisHeadBuilder()) return;
    s.getYAxisHeadBuilder().setHeadline(headline);
  }

  //
  // OBSERVABLES
  //

  /**
   * Observable which gets triggered when the instance gets destroyed.
   */
  public get onDestroy(): Observable<void> {
    return this._onDestroySubject.asObservable();
  }

  /**
   * Observable which will be triggered every time the y axis got resized.
   * It will pass the new y axis width as value.
   */
  public get afterYAxisResizeEnd(): Observable<number> {
    return this._htmlStructureBuilder.getSizeHandler().afterYAxisResizeEnd;
  }

  //
  // CALLBACKS
  //

  addSelectShiftsByRightClickCallback(id, func) {
    this.callbacks.selectShiftsByRightClick[id] = func;
  }
  removeSelectShiftsByRightClickCallback(id) {
    delete this.callbacks.selectShiftsByRightClick[id];
  }

  subscribeOriginDataUpdate(id, func) {
    this.callbacks.afterOriginDataUpdate[id] = func;
  }
  unSubscribeOriginDataUpdate(id) {
    delete this.callbacks.afterOriginDataUpdate[id];
  }

  subscribeMouseLeaveShiftContainer(id, func) {
    this.callbacks.mouseLeaveShiftContainer[id] = func;
  }
  unSubscribeMouseLeaveShiftContainer(id) {
    delete this.callbacks.mouseLeaveShiftContainer[id];
  }

  setAutomaticZoomAndScrollTimeGrid(bool: boolean): void {
    this.getXAxisBuilder().setAutomaticZoomGrid(bool);
  }

  setStaticZoomGrid(grid: ETimeGradationTimeGrid | string): void {
    if (typeof grid === 'string') grid = ETimeGradationTimeGrid[grid];
    this.getXAxisBuilder().setStaticZoomGrid(grid as ETimeGradationTimeGrid);
  }

  setCustomZoomGridStepWidth(customizedStartDate: Date | number, customizedStepSize: Date | number): void {
    this.getXAxisBuilder().setCustomizedTimeGrid(customizedStartDate, customizedStepSize);
  }

  //
  // PLUG-INS
  //

  addPlugIn(id: string, plugInObject: BestGanttPlugIn): void {
    this.getPlugInHandler().addPlugIn(id, plugInObject, this);
  }

  updatePlugIns(args: any): void {
    this.getPlugInHandler().updatePlugIns(args);
  }

  setPlugIns(plugIns: Map<string, BestGanttPlugIn>): void {
    this.getPlugInHandler().setPlugIns(plugIns, this);
  }

  getPlugIns(): Map<string, BestGanttPlugIn> {
    return this.getPlugInHandler().getPlugIns();
  }

  //
  // PERMISSIONS
  //

  public allowShiftDragDrop(bool: boolean): void {
    this.getShiftFacade().getShiftBuilder().setDragDrop(bool);
  }

  public allowShiftResizer(bool: boolean): void {
    this.getShiftResizer().shiftEditLimiterAdapter.allowShiftResizing = bool;
  }

  public allowDraggingVertical(bool: boolean): void {
    this.getShiftTranslator().shiftEditLimiterAdapter.allowDragVertical = bool;
  }

  public allowDraggingHorizontal(bool: boolean): void {
    this.getShiftTranslator().shiftEditLimiterAdapter.allowDragHorizontal = bool;
  }

  public allowTooltip(bool: boolean): void {
    this.getTooltipBuilder().showTooltips(bool);
  }

  public allowRowDrag(bool: boolean): void {
    this.getYAxisFacade().setAllowDragDrop(bool);
  }

  allowClicks(bool: boolean): void {
    const s = this;
    s._allowClicks = bool;
    s.getShiftFacade().getShiftBuilder().setAllowShiftClicks(bool);
    s.getSelectionBoxFacade().setEnable(bool);
    s.getYAxisFacade().setAllowClicks(bool);
  }

  blockInteractionByShiftIds(shiftIds: string[]): void {
    const s = this;

    if (Array.isArray(shiftIds) && shiftIds.length) {
      const canvasDataSet = s.getDataHandler().getCanvasShiftDataset();
      const originDataSet = s.getDataHandler().getOriginDataset().ganttEntries;
      s.getSelectionBoxFacade().deselectAllShifts(canvasDataSet, originDataSet);
      s.getDataHandler().setShiftsEditable(shiftIds, false);
    }
  }

  unBlockInteractionByShiftIds(shiftIds: string[]): void {
    const s = this;

    if (Array.isArray(shiftIds) && shiftIds.length) {
      s.getDataHandler().setShiftsEditable(shiftIds, true);
    }
  }

  public collapseAllRows(collapse: boolean): void {
    if (collapse) {
      this.getDataHandler().collapseAllRows();
    } else {
      this.getDataHandler().expandAllRows();
    }
    this.update();
  }

  setCollapseAllRowsButton(bool: boolean): void {
    const s = this;
    const id = 'toggle-close-all-rows';
    if (bool) {
      const collapseAllRowsButton = new GanttYAxisHeadButtonData(id);
      collapseAllRowsButton.addState(
        'material-icons md-24',
        () => {
          s.collapseAllRows(true);
        },
        'unfold_less',
        'Alle Zeilen schließen'
      );
      collapseAllRowsButton.addState(
        'material-icons md-24',
        () => {
          s.collapseAllRows(false);
        },
        'unfold_more',
        'Alle Zeilen öffnen'
      );
      s.getYAxisHeadBuilder().addButton(collapseAllRowsButton);
    } else {
      s.getYAxisHeadBuilder().removeGanttMenuItem(id);
    }
  }

  /**
   * Adds/removes the row sorting button from y axis head.
   * @param {boolean} activate Specfies whether the button should be activated or deactivated.
   */
  public setSortRowsButton(activate: boolean): void {
    const s = this;
    const id = 'sort-rows-button';

    if (activate) {
      const sortButton = new GanttYAxisHeadSortRowsButtonData(s, id);
      s.getYAxisHeadBuilder().addButton(sortButton);
    } else {
      s.getYAxisHeadBuilder().removeGanttMenuItem(id);
    }
  }

  /**
   * Adds/removes the row search button from y axis head.
   * @param {boolean} activate Specfies whether the button should be activated or deactivated.
   */
  public setYAxisSearchButton(activate: boolean): void {
    const s = this;
    const id = 'y-axis-search-button';

    if (activate) {
      const yAxisSearchButton = new GanttYAxisHeadRowSearchButtonData(s, id);
      s.getYAxisHeadBuilder().addButton(yAxisSearchButton);
    } else {
      s.getYAxisHeadBuilder().removeGanttMenuItem(id);
    }
  }

  /**
   * During Backend requests we dont want click interaction with shifts or the canvas.
   * It may lead to bugs such as deselecting elements during the request causing responses to fail as the set variables are cleared.
   */
  getAllowClicks(): boolean {
    return this._allowClicks;
  }
}
