<div class="wrapper">
  <form [formGroup]="formGroup">
    <!-- Current Password -->
    <mat-form-field appearance="outline" *ngIf="showCurrentPasswordField">
      <mat-label>{{ 'old password' | translate }}</mat-label>
      <input
        [formControlName]="'currentPassword'"
        [formControl]="currentPasswordControl"
        [placeholder]="'old password' | translate"
        [type]="currentPasswordVisible ? 'text' : 'password'"
        required
        matInput
      />
      <button type="button" mat-icon-button matSuffix (click)="currentPasswordVisible = !currentPasswordVisible">
        <mat-icon> {{ currentPasswordVisible ? 'visibility' : 'visibility_off' }}</mat-icon>
      </button>
      <mat-error *ngIf="currentPasswordControl.invalid && currentPasswordControl.touched">
        {{ getErrorMessage(currentPasswordControl) }}
      </mat-error>
    </mat-form-field>

    <!-- New Password -->

    <mat-form-field appearance="outline">
      <mat-label>{{ 'new password' | translate }}</mat-label>
      <input
        [formControlName]="'newPassword'"
        [formControl]="newPasswordControl"
        [placeholder]="'new password' | translate"
        [type]="newPasswordVisible ? 'text' : 'password'"
        (blur)="confirmPasswordControl.updateValueAndValidity()"
        required
        matInput
      />
      <button type="button" mat-icon-button matSuffix (click)="newPasswordVisible = !newPasswordVisible">
        <mat-icon> {{ newPasswordVisible ? 'visibility' : 'visibility_off' }}</mat-icon>
      </button>
      <mat-error *ngIf="newPasswordControl.invalid && newPasswordControl.touched">
        {{ getErrorMessage(newPasswordControl) }}
      </mat-error>
    </mat-form-field>

    <!-- Confirm Password -->
    <mat-form-field appearance="outline">
      <mat-label>{{ 'confirm new password' | translate }}</mat-label>
      <input
        [formControlName]="'confirmPassword'"
        [formControl]="confirmPasswordControl"
        [placeholder]="'confirm new password' | translate"
        [type]="confirmPasswordVisible ? 'text' : 'password'"
        required
        matInput
        validateEqual="newPassword"
      />
      <button type="button" mat-icon-button matSuffix (click)="confirmPasswordVisible = !confirmPasswordVisible">
        <mat-icon> {{ confirmPasswordVisible ? 'visibility' : 'visibility_off' }}</mat-icon>
      </button>
      <mat-error *ngIf="confirmPasswordControl.invalid && confirmPasswordControl.touched">
        {{ getErrorMessage(confirmPasswordControl) }}
      </mat-error>
    </mat-form-field>

    <re-captcha *ngIf="useRecaptcha" [formControl]="recaptchaReactive"></re-captcha>

    <!-- Submit Button -->
    <button class="submit-btn" mat-raised-button [disabled]="formGroup.invalid" (click)="onSubmit()" color="primary">
      <ng-container *ngIf="_isProcessing">
        <loading-spinner></loading-spinner>
      </ng-container>
      {{ _isProcessing ? '' : ('SECURITY.PASSWORD.change' | translate | uppercase) }}
    </button>
  </form>
</div>
