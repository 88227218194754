<div #hotspotWidget class="hotspot-wrapper" [ngStyle]="{ height: template ? template.getHeight() + 'px' : '500px' }">
  <svg
    #svgContainer
    [style.width]="template ? template.getWidth() + 'px' : '500px'"
    [style.height]="template ? template.getHeight() + 'px' : '500px'"
    [style.background]="'rgb(200,200,200)'"
    class="poly-wrapper"
    (dblclick)="onAddNewVertex($event)"
  >
    <image
      #imageContainer
      attr.xlink:href="{{ template.getBackgroundImage() }}"
      *ngIf="template.getBackgroundImage()"
      [style.width]="template ? template.getWidth() + 'px' : '500px'"
      [style.height]="template ? template.getHeight() + 'px' : '500px'"
    />

    <ng-container *ngFor="let poly of template.getPolygons()">
      <path
        [cdkDragDisabled]="!poly.getEditMode()"
        (dblclick)="stopEvents($event)"
        (click)="onEditPolygon($event, poly)"
        #line
        (cdkDragMoved)="onDragMoved($event, poly, line, null)"
        (cdkDragStarted)="onDragStart($event, poly)"
        (cdkDragEnded)="onDragEnd($event, poly, line, null, null)"
        attr.opacity="{{ currentAttribute?.getColorScheme()?.getOpacity() / 100 || '1' }}"
        cdkDrag
        attr.fill="{{ poly.getColor() }}"
        stroke-width="1"
        stroke="blue"
        attr.d="{{ getVertices(poly) + 'Z' }}"
      ></path>
      <!-- <path [cdkDragDisabled]="!poly.getEditMode()" (dblclick)="stopEvents($event)" (click)="onEditPolygon($event,poly)"
        #line (cdkDragMoved)="onDragMoved($event,poly,line,null)" (cdkDragEnded)="onDragEnd($event,poly,line,null,null)"
        cdkDragBoundary=".poly-wrapper" attr.opacity="{{currentAttribute?.getColorScheme()?.getOpacity() / 100 || '1'}}"
        cdkDrag attr.fill="{{poly.getColor()}}" stroke-width="1" stroke="blue" attr.d="{{getVertices(poly) + 'Z'}}">
      </path> -->
      <ng-container *ngIf="poly.getEditMode() && !poly.isMoved()">
        <ng-container *ngFor="let vertex of poly.getVertices()">
          <circle
            (dblclick)="stopEvents($event)"
            [ngClass]="{ 'vertrex-selected': currentNode && vertex.getId() === currentNode.getId() }"
            #editVertex
            cdkDrag
            (click)="onSelectVertex($event, vertex)"
            (cdkDragMoved)="onDragMoved($event, poly, editVertex, vertex)"
            (cdkDragEnded)="onDragEnd($event, currentPolygon, editVertex, vertex)"
            attr.cx="{{ transformVertex(vertex).x }}"
            attr.cy="{{ transformVertex(vertex).y }}"
            r="3"
            stroke="black"
            stroke-width="2"
            fill="blue"
          />
          <text attr.x="{{ transformVertex(vertex).x + 5 }}" attr.y="{{ transformVertex(vertex).y }}" dy=".3em">
            {{ vertex.getIndex() }}
          </text>
        </ng-container>
        <ng-container *ngIf="currentPolygon && currentPolygon.getPolygonType() === 'quadratic'">
          <ng-container *ngFor="let interpolationVertex of poly.getInterpolationPoints()">
            <circle
              (dblclick)="stopEvents($event)"
              #editInterpolationVertex
              cdkDrag
              (cdkDragMoved)="onDragMoved($event, poly, editInterpolationVertex, interpolationVertex)"
              (cdkDragEnded)="onDragEnd($event, currentPolygon, editInterpolationVertex, interpolationVertex)"
              attr.cx="{{ transformVertex(interpolationVertex).x }}"
              attr.cy="{{ transformVertex(interpolationVertex).y }}"
              r="3"
              stroke="black"
              stroke-width="2"
              fill="orange"
            />
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </svg>
</div>
