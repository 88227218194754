import * as d3 from 'd3';
import { Observable, Subject, takeUntil } from 'rxjs';
import { GanttCanvasShift } from '../../data-handler/data-structure/data-structure';
import { GanttScrollContainerEvent } from '../../html-structure/scroll-container-event';
import { BestGantt } from '../../main';
import { ETimeMarkerAnchor } from '../../x-axis/x-axis';
import { BestGanttPlugIn } from '../gantt-plug-in';

/**
 * Plugin which handles additional timestamps which will be rendered after shift mouseover inside the x axis.
 * @constructor
 * @property {Map<string,GanttShowShiftTimeStampData[]>} allShiftTimeStamps
 */
export class GanttShowShiftTimeStamps extends BestGanttPlugIn {
  private _allShiftTimeStamps: { [shiftId: string]: GanttShowShiftTimeStampData[] } = {};

  private _onDestroySubject: Subject<void> = new Subject<void>();

  constructor() {
    super(); // call super-constructor
  }

  public initPlugIn(ganttDiagram: BestGantt): void {
    this.ganttDiagram = ganttDiagram;
    this.ganttDiagram
      .getShiftFacade()
      .shiftMouseOver()
      .pipe(takeUntil(this.onDestroy))
      .subscribe((event) => this._renderAdditionalTimeStamps(event));
  }

  /**
   * Remove of plugin. Part of plugin lifecycle.
   */
  public removePlugIn(): void {
    // callback unsubscribe
    this._onDestroySubject.next();
    this._onDestroySubject.complete();
  }

  /**
   * @param mouseOverEvent
   */
  private _renderAdditionalTimeStamps(mouseOverEvent: GanttScrollContainerEvent<MouseEvent>): void {
    const targetShiftId = d3
      .select<SVGRectElement, GanttCanvasShift>(mouseOverEvent.event.target as SVGRectElement)
      .data()[0].id;
    const matchingAdditionalTimestamps = this._allShiftTimeStamps[targetShiftId];
    if (!matchingAdditionalTimestamps) return;
    // sort by date ascending
    matchingAdditionalTimestamps.sort((a, b) => b.timePoint.getTime() - a.timePoint.getTime());
    for (let i = 0; i < matchingAdditionalTimestamps.length; i++) {
      const timeStamp = matchingAdditionalTimestamps[i];
      const pos = i === 0 ? ETimeMarkerAnchor.START : ETimeMarkerAnchor.END;
      this.ganttDiagram
        .getXAxisBuilder()
        .addMarkerByDate(new Date(timeStamp.timePoint), pos, timeStamp.label, timeStamp.color);
    }
  }

  /**
   * Adds given list of timestampdata to shift with given id.
   * @param {string} shiftId Id of shift where timestamps will be added.
   * @param {GanttShowShiftTimeStampData[]} timeStampdata List of time stamp data.
   */
  setAdditionalTimeStamps(shiftId, timeStampdata) {
    this._allShiftTimeStamps[shiftId] = timeStampdata;
  }

  /**
   * Replaces time stamp with same timestamp value. If there is nothing found, time stamp data will be added.
   */
  replaceAdditionalTimeStamp(shiftId, timeStampData) {
    const foundShiftData = this._allShiftTimeStamps[shiftId];
    if (!foundShiftData) return;
    const overlappingTimeStampIndex = foundShiftData.findIndex(function (timestampDataItem) {
      return timestampDataItem.timePoint.getTime() == timeStampData.timePoint.getTime();
    });
    if (overlappingTimeStampIndex == -1) foundShiftData.push(timeStampData);
    else foundShiftData.splice(overlappingTimeStampIndex, 1, timeStampData);
  }

  //
  // OBSERVABLES
  //

  /**
   * Observable which gets triggered when the instance gets destroyed.
   */
  private get onDestroy(): Observable<void> {
    return this._onDestroySubject.asObservable();
  }
}

/**
 * Data class to store information about additional timepoint.
 * @constructor
 * @param {string} color
 * @param {Date} timePoint
 * @param {string} [label] If no label is set, the date time will be displayed.
 */
export class GanttShowShiftTimeStampData {
  color: string;
  timePoint: Date;
  label: string;

  constructor(color, timePoint, label) {
    this.color = color;
    this.timePoint = new Date(timePoint);
    this.label = label;
  }
}
