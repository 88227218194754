/**
 * All states of the GanttResourceChangePlugIn.
 * String allocation to enum values is important to change state from backend.
 */
export enum GanttResourceChangeMode {
  SLEEP = 'SLEEP',
  CREATE = 'togglepluginmodecreate',
  EDIT = 'togglepluginmodeedit',
  DELETE = 'togglepluginmodedelete',
}
