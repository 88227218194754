export class ResizeEvent {
  private oldWidth: number;
  private newWidth: number;
  private oldHeight: number;
  private newHeight: number;

  constructor(initialWidth: number, initialHeight: number) {
    this.setNewWidth(initialWidth);
    this.setNewHeight(initialHeight);
  }

  public getOldWidth(): number {
    return this.oldWidth;
  }

  public setOldWidth(oldWidth: number): this {
    this.oldWidth = oldWidth;
    return this;
  }

  public getNewWidth(): number {
    return this.newWidth;
  }

  public setNewWidth(newWidth: number): this {
    this.newWidth = newWidth;
    return this;
  }

  public getOldHeight(): number {
    return this.oldHeight;
  }

  public setOldHeight(oldHeight: number): this {
    this.oldHeight = oldHeight;
    return this;
  }

  public getNewHeight(): number {
    return this.newHeight;
  }

  public setNewHeight(newHeight: number): this {
    this.newHeight = newHeight;
    return this;
  }
}
