import { OverlayRef } from '@angular/cdk/overlay';
import { ElementRef, Injectable } from '@angular/core';
import { ContextMenu } from '@app-modeleditor/components/contextmenu/contextmenu';
import { OverlayService } from '@app-modeleditor/components/lightbox/overlay/overlay.service';
import { ContextMenuComponent } from './contextmenu.component';

@Injectable({
  providedIn: 'root',
})
export class ContextmenuService {
  private menues: ContextMenu[] = [];

  constructor(private popupApi: OverlayService) {}

  create(pos: ElementRef | MouseEvent | HTMLElement, clazz: ContextMenu): OverlayRef {
    if (pos instanceof MouseEvent) {
      pos.preventDefault();
      pos.stopPropagation();
    }
    // close current menu
    this.closeAll();
    const currentMenu: OverlayRef = this.popupApi.create<ContextMenuComponent>(ContextMenuComponent, pos, clazz, {
      backdrop: false,
    }).overlayRef;
    this.menues.push(clazz);
    clazz.setRef(currentMenu);

    return currentMenu;
  }

  /**
   * close all contextmenues
   * @returns boolean
   */
  private closeAll(): boolean {
    if (this.menues.length === 0) {
      return false;
    }
    this.menues.forEach((m: ContextMenu) => {
      m.getRef().dispose();
    });
    this.menues = [];
    return true;
  }

  /**
   * close contextmenu
   * @returns boolean
   */
  public close(): boolean {
    return this.closeAll();
  }
}
