import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@core/material/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { FilterNodeViewerComponent } from './filter-node-viewer.component';

@NgModule({
  imports: [CommonModule, MaterialModule, TranslateModule],
  exports: [FilterNodeViewerComponent],
  declarations: [FilterNodeViewerComponent],
})
export class FilterNodeViewerModule {}
