import { IOverlayData } from '@app-modeleditor/components/lightbox/overlay/overlay-data.interface';
import { Subscription } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { GanttDataRow, GanttDataShift } from '../../data-handler/data-structure/data-structure';
import { DataManipulator } from '../../data-handler/data-tools/data-manipulator';
import { BestGantt } from '../../main';
import { GanttBlockHighlighter } from '../block-highlighter/block-highlighter';
import { BestGanttPlugIn } from '../gantt-plug-in';
import { GanttBrokenConstraintsNavigatorComponent } from './navigator-overlay/navigator-overlay.component';

/**
 * Plug-In to find a specific shift with a specific broken constraint.
 * @class
 * @extends BestGanttPlugIn
 */
export class GanttBrokenConstraintsNavigator extends BestGanttPlugIn {
  private _isOverlayActive: boolean;
  private _currentRowId: string;
  private _overlay: IOverlayData;
  private _shiftDateTimeFormat: string;
  private _automaticNavigation: boolean;
  private _showLeftRightButtons: boolean;
  private _showTitle: boolean;
  private _titleAsDragHandle: boolean;

  private _overlayCloseSubscription: Subscription;
  private _navigationButtonSubscription: Subscription;

  constructor() {
    super();

    this._isOverlayActive = false;
    this._currentRowId = null;
    this._automaticNavigation = true;
    this._showLeftRightButtons = true;
    this._showTitle = false;
    this._titleAsDragHandle = false;

    this._overlayCloseSubscription = null;
    this._navigationButtonSubscription = null;
  }

  public initPlugIn(ganttDiagram: BestGantt): void {
    this.ganttDiagram = ganttDiagram;
    this._shiftDateTimeFormat = `${this.ganttDiagram.getConfig().timeFormat().date} ${
      this.ganttDiagram.getConfig().timeFormat().time
    }`;
  }

  public update(): void {
    if (this._isOverlayActive && this._overlay?.componentRef?.instance) {
      this._overlay.componentRef.instance.setAvailableRows(this._getAvailableRowsById(this._currentRowId));
    }
  }

  public removePlugIn(): void {
    if (this._isOverlayActive && this._overlay?.componentRef?.instance) {
      this._overlay.componentRef.instance.closeOverlay();
    }
  }

  /**
   * Opens the navigation component as Overlay.
   * @param {string} [rowId=null] Navigate only through broken constraints of the row with the specified id (if null, navigate through all rows).
   */
  public openOverlay(rowId: string = null): void {
    this._buildOverlay();
    if (!this._isOverlayActive || !this._overlay?.componentRef?.instance) return;

    this._currentRowId = rowId;
    this._overlay.componentRef.instance.setAvailableRows(this._getAvailableRowsById(this._currentRowId));
  }

  /**
   * Builds and shows the navigator overlay.
   */
  private _buildOverlay(): void {
    if (this._isOverlayActive) return; // overlay already active

    const overlayApi = this.ganttDiagram.getOverlayApi();
    if (!overlayApi) {
      console.warn('No overlay service found.');
      return;
    }

    this._overlay = overlayApi.create(GanttBrokenConstraintsNavigatorComponent, null, this.ganttDiagram, {
      backdrop: false,
    });

    this._overlay.overlayRef.overlayElement.style.pointerEvents = 'none';
    this._overlay.componentRef.instance.setDateTimeFormat(this._shiftDateTimeFormat);
    this._overlay.componentRef.instance.setDragBoundary(this.ganttDiagram.getParentNode());
    this._updateOverlayNavigationMode();
    this._updateOverlayLeftRightButtonMode();
    this._updateOverlayTitleMode();

    this._overlay.componentRef.instance.afterViewInit.pipe(first()).subscribe(() => {
      this._overlay.componentRef.instance.setDragPosition(this._getInitialDragPosition());
    });

    this._isOverlayActive = true;
    this._overlayCloseSubscription = this._overlay.componentRef.instance.afterClosed.subscribe((event) => {
      this._isOverlayActive = false;
      this._overlayCloseSubscription.unsubscribe();
      this._overlayCloseSubscription = null;
      this._navigationButtonSubscription?.unsubscribe();
      this._navigationButtonSubscription = null;
      this._overlay = null;
    });
    this._navigationButtonSubscription = this._overlay.componentRef.instance.onNavigationButtonClick.subscribe(
      (shift) => this._zoomToShift(shift)
    );

    // callbacks to trigger closing of the overlay
    const checkAndCloseOverlay = function () {
      const availableRows = this._getAvailableRowsById(this._currentRowId);
      for (const row of availableRows) {
        if (row.open) return;
      }
      this._overlay.componentRef.instance.closeOverlay();
    };

    this.ganttDiagram
      .getOpenRowHandler()
      .afterShiftRowClosed.pipe(takeUntil(this._overlay.componentRef.instance.afterClosed))
      .subscribe(() => checkAndCloseOverlay());
  }

  /**
   * Calculates the initial position of the overlay.
   * @returns {{x: number; y: number}} Object containing initial x and y.
   */
  private _getInitialDragPosition(): { x: number; y: number } {
    const dragPosition = {
      x: 0,
      y: 0,
    };

    const verticalScrollContainer = this.ganttDiagram.getHTMLStructureBuilder().getVerticalScrollContainer().node();
    const verticalScrollContainerRect = verticalScrollContainer.getBoundingClientRect();
    const navigatorContainerProportions = this._overlay.componentRef.instance.getNavigatorContainerProportions();
    const yAxisProportions = this.ganttDiagram.getNodeProportionsState().getYAxisProportions();
    const verticalScrollbarWidth = this.ganttDiagram.getConfig().verticalScrollbarWidth;
    const shiftContainerWidth = verticalScrollContainerRect.width - yAxisProportions.width - verticalScrollbarWidth;
    const shiftContainerX = verticalScrollContainerRect.x + yAxisProportions.width;

    dragPosition.x = shiftContainerX + 0.5 * (shiftContainerWidth - navigatorContainerProportions.width);
    dragPosition.y =
      verticalScrollContainerRect.y + verticalScrollContainerRect.height - navigatorContainerProportions.height - 10;

    return dragPosition;
  }

  /**
   * Returns all rows belonging to the specified row id (all rows if null).
   * Useful in cases when another plug-in splits rows into multiple.
   * @param rowId Id to find rows for (all rows if null).
   * @returns Array of found rows.
   */
  private _getAvailableRowsById(rowId: string): GanttDataRow[] {
    const availableRows: GanttDataRow[] = [];
    DataManipulator.iterateOverDataSet(this.ganttDiagram.getDataHandler().getOriginDataset().ganttEntries, {
      checkRowId: (child: GanttDataRow) => {
        if (!rowId || rowId === child.id || rowId === child.additionalData?.originalRowId) availableRows.push(child);
      },
    });
    return availableRows;
  }

  /**
   * Zooms to a specified shift & highlight it.
   * @param {GanttDataShift} shift Shift to zoom to.
   */
  private _zoomToShift(shift: GanttDataShift): void {
    if (!shift) return;

    // zoom to shift
    const shiftLengthMs = shift.timePointEnd.getTime() - shift.timePointStart.getTime();
    const shiftPaddingMs = shiftLengthMs * 0.07;
    const startDate = new Date(shift.timePointStart.getTime() - shiftPaddingMs);
    const endDate = new Date(shift.timePointEnd.getTime() + shiftPaddingMs);
    this.ganttDiagram.getXAxisBuilder().zoomToTimeSpan(startDate, endDate, true);

    // highlight shift
    const blockHighlighter: GanttBlockHighlighter = this.ganttDiagram
      .getPlugInHandler()
      .getPlugInsOfType(GanttBlockHighlighter)[0];
    if (!blockHighlighter) {
      console.warn('No block highlighter found.');
      return;
    }
    blockHighlighter.highlightBlocks([shift.id]);
  }

  /**
   * Applies the current navigation mode to the overlay when it is active.
   */
  private _updateOverlayNavigationMode(): void {
    if (this._isOverlayActive || this._overlay?.componentRef?.instance) {
      this._overlay.componentRef.instance.setAutomaticNavigation(this._automaticNavigation);
      this._overlay.componentRef.instance.setShowNavigationButton(!this._automaticNavigation);
      this._overlay.componentRef.instance.setShowShiftCounter(this._automaticNavigation);
    }
  }

  /**
   * Applies the current visibility of the left/right navigation buttons.
   */
  private _updateOverlayLeftRightButtonMode(): void {
    if (this._isOverlayActive || this._overlay?.componentRef?.instance) {
      this._overlay.componentRef.instance.setShowLeftRightButtons(this._showLeftRightButtons);
    }
  }

  private _updateOverlayTitleMode(): void {
    if (this._isOverlayActive || this._overlay?.componentRef?.instance) {
      this._overlay.componentRef.instance.setShowTitle(this._showTitle, this._titleAsDragHandle);
    }
  }

  //
  // GETTER & SETTER
  //

  /**
   * Sets a new navigation mode (automatic navigation or on button click).
   * @param automaticNavigation New navigation mode (true = automaic, false = on button click)
   */
  public setAutomaticNavigation(automaticNavigation: boolean): void {
    this._automaticNavigation = automaticNavigation;
    this._updateOverlayNavigationMode();
  }

  /**
   * Sets a new visibility of the left/right navigation buttons.
   * @param showLeftRightButtons New visibility.
   */
  public setShowLeftRightButtons(showLeftRightButtons: boolean): void {
    this._showLeftRightButtons = showLeftRightButtons;
    this._updateOverlayLeftRightButtonMode();
  }

  public setShowTitle(showTitle: boolean, titleAsDragHandle = false) {
    this._showTitle = showTitle;
    if (!this._showTitle) this._titleAsDragHandle = false;
    else this._titleAsDragHandle = titleAsDragHandle;
    this._updateOverlayTitleMode();
  }
}
