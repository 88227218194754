import { Observable, Subject, take } from 'rxjs';
import { GanttConfig } from '../config/gantt-config';
import { NodeProportionsState } from '../html-structure/node-proportion-state/node-proportion-state';
import { NodeProportionsStateConnector } from '../html-structure/node-proportion-state/node-proportion-state-connector';
import { GanttScrollContainerEvent } from '../html-structure/scroll-container-event';
import { EGanttScrollContainer } from '../html-structure/scroll-container.enum';
import { BestGantt } from '../main';
import { VerticalScrollByOverlayHandler } from './vertical-scroll-by-overlay-handler';

/**
 * Handles vertical scroll in gantt diagram.
 * Also synchronizes the scroll position between Y-axis and shift container.
 * The function divides the scroll events into 3 phases: scrollStart, scroll and scrollEnd
 */
export class VerticalScrollHandler {
  private _verticalScrollContainer: { [id: string]: HTMLDivElement } = {};

  private readonly _verticalScrollByOverlayHandler: VerticalScrollByOverlayHandler = undefined;

  private _scrollStarted = false;
  private _scrollTimeOut: NodeJS.Timeout = undefined;

  private _onScrollVerticalStartSubject = new Subject<GanttScrollContainerEvent<Event>>();
  private _onScrollVerticalUpdateSubject = new Subject<GanttScrollContainerEvent<Event>>();
  private _onScrollVerticalEndSubject = new Subject<GanttScrollContainerEvent<Event>>();

  /**
   * @param _ganttDiagram
   */
  constructor(private readonly _ganttDiagram: BestGantt) {
    this._verticalScrollByOverlayHandler = new VerticalScrollByOverlayHandler(this._ganttDiagram);
  }

  public init(): void {
    for (const scrollContainerId of Object.values(EGanttScrollContainer)) {
      this._verticalScrollContainer[scrollContainerId] = this._ganttDiagram
        .getHTMLStructureBuilder()
        .getVerticalScrollContainer(scrollContainerId)
        .node();

      this._listenToScrollEvent(scrollContainerId);
    }

    this._verticalScrollByOverlayHandler.init();
    this._ganttDiagram.onDestroy.pipe(take(1)).subscribe(() => this._verticalScrollByOverlayHandler.destroy());
  }

  /**
   * Sets a new vertical scroll top position.
   * @param scrollTop
   * @param scrollContainerId
   */
  public setVerticalScrollPos(
    scrollTop: number,
    scrollContainerId: EGanttScrollContainer = EGanttScrollContainer.DEFAULT
  ): void {
    this._verticalScrollContainer[scrollContainerId].scrollTop = scrollTop;
  }

  public validateVerticalScrollPosition(): void {
    if (!this._nodeProportionsState) return;

    for (const scrollContainerId of Object.values(EGanttScrollContainer)) {
      this._correctScrollPosition(scrollContainerId);
    }
  }

  /**
   * Returns the largest possible scroll value.
   * @returns
   */
  private _getMaxScrollPosition(scrollContainerId: EGanttScrollContainer): number {
    const nodeProportionsState = this._getNodeProportionState(scrollContainerId);
    const shiftContainerHeight = nodeProportionsState.getShiftCanvasProportions().height;
    const maxScrollPosition = shiftContainerHeight - nodeProportionsState.getShiftViewPortProportions().height;
    return maxScrollPosition;
  }

  /**
   * Corrects the scroll position in relation to the current top row in viewport.
   */
  private _correctScrollPosition(scrollContainerId: EGanttScrollContainer): void {
    // don't correct during scroll
    if (this._scrollStarted) return;

    const nodeProportionsState = this._getNodeProportionState(scrollContainerId);

    const currentTopRowId = nodeProportionsState.getCurrentTopRowId();
    const currentOriginTopRowId = nodeProportionsState.getCurrentTopOriginRowId();
    const currentScrollPosition = nodeProportionsState.getScrollTopPosition();
    const maxScrollPosition = this._getMaxScrollPosition(scrollContainerId);
    let fallbackRow = false;
    if (currentTopRowId) {
      let row = this._ganttDiagram
        .getRenderDataHandler()
        .getRenderDataYAxis(scrollContainerId)
        .find((row) => row.id === currentTopRowId);
      if (!row) {
        row = this._ganttDiagram
          .getRenderDataHandler()
          .getRenderDataYAxis(scrollContainerId)
          .find((row) => row.id === currentOriginTopRowId); // fallback if there is an existing origin row -> maybe it was a splitted row
        fallbackRow = true;
        if (!row) {
          if (maxScrollPosition < currentScrollPosition) {
            // only check if current scroll position over maximum
            this.setVerticalScrollPos(Math.min(currentScrollPosition, maxScrollPosition), scrollContainerId);
          }
          return;
        } // no row found
      }
      const currentTopRowOffset = fallbackRow ? 0 : nodeProportionsState.getCurrentTopRowOffset();

      const rowY = this._ganttDiagram.getRenderDataHandler().getStateStorage().getYPositionRow(row.id);
      const newScrollPosition = rowY - currentTopRowOffset;
      if (newScrollPosition != currentScrollPosition || maxScrollPosition < newScrollPosition) {
        // only correct if position has actually changed or to small
        this.setVerticalScrollPos(Math.min(newScrollPosition, maxScrollPosition), scrollContainerId);
      }
    }
  }

  private _listenToScrollEvent(scrollContainerId: EGanttScrollContainer) {
    this._verticalScrollContainer[scrollContainerId].onscroll = (event) => {
      this._handleScrollEvent(event, scrollContainerId);
    };
  }

  /**
   * Extracts the scrollTop position.
   * Divides the scroll event into 3 phases. (scrollStart, scroll, scrollEnd)
   * @param scrollEvent
   */
  private _handleScrollEvent(scrollEvent: Event, scrollContainerId: EGanttScrollContainer): void {
    const scrollTop = (scrollEvent.target as HTMLDivElement).scrollTop;
    this._nodeProportionsState.setScrollTopPosition(scrollTop, scrollContainerId);
    const SCROLLENDTIMEOUT = this._ganttConfig.getScrollEndTimeout();
    // scroll start
    if (!this._scrollStarted) {
      this._onScrollStart(scrollContainerId, scrollEvent);
      this._scrollStarted = true;
    }

    // while scrolling
    this._onScroll(scrollContainerId, scrollEvent);
    clearTimeout(this._scrollTimeOut);

    // scroll end
    this._scrollTimeOut = setTimeout(() => {
      this._scrollStarted = false;
      this._onScrollEnd(scrollContainerId, scrollEvent);
    }, SCROLLENDTIMEOUT);
  }

  /**
   * Function executes callbacks that are registered on scroll start event.
   * @param scrollEvent
   */
  private _onScrollStart(scrollContainerId: EGanttScrollContainer, scrollEvent: Event): void {
    this._onScrollVerticalStartSubject.next(new GanttScrollContainerEvent(scrollContainerId, scrollEvent));
  }

  /**
   * Function executes callbacks that are registered on scroll event. (is triggered while scrolling)
   * @param scrollEvent
   */
  private _onScroll(scrollContainerId: EGanttScrollContainer, scrollEvent: Event): void {
    this._onScrollVerticalUpdateSubject.next(new GanttScrollContainerEvent(scrollContainerId, scrollEvent));
  }

  /**
   * Function executes callbacks that are registered on scroll end event.
   * @param scrollEvent
   */
  private _onScrollEnd(scrollContainerId: EGanttScrollContainer, scrollEvent: Event): void {
    this._onScrollVerticalEndSubject.next(new GanttScrollContainerEvent(scrollContainerId, scrollEvent));
  }

  //
  // GETTER & SETTER
  //

  /**
   * Helper getter which returns the gantt config of the current gantt.
   */
  private get _ganttConfig(): GanttConfig {
    return this._ganttDiagram.getConfig();
  }

  /**
   * Helper getter which returns the node proportions state of the current gantt.
   */
  private get _nodeProportionsState(): NodeProportionsState {
    return this._ganttDiagram.getNodeProportionsState();
  }

  /**
   * Helper getter which returns a node proportions state connector for the specified scroll container.
   * @param scrollContainerId Id of the scroll container to return a node proportions state connector for.
   */
  private _getNodeProportionState(scrollContainerId: EGanttScrollContainer): NodeProportionsStateConnector {
    return new NodeProportionsStateConnector(this._nodeProportionsState, scrollContainerId);
  }

  //
  // OBSERVABLES
  //

  public get onScrollVerticalStart(): Observable<GanttScrollContainerEvent<Event>> {
    return this._onScrollVerticalStartSubject.asObservable();
  }

  public get onScrollVerticalUpdate(): Observable<GanttScrollContainerEvent<Event>> {
    return this._onScrollVerticalUpdateSubject.asObservable();
  }

  public get onScrollVerticalEnd(): Observable<GanttScrollContainerEvent<Event>> {
    return this._onScrollVerticalEndSubject.asObservable();
  }
}
