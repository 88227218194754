<mat-form-field class="button-slider-form-field">
  <input
    [disabled]="element.isDisabled() || element.getLowerBound() === element.getUpperBound()"
    [placeholder]="element.getName()"
    (change)="changes($event.target.value)"
    [type]="'number'"
    [min]="element.getLowerBound()"
    [max]="element.getUpperBound()"
    [step]="element.getStepWidth()"
    matInput
    class="button-slider-input"
    [(ngModel)]="InnerValue"
  />
  <button mat-icon-button matPrefix [disabled]="InnerValue <= element.getLowerBound()" (click)="update(-1)">
    <mat-icon>remove</mat-icon>
  </button>
  <button mat-button matSuffix [disabled]="InnerValue >= element.getUpperBound()" (click)="update(1)">
    <mat-icon>add</mat-icon>/ {{ element.getUpperBound() }}
  </button>
</mat-form-field>
