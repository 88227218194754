import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SaxmsSidebarService {
  private currentActiveSidebar: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public getCurrentActiveSidebar(): Observable<any> {
    return this.currentActiveSidebar.asObservable();
  }

  constructor() {}

  public toggle(id: string): void {
    if (this.currentActiveSidebar.getValue() === id) {
      this.currentActiveSidebar.next(null);
    } else {
      this.currentActiveSidebar.next(id);
    }
  }
}
