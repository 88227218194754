import { GanttCanvasShift } from '../../data-handler/data-structure/data-structure';
import { GanttFontSizeCalculator } from '../../font-tools/font-size';
import { EGanttScrollContainer } from '../../html-structure/scroll-container.enum';
import { BestGantt } from '../../main';
import { IndexCardConfig } from './index-card-config';
import { IndexCardExecuter, IndexCardExecuterBuildData } from './index-card-executer';
import { IndexCardTextInside } from './text-creator/text-creator-inside';
import { IIndexCardTextInterface } from './text-creator/text-creator-interface';

/**
 * Responsible for adding text SVG elemetns for index cards.
 * @keywords plugin, index, card, index-card, text, overlay, description
 * @plugin index-card
 */
export class IndexCardTextBuilder {
  private _indexCardTextStrategie: IIndexCardTextInterface = new IndexCardTextInside();

  /**
   * @param _canvas D3 selection of canvas node in which all text will be rendered.
   * @param fontSizeCalc Calculator for all gantt font sizes.
   * @param ganttDiagram
   * @param indexCardExecuter
   * @param scrollContainerId
   */
  constructor(
    private readonly _canvas: d3.Selection<HTMLCanvasElement, undefined, d3.BaseType, undefined>,
    public readonly fontSizeCalc: GanttFontSizeCalculator,
    public readonly ganttDiagram: BestGantt,
    public readonly indexCardExecuter: IndexCardExecuter,
    public readonly scrollContainerId: EGanttScrollContainer = EGanttScrollContainer.DEFAULT
  ) {}

  /**
   * Building function which adds index card text to shifts.
   * @param dataSet
   * @param buildData Dataset with all visible shift rects and current zoom transformation.
   * @param config Building config for text box style.
   */
  public build(
    dataSet: { [id: string]: string[] },
    buildData: IndexCardExecuterBuildData,
    config: IndexCardConfig
  ): void {
    this._clearCanvas();
    this._indexCardTextStrategie.build(dataSet, buildData, config, this);
  }

  private _clearCanvas(): void {
    const shiftViewportProportions = this.ganttDiagram
      .getNodeProportionsState()
      .getShiftViewPortProportions(this.scrollContainerId);
    this._canvas
      .node()
      .getContext('2d')
      .clearRect(0, 0, shiftViewportProportions.width, shiftViewportProportions.height);
  }

  public cleanUp(): void {
    this._indexCardTextStrategie.cleanUp(this);
  }

  /**
   * Removes all index card text elements.
   * Clears text box references.
   */
  public removeAllElements(): void {
    this._clearCanvas();
  }

  /**
   * Changes index Card Text Building Strategie
   * @param strategie The new Strategie to use.
   */
  public changeIndexCardTextStrategie(strategie: IIndexCardTextInterface): void {
    this._indexCardTextStrategie.cleanUp(this);
    this._indexCardTextStrategie = strategie;
    this._indexCardTextStrategie.init(this);
  }

  /**
   * Returns array with all lines that will match inside current shift height.
   * Used by the implementing Strategies.
   * Considers Paddings.
   * @param dataSet Index Card Dataset.
   * @param d The canvas Shift matching set is calculated for.
   * @returns
   */
  public getMatchingLineSet(dataSet: { [id: string]: string[] }, d: GanttCanvasShift): { text: string; id: string }[] {
    const lines = [];
    if (!dataSet[d.id]) return lines;
    for (let i = 0; i < dataSet[d.id].length; i++) {
      lines.push({
        text: dataSet[d.id][i],
        id: d.id,
      });
    }
    return lines;
  }

  /**
   * Returns matching substring for text. Such that SubString + "..." truncates before given x Postion.
   * Used by implementing Strategies.
   * @deprecated Not used as of 2024-02-20.
   * @param text The String that should match to the xPosWrap.
   * @param xPosWrap xPostion text has to wrap before.
   * @param zoom d3 zoom transformation.
   * @param reference Reference to text creator using the strategie.
   * @param config Index Card Config.
   * @param behindYAxis shiftStart behind y Axis.
   * @returns Substring of text.
   */
  public getTruncatedText(
    text: string,
    xPosWrap: number,
    zoom: d3.ZoomTransform,
    config: IndexCardConfig,
    behindYAxis: boolean
  ): string {
    console.warn(`calling deprecated 'getTruncatedText' method`);
    const amount = text.length;
    const maxTextWidth = xPosWrap * zoom.k + (behindYAxis ? zoom.x : 0) - config.padding_right;
    let textWidth = this.fontSizeCalc.getTextWidth(text);

    if (maxTextWidth > textWidth) return text;
    if (maxTextWidth < config.padding_right || textWidth < 5) return '';

    let i = 0;
    textWidth = 0;
    while (textWidth < maxTextWidth) {
      if (i === amount) {
        i = amount;
        break;
      }
      i++;
      textWidth = this.fontSizeCalc.getTextWidth(text.slice(0, i)) + this.fontSizeCalc.getTextWidthTriplePoints();
    }
    i--;
    return i < 2 ? '' : text.slice(0, i) + '...';
  }

  public fittingString(str: string, maxWidth: number, ellipsis: string, ellipsisWidth: number): string {
    let width = this.fontSizeCalc.getTextWidth(str);
    if (width <= maxWidth || width <= ellipsisWidth) {
      return str;
    } else {
      let len = str.length;
      while (width >= maxWidth - ellipsisWidth && len-- > 0) {
        str = str.substring(0, len);
        width = this.fontSizeCalc.getTextWidth(str);
      }
      if (str.length) {
        return str + ellipsis;
      } else {
        return null;
      }
    }
  }

  public getWidthToNextShift(
    buildData: IndexCardExecuterBuildData,
    textParent: GanttCanvasShift,
    textContainerWidth: number,
    config: IndexCardConfig
  ): number {
    let width = textParent.width * buildData.transform.k - config.padding_left;
    const index = buildData.shifts.findIndex((elem) => {
      return textParent.id === elem.id;
    });
    const neighbor = buildData.shifts[index + 1];
    let neighborWidth;

    if (neighbor) {
      if (
        neighbor.y === textParent.y ||
        neighbor.y + this.ganttDiagram.getConfig().getLineTop() === textParent.y ||
        neighbor.y - this.ganttDiagram.getConfig().getLineTop() === textParent.y
      ) {
        neighborWidth =
          neighbor.x * buildData.transform.k -
          textParent.x * buildData.transform.k; /* - this.config.textOverflowLeft() - 2; */
      }
    }

    if (neighborWidth === undefined) {
      return;
    }

    if (!isNaN(neighborWidth)) {
      width = neighborWidth;
    }

    if (width < 1) {
      width = 0; // on very small widths the text-overflow is glitching, so we set a minimum to fix this
    }
    if (width > textContainerWidth) {
      // on very big widths the div disappers, so we set max width to the text container width
      width = textContainerWidth;
    }

    return width;
  }
}
