import { Component, Inject } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';

@Component({
  selector: 'experiment-test-dialog',
  templateUrl: 'toolbar-dialog.component.html',
  styleUrls: ['toolbar-dialog.component.scss'],
})
export class ExperimentTestDialog {
  public styleRef: any;
  constructor(public dialogRef: MatDialogRef<ExperimentTestDialog>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  onClick(value: string) {
    this.dialogRef.close(value);
  }

  onCancel() {
    this.dialogRef.close();
  }
}
