import { EntryElement } from '@app-modeleditor/components/entry-collection/entry-element';
import { EntryElementValue } from '@app-modeleditor/components/entry-collection/entry-element-value';
import { EFieldType } from '@app-modeleditor/components/entry-collection/field-type.enum';
import { EGanttShiftColorMode } from '@gantt/public-api';
import { ResourcesDataService } from 'frontend/src/dashboard/gantt/gantt/dock/views/resources/resources-data.service';
import { GanttLibService } from 'frontend/src/dashboard/gantt/gantt/gantt-lib.service';
import { Gantt_General } from '../../general.gantt.component';
import { GanttEssentialPlugIns } from '../../plugin/e-gantt-essential-plugins';
import { GanttPluginHandlerService } from '../../plugin/gantt-plugin-handler.service';
import { GanttColorizerByAttributePlugIn } from '../../plugin/plugin-list/block-colorizer/by-attribute/colorizer-by-attribute';

export class ResourceColorizeToggleElement {
  private _resourcesDataService: ResourcesDataService;
  private _ganttLibService: GanttLibService;
  private _pluginHandlerService: GanttPluginHandlerService;

  constructor(private _scope: Gantt_General) {
    this._resourcesDataService = this._scope.ganttResourcesDataService;
    this._ganttLibService = this._scope.ganttLibService;
    this._pluginHandlerService = this._scope.ganttPluginHandlerService;
  }

  getGroup(): EntryElement[] {
    const group: EntryElement[] = [];

    const machineCheckbox = new EntryElement()
      .setFieldType(EFieldType.CHECK_BOX)
      .setAlwaysEnabled(true)
      .onChanges((e: EntryElementValue) => this._handleGanttColorMode(e.getValue(), EGanttResourceCheckboxType.MACHINE))
      .setValue(new EntryElementValue().setValue(this._resourcesDataService.colorizeMachine))
      .setName('Maschinen');

    const workerCheckbox = new EntryElement()
      .setFieldType(EFieldType.CHECK_BOX)
      .setAlwaysEnabled(true)
      .onChanges((e: EntryElementValue) => this._handleGanttColorMode(e.getValue(), EGanttResourceCheckboxType.WORKER))

      .setValue(new EntryElementValue().setValue(this._resourcesDataService.colorizeMachine))
      .setName('Arbeiter');

    const toggleColorize = new EntryElement()
      .setFieldType(EFieldType.SLIDE_TOGGLE)
      .setAlwaysEnabled(true)
      .onChanges((e: EntryElementValue) => this._handleColorization(e.getValue()))
      .setValue(new EntryElementValue().setValue(this._resourcesDataService.applyColorization))
      .setName('Einfärbung anwenden');

    group.push(machineCheckbox);
    group.push(workerCheckbox);
    group.push(toggleColorize);

    return group;
  }

  private _handleGanttColorMode(check?: boolean, type?: EGanttResourceCheckboxType): void {
    if (type === EGanttResourceCheckboxType.MACHINE) {
      this._resourcesDataService.colorizeMachine = check;
    } else if (type === EGanttResourceCheckboxType.WORKER) {
      this._resourcesDataService.colorizeWorker = check;
    }

    if (this._resourcesDataService.applyColorization) {
      if (this._resourcesDataService.colorizeMachine && this._resourcesDataService.colorizeWorker) {
        this._ganttLibService.bestGantt
          .getConfig()
          .setShiftBuildingColorMode(EGanttShiftColorMode.SHOW_MULTIPLE_COLORS);
      } else if (!this._resourcesDataService.colorizeMachine && !this._resourcesDataService.colorizeWorker) {
        this._ganttLibService.bestGantt.getConfig().setShiftBuildingColorMode(EGanttShiftColorMode.SHOW_DEFAULT_COLOR);
      } else if (!this._resourcesDataService.colorizeWorker) {
        this._ganttLibService.bestGantt.getConfig().setShiftBuildingColorMode(EGanttShiftColorMode.SHOW_FIRST_COLOR);
      } else if (this._resourcesDataService.colorizeWorker && !this._resourcesDataService.colorizeMachine) {
        this._ganttLibService.bestGantt.getConfig().setShiftBuildingColorMode(EGanttShiftColorMode.SHOW_SECOND_COLOR);
      }
    }

    this._ganttLibService.bestGantt.rerenderShiftsVertical();
  }

  private _handleColorization(check: boolean): void {
    this._resourcesDataService.applyColorization = check;
    const colorizerPlugin = this._pluginHandlerService.getPlugInById(
      GanttEssentialPlugIns.BlockColorizeByAttributePlugIn
    ) as GanttColorizerByAttributePlugIn;

    if (check) {
      colorizerPlugin.deactivatePlugin();
      this._handleGanttColorMode();
    } else {
      colorizerPlugin.activatePlugin();
      this._ganttLibService.bestGantt.getConfig().setShiftBuildingColorMode(EGanttShiftColorMode.SHOW_DEFAULT_COLOR);
      this._ganttLibService.bestGantt.rerenderShiftsVertical();
    }
  }
}

export enum EGanttResourceCheckboxType {
  MACHINE,
  WORKER,
}
