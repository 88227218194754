import { EGanttInstance } from '@gantt/public-api';
import { GanttLibService } from 'frontend/src/dashboard/gantt/gantt/gantt-lib.service';
import { SaxMsBestGanttActiveSubmenuEntryElementSetting } from 'frontend/src/dashboard/gantt/gantt/saxms-best-gantt.settings';
import { GanttTemplateData } from 'frontend/src/dashboard/gantt/helper/gantt';
import { Observable, of } from 'rxjs';
import { GeneralGanttActionHandler } from '../../../action-handling/action-handler';
import { GanttChildren, IGanttTemplateValue } from '../../../generator/gantt-input.data';
import { GanttResponseHandler } from '../../../response/response-handler';
import { ExternalGanttPlugin } from '../../external-plugin';
import { GanttPluginHandlerService } from '../../gantt-plugin-handler.service';
import { GanttResponseAdditionalMarkers } from './response/block-additional-markers-response';

const GanttPlugInShiftTimeStamps = 'gantt-plugin-additional-markers';

/**
 * PlugIn-Wrapper for GanttShowShiftTimeStamps.
 * Provides possibility to show additional timepointmarkers for each shift block.
 */
export class GanttBlockAdditionalMarkersPlugIn extends ExternalGanttPlugin {
  constructor(
    protected _ganttPluginHandlerService: GanttPluginHandlerService,
    protected _ganttLibService: GanttLibService,
    protected _actionHandler: GeneralGanttActionHandler,
    protected _responseHandler: GanttResponseHandler
  ) {
    super(_ganttPluginHandlerService, _ganttLibService, _actionHandler);
  }

  /**
   * Extracts all additional marker data for shift blocks.
   * @param templateData
   * @param responseData
   */
  public onInit(templateData: GanttTemplateData, responseData: IGanttTemplateValue) {
    this.addPlugIn(
      GanttPlugInShiftTimeStamps,
      this._ganttLibService.ganttInstanceService.getInstance(EGanttInstance.SHOW_SHIFT_TIME_STAMP)
    );
    this.insertAdditionalMarkersByBackendData(responseData?.hierarchicalPlan?.ganttEntries);
    const shiftTimeStampsPlugin = this.getPlugInById(GanttPlugInShiftTimeStamps);
    this._responseHandler.addResponse(GanttResponseAdditionalMarkers, shiftTimeStampsPlugin);
  }

  public onDestroy(): void {}

  public onAction(action: any) {}

  public executeAction(action: any): Observable<any> {
    return of(null);
  }

  /**
   * Extracts additional markers from backend block data.
   * @param response Hierarchical plan from backend.
   */
  public insertAdditionalMarkersByBackendData(ganttEntries: GanttChildren[] = []): void {
    if (!ganttEntries?.length) return;
    const mapper = this._ganttLibService.backendToGanttOriginInputMapper;
    this._ganttLibService.ganttInstanceService.getInstance(EGanttInstance.DATA_MANIPULATOR).iterateOverDataSet(
      ganttEntries,
      {
        extractMarkers: (child) => {
          for (const block of child.blocks || []) {
            if (!block || !block.timestamps) return;
            const allTimeStamps: IGanttBlockAdditionalMarkersPlugInData[] = [];
            for (const timeStampKey in block.timestamps) {
              try {
                allTimeStamps.push({
                  color: block.timestamps[timeStampKey],
                  timePoint: new Date(parseInt(timeStampKey)),
                  label: null,
                });
              } catch (e) {
                console.warn(e);
              }
            }
            mapper.getShiftClonesByShiftId(block.id).forEach((id) => {
              this.getPlugInById(GanttPlugInShiftTimeStamps).setAdditionalTimeStamps(id, allTimeStamps);
            });
          }
        },
      },
      null,
      'children',
      null
    );
  }

  public injectSettings(submenuElements: SaxMsBestGanttActiveSubmenuEntryElementSetting[]): void {}
}

export interface IGanttBlockAdditionalMarkersPlugInData {
  color: string;
  timePoint: Date;
  label: string;
}
