import { Action } from '@app-modeleditor/components/button/action/action';
import { ContextMenuItem } from '@app-modeleditor/components/contextmenu/context-menu-item';
import { ContextMenu } from '@app-modeleditor/components/contextmenu/contextmenu';
import {
  EGanttInstance,
  GanttCanvasShift,
  GanttDataShift,
  GanttScrollContainerEvent,
  GanttYAxisContextMenuEvent,
} from '@gantt/public-api';
import { GanttActionService } from 'frontend/src/dashboard/gantt/general/gantt-action.service';
import { GanttBlockingIntervalsPlugIn } from 'frontend/src/dashboard/gantt/general/plugin/plugin-list/blocking-intervals/blocking-intervals.plugin';
import { IGanttTimePeriodMouseOverResult } from 'frontend/src/dashboard/gantt/general/plugin/plugin-list/blocking-intervals/gantt-template-data-attribute-mapping.interface';
import { GanttTemplateData } from 'frontend/src/dashboard/gantt/helper/gantt';
import { of } from 'rxjs';
import { takeUntil, takeWhile } from 'rxjs/operators';
import { GanttEssentialPlugIns } from '../../../plugin/e-gantt-essential-plugins';
import { GanttOverlappingShiftsPlugIn } from '../../../plugin/plugin-list/overlapping-shifts/overlapping-shifts';
import { IGanttBlock, IGanttTemplateValue } from '../../gantt-input.data';
import { GanttPredefinedSetting } from '../predefined-setting';

const GanttContextMenuRegistration = 'GanttContextMenuRegistration';
const GanttAreaOverlayExecuter = 'gantt-plugin-area-overlay-executer';

export class GanttContextMenuHandler extends GanttPredefinedSetting {
  private _clickedBlock: IGanttBlock = null;
  private _customRowContextMenuEntries: IGanttCustomContextMenuItemData[] = [];

  public onInit(
    templateData: GanttTemplateData,
    ganttResponseData: IGanttTemplateValue,
    contextMenuService: GanttActionService
  ): void {
    // build context menu only if data exists

    const ganttDiagram = this.ganttLibService.bestGantt;

    // context menu for rows
    ganttDiagram
      .getYAxisFacade()
      .onContextMenu.pipe(takeUntil(ganttDiagram.onDestroy))
      .subscribe((event) => {
        this.ganttLibService.ngZone.run(() => this._handleRowContextMenu(templateData, contextMenuService, event));
      });

    ganttDiagram
      .getShiftFacade()
      .rowOnContextMenu()
      .pipe(takeWhile(() => this.ganttLibService.isAlive))
      .subscribe((eventData) => {
        this.ganttLibService.ngZone.run(() => this._handleRowContextMenu(templateData, contextMenuService, eventData)); // run inside angular for change detection
      });

    // double click action for blocking intervals
    ganttDiagram
      .getShiftFacade()
      .canvasDoubleClick()
      .pipe(takeWhile(() => this.ganttLibService.isAlive))
      .subscribe((event) => this.handleBlockingIntervalDoubleClickAction(templateData, contextMenuService, event));

    // context menu for shifts
    if (
      !templateData.getContextmenu() ||
      !templateData.getContextmenu().getContextMenuItems() ||
      templateData.getContextmenu().getContextMenuItems().length == 0
    ) {
      return;
    }
    ganttDiagram
      .getShiftFacade()
      .shiftOnDoubleClick()
      .pipe(takeWhile(() => this.ganttLibService.isAlive))
      .subscribe(() => {
        this._contextMenuActionHandling(templateData);
        if (this._clickedBlock) {
          this.ganttLibService.ngZone.run(() =>
            contextMenuService.executeDoubleClickAction(templateData, this._clickedBlock?.sIds)
          ); // run inside angular for change detection
        }
      });

    ganttDiagram.addSelectShiftsByRightClickCallback(GanttContextMenuRegistration, (shiftData) => {
      this._contextMenuActionHandling(templateData);
      if (this._clickedBlock) {
        this.ganttLibService.ngZone.run(() =>
          contextMenuService.openDefault(
            shiftData.event,
            templateData.getContextmenu()?.copy(ContextMenu),
            this._clickedBlock?.sIds,
            true
          )
        ); // run inside angular for change detection
      }
    });
  }

  /**
   * Handles creation of a ctx menu by event for rows.
   */
  private _handleRowContextMenu(
    templateData: GanttTemplateData,
    contextMenuService: GanttActionService,
    eventData: GanttScrollContainerEvent<GanttYAxisContextMenuEvent>
  ): void {
    const blockingIntervalPlugIn: GanttBlockingIntervalsPlugIn = this.ganttPluginHandlerService.getEssentialPlugIn(
      GanttEssentialPlugIns.BlockingIntervalPlugIn
    );
    const hitBlockingIntervals = blockingIntervalPlugIn.getTimePeriodsByMouseEvent(
      new GanttScrollContainerEvent(eventData.source, eventData.event.event)
    );

    // A blocking interval has been hit instead of row
    if (hitBlockingIntervals.length) {
      if (hitBlockingIntervals.length > 1) {
        // select one block from multiple blocks
        eventData.event.event.preventDefault();
        blockingIntervalPlugIn
          .handleMultipleIntervalSelection(hitBlockingIntervals)
          .subscribe((selectedInterval: IGanttTimePeriodMouseOverResult) => {
            if (selectedInterval) {
              this.handleBlockingIntervalContextMenu(
                templateData,
                contextMenuService,
                eventData,
                selectedInterval.block.id,
                selectedInterval.interval.additionalData.type,
                selectedInterval.interval.additionalData.ganttTimePeriodGroupIntervalInputId
              );
            }
          });
      } else {
        // single block was hit
        const lastBlock = hitBlockingIntervals[hitBlockingIntervals.length - 1];

        this.handleBlockingIntervalContextMenu(
          templateData,
          contextMenuService,
          eventData,
          lastBlock.block.id,
          lastBlock.interval.additionalData.type,
          lastBlock.interval.additionalData.ganttTimePeriodGroupIntervalInputId
        );
      }
      return;
    }

    const rowID = eventData?.event?.rowData?.originalResource
      ? eventData?.event?.rowData?.originalResource
      : eventData?.event?.rowData?.id;
    if (!rowID) {
      return;
    }
    const backendRow = templateData.getEntryByIdFromHierarchicalPlan(rowID);
    templateData.setSelectedGanttEntry(backendRow);
    const staticItems = this._getStaticRowContextMenuItems(eventData);

    contextMenuService.openDefault(
      eventData.event.event,
      templateData.getContextmenu()?.copy(ContextMenu),
      backendRow?.sIds,
      false,
      staticItems
    );
  }

  /**
   * Handles creation of a ctx menu by event for blocking intervals.
   * @param templateData Reference to the gantt template data.
   * @param contextMenuService Reference to the gantt context menu service.
   * @param eventData Data of the event which triggered the opening of the context menu.
   * @param intervalId Id of the blocking interval to open the context menu for.
   * @param intervalType Type of the blocking interval to open the context menu for.
   * @param intervalGroupId Group id of the blocking interval to open the context menu for.
   */
  private handleBlockingIntervalContextMenu(
    templateData: GanttTemplateData,
    contextMenuService: GanttActionService,
    eventData: GanttScrollContainerEvent<GanttYAxisContextMenuEvent>,
    intervalId: string,
    intervalType: string,
    intervalGroupId: string
  ) {
    this._applyBlockingIntervalSelection(templateData, intervalId, intervalType, intervalGroupId);

    const blockingIntervalPlugIn: GanttBlockingIntervalsPlugIn = this.ganttPluginHandlerService.getEssentialPlugIn(
      GanttEssentialPlugIns.BlockingIntervalPlugIn
    );
    const blockData = blockingIntervalPlugIn.getPeriodById(intervalId);
    const contextMenu = blockingIntervalPlugIn.getContextMenuByIntervalType(intervalType);

    contextMenuService.openDefault(eventData.event.event, contextMenu, blockData?.sIds, false);
  }

  /**
   * Handles the execution of the double click action of a double-clicked blocking interval.
   * @param templateData Reference to the gantt template data.
   * @param contextMenuService Reference to the gantt context menu service.
   * @param event Data of the double click event which triggered the execution of the double click action.
   */
  private handleBlockingIntervalDoubleClickAction(
    templateData: GanttTemplateData,
    contextMenuService: GanttActionService,
    event: GanttScrollContainerEvent<MouseEvent>
  ): void {
    const blockingIntervalPlugIn: GanttBlockingIntervalsPlugIn = this.ganttPluginHandlerService.getEssentialPlugIn(
      GanttEssentialPlugIns.BlockingIntervalPlugIn
    );
    const hitBlockingIntervals = blockingIntervalPlugIn.getTimePeriodsByMouseEvent(event);

    // A blocking interval has been hit instead of row
    if (hitBlockingIntervals.length) {
      if (hitBlockingIntervals.length > 1) {
        // select one block from multiple blocks
        event.event.preventDefault();
        blockingIntervalPlugIn
          .handleMultipleIntervalSelection(hitBlockingIntervals)
          .subscribe((selectedInterval: IGanttTimePeriodMouseOverResult) => {
            if (selectedInterval) {
              this._applyBlockingIntervalSelection(
                templateData,
                selectedInterval.block.id,
                selectedInterval.interval.additionalData.type,
                selectedInterval.interval.additionalData.ganttTimePeriodGroupIntervalInputId
              );

              const blockData = blockingIntervalPlugIn.getPeriodById(selectedInterval.block.id);
              const contextMenu = blockingIntervalPlugIn.getContextMenuByIntervalType(
                selectedInterval.interval.additionalData.type
              );
              contextMenuService.executeDoubleClickActionOnContextMenu(contextMenu, blockData?.sIds);
            }
          });
      } else {
        // single block was hit
        const lastBlock = hitBlockingIntervals[hitBlockingIntervals.length - 1];

        this._applyBlockingIntervalSelection(
          templateData,
          lastBlock.block.id,
          lastBlock.interval.additionalData.type,
          lastBlock.interval.additionalData.ganttTimePeriodGroupIntervalInputId
        );

        const blockData = blockingIntervalPlugIn.getPeriodById(lastBlock.block.id);
        const contextMenu = blockingIntervalPlugIn.getContextMenuByIntervalType(lastBlock.interval.additionalData.type);
        contextMenuService.executeDoubleClickActionOnContextMenu(contextMenu, blockData?.sIds);
      }
    }
  }

  /**
   * Applies the specified blocking interval as selected blocking interval in the gantt template data.
   * @param templateData Reference to the gantt template data.
   * @param intervalId Id of the blocking interval to be selected.
   * @param intervalType Type of the blocking interval to be selected.
   * @param intervalGroupId Group id of the blocking interval to be selected.
   */
  private _applyBlockingIntervalSelection(
    templateData: GanttTemplateData,
    intervalId: string,
    intervalType: string,
    intervalGroupId: string
  ): void {
    const blockingIntervalPlugIn: GanttBlockingIntervalsPlugIn = this.ganttPluginHandlerService.getEssentialPlugIn(
      GanttEssentialPlugIns.BlockingIntervalPlugIn
    );
    const blockData = blockingIntervalPlugIn.getPeriodById(intervalId);

    this.ganttLibService.bestGantt.getShiftFacade().getTooltipBuilder().removeAllTooltips();

    // set template data
    templateData.setTargetRow(blockData.yId);
    const mappedId = blockingIntervalPlugIn.intervalMapper.getOriginIdByGeneratedId(blockData.id) || blockData.id;

    templateData.setSelectedblock({
      // TODO: must be removed as soon as the backend uses the selectedTimePeriodInput and selectedTimePeriodInputGroup attribute (14.06.21)
      id: mappedId,
      end: blockData.dateEnd.getTime(),
      start: blockData.dateStart.getTime(),
      ganttTimePeriodGroupIntervalInputId: intervalGroupId,
    });

    templateData.setSelectedTimePeriodInput({
      id: mappedId,
      groupId: intervalGroupId,
      type: intervalType,
      end: blockData.dateEnd.getTime(),
      start: blockData.dateStart.getTime(),
    });

    templateData.setSelectedTimePeriodInputGroupId(intervalGroupId);
  }

  private _getStaticRowContextMenuItems(
    eventData: GanttScrollContainerEvent<GanttYAxisContextMenuEvent>
  ): ContextMenuItem[] {
    const ganttDiagram = this.ganttLibService.bestGantt;
    const overlapPlugIn: GanttOverlappingShiftsPlugIn = this.ganttPluginHandlerService.getEssentialPlugIn(
      GanttEssentialPlugIns.OverlappingShiftsPlugIn
    );
    const staticContextMenuItems: ContextMenuItem[] = [];

    // create overlapping blocks item
    const predefinedOverlappingCB = () => {
      const overlapPlugIn: GanttOverlappingShiftsPlugIn = this.ganttPluginHandlerService.getEssentialPlugIn(
        GanttEssentialPlugIns.OverlappingShiftsPlugIn
      );
      const originRowData = this.ganttLibService.ganttInstanceService
        .getInstance(EGanttInstance.Y_AXIS_DATA_FINDER)
        .getRowById(ganttDiagram.getDataHandler().getOriginDataset().ganttEntries, eventData.event.rowData.id);
      if (!originRowData.data || !originRowData.data.additionalData) return;

      overlapPlugIn.toggleNotAffectedRows(originRowData.data.additionalData.originalRowId, true);
      // contextMenuService.closeCtx();
      return of(null);
    };

    const label = overlapPlugIn.isRowAffected(eventData?.event?.rowData?.id)
      ? '@block-overlapping-on@'
      : '@block-overlapping-off@';

    const overlappingBlocksItem = new ContextMenuItem()
      .setId('predefined_overlapping')
      .setName(label)
      .chainActions(new Action().setCb(predefinedOverlappingCB))
      .setDisabled(false);

    staticContextMenuItems.push(overlappingBlocksItem);

    // build custom items
    for (const entry of this._customRowContextMenuEntries) {
      const contextMenuItem = new ContextMenuItem()
        .setId(entry.id)
        .setName(entry.label)
        .chainActions(new Action().setCb(entry.cb.bind(this, eventData)))
        .setDisabled(entry.disabled);
      staticContextMenuItems.push(contextMenuItem);
    }

    return staticContextMenuItems;
  }

  /**
   * Handles misc things for context menu creation.
   */
  private _contextMenuActionHandling(templateData: GanttTemplateData): void {
    const ganttDiagram = this.ganttLibService.bestGantt;
    this._clickedBlock = null;
    const selectedCanvasShifts = ganttDiagram.getSelectionBoxFacade().getSelectedShifts();
    this._saveBlockSelectionInTemplateData(selectedCanvasShifts, templateData);
    const selectedShifts: GanttDataShift[] = [];

    for (const canvasShift of selectedCanvasShifts) {
      const foundShift = this.ganttLibService.ganttInstanceService
        .getInstance(EGanttInstance.SHIFT_DATA_FINDER)
        .getShiftById(ganttDiagram.getDataHandler().getOriginDataset().ganttEntries, canvasShift.id);
      if (!foundShift?.shift) continue;
      selectedShifts.push(foundShift.shift);
    }

    if (this._checkForRestrictions(selectedShifts)) {
      this._clickedBlock = templateData.getBlockByIdFromHierarchicalPlan(
        selectedShifts[0].id,
        this.ganttLibService.backendToGanttOriginInputMapper
      );
    }
  }

  /**
   * Checks if context menu can be displayed. Returns true if can be displayed.
   */
  private _checkForRestrictions(selectedShifts: GanttDataShift[]): boolean {
    const ganttDiagram = this.ganttLibService.bestGantt;
    const noEditableIntervals = ganttDiagram.plugInHandler.getPlugIns()[GanttAreaOverlayExecuter].overlayData;

    // @Todo Nils: anpassen für den Fall dass zwei Sperrzeiten aneinander grenzen und die Schicht in beiden Grenzen drin liegt [///][///]
    // Schicht:14:00 - 20:00 || Sperr-A: 10:00 - 16:00 || Sperr-B 16:00 - 23 Uhr --> nicht editierbar
    // Schicht:14:00 - 20:00 || Sperr-A: 10:00 - 16:00 || Sperr-B 16:01 - 23 Uhr --> editierbar (da eine Minute drin liegt)

    return !selectedShifts.find((shift) => {
      const match = noEditableIntervals.find(
        (interval) =>
          !(
            shift.timePointStart.getTime() < interval.timeStart.getTime() ||
            shift.timePointEnd.getTime() > interval.timeEnd.getTime()
          )
      );
      return match;
    });
  }

  /**
   * Saves selected blocks into template data.
   */
  private _saveBlockSelectionInTemplateData(
    selectedCanvasShifts: GanttCanvasShift[],
    templateData: GanttTemplateData
  ): void {
    if (selectedCanvasShifts.length == 0) return;

    // add selected block id
    if (!templateData.getSelectedBlock())
      templateData.setSelectedBlock({}, this.ganttLibService.backendToGanttOriginInputMapper);
    templateData.setSelectedBlock(
      {
        id: selectedCanvasShifts[0].id,
      },
      this.ganttLibService.backendToGanttOriginInputMapper
    );
    // ? templateData.selectedBlock.id = selectedCanvasShifts[0].id;

    // add all shifts
    const selectedShifts: GanttDataShift[] = [];

    for (const canvasShift of selectedCanvasShifts) {
      const foundShift = this.ganttLibService.ganttInstanceService
        .getInstance(EGanttInstance.SHIFT_DATA_FINDER)
        .getShiftById(this.ganttLibService.bestGantt.getDataHandler().getOriginDataset().ganttEntries, canvasShift.id);
      if (!foundShift?.shift) continue;
      selectedShifts.push(foundShift.shift);
    }

    templateData.setSelectedValues(
      selectedShifts.length === 0 ? false : selectedShifts,
      this.ganttLibService.backendToGanttOriginInputMapper
    );
  }

  /**
   * Adds a new custom context menu item to row context menu.
   * @param id Id of the new item.
   */
  public addCustomRowContextMenuItem(
    id: string,
    label: string,
    cb: (event: GanttScrollContainerEvent<GanttYAxisContextMenuEvent>) => void,
    cbBindRef: unknown,
    disabled = false
  ): void {
    const foundIdIndex = this._customRowContextMenuEntries.indexOf(
      this._customRowContextMenuEntries.find((entry) => entry.id === id)
    );
    if (foundIdIndex >= 0) {
      console.warn(`Item with id ${id} already exists and will be overwritten!`);
      this._customRowContextMenuEntries.splice(foundIdIndex, 1);
    }
    this._customRowContextMenuEntries.push({
      id: id,
      label: label,
      disabled: disabled,
      cb: cb,
      cbBindRef: cbBindRef,
    });
  }

  /**
   * Removes a custom context menu item from row context menu.
   * @param id Id of the custom item.
   */
  public removeCustomRowContextMenuItem(id: string): void {
    const foundIdIndex = this._customRowContextMenuEntries.indexOf(
      this._customRowContextMenuEntries.find((entry) => entry.id === id)
    );
    if (foundIdIndex < 0) {
      console.warn(`No item with id ${id} found!`);
      return;
    }
    this._customRowContextMenuEntries.splice(foundIdIndex, 1);
  }
}

export interface IGanttCustomContextMenuItemData {
  id: string;
  label: string;
  disabled: boolean;
  cb: (eventData: GanttScrollContainerEvent<GanttYAxisContextMenuEvent>) => void;
  cbBindRef: unknown;
}
