import { EntryElement } from '@app-modeleditor/components/entry-collection/entry-element';
import { EntryElementValue } from '@app-modeleditor/components/entry-collection/entry-element-value';
import { EFieldType } from '@app-modeleditor/components/entry-collection/field-type.enum';

/**
 * Enabeling comparison of "EntryElementValue"s because a MULTI_OBJECT_SELECTOR would be interpreted
 * similarly being undefined or having an array of all options.
 * This is mapping all options value to undefined for all other values it is keeping the value.
 * @param value EntryElementValue
 * @returns undefined if all options selected otherwise the input value (if not all options selected or value of a different type)
 */
export const normalizeMultiObject = (value: any, entryElement: EntryElement) => {
  if (!Array.isArray(value)) {
    return value;
  }
  if (entryElement.getFieldType() === EFieldType.MULTI_OBJECT_SELECTOR) {
    const availableOptionsLength = entryElement.getValue<EntryElementValue>().getAvailableValues<any[]>().length;

    if (availableOptionsLength === value.length) {
      return undefined;
    }
  }
  return value;
};
