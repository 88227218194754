/**
 * Class for all (GUI related) search operations insde the node network.
 */
export class NodeNetworkSearchNode {
  /**
   * Function to find given node inside the whole dataset.
   * @param id Id of node to find.
   * @param nodeDataset Node dataset.
   */
  public getNodeById(id: string, nodeDataset: any): any {
    if (!nodeDataset.fields || nodeDataset.fields.length == 0) return;
    return nodeDataset.fields.find((nodeData) => nodeData.id == id);
  }

  /**
   * Extracts autocomplete suggestions.
   * @param query Freetext query for search input field.
   * @param nodeDataset Node network dataset.
   * @param maxItems Max count of auto complete list items.
   */
  public getNodesByQuery(query: string, nodeDataset: any, maxItems: number): any[] {
    if (!nodeDataset.fields || nodeDataset.fields.length == 0) return [];
    let counter = 0;
    return nodeDataset.fields.filter((nodeData, index) => {
      if (nodeData.label.toLowerCase().indexOf(query) != -1 && counter < maxItems) {
        counter++;
        return true;
      }
      return false;
    });
  }
}
