import {
  IGanttAttributeMapping,
  IGanttLegend,
  IGanttResourceEntry,
  IGanttResourceEntryAdditionalDetails,
  IGanttResourceGroup,
  IGanttResourcePlan,
} from 'frontend/src/dashboard/gantt/general/generator/gantt-input.data';
import { IResourceElement, IResourceElementAttribute } from './components/resource-element/resource-element.interface';
import { IResourceGroup } from './components/resource-element/resource-group.inerface';
import { IStatusLegendEntry } from './components/status-legend/status-legend.interface';
import { IResourcesData } from './resources-data.interface';

export class ResourcesBackendToFrontendMapper {
  public mapDataSet(
    backendResourcesData: IGanttResourcePlan,
    resourceAttributeMapping: IGanttAttributeMapping
  ): IResourcesData {
    const dataSet: IResourcesData = {
      id: backendResourcesData.readableClassName,
      name: 'Ressourcen',
      statusLegendData: this.getStatusLegendData(backendResourcesData.ganttLegend),
      resources: this._getResourceGroups(backendResourcesData.resourceGroups, resourceAttributeMapping),
    };
    return dataSet;
  }

  private _mapResourceElement(
    backendResource: IGanttResourceEntry,
    resourceAttributeMapping: IGanttAttributeMapping
  ): IResourceElement {
    const frontendResource: IResourceElement = {
      id: backendResource.id,
      name: backendResource.name,
      color: backendResource.color,
      attributes: this._getResourceElementAttributes(
        backendResource.details.additionalDetails,
        resourceAttributeMapping
      ),
    };
    return frontendResource;
  }

  private _getResourceGroups(
    backendResourceGroups: IGanttResourceGroup[],
    resourceAttributeMapping: IGanttAttributeMapping
  ): IResourceGroup[] {
    const groups: IResourceGroup[] = backendResourceGroups.map((group) => {
      const resources: IResourceElement[] = group.resourceEntries.map((backendResource) =>
        this._mapResourceElement(backendResource, resourceAttributeMapping)
      );
      return {
        id: group.name,
        name: group.name,
        elements: resources,
        icon: group.icon,
      };
    });

    return groups;
  }

  private _getResourceElementAttributes(
    additionalDetails: IGanttResourceEntryAdditionalDetails,
    resourceAttributeMapping: IGanttAttributeMapping
  ): IResourceElementAttribute[] {
    const attributes: IResourceElementAttribute[] = [];
    for (const index in additionalDetails) {
      attributes.push({
        property: resourceAttributeMapping[index].localization,
        value: additionalDetails[index].t2.toString(),
        showInTooltip: resourceAttributeMapping[index].showInTooltip,
      });
    }
    return attributes;
  }

  private getStatusLegendData(resourceLegend: IGanttLegend): IStatusLegendEntry[] {
    const status: IStatusLegendEntry[] = [];

    if (resourceLegend?.legendBlocks?.length === 0) {
      return status;
    }

    resourceLegend.legendBlocks.forEach((entry) =>
      status.push({
        id: entry.name,
        text: entry.name,
        color: entry.color,
      })
    );

    return status;
  }
}
