import { IFilterNode } from './filter-tree';
import { EConnectionType } from './connection-type.enum';
import { IGanttBlockAttributeData } from './attribute-data.interface';
import { EFilterOperator } from '@app-modeleditor/components/filter-element/filter-operator.enum';
import { EGanttBlockAttributeDataType } from './attribute-data-types.enum';
import { GanttRowAdditionalDataMapper } from '../../../generator/mapper/gantt-row-additional-data.mapper';

/**
 * Converts a given filter node into a string.
 */
export class FilterNodeToStringConverter {
  private attributeData: IGanttBlockAttributeData[] = [];

  // Object of comparator signs to be displayed
  private comparatorSigns = {
    begins_with: 'beginnt mit',
    between: 'zwischen',
    by_value: 'hat den Wert',
    contains: 'enthält',
    empty: 'ist leer',
    ends_with: 'endet auf',
    eq: '=', // Equal
    gt: '>', // Greater than
    gte: '≥', // Greater than or equal
    lt: '<', // Less than
    lte: '≤', // Less than or equal
    none: ': kein Filter', // None(no filter)
    not_between: 'nicht zwischen',
    not_contains: 'enthält nicht',
    not_empty: 'nicht leer',
    neq: '≠', // Not equal
  };

  /**
   * Converts a given filter node to string.
   *
   * @param node Filter node to be converted
   */
  public convertNodeToString(node: IFilterNode, attributes: IGanttBlockAttributeData[]): string {
    this.attributeData = attributes;
    return this._iterateOverCondition([node], EConnectionType.AND, true);
  }

  /**
   * Iterates over filter node recursively an generates a string of the given filter node.
   *
   * @param conArray Array of filter nodes
   * @param connectionType Current connection type of filter node condition
   * @param first Is this the first condition? Relevant for first condition without brackets.
   */
  private _iterateOverCondition(conArray: IFilterNode[], connectionType: EConnectionType, first: boolean): string {
    let stringPart = '';
    const firstCopy = !!first;

    if (conArray.length > 1) {
      if (!firstCopy) {
        stringPart += '(';
      } else {
        first = false;
      }
    }

    conArray.forEach((con, index) => {
      if (!Array.isArray(con)) {
        // its a connection
        if (con?.OR) {
          stringPart += this._iterateOverCondition(con.OR, EConnectionType.OR, first);
        }
        if (con?.AND) {
          stringPart += this._iterateOverCondition(con.AND, EConnectionType.AND, first);
        }
      } else {
        // its a condition
        stringPart += this._getSingleConditionAsString(con);
      }
      // check results by connection type
      if (conArray.length - 1 != index) {
        stringPart += connectionType === EConnectionType.OR ? ' | ' : ' & ';
      }
    });
    if (conArray.length > 1 && !firstCopy) {
      stringPart += ')';
    }
    return stringPart;
  }

  /**
   * Returns the condition value by comparator and attribute type.
   */
  private getConditionValue(condition: string[]): string {
    const comparatorType = EFilterOperator[condition[1]];
    const attributeType = this.getTypeOfAttribute(condition[0]);
    if (!attributeType) {
      return '';
    }

    let conditionValue = '';
    if (attributeType == EGanttBlockAttributeDataType.DATE) {
      conditionValue = GanttRowAdditionalDataMapper.getDateStrig(condition[2]);
    } else if (attributeType == EGanttBlockAttributeDataType.TIME) {
      conditionValue = GanttRowAdditionalDataMapper.getTimeString(condition[2], true);
    } else if (attributeType == EGanttBlockAttributeDataType.DATE_TIME) {
      conditionValue = this.getDateString(new Date(condition[2]));
    } else if (attributeType == EGanttBlockAttributeDataType.BOOLEAN) {
      conditionValue = condition[2] ? 'wahr' : 'falsch';
    } else {
      conditionValue = condition[2];
    }

    if (comparatorType === EFilterOperator.between || comparatorType === EFilterOperator.not_between) {
      if (attributeType == EGanttBlockAttributeDataType.DATE) {
        conditionValue += ' - ' + GanttRowAdditionalDataMapper.getDateStrig(condition[3]);
      } else if (attributeType == EGanttBlockAttributeDataType.TIME) {
        conditionValue += ' - ' + GanttRowAdditionalDataMapper.getTimeString(condition[3], true);
      } else if (attributeType == EGanttBlockAttributeDataType.DATE_TIME) {
        conditionValue += ' - ' + this.getDateString(new Date(condition[3]));
      } else {
        conditionValue += ' - ' + condition[3];
      }
    }

    if (
      comparatorType === EFilterOperator.none ||
      comparatorType === EFilterOperator.empty ||
      comparatorType === EFilterOperator.not_empty
    ) {
      conditionValue = ''; // no condition value required
    }

    return conditionValue;
  }

  /**
   * Generates a string of a given filter node condition.
   *
   * @param condition Single filter node condition
   */
  private _getSingleConditionAsString(condition: string[]): string {
    if (condition.length != 4) return ''; // return if condition not complete

    // extract attribute, comparator and value of condition
    const attribute = condition[0];
    const comparator = condition[1];
    const conditionValue = this.getConditionValue(condition);

    return `${attribute} ${this.comparatorSigns[comparator]} ${conditionValue ? `"${conditionValue}"` : ''}`;
  }

  /**
   * Returns the data type of an attribute by the given attribute type.
   */
  private getTypeOfAttribute(attributeName: string): EGanttBlockAttributeDataType {
    const attribute = this.attributeData.find((attribute) => attribute.name === attributeName);
    if (!attribute) {
      return null;
    }
    return attribute.type;
  }

  /**
   * Returns a date as string by a given date object.
   */
  private getDateString(date: Date): string {
    return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()} ${
      date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
    }:${date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()}`;
  }
}
