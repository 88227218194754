export enum ToolbarAction {
  PREVIOUS,
  NEXT,
  DELETE,
  EDIT,
  CREATE,
  DUPLICATE,
  PRINT,
  SAVE,
  GRID,
  SEARCH,
  BDE,
  FINISH_SAVE,
  CHOOSE_EXPERIMENT,
  PUBLISH_PLAN,
  DECISION_NEEDED,
  FREETEXT,
  PLANNING_RUN,
  TOGGLE_WORKSPACE,
  TOGGLE_EDITMODE,
  TOGGLE_MODE,
  TOGGLE_ACTION,
  SYNC_ERP,
  SYNC_MES,
  REPORT_RESOURCE,
  REPORT_PROCESS,
  HIGHLIGHT,
  DISABLE,
  ENABLE,
  REFRESH,
  CHANGE_ELEMENT,
  SIMULATE_CHANGES,
  OPTIMIZE_CHANGES,
  SIMULATE_CHANGES_WITH_NAME,
  GOTO_PUBLISHED_PLAN,
  NAVIGATION,
  DATA,
  SYNC,
  CALC,
  INTERACTIVE_PLANNING_RUN,
  SOLVER,
  SOLVER_STOP,
  SOLVER_STOP_ALL,
  SOLVER_PAUSE,
  SOLVER_PLAY,
  SOLVER_TAKE_PLAN,
  UPDATE_MAP,
}
