import { ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { GanttSettingsService } from 'frontend/src/dashboard/gantt/general/gantt-settings/service/gantt-settings.service';
import { Subscription } from 'rxjs';
import { EDockComponent, EDockViewMode } from './dock.enum';
import { IGanttDockComponent, IGanttDockComponentNotification } from './dock.interface';
import { GanttDockService } from './gantt-dock.service';

@Component({
  selector: 'app-dock',
  templateUrl: './dock.component.html',
  styleUrls: ['./dock.component.scss'],
})
export class DockComponent implements OnInit, OnDestroy {
  @Input() dockViewType: EDockViewMode;
  @ViewChild(MatMenuTrigger, { static: true }) matMenuTrigger: MatMenuTrigger;
  @ViewChild('dockWrapper') private _dockWrapper: ElementRef;

  public viewModeTypes = EDockViewMode;
  public menuOpen = false;
  public dockComponentTypes = EDockComponent;
  public contextMenuPosition = { x: '0px', y: '0px' };
  private _dockNotificationSubscription: Subscription;

  constructor(
    public ganttDockService: GanttDockService,
    private _cd: ChangeDetectorRef,
    private _ganttSettingsService: GanttSettingsService,
    private _elementRef: ElementRef
  ) {}

  ngOnInit(): void {
    this._listenForNotifications();
  }

  ngOnDestroy(): void {
    this._dockNotificationSubscription.unsubscribe();
  }

  public onRightClick(event: MouseEvent, component: IGanttDockComponent): void {
    event.preventDefault();
    const offsetX =
      this.dockViewType === EDockViewMode.UNDOCK
        ? -this._ganttSettingsService.getGanttSettings().dockWindowProportions.x
        : 0;
    const offsetY =
      this.dockViewType === EDockViewMode.UNDOCK
        ? -this._ganttSettingsService.getGanttSettings().dockWindowProportions.y
        : 0;
    this.contextMenuPosition.x = event.pageX + offsetX + 'px';
    this.contextMenuPosition.y = event.pageY + offsetY + 'px';
    this.matMenuTrigger.menuData = { item: component };
    this.matMenuTrigger.menu.focusFirstItem('mouse');
    this.matMenuTrigger.openMenu();
  }

  public onDockChange(viewMode: EDockViewMode, component: IGanttDockComponent): void {
    this.ganttDockService.addComponentToDockByView(component, viewMode);

    if (component.type === EDockComponent.LEGEND) {
      this._ganttSettingsService.changeSettings({ legendPosition: viewMode });
      this._ganttSettingsService.saveSettings().subscribe();
    }
  }

  private _listenForNotifications(): void {
    this._dockNotificationSubscription = this.ganttDockService
      .listenToDockNotifications()
      .subscribe((notification: IGanttDockComponentNotification) => {
        if (!notification) {
          return;
        }
        this._cd.detectChanges();
      });
  }

  public get dockWrapper(): ElementRef {
    return this._dockWrapper;
  }
}
