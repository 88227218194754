import { ETemplateType } from 'frontend/src/dashboard/model/resource/template-type';
import { EntryElement } from '../entry-collection/entry-element';
import { EntryElementValue } from '../entry-collection/entry-element-value';
import { EFieldType } from '../entry-collection/field-type.enum';
import { ContextMenu } from './../contextmenu/contextmenu';
import { Action } from './action/action';
import { ActionChain } from './action/action-chain';
import { EBadgePosition } from './badge-position.enum';
import { EButtonDisplayType } from './button-display-type.enum';
import { ButtonLine } from './button-line';

export class Button extends EntryElement {
  private localActions: Action[];
  private globalActions: Action[];
  private toggleIcon: string;
  private displayType: EButtonDisplayType;
  private displayBackground: boolean;
  private badge: number;
  private toggleEntry: EntryElementValue;
  private menu: ContextMenu;
  private color: string;
  private doubleClickActions: Action[];
  private customActions: Action[];
  private toggleName: string;
  private lines: ButtonLine[];
  private maxWidth: number;
  private indicator: string[] = [];
  private badgePosition: EBadgePosition;
  private pendingNotification: string[];
  private waitForUnsavedTemplateIds: string[];
  private showDeleteConfirmDialog: boolean;

  public isShowDeleteConfirmDialog(): boolean {
    return typeof this.showDeleteConfirmDialog === 'boolean' ? this.showDeleteConfirmDialog : false;
  }

  public setShowDeleteConfirmDialog(showDeleteConfirmDialog: boolean): this {
    this.showDeleteConfirmDialog = showDeleteConfirmDialog;
    return this;
  }

  public getUnsavedTemplateIds(): string[] {
    return this.waitForUnsavedTemplateIds || [];
  }

  public setUnsavedTemplateIds(connectedTableIds: string[]): this {
    this.waitForUnsavedTemplateIds = connectedTableIds;
    return this;
  }

  public getPendingNotification(): string[] {
    return this.pendingNotification || [];
  }

  public setPendingNotification(pendingNotification: string[]): this {
    this.update('pendingNotification', pendingNotification);
    return this;
  }

  public getMaxWidth(): number {
    return this.maxWidth;
  }

  public setMaxWidth(maxWidth: number): this {
    this.maxWidth = maxWidth;
    return this;
  }

  public getLines(): ButtonLine[] {
    return this.lines || [];
  }

  public setLines(lines: ButtonLine[]): this {
    this.update('lines', lines);
    return this;
  }

  public getBadgePosition(): EBadgePosition {
    return this.badgePosition || EBadgePosition.ABOVE_AND_AFTER;
  }

  public setBadgePosition(badgePosition: EBadgePosition): this {
    this.badgePosition = badgePosition;
    return this;
  }

  public setResourceId(resourceId: string): this {
    super.setResourceId(resourceId);
    this.getChain()
      .getActions()
      .forEach((a: Action) => {
        a.setResourceId(resourceId);
      });
    return this;
  }

  public getDoubleClickActions(): Action[] {
    return this.doubleClickActions || [];
  }

  public setDoubleClickActions(doubleClickActions: Action[]): this {
    this.doubleClickActions = doubleClickActions;
    return this;
  }

  public isDisplayBackground(): boolean {
    return typeof this.displayBackground === 'boolean' ? this.displayBackground : false;
  }

  public setDisplayBackground(displayBackground: boolean): this {
    this.displayBackground = displayBackground;
    return this;
  }

  constructor() {
    super();
    this.setFieldType(EFieldType.BUTTON)
      .setType(ETemplateType.ENTRY_ELEMENT)
      .setDisplayType(EButtonDisplayType.ICON_AND_LABEL);
  }

  public getMenu(): ContextMenu {
    return this.menu;
  }

  public setMenu(menu: ContextMenu): this {
    this.menu = menu;
    return this;
  }

  public getToggleEntry(): EntryElementValue {
    return this.toggleEntry;
  }

  public setToggleEntry(toggleEntry: EntryElementValue): this {
    this.toggleEntry = toggleEntry;
    return this;
  }

  public getToggleName(): string {
    if (this.toggleName) {
      return this.toggleName;
    }

    return this.getToggleEntry() ? this.getToggleEntry().getValue<string>() : null;
  }

  public setToggleName(name: string): this {
    this.toggleName = name;
    return this;
  }

  /**
   * Register an element by id to show an indicator in the button.
   * The id is needed because multiple elements can control the indicator state.
   * @param id Identifier of element to be registered
   * @returns {Button}
   */
  public registerIndicator(id: string): this {
    const indicators: string[] = (this.indicator || []).slice();
    if (!indicators.includes(id)) {
      indicators.push(id);
      this.update('indicator', indicators);
    }
    return this;
  }

  /**
   * Removes the id of an registered element for the indicator.
   * @param id Identifier of element to be registered
   * @returns {Button}
   */
  public deregisterIndicator(id: string): this {
    if (this.indicator.includes(id)) {
      this.update(
        'indicator',
        (this.indicator || []).slice().filter((identifier) => identifier != id)
      );
    }
    return this;
  }

  public isIndicator(): boolean {
    return this.indicator?.length > 0;
  }

  public getBadge(): number {
    return this.badge;
  }

  public setBadge(badge: number): this {
    this.update('badge', badge);
    return this;
  }

  public getDisplayType(): EButtonDisplayType {
    return this.displayType;
  }

  public setDisplayType(displayType: EButtonDisplayType): this {
    this.update('displayType', displayType);
    return this;
  }

  public getToggleIcon(): string {
    return this.toggleIcon;
  }

  public setToggleIcon(toggleImage: string): this {
    this.toggleIcon = toggleImage;
    return this;
  }

  public getLocalActions(): Action[] {
    return this.localActions || [];
  }

  public setLocalActions(localActions: Action[]): this {
    this.localActions = localActions;
    return this;
  }

  public setGlobalActions(globalActions: Action[]): this {
    this.globalActions = globalActions;
    return this;
  }

  public getGlobalActions(): Action[] {
    return this.globalActions || [];
  }

  /**
   * chains action in order they are injected
   * @param actions ...Action[]
   * @returns this
   */
  public chainActions(...actions: Action[]): this {
    this.customActions = this.getCustomActions().concat(actions);
    return this;
  }

  public overwriteChain(...actions: Action[]): this {
    this.customActions = actions;
    return this;
  }

  private getCustomActions(): Action[] {
    return this.customActions || [];
  }

  /**
   * removes an action from the chain
   * @param actionIds string[]
   * @returns this
   */
  public removeActions(...actionIds: string[]): this {
    this.globalActions = this.getGlobalActions().filter((a: Action) =>
      actionIds.find((id: string) => a.getId() === id) ? false : true
    );
    this.localActions = this.getLocalActions().filter((a: Action) =>
      actionIds.find((id: string) => a.getId() === id) ? false : true
    );
    this.customActions = this.getCustomActions().filter((a: Action) =>
      actionIds.find((id: string) => a.getId() === id) ? false : true
    );
    return this;
  }

  /**
   * chain actions but injects them before existing ones
   * @param actions ...Action[]
   * @returns this
   */
  public chainActionsBefore(...actions: Action[]): this {
    this.globalActions = (actions || []).concat(this.getGlobalActions());
    return this;
  }

  public getChain(): ActionChain {
    return new ActionChain(...this.getGlobalActions().concat(this.getLocalActions()).concat(this.getCustomActions()));
  }

  public getColor(): string {
    return this.color;
  }

  public setColor(color: string): this {
    this.update('color', color);
    return this;
  }
}
