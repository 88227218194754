import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
export interface IBubblePage {
  labelIndex: number;
  pageIndex: number;
  pageSize: number;
  offset: number;
  limit: number;
}
@Component({
  selector: 'app-bubble-pagination',
  templateUrl: './bubble-pagination.component.html',
  styleUrls: ['./bubble-pagination.component.scss'],
})
export class BubblePaginationComponent implements OnInit, OnChanges {
  @Input() pageSize = 5;
  @Input() pageIndex = 0;
  @Input() maxCount = 13;

  @Output() pageChange: EventEmitter<IBubblePage> = new EventEmitter();

  public pages: IBubblePage[] = [];
  public currentPage: IBubblePage;
  constructor() {}

  ngOnInit() {
    this.mappingPages();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.pageSize || changes.maxCount) {
      this.mappingPages();
    }

    if (changes.pageIndex) {
      this.currentPage = this.pages[this.pageIndex];
    }
  }

  private mappingPages() {
    const pageCount = Math.ceil(this.maxCount / this.pageSize);
    const pages: IBubblePage[] = [];
    for (let index = 0; index < pageCount; index++) {
      const page: IBubblePage = {
        labelIndex: index + 1,
        pageIndex: index,
        pageSize: this.pageSize,
        offset: index * this.pageSize,
        limit: this.pageSize,
      };
      pages.push(page);
    }
    this.pages = pages.slice();
    this.currentPage = this.pages[this.pageIndex];
  }

  public onPageClick(page: IBubblePage) {
    this.currentPage = page;
    this.pageChange.emit(page);
  }

  public nextPage() {
    this.onPageClick(this.pages[this.currentPage.pageIndex + 1]);
  }

  public prevPage() {
    this.onPageClick(this.pages[this.currentPage.pageIndex - 1]);
  }

  public getMorePrevPageMenuBtnState(page: IBubblePage) {
    if (this.pages.length > 7) {
      if (this.currentPage.pageIndex >= 4 && this.currentPage.pageIndex <= this.pages.length - 5) {
        return page.pageIndex === this.currentPage.pageIndex - 2;
      } else {
        if (this.currentPage.pageIndex < 4) {
          return false;
        }
        if (this.currentPage.pageIndex > this.pages.length - 5) {
          return page.pageIndex === this.currentPage.pageIndex - 5;
        }
      }
    } else {
      return false;
    }
  }

  public getMoreNextPageMenuBtnState(page) {
    if (this.pages.length > 7) {
      return (
        this.currentPage.pageIndex < this.pages.length - 4 &&
        page.pageIndex === this.pages.length - 3 &&
        this.currentPage.pageIndex !== this.pages.length - 1 &&
        this.currentPage.pageIndex !== this.pages.length - 2
      );
    } else {
      return false;
    }
  }
}
