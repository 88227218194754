import { Injectable } from '@angular/core';
import { EntryElementValue } from '@app-modeleditor/components/entry-collection/entry-element-value';
import { EFieldType } from '@app-modeleditor/components/entry-collection/field-type.enum';
import { ConfigService } from '@core/config/config.service';
import { TranslateService } from '@ngx-translate/core';
import { CellProperties } from 'handsontable/settings';
import { SaxMsSpreadSheetColumn } from '../core/saxms-spreadsheet';
import { ESaxMsColumnUnits } from '../model/table-column-unity.enum';
import { ESpreadsheetDatatypes } from '../model/table-datatypes.enum';
import { ETextStyle, ISaxMsSpreadsheetRowEntry } from '../model/table-row-entry';
import { ColumnUtil } from './column-util';
import { RowDataUtil } from './row-data-util';
import { SpreadsheetHelperUtil } from './spreadsheet-helper-util';

@Injectable()
export class CellRendererUtil {
  private hoveringEnabled = false;
  private numberValueFontsize = 14;
  constructor(
    private spreadsheetHelperUtil: SpreadsheetHelperUtil,
    private rowDataUtil: RowDataUtil,
    private columnDataUtil: ColumnUtil,
    private configService: ConfigService,
    private translateService: TranslateService
  ) {}

  /**
   * handle the styling of a cell
   * @param td parent container of the cell
   * @param cellTemplate container of the cell
   * @param cellProperties properties of the cell from the handsontable
   * @param column column of the cell
   * @param relatedCell information of the cell
   * @param visualValue visual value of the cell
   */
  private applyStyleToCell(
    td: HTMLElement,
    cellTemplate: HTMLElement,
    cellProperties: CellProperties,
    column: SaxMsSpreadSheetColumn,
    relatedCell: ISaxMsSpreadsheetRowEntry,
    visualValue: string | number
  ) {
    // set readonly color
    const textColor = column.readOnly || !relatedCell.rowEditable ? '#0b0b0b96' : 'black';
    const useGreyOutOnColoredCells = this.configService.access().templates.Table.greyOutColoredCells;

    td.style.setProperty('color', textColor, useGreyOutOnColoredCells ? 'important' : ''); // if not important it will be overwritten by colored cell

    cellProperties.readOnly = column.readOnly || !relatedCell.rowEditable;
    td.id = relatedCell.uniqueId || td.id;

    if (relatedCell.color) {
      td.style.backgroundColor = relatedCell.color; // html color code from backend
    }

    if (relatedCell.foreColor) {
      cellTemplate.style.color = relatedCell.foreColor;
    }

    if (relatedCell.textAlign) {
      cellTemplate.style.textAlign = relatedCell.textAlign;
    }

    if (relatedCell.textStyle) {
      if (relatedCell.textStyle.includes(ETextStyle.BOLD)) {
        td.style.fontWeight = ETextStyle.BOLD;
      }

      if (relatedCell.textStyle.includes(ETextStyle.ITALIC)) {
        td.style.fontStyle = ETextStyle.ITALIC;
      }

      if (relatedCell.textStyle.includes(ETextStyle.UNDERLINE)) {
        td.style.textDecoration = ETextStyle.UNDERLINE;
      }
    }

    if (relatedCell.leftPadding) {
      td.style.setProperty('padding-left', relatedCell.leftPadding + 'px', 'important');
    }

    if (column.listenToAutoFontSize) {
      td.style.fontSize = this.numberValueFontsize + 'px';
    }

    this.getTextAlignBaseOnType(td, column);
    this.classHandling(td, cellTemplate, column, relatedCell, visualValue);
  }

  private classHandling(
    td: HTMLElement,
    cellTemplate: HTMLElement,
    column: SaxMsSpreadSheetColumn,
    relatedCell: ISaxMsSpreadsheetRowEntry,
    visualValue: string | number
  ) {
    const tdClasses: string[] = ['saxms-table-cell-wrapper'];

    if (this.hoveringEnabled) {
      tdClasses.push('saxms-hover-cell');
    }

    if ((column.readOnly || !relatedCell.rowEditable) && visualValue !== 0 && !visualValue) {
      tdClasses.push('notEditableCell');
    }

    if (!relatedCell.color) {
      if (relatedCell.defaultColor) {
        // class name for cell from backend
        tdClasses.push(relatedCell.defaultColor);
      } else if (relatedCell.defaultColorClass) {
        // class name for row mapped from backend
        tdClasses.push(relatedCell.defaultColorClass);
      }
    }

    if (column.validatorRegEx) {
      if (!this.validateCellByRegex(visualValue, column.validatorRegEx)) {
        tdClasses.push(column.invalidCellClassName);
      }
    }

    if (relatedCell.edited) {
      tdClasses.push('edited-cell');
      if (!this.validateCell(relatedCell.value, column.datatype) && !tdClasses.includes(column.invalidCellClassName)) {
        tdClasses.push(column.invalidCellClassName);
      }
    }

    if (relatedCell.isInvalid && !tdClasses.includes(column.invalidCellClassName)) {
      tdClasses.push(column.invalidCellClassName);
    }

    cellTemplate.classList.add('saxms-table-cell');
    td.classList.add(...tdClasses);
  }

  /**
   * validate a cell value by the datatype
   * @param value value of the cell
   * @param dataType datattype of the cell
   * @returns the state if the cell value valid or not
   */
  private validateCell(value, dataType: ESpreadsheetDatatypes) {
    switch (dataType) {
      case ESpreadsheetDatatypes.number:
        return !isNaN(value);
      default:
        return true;
    }
  }

  /**
   * checks the value of a cell by a regex
   * @param value value of the cell
   * @param regex regEx of the cell
   * @returns returns the value whether the value fits the regex or not
   */
  private validateCellByRegex(value, regex: RegExp) {
    return regex.test(value);
  }

  /**
   * style the text align of the cells based on the data type
   * @param cellElement html element of a single cells of the table
   * @param columnSettings columnsettings of the column of the cell
   */
  private getTextAlignBaseOnType(cellElement: HTMLElement, columnSettings: SaxMsSpreadSheetColumn): void {
    switch (columnSettings.editor) {
      case 'numeric':
        cellElement.style.textAlign = 'right';
        break;

      case 'text':
        cellElement.style.textAlign = 'left';
        break;
    }

    if (
      columnSettings.datatype === ESpreadsheetDatatypes.bool ||
      columnSettings.datatype === ESpreadsheetDatatypes.float ||
      columnSettings.datatype === ESpreadsheetDatatypes.number
    ) {
      cellElement.style.textAlign = 'right';
    }
    return;
  }

  /**
   * set the value to the html container
   * @param cellTemplate html container of the cell
   * @param columnSettings column information
   * @param value value of the cell
   */
  private setCellTemplate(cellTemplate: HTMLDivElement, columnSettings: SaxMsSpreadSheetColumn, value: string) {
    if (columnSettings.fieldType === EFieldType.CHECK_BOX || columnSettings.fieldType === EFieldType.COLOR_PICKER) {
      cellTemplate.innerHTML = value;
    } else {
      cellTemplate.innerText = value;
    }
  }

  /**
   * transform the stored value of the cell to the visiual value by the datatype
   * @param value stored value of the cell
   * @param columnSettings column inforamtion of the cell
   * @param forTooltip flag if the value for shown in the tooltip
   * @returns the visual value of a cell by the datattype
   */
  private getVisibileValueByDatatype(value: any, columnSettings: SaxMsSpreadSheetColumn, forTooltip: boolean) {
    switch (columnSettings.datatype) {
      case ESpreadsheetDatatypes.date:
      case ESpreadsheetDatatypes.long:
        return !isNaN(value) && typeof value === 'number'
          ? this.spreadsheetHelperUtil.mappingDateString(value, columnSettings)
          : '';
      case ESpreadsheetDatatypes.number:
        if (isNaN(value) || typeof value !== 'number') {
          return '';
        }
        return Math.round(parseFloat(this.getAltNegativeRounding(value) + ''));
      case ESpreadsheetDatatypes.float:
        if (isNaN(value) || typeof value !== 'number') {
          return '';
        }
        return parseFloat(value + '')
          .toFixed(this.spreadsheetHelperUtil.getFixedCount(columnSettings))
          .replace('.', ',');
      case ESpreadsheetDatatypes.bool:
        if (value) {
          return `<i class="material-icons boolean-icon">done</i>`;
        } else {
          return `<i class="material-icons boolean-icon">clear</i>`;
        }
      case ESpreadsheetDatatypes.text:
        if (columnSettings.fieldType === EFieldType.TEXT_AREA) {
          const textAreaValue = (value as string) || '';
          if (forTooltip) {
            return textAreaValue.replaceAll('\n', '<br>');
          } else {
            return textAreaValue.replaceAll('\n', ' ');
          }
        }

        const multiLineRows = (value + '').split('\n');
        if (multiLineRows.length > 1 && !forTooltip) {
          let lineValue = '';
          multiLineRows.forEach((line, index) => {
            lineValue += `${line}${index !== multiLineRows.length - 1 ? ', ' : ''}`;
          });
          return lineValue;
        }
        return value;
      default:
        return value;
    }
  }

  /**
   * transform the stored value of the cell to the visiual value by the entryelment
   * @param value stored value of the cell
   * @param columnSettings column inforamtion of the cell
   * @param forTooltip flag if the value for shown in the tooltip
   * @returns the visual value of a cell by the entryelement
   */
  private getVisibileValueByEntryElement(value: any, columnSettings: SaxMsSpreadSheetColumn, forTooltip: boolean) {
    let visibleValue = '';
    if (columnSettings.fieldType === EFieldType.COMBO_BOX && !(value instanceof EntryElementValue)) {
      return value;
    }

    switch (columnSettings.entryElement.getFieldType()) {
      case EFieldType.NUMERIC_RANGE_PICKER:
        if (Array.isArray(value)) {
          if (value.length === 0) {
            return '';
          }
          const visual: string[] = value
            .filter((v) => !isNaN(v.value) && v.value > 0)
            .map((v) => `${v.value} x ${v.name}`);

          const result = visual?.length > 0 ? visual.reduce((prev: string, cur: string) => `${prev}; ${cur}`) : '';
          return result;
        }
        return '';
      case EFieldType.MULTI_OBJECT_SELECTOR:
        const values = (value as EntryElementValue[]) || [];

        if (!Array.isArray(values)) {
          return values;
        }

        values?.forEach((v, index) => {
          visibleValue += index === values.length - 1 ? `${v.getName()}` : `${v.getName()}, `;
        });
        return visibleValue;
      case EFieldType.OBJECT_SELECTOR:
        const entryValue = value as EntryElementValue;
        return entryValue ? (typeof entryValue === 'string' ? entryValue : entryValue.getName()) : '';
      case EFieldType.TEXT_FIELD:
      case EFieldType.TEXT_AREA:
        return columnSettings.unit
          ? this.getVisibileValueByUnit(value, columnSettings)
          : this.getVisibileValueByDatatype(value, columnSettings, forTooltip);
      case EFieldType.COLOR_PICKER:
        if (forTooltip) {
          return value || this.translateService.instant('TABLE.no_color');
        }
        return `<div class="color-indicator" style="background: ${value || 'white'}"></div>`;
    }
  }

  /**
   * transform the stored value of the cell to the visiual value by the unit
   * @param value stored value of the cell
   * @param columnSettings column inforamtion of the cell
   * @returns the visual value of a cell by the unit
   */
  private getVisibileValueByUnit(value: any, columnSettings: SaxMsSpreadSheetColumn) {
    switch (columnSettings.unit) {
      case ESaxMsColumnUnits.float:
        if (!value) {
          return '';
        }
        return parseFloat(value + '')
          .toFixed(this.spreadsheetHelperUtil.getFixedCount(columnSettings))
          .replace('.', ',');
      case ESaxMsColumnUnits.int:
        // tslint:disable-next-line:radix
        return value ? parseInt(this.getAltNegativeRounding(value) + '') : '';
      case ESaxMsColumnUnits.percent:
        return value
          ? (parseFloat(value) * 100)
              .toFixed(this.spreadsheetHelperUtil.getFixedCount(columnSettings))
              .replace('.', ',') + '%'
          : '';
      case ESaxMsColumnUnits.euro:
        return value
          ? this.addThousandSeperator(
              parseFloat(value).toFixed(this.spreadsheetHelperUtil.getFixedCount(columnSettings)).replace('.', ',')
            ) + ' €'
          : '';
      case ESaxMsColumnUnits.date:
      case ESaxMsColumnUnits.long:
        return value ? this.spreadsheetHelperUtil.mappingDateString(parseInt(value), columnSettings) : '';
      default:
        return value;
    }
  }

  /**
   * adds seperator for thousands
   * @param {string} value string value where thousand seperator should be injected
   * @returns string
   */
  private addThousandSeperator(value: string): string {
    return value.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
  }

  /**
   * customrenderer for the cells, replace the default renderer of the handsontable
   * @param instance handsontable instance
   * @param td html container of the cell
   * @param row row-index of the cell
   * @param col column-index of the cell
   * @param prop
   * @param value stored value of the cell
   * @param cellProperties properties of the cell
   * @returns updated html container of the cell
   */
  private customCellRenderer(instance, td: HTMLTableCellElement, row, col, prop, value, cellProperties) {
    const physicalRow: number = isNaN(this.spreadsheetHelperUtil.getRowIndex(row))
      ? row
      : this.spreadsheetHelperUtil.getRowIndex(row);
    const physicalColumn: number = isNaN(this.spreadsheetHelperUtil.getColumnIndex(col))
      ? col
      : this.spreadsheetHelperUtil.getColumnIndex(col);
    let visualValue;

    if (isNaN(physicalRow) || isNaN(physicalColumn)) {
      return;
    }
    // Handsontable.dom.empty(td);
    if (!this.rowDataUtil.getDatasetPlainlist()[physicalRow]) {
      return;
    }
    const relatedCell = this.rowDataUtil.getDatasetPlainlist()[physicalRow][physicalColumn];

    // set colors depending on related cell
    if (relatedCell) {
      const columnSettings = this.columnDataUtil.getColumns().find((column) => column.id === relatedCell.tableHeaderId);
      if (columnSettings) {
        visualValue = this.getVisualValueOfCell(relatedCell.value, columnSettings, false);
        let cellTemplate = td.getElementsByClassName('saxms-table-cell').item(0) as HTMLDivElement;
        if (!cellTemplate) {
          if (td.childNodes.length) {
            return td;
          } // another node is already in the cell
          cellTemplate = document.createElement('div');
          td.appendChild(cellTemplate);
        }
        this.applyStyleToCell(td, cellTemplate, cellProperties, columnSettings, relatedCell, visualValue);
        this.setCellTemplate(cellTemplate, columnSettings, visualValue);
      }
    }

    return td;
  }

  /**
   * rerender a single cell with a new value or new styling
   * @param column column information of the cell
   * @param row row information of the cell
   * @param value new value of the cell
   * @param editable flag for is the cell editable
   */
  public rerenderCell(column: SaxMsSpreadSheetColumn, rowResourceId: string, value: any, editable: boolean): void {
    const foundRow = this.rowDataUtil.getRowDatasets().find((_row) => _row.resourceId === rowResourceId);
    const foundRowIndex = this.rowDataUtil.getRowDatasets().indexOf(foundRow);
    const foundCell =
      foundRow.properties[
        Object.entries(foundRow.properties).find(([key, value]) => value.tableHeaderId === column.id)[0]
      ];

    const cellValues = Object.values(this.rowDataUtil.getDatasetPlainlist()[foundRowIndex]);
    const relatedCell = cellValues.find((value) => value.tableHeaderId === column.id);
    // const relatedCell = this.rowDataUtil.getDatasetPlainlist()[foundRowIndex][column.filterSortObject.getColumnIndex()];

    foundCell.value = this.rowDataUtil.getValuetypeAfterChange(column.filterSortObject.getColumnIndex(), value);

    const visualRowIndex = this.spreadsheetHelperUtil.getVisualRowIndex(foundRowIndex);

    if (visualRowIndex === null) {
      return;
    }

    const cellTD = this.spreadsheetHelperUtil.getSpreadSheetInstance().getCell(visualRowIndex, column.data);

    const cellMeta = this.spreadsheetHelperUtil.getSpreadSheetInstance().getCellMeta(visualRowIndex, column.data);

    if (!cellTD) {
      return;
    }

    let cellTemplate = cellTD.getElementsByClassName('saxms-table-cell').item(0) as HTMLDivElement;
    if (cellTD.classList.contains('edited-cell')) {
      cellTD.classList.remove('edited-cell');
    }
    const visualValue = this.getVisualValueOfCell(value, column, false);
    const textColor = column.readOnly || !editable ? '#0b0b0b96' : 'black';
    const useGreyOutOnColoredCells = this.configService.access().templates.Table.greyOutColoredCells;
    cellTD.style.setProperty('color', textColor, useGreyOutOnColoredCells ? 'important' : ''); // if not important it will be overwritten by colored cell

    if ((!column.readOnly && editable) || visualValue === 0 || visualValue) {
      cellTD.classList.remove('notEditableCell');
    }

    if ((column.readOnly || !editable) && visualValue !== 0 && !visualValue) {
      cellTD.classList.add('notEditableCell');
    }
    cellMeta.readOnly = column.readOnly || !editable;

    if (!cellTemplate) {
      const currentCords = this.spreadsheetHelperUtil.getCustomEditor().getCoords();
      const isCellInEdit = currentCords.colPos === column.data && currentCords.rowPos === visualRowIndex;
      if (!isCellInEdit) {
        // if cell is in edit, the cell template will be created by the custom editor
        cellTemplate = document.createElement('div');
        cellTemplate.classList.add('saxms-table-cell');
        this.setCellTemplate(cellTemplate, column, visualValue);
        cellTD.appendChild(cellTemplate);
      }
    } else {
      this.setCellTemplate(cellTemplate, column, visualValue);
      cellTemplate.style.display = 'block';
    }

    this.applyStyleToCell(cellTD, cellTemplate, cellMeta, column, relatedCell, visualValue);
  }

  /**
   * update the visibility of the hovering effect inside the table
   * @param state
   */
  public setHoveringEnabled(state: boolean): void {
    this.hoveringEnabled = state;
  }

  /**
   * If altNegativeRounding in column is set to true, the value will be rounded to the nearest negative value.
   * Alternative means rounding down negative values at 0.5
   */
  private getAltNegativeRounding(value: number): number {
    let newValue = value;
    if (this.spreadsheetHelperUtil.getTableTemplate().isAltNegativeRounding() && value < 0) {
      newValue = -Math.round(-value);
    }
    return newValue;
  }

  /**
   * update the fontsoze of all cells
   * @param cellFontSize font size of all cells
   */
  public setFontSizeOfCell(cellFontSize: number): void {
    this.numberValueFontsize = cellFontSize;
  }

  /**
   * handle the visiual value of a cell
   * @param cellValue stored value of the cell
   * @param columnSettings column information of the cell
   * @param forTooltip flag if the value for shown in the tooltip
   * @returns the visual value of the cell
   */
  public getVisualValueOfCell(cellValue, columnSettings, forTooltip: boolean): any {
    let val = cellValue;
    if (columnSettings.entryElement) {
      val = this.getVisibileValueByEntryElement(cellValue, columnSettings, forTooltip);
    } else {
      val = columnSettings.unit
        ? this.getVisibileValueByUnit(cellValue, columnSettings)
        : this.getVisibileValueByDatatype(cellValue, columnSettings, forTooltip);
    }

    if (columnSettings.thousandSeparator === true) {
      const split = `${val}`.match(/[0-9]+/g);
      if (split?.length > 0 && split.length <= 2) {
        const withoutSeperator: string = split.length === 2 ? `${split[0]},${split[1]}` : split[0];
        const withSeperator: string = this.addThousandSeperator(
          parseFloat(withoutSeperator.replace(/\,/gm, '.'))
            .toFixed(this.spreadsheetHelperUtil.getFixedCount(columnSettings))
            .replace('.', ',')
        );
        val = val.replace(withoutSeperator, withSeperator);
      }
    }
    return val;
  }

  /**
   * @returns the custom renderer of the table
   */
  public getCustomCellRenderer(): any {
    return this.customCellRenderer.bind(this);
  }
}
