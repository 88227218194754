<div>
  <div class="saxms-lightbox-title-wrapper">
    <h2 class="saxms-lightbox-headline-template" mat-dialog-title>
      <ng-container *ngTemplateOutlet="lightBoxHeadline"></ng-container>
    </h2>
  </div>
  <mat-dialog-actions class="saxms-lightbox-action-top">
    <ng-container *ngTemplateOutlet="lightBoxActionbarTop"></ng-container>
  </mat-dialog-actions>

  <mat-dialog-content [ngClass]="contentBorder ? '' : 'no-border'">
    <ng-container *ngTemplateOutlet="lightBoxContent"></ng-container>
  </mat-dialog-content>

  <mat-dialog-actions>
    <ng-container *ngTemplateOutlet="lightBoxActionbarBottom"></ng-container>
  </mat-dialog-actions>

  <!-- add components -->
  <ng-template #lightBoxHeadline><ng-content select=".headline"></ng-content></ng-template>
  <ng-template #lightBoxActionbarTop><ng-content select=".actionbarTop"></ng-content></ng-template>
  <ng-template #lightBoxContent><ng-content select=".content"></ng-content></ng-template>
  <ng-template #lightBoxActionbarBottom><ng-content select=".actionbarBottom"></ng-content></ng-template>
</div>
