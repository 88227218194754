import { AutocompleteTextfield } from '@app-modeleditor/components/elements/autocomplete-textfield';
import { EntryElementValue } from '@app-modeleditor/components/entry-collection/entry-element-value';
import { EFieldType } from '@app-modeleditor/components/entry-collection/field-type.enum';
import { GlobalUtils } from 'frontend/src/dashboard/global-utils';
import { Gantt_General } from '../../../general.gantt.component';
import { GanttEssentialPlugIns } from '../../../plugin/e-gantt-essential-plugins';
import { GanttBlockFilterByAttributePlugIn } from '../../../plugin/plugin-list/block-filter/block-filter-by-attribute';
import { IBlockFilterByAttributeSearchResult } from '../../../plugin/plugin-list/block-filter/block-filter-by-attribute.interface';

export class AttributeSearchInputElement extends AutocompleteTextfield {
  private typingTimer: number;
  private searchString: string;
  private lastSearchResult: IBlockFilterByAttributeSearchResult;
  private indicatorIdentifier: string = GlobalUtils.generateUUID();
  private isIndicatorVisible = false;

  constructor(private scope: Gantt_General) {
    super();
  }

  //
  get(data: any): AutocompleteTextfield {
    this.setName('GANTT.attribute-search-blocks')
      .onChanges((val: EntryElementValue) => {
        clearTimeout(this.typingTimer);
        const tag: string = val.getValue() || '';
        this.executeSearch(tag);
      })
      .setId(`attribute-search-input-${data.id}`)
      .setFreeSelection(true)
      .setFieldType(EFieldType.AUTOCOMPLETE_TEXT_FIELD)
      .setAlwaysEnabled(true)
      .setInvalidOnNoOptions(true)
      .setValue(new EntryElementValue().setValue(null));

    return this;
  }

  public refresh() {
    if (this.searchString) {
      this.executeSearch(this.searchString, true);
    }
  }

  private executeSearch(inputValue: string, force = false) {
    if (this.searchString === inputValue && !force) {
      return this.lastSearchResult;
    }
    const result = this.searchTerm(inputValue); // shift ids of hidden results
    this.setOptions(this.extractFilterList(inputValue, result?.hiddenResults || {}));
  }

  private searchTerm(inputValue: string): IBlockFilterByAttributeSearchResult {
    // change indicator only if value has changed
    if (!!inputValue !== this.isIndicatorVisible) {
      this.isIndicatorVisible = !!inputValue;
      this.scope.toolbar.updateMenuIndicator(this.isIndicatorVisible, this.getId(), this.indicatorIdentifier);
    }

    this.searchString = inputValue;

    const plugIn: GanttBlockFilterByAttributePlugIn = this.scope.ganttPluginHandlerService.getEssentialPlugIn(
      GanttEssentialPlugIns.GanttBlockFilterByAttributePlugIn
    );
    const result = plugIn.filterBlocksByQuery(inputValue);
    this.lastSearchResult = result;
    return result;
  }

  private extractFilterList(query: string, hiddenShiftIds: { [shiftId: string]: string[] }): EntryElementValue[] {
    const blockFilterByAttributePlugIn = this.scope.ganttPluginHandlerService.getEssentialPlugIn(
      GanttEssentialPlugIns.GanttBlockFilterByAttributePlugIn
    );
    const blockFilterPlugIn = this.scope.ganttPluginHandlerService.getEssentialPlugIn(
      GanttEssentialPlugIns.BlockFilterPlugIn
    );

    const attributeList: EntryElementValue[] = [];
    blockFilterByAttributePlugIn.getAllPropertyAttributesByQuery(query).forEach((val, key) => {
      let isHiddenAttributeInside = false;
      for (const shiftId of val) {
        const noRenderIds = hiddenShiftIds[shiftId];
        if (!noRenderIds || noRenderIds.length <= 0) continue;
        for (const noRenderId of noRenderIds) {
          if (!blockFilterPlugIn.isFilterInvertibleById(noRenderId)) {
            isHiddenAttributeInside = true;
            break;
          }
        }
        if (isHiddenAttributeInside) break;
      }

      attributeList.push(
        new EntryElementValue()
          .setValue(key)
          .setId(key)
          .setIcon(isHiddenAttributeInside ? 'warning' : undefined)
          .setColor('#ff8d00')
          .setName(key)
      );
    });

    attributeList.sort((a, b) => {
      if (a.getName() < b.getName()) {
        return -1;
      }
      if (a.getName() > b.getName()) {
        return 1;
      }
      return 0;
    });

    return attributeList;
  }
}
