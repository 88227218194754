import { Template } from './model/resource/template';

export class GlobalUtils {
  // generates uuid
  public static generateUUID(): string {
    let d = new Date().getTime();
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
    return uuid;
  }

  /**
   * generates random number between min and max
   * @param {number} min lower range
   * @param {number} max upper range
   * @returns number
   */
  public static randomIntBetween(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  /**
   * Modifies a URL and replaces certain attributes with data from the given template.
   * @param node template object for data extraction
   * @param url URL that is to be modified
   * @param additionalAttributes
   * @returns transformed url
   */
  public static transformUrl(node: Template, url: string, additionalAttributes?: { [key: string]: any }): string {
    // find all keys
    const keys: string[] = [];
    let l = url.slice();
    while (true) {
      const start = l.indexOf('{');
      const end = l.indexOf('}');
      const k = l.substr(start + 1, end - (start + 1));
      if (!k) {
        break;
      }
      keys.push(k);
      l = l.substr(end + 1, l.length - (end + 1));
    }

    keys.forEach((key: string) => {
      if (node[key] === null || node[key] === undefined) {
        // check if additional arguments are given
        if (additionalAttributes && additionalAttributes[key] !== null && additionalAttributes[key] !== undefined) {
          url = url.replace(`{${key}}`, additionalAttributes[key]);
        }
        return;
      }
      url = url.replace(`{${key}}`, node[key]);
    });
    return url;
  }
}
