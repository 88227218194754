import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, Routes } from '@angular/router';
import { ConfigService } from '@core/config/config.service';
import { AuthGuard } from './user/utils/auth.guard';

const isResetPasswordActive: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const isActive = inject(ConfigService).access()?.login?.enablePasswordReset;
  if (isActive) {
    return true;
  }
  inject(Router).navigate(['auth/login']);
  return false;
};

export const APP_ROUTES: Routes = [
  {
    path: '',
    redirectTo: 'guard',
    pathMatch: 'full',
  },
  {
    path: 'window',
    loadChildren: () => import('frontend/src/dashboard/view/window/window.module').then((m) => m.WindowModule),
  },
  {
    path: 'guard',
    canActivate: [AuthGuard],
    loadChildren: () => import('frontend/src/dashboard/view/main/main.module').then((m) => m.MainModule),
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('frontend/src/dashboard/login/feature/auth-shell/auth.routes').then((m) => m.AUTH_ROUTES),
  },
  {
    path: 'data-privacy',
    loadChildren: () => import('./login/feature/auth-privacy/auth-privacy.routes').then((m) => m.DataPrivacyRoutes),
  },
  {
    path: 'imprint',
    loadChildren: () => import('./login/feature/auth-imprint/auth-imprint.routes').then((m) => m.ImprintRoutes),
  },
  {
    path: 'reset-password',
    loadComponent: () =>
      import('./login/feature/reset-password/reset-password.component').then((m) => m.ResetPasswordComponent),
    canActivate: [isResetPasswordActive],
  },
];
