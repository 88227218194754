import { EntryElement } from '@app-modeleditor/components/entry-collection/entry-element';
import { EntryElementValue } from '@app-modeleditor/components/entry-collection/entry-element-value';
import { EFieldType } from '@app-modeleditor/components/entry-collection/field-type.enum';
import { GlobalUtils } from 'frontend/src/dashboard/global-utils';
import { Gantt_General } from '../../../general.gantt.component';
import { GanttEssentialPlugIns } from '../../../plugin/e-gantt-essential-plugins';
import { GanttBlockFilterByAttributePlugIn } from '../../../plugin/plugin-list/block-filter/block-filter-by-attribute';

export class AttributeHideRowsElement extends EntryElement {
  private indicatorIdentifier: string = GlobalUtils.generateUUID();

  constructor(private scope: Gantt_General) {
    super();
  }

  get(data: any): this {
    this.setFieldType(EFieldType.SLIDE_TOGGLE)
      .setName(this.scope.translate.instant('@hide-empty-rows@'))
      .setAlwaysEnabled(true)
      .setId(data.id)
      .setId(`attribute-hiderows-btn-${data.id}`)
      .setValue(new EntryElementValue().setValue(false))
      .onChanges((change) => this.handleHideRowsToggle(change.value));

    return this;
  }

  private handleHideRowsToggle(value: boolean) {
    const plugIn: GanttBlockFilterByAttributePlugIn = this.scope.ganttPluginHandlerService.getEssentialPlugIn(
      GanttEssentialPlugIns.GanttBlockFilterByAttributePlugIn
    );

    this.scope.toolbar.updateMenuIndicator(value, this.getId(), this.indicatorIdentifier);
    plugIn.setHideEmptyRows(value);
  }
}
