import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DateRange } from '@angular/material/datepicker';
import { TemplateService } from '@app-modeleditor/utils/template.service';
import { ConfigService } from '@core/config/config.service';
import { TranslateService } from '@ngx-translate/core';
import { EDatatype } from 'frontend/src/dashboard/model/entry/datatype.enum';
import moment, { Moment } from 'moment';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { EntryElement } from '../entry-collection/entry-element';
import { EEntryElementEvents } from '../entry-collection/entry-element-events.enum';
import { EntryElementValue } from '../entry-collection/entry-element-value';
import { ALink } from '../entry-collection/link/link';
import { ELinkType } from '../entry-collection/link/link-type.enum';
import { AEntryElement } from '../entry-element/a-entry-element';
import { OverlayCloseEvent } from '../lightbox/overlay/overlay-close.interface';
import { IOverlayData } from '../lightbox/overlay/overlay-data.interface';
import { OverlayService } from '../lightbox/overlay/overlay.service';
import { ICalendarOptions } from './calendar/calendar-options.interface';
import { DateCalendarComponent } from './calendar/calendar.component';
import { Datepicker } from './datepicker';
import { EDatepickerMode } from './datepicker-mode.enum';
import { EDatepickerType } from './datepicker-type.enum';
@Component({
  selector: 'template-datepicker',
  templateUrl: './template-datepicker.component.html',
  styleUrls: ['template-datepicker.component.scss'],
  providers: [],
})
export class TemplateDatepickerComponent extends AEntryElement implements OnInit, OnDestroy, OnChanges {
  private lastStart: Date = null;
  private lastEnd: Date = null;
  protected hideRequiredMarker = true;
  public prevValue: number = null;
  @Input() entryElement: Datepicker | EntryElement;
  @Input() type: EDatepickerType = EDatepickerType.DATETIME;
  @Input() useLocalTime = false; // use local time +offset/-offset
  @Input() placeholder = ''; // placeholder to show
  @Input() open: boolean;
  @Input() id: string;
  @Input() onlyInput = false; // only show input?
  @Input() simpleInput = false; // wether the flatpicker has default or material styled input
  @Input() disabled = false; // wether the flatpicker is enabled or disabled
  @Input() dateTimeFormat: string | null = null;
  @Input() mode: EDatepickerMode = EDatepickerMode.SINGLE; // selected picker mode
  @Input() time24hr = true; // is 24h format used
  @Input() selectedValues: { from: number | Date | string; to?: number | Date | string }; // value for range-picker
  @Output() openPicker: EventEmitter<any> = new EventEmitter<any>();
  @Output() closePicker: EventEmitter<'backdropClick' | 'close'> = new EventEmitter<'backdropClick' | 'close'>();
  @Input() required: boolean;
  @Input() minDate: Date = null;
  @Input() maxDate: Date = null;
  @Input() autofocus = false;
  @Input() defaultText: string;
  @Input() dataType: EDatatype.LOCALDATE | EDatatype.LONG = EDatatype.LONG;
  // executes for each change made
  @Output() changes: EventEmitter<(number | string)[]> = new EventEmitter<(number | string)[]>();

  // reference to active calendar overlay
  calendarRef: IOverlayData;

  // form group for reactive form
  formGroup: UntypedFormGroup;

  // list of linked datepickers
  private _otherPickers: Datepicker[];

  constructor(
    private overlayApi: OverlayService,
    protected templateApi: TemplateService,
    protected zone: NgZone,
    private translate: TranslateService,
    private configApi: ConfigService
  ) {
    super(templateApi, zone);

    this.formGroup = new UntypedFormGroup({
      start: new UntypedFormControl(new Date()),
      end: new UntypedFormControl(new Date()),
    });
  }

  /**
   * open calendar with picker
   * @param {MouseEvent} elementRef mouse event
   * @returns void
   */
  openCalendarPicker(evt: MouseEvent, elementRef: ElementRef | HTMLElement): void {
    // return if there is already a picker opened
    if (this.calendarRef) {
      return;
    }

    // stop entering input again
    // @todo sebastian remove this line
    evt.stopPropagation();
    const d: Datepicker = new Datepicker();
    d.setDatepickerType(this.type);
    d.setDatepickerMode(this.mode);

    const minDate = this.minDate
      ? this.minDate
      : this.entryElement?.getValue<EntryElementValue>()?.getLowerBound()
      ? new Date(this.entryElement.getValue<EntryElementValue>().getLowerBound())
      : null;
    const maxDate = this.maxDate
      ? this.maxDate
      : this.entryElement?.getValue<EntryElementValue>()?.getUpperBound()
      ? new Date(this.entryElement.getValue<EntryElementValue>().getUpperBound())
      : null;

    const calendarOptions: ICalendarOptions = {
      start: moment(this.read('from')),
      end: moment(this.read('to')),
      mode: this.mode,
      id: this.id,
      type: this.type,
      timeStep: 900000,
      minDate: minDate,
      maxDate: maxDate,
    };

    const ref: IOverlayData = this.overlayApi.create(DateCalendarComponent, elementRef, calendarOptions, {
      overlayOrigin: 'bottom',
    });

    this.calendarRef = ref;
    (ref.componentRef.instance as DateCalendarComponent).afterClosed.subscribe(
      (d: OverlayCloseEvent<DateRange<Moment>>) => {
        if (d && d.type === 'close' && d.data) {
          this.onChanges(d.data);
        }
        this.calendarRef = null;
        this.closePicker.emit(d.type);
      }
    );

    // emits event that picker was opened
    this.openPicker.emit();
  }

  /**
   * parse string from time-picker input to valid format
   * @param {string | number} value value which should be parsed
   * @returns void
   */
  parseTimeString(value: number): void {
    this.updateData(value);
    this.entryElement?.setEdited(true);
    this.changes.emit([value]);
  }

  /**
   * updates input with new time
   * @param {number} time milliseconds
   * @returns void
   */
  private updateData(time: number): void {
    // const timeFormat: string = this.entryElement.getDateTimeFormat() ? this.entryElement.getDateTimeFormat().replaceAll('d', 'D') : this.translate.instant(`DATE.NGX.${this.type.toLowerCase()}`);
    if (
      this.entryElement &&
      this.entryElement.getValue<EntryElementValue>().getValue<number>() !== this.writeBack(time)
    ) {
      this.prevValue = this.entryElement.getValue<EntryElementValue>().getValue<number>();
      this.entryElement.setValue(this.entryElement.getValue<EntryElementValue>().setValue(this.writeBack(time)));
    }

    // updates input param
    this.selectedValues.from = this.writeBack(time);
  }

  /**
   * patch form controls
   * @input {DateRange<Moment>} d date range
   * @returns void
   */
  private patchControls(d: DateRange<Moment>, emitValueChange: boolean): void {
    const start: Moment = d?.start.isValid() ? d.start : null;
    const end: Moment = d?.end?.isValid() ? d.end : null;
    if (this.mode === EDatepickerMode.RANGE) {
      this.startControl.setValue(start, { emitEvent: false });
      this.endControl.setValue(end, { emitEvent: false });
    } else {
      // set date for start control
      this.startControl.setValue(start?.isValid() ? start?.format(this.dateFormat) : null, { emitEvent: false });
      start?.format(this.dateFormat);
      // update entry element
      if (
        this.entryElement &&
        this.entryElement.getValue<EntryElementValue>()?.getValue<number>() !==
          this.writeBack(start?.toDate().getTime())
      ) {
        this.prevValue = this.entryElement.getValue<EntryElementValue>().getValue<number>();
        this.entryElement.setValue(
          this.entryElement.getValue<EntryElementValue>().setValue(this.writeBack(start?.toDate().getTime())),
          emitValueChange
        );
      }
    }

    // updates last selected values
    this.lastStart = start?.isValid() ? start?.toDate() : null;
    this.lastEnd = end?.isValid() ? end?.toDate() : null;

    // updates input param
    this.selectedValues.from = this.writeBack(start?.toDate().getTime());
    this.selectedValues.to = this.writeBack(end?.toDate().getTime());
  }

  /**
   * execute whenever a value must be given to the outside
   * @param {DateRange<Moment>} event date range
   * @returns void
   */
  onChanges(event: DateRange<Moment>): void {
    const start: Date = isNaN(event.start?.toDate()?.getTime()) ? undefined : event.start?.toDate();
    const end: Date = isNaN(event.end?.toDate()?.getTime()) ? undefined : event.end?.toDate();

    if (!this.hasDateChanged(start, end)) {
      return;
    }

    this.lastStart = start;
    this.lastEnd = end;
    this.entryElement?.setEdited(true);

    // if both are zero
    if ((start === null || start === undefined) && (end === null || end === undefined)) {
      this.changes.emit([]);
    }
    // patches control
    this.patchControls(event, false); // do not emit value change here, because changes emitted at the end of this method

    this.validation();

    // if both fields set
    if (this.mode === EDatepickerMode.RANGE && start && end) {
      this.changes.emit([this.writeBack(start?.getTime()), this.writeBack(end?.getTime())]);
    } else {
      this.changes.emit([this.writeBack(start?.getTime())]);
    }
  }

  /**
   * Checks if the passed date has changed.
   * @param start Date to check against lastStart
   * @param end Date to check against lastEnd
   * @returns True if the date has changed, false otherwise
   */
  private hasDateChanged(start: Date, end: Date): boolean {
    return !(
      (start?.getTime() === this.lastStart?.getTime() &&
        end?.getTime() === this.lastEnd?.getTime() &&
        this.mode === EDatepickerMode.RANGE) ||
      (start?.getTime() === this.lastStart?.getTime() && this.mode === EDatepickerMode.SINGLE) ||
      (this.type === EDatepickerType.DATE &&
        this.mode === EDatepickerMode.SINGLE &&
        start?.getFullYear() === this.lastStart?.getFullYear() &&
        start?.getMonth() === this.lastStart?.getMonth() &&
        start?.getDate() === this.lastStart?.getDate())
    );
  }

  /**
   * Handles input field validation.
   */
  validation(): void {
    if (!this.entryElement?.isRequired()) {
      return;
    }

    // Invalid is checked in case no validation has taken place.
    // This is the case if the field is initially disabled.
    this.entryElement.setValid(this.formGroup.valid || !this.formGroup.invalid);
  }

  /**
   * checks if value fullfills bounds of component (minDate/maxDate),
   * entryElement (getLowerBound/getUpperBound) and from to order kept.
   * @returns undefined if validation passed or dummy error object if validation not passed
   */
  private checkMinMax(value: Moment, bound: 'min' | 'max', ofKey: 'from' | 'to') {
    if (!value) {
      return undefined;
    }

    const componentBound = bound === 'min' ? 'minDate' : 'maxDate';
    const getEntryBound = bound === 'min' ? 'getLowerBound' : 'getUpperBound';

    const _boundValue =
      this[componentBound] ||
      (isFinite(this.entryElement?.getValue<EntryElementValue>()?.[getEntryBound]())
        ? new Date(this.entryElement.getValue<EntryElementValue>()[getEntryBound]())
        : undefined);

    const boundValue = _boundValue && moment(_boundValue);

    const _testedValue =
      this.mode !== EDatepickerMode.RANGE
        ? null
        : ofKey === 'to' && bound === 'min'
        ? this.read('from')
        : ofKey === 'from' && bound === 'max'
        ? this.read('to')
        : null;

    const testedValue = _testedValue ? moment(_testedValue) : undefined;

    let isValid = true;
    if (bound === 'min') {
      isValid = (!boundValue || value.diff(boundValue) >= 0) && (!testedValue || value.diff(testedValue) >= 0);
    } else {
      // bound === "max"
      isValid = (!boundValue || value.diff(boundValue) <= 0) && (!testedValue || value.diff(testedValue) <= 0);
    }

    return isValid ? undefined : { [bound]: bound };
  }

  /**
   * exectuted for each link connected to the current picker
   * @param {ALink[]} links list of connected links
   * @returns void
   */
  _afterLinksLoaded(links: ALink[]): void {
    (this._otherPickers || []).forEach((p: Datepicker) =>
      p.removeEventListener(this.getComponentId(), EEntryElementEvents.VALUE_CHANGED)
    );
    this._otherPickers = [];

    of(null)
      .pipe(delay(0))
      .subscribe(() => {
        links.forEach((l: ALink) => {
          // is starting element
          if (l.getStartId() === this.entryElement.getId()) {
            const endPicker: Datepicker = this.templateApi.getElementById<Datepicker>(
              l.getEndId(),
              this.entryElement.getResourceId()
            );
            if (endPicker) {
              this._otherPickers.push(endPicker);
              // if endpicker has no value set it to the startpicker value
              if (
                this.entryElement.getValue<EntryElementValue>()?.getValue() &&
                !endPicker.getValue<EntryElementValue>()?.getValue()
              ) {
                const d: Date = new Date(this.entryElement.getValue<EntryElementValue>().getValue());
                endPicker.getValue<EntryElementValue>()?.setValue(d);
              }
              endPicker.addEventListener(
                this.getComponentId(),
                EEntryElementEvents.VALUE_CHANGED,
                (ev: CustomEvent) => {
                  this.handleDateLinkEnd(l, endPicker);
                }
              );
            } else {
              console.error(`Can't find connected datepicker with id ${l.getEndId()}`, this.entryElement);
            }
          }

          // is end element
          if (l.getEndId() === this.entryElement.getId()) {
            const startPicker: Datepicker = this.templateApi.getElementById<Datepicker>(
              l.getStartId(),
              this.entryElement.getResourceId()
            );
            if (startPicker) {
              this._otherPickers.push(startPicker);
              // if starpicker has no value set it to the endpicker value
              if (
                this.entryElement.getValue<EntryElementValue>()?.getValue() &&
                !startPicker.getValue<EntryElementValue>()?.getValue()
              ) {
                const d: Date = new Date(this.entryElement.getValue<EntryElementValue>().getValue());
                startPicker.getValue<EntryElementValue>()?.setValue(d);
              }
              startPicker.addEventListener(
                this.getComponentId(),
                EEntryElementEvents.VALUE_CHANGED,
                (ev: CustomEvent) => {
                  this.handleDateLinkStart(l, startPicker);
                }
              );
            } else {
              console.error(`Can't find connected datepicker with id ${l.getStartId()}`, this.entryElement);
            }
          }
        });
      });
  }

  /**
   * Called if endpicker value changes
   */
  private handleDateLinkEnd(link: ALink, endPicker: Datepicker): void {
    const endPickerValue = endPicker.getValue<EntryElementValue>().getValue<number>();
    const endPickerDate: Date = new Date(endPickerValue);
    const thisStartPickerValue = this.entryElement.getValue<EntryElementValue>().getValue<number>();
    const thisStartPickerDate: Date = new Date(thisStartPickerValue);

    if (!endPickerValue) {
      return;
    }

    switch (link.getType()) {
      case ELinkType.START_AND_END_TIME:
        if (this.entryElement.isDisabled() && thisStartPickerValue > endPickerValue) {
          endPicker.getValue<EntryElementValue>().setValue(thisStartPickerDate);
        } else if (thisStartPickerValue === null || thisStartPickerValue > endPickerValue) {
          const dr: DateRange<Moment> = new DateRange(moment(endPickerDate.getTime()), moment(endPickerDate.getTime()));
          this.onChanges(dr);
        }
        break;
      case ELinkType.SHIFT_TIME:
        const dateDiff = new Date(endPicker.getRef()?.prevValue).getTime() - thisStartPickerDate.getTime();

        if (this.entryElement.isDisabled() && thisStartPickerValue > endPickerValue) {
          endPicker.getValue<EntryElementValue>().setValue(endPicker.getRef()?.prevValue); // reset to old value
        } else if (thisStartPickerValue > endPickerValue) {
          const newStartDate: Date = new Date(endPickerDate.getTime() - dateDiff);
          const dr: DateRange<Moment> = new DateRange(moment(newStartDate.getTime()), moment(newStartDate.getTime()));
          this.onChanges(dr);
        }
        break;
      case ELinkType.ADJUST_TIME_DIFFERENCE:
        if (thisStartPickerValue === null || isNaN(thisStartPickerValue)) {
          // start is empty -> do nothing
          return;
        }

        const endDateDiff = new Date(endPicker.getRef()?.prevValue).getTime() - endPickerDate.getTime();
        const newStartDate: Date = new Date(thisStartPickerDate.getTime() - endDateDiff);
        const dr: DateRange<Moment> = new DateRange(moment(newStartDate.getTime()), moment(endPickerDate.getTime()));
        this.onChanges(dr);
        break;
      default:
        console.error('Unknown link type for date picker', link.getType());
        break;
    }
  }

  /**
   * Called if endpicker value changes
   */
  private handleDateLinkStart(link: ALink, startPicker: Datepicker): void {
    const startPickerValue = startPicker.getValue<EntryElementValue>().getValue<number>();
    const startPickerDate: Date = new Date(startPickerValue);
    const thisEndPickerValue = this.entryElement.getValue<EntryElementValue>().getValue<number>();
    const thisEndPickerDate: Date = new Date(thisEndPickerValue);

    if (!startPickerValue) {
      return;
    }

    switch (link.getType()) {
      case ELinkType.START_AND_END_TIME:
        if (this.entryElement.isDisabled() && thisEndPickerValue < startPickerValue) {
          startPicker.getValue<EntryElementValue>().setValue(thisEndPickerDate);
        } else if (thisEndPickerValue === null || thisEndPickerValue < startPickerValue) {
          const dr: DateRange<Moment> = new DateRange(
            moment(startPickerDate.getTime()),
            moment(startPickerDate.getTime())
          );
          this.onChanges(dr);
        }
        break;
      case ELinkType.SHIFT_TIME:
        const dateDiff = new Date(startPicker.getRef()?.prevValue).getTime() - thisEndPickerDate.getTime();

        if (this.entryElement.isDisabled() && thisEndPickerValue < startPickerValue) {
          startPicker.getValue<EntryElementValue>().setValue(startPicker.getRef()?.prevValue); // reset to old value
        } else if (thisEndPickerValue === null || thisEndPickerValue < startPickerValue) {
          const newEndDate: Date = new Date(startPickerDate.getTime() - dateDiff);
          const dr: DateRange<Moment> = new DateRange(moment(newEndDate.getTime()), moment(newEndDate.getTime()));
          this.onChanges(dr);
        }
        break;
      case ELinkType.ADJUST_TIME_DIFFERENCE:
        // do nothing for endpicker
        break;
      default:
        console.error('Unknown link type for date picker', link.getType());
        break;
    }
  }

  ngOnInit(): void {
    this.entryElement?.setEdited(false);
    this.prevValue = this.entryElement?.getValue<EntryElementValue>()?.getValue();
    super.ngOnInit();
    this.init();
  }

  /**
   * parses string to moment date
   * @param {string} event input string
   * @returns void
   */
  parseDateString(parseYear = false): void {
    // if start or end in rangepicker are empty, return without any changes
    if (this.mode === EDatepickerMode.RANGE && (!this.startControl.value || !this.endControl.value)) {
      return;
    }

    const dStart: Moment = inputToValue(this.startControl.value, this.dateFormat, parseYear);
    const dEnd: Moment = inputToValue(this.endControl.value, this.dateFormat, parseYear);
    if (parseYear && dStart.year() < 100) {
      dStart.set('year', 2000 + dStart.year());
    }

    if ([dStart, dEnd].includes(undefined)) {
      return;
    }

    this.onChanges(new DateRange<Moment>(dStart, dEnd));
  }

  init(): void {
    const getValidator = (bound: 'min' | 'max', ofKey: 'from' | 'to') => (control: AbstractControl) => {
      const moment = inputToValue(control.value, this.dateFormat, true);
      return this.checkMinMax(moment, bound, ofKey);
    };

    this.startControl.addValidators([getValidator('min', 'from'), getValidator('max', 'from')]);

    if (this.mode === EDatepickerMode.RANGE) {
      this.endControl.addValidators([getValidator('min', 'to'), getValidator('max', 'to')]);
    }

    if (this.entryElement instanceof Datepicker) {
      this.entryElement?.setRef(this);
    }

    if (this.selectedValues.from && typeof this.selectedValues.from !== 'number') {
      // parse other dateformats than numbers
      if (this.selectedValues.from instanceof Date) {
        this.selectedValues.from = this.selectedValues.from.getTime();
      }
      if (this.selectedValues.to instanceof Date) {
        this.selectedValues.to = this.selectedValues.to.getTime();
      }
    } else if (this.type !== EDatepickerType.TIME) {
      // if inputs are zero, set them to null
      if (this.selectedValues.from === 0) {
        this.selectedValues.from = this.configApi.access().templates.Datepicker.zeroToDate
          ? new Date().getTime()
          : null;
      }

      if (this.selectedValues.to === 0) {
        this.selectedValues.to = this.configApi.access().templates.Datepicker.zeroToDate ? new Date().getTime() : null;
      }
    }

    if (this.defaultText) {
      this.startControl?.setValue(this.defaultText, { skipEmit: true });
    } else if (this.selectedValues) {
      this.patchControls(
        new DateRange<Moment>(moment(this.read('from')), moment(this.read('to') || this.read('from'))),
        true
      );
    }

    if (this.disabled) {
      this.formGroup.disable();
    } else {
      this.formGroup.enable();
    }
    this.validation();
  }

  /**
   * read input with timezone offset
   * @param {'from'|'to'} key where to read from
   * @returns number
   */
  read(key: 'from' | 'to'): number | string {
    if (this.selectedValues[key] === null || this.selectedValues[key] === undefined) {
      return null;
    }
    const d: Date = new Date(this.selectedValues[key]);
    if (this.dataType === EDatatype.LOCALDATE) {
      return this.selectedValues[key] as string;
    }
    const offset: number = this.type !== EDatepickerType.TIME && !this.useLocalTime ? d.getTimezoneOffset() * 60000 : 0;
    const v: number = this.selectedValues[key] as number;
    return v ? v + offset : v;
  }

  /**
   * add timezone offset back
   * @param {number} d timestamp
   * @returns number
   */
  writeBack(d: number): number | string {
    if (d === null || d === undefined) {
      return null;
    }
    const date: Date = new Date(d);
    const offset: number =
      this.type !== EDatepickerType.TIME && !this.useLocalTime ? date.getTimezoneOffset() * 60000 : 0;
    return this.dataType === EDatatype.LOCALDATE ? moment(date).format('YYYY-MM-DD') : d - offset;
  }

  /**
   * changes to input
   * @returns void
   */
  ngOnChanges(changes: SimpleChanges): void {
    // executes when values changed
    if (changes.selectedValues || changes.disabled || changes.type) {
      this.init();
    }
  }

  /**
   * hook triggers when component gets destroyed
   * @returns void
   */
  ngOnDestroy(): void {
    super.ngOnDestroy();
    (this._otherPickers || []).forEach((p: Datepicker) =>
      p.removeEventListener(this.getComponentId(), EEntryElementEvents.VALUE_CHANGED)
    );
    this.calendarRef?.componentRef.instance.cancel();
  }

  protected getRequiredMarker(): string {
    return this.hideRequiredMarker && this.entryElement?.isRequired() ? '* ' : '';
  }

  // getters //////////////////////////////////////////////////////////////////////////

  get startControl(): AbstractControl {
    return this.formGroup.get('start');
  }

  get endControl(): AbstractControl {
    return this.formGroup.get('end');
  }

  // dateformat to be displayed
  get dateFormat() {
    const entryDateFormat: string =
      this.entryElement instanceof Datepicker ? this.entryElement.getDateTimeFormat() : this.dateTimeFormat;
    const dateFormat: string =
      this.dataType === EDatatype.LOCALDATE
        ? 'YYYY-MM-DD'
        : entryDateFormat
        ? entryDateFormat.replaceAll('d', 'D')
        : this.translate.instant(`DATE.NGX.${this.type.toLowerCase()}`);

    return dateFormat;
  }
}

const inputToValue = (input: string, dateFormat: string, parseYear = false): Moment => {
  const buffer: Moment = moment(input, dateFormat);

  if (!(buffer.toDate() instanceof Date)) {
    return undefined;
  }

  if (parseYear && buffer.year() < 100) {
    buffer.set('year', 2000 + buffer.year());
  }
  return buffer;
};
