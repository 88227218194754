<h3 mat-dialog-title>
  <span *ngIf="user">{{ user.getFullname() }} - </span>{{ 'SECURITY.PASSWORD.force_change' | translate }}
</h3>
<div mat-dialog-content>
  <ul class="info-container">
    <h3 style="display: flex; align-items: center; margin: 8px 0px">
      <mat-icon>info</mat-icon><span style="margin-left: 5px">{{ 'SECURITY.PASSWORD.policy' | translate }}</span>
    </h3>
    <li *ngIf="passwordConfig.lowercase">{{ 'one lowercase' | translate }}</li>
    <li *ngIf="passwordConfig.uppercase">{{ 'one uppercase' | translate }}</li>
    <li *ngIf="passwordConfig.decimal">{{ 'one number' | translate }}</li>
    <li *ngIf="encodedSpecialCharacters">
      {{ 'one special character' | translate }}
      <span style="background-color: #ffff00">{{ encodedSpecialCharacters }}</span>
    </li>
    <li>
      {{ 'SECURITY.PASSWORD.LENGTH.min' | translate : { value: passwordConfig.minPasswordLength } }},
      {{ 'SECURITY.PASSWORD.LENGTH.max' | translate : { value: passwordConfig.maxPasswordLength } }}
    </li>
  </ul>
  <form [formGroup]="passwordForm" *ngIf="!passwordChanged" (submit)="passwordForm?.valid ? confirm($event) : null">
    <div class="error-box" *ngIf="errorList?.length > 0">
      <div *ngFor="let error of errorList">
        {{ 'SECURITY.ERRORS.LABEL.' + error | translate }}
      </div>
    </div>
    <div>
      <mat-form-field appearance="outline">
        <mat-label>{{ 'old password' | translate }}</mat-label>
        <input
          (keyup.enter)="confirm()"
          (change)="reset()"
          autocomplete="current-password"
          [type]="curPWVisibility ? 'text' : 'password'"
          required
          formControlName="oldPassword"
          matInput
          [placeholder]="'old password' | translate"
        />
        <button
          type="button"
          [saxmsTooltip]="'SECURITY.PASSWORD.' + (curPWVisibility ? 'hide' : 'show') | translate"
          mat-icon-button
          matSuffix
          (click)="curPWVisibility = !curPWVisibility"
        >
          <mat-icon>{{ curPWVisibility ? 'visibility' : 'visibility_off' }}</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field appearance="outline">
        <mat-label>{{ 'new password' | translate }}</mat-label>
        <input
          (keyup.enter)="confirm()"
          (change)="reset()"
          autocomplete="new-password"
          [type]="newPWvisibility ? 'text' : 'password'"
          required
          #newPw
          formControlName="newPassword"
          matInput
          [placeholder]="'new password' | translate"
        />
        <mat-error *ngIf="passwordForm.get('newPassword').invalid && passwordForm.get('newPassword').touched">
          <ng-container *ngIf="passwordForm.get('newPassword').hasError('required'); else error2">
            {{ 'SECURITY.ERRORS.required' | translate }}
          </ng-container>
          <ng-template #error2>
            <ng-container *ngIf="passwordForm.get('newPassword').hasError('minlength'); else error3">
              {{
                'SECURITY.ERRORS.minlength'
                  | translate : { value: passwordForm.get('newPassword').errors.minlength.requiredLength }
              }}
            </ng-container>
          </ng-template>
          <ng-template #error3>
            <ng-container *ngIf="passwordForm.get('newPassword').hasError('special'); else error4">
              {{ 'SECURITY.ERRORS.special' | translate }}
            </ng-container>
          </ng-template>
          <ng-template #error4>
            <ng-container *ngIf="passwordForm.get('newPassword').hasError('decimal'); else error5">
              {{ 'SECURITY.ERRORS.decimal' | translate }}
            </ng-container>
          </ng-template>
          <ng-template #error5>
            <ng-container *ngIf="passwordForm.get('newPassword').hasError('uppercase'); else error6">
              {{ 'SECURITY.ERRORS.uppercase' | translate }}
            </ng-container>
          </ng-template>
          <ng-template #error6>
            <ng-container *ngIf="passwordForm.get('newPassword').hasError('lowercase')">
              {{ 'SECURITY.ERRORS.lowercase' | translate }}
            </ng-container>
          </ng-template>
        </mat-error>
        <button
          type="button"
          (click)="generatePassword($event)"
          mat-icon-button
          matSuffix
          [saxmsTooltip]="'SECURITY.PASSWORD.generate' | translate"
        >
          <mat-icon>lock</mat-icon>
        </button>
        <button
          type="button"
          [saxmsTooltip]="'SECURITY.PASSWORD.' + (newPWvisibility ? 'hide' : 'show') | translate"
          mat-icon-button
          matSuffix
          (click)="newPWvisibility = !newPWvisibility"
        >
          <mat-icon>{{ newPWvisibility ? 'visibility' : 'visibility_off' }}</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field appearance="outline">
        <mat-label>{{ 'repeat new password' | translate }}</mat-label>
        <input
          (keyup.enter)="confirm()"
          (change)="reset()"
          autocomplete="new-password"
          [type]="repeatedPWvisibility ? 'text' : 'password'"
          required
          formControlName="repeatedPassword"
          matInput
          [placeholder]="'repeat new password' | translate"
        />

        <button
          type="button"
          [saxmsTooltip]="'SECURITY.PASSWORD.' + (repeatedPWvisibility ? 'hide' : 'show') | translate"
          mat-icon-button
          matSuffix
          (click)="repeatedPWvisibility = !repeatedPWvisibility"
        >
          <mat-icon>{{ repeatedPWvisibility ? 'visibility' : 'visibility_off' }}</mat-icon>
        </button>
      </mat-form-field>
    </div>

    <mat-error *ngIf="!passwordForm.valid && passwordForm.get('repeatedPassword').touched">
      <ng-container *ngIf="passwordForm.errors?.same">
        {{ 'SECURITY.ERRORS.same' | translate }}
      </ng-container>
    </mat-error>
  </form>
  <ng-container *ngIf="passwordChanged">
    {{ 'SECURITY.PASSWORD.changed_by_value' | translate : { value: 5 } }}
  </ng-container>
</div>
<div mat-dialog-actions>
  <button type="button" *ngIf="!passwordChanged" (click)="cancel()" mat-stroked-button>
    {{ 'BUTTON.cancel' | translate }}
  </button>
  <span class="spacer"></span>
  <button
    type="confirm"
    *ngIf="!passwordChanged"
    (click)="confirm($event)"
    [disabled]="!passwordForm.valid"
    mat-stroked-button
  >
    {{ 'BUTTON.confirm' | translate }}
  </button>
</div>
