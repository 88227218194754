import { ETemplateType } from 'frontend/src/dashboard/model/resource/template-type';
import { Action } from '../button/action/action';
import { Button } from '../button/button';
import { HMI } from '../tree/tree-item';
import { ToolbarModifier } from './toolbar-modifier';

export class HierarchicalMenuItem extends HMI {
  private createdFieldIdentifier: string;
  private nameFieldIdentifier: string;
  private nameRequired: boolean;
  private nameUnique: boolean;
  private restListSizeUrl: string;
  private forceReloadByReOpen: boolean;
  private updateContentRestUrl: string; // updates content of item
  private onChangeCB: () => void;
  private contentRestUrl: string;
  private toolbarModifier: ToolbarModifier;
  private onCustomCloseTemplateAction?: Action;
  private showExtendedFilter?: boolean;
  /**
   * Button to execute custom save behavior TODO: rename this to onCustomSaveButton in the next release (2.28)
   */
  protected onCustomSaveAction: Button;

  public isShowExtendedFilter(): boolean {
    return this.showExtendedFilter !== false;
  }

  public setShowExtendedFilter(showExtendedFilter: boolean): this {
    this.showExtendedFilter = showExtendedFilter;
    return this;
  }

  public getToolbarModifier(): ToolbarModifier {
    return this.toolbarModifier;
  }

  public setToolbarModifier(toolbarModifier: ToolbarModifier): this {
    this.toolbarModifier = toolbarModifier;
    return this;
  }

  public getContentRestUrl(): string {
    return this.contentRestUrl;
  }

  public setContentRestUrl(contentRestUrl: string): this {
    this.contentRestUrl = contentRestUrl;
    return this;
  }

  public setReinitializationCB(cb: () => void): this {
    this.onChangeCB = cb;
    return this;
  }

  public reinitialize(): void {
    if (this.onChangeCB) {
      this.onChangeCB();
    }
  }

  public getUpdateContentRestUrl(): string {
    return this.updateContentRestUrl;
  }

  public setUpdateContentRestUrl(updateContentRestUrl: string): this {
    this.updateContentRestUrl = updateContentRestUrl;
    return this;
  }

  public isForceReloadByReOpen(): boolean {
    return typeof this.forceReloadByReOpen === 'boolean' ? this.forceReloadByReOpen : false;
  }

  public setForceReloadByReOpen(forceReloadByReOpen: boolean): this {
    this.forceReloadByReOpen = forceReloadByReOpen;
    return this;
  }

  public getRestListSizeUrl(): string {
    return this.restListSizeUrl;
  }

  public setRestListSizeUrl(restListSizeUrl: string): this {
    this.restListSizeUrl = restListSizeUrl;
    return this;
  }

  public getCreatedFieldIdentifier(): string {
    return this.createdFieldIdentifier;
  }

  public setCreatedFieldIdentifier(createdFieldIdentifier: string): this {
    this.createdFieldIdentifier = createdFieldIdentifier;
    return this;
  }

  public getNameFieldIdentifier(): string {
    return this.nameFieldIdentifier;
  }

  public setNameFieldIdentifier(nameFieldIdentifier: string): this {
    this.nameFieldIdentifier = nameFieldIdentifier;
    return this;
  }

  public isNameRequired(): boolean {
    return this.nameRequired;
  }

  public setNameRequired(nameRequired: boolean): this {
    this.nameRequired = nameRequired;
    return this;
  }

  public isNameUnique(): boolean {
    return this.nameUnique;
  }

  public setNameUnique(nameUnique: boolean): this {
    this.nameUnique = nameUnique;
    return this;
  }

  public getOnCustomCloseTemplateAction(): Action | undefined {
    return this.onCustomCloseTemplateAction;
  }

  public setOnCustomCloseTemplateAction(onCustomCloseTemplateAction?: Action): this {
    this.onCustomCloseTemplateAction = onCustomCloseTemplateAction;
    return this;
  }

  public getOnCustomSaveAction(): Button {
    return this.onCustomSaveAction;
  }

  public setOnCustomSaveAction(onCustomSaveAction: Button): this {
    this.onCustomSaveAction = onCustomSaveAction;
    return this;
  }

  constructor() {
    super();
    this.setType(ETemplateType.HIERARCHIC_MENU_ITEM);
  }
}
