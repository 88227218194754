import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA } from '@angular/material/legacy-snack-bar';

/**
 * @title Snack-bar with a custom component
 */
@Component({
  selector: 'system-message',
  templateUrl: 'system-message.html',
  styleUrls: ['system-message.scss'],
})
export class SystemMessageComponent {
  public text: string;
  public type: string;
  public callback: any;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {
    this.text = this.data.text ? this.data.text : '';
    this.type = this.data.type ? this.data.type : '';
    this.callback = this.data.action ? this.data.action : null;
  }
}
