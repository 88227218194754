import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { of, Subject } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';
import { AttributeCategory, Region } from '../../hotspot/hotspot';

@Component({
  selector: 'template-hotspot-region-sidebar',
  templateUrl: './hotspot-region-sidebar.component.html',
  styleUrls: ['./hotspot-region-sidebar.component.scss'],
  providers: [],
})
export class HotspotRegionSidebarComponent implements OnInit, OnDestroy {
  @Input() regions: Region[] = [];
  @Input() attributeCategories: AttributeCategory[] = [];
  @Input() currentAttribute: AttributeCategory;

  @Output() afterHoverRegion: EventEmitter<Region> = new EventEmitter();
  @Output() afterAttributeSelect: EventEmitter<AttributeCategory> = new EventEmitter();

  // public currentAttribute: AttributeCategory;
  public searchString = '';
  public searchControl = new UntypedFormControl('', []);
  private searchTimeoutId: Subject<void> = new Subject<void>();
  ngOnInit(): void {
    this.searchControl.setValue(this.searchString);

    if (this.attributeCategories.length > 0 && !this.currentAttribute) {
      this.currentAttribute = this.attributeCategories[0];
      of(null)
        .pipe(delay(0))
        .subscribe(() => {
          this.afterAttributeSelect.emit(this.currentAttribute);
        });
    }
  }

  ngOnDestroy(): void {
    this.searchTimeoutId.next();
    this.searchTimeoutId.complete();
  }

  public searchRegion(event) {
    this.searchTimeoutId.next();
    of(null)
      .pipe(delay(300), takeUntil(this.searchTimeoutId))
      .subscribe(() => {
        this.searchString = this.searchControl.value;
      });
  }

  public fill(obj, array: string[], value?: number) {
    if (array.length === 0) {
      return;
    }
    const key = array.shift();
    if (!obj[key]) {
      obj[key] = array.length === 0 ? value : {};
    }
    this.fill(obj[key], array, value);
    return obj;
  }

  public changeAttributeCategory(change: MatSelectChange) {
    this.currentAttribute = this.attributeCategories.find((attributes) => attributes.getId() === change.value);
    this.afterAttributeSelect.emit(this.currentAttribute);
  }

  public getRegions(): Region[] {
    return this.regions.filter((region) => region.getName().includes(this.searchString));
  }

  public getCurrentAttributeValue(region: Region) {
    const regionAttribute = region
      .getRegionAttributes()
      .find((value) => value.getAttributeKey() === this.currentAttribute.getId());
    if (regionAttribute) {
      return regionAttribute.getValue();
    }
    return '';
  }
  public getCurrentRegionColor(region: Region) {
    const regionAttribute = region
      .getRegionAttributes()
      .find((value) => value.getAttributeKey() === this.currentAttribute.getId());
    if (regionAttribute) {
      const regionValue = regionAttribute.getValue();
      return this.currentAttribute.getColorScheme().getColorByValue(regionValue);
    }
    return 'grey';

    // return 'green';
  }
}
