import { NgModule } from '@angular/core';
import { PopupComponent } from './popup.component';
import { PopUpService } from './popup.service';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'frontend/src/dashboard/core/material/material.module';

@NgModule({
  imports: [MaterialModule, CommonModule],
  declarations: [PopupComponent],
  providers: [PopUpService],
  exports: [PopupComponent],
})
export class PopupModule {}
