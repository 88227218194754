<div class="group" role="group" [formGroup]="parts" (focusin)="onFocusIn($event)" (focusout)="onFocusOut($event)">
  <input
    #day
    class="day two-numbers"
    formControlName="day"
    maxlength="2"
    size="2"
    [placeholder]="'DATE.DD' | translate"
    (input)="handleInput($event, parts.controls.day, month)"
    (keydown.arrowright)="autoFocusNext(parts.controls.day, month, $event)"
  />
  <span>.</span>
  <input
    #month
    class="month two-numbers"
    formControlName="month"
    maxlength="2"
    placeholder="MM"
    (input)="handleInput($event, parts.controls.month, year)"
    (keydown.backspace)="autoFocusPrev(parts.controls.month, day, $event)"
    (keydown.arrowleft)="autoFocusPrev(parts.controls.month, day, $event)"
    (keydown.arrowright)="autoFocusNext(parts.controls.month, year, $event)"
  />
  <span>.</span>
  <input
    #year
    class="year four-numbers"
    formControlName="year"
    maxlength="4"
    [placeholder]="'DATE.YYYY' | translate"
    (input)="handleInput($event, parts.controls.year, undefined, true)"
    (keydown.backspace)="autoFocusPrev(parts.controls.year, month, $event, true)"
    (keydown.arrowleft)="autoFocusPrev(parts.controls.year, month, $event, true)"
    (keydown.arrowright)="autoFocusNext(parts.controls.year, undefined, $event, true)"
  />
  <ng-container *ngIf="_showTime">
    <span>&nbsp;</span>
    <input
      #hour
      class="two-numbers"
      formControlName="hour"
      maxlength="2"
      placeholder="hh"
      (input)="handleInput($event, parts.controls.hour, minute)"
      (keydown.backspace)="autoFocusPrev(parts.controls.hour, year, $event)"
      (keydown.arrowleft)="autoFocusPrev(parts.controls.hour, year, $event)"
      (keydown.arrowright)="autoFocusNext(parts.controls.hour, minute, $event)"
    />
    <span>:</span>
    <input
      #minute
      class="two-numbers"
      formControlName="minute"
      maxlength="2"
      placeholder="mm"
      (input)="handleInput($event, parts.controls.minute)"
      (keydown.backspace)="autoFocusPrev(parts.controls.minute, hour, $event)"
      (keydown.arrowleft)="autoFocusPrev(parts.controls.minute, hour, $event)"
    />
  </ng-container>
</div>
