import { ComponentFactoryResolver, Injectable, Injector } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Observer, Subject } from 'rxjs';
import { ExperimentService2, IExperiment } from '../data/experiment/experiment.service';
import { Widget } from '../data/grid/data/widget';
import { PopUpService } from '../popups/popup.service';
import { WidgetPopupComponent } from '../popups/widgetspopup/widgetPopup.component';
import { ConfirmDialog } from '../shared/dialogs/confirm.dialog';
import { SystemMessageComponent } from '../system/system-messages/system-message.component';
import { AbstractGridService } from './abstract-grid.service';
import { GridAction } from './grid-action.enum';
import { GridService } from './grid.service';

@Injectable({
  providedIn: 'root',
})
export class GridGloablService {
  public currentView: any;
  public isGrid = false;

  public _actions: Observable<any>;
  public _actionSubject: Subject<any>;

  public _onWidget: Observable<any>;
  public _widgetSubject: Subject<any>;

  public _onView: Observable<any>;
  public _viewSubject: Subject<any>;

  public _onCreateView: Observable<any>;
  public _viewCreateSubject: Subject<any>;

  public _onDeleteView: Observable<any>;
  public _viewDeleteSubject: Subject<any>;

  public _compareCharts: Observable<any>;
  public _compareChartsSubject: Subject<any>;

  public _onChooseExperiment: Observable<any>;
  public _onChooseExperimentSub: Subject<any>;

  public _changeAbstractService: Observable<any>;
  public _changeAbstractServiceSub: Subject<any>;

  public _onChangeWidgetSizeService: Observable<any>;
  public _onChangeWidgetSizeServiceSub: Subject<void>;

  public _onActionObserver: Observable<any>;
  private _onActionSubject: Subject<any>;

  public gridService: AbstractGridService;
  public gridType: string;

  getViewObject2: BehaviorSubject<any> = new BehaviorSubject(null);
  constructor(
    public dialog: MatDialog,
    public systemMessage: MatSnackBar,
    private trx: TranslateService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private popUpService: PopUpService,
    private experimentService2: ExperimentService2,
    private gridService2: GridService
  ) {
    this._actionSubject = new Subject<any>();
    this._actions = this._actionSubject.asObservable();

    this._onActionSubject = new Subject<any>();
    this._onActionObserver = this._onActionSubject.asObservable();

    this._widgetSubject = new Subject<any>();
    this._onWidget = this._widgetSubject.asObservable();

    this._onChooseExperimentSub = new Subject<any>();
    this._onChooseExperiment = this._onChooseExperimentSub.asObservable();

    this._viewSubject = new Subject<any>();
    this._onView = this._viewSubject.asObservable();

    this._viewCreateSubject = new Subject<any>();
    this._onCreateView = this._viewCreateSubject.asObservable();

    this._viewDeleteSubject = new Subject<any>();
    this._onDeleteView = this._viewDeleteSubject.asObservable();

    this._compareChartsSubject = new Subject<any>();
    this._compareCharts = this._compareChartsSubject.asObservable();

    this._changeAbstractServiceSub = new Subject<any>();
    this._changeAbstractService = this._changeAbstractServiceSub.asObservable();

    this._onChangeWidgetSizeServiceSub = new Subject<any>();
    this._onChangeWidgetSizeService = this._onChangeWidgetSizeServiceSub.asObservable();

    this.init();
  }

  experimentId;
  init() {
    this._onActionObserver.subscribe((data) => {
      if (data.type === GridAction.POPUP) {
        const injector = Injector.create([]);
        const factory = this.componentFactoryResolver.resolveComponentFactory(WidgetPopupComponent);
        // We create a factory out of the component we want to create

        // We create the component using the factory and the injector
        const componentRef = factory.create(injector);

        const popUpWidget: any = new Widget();
        /* let popUpWidget:Widget = {
                    componentRef: componentRef,
                    component: null,
                    widget: data.data,
                    data: null,
                    experimentId: this.experimentId,
                    oldStoreWidgetId: (data.data) ? data.data.id : ""
                };*/

        popUpWidget.componentRef = componentRef;

        popUpWidget.component = null;
        popUpWidget.widget = { id: data.data.id };
        popUpWidget.widgetBaseId = data.data.basedOnWidgetTemplateRefId;
        popUpWidget.data = null;
        popUpWidget.experimentId = this.experimentId;
        popUpWidget.oldStoreWidgetId = data.data ? data.data.id : '';

        popUpWidget.closeButton = true;
        let gridServiceType = null;

        if (data.data.handlingType === 'WIDGET') {
          gridServiceType = this.gridService2;
          this.setGridService(this.gridService2, '');
        } else if (data.data.handlingType === 'WIDGET_BDE') {
        }

        gridServiceType.getWidgetTemplate(this.experimentId, data.data.id).subscribe(
          (template) => {
            popUpWidget.component = this.checkRepresentationType(template.availableVisualRepresentationTyps[0]);
            componentRef.instance.widget = popUpWidget;
            this.popUpService.open(popUpWidget);
          },
          (err) => {
            console.error(err);
          }
        );
      }
    });

    this.experimentService2.getCurrentExperiment().subscribe((experiment: IExperiment) => {
      this.experimentId = experiment ? experiment.id : '';
    });
  }

  public setGridService(a: AbstractGridService, gridType: string) {
    this.gridType = gridType;
    this.gridService = a;
  }

  public checkRepresentationType(type: string) {
    switch (type) {
      case 'LIST':
      case 'TABLE':
        return 'table';
      case 'KPITABLE':
        return 'kpi';
      case 'GANTT':
        return 'gantt';
      case 'PIECHART':
        return 'piechart';
      case 'BARCHART':
        return 'barchart';
      case 'LINECHART':
        return 'linechart';
      case 'COMBINED_TIMED_DATA':
        return 'combinedWidget';
      case 'ERROR':
        return 'error';
    }
  }

  public trigger(data: any): void {
    switch (data.type) {
      case GridAction.POPUP:
        this._onActionSubject.next({ type: GridAction.POPUP, data: data.data });
        break;
      case GridAction.ADD_WIDGET:
        this._onActionSubject.next({
          type: GridAction.ADD_WIDGET,
          data: data.data,
        });
        break;
      default:
        console.error('No valid trigger type');
    }
  }

  public getGridService(): AbstractGridService {
    return this.gridService;
  }

  public getGridType(): string {
    return this.gridType;
  }
  public addWidget(widget: any): void {
    this._widgetSubject.next(widget);
  }

  public changeView(view: any): void {
    this.currentView = view;
    this.isGrid = true;
    this._viewSubject.next(view);
    this.getViewObject2.next(view);
  }

  public getViewObject() {
    return this.getViewObject2.asObservable();
  }

  public action(event: any): void {
    this._actionSubject.next(event);
  }

  public onChooseExperiment(experiment: any) {
    this._onChooseExperimentSub.next(experiment);
  }

  public compareCharts(event: any): void {
    this._compareChartsSubject.next(event);
  }

  public changeAbstractService(gridType: string) {
    this._changeAbstractServiceSub.next(gridType);
  }

  public onChangeWidgetSize() {
    this._onChangeWidgetSizeServiceSub.next();
  }

  /**
   * creates a new workspace
   * @param type add or edit
   * @param resultReport resultreport
   * @param view (optional) for edit-dialog
   */
  public async addWorkspace(type: string, resultReport: any, view?: any): Promise<any> {
    return new Promise((resolve) => {
      this.showTabDialog(type, resultReport, view).subscribe((result) => {
        this._viewCreateSubject.next(result);
        resolve(result);
      });
    });
  }

  /**
   * deletes a view
   * @param resultReportId id of the resultreport
   * @param tabId id of the tab
   */
  public deleteView(resultReportId: string, tabId: string): Observable<any> {
    return this.getGridService().deleteView(resultReportId, tabId);
  }

  /**
   * creates a new workspace
   * @param type add or edit
   * @param resultReport resultreport
   * @param view (optional) for edit-dialog
   */

  public showTabDialog(type: string, resultReport: any, view?: any): Observable<any> {
    const self = this;
    return new Observable<any>((observer: Observer<any>) => {
      /* let dialogRef = this.dialog.open(GridDialog, {
        data: {
          type: type,
          resultReport: resultReport,
          view: view,
          globalGridService: self,
        },
      });
      // onCloseDialog
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          observer.next(result);
          //this.gridData.views.push(result);
            //        this.globalGridService.createView(result);
        }
      });*/
    });
  }

  /**
   * removes a workspace
   * @param viewId id of the view to remove
   */
  public async removeWorkspace(resultReport: any, viewId: any) {
    const text = (await this.trx.get('successfullyRemovedWorkspace'))['value'];

    const dialogRef = this.dialog.open(ConfirmDialog, {
      data: {
        title: 'Delete workspace?',
        text: '',
      },
    });
    // onCloseDialog
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.deleteView(resultReport.resultReport.id, viewId).subscribe(
          () => {
            this._viewDeleteSubject.next(viewId);
            this.systemMessage.openFromComponent(SystemMessageComponent, {
              duration: 5000,
              data: {
                type: 'success',
                text: text,
              },
            });
          },
          (err) => {
            console.error(err);
          }
        );
      }
    });
  }
}
