import { GanttCanvasShift } from '../../data-handler/data-structure/data-structure';
import { IGanttShiftResizeEvent } from '../../edit-shifts/shift-resizing/resize-events/resize-event.interface';
import { GanttShiftsAfterRenderEvent } from '../../shifts/shift-builder';
import { GanttXAxis } from '../../x-axis/x-axis';

/**
 * Class which gives ability to draw a stroke on top of time periods.
 * Has the performance advantage that there is no recalculation/translation during zooming necessary.
 * @keywords plugin, time, period, stroke, line, status, blocking, interval
 * @plugin timeperiod-marker
 */
export class GanttTimePeriodStrokeHandler {
  private readonly _strokeDataSet = new Map<string, GanttTimePeriodStrokeHandlerDataItem>();

  constructor(private _xAxisBuilder: GanttXAxis) {}

  /**
   * Adds stroke on top of time period by given time period data.
   * Stroke drawing works with "stroke-dasharray" attribute to have possibility to draw stroke only on one side of the rect.
   * @param event
   */
  public buildStrokesByEvent(event: GanttShiftsAfterRenderEvent) {
    if (event.selection.empty()) return;
    this._updateStroke(event.selection, null);
  }

  /**
   * Updates stroke by resize event (aspecialy stroke width).
   * @param event
   */
  public updateStrokesByEvent(event: IGanttShiftResizeEvent): void {
    if (event.shiftSelection.empty()) return;
    this._updateStroke(event.shiftSelection, parseFloat(event.shiftSelection.attr('width')));
  }

  /**
   * Updates stroke width for given time period element(s).
   * @param timePeriodSelection D3 selection of time period element(s).
   * @param strokeLength Length of stroke. Defaults to length of shift canvas data.
   */
  private _updateStroke(
    timePeriodSelection: d3.Selection<SVGRectElement, GanttCanvasShift, d3.BaseType, undefined>,
    strokeLength: number
  ): void {
    const zoom = this._xAxisBuilder.getLastZoomEvent();

    timePeriodSelection
      .filter((d) => {
        return this._strokeDataSet.get(d.id) != null;
      })
      .attr('stroke-dasharray', (d) => {
        return strokeLength
          ? strokeLength
          : (d.width * zoom.k).toString() + ' ' + (2 * d.height + d.width * zoom.k).toString();
      })
      .style('stroke', (d) => {
        return this._strokeDataSet.get(d.id).color;
      })
      .style('stroke-width', (d) => {
        return this._strokeDataSet.get(d.id).width;
      });
  }

  //
  // GETTER & SETTER
  //

  /**
   * Adds one
   * @param id Id of time inteval whrere stroke should be added.
   * @param dataItem Stroke settings.
   */
  public addStrokeDataItem(id: string, dataItem: GanttTimePeriodStrokeHandlerDataItem): void {
    this._strokeDataSet.set(id, dataItem);
  }
}

/**
 * Data handling for stroke settings.
 * @keywords plugin, time, period, stroke, line, setting, blocking, interval
 * @plugin timeperiod-marker
 */
export class GanttTimePeriodStrokeHandlerDataItem {
  constructor(public color: string, public width = 3) {}
}
