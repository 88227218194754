import { EntryElement } from '@app-modeleditor/components/entry-collection/entry-element';

export class DateInput extends EntryElement {
  private showTime: boolean;

  public getShowTime(): boolean {
    return this.showTime;
  }

  public setShowTime(showTime: boolean): this {
    this.showTime = showTime;
    return this;
  }
}
