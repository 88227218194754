import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ITemplate } from './template';

export interface TemplateResizeEvent {
  template: ITemplate;
  distance: number;
  wrapperHeight: number;
}

@Injectable({
  providedIn: 'root',
})
export class TemplateResizeService {
  public _resize: Subject<TemplateResizeEvent> = new Subject<TemplateResizeEvent>();

  constructor() {}

  public onResize(): Observable<TemplateResizeEvent> {
    return this._resize.asObservable();
  }

  public resize(action: any, distance: number, wrapperHeight: number): void {
    this._resize.next({ template: action, distance, wrapperHeight });
  }
}
