import { Component, OnInit, Input } from '@angular/core';
import { Widget } from 'frontend/src/dashboard/data/grid/data/widget';
import { AbstractGridService } from 'frontend/src/dashboard/moving-grid/abstract-grid.service';
import { GridGloablService } from 'frontend/src/dashboard/moving-grid/grid-global.service';
import { UiService } from '@app-modeleditor/ui.service';

@Component({
  selector: 'app-template-widget',
  templateUrl: './template-widget.component.html',
  styleUrls: ['./template-widget.component.scss'],
})
export class TemplateWidgetComponent implements OnInit {
  @Input() widget: Widget;
  public template;
  public gridService: AbstractGridService;

  constructor(public globalGridService: GridGloablService, private uiService: UiService) {
    this.gridService = globalGridService.getGridService();
  }

  ngOnInit() {
    if (this.widget) {
      this.sendData();
    }
  }

  sendData() {
    this.gridService.getWidgetData(this.widget.experimentId, this.widget.widget.id).subscribe((data) => {
      this.uiService.getData(data.resturl).subscribe((template) => {
        this.template = template;
      });
    });
  }
}
