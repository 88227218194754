import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import { EntryElement } from '@app-modeleditor/components/entry-collection/entry-element';
import { Registered, TemplateService } from '@app-modeleditor/utils/template.service';
import { EResizeMode } from 'frontend/src/dashboard/model/resource/template-resize-mode.enum';
import { of, Subject } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';
import { TemplateUiService } from '../template-ui/template.service';
import { EntryCollection } from './entry-collection';
import { EntryElementValue } from './entry-element-value';
import { EEntryCollectionEvents } from './entry-element/entry-collection-events.enum';
import { EntryElementFactory } from './entry-factory.service';
@Component({
  selector: 'template-entry-collection',
  templateUrl: './entry-collection.component.html',
  styleUrls: ['./entry-collection.component.scss'],
})
export class EntryCollectionComponent implements OnInit, OnDestroy, OnChanges {
  @Input('template') nt: EntryCollection;
  @Input() r: any;
  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() highlightedKey: string;
  specialTemplates = {};
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  editmode = true;
  @Input() resourceId: string;

  @Input() templateElement: EntryCollection;
  @Input() disabled: boolean;
  hasPositionMarker = false;
  private registered: Record<string, Registered>;

  @ViewChildren('entryElement') set div(divs: QueryList<ElementRef>) {
    const idx: number = divs
      .toArray()
      .findIndex((el: ElementRef) => el.nativeElement.classList.contains('is-on-the-right'));
    if (this.hasPositionMarker !== (idx - 1 === -1 ? true : false)) {
      of(null)
        .pipe(delay(0))
        .subscribe(() => {
          this.hasPositionMarker = idx - 1 === -1 ? true : false;
        });
    }

    if (idx !== 0) {
      const matchingEl: ElementRef = divs.toArray()[idx - 1];
      if (matchingEl) {
        matchingEl.nativeElement.classList.remove('is-last');
        matchingEl.nativeElement.classList.add('is-last');
      }
    }
  }

  constructor(
    private templateApi: TemplateService,
    public elementRef: ElementRef,
    private _ngZone: NgZone,
    private _cd: ChangeDetectorRef,
    private http: HttpClient,
    private entryElementAdapter: EntryElementFactory,
    private templateUiService: TemplateUiService
  ) {
    this.templateApi
      .onRefreshElementData()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((ids: string[]) => this.refreshElements(ids));

    this.templateApi
      .onUpdateElements()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((ids: string[]) => this.refreshElements(ids));
  }

  /**
   * refresh elements by their ids
   * @param {string[]} ids list of entry-element ids
   * @returns void
   */
  private refreshElements(ids: string[]): void {
    const ee: EntryElement[] = this.nt?.getEntryElements().filter((e: EntryElement) => ids.includes(e.getId()));
    if (ee && ee.length > 0 && this.nt.getRestUrl()) {
      this.http.get(`rest/${this.nt.getRestUrl()}`).subscribe((d: Record<string, any>) => {
        ee.forEach((e: EntryElement) => {
          e.setValue(this.entryElementAdapter.parseEntryValue(EntryElementValue, d[e.getId()]));
          this.templateUiService.clearChangesOfTemplate(this.nt, [e.getId()], true);
        });
      });
    }
  }

  lastSelectedIdx = null;

  private getIndexOfEntryElement(el: EntryElement): number {
    return this.nt.getEntryElements().findIndex((e: EntryElement) => e.getId() === el.getId());
  }

  onClick(event: MouseEvent, el: EntryElement, preventDefault = false): void {
    if (!el.isSelectable()) {
      return;
    }
    if (preventDefault) {
      event.preventDefault();
    }
    event.stopPropagation();

    if (event.shiftKey && this.lastSelectedIdx !== null) {
      const curIdx: number = this.getIndexOfEntryElement(el);
      const start: number = Math.min(curIdx, this.lastSelectedIdx);
      const end: number = Math.max(curIdx, this.lastSelectedIdx);
      this.nt.getEntryElements().forEach((e: EntryElement) => e.setSelected(false));
      for (let i = start; i <= end; i++) {
        this.nt.getEntryElements()[i].setSelected(true);
      }
    } else if (event.ctrlKey === true) {
      el.setSelected(!el.isSelected());
    } else {
      this.nt
        .getEntryElements()
        .forEach((e: EntryElement) => e.setSelected(el.getId() === e.getId() ? !el.isSelected() : false));
      this.lastSelectedIdx = this.getIndexOfEntryElement(el);
    }

    if (this.nt.selectedEntries.length === 0) {
      this.lastSelectedIdx = null;
    }
  }

  getElementHeight(elements: number): string {
    if (this.elementRef && this.nt.getResizeMode() === EResizeMode.FIT_PARENT) {
      const v: number = Math.floor(this.elementRef.nativeElement.clientHeight / elements);
      return `${v}px`;
    }

    return 'auto';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.nt) {
      this.initElement();
    }
  }

  ngOnDestroy(): void {
    this.nt.removeEventListener('eeee');
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private initElement() {
    if (!this.registered || !this.nt) {
      return;
    }
    const resourceId: string = this.templateApi.findResourceByTemplate(this.nt.getId());
    if (resourceId !== this.resourceId) {
      this._ngZone.run(() => {
        this.resourceId = resourceId;
        this._cd.markForCheck();
      });
    }

    const editmode: boolean =
      typeof this.registered[resourceId]?.editable === 'boolean' ? this.registered[resourceId].editable : true;
    if (this.editmode !== editmode) {
      this._ngZone.run(() => {
        this.editmode = editmode;
        this._cd.markForCheck();
      });
    }
  }

  isDisabled(el): boolean {
    if (el instanceof EntryElement) {
      return this.disabled || !this.editmode || el.isDisabled();
    }

    return !el.alwaysEnabled && (this.disabled || !this.editmode || el.editable === false);
  }

  ngOnInit(): void {
    this.initElement();
    this.nt.addEventListener('eeee', EEntryCollectionEvents.VALUE_CHANGED, (event: CustomEvent) => {
      this.onChanges(event.detail);
    });

    this._ngZone.runOutsideAngular(() => {
      this.templateApi
        .afterRegisteredChanges()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((r: Record<string, Registered>) => {
          this.registered = r;
          this.initElement();
        });
    });
  }

  /**
   * decides if entryElement is visible or not
   * @param el entryElement
   * @returns boolean
   */
  getVisibility(el): boolean {
    if (!el) {
      return false;
    }

    if (el instanceof EntryElement) {
      return el.isShow();
    }

    return typeof el.show !== 'boolean' || el.show === true;
  }

  onChanges(event): void {
    if (this.nt.getEntryElementTemplates().length > 0) {
      const match: EntryElement = this.nt.getEntryElementTemplates().find((entryElement: EntryElement) => {
        if (
          !(entryElement.getValue() instanceof EntryElementValue) ||
          !Array.isArray(entryElement.getValue<EntryElementValue>().getValue())
        ) {
          return false;
        }
        return entryElement
          .getValue<EntryElementValue>()
          .getValue<EntryElementValue[]>()
          .find((val: EntryElementValue) => {
            if (val.getUuid() !== event.event.getValue().getUuid()) {
              return false;
            }
            val.setValue(event.event.getValue().getValue());

            return true;
          })
          ? true
          : false;
      });
      // only if element was found, set event
      if (match) {
        event.event = match;
      }
    }

    this.valueChange.emit(event);
  }

  getTimeUnit(timeUnits: Array<string>, type: 'MIN' | 'MAX'): string {
    const possibleUnits: Array<string> =
      type === 'MIN'
        ? ['millis', 'seconds', 'minutes', 'hours', 'days', 'weeks']
        : ['weeks', 'days', 'hours', 'minutes', 'seconds', 'millis'];
    const returnUnit: Array<string> =
      type === 'MIN'
        ? ['millisecond', 'second', 'minute', 'hour', 'day', 'week']
        : ['week', 'day', 'hour', 'minute', 'second', 'millisecond'];

    if (!timeUnits) {
      return returnUnit[0];
    }

    for (const index in possibleUnits) {
      if (timeUnits.includes(possibleUnits[index])) {
        return returnUnit[index];
      }
    }

    return returnUnit[0];
  }
}
