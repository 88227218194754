import { EGanttInstance } from '@gantt/public-api';
import { GanttLibService } from 'frontend/src/dashboard/gantt/gantt/gantt-lib.service';
import { GanttPlugInAction } from 'frontend/src/dashboard/gantt/gantt/plugin/i-saxms-best-gantt.plugin';
import { SaxMsBestGanttActiveSubmenuEntryElementSetting } from 'frontend/src/dashboard/gantt/gantt/saxms-best-gantt.settings';
import { GeneralGanttActionHandler } from 'frontend/src/dashboard/gantt/general/action-handling/action-handler';
import { GanttResponseHandler } from 'frontend/src/dashboard/gantt/general/response/response-handler';
import { GanttTemplateData } from 'frontend/src/dashboard/gantt/helper/gantt';
import { Observable, of } from 'rxjs';
import { GanttSettingsService } from '../../../gantt-settings/service/gantt-settings.service';
import { IGanttBlock } from '../../../generator/gantt-input.data';
import { GanttEssentialPlugIns } from '../../e-gantt-essential-plugins';
import { ExternalGanttPlugin } from '../../external-plugin';
import { GanttPluginHandlerService } from '../../gantt-plugin-handler.service';
import { GanttShiftColorByAttributeExecuter } from '../block-colorizer/by-attribute/colorizer-by-attribute';
import { GanttBlockFilterPlugIn } from '../block-filter/block-filter';
import { GanttIndexCardsPlugIn } from '../index-cards/index-cards.plugin';
import { GanttOverlappingShiftsPlugIn } from '../overlapping-shifts/overlapping-shifts';
import { GanttAreaOverlayPlugIn } from './../area-overlay/area-overlay.plugin';
import { GanttPlugInSuperBlockHandlerResponse } from './response/superblock.response';
import { ESuperBlockVisualization, GanttSuperBlocksMapper, SuperBlockData } from './superblocks.mapper';

export const GanttPlugInSuperBlockHandler = 'gantt-plugin-superblock-handler';

export const GanttSuperblockSubmenuOriginal: { name: string; value: number } = {
  name: '@original@',
  value: 1,
};

/**
 * PlugIn-Wrapper for GanttShiftComponentGroup.
 * Provides superblock concept to give ability to split
 * shift blocks into components by selecting a view mode.
 */
export class GanttSuperBlocksPlugIn extends ExternalGanttPlugin {
  public superBlocksHandler: any;
  private typeTranslationMap: Map<string | number, string | number> = new Map<string | number, string | number>();
  public mapper: GanttSuperBlocksMapper = new GanttSuperBlocksMapper();
  public templateData: GanttTemplateData;

  public superBlockVisualization: Map<string, ESuperBlockVisualization> = new Map();

  constructor(
    protected _ganttPluginHandlerService: GanttPluginHandlerService,
    protected _ganttLibService: GanttLibService,
    protected _actionHandler: GeneralGanttActionHandler,
    protected _responseHandler: GanttResponseHandler,
    protected _ganttSettingsService: GanttSettingsService
  ) {
    super(_ganttPluginHandlerService, _ganttLibService, _actionHandler);
  }

  public onInit(templateData: GanttTemplateData, responseData: any) {
    this.templateData = templateData;
    this.addPlugIn(
      GanttPlugInSuperBlockHandler,
      this._ganttLibService.ganttInstanceService.getInstance(EGanttInstance.SHIFT_COMPONENTS_GROUP)
    );
    let superBlockBackendData = responseData.hierarchicalPlan.superBlockDataViews;

    if (!superBlockBackendData) {
      superBlockBackendData = {};
    }
    const newSuperBlockBackendData = superBlockBackendData;
    const oldSuperBlockBackendData = this.getPlugInById(GanttPlugInSuperBlockHandler).getSuperBlockBackendData();
    const mergedData = {
      ...oldSuperBlockBackendData,
      ...newSuperBlockBackendData,
    };
    const plugin = this.getPlugInById(GanttPlugInSuperBlockHandler);

    this.registerSuperBlockExecuter(templateData, responseData);
    plugin.setSuperBlockBackendData(mergedData);
    this.generateTranslationMap(responseData);

    this._responseHandler.addResponse(GanttPlugInSuperBlockHandlerResponse, {
      plugin: this.getPlugInById(GanttPlugInSuperBlockHandler),
      templateData: templateData,
      mapper: this.mapper,
    });
  }

  public onDestroy(): void {}

  public onAction(action: any): void {}

  /**
   * Creates and registers GanttShiftComponents instances by backend data.
   * @param responseData Backend data.
   */
  private registerSuperBlockExecuter(templateData: GanttTemplateData, responseData: any): void {
    if (!responseData.hierarchicalPlan) return;
    for (const view of responseData.hierarchicalPlan.superBlockViews || []) {
      const newSuperBlockExecuter = this._ganttLibService.ganttInstanceService.getInstance(
        EGanttInstance.SHIFT_COMPONENTS,
        false
      );
      this.getPlugInById(GanttPlugInSuperBlockHandler).addPlugShiftComponentExecuter(
        view.type + '',
        newSuperBlockExecuter
      );
      this.superBlockVisualization.set(view.type + '', this.mapper.getDisplayTypeByBackEndData(view));
      // TODO: set initial by default as boolean property
    }
    this.addSuperBlocks(
      this.mapper.getSuperBlocksByBackendData(templateData, responseData, this._ganttLibService.ganttInstanceService)
    );
  }

  /**
   * Creates data structure to initialy store all block types to provide
   * them even gantt data has been mapped (and no block types anymore).
   * @param responseData Backend data.
   */
  private generateTranslationMap(responseData: any): void {
    if (!responseData.hierarchicalPlan) return;
    for (const key in responseData.hierarchicalPlan.blockTypes || []) {
      this.typeTranslationMap.set(key, responseData.hierarchicalPlan.blockTypes[key]);
      this.typeTranslationMap.set(responseData.hierarchicalPlan.blockTypes[key], key);
    }
  }

  /**
   * Triggers recombining of active combine plugin.
   * Does nothing if there is no active combine plugin.
   */
  public reCombine(): void {
    this.getPlugInById(GanttPlugInSuperBlockHandler).reCombine();
  }

  /**
   * Adds an array of super blocks to the Gantt chart.
   * @param superBlocks An array of `SuperBlockData` objects to add to the chart.
   * @param rerenderEdges Whether or not to re-render the edges after adding the super blocks. Defaults to `true`.
   * @returns void
   */
  public addSuperBlocks(superBlocks: SuperBlockData[], rerenderEdges = true): void {
    for (const superBlock of superBlocks || []) {
      const matchingExecuter = this.getPlugInById(GanttPlugInSuperBlockHandler).getExecuterById(superBlock.type);
      if (!matchingExecuter) continue;

      matchingExecuter.plugin.getShiftComponentDataHandler().addToShiftComponent([
        {
          id: superBlock.id,
          tooltip: superBlock.tooltip,
          group: superBlock.group,
          details: superBlock.details,
        },
      ]);
      // add superblocks with attribute color to pr
    }
    this._addSuperblocksToColorizerPlugin(superBlocks);
    if (rerenderEdges) {
      for (const executer of this.getPlugInById(GanttPlugInSuperBlockHandler).getAllExecuters()) {
        executer.plugin.reRenderEdges();
      }
    }
  }

  /**
   * Adds superblocks to the colorizer plugin.
   * @param superBlocks An array of superblock data.
   */
  private _addSuperblocksToColorizerPlugin(superBlocks: SuperBlockData[]) {
    const firstBlockIds: Map<string, string> = new Map<string, string>();
    const colorizePlugin = this.getPlugInById(GanttShiftColorByAttributeExecuter);
    superBlocks.forEach((superBlock) => {
      if (superBlock.group.length) {
        if (colorizePlugin) {
          const firstBlockId = superBlock.group[0];
          firstBlockIds.set(firstBlockId, superBlock.id);
        }
      }
    });
    const firstBlockIdsArray = Array.from(firstBlockIds.keys());
    const shifts = this._ganttLibService.bestGantt.getShiftsByIds(firstBlockIdsArray);

    shifts.forEach((shift) => {
      const originColor = shift?.color || '#000000';
      colorizePlugin.addOriginShiftColor(firstBlockIds.get(shift.id), originColor);
    });
  }

  /**
   * Updates the superblock data based on the provided set of gantt blocks.
   * Removes the gantt blocks from their previous superblocks and adds them to their new superblocks.
   * Finally, re-renders the edges of all superblocks.
   * @param ganttBlocks The set of gantt blocks to update the superblock data for.
   */
  public updateSuperBlockByGanttBlock(ganttBlocks: Set<IGanttBlock>): void {
    ganttBlocks.forEach((ganttBlock) => {
      this.removeFromSuperBlockByGanttBlock(ganttBlock, false);
      const extractedSuperBlocks = this.mapper.getSuperBlockDataByBlock(ganttBlock);
      if (extractedSuperBlocks) this.addSuperBlocks(extractedSuperBlocks, false);
    });

    for (const executer of this.getPlugInById(GanttPlugInSuperBlockHandler).getAllExecuters()) {
      executer.plugin.reRenderEdges();
    }
  }

  /**
   * Removes a gantt block from all super blocks.
   * @param ganttBlock The gantt block to remove.
   * @param rerenderEdges Whether to rerender edges after removing the block. Defaults to true.
   */
  public removeFromSuperBlockByGanttBlock(ganttBlock: IGanttBlock, rerenderEdges = true): void {
    const allSuperBlockHandlers: any[] = this.getPlugInById(GanttPlugInSuperBlockHandler).getAllExecuters();
    for (const superBlockHandlerWrapper of allSuperBlockHandlers) {
      superBlockHandlerWrapper.plugin.removeComponentByComponentID(ganttBlock.id, rerenderEdges);
    }
  }

  /**
   * Checks if given block belongs to given superblocktype.
   * @param blockId Id of block to check.
   * @param superblockType Superblock type to check.
   */
  public blockBelongsToType(blockId: string, superblockType: string): boolean {
    const superBlockTypeId: string = this.typeTranslationMap.get(superblockType) + '';
    if (!superBlockTypeId) return false;
    const superBlockExecuterWrapper =
      this.getPlugInById(GanttPlugInSuperBlockHandler).getExecuterById(superBlockTypeId);
    if (!superBlockExecuterWrapper) return false;
    return !!superBlockExecuterWrapper.plugin.getShiftComponentDataHandler().getComponentGroupIDByComponentID(blockId);
  }

  /**
   * Triggers superblock view by given super block id.
   * @param superBlockGroupId Id of super block type.
   */
  public activateSuperBlockViewById(superBlockGroupId: number): void {
    const plugIn: any = this.getPlugInById(GanttPlugInSuperBlockHandler);

    const overlappingShiftsPlugIn: GanttOverlappingShiftsPlugIn = this._ganttPluginHandlerService.getEssentialPlugIn(
      GanttEssentialPlugIns.OverlappingShiftsPlugIn
    );
    const blockFilterPlugIn: GanttBlockFilterPlugIn = this._ganttPluginHandlerService.getEssentialPlugIn(
      GanttEssentialPlugIns.BlockFilterPlugIn
    );
    const areaOverlayPlugin: GanttAreaOverlayPlugIn = this._ganttPluginHandlerService.getEssentialPlugIn(
      GanttEssentialPlugIns.AreaOverlayPlugIn
    );
    const indexCardsPlugin: GanttIndexCardsPlugIn = this._ganttPluginHandlerService.getEssentialPlugIn(
      GanttEssentialPlugIns.IndexCardBuilderPlugIn
    );
    const colorizeJSPlugin = this.getPlugInById(GanttShiftColorByAttributeExecuter);
    overlappingShiftsPlugIn.resetSplitOverlappingShifts(false);
    if (superBlockGroupId === GanttSuperblockSubmenuOriginal.value) {
      plugIn.deactivateAllPlugIns();
    } else {
      plugIn.setActivePlugInById(superBlockGroupId, this.superBlockVisualization.get(superBlockGroupId + ''));
    }
    blockFilterPlugIn.filterAllBlocks(false);
    areaOverlayPlugin.updateIntervals();
    indexCardsPlugin.updatePlugin(false);
    if (!colorizeJSPlugin.isActive) {
      colorizeJSPlugin.resetColor();
    } // update default shift colors on view change
    overlappingShiftsPlugIn.splitOverlappingShifts(true);
  }

  /**
   * Executes local actions.
   * The superblockgroups will be activated by a localaction
   * @param localAction Local action.
   */
  public executeAction(localAction: GanttSuperBlockActivationAction | any): Observable<any> {
    // execute if GanttSuperBlockActivationAction
    if (localAction.activateType) this.activateSuperBlockViewById(localAction.activateType);
    return of(true);
  }

  /**
   * Translates given typenumber to type.
   * @param typeNumber (Super) block type.
   */
  public getTypeNumberByType(typeNumber: string): number {
    return this.typeTranslationMap.get(typeNumber) as number;
  }

  /**
   * Provides active JS superblock plugin.
   */
  public getActivePlugInExecuterData(): any {
    return this.getPlugInById(GanttPlugInSuperBlockHandler).getActivePlugIn();
  }

  public getSuperblockBackendData(): { [id: string]: IGanttBlock } {
    return this.getPlugInById(GanttPlugInSuperBlockHandler).getSuperBlockBackendData();
  }

  public injectSettings(submenuElements: SaxMsBestGanttActiveSubmenuEntryElementSetting[]): void {}
}

export abstract class GanttSuperBlockActivationAction implements GanttPlugInAction {
  pluginId: string;
  activateType: number;
}

export interface IGanttSuperBlocksResponseExecuter {
  plugin: any;
  templateData: GanttTemplateData;
  mapper: GanttSuperBlocksMapper;
}
