import * as d3 from 'd3';
import { Observable, Subject } from 'rxjs';
import { debounceTime, filter, takeUntil, tap } from 'rxjs/operators';

/**
 * Handles zoom events for the x-axis of a Gantt chart.
 */
export class ZoomHandler {
  private zoomStartSubject = new Subject<d3.D3ZoomEvent<Element, undefined>>();
  private zoomSubject = new Subject<d3.D3ZoomEvent<Element, undefined>>();
  private zoomEndSubject = new Subject<d3.D3ZoomEvent<Element, undefined>>();
  private lastZoomTransform: d3.ZoomTransform;
  private _isZooming = false;

  constructor(private xAxisNode: SVGGElement) {}

  /**
   * Triggers the zoom event and emits the corresponding start, zoom, and end events.
   * @param zoomEvent The D3 zoom event.
   */
  triggerZoom(zoomEvent: d3.D3ZoomEvent<Element, undefined>) {
    if (!this._isZooming) {
      // zoom start handling
      this.zoomStartSubject.next(this.getZoomEvent('start', zoomEvent));
      this._isZooming = true;

      // zoom end handling
      this.zoomSubject.pipe(takeUntil(this.zoomEndSubject), debounceTime(100)).subscribe(() => {
        this.zoomEndSubject.next(this.getZoomEvent('end', zoomEvent));
        this._isZooming = false;
      });
      //   return;
    }

    // during zoom handling
    this.zoomSubject.next(this.getZoomEvent('zoom', zoomEvent));
  }

  /**
   * Returns a new D3 zoom event with the given type and updated transform.
   * @param type - The type of the zoom event ('start', 'zoom', or 'end').
   * @param event - The original D3 zoom event.
   * @returns A new D3 zoom event with the updated transform and type.
   */
  private getZoomEvent(
    type: 'start' | 'zoom' | 'end',
    event: d3.D3ZoomEvent<Element, undefined>
  ): d3.D3ZoomEvent<Element, undefined> {
    const zoomTransform = d3.zoomTransform(this.xAxisNode);
    const newEvent: d3.D3ZoomEvent<Element, undefined> = {
      ...event,
      transform: zoomTransform,
      type: type,
    };
    return newEvent;
  }

  /**
   * Returns an observable that emits a `d3.D3ZoomEvent` when a zoom event starts.
   * @returns An observable that emits a `d3.D3ZoomEvent` when a zoom event starts.
   */
  onZoomStart(): Observable<d3.D3ZoomEvent<Element, undefined>> {
    return this.zoomStartSubject.asObservable();
  }

  /**
   * Returns an observable that emits a `d3.D3ZoomEvent` whenever the zoom level changes.
   * The observable is filtered to only emit events where the zoom transform has changed.
   * The `lastZoomTransform` property is updated with the new transform on each emitted event.
   * @returns An observable that emits a `d3.D3ZoomEvent` whenever the zoom level changes.
   */
  onZoom(): Observable<d3.D3ZoomEvent<Element, undefined>> {
    return this.zoomSubject.asObservable().pipe(
      // filter out events where the zoom transform has not changed
      filter((e) => this.lastZoomTransform?.x !== e.transform.x || this.lastZoomTransform?.k !== e.transform.k),
      tap((e) => (this.lastZoomTransform = e.transform))
    );
  }

  /**
   * Returns an observable that emits a `d3.D3ZoomEvent` when the zoom operation ends.
   * @returns An observable that emits a `d3.D3ZoomEvent` when the zoom operation ends.
   */
  onZoomEnd(): Observable<d3.D3ZoomEvent<Element, undefined>> {
    return this.zoomEndSubject.asObservable();
  }
}
