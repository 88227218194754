export enum EGanttInstance {
  ROW_COLORIZER = 'GanttRowColorizer',
  DATA_MANIPULATOR = 'DataManipulator',
  SHIFT_DRAG_LIMITER = 'GanttShiftDragLimiter',
  GANTT_UTILITIES = 'GanttUtilities',
  SHIFT_DATA_FINDER = 'ShiftDataFinder',
  SHIFT_DATA_SORTING = 'ShiftDataSorting',
  Y_AXIS_DATA_FINDER = 'YAxisDataFinder',
  COMPARE_GANTTS = 'CompareGantts',
  FILTER_SHIFTS_BY_ATTRIBUTE = 'FilterShiftsByAttribute',
  CURRENT_TIME_POINT_MARKER = 'GanttCurrentTimePointMarker',
  GANTT_OVERLAY = 'GanttOverlay',
  PRINTER = 'GanttPrinterExecuter',
  PRINT_VIEW_STRATEGY_EFW = 'GanttPrintViewStrategieEFW',
  PRINT_VIEW_STRATEGY_LW = 'GanttPrintViewStrategyLW',
  ROW_SEARCH = 'GanttRowSearchExecuter',
  SHIFT_COMPONENTS_GROUP = 'GanttShiftComponentGroup',
  SHIFT_COMPONENTS = 'GanttShiftComponents',
  SHIFT_MOVER = 'GanttShiftMover',
  SHOW_SHIFT_TIME_STAMP = 'GanttShowShiftTimeStamps',
  SHIFT_COLOR_BY_ATTRIBUTE = 'GanttShiftColorByAttribute',
  SHIFT_COLOR_BY_ATTRIBUTE_PARAMS = 'GanttShiftColorByAttributeParams',
  SPLIT_OVERLAPPING_SHIFTS = 'GanttSplitOverlappingShifts',
  SPLIT_OVERLAPPING_SHIFTS_PRIORITIZED_STRATEGY = 'GanttSplitOverlappingShiftsPrioritizedStrategy',
  SPLIT_OVERLAPPING_SHIFTS_DEFAULT_STRATEGY = 'GanttSplitOverlappingShiftsDefaultStrategy',
  SPLIT_OVERLAPPING_SHIFTS_BY_ATTRIBUTE_STRATEGY = 'GanttSplitOverlappingShiftsByAttributeStrategy',
  TEXTURIZER = 'GanttTexturizer',
  TIME_PERIOD_GROUP = 'GanttTimePeriodGroupExecuter',
  TIME_POINT = 'GanttTimePointExecuter',
  EDGE_HANDLER = 'GanttEdgeHandler',
  X_AXIS_ZOOM_TRANSFORMATOR = 'GanttXAxisZoomTransformator',
  Y_AXIS_TABLE_BUILDER = 'GanttYAxisTableBuilder',
  INDEX_CARD = 'IndexCardExecuter',
  SETTING_BUBBLES = 'SettingBubblesExecuter',
  SHIFT_SPLIT_BY_CLICK = 'ShiftSplitByClick',
  X_AXIS_FORMAT_GENERAL = 'GanttXAxisFormatGeneral',
  SHIFT_CREATOR = 'GanttShiftCreator',
  TEXT_OVERLAY_DEFAULT = 'TextOverlayDefault',
  TEXT_OVERLAY_INSIDE = 'TextOverlayInside',
  TEXT_OVERLAY_OUTSIDE = 'TextOverlayOutside',
  TEXT_OVERLAY_NO_TEXT = 'TextOverlayNoText',
  TEXT_OVERLAY_OUTSIDE_SPLIT = 'TextOverlayOutsideSplit',
  SHIFT_DRAG_VISUALIZER = 'GanttShiftDragVisualizer',
  FILTER_SHIFTS_BY_CONDITION = 'FilterShiftsByCondition',
  STICKY_BLOCKS = 'GanttStickyBlocks',
  GANTT_BLOCK_HIGHLIGHTER = 'GanttBlockHighlighter',
  GANTT_EARLIEST_START_LATEST_END_VISUALIZER = 'GanttEarliestStartLatestEndVisualizer',
  GANTT_BLOCK_LOADING = 'GanttBlockLoading',
  GANTT_GRID_LABELS = 'GanttGridLabels',
  GANTT_ROW_LIMITER_MARKER = 'GanttRowLimiterMarker',
  ROW_RASTER_COLOR_EXECUTER = 'RowRasterColorExecuter',
  ROW_COLOR_HIERARCHIE = 'RowColorHierarchieExecuter',
  BROKEN_CONSTRAINTS_NAVIGATOR = 'GanttBrokenConstraintsNavigator',
}
