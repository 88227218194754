import { GanttLibService } from 'frontend/src/dashboard/gantt/gantt/gantt-lib.service';
import { GanttPluginHandlerService } from 'frontend/src/dashboard/gantt/general/plugin/gantt-plugin-handler.service';
import { GanttTemplateData } from 'frontend/src/dashboard/gantt/helper/gantt';
import { GeneralGanttActionHandler } from '../action-handling/action-handler';
import { GanttResponseHandler } from '../response/response-handler';
import { EGanttActionEvent } from './gantt-action-handling/gantt-action-event.enum';
import { GanttAction } from './gantt-action-handling/gantt-action.interface';
import { Observable, Subject } from 'rxjs';

/**
 * Structure of event-callback registration inside gantt and plugins.
 */
export class GanttEventTrigger {
  public readonly eventName: EGanttActionEvent;

  private _onRemoveFromGanttSubject = new Subject<void>();

  constructor(
    protected executer: any,
    protected actionHandler: GeneralGanttActionHandler,
    protected templateData: GanttTemplateData,
    protected responseHandler: GanttResponseHandler,
    protected _ganttLibService: GanttLibService,
    protected _ganttPluginHandlerService: GanttPluginHandlerService,
    public action?: GanttAction
  ) {}

  /**
   * Init function, usefull to register this trigger inside gantt or plugin executer via callback.
   */
  public registerInGantt(): void {};

  /**
   * Removes Event trigger.
   * Cuts all dependencies (callback and services).
   */
  public removeFromGantt(): void {
    this._onRemoveFromGanttSubject.next();
    this._onRemoveFromGanttSubject.complete();
  }

  /**
   * Observable which gets triggered when the event-callback registration gets removed from the gantt.
   */
  protected get onRemoveFromGantt(): Observable<void> {
    return this._onRemoveFromGanttSubject.asObservable();
  }
}
