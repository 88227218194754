import { Injectable, Type } from '@angular/core';
import { ERequestMethod, RequestOptions, RequestService } from '@app-modeleditor/request.service';
import { TemplateAdapter } from '@app-modeleditor/utils/template-factory.service';
import { Adapter } from '@core/adapter';
import { ConfigService } from '@core/config/config.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { EntryElementFactory } from './../../../../../modeleditor/components/entry-collection/entry-factory.service';
import { FrontendFilter } from './frontend-filter';

const STORAGE_NAME = 'IFilterNode';

@Injectable()
export class GanttFrontendFilterAdapter implements Adapter<FrontendFilter> {
  private readonly restURL: string = 'rest/template/ganttfrontendfiltersettings';

  private _ganttTemplateId = '';
  private _currentActiveFilter: FrontendFilter = null;
  private _filterList: FrontendFilter[] = [];
  private _isChangeFixedFiltersAllowed = true;

  private _currentActiveFilterSubject: BehaviorSubject<FrontendFilter> = new BehaviorSubject<FrontendFilter>(null);
  private _filterListSubject: BehaviorSubject<FrontendFilter[]> = new BehaviorSubject<FrontendFilter[]>([]);

  constructor(
    private entryElementFactory: EntryElementFactory,
    private requestService: RequestService,
    protected configService: ConfigService
  ) {}

  /**
   * Initializes the frontend filter service with the given gantt template id.
   */
  public init(ganttTemplateId: string) {
    this._ganttTemplateId = ganttTemplateId;
    this._loadFilter(this._ganttTemplateId);
    this.checkIfUserCanChangeFixedFilters(this._ganttTemplateId);
  }

  /**
   * Returns the current active filter.
   */
  public getCurrentActiveFilter(): Observable<FrontendFilter> {
    return this._currentActiveFilterSubject.asObservable();
  }

  /**
   * Emits current active filter again.
   */
  public refreshCurrentActiveFilter(): void {
    this._currentActiveFilter = this._filterList.find(
      (filter) => filter.getId() === this._currentActiveFilter?.getId()
    ); // update current filter because sometimes the reference of the current filter list is lost.
    this._currentActiveFilterSubject.next(this._currentActiveFilter);
  }

  /**
   * Updates the current active filter and emits the new value to the subscribers.
   */
  public setCurrentActiveFilter(f: FrontendFilter): void {
    this._currentActiveFilter = f;
    this._currentActiveFilterSubject.next(f);
  }

  public adapt(item: any): FrontendFilter {
    throw new Error('Method not implemented.');
  }
  public inherit<T extends FrontendFilter>(type: Type<T>, item: any): T {
    return this._parseData(item, type);
  }
  public inheritFrom?<T extends FrontendFilter>(scope: TemplateAdapter, type: Type<T>, item: any): T {
    return this._parseData(item, type);
  }
  public applyValues<T>(scope: TemplateAdapter, item: any, values: any): T {
    throw new Error('Method not implemented.');
  }

  /**
   * For subscription to filter list change event.
   */
  public onFilterChanged(): Observable<FrontendFilter[]> {
    return this._filterListSubject.asObservable();
  }

  /**
   * Saves the given filter list to the backend.
   */
  public saveFilterListOnBackend(filter: FrontendFilter[]): void {
    if (!this.configService.access().templates.Gantt.requests.frontendFilterSettings) {
      // return if deactivated in config
      return;
    }
    // filter.forEach(f => this._saveFilter(this._ganttTemplateId, f));
    this.requestService
      .call(
        ERequestMethod.POST,
        `${this.restURL}/${this._ganttTemplateId}`,
        new RequestOptions().setHttpOptions({ body: filter })
      )
      .subscribe();
  }

  /**
   * Adds a new filter to the filter list and emits a change event.
   * Also saves the list on the backend.
   */
  public addNewFilterToFilterList(filter: FrontendFilter): void {
    const list = this.filterList.filter((filterItem) => filterItem.getId() !== filter.getId()); // filter out existing filter
    list.push(filter);
    this.saveAndSetNewFilterList(list);
  }

  /**
   * Saves the given list on backend and updates the new filter list.
   */
  public saveAndSetNewFilterList(filterList: FrontendFilter[]): void {
    this.saveFilterListOnBackend(filterList);
    this.filterList = filterList;
  }

  /**
   * Removes a given filter from filter list.
   * Also saves the updated list to the backend.
   */
  public removeFilter(filter: FrontendFilter): void {
    this.requestService.call(ERequestMethod.GET, `${this.restURL}/delete/${filter.getId()}`).subscribe(() => {
      const list = this.filterList.filter((filterItem) => filterItem.getId() !== filter.getId()); // filter out existing filter
      this.filterList = list;
    });
  }

  /**
   * Parses data for new class creation.
   */
  private _parseData<T extends FrontendFilter>(item, type): T {
    const t: T = this.entryElementFactory
      .parseEntryValue<T>(type, item)
      .setName(item.name)
      .setQuickfilter(item.quickfilter)
      .setNode(item.node || {})
      .setFilteredOutElementDisplayOption(item.filteredOutElementDisplayOption)
      .setCaseSensitive(item.caseSensitive)
      .setHideEmptyRows(item.hideEmptyRows)
      .setPublicFilter(item.publicFilter || !!item.node)
      .setFixed(item.fixed ?? false);

    if (item.filterString) {
      t.setNode(JSON.parse(item.filterString));
      t.setFilterString(JSON.stringify(item.node));
    } else {
      t.setFilterString(JSON.stringify(item.node));
    }

    if (t.getCreated()) {
      t.setCreated(null); // set created to null because backend cant read created property as string value
    }

    return t;
  }

  /**
   * Saves filters to backend by given gantt template ID and filter item;
   * @param body Settings object.
   */
  private _saveFilter(ganttID: string, filter: FrontendFilter): void {
    if (!this.configService.access().templates.Gantt.requests.frontendFilterSettings) {
      // return if deactivated in config
      return;
    }
    this.requestService
      .call(ERequestMethod.POST, `${this.restURL}/${ganttID}`, new RequestOptions().setHttpOptions({ body: filter }))
      .subscribe();
  }

  /**
   * Read filters from backend by given gantt template ID.
   * @param templateId Id of gantt template.
   */
  private _loadFilter(templateId: string): void {
    if (!this.configService.access().templates.Gantt.requests.frontendFilterSettings) {
      // return if deactivated in config
      return;
    }
    this.requestService
      .call(ERequestMethod.GET, `${this.restURL}/${templateId}`)
      .pipe(
        map((data: FrontendFilter[]) => {
          return data.map((ff) => this.inherit(FrontendFilter, ff));
        })
      )
      .subscribe(
        (filterList: FrontendFilter[]) => {
          this.filterList = filterList;
        },
        (_) => {
          console.warn('Failed to load gantt filter data');
          this.filterList = [];
        }
      );
  }

  /**
   * Checks if the user can change the fixed filters for a given template.
   * If the feature is deactivated in the config, the method returns early.
   * Otherwise, it sends a GET request to the server to check if the user is allowed to edit the filters.
   * @param templateId The ID of the template to check.
   */
  private checkIfUserCanChangeFixedFilters(templateId: string): void {
    if (!this.configService.access().templates.Gantt.requests.frontendFilterSettings) {
      // return if deactivated in config
      return;
    }
    this.requestService
      .call(ERequestMethod.GET, `${this.restURL}/${templateId}/editable`)
      .pipe(
        catchError((error) => {
          console.warn('Filter editable check failed.');
          return of(true);
        })
      )
      .subscribe((isAllowed: boolean) => (this._isChangeFixedFiltersAllowed = isAllowed));
  }

  /**
   * Emits a given filter list as filter change event.
   */
  private changeFilter(filterList: FrontendFilter[]): void {
    this._filterListSubject.next(filterList);
  }

  set filterList(filterList: FrontendFilter[]) {
    this._filterList = filterList;
    this.changeFilter(filterList);
  }

  get filterList(): FrontendFilter[] {
    return this._filterList;
  }

  get isChangeFixedFiltersAllowed() {
    return this._isChangeFixedFiltersAllowed;
  }
}
