import * as d3 from 'd3';
import { Subscription } from 'rxjs';
import { ShiftDataFinder } from '../../data-handler/data-finder/shift-data-finder';
import { GanttScrollContainerEvent } from '../../html-structure/scroll-container-event';
import { BestGantt } from '../../main';
import { IShiftClickEvent } from '../../shifts/shift-events.interface';

/**
 * Mouse handler to give possibility to add new edges into gantt by user action.
 * @keywords user, click, onclick, plugin, edge, edit, dashboard
 * @plugin edges
 * @class
 * @constructor
 */
export class GanttEdgeMouseConnect {
  private _catchedIDs: string[] = [];
  private _isStartConnectionModeActive = false; // mechanism to avoid double execution
  private _onClickCallbacks: { [id: string]: (id1: string, id2: string) => void } = {};
  private _subscriptions: { [id: string]: Subscription } = {};

  constructor(private _ganttDiagram: BestGantt, private _parentNode: SVGElement) {}

  /**
   * this function is called by starting the "connection-mode"
   */
  public startConnectionMode(): void {
    if (this._isStartConnectionModeActive) return;

    this._isStartConnectionModeActive = true;

    this._subscriptions.getIDsFromClickEvent = this._ganttDiagram
      .getShiftFacade()
      .shiftOnClick()
      .subscribe((event) => this._getIDsFromClickEvent(event));
  }

  /**
   * this function is called by clicking on shift and handles the connection-process
   * @param event click-event
   */
  private _getIDsFromClickEvent(event: GanttScrollContainerEvent<IShiftClickEvent>): void {
    const shiftID = (event.event.event as any).subject.id;

    this._clickVisualizer(shiftID);

    this._catchedIDs.push(shiftID);

    if (this._catchedIDs.length == 2) {
      //if the second shift is selected
      for (const func in this._onClickCallbacks) {
        if (func == 'createEdgeByIds') this._onClickCallbacks[func](this._catchedIDs[0], this._catchedIDs[1]); //creates the edge by shiftID
      }
      this._isStartConnectionModeActive = false;
      this._catchedIDs = []; //clear ID-array
      this._subscriptions.getIDsFromClickEvent.unsubscribe(); //removing this function from click-event
    }
  }

  /**
   * this function visualizes the selection via click-event
   * @param shiftID
   */
  private _clickVisualizer(shiftID: string): void {
    const canvasShiftDataset = this._ganttDiagram.getDataHandler().getCanvasShiftDataset();
    const shiftData = ShiftDataFinder.getCanvasShiftById(canvasShiftDataset, shiftID)[0];

    const visualisation = d3
      .select(this._parentNode) //build frame around the rect
      .append('rect')
      .attr('x', this._zoomCoordinate(shiftData.x))
      .attr('y', shiftData.y)
      .attr('width', this.zoomProportion(shiftData.width))
      .attr('height', shiftData.height)
      .style('fill', 'lime')
      .style('opacity', 0);

    visualisation //animation
      .transition()
      .ease(d3.easeLinear)
      .duration(100)
      .style('opacity', 0.5)
      .transition()
      .ease(d3.easeLinear)
      .duration(200)
      .delay(300)
      .style('opacity', '0')
      .on('end', function () {
        this.remove();
      });
  }

  public createOnClickCallback(id: string, func: (id1: string, id2: string) => void): void {
    this._onClickCallbacks[id] = func;
  }

  public removeOnClickCallback(id: string): void {
    delete this._onClickCallbacks[id];
  }

  /**
   * this function returns the real x-coordinate of canvas
   * @param coordinate x-coordinate
   */
  private _zoomCoordinate(coordinate: number): number {
    const transformInformation = this._ganttDiagram.getShiftFacade().getShiftBuilder().getLastZoomTransformation();
    return coordinate * transformInformation.k + transformInformation.x;
  }

  /**
   * this function returns the real height or width of canvas
   * @param attribute height or width
   */
  private zoomProportion(attribute: number): number {
    const transformInformation = this._ganttDiagram.getShiftFacade().getShiftBuilder().getLastZoomTransformation();
    return attribute * transformInformation.k;
  }
}
