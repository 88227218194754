import { ZoomTransform } from 'd3';
import { GanttCanvasShift } from '../../data-handler/data-structure/data-structure';
import { TextOverlay } from '../text-overlay';
import { TextOverlayOutside } from './text-overlay-outside';

/**
 * Strategie lets text flow out of shift but caps before next shift.
 * Additionally creates colored rect behind shift to cover borders and improve readability.
 * Use this strategie to show more information.
 * @extends {TextOverlayOutside}
 * @class
 */
export class TextOverlayOutsideBG extends TextOverlayOutside {
  constructor() {
    super();
  }

  /**
   * @override
   */
  init(reference) {
    const s = reference;
    const self = this;

    s.config.setShiftStrokeLeaveRightSideOpen(true);
  }
  /**
   * @override
   */
  render(shiftDataset: GanttCanvasShift[], zoomTransformation: ZoomTransform, reference: TextOverlay) {
    const s = reference;
    const self = this;

    if (!shiftDataset) return;
    const textOverlay = s.canvas
      .selectAll('dummy')
      .data(shiftDataset)
      .enter()
      .append('div')
      .style('top', (d) => {
        const y = s.ganttDiagram.getRenderDataHandler().getStateStorage().getYPositionShift(d.id);
        return y + 'px';
      })
      .style('left', (d) => {
        let x = d.x * zoomTransformation.k + zoomTransformation.x + s.config.textOverflowLeft();
        if (x < 0) {
          x = s.config.textOverflowLeft();
        }
        return x + 'px';
      })
      .style('height', (d) => s.config.getTextOverlayHeight() + 'px')
      .style('width', (d, i) => {
        let width = 0;
        let nextWidth: number;
        const x = d.x * zoomTransformation.k + zoomTransformation.x + s.config.textOverflowLeft();
        const neighbor = s.findNeighbor(shiftDataset, i, d, s.config);
        const next = neighbor.next;
        if (next) {
          if (s.isElemOverlapping(shiftDataset, d, s.config, 'next')) {
            nextWidth = 0;
          } else {
            nextWidth = next.x * zoomTransformation.k - d.x * zoomTransformation.k;
          }
        } else {
          nextWidth = 10000; // no restriction to the right as there is no right shift neighbor
        }

        let preWidth;
        const prev = neighbor.prev;
        if (prev) {
          if (s.isElemOverlapping(shiftDataset, d, s.config, 'prev')) {
            preWidth = 0; // if prev with higher priority is overlapping, we don't show the text
          } else {
            preWidth = d.x * zoomTransformation.k - prev.x * zoomTransformation.k;
          }
        }

        if (nextWidth !== 0 || d.renderPriority < next.renderPriority) {
          width = nextWidth;
        } else if (
          neighbor.nextNotSameStart &&
          neighbor.nextNotSameStart.id !== next.id &&
          s.isOverlapping(d, neighbor.nextNotSameStart)
        ) {
          width = neighbor.nextNotSameStart.x * zoomTransformation.k - d.x * zoomTransformation.k;
        } else {
          width = s.findNextNeighbor(shiftDataset, d, s.config) * zoomTransformation.k;
        }

        width -= s.config.textOverflowLeft() + 2;

        if (x < 0) {
          width = width - -1 * x; // compensate shift partly behind y Axis
        }
        if (width < 10 || (preWidth === 0 && d.renderPriority < prev.renderPriority)) {
          width = 0; // on very small widths the text-overflow is glitching, so we set a minimum to fix this
        }
        return width + 'px';
      })
      .attr('class', () => {
        return reference.config.css.shifts.text_overlay_item;
      })
      .text((d) => d.name)
      .style('font-size', function () {
        return s.config.getShiftFontSize() + 'px';
      })
      .style('color', s.config.getShiftTextColor())
      .style('text-shadow', (d) => s.getTextOverlayShadow(d));
  }

  /**
   * @deprecated
   */
  private _matchingText(d, zoomTransformation, reference, shiftDataset) {
    const s = reference;
    return;
    const self = this;
    const xPos = d.x * zoomTransformation.k + zoomTransformation.x + s.config.textOverlayBorderToNextShift();

    let text = '';
    let neighborxStart;

    const index = shiftDataset.findIndex(function (elem) {
      return elem.id == d.id;
    });

    if (shiftDataset[index + 1]) {
      if (shiftDataset[index + 1].y == d.y && shiftDataset[index + 1].x > d.x) {
        neighborxStart = shiftDataset[index + 1].x;
      } else {
        // last shift in its row
        return d.name;
      }
    } else {
      // last shift in dataset
      return d.name;
    }

    if (xPos < 0) {
      // shift cut off by y axis
      text = s.getTruncatedText(d.name, neighborxStart, zoomTransformation, true);
    } else {
      text = s.getTruncatedText(d.name, neighborxStart - d.x, zoomTransformation, false);
    }

    return text;
  }

  /**
   * @override
   */
  cleanUp(reference) {
    const s = reference;
    const self = this;

    s.config.setShiftStrokeLeaveRightSideOpen(false);
  }
}
