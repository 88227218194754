import {
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { TemplateComponent } from '@app-modeleditor/components/template/template.component';
import { TemplateService } from '@app-modeleditor/utils/template.service';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { ChartLegendWrapperTemplate } from './chart-legend-wrapper-template';

@Component({
  selector: 'app-chart-legend',
  templateUrl: './chart-legend.component.html',
  styleUrls: ['./chart-legend.component.scss'],
})
export class ChartLegendComponent implements OnInit, OnDestroy {
  @Input() legendTemplate: ChartLegendWrapperTemplate;
  @Input() legendHeight: number;

  @ViewChild('chartLegendContainer', { read: ViewContainerRef }) chartLegendContainer: ViewContainerRef;

  constructor(private templateApi: TemplateService, private resolver: ComponentFactoryResolver) {}

  ngOnInit(): void {
    this.initLegend();
  }

  private chartLegendContainerRef: ComponentRef<TemplateComponent>;
  private initLegend() {
    of(null)
      .pipe(delay(0))
      .subscribe(() => {
        if (this.chartLegendContainer) {
          this.chartLegendContainerRef = this.templateApi.loadComponent<TemplateComponent>(
            TemplateComponent,
            this.chartLegendContainer,
            this.resolver
          );
          this.legendTemplate.setHeight(this.legendHeight);
          this.chartLegendContainerRef.instance.templateNode = this.legendTemplate;
        }
      });
  }

  ngOnDestroy(): void {
    if (this.chartLegendContainerRef) {
      this.chartLegendContainerRef.destroy();
    }
  }
}
