import { Overlay, OverlayRef, PositionStrategy } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { Injectable, Injector, NgZone } from '@angular/core';
import { ConfigService } from '@core/config/config.service';
import { Store } from '@ngxs/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { NotificationActions } from '../../shared/data-access/notification.actions';
import { CONTAINER_DATA, Message } from './message';
import { MessageComponent } from './message.component';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  private _messages: Message[];
  private _storage: Message[] = [];
  private _storageAction: BehaviorSubject<Message[]> = new BehaviorSubject<Message[]>([]);
  private old = this.config.access()?.notification?.old ? true : false;

  constructor(
    private store: Store,
    private overlay: Overlay,
    private injector: Injector,
    private zone: NgZone,
    private config: ConfigService
  ) {}

  getStorage(): Observable<Message[]> {
    return this._storageAction.asObservable();
  }

  setStorage(storage: Message[]): void {
    if (storage == undefined) {
      return;
    }
    while (storage.length > 10) {
      storage.shift();
    }

    this._storageAction.next(storage);
  }

  public confirmMessage(message: Message): void {
    this.setStorage((this._storage || []).filter((m: Message) => message.getId() !== m.getId()));
  }

  /**
   * updates position an removes messages if there are more than three
   * @returns void
   */
  private _updateMessages(): void {
    this._messages = this._messages.sort((a: Message, b) =>
      a.getCreated().getTime() > b.getCreated().getTime() ? -1 : 1
    );

    if (this._messages.length > 3) {
      this._messages.slice(3, this._messages.length).forEach((m: Message) => {
        if (!m.isPinned()) {
          m.getRef().dispose();
        }
      });
    }

    this._messages.reverse().forEach((m: Message, index: number) => {
      if (m.isPinned()) {
        return;
      }
      const top = 35 + index * 90;
      const right = 10;
      const positionStrategy: PositionStrategy = this.overlay.position().global().top(`${top}px`).right(`${right}px`);
      m.getRef().updatePositionStrategy(positionStrategy);
    });
  }

  private _removeMessage(id: string) {
    this._messages = this._messages.filter((m: Message) => m.getId() !== id);

    this._updateMessages();
  }

  private _createInjector(dataToPass: Message): PortalInjector {
    const injectorTokens = new WeakMap();
    injectorTokens.set(CONTAINER_DATA, dataToPass);
    return new PortalInjector(this.injector, injectorTokens);
  }

  /**
   * show message
   * @param msg Message
   * @returns void
   */
  public show(msg: Message): void {
    if (this.config.access().messaging.showUserMessages === false) {
      return;
    }

    this.zone.run(() => {
      if (!this._messages) {
        this._messages = [];
      }
      this._messages = this._messages.filter((_message) => _message.getRef() && _message.getRef().overlayElement);
      if (this.old) {
        msg.setCreated(new Date());
        const positionStrategy = this.overlay.position().global().top('10px').right('10px');

        const overlayRef: OverlayRef = this.overlay.create({
          hasBackdrop: false,
          positionStrategy,
          backdropClass: 'custom-backdrop',
        });
        overlayRef.addPanelClass('saxms-system-message');

        msg.setRef(overlayRef);
        this._messages.push(msg);

        const overlayPortal: ComponentPortal<MessageComponent> = new ComponentPortal(
          MessageComponent,
          null,
          this._createInjector(msg)
        );

        const cmpRef = overlayRef.attach(overlayPortal);

        // remove message from list of cmp got destroyed
        cmpRef.onDestroy(() => {
          this._removeMessage(msg.getId());
        });

        this._updateMessages();
      } else {
        this.store.dispatch(
          new NotificationActions.Create({
            duration: 20000,
            title: msg.getTitle(),
            text: msg.getText(),
            params: msg.getParams(),
            id: msg.getId(),
            type: msg.getType() as any,
          })
        );
      }
    });
  }
}
