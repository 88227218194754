import { EGanttInstance, GanttCanvasShift } from '@gantt/public-api';
import { GanttEssentialPlugIns } from '../../../plugin/e-gantt-essential-plugins';
import { GanttSuperBlocksPlugIn } from '../../../plugin/plugin-list/superblocks/superblocks.plugin';
import { GanttActionWrapper } from '../../external-action-registration';
import { GanttEventTrigger } from '../../external-event-trigger';
import { EGanttActionEvent } from '../gantt-action-event.enum';
import { takeUntil } from 'rxjs';

/**
 * Event which will be fired on resize end of a shift block.
 */
export class GanttResizeEndEvent extends GanttEventTrigger {
  public readonly eventName: EGanttActionEvent = EGanttActionEvent.RESIZE_END;

  /**
   * Registers shiftResizeEnd-callbacks inside the given gantt.
   */
  public registerInGantt(): void {
    const ganttDiagram = this._ganttLibService.bestGantt;
    ganttDiagram
      .getShiftResizer()
      .afterShiftEditEnd()
      .pipe(takeUntil(this.onRemoveFromGantt))
      .subscribe((event) => {
        const shiftData: GanttCanvasShift = event.shiftSelection.data()[0];
        const foundShiftData = this._ganttLibService.ganttInstanceService
          .getInstance(EGanttInstance.SHIFT_DATA_FINDER)
          .getShiftById(ganttDiagram.getDataHandler().getOriginDataset().ganttEntries, shiftData.id);
        if (!foundShiftData?.shift || !foundShiftData?.shiftRow) return;
        // check if action will be executed by correct target row
        if (
          this.action.targetRowIDs &&
          this.action.targetRowIDs.indexOf(foundShiftData.shiftRow.additionalData.originalRowId) == -1
        )
          return;
        if (
          this.action.forBlockType &&
          foundShiftData.shift.additionalData.blockTypes &&
          !this.shiftHasBlocktype(foundShiftData.shift.additionalData.blockTypes, this.action.forBlockType)
        )
          return;
        this.saveDataToTemplate(foundShiftData.shift, foundShiftData.shiftRow);
        const actionWrapper: GanttActionWrapper = {
          actionScope: this.action.actionScope,
          actions: this.action.actions,
          editedBlockIds: [foundShiftData.shift.id],
        };
        this._ganttLibService.ngZone.run(() => {
          this.actionHandler
            .executeActions(actionWrapper)
            .subscribe((data) => this.responseHandler.handleUpdateNotification(data as any));
        });
      });
  }

  /**
   * Removes all callbacks from gantt.
   */
  public removeFromGantt(): void {
    super.removeFromGantt();
  }

  /**
   * Saves affected block and its row to gantt template.
   * @param shiftData Resized shift block.
   * @param rowData Row in which block is inside.
   */
  public saveDataToTemplate(shiftData: any, rowData: any): void {
    // all dragged shifts
    this.templateData.setSelectedValues([shiftData], this._ganttLibService.backendToGanttOriginInputMapper);
    // ? this.templateData.selectedValues = [shiftData.id];
    // move origin and target
    // if (!this.templateData.targetrow) this.templateData.targetrow = {};
    // drag target block
    // if (!this.templateData.selectedBlock) this.templateData.selectedBlock = {};

    // extract row data
    // save data to template
    this.templateData.setTargetRow(rowData.id);
    // ? this.templateData.targetrow.id = rowData.id;
    this.templateData.setSelectedBlock(
      {
        id: shiftData.id,
        start: new Date(shiftData.timePointStart).getTime(),
        end: new Date(shiftData.timePointEnd).getTime(),
      },
      this._ganttLibService.backendToGanttOriginInputMapper
    );
    /* ?
    this.templateData.selectedBlock.id = shiftData.id;
    this.templateData.selectedBlock.start = new Date(shiftData.timePointStart).getTime();
    this.templateData.selectedBlock.end = new Date(shiftData.timePointEnd).getTime(); */

    if (shiftData.additionalData && shiftData.additionalData.superBlockData) {
      const superBlocks = {};
      for (const superBlockTypeKey in shiftData.additionalData.superBlockData) {
        superBlocks[superBlockTypeKey] = shiftData.additionalData.superBlockData[superBlockTypeKey];
        // ? this.templateData.selectedBlock["superBlocks[" + superBlockTypeKey + "]"] = shiftData.additionalData.superBlockData[superBlockTypeKey];
      }
      this.templateData.setSuperBlocks(superBlocks);
    }
  }

  private shiftHasBlocktype(blockTypes: number[], superBlockType: string): boolean {
    const superBlocksPlugIn: GanttSuperBlocksPlugIn = this._ganttPluginHandlerService.getEssentialPlugIn(
      GanttEssentialPlugIns.SuperBlocksPlugIn
    );
    if (!superBlocksPlugIn) return false;
    const superBlockTypeNumber = superBlocksPlugIn.getTypeNumberByType(superBlockType);
    if (superBlockTypeNumber == null) return false;
    return blockTypes.indexOf(superBlockTypeNumber) != -1;
  }
}
