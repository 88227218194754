import { FreetextFilter } from '@core/filter/freetext-filter.enum';
import { ETemplateType } from 'frontend/src/dashboard/model/resource/template-type';
import { Action } from '../button/action/action';
import { Content } from '../content/content';
import { ContentElement } from '../content/content-element/content-element';
import { ContextMenuItem } from '../contextmenu/context-menu-item';
import { Filter } from '../menu-item/filter';
import { EMenuItemHandlingType } from '../menu-item/menu-item-handling-type.enum';
import { Sidebar } from '../template-sidebar/sidebar';
import { HierarchicalMenuItem } from '../template-ui/hierarchical-menu-item';
import { EDefaultContextMenuItem } from './default-context-menu-item.enum';

export interface IPropertyIcon {
  color: string;
  icon: string;
}

export class HMI extends ContentElement {
  private content: Content;
  private hierachicMenuItems: HierarchicalMenuItem[];
  private visibility: boolean;
  private level: number;
  private tenantId: string;
  private sideBar: Sidebar;
  private index: number;
  private editRestUrl: string;
  private freetextSearchUrl: string;
  private expanded: boolean;
  private deleteRestUrl: string;
  private icon: string;
  private filterSearchUrl: string;
  private filters: Filter[];
  private createRestWizardUrls?: { [key: string]: string };
  private createRestUrls: { [key: string]: string };
  private lastFilterType: FreetextFilter;
  private filterString: string;
  private handlingType: EMenuItemHandlingType;
  private notificationsCount: number;
  private showNotificationsCount: boolean;
  private additionalDisplayElements: string[];
  private invisibleDefaultContextMenuItems: EDefaultContextMenuItem[];
  private additionalContextMenuItems: ContextMenuItem[];
  private cloneRestUrl: string;
  private canOpenExternalWindow: boolean;
  private selected: boolean;
  private subHierachicMenuItemsRestUrl: string;
  private icons: IPropertyIcon[];
  private preDeliveryActions: Action[];
  private leaf: boolean;
  private parentList: string[];
  private multitenant: boolean;
  // name of the tenant
  private tenantName: string;
  private showContentPartNavigation: boolean;
  private executers: Action[];
  private hiddenDefaultTemplateElements: string[];
  private uniqueTemplateId: string;
  private hideContentPartNavigation: boolean;

  public isHideContentPartNavigation(): boolean {
    return typeof this.hideContentPartNavigation === 'boolean' ? this.hideContentPartNavigation : false;
  }

  public setHideContentPartNavigation(hideContentPartNavigation: boolean): this {
    this.hideContentPartNavigation = hideContentPartNavigation;
    return this;
  }

  public getUniqueTemplateId(): string {
    return this.uniqueTemplateId;
  }

  public setUniqueTemplateId(uniqueTemplateId: string): this {
    this.uniqueTemplateId = uniqueTemplateId;
    return this;
  }

  public getExecuters(): Action[] {
    return this.executers || [];
  }

  public setExecuters(executers: Action[]): this {
    this.executers = executers;
    return this;
  }

  public getHiddenDefaultTemplateElements(): string[] {
    return this.hiddenDefaultTemplateElements || [];
  }

  public setHiddenDefaultTemplateElements(hiddenDefaultTemplateElements: string[]): this {
    this.hiddenDefaultTemplateElements = hiddenDefaultTemplateElements;
    return this;
  }

  public isHiddenTemplate(template: string): boolean {
    return this.getHiddenDefaultTemplateElements().find((t: string) => template) ? true : false;
  }

  public getSideBar(): Sidebar {
    return this.sideBar;
  }

  public setSideBar(sideBar: Sidebar): this {
    this.sideBar = sideBar;
    return this;
  }

  public getTenantId(): string {
    return this.tenantId;
  }

  public setTenantId(tenantId: string): this {
    this.tenantId = tenantId;
    return this;
  }

  public isShowContentPartNavigation(): boolean {
    if (this.isHideContentPartNavigation() === true) {
      return false;
    }

    return typeof this.showContentPartNavigation === 'boolean' ? this.showContentPartNavigation : false;
  }

  public setShowContentPartNavigation(showContentPartNavigation: boolean): this {
    this.showContentPartNavigation = showContentPartNavigation;
    return this;
  }

  /**
   * get name of assigned tenant
   * @returns string
   */
  public getTenantName(): string {
    return this.tenantName;
  }

  /**
   * set assigned tenant of hmi
   * @param {string} tenantName name of the tenant
   * @returns this
   */
  public setTenantName(tenantName: string): this {
    this.tenantName = tenantName;
    return this;
  }

  /**
   * wether the entry is multitenant or not
   * @returns boolean
   */
  public isMultitenant(): boolean {
    return typeof this.multitenant === 'boolean' ? this.multitenant : true;
  }

  /**
   * get number of rows to render
   * @returns number
   */
  public getRowCount(): number {
    let count = 0;

    if (this.getTenantName() && this.getAdditionalDisplayElements().length === 0) {
      count++;
    }

    count += this.getAdditionalDisplayElements().length;

    return count;
  }

  public setMultitenant(multitenant: boolean): this {
    this.multitenant = multitenant;
    return this;
  }

  public getParentList(): string[] {
    return this.parentList || [];
  }

  public setParentList(parentList: string[]): this {
    this.parentList = parentList;
    return this;
  }

  public getIndex(): number {
    return this.index || 0;
  }

  public setIndex(index: number): this {
    this.index = index;
    return this;
  }

  public isLeaf(): boolean {
    return typeof this.leaf === 'boolean' ? this.leaf : false;
  }

  public setLeaf(leaf: boolean): this {
    this.leaf = leaf;
    return this;
  }

  public getPreDeliveryActions(): Action[] {
    return this.preDeliveryActions || [];
  }

  public setPreDeliveryActions(preDeliveryActions: Action[]): this {
    this.preDeliveryActions = preDeliveryActions;
    return this;
  }

  public getIcons(): IPropertyIcon[] {
    return this.icons || [];
  }

  public setIcons(icons: IPropertyIcon[]): this {
    this.icons = icons;
    return this;
  }

  /**
   * get children of the tree dynamically
   * @returns string
   */
  public getSubHierachicMenuItemsRestUrl(): string {
    return this.subHierachicMenuItemsRestUrl;
  }

  /**
   * set the url for getting children of the tree dynamically
   * @params subHierachicMenuItemsRestUrl string
   * @returns this,
   */
  public setSubHierachicMenuItemsRestUrl(subHierachicMenuItemsRestUrl: string): this {
    this.subHierachicMenuItemsRestUrl = subHierachicMenuItemsRestUrl;
    return this;
  }

  public isSelected(): boolean {
    return typeof this.selected === 'boolean' ? this.selected : false;
  }

  public setSelected(selected: boolean): this {
    this.selected = selected;
    return this;
  }

  public isCanOpenExternalWindow(): boolean {
    return typeof this.canOpenExternalWindow === 'boolean' ? this.canOpenExternalWindow : true;
  }

  public setCanOpenExternalWindow(canOpenExternalWindow: boolean): this {
    this.canOpenExternalWindow = canOpenExternalWindow;
    return this;
  }

  public getEditRestUrl(): string {
    return this.editRestUrl;
  }

  public setEditRestUrl(editRestUrl: string): this {
    this.editRestUrl = editRestUrl;
    return this;
  }

  public getCloneRestUrl(): string {
    return this.cloneRestUrl;
  }

  public setCloneRestUrl(cloneRestUrl: string): this {
    this.cloneRestUrl = cloneRestUrl;
    return this;
  }

  public getAdditionalContextMenuItems(): ContextMenuItem[] {
    return this.additionalContextMenuItems || [];
  }

  public setAdditionalContextMenuItems(additionalContextMenuItems: ContextMenuItem[]): this {
    this.additionalContextMenuItems = additionalContextMenuItems;
    return this;
  }

  public isInvisibleDefaultContextMenuItem(key: EDefaultContextMenuItem): boolean {
    return (this.invisibleDefaultContextMenuItems || []).find((s: EDefaultContextMenuItem) => s === key) ? true : false;
  }

  public setInvisibleDefaultContextMenuItems(invisibleDefaultContextMenuItems: EDefaultContextMenuItem[]): this {
    this.invisibleDefaultContextMenuItems = invisibleDefaultContextMenuItems;
    return this;
  }

  public getDeleteRestUrl(): string {
    return this.deleteRestUrl;
  }

  public setDeleteRestUrl(deleteRestUrl: string): this {
    this.deleteRestUrl = deleteRestUrl;
    return this;
  }

  public isShowNotificationsCount(): boolean {
    return typeof this.showNotificationsCount === 'boolean' ? this.showNotificationsCount : true;
  }

  public setShowNotificationsCount(showNotificationsCount: boolean): this {
    this.showNotificationsCount = showNotificationsCount;
    return this;
  }

  public getAdditionalDisplayElements(): string[] {
    return this.additionalDisplayElements || [];
  }

  public setAdditionalDisplayElements(additionalDisplayElements: string[]): this {
    this.additionalDisplayElements = additionalDisplayElements;
    return this;
  }

  public getIcon(): string {
    return this.icon;
  }

  public setIcon(icon: string): this {
    this.icon = icon;
    return this;
  }

  public getNotificationsCount(): number {
    return this.notificationsCount;
  }

  public setNotificationsCount(notificationsCount: number): this {
    this.notificationsCount = notificationsCount;
    return this;
  }

  public getFreetextSearchUrl(): string {
    return this.freetextSearchUrl;
  }

  public setFreetextSearchUrl(freetextSearchUrl: string): this {
    this.freetextSearchUrl = freetextSearchUrl;
    return this;
  }

  public getHandlingType(): EMenuItemHandlingType {
    return this.handlingType;
  }

  public setHandlingType(handlingType: EMenuItemHandlingType): this {
    this.handlingType = handlingType;
    return this;
  }

  public getLastFilterType(): FreetextFilter {
    return this.lastFilterType;
  }

  public setLastFilterType(lastFilterType: FreetextFilter): this {
    this.lastFilterType = lastFilterType;
    return this;
  }

  public getFilterString(): string {
    return this.filterString;
  }

  public setFilterString(filterString: string): this {
    this.filterString = filterString;
    return this;
  }

  public getCreateRestUrls(): { [key: string]: string } {
    return this.createRestUrls;
  }

  public setCreateRestUrls(urls: { [key: string]: string }): this {
    this.createRestUrls = urls;
    return this;
  }

  public setCreateWizardRestUrls(urls: { [key: string]: string }): this {
    this.createRestWizardUrls = urls;
    return this;
  }

  public getCreateWizardRestUrls(): { [key: string]: string } {
    return this.createRestWizardUrls;
  }

  public getFilterSearchUrl(): string {
    return this.filterSearchUrl;
  }

  public setFilterSearchUrl(filterSearchUrl: string): this {
    this.filterSearchUrl = filterSearchUrl;
    return this;
  }

  public getFilters(): Filter[] {
    return this.filters || [];
  }

  public setFilters(filters: Filter[]): this {
    this.filters = filters;
    return this;
  }

  public setExpanded(expanded: boolean): this {
    this.expanded = expanded;
    return this;
  }

  public isExpanded(): boolean {
    return typeof this.expanded === 'boolean' ? this.expanded : false;
  }

  public isExpandable(): boolean {
    if (this.isLeaf()) {
      return false;
    }

    return this.getHierachicMenuItems().length === 0 && !this.getSubHierachicMenuItemsRestUrl() ? false : true;
  }

  public getLevel(): number {
    return this.level;
  }

  public setLevel(level: number): this {
    this.level = level;
    return this;
  }

  public isVisible(): boolean {
    return typeof this.visibility === 'boolean' ? this.visibility : false;
  }

  public setVisibility(visibility: boolean): this {
    this.visibility = visibility;
    return this;
  }

  public getContent(): Content {
    return this.content;
  }

  public setContent(content: Content): this {
    this.content = content;
    this.getContent()?.setParentId(this.getId());
    return this;
  }

  public getHierachicMenuItems(): HierarchicalMenuItem[] {
    return this.hierachicMenuItems || [];
  }

  public setHierachicMenuItems(hierachicMenuItems: HierarchicalMenuItem[]): this {
    this.hierachicMenuItems = hierachicMenuItems;
    this.getHierachicMenuItems().forEach((hmi: HierarchicalMenuItem) => hmi.setParentId(this.getId()));
    return this;
  }

  constructor() {
    super();
    this.setType(ETemplateType.CONTENT_WRAPPER);
  }
}
