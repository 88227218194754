import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EmptyElementComponent } from './empty-element.component';

@NgModule({
  imports: [CommonModule],
  declarations: [EmptyElementComponent],
  exports: [EmptyElementComponent],
})
export class EmptyElementModule {}
