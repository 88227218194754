import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Injectable()
export class ScrollHandlerService {
  private verticalScrollStartSubject = new Subject<void>();
  private verticalScrollSubject = new Subject<void>();
  private verticalScrollEndSubject = new Subject<void>();
  private _isVerticalScrolling = false;

  private horizontalScrollStartSubject = new Subject<void>();
  private horizontalScrollSubject = new Subject<void>();
  private horizontalScrollEndSubject = new Subject<void>();
  private _isHorizontalScrolling = false;

  constructor() {}

  triggerVerticalScroll() {
    if (!this._isVerticalScrolling) {
      // scroll start handling
      this.verticalScrollStartSubject.next();
      this._isVerticalScrolling = true;

      // scroll end handling
      this.verticalScrollSubject.pipe(takeUntil(this.verticalScrollEndSubject), debounceTime(100)).subscribe(() => {
        this._isVerticalScrolling = false;
        this.verticalScrollEndSubject.next();
      });
      return;
    }

    // during scroll handling
    this.verticalScrollSubject.next();
  }

  onVerticalScrollStart(): Observable<void> {
    return this.verticalScrollStartSubject.asObservable();
  }

  onVerticalScroll(): Observable<void> {
    return this.verticalScrollSubject.asObservable();
  }

  onVerticalScrollEnd(): Observable<void> {
    return this.verticalScrollEndSubject.asObservable();
  }

  triggerHorizontalScroll() {
    if (!this._isHorizontalScrolling) {
      // scroll start handling
      this.horizontalScrollStartSubject.next();
      this._isHorizontalScrolling = true;

      // scroll end handling
      this.horizontalScrollSubject.pipe(takeUntil(this.horizontalScrollEndSubject), debounceTime(100)).subscribe(() => {
        this._isHorizontalScrolling = false;
        this.horizontalScrollEndSubject.next();
      });
      return;
    }

    // during scroll handling
    this.horizontalScrollSubject.next();
  }

  onHorizontalScrollStart(): Observable<void> {
    return this.horizontalScrollStartSubject.asObservable();
  }

  onHorizontalScroll(): Observable<void> {
    return this.horizontalScrollSubject.asObservable();
  }

  onHorizontalScrollEnd(): Observable<void> {
    return this.horizontalScrollEndSubject.asObservable();
  }

  get isHorizontalScrolling(): boolean {
    return this._isHorizontalScrolling;
  }

  get isVerticalScrolling(): boolean {
    return this._isVerticalScrolling;
  }
}
