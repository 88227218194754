import { Observable, Subject, takeUntil } from 'rxjs';
import { GanttCanvasShift } from '../../data-handler/data-structure/data-structure';
import { EGanttScrollContainer } from '../../html-structure/scroll-container.enum';
import { BestGantt } from '../../main';
import { BestGanttPlugIn } from '../gantt-plug-in';

/**
 * Plugin to visualize earliest start and latest end of gantt blocks.
 */
export class GanttEarliestStartLatestEndVisualizer extends BestGanttPlugIn {
  private _canvas: { [id: string]: d3.Selection<SVGGElement, undefined, d3.BaseType, undefined> } = {};
  private _selectionCanvas: { [id: string]: d3.Selection<SVGGElement, undefined, d3.BaseType, undefined> } = {};
  private _strokeWidth = 1; // stroke width in px
  private _isActive = false;
  private _visualizeShiftSelections = false;

  private _onDestroySubject: Subject<void> = new Subject<void>();
  private _onUnsubscribeFromShiftSelectionSubject = new Subject<void>();

  constructor() {
    super();
  }

  public initPlugIn(ganttDiagram: BestGantt): void {
    this.ganttDiagram = ganttDiagram;
    if (!this._isActive) {
      return;
    }
    this._initCanvas();

    // add callbacks
    this.ganttDiagram
      .getXAxisBuilder()
      .onZooming.pipe(takeUntil(this.onDestroy))
      .subscribe(() => this._build());
    this.ganttDiagram
      .getVerticalScrollHandler()
      .onScrollVerticalUpdate.pipe(takeUntil(this.onDestroy))
      .subscribe(() => this._build());

    // activate shift selection visualization by default
    this.setVisualizeShiftSelections(true);
  }

  public update(): void {
    this._build();
  }

  public removePlugIn(): void {
    // remove DOM nodes
    for (const scrollContainerId of Object.values(EGanttScrollContainer)) {
      this._canvas[scrollContainerId]?.remove();
      this._selectionCanvas[scrollContainerId]?.remove();
    }
    // remove callbacks
    this._onUnsubscribeFromShiftSelectionSubject.next();
    this._onUnsubscribeFromShiftSelectionSubject.complete();
    this._onDestroySubject.next();
    this._onDestroySubject.complete();
  }

  /**
   * Activates or deactivates this plugin
   * @param {boolean} bool Must be true for activation
   */
  public setActive(bool: boolean): void {
    if (this._isActive === bool) {
      return;
    }
    this._isActive = bool;
    if (this._isActive) {
      this.initPlugIn(this.ganttDiagram);
    } else {
      this.removePlugIn();
    }
  }

  /**
   * Activates/deactivates the visualization of shift selections on earliest start/latest end frames.
   * @param bool Visualization will be activated if true and deactivated if false.
   */
  public setVisualizeShiftSelections(bool: boolean): void {
    if (this._visualizeShiftSelections === bool) return;
    this._visualizeShiftSelections = bool;

    // subscribe shift selection callbacks & build selection canvas
    if (this._visualizeShiftSelections) {
      this._initSelectionCanvas();
      this._subscribeToShiftSelection();
    } else {
      this._onUnsubscribeFromShiftSelectionSubject.next();
      for (const scrollContainerId of Object.values(EGanttScrollContainer)) {
        this._selectionCanvas[scrollContainerId].remove();
        this._selectionCanvas[scrollContainerId] = null;
      }
    }
    // remove remaining selection visualizations
    this._build();
  }

  /**
   * Subscribes to shift selection events to update the selection visualization.
   */
  private _subscribeToShiftSelection(): void {
    this.ganttDiagram
      .getSelectionBoxFacade()
      .afterSelection.pipe(takeUntil(this.onUnsubscribeFromShiftSelection))
      .subscribe(() => this._build());
    this.ganttDiagram
      .getSelectionBoxFacade()
      .afterDeselection.pipe(takeUntil(this.onUnsubscribeFromShiftSelection))
      .subscribe(() => this._build());
    this.ganttDiagram
      .getSelectionBoxFacade()
      .afterSingleShiftSelection.pipe(takeUntil(this.onUnsubscribeFromShiftSelection))
      .subscribe(() => this._build());
    this.ganttDiagram
      .getSelectionBoxFacade()
      .afterSingleShiftDeselection.pipe(takeUntil(this.onUnsubscribeFromShiftSelection))
      .subscribe(() => this._build());
  }

  /**
   * Canvas group creation.
   */
  private _initCanvas(): void {
    for (const scrollContainerId of Object.values(EGanttScrollContainer)) {
      this._canvas[scrollContainerId] = this.ganttDiagram
        .getShiftFacade()
        .getCanvasBehindShifts(scrollContainerId)
        .insert('g', '.gantt_milestone-group')
        .attr('class', 'gantt-earliest-start-latest-end');
    }
  }

  /**
   * Selection canvas group creation.
   */
  private _initSelectionCanvas(): void {
    for (const scrollContainerId of Object.values(EGanttScrollContainer)) {
      this._selectionCanvas[scrollContainerId] = this.ganttDiagram
        .getShiftFacade()
        .getCanvasBehindShifts(scrollContainerId)
        .insert('g', '.gantt_milestone-group')
        .attr('class', 'gantt-earliest-start-latest-end-selections');
    }
  }

  /**
   * Builds earliest start and latest end frames.
   */
  private _build(): void {
    if (!this._isActive) {
      return;
    }
    const renderDataSet = this._getRenderDataSet();

    for (const key in renderDataSet) {
      const frameSelection = this._canvas[key]
        .selectAll('.frame')
        .data(renderDataSet[key].filter((e) => !this._visualizeShiftSelections || !e.selected))
        .attr('x', (d) => d.x)
        .attr('y', (d) => d.y)
        .attr('rx', (d) => d.cornerRadius)
        .attr('ry', (d) => d.cornerRadius)
        .attr('height', (d) => d.height)
        .attr('width', (d) => d.width);
      frameSelection
        .enter()
        .append('rect')
        .attr('class', 'frame')
        .attr('x', (d) => d.x)
        .attr('y', (d) => d.y)
        .attr('height', (d) => d.height)
        .attr('width', (d) => d.width)
        .attr('rx', (d) => d.cornerRadius)
        .attr('ry', (d) => d.cornerRadius)
        .style('fill', '#d45500')
        .style('fill-opacity', 0.145)
        .style('stroke', '#d40000')
        .style('stroke-dasharray', '8 5')
        .style('stroke-width', this._strokeWidth);
      frameSelection.exit().remove();

      if (!this._visualizeShiftSelections || !this._selectionCanvas[key]) return;
      const selectedSelection = this._selectionCanvas[key]
        .selectAll('.frame')
        .data(renderDataSet[key].filter((e) => e.selected))
        .attr('x', (d) => d.x)
        .attr('y', (d) => d.y)
        .attr('rx', (d) => d.cornerRadius)
        .attr('ry', (d) => d.cornerRadius)
        .attr('height', (d) => d.height)
        .attr('width', (d) => d.width)
        .style('fill', '#0055d4')
        .style('stroke', '#0000d4');
      selectedSelection
        .enter()
        .append('rect')
        .attr('class', 'frame')
        .attr('x', (d) => d.x)
        .attr('y', (d) => d.y)
        .attr('height', (d) => d.height)
        .attr('width', (d) => d.width)
        .attr('rx', (d) => d.cornerRadius)
        .attr('ry', (d) => d.cornerRadius)
        .style('fill', '#0055d4')
        .style('fill-opacity', 0.145)
        .style('stroke', '#0000d4')
        .style('stroke-dasharray', '8 5')
        .style('stroke-width', this._strokeWidth);
      selectedSelection.exit().remove();
    }
  }

  /**
   * Handles render dataset creation.
   * @returns render dataset
   */
  private _getRenderDataSet(): IGanttRenderDataSetEsLe {
    const scale = this.ganttDiagram.getXAxisBuilder().getCurrentScale();
    const canvasWidth = this.ganttDiagram.getNodeProportionsState().getShiftViewPortProportions().width;
    const clippingOffset = 10; // an offset to not cut off directly at the viewport edge
    const currentZoomedTimeSpan = this.ganttDiagram.getCurrentZoomedTimeSpan();

    const renderDataset: IGanttRenderDataSetEsLe = {};
    for (const scrollContainerId of Object.values(EGanttScrollContainer)) {
      renderDataset[scrollContainerId] = [];
    }

    this.ganttDiagram
      .getDataHandler()
      .getCanvasShiftDataset()
      .forEach((elem) => {
        const originShift = this.ganttDiagram.getShiftById(elem.id);

        if (
          originShift &&
          originShift.noRender?.length === 0 &&
          originShift.modificationRestriction &&
          originShift.modificationRestriction.earliestStartTime &&
          originShift.modificationRestriction.latestEndTime
        ) {
          const earliestStart = new Date(originShift.modificationRestriction.earliestStartTime);
          const latestEnd = new Date(originShift.modificationRestriction.latestEndTime);

          // filter for shifts in view port
          if (
            (earliestStart < currentZoomedTimeSpan.from && latestEnd < currentZoomedTimeSpan.from) ||
            (earliestStart > currentZoomedTimeSpan.to && latestEnd > currentZoomedTimeSpan.to)
          ) {
            return;
          }

          const x = scale(earliestStart);
          const width = scale(latestEnd) - x;

          // handle clipping
          let cutoffX = x;
          let cutoffWidth = width;

          if (x < 0) {
            // handle left side clipping
            cutoffX = -clippingOffset;
            cutoffWidth += x + clippingOffset;
          }

          if (cutoffX + cutoffWidth > canvasWidth) {
            // handle right side clipping
            cutoffWidth = canvasWidth - cutoffX + clippingOffset;
          }

          const resultWidth = cutoffWidth - 2 * this._strokeWidth;
          if (resultWidth <= 0) {
            return;
          } // prevent negative widths

          const elemY = this.ganttDiagram.getRenderDataHandler().getStateStorage().getYPositionShift(elem.id);
          const scrollContainerId = this.ganttDiagram
            .getRenderDataHandler()
            .getStateStorage()
            .getShiftScrollContainer(elem.id);

          if (isNaN(elemY)) return;

          renderDataset[scrollContainerId].push({
            shiftId: elem.id,
            x: cutoffX + this._strokeWidth,
            y: elemY - this._strokeWidth,
            width: resultWidth,
            height: elem.height + 2 * this._strokeWidth,
            cornerRadius: this._getRoundCornerRadius(elem),
            selected: elem.selected,
          });
        }
      }); // filter out null values

    return renderDataset;
  }

  /**
   * Returns current corner radius.
   * @param {GanttCanvasShift} canvasShift
   * @returns {number} radius
   */
  private _getRoundCornerRadius(canvasShift: GanttCanvasShift): number {
    const areRoundCornersActive = this.ganttDiagram.getConfig().getShiftBuildingRoundedCorners();
    const radius =
      areRoundCornersActive && !canvasShift.noRoundedCorners ? this.ganttDiagram.getConfig().getRoundedCorners() : 0;
    return radius;
  }

  //
  // OBSERVABLES
  //

  /**
   * Observable which gets triggered when the instance gets destroyed.
   */
  private get onDestroy(): Observable<void> {
    return this._onDestroySubject.asObservable();
  }

  private get onUnsubscribeFromShiftSelection(): Observable<void> {
    return this._onUnsubscribeFromShiftSelectionSubject.asObservable();
  }
}

interface IGanttRenderDataSetEsLe {
  [scrollContainerId: string]: IGanttEarliestStartLatestEndVisualizationData[];
}

/**
 * Data structure for earliest start/latest end render data.
 */
interface IGanttEarliestStartLatestEndVisualizationData {
  shiftId: string;
  x: number;
  y: number;
  width: number;
  height: number;
  cornerRadius: number;
  selected: string;
}
