import { ChartLegendTemplate } from './chart-legend-template';
import { ETemplateType } from 'frontend/src/dashboard/model/resource/template-type';
import { EChartLegendType } from '../chart-legend-type.enum';
import { ChartLegendItem } from '../chart-legend-item';

export class ChartDatasetLegend extends ChartLegendTemplate {
  constructor() {
    super();
    this.setType(ETemplateType.CHART_LEGEND_DATASET);
    this.setChartLegendType(EChartLegendType.DATASET);
  }
}
