import { Action } from '@app-modeleditor/components/button/action/action';
import { ETemplateType } from 'frontend/src/dashboard/model/resource/template-type';
import { ContentElement } from '../../content/content-element/content-element';
import { SaxmsColumnHeaderIconSet } from '../core/saxms-column-header-template/saxms-column-header-template.component';
import { SaxMsSelectionModel } from '../core/saxmsSelectionModel/saxmsSelectionModel';
import { EDefaultSubmenues } from './default-submenues.enum';
import { EDefaultTableButton } from './default-table-button.enum';
import { ETableEvents } from './table-events.enum';
import { TableHeader } from './table-header';
import { ETableRowType } from './table-row-type.enum';
import { ETableSelectionMode } from './table-selection-mode.enum';
import { TableValue } from './table-values';

export class Table extends ContentElement {
  private addNewRows: boolean;
  private addTableRowType: ETableRowType;
  private additionalSubMenus: any;
  private applyUserTimeFilter: boolean;
  private autoScrollableIndex: number;
  private batchTable: boolean;
  private cellEdit: boolean;
  private createInlineRow: boolean;
  private createViewTable: boolean;
  private defaultColumns: any;
  private defaultNumberColumns: number;
  private defaultPageSize: number;
  private disabledSubMenues: EDefaultSubmenues[];
  private dynamicTableHeaders: boolean;
  private hiddenHeaders: any;
  private hierarchical: boolean;
  private nameRequired: boolean;
  private defaultFilters: any;
  private nameUnique: boolean;
  private numberFixedColumns: number;
  private numberFixedRowsBottom: number;
  private numberFixedRowsTop: number;
  private scrollableTable: boolean;
  private searchable: boolean;
  private selectable: ETableSelectionMode;
  private settings: any;
  private tableColumnCount: number;
  private tableHeaders: TableHeader[];
  private valuesRestUrl: string;
  private createNewEntryRestUrls: any;
  private customOpenAction: Action;
  private defaultSorting: any;
  private editEntryTemplateRestUrlTemplates: any;
  private showNameDialog: boolean;
  private viewObjectName: string;
  private viewTableName: string;
  private selectedValues: any = {};
  private selectedCells: any = {};
  private selectedValue: any = {};
  private headerIconSet: SaxmsColumnHeaderIconSet;
  private additionalSubMenuGroups: any;
  private disableDefaultTableMenuButtons: EDefaultTableButton[];
  private allwaysEnableDefaultTableMenuButtons: string[];
  private userFilterable: boolean;
  private deleteAction: any;
  private value: TableValue;
  private hideHeader = false;
  private listensToTime = false;
  private fontSizeAutoScale = false;
  private selectionModel: SaxMsSelectionModel;
  private saveSettings: boolean;
  private columnReorder: boolean;
  private enableTenantSelection: boolean;
  private updateRowsRestUrl: string;
  private colorLegendMap: { [key: string]: string };
  private forceRefresh: boolean;
  private altNegativeRounding: boolean; // rounding down negative values at 0.5
  private enableAsyncUpdate: boolean;
  private enableSingleClickEdit: boolean;
  private syncHorizontalScrolling: string[]; // list of table ids to sync horizontal scrolling with
  /**
   * list of table ids to sync vertical scrolling with
   */
  private syncVerticalScrolling: string[];
  /**
   * This property is used to set an upper limit on the number of rows that can be displayed within a table, providing control over the table's vertical size.
   * By setting a maximum row height, you can ensure that the table remains within a desired visual range, preventing excessive vertical expansion.
   */
  private maxTableRows: number;

  /**
   * A boolean indicating whether the table should be refreshed after saving changes.
   */
  private refreshAfterSave?: boolean;

  public isEnableSingleClickEdit(): boolean {
    return typeof this.enableSingleClickEdit === 'boolean' ? this.enableSingleClickEdit : true;
  }

  public setEnableSingleClickEdit(enableSingleClickEdit: boolean): this {
    this.enableSingleClickEdit = enableSingleClickEdit;
    return this;
  }

  public isEnableAsyncUpdate(): boolean {
    return typeof this.enableAsyncUpdate === 'boolean' ? this.enableAsyncUpdate : false;
  }

  public setEnableAsyncUpdate(enableAsyncUpdate: boolean): this {
    this.enableAsyncUpdate = enableAsyncUpdate;
    return this;
  }

  public getColorLegendMap(): { [key: string]: string } {
    return this.colorLegendMap;
  }

  public setColorLegendMap(colorLegendMap: { [key: string]: string }): this {
    this.colorLegendMap = colorLegendMap;
    return this;
  }

  public isForceRefresh(): boolean {
    return this.forceRefresh;
  }

  public setForceRefresh(forceRefresh: boolean): this {
    this.forceRefresh = forceRefresh;
    return this;
  }

  public getUpdateRowsRestUrl(): string {
    return this.updateRowsRestUrl;
  }

  public setUpdateRowsRestUrl(updateRowsRestUrl: string): this {
    this.updateRowsRestUrl = updateRowsRestUrl;
    return this;
  }

  public isEnableTenantSelection(): boolean {
    return typeof this.enableTenantSelection === 'boolean' ? this.enableTenantSelection : false;
  }

  public setEnableTenantSelection(enableTenantSelection: boolean): this {
    this.enableTenantSelection = enableTenantSelection;
    return this;
  }

  public isColumnReorder(): boolean {
    return typeof this.columnReorder === 'boolean' ? this.columnReorder : true;
  }

  public setColumnReorder(columnReorder: boolean): this {
    this.columnReorder = columnReorder;
    return this;
  }

  public isSaveSettings(): boolean {
    return typeof this.saveSettings === 'boolean' ? this.saveSettings : true;
  }

  public setSaveSettings(saveSettings: boolean): this {
    this.saveSettings = saveSettings;
    return this;
  }

  public getSelectionModel(): SaxMsSelectionModel {
    return this.selectionModel;
  }

  public setSelectionModel(selectionModel: SaxMsSelectionModel): this {
    this.selectionModel = selectionModel;
    return this;
  }

  public getDefaultFilters(): any {
    return this.defaultFilters || [];
  }

  public setDefaultFilters(defaultFilters: any): this {
    this.defaultFilters = defaultFilters;
    return this;
  }
  public isHideHeader(): boolean {
    return this.hideHeader;
  }
  public setHideHeader(hideHeader: boolean): this {
    this.hideHeader = hideHeader;
    return this;
  }

  public isListensToTime(): boolean {
    return this.listensToTime;
  }
  public isFontSizeAutoScale(): boolean {
    return this.fontSizeAutoScale;
  }

  public setListensToTime(listensToTime: boolean): this {
    this.listensToTime = listensToTime;
    return this;
  }
  public setFontSizeAutoScale(fontSizeAutoScale: boolean): this {
    this.fontSizeAutoScale = fontSizeAutoScale;
    return this;
  }

  public getValues(): TableValue {
    return this.value;
  }

  public setValues(values: TableValue): this {
    this.value = values;
    Object.keys(this.getEventListener()[`${ETableEvents.VALUE_CHANGED}`] || {}).forEach((id: string) => {
      const ev: CustomEvent = new CustomEvent(ETableEvents.VALUE_CHANGED, { detail: values });
      this.getEventListener()[`${ETableEvents.VALUE_CHANGED}`][id](ev);
    });
    return this;
  }

  public getDeleteAction(): any {
    return this.deleteAction;
  }

  public isUserFilterable(): any {
    return this.userFilterable;
  }

  public setUserFilterable(userFilterable: boolean): this {
    this.userFilterable = userFilterable;
    return this;
  }
  public getAdditionalSubMenuGroups(): any {
    return this.additionalSubMenuGroups;
  }

  public setAdditionalSubMenuGroups(additionalSubMenuGroups: any): this {
    this.additionalSubMenuGroups = additionalSubMenuGroups;
    return this;
  }

  public setDisableDefaultTableMenuButtons(btns: EDefaultTableButton[]): this {
    this.disableDefaultTableMenuButtons = btns;
    return this;
  }

  public getDisableDefaultTableMenuButtons(): EDefaultTableButton[] {
    return this.disableDefaultTableMenuButtons || [];
  }

  public insideDisableDefaultTableMenuButtons(key: EDefaultTableButton): boolean {
    return (
      this.getDisableDefaultTableMenuButtons()
        .map((key: EDefaultTableButton) => key.toLowerCase())
        .indexOf(key.toLowerCase()) !== -1
    );
  }

  public setAllwaysEnableDefaultTableMenuButtons(btns: string[]): this {
    this.allwaysEnableDefaultTableMenuButtons = btns;
    return this;
  }

  public getAllwaysEnableDefaultTableMenuButtons(): string[] {
    return this.allwaysEnableDefaultTableMenuButtons || [];
  }

  public insideAllwaysEnableDefaultTableMenuButtons(key: string): boolean {
    return (
      this.getAllwaysEnableDefaultTableMenuButtons()
        .map((key: string) => key.toLowerCase())
        .indexOf(key.toLowerCase()) !== -1
    );
  }

  public setSelectedValues(selectedValues: any): this {
    this.selectedValues = selectedValues;
    return this;
  }
  public setSelectedCells(selectedCells: any): this {
    this.selectedCells = selectedCells;
    return this;
  }

  public addSelectedCell(key: string, value: any): this {
    this.selectedCells[key] = value;
    return this;
  }
  public addSelectedValue(key: string, value: string): this {
    this.selectedValues[key] = value;
    return this;
  }
  public setSelectedValue(selectedValue: any): this {
    this.selectedValue = selectedValue;
    return this;
  }

  public getSelectedValues(): any {
    return this.selectedValues;
  }
  public getSelectedCells(): this {
    return this.selectedCells;
  }

  public getSelectedValue(): any {
    return this.selectedValue;
  }
  public setHeaderIconSet(headerIconSet: SaxmsColumnHeaderIconSet): this {
    this.headerIconSet = headerIconSet;
    return this;
  }
  public getHeaderIconSet(): SaxmsColumnHeaderIconSet {
    return this.headerIconSet;
  }
  public isAddNewRows(): boolean {
    return this.addNewRows;
  }

  public setAddNewRows(addNewRows: boolean): this {
    this.addNewRows = addNewRows;
    return this;
  }

  public getAddTableRowType(): ETableRowType {
    return this.addTableRowType;
  }

  public setAddTableRowType(addTableRowType: ETableRowType): this {
    this.addTableRowType = addTableRowType;
    return this;
  }

  public getAdditionalSubMenus(): any {
    return this.additionalSubMenus || [];
  }

  public setAdditionalSubMenus(additionalSubMenus: any): this {
    this.additionalSubMenus = additionalSubMenus;
    return this;
  }

  public isApplyUserTimeFilter(): boolean {
    return this.applyUserTimeFilter;
  }

  public setApplyUserTimeFilter(applyUserTimeFilter: boolean): this {
    this.applyUserTimeFilter = applyUserTimeFilter;
    return this;
  }

  public getAutoScrollableIndex(): number {
    return this.autoScrollableIndex;
  }

  public setAutoScrollableIndex(autoScrollableIndex: number): this {
    this.autoScrollableIndex = autoScrollableIndex;
    return this;
  }

  public isBatchTable(): boolean {
    return this.batchTable;
  }

  public setBatchTable(batchTable: boolean): this {
    this.batchTable = batchTable;
    return this;
  }

  public isCellEdit(): boolean {
    return this.cellEdit;
  }

  public setCellEdit(cellEdit: boolean): this {
    this.cellEdit = cellEdit;
    return this;
  }

  public isCreateInlineRow(): boolean {
    return this.createInlineRow;
  }

  public setCreateInlineRow(createInlineRow: boolean): this {
    this.createInlineRow = createInlineRow;
    return this;
  }

  public isCreateViewTable(): boolean {
    return this.createViewTable;
  }

  public setCreateViewTable(createViewTable: boolean): this {
    this.createViewTable = createViewTable;
    return this;
  }

  public getDefaultColumns(): [] {
    return this.defaultColumns;
  }

  public setDefaultColumns(defaultColumns: []): this {
    this.defaultColumns = defaultColumns;
    return this;
  }

  public getDefaultNumberColumns(): number {
    return this.defaultNumberColumns;
  }

  public setDefaultNumberColumns(defaultNumberColumns: number): this {
    this.defaultNumberColumns = defaultNumberColumns;
    return this;
  }

  public getDefaultPageSize(): number {
    return this.defaultPageSize;
  }

  public setDefaultPageSize(defaultPageSize: number): this {
    this.defaultPageSize = defaultPageSize;
    return this;
  }

  insideDisabledSubMenues(key: EDefaultSubmenues): boolean {
    return this.getDisabledSubMenues().find((s: EDefaultSubmenues) => s === key) ? true : false;
  }

  public getDisabledSubMenues(): EDefaultSubmenues[] {
    return this.disabledSubMenues || [];
  }

  public setDisabledSubMenues(disabledSubMenues: EDefaultSubmenues[]): this {
    this.disabledSubMenues = disabledSubMenues;
    return this;
  }

  public isDynamicTableHeaders(): boolean {
    return this.dynamicTableHeaders;
  }

  public setDynamicTableHeaders(dynamicTableHeaders: boolean): this {
    this.dynamicTableHeaders = dynamicTableHeaders;
    return this;
  }

  public getHiddenHeaders(): any {
    return this.hiddenHeaders;
  }

  public setHiddenHeaders(hiddenHeaders: any): this {
    this.hiddenHeaders = hiddenHeaders;
    return this;
  }

  public isHierarchical(): boolean {
    return this.hierarchical;
  }

  public setHierarchical(hierarchical: boolean): this {
    this.hierarchical = hierarchical;
    return this;
  }

  public isNameRequired(): boolean {
    return this.nameRequired;
  }

  public setNameRequired(nameRequired: boolean): this {
    this.nameRequired = nameRequired;
    return this;
  }

  public isNameUnique(): boolean {
    return this.nameUnique;
  }

  public setNameUnique(nameUnique: boolean): this {
    this.nameUnique = nameUnique;
    return this;
  }

  public getNumberFixedColumns(): number {
    return this.numberFixedColumns;
  }

  public setNumberFixedColumns(numberFixedColumns: number): this {
    this.numberFixedColumns = numberFixedColumns;
    return this;
  }

  public getNumberFixedRowsBottom(): number {
    return this.numberFixedRowsBottom || 0;
  }

  public setNumberFixedRowsBottom(numberFixedRowsBottom: number): this {
    this.numberFixedRowsBottom = numberFixedRowsBottom;
    return this;
  }

  public getNumberFixedRowsTop(): number {
    return this.numberFixedRowsTop || 0;
  }

  public setNumberFixedRowsTop(numberFixedRowsTop: number): this {
    this.numberFixedRowsTop = numberFixedRowsTop;
    return this;
  }

  public isScrollableTable(): boolean {
    return this.scrollableTable;
  }

  public setScrollableTable(scrollableTable: boolean): this {
    this.scrollableTable = scrollableTable;
    return this;
  }

  public isSearchable(): boolean {
    return this.searchable;
  }

  public setSearchable(searchable: boolean): this {
    this.searchable = searchable;
    return this;
  }

  public getSelectable(): ETableSelectionMode {
    return this.selectable;
  }

  public setSelectable(selectable: ETableSelectionMode): this {
    this.selectable = selectable;
    return this;
  }

  public getSettings(): any {
    return this.settings;
  }

  public setSettings(settings: any): this {
    this.settings = settings;
    return this;
  }

  public getTableColumnCount(): number {
    return this.tableColumnCount;
  }

  public setTableColumnCount(tableColumnCount: number): this {
    this.tableColumnCount = tableColumnCount;
    return this;
  }

  public getTableHeaders(): TableHeader[] {
    return this.tableHeaders || [];
  }

  /**
   * set table headers
   * also set tableColumnCount to the number of elements
   * also provides table headers width a columnIndex if none was given
   * @param tableHeaders TableHeader[]
   * @returns this
   */
  public setTableHeaders(tableHeaders: TableHeader[]): this {
    tableHeaders = tableHeaders.map((h: TableHeader, index: number) =>
      h.setColumnIndex(isNaN(h.getColumnIndex()) ? index : h.getColumnIndex())
    );
    this.setTableColumnCount(tableHeaders.length);
    this.tableHeaders = tableHeaders;
    return this;
  }

  public getValuesRestUrl(): string {
    return this.valuesRestUrl || '';
  }

  public setValuesRestUrl(valuesRestUrl: string): this {
    this.valuesRestUrl = valuesRestUrl;
    return this;
  }

  public getCreateNewEntryRestUrls(): any {
    return this.createNewEntryRestUrls;
  }

  public setCreateNewEntryRestUrls(createNewEntryRestUrls: any): this {
    this.createNewEntryRestUrls = createNewEntryRestUrls;
    return this;
  }

  public getCustomOpenAction(): Action {
    return this.customOpenAction;
  }

  public setCustomOpenAction(customOpenAction: Action): this {
    this.customOpenAction = customOpenAction;
    return this;
  }

  public getDefaultSorting(): any[] {
    return this.defaultSorting;
  }

  public setDefaultSorting(defaultSorting: any): this {
    this.defaultSorting = defaultSorting;
    return this;
  }

  public getEditEntryTemplateRestUrlTemplates(): any {
    return this.editEntryTemplateRestUrlTemplates;
  }

  public setEditEntryTemplateRestUrlTemplates(editEntryTemplateRestUrlTemplates: any): this {
    this.editEntryTemplateRestUrlTemplates = editEntryTemplateRestUrlTemplates;
    return this;
  }

  public isShowNameDialog(): boolean {
    return this.showNameDialog;
  }

  public setShowNameDialog(showNameDialog: boolean): this {
    this.showNameDialog = showNameDialog;
    return this;
  }

  public getViewObjectName(): string {
    return this.viewObjectName;
  }

  public setViewObjectName(viewObjectName: string): this {
    this.viewObjectName = viewObjectName;
    return this;
  }

  public getViewTableName(): string {
    return this.viewTableName;
  }

  public setViewTableName(viewTableName: string): this {
    this.viewTableName = viewTableName;
    return this;
  }

  public isAltNegativeRounding(): boolean {
    return this.altNegativeRounding;
  }

  public setAltNegativeRounding(altNegativeRounding: boolean): this {
    this.altNegativeRounding = !!altNegativeRounding;
    return this;
  }

  public getSyncHorizontalScrolling(): string[] {
    return this.syncHorizontalScrolling || [];
  }

  public setSyncHorizontalScrolling(syncHorizontalScrolling: string[]): this {
    this.syncHorizontalScrolling = syncHorizontalScrolling;
    return this;
  }

  public getSyncVerticalScrolling(): string[] {
    return this.syncVerticalScrolling || [];
  }

  public setSyncVerticalScrolling(syncVerticalScrolling: string[]): this {
    this.syncVerticalScrolling = syncVerticalScrolling;
    return this;
  }

  public getMaxTableRows(): number {
    return this.maxTableRows;
  }

  public setMaxTableRows(maxTableRows: number): this {
    this.maxTableRows = maxTableRows;
    return this;
  }

  public isRefreshAfterSave(): boolean {
    return this.refreshAfterSave ?? true;
  }

  public setRefreshAfterSave(refreshAfterSave: boolean): this {
    this.refreshAfterSave = refreshAfterSave;
    return this;
  }

  constructor() {
    super();
    this.setType(ETemplateType.TABLE);
  }
}
