import { HttpClient, HttpXhrBackend } from '@angular/common/http';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { MSAL_GUARD_CONFIG, MSAL_INSTANCE } from '@azure/msal-angular';
import { IConfig } from '@core/config/config.interface';
import { MSALGuardConfigFactory, MSALInstanceFactory } from '@core/core.module';
import { switchMap } from 'rxjs';
import { AppModule } from './dashboard/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

const httpClient = new HttpClient(new HttpXhrBackend({ build: () => new XMLHttpRequest() }));
const configCall$ = httpClient.get<IConfig>('./assets/config.json');

configCall$.pipe(switchMap((config) => MSALInstanceFactory(config))).subscribe((msalInstance) => {
  platformBrowserDynamic([
    {
      provide: MSAL_INSTANCE,
      useFactory: () => msalInstance,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
  ]).bootstrapModule(AppModule);
});
