import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Subject, merge } from 'rxjs';
import { sample } from 'rxjs/operators';
import { EntryElement } from '../entry-collection/entry-element';
import { EDurationPickerUnit } from './duration-picker-unit.enum';
import { IDurationPickerZone } from './duration-picker-zone.interface';

@Component({
  selector: 'template-duration-picker',
  templateUrl: './template-duration-picker.component.html',
  styleUrls: ['./template-duration-picker.component.scss'],
})
export class TemplateDurationPickerComponent implements OnChanges, OnDestroy, AfterViewInit {
  private readonly _millisChange$ = new Subject<number>();
  private readonly _blur$ = new Subject<void>();
  private readonly _ngDestroy$ = new Subject<void>();

  @Output() get millisChange(): EventEmitter<number> {
    const buffer = new EventEmitter<number>();
    this._millisChange$.pipe(sample(merge(this._blur$, this._ngDestroy$))).subscribe((val) => {
      buffer.emit(val);
    });
    return buffer;
  }

  @Input() disabled = false;
  @Input() millis: number;
  @Input() title: string;
  @Input() id: string;
  @Input() displayed: EDurationPickerUnit[] = [
    EDurationPickerUnit.MILLIS,
    EDurationPickerUnit.SECONDS,
    EDurationPickerUnit.MINUTES,
    EDurationPickerUnit.HOURS,
    EDurationPickerUnit.DAYS,
    EDurationPickerUnit.WEEKS,
  ];
  @Input() simpleInput = false;
  @Input() entryElement: EntryElement;
  @Input() protected autofocus: boolean;

  @ViewChild('ref1')
  protected elementRef1: ElementRef;

  @ViewChild('ref2')
  protected elementRef2: ElementRef;

  public simpleInputWidth = '100%';

  zones: IDurationPickerZone[] = [
    { key: EDurationPickerUnit.WEEKS, value: undefined, enabled: false, order: 0 },
    { key: EDurationPickerUnit.DAYS, value: undefined, enabled: false, order: 1 },
    { key: EDurationPickerUnit.HOURS, value: undefined, enabled: false, order: 2 },
    { key: EDurationPickerUnit.MINUTES, value: undefined, enabled: false, order: 3 },
    { key: EDurationPickerUnit.SECONDS, value: undefined, enabled: false, order: 4 },
    { key: EDurationPickerUnit.MILLIS, value: undefined, enabled: false, order: 5 },
  ];

  ngAfterViewInit(): void {
    if (this.autofocus) {
      this.elementRef1?.nativeElement.focus();
      this.elementRef2?.nativeElement.focus();
    }
  }

  ngOnDestroy(): void {
    this._ngDestroy$.next();
    this._millisChange$.complete();
    this._blur$.complete();
    this._ngDestroy$.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.millis) {
      this.transform(this.millis);
    }

    if (changes.displayed) {
      this.toggleZones(this.displayed);
    }
  }

  private toggleZones(list: EDurationPickerUnit[]) {
    if (!list) {
      list = [
        EDurationPickerUnit.MILLIS,
        EDurationPickerUnit.SECONDS,
        EDurationPickerUnit.MINUTES,
        EDurationPickerUnit.HOURS,
        EDurationPickerUnit.DAYS,
        EDurationPickerUnit.WEEKS,
      ];
    }

    list.forEach((elem: EDurationPickerUnit) => {
      this.getZone(elem).enabled = true;
    });

    this.transform(this.millis);
    this.simpleInputWidth = `calc(${100 / list.length}% - 20px)`; // 20px is the label width
  }

  /**
   * returns duration element
   * @param key string
   * @returns any
   */
  private getZone(key: EDurationPickerUnit): IDurationPickerZone {
    return this.zones.find((zone) => zone.key === key);
  }

  protected transformBack(): number {
    const hasValues = this.displayed.find((key) => this.getZone(key).value === 0 || this.getZone(key).value >= 1);

    if (!hasValues) {
      this._millisChange$.next(undefined);
      return undefined;
    }

    let millis = 0;

    millis += this.getZone(EDurationPickerUnit.WEEKS).value * 604800000 || 0;
    millis += this.getZone(EDurationPickerUnit.DAYS).value * 86400000 || 0;
    millis += this.getZone(EDurationPickerUnit.HOURS).value * 3600000 || 0;
    millis += this.getZone(EDurationPickerUnit.MINUTES).value * 60000 || 0;
    millis += this.getZone(EDurationPickerUnit.SECONDS).value * 1000 || 0;
    millis += this.getZone(EDurationPickerUnit.MILLIS).value * 1 || 0;

    this._millisChange$.next(millis);
    return millis;
  }

  private transform(millis: number) {
    if (millis === undefined || millis === null) {
      return;
    }

    let rest = millis;
    if (this.getZone(EDurationPickerUnit.WEEKS).enabled) {
      this.getZone(EDurationPickerUnit.WEEKS).value = Math.floor(rest / 604800000);
      rest = rest % 604800000;
    }

    if (this.getZone(EDurationPickerUnit.DAYS).enabled) {
      this.getZone(EDurationPickerUnit.DAYS).value = Math.floor(rest / 86400000);
      rest = rest % 86400000;
    }

    if (this.getZone(EDurationPickerUnit.HOURS).enabled) {
      this.getZone(EDurationPickerUnit.HOURS).value = Math.floor(rest / 3600000);
      rest = rest % 3600000;
    }

    if (this.getZone(EDurationPickerUnit.MINUTES).enabled) {
      this.getZone(EDurationPickerUnit.MINUTES).value = Math.floor(rest / 60000);
      rest = rest % 60000;
    }

    if (this.getZone(EDurationPickerUnit.SECONDS).enabled) {
      this.getZone(EDurationPickerUnit.SECONDS).value = Math.floor(rest / 1000);
      rest = rest % 1000;
    }

    this.getZone(EDurationPickerUnit.MILLIS).value = rest;
  }

  protected onBlur() {
    this._blur$.next();
  }
}
