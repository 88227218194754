<div
  class="statebar-icon-wrapper"
  [id]="element.getElementId()"
  [style.color]="element.isDisabled() ? 'grey' : element.getColor()"
  [matTooltip]="element.getTooltip() || element.getName()"
>
  <app-image
    class="statebar-icon"
    [class.disabled]="element.isDisabled()"
    [matBadge]="element.getNotificationCounter() > 9 ? '+9' : element.getNotificationCounter() || null"
    [matBadgeSize]="'small'"
    [matBadgeOverlap]="true"
    [label]="element.getIcon()"
  ></app-image>
  <div class="statebar-icon-label">
    {{ element.getName() }}
  </div>
</div>
