import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { TimespanFooterComponent } from './timespan-footer.component';
import { TemplateDatepickerModule } from '@app-modeleditor/components/template-datepicker/template-datepicker.module';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatIconModule,
    TemplateDatepickerModule,
  ],
  exports: [TimespanFooterComponent],
  declarations: [TimespanFooterComponent],
})
export class TimespanFooterModule {}
