<div class="node-network-edit-edge-wrapper">
  <button mat-icon-button>
    <mat-icon (click)="onclickEditCurve($event)" [style.color]="edit ? 'blue' : null">linear_scale</mat-icon>
  </button>
  <button mat-icon-button>
    <mat-icon (click)="onclickAddCurve($event)" [style.color]="add ? 'blue' : null">trending_flat</mat-icon>
  </button>
  <button mat-icon-button>
    <mat-icon (click)="onclickSelectCurve($event)" [style.color]="select ? 'blue' : null">launch</mat-icon>
  </button>
</div>
