import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable, InjectionToken, NgZone } from '@angular/core';
import { NotificationComponent } from '../ui/notification-overlay/notification-overlay.component';

export const NOTIFICATION_DATA = new InjectionToken<any>('NOTIFICATION_DATA');

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private overlayRef: OverlayRef;
  private _zIndexDefault = null;

  constructor(private zone: NgZone, private overlay: Overlay) {}

  open(): void {
    if (this.overlayRef) {
      return;
    }
    // position
    const positionStrategy = this.overlay.position().global().top('5px').centerHorizontally();

    this.zone.run(() => {
      // Returns an OverlayRef (which is a PortalHost)
      const overlayRef = this.overlay.create({
        hasBackdrop: false,
        backdropClass: '',
        panelClass: '',
        width: '100%',
        maxWidth: '420px',
        scrollStrategy: this.overlay.scrollStrategies.noop(),
        positionStrategy,
      });
      // Create ComponentPortal that can be attached to a PortalHost
      const portal = new ComponentPortal(NotificationComponent, null);
      // Attach ComponentPortal to PortalHost
      overlayRef.attach(portal);
      this.overlayRef = overlayRef;
      // Raise z index of overlay to display notifications always in front
      this._getDefaultZIndex();
      this._applyCustomZIndexOnOverlay(50);
    });
  }

  /**
   * Gets the current z index of the overlay and applies it as default.
   */
  private _getDefaultZIndex(): void {
    if (!this.overlayRef || !this.overlayRef.hostElement) {
      this._zIndexDefault = null;
      return;
    }
    const defaultZIndex = parseInt(window.getComputedStyle(this.overlayRef.hostElement).zIndex);
    this._zIndexDefault = isNaN(defaultZIndex) ? null : defaultZIndex;
  }

  /**
   * Applies a custom z index on the notification overlay wrapper (used to bring notification overlay into foreground).
   * @param zIndexDiff Difference from default z index.
   */
  private _applyCustomZIndexOnOverlay(zIndexDiff: number): void {
    if (!this.overlayRef || !this.overlayRef.hostElement) return;
    this.overlayRef.hostElement.style.zIndex = `${(this._zIndexDefault || 0) + zIndexDiff}`;
  }
}
