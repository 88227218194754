import { Injectable, Type } from '@angular/core';
import { TemplateAdapter } from '@app-modeleditor/utils/template-factory.service';
import { TemplateResourceAdapter } from '@app-modeleditor/utils/template-resource-factory.service';
import { ConfigService } from '@core/config/config.service';
import { GlobalUtils } from 'frontend/src/dashboard/global-utils';
import { Adapter } from '../../../core/adapter';
import { Button } from '../button/button';
import { ButtonAdapter } from '../button/button-adapter.service';
import { Lightbox } from './lightbox';

@Injectable({
  providedIn: 'root',
})
export class LightboxAdapter implements Adapter<Lightbox> {
  constructor(
    private _templateResourceAdapter: TemplateResourceAdapter,
    private _buttonAdapter: ButtonAdapter,
    private configService: ConfigService
  ) {}
  adapt(item: any): Lightbox {
    return this._applyProperties(this._templateResourceAdapter.inherit<Lightbox>(Lightbox, item), item);
  }
  inherit?<T extends Lightbox>(type: Type<T>, item: any): T {
    return this._applyProperties(this._templateResourceAdapter.inherit<T>(type, item), item);
  }
  inheritFrom?<T extends Lightbox>(scope: TemplateAdapter, type: Type<T>, item: any): T {
    return this._applyProperties(this._templateResourceAdapter.inheritFrom<T>(scope, type, item), item, scope);
  }
  applyValues<T>(scope: TemplateAdapter, item: any, values: any): T {
    throw new Error('Method not implemented.');
  }

  private _applyProperties<T extends Lightbox>(item: T, data: any, scope?: TemplateAdapter): T {
    if (!scope) {
      return item;
    }
    item
      .setButtons((data.buttons || []).map((b) => this._buttonAdapter.inheritFrom<Button>(scope, Button, b)))
      .setOnCustomCancelAction(this._buttonAdapter.inherit<Button>(Button, data.onCustomCancelAction))
      .setOnCustomSaveAction(this._buttonAdapter.inherit<Button>(Button, data.onCustomSaveAction))
      .setAdditionalButtons((data.additionalButtons || []).map((b) => this._buttonAdapter.inherit<Button>(Button, b)))
      .setDisableCancelButton(data.disableCancelButton)
      .setCanOpenExternalWindow(data.canOpenExternalWindow && this.configService.access().allowExternalWindows)
      .setDisableSaveButton(data.disableSaveButton)
      .setWidth(data.width)
      .setAbsoluteWidth(data.absoluteWidth)
      .setResizeable(typeof data.resizeable === 'boolean' ? data.resizeable : true)
      .setDraggable(data.draggable)
      .setNameRequired(data.nameRequired)
      .setHeight(data.height)
      .setNameUnique(data.nameUnique)
      .setHeadline(data.headline)
      .setResourceId(data.resourceId || GlobalUtils.generateUUID())
      .setDisableClose(data.disableClose)
      .setCustomSaveButton(this._buttonAdapter.inheritFrom(scope, Button, data.customSaveButton))
      .setCustomCancelButton(this._buttonAdapter.inheritFrom(scope, Button, data.customCancelButton))
      .setContent(scope.adapt(data.content));
    return item;
  }
}
