import { Injectable } from '@angular/core';
import { Action } from '@app-modeleditor/components/button/action/action';
import { EActionType } from '@app-modeleditor/components/button/action/action-type.enum';
import { IActionParams } from '@app-modeleditor/components/button/action/parameter.interface';
import { EPredefinedAction } from '@app-modeleditor/components/button/action/predefined-action.enum';
import { ButtonService } from '@app-modeleditor/components/button/button.service';
import { TemplateTreeService } from '@app-modeleditor/components/tree/tree.service';
import { EMessageType, Message } from '@core/message/message';
import { MessageService } from '@core/message/message.service';
import { Notification } from '@core/notification/notification';
import { SharedUiService } from 'frontend/src/dashboard/model-editor/ui/service/shared.ui.service';
import { take } from 'rxjs/operators';
import { UserService } from '../user/data-access/user.service';
import { CloudMessagingService } from './notification/cloud-messaging.service';
import { ENotificationCode } from './notification/notification-code.enum';
import { ENotificationType } from './notification/notification-type.enum';
@Injectable({
  providedIn: 'root',
})
export class CustomMessagingActionService {
  constructor(
    private fcm: CloudMessagingService,
    private sharedUiService: SharedUiService,
    private templateTreeService: TemplateTreeService,
    private userService: UserService,
    private messageApi: MessageService,
    private btnService: ButtonService
  ) {
    this.fcm.getMessage().subscribe((event: Notification) => {
      if (!event) {
        return;
      }

      switch (event.getMessageCode()) {
        case ENotificationCode.CLONE_MODEL:
          this.messageApi.show(
            new Message()
              .setTitle('NOTIFICATION.MODEL.CLONE.SUCCESS.title')
              .setText('NOTIFICATION.MODEL.CLONE.SUCCESS.text')
              .setType(EMessageType.SUCCESS)
          );
          break;
        case ENotificationCode.BDE_AUTO_STEERING_RECALCULATION_SUCCESSFUL:
          this.sharedUiService.changeWidgets();
          break;
      }

      switch (event.getType()) {
        case ENotificationType.FRONTEND_UPDATE_NOTIFICATION:
          this.templateTreeService.refresh(event);
          break;
        case ENotificationType.MENU_UPDATE_NOTIFICATION:
          this.templateTreeService.refresh(event);
          break;
        case ENotificationType.USER_SETTINGS_CHANGED:
          this.userService.refreshUsersettings(event.isDisableReload());
          break;
        case ENotificationType.TRIGGER_LOADING_INDICATOR:
          // build action
          const triggerLoadingAction = new Action();
          triggerLoadingAction.setActionType(EActionType.PREDEFINED);
          triggerLoadingAction.setId(EPredefinedAction.TRIGGER_LOADING_INDICATOR);
          triggerLoadingAction.setParams(event.getLoadingParams() as unknown as IActionParams);

          this.btnService.executeActions([triggerLoadingAction]).pipe(take(1)).subscribe();
          break;
        case ENotificationType.REFRESH_PAGE:
          window.location.reload();
          break;
      }
    });
  }
  public initialize() {}
}
