import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { CONTAINER_DATA } from '@app-modeleditor/components/lightbox/overlay/container-data';
import { DefaultOverlayContainer } from '@app-modeleditor/components/lightbox/overlay/default-overlay-container';
import { Subscription } from 'rxjs';
import { IResourceElement } from '../../components/resource-element/resource-element.interface';
import { ResourceCommunicationService } from '../../resource-communication.service';
import { ResourcesDataService } from '../../resources-data.service';

@Component({
  selector: 'app-drag-resource-element',
  templateUrl: './drag-resource-element.component.html',
  styleUrls: ['./drag-resource-element.component.scss'],
})
export class DragResourceElementComponent extends DefaultOverlayContainer<any> implements OnInit {
  @ViewChild('dragObject') dragObject: ElementRef;
  private _externalDropSubscription: Subscription = null;
  private _height = 0;
  private _width = 0;
  private _dropped = false;

  constructor(
    @Inject(CONTAINER_DATA) public resource: IResourceElement,
    public resourcesDataStorageService: ResourcesDataService,
    public resourceCommunicationService: ResourceCommunicationService
  ) {
    super();
  }

  ngOnInit() {}

  ngOnDestroy() {}

  ngAfterViewChecked() {
    if (this._dropped) {
      // performance improvement
      return;
    }
    const boundings: DOMRect = this.dragObject.nativeElement.getBoundingClientRect();
    this._height = boundings.height;
    this._width = boundings.width;
  }

  get width(): number {
    return this._width;
  }

  get height(): number {
    return this._height;
  }

  set dropped(bool: boolean) {
    this._dropped = bool;
  }
}
