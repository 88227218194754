import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Button } from '@app-modeleditor/components/button/button';
import { EButtonDisplayType } from '@app-modeleditor/components/button/button-display-type.enum';
import { EntryElement } from '@app-modeleditor/components/entry-collection/entry-element';
import { MenuItem } from 'frontend/src/dashboard/view/template-toolbar/menu-item';
import { ToolbarGroup } from './../../../../view/template-toolbar/toolbar-group';
import { TemplateAdapter } from './../../../utils/template-factory.service';
import { IToolbarItem, IToolbarModifier } from './toolbar-modifier.interface';

@Component({
  selector: 'app-toolbar-row',
  templateUrl: './toolbar-row.component.html',
  styleUrls: ['./toolbar-row.component.scss'],
})
export class ToolbarRowComponent implements OnInit, OnChanges {
  @Input() modifier: IToolbarModifier;
  @Input() resourceId: string;
  // groups: ToolbarGroup[];
  menuItems: MenuItem[];

  constructor(private templateAdapter: TemplateAdapter) {}

  get groups(): ToolbarGroup[] {
    return [].concat(...(this.menuItems || []).map((m: MenuItem) => m.getToolbarGroups()));
  }

  ngOnInit(): void {
    this.parse();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.modifier) {
      this.parse();
    }

    if (changes.resourceId) {
      this.setResourceId();
    }
  }

  private setResourceId(): void {
    (this.menuItems || []).forEach((g: MenuItem) => g.setResourceId(this.resourceId));

    this.groups.forEach((g: ToolbarGroup) => {
      g.getEntryElements().forEach((e: EntryElement) => {
        e.setResourceId(this.resourceId);
      });
    });
  }

  private parse() {
    if (!this.modifier?.additionalToolbarItems) {
      return;
    }
    this.menuItems = this.modifier.additionalToolbarItems.map((i: IToolbarItem) => {
      (i.groups || []).map((g) => (g.elements || []).map((e) => (e.disableContextMenu = true)));
      return this.templateAdapter.adapt(i);
    });

    // apply resource id which is important for editmode to work correctly
    this.setResourceId();

    // set display types to icon only to decrease height of new toolbar
    this.groups.forEach((g: ToolbarGroup) => {
      g.getEntryElements().forEach((e: EntryElement) => {
        if (e instanceof Button) {
          e.setDisplayType(EButtonDisplayType.ICON_WITH_LABEL_BELOW);
        }
      });
    });
  }
}
