import { ChartType } from 'chart.js';
import { OptionTree } from './chart.extensions/chartlib';
import { ColorScheme } from './chart.extensions/color.handler';

export interface ICompareChartInormations {
  id: string;
  created: number;
  name: string;
  restURL: string;
}
export interface ChartDataGroup {
  id: string;
  chartDataGroupId: string;
  localID: string;
  label: any;
  data: any[];
  attributes: ChartgroupAttribute[];
  customObject?: any;
  backgroundColor?: any;
  backgroundHexColor?: any;
  fill?: boolean | number | string;
  zIndex?: number;
  borderColor?: any;
  lineTension?: number;
  hidden: boolean;
  type?: string;
  originType?: string;
  index?: number;
  idx?: number;
  cubicInterpolationMode?: string;
  steppedLine?: boolean;
  stack?: string;
  stackId?: string;
  stackedLine?: boolean;
  calc?: boolean;
  stacklessVisible: boolean;
  stackedLineLegendHidden?: boolean;
  associatedResource: string;
  style?: string;
  lineStyle?: string;
  pieGroupId?: string;
  pieGroupName?: string;
  borderWidth?: number;
  pointHitRadius?: number;
  pointBackgroundColor?: string;
  pointBorderColor?: string;
  borderDash?: number[];
  integralData?: {
    startId: string;
    destinationId: string;
    connectToOrigin: boolean;
  };
}

interface ChartgroupAttribute {
  key: string;
  name: string;
  value: any;
}

interface AxisInformation {
  label: string;
  direction: string;
  id: string;
  interval: AxisInterval;
  type: string;
  valueAxis: boolean;
  valueTime: boolean;
  description: string;
  unit: string;
}

interface AxisInterval {
  max: any;
  min: any;
  stepWidth: any;
}

interface NumericPoint {
  x: any;
  y: any;
}
interface CategoryObject {
  id: any;
  label: any;
  values: any[];
}

interface LineChartGroup {
  dataPoints: Array<NumericPoint>;
  id: string;
}

export interface ChartLabels {
  categoryId: string;
  label: string;
  idx: number;
  categoryValue: number;
  additionalName: string;
  tooltipLabel: string;
}
interface AxesAndCategories {
  axisInformation: AxisInformation[];
  categories: string[];
}

export interface LabelInterval {
  label: string;
  index: number;
  key: any;
  startTime?: number;
  endTime?: number;
  segments: Array<Segment>;
}

export interface Segment {
  start: number;
  end: number;
  duration: number;
  key: string;
  associatedResource: string;
  values: Array<ChartgroupValue>;
}

export interface ChartgroupValue {
  chartGroupId: string;
  value: number;
}

export class Interval {
  public startTime: number;
  public endTime: number;
}

export interface BackgroundDraws {
  axisRef: string;
  startCoordinate: ChartLabels;
  endCoordinate: ChartLabels;
  backgroundColor: string;
}

/**
 * Chartdata for bar and line chart
 */
export class ChartData {
  public chartData: ChartDataGroup[];
  public originChartData: ChartDataGroup[] = [];
  public chartLabels: string[] = [];
  public maxPoints = 0;
  public chartType: ChartType = 'bar';
  public title: string;
  public backgroundDraws: BackgroundDraws[] = [];
  public axes: AxisInformation[];
  public optionTree: OptionTree = {
    chartOptions: [],
    chartMathOption: [],
    chartSorter: [],
    representation: [],
    chartLimits: [],
    chartUnits: [],
  };
  public chartColor: any[] = [];

  public maxValue: any;
  public minValue: any;
  public chartLegend = true;
  public inputCategories: any[] = [];
  public id: string;
  public isNumericChart = false;
  public categoriesLabelsObject: ChartLabels[] = [];
  public categoriesLabelsObjectInsideZoom: ChartLabels[] = [];
  public defaultInterval = '';

  public stacked = false;
  public lineStacked = false;
  public mixed = false;
  public colors: ColorScheme;
  public Origindata: any;
  public listenToPlanModification: boolean;
  public zoom: Interval = null;
  public timeInterval = 'total';
  public startTime: number;
  public currentTime: number;
  public addtionalStringCount: Map<string, number> = new Map();
  public addtionalString: Map<string, ChartLabels[]> = new Map();
  public initialScrollStart: Date;
  public initialScrollEnd: Date;

  constructor(data?: any, colors?: any[]) {
    if (!data) {
      return;
    }
    try {
      if (colors) {
        this.chartColor = colors.slice();
      }
      this.mapper(data);
    } catch (error) {
      console.error('mapping error');
      console.error(error);
    }
  }

  /**
   * mapping the background draws
   * example: special background color for weekends or holidays
   * @param data chartData object from the backend
   */
  private mappingBackgroundDraws(data: any) {
    this.backgroundDraws = [];
    if (data.backgroundColor) {
      for (const backgroudDraw of data.backgroundColor) {
        this.backgroundDraws.push({
          axisRef: backgroudDraw.axisRef.id,
          startCoordinate: backgroudDraw.startCoordinate,
          endCoordinate: backgroudDraw.endCoordinate,
          backgroundColor: backgroudDraw.backgroundColor,
        });
      }
    }

    this.currentTime = data.currentTime;
  }

  /**
   * @returns the current chart colors
   */
  getColors(): any[] {
    return this.chartColor;
  }

  /**
   * @returns the current color scheme
   */
  getColorArray() {
    return this.colors;
  }

  /**
   * @returns the instance of the chartData
   */
  public getInstance() {
    return this;
  }

  /**
   * handle the switch between the different chart-types
   * @param pageSize count of the items of current page
   */
  public toogleChartType(pageSize: number) {
    if (pageSize > 1) {
      switch (this.chartType) {
        case 'bar':
          this.chartType = 'line';
          break;
        case 'line':
          this.chartType = 'bar';
          break;
        case 'pie':
          this.chartType = 'doughnut';
          break;
        case 'doughnut':
          this.chartType = 'pie';
          break;
      }

      this.setType(this.chartType);
    }
  }

  /**
   * update the type of the chart
   * @param type new type of the chart
   */
  public setType(type: ChartType) {
    for (const data of this.chartData) {
      data.type = type;
    }
    this.chartType = type;
  }

  /**
   * replace all data in the chart by new mapping
   * @param data updated data
   * @param colors new colors of the chart-data
   */
  public replaceDataset(data: any, colors?: any[]) {
    try {
      this.chartColor = colors;
      this.chartLabels = [];
      this.resetAllData();
      this.mapper(data);
      this.executeZoom();
    } catch (error) {
      console.error('mapping error');
      console.error(error);
    }
  }

  /**
   * handle the mapping of the integral information of the chart
   * @param chartData backend-object of the chartdata
   */
  private mappingIntegralInformations(chartData: any) {
    if (!chartData.chartIntegrals) {
      return;
    }
    for (const integral of chartData.chartIntegrals) {
      this.fillBetweenDataSets(integral.toChartDataGroupID, integral.fromChartDataGroupID, false, integral.idx);
    }
  }

  /**
   * fill the space between two lines in the chart
   * @param startId id of the start line
   * @param destinationId id of the end line
   * @param connectToOrigin decides whether to fill the space between the start line and the x-axis or between the start line and the destination line
   * @param zIndex z-index of the integral
   */
  public fillBetweenDataSets(startId: string, destinationId: string, connectToOrigin: boolean, zIndex?: number): void {
    const dataset = this.chartData.find((data) => data.id === startId);
    const destination =
      destinationId && !connectToOrigin ? this.chartData.findIndex((data) => data.id === destinationId) : 'origin';
    dataset.fill = destination;
    // add integral data to update colors later
    dataset.integralData = {
      startId: startId,
      destinationId: destinationId,
      connectToOrigin: connectToOrigin,
    };
    dataset.zIndex = 5;
    this.sortDatasetByZIndex();
  }

  /**
   * reset a intergral for the passed chartdata-id
   * @param id chartdata-id
   */
  public resetIntegral(id: string) {
    const dataset = this.chartData.find((data) => data.id === id);
    dataset.fill = false;
    dataset.zIndex = 10;
    this.sortDatasetByZIndex();
  }

  /**
   * sort datsets of the chart by the z-index
   */
  private sortDatasetByZIndex() {
    this.chartData = this.chartData
      .sort((a, b) => {
        if (a.zIndex > b.zIndex) return -1;
        else if (a.zIndex < b.zIndex) return 1;
        else return 0;
      })
      .slice();

    this.originChartData = this.originChartData
      .sort((a, b) => {
        if (a.zIndex > b.zIndex) return -1;
        else if (a.zIndex < b.zIndex) return 1;
        else return 0;
      })
      .slice();
  }

  /**
   * sort datsets of the chart by the Idx
   */
  public sortDatasetByIdx() {
    this.chartData = this.chartData
      .sort((a, b) => {
        if (a.idx > b.idx) return 1;
        else if (a.idx < b.idx) return -1;
        else return 0;
      })
      .slice();

    this.originChartData = this.originChartData
      .sort((a, b) => {
        if (a.idx > b.idx) return 1;
        else if (a.idx < b.idx) return -1;
        else return 0;
      })
      .slice();
  }

  /**
   * reste all data of the chart
   */
  public resetAllData() {
    this.chartData = [];
    this.originChartData = [];
    this.categoriesLabelsObject = [];
    this.categoriesLabelsObjectInsideZoom = [];
    this.chartLabels = [];
    this.inputCategories = [];
    this.addtionalString.clear();
    this.addtionalStringCount.clear();
  }

  /**
   * update the dataset of the chart
   * @param chartData new chart data
   */
  public updateDataset(chartData: any) {
    if (chartData.chartDataGroups.length > 0) {
      if (this.isNumericChart) {
        this.buildLinePoints(chartData);
        this.originChartData = JSON.parse(JSON.stringify(this.chartData));
      } else {
        this.updateCategoricData(chartData);
      }
    }

    this.executeZoom();
  }

  /**
   * updates the categorical data of a diagram
   * @param chartData new categorical data
   */
  public updateCategoricData(chartData: any) {
    this.refreshLabels(chartData.chartDataGroups[0]);
    this.chartLabels = this.getLabels();
    for (const dataGroup of chartData.chartDataGroups) {
      this.maxPoints = dataGroup.dataPointsCount;
      let dataSet;
      if (!this.zoom) {
        dataSet = this.chartData.find((data) => data.localID === dataGroup.localID);
      } else {
        dataSet = this.originChartData.find((data) => data.localID === dataGroup.localID);
      }
      dataSet.data = [];
      for (const dataPoint of dataGroup.dataPoints) {
        if (this.getValue(dataPoint)) {
          dataSet.data.push(this.getValue(dataPoint));
        } else {
          dataSet.data.push(0);
        }
      }
      dataSet = this.setStyletoDataGroup(dataSet, dataGroup);
    }
    this.refreshStackedLineData();
    this.maxValue = this.getMaxValue(this.chartData);
    this.minValue = this.getMinValue(this.chartData);
  }

  /**
   * update the current labels of the chart
   * @param dataGroup one datagroup of the chart
   */
  public refreshLabels(dataGroup: any) {
    this.categoriesLabelsObject = [];
    this.categoriesLabelsObjectInsideZoom = [];
    this.addtionalString.clear();
    this.addtionalStringCount.clear();
    for (const dataPoint of dataGroup.dataPoints) {
      for (const coordinate of dataPoint.coordinates) {
        if (!coordinate) {
          console.error('dataPoint have invalid coordinate data', dataPoint);
        }
        if (coordinate && coordinate.category) {
          const chartLabelObject: ChartLabels = {
            categoryId: coordinate.category.id,
            additionalName: '',
            idx: coordinate.category.idx,
            label: coordinate.category.categoryName,
            categoryValue: coordinate.category.categoryValue,
            tooltipLabel: coordinate.category.tooltipLabel,
          };
          this.calcAdditionalNamesMaps(chartLabelObject, coordinate.category);

          this.categoriesLabelsObject.push(chartLabelObject);
        }
      }
    }
  }

  /**
   * mapping the additionalName of a chart label
   * @param label chart label
   * @param category chart category
   * @returns the additionalName of a chart label
   */
  private calcAdditionalNamesMaps(label: ChartLabels, category?: any) {
    let additonalStringValue = '';
    if (category) {
      category.additionalNames.forEach((name) => {
        if (additonalStringValue) {
          additonalStringValue += ` ${name}`;
        } else {
          additonalStringValue += `${name}`;
        }
      });
      label.additionalName = additonalStringValue;
    } else {
      additonalStringValue = label.additionalName;
    }

    if (this.addtionalStringCount.has(additonalStringValue)) {
      this.addtionalStringCount.set(additonalStringValue, this.addtionalStringCount.get(additonalStringValue) + 1);
      this.addtionalString.set(additonalStringValue, this.addtionalString.get(additonalStringValue).concat(label));
    } else {
      this.addtionalStringCount.set(additonalStringValue, 1);
      this.addtionalString.set(additonalStringValue, [].concat(label));
    }

    return additonalStringValue;
  }

  /**
   * mapping the different chart options for the selection in tht toolbar
   * example:
   *  interval -> hourly,daily,weekly,....
   * @param data backend-chart-data-object
   */
  private mappingChartOptions(data): void {
    if (data.chartOptions) {
      this.optionTree.chartOptions = data.chartOptions.slice(0, -1).split(';');
    }
    if (data.chartMathOptions) {
      this.optionTree.chartMathOption = data.chartMathOptions.slice(0, -1).split(';');
    }
    if (data.chartSorter) {
      this.optionTree.chartSorter = data.chartSorter.slice(0, -1).split(';');
    }
    if (data.areas) {
      this.optionTree.representation = data.areas.slice(0, -1).split(';');
      this.optionTree.representation.push(''); // Here
    }
    if (data.chartLimits) {
      const chartLimits = data.chartLimits.slice().split(';');
      this.optionTree.chartLimits = [];
      chartLimits.forEach((element) => {
        this.optionTree.chartLimits.push(parseInt(element));
      });
    }
    if (data.chartUnits) {
      this.optionTree.chartUnits = data.chartUnits.slice(0, -1).split(';');
    }
  }

  /**
   * mapping the full data of the chart
   * @param data backend-chart-data-object
   */
  public mapper(data: any): void {
    this.defaultInterval = data.defaultInterval;
    this.id = data.id;
    this.title = data.headline;
    this.startTime = data.timePointZero;
    this.listenToPlanModification = data.listenToPlanModification;
    this.addtionalString.clear();
    this.addtionalStringCount.clear();
    this.mappingChartOptions(data);

    // set initial zoom (relevant for widget grid layout)
    if (data.scrollStart && data.scrollEnd) {
      this.initialScrollStart = new Date(data.scrollStart);
      this.initialScrollEnd = new Date(data.scrollEnd);
    }

    const axesInformations = this.getAxesAndCategories(data.chartData.axis);
    this.axes = axesInformations.axisInformation;

    if (this.axes.length == 1) {
      this.axes[0].valueAxis = true;
    }

    this.inputCategories = axesInformations.categories;
    // this.colors = new ColorScheme(data.chartData.chartDataGroups.length);
    this.colors = new ColorScheme();

    if (this.inputCategories.length !== 0) {
      this.mapCategoricChartData(this.inputCategories, data.chartData);
      // this.buildStackedLine(this.chartData);

      this.chartLabels = this.getLabels();
    } else {
      // deprecated case -> chart does not support static axis yet
      // mapping linechartdata
      this.calcMinMaxTime(data.chartData);
      this.buildLinePoints(data.chartData);
      // this.initColorArray();
    }

    this.mappingIntegralInformations(data.chartData);
    this.mappingBackgroundDraws(data.chartData);
    this.checksEmpty();

    if (this.axes.length > 1) {
      this.paint(this.chartData);
    } else {
      this.paintForPie(this.chartData);
    }

    this.mixed = data.chartData.mixedChart;
    // this.sortChartData();
    this.sortDatasetByZIndex();
    this.originChartData = JSON.parse(JSON.stringify(this.chartData));
  }

  /**
   * create a default empty dataset in case the chart does not contain any data
   */
  public checksEmpty() {
    if (this.chartData.length == 0) {
      const chartDataGroup: ChartDataGroup = {
        id: 'nodata',
        chartDataGroupId: 'nodata',
        label: 'nodata',
        localID: 'nodata',
        attributes: [],
        // insert Datagroupname
        data: [0],
        fill: false,
        zIndex: 10,
        backgroundColor: '',
        borderColor: '',
        lineTension: 0,
        hidden: false,
        index: 0,
        stacklessVisible: true,
        associatedResource: '',
      };
      this.chartData.push(chartDataGroup);
    }

    if (this.chartLabels.length == 0) {
      this.chartLabels.push('nodata');
    }
  }

  /**
   * builds a stacked line for all lines that have the same stackedId
   * @param chartData data of the current chart
   */
  private buildStackedLine(chartData) {
    if (this.lineStacked) {
      let newChartData: ChartDataGroup;
      for (const data of chartData) {
        if (
          data.originType == 'line' &&
          data.stack &&
          !this.chartData.find((group) => group.stackedLine && group.stackId === data.stackId)
        ) {
          newChartData = {
            backgroundColor: this.colors.getRGBAColorFromString(data.stackId, 0.7),
            borderColor: this.colors.getRGBAColorFromString(data.stackId, 0.7),
            backgroundHexColor: this.colors.getHexColorFromString(data.stackId),
            label: data.stack,
            attributes: [],
            id: data.stackId,
            chartDataGroupId: data.idChartDataGroupParent,
            localID: 'stackedLine_' + data.localID,
            stackedLine: true,
            index: this.chartData.length,
            lineTension: data.lineTension,
            fill: data.fill,
            zIndex: 10,
            type: data.type,
            hidden: false,
            originType: data.originType,
            stackId: data.stackId,
            stacklessVisible: true,
            data: [],
            associatedResource: data.associatedResource,
          };
          data.data.forEach((element, index) => {
            newChartData.data.push(this.getStackedLineData(data.stackId, index));
          });
          // this.categoriesLabelsObject.push({id:data.stackId, label:data.stackName});
          this.chartData.push(newChartData);
        }
      }

      // this.chartData.push(newChartData);
    }
  }

  /**
   * update the data of all stacked lines of a chart
   */
  refreshStackedLineData() {
    let index = 0;
    for (const data of this.chartData) {
      if (data.originType == 'line' && data.stack) {
        index = data.data.length;
      }
      if (data.originType == 'line' && data.stackedLine && index > 0) {
        const newData = [];

        for (let i = 0; i < index; i++) {
          newData.push(this.getStackedLineData(data.stackId, i));
        }

        data.data = newData.slice();
      }
    }
  }

  /**
   * calculates a value point of the stacked line of a given stack id
   * @param stackId id of the stacked line
   * @param index index of the datapoints of the calculation
   * @returns stacked line value
   */
  getStackedLineData(stackId: string, index: number) {
    let value = 0;
    for (const data of this.chartData) {
      if (data.originType == 'line' && data.stack && data.calc && data.stackId === stackId) {
        value += data.data[index];
      }
    }
    return value;
  }

  /**
   * fill Axes Data from Raw Data into an Array with mapped Axes Data and Categorie Datas
   * @param data raw Input Data
   * @return axes mapped Axes Data [0] and Categorie Datas[1] as in Array
   */
  public getAxesAndCategories(data: any): AxesAndCategories {
    const axes: AxisInformation[] = [];
    let inputCategories: string[] = [];

    for (const inputAxisData of data) {
      const axis: AxisInformation = {
        label:
          inputAxisData.axisType.indexOf('continuous') !== -1
            ? inputAxisData.name + ' / ' + inputAxisData.unit
            : inputAxisData.name,
        id: inputAxisData.id,
        type: inputAxisData.axisType,
        interval: inputAxisData.axisType.indexOf('continuous') !== -1 ? inputAxisData.axisInterval : null,
        direction: inputAxisData.axisDirection,
        description: inputAxisData.description,
        valueAxis: inputAxisData.axisType.indexOf('continuous') !== -1,
        valueTime: inputAxisData.axisType.indexOf('continuousTime') !== -1,
        unit: inputAxisData.unit,
      };

      if (inputAxisData.axisInterval) {
        const interval: AxisInterval = {
          max: inputAxisData.axisInterval.max,
          min: inputAxisData.axisInterval.min,
          stepWidth: inputAxisData.axisInterval.stepWidth,
        };
        axis.interval = interval;
      }
      if (
        inputAxisData.categories &&
        inputAxisData.categories.length !== 0 &&
        !(inputAxisData.axisType.indexOf('continuousTime') !== -1)
      ) {
        inputCategories = inputAxisData.categories;
      }
      axes.push(axis);
    }
    return {
      axisInformation: axes,
      categories: inputCategories,
    };
  }

  /**
   * @returns all current labels of the chart
   */
  public getLabels() {
    const labels = [];
    for (const cat of this.categoriesLabelsObject) {
      labels.push(cat.label);
    }
    return labels;
  }

  /**
   * calculate the min and max value of continuousTime-chart
   * @param chartData chartdata-object of the backend
   * @deprecated since 03.05.2022
   */
  public calcMinMaxTime(chartData: any) {
    for (const axis of this.axes) {
      for (const dataGroups of chartData.chartDataGroups) {
        this.maxPoints = dataGroups.dataPointsCount;
        for (const dataGroup of dataGroups.dataPoints) {
          const coord = dataGroup.coordinates.find((data) => data.axis.id === axis.id);

          if (coord.value !== 0) {
            if (coord.value >= axis.interval?.max) {
              axis.interval.max = coord.value;
            } else if (coord.value <= axis.interval?.min) {
              axis.interval.min = coord.value;
            }
          }
        }
      }
      if (axis.valueTime) {
        if (axis.interval) {
          axis.interval.stepWidth = 86400000;
        }
      }
    }
  }

  /**
   * mapping the line-data of the chart
   * @param chartData chartdata-object of the backend
   */
  public buildLinePoints(chartData: any) {
    this.chartData = [];
    this.originChartData = [];
    this.isNumericChart = true;
    // let lineChartGroups: Array<ChartDataGroup> = [];
    let count = 1;
    for (const dataGroup of chartData.chartDataGroups) {
      // Obsolet because of Sorting
      const lineGroup: ChartDataGroup = {
        id: dataGroup.id,
        chartDataGroupId: dataGroup.idChartDataGroupParent,
        localID: dataGroup.localID,
        attributes: this.getAttributeOfChartdataGroup(dataGroup),
        data: [],
        fill: false,
        zIndex: 10,
        label: dataGroup.name,
        // insert Linechartname
        backgroundColor: '',
        borderColor: '',
        hidden: false,
        stacklessVisible: true,
        associatedResource: dataGroup.associatedResource,
      };
      for (const dataPoint of dataGroup.dataPoints) {
        lineGroup.data.push(this.getNumericPoint(dataPoint));
        count++;
      }
      this.chartData.push(this.setStyletoDataGroup(lineGroup, dataGroup));
    }

    // return lineChartGroups;
  }

  /**
   * mapping the styling information of a datagroup
   * @param chartDataGroup ChartDataGroup
   * @param dataGroup dataGroup-object of the backend
   * @returns the datagroup with edited styöling information
   */
  public setStyletoDataGroup(chartDataGroup: ChartDataGroup, dataGroup: any): ChartDataGroup {
    chartDataGroup.style = dataGroup.style;
    switch (dataGroup.style) {
      case 'PIE':
        chartDataGroup.type = 'pie';
        chartDataGroup.originType = 'pie';
        break;
      case 'NONE':
        break;
      case 'STACKABLE_BAR':
        chartDataGroup.type = 'bar';
        chartDataGroup.originType = 'bar';
        chartDataGroup.stack = dataGroup.stackName;
        chartDataGroup.stackId = dataGroup.stackID;
        this.stacked = true;
        break;
      case 'BAR':
        chartDataGroup.type = 'bar';
        chartDataGroup.originType = 'bar';
        break;
      case 'STACKABLE_NORMAL_INTERPOLATION':
        chartDataGroup.calc = true;
        this.stacked = true;
        this.lineStacked = true;

        chartDataGroup.stack = dataGroup.stackName;
        chartDataGroup.stackId = dataGroup.stackID;
        chartDataGroup.hidden = !dataGroup.stacklessVisible;
        chartDataGroup.stacklessVisible = dataGroup.stacklessVisible;
        chartDataGroup.stackedLineLegendHidden = dataGroup.stacklessVisible;
      // eslint-disable-next-line no-fallthrough
      case 'NORMAL_INTERPOLATION':
        chartDataGroup.type = 'line';
        chartDataGroup.originType = 'line';
        break;
      case 'STACKABLE_LINEAR_INTERPOLATION':
        chartDataGroup.calc = true;
        this.stacked = true;
        this.lineStacked = true;

        chartDataGroup.stack = dataGroup.stackName;
        chartDataGroup.stackId = dataGroup.stackID;
        chartDataGroup.hidden = !dataGroup.stacklessVisible;
        chartDataGroup.stacklessVisible = dataGroup.stacklessVisible;
        chartDataGroup.stackedLineLegendHidden = dataGroup.stacklessVisible;
      // eslint-disable-next-line no-fallthrough
      case 'LINEAR_INTERPOLATION':
        chartDataGroup.type = 'line';
        chartDataGroup.originType = 'line';
        chartDataGroup.lineTension = 0;
        break;
      case 'STACKABLE_MONOTONE_INTERPOLATION':
        chartDataGroup.calc = true;
        this.stacked = true;
        this.lineStacked = true;
        chartDataGroup.stack = dataGroup.stackName;
        chartDataGroup.stackId = dataGroup.stackID;
        chartDataGroup.hidden = !dataGroup.stacklessVisible;
        chartDataGroup.stacklessVisible = dataGroup.stacklessVisible;
        chartDataGroup.stackedLineLegendHidden = dataGroup.stacklessVisible;
      // eslint-disable-next-line no-fallthrough
      case 'MONOTONE_INTERPOLATION':
        chartDataGroup.type = 'line';
        chartDataGroup.originType = 'line';
        chartDataGroup.cubicInterpolationMode = 'monotone';
        break;
      case 'STEPPED':
        chartDataGroup.type = 'line';
        chartDataGroup.originType = 'line';
        chartDataGroup.steppedLine = true;
        break;
    }

    chartDataGroup.zIndex = chartDataGroup.originType === 'line' ? 15 : 10;
    chartDataGroup.lineStyle = dataGroup.lineStyle;

    if (chartDataGroup.originType === 'line') {
      switch (dataGroup.lineStyle) {
        case 'DOTTED':
          chartDataGroup.borderDash = [3, 6];
          break;
        case 'DASHED':
          chartDataGroup.borderDash = [10, 10];
          break;
        case 'SOLID':
        default:
          chartDataGroup.borderDash = [0, 0];
      }

      chartDataGroup.borderWidth = dataGroup.lineWidth ? dataGroup.lineWidth : 2;
      switch (dataGroup.pointType) {
        case 'CIRCLE':
          chartDataGroup.pointHitRadius = 4;
          chartDataGroup.pointBackgroundColor = chartDataGroup.backgroundColor;
          chartDataGroup.pointBorderColor = chartDataGroup.backgroundColor;
          break;
        case 'NONE':
          chartDataGroup.pointHitRadius = 5;
          chartDataGroup.pointBackgroundColor = 'transparent';
          chartDataGroup.pointBorderColor = 'transparent';
          break;
      }
    }

    return chartDataGroup;
  }

  /**
   * checks if there is already a label for the given category and if not creates one
   * @param category category information
   */
  public addToLables(category): void {
    if (!this.categoriesLabelsObject.find((data) => data.idx === category.idx)) {
      const categoryLabelObject: ChartLabels = {
        categoryId: category.id,
        additionalName: '',
        idx: category.idx,
        label: category.categoryName,
        categoryValue: category.categoryValue,
        tooltipLabel: category.tooltipLabel,
      };
      this.calcAdditionalNamesMaps(categoryLabelObject, category);
      this.categoriesLabelsObject.push(categoryLabelObject);
      this.categoriesLabelsObjectInsideZoom.push(categoryLabelObject);
    }
  }

  /**
   * mapping the categorical data from a chart
   * @param categoryData information baout teh categories of the chart
   * @param chartData chartdata-object of the backend
   */
  public mapCategoricChartData(categoryData: any, chartData: any): void {
    this.chartData = [];
    this.originChartData = [];
    if (chartData.chartDataGroups.length == 0) {
      return;
    }

    for (const category of categoryData) {
      for (const dataGroup of chartData.chartDataGroups) {
        this.maxPoints = dataGroup.dataPointsCount;
        const dataPoint = dataGroup.dataPoints.find((data) => data.idx === category.idx);
        if (dataPoint) {
          this.addDatapointToDatagroup(dataGroup, dataPoint, category, category.idx);
        }
      }
    }

    this.maxValue = this.getMaxValue(this.chartData);
    this.minValue = this.getMinValue(this.chartData);
    this.chartData.sort((a, b) => {
      if (a.index < b.index) return -1;
      else if (a.index > b.index) return 1;
      else return 0;
    });

    this.buildStackedLine(this.chartData);
  }

  /**
   * create the colors for the chart-datagroups
   * @param chartDataGroups datagroups of the chart
   */
  public paint(chartDataGroups: ChartDataGroup[]): void {
    this.chartColor = [];

    chartDataGroups.forEach((element, index) => {
      if (!element.backgroundColor && !element.borderColor && !element.backgroundHexColor) {
        if (!this.chartColor[index]) {
          this.chartColor.push(this.colors.getRGBAColorFromString(element.id, 0.7));
          element.backgroundColor = this.colors.getRGBAColorFromString(element.id, 0.7);
          element.borderColor = this.colors.getRGBAColorFromString(element.id, 0.7);
          element.backgroundHexColor = this.colors.getHexColorFromString(element.id);
        } else {
          element.backgroundColor = this.chartColor[index];
          element.borderColor = this.chartColor[index];
          element.backgroundHexColor = this.chartColor[index];
        }
      } else {
        this.chartColor.push(element.backgroundColor);
      }
    });
  }

  /**
   * create the color for the datagroups of a pie chart
   * @param chartDataGroups
   */
  public paintForPie(chartDataGroups: ChartDataGroup[]) {
    chartDataGroups[0].backgroundColor = [];
    chartDataGroups[0].borderColor = [];
    chartDataGroups[0].data.forEach((element) => {
      const colorFamily = this.colors.getNextColorFamilyAsStrings();
      chartDataGroups[0].backgroundColor.push(colorFamily[1]);
      chartDataGroups[0].borderColor.push(colorFamily[0]);
    });
  }

  /**
   * update the current colorscheme
   * @param colors new colorscheme
   */
  public setColor(colors: ColorScheme) {
    this.colors = colors;
    if (this.axes.length > 1) {
      this.paint(this.chartData);
    } else {
      this.paintForPie(this.chartData);
    }
  }

  /**
   * mapping the attributes of a given datagroup
   * important for the filtering by the attribute-legend
   * @param dataGroup backend-object of the dataGroup
   * @returns attributes of the datagroup
   */
  private getAttributeOfChartdataGroup(dataGroup: any): ChartgroupAttribute[] {
    const attributes: ChartgroupAttribute[] = [];
    if (dataGroup.attributes) {
      for (const attribute of dataGroup.attributes) {
        attributes.push({
          name: attribute.name ? attribute.name : attribute.strValue,
          key: attribute.key,
          value: attribute.value,
        });
      }
    }
    return attributes;
  }

  /**
   * adds a data point to a data group
   * if the data group does not exist yet, it will be created and the data point will be added
   * @param dataGroup backend-object of a datagroup
   * @param dataPoint backend-object of a dataPoint
   * @param category category information
   * @param index index of the datagroup
   */
  public addDatapointToDatagroup(dataGroup: any, dataPoint: any, category: any, index: any): void {
    this.addToLables(category);
    if (!this.chartData.find((data) => data.idx === dataGroup.idx)) {
      if (this.getValue(dataPoint) !== undefined) {
        const chartDataGroup: ChartDataGroup = {
          id: dataGroup.id,
          chartDataGroupId: dataGroup.idChartDataGroupParent,
          localID: dataGroup.localID,
          label: dataGroup.name,
          data: [],
          attributes: this.getAttributeOfChartdataGroup(dataGroup),
          fill: false,
          zIndex: 10,
          backgroundColor: dataGroup.color ? this.colors.hexToRgbA(dataGroup.color, 1) : null,
          borderColor: dataGroup.color ? this.colors.hexToRgbA(dataGroup.color, 1) : null,
          backgroundHexColor: dataGroup.color ? dataGroup.color : null,
          hidden: false,
          index: index,
          idx: dataGroup.idx,
          stacklessVisible: true,
          associatedResource: dataGroup.associatedResource,
          pieGroupId: this.getCategorie(dataPoint),
          pieGroupName: this.getCategorieName(dataPoint),
        };

        chartDataGroup.data.push(this.getValue(dataPoint));

        this.chartData.push(this.setStyletoDataGroup(chartDataGroup, dataGroup));
      }
    } else {
      if (this.getValue(dataPoint) !== undefined) {
        const chartDataGroup: any = this.chartData.find((data) => data.idx === dataGroup.idx);
        chartDataGroup.data.push(this.getValue(dataPoint));
      }
    }
  }

  /**
   * calculate the max value of the y-axis for chart
   * @param chartData all chart-data-groups
   * @returns max value of the y-axis
   */
  public getMaxValue(chartData): number {
    let maxValue = 0;
    const maxValuesStacked = [];
    const stackedGroupArray = [];
    let allDataGroupsHidden = true;
    for (const dataGroup of chartData) {
      if (!dataGroup.hidden) {
        allDataGroupsHidden = false;
        const maxValueGroup = 0;

        dataGroup.data.forEach((point, index) => {
          if (this.stacked) {
            let stackGroupFound = false;
            for (const stackGroupData of stackedGroupArray) {
              if (stackGroupData.id == dataGroup.stackId && stackGroupData.index == index) {
                stackGroupFound = true;
              }
            }
            if (!stackGroupFound) {
              const stackGroup = {
                value: 0,
                id: dataGroup.stackId,
                index: index,
              };
              stackedGroupArray.push(stackGroup);
            }
          }

          const value = point;
          if (value !== 0) {
            if (this.stacked) {
              if (dataGroup.style === 'STACKABLE_BAR' || dataGroup.style === 'STACKABLE_LINEAR_INTERPOLATION') {
                for (const stackGroupData of stackedGroupArray) {
                  if (stackGroupData.id == dataGroup.stackId && stackGroupData.index == index) {
                    stackGroupData.value += value;
                  }
                }
              } else {
                maxValuesStacked.push(value);
              }
            } else {
              if (value > maxValue) {
                maxValue = value;
              }
            }
          }
        });
      }
    }
    if (this.stacked) {
      for (const stackedGroupData of stackedGroupArray) {
        if (stackedGroupData.value > maxValue) {
          maxValue = stackedGroupData.value;
        }
      }

      for (const value of maxValuesStacked) {
        if (value > maxValue) {
          maxValue = value;
        }
      }
    }
    if (maxValue === 0) {
      maxValue = 10;
    }

    if (allDataGroupsHidden) {
      return this.maxValue;
    } else {
      return maxValue;
    }
  }
  /**
   * calculate the min value of the y-axis for chart
   * @param chartData all chart-data-groups
   * @returns min value of the y-axis
   */
  public getMinValue(chartData): number {
    let minValue = 0;
    const minValuesStacked = [];
    const stackedGroupArray = [];
    let allDataGroupsHidden = true;
    for (const dataGroup of chartData) {
      if (!dataGroup.hidden) {
        allDataGroupsHidden = false;
        const minValueGroup = 0;

        dataGroup.data.forEach((point, index) => {
          if (this.stacked) {
            let stackGroupFound = false;
            for (const stackGroupData of stackedGroupArray) {
              if (stackGroupData.id == dataGroup.stackId && stackGroupData.index == index) {
                stackGroupFound = true;
              }
            }
            if (!stackGroupFound) {
              const stackGroup = {
                value: 0,
                id: dataGroup.stackId,
                index: index,
              };
              stackedGroupArray.push(stackGroup);
            }
          }

          const value = point;
          if (value !== 0) {
            if (this.stacked) {
              if (dataGroup.style === 'STACKABLE_BAR' || dataGroup.style === 'STACKABLE_LINEAR_INTERPOLATION') {
                for (const stackGroupData of stackedGroupArray) {
                  if (stackGroupData.id == dataGroup.stackId && stackGroupData.index == index) {
                    stackGroupData.value += value;
                  }
                }
              } else {
                minValuesStacked.push(value);
              }
            } else {
              if (value < minValue) {
                minValue = value;
              }
            }
          }
        });
      }
      // dataGroup.data.forEach((point) => {
      //   if (point !== 0) {
      //     if (point < minValue) {
      //       minValue = point;
      //     }
      //   }
      // });
    }

    if (this.stacked) {
      for (const stackedGroupData of stackedGroupArray) {
        if (stackedGroupData.value < minValue) {
          minValue = stackedGroupData.value;
        }
      }

      for (const value of minValuesStacked) {
        if (value < minValue) {
          minValue = value;
        }
      }
    }
    if (minValue >= 0) {
      minValue = 0;
    }

    if (allDataGroupsHidden) {
      return this.minValue;
    } else {
      return minValue;
    }
  }

  /**
   * returns the value of the datapoint
   * @param dataPoint chart-datapoint
   * @returns the value of the datapoint
   */
  public getValue(dataPoint: any): any {
    let value = 0;
    if (dataPoint.val) {
      this.axes.forEach((axis, index) => {
        if (axis.valueAxis) {
          if (isNaN(dataPoint.val[index])) {
            console.error('dataPoint have invalid coordinate data', dataPoint);
          } else {
            value = dataPoint.val[index];
          }
        }
      });
      return value;
    } else {
      for (const axis of this.axes) {
        if (axis.valueAxis) {
          for (const coordinate of dataPoint.coordinates) {
            if (!coordinate) {
              console.error('dataPoint have invalid coordinate data', dataPoint);
            }
            if (coordinate && coordinate.axis.id === axis.id) {
              return coordinate.value;
            }
          }
        }
      }
    }
  }

  /**
   * mapping the numeric point of a datapoint for a chart
   * @param dataPoint datapointof the chart
   * @returns numeric point of a datapoint
   */
  public getNumericPoint(dataPoint: any): NumericPoint {
    const numeric: NumericPoint = {
      x: 0,
      y: 0,
    };

    for (const axis of this.axes) {
      if (axis.valueTime) {
        const coordinate = dataPoint.coordinates.find((data) => data.axis.id === axis.id);
        numeric.x = new Date(coordinate.value);
      } else {
        const coordinate = dataPoint.coordinates.find((data) => data.axis.id === axis.id);
        numeric.y = coordinate.value;
      }
    }
    return numeric;
  }

  /**
   * find the category-id for a given datapoint
   * @param dataPoint datapoint
   * @returns category-id for a given datapoint
   */
  public getCategorie(dataPoint: any): string {
    let result = '';
    const category = this.inputCategories.find((cat) => cat.idx === dataPoint.idx);
    if (!category) {
      console.error('dataPoint have invalid coordinate data', dataPoint);
    } else {
      result = category.id;
    }
    return result;
  }

  /**
   * find the category-name for a given datapoint
   * @param dataPoint datapoint
   * @returns category-name for a given datapoint
   */
  public getCategorieName(dataPoint: any): string {
    let result = '';
    const category = this.inputCategories.find((cat) => cat.idx === dataPoint.idx);
    if (!category) {
      console.error('dataPoint have invalid coordinate data', dataPoint);
    } else {
      result = category.categoryName;
    }
    return result;
  }

  /**
   * mapping the datagroups for a pie chart
   * @returns the datagroups of a pie chart
   */
  public getPieChartDataGroups() {
    const pieDataGroups: ChartDataGroup[] = [];
    let groupFound = false;
    for (const dataGroup of this.chartData) {
      if (!dataGroup.hidden) {
        for (const pieGroup of pieDataGroups) {
          if (dataGroup.pieGroupId === pieGroup.pieGroupId) {
            pieGroup.backgroundColor.push(dataGroup.backgroundColor);
            pieGroup.borderColor.push(dataGroup.borderColor);
            pieGroup.backgroundHexColor.push(dataGroup.backgroundHexColor);
            pieGroup.label.push(dataGroup.label);
            dataGroup.data.forEach((element) => {
              pieGroup.data.push(element);
            });
            groupFound = true;
          }
        }
        if (!groupFound) {
          const newPieGroup: ChartDataGroup = JSON.parse(JSON.stringify(dataGroup));
          newPieGroup.backgroundColor = [newPieGroup.backgroundColor];
          newPieGroup.borderColor = [newPieGroup.borderColor];
          newPieGroup.backgroundHexColor = [newPieGroup.backgroundHexColor];
          newPieGroup.label = [newPieGroup.label];
          pieDataGroups.push(newPieGroup);
        }
      }
    }
    return pieDataGroups;
  }

  /**
   * generate interval information for the labels of the chart
   * the interval information are important for the zoom feature in the combine d widget
   * @param label label information
   * @param index index of the label
   * @returns the labelintervall information of a label
   */
  public convertLabelsToInterval(label: ChartLabels, index: number): LabelInterval {
    const labelInterval: LabelInterval = {
      label: label.label,
      index: label.idx,
      key: 0,
      segments: [],
    };
    // let times = label.split("_");
    if (this.categoriesLabelsObject[index - 1]) {
      labelInterval.startTime = this.categoriesLabelsObject[index - 1].categoryValue;
    } else {
      labelInterval.startTime = this.startTime;
    }

    labelInterval.endTime = label.categoryValue;
    labelInterval.key = label.categoryId;

    return labelInterval;
  }

  /**
   * determines which data points of the data groups lie in the current zoom in each case
   * @param chartLabelInsideZoom information about the visible labels in the current zoom
   * @returns modified data groups
   */
  public getZoomDataset(chartLabelInsideZoom: LabelInterval[]): ChartDataGroup[] {
    const groups: ChartDataGroup[] = [];
    // let newValues:Array<number> = new Array<number>(this.chartData.chartData[0].data.length).fill(0);

    for (const chartDataGroup of this.originChartData) {
      const dataGroup: ChartDataGroup = JSON.parse(JSON.stringify(chartDataGroup));
      dataGroup.data = [];
      chartDataGroup.data.forEach((element, index) => {
        for (const labelInZoom of chartLabelInsideZoom) {
          if (index === labelInZoom.index) {
            dataGroup.data.push(element);
          }
        }
      });
      // dataGroup.data = data.slice();
      groups.push(dataGroup);
    }

    return groups;
  }

  /**
   * updates the labels and datagroups which are in the current zoom of the chart
   */
  public executeZoom(): void {
    this.addtionalString.clear();
    this.addtionalStringCount.clear();

    this.categoriesLabelsObjectInsideZoom = this.categoriesLabelsObject;
    if (!this.zoom) {
      this.categoriesLabelsObjectInsideZoom.forEach((label) => this.calcAdditionalNamesMaps(label));
      return;
    }
    if (this.timeInterval && this.timeInterval !== 'total') {
      this.categoriesLabelsObjectInsideZoom = [];

      const chartLabelIntervals: Array<LabelInterval> = this.categoriesLabelsObject.map((element, index) =>
        this.convertLabelsToInterval(element, index)
      );

      const chartLabelInsideZoom: Array<LabelInterval> = chartLabelIntervals.filter((chartLabelInterval, index) => {
        if (
          (chartLabelInterval.startTime >= this.zoom.startTime || chartLabelInterval.endTime >= this.zoom.startTime) &&
          chartLabelInterval.endTime < this.zoom.endTime
        ) {
          this.categoriesLabelsObjectInsideZoom.push(
            this.categoriesLabelsObject.find((label) => label.idx === chartLabelInterval.index)
          );
          return true;
        }
      });

      const newLabels = [];

      for (const label of chartLabelInsideZoom) {
        newLabels.push(label.label);
      }

      this.chartLabels = newLabels.slice();

      this.chartData = this.getZoomDataset(chartLabelInsideZoom).slice();

      this.refreshStackedLineData();
      this.maxValue = this.getMaxValue(this.chartData);
      this.minValue = this.getMinValue(this.chartData);
    }
    this.categoriesLabelsObjectInsideZoom.forEach((label) => this.calcAdditionalNamesMaps(label));
  }
}
