import { CdkDragDrop, moveItemInArray, CdkDragEnter, CdkDragExit } from '@angular/cdk/drag-drop';

import { Component, ContentChild, Input, OnInit, TemplateRef, EventEmitter, Output } from '@angular/core';

import { Stackable } from './stackable';

@Component({
  selector: 'app-stackable',
  templateUrl: './stackable.component.html',
  styleUrls: ['./stackable.component.scss'],
})
export class StackableComponent implements OnInit {
  @Input() stackable: Stackable;
  @Input() parent: Stackable;
  @Input() connectedTo: string[];
  @Output() handleDragRunning: EventEmitter<boolean> = new EventEmitter();
  @ContentChild('content') templateNode: TemplateRef<any>;
  // @ViewChild(CdkDropList) set dropList(list: CdkDropList) {

  // }

  public dragRunning = false;

  public get connectedDropListsIds(): string[] {
    return this.allDropListsIds.filter((id) => id !== this.stackable.getId());
  }

  public get dragDisabled(): boolean {
    return !this.parent;
  }

  public get parentItemId(): string {
    return this.dragDisabled ? '' : this.stackable?.getParent()?.getId();
  }

  ngOnInit(): void {}

  onClick(event: MouseEvent) {
    event.stopPropagation();
    this.stackable.click(this.stackable);
  }

  get connectedIds(): string[] {
    return this.connectedTo || this.stackable.getConntection() || [];
  }

  public get allDropListsIds(): string[] {
    // We reverse ids here to respect items nesting hierarchy
    return this.parent ? this.connectedTo : this.getIdsRecursive(this.stackable).reverse();
  }

  private getIdsRecursive(item: Stackable): string[] {
    let ids = [item.getId()];

    item.getChildren().forEach((childItem) => {
      ids = ids.concat(this.getIdsRecursive(childItem));
    });
    return ids;
  }

  addGroup(event: MouseEvent): void {
    this.stackable.addChildren(new Stackable());
  }

  addCondition(event: MouseEvent): void {}

  // afterSlotInit(ref: TemplateComponent, stackable: Stackable): void {
  //   stackable.setType(ETemplateType.TEMPLATE_UI);
  //   ref.resourceId = stackable.getResourceId() || this.stackable.getResourceId();
  // }

  get index(): number {
    if (!this.parent) {
      return 0;
    }

    return this.parent.getChildren().findIndex((g: Stackable) => g.getId() === this.stackable.getId());
  }

  handleDraging(state) {
    this.dragRunning = state;
    this.handleDragRunning.emit(this.dragRunning);
  }

  drop(event: CdkDragDrop<Stackable>): void {
    this.dragRunning = false;
    const cur: Stackable = event.container.data;
    const prev: Stackable = event.previousContainer.data;
    // if (event.previousContainer === event.container) {
    //   moveItemInArray(cur.getChildren(), event.previousIndex, event.currentIndex);
    // } else {
    //   const idx = event.previousIndex;
    //   const elem: Stackable = prev.getChildren().find((g: Stackable, index: number) => idx === index);
    //   prev.setChildren(prev.getChildren().filter((g: Stackable, index: number) => idx !== index));
    //   this.stackable.addChildAtIndex(elem, event.currentIndex);
    // }

    if (event.previousContainer.id !== event.container.id) {
      // const idx = event.previousIndex;
      // const elem: Stackable = prev.getChildren().find((g: Stackable, index: number) => idx === index);
      // prev.setChildren(prev.getChildren().filter((g: Stackable, index: number) => idx !== index));
      // this.stackable.addChildAtIndex(elem, event.currentIndex);

      const movingItem: Stackable = event.item.data;
      const idx = event.previousIndex;
      const elem: Stackable = prev.getChildren().find((g: Stackable, index: number) => idx === index);
      cur.addChildAtIndex(elem, event.currentIndex);
      prev.setChildren(event.previousContainer.data.getChildren().filter((child) => child.getId() !== elem.getId()));
    } else {
      moveItemInArray(cur.getChildren(), event.previousIndex, event.currentIndex);
    }

    this.stackable.drop(event);
  }

  private canBeDropped(event: CdkDragDrop<Stackable>): boolean {
    const movingItem: Stackable = event.item.data;

    return (
      event.previousContainer.id !== event.container.id &&
      this.isNotSelfDrop(event) &&
      !this.hasChild(movingItem, event.container.data)
    );
  }

  private isNotSelfDrop(event: CdkDragDrop<Stackable> | CdkDragEnter<Stackable> | CdkDragExit<Stackable>): boolean {
    return event.container.data.getId() !== event.item.data.getId();
  }

  private hasChild(parentItem: Stackable, childItem: Stackable): boolean {
    const hasChild = parentItem.getChildren().some((item) => item.getId() === childItem.getId());
    return hasChild ? true : parentItem.getChildren().some((item) => this.hasChild(item, childItem));
  }

  public getStackableItemClass(idx: number) {
    let className = null;
    if (this.stackable.getChildren().length <= 1) {
      className = 'stackable-child';
    } else {
      if (idx === 0) {
        className = 'stackable-child top';
      }

      if (idx === this.stackable.getChildren().length - 1) {
        className = 'stackable-child bottom';
      }

      if (!className) {
        className = 'stackable-child middle';
      }
    }

    if (this.stackable.getParent() && this.stackable.getParent().getChildren()[0].getId() !== this.stackable.getId()) {
      className += ' noPadding';
    }

    return className;
  }

  public getDragHandleClass(item: Stackable) {
    if (item.getChildren().length > 0) {
      return 'drag-handle-icon-group';
    } else {
      return 'drag-handle-icon-item';
    }
  }
  public getStackableChildItemClass(item: Stackable) {
    if (item.getChildren().length > 0) {
      return 'drag-handle-icon-group';
    } else {
      return 'drag-handle-icon-item';
    }
  }
  public getDragHandleIconClass(item: Stackable) {
    let classname;
    if (item.getChildren().length > 0) {
      classname = 'drag-handle-icon-group';
      classname += item.getContent() ? ' operator' : '';
    } else {
      classname = 'drag-handle-icon-item';
    }
    return classname;
  }
}
