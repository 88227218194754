import {
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  ElementRef,
  Input,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { TemplateComponent } from '@app-modeleditor/components/template/template.component';
import { TemplateService } from '@app-modeleditor/utils/template.service';
import { Printer } from './printer';
@Component({
  selector: 'template-print',
  templateUrl: './print.component.html',
  styleUrls: ['./print.component.scss'],
})
export class PrintComponent {
  private instance: ComponentRef<TemplateComponent>;
  zoomFactor: number;
  @ViewChild('displayContainer', { read: ViewContainerRef }) set paper(paper: ViewContainerRef) {
    this.instance = this._templateApi.loadComponent<TemplateComponent>(TemplateComponent, paper, this._resolver);
    this.instance.instance.templateNode = this.printer.getPrintableElement();
    this.init();
  }
  private root: ElementRef;
  @ViewChild('wrapper') set wrapper(wrapper: ElementRef) {
    this.root = wrapper.nativeElement;
    this.init();
  }

  @Input() printer: Printer;
  constructor(private _templateApi: TemplateService, private _resolver: ComponentFactoryResolver) {}

  get width(): number {
    if (!this.printer || !this.printer.getSelectedFormat()) {
      return 0;
    }
    return (
      this.printer.getZoomFactor() *
      (this.printer.isToggled()
        ? this.printer.getSelectedFormat().getHeight()
        : this.printer.getSelectedFormat().getWidth())
    );
  }

  get height(): number {
    if (!this.printer || !this.printer.getSelectedFormat()) {
      return 0;
    }
    return (
      this.printer.getZoomFactor() *
      (this.printer.isToggled()
        ? this.printer.getSelectedFormat().getWidth()
        : this.printer.getSelectedFormat().getHeight())
    );
  }

  private init() {
    if (this.instance && this.instance.instance) {
      this.instance.instance.root = this.root as any;
    }
  }

  getTransformation(element: HTMLElement): string {
    const el: HTMLElement = element;
    if (el.style.transform.match(/scale\(.*\)/gi)) {
      return el.style.transform.replace(/scale\(.*\)/gi, `scale(${1 - this.printer.getResolution()})`);
    } else {
      return `${el.style.transform} scale(${1 - this.printer.getResolution()})`;
    }
  }
}
