import { Component, inject } from '@angular/core';
import { EMessageType, Message } from '@core/message/message';
import { MessageService } from '@core/message/message.service';
import { finalize } from 'rxjs/operators';
import { PasswordService } from '../password.service';
import { ChangePasswordResult } from '../shared/change-password-form/change-password-result.interface';
@Component({
  selector: 'change-password',
  templateUrl: './password-change.html',
  styleUrls: ['./password-change.scss'],
})
export class PasswordChangeComponent {
  protected readonly passwordService = inject(PasswordService);
  protected readonly messageService = inject(MessageService);
  protected isProcessing = false;

  protected onSubmit(result: ChangePasswordResult): void {
    this.isProcessing = true;
    this.passwordService
      .changePassword(result.oldPassword, result.newPassword)
      .pipe(finalize(() => (this.isProcessing = false)))
      .subscribe((data: { errorList: string[]; forceChangePassword: boolean }) => {
        if (data.errorList?.length > 0) {
          this.messageService.show(
            new Message()
              .setType(EMessageType.ERROR)
              .setDuration(7500)
              .setText(`SECURITY.ERRORS.LABEL.${data.errorList[0]}`)
          );
        } else {
          this.messageService.show(
            new Message().setType(EMessageType.SUCCESS).setDuration(5000).setText(`SECURITY.PASSWORD.changed`)
          );
        }
      });
  }
}
