import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { CONTAINER_DATA } from '@app-modeleditor/components/lightbox/overlay/container-data';
import { DefaultOverlayContainer } from '@app-modeleditor/components/lightbox/overlay/default-overlay-container';
import { ISelectionBoxProportions } from './selection-box-proportions.interface';

@Component({
  selector: 'app-selection-box',
  templateUrl: './selection-box.component.html',
  styleUrls: ['./selection-box.component.scss'],
})
export class SelectionBoxComponent extends DefaultOverlayContainer<any> implements OnInit {
  public proportions: ISelectionBoxProportions = {
    height: 0,
    width: 0,
    y: 0,
    x: 0,
  };

  constructor(@Inject(CONTAINER_DATA) private _data: ISelectionBoxProportions, private _cd: ChangeDetectorRef) {
    super();
    this.proportions = this._data;
  }

  public setProportions(proportions: ISelectionBoxProportions): void {
    this.proportions = proportions;
    this._cd.detectChanges();
  }

  ngOnInit(): void {}
}
