import { GanttResponse } from 'frontend/src/dashboard/gantt/general/response/responses/response';
import { IGanttResponse } from '../../../../response/gantt-response';
import { GanttEssentialPlugIns } from '../../../e-gantt-essential-plugins';
import { GanttBlockAdditionalMarkersPlugIn } from '../block-additional-markers';

/**
 * Represents a response for additional markers in the Gantt chart.
 * @extends GanttResponse
 */
export class GanttResponseAdditionalMarkers extends GanttResponse {
  /**
   * Handles the response received from the server and inserts additional markers into the Gantt chart.
   * @param response The response received from the server.
   * @returns A boolean indicating whether the response was handled successfully.
   */
  public handleResponse(response: IGanttResponse): boolean {
    let handledResponse = false;

    const plugin = this._pluginHandlerService.getPlugInById(
      GanttEssentialPlugIns.BlockAdditionalMarkersPlugIn
    ) as GanttBlockAdditionalMarkersPlugIn;

    plugin.insertAdditionalMarkersByBackendData(response?.ganttEntries);

    handledResponse = true;

    return handledResponse;
  }
}
