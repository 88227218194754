import { EntryElement } from '../entry-collection/entry-element';
import { EFieldType } from '../entry-collection/field-type.enum';
import { EDisplaySize } from './displaySize.enum';
import { FilterNodeToViewerConverter } from './filter-node-to-viewer-converter';
import { IFilterNodeViewerInputData } from './filter-node-viewer-input-data.interface';
import { IFilterNodeViewerData } from './filter-node-viewer.interface';

export class FilterNodeViewer extends EntryElement {
  private displaySize: EDisplaySize;
  private viewerData: IFilterNodeViewerData[];
  private converter: FilterNodeToViewerConverter = new FilterNodeToViewerConverter();

  constructor() {
    super();
    this.setFieldType(EFieldType.FILTER_NODE_VIEWER);
    this.setDisplaySize(EDisplaySize.SMALL).setId('filterNodeViewer');
  }

  public getDisplaySize(): EDisplaySize {
    return this.displaySize;
  }

  public setDisplaySize(displaySize: EDisplaySize): this {
    this.displaySize = displaySize;
    return this;
  }

  public getViewerData(): IFilterNodeViewerData[] {
    return this.viewerData || [];
  }

  private setViewerData(viewerData: IFilterNodeViewerData[]): this {
    this.viewerData = viewerData;
    return this;
  }

  private getColorByLevel(itemLevel: number) {
    const colorValue = itemLevel == 1 ? 0 : (itemLevel * 40) % 255;
    return `rgb(${colorValue},${colorValue},${colorValue})`;
  }

  public updateViewerData(inputData: IFilterNodeViewerInputData): this {
    this.setViewerData(
      (this.converter.convertNodeToViewerFormat(inputData.node, inputData.attributeData) || []).map((value) => {
        value.colorValue = this.getColorByLevel(value.level);
        return value;
      })
    );
    return this;
  }
}
