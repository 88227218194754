import { Component } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { NNIconLegendItem } from '@app-nodenetwork/legend/icon-legend/icon-legend.component';

@Component({
  selector: 'nn-icon-edit-dialog',
  templateUrl: './icon-edit.dialog.html',
  styleUrls: ['./icon-edit.dialog.scss'],
})

/**
 * Dialog to handle (custom) icons inside node network.
 * This is an experimental approach and will not be used yet.
 */
export class NodeNetworkIconEditDialog {
  public selectedAttributeItem: NNIconLegendItem = null;

  public allSelectableIcons: string[] = [
    './assets/img/custom/example-icon.png',
    './assets/img/custom/example-icon.png',
    './assets/img/custom/example-icon.png',
    './assets/img/custom/example-icon.png',
  ];

  public attributeIconlist: NNIconEditDialogListItem[] = [];

  constructor(public dialogRef: MatDialogRef<NodeNetworkIconEditDialog>) {
    const entry1 = new NNIconEditDialogListItem();
    entry1.headline = 'Headline 1';
    entry1.icons.push(new NNIconEditDialogItem('./assets/img/custom/example-icon.png', 'Label1', 'id1'));
    entry1.icons.push(new NNIconEditDialogItem('./assets/img/custom/example-icon.png', 'Label2', 'id2'));
    this.attributeIconlist.push(entry1);
    this.attributeIconlist.push(entry1);
    this.attributeIconlist.push(entry1);
    this.attributeIconlist.push(entry1);
    this.attributeIconlist.push(entry1);
  }

  public onClose(): void {
    this.dialogRef.close(true);
  }

  public importFile(event: any): void {}

  onSelectIcon(f) {}
}

/**
 * Structure of menu item to select icon.
 */
export class NNIconEditDialogListItem {
  public headline = '';
  public icons: NNIconEditDialogItem[] = [];
}

/**
 * Description of one icon.
 */
export class NNIconEditDialogItem {
  public imageURL: string;
  public label: string;
  public id: string;

  constructor(imageURL: string, label: string, id: string) {
    this.imageURL = imageURL;
    this.label = label;
    this.id = id;
  }
}
