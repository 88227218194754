import {
  Component,
  ComponentRef,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { getContentType } from '@app-modeleditor/components/downloader/file-download-helper';
import { Lightbox } from '@app-modeleditor/components/lightbox/lightbox';
import { LightboxComponent } from '@app-modeleditor/components/lightbox/lightbox.component';
import { LightboxService } from '@app-modeleditor/components/lightbox/lightbox.service';
import { TemplateComponent } from '@app-modeleditor/components/template/template.component';
import { ERequestMethod, RequestOptions, RequestService } from '@app-modeleditor/request.service';
import { UiService } from '@app-modeleditor/ui.service';
import { TemplateAdapter } from '@app-modeleditor/utils/template-factory.service';
import { TemplateService } from '@app-modeleditor/utils/template.service';
import {
  SaxMsServiceObject,
  SaxMsSubmenuFavoriteTabService,
} from '@app-modules/saxms-submenu-elements/saxms-submenu-favorite-tab.service';
import { SaxMsSubmenuService } from '@app-modules/saxms-submenu-elements/saxms-submenu.service';
import { TranslateService } from '@ngx-translate/core';
import { ActionService } from 'frontend/src/dashboard/shared/data-access/actions/action.service';
import { ETemplateType } from 'frontend/src/dashboard/model/resource/template-type';
import { of } from 'rxjs';
import { delay, take, takeWhile } from 'rxjs/operators';
import {
  ColorScheme,
  ColorValue,
  EColorSchemenStyles,
} from './hotspot-sidebars/hotspot-colorscheme-sidebar/colorscheme-color-slider/colorscheme';
import { HotspotToolbar } from './hotspot-toolbar';
import { AttributeCategory, Hotspot, Region, RegionAttribute } from './hotspot/hotspot';
import { HotspotComponent } from './hotspot/hotspot.component';
import { EPolygonModes, EPolygonType, Polygon } from './hotspot/polygon';
import { InterpolationVertex, Vertex } from './hotspot/vertex';

@Component({
  selector: 'template-hotspot-wrapper',
  templateUrl: './hotspot-wrapper.component.html',
  styleUrls: ['./hotspot-wrapper.component.scss'],
  providers: [SaxMsSubmenuFavoriteTabService],
})
export class HotspotWrapperComponent implements OnInit, OnDestroy {
  @ViewChild('hotspotWidget') hotspotWidget: ElementRef;
  @ViewChild('hotspotTemplate') hotspotTemplate: HotspotComponent;
  @ViewChild('toolbarContainer', { read: ViewContainerRef }) toolbarContainer: ViewContainerRef;
  @Input() template: Hotspot;

  //  protected requestApi: RequestService;

  private alive = false;
  private initLoad = true;
  private regionAttributeValues: RegionAttribute[] = [];
  private toolbar: HotspotToolbar;
  public toolbarMode: any;
  public title = '';
  public toolbarContentVisible = false;
  public currentPolygon: Polygon;
  public currentAttribute: AttributeCategory;
  public currentNode: Vertex;

  constructor(
    private submenuService: SaxMsSubmenuService,
    private uiService: UiService,
    public translate: TranslateService,
    private requestApi: RequestService,
    private buttonService: ActionService,
    public templatefactory: TemplateAdapter,
    public lightboxApi: LightboxService,
    protected templateApi: TemplateService
  ) {
    //  super(injector);
    // this.requestApi = this.injector.get(RequestService);
  }

  ngOnInit() {
    // super.ngOnInit();
    this.init();
    this.alive = true;
    this.buildAdditionalSubmenu();
    this.getToolbar();
    this.buttonService.customActionListener.pipe(takeWhile(() => this.alive)).subscribe((action) => {
      switch (action.button.id) {
        case 'hotspot.rectangleTemplate':
          this.handleNewPolygonDialog(EPolygonType.RECTANGLE);
          break;
        case 'hotspot.triangleTemplate':
          this.handleNewPolygonDialog(EPolygonType.TRIANGLE);
          break;

        case 'hotspot.polygonLineMode':
          this.hotspotTemplate.handleLinearMode();
          break;
        case 'hotspot.polygonInterpolationMode':
          this.hotspotTemplate.handleInterpolation();
          break;

        case 'hotspot.polygonEdit':
          this.editSelectedPolygon();
          break;
        case 'hotspot.polygonDelete':
          this.handleDeletePolygon();
          break;
        case 'hotspot.vertexDelete':
          this.handleDeleteNode();
          break;
      }
    });
  }

  ngOnDestroy() {
    this.alive = false;
  }

  afterAttributeSelected(attributeCategory: AttributeCategory) {
    this.currentAttribute = attributeCategory;
  }

  /**
   * template hook which gets called after data was successfully loaded
   * @param result any
   * @returns void
   */
  init(): void {
    if (this.template.getValue().imageRestUrl && this.initLoad) {
      this.template.setImageUrl(this.template.getValue().imageRestUrl);
      this.getBackgroundImage();
      this.initLoad = false;
    }
    // this.hotSpotTemplate.setEntry(result);
    for (const dataObj of this.template.getValue().dataObj) {
      switch (dataObj.type) {
        case 'polygon':
          this.mappingPolygons(dataObj);
          break;
        case 'region':
          this.mappingRegions(dataObj);
          break;
        case 'regionattributevalue':
          this.mappingRegionAttributeValues(dataObj);
          break;
        case 'attributecategory':
          this.mappingAttributes(dataObj);
          break;
      }
    }
  }

  private mappingAttributes(dataObj: any) {
    const attributeCategories: any[] = dataObj.resources;
    this.template.setAttributeCategories(
      (attributeCategories || []).map((jsonAttribute: any) => {
        const attributeCategory: AttributeCategory = new AttributeCategory();
        attributeCategory.setId(jsonAttribute.id).setName(jsonAttribute.name);
        attributeCategory.setColorScheme(this.mappingColorScheme(jsonAttribute.colorScheme));
        return attributeCategory;
      })
    );

    if (!this.currentAttribute && this.template.getAttributeCategories().length > 0) {
      this.currentAttribute = this.template.getAttributeCategories()[0];
    }
  }

  private mappingColorScheme(colorSchemeData: any): ColorScheme {
    if (!colorSchemeData) {
      return null;
    }
    const colorScheme = new ColorScheme();
    colorScheme
      .setStyle((colorSchemeData.colorSchemeType as string).toLowerCase() as EColorSchemenStyles)
      .setId(colorSchemeData.id)
      .setName(colorSchemeData.name)
      .setOpacity(colorSchemeData.opacity * 100)
      .setDefaultColor(colorSchemeData.defaultColor);

    for (const colorData of colorSchemeData.coloredValues) {
      const colorValue = new ColorValue();

      colorValue
        .setId(colorData.id)
        .setColor(colorData.color)
        .setColorSchemeRef(colorData.colorSchemeRef)
        .setValue(colorData.attributeValue)
        .setAttributeValueType(colorData.attributeValueType);

      if (colorValue.getColor() === 'b') {
        colorValue.setColor('blue');
      }

      if (colorValue.getColor() === 'g') {
        colorValue.setColor('green');
      }
      colorScheme.addColoredValues(colorValue);
    }
    return colorScheme;
  }

  private mappingRegionAttributeValues(dataObj: any) {
    this.regionAttributeValues = [];
    const regionAttributeValues: any[] = dataObj.resources;
    for (const regionAttributeValue of regionAttributeValues) {
      const attributeValue = new RegionAttribute();

      attributeValue
        .setId(regionAttributeValue.id)
        .setAttributeKey(regionAttributeValue.attributeKey)
        .setRegionKey(regionAttributeValue.regionKey)
        .setValue(regionAttributeValue.stringValue || regionAttributeValue.numValue || regionAttributeValue.enumValue);

      this.regionAttributeValues.push(attributeValue);
    }

    if (this.template.getRegions().length > 0) {
      this.template.getRegions().forEach((region) => {
        const regionAttributes: RegionAttribute[] = this.regionAttributeValues.filter(
          (value) => value.getRegionKey() === region.getId()
        );
        region.setRegionAttributes(regionAttributes);
      });
    }
  }

  private mappingRegions(dataObj: any) {
    const regions: any[] = dataObj.resources;
    this.template.setRegions(
      (regions || []).map((jsonRegion: any) => {
        const region: Region = new Region();
        region.setId(jsonRegion.id).setName(jsonRegion.name);
        if (this.regionAttributeValues.length > 0) {
          const regionAttributes: RegionAttribute[] = this.regionAttributeValues.filter(
            (value) => value.getRegionKey() === region.getId()
          );
          region.setRegionAttributes(regionAttributes);
        }
        return region;
      })
    );
  }

  private validatePolygon(jsonPoly) {
    const validVertices = (jsonPoly.vertices as any[]).filter(
      (vertex) =>
        vertex.xCoordinate >= 0 && vertex.xCoordinate <= 100 && vertex.yCoordinate >= 0 && vertex.yCoordinate <= 100
    );

    return validVertices.length > 0;
  }

  private mappingPolygons(dataObj: any) {
    const polygons: any[] = dataObj.resources;
    this.template.setPolygons(
      (polygons || [])
        .filter((poly) => this.validatePolygon(poly))
        .map((jsonPoly: any) => {
          const poly: Polygon = new Polygon();

          poly
            .setEditEntryRestUrl(jsonPoly.editEntryRestUrl)
            .setId(jsonPoly.id)
            .setName(jsonPoly.name)
            .setRegionKey(jsonPoly.regionKey);

          // set vertices
          poly.setVertices(
            (jsonPoly.vertices || []).map((jsonVertice: any) => {
              const vertex: Vertex = new Vertex(jsonVertice.xCoordinate, jsonVertice.yCoordinate)
                .setIndex(jsonVertice.indexInPolygon)
                .setId(jsonVertice.id);
              return vertex;
            })
          );
          // set interpolation vertices
          poly.setInterpolationPoints(
            (jsonPoly.interpolationVertices || []).map((jsonVertice: any) => {
              const backendOriginVertex = jsonVertice.originVertex;
              const originVertex: Vertex = new Vertex(backendOriginVertex.xCoordinate, backendOriginVertex.yCoordinate)
                .setId(backendOriginVertex.id)
                .setIndex(backendOriginVertex.indexInPolygon)
                .setType(backendOriginVertex.vertexType);

              const vertex: InterpolationVertex = new InterpolationVertex(
                originVertex,
                jsonVertice.xCoordinate,
                jsonVertice.yCoordinate
              )
                .setIndex(jsonVertice.indexInPolygon)
                .setId(jsonVertice.id);
              // vertex.setOriginVertex(jsonPoly)

              return vertex;
            })
          );
          return poly;
        })
    );
  }

  public afterColorValueChanges() {
    this.hotspotTemplate.getColorOfPolygons();
    this.template.prepareSaveForBackend();
  }

  private getBackgroundImage() {
    const options: RequestOptions = new RequestOptions();
    options.setHttpOptions({ responseType: 'blob' as 'json', observe: 'response' });
    this.requestApi
      .call(ERequestMethod.GET, this.template.getImageUrl(), options)
      .pipe(takeWhile(() => this.alive))
      .subscribe((image: any) => {
        this.template.setBackgroundImage(image.body, getContentType(image));
      });
  }

  public setCurrentNode(vertex: Vertex) {
    this.currentNode = vertex;
    this.changeSubmenuMenu();
  }

  public setCurrentPolygon(polygon: Polygon) {
    this.currentPolygon = polygon;
    this.changeSubmenuMenu();
  }

  private getToolbar() {
    setTimeout(() => {
      const toolbarRef: ComponentRef<TemplateComponent> = this.templateApi.loadComponent<TemplateComponent>(
        TemplateComponent,
        this.toolbarContainer
      );
      if (toolbarRef) {
        toolbarRef.instance.templateNode = this.toolbar = new HotspotToolbar({ showMenuToggleBtn: true }, this).get();
      }
    }, 0);
  }

  // old subemnu concept

  private buildAdditionalSubmenu() {
    // if (this.template.additionalSubMenus) {
    //   for (const additionalSubemenu of this.template.additionalSubMenus) {
    //     const submenu = new SaxMsSubMenu(additionalSubemenu.id, additionalSubemenu.name);
    //     if (additionalSubemenu.sideBar) {
    //       submenu.setSideBar(additionalSubemenu.sideBar);
    //     }
    //     for (const additionalGroup of additionalSubemenu.subMenuGroups) {
    //       const group = this.buildAdditionalSubmenuGroup(additionalGroup);
    //       submenu.groups.push(group);
    //     }
    //     if (additionalSubemenu.defaultSelected) {
    //       this.toolbarMode = additionalSubemenu.id;
    //     }
    //     this.submenuObject.set(additionalSubemenu.id, submenu);
    //     if (additionalSubemenu.defaultSelected) {
    //       this.toolbarMode = additionalSubemenu.id;
    //     }
    //   }
    // }
  }

  public handleToolbarClick(mode: string) {
    if (this.toolbarMode === mode || this.toolbarMode === '' || !this.toolbarMode || !this.toolbarContentVisible) {
      this.toolbarContentVisible = !this.toolbarContentVisible;
    }

    this.toolbarMode = mode;
  }

  private additionalButtonsCallback(event) {}

  private handleDeleteNode() {
    this.hotspotTemplate.handleDeleteNode();
    this.changeSubmenuMenu();
  }

  private handleDeletePolygon() {
    this.hotspotTemplate.handleDeletePolygon();
    this.changeSubmenuMenu();
  }

  private editSelectedPolygon() {
    if (!this.currentPolygon) {
      return;
    }
    this.uiService.getData(this.currentPolygon.getEditEntryRestUrl()).subscribe((data) => {
      data = this.appendButtonsToDialog(data, true);
      this.openDialog(data);
    });
  }

  private handleNewPolygonDialog(type: EPolygonType) {
    // const dialogOptions: ICreateDialogOptions = {
    //   showName: true,
    //   nameLbl: this.translate.instant('HOTSPOT.DIALOG.ADD.polygonName'),
    //   selectLbl: this.translate.instant('HOTSPOT.DIALOG.ADD.regions'),
    //   dialogLbl: this.translate.instant('HOTSPOT.DIALOG.ADD.label'),
    // };
    // const createUiDialogElement: CreateDialog =
    //   new CreateDialog(
    //     this.dialogS,
    //     this.template.getRegions().map(region => {
    //       return { key: region.getName(), value: region.getId() };
    //     }),
    //     dialogOptions).open();
    // createUiDialogElement.getRef().afterClosed().pipe(take(1)).subscribe((result: IDialogResult) => {
    //   if (result && result.type === EDialogResultType.CONFIRM) {
    //     this.addNewPolygon(type, result.value.name, result.value.select);
    //   }
    // });
  }

  private addNewPolygon(type: EPolygonType, name: string, regionKey: string) {
    let region;
    if (regionKey && this.template.getRegions().length > 0) {
      region = this.template.getRegions().find((region) => regionKey === region.getId());
    }
    this.hotspotTemplate.createNewPolygon(name, region, type);
    this.changeSubmenuMenu();
    // this.dialogS.close();
    this.hotspotTemplate.getColorOfPolygons();
  }

  private handlePolygonModeButton(event) {
    switch (event.additionalArgs[0]) {
      case 'interpolation_mode':
        this.hotspotTemplate.handleInterpolation();
        break;
      case 'linear_mode':
        this.hotspotTemplate.handleLinearMode();
        break;
    }
    this.changeSubmenuMenu();
  }

  appendButtonsToDialog(data, saveEnable) {
    data.buttons = [];
    if (saveEnable && !data.disableSaveButton) {
      data.buttons.push({
        label: '@save@',
        close: true,
        save: true,
        id: data.id + '_saveBtn',
      });
    }

    if (!data.disableCancelButton) {
      data.buttons.push({
        label: '@cancel@',
        close: true,
        id: data.id + '_cancelBtn',
      });
    }

    return data;
  }

  openDialog(content) {
    content.type = ETemplateType.LIGHTBOX;
    const lightbox: Lightbox = this.templatefactory.adapt(content);

    const ref: MatDialogRef<LightboxComponent> = this.lightboxApi.open(lightbox);
    ref
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => {});
    return;
  }

  public getSideBarType() {
    return 'region';
    // if (this.currentSubmenu.getSideBar()) {
    //   const idSplit = (this.currentSubmenu.getSideBar().id as string).split('.');
    //   return idSplit[idSplit.length - 1].toLowerCase();
    // }
  }

  private changeSubmenuMenu() {
    of(null)
      .pipe(delay(0))
      .subscribe(() => {
        const editPolyBtnData: SaxMsServiceObject = {
          elementId: this.template.getId() + '_edit_polygon_btn',
          disabled: !this.currentPolygon,
        };

        const removePolygonBtnData: SaxMsServiceObject = {
          elementId: this.template.getId() + '_remove_polygon_btn',
          disabled: !this.currentPolygon,
        };
        const removeVertexBtnData: SaxMsServiceObject = {
          elementId: this.template.getId() + '_remove_vertex_btn',
          disabled: !this.currentNode || !this.currentPolygon,
        };

        const interpolationModeBtn: SaxMsServiceObject = {
          elementId: this.template.getId() + '_interpolation_mode',
          disabled:
            !this.currentPolygon ||
            (this.currentPolygon && this.currentPolygon.getPolygonType() === EPolygonModes.QUADRATIC),
        };
        const linearModeBtn: SaxMsServiceObject = {
          elementId: this.template.getId() + '_linear_mode',
          disabled:
            !this.currentPolygon ||
            (this.currentPolygon && this.currentPolygon.getPolygonType() === EPolygonModes.LINE),
        };

        this.submenuService.triggerDataChange(editPolyBtnData);
        this.submenuService.triggerDataChange(interpolationModeBtn);
        this.submenuService.triggerDataChange(linearModeBtn);
        this.submenuService.triggerDataChange(removePolygonBtnData);
        this.submenuService.triggerDataChange(removeVertexBtnData);
      });
  }

  public onChanges(changedTemplate) {
    this.template.prepareSaveForBackend();
  }

  public setEditMode(editMode: boolean) {
    this.hotspotTemplate.setEditMode(editMode);
    if (editMode && this.currentPolygon) {
      this.currentPolygon.setEditMode(true);
    }
  }
}
