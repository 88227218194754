import { EResizeMode } from 'frontend/src/dashboard/model/resource/template-resize-mode.enum';
import { ETemplateType } from 'frontend/src/dashboard/model/resource/template-type';
import { ContentElement } from '../content/content-element/content-element';
import { Content } from './../content/content';

export class Page extends ContentElement {
  private content: Content;

  public getContent(): Content {
    return this.content;
  }

  public setContent(content: Content): this {
    this.content = content;
    if (this.content && !this.content.getParentId()) {
      this.content.setParentId(this.getId());
    }
    return this;
  }

  constructor() {
    super();
    this.setType(ETemplateType.PAGE).setResizeMode(EResizeMode.FIT_PARENT);
  }
}
