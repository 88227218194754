import { Component, Input } from '@angular/core';

@Component({
  selector: 'node-network-add-element',
  templateUrl: './node-network-add-element.html',
  styleUrls: ['./node-network-add-element.scss'],
})

/**
 * Angular component of node network edit menu to add different types of nodes.
 */
export class NodeNetworkAddElement {
  @Input('elements') addableElements: any;

  /**
   * Sets data to Drag event to propagate data to drag end listener.
   * @param event Drag event by dragging node from menu into node network.
   * @param type Type of backend description of node.
   */
  public setNodeType(event: DragEvent, type: string) {
    event.dataTransfer.setData('text', type);
  }

  /**
   * Checks if elemnt wiht given type is active.
   * @param type Type of element.
   */
  public elementIsActive(type: string): boolean {
    for (const nodeType of this.addableElements) {
      if (nodeType.type == type) return true;
    }
    return false;
  }
}
