<!-- <button (click)="test()">test</button> -->
<div
  class="editmode"
  [class.locked]="!editmode"
  *ngIf="showEditModeInfo && menuItem?.getTemplateMode() === 'REQUIRE_EDIT_MODE'"
>
  <div class="text-wrapper">
    <app-image class="image" [label]="editmode ? 'EDIT' : 'DEACTIVATE_EDIT'"></app-image>
    <div class="text">
      {{ (editmode ? 'BUTTON.edit_mode_enabled' : 'BUTTON.edit_mode_disabled') | translate }}
      <button class="toggle-editmode-btn" mat-stroked-button (click)="toggleEditMode()">
        {{ (editmode ? 'BUTTON.disable' : 'BUTTON.enable') | translate }}
      </button>
    </div>
  </div>
  <button mat-icon-button class="close-edit" (click)="showEditModeInfo = !showEditModeInfo">
    <mat-icon>close</mat-icon>
  </button>
</div>
