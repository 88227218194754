import { TextOverlayShadowMode } from '@gantt/lib/best_gantt/script/config/config-data/text-overlay-config.data';
import {
  BestGantt,
  EGanttInstance,
  EGanttTextStrategy,
  EGanttXAxisPosition,
  GanttInstanceService,
} from '@gantt/public-api';
import {
  SaxMsBestGanttRowColorHierarchiePluginSetting,
  SaxMsBestGanttSettings,
} from '../../gantt/saxms-best-gantt.settings';
import { EdgesPluginSettings } from '../plugin/plugin-list/edges/edges-plugin-settings.enum';
import { SaxMsBestGanttZoomGridMode, SaxMsBestGanttZoomGridStep } from './gantt-settings-dialog/gantt-style.enum';

/**
 * Integration handling for given gantt settings.
 */
export class SaxMsBestGanttDiagramSettingsIntegrator {
  public gantt: BestGantt;

  /**
   * @param gantt Best gantt instance where the settings should be included.
   * @param ganttInstanceService
   */
  constructor(gantt: BestGantt, private _ganttInstanceService: GanttInstanceService) {
    this.gantt = gantt;
  }

  /**
   * Changed row height of gantt diagram to given height.
   * Affects also block height.
   * @param rowHeight New row height.
   */
  public changeRowHeight(rowHeight: number): void {
    this.gantt.getConfig().setRowHeight(rowHeight);
  }

  /**
   * Decides if vertical lines are visible or not.
   * @param showVerticalLines Show or hide vertical lines.
   */
  public changeVerticalLinesVisibility(showVerticalLines: boolean): void {
    this.gantt.getConfig().setVerticalLinesVisible(showVerticalLines);
    this.gantt.getShiftFacade().checkVerticalLinesVisibility();
  }

  /**
   * Change the vertical lines color.
   * @param color general color of lines.
   * @param colorDaily color of lines on full day.
   * @param colorMonthly color of lines on full month.
   */
  public changeVerticalLinesColor(color: string, colorDaily: string, colorMonthly: string): void {
    this.gantt.getConfig().setVerticalLinesColor(color);
    this.gantt.getConfig().setVerticalLinesDailyColor(colorDaily);
    this.gantt.getConfig().setVerticalLinesMonthlyColor(colorMonthly);
  }

  /**
   * Change the vertical lines thickness.
   * @param thickness general thickness of lines.
   * @param thicknessDaily thickness of lines on full day.
   * @param thicknessMonthly general thickness of lines on full month.
   */
  public changeVerticalLinesThickness(thickness: number, thicknessDaily: number, thicknessMonthly: number): void {
    this.gantt.getConfig().setVerticalLinesThickness(thickness);
    this.gantt.getConfig().setVerticalLinesDailyThickness(thicknessDaily);
    this.gantt.getConfig().setVerticalLinesMonthlyThickness(thicknessMonthly);
  }

  /**
   * Change the horizontal lines color.
   * @param color color of lines.
   */
  public changeHorizontalLinesColor(color: string): void {
    this.gantt.getConfig().setHorizontalLinesColor(color);
  }

  /**
   * Change the horizontal lines thickness.
   * @param thickness thickness of lines.
   */
  public changeHorizontalLinesThickness(thickness: number): void {
    this.gantt.getConfig().setHorizontalLinesThickness(thickness);
  }

  /**
   * Change displaying dividing lines between split rows.
   * @param showSplitLines shows lines if true
   */
  public changeShowHorizontalSplitLines(showSplitLines: boolean): void {
    this.gantt.getConfig().setShowHorizontalSplitLines(showSplitLines);
  }

  /**
   * Decides if horizontal lines are visible or not.
   * @param showHorizontalLines Show or hide horizontal lines.
   */
  public changeHorizontalLinesVisibility(showHorizontalLines: boolean): void {
    this.gantt.getConfig().setHorizontalLinesVisible(showHorizontalLines);
    this.gantt.getShiftFacade().checkHorizontalLinesVisibility();
  }

  /**
   * Add dark grey color background to every second row.
   * @param plugInId Storage id to regiser RowRasterColorExecuter plugin.
   * @param markRows Show or hide color background.
   */
  public markOddRows(plugInId: string, markRows: boolean): void {
    if (!this.gantt.getPlugIns()[plugInId]) {
      this.gantt.addPlugIn(plugInId, this._ganttInstanceService.getInstance(EGanttInstance.ROW_RASTER_COLOR_EXECUTER));
    }
    this.gantt.getPlugIns()[plugInId].setActive(markRows);
  }

  /**
   * Add color backgrounds to every row bases on it's level in the hierarchie.
   * @param plugInId Storage id to regiser RowColorHierarchieExecuter plugin.
   * @param colors Show or hide color background.
   */
  public colorRowHierarchie(plugInId: string, data: SaxMsBestGanttRowColorHierarchiePluginSetting): void {
    if (!data) {
      return;
    }
    if (!this.gantt.getPlugIns()[plugInId]) {
      this.gantt.addPlugIn(plugInId, this._ganttInstanceService.getInstance(EGanttInstance.ROW_COLOR_HIERARCHIE));
    }

    if (data.active != null && data.colors) {
      this.gantt.getPlugIns()[plugInId].setActive(data.active);
      if (data.colors) {
        this.gantt.getPlugIns()[plugInId].setRowHierarchieColor(data.colors);
      }
    } else {
      console.warn(
        `ganttSettings.rowColorHierarchies malformed from backend - should have properties: active [boolean], colors [string[]]`,
        data
      );
    }
  }

  /**
   * Decides if blocks has rounded corners or not.
   * @param roundedCorners Rounded corners or not.
   */
  public toggleRoundedCorners(roundedCorners: boolean): void {
    this.gantt.getConfig().setShiftBuildingRoundedCorners(roundedCorners);
  }

  /**
   * Sets date formatting for time point markers inside x axis format.
   * Look at {D3-time-format}{ @link https://github.com/d3/d3-time-format } for fromat definition
   * @param format D3 time format.
   */
  public changeXAxisFormat(format: string): void {
    this.gantt.getConfig().xAxisMarkerFormat = format;
  }

  /**
   * Decides if weekends should be marked or not.
   * @param showWeekends Show or hide weekends.
   */
  public toggleWeekends(showWeekends: boolean): void {
    if (showWeekends) {
      this.gantt.getGlobalTimeSpanMarker().setHideWeekends(false);
      this.gantt.getGlobalTimeSpanMarker().show();
    } else {
      this.gantt.getGlobalTimeSpanMarker().setHideWeekends(true);
    }
  }

  /**
   * Changes color of weekend marking by color hex.
   * @param color Weekend color.
   */
  public changeWeekendColor(color: string): void {
    this.gantt.getGlobalTimeSpanMarker().setWeekendColor(color);
  }

  /**
   * Changes color of holidays marking by color hex.
   * @param color holiday color.
   */
  public changeHolidayColor(color: string): void {
    this.gantt.getGlobalTimeSpanMarker().setHolidayColor(color);
  }

  /**
   * Decides if non-similar shift blocks should be dehighlighted by mouseover.
   * @param showSimilarShifts Activate dehighlighting.
   */
  public toggleShowSimilarShifts(showSimilarShifts: boolean): void {
    this.gantt.getConfig().deHighlightShiftsHover = showSimilarShifts;
  }

  /**
   * Decides, if tooltips have delay before open.
   * @param delay Add delay of 300ms or not.
   */
  public toggleDelay(delay: boolean): void {
    this.gantt.getConfig().tooltip.delay = delay ? 300 : 0;
  }

  /**
   * Sets font size of block names.
   * @param fontSize New font size (without 'px').
   */
  public changeShiftFontSize(fontSize: number): void {
    this.gantt.getConfig().setShiftFontSize(fontSize);
  }

  /**
   * Sets text color of block names.
   * @param fontSize New font size (without 'px').
   */
  public changeShiftTextColor(color: string): void {
    this.gantt.getConfig().setShiftTextColor(color);
  }

  /**
   * Sets font size of row names.
   * @param fontSize New font size (without 'px').
   */
  public changeRowFontSize(fontSize: number): void {
    this.gantt.getConfig().setRowFontSize(fontSize);
  }

  /**
   * Changes the text overlay shadow mode of the Gantt chart.
   * @param mode The new text overlay shadow mode.
   */
  public changeTextOverlayShadowMode(mode: TextOverlayShadowMode): void {
    this.gantt.getConfig().setTextOverlayShadowMode(mode);
  }

  /**
   * Padding top and bottom between shift blocks and its row.
   * @param padding Value to set padding.
   */
  public changeShiftPadding(padding: number): void {
    this.gantt.getConfig().setLineTop(padding);
    this.gantt.getConfig().setLineBottom(padding + 1);
  }

  /**
   * Sets zoom timespan of gantt.
   * @param from Start time in ms
   * @param to End time in ms
   */
  public changeZoom(from: number, to: number): void {
    if (from && to) {
      if (from === to || to < from) {
        console.warn('Invalid time span!');
        return;
      }
      const minTimeValue: number = this.gantt.getDataHandler().getOriginDataset().minValue.getTime();
      const maxTimeValue: number = this.gantt.getDataHandler().getOriginDataset().maxValue.getTime();
      if (from >= minTimeValue && to <= maxTimeValue) {
        // if time span is in whole time span
        this.gantt.setCurrentZoomedTimeSpan(new Date(from), new Date(to));
      } else {
        console.warn(`Zoomed time span not in gantt time span.
         ${new Date(from)} - ${new Date(to)} out of bounds of ${new Date(minTimeValue)} - ${new Date(
          maxTimeValue
        )}. Zoom is set to full width.`);
        this.gantt.setCurrentZoomedTimeSpan(new Date(minTimeValue), new Date(maxTimeValue));
      }
    }
  }

  /**
   * Applies settings of zoom and scroll rasterization to gantt diagram.
   * @param {SaxMsBestGanttZoomGridMode} mode Zoom grid mode.
   * @param {SaxMsBestGanttZoomGridStep} staticLevel Zoom grid level.
   * @param {number} customRefDate Grid reference date in milliseconds for customized grid.
   * @param {number} customStepWidth Grid step width in milliseconds for customized grid.
   */
  public changeZoomAndScrollRasterization(
    mode: SaxMsBestGanttZoomGridMode,
    staticLevel: SaxMsBestGanttZoomGridStep,
    customRefDate: number,
    customStepWidth: number
  ): void {
    switch (mode) {
      case SaxMsBestGanttZoomGridMode.AUTO:
        this.gantt.setAutomaticZoomAndScrollTimeGrid(true);
        break;
      case SaxMsBestGanttZoomGridMode.STATIC:
        this.gantt.setStaticZoomGrid(this.parseBackendZoomGridStepForGantt(staticLevel));
        if (staticLevel === SaxMsBestGanttZoomGridStep.CUSTOM) {
          this.gantt.setCustomZoomGridStepWidth(customRefDate, customStepWidth);
        }
        break;
      case SaxMsBestGanttZoomGridMode.OFF:
        this.gantt.setStaticZoomGrid('none');
        break;
    }
  }

  /**
   * Converts the zoom grid enums from backend to gantt enums.
   * @param {SaxMsBestGanttZoomGridStep} step Step enums.
   * @returns {string} Converted enum;
   */
  private parseBackendZoomGridStepForGantt(step: SaxMsBestGanttZoomGridStep): string {
    switch (step) {
      case SaxMsBestGanttZoomGridStep.HOURLY:
        return 'hour';
      case SaxMsBestGanttZoomGridStep.DAILY:
        return 'day';
      case SaxMsBestGanttZoomGridStep.WEEKLY:
        return 'week';
      case SaxMsBestGanttZoomGridStep.MONTHLY:
        return 'month';
      case SaxMsBestGanttZoomGridStep.QUARTERLY:
        return 'quarter';
      case SaxMsBestGanttZoomGridStep.CUSTOM:
        return 'customized';
      default:
        return 'none';
    }
  }

  /**
   * Changes name appereance of shift blocks.
   * @param strategy Select a number matching to strategy:
   * 0 - Names will be hidden if they are longer than the shift block.
   * 1 - Names will be cut by shift block width.
   * 2 - Names will be full shown, independent of block size.
   * 3 - Names will always be hidden.
   */
  public changeTextBuilderStrategy(strategy: EGanttTextStrategy): void {
    this.gantt.getShiftFacade().changeTextOverlayStrategy(strategy);
  }

  /* public positionXAxis(position: string) {
    this.gantt.positionXAxis(position);
  } */

  public positionXAxis(positionEnum: EGanttXAxisPosition = EGanttXAxisPosition.TOP): void {
    this.gantt.getConfig().setXAxisPosition(positionEnum);
  }

  public changeYAxisWidth(width: number): void {
    if (!width) {
      return;
    }
    this.gantt.getHTMLStructureBuilder().getSizeHandler().changeDivSize(width);
    this.gantt.update();
    this.gantt.updateYAxis();
  }

  public changeYAxisEntryColorization(yAxisEntryColorization: boolean): void {
    this.gantt.getConfig().yAxisEntryColorization = yAxisEntryColorization;
  }

  public changeSelectionColor(color: string) {
    this.gantt.getConfig().setColorSelect(color);
  }

  public changeSelectionOpacity(opacity: number) {
    this.gantt.getConfig().setColorSelectOpacity(opacity);
  }

  public changeTooltipDelay(delay: number) {
    this.gantt.getConfig().setTooltipDelay(delay);
  }

  public changeShowTooltip(show: boolean): void {
    this.gantt.allowTooltip(show);
  }

  /**
   * Toggles Holiday Rendering
   */
  public changeShowCalendarHolidays(boolean) {
    this.gantt.getConfig().setShowCalendarHolidays(boolean);
    this.gantt.getGlobalTimeSpanMarker().rebuild();
  }

  /**
   * Filters Holidays to only show those applying to the chosen federal state.
   */
  public changeCalendarFederalStateFilter(state) {
    this.gantt.getConfig().setCalenderSelectedFilterFederalState(state);
    this.gantt.getGlobalTimeSpanMarker().rebuild();
  }

  /**
   * Toggles time interval rendering.
   */
  public changeShowCalendarTimeIntervals(boolean: boolean): void {
    this.gantt.getConfig().setShowCalendarTimeIntervals(boolean);
    this.gantt.getGlobalTimeSpanMarker().rebuild();
  }

  public changeEdgePluginStrategy(strategy: EdgesPluginSettings) {
    this.gantt.getConfig().setEdgePluginRendering(strategy);
  }

  public setShowStickyRows(showStickyRows: boolean) {
    this.gantt.getConfig().setShowStickyRows(showStickyRows);
  }

  public changeTooltipOpacity(value: number) {
    this.gantt.getConfig().setTooltipOpacity(value);
  }

  private changeDefaultRowColor(color: string) {
    this.gantt.getConfig().setRowBackgroundColorDefault(color);
    this.gantt.getShiftFacade().updateCanvasBackgroundColor();
  }

  private changeTooltipScrollable(isScrollable: boolean) {
    this.gantt.getConfig().setTooltipScrollable(isScrollable);
  }

  private changeTooltipFontSize(fontSize: number) {
    this.gantt.getConfig().setTooltipFontSize(fontSize);
  }

  /**
   * Changes the visibility of gantt x axis.
   * @param isVisible true = visible
   */
  public changeXAxisVisibility(isVisible: boolean): void {
    this.gantt.getConfig().setXAxisVisible(isVisible);
  }

  /**
   * Changes the visibility of weekdays in the gantt x axis.
   * @param showWeekdays true = visible
   */
  public changeXAxisShowWeekdays(showWeekdays: boolean): void {
    this.gantt.getConfig().setXAxisShowWeekdays(showWeekdays);
  }

  /**
   * Function which bundles all settings integration functionality and updates gantt after adding settings.
   * @param settings Settings container.
   */
  public integrateByStyleSettingsContainer(settings: SaxMsBestGanttSettings, ignoreZoom?: boolean): void {
    if (!settings) return;
    this.changeRowHeight(settings.rowHeight);
    this.changeVerticalLinesVisibility(settings.showVerticalLines);
    this.changeVerticalLinesColor(
      settings.verticalLinesColor,
      settings.verticalLinesDailyColor,
      settings.verticalLinesMonthlyColor
    );
    this.changeVerticalLinesThickness(
      settings.verticalLinesThickness,
      settings.verticalLinesDailyThickness,
      settings.verticalLinesMonthlyThickness
    );
    this.changeHorizontalLinesColor(settings.horizontalLinesColor);
    this.changeHorizontalLinesThickness(settings.horizontalLinesThickness);
    this.changeShowHorizontalSplitLines(settings.showHorizontalSplitLines);
    this.changeHorizontalLinesVisibility(settings.showHorizontalLines);
    this.markOddRows('markOddRows', settings.colorizeRowSibling);
    this.colorRowHierarchie('rowColorHiearchie', settings.rowColorHierarchies);
    this.positionXAxis(settings.xAxisPosition);
    this.toggleRoundedCorners(settings.roundCorners);
    this.changeXAxisFormat(settings.xAxisMarkerFormat);
    this.toggleWeekends(settings.showWeekends);
    this.changeWeekendColor(settings.weekendColor);
    this.changeHolidayColor(settings.holidayColor);
    this.toggleShowSimilarShifts(settings.highlightSimilarShifts);
    this.toggleDelay(settings.tooltipDelay);
    this.changeRowFontSize(settings.fontSize);
    this.changeShiftFontSize(settings.fontSize);
    this.changeShiftTextColor(settings.textColorShifts);
    this.changeTextOverlayShadowMode(settings.textOverlayShadowMode);
    this.changeShiftPadding(settings.shiftPadding);
    this.changeTextBuilderStrategy(settings.labelMode);
    if (!ignoreZoom) {
      this.changeZoom(settings.scrollStart, settings.scrollEnd);
    }
    this.changeZoomAndScrollRasterization(
      settings.zoomGridMode,
      settings.zoomGridStep,
      settings.zoomGridRef,
      settings.zoomGridWidth
    );
    this.changeYAxisWidth(settings.ganttEntryWidth);
    this.changeYAxisEntryColorization(settings.ganttEntryColorization);
    this.changeSelectionColor(settings.selectColor);
    this.changeSelectionOpacity(settings.selectOpacity);
    this.changeShowCalendarHolidays(settings.calendar.showHolidays);
    this.changeCalendarFederalStateFilter(settings.calendar.selectedFilterFederalState);
    this.changeShowCalendarTimeIntervals(settings.calendar.showTimeIntervals);
    this.changeDefaultRowColor(settings.defaultRowColor);
    this.changeXAxisVisibility(settings.xaxisVisible);
    this.changeXAxisShowWeekdays(settings.xAxisShowWeekdays);

    this.changeTooltipOpacity(settings.tooltipOpacity);
    this.changeTooltipDelay(settings.tooltipDelayMS);
    this.changeShowTooltip(settings.showTooltip);
    this.changeTooltipScrollable(settings.scrollableTooltip);
    this.changeTooltipFontSize(settings.tooltipFontSize);

    // Plugin Settings
    this.changeEdgePluginStrategy(settings.ganttPluginSettings.edgePluginRendering);
    this.setShowStickyRows(settings.showStickyRows);

    this.gantt.rerenderShiftsVertical();
  }
}
