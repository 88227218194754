import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ISelectionBoxFenceCoordinates } from './selection-box/selection-box-fence-coordinates';

@Injectable()
export class ResourceCommunicationService {
  private _currentSelectedResources: string[] = []; // contains ids of selected resource elements
  private _lastClickedResourcePosition: number[] = [0, 0]; // [x,y]
  private _onSingleSelectionSubject: BehaviorSubject<string[]> = new BehaviorSubject(null);
  private _onMultiSelectionSubject: Subject<number[]> = new Subject();
  private _onHighlightSubject: Subject<string[]> = new Subject();
  private _onSelectionFenceSubject: Subject<ISelectionBoxFenceCoordinates> = new Subject();
  constructor() {}

  public listenForSingleSelection(): Observable<string[]> {
    return this._onSingleSelectionSubject.asObservable();
  }

  public listenForHighlight(): Observable<string[]> {
    return this._onHighlightSubject.asObservable();
  }

  public listenForMultiSelection(): Observable<number[]> {
    return this._onMultiSelectionSubject.asObservable();
  }

  public listenForSelectionFence(): Observable<ISelectionBoxFenceCoordinates> {
    return this._onSelectionFenceSubject.asObservable();
  }

  public emitSingleSelection(): void {
    this._onSingleSelectionSubject.next(this._currentSelectedResources);
  }

  public emitHighlight(resourceIds: string[]): void {
    this._onHighlightSubject.next(resourceIds);
  }

  public emitMultiSelection(pos: number[]): void {
    this._onMultiSelectionSubject.next(pos);
  }

  public emitSelectionFence(fence: ISelectionBoxFenceCoordinates): void {
    this._onSelectionFenceSubject.next(fence);
  }

  public addToSelectedResources(resourceId: string): void {
    this._currentSelectedResources.push(resourceId);
  }

  public removeFromSelectedResources(resourceId: string): void {
    this._currentSelectedResources = this.currentSelectedResources.filter((id) => resourceId !== id);
  }

  public clearSelectedResources(): void {
    this._currentSelectedResources = [];
    this._lastClickedResourcePosition = [0, 0];
  }

  get currentSelectedResources(): string[] {
    return this._currentSelectedResources;
  }

  set lastClickedResourcePosition(pos: number[]) {
    if (pos.length !== 2) {
      return;
    }
    this._lastClickedResourcePosition = pos;
  }

  get lastClickedResourcePosition(): number[] {
    return this._lastClickedResourcePosition;
  }
}
