import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ERequestMethod, RequestOptions, RequestService } from '@app-modeleditor/request.service';
import { MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { InteractionType } from '@azure/msal-browser';
import { ConfigService } from '@core/config/config.service';
import { Store } from '@ngxs/store';
import { WebStorageService } from 'frontend/src/dashboard/core/web-storage.service';
import { BehaviorSubject, from, Observable, of, Subject } from 'rxjs';
import { distinctUntilChanged, finalize, map, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { AuthActions } from '../../login/data-access/auth.actions';
import { UserCredentials } from '../../login/data-access/login.service';
import { Credentials } from './credentials';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private readonly msalService: MsalService = inject(MsalService);
  private readonly msalGuardConfig: MsalGuardConfiguration = inject<MsalGuardConfiguration>(MSAL_GUARD_CONFIG);

  private authUrl = 'rest/oauth2/token/';
  private credentials: BehaviorSubject<Credentials> = new BehaviorSubject<Credentials>(null);
  private refresh: Subject<Credentials> = new Subject<Credentials>();
  private autoRefresh: boolean;
  private override: Subject<Credentials> = new Subject<Credentials>();
  private refreshing: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private configApi: ConfigService,
    private requestApi: RequestService,
    private storageApi: WebStorageService,
    private store: Store,
    private http: HttpClient
  ) {
    this.enableAutoRefresh();
    this.init().subscribe();
  }

  // sso logout
  // @todo rename function
  logout(): Observable<void> {
    const account = this.msalService.instance.getActiveAccount();
    sessionStorage.removeItem('msal.interaction.status');
    if (account) {
      if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
        return from(this.msalService.instance.logoutPopup({ account, postLogoutRedirectUri: '/' })).pipe(tap(() => {}));
      }
    }

    return of(void 0);
  }

  public disableAutoRefresh(): void {
    this.autoRefresh = false;
  }

  public enableAutoRefresh(): void {
    this.autoRefresh = true;
  }

  public onRefresh(): Observable<Credentials> {
    return this.refresh.asObservable();
  }

  /**
   * whether the service is refreshing or not
   * @returns Observable<boolean>
   */
  public isRefreshing(): Observable<boolean> {
    return this.refreshing.asObservable();
  }

  /**
   * set refresh state
   * @param {boolean} state state of refreshing
   * @returns void
   */
  private setRefreshing(state: boolean): void {
    this.refreshing.next(state);
  }

  /**
   * returns currentcredentials
   * @returns Credentials
   */
  public getCurrentCredentials(): Credentials {
    return this.credentials.value || new Credentials();
  }

  /**
   * get current credentials
   * @returns Observable<Credentials>
   */
  public getCredentials(): Observable<Credentials> {
    return this.credentials.asObservable().pipe(distinctUntilChanged(), shareReplay({ bufferSize: 1, refCount: true }));
  }

  /**
   * set credentials
   * @returns Observable<Credentials>
   */
  public setCredentials(credentials: Credentials): Observable<Credentials> {
    return this.storageApi
      .addItem('credentials', credentials && credentials.getAccessToken() ? credentials.serialize() : null)
      .pipe(
        map(() => {
          this.credentials.next(credentials);
          return credentials;
        })
      );
  }

  private init(): Observable<Credentials> {
    return of(null);
    return this.storageApi
      .getItem('credentials')
      .pipe(map((cred: any) => this.parseCredentials(cred)))
      .pipe(switchMap((credentials: Credentials) => this.setCredentials(credentials)));
  }

  public parseCredentials(data: { [key: string]: any }): Credentials {
    if (!data) {
      return null;
    }
    return new Credentials()
      .setPasswordExpired(data.passwordExpired)
      .setUserId(data.user_id || data.userId)
      .setRefreshToken(data.refresh_token || data.refreshToken)
      .setAccessToken(data.access_token || data.accessToken);
  }

  public softLogout(token: string): void {
    this.setCredentials(this.credentials.getValue().setAccessToken(null));
  }

  /**
   * sign in
   * @param username username
   * @param password password
   * @param origin origin
   */
  public signIn(username: string, password: string, origin?: string): Observable<Credentials> {
    const loginConf: any = this.configApi.access().login;

    let body = new HttpParams()
      .set('grant_type', 'password')
      .set('username', username)
      .set('password', loginConf.urlEncodingPassword ? encodeURIComponent(password) : password);

    if (origin) {
      body = body.set('caller-origin', origin);
    }

    return this.requestApi
      .call(ERequestMethod.POST, this.authUrl, new RequestOptions().setHttpOptions({ body: body }))
      .pipe(
        switchMap((credentials: { [key: string]: any }) => {
          return this.setRefresh(this.parseCredentials(credentials));
        })
      );
  }

  setRefresh(credentials: Credentials): Observable<Credentials> {
    return this.setCredentials(credentials).pipe(tap((credentials: Credentials) => this.refresh.next(credentials)));
  }

  /**
   * sign out
   */
  public signOut(): Observable<void> {
    return this.setCredentials(null).pipe(
      map(() => void 0),
      finalize(() => {
        this.store.dispatch(new AuthActions.Logout());
        // location.reload();
        // console.log(this.activatedRoute)
      })
    );
  }

  public singleSignOn(cred) {
    return this.http.post<UserCredentials>(`rest/oauth2/token/external`, cred);
  }

  public onOverrideCredentials(): Observable<Credentials> {
    return this.override.asObservable();
  }

  public overrideCredentials(credentials: Credentials): Observable<Credentials> {
    return this.setCredentials(credentials).pipe(tap(() => this.override.next(credentials)));
  }

  //
  /**
   * try to refresh token
   */
  public refreshToken(token?: string): Observable<Credentials> {
    if (this.autoRefresh === true) {
      const body = new HttpParams()
        .set('grant_type', 'refresh_token')
        .set('client_id', this.getCurrentCredentials().getUserId())
        .set('refresh_token', token ?? this.getCurrentCredentials().getRefreshToken());

      return this.requestApi
        .call(ERequestMethod.POST, this.authUrl, new RequestOptions().setHttpOptions({ body: body }))
        .pipe(switchMap((credentials: { [key: string]: any }) => this.setRefresh(this.parseCredentials(credentials))));
    }

    this.setRefreshing(true);
    return this.onOverrideCredentials().pipe(
      take(1),
      finalize(() => this.setRefreshing(false))
    );
  }
}
