import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TimelineModule } from '@app-modeleditor/components/timeline/timeline/timeline.module';
import { SaxmsTooltipModule } from '@app-modeleditor/components/tooltip/tooltip.module';
import { SaxmsLightboxModule } from '@app-modules/lightbox-module/lightbox.module';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../../core/material/material.module';
import { ExperimentDialogComponent } from './dialogs/experimentDialog/experimentDialog.component';
import { ExperimentComponent } from './experiment.component';
import { ListElementTemplateComponent } from './listElementTemplate/listElementTemplate.component';

@NgModule({
  imports: [MaterialModule, CommonModule, TranslateModule, TimelineModule, SaxmsLightboxModule, SaxmsTooltipModule],

  declarations: [ExperimentComponent, ListElementTemplateComponent, ExperimentDialogComponent],
  exports: [ExperimentComponent],
})
export class ExperimentModule {}
