import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { SlotModule } from '@app-modeleditor/components/template/slot/slot.module';
import { TranslateModule } from '@ngx-translate/core';
import { SaxMsGanttModule } from 'frontend/src/dashboard/gantt/gantt/saxms-best-gantt.module';
import { MySharedModule } from 'frontend/src/dashboard/shared/shared.module';
import { Gantt_General } from './general.gantt.component';
@NgModule({
  imports: [
    CommonModule,
    SaxMsGanttModule,
    MatExpansionModule,
    MatDividerModule,
    MySharedModule,
    SlotModule,
    TranslateModule,
  ],
  declarations: [Gantt_General],
  providers: [DatePipe],
  exports: [Gantt_General],
})
export class GanttGeneralModule {}
