import { BestGantt } from '../../main';
import { MainShiftClickEventCallbacks } from './shift-click-event-callbacks';
import { MainShiftMouseHoverCallbacks } from './shift-mouse-hover-callbacks';
import { MainShiftScrollEventCallbacks } from './shift-scroll-event-callbacks';

export class MainShiftCallbacks {
  constructor(private _ganttDiagram: BestGantt) {}

  /**
   * Adds shift callbacks.
   */
  public initCallbacks(): void {
    new MainShiftScrollEventCallbacks(this._ganttDiagram).initCallbacks();
    new MainShiftMouseHoverCallbacks(this._ganttDiagram).initCallbacks();
    new MainShiftClickEventCallbacks(this._ganttDiagram).initCallbacks();
  }
}
