import { takeUntil } from 'rxjs';
import { GanttActionWrapper } from '../../external-action-registration';
import { GanttEventTrigger } from '../../external-event-trigger';
import { EGanttActionEvent } from '../gantt-action-event.enum';
import { IGanttExternalDropElement } from './external-drop-element.interface';

/**
 * Event which will be fired if an external element was dropped in gantt.
 */
export class GanttExternalDropEvent extends GanttEventTrigger {
  public readonly eventName: EGanttActionEvent = EGanttActionEvent.EXTERNAL_DROP;

  /**
   * Registers external drop event.
   */
  public registerInGantt(): void {
    this._ganttLibService
      .listenForExternalDropEvent()
      .pipe(takeUntil(this.onRemoveFromGantt))
      .subscribe((externalDropElement: IGanttExternalDropElement) => {
        this.templateData.setSelectedValuesAndResources(externalDropElement);
        const actionWrapper: GanttActionWrapper = {
          actionScope: this.action.actionScope,
          actions: this.action.actions,
        };
        this._ganttLibService.ngZone.run(() => {
          this.actionHandler.executeActions(actionWrapper).subscribe((data) => {
            this.responseHandler.handleResponse(data as any);
          });
        });
      });
  }

  /**
   * Removes Callback from gantt.
   * @param ganttDiagram BestGantt instance to remove callback from.
   */
  public removeFromGantt(): void {
    super.removeFromGantt();
  }
}
