import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ChartLegendItem } from '../chart-legend-item';
import { ChartAttributeLegend } from '../chart-legend/chart-attribute-legend';

@Component({
  selector: 'app-chart-legend-attribute',
  templateUrl: './chart-legend-attribute.component.html',
  styleUrls: ['./chart-legend-attribute.component.scss'],
})
export class ChartLegendAttributeComponent implements OnInit {
  @ViewChild('activeAttributes') activeAttributes: ElementRef;
  @Input() templateNode: ChartAttributeLegend;

  constructor(public translate: TranslateService) {}

  ngOnInit() {}

  public getActiveItems() {
    return (
      this.templateNode.getLegendItemPlainList().filter((value) =>
        value.getParentItem()
          ? value.isActive() &&
            value
              .getParentItem()
              .getAvailableValues()
              .filter((child) => !child.isActive()).length !== 0
          : (!this.allChildActive(value) && this.childActiveCount(value) > 0) || !this.allChildActive(value)
      ) || []
    );
  }

  public allChildActive(item: ChartLegendItem) {
    return this.childActiveCount(item) === item.getAvailableValues().length;
  }
  public allChildDeactive(item: ChartLegendItem) {
    return this.childActiveCount(item) === 0;
  }

  public childActiveCount(item: ChartLegendItem) {
    return item.getAvailableValues().filter((child) => child.isActive()).length;
  }

  public handleAttributeClick(item: ChartLegendItem): void {
    if (item.getParentItem()) {
      item.toogleActive();
      this.templateNode.executeChanges(item);
    } else {
      this.handleToogleAttributeGroup(
        this.templateNode.getLegendValues().find((value) => value.getId() === item.getId())
      );
    }
  }

  public removeAllAttributes() {
    this.templateNode.executeRemoveAll();
  }

  public addAllAttributes() {
    // this.getHeightOfAllAttributes();
    this.templateNode.executeAddAll();
  }

  public allAttributeActive() {
    return (
      this.templateNode
        .getLegendItemPlainList()
        .filter((value) => !value.isActive() && value.getAvailableValues().length === 0).length === 0
    );
  }

  public handleToogleAttributeGroup(attributeGroup: ChartLegendItem) {
    if (attributeGroup.getAvailableValues().length > 0) {
      attributeGroup.getAvailableValues().forEach((item) => {
        item.toogleActive();
      });
    }
    this.templateNode.executeChanges(attributeGroup);
  }

  public handleActiveAttributeGroup(attributeGroup: ChartLegendItem) {
    if (attributeGroup.getAvailableValues().length > 0) {
      attributeGroup.getAvailableValues().forEach((item) => {
        item.setActive(true);
      });
    }
    this.templateNode.executeChanges(attributeGroup);
  }
}
