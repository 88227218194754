<div class="message" [ngClass]="message.orientation">
  <div class="settings-button-background" *ngIf="message.orientation === messageOrientation.RIGHT"></div>
  <button
    class="settings-button"
    mat-icon-button
    [matMenuTriggerFor]="messageMenu"
    *ngIf="message.orientation === messageOrientation.RIGHT && (showEdit || showDelete) && !message.deleted"
  >
    <mat-icon class="settings-icon">more_vert</mat-icon>
  </button>
  <div class="sender" [ngClass]="message.orientation">{{ message.sender }}</div>
  <div class="content">
    <p *ngIf="!message.deleted && !isEditModeActive">{{ message.content }}</p>
    <p class="deletedMessage" *ngIf="message.deleted">{{ 'MESSAGE.deleted' | translate }}</p>
    <mat-form-field *ngIf="isEditModeActive" appearance="fill">
      <!-- <mat-label>{{'MESSAGE.edit'|translate}}</mat-label> -->
      <textarea #textArea class="edit-text-box" cdkTextareaAutosize matInput>{{ message.content }}</textarea>
      <button
        mat-icon-button
        (click)="discardEdit()"
        class="edit-message-discard-btn"
        [matTooltip]="'BUTTON.discard' | translate"
      >
        <mat-icon>close</mat-icon>
      </button>
      <button
        mat-icon-button
        (click)="saveEdit(textArea.value)"
        class="edit-message-save-btn"
        [matTooltip]="'BUTTON.save' | translate"
      >
        <mat-icon>done</mat-icon>
      </button>
    </mat-form-field>
  </div>
  <div class="timestamp">{{ message.timestamp | date : ('DATE.FORMATS.DATETIME' | translate) }}</div>
  <div class="edited" *ngIf="message.edited">{{ 'MESSAGE.edited' | translate }}</div>
</div>

<mat-menu #messageMenu="matMenu">
  <button *ngIf="showEdit" mat-menu-item (click)="activateEditMode()">
    <mat-icon>edit</mat-icon>
    <span>{{ 'MESSAGE.edit' | translate }}</span>
  </button>
  <button *ngIf="showDelete" mat-menu-item class="delete" (click)="deleteMessage()">
    <mat-icon class="delete">delete</mat-icon>
    <span>{{ 'MESSAGE.delete' | translate }}</span>
  </button>
</mat-menu>
