import * as d3 from 'd3';
import { Observable, Subject, takeUntil } from 'rxjs';
import { GanttCallBackStackExecuter } from '../../callback-tools/callback-stack-executer';
import { YAxisDataFinder } from '../../data-handler/data-finder/yaxis-data-finder';
import { GanttDataRow, GanttDataShift } from '../../data-handler/data-structure/data-structure';
import { GanttUtilities } from '../../gantt-utilities/gantt-utilities';
import { GanttScrollContainerEvent } from '../../html-structure/scroll-container-event';
import { EGanttScrollContainer } from '../../html-structure/scroll-container.enum';
import {
  ElementSelectorProportions,
  SelectionBoxDragEvent,
  SelectionBoxParameters,
} from '../../selector/element-selector';
import { TimeFormatterAxisFormat } from '../../x-axis/axis-formats/x-axis-format-general';
import { BestGanttPlugIn } from '../gantt-plug-in';

/**
 * Creates new shift in gantt diagram by dragging a rect.
 * @keywords executer, create, creator, shift
 * @plugin shift-creator
 * @class
 * @constructor
 * @extends BestGanttPlugIn
 *
 * @requires BestGanttPlugIn
 */
export class GanttShiftCreator extends BestGanttPlugIn {
  private _isInCreationMode: boolean;
  selectionBoxColor: string;
  createShiftEntry: boolean;
  selectionBoxRules: SelectionBoxParameters;

  private _onCreateShiftModeEndSubject: Subject<void> = new Subject<void>();

  callBack: any;

  constructor() {
    super(); // call super-constructor
    /**
     * @type {BestGantt}
     */
    this.ganttDiagram = null;
    this._isInCreationMode = false;
    this.selectionBoxColor = 'blue';
    this.createShiftEntry = false;
    this.selectionBoxRules = this._createSelectionBoxRules();

    this.callBack = {
      dragStart: {},
      dragEnd: {},
    };
  }

  /**
   * @override
   */
  initPlugIn(ganttDiagram) {
    this.ganttDiagram = ganttDiagram;
  }

  public removePlugIn(): void {
    this._onCreateShiftModeEndSubject.next();
    this._onCreateShiftModeEndSubject.complete();
  }

  toggleCreateShiftMode() {
    if (this._isInCreationMode) {
      this.endCreateShiftMode();
    } else {
      this.startCreateShiftMode();
    }
  }

  /**
   * Starts the shift creation mode.
   */
  startCreateShiftMode() {
    if (this._isInCreationMode) return;
    this._isInCreationMode = true;
    this._updateBoxRules();
    this._registerCallbacks();
  }

  /**
   * Closes the shift creation mode.
   */
  endCreateShiftMode() {
    if (!this._isInCreationMode) return;
    this._isInCreationMode = false;

    this._clearSelectionBoxRules();
    this._onCreateShiftModeEndSubject.next();
  }

  /**
   * Registers all necessary callbacks.
   */
  private _registerCallbacks(): void {
    this.ganttDiagram
      .getSelectionBoxFacade()
      .onDragStart.pipe(takeUntil(this.onCreateShiftModeEnd))
      .subscribe((event) => this._selectionBoxStart(event));
    this.ganttDiagram
      .getSelectionBoxFacade()
      .afterSelection.pipe(takeUntil(this.onCreateShiftModeEnd))
      .subscribe((event) => this._selectionBoxEnd(event));
    this.ganttDiagram
      .getXAxisBuilder()
      .onZoomEnd.pipe(takeUntil(this.onCreateShiftModeEnd))
      .subscribe(() => this._updateBoxRules());
  }

  private _updateBoxRules(): void {
    const currentTimeFormats = this.ganttDiagram.getXAxisBuilder().getCurrentTimeFormats();
    this.selectionBoxRules.timeGradation.unit = this._getTimeGradationUnit(
      currentTimeFormats[currentTimeFormats.length - 1]
    );
    this.ganttDiagram.getSelectionBoxFacade().setBoxRules(this.selectionBoxRules);
  }

  /**
   * Converts the time format of the x-axis into a format that can be processed by the TimeGradationHandler.
   * Presets custom time grid.
   * @param {TimeFormatterAxisFormat} timeFormat
   * @returns
   */
  private _getTimeGradationUnit(timeFormat: TimeFormatterAxisFormat) {
    const s = this;
    const ticksStepSize = s._getTimeFormatTicksStepSize(timeFormat);

    switch (timeFormat.unit) {
      case 'YEAR':
        console.warn('Not supported time format!');
        s.selectionBoxRules.timeGradation.unit = 'month';
        return 'month';
      case 'MONTH':
        if (ticksStepSize === 3) {
          s.selectionBoxRules.timeGradation.unit = 'quarter';
          return 'quarter';
        } else if (ticksStepSize === 1) {
          s.selectionBoxRules.timeGradation.unit = 'month';
          return 'month';
        } else {
          console.warn('Not supported time format!');
          s.selectionBoxRules.timeGradation.unit = 'hour';
          return 'hour';
        }
      case 'CALENDAR_WEEK':
        if (ticksStepSize === 1) {
          s.selectionBoxRules.timeGradation.unit = 'week';
          return 'week';
        } else {
          console.warn('Not supported time format!');
          s.selectionBoxRules.timeGradation.unit = 'hour';
          return 'hour';
        }
      case 'DAY':
        if (ticksStepSize === 1) {
          s.selectionBoxRules.timeGradation.unit = 'day';
          return 'day';
        } else {
          s.selectionBoxRules.timeGradation.customStepSize = ticksStepSize * 86400000;
          s.selectionBoxRules.timeGradation.unit = 'customized';
          return 'customized';
        }
      case 'HOUR':
        if (ticksStepSize === 1) {
          s.selectionBoxRules.timeGradation.unit = 'hour';
          return 'hour';
        } else {
          s.selectionBoxRules.timeGradation.customStepSize = ticksStepSize * 3600000;
          s.selectionBoxRules.timeGradation.unit = 'customized';
          return 'customized';
        }
      case 'MINUTE':
        if (ticksStepSize === 1) {
          s.selectionBoxRules.timeGradation.unit = 'minute';
          return 'minute';
        } else {
          s.selectionBoxRules.timeGradation.customStepSize = ticksStepSize * 60000;
          s.selectionBoxRules.timeGradation.unit = 'customized';
          return 'customized';
        }
      case 'SECOND':
        s.selectionBoxRules.timeGradation.customStepSize = ticksStepSize * 1000;
        s.selectionBoxRules.timeGradation.unit = 'customized';
        return 'customized';
      default:
        return 'hour';
    }
  }

  /**
   * Calculates the step value of a given time format considering the unit.
   * @param {TimeFormatterAxisFormat} timeFormat Time format for calculation.
   * @returns {number} Calculated step value or null when error.
   */
  private _getTimeFormatTicksStepSize(timeFormat: TimeFormatterAxisFormat): number {
    const now = new Date();
    const ticksStepSizeMs = timeFormat.ticks.ceil(now).getTime() - timeFormat.ticks.floor(now).getTime();

    switch (timeFormat.unit) {
      case 'MONTH':
        const lengthMonthMax = 2678400000; // 31 days in ms
        return Math.round(ticksStepSizeMs / lengthMonthMax);
      case 'CALENDAR_WEEK':
        return ticksStepSizeMs / (d3.timeWeek.ceil(now).getTime() - d3.timeWeek.floor(now).getTime());
      case 'DAY':
        return ticksStepSizeMs / (d3.timeDay.ceil(now).getTime() - d3.timeDay.floor(now).getTime());
      case 'HOUR':
        return ticksStepSizeMs / (d3.timeHour.ceil(now).getTime() - d3.timeHour.floor(now).getTime());
      case 'MINUTE':
        return ticksStepSizeMs / (d3.timeMinute.ceil(now).getTime() - d3.timeMinute.floor(now).getTime());
      case 'SECOND':
        return ticksStepSizeMs / (d3.timeSecond.ceil(now).getTime() - d3.timeSecond.floor(now).getTime());
      default:
        // unknown unit or calculation not neccesary
        return null;
    }
  }

  /**
   * Clears the selection box rules.
   * @private
   */
  private _clearSelectionBoxRules() {
    this.ganttDiagram.getSelectionBoxFacade().clearSelectionBoxRules();
  }

  /**
   * Is called on selection box drag start.
   * @param event
   */
  private _selectionBoxStart(event: GanttScrollContainerEvent<SelectionBoxDragEvent>): void {
    const rowCanvasData = this.ganttDiagram
      .getRenderDataHandler()
      .getStateStorage()
      .getRowByYPosition(event.event.selectionBoxProportions.topLeft[1], event.source);

    this.selectionBoxRules.height.min = rowCanvasData.height;
    this.selectionBoxRules.height.max = rowCanvasData.height;
    this.ganttDiagram.getSelectionBoxFacade().setBoxRules(this.selectionBoxRules);
  }

  /**
   * Is called on selection box drag start.
   * @param event
   */
  private _selectionBoxEnd(event: GanttScrollContainerEvent<SelectionBoxDragEvent>): void {
    if (event.event.selectionBoxProportions.topLeft[0] - event.event.selectionBoxProportions.bottomRight[0] === 0) {
      // shift has no width
      this.endCreateShiftMode();
      GanttCallBackStackExecuter.execute(this.callBack.dragEnd, null);
      return;
    }
    this._deselectAllShifts();
    const shiftToCreate = this._createNewShift(event.event.selectionBoxProportions);
    const targetRow = this._getTargetRow(event.event.selectionBoxProportions, event.source);

    if (targetRow && this.createShiftEntry) {
      targetRow.shifts.push(shiftToCreate);
      this.ganttDiagram.update();
    }

    const data = {
      shiftData: shiftToCreate,
      rowData: targetRow,
    };
    // execute callbacks
    GanttCallBackStackExecuter.execute(this.callBack.dragEnd, data);
  }

  /**
   * Deselect all shifts.
   */
  private _deselectAllShifts() {
    this.ganttDiagram
      .getSelectionBoxFacade()
      .deselectAllShifts(
        this.ganttDiagram.getDataHandler().getCanvasShiftDataset(),
        this.ganttDiagram.getDataHandler().getOriginDataset().ganttEntries
      );
    this.ganttDiagram.rerenderShiftsVertical();
  }

  /**
   * Creates a new shift with data of the selection box size.
   * @private
   * @param {any} selectionBoxSize Selection box proportions
   */
  private _createNewShift(selectionBoxSize: ElementSelectorProportions) {
    const startDate =
      selectionBoxSize.timeSpan[0] < selectionBoxSize.timeSpan[1]
        ? selectionBoxSize.timeSpan[0]
        : selectionBoxSize.timeSpan[1];
    const endDate =
      selectionBoxSize.timeSpan[0] > selectionBoxSize.timeSpan[1]
        ? selectionBoxSize.timeSpan[0]
        : selectionBoxSize.timeSpan[1];

    const shiftToCreate: GanttDataShift = {
      id: GanttUtilities.generateUniqueID(),
      name: null,
      originName: '',
      color: '#000000',
      timePointStart: new Date(startDate),
      timePointEnd: new Date(endDate),
      tooltip: null,
      firstColor: null,
      secondColor: null,
      noRender: null,
      highlighted: null,
      editable: null,
      additionalData: null,
      modificationRestriction: null,
      opacity: null,
      weaken: null,
      symbols: null,
    };
    shiftToCreate.name = shiftToCreate.id; // set name to id

    return shiftToCreate;
  }

  /**
   * Extracts the target row by selection box position.
   * @param selectionBoxProportions Selection box proportions
   * @param scrollContainerId
   */
  private _getTargetRow(
    selectionBoxProportions: ElementSelectorProportions,
    scrollContainerId: EGanttScrollContainer
  ): GanttDataRow {
    const y = d3.min([selectionBoxProportions.topLeft[1], selectionBoxProportions.bottomRight[1]]);
    const targetRowId = this.ganttDiagram
      .getRenderDataHandler()
      .getStateStorage()
      .getRowIdByYPosition(y, scrollContainerId);
    const targetRow = YAxisDataFinder.getRowById(
      this.ganttDiagram.getDataHandler().getOriginDataset().ganttEntries,
      targetRowId
    ).data;
    return targetRow;
  }

  /**
   * Creates and returns an instance of selection box rules.
   * @returns {SelectionBoxParameters}
   */
  private _createSelectionBoxRules() {
    const selectionBoxRules = new SelectionBoxParameters();
    selectionBoxRules.color = this.selectionBoxColor;
    selectionBoxRules.rasterRow = true;
    selectionBoxRules.timeGradation.unit = 'hour';
    selectionBoxRules.timeGradation.customStepSize = 0;
    return selectionBoxRules;
  }

  //
  // OBSERVABLES
  //

  private get onCreateShiftModeEnd(): Observable<void> {
    return this._onCreateShiftModeEndSubject.asObservable();
  }

  //
  // CALLBACKS
  //

  onDragEndCallback(id, func) {
    this.callBack.dragEnd[id] = func;
  }

  onDragEndCallbackRemove(id) {
    delete this.callBack.dragEnd[id];
  }

  isInCreationMode() {
    return this._isInCreationMode;
  }
}
