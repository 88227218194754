import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { MatLegacySliderChange as MatSliderChange } from '@angular/material/legacy-slider';
import { TextOverlayShadowMode } from '@gantt/lib/best_gantt/script/config/config-data/text-overlay-config.data';
import { EGanttTextStrategy } from '@gantt/public-api';
import { SaxMsBestGanttSettings } from 'frontend/src/dashboard/gantt/gantt/saxms-best-gantt.settings';

interface IBlockLabelOptions {
  value: EGanttTextStrategy;
  viewValue: string;
}

interface ILowContrastBehaviorOptions {
  value: TextOverlayShadowMode;
  viewValue: string;
}

@Component({
  selector: 'app-labels',
  templateUrl: './labels.component.html',
  styleUrls: ['./labels.component.scss'],
})
export class LabelsComponent implements OnInit {
  @Input() settings: SaxMsBestGanttSettings;
  @Output() changeEvent: EventEmitter<null> = new EventEmitter();

  public blockLabelsOptions: IBlockLabelOptions[] = [
    { value: EGanttTextStrategy.SHOW_NEVER_LABELS, viewValue: '@show-never-labels@' },
    // { value: EGanttTextStrategy.HIDE_OVERLAPPING_LABELS, viewValue: '@hide-overlapping-labels@' },
    { value: EGanttTextStrategy.CUT_OFF_LABELS, viewValue: '@cut-off-labels@' },
    { value: EGanttTextStrategy.CUT_OFF_LABELS_MULTIPLE_LINES, viewValue: '@cut-off-labels-multiple-lines@' },
    { value: EGanttTextStrategy.SHOW_ALWAYS_LABELS, viewValue: '@show-always-labels@' },
    { value: EGanttTextStrategy.SHOW_ALWAYS_LABELS_SPLIT, viewValue: '@show-always-labels-split@' },
  ];

  public lowContrastBehaviorOptions: ILowContrastBehaviorOptions[] = [
    { value: TextOverlayShadowMode.NONE, viewValue: '@off@' },
    { value: TextOverlayShadowMode.DROP_SHADOW, viewValue: 'GANTT.show-drop-shadow' },
    { value: TextOverlayShadowMode.CONTOUR, viewValue: 'GANTT.show-contour' },
  ];

  public selectedBlockLabelOption: EGanttTextStrategy;
  public selectedLowContrastBehaviorOption: TextOverlayShadowMode;

  constructor(private cdRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.selectedBlockLabelOption = this.settings.labelMode || EGanttTextStrategy.CUT_OFF_LABELS;
    this.selectedLowContrastBehaviorOption = this.settings.textOverlayShadowMode || TextOverlayShadowMode.DROP_SHADOW;
  }

  public onBlockLabelSelectionChange(event: MatSelectChange) {
    this.settings.labelMode = event.value;
    this.changeEvent.emit();
  }

  public onLowContrastBehaviorOptionChange(event: MatSelectChange) {
    this.settings.textOverlayShadowMode = event.value;
    this.changeEvent.emit();
  }

  public setBlockLabelColor(color: string) {
    this.settings.textColorShifts = color;
    this.changeEvent.emit();
  }

  public fontSizeSliderChange(event: MatSliderChange) {
    this.settings.fontSize = event.value;
    this.cdRef.detectChanges();
    this.changeEvent.emit();
  }
}
