import { Injectable, Type } from '@angular/core';
import { StatusIcon } from '@app-modeleditor/components/template-statebar/status-icon';
import { TemplateAdapter } from '@app-modeleditor/utils/template-factory.service';
import { Adapter } from '../../../core/adapter';
import { TemplateResourceAdapter } from './../../utils/template-resource-factory.service';
import { StatusBar } from './status-bar';

@Injectable({
  providedIn: 'root',
})
export class StatusBarIconAdapter implements Adapter<StatusIcon> {
  constructor(private _templateResourceAdapter: TemplateResourceAdapter) {}
  adapt(item: any): StatusIcon {
    throw new Error('Method not implemented.');
  }
  inherit?<T extends StatusIcon>(type: Type<T>, item: any): T {
    const t: T = this._getData(this._templateResourceAdapter.inherit<T>(type, item), item);
    return t;
  }
  inheritFrom?<T extends StatusIcon>(scope: TemplateAdapter, type: Type<T>, item: any): T {
    const t: T = this._getData(this._templateResourceAdapter.inheritFrom<T>(scope, type, item), item, scope);
    return t;
  }

  applyValues<T>(scope: TemplateAdapter, item: StatusBar, values: any): T {
    if (!values) {
      return;
    }

    item.setValues(
      item.getStatusIcons().map((icon: StatusIcon) => {
        const entry = values[icon.getId()];
        if (!entry) {
          return icon;
        }
        icon
          .setId(entry.id)
          .setTooltip(entry.tooltip || icon.getTooltip())
          .setNotificationCounter(isNaN(entry.counter) ? icon.getColor() : entry.counter)
          .setColor(entry.color || icon.getColor())
          .setIcon(entry.icon || icon.getIcon())
          .setElementId(`${icon.getId()}_${item.getId()}`);
        return icon;
      })
    );
    return;
  }

  private _getData<T extends StatusIcon>(elem: T, item: any, scope?: TemplateAdapter): T {
    elem
      .setIcon(item.icon)
      .setNotificationCounter(item.notificationCounter)
      .setColor(item.color)
      .setTooltip(item.tooltip)
      .setIcon(item.icon);

    return elem;
  }
}
