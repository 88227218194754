<div class="node-wrapper" [ngClass]="classList">
  <div class="inner-node-wrapper" #innerNodeWrapper [class.has-border]="mode !== 'tree'">
    <button
      [disabled]="hasPendingActions$ | async"
      #treeNodeButton
      [id]="id || node.getId()"
      class="tree-node-btn"
      [class.tree-button]="mode !== 'tree'"
      mat-flat-button
      [class.selected]="selected"
      (click)="onItemClick($event, node)"
      (contextmenu)="onContextMenu($event, node)"
    >
      <ng-container *ngIf="mode === 'tree'; else defaultNode">
        <ng-container *ngTemplateOutlet="notificationCounter; context: { $implicit: node }"></ng-container>
        <app-image *ngIf="node.getIcon()" [label]="node.getIcon()"></app-image>
        <span [saxmsTooltip]="node.getName()">{{ node.getName() }}</span>
      </ng-container>
      <ng-template #defaultNode>
        <ng-container>
          <app-image
            #icoLabel
            *ngIf="node.getIcon()"
            class="minimal-icon"
            [label]="node.getIcon()"
            style="float: left; margin-top: -2px; margin-right: 5px"
          >
          </app-image>
          <div class="headline">
            <div #textContainer [saxmsTooltip]="node.getName()" class="tooltip-wrapper title col-50 text">
              <ng-container *ngTemplateOutlet="notificationCounter; context: { $implicit: node }"></ng-container>
              <ng-container *ngFor="let ico of node.getIcons()">
                <app-image [color]="ico.color" class="minimal-icon small-text" [label]="ico.icon"> </app-image>
              </ng-container>
              <b>{{ node.getName() }}</b>
            </div>
            <span
              [saxmsTooltip]="node.getCreated() | date : 'dd.MM.yyyy HH:mm'"
              class="date-text col-50 text date"
              *ngIf="node.getCreated()"
              >{{ node.getCreated() | date : ('DATE.FORMATS.DATETIME' | translate) }}</span
            >
          </div>
          <div #lineRef class="line-wrapper" [style.height]="node.getRowCount() * 15 + 'px'">
            <div [saxmsTooltip]="d" class="line text" *ngFor="let d of node.getAdditionalDisplayElements()">
              {{ d }}
            </div>
            <div *ngIf="node.getTenantName()" class="tenant-name">
              <span class="tenant-badge mat-elevation-z2">{{ node.getTenantName() }}</span>
            </div>
          </div>
        </ng-container>
      </ng-template>
    </button>

    <app-filterchips-list
      [node]="node"
      *ngIf="mode === 'tree'"
      [twoRowLayout]="true"
      (afterCalcContainerWidth)="afterCalcFilterContainerWidth($event)"
    >
    </app-filterchips-list>
  </div>
</div>

<ng-template #notificationCounter let-node>
  <span
    [saxmsTooltip]="node.getNotificationsCount()"
    class="notification-counter"
    *ngIf="node.getNotificationsCount() && node.isShowNotificationsCount()"
  >
    {{
      node.getNotificationsCount() > maxNotificationCount ? maxNotificationCount + '+' : node.getNotificationsCount()
    }}
  </span>
</ng-template>
<div [ngClass]="classList" class="border-line"></div>
