import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LightboxService } from '@app-modeleditor/components/lightbox/lightbox.service';
import { ConfirmLightbox } from '@app-modeleditor/components/lightbox/predefined/confirm-lightbox';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { EMessageOrientation } from '../chat.enum';
import { IMessageDisplay } from '../chat.interface';

@Component({
  selector: 'app-message-item',
  templateUrl: './message-item.component.html',
  styleUrls: ['./message-item.component.scss'],
})
export class MessageItemComponent implements OnInit {
  public messageOrientation = EMessageOrientation;
  public isEditModeActive = false;
  public editedText = '';

  @Input() message: IMessageDisplay;
  @Input() showEdit = false;
  @Input() showDelete = false;
  @Output() onDelete = new EventEmitter<IMessageDisplay>();
  @Output() onEdit = new EventEmitter<{ message: IMessageDisplay; editedText: string }>();

  constructor(private lightboxApi: LightboxService, private translate: TranslateService) {}

  ngOnInit(): void {}

  deleteMessage() {
    const l: ConfirmLightbox = new ConfirmLightbox(this.translate.instant('@delete@') + '?').setCustomConfirmAction(
      () => {
        this.onDelete.emit(this.message);
        return of();
      }
    );

    this.lightboxApi.open(l);
  }

  activateEditMode() {
    this.isEditModeActive = true;
  }

  saveEdit(editedText: string): void {
    this.isEditModeActive = false;
    this.onEdit.emit({ message: this.message, editedText: editedText });
  }

  discardEdit() {
    const l: ConfirmLightbox = new ConfirmLightbox(this.translate.instant('discard') + '?').setCustomConfirmAction(
      () => {
        this.isEditModeActive = false;
        return of();
      }
    );

    this.lightboxApi.open(l);
  }
}
