import {
  ComponentRef,
  Directive,
  ElementRef,
  HostListener,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { of, Subject } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';
import { TooltipComponent } from './tooltip.component';
import { TooltipService } from './tooltip.service';

@Directive({
  selector: '[saxmsTooltip]',
})
export class TooltipDirective implements OnInit, OnChanges, OnDestroy {
  @Input() public saxmsTooltip: string;
  @Input() public saxmsTooltipDisabled: boolean;
  @Input() public saxmsTooltipShowDelay = 750;
  @Input() public saxmsTooltipHideDelay = 0;
  @Input() public saxmsTooltipClass: string;

  private leaveSub: Subject<void> = new Subject<void>();
  private createSub: Subject<void> = new Subject<void>();
  private cmpRef: ComponentRef<TooltipComponent>;

  public constructor(private el: ElementRef, private zone: NgZone, private tooltipApi: TooltipService) {
    this.zone.runOutsideAngular(() => {
      this.el.nativeElement.addEventListener('mouseenter', this.mouseenter.bind(this));
      this.el.nativeElement.addEventListener('mouseleave', this.mouseleave.bind(this));
      this.el.nativeElement.addEventListener('click', this.close.bind(this));
    });
  }

  private mouseenter(event: MouseEvent): void {
    this.cmpRef?.instance.preventClosing(true);

    if (this.saxmsTooltipDisabled === true) {
      return;
    }

    this.zone.run(() => {
      if (!this.el || !this.saxmsTooltip) {
        return;
      }
      this.tooltipApi.createTooltip(this.tooltipApi.getMouse() || this.el, this.saxmsTooltipShowDelay, {
        text: this.saxmsTooltip,
        clazz: this.saxmsTooltipClass,
      });
    });
  }

  private mouseleave(): void {
    this.cmpRef?.instance.preventClosing(false);
    this.leaveSub.next();
    of(null)
      .pipe(delay(this.saxmsTooltipHideDelay), takeUntil(this.leaveSub))
      .subscribe(() => {
        this.tooltipApi.closeTooltip(true);
      });
  }

  private close(): void {
    this.zone.run(() => {
      this.createSub.next();
      this.leaveSub.next();
      this.cmpRef?.instance.close();
      this.cmpRef = null;
      this.tooltipApi.closeTooltip();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.saxmsTooltipDisabled) {
      if (this.saxmsTooltipDisabled === true) {
        this.close();
      }
    }
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.close();
    this.leaveSub.complete();
    this.createSub.complete();
    if (this.el) {
      this.el.nativeElement.removeEventListener('mouseenter', () => {});
      this.el.nativeElement.removeEventListener('mouseleave', () => {});
      this.el.nativeElement.removeEventListener('click', () => {});
    }
  }
}
