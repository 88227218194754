import { Component, ContentChild, ElementRef, Input, OnInit, TemplateRef } from '@angular/core';
import { EntryCollection } from '@app-modeleditor/components/entry-collection/entry-collection';
import { Template } from 'frontend/src/dashboard/model/resource/template';
import { Layout } from '../layout';

@Component({
  selector: 'core-layout',
  templateUrl: './core-layout.component.html',
  styleUrls: ['./core-layout.component.scss'],
})
export class CoreLayoutComponent implements OnInit {
  @Input() templates: Template[];
  @Input() layout: Layout;
  @ContentChild('contentElement') element: TemplateRef<any>;
  @Input() elementRef: ElementRef;
  @Input() resourceId: string;
  @Input() editmode: boolean;
  @Input() disabled: boolean;
  @Input() entryCollection: EntryCollection;
  @Input() positionMarker: boolean;

  constructor() {}

  ngOnInit(): void {}
}
