import { Injectable, Type } from '@angular/core';
import { MultiObjectSelector } from '@app-modeleditor/components/selector/multi-object-selector';
import { TemplateAdapter } from '@app-modeleditor/utils/template-factory.service';
import { Adapter } from '../../../core/adapter';
import { EntryElementFactory } from '../entry-collection/entry-factory.service';

@Injectable({
  providedIn: 'root',
})
export class MultiObjectSelectorAdapter implements Adapter<MultiObjectSelector> {
  constructor(private _entryElementAdapter: EntryElementFactory) {}

  adapt(item: any): MultiObjectSelector {
    const t: MultiObjectSelector = this._applyProperties(
      this._entryElementAdapter.inherit<MultiObjectSelector>(MultiObjectSelector, item),
      item
    );
    return t;
  }

  inherit?<T extends MultiObjectSelector>(type: Type<T>, item: any): T {
    const t: T = this._applyProperties(this._entryElementAdapter.inherit<T>(type, item), item);
    return t;
  }

  inheritFrom?<T extends MultiObjectSelector>(scope: TemplateAdapter, type: Type<T>, item: any): T {
    const t: T = this._applyProperties(this._entryElementAdapter.inheritFrom<T>(scope, type, item), item);
    return t;
  }

  applyValues<T>(scope: TemplateAdapter, item: any, values: any): T {
    throw new Error('Method not implemented.');
  }

  private _applyProperties<T extends MultiObjectSelector>(el: T, item: any): T {
    el.setAvailableValuesFieldIdentifier(item.availableValuesFieldIdentifier)
      .setColorFieldIdentifier(item.colorFieldIdentifier)
      .setDataSourceRestriction(item.dataSourceRestriction)
      .setInternUploadButtonAvailible(item.internUploadButtonAvailible)
      .setLoadAllOptionsObjectSelector(item.loadAllOptionsObjectSelector)
      .setObjectFieldIdentifier(item.objectFieldIdentifier)
      .setShowChipsBelow(item.showChipsBelow)
      .setFreeSelection(item.freeSelection);
    return el;
  }
}
