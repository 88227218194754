import { NodeNetworkImageService } from '@app-nodenetwork/service/node-network.image.service';

/**
 * Handler to receive backend information about icons.
 */
export class IconHandler {
  constructor(public nodeNetworkImageService: NodeNetworkImageService) {}

  /**
   * Provides image types by get them from backend.
   * @param filterAttributeIntersection List of possible attributes to return only the icons which will be used.
   */
  public getIconAttributes(filterAttributeIntersection?: any[]): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.nodeNetworkImageService.getPossibleTypes().subscribe((result) => {
        if (filterAttributeIntersection) {
          const filteredDataset = filterAttributeIntersection.filter(
            (attribute) => result.indexOf(attribute.type) !== -1
          );
          resolve(Array.from(filteredDataset, (set) => set.type));
        } else resolve(result);
      });
    });
  }
}
