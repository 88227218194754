import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EntryElementValue } from '../entry-collection/entry-element-value';
import { NumericRangePicker } from './numeric-range-picker';

@Component({
  selector: 'app-button-slider',
  templateUrl: './button-slider.component.html',
  styleUrls: ['./button-slider.component.scss'],
})
export class ButtonSliderComponent implements OnInit {
  @Input() element: NumericRangePicker;
  @Output() changed: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  private get Value(): EntryElementValue {
    return this.element.getValue<EntryElementValue>();
  }

  /**
   * get nested entry element value
   * @returns number
   */
  get InnerValue(): number {
    return this.Value.getValue<number>();
  }

  /**
   * sets nested entry element value
   * @param value number
   */
  set InnerValue(value: number) {
    this.Value.setValue(value);
  }

  /**
   * updates value after button clicked
   * @param value number
   * @returns void
   */
  update(value: number): void {
    if (typeof this.InnerValue === 'string') {
      this.InnerValue = parseFloat(this.InnerValue);
    }
    this.InnerValue += value;
    this.changes(this.InnerValue);
  }

  /**
   * validates value and triggers changes
   * @param value number
   * @returns void
   */
  changes(value: number): void {
    if (typeof value === 'string') {
      value = parseFloat(value);
    }

    if (isNaN(value)) {
      this.InnerValue = this.element.getLowerBound();
    } else if (value < this.element.getLowerBound()) {
      this.InnerValue = this.element.getLowerBound();
    } else if (value > this.element.getUpperBound()) {
      this.InnerValue = this.element.getUpperBound();
    }
    this.changed.emit(this.InnerValue);
  }
}
