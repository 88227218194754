import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { ColorSketchModule } from 'ngx-color/sketch';
import { ColorButtonModule } from './color-button/color-button.module';
import { ColorpickerPopupContainerComponent } from './colorpicker-popup-container/colorpicker-popup-container.component';
import { ColorpickerComponent } from './colorpicker.component';

@NgModule({
  imports: [CommonModule, ColorSketchModule, ColorButtonModule, MatButtonModule, TranslateModule],
  declarations: [ColorpickerComponent, ColorpickerPopupContainerComponent],
  exports: [ColorpickerComponent, ColorpickerPopupContainerComponent, ColorButtonModule],
})
export class ColorpickerModule {}
