<div *ngIf="data" (mousedown)="defaultClick($event)" (click)="defaultClick($event)">
  <h2>{{ data.name }}</h2>
  <ng-container *ngFor="let item of data.value.value">
    <div>
      <mat-form-field>
        <mat-label>{{ item.name }}</mat-label>
        <input matInput [(ngModel)]="item.value" (change)="afterChanged(item)" />
        <button mat-icon-button matPrefix [disabled]="item.value <= item.lowerBound" (click)="decrease($event, item)">
          <mat-icon>remove</mat-icon>
        </button>
        <button mat-button matSuffix [disabled]="item.value >= item.upperBound" (click)="increase($event, item)">
          <mat-icon>add</mat-icon>/{{ item.upperBound }}
        </button>
      </mat-form-field>
    </div>
  </ng-container>
</div>
