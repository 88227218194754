import { Observable, Subject } from 'rxjs';
import { EntryElement } from '../entry-element';
import { EntryElementValue } from '../entry-element-value';
import { EFieldType } from '../field-type.enum';

export class TextField extends EntryElement {
  private onKeyUpEvent: Subject<{
    value: EntryElementValue;
    event: KeyboardEvent;
  }> = new Subject<{ value: EntryElementValue; event: KeyboardEvent }>();
  private forbiddenCharacters: string[];

  public getForbiddenCharacters(): string[] {
    return this.forbiddenCharacters || [];
  }

  public setForbiddenCharacters(...forbiddenCharacters: string[]): this {
    this.forbiddenCharacters = this.getForbiddenCharacters().concat(forbiddenCharacters);
    return this;
  }

  public emitKeyUp(value: { value: EntryElementValue; event: KeyboardEvent }): void {
    this.onKeyUpEvent.next(value);
  }

  // get keyup changes without changing the current value of the form element
  public onKeyUp(): Observable<{
    value: EntryElementValue;
    event: KeyboardEvent;
  }> {
    return this.onKeyUpEvent.asObservable();
  }

  constructor() {
    super();
    this.setFieldType(EFieldType.TEXT_FIELD);
  }
}
