import { Template } from 'frontend/src/dashboard/model/resource/template';

export class VirtualRow {
  private templates: Template[];

  public getTemplates(): Template[] {
    return this.templates || [];
  }

  public setTemplates(templates: Template[]): this {
    this.templates = templates;
    return this;
  }

  public addTemplates(...templates: Template[]): this {
    this.templates = this.getTemplates().concat(templates);
    return this;
  }
}
