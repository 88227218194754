import { Content } from '@app-modeleditor/components/content/content';
import { WizardPage } from './wizard-page';

export class WizardContent extends Content {
  private wizardPage: WizardPage[];

  public getWizardPage(): WizardPage[] {
    return this.wizardPage || [];
  }

  public setWizardPage(wizardPage: WizardPage[]): this {
    this.wizardPage = wizardPage;
    this.setResourceId(this.getResourceId());
    return this;
  }

  public setResourceId(resourceId: string): this {
    super.setResourceId(resourceId);
    this.getWizardPage().forEach((p: WizardPage) => {
      p.setResourceId(this.getResourceId());
    });
    return this;
  }
}
