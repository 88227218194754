<!-- wrapper for all content elements -->
<div
  #templatewrapper
  class="wrapper"
  [class.fullscreen]="fullscreen || ce.getResizeMode() === 'FIT_PARENT'"
  [class.show-title]="ce.isShowTitle()"
>
  <span *ngIf="ce.isShowTitle()" class="title">{{ ce.getName() }}</span>

  <template-slot [source]="ce" *ngIf="ce" (afterInit)="afterSlotInit($event, ce)"></template-slot>
  <!-- <ng-container *ngTemplateOutlet="elements; context: {$implicit:ce}">
  </ng-container> -->
</div>
