import { Injectable } from '@angular/core';
import { IDateSet } from '@app-modeleditor/components/elements/date-input/date-input.interface';
import { EntryElement } from '@app-modeleditor/components/entry-collection/entry-element';
import { EntryElementValue } from '@app-modeleditor/components/entry-collection/entry-element-value';
import { EFieldType } from '@app-modeleditor/components/entry-collection/field-type.enum';
import { MultiObjectSelector } from '@app-modeleditor/components/selector/multi-object-selector';
import { EDatatype } from 'frontend/src/dashboard/model/entry/datatype.enum';
import { normalizeMultiObject } from 'frontend/src/dashboard/utils/normalizeMultiObject';
import { ToolbarGroup } from 'frontend/src/dashboard/view/template-toolbar/toolbar-group';
import Handsontable from 'handsontable';
import { ColumnConditions, ConditionId, ConditionName } from 'handsontable/plugins/filters';
import { take } from 'rxjs/operators';
import { SaxmsColumnHeaderTemplateService } from '../core/saxms-column-header-template/saxms-column-header-template.service';
import { SaxMsSpreadSheetColumn } from '../core/saxms-spreadsheet';
import { SaxMsSubemenuFilterOutput } from '../full-spreadsheet/filter-output.interface';
import { FullSpreadsheetComponent } from '../full-spreadsheet/full-spreadsheet.component';
import { EFilterConditions } from '../model/table-filter-conditions.enum';
import { ESpreadsheetLogicOperator } from '../model/table-logic-operator.enum';
import { ESpreadsheetPlugin } from '../model/table-plugin.enum';
import { IExecuteSeetings, QuickSearchFilter, SpreadsheetColumnSettings } from '../spreadsheet';
import { ColumnUtil } from './column-util';
import { SpreadsheetHelperUtil } from './spreadsheet-helper-util';

@Injectable()
export class SpreadsheetFilterService {
  private _filtersPlugin: Handsontable.plugins.Filters;
  private _spreadSheetComponent: FullSpreadsheetComponent = null;
  private _filterConditions: ColumnConditions[] = [];
  private _columnFilterMap: Map<number, SaxMsSubemenuFilterOutput> = new Map();
  private _quickSearchFilterMap: Map<number, QuickSearchFilter> = new Map();
  private _filterActive = false;

  constructor(
    private _columnHeaderTemplateService: SaxmsColumnHeaderTemplateService,
    private _spreadsheetHelperUtil: SpreadsheetHelperUtil,
    private _columnUtil: ColumnUtil
  ) {}

  /**
   * applies the quick search for a specific column
   * @param entryElement quick search entry element
   * @param datatype of the column
   */
  public quicksearch(entryElement: EntryElement, datatype?: string): void {
    if (this._spreadSheetComponent.isInitLoading() && !this._spreadSheetComponent.isInitFinished()) {
      return;
    }

    this._spreadSheetComponent.removeTooltip();
    const element = entryElement;

    const column = this._spreadSheetComponent.columnUtil
      .getColumns()
      ?.find((column) => column.filterSortObject.getColumnIndex() === parseInt(element.getId().split('_').pop()));
    if (!column) {
      return;
    }

    const columnIndex = column.filterSortObject.getColumnIndex();
    let condition = element instanceof EntryElement ? element.getCondition() : null;

    if (!condition) {
      switch (datatype || element.getDatatype()) {
        case EDatatype.LONG:
          condition = EFilterConditions.gte;
          break;
        case EDatatype.STRING:
          condition =
            element.getFieldType() === EFieldType.MULTI_OBJECT_SELECTOR
              ? EFilterConditions.eq
              : EFilterConditions.contains;
          break;
        case EDatatype.FLOAT:
        case EDatatype.DOUBLE:
        case EDatatype.INTEGER:
          condition = EFilterConditions.gte;
          break;
        case EDatatype.BOOLEAN:
          condition = EFilterConditions.eq;
          break;
        default:
          condition = element.getCondition() ? element.getCondition() : EFilterConditions.contains;
      }
    }

    column.filterSortObject.setFilterActive(false);
    this.clearFilterByColumn(column);

    let quicksearchCondition: any = ESpreadsheetLogicOperator.CONJUNCTION;
    if (this._columnFilterMap.has(columnIndex)) {
      column.filterSortObject.setFilterActive(true);
      this.addFilterCondition(
        column,
        column.data,
        this._columnFilterMap.get(columnIndex).filterConditionOne,
        [this._columnFilterMap.get(columnIndex).filterValueOne],
        this._columnFilterMap.get(columnIndex).logicOperator === 'AND'
          ? ESpreadsheetLogicOperator.CONJUNCTION
          : ESpreadsheetLogicOperator.DISJUNCTION
      );

      if (this._columnFilterMap.get(columnIndex).filterValueTwo) {
        this.addFilterCondition(
          column,
          column.data,
          this._columnFilterMap.get(columnIndex).filterConditionTwo,
          [this._columnFilterMap.get(columnIndex).filterValueTwo],
          this._columnFilterMap.get(columnIndex).logicOperator === 'AND'
            ? ESpreadsheetLogicOperator.CONJUNCTION
            : ESpreadsheetLogicOperator.DISJUNCTION
        );
        quicksearchCondition = ESpreadsheetLogicOperator.CONJUNCTION;
      }
    } else {
      column.filterSortObject.setFilterActive(false);
    }

    let val: any;
    if (
      element.getValue<EntryElementValue>().getValue() ||
      element.getValue<EntryElementValue>().getValue() === 0 ||
      typeof element.getValue<EntryElementValue>().getValue() === 'boolean'
    ) {
      if (element.getValue<EntryElementValue>().getValue() instanceof EntryElementValue) {
        val = element.getValue<EntryElementValue>().getValue<EntryElementValue>().getName();
      } else {
        if (
          !Array.isArray(element.getValue<EntryElementValue>().getValue()) &&
          typeof element.getValue<EntryElementValue>().getValue() === 'object'
        ) {
          val = element.getValue<EntryElementValue>().getValue<any>()?.value;
        } else if (Array.isArray(element.getValue<EntryElementValue>().getValue())) {
          val = element.getValue<EntryElementValue>().getValue<any[]>().length
            ? element
                .getValue<EntryElementValue>()
                .getValue<any[]>()
                .map((elem) => elem.value)
            : null; // extract values of multi object selector
        } else {
          val = element.getValue<EntryElementValue>().getValue();
        }
      }
    }

    if (val === 0 && element.getFieldType() === EFieldType.DURATION_PICKER) {
      column.filterSortObject.setFilterActive(false);
      if (this._quickSearchFilterMap.has(columnIndex)) {
        this._quickSearchFilterMap.delete(columnIndex);
        this.forceFilter();
      }
    } else {
      if (
        (val !== null && val !== undefined && val !== '') ||
        element.getFieldType() === EFieldType.MULTI_OBJECT_SELECTOR
      ) {
        if (element.getFieldType() === EFieldType.MULTI_OBJECT_SELECTOR) {
          if (
            !val ||
            element.getValue<EntryElementValue>().getValue<EntryElementValue[]>()?.length ===
              element.getValue<EntryElementValue>().getAvailableValues<string[]>().length
          ) {
            // all/no options selected -> no filter
            column.filterSortObject.setFilterActive(false);
            if (this._quickSearchFilterMap.has(columnIndex)) {
              this._quickSearchFilterMap.delete(columnIndex);
              this.forceFilter();
            }
          } else {
            // handle filter
            column.filterSortObject.setFilterActive(true);
            this._quickSearchFilterMap.set(columnIndex, {
              id: element.getId(),
              value: element.getValue<any>(),
              condition: quicksearchCondition,
            });
            if (val) {
              val.forEach((elem) =>
                this.addFilterCondition(column, column.data, condition, [elem], ESpreadsheetLogicOperator.DISJUNCTION)
              );
            } else {
              // nothing selected -> filter all
              console.warn('no filter selected');
              val = [];
              element
                .getValue<EntryElementValue>()
                .getAvailableValues<string[]>()
                .forEach((elem) => {
                  this.addFilterCondition(
                    column,
                    column.data,
                    EFilterConditions.neq,
                    [elem],
                    ESpreadsheetLogicOperator.CONJUNCTION
                  );
                });
            }
          }
        } else {
          column.filterSortObject.setFilterActive(true);
          this._quickSearchFilterMap.set(columnIndex, {
            id: element.getId(),
            value: element.getValue<any>(),
            condition: quicksearchCondition,
          });
          this.addFilterCondition(column, column.data, condition, [val], quicksearchCondition);
        }
      } else {
        column.filterSortObject.setFilterActive(false);
        if (this._quickSearchFilterMap.has(columnIndex)) {
          this._quickSearchFilterMap.delete(columnIndex);
          this.forceFilter();
        }
      }
    }

    this.executeFilter();
    const valueToStore =
      element.getValue<EntryElementValue>().getValue() instanceof EntryElementValue
        ? element.getValue<EntryElementValue>().getValue<EntryElementValue>().getName()
        : val;
    this.handleSettingsChange(column, condition, valueToStore);
  }

  public searchString(query: string, col: SaxMsSpreadSheetColumn): void {
    if (this._spreadSheetComponent.isInitLoading() && !this._spreadSheetComponent.isInitFinished()) {
      return;
    }
    this._spreadSheetComponent.removeTooltip();

    const columnIndex = col.filterSortObject.getColumnIndex();
    const condition = query ? EFilterConditions.contains : undefined;
    col.filterSortObject.setFilterActive(false);
    this.clearFilterByColumn(col);

    // handle filter
    col.filterSortObject.setFilterActive(!!query);
    this._quickSearchFilterMap.set(columnIndex, {
      id: `searchFilter_${col.id}`,
      value: { value: query },
      condition: EFilterConditions.contains,
    });
    if (query) {
      this.addFilterCondition(
        col,
        col.data,
        EFilterConditions.contains,
        [query],
        ESpreadsheetLogicOperator.DISJUNCTION
      );
    }

    this.executeFilter();
    this.handleSettingsChange(col, condition, query ? query : undefined);
  }

  public searchBoolean(bool: boolean, col: SaxMsSpreadSheetColumn): void {
    if (this._spreadSheetComponent.isInitLoading() && !this._spreadSheetComponent.isInitFinished()) {
      return;
    }
    this._spreadSheetComponent.removeTooltip();

    const isValueSet = bool !== null;
    const columnIndex = col.filterSortObject.getColumnIndex();
    const condition = isValueSet ? EFilterConditions.contains : undefined;
    col.filterSortObject.setFilterActive(false);
    this.clearFilterByColumn(col);

    // handle filter
    col.filterSortObject.setFilterActive(isValueSet);
    this._quickSearchFilterMap.set(columnIndex, {
      id: `searchFilter_${col.id}`,
      value: { value: bool },
      condition: EFilterConditions.contains,
    });
    if (isValueSet) {
      this.addFilterCondition(col, col.data, EFilterConditions.contains, [bool], ESpreadsheetLogicOperator.DISJUNCTION);
    }

    this.executeFilter();
    this.handleSettingsChange(col, condition, isValueSet ? bool : undefined);
  }

  public searchDate(dateset: IDateSet, col: SaxMsSpreadSheetColumn): void {
    if (this._spreadSheetComponent.isInitLoading() && !this._spreadSheetComponent.isInitFinished()) {
      return;
    }
    this._spreadSheetComponent.removeTooltip();
    const columnIndex = this._spreadSheetComponent
      .getSpreadSheetInstance()
      .toPhysicalColumn(this._spreadSheetComponent.columnUtil.getColIndex(col.id));
    const rowsToHide: number[] = [];
    const rowsToUnhide: number[] = [];
    const datesetEmpty =
      !dateset.day && !dateset.month && !dateset.year && dateset.hour === null && dateset.minute === null;

    this._spreadSheetComponent.rows.forEach((row, i) => {
      const dateInMs = parseInt(row[columnIndex]);
      if (datesetEmpty || (!isNaN(dateInMs) && this.checkDateSet(new Date(dateInMs), dateset))) {
        rowsToUnhide.push(i);
      } else {
        rowsToHide.push(i);
      }
    });

    col.filterSortObject.setFilterActive(!datesetEmpty);
    this._spreadSheetComponent.handleVisibilityOfRows(rowsToUnhide, rowsToHide, `dateSearch_${col.id}`);
    this._spreadSheetComponent.getSpreadSheetInstance().render();
    this._spreadSheetComponent.triggerHeightCalculation();
    this.handleSettingsChange(col, undefined, datesetEmpty ? undefined : dateset);
  }

  private checkDateSet(date: Date, dateset: IDateSet): boolean {
    const isDayValid = !dateset.day || dateset.day === date.getDate();
    const isMonthValid = !dateset.month || dateset.month === date.getMonth() + 1;
    const isYearValid = !dateset.year || dateset.year === date.getFullYear();
    const isHourValid = dateset.hour === null || dateset.hour === date.getHours();
    const isMinuteValid = dateset.minute === null || dateset.minute === date.getMinutes();
    return isDayValid && isMonthValid && isYearValid && isHourValid && isMinuteValid;
  }

  private handleSettingsChange(column: SaxMsSpreadSheetColumn, condition: EFilterConditions, filterValue: any): void {
    const columnSetting = this._spreadSheetComponent.getColumnSettings(column);
    if (!columnSetting) {
      return;
    }
    let shouldSave = false;

    const quickSearchElement = this.getQuickSearchElementByColumnIndex(column.filterSortObject.getColumnIndex());

    // check if settings have been changed
    if (
      JSON.stringify(filterValue) !== JSON.stringify(columnSetting.column.quickFilterVal) ||
      condition !== columnSetting.column.quickFilterCond
    ) {
      // This is a workaround to prevent unintended saveSettings to the backend.
      // A MULTI_OBJECT_SELECTOR would be interpreted similarly being undefined or having an array of all options.
      // "normalizeMultiObject" is mapping both to undefined.
      shouldSave = [filterValue, columnSetting.column.quickFilterVal]
        .map((val) => normalizeMultiObject(val, quickSearchElement))
        .some((val) => val != undefined);

      columnSetting.column.quickFilterVal = filterValue;
      columnSetting.column.quickFilterCond = condition;
    }

    if (columnSetting.createNew) {
      shouldSave = true;
      this._spreadSheetComponent.getSpreadsheetsettings().columnSettings.push(columnSetting.column);
    }

    this._spreadSheetComponent?.getToolbar()?.updateMenuItemsIndications();

    if (shouldSave) {
      this._spreadSheetComponent.saveSettings().pipe(take(1)).subscribe();
    }
  }

  /**
   * clear the filter conditions on specific columns
   * @param columns spreadsheet columns
   * @param executeFilter flag for execute the filtering after clear the conditions
   */
  public clearFilterByColumn(...columns: SaxMsSpreadSheetColumn[]): void {
    if (!this._spreadSheetComponent.isInitFinished() || !columns?.length) {
      return;
    }

    const columnIndices = columns.map((column) => column.data);

    this._filterConditions = this._filterConditions.filter((fc) => !columnIndices.includes(fc.column));

    if (this.spreadsheetHelperUtil.checkPlugin(ESpreadsheetPlugin.FILTERS_PLUGIN, 'filters')) {
      if (columns.length === 1) this.columnHeaderTemplateService.setCurrentSortFilterObjectByColumn(columns[0]);

      for (const columnIndex of columnIndices) {
        this.filtersPlugin.clearConditions(columnIndex);
      }

      this.executeFilter(columnIndices[0]); // first filter selected
    }
  }

  /**
   * forces the execution of the fliter plugin
   */
  private forceFilter() {
    if (this.spreadsheetHelperUtil.checkPlugin(ESpreadsheetPlugin.FILTERS_PLUGIN, 'filters')) {
      this.filtersPlugin.filter();
    }
  }

  /**
   * @param columnIndex
   * @returns the current quicksearch value of a column
   */
  public getQuicksearchValue(columnIndex: number): any {
    if (this._quickSearchFilterMap.has(columnIndex)) {
      return this._quickSearchFilterMap.get(columnIndex).value.value;
    }
    return null;
  }

  /**
   * filters a column based on passed information
   * @param event filter information
   */
  public filterColumn(event: SaxMsSubemenuFilterOutput): void {
    let columnIndex: any = event.columnId;

    if (event.columnId === null || event.columnId === undefined) {
      columnIndex = Number.parseInt(
        Array.from(this._spreadSheetComponent.getSelectionModel().selectionColumns.keys())[0]
      );
    }

    const colId = this.columnUtil.getColumns().find((colum) => colum.data === columnIndex).id;
    const column = this.columnUtil.getColumns().find((h) => h.id === colId);

    column.filterSortObject.setFilterActive(false);
    if (this._columnFilterMap.has(column.filterSortObject.getColumnIndex())) {
      this.clearFilterByColumn(column);
    }

    const operation =
      event.logicOperator === 'AND' ? ESpreadsheetLogicOperator.CONJUNCTION : ESpreadsheetLogicOperator.DISJUNCTION;

    if (
      event.filterConditionOne !== EFilterConditions.none &&
      event.filterValueOne !== null &&
      event.filterValueOne !== undefined
    ) {
      column.filterSortObject.setFilterActive(true);
      this.addFilterCondition(column, column.data, event.filterConditionOne, [event.filterValueOne], operation);
      if (event.filterValueTwo) {
        this.addFilterCondition(column, column.data, event.filterConditionTwo, [event.filterValueTwo], operation);
      }
      this._columnFilterMap.set(column.filterSortObject.getColumnIndex(), event);
    } else {
      if (this._columnFilterMap.has(column.filterSortObject.getColumnIndex())) {
        this._columnFilterMap.delete(column.filterSortObject.getColumnIndex());
        this.executeFilter(column.data);
      }
    }
    if (event.executeFilter) {
      if (this._columnFilterMap.has(column.filterSortObject.getColumnIndex())) {
        this.executeFilter(column.data);
      }
    }

    this.saveFilterSettings(event);
  }

  /**
   * add filter condition to the selected column
   * @param column information of the selected column
   * @param columnIndex columnindex of the selected column
   * @param condition condition for the comparison for the filter values
   * @param filterArgs array with the filter values
   * @param operationId logic operation between more filter conditions
   */
  public addFilterCondition(
    column: SaxMsSpreadSheetColumn,
    columnIndex: number,
    condition: EFilterConditions,
    filterArgs: any[],
    operationId?: ESpreadsheetLogicOperator.CONJUNCTION | ESpreadsheetLogicOperator.DISJUNCTION
  ): void {
    this.buildFilterCondition(columnIndex, condition, filterArgs, operationId);
    if (this.spreadsheetHelperUtil.checkPlugin(ESpreadsheetPlugin.FILTERS_PLUGIN, 'filters')) {
      if (operationId) {
        this.filtersPlugin.addCondition(columnIndex, condition, filterArgs, operationId);
      } else {
        this.filtersPlugin.addCondition(columnIndex, condition, filterArgs, ESpreadsheetLogicOperator.CONJUNCTION);
      }
    }

    this.columnHeaderTemplateService.setCurrentSortFilterObjectByColumn(column);
  }

  /**
   * maping a filtercondition with the passed information for the specific column index
   * @param columnIndex columnindex of the selected column
   * @param condition condition for the comparison for the filter values
   * @param filterArgs array with the filter values
   * @param operationId logic operation between more filter conditions
   */
  public buildFilterCondition(
    columnIndex: number,
    condition: EFilterConditions,
    filterArgs: any[],
    operationId?: ESpreadsheetLogicOperator
  ): void {
    let filterCondition = this._filterConditions.find((fc) => fc.column === columnIndex);
    const quickSearchElem = this.getQuickSearchElementByColumnIndex(columnIndex);

    // do not filter if all values are set for multi object selector
    if (
      quickSearchElem?.getFieldType() === EFieldType.MULTI_OBJECT_SELECTOR &&
      filterArgs[0].length === (quickSearchElem as MultiObjectSelector).getAvailableOptions().length
    ) {
      this._filterConditions = this._filterConditions.filter((con) => con.column !== columnIndex);
      return;
    }

    if (filterCondition) {
      if (
        !this.isConditionDuplicate(filterCondition.conditions, {
          name: condition as ConditionName,
          args: filterArgs,
        })
      ) {
        filterCondition.conditions.push({
          args: filterArgs,
          name: condition as ConditionName,
        });
        filterCondition.operation = operationId ? operationId : filterCondition.operation;
      }
    } else {
      filterCondition = {
        column: columnIndex,
        conditions: [
          {
            args: filterArgs,
            name: condition as ConditionName,
          },
        ],
        operation: operationId,
      };
      if (condition) {
        this._filterConditions.push(filterCondition);
      }
    }
  }

  /**
   * Checks wether a condition is already inside the given filter conditions array.
   * @param originFilterConditions
   * @param conditionToCompare
   * @returns Returns true if a duplicate is detected
   */
  private isConditionDuplicate(originFilterConditions: ConditionId[], conditionToCompare: ConditionId): boolean {
    const conditionsWithSameName = originFilterConditions.filter((elem) => elem.name === conditionToCompare.name);
    if (!conditionsWithSameName || !Array.isArray(conditionToCompare.args)) {
      return false;
    }

    for (const singleCondition of conditionsWithSameName) {
      const singleConditionArgs = singleCondition.args;
      if (Array.isArray(singleConditionArgs) && singleConditionArgs.length === conditionToCompare.args.length) {
        for (let i = 0; i < singleConditionArgs.length; i++) {
          if (singleConditionArgs[i] !== conditionToCompare.args[i]) {
            break;
          }
        }
        return true;
      }
    }
    return false;
  }

  /**
   * execute the filter on a specific column
   * @param columnIndex column index of the specific column
   */
  public executeFilter(columnIndex?: number): void {
    if (this._filterConditions.length === 0 && !this._filterActive) {
      return;
    }

    if (
      this.spreadsheetHelperUtil.checkPlugin(ESpreadsheetPlugin.FILTERS_PLUGIN, 'filters') &&
      this.filtersPlugin.conditionCollection
    ) {
      this.filtersPlugin.filter();
    }

    if (
      this._spreadSheetComponent.getSpreadSheetInstance() &&
      !this._spreadSheetComponent.getSpreadSheetInstance().isDestroyed &&
      (columnIndex || !isNaN(columnIndex))
    ) {
      this._spreadSheetComponent.getSpreadSheetInstance().selectColumns(columnIndex);
    }

    if (this._filterConditions.length === 0) {
      this.filterActive = false;
    }
  }

  public getQuickSearchElementByColumnIndex(index: number): EntryElement {
    for (const m of this._spreadSheetComponent.getToolbar().getMenuItems()) {
      for (const g of m.getToolbarGroups().filter((g: ToolbarGroup) => g.getId() === 'quicksearch')) {
        for (const e of g.getEntryElements()) {
          if (index === parseInt(e.getId().split('_').pop())) {
            return e;
          }
        }
      }
    }
  }

  /**
   * saves filter settings for a specific column
   * @param event filter settings of specific column
   */
  private saveFilterSettings(event: SaxMsSubemenuFilterOutput) {
    let columnIndex: any = event.columnId;

    if (event.columnId === null || event.columnId === undefined) {
      columnIndex = Number.parseInt(
        Array.from(this._spreadSheetComponent.getSelectionModel().selectionColumns.keys())[0]
      );
    }

    const column = this.columnUtil.getColumnByIndex(columnIndex, true);

    if (!this._spreadSheetComponent.getBlockedSaveSettings() && column) {
      const columnSetting = this._spreadSheetComponent.getColumnSettings(column);

      delete columnSetting.column.filterVal1;
      delete columnSetting.column.filterVal2;

      columnSetting.column.filterCond1 = event.filterConditionOne;
      columnSetting.column.filterCond2 = event.filterConditionTwo;

      if (event.filterValueOne !== null) {
        columnSetting.column.filterVal1 = event.filterValueOne;
        if (event.filterValueTwo !== null) {
          columnSetting.column.filterVal2 = event.filterValueTwo;
        }
      }
      columnSetting.column.filterOperator = event.logicOperator;
      columnSetting.column.filterElementType = event.elementType;
      columnSetting.column.filterDataType = event.datatype;

      if (columnSetting.createNew) {
        this._spreadSheetComponent.getSpreadsheetsettings().columnSettings.push(columnSetting.column);
      }

      this._spreadSheetComponent.saveSettings().pipe(take(1)).subscribe();
    }
  }

  /**
   * returns the full filter information of a specific column
   * @param columnIndex index of the specific column
   * @returns full filter information of a specific column
   */
  public getColumnFilterDataByIndex(columnIndex: number): SaxMsSubemenuFilterOutput {
    const column = this.columnUtil.getColumnByIndex(columnIndex, true);
    let filterData: SaxMsSubemenuFilterOutput = {
      columnId: columnIndex,
      elementType: column.fieldType,
      datatype: column.datatype,
      filterConditionOne: EFilterConditions.none,
      filterConditionTwo: EFilterConditions.none,
      filterValueOne: null,
      filterValueTwo: null,
      logicOperator: 'AND',
      executeFilter: true,
    };
    if (this._columnFilterMap.has(columnIndex)) {
      filterData = this._columnFilterMap.get(columnIndex);
    }
    return filterData;
  }

  /**
   * remove all filters of specific columns
   * @param columnIds ids of specific columns
   */
  public removeFilter(...columnIds: string[]): void {
    this._spreadSheetComponent.removeTooltip();
    if (!columnIds?.length) {
      return;
    }

    const columns = columnIds.map((columnId) => this.columnUtil.getColumnById(columnId)).filter((column) => !!column);

    const columnIndices = columns.map((column) => column.filterSortObject.getColumnIndex());

    for (const columnIndex of columnIndices) {
      this._columnFilterMap.delete(columnIndex);
      this._quickSearchFilterMap.delete(columnIndex);
    }

    for (const columnId of columnIds) {
      this._spreadSheetComponent.getSortMap().delete(columnId);
    }

    this._spreadSheetComponent.handleVisibilityOfRows(
      this._spreadSheetComponent.rows.map((_, i) => i),
      [],
      `dateSearch_${columnIds[0]}`
    );

    this.clearFilterByColumn(...columns);
    this.clearFilterSettings(...columns);
    this._spreadSheetComponent.clearSortSettings(...columns);

    this._spreadSheetComponent.executeSortPlugin(this._spreadSheetComponent.getSortConfig());
    this._spreadSheetComponent.recalculateMergedCells();
    this._spreadSheetComponent.triggerHeightCalculation();
    this._spreadSheetComponent.changeDataOfSubmenu();
    this._spreadSheetComponent
      .saveSettings()
      .pipe(take(1))
      .subscribe(() => {
        if (
          this._spreadSheetComponent.getSpreadSheetInstance() &&
          !this._spreadSheetComponent.getSpreadSheetInstance().isDestroyed &&
          (columns[0].data || !isNaN(columns[0].data)) &&
          columns.length === 1
        ) {
          this._spreadSheetComponent.getSpreadSheetInstance().selectColumns(columns[0].data);
        }
      });
  }

  /**
   * Removes all filters of all columns
   */
  public removeAllFilters() {
    this.removeFilter(...this.columnUtil.getColumns().map(({ id }) => id));
  }

  /**
   * clear settings of a specific column
   * @param columnIndex index of the specific column
   */
  private clearFilterSettings(...columns: SaxMsSpreadSheetColumn[]): void {
    for (const column of columns) {
      const columnSetting = this._spreadSheetComponent.getColumnSettings(column);
      delete columnSetting.column.quickFilterCond;
      delete columnSetting.column.quickFilterVal;
      delete columnSetting.column.filterCond1;
      delete columnSetting.column.filterCond2;
      delete columnSetting.column.filterDataType;
      delete columnSetting.column.filterElementType;
      delete columnSetting.column.filterVal1;
      delete columnSetting.column.filterVal2;
      delete columnSetting.column.filterOperator;
    }
  }

  /**
   * mapping filter setting after mapping the setting from the backend
   * @param columnSettings settings of a column
   * @param column column information
   * @param filterObject information about the complex filter of the column
   * @param quicksearchFilter information about the quickfilter of the column
   * @returns a promise
   */
  public executeFilterSetting(
    columnSettings: SpreadsheetColumnSettings,
    column: SaxMsSpreadSheetColumn,
    filterObject: SaxMsSubemenuFilterOutput,
    quicksearchFilter?: QuickSearchFilter
  ): Promise<IExecuteSeetings> {
    return new Promise((resolve) => {
      // of(null).pipe(delay(0)).subscribe(() => {
      if (filterObject.filterConditionOne !== EFilterConditions.none) {
        column.filterSortObject.setFilterActive(true);
        const operation =
          filterObject.logicOperator === 'AND'
            ? ESpreadsheetLogicOperator.CONJUNCTION
            : ESpreadsheetLogicOperator.DISJUNCTION;
        this.buildFilterCondition(
          this.columnUtil.getColumnById(columnSettings.columnID).data,
          filterObject.filterConditionOne,
          [filterObject.filterValueOne],
          operation
        );
        if (filterObject.filterConditionTwo !== EFilterConditions.none && filterObject.filterValueTwo) {
          this.buildFilterCondition(
            this.columnUtil.getColumnById(columnSettings.columnID).data,
            filterObject.filterConditionTwo,
            [filterObject.filterValueTwo],
            operation
          );
        }
      }
      if (
        quicksearchFilter &&
        quicksearchFilter.value.value &&
        quicksearchFilter.condition !== EFilterConditions.none
      ) {
        const columnIndex = this.columnUtil.getColumnById(columnSettings.columnID).data;
        this.buildFilterCondition(columnIndex, EFilterConditions[quicksearchFilter.condition], [
          quicksearchFilter.value.value,
        ]);
      }
      resolve({
        columnSettings: columnSettings,
        column: column,
      });
      // });
    });
  }

  set spreadSheetComponent(component: FullSpreadsheetComponent) {
    this._spreadSheetComponent = component;
  }

  get spreadSheetComponent() {
    return this._spreadSheetComponent;
  }

  set filtersPlugin(plugin: Handsontable.plugins.Filters) {
    this._filtersPlugin = plugin;
  }

  get filtersPlugin(): Handsontable.plugins.Filters {
    return this._filtersPlugin;
  }

  set filterActive(isActive: boolean) {
    this._filterActive = isActive;
  }

  get filterConditions(): ColumnConditions[] {
    return this._filterConditions;
  }

  get quickSearchFilterMap(): Map<number, QuickSearchFilter> {
    return this._quickSearchFilterMap;
  }

  get columnFilterMap(): Map<number, SaxMsSubemenuFilterOutput> {
    return this._columnFilterMap;
  }

  public get columnHeaderTemplateService(): SaxmsColumnHeaderTemplateService {
    return this._columnHeaderTemplateService;
  }

  public get spreadsheetHelperUtil(): SpreadsheetHelperUtil {
    return this._spreadsheetHelperUtil;
  }

  public get columnUtil(): ColumnUtil {
    return this._columnUtil;
  }
}
