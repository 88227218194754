import { Action } from '@app-modeleditor/components/button/action/action';
import { Button } from '@app-modeleditor/components/button/button';
import { EButtonDisplayType } from '@app-modeleditor/components/button/button-display-type.enum';
import { GanttLibService } from 'frontend/src/dashboard/gantt/gantt/gantt-lib.service';
import { Observable, of } from 'rxjs';

/**
 * Button to zoom on all visible shifts in gantt diagram.
 */
export class ZoomToVisibleBlocksElement extends Button {
  constructor(private ganttLibService: GanttLibService) {
    super();
  }

  get(data: any): this {
    return this.setIcon('crop_free')
      .setName('@zoom-to-results@')
      .setId(`zoom-visible-blocks-${data.id}`)
      .setAlwaysEnabled(true)
      .setDisplayType(EButtonDisplayType.ICON_WITH_LABEL_BELOW)
      .chainActions(new Action().setCb(() => this.executeZoom()));
  }

  private executeZoom(): Observable<null> {
    const dataSet = this.ganttLibService.bestGantt.getDataHandler().getOriginDataset().ganttEntries;
    this.ganttLibService.bestGantt.getXAxisBuilder().zoomToAllVisibleShiftsByDataSet(dataSet);
    return of(null);
  }
}
