import { ContentPart } from '../content/content-part/content-part';
import { ColumnListSelectorElement } from './column-list-selector-element';

export class ColumnListSelectorValue {
  private availableListEntryElements: ColumnListSelectorElement[];
  private selectedListEntryElements: ColumnListSelectorElement[];

  public getAvailableListEntryElements(): ColumnListSelectorElement[] {
    return this.filter(this.availableListEntryElements || []);
  }

  public setAvailableListEntryElements(availableListEntryElements: ColumnListSelectorElement[]): this {
    this.availableListEntryElements = availableListEntryElements;
    return this;
  }

  public getSelectedListEntryElements(): ColumnListSelectorElement[] {
    return this.filter(this.selectedListEntryElements || []);
  }

  public setSelectedListEntryElements(selectedListEntryElements: ColumnListSelectorElement[]): this {
    this.selectedListEntryElements = selectedListEntryElements;
    return this;
  }

  private filter(items: ColumnListSelectorElement[]) {
    return items.filter((item: ColumnListSelectorElement) => {
      if (item.isSimpleElement() && item.getFloatLeft()) {
        return true;
      }

      if (!item.getContent() || item.getContent().getContentParts().length === 0) {
        return false;
      }

      item
        .getContent()
        .getContentParts()
        .forEach((part: ContentPart) => {
          part.setDisplayContentpartContainer(false);
          if (part.getContentElements().length === 0) {
            part.setShow(false);
          }
        });
      return true;
    });
  }
}
