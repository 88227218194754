import { GanttLibService } from 'frontend/src/dashboard/gantt/gantt/gantt-lib.service';
import { SaxMsBestGanttActiveSubmenuEntryElementSetting } from 'frontend/src/dashboard/gantt/gantt/saxms-best-gantt.settings';
import { GanttPluginHandlerService } from 'frontend/src/dashboard/gantt/general/plugin/gantt-plugin-handler.service';
import { Observable, of } from 'rxjs';
import { GeneralGanttActionHandler } from '../../../action-handling/action-handler';
import { ExternalGanttPlugin } from '../../external-plugin';

/**
 * This PlugIn is NOT based on any JS-PlugIn.
 * It provides x axis manipulation to jump to specific zoom levels.
 */
export class GanttXAxisManipulatorPlugIn extends ExternalGanttPlugin {
  private isZoomForTimePointScrollEnabled = false;

  constructor(
    protected _ganttPluginHandlerService: GanttPluginHandlerService,
    protected _ganttLibService: GanttLibService,
    actionHandler: GeneralGanttActionHandler
  ) {
    super(_ganttPluginHandlerService, _ganttLibService, actionHandler);
  }

  public onInit(responseData: any) {}

  public onDestroy(): void {}

  public onAction(action: any) {}

  /**
   * Sets gantt zoom level to given start and end date.
   * @param timeStart Zoom start date.
   * @param timeEnd Zoom end date.
   */
  public changeTimeToInterval(timeStart: Date, timeEnd: Date): void {
    const originGanttData = this._ganttLibService.bestGantt.getDataHandler().getOriginDataset();
    const ganttStart = originGanttData.minValue;
    const ganttEnd = originGanttData.maxValue;
    // validation to check if zoom target is inside gantt timespan
    if (timeStart.getTime() < ganttStart.getTime()) timeStart = ganttStart;
    if (timeEnd.getTime() > ganttEnd.getTime()) timeEnd = ganttEnd;
    this._ganttLibService.bestGantt.setCurrentZoomedTimeSpan(timeStart, timeEnd);
  }

  /**
   * Changes zoom level to first full day whichis inside current zoom timespan.
   */
  public changeTimeToFullDay(): void {
    const ganttDates: Date[] = this.gantt.getXAxisBuilder().getCurrentScale().domain();
    const timePeriod: number = ganttDates[1].getTime() - ganttDates[0].getTime();
    let newStartDate: number = this.findFirstFullDayInsideTimeSpan(ganttDates[0], timePeriod);
    newStartDate += new Date(newStartDate).getTimezoneOffset() * 60 * 1000;
    const twoHours: number = 2 * 60 * 60 * 1000;
    this.changeTimeToInterval(
      new Date(newStartDate - twoHours),
      new Date(newStartDate + 24 * 60 * 60 * 1000 + twoHours)
    );
  }

  /**
   * Extracts start time point of first full say inside the given timespan.
   * @param startDate Start date of timespan.
   * @param timeSpan timespan in milliseconds.
   */
  private findFirstFullDayInsideTimeSpan(startDate: Date, timeSpan: number): number {
    const oneDay = 24 * 60 * 60 * 1000;
    // check if given startDate is already full date
    if (Number.isInteger(startDate.getTime() / oneDay)) {
      return startDate.getTime();
    }
    // devide between time span shorter/longer than one day
    let finalDate;
    if (timeSpan < oneDay) finalDate = startDate;
    else {
      finalDate = new Date(startDate.getTime() + oneDay);
    }
    // cut of all time
    return Math.floor(finalDate.getTime() / oneDay) * oneDay;
  }

  public executeAction(localAction: any): Observable<any> {
    return of(true);
  }

  public injectSettings(submenuElements: SaxMsBestGanttActiveSubmenuEntryElementSetting[]): void {}

  public setIsZoomForTimePointScrollEnabled(isEnabled: boolean): void {
    this.isZoomForTimePointScrollEnabled = isEnabled;
  }

  public getIsZoomForTimePointScrollEnabled(): boolean {
    return this.isZoomForTimePointScrollEnabled;
  }
}
