import { EntryElement } from '@app-modeleditor/components/entry-collection/entry-element';
import { EntryElementValue } from '@app-modeleditor/components/entry-collection/entry-element-value';
import { EFieldType } from '@app-modeleditor/components/entry-collection/field-type.enum';
import { Gantt_General } from '../../general.gantt.component';
import { GanttEssentialPlugIns } from '../../plugin/e-gantt-essential-plugins';
import { GanttIndexCardsPlugIn } from '../../plugin/plugin-list/index-cards/index-cards.plugin';
import {
  GanttBlockAddButtonBackendType,
  GanttBlockAddActivationAction,
} from '../../plugin/plugin-list/block-add-button/block-add-button.plugin';

export class ToogleAddBlock extends EntryElement {
  private entryelementData;
  constructor(private scope: Gantt_General) {
    super();
  }

  get(data: any): EntryElement {
    this.entryelementData = data;

    return this.setId(data.id)
      .setName(data.name)
      .setFieldType(EFieldType.SLIDE_TOGGLE)
      .onChanges((val: EntryElementValue) => this.toggleMode(val.getValue()))
      .setValue(new EntryElementValue().setValue(false));
  }

  public toggleMode(state: boolean): void {
    const localAction: GanttBlockAddActivationAction = {
      pluginId: GanttBlockAddButtonBackendType,
      activateModus: state,
    };
    if (this.entryelementData.localActions) {
      this.scope.ganttPluginHandlerService.executePlugInAction(localAction);
    }
  }
}
