import { SaveButtonRestriction } from 'frontend/src/dashboard/gantt/gantt/save-button-restriction';

/**
 * Notifies Saxmsbest gantt component if
 * the backend registered any save buttons.
 * (Not used yet)
 */
export class GeneralGanttSaveHandler {
  public buttonRestriction: SaveButtonRestriction;

  constructor(templateData: any) {
    this.buttonRestriction = this.extractSaveButtonRestrictions(templateData);
  }

  /**
   * Extracts save button restrictions by backend data.
   * @param templateData Backend temaplte data.
   */
  private extractSaveButtonRestrictions(templateData: any): SaveButtonRestriction {
    const saveButtonRestrictions: SaveButtonRestriction = new SaveButtonRestriction();
    saveButtonRestrictions.showSaveButton = !!templateData.saveUrl;
    saveButtonRestrictions.showSaveAsButton = !!templateData.saveAsUrl;
    return saveButtonRestrictions;
  }
}
