import { Injectable, Type } from '@angular/core';
import { TemplateAdapter } from '@app-modeleditor/utils/template-factory.service';
import { Adapter } from '@core/adapter';
import { EntryElementFactory } from '../entry-factory.service';
import { TextField } from './text-field';

@Injectable({
  providedIn: 'root',
})
export class TextFieldAdapter implements Adapter<TextField> {
  constructor(private entryElementAdapter: EntryElementFactory) {}

  adapt(item: any): TextField {
    throw new Error('Method not implemented.');
  }
  inherit?<T extends TextField>(type: Type<T>, item: any): T {
    const t: T = this.build(this.entryElementAdapter.inherit<T>(type, item), item);
    return t;
  }

  inheritFrom?<T extends TextField>(scope: TemplateAdapter, type: Type<T>, item: any): T {
    const t: T = this.build(this.entryElementAdapter.inheritFrom<T>(scope, type, item), item);
    return t;
  }

  private build<T extends TextField>(t: T, item: Record<string, any>): T {
    if (item.forbiddenCharacters) {
      t.setForbiddenCharacters(...item.forbiddenCharacters);
    }
    return t;
  }

  applyValues<T>(scope: TemplateAdapter, item: any, values: any): T {
    throw new Error('Method not implemented.');
  }
}
