import { Component, OnDestroy } from '@angular/core';
import { Tenant } from 'frontend/src/dashboard/view/template-footer/default-footer-components/tenant-footer/tenant';
import { fromEvent, of } from 'rxjs';
import { switchMap, takeUntil, takeWhile, tap } from 'rxjs/operators';
import { LastVisitedContentService } from './last-visited-content.service';

export interface IRecentlyVisitedNode {
  name?: string;
  details?: string;
  path?: string[];
  time?: number;
  tenant?: Tenant;
  tenantId?: string;
  tenantName?: string;
  resourceId: string;
  type?: string;
  url: string;
}

interface ResizeEvent {
  element: HTMLDivElement;
  startX: number;
  startWidth: number;
}

@Component({
  selector: 'app-last-visited-content',
  templateUrl: './last-visited-content.component.html',
  styleUrls: ['./last-visited-content.component.scss'],
})
export class LastVisitedContentComponent implements OnDestroy {
  protected displayedItems: IRecentlyVisitedNode[] = [];
  protected groups: { name: string; items: IRecentlyVisitedNode[] }[];
  private drag$ = fromEvent<MouseEvent>(document, 'mousemove');
  private dragEnd$ = fromEvent<MouseEvent>(document, 'mouseup');
  private $alive = true;

  constructor(private $lastVisitedContentApi: LastVisitedContentService) {
    this.$lastVisitedContentApi
      .getLastVisitedContentNodes()
      .pipe(takeWhile(() => this.$alive))
      .subscribe((recently: IRecentlyVisitedNode[]) => {
        this.groups = [];

        (recently || []).forEach((r: IRecentlyVisitedNode) => {
          let match = this.groups.find((g) => g.name === r.type);
          if (!match) {
            match = { name: r.type, items: [] };
            this.groups.push(match);
          }
          match.items.push(r);
        });
        this.displayedItems = recently;
      });
  }

  ngOnDestroy(): void {
    this.$alive = false;
  }

  protected openRecentlyVisitedNode(node: IRecentlyVisitedNode): void {
    this.$lastVisitedContentApi.openRecentlyVisitedNode(node);
  }

  protected startResize(elem: HTMLDivElement, event: MouseEvent): void {
    const currentWidth = elem.offsetWidth;
    event.preventDefault();
    elem.style.maxWidth = 'unset';
    elem.style.width = `${currentWidth}px`;
    const startEvent = {
      element: elem,
      startX: event.clientX,
      startWidth: currentWidth,
    };

    of(startEvent)
      .pipe(
        takeWhile(() => this.$alive),
        switchMap((dragStartEvent) => {
          return this.drag$.pipe(tap((dragEvent) => this.resize(dragStartEvent, dragEvent)));
        }),
        takeUntil(this.dragEnd$)
      )
      .subscribe();
  }

  protected blockClick(event: MouseEvent): void {
    event.stopImmediatePropagation();
  }

  private resize(startEvent: ResizeEvent, dragEvent: MouseEvent): void {
    const diff = dragEvent.clientX - startEvent.startX;
    startEvent.element.style.width = `${startEvent.startWidth + diff}px`;
  }
}
