import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { LegendEntryComponent } from './legend-entry/legend-entry.component';
import { LegendComponent } from './legend.component';

@NgModule({
  declarations: [LegendComponent, LegendEntryComponent],
  imports: [CommonModule, MatRippleModule, MatTooltipModule],
  exports: [LegendComponent],
})
export class LegendModule {}
