import * as d3 from 'd3';
import { takeUntil } from 'rxjs';
import { ShiftDataFinder } from '../../data-handler/data-finder/shift-data-finder';
import { GanttCanvasShift } from '../../data-handler/data-structure/data-structure';
import { EGanttScrollContainer } from '../../html-structure/scroll-container.enum';
import { BestGantt } from '../../main';
import { ShiftBuilder } from '../../shifts/shift-builder';
import { ETimeMarkerAnchor } from '../../x-axis/x-axis';
import { EGanttShiftEditEventType } from '../shift-edit-general/edit-events/shift-edit-event-type.enum';
import { GanttShiftEditor } from '../shift-edit-general/shift-editor';
import { EGanttShiftResizeDraggingDirection } from './resize-events/resize-dragging-direction.enum';
import { IGanttShiftResizeEvent } from './resize-events/resize-event.interface';
import { GanttShiftResizeHandleBuilder } from './resize-handles/shift-resize-handle-builder';
import { GanttShiftResizeLimiter } from './resize-restrictions/shift-resize-limiter';
import { IGanttShiftResizeStateModel } from './resize-state/resize-state-model.interface';

/**
 * Shift resize handling inside canvas.
 * Responsible for building resize handles for shifts and timeperiods.
 * @keywords shift, edit, resize, resizing, change, width, handler, executer, manager
 */
export class GanttShiftResizer extends GanttShiftEditor<
  IGanttShiftResizeEvent,
  IGanttShiftResizeEvent,
  IGanttShiftResizeStateModel,
  GanttShiftResizeLimiter,
  GanttShiftResizeHandleBuilder
> {
  private _canvas: d3.Selection<SVGGElement, unknown, SVGElement, unknown>;

  constructor(ganttDiagram: BestGantt) {
    const initialShiftEditState: IGanttShiftResizeStateModel = {
      originShiftData: null,
      originalShiftProportions: { x: null, width: null },
      visualizeNotAllowed: false,
    };
    super(
      ganttDiagram,
      initialShiftEditState,
      new GanttShiftResizeLimiter(ganttDiagram),
      new GanttShiftResizeHandleBuilder(null, ganttDiagram)
    );
  }

  /**
   * Enables resize event handling.
   * @override
   * @param buildResizeHandles If false, the calback for building the resize handles will not be registered.
   * @param alternateShiftBuilders Shift builders to use for resize handle building if the default shift builder should not be used.
   */
  public build(buildResizeHandles = true, alternateShiftBuilders: { [id: string]: ShiftBuilder } = undefined): void {
    if (buildResizeHandles) {
      if (alternateShiftBuilders) {
        // build resize handles when mouse over shift
        for (const scrollContainerId in alternateShiftBuilders) {
          alternateShiftBuilders[scrollContainerId]
            .shiftMouseOver()
            .pipe(takeUntil(this.onDestroy))
            .subscribe((event) =>
              this.buildResizeHandlesByMouseEvent(event, scrollContainerId as EGanttScrollContainer)
            );
        }
      } else {
        // build resize handles when mouse over shift
        this._shiftFacade
          .shiftMouseOver()
          .pipe(takeUntil(this.onDestroy))
          .subscribe((event) => this.buildResizeHandlesByMouseEvent(event.event, event.source));
      }
    }

    // resize handle events
    this.resizeHandleBuilder.onResizeHandleDragStart
      .pipe(takeUntil(this.onDestroy))
      .subscribe((event) => this._handleShiftResizeEvent(event));
    this.resizeHandleBuilder.onResizeHandleDragUpdate
      .pipe(takeUntil(this.onDestroy))
      .subscribe((event) => this._handleShiftResizeEvent(event));
    this.resizeHandleBuilder.onResizeHandleDragEnd
      .pipe(takeUntil(this.onDestroy))
      .subscribe((event) => this._handleShiftResizeEvent(event));
  }

  //
  // RESIZE EVENT HANDLING
  //

  /**
   * Converts resize handle event data into usable data for shift resizer and triggers the correct functions.
   * @param event Resize handle drag event.
   */
  private _handleShiftResizeEvent(event: IGanttShiftResizeEvent): void {
    switch (event.type) {
      case EGanttShiftEditEventType.START:
        this._start(event);
        break;
      case EGanttShiftEditEventType.UPDATE:
        this._update(event);
        break;
      case EGanttShiftEditEventType.END:
        this._finish(event);
        break;
    }
  }

  protected _start(event: IGanttShiftResizeEvent): void {
    const resizeShiftData = event.shiftSelection.data()[0];

    const originalShiftProportions = this._shiftEditState.get('originalShiftProportions');
    originalShiftProportions.x = parseFloat(event.shiftSelection.attr('x'));
    originalShiftProportions.width = parseFloat(event.shiftSelection.attr('width'));

    this._shiftEditLimiterAdapter.setGridStartDateRef(resizeShiftData, event.orientation);

    this._shiftFacade.setShiftsAreResizing(true);

    const updateData = new Map<string, Partial<GanttCanvasShift>>();
    updateData.set(resizeShiftData.id, {
      opacity: 0.2,
    });
    this._dataHandler.updateCanvasShiftPropertiesInCanvasDataByShiftId(updateData);

    this._ganttDiagram.rerenderShiftsVertical();
    this._xAxisBuilder.setWhileResizing(true); // Set Flag to prevent double time date markers from mouseOver
    const foundShiftData = ShiftDataFinder.getShiftById(
      this._dataHandler.getOriginDataset().ganttEntries,
      resizeShiftData.id
    );
    this._shiftEditState.set({ originShiftData: foundShiftData.shift });

    this._onShiftEditSubject.next(event);
  }

  protected _update(event: IGanttShiftResizeEvent): void {
    const resizeShiftData = event.shiftSelection.data()[0];

    // get new x position of shift start/end
    const restrictedX = this._getRestrictedXCoordinate(
      event.event.x,
      resizeShiftData,
      event.orientation,
      event.scrollContainerId
    );

    // trigger before resize event
    this._beforeShiftEditSubject.next(event);

    // resize canvas shift to current restricted x coordinate
    this._resizeShift(event, restrictedX);

    this._updateCanvasShiftData(event.shiftSelection);

    // text overlay handling
    const textOverlayElement = this._ganttDiagram
      .getTextOverlay(event.scrollContainerId)
      .getTextElementById(resizeShiftData.id);

    this._ganttDiagram
      .getTextOverlay(event.scrollContainerId)
      .buildSmallerText(
        this._ganttDiagram.getRenderDataHandler().getRenderDataShifts(event.scrollContainerId),
        resizeShiftData,
        textOverlayElement,
        this._zoomTransform
      );

    this._ganttDiagram.getTooltipBuilder().removeAllTooltips();
    this._xAxisBuilder.removeAllDateMarkers();

    this._onShiftEditSubject.next(event);
    this._afterShiftEditSubject.next(event);

    // mark relevant shift dates
    this._markShiftDates(event);

    // handle resize handle blocking
    this._handleResizeHandleBlocking(event.orientation);
  }

  protected _finish(event: IGanttShiftResizeEvent): void {
    const target = event.shiftSelection;
    // get data directly because sometimes ref is not up to date
    const targetData = ShiftDataFinder.getCanvasShiftById(
      this._dataHandler.getCanvasShiftDataset(),
      target.data()[0].id
    )[0];

    const scale = this._xAxisBuilder.getGlobalScale();

    this._updateCanvasShiftData(target);

    // change start and end point of target shift
    const shiftOriginData = ShiftDataFinder.getShiftById(
      this._dataHandler.getOriginDataset().ganttEntries,
      targetData.id
    ).shift;

    shiftOriginData.timePointStart = this._xAxisBuilder.pxToTime(targetData.x, scale);
    shiftOriginData.timePointEnd = this._xAxisBuilder.pxToTime(targetData.x + targetData.width, scale);

    let originDataUpdated = false;
    // check for earliestStartTime & update shift data accordingly
    if (
      shiftOriginData.modificationRestriction?.earliestStartTime &&
      !shiftOriginData.modificationRestriction?.free_movement
    ) {
      if (shiftOriginData.timePointStart.getTime() < shiftOriginData.modificationRestriction.earliestStartTime) {
        shiftOriginData.timePointStart = new Date(shiftOriginData.modificationRestriction.earliestStartTime); // update origin Data
        originDataUpdated = true;
      }
    }
    // check for latestEndTime & update shift data accordingly
    if (
      shiftOriginData.modificationRestriction?.latestEndTime &&
      !shiftOriginData.modificationRestriction?.free_movement
    ) {
      if (shiftOriginData.timePointEnd.getTime() > shiftOriginData.modificationRestriction.latestEndTime) {
        shiftOriginData.timePointEnd = new Date(shiftOriginData.modificationRestriction.latestEndTime); // update origin data
        originDataUpdated = true;
      }
    }
    // update visual shift data
    if (originDataUpdated) {
      const updateData = new Map<string, Partial<GanttCanvasShift>>();
      updateData.set(targetData.id, {
        x: scale(shiftOriginData.timePointStart),
        width: scale(shiftOriginData.timePointEnd) - scale(shiftOriginData.timePointStart),
      });
      this._dataHandler.updateCanvasShiftPropertiesInCanvasDataByShiftId(updateData);
    }

    // reset state management
    this._shiftEditState.reset();

    // clean up gantt after shift resize
    this._xAxisBuilder.removeAllDateMarkers();
    this._dataHandler.initCanvasShiftData();
    this._ganttDiagram.rerenderShiftsVertical();
    this._xAxisBuilder.setWhileResizing(false);
    this.resizeHandleBuilder.removeAllResizeHandles();
    this._shiftFacade.setShiftsAreResizing(false);

    // trigger shift resize end events
    this._onShiftEditSubject.next(event);
    this._afterShiftEditSubject.next(event);
  }

  //
  // HELPER METHODS
  //

  /**
   * Handles the rendering of relevant shift dates on the x axis.
   * @param event Edit update event.
   */
  private _markShiftDates(event: IGanttShiftResizeEvent): void {
    const canvasShiftData = event.shiftSelection.data()[0];
    const shiftData = this._shiftEditState.get('originShiftData');
    if (!shiftData || !canvasShiftData) return;

    // render current start & end dates
    const resizeShiftX = canvasShiftData.x * this._zoomTransform.k + this._zoomTransform.x;
    const resizeShiftWidth = canvasShiftData.width * this._zoomTransform.k;
    const shiftStartDate = this._xAxisBuilder.pxToTime(resizeShiftX, this._currentScale);
    const shiftEndDate = this._xAxisBuilder.pxToTime(resizeShiftX + resizeShiftWidth, this._currentScale);
    this._xAxisBuilder.addMarkerByDate(shiftEndDate, ETimeMarkerAnchor.START, null, 'white', true);
    this._xAxisBuilder.addMarkerByDate(shiftStartDate, ETimeMarkerAnchor.END, null, 'white', true);

    // render original shift start/end
    if (
      event.orientation === EGanttShiftResizeDraggingDirection.RIGHT &&
      shiftEndDate.getTime() !== shiftData.timePointEnd.getTime()
    ) {
      this._xAxisBuilder.addMarkerByDate(shiftData.timePointEnd, ETimeMarkerAnchor.START, null, 'gainsboro', true);
    } else if (
      event.orientation === EGanttShiftResizeDraggingDirection.LEFT &&
      shiftStartDate.getTime() !== shiftData.timePointStart.getTime()
    ) {
      this._xAxisBuilder.addMarkerByDate(shiftData.timePointStart, ETimeMarkerAnchor.START, null, 'gainsboro', true);
    }

    // render ES/LE markers
    this._ganttDiagram.markShiftDueDates(shiftData.id, true);
  }

  /**
   * Resizing function to change shift length by dragging.
   * @param event Shift resize event.
   * @param x X coordinate to move change the shift start/end to.
   */
  private _resizeShift(event: IGanttShiftResizeEvent, x: number): void {
    const resizeRect = event.shiftSelection;
    const originalShiftProportions = this._shiftEditState.get('originalShiftProportions');

    if (event.orientation === EGanttShiftResizeDraggingDirection.LEFT) {
      const width = this._getAllowedShiftWidth(
        originalShiftProportions.x - x + originalShiftProportions.width,
        event.shiftSelection,
        event.orientation
      );
      resizeRect
        .attr('x', function () {
          const newX = parseFloat(d3.select(this).attr('x')) + (parseFloat(d3.select(this).attr('width')) - width);
          return newX;
        })
        .attr('width', width);
    } else if (event.orientation === EGanttShiftResizeDraggingDirection.RIGHT) {
      const width = this._getAllowedShiftWidth(x - originalShiftProportions.x, event.shiftSelection, event.orientation);
      resizeRect.attr('width', width);
    }
  }

  /**
   * Updates the canvas shift data according to the current state of its d3 selection.
   * @param shiftSelection Shift selection containing the shift data to update.
   */
  private _updateCanvasShiftData(
    shiftSelection: d3.Selection<SVGRectElement, GanttCanvasShift, d3.BaseType, undefined>
  ): void {
    const shiftData = shiftSelection.data()[0];
    const strokeWidth = parseFloat(shiftSelection.attr('stroke-width')) || 0;

    const updateData = new Map<string, Partial<GanttCanvasShift>>();
    updateData.set(shiftData.id, {
      width: (parseFloat(shiftSelection.attr('width')) + strokeWidth) / this._zoomTransform.k,
      x: (parseFloat(shiftSelection.attr('x')) - strokeWidth / 2 - this._zoomTransform.x) / this._zoomTransform.k,
    });
    this._dataHandler.updateCanvasShiftPropertiesInCanvasDataByShiftId(updateData);
  }

  //
  // RESTRICTION HANDLING
  //

  /**
   * Checks shift width restrictions from config and returns a valid shift width value.
   * @param shiftWidth Shift width to be controlled.
   * @returns A valid shift width.
   */
  private _getAllowedShiftWidth(
    shiftWidth: number,
    shiftSelection: d3.Selection<SVGRectElement, GanttCanvasShift, d3.BaseType, undefined>,
    orientation: EGanttShiftResizeDraggingDirection
  ): number {
    const oldWidth = shiftWidth;

    const startTime = this._xAxisBuilder.pxToTime(0, this._currentScale).getTime();
    const endTime = this._xAxisBuilder.pxToTime(shiftWidth, this._currentScale).getTime();
    const currentTimespan = endTime - startTime; // calculated time span in ms

    const minTime = this._ganttConfig.getMinShiftTimespan();
    const maxTime = this._ganttConfig.getMaxShiftTimespan();

    let newTimespan: number = null;
    if (minTime && currentTimespan < minTime && !newTimespan) {
      newTimespan = minTime;
    } // check min shift time span
    if (maxTime && currentTimespan > maxTime && !newTimespan) {
      newTimespan = maxTime;
    } // check max shift time span

    if (newTimespan) {
      const strokeWidth = parseFloat(shiftSelection.attr('stroke-width')) || 0;
      const diff = strokeWidth / 2;

      const originShiftStartX = parseFloat(shiftSelection.attr('x'));
      const originShiftEndX = originShiftStartX + parseFloat(shiftSelection.attr('width'));

      const originShiftStart = this._xAxisBuilder.pxToTime(originShiftStartX - diff, this._currentScale);
      const originShiftEnd = this._xAxisBuilder.pxToTime(originShiftEndX + diff, this._currentScale);

      if (orientation === EGanttShiftResizeDraggingDirection.LEFT) {
        const newShiftStart = this._timeGradationHandler.getAliginDateByDate(
          new Date(originShiftEnd.getTime() - newTimespan)
        );
        const newShiftStartX = this._currentScale(newShiftStart) + diff;
        shiftWidth = originShiftEndX - newShiftStartX;
      } else if (orientation === EGanttShiftResizeDraggingDirection.RIGHT) {
        const newShiftEnd = this._timeGradationHandler.getAliginDateByDate(
          new Date(originShiftStart.getTime() + newTimespan)
        );
        const newShiftEndX = this._currentScale(newShiftEnd) - diff;
        shiftWidth = newShiftEndX - originShiftStartX;
      }
    }

    const newWidth = Math.max(shiftWidth, this._ganttConfig.getMinCanvasShiftWidth());

    // if resizing got blocked -> visualize it by blocking the resize handle
    this._blockResizeHandleIfVisibleDiff(oldWidth, newWidth);

    return newWidth;
  }

  /**
   * Checks all resize restrictions and calculates a x coordinate which does not violate any restrictions.
   * @param eventX X coordinate as received from the resize event.
   * @param shiftData Canvas shift data to check the restriction for.
   * @param orientation Resize dragging orientation of the event.
   * @param scrollContainerId Id of the scroll container the resize event originates from.
   * @returns X coordinate which does not violate any resize restrictions.
   */
  private _getRestrictedXCoordinate(
    eventX: number,
    shiftData: GanttCanvasShift,
    orientation: EGanttShiftResizeDraggingDirection,
    scrollContainerId: EGanttScrollContainer
  ): number {
    const shiftStrokeWidth = this._shiftFacade
      .getShiftBuilder(scrollContainerId)
      .getShiftCalculationStrategy()
      .getShiftStrokeWidth(shiftData);

    // 1) Check x coordinate for violated restrictions.
    const x1 =
      this._shiftEditLimiterAdapter.getRestrictedXCoordinate(eventX - shiftStrokeWidth / 2, orientation, shiftData) +
      shiftStrokeWidth / 2;

    // if resizing got blocked -> visualize it by blocking the resize handle
    this._blockResizeHandleIfVisibleDiff(eventX, x1);

    // 2) Check x coordinate for violated ES/LE restrictions.
    let x2 = x1;
    x2 = this._shiftEditLimiterAdapter.getEarliestStartConformingXCoordinate(x2, shiftData);
    x2 = this._shiftEditLimiterAdapter.getLatestEndConformingXCoordinate(x2, shiftData);

    // if resizing got blocked -> visualize it by blocking the resize handle
    this._blockResizeHandleIfVisibleDiff(x1, x2);

    x2 += (shiftStrokeWidth / 2) * (orientation === EGanttShiftResizeDraggingDirection.LEFT ? 1 : -1);

    // 3) Adapt x coordinate to gantt time grid.
    const x3 = this._shiftEditLimiterAdapter.getGradiatedXCoordinate(x2, orientation, shiftData);

    // 4) Check if x coordinate is outside the shift canvas.
    const canvasWidth = this._shiftFacade
      .getShiftBuilder(scrollContainerId)
      .getCanvasInFrontShifts()
      .node().clientWidth;
    let x4 = x3;
    if (x4 < 0) x4 = 0;
    if (x4 > canvasWidth) x4 = canvasWidth;

    // if resizing got blocked -> visualize it by blocking the resize handle
    this._blockResizeHandleIfVisibleDiff(x3, x4);

    // 5) Check if x coordinate violates translation area restrictions.
    const x5 = this._shiftEditLimiterAdapter.getTranslationAreaConformingXCoordinate(x4, orientation, shiftData);

    // if resizing got blocked -> visualize it by blocking the resize handle
    this._blockResizeHandleIfVisibleDiff(x4, x5);

    return x5;
  }

  //
  // RESIZE HANDLES
  //

  /**
   * Triggers the building of resize handles by the specified mouse event.
   * @param event Mouse event to build the resize handles for.
   * @param source
   */
  public buildResizeHandlesByMouseEvent(event: MouseEvent, source: EGanttScrollContainer): void {
    const target: d3.Selection<any, any, null, undefined> = d3.select(event.target as any),
      targetData = target.data()[0];

    if (!this._shiftEditLimiterAdapter.allowShiftResizing || !targetData.editable) return;
    const targetWrapper: d3.Selection<SVGRectElement, GanttCanvasShift, d3.BaseType, undefined> = d3.select(
      (event.target as any).parentNode
    );

    this._buildResizeHandles(target, targetWrapper, source);
  }

  /**
   * Removes all current resize handles and builds new resize handles for the specified shift selection.
   * @param shiftSelection Shift selection to build the resize handles for.
   * @param canvasWrapper Selection into which the resize handles should be built.
   * @param scrollContainerId
   */
  private _buildResizeHandles(
    shiftSelection: d3.Selection<SVGRectElement, GanttCanvasShift, d3.BaseType, undefined>,
    canvasWrapper: d3.Selection<SVGRectElement, GanttCanvasShift, d3.BaseType, undefined>,
    scrollContainerId: EGanttScrollContainer
  ): void {
    this._removeCanvas();
    this._canvas = this._createCanvas(canvasWrapper);
    this.resizeHandleBuilder.setParentNode(this._canvas);

    const canvasShift = shiftSelection.data()[0];

    // build resize handles
    this._checkRestrictionsAndBuildResizeHandles(canvasShift, shiftSelection, scrollContainerId);
  }

  /**
   * Checks all resize restrictions for the specified shift and builds the resize handles according to them.
   * @param shift Canvas shift to build the resize handles for.
   * @param shiftSelection
   * @param scrollContainerId
   */
  private _checkRestrictionsAndBuildResizeHandles(
    shift: GanttCanvasShift,
    shiftSelection: d3.Selection<SVGRectElement, GanttCanvasShift, d3.BaseType, undefined>,
    scrollContainerId: EGanttScrollContainer
  ): void {
    // get resize handle restrictions
    const restrictions = this._shiftEditLimiterAdapter.getResizeHandleRestrictionsByShift(shift);
    // build resize handles
    this.resizeHandleBuilder.buildResizeHandles(
      shift,
      shiftSelection,
      restrictions.left,
      restrictions.right,
      scrollContainerId
    );
  }

  /**
   * Sets the flag for blocking the currently active resize handle if the specified pixel values have a visible difference.
   * @param val1 1st pixel value.
   * @param val2 2nd pixel value.
   */
  private _blockResizeHandleIfVisibleDiff(val1: number, val2: number): void {
    if (this._shiftEditLimiterAdapter.isVisiblePixelDiff(val1, val2)) {
      this._shiftEditState.set({ visualizeNotAllowed: true });
    }
  }

  /**
   * Handles the (un-)blocking of resize handles depending on the current value of the corresponing state.
   * @param orientation Dragging direction of the resize handle to handle the (un-)blocking for.
   */
  private _handleResizeHandleBlocking(orientation: EGanttShiftResizeDraggingDirection): void {
    if (this._shiftEditState.get('visualizeNotAllowed')) {
      this.resizeHandleBuilder.block(orientation);
      this._shiftEditState.set({ visualizeNotAllowed: false });
    } else {
      this.resizeHandleBuilder.unblock(orientation);
    }
  }

  /**
   * Creates a SVG group node for resize handles and returns it.
   * @param canvasWrapper Parent node where the canvas will be created in.
   * @returns Created canvas.
   */
  private _createCanvas(
    canvasWrapper: d3.Selection<SVGRectElement, GanttCanvasShift, d3.BaseType, undefined>
  ): d3.Selection<SVGGElement, unknown, SVGElement, unknown> {
    return d3
      .select<SVGElement, unknown>(canvasWrapper.node().parentNode as SVGElement)
      .append<SVGGElement>('g')
      .attr('class', 'gantt-shift-resize-wrapper');
  }

  /**
   * Removes the resize handle canvas from DOM.
   * @keywords remove, clear, empty, delete, canvas, shift, resize
   */
  private _removeCanvas(): void {
    if (this._canvas) this._canvas.remove();
  }

  /**
   * Removes all resize handle SVG elements.
   * @keywords remove, clear, empty, delete, canvas, resize, handler
   */
  public removeAllResizeHandles(): void {
    this.resizeHandleBuilder.removeAllResizeHandles();
  }

  //
  // GETTER & SETTER
  //

  /**
   * Returns the resize hanlde builder of this shift resizer.
   */
  public get resizeHandleBuilder(): GanttShiftResizeHandleBuilder {
    return this._shiftEditRestrictionVisualizer;
  }
}
