<div
  *ngIf="column"
  class="column-header-template-container"
  [id]="tableId + '_' + column.id + '_' + columnIndex"
  (mouseleave)="onMouseLeave()"
>
  <div
    [ngStyle]="{
      width:
        column.filterSortObject.isSortActive() || column.filterSortObject.isFilterActive()
          ? 'calc(100% - 20px)'
          : '100%'
    }"
    *ngIf="!column.iconConfig || (column.iconConfig && column.iconConfig.labelVisibile)"
    class="labelcontainer"
  >
    <span>{{ column.label }}</span>
  </div>

  <button
    mat-icon-button
    class="filterIconButton"
    (mousedown)="mouseDownSortFilterBtn()"
    (mouseup)="removeSortFilter()"
    *ngIf="column.filterSortObject.isSortActive() || column.filterSortObject.isFilterActive()"
  >
    <ng-container *ngIf="!column.filterSortObject.isSortActive() && column.filterSortObject.isFilterActive()">
      <app-image [label]="iconSet.filter"></app-image>
    </ng-container>

    <ng-container *ngIf="column.filterSortObject.isSortActive() && !column.filterSortObject.isFilterActive()">
      <ng-container [ngSwitch]="column.filterSortObject.getSortDirection()">
        <ng-container *ngSwitchCase="'asc'">
          <app-image [label]="iconSet.sortAsc"></app-image>
        </ng-container>
        <ng-container *ngSwitchCase="'desc'">
          <app-image [label]="iconSet.sortDesc"></app-image>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="column.filterSortObject.isSortActive() && column.filterSortObject.isFilterActive()">
      <ng-container [ngSwitch]="column.filterSortObject.getSortDirection()">
        <ng-container *ngSwitchCase="'asc'">
          <app-image [label]="iconSet.filterAsc"></app-image>
        </ng-container>
        <ng-container *ngSwitchCase="'desc'">
          <app-image [label]="iconSet.filterDesc"></app-image>
        </ng-container>
      </ng-container>
    </ng-container>
  </button>
</div>
