import { Action } from '@app-modeleditor/components/button/action/action';
import { Button } from '@app-modeleditor/components/button/button';
import { Content } from '@app-modeleditor/components/content/content';
import { ContentPart } from '@app-modeleditor/components/content/content-part/content-part';
import { EntryCollection } from '@app-modeleditor/components/entry-collection/entry-collection';
import { EntryElement } from '@app-modeleditor/components/entry-collection/entry-element';
import { EntryElementValue } from '@app-modeleditor/components/entry-collection/entry-element-value';
import { EFieldType } from '@app-modeleditor/components/entry-collection/field-type.enum';
import { Lightbox } from '@app-modeleditor/components/lightbox/lightbox';
import { MultiObjectSelector } from '@app-modeleditor/components/selector/multi-object-selector';

export class TestLightbox extends Lightbox {
  constructor() {
    super();

    // init content
    const c: Content = new Content();

    // init contentpart
    const cp: ContentPart = new ContentPart();

    // init entry-collection
    const ee: EntryCollection = new EntryCollection();
    ee.setId('my-collection');
    ee.setGetDataAutomatically(false);

    const e: MultiObjectSelector = new MultiObjectSelector();
    e.setName('FOO')
      .setAvailableOptions([
        new EntryElementValue().setValue(new EntryElementValue().setName('FOO').setValue('foo')).setName('A'),
        new EntryElementValue().setValue(new EntryElementValue().setName('BAR').setValue('bar')).setName('B'),
      ])
      .setRequired(true)
      .setValue(new EntryElementValue().setValue(null).setName('A'));

    ee.setEntryElements([e]);

    cp.setContentElements([ee]);
    c.setContentParts([cp]);
    this.setContent(c);
  }
}
