import { Injectable } from '@angular/core';
import { EGanttSubMenuUpdateMessageType } from '@app-modules/saxms-submenu-elements/saxms-submenu.enum';
import { IGanttSubMenuUpdateMessage } from '@app-modules/saxms-submenu-elements/saxms-submenu.interface';
import { SaxMsSubmenuService } from '@app-modules/saxms-submenu-elements/saxms-submenu.service';
import { EGanttStickyRowStrategy } from '@gantt/public-api';
import { GanttLibService } from 'frontend/src/dashboard/gantt/gantt/gantt-lib.service';
import { SaxMsBestGanttSettings } from 'frontend/src/dashboard/gantt/gantt/saxms-best-gantt.settings';
import { GanttPluginHandlerService } from 'frontend/src/dashboard/gantt/general/plugin/gantt-plugin-handler.service';
import { EColorizeStrategyOption } from 'frontend/src/dashboard/saxms-best-gantt/legend/colorize-startegy-option';
import { LegendCommunicationService } from '../../../gantt/dock/views/legend/legend-communication.service';
import { GanttEssentialPlugIns } from '../../plugin/e-gantt-essential-plugins';
import { GanttEarliestStartLatestEndPlugIn } from '../../plugin/plugin-list/earliest-start-latest-end-visualizer/earliest-start-latest-end-visualizer';
import { GanttIndexCardsPlugIn } from '../../plugin/plugin-list/index-cards/index-cards.plugin';
import { GanttTemplateDataService } from '../../template-data/gantt-template-data.service';
import { EGanttToolbarIdentifier } from '../../toolbar/gantt-toolbar-identifier';

/**
 * Integrates and applies settings of gantt plugins in gantt diagram.
 */
@Injectable()
export class PluginSettingsIntegratorService {
  constructor(
    private submenuService: SaxMsSubmenuService,
    private legendCommunicationService: LegendCommunicationService,
    private ganttPluginHandlerService: GanttPluginHandlerService,
    private ganttLibService: GanttLibService,
    private ganttTemplateService: GanttTemplateDataService
  ) {}

  public integratePluginSettings(settings: SaxMsBestGanttSettings) {
    if (!this.ganttPluginHandlerService) {
      console.warn('No local action handler initialized.');
      return;
    }

    this._handleShowIndexCardTimespan(settings.showIndexCardTimespan);
    this._handleEnableIndexCardView(settings.enableIndexCardView);
    this._handleShowIndexCardEmptyAttributes(settings.showIndexCardEmptyAttributes);
    this._toggleGanttEntryBackgroundColor(settings.enableGanttEntryBackgroundColor);
    this._toggleGanttEarliestStartLatestEndVisualization(settings.enableGanttEarliestStartLatestEnd);
    this._toggleGanttFirstRowStickyMode(
      settings.enableFirstRowStickyMode,
      settings.enableFirstRowWithChildRowsStickyMode
    );
    this._setColorizeStrategyOption(settings.colorizeStrategyOption);
    this._setActiveSuperblockView(settings.activeSuperBlockType);
    this._setShowOnlyViewportEntriesInLegend(settings.showOnlyViewportInLegend);
    this._changeBlockSortingAttribute(settings.blockSortingAttribute);
  }

  private _setColorizeStrategyOption(option: EColorizeStrategyOption) {
    this.legendCommunicationService.colorizeStrategyOption = option || EColorizeStrategyOption.DEFAULT;
    this.legendCommunicationService.updateColorizeStrategy();
  }

  private _setShowOnlyViewportEntriesInLegend(isActive: boolean) {
    this.legendCommunicationService.showOnlyViewportEntries = !!isActive;
    this.legendCommunicationService.updateColorizeStrategy();
  }

  private _toggleGanttFirstRowStickyMode(
    enableFirstRowStickyMode: boolean,
    enableFirstRowWithChildRowsStickyMode: boolean
  ): void {
    const stickyRowStrategy = enableFirstRowStickyMode
      ? enableFirstRowWithChildRowsStickyMode
        ? EGanttStickyRowStrategy.STICKY_FIRST_ROW_WITH_CHILD_ROWS
        : EGanttStickyRowStrategy.STICKY_FIRST_ROW
      : EGanttStickyRowStrategy.STICKY_PARENT_ROWS;
    this.ganttLibService.bestGantt.getConfig().setStickyRowStrategy(stickyRowStrategy);
  }

  private _toggleGanttEntryBackgroundColor(bool: boolean): void {
    if (bool === undefined) {
      bool = true;
    } // activate if not defined for row search visualization

    this.ganttLibService.bestGantt.getConfig().setDefinedRowBackgroundColorApplied(bool);

    // Sync submenu elements
    const message: IGanttSubMenuUpdateMessage = {
      elementId: EGanttToolbarIdentifier.TOGGLE_GANTT_ENTRY_BACKGROUND_COLOR,
      type: EGanttSubMenuUpdateMessageType.UPDATE,
      value: bool,
    };
    this.submenuService.triggerElementById(message);
  }

  private _toggleGanttEarliestStartLatestEndVisualization(bool: boolean): void {
    const plugin: GanttEarliestStartLatestEndPlugIn = this.ganttPluginHandlerService.getEssentialPlugIn(
      GanttEssentialPlugIns.GanttEarliestStartLatestEndVisualizer
    );
    plugin.getJsPlugin().setActive(!!bool);

    // Sync submenu elements
    const message: IGanttSubMenuUpdateMessage = {
      elementId: EGanttToolbarIdentifier.TOGGLE_GANTT_EARLIEST_START_LATEST_END,
      type: EGanttSubMenuUpdateMessageType.UPDATE,
      value: bool,
    };
    this.submenuService.triggerElementById(message);
  }

  private _setActiveSuperblockView(viewType: number): void {
    // Sync submenu elements
    const message: IGanttSubMenuUpdateMessage = {
      elementId: EGanttToolbarIdentifier.SUPER_BLOCK_VIEW,
      type: EGanttSubMenuUpdateMessageType.UPDATE,
      value: viewType,
    };
    this.submenuService.triggerElementById(message);
  }

  private _handleShowIndexCardTimespan(isShowTimeSpan: boolean) {
    const indexCardPlugin: GanttIndexCardsPlugIn = this.ganttPluginHandlerService.getEssentialPlugIn(
      GanttEssentialPlugIns.IndexCardBuilderPlugIn
    );
    indexCardPlugin.setShowTimeSpan(!!isShowTimeSpan);
  }

  private _handleShowIndexCardEmptyAttributes(isShowEmptyAttributes: boolean) {
    const indexCardPlugin: GanttIndexCardsPlugIn = this.ganttPluginHandlerService.getEssentialPlugIn(
      GanttEssentialPlugIns.IndexCardBuilderPlugIn
    );
    indexCardPlugin.setShowEmptyAttributes(!!isShowEmptyAttributes);
  }

  private _handleEnableIndexCardView(enableIndexCardView: boolean) {
    const indexCardPlugin: GanttIndexCardsPlugIn = this.ganttPluginHandlerService.getEssentialPlugIn(
      GanttEssentialPlugIns.IndexCardBuilderPlugIn
    );

    if (enableIndexCardView) {
      indexCardPlugin.activateIndexCardView(false);
    } else {
      indexCardPlugin.deactivateIndexCardView();
    }
  }

  private _changeBlockSortingAttribute(attribute: string): void {
    const overlappingShiftsPlugIn = this.ganttPluginHandlerService.getEssentialPlugIn(
      GanttEssentialPlugIns.OverlappingShiftsPlugIn
    );
    const attributeMapping = this.ganttTemplateService.getTemplateData().getAttributeMapping();
    const attributeList = Object.keys(attributeMapping).map((attribute) => attributeMapping[attribute].localization);
    const isValid = attributeList.includes(attribute);

    overlappingShiftsPlugIn.setSortAttribute(attribute);
    overlappingShiftsPlugIn.changeStrategy(attribute && isValid ? 'sortByAttribute' : 'default');
  }
}
