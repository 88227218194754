import { Resource } from 'frontend/src/dashboard/model/resource/resource';

export class Tenant extends Resource {
  private selected: boolean;

  public isSelected(): boolean {
    return typeof this.selected === 'boolean' ? this.selected : false;
  }

  public setSelected(selected: boolean): this {
    this.selected = selected;
    return this;
  }
}
