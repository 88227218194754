import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { TranslateModule } from '@ngx-translate/core';
import { FileuploaderComponent } from './fileuploader.component';

@NgModule({
  imports: [CommonModule, MatFormFieldModule, MatIconModule, TranslateModule, MatInputModule, MatButtonModule],
  declarations: [FileuploaderComponent],
  exports: [FileuploaderComponent],
})
export class FileuploaderModule {}
