import * as d3 from 'd3';
import { takeUntil } from 'rxjs';
import { GanttCanvasRow } from '../../data-handler/data-structure/data-structure';
import { GanttUtilities } from '../../gantt-utilities/gantt-utilities';
import { NodeProportionsStateConnector } from '../../html-structure/node-proportion-state/node-proportion-state-connector';
import { EGanttScrollContainer } from '../../html-structure/scroll-container.enum';
import { BestGantt } from '../../main';
import { GanttSplitOverlappingShifts } from './split-overlapping-shifts-executer';

/**
 * Builds indicators to visualize if rows are not affected by splitting.
 */
export class NotAffectedRowsIndicator {
  private _canvas: { [id: string]: d3.Selection<HTMLDivElement, undefined, d3.BaseType, undefined> } = {};

  constructor(
    private _ganttDiagram: BestGantt,
    private _notAffectedRowsList: string[],
    private _splitPlugIn: GanttSplitOverlappingShifts
  ) {
    this._init();
  }

  /**
   * Initialize indicator.
   */
  private _init(): void {
    // create canvas
    for (const scrollContainerId of Object.values(EGanttScrollContainer)) {
      this._canvas[scrollContainerId] = this._ganttDiagram
        .getHTMLStructureBuilder()
        .getShiftContainer(scrollContainerId)
        .append('div')
        .attr('class', 'notAffectedRowIndicatorWrapper')
        .style('z-index', 2)
        .style('pointer-events', 'none');
    }

    this._registerCallBacks();
  }

  /**
   * Update canvas
   */
  public update(): void {
    this._updateCanvasProportions();
    this.build();
  }

  private _registerCallBacks() {
    this._ganttDiagram
      .getVerticalScrollHandler()
      .onScrollVerticalUpdate.pipe(takeUntil(this._splitPlugIn.onDestroy))
      .subscribe(() => this.build());
  }

  /**
   * Builds the indicators
   */
  public build(): void {
    const filteredYAxisDataSet = this._getFilteredYAxisDataSet();

    for (const key in filteredYAxisDataSet) {
      const notAffectedRowIndicator = this._canvas[key]
        .selectAll<HTMLDivElement, GanttCanvasRow>('.notAffectedRowIndicator')
        .data(filteredYAxisDataSet[key]);

      notAffectedRowIndicator.style('top', (d) => {
        return `${this._ganttDiagram.getRenderDataHandler().getStateStorage().getYPositionRow(d.id)}px`;
      });

      notAffectedRowIndicator
        .enter()
        .append('div')
        .attr('class', 'notAffectedRowIndicator')
        .text('+')
        .style('position', 'absolute')
        .style('left', 0)
        .style('top', (d) => `${this._ganttDiagram.getRenderDataHandler().getStateStorage().getYPositionRow(d.id)}px`)
        .style('width', '15px')
        .style('height', '15px')
        .style('background-color', '#0000008a')
        .style('color', 'white')
        .style('display', 'flex')
        .style('justify-content', 'center')
        .style('align-items', 'center')
        .style('font-size', '20px')
        .style('pointer-events', 'auto')
        .style('cursor', 'pointer')
        .on('mouseover', (event) => {
          const mouse = [event.x, event.y];
          this._ganttDiagram
            .getTooltipBuilder()
            .addTooltipToHTMLBody(mouse[0], mouse[1], 'Block-Überlappung aus, Zeilen teilen');
        })
        .on('mouseout', () => {
          this._ganttDiagram.getTooltipBuilder().removeAllTooltips();
        })
        .on('click', (event, d) => {
          this._ganttDiagram.getTooltipBuilder().removeAllTooltips();
          this._splitPlugIn.resetSplitOverlappingShifts(false);
          this._splitPlugIn.toggleNotAffectedRows(d.id);
          this._splitPlugIn.splitOverlappingShifts(true);
        });

      notAffectedRowIndicator.exit().remove();
    }
  }

  private _getFilteredYAxisDataSet(): { [id: string]: GanttCanvasRow[] } {
    const scrollContainerIds = [EGanttScrollContainer.DEFAULT];
    if (this._ganttDiagram.getConfig().showStickyRows()) {
      scrollContainerIds.push(EGanttScrollContainer.STICKY_ROWS);
    }

    const finalFilteredYAxisDataSet: { [id: string]: GanttCanvasRow[] } = {};

    for (const scrollContainerId of scrollContainerIds) {
      const nodeProportionsState = new NodeProportionsStateConnector(
        this._ganttDiagram.getNodeProportionsState(),
        scrollContainerId
      );

      let filteredYAxisDataSet = GanttUtilities.filterDataSetByViewPort(
        this._ganttDiagram.getHTMLStructureBuilder().getYAxisContainer(scrollContainerId).node(),
        this._ganttDiagram.getRenderDataHandler().getRenderDataYAxis(scrollContainerId),
        this._ganttDiagram.getRenderDataHandler(),
        0,
        nodeProportionsState.getShiftViewPortProportions().height,
        nodeProportionsState.getScrollTopPosition()
      );
      filteredYAxisDataSet = filteredYAxisDataSet.filter((elem) => this._notAffectedRowsList.includes(elem.id));

      finalFilteredYAxisDataSet[scrollContainerId] = filteredYAxisDataSet;
    }
    return finalFilteredYAxisDataSet;
  }

  /**
   * Updates the height and width of svg canvas.
   */
  private _updateCanvasProportions(): void {
    for (const key in this._canvas) {
      this._canvas[key].style(
        'height',
        this._ganttDiagram.getNodeProportionsState().getYAxisProportions(key as EGanttScrollContainer).height
      );
    }
  }

  /**
   * Remove canvas and callbacks
   */
  public remove(): void {
    for (const key in this._canvas) {
      this._canvas[key].remove();
    }
  }
}
