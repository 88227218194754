import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { takeWhile } from 'rxjs/operators';
import { ViewService } from './../../../../view/view.service';

@Component({
  selector: 'ui-create-dialog',
  templateUrl: './ui.create.dialog.html',
  styleUrls: ['../ui.dialog.scss'],
  providers: [],
})
export class UiCreateDialog implements OnInit, OnDestroy {
  public text = '';
  public options: Array<any> = [];
  public selectedItem: any;
  public styleRef;
  public title: string = null;
  label = '';
  hasName: boolean;
  private isAlive = true;

  constructor(
    public dialogRef: MatDialogRef<UiCreateDialog>,
    public globalViewService: ViewService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (this.data && this.data.createRestUrls) {
      for (const key in this.data.createRestUrls) {
        this.options.push({
          title: key,
          value: this.data.createRestUrls[key],
        });
      }

      this.selectedItem = this.options[0];
    }

    if (this.data.name) {
      this.title = this.data.name;
    }

    this.hasName = this.data.hasName;
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }

  ngOnInit(): void {
    this.globalViewService
      .getCurrentTheme()
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((style) => (this.styleRef = style));
  }

  /**
   * returns the selected Item
   */
  public create(): void {
    this.dialogRef.close({ selectedItem: this.selectedItem.value, label: this.hasName ? this.label : null });
  }

  /**
   * close without any action performed
   */
  public cancel(): void {
    this.dialogRef.close(null);
  }

  public getDisabledStateOfSave(): boolean {
    if (this.hasName) {
      return !this.label || this.label === '';
    } else {
      return !this.selectedItem;
    }
  }
}
