import { Pipe, PipeTransform } from '@angular/core';
import { millisecondsToDate } from '../../model-editor/ui/output-formatter/date.util';

@Pipe({
  name: 'millisecondsToDate',
})
export class MillisecondsToDatePipe implements PipeTransform {
  transform(milliseconds: number, timeZone?: boolean): any {
    if (!milliseconds) return null;
    if (!timeZone) timeZone = false;
    return millisecondsToDate(milliseconds, timeZone);
  }
}
