import { takeUntil } from 'rxjs';
import { BestGantt } from '../../main';

export class MainYAxisCallbacks {
  constructor(private _ganttDiagram: BestGantt) {}

  /**
   * Adds y axis callbacks.
   */
  public initCallbacks(): void {
    this._ganttDiagram
      .getYAxisFacade()
      .onDragStart.pipe(takeUntil(this._ganttDiagram.onDestroy))
      .subscribe((event) =>
        this._ganttDiagram.getYAxisFacade().getYAxisTranslator(event.source).translationStart(event.event)
      );
    this._ganttDiagram
      .getYAxisFacade()
      .onDragUpdate.pipe(takeUntil(this._ganttDiagram.onDestroy))
      .subscribe((event) =>
        this._ganttDiagram.getYAxisFacade().getYAxisTranslator(event.source).translationDragging(event.event)
      );
    this._ganttDiagram
      .getYAxisFacade()
      .onDragEnd.pipe(takeUntil(this._ganttDiagram.onDestroy))
      .subscribe((event) => this._ganttDiagram.getYAxisFacade().getYAxisTranslator(event.source).translationEnd());
  }
}
