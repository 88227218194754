import { Injectable, Type } from '@angular/core';
import { TemplateResourceAdapter } from '@app-modeleditor/utils/template-resource-factory.service';
import { TemplateAdapter } from '../../utils/template-factory.service';
import { Adapter } from '../../../core/adapter';
import { MenuAdapter } from '../tree/menu-factory.service';
import { ColumnListSelector } from './column-list-selector';
import { ColumnListSelectorElement } from './column-list-selector-element';
import { ColumnListSelectorValue } from './column-list-selector-value';

@Injectable({
  providedIn: 'root',
})
export class ColumnListSelectorAdater implements Adapter<ColumnListSelector> {
  constructor(private menuFactory: MenuAdapter, private templateResourceFactory: TemplateResourceAdapter) {}

  adapt(item: any): ColumnListSelector {
    throw new Error('Method not implemented.');
  }
  inherit?<T>(type: Type<any>, item: any): T {
    throw new Error('Method not implemented.');
  }

  applyValues<T extends ColumnListSelector>(scope: TemplateAdapter, item: T, values: any): T {
    if (!values.availableListEntryElements || !values.selectedListEntryElements) {
      return null;
    }

    const v = new ColumnListSelectorValue()
      .setAvailableListEntryElements((values.availableListEntryElements || []).map((e) => this.parseElement(e, scope)))
      .setSelectedListEntryElements((values.selectedListEntryElements || []).map((e) => this.parseElement(e, scope)));
    const t = item.setValue(v);
    return t;
  }

  public inheritFrom<T extends ColumnListSelector>(scope: TemplateAdapter, type: Type<T>, data: any): T {
    const t: T = this.templateResourceFactory.inheritFrom<T>(scope, type, data);
    t.setHeadline(data.headline);
    if (data.value) {
      this.applyValues(scope, t, data.value);
    }
    return t;
  }

  private parseElement(data: any, scope: TemplateAdapter): ColumnListSelectorElement {
    const t = this.menuFactory
      .getHMI<ColumnListSelectorElement>(ColumnListSelectorElement, data, scope)
      .setSelected(data.selected)
      .setFloatLeft(data.floatLeft)
      .setFloatFieldIdentifiers(data.floatLeftFieldIdentifiers)
      .setSimpleElement(data.simpleElement);
    return t;
  }
}
