import { BehaviorSubject } from 'rxjs';

/**
 * Container class for gantt row config.
 */
export class GanttRowConfig {
  /**
   * Row poportion config.
   */
  public readonly rowProportions = new GanttRowProportionConfig();
  /**
   * Sticky row config.
   */
  public readonly stickyRows = new GanttStickyRowConfig();
  public isDefinedRowBackgroundColorApplied = true;
  public backgroundColorDefault = '#dcdcdc';
  public backgroundColor: { [scrollContainerId: string]: string } = {
    gantt_scroll_wrapper_sticky_rows: '#bdbdbd',
  };
}

/**
 * Container class for gantt row proportion config.
 * @keywords config, row, data, proportion, rowheight
 */
export class GanttRowProportionConfig {
  /**
   * Height of gantt rows.
   */
  public height = 34;
  /**
   * Font size of text in gantt rows.
   */
  public fontSize = 14;
}

export class GanttStickyRowConfig {
  /**
   * Flag indicating if sticky parent rows are active or not.
   */
  public showStickyRows = new BehaviorSubject<boolean>(false);
  /**
   * Enum value representing the sticky row strategy which is currently active.
   */
  public stickyRowStrategy = new BehaviorSubject<EGanttStickyRowStrategy>(EGanttStickyRowStrategy.STICKY_PARENT_ROWS);
  /**
   * Value in the interval [0,1] indicating the maximum percentage the sticky rows container is allowed to use of the whole scroll container area.
   */
  public containerMaxHeight = 0.5;
  /**
   * Object containing pixel values for minimum, maximum and optimum sizes of the sticky rows scroll container.
   * All these values are only applied if they are compliant to {@link GanttStickyRowConfig.containerMaxHeight} or always if
   * {@link GanttStickyRowConfig.allowUnlimitedContainerHeight} is set to `true`.
   */
  public readonly containerLimits = {
    /**
     * Pixel value indicating the maximum height the sticky rows container is allowed to have.
     * This value will only be applied under the conditions mentioned in {@link GanttStickyRowConfig.containerLimits}.
     */
    minHeightPx: 0,
    /**
     * Pixel value indicating the optimal height of the sticky rows container.
     * This value will only be applied under the conditions mentioned in {@link GanttStickyRowConfig.containerLimits}.
     */
    optimalHeightPx: 0,
    /**
     * Pixel value indicating the minimum height the sticky rows container is allowed to have.
     * This value will only be applied under the conditions mentioned in {@link GanttStickyRowConfig.containerLimits}.
     */
    maxHeightPx: 0,
  };
  /**
   * Flag indicating if the sticky rows container is allowed to be higher than specified in {@link GanttStickyRowConfig.containerMaxHeight} or not.
   * If it is `true` {@link GanttStickyRowConfig.containerMaxHeight} will be completely ignored.
   */
  public allowUnlimitedContainerHeight = false;
}

/**
 * Enum defining all available sticky row strategies.
 */
export enum EGanttStickyRowStrategy {
  STICKY_PARENT_ROWS = 'STICKY_PARENT_ROW_STRATEGY',
  STICKY_FIRST_ROW = 'STICKY_FIRST_ROW_STRATEGY',
  STICKY_FIRST_ROW_WITH_CHILD_ROWS = 'STICKY_FIRST_ROW_WITH_CHILD_ROWS_STRATEGY',
}
