<mat-form-field
  [class.has-error]="isError()"
  *ngIf="element?.getValue()"
  [id]="element.getId()"
  (click)="getOptions($event, false)"
  [style.width]="element.getResizeMode() === 'FIT_PARENT' ? '100%' : null"
  (focusout)="onFocusOut()"
>
  <input
    [style.color]="selectedColor"
    name="default-selector-entry-element"
    autocomplete="off"
    [class.applied]="isApplied()"
    (paste)="onPaste($event)"
    [disabled]="element.isDisabled() || disabled"
    type="text"
    #selector
    [placeholder]="(element.isRequired() ? '* ' : '') + element.getName()"
    matInput
    [matAutocomplete]="auto"
    (keyup)="handleKeyUpEvent($event)"
    [(ngModel)]="value"
    [value]="value"
    #inputMask
    (change)="afterChanged()"
  />
  <button mat-icon-button matPrefix *ngIf="element.getInfoText(disabled)" (click)="$event.stopPropagation()">
    <mat-icon class="info-icon" [matMenuTriggerFor]="infoMenu">info </mat-icon>
  </button>
  <span class="numeric-count" matPrefix *ngIf="multi && !element.isShowChipsBelow()">{{ selectedValues.length }}</span>
  <button
    matPrefix
    mat-icon-button
    *ngIf="isError()"
    [matMenuTriggerFor]="errorMenu"
    (click)="afterErrorMenuOpened($event)"
  >
    <mat-icon class="error-indicator"> error_outline </mat-icon>
  </button>
  <button
    class="dropdown-arrow"
    (mouseenter)="setInsidePanel(true)"
    (mouseleave)="setInsidePanel(false)"
    *ngIf="!element.isDisabled() && !disabled"
    mat-icon-button
    matSuffix
    (click)="handleToogleSelectorPanel($event)"
  >
    <mat-icon style="font-size: 24px">{{ 'arrow_drop_down' }}</mat-icon>
  </button>
  <app-image
    [disabled]="element.isDisabled() || disabled"
    class="prefixed-icon"
    matSuffix
    *ngIf="element.getIcon()"
    [label]="element.getIcon()"
  ></app-image>
</mat-form-field>

<mat-menu #infoMenu="matMenu" class="customize">
  <p class="info-box">
    {{ element.getInfoText(disabled) }}
  </p>
</mat-menu>

<mat-menu #errorMenu="matMenu" [class]="'custom-menu'">
  <p class="error-box">
    <ng-container *ngIf="requiredError"> Pflichtfeld! </ng-container>
    <ng-container *ngIf="!requiredError"> Der Eintrag ist nicht gültig. </ng-container>
  </p>
</mat-menu>

<!-- only if multi -->
<mat-chip-list *ngIf="multi && element.isShowChipsBelow()">
  <mat-chip
    [disabled]="element.isDisabled() || disabled"
    [removable]="true"
    [style.color]="getChipColor(chip) ? 'white' : 'black'"
    [style.background-color]="getChipColor(chip)"
    (removed)="afterChipRemoved(chip.getName(), true)"
    *ngFor="let chip of selectedValues.slice(0, 5)"
  >
    {{ chip.getName() }}
    <mat-icon matChipRemove>cancel</mat-icon>
  </mat-chip>
  <ng-container *ngIf="selectedValues.length > 5">
    <span [saxmsTooltip]="tooltipInfo">
      <mat-chip class="hidden-chips-selector">+{{ selectedValues.length - 5 }} weitere</mat-chip>
    </span>
  </ng-container>
</mat-chip-list>

<!-- autocomplete to filter options -->
<mat-autocomplete
  #auto="matAutocomplete"
  (optionSelected)="afterOptionSelected($event.option.value)"
  (opened)="opened()"
  (closed)="closed()"
  (optionActivated)="handleKeyboardEvent($event)"
>
  <mat-option style="height: 0 !important; overflow: hidden">empty</mat-option>
  <div
    #upperPart
    (mouseenter)="setInsidePanel(true)"
    (mouseleave)="setInsidePanel(false)"
    (mousedown)="stopEvent($event)"
  >
    <mat-option *ngIf="loadingResults() || availableOptions().length === 0" [disabled]="true">
      {{ (loadingResults() ? 'searching' : 'noResults') | translate }}
    </mat-option>

    <mat-option
      *ngIf="element?.isFreeSelection && element?.isFreeSelection() && getOptionsName(customOption)"
      [value]="getOptionsName(customOption)"
    >
      {{ getOptionsName(customOption) }}
    </mat-option>

    <mat-option *ngIf="multi" [value]="'all'">
      <mat-checkbox [indeterminate]="isIndeterminate" [checked]="allSelected" (click)="toggleMaster($event)">
        {{ '@selectAll@' | translate }}
      </mat-checkbox>
    </mat-option>
    <mat-divider></mat-divider>
  </div>
  <cdk-virtual-scroll-viewport
    (scrolledIndexChange)="scrolledIndexChanged()"
    [style.max-height]="scrollWrapperMaxHeight"
    [style.height]="scrollHeight"
    *ngIf="scrollHeight && availableOptions().length > 0 && !loadingResults() && isOpen"
    (mouseenter)="setInsidePanel(true)"
    (mouseleave)="setInsidePanel(false)"
    class="scrollport"
    (mousedown)="stopEvent($event)"
    [itemSize]="itemHeight"
  >
    <ng-container *cdkVirtualFor="let option of availableOptions(); trackBy: trackByFn">
      <mat-option
        #virtualOption
        [saxmsTooltip]="getOptionsName(option)"
        [saxmsTooltipShowDelay]="1000"
        [id]="option.getId()"
        [value]="getOptionsName(option)"
      >
        <virtual-option
          [textColor]="option.getColor()"
          [option]="option"
          [multi]="multi"
          [name]="getOptionsName(option)"
          [color]="getChipColor(option)"
          [selected]="isOptionSelected(option)"
          (afterClick)="handleOptionCheckbox($event, option)"
        ></virtual-option>
      </mat-option>
    </ng-container>
  </cdk-virtual-scroll-viewport>

  <div class="filter-resizer" *ngIf="resizeable" (mousedown)="mouseDown($event)" (click)="handleResizeClick($event)">
    <mat-icon *ngIf="getPanelWidth() < maxPanelWidth">chevron_right</mat-icon>
    <mat-icon *ngIf="getPanelWidth() >= maxPanelWidth">chevron_left</mat-icon>
  </div>
</mat-autocomplete>
