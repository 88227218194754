import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Select } from '@ngxs/store';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthState } from '../login/data-access/auth.state';
import { User } from '../user/data-access/user';

@Injectable({
  providedIn: 'root',
})
export class FunctionPermissionService {
  public cachedData: any;
  public lang = 'de';
  public uri = 'rest/permission/user/functionpermission';
  data: any;
  private permissions: BehaviorSubject<Map<string, any>>;
  private permissionsMap: Map<string, any> = new Map();
  @Select(AuthState.user) user$!: Observable<User>;

  constructor(public http: HttpClient) {
    this.permissions = new BehaviorSubject(new Map());
    this.user$.subscribe((user) => {
      if (user) {
        this.load()
          .then((data) => {})
          .catch((err) => {
            console.error(err);
          });
      }
    });
  }

  /* getValue(key: string) {
      return (this.permissions[key]) ? this.permissions[key] : { action: null };
  }*/

  public permissionChange(): Observable<any> {
    return this.permissions.asObservable();
  }

  private setPermissions(): void {
    this.permissions.next(this.permissionsMap);
  }

  /**
   * get value from stored conf
   * @param key key
   */
  getValue(key: string): Promise<any> {
    // get cache
    if (this.permissionsMap.size > 0) {
      return new Promise((resolve) => {
        resolve(this.permissionsMap.get(key));
      });
    } else {
      // read config file
      return this.http
        .get(`${this.uri}`)
        .pipe(
          map((permissions: any) => {
            for (const p of permissions) {
              this.permissionsMap.set(p.path, p);
            }
            this.setPermissions();
            return this.permissionsMap.get(key);
          })
        )
        .toPromise();
    }
  }

  hasPermission(key: string) {
    if (this.permissionsMap.has(key)) {
      return true;
    } else {
      return false;
    }
  }

  get() {
    return this.permissionsMap;
  }

  private loadFiles(): Observable<any> {
    return forkJoin(
      this.http.get(`${this.uri}`).pipe(
        map((permissions: any) => {
          for (const p of permissions) {
            this.permissionsMap.set(p.path, p);
          }
          this.setPermissions();
        })
      )
    );
  }

  public load() {
    return this.loadFiles().toPromise();
  }
}
