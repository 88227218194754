/**
 * Mapper to translate backend icon data to frontend icon data.
 */
export class NodeNetworkIconMapper {
  /**
   * Replaces node network nodes with given icons by attribute.
   * @param dataset
   * @param attribute
   * @param attributeValues
   * @param uriPrefix
   */
  public mapIconsByAttribute(dataset: any, attribute: any, attributeValues: any, uriPrefix: string): any {
    if (!dataset || !attribute || !attributeValues) return dataset;

    for (const key in dataset.nodes) {
      const node = dataset.nodes[key];
      if (node.attributeList && node.attributeList.attributes) {
        for (const resource of node.attributeList.attributes) {
          if (resource.type == attribute && attributeValues[resource.value]) {
            node.type = 'IMAGE';
            node.imageUrl = uriPrefix + attributeValues[resource.value];
            break;
          }
        }
      }
    }
    return dataset;
  }
}
