import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Observer, throwError as observableThrowError } from 'rxjs';
import { ExperimentService2, IExperiment } from '../../data/experiment/experiment.service';

/**
 * Service which provides animation data from backend side for node network.
 */
@Injectable()
export class NodeNetworkCinemaService {
  public uri = 'rest/animation';
  experiment: IExperiment;

  constructor(public http: HttpClient, public experimentService2: ExperimentService2) {
    this.experimentService2
      .getCurrentExperiment()
      .subscribe((experiment: IExperiment) => (this.experiment = experiment));
  }

  /**
   * Gets event list from backend.
   */
  public getCinema(): Observable<any> {
    if (!this.experiment) {
      return new Observable<any>((observer: Observer<any>) => {
        observer.next(null);
        observer.complete();
      });
    }

    const url = this.uri + '/results/' + this.experiment.id;

    if (!this.experiment || !this.experiment.id) {
      return observableThrowError(null);
    }

    return this.http.get(`` + url);
  }
}
