import { SlotModule } from './../../template/slot/slot.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToolbarRowComponent } from './toolbar-row.component';

@NgModule({
  imports: [CommonModule, SlotModule],
  declarations: [ToolbarRowComponent],
  exports: [ToolbarRowComponent],
})
export class ToolbarRowModule {}
