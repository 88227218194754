import * as d3 from 'd3';
import { GanttUtilities } from '../../gantt-utilities/gantt-utilities';
import { BestGantt } from '../../main';
import { IGanttEdgeBuilderData } from './edge-builder';

/**
 * Edit edges by drag and drop.
 * @keywords edit, arrow, edge, plugin, editor
 * @plugin edges
 * @class
 * @constructor
 * @param {HTMLNode} parentNode
 * @param {BestGantt} ganttDiagram Gantt diagram which will be affected by this plugin.
 */
export class GanttEdgeEditor {
  parentNode: any;
  ganttDiagram: BestGantt;
  canvas: d3.Selection<any, any, null, undefined>;
  zoomTransformation: any;
  properties: any;
  callBacks: any;

  constructor(parentNode, ganttDiagram) {
    this.parentNode = parentNode;
    this.ganttDiagram = ganttDiagram;
    this.canvas = null;
    this.zoomTransformation = { k: 1, x: 0, y: 0 };
    this.properties = {
      handleRadius: 5,
      defaultHandleColor: 'blue',
      handlePosition: 15,
      handleOpacity: 0.7,
      detectingArea: 10,
    };

    this.callBacks = {
      onEdgeEdit: {},
      onEdgeEditDragEnd: {},
    };
  }

  /**
   * For initialization the editor.
   */
  init() {
    const s = this;
    s.canvas = d3.select(s.parentNode).append('g').attr('class', 'gantt_edge_handle_group');
  }

  /**
   * Function wich handles the creation of the edge handles.
   */
  buildHandles() {
    const s = this;

    s.removeHandles();

    d3.select(s.parentNode)
      .selectAll('.gantt-edge-line')
      .each(function (d) {
        s._buildStartHandle(d);
        s._buildEndHandle(d);
      });
  }

  /**
   * Function wich handles the deletion of the edge handles.
   */
  removeHandles() {
    const s = this;

    s.canvas.selectAll('circle').remove();
  }

  /**
   * Handles the building of the start handle and takes care of the drag event.
   * @param {Object} edgeData object of the attached edge data
   */
  private _buildStartHandle(edgeData) {
    const s = this;

    let startHandleY, mouseStart, startHandleX, connectedShift, edgeObjectData;

    s.canvas
      .selectAll('dummy')
      .data([edgeData])
      .enter()
      .append('circle')
      .attr('r', s.properties.handleRadius)
      .attr('cx', s._getStartHandleXPosition(edgeData))
      .attr('cy', edgeData.y1)
      .style('fill', function () {
        if (edgeData.color) return edgeData.color;
        return s.properties.defaultHandleColor;
      })
      .style('opacity', s.properties.handleOpacity)
      .call(
        d3
          .drag()
          .on('start', function (event, d: IGanttEdgeBuilderData) {
            GanttUtilities.dispatchD3EventToOutside(d3.select(this), event);
            startHandleX = s._getStartHandleXPosition(d);
            startHandleY = d.y1;
            mouseStart = d3.pointer(event);
            for (const func in s.callBacks.onEdgeEdit) {
              if (func == 'getEdgeDataByEdgeId') edgeObjectData = s.callBacks.onEdgeEdit[func](d.edgeId)[0]; //call getEdgeDataByEdgeId function in edge executer
            }
          })
          .on('drag', function (event, d: IGanttEdgeBuilderData) {
            GanttUtilities.dispatchD3EventToOutside(d3.select(this), event);
            const mouseDistanceX = d3.pointer(event)[0] - mouseStart[0];
            const mouseDistanceY = d3.pointer(event)[1] - mouseStart[1];
            const transformedX1 =
              (startHandleX + mouseDistanceX - s.zoomTransformation.x - s.properties.handlePosition) /
              s.zoomTransformation.k;
            const newY1 = mouseDistanceY + startHandleY;
            const nearObject = s._detectNearbyObjects([transformedX1, newY1], 'end');
            connectedShift = nearObject.id;

            for (const func in s.callBacks.onEdgeEdit) {
              if (func == '_distributeComponents')
                s.callBacks.onEdgeEdit[func](
                  d.edgeId,
                  nearObject.x,
                  nearObject.y,
                  d.x2,
                  d.y2,
                  d.color,
                  edgeObjectData.labelStart,
                  edgeObjectData.labelEnd,
                  true,
                  d.typeStart,
                  d.typeEnd,
                  d.lineStyle
                ); //call addEdge function in edge builder
              if (func == 'buildEdges') s.callBacks.onEdgeEdit[func](); //call buildEdges function in edge builder
              if (func == 'buildLabels') s.callBacks.onEdgeEdit[func](); //call build function in edge labeler
            }

            d3.select(this).attr('cx', s._getStartHandleXPosition(d));
            d3.select(this).attr('cy', nearObject.y);
          })
          .on('end', function (event, d: IGanttEdgeBuilderData) {
            GanttUtilities.dispatchD3EventToOutside(d3.select(this), event);
            if (connectedShift) {
              for (var func in s.callBacks.onEdgeEdit) {
                if (func == 'createEdgeByIds')
                  s.callBacks.onEdgeEdit[func](
                    connectedShift,
                    edgeObjectData.id2,
                    edgeObjectData.color,
                    edgeObjectData.labelStart,
                    edgeObjectData.labelEnd,
                    d.typeStart,
                    d.typeEnd,
                    d.lineStyle
                  ); //call createEdgeByIds function in edge executer
                if (func == 'removeEdgeByEdgeId') s.callBacks.onEdgeEdit[func](d.edgeId); //call removeEdgeByEdgeId function in edge executer
              }
            }
            for (var func in s.callBacks.onEdgeEditDragEnd) {
              s.callBacks.onEdgeEditDragEnd[func]();
            }
          })
      );
  }

  /**
   * Handles the building of the end handle and takes care of the drag event.
   * @param {Object} edgeData object of the attached edge data
   */
  private _buildEndHandle(edgeData) {
    const s = this;

    let startHandleY, mouseStart, startHandleX, connectedShift, edgeObjectData;

    s.canvas
      .selectAll('dummy')
      .data([edgeData])
      .enter()
      .append('circle')
      .attr('r', s.properties.handleRadius)
      .attr('cx', s._getEndHandleXPosition(edgeData))
      .attr('cy', edgeData.y2)
      .style('fill', function () {
        if (edgeData.color) return edgeData.color;
        return s.properties.defaultHandleColor;
      })
      .style('opacity', s.properties.handleOpacity)
      .call(
        d3
          .drag()
          .on('start', function (event, d: IGanttEdgeBuilderData) {
            startHandleX = s._getEndHandleXPosition(d);
            startHandleY = d.y2;
            mouseStart = d3.pointer(event);
            for (const func in s.callBacks.onEdgeEdit) {
              if (func == 'getEdgeDataByEdgeId') edgeObjectData = s.callBacks.onEdgeEdit[func](d.edgeId)[0]; //call getEdgeDataByEdgeId function in edge executer
            }
          })
          .on('drag', function (event, d: IGanttEdgeBuilderData) {
            const mouseDistanceX = d3.pointer(event)[0] - mouseStart[0];
            const mouseDistanceY = d3.pointer(event)[1] - mouseStart[1];
            const transformedX2 =
              (startHandleX + mouseDistanceX - s.zoomTransformation.x + s.properties.handlePosition) /
              s.zoomTransformation.k;
            const newY2 = mouseDistanceY + startHandleY;
            const nearObject = s._detectNearbyObjects([transformedX2, newY2], 'start');
            connectedShift = nearObject.id;

            for (const func in s.callBacks.onEdgeEdit) {
              if (func == '_distributeComponents')
                s.callBacks.onEdgeEdit[func](
                  d.edgeId,
                  d.x1,
                  d.y1,
                  nearObject.x,
                  nearObject.y,
                  d.color,
                  edgeObjectData.labelStart,
                  edgeObjectData.labelEnd,
                  true,
                  d.typeStart,
                  d.typeEnd,
                  d.lineStyle
                ); //call addEdge function in edge builder
              if (func == 'buildEdges') s.callBacks.onEdgeEdit[func](); //call buildEdges function in edge builder
              if (func == 'buildLabels') s.callBacks.onEdgeEdit[func](); //call build function in edge labeler
            }
            d3.select(this).attr('cx', s._getEndHandleXPosition(d));
            d3.select(this).attr('cy', nearObject.y);
          })
          .on('end', function (event, d: IGanttEdgeBuilderData) {
            if (connectedShift) {
              for (var func in s.callBacks.onEdgeEdit) {
                if (func == 'createEdgeByIds')
                  s.callBacks.onEdgeEdit[func](
                    edgeObjectData.id1,
                    connectedShift,
                    edgeObjectData.color,
                    edgeObjectData.labelStart,
                    edgeObjectData.labelEnd,
                    d.typeStart,
                    d.typeEnd,
                    d.lineStyle
                  ); //call createEdgeByIds function in edge executer
                if (func == 'removeEdgeByEdgeId') s.callBacks.onEdgeEdit[func](d.edgeId); //call removeEdgeByEdgeId function in edge executer
              }
            }
            for (var func in s.callBacks.onEdgeEditDragEnd) {
              s.callBacks.onEdgeEditDragEnd[func]();
            }
          })
      );
  }

  /**
   * Look which objects are near the set area and return an object with the found data.
   * @param {Array} detectingPoint Array with x and y coordinate of the detecting point.
   * @param {String} side String can be "start" or "end" for specifying which side of the object to search.
   * @returns {Object} Object with id of the nearest element with id and coordinates.
   */
  private _detectNearbyObjects(detectingPoint, side) {
    const s = this;
    let closestShift,
      xCoordinate = 0,
      yCoordinate = 0,
      shiftX;

    const canvasShiftDataset = s.ganttDiagram.getDataHandler().getCanvasShiftDataset();
    const found = canvasShiftDataset.filter(function (shiftData) {
      if (side == 'start') {
        shiftX = shiftData.x;
      } else if (side == 'end') {
        shiftX = shiftData.x + shiftData.width;
      }
      return (
        shiftX >= detectingPoint[0] - s.properties.detectingArea / s.zoomTransformation.k &&
        shiftX <= detectingPoint[0] + s.properties.detectingArea / s.zoomTransformation.k &&
        shiftData.y + shiftData.height / 2 <= detectingPoint[1] + s.properties.detectingArea &&
        shiftData.y + shiftData.height / 2 >= detectingPoint[1] - s.properties.detectingArea
      );
    });

    if (found.length > 0) {
      // looking for closest point
      let distance = s.properties.detectingArea;
      for (let i = 0; i < found.length; i++) {
        if (side == 'start') {
          shiftX = found[i].x;
        } else if (side == 'end') {
          shiftX = found[i].x + found[i].width;
        }
        const currentDistance = Math.sqrt(
          Math.pow(shiftX - detectingPoint[0], 2) + Math.pow(found[i].y + found[i].height / 2 - detectingPoint[1], 2)
        );
        if (currentDistance < distance) {
          distance = currentDistance;
          xCoordinate = shiftX;
          yCoordinate = found[i].y + found[i].height / 2;
          closestShift = found[i].id;
        }
      }
    } else {
      closestShift = null;
      xCoordinate = detectingPoint[0];
      yCoordinate = detectingPoint[1];
    }

    return {
      id: closestShift,
      x: xCoordinate,
      y: yCoordinate,
    };
  }

  /**
   * Returns the start handle x coordinate by edge Data
   * @param {Object} edgeData Object of the attached edge data.
   * @returns {Number} The x coordinate of the start handle.
   */
  private _getStartHandleXPosition(edgeData) {
    const s = this;
    return s.zoom(edgeData.x1) + s.properties.handlePosition;
  }

  /**
   * Returns the end handle x coordinate by edge Data
   * @param {Object} edgeData Object of the attached edge data.
   * @returns {Number} The x coordinate of the end handle.
   */
  private _getEndHandleXPosition(edgeData) {
    const s = this;

    return s.zoom(edgeData.x2) - s.properties.handlePosition;
  }

  /**
   * this function retruns a zoomed x-coordinate of current scale
   *
   * @param {float} coordinate x-coordinate
   */
  zoom(coordinate) {
    const s = this;

    return coordinate * s.zoomTransformation.k + s.zoomTransformation.x;
  }

  //
  //  GETTER & SETTER
  //
  setZoomTransformation(zoomTransformation) {
    const s = this;
    s.zoomTransformation = zoomTransformation;
  }

  addOnEdgeEditCallback(id, func) {
    const s = this;
    s.callBacks.onEdgeEdit[id] = func;
  }

  addOnEdgeEditDragEndCallback(id, func) {
    const s = this;
    s.callBacks.onEdgeEditDragEnd[id] = func;
  }

  setProperties(propertyObject) {
    const s = this;
    s.properties = propertyObject;
  }
}
