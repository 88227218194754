import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { HierarchicalMenuItem } from '@app-modeleditor/components/template-ui/hierarchical-menu-item';
import { ETemplateType } from 'frontend/src/dashboard/model/resource/template-type';
import { Action } from '../button/action/action';
import { EActionType } from '../button/action/action-type.enum';
import { Button } from '../button/button';
import { EEntryElementPosition } from '../entry-collection/entry-element-position.enum';
import { EPredefinedAction } from './../button/action/predefined-action.enum';
import { LightboxComponent } from './lightbox.component';

export class Lightbox extends HierarchicalMenuItem {
  private buttons: Button[];
  private additionalButtons: Button[];
  private component: any;
  private disableCancelButton: boolean;
  private disableSaveButton: boolean;
  private ref: MatDialogRef<LightboxComponent>;
  private backdropClose: boolean;
  private disableClose: boolean;
  private onCustomCancelAction: Button;
  private height: number;
  private customSaveButton: Button;
  private customCancelButton: Button;
  /**
   * Content of lightbox contains only simple text or input fields
   */
  private simpleContent: boolean;

  public getCustomSaveButton(): Button {
    return this.customSaveButton;
  }

  public setCustomSaveButton(customSaveButton: Button): this {
    this.customSaveButton = customSaveButton;
    return this;
  }

  public getCustomCancelButton(): Button {
    return this.customCancelButton?.setAlwaysEnabled(true);
  }

  public setCustomCancelButton(customCancelButton: Button): this {
    this.customCancelButton = customCancelButton;
    return this;
  }

  public getHeight(): number {
    return this.height;
  }

  public setHeight(height: number): this {
    this.height = height;
    return this;
  }

  // absolute width of lightbox in px
  private absoluteWidth: number;

  // width relative to current screen-width (%)
  private width: number;

  /**
   * get absolute width of lightbox in px
   * @returns number
   */
  public getAbsoluteWidth(): number {
    return this.absoluteWidth;
  }

  /**
   * set the absolute width of the lightbox in px
   * @param {string} absoluteWidth width in px
   * @returns this
   */
  public setAbsoluteWidth(absoluteWidth: number): this {
    this.absoluteWidth = absoluteWidth;
    return this;
  }

  /**
   * get width as percentage of the current screen-width
   * @returns number
   */
  public getWidth(): number {
    return this.width;
  }

  /**
   * set width relative to the current screen-width (%)
   * @param {number} width width in percentage
   * @returns this
   */
  public setWidth(width: number): this {
    this.width = width;
    return this;
  }

  public getOnCustomSaveAction(): Button {
    if (this.getCustomSaveButton()) {
      return this.getCustomSaveButton();
    }
    return this.onCustomSaveAction;
  }

  public setOnCustomSaveAction(onCustomSaveAction: Button): this {
    this.onCustomSaveAction = onCustomSaveAction;
    return this;
  }

  public getOnCustomCancelAction(): Button {
    if (this.getCustomCancelButton()) {
      return this.getCustomCancelButton();
    }
    return this.onCustomCancelAction;
  }

  public setOnCustomCancelAction(onCustomCancelAction: Button): this {
    this.onCustomCancelAction = onCustomCancelAction;
    return this;
  }

  public isDisableClose(): boolean {
    return typeof this.disableClose === 'boolean' ? this.disableClose : false;
  }

  public setDisableClose(disableClose: boolean): this {
    this.disableClose = disableClose;
    return this;
  }

  public isBackdropClose(): boolean {
    return typeof this.backdropClose === 'boolean' ? this.backdropClose : false;
  }

  public setBackdropClose(backdropClose: boolean): this {
    this.backdropClose = backdropClose;
    return this;
  }

  public getAdditionalButtons(): Button[] {
    return this.additionalButtons || [];
  }

  public setAdditionalButtons(additionalButtons: Button[]): this {
    this.additionalButtons = additionalButtons;
    return this;
  }

  public getRef(): MatDialogRef<LightboxComponent> {
    return this.ref;
  }

  public setRef(ref: MatDialogRef<LightboxComponent>): this {
    this.ref = ref;
    return this;
  }

  public getComponent(): any {
    return this.component;
  }

  public setComponent(component: any): this {
    this.component = component;
    return this;
  }

  public setButtons(buttons: Button[]): this {
    this.buttons = buttons;
    return this;
  }

  public getButtons(): Button[] {
    return this.buttons || [];
  }

  public getDefaultButtons(): Button[] {
    const e: Button[] = [];

    if (this.getCustomCancelButton()) {
      this.setDisableCancelButton(false);
      const btn: Button = this.getCustomCancelButton().copy(Button);
      btn.setOrder(-999);
      e.push(btn);
    } else {
      if (!this.isDisableCancelButton()) {
        const btn: Button = this.getOnCustomCancelAction()
          ? this.getOnCustomCancelAction().copy(Button)
          : new Button().setId('saxms-dialog-button-cancel').setName('BUTTON.cancel');

        btn.chainActions(new Action().setActionType(EActionType.PREDEFINED).setId(EPredefinedAction.CANCEL_LIGHTBOX));

        // set resource id
        if (!btn.getResourceId()) {
          btn.setResourceId(this.getResourceId());
        }
        // set default order to be the most left
        btn.setOrder(-999);
        e.push(btn);
      }
    }

    if (this.getCustomSaveButton()) {
      this.setDisableSaveButton(false);
      const btn: Button = this.getCustomSaveButton().copy(Button);
      btn.setOrder(999).setPosition(EEntryElementPosition.RIGHT);
      e.push(btn);
    } else {
      if (!this.isDisableSaveButton()) {
        // e.setEntryElements(
        const btn: Button = this.getOnCustomSaveAction()
          ? this.getOnCustomSaveAction().copy(Button)
          : new Button()
              .setPosition(EEntryElementPosition.RIGHT)
              .setId('saxms-dialog-button-save')
              .setName('BUTTON.save');
        btn.chainActionsBefore(new Action().setActionType(EActionType.PREDEFINED).setId(EPredefinedAction.SAVE));
        btn.chainActions(new Action().setActionType(EActionType.PREDEFINED).setId(EPredefinedAction.CLOSE_LIGHTBOX));
        // set default order to max
        btn.setOrder(999);
        e.push(btn);
      }
    }

    return e
      .concat(this.getButtons())
      .concat(this.getAdditionalButtons())
      .map((_b) => {
        _b.setResourceId(this.getResourceId());
        return _b;
      });
  }

  get NumberOfButtons(): number {
    let count = this.getButtons().length;
    count += this.isDisableSaveButton() ? 0 : 1;
    count += this.isDisableCancelButton() ? 0 : 1;
    count += this.getAdditionalButtons().length;
    return count || 0;
  }

  public isDisableCancelButton(): boolean {
    return this.disableCancelButton;
  }

  public setDisableCancelButton(disableCancelButton: boolean): this {
    this.disableCancelButton = disableCancelButton;
    return this;
  }

  public isDisableSaveButton(): boolean {
    return this.disableSaveButton;
  }

  public setDisableSaveButton(disableSaveButton: boolean): this {
    this.disableSaveButton = disableSaveButton;
    return this;
  }

  constructor() {
    super();
    this.setType(ETemplateType.LIGHTBOX).setResizeable(true).setDraggable(true);
  }

  public hasSimpleContent(): boolean {
    return this.simpleContent ?? false;
  }

  public setSimpleContent(simpleContent: boolean): this {
    this.simpleContent = simpleContent;
    return this;
  }
}
