export class SavedRecord {
  private resourceId: string;
  private lightboxId: string;
  private templateId: string;
  private saveOrder: number;
  /**
   * Key value is the save url and the value is the data to be saved
   */
  private data: { [key: string]: any };
  private skipOnError: boolean;

  public isSkipOnError(): boolean {
    return typeof this.skipOnError === 'boolean' ? this.skipOnError : false;
  }

  public setSkipOnError(skipOnError: boolean): this {
    this.skipOnError = skipOnError;
    return this;
  }

  public getLightboxId(): string {
    return this.lightboxId;
  }

  public setLightboxId(lightboxId: string): this {
    this.lightboxId = lightboxId;
    return this;
  }

  public getResourceId(): string {
    return this.resourceId;
  }

  public setResourceId(resourceId: string): this {
    this.resourceId = resourceId;
    return this;
  }

  public getTemplateId(): string {
    return this.templateId;
  }

  public setTemplateId(templateId: string): this {
    this.templateId = templateId;
    return this;
  }

  public getSaveOrder(): number {
    return this.saveOrder;
  }

  public setSaveOrder(saveOrder: number): this {
    this.saveOrder = saveOrder;
    return this;
  }

  public setData(data: { [key: string]: any }): this {
    this.data = data;
    return this;
  }

  public getData(): { [key: string]: any } {
    return this.data || {};
  }
}
