import { GanttCallBackStackExecuter } from '../../callback-tools/callback-stack-executer';
import { GanttDataRow, GanttDataShift } from '../../data-handler/data-structure/data-structure';
import { DataManipulator } from '../../data-handler/data-tools/data-manipulator';
import { GanttUtilities } from '../../gantt-utilities/gantt-utilities';
import { BestGanttPlugIn } from '../gantt-plug-in';
import { GanttSplitOverlappingShifts } from '../split-overlapping-shifts/split-overlapping-shifts-executer';

interface IFilterShiftsByAttributeResult {
  visibleResults: string[];
  hiddenResults: { [attribute: string]: string[] };
}

/**
 * Data extractor for gantt shift data.
 * @keywords plugin, data, dataset, extractor, executer, filter, collect, extract, attribute, shift
 * @plugin filter-shifts-by-attribute
 * @class
 * @constructor
 * @extends BestGanttPlugIn
 *
 * @requires BestGanttPlugIn
 * @requires DataManipulator
 */
export class FilterShiftsByAttribute extends BestGanttPlugIn {
  filterIsActive: boolean;
  currentQuery: string;
  noRenderId: string;
  properties: any;
  hiddenResultsSubscribers: { [subscriberId: string]: (hiddenShiftIds: { [attribute: string]: string[] }) => void };

  constructor() {
    super(); // call super-constructor
    /**
     * @type {BestGantt}
     */
    this.ganttDiagram = null;
    this.filterIsActive = false;
    this.currentQuery = null;
    this.noRenderId = GanttUtilities.generateUniqueID();
    this.hiddenResultsSubscribers = {};

    this.properties = {
      hideEmptyRows: false,
      includeMilestones: false,
    };
  }

  /**
   * @override
   */
  initPlugIn(ganttDiagram) {
    const s = this;
    s.ganttDiagram = ganttDiagram;
  }

  /**
   * Remove of plugin. Part of plugin lifecycle.
   */
  removePlugIn() {
    const s = this;
    s.resetFilter();
  }

  /**
   * Wrapper function for <b>_filterShiftsByAttributeQuery </b>
   * to only execute on alphanumerical keystrokes
   * @param {string} query Part of queries value of all attributes.
   * @returns {IFilterShiftsByAttributeResult} visible and hidden results (shift ids)
   */
  public filterShiftsByAttributeQuery(
    query: string,
    attributeMapping,
    updateGantt = true
  ): IFilterShiftsByAttributeResult {
    const s = this;
    s.currentQuery = query;
    if (!query) {
      s.resetFilter(updateGantt);
      return null;
    } else {
      s.filterIsActive = true;
      return s._filterShiftsByAttributeQuery(query, attributeMapping, updateGantt);
    }
  }

  /**
   * Resets the filter and restores the basic state
   */
  resetFilter(updateGantt = true) {
    const s = this;
    const resetAllRenderAttributes = function (child) {
      GanttUtilities.removeNoRenderId(child, s.noRenderId);
      if (child.shifts && child.shifts.length) {
        child.shifts.forEach((shift) => GanttUtilities.removeNoRenderId(shift, s.noRenderId));
      }
    };

    if (s.filterIsActive) {
      const overlappingShiftsPlugins = s.ganttDiagram.getPlugInHandler().getPlugInsOfType(GanttSplitOverlappingShifts);
      const overlappingShiftsPlugin = overlappingShiftsPlugins.length ? overlappingShiftsPlugins[0] : null;

      if (overlappingShiftsPlugin && updateGantt) {
        overlappingShiftsPlugin.resetSplitOverlappingShifts(false); // reset split if plugin is active
      }
      DataManipulator.iterateOverDataSet(s.ganttDiagram.getDataHandler().getOriginDataset().ganttEntries, {
        resetAllRenderAttributes: resetAllRenderAttributes,
      });
      if (overlappingShiftsPlugin && updateGantt) {
        overlappingShiftsPlugin.splitOverlappingShifts(null, false); // split rows if plugin is active
      }
      if (updateGantt) {
        s.ganttDiagram.update();
      }
    }
    s.filterIsActive = false;
    s.currentQuery = null;
    GanttCallBackStackExecuter.execute(s.hiddenResultsSubscribers, []);
  }

  /**
   * Usefull to find suggestions for autocomplete input field.
   * @param {string} query
   * @param {number} maximumResultSize
   * @param {boolean} avoidDuplicates
   * @return {FilterShiftsByAttributeDataItem[]}
   */
  getAllPropertyAttributesByQuery(query, attributeMapping) {
    const s = this;
    const uniqueAttributes = new Map(); // <value: string, shiftIds: string[]>

    const getAllShiftAttributes = function (child, level, parent, index, abort) {
      child.shifts.forEach((shift) => {
        const matches = s._getAllMatchingValuesByQuery(shift, query, attributeMapping);
        matches.forEach((item) => {
          const value = item.value;
          if (!value?.length) {
            return;
          }
          if (item.type && item.type !== 'STRING' && item.originType !== 'string') {
            return;
          }
          if (!uniqueAttributes.has(value)) {
            uniqueAttributes.set(value, [shift.id]);
          } else {
            uniqueAttributes.get(value).push(shift.id);
          }
        });
      });
    };
    DataManipulator.iterateOverDataSet(s.ganttDiagram.getDataHandler().getOriginDataset().ganttEntries, {
      getAllShiftAttributes: getAllShiftAttributes,
    });

    return uniqueAttributes;
  }

  /**
   * Handles the search for attributes and takes care of hiding shifts and empty rows.
   * @param {GanttDataRow} entries rows
   * @param {string} query query term
   * @returns {boolean} If search was successful
   */
  private _searchRecursive(
    entries: GanttDataRow[],
    query: string,
    attributeMapping,
    visibleResults: string[],
    hiddenResults: { [attribute: string]: string[] }
  ) {
    const s = this;
    let result = false;
    for (const entry of entries) {
      let entryResult = false;
      // check if entry has children
      if (entry.child && entry.child.length) {
        if (s._searchRecursive(entry.child, query, attributeMapping, visibleResults, hiddenResults)) {
          result = true;
          entryResult = true;
        }
      }

      GanttUtilities.removeNoRenderId(entry, s.noRenderId);

      // check shifts
      if (entry.shifts && entry.shifts.length) {
        for (const shift of entry.shifts) {
          if (s._attributesMatchesQuery(shift, query, attributeMapping)) {
            // result
            GanttUtilities.removeNoRenderId(shift, s.noRenderId);
            if (shift.noRender.length || entry.noRender.length) {
              // if shift or row is hidden by another plugin
              hiddenResults[shift.id] = [...shift.noRender.slice(), ...entry.noRender.slice()];
            } else {
              visibleResults.push(shift.id);
            }
            result = true;
            entryResult = true;
          } else {
            // no result
            GanttUtilities.registerNoRenderId(shift, s.noRenderId);
          }
        }
      }
      // handle row hiding if no result
      if (s.properties.hideEmptyRows && !entryResult) {
        GanttUtilities.registerNoRenderId(entry, s.noRenderId);
      }
    }
    return result;
  }

  /**
   * Checks if the given shift's time span is within the global time span of the Gantt diagram.
   * @param shift - The shift to check.
   * @returns A boolean indicating whether the shift's time span is within the global time span.
   * @deprecated since version 2.33 -> use same named function from GanttOriginDataStateStorage
   */
  private isOriginShiftInTimespan(shift: GanttDataShift): boolean {
    const globalTimespan = this.ganttDiagram.getXAxisBuilder().getGlobalScale().domain();
    return shift.timePointStart >= globalTimespan[0] && shift.timePointEnd <= globalTimespan[1];
  }

  /**
   * Make all shifts invisible that don't have any attribute which contains a part of the given query.
   * @param {string} query Part of queries value of all attributes.
   * @returns {IFilterShiftsByAttributeResult} visible and hidden results (shift ids)
   */
  private _filterShiftsByAttributeQuery(
    query: string,
    attributeMapping,
    updateGantt = true
  ): IFilterShiftsByAttributeResult {
    const s = this;
    const overlappingShiftsPlugins = s.ganttDiagram.getPlugInHandler().getPlugInsOfType(GanttSplitOverlappingShifts);
    const overlappingShiftsPlugin = overlappingShiftsPlugins.length ? overlappingShiftsPlugins[0] : null;
    const hiddenResults = {};
    const visibleResults: string[] = [];

    if (overlappingShiftsPlugin && updateGantt) {
      overlappingShiftsPlugin.resetSplitOverlappingShifts(false); // reset split if plugin is active
    }
    s._searchRecursive(
      s.ganttDiagram.getDataHandler().getOriginDataset().ganttEntries,
      query,
      attributeMapping,
      visibleResults,
      hiddenResults
    );
    if (overlappingShiftsPlugin && updateGantt) {
      overlappingShiftsPlugin.splitOverlappingShifts(null, false); // split rows if plugin is active
    }
    GanttCallBackStackExecuter.execute(s.hiddenResultsSubscribers, hiddenResults);
    if (updateGantt) {
      s.ganttDiagram.update();
    }
    return { visibleResults, hiddenResults };
  }

  /**
   * Checks if given dataset has any value in it which matches query.
   * @private
   * @param {any} dataObject Given dataset (Array, JSON or primitive value)
   * @param {string} query Query text.
   * @return {boolean} Data object contains value or not.
   */
  private _attributesMatchesQuery(shift: GanttDataShift, query: string, attributeMapping): boolean {
    return !!this._getAllMatchingValuesByQuery(shift, query, attributeMapping, true).filter((item) => {
      return !item.type || item.type === 'STRING' || item.originType === 'string';
    }).length;
  }

  /**
   * Checks if given dataset has any value in it witch matches query.
   * @private
   * @param {any} dataObject Given dataset (Array, JSON or primitve value)
   * @param {string} query Query text.
   * @param {any} attributeMapping
   * @param {boolean} returnFirstMatch terminates search after first match if true
   * @return {FilterShiftsByAttributeDataItem[]} Dataobject contains value or not.
   */
  private _getAllMatchingValuesByQuery(
    shift: GanttDataShift,
    query: string,
    attributeMapping,
    returnFirstMatch = false
  ): ShiftsAttributeDataItem[] {
    const allFoundValues = [];

    // Commented out because the block names sometimes end with " ". This leads to duplicate suggestions
    // also search for name
    // if ((shift.name || "").toLowerCase().includes(query.toLowerCase())) {
    //   const result = new ShiftsAttributeDataItem("name", shift.name || "", "STRING");
    //   if (returnFirstMatch) { return [result]; }
    //   allFoundValues.push(result);
    // }

    if (!this.isOriginShiftInTimespan(shift) || !shift?.additionalData?.additionalData?.additionalDetails) return [];
    const additionalDetails = shift.additionalData.additionalData.additionalDetails;

    for (const position in additionalDetails) {
      if (!attributeMapping[position]) {
        continue;
      }
      const attribute = additionalDetails[position];
      const attributeValue = attribute.t2;
      const attributeName = attributeMapping[position].localization;
      const dataType = attributeMapping[position].dataType;
      if ((attributeValue + '').toLowerCase().includes(query.toLowerCase())) {
        const result = new ShiftsAttributeDataItem(attributeName, attributeValue + '', dataType, typeof attributeValue);
        if (returnFirstMatch) {
          return [result];
        }
        allFoundValues.push(result);
      }
    }

    return allFoundValues;
  }

  subscribeToHiddenResults(id: string, cb: (hiddenResults: { [attribute: string]: string[] }) => void) {
    this.hiddenResultsSubscribers[id] = cb;
  }

  unsubscribeFromHiddenResults(id: string) {
    delete this.hiddenResultsSubscribers[id];
  }

  //
  // GETTER & SETTER
  //

  setHideEmptyRows(hideRowsBool) {
    this.properties.hideEmptyRows = hideRowsBool;
  }

  isHideEmptyRows() {
    return this.properties.hideEmptyRows;
  }

  getCurrentQuery() {
    return this.currentQuery;
  }

  isFilterActive() {
    return this.filterIsActive;
  }
}

/**
 * Data container to combine name of shift attribute with its value.
 * @class
 * @constructor
 * @param {*} attributeName
 * @param {*} value
 */
export class FilterShiftsByAttributeDataItem {
  attributeName: string;
  value: any;

  constructor(attributeName, value) {
    this.attributeName = attributeName;
    this.value = value;
  }
}

export class ShiftsAttributeDataItem {
  property: string;
  value: string;
  type: string;
  originType: string;

  constructor(property: string, value: string, type: string, originType: string) {
    this.property = property;
    this.value = value;
    this.type = type;
    this.originType = originType;
  }
}
