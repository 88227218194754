import { SaxmsTooltipModule } from './../../tooltip/tooltip.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FileUploaderModule } from '@app-modeleditor/components/file-uploader/file-uploader.module';
import { TranslateModule } from '@ngx-translate/core';
import { ImageModule } from '@app-modeleditor/components/image/image.module';
import { MaterialModule } from 'frontend/src/dashboard/core/material/material.module';
import { FilterchipsListModule } from '../filterchips-list/filterchips-list.module';
import { TooltipDirective } from '../../tooltip/tooltip.directive';
import { TreeNodeComponent } from './tree-node.component';
@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    TranslateModule,
    ImageModule,
    FilterchipsListModule,
    FileUploaderModule,
    SaxmsTooltipModule,
  ],
  declarations: [TreeNodeComponent],
  exports: [TreeNodeComponent],
})
export class TreeNodeModule {}
