import { Injectable, Injector, ElementRef } from '@angular/core';
import { GeneralPopUpService } from '../general-popup.service';
import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { ContextMenuPopupComponent } from './context-menu-popup.component';
import { CONTEXT_MENU_TEMPLATE, IContextMenuTemplate } from './context-menu-element.builder';

@Injectable({
  providedIn: 'root',
})
export class ContextMenuPopupService extends GeneralPopUpService {
  constructor(public injector: Injector, overlay: Overlay) {
    super(overlay);
  }

  openContextMenuOnElement(htmlTarget: ElementRef, contextMenuTemplate: IContextMenuTemplate) {
    const portalComponent = this.buildPortalComponent(contextMenuTemplate);
    this.createPopupOnElement(htmlTarget, portalComponent);
  }

  openContextMenuOnMouse(positionX: number, positionY: number, contextMenuTemplate: IContextMenuTemplate) {
    const portalComponent = this.buildPortalComponent(contextMenuTemplate);
    this.createPopupOnMouse(positionX, positionY, portalComponent);
  }

  closePopup() {
    this.close();
  }

  private buildPortalComponent(contextMenuTemplate: IContextMenuTemplate): ComponentPortal<ContextMenuPopupComponent> {
    const injector = this.getPortalInjector(contextMenuTemplate);
    const componentPortal = new ComponentPortal(ContextMenuPopupComponent, null, injector);
    return componentPortal;
  }

  private getPortalInjector(contextMenuTemplate: IContextMenuTemplate) {
    const injectionTokens = new WeakMap();
    injectionTokens.set(CONTEXT_MENU_TEMPLATE, contextMenuTemplate);
    return new PortalInjector(this.injector, injectionTokens);
  }
}
