import { Action } from '../button/action/action';
import { ContentElement } from '../content/content-element/content-element';

export interface ProgressBarValue {
  value: number;
  color?: string;
  hint?: string;
}

export class ProgressBar extends ContentElement {
  private updateDuration?: number;
  private updateAutomatically?: boolean;
  private value: ProgressBarValue;
  private valueTriggeredActions?: { [percent: number]: Action[] }; // percent: 0.0-1.0

  constructor() {
    super();
  }

  public getUpdateDuration(): number {
    return this.updateDuration | 2000;
  }

  public setUpdateDuration(updateDuration: number): this {
    this.updateDuration = updateDuration;
    return this;
  }

  public isUpdateAutomatically(): boolean {
    return this.updateAutomatically;
  }

  public setUpdateAutomatically(updateAutomatically?: boolean): this {
    this.updateAutomatically = updateAutomatically;
    return this;
  }

  public getValue(): ProgressBarValue {
    return this.value;
  }

  public setValue(value: ProgressBarValue): this {
    this.value = value;
    return this;
  }

  public getValueTriggeredActions(): { [percent: number]: Action[] } {
    return this.valueTriggeredActions || {};
  }

  public setValueTriggeredActions(valueTriggeredActions: { [percent: number]: Action[] }): this {
    this.valueTriggeredActions = valueTriggeredActions;
    return this;
  }
}
