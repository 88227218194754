import Handsontable from 'handsontable';
import CellCoords from 'handsontable/3rdparty/walkontable/src/cell/coords';
import CellRange from 'handsontable/3rdparty/walkontable/src/cell/range';

export interface ISelectionRangeInformation {
  from: CellCoords;
  to: CellCoords;
}
export class SelectionInformation {
  private templateId: string;
  private contentId: string;
  private selectedResources: Map<string, Set<number>> = new Map();
  private selectedRanges: CellRange[] = [];

  public getSelectedRangesForSelect(): CellRange[] {
    return this.selectedRanges.map((range) => {
      if (range.from.row === -1) {
        range.from.row = 0;
      }
      if (range.from.col === -1) {
        range.from.col = 0;
      }
      if (range.to.row === -1) {
        range.to.row = 0;
      }
      if (range.to.col === -1) {
        range.to.col = 0;
      }
      return range;
    });
  }
  public getSelectedRanges(): CellRange[] {
    return this.selectedRanges;
  }

  public setSelectedRanges(selectedRanges: CellRange[]): this {
    this.selectedRanges = selectedRanges;
    return this;
  }

  public addSelectedRange(selectedRange: CellRange): this {
    this.selectedRanges.push(selectedRange);
    return this;
  }

  public getTemplateId(): string {
    return this.templateId;
  }

  public setTemplateId(templateId: string): this {
    this.templateId = templateId;
    return this;
  }
  public getContentId(): string {
    return this.contentId;
  }

  public setContentId(contentId: string): this {
    this.contentId = contentId;
    return this;
  }

  public setSelectionResource(information: Map<string, Set<number>>): this {
    // this.selectedResources.clear();
    this.selectedResources = information;
    return this;
  }
  public getSelectionResource(): Map<string, Set<number>> {
    return this.selectedResources;
  }

  public addSelectedResource(resourceId: string, itemIndex: number) {
    const selectedResourcesItems: Set<number> = this.selectedResources.get(resourceId) || new Set();
    selectedResourcesItems.add(itemIndex);
    this.selectedResources.set(resourceId, selectedResourcesItems);
  }

  public clearSelection() {
    this.selectedResources.clear();
  }
}
