import { RenderDataHandler } from '../../render-data-handler/render-data-handler';
import { GanttCanvasRow, GanttDataRow } from '../data-structure/data-structure';
import { DataManipulator } from '../data-tools/data-manipulator';

/**
 * Data filtering and handling for y axis data.
 * @keywords yaxis, y axis, data, dataset, find, query, search, filter, recursive, iterative, manipulation
 * @class
 * @constructor
 * @static
 */
export class YAxisDataFinder {
  private constructor() {}

  /**
   * Returns IDs of all open gantt rows.
   * @keywords open, row, child, yaxis, y axis
   * @param {GanttDataRow[]} dataSet Origin dataset in which the search will be executed.
   * @return {string[]} List of row ids.
   */
  static getAllOpenChilds(dataSet) {
    const openChildIds = [];

    const getOpenChilds = function (child, level, parent) {
      if (child.open) openChildIds.push(child.id);
    };

    DataManipulator.iterateOverDataSet(dataSet, { getOpenChilds: getOpenChilds });
    return openChildIds;
  }

  static getAllRowIds(dataSet) {
    const allRows = [];

    const getAllChilds = function (child, level, parent) {
      allRows.push(child.id);
    };

    DataManipulator.iterateOverDataSet(dataSet, { getAllChilds: getAllChilds });
    return allRows;
  }

  /**
   * Returns all open gantt rows.
   * @keywords open, row, child, yaxis, y axis
   * @param {GanttDataRow[]} dataSet Origin dataset in which the search will be executed.
   * @return {GanttDataRow[]} List of row ids.
   */
  static getAllOpenChildrenDataRows(dataSet) {
    const openChildren = [];

    const getOpenChildren = function (child, level, parent) {
      if (child.open) openChildren.push(child);
    };

    DataManipulator.iterateOverDataSet(dataSet, { getOpenChildren: getOpenChildren });
    return openChildren;
  }
  /**
   * Returns all childs which has an opened parent.
   * @keywords open, row, child, parent, yaxis, y axis
   * @param {GanttDataRow[]} dataSet Origin dataset in which the search will be executed.
   * @return {string[]} List of child ids.
   */
  static getAllChildsWithOpenParent(dataSet) {
    const openChildIds = [];

    const getOpenChilds = function (child, level, parent) {
      if (parent && parent.open) openChildIds.push(child.id);
    };

    DataManipulator.iterateOverDataSet(dataSet, { getOpenChilds: getOpenChilds });
    return openChildIds;
  }

  /**
   * Returns a map of all row ids and their currently assigned open state.
   * @param dataSet Data set to generate the map for.
   */
  static getRowOpenStateMap(dataSet: GanttDataRow[]): Map<string, boolean> {
    if (!dataSet || dataSet.length <= 0) return null;
    const rowOpenStateMap = new Map<string, boolean>();

    const getRowOpenState = (child: GanttDataRow) => {
      rowOpenStateMap.set(child.id, child.open);
    };
    DataManipulator.iterateOverDataSet(dataSet, { getRowOpenState: getRowOpenState });

    return rowOpenStateMap;
  }

  static getYAxisCanvasMap(dataSet: GanttCanvasRow[]): Map<string, GanttCanvasRow> {
    const yAxisDataMap = new Map<string, GanttCanvasRow>();
    dataSet.forEach((row) => {
      yAxisDataMap.set(row.id, row);
    });
    return yAxisDataMap;
  }

  /**
   * Returns found row and depth by row id.
   * @keywords row, origin, data, dataset, child, y axis, yaxis, id, data, dataset, find, search
   * @param {GanttDataRow[]} dataSet Origin dataset in which the search will be executed.
   * @param {string} id Id of row.
   * @return {FoundRowData}
   */
  public static getRowById(dataSet: GanttDataRow[], id: string, firstLevelParent: GanttDataRow = null): FoundRowData {
    let foundData: GanttDataRow = null;
    let layerDepth = 0;
    let parentData: GanttDataRow = null;

    function findId(dataRow: GanttDataRow, depth: number, parent: GanttDataRow): void {
      depth++;
      if (!dataRow || !dataRow.child || foundData) return;
      if (id == dataRow.id) {
        foundData = dataRow;
        layerDepth = depth;
        parentData = parent;
        return;
      }
      for (let i = 0; i < dataRow.child.length; i++) {
        const c = dataRow.child[i];
        findId(c, depth, dataRow);
      }
    }
    for (let i = 0; i < dataSet.length; i++) {
      const c = dataSet[i];
      findId(c, 0, firstLevelParent);
    }
    const result = new FoundRowData();
    result.data = foundData;
    result.depth = layerDepth;
    result.parent = parentData;

    return result;
  }

  /**
   * Returns found rows and depth by row ids.
   * @keywords row, rows, origin, data, dataset, rowid, id, find
   * @param {GanttDataRow[]} dataSet Origin dataset in which the search will be executed.
   * @param {string[]} ids row id list.
   * @return {Object[]}
   */
  static getRowsByIds(dataSet: GanttDataRow[], ids: string[]): GanttDataRow[] {
    const foundRows: GanttDataRow[] = [];
    if (!ids || ids.length <= 0) return foundRows;
    const idsToRemove = ids.slice();

    const checkRows = function (child) {
      if (idsToRemove.length <= 0) return;
      const foundIndex = idsToRemove.indexOf(child.id);
      if (foundIndex != -1) {
        // store matching row
        foundRows.push(child);
        idsToRemove.splice(foundIndex, 1);
      }
    };
    DataManipulator.iterateOverDataSet(dataSet, { checkRows: checkRows });

    return foundRows;
  }

  /**
   * Removes found row from dataset.
   * @keywords row, origin, data, dataset, child, y axis, yaxis, id, data, dataset, remove, delete
   * @param {GanttDataRow[]} dataSet Origin dataset in which the search will be executed.
   * @param {string} id Id of row.
   * @return {FoundRowData}
   */
  static deleteRowById(dataSet: GanttDataRow[], id: string) {
    let foundData = null;
    let layerDepth = 0;

    function findId(dataRow, depth, index, parent) {
      depth++;
      if (!dataRow || !dataRow.child || foundData) return;
      if (id == dataRow.id) {
        foundData = dataRow;
        layerDepth = depth;
        if (depth == 1) parent.splice(index.index, 1);
        else parent.child.splice(index.index, 1);
        return;
      }
      for (let i = 0; i < dataRow.child.length; i++) {
        const c = dataRow.child[i];
        findId(c, depth, { index: i }, dataRow);
      }
    }
    for (let i = 0; i < dataSet.length; i++) {
      const c = dataSet[i];
      findId(c, 0, { index: i }, dataSet);
    }
    const result = new FoundRowData();
    result.data = foundData;
    result.depth = layerDepth;

    return result;
  }

  /**
   * Replaces row by given row id by using new row.
   * @param {GanttDataRow[]} dataSet Origin dataset in which the search will be executed.
   * @param {string} id Row id of row which should be replaced.
   * @param {GanttDataRow} replacement Row which will be insertes at position of old row.
   * @return {FoundRowData} Row which has been replaced. NUll if no matching row was found.
   */
  static replaceRowByRowId(dataSet: GanttDataRow[], id: string, replacement: GanttDataRow) {
    let result;
    DataManipulator.iterateOverDataSet(dataSet, {
      replaceRowByRowId: function (child, level, parent, index) {
        if (child.id != id || result) return;
        result = new FoundRowData();
        result.data = child;
        result.depth = level;
        const targetParent = level == 0 ? dataSet : parent.child;
        targetParent.splice(index.index, 1, replacement);
      },
    });

    return result;
  }

  /**
   * Returns first found row and depth by row property value.
   * @keywords row, origin, data, dataset, find, property, additional, data
   * @param {GanttDataRow[]} dataSet Origin dataset in which the search will be executed.
   * @param {string} property Name of JSON property.
   * @param {any} value Value of JSON property.
   * @return {FoundRowData} All rows with matching id (should be only one).
   */
  static getRowByProperty(dataSet, property, value) {
    let foundData = null;
    let layerDepth = 0;

    function findId(dataRow, depth) {
      depth++;
      if (!dataRow || foundData) return;
      if (dataRow[property] && value == dataRow[property]) {
        foundData = dataRow;
        layerDepth = depth;
        return;
      }
      if (dataRow.child) {
        for (let i = 0; i < dataRow.child.length; i++) {
          const c = dataRow.child[i];
          findId(c, depth);
        }
      }
    }
    for (let i = 0; i < dataSet.length; i++) {
      const c = dataSet[i];
      findId(c, 0);
    }
    const result = new FoundRowData();
    result.data = foundData;
    result.depth = layerDepth;

    return result;
  }

  /**
   * Returns canvas row by vertical position from given canvas dataset.
   * @keywords row, canvas, data, dataset, y, y position
   * @param canvasDataSet List of canvas rows.
   * @param yPosition Vertical position of row.
   * @param renderDataHandler If specified, the y positions of the rows will be obtained from this {@link RenderDataHandler} instance (default is `undefined`).
   * @return Found row.
   */
  public static getRowByYPosition(
    canvasDataSet: GanttCanvasRow[],
    yPosition: number,
    renderDataHandler: RenderDataHandler = undefined
  ): GanttCanvasRow {
    return canvasDataSet.find(
      (row) =>
        yPosition >= (renderDataHandler ? renderDataHandler.getStateStorage().getYPositionRow(row.id) : row.y) &&
        yPosition <
          (renderDataHandler ? renderDataHandler.getStateStorage().getYPositionRow(row.id) : row.y) + row.height
    );
  }

  /**
   * Returns canvas row by mouse position from the given canvas dataset.
   * @param {GanttCanvasRow[]} canvasDataSet
   * @param {MouseEvent} mouseEvent
   * @param {GanttHTMLStructureBuilder} htmlStructureBuilder
   * @param {NodeProportionsState} nodeProportionState
   * @returns {GanttCanvasRow} Found row.
   */
  static getRowByMousePosition(canvasDataSet, mouseEvent, htmlStructureBuilder, nodeProportionState, yCorrection = 0) {
    const shiftCanvasProportions = htmlStructureBuilder.getShiftContainer().node().getBoundingClientRect();
    const absoluteShiftContainerPosY = mouseEvent.pageY - shiftCanvasProportions.y;
    let shiftContainerPositionY = absoluteShiftContainerPosY + yCorrection;

    // handle coordinates outside the shift area
    shiftContainerPositionY = Math.max(shiftContainerPositionY, 0);
    shiftContainerPositionY = Math.min(
      shiftContainerPositionY,
      nodeProportionState.getShiftCanvasProportions().height - 1
    );

    return this.getRowByYPosition(canvasDataSet, shiftContainerPositionY);
  }

  /**
   * Returns canvas row by id from given canvas dataset.
   * @keywords canvas, row, child, yaxis, y axis, id, rowid, find, get, search
   * @param {GanttCanvasRow[]} canvasDataSet List of canvas rows.
   * @param {string} id Row id.
   * @return {GanttCanvasRow} Found row.
   */
  static getCanvasRowById(canvasDataSet: GanttCanvasRow[], id: string) {
    return canvasDataSet.find((rowElement) => rowElement.id == id);
  }
  /**
   * Returns canvas row by id from given canvas dataset.
   * @keywords canvas, row, child, yaxis, y axis, id, rowid, find, get, search, index, position
   * @param {GanttCanvasRow[]} canvasDataSet List of canvas rows.
   * @param {string} id Row id.
   * @return {number} Found index. Defaults to 'null' if no row was found.
   */
  static getRowIndexByRowId(canvasDataSet, id) {
    for (let i = 0; i < canvasDataSet.length; i++) {
      const row = canvasDataSet[i];
      if (row.id == id) return i;
    }
  }
  /**
   * Returns last canvas row of grouped rows.
   * @keywords canvas, row, child, yaxis, y axis, group, member, last, rowid, id
   * @param {GanttCanvasRow[]} canvasDataSet List of canvas rows.
   * @param {string} id Row id.
   * @return {GanttCanvasRow} Found row.
   */
  static getLastConnectedRow(canvasDataSet, id) {
    let rowFound = false,
      lastConnectedRow;

    for (let i = 0; i < canvasDataSet.length; i++) {
      const row = canvasDataSet[i];

      if (row.id == id) rowFound = true;
      else if (rowFound && row.group == 'MEMBER') lastConnectedRow = row;
      else if (rowFound) break;
    }

    return lastConnectedRow;
  }
  /**
   * Returns first canvas row of grouped rows.
   * @keywords canvas, row, child, yaxis, y axis, group, member, first, rowid, id
   * @param {GanttCanvasRow[]} canvasDataSet List of canvas rows.
   * @param {string} id Row id.
   * @return {GanttCanvasRow} Found row.
   */
  static getFirstConnectedRow(canvasDataSet, id) {
    let rowFound = false,
      firstConnectedRow;

    for (let i = canvasDataSet.length; i > 0; i--) {
      const row = canvasDataSet[i - 1];

      if (row.id == id) {
        rowFound = true;
        if (row.group != 'MEMBER') return row; // row is first connected row
      } else if (rowFound) {
        firstConnectedRow = row;
        if (row.group != 'MEMBER') break;
      }
    }

    return firstConnectedRow;
  }

  static getAllSplitRowsById(id: string, stickyPluginId: string, dataset: GanttCanvasRow[]): GanttCanvasRow[] {
    return dataset.filter((row) => {
      let pass = row.id === id;
      if (stickyPluginId) {
        if (!pass) {
          pass = row.id.startsWith(id + stickyPluginId);
        }
      }
      return pass;
    });
  }

  /**
   * Returns all member rows of row id
   * @param { string } id rowId
   * @param { object } dataset Origin data set
   * @returns {string[]} Array of member row ids
   */
  static getAllMemberRows(id, dataset) {
    let baseFlag = false;
    let members = [];

    for (let i = 0; i < dataset.length; i++) {
      if (dataset[i].id == id) {
        if (dataset[i].group == 'BASE-LEAF' || dataset[i].group == 'BASE-TREE') {
          baseFlag = true;
          continue;
        }
      }
      if (baseFlag) {
        if (dataset[i].group == 'MEMBER') {
          members.push(dataset[i].id);
        } else {
          baseFlag = false;
        }
        if (i == dataset.length - 1) {
          baseFlag = false;
        }
      }
      if (dataset[i].child.length != 0) {
        members = members.concat(this.getAllMemberRows(id, dataset[i].child));
      }
    }
    return members;
  }

  /**
   * Returns the sum height of member rows by the given origin row id.
   * @param {string} originRowID ID of originalResource
   * @param {GanttCanvasRow[]} dataset Y-Axis dataset
   */
  static getHeightOfMemberRowsByOriginRowID(originRowID, dataset) {
    let height = 0;
    dataset.forEach((row) => {
      if (row.originalResource && row.originalResource == originRowID) {
        height += row.height;
      }
    });

    return height;
  }

  /**
   * Returns an array of row member ids who are rendered.
   * @param {object} parentRowData row data of parent
   * @returns {string[]}  rendered child ids
   */
  static getIdsOfNewRenderedRowsAfterOpenParentRow(parentRowData) {
    const renderedRows = [];
    if (
      !parentRowData.hasOwnProperty('child') ||
      (parentRowData.hasOwnProperty('noRender') && parentRowData.noRender.length)
    )
      return renderedRows;
    for (let i = 0; i < parentRowData.child.length; i++) {
      if (!parentRowData.child[i].noRender || !parentRowData.child[i].noRender.length)
        renderedRows.push(parentRowData.child[i].id);
    }
    return renderedRows;
  }

  static getLevelByID(dataSet, id) {
    let levelPosition;
    DataManipulator.iterateOverDataSet(
      dataSet,
      {
        getLevelOfRow: function (child, level, parent, index, abort) {
          if (child.id === id) {
            levelPosition = level;
            abort.abort = true;
            return;
          }
        },
      },
      null,
      null,
      dataSet
    );

    return levelPosition;
  }

  /**
   * Finds and return the parent Row of a row.
   * @param {OriginDataSet} dataSet
   * @param {String} rowId
   */
  static getParentRowById(dataSet, rowId) {
    let returnValue;
    const findParentRow = function (child, level, parent, index, abort) {
      if (!parent) parent = child;
      if (child.id === rowId || child.originalResource === rowId) {
        returnValue = parent.id;
        if (parent.hasOwnProperty('originalResource')) {
          returnValue = parent.originalResource;
        }
      }
    };
    DataManipulator.iterateOverDataSet(dataSet, { findParentRow: findParentRow });
    return returnValue;
  }

  /**
   * Finds and returns ALL (hierachical) parent-rows of a row.
   * @param {OriginDataSet} dataSet
   * @param {String} rowId
   */
  static getAllParentsOfRowById(dataSet, rowId) {
    const parentRows = [];
    let currentRowId = rowId;
    let parentRowId = this.getParentRowById(dataSet, currentRowId);
    while (parentRowId !== currentRowId) {
      currentRowId = parentRowId;
      parentRows.push(currentRowId);
      parentRowId = this.getParentRowById(dataSet, parentRowId);
    }

    return parentRows;
  }
}

/**
 * Tupel which saves GanttDataRow and depth of row.
 * @class
 * @constructor
 */
export class FoundRowData {
  data: GanttDataRow = null;
  depth = 0;
  parent: GanttDataRow = null;
  open: boolean;
}
