import * as d3 from 'd3';
import { Observable, Subject, takeUntil, takeWhile } from 'rxjs';
import { GanttCallBackStackExecuter } from '../../callback-tools/callback-stack-executer';
import { YAxisDataFinder } from '../../data-handler/data-finder/yaxis-data-finder';
import { GanttCanvasRow, GanttCanvasShift } from '../../data-handler/data-structure/data-structure';
import { IGanttShiftResizeEvent } from '../../edit-shifts/shift-resizing/resize-events/resize-event.interface';
import { GanttShiftResizer } from '../../edit-shifts/shift-resizing/shift-resizer';
import { GanttShiftTranslator } from '../../edit-shifts/shift-translation/shift-translator';
import { GanttUtilities } from '../../gantt-utilities/gantt-utilities';
import { GanttScrollContainerEvent } from '../../html-structure/scroll-container-event';
import { PatternType } from '../../pattern/pattern-type.enum';
import { PatternHandler } from '../../pattern/patternHandler';
import { GanttAnimator } from '../../render/animator';
import { SelectionBoxDragEvent, SelectionBoxParameters } from '../../selector/element-selector';
import { GanttSelectShiftCalculator } from '../../selector/select-shifts-calculator';
import { GanttShiftCanvasGeneratorRenderCallback } from '../../shifts/canvas-generator';
import { ShiftBuilder } from '../../shifts/shift-builder';
import { IShiftClickEvent, IShiftDragEvent } from '../../shifts/shift-events.interface';
import { TimeFormatterAxisFormat } from '../../x-axis/axis-formats/x-axis-format-general';
import { ETimeMarkerAnchor, MarkerItem } from '../../x-axis/x-axis';
import { BestGanttPlugIn } from '../gantt-plug-in';
import { GanttTimePeriodMarker, GanttTimePeriodMouseOverEvent } from './create-timeperiod-marker';
import { GanttTimePeriodEditor } from './edit-timeperiod-marker';
import { GanttTimePeriodStrokeHandlerDataItem } from './stroke-handler';
import { GanttTimePeriodEvent } from './undo-redo/timeperiod-marker-event';

/**
 * Plug in which replaces the normal shift selection box with a timespan marker which
 * has a daily screening.
 *
 * Call 'activate' function to activate the plug-in.
 *
 * @keywords plugin, executer, wrapper, timeperiod, blocking, interval, holiday, illness, free, time
 * @plugin timeperiod-marker
 */
export class GanttTimePeriodExecuter extends BestGanttPlugIn {
  private _periodMarker: GanttTimePeriodMarker = undefined;
  private _periodEditor: GanttTimePeriodEditor = undefined;
  private _markerResizer: GanttShiftResizer = undefined;
  private _markerTranslator: GanttShiftTranslator = undefined;
  private _animator: GanttAnimator = undefined;

  private _markedTimePeriodData: MarkedTimePeriod[];
  private _selectedTimePeriods: SelectedTimePeriod[];

  private _mixedEditModeAvailable: boolean;
  private _active: boolean;
  private _activeEditMode: boolean;
  private _isForOverlapVisualization: boolean;
  private _selectionBoxRules: SelectionBoxParameters;
  private _permissions: any;
  private _timeOuts: any;
  private _markerIdCounter: number;
  private _type: string;
  private _color: string;
  /**
   * Default stroke style.
   */
  private _stroke: GanttTimePeriodStrokeHandlerDataItem;
  private _xAxisDateMarkers: MarkerItem[];
  private _yAxisDataset: GanttCanvasRow[];
  private _drawIntervalAfterSelectionBoxDraw: boolean;
  private _splitId: string;

  private _callBack: any;

  private _onDestroySubject: Subject<void> = new Subject<void>();
  private _onDeactivateCreateModeSubject: Subject<void> = new Subject<void>();
  private _onDeactivateEditModeSubject: Subject<void> = new Subject<void>();
  private _onDeactivateMouseOverEditModeSubject: Subject<void> = new Subject<void>();

  constructor() {
    super(); // call super-constructor

    this._active = false;
    this._activeEditMode = false;
    this._isForOverlapVisualization = false;

    this._markedTimePeriodData = [];

    this._selectionBoxRules = null;

    this._markerIdCounter = 0;

    this._type = '';
    this._color = 'red';

    this._stroke = null;

    this._selectedTimePeriods = [];

    this._callBack = {
      dragStart: {},
      // executed before time period has been drawn
      dragEndBeforeMarkerAdding: {},
      // executed after time period has been drawn
      dragEnd: {},
      // executed after dragEnd Callback (after data of blocking interval was updated)
      dragEndAfterDragEnd: {},
      // executed after time periods were selected via edit mode
      selectionEnd: {},
      // executed after marked time periods were removed
      afterRemove: {},
      // executed after onclick on interval
      afterSelectByClick: {},
      // execute after (de-)activation of interval plug in
      afterActiveStatusChanged: {},
      mouseOver: {}, // Special MouseOver that is active outside the edit Mode.
      mouseOut: {},

      editModeEnd: {}, // Edit Mode enabled, disabled
      editModeStart: {},

      interceptDragEnd: {}, // same as interceptDragCreation, but for existing blocks in edit mode
    };

    this._permissions = {
      resizeShifts: true,
    };

    this._timeOuts = {
      openToolTip: null,
      removeToolTip: null,
      showXAxisDateMarkers: null,
      removeXAxisDateMarkers: null,
      bringPeriodsToFront: null,
      bringPeriodsToBack: null,
      tooltip: null,
      renderToolTipOnDragEnd: null,
      renderToolTipOnDragStart: null,
    };

    this._xAxisDateMarkers = [];

    this._mixedEditModeAvailable = true;

    // backend option: do not draw blocking interval after dragging selection box
    this._drawIntervalAfterSelectionBoxDraw = true;

    this._yAxisDataset = null; // alternative yAxisDataset that is set by stickt parent Row PlugIn.
  }

  /**
   * @override
   */
  initPlugIn(ganttDiagram, patternHandler: PatternHandler = undefined) {
    this.ganttDiagram = ganttDiagram;

    this._periodMarker = new GanttTimePeriodMarker(this.ganttDiagram, patternHandler);
    this._periodMarker.init(this.ganttDiagram.getXAxisBuilder());

    this._periodEditor = new GanttTimePeriodEditor(this.ganttDiagram, this._periodMarker.getCanvasMap());

    this._selectionBoxRules = new SelectionBoxParameters();
    this._selectionBoxRules.color = this._color;
    this._selectionBoxRules.rasterRow = true;
    this._selectionBoxRules.timeGradation.unit = 'hour';
    this._selectionBoxRules.timeGradation.customStepSize = 0;

    const shiftBuilderMap: { [id: string]: ShiftBuilder } = {};
    for (const scrollContainerId of this.ganttDiagram.getRenderDataHandler().getAllScrollContainerIds()) {
      shiftBuilderMap[scrollContainerId] = this.getMarkedPeriodBuilder().getShiftBuilder(scrollContainerId);
    }

    this._markerResizer = new GanttShiftResizer(this.ganttDiagram);
    this._markerResizer.build(true, shiftBuilderMap);

    this._markerTranslator = new GanttShiftTranslator(this.ganttDiagram);

    this.addDragEndCallback('reRenderTextOverlayAfterBlockWasDragCreated' + this.UUID, () => {
      this.ganttDiagram.rerenderShiftTextOverlay();
    });

    if (this.ganttDiagram.xAxisBuilder) {
      this._initCallbacks();
    }

    this.ganttDiagram.subscribeMouseLeaveShiftContainer(`removeAndCancelTooltipBlockingInterval ${this.UUID}`, () => {
      this._resetMouseOverState();
    });

    this._animator = new GanttAnimator();
  }

  public removePlugIn(): void {
    // callback unsubscribe
    this._unsubscribeCallbacks();

    this.ganttDiagram.unSubscribeMouseLeaveShiftContainer(`removeAndCancelTooltipBlockingInterval ${this.UUID}`);

    this.removeDragEndCallback('reRenderTextOverlayAfterBlockWasDragCreated' + this.UUID);

    // remove helper plugins
    this._periodMarker.destroy();
    // clear TimeOuts
    GanttUtilities.clearTimeouts(this._timeOuts);

    this._onDeactivateCreateModeSubject.next();
    this._onDeactivateCreateModeSubject.complete();
    this._onDeactivateEditModeSubject.next();
    this._onDeactivateEditModeSubject.complete();
    this._onDeactivateMouseOverEditModeSubject.next();
    this._onDeactivateMouseOverEditModeSubject.complete();
    this._onDestroySubject.next();
    this._onDestroySubject.complete();
  }

  /**
   * Initializes all callbacks.
   */
  private _initCallbacks(): void {
    // external
    this.ganttDiagram
      .getOpenRowHandler()
      .afterShiftRowOpen.pipe(takeUntil(this.onDestroy))
      .subscribe(() => this.updateAllTimePeriodyPos());
    this.ganttDiagram
      .getOpenRowHandler()
      .afterShiftRowClosed.pipe(takeUntil(this.onDestroy))
      .subscribe(() => this.updateAllTimePeriodyPos());
    this.ganttDiagram
      .getConfig()
      .onShiftHeightChanged()
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => this.updatePlugInHeight());
    for (const scrollContainerId of this.ganttDiagram.getRenderDataHandler().getAllScrollContainerIds()) {
      this.ganttDiagram
        .getShiftFacade()
        .getCanvasAnimator(scrollContainerId)
        .beforePerformanceRenderShiftsCallback('_addTimePeriods' + this.UUID, (event) => this._addTimePeriods(event));
    }

    this.ganttDiagram
      .getXAxisBuilder()
      .onZooming.pipe(takeUntil(this.onDestroy))
      .subscribe(() => this._shiftZoomEnd());
    this.ganttDiagram
      .getVerticalScrollHandler()
      .onScrollVerticalUpdate.pipe(takeUntil(this.onDestroy))
      .subscribe(() => this._onScroll());

    this.ganttDiagram
      .getShiftFacade()
      .shiftMouseOver()
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => this._resetMouseOverState());

    this.ganttDiagram
      .getOpenRowHandler()
      .beforeShiftRowOpen.pipe(takeUntil(this.onDestroy))
      .subscribe((event) => this._startOpenRowAnimation(event));
    this.ganttDiagram
      .getOpenRowHandler()
      .beforeShiftRowClosed.pipe(takeUntil(this.onDestroy))
      .subscribe((event) => this._startCloseRowAnimation(event));

    // internal
    this._periodMarker.addMouseOverCallback(
      'timePeriodMouseOverCallback' + this.UUID,
      this._mouseOverCallbackStackExecuteWrapper.bind(this)
    );
    this._periodMarker.addMouseOutCallback(
      'timePeriodMouseOverCallback' + this.UUID,
      this._mouseOutCallbackStackExecuteWrapper.bind(this)
    );

    this.addMouseOverCallback('showTooltipOnMouseOver' + this.UUID, this._showToolTip.bind(this));
    this.addMouseOverCallback('deHighlightXAxisOnMouseOverBlockingInterval' + this.UUID, () => {
      this._deHighlightXAxis();
    });
    this.addMouseOutCallback('deHighlightXAxisOnMouseOverBlockingInterval' + this.UUID, () => {
      this.ganttDiagram.getXAxisBuilder().highlightXAxis();
    });
    this._periodMarker
      .shiftDragEnd()
      .pipe(takeUntil(this.onDestroy))
      .subscribe((event) => this._showToolTip(event));
    this._periodMarker
      .shiftDragStart()
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => this._closeToolTip());
    this.addMouseOutCallback('removeTooltipOnMouseOut' + this.UUID, this._closeToolTip.bind(this));

    this.addMouseOverCallback('showDatemarkersOnXAxis' + this.UUID, this._showDateMarkers.bind(this));
    this.addMouseOutCallback('removeDatemarkersOnXAxis' + this.UUID, this._removeDateMarkers.bind(this));

    this._markerResizer
      .onShiftEditUpdate()
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => this.ganttDiagram.getXAxisBuilder().removeAllDateMarkers());

    this._markerResizer
      .afterShiftEditUpdate()
      .pipe(takeUntil(this.onDestroy))
      .subscribe((event) => this._afterDuringShiftResizing(event));

    this._markerResizer
      .onShiftEditUpdate()
      .pipe(takeUntil(this.onDestroy))
      .subscribe((event: IGanttShiftResizeEvent) => {
        const target = event.shiftSelection;
        const zoom = this.ganttDiagram.getXAxisBuilder().getLastZoomEvent();
        const targetData = target.data()[0];
        const lineTop = this.ganttDiagram.getConfig().getLineTop();
        const targetCopy = JSON.parse(JSON.stringify(targetData));
        targetCopy.x = (parseFloat(target.attr('x')) - zoom.x) / zoom.k;
        targetCopy.width = parseFloat(target.attr('width')) / zoom.k;
        targetCopy.y += lineTop;
        const textOverlayElement = this.ganttDiagram.getTextOverlay().getTextElementById(targetData.id);
        this.ganttDiagram.getTextOverlay().buildSmallerText([], targetCopy, textOverlayElement, zoom);
      });

    this._periodMarker
      .shiftDragStart()
      .pipe(takeUntil(this.onDestroy))
      .subscribe((event) => {
        const shift = event.event.target;
        const shiftData = shift.data()[0];

        GanttUtilities.registerNoRenderId(shiftData, this.UUID);
        setTimeout(this.ganttDiagram.rerenderShiftTextOverlay.bind(this.ganttDiagram), 0);
      });

    this._periodMarker
      .shiftDragEnd()
      .pipe(takeUntil(this.onDestroy))
      .subscribe((event) => {
        const shift = event.event.target;
        const shiftData = shift.data()[0];

        GanttUtilities.removeNoRenderId(shiftData, this.UUID);
        setTimeout(this.ganttDiagram.rerenderShiftTextOverlay.bind(this.ganttDiagram), 0);
      });

    this.afterRemoveCallback(`reRenderTextAfterRemove ${this.UUID}`, () => {
      setTimeout(() => this.ganttDiagram.rerenderShiftTextOverlay(), 0);
    });

    this._periodMarker
      .afterShiftRender()
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => this._colorSelectedTimePeriods());
  }

  /**
   * Callback function to decrease x axis opacity.
   */
  private _deHighlightXAxis() {
    this.ganttDiagram.getXAxisBuilder().getAxisContainer().transition().style('opacity', '0.6');
  }

  /**
   * Activates Edit Mode On MouseOver of blocking interval
   */
  private _mouseOverBringTimePeriodsToFront(): void {
    clearTimeout(this._timeOuts.bringPeriodsToFront);
    clearTimeout(this._timeOuts.bringPeriodsToBack);
    this._timeOuts.bringPeriodsToFront = setTimeout(this.activateEditMode.bind(this), 100);
  }

  /**
   * Deactivates Edit Mode On MouseOut of blocking interval
   */
  private _mouseOutBringTimePeriodsToFront(): void {
    clearTimeout(this._timeOuts.bringPeriodsToFront);
    clearTimeout(this._timeOuts.bringPeriodsToBack);
    this._timeOuts.bringtPeriodsToBack = setTimeout(this.deactivateEditMode.bind(this), 100);
  }

  private _afterDuringShiftResizing(event: IGanttShiftResizeEvent): void {
    const x = parseFloat(event.shiftSelection.attr('x'));
    const width = parseFloat(event.shiftSelection.attr('width'));
    this.ganttDiagram.getXAxisBuilder().addMarkerByPointsWhileResizing(x, ETimeMarkerAnchor.END);
    this.ganttDiagram.getXAxisBuilder().addMarkerByPointsWhileResizing(x + width, ETimeMarkerAnchor.START);
  }

  /**
   * Unsubscribe all callbacks.
   */
  private _unsubscribeCallbacks(): void {
    for (const scrollContainerId of this.ganttDiagram.getRenderDataHandler().getAllScrollContainerIds()) {
      this.ganttDiagram
        .getShiftFacade()
        .getCanvasAnimator(scrollContainerId)
        .removeBeforePerformanceRenderShiftsCallback('_addTimePeriods' + this.UUID);
    }

    // internal
    this.removeMouseOverCallback('showTooltipOnMouseOver' + this.UUID);
    this.removeMouseOutCallback('removeTooltipOnMouseOut' + this.UUID);
    this.removeMouseOverCallback('deHighlightXAxisOnMouseOverBlockingInterval' + this.UUID);
    this.removeMouseOutCallback('deHighlightXAxisOnMouseOverBlockingInterval' + this.UUID);
    this._periodMarker.removeMouseOverCallback('timePeriodMouseOverCallback' + this.UUID);
    this._periodMarker.removeMouseOutCallback('timePeriodMouseOutCallback' + this.UUID);

    this.removeAfterRemoveCallback(`reRenderTextAfterRemove ${this.UUID}`);

    this._unSubscribeMouseOverEditMode();
  }

  /**
   * Shows tooltip on MouseOver timePeriod.
   * @param timePeriodEvent
   */
  private _showToolTip(
    timePeriodEvent: GanttTimePeriodMouseOverEvent | GanttScrollContainerEvent<IShiftDragEvent>
  ): void {
    let timePeriodID, x, y;

    if (timePeriodEvent instanceof GanttTimePeriodMouseOverEvent) {
      timePeriodID = timePeriodEvent.timePeriodID;
      x = timePeriodEvent.x;
      y = timePeriodEvent.y;
    } else if (timePeriodEvent) {
      const shift = timePeriodEvent.event.target;
      const shiftData = shift.data()[0];
      timePeriodID = shiftData.id;
      x = timePeriodEvent.event.event.sourceEvent.clientX;
      y = timePeriodEvent.event.event.sourceEvent.clientY;
    }
    const timePeriod = this._periodMarker.getMarkerDataById(timePeriodID);
    const toolTipData = timePeriod ? timePeriod.tooltip : null;

    if (toolTipData) {
      // add Tooltip
      this.ganttDiagram.getShiftFacade().getTooltipBuilder().addTooltipToHTMLBody(x, y, toolTipData);
    }
  }

  /**
   * Callback that resets the timePeriodMouseOver State whenever a "real" shift is mouse overed.
   * Fixes the case when a shift is ontop of a timePeriod and the timePeriod would be still mouse over,
   * when actually the shift should be mouse over.
   */
  private _resetMouseOverState() {
    clearTimeout(this.getTimeOuts().showXAxisDateMarkers);
    this.getTimeOuts().showXAxisDateMarkers = null;
    this._periodMarker.setMouseOverActive('');
    this._xAxisDateMarkers.forEach((marker) => this.ganttDiagram.getXAxisBuilder().removeDateMarkerByID(marker.id));
    this._xAxisDateMarkers.length = 0;
  }

  /**
   * Closes tooltip on MouseOut timePeriod.
   */
  private _closeToolTip() {
    this.ganttDiagram.getShiftFacade().getTooltipBuilder().removeAllTooltips();
    clearTimeout(this.getTimeOuts().showXAxisDateMarkers);
    clearTimeout(this.getTimeOuts().tooltip);
  }

  /**
   * Renders date Markers on xAxis when timePeriod MouseOver. Corresponding {@link GanttTimePeriodExecuter#_removeDateMarkers}.
   * @param timePeriodMousOverEvent
   */
  private _showDateMarkers(timePeriodMousOverEvent: GanttTimePeriodMouseOverEvent): void {
    this.getTimeOuts().showXAxisDateMarkers = null;
    // if (this.activeEditMode) return;
    this.getTimeOuts().showXAxisDateMarkers = setTimeout(() => {
      const { timePeriodID } = timePeriodMousOverEvent;

      const timePeriod = this._periodMarker.getMarkerDataById(timePeriodID);
      if (timePeriod) {
        const periodData = this._markedTimePeriodData.find((timePeriodData) => timePeriodData.id === timePeriod.id);
        if (periodData) {
          this.ganttDiagram.getXAxisBuilder().addMarkerByDate(periodData.dateStart, ETimeMarkerAnchor.END);
          this.ganttDiagram.getXAxisBuilder().addMarkerByDate(periodData.dateEnd, ETimeMarkerAnchor.START);
        }
      }
    }, this.ganttDiagram.getConfig().tooltipDelay() * 0.1);
  }

  /**
   * Removes date Markers on xAxis when timePeriod MouseOut. Corresponding {@link GanttTimePeriodExecuter#_showDateMarkers}.
   */
  private _removeDateMarkers(): void {
    this.ganttDiagram.getXAxisBuilder().removeAllDateMarkers();
    this._xAxisDateMarkers.length = 0;
  }

  /**
   * Wrapper needed to put callbackStack into another CallbackStack. Corresponding <b>{@link GanttTimePeriodExecuter#_mouseOutCallbackStackExecuteWrapper}</b>
   */
  private _mouseOverCallbackStackExecuteWrapper(timePeriodID) {
    GanttCallBackStackExecuter.execute(this._callBack.mouseOver, timePeriodID);
  }
  /**
   * Wrapper needed to put callbackStack into another CallbackStack. Corresponding <b>{@link GanttTimePeriodExecuter#_mouseOverCallbackStackExecuteWrapper}</b>
   */
  private _mouseOutCallbackStackExecuteWrapper() {
    GanttCallBackStackExecuter.execute(this._callBack.mouseOut);
  }

  /**
   * Callback registered on ShiftCanvas. Notified if mouse hits no shift.
   * Checks if a timePeriod is hit by Mouse instead.
   * @param mouseEvent Native Mouse Event.
   */
  public possibleMouseOverCallback(mouseEvent: GanttScrollContainerEvent<MouseEvent>): GanttCanvasShift[] {
    return this._periodMarker.possibleMouseOverCallback(mouseEvent);
  }

  public update(): void {
    const markedPeriodsMap = this.getMarkedPeriodsMap();
    this._updateWidthData(this._periodMarker.getMarkedAreas(), markedPeriodsMap);
    this._updateYPositionData(markedPeriodsMap);
    this.updatePlugInHeight();
    this._periodMarker.buildMarkers(this._isForOverlapVisualization);
  }

  /**
   * Special implementation of updatePlugInHeight.
   * Called by ganttConfig on row Height Change.
   * @param {number} newHeight new RowHeight
   */
  updatePlugInHeight() {
    this._updateDataHeight();
    this.updateAllTimePeriodyPos();
    this._periodMarker.updatePlugInHeight(this.getMarkedPeriodsMap());
  }

  /**
   * Internal update height time period data handling.
   */
  private _updateDataHeight() {
    const dataHeightMap = new Map<string, number>();

    this._markedTimePeriodData.forEach((period) => {
      if (!dataHeightMap.has(period.yId)) {
        // new height calculation
        const height = this.calculateResourceHeight(period.yId, this._splitId);
        dataHeightMap.set(period.yId, height);
        period.height = height;
      } else {
        // already has height calculation result
        period.height = dataHeightMap.get(period.yId);
      }
    });
  }

  /**
   * Internal time period data handling to update horizontal time period proportions.
   */
  private _updateWidthData(markerData: GanttCanvasShift[], markedPeriodsMap: Map<string, MarkedTimePeriod>) {
    const xScale = this.ganttDiagram.xAxisBuilder.getGlobalScale();

    markerData.forEach((marker) => {
      const id = marker.id;
      const timPeriodData = markedPeriodsMap.get(id);
      const startScale = xScale(timPeriodData.dateStart);
      marker.x = startScale;
      marker.width = xScale(timPeriodData.dateEnd) - startScale;
    });
  }

  /**
   * Updates the y-position data for the marked time periods.
   * @param markedTimePeriodsMap - A map of marked time periods.
   */
  private _updateYPositionData(markedTimePeriodsMap: Map<string, MarkedTimePeriod>) {
    const yAxisDatasetMap = YAxisDataFinder.getYAxisCanvasMap(this.ganttDiagram.getDataHandler().getYAxisDataset());

    for (const markedArea of this._periodMarker.getMarkedAreas()) {
      const foundPeriodData = markedTimePeriodsMap.get(markedArea.id);
      if (!foundPeriodData) continue;
      const yAxisData = yAxisDatasetMap.get(foundPeriodData.yId);
      markedArea.y = yAxisData ? yAxisData.y : null;
    }
  }

  /**
   * Activates modus to create new timeperiods by drag.
   */
  activate() {
    if (this._active) return;
    this._active = true;
    this._updateBoxRules();
    this._initCallbacks();

    this.ganttDiagram
      .getSelectionBoxFacade()
      .onDragStart.pipe(takeUntil(this.onDeactivateCreateMode))
      .subscribe((event) => this._selectionBoxStartPoint(event));
    this.ganttDiagram
      .getSelectionBoxFacade()
      .afterSelection.pipe(takeUntil(this.onDeactivateCreateMode))
      .subscribe((event) => this._markSelectedArea(event));
    this.ganttDiagram
      .getXAxisBuilder()
      .onZoomEnd.pipe(takeUntil(this.onDeactivateCreateMode))
      .subscribe(() => this._updateBoxRules());
    GanttCallBackStackExecuter.execute(this._callBack.afterActiveStatusChanged, this._active);
  }

  /**
   * Deactivates modus to create new timeperiods by drag.
   */
  public deactivate(): void {
    if (!this._active) return;
    this._active = false;

    this.ganttDiagram.getSelectionBoxFacade().clearSelectionBoxRules();

    this._onDeactivateCreateModeSubject.next();

    GanttCallBackStackExecuter.execute(this._callBack.afterActiveStatusChanged, this._active);
  }

  /**
   * Adds time periods to a row in the Gantt chart.
   *
   * @param periods - An array of time period data to be added.
   * @param rerenderText - Optional. Specifies whether to rerender the text overlay after adding the time periods. Default is true.
   * @param sort - Optional. Specifies whether to sort the time periods after adding them. Default is true.
   */
  addTimePeriodsToRow(periods: TimePeriodAddData[], rerenderText = true, sort = true) {
    const scale = this.ganttDiagram.xAxisBuilder.getGlobalScale();
    const yAxisMap = YAxisDataFinder.getYAxisCanvasMap(this.ganttDiagram.getDataHandler().getYAxisDataset());

    periods.forEach((period) => {
      const yAxisData = yAxisMap.get(period.rowId);

      const id = period.intervalId || this._getUniqueMarkerId();

      const markedPeriod = new MarkedTimePeriod();
      markedPeriod.id = id;
      markedPeriod.name = period.name || 'Blocked';
      markedPeriod.dateStart = period.timeStart;
      markedPeriod.dateEnd = period.timeEnd;
      markedPeriod.yId = period.rowId;
      markedPeriod.height = yAxisData ? yAxisData.height : null;
      markedPeriod.y = yAxisData ? yAxisData.y : null;
      markedPeriod.tooltip = period.tooltip || '';
      markedPeriod.customColor = period.customColor;
      markedPeriod.pattern = period.pattern || null;
      markedPeriod.patternColor = period.patternColor || '#333333';
      markedPeriod.sIds = period.sIds || [];
      markedPeriod.additionalDetails = period.additionalDetails || {};

      const scaledStart = scale(period.timeStart);

      this._periodMarker.addMarker(
        markedPeriod.id,
        scaledStart,
        markedPeriod.y,
        scale(period.timeEnd) - scaledStart,
        markedPeriod.height,
        markedPeriod.customColor || this._selectionBoxRules.color,
        period.stroke || this._stroke,
        markedPeriod.tooltip || '',
        markedPeriod.name || 'Blocked',
        markedPeriod.pattern || null,
        markedPeriod.patternColor || '#333333',
        sort
      );

      this._markedTimePeriodData.push(markedPeriod);
    });

    if (rerenderText) {
      this.ganttDiagram.rerenderShiftTextOverlay();
    }
  }

  calculateResourceHeight(rowId: string, stickyId: string) {
    const memberRows = YAxisDataFinder.getAllSplitRowsById(
      rowId,
      stickyId,
      this.ganttDiagram.getDataHandler().getYAxisDataset()
    );
    let rowHeight = 0;
    memberRows.forEach((row) => (rowHeight += row.height));
    return rowHeight;
  }

  /**
   * Toggles edit mode activation on mouse over of timePeriods
   * @param {boolean} enable true: enables mouse over, false: disables mouse over
   */
  toggleEditModeOnMouseOver(enable) {
    if (enable && this._mixedEditModeAvailable) {
      // Activate mouse over edit mode.
      this._unSubscribeMouseOverEditMode();
      this._subscribeMouseOverEditMode();
    } else {
      // DEactivate mouse over edit mode.
      this._unSubscribeMouseOverEditMode();
    }
  }

  private _subscribeMouseOverEditMode() {
    this.addMouseOverCallback(
      'bringMarkerToFrontOnMouseOver' + this.UUID,
      this._mouseOverBringTimePeriodsToFront.bind(this)
    );
    this.addMouseOutCallback(
      'bringMarkerToFrontOnMouseOver' + this.UUID,
      this._mouseOutBringTimePeriodsToFront.bind(this)
    );

    // blocking interval moves back if shift is hit behind it
    this.ganttDiagram
      .getShiftFacade()
      .shiftMouseOverShiftOnCanvas()
      .pipe(takeUntil(this.onDeactivateMouseOverEditMode))
      .subscribe(() => this._mouseOutBringTimePeriodsToFront());
  }

  private _unSubscribeMouseOverEditMode() {
    this.removeMouseOverCallback('bringMarkerToFrontOnMouseOver' + this.UUID);
    this.removeMouseOutCallback('bringMarkerToFrontOnMouseOver' + this.UUID);
    this._onDeactivateMouseOverEditModeSubject.next();
  }

  /**
   * Callback function to use selection box as time period builder.
   * @param event Object containing proportions of the selection box and the event that triggered this callback.
   */
  private _markSelectedArea(event: GanttScrollContainerEvent<SelectionBoxDragEvent>): void {
    // deselect all shifts
    this.ganttDiagram
      .getSelectionBoxFacade()
      .deselectAllShifts(
        this.ganttDiagram.getDataHandler().getCanvasShiftDataset(),
        this.ganttDiagram.getDataHandler().getOriginDataset().ganttEntries
      );
    this.ganttDiagram.rerenderShiftsVertical();

    const renderY = d3.min([
      event.event.selectionBoxProportions.topLeft[1],
      event.event.selectionBoxProportions.bottomRight[1],
    ]);
    const rowId = this.ganttDiagram.getRenderDataHandler().getStateStorage().getRowIdByYPosition(renderY, event.source);

    const x = d3.min([
      event.event.selectionBoxProportions.topLeft[0],
      event.event.selectionBoxProportions.bottomRight[0],
    ]);
    const y = YAxisDataFinder.getCanvasRowById(this.ganttDiagram.getDataHandler().getYAxisDataset(), rowId).y;
    const width = Math.abs(
      event.event.selectionBoxProportions.topLeft[0] - event.event.selectionBoxProportions.bottomRight[0]
    );
    const height = Math.abs(
      event.event.selectionBoxProportions.topLeft[1] - event.event.selectionBoxProportions.bottomRight[1]
    );

    if (width === 0) {
      // Blocking Interval with 0 width doesn't make sense to create.
      return;
    }

    const fromDate =
      event.event.selectionBoxProportions.timeSpan[0] > event.event.selectionBoxProportions.timeSpan[1]
        ? event.event.selectionBoxProportions.timeSpan[1]
        : event.event.selectionBoxProportions.timeSpan[0];
    const toDate =
      event.event.selectionBoxProportions.timeSpan[0] > event.event.selectionBoxProportions.timeSpan[1]
        ? event.event.selectionBoxProportions.timeSpan[0]
        : event.event.selectionBoxProportions.timeSpan[1];

    const zoom = this.ganttDiagram.xAxisBuilder.getLastZoomEvent();

    const markedPeriod = new MarkedTimePeriod();
    markedPeriod.id = this._getUniqueMarkerId();
    markedPeriod.dateStart = new Date(fromDate);
    markedPeriod.dateEnd = new Date(toDate);
    markedPeriod.yId = rowId;
    markedPeriod.height = height;
    markedPeriod.y = y;
    markedPeriod.descriptionData = null;
    markedPeriod.groupId = this.getAdditionalData().ganttTimePeriodGroupIntervalInputId;
    markedPeriod.type = this.getAdditionalData().type;

    GanttCallBackStackExecuter.execute(this._callBack.dragEndBeforeMarkerAdding, markedPeriod);

    if (!this._drawIntervalAfterSelectionBoxDraw) return;

    this._periodMarker.addMarker(
      markedPeriod.id,
      (x - zoom.x) / zoom.k,
      y,
      width / zoom.k,
      markedPeriod.height,
      this._selectionBoxRules.color,
      this._stroke
    );
    this._markedTimePeriodData.push(markedPeriod);

    this.ganttDiagram
      .getHistory()
      .addNewEvent(
        'addTimePeriodToRow',
        new GanttTimePeriodEvent(),
        this,
        markedPeriod /* , this.selectionBoxRules.color, this.stroke*/
      );
    // execute callbacks
    GanttCallBackStackExecuter.execute(this._callBack.dragEnd, markedPeriod);
  }

  /**
   * Callback to determine start point of selection box. Necessary if time rasterization is activated.
   * The adjustment of the time is based on the smallest unit on the x-axis.
   * @param event
   */
  private _selectionBoxStartPoint(event: GanttScrollContainerEvent<SelectionBoxDragEvent>): void {
    // update selection box height
    const canvasRow = this.ganttDiagram
      .getRenderDataHandler()
      .getStateStorage()
      .getRowByYPosition(event.event.event.y, event.source);
    this._selectionBoxRules.height.min = canvasRow.height;
    this._selectionBoxRules.height.max = canvasRow.height;
    this.ganttDiagram.getSelectionBoxFacade().setBoxRules(this._selectionBoxRules);

    this._intervalDragStartCallback(event.event.event);
  }

  private _updateBoxRules(): void {
    const currentTimeFormats = this.ganttDiagram.getXAxisBuilder().getCurrentTimeFormats();
    this._selectionBoxRules.timeGradation.unit = this._getTimeGradationUnit(
      currentTimeFormats[currentTimeFormats.length - 1]
    );
    this.ganttDiagram.getSelectionBoxFacade().setBoxRules(this._selectionBoxRules);
  }

  /**
   * Converts the time format of the x-axis into a format that can be processed by the TimeGradationHandler.
   * Presets custom time grid.
   * @param {TimeFormatterAxisFormat} timeFormat
   * @returns
   */
  private _getTimeGradationUnit(timeFormat: TimeFormatterAxisFormat) {
    const ticksStepSize = this._getTimeFormatTicksStepSize(timeFormat);

    switch (timeFormat.unit) {
      case 'YEAR':
        console.warn('Not supported time format!');
        this._selectionBoxRules.timeGradation.unit = 'month';
        return 'month';
      case 'MONTH':
        if (ticksStepSize === 3) {
          this._selectionBoxRules.timeGradation.unit = 'quarter';
          return 'quarter';
        } else if (ticksStepSize === 1) {
          this._selectionBoxRules.timeGradation.unit = 'month';
          return 'month';
        } else {
          console.warn('Not supported time format!');
          this._selectionBoxRules.timeGradation.unit = 'hour';
          return 'hour';
        }
      case 'CALENDAR_WEEK':
        if (ticksStepSize === 1) {
          this._selectionBoxRules.timeGradation.unit = 'week';
          return 'week';
        } else {
          console.warn('Not supported time format!');
          this._selectionBoxRules.timeGradation.unit = 'hour';
          return 'hour';
        }
      case 'DAY':
        if (ticksStepSize === 1) {
          this._selectionBoxRules.timeGradation.unit = 'day';
          return 'day';
        } else {
          this._selectionBoxRules.timeGradation.customStepSize = ticksStepSize * 86400000;
          this._selectionBoxRules.timeGradation.unit = 'customized';
          return 'customized';
        }
      case 'HOUR':
        if (ticksStepSize === 1) {
          this._selectionBoxRules.timeGradation.unit = 'hour';
          return 'hour';
        } else {
          this._selectionBoxRules.timeGradation.customStepSize = ticksStepSize * 3600000;
          this._selectionBoxRules.timeGradation.unit = 'customized';
          return 'customized';
        }
      case 'MINUTE':
        if (ticksStepSize === 1) {
          this._selectionBoxRules.timeGradation.unit = 'minute';
          return 'minute';
        } else {
          this._selectionBoxRules.timeGradation.customStepSize = ticksStepSize * 60000;
          this._selectionBoxRules.timeGradation.unit = 'customized';
          return 'customized';
        }
      case 'SECOND':
        this._selectionBoxRules.timeGradation.customStepSize = ticksStepSize * 1000;
        this._selectionBoxRules.timeGradation.unit = 'customized';
        return 'customized';
      default:
        return 'hour';
    }
  }

  /**
   * Calculates the step value of a given time format considering the unit.
   * @param {TimeFormatterAxisFormat} timeFormat Time format for calculation.
   * @returns {number} Calculated step value or null when error.
   */
  private _getTimeFormatTicksStepSize(timeFormat: TimeFormatterAxisFormat): number {
    const now = new Date();
    const ticksStepSizeMs = timeFormat.ticks.ceil(now).getTime() - timeFormat.ticks.floor(now).getTime();
    const lengthMonthMax = 2678400000; // 31 days in ms

    switch (timeFormat.unit) {
      case 'MONTH':
        return Math.round(ticksStepSizeMs / lengthMonthMax);
      case 'CALENDAR_WEEK':
        return ticksStepSizeMs / (d3.timeWeek.ceil(now).getTime() - d3.timeWeek.floor(now).getTime());
      case 'DAY':
        return ticksStepSizeMs / (d3.timeDay.ceil(now).getTime() - d3.timeDay.floor(now).getTime());
      case 'HOUR':
        return ticksStepSizeMs / (d3.timeHour.ceil(now).getTime() - d3.timeHour.floor(now).getTime());
      case 'MINUTE':
        return ticksStepSizeMs / (d3.timeMinute.ceil(now).getTime() - d3.timeMinute.floor(now).getTime());
      case 'SECOND':
        return ticksStepSizeMs / (d3.timeSecond.ceil(now).getTime() - d3.timeSecond.floor(now).getTime());
      default:
        // unknown unit or calculation not neccesary
        return null;
    }
  }

  /**
   * Executes own dragstart callback stack.
   * @param {d3.D3DragEvent<SVGRectElement,unknown,unknown>} event
   */
  private _intervalDragStartCallback(event: d3.D3DragEvent<SVGRectElement, unknown, unknown>) {
    GanttCallBackStackExecuter.execute(this._callBack.dragStart, event);
  }

  /**
   * Activates edit mode of this executers time periods.
   * All time periods can be dragged, resized, selected and deleted.
   */
  public activateEditMode(): void {
    if (this._activeEditMode) return;

    this._activeEditMode = true;
    this._periodEditor.bringPeriodsToFront();
    if (this._permissions.resizeShifts) {
      this._periodMarker
        .shiftMouseOver()
        .pipe(takeUntil(this.onDeactivateEditMode), takeWhile(this._permissions.resizeShifts))
        .subscribe((event) => this._markerResizer.buildResizeHandlesByMouseEvent(event.event, event.source));
    }
    this._periodMarker
      .shiftMouseOver()
      .pipe(takeUntil(this.onDeactivateEditMode))
      .subscribe((event) => {
        // cancels shift hovering if blocking interval is hit & infront of shift due to edit mode
        this.ganttDiagram.getShiftFacade().getShiftBuilder(event.source).addStopShiftHovering(this.UUID);
      });
    this._periodMarker
      .shiftMouseOut()
      .pipe(takeUntil(this.onDeactivateEditMode))
      .subscribe((event) => {
        this.ganttDiagram.getShiftFacade().getShiftBuilder(event.source).removeStopShiftHovering(this.UUID);
      });

    this._markerResizer
      .onShiftEditEnd()
      .pipe(takeUntil(this.onDeactivateEditMode))
      .subscribe((event) => this._updateNewMarkerEndPosition(event));

    // drag shifts
    this._periodMarker
      .shiftDragStart()
      .pipe(takeUntil(this.onDeactivateEditMode))
      .subscribe((event) => this._markerTranslationStart(event));
    this._periodMarker
      .shiftDragging()
      .pipe(takeUntil(this.onDeactivateEditMode))
      .subscribe((event) => this._markerTranslationDragging(event));
    this._periodMarker
      .shiftDragEnd()
      .pipe(takeUntil(this.onDeactivateEditMode))
      .subscribe((event) => this._markerTranslationEnd(event));

    this._periodMarker
      .shiftOnClick()
      .pipe(takeUntil(this.onDeactivateEditMode))
      .subscribe((event) => this._onClickSelection(event));

    // resize shifts
    this._markerResizer
      .onShiftEditUpdate()
      .pipe(takeUntil(this.onDeactivateEditMode))
      .subscribe((event) => this._periodMarker.getStrokeHandler().updateStrokesByEvent(event));

    this.ganttDiagram
      .getSelectionBoxFacade()
      .afterSelection.pipe(takeUntil(this.onDeactivateEditMode))
      .subscribe((event) => this._selectTimePeriods(event));

    this._markerResizer
      .onShiftEditStart()
      .pipe(takeUntil(this.onDeactivateEditMode))
      .subscribe(() => this._closeToolTip());
  }

  /**
   * Deactivates edit mode of this executers time periods.
   */
  public deactivateEditMode(): void {
    if (!this._activeEditMode) return;

    this._activeEditMode = false;
    this.deselectTimePeriods();
    this._periodEditor.bringPeriodsBack();

    this._markerResizer.removeAllResizeHandles();

    this._onDeactivateEditModeSubject.next();

    GanttCallBackStackExecuter.execute(this._callBack.editModeEnd, null);
  }

  /**
   * For timeperiod selection by click
   * @param event click event
   */
  private _onClickSelection(event: GanttScrollContainerEvent<IShiftClickEvent>): void {
    GanttCallBackStackExecuter.execute(this._callBack.afterSelectByClick, event);
  }

  /**
   * Handles the selection of a time period by click.
   * @param event Click event to handle.
   * @param target Canvas shift data of the time period to be selected.
   */
  public handleClick(event: IShiftClickEvent, target: GanttCanvasShift): void {
    const mouseEvent = event.event;

    const selectionData = new SelectedTimePeriod(
      target.id,
      target.width,
      target.x,
      target.y,
      this.getAdditionalData().type,
      this.getAdditionalData().ganttTimePeriodGroupIntervalInputId
    );
    const isAlreadySelected = !!this._selectedTimePeriods.find((elem) => elem.id === selectionData.id);

    if (mouseEvent.shiftKey && !isAlreadySelected) {
      // shift key pressed
      this._selectedTimePeriods.push(selectionData);
    } else if (mouseEvent.ctrlKey) {
      // ctrl key pressed
      if (isAlreadySelected) {
        this._selectedTimePeriods = this._selectedTimePeriods.filter((elem) => elem.id !== selectionData.id); // deselect
      } else {
        this._selectedTimePeriods.push(selectionData);
      }
    } else {
      this._selectedTimePeriods = [selectionData];
    }

    this._colorSelectedTimePeriods();
  }

  /**
   * Callback function to change data of resized interval.
   * @param event
   */
  private _updateNewMarkerEndPosition(event: IGanttShiftResizeEvent): void {
    const target = event.shiftSelection;
    const zoom = this.ganttDiagram.getXAxisBuilder().getLastZoomEvent();

    const xStart = (parseFloat(target.attr('x')) - zoom.x) / zoom.k;
    const xEnd = xStart + parseFloat(target.attr('width')) / zoom.k;

    this.updateTimePeriodPosById(target.data()[0].id, xStart, xEnd, null, null);
    setTimeout(() => {
      this.sortMarkerData();
      this.ganttDiagram.update();
    }, 0);
  }

  /**
   * Handles translation animation while opening row.
   * @param {Object} data {positionY: number, translationY: number}
   */
  private _startOpenRowAnimation(data) {
    if (!data) {
      return;
    }
    this._animator.translateShiftAnimation(
      this.getMarkedPeriodBuilder().getCanvas(),
      data.positionY,
      data.translationY,
      null,
      null
    );
  }

  /**
   * Handles translation animation while closing row.
   * @param {Object} data {positionY: number, translationY: number}
   */
  private _startCloseRowAnimation(data) {
    if (!data) {
      return;
    }

    // remove all rects in this area
    this.getMarkedPeriodBuilder()
      .getCanvas()
      .selectAll<SVGRectElement, GanttCanvasShift>('rect')
      .filter((d) => {
        return d.y >= data.positionY && d.y <= data.positionY - data.translationY;
      })
      .remove();

    this._animator.translateShiftAnimation(
      this.getMarkedPeriodBuilder().getCanvas(),
      data.positionY,
      data.translationY,
      null,
      null
    );
  }

  /**
   * Returns time period with given id.
   * @param {string} id Time period id.
   * @return {MarkedTimePeriod} Found period. If no period was found, returns undefined.
   */
  public getTimePeriodById(id: string): MarkedTimePeriod {
    return this._markedTimePeriodData.find(function (item) {
      return item.id == id;
    });
  }

  /**
   * Callback which handles all action on interval drag start (during edit mode).
   * @param dragEvent Drag Event.
   */
  private _markerTranslationStart(dragEvent: GanttScrollContainerEvent<IShiftDragEvent>): void {
    const target = dragEvent.event.target;
    target.attr('height', () => target.data()[0].height);

    this.ganttDiagram.getShiftResizer().removeAllResizeHandles();
    this._markerResizer.removeAllResizeHandles();

    this._markerTranslator.start(dragEvent);
  }

  /**
   * Callback which handles all action on interval dragging (during edit mode).
   * @param dragEvent Drag Event.
   */
  private _markerTranslationDragging(dragEvent: GanttScrollContainerEvent<IShiftDragEvent>): void {
    const target = dragEvent.event.target;
    const targetData = target.data()[0];
    const zoom = this.ganttDiagram.getXAxisBuilder().getLastZoomEvent();

    this.ganttDiagram.getXAxisBuilder().removeAllDateMarkers();
    this.ganttDiagram.getShiftResizer().removeAllResizeHandles();
    this._markerResizer.removeAllResizeHandles();

    this._markerTranslator.update(dragEvent);
    const newCoordinates = this._markerTranslator.shiftEditState.dragCoordinate;

    if (!targetData || !targetData.width) return;

    const scaleWidth = targetData.width * zoom.k;
    this.ganttDiagram.markShiftXAxisByPosition(newCoordinates[0], scaleWidth);
  }

  /**
   * @param dragEvent Drag Event.
   */
  private _markerTranslationEnd(dragEvent: GanttScrollContainerEvent<IShiftDragEvent>): void {
    const zoom = this.ganttDiagram.getXAxisBuilder().getLastZoomEvent();

    const target = dragEvent.event.target;

    const xStart = (parseFloat(target.attr('x')) - zoom.x) / zoom.k;
    const xEnd = xStart + parseFloat(target.attr('width')) / zoom.k;

    const yMouse = dragEvent.event.event.y;
    const yAxisCanvasRow = this.ganttDiagram.getRenderDataHandler().getYAxisDataFinder().getRowByViewportY(yMouse);
    const roundedY = this.ganttDiagram.getRenderDataHandler().getStateStorage().getYPositionRow(yAxisCanvasRow.id);

    target.attr('y', roundedY);

    const intervalData = target.data()[0];

    if (this.possibleInterceptDragEnd(intervalData.id, yAxisCanvasRow ? yAxisCanvasRow.id : null)) {
      console.warn(`You can not drag a Blocking Interval to a sticky parent row as of now.`);
      setTimeout(() => {
        this.ganttDiagram.update();
      }, 0);
      return;
    }

    this._markerTranslator.finish(dragEvent);

    this.updateTimePeriodPosById(intervalData.id, xStart, xEnd, roundedY, yAxisCanvasRow ? yAxisCanvasRow.id : null);
    this.ganttDiagram.getXAxisBuilder().removeAllDateMarkers();

    this.sortMarkerData();

    GanttCallBackStackExecuter.execute(this._callBack.dragEndAfterDragEnd, dragEvent);
    setTimeout(() => {
      this.ganttDiagram.update();
    }, 0);
  }

  possibleInterceptDragEnd(intervalId, yAxisCanvasRowId) {
    const interceptFlag = {
      intercept: false,
    };
    GanttCallBackStackExecuter.execute(this._callBack.interceptDragEnd, {
      periodId: intervalId,
      targetRowId: yAxisCanvasRowId,
      interceptFlag: interceptFlag,
    });

    return interceptFlag.intercept;
  }

  sortMarkerData() {
    this._markedTimePeriodData = this._markedTimePeriodData.sort((a, b) => a.x - b.x);
    this._periodMarker.sortMarkedAreas();
  }

  /**
   * Calculates date on current scale by x position.
   * @param {number} xPos
   * @return {Date} Calculated date.
   */
  getDateByXPos(xPos) {
    const scale = this.ganttDiagram.xAxisBuilder.getCurrentScale(),
      zoom = this.ganttDiagram.xAxisBuilder.getLastZoomEvent();

    return this.ganttDiagram.getXAxisBuilder().pxToTime(xPos * zoom.k + zoom.x, scale);
  }

  /**
   * Updates data and canvas data of time period.
   * @param {string} id Id of time period which should be updated.
   * @param {number} xStart New x position.
   * @param {number} xEnd New end position.
   * @param {number} [y] New y position.
   * @param {string} [rowId] Id of row in y axis.
   */
  updateTimePeriodPosById(id, xStart, xEnd, y, rowId) {
    const dateStart = this.getDateByXPos(xStart),
      dateEnd = this.getDateByXPos(xEnd);

    const eventLog = new GanttTimePeriodExecuterEventLog();
    eventLog.id = id;
    eventLog.newXStart = xStart;
    eventLog.newXEnd = xEnd;
    eventLog.newY = y;
    eventLog.newRowId = rowId;

    this.ganttDiagram.getHistory().addNewEvent('updateTimePeriod', new GanttTimePeriodEvent(), this, eventLog);

    this._updateTimePeriodPosByIDwithDates(id, dateStart, dateEnd, eventLog, y, rowId);
    this._updateMarkerData(id, xStart, xEnd, y, eventLog);
  }

  /**
   * Updates data and canvas data of time period.
   * @param {string} id Id of time period which should be updated.
   * @param {Date} dateStart New start Date.
   * @param {Date} dateEnd New end Date.
   * @param {GanttTimePeriodExecuterEventLog} event Event for undo redo.
   * @param {number} [y] New y position.
   * @param {string} [rowId] Id of row in y axis.
   */
  private _updateTimePeriodPosByIDwithDates(id, dateStart, dateEnd, event, y, rowId) {
    const timePeriod = this.getTimePeriodById(id);
    if (timePeriod) {
      event.oldDateStart = JSON.parse(JSON.stringify(timePeriod.dateStart));
      event.oldDateEnd = JSON.parse(JSON.stringify(timePeriod.dateEnd));
      event.newDateStart = JSON.parse(JSON.stringify(dateStart));
      event.newDateEnd = JSON.parse(JSON.stringify(dateEnd));

      timePeriod.dateStart = dateStart;
      timePeriod.dateEnd = dateEnd;

      if (typeof y !== 'undefined') {
        event.oldY = timePeriod.y;
        timePeriod.y = y;
      }
      if (rowId) {
        event.oldRowId = timePeriod.yId;
        timePeriod.yId = rowId;
      }
    }
  }

  /**
   * Updates data and canvas data of time period.
   * @param {string} id Id of time period which should be updated.
   * @param {number} xStart New x position.
   * @param {number} xEnd New end position.
   * @param {number} y New y position.
   * @param {GanttTimePeriodExecuterEventLog} event Event for undo redo.
   */
  private _updateMarkerData(id, xStart, xEnd, y, event) {
    const markerData = this._periodMarker.getMarkerDataById(id);
    event.oldX = markerData.x;
    event.oldWidth = markerData.width;

    markerData.x = xStart;
    markerData.width = xEnd - xStart;
    if (typeof y !== 'undefined') {
      event.oldY = markerData.y;
      markerData.y = y;
    }
  }

  /**
   * Updates all canvas y data of time periods.
   * Kicks out canvas time period data if it's inside closed row.
   * Usefull after row open/closing.
   */
  updateAllTimePeriodyPos() {
    // update every timePeriod
    this._updateTimePeriodYPositions();

    // update timeperiod build && description build
    this._periodMarker.buildMarkers(this._isForOverlapVisualization);
    this.ganttDiagram.rerenderShiftTextOverlay();
  }

  /**
   * Updates the Y positions of the time periods based on the current state of the Gantt chart.
   * This function adjusts the Y positions of the time periods based on the corresponding rows in the Y axis dataset.
   * If a time period is marked as "noRender", its Y position is set to null and it is added to the hidden areas.
   */
  _updateTimePeriodYPositions() {
    const yAxisDatasetMap = YAxisDataFinder.getYAxisCanvasMap(
      this._yAxisDataset || this.ganttDiagram.getDataHandler().getYAxisDataset()
    );
    const markerCanvasMap = this._periodMarker.getMarkedAreasMap();

    for (const markedTimePeriod of this._markedTimePeriodData) {
      const rowData = yAxisDatasetMap.get(markedTimePeriod.yId);
      if (!rowData) continue;

      if (!rowData.noRender?.length) {
        const newY = rowData.y;
        const canvasMarker = markerCanvasMap.get(markedTimePeriod.id);

        markedTimePeriod.y = newY;
        this._periodMarker.removeFromHiddenAreas(markedTimePeriod.id, TimePeriodNoRenderReason.HIDDEN_COORDINATES);
        if (canvasMarker) canvasMarker.y = newY;
      } else {
        markedTimePeriod.y = null;
        this._periodMarker.addToHiddenAreas(markedTimePeriod.id, TimePeriodNoRenderReason.HIDDEN_COORDINATES);
      }
    }
  }

  private _selectTimePeriods(
    event: GanttScrollContainerEvent<SelectionBoxDragEvent>,
    clicked = false,
    target: d3.Selection<any, any, null, undefined> = undefined
  ): void {
    const scale = this.ganttDiagram.getXAxisBuilder().getGlobalScale(),
      zoom = this.ganttDiagram.getXAxisBuilder().getLastZoomEvent();
    const mappedTimePeriodData: SelectedTimePeriod[] = [];

    for (let i = 0; i < this.getAllMarkedPeriods().length; i++) {
      const period = this.getAllMarkedPeriods()[i];
      if (period.y != null) {
        const x = scale(period.dateStart);
        const y = period.y;
        const id = period.id;
        const width = scale(period.dateEnd) - scale(period.dateStart);

        mappedTimePeriodData.push(
          new SelectedTimePeriod(
            id,
            width,
            x,
            y,
            this.getAdditionalData().type,
            this.getAdditionalData().ganttTimePeriodGroupIntervalInputId
          )
        );
      }
    }

    // get selected time periods
    this._selectedTimePeriods = GanttSelectShiftCalculator.selectShiftsByCoordinates(
      event.event.selectionBoxProportions.topLeft,
      event.event.selectionBoxProportions.bottomRight,
      zoom,
      mappedTimePeriodData,
      [],
      this.ganttDiagram.config.colorSelect(),
      null, // dont set DataHandler for blocking Interval Selections
      this.ganttDiagram.getRenderDataHandler()
    );

    if (clicked && this._selectedTimePeriods.length) {
      this._selectedTimePeriods = [this._selectedTimePeriods[this._selectedTimePeriods.length - 1]];
    }

    if (target) {
      this._selectedTimePeriods = [mappedTimePeriodData.find((datum) => datum.id === target.data()[0].id)];
    }

    this._colorSelectedTimePeriods();

    GanttCallBackStackExecuter.execute(this._callBack.selectionEnd, this._selectedTimePeriods);
  }

  /**
   * Colors selection of the selected time periods.
   */
  private _colorSelectedTimePeriods(): void {
    for (const scrollContainerId of this.ganttDiagram.getRenderDataHandler().getAllScrollContainerIds()) {
      this._periodMarker
        .getCanvas(scrollContainerId)
        .selectAll<SVGRectElement, GanttCanvasShift>('rect')
        .attr('fill', (d) => {
          const selectedPeriod = this._selectedTimePeriods.find(function (item) {
            return d.id == item.id;
          });

          if (selectedPeriod) {
            const selectColorInterpolation = d3.interpolate(
              {
                colors: [d.color],
              },
              {
                colors: [this.ganttDiagram.getConfig().colorSelect()],
              }
            );
            return selectColorInterpolation(this.ganttDiagram.getConfig().colorSelectOpacity()).colors[0];
          }

          if (d.pattern) {
            return this._periodMarker
              .getShiftBuilder(scrollContainerId)
              .getPatternHandler()
              .getPatternAsUrl(d.pattern, d.color, d.patternColor);
          } else {
            return d.color || this._color;
          }
        });
    }
  }

  /**
   * Remove selected time periods (during edit mode).
   */
  removeSelectedTimePeriods() {
    if (!this._activeEditMode) return;

    const removeEvent = this.ganttDiagram
      .getHistory()
      .addNewEvent(
        'removeSelectedTPs',
        new GanttTimePeriodEvent(),
        this,
        [] /* , JSON.parse(JSON.stringify(this.stroke)) || null*/
      );

    for (let i = 0; i < this._selectedTimePeriods.length; i++) {
      const period = this._selectedTimePeriods[i];

      // undo-redo Data
      const removeIndexMarkedAreas = this._markedTimePeriodData.findIndex(function (area) {
        return area.id == period.id;
      });
      removeEvent.arguments[0].push(JSON.parse(JSON.stringify(this._markedTimePeriodData[removeIndexMarkedAreas])));
      // \ undo-redo Data

      this._removeTimePeriod(period.id);
    }

    this._selectedTimePeriods = [];
    GanttCallBackStackExecuter.execute(this._callBack.afterRemove);
  }

  deselectTimePeriods() {
    this._selectedTimePeriods = [];
    this._colorSelectedTimePeriods();
  }

  deselectTimePeriodById(id) {
    this._selectedTimePeriods = this._selectedTimePeriods.filter((elem) => elem.id !== id);
    this._colorSelectedTimePeriods();
  }

  /**
   * Removes a timeperiod by its ID.
   * @param periodID ID of the period to be removed.
   * @param rebuildMarkers If `true`, all time period markes will be rerendered (default is `true`).
   */
  private _removeTimePeriod(periodID: string, reuildMarkers = true): void {
    const removeIndexPeriodMarker = this._periodMarker.getMarkedAreas().findIndex(function (area) {
      return area.id == periodID;
    });
    if (removeIndexPeriodMarker != -1) this._periodMarker.getMarkedAreas().splice(removeIndexPeriodMarker, 1);

    const removeIndexMarkedAreas = this._markedTimePeriodData.findIndex(function (area) {
      return area.id == periodID;
    });
    if (removeIndexMarkedAreas != -1) this._markedTimePeriodData.splice(removeIndexMarkedAreas, 1);

    if (reuildMarkers) {
      this._periodMarker.buildMarkers(this._isForOverlapVisualization);
    }
  }

  public removeAllTimePeriods(): void {
    this._periodMarker.setMarkedAreas([]);
    this._markedTimePeriodData.length = 0;
  }

  /**
   * Removes all timeperiods from row by rowId
   * @param rowId Id of row where periods to be removed
   */
  removeAllTimePeriodsFromRow(rowId) {
    const periods = this.getAllTimePeriodsByRowId(rowId);
    for (let i = 0; i < periods.length; i++) {
      this._removeTimePeriod(periods[i].id);
    }
    GanttCallBackStackExecuter.execute(this._callBack.afterRemove);
  }

  /**
   * @param periodId
   * @param rebuildMarkers If `true`, all time period markes will be rerendered (default is `true`).
   */
  public removeTimePeriodById(periodId: string, rebuildMarkers = true): void {
    this._removeTimePeriod(periodId, rebuildMarkers);
    GanttCallBackStackExecuter.execute(this._callBack.afterRemove);
  }

  //
  // OBSERVABLES
  //

  /**
   * Observable which gets triggered when the instance gets destroyed.
   */
  private get onDestroy(): Observable<void> {
    return this._onDestroySubject.asObservable();
  }

  /**
   * Observable which gets triggered when the create mode gets deactivated.
   */
  private get onDeactivateCreateMode(): Observable<void> {
    return this._onDeactivateCreateModeSubject.asObservable();
  }

  /**
   * Observable which gets triggered when the edit mode gets deactivated.
   */
  private get onDeactivateEditMode(): Observable<void> {
    return this._onDeactivateEditModeSubject.asObservable();
  }

  /**
   * Observable which gets triggered when the mouse over edit mode gets deactivated.
   */
  private get onDeactivateMouseOverEditMode(): Observable<void> {
    return this._onDeactivateMouseOverEditModeSubject.asObservable();
  }

  //
  // CALLBACKS
  //

  addDragStartCallback(id, func) {
    this._callBack.dragStart[id] = func;
  }

  removeDragStartCallback(id) {
    delete this._callBack.dragStart[id];
  }

  addDragEndCallback(id, func) {
    this._callBack.dragEnd[id] = func;
  }

  removeDragEndCallback(id) {
    delete this._callBack.dragEnd[id];
  }

  addEditDragEndCallback(id, func) {
    this._callBack.dragEndAfterDragEnd[id] = func;
  }

  removeEditDragEndCallback(id) {
    delete this._callBack.dragEndAfterDragEnd[id];
  }

  subscribeInterceptDraggingEnd(id, func) {
    this._callBack.interceptDragEnd[id] = func;
  }

  unSubscribeInterceptDraggingEnd(id) {
    delete this._callBack.interceptDragEnd[id];
  }

  dragEndBeforeMarkerAdding(id, func) {
    this._callBack.dragEndBeforeMarkerAdding[id] = func;
  }

  removeDragEndBeforeMarkerAdding(id) {
    delete this._callBack.dragEndBeforeMarkerAdding[id];
  }

  selectionEndCallback(id, func) {
    this._callBack.selectionEnd[id] = func;
  }

  removeSelectionEndCallback(id) {
    delete this._callBack.selectionEnd[id];
  }

  afterRemoveCallback(id, func) {
    this._callBack.afterRemove[id] = func;
  }

  removeAfterRemoveCallback(id) {
    delete this._callBack.afterRemove[id];
  }

  public addAfterSelectByClickCallback(
    id: string,
    func: (event: GanttScrollContainerEvent<IShiftClickEvent>) => void
  ): void {
    this._callBack.afterSelectByClick[id] = func;
  }

  public removeAfterSelectByClickCallback(id: string): void {
    delete this._callBack.afterSelectByClick[id];
  }

  addAfterActiveStatusChanged(id, func) {
    this._callBack.afterActiveStatusChanged[id] = func;
  }

  removeAfterActiveStatusChanged(id) {
    delete this._callBack.afterActiveStatusChanged[id];
  }

  subscribeEditModeStart(id, func) {
    this._callBack.editModeStart[id] = func;
  }

  unSubscribeEditModeStart(id) {
    delete this._callBack.editModeStart[id];
  }

  subscribeEditModeEnd(id, func) {
    this._callBack.editModeEnd[id] = func;
  }

  unSubscribeEditModeEnd(id) {
    delete this._callBack.editModeEnd[id];
  }

  addMouseOverCallback(id, func) {
    this._callBack.mouseOver[id] = func;
  }

  removeMouseOverCallback(id) {
    delete this._callBack.mouseOver[id];
  }

  addMouseOutCallback(id, func) {
    this._callBack.mouseOut[id] = func;
  }

  removeMouseOutCallback(id) {
    delete this._callBack.mouseOut[id];
  }

  //
  // GETTER & SETTER
  //

  /**
   * @return {MarkedTimePeriod[]} List of all marked periods.
   */
  getAllMarkedPeriods() {
    return this._markedTimePeriodData;
  }

  getMarkedPeriodsMap() {
    const map = new Map<string, MarkedTimePeriod>();
    this._markedTimePeriodData.forEach((period) => map.set(period.id, period));
    return map;
  }

  /**
   * @return {MarkedTimePeriod[]} List periods which are inside given row.
   */
  getAllMarkedPeriodsByRowId(rowId) {
    return this._markedTimePeriodData.filter(function (period) {
      return period.yId == rowId;
    });
  }

  getMarkedPeriodById(periodId) {
    return this._markedTimePeriodData.find((period) => period.id === periodId);
  }

  /**
   * @return {GanttTimePeriodMarker} Time period executer.
   */
  getMarkedPeriodBuilder() {
    return this._periodMarker;
  }

  /**
   * Shift resize facade for marked time periods.
   */
  public get markerResizer(): GanttShiftResizer {
    return this._markerResizer;
  }

  /**
   * @returns {GanttShiftTranslator} Translator which handles time interval handling in edit mode.
   */
  getMarkerTranslator() {
    return this._markerTranslator;
  }

  /**
   * @return {GanttCanvasShift} List of all time period canvas data.
   */
  getMarkedAreaData() {
    return this._periodMarker.getMarkedAreas();
  }

  /**
   * @return {GanttCanvasShift} List of all visible time period canvas data.
   */
  getVisibleMarkerData() {
    return this._periodMarker.getVisibleMarkerData();
  }

  /**
   * @return {GanttCanvasShift} List of all time period canvas data.
   */
  getMarkedAreaById(periodID) {
    return this._periodMarker.getMarkedAreas().find(function (periodMarker) {
      return periodMarker.id == periodID;
    });
  }

  /**
   * @return {GanttCanvasShift[]} List of all selected canvas time periods.
   */
  getSelectedMarkers() {
    return this._selectedTimePeriods;
  }

  /**
   * @return {GanttCanvasShift[]} List of all selected canvas time periods.
   */
  addToSelectedTimePeriods(id) {
    this._selectedTimePeriods.push(this.getTimePeriodById(id));
  }

  /**
   * @return {boolean} Decides if plugin is active or not.
   */
  isActive() {
    return this._active;
  }

  /**
   * @return {boolean} Decides if plugin is in edit mode.
   */
  isInEditMode() {
    return this._activeEditMode;
  }

  /**
   * Returns the current render state.
   * @returns {boolean} Decides if plugin will be rendered or not.
   */
  public isRendered(): boolean {
    return this._periodMarker.isRendered();
  }

  /**
   * Sets the render state.
   * @param {boolean} render New render state.
   */
  public setRender(render: boolean) {
    this._periodMarker.setRender(render);
  }

  /**
   * Generates unique time period marker id.
   * @return {string} Marker id.
   */
  private _getUniqueMarkerId() {
    this._markerIdCounter++;
    return 'gantt-time-period-marker-item_' + this.UUID + '_' + this._markerIdCounter;
  }

  /**
   * Sets color of time periods.
   * @param {string} colorString Color value.
   */
  setColor(colorString) {
    this.ganttDiagram
      .getHistory()
      .addNewEvent('changeTPColor', new GanttTimePeriodEvent(), this, this._color /* , colorString*/);
    this._color = colorString;
    this._selectionBoxRules.color = colorString;
  }

  /**
   * Sets type of time intervals. Can be helpfull for backend information transfer.
   * @param {string} type Type of intervals.
   */
  setType(type) {
    this._type = type;
  }

  /**
   * Returns type of time intervals. Can be helpfull for backend information transfer.
   * @return {string} Type of intervals.
   */
  getType() {
    return this._type;
  }

  /**
   * Sets a flag, which decides whether this executer is used to visualize block overlaps.
   * @return {boolean} true if this is an overlap executer
   */
  setOverlapVisualization(isOverlapVisualization) {
    this._isForOverlapVisualization = isOverlapVisualization;
  }

  /**
   * Returns timeOut Collection.
   */
  getTimeOuts() {
    return this._timeOuts;
  }

  /**
   * return {Array.<String>} Array of the active xAxis Markers.
   */
  getXAxisDateMarker() {
    return this._xAxisDateMarkers;
  }

  /**
   * Sets stroke settings for time time periods.
   * @param {GanttTimePeriodStrokeHandlerDataItem} stroke Time period stroke setting.
   */
  setStroke(stroke) {
    this._stroke = stroke;
  }

  /**
   * Sets alternativ yAxisDataset.
   * Used by sticky parent row Plugin to position blocks correctly when rows position is manipulated.
   * @param {GanttShiftRow[]} yAxisDataset
   */
  setYAxisDataset(yAxisDataset) {
    this._yAxisDataset = yAxisDataset;
  }

  /**
   * Returns an array with data of the periods wich are in the desired row
   * @param {String} rowId ID of the row in which periods should be found.
   * @return {Array.<MarkedTimePeriod>} Found time Periods.
   */
  getAllTimePeriodsByRowId(rowId) {
    const tmpArray = [];
    for (let i = 0; i < this._markedTimePeriodData.length; i++) {
      if (this._markedTimePeriodData[i].yId == rowId) {
        tmpArray.push(this._markedTimePeriodData[i]);
      }
    }
    return tmpArray;
  }

  /**
   * @param {boolean} setDrawIntervalAfterSelectionBoxDraw
   */
  setDrawIntervalAfterSelectionBoxDraw(drawIntervalAfterSelectionBoxDraw) {
    this._drawIntervalAfterSelectionBoxDraw = drawIntervalAfterSelectionBoxDraw;
  }

  public setSplitId(splitId: string): void {
    this._splitId = splitId;
  }

  /**
   * Helper function for gantt dashboard handling.
   * This function returns the gantt dataset with marked time periods as shifts.
   * @param {GanttData} ganttOriginData Gantt data where the time period should be integrated.
   */
  getGanttOriginDataWithShiftIntervals(ganttOriginData) {
    if (!ganttOriginData)
      ganttOriginData = JSON.parse(JSON.stringify(this.ganttDiagram.getDataHandler().getOriginDataset()));

    for (let i = 0; i < this._markedTimePeriodData.length; i++) {
      const yRow = YAxisDataFinder.getRowById(ganttOriginData.ganttEntries, this._markedTimePeriodData[i].yId);

      if (!yRow || !yRow.data) continue;
      yRow.data.shifts.push({
        name: 'marker',
        id: 'marker_' + this.UUID,
        timePointStart: this._markedTimePeriodData[i].dateStart,
        timePointEnd: this._markedTimePeriodData[i].dateEnd,
        type: this._type,
      } as any);
    }

    return ganttOriginData;
  }

  //
  // PERMISSIONS
  //

  public allowShiftDragDrop(bool: boolean): void {
    for (const scrollContainerId of this.ganttDiagram.getRenderDataHandler().getAllScrollContainerIds()) {
      this._periodMarker.getShiftBuilder(scrollContainerId).setDragDrop(bool);
    }
  }

  public allowShiftResizer(bool: boolean): void {
    this._permissions.resizeShifts = bool;
  }

  public allowDraggingVertical(bool: boolean): void {
    this.getMarkerTranslator().shiftEditLimiterAdapter.allowDragVertical = bool;
  }

  public allowDraggingHorizontal(bool: boolean): void {
    this.getMarkerTranslator().shiftEditLimiterAdapter.allowDragHorizontal = bool;
  }

  setMixedEditModeAvailable(bool) {
    this._mixedEditModeAvailable = bool;
    this.toggleEditModeOnMouseOver(bool);
  }

  private _addTimePeriods(renderData: GanttShiftCanvasGeneratorRenderCallback): void {
    const visibleMarkerData = this._periodMarker.getVisibleMarkerData();
    const timePeriod = renderData.canvas.node().getContext('2d');
    timePeriod.translate(renderData.scale.x, 0);
    timePeriod.scale(renderData.scale.k, 1);
    timePeriod.setTransform(1, 0, 0, 1, 0, 0);

    for (let i = 0; i < visibleMarkerData.length; i++) {
      const timePeriodData = visibleMarkerData[i];
      if (typeof timePeriodData.y == 'undefined') continue; // noRendered row
      timePeriod.fillStyle = timePeriodData.color;
      timePeriod.fillRect(
        timePeriodData.x,
        timePeriodData.y - renderData.scrollTop,
        timePeriodData.width,
        timePeriodData.height
      );
    }
  }

  private _shiftZoomEnd(): void {
    for (const scrollContainerId of this.ganttDiagram.getRenderDataHandler().getAllScrollContainerIds()) {
      this._periodMarker.getShiftBuilder(scrollContainerId).scaleHorizontal({
        transform: this.ganttDiagram.getXAxisBuilder().getLastZoomEvent(),
      });
    }
    this._periodMarker.buildMarkers(this._isForOverlapVisualization);
  }

  private _onScroll(): void {
    this._periodMarker.buildMarkers(this._isForOverlapVisualization);
  }
}

/**
 * Data class which holds data for one time interval for internal execution.
 * @keywords time, period, blocking, interval, mark, data
 * @plugin timeperiod-marker
 */
export class MarkedTimePeriod {
  public id: string;
  public dateStart: Date = null;
  public dateEnd: Date = null;
  public yId: string = null;
  public y = 0;
  public height = 0;
  public customColor: string = null;
  public tooltip = '';
  public name = '';
  public sIds: string[] = [];
  public descriptionData: any = null;
  public pattern: any;
  public patternColor: string;
  public groupId: string;
  public type: string;
  public width: number;
  public x: number;
  public node: any;
  public additionalDetails: TimePeriodAdditionalDetails;
}

/**
 * Data of a selected timeperiod.
 */
export class SelectedTimePeriod {
  constructor(
    public id: string,
    public width: number,
    public x: number,
    public y: number,
    public type: string,
    public groupId: string
  ) {}
}

/**
 * Data class for GanttTimePeriodExecuter Undo / Redo Operations
 * @keywords undo, redo, time-period, interval, mark, data, period
 * @plugin timeperiod-marker
 */
export class GanttTimePeriodExecuterEventLog {
  public id: string = null;
  public newXStart: number = null;
  public newXEnd: number = null;
  public newY: number = null;
  public oldY: number = null;
  public oldX: number = null;
  public oldWidth = 0;
  public newRowId: string = null;
  public oldRowId: string = null;
  public oldDateStart: Date = null;
  public oldDateEnd: Date = null;
  public newDateStart: Date = null;
  public newDateEnd: Date = null;
}

export interface TimePeriodAdditionalDetails {
  [index: number]: {
    t1: boolean;
    t2: number | string | string[];
  };
}

export interface TimePeriodAddData {
  rowId: string;
  timeStart: Date;
  timeEnd: Date;
  intervalId: string;
  customColor: string;
  stroke?: GanttTimePeriodStrokeHandlerDataItem;
  additionalDetails?: TimePeriodAdditionalDetails;
  tooltip?: string;
  name?: string;
  pattern?: PatternType;
  patternColor?: string;
  sIds?: string[];
}

export enum TimePeriodNoRenderReason {
  HIDDEN_COORDINATES = 'HIDDEN_COORDINATES',
  FILTERED_OUT = 'FILTERED_OUT',
  ATTRIBUTE_SEARCH = 'ATTRIBUTE_SEARCH',
}
