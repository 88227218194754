import { Template } from 'frontend/src/dashboard/model/resource/template';
import { ETemplateType } from 'frontend/src/dashboard/model/resource/template-type';
import { HMI } from '../tree/tree-item';
import { PrintFormat } from './print-format';

export class Printer extends HMI {
  private printableElement: Template;
  private zoomFactor: number;
  private toggled: boolean;
  private title: string;
  private busy: boolean;
  private scaleFactor: number;
  private resolution: number;
  private livePreview: boolean;

  public getResolution(): number {
    return this.resolution;
  }

  public setResolution(resolution: number): this {
    this.resolution = resolution;
    return this;
  }

  public getScaleFactor(): number {
    return this.scaleFactor;
  }

  public setScaleFactor(scaleFactor: number): this {
    this.scaleFactor = scaleFactor;
    return this;
  }

  public isBusy(): boolean {
    return this.busy;
  }

  public setBusy(busy: boolean): this {
    this.busy = busy;
    return this;
  }

  public getTitle(): string {
    return this.title;
  }

  public setTitle(title: string): this {
    this.title = title;
    return this;
  }

  private selectedFormat: PrintFormat;

  public getSelectedFormat(): PrintFormat {
    return this.selectedFormat;
  }

  public setSelectedFormat(selectedFormat: PrintFormat): this {
    this.selectedFormat = selectedFormat;
    return this;
  }

  public isToggled(): boolean {
    return typeof this.toggled === 'boolean' ? this.toggled : false;
  }

  public setToggled(toggled: boolean): this {
    this.toggled = toggled;
    return this;
  }

  public hasLivePreview(): boolean {
    return this.livePreview;
  }

  public setLivePreview(livePreview: boolean) {
    this.livePreview = livePreview;
  }

  public getZoomFactor(): number {
    return this.zoomFactor;
  }

  public setZoomFactor(zoomFactor: number): this {
    this.zoomFactor = zoomFactor;
    return this;
  }

  private modifyZoom(): void {}

  constructor() {
    super();
    this.setType(ETemplateType.PRINTER).setZoomFactor(1).setToggled(true).setScaleFactor(1).setResolution(0);
  }

  public getPrintableElement(): Template {
    return this.printableElement;
  }

  public setPrintableElement(printableElement: Template): this {
    this.printableElement = printableElement;
    return this;
  }
}
