import { Type } from '@angular/core';
import { FileViewerComponent } from '@app-modeleditor/components/file-viewer/file-viewer.component';
import { BreadcrumbsComponent } from '../../shared/ui/breadcrumbs/breadcrumbs.component';
import { MenuComponent } from '../../shared/ui/menu/menu.component';
import { ToolbarComponent } from '../../shared/ui/toolbar/toolbar.component';
import { DataType } from './base/base-template';

export interface TemplateDefinition<T> {
  loadComponent: () => Promise<Type<T>>;
}

export type ReturnType<T> = T extends DataType.toolbar
  ? ToolbarComponent
  : T extends DataType.menu
  ? MenuComponent
  : T extends DataType.breadcrumbs
  ? BreadcrumbsComponent
  : T extends DataType.fileViewer
  ? FileViewerComponent
  : MenuComponent;

export const TEMPLATE_DEFINITIONS: Map<string, TemplateDefinition<ReturnType<DataType>>> = new Map([
  [
    DataType.toolbar,
    { loadComponent: () => import('../../shared/ui/toolbar/toolbar.component').then((m) => m.ToolbarComponent) },
  ],
  [DataType.menu, { loadComponent: () => import('../../shared/ui/menu/menu.component').then((m) => m.MenuComponent) }],
  [
    DataType.breadcrumbs,
    {
      loadComponent: () =>
        import('../../shared/ui/breadcrumbs/breadcrumbs.component').then((m) => m.BreadcrumbsComponent),
    },
  ],
  [
    DataType.fileViewer,
    {
      loadComponent: () =>
        import('../../modeleditor/components/file-viewer/file-viewer.component').then((m) => m.FileViewerComponent),
    },
  ],
]);
