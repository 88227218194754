import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NodeNetworkType } from '@app-nodenetwork/data/node-network-type.enum';
import { NodeNetworkImageService } from '@app-nodenetwork/service/node-network.image.service';
import { NodeNetworkService } from '@app-nodenetwork/service/node-network.service';
import { ExperimentService2, IExperiment } from 'frontend/src/dashboard/data/experiment/experiment.service';
import { SharedUiService } from 'frontend/src/dashboard/model-editor/ui/service/shared.ui.service';
import { ModelService } from 'frontend/src/dashboard/model/model.service';
import { BehaviorSubject, Observable, Observer, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { CustomHttpParamEncoder } from './custom-encorder';
import { ERequestMethod, RequestOptions, RequestService } from './request.service';

@Injectable({
  providedIn: 'root',
})
export class UiService {
  currentExperiment: IExperiment;
  private selectedTreeNode: BehaviorSubject<any>;
  private editQueue: Array<any> = [];
  private lockEdit = false;
  constructor(
    public http: HttpClient,
    public sharedModelService: ModelService,
    public experimentService2: ExperimentService2,
    public sharedUiService: SharedUiService,
    public nodeNetworkService: NodeNetworkService,
    public nodeNetworkImageService: NodeNetworkImageService,
    private requestApi: RequestService
  ) {
    this.selectedTreeNode = new BehaviorSubject(null);
    this.experimentService2
      .getCurrentExperiment()
      .subscribe((experiment: IExperiment) => (this.currentExperiment = experiment));
  }

  /**
   * get currently selected tree node
   */
  public getSelectedTreeNode(): Observable<any> {
    return this.selectedTreeNode.asObservable();
  }

  /**
   * get currently selected tree node
   */
  public setTreeNode(node: any): void {
    this.selectedTreeNode.next(node);
  }

  getDataAdvanced(uri: string, options?: IHttpOptions): Observable<any> {
    uri = 'rest/' + uri;
    let params = new HttpParams();
    if (options) {
      if (options.offset) {
        params = params.append('offset', options.offset.toString());
      }
      if (options.limit) {
        params = params.append('limit', options.limit.toString());
      }
      if (options.filterQuery) {
        params = params.append('filter', options.filterQuery.toString());
      }
    }

    return this.http.get(`${uri}`, { params: params });
  }

  postDataAdvanced(uri: string, options?: IHttpOptions, body?: any): Observable<any> {
    uri = 'rest/' + uri;
    let params = new HttpParams();
    if (options) {
      if (options.offset) {
        params = params.append('offset', options.offset.toString());
      }
      if (options.limit) {
        params = params.append('limit', options.limit.toString());
      }
      if (options.filterQuery) {
        params = params.append('filter', options.filterQuery.toString());
      }
    }

    return this.http.post(`${uri}`, body, { params: params });
  }

  /**
   * gets data for all ui elements
   * @param uri url to call rest service
   */
  public getData(uri: string, _params?: any): Observable<any> {
    uri = 'rest/' + uri;
    let params: HttpParams = new HttpParams({
      encoder: new CustomHttpParamEncoder(),
    });
    if (_params) {
      for (const key of Object.keys(_params)) {
        params = params.set(key, _params[key]);
      }
    }

    return this.http.get(`${uri}`, { params }).pipe(
      map((result: any) => {
        return result;
      })
    );
  }

  /**
   * post data for all ui elements
   * @param uri url to call rest service
   * @param body body to call rest service
   * @param extra? extra query parameter
   */
  public postData(uri: string, body: any, extra?: string): Observable<any> {
    uri = 'rest/' + uri;
    let fExtra = '';
    if (extra) {
      // Existing query param
      if (uri.indexOf('?') >= 0) {
        fExtra += '&' + encodeURI(extra);
        // No query param
      } else {
        fExtra += '?' + encodeURI(extra);
      }
    }

    return this.requestApi.call(ERequestMethod.POST, `${uri}${fExtra}`, new RequestOptions().setHttpOptions({ body }));
  }

  /**
   * delete data for all ui elements
   * @param uri url to call rest service
   */
  public deleteData(uri: string, extra?: string): Observable<any> {
    uri = 'rest/' + uri;
    let fExtra = '';
    if (extra) {
      // Existing query param
      if (uri.indexOf('?') >= 0) {
        fExtra += '&' + encodeURI(extra);
        // No query param
      } else {
        fExtra += '?' + encodeURI(extra);
      }
    }
    return this.http.delete(`${uri}${fExtra}`);
  }

  /**
   * puts data for all ui elements
   * @param uri url to call rest service
   * @param body body
   */
  public putData(uri: string, body: any, extra?: string): Observable<any> {
    uri = 'rest/' + uri;

    let fExtra = '';
    if (extra) {
      // Existing query param
      if (uri.indexOf('?') >= 0) {
        fExtra += '&' + encodeURI(extra);
        // No query param
      } else {
        fExtra += '?' + encodeURI(extra);
      }
    }

    return this.http.put(`${uri}${fExtra}`, body);
  }

  /**
   * edits data for all ui elements
   * @param uri url to call rest service
   * @param body edited object
   */
  private enqueueEdit(): void {
    if (!this.lockEdit && this.editQueue.length > 0) {
      this.lockEdit = true;
      const el = this.editQueue.pop();
      this.editQueueData(el.uri, el.body).subscribe(
        (data) => {
          el.observer.next(data);
          this.lockEdit = false;
          this.enqueueEdit();
        },
        (err) => {
          this.lockEdit = false;
          el.observer.error(err);
        }
      );
    }
  }

  private editQueueData(uri: string, body: any): Observable<any> {
    return this.http.post(`${uri}`, body).pipe(
      switchMap((data) => {
        return this.changeState().pipe(
          map((val) => {
            return data;
          })
        );
      })
    );
  }

  public editData(uri: string, body: any): Observable<any> {
    uri = 'rest/' + uri;
    return new Observable<any>((observer: Observer<any>) => {
      this.editQueue.push({ uri: uri, body: body, observer: observer });
      this.enqueueEdit();
    });
  }

  public editTableRow(editUrl: string, value: any): Observable<any> {
    const uri = 'rest/' + editUrl + value;

    return this.http.get(`${uri}`);
  }

  /**
   * change modified flag of model
   */
  public changeState(): Observable<any> {
    const model = this.sharedModelService.model;
    if (!model) {
      return null;
    }

    const uri = `rest/model/${model.id}/changeToModified`;

    return this.http.get(`${uri}`);
  }

  /**
   * get widgets for reports
   */
  public getWidgetsByExperiments(): Observable<any> {
    const m = this.currentExperiment;
    if (!this.currentExperiment) {
      return null;
    }
    return this.experimentService2.getExperimentResult(this.currentExperiment.id).pipe(
      map((resultReport: any) => {
        const items: Array<any> = [];
        for (const widget of resultReport.widgets) {
          widget.type = 'widget';
          widget.uuid = widget.id;
          widget.draggable = true;
          widget.icon = 'FLIP_TO_FRONT';
          widget.handlingType = 'WIDGET';
          items.push(widget);
        }
        return items;
      })
    );
  }

  /**
   * get steering widgets for reports
   */
  public getWidgetsBySteering(): Observable<any> {
    return of(null);
  }

  /**
   * get data for node networks
   */
  public getNodeNetworkViews(networkType: NodeNetworkType): Observable<any> {
    const model: string = this.sharedModelService.model.id;

    return this.nodeNetworkService.getAllModelViews(model, networkType).pipe(
      map((networkList) => {
        const items: Array<{
          uuid: string;
          type: string;
          value: number;
          id: string;
          name: string;
        }> = [];
        for (const networkItem of networkList) {
          let type = 'PROCESS_VIEW';

          if (networkType !== 1) {
            type = 'MACHINE_VIEW';
          }

          items.push({
            uuid: networkItem.id + '_' + type,
            type: type,
            value: 1,
            id: networkItem.id,
            name: networkItem.name,
          });
        }
        return items;
      })
    );
  }

  /**
   * get views for reports
   */
  public getViews(): Observable<any> {
    const m = this.currentExperiment;
    if (!this.currentExperiment) {
      return of(null);
    }
    return this.experimentService2.getExperimentResult(this.currentExperiment.id).pipe(
      map((resultReport: any) => {
        const items: Array<any> = [];

        for (const tab of resultReport.resultReport.tabs) {
          tab.type = 'tab';
          tab.uuid = tab.id;
          tab.icon = 'DASHBOARD';
          tab.resultReport = resultReport;
          tab.favourite = tab.isFavourite;
          items.push(tab);
        }

        return items;
      })
    );
  }

  /**
   * steering views
   */
  public getSteeringViews(): Observable<any> {
    return of(null);
  }

  public putFavourite(id: string, favourite: boolean): Observable<any> {
    return this.http.get(`` + 'rest/resultreport/tab/' + id + '?' + 'isFavorite=' + favourite);
  }

  /**
   * listen to view changes
   */
  public listenToViews(): Observable<any> {
    return new Observable<any>((observer: Observer<any>) => {
      this.sharedUiService._onWidgetChange.subscribe(() => {
        this.getViews().subscribe((result) => {
          observer.next(result);
        });
      });
      this.getViews().subscribe((result) => {
        observer.next(result);
      });
    });
  }

  /**
   * listen to steering view changes
   */
  public listenToSteeringViews(): Observable<any> {
    return new Observable<any>((observer: Observer<any>) => {
      this.sharedUiService._onWidgetChange.subscribe(() => {
        this.getSteeringViews().subscribe((result) => {
          observer.next(result);
        });
      });
      this.getSteeringViews().subscribe((result) => {
        observer.next(result);
      });
    });
  }

  /**
   * listen on widget changes
   */
  public listenToWidgets(): Observable<any> {
    return new Observable<any>((observer: Observer<any>) => {
      this.sharedUiService._onWidgetChange.subscribe(() => {
        this.getWidgetsByExperiments().subscribe((result) => {
          observer.next(result);
        });
      });
      this.getWidgetsByExperiments().subscribe((result) => {
        observer.next(result);
      });
    });
  }

  /**
   * listen on widget changes
   */
  public listenToSteeringWidgets(): Observable<any> {
    return new Observable<any>((observer: Observer<any>) => {
      this.sharedUiService._onWidgetChange.subscribe(() => {
        this.getWidgetsBySteering().subscribe((result) => {
          observer.next(result);
        });
      });
      this.getWidgetsBySteering().subscribe((result) => {
        observer.next(result);
      });
    });
  }

  /**
   * listen on widget changes
   */
  public listenToNodeNetworkViews(networkType: NodeNetworkType): Observable<any> {
    return new Observable<any>((observer: Observer<any>) => {
      // this.sharedUiService._onWidgetChange.subscribe(result => {
      //     this.getWidgets().subscribe(result => { observer.next(result); });
      // });
      this.getNodeNetworkViews(networkType).subscribe((result) => {
        observer.next(result);
      });
    });
  }

  public getFreeTextSearch(url: string): Observable<any> {
    return this.http.get(`${url}`);
  }

  /**
   * GET to url
   * @param restUrl api url
   * @param offset offset
   * @param limit amount
   */
  public getFromUrl(restUrl: string, offset?: number, limit?: number, filter?: string): Observable<any> {
    let url = `rest/${restUrl}`;
    url = this.addQueryToUrl(url, 'offset', offset ? offset : 0);
    url = this.addQueryToUrl(url, 'limit', limit ? limit : 10);
    url = this.addQueryToUrl(url, 'value', filter ? encodeURIComponent(filter) : undefined);
    return this.http.get(url);
  }

  /**
   * get list size
   * @param restUrl api url
   */
  public listSize(restUrl: string, filter?: string): Observable<any> {
    let url = `rest/${restUrl}/listsize`;
    url = this.addQueryToUrl(url, 'value', filter ? encodeURIComponent(filter) : undefined);
    return this.http.get(url);
  }

  /**
   * get list size from filter
   * @param restUrl api url
   * @param filters filters
   */
  public listSizeFromFilter(restUrl: string, filters: Array<any>): Observable<any> {
    const url = `rest/${restUrl}/listsize`;
    return this.http.post(url, filters);
  }

  /**
   * POST to url
   * @param restUrl api url
   * @param body body
   */
  public postToUrl(restUrl: string, body: any, offset?: number, limit?: number, filter?: string) {
    let url = `rest/${restUrl}`;
    url = this.addQueryToUrl(url, 'offset', offset ? offset : 0);
    url = this.addQueryToUrl(url, 'limit', limit ? limit : 10);
    url = this.addQueryToUrl(url, 'value', filter);
    return this.http.post(url, body);
  }

  /**
   * Appends queryparam to url
   * @param restUrl url
   * @param name name of queryparam
   * @param value value of queryparam
   * addQueryToUrl("saxms.de/test","hallo","welt")
   * returns
   * saxms.de/test?hallo=welt
   */
  private addQueryToUrl(restUrl: string, name: string, value: any): string {
    if (name !== undefined && name !== '' && value !== undefined && value !== '') {
      const separator = restUrl.includes('?') ? '&' : '?';
      return restUrl + separator + name + '=' + value;
    } else {
      return restUrl;
    }
  }

  /**
   * get experimentsettings for wizard
   */
  public getExperimentsettingsWizard(): Observable<any> {
    return this.http.get(`rest/template/experimentsettings/wizard`);
  }
  public getSolversettingsWizard(): Observable<any> {
    return this.http.get(`rest/template/settings/wizard/template.solversettings`);
  }
  public getSolversettingsWizardtest(): Observable<any> {
    return this.http.get(`rest/template/settings/wizard/solversettings`);
  }

  public getModelsettingsWizard(url: string): Observable<any> {
    return this.http.get(`rest/${url}`);
  }

  public getWizardPageContent(contentUrl): Observable<any> {
    return this.http.get(`rest/${contentUrl}`);
  }

  public action(actionUrl): Observable<any> {
    return this.http.get(`rest/${actionUrl}`);
  }

  public actionUpload(actionUrl, filename: string, file: any): Observable<any> {
    return this.http.post(`rest/${actionUrl}`, file, { responseType: 'blob' });
  }
}

export interface IHttpOptions {
  offset?: number;
  limit?: number;
  filterQuery?: string;
  filterOrder?: 'asc' | 'desc';
}
