<mat-form-field
  [formGroup]="formGroup"
  *ngIf="entryElement?.getValue()"
  [ngClass]="hintText ? 'has-hint' : ''"
  [appearance]="hintText ? 'outline' : appearance"
  class=""
  [hideRequiredMarker]="hideRequiredMarker"
>
  <app-conditions-menu
    matPrefix
    *ngIf="entryElement?.getAvailableFilterConditions().length > 0"
    [entryElement]="entryElement"
  ></app-conditions-menu>
  <mat-label *ngIf="appearance === 'outline' || hintText">{{ entryElement.getName() }}</mat-label>

  <input
    [type]="'text'"
    [id]="entryElement.getId()"
    #ref
    class="number inner-input"
    name="default-numeric-entry-element"
    matInput
    (input)="onInput($event.target.value)"
    (focusout)="onSynchronize()"
    (wheel)="onWheel($event)"
    (focusin)="onFocused()"
    [saxmsTooltip]="value"
    autocomplete="off"
    formControlName="inputControl"
    [placeholder]="
      (hideRequiredMarker && entryElement.isRequired() ? '* ' : '') + (entryElement.getName() || '' | translate)
    "
    [style.color]="textColor"
    [required]="entryElement.isRequired()"
    (keyup)="onKeyUp($event)"
    (keydown)="onKeyDown($event)"
  />

  <!-- unit -->
  <span matSuffix class="unit suffix" *ngIf="entryElement.getUnit()">{{ entryElement.getUnit() }}</span>
  <mat-error *ngIf="control?.errors">
    <ng-container *ngIf="control.errors.max; else error2">
      {{ 'SECURITY.ERRORS.max' | translate : { value: control.errors.max } }}
    </ng-container>
    <ng-template #error2>
      <ng-container *ngIf="control.errors.min; else error3">
        {{ 'SECURITY.ERRORS.min' | translate : { value: control.errors.min } }}
      </ng-container>
    </ng-template>
    <ng-template #error3>
      <ng-container *ngIf="control.errors.step">
        {{ 'SECURITY.ERRORS.step' | translate : { value: control.errors.step } }}
      </ng-container>
    </ng-template>
  </mat-error>
  <!-- error -->
  <!-- <mat-icon *ngIf="textFieldControl.errors?.pattern" matSuffix [matMenuTriggerFor]="errorMenu" class="error-icon">
    info</mat-icon> -->
  <app-input-error class="suffix" matSuffix [entryElement]="entryElement" [control]="control"></app-input-error>
  <!-- <mat-icon *ngIf="control.errors?.pattern" matSuffix [matMenuTriggerFor]="errorMenu" class="error-icon">
    info</mat-icon> -->

  <!-- numeric -->
  <div
    matSuffix
    *ngIf="!isDisabled && entryElement?.isDisableArrows && entryElement?.isDisableArrows() === false"
    class="numeric-selectors"
  >
    <button (click)="onIncrementDecrement(true)" mat-icon-button class="up trigger" [disabled]="upDisabled">
      <app-image [label]="'keyboard_arrow_up'"></app-image>
    </button>
    <button (click)="onIncrementDecrement(false)" mat-icon-button class="down trigger" [disabled]="downDisabled">
      <app-image [label]="'keyboard_arrow_down'"></app-image>
    </button>
  </div>

  <!-- info menu -->
  <app-info-menu matPrefix [entryElement]="entryElement" [disabled]="isDisabled" [enabled]="!control.errors?.pattern">
  </app-info-menu>

  <!-- image -->
  <app-image
    [disabled]="isDisabled"
    *ngIf="entryElement.getIcon()"
    class="prefixed-icon"
    matSuffix
    [label]="entryElement.getIcon()"
  >
  </app-image>
</mat-form-field>
