import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IAvailableGantts } from 'frontend/src/dashboard/gantt/general/gantt-settings/gantt-settings-dialog/gantt-settings.interface';

interface Option {
  name: string;
  completed: boolean;
  value?: string;
  options?: Option[];
}

/**
 * Content of the dialog for transferring gantt settings to other gantts.
 */
@Component({
  selector: 'app-transfer-gantt-settings',
  templateUrl: './transfer-gantt-settings.component.html',
  styleUrls: ['./transfer-gantt-settings.component.scss'],
})
export class TransferGanttSettingsComponent implements OnInit {
  public availableGantts: IAvailableGantts = {};
  @Output() changeEvent: EventEmitter<string[]> = new EventEmitter();
  @Input() ownGanttID = '';

  public options: Option;
  public allComplete = false;
  public isLoading = true;
  private readonly rest: string = 'rest/template/gantt/availablegantts/';

  constructor(private translate: TranslateService, private http: HttpClient) {}

  ngOnInit(): void {
    this.getAvailableGantts();
  }

  updateAllComplete() {
    this.allComplete = this.options.options != null && this.options.options.every((t) => t.completed);
    this.emitChanges();
  }

  someComplete(): boolean {
    if (this.options.options == null) {
      return false;
    }
    return this.options.options.filter((t) => t.completed).length > 0 && !this.allComplete;
  }

  setAll(completed: boolean) {
    this.allComplete = completed;
    if (this.options.options == null) {
      return;
    }
    this.options.options.forEach((t) => (t.completed = completed));
    this.emitChanges();
  }

  /**
   * Fetches a map of available gantts from the backend.
   */
  private getAvailableGantts() {
    this.http.get<IAvailableGantts>(this.rest).subscribe((availableGantts) => {
      this.availableGantts = availableGantts;
      this.options = this.generateOptions();
      this.isLoading = false;
    });
  }

  /**
   * Generates an object from which the checkboxes are built.
   */
  private generateOptions(): Option {
    return {
      name: this.translate.instant('GANTT.SETTINGS.selectAll'),
      completed: false,
      options: Object.entries(this.availableGantts)
        .map((entry) => {
          return {
            name: entry[1],
            value: entry[0],
            completed: false,
          };
        })
        .filter((t) => t.value != this.ownGanttID),
    };
  }

  private emitChanges() {
    const result = this.options.options.filter((t) => t.completed).map((t) => t.value);
    this.changeEvent.emit(result);
  }
}
