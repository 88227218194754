import { Component, OnInit } from '@angular/core';
import { AppErrorsHandler } from '@core/error-handler.service';
import { TimeFormatterService } from 'frontend/src/dashboard/model-editor/ui/output-formatter/time.formatter';
import { ErrorService } from './error.service';
import { LogData, LogType } from './errorlog.data';

@Component({
  selector: 'errorlog',
  templateUrl: './errorlog.component.html',
  styleUrls: ['./errorlog.component.scss'],
  providers: [ErrorService],
})
export class ErrorlogComponent implements OnInit {
  constructor(
    public globalErrorHandler: AppErrorsHandler,
    public errorservice: ErrorService,
    private timeFormatterService: TimeFormatterService
  ) {}

  // Server Error Log
  public from: number;
  public to: number;
  public limit = 10;
  public offset = 0;
  public level: string = LogType[0];

  ngOnInit() {}

  // Dashboard

  public getDashboardErrorList(): Array<string> {
    return this.globalErrorHandler.getErrorStack().getDashboardStringList();
  }

  // Server

  public servererrorlist: LogData;

  public updateServerErrorList(): void {
    const queries = {
      from: 1412476511000,
      to: 1612649311000,
      limit: this.limit,
      offset: this.offset,
      filter: this.getFilter(),
    };

    this.errorservice.getBackendLog(queries).subscribe((data: any) => {
      this.servererrorlist = new LogData();
      this.servererrorlist.rowCount = data.rowCount;
      for (const entry of data.logEntries) {
        this.servererrorlist.logEntries.push(entry);
      }
    });
  }

  public getFilter(): string {
    let filter = '';
    // Level
    if (this.level && this.level != 'ALL') {
      filter += 'level=' + this.level;
    }

    return filter;
  }

  public getServerErrorList(): LogData {
    // First call
    if (this.servererrorlist == undefined) {
      this.servererrorlist = new LogData();
      this.updateServerErrorList();
    }

    return this.servererrorlist;
  }

  // Buttons

  public removeAllLogs(): void {
    this.globalErrorHandler.getErrorStack().empty();
  }

  public downloadLog(): void {
    this.errorservice.downloadLog();
  }

  // OnChange

  public serverPageChange(event) {
    this.limit = event.pageSize;
    this.offset = event.pageIndex * event.pageSize;

    this.updateServerErrorList();
  }

  public filterChange(event) {
    this.offset = 0;
    this.updateServerErrorList();
  }

  // Util

  public pprint(timestamp: number): string {
    const date = new Date(timestamp);
    return this.timeFormatterService.dateToDateTimeString(date);
  }

  public logtypes(): string[] {
    const names: string[] = [];
    for (const n in LogType) {
      if (typeof LogType[n] === 'number') names.push(n);
    }
    return names;
  }
}
