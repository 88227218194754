import { Injectable, Type } from '@angular/core';
import { DateUtils } from '@app-modeleditor/utils/date-utils';
import { TemplateAdapter } from '@app-modeleditor/utils/template-factory.service';
import { TemplateResourceAdapter } from '@app-modeleditor/utils/template-resource-factory.service';
import { ResourceAdapter } from 'frontend/src/dashboard/model/resource/resource-adapter.service';
import { ChartFactoryService } from '../elements/pie-chart/chart-factory.service';
import { TemplatePicker } from '../template-picker/template-picker';
import { TemplatePickerValue } from './../template-picker/template-picker-value';
import { EntryElement } from './entry-element';
import { EntryElementEntry } from './entry-element-enty';
import { EntryElementValue } from './entry-element-value';
import { EFieldType } from './field-type.enum';
import { TemplateValue } from './value';

@Injectable({
  providedIn: 'root',
})
export class EntryElementFactory {
  constructor(
    private resourceAdapter: ResourceAdapter,
    private templateResourceFactory: TemplateResourceAdapter,
    private chartFactory: ChartFactoryService
  ) {}

  public parseEntryElementEntry<T extends EntryElementEntry>(type: Type<T>, data: any): T {
    if (!data) {
      return null;
    }

    return this.parseTemplateValue<T>(type, data).setType(data.type).setUuid(data.uuid).setColor(data.color);
  }

  private parseTemplateValue<T extends TemplateValue>(type: Type<T>, data: any): T {
    if (!data) {
      return null;
    }
    return this.resourceAdapter
      .inherit<T>(type, data)
      .setValue(data.value && data.value.value ? this.parseTemplateValue(TemplateValue, data.value) : data.value)
      .setRequired(data.required)
      .setIndex(data.index);
  }

  public parseEntryValue<T extends EntryElementValue>(type: Type<T>, data: Record<string, any>): T {
    if (!data) {
      return null;
    }

    return this.parseTemplateValue<T>(type, data)
      .setType(data.type)
      .setUuid(data.uuid)
      .setColor(data.color)
      .setHint(data.hint)
      .setHintColor(data.hintColor)
      .setShow(data.show)
      .setAvailableValues(data.availableValues)
      .setStartValue(data.startValue)
      .setDescription(data.description)
      .setEndValue(data.endValue);
  }

  public parseTemplateEntry<T extends EntryElement>(item: T, data: any): EntryElementValue {
    let entry: EntryElementValue = this.resourceAdapter.inherit<EntryElementValue>(EntryElementValue, data);
    // if field type is provided set special
    if (item) {
      switch (item.getFieldType()) {
        case EFieldType.CALENDAR_MONTH_PICKER:
          entry = this.resourceAdapter
            .inherit<TemplatePickerValue>(TemplatePickerValue, data)
            .setYear(new Date(data.value).getFullYear())
            .setMonth(new Date(data.value).getMonth());
          break;
        case EFieldType.CALENDAR_YEAR_PICKER:
          entry = this.resourceAdapter
            .inherit<TemplatePickerValue>(TemplatePickerValue, data)
            .setYear(new Date(data.value).getFullYear());
        case EFieldType.CALENDAR_WEEK_PICKER:
          if (item instanceof TemplatePicker) {
            entry = this.resourceAdapter
              .inherit<TemplatePickerValue>(TemplatePickerValue, data)
              .setShowMillis(item.isUseMillisForCalendarWeek())
              .setWeek(
                data.value
                  ? item.isUseMillisForCalendarWeek()
                    ? DateUtils.getNumberOfWeek(new Date(data.value))
                    : data.value
                  : null
              );
          }
          break;
        case EFieldType.PIE_CHART:
          entry = this.chartFactory.inheritFrom<EntryElementValue>(EntryElementValue, this, data);
          return entry;
      }
    }

    entry
      .setLocalizedValues(data.localizedValues)
      .setAvailableValues(data.availableValues)
      .setType(data.type)
      .setUuid(data.uuid)
      .setShow(data.show)
      .setStepWidth(data.stepWidth)
      .setUpperBound(data.upperBound)
      .setLowerBound(data.lowerBound)
      .setStartValue(data.startValue)
      .setDescription(data.description)
      .setEndValue(data.endValue)
      .setHint(data.hint)
      .setColor(data.color)
      .setRequired(data.required);

    if (Array.isArray(data.value)) {
      entry.setValue(
        data.value.map((element) => (typeof element === 'object' ? this.parseTemplateEntry(item, element) : element))
      );
    } else if (typeof data.value === 'object') {
      entry.setValue(this.parseTemplateEntry(item, data.value));
    } else {
      entry.setValue(data.value);
    }

    return entry;
  }

  public inherit<T extends EntryElement>(type: Type<T>, data: any): T {
    if (!data) {
      return null;
    }

    const entry: T = this._applyProperties(this.templateResourceFactory.inherit<T>(type, data), data);
    return entry;
  }

  private _applyProperties<T extends EntryElement>(item: T, data: any, scope?: TemplateAdapter): T {
    item
      .setActionOperation(data.actionOperation)
      .setDatatype(data.datatype)
      .setFieldType(data.fieldType)
      .setPoweredByGoogle(data.poweredByGoogle)
      .setRequired(data.required)
      .setShow(data.show)
      .setUpdateElementIds(data.updateElementIds)
      .setFreeSelection(data.freeSelection)
      .setActionURLParameterSelectors(data.actionURLParameterSelectors)
      .setToolbarGroupIndexOrder(data.toolbarGroupIndexOrder)
      .setToolbarItemIndexOrder(data.toolbarItemIndexOrder)
      .setUseLocalTime(data.useLocalTime)
      .setValidationRegex(data.validationRegex)
      .setValidationRegexInfoText(data.validationRegexInfoText)
      .setEntry(this.parseEntryElementEntry<EntryElementEntry>(EntryElementEntry, data.entry))
      .setValue(this.parseEntryValue<EntryElementValue>(EntryElementValue, data.value))
      .setPlaceholder(data.placeholder)
      .setActionRestUrl(data.actionRestUrl)
      .setInfoText(data.infoText)
      .setNotEditableInfoText(data.notEditableInfoText)
      .setEditableInfoText(data.editableInfoText)
      .setWeb_url(data.web_url)
      .setUnit(data.unit)
      .setRows(data.rows)
      .setEnableSort(data.enableSort)
      .setSortOrder(data.sortOrder)
      .setOpenType(data.openType)
      .setSimple(data.simple)
      .setAutoFocused(data.autoFocused)
      .setIcon(data.icon)
      .setNullable(data.nullable)
      .setDurationPickerValues(data.durationPickerValues)
      .setAlign(data.align)
      // .setToolbar(data.toolbar)
      .setLowerBound(data.lowerBound)
      .setUpperBound(data.upperBound)
      .setPosition(data.position)
      .setStepWidth(data.stepWidth)
      .setShortId(data.shortId)
      .setPassword(data.password)
      .setTooltip(data.tooltip)
      .setValueRestUrl(data.valueRestUrl);

    return item;
  }

  public inheritFrom<T extends EntryElement>(scope: TemplateAdapter, type: Type<T>, data: any): T {
    if (!data) {
      return null;
    }

    const entry: T = this._applyProperties(this.templateResourceFactory.inheritFrom<T>(scope, type, data), data, scope);
    return entry;
  }
}
