import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { GanttActionWrapper } from '../../external-action-registration';
import { GanttEventTrigger } from '../../external-event-trigger';
import { EGanttActionEvent } from '../gantt-action-event.enum';

/**
 * Event which will be fired on zoom end.
 */
export class GanttZoomEndEvent extends GanttEventTrigger {
  public readonly eventName: EGanttActionEvent = EGanttActionEvent.ZOOM_END;
  private executeActionSubject = new Subject<any>();

  private readonly millisecondsBetweenRequests: number = 500;
  // saves previous zoom interval to check if it has been changed
  private lastDomain: GanttZoomEndEventDomain;

  /**
   * Registers addToZoomEndCallback-callback inside given gantt.
   * @param ganttDiagram BestGantt instance to insert callback.
   */
  public registerInGantt(): void {
    // init last domain
    const ganttDiagram = this._ganttLibService.bestGantt;
    this.saveDataToTemplate(ganttDiagram.getXAxisBuilder().getCurrentScale().domain());

    ganttDiagram.getXAxisBuilder().addToZoomEndCallback(this.action.id, (zoomEndParam) => {
      this.executeActionSubject.next(zoomEndParam);
    });

    this.executeActionSubject.pipe(debounceTime(500)).subscribe((_) => {
      this.handleAction();
    });
  }

  private handleAction() {
    const ganttDiagram = this._ganttLibService.bestGantt;
    const domain: any = ganttDiagram.getXAxisBuilder().getCurrentScale().domain();
    if (
      this.lastDomain != null &&
      this.lastDomain.startDate.getTime() == domain[0].getTime() &&
      this.lastDomain.endDate.getTime() == domain[1].getTime()
    )
      return;

    this.saveDataToTemplate(domain);
    this.setLastDomainByDomain(domain);
    const actionWrapper: GanttActionWrapper = {
      actionScope: this.action.actionScope,
      actions: this.action.actions,
    };
    this._ganttLibService.ngZone.run(() => {
      this.actionHandler.executeActions(actionWrapper).subscribe((data) => {
        this.responseHandler.handleUpdateNotification(data as any);
      });
    });
  }

  /**
   * Sets last zoom interval by given domain.
   * @param ganttDomain D3 time domain from gantt.
   */
  private setLastDomainByDomain(ganttDomain: any): void {
    this.lastDomain = {
      startDate: ganttDomain[0],
      endDate: ganttDomain[1],
    };
  }

  /**
   * Removes callback from gantt.
   * @param ganttDiagram BestGantt instance.
   */
  public removeFromGantt(): void {
    this.executeActionSubject.complete();
    super.removeFromGantt();
    this._ganttLibService.bestGantt.getXAxisBuilder().removeZoomEndCallback(this.action.id);
  }

  /**
   * Stores event data into template. Allowes access to currnt zoom level.
   * @param domain D3 time domain to extract start and end date.
   */
  public saveDataToTemplate(domain: any): void {
    // move origin and target
    // ? if (!this.templateData.zoom) this.templateData.zoom = {};

    // save zoom data to template
    this.templateData.setZoom({
      start: new Date(domain[0]).getTime(),
      end: new Date(domain[1]).getTime(),
    });
    /* ?
    this.templateData.zoom.start = new Date(domain[0]).getTime();
    this.templateData.zoom.end = new Date(domain[1]).getTime();
    */
  }
}

/**
 * Structure to describe information about zoom level.
 */
interface GanttZoomEndEventDomain {
  startDate: Date;
  endDate: Date;
}
