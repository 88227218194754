<!-- <button (click)="test()">test</button> -->
<ng-container *ngIf="this._layout">
  <div
    [class.fullscreen]="fullscreen"
    #gridContainer
    class="column-layout-wrapper"
    #mainSplitter
    useTransition="true"
    [style.grid-template-columns]="this.gridColumnWidth"
    (mouseup)="endDrag()"
    (mousemove)="onDrag($event)"
    (mouseleave)="endDrag()"
  >
    <!-- row-count = -->

    <ng-container *ngFor="let item of rows; let rowIndex = index">
      <!-- column-count || -->
      <ng-container *ngFor="let item of columns; let colIndex = index">
        <!-- resize-Handle -->
        <ng-container *ngIf="this._gridOptimizedLayout.isResizeable() && rowIndex == 0 && colIndex >= 1">
          <div
            class="resizeHandle"
            [ngStyle]="{ 'grid-column': colIndex * 2, 'grid-row': '1/' + (rows.length + 1) }"
            (mousedown)="startResizerDrag(colIndex)"
          ></div>
        </ng-container>

        <!-- opt 1 - element -->
        <ng-container
          *ngIf="layoutContentsIDs[rowIndex] && layoutContentsIDs[rowIndex][colIndex]; else placeHolderElement"
        >
          <div
            #rootDiv
            *ngIf="layoutContentsIDs[rowIndex][colIndex] !== '#noPlaceHolder'"
            class="grid-row"
            [ngStyle]="{
              'grid-row':
                rowIndex +
                1 +
                '/' +
                (rowIndex + 1 + this._gridOptimizedLayout.getColumnHeightAtIndex(colIndex, rowIndex)),
              overflow: 'hidden'
            }"
          >
            <ng-container
              *ngTemplateOutlet="
                contentElement;
                context: {
                  $implicit: getT(colIndex, rowIndex),
                  numElements: this._layout.getContent()[colIndex]?.length || 0,
                  root: rootDiv
                }
              "
            >
            </ng-container>
          </div>
        </ng-container>
        <!-- opt 2 - placeholder -->
        <ng-template #placeHolderElement>
          <div class="gridPlaceHolder" [ngStyle]="{ 'grid-row': rowIndex + 1 + '/' + (rowIndex + 2) }"></div>
        </ng-template>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
