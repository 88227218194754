import { Resource } from 'frontend/src/dashboard/model/resource/resource';
import { ELinkType } from './link-type.enum';

export abstract class ALink extends Resource {
  private type: ELinkType;
  private startId: string;
  private endId: string;

  public getStartId(): string {
    return this.startId;
  }

  public setStartId(startId: string): this {
    this.startId = startId;
    return this;
  }

  public getEndId(): string {
    return this.endId;
  }

  public setEndId(endId: string): this {
    this.endId = endId;
    return this;
  }

  public getType(): ELinkType {
    return this.type;
  }

  public setType(type: ELinkType): void {
    this.type = type;
  }
}
