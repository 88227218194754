import { Observable, Subject, filter } from 'rxjs';
import { GanttConfig } from '../../config/gantt-config';
import { DataHandler } from '../../data-handler/data-handler';
import { GanttUtilities } from '../../gantt-utilities/gantt-utilities';
import { GanttHTMLStructureBuilder } from '../../html-structure/html-structure-builder';
import { NodeProportionsState } from '../../html-structure/node-proportion-state/node-proportion-state';
import { BestGantt } from '../../main';
import { RenderDataHandler } from '../../render-data-handler/render-data-handler';
import { GanttShiftBuilderFacade } from '../../shifts/shiftbuilder-facade';
import { TimeGradationHandler } from '../../time-gradation-handler/time-gradation-handler';
import { VerticalScrollHandler } from '../../vertical-scroll/vertical-scroll-handler';
import { GanttXAxis } from '../../x-axis/x-axis';
import { GanttShiftTranslationChain } from '../shift-translation/shift-translation-chain';
import { EGanttShiftEditEventType } from './edit-events/shift-edit-event-type.enum';
import { IGanttShiftEditEvent } from './edit-events/shift-edit-event.interface';
import { GanttShiftEditLimiterAdapter } from './edit-restrictions/shift-edit-limiter-adapter';
import { GanttShiftEditRestrictionVisualizer } from './edit-restrictions/shift-edit-restriction-visualizer';
import { GanttShiftEditState } from './edit-state/shift-edit-state';

/**
 * Base class for all shift editing classes.
 * @template InputEvent Type of event data the shift editor is able to handle.
 * @template OutputEvent Type of event data the shift editor emits.
 * @template StateModel Type of state model the state manager should use.
 * @template RestrictionHandler Type of restriction handler the shift editor should use.
 * @template RestrictionVisualizer Type of restriction visualizer the shift editor should use.
 */
export abstract class GanttShiftEditor<
  InputEvent extends IGanttShiftEditEvent,
  OutputEvent extends IGanttShiftEditEvent = InputEvent,
  StateModel extends object = object,
  RestrictionHandler extends GanttShiftEditLimiterAdapter = GanttShiftEditLimiterAdapter,
  RestrictionVisualizer extends GanttShiftEditRestrictionVisualizer = GanttShiftEditRestrictionVisualizer
> {
  public readonly noRenderId = GanttUtilities.generateUniqueID();
  protected _ganttDiagram: BestGantt;
  protected _shiftEditState: GanttShiftEditState<StateModel>;
  protected _shiftEditLimiterAdapter: RestrictionHandler;
  protected _shiftEditRestrictionVisualizer: RestrictionVisualizer;

  private _onDestroySubject = new Subject<void>();

  protected _beforeShiftEditSubject = new Subject<OutputEvent>();
  protected _onShiftEditSubject = new Subject<OutputEvent>();
  protected _afterShiftEditSubject = new Subject<OutputEvent>();

  constructor(
    ganttDiagram: BestGantt,
    initialShiftEditState: StateModel,
    shiftEditLimiterAdapter: RestrictionHandler,
    shiftEditRestrictionVisualizer: RestrictionVisualizer
  ) {
    this._ganttDiagram = ganttDiagram;
    this._shiftEditState = new GanttShiftEditState<StateModel>(initialShiftEditState);
    this._shiftEditLimiterAdapter = shiftEditLimiterAdapter;
    this._shiftEditRestrictionVisualizer = shiftEditRestrictionVisualizer;
  }

  /**
   * Prepares the shift editor to edit shifts.
   */
  public abstract build(): void;

  /**
   * Cleans up the shift editor and deactivates it.
   */
  public destroy(): void {
    this._onDestroySubject.next();
    this._onDestroySubject.complete();
  }

  //
  // EDIT HANDLING METHODS
  //

  /**
   * Handles shift edit start events.
   * @param event Edit start event.
   */
  protected abstract _start(event: InputEvent): void;

  /**
   * Handles shift edit update events.
   * @param event Edit update event.
   */
  protected abstract _update(event: InputEvent): void;

  /**
   * Handles shift edit end events.
   * @param event Edit end event.
   */
  protected abstract _finish(event: InputEvent): void;

  //
  // GETTER & SETTER
  //

  /**
   * Returns the current shift edit state as object.
   */
  public get shiftEditState(): StateModel {
    const shiftEditState: Partial<StateModel> = {};
    for (const key of this._shiftEditState.keys()) {
      shiftEditState[key] = this._shiftEditState.get(key as keyof StateModel);
    }
    return shiftEditState as StateModel;
  }

  /**
   * Returns the shift edit restriction handler of this shift editor.
   */
  public get shiftEditLimiterAdapter(): RestrictionHandler {
    return this._shiftEditLimiterAdapter;
  }

  /**
   * Helper getter which returns the gantt config of the current gantt.
   */
  protected get _ganttConfig(): GanttConfig {
    return this._ganttDiagram.getConfig();
  }

  /**
   * Helper getter which returns the data handler of the current gantt.
   */
  protected get _dataHandler(): DataHandler {
    return this._ganttDiagram.getDataHandler();
  }

  /**
   * Helper getter which returns the render data handler of the current gantt.
   */
  protected get _renderDataHandler(): RenderDataHandler {
    return this._ganttDiagram.getRenderDataHandler();
  }

  /**
   * Helper getter which returns the HTML structure builder of the current gantt.
   */
  protected get _htmlStructureBuilder(): GanttHTMLStructureBuilder {
    return this._ganttDiagram.getHTMLStructureBuilder();
  }

  /**
   * Helper getter which returns the node proportions state of the current gantt.
   */
  protected get _nodeProportionsState(): NodeProportionsState {
    return this._ganttDiagram.getNodeProportionsState();
  }

  /**
   * Helper getter which returns the x axis builder of the current gantt.
   */
  protected get _xAxisBuilder(): GanttXAxis {
    return this._ganttDiagram.getXAxisBuilder();
  }

  /**
   * Helper getter which returns the global scale of the current gantt.
   */
  protected get _globalScale(): d3.ScaleTime<number, number> {
    return this._ganttDiagram.getXAxisBuilder().getGlobalScale();
  }

  /**
   * Helper getter which returns the current scale of the current gantt.
   */
  protected get _currentScale(): d3.ScaleTime<number, number> {
    return this._ganttDiagram.getXAxisBuilder().getCurrentScale();
  }

  /**
   * Helper getter which returns the zoom transform of the current gantt.
   */
  protected get _zoomTransform(): d3.ZoomTransform {
    return this._ganttDiagram.getXAxisBuilder().getLastZoomEvent();
  }

  /**
   * Helper getter which returns the shift builder facade of the current gantt.
   */
  protected get _shiftFacade(): GanttShiftBuilderFacade {
    return this._ganttDiagram.getShiftFacade();
  }

  /**
   * Helper getter which returns the shift canvas selection of the current gantt.
   */
  protected get _shiftCanvas(): d3.Selection<SVGSVGElement, undefined, d3.BaseType, undefined> {
    return this._ganttDiagram.getShiftFacade().getShiftBuilder().getCanvasInFrontShifts();
  }

  /**
   * Helper getter which returns the shift group overlay selection of the current gantt.
   */
  protected get _shiftGroupOverlay(): d3.Selection<SVGGElement, undefined, d3.BaseType, undefined> {
    return this._ganttDiagram.getShiftFacade().getShiftGroupOverlay();
  }

  /**
   * Helper getter which returns the vertical scroll handler of the current gantt.
   */
  protected get _verticalScrollHandler(): VerticalScrollHandler {
    return this._ganttDiagram.getVerticalScrollHandler();
  }

  /**
   * Helper getter which returns the time gradation handler of the current shift edit limiter.
   */
  protected get _timeGradationHandler(): TimeGradationHandler {
    return this._shiftEditLimiterAdapter.timeGradationHandler;
  }

  /**
   * Helper getter which returns the shift translation chain handler of the current gantt.
   */
  protected get _chainHandler(): GanttShiftTranslationChain {
    return this._ganttDiagram.getShiftTranslationChain();
  }

  //
  // OBSERVABLES
  //

  /**
   * Observable which gets triggered when the instance gets destroyed.
   */
  public get onDestroy(): Observable<void> {
    return this._onDestroySubject.asObservable();
  }

  /**
   * Observable which gets triggered by any Edit event (start, update, end).
   */
  public onShiftEdit(): Observable<OutputEvent> {
    return this._onShiftEditSubject.asObservable();
  }

  /**
   * Observable which gets triggered by a Edit start event.
   */
  public onShiftEditStart(): Observable<OutputEvent> {
    return this.onShiftEdit().pipe(filter((event) => event.type === EGanttShiftEditEventType.START));
  }

  /**
   * Observable which gets triggered by a Edit update event.
   */
  public onShiftEditUpdate(): Observable<OutputEvent> {
    return this.onShiftEdit().pipe(filter((event) => event.type === EGanttShiftEditEventType.UPDATE));
  }

  /**
   * Observable which gets triggered by a Edit end event.
   */
  public onShiftEditEnd(): Observable<OutputEvent> {
    return this.onShiftEdit().pipe(filter((event) => event.type === EGanttShiftEditEventType.END));
  }

  /**
   * Observable which gets triggered before the handling of any Edit event (start, update, end).
   */
  public beforeShiftEdit(): Observable<OutputEvent> {
    return this._beforeShiftEditSubject.asObservable();
  }

  /**
   * Observable which gets triggered before the handling of a Edit update event.
   */
  public beforeShiftEditUpdate(): Observable<OutputEvent> {
    return this.beforeShiftEdit().pipe(filter((event) => event.type === EGanttShiftEditEventType.UPDATE));
  }

  /**
   * Observable which gets triggered after the handling of any Edit event (start, update, end).
   */
  public afterShiftEdit(): Observable<OutputEvent> {
    return this._afterShiftEditSubject.asObservable();
  }

  /**
   * Observable which gets triggered after the handling of a Edit update event.
   */
  public afterShiftEditUpdate(): Observable<OutputEvent> {
    return this.afterShiftEdit().pipe(filter((event) => event.type === EGanttShiftEditEventType.UPDATE));
  }

  /**
   * Observable which gets triggered after the handling of a Edit end event.
   */
  public afterShiftEditEnd(): Observable<OutputEvent> {
    return this.afterShiftEdit().pipe(filter((event) => event.type === EGanttShiftEditEventType.END));
  }
}
