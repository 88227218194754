import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { EntryElementValue } from '@app-modeleditor/components/entry-collection/entry-element-value';
import { IOverlayData } from '@app-modeleditor/components/lightbox/overlay/overlay-data.interface';
import { OverlayService } from '@app-modeleditor/components/lightbox/overlay/overlay.service';
import { Colorpicker } from '../colorpicker';
import { ColorpickerPopupContainerComponent } from '../colorpicker-popup-container/colorpicker-popup-container.component';

@Component({
  selector: 'app-color-button',
  templateUrl: './color-button.component.html',
  styleUrls: ['./color-button.component.scss'],
})
export class ColorButtonComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() disabled = false;
  @Input() picker: Colorpicker;
  @Input() isSpreadsheetContext = false;
  @Output() colorChanged: EventEmitter<EntryElementValue> = new EventEmitter<EntryElementValue>();
  @ViewChild('btnRef', { read: ElementRef }) btnRef: ElementRef;
  color: string;

  constructor(private overlayApi: OverlayService) {}

  openPicker(): void {
    const ref: IOverlayData = this.overlayApi.create<ColorpickerPopupContainerComponent>(
      ColorpickerPopupContainerComponent,
      this.btnRef,
      this.picker
    );
    ref.componentRef.instance.afterClosed$.subscribe((d) => {
      const newValue = d?.data?.getValue();
      if (newValue.getValue() !== this.color) {
        // only emit if value changed
        this.color = newValue.getValue();
        this.colorChanged.emit(newValue);
      }
    });
  }

  ngOnInit(): void {
    if (this.picker) {
      this.color = this.picker.getValue<EntryElementValue>().getValue<string>();
    }
  }

  ngAfterViewInit(): void {
    if (this.picker && this.isSpreadsheetContext) {
      this.openPicker(); // open picker directly if in spreadsheet context
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.picker && this.picker) {
      this.color = this.picker.getValue<EntryElementValue>().getValue<string>();
    }
  }
}
