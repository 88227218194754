/**
 * Storage to save filtered connections.
 */
export class NodeNetworkEdgeFilterCache {
  public cache: any = {};

  /**
   * Adds connection ids to storage.
   * @param id Storage id.
   * @param connectionIds List of connections to filter.
   */
  public addById(id: string, connectionIds: string[]): void {
    this.cache[id] = connectionIds;
  }

  /**
   * Returns connection list by given storage id.
   * @param id Storage id.
   */
  public getById(id: string): string[] {
    return this.cache[id];
  }

  /**
   * Removes storage item by storage id.
   * @param id Storage id of dataset to remove.
   */
  public removeById(id: string): void {
    delete this.cache[id];
  }

  /**
   * Returns complete cache data.
   */
  public getCache(): any {
    return this.cache;
  }

  /**
   * Clears cache.
   */
  public emptyCache(): void {
    this.cache = {};
  }
}
