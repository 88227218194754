<span class="wrapper">
  <mat-form-field *ngIf="selectedValues.selectedYear" class="year">
    <input
      (change)="onYearChanged($event)"
      [placeholder]="'Jahr' | translate"
      matInput
      type="number"
      [min]="0"
      [(value)]="selectedValues.selectedYear"
    />
  </mat-form-field>
  <mat-form-field *ngIf="isShowMonth">
    <mat-label>{{ 'Monat' | translate }}</mat-label>
    <mat-select [(value)]="selectedValues.selectedMonth" (selectionChange)="onMonthChanged($event)">
      <mat-option *ngFor="let month of months" [value]="month">
        {{ ('DATES.monthNames' | translate)[month] }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <div class="week-wrapper" *ngIf="isShowWeeks">
    <div
      *ngFor="let week of weeks"
      [class.selected]="selectedValues.week.number === week.number"
      class="week"
      (click)="onWeekClicked(week)"
    >
      <span class="week-number">{{ week.number }}</span> {{ week.start | date : ('DATE.FORMATS.DATE' | translate) }} -
      {{ week.end | date : ('DATE.FORMATS.DATE' | translate) }}
    </div>
  </div>
</span>
