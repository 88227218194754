import { GanttConfig } from '../../config/gantt-config';
import { GanttCanvasRow } from '../../data-handler/data-structure/data-structure';
import { GanttRenderDataSetYAxis } from '../../render-data-handler/data-structure/render-data-structure';
import { GanttHTMLStructureBuilder } from '../html-structure-builder';
import { EGanttScrollContainer } from '../scroll-container.enum';

/**
 * Helper class which handles the resizing of gantt scroll containers.
 */
export class GanttScrollContainerSizeHandler {
  /**
   * @param _htmlStructureBuilder Reference to the {@link GanttHTMLStructureBuilder} to handle the scroll container sizes for.
   * @param _config Reference to the {@link GanttConfig} providing the scroll container config.
   */
  constructor(private _htmlStructureBuilder: GanttHTMLStructureBuilder, private _config: GanttConfig) {}

  /**
   * Updates the scroll container sizes by the given y axis render data.
   * @param yAxisRenderData Y axis render dataset to update the scroll container sizes for.
   */
  public updateByRenderData(yAxisRenderData: GanttRenderDataSetYAxis): void {
    // determine new ideal height of sticky rows container
    let newStickyRowsHeight = this._config.stickyRowsContainerOptimalHeightPx();
    if (newStickyRowsHeight <= 0) {
      newStickyRowsHeight = this._getCombinedRowHeight(yAxisRenderData[EGanttScrollContainer.STICKY_ROWS]);
    }

    // check height restrictions for sticky rows container
    newStickyRowsHeight = this._getRestrictedStickyRowsContainerHeight(newStickyRowsHeight);

    // apply new height to scroll containers
    this._updateStickyRowsScrollContainer(newStickyRowsHeight);
  }

  /**
   * Updates the height of the scroll container for sticky rows to the given value.
   * @param newContainerHeightPx New height of the scroll container for sticky rows in px.
   */
  private _updateStickyRowsScrollContainer(newContainerHeightPx: number): void {
    const scrollContainer = this._htmlStructureBuilder.getVerticalScrollContainer(EGanttScrollContainer.STICKY_ROWS);
    scrollContainer
      .style('height', `${newContainerHeightPx}px`)
      .style('border-bottom', newContainerHeightPx <= 0 ? '0px' : null);
  }

  /**
   * Calculates the combined height of all given rows.
   * @param rows Y axis canvas data of all rows to be included in the calculation.
   * @returns Combined height of all given rows.
   */
  private _getCombinedRowHeight(rows: GanttCanvasRow[]): number {
    if (!rows || rows.length <= 0) return 0;

    let combinedRowHeight = 0;
    for (const row of rows) {
      combinedRowHeight += row.height;
    }
    return combinedRowHeight;
  }

  /**
   * Checks all sticky row container height restrictions for the given height value and corrects it if necessary.
   * @param containerHeightPx Height value to be checked.
   * @returns Restriction-conforming height value for the sticky row container.
   */
  private _getRestrictedStickyRowsContainerHeight(containerHeightPx: number): number {
    const parentNodeHeight = this._htmlStructureBuilder.getVerticalScrollContainerWrapper().node().clientHeight;

    const minDivHeight = Math.max(this._config.stickyRowsContainerMinHeightPx(), 0);
    const maxDivHeightRelativePx = parentNodeHeight * this._config.stickyRowsContainerMaxHeight();
    const maxDivHeightPx = this._config.stickyRowsContainerMaxHeightPx();
    const maxDivHeight =
      (maxDivHeightPx > 0 && maxDivHeightRelativePx > maxDivHeightPx) ||
      this._config.stickyRowsAllowUnlimitedContainerHeight()
        ? maxDivHeightPx
        : maxDivHeightRelativePx;

    return Math.min(maxDivHeight, Math.max(minDivHeight, containerHeightPx));
  }
}
