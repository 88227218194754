import { ElementRef } from '@angular/core';
import { Action } from '@app-modeleditor/components/button/action/action';
import { EActionType } from '@app-modeleditor/components/button/action/action-type.enum';
import { Button } from '@app-modeleditor/components/button/button';
import { EButtonDisplayType } from '@app-modeleditor/components/button/button-display-type.enum';
import { ContentElement } from '@app-modeleditor/components/content/content-element/content-element';
import { EMenuMode } from '@app-modeleditor/components/content/content-element/menu-mode.enum';
import { ContextMenuGroup } from '@app-modeleditor/components/contextmenu/context-menu-group';
import { ContextMenuItem } from '@app-modeleditor/components/contextmenu/context-menu-item';
import { ContextMenu } from '@app-modeleditor/components/contextmenu/contextmenu';
import { EntryElement } from '@app-modeleditor/components/entry-collection/entry-element';
import { ETemplateType } from 'frontend/src/dashboard/model/resource/template-type';
import { of } from 'rxjs';
import { EFavoriteMenuNavigation } from './favorite-menu-navigation.enum';
import { MenuItem } from './menu-item';
import { EToolbarEvents } from './toolbar-events.enum';
import { ToolbarGroup } from './toolbar-group';
import { EToolbarItemType } from './toolbar-item-type';
import { IToolbarOptions } from './toolbar-options.interface';

export class Toolbar extends ContentElement {
  private mainMenu: boolean;
  private maxShortcuts: number;
  private menuItems: MenuItem[];
  private title: string;
  private shortcuts: string[];
  private logo: string;
  private activeMenuItem: MenuItem;
  private predefinedMenuItems: MenuItem[];
  private shortcutsEnabled: boolean;
  private clazz: string;
  private favoriteMenuEnabled: boolean;
  private favoriteMenu: MenuItem;
  private extended: boolean;
  private extendable: boolean;
  private extendAsOverlay = false;
  private titleColor: string;
  private hideCb: () => any;
  private referenceContainer: ElementRef;

  public getReferenceContainer(): ElementRef {
    return this.referenceContainer;
  }

  public setReferenceContainer(referenceContainer: ElementRef): this {
    this.referenceContainer = referenceContainer;
    return this;
  }

  public onHide(hideCb: () => any): this {
    this.hideCb = hideCb;
    return this;
  }

  public getTitleColor(): string {
    return this.titleColor;
  }

  public setTitleColor(titleColor: string): this {
    this.titleColor = titleColor;
    return this;
  }

  /**
   * set scroll position from left
   * @param scrollLeft number
   * @returns void
   */
  public setScrollLeft(scrollLeft: number): void {
    if (!this.getEventListener()[EToolbarEvents.SCROLLED]) {
      return;
    }
    Object.keys(this.getEventListener()[EToolbarEvents.SCROLLED] || {}).forEach((id: string) => {
      this.getEventListener()[EToolbarEvents.SCROLLED][id](
        new CustomEvent(EToolbarEvents.SCROLLED, { detail: { scrollLeft } })
      );
    });
  }

  public isExtendable(): boolean {
    return typeof this.extendable === 'boolean' ? this.extendable : true;
  }

  public setExtendable(extendable: boolean): this {
    this.extendable = extendable;
    return this;
  }

  public isExtended(): boolean {
    return this.extended;
  }

  public setExtended(extended: boolean): this {
    this.getMenuItems().forEach((m: MenuItem) => {
      m.getToolbarGroups().forEach((g: ToolbarGroup) => {
        g.setPreventNewLines(extended ? false : true);
      });
    });
    this.extended = extended;
    return this;
  }

  public getExtendAsOverlay(): boolean {
    return this.extendAsOverlay;
  }

  public setExtendAsOverlay(extendAsOverlay: boolean): void {
    this.extendAsOverlay = extendAsOverlay;
  }

  public isFavoriteMenuEnabled(): boolean {
    return this.favoriteMenuEnabled;
  }
  public setFavoriteMenuEnabled(favoriteMenuEnabled: boolean): this {
    this.favoriteMenuEnabled = favoriteMenuEnabled;
    return this;
  }

  public getFavoriteMenu(): MenuItem {
    return this.favoriteMenu;
  }

  public setFavoriteMenu(favoriteMenu: MenuItem): this {
    this.favoriteMenu = favoriteMenu;
    return this;
  }

  public getClass(): string {
    return this.clazz;
  }

  public setClass(clazz: string): this {
    this.clazz = clazz;
    return this;
  }

  public toggleActiveMenuItem(item: MenuItem): void {
    this.setActiveMenuItem(this.getActiveMenuItem() && this.getActiveMenuItem().getId() === item.getId() ? null : item);
  }

  public isShortcutsEnabled(): boolean {
    return typeof this.shortcutsEnabled === 'boolean' ? this.shortcutsEnabled : true;
  }

  /**
   * disables usage of shortcuts
   * @returns this
   */
  public disableShortcuts(shortcutsEnabled: boolean): this {
    this.shortcutsEnabled = shortcutsEnabled;
    return this;
  }

  constructor(options?: IToolbarOptions) {
    super();
    this.setType(ETemplateType.TOOLBAR).setMenuMode(EMenuMode.SHOW);
    this.triggerOptions(options);
  }

  private triggerOptions(options: IToolbarOptions): void {
    if (!options) {
      return;
    }

    if (options.showMenuToggleBtn) {
      const hideBtn = new Button()
        .setDisplayBackground(false)
        .setId('toolbar-visibility-toggle')
        .setDisplayType(EButtonDisplayType.ICON_ONLY)
        .chainActions(new Action().setActionType(EActionType.LOCAL).setCb(() => of(this.changeMenuMode(hideBtn))));

      this.predefinedMenuItems = this.getPredefinedMenuItems().concat(
        new MenuItem().setToolbarItemType(EToolbarItemType.NAVIGATOR).setNavigationElement(hideBtn)
      );
      this.setHideToggle();
    }
  }

  private setHideToggle(): void {
    const hideToggle: Button = this.getPredefinedMenuItems()
      .map((m: MenuItem) => m.getNavigationElement())
      .find((m: Button) => m.getId() === 'toolbar-visibility-toggle');

    if (hideToggle) {
      hideToggle
        .setName(this.menuMode === EMenuMode.HIDE ? 'show' : 'hide')
        .setIcon(this.menuMode === EMenuMode.HIDE ? 'keyboard_arrow_down' : 'keyboard_arrow_up');
    }
  }

  public getPredefinedMenuItems(): MenuItem[] {
    return this.predefinedMenuItems || [];
  }

  private changeMenuMode(btn: Button): void {
    if (this.menuMode === EMenuMode.SHOW) {
      btn.setIcon('keyboard_arrow_down');
      this.menuMode = EMenuMode.HIDE;
    } else {
      btn.setIcon('keyboard_arrow_up');
      this.menuMode = EMenuMode.SHOW;
    }

    Object.keys(this.getEventListener()[EToolbarEvents.MENU_MODE_CHANGED] || {}).forEach((id: string) => {
      const ev: CustomEvent = new CustomEvent(EToolbarEvents.MENU_MODE_CHANGED, {
        detail: { extended: this.menuMode },
      });
      this.getEventListener()[EToolbarEvents.MENU_MODE_CHANGED][id](ev);
    });
  }

  public getMenuMode(): EMenuMode {
    return this.menuMode || EMenuMode.SHOW;
  }

  public setMenuMode(menuMode: EMenuMode): this {
    this.menuMode = menuMode;
    this.setHideToggle();
    return this;
  }

  public toggleMenuMode(): EMenuMode {
    this.setMenuMode(this.getMenuMode() === EMenuMode.HIDE ? EMenuMode.SHOW : EMenuMode.HIDE);
    Object.keys(this.getEventListener()[EToolbarEvents.MENU_MODE_CHANGED] || {}).forEach((id: string) => {
      const ev: CustomEvent = new CustomEvent(EToolbarEvents.MENU_MODE_CHANGED, {
        detail: { extended: this.menuMode },
      });
      this.getEventListener()[EToolbarEvents.MENU_MODE_CHANGED][id](ev);
    });
    return this.getMenuMode();
  }

  /**
   * Updates the menu indicator for a given entry element ID.
   * @param state - The state of the menu indicator (true for active, false for inactive).
   * @param entryElementId - The ID of the entry element to update the menu indicator for.
   * @param indicatorId - The ID of the menu indicator to update.
   */
  public updateMenuIndicator(state: boolean, entryElementId: string, indicatorId: string): void {
    const toolbarItems = this.getMenuItems();
    for (const menuItem of toolbarItems) {
      for (const group of menuItem.getToolbarGroups()) {
        for (const entryElement of group.getEntryElements()) {
          if (entryElement.getId() === entryElementId) {
            if (state) {
              menuItem.registerIndicator(indicatorId);
            } else {
              menuItem.deregisterIndicator(indicatorId);
            }
          }
        }
      }
    }
  }

  public getTitle(): string {
    return this.title;
  }

  public setTitle(title: string): this {
    this.title = title;
    return this;
  }

  public setActiveMenuItem(item: MenuItem): this {
    this.activeMenuItem = item;

    if (!this.getEventListener()) {
      return;
    }
    Object.keys(this.getEventListener()[EToolbarEvents.ACTIVE_ITEM_CHANGED] || {}).forEach((id: string) => {
      this.getEventListener()[EToolbarEvents.ACTIVE_ITEM_CHANGED][id](
        new CustomEvent(EToolbarEvents.ACTIVE_ITEM_CHANGED, {
          detail: { item: this.activeMenuItem },
        })
      );
    });

    return this;
  }

  public getActiveMenuItem(): MenuItem {
    return this.activeMenuItem;
  }

  public getLogo(): string {
    return this.logo;
  }

  public setLogo(logo: string): this {
    this.logo = logo;
    return this;
  }

  public getShortcuts(): string[] {
    return this.shortcuts || [];
  }

  public setShortcuts(shortcuts: string[]): this {
    if (!Array.isArray(shortcuts)) {
      return this;
    }

    this.shortcuts = shortcuts;
    return this;
  }

  public addShortcuts(...shortcuts: string[]): this {
    this.shortcuts = this.getShortcuts().concat(shortcuts);
    return this;
  }

  public isMainMenu(): boolean {
    return this.mainMenu;
  }

  public setMainMenu(mainMenu: boolean): this {
    this.mainMenu = mainMenu;
    return this;
  }

  public getMaxShortcuts(): number {
    return this.maxShortcuts;
  }

  public setMaxShortcuts(maxShortcuts: number): this {
    this.maxShortcuts = maxShortcuts;
    return this;
  }

  public getMenuItems(): MenuItem[] {
    return this.menuItems || [];
  }

  public setMenuItems(menuItems: MenuItem[]): this {
    this.update('menuItems', menuItems);
    return this;
  }

  public addMenuItems(...menuItems: MenuItem[]): this {
    this.menuItems = this.getMenuItems().concat(menuItems);
    return this;
  }

  public createFavoriteMenu(): void {
    const favoriteMenu: MenuItem = new MenuItem()
      .setIndex(0)
      .defaultGroup(this)
      .setIcon('grade')
      .setName('favorites')
      .setDisplayType(EButtonDisplayType.ICON_ONLY)
      .setEmptyGroupText('SUBMENU.noFavorites');
    const favoriteGroup: ToolbarGroup = new ToolbarGroup().setId('favorite').setName('favorites');
    favoriteMenu.addToolbarGroups(favoriteGroup);
    this.setFavoriteMenu(favoriteMenu);
    this.menuItems = [favoriteMenu].concat(this.menuItems);
  }

  public addItemsFromSettingsToFavoriteMenu(favoriteItemIds: string[], saveCallback: any): void {
    this.getMenuItems().forEach((menuItem) => {
      menuItem.getToolbarGroups().forEach((group) => {
        group.getEntryElements().forEach((element) => {
          if (
            element &&
            !this.getFavoriteMenu().isEntryElementInside(element) &&
            favoriteItemIds.includes(element.getId())
          ) {
            this.getFavoriteMenu().getToolbarGroups()[0].addEntryElements(element);
          }
          if (element) {
            this.addFavoriteContextMenu(element, saveCallback);
          }
        });
      });
    });

    this.getFavoriteMenu()
      .getToolbarGroups()[0]
      .getEntryElements()
      .sort(
        (entryElement1, entryElement2) =>
          favoriteItemIds.indexOf(entryElement1.getId()) - favoriteItemIds.indexOf(entryElement2.getId())
      );
  }

  private addFavoriteContextMenu(e: EntryElement, saveCallback: any) {
    e.setAllowContextMenuWhenDisabled(true); // allow the context menu even if the element is disabled

    if (this.isFavoriteMenuEnabled() && e) {
      const entryElmentIsFavorite = this.getFavoriteMenu().isEntryElementInside(e);
      const icon = entryElmentIsFavorite ? 'delete' : 'add';
      const name = entryElmentIsFavorite ? 'SUBMENU.ACTION.removeFavorit' : 'SUBMENU.ACTION.addFavorit';
      const contextMenuGroup = new ContextMenuGroup()
        .setId('favorite_menu_navigation')
        .setName('Navigation')
        .setContextMenuItems(this.getMovingFavoriteButtons(e, saveCallback));
      const contextMenuBtn = new ContextMenuItem()
        .setDisplayBackground(false)
        .setDisplayType(EButtonDisplayType.ICON_AND_LABEL)
        .setName(name)
        .setIcon(icon)
        .chainActions(
          new Action().setActionType(EActionType.LOCAL).setCb(() => {
            if (this.getFavoriteMenu().isEntryElementInside(e)) {
              this.getFavoriteMenu().getToolbarGroups()[0].removeEntryElement(e);
              contextMenuBtn.setIcon('add').setName('SUBMENU.ACTION.addFavorit');
              saveCallback(this.getFavoriteMenu().getToolbarGroups()[0].getEntryElements());
              e.getContextmenu().setContextMenuGroup([]);
            } else {
              this.getFavoriteMenu().getToolbarGroups()[0].addEntryElements(e);
              contextMenuBtn.setIcon('delete').setName('SUBMENU.ACTION.removeFavorit');
              saveCallback(this.getFavoriteMenu().getToolbarGroups()[0].getEntryElements());
              e.getContextmenu().addContextMenuGroups(contextMenuGroup);
            }
            return of(null);
          })
        );

      e.setContextmenu(new ContextMenu().setContextMenuItems([contextMenuBtn]));
      if (entryElmentIsFavorite) {
        e.getContextmenu().addContextMenuGroups(contextMenuGroup);
      }
    }
  }

  /**
   * creates a contextmenugroup with the specific navigation buttons
   * @param entryElement entryelement for the navigation
   * @param saveCallback save function after navigation
   * @returns contextmenu-group for the navigation inside the favorite menu
   */
  private getMovingFavoriteButtons(entryElement: EntryElement, saveCallback: any) {
    return [
      new ContextMenuItem()
        .setDisplayBackground(false)
        .setDisplayType(EButtonDisplayType.ICON_AND_LABEL)
        .setName(`SUBMENU.ACTION.${EFavoriteMenuNavigation.FIRST_POSITION}`)
        .setIcon('first_page')
        .setEnableBy(
          () =>
            this.favoriteMenu.getToolbarGroups()[0].getEntryElements().indexOf(entryElement) !== 0 &&
            this.getActiveMenuItem().getId() === this.getFavoriteMenu().getId()
        )
        .chainActions(
          new Action().setActionType(EActionType.LOCAL).setCb(() => {
            this.navigateInFavoriteMenu(entryElement, EFavoriteMenuNavigation.FIRST_POSITION, saveCallback);
            return of(null);
          })
        ),
      new ContextMenuItem()
        .setDisplayBackground(false)
        .setDisplayType(EButtonDisplayType.ICON_AND_LABEL)
        .setName(`SUBMENU.ACTION.${EFavoriteMenuNavigation.LAST_POSITION}`)
        .setIcon('last_page')
        .setEnableBy(
          () =>
            this.favoriteMenu.getToolbarGroups()[0].getEntryElements().indexOf(entryElement) !==
              this.favoriteMenu.getToolbarGroups()[0].getEntryElements().length - 1 &&
            this.getActiveMenuItem().getId() === this.getFavoriteMenu().getId()
        )
        .chainActions(
          new Action().setActionType(EActionType.LOCAL).setCb(() => {
            this.navigateInFavoriteMenu(entryElement, EFavoriteMenuNavigation.LAST_POSITION, saveCallback);
            return of(null);
          })
        ),
      new ContextMenuItem()
        .setDisplayBackground(false)
        .setDisplayType(EButtonDisplayType.ICON_AND_LABEL)
        .setName(`SUBMENU.ACTION.${EFavoriteMenuNavigation.PREV_POSITION}`)
        .setIcon('keyboard_arrow_left')
        .setEnableBy(
          () =>
            this.favoriteMenu.getToolbarGroups()[0].getEntryElements().indexOf(entryElement) !== 0 &&
            this.getActiveMenuItem().getId() === this.getFavoriteMenu().getId()
        )
        .chainActions(
          new Action().setActionType(EActionType.LOCAL).setCb(() => {
            this.navigateInFavoriteMenu(entryElement, EFavoriteMenuNavigation.PREV_POSITION, saveCallback);
            return of(null);
          })
        ),
      new ContextMenuItem()
        .setDisplayBackground(false)
        .setDisplayType(EButtonDisplayType.ICON_AND_LABEL)
        .setName(`SUBMENU.ACTION.${EFavoriteMenuNavigation.NEXT_POSITION}`)
        .setIcon('keyboard_arrow_right')
        .setEnableBy(
          () =>
            this.favoriteMenu.getToolbarGroups()[0].getEntryElements().indexOf(entryElement) !==
              this.favoriteMenu.getToolbarGroups()[0].getEntryElements().length - 1 &&
            this.getActiveMenuItem().getId() === this.getFavoriteMenu().getId()
        )
        .chainActions(
          new Action().setActionType(EActionType.LOCAL).setCb(() => {
            this.navigateInFavoriteMenu(entryElement, EFavoriteMenuNavigation.NEXT_POSITION, saveCallback);
            return of(null);
          })
        ),
    ];
  }

  /**
   * handle the navigation of the entry elemets inside the favorite menu
   * @param entryElement element to move inside the favorite menu
   * @param mode type of the navigation
   * @param saveCallback save function after navigation
   */
  private navigateInFavoriteMenu(entryElement: EntryElement, mode: EFavoriteMenuNavigation, saveCallback: any) {
    const currentOrder = this.favoriteMenu.getToolbarGroups()[0].getEntryElements();
    const currentIndex = currentOrder.indexOf(entryElement);
    const newOrder: EntryElement[] = [];
    switch (mode) {
      case EFavoriteMenuNavigation.FIRST_POSITION:
        this.moveElementInArray(currentOrder, currentIndex, 0);
        break;
      case EFavoriteMenuNavigation.LAST_POSITION:
        this.moveElementInArray(currentOrder, currentIndex, currentOrder.length - 1);
        break;
      case EFavoriteMenuNavigation.NEXT_POSITION:
        this.moveElementInArray(currentOrder, currentIndex, currentIndex + 1);
        break;
      case EFavoriteMenuNavigation.PREV_POSITION:
        this.moveElementInArray(currentOrder, currentIndex, currentIndex - 1);
        break;
    }

    saveCallback(this.getFavoriteMenu().getToolbarGroups()[0].getEntryElements());
  }

  /**
   * reorder a given array by source-index and destination-index
   * @param array array for reorder
   * @param fromIndex  index of the source
   * @param toIndex index of the destination
   * @returns reorder array
   */
  private moveElementInArray(array: any[], fromIndex: number, toIndex: number) {
    array.splice(toIndex, 0, array.splice(fromIndex, 1)[0]);
    return array;
  }
}
