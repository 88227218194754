import { EGanttInstance } from '@gantt/public-api';
import { GanttLibService } from 'frontend/src/dashboard/gantt/gantt/gantt-lib.service';
import { GanttPluginHandlerService } from 'frontend/src/dashboard/gantt/general/plugin/gantt-plugin-handler.service';
import { IGanttResponse } from '../../../gantt-response';
import { GanttRowUpdateSuperblockHandler } from '../strategies/handler/superblocks';

/**
 * Handels Delete-Blocks part of the gantt response.
 */
export class GanttDeleteBlocksResponse {
  private updateSuperblockHandler: GanttRowUpdateSuperblockHandler;

  constructor(
    private _ganttPluginHandlerService: GanttPluginHandlerService,
    private _ganttLibService: GanttLibService
  ) {
    this.updateSuperblockHandler = new GanttRowUpdateSuperblockHandler(_ganttPluginHandlerService);
  }

  /**
   * Extracts delete-dataa from response data and removes shift blocks from gantt.
   * Pays attention to shift overlapping algorithm.
   * Returns false if response has not block deletion.
   * @param response Server response.
   */
  public handleResponse(response: IGanttResponse): boolean {
    if (!response.deletedBlocks) return false;
    const mapper = this._ganttLibService.backendToGanttOriginInputMapper;
    const ShiftDataFinder = this._ganttLibService.ganttInstanceService.getInstance(EGanttInstance.SHIFT_DATA_FINDER);

    const deletedBlockIds = response.deletedBlocks.map((deletedBlock) => deletedBlock.id);
    const deletedBlockCloneIds: string[] = [];
    for (let i = 0; i < deletedBlockIds.length; i++) {
      deletedBlockCloneIds.push(...mapper.getShiftClonesByShiftId(deletedBlockIds[i]));
    }

    // remove from hierarchical plan
    this._ganttPluginHandlerService.getTemplateData().removeBlocksByIdsFromHierarchicalPlan(deletedBlockCloneIds);

    // remove from js gantt
    ShiftDataFinder.removeShiftsById(
      this._ganttLibService.bestGantt.getDataHandler().getOriginDataset().ganttEntries,
      deletedBlockCloneIds
    );
    this.updateSuperblockHandler.removeFromSuperBlockPlugin(...response.deletedBlocks);

    return true;
  }
}
