import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TemplateImageComponent } from './template-image.component';

@NgModule({
  imports: [CommonModule],
  declarations: [TemplateImageComponent],
  exports: [TemplateImageComponent],
})
export class TemplateImageModule {}
