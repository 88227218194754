import { BestGanttPlugIn } from '../gantt-plug-in';
import { FilterShiftsByConditionOperator } from './filter-shifts-by-condition-operator';

export class FilterShiftsByCondition extends BestGanttPlugIn {
  operator: FilterShiftsByConditionOperator;
  lastFilterEvent: ILastFilterEvent;
  isFilterActive: boolean;
  attributeMapping: any;

  constructor() {
    super(); // call super-constructor
    /**
     * @type {BestGantt}
     */
    this.ganttDiagram = null;
    this.operator = new FilterShiftsByConditionOperator();
    this.lastFilterEvent = null;
    this.isFilterActive = false;
    this.attributeMapping = {};
  }

  /**
   * @override
   */
  initPlugIn(ganttDiagram) {
    const s = this;
    s.ganttDiagram = ganttDiagram;
    s.lastFilterEvent = new ILastFilterEvent(null, null, null, null);
    s.ganttDiagram.subscribeOriginDataUpdate('FilterShiftsByConditionUpdate' + s.UUID, s.updatePlug.bind(s));
  }

  /**
   * @override
   */
  updatePlug() {
    const s = this;

    if (s.isFilterActive && s.lastFilterEvent && s.lastFilterEvent.condition) {
      s.clearFilter();
      s.filterByCondition(
        s.lastFilterEvent.condition,
        s.lastFilterEvent.isCaseSensitive,
        s.lastFilterEvent.filteredOutElementDisplayOption,
        s.lastFilterEvent.isHideEmptyRows
      );
    }
  }

  /**
   * Remove of plugin. Part of plugin lifecycle.
   */
  removePlugIn() {
    const s = this;
    s.clearFilter();
    s.ganttDiagram.unSubscribeOriginDataUpdate('FilterShiftsByConditionUpdate' + s.UUID);
  }

  /**
   * Filters shifts by condition.
   */
  filterByCondition(condition, isCaseSensitive, filteredOutElementDisplayOption, isHideEmptyRows, ganttUpdate = false) {
    this.isFilterActive = true;
    this._saveLastFilterEvent(condition, isCaseSensitive, filteredOutElementDisplayOption, isHideEmptyRows);
    const dataHandler = this.ganttDiagram.getDataHandler();
    const ganttEntries = dataHandler.getOriginDataset().ganttEntries;
    const dataEditor = dataHandler.getDataEditor();

    this.operator._filterByCondition(
      ganttEntries,
      dataHandler,
      condition,
      !!isCaseSensitive,
      filteredOutElementDisplayOption
    );
    isHideEmptyRows
      ? dataEditor.setEmptyRowsToNoRender('filterPlugin')
      : dataEditor.removeNoRenderIdsOfDataEditor('filterPlugin');

    if (ganttUpdate) this.ganttDiagram.update();
  }

  /**
   * Set attribute mapping of additionalDetails shift property.
   */
  setAttributeMapping(attributeMapping) {
    this.operator._setAttributeMapping(attributeMapping);
  }

  /**
   * Saves the given filter detail in a last filter event object.
   * @param {*} condition
   * @param {*} isCaseSensitive
   * @param {*} filteredOutElementDisplayOption
   * @param {*} isHideEmptyRows
   */
  private _saveLastFilterEvent(condition, isCaseSensitive, filteredOutElementDisplayOption, isHideEmptyRows) {
    this.lastFilterEvent = new ILastFilterEvent(
      condition,
      isCaseSensitive,
      filteredOutElementDisplayOption,
      isHideEmptyRows
    );
  }

  /**
   * Clears current Filter.
   */
  clearFilter(ganttUpdate = false) {
    this.isFilterActive = false;
    const dataHandler = this.ganttDiagram.getDataHandler();
    const ganttEntries = dataHandler.getOriginDataset().ganttEntries;
    dataHandler.getDataEditor().removeNoRenderIdsOfDataEditor('filterPlugin');

    this.operator._clearFilter(ganttEntries, dataHandler);
    if (ganttUpdate) this.ganttDiagram.update();
  }

  public getNoRenderId(): string {
    return this.operator.UUID;
  }
}

export class ILastFilterEvent {
  condition: any;
  isCaseSensitive: boolean;
  filteredOutElementDisplayOption: any;
  isHideEmptyRows: boolean;

  constructor(condition, isCaseSensitive, filteredOutElementDisplayOption, isHideEmptyRows) {
    this.condition = condition;
    this.isCaseSensitive = isCaseSensitive;
    this.filteredOutElementDisplayOption = filteredOutElementDisplayOption;
    this.isHideEmptyRows = isHideEmptyRows;
  }
}
