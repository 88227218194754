export class IconConfig {
  private name: string;
  private namespace: string;
  private label: string;
  private path: string;

  public isCustom(): boolean {
    return this.getPath() || this.getNamespace() ? true : false;
  }

  public getName(): string {
    return this.name;
  }

  public setName(name: string): this {
    this.name = name;
    return this;
  }

  public getNamespace(): string {
    return this.namespace;
  }

  public setNamespace(namespace: string): this {
    this.namespace = namespace;
    return this;
  }

  public getValue(): string {
    return `${this.getNamespace() ? `${this.getNamespace()}:` : ''}${this.getLabel()}`;
  }

  public getLabel(): string {
    return this.label;
  }

  public setLabel(label: string): this {
    this.label = label;
    return this;
  }

  public getPath(): string {
    return this.path;
  }

  public setPath(path: string): this {
    this.path = path;
    return this;
  }
}
