import { Component, Inject, OnInit } from '@angular/core';
import { CONTAINER_DATA } from '@app-modeleditor/components/lightbox/overlay/container-data';
import { DefaultOverlayContainer } from '@app-modeleditor/components/lightbox/overlay/default-overlay-container';
import { IResourceTooltipData } from './resource-tooltip-data.interface';

@Component({
  selector: 'app-resource-tooltip',
  templateUrl: './resource-tooltip.component.html',
  styleUrls: ['./resource-tooltip.component.scss'],
})
export class ResourceTooltipComponent extends DefaultOverlayContainer<any> implements OnInit {
  public x = 0;
  public y = 0;

  constructor(@Inject(CONTAINER_DATA) public data: IResourceTooltipData) {
    super();
    this._calculateTooltipPosition();
  }

  ngOnInit(): void {}

  private _calculateTooltipPosition(): void {
    const tooltipWidth = 320; // max width
    if (this.data.event.pageX + tooltipWidth > window.innerWidth) {
      this.x = window.innerWidth - tooltipWidth;
    } else {
      this.x = this.data.event.pageX;
    }

    const tooltipHeight = this.data.resourceElement.attributes.filter((attr) => attr.showInTooltip).length * 23 + 100;
    if (this.data.event.pageY + tooltipHeight > window.innerHeight) {
      this.y = window.innerHeight - tooltipHeight;
    } else {
      this.y = this.data.event.pageY;
    }
  }
}
