import {
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ElementsComponent } from '@app-modeleditor/components/elements/elements.component';
import { TemplateService } from '@app-modeleditor/utils/template.service';
import { Template } from 'frontend/src/dashboard/model/resource/template';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { TemplateComponent } from '../template.component';

@Component({
  selector: 'template-slot',
  templateUrl: './slot.component.html',
  styleUrls: ['./slot.component.scss'],
})
export class SlotComponent implements OnChanges, OnDestroy {
  private _instance: ComponentRef<any>;
  @Output('afterInit') _afterInit: EventEmitter<ComponentRef<any>> = new EventEmitter<ComponentRef<any>>();
  @ViewChild('container', { read: ViewContainerRef }) _vc: ViewContainerRef;
  type: any;

  constructor(private _templateApi: TemplateService, private _resolver: ComponentFactoryResolver) {
    of(null)
      .pipe(delay(0))
      .subscribe(() => {
        this.init();
      });
  }

  @Input() source: Template;
  @Input() componentType: 'TemplateComponent' | 'ElementsComponent' = 'TemplateComponent';

  private init(): void {
    if (!this._vc) {
      return;
    }
    if (this._instance) {
      this._instance.destroy();
    }

    switch (this.componentType) {
      case 'TemplateComponent':
        this.type = TemplateComponent;
        break;
      case 'ElementsComponent':
        this.type = ElementsComponent;
        break;
    }

    this._instance = this._templateApi.loadComponent(this.type, this._vc, this._resolver);
    this._applySource();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.source && this._instance) {
      this._applySource();
    }
  }

  private _applySource(): void {
    this._instance.instance.templateNode = this.source;
    this._afterInit.emit(this._instance);
  }

  ngOnDestroy(): void {
    this._instance?.destroy();
    this._vc.clear();
  }
}
