import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { DragNDropModule } from '../drag-n-drop/drag-n-drop.module';
import { EntryElementModule } from '../entry-element/entry-element.module';
import { LayoutModule as OldLayoutModule } from '../layout/layout.module';
import { LayoutModule } from './../structure/layout.module';
import { EntryCollectionComponent } from './entry-collection.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    OldLayoutModule,
    MatIconModule,
    LayoutModule,
    DragNDropModule,
    EntryElementModule,
  ],
  declarations: [EntryCollectionComponent],
  exports: [EntryCollectionComponent],
})
export class EntryCollectionModule {}
