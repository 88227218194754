<h2>{{ '@dashboard@' | translate | capitalize }}</h2>
<span class="user-categories-error-log-count"
  >{{ getDashboardErrorList().length }} {{ '@messages@' | translate | capitalize }}</span
>
<div class="user-categories-error-log-entry" *ngIf="getDashboardErrorList().length <= 0; else dashboardErrorList">
  -
</div>
<ng-template #dashboardErrorList>
  <div class="user-categories-error-log-entry-wrapper">
    <div class="user-categories-error-log-entry" *ngFor="let error of getDashboardErrorList()">
      {{ error }}
    </div>
  </div>
</ng-template>
<h2>{{ '@server@' | translate | capitalize }}</h2>
<span class="user-categories-error-log-count"
  >{{ getServerErrorList().rowCount }} {{ '@messages@' | translate | capitalize }}</span
>
<div class="user-categories-error-log-entry" *ngIf="getServerErrorList().rowCount <= 0; else serverErrorList">-</div>
<ng-template #serverErrorList>
  <div class="user-categories-error-log-entry-wrapper">
    <div class="user-categories-error-log-entry" *ngFor="let error of getServerErrorList().logEntries">
      {{ pprint(error.timestamp) }} - {{ error.message }}
    </div>
  </div>
</ng-template>
<div class="user-categories-error-log-action">
  <button mat-icon-button [saxmsTooltip]="'USERSETTINGS.ERRORLOG.print' | translate">
    <mat-icon>print</mat-icon>
  </button>
  <button mat-icon-button [saxmsTooltip]="'USERSETTINGS.ERRORLOG.delete' | translate" (click)="removeAllLogs()">
    <mat-icon>delete</mat-icon>
  </button>
  <button mat-icon-button [saxmsTooltip]="'USERSETTINGS.ERRORLOG.download' | translate" (click)="downloadLog()">
    <mat-icon>file_download</mat-icon>
  </button>

  <mat-form-field>
    <mat-select placeholder="Level" [(ngModel)]="level" (change)="filterChange($event)">
      <mat-option *ngFor="let level of logtypes()" [value]="level">
        {{ level }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div>
    <mat-paginator
      [length]="getServerErrorList().rowCount"
      [pageSize]="limit"
      [pageSizeOptions]="[5, 10, 25, 100]"
      (page)="serverPageChange($event)"
    >
    </mat-paginator>
  </div>
</div>
