import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SlotComponent } from './slot.component';

@NgModule({
  imports: [CommonModule],
  declarations: [SlotComponent],
  exports: [SlotComponent],
})
export class SlotModule {}
