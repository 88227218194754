<ng-template #hintElement let-clazz>
  <mat-icon
    *ngIf="hint?.text"
    class="floating-hint"
    [ngClass]="clazz"
    [style.color]="hint?.color"
    [matMenuTriggerFor]="menu"
  >
    message
  </mat-icon>

  <mat-menu #menu="matMenu">
    <p class="hint-box" [style.color]="hint?.color">
      {{ hint?.text }}
    </p>
  </mat-menu>
</ng-template>

<mat-menu #errorMenu="matMenu" [class]="'custom-menu'">
  <p class="error-box">
    {{ formElement?.getValidationRegexInfoText() }}
  </p>
</mat-menu>

<mat-menu #infoMenu="matMenu" class="customize">
  <p class="info-box">
    {{ formElement?.getInfoText(isDisabled) }}
  </p>
</mat-menu>

<ng-container *ngIf="true">
  <div class="saxms-elements-wrapper" *ngIf="formElement">
    <ng-container *ngIf="formElement && show">
      <ng-container [ngSwitch]="formElement.getFieldType()">
        <!-- HEADLINE -->
        <ng-container *ngSwitchCase="'HEADLINE'">
          <template-headline [id]="formElement.getId()" [headline]="formElement" [stretched]="stretched">
          </template-headline>
        </ng-container>
        <!-- BUTTON -->
        <ng-container *ngSwitchCase="'BUTTON'">
          <app-button [id]="formElement.getId()" [icon]="formElement.getIcon()" [data]="elementData"></app-button>
        </ng-container>
        <!-- TOGGLE_BUTTON -->
        <ng-container *ngSwitchCase="'TOGGLE_BUTTON'">
          <app-button [id]="formElement.getId()" [icon]="formElement.getIcon()" [data]="elementData"></app-button>
        </ng-container>
        <!-- TEXT_FIELD -->
        <ng-container *ngSwitchCase="'TEXT_FIELD'">
          <ng-container *ngIf="mapDataTypes(formElement.getDatatype()) === 'number'; else defaultTextField">
            <app-numeric-input
              [disabled]="isDisabled"
              (changes)="onChanges(null)"
              *ngIf="formElement"
              [entryElement]="formElement"
              [isSpreadsheetContext]="isSpreadsheetContext"
            >
            </app-numeric-input>
          </ng-container>
          <ng-template #defaultTextField>
            <mat-form-field
              [floatLabel]="formElement.getFloatingLabel()"
              [formGroup]="userForm"
              [saxmsTooltip]="Value.getValue() | unitPipe : 'forward' : formElement"
              *ngIf="formElement.getValue()"
              [ngClass]="hint?.text ? 'has-hint' : ''"
              #textField
              [appearance]="hint?.text ? 'outline' : appearance"
              [hideRequiredMarker]="hideRequiredMarker"
            >
              <mat-label *ngIf="appearance === 'outline' || hint?.text">
                {{ formElement.getName() }}
              </mat-label>
              <input
                [autofocus]="formElement?.isAutoFocused()"
                name="default-text-entry-element"
                #inputRef
                #elementRef
                (keydown)="keydown($event)"
                (change)="onChanges(null)"
                (ngModelChange)="afterValueChanged($event, elementRef)"
                autocomplete="off"
                [ngModel]="Value.getValue() | unitPipe : 'forward' : formElement"
                (keyup)="onKeyUp($event)"
                [id]="formElement.getId()"
                class="inner-input"
                [ngClass]="mapDataTypes(formElement.getDatatype())"
                formControlName="textFieldControl"
                [pattern]="formElement.getValidationRegex()"
                matInput
                [style.color]="textColor"
                [passwordInput]="formElement.isPassword()"
                [type]="'text'"
                [required]="formElement.isRequired()"
                [maxlength]="formElement.getMaxLength()"
                [min]="Value.getValue() | unitPipe : 'forward' : formElement : 'min'"
                [max]="Value.getValue() | unitPipe : 'forward' : formElement : 'max'"
                [step]="Value.getValue() | unitPipe : 'forward' : formElement : 'step'"
                [placeholder]="
                  (hideRequiredMarker && formElement.isRequired() ? '* ' : '') +
                  (formElement.getName() || '' | translate)
                "
              />
              <!--<mat-hint class="hint" *ngIf="hint" [style.color]="hint.color">
                  <mat-icon *ngIf="hint.icon">{{hint.icon}}</mat-icon> {{hint.text}}
                </mat-hint>-->
              <span matSuffix class="unit" *ngIf="formElement.getUnit()">{{ formElement.getUnit() }}</span>
              <ng-container *ngTemplateOutlet="hintElement"></ng-container>
              <mat-icon
                *ngIf="textFieldControl.errors?.pattern"
                matSuffix
                [matMenuTriggerFor]="errorMenu"
                class="error-icon"
              >
                info</mat-icon
              >
              <app-conditions-menu
                matPrefix
                *ngIf="formElement?.getAvailableFilterConditions().length > 0"
                [entryElement]="formElement"
              ></app-conditions-menu>
              <app-sort-menu matPrefix *ngIf="formElement.isEnableSort()" [element]="formElement"></app-sort-menu>
              <mat-error *ngIf="textFieldControl.invalid"
                >{{ getErrorMessage(textFieldControl) | translate }}
              </mat-error>
              <button
                mat-icon-button
                matPrefix
                *ngIf="formElement.getInfoText(isDisabled) && !textFieldControl.errors?.pattern"
              >
                <mat-icon *ngIf="formElement.getInfoText(isDisabled)" class="info-icon" [matMenuTriggerFor]="infoMenu">
                  info
                </mat-icon>
              </button>
              <app-image
                [disabled]="isDisabled"
                *ngIf="formElement.getIcon()"
                class="prefixed-icon"
                matSuffix
                [label]="formElement.getIcon()"
              >
              </app-image>
            </mat-form-field>
          </ng-template>
        </ng-container>

        <!-- TEXT_AREA -->
        <ng-container *ngSwitchCase="'TEXT_AREA'">
          <mat-form-field
            [formGroup]="userForm"
            *ngIf="formElement.getValue()"
            [ngClass]="hint?.text ? 'has-hint' : ''"
            #textArea
            [appearance]="hint?.text ? 'outline' : appearance"
            class="text-area"
            [hideRequiredMarker]="hideRequiredMarker"
          >
            <mat-label *ngIf="appearance === 'outline' || hint?.text">{{ formElement.getName() }}</mat-label>
            <textarea
              [rows]="formElement.getRows()"
              (change)="onChanges(null)"
              [ngModel]="Value.getValue() | unitPipe : 'forward' : formElement"
              [id]="formElement.getId()"
              [saxmsTooltip]="elementData.value.value"
              [pattern]="formElement.getValidationRegex()"
              formControlName="textFieldControl"
              [style.color]="textColor"
              [required]="formElement.isRequired()"
              matInput
              (ngModelChange)="afterValueChanged($event)"
              [placeholder]="
                (hideRequiredMarker && formElement.isRequired() ? '* ' : '') + (formElement.getName() | translate)
              "
              [autofocus]="formElement?.isAutoFocused()"
            ></textarea>
            <ng-container *ngTemplateOutlet="hintElement"></ng-container>
            <mat-icon
              *ngIf="textFieldControl.errors?.pattern"
              matSuffix
              [matMenuTriggerFor]="errorMenu"
              class="error-icon"
            >
              info</mat-icon
            >
            <mat-error *ngIf="textFieldControl.invalid">{{ getErrorMessage(textFieldControl) | translate }} </mat-error>
            <button mat-icon-button matPrefix *ngIf="formElement.getInfoText(isDisabled)">
              <mat-icon class="info-icon" [matMenuTriggerFor]="infoMenu">info </mat-icon>
            </button>
            <app-image
              [disabled]="isDisabled"
              class="prefixed-icon"
              *ngIf="formElement.getIcon()"
              matSuffix
              [label]="formElement.getIcon()"
            >
            </app-image>
          </mat-form-field>
        </ng-container>

        <!-- AUTOCOMPLETE_TEXT_FIELD -->
        <ng-container *ngSwitchCase="'AUTOCOMPLETE_TEXT_FIELD'">
          <mat-form-field
            [id]="formElement.getId()"
            [formGroup]="userForm"
            *ngIf="formElement.getValue()"
            [ngClass]="hint?.text ? 'has-hint' : ''"
            [appearance]="hint?.text ? 'outline' : appearance"
            [hideRequiredMarker]="hideRequiredMarker"
            (focusout)="onFocusOut($event)"
          >
            <mat-label [saxmsTooltip]="elementData.value.value" *ngIf="appearance === 'outline' || hint?.text">
              {{ formElement.getName() }}</mat-label
            >
            <input
              (keyup)="onAutocompleteKeyUp($event)"
              name="default-autocomple-entry-element"
              autocomplete="off"
              #control
              [ngModel]="Value.getValue() === '-' ? null : Value.getValue()"
              [pattern]="formElement.getValidationRegex()"
              formControlName="textFieldControl"
              [style.color]="textColor"
              (click)="getOptions(control.value, true)"
              [matAutocomplete]="auto"
              matInput
              [min]="Value.getValue() | unitPipe : 'forward' : formElement : 'min'"
              [max]="Value.getValue() | unitPipe : 'forward' : formElement : 'max'"
              [step]="Value.getValue() | unitPipe : 'forward' : formElement : 'step'"
              [required]="formElement.isRequired()"
              [placeholder]="
                (hideRequiredMarker && formElement.isRequired() ? '* ' : '') + (formElement.getName() | translate)
              "
            />
            <ng-container *ngTemplateOutlet="hintElement"></ng-container>
            <mat-icon
              [saxmsTooltip]="'@does_not_match@' | translate"
              matPrefix
              style="color: darkred"
              *ngIf="autocompleteError && !isDisabled"
              >error</mat-icon
            >
            <!-- open autocomplete option list -->
            <button
              *ngIf="!Value.getValue()?.length"
              class="saxms-support-arrow"
              [disabled]="disabled"
              mat-icon-button
              matSuffix
              (click)="getOptions(control.value)"
            >
              <mat-icon>search</mat-icon>
            </button>
            <!-- clear textfield -->
            <button
              *ngIf="Value.getValue()?.length && !disabled"
              class="saxms-support-arrow"
              mat-icon-button
              matSuffix
              (click)="optionSelected('')"
            >
              <mat-icon>close</mat-icon>
            </button>
            <mat-icon
              *ngIf="textFieldControl.errors?.pattern"
              matPrefix
              [matMenuTriggerFor]="errorMenu"
              class="error-icon"
            >
              info</mat-icon
            >
            <mat-error *ngIf="textFieldControl.invalid">{{ getErrorMessage(textFieldControl) | translate }} </mat-error>
            <button mat-icon-button matPrefix *ngIf="formElement.getInfoText(isDisabled)">
              <mat-icon class="info-icon" [matMenuTriggerFor]="infoMenu">info </mat-icon>
            </button>
            <app-image
              [disabled]="isDisabled"
              class="prefixed-icon"
              *ngIf="formElement.getIcon()"
              matSuffix
              [label]="formElement.getIcon()"
            >
            </app-image>
          </mat-form-field>
          <mat-autocomplete [panelWidth]="480" #auto="matAutocomplete" (optionActivated)="handleKeyboardEvent($event)">
            <div
              class="autocomplete-content"
              [class.has-footer]="formElement.isPoweredByGoogle()"
              (mouseenter)="setInsidePanel(true)"
              (mouseleave)="setInsidePanel(false)"
            >
              <mat-option
                *ngFor="let option of options"
                class="auto-option"
                (click)="optionSelected(option)"
                [value]="option.getValue()"
              >
                <app-image *ngIf="option.getIcon()" [label]="option.getIcon()" [style.color]="option.getColor()">
                </app-image>
                {{ option.getName() || option.getValue() }}
              </mat-option>
            </div>
            <div class="autocomplete-footer" *ngIf="formElement.isPoweredByGoogle()">
              <img src="./assets/img/licensed/powered-by-google-light.png" [style.height]="'50px'" />
            </div>
          </mat-autocomplete>
        </ng-container>
        <!-- LABELED_TEXT -->
        <ng-container *ngSwitchCase="'LABELED_TEXT'">
          <app-labeled-text [disabled]="isDisabled" [formElement]="formElement"></app-labeled-text>
        </ng-container>
        <!-- COMBO_BOX -->
        <ng-container *ngSwitchCase="'COMBO_BOX'">
          <mat-form-field
            [saxmsTooltip]="elementData.value?.value?.value || elementData.value?.value | translate"
            [formGroup]="userForm"
            *ngIf="formElement.getValue()"
            [ngClass]="hint?.text ? 'has-hint' : ''"
            [appearance]="hint?.text ? 'outline' : appearance"
            [hideRequiredMarker]="hideRequiredMarker"
          >
            <mat-label *ngIf="appearance === 'outline' || hint?.text">{{ formElement.getName() }}</mat-label>
            <mat-select
              formControlName="comboBoxFieldControl"
              [compareWith]="compareComboBox"
              [id]="formElement.getId()"
              (selectionChange)="onChanges(null)"
              [value]="Value.getValue()"
              [ngModel]="Value.getValue()"
              (ngModelChange)="setValue($event)"
              [required]="formElement.isRequired()"
              [placeholder]="
                (hideRequiredMarker && formElement.isRequired() ? '* ' : '') + (formElement.getName() | translate)
              "
            >
              <mat-select-trigger elementData.value>
                <mat-icon class="selection-icon" *ngIf="isABoolean(formElement)">
                  {{ getBooleanValue(formElement) ? 'done' : 'clear' }}</mat-icon
                >
                <!-- <ng-container *ngIf="isABoolean(formElement)">JA</ng-container> -->
                <ng-container
                  *ngIf="!elementData.value && !elementData.value?.value && !elementData.value?.value?.value"
                >
                  --
                </ng-container>
                <ng-container *ngIf="elementData.value.value && elementData.value.value !== true">
                  {{
                    (elementData.value.value?.getName ? elementData.value.value.getName() : elementData.value.value)
                      | translate
                  }}
                </ng-container>
              </mat-select-trigger>
              <mat-option
                [saxmsTooltip]="(option?.getName ? option.getName() : option) | translate"
                [id]="option"
                *ngFor="let option of Value.getAvailableValues()"
                [value]="option"
              >
                <ng-container *ngIf="option === null">--</ng-container>
                <mat-icon *ngIf="option === true">done</mat-icon>
                <mat-icon *ngIf="option === false">clear</mat-icon>
                <mat-icon
                  *ngIf="option?.isFavorite && formElement.executeFavoriteChange"
                  (click)="formElement.executeFavoriteChange($event, option)"
                >
                  {{ option.isFavorite() ? 'star' : 'star_outline' }}
                </mat-icon>
                <ng-container *ngIf="option && option !== true">
                  {{ (option?.getName ? option.getName() : option) | translate }}
                </ng-container>
              </mat-option>
            </mat-select>
            <ng-container *ngTemplateOutlet="hintElement"></ng-container>
            <mat-hint *ngIf="formElement.getInfoText(isDisabled)">
              <mat-icon class="info-icon" [matMenuTriggerFor]="infoMenu">info </mat-icon>
            </mat-hint>
          </mat-form-field>
        </ng-container>
        <!-- COMBO_BOX_MULTIPLE_SELECT -->
        <ng-container *ngSwitchCase="'COMBO_BOX_MULTIPLE_SELECT'">
          <mat-form-field
            [saxmsTooltip]="formElement.getName()"
            *ngIf="formElement.getValue()"
            [ngClass]="hint?.text ? 'has-hint' : ''"
            [appearance]="hint?.text ? 'outline' : appearance"
          >
            <mat-label *ngIf="appearance === 'outline' || hint?.text">
              {{ formElement.getName() }}
            </mat-label>
            <mat-select
              [compareWith]="compareComboBox"
              [id]="formElement.getId()"
              (selectionChange)="onChanges(null)"
              [value]="Value.getValue()"
              [ngModel]="Value.getValue()"
              (ngModelChange)="setValue($event)"
              [disabled]="disabled"
              [placeholder]="formElement.getName() | translate"
              multiple
              class="mat-select-multiple"
            >
              <mat-select-trigger>
                <ng-container
                  *ngIf="!elementData.value && !elementData.value?.value && !elementData.value?.value?.length"
                >
                  --
                </ng-container>
                <ng-container *ngIf="elementData.value.value && elementData.value.value.length">
                  {{
                  (elementData.value.value?.[0].getName
                  ? elementData.value.value[0].getName()
                  : elementData.value.value[0]
                  ) | translate
                  }}
                  <span *ngIf="(elementData.value.value?.length || 0) > 1" class="multiple-selection-info">
                    (+{{ 'LABEL.x_others' | translate : { x: elementData.value.value.length - 1 } }})
                  </span>
                </ng-container>
              </mat-select-trigger>
              <mat-option
                [saxmsTooltip]="(option?.getName ? option.getName() : option) | translate"
                [id]="option"
                *ngFor="let option of Value.getAvailableValues()"
                [value]="option"
              >
                <ng-container *ngIf="option === null">--</ng-container>
                <ng-container *ngIf="option">
                  {{ (option?.getName ? option.getName() : option) | translate }}
                </ng-container>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </ng-container>
        <!-- CHECK_BOX -->
        <ng-container *ngSwitchCase="'CHECK_BOX'">
          <mat-icon *ngIf="formElement.getInfoText(isDisabled)" [matMenuTriggerFor]="infoMenu" class="info-icon custom">
            info
          </mat-icon>
          <mat-checkbox
            class="checkbox"
            [saxmsTooltip]="formElement.getName() | translate"
            [id]="formElement.getId()"
            [style]="'margin-bottom: 0px; display: inline-block; ' + formElement.getStyle()"
            *ngIf="formElement.getValue()"
            (change)="onChanges()"
            [disabled]="disabled"
            [ngModel]="Value.getValue()"
            (ngModelChange)="afterValueChanged($event)"
          >
            {{ formElement.getName() | translate }}</mat-checkbox
          >
        </ng-container>

        <!-- SLIDE_TOGGLE -->
        <ng-container *ngSwitchCase="'SLIDE_TOGGLE'">
          <mat-slide-toggle
            [id]="formElement.getId()"
            *ngIf="formElement.getValue()"
            [saxmsTooltip]="formElement.getName() | translate"
            [(ngModel)]="elementData.value.value"
            [checked]="elementData.value.value"
            [disabled]="disabled"
            [ngClass]="hint?.text ? 'has-message' : ''"
            (change)="onChanges(null)"
            ><span [style.font-size]="'14px'">{{ formElement.getName() | translate }}</span>
          </mat-slide-toggle>
          <button
            class="info-14"
            mat-icon-button
            matPrefix
            *ngIf="formElement.getInfoText(isDisabled)"
            [matMenuTriggerFor]="infoMenu"
          >
            <mat-icon *ngIf="formElement.getInfoText(isDisabled)" class="info-icon">info </mat-icon>
          </button>
          <ng-container *ngTemplateOutlet="hintElement; context: { $implicit: 'saxms-formfield-element' }">
          </ng-container>
        </ng-container>
        <!-- SLIDER -->
        <ng-container *ngSwitchCase="'SLIDER'">
          <mat-slider
            [min]="Value.getValue() | unitPipe : 'forward' : formElement : 'min'"
            [max]="Value.getValue() | unitPipe : 'forward' : formElement : 'max'"
            [step]="Value.getValue() | unitPipe : 'forward' : formElement : 'step'"
            [value]="Value?.getValue()"
            thumbLabel="true"
            (change)="onValueChange($event.value)"
          >
          </mat-slider>
          <button mat-icon-button matPrefix *ngIf="formElement.getInfoText(isDisabled)" [matMenuTriggerFor]="infoMenu">
            <mat-icon *ngIf="formElement.getInfoText(isDisabled)" class="info-icon">info </mat-icon>
          </button>
        </ng-container>
        <!-- TOGGLE_BUTTON_GROUP -->
        <ng-container *ngSwitchCase="'TOGGLE_BUTTON_GROUP'">
          <mat-button-toggle-group [value]="Value?.getValue()" (change)="onValueChange($event.value)">
            <mat-button-toggle
              *ngFor="let btn of formElement.getAvailableButtons()"
              [value]="btn.getValue().getValue()"
            >
              <app-button [id]="btn.getId()" [icon]="btn.getIcon()" [data]="btn"></app-button>
            </mat-button-toggle>
          </mat-button-toggle-group>
        </ng-container>
        <!-- RADIOBUTTONS -->
        <ng-container *ngSwitchCase="'RADIOBUTTONS'">
          <label class="radioButtonLabelClass">{{ formElement.getName() }}</label>
          <mat-radio-group [value]="Value.getValue()" (change)="onValueChange($event.value)">
            <mat-radio-button
              class="template-radio-button"
              *ngFor="let btn of formElement.getAvailableButtons()"
              [value]="btn.getValue().getValue()"
            >
              <app-button [id]="btn.getId()" [icon]="btn.getIcon()" [data]="btn"></app-button>
            </mat-radio-button>
          </mat-radio-group>
        </ng-container>

        <!-- WEB_LINK -->
        <ng-container *ngSwitchCase="'WEB_LINK'">
          <app-button [id]="formElement.getId()" [data]="formElement"> </app-button>
        </ng-container>
        <!-- VIEW_OBJECT -->
        <ng-container *ngSwitchCase="'VIEW_OBJECT'">
          Unterstützung von 'VIEW_OBJECT' wurde am 28.04.2021 entfernt
        </ng-container>
        <!-- RESOURCE_SELECTOR -->
        <ng-container *ngSwitchCase="'RESOURCE_SELECTOR'">
          <mat-icon *ngIf="formElement.getInfoText(isDisabled)" [matMenuTriggerFor]="infoMenu" class="info-icon custom">
            info
          </mat-icon>
          <template-resource-selector
            [id]="formElement.getId()"
            [stretched]="stretched"
            *ngIf="Value?.getValue()"
            [disabled]="disabled"
            [selector]="formElement"
          >
          </template-resource-selector>
        </ng-container>
        <!-- BUTTON_SLIDER-->
        <ng-container *ngSwitchCase="'NUMERIC_RANGE_PICKER'">
          <app-button-slider [element]="formElement" (changed)="onChanges(null)"> </app-button-slider>
          <button mat-icon-button matPrefix *ngIf="formElement.getInfoText(isDisabled)" [matMenuTriggerFor]="infoMenu">
            <mat-icon *ngIf="formElement.getInfoText(isDisabled)" class="info-icon">info </mat-icon>
          </button>
        </ng-container>

        <!-- FILESYSTEMELEMENT -->
        <ng-container *ngSwitchCase="'FILESYSTEMELEMENT'">
          <app-tile-button [id]="formElement.getId()" [icon]="formElement.getIcon()" [data]="formElement">
          </app-tile-button>
        </ng-container>
        <!-- ICON_TEXT -->
        <ng-container *ngSwitchCase="'ICON_TEXT'"> </ng-container>

        <!-- FILTER_NODE_VIEWER -->
        <ng-container *ngSwitchCase="'FILTER_NODE_VIEWER'">
          <app-filter-node-viewer
            (change)="onChanges($event)"
            [viewer]="formElement"
            *ngIf="formElement?.getViewerData()"
          >
          </app-filter-node-viewer>
        </ng-container>

        <!-- SEPARATOR -->
        <ng-container *ngSwitchCase="'SEPARATOR'">
          <div class="separatorClass"></div>
        </ng-container>

        <!-- SEPARATOREMPTY -->
        <ng-container *ngSwitchCase="'SEPARATOREMPTY'">
          <div class="separatorEmptyClass"></div>
        </ng-container>

        <!-- DATE_INPUT -->
        <ng-container *ngSwitchCase="'DATE_INPUT'">
          <mat-form-field>
            <app-date-input
              (change)="onChanges(null)"
              [value]="formElement.getValue()"
              [showTime]="formElement.getShowTime()"
            ></app-date-input>
          </mat-form-field>
        </ng-container>

        <!-- DEFAULT -->
        <ng-container *ngSwitchDefault>
          <strong>{{ formElement.getFieldType() }}</strong>
          {{ 'NOT_IMPLEMENTED.saxms-element' | translate }}
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
