<mat-expansion-panel [ngClass]="!options.visible ? 'is-invisible' : ''">
  <mat-expansion-panel-header>
    <mat-panel-title>
      {{ options.name }}
    </mat-panel-title>
    <mat-panel-description>
      {{ options.description }}
    </mat-panel-description>
  </mat-expansion-panel-header>

  <mdui-entry
    *ngFor="let entry of options.settingsEntries"
    [id]="entry.id"
    (onResults)="handleResults($event)"
    [onEvent]="localSubject"
    [options]="entry"
  ></mdui-entry>
  <mat-accordion class="example-headers-align">
    <mdui-headline
      *ngFor="let category of options.subSettingsCategories"
      (onResults)="handleResults($event)"
      [id]="category.id"
      [onEvent]="localSubject"
      [options]="category"
    ></mdui-headline>
  </mat-accordion>
</mat-expansion-panel>

<!---</mat-accordion>
<mat-form-field>
    <input matInput placeholder="Age">
</mat-form-field>
</mat-expansion-panel>-->
