import { YAxisDataFinder } from '../../data-handler/data-finder/yaxis-data-finder';
import { EGanttScrollContainer } from '../../html-structure/scroll-container.enum';
import { BestGantt } from '../../main';
import { GanttShiftComponentsDataHandler } from './shift-components-data-handler';

export class GanttShiftComponentVisualizer {
  private _canvas: { [id: string]: d3.Selection<SVGGElement, undefined, d3.BaseType, undefined> } = {};
  private _relatedComponents: {
    [id: string]: d3.Selection<SVGRectElement, IGanttShiftComponentVisualizationData, d3.BaseType, undefined>;
  } = {};

  private readonly _overflow = 3;
  private _renderDataSet: IGanttShiftComponentVisualizationData[] = [];

  constructor(private _ganttDiagram: BestGantt, private _shiftComponentsDataHandler: GanttShiftComponentsDataHandler) {
    this._initCanvas();
  }

  private _initCanvas(): void {
    for (const scrollContainerId of Object.values(EGanttScrollContainer)) {
      this._canvas[scrollContainerId] = this._ganttDiagram
        .getShiftFacade()
        .getCanvasBehindShifts(scrollContainerId)
        .append('g')
        .attr('class', 'componentVisualizer');
    }
  }

  reRender() {
    this._buildVisualization();
  }

  regenerateData() {
    this._renderDataSet = [];
    this._renderDataSet = this._generateRenderDataSet(null);
  }

  visualizeRelatedComponents(superBlockBackendData) {
    if (!superBlockBackendData) {
      console.warn('No backend data. Cant visualize.');
      return;
    }
    this._renderDataSet = this._generateRenderDataSet(this._shiftComponentsDataHandler.getSuperBlockBackendData());
    this._buildVisualization();
  }

  public removeAll(): void {
    for (const id in this._canvas) {
      if (this._relatedComponents[id]) {
        this._canvas[id].remove();
        this._canvas[id] = this._ganttDiagram
          .getShiftFacade()
          .getCanvasBehindShifts(id as EGanttScrollContainer)
          .append('g')
          .attr('class', 'componentVisualizer');
      }
    }
  }

  public remove(): void {
    for (const id in this._canvas) {
      if (this._relatedComponents[id]) {
        this._canvas[id].remove();
      }
    }
  }

  private _buildVisualization(): void {
    this.removeAll();

    const scrollContainerIds = this._ganttDiagram.getRenderDataHandler().getActiveScrollContainerIds();
    for (const scrollContainerId of scrollContainerIds) {
      this._relatedComponents[scrollContainerId] = this._canvas[scrollContainerId]
        .selectAll<SVGRectElement, IGanttShiftComponentVisualizationData>('.componentVisualizerBackgrounds')
        .data(this._renderDataSet.filter((d) => d.scrollContainerId === scrollContainerId));

      this._relatedComponents[scrollContainerId].exit().remove();

      this._relatedComponents[scrollContainerId]
        .enter()
        .append('rect')
        .attr('class', 'componentVisualizerBackgrounds')
        .attr('height', (d) => d.height)
        .attr('width', (d) => d.width)
        .attr('rx', (d) => d.rx)
        .attr('ry', (d) => d.ry)
        .attr('x', (d) => d.x)
        .attr('y', (d) => d.y)
        .attr('fill-opacity', 0)
        .attr('stroke', 'rgba(0, 0, 0, 0.8)')
        .attr('stroke-width', '1px');
    }
  }

  private _generateRenderDataSet(superBlockBackendData): IGanttShiftComponentVisualizationData[] {
    const dataSet: IGanttShiftComponentVisualizationData[] = [];
    if (!superBlockBackendData) {
      superBlockBackendData = this._shiftComponentsDataHandler.getSuperBlockBackendData();
    }

    for (const superBlock of this._shiftComponentsDataHandler.getShiftComponents()) {
      const scrollContainerIds = this._ganttDiagram.getRenderDataHandler().getActiveScrollContainerIds();
      for (const scrollContainerId of scrollContainerIds) {
        const renderDataSet = this._ganttDiagram.getRenderDataHandler().getRenderDataShifts(scrollContainerId);
        const foundShiftData = renderDataSet.filter((shift) => superBlock.group.includes(shift.id));
        if (!foundShiftData.length || !superBlockBackendData[superBlock.id]) {
          continue;
        }
        const startTimePoint = superBlockBackendData[superBlock.id].start;
        const endTimePoint = superBlockBackendData[superBlock.id].end;

        const yRange: number[] = Array.from<number>(new Set(foundShiftData.map((shift) => shift.y))).sort(
          (a, b) => a - b
        );
        const yPosition = yRange[0] - this._ganttDiagram.getConfig().getLineTop();

        if (
          foundShiftData.some((shift) => {
            return shift.noRender.length === 0;
          })
        ) {
          dataSet.push(this._getEntry(startTimePoint, endTimePoint, yPosition, yRange, scrollContainerId));
        }
      }
    }
    return dataSet;
  }

  private _getEntry(
    startTimePoint,
    endTimePoint,
    yPosition,
    yRange,
    scrollContainerId: EGanttScrollContainer
  ): IGanttShiftComponentVisualizationData {
    const rowCanvasData = YAxisDataFinder.getRowByYPosition(
      this._ganttDiagram.getDataHandler().getYAxisDataset(),
      yPosition
    );

    const currentScale = this._ganttDiagram.getXAxisBuilder().getCurrentScale();
    const range = yRange[yRange.length - 1] - yRange[0];
    const startDate = new Date(startTimePoint);
    const xPos = currentScale(startDate);
    return {
      scrollContainerId: scrollContainerId,
      rx: this._ganttDiagram.getConfig().getRoundedCorners(),
      ry: this._ganttDiagram.getConfig().getRoundedCorners(),
      x: xPos - this._overflow,
      y: this._ganttDiagram.getRenderDataHandler().getStateStorage().getYPositionRow(rowCanvasData.id),
      height: this._ganttDiagram.getDataHandler().getRowHeightStorage().getRowHeightById(rowCanvasData?.id) + range,
      width: currentScale(new Date(endTimePoint)) - xPos + 2 * this._overflow,
    };
  }
}

interface IGanttShiftComponentVisualizationData {
  scrollContainerId: EGanttScrollContainer;
  rx: number;
  ry: number;
  x: number;
  y: number;
  height: number;
  width: number;
}
