/** Modi for xAxis Positions, communication with backed*/

export enum SaxMsBestGanttZoomGridMode {
  OFF = 'OFF',
  AUTO = 'AUTO',
  STATIC = 'STATIC',
}

export enum SaxMsBestGanttZoomGridStep {
  HOURLY = 'HOURLY',
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY',
  CUSTOM = 'CUSTOM',
}

export enum EGanttTooltipDelayType {
  NO_DELAY = 0,
  SHORT_DELAY = 600,
  MEDIUM_DELAY = 1000,
  LONG_DELAY = 2000,
}

export enum EGanttLightboxExitType {
  DISCARD = 'cancellightbox',
  SAVE = 'save',
  RESET = 'reset',
}
