/**
 * Storage of index card settings.
 * @keywords index, card, config, settings, style
 * @plugin index-card
 * @class
 * @constructor
 */
export class IndexCardConfig {
  fontSize: number;
  lineHeight: number;
  padding_left: number;
  padding_top: number;
  padding_bottom: number;
  padding_right: number;
  dateFormat: string;
  allowVerticalOverlap: boolean;
  textSelectablePointerEvent: boolean;
  text_shadow: any;

  constructor() {
    this.fontSize = null;
    this.lineHeight = null;
    this.padding_left = 2;
    this.padding_top = 5;
    this.padding_bottom = 0;
    this.padding_right = 1;
    this.dateFormat = '%d.%m.';
    this.allowVerticalOverlap = false;
    this.textSelectablePointerEvent = false;
    this.text_shadow = {
      shift: {
        right: 1,
        down: 1,
      },
      color: 'grey',
      enable: true,
    };
  }

  //
  // GETTER & SETTER
  //
  /**
   * Sets new row height. Pays attention that font-size has minimum size that d3-pls framework works.
   * @param {number} lineHeight New height of text row inside index cards.
   */
  setLineHeight(lineHeight) {
    this.lineHeight = lineHeight;
  }

  getLineHeight() {
    return this.lineHeight;
  }
  /**
   * Sets new text size. Pays attention that row height has minimum size that d3-pls framework works.
   * @param {number} lineHeight New height of text row inside index cards.
   */
  setFontSize(fontSize) {
    this.fontSize = fontSize;
    if (this.fontSize > this.lineHeight) this.lineHeight = this.fontSize * 1.2;
  }
  /**
   * Sets date format for index card date text line:
   * <a href="https://github.com/d3/d3-time-format">D3 Date formats</a>
   * @param {string} dateFormat D3 date formatting.
   */
  setDateFormat(dateFormat) {
    this.dateFormat = dateFormat;
  }

  /**
   * @return {string}
   */
  getDateFormat() {
    return this.dateFormat;
  }

  textShadow() {
    return this.text_shadow.enable;
  }
  textShadowDown() {
    return this.text_shadow.shift.down;
  }
  textShadowRight() {
    return this.text_shadow.shift.right;
  }
  textShadowColor() {
    return this.text_shadow.color;
  }

  textSelectable() {
    return this.textSelectablePointerEvent;
  }

  /**
   * Enable / disable textShadows of indexCardText on shifts with pattern.
   * @param {boolean} boolean
   */
  toggleTextShadow(boolean) {
    this.text_shadow.enable = boolean;
  }
  /**
   * Enable / disable textShadows of indexCardText on shifts with pattern.
   * @param {String} color A css color Value.
   */
  setTextShadowColor(color) {
    this.text_shadow.color = color;
  }
  /**
   * TextShadow offset down.
   * @param {number} shift In pixel.
   */
  setTextShadowDownShift(shift) {
    this.text_shadow.shift.down = shift;
  }
  /**
   * TextShadow offset right.
   * @param {number} shift In pixel.
   */
  setTextShadowRightShift(shift) {
    this.text_shadow.shift.right = shift;
  }

  /**
   * Toggle textOverlay selectable (pointer-event)
   * @param {boolean} toggle
   */
  toggleTextSelectable(toggle) {
    this.textSelectable = toggle;
  }
}
