import { GanttCanvasRow } from '../../../data-handler/data-structure/data-structure';

/**
 * Handler for defining complete rows in which different shifts are not allowed to drag.
 * All strictEntryTypes are blocked for any shift drop.
 * @keywords shift, translation, limiter, limit, restriction, rule
 */
export class GanttShiftTranslationRowLimiter {
  private _strictEntryTypes: number[] = [];

  /**
   * Returns an array with ids of forbidden rows.
   * @param shiftEntryTypesArray Array of entryTypes by shift.
   * @param canvasYAxisDataSet Data set of y axis canvas.
   * @returns Array of forbidden row ids.
   */
  public getForbiddenRowIdsByShiftEntryTypes(
    shiftEntryTypesArray: number[],
    canvasYAxisDataSet: GanttCanvasRow[]
  ): string[] {
    const allForbiddenRows = [];
    if (!shiftEntryTypesArray || !Array.isArray(shiftEntryTypesArray) || !canvasYAxisDataSet) {
      return allForbiddenRows;
    }

    for (const rowData of canvasYAxisDataSet) {
      if (!rowData.allowedEntryTypes || !Array.isArray(rowData.allowedEntryTypes)) {
        continue;
      }
      let entryMissMatch = false;
      if (rowData.allowedEntryTypes.includes(-1)) {
        continue;
      } // all shifts can be dropped on this row if allowed entry type is -1
      for (const shiftEntryType of shiftEntryTypesArray) {
        if (!rowData.allowedEntryTypes.includes(shiftEntryType)) {
          entryMissMatch = true;
          break;
        }
      }
      if (entryMissMatch) allForbiddenRows.push(rowData.id);
    }
    return allForbiddenRows;
  }

  /**
   * Returns a boolean value if shift drop is allowed by blockTypes of shift.
   * @param shiftEntryTypesArray Array of entryTypes by shift.
   * @param canvasYAxisDataSet Data set of y axis canvas.
   * @param targetRowId
   * @returns If shift drop is allowed (true) if not (false).
   */
  public isShiftDropAllowed(
    shiftEntryTypesArray: number[],
    canvasYAxisDataSet: GanttCanvasRow[],
    targetRowId: string
  ): boolean {
    const allForbiddenRows = this.getForbiddenRowIdsByShiftEntryTypes(shiftEntryTypesArray, canvasYAxisDataSet);
    if (!allForbiddenRows.includes(targetRowId)) return true;

    if (this._strictEntryTypes.length === 0) {
      return true;
    }

    for (const shiftEntryType of shiftEntryTypesArray) {
      for (const strictEntryType of this._strictEntryTypes) {
        if (shiftEntryType == strictEntryType) return false;
      }
    }
    return true;
  }

  //
  // GETTER & SETTER
  //

  /**
   * Sets the array of strict entry types to the specified one.
   * @param strictEntryTypes New array of strict entry types.
   */
  public setStrictEntryTypes(strictEntryTypes: number[] = []) {
    this._strictEntryTypes = strictEntryTypes;
  }
}
