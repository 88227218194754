import { CdkDragEnd, CdkDragStart } from '@angular/cdk/drag-drop';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { TemplateUiService } from '@app-modeleditor/components/template-ui/template.service';
import { ERequestMethod, RequestService } from '@app-modeleditor/request.service';
import { UiService } from '@app-modeleditor/ui.service';
import { TemplateHotkeyService } from '@app-modeleditor/utils/template-hotkey.service';
import { Registered, TemplateService } from '@app-modeleditor/utils/template.service';
import { ConfigService } from '@core/config/config.service';
import { CloudMessagingService } from '@core/notification/cloud-messaging.service';
import { Notification } from '@core/notification/notification';
import { GlobalUtils } from 'frontend/src/dashboard/global-utils';
import { Template } from 'frontend/src/dashboard/model/resource/template';
import { ETemplateType } from 'frontend/src/dashboard/model/resource/template-type';
import { TemplateService as NewTemplateService } from 'frontend/src/dashboard/template/data-access/template.service';
import { NEW_SUPPORTED_TYPES } from 'frontend/src/dashboard/template/data-access/template.state';
import { ToolbarAction } from 'frontend/src/dashboard/view/navbar/toolbar/toolbar-action.enum';
import { Observable, Subject, Subscription, of, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, takeUntil, takeWhile, tap } from 'rxjs/operators';
import { SharedUiService } from '../../../model-editor/ui/service/shared.ui.service';
import { SharedToolbarService } from '../../../view/navbar/toolbar/shared-toolbar.service';
import { ButtonService } from '../button/button.service';
import { EntryCollection } from '../entry-collection/entry-collection';
import { EntryElement } from '../entry-collection/entry-element';
import { EFieldType } from '../entry-collection/field-type.enum';
import { Table } from '../spreadsheet/model/table';
import { SpreadsheetSaveOutput } from '../spreadsheet/spreadsheet';
import { TemplateAdapter } from './../../utils/template-factory.service';
import { ContextmenuService } from './../contextmenu/contextmenu.service';
import { TemplateResizeService } from './template-resize.service';
import { TemplateUnregisterService } from './template-unregister.service';

@Component({
  selector: 'app-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [],
})
export class TemplateComponent implements OnInit, OnDestroy, OnChanges {
  // @HostBinding('class.sticky') get cc(): boolean { return this.templateNode?.isSticky() ? true : false; }
  private uuid: string = GlobalUtils.generateUUID();
  // templateNode: any;
  private templateId: string;
  private lastRequestedUrl: string;
  @Input() templateNode;
  @Input() contentId: string;
  @Input() resourceId: string;
  highlightedKey: string = null;
  public errorMessage: string = null;
  public errorFlag = false;

  unsaved = false;
  unsavedData: any;
  selectedTabIndex = 0;
  debug: boolean;
  alive = true;

  private ngNext: Subject<void> = new Subject<void>();
  private reloadSub: Subscription;
  private refreshDataSub: Subscription;
  private ngRequest: Subject<void> = new Subject<void>();

  private loading: boolean;
  editMode = true; // edit mode on/off
  @Output() changeEvent: EventEmitter<any> = new EventEmitter<any>();
  @Input() root: HTMLElement;

  doNotRender = false;
  setDoNotRender(state: boolean): void {
    this.doNotRender = state;
  }

  constructor(
    private uiService: UiService,
    private toolbarService: SharedToolbarService,
    public templateUiService: TemplateUiService,
    private scharedUiService: SharedUiService,
    public configApi: ConfigService,
    private el: ElementRef,
    private actionApi: ButtonService,
    private templateApi: TemplateService,
    private contextmenuApi: ContextmenuService,
    private templateFactory: TemplateAdapter,
    private requestApi: RequestService,
    private _cd: ChangeDetectorRef,
    private $zone: NgZone,
    private _templateResizeService: TemplateResizeService,
    private _templateHotkeyApi: TemplateHotkeyService,
    private _fcm: CloudMessagingService,
    private $templateUnregisterApi: TemplateUnregisterService,
    private newTemplateService: NewTemplateService
  ) {}

  isShow(): boolean {
    if (!this.templateNode) {
      return false;
    }

    if (this.templateNode instanceof Template) {
      return this.templateNode.isShow();
    }

    return this.templateNode.show;
  }

  onClick(e: MouseEvent): void {
    this._templateHotkeyApi.focusTemplate(e.target as HTMLElement, this.templateNode.getId());
  }

  openCtx(event: MouseEvent): void {
    if (this.templateNode instanceof Template && !this.isDisabled) {
      if (this.templateNode.getContextmenu() && this.templateNode.getContextmenu().isDefaultBehaviour()) {
        event.preventDefault();
        event.stopImmediatePropagation();
        this.contextmenuApi.create(event, this.templateNode.getContextmenu());
      }
    }
  }

  get hasFileUploadVisible(): boolean {
    return !this.isDisabled && this.templateNode?.getFileUploadActions()?.length > 0 ? true : false;
  }

  /**
   * determines disabled state of template
   * @returns boolean
   */
  get isDisabled(): boolean {
    if (!this.templateNode || this.isLoading()) {
      return true;
    }

    return (
      !this.templateNode.isAlwaysEnabled() &&
      (!this.editMode ||
        !this.templateNode.isEditable() ||
        !this.templateNode.isEnabled() ||
        this.templateNode.isDisabled())
    );
  }

  /**
   * refresh component
   */
  refresh() {
    // TODO: implement custom refresh
  }

  /**
   * on init lifecycle
   * @returns void
   */
  ngOnInit(): void {
    if (!this.templateNode) {
      return;
    }

    this.templateId = this.templateNode.id;

    this.init();
  }

  private _listenAndQueueNotification() {
    this._notificationQueue = [];
    if (this.templateNode.getType() === ETemplateType.GANTT) {
      this._fcm
        .getMessage()
        .pipe(
          takeWhile(() => this.alive),
          takeUntil(this.ngNext)
        )
        .subscribe((event: Notification) => {
          if (!this._notificationQueue) {
            this._notificationQueue = [];
          }

          this._notificationQueue = this._notificationQueue.concat(event);
        });
    }
  }

  _notificationQueue = [];
  private init() {
    // this.$zone.runOutsideAngular(() => {
    if (!this.templateNode) {
      this.setLoading(true);
      return;
    }

    if (this.templateNode && this.templateNode instanceof Template) {
      this.templateNode.setElementRef(this.el);
    }
    if (!this.templateNode.restUrl) {
      this.setLoading(false);
    }

    this.ngNext.next();

    // keypress usage
    // this._handleKeyPress();
    this._listenAndQueueNotification();
    this.templateApi
      .afterRegisteredChanges()
      .pipe(takeUntil(this.ngNext))
      .subscribe((registered: Record<string, Registered>) => {
        const resourceId: string =
          this.resourceId || (this.templateNode instanceof Template ? this.templateNode.getResourceId() : null);
        const resource: Registered = registered[resourceId];
        const editmode: boolean = typeof resource?.editable === 'boolean' ? resource.editable : true;
        if (this.editMode !== editmode) {
          this.editMode = editmode;
          this._cd.markForCheck();
        }
      });

    if (this.templateNode && this.templateNode instanceof Template) {
      this.templateApi.registerTemplate(this.resourceId || this.templateNode.getResourceId(), this.templateNode);
    }

    // check for new template mechanism
    if (!NEW_SUPPORTED_TYPES.includes((this.templateNode?.getType() || '').toLowerCase())) {
      // // update elements
      this.templateApi
        .onUpdateElements()
        .pipe(takeUntil(this.ngNext))
        .pipe(filter((ids: string[]) => ids.indexOf(this.templateId) !== -1))
        .subscribe((ids: string[]) => {
          this.$forceGetData();
        });

      // refresh element data
      this.templateApi
        .onRefreshElementData()
        .pipe(takeUntil(this.ngNext))
        .pipe(filter((ids: string[]) => ids.indexOf(this.templateId) !== -1))
        .subscribe((ids: string[]) => this.$forceRefreshData());
    } else {
      this.newTemplateService
        .onElementRefreshed()
        .pipe(takeUntil(this.ngNext))
        .subscribe((result) => {
          if (result.templateID === this.templateId) {
            // update template to support old template mechanism
            this.templateNode.applyObject(this.templateFactory.adapt(result.responseData), {
              overwrite: true,
            });
          }
        });
    }

    if (this.lastRequestedUrl === this.templateNode.restUrl) {
      return;
    }
    this.lastRequestedUrl = this.templateNode.restUrl;

    if (this.templateApi.needsUpdate(this.templateNode.getId())) {
      this.templateNode.alreadyGotData = true;
      this.templateApi.unmarkFromUpdate(this.templateNode.getId());
      this.$forceGetData();
    } else {
      this._fetchData(this.templateNode.restUrl).subscribe();
    }

    this.$registerReload();

    // actions
    this.scharedUiService.onAction.pipe(takeUntil(this.ngNext)).subscribe((action) => {
      if (!action || !action.data) {
        return;
      }

      for (const key of action.data) {
        if (key === this.templateNode.id) {
          this.$setHighlighted(key);
        }

        if (!this.templateNode.entryElements) {
          continue;
        }

        for (const entry of this.templateNode.entryElements) {
          if (entry.id === key) {
            this.$setHighlighted(key);
            break;
          }
        }
      }
    });
    // });
  }

  isHighlighted = false;
  private $setHighlighted(key: string): void {
    this.isHighlighted =
      this.highlightedKey && this.templateNode.getId() && this.highlightedKey === this.templateNode.getId()
        ? true
        : false;
  }

  /**
   * on changes lifecycle
   * @param changes SimpleChanges
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.resourceId || changes.templateNode) {
      if (changes.templateNode && changes.templateNode.currentValue) {
        this.templateId = changes.templateNode.currentValue.id;
        if (!this.resourceId) {
          this.resourceId = this.templateNode.resourceId;
        }
      }
      this.init();
    }
  }

  /**
   * register reload
   */
  private $registerReload(): void {
    if (this.reloadSub) {
      this.reloadSub.unsubscribe();
    }
    if (this.refreshDataSub) {
      this.refreshDataSub.unsubscribe();
    }
  }

  /**
   * force to get data again for this template
   * @param force boolean (optional)
   * @returns void
   */
  $forceGetData(force?: boolean): void {
    if (!this.templateNode.restUrl) {
      return;
    }

    this.$zone.run(() => {
      this.templateNode.alreadyGotData = false;
      this.setLoading(true);
      this.lastRequestedUrl = null;
      this._cd.detectChanges();
      if (this.templateNode instanceof EntryCollection && this.templateNode.getUpdateTemplateRestUrl()) {
        this.requestApi
          .call(ERequestMethod.GET, `rest/${this.templateNode.getUpdateTemplateRestUrl()}`)
          .subscribe((r) => {
            if (!r) {
              console.error('no data received', r);
              return;
            }

            this.templateNode = this.templateFactory.adapt(r);
            this.init();
            this.templateUiService.clearChangesOfTemplate(this.templateNode);
          });
      } else {
        this.init();
        this.templateUiService.clearChangesOfTemplate(this.templateNode);
      }
    });
  }

  private $forceRefreshData(): void {
    this.templateNode.alreadyGotData = false;
    this.$zone.runOutsideAngular(() => {
      if (this.templateNode instanceof Template && this.templateNode.getUpdateTemplateRestUrl()) {
        // refresh this data
        // this.templateUiService.unmarkFromSave(this.templateNode.getRestUrl() || this.templateNode.getId());
        this._updateTemplate().subscribe((r) => {
          this.$zone.run(() => {
            this.init();
            this.templateUiService.clearChangesOfTemplate(this.templateNode);
          });
        });
      } else {
        this._fetchData(this.templateNode.restUrl, true, true).subscribe((result) => {
          // remove entry-elements if not
          if (this.templateNode instanceof EntryCollection) {
            this.templateNode.setEntryElements(
              this.templateNode
                .getEntryElements()
                .filter((item) => Object.keys(result).find((key) => item.getId() === key))
            );
          }
          this.templateUiService.clearChangesOfTemplate(this.templateNode);
        });
      }
    });
  }

  private ngUpdateTemplate: Subject<void> = new Subject<void>();

  private _updateTemplate(): Observable<any> {
    this.ngUpdateTemplate.next();
    return this.requestApi
      .call(ERequestMethod.GET, `rest/${this.templateNode.getUpdateTemplateRestUrl()}`)
      .pipe(takeUntil(this.ngUpdateTemplate))
      .pipe(
        switchMap((r: any) => {
          this.templateNode.applyObject(this.templateFactory.adapt(r), {
            overwrite: true,
          });
          return this.getData(this.templateNode.getRestUrl(), true).pipe(
            map((a: any) => {
              this.templateApi.updateTemplate(this.templateId, this.templateNode);
              return this.templateNode;
            })
          );
        })
      );
  }

  private _fetchData(url: string, force?: boolean, refresh?: boolean) {
    this.ngRequest.next();
    this.setLoading(refresh ? false : this.isLoading());
    this.errorFlag = false;
    return this.getData(url, force, refresh).pipe(
      takeUntil(this.ngRequest),
      catchError((error) => {
        this.errorMessage = error?.error?.message;
        this.errorFlag = true;
        return throwError(() => error);
      }),
      tap(() => {
        this.$zone.run(() => {
          this.templateNode.alreadyGotData = true;
          this.setLoading(false);
        });
      })
    );
  }

  /**
   * on destroy lifecycle
   */
  ngOnDestroy(): void {
    if (this.reloadSub) {
      this.reloadSub.unsubscribe();
    }
    if (this.refreshDataSub) {
      this.refreshDataSub.unsubscribe();
    }

    this.alive = false;
    this.ngNext.next();
    this.ngNext.complete();
    this.ngRequest.next();
    this.ngRequest.complete();
    this.ngUpdateTemplate.next();
    this.ngUpdateTemplate.complete();
    if (this.templateNode && this.templateNode instanceof Template) {
      this.templateNode.setElementRef(null);
    }

    this.$templateUnregisterApi.unregister(this.templateNode, this.resourceId);
  }

  onChanges(doNotTrack: boolean, event, url: string, el?: any): void {
    // if disabled, do not save any changes
    if (this.isDisabled) {
      return;
    }

    this.$setHighlighted(null);
    const ev = this.getEvent(el, event);
    this.changeEvent.emit(ev);
    this.handleUnsavedData(doNotTrack, ev, url);
    this.$executeChangeActions(ev);
  }

  getEvent(el: any = null, event: any = null): any {
    if (el) {
      return {
        id: el.id,
        value: this.getInnerType(el) || event,
      };
    }

    if (event.fieldType === 'SPREADSHEET') {
      event.elementData.value = event.value;
      event.value = event.elementData;
    }
    return event;
  }

  getInnerType(el: any = null): any {
    if (
      el.fieldType === EFieldType.DATE_TIME_PICKER ||
      el.fieldType === EFieldType.DATE_PICKER ||
      el.fieldType === EFieldType.TIME_PICKER
    ) {
      return el.value;
    }
    return el.getValue();
  }

  /**
   * executes onFrontendValueChangeActions if available
   * @param {any} event any
   * @returns void
   */
  private $executeChangeActions(event: any): void {
    const element = this.templateApi.getElementById(event.id);
    // execute on value change actions

    if (this.checkChangeActionsOfElement(element)) {
      this.actionApi
        .onClick(element, element.getOnFrontendValueChangeActions().slice(), {
          overrideActions: true,
        })
        .subscribe();
    }
  }

  /**
   * Check if changeActions of element are at least 1 and element is valid.
   */
  private checkChangeActionsOfElement(element: Template) {
    if (!element || !element.getOnFrontendValueChangeActions()?.length) {
      return false;
    }

    if (!(element instanceof EntryElement)) {
      return true;
    }

    return element.isValid();
  }

  public getEditedEntries(event: SpreadsheetSaveOutput): void {
    if (!event.delete) {
      this.handleUnsavedData(this.templateNode.doNotTrackChanges, event.saveModel.value, event.saveModel.restUrl);
    } else {
      this.removeUnsavedData(event.saveModel.restUrl);
    }
    // this.templateUiService.sortBySaveOrder();
  }

  removeUnsavedData(url): void {
    this.templateUiService.unmarkFromSave(url || this.templateNode.id);
  }

  handleUnsavedData(doNotTrack: boolean, event, url): void {
    this.unsaved = true;
    const path: string[] = [];

    path.push(url || this.templateNode.id);
    let obj = {};
    if (!obj) {
      obj = {};
    }

    if (this.templateNode instanceof Table) {
      obj = event;
    } else {
      // check if it is an array
      if (Array.isArray(event.value)) {
        obj = { value: [] };
        path.push(event.id);
        for (const v of event.value) {
          obj['value'].push(v);
        }
      } else if (!event.id) {
        obj = event;
      } else {
        obj = event.value;
        path.push(event.id);
      }
    }

    // mark obj with its dataset for save
    // take event.uuid or objects url if no event is given
    if (!doNotTrack && url) {
      this.templateUiService.markForSave(
        obj,
        path,
        this.templateNode,
        this.resourceId || this.templateNode.getResourceId()
      );
    }

    if (this.templateNode.updateElementIds) {
      if (!this.templateUiService.forceUpdateIds) {
        this.templateUiService.forceUpdateIds = [];
      }
      for (const id of this.templateNode.updateElementIds) {
        this.templateUiService.forceUpdateIds.push(id);
      }
    }
    // enable save button
    this.toolbarService.trigger({
      type: ToolbarAction.ENABLE,
      data: ToolbarAction.SAVE,
    });
  }

  adjustValues(el, data) {
    const temp = el;
    for (const key of Object.keys(data)) {
      if (key === temp.id) {
        temp.value = data[key];
        if (temp.fieldType === 'FILE_UPLOAD') {
          temp.elementData = data[key];
        }

        return temp;
      }
    }

    temp.value = data;
    return temp;
  }

  getData(url: any, force?: boolean, refreshData?: boolean): Observable<any> {
    this.errorFlag = false;
    if (
      (!force && this.templateNode.alreadyGotData) ||
      (this.templateNode instanceof Template && this.templateNode.isGetDataAutomatically() === false)
    ) {
      return of(null);
    }

    const cur: any = this.templateNode;

    if (!url || cur.type === ETemplateType.FILE_VIEWER) {
      return of(null);
    }

    if (cur.type === ETemplateType.TABLE) {
      return this.fetchTableData(this.templateNode, -1, -1, '');
    }

    return this.requestApi
      .call(ERequestMethod.GET, `rest/${url}`)
      .pipe(takeWhile(() => this.alive))
      .pipe(
        tap((result) => {
          if (
            typeof this.templateNode.setValue === 'function' ||
            (this.templateNode instanceof Template && this.templateNode.isAutomaticallySetValues())
          ) {
            this.templateFactory.applyValues(this.templateNode, result);
          } else {
            this.templateNode.value = result;
          }
        })
      )
      .pipe(
        catchError((error) => {
          this.errorMessage = error?.error?.message;
          this.errorFlag = true;
          return throwError(error);
        })
      );
  }

  fetchTableData(template: Table, offset: number, limit: number, filterQuery): Observable<any> {
    return this.uiService.postDataAdvanced(template.getRestUrl(), { offset, limit, filterQuery }, {}).pipe(
      tap((result) => {
        this.$zone.run(() => {
          template.setValues(result);
          this._cd.detectChanges();
        });
      })
    );
  }

  setLoading(state: boolean): void {
    if (this.loading === state) {
      return;
    }
    this.$zone.run(() => {
      this.loading = state;
      this._cd.detectChanges();
    });
  }

  isLoading(): boolean {
    return typeof this.loading === 'boolean' ? this.loading : true;
  }

  dragStartPosition: DOMRect | ClientRect;
  /**
   * handles drag start of resizer
   * @param event CdkDragStart
   * @returns void
   */
  public dragResizeStart(event: CdkDragStart): void {
    this.dragStartPosition = event.source.element.nativeElement.getBoundingClientRect();
  }

  /**
   * handles drag end of resizer
   * @param event CdkDragEnd
   * @returns void
   */
  public dragResizeEnd(event: CdkDragEnd): void {
    const distance: number = this.getNewContainerHeight(event.source.element.nativeElement, true);
    event.source._dragRef.reset();

    this._templateResizeService.resize(
      this.templateNode,
      distance,
      parseInt((this.el.nativeElement as HTMLElement).style.height)
    );
  }

  /**
   * calculates new container height
   * @param element HTMLElement
   * @param dragEnd boolean
   * @retunrs number
   */
  private getNewContainerHeight(element: HTMLElement, dragEnd: boolean): number {
    const distance: number = element.getBoundingClientRect().top - this.dragStartPosition.top;
    this.el.nativeElement.style.height = `${this.el.nativeElement.offsetHeight + distance}px`;
    return distance;
  }
}
