import { Injectable, NgZone } from '@angular/core';
import { ConfigService } from '@core/config/config.service';
import {
  BestGantt,
  GanttCanvasShift,
  GanttDataContainer,
  GanttEditPermissions,
  GanttInstanceService,
} from '@gantt/public-api';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { IGanttExternalDropElement } from '../general/gantt-actions/gantt-action-handling/external-drop/external-drop-element.interface';
import { BackendToGanttOriginInputMapperService } from '../general/generator/mapper/gantt-to-gantt-origin.mapper';
import { SaxMsBestGanttDiagramHandler } from './saxms-best-gantt-diagram.handler';
import { SaxMsBestGanttOutputHandler } from './saxms-best-gantt.handler.component';
import { SaxMsBestGanttSettings } from './saxms-best-gantt.settings';

@Injectable()
export class GanttLibService {
  private _bestGantt: BestGantt /* BestGantt */ = null;
  private _ganttDiagramHandler: SaxMsBestGanttDiagramHandler = null;
  private _backendToGanttOriginInputMapper: BackendToGanttOriginInputMapperService =
    new BackendToGanttOriginInputMapperService(this, this._configService);
  private _externalDropSubject: Subject<IGanttExternalDropElement> = new Subject();
  private _afterBestGanttInitialization: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _isAlive = false;

  constructor(
    private _ngZone: NgZone,
    private _configService: ConfigService,
    private _ganttInstanceService: GanttInstanceService
  ) {
    this._backendToGanttOriginInputMapper = new BackendToGanttOriginInputMapperService(this, this._configService);
  }

  public init(ganttInstance: BestGantt): void {
    this._isAlive = true;
    this._bestGantt = ganttInstance;
    this._ganttDiagramHandler = new SaxMsBestGanttDiagramHandler(
      this._bestGantt,
      this._ngZone,
      this._ganttInstanceService
    );
  }

  public destroy(): void {
    this._isAlive = false;
  }

  public updateSettings(settings: SaxMsBestGanttSettings): void {
    if (!this._ganttDiagramHandler) {
      return;
    }
    this._ganttDiagramHandler.updateSettings(settings);
  }

  public build(
    data: GanttDataContainer,
    restricitons: GanttEditPermissions,
    ganttSettings: SaxMsBestGanttSettings,
    outputHandler: SaxMsBestGanttOutputHandler
  ): void {
    if (!this._ganttDiagramHandler) {
      return;
    }
    this._ganttDiagramHandler.build(data, restricitons, ganttSettings, outputHandler);
    this.bestGantt.setCollapseAllRowsButton(this._configService.access().templates.Gantt.globalCollapseRowBtn);
    this._afterBestGanttInitialization.next(true);
  }

  public applyGanttRestrictions(restrictions: GanttEditPermissions): void {
    this._ganttDiagramHandler.applyRestrictions(restrictions);
  }

  public listenForExternalDropEvent(): Observable<IGanttExternalDropElement> {
    return this._externalDropSubject.asObservable();
  }

  public triggerExternalDrop(targetShiftData: GanttCanvasShift, resourceIds: string[]): void {
    const selectedShifts: GanttCanvasShift[] = this.bestGantt.getSelectionBoxFacade().getSelectedShifts();
    let selectedValues: string[] = [];
    if (selectedShifts.length) {
      // shifts are selected
      if (selectedShifts.find((shift) => shift.id === targetShiftData.id)) {
        // target shift is also selected -> address all selected shifts as target
        selectedValues = selectedShifts.map((shift) => shift.id);
      } else {
        selectedValues = [targetShiftData.id]; // target shift is not selected -> don't address selected shifts
      }
    } else {
      // no selected shifts -> only target shift is addressed
      selectedValues = [targetShiftData.id];
    }
    const selectedOriginValues = selectedValues.map((id) =>
      this._backendToGanttOriginInputMapper.mapShiftIdToOriginId(id)
    );
    this._externalDropSubject.next({ selectedValues: selectedOriginValues, selectedResources: resourceIds });
  }

  get bestGantt(): BestGantt {
    return this._bestGantt;
  }

  get isAlive(): boolean {
    return this._isAlive;
  }

  get ganttInstanceService(): GanttInstanceService {
    return this._ganttInstanceService;
  }

  get backendToGanttOriginInputMapper(): BackendToGanttOriginInputMapperService {
    return this._backendToGanttOriginInputMapper;
  }

  get ngZone(): NgZone {
    return this._ngZone;
  }

  get afterInit(): Observable<boolean> {
    return this._afterBestGanttInitialization.asObservable();
  }
}
