import * as d3 from 'd3';
import { GanttConfig } from '../../config/gantt-config';
import {
  GanttXAxisFormatGeneral,
  TimeFormatterAxisFormat,
  TimeFormatterTimeFormat,
} from '../../x-axis/axis-formats/x-axis-format-general';
import { BestGanttPlugIn } from '../gantt-plug-in';
import { GanttXAxisZoomTransformatorEvent } from './undo-redo/x-axis-zoom-transformator-event';
import { GanttXAxisZoomConfig } from './x-axis-zoom-transformator-config';

/**
 * Manipulates horizontal zoom level and axis formatting.
 * @keywords plugin, executer, xaxis, x axis, time, zoom, scale, set, fix, start, end, format
 * @plugin time-manipulator
 * @class
 * @constructor
 * @extends BestGanttPlugIn
 *
 * @requires BestGanttPlugIn
 */
export class GanttXAxisZoomTransformator extends BestGanttPlugIn {
  config: GanttXAxisZoomConfig;
  ganttConfig: GanttConfig;
  dayWidth: number;
  weekWidth: number;
  originalTimeFormats: any = null;

  constructor() {
    super(); // call super-constructor

    /**
     * @type {BestGantt}
     */
    this.ganttDiagram = null;

    this.config = new GanttXAxisZoomConfig();
    this.ganttConfig = null;

    this.dayWidth = 15;
    this.weekWidth = 15;
  }

  /**
   * @override
   */
  initPlugIn(ganttDiagram) {
    const s = this;
    s.ganttDiagram = ganttDiagram;
    s.ganttConfig = ganttDiagram.getConfig();
  }

  /**
   * Remove of plugin. Part of plugin lifecycle.
   */
  removePlugIn() {
    const s = this;
    s.removeFormat();
  }

  /**
   * @param {TimeFormatterTimeFormat} globalTimeFormat New axis timeformat which is valid for all zoom levels.
   * @param {number} [timeSpanMilliseconds] Time span of new zoom level.
   * @param {number} [axisTickDistance] Current distance between two axis ticks.
   */
  setFormatToTimeSpan(
    globalTimeFormat: TimeFormatterTimeFormat,
    timeSpanMilliseconds: number,
    axisTickDistance: number
  ) {
    const s = this;
    const currentDomain = s.ganttDiagram.getXAxisBuilder().getCurrentScale().domain();
    const currentStart = currentDomain[0].getTime();
    const currentEnd = currentDomain[1].getTime();
    const timeFormats = [];
    for (let i = 0; i < globalTimeFormat.time.length; i++) {
      const newFormat = new GanttXAxisFormatGeneral([
        {
          value: globalTimeFormat.value,
          time: [
            new TimeFormatterAxisFormat(
              globalTimeFormat.time[i].format,
              globalTimeFormat.time[i].ticks,
              globalTimeFormat.time[i].unit
            ),
          ],
        },
      ]);

      typeof globalTimeFormat.time[i].css !== 'undefined'
        ? newFormat.setCssClassName(globalTimeFormat.time[i].css)
        : null;
      typeof globalTimeFormat.time[i].cssText !== 'undefined'
        ? newFormat.setCssClassNameText(globalTimeFormat.time[i].cssText)
        : null;
      typeof globalTimeFormat.time[i].height !== 'undefined'
        ? newFormat.setHeight(globalTimeFormat.time[i].height)
        : null;
      typeof globalTimeFormat.time[i].fontSize !== 'undefined'
        ? newFormat.setFontSize(globalTimeFormat.time[i].fontSize)
        : null;
      typeof globalTimeFormat.time[i].tickSize !== 'undefined'
        ? newFormat.setTickSize(globalTimeFormat.time[i].tickSize)
        : null;
      typeof globalTimeFormat.time[i].tickSizeText !== 'undefined'
        ? newFormat.setTickSizeText(globalTimeFormat.time[i].tickSizeText)
        : null;
      typeof globalTimeFormat.time[i].renderVerticalLinesOnShifts !== 'undefined'
        ? newFormat.setRenderVerticalLines(globalTimeFormat.time[i].renderVerticalLinesOnShifts)
        : null;
      timeFormats.push(newFormat);
    }

    if (!s.originalTimeFormats) s.originalTimeFormats = s.ganttDiagram.getConfig().xAxes();

    s.ganttDiagram.getConfig().setXAxesConfig(timeFormats);

    s.ganttDiagram.getXAxisBuilder().setGlobalTimeFormat(globalTimeFormat);

    // refresh zoom
    s.ganttDiagram.getXAxisBuilder().translateToTimeSpanAdapted(new Date(currentStart), new Date(currentEnd));
  }

  /**
   * Sets x axis format to day format.
   */
  setFormatToDays() {
    const s = this;
    s.ganttDiagram
      .getHistory()
      .addNewEvent('setFormatToDays', new GanttXAxisZoomTransformatorEvent(), this, s.getActiveFormat());
    s.ganttConfig.setXAxisFormat('DAY');
    this._removeWeekViewLabelsFromHeader();
    this._addDayViewLabelsToHeader();

    s.setFormatToTimeSpan(
      {
        value: 55,
        time: [
          new TimeFormatterAxisFormat('%B %Y', d3.timeMonth.every(1), 'MONTH'),
          new TimeFormatterAxisFormat('%V', d3.timeMonday.every(1), 'CALENDAR_WEEK'),
          new TimeFormatterAxisFormat('%d', d3.timeDay.every(1), 'DAY'),
        ],
      },
      86400000,
      s.dayWidth
    );
  }

  /**
   * Sets x axis format to week format.
   */
  setFormatToWeeks() {
    const s = this;
    s.ganttDiagram
      .getHistory()
      .addNewEvent('setFormatToWeeks', new GanttXAxisZoomTransformatorEvent(), this, s.getActiveFormat());
    s.ganttConfig.setXAxisFormat('WEEK');
    this._removeDayViewLabelsFromHeader();
    this._addWeekViewLabelsToHeader();

    s.setFormatToTimeSpan(
      {
        value: 5.3,
        time: [
          new TimeFormatterAxisFormat('Quartal', d3.timeMonth.every(3), 'MONTH'),
          new TimeFormatterAxisFormat('%B %y', d3.timeMonth.every(1), 'MONTH'),
          new TimeFormatterAxisFormat('%V', d3.timeMonday.every(1), 'CALENDAR_WEEK'),
        ],
      },
      604800000,
      s.weekWidth
    );
  }

  /**
   * Removes global x axis format and brings back original x axis format.
   */
  removeFormat() {
    const s = this;
    const currentDomain = s.ganttDiagram.getXAxisBuilder().getCurrentScale().domain();
    const currentStart = currentDomain[0].getTime();
    const currentEnd = currentDomain[1].getTime();

    s.ganttDiagram
      .getHistory()
      .addNewEvent('resetFormat', new GanttXAxisZoomTransformatorEvent(), this, s.getActiveFormat());
    s.ganttConfig.setXAxisFormat(null);
    this._removeWeekViewLabelsFromHeader();
    this._removeDayViewLabelsFromHeader();

    s.ganttDiagram.getXAxisBuilder().removeGlobalTimeFormat();

    if (s.originalTimeFormats) {
      s.ganttDiagram.getConfig().setXAxesConfig(s.originalTimeFormats);
    }

    // refresh zoom
    s.ganttDiagram.getXAxisBuilder().translateToTimeSpanAdapted(new Date(currentStart), new Date(currentEnd));
  }

  /**
   * Sets start and end date of current zoom scale.
   * @param {Date} timeStart Start timepoint.
   * @param {Date} timeEnd End timepoint.
   */
  setZoomToTimeSpan(timeStart, timeEnd) {
    const s = this;
    let start = new Date(timeStart);
    let end = new Date(timeEnd);

    if (s.ganttDiagram.getDataHandler().getOriginDataset().minValue > start) {
      start = s.ganttDiagram.getDataHandler().getOriginDataset().minValue;
    }
    if (s.ganttDiagram.getDataHandler().getOriginDataset().maxValue < end) {
      end = s.ganttDiagram.getDataHandler().getOriginDataset().maxValue;
    }

    s.ganttDiagram
      .getHistory()
      .addNewEvent(
        'setZoomToTimeSpan',
        new GanttXAxisZoomTransformatorEvent(),
        this,
        JSON.parse(
          JSON.stringify(s.ganttDiagram.getXAxisBuilder().getCurrentScale().domain())
        ) /*, new Date(start), new Date(end)*/
      );

    s.ganttDiagram.getXAxisBuilder().zoomToTimeSpan(new Date(start), new Date(end), false);
  }

  /**
   * Adds labels of time formats to the y axis wrapper in week view.
   */
  private _addWeekViewLabelsToHeader() {
    const cssStylingKW = {
      position: 'absolute',
      right: '10px',
      top: '52px',
    };

    const cssStylingM = {
      position: 'absolute',
      right: '10px',
      top: '28px',
    };

    const cssStylingQ = {
      position: 'absolute',
      right: '10px',
      top: '3px',
    };

    this.ganttDiagram
      .getYAxisHeadBuilder()
      .addTextLine('week_view_format_kw', 'week_view_format_kw', 'KW', cssStylingKW);
    this.ganttDiagram.getYAxisHeadBuilder().addTextLine('week_view_format_m', 'week_view_format_m', 'M', cssStylingM);
    this.ganttDiagram.getYAxisHeadBuilder().addTextLine('week_view_format_q', 'week_view_format_q', 'Q', cssStylingQ);
  }

  /**
   * Adds labels of time formats to the y axis wrapper in day view.
   */
  private _addDayViewLabelsToHeader() {
    const cssStylingD = {
      position: 'absolute',
      right: '10px',
      top: '52px',
    };

    const cssStylingKW = {
      position: 'absolute',
      right: '10px',
      top: '28px',
    };

    const cssStylingM = {
      position: 'absolute',
      right: '10px',
      top: '3px',
    };

    this.ganttDiagram.getYAxisHeadBuilder().addTextLine('day_view_format_kw', 'day_view_format_kw', 'KW', cssStylingKW);
    this.ganttDiagram.getYAxisHeadBuilder().addTextLine('day_view_format_m', 'day_view_format_m', 'M', cssStylingM);
    this.ganttDiagram.getYAxisHeadBuilder().addTextLine('day_view_format_d', 'day_view_format_d', 'T', cssStylingD);
  }

  /**
   * Removes week view labels form y-axis header.
   */
  private _removeWeekViewLabelsFromHeader() {
    this.ganttDiagram.getYAxisHeadBuilder().removeTextLineById('week_view_format_kw');
    this.ganttDiagram.getYAxisHeadBuilder().removeTextLineById('week_view_format_m');
    this.ganttDiagram.getYAxisHeadBuilder().removeTextLineById('week_view_format_q');
  }

  /**
   * Removes day view labels form y-axis header.
   */
  private _removeDayViewLabelsFromHeader() {
    this.ganttDiagram.getYAxisHeadBuilder().removeTextLineById('day_view_format_kw');
    this.ganttDiagram.getYAxisHeadBuilder().removeTextLineById('day_view_format_m');
    this.ganttDiagram.getYAxisHeadBuilder().removeTextLineById('day_view_format_d');
  }

  //
  // GETTER & SETTER
  //

  /**
   * @typedef {"WEEK" | "DAY" | string | null} AxisFormat
   */
  /**
   * @return {AxisFormat} Current axis format.
   */
  getActiveFormat() {
    return this.ganttConfig.getXAxisFormat();
  }
}
