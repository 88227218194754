import { AuthenticationResult } from '@azure/msal-browser';
import { Credentials } from '@core/authentication/credentials';

export namespace AuthActions {
  export interface LoginDto {
    username: string;
    password: string;
    rememberMe?: boolean;
  }

  export class Login {
    static readonly type = '[AuthActions] Login';
    constructor(public payload: LoginDto) {}
  }

  export class OverrideCredentials {
    static readonly type = '[AuthActions] Override Credentials';
    constructor(public payload: Credentials) {}
  }

  export class SignleSignOn {
    static readonly type = '[AuthActions] Signle Sign On';
    constructor(public payload: AuthenticationResult) {}
  }

  export class Refresh {
    static readonly type = '[AuthActions] Refresh';
    constructor() {}
  }

  export class Logout {
    static readonly type = '[AuthActions] Logout';
    constructor() {}
  }

  export class GetUser {
    static readonly type = '[AuthActions] Get User';
    constructor() {}
  }
}
