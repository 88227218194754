import * as d3 from 'd3';
import { GanttConfig } from '../../config/gantt-config';
import { TooltipBuilder } from '../../tooltip/tooltip-builder';
import { GanttYAxisHeadButtonData } from './buttons/head-y-axis-button';

/**
 * Builder for y axis top container which holds headline.
 * @keywords y axis, yaxis, head, container, headline, top, left
 * @class
 * @constructor
 * @param {GanttConfig} config
 * @param {HTMLNode} parentNode
 * @param {TooltipBuilder} tooltipBuilder
 */
export class GanttYAxisHead {
  config: GanttConfig;
  canvas: d3.Selection<any, any, null, undefined>;
  parentNode: any;
  tooltipBuilder: TooltipBuilder;
  private _headline: d3.Selection<any, any, null, undefined>;
  private _buttonWrapper: d3.Selection<HTMLDivElement, any, null, undefined>;
  private _textLineWrapper: d3.Selection<SVGGElement, any, null, undefined>;
  private _divWrapper: d3.Selection<HTMLDivElement, any, null, undefined>;

  constructor(config, parentNode: HTMLElement, tooltipBuilder) {
    this.config = config;
    this.canvas = null;
    this.parentNode = parentNode;
    this.tooltipBuilder = tooltipBuilder;
  }

  /**
   * Builds container to y axis top.
   * @keywords init, initialize, yaxis, y axis, headline, container
   */
  init() {
    const s = this;

    s._headline = d3.select(s.parentNode).append('h1');

    s._buttonWrapper = d3
      .select(s.parentNode)
      .append('div')
      .attr('class', 'gantt_x-axis_placeholder-btn-wrapper')
      .style('display', s.config.isXAxisVisible() ? 'block' : 'none');

    s._textLineWrapper = d3
      .select(s.parentNode)
      .append('div')
      .attr('class', 'gantt_x-axis_placeholder-textline-wrapper')
      .append('g')
      .attr('class', 'gantt_x-axis_placeholder-textline-wrapper-group')
      .style('display', s.config.isXAxisVisible() ? 'block' : 'none');

    s.config.addXAxesConfigChangedCallback('hideBtnWrapper', () =>
      s._buttonWrapper?.style('display', s.config.isXAxisVisible() ? 'block' : 'none')
    );
    s.config.addXAxesConfigChangedCallback('hideTextLineWrapper', () =>
      s._textLineWrapper?.style('display', s.config.isXAxisVisible() ? 'block' : 'none')
    );

    s._divWrapper = d3.select(s.parentNode).append('div').attr('class', 'gantt_x-axis_placeholder-div-wrapper');
  }
  /**
   * Adds button to y axis top.
   * @keywords button, y axis, yaxis, top, headline, container, click
   * @param {GanttYAxisHeadButton} button Button to be added.
   */
  addButton(button: GanttYAxisHeadButtonData) {
    const s = this;

    s._buttonWrapper
      .selectAll('dummy')
      .data([{ buttonData: button, currentState: 0 }])
      .enter()
      .append('div')
      .attr('class', 'gantt_x-axis_placeholder-btn ' + button.getClassName(0))
      .text(button.getContent(0))
      .on('mouseenter', function (event, d) {
        if (button.getTooltipText(d.currentState)) {
          const mouse = [event.x, event.y];
          s.tooltipBuilder.addTooltipToHTMLBody(
            mouse[0],
            mouse[1],
            button.getTooltipText(d.currentState) || '',
            d3.select(this).node()
          );
        }
      })
      .on('mouseleave', function (event, d) {
        if (button.getTooltipText(d.currentState)) {
          s.tooltipBuilder.removeAllTooltips();
        }
      })
      .on('click', function (event, d) {
        if (button.getTooltipText(d.currentState)) {
          s.tooltipBuilder.removeAllTooltips();
        }
        button.callback(d.currentState);
        if (d.currentState < button.getStateCount() - 1) d.currentState++;
        else d.currentState = 0;
        d3.select(this).attr(
          'class',
          button.getClassName(d.currentState)
            ? 'gantt_x-axis_placeholder-btn ' + button.getClassName(d.currentState)
            : 'gantt_x-axis_placeholder-btn'
        );
        d3.select(this).text(button.getContent(d.currentState));
      });
  }

  /**
   * Adds text line to y-axis head.
   * @param {string} className Class name of new text line
   * @param {string} id ID of new text line
   * @param {string} text Text of new text line
   * @param {object} styling Style object of new text line
   */
  addTextLine(className, id, text, styling) {
    const s = this;
    if (!id) id = 'text_id_' + new Date().getTime();

    const textWrapper = s._textLineWrapper
      .selectAll('dummy')
      .data([{ id: id, open: null }])
      .enter()
      .append('p')
      .attr('class', className)
      .text(text);

    if (styling) {
      const styleEntries: [string, any][] = Object.entries(styling);
      for (const entry of styleEntries) {
        textWrapper.style(entry[0], entry[1]);
      }
    }
  }

  /**
   * Adds a customizable div to y-axis head.
   * @param {string} [className=""] Class name of new div.
   * @param {string} [id=null] ID of new div.
   * @returns {d3.Selection<HTMLDivElement, any, null, undefined>} Created div.
   */
  public addDiv(className: string = null, id: string = null): d3.Selection<HTMLDivElement, any, null, undefined> {
    const s = this;
    if (!id) id = 'div_id_' + new Date().getTime();

    const newDiv = s._divWrapper
      .append('div')
      .data([{ id: id, open: null }])
      .attr('class', className ? 'gantt_x-axis_placeholder-div ' + className : 'gantt_x-axis_placeholder-div');

    return newDiv;
  }

  /**
   * Removes text lines by the given id.
   * @param {string} id ID of text line
   */
  removeTextLineById(id) {
    this._textLineWrapper
      .selectAll('p')
      .filter(function (d: any) {
        return d.id == id;
      })
      .remove();
  }

  /**
   * Remove button from y axis top container by given id.
   * @keywords remove, delete, clear, button, yaxis, y axis
   * @param {string} id Id of button.
   */
  public removeGanttMenuItem(id: string): void {
    const s = this;
    s._buttonWrapper.selectAll('.gantt_x-axis_placeholder-btn').each(function (d: IGanttYAxisHeadButtonDataContainer) {
      if (d.buttonData.getId() == id) {
        d.buttonData.destroy();
        d3.select(this).remove();
      }
    });
  }

  public removeAllGanttMenuItems(): void {
    const s = this;
    s._buttonWrapper
      .selectAll('.gantt_x-axis_placeholder-btn')
      .each((d: IGanttYAxisHeadButtonDataContainer) => d.buttonData.destroy());
    s._buttonWrapper.remove();
    s._buttonWrapper = null;
  }

  /**
   * Remove all content from y axis top container.
   * @keywords remove, delete, clear, all, headline, buttons, container
   */
  public removeAll(): void {
    // remove headline if it exists
    if (this._headline) {
      this._headline.remove();
      this._headline = null;
    }
    // remove y axis head buttons if they exist
    if (this._buttonWrapper) {
      this._buttonWrapper
        .selectAll('.gantt_x-axis_placeholder-btn')
        .each((d: IGanttYAxisHeadButtonDataContainer) => d.buttonData.destroy());
      this._buttonWrapper?.remove();
      this._buttonWrapper = null;
    }
  }

  //
  // GETTER & SETTER
  //
  setHeadline(text) {
    this._headline.html(text);
  }

  public getParentNode(): HTMLElement {
    return this.parentNode;
  }

  updateHeight() {
    const s = this;
    d3.select(s.parentNode).style('height', parseInt(s.config.xAxisHeight() + s.config.xAxisScrollBarHeight() + 'px'));
  }
}

/**
 * Data container to store y axis head button data in d3 selection.
 */
interface IGanttYAxisHeadButtonDataContainer {
  buttonData: GanttYAxisHeadButtonData;
  currentState: number;
}
