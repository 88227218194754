export enum EPredefinedAction {
  CREATE = 'create',
  ENABLE_EDIT = 'enableedit',
  TOGGLE_DEFAULT_FILTER = 'toggledefaultfilter',
  TOGGLE_PLUGIN_MODE_EDIT = 'togglepluginmodeedit',
  TOGGLE_PLUGIN_MODE_CREATE = 'togglepluginmodecreate',
  TOGGLE_PLUGIN_MODE_DELETE = 'togglepluginmodedelete',
  RESET_ZOOM_GANTT = 'resetzoom',
  DELETE = 'delete',
  CANCEL = 'cancel',
  CHECK_EXTERNAL_CONDITIONS = 'check_external_conditions',
  SAVE = 'save',
  GRAPH_API_ACTION = 'GRAPH_API_ACTION',
  CHECK_BUTTON = 'check_button',
  SHOW_MESSAGE = 'show_message',
  CLONE = 'clone',
  OPEN_WITH_CREATE_NAME_DIALOG = 'open_with_create_name_dialog',
  OPEN_WITH_CONFIRM_DELETE_DIALOG = 'open_with_confirm_delete_dialog',
  EDIT = 'edit',
  SETTINGS = 'settings',
  CLOSE_LIGHTBOX = 'closelightbox',
  CLOSE_WIZARD = 'closewizard',
  CANCEL_LIGHTBOX = 'cancellightbox',
  TOGGLE_USERSETTINGS = 'toggle_usersettings',
  TOGGLE_MESSAGECENTER = 'template.toolbaritem.messagecenter',
  OPEN_TOOLBAR_GROUP = 'open_toolbar_group',
  TOGGLE_TOOLBAR_FAVORITE_ELEMENT = 'toggle_toolbar_favorite_element',
  FILE_UPLOAD = 'FILE_UPLOAD',
  DISCARD_CHANGES = 'DISCARD_CHANGES',
  DISCARD_CHANGES_BY_ID = 'discard_changes_by_id',
  UPDATE_ELEMENT = 'update_element',
  REFRESH_ELEMENT = 'refresh_element',
  HOTSPOT_RECTANGLETEMPLATE = 'hotspot.rectangleTemplate',
  HOTSPOT_TRIANGLETEMPLATE = 'hotspot.triangleTemplate',
  HOTSPOT_POLYGONLINEMODE = 'hotspot.polygonLineMode',
  HOTSPOT_POLYGONINTERPLOTATIONMODE = 'hotspot.polygonInterpolationMode',
  HOTSPOT_POLYGONEDIT = 'hotspot.polygonEdit',
  HOTSPOT_POLYGONDELETE = 'hotspot.polygonDelete',
  HOTSPOT_VERTEXDELETE = 'hotspot.vertexDelete',
  LOGOUT = 'logout',
  UPDATE_INDEX_CARD_CONFIG = 'updateindexcard',
  REGISTER_TEMPLATE = 'register_template',
  CLOSE_CONTENT = 'closeContent',
  FORCE_RELOAD_SECOND_TREE_COLUMN = 'forcedreloadtree',
  OPEN_WEB_LINK = 'OPEN_WEB_LINK',
  GANTT_ENABLE_EDIT_MODE = 'GANTT_EDIT_MODE',
  MAP_JUMP_TO_LOCATION = 'jump_to_location',
  MAP_ZOOM_IN = 'map_contextmenu_zoom_in',
  MAP_ZOOM_OUT = 'map_contextmenu_zoom_out',
  MAP_CREATE_MARKER = 'create_new_marker',
  UPDATE_GANTT = 'updateGantt',
  GET_AVAILABLE_RESOURCE_ENTRIES = 'getavailableresourceentries',
  VALIDATE = 'validate',
  GANTT_TOGGLE_BROKEN_CONSTRAINTS = 'togglebrokenconstraints',
  TRIGGER_LOADING_INDICATOR = 'trigger_loading',
  MARK_FOR_SAVE = 'MARK_FOR_SAVE',
}
