import * as d3 from 'd3';
import { Observable, Subject, takeUntil } from 'rxjs';
import { GanttCallBackStackExecuter } from '../callback-tools/callback-stack-executer';
import { GanttConfig } from '../config/gantt-config';
import { GanttCanvasMilestone, GanttCanvasShift } from '../data-handler/data-structure/data-structure';
import { NodeProportionsState } from '../html-structure/node-proportion-state/node-proportion-state';
import { NodeProportionsStateConnector } from '../html-structure/node-proportion-state/node-proportion-state-connector';
import { EGanttScrollContainer } from '../html-structure/scroll-container.enum';
import { VerticalScrollHandler } from '../vertical-scroll/vertical-scroll-handler';
import { IGanttShiftsBuilding } from './shift-build-strategies/shift-build-interface';
import { ShiftBuilder } from './shift-builder';

/**
 * Class which handles shift canvas rendering during scrolling, zooming
 * and all other performance intensive rendering by using canvas instead of svg.
 * @class
 * @constructor
 * @param {HTMLNode} parentNode
 * @param {GanttConfig} config
 * @param {IGanttShiftsBuilding} shiftRenderStrategy
 * @param {ShiftBuilder} shiftBuilder
 */
export class GanttShiftCanvasGenerator {
  parentNode: any;
  private _nodeProportionsState: NodeProportionsStateConnector;
  verticalScrollHandler: VerticalScrollHandler;
  canvas: d3.Selection<any, any, null, undefined>;
  config: GanttConfig;
  shiftBuilder: ShiftBuilder;
  shiftRenderStrategy: IGanttShiftsBuilding;
  callBack: any;

  private _onDestroySubject: Subject<void> = new Subject<void>();

  constructor(
    parentNode,
    config,
    shiftRenderStrategy,
    shiftBuilder,
    nodeProportionsState: NodeProportionsState,
    verticalScrollHandler,
    scrollContainerId: EGanttScrollContainer
  ) {
    this.parentNode = parentNode;
    this._nodeProportionsState = new NodeProportionsStateConnector(nodeProportionsState, scrollContainerId);
    this.verticalScrollHandler = verticalScrollHandler;
    this.canvas = null;
    this.config = config;
    this.shiftBuilder = shiftBuilder;
    this.shiftRenderStrategy = shiftRenderStrategy;

    this.callBack = {
      afterRenderShifts: {},
      performanceRenderShiftsBefore: {},
      performanceRenderShiftsAfter: {},
    };
  }

  /**
   * Builds canvas node and refreshs size of node.
   * Should be used initially.
   */
  init() {
    const s = this;
    s._buildCanvas();
    s._addScrollCallback();
    s.refreshCanvasSize();
  }

  /**
   * Rebuilds canvas node.
   * @private
   */
  private _buildCanvas() {
    const s = this;
    s.removeCanvas();
    s.canvas = d3.select(s.parentNode).append('canvas').attr('class', 'animation-canvas');
  }

  /**
   * Move canvas to viewport.
   */
  private _addScrollCallback(): void {
    this.verticalScrollHandler.onScrollVerticalUpdate.pipe(takeUntil(this.onDestroy)).subscribe(() => {
      this.canvas.style('top', this._nodeProportionsState.getScrollTopPosition() + 'px');
    });
  }

  /**
   * @param {GanttCanvasShift[]} shiftRenderDataset
   * @param {GanttCanvasMilestone[]} milestoneRenderDataset
   * @param {Transform} scale D3 zoom transform data with x, y, k value.
   * @param {number} [yTranslationMilestones] Additional vertical translation of all given milestones.
   * @param {number} [yTranslationShifts] Additional vertical translation of all given shifts.
   */
  rerenderShiftArea(
    shiftRenderDataset: GanttCanvasShift[],
    milestoneRenderDataset: GanttCanvasMilestone[],
    scale: d3.ZoomTransform,
    yTranslationMilestones = 0,
    yTranslationShifts = 0
  ) {
    const s = this;
    s.removeCanvasContent();

    // if fastRenderingOnScrollAndZoom is active -> builds only rects without opacity, round corners, etc..
    if (s.config.isFastRenderingOnScrollAndZoom()) {
      GanttCallBackStackExecuter.execute(
        s.callBack.performanceRenderShiftsBefore,
        new GanttShiftCanvasGeneratorRenderCallback(
          s.canvas,
          shiftRenderDataset,
          scale,
          s._nodeProportionsState.getScrollTopPosition()
        )
      );
      s._addShiftBlocks(shiftRenderDataset, scale, yTranslationShifts);
      GanttCallBackStackExecuter.execute(
        s.callBack.performanceRenderShiftsAfter,
        new GanttShiftCanvasGeneratorRenderCallback(
          s.canvas,
          shiftRenderDataset,
          scale,
          s._nodeProportionsState.getScrollTopPosition()
        )
      );
    } else {
      s.shiftRenderStrategy.renderShifts(shiftRenderDataset, s.parentNode, s.shiftBuilder);
      s.shiftBuilder.renderShifts(shiftRenderDataset, s.parentNode);
    }
    GanttCallBackStackExecuter.execute(
      s.callBack.afterRenderShifts,
      new GanttShiftCanvasGeneratorRenderCallback(
        s.canvas,
        shiftRenderDataset,
        scale,
        s._nodeProportionsState.getScrollTopPosition()
      )
    );
  }

  /**
   * @private
   * @param {GanttCanvasShift[]} shiftRenderDataset
   * @param {Transform} scale D3 zoom transform data with x, y, k value.
   * @param {number} [yTranslationShifts] Additional vertical translation of all given shifts.
   */
  private _addShiftBlocks(shiftRenderDataset, scale, yTranslationShifts = 0) {
    const s = this;
    const shiftBlock = s.canvas.node().getContext('2d');
    for (let i = 0; i < shiftRenderDataset.length; i++) {
      const canvasShiftData = shiftRenderDataset[i];
      if (canvasShiftData.width < 0) continue;
      if (canvasShiftData.hasOwnProperty('noRender') && canvasShiftData.noRender.length) continue;
      shiftBlock.fillStyle = s._extractShiftColor(canvasShiftData);
      shiftBlock.setTransform(1, 0, 0, 1, 0, 0);
      shiftBlock.translate(scale.x, 0);
      shiftBlock.scale(scale.k, 1);
      shiftBlock.fillRect(
        canvasShiftData.x,
        canvasShiftData.y - s._nodeProportionsState.getScrollTopPosition() + yTranslationShifts,
        canvasShiftData.width,
        canvasShiftData.height
      );
    }
  }

  /**
   * Sets canvas size.
   * If no proportion is explicitly given, the size of parent nodes will be used.
   * @param {number} [width] New width of canvas.
   * @param {number} [height] New height of canvas.
   */
  refreshCanvasSize(width = null, height = null) {
    const s = this;
    if (!width) width = s._nodeProportionsState.getShiftViewPortProportions().width;
    if (!height) height = s._nodeProportionsState.getShiftViewPortProportions().height;
    s.canvas.attr('width', width).attr('height', height);
  }

  /**
   * Calculates color for shift block by given shift data.
   * @private
   * @param {GanttCanvasShift} shiftData Canvas data of shifts.
   */
  private _extractShiftColor(shiftData) {
    if (shiftData.highlighted) return shiftData.highlighted;
    if (shiftData.selected) return shiftData.selected;
    if (shiftData.color) return shiftData.color;
    return '#000000';
  }

  /**
   * Calculates color for milestones by given milestone data.
   * @private
   * @param {GanttCanvasMilestone} milestoneData
   */
  private _extractMilestoneColor(milestoneData) {
    if (milestoneData.color) return milestoneData.color;
    return '#000000';
  }

  /**
   * Clears complete canvas.
   */
  removeCanvasContent() {
    const s = this;
    const context = s.canvas.node().getContext('2d');
    const proportions = s._nodeProportionsState.getShiftViewPortProportions();

    context.setTransform(1, 0, 0, 1, 0, 0);
    context.clearRect(0, 0, proportions.width, proportions.height);
  }

  /**
   * Removes cnavas HTML-node.
   */
  removeCanvas() {
    const s = this;
    if (s.canvas) s.canvas.remove();
  }

  public destroy(): void {
    this._onDestroySubject.next();
    this._onDestroySubject.complete();
  }

  //
  // OBSERVABLES
  //

  /**
   * Observable which gets triggered when the instance gets destroyed.
   */
  private get onDestroy(): Observable<void> {
    return this._onDestroySubject.asObservable();
  }

  //
  //  CALLBACKS
  //

  afterRenderShiftsCallback(id, func) {
    this.callBack.afterRenderShifts[id] = func;
  }
  removeAfterRenderShiftsCallback(id) {
    delete this.callBack.afterRenderShifts[id];
  }

  beforePerformanceRenderShiftsCallback(id, func) {
    this.callBack.performanceRenderShiftsBefore[id] = func;
  }
  removeBeforePerformanceRenderShiftsCallback(id) {
    delete this.callBack.performanceRenderShiftsBefore[id];
  }

  afterPerformanceRenderShiftsCallback(id, func) {
    this.callBack.performanceRenderShiftsAfter[id] = func;
  }
  removeAfterPerformanceRenderShiftsCallback(id) {
    delete this.callBack.performanceRenderShiftsAfter[id];
  }
}

export class GanttShiftCanvasGeneratorRenderCallback {
  constructor(
    public canvas: d3.Selection<any, any, null, undefined>,
    public shiftRenderDataset: GanttCanvasShift[],
    public scale: d3.ZoomTransform,
    public scrollTop: number
  ) {}
}
