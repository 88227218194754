import { BestGanttPlugIn } from '../gantt-plug-in';
import { GanttShiftComponents } from './shift-components';
import { SuperBlockBuilder } from './superBlockBuilder';

/**
 * Handles multiple component grouping plugins.
 * @keywords bundle, handler, shift, component, multiple, dashboard, all
 * @class
 * @constructor
 * @extends BestGanttPlugIn
 * @property {GanttShiftComponentGroupDataItem[]} shiftComponentExecuters
 * @property {GanttShiftComponentGroupDataItem} activePlugIn
 *
 * @requires BestGanttPlugIn
 * @requires GanttShiftComponents
 * @requires SuperBlockBuilder
 */
export class GanttShiftComponentGroup extends BestGanttPlugIn {
  shiftComponentExecuters: GanttShiftComponentGroupDataItem[];
  superBlockBackendData: any;
  activePlugIn: GanttShiftComponentGroupDataItem;

  constructor() {
    super(); // call super-constructor

    /**
     * @type {BestGantt}
     */
    this.ganttDiagram = null;
    this.shiftComponentExecuters = [];
    this.superBlockBackendData = {};
    this.activePlugIn = null;
  }

  /**
   * @override
   */
  initPlugIn(ganttDiagram) {
    const s = this;
    s.ganttDiagram = ganttDiagram;
  }

  /**
   * Updates plugin to react to new conditions inside gantt.
   * @keywords init, initialize, plugin, plug in, lifecycle, update, refresh
   */
  update() {
    const s = this;
    for (let i = 0; i < s.shiftComponentExecuters.length; i++) {
      s.shiftComponentExecuters[i].plugin.update();
    }
  }

  /**
   * Adds given shift component executer plugin to group handler.
   * @param {string} id Save Id to get access to plugin.
   * @param {GanttShiftComponent} plugin Shift component plugin.
   * @param {SuperBlockBuilder} [combinePlugIn] Handler which give ability to combine connected shifts of GanttShiftComponent.
   */
  addPlugShiftComponentExecuter(id, shiftComponentExecuter) {
    const s = this;
    shiftComponentExecuter.initPlugIn(s.ganttDiagram);
    // deactivate plugin by default
    shiftComponentExecuter.activate(false);

    const shiftComponentData = new GanttShiftComponentGroupDataItem(id, shiftComponentExecuter);
    s.shiftComponentExecuters.push(shiftComponentData);
  }

  /**
   * @param {string} pluginId
   * @param {"COMBINED"|"SUPERBLOCK"|"NONE"|"ARROWS"|"MIXED"} [combineModus]
   */
  setActivePlugInById(pluginId, combineModus) {
    const s = this;
    const matchingPluginData = s.shiftComponentExecuters.find((dataItem) => dataItem.id == pluginId);

    if (!matchingPluginData) {
      return;
    }
    s.deactivateAllPlugIns();
    matchingPluginData.plugin.activate(true, combineModus, s.superBlockBackendData);
    s.activePlugIn = matchingPluginData;
  }

  /**
   * Deactivates all functionality which affects gantt by active shift component plugin.
   */
  deactivateAllPlugIns() {
    const s = this;
    for (let i = 0; i < s.shiftComponentExecuters.length; i++) {
      const plugin = s.shiftComponentExecuters[i].plugin;
      plugin.activate(false, null, null);
    }
    s.activePlugIn = null;
  }

  /**
   * Triggers recombining of active combine plugin.
   * Does nothing if there is no active combine plugin.
   */
  reCombine() {
    const s = this;
    for (let i = 0; i < s.shiftComponentExecuters.length; i++) {
      s.shiftComponentExecuters[i].combinePlugIn.reCombine(true);
    }
  }

  //
  // GETTER & SETTER
  //
  getExecuterById(id) {
    return this.shiftComponentExecuters.find((executerItem) => executerItem.id == id);
  }

  setSuperBlockBackendData(data) {
    const s = this;
    s.superBlockBackendData = data;
    for (let i = 0; i < s.shiftComponentExecuters.length; i++) {
      s.shiftComponentExecuters[i].plugin.getShiftComponentDataHandler().setSuperBlockBackendData(data);
    }
  }
  getSuperBlockBackendData() {
    return this.superBlockBackendData;
  }

  getAllExecuters() {
    return this.shiftComponentExecuters;
  }

  getActivePlugIn() {
    return this.activePlugIn;
  }
}

/**
 * Data class to save plugin.
 * @param {string} id Save Id to get access to plugin.
 * @param {GanttShiftComponent} plugin Shift component plugin.
 */
export class GanttShiftComponentGroupDataItem {
  plugin: GanttShiftComponents;
  combinePlugIn: SuperBlockBuilder;
  id: string;

  constructor(id, plugin) {
    this.plugin = plugin;
    this.combinePlugIn = plugin.getSuperBlockBuilder();
    this.id = id;
  }
}
