import { Injectable, Type } from '@angular/core';
import { Adapter } from '../../../core/adapter';
import { ActionAdapter } from '../button/action-adapter.service';
import { Action } from '../button/action/action';
import { ContentElementAdapter } from '../structure/content-element-adapter.service';
import { TemplateAdapter } from './../../utils/template-factory.service';
import { ProgressBar } from './progressbar';

@Injectable({
  providedIn: 'root',
})
export class ProgressBarAdapter implements Adapter<ProgressBar> {
  constructor(private contentElementAdapter: ContentElementAdapter, private actionFactory: ActionAdapter) {}

  adapt(item: any): ProgressBar {
    throw new Error('Method not implemented.');
  }
  inherit<T extends ProgressBar>(type: Type<T>, data: any): T {
    const t: T = this.contentElementAdapter.inherit<T>(type, data);
    return t;
  }

  inheritFrom?<T extends ProgressBar>(scope: TemplateAdapter, type: Type<T>, item: any): T {
    const t: T = this.contentElementAdapter.inheritFrom<T>(scope, type, item);
    t.setValue(item.value);
    t.setUpdateDuration(item.updateDuration);
    t.setUpdateAutomatically(item.updateAutomatically);
    t.setValueTriggeredActions(this.mapValueTriggeredActions(item.valueTriggeredActions || {}));
    return t;
  }

  applyValues<T>(scope: TemplateAdapter, item: any, values: Record<string, any>): T {
    throw new Error('Method not implemented.');
  }

  private mapValueTriggeredActions(backendTriggerActions: { [percent: number]: unknown[] }): {
    [percent: number]: Action[];
  } {
    const mappedValueTriggeredActions: { [percent: number]: Action[] } = {};

    for (const percent in backendTriggerActions) {
      const backendActions = backendTriggerActions[percent];
      const actions: Action[] = backendActions.map((backendAction: any) =>
        this.actionFactory.parseAction(Action, backendAction)
      );
      mappedValueTriggeredActions[parseFloat(percent)] = actions;
    }
    return mappedValueTriggeredActions;
  }
}
