<button
  [disabled]="displayedItems?.length === 0"
  class="last-vistited-items"
  [matTooltip]="'last_visited_page' | translate"
  [matTooltipShowDelay]="750"
  mat-icon-button
  [matMenuTriggerFor]="lastVisitedMenu"
  (click)="$event.stopPropagation()"
>
  <mat-icon>timeline</mat-icon>
</button>

<mat-menu #lastVisitedMenu="matMenu">
  <div #menuWrapper>
    <div class="title">{{ 'last_visited' | translate }}</div>
    <ng-container *ngFor="let group of groups; let idx = index">
      <div class="headline">{{ group.name }}</div>
      <ng-container *ngFor="let item of group.items">
        <button class="list-item" mat-menu-item (click)="openRecentlyVisitedNode(item)">
          <div class="line flex-box">
            <span class="title-name">{{ item.name }}</span
            ><span class="spacer"></span
            ><span class="title-date">{{ item.time | date : ('DATE.FORMATS.DATETIME' | translate) }}</span>
          </div>
          <div class="line details">
            <span class="info">{{ item.details | translate }}</span>
            <span *ngIf="item.tenant" class="tenant mat-elevation-z4">{{ item.tenant?.getName() }}</span>
          </div>
        </button>
      </ng-container>
      <mat-divider *ngIf="idx !== groups.length - 1"></mat-divider>
    </ng-container>
    <div
      [saxmsTooltip]="'LIGHTBOX.resize' | translate"
      class="resizer"
      (mousedown)="startResize(menuWrapper.parentNode.parentNode, $event)"
      (click)="blockClick($event)"
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 13">
        <path
          d="M12.41,11.21a.5.5,0,0,1,.71,0,.48.48,0,0,1,0,.7l-.71.71a.48.48,0,0,1-.7,0,.5.5,0,0,1,0-.71Z"
          fill-rule="evenodd"
        />
        <path d="M12.41,8.38a.5.5,0,1,1,.71.71L9.59,12.62a.5.5,0,1,1-.71-.71Z" fill-rule="evenodd" />
        <path d="M12.77,5.2a.49.49,0,1,1,.7.7L6.4,13a.49.49,0,1,1-.7-.7Z" fill-rule="evenodd" />
      </svg>
    </div>
  </div>
</mat-menu>
