import { GanttTemplateData } from 'frontend/src/dashboard/gantt/helper/gantt';
import { GlobalUtils } from 'frontend/src/dashboard/global-utils';
import { ILegendEntry } from 'frontend/src/dashboard/legend/legend.interface';

/**
 * Mapper to convert backend legend data to frontend legend data.
 * Usefull to get initial legend data.
 */
export const GanttLegendMapper = {
  mapGanttLegendDataByTemplate: function (templateData: GanttTemplateData): ILegendEntry[] {
    const legendData: ILegendEntry[] = [];
    if (templateData.getGanttLegend() && templateData.getGanttLegend().legendBlocks) {
      for (const legendBlock of templateData.getGanttLegend().legendBlocks) {
        const entryItem: ILegendEntry = {
          label: legendBlock.name,
          id: GlobalUtils.generateUUID(),
          tooltip: legendBlock.name,
          color: legendBlock.color,
          pattern: legendBlock.pattern,
          patternColor: legendBlock.patternColor,
          strokeColor: legendBlock.stroke,
          strokePattern: legendBlock.strokePattern,
          isActive: true,
          noRender: [],
        };
        legendData.push(entryItem);
      }
    }
    return legendData;
  },
};
