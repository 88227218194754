import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { UserFooterComponent } from './user-footer.component';

@NgModule({
  imports: [CommonModule, TranslateModule, MatTooltipModule],
  exports: [UserFooterComponent],
  declarations: [UserFooterComponent],
})
export class UserFooterModule {}
