import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { TimeDurationPickerModule } from 'frontend/src/dashboard/shared/duration-picker/time-duration-picker-module';
import { SelectorModule as PredefinedSelectorModule } from 'frontend/src/dashboard/view/navbar/selectors/selector.module';
import { TemplateButtonModule } from '../button/button.module';
import { ColorpickerModule } from '../color-picker/colorpicker.module';
import { ElementsModule } from '../elements/elements.module';
import { PieChartModule } from '../elements/pie-chart/pie-chart.module';
import { FileUploaderModule } from '../file-uploader/file-uploader.module';
import { FileuploaderModule } from '../fileuploader-old/fileuploader.module';
import { FilterElementModule } from '../filter-element/filter-element.module';
import { LayoutModule } from '../layout/layout.module';
import { RichTextEditorModule } from '../rich-text-editor/rich-text-editor.module';
import { SelectionBoxModule } from '../selection-box/selection-box.module';
import { LoadingSpinnerModule } from '../spinner/spinner.module';
import { TemplateDatepickerModule } from '../template-datepicker/template-datepicker.module';
import { TemplateDurationPickerModule } from '../template-duration-picker/template-duration-picker.module';
import { TemplateImageModule } from '../template-image/template-image.module';
import { TemplatePickerModule } from '../template-picker/template-picker.module';
import { TemplateTileButtonModule } from '../tile-button/tile-button.module';
import { DateSelectorElementModule } from '../gantt-date-selector/date-selector-element.module';
import { SelectorModule as AppSelectorModule } from './../selector/selector.module';
import { EmptyElementModule } from './empty-element/empty-element.module';
import { EntryElementComponent } from './entry-element.component';
import { GanttSettingsModule } from '../gantt-settings/gantt-settings.module';
@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    MatIconModule,
    AppSelectorModule,
    FileuploaderModule,
    TemplateDatepickerModule,
    TimeDurationPickerModule,
    TemplateButtonModule,
    ElementsModule,
    TemplateDurationPickerModule,
    TemplatePickerModule,
    EmptyElementModule,
    LayoutModule,
    LoadingSpinnerModule,
    ColorpickerModule,
    TemplateTileButtonModule,
    TemplateImageModule,
    PredefinedSelectorModule,
    FileUploaderModule,
    SelectionBoxModule,
    FilterElementModule,
    DateSelectorElementModule,
    PieChartModule,
    RichTextEditorModule,
    GanttSettingsModule,
  ],
  declarations: [EntryElementComponent],
  exports: [EntryElementComponent],
})
export class EntryElementModule {}
