import { Component, OnDestroy } from '@angular/core';
import { takeWhile } from 'rxjs/operators';
import { EFileState } from '../file-state.enum';
import { FileData } from '../filte-data';
import { ProgressDatasourceService } from './datasource.service';

@Component({
  selector: 'app-file-uploader-progress',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.scss'],
})
export class ProgressComponent implements OnDestroy {
  private alive = true;
  opened: boolean;
  files: FileData[] = [];

  constructor(private fileProgressApi: ProgressDatasourceService) {
    this.fileProgressApi
      .getItems()
      .pipe(takeWhile(() => this.alive))
      .subscribe((files: FileData[]) => (this.files = files));

    this.fileProgressApi
      .isOpened()
      .pipe(takeWhile(() => this.alive))
      .subscribe((state: boolean) => (this.opened = state));
  }

  toggleMode(): void {
    this.fileProgressApi.toggle();
  }

  removeItem(file: FileData): void {
    this.fileProgressApi.setItems(this.fileProgressApi.getValue().filter((f: FileData) => f.getId() !== file.getId()));
  }

  get badgeCount(): number {
    return this.files.filter((f: FileData) => f.getState() === EFileState.LOADING).length || null;
  }

  ngOnDestroy(): void {
    this.alive = false;
  }
}
