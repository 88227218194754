import { IKeyValue } from 'frontend/src/dashboard/model/resource/key-value.type';
import { ContentElement } from '../content/content-element/content-element';
import { ETemplateType } from './../../../model/resource/template-type';
import { CalendarEvent } from './calendar-event';

export class SaxMSCalendar extends ContentElement {
  private addNewCalendarEntry: boolean;
  private endDate: Date;
  private searchable: boolean;
  private startDate: Date;
  private useLocalTime: boolean;
  private valuesRestUrl: string;
  private createRestUrls: IKeyValue;
  private value: CalendarEvent[];
  private nameRequired: boolean;
  private createCustomTitle: string;

  public isNameRequired(): boolean {
    return typeof this.nameRequired === 'boolean' ? this.nameRequired : true;
  }

  public setNameRequired(nameRequired: boolean): this {
    this.nameRequired = nameRequired;
    return this;
  }

  public getValue(): CalendarEvent[] {
    return this.value || [];
  }

  public setValue(value: CalendarEvent[]): this {
    this.value = value;
    return this;
  }

  constructor() {
    super();
    this.setType(ETemplateType.CALENDAR);
  }

  public getCreateRestUrls(): IKeyValue {
    return this.createRestUrls;
  }

  public setCreateRestUrls(createRestUrls: IKeyValue): this {
    this.createRestUrls = createRestUrls;
    return this;
  }

  public getEndDate(): Date {
    return this.endDate;
  }

  public setEndDate(endDate: Date): this {
    this.endDate = endDate;
    return this;
  }

  public isSearchable(): boolean {
    return this.searchable;
  }

  public setSearchable(searchable: boolean): this {
    this.searchable = searchable;
    return this;
  }

  public getStartDate(): Date {
    return this.startDate;
  }

  public setStartDate(startDate: Date): this {
    this.startDate = startDate;
    return this;
  }

  public isUseLocalTime(): boolean {
    return this.useLocalTime;
  }

  public setUseLocalTime(useLocalTime: boolean): this {
    this.useLocalTime = useLocalTime;
    return this;
  }

  public getValuesRestUrl(): string {
    return this.valuesRestUrl;
  }

  public setValuesRestUrl(valuesRestUrl: string): this {
    this.valuesRestUrl = valuesRestUrl;
    return this;
  }

  public isAddNewCalendarEntry(): boolean {
    return this.addNewCalendarEntry;
  }

  public setAddNewCalendarEntry(addNewCalendarEntry: boolean): this {
    this.addNewCalendarEntry = addNewCalendarEntry;
    return this;
  }

  public getCreateCustomTitle(): string {
    return this.createCustomTitle;
  }

  public setCreateCustomTitle(createCustomTitle: string): this {
    this.createCustomTitle = createCustomTitle;
    return this;
  }
}
