import { Resource } from 'frontend/src/dashboard/model/resource/resource';
import { ISaxMsSpreadsheetRowEntry } from './table-row-entry';

export interface ISaxMsSpreadsheetRow {
  id: string;
  resourceId: string;
  editEntryRestUrl: string;
  canonicalName: string;
  editable: boolean;
  defaultColor?: string;
  children?: ISaxMsSpreadsheetRow[];
  index?: number;

  spreadsheetRowEntries: { [key: string]: ISaxMsSpreadsheetRowEntry };
}
export class SaxMsSpreadsheetRow extends Resource {
  private editEntryRestUrl: string;
  private editable: boolean;
  private spreadsheetRowEntries: { [key: string]: ISaxMsSpreadsheetRowEntry };

  setSpreadsheetRowEntries(spreadsheetRowEntries: { [key: string]: ISaxMsSpreadsheetRowEntry }): SaxMsSpreadsheetRow {
    this.spreadsheetRowEntries = spreadsheetRowEntries;
    return this;
  }

  setEditEntryRestUrl(editEntryRestUrl: string): SaxMsSpreadsheetRow {
    this.editEntryRestUrl = editEntryRestUrl;
    return this;
  }
  setEditable(editable: boolean): SaxMsSpreadsheetRow {
    this.editable = editable;
    return this;
  }

  getEditEntryRestUrl(): string {
    return this.editEntryRestUrl;
  }
  getSpreadsheetRowEntries(): { [key: string]: ISaxMsSpreadsheetRowEntry } {
    return this.spreadsheetRowEntries;
  }

  isEditable(): boolean {
    return this.editable;
  }
}
