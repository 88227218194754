/**
 * Mapper to convert backend data for GanttTimePointMarkerPlugIn.
 */
export class GanttTimePointMapper {
  /**
   * Uses backend hierarchical plan data to provide timepoint marker for GanttTimePointMarkerPlugIn.
   * @param responseData Backend hierarchical plan.
   */
  extractTimePointData(responseData: any): any[] {
    const globalMilestones = responseData.globalMilestones || [];

    const mappedData = globalMilestones.map((milestone) => {
      return {
        name: milestone.name,
        timePoint: milestone.timePointTime,
        color: milestone.color,
      };
    });
    return mappedData;
  }
}
