<ng-container *ngIf="multi">
  <mat-checkbox class="option-selectbox" [checked]="selected" (click)="afterClick.emit($event, option)">
    <span *ngIf="color" class="color-tag" [style.background-color]="color"></span
    ><span [style.color]="textColor">{{ name }}</span>
  </mat-checkbox>
</ng-container>
<ng-container *ngIf="!multi">
  <span *ngIf="color" class="color-tag" [style.background-color]="color"></span
  ><span [style.color]="textColor">{{ name }}</span>
</ng-container>
