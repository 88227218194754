import { HttpClient } from '@angular/common/http';
import { EPredefinedAction } from '@app-modeleditor/components/button/action/predefined-action.enum';
import { ConfigService } from '@core/config/config.service';
import { EGanttInstance, GanttDataRow } from '@gantt/public-api';
import { GanttLibService } from 'frontend/src/dashboard/gantt/gantt/gantt-lib.service';
import { SaxMsBestGanttActiveSubmenuEntryElementSetting } from 'frontend/src/dashboard/gantt/gantt/saxms-best-gantt.settings';
import { GanttPluginHandlerService } from 'frontend/src/dashboard/gantt/general/plugin/gantt-plugin-handler.service';
import { GanttTemplateData } from 'frontend/src/dashboard/gantt/helper/gantt';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { GeneralGanttActionHandler } from '../../../action-handling/action-handler';
import { GanttRowAdditionalDataMapper } from '../../../generator/mapper/gantt-row-additional-data.mapper';
import { GanttResponseHandler } from '../../../response/response-handler';
import { GanttEssentialPlugIns } from '../../e-gantt-essential-plugins';
import { ExternalGanttPlugin } from '../../external-plugin';
import { GanttResponseIndexCardUpdate } from './responses/index-card-update/index-card-update.response';

export const GanttPlugInIndexCardExecuter = 'gantt-plugin-index-card-executer';

/**
 * PlugIn-Wrapper for IndexCardExecuter and GanttTexturizer.
 * Provides index card functionality.
 */
export class GanttIndexCardsPlugIn extends ExternalGanttPlugin {
  public plugInIsActiveNotification: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private templateData: GanttTemplateData;
  private responseData: any;
  private plugInIsActive: boolean;
  private showTexturizer = true;
  private _showAttributeName = true;
  private _showEmptyAttributes = false;

  constructor(
    protected _ganttPluginHandlerService: GanttPluginHandlerService,
    protected _ganttLibService: GanttLibService,
    actionHandler: GeneralGanttActionHandler,
    private responseHandler: GanttResponseHandler,
    private _http: HttpClient,
    private configService: ConfigService
  ) {
    super(_ganttPluginHandlerService, _ganttLibService, actionHandler);
  }

  public onInit(templateData: GanttTemplateData, responseData: any) {
    this.addPlugIn(
      GanttPlugInIndexCardExecuter,
      this._ganttLibService.ganttInstanceService.getInstance(EGanttInstance.INDEX_CARD)
    );
    this.templateData = templateData;
    this.responseData = responseData;
    this.responseHandler.addResponse(GanttResponseIndexCardUpdate, this.getPlugInById(GanttPlugInIndexCardExecuter));
    this.getIndexCardConfigAndUpdate().subscribe();
  }

  public onDestroy(): void {}

  public onAction(action: any) {}

  /**
   * Extracts index card content from backend data.
   */
  public extractIndexCardData(): { [id: string]: string[] } {
    const indexCardData = {};

    if (!this.templateData.getIndexCard() || !this.templateData.getIndexCard().attributeMetadata) {
      return indexCardData;
    }
    this._extractRecursive(this.gantt.getDataHandler().getOriginDataset().ganttEntries, indexCardData);

    const superBlocksPlugIn = this._ganttPluginHandlerService.getEssentialPlugIn(
      GanttEssentialPlugIns.SuperBlocksPlugIn
    );
    this._extractSuperblockData(superBlocksPlugIn.getSuperblockBackendData(), indexCardData);

    return indexCardData;
  }
  /**
   * Extracts index card content from all shifts in a given gantt dataset.
   * @param rows Gantt dataset.
   * @param indexCardData Map to extract the index card content into.
   */
  private _extractRecursive(rows: GanttDataRow[], indexCardData: { [id: string]: string[] }): void {
    for (const row of rows) {
      for (const block of row.shifts) {
        if (!block.additionalData.additionalData || !block.additionalData.additionalData.additionalDetails) {
          continue;
        }
        const additionalDetailsOfBlock = block.additionalData.additionalData.additionalDetails;
        for (const attribute of this.templateData.getIndexCard().attributeMetadata) {
          const matchingIndexCardAttribute: any = additionalDetailsOfBlock[attribute.attributeID];
          if (!matchingIndexCardAttribute && !this._showEmptyAttributes) {
            continue;
          }
          if (!indexCardData[block.id]) indexCardData[block.id] = [];
          let indexCardDescription: string = this._showAttributeName
            ? this.getIndexCardDescriptionByNumber(this.templateData, attribute.attributeID)
            : '';
          if (indexCardDescription != null && indexCardDescription != '') indexCardDescription += ': ';
          indexCardData[block.id].push(
            indexCardDescription +
              this._getValueByDataType(attribute.attributeID, matchingIndexCardAttribute?.t2 || '?')
          );
        }
        this.turnBlockIdToCloneId(block, indexCardData, row);
      }
      this._extractRecursive(row.child, indexCardData);
    }
  }
  /**
   * Extracts index card content from all super block data view of a given superblock dataset.
   * @param superBlockBackendData Superblock dataset.
   * @param indexCardData Map to extract the index card content into.
   */
  private _extractSuperblockData(superBlockBackendData: any, indexCardData: { [id: string]: string[] }): void {
    for (const id in superBlockBackendData) {
      if (indexCardData[id]) {
        continue;
      }
      const superBlock = superBlockBackendData[id];
      if (!superBlock.details || !superBlock.details.additionalDetails) {
        continue;
      }
      const additionalDetailsOfBlock = superBlock.details.additionalDetails;
      for (const attribute of this.templateData.getIndexCard().attributeMetadata) {
        const matchingIndexCardAttribute: any = additionalDetailsOfBlock[attribute.attributeID];
        if (!matchingIndexCardAttribute && !this._showEmptyAttributes) {
          continue;
        }
        if (!indexCardData[superBlock.id]) indexCardData[superBlock.id] = [];
        let indexCardDescription: string = this._showAttributeName
          ? this.getIndexCardDescriptionByNumber(this.templateData, attribute.attributeID)
          : '';
        if (indexCardDescription != null && indexCardDescription != '') indexCardDescription += ': ';
        indexCardData[superBlock.id].push(
          indexCardDescription + this._getValueByDataType(attribute.attributeID, matchingIndexCardAttribute?.t2 || '?')
        );
      }
    }
  }

  /**
   * Returns a parsed value by datatype.
   */
  private _getValueByDataType(lineNumber: number, value: string | number): string {
    const description: any = this.templateData.getAttributeMapping()[lineNumber];
    if (!description) return value as string;
    return this.trimString(GanttRowAdditionalDataMapper.getAttributeValueByDataType(value, description.dataType), 50);
  }

  /**
   * Limits a string to the passed maximum length.
   * If the string is too long, it is shortened.
   * @param string string to limit
   * @param maxLength maximum length
   * @returns trimmed string
   */
  private trimString(string: string, maxLength: number): string {
    if (string?.length && string.length > maxLength) {
      return string.substring(0, maxLength) + '…';
    }
    return string;
  }

  private turnBlockIdToCloneId(block, indexCardData, row) {
    const mapper = this._ganttLibService.backendToGanttOriginInputMapper;
    if (mapper.hasOriginShiftClones(block.id)) {
      const cloneId = mapper.getCloneIdByOriginIdAndRowId(block.id, row.id);
      indexCardData[cloneId] = indexCardData[block.id];
    }
  }

  /**
   * Extracts index card row content by attributeMapping and line number.
   * @param templateData Hierarchical plan backend data.
   * @param lineNumber Line of text list which will be shown inside index card.
   */
  private getIndexCardDescriptionByNumber(templateData: GanttTemplateData, lineNumber: number): string {
    const description: any = templateData.getAttributeMapping()[lineNumber];
    if (!description) return lineNumber + '';
    return description.localization;
  }

  /**
   * Sets visibility of headline texture inside index cards.
   * @param showTexturizer Show or hide headline textures.
   */
  public setShowTexturizer(showTexturizer: boolean): void {
    this.showTexturizer = showTexturizer;
  }

  /**
   * Actiates Index card view. Bundles all necessary handling of the IndexCardExecuter and GanttTexturizer.
   * Activates plugin callbacks.
   * @param {optional} skipNotification whether to skip notifying other components or not
   * @returns void
   */
  public activateIndexCardView(skipNotification = false): void {
    if (this.plugInIsActive === true) {
      return;
    }
    this.getPlugInById(GanttPlugInIndexCardExecuter).changeGanttRowHeight();
    this.getPlugInById(GanttPlugInIndexCardExecuter).showIndexCards(true);
    this.getPlugInById(GanttPlugInIndexCardExecuter).setDataSet(this.extractIndexCardData());
    this.getPlugInById(GanttPlugInIndexCardExecuter).build();
    this.addCallbacks();
    this.plugInIsActive = true;
    if (!skipNotification) {
      this.plugInIsActiveNotification.next(true);
    }
    this.gantt.update();
  }

  /**
   * Deactivates Index card view. Removes callbacks.
   * @@param {optional} skipNotification whether to skip notifying other components or not
   * @returns void
   */
  public deactivateIndexCardView(skipNotification = false): void {
    if (this.plugInIsActive === false) {
      return;
    }
    this.removeCallbacks();
    this.getPlugInById(GanttPlugInIndexCardExecuter).hideIndexCardsMode();

    this.plugInIsActive = false;
    if (!skipNotification) {
      this.plugInIsActiveNotification.next(false);
    }
    this.gantt.update();
  }

  /**
   * set timespan visibility
   * @param {boolean} isShowTimespan whether to show or hide timespan
   * @returns void
   */
  public setShowTimeSpan(isShowTimespan: boolean): void {
    this.getPlugInById(GanttPlugInIndexCardExecuter).setShowDate(isShowTimespan);
    if (this.isActive()) {
      this.deactivateIndexCardView(true);
      this.activateIndexCardView(true);
    }
  }

  public getActivationNotification(): Observable<boolean> {
    return this.plugInIsActiveNotification.asObservable();
  }

  /**
   * Adds callback to react to font size changes.
   */
  private addCallbacks() {
    const s = this;
    this.gantt.getConfig().addAfterShiftFontSizeChangedCallback(GanttPlugInIndexCardExecuter, (_) => {
      s.getPlugInById(GanttPlugInIndexCardExecuter)?.changeGanttRowHeight();
    });
  }

  /**
   * Removes callbacks.
   */
  private removeCallbacks() {
    this.gantt.getConfig().removeAfterShiftFontSizeChangedCallback(GanttPlugInIndexCardExecuter);
  }

  /**
   * Calculates Height if index card view will be activated.
   */
  private calculateIndexCardRowHeight() {
    try {
      let amountOfContentRows = this.templateData.getIndexCard().attributeMetadata.length;
      if (this.getPlugInById(GanttPlugInIndexCardExecuter).isShowDate()) {
        amountOfContentRows++;
      }
      return this.getPlugInById(GanttPlugInIndexCardExecuter).getIndexCardShiftHeight(amountOfContentRows);
    } catch (e) {
      return 128;
    }
  }

  public executeAction(localAction: any): Observable<any> {
    // todo: activate index card view

    if (localAction.id !== EPredefinedAction.UPDATE_INDEX_CARD_CONFIG) {
      return of();
    }

    this.getIndexCardConfigAndUpdate().subscribe();
    return of();
  }

  private getIndexCardConfigAndUpdate(): Observable<any> {
    if (
      !this.templateData.hasOwnProperty('restUrl') ||
      !this.configService.access().templates.Gantt.requests.indexCardSettings ||
      this.templateData.isPrint()
    ) {
      return of(null);
    }
    const ganttId = this.templateData.getId();

    // @TODO: Find better way to get the template Id than this.
    const urlParts = this.templateData.getRestUrl().split('/');
    const templateId = urlParts[1];
    const restUrl = `rest/template/${templateId}/gantt/${ganttId}/indexcardsettings`;

    return this._http.get(restUrl).pipe(
      catchError((e) => of()),
      tap((indexCardConfig) => {
        this.templateData.setIndexCard(indexCardConfig);
        this.updatePlugin(true);
      })
    );
  }

  public updatePlugin(updateGantt?: boolean): void {
    const indexCardData = this.extractIndexCardData();
    this.getPlugInById(GanttPlugInIndexCardExecuter).setDataSet(indexCardData);
    if (this.plugInIsActive) {
      this.getPlugInById(GanttPlugInIndexCardExecuter).changeGanttRowHeight();
      this.getPlugInById(GanttPlugInIndexCardExecuter).build();
      if (updateGantt) {
        this.gantt.update();
      }
    }
  }

  public isActive(): boolean {
    return this.plugInIsActive;
  }

  public setShowAttributeName(bool: boolean): void {
    if (this._showAttributeName === bool) {
      return;
    } // nothing has changed
    this._showAttributeName = bool;
    this.updatePlugin(true);
  }

  public setShowEmptyAttributes(bool: boolean): void {
    if (this._showEmptyAttributes === bool) {
      return;
    } // nothing has changed
    this._showEmptyAttributes = bool;
    this.updatePlugin(true);
  }

  public isShowAttributeName(): boolean {
    return this._showAttributeName;
  }

  public injectSettings(submenuElements: SaxMsBestGanttActiveSubmenuEntryElementSetting[]): void {}
}
