<div
  #templateSpreadsheetContainer
  *ngIf="getTableTemplate()"
  class="template-spreadsheet-container"
  [style.height]="getTableTemplate()?.getResizeMode() === 'FIT_PARENT' ? 'calc(100% - 4px)' : 'unset'"
  [class.no-bg]="
    getTableTemplate() &&
    ((getTableTemplate().isHideHeader() && getTableTemplate().getMenuMode() === 'HIDE') ||
      getTableTemplate().getMenuMode() === 'HIDE')
  "
  oncontextmenu="return false;"
>
  <div class="loading-spinner" *ngIf="getLoadingState()">
    <mat-progress-spinner [diameter]="24" mode="indeterminate"> </mat-progress-spinner>
  </div>

  <div #toolbarWrapper>
    <a #hiddenLink href="null" style="display: none"></a>
    <div class="wrapper-for-toolbar" *ngIf="toolbar">
      <template-slot (afterInit)="afterInitSlot($event)"></template-slot>
    </div>
    <ng-container *ngIf="getMessageBars().length">
      <app-message-bar *ngFor="let bar of getMessageBars()" [data]="bar"></app-message-bar>
    </ng-container>
  </div>
  <div
    class="spreadsheet-container-wrapper"
    *ngIf="!loading && displayedColumns && displayedColumns.length > 0"
    [style.height]="getTableWrapperHeight()"
    [style.width]="_containerPaddingRight ? 'calc(100% - ' + _containerPaddingRight + 'px)' : null"
    [class.allColumnsHidden]="hiddenColumnFilterSet.size === displayedColumns.length"
  >
    <hot-table
      #spreadSheet
      tableClassName="saxms-spreadsheet"
      height="100%"
      [afterInit]="afterInitCallback"
      [afterBeginEditing]="afterBeginEditingCallback"
      [settings]="tableSettings"
      [data]="rows"
      [columns]="displayedColumns"
      [hotId]="tableId"
      [maxRows]="MAX_ROWS"
    >
    </hot-table>
  </div>

  <div
    class="no-data-container"
    *ngIf="
      displayedColumns &&
      displayedColumns.length > 0 &&
      initLoadRows &&
      hiddenColumnFilterSet.size === displayedColumns.length
    "
  >
    {{ 'TABLE.all_column_invisible' | translate }}
  </div>

  <div class="no-data-container" *ngIf="initLoadRows && hiddenHeader && rows.length === 0">
    {{ 'TABLE.no_data' | translate }}
  </div>

  <div *ngIf="colorClassSet && colorClassSet.length > 0" #legendRef>
    <app-legend
      [pointerInteraction]="true"
      [data]="colorClassSet"
      (onEntryClick)="handleLegendClick($event)"
      (onEntryDblClick)="handleLegendDblClick($event)"
    >
    </app-legend>
  </div>
</div>

<ng-container *ngIf="isAlive()">
  <app-customEditor #customCellEditor [cellEditorContext]="cellEditorContext" [id]="tableId">
    <ng-template #cellEditor let-cell let-column="column" let-visibile="visibile">
      <div class="spreadsheet-cell">
        <ng-container *ngIf="column && cell">
          <ng-container [ngSwitch]="column.fieldType">
            <ng-container *ngSwitchCase="'DATE_TIME_PICKER'">
              <template-datepicker
                [autofocus]="true"
                #dateEditor
                class="custom-datetime"
                [id]="cell"
                [open]="true"
                [useLocalTime]="column.useLocalTime"
                [defaultText]="spreadsheetHooksUtilService?.getSavedKeyForEditor()"
                (changes)="handleDatePicker($event)"
                (closePicker)="handlePickerClose($event)"
                [placeholder]="column.label"
                [disabled]="false"
                [selectedValues]="{
                  from: editValueObject.value?.value,
                  to: editValueObject.value?.value
                }"
                [type]="'DATETIME'"
                [mode]="'single'"
                [simpleInput]="true"
                (openPicker)="onCalendarOpen()"
              >
              </template-datepicker>
            </ng-container>

            <ng-container *ngSwitchCase="'DATE_PICKER'">
              <template-datepicker
                [autofocus]="true"
                #dateEditor
                class="custom-datetime"
                [id]="cell"
                [open]="true"
                [useLocalTime]="column.useLocalTime"
                [defaultText]="spreadsheetHooksUtilService?.getSavedKeyForEditor()"
                (changes)="handleDatePicker($event)"
                (closePicker)="handlePickerClose($event)"
                [placeholder]="column.label"
                [disabled]="false"
                [type]="'DATE'"
                [mode]="'single'"
                [simpleInput]="true"
                [dateTimeFormat]="column.dateTimeFormat"
                (openPicker)="onCalendarOpen()"
                [selectedValues]="{
                  from: editValueObject.value?.value,
                  to: editValueObject.value?.value
                }"
              >
              </template-datepicker>
            </ng-container>

            <ng-container *ngSwitchCase="'TIME_PICKER'">
              <template-datepicker
                [autofocus]="true"
                #dateEditor
                class="custom-datetime"
                [id]="cell"
                [open]="true"
                [useLocalTime]="column.useLocalTime"
                [defaultText]="spreadsheetHooksUtilService?.getSavedKeyForEditor()"
                (changes)="handleDatePicker($event)"
                (closePicker)="handlePickerClose($event)"
                [placeholder]="column.label"
                [disabled]="false"
                [type]="'TIME'"
                [selectedValues]="{
                  from: editValueObject.value?.value,
                  to: editValueObject.value?.value
                }"
                [mode]="'single'"
                [simpleInput]="true"
                (openPicker)="onCalendarOpen()"
              >
              </template-datepicker>
            </ng-container>

            <ng-container *ngSwitchCase="'CHECK_BOX'">
              <mat-checkbox
                *ngIf="column.datatype === 'Bool'"
                [checked]="editValueObject.value.value"
                (change)="editCellEditorChange($event.checked)"
              >
              </mat-checkbox>
            </ng-container>
            <ng-container *ngSwitchCase="'COLOR_PICKER'">
              <app-color-button
                [picker]="editValueObject.getEntryElement()"
                [isSpreadsheetContext]="true"
                (colorChanged)="editEntryElementOfCellEditor(editValueObject.getEntryElement())"
              ></app-color-button>
            </ng-container>

            <ng-container *ngSwitchCase="'COMBO_BOX'">
              <app-selector
                [disabled]="false"
                [simpleInput]="true"
                [initiallyOpened]="true"
                [element]="editValueObject.getEntryElement()"
                (changeEvent)="editEntryElementOfCellEditor(editValueObject.getEntryElement())"
                [resizeable]="true"
              >
              </app-selector>
            </ng-container>

            <ng-container *ngSwitchCase="'DURATION_PICKER'">
              <template-duration-picker
                [autofocus]="true"
                (millisChange)="editCellEditorChange($event, true)"
                [(millis)]="editValueObject.value.value"
                simpleInput="true"
                initialFocus="true"
                [displayed]="column.durationPickerValues"
              >
              </template-duration-picker>
            </ng-container>

            <ng-container *ngSwitchCase="'NUMERIC_RANGE_PICKER'">
              <app-numeric-range-picker
                (change)="editEntryElementOfCellEditor(editValueObject.getEntryElement(), false, $event.changed)"
                [element]="editValueObject.getEntryElement()"
              ></app-numeric-range-picker>
            </ng-container>

            <ng-container *ngSwitchCase="'OBJECT_SELECTOR'">
              <app-selector
                [disabled]="false"
                [initiallyOpened]="true"
                [element]="editValueObject.getEntryElement()"
                (changeEvent)="editEntryElementOfCellEditor(editValueObject.getEntryElement())"
                [resizeable]="true"
              >
              </app-selector>
            </ng-container>

            <ng-container *ngSwitchCase="'MULTI_OBJECT_SELECTOR'">
              <app-selector
                [disabled]="false"
                [initiallyOpened]="true"
                [multi]="true"
                [element]="editValueObject.getEntryElement()"
                (changeEvent)="editEntryElementOfCellEditor(editValueObject.getEntryElement(), true)"
                [resizeable]="true"
              >
              </app-selector>
            </ng-container>

            <ng-container *ngSwitchCase="'TEXT_FIELD'">
              <saxms-element
                *ngIf="editValueObject?.getEntryElement()"
                (changeEvent)="editEntryElementOfCellEditor(editValueObject.getEntryElement())"
                [elementData]="editValueObject.getEntryElement()"
                [isSpreadsheetContext]="true"
              >
              </saxms-element>
            </ng-container>
            <ng-container *ngSwitchCase="'TEXT_AREA'">
              <app-textarea-overlay
                *ngIf="editValueObject?.getEntryElement()"
                [entryElement]="editValueObject.getEntryElement()"
                (changeEvent)="editEntryElementOfCellEditor(editValueObject.getEntryElement())"
              />
            </ng-container>
            <ng-container *ngSwitchDefault>
              <strong>{{ column.fieldType }}</strong> is currently not implemented!
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
    </ng-template>
  </app-customEditor>
</ng-container>
