import { Injectable, Inject } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GlobalViewService {
  public currentStyle: any = '';

  public _observable: Observable<any>;
  public _subject: Subject<any>;

  public onChangeStyle: Observable<any>;
  public onChangeStyleSub: Subject<any>;

  constructor() {
    this._subject = new Subject<any>();
    this._observable = this._subject.asObservable();

    this.onChangeStyleSub = new Subject<any>();
    this.onChangeStyle = this.onChangeStyleSub.asObservable();
  }

  public notify(event: any): void {
    this._subject.next(event);
  }

  public changeStyle(style: any): void {
    this.currentStyle = style;
    this.onChangeStyleSub.next(style);
  }
}
