import { IGanttResponse } from 'frontend/src/dashboard/gantt/general/response/gantt-response';
import { GanttResponse } from 'frontend/src/dashboard/gantt/general/response/responses/response';
import { GanttBlockingIntervalsPlugIn } from '../../blocking-intervals.plugin';

/**
 * Watches for changes in blocking intervals and triggers a change event.
 */
export class BlockingIntervalChangeObservation extends GanttResponse<GanttBlockingIntervalsPlugIn> {
  /**
   * Handles response by given change strategy.
   * @param response Backend response.
   * @returns True if the response was handled successfully, false otherwise.
   */
  public handleResponse(response: IGanttResponse): boolean {
    if (
      (response?.updatedPluginData && response.updatedPluginData['template.ganttplugin.blockinginterval']) ||
      response?.ganttTimePeriodInput ||
      response?.ganttTimePeriodInputs?.length ||
      response?.deleteGanttTimePeriodInput ||
      response?.deleteGanttTimePeriodInputs?.length ||
      response?.deletedGanttTimePeriodInputs?.length
    ) {
      this.executer.triggerBlockingIntervalChange();
    }
    return true;
  }
}
