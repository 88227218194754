import * as d3 from 'd3';
import { ComponentEvent } from '../../../history/component-event';

/**
 * @constructor
 * @class
 * @extends ComponentEvent
 * @requires ComponentEvent
 * @plugin timepoint-marker
 * */
export class GanttTimePointEvent extends ComponentEvent {
  constructor() {
    super();
    /**
     * @type {GanttTimePointExecuter}
     */
    this.ref = null;
  }

  /**
   * @override
   * @returns {boolean} true if undo was succesfull, else false
   */
  undo() {
    const s = this;

    function handle(type) {
      const types = {
        addTimePointMarker: function () {
          return s._undoAddTPMarker();
        },
        changeTPMarkerColor: function () {
          return s._undoChangeTPMarkerColor();
        },
        default: function () {
          console.warn('can not undo: ' + type + '. May not be implemented yet.');
          return false;
        },
      };
      return (types[type] || types['default'])();
    }
    return handle(s.eventName);
  }

  /**
   * @override
   * @returns {boolean} true if redo was succesfull, else false
   */
  redo() {
    const s = this;
    function handle(type) {
      const types = {
        addTimePointMarker: function () {
          return s._redoAddTPMarker();
        },
        changeTPMarkerColor: function () {
          return s._redoChangeTPMarkerColor();
        },
        default: function () {
          console.warn('can not redo: ' + type + '. May not be implemented yet.');
          return false;
        },
      };
      return (types[type] || types['default'])();
    }
    return handle(s.eventName);
  }

  /**
   * @overrides
   * @return {String}
   */
  getEventDescription() {
    const s = this;
    function handle(type) {
      const types = {
        addTimePointMarker: function () {
          return GanttTimePointEventEventDescription.getAddTimePointMarkerDescription(
            s.ref.ganttDiagram.getConfig().getLanguage(),
            s.arguments
          );
        },
        changeTPMarkerColor: function () {
          return GanttTimePointEventEventDescription.getChangeTPMarkerColorDescription(
            s.ref.ganttDiagram.getConfig().getLanguage(),
            s.arguments
          );
        },
        default: function () {
          console.warn('no description for: ' + s.eventName + ' set. May not be implemented yet.');
          return '';
        },
      };
      return (types[type] || types['default'])();
    }
    return handle(s.eventName);
  }

  private _undoAddTPMarker() {
    const s = this;
    s.ref.disableLogging();

    delete s.ref.timePoints[s.arguments[0]];
    s.ref.build();

    s.ref.enableLogging();
    return true;
  }
  private _redoAddTPMarker() {
    const s = this;
    s.ref.disableLogging();

    s.ref.addTimePoint(s.arguments[0], new Date(s.arguments[1]));
    s.ref.build();

    s.ref.enableLogging();
    return true;
  }

  private _undoChangeTPMarkerColor() {
    const s = this;
    s.ref.disableLogging();
    s.ref.setColor(s.arguments[0]);
    s.ref.enableLogging();
    return true;
  }
  private _redoChangeTPMarkerColor() {
    const s = this;
    s.ref.disableLogging();
    s.ref.setColor(s.arguments[1]);
    s.ref.enableLogging();
    return true;
  }
}

// GETTER and SETTER inherited from Main Class

export class GanttTimePointEventEventDescription {
  // TODO: Event explanations.
  private constructor() {}

  static getAddTimePointMarkerDescription(language, args) {
    const s = this;
    const formatTimeDE = d3.timeFormat('%H:%M:%S am %d %B %Y');
    const formatTimeEN = d3.timeFormat('%H:%M:%S on %B %d %Y');
    let description = '';

    if (language === 'DE') {
      description = 'Hinzufügen von Zeitpunkt Markierung: ' + args[0] + ' um ' + formatTimeDE(new Date(args[1])) + '.';
    }
    if (language === 'EN') {
      description = 'Adding new timepoint marker ' + args[0] + ' at ' + formatTimeEN(new Date(args[1])) + '.';
    }
    return description;
  }
  static getChangeTPMarkerColorDescription(language, args) {
    const s = this;
    let description = '';

    if (language === 'DE') {
      description = 'Ändere Farbe des Zeitpunkt Markierers von ' + args[0] + ' auf ' + args[1] + '.';
    }
    if (language === 'EN') {
      description = 'Change timepoint marker color from ' + args[0] + ' to ' + args[1] + '.';
    }
    return description;
  }
}
