<div class="saxms-best-gantt-legend-container">
  <app-legend-toolbar
    *ngIf="menuOpen"
    #legendToolbar
    (visibilityChange)="toggleVisibility($event)"
    (onHideFilteredOutElements)="onDisplayOptionChange($event)"
    (onSearchForEntry)="searchForEntry($event)"
    (onClearSearch)="clearSearch()"
    (onHideSearchResults)="toggleHideFilteredOutEntries($event)"
    (showOnlyVisibleElementsChange)="validateLegendVisibility()"
    (onResetIgnoredEntries)="onResetIgnoredEntries()"
    (onIgnoreEditedEntries)="onIgnoreEditedEntries()"
  >
  </app-legend-toolbar>
  <div class="view">
    <app-legend
      [data]="legendCommunicationService.getLegendData().legendEntries"
      [pointerInteraction]="true"
      (onEntryClick)="onItemClick($event)"
      (onEntryDblClick)="onItemDoubleClick($event)"
    ></app-legend>
  </div>
</div>
