import NumberParsing from './number-parsing';
import { countDecimals } from './parsing-utils';

class FloatParsing extends NumberParsing {
  public correctOnTheFly(input?: string, fallbackInput?: string, correctValue = true): string {
    const hasNegation = super.allowNegative && !!input?.length && input.charAt(0) === '-'; // dash will be added later again

    let buffer = (input || '')
      .replace(/[^0-9,]/g, '') // only accept numbers and comma, thousand points will be added again later
      .replace(/^0+([^,$])/, '$1'); // remove 0s at beginning

    if (this.decimalsCount === 0) {
      buffer = buffer.replace(/,/g, '');
    } // remove comma of explicit no decimals by defined step

    // only accept first comma and add thousand points
    const commaIdx = buffer.indexOf(',');

    if (commaIdx > 0) {
      const beforeComma = super.addThousandPoints(buffer.substring(0, commaIdx));
      const afterComma = buffer.substring(commaIdx + 1).replace(',', '');
      buffer = `${beforeComma},${afterComma}`;
    } else {
      buffer = super.addThousandPoints(buffer);
    }

    if (buffer !== '' && correctValue) {
      buffer = this.formatValue(buffer);
    }
    if (hasNegation) {
      buffer = `-${buffer}`;
    }

    return super.correctOnTheFly(buffer, fallbackInput);
  }

  public inputToValue(input?: string): number | undefined {
    return super.finiteOrUndefined(
      parseFloat(
        input
          .replace(/,$/g, '') // remove comma, if last character
          .replace(/\./g, '') // remove thousand points
          .replace(/,/, '.') // replace comma by point
      )
    );
  }

  public valueToInput(value: number): string {
    return this.correctOnTheFly(`${value}`.replace('.', ','));
  }

  /**
   * Adds decimals to input if less than decimalsCount after comma or removes decimals if more.
   */
  public formatValue(input: string) {
    if (this.decimalsCount === undefined) return input;
    if (input === undefined || input === null || input === '') return undefined;
    if (this.decimalsCount === 0) {
      return input.includes(',') ? input.substring(0, input.indexOf(',')) : input;
    }

    if (!input) {
      input = '0';
    }
    if (!input.includes(',')) {
      input = `${input},0`;
    }

    const countDecimals = input.length - input.indexOf(',') - 1;
    const decimalsDiff = this.decimalsCount - countDecimals;

    if (decimalsDiff >= 0) {
      return `${input}${'0'.repeat(decimalsDiff)}`;
    } else {
      return input.substring(0, input.length + decimalsDiff);
    }
  }

  /**
   * number of decimals are based on the number of decimals of step
   */
  protected get decimalsCount(): number | undefined {
    if (!super.isExplicitStep) return undefined;
    return countDecimals(this.step);
  }
}

export default FloatParsing;
