import { ContextMenuAdapter } from '@app-modeleditor/components/contextmenu/context-menu-adapter.service';
import { ContextMenu } from '@app-modeleditor/components/contextmenu/contextmenu';
import { UiService } from '@app-modeleditor/ui.service';
import { ConfigService } from '@core/config/config.service';
import {
  EGanttInstance,
  GanttScrollContainerEvent,
  GanttTimePeriodExecuter,
  GanttTimePeriodGroupExecuter,
  TimePeriodAddData,
} from '@gantt/public-api';
import { GanttLibService } from 'frontend/src/dashboard/gantt/gantt/gantt-lib.service';
import { SaxMsBestGanttActiveSubmenuEntryElementSetting } from 'frontend/src/dashboard/gantt/gantt/saxms-best-gantt.settings';
import { GanttPluginHandlerService } from 'frontend/src/dashboard/gantt/general/plugin/gantt-plugin-handler.service';
import { GanttTemplateData } from 'frontend/src/dashboard/gantt/helper/gantt';
import { Observable, Subject, Subscription, of } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GeneralGanttActionHandler } from '../../../action-handling/action-handler';
import { IGanttBlockEditNotification } from '../../../gantt-edit/gantt-edit.interface';
import { GanttEditService } from '../../../gantt-edit/gantt-edit.service';
import { IGanttPlugin } from '../../../generator/gantt-input.data';
import { GanttResponseHandler } from '../../../response/response-handler';
import { ExternalGanttPlugin } from '../../external-plugin';
import { BlockingIntervalSelectionLightbox } from './blocking-interval-selection-lightbox';
import { GanttBlockingIntervalsMapper } from './blocking-intervals.mapper';
import { GanttBlockingIntervalActionHandler } from './event-handling/interval-registration';
import {
  IGanttMultipleIntervalSelectionCB,
  IGanttSelectedTimePeriod,
  IGanttTimePeriodGroupIntervalInputs,
  IGanttTimePeriodMouseOverResult,
  IGanttTimePeriodTemplateData,
} from './gantt-template-data-attribute-mapping.interface';
import { BlockingIntervalChangeObservation } from './responses/interval-update/interval-change-observation.response';
import { GanttResponseBlockingIntervalUpdate } from './responses/interval-update/interval-update.response';

export const BlockingIntervalBackendType = 'template.ganttplugin.blockinginterval';

export const GanttPlugInTimePeriodGroupExecuter = 'gantt-plugin-time-period-group-executer';

/**
 * PlugIn-Wrapper for GanttTimePeriodGroupExecuter.
 * This plugin ist not essential and has all dashobard plugin-wrapper options.
 * It is the most complex plugin wrapper for now.
 */
export class GanttBlockingIntervalsPlugIn extends ExternalGanttPlugin {
  public intervalMapper: GanttBlockingIntervalsMapper;
  private registration: GanttBlockingIntervalActionHandler;
  private blockingIntervalData: IGanttTimePeriodTemplateData;
  private ganttEditServiceSubscription: Subscription;
  private blockingIntervalChanged$ = new Subject<void>();

  constructor(
    protected _ganttPluginHandlerService: GanttPluginHandlerService,
    protected _ganttLibService: GanttLibService,
    private responseHandler: GanttResponseHandler,
    public actionHandler: GeneralGanttActionHandler,
    private backendPluginData: IGanttPlugin[],
    private uiService: UiService,
    private templateData: GanttTemplateData,
    private contextMenuAdapter: ContextMenuAdapter,
    private configService: ConfigService,
    private ganttEditService: GanttEditService
  ) {
    super(_ganttPluginHandlerService, _ganttLibService, actionHandler);
    this.intervalMapper = new GanttBlockingIntervalsMapper(this._ganttPluginHandlerService);
    this.blockingIntervalData = this.findIntervalInputData(this.backendPluginData);
    this._ganttPluginHandlerService.loadingStateService.setExpected('blockingIntervals', 1);
  }

  /**
   * Subscribes to edit service to mimic deactivation behavior of shifts
   */
  private subscribeToEditService(): Subscription {
    return this.ganttEditService
      .onGanttShiftBlockEditChange()
      .subscribe((notification: IGanttBlockEditNotification) => {
        const isEditable = notification.isEditable;
        const editSettings = this.blockingIntervalData.editAllowSettings;
        this.groupExecuter.allowShiftDragDrop(
          isEditable ? editSettings.edit_allow_changeRow || editSettings.edit_allow_move || false : false
        );
        this.groupExecuter.allowShiftResizer(isEditable ? editSettings.edit_allow_resize || false : false);
        this.groupExecuter.allowDraggingVertical(isEditable ? editSettings.edit_allow_changeRow || false : false);
        this.groupExecuter.allowDraggingHorizontal(isEditable ? editSettings.edit_allow_move || false : false);
      });
  }

  /**
   * Inits seperate GanttTimePeriodExecuter by using the GanttTimePeriodGroupExecuter plugin.
   * Based on backend data. Initializes also the action handler which builds instances for user event handling.
   * @param templateData
   * @param responseData
   */
  public onInit(templateData: any, responseData: any): void {
    this.addPlugIn(
      GanttPlugInTimePeriodGroupExecuter,
      this._ganttLibService.ganttInstanceService.getInstance(EGanttInstance.TIME_PERIOD_GROUP)
    );

    this.groupExecuter.setDrawIntervalAfterSelectionBoxDraw(false);
    this.registerIntervalTypes();
    if (this.blockingIntervalData) {
      this.getTimePeriodInputs(this.blockingIntervalData.restUrl);
    }
    this.registration = new GanttBlockingIntervalActionHandler(
      this._ganttLibService,
      this._ganttPluginHandlerService,
      this.groupExecuter,
      this.actionHandler,
      this.responseHandler,
      this.templateData,
      this.blockingIntervalData
    );
    if (this.blockingIntervalData.editAllowSettings)
      this.extractEditPermissions(this.blockingIntervalData.editAllowSettings);
    this.handleTimePeriodSelection();
    this.subscribeToMultipleSelectionCallbackByClick();
    this.ganttEditServiceSubscription = this.subscribeToEditService();
    this.responseHandler.addResponse(BlockingIntervalChangeObservation, this);
  }

  public onDestroy(): void {
    this.groupExecuter?.unsubscribeFromMultipleSelectionCallback();
    this.registration.destroy();
    this.ganttEditServiceSubscription.unsubscribe();
  }

  public onAction(action: any) {}

  public getPeriodById(id: string): JSGanttTimePeriod {
    return this.groupExecuter.getPeriodById(id);
  }

  public getRenderMap(): { [id: string]: boolean } {
    const map: { [id: string]: boolean } = {};
    this.getAllIntervalIds().forEach((id) => {
      map[id] = this.groupExecuter?.getPeriodExecuterById(id)?.isRendered();
    });
    return map;
  }

  /**
   * Returns the context menu element by the given interval type array.
   */
  public getContextMenuByIntervalType(type: string): ContextMenu {
    const inputs = this.blockingIntervalData.ganttTimePeriodGroupIntervalInputs;
    if (!Array.isArray(inputs)) {
      return null;
    }
    const result = this.blockingIntervalData.ganttTimePeriodGroupIntervalInputs.find((input) => input.clazz === type);
    if (result && result.contextMenu) {
      return this.contextMenuAdapter.inherit(ContextMenu, result.contextMenu);
    } else {
      return null;
    }
  }

  /**
   * Returns all time periods are hit by mouse position.
   */
  public getTimePeriodsByMouseEvent(
    mouseEvent: GanttScrollContainerEvent<MouseEvent>
  ): IGanttTimePeriodMouseOverResult[] {
    const intervals = this.groupExecuter.getTimePeriodsByMouseEvent(mouseEvent);
    return intervals;
  }

  /**
   * Gets a list of blocking intervals and opens a lightbox where the user can choose a single interval.
   * The selection result will be returned.
   */
  public handleMultipleIntervalSelection(
    intervals: IGanttTimePeriodMouseOverResult[]
  ): Observable<IGanttTimePeriodMouseOverResult> {
    return new Observable<IGanttTimePeriodMouseOverResult>((observer) => {
      this._ganttLibService.ngZone.run((_) => {
        const lightbox = new BlockingIntervalSelectionLightbox('chooseInterval', this.actionHandler.translate).init(
          intervals
        );
        this.actionHandler.lightboxApi.open(
          lightbox
            .setCustomConfirmAction(() => {
              const selectedValue = lightbox.selector.getValue().find((elem) => elem.isSelected());
              const interval = intervals.find((elem) => selectedValue.getUuid() === elem.block.id);
              observer.next(interval);
              observer.complete();
              return of();
            })
            .setCustomCancelAction(() => {
              this.actionHandler.lightboxApi.close();
              observer.next(null);
              observer.complete();
              return of();
            })
        );
      });
    });
  }

  /**
   * Notifies subscribers that the blocking interval data has changed through an update notification.
   * @returns {void}
   */
  public triggerBlockingIntervalChange() {
    this.blockingIntervalChanged$.next();
  }

  /**
   * Returns an Observable that emits when the blocking interval changes.
   * @returns An Observable that emits when the blocking interval changes.
   */
  public onBlockingIntervalChange(): Observable<void> {
    return this.blockingIntervalChanged$.asObservable();
  }

  /**
   * Handles the selection of blocking intervals by click or by selection box.
   */
  private handleTimePeriodSelection() {
    this.groupExecuter.addActivePlugInSelectionEndCallback(
      'ganttPlugin_onSelectionEnd',
      (selections: IGanttSelectedTimePeriod[]) => {
        if (selections && Array.isArray(selections) && selections.length) {
          const originIntervals: IGanttSelectedTimePeriod[] =
            this.intervalMapper.mapIntervalsBackToOriginId(selections);

          this.templateData.setSelectedblock({
            // TODO: must be removed as soon as the backend uses the selectedTimePeriodInput attribute (14.06.21)
            id: originIntervals[0].id,
            ganttTimePeriodGroupIntervalInputId: originIntervals[0].groupId,
            clazz: originIntervals[0].type,
          });

          this.templateData.setSelectedTimePeriodInput(originIntervals[0]);
          this.templateData.setSelectedTimePeriodInputs(originIntervals);
          this.templateData.setSelectedTimePeriodInputGroupId(originIntervals[0].groupId);
        } else {
          // reset selection on template data
          this.templateData.setSelectedblock({}); // TODO: must be removed as soon as the backend uses the selectedTimePeriodInput attribute (14.06.21)
          this.templateData.setSelectedTimePeriodInput(null);
          this.templateData.setSelectedTimePeriodInputGroupId(null);
          this.templateData.setSelectedTimePeriodInputs(null);
        }
      }
    );
  }

  /**
   * Handles registration of multiple GanttTimePeriodExecuters by backend data.
   */
  private registerIntervalTypes(): void {
    if (!this.blockingIntervalData) return;
    for (const intervalAction of this.blockingIntervalData.ganttTimePeriodGroupIntervalInputs) {
      const intervalType: IIntervalInput = this.intervalMapper.getIntervalByBackendData(intervalAction);
      const additionalData: IBlockingIntervalAdditionalData = {
        type: intervalAction.clazz,
        ganttTimePeriodGroupIntervalInputId: intervalAction.id,
      };
      const intervalExecuter: any = this.addIntervalType(intervalType);
      intervalExecuter.setAdditionalData(additionalData);
      this.registerResponseByInterval(intervalExecuter);
    }
  }

  /**
   * Registers sesponse handling for given executer.
   * @param intervalExecuter GanttTimePeriodExecuter instance.
   */
  private registerResponseByInterval(intervalExecuter: any) {
    // add specific plugin responses to response handler
    this.responseHandler.addResponse(GanttResponseBlockingIntervalUpdate, intervalExecuter);
  }

  /**
   * Loads concrete timeperiods from backend which are already inside the current gantt.
   * @param pluginRestURL Generic rest url which will be provides from temaplate data to load data.
   */
  private getTimePeriodInputs(pluginRestURL: string): void {
    if (!this.configService.access().templates.Gantt.requests.timePeriods) {
      // return if deactivated in config
      return;
    }
    this.intervalMapper
      .getIntervalTimePeriodsByRestURL(
        pluginRestURL,
        this.uiService,
        this.blockingIntervalData.ganttTimePeriodGroupIntervalInputs
      )
      .pipe(takeUntil(this._ganttPluginHandlerService.onDestroy))
      .subscribe((data) => {
        this._ganttPluginHandlerService.loadingStateService.setReceived('blockingIntervals', 1);
        for (const periodDataItem of data) {
          if (!this.groupExecuter) {
            console.warn(
              `In this subscription no instance of ${GanttPlugInTimePeriodGroupExecuter} was available when datastream resolved.`
            );
            continue;
          }
          const periodExecuter: GanttTimePeriodExecuter = this.getPlugInById(
            GanttPlugInTimePeriodGroupExecuter
          ).getPeriodExecuterById(periodDataItem.type);
          if (!periodExecuter) {
            console.warn(`There was intitially no periodExecuter of type ${periodDataItem.type} registered.`);
            continue;
          }

          const periods: TimePeriodAddData[] = periodDataItem.timePeriods.map((period): TimePeriodAddData => {
            return {
              rowId: period.rowId,
              timeStart: period.timeStart,
              timeEnd: period.timeEnd,
              intervalId: period.intervalId,
              customColor: period.customColor,
              stroke: period.stroke,
              additionalDetails: period.details,
              tooltip: period.tooltip,
              name: period.name,
              sIds: period.sIds,
            };
          });

          periodExecuter.addTimePeriodsToRow(periods, false, false);
        }
        this.groupExecuter.sortAllIntervals();
        this.groupExecuter.update();
      });
  }

  /**
   * Adds necessary data for GanttTimePeriodExecuter-creation.
   * @param interval Data to configure a new GanttTimePeriodExecuter.
   */
  public addIntervalType(interval: IIntervalInput) {
    return this.groupExecuter.addInterval(interval as any);
  }

  /**
   * Extracts blocking interval data from template by comparing all elements of the plugin list with the BlockingIntervalBackendType.
   * @param backendPluginData Plugin part of template data.
   */
  private findIntervalInputData(backendPluginData: IGanttPlugin[]): any {
    return backendPluginData.find((pluginData) => pluginData.id == BlockingIntervalBackendType);
  }

  /**
   * Will be called if user clicks on a overlapped interval.
   */
  private subscribeToMultipleSelectionCallbackByClick() {
    this.groupExecuter.subscribeToMultipleSelectionCallback((result: IGanttMultipleIntervalSelectionCB) => {
      this.handleMultipleIntervalSelection(result.intervals).subscribe((interval: IGanttTimePeriodMouseOverResult) => {
        if (interval) {
          result.res(interval);
        } else {
          result.reject();
        }
      });
    });
  }

  /**
   * Local action handling.
   * @param action Local action.
   */
  public executeAction(action: any): Observable<any> {
    if (!action.actionPlugInTargetIds) of(false);
    return this.registration.handleLocalAction(action);
  }

  /**
   * Configures GanttPlugInTimePeriodGroupExecuter by edit permissions from template data.
   * @param editSettings Edit settings.
   */
  private extractEditPermissions(editSettings: IBlockingIntervalEditSettings): void {
    this.groupExecuter.allowShiftDragDrop(editSettings.edit_allow_changeRow || editSettings.edit_allow_move || false);
    this.groupExecuter.allowShiftResizer(editSettings.edit_allow_resize || false);
    this.groupExecuter.allowDraggingVertical(editSettings.edit_allow_changeRow || false);
    this.groupExecuter.allowDraggingHorizontal(editSettings.edit_allow_move || false);
  }

  public injectSettings(submenuElements: SaxMsBestGanttActiveSubmenuEntryElementSetting[]): void {}

  /**
   * Provides intervals template plugin data.
   */
  public getArrayOfAttributeMappingTypes(): IGanttTimePeriodGroupIntervalInputs[] {
    return this.blockingIntervalData.ganttTimePeriodGroupIntervalInputs;
  }

  /**
   * Provides the generated interval ids by the original id from backend.
   * Generating ids is necessary for the case of multiple intervals with same id from backend.
   */
  public getGeneratedBlockingIntervalIdsByRealId(realId: string): string[] {
    const generatedIds: string[] = [];
    const multipleMap = this.intervalMapper.getMultipleIntervalIds();
    Array.from(multipleMap.entries()).forEach((tuple) => {
      if (tuple[1] === realId) {
        generatedIds.push(tuple[0]);
      }
    });
    if (!generatedIds.length) {
      // nothing is pushed --> seems to bee the real id
      generatedIds.push(realId);
    }
    return generatedIds;
  }

  /**
   * Activate the rendering for intervals with the specified ids.
   * @param ids Ids of all intervals that should be rendered (null or empty array deactivates all).
   * @param deactivateOthers Specifies whether all other intervals should be hidden or stay visible (if they are visible already).
   */
  public renderByIds(ids: { [id: string]: boolean } = {}, deactivateOthers = true) {
    for (const id in ids) {
      const render = ids[id];
      if (!deactivateOthers && !render) continue;
      this.groupExecuter.setRenderById(id, render);
    }
  }

  public getAllIntervalIds(): string[] {
    return this.groupExecuter.getAllIntervalIds();
  }

  public getAllExecuters() {
    return Object.values(this.groupExecuter.getAllIntervals());
  }

  /**
   * Returns an array of GanttTimePeriodExecuter objects that belong to the specified group IDs.
   * @param ids An array of group IDs to filter by.
   * @returns An array of GanttTimePeriodExecuter objects that belong to the specified group IDs.
   */
  public getAllExecutersByGroupIds(ids: string[]): GanttTimePeriodExecuter[] {
    const executers: GanttTimePeriodExecuter[] = [];
    for (const key in this.groupExecuter.getAllIntervals()) {
      const executer = this.groupExecuter.getAllIntervals()[key];
      if (
        executer?.additionalData?.ganttTimePeriodGroupIntervalInputId &&
        ids.includes(executer.additionalData.ganttTimePeriodGroupIntervalInputId)
      ) {
        executers.push(executer);
      }
    }

    return executers;
  }

  get groupExecuter(): GanttTimePeriodGroupExecuter {
    return this.getPlugInById(GanttPlugInTimePeriodGroupExecuter);
  }
}

/**
 * Data structure to bundle all necessary data to define a IntervalExecuter.
 */
export interface IIntervalInput {
  id: string;
  type: string;
  color: string;
  stroke: ITimeIntervalStroke;
  timePeriods: ITimePeriod[];
  mixedEditModeAvailable?: boolean;
}

/**
 * Data structure to define the stroke settings of each intervals.
 */
export interface ITimeIntervalStroke {
  color: string;
  width: number;
}

/**
 * Concrete timeperiod data.
 */
export interface ITimePeriod {
  rowId: string;
  timeStart: Date;
  timeEnd: Date;
  intervalId: string;
  descriptionData: any;
  stroke: ITimeIntervalStroke;
  customColor?: string;
  tooltip?: string;
  name: string;
  sIds?: string[];
  details?: {
    [index: number]: {
      t1: boolean;
      t2: number | string | string[];
    };
  };
}

/**
 * Internal data format of js gantt.
 */
export interface JSGanttTimePeriod {
  dateStart: Date;
  dateEnd: Date;
  yId: string;
  y: number;
  height: number;
  descriptionData: any;
  customColor: string;
  tooltip: string;
  name: string;
  sIds: string[];
  id: string;
}

/**
 * Data container whcih will be stored as additional data inside each blocking interval executer.
 */
export interface IBlockingIntervalAdditionalData {
  type: string;
  ganttTimePeriodGroupIntervalInputId: string;
}

/**
 * Edit settings for edit mode of bocking intervals.
 */
export interface IBlockingIntervalEditSettings {
  edit_allow_add: boolean;
  edit_allow_changeRow: boolean;
  edit_allow_delete: boolean;
  edit_allow_move: boolean;
  edit_allow_resize: boolean;
  editable: boolean;
}
