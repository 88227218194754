import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { OverlayService } from '@app-modeleditor/components/lightbox/overlay/overlay.service';
import { PatternType } from '@gantt/lib/best_gantt/script/pattern/pattern-type.enum';
import { GanttTimePeriodGroupIntervalInput } from '@gantt/lib/best_gantt/script/plug-ins/timeperiod-marker/executer-timeperiod-marker-group';
import {
  BestGantt,
  EGanttInstance,
  GanttDataContainer,
  GanttDataRow,
  GanttDataShift,
  GanttEditPermissions,
  GanttInstanceService,
} from '@gantt/public-api';
import { TranslateService } from '@ngx-translate/core';
import { DockWindowService } from 'frontend/src/dashboard/gantt/gantt/dock/dock-window/dock-window.service';
import { GanttDockService } from 'frontend/src/dashboard/gantt/gantt/dock/gantt-dock.service';
import { LegendCommunicationService } from 'frontend/src/dashboard/gantt/gantt/dock/views/legend/legend-communication.service';
import { GanttDataStorageService } from 'frontend/src/dashboard/gantt/gantt/gantt-data/data-storage.service';
import { GanttLibService } from 'frontend/src/dashboard/gantt/gantt/gantt-lib.service';
import { GanttDataInput } from 'frontend/src/dashboard/gantt/gantt/saxms-best-gantt.handler.component';
import { SaxMsBestGanttSettings } from 'frontend/src/dashboard/gantt/gantt/saxms-best-gantt.settings';
import { GanttEditService } from 'frontend/src/dashboard/gantt/general/gantt-edit/gantt-edit.service';
import { SaxMsBestGanttDiagramSettingsIntegrator } from 'frontend/src/dashboard/gantt/general/gantt-settings/saxms-best-gantt-settings.integrator';
import { GanttSettingsService } from 'frontend/src/dashboard/gantt/general/gantt-settings/service/gantt-settings.service';
import { Subscription } from 'rxjs';
import { PreviewGanttService } from './preview-gantt.service';

/**
 * Displays the preview gantt. It is also filled with sample data here.
 */
@Component({
  selector: 'app-preview-gantt',
  templateUrl: './preview-gantt.component.html',
  styleUrls: ['./preview-gantt.component.scss'],
  providers: [
    GanttLibService,
    GanttDataStorageService,
    GanttDockService,
    GanttSettingsService,
    LegendCommunicationService,
    DockWindowService,
    GanttEditService,
    { provide: 'InternalOverlayService', useClass: OverlayService },
  ],
})
export class PreviewGanttComponent implements OnInit, OnDestroy {
  @Input() settings: SaxMsBestGanttSettings;

  public ganttData: GanttDataInput = null;
  private startDate: Date = new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 10);
  private endDate: Date = new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 10);
  private settingsIntegrator: SaxMsBestGanttDiagramSettingsIntegrator;
  private settingsChangedSubscription: Subscription;
  private overlapPlugin: any;

  constructor(
    private previewGanttService: PreviewGanttService,
    private ganttLibService: GanttLibService,
    private translate: TranslateService,
    private ganttInstanceService: GanttInstanceService
  ) {
    this.ganttData = this.generateGanttInputData();
  }

  ngOnDestroy(): void {
    this.settingsChangedSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.settingsChangedSubscription = this.previewGanttService.onSettingsChanged().subscribe(() => {
      if (this.settingsIntegrator) {
        this.settingsIntegrator.integrateByStyleSettingsContainer(this.settings, true);
        this.overlapPlugin?.resetSplitOverlappingShifts(false);
        this.overlapPlugin?.splitOverlappingShifts(null, true);
      }
    });
  }

  public setGanttInstance(ganttInstance: BestGantt) {
    if (this.settingsIntegrator) return;
    this.settingsIntegrator = new SaxMsBestGanttDiagramSettingsIntegrator(ganttInstance, this.ganttInstanceService);
    this.settingsIntegrator.integrateByStyleSettingsContainer(this.settings, true);
    this.addPlugins();
    this.overlapPlugin?.splitOverlappingShifts(null, true);
  }

  private addPlugins() {
    this.initOverlapPlugin();
    this.initIntervalPlugin();
    this.initEdgePlugin();
  }

  private initOverlapPlugin() {
    this.overlapPlugin = this.ganttLibService.ganttInstanceService.getInstance(EGanttInstance.SPLIT_OVERLAPPING_SHIFTS);
    this.ganttLibService.bestGantt.addPlugIn('overlapPlugin', this.overlapPlugin);
  }

  private initIntervalPlugin() {
    const timePeriodPlugin = this.ganttLibService.ganttInstanceService.getInstance(EGanttInstance.TIME_PERIOD_GROUP);
    this.ganttLibService.bestGantt.addPlugIn('timePeriodPlugin', timePeriodPlugin);

    const interval: GanttTimePeriodGroupIntervalInput = {
      id: 'testgroup',
      type: 'testgroup',
      color: '#3D9970',
      mixedEditModeAvailable: false,
      stroke: { color: 'green', width: 3 },
      timePeriods: [
        {
          rowId: this.translate.instant('GANTT.SETTINGS.resource') + ' 2.1',
          timeStart: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 9),
          timeEnd: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 3),
          intervalId: 'id1',
          customColor: '#3D9970',
          stroke: { color: 'green', width: 3 },
          tooltip: 'Tooltip',
          name: this.translate.instant('GANTT.SETTINGS.absence'),
          sIds: [],
        },
      ],
    };
    timePeriodPlugin.addInterval(interval);
  }

  private initEdgePlugin() {
    const edgePlugin = this.ganttLibService.ganttInstanceService.getInstance(EGanttInstance.EDGE_HANDLER);
    this.ganttLibService.bestGantt.addPlugIn('edgePlugin', edgePlugin);
    this.settingsIntegrator.changeEdgePluginStrategy(this.settings.ganttPluginSettings.edgePluginRendering);

    const blockID = this.translate.instant('GANTT.SETTINGS.blockName');

    edgePlugin.createEdgeByIds(blockID + '_2', blockID + '_3', 'red', 'label', '', 0, 1, 0);
    edgePlugin.createEdgeByIds(blockID + '_3', blockID + '_4', 'blue', '', 'label', 0, 2, 0);
  }

  private generateGanttInputData(): GanttDataInput {
    return {
      ganttOrigindata: this.getGanttDataContainer(),
      editAllowSettings: this.getEditAllowSettings(),
    };
  }

  private getEditAllowSettings(): GanttEditPermissions {
    return {
      edit_allow_add: false,
      edit_allow_chain: false,
      edit_allow_changeRow: false,
      edit_allow_delete: false,
      edit_allow_move: false,
      edit_allow_resize: false,
      editable: true,
      edit_allow_row_drag: false,
      edit_allow_clicks: false,
    };
  }

  private getGanttDataContainer(): GanttDataContainer {
    return {
      currentDate: new Date(),
      ganttEntries: this.getGanttEntries(),
      globalMilestones: [],
      gridRef: null,
      id: 'preview_gantt',
      labelXAxis: '',
      labelYAxis: '',
      maxCount: 0,
      maxValue: this.endDate,
      minStepWidth: 86400000,
      minValue: this.startDate,
      title: '',
    };
  }

  private getGanttEntries(): GanttDataRow[] {
    const dayMs = 1000 * 60 * 60 * 24;
    const currentTime = new Date().getTime();
    const shift1 = this.getShift(
      this.translate.instant('GANTT.SETTINGS.blockName') + '_1',
      '#001f3f',
      new Date(currentTime - dayMs * 9.5),
      new Date(currentTime - dayMs * 8)
    );
    const shift2 = this.getShift(
      this.translate.instant('GANTT.SETTINGS.blockName') + '_2',
      '#0074D9',
      new Date(currentTime - dayMs * 4.5),
      new Date(currentTime + dayMs * 1)
    );
    const shift3 = this.getShift(
      this.translate.instant('GANTT.SETTINGS.blockName') + '_3',
      '#7FDBFF',
      new Date(currentTime - dayMs * 1),
      new Date(currentTime + dayMs * 3.5)
    );
    const shift4 = this.getShift(
      this.translate.instant('GANTT.SETTINGS.blockName') + '_4',
      '#39CCCC',
      new Date(currentTime + dayMs * 6),
      new Date(currentTime + dayMs * 9)
    );
    const shift5 = this.getShift(
      this.translate.instant('GANTT.SETTINGS.blockName') + '_5',
      '#2ECC40',
      new Date(currentTime - dayMs * 3.9),
      new Date(currentTime + dayMs * 1)
    );
    const shift6 = this.getShift(
      this.translate.instant('GANTT.SETTINGS.blockName') + '_6',
      '#B10DC9',
      new Date(currentTime - dayMs * 8),
      new Date(currentTime - dayMs * 5.5)
    );
    const shift7 = this.getShift(
      this.translate.instant('GANTT.SETTINGS.blockName') + '_7',
      '#F012BE',
      new Date(currentTime - dayMs * 6),
      new Date(currentTime - dayMs * 1)
    );
    const shift8 = this.getShift(
      this.translate.instant('GANTT.SETTINGS.blockName') + '_8',
      '#85144b',
      new Date(currentTime + dayMs * 4.5),
      new Date(currentTime + dayMs * 9.5)
    );
    const shift9 = this.getShift(
      this.translate.instant('GANTT.SETTINGS.blockName') + '_9',
      '#FF4136',
      new Date(currentTime - dayMs * 1),
      new Date(currentTime + dayMs * 0.5)
    );
    const shift10 = this.getShift(
      this.translate.instant('GANTT.SETTINGS.blockName') + '_10',
      '#FF851B',
      new Date(currentTime + dayMs * 0.5),
      new Date(currentTime + dayMs * 5)
    );
    const shift11 = this.getShift(
      this.translate.instant('GANTT.SETTINGS.blockName') + '_11',
      '#FFDC00',
      new Date(currentTime - dayMs * 7),
      new Date(currentTime - dayMs * 4)
    );

    const entries: GanttDataRow[] = [];
    const entry1 = this.getGanttEntry(
      this.translate.instant('GANTT.SETTINGS.resource') + ' 1',
      [
        this.getGanttEntry(this.translate.instant('GANTT.SETTINGS.resource') + ' 1.1', [], [shift4]),
        this.getGanttEntry(
          this.translate.instant('GANTT.SETTINGS.resource') + ' 1.2',
          [this.getGanttEntry(this.translate.instant('GANTT.SETTINGS.resource') + ' 1.2.1', [], [shift6])],
          [shift5]
        ),
      ],
      [shift1, shift2, shift3]
    );
    const entry2 = this.getGanttEntry(
      this.translate.instant('GANTT.SETTINGS.resource') + ' 2',
      [
        this.getGanttEntry(this.translate.instant('GANTT.SETTINGS.resource') + ' 2.1', [], [shift9, shift10]),
        this.getGanttEntry(this.translate.instant('GANTT.SETTINGS.resource') + ' 2.2', [], [shift11]),
      ],
      [shift7, shift8],
      '#5f90ff'
    );
    entries.push(entry1, entry2);

    return entries;
  }

  private getGanttEntry(
    name: string,
    children?: GanttDataRow[],
    blocks?: GanttDataShift[],
    color?: string
  ): GanttDataRow {
    return {
      id: name,
      name: name,
      milestones: [],
      shifts: blocks || [],
      child: children || [],
      open: true,
      group: 'NO-GROUP',
      textColor: '#ffffff',
      color: color || null,
      tooltip: name,
      subtitleElements: [this.translate.instant('GANTT.SETTINGS.details')],
      noRender: [],
      sampleValues: [],
    };
  }

  private getShift(
    shiftName: string,
    color: string,
    from: Date,
    to: Date,
    stroke?: string,
    pattern?: PatternType
  ): GanttDataShift {
    return {
      id: shiftName,
      originName: shiftName,
      name: shiftName,
      timePointStart: from,
      timePointEnd: to,
      tooltip: shiftName,
      color: color,
      firstColor: null,
      secondColor: null,
      strokeColor: stroke,
      pattern: pattern,
      patternColor: 'blue',
      noRender: [],
      highlighted: '',
      selected: null,
      editable: true,
      additionalData: {},
      modificationRestriction: null,
      opacity: 1,
      weaken: [],
      disableMove: true,
      symbols: null,
    };
  }
}
