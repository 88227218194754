<h2 class="dialog-header" mat-dialog-title>
  {{ 'MODAL.CREATE.title' | translate }}
</h2>
<mat-dialog-content class="my-content">
  <mat-form-field *ngIf="entries">
    <mat-label>{{ 'MODAL.CREATE.header' | translate }}</mat-label>
    <mat-select [(value)]="selected">
      <mat-option *ngFor="let entry of entries" [value]="entry.data">
        {{ entry.key }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions class="dialog-actions">
  <button mat-button mat-dialog-close>{{ 'BUTTON.CANCEL' | translate }}</button>
  <button mat-button (click)="confirm()" [disabled]="!selected">{{ 'BUTTON.CONFIRM' | translate }}</button>
</mat-dialog-actions>
