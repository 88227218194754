import { GanttLibService } from 'frontend/src/dashboard/gantt/gantt/gantt-lib.service';
import { GeneralGanttActionHandler } from 'frontend/src/dashboard/gantt/general/action-handling/action-handler';
import { TriggerEventFactory } from 'frontend/src/dashboard/gantt/general/gantt-actions/event-factory';
import { GanttExternalActionRegistration } from 'frontend/src/dashboard/gantt/general/gantt-actions/external-action-registration';
import { GanttEventTrigger } from 'frontend/src/dashboard/gantt/general/gantt-actions/external-event-trigger';
import { GanttPluginHandlerService } from 'frontend/src/dashboard/gantt/general/plugin/gantt-plugin-handler.service';
import { GanttResponseHandler } from 'frontend/src/dashboard/gantt/general/response/response-handler';
import { Observable, of } from 'rxjs';
import { GanttResourceChangeMode } from '../resource-change.mode';
import { ShiftSplitterAdditionalData } from '../resource-change.plugin';
import { TriggerEventResourceChangeFactory } from './event-factory';

/**
 * Action handler for all user interaction of GanttResourceChangePlugIn.
 */
export class GanttResourceChangeActionHandler implements GanttExternalActionRegistration {
  private triggerEventFactory: TriggerEventFactory;

  constructor(
    private _ganttPluginHandlerService: GanttPluginHandlerService,
    private _ganttLibService: GanttLibService,
    private ganttResourceChangeExecuter: any,
    private actionHandler: GeneralGanttActionHandler,
    private responseHandler: GanttResponseHandler,
    templateData: any,
    resourceChangeData: any
  ) {
    this.triggerEventFactory = new TriggerEventResourceChangeFactory(templateData);
    const currentActionTriggers = this.extractEventTriggersByGanttActions(resourceChangeData.ganttActions);
  }

  /**
   * Inserts all local actions by backend configuration using the action factory.
   * @param ganttResourceChangeActions
   */
  private extractEventTriggersByGanttActions(ganttResourceChangeActions: any[]): GanttEventTrigger[] {
    let triggerList: GanttEventTrigger[] = [];
    // add local actions
    triggerList = triggerList.concat(
      this.triggerEventFactory.getTriggerEventByActionTypes(
        this._ganttPluginHandlerService,
        this._ganttLibService,
        ganttResourceChangeActions,
        this.ganttResourceChangeExecuter,
        this.actionHandler,
        this.responseHandler
      )
    );
    return triggerList;
  }

  /**
   * Executes given local action.
   * @param localAction Local action.
   * @param callBackParams Additional parameters which will be inserted.
   */
  public handleLocalAction(localAction: any, callBackParams?: any): Observable<any> {
    if (localAction.actionType == 'PREDEFINED') {
      // toggle plugin states
      switch (localAction.id) {
        case GanttResourceChangeMode.CREATE:
        case GanttResourceChangeMode.EDIT:
        case GanttResourceChangeMode.DELETE:
          this.ganttResourceChangeExecuter.exitShiftSplitMode();
          const additionalData: ShiftSplitterAdditionalData =
            this.ganttResourceChangeExecuter.getAdditionalData() as ShiftSplitterAdditionalData;
          if (additionalData.state != localAction.id) {
            this.ganttResourceChangeExecuter.setShowMarker(localAction.id == GanttResourceChangeMode.CREATE);
            this.ganttResourceChangeExecuter.startSplitByClickMode(false, 'customized');
            additionalData.state = localAction.id;
          } else {
            this.ganttResourceChangeExecuter.setShowMarker(true);
            additionalData.state = GanttResourceChangeMode.SLEEP;
          }
          return of(true);
      }
    }

    return of(null);
  }
}
