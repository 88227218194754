/**
 * Milestone proportions.
 * @keywords config, milestone, proportion, size
 * @class
 * @constructor
 * @param {number} width Distance on top between vertical row start and vertical shift position.
 * @param {number} height Distance on bottom between vertical row start and vertical shift position.
 */
export class GanttMilestoneProportions {
  width: number;
  height: number;

  constructor(width, height) {
    this.width = width;
    this.height = height;
  }
}

/**
 * Y axis config.
 */
export interface IGanttYAxisConfig {
  width: number;
  scrollEndTimeout: number;
  indicatorWidth: number;
  entryColorization: boolean;
}

/**
 * Settings for customized scrollbars.
 * @interface
 */
export interface GanttScrollbarSettings {
  width: number;
  thumbPadding: number;
  thumbColor: string;
  thumbColorHover: string;
  thumbColorActive: string;
  trackColor: string;
  showArrowButtons: boolean;
  arrowColor: string;
  arrowColorHover: string;
  arrowColorActive: string;
}
