import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatLegacySlideToggleChange as MatSlideToggleChange } from '@angular/material/legacy-slide-toggle';
import { MatLegacySliderChange as MatSliderChange } from '@angular/material/legacy-slider';
import { SaxMsBestGanttSettings } from 'frontend/src/dashboard/gantt/gantt/saxms-best-gantt.settings';
@Component({
  selector: 'app-rows',
  templateUrl: './rows.component.html',
  styleUrls: ['./rows.component.scss'],
})
export class RowsComponent implements OnInit {
  @Input() settings: SaxMsBestGanttSettings;
  @Output() changeEvent: EventEmitter<null> = new EventEmitter();

  constructor(private cdRef: ChangeDetectorRef) {}

  ngOnInit(): void {}

  public handleColorOddRows(event: MatSlideToggleChange): void {
    this.settings.colorizeRowSibling = event.checked;
    this.changeEvent.emit();
  }

  public handleRowClorHierarchieActivity(event: MatSlideToggleChange): void {
    this.settings.rowColorHierarchies.active = event.checked;
    this.changeEvent.emit();
  }

  public setRowColorHierarchy(color: string, index: number) {
    this.settings.rowColorHierarchies.colors[index] = color;
    this.changeEvent.emit();
  }

  protected handleRowEntryColorization(event: MatSlideToggleChange): void {
    this.settings.ganttEntryColorization = event.checked;
    this.changeEvent.emit();
  }

  public rowHeightSliderChange(event: MatSliderChange) {
    this.settings.rowHeight = event.value;
    this.cdRef.detectChanges();
    this.changeEvent.emit();
  }

  public handleStickyRowsActivity(event: MatSlideToggleChange): void {
    this.settings.showStickyRows = event.checked;
    this.changeEvent.emit();
  }

  public handleFirstStickyRowsActivity(event: MatSlideToggleChange): void {
    this.settings.enableFirstRowStickyMode = event.checked;
    this.changeEvent.emit();
  }

  public handleFirstStickyRowWithChildRowsActivity(event: MatSlideToggleChange): void {
    this.settings.enableFirstRowWithChildRowsStickyMode = event.checked;
    this.changeEvent.emit();
  }
}
