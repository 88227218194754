export enum DataType {
  menu = 'menu',
  toolbar = 'toolbar',
  hierarchicMenuItem = 'hierarchicmenuitem',
  breadcrumbs = 'breadcrumbs',
  entryCollection = 'entrycollection',
  entryElement = 'entryelement',
  fileViewer = 'fileviewer',
}

export interface BaseTemplate {
  id: string;
  resourceId?: string;
  type: DataType;
  updateTemplateRestUrl?: string;
  restUrl?: string;
}
