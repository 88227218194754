import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HotspotComponent } from './hotspot.component';
@NgModule({
  declarations: [HotspotComponent],
  exports: [HotspotComponent],
  imports: [CommonModule, DragDropModule],
})
export class HotspotModule {}
