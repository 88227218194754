import { BaseContentElement } from '../base/base-content-element';
import { DataType } from '../base/base-template';
import { InputType, mapItem } from '../mapper';

export interface HierarchicalMenuItem extends BaseContentElement {
  type: DataType.hierarchicMenuItem;
  hierachicMenuItems: string[];
}

export const mapHierarchicalMenuItem = <T extends DataType>(item: InputType<T>): HierarchicalMenuItem => {
  const children = item?.hierachicMenuItems?.map((i: InputType<T>) => mapItem(i)) ?? [];
  const hmi: HierarchicalMenuItem = {
    ...item,
    hierachicMenuItems: children,
    type: DataType.hierarchicMenuItem,
  } as HierarchicalMenuItem;

  return hmi;
};
