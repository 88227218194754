export enum EFileState {
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  LOADING = 'LOADING',
}
export enum EFileLoadingType {
  UPLOAD = 'file_upload',
  DOWNLOAD = 'file_download',
}

export enum EFileType {
  FILE,
  DIRECTORY,
}
