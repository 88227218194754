import { takeUntil } from 'rxjs';
import { GanttConfig } from '../../config/gantt-config';
import { BestGantt } from '../../main';

export class MainConfigChangeCallbacks {
  ganttDiagram: BestGantt;
  config: GanttConfig;

  constructor(ganttDiagram, config) {
    this.ganttDiagram = ganttDiagram;
    this.config = config;
  }

  /**
   * Adds config change callbacks.
   *
   */
  initCallbacks() {
    this.config.addAfterShiftFontSizeChangedCallback(
      'afterConfigChangeShiftFontSize',
      this.ganttDiagram.rerenderShiftTextOverlay.bind(this.ganttDiagram)
    );
    this.config
      .onRowFontSizeChanged()
      .pipe(takeUntil(this.ganttDiagram.onDestroy))
      .subscribe(() => this.ganttDiagram.rerenderYAxisByScroll());
    this.config
      .onShiftHeightChanged()
      .pipe(takeUntil(this.ganttDiagram.onDestroy))
      .subscribe(() => this.ganttDiagram.updateHeight());
  }
}
