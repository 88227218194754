import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatDialogModule } from '@angular/material/dialog';
import { MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { IPublicClientApplication, InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { ConfigService as ConfigApi } from '@core/config/config.service';
import { AppErrorsHandler } from '@core/error-handler.service';
import { MessageModule } from '@core/message/message.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SystemMessageComponent } from 'frontend/src/dashboard/system/system-messages/system-message.component';
import { msalConfig } from '../app.config';
import { SolverService } from '../data/solver/solver.service';
import { GridGloablService } from '../moving-grid/grid-global.service';
import { FunctionPermissionService } from '../permission/functionpermission.service';
import { ToolbarDialogsModule } from '../view/navbar/toolbar/dialogs/dialogs.module';
import { SharedToolbarService } from '../view/navbar/toolbar/shared-toolbar.service';
import { AuthenticationService } from './authentication/auth.service';
import { IConfig } from './config/config.interface';
import { HttpNetworkInterceptor } from './http.interceptor';
import { RequestInterceptor } from './main.interceptor';
import { MaterialModule } from './material/material.module';
import { LanguageService } from './provider/language.service';

export const MSALInstanceFactory = async (config: IConfig): Promise<IPublicClientApplication> => {
  const { tenantId, clientId, redirectUri, postLogoutRedirectUri } = config.sso;
  msalConfig.auth.authority = `https://login.microsoftonline.com/${tenantId}`;
  msalConfig.auth.clientId = clientId;
  msalConfig.auth.redirectUri = redirectUri;
  msalConfig.auth.postLogoutRedirectUri = postLogoutRedirectUri;
  msalConfig.cache.claimsBasedCachingEnabled = true;
  const msalInstance = new PublicClientApplication(msalConfig);
  await msalInstance.initialize();
  return msalInstance;
};

export const MSALGuardConfigFactory = (): MsalGuardConfiguration => ({ interactionType: InteractionType.Popup });

@NgModule({
  imports: [
    ToolbarDialogsModule,
    CommonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: setTranslateLoader,
        deps: [HttpClient],
      },
    }),
    MessageModule,
    MatDialogModule,
    HttpClientModule,
    MatMomentDateModule,
    MessageModule,
  ],
  declarations: [SystemMessageComponent],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpNetworkInterceptor, multi: true },
    { provide: ErrorHandler, useClass: AppErrorsHandler },
  ],
  exports: [TranslateModule, MaterialModule],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        { provide: 'Window', useValue: window },
        FunctionPermissionService,
        {
          provide: APP_INITIALIZER,
          useFactory: configApiFactory,
          deps: [ConfigApi],
          multi: true,
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        LanguageService,
        GridGloablService,
        SharedToolbarService,
        AuthenticationService,
        SolverService,
      ],
    };
  }
}

export function setTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function functionPermissionFactory(functionPermissionService: FunctionPermissionService) {
  return (): Promise<any> => functionPermissionService.load();
}

export function configApiFactory(config: ConfigApi) {
  return (): Promise<any> => config.load();
}
