import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { ConditionsMenuModule } from '@app-modeleditor/components/elements/core/conditions-menu/conditions-menu.module';
import { TranslateModule } from '@ngx-translate/core';
import { TimePickerComponent } from './time-picker.component';
import { TimePipe } from './time.pipe';

@NgModule({
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    TranslateModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    FormsModule,
    MatIconModule,
    MatButtonModule,
    ConditionsMenuModule,
  ],
  declarations: [TimePipe, TimePickerComponent],
  exports: [TimePipe, TimePickerComponent],
})
export class TimePickerModule {}
