import { YAxisDataFinder } from '../../../data-handler/data-finder/yaxis-data-finder';
import { GanttCanvasShift } from '../../../data-handler/data-structure/data-structure';
import { BestGantt } from '../../../main';
import { ETimeGradationRoundingType } from '../../../time-gradation-handler/time-gradation-handler';
import { GanttShiftEditLimiterAdapter } from '../../shift-edit-general/edit-restrictions/shift-edit-limiter-adapter';
import { EGanttShiftResizeDraggingDirection } from '../resize-events/resize-dragging-direction.enum';

/**
 * Class which handles shift resize restrictions.
 */
export class GanttShiftResizeLimiter extends GanttShiftEditLimiterAdapter {
  private _allowShiftResizing = true;

  private _resizeHandleRestrictions: { [id: string]: IResizeHandleRestrictions } = {};

  constructor(ganttDiagram: BestGantt) {
    super(ganttDiagram);
  }

  //
  // GENERAL RESTRICTIONS
  //

  /**
   * Flag indicating if shift resizing is currently allowed or not.
   */
  public get allowShiftResizing(): boolean {
    return this._allowShiftResizing;
  }

  /**
   * Flag indicating if shift resizing is currently allowed or not.
   */
  public set allowShiftResizing(value: boolean) {
    this._allowShiftResizing = value;
  }

  //
  // RESIZE HANDLES
  //

  /**
   * Checks all resize handle restrictions for the specified shift and decides for each dragging direction if a resize handle should be built or not.
   * @param shift Shift to check the resize handle restrictions for.
   * @returns Data structure with check results.
   */
  public getResizeHandleRestrictionsByShift(shift: GanttCanvasShift): IResizeHandleRestrictions {
    return {
      left: this.getResizeHandleRestrictionByShift(shift, EGanttShiftResizeDraggingDirection.LEFT),
      right: this.getResizeHandleRestrictionByShift(shift, EGanttShiftResizeDraggingDirection.RIGHT),
    };
  }

  /**
   * Checks all resize handle restrictions for the specified shift and decides for the specified dragging direction if a resize handle should be built or not.
   * @param shift Shift to check the resize handle restrictions for.
   * @param orientation Dragging direction to check the resize handle restrictions for.
   * @returns Check result.
   */
  public getResizeHandleRestrictionByShift(
    shift: GanttCanvasShift,
    orientation: EGanttShiftResizeDraggingDirection
  ): boolean {
    // Check for all restrictions in the right order.
    let checkGeneralRestriction = true;
    // 1) Check if resizing on the specified side is allowed.
    if (shift.modificationRestriction) {
      if (shift.modificationRestriction[`edit_allow_resize_${orientation}`] === false) return false;
      if (shift.modificationRestriction[`edit_allow_resize_${orientation}`] === true) checkGeneralRestriction = false;
    }
    // 2) Check if resizing in general is allowed.
    if (
      checkGeneralRestriction &&
      shift.modificationRestriction &&
      shift.modificationRestriction.edit_allow_resize === false
    ) {
      return false;
    }
    // 3) Check if custom restrictions are existing.
    if (this._resizeHandleRestrictions[shift.id] && this._resizeHandleRestrictions[shift.id][orientation] === false) {
      return false;
    }

    return true;
  }

  /**
   * Sets resize handle restrictions for the specified shift.
   * @param shiftId Shift to set the restrictions for.
   * @param left Restriction for left resize handle.
   * @param right Restriction for right resize handle.
   */
  public setResizeHandleRestriction(shiftId: string, left = true, right = true): void {
    if (!this._resizeHandleRestrictions[shiftId]) this._resizeHandleRestrictions[shiftId] = { left: true, right: true };
    this._resizeHandleRestrictions[shiftId].left = left;
    this._resizeHandleRestrictions[shiftId].right = right;
  }

  //
  // EARLIEST START / LATEST END
  //

  /**
   * Checks if resize event violates ES/LE restrictions of the specified shift.
   * @param shift Shift to check the ES/LE restrictions for.
   * @param orientation Resize dragging orientation to check the ES/LE restrictions for.
   * @returns True if the restrictions are violated, false if not.
   */
  public isResizeEventViolatingEsLeRestrictions(
    shift: GanttCanvasShift,
    orientation: EGanttShiftResizeDraggingDirection
  ): boolean {
    const xAxisBuilder = this._ganttDiagram.getXAxisBuilder();

    if (this.handleEsLeForShift(shift)) {
      const globalscale = xAxisBuilder.getGlobalScale();

      const dragX = xAxisBuilder.pxToTime(shift.x, globalscale);
      if (this.handleEarliestStartForShift(shift)) {
        const earliestStartTime = shift.modificationRestriction.earliestStartTime;
        if (orientation === EGanttShiftResizeDraggingDirection.LEFT && dragX.getTime() <= earliestStartTime) {
          return true;
        }
      }
      if (this.handleLatestEndForShift(shift)) {
        const latestEndTime = shift.modificationRestriction.latestEndTime;
        if (orientation === EGanttShiftResizeDraggingDirection.RIGHT && dragX.getTime() >= latestEndTime) {
          return true;
        }
      }
    }
    return false;
  }

  //
  // TIME RESTRICTIONS
  //

  /**
   * Checks the specified date if it exceeds resize restrictions and corrects it if necessary.
   * @param date Date to check.
   * @param orientation Resize orientation to check for.
   * @param canvasShift Canvas shift data containing the restrictions.
   * @returns Checked and corrected date.
   */
  public getRestrictedDate(
    date: Date,
    orientation: EGanttShiftResizeDraggingDirection,
    shiftData: GanttCanvasShift
  ): Date {
    if (!shiftData.modificationRestriction) return date;
    const restrictedTimespanStart = shiftData.modificationRestriction[`restrict_resize_${orientation}_from`];
    const restrictedTimespanEnd = shiftData.modificationRestriction[`restrict_resize_${orientation}_to`];

    if (!isNaN(restrictedTimespanStart)) {
      if (date.getTime() < restrictedTimespanStart) {
        date = new Date(restrictedTimespanStart);
      }
    }
    if (!isNaN(restrictedTimespanEnd)) {
      if (date.getTime() > restrictedTimespanEnd) {
        date = new Date(restrictedTimespanEnd);
      }
    }

    return date;
  }

  /**
   * Checks the specified x coordinate if it exceeds resize restrictions and corrects it if necessary.
   * @param x X coordinate to check.
   * @param orientation Resize orientation to check for.
   * @param canvasShift Canvas shift data containing the restrictions.
   * @returns Checked and corrected x coordinate.
   */
  public getRestrictedXCoordinate(
    x: number,
    orientation: EGanttShiftResizeDraggingDirection,
    shiftData: GanttCanvasShift
  ): number {
    const xAxisBuilder = this._ganttDiagram.getXAxisBuilder();
    const currentScale = xAxisBuilder.getCurrentScale();
    const restrictedDate = this.getRestrictedDate(xAxisBuilder.pxToTime(x, currentScale), orientation, shiftData);
    return currentScale(restrictedDate);
  }

  /**
   * Checks the specified date if it exceeds resize restrictions.
   * @param date Date to check.
   * @param orientation Resize orientation to check for.
   * @param canvasShift Canvas shift data containing the restrictions.
   * @returns Flag indicating whether restrictions are violated (= true) or not (= false).
   */
  public isDateViolatingRestrictions(
    date: Date,
    orientation: EGanttShiftResizeDraggingDirection,
    shiftData: GanttCanvasShift
  ): boolean {
    if (!shiftData.modificationRestriction) return false;
    const restrictedTimespanStart = shiftData.modificationRestriction[`restrict_resize_${orientation}_from`];
    const restrictedTimespanEnd = shiftData.modificationRestriction[`restrict_resize_${orientation}_to`];

    if (!isNaN(restrictedTimespanStart) && date.getTime() < restrictedTimespanStart) {
      return true;
    }
    if (!isNaN(restrictedTimespanEnd) && date.getTime() > restrictedTimespanEnd) {
      return true;
    }

    return false;
  }

  /**
   * Checks the specified x coordinate if it exceeds resize restrictions.
   * @param x X coordinate to check.
   * @param orientation Resize orientation to check for.
   * @param canvasShift Canvas shift data containing the restrictions.
   * @returns Flag indicating whether restrictions are violated (= true) or not (= false).
   */
  public isXCoordinateViolatingRestrictions(
    x: number,
    orientation: EGanttShiftResizeDraggingDirection,
    shiftData: GanttCanvasShift
  ): boolean {
    const xAxisBuilder = this._ganttDiagram.getXAxisBuilder();
    const currentScale = xAxisBuilder.getCurrentScale();
    return this.isDateViolatingRestrictions(xAxisBuilder.pxToTime(x, currentScale), orientation, shiftData);
  }

  //
  // TRANSLATION AREA RESTRICTIONS
  //

  /**
   * Checks the specified x coordinate if it violates translation area restrictions and corrects it if necessary.
   * @param x X coordinate to check.
   * @param orientation Resize orientation to check for.
   * @param shiftData Canvas shift data of the shift to check the restrictions for.
   * @returns Checked and corrected x coordinate.
   */
  public getTranslationAreaConformingXCoordinate(
    x: number,
    orientation: EGanttShiftResizeDraggingDirection,
    shiftData: GanttCanvasShift
  ): number {
    const currentRowId = YAxisDataFinder.getRowByYPosition(
      this._ganttDiagram.getDataHandler().getYAxisDataset(),
      shiftData.y
    ).id;
    const zoomTransform = this._ganttDiagram.getXAxisBuilder().getLastZoomEvent();
    const globalScale = this._ganttDiagram.getXAxisBuilder().getGlobalScale();

    if (orientation === EGanttShiftResizeDraggingDirection.LEFT) {
      const restriction = this._ganttDiagram
        .getShiftTranslator()
        .shiftEditLimiterAdapter.translationAreaLimiter.checkAreaRestriction(
          shiftData.id,
          currentRowId,
          this._ganttDiagram.getXAxisBuilder().pxToTime(shiftData.x, globalScale),
          this._ganttDiagram.getXAxisBuilder().pxToTime(shiftData.x, globalScale)
        );
      const areaEndX = globalScale(restriction.areaEnd) * zoomTransform.k + zoomTransform.x;

      // if shift is in restricted area -> update x value
      if (x < areaEndX && !restriction.allowDrop) {
        return areaEndX;
      }
    } else {
      const restriction = this._ganttDiagram
        .getShiftTranslator()
        .shiftEditLimiterAdapter.translationAreaLimiter.checkAreaRestriction(
          shiftData.id,
          currentRowId,
          this._ganttDiagram.getXAxisBuilder().pxToTime(shiftData.x + shiftData.width, globalScale),
          this._ganttDiagram.getXAxisBuilder().pxToTime(shiftData.x + shiftData.width, globalScale)
        );
      const areaStartX = globalScale(restriction.areaStart) * zoomTransform.k + zoomTransform.x;

      // if shift is in restricted area -> update x value
      if (x > areaStartX && restriction.areaStart != null && !restriction.allowDrop) {
        return areaStartX;
      }
    }

    return x;
  }

  //
  // TIME GRADATION
  //

  /**
   * Sets a grid ref date if a customized enum time grid is active.
   * @param shiftData Canvas shift data of the currently resized shift.
   * @param orientation Dragging orientation of the current resize action.
   */
  public setGridStartDateRef(shiftData: GanttCanvasShift, orientation: EGanttShiftResizeDraggingDirection): void {
    const xAxisBuilder = this._ganttDiagram.getXAxisBuilder();

    if (this.timeGradationHandler.isStepSizeEnum() && this.timeGradationHandler.getDefaultTimeGrid() === 'customized') {
      let coordinate = 0;
      if (orientation === EGanttShiftResizeDraggingDirection.LEFT) {
        // handle left
        coordinate = shiftData.x;
      } else {
        // handle right
        coordinate = shiftData.x + shiftData.width;
      }
      // set ref date
      this.timeGradationHandler.setCustomizedStartDate(
        xAxisBuilder.pxToTime(coordinate, xAxisBuilder.getGlobalScale())
      );
    }
  }

  /**
   * Manipulates the specified x coordinate to align to a time grid.
   * @param {number} x X coordinate to manipulate.
   * @param {EGanttShiftResizerDraggingDirection} [orientation=null] Dragging orientation of the current resize action (optional parameter for restriction checks).
   * @param {GanttCanvasShift} [shiftData=null] Canvas shift data of the currently resized shift (optional parameter for restriction checks).
   */
  public getGradiatedXCoordinate(
    x: number,
    orientation: EGanttShiftResizeDraggingDirection = null,
    shiftData: GanttCanvasShift = null
  ): number {
    const zoomTransform = this._ganttDiagram.getXAxisBuilder().getLastZoomEvent();

    const strokeWidth = this._ganttDiagram
      .getShiftFacade()
      .getShiftBuilder()
      .getShiftCalculationStrategy()
      .getShiftStrokeWidth(shiftData);

    // for the calculation a conversion for the future shift without stroke is necessary
    let diff = 0;
    if (orientation === EGanttShiftResizeDraggingDirection.LEFT) {
      diff = -strokeWidth / 2;
    } else {
      diff = strokeWidth / 2;
    }

    let alignedX =
      this.timeGradationHandler.getAliginXCoordinateByXCoordinate((x + diff - zoomTransform.x) / zoomTransform.k) -
      diff;

    // if restriction data was specified -> check restrictions
    if (orientation && shiftData) {
      if (this.isXCoordinateViolatingRestrictions(alignedX, orientation, shiftData)) {
        // if earlier than start date of allowed time span -> round up
        if (alignedX < x) {
          alignedX =
            this.timeGradationHandler.getAliginXCoordinateByXCoordinate(
              (x + diff - zoomTransform.x) / zoomTransform.k,
              null,
              ETimeGradationRoundingType.UP
            ) - diff;
        }
        // if later than end date of allowed time span -> round down
        else if (alignedX > x) {
          alignedX =
            this.timeGradationHandler.getAliginXCoordinateByXCoordinate(
              (x + diff - zoomTransform.x) / zoomTransform.k,
              null,
              ETimeGradationRoundingType.DOWN
            ) - diff;
        }
      }
    }

    return alignedX;
  }
}

/**
 * Data structure for storing resize handle restrictions for a specific shift.
 */
interface IResizeHandleRestrictions {
  left: boolean;
  right: boolean;
}
