import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Subscription } from 'rxjs';
import { GanttLibService } from '../../../gantt-lib.service';
import { GanttDockService } from '../../gantt-dock.service';
import { IResourceGroup } from './components/resource-element/resource-group.inerface';
import { ResourceCommunicationService } from './resource-communication.service';
import { ResourcesDataService } from './resources-data.service';

@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.scss'],
})
export class ResourcesComponent implements OnInit, OnDestroy {
  @Input() menuOpen = false;
  public favoritesGroup: IResourceGroup;
  private _favoritesChangeSubscription: Subscription = null;
  private _ganttInitSubscription: Subscription = null;

  constructor(
    public ganttDockService: GanttDockService,
    public resourcesDataService: ResourcesDataService,
    private _resourceCommunicationService: ResourceCommunicationService,
    private _ganttLibService: GanttLibService
  ) {}

  ngOnInit(): void {
    this.favoritesGroup = this.generateFavoriteResourceGroup();
    this._subscribeToFavoritesChange();
    this._subscribeToGanttSelection();
  }

  ngOnDestroy(): void {
    this._favoritesChangeSubscription.unsubscribe();
    this._ganttInitSubscription.unsubscribe();
  }

  public onBackgroundClick(): void {
    this._resourceCommunicationService.clearSelectedResources();
    this._resourceCommunicationService.emitSingleSelection();
  }

  public generateFavoriteResourceGroup(): IResourceGroup {
    return {
      id: 'favoritesGroupId',
      name: 'Favoriten',
      icon: 'grade',
      elements: this.resourcesDataService.favorites.map((id) => this.resourcesDataService.getResourceById(id)),
    };
  }

  private _subscribeToFavoritesChange(): void {
    this._favoritesChangeSubscription = this.resourcesDataService.listenForFavoritesChange().subscribe((_) => {
      this.favoritesGroup = this.generateFavoriteResourceGroup();
    });
  }

  private _subscribeToGanttSelection(): void {
    this._ganttInitSubscription = this._ganttLibService.afterInit.subscribe((isInit) => {
      if (!isInit) {
        return;
      }
      this._ganttLibService.bestGantt
        .getSelectionBoxFacade()
        .afterDeselection.pipe(takeUntilDestroyed())
        .subscribe(() => this._resourceCommunicationService.emitHighlight(null));
    });
  }
}
