import { ConfigService } from '@core/config/config.service';
import { GanttLibService } from 'frontend/src/dashboard/gantt/gantt/gantt-lib.service';
import { GanttPluginHandlerService } from 'frontend/src/dashboard/gantt/general/plugin/gantt-plugin-handler.service';
import { Logger } from 'frontend/src/dashboard/shared/utils/logger';
import { GeneralGanttActionHandler } from '../../action-handling/action-handler';
import { GanttActionService } from '../../gantt-action.service';
import { IGanttTemplateValue } from '../gantt-input.data';
import { GanttContextMenuHandler } from './context-menu/context-menu';
import { GanttShiftTranslationRestrictionHandler } from './edit-restriction/shift-translation/shift-translation';
import { GanttSelectBlockActionHandler } from './events/select/select';
import { GanttMarkedHolidays } from './holidays/holidays';
import { GanttMarkFreeDaysHandler } from './marked-days/marked-days';
import { GanttMarkedTimeSpans } from './marked-timespans/marked-timespans';
import { GanttPredefinedSetting } from './predefined-setting';
import { GanttInitZoomLevelHandler } from './time-manipulation/init-zoom-level/init-zoom-level';

export class GanttPredefinedSettings {
  private readonly logger = new Logger('gantt-predefined-settings');
  private readonly predefinedHandlingTypes: (typeof GanttPredefinedSetting)[] = [
    GanttShiftTranslationRestrictionHandler,
    GanttInitZoomLevelHandler,
    GanttMarkFreeDaysHandler,
    GanttMarkedHolidays,
    GanttMarkedTimeSpans,
    GanttContextMenuHandler,
    GanttSelectBlockActionHandler,
  ];
  private predefinedHandlers: GanttPredefinedSetting[] = [];

  constructor(
    ganttPluginHandlerService: GanttPluginHandlerService,
    ganttLibService: GanttLibService,
    response: IGanttTemplateValue,
    templateData: any,
    actionHandler: GeneralGanttActionHandler,
    contextMenuService: GanttActionService,
    configService: ConfigService
  ) {
    this.predefinedHandlingTypes.forEach((type) => {
      const instance = new type(ganttPluginHandlerService, ganttLibService, actionHandler, configService, this.logger);
      this.predefinedHandlers.push(instance);
      instance.onInit(templateData, response, contextMenuService);
    });
  }

  public getInstance<instance extends GanttPredefinedSetting>(type: typeof GanttPredefinedSetting): instance {
    return this.predefinedHandlers.find((elem) => elem instanceof type) as instance;
  }
}
