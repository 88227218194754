import { IGanttAdditionalRowData } from '@gantt/public-api';
import { EGanttBlockAttributeDataType } from '../../toolbar/filter/lightbox/attribute-data-types.enum';
import { IGanttAttributeMapping, IGanttDetails, IGanttVisibleAttributes } from '../gantt-input.data';
import { GanttRowAdditionalDataMapper } from './gantt-row-additional-data.mapper';

/**
 * Bundles all tooltip mapping including attribute mapping.
 */
export const UtilTooltipMapper = {
  /**
   * Combines all tooltip data to one HTML-Tooltip.
   * @param additionalDetails Block details to find tooltip rows.
   * @param attributeMapping To encrypt number based rows.
   * @param additionalTooltipDataPrefix Key-value JSON which will be added row-wise to the first position to the tooltip.
   * @param additionalTooltipDataSuffix Key-value JSON which will be added row-wise to the last position to the tooltip.
   */
  getTooltipByTemplateData: function (
    additionalDetails: any,
    attributeMapping: IGanttAttributeMapping,
    additionalTooltipDataPrefix?: any,
    additionalTooltipDataSuffix?: any
  ): string {
    let tooltip = '<table>';
    if (additionalTooltipDataPrefix)
      tooltip += UtilTooltipMapper._getHTMLTableRowStrucuteByAdditionalTooltipData(additionalTooltipDataPrefix);
    if (attributeMapping) {
      for (const key in additionalDetails) {
        if (additionalDetails[key].t1)
          tooltip += UtilTooltipMapper._getHTMLTableRowStrucuteByAdditionalDetail(
            key,
            additionalDetails[key].t2,
            attributeMapping
          );
      }
    } else {
      console.warn(`Tooltip attribute Mapping was not provided!`);
    }
    if (additionalTooltipDataSuffix)
      tooltip += UtilTooltipMapper._getHTMLTableRowStrucuteByAdditionalTooltipData(additionalTooltipDataSuffix);
    tooltip += '</table>';
    if (tooltip === '<table></table>') {
      return null;
    } // empty tooltip
    return tooltip;
  },

  /**
   * Combines all tooltip data to one HTML-Tooltip for gantt blocks.
   * @param additionalDetails Block details to find tooltip rows.
   * @param attributeMapping To encrypt number based rows.
   */
  getBlockTooltipByTemplateData: function (
    blockDetails: IGanttDetails,
    attributeMapping: IGanttAttributeMapping,
    tooltipSettings: IGanttVisibleAttributes,
    includeBrokenConstraints = true
  ): string {
    let tooltip = '<table>';
    const additionalDetails = blockDetails.additionalDetails;
    const brokenConstraints = blockDetails.brokenConstraints;

    if (attributeMapping) {
      if (tooltipSettings?.attributeMetadata) {
        // apply list of block tooltip settings
        for (const attributeData of tooltipSettings.attributeMetadata) {
          if (additionalDetails[attributeData.attributeID]?.t1) {
            tooltip += UtilTooltipMapper._getHTMLTableRowStrucuteByAdditionalDetail(
              attributeData.attributeID + '',
              additionalDetails[attributeData.attributeID].t2,
              attributeMapping
            );
          }
        }
      }

      // add attributes where the key is not a numeric value (e.g. "1. Regelbruch")
      for (const key in additionalDetails) {
        if (additionalDetails[key].t1 && isNaN(+key)) {
          tooltip += UtilTooltipMapper._getHTMLTableRowStrucuteByAdditionalDetail(
            key,
            additionalDetails[key].t2,
            attributeMapping
          );
        }
      }

      if (includeBrokenConstraints) {
        // add broken constraints if set
        if (brokenConstraints) {
          for (const key in brokenConstraints) {
            if (brokenConstraints[key].t1) {
              tooltip += UtilTooltipMapper._getHTMLTableRowStrucuteByAdditionalDetail(
                key,
                brokenConstraints[key].t2,
                attributeMapping
              );
            }
          }
        }
      }
    }
    tooltip += '</table>';
    if (tooltip === '<table></table>') {
      return null;
    } // empty tooltip
    return tooltip;
  },
  /**
   * Creates a tooltip as string by the given additionalRowAttributes.
   * @param additionalRowAttributes
   */
  getEntryTooltipByAdditionalRowAttributes: function (additionalRowData: IGanttAdditionalRowData): string {
    let tooltip = '<table>';
    for (const attribute of additionalRowData.attributes) {
      if (attribute.value !== '' && attribute.showInTooltip) {
        tooltip += `<tr>
        <td>${attribute.key}: </td>
        <td>${attribute.value}</td>
        </tr>`;
      }
    }

    for (const constraint in additionalRowData.brokenConstraints) {
      if (additionalRowData.brokenConstraints[constraint].t1) {
        tooltip += `<tr>
        <td>${constraint}: </td>
        <td>${additionalRowData.brokenConstraints[constraint].t2}</td>
        </tr>`;
      }
    }

    tooltip += '</table>';
    if (tooltip === '<table></table>') {
      return null;
    } // empty tooltip
    return tooltip;
  },

  /**
   * Builds tooltip row by attribute and matching value.
   * This form will be shown inside the tooltip: "Attribut1: AttributValue1".
   * @param key Attribute.
   * @param value attribute value.
   * @param attributeMapping Attribute mapping map from backend.
   */
  _getHTMLTableRowStrucuteByAdditionalDetail: function (
    key: string,
    value: string | number,
    attributeMapping: IGanttAttributeMapping
  ): string {
    const valueDataType: EGanttBlockAttributeDataType =
      attributeMapping && attributeMapping[key] && attributeMapping[key]?.dataType
        ? attributeMapping[key]?.dataType
        : EGanttBlockAttributeDataType.STRING;

    if (!(value === null) || !(value === undefined)) {
      if (value === '') {
        return '';
      }
      return (
        `<tr>
      <td>` +
        UtilTooltipMapper._getAttributeNameByNumber(key, attributeMapping) +
        `</td>
      <td>` +
        UtilTooltipMapper._trimString(
          GanttRowAdditionalDataMapper.getAttributeValueByDataType(value, valueDataType),
          200
        ) +
        `</td>
      </tr>`
      );
    } else {
      return '';
    }
  },

  /**
   * Provides HTML-Code for given key-value-map.
   * @param tooltipDataMap ...
   */
  _getHTMLTableRowStrucuteByAdditionalTooltipData: function (tooltipDataMap: any): string {
    let htmlStructure = '';
    for (const key in tooltipDataMap) {
      const value = tooltipDataMap[key];
      if (!(value === null) || !(value === undefined)) {
        if (value === '') {
          return htmlStructure;
        }
        htmlStructure +=
          `<tr>
        <td>` +
          key +
          `: </td>
          <td>` +
          value +
          `</td>
          </tr>`;
      }
    }
    return htmlStructure;
  },

  /**
   * Decode attributeMapping to recieve full tooltip rows.
   * If no matching entry inside attributeMapping does exist,
   * the attributeNumber as tooltip entry will be returned.
   * @param attributeNumber Number to extract row content.
   * @param attributeMapping Attribute mapping map from backend.
   */
  _getAttributeNameByNumber: function (attributeNumber: string, attributeMapping: IGanttAttributeMapping): string {
    if (attributeMapping && attributeNumber.match(/^[0-9]+$/) != null && attributeMapping[+attributeNumber]) {
      // faster parseInt
      const attributeNameWrapper = attributeMapping[attributeNumber];
      if (attributeNameWrapper.localization) return attributeNameWrapper.localization + ': ';
    }
    return attributeNumber + ': ';
  },

  /**
   * Transforms backend row data into the right format
   * to use it for the tooltip handling.
   * @param rowDetails Additional data of backend row.
   */
  additionalRowDetailsToMap: function (rowDetails: any): any {
    const mappedDetails: any = {};
    for (const key in rowDetails) {
      const value = rowDetails[key];
      if (!value.t1) continue;
      mappedDetails[key] = value.t2;
    }
    return mappedDetails;
  },
  /**
   * Limits a string to the passed maximum length.
   * If the string is too long, it is shortened.
   * @param string string to limit
   * @param maxLength maximum length
   * @returns trimmed string
   */
  _trimString: (string: string, maxLength: number): string => {
    if (string && string.length && string.length > maxLength) {
      return string.substring(0, maxLength) + '…';
    }
    return string;
  },
};
