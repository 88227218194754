import { ElementRef } from '@angular/core';
import { Action } from '@app-modeleditor/components/button/action/action';
import { Content } from '@app-modeleditor/components/content/content';
import { Lightbox } from '@app-modeleditor/components/lightbox/lightbox';
import { Printer } from '@app-modeleditor/components/print/printer';
import { GlobalUtils } from 'frontend/src/dashboard/global-utils';
import { Observable, of, Subject } from 'rxjs';
import { finalize, switchMap, take } from 'rxjs/operators';
import { Button } from '../button/button';
import { EEntryElementPosition } from '../entry-collection/entry-element-position.enum';
import { IPrintItem } from './print-item.interface';
import { PrintLightboxStrategyDefault } from './print-lightbox-strategies/print-lightbox-strategy-default';
import { IPrintLightboxStrategy } from './print-lightbox-strategies/print-lightbox-strategy.interface';

export class PrintLightbox extends Lightbox {
  private printer: Printer;
  private printRef: ElementRef;
  private strategy: IPrintLightboxStrategy;
  private componentId = GlobalUtils.generateUUID();
  private afterPrintCb: () => void;

  public getPrintRef(): ElementRef {
    return this.printRef;
  }

  public setPrintRef(printRef: ElementRef): this {
    this.printRef = printRef;
    return this;
  }

  constructor(strategy: IPrintLightboxStrategy = new PrintLightboxStrategyDefault()) {
    super();

    this.strategy = strategy;
    this.strategy.setLightboxRef(this);

    this.setFullscreen(this.strategy.getLightboxFullscreen());
    this.setResizeable(this.strategy.getLightboxResizeable());
    if (!this.strategy.getLightboxFullscreen()) this.setDraggable(false);
    this.setDisableSaveButton(true);
    const printAction: Action = new Action().setCb(() => this.print());
    const afterPrintAction: Action = new Action().setCb(() => this.executeAfterPrintCb());
    const printBtn: Button = new Button()
      .setName('print')
      .setIcon('print')
      .setPosition(EEntryElementPosition.RIGHT)
      .chainActions(printAction, afterPrintAction);

    this.setContent(this.createContent());
    this.setAdditionalButtons([printBtn]);
    this.setName('PRINT.preview');
  }

  public getPrinter(): Printer {
    return this.printer;
  }

  private createContent(): Content {
    this.printer = new Printer();

    return this.strategy.createContent();
  }

  public setMaterial(node: any, value: number): void {
    for (const button of node.getElementsByTagName('button')) {
      if (button.hasAttribute('mat-icon-button')) {
        button.style.opacity = value;
      }
    }
    for (const icons of node.getElementsByTagName('mat-icon')) {
      icons.style.opacity = value;
    }
  }

  private print(): Observable<boolean> {
    this.getPrinter().setBusy(true);
    this.setMaterial(this.getPrintRef().nativeElement, 0);
    return this.strategy.print().pipe(
      switchMap((printItems: IPrintItem[]) => {
        return this.openBrowserPreview(this.getPrinter().getTitle(), printItems);
      }),
      finalize(() => {
        this.getPrinter().setBusy(false);
      })
    );
  }

  private openBrowserPreview(title: string, printItems: IPrintItem[]): Observable<boolean> {
    const browserWindow = window.open('', title);
    browserWindow.document.title = title;
    browserWindow.document.write(
      `<html>
        <head>
          <title>${title || 'Gantt'}</title>
          <style>
            body {
              margin: 0;
            }
            div.img {
              background-size: contain;
              background-repeat: no-repeat;
              width: 100%;
              -webkit-print-color-adjust: exact;
            }
            div.break-before {
              page-break-before: always;
            }
            div.no-break-inside {
              page-break-inside: avoid;
            }
          </style>
        </head>
        <body>
        </body>
      </html>`
    );
    browserWindow.document.close(); // necessary for IE >= 10
    browserWindow.focus(); // necessary for IE >= 10

    const printCompletedSubject = new Subject<boolean>();
    let pagesReady = 0;

    printItems.forEach((printItem, i) => {
      const sizeImg = browserWindow.document.createElement('img');
      const sizeDiv = browserWindow.document.createElement('div');
      sizeDiv.style.height = 'fit-content';
      browserWindow.document.body.appendChild(sizeDiv).append(sizeImg);

      const pageElement = browserWindow.document.body.appendChild(browserWindow.document.createElement('div'));
      sizeImg.onload = () => {
        pageElement.style.height = `${sizeImg.height}px`;
        pageElement.style.width = `${sizeImg.width}px`;
        sizeImg.onload = null;
        sizeImg.remove();
        sizeDiv.remove();
        pagesReady++;
        if (pagesReady === printItems.length) {
          browserWindow.print();
          browserWindow.close();
          this.setMaterial(this.getPrintRef().nativeElement, 1);
          printCompletedSubject.next(true);
        }
      };
      sizeImg.src = `${printItem.dataUrl}`;
      pageElement.style.backgroundImage = `url("${printItem.dataUrl}")`;
      pageElement.classList.add('img');
      if (i > 0 && printItem.pageBreakBefore) pageElement.classList.add('break-before');
      if (!printItem.pageBreakInside) pageElement.classList.add('no-break-inside');
    });

    return printCompletedSubject.asObservable().pipe(take(1));
  }

  public afterPrint(cb: () => void): void {
    this.afterPrintCb = cb;
  }

  private executeAfterPrintCb(): Observable<void> {
    if (this.afterPrintCb) this.afterPrintCb();
    return of(null);
  }
}
