<mat-toolbar>
  <app-button [data]="btn"></app-button>
  Log
</mat-toolbar>
<div class="content">
  <div
    class="entry"
    *ngFor="let item of entries"
    (click)="states[item.uuid] = !states[item.uuid]"
    [class.extended]="states[item.uuid] === true"
  >
    <div class="date" *ngIf="item.floatRightLines">
      <span *ngFor="let line of item.floatRightLines">{{ line }}</span>
    </div>
    <div *ngIf="item.floatFullLines">
      <div
        *ngFor="let line of item.floatFullLines"
        style="display: flex; flex-wrap: wrap; width: 100%; white-space: normal"
      >
        {{ line }}
      </div>
    </div>
    <div *ngIf="item.floatLeftLines">
      <div class="line" *ngFor="let line of item.floatLeftLines">
        {{ line }}
      </div>
    </div>
  </div>
</div>
