import { Observable, merge, of, switchMap } from 'rxjs';
import { GanttYAxisTranslation } from '../edit-y-axis/y-axis-translation/y-axis-translation';
import { GanttHTMLStructureBuilder } from '../html-structure/html-structure-builder';
import { GanttScrollContainerEvent } from '../html-structure/scroll-container-event';
import { EGanttScrollContainer } from '../html-structure/scroll-container.enum';
import { BestGantt } from '../main';
import { GanttRenderDataSetYAxis } from '../render-data-handler/data-structure/render-data-structure';
import { TooltipBuilder } from '../tooltip/tooltip-builder';
import { GanttYAxis, GanttYAxisContextMenuEvent, GanttYAxisDragEvent } from './y-axis';

/**
 * Facade for y axis building.
 */
export class GanttYAxisFacade {
  private _parentNode: { [id: string]: d3.Selection<HTMLDivElement, undefined, d3.BaseType, undefined> } = {};

  private _yAxisBuilder: { [id: string]: GanttYAxis } = {};
  private _yAxisTranslator: { [id: string]: GanttYAxisTranslation } = {};

  /**
   * @param _ganttDiagram Reference to the gantt diagram to build the y axis for.
   */
  constructor(private _ganttDiagram: BestGantt) {}

  /**
   * Initializes the y axis facade.
   */
  public init(): void {
    for (const scrollContainerId of Object.values(EGanttScrollContainer)) {
      this._init(scrollContainerId);
    }
  }

  /**
   * Initializes the y axis facade for the specified scroll container.
   * @param scrollContainerId Id of the scroll container to initialize the y axis facade for.
   */
  private _init(scrollContainerId: EGanttScrollContainer): void {
    this._parentNode[scrollContainerId] = this._htmlStructureBuilder.getYAxisContainer(scrollContainerId);

    this._yAxisBuilder[scrollContainerId] = new GanttYAxis(
      this._ganttDiagram,
      this._parentNode[scrollContainerId],
      scrollContainerId
    );
    this._yAxisTranslator[scrollContainerId] = new GanttYAxisTranslation(
      this._yAxisBuilder[scrollContainerId],
      this._ganttDiagram.getDataHandler(),
      this._ganttDiagram,
      this._ganttDiagram.getConfig()
    );
  }

  public build(): void {
    for (const scrollContainerId of Object.values(EGanttScrollContainer)) {
      this._yAxisBuilder[scrollContainerId].build();
    }
  }

  public render(renderDataSet: GanttRenderDataSetYAxis): void {
    for (const scrollContainerId of this._ganttDiagram.getRenderDataHandler().getActiveScrollContainerIds()) {
      this._yAxisBuilder[scrollContainerId].render(renderDataSet[scrollContainerId]);
    }
  }

  public reBuildList(renderDataSet: GanttRenderDataSetYAxis): void {
    for (const scrollContainerId of this._ganttDiagram.getRenderDataHandler().getActiveScrollContainerIds()) {
      this._yAxisBuilder[scrollContainerId].reBuildList(renderDataSet[scrollContainerId]);
    }
  }

  public updateSize(renderDataSet: GanttRenderDataSetYAxis): void {
    for (const scrollContainerId of this._ganttDiagram.getRenderDataHandler().getActiveScrollContainerIds()) {
      this._yAxisBuilder[scrollContainerId].updateSize(renderDataSet[scrollContainerId]);
    }
  }

  //
  // RESTRICTIONS
  //

  public setAllowDragDrop(bool: boolean): void {
    for (const scrollContainerId of Object.values(EGanttScrollContainer)) {
      this._yAxisBuilder[scrollContainerId].setAllowDragDrop(bool);
    }
  }

  public setAllowClicks(bool: boolean): void {
    for (const scrollContainerId of Object.values(EGanttScrollContainer)) {
      this._yAxisBuilder[scrollContainerId].setAllowClicks(bool);
    }
  }

  //
  // GETTER & SETTER
  //

  /**
   * Helper getter which returns the HTML structure builder of the current gantt.
   */
  private get _htmlStructureBuilder(): GanttHTMLStructureBuilder {
    return this._ganttDiagram.getHTMLStructureBuilder();
  }

  public getYAxisBuilder(scrollContainerId: EGanttScrollContainer = EGanttScrollContainer.DEFAULT): GanttYAxis {
    return this._yAxisBuilder[scrollContainerId];
  }

  public getYAxisTranslator(
    scrollContainerId: EGanttScrollContainer = EGanttScrollContainer.DEFAULT
  ): GanttYAxisTranslation {
    return this._yAxisTranslator[scrollContainerId];
  }

  public setTooltipBuilder(tooltipBuilder: TooltipBuilder): void {
    for (const scrollContainerId of Object.values(EGanttScrollContainer)) {
      this._yAxisBuilder[scrollContainerId].setTooltipBuilder(tooltipBuilder);
    }
  }

  public setCanvasHeight(canvasHeightMap: Map<EGanttScrollContainer, number>): void {
    for (const scrollContainerId of canvasHeightMap.keys()) {
      this._yAxisBuilder[scrollContainerId].setCanvasHeight(canvasHeightMap.get(scrollContainerId));
    }
  }

  //
  // OBSERVABLES
  //

  public get onClickShifts(): Observable<GanttScrollContainerEvent<PointerEvent>> {
    return this._mergeObservables('onClickShifts');
  }

  public get onDragStart(): Observable<GanttScrollContainerEvent<GanttYAxisDragEvent>> {
    return this._mergeObservables('onDragStart');
  }

  public get onDragUpdate(): Observable<GanttScrollContainerEvent<GanttYAxisDragEvent>> {
    return this._mergeObservables('onDragUpdate');
  }

  public get onDragEnd(): Observable<GanttScrollContainerEvent<GanttYAxisDragEvent>> {
    return this._mergeObservables('onDragEnd');
  }

  public get onContextMenu(): Observable<GanttScrollContainerEvent<GanttYAxisContextMenuEvent>> {
    return this._mergeObservables('onContextMenu');
  }

  private _mergeObservables<T>(key: string): Observable<GanttScrollContainerEvent<T>> {
    const observables: Observable<GanttScrollContainerEvent<T>>[] = [];
    for (const scrollContainerId of Object.values(EGanttScrollContainer)) {
      observables.push(
        (this._yAxisBuilder[scrollContainerId][key] as Observable<T>).pipe(
          switchMap((event) => of(new GanttScrollContainerEvent<T>(scrollContainerId, event)))
        )
      );
    }
    return merge(...observables);
  }
}
