import { HMI } from './tree-item';

export class Menu extends HMI {
  private menuUpdateNotificationsRestURL: string;
  private reloadMenuRestURL: string;

  public getMenuUpdateNotificationsRestURL(): string {
    return this.menuUpdateNotificationsRestURL;
  }

  public setMenuUpdateNotificationsRestURL(menuUpdateNotificationsRestURL: string): this {
    this.menuUpdateNotificationsRestURL = menuUpdateNotificationsRestURL;
    return this;
  }

  public getReloadMenuRestURL(): string {
    return this.reloadMenuRestURL;
  }

  public setReloadMenuRestURL(reloadMenuRestURL: string): this {
    this.reloadMenuRestURL = reloadMenuRestURL;
    return this;
  }
}
