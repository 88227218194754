import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { TranslateModule } from '@ngx-translate/core';
import { FileUploaderComponent } from './file-uploader.component';
import { FileUploadProgressModule } from './progress/progress.module';
import { SlotModule } from '../template/slot/slot.module';
import { ProgressDialogModule } from './progress-dialog/progress-dialog.module';
@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    MatIconModule,
    OverlayModule,
    MatButtonModule,
    FileUploadProgressModule,
    MatProgressBarModule,
    MatTooltipModule,
    SlotModule,
    ProgressDialogModule,
  ],
  declarations: [FileUploaderComponent],
  exports: [FileUploaderComponent],
})
export class FileUploaderModule {}
