import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatLegacySlideToggleChange as MatSlideToggleChange } from '@angular/material/legacy-slide-toggle';
import { SaxMsBestGanttSettings } from 'frontend/src/dashboard/gantt/gantt/saxms-best-gantt.settings';

@Component({
  selector: 'app-index-cards',
  templateUrl: './index-cards.component.html',
  styleUrls: ['./index-cards.component.scss'],
})
export class IndexCardsComponent implements OnInit {
  @Input() settings: SaxMsBestGanttSettings;
  @Output() changeEvent: EventEmitter<null> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  public handleTimeSpan(event: MatSlideToggleChange): void {
    this.settings.showIndexCardTimespan = event.checked;
    this.changeEvent.emit();
  }

  public handleShowEmptyAttributes(event: MatSlideToggleChange): void {
    this.settings.showIndexCardEmptyAttributes = event.checked;
    this.changeEvent.emit();
  }
}
