import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatLegacyAutocompleteTrigger as MatAutocompleteTrigger } from '@angular/material/legacy-autocomplete';
import { MatLegacyFormFieldAppearance as MatFormFieldAppearance } from '@angular/material/legacy-form-field';
import { EntryElementValue } from '@app-modeleditor/components/entry-collection/entry-element-value';
import { UiService } from '@app-modeleditor/ui.service';
import { TemplateService } from '@app-modeleditor/utils/template.service';
import { GlobalUtils } from 'frontend/src/dashboard/global-utils';
import { Subject, Subscription, of } from 'rxjs';
import { delay, switchMap, takeUntil, tap } from 'rxjs/operators';
import { EntryElement } from '../entry-collection/entry-element';
import { EFieldType } from '../entry-collection/field-type.enum';
import { TextField } from '../entry-collection/text-field/text-field';
import { TemplateAdapter } from './../../utils/template-factory.service';
import { EntryElementFactory } from './../entry-collection/entry-factory.service';
import { AutocompleteTextfield } from './autocomplete-textfield';
import { UnitPipe } from './unit.pipe';
@Component({
  selector: 'saxms-element',
  templateUrl: './elements.component.html',
  providers: [UnitPipe],
  styleUrls: ['./elements.component.scss'],
})
export class ElementsComponent implements OnDestroy, OnChanges, OnInit, AfterViewInit {
  appearance: MatFormFieldAppearance = 'legacy';
  elementData: IEntryElement;
  @Input('elementData') set myData(elementData: IEntryElement) {
    this.elementData = elementData;
    this._init();
  }
  @Output() changeEvent: EventEmitter<any> = new EventEmitter<any>();
  @Input() hintIcon;
  @Input() hintText;
  @Input() hintColor;
  componentId: string = GlobalUtils.generateUUID();
  @Input() hint;
  @Input() ref: any;
  @Input() disabled: boolean;
  @Input() enabled: boolean;
  @Input() enabledByCondition = true;
  @Input() stretched: any;
  @Input() isSpreadsheetContext = false;
  @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;
  show = true;
  hideRequiredMarker = true;
  @HostBinding('class.stretched-host') isStretched = false;
  timeout: Subject<void> = new Subject<void>();
  sub: Subscription;
  @Input() selectedOption: any;
  textColor: string;
  @HostBinding('class.stretched') stretchedClass;
  datatype: EDataType;
  alive = true; // state of the component
  public inSidePanel = false;
  userForm: UntypedFormGroup;

  // implementation of auto focus
  private isAlreadyAutoFocused: boolean;
  @ViewChild('inputRef') set inputRef(elementRef: ElementRef) {
    if (elementRef && this.formElement?.isAutoFocused() === true && !this.isAlreadyAutoFocused) {
      this.isAlreadyAutoFocused = true;
      const inp: HTMLInputElement = elementRef.nativeElement as HTMLInputElement;
    }
  }

  formElement: EntryElement;
  /**
   * constructor
   * @param $uiService UiService
   * @param modeleditorService ModeleditorService
   */
  constructor(
    private $uiService: UiService,
    private $formBuilder: UntypedFormBuilder,
    private $templateAdapter: TemplateAdapter,
    private $entryElementFactory: EntryElementFactory,
    private $unitPipe: UnitPipe,
    private $templateApi: TemplateService,
    private $zone: NgZone
  ) {
    this.userForm = this.$formBuilder.group({
      textFieldControl: '',
      comboBoxFieldControl: '',
    });
  }
  ngOnInit(): void {
    this.formElement?.setEdited(false);
  }
  ngAfterViewInit(): void {
    this.validation();
  }

  compareComboBox(o1: EntryElementValue, o2: EntryElementValue): boolean {
    return (o1 && o2 && o1.getValue && o2.getValue && o1.getValue() === o2.getValue()) || o1 === o2;
  }

  keydown(event: KeyboardEvent): void {
    if (this.formElement instanceof TextField && this.formElement.getForbiddenCharacters().length > 0) {
      if (this.formElement.getForbiddenCharacters().find((item) => item === event.key)) {
        event.stopPropagation();
        event.preventDefault();
      }
    }
  }

  private onKeyUpTimeout: Subject<void> = new Subject<void>();
  onKeyUp(event: KeyboardEvent): void {
    this.$zone.runOutsideAngular(() => {
      if (!this.formElement || !(this.formElement instanceof TextField)) {
        return;
      }

      this.onKeyUpTimeout.next();
      of(null)
        .pipe(delay(350), takeUntil(this.onKeyUpTimeout))
        .subscribe(() => {
          this.$zone.run(() => {
            const e: EntryElementValue = this.formElement
              .getValue<EntryElementValue>()
              .copy(EntryElementValue)
              .setValue((event.target as any).value);

            (this.formElement as TextField).emitKeyUp({ value: e, event });
          });
        });
    });
  }

  private _init(): void {
    if (!this.elementData) {
      return;
    }

    this.formElement =
      this.elementData instanceof EntryElement ? this.elementData : this.$templateAdapter.adapt(this.elementData);
  }

  get isDisabled(): boolean {
    if (!this.formElement) {
      return true;
    }

    return this.formElement.isDisabled() || this.disabled ? true : false;
  }

  /**
   * on changes lifecycle
   * @returns void
   */
  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.stretched) {
      this.stretchedClass = this.stretched ? true : false;
    }
    if (this.elementData && this.elementData.value) {
      if (this.elementData.value.hint) {
        if (!this.hint) {
          this.hint = {};
        }

        this.hint.text = this.elementData.value.hint;
        this.hint.color = this.elementData.value.hintColor;
      }

      if (typeof this.elementData.value.show === 'boolean') {
        this.show = this.elementData.value.show;
      }

      // update required marker
      if (this.elementData.value.required === true || this.elementData.value.required === false) {
        this.elementData.required = this.elementData.value.required;
      }

      // set text color
      this.textColor = this.elementData.value.color;
    }
    this.isStretched = this.stretched ? true : false;

    if (changes.disabled || changes.enabledByCondition) {
      this.toggleFormControls();
    }

    // this.fillSpecialEntry();
  }

  setValue(val: Record<string, any>): void {
    this.formElement.setValue(this.formElement.getValue<EntryElementValue>().setValue(val));
  }

  get Value(): EntryElementValue {
    return this.formElement ? this.formElement.getValue<EntryElementValue>() : null;
  }

  isABoolean(item: EntryElementValue): boolean {
    return typeof item.getValue() === 'boolean' || typeof item.getValue<EntryElementValue>().getValue() === 'boolean';
  }

  getBooleanValue(item: EntryElementValue): boolean {
    if (!item) {
      return null;
    }

    if (typeof item.getValue() === 'boolean') {
      return item.getValue();
    }

    if (typeof item.getValue<EntryElementValue>().getValue() === 'boolean') {
      return item.getValue<EntryElementValue>().getValue();
    }
  }

  /**
   * toggle state of reactive forms
   * @returns void
   */
  private toggleFormControls(): void {
    const controls: UntypedFormControl[] = [];
    controls.push(this.textFieldControl, this.comboBoxFieldControl);
    for (const control of controls) {
      if (!this.disabled && this.enabledByCondition) {
        control.enable();
      } else if (this.disabled || !this.enabledByCondition) {
        control.disable();
      } else {
        control.enable();
      }
    }
  }

  get upDisabled(): boolean {
    if (!this.formElement.getUpperBound()) {
      return false;
    }
    if (this.formElement.getValue<EntryElementValue>().getValue() >= this.formElement.getUpperBound()) {
      return true;
    }
    return false;
  }

  get downDisabled(): boolean {
    if (!this.formElement.getLowerBound()) {
      return false;
    }
    if (this.formElement.getValue<EntryElementValue>().getValue() <= this.formElement.getLowerBound()) {
      return true;
    }
    return false;
  }

  get textFieldControl() {
    return this.userForm.get('textFieldControl') as UntypedFormControl;
  }

  get comboBoxFieldControl() {
    return this.userForm.get('comboBoxFieldControl') as UntypedFormControl;
  }

  /**
   * get error message from forms control
   * @param control FomControl
   * @returns string
   */
  public getErrorMessage(control: UntypedFormControl): string {
    return control.hasError('required') ? 'FORMS.VALIDATION.ERROR.required' : '';
  }
  public handleKeyUpEvent(event: KeyboardEvent, data: string): void {
    if (event.keyCode === 40 || event.keyCode === 38) {
      return;
    }
    this.getOptions(data);
  }

  /**
   * get option of autocomplete
   * @param data any
   * @returns void
   */
  getOptions(data: string, initial = false): void {
    this.timeout.next();
    if (this.sub) {
      this.sub.unsubscribe();
    }

    of(null)
      .pipe(delay(300), takeUntil(this.timeout))
      .pipe(
        switchMap(() => {
          (this.formElement as AutocompleteTextfield).triggerOptions(data);
          if (!this.elementData['actionRestUrl']) {
            return of(null);
          }
          const paramUrl: string = this.getActionSelectorURL(this.elementData);
          return this.$uiService.getData(`${paramUrl}`, data && data !== '-' ? { value: data, limit: 20 } : {}).pipe(
            tap((options) => {
              if (this.formElement instanceof AutocompleteTextfield) {
                const newOptions: EntryElementValue[] = [];
                this.formElement.setOptions(
                  newOptions.concat(
                    options.map((o) =>
                      this.$entryElementFactory.parseEntryValue<EntryElementValue>(EntryElementValue, o)
                    )
                  )
                );
              }
            })
          );
        })
      )
      .subscribe(() => {
        if (!initial) {
          this.isValidAutoComplete(data);
        }
      });
  }

  public getActionSelectorURL(element: Record<string, any>): string {
    let uri = element.actionRestUrl;
    if (!element.actionURLParameterSelectors) {
      return uri;
    }

    for (const url of Object.keys(element.actionURLParameterSelectors)) {
      // get element part from second part
      const id = this.getSelectorId(element.actionURLParameterSelectors[url]);
      // get element by id
      const el = this.$templateApi.getElementById(id);

      // get attributes
      const attr = this.getSelectorAttributes(el, element.actionURLParameterSelectors[url]);
      // replace url with params
      uri = uri.replace(url, attr);
    }

    return uri;
  }

  private accessSelectorAttribute(selector, attrList) {
    if (!attrList || attrList.length === 0) {
      return selector;
    }
    const key = attrList.shift();
    return this.accessSelectorAttribute(selector[key], attrList);
  }

  private getSelectorAttributes(element, attributes: string) {
    const splitted = attributes.substring(attributes.lastIndexOf(']') + 2, attributes.lastIndexOf('')).split('.');

    return this.accessSelectorAttribute(element, splitted);
  }

  /**
   * get id from selector url
   */
  private getSelectorId(url: string): string {
    return url.substring(url.lastIndexOf('[') + 1, url.lastIndexOf(']'));
  }

  get options(): EntryElementValue[] {
    if (this.formElement instanceof AutocompleteTextfield) {
      return this.formElement.getOptions();
    }
    return [];
  }
  ngAutoComplete: Subject<void> = new Subject<void>();
  onAutocompleteKeyUp(ev: KeyboardEvent): void {
    this.ngAutoComplete.next();
    of(null)
      .pipe(delay(350), takeUntil(this.ngAutoComplete))
      .subscribe(() => {
        this.$zone.run(() => {
          const val = (ev.target as any).value;
          this.getOptions(val);
          if (this.formElement?.isFreeSelection() && this.formElement instanceof AutocompleteTextfield) {
            this.formElement.getValue<EntryElementValue>().setValue(val);
            this.formElement.triggerOptions(val);
            this.onChanges();
          }
        });
      });
  }

  private isValidAutoComplete(value: string): void {
    if (!value) {
      this.autocompleteError = false;
      this.optionSelected(null);
      return;
    }

    if (this.formElement instanceof AutocompleteTextfield) {
      if (this.formElement.isInvalidOnNoOptions()) {
        this.autocompleteError = !this.formElement
          .getOptions()
          .find((item) => (item.getValue<string>() || '').toLowerCase().includes((value || '').toLowerCase()));
        return;
      }

      const val = this.formElement.getOptions().find((item) => `${item.getValue()}` === `${value}`);

      if (val) {
        this.optionSelected(val);
        return;
      }
    }

    this.autocompleteError = true;
  }

  autocompleteError = false;

  optionSelected(option: EntryElementValue | string): void {
    const val = option instanceof EntryElementValue ? option.getValue() : option;

    if (this.elementData.value) {
      this.elementData.value.value = val;
    }

    if (this.formElement instanceof AutocompleteTextfield) {
      this.autocompleteError = false;
      this.formElement.getValue<EntryElementValue>().setValue(val);
    }

    this.onChanges();
  }

  public handleKeyboardEvent(event: Record<string, any>): void {
    if (event.source.isOpen) {
      (event.option?._element as ElementRef)?.nativeElement.scrollIntoView();
    }
  }

  afterValueChanged(e: string, ref?: HTMLInputElement): void {
    const val = this.$unitPipe.transform(e, 'backward', this.formElement);
    this.Value.setValue(val);
    this.validation();
  }

  public get StringValue(): string {
    return this.Value.getValue<string>();
  }

  private _ngOverride: Subject<void> = new Subject<void>();
  /**
   * handles change of form controls
   * @param event Event
   * @param data any
   * @returns void
   */
  public onChanges(event?: Event): void {
    this._ngOverride.next();
    if (event) {
      const el: any = event.target;
      this.Value.setValue(el.value);
    }

    this.validation();
    this.formElement?.setEdited(true);
    this.changeEvent.emit(this.formElement || this.elementData);
  }

  public onValueChange(value: Record<string, any>): void {
    if (value === undefined || value === null) {
      return;
    }
    this.elementData.value.value = value;
    this.Value.setValue(value);
    this.validation();
    this.formElement?.setEdited(true);
    this.changeEvent.emit(this.formElement);
  }

  _emitValue(val: Record<string, any>): void {}

  get FormElement(): EntryElement {
    if (!this.formElement) {
      return null;
    }

    // if (this.formElement.getFieldType() ===)
  }

  mapDataTypes(type: string): 'text' | 'number' {
    switch (type) {
      case 'java.lang.String':
        return 'text';
      case 'java.lang.Integer':
      case 'java.lang.Double':
      case 'java.lang.Long':
      case 'java.lang.Float':
        return 'number';
      default:
        return null;
    }
  }

  ngOnDestroy(): void {
    this.onKeyUpTimeout.next();
    this.onKeyUpTimeout.complete();
    this.alive = false;

    this.ngAutoComplete.next();
    this.ngAutoComplete.complete();

    this.timeout.next();
    this.timeout.complete();
    this._ngOverride.next();
    this._ngOverride.complete();
    this.formElement?.removeEventListener(this.componentId);
  }

  /**
   * only for WEB_VIEW
   * @returns boolean
   */
  openInNewWindow(open?: boolean): void {
    if (!open) {
      return;
    }

    window.open(this.elementData.web_url, 'newwindow', 'width=1200,height=750,noreferrer');
  }

  /**
   * only for WEB_VIEW
   * @returns void
   */
  getWindowType(openType: string): string {
    switch (openType) {
      case 'TAB':
        return '_blank';
      case 'WINDOW':
        return '_self';
      case 'NEW':
        return '';
      case 'IFRAME':
      default:
        break;
    }
  }
  setInsidePanel(inside: boolean): void {
    this.inSidePanel = inside;
  }

  onFocusOut(ev: Event): void {
    if (!this.inSidePanel && this.autocomplete) {
      this.autocomplete.closePanel();
    }
  }

  validation() {
    const fieldType = this.formElement.getFieldType();
    const isUserFormUsed =
      (fieldType === EFieldType.TEXT_FIELD && this.mapDataTypes(this.formElement.getDatatype()) !== 'number') ||
      fieldType === EFieldType.AUTOCOMPLETE_TEXT_FIELD ||
      fieldType === EFieldType.COMBO_BOX ||
      fieldType === EFieldType.TEXT_AREA;

    if (!isUserFormUsed || !this.formElement?.isRequired()) {
      return;
    }

    // mark as touched to show validate errors directly after initialization
    this.textFieldControl.markAsTouched();

    // Invalid is checked in case no validation has taken place.
    // This is the case if the field is initially disabled.
    this.formElement.setValid(this.userForm.valid || !this.userForm.invalid);
  }
}

export enum EDataType {
  FLOAT = 'java.lang.Float',
  DOUBLE = 'java.lang.Double',
  INT = 'java.lang.Integer',
  LONG = 'java.lang.Long',
  STRING = 'java.lang.String',
}

export interface IEntryElement {
  fieldType: string;
  web_url?: string;
  datatype: EDataType;
  value: IEntryValue;
  lowerBound?: number;
  upperBound?: number;
  description?: string;
  stepWidth?: number;
  unit?: '%';
  required: boolean;
  openType?: 'TAB' | 'WINDOW' | 'NEW' | 'IFRAME';
  name: string;
  infoText?: string;
  id: string;
  validationRegex?: string;
  icon?: any;
  align?: any;
  entry?: IEntryValue;
  validationRegexInfoText?: string;
}

export interface IEntryValue {
  value: any;
  required?: boolean;
  color?: string;
  hintColor?: string;
  hint?: string;
  availableValues?: any[];
  show?: boolean;
}
