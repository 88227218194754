import { ConfigService } from '@core/config/config.service';
import { EGanttInstance, GanttDataContainer, GanttDataRow, GanttDataShift } from '@gantt/public-api';
import { GanttLibService } from 'frontend/src/dashboard/gantt/gantt/gantt-lib.service';
import {
  GanttChildren,
  GanttHierarchicalPlanData,
  IGanttAttributeMapping,
  IGanttBlock,
  IGanttDetails,
  IGanttEntryAttributeMapping,
  IGanttVisibleAttributes,
} from '../gantt-input.data';
import { EGanttColors } from '../gantt-input.enmu';
import { GanttRowAdditionalDataMapper } from './gantt-row-additional-data.mapper';
import { UtilTooltipMapper } from './gantt.tooltip.mapper';

/**
 * Main mapper to transform backend gantt data to front end gantt data.
 */
export class BackendToGanttOriginInputMapperService {
  constructor(private _ganttLibService: GanttLibService, private _configService: ConfigService) {}

  private _multipleBlockIds: Map<string, { originID: string; originRow: string }> = new Map();
  private _clonedOriginIds: Map<string, string[]> = new Map();
  private _mapBrokenConstraintsToTooltips = true;

  /**
   * Mapping of complete hierarchical plan.
   * Uses attributeMapping for tooltip handling.
   * @param ganttData Hierarchical plan backend data.
   * @param attributeMapping Attribute mapping map from backend.
   */
  ganttToInput(
    ganttData: GanttHierarchicalPlanData,
    attributeMapping: IGanttAttributeMapping,
    tooltipSettings: IGanttVisibleAttributes,
    entryAttributeMapping: IGanttEntryAttributeMapping,
    keepRowOpenStates = false
  ): GanttDataContainer {
    const rowOpenStateMap: Map<string, boolean> = keepRowOpenStates
      ? this._ganttLibService.ganttInstanceService
          .getInstance(EGanttInstance.Y_AXIS_DATA_FINDER)
          .getRowOpenStateMap(this._ganttLibService.bestGantt?.getDataHandler().getOriginDataset().ganttEntries)
      : null;
    const dataContainer: GanttDataContainer = {
      id: 'id_' + Math.random(),
      minValue: new Date(ganttData.startDate),
      maxValue: new Date(ganttData.endDate),
      currentDate: ganttData.current != null ? new Date(ganttData.current) : null,
      labelXAxis: '',
      labelYAxis: '',
      title: '',
      maxCount: 0,
      minStepWidth: ganttData.minStepWidth ? ganttData.minStepWidth : null,
      gridRef: ganttData.gridRef && ganttData.minStepWidth ? ganttData.gridRef : null,
      timeGrid: ganttData.timeGrid,
      globalMilestones: ganttData.globalMilestones,
      ganttEntries: this.childrenToInput(
        ganttData.ganttEntries,
        attributeMapping,
        tooltipSettings,
        entryAttributeMapping,
        rowOpenStateMap
      ),
    };
    this._findAndMapShiftClones(dataContainer.ganttEntries);
    return dataContainer;
  }

  /**
   * Maps a list of backend childs to front end data format.
   * @param ganttChildren List of childs from backend.
   * @param attributeMapping Attribute mapping map from backend.
   */
  childrenToInput(
    ganttChildren: GanttChildren[],
    attributeMapping: IGanttAttributeMapping,
    tooltipSettings: IGanttVisibleAttributes,
    entryAttributeMapping: IGanttEntryAttributeMapping,
    rowOpenStateMap: Map<string, boolean> = null,
    recursionDepth = 0
  ): GanttDataRow[] {
    const mappedChildren: GanttDataRow[] = [];
    if (!ganttChildren) return mappedChildren;
    for (const children of ganttChildren) {
      const additionalData = GanttRowAdditionalDataMapper.extractAdditionalDataFromRow(
        children,
        attributeMapping,
        entryAttributeMapping,
        children.toolTipDetails?.brokenConstraints
      );

      // extract background color if its defined in sections without from and to property and with no tooltip. Because tooltips can only be defined in sections.
      const backgroundColorSection = children.colorSections?.BACKGROUND_COLOR;
      const backgroundColor =
        backgroundColorSection?.color &&
        !backgroundColorSection?.from &&
        !backgroundColorSection?.to &&
        !backgroundColorSection.tooltip
          ? backgroundColorSection.color
          : undefined;

      const hasBrokenConstraints = !!Object.keys(children.toolTipDetails?.brokenConstraints || {}).length;

      const child: GanttDataRow = {
        id: children.id,
        name: children.name,
        milestones: [],
        shifts: this.blocksToInput(children.blocks, attributeMapping, tooltipSettings),
        child: this.childrenToInput(
          children.children,
          attributeMapping,
          tooltipSettings,
          entryAttributeMapping,
          rowOpenStateMap,
          recursionDepth + 1
        ),
        open:
          rowOpenStateMap && rowOpenStateMap.get(children.id) !== undefined
            ? rowOpenStateMap.get(children.id)
            : children.open,
        group: 'NO-GROUP',
        textColor: this.getEntryTextColor(children, hasBrokenConstraints),
        color: backgroundColor || children.backgroundColor,
        tooltip: UtilTooltipMapper.getEntryTooltipByAdditionalRowAttributes(additionalData),
        associatedResource: null,
        additionalData: additionalData,
        allowedEntryTypes: children.allowedEntryTypes,
        indicatorColor: children.indicatorColor,
        noRender: children.noRender || [],
        icon: this._configService.getIcon(children.icon),
        subtitleElements: this.generateEntrySubtitleElementList(children, entryAttributeMapping),
        stickyChild: children.stickyChild || false,
        sampleValues: children.sampleValues || [],
        startCellIndexForSampleValues: children.startCellIndexForSampleValues || 0,
        notHideable: children.notHideable || false,
      };
      mappedChildren.push(child);
    }

    return mappedChildren;
  }

  /**
   * Maps a list of backend blocks to front end data format.
   * @param ganttBlocks List of blocks from backend.
   * @param attributeMapping Attribute mapping map from backend.
   */
  blocksToInput(
    ganttBlocks: IGanttBlock[],
    attributeMapping: IGanttAttributeMapping,
    tooltipSettings: IGanttVisibleAttributes
  ): GanttDataShift[] {
    const mappedBlocks: GanttDataShift[] = [];
    if (!ganttBlocks) return mappedBlocks;
    for (const block of ganttBlocks) {
      const mappedBlock = this.blockToInput(block, attributeMapping, tooltipSettings);
      if (mappedBlock) mappedBlocks.push(mappedBlock);
    }
    return mappedBlocks;
  }

  /**
   * Maps a single backend blocks to front end data format.
   * @param block One blocks from backend.
   * @param attributeMapping Attribute mapping map from backend.
   */
  blockToInput(
    block: IGanttBlock,
    attributeMapping: IGanttAttributeMapping,
    tooltipSettings: IGanttVisibleAttributes
  ): GanttDataShift {
    if (!block) {
      return null;
    }
    const startDate = new Date(block.start);
    const endDate = new Date(block.end);
    if (endDate.getTime() <= startDate.getTime()) {
      console.warn('Invalid block from backend! starttime is less equal than endtime.', block);
      return null;
    }

    if (!block?.color) {
      console.warn(`Block without color provided. Default color ${EGanttColors.DEFAULT_BLOCK} is used.`, block);
    }

    return {
      id: block.id,
      name: block.name || '',
      originName: block.name || '',
      timePointStart: new Date(block.start),
      timePointEnd: new Date(block.end),
      tooltip: block.details
        ? this.getBlockTooltipByTemplateData(block.details, attributeMapping, tooltipSettings)
        : '',
      color: block.color || EGanttColors.DEFAULT_BLOCK,
      firstColor: block.firstColor,
      secondColor: block.secondColor,
      strokeColor: block.stroke,
      strokePattern: block.strokePattern,
      pattern: block.pattern,
      patternColor: block.patternColor,
      highlighted: null,
      additionalData: new GeneralGanttAdditionalBlockInfo(
        block.details,
        block.blockTypes,
        block.superBlocks,
        block.entryTypes
      ),
      modificationRestriction: block.modificationRestriction,
      entryTypes: block.entryTypes || [],
      blockTypes: block.blockTypes || [],
      noRender: block.noRender || [],
      opacity: 1,
      weaken: [],
      disableMove: block.disableMove || false,
      disableColorization: block.disableColorization || false,
      stickyBlockType: block.stickyBlockType,
      linkedParentBlock: block.linkedParentBlock,
      strokeWidth: block.strokeWidth || null,
      editable: true,
      isFullHeight: block.isFullHeight ?? false,
      noRoundedCorners: block.noRoundedCorners ?? false,
      symbols: [],
    };
  }

  /**
   * Wrapper method for the generation of tooltip content for blocks.
   * @param blockDetails
   * @param attributeMapping
   * @param tooltipSettings
   * @returns Tooltip content as HTML code.
   */
  public getBlockTooltipByTemplateData(
    blockDetails: IGanttDetails,
    attributeMapping: IGanttAttributeMapping,
    tooltipSettings: IGanttVisibleAttributes
  ): string {
    return UtilTooltipMapper.getBlockTooltipByTemplateData(
      blockDetails,
      attributeMapping,
      tooltipSettings,
      this._mapBrokenConstraintsToTooltips
    );
  }

  reinitaliseBlockClones(ganttEntries: GanttDataRow[]): void {
    this._mapClonesBackToOrigin(ganttEntries);
    this._multipleBlockIds.clear();
    this._clonedOriginIds.clear();
    this._findAndMapShiftClones(ganttEntries);
  }

  public generateEntrySubtitleElementList(
    ganttChildren: GanttChildren,
    entryAttributeMapping: IGanttEntryAttributeMapping
  ): string[] {
    const elements = [];
    if (!ganttChildren.details || !ganttChildren.details.additionalDetails) {
      return elements;
    }
    const attributeMapping =
      entryAttributeMapping && entryAttributeMapping[ganttChildren.canonicalName]
        ? entryAttributeMapping[ganttChildren.canonicalName]
        : null;
    const detailData = ganttChildren.details.additionalDetails;
    for (const entry in detailData) {
      if (detailData[entry].t1) {
        if (attributeMapping && attributeMapping[entry]) {
          if (!attributeMapping[entry].hideInGanttEntry) {
            elements.push(detailData[entry].t2);
          }
        } else {
          elements.push(detailData[entry].t2);
        }
      }
    }
    return elements;
  }

  _mapClonesBackToOrigin(ganttEntries: GanttDataRow[]): void {
    const s = this;
    const mapClonesBack = function (child) {
      for (let i = 0; i < child.shifts.length; i++) {
        const shift = child.shifts[i];
        if (s._multipleBlockIds.has(shift.id)) {
          shift.id = s._multipleBlockIds.get(shift.id).originID;
        }
      }
    };

    this._ganttLibService.ganttInstanceService
      .getInstance(EGanttInstance.DATA_MANIPULATOR)
      .iterateOverDataSet(ganttEntries, { mapClonesBack: mapClonesBack });
  }

  _findAndMapShiftClones(mappedChildren: GanttDataRow[]): void {
    const findMap: Map<string, string[]> = new Map<string, string[]>();
    const findShiftClones = function (child) {
      for (let i = 0; i < child.shifts.length; i++) {
        const shift = child.shifts[i];
        if (!shift) {
          continue;
        }
        if (findMap.has(shift.id)) {
          findMap.set(shift.id, [...findMap.get(shift.id), child.id]);
        } else {
          findMap.set(shift.id, [child.id]);
        }
      }
    };

    this._ganttLibService.ganttInstanceService
      .getInstance(EGanttInstance.DATA_MANIPULATOR)
      .iterateOverDataSet(mappedChildren, { findShiftClones: findShiftClones });

    findMap.forEach((value, key, map) => {
      if (value.length < 2) {
        map.delete(key);
      }
    }); // delete all entries where are no clones

    const rowIdArrayOfArrays = Array.from(findMap.values());
    const flattenedRowIds = rowIdArrayOfArrays.reduce((acc, val) => acc.concat(val), []);
    const childrenWithClones = new Set(flattenedRowIds);

    const s = this;
    const renameShiftClones = function (child) {
      if (!childrenWithClones.has(child.id)) {
        return;
      }

      for (let i = 0; i < child.shifts.length; i++) {
        const shift = child.shifts[i];

        if (findMap.has(shift.id)) {
          const newId = shift.id + '_' + child.id + '_clone';
          s._multipleBlockIds.set(newId, { originID: shift.id, originRow: child.id });

          if (s._clonedOriginIds.has(shift.id)) {
            s._clonedOriginIds.set(shift.id, [...s._clonedOriginIds.get(shift.id), newId]);
          } else {
            s._clonedOriginIds.set(shift.id, [newId]);
          }

          shift.id = newId;
        }
      }
    };

    this._ganttLibService.ganttInstanceService
      .getInstance(EGanttInstance.DATA_MANIPULATOR)
      .iterateOverDataSet(mappedChildren, { renameShiftClones: renameShiftClones });
  }

  isShiftAClone(cloneId) {
    return this._multipleBlockIds.has(cloneId);
  }

  hasOriginShiftClones(originId) {
    return this._clonedOriginIds.has(originId);
  }

  getShiftClonesByCloneId(cloneId) {
    return this._clonedOriginIds.get(this.getOriginShiftIdByCloneId(cloneId));
  }

  public getOriginShiftIdByCloneId(cloneId: string): string {
    return this._multipleBlockIds.get(cloneId).originID;
  }

  getCloneIdByOriginIdAndRowId(originId, rowId) {
    let cloneId = originId;
    this._clonedOriginIds.get(originId).forEach((clone) => {
      if (this._multipleBlockIds.get(clone).originRow === rowId) {
        cloneId = clone;
      }
    });
    return cloneId;
  }

  public mapShiftIdToOriginId(shiftId: string): string {
    if (this.isShiftAClone(shiftId)) {
      return this.getOriginShiftIdByCloneId(shiftId);
    }
    return shiftId;
  }

  /**
   * Finds and returns cloned shifts by id, if there are none, returns array with id.
   * @param {string} shift id, either native or a cloned id.
   * @return {string[]}
   */
  getShiftClonesByShiftId(shiftId: string): string[] {
    if (this.isShiftAClone(shiftId)) {
      return this.getShiftClonesByCloneId(shiftId);
    }
    if (this.hasOriginShiftClones(shiftId)) {
      return this._clonedOriginIds.get(shiftId);
    }
    return [shiftId];
  }

  public get mapBrokenConstraintsToTooltips(): boolean {
    return this._mapBrokenConstraintsToTooltips;
  }
  public set mapBrokenConstraintsToTooltips(value: boolean) {
    this._mapBrokenConstraintsToTooltips = value;
  }

  /**
   * Returns the color of the text for a given Gantt entry, based on whether or not it has broken constraints and the entry's color property.
   * @param entry - The Gantt entry to get the text color for.
   * @param showBrokenConstraints - Whether or not to show the color for broken constraints.
   * @returns The color of the text for the given Gantt entry.
   */
  public getEntryTextColor(entry: GanttChildren, showBrokenConstraints: boolean): string {
    return showBrokenConstraints ? EGanttColors.BROKEN_CONSTRAINT : entry.color || EGanttColors.ENTRY_TEXT;
  }
}

/**
 * Structure of additional block info.
 * Extend this to store more information inside the blocks.
 */
export class GeneralGanttAdditionalBlockInfo {
  constructor(
    public additionalData: any,
    public blockTypes: number[],
    public superBlockData: any,
    public entryTypes: number[]
  ) {}
}

