import { Button } from '../button/button';
import { EntryElementValue } from '../entry-collection/entry-element-value';
import { EFieldType } from '../entry-collection/field-type.enum';

export class Tile extends Button {
  private hoverContent: string;
  private tags: EntryElementValue[];
  private dragAndDropEnabled: boolean;
  private additionalDetails: string[];
  private iconDetails: { [key: string]: string };
  private receiverDetails: string[];

  public getReceiverDetails(): string[] {
    return this.receiverDetails || [];
  }

  public setReceiverDetails(receiverDetails: string[]): this {
    this.receiverDetails = receiverDetails;
    return this;
  }

  public getIconDetails(): { [key: string]: string } {
    return this.iconDetails;
  }

  public setIconDetails(details: { [key: string]: string }): this {
    this.iconDetails = details;
    return this;
  }

  public getAdditionalDetails(): string[] {
    return this.additionalDetails || [];
  }

  public setAdditionalDetails(additionalDetails: string[]): this {
    this.additionalDetails = additionalDetails;
    return this;
  }

  public isDraggable(): boolean {
    if (typeof this.dragAndDropEnabled === 'boolean') {
      return this.isDragAndDropEnabled();
    }
    return super.isDraggable();
  }

  public isDragAndDropEnabled(): boolean {
    return this.dragAndDropEnabled;
  }

  public setDragAndDropEnabled(dragAndDropEnabled: boolean): this {
    this.dragAndDropEnabled = dragAndDropEnabled;
    return this;
  }

  constructor() {
    super();
    this.setFieldType(EFieldType.FILE_SYSTEM_ELEMENT).setSelectable(true).setDisplayBackground(false);
  }

  public getTags(): EntryElementValue[] {
    return (this.tags || []).sort((a: EntryElementValue, b: EntryElementValue) =>
      a.getIndex() < b.getIndex() ? -1 : 1
    );
  }

  public setTags(tags: EntryElementValue[]): this {
    this.tags = tags;
    return this;
  }

  public getHoverContent(): string {
    return this.hoverContent;
  }

  public setHoverContent(hoverContent: string): this {
    this.hoverContent = hoverContent;
    return this;
  }
}
