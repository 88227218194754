import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { CloudMessagingService } from '@core/notification/cloud-messaging.service';
import { Notification } from '@core/notification/notification';
import { ENotificationCode } from '@core/notification/notification-code.enum';
import { Store } from '@ngxs/store';
import { IModel } from 'frontend/src/dashboard/model/model.data';
import { environment } from 'frontend/src/environments/environment';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { delay, finalize, map, switchMap, takeWhile, tap } from 'rxjs/operators';
import { TemplateActions } from '../template/data-access/template.actions';
import { IUserSettings, UserService } from '../user/data-access/user.service';
import { Model } from './model.data';

@Injectable({
  providedIn: 'root',
})
export class ModelService {
  public model: any;
  public _currentModel: BehaviorSubject<IModel> = new BehaviorSubject(null);
  public currentModel: IModel;
  private models: IModel[];
  private _models: BehaviorSubject<IModel[]> = new BehaviorSubject([]);
  private alive = true;
  lastModelId: string;
  private readonly isLoadingModel$ = new BehaviorSubject<number>(0);

  private readonly Store = inject(Store);

  constructor(public http: HttpClient, public userSettingsService: UserService, private fcm: CloudMessagingService) {
    this.userSettingsService
      .getUserSettings()
      .pipe(takeWhile(() => this.alive))
      .subscribe((usersettings: IUserSettings) => {
        this.userSettingsService.getValueByType('CURRENT_MODEL', true).subscribe((entry) => {
          if (!entry || entry.settingsValue.value === this.lastModelId) {
            return;
          }

          this.lastModelId = entry.settingsValue.value;
          this._init();
        });
      });

    this.fcm
      .getMessage()
      .pipe(takeWhile(() => this.alive))
      .subscribe((event: Notification) => {
        if (event && ENotificationCode.CLONE_MODEL === event.getMessageCode()) {
          this._init();
        }
      });
  }

  getLoadingCount(): Observable<number> {
    return this.isLoadingModel$.asObservable();
  }

  isLoadingModel(): Observable<boolean> {
    return this.isLoadingModel$.asObservable().pipe(map((count) => (count === 0 ? false : true)));
  }

  public setCurrentModel(model: IModel) {
    if (this.currentModel === model || (this.currentModel && model && this.currentModel.id === model.id)) {
      return;
    }

    this.isLoadingModel$.next(this.isLoadingModel$.value + 1);
    this.userSettingsService
      .updateUserSettings('CURRENT_MODEL', model ? model.id : '')
      .pipe(
        finalize(() =>
          of(void 0)
            .pipe(delay(environment.initialLoadingAnimationDelay))
            .subscribe(() => this.isLoadingModel$.next(this.isLoadingModel$.value - 1))
        )
      )
      .subscribe((result) => {
        this.model = model;
        this.currentModel = model;
        this._currentModel.next(model);
      });
  }

  private setModels(models: IModel[]) {
    this.models = models || [];
    this._models.next(this.models);
  }

  public getModels() {
    return this._models.asObservable();
  }

  _init() {
    this.getModel()
      .pipe(
        switchMap((models) => {
          this.setModels(models);

          if (models.length > 0) {
            // this.setActive(this.getLatestModel(models));
            return this.userSettingsService.getValueByType('CURRENT_MODEL', true).pipe(
              switchMap((entry) => {
                for (const model of models) {
                  if (entry.settingsValue.value == model.id) {
                    return of(model);
                  }
                }
                return of(models[0]);
              })
            );
          }
          return of(null);
        })
      )
      .subscribe((model: IModel) => {
        this.setCurrentModel(model);
      });
  }

  public getCurrentModel(): Observable<any> {
    return this._currentModel.asObservable();
  }

  /**
   * get models start template
   * @param modelId model id
   */
  public getStartTemplate(modelId: string): Observable<any> {
    return this.http
      .get(`rest/template/start/${modelId}`)
      .pipe(tap((d) => this.Store.dispatch(new TemplateActions.AddTemplate(d))));
  }

  /**
   * returns either a list of models or the model related to a given id
   * @param id id of the model
   */
  public getModel(id?: string): Observable<any> {
    return this.http.get(`rest/model/${id ? id : ''}`);
  }

  /**
   * get a list of all models with different filter-possibilities
   */
  public getTimeLine(): Observable<any> {
    return this.http.get(`rest/model/timeline`);
  }

  /**
   * returns a list of models for planning horizon
   * @param id id of the planning horizon
   */
  public getModelByPlanningHorizon(id: string): Observable<any> {
    return this.http.get(`rest/model/planninghorizon/${id}`);
  }

  /**
   * returns a list of data-import-possibilities for new model
   */
  public getModelDataSource(): Observable<any> {
    return this.http.get(`rest/model/datasource`);
  }

  /**
   * send selected model to back-end to activate it
   * @param body selected model
   */
  public importSelectedData(selectedModel: Model): Observable<any> {
    return this.http.put(`rest/model/import`, selectedModel).pipe(
      map((model) => {
        this._init();
        return model;
      })
    );
  }

  public getModelEvents(from?: number, to?: number): Observable<any> {
    const filter = from && to ? '?' + encodeURI('from=' + from + '&to=' + to) : '';
    return this.http.get(`rest/model/timeLineEvent` + filter);
  }

  public deleteModel(modelId: string): Observable<void> {
    return this.http.delete(`rest/model/${modelId}`).pipe(
      map((model) => {
        this._init();
      })
    );
  }

  public reimportModel(modelId: string, body: any): Observable<any> {
    return this.http.put(`rest/model/${modelId}/reImport`, body).pipe(
      map((model) => {
        this._init();
        return model;
      })
    );
  }

  public cloneModel(modelId: string, body: any): Observable<any> {
    return this.http.put(`rest/model/clone/${modelId}`, body).pipe(
      map((model) => {
        this._init();
        return model;
      })
    );
  }

  public synchronizeModel(modelId: string, body: any): Observable<any> {
    return this.http.put(`rest/model/${modelId}/synchronize`, body).pipe(
      map((model) => {
        this._init();
        return model;
      })
    );
  }
  public renameModel(modelId: string, body: any): Observable<any> {
    return this.http.post(`rest/model/${modelId}`, body).pipe(
      map((model) => {
        this._init();
      })
    );
  }
  public addCommentary(modelId: string, body: any): Observable<any> {
    return this.http.put(`rest/model/${modelId}/comment`, body).pipe(
      map((model) => {
        this._init();
        return model;
      })
    );
  }
}
