import { EGanttInstance } from '@gantt/public-api';
import { SaxMsBestGanttActiveSubmenuEntryElementSetting } from 'frontend/src/dashboard/gantt/gantt/saxms-best-gantt.settings';
import { EFilteredOutElementDisplayOption } from 'frontend/src/dashboard/gantt/general/toolbar/filter/lightbox/filter-options.enum';
import { IFilterNode } from 'frontend/src/dashboard/gantt/general/toolbar/filter/lightbox/filter-tree';
import { Observable, of } from 'rxjs';
import { GanttEssentialPlugIns } from '../../e-gantt-essential-plugins';
import { ExternalGanttPlugin } from '../../external-plugin';
import { GanttOverlappingShiftsPlugIn } from '../overlapping-shifts/overlapping-shifts';

const GanttBlockFilterByCondition = 'gantt-block-filter-by-condition-plugin';

/**
 * PlugIn-Wrapper for GanttShiftFilterByCondition.
 * Provides funcitonality filter shifts by condition in gantt.
 */
export class GanttBlockFilterByConditionPlugIn extends ExternalGanttPlugin {
  /**
   * Inits connections by backend data.
   * @param templateData
   * @param responseData
   */
  public onInit(templateData: any, responseData: any) {
    try {
      this.addPlugIn(
        GanttBlockFilterByCondition,
        this._ganttLibService.ganttInstanceService.getInstance(EGanttInstance.FILTER_SHIFTS_BY_CONDITION)
      );
      this.getPlugInById(GanttBlockFilterByCondition).setAttributeMapping(templateData.attributeMapping);
    } catch (e) {
      console.error(e);
    }
  }

  public onDestroy(): void {}

  public onAction(action: any) {}

  public executeAction(action: any): Observable<any> {
    return of(null);
  }

  public injectSettings(submenuElements: SaxMsBestGanttActiveSubmenuEntryElementSetting[]): void {}

  public filterByCondition(
    condition: IFilterNode,
    isCaseSensitive: boolean,
    filteredOutElementDisplayOption: EFilteredOutElementDisplayOption,
    isHideEmptyRows: boolean
  ) {
    const overlappingShiftsPlugin: GanttOverlappingShiftsPlugIn = this._ganttPluginHandlerService.getEssentialPlugIn(
      GanttEssentialPlugIns.OverlappingShiftsPlugIn
    );
    overlappingShiftsPlugin.resetSplitOverlappingShifts(false);
    this.getPlugInById(GanttBlockFilterByCondition).filterByCondition(
      condition,
      isCaseSensitive,
      filteredOutElementDisplayOption,
      isHideEmptyRows,
      true
    );
    overlappingShiftsPlugin.splitOverlappingShifts(true);

    if (isHideEmptyRows) this.gantt.getVerticalScrollHandler().setVerticalScrollPos(0); // scroll to top
  }

  public clearFilter() {
    const overlappingShiftsPlugin: GanttOverlappingShiftsPlugIn = this._ganttPluginHandlerService.getEssentialPlugIn(
      GanttEssentialPlugIns.OverlappingShiftsPlugIn
    );
    overlappingShiftsPlugin.resetSplitOverlappingShifts(false);
    this.getPlugInById(GanttBlockFilterByCondition).clearFilter(true);
    overlappingShiftsPlugin.splitOverlappingShifts(true);
  }

  /**
   * Completely resets the active filter and all dependencies.
   */
  public resetAll(): void {
    this.clearFilter();
    this.resetToolbarElements();
  }

  private resetToolbarElements(): void {
    this.actionHandler.newToolbar.getConditionFilter()?.setFilter(null);
  }

  public getNoRenderId(): string {
    return this.getPlugInById(GanttBlockFilterByCondition).getNoRenderId();
  }
}
