import { Action } from '@app-modeleditor/components/button/action/action';
import { EActionType } from '@app-modeleditor/components/button/action/action-type.enum';
import { Button } from '@app-modeleditor/components/button/button';
import { EButtonDisplayType } from '@app-modeleditor/components/button/button-display-type.enum';
import { ButtonService } from '@app-modeleditor/components/button/button.service';
import { TemplateActionService } from '@app-modeleditor/components/button/template-action.service';
import { ContentPart } from '@app-modeleditor/components/content/content-part/content-part';
import { ContextMenuItem } from '@app-modeleditor/components/contextmenu/context-menu-item';
import { ContextMenu } from '@app-modeleditor/components/contextmenu/contextmenu';
import { EntryElement } from '@app-modeleditor/components/entry-collection/entry-element';
import { EntryElementValue } from '@app-modeleditor/components/entry-collection/entry-element-value';
import { EFieldType } from '@app-modeleditor/components/entry-collection/field-type.enum';
import { SelectionBox } from '@app-modeleditor/components/selection-box/selection-box';
import { MultiObjectSelector } from '@app-modeleditor/components/selector/multi-object-selector';
import { EMessageType, Message } from '@core/message/message';
import { EActionOperation } from 'frontend/src/dashboard/model/entry/action-operation.enum';
import { MenuItem } from 'frontend/src/dashboard/view/template-toolbar/menu-item';
import { Toolbar } from 'frontend/src/dashboard/view/template-toolbar/toolbar';
import { ToolbarGroup } from 'frontend/src/dashboard/view/template-toolbar/toolbar-group';
import { EToolbarItemType } from 'frontend/src/dashboard/view/template-toolbar/toolbar-item-type';
import { IToolbarOptions } from 'frontend/src/dashboard/view/template-toolbar/toolbar-options.interface';
import { of } from 'rxjs';
import { SaxMsSpreadSheetColumn } from '../core/saxms-spreadsheet';
import { FullSpreadsheetComponent } from '../full-spreadsheet/full-spreadsheet.component';
import { EDefaultSubmenues } from '../model/default-submenues.enum';
import { EDefaultTableButton } from '../model/default-table-button.enum';
import { SpreadsheetColumnSettings } from '../spreadsheet';
import { SpreadsheetFilterService } from '../utils/spreadsheet-filter.service';
import { EPredefinedSpreadsheetToolbarElement } from './predefined/elements.enum';
import { ExportCsvElement } from './predefined/export-csv-element';
import { HeaderVisibilityElement } from './predefined/header-visibility-element';
import { PredefinedQuickSearchMenu } from './predefined/quicksearch-menu';
import { ResetSettingsElement } from './predefined/reset-settings-element';

export class SpreadsheetToolbar extends Toolbar {
  private _quicksearchMenu: PredefinedQuickSearchMenu;
  private _colMenu: MenuItem;
  public elementsToUpdate: any[] = [];

  constructor(
    public scope: FullSpreadsheetComponent,
    options: IToolbarOptions,
    private spreadsheetFilterService: SpreadsheetFilterService,
    private buttonService: ButtonService,
    private templateActionService: TemplateActionService
  ) {
    super(options);
    this.setParentId(this.scope.getTableTemplate()?.getId());
  }

  public serialize(n?: SpreadsheetToolbar, whitelist: string[] = []): Record<string, any> {
    return super.serialize(
      n,
      whitelist.concat([
        'scope',
        'referenceContainer',
        'eventListener',
        'elementRef',
        'buttonService',
        'spreadsheetFilterService',
      ])
    );
  }

  public updateMenuItemsIndications() {
    const { spreadSheetComponent } = this.spreadsheetFilterService;

    const hiddenColumnsCount = spreadSheetComponent?.getHiddenColumnFilterSet()?.size || 0;
    const quickfiltersCount =
      spreadSheetComponent
        ?.getSpreadsheetsettings()
        ?.columnSettings?.filter((settings) => this.isQuickfilterDefinedOfSetting(settings)).length || 0;

    if (quickfiltersCount > 0) {
      this.quicksearchMenu.registerIndicator('quicksearch');
    } else {
      this.quicksearchMenu.deregisterIndicator('quicksearch');
    }

    if (hiddenColumnsCount > 0) {
      this._colMenu.registerIndicator('hiddenColumns');
    } else {
      this._colMenu.deregisterIndicator('hiddenColumns');
    }
  }

  private isQuickfilterDefinedOfSetting(settings: SpreadsheetColumnSettings) {
    if (settings?.quickFilterVal === undefined) {
      return false;
    }

    // if all options of a selection quickfiler are set then the quickfilter is interpreted as not being set.

    const columnIndex = this.spreadsheetFilterService?.columnUtil?.getColumnById(settings.columnID)?.data;
    const quickSearchElem = this.spreadsheetFilterService?.getQuickSearchElementByColumnIndex(columnIndex);

    if (quickSearchElem?.getFieldType() === 'MULTI_OBJECT_SELECTOR') {
      const optionsCount = (quickSearchElem as MultiObjectSelector).getAvailableOptions().length;
      return settings.quickFilterVal.length < optionsCount && settings.quickFilterVal.length > 0;
    }

    return true;
  }

  get(): SpreadsheetToolbar {
    if (!this.scope.getTableTemplate()) {
      return this;
    }

    this.setClass('saxms-submenu').setMenuMode(this.scope.getTableTemplate().getMenuMode()).disableShortcuts(false);

    this.setName(this.scope.getTableTemplate().getName() || this.scope.getTableTemplate().getHeadline());

    // const favoriteMenu: MenuItem = this.getSpreadsheetFavoriteMenu();
    this._quicksearchMenu = new PredefinedQuickSearchMenu(
      this,
      this.spreadsheetFilterService,
      this.scope.getConfigApi().access().templates.Table.simplifiedQuickSearch
    );
    const rowMenu: MenuItem = this.getPredefinedToolbarRowMenu();
    const additionalMenues: MenuItem[] = this.getAdditionalMenus();
    this._colMenu = this.getPredefinedToolbarColMenu();

    if (this.scope.getConfigApi().access().templates?.Table?.column?.defaultHeaderSelection === 'alternative') {
      const items = this.getHeaderVisibilityItems();

      if (this.scope.getConfigApi().access().templates?.Table?.toolbarButtons?.headerVisibility !== false) {
        const alternativeHeaderToggle = new MenuItem()
          .setId('header-visibility-manager')
          .setIcon('visibility')
          .setMenu(new ContextMenu().setClosedByClick(false).setContextMenuItems(items))
          .setShortcut(true)
          .setName('header_visibility')
          .setDisplayType(EButtonDisplayType.ICON_ONLY);
        this.addMenuItems(alternativeHeaderToggle);
      }
    }

    // set toolbar menues

    if (this.quicksearchMenu.getToolbarGroups().length > 0 && !this.scope.getTableTemplate().isListensToTime()) {
      this.addMenuItems(this.quicksearchMenu);
    }

    if (rowMenu.getToolbarGroups().length > 0) {
      this.addMenuItems(rowMenu);
    }

    if (this._colMenu.getToolbarGroups().length > 0 && !this.scope.getTableTemplate().isListensToTime()) {
      this.addMenuItems(this._colMenu);
    }

    this.addMenuItems(...additionalMenues);

    if (this.scope.getConfigApi().access().templates?.Table?.toolbarButtons?.moreOptions !== false) {
      const options: MenuItem = this.getOptionsMenu();
      this.addMenuItems(options);
    }

    // set row to be the active menu
    if (!this.getActiveMenuItem()) {
      if (
        !this.scope.getTableTemplate().insideDisabledSubMenues(EDefaultSubmenues.ROW) &&
        rowMenu.getToolbarGroups().length > 0
      ) {
        this.setActiveMenuItem(rowMenu);
      } else if (
        !this.scope.getTableTemplate().insideDisabledSubMenues(EDefaultSubmenues.QUICKSEARCH) &&
        this.quicksearchMenu.getToolbarGroups().length > 0
      ) {
        this.setActiveMenuItem(this.quicksearchMenu);
      } else if (this.getMenuItems().length > 0) {
        this.setActiveMenuItem(
          this.getMenuItems().find((m: MenuItem) => m.getToolbarItemType() === EToolbarItemType.GROUP)
        );
      }
    }

    return this;
  }

  private getHeaderVisibilityItems(): ContextMenuItem[] {
    return this.scope
      .getColumnUtil()
      .getColumns()
      .map((col) => {
        const found: boolean = this.scope.getHiddenColumnFilterSet().has(col.filterSortObject.getColumnIndex());

        const item = new ContextMenuItem().setId(`${col.filterSortObject.getColumnIndex()}`);
        item
          .setName(col.label)
          .setIcon(!found ? 'visibility' : 'visibility_off')
          .chainActions(
            new Action(() => {
              const foundInner: boolean = this.scope
                .getHiddenColumnFilterSet()
                .has(col.filterSortObject.getColumnIndex());
              this.scope.toggleVisibilityOfHeader(col.id, foundInner);
              item.setIcon(foundInner ? 'visibility' : 'visibility_off');
              return of(null);
            })
          );
        return item;
      });
  }

  private getSpreadsheetFavoriteMenu(): MenuItem {
    const entries: EntryElement[] = [];
    const options: MenuItem = this.setToggleAction(new MenuItem())
      .setIcon('grade')
      .setId('favorites')
      .setName(this.scope.translate.instant('favorites'))
      .setDisplayType(EButtonDisplayType.ICON_ONLY)
      .setToolbarGroups([
        new ToolbarGroup().setName(this.scope.translate.instant('favorites')).setEntryElements(entries),
      ]);

    return options;
  }

  private getIconButton(name: string, icon: string): ContextMenuItem {
    return new ContextMenuItem().setDisplayType(EButtonDisplayType.ICON_AND_LABEL).setName(name).setIcon(icon);
  }

  private getOptionsMenu(): MenuItem {
    const moreBtn: Button = new Button()
      .setIcon('more_vert')
      .setDisplayType(EButtonDisplayType.ICON_ONLY)
      .setName(this.scope.translate.instant('more'));
    const options: MenuItem = new MenuItem()
      .setNavigationElement(moreBtn)
      .setToolbarItemType(EToolbarItemType.NAVIGATOR);
    moreBtn.setMenu(new ContextMenu().setContextMenuItems(this.addAdditionalMenuButtons()));

    return options;
  }

  private addAdditionalMenuButtons(): ContextMenuItem[] {
    const tooltipToggleBtn: ContextMenuItem = this.getIconButton('TOOLTIP.hide', 'visibility');
    const alternativeExportLabel = this.scope.getConfigApi().access().templates?.Table
      ?.toolbarButtons?.alternativeExportLabel;

    const additionalButtons: ContextMenuItem[] = [
      this.getIconButton('Table.Settings.Reset', 'clear_all').chainActions(
        new Action().setActionType(EActionType.LOCAL).setCb(() => of(this.scope.resetTableSettings()))
      ),
      tooltipToggleBtn.chainActions(
        new Action().setActionType(EActionType.LOCAL).setCb(() => of(this.toggleTooltips(tooltipToggleBtn)))
      ),
      this.getIconButton(alternativeExportLabel || 'Table.Settings.Export', 'import_export').chainActions(
        new Action().setActionType(EActionType.LOCAL).setCb(() => of(this.scope.exportAsCSV()))
      ),
      this.getIconButton('Table.Action.hiddenToolbar', 'minimize').chainActions(
        new Action().setActionType(EActionType.LOCAL).setCb(() => of(this.toggleMenuMode()))
      ),
    ];

    if (this.scope.getConfigApi().access().templates?.Table?.toolbarButtons?.toggleSticky !== false) {
      additionalButtons.push(
        this.getIconButton('BUTTON.toggle_sticky', 'PIN').chainActions(
          new Action().setActionType(EActionType.LOCAL).setCb(() => {
            const cp: ContentPart = this.scope.templateApi.getElementById(this.scope.getTableTemplate().getParentId());
            cp.setSticky(!cp.isSticky());
            this.scope.contentService.check();
            return of(null);
          })
        )
      );
    }

    return additionalButtons;
  }

  private toggleTooltips(btn: Button) {
    const display: boolean = this.scope.toggleTooltipState();
    btn.setName(display ? 'TOOLTIP.hide' : 'TOOLTIP.show').setIcon(display ? 'visibility' : 'visibility_off');
  }

  private getAdditionalMenus(): MenuItem[] {
    return (this.scope.getTableTemplate().getAdditionalSubMenus() || []).map((menu) => {
      const item = this.setToggleAction(
        new MenuItem()
          .setIndex(menu.index || 0)
          .setName(menu.name)
          .setId(menu.id)
      );
      item.setToolbarGroups(
        menu.subMenuGroups.map((group) => {
          const g: ToolbarGroup = new ToolbarGroup().setName(group.name);
          // set entry elements
          const entries: EntryElement[] = group.entryElements.map((entry) => {
            let e: EntryElement = this.createEntryElement(this.scope.getTemplateAdapter().adapt(entry));
            // if it is a button, set display type
            if (e instanceof Button) {
              const b: Button = e;
              b.setDisplayType(EButtonDisplayType.ICON_WITH_LABEL_BELOW).setEnableBy(() => {
                return (
                  b.isAlwaysEnabled() ||
                  (this.templateActionService.checkActions(b.getChain().getActions()) &&
                    !this.scope.isEditableModeLocked())
                );
              });
            }

            if (entry.fieldType === EFieldType.PREDEFINED) {
              e = this.getPredefinedEntryElement(entry);
            } else {
              e = this.createEntryElement(this.scope.getTemplateAdapter().adapt(entry));
              // if it is a button, set display type
              if (e instanceof Button) {
                e.setDisplayType(EButtonDisplayType.ICON_WITH_LABEL_BELOW).setEnableBy(() => {
                  return (
                    e.isAlwaysEnabled() ||
                    (this.templateActionService.checkActions((e as Button).getChain().getActions()) &&
                      !this.scope.isEditableModeLocked())
                  );
                });
              }
            }
            return e;
          });

          g.setEntryElements(entries);
          return g;
        })
      );

      if (menu.defaultSelected) {
        this.setActiveMenuItem(item);
      }
      return item;
    });
  }

  private getPredefinedEntryElement(entry: any): EntryElement {
    const fieldIdentifier: EPredefinedSpreadsheetToolbarElement = entry.fieldIdentifier;
    switch (fieldIdentifier) {
      case EPredefinedSpreadsheetToolbarElement.RESET_SETTINGS:
        return new ResetSettingsElement(this.scope);
      case EPredefinedSpreadsheetToolbarElement.EXPORT_CSV:
        return new ExportCsvElement(this.scope);
      case EPredefinedSpreadsheetToolbarElement.HEADER_VISIBILITY:
        const headerVisibilityElement = new HeaderVisibilityElement(this.scope);
        this.elementsToUpdate.push(headerVisibilityElement);
        return headerVisibilityElement;
      default:
        console.error('Unknown predefined toolbar element: ' + fieldIdentifier);
        return;
    }
  }

  public updateElements() {
    this.elementsToUpdate.forEach((element) => {
      if (element instanceof HeaderVisibilityElement) {
        element.updateContextMenu();
      }
    });
  }

  private getPredefinedToolbarColMenu(): MenuItem {
    const colGroup: ToolbarGroup = new ToolbarGroup().setName('').setId('col-group');
    const showAllColsGroup: ToolbarGroup = new ToolbarGroup().setName(
      this.scope.translate.instant('Table.Action.showAllColumns')
    );
    const sortGroup: ToolbarGroup = new ToolbarGroup().setName(this.scope.translate.instant('Table.Action.sort'));
    const filterGroup: ToolbarGroup = new ToolbarGroup()
      .setName(this.scope.translate.instant('Table.Action.filter'))
      .setId('filter-group');
    const colMenu: MenuItem = this.setToggleAction(
      new MenuItem().setName(this.scope.translate.instant('Table.Column')).setId('col-item')
    );

    if (this.scope.getTableTemplate().insideDisabledSubMenues(EDefaultSubmenues.COLUMN)) {
      return colMenu;
    }

    const headerSelector: SelectionBox = this.createEntryElement<SelectionBox>(
      new SelectionBox()
        .setId(`${this.scope.getTableTemplate().getId()}_column_selection`)
        .onChanges((changes: EntryElementValue) => {
          if (changes instanceof EntryElementValue) {
            this.scope.toggleVisibilityOfHeader(changes.getId(), changes.isChecked());
          }
        }),
      true
    );

    const values: EntryElementValue[] = this.scope
      .getColumnUtil()
      .getColumns()
      .map((h: SaxMsSpreadSheetColumn) => {
        const found: boolean = this.scope.getHiddenColumnFilterSet().has(h.filterSortObject.getColumnIndex());
        return new EntryElementValue()
          .setName(h.label)
          .setId(h.id)
          .setIndex(h.filterSortObject.getColumnIndex())
          .setChecked(!found);
      });

    const showAllColsIcon: Button = this.createEntryElement<Button>(
      new Button()
        .setDisplayType(EButtonDisplayType.ICON_WITH_LABEL_BELOW)
        .setIcon('THREE_COLUMNS')
        .setName(this.scope.translate.instant('Table.Action.showAllColumns'))
        .setId(`${this.scope.getTableTemplate().getId()}_showAll_columns_btn`)
        .chainActions(
          new Action().setActionType(EActionType.LOCAL).setCb(() => of(this.scope.resetVisibilityOfHeaders()))
        ),
      true
    );

    showAllColsGroup.addEntryElements(showAllColsIcon);

    const sorticon: Button = this.createEntryElement<Button>(
      new Button()
        .setDisplayType(EButtonDisplayType.ICON_WITH_LABEL_BELOW)
        .setIcon('SORTICON')

        .setName(this.scope.translate.instant('Table.Action.sort'))
        .setEnableBy(() => this.scope.getSelectionModel().columnActionsEnable)
        .setId(`${this.scope.getTableTemplate().getId()}_sort_column_btn`)
        .chainActions(new Action().setActionType(EActionType.LOCAL).setCb(() => of(this.scope.sortColumn()))),
      true
    );

    sortGroup.addEntryElements(sorticon);

    headerSelector.setValue(new EntryElementValue().setValue(values));
    if (!this.scope.getTableTemplate().isListensToTime()) {
      colGroup.addEntryElements(headerSelector);
    }

    const columnConfig = this.scope.getConfigApi().access().templates?.Table?.column;

    if (colGroup.getEntryElements().length > 0 && columnConfig?.defaultHeaderSelection === 'default') {
      colMenu.addToolbarGroups(colGroup);
    }
    colMenu.addToolbarGroups(showAllColsGroup);
    if (
      sortGroup.getEntryElements().length > 0 &&
      !this.scope.getTableTemplate().isListensToTime() &&
      columnConfig?.defaultSortBtn !== false
    ) {
      colMenu.addToolbarGroups(sortGroup);
    }
    if (filterGroup.getEntryElements().length > 0 && columnConfig?.defaultFilterOption !== false) {
      colMenu.addToolbarGroups(filterGroup);
    }

    if (
      this.scope.getTableTemplate().getAdditionalSubMenuGroups() &&
      this.scope.getTableTemplate().getAdditionalSubMenuGroups().COLUMN
    ) {
      colMenu.addToolbarGroups(
        ...this.getAdditionalGroupsForPredefineMenus(
          this.scope.getTableTemplate().getAdditionalSubMenuGroups().COLUMN,
          EDefaultSubmenues.COLUMN
        )
      );
    }

    return colMenu;
  }

  private get _rowActionsEnabled(): boolean {
    if (!this.scope.getTableTemplate().getSelectedValue()) {
      return false;
    }
    const match = this.scope
      .getRowDataUtil()
      .getTableRows()
      .find((r) => r.resourceId === this.scope.getTableTemplate().getSelectedValue().resourceId);
    return !this.scope.getRowButtonState('edit') && match && match.editEntryRestUrl ? true : false;
  }

  private getPredefinedToolbarRowMenu(): MenuItem {
    // row
    const rowGroup: ToolbarGroup = new ToolbarGroup().setName(this.scope.translate.instant('Table.Row'));
    const rowMenu: MenuItem = this.setToggleAction(new MenuItem().setName(this.scope.translate.instant('Table.Row')));

    if (this.scope.getTableTemplate().insideDisabledSubMenues(EDefaultSubmenues.ROW)) {
      return rowMenu;
    }

    if (this.scope.tableOptions.editable) {
      if (!this.scope.getTableTemplate().insideDisableDefaultTableMenuButtons(EDefaultTableButton.EDIT)) {
        rowGroup.addEntryElements(
          this.setGroupItem(
            new MenuItem()
              .chainActions(
                new Action().setActionType(EActionType.LOCAL).setCb(() => this.scope.editCurrentSelectedRow())
              )
              .setEnableBy(() => !this.scope.getRowButtonState('edit'))
              .setName('BUTTON.edit')
              .setIcon('edit')
              .setId(this.scope.getTableTemplate().getId() + '_edit_row_btn')
          )
        );
      }
    } else {
      if (!this.scope.getTableTemplate().insideDisableDefaultTableMenuButtons(EDefaultTableButton.DETAILS)) {
        rowGroup.addEntryElements(
          this.setGroupItem(
            new MenuItem()
              .chainActions(
                new Action().setActionType(EActionType.LOCAL).setCb(() => this.scope.editCurrentSelectedRow())
              )
              .setEnableBy(() => !this.scope.getRowButtonState('details'))
              .setName(this.scope.translate.instant('details'))
              .setIcon('visibility')
              .setId(this.scope.getTableTemplate().getId() + '_row_details_btn')
          )
        );
      }
    }
    if (!this.scope.getTableTemplate().insideDisableDefaultTableMenuButtons(EDefaultTableButton.DELETE)) {
      rowGroup.addEntryElements(
        this.setGroupItem(
          new MenuItem()
            .chainActions(
              new Action().setActionType(EActionType.LOCAL).setCb(() => of(this.scope.deleteRowEntryBySubmenu()))
            )
            .setEnableBy(() => !this.scope.getRowButtonState('delete'))
            .setName(this.scope.translate.instant('delete'))
            .setIcon('delete')
            .setId(this.scope.getTableTemplate().getId() + '_delete_row_btn')
        )
      );
    }

    if (!this.scope.getTableTemplate().insideDisableDefaultTableMenuButtons(EDefaultTableButton.CREATE)) {
      const alternativeAddRowLabel = this.scope.getConfigApi().access().templates?.Table
        ?.toolbarButtons?.alternativeAddRowLabel;

      rowGroup.addEntryElements(
        this.setGroupItem(
          new MenuItem()
            .chainActions(new Action().setActionType(EActionType.LOCAL).setCb(() => this.scope.addRowEntry()))
            .setEnableBy(() => !this.scope.getRowButtonState('create'))
            .setName(alternativeAddRowLabel || this.scope.translate.instant('add'))
            .setIcon('ADDROW')
            .setId(this.scope.getTableTemplate().getId() + '_add_btn')
        )
      );
    }

    if (this.scope.getTableTemplate().getCustomOpenAction()) {
      rowGroup.addEntryElements(
        this.setGroupItem(
          new MenuItem()
            .chainActions(
              new Action().setActionType(EActionType.LOCAL).setCb(() => of(this.scope.handleOpenRowEntry()))
            )
            .setEnableBy(() => !this.scope.getRowButtonState('open'))
            .setName(this.scope.translate.instant('navigate'))
            .setIcon('open_in_new')
            .setId(this.scope.getTableTemplate().getId() + '_open_btn')
        )
      );
    }

    if (rowGroup.getEntryElements().length > 0) {
      rowMenu.addToolbarGroups(rowGroup);
    }

    if (
      this.scope.getTableTemplate().getAdditionalSubMenuGroups() &&
      this.scope.getTableTemplate().getAdditionalSubMenuGroups().ROW
    ) {
      rowMenu.addToolbarGroups(
        ...this.getAdditionalGroupsForPredefineMenus(
          this.scope.getTableTemplate().getAdditionalSubMenuGroups().ROW,
          EDefaultSubmenues.ROW
        )
      );
    }

    return rowMenu;
  }

  private getAdditionalGroupsForPredefineMenus(
    additonalGroupsData: any,
    submenuType: EDefaultSubmenues
  ): ToolbarGroup[] {
    const additionrowGroups: ToolbarGroup[] = additonalGroupsData.map((group) => {
      const g: ToolbarGroup = new ToolbarGroup().setName(group.name);
      const entries: EntryElement[] = group.entryElements.map((entry) => {
        if (entry.fieldType === EFieldType.PREDEFINED) {
          return this.getPredefinedEntryElement(entry);
        }
        const e: EntryElement = this.createEntryElement(
          entry instanceof Button ? entry : this.scope.getTemplateAdapter().adapt(entry)
        );
        // if it is a button, set display type
        if (e instanceof Button) {
          const cond: () => boolean = e.getEnabledBy() || (() => true);
          let submenuSpecialCond: () => boolean;
          if (e.isAlwaysEnabled()) {
            submenuSpecialCond = () => true;
          } else {
            switch (submenuType) {
              case EDefaultSubmenues.COLUMN:
                submenuSpecialCond = () => this.scope.getSelectionModel().columnActionsEnable;
                break;
              case EDefaultSubmenues.ROW:
                submenuSpecialCond = () => {
                  switch (e.getActionOperation()) {
                    case EActionOperation.SINGLE_OBJECT:
                      return this.scope.getSelectionModel().rowActionsEnable;
                    case EActionOperation.BATCH_OBJECTS:
                      return this.scope.getSelectionModel().batchRowActionsEnable;
                    case EActionOperation.NONE_OBJECT:
                    default:
                      return true;
                  }
                };
                break;
              default:
                submenuSpecialCond = () => true;
                break;
            }
          }
          e.setAlwaysEnabled(false);
          e.setDisplayType(EButtonDisplayType.ICON_WITH_LABEL_BELOW).setEnableBy(
            () =>
              !this.scope.isEditableModeLocked() &&
              cond() &&
              submenuSpecialCond() &&
              this.templateActionService.checkActions(e.getChain().getActions())
          );
        }
        return e;
      });
      g.setEntryElements(entries);
      return g;
    });
    return additionrowGroups;
  }

  public setToggleAction(item: MenuItem): MenuItem {
    return item
      .setDisplayType(EButtonDisplayType.ICON_AND_LABEL)
      .setToolbarItemType(EToolbarItemType.GROUP)
      .chainActions(new Action().setActionType(EActionType.LOCAL).setCb(() => of(this.toggleActiveMenuItem(item))));
  }

  private setGroupItem(item: MenuItem): MenuItem {
    return this.createEntryElement<MenuItem>(item.setDisplayType(EButtonDisplayType.ICON_WITH_LABEL_BELOW), true);
  }

  public createEntryElement<T extends EntryElement>(element: T, predefined?: boolean): T {
    if (
      element instanceof Button &&
      !element.getUpdateElementIds().find((id: string) => id === this.scope.getTableTemplate().getId()) &&
      !predefined
    ) {
      // console.warn(`[SPREADSHEET] the button has no updateElementId for its own template id`, element);
      // element.setUpdateElementIds(element.getUpdateElementIds().concat(this.scope.tableTemplate.getId()));
      element.setRefreshElementIds(element.getRefreshElementIds().concat(this.scope.getTableTemplate().getId()));
    }

    return element; // .setContextmenu(this.addContextMenu(element));
  }

  private addContextMenu(element: EntryElement): ContextMenu {
    const ctx: ContextMenu = new ContextMenu();
    const entry: ContextMenuItem = new ContextMenuItem()
      .setDisplayType(EButtonDisplayType.ICON_AND_LABEL)
      .setDisabled(true)
      .setName(this.scope.translate.instant('SUBMENU.ACTION.addFavorit'))
      .setIcon('star_border')
      .chainActions(new Action().setActionType(EActionType.LOCAL).setCb(() => of(this.toggleFavorite(element, entry))));
    ctx.setContextMenuItems([entry]);

    return ctx;
  }

  private toggleFavorite(entry: EntryElement, ctxAction: Button) {
    const g: ToolbarGroup = this.getMenuItems()
      .find((m: MenuItem) => m.getId() === 'favorites')
      .getToolbarGroups()[0];
    if (!g) {
      return;
    }

    if (!g.getEntryElements().find((e: EntryElement) => e.getId() === entry.getId())) {
      g.setEntryElements(g.getEntryElements().concat(entry));
      ctxAction.setName(this.scope.translate.instant('SUBMENU.ACTION.removeFavorit')).setIcon('star');
      this.scope
        .getSystemMessageService()
        .show(new Message().setText('SUBMENU.ACTION.valueAddToFavorites').setType(EMessageType.INFO).setDuration(7500));
    } else {
      ctxAction.setName(this.scope.translate.instant('SUBMENU.ACTION.addFavorit')).setIcon('star_border');
      g.setEntryElements(g.getEntryElements().filter((e: EntryElement) => e.getId() !== entry.getId()));
    }
  }

  public get quicksearchMenu(): PredefinedQuickSearchMenu {
    return this._quicksearchMenu;
  }
}
