declare let NodeNetworkBackgroundHandler: any;

/**
 * Experimental implementation to add a background image to node networks.
 */
export class NodeNetworkBackground {
  public builder: any = null;
  public backgroundImages: NodeNetworkBackgroundImage[] = [];

  constructor() {
    this.builder = new NodeNetworkBackgroundHandler();
    // remove comment to test background image.
    // let testImage = new NodeNetworkBackgroundImage();
    // testImage.url = "https://thumbs.gfycat.com/FaintMerryIcelandicsheepdog-size_restricted.gif";
    // testImage.x = 200;
    // testImage.y = 200;
    // this.backgroundImages.push(testImage);
  }

  // Inits background builder.
  public init(parentNode, parentHandlerNode): void {
    this.builder.init(parentNode, parentHandlerNode);
  }

  /**
   * Adds all registered images.
   */
  public buildImages(): void {
    for (const image of this.backgroundImages) {
      this.addImage(image.url, image.x, image.y);
    }
  }

  /**
   * Adds one image to given position.
   * @param url Image path.
   * @param x Horizontal image position.
   * @param y Vertical image position.
   */
  public addImage(url: string, x: number, y: number): void {
    this.builder.addImage(url, x, y);
  }

  /**
   * (De-)Activates mode to resize and drag and drop image.
   * @param bool De-/Activate edit mode.
   */
  public activateEditMode(bool: boolean): void {
    this.builder.activateEditMode(bool);
  }

  /**
   * Get state of edit mode.
   */
  public getEditMode(): boolean {
    return this.builder.getEditMode();
  }
}

/**
 * Data container for one image.
 */
export class NodeNetworkBackgroundImage {
  public x = 0;
  public y = 0;
  public url = '';
}
