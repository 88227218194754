import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

/**
 * Provides all node network elements after filter them.
 */
@Injectable()
export class NodeNetworkFilterService {
  public uri = 'rest/visualization';

  constructor(public http: HttpClient) {}

  public getCompleteMachineViewProducts(modelId: string, id: string, body: any): Observable<any> {
    return this.http.post(`` + this.uri + '/completeMachineView/' + modelId + '/' + id + '/products', body);
  }

  public getCompleteMachineViewConnections(modelId: string, id: string, productId: string, body: any): Observable<any> {
    return this.http.post(
      `` + this.uri + '/completeMachineView/' + modelId + '/' + id + '/products/' + productId,
      body
    );
  }
}
