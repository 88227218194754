import { ETemplateType } from 'frontend/src/dashboard/model/resource/template-type';
import { EntryElement } from '../entry-collection/entry-element';
import { EntryElementValue } from '../entry-collection/entry-element-value';
import { EFieldType } from '../entry-collection/field-type.enum';

export class ComboBox extends EntryElement {
  constructor() {
    super();
    this.setType(ETemplateType.COMBO_BOX);
    this.setFieldType(EFieldType.COMBO_BOX);
  }

  public setValue(value: EntryElementValue) {
    // extend the combo box value with the unlocalized value
    if (value && value instanceof EntryElementValue) {
      const unlocalizedValue = (value?.getLocalizedValues() || {})[value?.getValue<string>()];
      value?.setUnlocalizedValue(unlocalizedValue);
    }

    super.setValue(value);
    return this;
  }
}
