<div [class]="viewerClassname">
  <button *ngIf="canScrollLeft" class="scroll left" mat-button (click)="scrollLeft($event)">
    <mat-icon>keyboard_arrow_left</mat-icon>
  </button>
  <button *ngIf="canScrollRight" class="scroll right" mat-button (click)="scrollRight($event)">
    <mat-icon>keyboard_arrow_right</mat-icon>
  </button>
  <div
    #nodeContainer
    class="nodeContainer"
    [ngStyle]="{ cursor: viewer.getViewerData().length ? 'pointer' : 'default' }"
    (click)="onClick()"
  >
    <div *ngIf="!viewer.getViewerData().length">{{ '@noFilterActive@' | translate }}</div>
    <ng-container *ngFor="let item of viewer.getViewerData()">
      <ng-container [ngSwitch]="item.type">
        <div *ngSwitchCase="'BRACKET'" class="brackets noInteraction" [style.color]="item.colorValue">
          {{ item.value }}
        </div>

        <div
          *ngSwitchCase="'CONNECTION'"
          class="connections noInteraction"
          [ngStyle]="{ color: item.value === 'UND' ? '#000000' : '#5a5a5a' }"
        >
          &nbsp;{{ item.value }}&nbsp;
        </div>

        <div *ngSwitchCase="'ATTRIBUTE'" class="attributes noInteraction">
          {{ item.value }}
        </div>

        <div *ngSwitchCase="'COMPARATOR'" class="comparators noInteraction">&nbsp;{{ item.value }}&nbsp;</div>

        <div *ngSwitchCase="'VALUE'" class="values noInteraction">
          {{ item.value }}
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
