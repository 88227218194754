import { Observable, Subject } from 'rxjs';
import { ContentElement } from '../content/content-element/content-element';
import { EventRange } from './event-range';
import { ECalendarRestriction } from './range-picker-restriction.enum';

export class RangePicker extends ContentElement {
  private restriction: ECalendarRestriction;
  private onChange: Subject<EventRange> = new Subject<EventRange>();
  private value: EventRange;
  private startRangeDate: Date;
  private endRangeDate: Date;

  public getStartRangeDate(): Date {
    return this.startRangeDate;
  }

  public setStartRangeDate(startRangeDate: Date): this {
    this.startRangeDate = startRangeDate;
    return this;
  }

  public getEndRangeDate(): Date {
    return this.endRangeDate;
  }

  public setEndRangeDate(endRangeDate: Date): this {
    this.endRangeDate = endRangeDate;
    return this;
  }

  get entry(): EventRange {
    return this.getValue();
  }

  public getValue(): EventRange {
    if (!this.value) {
      this.value = new EventRange();
    }
    return this.value;
  }

  public setValue(value: EventRange): this {
    this.value = value;
    this.onChange.next(this.value);
    return this;
  }

  public onChanges(): Observable<EventRange> {
    return this.onChange.asObservable();
  }

  public getRestriction(): ECalendarRestriction {
    return this.restriction;
  }

  public setRestriction(restrictions: ECalendarRestriction): this {
    this.restriction = restrictions;
    return this;
  }

  constructor() {
    super();
    this.setRestriction(ECalendarRestriction.DAY);
  }
}
