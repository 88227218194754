import { TriggerEventFactory } from 'frontend/src/dashboard/gantt/general/gantt-actions/event-factory';
import { GeneralGanttActionHandler } from 'frontend/src/dashboard/gantt/general/action-handling/action-handler';
import { GanttResponseHandler } from 'frontend/src/dashboard/gantt/general/response/response-handler';
import { Observable, of } from 'rxjs';
import { TriggerEventBlockAddButtonFactory } from './event-factory';
import { GanttEventTrigger } from 'frontend/src/dashboard/gantt/general/gantt-actions/external-event-trigger';
import { GanttExternalActionRegistration } from 'frontend/src/dashboard/gantt/general/gantt-actions/external-action-registration';
import { GanttLibService } from 'frontend/src/dashboard/gantt/gantt/gantt-lib.service';
import { GanttPluginHandlerService } from 'frontend/src/dashboard/gantt/general/plugin/gantt-plugin-handler.service';

/**
 * Action handler whiuch registers callbacks and handles local actions.
 */
export class GanttBlockAddActionHandler implements GanttExternalActionRegistration {
  private currentActionTriggers: GanttEventTrigger[] = [];
  private triggerEventFactory: TriggerEventFactory;

  constructor(
    private _ganttPluginHandlerService: GanttPluginHandlerService,
    private _ganttLibService: GanttLibService,
    private ganttAddBlockExecuter: any,
    private actionHandler: GeneralGanttActionHandler,
    private responseHandler: GanttResponseHandler,
    templateData: any,
    blockAddData: any
  ) {
    this.triggerEventFactory = new TriggerEventBlockAddButtonFactory(templateData);
    this.currentActionTriggers = this.extractEventTriggersByGanttActions(blockAddData.ganttActions);
  }

  private extractEventTriggersByGanttActions(ganttResourceChangeActions: any[]): GanttEventTrigger[] {
    let triggerList: GanttEventTrigger[] = [];
    // add local actions
    triggerList = triggerList.concat(
      this.triggerEventFactory.getTriggerEventByActionTypes(
        this._ganttPluginHandlerService,
        this._ganttLibService,
        ganttResourceChangeActions,
        this.ganttAddBlockExecuter,
        this.actionHandler,
        this.responseHandler
      )
    );
    return triggerList;
  }

  public handleLocalAction(localAction: any, callBackParams?: any): Observable<any> {
    if (localAction.actionType == 'PREDEFINED') {
      switch (localAction.id) {
      }
    }

    return of(null);
  }
}
