import { Component, OnDestroy } from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { AuthenticationService } from '@core/authentication/auth.service';
import { ConfigService } from '@core/config/config.service';
import { User } from 'frontend/src/dashboard/user/user';
import { PasswordChangeResponse, UserService } from 'frontend/src/dashboard/user/data-access/user.service';
import { of } from 'rxjs';
import { delay, switchMap, takeWhile } from 'rxjs/operators';
import { PasswordUtils } from '../password-utils';
@Component({
  selector: 'change-password-lightbox',
  templateUrl: './change-password-lightbox.html',
  styleUrls: ['./change-password-lightbox.scss'],
})
export class ChangePasswordLightboxComponent implements OnDestroy {
  passwordForm: UntypedFormGroup;
  passwordConfig: Record<string, any>;
  newPWvisibility: boolean;
  repeatedPWvisibility: boolean;
  curPWVisibility: boolean;
  passwordChanged: boolean;
  errorList: string[];
  alive = true;
  user: User;
  specialCharacters: string;

  constructor(private config: ConfigService, private userApi: UserService, private auth: AuthenticationService) {
    this.userApi
      .getUser()
      .pipe(takeWhile(() => this.alive))
      .subscribe((user: User) => (this.user = user));
    this.passwordConfig = PasswordUtils.getPasswordConfig(this.config.access().security?.password);
    this.specialCharacters = this.passwordConfig.specialCharacters.map((item) => `\\${item}`).join('');

    this.passwordForm = new UntypedFormGroup(
      {
        oldPassword: new UntypedFormControl('', Validators.required),
        newPassword: new UntypedFormControl('', [
          Validators.required,
          PasswordUtils.testRegex(/[a-z]+/, 'lowercase', this.passwordConfig.lowercase),
          PasswordUtils.testRegex(/[A-Z]+/, 'uppercase', this.passwordConfig.uppercase),
          PasswordUtils.testRegex(/[0-9]+/, 'decimal', this.passwordConfig.decimal),
          PasswordUtils.testRegex(
            new RegExp(`[${this.specialCharacters}]+`),
            'special',
            this.passwordConfig?.specialCharacters ? true : false
          ),
          Validators.minLength(this.passwordConfig.minPasswordLength),
          Validators.maxLength(this.passwordConfig.maxPasswordLength),
        ]),
        repeatedPassword: new UntypedFormControl('', [Validators.required]),
      },
      {
        validators: this.customValidator('same'),
      }
    );
  }

  get encodedSpecialCharacters(): string {
    return this.passwordConfig.specialCharacters.join('');
  }

  cancel(): void {
    this.auth.signOut().subscribe();
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  reset(): void {
    this.errorList = [];
  }

  customValidator(name: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const match = this.passwordForm?.get('newPassword').value === this.passwordForm?.get('repeatedPassword').value;

      return match ? null : { [name]: { value: control.value } };
    };
  }

  /**
   * generates password
   * @returns string
   */
  generatePassword(ev: MouseEvent): void {
    const pw: string = PasswordUtils.generate(this.passwordConfig);
    this.passwordForm.get('newPassword').setValue(pw);
    this.passwordForm.get('repeatedPassword').setValue(pw);
  }

  /**
   * confirms dialog and changes password
   * @param {MouseEvent} ev mouse event
   * @returns void
   */
  confirm(ev: MouseEvent): void {
    if (
      !this.passwordForm.get('newPassword').touched ||
      !this.passwordForm.get('repeatedPassword').touched ||
      !PasswordUtils.validate(this.passwordForm.get('newPassword').value, this.passwordConfig) ||
      !PasswordUtils.validate(this.passwordForm.get('repeatedPassword').value, this.passwordConfig)
    ) {
      return;
    }

    this.errorList = [];
    this.userApi
      .changePassword(this.passwordForm.get('oldPassword').value, this.passwordForm.get('newPassword').value)
      .pipe(
        switchMap((response: PasswordChangeResponse) => {
          if (response?.errorList?.length > 0) {
            this.errorList = response.errorList;
            return of(null);
          } else {
            this.passwordChanged = true;
          }
          return of(null)
            .pipe(delay(5000))
            .pipe(switchMap(() => this.auth.signOut()));
        })
      )
      .subscribe();
  }
}
