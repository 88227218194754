export enum ENotificationType {
  MESSAGE = 'MESSAGE',
  NOTIFICATION = 'NOTIFICATION',
  EMAIL_NOTIFICATION = 'EMAIL_NOTIFICATION',
  SYSTEM_NOTIFICATION = 'SYSTEM_NOTIFICATION',
  TASK = 'TASK',
  FRONTEND_UPDATE_NOTIFICATION = 'FRONTEND_UPDATE_NOTIFICATION',
  HIDDEN_NOTIFICATION = 'HIDDEN_NOTIFICATION',
  USER_SETTINGS_CHANGED = 'USER_SETTINGS_CHANGED',
  MENU_UPDATE_NOTIFICATION = 'MENU_UPDATE_NOTIFICATION',
  HIGHLIGHT_GANTT_BLOCK_NOTIFICATION = 'HIGHLIGHT_GANTT_BLOCK_NOTIFICATION',
  GANTT_UPDATE_NOTIFICATION = 'GANTT_UPDATE_NOTIFICATION',
  EXPERIMENT_UDPATED = 'EXPERIMENT_UDPATED',
  EXPERIMENT_UPDATED = 'EXPERIMENT_UPDATED',
  UPDATE_ELEMENT_NOTIFICATION = 'UPDATE_ELEMENT_NOTIFICATION',
  DISPOSITION_ORDER_REQUEST_CANCELLED_NOTFICATION = 'DISPOSITION_ORDER_REQUEST_CANCELLED_NOTFICATION',
  DISPOSITION_ORDER_REQUEST_CHANGED_NOTFICATION = 'DISPOSITION_ORDER_REQUEST_CHANGED_NOTFICATION',
  HMI_CHILD_NODE_NOTIFICATION = 'HMI_CHILD_NODE_NOTIFICATION',
  /**
   * Updates second tree column only
   */
  FORCED_NOTIFICATION = 'FORCED_NOTIFICATION',
  UPDATE_TABLE_ROWS_NOTIFICATION = 'UPDATE_TABLE_ROWS_NOTIFICATION',
  FILE_DOWNLOAD_NOTIFICATION = 'FILE_DOWNLOAD_NOTIFICATION',
  GANTT_CONTEXT_CHANGE = 'GANTT_CONTEXT_CHANGE',
  UPDATE_CHAT_NOTIFICATION = 'UPDATE_CHAT_NOTIFICATION',
  AUTHENTICATE_GRAPH_API = 'AUTHENTICATE_GRAPH_API',
  RELOAD_TREE_NOTIFICATION = 'RELOAD_TREE_NOTIFICATION',
  TRIGGER_LOADING_INDICATOR = 'TRIGGER_LOADING_INDICATOR',
  REFRESH_PAGE = 'REFRESH_PAGE',
}
