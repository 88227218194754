import { BestGantt } from '../main';

/**
 * Central managing Class for undo / redo events.
 * Manages history of component events and calls components to undo / redo events if called.
 * @param {BestGantt} ganttDiagram Reference to the ganttDiagram.
 * @keywords ganttHistory undo redo central
 * @constructor
 * @class
 * @requires GanttHistoryEvent
 * @author Florian Freier
 */
export class GanttHistory {
  history: any;
  enabled: boolean;
  undoRedo: boolean;
  ganttDiagram: BestGantt;

  constructor(ganttDiagram) {
    this.history = {
      past: [],
      present: null,
      future: [],
    };
    this.enabled = false; // boolean to allow adding new events to queue
    this.undoRedo = true; // boolean to toggle undo redo functionality
    this.ganttDiagram = ganttDiagram;
  }

  /**
   * Pushes latest event to the past. New Event is the present now. Discardes the future.
   * Creates new GanttHistoryEvent with eventName and sets refernce to the component that created event.
   *
   * @keywords history, event, undo, redo
   * @param {String} eventName Name of the event that has taken place.
   * @param {ComponentEvent} componentEvent Reference to the eventType that handles this event.
   * @param {*} componentReference Reference to the component that created this event.
   * @param {*} optionalParam Any parameters for handling event later.
   * @return {ComponentEvent} Reference to the component event.
   */
  addNewEvent(name, componentEvent, componentReference, optionalParam = undefined) {
    const s = this;
    if (!componentReference.hasOwnProperty('eventLogging'))
      console.error('Forgot to give the component ' + componentReference + ' the eventlLogging property!');
    if (!s.enabled || !componentReference.eventLogging) return; // check if history or component is supposed to log events or not.

    s.history.past.push(s.history.present);
    s.history.present = componentEvent;
    s.history.present.setName(name);
    s.history.present.setReference(componentReference);
    s.history.present.setGanttReference(s.ganttDiagram);
    const args = Array.from(arguments);
    for (
      let i = 0;
      i < 3;
      i++ // cut off name and references, so only args remain
    )
      args.shift();
    s.history.present.setArguments(args);
    s.history.future = [];

    return componentEvent;
  }

  /**
   * Gets called from the html to initiate a UNDO.
   * Manages the global history.
   * Calls on the present Event to do the undoing.
   *
   * @keywords global history undo
   * @returns {boolean} true if undo was succesful, else false
   */
  undo() {
    const s = this;
    if (!s.undoRedo) return;
    let success = false;
    if (s.history.present != undefined) {
      if (typeof s.history.present.undo === 'function') success = s.history.present.undo();
      if (success) {
        s.history.future.push(s.history.present);
        s.history.present = s.history.past.pop();
      }
    }
    return success;
  }

  /**
   * Gets called from the html to initiate a REDO.
   * Manages the global history.
   * Calls on the present Event to do the redoing.
   *
   * @keywords global history redo
   * @returns {boolean} true if redo was succesful, else false
   */
  redo() {
    const s = this;
    if (!s.undoRedo) return;
    let success = false;
    if (s.history.future.length > 0) {
      s.history.past.push(s.history.present);
      s.history.present = s.history.future.pop();

      if (typeof s.history.present.redo === 'function') success = s.history.present.redo();

      if (!success) {
        s.history.future.push(s.history.present);
        s.history.present = s.history.past.pop();
      }
    }
    return success;
  }

  // GETTER & SETTER
  /**
   * @returns {{past: GanttHistoryEvent[], present: GanttHistoryEvent, future: GanttHistoryEvent[]}}
   */
  getHistory() {
    return this.history;
  }

  /**
   * @returns {GanttHistoryEvent[]} The past events ordered by time in the array.
   */
  getPast() {
    return this.history.past;
  }

  /**
   * @returns {GanttHistoryEvent} The latest event.
   */
  getPresent() {
    return this.history.present;
  }

  /**
   * @returns {GanttHistoryEvent[]} The future events ordered by time in the array.
   */
  getFuture() {
    return this.history.future;
  }

  /**
   * @returns {GanttHistoryEvent | null} The next previous element to the latest one.
   */
  getPreviousPast() {
    return this.history.past[this.history.past.length - 1] || null;
  }

  /**
   * @returns {GanttHistoryEvent} The event that next in the future.
   */
  getNextFuture() {
    return this.history.future[this.history.future.length - 1] || null;
  }

  /**
   * Enables EventLogging for GanttHistory.
   * Does not enable undo redo functions.
   */
  enableEventLogging() {
    this.enabled = true;
  }

  /**
   * Disables EventLogging for GanttHistory.
   * Does not disable undo redo functions from working.
   */
  disableEventLogging() {
    this.enabled = false;
  }

  /**
   * Stops undo redo functions from performing any actions.
   * Does not disable addition of new events to queue.
   */
  blockUndoRedo() {
    this.undoRedo = false;
  }

  /**
   * Enables undo redo functions.
   * Does not enable addition of new events to queue.
   */
  enableUndoRedo() {
    this.undoRedo = true;
  }

  /**
   * only for testing / debugging
   * @private
   */
  _clearHistory() {
    this.history.past = [];
    this.history.present = null;
    this.history.future = [];
  }

  removeComponent(componentReference) {
    const s = this;

    for (var i = s.history.future.length - 1; i > -1; i--) {
      if (s.history.future[i].ref === componentReference) {
        s.history.future.splice(0, i + 1);
        return;
      }
    }

    if (componentReference === s.history.present.getReference()) {
      s._clearHistory();
      return;
    }

    for (var i = s.history.past.length - 1; i > -1; i--) {
      if (s.history.past[i].ref === componentReference) {
        s.history.past.splice(0, i + 1);
        return;
      }
    }
  }

  /**
   * Use with care. Deletes the present (last) element from the history.
   * Used e.g. in trash-bin plugin to get rid of translation event before deletion.
   * Danger of creating inconsistent state!
   */
  deletePresentEvent() {
    if (this.history.past.length !== 0) this.history.present = this.history.past.pop();
  }
}
