import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ISpreadsheetSaveSettings } from './spreadsheet';

@Injectable({
  providedIn: 'root',
})
export class SpreadsheetSettingsService {
  private lastJson: string;
  constructor(private http: HttpClient) {}

  public resetSpreadsheetSettings(tableId: string): Observable<ISpreadsheetSaveSettings> {
    return this.http.get<ISpreadsheetSaveSettings>(`rest/tablesettings/reset/${tableId}`);
  }

  public getSpreadsheetSettings(tableId: string): Observable<ISpreadsheetSaveSettings> {
    return this.http.get<ISpreadsheetSaveSettings>(`rest/tablesettings/${tableId}`);
  }

  public saveSpreadsheetSettings(body: ISpreadsheetSaveSettings): Observable<any> {
    if (body.columnSettings) {
      body.columnSettings = body.columnSettings
        .sort((a, b) => {
          return a.createdValue > b.createdValue ? -1 : 1;
        })
        .filter((column, index, list) => {
          return list.findIndex((_item) => _item.columnID === column.columnID) === index;
        });
    }

    const newJson = JSON.stringify(body);
    if (this.lastJson === newJson) {
      return of(null);
    }

    this.lastJson = newJson;

    return this.http.post(`rest/tablesettings/save`, body);
  }

  public deleteSpreadsheetSettings(seetingsId: string): Observable<any> {
    return this.http.delete(`rest/tablesettings/${seetingsId}`);
  }

  public isValid(settings: ISpreadsheetSaveSettings): boolean {
    return true;
    // check orderIdx uniqueness
    const orderIdxList = settings.columnSettings
      .map((column) => column.orderIdx)
      .filter((column) => column !== undefined && column !== null);
    const noOrderIdxDuplicates = !orderIdxList.filter((item, index) => orderIdxList.indexOf(item) !== index).length;

    return noOrderIdxDuplicates;
  }
}
