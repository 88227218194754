import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ERequestMethod, RequestService } from '@app-modeleditor/request.service';
import { TemplateService } from '@app-modeleditor/utils/template.service';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { ContextMenu } from '../contextmenu/contextmenu';
import { EntryElement } from '../entry-collection/entry-element';
import { EntryElementValue } from '../entry-collection/entry-element-value';
import { EntryElementFactory } from '../entry-collection/entry-factory.service';
import { EFieldType } from '../entry-collection/field-type.enum';
import { TemplatePicker } from '../template-picker/template-picker';
import { Tile } from '../tile-button/tile';
import { TemplateAdapter } from './../../utils/template-factory.service';
import { ContextmenuService } from './../contextmenu/contextmenu.service';
import { TemplateUnregisterService } from './../template/template-unregister.service';
@Component({
  selector: 'entry-element',
  templateUrl: './entry-element.component.html',
  styleUrls: ['./entry-element.component.scss'],
})
export class EntryElementComponent implements OnInit, OnChanges, OnDestroy {
  @Input() el;
  @Input() template;
  @Input() layoutElement;
  @Input() highlightedKey: string;
  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() disabled: boolean;
  @Input() resourceId: string;
  entryElement: EntryElement;
  private ngDestroy: Subject<void> = new Subject<void>();
  /**
   * Indicates whether the entry element is currently processing a request.
   */
  protected isProcessing = false;

  constructor(
    private templateApi: TemplateService,
    private contextMenuApi: ContextmenuService,
    private ref: ElementRef,
    private templatefactory: TemplateAdapter,
    private _cd: ChangeDetectorRef,
    private requestApi: RequestService,
    private entryElementFactory: EntryElementFactory,
    private $templateUnregisterApi: TemplateUnregisterService
  ) {}

  openCtx(event: MouseEvent): void {
    if (!this.entryElement.isAllowContextMenuWhenDisabled() && this.disabled === true) {
      return;
    }
    const ctx: ContextMenu = this.entryElement.getContextmenu();
    if (!ctx) {
      return;
    }
    event.preventDefault();
    if (!(this.entryElement instanceof Tile)) {
      event.stopImmediatePropagation();
    }
    this.contextMenuApi.create(this.ref, ctx);
  }

  ngOnInit(): void {
    this.initialize();

    if (this.entryElement.getValueRestUrl()) {
      this.fetchValue(this.entryElement.getValueRestUrl());
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.resourceId) {
      this.initialize();
    }
  }

  ngOnDestroy(): void {
    this.$templateUnregisterApi.unregister(this.entryElement, this.entryElement.getResourceId());
    this.entryElement.destroy();
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  /**
   * Registers element for global usage.
   */
  private initialize(): void {
    if (!this.el) {
      return;
    }

    if (this.el && this.el instanceof EntryElement) {
      this.el.setElementRef(this.ref);
    }

    this.entryElement = this.el instanceof EntryElement ? this.el : this.templatefactory.adapt(this.el);

    if (!this.entryElement.getResourceId()) {
      this.entryElement.setResourceId(this.resourceId);
    }

    this.templateApi.registerTemplate(this.entryElement.getResourceId(), this.entryElement);

    this._cd.markForCheck();
  }

  /**
   * Fetches a value from the API and sets it as the new value of the entry element.
   * @param url - The URL to fetch the value from.
   */
  private fetchValue(url: string): void {
    this.isProcessing = true;
    this.requestApi
      .call(ERequestMethod.GET, `rest/${url}`)
      .pipe(
        takeUntil(this.ngDestroy),
        finalize(() => (this.isProcessing = false))
      )
      .subscribe((res) => {
        if (res) {
          // set new value
          this.entryElement.setValue(
            this.entryElementFactory.parseEntryValue<EntryElementValue>(EntryElementValue, res)
          );
          this._cd.markForCheck();
        }
      });
  }

  onEmit(element: EntryElement, event: any, url: string, el?: any): void {
    this.valueChange.emit({ track: !element.isDoNotTrackChanges(), event, url, template: el });

    if (this.el instanceof EntryElement) {
      switch (this.el.getFieldType()) {
        case EFieldType.GANTT_SETTINGS:
        case EFieldType.TRANSFER_GANTT_SETTINGS:
        case EFieldType.DURATION_PICKER:
          this.el.setValue(this.el.getValue<EntryElementValue>().setValue(event));
          break;
        case EFieldType.DATE_PICKER:
        case EFieldType.TIME_PICKER:
        case EFieldType.DATE_TIME_PICKER:
          this.el.setValue(
            this.el
              .getValue<EntryElementValue>()
              .setValue(event && event[0] !== null ? new Date(event[0]).getTime() : null)
          );
          break;
        case EFieldType.RANGE_PICKER:
          this.el.getValue<EntryElementValue>().setValue({
            from: event ? new Date(event[0]).getTime() : null,
            to: event ? new Date(event[1]).getTime() : null,
          });
          break;

        case EFieldType.CALENDAR_WEEK_PICKER:
          if ((<TemplatePicker>element).isUseMillisForCalendarWeek()) {
            this.el.getValue<EntryElementValue>().setValue(new Date(event.week.start).getTime());
          } else {
            this.el.getValue<EntryElementValue>().setValue(event.week.number);
          }
          break;
        case EFieldType.CALENDAR_YEAR_PICKER:
          this.el.getValue<EntryElementValue>().setValue(event.selectedYear);
          break;
        case EFieldType.CALENDAR_MONTH_PICKER:
          this.el
            .getValue<EntryElementValue>()
            .setValue(new Date(event.selectedYear || new Date().getFullYear(), event.selectedMonth || 0).getTime());
          break;
        case EFieldType.SELECTION_BOX:
          this.el.executeChanges(event);
          return;
      }
      this.el.executeChanges();
    }
  }
}
