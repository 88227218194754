import { EDisplayOrientation } from '@app-modeleditor/components/content/content-element/display-orientation.enum';
import { EntryElement } from '@app-modeleditor/components/entry-collection/entry-element';
import { EntryCollection } from './../../modeleditor/components/entry-collection/entry-collection';

export class ToolbarGroup extends EntryCollection {
  protected entryElements: EntryElement[];

  constructor() {
    super();
    this.setDisplayOrientation(EDisplayOrientation.HORIZONTAL).setPreventNewLines(true).setDoNotTrackChanges(true);
  }
}
