import { Component, ElementRef, HostBinding, Inject, NgZone, OnInit } from '@angular/core';
import { CONTAINER_DATA } from '@app-modeleditor/components/lightbox/overlay/container-data';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { DefaultOverlayContainer } from '../lightbox/overlay/default-overlay-container';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  host: {
    '(mouseenter)': 'onMouseEnter()',
    '(mouseleave)': 'onMouseLeave()',
  },
})
export class TooltipComponent extends DefaultOverlayContainer<any> implements OnInit {
  @HostBinding('class') class: string;
  private prevClosing: boolean;
  private locked: boolean;
  public hideDelay: number;

  constructor(
    private zone: NgZone,
    @Inject(CONTAINER_DATA)
    public options: {
      text: string;
      class: string;
      parent: HTMLElement | ElementRef;
    }
  ) {
    super();
    this.class = options?.class;
  }

  onMouseEnter(): void {
    this.locked = true;
  }

  onMouseLeave(): void {
    this.locked = false;
    this.close();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  public preventClosing(state: boolean): void {
    this.prevClosing = state;
  }

  isLocked(): boolean {
    return typeof this.locked === 'boolean' ? this.locked : false;
  }

  isPreventClosing(): boolean {
    return typeof this.prevClosing === 'boolean' ? this.prevClosing : false;
  }

  close(): Observable<void> {
    if (this.isPreventClosing() === true || this.isLocked() === true) {
      return;
    }
    this.zone.run(() => {
      of(null)
        .pipe(delay(this.hideDelay))
        .subscribe(() => {
          this.getRef().dispose();
          this.getRef().detach();
        });
    });
  }
}
