import * as d3 from 'd3';
import { YAxisDataFinder } from '../../../data-handler/data-finder/yaxis-data-finder';
import { GanttCanvasShift } from '../../../data-handler/data-structure/data-structure';
import { GanttUtilities } from '../../../gantt-utilities/gantt-utilities';
import { PatternType } from '../../../pattern/pattern-type.enum';
import { IGanttShiftsBuilding } from '../../../shifts/shift-build-strategies/shift-build-interface';
import { GanttShiftsAfterRenderEvent, ShiftBuilder } from '../../../shifts/shift-builder';

/**
 * Default shift building.
 * @implements {IGanttShiftsBuilding}
 * @class
 */
export class GanttTimePeriodShiftBuildingPrintEFW implements IGanttShiftsBuilding {
  /**
   * @override
   */
  renderShifts(dataSet, parentNode, executer: ShiftBuilder) {
    const s = executer;
    if (!dataSet) return;
    let parent = s.getShiftGroupOverlay();
    let dragStartEvent, draggedElementCoordinates, isToleranceOvercome;

    if (parentNode) parent = parentNode;

    s.setAllShifts(parent.selectAll<SVGRectElement, GanttCanvasShift>('.gantt_shifts').data(dataSet));

    s.getAllShifts()
      .attr('width', function (d) {
        return d.width * s.getLastZoomTransformation().k;
      })
      .attr('height', function (d) {
        return d.height - 2;
      })
      .attr('x', function (d) {
        return d.x * s.getLastZoomTransformation().k + s.getLastZoomTransformation().x;
      })
      .attr('y', function (d) {
        return s.renderDataHandler
          .getStateStorage()
          .getYPositionRow(YAxisDataFinder.getRowByYPosition(s.dataHandler.getYAxisDataset(), d.y).id);
      })
      // use fill as an attribute here, d3.js has problems with .style() to update
      .attr('fill', function (d) {
        if (d.highlighted) return d.highlighted;
        if (d.selected) return d.selected;
        return s.getPatternHandler().getPatternAsUrl(PatternType.DIAGONAL_STRIPE_1, 'white', d.color);
      })
      .attr('stroke', function (d) {
        return 'black';
      })
      .attr('stroke-width', function () {
        return s.ganttConfig.getShiftStrokeWidth();
      });

    s.getAllShifts()
      .enter()
      .append('rect')
      .attr('class', 'gantt_shifts')
      .attr('width', function (d) {
        return d.width * s.getLastZoomTransformation().k;
      })
      .attr('height', function (d) {
        return d.height;
      })
      .attr('x', function (d) {
        return d.x * s.getLastZoomTransformation().k + s.getLastZoomTransformation().x;
      })
      .attr('y', function (d) {
        return s.renderDataHandler
          .getStateStorage()
          .getYPositionRow(YAxisDataFinder.getRowByYPosition(s.dataHandler.getYAxisDataset(), d.y).id);
      })
      .attr('fill', function (d) {
        if (d.highlighted) return d.highlighted;
        if (d.selected) return d.selected;
        return s.getPatternHandler().getPatternAsUrl(PatternType.DIAGONAL_STRIPE_1, 'white', d.color);
      })
      .attr('stroke', function (d) {
        return 'black';
      })
      .attr('stroke-width', function () {
        return s.ganttConfig.getShiftStrokeWidth();
      })
      .on('mouseover', function (event) {
        s.shiftMouseOverEvent(event);
      })
      .on('mouseout', function (event) {
        s.shiftMouseOutEvent(event);
      })
      .on('contextmenu', function (event) {
        s.shiftOnContextMenuEvent({ event, target: d3.select(this) });
      })
      .call(
        d3
          .drag<SVGRectElement, GanttCanvasShift>()
          .on('start', function (event) {
            GanttUtilities.dispatchD3EventToOutside(d3.select(this), event);
            isToleranceOvercome = false;
            draggedElementCoordinates = d3.pointer(event);
            dragStartEvent = event;
          })
          .on('drag', function (event) {
            GanttUtilities.dispatchD3EventToOutside(d3.select(this), event);
            const mouse = d3.pointer(event);
            if (s.getDragDrop() && isToleranceOvercome) {
              // only executed if start dragging
              if (!s.getHasBeenDragged()) {
                s.setHasBeenDragged(true);
                s.shiftDragStartEvent({ event, target: d3.select(this), hasBeenDragged: s.getHasBeenDragged() });
              }
              // executed while dragging
              s.shiftDraggingEvent({ event, target: d3.select(this), hasBeenDragged: s.getHasBeenDragged() });
            } else {
              if (!s.getHasBeenDragged()) {
                s.blockedShiftDragStartEvent({ event, target: d3.select(this), hasBeenDragged: s.getHasBeenDragged() });
              }
              s.blockedShiftDraggingEvent({ event, target: d3.select(this), hasBeenDragged: s.getHasBeenDragged() });
            }

            if (
              Math.abs(draggedElementCoordinates[0] - mouse[0]) > s.ganttConfig.getShiftDragStartTolerance() ||
              Math.abs(draggedElementCoordinates[1] - mouse[1]) > s.ganttConfig.getShiftDragStartTolerance()
            ) {
              isToleranceOvercome = true;
            }
          })
          .on('end', function (event) {
            GanttUtilities.dispatchD3EventToOutside(d3.select(this), event);
            if (s.getDragDrop()) {
              s.shiftDragEndEvent({ event, target: d3.select(this), hasBeenDragged: s.getHasBeenDragged() });

              // Execute onclick function if there was no drag
              if (!s.getHasBeenDragged()) {
                s.shiftOnClickEvent({ event, target: d3.select(this) });
              }
            } else {
              s.blockedShiftDragEndEvent({ event, target: d3.select(this), hasBeenDragged: s.getHasBeenDragged() });
              s.shiftOnClickEvent({ event, target: d3.select(this) });
            }
            s.setHasBeenDragged(false);
            isToleranceOvercome = false;
          })
      );

    s.getAllShifts().exit().remove();

    s.afterShiftRenderEvent(new GanttShiftsAfterRenderEvent(parent.selectAll('rect'), s.getLastZoomTransformation()));
  }

  /**
   * @override
   */
  initCallbacks(executer: any): void {}

  /**
   * @override
   */
  removeCallbacks(executer: any): void {}

  /**
   * @override
   */
  removeAllShifts(executer: any): void {}

  /**
   * @override
   */
  init(executer: any): void {}

  /**
   * @override
   */
  preloadPatterns(executer: any, originDataSet: any): void {}

  /**
   * @override
   */
  resizeChange(executer: any, boolean: any): void {}

  /**
   * @override
   */
  clearTimeouts(): void {}
}
