import { Injectable } from '@angular/core';
import { UiService } from '@app-modeleditor/ui.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ChartLegendQuickfilter } from './chart-legend-quickfilter';

@Injectable({
  providedIn: 'root',
})
export class ChartLegendQuickfilterService {
  constructor(private uiService: UiService) {}

  public getAllQuickFilter(widgetRef: string) {
    const url = `widget/${widgetRef}/customfilter/read/`;
    return this.uiService.getData(url).pipe(
      map((quickFilterData) => {
        const quickFilters: ChartLegendQuickfilter[] = [];
        for (const item of quickFilterData.customFilterList) {
          const newFilter = new ChartLegendQuickfilter()
            .setName(item.name)
            .setDescription(item.description)
            .setHideToMe(item.hideToMe)
            .setId(item.id)
            .setHiddenAttributes(item.hiddenAttributes)
            .setMyFilter(item.myFilter)
            .setOriginalFilter(item.originalFilter)
            .setPosition(item.position)
            .setPublicFilter(item.publicFilter)
            .setReadableClassName(item.readableClassName)
            .setSelected(item.selected)
            .setValue(item.name);
          quickFilters.push(newFilter);
        }
        return quickFilters;
      })
    );
  }

  public creatQuickSearchFilter(quickFilter: ChartLegendQuickfilter, widgetRef: string) {
    const url = `widget/${widgetRef}/customfilter/create/`;
    return this.uiService.postData(url, quickFilter);
  }

  public selectFilter(widgetRef: string, quickFilter: ChartLegendQuickfilter) {
    const url = `widget/${widgetRef}/customfilter/select/${quickFilter.getId()}`;
    return this.uiService.getData(url);
  }

  public noFilterSelection(widgetRef: string): Observable<any> {
    const url = `widget/${widgetRef}/customfilter/deselect/`;
    return this.uiService.getData(url);
  }

  public deleteFilter(widgetRef: string, quickFilter: ChartLegendQuickfilter) {
    const url = `widget/${widgetRef}/customfilter/${quickFilter.getId()}`;
    return this.uiService.deleteData(url);
  }

  public setFilterHideFromMe(widgetRef: string, quickFilter: ChartLegendQuickfilter) {
    const url = `widget/${widgetRef}/customfilter/hidefromme/${quickFilter.getId()}`;
    return this.uiService.getData(url, { hidden: quickFilter.isHideToMe() });
  }

  public setFilterPublic(widgetRef: string, quickFilter: ChartLegendQuickfilter) {
    const url = `widget/${widgetRef}/customfilter/publish/${quickFilter.getId()}`;
    return this.uiService.getData(url, { published: quickFilter.isPublicFilter() });
  }

  public updatePostion(widgetRef: string, quickFilters: ChartLegendQuickfilter[]) {
    const url = `widget/${widgetRef}/customfilter/position/`;
    return this.uiService.postData(url, { customFilterList: quickFilters });
  }
}
