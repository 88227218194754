import { Observable } from 'rxjs';

export abstract class AbstractGridService {
  abstract getWidgetTemplate(experimentId: string, widgetId: string): Observable<any>;
  abstract getResultReport(id: string): Observable<any>;
  public abstract addTab(id: string, body: any): Observable<any>;
  public abstract editTab(resultReportId: string, body: any): Observable<any>;
  public abstract getWidgetData(
    experimentId: string,
    widgetId: string,
    extra?: string,
    stop?: boolean
  ): Observable<any>;
  public abstract postWidgetTemplate(experimentId: string, widgetId: string, body: any): Observable<any>;
  public abstract getResultWidgetDataByRefId(
    experimentId: string,
    widgetRefId: string,
    extra?: string
  ): Observable<any>;
  public abstract deleteView(resultReportId: string, tabId: string): Observable<any>;
  public abstract putFavourite(id: string, favourite: boolean): Observable<any>;
}
