import { Button } from '../button/button';
import { EDatepickerMode } from './datepicker-mode.enum';
import { EDatepickerType } from './datepicker-type.enum';
import { TemplateDatepickerComponent } from './template-datepicker.components';

export class Datepicker extends Button {
  private datepickerType: EDatepickerType = EDatepickerType.DATETIME;
  private dateformat: string;
  private onlyInput = false;
  private simpleInput = false;
  private minDate: Date;
  private maxDate: Date;
  private datepickerMode: EDatepickerMode;
  private ref: TemplateDatepickerComponent;
  private dateTimeFormat: string;

  public getRef(): TemplateDatepickerComponent {
    return this.ref;
  }

  public setRef(ref: TemplateDatepickerComponent): this {
    this.ref = ref;
    return this;
  }

  public getDatepickerType(): EDatepickerType {
    return this.datepickerType;
  }

  public setDatepickerType(datepickerType: EDatepickerType): void {
    this.datepickerType = datepickerType;
  }

  public getMinDate(): Date {
    return this.minDate || null;
  }

  public setMinDate(minDate: Date): this {
    this.minDate = minDate;
    return this;
  }

  public getMaxDate(): Date {
    return this.maxDate || null;
  }

  public setMaxDate(maxDate: Date): this {
    this.maxDate = maxDate;
    return this;
  }

  date: number | string = new Date().getTime();
  time24hr = true;
  public enableDate = true;
  public enableTime = true;
  public selectedValue: Date = new Date();
  selectedValues: { from: Date; to: Date };

  public setDatepickerMode(mode: EDatepickerMode): this {
    this.datepickerMode = mode;
    return this;
  }

  public getDatepickerMode(): EDatepickerMode {
    return this.datepickerMode;
  }

  public setDateTimeFormat(format: string): this {
    this.dateTimeFormat = format;
    return this;
  }

  public getDateTimeFormat(): string {
    return this.dateTimeFormat;
  }
}
