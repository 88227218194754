export enum EButtonDisplayType {
  ICON_ONLY = 'ICON_ONLY',
  LABEL_ONLY = 'LABEL_ONLY',
  ICON_AND_LABEL = 'ICON_AND_LABEL',
  LABEL_AND_ICON = 'LABEL_AND_ICON',
  DYNAMIC = 'DYNAMIC',
  ICON_BEHIND_LABEL = 'ICON_BEHIND_LABEL',
  ICON_WITH_LABEL_BELOW = 'ICON_WITH_LABEL_BELOW',
  SPINNER = 'SPINNER',
  HIDDEN = 'HIDDEN',
}
