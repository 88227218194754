import { Observable, Subject, takeUntil } from 'rxjs';
import { GanttConfig } from '../../config/gantt-config';
import { GanttUtilities } from '../../gantt-utilities/gantt-utilities';
import { BestGantt } from '../../main';
import { BestGanttPlugIn } from '../gantt-plug-in';
import { GanttOverlayBlockRestrictor } from './overlay-block-restrictor';

/**
 * Builds area over gantt between two time points.
 * It's height is as big as the gantt.
 * The area blocks mouse interaction on shift of gantt.
 * @keywords plugin, executer, overlay, block, mark, background, color
 * @plugin overlay
 * @class
 * @constructor
 * @extends BestGanttPlugIn
 *
 * @requires BestGanttPlugIn
 * @requires GanttOverlayBlockRestrictor
 * @requires GanttShiftDragLimiter
 *
 * @deprecated This plug-in is no longer used (2023-11-29).
 */
export class GanttOverlay extends BestGanttPlugIn {
  ganttDiagram: BestGantt;
  canvas: d3.Selection<SVGGElement, undefined, d3.BaseType, undefined>;
  config: GanttConfig;
  overlayData: GanttOverlayPlugInData[];
  blockRestrictor: GanttOverlayBlockRestrictor;
  timeouts: any;

  private _onDestroySubject: Subject<void> = new Subject<void>();

  constructor() {
    super(); //call super-constructor

    /**
     * @type {BestGantt}
     */
    this.ganttDiagram = null;
    this.canvas = null;
    this.config = null;

    this.overlayData = [];

    this.blockRestrictor;
    this.timeouts = {
      afterOriginDataUpate: null,
    };
  }

  /**
   * @override
   */
  initPlugIn(ganttDiagram) {
    const s = this;

    s.ganttDiagram = ganttDiagram;
    s.config = s.ganttDiagram.getConfig();
    s.canvas = s.ganttDiagram
      .getShiftFacade()
      .getShiftBuilder()
      .getCanvasInFrontShifts()
      .append('g')
      .attr('class', 'gantt-overlay-blocker')
      .style('pointer-events', 'none');
    s._zoom();

    s._subscribeCallbacks();

    this.blockRestrictor = new GanttOverlayBlockRestrictor(ganttDiagram);
    this.blockRestrictor.init();
  }

  _updateRestrictions() {
    const s = this;
    clearTimeout(s.timeouts.afterOriginDataUpate);
    s.timeouts.afterOriginDataUpate = setTimeout(() => {
      s.blockRestrictor.removeAllRestrictions();
      s.blockRestrictor.initRestrictions(s.overlayData);
    }, 300);
  }

  clearData() {
    const s = this;
    s.overlayData = [];
    s.blockRestrictor.removeAllRestrictions();
  }

  /**
   * Remove of plugin. Part of plugin lifecycle.
   */
  removePlugIn() {
    const s = this;

    s._unsubscribeCallbacks();
    s._onDestroySubject.next();
    s._onDestroySubject.complete();

    GanttUtilities.clearTimeouts(s.timeouts);
    s.blockRestrictor.removeAllRestrictions();
    //remove groups
    s.canvas.remove();
  }

  _subscribeCallbacks() {
    const s = this;
    s.ganttDiagram.getXAxisBuilder().addToZoomCallback('gantt-overlay-plug-in-zoom' + this.UUID, s._zoom.bind(s));
    s.ganttDiagram
      .getOpenRowHandler()
      .afterShiftRowOpen.pipe(takeUntil(this.onDestroy))
      .subscribe(() => s._updateHeight());
    s.ganttDiagram.subscribeOriginDataUpdate(
      `updateRestrictionsOnChangedDataset ${this.UUID}`,
      s._updateRestrictions.bind(s)
    );
  }

  _unsubscribeCallbacks() {
    const s = this;
    s.ganttDiagram.getXAxisBuilder().removeZoomCallback('gantt-overlay-plug-in-zoom' + this.UUID);
    s.ganttDiagram.unSubscribeOriginDataUpdate(`updateRestrictionsOnChangedDataset ${this.UUID}`);
  }

  /**
   * @param {GanttOverlayPlugInData[]} overlays
   */
  addOverlays(overlays) {
    const s = this;
    s.overlayData.push(...overlays);
    this.blockRestrictor.registerRestrictions(overlays);
    s.buildOverlay();
  }

  /**
   * Saves and builds overlay between given timepoints.
   * @param {Date} timeStart Start timepoint of area.
   * @param {Date} timeEnd End timepoint of area.
   * @param {GanttOverlayRestriciton} [restriction] Restrictions for shift blocks inside overlapping area.
   */
  addOverlay(timeStart, timeEnd, restriction, color, opacity) {
    const s = this;
    const overlay = new GanttOverlayPlugInData(timeStart, timeEnd, restriction, color, opacity);
    s.overlayData.push(overlay);
    this.blockRestrictor.registerRestrictions([overlay]);
    s.buildOverlay();
  }

  /**
   * Adds SVG elements to gantt by saved timepoint data.
   */
  buildOverlay() {
    const s = this;

    const scale = s.ganttDiagram.getXAxisBuilder().getGlobalScale();
    const allOverlays = s.canvas.selectAll('rect').data(s.overlayData);

    // update
    allOverlays
      .attr('width', function (d) {
        return scale(new Date(d.timeEnd)) - scale(new Date(d.timeStart));
      })
      .attr('height', s.getShiftCanvasHeight())
      .attr('x', function (d) {
        return scale(new Date(d.timeStart));
      });

    // enter
    allOverlays
      .enter()
      .append('rect')
      .attr('width', function (d) {
        return scale(new Date(d.timeEnd)) - scale(new Date(d.timeStart));
      })
      .attr('height', s.getShiftCanvasHeight())
      .attr('x', function (d) {
        return scale(new Date(d.timeStart));
      })
      .style('fill', (d) => d.color || 'black')
      .style('opacity', (d) => d.opacity || 0.5);

    // exit
    allOverlays.exit().remove();
  }

  /**
   * Removes all overlay rects.
   */
  removeAll() {
    const s = this;
    if (s.canvas) s.canvas.selectAll('rect').remove();
  }

  /**
   * Callback function to update width of gantt overlays by zoom.
   * @private
   */
  _zoom() {
    const s = this;
    const zoom = s.ganttDiagram.getXAxisBuilder().getLastZoomEvent();
    s.canvas.attr('transform', 'translate(' + zoom.x + ',0) scale(' + zoom.k + ',1)');
  }

  /**
   * Updates height of all overlays.
   * @private
   */
  _updateHeight() {
    const s = this;
    if (!s.canvas) return;
    s.canvas.selectAll('rect').attr('height', s.getShiftCanvasHeight());
  }

  /**
   * Updates width of all overlays.
   * @private
   */
  _updateWidth() {
    const s = this;
    if (!s.canvas) return;
    s.canvas.selectAll('rect').attr('height', s.getShiftCanvasHeight());
  }

  /**
   *
   */
  update() {
    this.buildOverlay();
  }

  //
  // GETTER & SETTER
  //
  /**
   * @return {number} Returns height of gantt shift area.
   */
  getShiftCanvasHeight() {
    return parseInt(this.ganttDiagram.getShiftFacade().getCanvasInFrontShifts().attr('height'));
  }

  /**
   * @return {GanttOverlayBlockRestrictor}
   */
  getBlockRestrictor() {
    return this.blockRestrictor;
  }

  //
  // OBSERVABLES
  //

  /**
   * Observable which gets triggered when the instance gets destroyed.
   */
  private get onDestroy(): Observable<void> {
    return this._onDestroySubject.asObservable();
  }
}

/**
 * Tupel to save time interval.
 * @class
 * @constructor
 * @param {Date} timeStart Start time of interval.
 * @param {Date} timeEnd End time of interval.
 * @param {GanttOverlayRestriciton} [restriction] Gantt edit restrictions.
 */
export class GanttOverlayPlugInData {
  timeStart: Date;
  timeEnd: Date;
  restriction: GanttOverlayRestriciton;
  color: string;
  opacity: number;

  constructor(timeStart, timeEnd, restriction, color, opacity) {
    this.timeStart = new Date(timeStart);
    this.timeEnd = new Date(timeEnd);
    this.restriction = restriction;
    this.color = color;
    this.opacity = opacity;
  }
}

/**
 * @constructor
 * @param {"VERTICAL"|"HORIZONTAL"|"NODRAG"} [dragMode]
 * @param {"LEFT"|"RIGHT"} [resizeMode]
 */
export class GanttOverlayRestriciton {
  dragMode: any;
  resizeMode: string;

  constructor(dragModeCompleteInside, dragModeStartInside, dragModeEndInside, resizeMode) {
    this.dragMode = {
      completeInside: dragModeCompleteInside,
      startInside: dragModeStartInside,
      endInside: dragModeEndInside,
    };
    this.resizeMode = resizeMode;
  }
}
