import { GanttColorConverter } from '../color-converter/color-converter';
import { ToHexColorConverter } from '../color-converter/to-hex-converter';
import { IRGBValues } from '../color-data-types.interface';

/**
 * Class providing static methods for color calculations.
 */
export abstract class GanttColorCalculator {
  /**
   * Determines if a given color is considered dark.
   * @param color - The color to check.
   * @param brightnessThreshold - A threshold value used to tell dark and bright colors apart (optional, default is `100`).
   * @returns True if the color is dark, false otherwise.
   */
  public static isDarkColor(color: string, brightnessThreshold = 100): boolean {
    const hexColor = ToHexColorConverter.convertColorToHex(color);
    const hex = hexColor.replace('#', '');
    const c_r = parseInt(hex.substring(0, 0 + 2), 16);
    const c_g = parseInt(hex.substring(2, 2 + 2), 16);
    const c_b = parseInt(hex.substring(4, 4 + 2), 16);
    const brightness = (c_r * 299 + c_g * 587 + c_b * 114) / 1000;
    return brightness < brightnessThreshold;
  }

  /**
   * Calculates contrast value between two colors.
   * Thanks [stackoverflow](https://stackoverflow.com/questions/9733288/how-to-programmatically-calculate-the-contrast-ratio-between-two-colors).
   * @param color1 1st color to use for contrast calculation (should be in the following format: #rrggbb).
   * @param color2 2nd color to use for contrast calculation (should be in the following format: #rrggbb).
   * @param contrastCache If specified, this {@link Map} will be used as cache for color contrasts.
   * @returns Contrast value between two colors.
   */
  public static getColorContrastHex(
    color1: string,
    color2: string,
    contrastCache: Map<string, number> = undefined
  ): number {
    if (!color1 || !color2) return 4;

    if (contrastCache?.has(color1 + color2)) {
      return contrastCache.get(color1 + color2);
    }

    const value = GanttColorCalculator.getColorContrastRgb(
      GanttColorConverter.hexToRgb(color1),
      GanttColorConverter.hexToRgb(color2)
    );
    contrastCache?.set(color1 + color2, value);
    return value;
  }

  /**
   * Calculates contrast value between two colors.
   * Thanks [stackoverflow](https://stackoverflow.com/questions/9733288/how-to-programmatically-calculate-the-contrast-ratio-between-two-colors).
   * @param color1 1st color to use for contrast calculation (should be in the following format: {@link IRGBValues}).
   * @param color2 2nd color to use for contrast calculation (should be in the following format: {@link IRGBValues}).
   * @returns Contrast value between two colors.
   */
  public static getColorContrastRgb(color1: IRGBValues, color2: IRGBValues): number {
    const lum1 = GanttColorCalculator.getColorLuminanceRgb(color1);
    const lum2 = GanttColorCalculator.getColorLuminanceRgb(color2);
    const brightest = Math.max(lum1, lum2);
    const darkest = Math.min(lum1, lum2);
    return (brightest + 0.05) / (darkest + 0.05);
  }

  /**
   * Calculates luminance value of the specified color.
   * Thanks [stackoverflow](https://stackoverflow.com/questions/9733288/how-to-programmatically-calculate-the-contrast-ratio-between-two-colors).
   * @param color Color to calculate the luminance value for (should be in the following format: {@link IRGBValues}).
   * @returns Luminance value of the specified color.
   */
  public static getColorLuminanceRgb(color: IRGBValues): number {
    const a = [color.r, color.g, color.b].map((v) => {
      v /= 255;
      return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
    });
    return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
  }
}
