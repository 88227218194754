import { Resource } from 'frontend/src/dashboard/model/resource/resource';
import { MessageBar } from '../message-bar/message-bar.interface';
import { SaxMsSpreadsheetRow } from './table-row';

export class TableValue extends Resource {
  private rowCount: number;
  private scrolledIndex: number;
  private tableRows: SaxMsSpreadsheetRow[] = [];
  private messageBars: MessageBar[] = [];

  constructor() {
    super();
    this.setRowCount(0).setScrolledIndex(0).setTableRows([]);
  }

  public getRowCount(): number {
    return isNaN(this.rowCount) ? this.getTableRows().length : this.rowCount;
  }

  public setRowCount(rowCount: number): this {
    this.rowCount = rowCount;
    return this;
  }

  public getScrolledIndex(): number {
    return this.scrolledIndex || 0;
  }

  public setScrolledIndex(scrolledIndex: number): this {
    this.scrolledIndex = scrolledIndex;
    return this;
  }

  public getTableRows(): SaxMsSpreadsheetRow[] {
    return this.tableRows || [];
  }

  public setTableRows(tableRows: SaxMsSpreadsheetRow[]): this {
    this.tableRows = tableRows;
    return this;
  }

  public getMessageBars(): MessageBar[] {
    return this.messageBars || [];
  }

  public setMessageBars(messageBars: MessageBar[]): this {
    this.messageBars = messageBars;
    return this;
  }
}
