import { Component, Input } from '@angular/core';

@Component({
  selector: 'nn-legend',
  templateUrl: './nn-legend.component.html',
  styleUrls: ['./nn-legend.scss'],
})

/**
 * Outer wrapper for all node network legends.
 */
export class NodeNetworkLegendContainer {
  @Input() headline: string;
  public showLegend = true;

  /**
   * Show/hide legend.
   */
  public toggleLegend(): void {
    this.showLegend = !this.showLegend;
  }
}
