import { EntryElement } from '@app-modeleditor/components/entry-collection/entry-element';
import { EFieldType } from '@app-modeleditor/components/entry-collection/field-type.enum';
import { EGanttSubMenuUpdateMessageType } from '@app-modules/saxms-submenu-elements/saxms-submenu.enum';
import {
  IGanttSubMenuSyncMessage,
  IGanttSubMenuUpdateMessage,
} from '@app-modules/saxms-submenu-elements/saxms-submenu.interface';
import { GlobalUtils } from 'frontend/src/dashboard/global-utils';
import { takeUntil } from 'rxjs/operators';
import { Gantt_General } from '../../general.gantt.component';
import { GanttEssentialPlugIns } from '../../plugin/e-gantt-essential-plugins';
import { GanttSuperBlockActivationAction } from '../../plugin/plugin-list/superblocks/superblocks.plugin';
import { EntryElementValue } from './../../../../modeleditor/components/entry-collection/entry-element-value';

export class SuperBlockViewElement extends EntryElement {
  constructor(private scope: Gantt_General) {
    super();
  }
  private _internalId: string = GlobalUtils.generateUUID();
  private availableValues: EntryElementValue[] = [];

  get(data: any): this {
    this.availableValues = this.scope.ganttTemplateDataService
      .getTemplateData()
      .getHierarchicalPlan()
      .superBlockViews.map((superBlockView) =>
        new EntryElementValue().setValue(superBlockView.type).setName(superBlockView.name)
      );

    const defaultValue = this.getSelectionValue(
      this.scope.ganttResultData.response.hierarchicalPlan.defaultSuperBlockView.type
    );

    this.setFieldType(EFieldType.COMBO_BOX)
      .setId(data.fieldIdentifier)
      .setAlwaysEnabled(true)
      .onChanges((val: EntryElementValue) => {
        this.handleOnSelect(val.getValue<EntryElementValue>().getValue(), true);
        const value: IGanttSubMenuSyncMessage = {
          internalId: this._internalId,
          value: val.getValue<EntryElementValue>().getValue(),
        };
        const message: IGanttSubMenuUpdateMessage = {
          elementId: this.getFieldIdentifier(),
          type: EGanttSubMenuUpdateMessageType.SYNC,
          value: value,
        };

        this.scope.submenuService.triggerElementById(message); // trigger sync
      })
      .setName(data.name)
      .setFloatingLabel(data.name)
      .setValue(new EntryElementValue().setAvailableValues(this.availableValues).setValue(defaultValue));

    // execute default value
    this.handleOnSelect(defaultValue.getValue());
    this._listenToActivationChanges();

    return this;
  }

  private handleOnSelect(viewType: number, save = false): void {
    if (viewType === undefined || viewType === null) {
      return;
    }

    const localAction: GanttSuperBlockActivationAction = {
      pluginId: GanttEssentialPlugIns.SuperBlocksPlugIn,
      activateType: viewType,
    };

    this.scope.ganttPluginHandlerService.executePlugInAction(localAction);

    if (save) {
      this.scope.ganttSettingsService.changeSettings({ activeSuperBlockType: viewType });
      this.scope.ganttSettingsService.saveSettings().subscribe();
    }
  }

  private getSelectionValue(viewType: number): EntryElementValue {
    const name = this.availableValues.find((value) => value.getValue() === viewType)?.getName(); // get name from availableValues. Because name in defaultSuperBlockView and availableValues is not the same
    return new EntryElementValue().setValue(viewType).setName(name);
  }

  private isViewTypeValid(viewType: number): boolean {
    return !!this.scope.ganttTemplateDataService
      .getTemplateData()
      .getHierarchicalPlan()
      ?.superBlockViews.find((view) => view.type === viewType);
  }

  private _listenToActivationChanges() {
    this.scope.submenuService
      .onUpdateElementState()
      .pipe(takeUntil(this.scope.onDestroy))
      .subscribe((activationData: IGanttSubMenuUpdateMessage) => {
        if (!activationData || activationData.elementId !== this.getId()) {
          return;
        }
        switch (activationData.type) {
          case EGanttSubMenuUpdateMessageType.ACTION:
            break;
          case EGanttSubMenuUpdateMessageType.UPDATE:
            if (this.isViewTypeValid(activationData.value)) {
              this.handleOnSelect(activationData.value);
              this.getValue<EntryElementValue>().setValue(this.getSelectionValue(activationData.value));
            }
            break;
          case EGanttSubMenuUpdateMessageType.SYNC:
            if (
              activationData.value.internalId === this._internalId &&
              this.isViewTypeValid(activationData.value.value)
            ) {
              this.getValue<EntryElementValue>().setValue(this.getSelectionValue(activationData.value.value));
              return;
            } // element notifies itself
            break;
        }
      });
  }
}
