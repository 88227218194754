import { TranslateModule } from '@ngx-translate/core';
import { ExperimentTestDialog } from './toolbar-dialog/toolbar-dialog.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../../../core/material/material.module';

@NgModule({
  imports: [CommonModule, MaterialModule, TranslateModule],
  declarations: [ExperimentTestDialog],
  exports: [ExperimentTestDialog],
})
export class ToolbarDialogsModule {}
