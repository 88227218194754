import { EGanttInstance, GanttDataShift } from '@gantt/public-api';
import { GanttTemplateData } from 'frontend/src/dashboard/gantt/helper/gantt';
import { takeUntil, takeWhile } from 'rxjs/operators';
import { GanttActionWrapper } from '../../external-action-registration';
import { GanttEventTrigger } from '../../external-event-trigger';
import { EGanttActionEvent } from '../gantt-action-event.enum';

/**
 * Event which will be fired on single click at a shift block.
 */
export class GanttSingleClickEvent extends GanttEventTrigger {
  public readonly eventName: EGanttActionEvent = EGanttActionEvent.ON_CLICK;

  /**
   * Init function, usefull to register this trigger inside gantt or plugin executer via callback.
   * @param ganttDiagram BestGantt instance.
   */
  public registerInGantt(): void {
    const ganttDiagram = this._ganttLibService.bestGantt;
    ganttDiagram
      .getShiftFacade()
      .shiftOnClick()
      .pipe(takeWhile(() => this._ganttLibService.isAlive))
      .subscribe(() => {
        this.handleClick();
      });

    ganttDiagram
      .getSelectionBoxFacade()
      .afterSelection.pipe(takeUntil(this.onRemoveFromGantt))
      .subscribe(() => {
        if (this._ganttLibService.bestGantt.getSelectionBoxFacade().getSelectedShifts().length) {
          this.handleClick();
        }
      });
  }

  private handleClick(): void {
    const ganttDiagram = this._ganttLibService.bestGantt;
    const actionWrapper: GanttActionWrapper = {
      actionScope: this.action.actionScope,
      actions: this.action.actions,
    };
    this._ganttLibService.ngZone.run(() => {
      this.saveTemplateData(ganttDiagram.getSelectionBoxFacade().getSelectedShifts(), this.templateData);
      this.actionHandler.executeActions(actionWrapper).subscribe((data) => {});
    });
  }

  /**
   * Removes Event trigger.
   * Cut all dependencies (callback and services).
   * @param ganttDiagram BestGantt instance.
   */
  public removeFromGantt(): void {
    super.removeFromGantt();
  }

  private saveTemplateData(selectedCanvasShifts: any[], templateData: GanttTemplateData): void {
    templateData.setSelectedValues(false, this._ganttLibService.backendToGanttOriginInputMapper);

    if (selectedCanvasShifts.length) {
      // add selected block id
      templateData.setSelectedBlock(
        {
          id: selectedCanvasShifts[0].id,
        },
        this._ganttLibService.backendToGanttOriginInputMapper
      );
      // ? templateData.selectedBlock.id = selectedCanvasShifts[0].id;

      // add all shifts
      const selectedShifts: GanttDataShift[] = [];

      for (const canvasShift of selectedCanvasShifts) {
        const foundShift = this._ganttLibService.ganttInstanceService
          .getInstance(EGanttInstance.SHIFT_DATA_FINDER)
          .getShiftById(
            this._ganttLibService.bestGantt.getDataHandler().getOriginDataset().ganttEntries,
            canvasShift.id
          );
        if (!foundShift?.shift) continue;
        selectedShifts.push(foundShift.shift);
      }
      // ? templateData.selectedValues = selectedShifts;
      templateData.setSelectedValues(selectedShifts, this._ganttLibService.backendToGanttOriginInputMapper);
    } else {
      templateData.setSelectedBlock({}, this._ganttLibService.backendToGanttOriginInputMapper);
      templateData.setSelectedValues(false, this._ganttLibService.backendToGanttOriginInputMapper);
    }
  }
}
