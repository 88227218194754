import { Component, OnInit, InjectionToken, Inject } from '@angular/core';
import { ChartDataGroup, ChartLabels } from '../generalChart/chart.mapper';

export const CHART_TOOLTIP_ITEMS = new InjectionToken<ChartDataGroup[]>('CHART_TOOLTIP_ITEMS');
export const CHART_TOOLTIP_LABEL = new InjectionToken<ChartLabels>('CHART_TOOLTIP_LABEL');
export const CHART_TOOLTIP_LABELINDEX = new InjectionToken<number>('CHART_TOOLTIP_LABELINDEX');

@Component({
  selector: 'app-tooltip-popup',
  templateUrl: './tooltip-popup.component.html',
  styleUrls: ['./tooltip-popup.component.scss'],
})
export class TooltipPopupComponent implements OnInit {
  constructor(
    @Inject(CHART_TOOLTIP_ITEMS) public chartDataGroups: ChartDataGroup[] = [],
    @Inject(CHART_TOOLTIP_LABEL) public label: ChartLabels = null,
    @Inject(CHART_TOOLTIP_LABELINDEX) public labelIndex: number = 0
  ) {}

  ngOnInit() {}

  getStackedValue() {
    let value = 0;
    this.chartDataGroups.forEach((element) => {
      value += element.data[this.labelIndex];
    });

    return value;
  }
}
