import { Component, Input, OnInit } from '@angular/core';
import { ISaxmsSidebarPanel } from '@app-modules/saxms-sidebar/saxms-sidebar.component';
import { Sidebar } from './sidebar';

@Component({
  selector: 'template-sidebar',
  templateUrl: './template-sidebar.component.html',
  styleUrls: ['./template-sidebar.component.scss'],
})
export class TemplateSidebarComponent implements OnInit {
  @Input() template: Sidebar;
  sidebar: ISaxmsSidebarPanel[] = [];
  title: string;

  constructor() {}

  ngOnInit(): void {
    this.sidebar = this.template
      .getIndexRegister()
      .getIndexPages()
      .map((panel) => {
        const _p = {
          id: panel.getUuid(),
          originalId: panel.getId(),
          title: panel.getName(),
          icon: panel.getIcon(),
        };
        this.title = panel.getName();
        return _p;
      });
  }
}
