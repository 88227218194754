import { Injectable } from '@angular/core';
import { GanttHierarchicalPlanData } from 'frontend/src/dashboard/gantt/general/generator/gantt-input.data';
import { GanttTemplateData } from 'frontend/src/dashboard/gantt/helper/gantt';
import { Observable, Subject } from 'rxjs';

/**
 * Stores gantt template data.
 */
@Injectable()
export class GanttTemplateDataService {
  private _ganttTemplateData: GanttTemplateData = null;
  private _onHierarchicalPlanUpdateSubject: Subject<GanttHierarchicalPlanData> = new Subject();

  constructor() {}

  public setTemplateData(templateData: GanttTemplateData): void {
    this._ganttTemplateData = templateData;
    if (templateData) {
      this.triggerHierarchicalPlanUpdateNotification();
    }
  }

  public getTemplateData(): GanttTemplateData {
    return this._ganttTemplateData;
  }

  public listenToHierarchicalPlanUpdates(): Observable<GanttHierarchicalPlanData> {
    return this._onHierarchicalPlanUpdateSubject.asObservable();
  }

  public triggerHierarchicalPlanUpdateNotification(): void {
    this._onHierarchicalPlanUpdateSubject.next(this._ganttTemplateData.getHierarchicalPlan());
  }
}
