import { Action } from '@app-modeleditor/components/button/action/action';
import { Button } from '@app-modeleditor/components/button/button';
import { EButtonDisplayType } from '@app-modeleditor/components/button/button-display-type.enum';
import { Content } from '@app-modeleditor/components/content/content';
import { EMenuMode } from '@app-modeleditor/components/content/content-element/menu-mode.enum';
import { ContentPart } from '@app-modeleditor/components/content/content-part/content-part';
import { EEntryElementPosition } from '@app-modeleditor/components/entry-collection/entry-element-position.enum';
import { EFieldType } from '@app-modeleditor/components/entry-collection/field-type.enum';
import { Lightbox } from '@app-modeleditor/components/lightbox/lightbox';
import { EDefaultTableButton } from '@app-modeleditor/components/spreadsheet/model/default-table-button.enum';
import { Table } from '@app-modeleditor/components/spreadsheet/model/table';
import { TableHeader } from '@app-modeleditor/components/spreadsheet/model/table-header';
import { SaxMsSpreadsheetRow } from '@app-modeleditor/components/spreadsheet/model/table-row';
import { ETableSelectionMode } from '@app-modeleditor/components/spreadsheet/model/table-selection-mode.enum';
import { TableValue } from '@app-modeleditor/components/spreadsheet/model/table-values';
import { Gantt_General } from 'frontend/src/dashboard/gantt/general/general.gantt.component';
import { GlobalUtils } from 'frontend/src/dashboard/global-utils';
import { EResizeMode } from 'frontend/src/dashboard/model/resource/template-resize-mode.enum';
import { ToolbarGroup } from 'frontend/src/dashboard/view/template-toolbar/toolbar-group';
import { of } from 'rxjs';
import { EDefaultSubmenues } from './../../../../../modeleditor/components/spreadsheet/model/default-submenues.enum';
import { ISaxMsSpreadsheetRowEntry } from './../../../../../modeleditor/components/spreadsheet/model/table-row-entry';
import { EGanttBlockAttributeDataType } from './attribute-data-types.enum';
import { IGanttBlockAttributeData } from './attribute-data.interface';
import { EditFilterLightbox } from './edit-filter-lightbox';
import { FilterNodeToStringConverter } from './filter-node-to-string-converter';
import { FrontendFilter } from './frontend-filter';

export class FilterManagementLightbox extends Lightbox {
  private table: Table;

  constructor(private scope: Gantt_General) {
    super();
    this.setName(this.scope.translate.instant('GANTT.FILTER.manage'))
      .setWidth(90)
      .setAdditionalButtons(this.getAdditionalActions())
      .setOnCustomSaveAction(
        new Button()
          .setPosition(EEntryElementPosition.RIGHT)
          .setDisplayType(EButtonDisplayType.LABEL_ONLY)
          .setName(this.scope.translate.instant('save'))
          .chainActions(new Action().setCb(() => of(this.onSave())))
      );

    this.scope.ganttFrontendFilterService.onFilterChanged().subscribe((filters: FrontendFilter[]) => {
      this.setContent(this.getFilterContent());
      const mapping = this.scope.ganttTemplateDataService.getTemplateData().getAttributeMapping();
      const attributeData: IGanttBlockAttributeData[] = Object.keys(mapping).map((index: string) => {
        return {
          name: mapping[index].localization,
          id: mapping[index].id,
          type: mapping[index].dataType || EGanttBlockAttributeDataType.STRING,
        };
      });

      this.table.setValues(
        new TableValue().setTableRows(
          (this.scope.ganttFrontendFilterService.filterList || []).map((f: FrontendFilter) => {
            const isRowEditable = this.isFilterEditable(f);
            return new SaxMsSpreadsheetRow()
              .setCanonicalName('canonical')
              .setResourceId(f.getId())
              .setId(f.getId())
              .setSpreadsheetRowEntries({
                0: this.getCell(0, f.getValue(), 'description', isRowEditable),
                1: this.getCell(
                  1,
                  new FilterNodeToStringConverter().convertNodeToString(f.getNode(), attributeData),
                  'preview',
                  isRowEditable
                ),
                2: this.getCell(2, f.isQuickfilter(), 'quickfilter', isRowEditable),
                3: this.getCell(3, f.isPublicFilter(), 'public', isRowEditable),
                4: this.getCell(4, f.isFixed(), 'fixed', isRowEditable),
              });
          })
        )
      );
    });
  }

  /**
   * Determines whether a filter is editable or not.
   * A filter is considered editable if it is not fixed or if the change of fixed filters is allowed.
   * @param filter - The filter to check.
   * @returns True if the filter is editable, false otherwise.
   */
  private isFilterEditable(filter: FrontendFilter): boolean {
    return !filter.isFixed() || this.scope.ganttFrontendFilterService.isChangeFixedFiltersAllowed;
  }

  private onSave(): void {
    const filterList = this.scope.ganttFrontendFilterService.filterList.map((item) =>
      this.scope.ganttFrontendFilterService.inherit(FrontendFilter, item)
    ); // make a copy
    (filterList || []).forEach((f: FrontendFilter) => {
      const matchingRow: SaxMsSpreadsheetRow = this.table
        .getValues()
        .getTableRows()
        .find((row: SaxMsSpreadsheetRow) => row.getId() === f.getId());
      f.setQuickfilter(matchingRow.getSpreadsheetRowEntries()[2].value);
      f.setPublicFilter(matchingRow.getSpreadsheetRowEntries()[3].value);
      f.setFixed(matchingRow.getSpreadsheetRowEntries()[4].value);
      f.setValue(matchingRow.getSpreadsheetRowEntries()[0].value);
    });
    this.scope.ganttFrontendFilterService.saveAndSetNewFilterList(filterList);
  }

  private getFilterContent(): Content {
    this.table = new Table().setSaveSettings(false).setFontSizeAutoScale(false).setColumnReorder(false);
    this.table.setTableHeaders([
      this.createTableHeader(
        'description',
        this.scope.translate.instant('GANTT.FILTER.description'),
        EFieldType.TEXT_FIELD,
        200,
        true
      ),
      this.createTableHeader(
        'preview',
        this.scope.translate.instant('GANTT.FILTER.preview'),
        EFieldType.TEXT_FIELD,
        700,
        false
      ),
      this.createTableHeader(
        'quickfilter',
        this.scope.translate.instant('GANTT.FILTER.quickfilter'),
        EFieldType.CHECK_BOX,
        100,
        true
      ),
      this.createTableHeader(
        'public',
        this.scope.translate.instant('GANTT.FILTER.public'),
        EFieldType.CHECK_BOX,
        100,
        true
      ),
      this.createTableHeader(
        'fixed',
        this.scope.translate.instant('GANTT.FILTER.fixed'),
        EFieldType.CHECK_BOX,
        100,
        this.scope.ganttFrontendFilterService.isChangeFixedFiltersAllowed
      ),
    ]);

    this.table
      .setDisabledSubMenues([EDefaultSubmenues.COLUMN, EDefaultSubmenues.QUICKSEARCH])
      .setDisableDefaultTableMenuButtons([
        EDefaultTableButton.CREATE,
        EDefaultTableButton.EDIT,
        EDefaultTableButton.DELETE,
        EDefaultTableButton.DETAILS,
      ])
      .setAdditionalSubMenuGroups({
        ROW: [
          new ToolbarGroup().setName(this.scope.translate.instant('GANTT.FILTER.general')).setEntryElements([
            new Button()
              .setName(this.scope.translate.instant('GANTT.FILTER.create'))
              .setIcon('add')
              .setAlwaysEnabled(true)
              .setDisplayType(EButtonDisplayType.ICON_WITH_LABEL_BELOW)
              .chainActions(new Action().setCb(() => of(this.createFilter()))),
            new Button()
              .setName(this.scope.translate.instant('GANTT.FILTER.edit'))
              .setIcon('edit')
              .setId('test')
              .setEnableBy(() => {
                const f: FrontendFilter = (this.scope.ganttFrontendFilterService.filterList || []).find(
                  (item: FrontendFilter) => item.getId() === this.table.getSelectedValue().resourceId
                );

                return (
                  this.table.getSelectionModel() &&
                  this.table.getSelectionModel().isRowActionsEnabled() === true &&
                  this.isFilterEditable(f)
                );
              })
              .setDisplayType(EButtonDisplayType.ICON_WITH_LABEL_BELOW)
              .chainActions(
                new Action().setCb(() => {
                  const f: FrontendFilter = (this.scope.ganttFrontendFilterService.filterList || []).find(
                    (item: FrontendFilter) => item.getId() === this.table.getSelectedValue().resourceId
                  );

                  return of(this.createFilter(f));
                })
              ),
            new Button()
              .setName(this.scope.translate.instant('GANTT.FILTER.delete'))
              .setIcon('delete')
              .setId('deleteFilter')
              .setEnableBy(() => {
                const f: FrontendFilter = (this.scope.ganttFrontendFilterService.filterList || []).find(
                  (item: FrontendFilter) => item.getId() === this.table.getSelectedValue().resourceId
                );

                return (
                  this.table.getSelectionModel() &&
                  this.table.getSelectionModel().isRowActionsEnabled() === true &&
                  this.isFilterEditable(f)
                );
              })
              .setDisplayType(EButtonDisplayType.ICON_WITH_LABEL_BELOW)
              .chainActions(
                new Action().setCb(() => {
                  const f: FrontendFilter = (this.scope.ganttFrontendFilterService.filterList || []).find(
                    (item: FrontendFilter) => item.getId() === this.table.getSelectedValue().resourceId
                  );
                  this.scope.ganttFrontendFilterService.removeFilter(f);
                  return of();
                })
              ),
          ]),
        ],
      })
      // .setEditable(true)
      .setMenuMode(EMenuMode.SHOW)
      .setCellEdit(true)
      .setDefaultFilters([])
      .setId('filter-table')
      .setUuid(GlobalUtils.generateUUID())
      .setEditable(true)
      .setHiddenHeaders([])
      .setFieldIdentifier('filterTable')
      .setHierarchical(false)
      .setNumberFixedColumns(0)
      .setResizeMode(EResizeMode.OWN_SIZE)
      .setSearchable(true)
      .setSelectable(ETableSelectionMode.MULTI)
      .setTableColumnCount(this.table.getTableHeaders().length)
      .setName(this.scope.translate.instant('GANTT.FILTER.gantt'));

    return new Content().setContentParts([
      new ContentPart().setDisplayContentpartContainer(false).setContentElements([this.table]),
    ]);
  }

  private getAdditionalActions(): Button[] {
    const buttons: Button[] = [];
    return buttons;
  }

  private createTableHeader(
    id: string,
    name: string,
    type: EFieldType,
    columnWidth: number,
    isEditable: boolean
  ): TableHeader {
    const header: TableHeader = new TableHeader()
      .setFieldIdentifier(id)
      .setSortable(true)
      .setValue(name)
      .setCanonicalName(id)
      .setEditable(isEditable)
      .setId(id)
      .setSearchable(true)
      .setFieldType(type)
      .setPreferredWidth(columnWidth)
      .setColumnName(id);
    switch (type) {
      case EFieldType.TEXT_FIELD:
        (header as any).datatype = 'string';
        break;
      case EFieldType.CHECK_BOX:
        (header as any).datatype = 'boolean';
        break;
    }
    return header;
  }

  getCell(idx: number, value: any, col: string, isRowEditable: boolean): ISaxMsSpreadsheetRowEntry {
    return {
      value: value,
      index: idx,
      id: col,
      uuid: GlobalUtils.generateUUID(),
      uniqueId: `${col}.${GlobalUtils.generateUUID()}`,
      tableHeaderId: col,
      tooltip: value,
      rowEditable: isRowEditable,
    };
  }

  createFilter(f?: FrontendFilter): void {
    const l: EditFilterLightbox = new EditFilterLightbox(this.scope);
    if (f) {
      l.loadFilter(f);
    } else {
      l.getFilterConditionsString();
    }
    this.scope.lightboxApi.open(l);
  }
}
