import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SaxMsSubmenuService } from '@app-modules/saxms-submenu-elements/saxms-submenu.service';
import { HotspotRegionSidebarModule } from './hotspot-sidebars/hotspot-sidebars.module';
import { HotspotWrapperComponent } from './hotspot-wrapper.component';
import { HotspotModule } from './hotspot/hotspot.module';
@NgModule({
  declarations: [HotspotWrapperComponent],
  exports: [HotspotWrapperComponent],
  imports: [CommonModule, HotspotModule, HotspotRegionSidebarModule],
  providers: [SaxMsSubmenuService],
})
export class HotspotWrapperModule {}
