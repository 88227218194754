import { EntryElement } from '@app-modeleditor/components/entry-collection/entry-element';
import { EntryElementValue } from '@app-modeleditor/components/entry-collection/entry-element-value';
import { EFieldType } from '@app-modeleditor/components/entry-collection/field-type.enum';
import { EGanttSubMenuUpdateMessageType } from '@app-modules/saxms-submenu-elements/saxms-submenu.enum';
import {
  IGanttSubMenuSyncMessage,
  IGanttSubMenuUpdateMessage,
} from '@app-modules/saxms-submenu-elements/saxms-submenu.interface';
import { GlobalUtils } from 'frontend/src/dashboard/global-utils';
import { takeUntil } from 'rxjs/operators';
import { Gantt_General } from '../../general.gantt.component';
import { GanttEssentialPlugIns } from '../../plugin/e-gantt-essential-plugins';
import {
  BlockingIntervalBackendType,
  GanttBlockingIntervalsPlugIn,
} from '../../plugin/plugin-list/blocking-intervals/blocking-intervals.plugin';
import { IGanttTimePeriodTemplateData } from '../../plugin/plugin-list/blocking-intervals/gantt-template-data-attribute-mapping.interface';

export class FilterBlockingIntervalsElement extends EntryElement {
  private _internalId: string = GlobalUtils.generateUUID();
  private readonly _indicatorIdentifier = 'FilterBlockingIntervalsElement_IndicatorIdentifier';
  private _blockingIntervalsPlugIn: GanttBlockingIntervalsPlugIn;
  private _selectAllEntriesByDefault: boolean;

  constructor(private scope: Gantt_General) {
    super();
    this._blockingIntervalsPlugIn = this.scope.ganttPluginHandlerService.getEssentialPlugIn(
      GanttEssentialPlugIns.BlockingIntervalPlugIn
    );
    this._selectAllEntriesByDefault = true;
  }

  public get(data: any): this {
    this.setName(data.name)
      .onChanges((val: EntryElementValue) => {
        this._updateFilter(val.getValue(), data);

        const value: IGanttSubMenuSyncMessage = { internalId: this._internalId, value: val.getValue() };
        const message: IGanttSubMenuUpdateMessage = {
          elementId: this.getId(),
          type: EGanttSubMenuUpdateMessageType.SYNC,
          value: value,
        };
        this.scope.submenuService.triggerElementById(message);
      })
      .setId(data.id)
      .setFieldType(EFieldType.COMBO_BOX_MULTIPLE_SELECT)
      .setAlwaysEnabled(true)
      .setValue(
        new EntryElementValue()
          .setValue(this._selectAllEntriesByDefault ? this._getAvailableValues(data) : null)
          .setAvailableValues(this._getAvailableValues(data))
      );

    this._subscribeToElementStateUpdates();

    return this;
  }

  /**
   * Updates the current filter to show only the specified entries.
   * @param values Blocking interval entries to show.
   */
  private _updateFilter(values: EntryElementValue[], data: any): void {
    const selectedIntervalIds: string[] = values.map((val) => val.getValue());
    const renderValues = {};
    const pluginData = (
      this.scope.ganttTemplateDataService.getTemplateData().getPlugins() as IGanttTimePeriodTemplateData[]
    ).find((pluginData) => pluginData.id === BlockingIntervalBackendType).ganttTimePeriodGroupIntervalInputs;
    const valuesList = data.availableValues?.length
      ? data.availableValues.map((val) => pluginData.find((plugin) => plugin.id === val).clazz)
      : this._blockingIntervalsPlugIn.getAllIntervalIds();

    valuesList.forEach((id) => {
      renderValues[id] = selectedIntervalIds.includes(id);
    });

    this._blockingIntervalsPlugIn.renderByIds(renderValues);

    const indicatorVisible =
      selectedIntervalIds.length < this.getValue<EntryElementValue>().getAvailableValues<EntryElementValue[]>().length;
    this.scope.toolbar.updateMenuIndicator(indicatorVisible, this.getId(), this._indicatorIdentifier);
  }

  /**
   * @returns {EntryElementValue} Array containing all blocking interval types as entries.
   */
  private _getAvailableValues(data: any): EntryElementValue[] {
    const availableValues: EntryElementValue[] = [];
    if (!this._blockingIntervalsPlugIn) return availableValues;

    const blockingIntervalPlugInData = (
      this.scope.ganttTemplateDataService.getTemplateData().getPlugins() as IGanttTimePeriodTemplateData[]
    ).find((pluginData) => pluginData.id === BlockingIntervalBackendType);

    const existingValues: string[] = data.availableValues || this._blockingIntervalsPlugIn.getAllIntervalIds();
    const filteredList = data.availableValues?.length
      ? blockingIntervalPlugInData.ganttTimePeriodGroupIntervalInputs.filter((interval) =>
          existingValues.includes(interval.id)
        )
      : blockingIntervalPlugInData.ganttTimePeriodGroupIntervalInputs;

    for (const intervalInput of filteredList) {
      availableValues.push(
        new EntryElementValue().setValue(intervalInput.clazz).setName(intervalInput.label || intervalInput.id)
      );
    }

    return availableValues;
  }

  /**
   * Subscribes to value changes of elements with the same id.
   */
  private _subscribeToElementStateUpdates(): void {
    this.scope.submenuService
      .onUpdateElementState()
      .pipe(takeUntil(this.scope.onDestroy))
      .subscribe((activationData: IGanttSubMenuUpdateMessage) => {
        if (!activationData || activationData.elementId !== this.getId()) return;
        switch (activationData.type) {
          case EGanttSubMenuUpdateMessageType.ACTION:
            break;
          case EGanttSubMenuUpdateMessageType.UPDATE:
            break;
          case EGanttSubMenuUpdateMessageType.SYNC:
            if (activationData.value.internalId === this._internalId) return; // prevent element from synchronizing itself
            this._syncElement(activationData.value.value);
            break;
        }
      });
  }

  /**
   * Synchronizes elements of sub menu with each other.
   * @param selectedValue Value from another element to synchronize with.
   */
  private _syncElement(selectedValue: EntryElementValue) {
    this.getValue<EntryElementValue>().setValue(selectedValue);
  }
}
