<div [class]="'progressbar-wrapper progressbar-wrapper-' + uuidString" [matTooltip]="template.getValue().hint">
  <mat-label [class]="'progressbar-hint'">
    {{ template.getValue().hint ? template.getValue().hint : ('LABEL.processing' | translate) + ' ...' }}
  </mat-label>
  <div class="meter" [class.complete]="progressValue >= 1">
    <span
      [ngStyle]="{
        width: (progressValue * 100 | number : '1.0-1') + '%',
        'background-color': progressBarColor
      }"
    >
    </span>
  </div>
</div>
