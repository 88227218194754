import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import { EFieldType } from '@app-modeleditor/components/entry-collection/field-type.enum';
import { DateUtils } from '@app-modeleditor/utils/date-utils';
import { CONTAINER_DATA } from '../picker';

@Component({
  selector: 'app-template-overlay',
  templateUrl: './overlay.component.html',
  styleUrls: ['./overlay.component.scss'],
  providers: [],
})
export class OverlayComponent implements OnInit, OnDestroy {
  months: number[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
  monthLabels: string[] = [
    'Januar',
    'Febraur',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember',
  ];
  weeks: IWeek[];
  private alive = true;
  selectedValues: IResult = {
    selectedYear: null,
    selectedMonth: null,
    week: {
      number: null,
      start: null,
      end: null,
    },
  };
  @Output() changeEvent: EventEmitter<IResult> = new EventEmitter<IResult>();
  type: EFieldType;

  constructor(
    // private tPickerService: TemplatePickerService,
    @Inject(CONTAINER_DATA) public componentData: { year: number; month: number; week: number; type: EFieldType }
  ) {}

  ngOnDestroy(): void {
    this.alive = false;
  }

  ngOnInit(): void {
    this.init();
  }

  get isShowMonth(): boolean {
    return this.months &&
      (this.type === EFieldType.CALENDAR_MONTH_PICKER || this.type === EFieldType.CALENDAR_WEEK_PICKER)
      ? true
      : false;
  }

  get isShowWeeks(): boolean {
    return this.type === EFieldType.CALENDAR_WEEK_PICKER ? true : false;
  }

  init(): void {
    // set type
    this.type = this.componentData.type;

    // get years
    this.selectedValues.selectedYear = this.componentData.year || new Date().getFullYear();

    // months
    this.selectedValues.selectedMonth = this.componentData.month || new Date().getMonth();

    this.selectedValues.week.number = this.componentData.week || null;

    this.buildCalendar();

    switch (this.type) {
      case EFieldType.CALENDAR_YEAR_PICKER:
        if (!this.componentData.year) {
          this.changeEvent.emit(this.selectedValues);
        }
        break;
      case EFieldType.CALENDAR_MONTH_PICKER:
        if (!this.componentData.month) {
          this.changeEvent.emit(this.selectedValues);
        }
        break;
    }
  }

  onMonthChanged(event: MatSelect): void {
    this.selectedValues.selectedMonth = parseInt(event.value);
    this.buildCalendar();
    if (this.type === EFieldType.CALENDAR_MONTH_PICKER) {
      this.changeEvent.emit(this.selectedValues);
    }
  }

  onYearChanged(event: Event): void {
    this.selectedValues.selectedYear = parseInt(event.target['value']);
    this.buildCalendar();
    switch (this.type) {
      case EFieldType.CALENDAR_YEAR_PICKER:
      case EFieldType.CALENDAR_MONTH_PICKER:
        this.changeEvent.emit(this.selectedValues);
        break;
      default:
        break;
    }
  }

  onWeekClicked(week: IWeek): void {
    const skipEmit: boolean = this.selectedValues.week.start?.getTime() === week.start?.getTime();
    this.selectedValues.week = week;
    if (skipEmit) {
      return;
    }
    this.changeEvent.emit(this.selectedValues);
  }

  buildCalendar(): void {
    const date = new Date(this.selectedValues.selectedYear, this.selectedValues.selectedMonth);

    this.weeks = [];
    for (let i = 1; i <= this.daysInMonth(date); i++) {
      date.setMonth(this.selectedValues.selectedMonth, i);
      const weekNum: number = this.getWeek(date);

      if (!this.weeks.find((week) => week.number === weekNum)) {
        const start: Date = DateUtils.getFirstDay(new Date(date));
        const end: Date = new Date(start);
        end.setDate(end.getDate() + 6);

        const w: IWeek = { number: weekNum, start: new Date(start), end: new Date(end) };
        this.weeks.push(w);

        if (this.selectedValues.week.number === w.number) {
          this.onWeekClicked(w);
        }
      }
    }
  }

  daysInMonth(date: Date): number {
    const d: Date = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    return d.getDate();
  }

  getWeek(_date: Date): number {
    const date = new Date(_date.getTime());
    date.setHours(0, 0, 0, 0);
    // Thursday in current week decides the year.
    date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
    // January 4 is always in week 1.
    const week1 = new Date(date.getFullYear(), 0, 4);
    // Adjust to Thursday in week 1 and count number of weeks from date to week1.
    return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + ((week1.getDay() + 6) % 7)) / 7);
  }
}

export interface CalendarYearPickerConfig {
  firstYear: number;
  lastYear: number;
}

export interface IWeek {
  number: number;
  start: Date;
  end: Date;
}

export interface IResult {
  selectedYear?: number;
  selectedMonth?: number;
  week?: IWeek;
}
