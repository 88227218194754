<mat-select *ngIf="options.availableValues" [(ngModel)]="value" placeholder="value">
  <mat-option *ngFor="let option of options.availableValues.value" [value]="option">
    {{ option }}
  </mat-option>
</mat-select>
<div class="form-group row" *ngIf="!options.availableValues">
  <!--<label for="id" class="col-2 col-form-label">{{label}}</label>-->
  <div class="col-10">
    <div class="input-group" *ngIf="!options.lowerSettingsValue && !options.upperSettingsValue">
      <!--<span class="input-group-addon" id="basic-addon1">{{unit}}</span>
            <input #inputControl aria-describedby="basic-addon1" (keypress)="validate($event.target.value)" [step]="step " [min]="min " [max]="max " class="form-control" id="id" [type]="type " [(ngModel)]="value">
            -->
      <mat-form-field class="standard-input">
        <input
          matInput
          #inputControl
          (keypress)="validate($event.target.value)"
          [step]="step"
          [min]="min"
          [max]="max"
          [id]="id"
          [type]="type"
          [(ngModel)]="value"
          matInput
          [placeholder]="label"
        />
        <mat-hint align="start"
          ><strong>{{ unit }}</strong>
        </mat-hint>
      </mat-form-field>
    </div>
    <div class="input-group" *ngIf="options.lowerSettingsValue && options.upperSettingsValue">
      <!--<span class="input-group-addon" id="basic-addon1">{{unit}}</span>
            <input class="form-control" aria-describedby="basic-addon1" [type]="lowerType" [(ngModel)]="options.lowerSettingsValue.value">
            <span class="input-group-addon" id="basic-addon2">{{unit}}</span>
            <input class="form-control" aria-describedby="basic-addon2" [type]="upperType" [(ngModel)]="options.upperSettingsValue.value">
            -->

      <mat-form-field class="standard-input-double">
        <input matInput [type]="lowerType" [(ngModel)]="options.lowerSettingsValue.value" />
        <mat-hint align="start"
          ><strong>{{ label }}</strong>
        </mat-hint>
      </mat-form-field>
      <mat-form-field class="standard-input-double">
        <input matInput [type]="upperType" [(ngModel)]="options.upperSettingsValue.value" />
        <mat-hint align="start"
          ><strong>{{ label }}</strong>
        </mat-hint>
      </mat-form-field>
    </div>
  </div>
</div>
