import { ConfigService } from '@core/config/config.service';
import { GanttLibService } from 'frontend/src/dashboard/gantt/gantt/gantt-lib.service';
import { GanttPluginHandlerService } from 'frontend/src/dashboard/gantt/general/plugin/gantt-plugin-handler.service';
import { Logger } from 'frontend/src/dashboard/shared/utils/logger';
import { GanttTemplateData } from '../../../helper/gantt';
import { GeneralGanttActionHandler } from '../../action-handling/action-handler';
import { GanttActionService } from '../../gantt-action.service';
import { IGanttTemplateValue } from '../gantt-input.data';

export class GanttPredefinedSetting {
  constructor(
    protected ganttPluginHandlerService: GanttPluginHandlerService,
    protected ganttLibService: GanttLibService,
    protected actionHandler: GeneralGanttActionHandler,
    protected configService: ConfigService,
    protected readonly logger: Logger
  ) {}

  public onInit(
    templateData: GanttTemplateData,
    ganttResponseData: IGanttTemplateValue,
    contextMenuService?: GanttActionService
  ): void {}
}
