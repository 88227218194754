<ng-container *ngIf="nt">
  <div #upperScrollArea [ngClass]="{ 'outer-navigation-area': isContentPartNavigationEnabled() }">
    <ng-container *ngIf="isContentPartNavigationEnabled()">
      <div class="navigation-area">
        <span
          [saxmsTooltip]="name"
          class="nav-name"
          *ngIf="nt.getName() || (nt.title && nt.getTitle()) || (nt.headline && nt.getHeadline())"
        >
          {{ name }}</span
        >
        <ng-container *ngFor="let part of parts; let i = index">
          <!-- <mat-icon class="nav-arrow" *ngIf="i!==0" mat-button>keyboard_arrow_right</mat-icon> -->
          <button
            mat-button
            class="nav-link default"
            [class.is-fully-selected]="currentScrolledCp.indexOf(part.getId()) !== -1"
            (click)="scrollIntoView(part)"
          >
            {{ part.getName() }}
          </button>
        </ng-container>
        <button
          *ngIf="model?.toolbarModifier"
          [saxmsTooltip]="showModifier ? 'Werkzeugleiste ausblenden' : 'Werkezugleiste einblenden'"
          [class.is-active]="showModifier"
          (click)="showModifier = !showModifier"
          class="modifier-action nav-link headline nav-link-right"
          mat-icon-button
        >
          <mat-icon>{{ showModifier ? 'expand_less' : 'expand_more' }}</mat-icon>
        </button>
        <button
          [saxmsTooltip]="'close' | translate"
          (click)="onClose()"
          class="nav-link headline"
          [class.nav-link-right]="!model?.toolbarModifier"
          *ngIf="onCloseCb"
          mat-icon-button
        >
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <div class="foo" *ngIf="showModifier && model?.toolbarModifier">
        <app-toolbar-row [resourceId]="resourceId || model.resourceId" [modifier]="model?.toolbarModifier">
        </app-toolbar-row>
      </div>
    </ng-container>
    <app-editmode-bar *ngIf="hasEditBarMode" [menuItem]="nt"></app-editmode-bar>
  </div>
  <div class="lower-scroll-area" #lowerScrollArea>
    <div
      class="template-ui-wrapper"
      #templateUiWrapperRef
      [style.height]="
        nt?.getContent()?.getId() === 'template.content.overviewpage'
          ? 'inherit'
          : nt?.getContent()?.isCompact()
          ? '100% !important'
          : 'foo'
      "
    >
      <app-content
        *ngIf="nt?.getContent()"
        [content]="nt.getContent()"
        [root]="root || lowerScrollArea"
        [resourceId]="resourceId || model.resourceId"
      >
      </app-content>
    </div>

    <template-sidebar
      [style.top]="upperScrollAreaHeight + 'px'"
      [style.height]="'calc(100% - ' + upperScrollAreaHeight + 'px)'"
      [style.right]="scrollbarWidth + 'px'"
      [template]="nt?.getSideBar()"
      *ngIf="isSidebarEnabled"
    >
    </template-sidebar>
  </div>
</ng-container>
