import * as d3 from 'd3';
import { Observable, Subject, takeUntil } from 'rxjs';
import { ShiftDataFinder } from '../../data-handler/data-finder/shift-data-finder';
import { YAxisDataFinder } from '../../data-handler/data-finder/yaxis-data-finder';
import { GanttCanvasShift } from '../../data-handler/data-structure/data-structure';
import { GanttUtilities } from '../../gantt-utilities/gantt-utilities';
import { GanttScrollContainerEvent } from '../../html-structure/scroll-container-event';
import { EGanttScrollContainer } from '../../html-structure/scroll-container.enum';
import { IShiftDragEvent } from '../../shifts/shift-events.interface';
import { BestGanttPlugIn } from '../gantt-plug-in';
import { GanttEdgeBuilder } from './edge-builder';
import { GanttEdgeEditor } from './edge-editor';
import { GanttEdgeLabeler } from './edge-labeler';
import { GanttEdgeMouseConnect } from './edge-mouse-connect';
import { GanttEdgeRemover } from './edge-remover';
import { GanttEdgeEvent } from './undo-redo/edge-event';

/**
 * Plugin which draws arrows into gantt.
 * @keywords plugin, edge, executer, builder, handler, shift, connect, connection, arrow, line
 * @plugin edges
 * @class
 * @constructor
 * @augments {BestGanttPlugIn}
 *
 * @requires GanttEdgeBuilder
 * @requires GanttEdgeLabeler
 * @requires GanttEdgeMouseConnect
 * @requires GanttEdgeRemover
 */
export class GanttEdgeHandler extends BestGanttPlugIn {
  builder: GanttEdgeBuilder;
  editor: GanttEdgeEditor;
  editorMode: boolean;
  currentMouseOverShiftId: string;
  connectedObjects: GanttEdgeHandlerConnectedShifts[];
  draggedShift: any;
  whileDragging: boolean;
  zoomTransformation: any;
  edgeConfig: {
    renderEdges: boolean;
    renderArrowsOnEdges: boolean;
  };
  canvas: d3.Selection<SVGGElement, undefined, d3.BaseType, undefined>;
  canvasShift: d3.Selection<SVGSVGElement, undefined, d3.BaseType, undefined>;
  mouseConnect: GanttEdgeMouseConnect;
  remover: GanttEdgeRemover;
  labeler: GanttEdgeLabeler;

  private _onDestroySubject: Subject<void> = new Subject<void>();

  constructor() {
    super(); // call super-constructor

    this.builder = null;
    this.editor = null;

    this.editorMode = false;
    this.currentMouseOverShiftId = null;

    this.connectedObjects = [];

    this.draggedShift = null;
    this.whileDragging = false;

    this.zoomTransformation = { k: 1, x: 0, y: 0 };

    this.edgeConfig = {
      renderEdges: true,
      renderArrowsOnEdges: true,
    };
  }

  /**
   * @override
   */
  initPlugIn(ganttDiagram) {
    const s = this;

    s.ganttDiagram = ganttDiagram;

    s.canvasShift = s.ganttDiagram // get the shiftCanvas
      .getShiftFacade()
      .getShiftWrapperOverlay()
      .select('.gantt_shifts-wrapper-overlay-svg');

    s.canvas = s.canvasShift.insert('g', '.gantt_shift-group-overlay').attr('class', 'edge-canvas');

    s.builder = new GanttEdgeBuilder(s.canvas.node(), s);
    s.mouseConnect = new GanttEdgeMouseConnect(s.ganttDiagram, s.canvas.node());
    s.remover = new GanttEdgeRemover(s.ganttDiagram, s.canvasShift);
    s.labeler = new GanttEdgeLabeler(s.canvas.node(), s);
    s.editor = new GanttEdgeEditor(s.canvas.node(), ganttDiagram);
    s.builder.init();
    s.labeler.init();
    s.editor.init();

    // init zoom
    s._zoomEdges({ transform: s.ganttDiagram.getXAxisBuilder().getLastZoomEvent() });

    // callback registration
    s.ganttDiagram.getXAxisBuilder().addToZoomCallback('edgeZooming' + this.UUID, s._zoomEdges.bind(s));
    s.ganttDiagram
      .getRenderDataHandler()
      .onFilterRenderDataSetShifts.pipe(takeUntil(this.onDestroy))
      .subscribe(() => s.rebuildEdges(s.getConnectedObjectsDataSet()));
    s.ganttDiagram.getXAxisBuilder().addToZoomCallback('removerUpdate' + this.UUID, s._removerAtScroll.bind(s));
    s.ganttDiagram
      .getShiftFacade()
      .shiftDragging()
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => s.rebuildEdges(s.getConnectedObjectsDataSet()));
    s.mouseConnect.createOnClickCallback('createEdgeByIds', s.createEdgeByIds.bind(s));
    s.remover.addOnCutConnectionCallback('removeEdgeById', s.removeEdgeByEdgeId.bind(s));
    s.editor.addOnEdgeEditCallback('_distributeComponents', s._distributeComponents.bind(s));
    s.editor.addOnEdgeEditCallback('buildEdges', s.builder.buildEdges.bind(s.builder));
    s.editor.addOnEdgeEditCallback('buildLabels', s.labeler.build.bind(s.labeler, s.labeler.getLabels()));
    s.editor.addOnEdgeEditDragEndCallback('rebuildEdges', s.rebuildEdges.bind(s, s.getConnectedObjectsDataSet()));
    s.editor.addOnEdgeEditCallback('removeEdgeByEdgeId', s.removeEdgeByEdgeId.bind(s));
    s.editor.addOnEdgeEditCallback('getEdgeDataByEdgeId', s.getEdgeDataByEdgeId.bind(s));
    s.editor.addOnEdgeEditCallback('createEdgeByIds', s.createEdgeByIds.bind(s));
    s.ganttDiagram
      .getShiftFacade()
      .shiftDragging()
      .pipe(takeUntil(this.onDestroy))
      .subscribe((event) => s._getDraggedShift(event));
    s.ganttDiagram
      .getShiftFacade()
      .shiftDragEnd()
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => s._onDragEnd());
    s.ganttDiagram
      .getShiftFacade()
      .shiftDragStart()
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => s._onDragStart());
    s.ganttDiagram
      .getShiftFacade()
      .shiftMouseOver()
      .pipe(takeUntil(this.onDestroy))
      .subscribe((event) => s._onMouseOver(event));
    s.ganttDiagram
      .getShiftFacade()
      .shiftMouseOut()
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => s._onMouseOut());
    this.ganttDiagram
      .getShiftResizer()
      .onShiftEditUpdate()
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => this.rebuildEdges([]));

    s.ganttDiagram.getConfig().subscribePluginSettingsChanges(`updateEdgePlugin ${s.UUID}`, (ganttPluginSettings) => {
      const globalRenderStrategy = ganttPluginSettings.edgePluginRendering;

      switch (globalRenderStrategy) {
        case 'ALWAYS':
        case 'ONMOUSEOVER':
        case 'ONMOUSEOVERTRANSITIV':
          s.setShowEdges(true);
          break;
        case 'NONE':
          s.setShowEdges(false);
          break;
      }
      s.rebuildEdges(s.getConnectedObjectsDataSet());
    });
  }

  /**
   * Maps visualization mode of gantt config to visualization mode of edge plugin.
   * @returns visualization mode of edge plugin
   */
  private getGlobalVisualizationMode(): VisualizationMode {
    const ganttConfigSettings = this.ganttDiagram.getConfig().ganttPluginSettings.edgePluginRendering;
    switch (ganttConfigSettings) {
      case 'ALWAYS':
        return VisualizationMode.ALWAYS_VISIBLE;
      case 'ONMOUSEOVER':
        return VisualizationMode.DIRECT_CONNECTIONS;
      case 'ONMOUSEOVERTRANSITIV':
        return VisualizationMode.TRANSITIVE_CONNECTIONS;
      default:
        return VisualizationMode.NONE;
    }
  }

  /**
   * @override
   */
  update() {
    const s = this;
    s.rebuildEdges(s.getConnectedObjectsDataSet());
  }

  /**
   * Remove of plugin. Part of plugin lifecycle.
   */
  public removePlugIn(): void {
    this.ganttDiagram.getPlugInHandler().unSubscribeToPlugIns(`connectToStickyParentRowsFromEdgeExecuter ${this.UUID}`);

    // callback unsubscribe
    this.ganttDiagram.getXAxisBuilder().removeZoomCallback('edgeZooming' + this.UUID);
    this.ganttDiagram.getXAxisBuilder().removeZoomCallback('removerUpdate' + this.UUID);
    this.mouseConnect.removeOnClickCallback('createEdgeByIds' + this.UUID);
    this.remover.removeOnCutConnectionCallback('removeEdgeByIds' + this.UUID);
    this.ganttDiagram.getConfig().unSubscribePluginSettingsChanges(`updateEdgePlugin ${this.UUID}`);

    this._onDestroySubject.next();
    this._onDestroySubject.complete();

    // remove helper plugins
    this.builder.remove();
    this.remover.removeAll();
    this.labeler.remove();

    // remove groups
    this.canvas.remove();
  }

  /**
   * Function wich handles start of the editor mode.
   */
  startEditorMode() {
    const s = this;

    this.editorMode = true;
    s.editor.buildHandles();
  }

  /**
   * Function wich handles end of the editor mode.
   */
  endEditorMode() {
    const s = this;

    this.editorMode = false;
    s.editor.removeHandles();
  }

  /**
   * Will be called if gantt row will be opened or closed.
   * @param {GanttCanvasRow} clickedElementData Canvas data of clicked row.
   */
  animation(clickedElementData) {
    const s = this;
    // FIXME: references OpenRowHandler.callFunctionWhileAnimationInPlugIns Turn this into a normal callback!
    let y1Change = 0,
      y2Change = 0;
    const clickedRowId = clickedElementData.id;
    const childRows = YAxisDataFinder.getRowById(
      s.ganttDiagram.getDataHandler().getOriginDataset().ganttEntries,
      clickedRowId
    ).data;
    let height = 0;
    childRows.child.forEach(
      (row) => (height += s.ganttDiagram.getDataHandler().getRowHeightStorage().getRowHeightById(row.id))
    ); // y-change

    if (!clickedElementData.open) {
      height = height * -1;
    }

    for (let i = 0; i < s.connectedObjects.length; i++) {
      const shiftId1 = s.connectedObjects[i].id1;
      const shiftId2 = s.connectedObjects[i].id2;

      const shift1Change = s._willPositionOfShiftChange(shiftId1, clickedRowId);
      if (shift1Change) y1Change = height;

      const shift2Change = s._willPositionOfShiftChange(shiftId2, clickedRowId);
      if (shift2Change) y2Change = height;

      if (shift1Change || shift2Change) {
        // if one or both shift are moved
        const polyLine = s._getPolyLine(shiftId1, shiftId2);

        if (
          Object.keys(ShiftDataFinder.getShiftById([childRows], shiftId1)).length > 0 || // check if shifts are visible in row
          Object.keys(ShiftDataFinder.getShiftById([childRows], shiftId2)).length > 0
        ) {
          if (polyLine) {
            if (
              clickedRowId ==
                ShiftDataFinder.getShiftById(s.ganttDiagram.getDataHandler().getOriginDataset().ganttEntries, shiftId1)
                  .shiftRow.id ||
              clickedRowId ==
                ShiftDataFinder.getShiftById(s.ganttDiagram.getDataHandler().getOriginDataset().ganttEntries, shiftId2)
                  .shiftRow.id
            ) {
              // if shift is in parent-row
              s.builder.animateEdge(polyLine, y1Change, y2Change);
            } else {
              // if one or both shifts are unvisible
              polyLine.remove();
            }
          }
        } else {
          s.builder.animateEdge(polyLine, y1Change, y2Change);
        }
      }
    }
  }

  /**
   * create edges by shift-id or milestone-id
   * @param {string} id1 shift-id or milestone-id
   * @param {string} id2 shift-id or milestone-id
   * @param {string} [edgeColor] color of edge
   * @param {string} [labelStart] label on arrow beginning
   * @param {string} [labelEnd] label on arrow end
   * @param {number} [typeStart] Arrow head type on arrow beginning.
   * @param {number} [typeEnd] Arrow head type on arrow end.
   * @param {number} [lineStyle] Arrow line style.
   * @param {number} [visualizationMode] 0 = always visible, 1 = only visible in edit mode or if mouse over source / target block of this connection, 2 = only visible in edit mode or if mouse over any block that is indirectly connected to this connection
   */
  createEdgeByIds(
    id1,
    id2,
    edgeColor,
    labelStart,
    labelEnd,
    typeStart,
    typeEnd = 1,
    lineStyle,
    visualizationMode?: VisualizationMode,
    canvasShiftDataMap?: Map<string, GanttCanvasShift>
  ) {
    const s = this;

    const connectedObject: GanttEdgeHandlerConnectedShifts = {
      id1: id1,
      id2: id2,
      edgeId: id1 + id2,
      color: edgeColor,
      labelStart: labelStart,
      labelEnd: labelEnd,
      visible: false,
      typeStart: typeStart,
      typeEnd: typeEnd,
      lineStyle: lineStyle,
      visualizationMode: visualizationMode,
    };

    // save edge-attributes
    s.connectedObjects.push(connectedObject);

    // distribute coordinates
    s._organizeEdge(
      connectedObject,
      this.findTransitiveEdgeConnections(this.connectedObjects, this.currentMouseOverShiftId),
      canvasShiftDataMap || this.ganttDiagram.getDataHandler().getCanvasShiftDataMap()
    );
  }

  /**
   * Remove edge by given shift id.
   * @param {string} id1 First shift id.
   * @param {string} id2 Second shift id.
   */
  removeEdgeByIds(id1, id2) {
    const s = this;
    s.removeEdgeByEdgeId(id1 + id2);
  }

  /**
   * Remove edge by given shift id.
   * @param {string} id Id of connected object.
   */
  removeEdgeByObjectId(id) {
    const s = this;
    const edgeData = s.getEdgeDataByObjectId(id);
    if (edgeData.length) {
      for (const edge of edgeData) {
        s.removeEdgeByEdgeId(edge.edgeId);
      }
    }
  }

  /**
   * Removes edge data andDOM element by id.
   * @param {string} edgeId Id of edge.
   */
  removeEdgeByEdgeId(edgeId) {
    const s = this;
    const event = s.ganttDiagram.getHistory().addNewEvent('removeEdgeByEdgeId', new GanttEdgeEvent(), this);
    s._removeEdgesFromConnectedObjectsListByEdgeId(edgeId, event);
    s.labeler.removeLabelByEdgeId(edgeId, null);
    s.builder.removeEdgeByEdgeID(edgeId);

    s.rebuildEdges(s.getConnectedObjectsDataSet());
  }

  /**
   * Removes all existing edges.
   */
  removeAllEdges() {
    const s = this;
    s.connectedObjects = [];
    s.labeler.removeAllLabels();
    s.builder.removeAllEdges();

    s.rebuildEdges(s.getConnectedObjectsDataSet());
  }

  /**
   * Removes edge by edge id from edge dataset.
   * @private
   * @param {string} edgeId
   * @param {ComponentEvent} event undo redo event that needs data from here
   */
  _removeEdgesFromConnectedObjectsListByEdgeId(edgeId, event) {
    const s = this;
    for (let i = 0; i < s.connectedObjects.length; i++) {
      if (s.connectedObjects[i].edgeId == edgeId) {
        if (event) event.addAnotherArgument(JSON.parse(JSON.stringify(s.connectedObjects[i])));
        s.connectedObjects.splice(i, 1);
      }
    }
  }

  /**
   * Organizes the build-process of an edge.
   * @param connectedObject
   */
  private _organizeEdge(
    connectedObject: GanttEdgeHandlerConnectedShifts,
    transitiveConnectionIdsForHoveredShift: string[] = [],
    canvasShitDataMap: Map<string, GanttCanvasShift>
  ): void {
    const id1 = connectedObject.id1,
      id2 = connectedObject.id2,
      color = connectedObject.color,
      labelStart = connectedObject.labelStart,
      labelEnd = connectedObject.labelEnd,
      typeStart = connectedObject.typeStart,
      typeEnd = connectedObject.typeEnd,
      lineStyle = connectedObject.lineStyle,
      visualizationMode = connectedObject.visualizationMode ?? this.getGlobalVisualizationMode(), // if no visualization mode is set, use global visualization mode so object specific visualization is stronger than global
      edgeId = id1 + id2;

    let diffShift1X = 0,
      diffShift2X = 0,
      diffShift1Y = 0,
      diffShift2Y = 0;

    // get object-data
    let objectData1: GanttCanvasShift | GanttEdgeHandlerDraggedShift = canvasShitDataMap.get(id1);
    let objectData2: GanttCanvasShift | GanttEdgeHandlerDraggedShift = canvasShitDataMap.get(id2);

    // check if the new edge is currently visible
    const areObjectsVisible = this._isEdgeVisible(objectData1, objectData2);

    if (this.draggedShift != null) {
      const chainElements = this.ganttDiagram.getShiftTranslationChain().getChainedElements();
      if (id1 == this.draggedShift[0].id) {
        for (const key in chainElements) {
          // if dragged shift has a chained shift, the coordinates adjusts
          if (key == id1) {
            diffShift2X = this.draggedShift[0].x - objectData1.x;
            diffShift2Y = this.draggedShift[0].y - this._getShiftViewportY(objectData1);
          }
        }
        objectData1 = this.draggedShift[0];
      }
      if (id2 == this.draggedShift[0].id) {
        for (const key in chainElements) {
          // if dragged shift has a chained shift, the coordinates adjusts
          if (key == id1) {
            diffShift1X = this.draggedShift[0].x - objectData2.x;
            diffShift1Y = this.draggedShift[0].y - this._getShiftViewportY(objectData2);
          }
        }
        objectData2 = this.draggedShift[0];
      }
    }

    if (
      !areObjectsVisible ||
      (areObjectsVisible &&
        !this.checkVisibilityByVisualizationMode(visualizationMode, id1, id2, transitiveConnectionIdsForHoveredShift))
    ) {
      connectedObject.visible = false;
      this.builder.setVisibilityInEdgeDatasetByEdgeId(edgeId, false);
      this.labeler.setVisibilityInEdgeLabelsDatasetByEdgeId(edgeId, false);
      return;
    } else {
      connectedObject.visible = true;
    }

    // calculate coordinates
    const object1X = objectData1.x + objectData1.width + diffShift1X;
    let object1Y = this._getShiftViewportY(objectData1) + objectData1.height / 2 + diffShift1Y;
    const object2X = objectData2.x + diffShift2X;
    let object2Y = this._getShiftViewportY(objectData2) + objectData1.height / 2 + diffShift2Y;

    // get clip path url for edge if necessary
    const manipulationData = { y1: object1Y, y2: object2Y };
    const clipPathUrl = this._getEdgeClipPathUrl(object1Y, object2Y, objectData1, objectData2, manipulationData);
    object1Y = manipulationData.y1;
    object2Y = manipulationData.y2;

    // distribute components
    this._distributeComponents(
      id1,
      id2,
      edgeId,
      object1X,
      object1Y,
      object2X,
      object2Y,
      color,
      labelStart,
      labelEnd,
      true,
      typeStart,
      typeEnd,
      lineStyle,
      clipPathUrl
    );
  }

  private _getEdgeClipPathUrl(
    y1: number,
    y2: number,
    o1: GanttCanvasShift | GanttEdgeHandlerDraggedShift,
    o2: GanttCanvasShift | GanttEdgeHandlerDraggedShift,
    manipulationData: { y1: number; y2: number }
  ): string {
    if (!this.ganttDiagram.getConfig().showStickyRows()) return undefined;

    const sy1 = this.ganttDiagram.getRenderDataHandler().getYAxisDataFinder().getScrollContainerByViewportY(y1);
    const sy2 = this.ganttDiagram.getRenderDataHandler().getYAxisDataFinder().getScrollContainerByViewportY(y2);
    const soy1 = this.ganttDiagram.getRenderDataHandler().getStateStorage().getShiftScrollContainer(o1.id);
    const soy2 = this.ganttDiagram.getRenderDataHandler().getStateStorage().getShiftScrollContainer(o2.id);

    let clipPathUrl: string = undefined;
    if (soy1 !== soy2 && (soy1 === EGanttScrollContainer.STICKY_ROWS || soy2 === EGanttScrollContainer.STICKY_ROWS)) {
      let isSetClipPathUrl = false;
      let isManipulateY1 = false;
      let isManipulateY2 = false;

      if (soy1 !== EGanttScrollContainer.STICKY_ROWS && sy1 === EGanttScrollContainer.STICKY_ROWS) {
        isManipulateY1 = true;
        isSetClipPathUrl = true;
      } else if (soy2 !== EGanttScrollContainer.STICKY_ROWS && sy2 === EGanttScrollContainer.STICKY_ROWS) {
        isManipulateY2 = true;
        isSetClipPathUrl = true;
      }
      if (soy2 === EGanttScrollContainer.STICKY_ROWS && sy1 === undefined) {
        isManipulateY1 = true;
        isSetClipPathUrl = true;
      } else if (soy1 === EGanttScrollContainer.STICKY_ROWS && sy2 === undefined) {
        isManipulateY2 = true;
        isSetClipPathUrl = true;
      }
      if (soy1 === EGanttScrollContainer.STICKY_ROWS && sy1 === EGanttScrollContainer.DEFAULT) {
        isSetClipPathUrl = true;
      } else if (soy2 === EGanttScrollContainer.STICKY_ROWS && sy2 === EGanttScrollContainer.DEFAULT) {
        isSetClipPathUrl = true;
      }

      if (isSetClipPathUrl) {
        clipPathUrl = this.ganttDiagram
          .getShiftFacade()
          .getShiftBuilder(EGanttScrollContainer.STICKY_ROWS)
          .getClipPathHandler().clipPathUrl;
      }
      if (isManipulateY1) {
        manipulationData.y1 =
          this.ganttDiagram.getNodeProportionsState().getShiftViewPortProportions(EGanttScrollContainer.STICKY_ROWS)
            .height + 20;
      }
      if (isManipulateY2) {
        manipulationData.y2 =
          this.ganttDiagram.getNodeProportionsState().getShiftViewPortProportions(EGanttScrollContainer.STICKY_ROWS)
            .height + 20;
      }
    } else if ((soy1 || EGanttScrollContainer.DEFAULT) === (soy2 || EGanttScrollContainer.DEFAULT)) {
      clipPathUrl = this.ganttDiagram.getShiftFacade().getShiftBuilder(soy1).getClipPathHandler().clipPathUrl;
    }
    return clipPathUrl;
  }

  private _getShiftViewportY(shift: GanttCanvasShift | GanttEdgeHandlerDraggedShift): number {
    // if shift data contains a dragged shift -> shift y is already vieport y
    if (shift instanceof GanttEdgeHandlerDraggedShift) {
      return shift.y;
    }
    // else -> calculate viewport y
    let viewportY = this.ganttDiagram.getRenderDataHandler().getShiftDataFinder().getShiftViewportY(shift.id);
    if (isNaN(viewportY)) {
      const scrollTop = this.ganttDiagram.getNodeProportionsState().getScrollTopPosition();
      viewportY = shift.y - scrollTop;
    }
    return viewportY;
  }

  /**
   * Checks if edge is visible by visualization mode.
   * @param {number} visualizationMode  0 = always visible, 1 = only visible in edit mode or if mouse over source / target block of this connection, 2 = only visible in edit mode or if mouse over any block that is indirectly connected to this connection
   * @param {string} object1Id Id of connected object.
   * @param {string} object2Id Id of connected object.
   * @returns {boolean} visible (true)
   */
  private checkVisibilityByVisualizationMode(
    visualizationMode: VisualizationMode,
    object1Id: string,
    object2Id: string,
    transitiveConnectionIDs: string[]
  ) {
    const s = this;
    switch (visualizationMode) {
      case VisualizationMode.DIRECT_CONNECTIONS:
        if (s.currentMouseOverShiftId == object1Id || s.currentMouseOverShiftId == object2Id) {
          return true;
        } else {
          return false;
        }
      case VisualizationMode.TRANSITIVE_CONNECTIONS:
        return transitiveConnectionIDs.includes(object1Id + object2Id);
      case VisualizationMode.ALWAYS_VISIBLE:
        return true;
      default:
        return false;
    }
  }

  /**
   * Handles the distribution of the necessary data to the individual components.
   * @param edgeId ID of the edge
   * @param object1X Start x coordinate of an edge
   * @param object1Y Start y coordinate of an edge
   * @param object2X End x coordinate of an edge
   * @param object2Y End y coordinate of an edge
   * @param color Color of an edge
   * @param labelStart Start label of an edge
   * @param labelEnd End label of an edge
   * @param visibility Is edge visible
   * @param typeStart Arrow head type on arrow beginning.
   * @param typeEnd Arrow head type on arrow end.
   * @param lineStyle Arrow line style.
   * @param clipPathUrl
   */
  private _distributeComponents(
    id1,
    id2,
    edgeId,
    object1X,
    object1Y,
    object2X,
    object2Y,
    color,
    labelStart,
    labelEnd,
    visibility,
    typeStart,
    typeEnd,
    lineStyle,
    clipPathUrl: string
  ): void {
    this.builder.addEdge(
      id1,
      id2,
      edgeId,
      object1X,
      object1Y,
      object2X,
      object2Y,
      color,
      visibility,
      typeStart,
      typeEnd,
      lineStyle,
      clipPathUrl
    );

    this.labeler.addStartLabel(id1, id2, labelStart, object1X, object1Y, edgeId, visibility, clipPathUrl);

    this.labeler.addEndLabel(id1, id2, labelEnd, object2X, object2Y, edgeId, visibility, clipPathUrl);
  }

  /**
   * Handles edges and lables while zooming.
   * @private
   * @param zoomEvent zoom event
   */
  _zoomEdges(zoomEvent) {
    const s = this;
    s.zoomTransformation = zoomEvent.transform;
    s.labeler.setZoomTransformation(zoomEvent.transform);
    s.builder.setZoomTransformation(zoomEvent.transform);
    s.editor.setZoomTransformation(zoomEvent.transform);
    s.builder.buildEdges(s.getEdgeBuilder().getEdgeData());
    s.labeler.build(s.labeler.getLabels());

    if (s.editorMode) {
      s.editor.buildHandles();
    }
  }

  /**
   * Handles the rebuild of all edges.
   */
  public rebuildEdges(dataset: GanttEdgeHandlerConnectedShifts[]): void {
    const transitiveConnectionIdsForHoveredShift = this.findTransitiveEdgeConnections(
      this.connectedObjects,
      this.currentMouseOverShiftId
    );

    if (dataset.length) {
      const canvasShitDataMap = this.ganttDiagram.getDataHandler().getCanvasShiftDataMap();

      dataset.forEach((connectedObject) => {
        this._organizeEdge(connectedObject, transitiveConnectionIdsForHoveredShift, canvasShitDataMap);
      });
    }

    this.builder.buildEdges(this.getEdgeBuilder().getEdgeData());
    this.labeler.build(this.labeler.getLabels());
    if (this.editorMode) this.editor.buildHandles();
  }

  /**
   * Necessary for starting the removerMode.
   * Executes all neccessary functions.
   */
  startRemoverMode() {
    const s = this;
    if (s.remover.isRemoverActive()) return;
    s.rebuildEdges(s.getConnectedObjectsDataSet()); // for refreshing pathData
    s.remover.setEdgeData(s.builder.getEdgeData());
    s.remover.startRemoverMode();
  }

  /**
   * Neccessary for end the removerMode.
   * Executes all neccessary functions.
   */
  endRemoverMode() {
    const s = this;
    s.remover.removeAll();
  }

  /**
   * Necessary for starting the connectionMode.
   * Executes all neccessary functions.
   */
  startConnectionMode() {
    this.mouseConnect.startConnectionMode();
  }

  /**
   * Handles the removerMode while scrolling.
   * @private
   */
  _removerAtScroll() {
    const s = this;
    if (this.remover.isRemoverActive()) {
      s.endRemoverMode();
      s.startRemoverMode();
    }
  }

  /**
   * Handles things to do on drag start.
   */
  private _onDragStart(): void {
    this.whileDragging = true;
  }

  /**
   * Updates the draggedShift variable with the current information.
   * @param event Mouse event.
   */
  private _getDraggedShift(event: GanttScrollContainerEvent<IShiftDragEvent>): void {
    this.draggedShift = [
      new GanttEdgeHandlerDraggedShift(
        (parseFloat(event.event.target.attr('x')) - this.zoomTransformation.x) / this.zoomTransformation.k,
        parseFloat(event.event.target.attr('y')),
        event.event.target.data()[0].id,
        parseFloat(event.event.target.attr('width')) / this.zoomTransformation.k,
        event.event.target.data()[0].height
      ),
    ];
  }

  /**
   * Handles things to do on drag end.
   */
  private _onDragEnd(): void {
    this.whileDragging = false;
    this.draggedShift = null;
    this.rebuildEdges(this.getConnectedObjectsDataSet());
  }

  /**
   * Handles things to do on mouse over.
   * @param event Drag end event.
   */
  private _onMouseOver(event: GanttScrollContainerEvent<MouseEvent>): void {
    const target = d3.select<SVGRectElement, GanttCanvasShift>(event.event.target as SVGRectElement).data()[0];
    this.currentMouseOverShiftId = target.id;
    this.update();
  }

  /**
   * Handles things to do on mouse out.
   * @param mouseEvent Drag end event.
   */
  private _onMouseOut(): void {
    if (!this.whileDragging) this.currentMouseOverShiftId = null; // because mouse out is driggered while dragging
    this.update();
  }

  /**
   * Returns a bool if the position of a shit will change while gantt row will be opened or closed.
   * @private
   * @param {string} shiftId id of a shift
   * @param {string} openRowId id of open row
   */
  _willPositionOfShiftChange(shiftId, openRowId) {
    const s = this;
    const canvasShiftDataset = s.ganttDiagram.getDataHandler().getCanvasShiftDataset();
    const shiftData = ShiftDataFinder.getCanvasShiftById(canvasShiftDataset, shiftId)[0];
    let rowIndexShift, rowIndexOpenRow;

    if (!shiftData) return false; // if shift is not visible
    const rowIdOfShift = ShiftDataFinder.getShiftById(
      s.ganttDiagram.getDataHandler().getOriginDataset().ganttEntries,
      shiftId
    ).shiftRow.id;
    const rowDataset = s.ganttDiagram.getDataHandler().getYAxisDataset();

    for (let i = 0; i < rowDataset.length; i++) {
      if (rowDataset[i].id == rowIdOfShift) {
        rowIndexShift = i;
        break;
      }
    }
    for (let j = 0; j < rowDataset.length; j++) {
      if (rowDataset[j].id == openRowId) {
        rowIndexOpenRow = j;
        break;
      }
    }

    if (rowIndexShift > rowIndexOpenRow) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * returns the polyline wich connects shift1 and shift2
   * @private
   * @param {string} shiftId1 id of startshift
   * @param {string} shiftId2 id of endshift
   */
  _getPolyLine(shiftId1, shiftId2) {
    const s = this;

    let polyline = null;

    s.builder
      .getGanttEdgeGroup()
      .selectAll('polyline')
      .each(function (d: any) {
        if (d.shiftId1 == shiftId1 && d.shiftId2 == shiftId2) {
          return (polyline = d3.select(this));
        }
      });
    return polyline;
  }

  /**
   * Returns a bool if edge is visible or not.
   * @private
   * @param {any} objectData1 Canvas data of start object.
   * @param {any} objectData2 Canvas data of end object.
   */
  _isEdgeVisible(objectData1: GanttCanvasShift, objectData2: GanttCanvasShift) {
    const shiftTranslationNoRenderId = this.ganttDiagram.getShiftTranslator().noRenderId;

    if (
      !objectData1 ||
      !objectData2 ||
      (objectData1.noRender?.length &&
        !(
          objectData1.noRender.length === 1 &&
          GanttUtilities.isStringInArray(shiftTranslationNoRenderId, objectData1.noRender)
        )) ||
      (objectData2.noRender?.length &&
        !(
          objectData2.noRender.length == 1 &&
          GanttUtilities.isStringInArray(shiftTranslationNoRenderId, objectData2.noRender)
        ))
    ) {
      return false; // id not exists/noRender is active -> edge unvisible
    }
    return true;
  }

  /**
   * Set the visible-value in the connectedObjects dataset true or false.
   * @private
   * @param {string} edgeId Id of edge.
   * @param {boolean} isVisible Set visibility true or false.
   */
  _setVisibilityInConnectedObjectsDatasetByEdgeId(edgeId: string, isVisible: boolean) {
    const result = this.connectedObjects.find((object) => object.edgeId == edgeId);
    if (result) {
      result.visible = isVisible;
    }
  }

  //
  // GETTER & SETTER
  //

  /**
   * Observable which gets triggered when the instance gets destroyed.
   */
  private get onDestroy(): Observable<void> {
    return this._onDestroySubject.asObservable();
  }

  /**
   * @return {Array.<GanttEdgeHandlerConnectedShifts>}
   */
  getConnectedObjectsDataSet(): GanttEdgeHandlerConnectedShifts[] {
    return this.connectedObjects;
  }
  /**
   * @return {GanttEdgeHandler}
   */
  getEdgeBuilder() {
    return this.builder;
  }

  /**
   * @return {GanttEdgeLabeler}
   */
  getLabeler() {
    return this.labeler;
  }

  setColorByEdgeId(edgeId, color) {
    const s = this;

    for (let i = 0; i < s.connectedObjects.length; i++) {
      if (edgeId == s.connectedObjects[i].edgeId) {
        s.ganttDiagram.getHistory().addNewEvent(
          'setColorByEdgeId',
          new GanttEdgeEvent(),
          this,
          edgeId /*
          s.connectedObjects[i].color,
          color,*/
        );
        s.connectedObjects[i].color = color;
        break;
      }
    }

    s.rebuildEdges(s.getConnectedObjectsDataSet());
  }

  setStartLabelByEdgeId(edgeId, text) {
    const s = this;

    const event = s.ganttDiagram.getHistory().addNewEvent(
      'setStartLabel',
      new GanttEdgeEvent(),
      this,
      edgeId
      /*text*/
    );

    for (let i = 0; i < s.connectedObjects.length; i++) {
      if (edgeId == s.connectedObjects[i].edgeId) {
        if (event) event.addAnotherArgument(s.connectedObjects[i].labelStart);
        s.connectedObjects[i].labelStart = text;
        break;
      }
    }

    s.labeler.setStartLabelByEdgeId(edgeId, text);
    s.rebuildEdges(s.getConnectedObjectsDataSet());
  }

  setEndLabelByEdgeId(edgeId, text) {
    const s = this;
    const event = s.ganttDiagram.getHistory().addNewEvent(
      'setStartLabel',
      new GanttEdgeEvent(),
      this,
      edgeId
      /*s.labeler.getLabelByEdgeId(edgeId).labelEnd.label,
      text*/
    );

    for (let i = 0; i < s.connectedObjects.length; i++) {
      if (edgeId == s.connectedObjects[i].edgeId) {
        if (event) event.addAnotherArgument(s.connectedObjects[i].labelEnd);
        s.connectedObjects[i].labelEnd = text;
        break;
      }
    }

    s.labeler.setEndLabelByEdgeId(edgeId, text);
    s.rebuildEdges(s.getConnectedObjectsDataSet());
  }

  getEdgeDataByEdgeId(edgeId) {
    return this.connectedObjects.filter(function (object) {
      return object.edgeId == edgeId;
    });
  }

  getEdgeDataByObjectId(objectId) {
    const foundEdges = [];
    for (const edgeEntry of this.connectedObjects) {
      if (edgeEntry.id1 == objectId || edgeEntry.id2 == objectId) {
        foundEdges.push(edgeEntry);
      }
    }
    return foundEdges;
  }

  showEdges(boolean) {
    const s = this;
    s.setShowEdges(boolean);
    if (!boolean) {
      s.getEdgeBuilder().removeAllEdgeNodes();
    } else {
      s.getEdgeBuilder().buildEdges(s.getEdgeBuilder().getEdgeData());
    }
  }

  setShowEdges(boolean) {
    this.edgeConfig.renderEdges = boolean;
  }
  setArrowHeads(boolean) {
    this.edgeConfig.renderArrowsOnEdges = boolean;
  }

  /**
   * Returns All Edges where the ShiftId is TRANSITIV connected to.
   * @param {dataset}
   * @param {shiftId} Id of shift to search for
   * @return {String[]} EdgeIds that are connected with shift transitiv.
   */
  findTransitiveEdgeConnections(dataset: GanttEdgeHandlerConnectedShifts[], shiftId?: string) {
    if (!shiftId) {
      return [];
    }

    const foundConnectionIds = new Set<string>();
    const foundElements = new Set<string>([shiftId]);

    let elementFound = true;
    while (elementFound) {
      elementFound = false;
      for (const datum of dataset) {
        const visualizationMode = datum.visualizationMode ?? this.getGlobalVisualizationMode(); // if no visualization mode is set, use global visualization mode so object specific visualization is stronger than global
        if (
          visualizationMode === VisualizationMode.TRANSITIVE_CONNECTIONS &&
          !foundConnectionIds.has(datum.edgeId) &&
          (foundElements.has(datum.id1) || foundElements.has(datum.id2))
        ) {
          foundElements.add(datum.id1);
          foundElements.add(datum.id2);
          foundConnectionIds.add(datum.edgeId);
          elementFound = true;
        }
      }
    }

    return Array.from(foundConnectionIds);
  }
}

/**
 * Object stores edge-information.
 *
 * @param {string} id1
 * @param {string} id2
 * @param {String} color HexCode
 * @param {string} labelStart
 * @param {string} labelEnd
 * @param {boolean} visible
 * @param {number} typeStart Arrow head type on arrow beginning.
 * @param {number} typeEnd Arrow head type on arrow end.
 * @param {number} lineStyle Arrow line style.
 * @param {number} visualizationMode Mode for visualization.
 */
export interface GanttEdgeHandlerConnectedShifts {
  id1: string;
  id2: string;
  edgeId: string;
  color: string;
  labelStart: string;
  labelEnd: string;
  visible: boolean;
  typeStart: number;
  typeEnd: number;
  lineStyle: number;
  visualizationMode?: VisualizationMode;
}

export enum VisualizationMode {
  /**
   * Edge is always visible.
   */
  ALWAYS_VISIBLE = 0,
  /**
   * Edge is only visible in edit mode or if mouse over source / target block of this connection.
   */
  DIRECT_CONNECTIONS = 1,
  /**
   * Edge is only visible in edit mode or if mouse over any block that is indirectly connected to this connection (transitive).
   */
  TRANSITIVE_CONNECTIONS = 2,
  /**
   * Edge is never visible.
   */
  NONE = 3,
}

class GanttEdgeHandlerDraggedShift {
  public readonly isDragged = true;

  constructor(public x: number, public y: number, public id: string, public width: number, public height: number) {}
}
