import { Observable, merge, of, switchMap } from 'rxjs';
import { GanttCanvasShift, GanttDataRow } from '../data-handler/data-structure/data-structure';
import { GanttScrollContainerEvent } from '../html-structure/scroll-container-event';
import { EGanttScrollContainer } from '../html-structure/scroll-container.enum';
import { BestGantt } from '../main';
import { RenderDataHandler } from '../render-data-handler/render-data-handler';
import {
  ElementSelectorProportions,
  GanttElementSelector,
  SelectionBoxDragEvent,
  SelectionBoxParameters,
} from './element-selector';

/**
 * Facade for selection box building.
 */
export class GanttElementSelectorFacade {
  private _selectionBoxBuilder: { [id: string]: GanttElementSelector } = {};

  /**
   * @param _ganttDiagram
   */
  constructor(private _ganttDiagram: BestGantt) {
    for (const scrollContainerId of Object.values(EGanttScrollContainer)) {
      this._selectionBoxBuilder[scrollContainerId] = new GanttElementSelector(this._ganttDiagram, scrollContainerId);
    }
  }

  public init(): void {
    for (const scrollContainerId of Object.values(EGanttScrollContainer)) {
      this._selectionBoxBuilder[scrollContainerId].initSelectionBox();
    }
  }

  //
  // SHIFT SELECTION
  //

  public selectShiftById(canvasDataSet: GanttCanvasShift[], originDataSet: GanttDataRow[], shiftId: string): void {
    const isShiftInStickyRow = this._renderDataHandler.getStateStorage().isShiftInStickyRow(shiftId);
    const scrollContainerId = isShiftInStickyRow ? EGanttScrollContainer.STICKY_ROWS : EGanttScrollContainer.DEFAULT;

    this._selectionBoxBuilder[scrollContainerId].selectShiftById(canvasDataSet, originDataSet, shiftId);
  }

  public deselectShiftById(canvasDataSet: GanttCanvasShift[], originDataSet: GanttDataRow[], shiftId: string): void {
    const isShiftInStickyRow = this._renderDataHandler.getStateStorage().isShiftInStickyRow(shiftId);
    const scrollContainerId = isShiftInStickyRow ? EGanttScrollContainer.STICKY_ROWS : EGanttScrollContainer.DEFAULT;

    this._selectionBoxBuilder[scrollContainerId].deselectShiftById(canvasDataSet, originDataSet, shiftId);
  }

  public selectShiftsByIds(shiftIds: string[]): void {
    const stickyRowShiftIds = shiftIds.filter((shiftId) =>
      this._renderDataHandler.getStateStorage().isShiftInStickyRow(shiftId)
    );
    const notStickyRowShiftIds = shiftIds.filter(
      (shiftId) => !this._renderDataHandler.getStateStorage().isShiftInStickyRow(shiftId)
    );

    for (const shiftId of notStickyRowShiftIds) {
      this._ganttDiagram
        .getSelectionBoxFacade()
        .getSelectionBoxBuilder(EGanttScrollContainer.DEFAULT)
        .selectShiftById(
          this._ganttDiagram.getDataHandler().getCanvasShiftDataset(),
          this._ganttDiagram.getDataHandler().getOriginDataset().ganttEntries,
          shiftId
        );
    }
    for (const shiftId of stickyRowShiftIds) {
      this._ganttDiagram
        .getSelectionBoxFacade()
        .getSelectionBoxBuilder(EGanttScrollContainer.STICKY_ROWS)
        .selectShiftById(
          this._ganttDiagram.getDataHandler().getCanvasShiftDataset(),
          this._ganttDiagram.getDataHandler().getOriginDataset().ganttEntries,
          shiftId
        );
    }
  }

  public deselectAllShifts(canvasDataSet: GanttCanvasShift[], originDataSet: GanttDataRow[]): void {
    for (const scrollContainerId of Object.values(EGanttScrollContainer)) {
      this._selectionBoxBuilder[scrollContainerId].deselectAllShifts(canvasDataSet, originDataSet);
    }
  }

  public getSelectedShifts(): GanttCanvasShift[] {
    let selectedShifts: GanttCanvasShift[] = [];
    for (const scrollContainerId of Object.values(EGanttScrollContainer)) {
      selectedShifts = selectedShifts.concat(this._selectionBoxBuilder[scrollContainerId].getSelectedShifts());
    }
    return selectedShifts;
  }

  //
  // RESTRICTIONS
  //

  public setEnable(bool: boolean): void {
    for (const scrollContainerId of Object.values(EGanttScrollContainer)) {
      this._selectionBoxBuilder[scrollContainerId].setEnable(bool);
    }
  }

  //
  // GETTER & SETTER
  //

  /**
   * Helper getter which returns the render data handler of the current gantt.
   */
  private get _renderDataHandler(): RenderDataHandler {
    return this._ganttDiagram.getRenderDataHandler();
  }

  public getSelectionBoxBuilder(scrollContainerId = EGanttScrollContainer.DEFAULT): GanttElementSelector {
    return this._selectionBoxBuilder[scrollContainerId];
  }

  public getSelectionBoxStartGroup(
    scrollContainerId = EGanttScrollContainer.DEFAULT
  ): d3.Selection<SVGRectElement, undefined, d3.BaseType, undefined> {
    return this._selectionBoxBuilder[scrollContainerId].getSelectionBoxStartGroup();
  }

  public setBoxRules(selectionBoxParameters: SelectionBoxParameters): void {
    for (const scrollContainerId of Object.values(EGanttScrollContainer)) {
      this._selectionBoxBuilder[scrollContainerId].setBoxRules(selectionBoxParameters);
    }
  }

  public clearSelectionBoxRules(): void {
    for (const scrollContainerId of Object.values(EGanttScrollContainer)) {
      this._selectionBoxBuilder[scrollContainerId].clearSelectionBoxRules();
    }
  }

  //
  // OBSERVABLES
  //

  public get onDragStart(): Observable<GanttScrollContainerEvent<SelectionBoxDragEvent>> {
    return this._mergeObservables('onDragStart');
  }

  public get beforeDragUpdate(): Observable<GanttScrollContainerEvent<ElementSelectorProportions>> {
    return this._mergeObservables('beforeDragUpdate');
  }

  public get onDragUpdate(): Observable<GanttScrollContainerEvent<ElementSelectorProportions>> {
    return this._mergeObservables('onDragUpdate');
  }

  public get afterSelection(): Observable<GanttScrollContainerEvent<SelectionBoxDragEvent>> {
    return this._mergeObservables('afterSelection');
  }

  public get afterDeselection(): Observable<GanttScrollContainerEvent<void>> {
    return this._mergeObservables('afterDeselection');
  }

  public get afterSingleShiftSelection(): Observable<GanttScrollContainerEvent<GanttCanvasShift>> {
    return this._mergeObservables('afterSingleShiftSelection');
  }

  public get afterSingleShiftDeselection(): Observable<GanttScrollContainerEvent<GanttCanvasShift[]>> {
    return this._mergeObservables('afterSingleShiftDeselection');
  }

  private _mergeObservables<T>(key: string): Observable<GanttScrollContainerEvent<T>> {
    const observables: Observable<GanttScrollContainerEvent<T>>[] = [];
    for (const scrollContainerId of Object.values(EGanttScrollContainer)) {
      observables.push(
        (this._selectionBoxBuilder[scrollContainerId][key] as Observable<T>).pipe(
          switchMap((event) => of(new GanttScrollContainerEvent<T>(scrollContainerId, event)))
        )
      );
    }
    return merge(...observables);
  }
}
