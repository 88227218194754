import { DataType } from './base/base-template';
import { InputType } from './mapper';

export namespace TemplateActions {
  export class AddTemplate {
    static readonly type = '[TemplateActions] Add Template';
    constructor(public template: InputType<DataType>, public resourceId: string = null) {}
  }

  export class UpdateResource {
    static readonly type = '[TemplateActions] Update Resource';
    constructor(public resourceId: string, public state: boolean) {}
  }

  export class RemoveTemplate {
    static readonly type = '[TemplateActions] Remove Template';
    constructor(public id: string) {}
  }

  export class RefreshElements {
    static readonly type = '[TemplateActions] Refresh Elements';
    constructor(public elements: string[]) {}
  }
}
