import { IIndexCardTextInterface } from './text-creator-interface';

/**
 * Interface for indexCard-PlugIn Text building strategies.
 * Renders no text at all.
 */
export class IndexCardTextNoText implements IIndexCardTextInterface {
  public init(): void {
    // nothing
  }

  public build(): void {
    // nothing
  }

  public cleanUp(): void {
    // nothing
  }
}
