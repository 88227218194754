export enum EActionType {
  GET_CALL = 'GET_CALL',
  EXECUTE_EXPERIMENT = 'EXECUTE_EXPERIMENT',
  NEXT_PAGE = 'NEXT_PAGE',
  PREVIOUS_PAGE = 'PREVIOUS_PAGE',
  NAVIGATE_TO_PAGE = 'NAVIGATE_TO_PAGE',
  EXECUTE_SOLVER_RUN = 'EXECUTE_SOLVER_RUN',
  CALL_ACTION_EXECUTER = 'CALL_ACTION_EXECUTER',
  CREATE_RESOURCE = 'CREATE_RESOURCE',
  NAVIGATE_TREE = 'NAVIGATE_TREE',
  LOAD_AND_CLONE_RESOURCE = 'LOAD_AND_CLONE_RESOURCE',
  FILE_UPLOAD = 'FILE_UPLOAD',
  GRAPH_API_ACTION = 'GRAPH_API_ACTION',
  GRAPH_API_SILENT_ACTION = 'GRAPH_API_SILENT_ACTION',
  EXECUTE_IMPORT = 'EXECUTE_IMPORT',
  CONDITIONAL_ACTION = 'CONDITIONAL_ACTION',
  DELETE_CALL = 'DELETE_CALL',
  RELOAD_TREE = 'RELOAD_TREE',
  SET_DATE_NOW = 'SET_DATE_NOW',
  PREDEFINED = 'PREDEFINED',
  LOCAL = 'LOCAL',
  CLOSE_WIZARD = 'CLOSE_WIZARD',
}
