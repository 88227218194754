import { PatternBuilder } from '../../patternBuilder';
import { GanttPatternData } from '../../patternHandler';
import { Pattern } from '../pattern.base';

/**
 * Class which handles the generation of a pattern with small dots.
 */
export class SmallDotsPattern extends Pattern {
  /**
   * @param patternBuilder {@link PatternBuilder} instance to be used for the pattern generation.
   */
  constructor(patternBuilder: PatternBuilder) {
    super(patternBuilder);
    this._patternWidth = 5;
    this._patternHeight = 5;
  }

  /**
   * Generates an SVG image containing the pattern and returns it as base64-encoded URL.
   * @param backgroundColor Background color of the pattern.
   * @param patternColor Color of the pattern.
   * @param strokeColor Stroke color of the pattern.
   * @returns SVG image containing the pattern as base64-encoded URL.
   */
  protected _getPatternDataString(backgroundColor: string, patternColor: string, strokeColor: string): string {
    const pattern = this._patternBuilder.getSvgElement(this._patternHeight, this._patternWidth);
    this._patternBuilder.appendBackground(pattern, backgroundColor);
    this._patternBuilder.appendRect(pattern, 1, 1, 0, 0, patternColor, strokeColor);
    return this._patternBuilder.convertSvgNodeIntoDataString(pattern);
  }

  /**
   * Generates an SVG image containing the pattern and returns it as {@link GanttPatternData} object.
   * @param backgroundColor Background color of the pattern.
   * @param patternColor Color of the pattern.
   * @param strokeColor Stroke color of the pattern.
   * @returns SVG image containing the pattern as {@link GanttPatternData} object.
   */
  public getPatternData(backgroundColor: string, patternColor: string, strokeColor: string): GanttPatternData {
    return new GanttPatternData(
      this._getPatternDataString(backgroundColor, patternColor, strokeColor),
      this._patternHeight,
      this._patternWidth
    );
  }
}
