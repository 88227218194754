import { Action } from '@app-modeleditor/components/button/action/action';
import { Button } from '@app-modeleditor/components/button/button';
import { EButtonDisplayType } from '@app-modeleditor/components/button/button-display-type.enum';
import { EEntryElementPosition } from '@app-modeleditor/components/entry-collection/entry-element-position.enum';
import { Observable, of } from 'rxjs';
import { Gantt_General } from '../../general.gantt.component';
import { IGanttAttributeData, IGanttVisibleAttributes } from '../../generator/gantt-input.data';
import { GanttEssentialPlugIns } from '../../plugin/e-gantt-essential-plugins';
import { GanttIndexCardsPlugIn } from '../../plugin/plugin-list/index-cards/index-cards.plugin';
import { EditIndexCardAttributesLightbox } from './edit-index-card-attributes-lightbox';

export class EditIndexCardAttributesButton extends Button {
  private lightBox: EditIndexCardAttributesLightbox;
  private attributeMapping: IGanttAttributeData[];
  private attributesOrderIds: string[];
  constructor(private scope: Gantt_General) {
    super();
  }

  get(): this {
    this.attributeMapping = Object.values(this.scope.ganttTemplateDataService.getTemplateData().getAttributeMapping());
    this.setName('@customize-content@')
      .setIcon('EDIT_INDEXCARD')
      .setDisplayType(EButtonDisplayType.ICON_WITH_LABEL_BELOW)
      .setAlwaysEnabled(true)
      .chainActions(new Action().setCb(() => this.openLightBox()));
    return this;
  }

  private openLightBox(): Observable<any> {
    this.lightBox = new EditIndexCardAttributesLightbox().get(
      this.attributeMapping,
      this.scope.ganttTemplateDataService.getTemplateData().getIndexCard()
    );
    // set action for applying changes of selection
    this.lightBox.setOnCustomSaveAction(
      new Button()
        .setPosition(EEntryElementPosition.RIGHT)
        .setName('@save@')
        .chainActions(
          new Action().setCb(() => {
            const selectedElements = this.lightBox.getColumnListSelector().getValue().getSelectedListEntryElements();
            const visibleAttributes = {
              attributeMetadata: selectedElements.map((elem, index) => {
                return {
                  attributeID: parseInt(elem.getId()),
                  index: index,
                  readableClassName: 'Attribute',
                };
              }),
              readableClassName: 'Karteikarte',
            };
            this.saveIndexCardSettingsToBackend(visibleAttributes);
            return of(this.lightBox.getRef().close(this.attributesOrderIds));
          })
        )
    );

    this.scope.lightboxApi.open(this.lightBox);
    return of(null);
  }

  private saveIndexCardSettingsToBackend(visibleAttributes: IGanttVisibleAttributes) {
    this.scope.ganttTemplateDataService.getTemplateData().setIndexCard(visibleAttributes);
    const indexCardPlugin: GanttIndexCardsPlugIn = this.scope.ganttPluginHandlerService.getEssentialPlugIn(
      GanttEssentialPlugIns.IndexCardBuilderPlugIn
    );
    if (indexCardPlugin) {
      indexCardPlugin.updatePlugin(true);
    } else {
      console.error('Index card plugin not available!');
    }
    const url = this.scope.ganttTemplateDataService.getTemplateData().getIndexCardSettingsURL();

    if (url) {
      this.scope.ganttPluginHandlerService.http.put('rest/' + url, visibleAttributes).subscribe();
    }
  }
}
