import { ALink } from './link';
import { ELinkType } from './link-type.enum';

export class StartAndEndTimeLink extends ALink {
  constructor(startId: string = null, endId: string = null) {
    super();
    this.setType(ELinkType.START_AND_END_TIME);
    if (startId) {
      this.setStartId(startId);
    }
    if (endId) {
      this.setEndId(endId);
    }
  }
}
