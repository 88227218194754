import {
  TriggerEventFactory,
  TriggerEventFactoryItem,
} from 'frontend/src/dashboard/gantt/general/gantt-actions/event-factory';
import { EGanttActionEvent } from '../../../../gantt-actions/gantt-action-handling/gantt-action-event.enum';
import { ShiftCreatorDragEndEvent } from './drag-end';

/**
 * Factory to register and handle new events.
 * Add more TriggerEventFactoryItems to extend event handling.
 */
export class TriggerEventShiftCreatorFactory extends TriggerEventFactory {
  constructor(templateData: any) {
    super(templateData, [new TriggerEventFactoryItem(EGanttActionEvent.DRAG_END, ShiftCreatorDragEndEvent)], []);
  }
}
