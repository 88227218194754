import * as d3 from 'd3';
import { GanttCanvasShift } from '../../data-handler/data-structure/data-structure';
import { DataManipulator } from '../../data-handler/data-tools/data-manipulator';
import { GanttShiftsAfterRenderEvent, ShiftBuilder } from '../shift-builder';
import { IGanttShiftsBuilding } from './shift-build-interface';

/**
 * PrintView shift building using canvas.
 * @implements {IGanttShiftsBuilding}
 * @class
 */
export class GanttShiftsBuildingCanvasPrint implements IGanttShiftsBuilding {
  canvas: d3.Selection<HTMLCanvasElement, undefined, d3.BaseType, undefined>;
  shiftDataSet: GanttCanvasShift[];
  isMouseOutOfShift: boolean;

  constructor() {
    this.canvas;
    this.shiftDataSet;
    this.isMouseOutOfShift = false;
  }

  /**
   * @override
   */
  init(executer) {
    executer.preloadPatterns();
  }

  /**
   * @override
   */
  renderShifts(dataSet, parentNode, executer: ShiftBuilder) {
    const s = executer;
    const self = this;
    if (!dataSet) return;
    self.shiftDataSet = dataSet;
    self.canvas = executer.getShiftRenderCanvas();
    self.removeAllShifts(self.canvas);

    const yTranslationShifts = 0;
    const scrollTop = parseFloat(this.canvas.style('top'));
    const shiftBlock = self.canvas.node().getContext('2d');
    let rectBoundings, fillColor, strokeData;

    const rowAmount = Math.ceil((parseInt(self.canvas.style('height')) + scrollTop) / s.ganttConfig.rowHeight());
    const oneDataPointPerRow = [0];
    for (let i = 1; i < rowAmount; i++) {
      oneDataPointPerRow[i] = oneDataPointPerRow[i - 1] + s.ganttConfig.rowHeight();
    }

    const canvasWidth = self.canvas.attr('width');
    const canvasBehindShifts = s.getCanvasBehindShifts();

    canvasBehindShifts.selectAll('.rowBGGroup').remove();
    const whiteRowBG = canvasBehindShifts
      .insert('g', '.gantt-area-marker-group')
      .attr('class', 'rowBGGroup')
      .selectAll('rect')
      .data(oneDataPointPerRow);

    // render the white row Backgrounds
    whiteRowBG
      .enter()
      .append('rect')
      .attr('x', 0)
      .attr('width', (d) => canvasWidth)
      .attr('height', s.ganttConfig.rowHeight() - 6)
      .attr('y', (d) => d + 2)
      .style('fill', 'white')
      .style('stroke', 'black')
      .style('stroke-width', '2')
      .attr('class', 'row-background-fill');

    for (let i = 0; i < dataSet.length; i++) {
      const canvasShiftData = dataSet[i];
      if (canvasShiftData.width < 0) continue;
      if (canvasShiftData.hasOwnProperty('noRender') && canvasShiftData.noRender.length) continue;

      rectBoundings = self._getRectBoundings(
        canvasShiftData,
        yTranslationShifts,
        s.getLastZoomTransformation(),
        scrollTop
      );
      fillColor = self._extractShiftColor(canvasShiftData, shiftBlock, s.getPatternHandler());
      rectBoundings.y = rectBoundings.y - s.ganttConfig.getLineTop() + s.ganttConfig.getShiftStrokeWidth();
      strokeData = self._getStrokeData(canvasShiftData, s.ganttConfig);
      self.roundRect(shiftBlock, rectBoundings, strokeData, null);

      self.renderColorStripe(executer, shiftBlock, rectBoundings, fillColor);
    }
    s.afterShiftRenderEvent(new GanttShiftsAfterRenderEvent(null, s.getLastZoomTransformation()));
  }

  renderColorStripe(s, ctx, rectBoundings, fill) {
    const self = this;
    const stripeGeo = { ...rectBoundings };
    stripeGeo.y += s.config.getTextOverlayHeight();
    stripeGeo.height -= s.config.getShiftFontSize() + s.config.getTextOverlayHeight();

    const x = stripeGeo.x,
      y = stripeGeo.y,
      height = stripeGeo.height,
      width = stripeGeo.width;

    ctx.beginPath();
    // Rounding for crisp Edges
    ctx.rect(Math.round(x), y, Math.round(width), Math.round(height));

    ctx.fillStyle = fill;
    ctx.fill();
  }

  roundRect(ctx, rectBoundings, strokeData, fill) {
    const x = rectBoundings.x,
      y = rectBoundings.y,
      height = rectBoundings.height,
      width = rectBoundings.width;

    ctx.beginPath();
    // Rounding for crisp Edges
    ctx.rect(Math.round(x), y, Math.round(width), Math.round(height));

    ctx.fillStyle = fill ? fill : 'white';
    ctx.fill();

    if (!strokeData.strokeColor) strokeData.strokeColor = 'black';
    ctx.strokeStyle = strokeData.strokeColor;
    if (width < 10) strokeData.strokeWidth = (strokeData.strokeWidth * width) / 10;
    ctx.lineWidth = strokeData.strokeWidth;
    ctx.stroke();
  }

  /**
   * Calculates the boundings of rect to be build.
   * @param {object[]} canvasShiftData Canvas shift data of rect to be build.
   * @param {number} yTranslationShifts Translation of rect in y direction.
   * @param {object} lastZoomTransformation Data of the current zoom transformation.
   *
   * @returns {object} Object with bounding information.
   */
  private _getRectBoundings(canvasShiftData, yTranslationShifts, lastZoomTransformation, scrollTop) {
    return {
      x: canvasShiftData.x * lastZoomTransformation.k + lastZoomTransformation.x,
      y: canvasShiftData.y - scrollTop + yTranslationShifts,
      width: canvasShiftData.width * lastZoomTransformation.k,
      height: canvasShiftData.height,
    };
  }

  /**
   * Extract stroke data of rect to be build.
   * @param {object[]} canvasShiftData Canvas shift data of rect to be build.
   * @param {object} ganttConfig
   *
   * @returns {object} Object with stroke information.
   */
  private _getStrokeData(canvasShiftData, ganttConfig) {
    return {
      strokeColor: canvasShiftData.strokeColor,
      strokeWidth: ganttConfig.getShiftStrokeWidth(),
    };
  }

  /**
   * Calculates shift Color
   * @private
   */
  private _extractShiftColor(shiftData, ctx, patternHandler) {
    if (shiftData.highlighted) return shiftData.highlighted;
    if (shiftData.selected) return shiftData.selected;
    if (shiftData.pattern) {
      return ctx.createPattern(
        patternHandler.getPatternAsSvgImage(shiftData.pattern, shiftData.color, shiftData.patternColor),
        'repeat'
      );
    }
    if (shiftData.color) return shiftData.color;
    return '#000000';
  }

  /**
   * @override
   */
  removeAllShifts(executer) {
    const s = this;
    if (!s.canvas) return;
    const context = s.canvas.node().getContext('2d');
    context.clearRect(0, 0, s.canvas.node().width, s.canvas.node().height);
  }

  /**
   * @override
   */
  preloadPatterns(executer, originDataSet) {
    const s = executer;
    const self = this;
    let cntPatterns = 0;
    let cntLoadings = 0;
    const patternList = [];

    const registerPattern = function (child, level, foundShift, shiftIndex) {
      for (const shift of child.shifts) {
        if (shift.pattern) {
          const patternId = shift.pattern + shift.color + shift.patternColor;
          if (patternList.indexOf(patternId) == -1) {
            //mechanism to load same pattern only once
            patternList.push(patternId);
            cntPatterns++;
            const img = s.patternHandler.getPatternAsSvgImage(shift.pattern, shift.color, shift.patternColor);
            img.onload = function () {
              cntLoadings++;
              if (cntPatterns == cntLoadings) {
                executer.reBuildShifts(self.shiftDataSet);
              } //when all patterns are loaded
            };
          }
        }
      }
    };
    DataManipulator.iterateOverDataSet(originDataSet, { registerPattern: registerPattern });
  }

  resizeChange(executer: any, boolean: any): void {}

  /**
   * @override
   */
  initCallbacks(executer) {}
  /**
   * @override
   */
  removeCallbacks(executer) {}

  /**
   * @override
   */
  clearTimeouts() {}
}
