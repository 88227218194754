import { CdkDragEnd, CdkDragMove } from '@angular/cdk/drag-drop';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  WritableSignal,
  signal,
} from '@angular/core';
import {
  MatLegacyAutocomplete as MatAutocomplete,
  MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent,
  MatLegacyAutocompleteTrigger as MatAutocompleteTrigger,
} from '@angular/material/legacy-autocomplete';
import { ERequestMethod, RequestService } from '@app-modeleditor/request.service';
import { ConfigService } from '@core/config/config.service';
import { GlobalUtils } from 'frontend/src/dashboard/global-utils';
import { Observable, Subject, of } from 'rxjs';
import {
  debounceTime,
  delay,
  distinctUntilChanged,
  finalize,
  map,
  sample,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { TemplateActionService } from '../button/template-action.service';
import { EEntryElementEvents } from '../entry-collection/entry-element-events.enum';
import { TemplateUiService } from '../template-ui/template.service';
import { EntryElementValue } from './../entry-collection/entry-element-value';
import { EntryElementFactory } from './../entry-collection/entry-factory.service';
import { MultiObjectSelector } from './multi-object-selector';

type ChangeType = { id: string; value: EntryElementValue | EntryElementValue[] };
type CloseType = { id: string; value: string };
const MAX_PANEL_WIDTH = 480;

@Component({
  selector: 'app-selector',
  templateUrl: './selector.component.html',
  styleUrls: ['./selector.component.scss'],
})
export class SelectorComponent implements OnInit, OnChanges, OnDestroy {
  @Input() element: MultiObjectSelector; // the element itself
  @Input() multi = false; // whether the component is a multi object selector or not
  @Input() initiallyOpened = false;
  @Input() disabled: boolean;
  @Input() simpleInput = false;
  @Input() resizeable = true;

  private readonly _change$ = new Subject<ChangeType>();
  private readonly _triggerMultiChange$ = new Subject<void>();
  @Output() get changeEvent(): EventEmitter<ChangeType> {
    const buffer = new EventEmitter<ChangeType>();
    this._change$
      .pipe(
        tap((val) => (!this.multi ? buffer.emit(val) : null)), // single change is triggered immediately
        sample(this._triggerMultiChange$),
        tap((val) => (this.multi ? buffer.emit(val) : null)) // multi change is waiting to be triggered by closing, this is relevant in table cells because change event is transforming input back into label
      )
      .subscribe();
    return buffer;
  }

  @Output() closeEvent: EventEmitter<CloseType> = new EventEmitter<CloseType>();

  @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;

  private readonly _ngOverwriteOption$ = new Subject<void>();
  private readonly _ngDestroy$ = new Subject<void>();
  private readonly requestOptionsFromServer$ = new Subject<{ query: string; force: boolean }>();
  protected readonly loadingResults = signal(false);
  selector: ElementRef;
  private availableValues = new Map<string, EntryElementValue>();
  protected readonly availableOptions: WritableSignal<EntryElementValue[]> = signal([]);
  selectedValues: EntryElementValue[] = [];
  value: any;
  itemHeight = 49;
  public scrollWrapperMaxHeight = 'calc(100%)';
  isOpen = false;
  private componentId: string = GlobalUtils.generateUUID();
  public inSidePanel = false;
  initial = true;
  initOpenDone = false;
  panelWidth: number;

  @ViewChild(CdkVirtualScrollViewport) scrollport: CdkVirtualScrollViewport;
  completeRef: MatAutocomplete;

  maxPanelWidth = MAX_PANEL_WIDTH;
  @ViewChild(MatAutocomplete) set myScrollport(completeRef: MatAutocomplete) {
    this._ngOverwriteOption$.next();
    this.curScrolledIndex = 0;
    this.completeRef = completeRef;
    of(null)
      .pipe(delay(0))
      .subscribe(() => {
        // autoselect first option
        completeRef._keyManager.setActiveItem(completeRef.options.toArray()[0]);
        // hook into key events
        completeRef._keyManager.onKeydown = (event: KeyboardEvent) => {
          if (!this.scrollport) {
            return;
          }
          if (event.code === 'ArrowDown') {
            this.curScrolledIndex++;
          } else if (event.code === 'ArrowUp') {
            this.curScrolledIndex--;
          } else {
            return;
          }

          // number of MatOptions in MatAutocomplete
          const numOptions: number = this.availableOptions().length + this._getScrollOffset();

          // normalize values
          this.curScrolledIndex =
            this.curScrolledIndex > numOptions - 1
              ? 0
              : this.curScrolledIndex < 0
              ? numOptions - 1
              : this.curScrolledIndex;

          // only scroll if current index exceeds offset
          if (this.curScrolledIndex >= this._getScrollOffset()) {
            this.scrollport?.scrollToIndex(this.curScrolledIndex - this._getScrollOffset());
          } else {
            this.scrollport?.scrollToIndex(0);
          }

          this._activateOption();
        };
      });
  }

  get tooltipInfo(): string {
    if (this.selectedValues.length > 5) {
      const rest = this.selectedValues.slice(5);
      return rest.map((item) => item.getName()).join('<br>');
    }
    return '';
  }

  private _activateOption(): void {
    of(null)
      .pipe(delay(0))
      .subscribe(() => {
        const renderedStart: number = this.scrollport.getRenderedRange().start;
        const idx: number = this.curScrolledIndex - renderedStart;
        this.completeRef._keyManager.setActiveItem(this.completeRef.options.toArray()[idx]);
      });
  }

  public scrolledIndexChanged(): void {
    this._activateOption();
  }

  get selectedColor(): string {
    if (this.disabled || this.element.isDisabled()) {
      return undefined; // use default color for disabled
    } else if (!this.multi) {
      return this.element?.getValue<EntryElementValue>()?.getColor() || 'black';
    }
    return 'black';
  }

  private _getScrollOffset(): number {
    let offset = 0;
    if (this.multi) {
      offset++;
    }
    if (this.element?.isFreeSelection && this.element?.isFreeSelection() && this.getOptionsName(this.customOption)) {
      offset++;
    }
    return offset;
  }

  @ViewChild('selector') set mySelector(_selector: ElementRef) {
    this.selector = _selector;
    if (this.initiallyOpened && !this.initOpenDone) {
      of(null)
        .pipe(delay(0))
        .subscribe(() => {
          this.selector.nativeElement.focus();
          this.getOptions(!this.simpleInput);
          this.initOpenDone = true;
        });
    }
  }
  @ViewChild('inputMask') mask: ElementRef;
  @ViewChild('upperPart') set upperPartReady(upperPart: ElementRef) {
    of(null)
      .pipe(delay(0))
      .subscribe(() => {
        const scrollWrapperOffset = upperPart.nativeElement.clientHeight + (this.resizeable ? 24 : 0);
        this.scrollWrapperMaxHeight = 'calc(100% - ' + scrollWrapperOffset + 'px)';
      });
  }

  constructor(
    private requestApi: RequestService,
    private entryElementFactory: EntryElementFactory,
    private templateActionApi: TemplateActionService,
    private _templateUiApi: TemplateUiService,
    private _zone: NgZone,
    private configApi: ConfigService
  ) {
    this._templateUiApi
      .afterSave()
      .pipe(takeUntil(this._ngDestroy$))
      .subscribe(() => {
        this.initial = true;
      });
    this.listenToRequestOptions();
  }

  trackByFn(index: number): number {
    return index;
  }

  afterChanged(): void {
    if (this.element.isFreeSelection()) {
      // this.afterSelectChip();
    } else if (
      this.availableOptions().find((v: EntryElementValue) => v.getValue<EntryElementValue>().getName() === this.value)
    ) {
      this.afterSelectChip(this.value);
    } else if (!this.value) {
      this._emit(new EntryElementValue().setValue(null));
    }

    if (this.isError() === true) {
      // this._emit(null);
    }

    if (this.multi) {
      this.value = '';
    }
  }

  scrollHeight: string;
  private _calcScrollHeight(): void {
    this.scrollHeight = `${(this.availableOptions() || []).length * this.itemHeight}px`;
  }

  stopEvent(event: MouseEvent): void {
    event.stopImmediatePropagation();
    event.stopPropagation();
  }

  /**
   * angulars on init hook
   * @returns void
   */
  ngOnInit(): void {
    this.validateData(true);
    this.element?.setEdited(false);
    this.element.addEventListener(this.componentId, EEntryElementEvents.VALUE_CHANGED, () => {
      this.validateData();
    });
  }

  ngOnDestroy(): void {
    this._ngOverwriteOption$.next();
    this._ngOverwriteOption$.complete();
    this._ngDestroy$.next();
    this._ngDestroy$.complete();
    this.element?.removeEventListener(this.componentId);
    this._change$.complete();
  }

  /**
   * get options name
   * @param option: EntryElementValue
   * @returns string;
   */
  public getOptionsName(option: EntryElementValue): string {
    if (!option || !option.getValue<EntryElementValue>() || !option.getValue<EntryElementValue>().getName()) {
      return null;
    }
    return option.getValue<EntryElementValue>().getName();
  }

  customOption: EntryElementValue = new EntryElementValue().setValue(new EntryElementValue());

  /**
   * after chip is removed
   * @param value string
   * @returns void
   */
  public afterChipRemoved(value: string, isCancelButton?: boolean): void {
    this.selectedValues = this.selectedValues.filter((item: EntryElementValue) => item.getName() !== value).slice();
    this.element.getValue<EntryElementValue>().setValue(this.selectedValues);
    this._emit(this.selectedValues);
    if (isCancelButton) {
      // only if the cancel button was pressed, otherwise it is emitted 2 times
      this._triggerMultiChange$.next();
    }
  }

  /**
   * inserts a new chip
   * @param availableValues IAvailableValues
   * @param chipName string
   * @returns void
   */
  public insertChip(chip: EntryElementValue): void {
    this.selectedValues = this.selectedValues
      .filter(
        (selectedChip: EntryElementValue) => selectedChip.getName() !== chip.getValue<EntryElementValue>().getName()
      )
      .concat(chip.getValue<EntryElementValue>());
  }

  /**
   * gets color based on chip
   * @param chip EntryElementValue
   * @returns string
   */
  public getChipColor(chip: EntryElementValue): string {
    if (chip.getColor()) {
      return chip.getColor();
    }
    const entryValue = this.availableValues.get(chip.getName());
    return entryValue?.getColor() || null;
  }

  public afterOptionSelected(value: string): void {
    if (this.multi) {
      if (value === 'all') {
        this.toggleMaster(null);
        this.value = '';
        this.mask.nativeElement.value = '';
        return;
      }
      const optionKey = this.keys.find((key: string) => key === value);
      if (!optionKey) {
        if (this.element.isFreeSelection()) {
          this.afterSelectChip();
        }
        return;
      }
      const option = this.availableValues.get(optionKey);
      this.handleOptionCheckbox(null, option);
    } else {
      if (value || this.value) {
        this.afterSelectChip(value);
      }
    }
  }
  /**
   * after chip is selected
   * @param eventValue string
   * @returns void
   */
  public afterSelectChip(eventValue: string = null): void {
    of(null)
      .pipe(delay(0))
      .subscribe(() => {
        const result: string = this.keys.find((key: string) => {
          const entryValue = this.availableValues.get(key);
          if (entryValue.getValue<EntryElementValue>().getName() === eventValue) {
            this.insertChip(entryValue);
            if (this.multi) {
              this.element.getValue<EntryElementValue>().setValue(this.selectedValues);
              this._emit(this.selectedValues);
            } else {
              this.element.setValue(entryValue);
              this._emit(entryValue);
            }
            return key;
          }
        });

        if (!result) {
          if (this.element && this.element instanceof MultiObjectSelector && this.element.isFreeSelection()) {
            const copied: EntryElementValue = this.customOption.copy(EntryElementValue);
            this.insertChip(copied);
            if (this.multi) {
              this.element.getValue<EntryElementValue>().setValue(this.selectedValues);
              this._emit(this.selectedValues);
              this._triggerMultiChange$.next();
            } else {
              this.element.getValue<EntryElementValue>().setValue(copied);
              this._emit(copied);
            }
            this.customOption = new EntryElementValue().setValue(new EntryElementValue());
          } else if (!this.multi) {
            // nothing selector
            this.element.getValue<EntryElementValue>().setValue(new EntryElementValue().setValue(null));
            this._emit(new EntryElementValue().setValue(null));
          }
        }

        if (this.multi) {
          // this.mask.nativeElement.value = '';
          this.value = '';
        }
      });
  }

  private lastSavedVal: string;
  private _emit(val?: EntryElementValue | EntryElementValue[]): void {
    if (
      !val ||
      Array.isArray(val) ||
      this.lastSavedVal !== (val as EntryElementValue)?.getValue<EntryElementValue>()?.getName()
    ) {
      this.lastSavedVal = !val
        ? null
        : Array.isArray(val)
        ? this.lastSavedVal
        : (val as EntryElementValue)?.getValue<EntryElementValue>()?.getName();
      this.isError();
      this.element?.setEdited(true);
      this._change$.next({ id: this.element.getId(), value: val });
      this.initial = false;
    } else {
      if (!this.isOpen) {
        this.closeEvent.emit({
          id: this.element.getId(),
          value: this.lastSavedVal,
        });
      }
    }
  }

  afterErrorMenuOpened(event: Event): void {
    event.stopPropagation();
  }

  get requiredError(): boolean {
    if (this.element.isDisabled() || this.disabled) {
      return false;
    }
    return (
      this.element.isRequired() && ((this.multi && this.selectedValues.length === 0) || (!this.multi && !this.value))
    );
  }

  isError(): boolean {
    let isInvalid: boolean =
      !(this.element.isDisabled() || this.disabled) &&
      !this.isApplied() &&
      !this.element.isFreeSelection() &&
      this.value &&
      this.lastSavedVal !== this.value
        ? true
        : false;

    isInvalid = !this.element.isNullable() && this.requiredError ? true : isInvalid;

    this.element.setValid(!isInvalid);
    return isInvalid;
  }

  isApplied(): boolean {
    if (!this.initial && this.element.isFreeSelection()) {
      return true;
    }

    if (!this.initial && !this.multi) {
      return this.element.getValue<EntryElementValue>().getValue<EntryElementValue>()?.getName() === this.value
        ? true
        : false;
    }

    return false;
  }

  opened(): void {
    this.isOpen = true;
  }

  closed(): void {
    this._triggerMultiChange$.next();
    this.setInsidePanel(false);
    this.isOpen = false;
  }

  public setInsidePanel(value: boolean): void {
    this.inSidePanel = value;
  }

  onPaste(event: Event): void {
    this.value = (event.target as any).value;
    this.onFocusOut();
  }

  /**
   * Returns the URL for retrieving available options.
   * @param requestAllOptions - Whether to request all options.
   * @returns The URL for retrieving available options.
   */
  private getUrlForAvailableOptions(query: string, requestAllOptions = false): string {
    const url = this.templateActionApi.getUrlFromParameterSelectors(
      this.element.getActionRestUrl(),
      this.element.getResourceId(),
      this.element.getActionURLParameterSelectors()
    );
    const queryParameterFound = url.indexOf('?') !== -1;
    const isRequestAllOptions =
      !query || (this.element.isLoadAllOptionsObjectSelector() === true && requestAllOptions === true);
    const queryParameter = isRequestAllOptions ? '' : `${queryParameterFound ? '&' : '?'}value=${query}`;

    return `rest/${url}${queryParameter}`;
  }

  /**
   * Requests available options from the server.
   * @param url - The URL for retrieving available options.
   * @returns An observable of available options.
   */
  private requestAvailableOptions(url: string): Observable<EntryElementValue[]> {
    return of(null).pipe(
      tap(() => this.loadingResults.set(true)),
      switchMap(() => this.requestApi.call(ERequestMethod.GET, url)),
      finalize(() => this.loadingResults.set(false)),
      map((result) => {
        const newAvailableValues = new Map<string, EntryElementValue>();
        const newAvailableOptions: EntryElementValue[] = [];

        // parse result
        Object.keys(result).forEach((key: string) => {
          const entryValue = this.entryElementFactory.parseTemplateEntry(this.element, result[key]);
          newAvailableValues.set(key, entryValue);
          newAvailableOptions.push(entryValue);
        });

        // sort options by index
        newAvailableOptions.sort((a: EntryElementValue, b: EntryElementValue) =>
          a.getIndex() < b.getIndex() ? -1 : 1
        );

        // update properties with new list
        this.availableValues = newAvailableValues;
        this.availableOptions.set(newAvailableOptions);

        return newAvailableOptions;
      })
    );
  }

  public handleKeyboardEvent(event: MatAutocompleteSelectedEvent): void {
    if (event.source.isOpen) {
      ((event.option as any)?._element as ElementRef)?.nativeElement.scrollIntoView();
    }
  }

  private curScrolledIndex = 0;
  public handleKeyUpEvent(event: KeyboardEvent): void {
    switch (event.key) {
      case 'Enter':
        // return;
        if (this.multi) {
          this.value = '';
        } else if (this.availableOptions().length > 0 && !this.value) {
          event.stopPropagation();
          const match = this.availableOptions()[0];
          this.value = match.getValue<EntryElementValue>().getName();
          this.element.getValue<EntryElementValue>().setValue(match.getValue());
          // this._emit(this.element.getValue());
        } else if (this.availableOptions().length === 0) {
          this.closeEvent.emit();
        }
        break;
      case 'ArrowUp':
      case 'ArrowDown':
        return;
      default:
        this.getOptions();
    }
  }

  /**
   * Retrieves the options for the selector component.
   *
   * @param force - Optional parameter to force the retrieval of options.
   * @returns void
   */
  public getOptions(force?: boolean): void {
    if (this.disabled || this.element.isDisabled()) {
      return;
    }
    if (this.element && this.element instanceof MultiObjectSelector && this.element.isFreeSelection()) {
      this.customOption.getValue<EntryElementValue>().setName(this.value).setValue(this.value);
    }
    if (!this.element.getActionRestUrl()) {
      this.availableOptions.set(this.element.getAvailableOptions().slice());
      this.availableValues = new Map<string, EntryElementValue>();
      this.availableOptions().forEach((option) => {
        this.availableValues.set(option.getValue<EntryElementValue>().getName(), option);
      });
      this._calcScrollHeight();
      return;
    }

    // emit value to request options from server
    const emitValue = { query: this.value || '', force: !!force };
    this.requestOptionsFromServer$.next(emitValue);
  }

  /**
   * Listens to the request options from the server and performs necessary actions.
   */
  private listenToRequestOptions(): void {
    this.requestOptionsFromServer$
      .pipe(
        debounceTime(300),
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        map((emitValue) => this.getUrlForAvailableOptions(emitValue.query, emitValue.force)),
        takeUntil(this._ngDestroy$),
        switchMap((url) => this.requestAvailableOptions(url)),
        finalize(() => this.loadingResults.set(false))
      )
      .subscribe(() => {
        this._zone.run(() => {
          this._calcScrollHeight();
          if ((!this.value || this.value === '') && !this.simpleInput && !this.multi) {
            this.element.getValue<EntryElementValue>().setValue(null);
            this._emit(this.element.getValue<EntryElementValue>());
          }
        });
      });
  }

  /**
   * returns current selected keys
   * @returns string[]
   */
  get keys(): string[] {
    return [...this.availableValues.keys()];
  }

  /**
   * whether all options are selected or not
   * @returns boolean
   */
  get allSelected(): boolean {
    return this.keys.length === this.selectedValues.length;
  }

  /**
   * whether somne options are selected or not
   * @returns boolean
   */
  get isIndeterminate(): boolean {
    return this.selectedValues.length > 0 && this.keys.length !== this.selectedValues.length;
  }

  handleOptionCheckbox(ev: Event, chip: EntryElementValue): void {
    ev?.stopPropagation();
    ev?.preventDefault();
    if (!this.isOptionSelected(chip)) {
      this.insertChip(chip);
      this.element.getValue<EntryElementValue>().setValue(this.selectedValues);
    } else {
      this.afterChipRemoved(chip.getValue<EntryElementValue>().getName());
    }

    this._emit(this.selectedValues);
    this.selector.nativeElement.focus();
  }

  isOptionSelected(chip: EntryElementValue): boolean {
    if (!Array.isArray(this.selectedValues)) {
      return false;
    }
    return (
      this.selectedValues.find(
        (selectedChip: EntryElementValue) => selectedChip.getName() === chip.getValue<EntryElementValue>().getName()
      ) !== undefined
    );
  }
  /**
   * toggle master
   * @param event
   * @returns void
   */
  public toggleMaster(event: Event): void {
    event?.stopPropagation();
    event?.preventDefault();

    this.selectedValues = this.allSelected
      ? []
      : this.keys.map((key: string) => this.availableValues.get(key).getValue<EntryElementValue>());
    this.element.getValue<EntryElementValue>().setValue(this.selectedValues);
    this._emit(this.selectedValues);
    this.selector.nativeElement.focus();
    this.value = '';
  }

  /**
   * angulars change hook
   * @returns void
   */
  ngOnChanges(): void {
    this.validateData(true);
  }

  /**
   * initially validates data
   * @returns void
   */
  private validateData(initial = false): void {
    // skip as long there is an error
    if (!initial && this.isError()) {
      return;
    }

    if (!this.element.getValue()) {
      this.value = null;
      this.element.setValue(new EntryElementValue());
    }

    if (this.multi) {
      this.selectedValues = this.element.getValue<EntryElementValue>().getValue<EntryElementValue[]>() || [];

      if (Array.isArray(this.selectedValues)) {
        this.selectedValues = this.selectedValues.map((v: EntryElementValue) => {
          if (v instanceof EntryElementValue) {
            return v;
          }
          return this.entryElementFactory.parseEntryValue(EntryElementValue, v);
        });
      }

      // set default search string of input
      if (typeof this.element.getInitialSearchValue<EntryElementValue>()?.getValue() === 'string') {
        this.value = this.element.getInitialSearchValue<EntryElementValue>().getValue();
      }
      return;
    }

    if (this.element.getValue<EntryElementValue>().getValue()) {
      if (typeof this.element.getValue<EntryElementValue>().getValue() === 'string') {
        this.value = this.element.getValue<EntryElementValue>().getValue();
      } else {
        this.value = this.element.getValue<EntryElementValue>().getValue<EntryElementValue>().getName();
      }

      if (initial) {
        if (typeof this.element.getValue<EntryElementValue>().getValue() === 'string') {
          this.lastSavedVal = this.element.getValue<EntryElementValue>().getValue();
        } else {
          this.lastSavedVal = this.element.getValue<EntryElementValue>().getValue<EntryElementValue>().getName();
        }
      }
    } else {
      this.value = null;
      this.element.getValue<EntryElementValue>().setValue(null);
    }
  }

  onFocusOut(): void {
    if (!this.inSidePanel && this.autocomplete) {
      this.autocomplete.closePanel();
    }
  }

  handleToogleSelectorPanel(event: Event): void {
    event.stopPropagation();
    event.preventDefault();
    if (!this.autocomplete.panelOpen) {
      this.getOptions(true);
      this.selector.nativeElement.focus();
      this.setInsidePanel(true);
    } else {
      this.autocomplete?.closePanel();
      this.setInsidePanel(false);
    }
  }

  onMove(event: CdkDragMove): void {
    this.handleDrag(event.distance);
    event.source.reset();
    event.event.preventDefault();
  }

  afterDrag(event: CdkDragEnd): void {
    this.handleDrag(event.distance);
    event.source.reset();
  }

  getPanelWidth(): number {
    return this.completeRef?.panel?.nativeElement?.parentElement?.offsetWidth;
  }

  handleResizeClick(event: MouseEvent): void {
    event.stopPropagation();
    event.stopImmediatePropagation();
    if (this.getPanelWidth() < MAX_PANEL_WIDTH) {
      this.completeRef.panel.nativeElement.parentElement.style.width = `${MAX_PANEL_WIDTH}px`;
    } else {
      this.completeRef.panel.nativeElement.parentElement.style.width = `${this.mask.nativeElement.offsetWidth + 21}px`;
    }
  }

  mouseDown(event: MouseEvent): void {
    this.inSidePanel = true;
    event.stopPropagation();
    event.stopImmediatePropagation();
  }

  handleDrag(distance): void {
    if (distance) {
      const delta = distance.x;
      this.completeRef.panel.nativeElement.parentElement.style.width = `${this.panelWidth + delta}px`;
    }
  }
}
