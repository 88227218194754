import { TemplateValue } from './value';

export class EntryElementValue extends TemplateValue {
  private availableValues: string[] | boolean[] | EntryElementValue[];
  private localizedValues: { [key: string]: string };
  private type: string;
  private uuid: string;
  private color: string;
  private hint: string;
  private hintColor: string;
  private startValue: number;
  private endValue: number;
  private description: string;
  private upperBound: number;
  private lowerBound: number;
  private stepWidth: number;
  private favorite: boolean;
  private size: number;
  /**
   * Used for combo box values to store the unlocalized value
   * Sometimes the backend wants to read the unlocalized value because the localized value is not unique
   */
  private unlocalizedValue: string;

  public isFavorite(): boolean {
    return this.favorite;
  }

  public setFavorite(favorite: boolean): this {
    this.favorite = favorite;
    return this;
  }

  public getHintColor(): string {
    return this.hintColor;
  }

  public setHintColor(hintColor: string): this {
    this.hintColor = hintColor;
    return this;
  }

  public getUpperBound(): number {
    return this.upperBound;
  }

  public setUpperBound(upperBound: number): this {
    this.upperBound = upperBound;
    return this;
  }

  public getLowerBound(): number {
    return this.lowerBound;
  }

  public setLowerBound(lowerBound: number): this {
    this.lowerBound = lowerBound;
    return this;
  }

  public getStepWidth(): number {
    return this.stepWidth;
  }

  public setStepWidth(stepWidth: number): this {
    this.stepWidth = stepWidth;
    return this;
  }

  public getDescription(): string {
    return this.description;
  }

  public setDescription(description: string): this {
    this.description = description;
    return this;
  }

  public getStartValue(): number {
    return this.startValue;
  }

  public setStartValue(startValue: number): this {
    this.startValue = startValue;
    return this;
  }

  public getEndValue(): number {
    return this.endValue;
  }

  public setEndValue(endValue: number): this {
    this.endValue = endValue;
    return this;
  }

  public getHint(): string {
    return this.hint;
  }

  public setHint(hint: string): this {
    this.hint = hint;
    return this;
  }

  public getAvailableValues<T extends any | string[] | TemplateValue[]>(): T {
    return this.availableValues as T;
  }

  public setAvailableValues(availableValues: string[] | boolean[] | EntryElementValue[]): this {
    this.availableValues = availableValues;
    return this;
  }

  public setLocalizedValues(localizedValues: { [key: string]: string }): this {
    this.localizedValues = localizedValues;
    return this;
  }

  public getLocalizedValues(): { [key: string]: string } {
    return this.localizedValues;
  }

  public getColor(): string {
    return this.color;
  }

  public setColor(color: string): this {
    this.color = color;
    return this;
  }

  public getType(): string {
    return this.type;
  }

  public setType(type: string): this {
    this.type = type;
    return this;
  }

  public getSize(): number {
    return this.size;
  }

  public setSize(size: number): this {
    this.size = size;
    return this;
  }

  public getUuid(): string {
    return this.uuid;
  }

  public setUuid(uuid: string): this {
    this.uuid = uuid;
    return this;
  }

  public getUnlocalizedValue(): string {
    return this.unlocalizedValue;
  }

  public setUnlocalizedValue(unlocalizedValue: string): this {
    this.unlocalizedValue = unlocalizedValue;
    return this;
  }

  constructor() {
    super();
  }
}
