/**
 * service to get Experiments and spread them oer the app
 */
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CloudMessagingService } from '@core/notification/cloud-messaging.service';
import { Notification } from '@core/notification/notification';
import { ENotificationCode } from '@core/notification/notification-code.enum';
import { HorizonService, IHorizon } from 'frontend/src/dashboard/horizonselecter/horizon.service';
import { IModel } from 'frontend/src/dashboard/model/model.data';
import { ModelService } from 'frontend/src/dashboard/model/model.service';
import { environment } from 'frontend/src/environments/environment';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { delay, finalize, map, switchMap } from 'rxjs/operators';
import { IUserSettings, UserService } from '../../user/data-access/user.service';
import { ExperimentResult } from './data/experiment-result';
import { Scenario } from './data/scenario';

@Injectable({
  providedIn: 'root',
})
export class ExperimentService2 {
  private currentExperiment: BehaviorSubject<IExperiment>;
  private _currentExperiment: IExperiment;
  public onExecuteExperiment: Subject<string> = new Subject<string>();
  private model: IModel;
  private horizon: IHorizon;
  private _experiments: IExperiment[];
  private experiments: BehaviorSubject<IExperiment[]> = new BehaviorSubject<IExperiment[]>([]);
  private lastId: string;
  private readonly isLoadingModel$ = new BehaviorSubject<number>(0);

  constructor(
    private http: HttpClient,
    private modelService: ModelService,
    private horzionService: HorizonService,
    private userService: UserService,
    private fcm: CloudMessagingService
  ) {
    this.currentExperiment = new BehaviorSubject(null);

    this.modelService.getCurrentModel().subscribe((model: IModel) => {
      this.model = model;
      this.refresh();
    });
    this.horzionService.getCurrenHorizon().subscribe((horizon: IHorizon) => {
      this.horizon = horizon;
      this.refresh();
    });

    // subscribe to changes of usersettings
    this.userService.getUserSettings().subscribe((settings: IUserSettings) => {
      this.userService.getValueByType('CURRENT_EXPERIMENT', true).subscribe((entry: any) => {
        if (!entry || entry.settingsValue.value === this.lastId) {
          return;
        }

        this.lastId = entry.settingsValue.value;
        this.refresh(true);
      });
    });

    this.fcm.getMessage().subscribe((event: Notification) => {
      if (event && ENotificationCode.EXPERIMENT === event.getMessageCode()) {
        this.refresh();
      }
    });
  }

  getLoadingCount(): Observable<number> {
    return this.isLoadingModel$.asObservable();
  }

  checkSettings(entry) {
    for (const exp of this._experiments) {
      if (entry.settingsValue.value === exp.id) {
        this.setCurrentExperiment(exp);
        return exp;
      }
    }

    this.setCurrentExperiment(this._experiments[0]);
  }

  private initExperiment() {
    return this.userService.getValueByType('CURRENT_EXPERIMENT', true).pipe(
      switchMap((entry: any) => {
        if (!this._experiments) {
          return of(null);
        }

        return of(this.checkSettings(entry));
      })
    );
  }

  private scenario: Scenario;
  private lastHash: string;
  private refresh(force = false): void {
    if (!this.model || !this.horizon) {
      this.scenario = undefined;
      this.setExperiments([]);
      this.setCurrentExperiment(undefined);
      return;
    }

    const newHash = `${this.model.id}-${this.horizon.id}`;
    if (!force && newHash === this.lastHash) {
      return;
    }

    this.lastHash = newHash;

    this.getScenario(this.horizon.id, this.model.id).subscribe((scenario) => {
      this.scenario = scenario;
      this.afterScenarioFetched();
    });
  }

  private afterScenarioFetched(): void {
    if (!this.scenario) {
      return;
    }
    this.getExperimentsListSize()
      .pipe(
        switchMap((listSize: number) => {
          return this._getExperiments();
        })
      )
      .subscribe((experiments: IExperiment[]) => {
        this.setExperiments(experiments);
      });
  }

  public getExperiments(): Observable<IExperiment[]> {
    return this.experiments.asObservable();
  }

  private setExperiments(experiments: IExperiment[]): void {
    if (experiments) {
      if (!this._experiments) {
        return this._setExperiments(experiments);
      }
      experiments = experiments.sort((a, b) => (a.id < b.id ? 1 : -1));
    }

    if (this._experiments) {
      if (!experiments) {
        return this._setExperiments(experiments);
      }
      this._experiments = this._experiments.sort((a, b) => (a.id < b.id ? 1 : -1));

      for (let i = 0; i < this._experiments.length; i++) {
        for (let y = 0; y < experiments.length; y++) {
          if (this._experiments[i].id !== experiments[y].id) {
            return this._setExperiments(experiments);
          }
        }
      }
    }

    return this._setExperiments(experiments);
  }

  private _setExperiments(experiments: IExperiment[]) {
    this._experiments = experiments;
    this.initExperiment().subscribe((res) => {
      this.experiments.next(this._experiments);
    });
  }

  /**
   * set current experiment
   * @param experiment Experiment
   */
  public setCurrentExperiment(experiment: IExperiment): void {
    if (experiment === this._currentExperiment) {
      return;
    } else if (experiment && this._currentExperiment && experiment.id === this._currentExperiment.id) {
      this._currentExperiment.experimentResult = experiment.experimentResult;
      this.currentExperiment.next(this._currentExperiment);
    }

    this._currentExperiment = experiment;
    this.isLoadingModel$.next(this.isLoadingModel$.value + 1);
    this.userService
      .updateUserSettings('CURRENT_EXPERIMENT', experiment ? experiment.id : '')
      .pipe(
        finalize(() =>
          of(void 0)
            .pipe(delay(environment.initialLoadingAnimationDelay))
            .subscribe(() => this.isLoadingModel$.next(this.isLoadingModel$.value - 1))
        )
      )
      .subscribe(() => {
        this.currentExperiment.next(experiment);
      });
  }

  public getCurrentExperiment(): Observable<IExperiment> {
    return this.currentExperiment.asObservable();
  }

  /**
   * return HttpParams out of ExperimentRequestParams
   * @param params ExperimentRequestParams
   */
  private getParams(params: ExperimentRequestParams): HttpParams {
    let httpParams = new HttpParams();

    if (!params) {
      return httpParams;
    }

    for (const item of Object.keys(params)) {
      httpParams = httpParams.set(item, params[item]);
    }
    return httpParams;
  }

  /**
   * get full detailed experiments from scenarioId
   */
  private getExperimentsByScenario(scenarioId: string, params?: HttpParams): Observable<Array<IExperiment>> {
    return this.http
      .get(`rest/scenario/${scenarioId}/experiment/listall`, { params })
      .pipe(map((experiments: Array<IExperiment>) => experiments));
  }

  /**
   * get ExperimentResult
   * @param experimentId id of Experiment
   */
  public getExperimentResult(experimentId: string): Observable<ExperimentResult> {
    return this.http
      .get(`rest/experiment/${experimentId}/result`)
      .pipe(map((experimentResult: ExperimentResult) => experimentResult));
  }

  public editExperiment(experimentId: string, body: any) {
    return this.http.post(`rest/experiment/${experimentId}`, body).pipe(map((experiment: IExperiment) => experiment));
  }

  /**
   * get full detailed experiments from scenarioId
   */
  private getExperimentsListSizeByScenario(scenarioId: string, params?: HttpParams): Observable<number> {
    return this.http
      .get(`rest/scenario/${scenarioId}/experiment/listsize`, { params })
      .pipe(map((listSize: number) => listSize));
  }

  /**
   * get scenario for setup
   * @param horizonId id of Horizon
   * @param modelId id of Model
   */
  private getScenario(horizonId: string, modelId: string): Observable<Scenario> {
    return this.http.get(`rest/planninghorizon/${horizonId}/model/${modelId}/scenario/`).pipe(
      map((scenarios: Array<Scenario>) => {
        return scenarios[0];
      })
    );
  }

  /**
   * get experiments by horizon and model
   * @param horizonId id of Horizon
   * @param modelId id of Model
   * @param params ExperimentRequestParams
   */
  private _getExperiments(params?: ExperimentRequestParams) {
    if (!this.scenario) {
      throw new Error('no scenario');
    }

    return this.getExperimentsByScenario(this.scenario.id, this.getParams(params));
  }

  /**
   * get number of experiments available
   * @param horizonId id of Horizon
   * @param modelId id of Model
   * @param params ExperimentRequestParams
   */
  public getExperimentsListSize(params?: ExperimentRequestParams): Observable<number> {
    if (!this.scenario) {
      throw new Error('No scenario');
    }

    return this.getExperimentsListSizeByScenario(this.scenario.id, this.getParams(params));
  }

  /**
   * execute experiment
   * @param experimentId id of Experiment
   */
  public executeExperiment(experimentId: string): Observable<any> {
    return this.http
      .get(`rest/experiment/${experimentId}/execute`)
      .pipe(map((experiment) => this.onExecuteExperiment.next(experimentId)));
  }

  /**
   * execute experiment interactive
   * @param experimentId id of Experiment
   */
  public executeExperimentInteractive(experimentId: string): Observable<any> {
    return this.http.get(`rest/experiment/${experimentId}/execute/interactive`);
  }

  public deleteExperiment(experimentId: string): Observable<any> {
    return this.http.delete(`rest/experiment/${experimentId}`);
  }
}

/**
 * defines HttpParams for getting Experiments
 */
export interface ExperimentRequestParams {
  offset?: number;
  limit?: number;
  filter?: string;
  order?: string;
}

export interface IExperiment {
  id: string;
  created: number;
  lastModified: number;
  name: string;
  description: string;
  experimentResult: any;
  onProgress: boolean;
  experimentSettings?: any;
}
