<button mat-icon-button [matMenuTriggerFor]="menu">
  <mat-icon [style]="{ color: color }" class="icon">circle</mat-icon>
</button>

<mat-menu class="menu" #menu="matMenu">
  <div (click)="$event.stopPropagation()">
    <color-sketch
      class="colorPicker"
      [color]="color"
      (onChangeComplete)="onColorChange($event)"
      [disableAlpha]="disableAlpha"
    ></color-sketch>
  </div>
  <button mat-button (click)="reset()">{{ 'BUTTON.clear' | translate }}</button>
  <button mat-button (click)="confirm()" class="confirm">{{ 'BUTTON.confirm' | translate }}</button>
</mat-menu>
