import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable, Subject, tap } from 'rxjs';

const BASE_URL = `rest`;
type RefreshedDataResponse = { templateID: string; responseData: any };

@Injectable({
  providedIn: 'root',
})
export class TemplateService {
  private readonly http = inject(HttpClient);
  private readonly _elementRefreshed$ = new Subject<RefreshedDataResponse>();

  public refreshElement(templateID: string, url: string): Observable<any> {
    return this.http
      .get(`${BASE_URL}/${url}`)
      .pipe(tap((responseData) => this._elementRefreshed$.next({ templateID, responseData })));
  }

  /**
   * Returns an observable that emits a RefreshedDataResponse object whenever an element is refreshed.
   * @returns An observable that emits a RefreshedDataResponse object.
   */
  public onElementRefreshed(): Observable<RefreshedDataResponse> {
    return this._elementRefreshed$.asObservable();
  }
}
