import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { EntryElement } from '@app-modeleditor/components/entry-collection/entry-element';
import { take } from 'rxjs/operators';
import { EntryElementValue } from '../entry-collection/entry-element-value';
import { ButtonService } from './../button/button.service';

@Component({
  selector: 'template-resource-selector',
  templateUrl: './resource-selector.component.html',
  styleUrls: ['./resource-selector.component.scss'],
})
export class ResourceSelectorComponent implements OnInit, OnChanges {
  @Input() selector: EntryElement;
  @Input() disabled: boolean;
  @Input() stretched: any;

  /**
   * constructor
   * @param buttonService ButtonService
   */
  constructor(private buttonService: ButtonService) {}

  get isDisabled() {
    if (this.selector.isDisabled() || this.disabled) {
      return true;
    }
    return false;
  }

  get value() {
    return this.selector.getValue<EntryElementValue>();
  }

  /**
   * on init lifecycle
   */
  ngOnInit() {}

  /**
   * handles click events for resource selector
   */
  onClick(event, selector) {
    this.buttonService.onClick(selector).pipe(take(1)).subscribe();
  }

  /**
   * on changes lifecycle
   */
  ngOnChanges() {}
}
