import { Observable, merge, of, switchMap } from 'rxjs';
import { GanttScrollContainerEvent } from '../html-structure/scroll-container-event';
import { EGanttScrollContainer } from '../html-structure/scroll-container.enum';
import { GanttYAxisContextMenuEvent } from '../y-axis/y-axis';
import { GanttShiftsAfterRenderEvent, ShiftBuilder } from './shift-builder';
import { IShiftClickEvent, IShiftDragEvent } from './shift-events.interface';

/**
 * Basic class defining all methods and properties a gantt shift builder facade should have.
 */
export abstract class GanttBasicShiftBuilderFacade {
  /**
   * Map containing a {@link ShiftBuilder} instance for each scroll container.
   */
  private readonly _shiftBuilderMap = new Map<EGanttScrollContainer, ShiftBuilder>();

  //
  // LIFE CYCLE
  //

  /**
   * Destroys this {@link GanttBasicShiftBuilderFacade}.
   * Destroys all current {@link ShiftBuilder} instances.
   */
  public destroy(): void {
    for (const scrollContainerId of this._shiftBuilderMap.keys()) {
      this._shiftBuilderMap.get(scrollContainerId).destroy();
    }
  }

  //
  // SHIFT BUILDER
  //

  /**
   * Adds a new {@link ShiftBuilder} instance to this {@link GanttBasicShiftBuilderFacade} for the specified scroll container.
   * @param scrollContainerId Id of the scroll container to add the new {@link ShiftBuilder} instance for.
   * @param shiftBuilder New {@link ShiftBuilder} instance.
   */
  protected _addShiftBuilder(scrollContainerId: EGanttScrollContainer, shiftBuilder: ShiftBuilder): void {
    this._shiftBuilderMap.set(scrollContainerId, shiftBuilder);
  }

  /**
   * Returns the current {@link ShiftBuilder} instance for the specified scroll container.
   * @param scrollContainerId Id of the scroll container to return the {@link ShiftBuilder} instance for.
   * @returns Current {@link ShiftBuilder} instance for the specified scroll container.
   */
  public getShiftBuilder(scrollContainerId = EGanttScrollContainer.DEFAULT): ShiftBuilder {
    return this._shiftBuilderMap.get(scrollContainerId);
  }

  //
  // SHIFT BUILDER CALLBACKS
  //

  /**
   * Adds the specified callback as `forceCanvasDoubleClick` callback to all current {@link ShiftBuilder} instances.
   * @param id Id of the callback to be added.
   * @param func Callback to be added.
   */
  public addForceCanvasDoubleClickCb(
    id: string,
    func: (event: GanttScrollContainerEvent<MouseEvent>) => boolean
  ): void {
    for (const scrollContainerId of this._shiftBuilderMap.keys()) {
      this.getShiftBuilder(scrollContainerId).addForceCanvasDoubleClickCb(id, (event) =>
        func(new GanttScrollContainerEvent(scrollContainerId, event))
      );
    }
  }

  /**
   * Removes the callback with the specified id from `forceCanvasDoubleClick` callbacks of all current {@link ShiftBuilder} instances.
   * @param id Id of the callback to be removed.
   */
  public removeForceCanvasDoubleClickCb(id: string): void {
    for (const scrollContainerId of this._shiftBuilderMap.keys()) {
      this.getShiftBuilder(scrollContainerId).removeForceCanvasDoubleClickCb(id);
    }
  }

  //
  // SHIFT BUILDER OBSERVABLES
  //

  /**
   * Provides the merged `onUpdate` Observable for all current {@link ShiftBuilder} instances.
   * @returns Merged `onUpdate` Observable for all current {@link ShiftBuilder} instances.
   */
  public onUpdate(): Observable<GanttScrollContainerEvent<any>> {
    return this._mergeObservables('onUpdate');
  }

  /**
   * Provides the merged `shiftMouseOver` Observable for all current {@link ShiftBuilder} instances.
   * @returns Merged `shiftMouseOver` Observable for all current {@link ShiftBuilder} instances.
   */
  public shiftMouseOver(): Observable<GanttScrollContainerEvent<MouseEvent>> {
    return this._mergeObservables('shiftMouseOver');
  }

  /**
   * Provides the merged `shiftMouseOut` Observable for all current {@link ShiftBuilder} instances.
   * @returns Merged `shiftMouseOut` Observable for all current {@link ShiftBuilder} instances.
   */
  public shiftMouseOut(): Observable<GanttScrollContainerEvent<MouseEvent>> {
    return this._mergeObservables('shiftMouseOut');
  }

  /**
   * Provides the merged `shiftDragStart` Observable for all current {@link ShiftBuilder} instances.
   * @returns Merged `shiftDragStart` Observable for all current {@link ShiftBuilder} instances.
   */
  public shiftDragStart(): Observable<GanttScrollContainerEvent<IShiftDragEvent>> {
    return this._mergeObservables('shiftDragStart');
  }

  /**
   * Provides the merged `shiftDragging` Observable for all current {@link ShiftBuilder} instances.
   * @returns Merged `shiftDragging` Observable for all current {@link ShiftBuilder} instances.
   */
  public shiftDragging(): Observable<GanttScrollContainerEvent<IShiftDragEvent>> {
    return this._mergeObservables('shiftDragging');
  }

  /**
   * Provides the merged `manipulateShiftDragEnd` Observable for all current {@link ShiftBuilder} instances.
   * @returns Merged `manipulateShiftDragEnd` Observable for all current {@link ShiftBuilder} instances.
   */
  public manipulateShiftDragEnd(): Observable<GanttScrollContainerEvent<IShiftDragEvent>> {
    return this._mergeObservables('manipulateShiftDragEnd');
  }

  /**
   * Provides the merged `shiftDragEnd` Observable for all current {@link ShiftBuilder} instances.
   * @returns Merged `shiftDragEnd` Observable for all current {@link ShiftBuilder} instances.
   */
  public shiftDragEnd(): Observable<GanttScrollContainerEvent<IShiftDragEvent>> {
    return this._mergeObservables('shiftDragEnd');
  }

  /**
   * Provides the merged `afterShiftRender` Observable for all current {@link ShiftBuilder} instances.
   * @returns Merged `afterShiftRender` Observable for all current {@link ShiftBuilder} instances.
   */
  public afterShiftRender(): Observable<GanttScrollContainerEvent<GanttShiftsAfterRenderEvent>> {
    return this._mergeObservables('afterShiftRender');
  }

  /**
   * Provides the merged `shiftOnMouseDown` Observable for all current {@link ShiftBuilder} instances.
   * @returns Merged `shiftOnMouseDown` Observable for all current {@link ShiftBuilder} instances.
   */
  public shiftOnMouseDown(): Observable<GanttScrollContainerEvent<d3.ClientPointEvent>> {
    return this._mergeObservables('shiftOnMouseDown');
  }

  /**
   * Provides the merged `shiftOnClickDirectly` Observable for all current {@link ShiftBuilder} instances.
   * @returns Merged `shiftOnClickDirectly` Observable for all current {@link ShiftBuilder} instances.
   */
  public shiftOnClickDirectly(priority = 0): Observable<GanttScrollContainerEvent<IShiftClickEvent>> {
    return this._mergeObservables('shiftOnClickDirectly', priority);
  }

  /**
   * Provides the merged `shiftOnClick` Observable for all current {@link ShiftBuilder} instances.
   * @returns Merged `shiftOnClick` Observable for all current {@link ShiftBuilder} instances.
   */
  public shiftOnClick(): Observable<GanttScrollContainerEvent<IShiftClickEvent>> {
    return this._mergeObservables('shiftOnClick');
  }

  /**
   * Provides the merged `shiftOnMiddleClick` Observable for all current {@link ShiftBuilder} instances.
   * @returns Merged `shiftOnMiddleClick` Observable for all current {@link ShiftBuilder} instances.
   */
  public shiftOnMiddleClick(): Observable<GanttScrollContainerEvent<d3.ClientPointEvent>> {
    return this._mergeObservables('shiftOnMiddleClick');
  }

  /**
   * Provides the merged `shiftAfterOnClick` Observable for all current {@link ShiftBuilder} instances.
   * @returns Merged `shiftAfterOnClick` Observable for all current {@link ShiftBuilder} instances.
   */
  public shiftAfterOnClick(): Observable<GanttScrollContainerEvent<IShiftClickEvent>> {
    return this._mergeObservables('shiftAfterOnClick');
  }

  /**
   * Provides the merged `shiftOnDoubleClick` Observable for all current {@link ShiftBuilder} instances.
   * @returns Merged `shiftOnDoubleClick` Observable for all current {@link ShiftBuilder} instances.
   */
  public shiftOnDoubleClick(): Observable<GanttScrollContainerEvent<IShiftClickEvent>> {
    return this._mergeObservables('shiftOnDoubleClick');
  }

  /**
   * Provides the merged `shiftAfterOnDoubleClick` Observable for all current {@link ShiftBuilder} instances.
   * @returns Merged `shiftAfterOnDoubleClick` Observable for all current {@link ShiftBuilder} instances.
   */
  public shiftAfterOnDoubleClick(): Observable<GanttScrollContainerEvent<IShiftClickEvent>> {
    return this._mergeObservables('shiftAfterOnDoubleClick');
  }

  /**
   * Provides the merged `shiftOnContextMenu` Observable for all current {@link ShiftBuilder} instances.
   * @returns Merged `shiftOnContextMenu` Observable for all current {@link ShiftBuilder} instances.
   */
  public shiftOnContextMenu(): Observable<GanttScrollContainerEvent<IShiftClickEvent>> {
    return this._mergeObservables('shiftOnContextMenu');
  }

  /**
   * Provides the merged `rowOnContextMenu` Observable for all current {@link ShiftBuilder} instances.
   * @returns Merged `rowOnContextMenu` Observable for all current {@link ShiftBuilder} instances.
   */
  public rowOnContextMenu(): Observable<GanttScrollContainerEvent<GanttYAxisContextMenuEvent>> {
    return this._mergeObservables('rowOnContextMenu');
  }

  /**
   * Provides the merged `blockedShiftDragStart` Observable for all current {@link ShiftBuilder} instances.
   * @returns Merged `blockedShiftDragStart` Observable for all current {@link ShiftBuilder} instances.
   */
  public blockedShiftDragStart(): Observable<GanttScrollContainerEvent<IShiftDragEvent>> {
    return this._mergeObservables('blockedShiftDragStart');
  }

  /**
   * Provides the merged `blockedShiftDragging` Observable for all current {@link ShiftBuilder} instances.
   * @returns Merged `blockedShiftDragging` Observable for all current {@link ShiftBuilder} instances.
   */
  public blockedShiftDragging(): Observable<GanttScrollContainerEvent<IShiftDragEvent>> {
    return this._mergeObservables('blockedShiftDragging');
  }

  /**
   * Provides the merged `blockedShiftDragEnd` Observable for all current {@link ShiftBuilder} instances.
   * @returns Merged `blockedShiftDragEnd` Observable for all current {@link ShiftBuilder} instances.
   */
  public blockedShiftDragEnd(): Observable<GanttScrollContainerEvent<IShiftDragEvent>> {
    return this._mergeObservables('blockedShiftDragEnd');
  }

  /**
   * Provides the merged `shiftAreaMouseMiss` Observable for all current {@link ShiftBuilder} instances.
   * @returns Merged `shiftAreaMouseMiss` Observable for all current {@link ShiftBuilder} instances.
   */
  public shiftAreaMouseMiss(): Observable<GanttScrollContainerEvent<MouseEvent>> {
    return this._mergeObservables('shiftAreaMouseMiss');
  }

  /**
   * Provides the merged `shiftMouseOverShiftOnCanvas` Observable for all current {@link ShiftBuilder} instances.
   * @returns Merged `shiftMouseOverShiftOnCanvas` Observable for all current {@link ShiftBuilder} instances.
   */
  public shiftMouseOverShiftOnCanvas(): Observable<GanttScrollContainerEvent<d3.ClientPointEvent>> {
    return this._mergeObservables('shiftMouseOverShiftOnCanvas');
  }

  /**
   * Provides the merged `enterCanvas` Observable for all current {@link ShiftBuilder} instances.
   * @returns Merged `enterCanvas` Observable for all current {@link ShiftBuilder} instances.
   */
  public enterCanvas(): Observable<GanttScrollContainerEvent<d3.ClientPointEvent>> {
    return this._mergeObservables('enterCanvas');
  }

  /**
   * Provides the merged `canvasMouseOver` Observable for all current {@link ShiftBuilder} instances.
   * @returns Merged `canvasMouseOver` Observable for all current {@link ShiftBuilder} instances.
   */
  public canvasMouseOver(): Observable<GanttScrollContainerEvent<MouseEvent>> {
    return this._mergeObservables('canvasMouseOver');
  }

  /**
   * Provides the merged `canvasDoubleClick` Observable for all current {@link ShiftBuilder} instances.
   * @returns Merged `canvasDoubleClick` Observable for all current {@link ShiftBuilder} instances.
   */
  public canvasDoubleClick(): Observable<GanttScrollContainerEvent<MouseEvent>> {
    return this._mergeObservables('canvasDoubleClick');
  }

  /**
   * Merges the {@link Observable}s with the specified key from all current {@link ShiftBuilder} instances into one.
   * @param key Key of the getter in {@link ShiftBuilder} which returns the desired {@link Observable}.
   * @param param Optional parameters to pass to the getter when calling it.
   * @returns Merged {@link Observable} with the specified key from all current {@link ShiftBuilder} instances.
   */
  private _mergeObservables<T>(key: string, param: unknown = undefined): Observable<GanttScrollContainerEvent<T>> {
    const observables: Observable<GanttScrollContainerEvent<T>>[] = [];
    for (const scrollContainerId of this._shiftBuilderMap.keys()) {
      observables.push(
        (this._shiftBuilderMap.get(scrollContainerId)[key](param) as Observable<T>).pipe(
          switchMap((event) => of(new GanttScrollContainerEvent<T>(scrollContainerId, event)))
        )
      );
    }
    return merge(...observables);
  }
}
