import { DataType } from '../base/base-template';
import { EntryCollection, mapEntryCollection } from '../entry-collection/entry-collection.mapper';
import { InputType } from '../mapper';

export interface Breadcrumbs extends EntryCollection<DataType.breadcrumbs> {
  type: DataType.breadcrumbs;
}

export const mapBreadcrumbs = <T extends DataType>(item: InputType<T>): Breadcrumbs => {
  const collection = mapEntryCollection(item);
  return collection as Breadcrumbs;
};
