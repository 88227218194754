import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MySharedModule } from 'frontend/src/dashboard/shared/shared.module';
import { ChartLegendAttributeComponent } from './chart-legend-attribute.component';
import { ChartAttributeLegendItemComponent } from './chart-attribute-legend-item/chart-attribute-legend-item.component';

@NgModule({
  imports: [CommonModule, FormsModule, MySharedModule],
  declarations: [ChartLegendAttributeComponent, ChartAttributeLegendItemComponent],
  exports: [ChartLegendAttributeComponent, ChartAttributeLegendItemComponent],
})
export class ChartAttributeLegendModule {}
