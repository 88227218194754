import { Injectable, Type } from '@angular/core';
import { TemplateAdapter } from '@app-modeleditor/utils/template-factory.service';
import { Adapter } from '@core/adapter';
import { ResourceAdapter } from './../../../../model/resource/resource-adapter.service';
import { AdjustTimeDifferenceLink } from './adjust-time-difference-link';
import { ALink } from './link';
import { ELinkType } from './link-type.enum';
import { ILink } from './link.interface';
import { MaxDurationWarningLink } from './max-duration-warning-link';
import { ShiftTimeLink } from './shift-time-link';
import { StartAndEndTimeLink } from './start-and-end-time-link';

@Injectable({
  providedIn: 'root',
})
export class LinkAdapter implements Adapter<ALink> {
  constructor(private _resourceAdapter: ResourceAdapter) {}

  adapt(item: ILink): ALink {
    return this._applyValues(item);
  }

  inherit<T extends ALink>(type: Type<T>, item: ILink): T {
    return this._applyValues(item) as T;
  }

  inheritFrom<T extends ALink>(scope: TemplateAdapter, type: Type<T>, item: ILink): T {
    return this._applyValues(item, scope) as T;
  }

  applyValues<T>(scope: TemplateAdapter, item: ILink, values: any): T {
    throw new Error('Method not implemented.');
  }

  private _applyValues(data: ILink, scope?: TemplateAdapter): ALink {
    let item;
    switch (data.type) {
      case ELinkType.START_AND_END_TIME:
        item = new StartAndEndTimeLink();
        item.setStartId(data.startId).setEndId(data.endId);
        break;
      case ELinkType.SHIFT_TIME:
        item = new ShiftTimeLink();
        item.setStartId(data.startId).setEndId(data.endId);
        break;
      case ELinkType.MAX_DURATION_WARNING:
        item = new MaxDurationWarningLink(data.startId, data.endId, data.value);
        break;
      case ELinkType.ADJUST_TIME_DIFFERENCE:
        item = new AdjustTimeDifferenceLink(data.startId, data.endId);
        break;
      default:
        throw new Error(`Link type ${data.type} not supported`);
    }

    return item;
  }
}
