<div class="wrapper" *ngIf="initialized">
  <ng-container *ngFor="let item of data">
    <app-legend-entry
      *ngIf="!item.noRender.length"
      [entryData]="item"
      [pointerInteraction]="pointerInteraction"
      (onClick)="click($event)"
      (onDblClick)="dblClick($event)"
    ></app-legend-entry>
  </ng-container>
</div>
