import { Button } from '../button/button';
import { ExecutionType } from './execution-type';

export class ContextMenuItem extends Button {
  private doubleClickAction: boolean;
  private executionType: ExecutionType;
  /**
   * Used to identify same context menu items in different context menus of entry elements.
   * This is used to execute the menu item button of all selected items.
   */
  private multiselectId: string;

  public isDoubleClickAction(): boolean {
    return typeof this.doubleClickAction === 'boolean' ? this.doubleClickAction : false;
  }

  public setDoubleClickAction(doubleClickAction: boolean): this {
    this.doubleClickAction = doubleClickAction;
    return this;
  }

  public getExecutionType(): ExecutionType {
    return this.executionType || ExecutionType.SINGLE;
  }

  public setExecutionType(executionType: ExecutionType): this {
    this.executionType = executionType;
    return this;
  }

  public getMultiselectId(): string {
    return this.multiselectId;
  }

  public setMultiselectId(multiselectId: string): this {
    this.multiselectId = multiselectId;
    return this;
  }
}
