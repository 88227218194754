import {
  TriggerEventFactory,
  TriggerEventFactoryItem,
} from 'frontend/src/dashboard/gantt/general/gantt-actions/event-factory';
import { EGanttActionEvent } from '../../../../gantt-actions/gantt-action-handling/gantt-action-event.enum';
import { BlockAddAfterEvent } from './block-add-after/block-add-after';
import { BlockAddBeforeEvent } from './block-add-before/block-add-before';

/**
 * Event factory which handles events emitted by button clicks.
 * Add more TriggerEventFactoryItems to extend event handling.
 */
export class TriggerEventBlockAddButtonFactory extends TriggerEventFactory {
  constructor(templateData: any) {
    super(
      templateData,
      [
        new TriggerEventFactoryItem(EGanttActionEvent.BLOCK_ADD_BEFORE, BlockAddBeforeEvent),
        new TriggerEventFactoryItem(EGanttActionEvent.BLOCK_ADD_AFTER, BlockAddAfterEvent),
      ],
      []
    );
  }
}
