<div class="scroll-wrapper" #wrapperContainer>
  <ng-container *ngIf="!forceRefresh">
    <cdk-virtual-scroll-viewport class="scrollport" scrollViewport itemSize="20" #virtualScroller>
      <div
        *cdkVirtualFor="let node of dataSource; trackBy: trackByFn"
        [id]="node.getId()"
        class="node example-tree-node"
        [style.width]="'calc(100% - ' + node.level * 24 + 'px)'"
        [style.padding-left]="node.level * 24 + 'px'"
      >
        <button
          *ngIf="node.isExpandable()"
          class="expand-toggle"
          [id]="node.id + '.toggle'"
          mat-icon-button
          (click)="onExpand($event, node)"
          [disabled]="!node.isExpandable()"
        >
          <mat-icon class="chevron">
            {{ database.treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}</mat-icon
          >
        </button>
        <template-slot [source]="node" (afterInit)="afterInitSlot($event, node)"></template-slot>
        <ng-container *ngFor="let _ of getLevel(node.level); let idx = index">
          <div [style.left]="idx * 24 + 'px'" class="expandable-line"></div>
        </ng-container>
      </div>
    </cdk-virtual-scroll-viewport>
  </ng-container>
</div>
