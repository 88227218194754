import { YAxisDataFinder } from '../../../data-handler/data-finder/yaxis-data-finder';
import { GanttCanvasRow, GanttDataRow } from '../../../data-handler/data-structure/data-structure';
import { GanttStickyRowStrategy } from './sticky-row-strategy.base';

/**
 * Gantt sticky row strategy that makes the first top parent row of the gantt diagram and all of its child rows sticky.
 */
export class GanttStickyFirstRowWithChildRowsStrategy extends GanttStickyRowStrategy {
  public init(): void {
    this._resetConfig();
  }

  public destroy(): void {
    this._resetConfig();
  }

  public getManipulatedYAxisDataSet(dataSet: GanttCanvasRow[]): GanttCanvasRow[] {
    const scrollTop = this._ganttDiagram.getNodeProportionsState().getScrollTopPosition() || 0;

    // if not scrolled -> no need for sticky rows
    if (scrollTop === 0) {
      this._removeAllStickyRows(dataSet);
      this._resetConfig();
      return dataSet;
    }

    // get the ids of all rows which have to be sticky (= all parent rows assigned to first parent row + all their child rows)
    const allStickyRowIds = this._getAllStickyRowIds(dataSet);

    // iterate through all rows and set their respective sticky flag
    let stickyRowHeightsCombined = 0;
    let isPreviousRowSticky = false;
    let firstNotStickyRow: GanttCanvasRow = undefined;
    const newStickyRows = new Set<GanttCanvasRow>();

    for (const row of dataSet) {
      if (allStickyRowIds.has(row.id)) {
        row.sticky = true;
        stickyRowHeightsCombined += row.height;
        newStickyRows.add(row);
        isPreviousRowSticky = true;
      } else {
        row.sticky = false;
        if (isPreviousRowSticky) firstNotStickyRow = row;
        isPreviousRowSticky = false;
      }
    }

    // if sticky rows are last rows
    //   -> no need for sticky rows
    if (!firstNotStickyRow) {
      for (const row of newStickyRows) row.sticky = false;
      this._resetConfig();
      return dataSet;
    }

    // check sticky rows container height limitations
    // max height
    const containerMaxHeightPx = this._getContainerMaxHeightPx(stickyRowHeightsCombined, firstNotStickyRow?.y);
    this._ganttDiagram.getConfig().setStickyRowsContainerMaxHeightPx(containerMaxHeightPx);

    // min height
    const containerMinHeightPx = this._getContainerMinHeightPx(stickyRowHeightsCombined, firstNotStickyRow?.y);
    this._ganttDiagram.getConfig().setStickyRowsContainerMinHeightPx(containerMinHeightPx);

    // return reference to manipulated dataset
    return dataSet;
  }

  /**
   * Determines which rows should be sticky be the given dataset.
   * Returns the ids of all rows which are assigned to the first parent row or are a child row of these rows.
   * @param dataSet Dataset to determine the sticky rows for.
   * @returns Ids of all rows which are assigned to the first parent row or are a child row of these rows.
   */
  private _getAllStickyRowIds(dataSet: GanttCanvasRow[]): Set<string> {
    const firstRow = YAxisDataFinder.getRowByYPosition(dataSet, 0);
    const allStickyRowIds = new Set<string>([firstRow.id]);
    const allStickyParentRowIds = new Set<string>([firstRow.id]);

    // get origin dataset
    const originDataSet = this._ganttDiagram.getDataHandler().getOriginDataset();

    // iterate over all parent rows
    for (const parentRow of originDataSet.ganttEntries) {
      // check if parent row is split clone of first parent row -> if so add it and all its child rows to sticky rows
      for (const rowId of allStickyParentRowIds) {
        if (parentRow.id === rowId || parentRow.id.startsWith(rowId + this._executer.getSplitPlugInId() || '')) {
          allStickyRowIds.add(parentRow.id);
          this._getAllChildRows(parentRow, allStickyRowIds);
          break;
        }
      }
    }

    return allStickyRowIds;
  }

  /**
   * Recursively adds the ids of all child rows of the given row to the given {@link Set<string>}.
   * @param row Row to get the ids of all child rows for.
   * @param childRowIdsRef {@link Set} to store the ids of all child rows in.
   */
  private _getAllChildRows(row: GanttDataRow, childRowIdsRef: Set<string>): void {
    if (!row.child || row.child.length <= 0) return;

    for (const childRow of row.child) {
      childRowIdsRef.add(childRow.id);
      this._getAllChildRows(childRow, childRowIdsRef);
    }
  }

  /**
   * Determines the maximum height of the sticky rows scroll container by the given values.
   * @param rowHeightsCombined Height of all sticky rows combined (in px).
   * @param firstNotStickyRowY Canvas data y position of the first row below all sticky rows (in px).
   * @returns Maximum height of the sticky rows scroll container determined by the given values (in px).
   */
  private _getContainerMaxHeightPx(rowHeightsCombined: number, firstNotStickyRowY: number = undefined): number {
    const shiftViewportHeight = this._ganttDiagram.getNodeProportionsState().getShiftViewPortProportions().height;
    const scrollTop = this._ganttDiagram.getNodeProportionsState().getScrollTopPosition();

    const containerMaxHeightRelative = this._ganttDiagram.getConfig().stickyRowsContainerMaxHeight();
    const containerMaxHeightRelativePx = containerMaxHeightRelative * shiftViewportHeight;

    const firstNotStickyRowViewportY = isNaN(firstNotStickyRowY) ? undefined : firstNotStickyRowY - scrollTop;

    // if sticky rows are higher than allowed AND there would be a gap between sticky rows container and first non-sticky row
    //   -> set max height to viewport y of first non-sticky row
    if (
      !isNaN(firstNotStickyRowViewportY) &&
      rowHeightsCombined > containerMaxHeightRelativePx &&
      firstNotStickyRowViewportY > containerMaxHeightRelativePx
    ) {
      this._ganttDiagram.getConfig().setStickyRowsAllowUnlimitedContainerHeight(true);
      return Math.max(Math.min(firstNotStickyRowViewportY, shiftViewportHeight), containerMaxHeightRelativePx);
    }
    // else
    //   -> use max height as specified
    this._ganttDiagram.getConfig().setStickyRowsAllowUnlimitedContainerHeight(false);
    return Math.min(rowHeightsCombined, shiftViewportHeight);
  }

  /**
   * Determines the minimum height of the sticky rows scroll container by the given values.
   * @param rowHeightsCombined Height of all sticky rows combined (in px).
   * @param firstNotStickyRowY Canvas data y position of the first row below all sticky rows (in px).
   * @returns Minimum height of the sticky rows scroll container determined by the given values (in px).
   */
  private _getContainerMinHeightPx(rowHeightsCombined: number, firstNotStickyRowY: number = undefined): number {
    const scrollTop = this._ganttDiagram.getNodeProportionsState().getScrollTopPosition();

    const firstNotStickyRowViepwortY = isNaN(firstNotStickyRowY) ? undefined : firstNotStickyRowY - scrollTop;

    // if there are sticky rows AND there could be a gap between sticky rows container and first non-sticky row when
    // resizing manually
    //   -> set min height to viewport y of first non-sticky row
    if (rowHeightsCombined > 0) {
      if (!isNaN(firstNotStickyRowViepwortY) && firstNotStickyRowViepwortY > 0) {
        return firstNotStickyRowViepwortY;
      }
    }

    // else
    //   -> set min height to disabled
    return 0;
  }

  /**
   * Resets the gantt config to the default values of this sticky row strategy.
   */
  private _resetConfig(): void {
    const config = this._ganttDiagram.getConfig();
    config.setStickyRowsAllowUnlimitedContainerHeight(false);
    config.setStickyRowsContainerMinHeightPx(0);
    config.setStickyRowsContainerMaxHeightPx(0);
    config.setStickyRowsContainerOptimalHeightPx(0);
  }
}
