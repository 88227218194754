export function millisecondsToDate(millis: number, removeOffset: boolean): Date {
  const date: Date = new Date(millis);
  return removeOffset ? removeTimezoneOffset(date) : date;
}

export function dateToMilliseconds(date: Date, addOffset: boolean): number {
  const newDate: Date = addOffset ? addTimezoneOffset(date) : date;
  return newDate.getTime();
}

function removeTimezoneOffset(date: Date): Date {
  const userTimezoneOffset = date.getTimezoneOffset() * 60000;
  const millis = date.getTime() + userTimezoneOffset;
  const newDate = new Date(millis);
  return newDate;
}

function addTimezoneOffset(date: Date): Date {
  const userTimezoneOffset = date.getTimezoneOffset() * 60000;
  const millis = date.getTime() - userTimezoneOffset;
  const newDate = new Date(millis);
  return newDate;
}

/**
 * Returns boolean if param date is valid
 * @param date
 */
export function isValidDate(date: Date): boolean {
  if (date) {
    // An invalid date object returns NaN for getTime() and NaN is the only
    // object not strictly equal to itself.
    return date.getTime() === date.getTime();
  } else {
    return false;
  }
}
