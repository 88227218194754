import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FileProgress } from './file-progress';
import { FileProgressService } from '../file-uploader/file-progress.service';

@Component({
  selector: 'app-file-progress',
  templateUrl: './file-progress.component.html',
  styleUrls: ['./file-progress.component.scss'],
})
export class FileProgressComponent implements OnInit {
  fileProgress: FileProgress;

  constructor(private _fileProgressApi: FileProgressService, private _cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    this._fileProgressApi.onFileProgressChanged().subscribe((fp: FileProgress) => {
      this.fileProgress = fp;
      this._cd.detectChanges();
    });
  }
}
