import { PatternType } from '../../pattern/pattern-type.enum';
import { EGanttStickyBlockType } from './data-structure-enums';
import { IGanttIconConfig } from './icon-config.interface';

/**
 * Description of input origin datastructure of BestGantt
 * @keywords origin, data, dataset, ganttdata, ganttentries, global
 * @interface
 */
export interface GanttDataContainer {
  id: string;
  minValue: Date;
  maxValue: Date;
  currentDate?: Date;
  labelXAxis: string;
  labelYAxis: string;
  title: string;
  maxCount: number;
  minStepWidth: number;
  gridRef: number;
  timeGrid?: string;
  globalMilestones: any[];
  ganttEntries: GanttDataRow[];
}

export interface GanttInputDataContainer extends GanttDataContainer {
  editAllowSettings: GanttEditPermissions;
  attributeMetadatas?: any;
}

/**
 * Description of row/child datastructure of BestGantt
 * @keywords origin, dataset, data, row, child, children, ganttentry, yaxis, y axis
 * @interface
 */
export interface GanttDataRow {
  id: string;
  name: string;
  milestones: GanttDataMilestone[];
  shifts: GanttDataShift[];
  child: GanttDataRow[];
  open: boolean;
  group: string;
  textColor?: string;
  color?: string;
  stroke?: string;
  tooltip?: string;
  associatedResource?: any;
  additionalData?: IGanttAdditionalRowData;
  allowedEntryTypes?: number[];
  indicatorColor?: string;
  subtitleElements?: string[];
  noRender?: string[];
  icon?: IGanttIconConfig;
  stickyChild?: boolean;
  sampleValues?: string[];
  startCellIndexForSampleValues?: number;
  originalResource?: string;
  notHideable?: boolean;
}

/**
 * Description of shift datastructure of BestGantt
 * @keywords origin, dataset, data, shift
 * @interface
 */
export interface GanttDataShift {
  id: string;
  name: string;
  readonly originName: string;
  timePointStart: Date;
  timePointEnd: Date;
  tooltip: string;
  color: string;
  firstColor: string;
  secondColor: string;
  strokePattern?: string;
  strokeColor?: string;
  pattern?: PatternType;
  patternColor?: string;
  noRender: string[];
  highlighted: string;
  selected?: string;
  editable: boolean;
  additionalData: any;
  modificationRestriction: IGanttModificationRestriction;
  entryTypes?: number[];
  blockTypes?: number[];
  opacity: number;
  weaken: string[];
  disableMove?: boolean;
  disableColorization?: boolean;
  stickyBlockType?: EGanttStickyBlockType;
  linkedParentBlock?: string;
  strokeWidth?: number;
  isFullHeight?: boolean;
  noRoundedCorners?: boolean;
  symbols: string[];
}

/**
 * Description of milestone datastructure of BestGantt
 * @keywords origin, global, dataset, data, milestone
 * @interface
 */
export interface GanttDataMilestone {
  id: string;
  timePoint: Date;
  tooltip: string;
  color?: string;
}

export interface IGanttAdditionalRowData {
  additionalData: IGanttAdditionalDetails;
  originalRowId: string;
  canonicalName: string;
  brokenConstraints: IGanttBrokenConstraints;
  attributes: IGanttAdditionalRowAttribute[];
}

/**
 * Backend additional details
 */
export interface IGanttAdditionalDetails {
  [index: number]: {
    t1: boolean;
    t2: string | number;
  };
}

export interface IGanttAdditionalRowAttribute {
  key: string;
  value: string;
  showInTooltip: boolean;
}

/**
 * Backend broken constraints
 */
export interface IGanttBrokenConstraints {
  [index: string]: {
    t1: boolean;
    t2: string;
    t3?: string;
  };
}

export interface IGanttModificationRestriction {
  allowedGanttEntries?: any[];
  created?: number;
  earliestStartTime?: number;
  latestEndTime?: number;
  free_movement?: boolean; // specifies if shift can be moved outside earliest start/latest end
  edit_allow_add?: boolean;
  edit_allow_changeRow?: boolean;
  edit_allow_delete?: boolean;
  edit_allow_move?: boolean;
  edit_allow_resize?: boolean; // specifies if shift can be resized in general
  edit_allow_resize_left?: boolean; // specifies if shift can be resized on its left side (overwrites edit_allow_resize)
  edit_allow_resize_right?: boolean; // specifies if shift can be resized on its right side (overwrites edit_allow_resize)
  restrict_resize_left_from?: number;
  restrict_resize_left_to?: number;
  restrict_resize_right_from?: number;
  restrict_resize_right_to?: number;
  editable?: boolean;
  restrictedRowModification?: any;
  edit_allow_chain?: boolean;
  edit_allow_row_drag?: boolean;
  edit_allow_clicks?: boolean;
}

/**
 * Gantt permissions to forbid user interactions.
 */
export class GanttEditPermissions {
  // if false, no edit function will be allowed
  editable? = false;
  // blocks can be added to gantt (not used yet)
  edit_allow_add? = false;
  // blocks can be dragged vertically
  edit_allow_changeRow? = false;
  // blocks can be deleted (not used yet)
  edit_allow_delete? = false;
  // blocks can be dragged horizontally
  edit_allow_move? = false;
  // blocks can be resized
  edit_allow_resize? = false;
  // blocks can be chained (not used yet)
  edit_allow_chain? = false;
  // rows can be dragged vertically
  edit_allow_row_drag? = false;
  // rows can be dragged vertically
  edit_allow_clicks? = false;
}

/**
 * Description of canvas row/child datastructure of BestGantt
 * @keywords canvas, dataset, data, row, child, children, ganttentry, yaxis, y axis
 * @interface
 */
export interface GanttCanvasRow {
  id: string;
  y: number;
  height: number;
  text: string;
  subtitleElements: any[];
  symbols?: any[];
  layer: number;
  open?: boolean;
  leaf: boolean;
  color: string;
  group: string;
  textColor: string;
  tooltip: string;
  noRender: string[];
  allowedEntryTypes: any[];
  icon?: IGanttIconConfig;
  indicatorColor?: string;
  sampleValues: any[];
  startCellIndexForSampleValues: number;
  stroke: string;
  originalResource?: any;
  stickyChild?: boolean;
  sticky?: boolean;
}

/**
 * Description of canvas shift datastructure of BestGantt
 * @keywords canvas, dataset, data, shift
 * @interface
 */
export interface GanttCanvasShift {
  id: string;
  x: number;
  y: number;
  width: number;
  height: number;
  originHeight?: number; // used for height manipulation
  layer: number;
  color: string;
  firstColor: string;
  secondColor: string;
  highlighted: string;
  selected: string;
  name: string;
  label?: { labelHeight?: number; contentHeight?: number; lineHeight?: number };
  symbols: string[];
  noRender: string[];
  strokeColor: string;
  strokePattern: string;
  pattern: PatternType;
  patternColor: string;
  entryTypes: number[];
  blockTypes: number[];
  opacity: number;
  weaken: string[];
  disableMove: boolean;
  strokeWidth?: number;
  editable: boolean;
  isFullHeight: boolean;
  noRoundedCorners: boolean;
  modificationRestriction?: IGanttModificationRestriction;
  tooltip: string;
  renderPriority: number;
}

/**
 * Description of canvas milestone datastructure of BestGantt
 * @keywords canvas, dataset, data, milestone, marker
 * @interface
 */
export interface GanttCanvasMilestone {
  id: string;
  x: number;
  y: number;
  color: string;
  layer: number;
}
