/**
 * Calculator for HSLColor schema.
 */
export class HSLColor {
  private h: number;
  private s: number;
  private l: number;

  constructor(h: number, s: number, l: number) {
    this.h = h / 360;
    this.s = s / 100;
    this.l = l / 100;
  }

  /**
   * Converts HSL-Value to hex value.
   * @param c
   */
  private componentToHex(c: number) {
    const hex = c.toString(16);
    return hex.length == 1 ? '0' + hex : hex;
  }

  /**
   * Converts given rgb values to one hex value.
   * @param r Red channel.
   * @param g Green channel.
   * @param b Blue channel.
   */
  private rgbToHex(r: number, g: number, b: number): string {
    return '#' + this.componentToHex(r) + this.componentToHex(g) + this.componentToHex(b);
  }

  /**
   * Converts an HSL color value to RGB. Conversion formula
   * adapted from http://en.wikipedia.org/wiki/HSL_color_space.
   * Assumes h, s, and l are contained in the set [0, 1] and
   * returns r, g, and b in the set [0, 255].
   *
   * @param   {number}  h       The hue
   * @param   {number}  s       The saturation
   * @param   {number}  l       The lightness
   * @return  {Array}           The RGB representation
   */
  public toRgb(): number[] {
    const h = this.h;
    const s = this.s;
    const l = this.l;

    let r, g, b;

    if (s == 0) {
      r = g = b = l; // achromatic
    } else {
      const hue2rgb = function hue2rgb(p, q, t) {
        if (t < 0) t += 1;
        if (t > 1) t -= 1;
        if (t < 1 / 6) return p + (q - p) * 6 * t;
        if (t < 1 / 2) return q;
        if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
        return p;
      };

      const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      const p = 2 * l - q;
      r = hue2rgb(p, q, h + 1 / 3);
      g = hue2rgb(p, q, h);
      b = hue2rgb(p, q, h - 1 / 3);
    }

    return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
  }

  /**
   * Provides HSL value as hex value.
   */
  public toHex(): string {
    const rgb = this.toRgb();
    return this.rgbToHex(rgb[0], rgb[1], rgb[2]);
  }
}
