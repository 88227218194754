import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StepUpdateItem } from '@app-nodenetwork/edit/node-network-server-save/new-objects';
import { Observable } from 'rxjs';

/**
 * Service which provides backend information for different node editing.
 */
@Injectable()
export class NodeNetworkEditService {
  public uri = 'rest/nodenetwork';

  constructor(public http: HttpClient) {}

  /**
   * Function to get all available node types which can be added to the diagram.
   */
  public getSteps(): Observable<any> {
    return this.http.get(`` + this.uri + '/steps');
  }

  /**
   * Extracts subtypes by canonical name.
   * Usefull to show them in the dialog to create a new node.
   * @param canonicalName
   */
  public getStepSubtypes(canonicalName: string): Observable<any> {
    return this.http.get(`` + this.uri + '/steps/' + canonicalName);
  }

  /**
   * Downloads all available step processes.
   * Usefull to show them in the dialog to create a new node.
   */
  public getStepProcesses(canonicalName: string): Observable<any> {
    return this.http.get(`` + this.uri + '/processes/step/' + canonicalName);
  }

  /**
   * Posts given (changed) workflow.
   * @param workflowId Id of affected workflow.
   * @param newElement List of front end events which changed the node network structure.
   */
  public postWorkflow(workflowId: string, newElement: StepUpdateItem[]): Observable<any> {
    return this.http.post(`` + this.uri + '/workflow/' + workflowId + '/update', newElement);
  }
}
