import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatLegacyButton as MatButton } from '@angular/material/legacy-button';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Action } from '@app-modeleditor/components/button/action/action';
import { ButtonService } from '@app-modeleditor/components/button/button.service';
import { ContextMenuItem } from '@app-modeleditor/components/contextmenu/context-menu-item';
import { ContextMenu } from '@app-modeleditor/components/contextmenu/contextmenu';
import { Lightbox } from '@app-modeleditor/components/lightbox/lightbox';
import { LightboxComponent } from '@app-modeleditor/components/lightbox/lightbox.component';
import { HierarchicalMenuItem } from '@app-modeleditor/components/template-ui/hierarchical-menu-item';
import { UiService } from '@app-modeleditor/ui.service';
import { TemplateService } from '@app-modeleditor/utils/template.service';
import { ConfigService } from '@core/config/config.service';
import { ETemplateType } from 'frontend/src/dashboard/model/resource/template-type';
import { Observable, of, Subject } from 'rxjs';
import { map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { IContextmenuActions } from '../contextmenu-actions.interface';
import { EDefaultContextMenuItem } from '../default-context-menu-item.enum';
import { TemplateTreeService } from '../tree.service';
import { TemplateAdapter } from './../../../utils/template-factory.service';
import { ContextmenuService } from './../../contextmenu/contextmenu.service';
import { LightboxService } from './../../lightbox/lightbox.service';
import { HMI } from './../tree-item';
@Component({
  selector: 'template-tree-node',
  templateUrl: './tree-node.component.html',
  // changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./tree-node.component.scss'],
})
export class TreeNodeComponent implements OnDestroy, AfterViewInit, OnInit, OnChanges {
  @Input() node: HMI;
  @Input() selected: boolean;
  @Input() mode: 'tree';
  @Input() id: string;
  private textContainer: ElementRef;
  @ViewChild('textContainer') set _textContainer(t: ElementRef) {
    this.textContainer = t;
  }
  @ViewChild('innerNodeWrapper') innerNodeWrapper: ElementRef;
  filterContainerWidth = 0;
  nodeContainerWidth = 200;

  @Output() afterRemoveFilter: EventEmitter<any> = new EventEmitter();
  @ViewChild('treeNodeButton') treeNodeButtonElement: MatButton;

  ngOnInit(): void {
    this.classList = this.node.getClassList().concat(this.node.getType());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.node) {
      this.classList = this.node.getClassList().concat(this.node.getType());
    }
    if (changes.node && this.textContainer) {
    }
  }

  hasPendingActions$: Observable<boolean> = this.actionExecuterApi
    .getPendingButtons()
    .pipe(
      map((item) =>
        this.configService.access().experimental?.switchingContextWhileExecutingActions === false
          ? item?.length > 0
          : false
      )
    );

  private _applyWrapperWidth() {
    if (this.treeNodeButtonElement) {
      this.nodeContainerWidth =
        this.filterContainerWidth + this.treeNodeButtonElement._elementRef.nativeElement.clientWidth + 10;
    } else {
      this.nodeContainerWidth = 200;
    }

    if (this.innerNodeWrapper) {
      // this.innerNodeWrapper.nativeElement.style.width = '100%';
      // this.innerNodeWrapper.nativeElement.style.width = this.mode !== 'tree' ? '100%' : `${this.nodeContainerWidth}px`;
    }
    return this.nodeContainerWidth;
  }

  _templateTreeService: TemplateTreeService;

  private ngUnsubscribe: Subject<void> = new Subject<void>();
  contextmenuActions: IContextmenuActions = {
    create: false,
    edit: false,
    duplicate: false,
    delete: false,
  };

  constructor(
    public templateTreeService: TemplateTreeService,
    private uiService: UiService,
    private templateFactory: TemplateAdapter,
    private lightboxApi: LightboxService,
    private contextMenuApi: ContextmenuService,
    private actionExecuterApi: ButtonService,
    private configService: ConfigService,
    private templateService: TemplateService
  ) {
    this.templateTreeService.onNotificationCount.pipe(takeUntil(this.ngUnsubscribe)).subscribe((match) => {
      if (this.node.getId() === match.id) {
        this.node.setNotificationsCount(match.count);
      }
    });
  }
  private alive: boolean;

  dateWidth = 0;
  classList: string[] = [];

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.alive = false;
  }

  ngAfterViewInit(): void {
    this.alive = true;
    this._applyWrapperWidth();

    this.templateTreeService
      .onTreeResized()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this._applyWrapperWidth();
      });
  }

  private _findItem(ctx: ContextMenu, node: HMI, id: EDefaultContextMenuItem): boolean {
    const parent = this.templateService.getElementById<HMI>(node.getParentId());

    return parent?.isInvisibleDefaultContextMenuItem(id) ||
      node.isInvisibleDefaultContextMenuItem(id) ||
      ctx.getContextMenuItems().find((item: ContextMenuItem) => item.getId() === `CONTEXTMENU.ITEM.PREDEFINED.${id}`)
      ? true
      : false;
  }

  public getContextmenu(node: HMI): ContextMenu {
    const ctx: ContextMenu = node.getContextmenu() || new ContextMenu();

    // DELETE
    if (!this._findItem(ctx, node, EDefaultContextMenuItem.DELETE) && node.getDeleteRestUrl()) {
      ctx.addContextMenuItems(
        new ContextMenuItem()
          .setId(`CONTEXTMENU.ITEM.PREDEFINED.${EDefaultContextMenuItem.DELETE}`)
          .setName('BUTTON.delete')
          .setIcon('delete')
          .chainActions(
            new Action().setCb(() => this.deleteElement(node)),
            new Action().setCb(() => of(ctx.getRef().dispose()))
          )
      );
    }

    const foundStart = this._findItem(ctx, node, EDefaultContextMenuItem.CREATE);
    // CREATE
    if (
      (!this._findItem(ctx, node, EDefaultContextMenuItem.CREATE) && node.getCreateRestUrls()) ||
      node.getCreateWizardRestUrls()
    ) {
      ctx.addContextMenuItems(
        new ContextMenuItem()
          .setId(`CONTEXTMENU.ITEM.PREDEFINED.${EDefaultContextMenuItem.CREATE}`)
          .setIcon('add')
          .setName('BUTTON.create')
          .chainActions(
            new Action().setCb(() => this.createElement(node)),
            new Action().setCb(() => of(ctx.getRef().dispose()))
          )
      );
    }

    // EDIT
    if (!this._findItem(ctx, node, EDefaultContextMenuItem.EDIT) && node.getEditRestUrl()) {
      ctx.addContextMenuItems(
        new ContextMenuItem()
          .setId(`CONTEXTMENU.ITEM.PREDEFINED.${EDefaultContextMenuItem.EDIT}`)
          .setIcon('edit')
          .setName('BUTTON.edit')
          .chainActions(new Action().setCb(() => of(ctx.getRef().dispose())))
      );
    }

    // COPY
    if (!this._findItem(ctx, node, EDefaultContextMenuItem.CLONE) && node.getCloneRestUrl()) {
      ctx.addContextMenuItems(
        new ContextMenuItem()
          .setId(`CONTEXTMENU.ITEM.PREDEFINED.${EDefaultContextMenuItem.CLONE}`)
          .setIcon('filter_none')
          .setName('BUTTON.copy')
          .chainActions(
            new Action().setCb(() => this.duplicateElement(node)),
            new Action().setCb(() => of(ctx.getRef().dispose()))
          )
      );
    }

    // FULLSCREEN
    if (!this._findItem(ctx, node, EDefaultContextMenuItem.FULL_SCREEN)) {
      ctx.addContextMenuItems(
        new ContextMenuItem()
          .setId(`CONTEXTMENU.ITEM.PREDEFINED.${EDefaultContextMenuItem.FULL_SCREEN}`)
          .setIcon('fullscreen')
          .setName('BUTTON.open_fullscreen')
          .setDisabled(node.getType() !== ETemplateType.MENU_ITEM && !node.getContent())
          .chainActions(
            new Action().setCb(() => this.openFullscreen(node)),
            new Action().setCb(() => of(ctx.getRef().dispose()))
          )
      );
    }

    // NEW TAB
    if (
      !this._findItem(ctx, node, EDefaultContextMenuItem.OPEN_NEW_TAB) &&
      this.configService.access().allowExternalWindows
    ) {
      ctx.addContextMenuItems(
        new ContextMenuItem()
          .setId(`CONTEXTMENU.ITEM.PREDEFINED.${EDefaultContextMenuItem.OPEN_NEW_TAB}`)
          .setIcon('open_in_browser')
          .setName('BUTTON.open_new_tab')
          .setDisabled(node.getType() !== ETemplateType.MENU_ITEM && !node.getContent())
          .chainActions(
            new Action().setCb(() => this.openExternalWindow(node, 'tab')),
            new Action().setCb(() => of(ctx.getRef().dispose()))
          )
      );
    }

    // NEW WINDOW
    if (
      !this._findItem(ctx, node, EDefaultContextMenuItem.OPEN_NEW_WINDOW) &&
      this.configService.access().allowExternalWindows
    ) {
      ctx.addContextMenuItems(
        new ContextMenuItem()
          .setId(`CONTEXTMENU.ITEM.PREDEFINED.${EDefaultContextMenuItem.OPEN_NEW_WINDOW}`)
          .setIcon('open_in_new')
          .setName('BUTTON.open_window')
          .setDisabled(node.getType() !== ETemplateType.MENU_ITEM && !node.getContent())
          .chainActions(
            new Action().setCb(() => this.openExternalWindow(node, 'window')),
            new Action().setCb(() => of(ctx.getRef().dispose()))
          )
      );
    }

    // apply additional items
    ctx.addContextMenuItems(
      ...node.getAdditionalContextMenuItems().filter((item: ContextMenuItem) => {
        return ctx.getContextMenuItems().find((_item: ContextMenuItem) => _item.getId() === item.getId())
          ? false
          : true;
      })
    );
    return ctx;
  }

  onContextMenu(event: MouseEvent, node: HMI): void {
    // this.handleRightClick(node);
    const m = this.getContextmenu(node);
    if (m.getContextMenuItems().length > 0) {
      event.preventDefault();
      this.contextMenuApi.create(event, m);
    }
    return;
  }

  /**
   * handles click event
   * @param event Event
   * @param node IHierarchicMenuItem
   * @param menu MatMenuTrigger
   */
  onItemClick(event: PointerEvent, node: HMI): void {
    if (
      this.configService.access().allowExternalWindows &&
      !(node.getType() !== ETemplateType.MENU_ITEM && !node.getContent())
    ) {
      if (
        event.ctrlKey &&
        this.getContextmenu(node)
          .getContextMenuItems()
          .find(
            (i: ContextMenuItem) => i.getId() === `CONTEXTMENU.ITEM.PREDEFINED.${EDefaultContextMenuItem.OPEN_NEW_TAB}`
          )
      ) {
        this.openExternalWindow(node, 'tab').subscribe();
        return;
      } else if (
        event.altKey &&
        this.getContextmenu(node)
          .getContextMenuItems()
          .find(
            (i: ContextMenuItem) =>
              i.getId() === `CONTEXTMENU.ITEM.PREDEFINED.${EDefaultContextMenuItem.OPEN_NEW_WINDOW}`
          )
      ) {
        this.openExternalWindow(node, 'window').subscribe();
        return;
      }
    }

    this.templateTreeService.forceClick(node);
  }

  /**
   * open template node in a fullscreen dialog
   * @param node Node
   */
  openFullscreen(node: HMI): Observable<any> {
    // only for testing
    // node.content.contentParts[0].displayContentpartContainer = false;
    // node.content.contentParts[0].enableFullscreen = true;
    return this._beforeClick(node).pipe(
      switchMap(() => {
        return (!node.getRestUrl() ? of(null) : this.uiService.getFromUrl(node.getRestUrl())).pipe(
          tap((template) => {
            this.templateTreeService.forceClick();
            /**
              const action = new NewAction().setTemplateHandlingType(ETemplateHandlingType.TEMPLATE_LIGHTBOX);
              action.setActionUrl(ERequestMethod.GET).setActionType(EActionType.GET_CALL).setActionUrl(node.restUrl);
        */
            // (template || node).type = 'LightBox';
            let lightbox: Lightbox;
            if (template && !Array.isArray(template)) {
              template.type = 'LightBox';
              lightbox = this.templateFactory.adapt(template);
            } else {
              lightbox = node.copy(Lightbox);
              lightbox.setType(ETemplateType.LIGHTBOX);
            }
            lightbox.setFullscreen(true);

            if (this.configService.access()?.templates?.Tree?.disableFullscreenDefaultButtons) {
              lightbox.setDisableCancelButton(true);
              lightbox.setDisableSaveButton(true);
            }

            const dialog: MatDialogRef<LightboxComponent> = this.lightboxApi.open(lightbox);

            // set active menu item for fullscreen mode
            if (node.getType() === ETemplateType.MENU_ITEM) {
              this.templateService.setActiveMenuItem(node as HierarchicalMenuItem);

              dialog
                .afterClosed()
                .pipe(take(1))
                .subscribe(() => {
                  this.templateService.removeActiveMenuItem(node.getId());
                });
            }

            // this.actionApi.executeAction(action).subscribe();
            // this.tDialogService.openDialog(template || node, { fullscreen: true });
          })
        );
      })
    );
  }

  createElement(node: HMI): Observable<any> {
    return this.templateTreeService.createElement(node as any);
  }

  closeCtx(ctx: ContextMenu): Observable<any> {
    return of(ctx.getRef().dispose());
  }

  duplicateElement(node: HMI): Observable<any> {
    return this.templateTreeService.duplicateElement(node);
  }

  deleteElement(node: HMI): Observable<any> {
    return this.templateTreeService.deleteElement(node as any);
  }

  private _beforeClick(node): Observable<any> {
    if (!node || !(node instanceof HMI)) {
      return of(null);
    }

    // node.getPreDeliveryActions().forEach(item => item.setActionUrl(GlobalUtils.transformUrl(node, item.getActionUrl())));
    // return this.actionExecuterApi.executeActions(node.getPreDeliveryActions()).pipe(catchError((e) => {
    //   return throwError(e);
    // }));
    return this.actionExecuterApi.executeActions(node.getPreDeliveryActions());
  }

  openExternalWindow(node: HMI, type: 'tab' | 'window' = 'window'): Observable<any> {
    return this._beforeClick(node).pipe(
      switchMap(() => {
        // set active menu item for external window mode
        this.templateService.setActiveMenuItem(node as HierarchicalMenuItem);
        return of(this.templateTreeService.openExternalWindow(node as any, type));
      })
    );
  }

  afterCalcFilterContainerWidth(event): void {
    this.filterContainerWidth = event;
    // this.getWrapperWidth();
  }

  protected get maxNotificationCount(): number {
    return this.configService.access()?.templates?.Tree?.maxNotificationCount || 9;
  }
}
