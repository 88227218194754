/**
 * Container class for gantt shift config.
 */
export class GanttShiftConfig {
  public readonly shiftProportions = new GanttShiftProportions();
  public readonly shiftBuilding = new GanttShiftBuildConfig();
  public readonly shiftDragging = new GanttShiftDragConfig();
  public readonly shiftPatterns = new GanttShiftPatternConfig();
}

/**
 * Shift style settings. Height of shift is the difference of row height and top- and bottom margin of shift.
 * @keywords config, shift, data, proportion, margin
 * @param top Distance on top between vertical row start and vertical shift position.
 * @param bottom Distance on bottom between vertical row start and vertical shift position.
 * @param minTimespan Time span a shift at least must have (in ms).
 * @param maxTimespan Time span that a shift have at most (in ms).
 * @param minRemainderTimespan Minimum shift timespan which should always be visible during shift editing.
 * @param minRenderWidth Minimum width a rendered shift can have.
 */
export class GanttShiftProportions {
  public top = 5;
  public bottom = 6;
  public minTimespan = 60000;
  public maxTimespan: number = null;
  public minRemainderTimespan = 60000;
  public minRenderWidth = 1;
}

/**
 * Container class for shift build settings.
 */
export class GanttShiftBuildConfig {
  /**
   * Decides if shifts have rounded corners.
   * @keywords allow, forbid, restriction, shift, rect, round, corners
   */
  public roundedCorners = false;
  /**
   * Decides with which color the shifts will be colored.
   */
  public colorMode = EGanttShiftColorMode.SHOW_DEFAULT_COLOR;
  public showShiftContent = false;
}

/**
 * Container class for shift pattern settings.
 */
export class GanttShiftPatternConfig {
  /**
   * Contains all settings related to strokes inside shift patterns.
   */
  public readonly patternStrokes = new GanttShiftPatternStrokeConfig();
}

export class GanttShiftPatternStrokeConfig {
  /**
   * Decides if shift pattern strokes are enabled and rendered or not.
   */
  public isStrokesEnabled = true;
  /**
   * Decides until which contrast value patterns containing similar colors will be rendered with strokes.
   */
  public strokeContrastThreshold = 1.35;
  /**
   * Stroke width of shift patterns.
   */
  public strokeWidth = 0.85;
  /**
   * Stroke color used for patterns with dark colors.
   */
  public strokeColorBright = '#ffffff';
  /**
   * Stroke color used for patterns with bright colors.
   * This color will only be used if {@link GanttShiftPatternStrokeConfig.strokeColorBright} cannot be used.
   */
  public strokeColorDark = '#000000';
}

/**
 * Container class for shift drag settings.
 */
export class GanttShiftDragConfig {
  public dragStartTolerance = 2;
}

export enum EGanttShiftColorMode {
  /**
   * Shifts will be colored by "color" attribute.
   */
  SHOW_DEFAULT_COLOR = 'SHOW_DEFAULT_COLOR',
  /**
   * Shifts will be colored by "firstColor" attribute.
   */
  SHOW_FIRST_COLOR = 'SHOW_FIRST_COLOR',
  /**
   * Shifts will be colored by "secondColor" attribute.
   */
  SHOW_SECOND_COLOR = 'SHOW_SECOND_COLOR',
  /**
   * Shifts will be colored by "color" AND "secondColor" attribute.
   */
  SHOW_MULTIPLE_COLORS = 'SHOW_MULTIPLE_COLORS',
}
