import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class PasswordService {
  public change = 'rest/user/changepassword/';
  public reset = 'rest/user/changepassword/reset';

  constructor(public http: HttpClient) {}

  changePassword(oldPassword: string, newPassword: string): Observable<any> {
    return this.http.post(`${this.change}`, { oldPassword, newPassword });
  }

  resetPassword(): Observable<any> {
    return this.http.post(`${this.reset}`, {});
  }
}
