import { Component, ElementRef, HostListener, Inject } from '@angular/core';
import { CONTAINER_DATA } from '@app-modeleditor/components/lightbox/overlay/container-data';
import { DefaultOverlayContainer } from '@app-modeleditor/components/lightbox/overlay/default-overlay-container';
import { TemplateService } from '@app-modeleditor/utils/template.service';
import { Template } from 'frontend/src/dashboard/model/resource/template';
import { ETemplateType } from 'frontend/src/dashboard/model/resource/template-type';
import { forkJoin } from 'rxjs';
import { take } from 'rxjs/operators';
import { Button } from '../button/button';
import { ButtonService } from '../button/button.service';
import { EntryCollection } from '../entry-collection/entry-collection';
import { EntryElement } from '../entry-collection/entry-element';
import { ContextMenuItem } from './context-menu-item';
import { ContextMenu } from './contextmenu';
import { ExecutionType } from './execution-type';

@Component({
  selector: 'contextmenu',
  templateUrl: './contextmenu.component.html',
  styleUrls: ['./contextmenu.component.scss'],
})
export class ContextMenuComponent extends DefaultOverlayContainer<any> {
  constructor(
    @Inject(CONTAINER_DATA) public menu: ContextMenu,
    private el: ElementRef,
    private templateService: TemplateService,
    private buttonService: ButtonService
  ) {
    super();
  }

  @HostListener('window:mousedown', ['$event'])
  onMouseDown(event: MouseEvent): void {
    if (this.el.nativeElement.contains(event.target)) {
      return;
    }

    switch (event.button) {
      case 0:
      case 2:
        this.menu.getRef().dispose();
        break;
    }
  }

  /**
   * Checks if the button should be visible by checking the visibility conditions
   * @param btn element to check
   * @returns true if the button should be visible
   */
  protected checkVisibility(btn: Button): boolean {
    if (btn instanceof Template && btn.getVisibilityConditions()) {
      return this.templateService.checkCondition(btn, 'visibilityConditions');
    }

    return true;
  }

  /**
   * Handles the click event for a context menu item.
   * @param event - The mouse event that triggered the click.
   * @param item - The context menu item that was clicked.
   */
  protected handleItemClick(event: MouseEvent, item: ContextMenuItem): void {
    // multi execution
    if (item.getExecutionType() === ExecutionType.MULTI && item.getMultiselectId()) {
      this.handleMultiExecution(item);
    }

    this.close(event);
  }

  /**
   * Executes the context menu item action for multiple selected entries.
   * @param item The context menu item to execute.
   * @returns void
   */
  private handleMultiExecution(item: ContextMenuItem): void {
    // we are looking for the entry collection and the entry of the item to search for the selected entries in this scope
    const { entryCollection, entry } = this.getEntryCollectionOfItem(item);

    // early return if we can't find the entry collection or the entry
    if (!entryCollection || !entry) {
      return;
    }

    // get the selected entries of the entry collection and ignore the current entry
    const selectedEntryIds = (entryCollection.selectedEntries || []).filter((entryId) => entryId !== entry.getId());

    // early return if there are no selected entries
    if (!selectedEntryIds.length) {
      return;
    }

    // get the selected entries
    const selectedEntries = entryCollection
      .getEntryElements()
      .filter((entry) => selectedEntryIds.includes(entry.getId()));

    // early return if there are no selected entries
    if (!selectedEntries.length) {
      return;
    }

    // find the context menu items of the entries
    const contextMenuItems = selectedEntries
      .map((entry) => entry.getContextmenu && entry.getContextmenu().getContextMenuItems())
      .flat()
      .filter((contextMenuItem) => contextMenuItem.getMultiselectId() === item.getMultiselectId()); // TODO: check for multiselect id

    // early return if there are no context menu items
    if (!contextMenuItems.length) {
      return;
    }

    // execute the context menu items (simulates a click)
    const onClicks = contextMenuItems.map((item) => this.buttonService.onClick(item));
    forkJoin(onClicks).pipe(take(1)).subscribe();
  }

  /**
   * Returns the entry collection and entry element of the given context menu item.
   * @param item The context menu item to search for.
   * @returns An object containing the entry collection and entry element of the given context menu item.
   */
  private getEntryCollectionOfItem(item: ContextMenuItem): {
    entryCollection: EntryCollection | undefined;
    entry: EntryElement | undefined;
  } {
    const result = { entryCollection: undefined, entry: undefined };
    try {
      result.entryCollection = this.templateService.getTemplates().find((template) => {
        // find entry collections
        if (template.getType() === ETemplateType.ENTRY_COLLECTION) {
          const entryElements = (template as EntryCollection).getEntryElements
            ? (template as EntryCollection).getEntryElements()
            : [] || [];

          // find entries
          return !!entryElements.find((entry) => {
            const contextMenuItems =
              entry.getContextmenu && entry.getContextmenu()?.getContextMenuItems
                ? entry.getContextmenu && entry.getContextmenu().getContextMenuItems()
                : [] || [];

            // find context menu items
            return !!contextMenuItems.find((contextMenuItem) => {
              const isResult = contextMenuItem.getUuid() === item.getUuid();

              if (isResult) {
                result.entry = entry;
              }

              return isResult;
            });
          });
        }
      });

      return result;
    } catch (error) {
      console.error('Error while getting entry collection of item');
      return result;
    }
  }

  /**
   * Closes the context menu if it was closed by a click event.
   * @param event The mouse event that triggered the close method.
   */
  private close(event: MouseEvent): void {
    if (this.menu.isClosedByClick()) {
      this.menu.getRef().dispose();
    }
  }
}
