import { NgModule } from '@angular/core';
import { HotspotRegionSidebarComponent } from './hotspot-region-sidebar/hotspot-region-sidebar.component';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'frontend/src/dashboard/core/material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HotspotColorschemeSidebarComponent } from './hotspot-colorscheme-sidebar/hotspot-colorscheme-sidebar.component';
import { ColorschemeColorSliderComponent } from './hotspot-colorscheme-sidebar/colorscheme-color-slider/colorscheme-color-slider.component';
import { AngularSplitModule } from 'angular-split';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [HotspotRegionSidebarComponent, HotspotColorschemeSidebarComponent, ColorschemeColorSliderComponent],
  exports: [HotspotRegionSidebarComponent, HotspotColorschemeSidebarComponent],
  imports: [CommonModule, MaterialModule, FormsModule, ReactiveFormsModule, AngularSplitModule, TranslateModule],
})
export class HotspotRegionSidebarModule {}
