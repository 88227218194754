import { GlobalUtils } from 'frontend/src/dashboard/global-utils';

export enum EVertexType {
  LINE = 'line',
  QUADRATIC = 'quadratic',
  BEZIER = 'bezier',
  HELPERVERTEX = 'helpervertex',
}
export class Vertex {
  private xCoordinate: number;
  private yCoordinate: number;
  private id: string;
  private vertexType: EVertexType = EVertexType.LINE;
  private indexInPolygon: number;

  public getIndex(): number {
    return this.indexInPolygon;
  }

  public setIndex(index: number): this {
    this.indexInPolygon = index;
    return this;
  }

  public getId(): string {
    return this.id;
  }

  public setId(id: string): this {
    this.id = id;
    return this;
  }

  constructor(x?: number, y?: number) {
    this.xCoordinate = x;
    this.yCoordinate = y;
    this.setId(GlobalUtils.generateUUID()).setIndex(0);
  }

  public getY(): number {
    return this.yCoordinate;
  }

  public setY(y: number): this {
    this.yCoordinate = y;
    return this;
  }

  public getX(): number {
    return this.xCoordinate;
  }

  public setX(x: number): this {
    this.xCoordinate = x;
    return this;
  }

  public getType(): EVertexType {
    return this.vertexType;
  }

  public setType(vertexType: EVertexType): this {
    this.vertexType = vertexType;
    return this;
  }
}

export class InterpolationVertex extends Vertex {
  private originVertex: Vertex;
  constructor(originVertex: Vertex, x?: number, y?: number) {
    super(x, y);
    this.originVertex = originVertex;
    this.setType(EVertexType.HELPERVERTEX);
  }

  public getOriginVertex(): Vertex {
    return this.originVertex;
  }

  public setOriginVertex(originVertex: Vertex): this {
    this.originVertex = originVertex;
    return this;
  }
}
