import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { HorizonFooterComponent } from './horizon-footer.component';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';

@NgModule({
  imports: [CommonModule, TranslateModule, MatTooltipModule, MatProgressSpinnerModule],
  exports: [HorizonFooterComponent],
  declarations: [HorizonFooterComponent],
})
export class HorizonFooterModule {}
