import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatLegacySlideToggleChange as MatSlideToggleChange } from '@angular/material/legacy-slide-toggle';
import { MatLegacySliderChange as MatSliderChange } from '@angular/material/legacy-slider';
import { SaxMsBestGanttSettings } from 'frontend/src/dashboard/gantt/gantt/saxms-best-gantt.settings';
import { EGanttTooltipDelayType } from 'frontend/src/dashboard/gantt/general/gantt-settings/gantt-settings-dialog/gantt-style.enum';

interface ITooltipDelaySelection {
  value: EGanttTooltipDelayType;
  viewValue: string;
}

@Component({
  selector: 'app-tooltips',
  templateUrl: './tooltips.component.html',
  styleUrls: ['./tooltips.component.scss'],
})
export class TooltipsComponent implements OnInit {
  public delayOptions: ITooltipDelaySelection[] = [
    { value: EGanttTooltipDelayType.NO_DELAY, viewValue: '@off@' },
    { value: EGanttTooltipDelayType.SHORT_DELAY, viewValue: '@small@' },
    { value: EGanttTooltipDelayType.MEDIUM_DELAY, viewValue: '@medium@' },
    { value: EGanttTooltipDelayType.LONG_DELAY, viewValue: '@big@' },
  ];
  public selectedDelayOption: EGanttTooltipDelayType;

  @Input() settings: SaxMsBestGanttSettings;
  @Output() changeEvent: EventEmitter<null> = new EventEmitter();

  constructor(private cdRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.selectedDelayOption =
      this.getSelectedDelayOptionByValue(this.settings.tooltipDelayMS)?.value ?? this.delayOptions[1].value;
  }

  public handleTooltipVisibility(event: MatSlideToggleChange): void {
    this.settings.showTooltip = event.checked;
    this.changeEvent.emit();
  }

  public onDelaySelectionChange(event: ITooltipDelaySelection): void {
    this.settings.tooltipDelayMS = event.value;
    this.changeEvent.emit();
  }

  private getSelectedDelayOptionByValue(value: number): ITooltipDelaySelection {
    return this.delayOptions.find((x) => x.value === value);
  }

  public handleTooltipScrollable(event: MatSlideToggleChange): void {
    this.settings.scrollableTooltip = event.checked;
    this.changeEvent.emit();
  }

  public fontSizeSliderChange(event: MatSliderChange) {
    this.settings.tooltipFontSize = event.value;
    this.cdRef.detectChanges();
    this.changeEvent.emit();
  }
}
