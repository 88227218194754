import { Injectable, NgZone, ViewChildren } from '@angular/core';
import { ButtonService } from '@app-modeleditor/components/button/button.service';
import { Hotkey } from 'frontend/src/dashboard/model/resource/hotkey';
import { Template } from 'frontend/src/dashboard/model/resource/template';
import { fromEvent } from 'rxjs';
import { TemplateService } from './template.service';

@Injectable({
  providedIn: 'root',
})
export class TemplateHotkeyService {
  private focusedTemplateId: string;
  private curTarget: HTMLElement;

  constructor(private actionApi: ButtonService, private templateService: TemplateService, private zone: NgZone) {
    this.zone.runOutsideAngular(() => {
      fromEvent(document, 'keydown').subscribe((keyboardEvent: KeyboardEvent) => {
        this.executeAction(keyboardEvent);
      });
    });
  }

  /**
   * get the current focusing template
   * @returns Template
   */
  public getFocusedTemplate(): Template {
    return this.templateService.getElementById(this.focusedTemplateId);
  }

  /**
   * set the current focusing template
   * @param {string} templateId
   * @returns this
   */
  public focusTemplate(target: HTMLElement, templateId: string): this {
    if (this.curTarget === target) {
      return;
    }
    this.curTarget = target;
    this.focusedTemplateId = templateId;
    return this;
  }

  /**
   * search the action for a given hotkey
   * @param template template for search the action
   * @param keyboardEvent specific hotkey-event
   */
  private getHotkeyEvent(template: Template, keyboardEvent: KeyboardEvent): Hotkey {
    if (!template || !keyboardEvent) {
      return null;
    }

    const match: Hotkey = template.getHotkeys().find((h: Hotkey) => {
      const r =
        h.getKeyCode() === keyboardEvent.code &&
        h.isShiftKey() === keyboardEvent.shiftKey &&
        h.isCtrlKey() === keyboardEvent.ctrlKey;
      return r;
    });

    return match || this.getHotkeyEvent(this.templateService.getElementById(template.getParentId()), keyboardEvent);
  }

  /**
   * search the action behind a hotkey event and execute this
   * @param keyboardEvent hotkey-event
   */
  private executeAction(keyboardEvent: KeyboardEvent) {
    if (!this.focusedTemplateId) {
      return;
    }
    const t: Template = this.templateService.getElementById(this.focusedTemplateId);
    const hotkey: Hotkey = this.getHotkeyEvent(t, keyboardEvent);
    if (!hotkey || hotkey.getActions().length === 0) {
      return;
    }

    if (hotkey.isStopDefault()) {
      keyboardEvent.preventDefault();
    }
    keyboardEvent.stopPropagation();

    this.zone.run(() => {
      this.actionApi.executeActions(hotkey.getActions()).subscribe();
    });
  }
}
