import { HttpClient } from '@angular/common/http';
import { GanttTemplateData } from 'frontend/src/dashboard/gantt/helper/gantt';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { GanttPredefinedSetting } from '../predefined-setting';
import { IGanttMarkedTimeSpan } from './marked-timespan-data.interface';

export class GanttMarkedTimeSpans extends GanttPredefinedSetting {
  public onInit(templateData: GanttTemplateData, ganttResponseData: any): void {
    if (!ganttResponseData.hierarchicalPlan) return;
    this.fetchTimeSpanData(templateData);
  }

  /**
   * Fetches marked time span data from the backend.
   * @param templateData Template data of the gantt to fetch the data for.
   */
  public fetchTimeSpanData(templateData: GanttTemplateData): void {
    // if marked time span requests are deactivated in config -> do nothing
    if (!this.configService.access().templates.Gantt.requests.markedTimeSpans) {
      return;
    }
    // if template data has no rest URL -> do nothing
    if (!templateData.hasOwnProperty('restUrl')) {
      this.logger.warn('No gantt template rest URL!');
      return;
    }
    // fetch marked time span data
    const http: HttpClient = this.ganttPluginHandlerService.http;
    const ganttId = templateData.getId();
    const canonicalName = templateData.getCanonicalName();
    const resourceId = templateData.getResourceId();
    const restUrl = `timemarker/gantt/${ganttId}/timemarker/${canonicalName}/${resourceId}`;

    http
      .get('rest/' + restUrl)
      .pipe(catchError(() => of([])))
      .subscribe((timeSpans: IGanttMarkedTimeSpan[]) => this.addTimeSpansToGantt(timeSpans));
  }

  /**
   * Adds the specified time spans to the gantt.
   * @param timeSpans Time spans which should be added.
   */
  private addTimeSpansToGantt(timeSpans: IGanttMarkedTimeSpan[]): void {
    for (const timeSpan of timeSpans) {
      this.ganttLibService.bestGantt
        .getGlobalTimeSpanMarker()
        .addInterval(
          timeSpan.start + new Date(timeSpan.start).getTimezoneOffset() * 60000,
          timeSpan.end + new Date(timeSpan.end).getTimezoneOffset() * 60000,
          timeSpan.color,
          0.2,
          timeSpan.name,
          [{ name: this.actionHandler.translate.instant('details'), value: timeSpan.details }],
          null,
          null,
          1101
        );
    }
  }
}
