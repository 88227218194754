import { ErrorType } from './error-types.enum';

export class ErrorStack {
  private errors = [];

  private static instance: ErrorStack;

  // stacksize is the count of elements which will be stored
  private stackSize = -1;

  private constructor(stackSize?) {
    if (stackSize) this.stackSize = stackSize;
  }

  public static getInstance(): ErrorStack {
    if (!this.instance) this.instance = new ErrorStack();
    return this.instance;
  }

  public add(error: Error, time: Date, type: ErrorType) {
    let timePoint = time;
    let errorType = type;

    if (!timePoint) timePoint = new Date();
    if (!errorType) errorType = ErrorType.UNDEFINED;

    this.errors.push({
      error: error,
      timePoint: timePoint,
      type: errorType,
    });

    if (this.stackSize > -1 && this.errors.length + 1 > this.stackSize) {
      this.errors.shift();
    }
  }

  public get(): Array<any> {
    return this.errors;
  }

  public getLatest(): any {
    if (this.errors.length < 0) return null;

    let latestError = this.errors[0];

    // iterate over all errors and find latest message
    for (const e of this.errors) {
      if (e.timePoint > latestError.timePoint) latestError = e.timePoint;
    }

    return latestError;
  }

  public getStringList(): Array<string> {
    const list: Array<string> = new Array<string>();

    for (const error of this.errors) {
      list.push(error.error + '');
    }

    return list;
  }

  public getDashboardStringList(): Array<string> {
    const list: Array<string> = new Array<string>();

    for (const error of this.errors) {
      if (error.type === ErrorType.DASHBOARD) list.push(error.error + '');
    }

    return list;
  }

  public getServerStringList(): Array<string> {
    const list: Array<string> = new Array<string>();

    for (const error of this.errors) {
      if (error.type === ErrorType.SERVER) list.push(error.error + '');
    }

    return list;
  }

  public empty(): void {
    this.errors = [];
  }
}
