import { Injectable } from '@angular/core';
// eslint-disable-next-line no-restricted-imports
import { Observable, Subject } from 'rxjs';

@Injectable()
export class TimelineService {
  public selectedItemObservable: Observable<any>;
  private selectedItemSubject: Subject<any>;

  constructor() {
    this.selectedItemSubject = new Subject<any>();
    this.selectedItemObservable = this.selectedItemSubject.asObservable();
  }

  /**
   *
   * @param item information over ther selected item
   */
  onSelectedItem(item: any) {
    this.selectedItemSubject.next(item);
  }
}
