import {
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  ElementRef,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyMenu as MatMenu } from '@angular/material/legacy-menu';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { ButtonService } from '@app-modeleditor/components/button/button.service';
import { TemplateActionService } from '@app-modeleditor/components/button/template-action.service';
import { ContentService } from '@app-modeleditor/components/content/content.service';
import { LightboxService } from '@app-modeleditor/components/lightbox/lightbox.service';
import { ISaxmsSubmenuOutput } from '@app-modeleditor/components/spreadsheet/full-spreadsheet/filter-output.interface';
import { TemplateComponent } from '@app-modeleditor/components/template/template.component';
import { UiService } from '@app-modeleditor/ui.service';
import { TemplateAdapter } from '@app-modeleditor/utils/template-factory.service';
import { TemplateService } from '@app-modeleditor/utils/template.service';
import { SaxMsSubmenuFavoriteTabService } from '@app-modules/saxms-submenu-elements/saxms-submenu-favorite-tab.service';
import { EMessageType, Message } from '@core/message/message';
import { MessageService } from '@core/message/message.service';
import { CloudMessagingService } from '@core/notification/cloud-messaging.service';
import { Notification } from '@core/notification/notification';
import { ENotificationType } from '@core/notification/notification-type.enum';
import { TranslateService } from '@ngx-translate/core';
import { ExperimentService2, IExperiment } from 'frontend/src/dashboard/data/experiment/experiment.service';
import { Widget } from 'frontend/src/dashboard/data/grid/data/widget';
import { HorizonService } from 'frontend/src/dashboard/horizonselecter/horizon.service';
import { ModelService } from 'frontend/src/dashboard/model/model.service';
import { AbstractGridService } from 'frontend/src/dashboard/moving-grid/abstract-grid.service';
import { GridGloablService } from 'frontend/src/dashboard/moving-grid/grid-global.service';
import { SharedToolbarService } from 'frontend/src/dashboard/view/navbar/toolbar/shared-toolbar.service';
import { Observable, of, Subject, Subscription, throwError } from 'rxjs';
import { catchError, delay, map, switchMap, take, takeUntil, takeWhile, tap } from 'rxjs/operators';
import { GridLayout } from '../../grid-layout/grid-layout';
import { WidgetGridLayoutService } from '../../grid-layout/widget-grid-layout.service';
import { Zoom } from '../../grid-layout/zoom';
import { IBubblePage } from './bubble-pagination/bubble-pagination.component';
import { ChartLegendItem } from './chart-legend/chart-legend-item';
import { ChartLegendQuickfilter } from './chart-legend/chart-legend-quickfilter/chart-legend-quickfilter';
import { ChartLegendQuickfilterService } from './chart-legend/chart-legend-quickfilter/chart-legend-quickfilter.service';
import { EChartLegendType } from './chart-legend/chart-legend-type.enum';
import { ChartLegendWrapperTemplate } from './chart-legend/chart-legend-wrapper-template';
import { ECharttoolbarModes } from './chart-toolbar-modes.enum';
import { GeneralChartInformation } from './general-chart-information';
import {
  ChartIntegralDialogComponent,
  ChartIntegralInput,
  ChartIntegralOutput,
} from './generalChart/chart.extensions/chart-integral-dialog/chart-integral-dialog.component';
import { OptionTree } from './generalChart/chart.extensions/chartlib';
import { ChartData, ChartDataGroup, ICompareChartInormations } from './generalChart/chart.mapper';
import { GeneralChartComponent } from './generalChart/generalChart.component';
import { LayoutInformation } from './LayoutInformation';
import { LegendModes } from './LegendModes';
import { ChartToolbar } from './toolbar/chart-toolbar';
import { ChartType } from 'chart.js';

export interface ChartSaveSettingsFormat {
  widgetRef: string;
  widgetSettings: any;
}

export interface IExperimentObject {
  id: string;
  restUrl: string;
  name: string;
}

@Component({
  selector: 'charts',
  templateUrl: './chart.html',
  styleUrls: ['./chart.scss'],
  providers: [SaxMsSubmenuFavoriteTabService],
})
export class ChartsComponent implements OnInit, OnChanges, OnDestroy {
  gutterSize = 7; // size of the as slit gutter
  toggleChartType = false;
  stacked = false;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('myDiv') myDiv: any;
  @ViewChild('chartMathOptionMenu') chartMathOptionMenu: MatMenu;
  @ViewChild('chartOptionsMenu') chartOptionsMenu: MatMenu;
  @ViewChild('chartSorterMenu') chartSorterMenu: MatMenu;
  @ViewChild('chartUnitMenu') chartUnitMenu: MatMenu;
  @ViewChild('chartIntegralMenu') chartIntegralMenu: MatMenu;
  @ViewChild('representationMenu') representationMenu: MatMenu;
  @ViewChild('legendMenu') legendMenu: MatMenu;
  @ViewChildren('generalChart') generalCharts: QueryList<GeneralChartComponent>;

  @Input() widget: Widget;
  @Input() combined = false;

  chartToolbar: ChartToolbar;
  private alive = true;
  private subs: Subscription[] = [];
  mainChart: GeneralChartInformation;
  private compareChart: GeneralChartInformation;
  private prevoiusPageSize = 1;
  private templateSettings: ChartSaveSettingsFormat;
  public template;
  private chartOptionsMap: Map<string, string> = new Map();
  private chartIntegralsMap: Map<string, ChartIntegralOutput> = new Map();
  private defaultInterval = '';
  private saveTimeout: Subject<void> = new Subject<void>();
  private backendManagedActive = false;
  private zoomEvent: Zoom;
  public compareChartsInformations: ICompareChartInormations[] = [];

  public currentSubmenu;
  public toolbarMode: ECharttoolbarModes | string = ECharttoolbarModes.VIEW;
  public widgetSettings: any;
  public loadingData = false;
  public loadingZoom = false;
  public enableToggle = true;
  public toolbarVisible = false;
  public hiddenDatasets: Set<string> = new Set();
  public hiddenAttributes: Set<string> = new Set();
  public legendSize = 300;
  public legendCompareSize = 300;
  public legendCombineSize = 300;
  public legendMode: LegendModes = 'left';
  public labelXAxisVisible = true;
  protected _chartContainerPaddingRight: number = null;

  public resultOptionTree: any = {
    chartOptions: false,
    chartMathOption: false,
    chartSorter: false,
    representation: false,
  };

  public optionTree: OptionTree = {
    chartOptions: [],
    chartMathOption: [],
    chartSorter: [],
    representation: [],
    chartLimits: [],
    chartUnits: [],
  };

  public layout: LayoutInformation;

  public optionsDalogResultTree: any;
  public showTooltip = false;
  public chartType: any = 'bar';

  public maxCount = 0;
  public chartRequestOptionsString = '';
  public pageSizeOptions = [];

  public selectedExperimentId = '';
  public choosedOptionalRequestOptions = [];

  public toRenderCharts: Array<GeneralChartInformation> = [];
  public mergedChartData: ChartData;
  public editmode = false;
  public heightRef = 100;

  public model: any;
  public horizon: any;
  public experimentsList: IExperiment[];
  public quickFilters: ChartLegendQuickfilter[] = [];
  public selectedExperiment: any = null;
  public compareExperiment = false;

  public gridService: AbstractGridService;
  public gridType = '';

  public pageSize = 1;
  public pageIndex = 0;
  public sortData: any[] = null;

  public legendBySide = true;
  public legendCombineData = [];
  public legendVisible = true;
  public loading = false;
  public initLoading = false;
  public errorFlag = false;
  public experimentChangeResult = false;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  constructor(
    public dialog: MatDialog,
    public globalGridService: GridGloablService,
    public elRef: ElementRef,
    public globalToolbarService: SharedToolbarService,
    public sharedModelService: ModelService,
    public sharedHorizonService: HorizonService,
    public experimentService2: ExperimentService2,
    public fcm: CloudMessagingService,
    public componentFactoryResolver: ComponentFactoryResolver,
    public messageApi: MessageService,
    public buttonService: ButtonService,
    public translate: TranslateService,
    public templateAdapter: TemplateAdapter,
    public lightboxApi: LightboxService,
    private submenuFavoriteTabService: SaxMsSubmenuFavoriteTabService,
    public widgetGridLayoutService: WidgetGridLayoutService,
    private uiService: UiService,
    private chartLegendQuickFilterService: ChartLegendQuickfilterService,
    public contentService: ContentService,
    public templateService: TemplateService,
    private zone: NgZone,
    public templateActionService: TemplateActionService
  ) {
    this.gridService = globalGridService.getGridService();
    this.gridType = globalGridService.getGridType();
    this.submenuFavoriteTabService.disableContextMenu(true);

    this.setDefaultSettings();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.widget) {
      this.init();
    }
  }

  /**
   * load all quicksearch filter of a chart and init the chart after this
   * @param force forces the execution of the function
   */
  private init(force?: boolean) {
    if (!force && (!this.widget || this.initLoading)) {
      return;
    }

    this.ngUnsubscribe.next();
    this.initLoading = true;

    this.chartLegendQuickFilterService
      .getAllQuickFilter(this.widget.widgetBaseId)
      .pipe(take(1))
      .subscribe((filters) => {
        this.quickFilters = filters;
        this.initChart();
      });
  }

  /**
   * @returns the id of the chart
   */
  public getChartId(): string {
    return this.widget.widget.id;
  }

  /**
   * init the chart
   */
  private initChart(): void {
    this.zone.run(() => {
      this.initMainChart(this.widget, this.widget.type as ChartType)
        .pipe(takeUntil(this.ngUnsubscribe))
        .pipe(takeWhile(() => this.alive))
        .subscribe(() => {
          this.chartToolbar = new ChartToolbar({ showMenuToggleBtn: true }, this);
          this.chartToolbar?.disabledCompare(this.compareChartsInformations?.length > 0 ? false : true);
        });
    });
  }

  @ViewChild('chartOuterContainer') chartOuterContainer: ElementRef;

  /**
   * hook to set the toolbar
   * @param instance componentref for the toolbar place
   */
  public afterInitSlot(instance: ComponentRef<TemplateComponent>): void {
    this.chartToolbar.setReferenceContainer(this.chartOuterContainer);
    instance.instance.templateNode = this.chartToolbar;
  }

  /**
   * after init-hook of the chart
   * update the grid start of the combine widget by the width of the legend
   * @param chartIndex index of the chart
   */
  public afterInitCharts(chartIndex): void {
    if (chartIndex === 0) {
      of(null)
        .pipe(delay(0))
        .subscribe(() => {
          const gridLayoutSize = this.legendSize + this.generalCharts.first.getStartPixelOfXAxis();
          this.widgetGridLayoutService.setGridStart(this.widget.contentId, gridLayoutSize + this.gutterSize);
          this.executeInitialZoom();
        });
      if (this.combined) {
        this.loadingZoom = true;
      }
    }
  }

  ngOnDestroy(): void {
    this.alive = false;

    this.subs.forEach((element) => {
      element.unsubscribe();
    });

    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();

    this.saveTimeout.next();
    this.saveTimeout.complete();
  }

  /**
   * save the legendsize of a specific legend
   * @param size of the legend
   * @param index (0 for first chartlegend, 1 for second chartlegend, 3 for the combinelegend)
   */
  onDragEnd(size: any, index: number): void {
    let legendSize = 0;

    if (this.legendBySide) {
      legendSize = this.legendMode === 'left' ? size.sizes[0] : size.sizes[1];
    } else {
      legendSize = this.legendMode === 'top' ? size.sizes[0] : size.sizes[1];
    }

    switch (index) {
      case 0:
        if (this.legendBySide) {
          this.widgetSettings.chartLegendWidth = legendSize;
        } else {
          this.widgetSettings.chartLegendHeight = legendSize;
        }
        break;
      case 1:
        if (this.legendBySide) {
          this.widgetSettings.chartLegendCompWidth = legendSize;
        } else {
          this.widgetSettings.chartLegendCompHeight = legendSize;
        }
        break;
      case 3:
        if (this.legendBySide) {
          this.widgetSettings.chartLegendCompCoupleWidth = legendSize;
        } else {
          this.widgetSettings.chartLegendCompCoupleHeight = legendSize;
        }
        break;
    }
    this.saveSettings();

    this.setGridLayoutSize();
  }

  /**
   * update the gridstart information of the combine widget by the current width of the legend
   */
  private setGridLayoutSize() {
    setTimeout(() => {
      const gridLayoutSize = this.widgetSettings.chartLegendWidth + this.generalCharts.first.getStartPixelOfXAxis();

      this.widgetSettings.chartLegendWidth = Math.trunc(gridLayoutSize);
      const gridWidth = Math.trunc(gridLayoutSize) + this.gutterSize;
      this.widgetGridLayoutService.setGridStart(this.widget.contentId, gridWidth);
    });
  }

  /**
   * reset the widget settings to default
   */
  private setDefaultSettings() {
    this.widgetSettings = {
      compare: false,
      experimentIds: [],
      pageSize: this.pageSizeOptions.length > 0 ? this.pageSizeOptions[0] : 1,
      stacked: false,
      toggleChartType: false,
      hiddenDatasets: [],
      hiddenAttributes: [],
      legendLayout: 'left',
      legendCompareCoupled: false,
      chartLegendWidth: 150,
      chartLegendHeight: 150,
      chartLegendCompWidth: 150,
      chartLegendCompHeight: 150,
      chartLegendCompCoupleWidth: 150,
      chartLegendCompCoupleHeight: 150,
      labelXAxisVisible: true,
      chartOption: this.defaultInterval,
    };
  }

  /**
   * return the legend width of a chart
   * important to show two charts side by side
   * @param chartIndex index of chart
   * @returns legend width of a chart
   */
  public getLegendSize(chartIndex: number): number {
    switch (chartIndex) {
      case 0:
        return this.legendSize;
      case 1:
        return this.legendCompareSize;
      case 3:
        return this.legendCombineSize;
    }
  }

  /**
   * update the legend size
   * @param size new size of the legend
   */
  private changeLegendSize(size: number) {
    if (this.generalCharts && this.generalCharts.length > 0) {
      size = size - this.generalCharts.first.getStartPixelOfXAxis();
      if (this.legendBySide) {
        this.widgetSettings.chartLegendWidth = size;
        this.widgetSettings.chartLegendCompWidth = size;
        this.widgetSettings.chartLegendCompCoupleHeight = size;
      }

      this.legendSize = this.widgetSettings.chartLegendWidth;
      this.legendCompareSize = this.widgetSettings.chartLegendCompWidth;
      this.legendCombineSize = this.widgetSettings.chartLegendCompCoupleHeight;
      this.saveSettings(true);
    }
  }

  /**
   * switch the Legend-Mode
   * @param mode 'top' | 'bottom' | 'left' | 'right'
   */
  switchLegend(mode: LegendModes): void {
    switch (mode) {
      case 'left':
      case 'right':
        this.legendBySide = true;
        this.legendVisible = true;
        this.legendMode = mode;
        break;
      case 'hidden':
        this.legendVisible = !this.legendVisible;
        break;
    }

    if (this.legendBySide) {
      this.legendSize = this.widgetSettings.chartLegendWidth;
      this.legendCompareSize = this.widgetSettings.chartLegendCompWidth;
      this.legendCombineSize = this.widgetSettings.chartLegendCompCoupleHeight;
    } else {
      this.legendSize = this.widgetSettings.chartLegendHeight;
      this.legendCompareSize = this.widgetSettings.chartLegendCompHeight;
      this.legendCombineSize = this.widgetSettings.chartLegendCompCoupleHeight;
    }

    this.widgetSettings.legendLayout = this.legendMode;
    this.widgetSettings.legendVisible = this.legendVisible;
    this.widget.widgetSettings = this.widgetSettings;
    this.saveSettings();
  }

  /**
   * handle the clicks on the chart legend
   * @param item clicked chartLegendItem
   * @param chartLegendWrapper chart legend wrapper
   */
  private handleLegendItemClick(item: ChartLegendItem, chartLegendWrapper: ChartLegendWrapperTemplate) {
    if (item) {
      this.generalCharts.toArray().forEach((element, index) => {
        switch (item.getType()) {
          case EChartLegendType.DATASET:
            element.handleHiddenDataSet(chartLegendWrapper.getChartData());
            break;
          case EChartLegendType.ATTRIBUTE:
            this.toRenderCharts.forEach((chart) => {
              chart.legend.updateHiddenAttributes(chartLegendWrapper.getHiddenAttributes());
            });
            break;
        }
      });
    }

    if (item?.getType() !== EChartLegendType.DATASET) {
      this.noQuicksearchFilterSelection();
    }

    this.saveHiddenDatasets(chartLegendWrapper.getHiddenDatasets(), chartLegendWrapper.getHiddenAttributes());
  }

  /**
   * update the information about the hiddenDatasets and hiddenAttributes inside the widget settings of the chart
   * @param hiddenDatasets new hiddenDatasets
   * @param hiddenAttributes new hiddenAttributes
   */
  private saveHiddenDatasets(hiddenDatasets: Set<string>, hiddenAttributes: Set<string>) {
    this.widgetSettings.hiddenDatasets = Array.from(hiddenDatasets.values()).slice();
    this.widgetSettings.hiddenAttributes = Array.from(hiddenAttributes.values()).slice();
    this.widget.widgetSettings = this.widgetSettings;

    this.saveSettings();
  }

  /**
   * handle the current zoom of the combined widget
   */
  private handleZoom() {
    this.loadingZoom = true;
    (this.toRenderCharts || []).forEach((element: GeneralChartInformation, index: number) => {
      if (element.chartData.listenToPlanModification) {
        if (
          this.zoomEvent &&
          this.zoomEvent.getStart() &&
          this.zoomEvent.getEnd() &&
          this.zoomEvent.getEnd() > this.zoomEvent.getStart()
        ) {
          element.chartData.zoom = {
            startTime: this.zoomEvent.getStart().getTime(),
            endTime: this.zoomEvent.getEnd().getTime(),
          };

          element.chartData.executeZoom();
          this.refreshChartInformation();
          this.generalCharts.toArray()[index].refreshChartwithNewMaxMin(true);
          this.setChartData(element.chartData, index);
        } else {
          this.updateSingleChart(element, true, index);
        }
      }
    });
  }

  /**
   * register different subscription
   * experimentService2 => for the experiment for the compare feature and the current experiment
   * widgetGridLayoutService => for the zoom information o fthe combined widget
   * fcm => for the notification of the chart
   */
  ngOnInit(): void {
    this.alive = true;
    this.subs.push(
      this.experimentService2
        .getExperiments()
        .pipe(takeWhile(() => this.alive))
        .subscribe((experiments: IExperiment[]) => {
          this.experimentsList = experiments;
        })
    );

    this.widgetGridLayoutService
      .getGridLayout()
      .pipe(takeWhile(() => this.alive))
      .subscribe((layouts: GridLayout[]) => {
        if (this.initLoading) {
          return;
        }

        const layout = layouts.find((layout) => layout.getContentId() == this.widget.contentId);
        if (layout) {
          // update right padding
          this._chartContainerPaddingRight = this.widgetGridLayoutService.paddingRight;

          if (this.widget && layout.getGridStart() && layout.getContentId() === this.widget.contentId) {
            // remove gutter size
            this.changeLegendSize(layout.getGridStart() - this.gutterSize);
          }
          if (layout.getZoom()) {
            if (
              this.zoomEvent &&
              this.zoomEvent.getStart()?.getTime() === layout.getZoom().getStart()?.getTime() &&
              this.zoomEvent.getEnd()?.getTime() === layout.getZoom().getEnd()?.getTime() &&
              layout.getZoom().getCreated() < this.zoomEvent.getCreated() // discard older zoom events caused by timeouts
            ) {
              return;
            }
            this.zoomEvent = layout.getZoom();
            this.loadingZoom = true;
            this.handleZoom();
          }
        }
      });
    this._chartContainerPaddingRight = this.widgetGridLayoutService.paddingRight;

    this.experimentService2
      .getCurrentExperiment()
      .pipe(takeWhile(() => this.alive))
      .subscribe((experiment) => {
        const experimentResult = experiment.experimentResult;
        if (
          this.experimentChangeResult &&
          experimentResult &&
          experimentResult.state &&
          experimentResult.state.progress === 1
        ) {
          this.init(true);
          this.experimentChangeResult = false;
        }
      });

    this.fcm
      .getMessage()
      .pipe(takeWhile(() => this.alive))
      .subscribe((event: Notification) => {
        if (
          (event.getType() === ENotificationType.EXPERIMENT_UDPATED ||
            event.getType() === ENotificationType.EXPERIMENT_UPDATED) &&
          !event.getZoomStart() &&
          !event.getZoomEnd() &&
          this.mainChart &&
          this.mainChart.experimentId === event.getResourceId()
        ) {
          if (!this.zoomEvent) {
            this.zoomEvent = new Zoom().setCreated(event.getCreated());
          }

          if (this.toRenderCharts.length > 0) {
            (this.toRenderCharts || []).forEach((element, index) => {
              this.updateSingleChart(element, true, index);
            });
          }
        }
      });
  }

  /**
   * update the information about the pagination
   * @param event current state of the pagination of the chart
   */
  public onPageChange(event: IBubblePage): void {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.enableToggle = this.pageSize != 1;

    this.widgetSettings.pageSize = event.pageSize;
    this.widgetSettings.pageIndex = event.pageIndex;
    this.widget.widgetSettings = this.widgetSettings;

    if (event.pageSize != this.prevoiusPageSize) {
      this.updateCharts(true);
    } else {
      this.updateCharts(false);
    }

    this.prevoiusPageSize = event.pageSize;
    this.maxCount = this.toRenderCharts[0].chartData.maxPoints;
    this.saveSettings();
    // this.stacked = false;
  }

  /**
   * update the sort data for the comparison of two charts
   * @param event new sort data
   */
  public changeDataOfElement(event): void {
    if (event.sortData) {
      of(null)
        .pipe(delay(0))
        .subscribe(() => {
          this.sortData = event.sortData;
        });
    }
  }

  /**
   * handle the loading of the chartdata from the backend
   * @param chart general chart information
   */
  private getChartData(chart: GeneralChartInformation): Observable<any> {
    this.loading = true;
    this.loadingData = true;
    return this.getChartDataFromBackend(chart)
      .pipe(
        map((data) => {
          this.loading = false;
          if (!this.initLoading) {
            this.loadingData = false;
          }
          return data;
        })
      )
      .pipe(
        catchError((err) => {
          this.loading = false;
          return throwError(err);
        })
      );
  }

  /**
   * loads the chart data from the backend
   * @param chart general chart information
   */
  private getChartDataFromBackend(chart: GeneralChartInformation) {
    this.errorFlag = false;
    return this.uiService
      .getData(chart.dataRestUrl, this.getExtraRequestString())
      .pipe(
        map((data) => {
          return data;
        })
      )
      .pipe(
        catchError((err) => {
          if (err.error.exceptionType === 'ExperimentResultNotAvailableException') {
            this.experimentChangeResult = true;
            this.messageApi.show(
              new Message()
                .setType(EMessageType.ERROR)
                .setText('SYSTEM.MESSAGE.EXCEPTION.ExperimentResultNotAvailableException')
                .setDuration(7500)
            );
          } else {
            this.errorFlag = true;
            // this.showMessage('@noServerDataArrived@');
          }
          return throwError(err);
        })
      );
  }

  /**
   * generate the extra queryparameters for the get-request of the chart data
   * @returns the extra queryparameters as string
   */
  private getExtraRequestString() {
    const requestOptionsObject = {
      offset: this.pageIndex * this.pageSize,
      limit: this.pageSize,
    };

    this.chartRequestOptionsString = '';
    this.chartOptionsMap.forEach((value, key) => {
      if (value) {
        this.chartRequestOptionsString += '&' + key + '=' + value;
        requestOptionsObject[key] = value;
      }
    });
    return requestOptionsObject;
  }

  /**
   * handle the switch between the type of the chart
   */
  private toggleType(): void {
    if (this.generalCharts) {
      this.generalCharts.toArray().forEach((element) => {
        element.toggleType();
      });

      this.widgetSettings.toggleChartType = !this.widgetSettings.toggleChartType;
      this.widget.widgetSettings = this.widgetSettings;
      this.saveSettings();
    }
  }

  /**
   * Returns The identifier of the Result Option Tree Values as an String Array
   * @returns String Array of the Variable Names of the  Result Option Tree
   */
  public resultOptionTreeKeysAsArray(): any[] {
    return Object.keys(this.resultOptionTree);
  }

  /**
   * Returns The Values of the Result Option Tree  as an String Array
   * @returns String Array of the Values of the  Result Option Tree
   */
  public resultOptionTreeAsArray(): any[] {
    return Object.values(this.resultOptionTree);
  }

  /**
   * update the data of a given chart
   * @param chart general chart information
   * @param replace flag to repalce the old data
   * @param index index of the chart
   */
  private updateSingleChart(chart: GeneralChartInformation, replace: boolean, index) {
    this.updateChartData(chart, replace).subscribe(
      (data: any) => {
        switch (index) {
          case 1:
            chart.chartData.chartData.forEach((element2) => {
              element2.label += ' (' + chart.experimentName + ') ';
            });
            break;
        }
        this.setChartData(chart.chartData, index);
        this.refreshChartInformation();
      },
      (err) => {
        this.removeInvalidCompareChart();
        console.error(err);
      }
    );
  }

  /**
   * update the data of the charts
   * @param replace flag to repalce the old data of the charts
   */
  private updateCharts(replace: boolean) {
    // this.mainChart = this.toRenderCharts[0];
    this.toRenderCharts.forEach((element, index) => {
      this.updateSingleChart(element, replace, index);
    });
  }

  /**
   * update the max count of the chartdata
   * update the pagesize options
   */
  private refreshChartInformation() {
    this.maxCount = this.toRenderCharts[0].chartData.maxPoints;
    this.getPageSizeOption();
  }

  /**
   * update the data of a chart by index
   * @param data new data for the chart
   * @param index index of the chart
   */
  private setChartData(data, index) {
    // setTimeout(() => {
    this.generalCharts.toArray()[index].setChartData(data);
    // }, 0);
  }

  /**
   * get new data for a chart from backend and update the chart data of this chart
   * @param chart generalchart information
   * @param replace flag to repalce the old data of the charts
   */
  private updateChartData(chart: GeneralChartInformation, replace: boolean): Observable<any> {
    return this.getChartData(chart)
      .pipe(takeWhile(() => this.alive))
      .pipe(
        tap((data: any) => {
          if (replace) {
            const beforeEmpty =
              this.mainChart.chartData.chartData.length === 1 &&
              this.mainChart.chartData.chartData[0].localID === 'nodata' &&
              data.chartData.chartDataGroups.length > 0;
            chart.chartData.replaceDataset(data, chart.chartData.getColors());

            if (!this.backendManagedActive || beforeEmpty || data.chartData.chartDataGroups.length === 0) {
              chart.legend.mapLegend(
                data.chartData.legend,
                chart.chartData.chartData,
                this.hiddenDatasets,
                this.hiddenAttributes
              );
            }

            chart.chartData.replaceDataset(data, chart.chartData.getColors());
          } else {
            chart.chartData.updateDataset(data.chartData);
          }

          this.setGridLayoutSize();
          this.executeInitialZoom();
          this.backendManagedActive = false;
        })
      );
  }

  /**
   * create a chartLegendWrapperTemplate for the chart
   * @param widgetRefId id for the chart legend
   * @returns chartLegendWrapperTemplate for the chart
   */
  private getChartLegendWrapperTemplate(widgetRefId: string): ChartLegendWrapperTemplate {
    return new ChartLegendWrapperTemplate()
      .onLegendItemClick(this.handleLegendItemClick.bind(this))
      .setWidgetRefId(widgetRefId);
  }

  /**
   * generate a chart from the compare-chart information
   * @param comapreChartInformation compare chart-information
   */
  private getCompareChart(comapreChartInformation: ICompareChartInormations): Observable<any> {
    return new Observable<any>((observer) => {
      this.layout = LayoutInformation.byside;
      this.widgetSettings.compareLayout = this.layout;
      const renderCharts = [];
      const mainChart = this.toRenderCharts[0];
      renderCharts.push(this.toRenderCharts[0]);

      this.compareChart = new GeneralChartInformation();

      this.compareChart.index = 1;
      this.compareChart.type = mainChart.type;
      this.compareChart.experimentId = comapreChartInformation.id;
      this.compareChart.widgetRefId = mainChart.widgetRefId;
      this.compareChart.chartId = mainChart.chartId;
      this.compareChart.dataRestUrl = comapreChartInformation.restURL;

      if (!this.compareChart.legend) {
        this.compareChart.legend = this.getChartLegendWrapperTemplate(this.compareChart.widgetRefId);
      }
      this.subs.push(
        this.getChartData(this.compareChart).subscribe(
          (data) => {
            this.compareChart.chartData = new ChartData(data, mainChart.chartData.getColors());
            this.compareChart.legend.mapLegend(
              data.chartData.legend,
              this.compareChart.chartData.chartData,
              this.hiddenDatasets,
              this.hiddenAttributes
            );
            this.compareChart.chartData.chartType = mainChart.chartData.chartType;
            this.compareChart.chartData.setType(mainChart.chartData.chartType);
            this.compareChart.chartData.chartData.forEach((element) => {
              element.label += ' (' + comapreChartInformation.name + ') ';
            });
            renderCharts.push(this.compareChart);
            this.toRenderCharts = renderCharts.slice();
            this.setRenderCharts(this.toRenderCharts);

            this.refreshMaxMinValue();
            observer.next();
          },
          (err) => {
            observer.error(err);
            console.error(err);
          }
        )
      );
    });
  }

  /**
   * handling the saving of the settings of the chart
   * @param invisibleMessage flag to hide the message after successfully saving the settings
   */
  public saveSettings(invisibleMessage?: boolean): void {
    if (this.initLoading) {
      return;
    }

    this.saveTimeout.next();
    of(null)
      .pipe(
        delay(750),
        takeUntil(this.saveTimeout),
        switchMap(() => this.saveWidgetSettings(this.widgetSettings, invisibleMessage))
      )
      .subscribe();
  }

  /**
   * handle  comparison of chart over the contextmenu
   * @param compareChartInformation
   */
  public addExperimentToCompare(compareChartInformation: ICompareChartInormations): void {
    this.getCompareChart(compareChartInformation).subscribe(
      (data) => {
        this.compareExperiment = true;
        this.widgetSettings.compare = true;
        this.widgetSettings.experimentIds = [compareChartInformation.id];
        this.saveSettings();
      },
      (err) => {
        this.removeInvalidCompareChart();
        console.error(err);
      }
    );
  }

  /**
   * remove the comapre chart
   */
  public removeCompareExperiment(): void {
    if (this.toRenderCharts.length >= 2) {
      this.toRenderCharts.pop();
    }

    this.compareExperiment = false;
    this.widgetSettings.compare = false;
    this.widgetSettings.experimentIds = [];
    this.widget.widgetSettings = this.widgetSettings;
    this.saveSettings();
  }

  /**
   * save the settings of the widget over a request
   * @param settings new widget settings
   * @param invisibleMessage flag to hide the message after successfully saving the settings
   */
  private saveWidgetSettings(settings: any, invisibleMessage?: boolean) {
    if (this.templateSettings) {
      this.templateSettings.widgetSettings = settings;
    } else {
      return of(null);
    }

    return this.uiService.postData(this.widget.templateRestUrl, this.templateSettings).pipe(
      tap((data) => {
        if (!invisibleMessage) {
          this.messageApi.show(
            new Message()
              .setType(EMessageType.INFO)
              .setText('CHART.SETTINGS.SAVE.success')
              .setDuration(2000)
              .setTitle('CHART.SETTINGS.label')
          );
        }
      })
    );
  }

  /**
   * reset the widgetsettings in the backend
   */
  private resetWidgetSettings() {
    this.templateSettings.widgetSettings = this.widgetSettings;
    this.uiService.postData(this.widget.templateRestUrl, this.templateSettings).subscribe((data) => {
      this.updateSingleChart(this.mainChart, true, 0);
      this.messageApi.show(
        new Message()
          .setType(EMessageType.SUCCESS)
          .setText('CHART.SETTINGS.RESET.success')
          .setDuration(2000)
          .setTitle('CHART.SETTINGS.label')
      );
    });
  }

  /**
   * loads all available options for the comparison of charts
   */
  private loadCompareWidgets(): Observable<any> {
    if (!this.widget.compareRestUrl) {
      return of(null);
    }
    return this.uiService.getData(this.widget.compareRestUrl).pipe(
      map((compareData: ICompareChartInormations[]) => {
        this.chartToolbar?.disabledCompare(compareData?.length > 0 ? false : true);
        this.compareChartsInformations = compareData;
      })
    );
  }

  /**
   * loading and map the widgetsettings
   */
  private loadWidgetSettings(): Observable<any> {
    return new Observable((observer) => {
      const widgetSettings = this.widget.settings.widgetSettings;
      this.templateSettings = this.widget.settings;
      if (!widgetSettings) {
        observer.next();
      } else {
        this.widgetSettings = Object.assign(this.widgetSettings, widgetSettings);
        this.chartOptionsMap.set('chartOption', widgetSettings.chartOption ? widgetSettings.chartOption : '');
        this.chartOptionsMap.set(
          'chartMathOption',
          widgetSettings.chartMathOption ? widgetSettings.chartMathOption : ''
        );
        this.chartOptionsMap.set('chartSorter', widgetSettings.chartSorter ? widgetSettings.chartSorter : '');
        this.chartOptionsMap.set('chartUnit', widgetSettings.chartUnit ? widgetSettings.chartUnit : '');
        this.chartOptionsMap.set('area', widgetSettings.chartArea ? widgetSettings.chartArea : '');

        // this.widgetSettings.optionTree = this.resultOptionTree;
        this.compareExperiment = widgetSettings.compare;
        this.labelXAxisVisible = widgetSettings.labelXAxisVisible;

        if (this.widgetSettings.pageSize) {
          this.pageSize = this.widgetSettings.pageSize;
          this.enableToggle = this.pageSize != 1;
        }

        if (this.widgetSettings.pageIndex) {
          this.pageIndex = this.widgetSettings.pageIndex;
        }

        if (this.widgetSettings.hiddenDatasets) {
          this.widgetSettings.hiddenDatasets.forEach((element) => {
            this.hiddenDatasets.add(element);
          });
        }
        if (this.widgetSettings.hiddenAttributes) {
          this.widgetSettings.hiddenAttributes.forEach((element) => {
            this.hiddenAttributes.add(element);
          });
        }

        if (this.widgetSettings.compareLayout) {
          this.layout = this.widgetSettings.compareLayout;
        }

        if (this.widgetSettings.legendLayout) {
          switch (this.widgetSettings.legendLayout) {
            case 'right':
            case 'left':
              this.legendBySide = true;
              break;
            case 'top':
            case 'bottom':
              this.legendBySide = false;
              break;
          }
          this.legendMode = this.widgetSettings.legendLayout;
        }

        if (this.widgetSettings.legendVisible) {
          this.legendVisible = this.widgetSettings.legendVisible;
        }

        if (this.legendBySide) {
          this.legendSize = this.widgetSettings.chartLegendWidth;
          this.legendCompareSize = this.widgetSettings.chartLegendCompWidth;
          this.legendCombineSize = this.widgetSettings.chartLegendCompCoupleWidth;
        } else {
          this.legendSize = this.widgetSettings.chartLegendHeight;
          this.legendCompareSize = this.widgetSettings.chartLegendCompHeight;
          this.legendCombineSize = this.widgetSettings.chartLegendCompCoupleHeight;
        }
        observer.next();
      }
    });
  }

  /**
   * update the minMax values of the charts
   */
  refreshMaxMinValue(): void {
    let maxValue = 0;
    let minValue = 0;
    if (this.toRenderCharts.length > 1) {
      if (
        this.toRenderCharts[0].chartData.getMaxValue(this.toRenderCharts[0].chartData.chartData) >
        this.toRenderCharts[1].chartData.getMaxValue(this.toRenderCharts[1].chartData.chartData)
      ) {
        maxValue = this.toRenderCharts[0].chartData.getMaxValue(this.toRenderCharts[0].chartData.chartData);
      } else {
        maxValue = this.toRenderCharts[1].chartData.getMaxValue(this.toRenderCharts[1].chartData.chartData);
      }
      if (
        this.toRenderCharts[0].chartData.getMinValue(this.toRenderCharts[0].chartData.chartData) <
        this.toRenderCharts[1].chartData.getMinValue(this.toRenderCharts[1].chartData.chartData)
      ) {
        minValue = this.toRenderCharts[0].chartData.getMinValue(this.toRenderCharts[0].chartData.chartData);
      } else {
        minValue = this.toRenderCharts[1].chartData.getMinValue(this.toRenderCharts[1].chartData.chartData);
      }
    } else {
      maxValue = this.toRenderCharts[0].chartData.getMaxValue(this.toRenderCharts[0].chartData.chartData);
      minValue = this.toRenderCharts[0].chartData.getMinValue(this.toRenderCharts[0].chartData.chartData);
    }
    of(null)
      .pipe(delay(0))
      .subscribe(() => {
        this.generalCharts.toArray().forEach((element) => {
          element.setMaxMinValue(minValue, maxValue);
        });
      });
  }

  /**
   * handle the generation of the compare chart after the settings are loaded
   * @param widget widget information
   * @param mainChart first chart inforamtion
   */
  getCompare(widget: Widget, mainChart: GeneralChartInformation): void {
    const chartsToRender = [];

    for (const experimentId of this.widgetSettings.experimentIds) {
      const compareExperimentObject = this.compareChartsInformations.find((item) => item.id == experimentId);
      if (!compareExperimentObject) {
        continue;
      }
      this.compareChart = new GeneralChartInformation();
      this.compareChart.index = 1;
      this.compareChart.type = mainChart.chartData.chartType;
      this.compareChart.dataRestUrl = compareExperimentObject.restURL;

      this.compareChart.widgetRefId = widget.widgetBaseId;
      this.compareChart.chartId = widget.widget.id;
      this.compareChart.experimentId = compareExperimentObject.id;
      this.compareChart.dataRestUrl = compareExperimentObject.restURL;

      if (!this.compareChart.legend) {
        this.compareChart.legend = this.getChartLegendWrapperTemplate(this.compareChart.widgetRefId);
      }
      this.subs.push(
        this.getChartData(this.compareChart).subscribe(
          (data: any) => {
            this.compareChart.chartData = new ChartData(data, mainChart.chartData.getColors());
            if (this.widgetSettings.chartOption) {
              this.compareChart.chartData.timeInterval = this.widgetSettings.chartOption.toLowerCase();
            }
            this.compareChart.legend.mapLegend(
              data.chartData.legend,
              this.compareChart.chartData.chartData,
              this.hiddenDatasets,
              this.hiddenAttributes
            );
            this.compareChart.chartData.chartType = mainChart.chartData.chartType;
            this.compareChart.chartData.setType(mainChart.chartData.chartType);

            this.experimentsList.forEach((exp) => {
              if (exp.id === this.compareChart.experimentId) {
                this.compareChart.experimentName = exp.name;
              }
            });
            this.compareChart.chartData.chartData.forEach((element) => {
              element.label += ' (' + this.compareChart.experimentName + ') ';
            });

            chartsToRender.push(mainChart);
            chartsToRender.push(this.compareChart);
            this.setRenderCharts(chartsToRender);

            this.widget = widget;

            chartsToRender.forEach((element) => {
              element.chartData.toogleChartType(this.widgetSettings.pageSize);
            });
            this.refreshMaxMinValue();
            this.loading = false;
          },
          (err) => {
            this.loading = false;
            this.toRenderCharts.push(this.mainChart);
            this.setRenderCharts(this.toRenderCharts);
            this.removeInvalidCompareChart();
            console.error(err);
          }
        )
      );
    }
  }

  /**
   * update the dispaly charts
   * @param charts charts to display
   */
  private setRenderCharts(charts) {
    this.toRenderCharts = charts;
    this.chartToolbar?.build();
    if (!this.initLoading) {
      setTimeout(() => {
        this.handleZoom();
      });
    }
  }

  /**
   * remove the comapre chart if it invalid
   */
  private removeInvalidCompareChart() {
    if (this.toRenderCharts.length >= 2) {
      this.toRenderCharts.pop();
      this.setRenderCharts(this.toRenderCharts.slice());
    }

    this.compareExperiment = false;
    this.widgetSettings.compare = false;
    this.widgetSettings.experimentIds = [];

    this.messageApi.show(
      new Message().setType(EMessageType.ERROR).setText('CHART.COMPARE.noData').setDuration(2000).setTitle('')
    );
    this.saveSettings(true);
  }

  /**
   * mapping of the pagination size options
   */
  getPageSizeOption(): void {
    const tmpSizes: Array<number> = this.optionTree.chartLimits.slice();
    let skip = false;
    if (tmpSizes.length != 1 && !isNaN(tmpSizes[0])) {
      tmpSizes.forEach((size) => (isNaN(size) ? (skip = true) : null));
    }

    if (!skip && !tmpSizes.includes(this.pageSize)) {
      if (tmpSizes.length > 0) {
        this.pageSize = tmpSizes[0];
      } else {
        this.pageSize = 1;
      }
      const event = {
        pageSize: this.pageSize,
        pageIndex: this.pageIndex,
      };
    }
    this.pageSizeOptions = tmpSizes.slice();
  }

  /**
   * initial point of widget
   * handle all information about the widget
   * handle the mainchart
   * @param widget complete widget object
   * @param chartType chart-display type
   */
  private initMainChart(widget: Widget, chartType: ChartType): Observable<any> {
    const chartsToRender = [];
    this.mainChart = new GeneralChartInformation();
    this.mainChart.index = 0;
    this.mainChart.type = chartType;
    this.mainChart.experimentId = widget.experimentId;
    this.mainChart.widgetRefId = widget.widgetBaseId;
    this.mainChart.chartId = widget.widget.id;
    this.mainChart.dataRestUrl = this.widget.dataRestUrl;
    this.loading = true;
    if (!this.mainChart.legend) {
      this.mainChart.legend = this.getChartLegendWrapperTemplate(this.mainChart.widgetRefId);
    }
    return this.loadCompareWidgets()
      .pipe(takeWhile(() => this.alive))
      .pipe(
        switchMap(() => {
          return this.loadWidgetSettings()
            .pipe(takeWhile(() => this.alive))
            .pipe(
              switchMap(() => {
                this.widget = widget;
                return this.getChartData(this.mainChart)
                  .pipe(
                    catchError((e) => {
                      this.loading = false;
                      return throwError(e);
                    })
                  )
                  .pipe(
                    tap((data: any) => {
                      this.mainChart.chartData = new ChartData(data);
                      this.defaultInterval = this.mainChart.chartData.defaultInterval;
                      if (this.widgetSettings.chartOption) {
                        this.mainChart.chartData.timeInterval = this.widgetSettings.chartOption.toLowerCase();
                      }
                      this.maxCount = this.mainChart.chartData.maxPoints;
                      this.optionTree = this.mainChart.chartData.optionTree;

                      this.mainChart.chartData.chartType = chartType;
                      this.mainChart.chartData.setType(chartType);

                      this.mainChart.legend.mapLegend(
                        data.chartData.legend,
                        this.mainChart.chartData.chartData,
                        this.hiddenDatasets,
                        this.hiddenAttributes
                      );
                      this.chartType = chartType;
                      if (this.widgetSettings.compare) {
                        this.getCompare(widget, this.mainChart);
                      } else {
                        if (this.widgetSettings.toggleChartType) {
                          this.mainChart.chartData.toogleChartType(this.widgetSettings.pageSize);
                        }

                        chartsToRender.push(this.mainChart);
                        this.setRenderCharts(chartsToRender.slice());
                      }
                      this.getPageSizeOption();

                      this.initLoading = false;

                      if (!this.chartOptionsMap.get('chartOption')) {
                        this.mainChart.chartData.timeInterval = this.mainChart.chartData.defaultInterval;
                        this.handleOptionsMenus('chartOption', this.mainChart.chartData.defaultInterval);
                      } else {
                        if (
                          !this.pageSizeOptions.includes(this.widgetSettings.pageSize) &&
                          this.pageSizeOptions.length > 0
                        ) {
                          this.pageSize = this.pageSizeOptions[0];
                          this.widgetSettings.pageSize = this.pageSizeOptions[0];
                          this.updateCharts(true);
                        } else {
                          this.loading = false;
                        }
                      }
                      this.saveSettings(true);
                    })
                  );
              })
            );
        })
      );
  }

  /**
   * Handles initial zooming of the table.
   * Directly applies the zoom that comes from the backend with the table data.
   * Relevant for the widget grid layout.
   */
  private executeInitialZoom() {
    // check if initial zoom is set
    if (this.mainChart.chartData.initialScrollStart && this.mainChart.chartData.initialScrollEnd) {
      if (!this.zoomEvent) {
        this.zoomEvent = new Zoom();
      }
      this.zoomEvent.setStart(this.mainChart.chartData.initialScrollStart);
      this.zoomEvent.setEnd(this.mainChart.chartData.initialScrollEnd);
      this.zoomEvent.setCreated(new Date());

      // apply zoom
      this.handleZoom();
    }
  }

  /**
   * handle the position of the charts
   * @param layout layout information about the charts position
   */
  private handleCompare(layout: LayoutInformation) {
    if (this.layout === layout) {
      return;
    }
    if (this.toRenderCharts.length > 1) {
      this.layout = layout;
      this.widgetSettings.compareLayout = this.layout;
      this.widget.widgetSettings = this.widgetSettings;
      this.refreshMaxMinValue();
      this.saveSettings();
    }
  }

  /**
   * handle the a chart option
   * @param key key of the chart option
   * @param option value of the option
   */
  public handleOptionsMenus(key: string, option: string): void {
    this.chartOptionsMap.set(key, option);
    if (key === 'area') {
      this.widgetSettings.chartArea = option;
    } else {
      this.widgetSettings[key] = option;
    }

    this.toRenderCharts.forEach((chart) => {
      if (this.widgetSettings.chartOption) {
        chart.chartData.timeInterval = this.widgetSettings.chartOption.toLowerCase();
      }
    });
    if (!this.initLoading) {
      this.updateCharts(true);
      this.saveSettings();
    }
  }

  /**
   * handle the button click from the toolbar of the position of the charts
   * @param mode layout mode of the charts
   */
  public handleLayoutSwitch(mode: any): void {
    this.handleCompare(mode.additionalArgs[0]);
  }

  /**
   * @returns the integrals of the main chart
   */
  public getIntegrals(): any[] {
    if (this.mainChart && this.mainChart.chartData && this.mainChart.chartData.chartData) {
      return this.mainChart.chartData.chartData.filter((data) => this.chartIntegralsMap.has(data.id));
    } else {
      return [];
    }
  }

  /**
   * remove a intergral for the given dataset-information
   * @param dataSet ChartDataGroup
   */
  public removeIntegral(dataSet: ChartDataGroup): void {
    this.mainChart.chartData.resetIntegral(dataSet.id);
    this.chartIntegralsMap.delete(dataSet.id);
    this.setChartData(this.mainChart.chartData, 0);
  }

  /**
   * open a dialog to add a integral to the mainchart
   */
  public addIntegral(): void {
    let destinationsDatasets: ChartDataGroup[] = [];
    let startDatasets: ChartDataGroup[] = [];
    if (this.mainChart.chartData.mixed) {
      destinationsDatasets = this.mainChart.chartData.chartData.filter(
        (data) => data.originType === 'line' && data.stacklessVisible && !data.hidden
      );
    } else {
      if (this.mainChart.chartData.chartType === 'line') {
        destinationsDatasets = this.mainChart.chartData.chartData.filter((data) => !data.hidden);
      }
    }
    startDatasets = destinationsDatasets.filter((data) => !this.chartIntegralsMap.has(data.id));

    const data: ChartIntegralInput = {
      startDatasets: startDatasets,
      destinationsDatasets: destinationsDatasets,
    };

    const dialogRef = this.dialog.open(ChartIntegralDialogComponent, {
      panelClass: 'edit-integral-lightbox',
      data: data,
    });

    dialogRef.afterClosed().subscribe((integralOutput: ChartIntegralOutput) => {
      if (integralOutput) {
        this.chartIntegralsMap.set(integralOutput.startId, integralOutput);
        this.mainChart.chartData.fillBetweenDataSets(
          integralOutput.startId,
          integralOutput.destinationId,
          integralOutput.connectToOrigin
        );
        this.setChartData(this.mainChart.chartData, 0);
      }
    });
  }

  /**
   * handle the toolbar clicks for change the chart type
   * @param event information of the new chart type
   */
  public handleTypeClick(event?: ISaxmsSubmenuOutput): void {
    if (!event || this.mainChart.chartData.chartType !== event.additionalArgs[0]) {
      this.toggleType();
    }
  }

  /**
   * handle the toolbar clicks for reset the settings
   */
  public resetSettings(): void {
    this.hiddenDatasets.clear();
    this.hiddenAttributes.clear();
    this.chartOptionsMap.clear();
    if (this.defaultInterval) {
      this.chartOptionsMap.set('chartOption', this.defaultInterval);
    }
    this.legendMode = 'left';
    this.pageIndex = 0;
    this.pageSize = this.pageSizeOptions.length > 0 ? this.pageSizeOptions[0] : 1;
    this.compareExperiment = false;
    this.labelXAxisVisible = true;

    if (this.toRenderCharts.length >= 2) {
      this.toRenderCharts.pop();
    }
    if (this.widgetSettings.toggleChartType) {
      this.toRenderCharts[0].chartData.toogleChartType(this.widgetSettings.pageSize);
    }

    this.setRenderCharts(this.toRenderCharts.slice());
    this.setDefaultSettings();
    this.resetWidgetSettings();
    this.noQuicksearchFilterSelection();
  }

  /**
   * Removes all integrals in chart
   */
  public resetIntegrals(): void {
    // clear integrals map
    this.chartIntegralsMap = new Map();

    // remove fillings
    this.mainChart.chartData.chartData.forEach((data) => {
      data.fill = false;
      data.zIndex = 10;
      delete data.integralData;
    });

    this.mainChart.chartData.sortDatasetByIdx();

    this.setChartData(this.mainChart.chartData, 0);
  }

  /**
   * handle toolbar button for change the visibilty of the x-axis
   * @param event information about the visibilty of the x-axis
   */
  public toogleXAxis(event): void {
    this.labelXAxisVisible = event.event.data.toggle;
    this.widgetSettings.labelXAxisVisible = this.labelXAxisVisible;
    this.saveSettings();
  }

  /**
   * handle toolbar selection of the new page size of the pagination
   * @param event information about page size of the pagination
   */
  public handlePageSize(event: ISaxmsSubmenuOutput): void {
    this.pageSize = event.event.value.value;
    this.pageIndex = 0;
    this.widgetSettings.pageSize = this.pageSize;
    this.widgetSettings.pageIndex = this.pageIndex;

    this.updateCharts(true);
    this.saveSettings();
  }

  /**
   * calacute and return the chartbody height
   * @returns the chartbody height
   */
  public getChartbodyHeight(): string {
    if (
      this.combined ||
      (this.toRenderCharts[0] && (this.toRenderCharts[0].type == 'pie' || this.toRenderCharts[0].type == 'doughnut'))
    ) {
      return this.toolbarVisible ? 'calc(100% - 118px)' : 'calc(100% - 28px)';
    } else {
      return this.toolbarVisible ? 'calc(100% - 145px)' : 'calc(100% - 55px)';
    }
  }

  /**
   * @returns the enabled state of the toolbar buttons
   */
  public enableToggleButton(): boolean {
    return (
      this.pageSize !== 1 &&
      this.mainChart &&
      this.mainChart.chartData &&
      !this.mainChart.chartData.mixed &&
      !this.mainChart.chartData.stacked &&
      this.mainChart.chartData.chartType != 'pie' &&
      this.mainChart.chartData.chartType != 'doughnut'
    );
  }

  /**
   * @returns the enabled state of the integral button in the toolbar
   */
  public enableIntegralButton(): boolean {
    return (
      this.pageSize !== 1 &&
      this.mainChart &&
      this.mainChart.chartData &&
      (this.mainChart.chartData.mixed || this.mainChart.chartData.chartType === 'line')
    );
  }

  /**
   * calculate and return a array of the current active options of the chart
   * @returns array of the current active options of the chart
   */
  public getArrayFromOptionsMap(): any[] {
    const optionsArray = [];
    this.chartOptionsMap.forEach((value, key) => {
      if (value) {
        const optionsObject = {
          key: key,
          value: value,
        };
        optionsArray.push(optionsObject);
      }
    });
    return optionsArray;
  }

  /**
   * update the current active quicksearch filter of the chart
   * @param filter new active quicksearch filter
   */
  public selectQuickSearchFilter(filter: ChartLegendQuickfilter): void {
    this.quickFilters.forEach((f) => f.setSelected(f.getId() === filter.getId()));

    this.chartLegendQuickFilterService
      .selectFilter(this.widget.widgetBaseId, filter)
      .pipe(take(1))
      .subscribe((data) => {
        this.hiddenAttributes.clear();
        filter.getHiddenAttributes().forEach((attribute) => this.hiddenAttributes.add(attribute));
        this.toRenderCharts.forEach((chart) => {
          chart.legend.updateHiddenAttributes(this.hiddenAttributes);
        });
        this.saveHiddenDatasets(this.hiddenDatasets, this.hiddenAttributes);
      });
  }

  /**
   * handle the creation of a new quicksearch filter with the current visible attributes
   * @param name name of the quicksearch filter
   * @param description description of the quicksearch filter
   * @param published flag to decide if the quicksearch filter should be visible to other users
   */
  public createQuickSearchFilter(name: string, description: string, published: boolean): void {
    const quickSearchFilter = new ChartLegendQuickfilter()
      .setName(name)
      .setDescription(description)
      .setPosition(this.quickFilters.length)
      .setHiddenAttributes(Array.from(this.hiddenAttributes.values()))
      .setPublicFilter(published)
      .setSelected(true);

    this.chartLegendQuickFilterService
      .creatQuickSearchFilter(quickSearchFilter, this.widget.widgetBaseId)
      .pipe(take(1))
      .subscribe((data) => {
        this.chartLegendQuickFilterService
          .getAllQuickFilter(this.widget.widgetBaseId)
          .pipe(take(1))
          .subscribe((filters) => {
            this.quickFilters = filters.slice();
            this.quickFilters.find((filter) => filter.getId() === data.id).setSelected(true);
            this.selectQuickSearchFilter(this.quickFilters.find((filter) => filter.getId() === data.id));
            this.chartToolbar.getLegendQuickSearch().updateValues();
          });
      });
  }

  /**
   * handle the deletion of a selected quickSearchFilter
   * @param filter quickSearchFilter
   */
  public deleteQuickSearchFilter(filter: ChartLegendQuickfilter): void {
    this.chartLegendQuickFilterService
      .deleteFilter(this.widget.widgetBaseId, filter)
      .pipe(take(1))
      .subscribe((data) => {
        this.chartLegendQuickFilterService
          .getAllQuickFilter(this.widget.widgetBaseId)
          .pipe(take(1))
          .subscribe((filters) => {
            this.quickFilters = filters.slice();
            this.chartToolbar.getLegendQuickSearch().updateValues();
          });
      });
  }

  /**
   * update the positon of the quicksearch filter inside the object
   * @param filters quickSearchFilter
   */
  public updatePostionOfQuicksearch(filters: ChartLegendQuickfilter[]): void {
    this.chartLegendQuickFilterService
      .updatePostion(this.widget.widgetBaseId, filters)
      .pipe(take(1))
      .subscribe((data) => {
        this.chartLegendQuickFilterService
          .getAllQuickFilter(this.widget.widgetBaseId)
          .pipe(take(1))
          .subscribe((filters) => {
            this.quickFilters = filters.slice();
            this.chartToolbar.getLegendQuickSearch().updateValues();
          });
      });
  }

  /**
   * updates the status if the filter is visible for other users
   * @param filters quickSearchFilter
   */
  public publishQuicksearchFilter(filters: ChartLegendQuickfilter[]): void {
    filters.forEach((filter) => {
      this.chartLegendQuickFilterService.setFilterPublic(this.widget.widgetBaseId, filter).pipe(take(1)).subscribe();
    });
  }

  /**
   * updates the status if the filter is present in the selection list in the toolbar
   * @param filters quickSearchFilter
   */
  public hideToMeQuicksearchFilter(filters: ChartLegendQuickfilter[]): void {
    filters.forEach((filter) => {
      this.chartLegendQuickFilterService
        .setFilterHideFromMe(this.widget.widgetBaseId, filter)
        .pipe(take(1))
        .subscribe();
    });
  }

  /**
   * Resets all hidden attributes.
   */
  public resetQuicksearchFilter(): void {
    this.mainChart.legend.getHiddenAttributes().clear();
    this.saveHiddenDatasets(this.mainChart.legend.getHiddenDatasets(), this.mainChart.legend.getHiddenAttributes());
  }

  /**
   * Handles backend call when no quicksearch filter is selected.
   */
  public noQuicksearchFilterSelection(): void {
    if (this.quickFilters.find((f) => f.isSelected())) {
      // set deselect to backend if any filter is selected
      this.chartLegendQuickFilterService.noFilterSelection(this.widget.widgetBaseId).pipe(take(1)).subscribe();
    }

    // deselect all filters
    this.quickFilters.forEach((f) => f.setSelected(false));
    this.chartToolbar.getLegendQuickSearch().updateQuickFilterValues();
  }

  /**
   * fired after the general-chart-component is updated
   */
  public afterUpdateChart(): void {
    this.loadingZoom = false;
  }
}
