import { takeUntil } from 'rxjs';
import { BestGantt } from '../../main';
import { GanttSelectionBoxHandler } from '../selection-box/selection-box-handler';

export class MainSelectionBoxCallbacks {
  constructor(private _ganttDiagram: BestGantt, private _selectionBoxHandler: GanttSelectionBoxHandler) {}

  /**
   * Adds selection box axis callbacks.
   */
  public initCallbacks(): void {
    this._ganttDiagram
      .getSelectionBoxFacade()
      .afterSelection.pipe(takeUntil(this._ganttDiagram.onDestroy))
      .subscribe((event) => this._selectionBoxHandler.selectShiftsBySelectionBox(event));
    this._ganttDiagram
      .getSelectionBoxFacade()
      .onDragUpdate.pipe(takeUntil(this._ganttDiagram.onDestroy))
      .subscribe((event) => this._selectionBoxHandler.draggingSelectionBox(event));
  }
}
