import { BestGantt } from '../../main';
import { BestGanttPlugIn } from '../gantt-plug-in';
import { GanttPrintViewStrategieEFW } from './print-strategies/efw-printing-strategie';
import { IGanttPrintViewStrategie } from './print-strategies/printing-strategie-interface';

/**
 * Transforms Gantt in Printable View.
 * @keywords plugin, executer, print, printing, export, view
 * @plugin print-view
 * @class
 * @constructor
 * @extends BestGanttPlugIn
 * @requires BestGanttPlugIn
 */
export class GanttPrinterExecuter extends BestGanttPlugIn {
  private _strategy: IGanttPrintViewStrategie = undefined;

  constructor() {
    super();
  }

  public initPlugIn(ganttDiagram: BestGantt) {
    this.ganttDiagram = ganttDiagram;
    this._strategy = new GanttPrintViewStrategieEFW();
  }

  public update(): void {
    this._strategy.update(null);
  }

  public removePlugIn(): void {
    this._strategy.destroy();
  }

  public printViewTransformation(): void {
    if (this._strategy) {
      this._strategy.printView(this.ganttDiagram);
    }
  }

  /**
   * Changes the current printing strategy for a new one.
   * @param strategy New printing stragegy.
   */
  public changeStrategy(strategy: IGanttPrintViewStrategie) {
    this._strategy?.destroy();
    this._strategy = strategy;
  }
}
