import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MySharedModule } from '../../shared/shared.module';
import { ChangePasswordFormComponent } from '../shared/change-password-form/change-password-form.component';
import { PasswordInfoComponent } from '../shared/password-info/password-info.component';
import { PasswordChangeComponent } from './password-change.component';

@NgModule({
  imports: [CommonModule, MySharedModule, MatProgressSpinnerModule, PasswordInfoComponent, ChangePasswordFormComponent],
  declarations: [PasswordChangeComponent],
  exports: [PasswordChangeComponent],
})
export class PasswordChangeModule {}
