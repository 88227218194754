import { Input, Component, ViewChild, Output, EventEmitter, AfterContentInit, AfterViewInit } from '@angular/core';

const discreteAxisItemWidth = 30;

@Component({
  selector: 'cinema-discrete-axis',
  templateUrl: './discrete-axis.component.html',
  styleUrls: ['./discrete-axis.scss'],
})

/**
 * Angular component to visualize the mice cinema axis which shows the order of animation steps.
 */
export class CinemaDiscreteAxis implements AfterViewInit {
  ngAfterViewInit(): void {
    this.reducedEventLog = this.compressLog(this.eventLog, this._eventPosition);
  }

  @Input('eventLog') set fullEventLog(eventLog: any[]) {
    this.eventLog = eventLog;
    this.reducedEventLog = this.compressLog(this.eventLog, this._eventPosition);
  }
  @Input()
  set eventPosition(value: number) {
    this._eventPosition = value;
    if (this._eventPosition == this.eventLog.length - 1) {
      this.running = false;
    }

    if (this.eventLog[value] && this.eventLog[value].timestamp) {
      this.timestamp = this.eventLog[value].timestamp;
    }

    this.reducedEventLog = this.compressLog(this.eventLog, value);
  }

  @Output('start') startCinema: EventEmitter<boolean> = new EventEmitter();
  @Output('pause') pauseCinema: EventEmitter<boolean> = new EventEmitter();
  @Output('stop') stopCinema: EventEmitter<boolean> = new EventEmitter();
  @Output() fastForward: EventEmitter<boolean> = new EventEmitter();
  @Output() fastForwardToItem: EventEmitter<number> = new EventEmitter();
  @Output() fastRevert: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('eventItemsWrapper') itemListWrapper: any;

  public _eventPosition = 0;
  public eventLog: any[] = [];
  public reducedEventLog: any[] = [];
  public running = false;
  public timestamp: Date;

  /**
   * Starts axis step visualization.
   */
  public onPlay(): void {
    this.startCinema.emit(true);
    this.running = true;
  }

  /**
   * Pauses axis step visualization.
   */
  public onPause(): void {
    this.pauseCinema.emit(true);
    this.running = false;
  }

  /**
   * Stops axis step visualization.
   */
  public onStop(): void {
    this.stopCinema.emit(true);
    this.running = false;
  }

  /**
   * Fast forwards axis step visualization.
   */
  public onFastForward(): void {
    this.fastForward.emit(true);
  }

  /**
   * Reverts axis step visualization.
   */
  public onFastRevert(): void {
    this.fastRevert.emit(true);
  }

  /**
   * Jumps to animation step by given index.
   * @param index Animation stap index.
   */
  public onFastForwardToItem(index: number): void {
    this.fastForwardToItem.emit(index + this._eventPosition);
  }

  /**
   * Provides GUI element style by given event.
   * @param eventType Animation event type.
   */
  public getStyleByEventType(
    eventType: 'addToStack' | 'machineWorkStart' | 'machineWorkEnd' | 'moveToken'
  ): CinemaEventStyleItem {
    const styleItem: CinemaEventStyleItem = new CinemaEventStyleItem();

    switch (eventType) {
      case 'addToStack':
        styleItem.color = '#e8b337';
        styleItem.matIcon = 'playlist_add';
        break;
      case 'machineWorkStart':
        styleItem.color = '#3079c9';
        styleItem.matIcon = 'play_circle_outline';
        break;
      case 'machineWorkEnd':
        styleItem.color = '#359947';
        styleItem.matIcon = 'check';
        break;
      case 'moveToken':
        styleItem.color = '#7730c9';
        styleItem.matIcon = 'redo';
        break;
    }

    return styleItem;
  }

  /**
   * Cuts out a list of event items to show them inside the axis.
   * @param eventLog List of all event logs.
   * @param index Current event index.
   */
  public compressLog(eventLog: any[], index: number): any[] {
    if (!this.itemListWrapper) return [];
    if (!this.itemListWrapper.nativeElement) return eventLog;

    const listWidth = this.itemListWrapper.nativeElement.offsetWidth,
      itemCount = Math.ceil(listWidth / discreteAxisItemWidth);

    return eventLog.slice(index, index + itemCount);
  }
}

/**
 * Data structure to describe style of one GUI element inside mice cinema axis.
 */
export class CinemaEventStyleItem {
  color = '#000000';
  matIcon = '';
}
