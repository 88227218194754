import { Resource } from 'frontend/src/dashboard/model/resource/resource';

export class Credentials extends Resource {
  private userId: string;
  private refreshToken: string;
  private accessToken: string;
  private passwordExpired: boolean;

  public isPasswordExpired(): boolean {
    return typeof this.passwordExpired == 'boolean' ? this.passwordExpired : false;
  }

  public setPasswordExpired(passwordExpired: boolean): this {
    this.passwordExpired = passwordExpired;
    return this;
  }

  public getUserId(): string {
    return this.userId;
  }

  public setUserId(userId: string): this {
    this.userId = userId;
    return this;
  }

  public getRefreshToken(): string {
    return this.refreshToken;
  }

  public setRefreshToken(refreshToken: string): this {
    this.refreshToken = refreshToken;
    return this;
  }

  public getAccessToken(): string {
    return this.accessToken;
  }

  public setAccessToken(accessToken: string): this {
    this.accessToken = accessToken;
    return this;
  }
}
