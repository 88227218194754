<button
  [style.height]="stretched ? '100%' : 'auto'"
  [ngClass]="stretched ? 'stretched' : ''"
  [disabled]="isDisabled"
  *ngIf="selector"
  mat-button
  (click)="onClick($event, selector)"
>
  <span *ngIf="value?.getValue()" class="upper-value" [ngClass]="value?.getHint() ? 'has-lower-value' : ''">
    <b> {{ value?.getValue() }}</b></span
  >
  <span *ngIf="value?.getHint()" class="lower-value">
    {{ value?.getHint() | date : ('DATE.FORMATS.DATETIME' | translate) }}
  </span>
  <mat-icon class="icon">chevron_right</mat-icon>
</button>
