import { EGanttInstance } from '@gantt/public-api';
import { SaxMsBestGanttActiveSubmenuEntryElementSetting } from 'frontend/src/dashboard/gantt/gantt/saxms-best-gantt.settings';
import { interval, Observable, of } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { ExternalGanttPlugin } from '../../external-plugin';

const GanttCurrentTimePointExecuterId = 'gantt-plugin-current-timepoint-executer';

/**
 * PlugIn-Wrapper for GanttCurrentTimePointMarker.
 */
export class GanttCurrentTimePointMarkerPlugIn extends ExternalGanttPlugin {
  private readonly currentTimeColor: string = 'red';

  /**
   * Init current timepoint marker.
   * Checks therefore if backend provides current timepoint.
   * If not, current timepoint will not be shown.
   * @param templateData
   * @param responseData Hierarchical plan from backend.
   */
  public onInit(templateData: any, responseData: any): void {
    if (!responseData.hierarchicalPlan) return;
    const currentTime = responseData.hierarchicalPlan.current;

    if (currentTime) {
      this.addPlugIn(
        GanttCurrentTimePointExecuterId,
        this._ganttLibService.ganttInstanceService.getInstance(
          EGanttInstance.CURRENT_TIME_POINT_MARKER,
          true,
          this.currentTimeColor,
          false,
          new Date(currentTime)
        )
      );

      this._ganttLibService.ngZone.runOutsideAngular(() => {
        interval(1000)
          .pipe(takeWhile(() => this.actionHandler.isAlive()))
          .subscribe(() => {
            this.getPlugInById(GanttCurrentTimePointExecuterId)?.keepMarkerUpdated();
          });
      });
    }
  }

  public onDestroy(): void {}

  public onAction(action: any) {}

  public executeAction(action: any): Observable<any> {
    return of();
  }

  public injectSettings(submenuElements: SaxMsBestGanttActiveSubmenuEntryElementSetting[]): void {}

  /**
   * Makes current timepoint marker visible.
   */
  public show(): void {
    this.getPlugInById(GanttCurrentTimePointExecuterId).setRender(true);
  }

  /**
   * Hides current timepoijt marker.
   */
  public hide(): void {
    this.getPlugInById(GanttCurrentTimePointExecuterId).setRender(false);
  }
}
