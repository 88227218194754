import { Injectable, Type } from '@angular/core';
import { Adapter } from '@core/adapter';
import { TemplateAdapter } from '@app-modeleditor/utils/template-factory.service';
import { EntryElementFactory } from './../../entry-collection/entry-factory.service';
import { LabeledText } from './labeled-text';

@Injectable({
  providedIn: 'root',
})
export class LabeledTextAdapter implements Adapter<LabeledText> {
  constructor(private entryElementAdapter: EntryElementFactory) {}

  adapt(item: any): LabeledText {
    throw new Error('Method not implemented.');
  }
  inherit?<T extends LabeledText>(type: Type<T>, item: any): T {
    const t: T = this._getData(this.entryElementAdapter.inherit<T>(type, item), item);
    return t;
  }
  inheritFrom?<T extends LabeledText>(scope: TemplateAdapter, type: Type<T>, item: any): T {
    const t: T = this._getData(this.entryElementAdapter.inheritFrom<T>(scope, type, item), item);
    return t;
  }
  applyValues<T>(scope: TemplateAdapter, item: any, values: any): T {
    throw new Error('Method not implemented.');
  }

  private _getData<T extends LabeledText>(t: T, item: any): T {
    t.setBreakLine(item.breakLine);
    return t;
  }
}
