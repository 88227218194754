import * as d3 from 'd3';
import { GanttCanvasShift } from '../../data-handler/data-structure/data-structure';
import { GanttScrollContainerEvent } from '../../html-structure/scroll-container-event';
import { BestGantt } from '../../main';
import { ETimeMarkerAnchor } from '../../x-axis/x-axis';

/**
 * Handler methods for shift mouse hover events.
 * @class
 */
export class GanttShiftMouseHoverHandler {
  ganttDiagram: BestGantt;
  private _subscriptions: any;

  constructor(ganttDiagram) {
    this.ganttDiagram = ganttDiagram;

    this._subscriptions = {
      listenToRippleOnClick: null,
    };
  }

  /**
   * Callback to mark x axis during timespan mouseover (like shift).
   * @keywords callback, markers, remove, xaxis, x axis
   * @param mouseOverEvent
   */
  public xAxisDateMarkers(mouseOverEvent: GanttScrollContainerEvent<MouseEvent>): void {
    const s = this.ganttDiagram;

    if (!mouseOverEvent || !mouseOverEvent.event.target) return;
    const data = d3.select<any, GanttCanvasShift>(mouseOverEvent.event.target).data()[0];
    s.markShiftXAxisByCanvasData(data);
  }

  /**
   * Callback to mark x axis during timepoint mouseover (for milestones).
   * @keywords callback, mark, shift, xaxis, x axis
   * @private
   * @param {MouseEvent} mouseOverEvent
   */
  _xAxisDateMarker(mouseOverEvent) {
    const s = this.ganttDiagram;

    if (!mouseOverEvent || !mouseOverEvent.target) return;
    const data: any = d3.select(mouseOverEvent.target).data()[0],
      zoomTransformation = s.getXAxisBuilder().getLastZoomEvent();
    s.getXAxisBuilder().addMarkerByPoints(
      data.x * zoomTransformation.k + zoomTransformation.x,
      ETimeMarkerAnchor.START
    );
  }

  /**
   * Callback function to decrease x axis opacity.
   */
  public deHighlightXAxis(): void {
    const s = this.ganttDiagram;
    s.getXAxisBuilder().getAxisContainer().transition().style('opacity', '0.6');
  }

  /**
   * Callback to highlight shift while shift mouseover.
   * @param mouseOverEvent
   */
  public highlightShiftOnHover(mouseOverEvent: GanttScrollContainerEvent<MouseEvent>): void {
    const s = this.ganttDiagram;
    const target = d3.select<SVGRectElement, GanttCanvasShift>(mouseOverEvent.event.target as SVGRectElement);
    const targetData = target.data()[0];
    const shiftContainer = s.getShiftFacade().getShiftWrapperOverlay();
    const zoomTransformation = s.getXAxisBuilder().getLastZoomEvent();
    const shiftBuilder = s.getShiftFacade().getShiftBuilder(mouseOverEvent.source);
    const clipPathHandler = shiftBuilder.getClipPathHandler();

    const x = targetData.x * zoomTransformation.k + zoomTransformation.x;
    const y = shiftBuilder.getShiftCalculationStrategy().getShiftViewportY(targetData);
    const width = targetData.width * zoomTransformation.k;

    const hoverElementNode = document.createElement('div');
    shiftContainer
      .node()
      .insertBefore(
        hoverElementNode,
        shiftContainer.select<SVGSVGElement>('.gantt_shifts-wrapper-overlay-svg').node().nextSibling
      );

    const hoverElement = d3
      .select(hoverElementNode)
      .attr('class', 'gantt_highlight_on_hover')
      .style('position', 'absolute')
      .style('overflow', 'hidden')
      .style('width', width + 'px')
      .style('height', targetData.height + 'px')
      .style('left', x + 'px')
      .style('top', y + 'px')
      .style('background-color', 'rgba(255, 255, 255, 0.2)')
      .style('border-radius', () => {
        if (s.getConfig().getShiftBuildingRoundedCorners() && !targetData.noRoundedCorners) {
          return s.getConfig().getRoundedCorners() + 'px';
        } else {
          return 0;
        }
      })
      .style('pointer-events', 'none')
      .style('clip-path', clipPathHandler.getHtmlClipPath({ x: x, y: y, width: width, height: targetData.height }));

    const hoverBounding = hoverElement.node().getBoundingClientRect();

    function createRippleOnClick(event: d3.ClientPointEvent): void {
      const cutX = x < 0 ? -x : 0;
      const cutWidth = Math.min(width - cutX, s.getNodeProportionsState().getShiftViewPortProportions().width);
      const diameter = Math.max(cutWidth, parseFloat(target.attr('height')));
      const radius = diameter / 2;

      // remove old ripple if exists
      hoverElement.selectAll('.shift-overlay-ripple').remove();

      hoverElement
        .append('span')
        .attr('class', 'shift-overlay-ripple')
        .style('width', `${diameter}px`)
        .style('height', `${diameter}px`)
        .style('left', `${event.clientX - (hoverBounding.x + radius)}px`)
        .style('top', `${event.clientY - (hoverBounding.y + radius)}px`);
    }
    this._subscriptions.listenToRippleOnClick = s
      .getShiftFacade()
      .shiftOnMouseDown()
      .subscribe((event) => createRippleOnClick(event.event));
  }

  /**
   * Callback to remove all highlight shift cover.
   */
  public removeHighlightShiftOnHover(): void {
    const s = this.ganttDiagram;
    const shiftContainer = s.getShiftFacade().getShiftWrapperOverlay();

    shiftContainer.selectAll('.gantt_highlight_on_hover').remove();
    if (this._subscriptions.listenToRippleOnClick) this._subscriptions.listenToRippleOnClick.unsubscribe();
  }

  /**
   * Callback to mark due dates while shift mouseover.
   * @param mouseOverEvent
   */
  public markDueDatesMouseOver(mouseOverEvent: GanttScrollContainerEvent<MouseEvent>): void {
    const target = d3.select<SVGRectElement, GanttCanvasShift>(mouseOverEvent.event.target as SVGRectElement);
    const targetData = target.data()[0];
    this.ganttDiagram.markShiftDueDates(targetData.id);
  }
}
