<mat-icon *ngIf="control?.errors?.pattern" [matMenuTriggerFor]="errorMenu" class="error-icon"> info </mat-icon>

<mat-menu #errorMenu="matMenu" [class]="'customize-error'">
  <p class="error-box">
    <ng-container *ngIf="control.invalid; else regexTemp">
      {{ errorMsg | translate }}
    </ng-container>
    <ng-template #regexTemp>
      {{ entryElement.getValidationRegexInfoText() }}
    </ng-template>
  </p>
</mat-menu>
