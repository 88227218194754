import { ETemplateType } from 'frontend/src/dashboard/model/resource/template-type';
import { ContentHeader } from './content-header';
import { ContentPart } from './content-part/content-part';
import { StructuralElement } from './structural-element';

export class Content extends StructuralElement {
  private contentHeader: ContentHeader;
  private contentParts: ContentPart[];
  private renderOnlyVisibleContentParts: boolean;

  public isRenderOnlyVisibleContentParts(): boolean {
    return typeof this.renderOnlyVisibleContentParts === 'boolean' ? this.renderOnlyVisibleContentParts : false;
  }

  public setRenderOnlyVisibleContentParts(renderOnlyVisibleContentParts: boolean): this {
    this.renderOnlyVisibleContentParts = renderOnlyVisibleContentParts;
    return this;
  }

  constructor() {
    super();
    this.setType(ETemplateType.CONTENT);
  }

  /**
   * get content header
   * @returns ContentHeader
   */
  public getContentHeader(): ContentHeader {
    return this.contentHeader;
  }

  /**
   * set content header
   * @param contentHeader ContentHeader
   * @returns this
   */
  public setContentHeader(contentHeader: ContentHeader): this {
    this.contentHeader = contentHeader;
    return this;
  }
  /**
   * get content parts
   * @returns ContentPart[]
   */
  public getContentParts(): ContentPart[] {
    return this.contentParts || [];
  }

  /**
   * set content parts
   * @params contentParts ContentPart[]
   * @returns this
   */
  public setContentParts(contentParts: ContentPart[]): this {
    this.contentParts = contentParts;
    this.getContentParts().forEach((p: ContentPart) => p.setParentId(this.getId()));
    return this;
  }

  public addContentParts(...contentParts: ContentPart[]): this {
    this.contentParts = this.getContentParts().concat(contentParts);
    return this;
  }

  public addContentPartsAtIndex(index: number, ...contentParts: ContentPart[]): this {
    (contentParts || []).forEach((c: ContentPart, i: number) => {
      if (!this.contentParts) {
        this.contentParts = [];
      }
      this.contentParts.splice(index + i, 0, c);
    });
    return this;
  }
}
