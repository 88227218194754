import { Template } from 'frontend/src/dashboard/model/resource/template';
import { ETemplateType } from './../../../model/resource/template-type';

export class StatusIcon extends Template {
  private color: string;
  private colorFieldIdentifier: string;
  private icon: string;
  private notificationCounter: number;
  private toolTipFieldIdentifier: string;
  private tooltip: string;
  private elementId: string;

  public getElementId(): string {
    return this.elementId;
  }

  public setElementId(elementId: string): this {
    this.elementId = elementId;
    return this;
  }

  public getTooltip(): string {
    return this.tooltip;
  }

  public setTooltip(tooltip: string): this {
    this.tooltip = tooltip;
    return this;
  }

  constructor() {
    super();
    this.setType(ETemplateType.STATUS_ICON);
  }

  public getColor(): string {
    return this.color;
  }

  public setColor(color: string): this {
    this.color = color;
    return this;
  }

  public getColorFieldIdentifier(): string {
    return this.colorFieldIdentifier;
  }

  public setColorFieldIdentifier(colorFieldIdentifier: string): this {
    this.colorFieldIdentifier = colorFieldIdentifier;
    return this;
  }

  public getIcon(): string {
    return this.icon;
  }

  public setIcon(icon: string): this {
    this.icon = icon;
    return this;
  }

  public getNotificationCounter(): number {
    return this.notificationCounter;
  }

  public setNotificationCounter(notificationCounter: number): this {
    this.notificationCounter = notificationCounter;
    return this;
  }

  public getToolTipFieldIdentifier(): string {
    return this.toolTipFieldIdentifier;
  }

  public setToolTipFieldIdentifier(toolTipFieldIdentifier: string): this {
    this.toolTipFieldIdentifier = toolTipFieldIdentifier;
    return this;
  }
}
