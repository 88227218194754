import { EGanttInstance } from '@gantt/public-api';
import { GanttActionWrapper } from 'frontend/src/dashboard/gantt/general/gantt-actions/external-action-registration';
import { GanttEventTrigger } from 'frontend/src/dashboard/gantt/general/gantt-actions/external-event-trigger';
import { takeWhile } from 'rxjs/operators';
import { ShiftSplitterAdditionalData } from '../../resource-change.plugin';
import { GanttResourceChangeMode } from './../../resource-change.mode';

/**
 * Onclick event which will be triggered if user uses ResourceChangeShift-plugin to split a shiftblock.
 */
export class ResourceChangeShiftOnClickEvent extends GanttEventTrigger {
  /**
   * Registers event to onclick callback of mouseposition based timepoint marker.
   * @param ganttDiagram BestGantt where the plugin has been initialized.
   */
  public registerInGantt(): void {
    this.executer.addOnSplitCallback(this.action.id, (onClickParam) => {
      const foundShiftData = this._ganttLibService.ganttInstanceService
        .getInstance(EGanttInstance.SHIFT_DATA_FINDER)
        .getShiftById(
          this._ganttLibService.bestGantt.getDataHandler().getOriginDataset().ganttEntries,
          onClickParam.shiftID
        );
      if (!foundShiftData?.shift || !foundShiftData?.shiftRow) return;
      if (this.isInCorrectPlugInMode(this.action.activatedByAction)) {
        this.saveDataToTemplate(onClickParam, foundShiftData.shift, foundShiftData.shiftRow);
        const actionWrapper: GanttActionWrapper = {
          actionScope: this.action.actionScope,
          actions: this.action.actions,
        };
        this._ganttLibService.ngZone.run(() => {
          this.actionHandler
            .executeActions(actionWrapper)
            .pipe(takeWhile(() => this.actionHandler.isAlive()))
            .subscribe(
              (data) => {
                this.responseHandler.handleUpdateNotification(data as any);
              },
              (error) => {},
              () => {
                this.setPlugInToSleep();
              }
            );
        });
      }
    });
  }

  /**
   * Function to remove callback/action from given gantt.
   * @param ganttDiagram BestGantt.
   */
  public removeFromGantt(): void {
    super.removeFromGantt();
    this.executer.removeOnSplitCallback(this.action.id);
  }

  /**
   * Changes plugin mode to inactive.
   * Exits interactive timepoint marker mode.
   */
  public setPlugInToSleep(): void {
    this.executer.exitShiftSplitMode();
    (this.executer.getAdditionalData() as ShiftSplitterAdditionalData).state = GanttResourceChangeMode.SLEEP;
  }

  /**
   * Saves event data to template.
   * @param callback Callback data of onlick.
   * @param shiftData Data of clicked shift block.
   * @param rowData Data of parent row of clicked shit block.
   */
  public saveDataToTemplate(callback: any, shiftData: any, rowData: any): void {
    // set temaplate data

    // ? if (!this.templateData.selectedblock) this.templateData.selectedblock = {};
    this.templateData.setSelectedblock({
      // I think this is not the right parameter ??? (maybe use setSelectedBlock with "B" instead "b")
      id: shiftData.id,
      start: new Date(shiftData.timePointStart).getTime(),
      end: new Date(shiftData.timePointEnd).getTime(),
      targetId: rowData.id,
      clickPoint: new Date(callback.cutDate).getTime(),
    });

    /* ?
     this.templateData.selectedblock.id = shiftData.id;
    this.templateData.selectedblock.start = new Date(shiftData.timePointStart).getTime();
    this.templateData.selectedblock.end = new Date(shiftData.timePointEnd).getTime();
    this.templateData.selectedblock.targetId = rowData.id;
    this.templateData.selectedblock.clickPoint = new Date(callback.cutDate).getTime();
    */
  }

  /**
   * Checks if plugin has correct mode so it can handle the given action.
   * @param activatedByAction Local action which triggeres a plugin action.
   */
  private isInCorrectPlugInMode(activatedByAction: any): boolean {
    if (!activatedByAction) return true;
    switch (activatedByAction.id) {
      case GanttResourceChangeMode.CREATE:
        return (
          (this.executer.getAdditionalData() as ShiftSplitterAdditionalData).state == GanttResourceChangeMode.CREATE
        );
      case GanttResourceChangeMode.EDIT:
        return (this.executer.getAdditionalData() as ShiftSplitterAdditionalData).state == GanttResourceChangeMode.EDIT;
      case GanttResourceChangeMode.DELETE:
        return (
          (this.executer.getAdditionalData() as ShiftSplitterAdditionalData).state == GanttResourceChangeMode.DELETE
        );
    }
    return false;
  }
}
