import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ImageModule } from '@app-modeleditor/components/image/image.module';
import { MessageBar } from './message-bar.interface';

@Component({
  selector: 'app-message-bar',
  templateUrl: './message-bar.component.html',
  styleUrls: ['./message-bar.component.scss'],
  standalone: true,
  imports: [CommonModule, MatIconModule, MatButtonModule, ImageModule],
})
export class MessageBarComponent {
  @Input() data: MessageBar;

  protected isActive = true;
}
