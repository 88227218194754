import { EntryElement } from '@app-modeleditor/components/entry-collection/entry-element';
import { EFieldType } from '@app-modeleditor/components/entry-collection/field-type.enum';

export class LabeledText extends EntryElement {
  private breakLine: boolean;

  public isBreakLine(): boolean {
    return typeof this.breakLine === 'boolean' ? this.breakLine : false;
  }

  public setBreakLine(breakLine: boolean): this {
    this.breakLine = breakLine;
    return this;
  }

  constructor() {
    super();
    this.setFieldType(EFieldType.LABELED_TEXT);
  }
}
