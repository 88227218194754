import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FreetextFilterService {
  private _filterNode: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  public onApplyFilterToObject: Observable<any>;
  private onApplyFilterToObjectSub: Subject<any>;

  public onFilterChanged: Observable<any>;
  private onFilterChangedSub: Subject<any>;

  constructor(private http: HttpClient) {
    this.onApplyFilterToObjectSub = new Subject<any>();
    this.onApplyFilterToObject = this.onApplyFilterToObjectSub.asObservable();

    this.onFilterChangedSub = new Subject<any>();
    this.onFilterChanged = this.onFilterChangedSub.asObservable();
  }

  /**
   * gets filter node
   */
  getFilterNode(): Observable<any> {
    return this._filterNode.asObservable();
  }

  /**
   * applies filter to specified object
   * @param filterObject object to apply the filter on
   */
  public applyFilterToObject(filterObject: any): void {
    this.onApplyFilterToObjectSub.next(filterObject);
    this._filterNode.next(filterObject);
  }

  public changeFilter(filter: any): void {
    this.onFilterChangedSub.next(filter);
  }

  public triggerNotification(): Observable<any> {
    return this.http.get(`rest/template/path/trigger/notification`);
  }

  public getPath(canonicalName: string, resourceId: string): Observable<any> {
    return this.http.get(`rest/template/path/${canonicalName}/${resourceId}`);
  }

  public applyFilter(templateId: string, hmiId: string, filter: string): Observable<any> {
    return this.http.get(`rest/template/${templateId}/filter/hmi/${hmiId}/freetextsearch?value="${filter}"`);
  }

  public getFilter(templateId: string, hmiId: string): Observable<any> {
    return this.http.get(`rest/template/{templateId}/filter/hmi/{hmiId}/filtersearch`);
  }
}
