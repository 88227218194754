<!-- <button (click)="test()">test</button> -->
<!-- <div class="list-wrapper mat-elevation-z4"> -->
<div class="list-wrapper">
  <!-- <div>
    <template-slot (afterInit)="afterInitSlot($event)"></template-slot>
  </div> -->
  <h3>{{ data.getHeadline() }}</h3>
  <ng-container *ngIf="!isRefreshing">
    <div class="example-container">
      <!-- <h2>{{'LABEL.available'|translate}}</h2> -->
      <div style="display: flex; align-items: center; justify-content: start">
        <mat-form-field class="filter">
          <input
            matInput
            [placeholder]="'LABEL.available' | translate"
            [(ngModel)]="searchTermLeft"
            (change)="filterLists($event)"
          />
          <button mat-icon-button matPrefix (click)="filterLists()">
            <mat-icon>search</mat-icon>
          </button>
          <button *ngIf="searchTermLeft" mat-icon-button matSuffix (click)="filterLists((searchTermLeft = ''))">
            <mat-icon>clear</mat-icon>
          </button>
          <span matSuffix class="amount-suffix">{{ numberOfAvailableItems }}</span>
        </mat-form-field>
        <button (click)="sortLeft = sortLeftItems(availableListItems, sortLeft)" mat-icon-button>
          <mat-icon>{{
            sortLeft === 'asc' ? 'arrow_upward' : sortLeft === 'desc' ? 'arrow_downward' : 'sort'
          }}</mat-icon>
        </button>
      </div>
      <div
        cdkDropList
        #todoList="cdkDropList"
        #todoScroller
        [cdkDropListData]="availableListItems"
        [cdkDropListConnectedTo]="[doneList]"
        class="example-list"
        (cdkDropListDropped)="drop($event, todoScroller)"
      >
        <mat-list>
          <ng-container *ngFor="let item of availableListItems; trackBy: trackByFn; let idx = index">
            <div
              #rootElement
              class="example-box"
              (dblclick)="dblClick($event, item, availableListItems, 'left')"
              (click)="select($event, item, availableListItems, 'left')"
              cdkDrag
              [class.selected]="isSelected(item)"
              [class.dragging]="dragging"
              [ngClass]="getBorderDefinition(item, availableListItems, idx)"
              (cdkDragStarted)="onDragStart($event, item, availableListItems)"
              (cdkDragEnded)="onDragEnd($event)"
            >
              <ng-container *ngIf="selectedElements">
                <span *cdkDragPreview class="selected-badge">ausgewählt: {{ selectedElements.length }}</span>
              </ng-container>
              <div [style.height]="itemHeight + 'px'" class="drag-placeholder" *cdkDragPlaceholder></div>
              <ng-container *ngIf="item.isSimpleElement(); else defaultItem">
                <span class="simple-element">{{ item.getFloatLeft() }}</span>
              </ng-container>
              <ng-template #defaultItem>
                <template-slot [source]="item" *ngIf="item" (afterInit)="afterSlotInit($event, rootElement)">
                </template-slot>
              </ng-template>
            </div>
          </ng-container>
        </mat-list>
      </div>
    </div>
    <div class="action-bar">
      <div style="text-align: center">{{ selectedElements?.length }}</div>
      <template-slot [source]="collection"></template-slot>
    </div>
    <div class="example-container">
      <!-- <h2>{{'LABEL.selected'|translate}}</h2> -->
      <div style="display: flex; align-items: center; justify-content: start">
        <mat-form-field class="filter">
          <input
            matInput
            [placeholder]="'LABEL.selected' | translate"
            [(ngModel)]="searchTermRight"
            (change)="filterLists($event)"
          />
          <button mat-icon-button matPrefix (click)="filterLists()">
            <mat-icon>search</mat-icon>
          </button>
          <button *ngIf="searchTermRight" mat-icon-button matSuffix (click)="filterLists((searchTermRight = ''))">
            <mat-icon>clear</mat-icon>
          </button>
          <span matSuffix class="amount-suffix">{{ numberOfSelectedItems }}</span>
        </mat-form-field>
        <button (click)="sortRight = sortRightItems(selectedListItems, sortRight)" mat-icon-button>
          <mat-icon>{{
            sortRight === 'asc' ? 'arrow_upward' : sortRight === 'desc' ? 'arrow_downward' : 'sort'
          }}</mat-icon>
        </button>
      </div>
      <div
        cdkDropList
        #doneList="cdkDropList"
        #doneScroller
        [cdkDropListData]="selectedListItems"
        [cdkDropListConnectedTo]="[todoList]"
        class="example-list"
        (cdkDropListDropped)="drop($event, doneScroller)"
      >
        <mat-list>
          <ng-container *ngFor="let item of selectedListItems; trackBy: trackByFn; let idx = index">
            <div
              #rootElement
              class="example-box"
              (dblclick)="dblClick($event, item, selectedListItems, 'right')"
              (click)="select($event, item, selectedListItems, 'right')"
              cdkDrag
              [class.selected]="isSelected(item)"
              [class.dragging]="dragging"
              (cdkDragStarted)="onDragStart($event, item, selectedListItems)"
              (cdkDragEnded)="onDragEnd($event)"
              [ngClass]="getBorderDefinition(item, selectedListItems, idx)"
            >
              <ng-container *ngIf="selectedElements">
                <span *cdkDragPreview class="selected-badge">ausgewählt: {{ selectedElements.length }}</span>
              </ng-container>
              <div [style.height]="itemHeight + 'px'" class="drag-placeholder" *cdkDragPlaceholder></div>
              <ng-container *ngIf="item.isSimpleElement(); else defaultItem">
                <span class="simple-element">{{ item.getFloatLeft() }}</span>
              </ng-container>
              <ng-template #defaultItem>
                <template-slot [source]="item" *ngIf="item" (afterInit)="afterSlotInit($event, rootElement)">
                </template-slot>
              </ng-template>
            </div>
          </ng-container>
        </mat-list>
      </div>
    </div>
  </ng-container>
</div>
