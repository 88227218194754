import { Component, OnDestroy } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { UserService } from 'frontend/src/dashboard/user/data-access/user.service';
import { Subject } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { HorizonService, IHorizon } from '../../../horizonselecter/horizon.service';
import { PlanningAlgorithm, PlanningHorizon } from '../../../horizonselecter/horizonselecter';
import { ModelService } from '../../../model/model.service';
import { ObjectPermission } from '../../../system/globals/globals.service';
import { ViewService } from './../../view.service';
import { SelectorComponent } from './selector.component';

@Component({
  selector: 'horizon-selector',
  templateUrl: './selector.html',
  styleUrls: ['./selector.scss'],
  providers: [],
  host: {
    '(window:resize)': 'onResize($event)',
  },
})
export class HorizonSelecterComponent extends SelectorComponent implements OnDestroy {
  alive = true;
  public nameRequired = false;
  public isRename = false;
  public dropHorizon: Array<PlanningHorizon> = [];
  public showLastItem = false;
  public enableMovingTabs = false;
  public selectedHorizon: PlanningHorizon = new PlanningHorizon('', '', '', '', false, null);
  public visibleHorizons = 4;
  public editable = false;
  public data: any = '';
  public menuPartWidth = 50;
  public selectedTimeFrame: any = 'days';
  public selectedTimeFrameDuration = 0;
  public editModus = false;
  public localPermission: ObjectPermission = new ObjectPermission();
  public planningAlgorithms: Array<PlanningAlgorithm> = [];
  public selectedAlgorithms: Array<PlanningAlgorithm> = [];
  public currentHorizon: string;

  public _showEditDialog: Subject<any> = new Subject();
  public _showRemoveDialog: Subject<any> = new Subject();

  public _contextmenuActions = [
    {
      title: 'rename',
      callback: this._showEditDialog,
    },
    {
      title: 'remove',
      callback: this._showRemoveDialog,
    },
  ];

  constructor(
    public hsService: HorizonService,
    public formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    public sharedModelService: ModelService,
    public systemMessage: MatSnackBar,
    public globalViewService: ViewService,
    public globalUserSettingsService: UserService
  ) {
    super(globalViewService);
    this.title = '@horizons@';
    this.actions = [
      {
        callback: this.showWizard.bind(this),
        title: 'new',
        icon: 'add',
      },
    ];

    this.hsService
      .getCurrenHorizon()
      .pipe(takeWhile(() => this.alive))
      .subscribe((horizon: IHorizon) => {
        this.selected = horizon;
      });
    this.hsService
      .getHorizons()
      .pipe(takeWhile(() => this.alive))
      .subscribe((horizons: IHorizon[]) => {
        this.items = horizons;
      });
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  public onResize(event: any) {}

  showWizard() {
    // this.horizonSelecterWizard.open();
    /* let dialogRef = this.dialog.open(HorizonDialogComponent);

    dialogRef.afterClosed().subscribe(horizon => {
      if (horizon) {
        this.items.push(horizon);
        this.items = this.items.slice();
      }
    });*/
  }

  public activeHorizons: Array<any> = [];
  public model: any;

  ngOnInit() {}

  // mark the clicked planner horizon
  setActive(horizon: IHorizon): void {
    this.hsService.setCurrentHorizon(horizon);
  }

  reset(): void {
    this.nameRequired = false;
    this.selectedTimeFrame = 'days';
    this.selectedTimeFrameDuration = 0;
    this.selectedAlgorithms = [];
    this.planningAlgorithms = [];
  }

  getPermission(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.hsService.getPermissions().subscribe(
        (data: any) => {
          this.localPermission.getAllow = data.readPermission;
          this.localPermission.putAllow = data.createPermission;
          this.localPermission.postAllow = data.editPermission;
          this.localPermission.deleteAllow = data.deletePermission;
          resolve(data);
        },
        (err) => {
          reject();
        },
        () => {}
      );
    });
  }
}
