import { Component, Input, OnInit } from '@angular/core';
import { EntryElement } from '@app-modeleditor/components/entry-collection/entry-element';
import { EFilterConditions } from '@app-modeleditor/components/spreadsheet/model/table-filter-conditions.enum';
import { EntryElementEntry } from './../../../entry-collection/entry-element-enty';

@Component({
  selector: 'app-conditions-menu',
  templateUrl: './conditions-menu.component.html',
  styleUrls: ['./conditions-menu.component.scss'],
})
export class ConditionsMenuComponent implements OnInit {
  @Input() entryElement: EntryElement;
  constructor() {}

  ngOnInit(): void {}

  stopEvent(ev: MouseEvent): void {
    ev.stopImmediatePropagation();
    ev.stopPropagation();
  }

  handleClick(ev: MouseEvent, condition: EFilterConditions): void {
    this.entryElement.setCondition(condition);
    if (this.entryElement.getValue() && this.entryElement.getValue<EntryElementEntry>().getValue()) {
      this.entryElement.executeChanges();
    }
  }

  getConditionIcon(condition: EFilterConditions): string {
    return `FILTER_${condition.toString().toUpperCase()}`;
  }
}
