import { ComponentEvent } from '../../../history/component-event';

/**
 * @constructor
 * @class
 * @extends ComponentEvent
 * @requires ComponentEvent
 * @plugin compare-gantts
 * */
export class CompareGanttsEvent extends ComponentEvent {
  constructor() {
    super();
    /**
     * @type {CompareGantts}
     */
    this.ref = null;
  }

  /**
   * @override
   * @returns {boolean} true if undo was succesfull, else false
   */
  undo() {
    const s = this;

    function handle(type) {
      const types = {
        compareGantts: function () {
          return s._undoCompareGantts();
        },
        resetCompareGantts: function () {
          return s._redoCompareGantts();
        },
        default: function () {
          console.warn('can not undo: ' + type + '. May not be implemented yet.');
          return false;
        },
      };
      return (types[type] || types['default'])();
    }
    return handle(s.eventName);
  }

  /**
   * @override
   * @returns {boolean} true if redo was succesfull, else false
   */

  redo() {
    const s = this;
    function handle(type) {
      const types = {
        compareGantts: function () {
          return s._redoCompareGantts();
        },
        resetCompareGantts: function () {
          return s._undoCompareGantts();
        },
        default: function () {
          console.warn('can not redo: ' + type + '. May not be implemented yet.');
          return false;
        },
      };
      return (types[type] || types['default'])();
    }
    return handle(s.eventName);
  }

  private _undoCompareGantts() {
    const s = this;
    s.ref.disableLogging();
    s.ref.reset();
    s.ref.enableLogging();
    return true;
  }

  private _redoCompareGantts() {
    const s = this;
    s.ref.disableLogging();
    s.ref.compareByRowIndex(s.arguments[0]);
    s.ref.enableLogging();
    return true;
  }

  /**
   * @override
   * @return {String}
   */

  getEventDescription() {
    const s = this;
    function handle(type) {
      const types = {
        compareGantts: function () {
          return CompareGanttsEventDescription.getCompareGanttsDescription(
            s.ref.ganttDiagram.getConfig().getLanguage(),
            s.arguments
          );
        },
        resetCompareGantts: function () {
          return CompareGanttsEventDescription.getResetCompareGanttsDescription(
            s.ref.ganttDiagram.getConfig().getLanguage(),
            s.arguments
          );
        },
        default: function () {
          console.warn('no description for: ' + s.eventName + ' set. May not be implemented yet.');
          return '';
        },
      };
      return (types[type] || types['default'])();
    }
    return handle(s.eventName);
  }
}

// GETTER and SETTER inherited from Main Class

export class CompareGanttsEventDescription {
  // TODO: Event explanations.
  private constructor() {}

  static getCompareGanttsDescription(language, args) {
    let description = '';
    language === 'DE' ? (description = 'Modus: Gantt-Diagramm-Vergleich aktivieren.') : null;
    language === 'EN' ? (description = 'Activate compare gantt diagram modus.') : null;
    return description;
  }
  static getResetCompareGanttsDescription(language, args) {
    let description = '';
    language === 'DE' ? (description = 'Deaktiviere Modus: Gantt-Diagramm-Vergleich') : null;
    language === 'EN' ? (description = 'Deactivate compare gantt diagram modus.') : null;
    return description;
  }
}
