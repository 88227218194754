import {
  Component,
  ElementRef,
  HostBinding,
  Inject,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { GlobalViewService } from 'frontend/src/dashboard/view/global-view.service';
import { ITemplateDialogOptions } from './dialog.service';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  providers: [],
})
export class DialogComponent implements OnInit, OnChanges {
  title: string;
  content: any;
  text: string;
  buttons: IButton[];
  isLoader: boolean;
  displayFooter: boolean;
  additionalButtons: any[];
  resourceId: string;
  options: ITemplateDialogOptions = {};
  // public _onCustomAction: EventEmitter<any> = new EventEmitter();
  @Input() type: 'CREATE';
  @HostBinding('class') styleRef = false;
  @ViewChild('wrapper') wrapper: ElementRef;
  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private globalViewService: GlobalViewService
  ) {
    this.title = this.data.node.headline;
    this.content = this.data.node;
    this.type = this.data.node.type;
    this.text = this.data.node.text ? this.data.node.text : null;
    this.isLoader = this.data.node.isLoader;
    this.buttons = this.data.node.buttons;
    this.styleRef = this.globalViewService.currentStyle;
    this.globalViewService.onChangeStyle.subscribe((style) => {
      this.styleRef = style;
    });
    this.additionalButtons = this.data.node.additionalButtons ? this.data.node.additionalButtons : [];
    this.options = this.data.options;
  }

  /**
   * on init lifecycle
   */
  ngOnInit() {
    // set width if available
    if (this.wrapper && this.content.width) {
      const elem = this.wrapper.nativeElement.parentNode.parentNode.parentNode;
      elem.style.maxWidth = '100vw';
      elem.style.width = this.content.width + '%';
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.wrapper && this.wrapper && this.content.width) {
      const elem = this.wrapper.nativeElement.parentNode.parentNode.parentNode;
      elem.style.maxWidth = '100vw';
      elem.style.width = this.content.width + '%';
    }
  }
}

/**
 * interface IButton
 */
export interface IButton {
  label: string;
  close?: boolean;
  save?: boolean;
  id?: string;
  color?: string;
  type?: string;
}
