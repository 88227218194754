import { HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { ButtonService } from '@app-modeleditor/components/button/button.service';
import { ERequestMethod, RequestOptions, RequestService } from '@app-modeleditor/request.service';
import { TemplateAdapter } from '@app-modeleditor/utils/template-factory.service';
import { TemplateService } from '@app-modeleditor/utils/template.service';
import { CloudMessagingService } from '@core/notification/cloud-messaging.service';
import { Notification } from '@core/notification/notification';
import { ENotificationType } from '@core/notification/notification-type.enum';
import { GlobalUtils } from 'frontend/src/dashboard/global-utils';
import { ResourceAdapter } from 'frontend/src/dashboard/model/resource/resource-adapter.service';
import { Template } from 'frontend/src/dashboard/model/resource/template';
import { BehaviorSubject, Observable, Observer, concat, from, throwError } from 'rxjs';
import { catchError, finalize, map, mergeMap, tap } from 'rxjs/operators';
import { Action } from '../button/action/action';
import { FileDownloadService } from '../downloader/file-download.service';
import { FileProgress } from '../file-progress/file-progress';
import { HierarchicalMenuItem } from './../template-ui/hierarchical-menu-item';
import { TemplateTreeService } from './../tree/tree.service';
import { FileProgressService } from './file-progress.service';
import { EFileLoadingType, EFileState } from './file-state.enum';
import { FileUtilsService } from './file-utils.service';
import { FileData } from './filte-data';
import { ProgressService } from './progress/progress.service';
class FileHolder {
  id: string;
  file: File;
}
@Injectable({
  providedIn: 'root',
})
export class FileUploaderService {
  private dragState: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private hmi: HierarchicalMenuItem = null;

  constructor(
    private actionApi: ButtonService,
    private fileProgressApi: ProgressService,
    private requestApi: RequestService,
    private templateAdapter: TemplateAdapter,
    private _fileProgressApi: FileProgressService,
    private _resourceAdapter: ResourceAdapter,
    private _templateApi: TemplateService,
    private _fcm: CloudMessagingService,
    private _templateTreeApi: TemplateTreeService,
    private _fileUtilsApi: FileUtilsService,
    private zone: NgZone,
    private fd: FileDownloadService
  ) {
    this.zone.runOutsideAngular(() => {
      window.addEventListener('dragover', this.handleDragOver.bind(this));
      window.addEventListener('dragleave', this.stopDragging.bind(this));
      window.addEventListener('drop', this.handleDrop.bind(this));
    });

    this._templateTreeApi.getCurrentHMI().subscribe((hmi: HierarchicalMenuItem) => {
      this.hmi = hmi;
    });
  }

  private handleDragOver(event: DragEvent): void {
    event.preventDefault();
    if (this.dragState.value === true) {
      return;
    }

    if (event.dataTransfer.types.indexOf('Files') !== -1) {
      this.setDragState(true);
    }
  }

  private handleDrop(event: DragEvent): void {
    event.preventDefault();
    this.setDragState(false);
  }

  private stopDragging(event: DragEvent): void {
    if (this.dragState.value === false) {
      return;
    }

    if (!event.clientX && !event.clientY) {
      this.setDragState(false);
    }
  }

  /**
   * sets global drag state to show or hide dropzones
   * @param state boolean
   * @returns void
   */
  public setDragState(state: boolean): void {
    this.zone.run(() => {
      this.dragState.next(state);
    });
  }

  /**
   * get subscription to global drag state
   * @returns Observable<void>
   */
  public getDragState(): Observable<boolean> {
    return this.dragState.asObservable();
  }

  public createDirectory(templateId: string, dirName: string): Observable<Template> {
    return this.requestApi
      .call(ERequestMethod.GET, `rest/create/dir/${templateId}/${dirName}`)
      .pipe(map((result) => this.templateAdapter.adapt(result)));
  }

  public removeFileBytes(file: FileData) {
    const fp = this._fileProgressApi.getFileProgressObject();
    fp.setNumberOfTransferedBytes(fp.getNumberOfTransferedBytes() - file.getTransferedBytes());
    fp.setNumberOfBytesToTransfer(fp.getNumberOfBytesToTransfer() - file.getSize());
    fp.setNumberOfFilesTransfered(fp.getNumberOfFilesTransfered() + 1);
    this._fileProgressApi.setFileProgress(fp);
  }

  public uploadFile(file: FileData, actions: Action[]): Observable<any> {
    this.fileProgressApi.addFile(file);
    file.setState(EFileState.LOADING);
    actions.forEach((action) => action.setUploadID(file.getId()));

    return this.actionApi
      .executeActions(actions)
      .pipe(
        tap(() => {
          file.setState(EFileState.COMPLETED);
          this.updateFileInProgress(file);
        })
      )
      .pipe(
        catchError((e: any) => {
          file.setState(EFileState.FAILED);
          this.updateFileInProgress(file);
          this.removeFileBytes(file);

          return throwError(e);
        })
      );
  }

  /**
   * handle file upload
   */

  /**
   * handle file drop event
   * @param template Template
   * @param files File[]
   * @returns void
   */
  public onFileDropEvent(template: Template, files: File[], isZippedContent: boolean): Observable<any> {
    // register temporary stuff
    this._templateApi.registerTemporaryTemplate(template);

    const fileHolders = files.map((f: File) => {
      const holder = new FileHolder();
      holder.id = GlobalUtils.generateUUID();
      holder.file = f;
      return holder;
    });
    this.updateFilesInProgress(fileHolders);

    return concat(...fileHolders.map((f: FileHolder) => this.send(template, f, isZippedContent)))
      .pipe(
        finalize(() => {
          // this._fileProgressApi.clearFileProgress();
          // unregister temporary stuff
          this._templateApi.unregisterTemporaryTemplate(template.getId());
        })
      )
      .pipe(tap(() => {}));
  }

  public updateHmi(): void {
    this._fcm.injectNotification([
      new Notification().setType(ENotificationType.UPDATE_ELEMENT_NOTIFICATION).setUpdateElementIds([this.hmi.getId()]),
    ]);
    // this._fileProgressApi.clearFileProgress();
  }

  public onFolderDrop(item, parentId: string, path = ''): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      if (item.isFile) {
        item.file((file) => {
          file.filepath = path + file.name;
          this.getFileData(file).subscribe((fd: FileData) => {
            this.uploadSingleFile(item.name, GlobalUtils.generateUUID(), parentId, fd).subscribe(
              (result) => {
                fd.setState(EFileState.COMPLETED);
                this.updateFileInProgress(fd);
                observer.next(file);
                observer.complete();
              },
              (err) => {
                fd.setState(EFileState.FAILED);
                this.updateFileInProgress(fd);
                this.removeFileBytes(file);
              }
            );
          });
        });
      } else if (item.isDirectory) {
        this.uploadSingleDirectory(item.name, GlobalUtils.generateUUID(), parentId)
          .pipe(
            catchError((e) => {
              observer.error(e);
              observer.complete();
              return throwError(e);
            })
          )
          .subscribe((result) => {
            const resourceId: string = result.id;
            if (this.hmi) {
              this._fcm.injectNotification([
                new Notification()
                  .setType(ENotificationType.HMI_CHILD_NODE_NOTIFICATION)
                  .setUpdateElementIds([this.hmi.getId()]),
              ]);
            }

            const dirReader = item.createReader();
            this.readAllEntriesInDir(dirReader, (entries) => {
              if (!entries || entries.length === 0) {
                observer.next([]);
                observer.complete();
                return;
              }
              const result = [];

              from(entries)
                // starts multiple parallel tasks at once and returns as soon as a result was found
                .pipe(
                  mergeMap((entry) => {
                    return this.onFolderDrop(entry, resourceId, path + item.name + '/');
                  }, 3)
                )
                .pipe(
                  catchError((e) => {
                    observer.error(e);
                    observer.complete();
                    return throwError(e);
                  }),
                  finalize(() => {
                    observer.next(result);
                    observer.complete();
                  })
                )
                .subscribe((r) => {
                  result.concat(...r);
                });
            });
          });
      }
    });
  }

  public readAllEntriesInDir(
    reader: FileSystemDirectoryReader,
    cb: (items: FileSystemEntry[]) => void,
    items?: FileSystemEntry[]
  ): void {
    if (!items) {
      items = [];
    }
    reader.readEntries((entries) => {
      items = items.concat(entries);
      if (entries.length > 0) {
        this.readAllEntriesInDir(reader, cb, items);
      } else {
        cb(items);
      }
    });
  }

  private updateFilesInProgress(fileHolders: FileHolder[]): void {
    const fileProgress: FileProgress = this._fileProgressApi.getFileProgressObject();
    fileHolders.forEach((holder) => {
      (holder.file as any).loadingType = EFileLoadingType.UPLOAD;
      fileProgress.addFile(holder.id, holder.file);
    });
    fileProgress.setNumberOfFilesToTransfer(Object.keys(fileProgress.getFiles()).length);
    this._fileProgressApi.setFileProgress(fileProgress);
  }

  private updateFileInProgress(fileData: FileData): void {
    const fileProgress: FileProgress = this._fileProgressApi.getFileProgressObject();
    fileData.setLoadingType(EFileLoadingType.UPLOAD);
    fileProgress.addFile(fileData.getId(), fileData);
    fileProgress.setNumberOfFilesToTransfer(Object.keys(fileProgress.getFiles()).length);
    this._fileProgressApi.setFileProgress(fileProgress);
  }

  fileToUpload;
  /**
   * send file to server
   * @param file File
   * @returns Observable<void>
   */
  private send(template: Template, file: FileHolder, isZippedContent: boolean): Observable<void> {
    return new Observable<void>((observer: Observer<void>) => {
      this._fileUtilsApi.readLocalFile(file.file).subscribe((r: CustomEvent) => {
        if (r?.type === 'ready') {
          const buffer: ArrayBuffer = r.detail;
          const fileData: FileData = new FileData()
            .setId(file.id)
            .setName(file.file.name)
            .setContent(buffer)
            .setSize(file.file.size)
            .setType(file.file.type)
            .setZippedContent(isZippedContent);
          // add files

          this.updateFileInProgress(fileData);
          this.fileToUpload = fileData;
          template.setFileToUpload(this.fileToUpload);
          this.applyFilesToUpload(template);
          this._resourceAdapter.addHook(template.getId(), (item: Template, b) => {
            item.setFileToUpload(fileData);
          });

          this.uploadFile(
            this.fileToUpload,
            isZippedContent ? template.getDirectoryUploadActions() : template.getFileUploadActions()
          )
            .pipe(
              finalize(() => {
                // remove files
                this._resourceAdapter.removeHook(template.getId());
              })
            )
            .subscribe(
              () => {
                observer.next();
                observer.complete();
              },
              (err) => {
                observer.next();
                observer.complete();
              }
            );
        }
      });
    });
  }

  private getFileData(file: File): Observable<FileData> {
    return new Observable<FileData>((observer: Observer<FileData>) => {
      this._fileUtilsApi.readLocalFile(file).subscribe((r: CustomEvent) => {
        if (r?.type === 'ready') {
          const buffer: ArrayBuffer = r.detail;
          const fileData: FileData = new FileData()
            .setName(file.name)
            .setContent(buffer)
            .setSize(file.size)
            .setType(file.type);
          observer.next(fileData);
          observer.complete();
        }
      });
    });
  }

  applyFilesToUpload(template: Template): void {
    if (!this.fileToUpload || !template) {
      return;
    }

    const temp: Template = this._templateApi.findTemplate(template.getId());
    temp.setFileToUpload(this.fileToUpload);
    // this._templateApi.getRegisteredTemplateValues().map((r: RegisteredTemplate) => {
    //   const temp: Template = r.getTemplates().find((t: Template) => t.getId() === template.getId());
    //   if (!temp) { return r; }

    //   temp.setFileToUpload(this.fileToUpload);

    //   return r;
    // });
  }

  public uploadSingleFile(filename: string, fileId: string, directoryId: string, fileData: FileData): Observable<any> {
    let alreadyTransferedBytes = 0;
    let totalBytes = 0;
    let completion = 0;
    // this._fileProgressApi.setFileProgress(this.fileProgress.setNumberOfBytesToTransfer(this.fileProgress.getNumberOfBytesToTransfer() + fileData.getSize()));
    return new Observable<any>((observer: Observer<any>) => {
      const options: any = {
        responseType: 'blob' as 'json',
        observe: 'events',
        reportProgress: true,
        body: fileData.getContent(),
      };
      this.requestApi
        .call(
          ERequestMethod.POST,
          `rest/files/upload/file/${encodeURIComponent(filename)}/${fileId}/directory/${directoryId}`,
          new RequestOptions().setHttpOptions(options)
        )
        .subscribe((event) => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              // const newBytes: number = event.loaded - alreadyTransferedBytes;
              // alreadyTransferedBytes = event.loaded;
              completion = (100 / event.total) * event.loaded;
              const newBytes: number = (fileData.getSize() / 100) * completion - alreadyTransferedBytes;
              alreadyTransferedBytes += newBytes;
              fileData.setTransferedBytes(newBytes);
              fileData.setState(EFileState.LOADING);
              this.updateFileInProgress(fileData);
              this._fileProgressApi.setByteOfFile(fileData.getId(), event.loaded);
              this._fileProgressApi.setFileProgress(
                this._fileProgressApi
                  .getFileProgressObject()
                  .setNumberOfTransferedBytes(
                    this._fileProgressApi.getFileProgressObject().getNumberOfTransferedBytes() + newBytes
                  )
              );
              totalBytes = event.total;
              break;
            case HttpEventType.Response:
              this._fileProgressApi.setFileProgress(
                this._fileProgressApi
                  .getFileProgressObject()
                  .setNumberOfFilesTransfered(
                    this._fileProgressApi.getFileProgressObject().getNumberOfFilesTransfered() + 1
                  )
              );
              fileData.setState(EFileState.COMPLETED);
              this.updateFileInProgress(fileData);
              observer.next(event.body);
              observer.complete();
              break;
          }
        });
    });
  }

  public uploadSingleDirectory(directoryName: string, directoryId: string, parentDirectoryId: string): Observable<any> {
    const options = {
      responseType: 'blob' as 'json',
    };

    return new Observable<any>((observer: Observer<any>) => {
      this.requestApi
        .call(
          ERequestMethod.GET,
          `rest/files/create/directory/${directoryName}/${directoryId}/parentdirectory/${parentDirectoryId}`,
          new RequestOptions().setHttpOptions(options)
        )
        .pipe(
          catchError((e: HttpErrorResponse) => {
            return this.fd.parseErrorBlob(e).pipe(
              tap((httpError) => {
                observer.error(httpError);
                observer.complete();
              })
            );
          })
        )
        .subscribe((r) => {
          switch (r.type) {
            case HttpEventType.Response:
              observer.next(r.body);
              observer.complete();
              break;
          }
        });
    });
  }
}
