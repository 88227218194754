import { GanttDataContainer, GanttEditPermissions } from '@gantt/public-api';
import { BackendToGanttOriginInputMapperService } from 'frontend/src/dashboard/gantt/general/generator/mapper/gantt-to-gantt-origin.mapper';
import { GanttTemplateData } from 'frontend/src/dashboard/gantt/helper/gantt';
import { ILegendEntry } from 'frontend/src/dashboard/legend/legend.interface';
import { IGanttAttributeMapping, IGanttTemplateValue } from './gantt-input.data';
import { GanttLegendMapper } from './mapper/legend.mapper';

/**
 * Provides interface to request hierarchical plan data from
 * backend by using the gantt template data.
 * Maps gantt data in correct format for integrate into SaxmsBestGantt component.
 */
export class GanttTemplateGenerator {
  public initialLegendData: ILegendEntry[];

  constructor() {}

  /**
   * Requests hierarchical plan data from backend by template.
   * Does the mapping and legend extraction.
   * @param templateData Gantt template from backend.
   */
  public getGanttByTemplateData(
    templateData: GanttTemplateData,
    backendToGanttOriginInputMapper: BackendToGanttOriginInputMapperService
  ): GanttMappedTemplateData {
    if (templateData.getValue() == null) {
      console.warn(`template data missing value property`, templateData);
    }

    this.initialLegendData = GanttLegendMapper.mapGanttLegendDataByTemplate(templateData);

    return {
      response: templateData.getValue(),
      ganttData: backendToGanttOriginInputMapper.ganttToInput(
        templateData.getHierarchicalPlan(),
        this.extractAttributeMapping(templateData),
        templateData.getDefaultBlockTooltipSettings(),
        templateData.getGanttEntryAttributeMappings()
      ),
      editAllowSettings: this.getEditAllowSettingsByTemplateData(templateData),
    };
  }

  /**
   * Extracts the edit allow settings from backend template data.
   * @param templateData Gantt template from backend.
   */
  private getEditAllowSettingsByTemplateData(templateData: any): GanttEditPermissions {
    if (!templateData.editAllowSettings) return null;
    const templateAllowSettings = templateData.editAllowSettings;
    const restrictions: GanttEditPermissions = new GanttEditPermissions();
    if (templateAllowSettings.edit_allow_add) restrictions.edit_allow_add = templateAllowSettings.editAllowSettings;
    if (templateAllowSettings.edit_allow_changeRow)
      restrictions.edit_allow_changeRow = templateAllowSettings.edit_allow_changeRow;
    if (templateAllowSettings.edit_allow_delete)
      restrictions.edit_allow_delete = templateAllowSettings.edit_allow_delete;
    if (templateAllowSettings.edit_allow_move) restrictions.edit_allow_move = templateAllowSettings.edit_allow_move;
    if (templateAllowSettings.edit_allow_resize)
      restrictions.edit_allow_resize = templateAllowSettings.edit_allow_resize;
    if (templateAllowSettings.editable) restrictions.editable = templateAllowSettings.editable;
    if (templateAllowSettings.edit_allow_row_drag)
      restrictions.edit_allow_row_drag = templateAllowSettings.edit_allow_row_drag;
    restrictions.edit_allow_clicks = templateAllowSettings.edit_allow_clicks ?? true;
    return restrictions;
  }

  /**
   * Extracts the attribute mapping from backend template data.
   * @param templateData Gantt template from backend.
   */
  private extractAttributeMapping(templateData: any): IGanttAttributeMapping {
    try {
      return templateData.attributeMapping;
    } catch (e) {
      console.warn('Problems during parsing of attribute mapping:' + e);
      return {};
    }
  }
}

/**
 * Data conatiner for initial data mapping and extarction of gantt data.
 */
export interface GanttMappedTemplateData {
  response: IGanttTemplateValue;
  ganttData: GanttDataContainer;
  editAllowSettings: GanttEditPermissions;
}
