import { Action } from '@app-modeleditor/components/button/action/action';
import { Button } from '@app-modeleditor/components/button/button';
import { EButtonDisplayType } from '@app-modeleditor/components/button/button-display-type.enum';
import { Content } from '@app-modeleditor/components/content/content';
import { ContentPart } from '@app-modeleditor/components/content/content-part/content-part';
import { EntryCollection } from '@app-modeleditor/components/entry-collection/entry-collection';
import { EntryElement } from '@app-modeleditor/components/entry-collection/entry-element';
import { EntryElementValue } from '@app-modeleditor/components/entry-collection/entry-element-value';
import { EFieldType } from '@app-modeleditor/components/entry-collection/field-type.enum';
import { IndexPage } from '@app-modeleditor/components/index-register/index-page';
import { IndexRegister } from '@app-modeleditor/components/index-register/index-register';
import { ConfirmLightbox } from '@app-modeleditor/components/lightbox/predefined/confirm-lightbox';
import { EDefaultSubmenues } from '@app-modeleditor/components/spreadsheet/model/default-submenues.enum';
import { EDefaultTableButton } from '@app-modeleditor/components/spreadsheet/model/default-table-button.enum';
import { Table } from '@app-modeleditor/components/spreadsheet/model/table';
import { TableHeader } from '@app-modeleditor/components/spreadsheet/model/table-header';
import { SaxMsSpreadsheetRow } from '@app-modeleditor/components/spreadsheet/model/table-row';
import { ISaxMsSpreadsheetRowEntry } from '@app-modeleditor/components/spreadsheet/model/table-row-entry';
import { TableValue } from '@app-modeleditor/components/spreadsheet/model/table-values';
import { GlobalUtils } from 'frontend/src/dashboard/global-utils';
import { EResizeMode } from 'frontend/src/dashboard/model/resource/template-resize-mode.enum';
import { ETemplateType } from 'frontend/src/dashboard/model/resource/template-type';
import { MenuItem } from 'frontend/src/dashboard/view/template-toolbar/menu-item';
import { Toolbar } from 'frontend/src/dashboard/view/template-toolbar/toolbar';
import { ToolbarGroup } from 'frontend/src/dashboard/view/template-toolbar/toolbar-group';
import { of } from 'rxjs';
import { delay, take } from 'rxjs/operators';
import { ChartLegendQuickfilter } from '../chart-legend/chart-legend-quickfilter/chart-legend-quickfilter';
import { ChartsComponent } from '../chart.component';
import { Lightbox } from './../../../../lightbox/lightbox';

export class LegendQuickSearchToolbar {
  private quickfilterElement: EntryElement;
  private positionsTable: Table;
  private ownFilterTable: Table;
  private publishedFilterTable: Table;
  constructor(private scope: ChartsComponent) {}

  public get(t: Toolbar): MenuItem {
    const quickFilterValues = this.getAvailableQuickFilterValues();
    const currentSelectedFilter = this.getCurrentSelectedFilter(quickFilterValues);

    this.quickfilterElement = new EntryElement()
      .setFieldType(EFieldType.COMBO_BOX)
      .setName('FILTER.quickfilter')
      .setPlaceholder('FILTER.quickfilter')
      .setResizeMode(EResizeMode.FIT_PARENT)
      .setValue(new EntryElementValue().setValue(currentSelectedFilter).setAvailableValues(quickFilterValues))
      .onChanges((data: EntryElementValue) => {
        if (data.getValue<ChartLegendQuickfilter>().getId() === 'userDefined') {
          // there is no filter selected
          this.scope.noQuicksearchFilterSelection();
        } else if (data.getValue<ChartLegendQuickfilter>().getId() === 'noFilter') {
          // no filter selected and all attributes visible
          this.scope.resetQuicksearchFilter();
          this.scope.noQuicksearchFilterSelection();
        } else {
          this.scope.selectQuickSearchFilter(data.getValue());
        }
      });

    const quickfilterManagmentBtn = new Button()
      .setIcon('menu')
      .setDisplayType(EButtonDisplayType.ICON_WITH_LABEL_BELOW)
      .setName('FILTER.manageQuickfilter')
      .chainActions(new Action().setCb(() => of(this.openPublishFilter())));
    const saveQuickfilterBtn = new Button()
      .setIcon('save')
      .setDisplayType(EButtonDisplayType.ICON_WITH_LABEL_BELOW)
      .setName('FILTER.saveAsQuick')
      .chainActions(new Action().setCb(() => of(this.openSaveQuickFilter())));

    const quickFilterGroup: ToolbarGroup = new ToolbarGroup()
      .setName('FILTER.quicksearch')
      .setId('chart-toolbar-legend-quickfilter-group')
      .setEntryElements([this.quickfilterElement, quickfilterManagmentBtn, saveQuickfilterBtn]);

    const m: MenuItem = new MenuItem()
      .defaultGroup(t)
      .setName('FILTER.quickfilter')
      .setId('chart-toolbar-legend-quickfilter')
      .setToolbarGroups([quickFilterGroup]);
    return m;
  }

  public updateValues() {
    this.updateQuickFilterValues();
    this.updateOwnFilterTable();
    this.updatePositionTable();
  }

  /**
   * Returns all available quick filter values.
   * Also contains a user defined filter.
   * This filter is activated as soon as no specific filter is activated.
   */
  private getAvailableQuickFilterValues(): ChartLegendQuickfilter[] {
    const userDefinedLabel = this.scope.translate.instant('custom');
    const noFilterLabel = this.scope.translate.instant('@noFilter@');

    // generate user defined entry
    const userDefined = new ChartLegendQuickfilter()
      .setName(userDefinedLabel)
      .setDescription(userDefinedLabel)
      .setValue(userDefinedLabel)
      .setPosition(0)
      .setHiddenAttributes([])
      .setPublicFilter(false)
      .setSelected(false)
      .setId('userDefined');

    const noFilter = new ChartLegendQuickfilter()
      .setName(noFilterLabel)
      .setDescription(noFilterLabel)
      .setValue(noFilterLabel)
      .setPosition(0)
      .setHiddenAttributes([])
      .setPublicFilter(false)
      .setSelected(false)
      .setId('noFilter');

    const quickFilterValues = this.scope.quickFilters.slice();

    // add to existing quick filter set
    quickFilterValues.unshift(userDefined);
    quickFilterValues.unshift(noFilter);

    return quickFilterValues;
  }

  public updateQuickFilterValues() {
    if (this.quickfilterElement) {
      const availableFilter = this.getAvailableQuickFilterValues().filter((quickFilter) => !quickFilter.isHideToMe());
      const selectedFilter = this.getCurrentSelectedFilter(availableFilter);
      const ev: EntryElementValue = this.quickfilterElement.getValue<EntryElementValue>();
      ev.setValue(selectedFilter).setAvailableValues(availableFilter);
    }
  }

  private getCurrentSelectedFilter(filterList: ChartLegendQuickfilter[]): ChartLegendQuickfilter {
    let filter = filterList.find((quickFilter) => quickFilter.isSelected());
    if (!filter) {
      filter = this.scope.mainChart.legend.getHiddenAttributes().size
        ? filterList.find((filter) => filter.getId() === 'userDefined')
        : filterList.find((filter) => filter.getId() === 'noFilter');
    }

    return filter;
  }

  public updateOwnFilterTable() {
    if (this.ownFilterTable) {
      this.ownFilterTable.setValues(null);

      of(null)
        .pipe(delay(0))
        .subscribe(() => {
          this.ownFilterTable.setValues(
            new TableValue().setTableRows(
              (this.getOwnFilterValues() || []).map((filter: ChartLegendQuickfilter) => {
                return new SaxMsSpreadsheetRow()
                  .setCanonicalName('canonical')
                  .setResourceId(filter.getId())
                  .setId(filter.getId())
                  .setSpreadsheetRowEntries({
                    0: this.getCell(0, filter.getName(), 'name'),
                    1: this.getCell(1, filter.getDescription(), 'description'),
                    2: this.getCell(2, filter.isPublicFilter(), 'publicFilter'),
                  });
              })
            )
          );
        });
    }
  }

  public updatePositionTable() {
    if (this.positionsTable) {
      this.positionsTable.setValues(null);

      of(null)
        .pipe(delay(0))
        .subscribe(() => {
          this.positionsTable.setValues(
            new TableValue().setTableRows(
              (this.scope.quickFilters || []).map((filter: ChartLegendQuickfilter) => {
                return new SaxMsSpreadsheetRow()
                  .setCanonicalName('canonical')
                  .setResourceId(filter.getId())
                  .setId(filter.getId())
                  .setSpreadsheetRowEntries({
                    0: this.getCell(0, filter.getName(), 'name'),
                    1: this.getCell(1, filter.getDescription(), 'description'),
                  });
              })
            )
          );
        });
    }
  }

  private getSaveQuickSearchLightBox() {
    const nameField = new EntryElement()
      .setName('FILTER.designation')
      .setPlaceholder('FILTER.designation')
      .setFieldType(EFieldType.TEXT_FIELD)
      .setValue(new EntryElementValue().setValue(''));

    const descriptionField = new EntryElement()
      .setName('FILTER.description')
      .setPlaceholder('FILTER.description')
      .setFieldType(EFieldType.TEXT_FIELD)
      .setValue(new EntryElementValue().setValue(''));

    const publishedField = new EntryElement()
      .setName(this.scope.translate.instant('GANTT.FILTER.share'))
      .setPlaceholder('FILTER.share')
      .setFieldType(EFieldType.CHECK_BOX)
      .setValue(new EntryElementValue().setValue(false));

    const lightBox = new Lightbox()
      .setName('FILTER.saveAsQuick')
      .setDisableSaveButton(true)
      .setContent(
        new Content().addContentParts(
          new ContentPart()
            .setDisplayContentpartContainer(false)
            .addContentElements(new EntryCollection().addEntryElements(nameField, descriptionField, publishedField))
        )
      );
    lightBox.setAdditionalButtons([
      new Button().setName('BUTTON.save').chainActions(
        new Action().setCb(() =>
          of(
            lightBox.getRef().close({
              name: nameField.getValue(),
              description: descriptionField.getValue(),
              published: publishedField.getValue(),
            })
          )
        )
      ),
    ]);
    return lightBox;
  }

  private getPublishFilterLightbox() {
    const lightBox = new Lightbox()
      .setName('FILTER.manageQuickfilter')
      .setDisableSaveButton(true)
      .setWidth(60)
      .setAdditionalButtons([
        new Button().setName('BUTTON.save').chainActions(new Action().setCb(() => of(this.onSave()))),
      ])
      .setContent(
        new Content().addContentParts(
          new ContentPart().setDisplayContentpartContainer(false).addContentElements(
            new IndexRegister().setType(ETemplateType.INDEX_REGISTER).setIndexPages([
              new IndexPage()
                .setName('GANTT.FILTER.positionOrder')
                .setType(ETemplateType.INDEX_PAGE)
                .setContent(
                  new Content().addContentParts(
                    new ContentPart().setDisplayContentpartContainer(false).addContentElements(this.getPostionsTable())
                  )
                ),
              new IndexPage()
                .setName('FILTER.own')
                .setType(ETemplateType.INDEX_PAGE)
                .setContent(
                  new Content().addContentParts(
                    new ContentPart().setDisplayContentpartContainer(false).addContentElements(this.getOwnFilterTable())
                  )
                ),
              new IndexPage()
                .setName('FILTER.shared')
                .setType(ETemplateType.INDEX_PAGE)
                .setContent(
                  new Content().addContentParts(
                    new ContentPart().setDisplayContentpartContainer(false).addContentElements(this.getPublicTable())
                  )
                ),
            ])
          )
        )
      );
    return lightBox;
  }

  private getTableHeader(name: string, index: number, id: string, fieldType: EFieldType, editable: boolean) {
    const header = new TableHeader()
      .setName(name)
      .setColumnIndex(index)
      .setId(id)
      .setFieldType(fieldType)
      .setEditable(editable)
      .setValue(name)
      .setCanonicalName(id)
      .setColumnName(id)
      .setSearchable(false)
      .setSortable(false);

    switch (fieldType) {
      case EFieldType.TEXT_FIELD:
        (header as any).datatype = 'string';
        break;
      case EFieldType.CHECK_BOX:
        (header as any).datatype = 'boolean';
        break;
    }
    return header;
  }

  getCell(idx: number, value: any, col: string): ISaxMsSpreadsheetRowEntry {
    return {
      value: value,
      index: idx,
      id: col,
      uuid: GlobalUtils.generateUUID(),
      uniqueId: `${col}.${GlobalUtils.generateUUID()}`,
      tableHeaderId: col,
      tooltip: value,
    };
  }

  private getPublicTable() {
    const publicFilters = this.scope.quickFilters.filter(
      (quickFilter) => !quickFilter.isMyFilter() && quickFilter.isPublicFilter()
    );
    this.publishedFilterTable = new Table()
      .setSaveSettings(false)
      .setDisabledSubMenues([EDefaultSubmenues.COLUMN, EDefaultSubmenues.ROW])
      .setDisableDefaultTableMenuButtons([
        EDefaultTableButton.CREATE,
        EDefaultTableButton.EDIT,
        EDefaultTableButton.DELETE,
        EDefaultTableButton.DETAILS,
      ])
      .setTableHeaders([
        this.getTableHeader(
          this.scope.translate.instant('FILTER.designation'),
          0,
          'name',
          EFieldType.TEXT_FIELD,
          false
        ),
        this.getTableHeader(
          this.scope.translate.instant('FILTER.description'),
          1,
          'description',
          EFieldType.TEXT_FIELD,
          false
        ),
        this.getTableHeader(this.scope.translate.instant('FILTER.hide'), 2, 'fadeOut', EFieldType.CHECK_BOX, true),
      ])
      .setHiddenHeaders(false)
      .setColumnReorder(false)
      .setEditable(true)
      .setCellEdit(true)
      .setNumberFixedColumns(0)
      .setUuid(GlobalUtils.generateUUID());

    this.publishedFilterTable.setValues(
      new TableValue().setTableRows(
        (publicFilters || []).map((filter: ChartLegendQuickfilter) => {
          return new SaxMsSpreadsheetRow()
            .setCanonicalName('canonical')
            .setResourceId(filter.getId())
            .setId(filter.getId())
            .setSpreadsheetRowEntries({
              0: this.getCell(0, filter.getName(), 'name'),
              1: this.getCell(1, filter.getDescription(), 'description'),
              2: this.getCell(2, filter.isHideToMe(), 'fadeOut'),
            });
        })
      )
    );
    return this.publishedFilterTable;
  }

  private getOwnFilterValues(): ChartLegendQuickfilter[] {
    let ownFilters = this.scope.quickFilters.filter((quickFilter) => quickFilter.isMyFilter());
    ownFilters = ownFilters.sort((a, b) =>
      a.getPosition() > b.getPosition() ? 1 : b.getPosition() > a.getPosition() ? -1 : 0
    );
    return ownFilters;
  }

  private getPostionsTable() {
    this.positionsTable = new Table()
      .setUpdateOnChange(true)
      .setSaveSettings(false)
      .setColumnReorder(false)
      .setDisabledSubMenues([EDefaultSubmenues.COLUMN, EDefaultSubmenues.QUICKSEARCH])
      .setDisableDefaultTableMenuButtons([
        EDefaultTableButton.CREATE,
        EDefaultTableButton.EDIT,
        EDefaultTableButton.DELETE,
        EDefaultTableButton.DETAILS,
      ])
      .setTableHeaders([
        this.getTableHeader(
          this.scope.translate.instant('FILTER.designation'),
          0,
          'name',
          EFieldType.TEXT_FIELD,
          false
        ),
        this.getTableHeader(
          this.scope.translate.instant('FILTER.description'),
          1,
          'description',
          EFieldType.TEXT_FIELD,
          false
        ),
      ])
      .setNumberFixedColumns(0)
      .setTableColumnCount(this.scope.quickFilters.length)
      .setId('filter-positionsTable')
      .setUuid(GlobalUtils.generateUUID());

    this.positionsTable.setValues(
      new TableValue().setTableRows(
        (this.scope.quickFilters || []).map((filter: ChartLegendQuickfilter) => {
          return new SaxMsSpreadsheetRow()
            .setCanonicalName('canonical')
            .setResourceId(filter.getId())
            .setId(filter.getId())
            .setSpreadsheetRowEntries({
              0: this.getCell(0, filter.getName(), 'name'),
              1: this.getCell(1, filter.getDescription(), 'description'),
            });
        })
      )
    );

    this.positionsTable.setAdditionalSubMenuGroups({
      ROW: [
        new ToolbarGroup().setName('general').setEntryElements([
          new Button()
            .setName('Position +')
            .setIcon('keyboard_arrow_up')
            .setId('position_up')
            .setEnableBy(() => {
              return (
                this.positionsTable.getSelectionModel() &&
                this.positionsTable.getSelectionModel().isRowActionsEnabled() === true &&
                this.enabledPostionButton(true)
              );
            })
            .setDisplayType(EButtonDisplayType.ICON_WITH_LABEL_BELOW)
            .chainActions(new Action().setCb(() => of(this.onPostionMove(true)))),
          new Button()
            .setName('Position -')
            .setIcon('keyboard_arrow_down')
            .setId('position_down')
            .setEnableBy(() => {
              return (
                this.positionsTable.getSelectionModel() &&
                this.positionsTable.getSelectionModel().isRowActionsEnabled() === true &&
                this.enabledPostionButton(false)
              );
            })
            .setDisplayType(EButtonDisplayType.ICON_WITH_LABEL_BELOW)
            .chainActions(new Action().setCb(() => of(this.onPostionMove(false)))),
        ]),
      ],
    });

    return this.positionsTable;
  }

  private getOwnFilterTable() {
    const ownFilters = this.scope.quickFilters.filter((quickFilter) => quickFilter.isMyFilter());

    this.ownFilterTable = new Table()
      .setUpdateOnChange(true)
      .setSaveSettings(false)
      .setColumnReorder(false)
      .setDisabledSubMenues([EDefaultSubmenues.COLUMN, EDefaultSubmenues.QUICKSEARCH])
      .setDisableDefaultTableMenuButtons([
        EDefaultTableButton.CREATE,
        EDefaultTableButton.EDIT,
        EDefaultTableButton.DELETE,
        EDefaultTableButton.DETAILS,
      ])
      .setTableHeaders([
        this.getTableHeader(
          this.scope.translate.instant('FILTER.designation'),
          0,
          'name',
          EFieldType.TEXT_FIELD,
          false
        ),
        this.getTableHeader(
          this.scope.translate.instant('FILTER.description'),
          1,
          'description',
          EFieldType.TEXT_FIELD,
          false
        ),
        this.getTableHeader(
          this.scope.translate.instant('FILTER.share'),
          2,
          'publicFilter',
          EFieldType.CHECK_BOX,
          true
        ),
      ])
      .setEditable(true)
      .setCellEdit(true)
      .setNumberFixedColumns(0)
      .setTableColumnCount(ownFilters.length)
      .setId('filter-ownFilterTable')
      .setUuid(GlobalUtils.generateUUID());

    this.ownFilterTable.setValues(
      new TableValue().setTableRows(
        (this.getOwnFilterValues() || []).map((filter: ChartLegendQuickfilter) => {
          return new SaxMsSpreadsheetRow()
            .setCanonicalName('canonical')
            .setResourceId(filter.getId())
            .setId(filter.getId())
            .setSpreadsheetRowEntries({
              0: this.getCell(0, filter.getName(), 'name'),
              1: this.getCell(1, filter.getDescription(), 'description'),
              2: this.getCell(2, filter.isPublicFilter(), 'publicFilter'),
            });
        })
      )
    );

    this.ownFilterTable.setAdditionalSubMenuGroups({
      ROW: [
        new ToolbarGroup().setName('general').setEntryElements([
          new Button()
            .setName('FILTER.delete')
            .setIcon('delete')
            .setDisplayType(EButtonDisplayType.ICON_WITH_LABEL_BELOW)
            .chainActions(new Action().setCb(() => of(this.onDeleteFilter())))
            .setEnableBy(() => {
              return (
                this.ownFilterTable.getSelectionModel() &&
                this.ownFilterTable.getSelectionModel().isRowActionsEnabled() === true
              );
            }),
        ]),
      ],
    });

    return this.ownFilterTable;
  }

  private enabledPostionButton(increase: boolean): boolean {
    if (this.positionsTable.getSelectionModel()) {
      const filter = this.scope.quickFilters.find(
        (quickFilter) => quickFilter.getId() === this.positionsTable.getSelectedValue().resourceId
      );
      if (filter) {
        if (increase) {
          return this.scope.quickFilters.indexOf(filter) !== 0;
        } else {
          return this.scope.quickFilters.indexOf(filter) !== this.scope.quickFilters.length - 1;
        }
      }
    }

    return false;
  }

  private onPostionMove(increase: boolean): void {
    const filter = this.scope.quickFilters.find(
      (quickFilter) => quickFilter.getId() === this.positionsTable.getSelectedValue().resourceId
    );
    const filterIndex = this.scope.quickFilters.indexOf(filter);
    let newIndex;
    if (increase) {
      newIndex = filterIndex - 1;
    } else {
      newIndex = filterIndex + 1;
    }

    const oldFilter = this.scope.quickFilters[newIndex];
    this.scope.quickFilters[newIndex] = filter;
    this.scope.quickFilters[filterIndex] = oldFilter;
    this.scope.updatePostionOfQuicksearch(this.scope.quickFilters);
  }

  private saveValueOfTable(table: Table, myFilters: boolean) {
    const editedFilters: ChartLegendQuickfilter[] = [];
    table
      .getValues()
      .getTableRows()
      .filter((row) => this.hasEditedCells(row))
      .forEach((row) => {
        const editedFilter = this.scope.quickFilters.find((filter) => row.getResourceId() === filter.getId());
        if (editedFilter) {
          if (myFilters) {
            editedFilter.setPublicFilter(row.getSpreadsheetRowEntries()[2].value);
          } else {
            editedFilter.setHideToMe(row.getSpreadsheetRowEntries()[2].value);
          }
          editedFilters.push(editedFilter);
        }
      });
    if (myFilters) {
      this.scope.publishQuicksearchFilter(editedFilters);
    } else {
      this.scope.hideToMeQuicksearchFilter(editedFilters);
    }
  }

  private onSave(): void {
    this.saveValueOfTable(this.ownFilterTable, true);
    this.saveValueOfTable(this.publishedFilterTable, false);
    this.updateValues();
    this.scope.lightboxApi.close();
  }

  private hasEditedCells(row: SaxMsSpreadsheetRow): boolean {
    for (const key in row.getSpreadsheetRowEntries()) {
      if (row.getSpreadsheetRowEntries()[key].edited) {
        return true;
      }
    }
    return false;
  }

  private onDeleteFilter(): void {
    const l: ConfirmLightbox = new ConfirmLightbox(
      this.scope.translate.instant('FILTER.delete') + '?'
    ).setCustomConfirmAction(() => {
      const filter = this.scope.quickFilters.find(
        (quickFilter) => quickFilter.getId() === this.ownFilterTable.getSelectedValue().resourceId
      );
      this.scope.deleteQuickSearchFilter(filter);
      return of();
    });

    this.scope.lightboxApi.open(l);
  }

  private openPublishFilter() {
    const publishFilterLigthBox = this.getPublishFilterLightbox();
    this.scope.lightboxApi
      .open(publishFilterLigthBox)
      .afterClosed()
      .pipe(take(1))
      .subscribe((data: any) => {});
  }

  private openSaveQuickFilter() {
    const saveQuickFilterLigthBox = this.getSaveQuickSearchLightBox();
    this.scope.lightboxApi
      .open(saveQuickFilterLigthBox)
      .afterClosed()
      .pipe(take(1))
      .subscribe((data: { name: EntryElementValue; description: EntryElementValue; published: EntryElementValue }) => {
        this.scope.createQuickSearchFilter(
          data.name.getValue(),
          data.description.getValue(),
          data.published.getValue()
        );
      });
  }
}
