import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

/**
 * Provides backend data for image/icon integration.
 */
@Injectable()
export class NodeNetworkImageService {
  public uri = 'rest/icons';

  constructor(public http: HttpClient) {}

  /**
   *
   */
  public getPossibleTypes(): Observable<any> {
    return this.http.get(`` + this.uri + '/types');
  }

  /**
   * Provides node images by given attribute.
   * @param attributeType Attribute to get image paths.
   */
  public getNodeImagesByAttribute(attributeType: string): Observable<any> {
    return this.http.get(`` + this.uri + '/' + attributeType);
  }

  /**
   *
   * @param type
   * @param value
   */
  public getNodeImage(type: string, value: string): Observable<any> {
    return this.http.get(`` + this.uri + '/' + type + '/' + value);
  }
}
