import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from 'frontend/src/dashboard/core/material/material.module';
import { ImageModule } from '@app-modeleditor/components/image/image.module';
import { FilterchipsListComponent } from './filterchips-list.component';
@NgModule({
  imports: [CommonModule, MaterialModule, TranslateModule],
  declarations: [FilterchipsListComponent],
  exports: [FilterchipsListComponent],
})
export class FilterchipsListModule {}
