import { OverlayRef } from '@angular/cdk/overlay';
import { ComponentRef, Inject, Injectable, Injector } from '@angular/core';
import { IOverlayOptions } from '@app-modeleditor/components/lightbox/overlay/overlay-options.interface';
import { OverlayService } from '@app-modeleditor/components/lightbox/overlay/overlay.service';
import { DockComponent } from '../dock.component';
import { DockWindowComponent } from './dock-window.component';

@Injectable()
export class DockWindowService {
  private _currentOverlayRef: OverlayRef;
  private _componentRef: ComponentRef<DockWindowComponent>;
  private _isWindowActive = false;

  constructor(@Inject('InternalOverlayService') private _overlayService: OverlayService) {}

  public generateDockWindow(injector: Injector): void {
    const overlayOptions: IOverlayOptions = {
      backdrop: false,
      positionStrategy: null,
    };
    const creation = this._overlayService.create(DockWindowComponent, null, null, overlayOptions, injector);
    this._currentOverlayRef = creation.overlayRef;
    this._componentRef = creation.componentRef;
    this._isWindowActive = true;
  }

  public closeWindow(): void {
    this._currentOverlayRef.dispose();
    this._isWindowActive = false;
  }

  public isWindowActive(): boolean {
    return this._isWindowActive;
  }

  public get dockWindow(): DockComponent {
    return this._componentRef?.instance?.dockWindow;
  }
}
