import { inject, Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { v4 as uuid } from 'uuid';
import { NotificationActions } from './notification.actions';
import { NotificationService } from './notification.service';
import { NotificationType } from './notification.type';

export interface Notification {
  title: string;
  text: string;
  type: NotificationType;
  id?: string;
  duration?: number;
  params?: { [key: string]: string };
}

export interface NotificationStateModel {
  notification: Notification[];
}

@State<NotificationStateModel>({
  name: 'notification',
  defaults: {
    notification: [],
  },
})
@Injectable()
export class NotificationState {
  private readonly notificationService: NotificationService = inject(NotificationService);

  @Selector()
  static notification(state: NotificationStateModel): Notification[] {
    return state.notification;
  }

  @Action(NotificationActions.Create)
  close(ctx: StateContext<NotificationStateModel>, action: NotificationActions.Create) {
    const msg = action.m;
    const state = ctx.getState();
    msg.id = msg.id ?? uuid();
    this.notificationService.open();

    const notification = state.notification.slice(Math.max(state.notification.length - 4, 0)).concat(action.m);

    ctx.patchState({ notification });
  }

  @Action(NotificationActions.Close)
  create(ctx: StateContext<NotificationStateModel>, action: NotificationActions.Close) {
    const state = ctx.getState();
    ctx.patchState({
      notification: state.notification.filter((item) => item.id !== action.id),
    });
  }
}
