import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'datex',
})
export class DatexPipe implements PipeTransform {
  transform(value: any, format = ''): any {
    // Try and parse the passed value.
    if (!(value instanceof Date)) {
      // return moment(value, 'YYYY-MM-DD HH:mm:ss').format(format.toUpperCase());
    }

    // If moment didn't understand the value, return it unformatted.
    // if (!moment(value)) {
    // return moment(value, 'YYYY-MM-DD HH:mm:ss').format(format.toUpperCase());
    // }

    // Otherwise, return the date formatted as requested.
    // return moment(value).format(format.toUpperCase());
  }
}
