import * as d3 from 'd3';
import { GanttCallBackStackExecuter } from '../../callback-tools/callback-stack-executer';
import { GanttCanvasShift } from '../../data-handler/data-structure/data-structure';
import { GanttUtilities } from '../../gantt-utilities/gantt-utilities';

/**
 * @class
 * @constructor
 * @param {*} canvas
 */
export class SettingBubblesBuilder {
  canvas: d3.Selection<any, any, null, undefined>;
  settingBubblesGroup: d3.Selection<any, any, null, undefined>;
  reactionArea: d3.Selection<any, any, null, undefined>;
  parametersFromShiftBox: any;
  isAnythingSelected: boolean;
  startTransX: number;
  startTransK: number;
  startCXR: number;
  startCXL: number;
  dragCL: number;
  dragCR: number;
  allowBubbleDrag: boolean;
  leftButton: boolean;
  rightButton: boolean;
  callback: any;
  private _hasBeenDragged: boolean;

  constructor(canvas) {
    this.canvas = canvas;
    this.settingBubblesGroup = null;
    this.parametersFromShiftBox = null;
    this.isAnythingSelected = false;
    this.startTransX = null;
    this.startTransK = null;
    this.startCXR = null;
    this.startCXL = null;
    this.dragCR = 0;
    this.dragCL = 0;

    this.allowBubbleDrag = false;

    this.leftButton = true;
    this.rightButton = true;

    this.callback = {
      onBubbleClicked: {},
    };

    this._hasBeenDragged = false;
  }

  /**
   * This function build the bubbles and a reaction-area behind the shift-boxes by clicking on a shift-box.
   * @param {*} parametersFromShiftBox The proportions of the selected shift-box in JSON-format.
   * @param {*} transform Scale- and transform-information.
   * @param {GanttCanvasShift} shiftData Canvas data of shift which origins the setting bubbles
   */
  build(parametersFromShiftBox, transform, shiftData) {
    const s = this;
    let dragCX, posMarker;
    this.parametersFromShiftBox = parametersFromShiftBox;
    this.dragCR = 0;
    this.dragCL = 0;

    if (this.isAnythingSelected) {
      //delete the existing bubble-elements
      d3.selectAll('.gantt-setting-bubbles-group').remove();
      d3.selectAll('.gantt-reactionArea-setting-bubbles').remove();
    }

    this.isAnythingSelected = true;
    this.startCXR =
      (parametersFromShiftBox.x + parametersFromShiftBox.width) * transform.k + transform.x + 20 + s.dragCR; //calculating the x-coordinate for the right bubble
    this.startCXL = parametersFromShiftBox.x * transform.k + transform.x - 20 + s.dragCL; //calculating the x-coordinate for the left bubble

    s.settingBubblesGroup = s.canvas.append('g').attr('class', 'gantt-setting-bubbles-group');

    s.reactionArea = s.canvas
      .insert('g', '.gantt-selection-box-start + *')
      .attr('class', 'gantt-reactionArea-setting-bubbles');

    s.reactionArea
      .append('rect') //build a reactionArea behind the shift-boxes
      .attr('height', s.canvas.attr('height'))
      .attr('width', s.canvas.attr('width'))
      .style('opacity', '0')
      .on('click', function () {
        s.remove(); //remove the bubbles and the reactionArea by clicking
      });

    //build left bubble
    if (s.leftButton) {
      s._buildBubble('LEFT', parametersFromShiftBox, shiftData);
    }
    //build right bubble
    if (s.rightButton) {
      s._buildBubble('RIGHT', parametersFromShiftBox, shiftData);
    }
  }

  /**
   * Builds an icon into the bubble
   * @param {Object} bubbleObject d3 object of the buble circle
   **/
  private _buildIcon(bubbleObject) {
    const s = this;
    const direction = bubbleObject.data()[0].direction;
    const icon = s.settingBubblesGroup
      .selectAll('dummy')
      .data([{ direction: direction }])
      .enter()
      .append('svg')
      .attr('class', 'settingBubblesIconClass')
      .attr('width', 23)
      .attr('height', 23)
      .attr('y', function () {
        return parseFloat(bubbleObject.attr('cy')) - parseFloat(bubbleObject.attr('r'));
      })
      .attr('x', function () {
        return parseFloat(bubbleObject.attr('cx')) - parseFloat(bubbleObject.attr('r'));
      })
      .attr('viewBox', '0 0 23 23')
      .append('path')
      .attr('d', 'M38 26H26v12h-4V26H10v-4h12V10h4v12h12v4z')
      .attr('transform', 'scale(0.48)');

    icon.data()[0]['direction'] = direction;
  }

  /**
   * Builds a bubble and manages the event handlings
   * @param {String} direction On wich side should the bubble be built. Can be LEFT or RIGHT
   * @param {*} parametersFromShiftBox The proportions of the selected shift-box in JSON-format.
   * @param {GanttCanvasShift} shiftData Canvas data of shift which origins the setting bubbles
   *
   */
  private _buildBubble(direction, parametersFromShiftBox, shiftData) {
    const s = this;
    let dragCX, dragX, mouseX, posMarker;

    const drag = d3
      .drag()
      .on('start', function (event) {
        GanttUtilities.dispatchD3EventToOutside(d3.select(this), event);
        s._hasBeenDragged = false;
        dragCX = event.x - parseFloat(d3.select(this).attr('cx'));
        dragX =
          event.x -
          parseFloat(
            d3
              .selectAll('.settingBubblesIconClass')
              .filter(function (d: any) {
                return direction == d.direction;
              })
              .attr('x')
          );
        mouseX = parseFloat(event.x);
        posMarker = d3.select(this).attr('cx');
        d3.select(this).attr('r', 13);
      })
      .on('drag', function (event) {
        GanttUtilities.dispatchD3EventToOutside(d3.select(this), event);
        if (!s.allowBubbleDrag) return;
        s._hasBeenDragged = true;
        d3.select(this).attr('cx', event.x - dragCX);
        d3.selectAll('.settingBubblesIconClass')
          .filter(function (d: any) {
            return direction == d.direction;
          })
          .attr('x', function () {
            return event.x - dragX;
          });
      })
      .on('end', function (event, d: any) {
        GanttUtilities.dispatchD3EventToOutside(d3.select(this), event);
        // execute onlick callback
        d3.select(this).attr('r', 11.5);
        s.dragCL += parseFloat(d3.select(this).attr('cx')) - posMarker;
        if (!s._hasBeenDragged)
          GanttCallBackStackExecuter.execute(
            s.callback.onBubbleClicked,
            new SettingBubblesBuilderOnClick(d.direction, shiftData)
          );
      });

    const circle = s.settingBubblesGroup
      .selectAll('dummy')
      .data([{ direction: direction }])
      .enter()
      .append('circle')
      .attr('class', 'settingBubblesClass')
      .attr('cx', function () {
        if (direction == 'RIGHT') return s.startCXR;
        return s.startCXL;
      })
      .attr('cy', parametersFromShiftBox.y + parametersFromShiftBox.height / 2)
      .attr('r', 0.1)
      .call(drag);

    circle.data()[0]['direction'] = direction;

    s.bubbleFadeInAnimation(circle);
  }

  /**
   * Handles fade-in animation.
   * @param {*} bubbleObject bubble-object
   */
  bubbleFadeInAnimation(bubbleObject) {
    if (!bubbleObject) return;
    const s = this;
    bubbleObject
      .transition()
      .ease(d3.easeQuad)
      .duration(200)
      .attr('r', 11.5)
      .on('end', function () {
        s._buildIcon(bubbleObject);
      });
  }
  /**
   * This function removes all elements of setting-bubbles.
   */
  remove() {
    const s = this;

    if (!s.isAnythingSelected) {
      return;
    }

    //resetting parameters
    this.isAnythingSelected = false;
    this.startTransX = null;
    this.startTransK = null;
    this.startCXR = null;
    this.startCXL = null;
    this.dragCR = 0;
    this.dragCL = 0;

    s.settingBubblesGroup.selectAll('.settingBubblesIconClass').remove();

    s.settingBubblesGroup
      .selectAll('.settingBubblesClass')
      .transition()
      .ease(d3.easeQuad)
      .duration(100)
      .attr('r', 0.1)
      .on('end', function () {
        d3.selectAll('.gantt-setting-bubbles-group').remove();
        d3.selectAll('.gantt-reactionArea-setting-bubbles').remove();
      });
  }
  /**
   * Adjusts bubbles when zooming and scrolling.
   * @param {*} parametersFromShiftBox The proportions of the selected shift-box in JSON-format.
   * @param {*} transform Scale- and transform-information.
   */
  adjustTheCircles(parametersFromShiftBox, transform) {
    const s = this;

    this.startCXR =
      (parametersFromShiftBox.x + parametersFromShiftBox.width) * transform.k + transform.x + 20 + s.dragCR; //calculating the x-coordinate for the right bubble
    this.startCXL = parametersFromShiftBox.x * transform.k + transform.x - 20 + s.dragCL; //calculating the x-coordinate for the left bubble

    //update the coordinates
    d3.selectAll('.settingBubblesClass')
      .filter(function (d: any) {
        return 'LEFT' == d.direction;
      })
      .attr('cx', s.startCXL);
    d3.selectAll('.settingBubblesClass')
      .filter(function (d: any) {
        return 'RIGHT' == d.direction;
      })
      .attr('cx', s.startCXR);
    d3.selectAll('.settingBubblesIconClass')
      .filter(function (d: any) {
        return 'LEFT' == d.direction;
      })
      .attr('x', s.startCXL - 11.5);
    d3.selectAll('.settingBubblesIconClass')
      .filter(function (d: any) {
        return 'RIGHT' == d.direction;
      })
      .attr('x', s.startCXR - 11.5);
  }
  //
  // GETTER & SETTER
  //
  showLeftButton(show) {
    this.leftButton = show;
  }
  showRightButton(show) {
    this.rightButton = show;
  }
}

export class SettingBubblesBuilderOnClick {
  direction: string;
  shiftData: GanttCanvasShift;

  constructor(direction, shiftData) {
    this.direction = direction;
    this.shiftData = shiftData;
  }
}
