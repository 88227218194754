import { SaxMsSubmenuService } from '@app-modules/saxms-submenu-elements/saxms-submenu.service';
import { GanttPluginHandlerService } from '../general/plugin/gantt-plugin-handler.service';
import { GanttLibService } from './gantt-lib.service';
import { GanttComparator } from './saxms-gantt-comparator';

/**
 * Submenu handling. Provides Submenu lifecycle for custom submenus.
 */
export class SaxMsBestGanttToolbarHandler {
  public open = false;
  public submenuType: string;
  public pPlugInHandler: GanttPluginHandlerService;
  public submenuService: SaxMsSubmenuService;

  constructor(
    private ganttLibService: GanttLibService,
    public experimentService: GanttComparator,
    submenuService: SaxMsSubmenuService
  ) {
    this.submenuService = submenuService;
  }
}
