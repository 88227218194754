import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { ImageComponent } from './image.component';
import { MatBadgeModule } from '@angular/material/badge';

@NgModule({
  imports: [CommonModule, TranslateModule, MatIconModule, MatBadgeModule],
  declarations: [ImageComponent],
  providers: [],
  exports: [ImageComponent],
})
export class ImageModule {}
