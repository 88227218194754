import { ElementRef } from '@angular/core';
import { Action } from '@app-modeleditor/components/button/action/action';
import { Button } from '@app-modeleditor/components/button/button';
import { EButtonDisplayType } from '@app-modeleditor/components/button/button-display-type.enum';
import { Content } from '@app-modeleditor/components/content/content';
import { ContentElement } from '@app-modeleditor/components/content/content-element/content-element';
import { EDisplayOrientation } from '@app-modeleditor/components/content/content-element/display-orientation.enum';
import { ContentPart } from '@app-modeleditor/components/content/content-part/content-part';
import { EntryCollection } from '@app-modeleditor/components/entry-collection/entry-collection';
import { EntryElement } from '@app-modeleditor/components/entry-collection/entry-element';
import { EntryElementValue } from '@app-modeleditor/components/entry-collection/entry-element-value';
import { EFieldType } from '@app-modeleditor/components/entry-collection/field-type.enum';
import { ColumnLayout } from '@app-modeleditor/components/structure/column-layout/column-layout';
import domtoimage from 'dom-to-image-more';
import { EResizeMode } from 'frontend/src/dashboard/model/resource/template-resize-mode.enum';
import { from, Observable, of } from 'rxjs';
import { PrintFormat } from '../print-format';
import { IPrintItem } from '../print-item.interface';
import { PrintLightbox } from '../print-lightbox';
import { IPrintLightboxStrategy } from './print-lightbox-strategy.interface';

export class PrintLightboxStrategyDefault implements IPrintLightboxStrategy {
  private lightboxRef: PrintLightbox;

  private readonly lightboxFullscreen = false;
  private readonly lightboxResizeable = true;

  public createContent(): Content {
    const part1: ContentPart = new ContentPart()
      .setResizeMode(EResizeMode.FIT_PARENT)
      .setDisplayContentpartContainer(false)
      .setContentElements(this.createSettingsContent());
    const part2: ContentPart = new ContentPart()
      .setResizeMode(EResizeMode.FIT_PARENT)
      .setDisplayContentpartContainer(false)
      .setContentElements(this.createPreviewContent());
    const c: Content = new Content()
      .setResizeMode(EResizeMode.FIT_PARENT)
      .setFullscreen(true)
      .setLayout(
        new ColumnLayout()
          .setResizeable(true)
          .setResizeMode(EResizeMode.FIT_PARENT)
          .setColumnCount(2)
          .setColumnWidths({
            0: 10,
            1: 90,
          })
          .setContent({
            0: [part1.getId()],
            1: [part2.getId()],
          })
      )
      .setContentParts([part1, part2]);
    return c;
  }

  private createSettingsContent(): ContentElement[] {
    const availableValues: PrintFormat[] = [
      new PrintFormat().setName('A6 - Format').setWidth(210).setHeight(296).setValue('A6'),
      new PrintFormat().setName('A5 - Format').setWidth(296).setHeight(420).setValue('A5'),
      new PrintFormat().setName('A4 - Format').setWidth(420).setHeight(594).setValue('A4'),
      new PrintFormat().setName('A3 - Format').setWidth(594).setHeight(840).setValue('A3'),
      new PrintFormat().setName('A2 - Format').setWidth(840).setHeight(1188).setValue('A2'),
      new PrintFormat().setName('A1 - Format').setWidth(1188).setHeight(1682).setValue('A1'),
      new PrintFormat().setName('A0 - Format').setWidth(1682).setHeight(2378).setValue('A0'),
    ];

    const modeBtn: Button = new Button().setName('BUTTON.FORMAT.landscape').setIcon('crop_landscape');

    const avResoltions: EntryElementValue[] = [
      new EntryElementValue().setValue(0.3).setName('x0.3'),
      new EntryElementValue().setValue(0.4).setName('x0.4'),
      new EntryElementValue().setValue(0.5).setName('x0.5'),
      new EntryElementValue().setValue(0.6).setName('x0.6'),
      new EntryElementValue().setValue(0.8).setName('x0.8'),
      new EntryElementValue().setValue(1).setName('x1'),
      new EntryElementValue().setValue(1.25).setName('x1.25'),
      new EntryElementValue().setValue(1.5).setName('x1.5'),
      new EntryElementValue().setValue(1.75).setName('x1.75'),
      new EntryElementValue().setValue(2).setName('x2'),
      new EntryElementValue().setValue(2.25).setName('x2.25'),
      new EntryElementValue().setValue(2.5).setName('x2.5'),
      new EntryElementValue().setValue(2.75).setName('x2.75'),
      new EntryElementValue().setValue(3).setName('x3'),
    ];

    const resolutionSelector: EntryElement = new EntryElement()
      .setName('PRINT.resolution')
      .setFieldType(EFieldType.COMBO_BOX)
      .setValue(new EntryElementValue().setAvailableValues(avResoltions).setValue(avResoltions[5]));

    const settingsContent = new EntryCollection().setDisplayOrientation(EDisplayOrientation.VERTICAL).setEntryElements([
      new Button()
        .setName('BUTTON.ZOOM.in')
        .setIcon('zoom_in')
        .setDisplayType(EButtonDisplayType.ICON_AND_LABEL)
        .chainActions(
          new Action().setCb(() =>
            of(this.lightboxRef.getPrinter().setResolution(this.lightboxRef.getPrinter().getResolution() - 0.1))
          )
        ),
      new Button()
        .setName('BUTTON.ZOOM.out')
        .setIcon('zoom_out')
        .chainActions(
          new Action().setCb(() =>
            of(this.lightboxRef.getPrinter().setResolution(this.lightboxRef.getPrinter().getResolution() + 0.1))
          )
        ),
      modeBtn.chainActions(
        new Action().setCb(() => {
          modeBtn
            .setName(this.lightboxRef.getPrinter().isToggled() ? 'BUTTON.FORMAT.portrait' : 'BUTTON.FORMAT.landscape')
            .setIcon(this.lightboxRef.getPrinter().isToggled() ? 'crop_portrait' : 'crop_landscape');
          return of(this.lightboxRef.getPrinter().setToggled(!this.lightboxRef.getPrinter().isToggled()));
        })
      ),
      new EntryElement()
        .setName('PRINT.format')
        .setFieldType(EFieldType.COMBO_BOX)
        .setValue(
          new EntryElementValue()
            .setAvailableValues(availableValues)
            .setValue(availableValues.find((v: PrintFormat) => v.getValue() === 'A4'))
        )
        .onChanges((e: EntryElementValue) => {
          this.lightboxRef.getPrinter().setSelectedFormat(e.getValue());
        }),
      new EntryElement()
        .setName('title')
        .setFieldType(EFieldType.TEXT_FIELD)
        .setValue(new EntryElementValue().setValue(''))
        .onChanges((e: EntryElementValue) => {
          this.lightboxRef.getPrinter().setTitle(e.getValue());
        }),
    ]);

    this.lightboxRef.getPrinter().setSelectedFormat(availableValues.find((v: PrintFormat) => v.getValue() === 'A4'));

    return [settingsContent];
  }

  private createPreviewContent(): ContentElement[] {
    return [this.lightboxRef.getPrinter()];
  }

  public print(): Observable<IPrintItem[]> {
    const generateImage = async function (printRef: ElementRef<any>): Promise<IPrintItem[]> {
      const dataUrl: string = await domtoimage.toPng(printRef.nativeElement);
      return [
        {
          dataUrl: dataUrl,
          pageBreakBefore: true,
          pageBreakInside: true,
        },
      ];
    };
    return from(generateImage(this.lightboxRef.getPrintRef()));
  }

  public getLightboxFullscreen(): boolean {
    return this.lightboxFullscreen;
  }

  public getLightboxResizeable(): boolean {
    return this.lightboxResizeable;
  }

  public setLightboxRef(lightboxRef: PrintLightbox): void {
    this.lightboxRef = lightboxRef;
  }
}
