import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ColorEvent } from 'ngx-color';
import { EntryElementValue } from '../entry-collection/entry-element-value';
import { Colorpicker } from './colorpicker';

@Component({
  selector: 'color-picker',
  templateUrl: './colorpicker.component.html',
  styleUrls: ['./colorpicker.component.scss'],
})
export class ColorpickerComponent {
  @Input() template: Colorpicker;
  @Output() colorChange: EventEmitter<EntryElementValue> = new EventEmitter<EntryElementValue>();

  handleChange(event: ColorEvent): void {
    if (this.template && this.template.getValue()) {
      this.template
        .getValue<EntryElementValue>()
        .setValue(`rgba(${event.color.rgb.r},${event.color.rgb.g},${event.color.rgb.b},${event.color.rgb.a})`);
      this.colorChange.emit(this.template.getValue());
      this.template.executeChanges(this.template.getValue());
    }
  }

  get hasPresetColors(): boolean {
    return this.template.getPossibileValues()?.length > 0;
  }

  get currentColor(): string {
    if (!this.template) {
      return '#4b8c8f';
    }

    return this.template.getValue() && this.template.getValue<EntryElementValue>().getValue()
      ? this.template.getValue<EntryElementValue>().getValue()
      : this.template.getDefaultColor();
  }
}
