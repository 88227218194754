<!--<div #chart class="chart"></div>-->
<ng-container *ngIf="initLoading">
  <ng-container *ngIf="!errorFlag">
    <div class="init-loading-label">
      <span>{{ 'CHART.LOADING.active' | translate }}</span>
    </div>
    <mat-progress-bar [mode]="'buffer'" class="progress-container"></mat-progress-bar>
  </ng-container>
  <ng-container *ngIf="errorFlag">
    <div class="init-loading-label">
      <span class="errorMessage">{{ 'CHART.LOADING.error' | translate }}</span>
    </div>
  </ng-container>
</ng-container>

<div #chartOuterContainer class="chart-outer-container" [class.no-bg]="template?.menuMode === 'HIDE'">
  <!--div class="chartContainer" *ngIf="!loadingData"-->
  <div class="chartContainer">
    <div class="mat-elevation-z2">
      <div *ngIf="chartToolbar">
        <template-slot (afterInit)="afterInitSlot($event)"></template-slot>
      </div>

      <div class="chartBody" [ngStyle]="{ height: getChartbodyHeight() }">
        <as-split
          [disabled]="!legendVisible"
          unit="pixel"
          [gutterSize]="gutterSize"
          (dragEnd)="onDragEnd($event, 3)"
          [direction]="legendMode === 'top' || legendMode === 'bottom' ? 'vertical' : 'horizontal'"
        >
          <as-split-area [size]="'*'" [order]="0">
            <ng-container>
              <ng-container *ngTemplateOutlet="chartContentTemplate"> </ng-container>
            </ng-container>
          </as-split-area>
        </as-split>
      </div>
    </div>
  </div>
</div>
<ng-template #chartContentTemplate>
  <div class="general-chart-body" #myDiv>
    <div
      *ngFor="let renderChart of toRenderCharts; let i = index"
      [ngClass]="{
        standalone: toRenderCharts.length === 1,
        'one-chart-row': compareExperiment && layout === 'stack',
        'two-chart-row': compareExperiment && layout === 'byside'
      }"
    >
      <as-split
        unit="pixel"
        [disabled]="!legendVisible"
        gutterSize="7"
        (dragEnd)="onDragEnd($event, i)"
        [direction]="legendMode === 'top' || legendMode === 'bottom' ? 'vertical' : 'horizontal'"
      >
        <as-split-area
          *ngIf="legendVisible"
          [size]="getLegendSize(i)"
          [order]="legendMode === 'right' || legendMode === 'bottom' ? 2 : 1"
        >
          <ng-container>
            <ng-container *ngTemplateOutlet="legendTemplate; context: { $implicit: renderChart?.legend, index: i }">
            </ng-container>
          </ng-container>
        </as-split-area>

        <as-split-area [size]="'*'" [order]="legendMode === 'right' || legendMode === 'bottom' ? 1 : 2">
          <ng-container>
            <ng-container *ngTemplateOutlet="chartTemplate; context: { $implicit: renderChart }"> </ng-container>
          </ng-container>
        </as-split-area>
      </as-split>
    </div>
  </div>
</ng-template>

<ng-template #legendTemplate let-legendTemplate let-index="index">
  <div class="legend-container">
    <app-chart-legend
      *ngIf="legendTemplate"
      [legendTemplate]="legendTemplate"
      [legendHeight]="widget?.templateLayout?.height ? widget.templateLayout.height : 300"
    >
    </app-chart-legend>
  </div>
</ng-template>

<ng-template #chartTemplate let-renderChart>
  <div
    class="outer-generall-chart-container"
    [ngStyle]="{
      height: widget?.templateLayout?.height ? widget.templateLayout.height + 'px' : '100%',
      'min-height': widget?.templateLayout?.height ? widget.templateLayout.height + 'px' : '300px'
    }"
  >
    <div class="generall-chart-container-header">
      <div *ngFor="let option of getArrayFromOptionsMap(); let i = index" style="float: left; margin-left: 15px">
        <div *ngIf="option">
          <p mat-line class="chart-option-key">
            {{ '@' + option.key + '@' | translate | capitalize }}
          </p>
          <p mat-line class="chart-option-value">{{ option.value }}</p>
        </div>
      </div>

      <app-bubble-pagination
        class="paginator"
        [maxCount]="maxCount"
        [pageSize]="pageSize"
        [pageIndex]="pageIndex"
        (pageChange)="onPageChange($event)"
      ></app-bubble-pagination>
    </div>
    <div class="generall-chart-container">
      <ng-container *ngIf="loading || loadingZoom">
        <div class="loading-label">
          <span>Lade Chart ...</span>
        </div>
        <mat-progress-bar [mode]="'buffer'" class="progress-container"></mat-progress-bar>
      </ng-container>

      <generalChart
        *ngIf="renderChart"
        class="renderChartContainer"
        #generalChart
        [chartData]="renderChart.chartData"
        [sortByChartData]="sortData"
        [chartIndex]="renderChart.index"
        [containerPaddingRight]="_chartContainerPaddingRight"
        (changeDataOfElement)="changeDataOfElement($event)"
        [labelXAxisVisible]="labelXAxisVisible"
        [toggleChartType]="toggleChartType"
        [combined]="combined"
        [hiddenDatasets]="hiddenDatasets"
        (afterInit)="afterInitCharts($event)"
        (afterUpdate)="afterUpdateChart()"
      >
      </generalChart>
    </div>
  </div>
</ng-template>

<mat-menu #compareExperimentMenu="matMenu">
  <button
    mat-menu-item
    *ngFor="let compareChartInformation of compareChartsInformations"
    (click)="addExperimentToCompare(compareChartInformation)"
  >
    {{ compareChartInformation.name }}
  </button>
</mat-menu>

<mat-menu #chartMathOptionMenu="matMenu">
  <button
    mat-menu-item
    *ngFor="let mathOptions of optionTree?.chartMathOption"
    (click)="handleOptionsMenus('chartMathOption', mathOptions)"
  >
    {{ mathOptions }}
  </button>
</mat-menu>

<mat-menu #chartOptionsMenu="matMenu">
  <button
    mat-menu-item
    *ngFor="let chartOption of optionTree?.chartOptions"
    (click)="handleOptionsMenus('chartOption', chartOption)"
  >
    {{ chartOption }}
  </button>
</mat-menu>

<mat-menu #chartSorterMenu="matMenu">
  <button mat-menu-item *ngFor="let sort of optionTree?.chartSorter" (click)="handleOptionsMenus('chartSorter', sort)">
    {{ sort }}
  </button>
</mat-menu>

<mat-menu #representationMenu="matMenu">
  <button mat-menu-item *ngFor="let area of optionTree?.representation" (click)="handleOptionsMenus('area', area)">
    {{ area }}
  </button>
</mat-menu>

<mat-menu #chartUnitMenu="matMenu">
  <button
    mat-menu-item
    *ngFor="let chartUnit of optionTree?.chartUnits"
    (click)="handleOptionsMenus('chartUnit', chartUnit)"
  >
    {{ chartUnit }}
  </button>
</mat-menu>

<mat-menu #chartIntegralMenu="matMenu">
  <div class="integral-item-container" mat-menu-item *ngFor="let dataSet of getIntegrals()">
    <div class="integral-item-color" mat-mini-fab [style.background]="dataSet.borderColor"></div>
    <span class="integral-item-label" [matTooltip]="dataSet.label">
      {{ dataSet.label }}
    </span>
    <button mat-icon-button (click)="removeIntegral(dataSet)" class="integral-remove-btn">
      <mat-icon>remove</mat-icon>
    </button>
  </div>
  <button mat-menu-item (click)="addIntegral()">
    <mat-icon>add</mat-icon> {{ 'CHART.VIEW.INTEGRAL.addIntegral' | translate | capitalize }}
  </button>
</mat-menu>

<!--<mat-menu #legendMenu="matMenu">
  <button mat-menu-item [disabled]="!legendVisible" (click)="switchLegend('left')">
    <ng-container>
      <mat-icon>swap_horiz</mat-icon>{{'@legendLeft@'|translate|capitalize}}
    </ng-container>
  </button>-->
<!-- <button mat-menu-item [disabled]="!legendVisible" (click)="switchLegend('bottom')">
    <ng-container>
      <mat-icon>swap_vert</mat-icon>{{'@legendDown@'|translate|capitalize}}
    </ng-container>
  </button>-->
<!--  <button mat-menu-item [disabled]="!legendVisible" (click)="switchLegend('top')">
    <ng-container>
      <mat-icon>swap_vert</mat-icon>{{'@legendUp@'|translate|capitalize}}
    </ng-container>
  </button>-->
<!-- <button mat-menu-item [disabled]="!legendVisible" (click)="switchLegend('right')">
    <ng-container>
      <mat-icon>swap_horiz</mat-icon>{{'@legendRight@'|translate|capitalize}}
    </ng-container>
  </button>
  <button mat-menu-item (click)="switchLegend('hidden')">
    <ng-container *ngIf="legendVisible">
      <mat-icon>visibility_off</mat-icon>{{'@legendHidden@'|translate|capitalize}}
    </ng-container>
    <ng-container *ngIf="!legendVisible">
      <mat-icon>visibility_on</mat-icon>{{'@legendVisible@'|translate|capitalize}}
    </ng-container>
  </button>
</mat-menu>
-->
