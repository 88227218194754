import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ResizeEvent } from 'frontend/src/dashboard/view/resize/resize-event';
import { EResizeType } from 'frontend/src/dashboard/view/resize/resize-type.enum';
import { ResizeService } from 'frontend/src/dashboard/view/resize/resize.service';
import { EntryElementValue } from '../entry-collection/entry-element-value';
import { Headline } from './healdine';

@Component({
  selector: 'template-headline',
  templateUrl: './template-headline.component.html',
  styleUrls: ['./template-headline.component.scss'],
})
export class TemplateHeadlineComponent implements OnInit, OnDestroy {
  @Input() headline: Headline;
  @Input() stretched: any; // defines if element is stretched
  private resizeId: string;
  @ViewChild('headlineContainerRef') set c1(headlineContainerRef: ElementRef) {
    this._resizeApi.complete(this.resizeId);
    if (!headlineContainerRef) {
      return;
    }
    this.resizeId = this._resizeApi.create(
      headlineContainerRef.nativeElement,
      (event: ResizeEvent) => {
        this.elementHeight = event.getNewHeight();
      },
      { types: [EResizeType.HEIGHT] }
    );
  }

  elementHeight: number;
  constructor(private _resizeApi: ResizeService) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this._resizeApi.complete(this.resizeId);
  }

  get Value(): string {
    if (!this.headline.getEntry() && !this.headline.getValue()) {
      return null;
    }
    return (this.headline.getEntry() || this.headline.getValue()).getValue();
  }

  get Color(): string {
    if (!this.headline.getEntry() && !this.headline.getValue()) {
      return null;
    }
    return (this.headline.getEntry() || this.headline.getValue()).getColor();
  }

  get Size(): string {
    if (
      !this.headline.getValue() ||
      !(this.headline.getValue() instanceof EntryElementValue) ||
      !this.headline.getValue<EntryElementValue>().getSize()
    ) {
      return null;
    } else {
      return this.headline.getValue<EntryElementValue>().getSize() + 'px';
    }
  }
}
