import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { Injectable } from '@angular/core';
import { ConfigService } from '@core/config/config.service';
import { TranslateService } from '@ngx-translate/core';
import { Language } from './language.enum';

@Injectable()
export class LanguageService {
  private currentLanguage: Language = Language.EN;

  constructor(private _translate: TranslateService, private _configApi: ConfigService) {}

  /**
   * initialize language service
   * @returns void
   */
  public initialize(): void {
    this._translate.setDefaultLang(Language.EN);
    this.setLang();
  }
  /**
   * emits a new language
   * @param lang language
   * @returns void
   */
  public setLang(lang: string = undefined): void {
    const configLang = this._configApi.access().language;
    const auto = !configLang || configLang === 'auto';
    const browserLang = navigator.language.split('-')[0] || (navigator as any).userLanguage;
    const curLang: string = lang ? lang : auto ? browserLang : configLang;

    this.currentLanguage = (curLang || Language.EN).toLowerCase() as Language;
    this._translate.use(this.currentLanguage);
    if (this.currentLanguage === Language.DE) {
      registerLocaleData(localeDe);
    }
  }

  public getCurrentLang(): Language {
    return this.currentLanguage;
  }
}
