import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'node-network-delete-element',
  templateUrl: './node-network-delete-element.html',
  styleUrls: ['./node-network-delete-element.scss'],
})

/**
 * Angular component of node network edit menu to delete the selected node.
 */
export class NodeNetworkDeleteElement {
  @Output() removeNodes: EventEmitter<Event>;

  constructor() {
    this.removeNodes = new EventEmitter();
  }

  /**
   * Emits user event, that user has clicked on the trash element.
   * @param event User click.
   */
  public onclickTrash(event: MouseEvent): void {
    this.removeNodes.emit(event);
  }
}
