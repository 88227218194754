export enum EGanttStickyBlockType {
  STICKY_LEFT = 'STICKY_LEFT',
  STICKY_RIGHT = 'STICKY_RIGHT',
  STICKY_BOTH = 'STICKY_BOTH',
}

export enum ETimeGridType {
  HOURLY = 'Hourly',
  DAILY = 'Daily',
  WEEKLY = 'Weekly',
  MONTHLY = 'Monthly',
  QUARTERLY = 'Quarterly',
}

export enum EGanttTextStrategy {
  /**
   * @deprecated
   */
  HIDE_OVERLAPPING_LABELS = 0,
  CUT_OFF_LABELS = 1,
  CUT_OFF_LABELS_MULTIPLE_LINES = 6,
  SHOW_ALWAYS_LABELS = 2,
  SHOW_NEVER_LABELS = 3,
  SHOW_ALWAYS_LABELS_SPLIT = 4,
  SHOW_ALWAYS_LABELS_BG = 5,
}
