declare let NodeNetworkMiceCinema: any;

/**
 * Facade for all mice cinmea control fucntions for node network.
 */
export class MiceCinema {
  public parentNode: any;
  public cinema: any;
  public active = false;
  public eventLog: any = [];

  constructor() {
    this.cinema = new NodeNetworkMiceCinema();
  }

  /**
   * Starts mice cinema.
   */
  public start(): void {
    if (this.active) {
      this.cinema.start();
    }
  }

  /**
   * Pauses mice cinema.
   */
  public pause(): void {
    if (this.active) {
      this.cinema.pause();
    }
  }

  /**
   * Stops mice cinema.
   */
  public stop(): void {
    if (this.active) {
      this.cinema.stop();
    }
  }

  /**
   * Fast forwards mice cinema animation.
   */
  public fastForward(): void {
    if (this.active) {
      this.cinema.fastForward();
    }
  }

  /**
   * Reverts mice cinema animation.
   * Increases animation speed if it will be used multiple times.
   */
  public fastRevert(): void {
    if (this.active) {
      this.cinema.fastRevert();
    }
  }

  /**
   * Cinema jumps to position of an event by given event index.
   * @param index Eventindex.
   */
  public goToPosition(index: number): void {
    if (this.active) {
      this.cinema.goToPosition(index);
    }
  }

  /**
   * Initializes mice cinema.
   * @param parentNode Parent node to render mice cinema components into.
   * @param nodeNetwork Instance of node network.
   */
  public build(parentNode: any, nodeNetwork: any): void {
    this.parentNode = parentNode;
    this.cinema.init(this.eventLog, nodeNetwork, this.parentNode);
  }

  /**
   * Rebuilds all mice cinema components.
   */
  public reBuild(): void {
    this.cinema.reBuild();
  }

  /**
   *
   * @param eventLog
   */
  public setEventLog(eventLog: any): void {
    this.eventLog = eventLog;
    if (this.cinema) {
      this.cinema.setEventLog(eventLog);
    }
  }

  /**
   * Returns all events from cinema.
   */
  public getEventLog(): any[] {
    return this.cinema.getEventLog();
  }

  /**
   * Returns instance of time engine which handles cinema animations and step order.
   */
  public getTimeEngine(): any {
    return this.cinema.getTimeEngine();
  }

  /**
   * Returns current step.
   */
  public getCurrentIndex(): number {
    return this.getTimeEngine().getCurrentIndex();
  }

  /**
   * Cheks if mice cinema is active.
   */
  public isActive(): boolean {
    return this.active;
  }

  /**
   * Changes active state of mice cinema.
   * @param active (De-)Activates mice cinema.
   */
  public setActive(active: boolean): void {
    if (!active) this.stop();
    this.active = active;
  }
}
