import { environment } from 'frontend/src/environments/environment';

export class Logger {
  private _name: string;

  constructor(name = '') {
    this._name = name;
  }

  public log<T>(...args: T[]): void {
    this.print('log', ...args);
  }

  public info<T>(...args: T[]): void {
    this.print('info', ...args);
  }

  public debug<T>(...args: T[]): void {
    this.print('debug', ...args);
  }

  public warn<T>(...args: T[]): void {
    this.print('warn', ...args);
  }

  public error<T>(...args: T[]): void {
    this.print('error', ...args);
  }

  private print<T>(type: 'log' | 'info' | 'debug' | 'warn' | 'error', ...args: T[]): void {
    switch (type) {
      case 'log':
        if (environment.minLogLevel < 4) return;
      case 'info':
        if (environment.minLogLevel < 4) return;
      case 'warn':
        if (environment.minLogLevel < 3) return;
      case 'debug':
        if (environment.minLogLevel < 2) return;
      case 'error':
        if (environment.minLogLevel < 1) return;
    }

    console[type](`[${this._name}]`, ...args);
  }

  public get name(): string {
    return this._name;
  }

  public set name(value: string) {
    this._name = value;
  }
}
