<mat-list>
  <mat-list-item class="contentText">
    {{ 'GANTT.ROW.colorOdd' | translate }}
    <mat-slide-toggle (change)="handleColorOddRows($event)" [checked]="settings.colorizeRowSibling"></mat-slide-toggle>
  </mat-list-item>
  <mat-divider></mat-divider>
  <mat-list-item class="contentText">
    {{ 'GANTT.ROW.hierachyRow' | translate }}
    <mat-slide-toggle
      (change)="handleRowClorHierarchieActivity($event)"
      [checked]="settings.rowColorHierarchies.active"
    ></mat-slide-toggle>
  </mat-list-item>
  <ng-container *ngIf="settings.rowColorHierarchies.active">
    <mat-divider></mat-divider>
    <mat-list-item class="contentText secondLevelList">
      <span class="indented">{{ '@first-row-hierarchie-color@' | translate }}</span>
      <app-color-picker
        [color]="settings.rowColorHierarchies.colors[0]"
        (changeEvent)="setRowColorHierarchy($event, 0)"
        [resetColor]="'#00000000'"
        [disableAlpha]="false"
      >
      </app-color-picker>
    </mat-list-item>
    <mat-divider></mat-divider>
    <mat-list-item class="contentText secondLevelList">
      <span class="indented">{{ '@second-row-hierarchie-color@' | translate }}</span>
      <app-color-picker
        [color]="settings.rowColorHierarchies.colors[1]"
        (changeEvent)="setRowColorHierarchy($event, 1)"
        [resetColor]="'#00000000'"
        [disableAlpha]="false"
      >
      </app-color-picker>
    </mat-list-item>
    <mat-divider></mat-divider>
    <mat-list-item class="contentText secondLevelList">
      <span class="indented">{{ '@third-row-hierarchie-color@' | translate }}</span>
      <app-color-picker
        [color]="settings.rowColorHierarchies.colors[2]"
        (changeEvent)="setRowColorHierarchy($event, 2)"
        [resetColor]="'#00000000'"
        [disableAlpha]="false"
      >
      </app-color-picker>
    </mat-list-item>
  </ng-container>
  <mat-divider></mat-divider>
  <mat-list-item class="contentText">
    {{ 'GANTT.ROW.entryColorization' | translate }}
    <mat-slide-toggle
      (change)="handleRowEntryColorization($event)"
      [checked]="settings.ganttEntryColorization"
    ></mat-slide-toggle>
  </mat-list-item>
  <mat-divider></mat-divider>
  <mat-list-item class="contentText">
    {{ 'GANTT.ROW.rowHeight' | translate }}
    <app-slider
      class="sliderContainer"
      [max]="200"
      [min]="12"
      [value]="settings.rowHeight"
      [label]="settings.rowHeight + ' px'"
      (changeEvent)="rowHeightSliderChange($event)"
    ></app-slider>
  </mat-list-item>
  <mat-divider></mat-divider>
  <mat-list-item class="contentText">
    {{ 'GANTT.ROW.stickyrow' | translate }}
    <mat-slide-toggle (change)="handleStickyRowsActivity($event)" [checked]="settings.showStickyRows">
    </mat-slide-toggle>
  </mat-list-item>
  <ng-container *ngIf="settings.showStickyRows">
    <mat-divider></mat-divider>
    <mat-list-item class="contentText secondLevelList">
      <span class="indented">{{ 'GANTT.ROW.stickyrowFirstRow' | translate }}</span>
      <mat-slide-toggle (change)="handleFirstStickyRowsActivity($event)" [checked]="settings.enableFirstRowStickyMode">
      </mat-slide-toggle>
    </mat-list-item>
  </ng-container>
  <ng-container *ngIf="settings.showStickyRows && settings.enableFirstRowStickyMode">
    <mat-divider></mat-divider>
    <mat-list-item class="contentText secondLevelList">
      <span class="indented">{{ 'GANTT.ROW.stickyrowFirstRowWidthChildRows' | translate }}</span>
      <mat-slide-toggle
        (change)="handleFirstStickyRowWithChildRowsActivity($event)"
        [checked]="settings.enableFirstRowWithChildRowsStickyMode"
      >
      </mat-slide-toggle>
    </mat-list-item>
  </ng-container>
</mat-list>
