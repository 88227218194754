import {
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  inject,
  Injectable,
  Type,
  ViewContainerRef,
} from '@angular/core';
import { Action } from '@app-modeleditor/components/button/action/action';
import { ContentElement } from '@app-modeleditor/components/content/content-element/content-element';
import { Lightbox } from '@app-modeleditor/components/lightbox/lightbox';
import { Condition } from '@app-modeleditor/components/template-actions/template-condition';
import { HierarchicalMenuItem } from '@app-modeleditor/components/template-ui/hierarchical-menu-item';
import { ERequestMethod, RequestService } from '@app-modeleditor/request.service';
import { CloudMessagingService } from '@core/notification/cloud-messaging.service';
import { Notification } from '@core/notification/notification';
import { Store } from '@ngxs/store';
import { Template } from 'frontend/src/dashboard/model/resource/template';
import { ETemplateMode } from 'frontend/src/dashboard/model/resource/template-mode.enum';
import { SharedToolbarService } from 'frontend/src/dashboard/view/navbar/toolbar/shared-toolbar.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TemplateActions } from '../../template/data-access/template.actions';
import { IActiveMenuItem } from './active-menu-item.interface';
import { IEditMode } from './editmode.interface';

export interface Registered {
  editable?: boolean;
  templates: Record<string, Template>;
}

@Injectable({
  providedIn: 'root',
})
export class TemplateService {
  private readonly store = inject(Store);
  private updateElementIds: Subject<string[]> = new Subject<string[]>();
  private refreshElementDataIds: Subject<string[]> = new Subject<string[]>();
  currentActiveNode: HierarchicalMenuItem;

  registered: Record<string, Registered> = {};
  private regObs: BehaviorSubject<Record<string, Registered>> = new BehaviorSubject<Record<string, Registered>>({});
  public afterRegisteredChanges(): Observable<Record<string, Registered>> {
    return this.regObs.asObservable();
  }
  private activeMenuItems: HierarchicalMenuItem[] = [];
  private closedMenuItemAction$: Subject<Action> = new Subject<Action>();

  constructor(
    private requestApi: RequestService,
    private fcm: CloudMessagingService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private toolbarApi: SharedToolbarService
  ) {
    this.fcm.getMessage().subscribe((event: Notification) => {
      if (!event) {
        return;
      }
      this.updateElements(event.getUpdateElementIds());
      this.refreshElementData(event.getRefreshElementIds());
    });

    this.toolbarApi.getAction().subscribe((action) => {
      if (!action || !this.currentActiveNode || !this.currentActiveNode.getTemplateMode()) {
        return;
      }

      switch (action.type) {
        case 'TOOLBAR.lock':
          this.requireEditMode(
            this.currentActiveNode.getId(),
            this.currentActiveNode.getResourceId(),
            this.currentActiveNode.getCanonicalName()
          ).subscribe();
          break;
        case 'TOOLBAR.lock_open':
          this.releaseEditMode(
            this.currentActiveNode.getId(),
            this.currentActiveNode.getResourceId(),
            this.currentActiveNode.getCanonicalName()
          ).subscribe();
          break;
      }
    });
  }

  /**
   * checks attribute of template
   * @param {Template} el template to check
   * @param {string} attr attribute to check for
   * @returns boolean
   */
  public checkCondition(el: Template, attr: string): boolean {
    if (!el[attr]) {
      return true;
    }

    for (const key of Object.keys(el[attr])) {
      const cond = new Condition(el[attr][key]);
      const t: Template[] = this.getTemplates();
      const valid: number = cond.validate(t);
      if (valid !== -1) {
        return false;
      }
    }

    return true;
  }

  /**
   * get flat list of all templates
   * @param {string} resourceId id of the resource
   * @returns Template[]
   */
  public getTemplates(resourceId: string = undefined): Template[] {
    let r = [];
    Object.keys(this.registered || {}).forEach((key: string) => {
      if (resourceId === undefined || resourceId === key) {
        Object.keys(this.registered[key].templates || {}).forEach((tId) => r.push(this.registered[key].templates[tId]));
      }
    });
    r = r.concat(this.getTemporaryTemplates());
    return r;
  }

  private _temporaryTemplates: Template[] = [];
  public registerTemporaryTemplate(t: Template): void {
    this._temporaryTemplates = this._temporaryTemplates.concat(t);
  }

  public unregisterTemporaryTemplate(id: string): void {
    this._temporaryTemplates = this.getTemporaryTemplates().filter((t: Template) => t.getId() !== id);
  }

  private getTemporaryTemplates(): Template[] {
    return this._temporaryTemplates || [];
  }

  /**
   * get a specific template (by its resource context)
   * @param {string} id identifier of the template
   * @param {string} resourceId optional context where to look at
   * @returns T extends Template
   */
  public getElementById<T extends Template>(id: string, resourceId = undefined): T {
    const temp = (
      resourceId && resourceId !== 'undefined' ? this.registered[resourceId]?.templates[id] : this.findTemplate(id)
    ) as T;

    return temp ? temp : this.findTemplate(id) || (this.getTemporaryTemplates().find((t) => t.getId() === id) as T);
  }

  /**
   * Returns an array of resource IDs that contain a template with the given ID.
   * @param {string} id The ID of the template to search for.
   * @returns {string[]} An array of resource IDs that contain the template with the given ID.
   */
  public getResourceIdsByTemplateId(id: string): string[] {
    return Object.keys(this.registered).filter((key: string) => {
      return this.registered[key].templates[id] ? true : false;
    });
  }

  /**
   * refresh element data by given template ids
   * @param ids string[]
   * @returns void
   */
  public refreshElementData(ids: string[]): void {
    if (!ids) {
      return;
    }
    this.$checkForUpdate(ids);
    this.store.dispatch(new TemplateActions.RefreshElements(ids));
    this.refreshElementDataIds.next(ids);
  }

  $checkForUpdate(ids: string[]): void {
    (ids || []).forEach((id: string) => {
      const t: Template = this.findTemplate(id);
      if (!t?.getElementRef()) {
        this.markForUpdate(id);
      }
    });
  }

  public $markForUpdate: string[] = [];
  public markForUpdate(...ids: string[]): void {
    this.$markForUpdate = (this.$markForUpdate || [])
      .filter((id: string) => !ids.find((_key: string) => _key === id))
      .concat(ids);
  }

  public unmarkFromUpdate(...ids: string[]): void {
    this.$markForUpdate = (this.$markForUpdate || []).filter((id: string) => !ids.find((_key: string) => _key === id));
  }

  public needsUpdate(id: string): boolean {
    return this.$markForUpdate.find((_id: string) => id === _id) ? true : false;
  }

  /**
   * triggers on refresh element data
   * @return Observable<string[]>
   */
  public onRefreshElementData(): Observable<string[]> {
    return this.refreshElementDataIds.asObservable();
  }

  /**
   * updates a list of given templates
   * @param ids string[]
   * @returns void
   */
  public updateElements(ids: string[]): void {
    if (!ids) {
      return;
    }
    this.$checkForUpdate(ids);
    this.updateElementIds.next(ids);
  }

  /**
   * triggers if elements will be forced to update
   * @returns Observable<string[]>
   */
  public onUpdateElements(): Observable<string[]> {
    return this.updateElementIds.asObservable();
  }

  public updateAllElements(): void {
    this.updateElements(this.getTemplates().map((t) => t.getId()));
  }
  /**
   * block resource for usage
   * @param templateId string
   * @param resourceId string
   * @param canonicalName string
   * @returns Observable<any>
   */
  public requireEditMode(
    templateId: string = this.currentActiveNode.getId(),
    resourceId: string = this.currentActiveNode.getResourceId(),
    canonicalName: string = this.currentActiveNode.getCanonicalName()
  ): Observable<any> {
    return this.requestApi
      .call(
        ERequestMethod.GET,
        `rest/template/${templateId}/requireeditmodeenabled/resource/${resourceId}/canonicalName/${canonicalName}`
      )
      .pipe(
        tap((r: IEditMode) => {
          const t = this.registered[resourceId];
          t.editable = true;
          this.store.dispatch(new TemplateActions.UpdateResource(resourceId, true));
          this.regObs.next(this.registered);
        })
      );
  }

  /**
   * release resource of usage
   * @param templateId string
   * @param resourceId string
   * @param canonicalName string
   * @returns Observable<any>
   */
  public releaseEditMode(
    templateId: string = this.currentActiveNode.getId(),
    resourceId: string = this.currentActiveNode.getResourceId(),
    canonicalName: string = this.currentActiveNode.getCanonicalName()
  ): Observable<any> {
    return this.requestApi
      .call(
        ERequestMethod.GET,
        `rest/template/${templateId}/releaseeditmodeenabled/resource/${resourceId}/canonicalName/${canonicalName}`
      )
      .pipe(
        tap((r: boolean) => {
          const t = this.registered[resourceId];
          t.editable = false;
          this.store.dispatch(new TemplateActions.UpdateResource(resourceId, false));
          this.regObs.next(this.registered);
        })
      );
  }

  /**
   * updates an template
   * @param templateId id of the template which should be updated
   * @param template template which should replace the old one
   * @returns void
   */
  public updateTemplate(templateId: string, template: Template): void {
    const resourceId: string = this.findResourceByTemplate(templateId);
    this.registered[resourceId].templates[templateId] = template;
    this.regObs.next(this.registered);
  }

  /**
   * loads template component into View
   * @param type Type<T>
   * @param vc: ViewContainerRef
   * @returns ComponentRef<T>
   */
  public loadComponent<T>(
    type: Type<T>,
    vc: ViewContainerRef,
    resolver: ComponentFactoryResolver = this.componentFactoryResolver
  ): ComponentRef<T> {
    if (!vc) {
      return;
    }
    const componentFactory: ComponentFactory<T> = resolver.resolveComponentFactory(type);
    vc.clear();
    const ref: ComponentRef<T> = vc.createComponent(componentFactory);
    return ref;
  }

  /**
   * requests write permission for a resource
   * @param templateId string
   * @param resourceId string
   * @param canonicalName string
   * @returns Observable<IEditMode>
   */
  private requestWritePermission(templateId: string, resourceId: string, canonicalName: string): Observable<IEditMode> {
    return this.requestApi.call(
      ERequestMethod.GET,
      `rest/template/${templateId}/checkeditmodeenabled/resource/${resourceId}/canonicalName/${canonicalName}`
    );
  }

  /**
   * checks editmode and / or requests write permission if necessary
   * @param template Template
   * @returns void
   */
  public checkEditMode(template: Template): void {
    if (!template.getTemplateMode() || !template.getResourceId()) {
      return;
    }
    const t = this.registered[template.getResourceId()];

    // if (t.editable === false) { return; }
    switch (template.getTemplateMode()) {
      case ETemplateMode.EDITABLE:
        if (!(template instanceof Lightbox)) {
          t.editable = true;
          this.store.dispatch(new TemplateActions.UpdateResource(template.getResourceId(), true));
          this.regObs.next(this.registered);
        }
        break;
      case ETemplateMode.REQUIRE_EDIT_MODE:
        this.requestWritePermission(template.getId(), template.getResourceId(), template.getCanonicalName()).subscribe(
          (r: IEditMode) => {
            const state: boolean = r && r.alreadyEditable ? r.alreadyEditable : false;
            t.editable = state;
            this.store.dispatch(new TemplateActions.UpdateResource(template.getResourceId(), state));
            this.regObs.next(this.registered);
          }
        );
        break;
    }
  }

  public getRegistered(): Record<string, Registered> {
    return this.registered;
  }

  public addOrUpdate(resourceId: string, template: Template): void {
    if (template instanceof ContentElement) {
      // add template to new store concept
      this.store.dispatch(new TemplateActions.AddTemplate(template.getDto()));
    }

    // delete all existing occurances of the template id
    Object.keys(this.registered).forEach((key: string) => {
      delete this.registered[key].templates[template.getId()];
    });

    if (this.registered[resourceId]) {
      this.registered[resourceId].templates[template.getId()] = template;
    } else {
      this.registered[resourceId] = {
        templates: { [template.getId()]: template },
      };
    }

    this.regObs.next(this.registered);
  }

  /**
   * registers a template to be globally accessable
   * @param resourceId string
   * @param template Template
   * @returns void
   */
  public registerTemplate(resourceId: string, template: Template): void {
    if (template.isRegisterable() !== true) {
      return;
    }

    this.addOrUpdate(resourceId, template);
    if (template.getEnableConditions()) {
      template.setEnabledByExternalCondition(() => this.checkCondition(template, 'enableConditions'));
    }

    if (template.getVisibilityConditions()) {
      template.setVisibleByExternalCondition(() => this.checkCondition(template, 'visibilityConditions'));
    }
  }

  /**
   * find a registered resource by a given template id
   * @param templateId string
   * @returns Template
   */
  public findResourceByTemplate(templateId: string): string {
    return Object.keys(this.registered).find((key: string) => {
      return this.registered[key].templates[templateId] ? true : false;
    });
  }

  /**
   * find a template by its id
   * @param templateId string
   * @returns Template
   */
  public findTemplate<T extends Template>(templateId: string): T {
    return this.getTemplates().find((t) => t.getId() === templateId) as T;
  }

  /**
   * unregisters a template from global accessability
   * @param templateId string
   * @param resourceId (optional) resourceId where the template belongs to
   * @returns void
   */
  public unregisterTemplate(templateId: string, resourceId?: string): void {
    if (this.registered[resourceId]) {
      delete this.registered[resourceId].templates[templateId];
      this.regObs.next(this.registered);
    }
  }

  public onClosedMenuItemAction(): Observable<Action> {
    return this.closedMenuItemAction$.asObservable();
  }

  /**
   * Removes an active MenuItem from the list of visible MenuItems.
   * Also takes care that a customCloseTemplateAction is executed, if present.
   */
  public removeActiveMenuItem(templateId: string): void {
    const templateIndex = this.activeMenuItems.findIndex((i) => i.getId() === templateId);

    if (templateIndex === -1) {
      console.warn(`Could not find template with id ${templateId} in active menu items`);
      return;
    }

    // execute custom close action if available
    if (this.activeMenuItems[templateIndex]?.getOnCustomCloseTemplateAction()) {
      this.closedMenuItemAction$.next(this.activeMenuItems[templateIndex].getOnCustomCloseTemplateAction());
    }

    // remove template from active menu items
    this.activeMenuItems.splice(templateIndex, 1);
    this.passActiveMenuItems();
  }

  public setActiveMenuItem(template: HierarchicalMenuItem): void {
    this.activeMenuItems.push(template);
    this.passActiveMenuItems();
  }

  /**
   * Passes a list of active MenuItems to the CloudMessagingService.
   */
  private passActiveMenuItems(): void {
    const items: IActiveMenuItem[] = this.activeMenuItems.map((t) => {
      return {
        id: t.getId(),
        resourceId: t.getResourceId(),
        canonicalName: t.getCanonicalName(),
      };
    });

    this.fcm.setActiveMenuItems(items);
  }

  public getActiveMenuItems(): HierarchicalMenuItem[] {
    return this.activeMenuItems;
  }
}
