import { Action } from '@app-modeleditor/components/button/action/action';
import { EActionType } from '@app-modeleditor/components/button/action/action-type.enum';
import { EPredefinedAction } from '@app-modeleditor/components/button/action/predefined-action.enum';
import { Button } from '@app-modeleditor/components/button/button';
import { Content } from '@app-modeleditor/components/content/content';
import { ContentPart } from '@app-modeleditor/components/content/content-part/content-part';
import { EntryCollection } from '@app-modeleditor/components/entry-collection/entry-collection';
import { EntryElement } from '@app-modeleditor/components/entry-collection/entry-element';
import { EEntryElementPosition } from '@app-modeleditor/components/entry-collection/entry-element-position.enum';
import { EntryElementValue } from '@app-modeleditor/components/entry-collection/entry-element-value';
import { EFieldType } from '@app-modeleditor/components/entry-collection/field-type.enum';
import { Lightbox } from '@app-modeleditor/components/lightbox/lightbox';
import { LightboxService } from '@app-modeleditor/components/lightbox/lightbox.service';
import { ConfirmLightbox } from '@app-modeleditor/components/lightbox/predefined/confirm-lightbox';
import { ConfigService } from '@core/config/config.service';
import { SaxMsBestGanttSettings } from 'frontend/src/dashboard/gantt/gantt/saxms-best-gantt.settings';
import { EResizeMode } from 'frontend/src/dashboard/model/resource/template-resize-mode.enum';
import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { GanttTemplateDataService } from '../../template-data/gantt-template-data.service';
import { GanttSettingsService } from '../service/gantt-settings.service';
import { IGanttSettingsInputData } from './gantt-settings.interface';
import { EGanttLightboxExitType } from './gantt-style.enum';

export class GanttSettingsDialog extends Lightbox {
  private transferSettingsGanttIds: string[] = [];

  constructor(
    public styleSettings: SaxMsBestGanttSettings,
    private lightboxApi: LightboxService,
    private settingsService: GanttSettingsService,
    private configService: ConfigService,
    private ganttTemplateDataService: GanttTemplateDataService
  ) {
    super();

    this.setDisableSaveButton(true);
    this.setDisableCancelButton(true);
    this.setAdditionalButtons(this.getActionButtons());
    this.setCanOpenExternalWindow(false);

    const settingsContentPart = this.getSettingsContentPart();
    this.setName('GANTT.settings');
    this.setResizeMode(EResizeMode.FIT_PARENT);
    this.setFullscreen(true).setResizeable(false).setDraggable(false);
    this.setContent(new Content().setResizeMode(EResizeMode.FIT_PARENT).setContentParts([settingsContentPart]));
  }

  private getSettingsContentPart(): ContentPart {
    const inputData: IGanttSettingsInputData = {
      settings: this.styleSettings,
      additional: {
        blockAttributes: this.getBlockAttributes(),
      },
    };

    const settingsPage = new EntryCollection().setResizeMode(EResizeMode.FIT_PARENT).addEntryElements(
      new EntryElement()
        .setFieldType(EFieldType.GANTT_SETTINGS)
        .setValue(new EntryElementValue().setValue(inputData))
        .onChanges((elem: EntryElementValue) => {})
    );

    return new ContentPart()
      .setFullscreen(true)
      .setDisplayContentpartContainer(false)
      .setContentElements([settingsPage]);
  }

  private getBlockAttributes(): string[] {
    return Object.keys(this.ganttTemplateDataService.getTemplateData().getAttributeMapping())
      .map((key: string) => {
        return this.ganttTemplateDataService.getTemplateData().getAttributeMapping()[key].localization;
      })
      .sort();
  }

  private getActionButtons(): Button[] {
    const buttons: Button[] = [];

    if (this.configService.access().templates.Gantt.allowTransferGanttSettings) {
      buttons.push(
        new Button()
          .setPosition(EEntryElementPosition.RIGHT)
          .setName('GANTT.SETTINGS.TRANSFER_SETTINGS')
          .chainActions(
            new Action().setCb(() => {
              const l: ConfirmLightbox = new ConfirmLightbox('GANTT.SETTINGS.TRANSFER_SETTINGS');

              l.setContent(this.getTransferGanttSettings())
                .setConfirmLabel('GANTT.SETTINGS.transfer')
                .setCustomConfirmAction(() => {
                  return this.transferSettings();
                })
                .setCustomCancelAction(() => {
                  return of(void 0);
                });

              this.lightboxApi.open(l);
              return of();
            })
          )
      );
    }

    buttons.push(
      new Button()
        .setPosition(EEntryElementPosition.RIGHT)
        .setName('GANTT.SETTINGS.CONFIG_FACTORY_SETTINGS')
        .chainActions(
          new Action().setCb(() => {
            return of(this.getRef().close({ type: EGanttLightboxExitType.RESET }));
          }),
          new Action().setActionType(EActionType.PREDEFINED).setId(EPredefinedAction.SAVE)
        )
    );

    buttons.push(
      new Button()
        .setPosition(EEntryElementPosition.RIGHT)
        .setName('BUTTON.save')
        .chainActions(
          new Action().setCb(() =>
            of(this.getRef().close({ type: EGanttLightboxExitType.SAVE, settings: this.styleSettings }))
          ),
          new Action().setActionType(EActionType.PREDEFINED).setId(EPredefinedAction.SAVE)
        )
    );

    return buttons;
  }

  /**
   * Returns the content of the dialog for transferring the gantt settings
   * @returns
   */
  private getTransferGanttSettings(): Content {
    this.transferSettingsGanttIds = [];

    return new Content().setResizeMode(EResizeMode.FIT_PARENT).setContentParts([
      new ContentPart()
        .setFullscreen(true)
        .setDisplayContentpartContainer(false)
        .setContentElements([
          new EntryCollection().setResizeMode(EResizeMode.FIT_PARENT).addEntryElements(
            new EntryElement()
              .setFieldType(EFieldType.TRANSFER_GANTT_SETTINGS)
              .setValue(new EntryElementValue().setValue(this.styleSettings.ganttID))
              .onChanges((elem: EntryElementValue) => {
                this.transferSettingsGanttIds = elem?.getValue() || [];
              })
          ),
        ]),
    ]);
  }

  /**
   * Saves the Gantt settings with the corresponding Gantt IDs.
   */
  private transferSettings(): Observable<void> {
    const requests: Observable<any>[] = [];

    this.transferSettingsGanttIds.forEach((ganttId) => {
      const settings: SaxMsBestGanttSettings = JSON.parse(JSON.stringify(this.styleSettings));
      settings.ganttID = ganttId;
      requests.push(this.settingsService.post(settings));
    });

    return forkJoin(requests).pipe(map((_) => void 0));
  }
}
