import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { IModel } from 'frontend/src/dashboard/model/model.data';
import { UserService } from 'frontend/src/dashboard/user/data-access/user.service';
import { takeWhile } from 'rxjs/operators';
import { HorizonService } from '../../../horizonselecter/horizon.service';
import { ModelService } from '../../../model/model.service';
import { ViewService } from './../../view.service';
import { SelectorComponent } from './selector.component';
import { GlobalSidenavService } from 'frontend/src/dashboard/view/options/global-sidenav.service';

@Component({
  selector: 'model-selector',
  templateUrl: './selector.html',
  styleUrls: ['./selector.scss'],
  providers: [],
})
export class ModelSelectorComponent extends SelectorComponent implements OnInit, OnDestroy {
  public currentModel: any;
  private alive = true;

  constructor(
    public modelService: ModelService,
    public sharedHorizonService: HorizonService,
    public systemMessage: MatSnackBar,
    public globalViewService: ViewService,
    public globalUserSettingsService: UserService,
    public globalSidenavService: GlobalSidenavService
  ) {
    super(globalViewService);
    this.title = '@models@';
    this.actions = [
      {
        callback: this.showModelView.bind(this),
        icon: 'more',
        title: '@model_management@',
      },
    ];

    this.modelService
      .getModels()
      .pipe(takeWhile(() => this.alive))
      .subscribe((models: IModel[]) => {
        this.items = models;
      });

    this.modelService
      .getCurrentModel()
      .pipe(takeWhile(() => this.alive))
      .subscribe((model: IModel) => {
        this.selectCurrentModel(model);
      });
  }

  private getTimestamp(timeString: string): any {
    return new Date(timeString).getTime();
    // return moment(timeString, 'YYYY-MM-DD HH:mm:ss.sss').valueOf();
  }

  /**
   * sets selected model as active for the whole app
   * @param model model
   */
  public setActive(model: any): void {
    this.modelService.setCurrentModel(model);
  }

  public selectCurrentModel(model: any): void {
    this.currentModel = model;
    this.selected = model;
  }

  ngOnDestroy() {
    this.alive = false;
  }

  /**
   * shows model view
   */
  public showModelView(): void {
    this.globalSidenavService.activate('model-timeline');
  }

  /**
   * returns the lates created model
   * @param models list of all models
   */
  public getLatestModel(models: Array<any>): any {
    let latestModel: any = models[0];
    for (const model of models) {
      if (this.getTimestamp(latestModel.created) < this.getTimestamp(model.created)) {
        latestModel = model;
      }
    }
    return latestModel;
  }

  ngOnInit() {}

  /**
   * check out which is the current model
   * if menu is open
   */
  onOpen(event) {
    // todo update current model
    // this.updateCurrentModel();
  }
}
