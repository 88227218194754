import { Injectable, OnDestroy } from '@angular/core';
import { EUserSettingsKey } from 'frontend/src/dashboard/user/user-seetings-key.enum';
import { IUserSettings, UserService } from 'frontend/src/dashboard/user/data-access/user.service';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { delay, finalize, switchMap, takeUntil } from 'rxjs/operators';
import { ETreeSettingsKeys } from './tree-settings-keys.enum';
import { ITreeSettings } from './tree-settings.interface';

const DEFAULT_TREE_SIZES = [20, 20];
@Injectable()
export class TreeSettingsService implements OnDestroy {
  private settings: IUserSettings;
  private _treeSettings: BehaviorSubject<{ [key: string]: ITreeSettings }> = new BehaviorSubject<{
    [key: string]: ITreeSettings;
  }>(null);
  private _settings: { [key: string]: any };
  private modelId: string;

  constructor(private _userSettingsApi: UserService) {
    this._userSettingsApi.getUserSettings().subscribe((settings: IUserSettings) => {
      this.settings = settings;
      this.init();
    });
  }

  private ngOverride: Subject<void> = new Subject<void>();
  updateTreeSettingsKey(key: ETreeSettingsKeys, entry: any, skip = false): void {
    if (!this._settings) {
      this._settings = {};
    }
    if (!this._settings[this.modelId]) {
      this._settings[this.modelId] = {};
    }
    this._settings[this.modelId][key] = entry;
    this.ngOverride.next();

    of(null)
      .pipe(delay(300))
      .pipe(takeUntil(this.ngOverride))
      .pipe(
        switchMap(() => {
          return this._userSettingsApi.setKey(this.settings, EUserSettingsKey.TREE, this._settings, skip);
        })
      )
      .pipe(finalize(() => {}))
      .subscribe(() => {
        this._treeSettings.next(this._settings);
      });
  }

  public setModelId(modelId: string): void {
    this.modelId = modelId;
    this.init();
  }

  private init(): void {
    if (!this.settings || !this.modelId) {
      return;
    }

    this._userSettingsApi.getKey(this.settings, EUserSettingsKey.TREE).subscribe((sett) => {
      this._settings = sett;
      this._treeSettings.next(this._settings);
    });
  }

  public onSettingsChanged(): Observable<{ [key: string]: ITreeSettings }> {
    return this._treeSettings.asObservable();
  }

  ngOnDestroy(): void {
    this.ngOverride.complete();
  }
}
