import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TemplateFooterElementComponent } from './template-footer-element.component';
import { ModelEditorModule } from '@app-modeleditor/modeleditor.module';

@NgModule({
  imports: [CommonModule, ModelEditorModule, MatTooltipModule],
  exports: [TemplateFooterElementComponent],
  declarations: [TemplateFooterElementComponent],
})
export class TemplateFooterElementModule {}
