<!-- <ng-container *ngIf="stackable">
  <div cdkDropList [id]="stackable.getId()" [cdkDropListConnectedTo]="connectedIds" [cdkDropListData]="stackable"
    class="stackable-list" (cdkDropListDropped)="drop($event)">

    <div class="stackable-box" [cdkDragDisabled]="stackable.isDisabled()"
      *ngFor="let item of stackable.getChildren(); let idx=index" cdkDrag>
      <div class="stackable-placeholder" *cdkDragPlaceholder> Drag&Drop </div>

      <app-stackable [parent]="stackable" [connectedTo]="connectedIds" [stackable]="item">
        <ng-template #content let-node>
          <ng-container *ngTemplateOutlet="templateNode; context:{$implicit:node}"></ng-container>
        </ng-template>
      </app-stackable>
    </div>

    <ng-container *ngIf="stackable.getContent()">
      <ng-container *ngTemplateOutlet="templateNode; context:{$implicit:stackable}"></ng-container>
    </ng-container>

    <ng-container *ngFor="let action of stackable.getActions()">
      <app-button [data]="action"></app-button>
    </ng-container>
  </div>
</ng-container> -->

<ng-container *ngIf="stackable">
  <div cdkDropListGroup [class.selected]="stackable.isSelected()" (click)="onClick($event)">
    <div
      *ngIf="stackable"
      cdkDropList
      class="item-dropzone parent"
      [id]="parentItemId"
      [cdkDropListData]="stackable.getParent()"
      [cdkDropListConnectedTo]="allDropListsIds"
      (cdkDropListDropped)="drop($event)"
    >
      <ul
        cdkDropList
        class="item-dropzone"
        [id]="stackable.getId()"
        [cdkDropListConnectedTo]="connectedDropListsIds"
        [cdkDropListData]="stackable"
        (cdkDropListDropped)="drop($event)"
      >
        <li
          *ngFor="let subItem of stackable.getChildren(); let idx = index"
          [cdkDragDisabled]="stackable.isDisabled()"
          cdkDrag
          [class]="getStackableItemClass(idx)"
        >
          <div class="item-drag-preview" *cdkDragPreview>
            {{ subItem.getChildren().length || 1 }} {{ 'CONDITION_RELOCATE.move' | translate }}
          </div>
          <div class="stackable-placeholder" *cdkDragPlaceholder>
            {{ subItem.getChildren().length || 1 }} {{ 'CONDITION_RELOCATE.insert' | translate }}
          </div>
          <mat-icon [class]="getDragHandleIconClass(subItem)" cdkDragHandle> drag_handle</mat-icon>
          <div [class]="subItem.getChildren().length > 0 ? 'stackable-child-group-item' : 'stackable-child-item'">
            <app-stackable
              [stackable]="subItem"
              [parent]="stackable"
              [connectedTo]="allDropListsIds"
              (handleDragRunning)="handleDraging($event)"
            >
              <ng-template #content let-node>
                <ng-container *ngTemplateOutlet="templateNode; context: { $implicit: node }"></ng-container>
              </ng-template>
            </app-stackable>
          </div>
        </li>

        <ng-container *ngIf="stackable.getContent()">
          <ng-container *ngTemplateOutlet="templateNode; context: { $implicit: stackable }"></ng-container>
        </ng-container>

        <ng-container *ngFor="let action of stackable.getActions()">
          <app-button [data]="action"></app-button>
        </ng-container>
      </ul>
    </div>
  </div>
</ng-container>
