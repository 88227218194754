import { IGanttShiftsBuilding } from './shift-build-interface';

/**
 * <b>Null Object</b> pattern implementation.
 * Does absolutly nothing.
 * @implements {IGanttShiftsBuilding}
 * @class
 */
export class GanttShiftsBuildingNULL implements IGanttShiftsBuilding {
  constructor() {}

  /**
   * @override
   */
  init(executer) {}

  /**
   * @override
   */
  initCallbacks(executer) {}

  /**
   * @override
   */
  removeCallbacks(executer) {}

  /**
   * @override
   */
  renderShifts(dataSet, parentNode, executer) {}

  /**
   * @override
   */
  removeAllShifts(executer) {}

  /**
   * @override
   */
  preloadPatterns(executer, originDataSet) {}

  /**
   * @override
   */
  clearTimeouts() {}

  /**
   * @override
   */
  resizeChange(executer, boolean) {}
}
