import { GanttLibService } from 'frontend/src/dashboard/gantt/gantt/gantt-lib.service';
import { IGanttAttributeMapping } from 'frontend/src/dashboard/gantt/general/generator/gantt-input.data';
import { GanttPluginHandlerService } from 'frontend/src/dashboard/gantt/general/plugin/gantt-plugin-handler.service';
import { IGanttResponse } from '../../../gantt-response';
import { GanttResponseStrategy } from './i-response-strategy';

/**
 * Update Strategy to load hierarchical plan from backend and replace all gantt entries by new data.
 */
export class GanttRowUpdateRefreshGanttStrategy implements GanttResponseStrategy {
  /**
   * Orders gantt data from backend and replaces origin dataset of current gantt.
   * Updates all plugins afterwards.
   * @param response Serverresponse data.
   * @param toolbarHandler Toolbar handler from SaxMSBestGantt.
   * @param externalPlugInHandler Mediator for nearly all components inside generalized gantt.
   * @param attributeMapping Attribute Mapping from gantt template to give the possibility to update block tooltips.
   */
  public update(
    response: IGanttResponse,
    ganttLibService: GanttLibService,
    ganttPluginHandlerService: GanttPluginHandlerService,
    attributeMapping: IGanttAttributeMapping
  ): boolean {
    const templateData = ganttPluginHandlerService.getTemplateData();
    const mappedData = ganttLibService.backendToGanttOriginInputMapper.ganttToInput(
      templateData.getHierarchicalPlan(),
      attributeMapping,
      templateData.getDefaultBlockTooltipSettings(),
      templateData.getGanttEntryAttributeMappings(),
      true
    );
    ganttLibService.bestGantt.refreshGanttByNewOriginData(mappedData);
    return true;
  }
}
