<mat-accordion class="example-headers-align">
  <mdui-headline
    *ngFor="let option of getOptions() | cut : currentPage * pageSize : pageSize"
    (onResults)="handleResults($event)"
    [id]="option.id"
    [onEvent]="localSubject"
    [options]="option"
  ></mdui-headline>
</mat-accordion>

<!-- pagination -->
<mat-paginator
  *ngIf="options.settingsCategories && !pageIgnore"
  (page)="handlePage($event)"
  [length]="options.settingsCategories.length"
  [pageSize]="pageSize"
  [pageSizeOptions]="[1, 2, 3, 4, 5, 10, 15, 20]"
>
</mat-paginator>
