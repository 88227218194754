import { Action } from '@app-modeleditor/components/button/action/action';
import { EActionType } from '@app-modeleditor/components/button/action/action-type.enum';
import { EPredefinedAction } from '@app-modeleditor/components/button/action/predefined-action.enum';
import { Button } from '@app-modeleditor/components/button/button';
import { EButtonDisplayType } from '@app-modeleditor/components/button/button-display-type.enum';
import { Content } from '@app-modeleditor/components/content/content';
import { ContentPart } from '@app-modeleditor/components/content/content-part/content-part';
import { EntryElement } from '@app-modeleditor/components/entry-collection/entry-element';
import { EEntryElementPosition } from '@app-modeleditor/components/entry-collection/entry-element-position.enum';
import { EntryElementValue } from '@app-modeleditor/components/entry-collection/entry-element-value';
import { EFieldType } from '@app-modeleditor/components/entry-collection/field-type.enum';
import { Observable } from 'rxjs';
import { Lightbox } from '../lightbox';
import { EntryCollection } from './../../entry-collection/entry-collection';

export class ContextChangeLightbox extends Lightbox {
  private cancel: Button;
  private discard: Button;
  private saveAll: Button;
  constructor(title?: string, text?: string) {
    super();
    this.setDisableSaveButton(true);
    this.setCanOpenExternalWindow(false);
    this.setDisableCancelButton(true);
    this.setDisableClose(true);
    this.setName(title || 'LABEL.security_question');

    this.setContent(
      new Content().setContentParts([
        new ContentPart().setDisplayContentpartContainer(false).setContentElements([
          new EntryCollection().setEntryElements([
            new EntryElement()
              .setFieldType(EFieldType.LABELED_TEXT)
              .setName(text || 'LABEL.unsaved_changes')
              .setValue(new EntryElementValue()),
          ]),
        ]),
      ])
    );

    this.cancel = this.getButton()
      .setOrder(-999)
      .setName('BUTTON.cancel')
      .chainActions(new Action().setActionType(EActionType.PREDEFINED).setId(EPredefinedAction.CANCEL_LIGHTBOX));
    this.discard = this.getButton()
      .setOrder(-998)
      .setName('BUTTON.discard')
      .chainActions(
        new Action().setActionType(EActionType.PREDEFINED).setId(EPredefinedAction.DISCARD_CHANGES),
        new Action().setActionType(EActionType.PREDEFINED).setId(EPredefinedAction.CLOSE_LIGHTBOX)
      );
    this.saveAll = this.getButton()
      .setPosition(EEntryElementPosition.RIGHT)
      .setOrder(999)
      .setName('BUTTON.save')
      .chainActions(
        new Action().setActionType(EActionType.PREDEFINED).setId(EPredefinedAction.SAVE),
        new Action().setActionType(EActionType.PREDEFINED).setId(EPredefinedAction.CLOSE_LIGHTBOX)
      );

    this.setButtons([this.saveAll, this.cancel, this.discard]);
  }

  public setCustomSaveAction(cb: () => Observable<void>): this {
    const customAction = new Action().setActionType(EActionType.LOCAL).setCb(cb);
    const closeLightboxAction = new Action()
      .setActionType(EActionType.PREDEFINED)
      .setId(EPredefinedAction.CLOSE_LIGHTBOX);
    this.saveAll.overwriteChain(customAction, closeLightboxAction);
    return this;
  }

  public setCustomDiscardAction(cb: () => Observable<void>): this {
    this.discard.chainActions(new Action().setActionType(EActionType.LOCAL).setCb(cb));
    return this;
  }

  public setCustomCancelAction(cb: () => Observable<void>): this {
    this.cancel.chainActions(new Action().setActionType(EActionType.LOCAL).setCb(cb));
    return this;
  }

  private getButton(): Button {
    return new Button().setDisplayType(EButtonDisplayType.ICON_AND_LABEL);
  }
}
