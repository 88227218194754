import { CommonModule } from '@angular/common';
import { OperatorSelectorComponent } from '@app-modeleditor/components/operator-selector/operator-selector.component';
import { NgModule } from '@angular/core';
import { MySharedModule } from 'frontend/src/dashboard/shared/shared.module';

@NgModule({
  imports: [CommonModule, MySharedModule],
  declarations: [OperatorSelectorComponent],
  exports: [OperatorSelectorComponent],
})
export class OperatorSelectorModule {}
