<ng-container *ngIf="isReady; else templateLoader">
  <div class="template-gantt" #ganttWrapper [class.active]="active">
    <div class="gantt-template mat-elevation-z2" [style.height]="rootHeight">
      <ng-container *ngIf="!ganttTemplateDataService.getTemplateData() || !isLoaded()">
        <ng-container *ngTemplateOutlet="templateLoader"></ng-container>
      </ng-container>

      <div class="sleep-mode" *ngIf="(visibilityStateService.isVisible$() | async) === false">
        <mat-icon>bedtime</mat-icon>
        <p>{{ '@inactive@' | translate }}...</p>
      </div>

      <div #toolbarContainer>
        <template-slot *ngIf="toolbar" (afterInit)="afterInitSlot($event)" [source]="toolbar"></template-slot>
      </div>
      <div
        [style.height]="containerHeight"
        (click)="onGanttClicked($event)"
        [class.combineView]="gridLayout && gridLayout.getGridStart() && gridLayout.getContentId() === contentId"
      >
        <saxms-best-gantt
          #ganttComponent
          *ngIf="dataStorage.ganttInputData && ganttTemplateDataService.getTemplateData()"
          [ganttData]="dataStorage.ganttInputData"
          [loadingSpinner]="loadingSpinner.show"
          [settingsData]="ganttSettingsService.getGanttSettings()"
          [disableToolbar]="ganttTemplateDataService.getTemplateData().getMenuMode() === 'HIDE'"
          [genericActions]="genericActions"
          [printView]="printHandler?.isActive"
          (ganttMenu)="receiveToolbar($event)"
          (refreshGantt)="refreshGantt($event)"
          (saveButtonRestriction)="(generalGanttSaveHandler?.buttonRestriction)"
          [disableCurrentZoomSpanSaveCallback]="
            ganttTemplateDataService.getTemplateData().isDisableCurrentZoomSpanSaveCallback()
          "
          (afterChangeToolbarVisibile)="handleMenumode($event)"
        >
        </saxms-best-gantt>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #templateLoader>
  <div class="template-loader">
    <mat-progress-bar [id]="ganttTemplateDataService.getTemplateData()?.getId() + '-loading-spinner'" mode="buffer">
    </mat-progress-bar>
    <div [style.text-align]="'center'">
      <span>{{ 'TEMPLATE.load_name' | translate : { name: getLoadingString() } }}</span>
    </div>
  </div>
</ng-template>
