import { IGanttGlobalShift } from '../../gantt-input.data';
import { GanttPredefinedSetting } from '../predefined-setting';

export class GanttMarkFreeDaysHandler extends GanttPredefinedSetting {
  public onInit(templateData: any, ganttResponseData: any): void {
    if (!ganttResponseData.hierarchicalPlan || !ganttResponseData.hierarchicalPlan.globalShifts) return;
    const globalShifts: IGanttGlobalShift[] = ganttResponseData.hierarchicalPlan.globalShifts;
    for (const globalShift of globalShifts) {
      this.ganttLibService.bestGantt
        .getGlobalTimeSpanMarker()
        .addInterval(
          new Date(globalShift.start),
          new Date(globalShift.end),
          globalShift.color,
          null,
          null,
          null,
          null,
          null,
          globalShift.index
        );
    }
  }
}
