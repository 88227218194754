import { Vertex, InterpolationVertex, EVertexType } from './vertex';
import { Resource } from 'frontend/src/dashboard/model/resource/resource';

export enum EPolygonModes {
  LINE = 'line',
  QUADRATIC = 'quadratic',
  BEZIER = 'bezier',
}

export enum EPolygonType {
  TRIANGLE = 'template_triangle',
  RECTANGLE = 'template_rectangle',
}
export class Polygon extends Resource {
  private style: any;
  private color = '#54f30370';
  private vertices: Vertex[] = [];
  private regionKey: string;
  private interpolationVertices: InterpolationVertex[] = [];
  private polygonType: EPolygonModes = EPolygonModes.LINE;
  private order: number; // zindex
  private editMode: boolean;
  private moved: boolean;
  private editEntryRestUrl: string;

  public setRegionKey(regionKey: string): this {
    this.regionKey = regionKey;
    return this;
  }

  public getRegionKey(): string {
    return this.regionKey;
  }

  public setColor(color: string): this {
    this.color = color;
    return this;
  }

  public getColor(): string {
    return this.color;
  }

  public setEditEntryRestUrl(editEntryRestUrl: string): this {
    this.editEntryRestUrl = editEntryRestUrl;
    return this;
  }

  public getEditEntryRestUrl(): string {
    return this.editEntryRestUrl;
  }
  public isMoved(): boolean {
    return this.moved ? true : false;
  }

  public setMoved(moved: boolean): this {
    this.moved = moved;
    return this;
  }

  public changeOriginVertexOfInterpolationPoitns(vert: Vertex) {
    if (vert.getType() === EVertexType.HELPERVERTEX) {
      return;
    }
    const interpolationVertex = this.getInterpolationPoints().find(
      (interpolation) => interpolation.getOriginVertex().getId() === vert.getId()
    );
    if (interpolationVertex) {
      interpolationVertex.setOriginVertex(vert);
    }
  }

  public toggleEdit(): this {
    this.editMode = !this.editMode;
    return this;
  }

  public getEditMode(): boolean {
    return this.editMode ? true : false;
  }

  public setEditMode(editMode: boolean): this {
    this.editMode = editMode;
    return this;
  }

  public getPolygonType(): EPolygonModes {
    return this.polygonType;
  }

  public setPolygonType(polygonType: EPolygonModes): this {
    this.polygonType = polygonType;
    return this;
  }

  public getOrder(): number {
    return this.order;
  }

  public setOrder(order: number): this {
    this.order = order;
    return this;
  }

  public getVertices(): Vertex[] {
    return (this.vertices || []).sort((a: Vertex, b: Vertex) => (a.getIndex() < b.getIndex() ? -1 : 1));
  }

  public setVertices(points: Vertex[]): this {
    this.vertices = points;
    return this;
  }

  public addVertices(...points: Vertex[]): this {
    this.vertices = this.getVertices().concat(points);
    return this;
  }

  public getInterpolationPoints(): InterpolationVertex[] {
    return (this.interpolationVertices || []).sort((a: Vertex, b: Vertex) => (a.getIndex() < b.getIndex() ? -1 : 1));
  }

  public setInterpolationPoints(interpolationPoints: InterpolationVertex[]): this {
    this.interpolationVertices = interpolationPoints;
    return this;
  }

  public addInterpolationPoints(...interpolationPoints: InterpolationVertex[]): this {
    this.interpolationVertices = this.getInterpolationPoints().concat(interpolationPoints);
    return this;
  }

  public getNeighborVertex(vertex: Vertex): { prevVertex: Vertex; nextVertex: Vertex } {
    const result = {
      prevVertex: null,
      nextVertex: null,
    };
    const currentIndex = vertex.getIndex();
    if (currentIndex === 0) {
      result.prevVertex = this.vertices[this.vertices.length - 1];
      result.nextVertex = this.vertices[vertex.getIndex() + 1];
    } else if (currentIndex === this.vertices.length - 1) {
      result.prevVertex = this.vertices[vertex.getIndex() - 1];
      result.nextVertex = this.vertices[0];
    } else {
      result.prevVertex = this.vertices[vertex.getIndex() - 1];
      result.nextVertex = this.vertices[vertex.getIndex() + 1];
    }
    return result;
  }

  public getWidth(): number {
    return (
      Math.max(...this.getVertices().map((p: Vertex) => p.getX())) -
      Math.min(...this.getVertices().map((p: Vertex) => p.getX()))
    );
  }

  public getHeight(): number {
    return (
      Math.max(...this.getVertices().map((p: Vertex) => p.getY())) -
      Math.min(...this.getVertices().map((p: Vertex) => p.getY()))
    );
  }

  public getVerticesAsString(el: HTMLElement): string {
    let result = '';
    this.getVertices().forEach((p: Vertex) => {
      const x: number = (el.clientWidth / 100) * p.getX();
      const y: number = (el.clientHeight / 100) * p.getY();
      result = `${result}${x},${y}\n`;
    });
    return result;
  }

  constructor() {
    super();
  }
}
