import { EGanttInstance, GanttDataRow } from '@gantt/public-api';
import { GanttLibService } from 'frontend/src/dashboard/gantt/gantt/gantt-lib.service';
import { GanttChildren, IGanttAttributeMapping } from 'frontend/src/dashboard/gantt/general/generator/gantt-input.data';
import { GanttEssentialPlugIns } from 'frontend/src/dashboard/gantt/general/plugin/e-gantt-essential-plugins';
import { GanttPluginHandlerService } from 'frontend/src/dashboard/gantt/general/plugin/gantt-plugin-handler.service';
import { GanttRestrictBlockDragPlugIn } from 'frontend/src/dashboard/gantt/general/plugin/plugin-list/edit-block/restrictions/restrict-block-drag';
import { IGanttResponse } from '../../../gantt-response';
import { GanttResponseStrategy } from './i-response-strategy';

/**
 * GanttResponseStrategy which updates single rows with blocks inside by backend data.
 */
export class GanttRowUpdateRefreshGanttEntryStrategy implements GanttResponseStrategy {
  /**
   * Replaces rows of gantt with new ones.
   * Can not add a new row or delete an old one.
   * @param response Serverresponse data.
   * @param toolbarHandler Toolbar handler from SaxMSBestGantt.
   * @param externalPlugInHandler Mediator for nearly all components inside generalized gantt.
   * @param attributeMapping Attribute Mapping from gantt template to give the possibility to update block tooltips.
   */
  public update(
    response: IGanttResponse,
    ganttLibService: GanttLibService,
    ganttPluginHandlerService: GanttPluginHandlerService,
    attributeMapping: IGanttAttributeMapping
  ): boolean {
    if (!response.ganttEntries || response.ganttEntries.length == 0) return false;

    const ganttDiagram = ganttLibService.bestGantt;
    for (const row of response.ganttEntries) {
      this.replaceRow(ganttLibService, row, attributeMapping, ganttPluginHandlerService);
    }

    const backendMapper = ganttLibService.backendToGanttOriginInputMapper;
    backendMapper.reinitaliseBlockClones(ganttDiagram.getDataHandler().getOriginDataset().ganttEntries);

    // update block restrictions
    const restrictBlockDragPlugIn: GanttRestrictBlockDragPlugIn = ganttPluginHandlerService.getEssentialPlugIn(
      GanttEssentialPlugIns.RestrictBlockDragPlugIn
    );
    restrictBlockDragPlugIn.addBlockDragRestrictionByGanttResponseData(response.ganttEntries);

    ganttDiagram.initCanvasData();

    return true;
  }

  /**
   * Finds and replace given row.
   * @param externalPlugInHandler Interface to get JS Factory.
   * @param responseRowData Backend row definition.
   * @param ganttDiagram BestGantt instace
   * @param attributeMapping Needed for tooltip creation.
   */
  private replaceRow(
    ganttLibService: GanttLibService,
    responseRowData: GanttChildren,
    attributeMapping: IGanttAttributeMapping,
    ganttPluginHandlerService: GanttPluginHandlerService
  ): boolean {
    if (!responseRowData) return;
    const backendMapper = ganttLibService.backendToGanttOriginInputMapper;
    const childEntry: GanttDataRow = backendMapper.childrenToInput(
      [responseRowData],
      attributeMapping,
      ganttPluginHandlerService.getTemplateData().getDefaultBlockTooltipSettings(),
      ganttPluginHandlerService.getTemplateData().getGanttEntryAttributeMappings()
    )[0];

    // update hierarchical plan
    ganttPluginHandlerService.getTemplateData().replaceEntryByIdInHierarchicalPlan(responseRowData);

    // update js gantt
    return !!ganttLibService.ganttInstanceService
      .getInstance(EGanttInstance.Y_AXIS_DATA_FINDER)
      .replaceRowByRowId(
        ganttLibService.bestGantt.getDataHandler().getOriginDataset().ganttEntries,
        childEntry.id,
        childEntry
      );
  }
}
