import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GanttSettingsComponent } from './gantt-settings/gantt-settings.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { TranslateModule } from '@ngx-translate/core';
import { BlocksComponent } from './gantt-settings/setting-control/parts/blocks/blocks.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ColorSketchModule } from 'ngx-color/sketch';
import { ColorPickerComponent } from './gantt-settings/setting-control/utils/color-picker/color-picker.component';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { RowsComponent } from './gantt-settings/setting-control/parts/rows/rows.component';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { DividingLinesComponent } from './gantt-settings/setting-control/parts/dividing-lines/dividing-lines.component';
import { SliderComponent } from './gantt-settings/setting-control/utils/slider/slider.component';
import { TooltipsComponent } from './gantt-settings/setting-control/parts/tooltips/tooltips.component';
import { DateAndTimeComponent } from './gantt-settings/setting-control/parts/date-and-time/date-and-time.component';
import { IndexCardsComponent } from './gantt-settings/setting-control/parts/index-cards/index-cards.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { TemplateDurationPickerModule } from '../template-duration-picker/template-duration-picker.module';
import { LabelsComponent } from './gantt-settings/setting-control/parts/labels/labels.component';
import { SettingControlComponent } from './gantt-settings/setting-control/setting-control.component';
import { SaxMsGanttModule } from 'frontend/src/dashboard/gantt/gantt/saxms-best-gantt.module';
import { PreviewGanttComponent } from './gantt-settings/preview-gantt/preview-gantt.component';
import { PreviewGanttService } from './gantt-settings/preview-gantt/preview-gantt.service';
import { TransferGanttSettingsComponent } from './transfer-gantt-settings/transfer-gantt-settings.component';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';

@NgModule({
  declarations: [
    GanttSettingsComponent,
    BlocksComponent,
    ColorPickerComponent,
    RowsComponent,
    DividingLinesComponent,
    SliderComponent,
    TooltipsComponent,
    DateAndTimeComponent,
    IndexCardsComponent,
    LabelsComponent,
    SettingControlComponent,
    PreviewGanttComponent,
    TransferGanttSettingsComponent,
  ],
  imports: [
    CommonModule,
    MatExpansionModule,
    MatDividerModule,
    MatListModule,
    MatSlideToggleModule,
    MatSliderModule,
    FormsModule,
    MatButtonModule,
    MatMenuModule,
    TranslateModule,
    MatIconModule,
    ColorSketchModule,
    MatSelectModule,
    MatDatepickerModule,
    MatInputModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    TemplateDurationPickerModule,
    SaxMsGanttModule,
    MatProgressSpinnerModule,
  ],
  exports: [GanttSettingsComponent, TransferGanttSettingsComponent],
  providers: [PreviewGanttService],
})
export class GanttSettingsModule {}
