import { GanttPatternData } from '../../patternHandler';
import { Pattern } from '../pattern.base';

/**
 * Class which handles the generation of a houndstooth pattern.
 */
export class HoundstoothPattern extends Pattern {
  /**
   * Generates an SVG image containing the pattern and returns it as base64-encoded URL.
   * @param backgroundColor Background color of the pattern.
   * @param patternColor Color of the pattern.
   * @param strokeColor Stroke color of the pattern.
   * @returns SVG image containing the pattern as base64-encoded URL.
   */
  protected _getPatternDataString(backgroundColor: string, patternColor: string, strokeColor: string): string {
    const pattern = this._patternBuilder.getSvgElement(this._patternHeight, this._patternWidth);
    this._patternBuilder.appendBackground(pattern, backgroundColor);
    this._patternBuilder.appendPath(pattern, 'M0 0L4 4', patternColor, 1, null, null, strokeColor);
    this._patternBuilder.appendPath(
      pattern,
      'M2.5 0L5 2.5L5 5L9 9L5 5L10 5L10 0',
      patternColor,
      1,
      null,
      null,
      strokeColor
    );
    this._patternBuilder.appendPath(pattern, 'M5 10L5 7.5L7.5 10', patternColor, 1, null, null, strokeColor);
    return this._patternBuilder.convertSvgNodeIntoDataString(pattern);
  }

  /**
   * Generates an SVG image containing the pattern and returns it as {@link GanttPatternData} object.
   * @param backgroundColor Background color of the pattern.
   * @param patternColor Color of the pattern.
   * @param strokeColor Stroke color of the pattern.
   * @returns SVG image containing the pattern as {@link GanttPatternData} object.
   */
  public getPatternData(backgroundColor: string, patternColor: string, strokeColor: string): GanttPatternData {
    return new GanttPatternData(
      this._getPatternDataString(backgroundColor, patternColor, strokeColor),
      this._patternHeight,
      this._patternWidth
    );
  }
}
