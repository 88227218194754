import { Notification } from './notification.store';

export namespace NotificationActions {
  export class Create {
    static readonly type = '[NotificationActions] Create';
    constructor(public m: Notification) {}
  }

  export class Close {
    static readonly type = '[NotificationActions] Close';
    constructor(public id: string) {}
  }
}
