import { EPatternEventSource } from './pattern-event-source.enum';

/**
 * Event class containing all information about a pattern image event.
 */
export class PatternImageEvent {
  constructor(
    /**
     * Id of the pattern this event affects.
     */
    public readonly patternId: string,
    /**
     * Value representing the method which caused this event to be thrown.
     */
    public readonly source: EPatternEventSource,
    /**
     * {@link HTMLImageElement} containing the affected image as the value of the src attribute.
     */
    public readonly image: HTMLImageElement
  ) {}
}
