import { ChartLegendTemplate } from './chart-legend-template';
import { ETemplateType } from 'frontend/src/dashboard/model/resource/template-type';
import { EChartLegendType } from '../chart-legend-type.enum';
import { ChartLegendItem } from '../chart-legend-item';

export class ChartAttributeLegend extends ChartLegendTemplate {
  private legendItemPlainList: ChartLegendItem[];

  constructor() {
    super();
    this.setType(ETemplateType.CHART_LEGEND_ATTRIBUTE);
    this.setChartLegendType(EChartLegendType.ATTRIBUTE);
  }

  public setLegendItemPlainList(legendItemPlainList: ChartLegendItem[]): ChartAttributeLegend {
    this.legendItemPlainList = legendItemPlainList;
    return this;
  }
  public getLegendItemPlainList(): ChartLegendItem[] {
    return this.legendItemPlainList || [];
  }
}
