import { inject, Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@core/authentication/auth.service';
import { Credentials } from '@core/authentication/credentials';
import { Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';
import { throwError } from 'rxjs';
import { catchError, finalize, map, switchMap, take, tap } from 'rxjs/operators';
import { User } from '../../user/data-access/user';
import { UserService } from '../../user/data-access/user.service';
import { EMessageType } from '../../view/window/message-type.enum';
import { WindowService } from '../../view/window/window.service';
import { AuthActions } from './auth.actions';

export interface AuthCredentials {
  accessToken: string;
  refreshToken: string;
  clientId: string;
  passwordExpired: boolean;
}

export interface AuthStateModel {
  token: AuthCredentials;
  user: User;
  processing: number;
}

@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    token: null,
    user: null,
    processing: 0,
  },
})
@Injectable()
export class AuthState implements NgxsOnInit {
  ngxsOnInit(ctx: StateContext<any>): void {}

  private readonly authService: AuthenticationService = inject(AuthenticationService);
  private readonly userService: UserService = inject(UserService);
  private readonly router: Router = inject(Router);
  private readonly injector: Injector = inject(Injector);

  @Selector()
  static token(state: AuthStateModel): string {
    return state.token?.accessToken;
  }

  @Selector()
  static isExpired(state: AuthStateModel): boolean {
    return state.token?.passwordExpired ? true : false;
  }

  @Selector()
  static inProgress(state: AuthStateModel): boolean {
    return state.processing > 0 ? true : false;
  }

  @Selector()
  static user(state: AuthStateModel): User {
    return state.user;
  }

  @Action(AuthActions.SignleSignOn)
  singleSignOn(ctx: StateContext<AuthStateModel>, action: AuthActions.SignleSignOn) {
    const cred = action.payload;
    this.authService.singleSignOn(cred).subscribe((data) => {
      const result = this.authService.parseCredentials(data);
      ctx.patchState({
        token: {
          accessToken: result.getAccessToken(),
          refreshToken: result.getRefreshToken(),
          clientId: result.getUserId(),
          passwordExpired: result.isPasswordExpired(),
        },
      });
      this.authService.setCredentials(result).subscribe(() => this.router.navigate(['/', 'guard', 'app']));
    });
  }

  private updateProgress(ctx: StateContext<AuthStateModel>, dec?: boolean) {
    const state = ctx.getState();
    ctx.patchState({
      processing: state.processing + (dec ? -1 : 1),
    });
  }

  @Action(AuthActions.Login)
  login(ctx: StateContext<AuthStateModel>, action: AuthActions.Login) {
    const { username, password, rememberMe } = action.payload;
    this.updateProgress(ctx);
    return this.authService.signIn(username, password).pipe(
      finalize(() => this.updateProgress(ctx, true)),
      map((result: Credentials) => {
        ctx.patchState({
          token: {
            accessToken: result.getAccessToken(),
            refreshToken: result.getRefreshToken(),
            clientId: result.getUserId(),
            passwordExpired: result.isPasswordExpired(),
          },
        });
        return result;
      }),
      switchMap((result) => this.authService.setCredentials(result))
    );
  }

  @Action(AuthActions.OverrideCredentials)
  overrideCredentials(ctx: StateContext<AuthStateModel>, action: AuthActions.OverrideCredentials) {
    const c = action.payload;
    ctx.patchState({
      token: {
        accessToken: c.getAccessToken(),
        refreshToken: c.getRefreshToken(),
        clientId: c.getUserId(),
        passwordExpired: c.isPasswordExpired(),
      },
    });

    return this.authService.overrideCredentials(c);
  }

  @Action(AuthActions.Refresh)
  refresh(ctx: StateContext<AuthStateModel>, action: AuthActions.Refresh) {
    const state = ctx.getState();
    return this.authService.refreshToken(state.token?.refreshToken).pipe(
      catchError((e) => throwError(() => new Error('refresh failed'))),
      tap((result: Credentials) => {
        ctx.patchState({
          token: {
            accessToken: result.getAccessToken(),
            refreshToken: result.getRefreshToken(),
            clientId: result.getUserId(),
            passwordExpired: result.isPasswordExpired(),
          },
        });
      }),
      switchMap((result) => this.authService.setCredentials(result))
    );
  }

  @Action(AuthActions.Logout)
  logout(ctx: StateContext<AuthStateModel>, action: AuthActions.Logout) {
    const state = ctx.getState().token;
    const windowService: WindowService = this.injector.get(WindowService);
    ctx.patchState({
      token: null,
      user: null,
    });

    this.authService
      .logout()
      .pipe(
        tap(() =>
          windowService.sendMessage({
            type: EMessageType.CLOSE_ALL_WINDOWS,
          })
        )
      )
      .subscribe(() => {
        if (state) {
          location.reload();
        }
      });
  }

  @Action(AuthActions.GetUser)
  getUser(ctx: StateContext<AuthStateModel>) {
    return this.userService.fetchUser().pipe(
      take(1),
      tap((user) => {
        ctx.patchState({
          user,
        });
      }),
      tap(() => {
        const state = ctx.getState();
        const cred = this.authService.parseCredentials({
          access_token: state.token.accessToken,
          refresh_token: state.token.refreshToken,
          client_id: state.token.clientId,
          passwordExpired: state.token.passwordExpired,
        });
        this.authService.setCredentials(cred).subscribe();
      })
    );
  }
}
