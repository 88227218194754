import * as d3 from 'd3';
import { GanttCallBackStackExecuter } from '../callback-tools/callback-stack-executer';
import { GanttColorCalculator } from '../color/color-calculator/color-calculator';
import { TextOverlayShadowMode } from '../config/config-data/text-overlay-config.data';
import { GanttConfig } from '../config/gantt-config';
import { DataHandler } from '../data-handler/data-handler';
import { GanttCanvasShift } from '../data-handler/data-structure/data-structure';
import { EGanttTextStrategy } from '../data-handler/data-structure/data-structure-enums';
import { GanttFontSizeCalculator } from '../font-tools/font-size';
import { NodeProportionsState } from '../html-structure/node-proportion-state/node-proportion-state';
import { EGanttScrollContainer } from '../html-structure/scroll-container.enum';
import { BestGantt } from '../main';
import { GanttSplitOverlappingShifts } from '../plug-ins/split-overlapping-shifts/split-overlapping-shifts-executer';
import { TextOverlayInside } from './text-overlay-strategies/text-overlay-inside';
import { TextOverlayInsideMultipleLines } from './text-overlay-strategies/text-overlay-inside-multiple-lines';
import { IGanttShiftTextOverlayBuilding } from './text-overlay-strategies/text-overlay-interface';
import { TextOverlayNoText } from './text-overlay-strategies/text-overlay-no-text';
import { TextOverlayOutside } from './text-overlay-strategies/text-overlay-outside';
import { TextOverlayOutsideBG } from './text-overlay-strategies/text-overlay-outside-bg';
import { TextOverlayOutsideSplit } from './text-overlay-strategies/text-overlay-outside-split';

/**
 * Class which builds text on top of shift rects.
 * @keywords text, builder, executer, shift, overlay, name
 * @class
 * @constructor
 * @param {Selection} parentDIVNode DIV Node for text overlays
 * @param {GanttConfig} config Gantt config.
 */
export class TextOverlay {
  parentNode: d3.Selection<any, any, null, undefined>;
  canvas: d3.Selection<HTMLDivElement, GanttCanvasShift, null, undefined>;
  nodeProportionState: NodeProportionsState;
  dataHandler: DataHandler;
  ganttDiagram: BestGantt;
  callback: {
    afterTextStrategieChanged: { [id: string]: (strategy: EGanttTextStrategy) => void };
    onManipulateTextDataStream: { [id: string]: (dataRef: { dataset: GanttCanvasShift[] }) => void };
    afterManipulateTextDataStream: { [id: string]: (dataRef: { dataset: GanttCanvasShift[] }) => void };
    afterSplitOverlappingShiftsConnection: {
      [id: string]: (overlappingShiftsPlugIn: GanttSplitOverlappingShifts) => void;
    };
  };
  mergedRenderDataSet: any[];
  config: GanttConfig;
  textOverlayRenderStrategie: IGanttShiftTextOverlayBuilding;
  private activeType: EGanttTextStrategy;
  colorContrastCache: Map<string, number>;
  fontSizeCalculator: GanttFontSizeCalculator;
  splitOverlappingShiftsPlugin: GanttSplitOverlappingShifts;

  constructor(
    parentDIVNode,
    config,
    nodeProportionState,
    dataHandler,
    ganttDiagram,
    private readonly _scrollContainerId = EGanttScrollContainer.DEFAULT
  ) {
    this.parentNode = parentDIVNode;
    this.canvas = null;
    this.nodeProportionState = nodeProportionState;
    this.dataHandler = dataHandler;
    this.ganttDiagram = ganttDiagram;

    this.callback = {
      afterTextStrategieChanged: {},
      onManipulateTextDataStream: {},
      afterManipulateTextDataStream: {},
      afterSplitOverlappingShiftsConnection: {},
    };

    this.mergedRenderDataSet = [];
    this.config = config;
    this.textOverlayRenderStrategie = new TextOverlayInside();
    this.activeType = EGanttTextStrategy.CUT_OFF_LABELS;
    this.colorContrastCache = new Map();

    this.fontSizeCalculator = new GanttFontSizeCalculator();

    this.splitOverlappingShiftsPlugin = null;
  }

  init() {
    const s = this;

    s.canvas = s.parentNode.append('div').attr('class', 'gantt-text-overlay');

    s.initFontSizeCalculator(s.config.getShiftFontSize());
    s.config.addAfterShiftFontSizeChangedCallback(`updateFontSizeCalc`, (fontSize) => {
      s.initFontSizeCalculator(fontSize);
    });
  }

  initFontSizeCalculator(fontSize) {
    const s = this;

    s.fontSizeCalculator.deleteData();
    const initSVG = s.parentNode.append<SVGSVGElement>('svg');
    s.fontSizeCalculator.init(initSVG.node(), fontSize);
    s.fontSizeCalculator.removeInitNode();
    initSVG.remove();
  }

  /**
   * Builds text overlay by shift data.
   * @keywords build, add, render, textoverlay, text, overlay, shift
   * @param {GanttCanvasShift[]} shiftDataset Canvas shifts which contain text data.
   * @param {*} zoomTransformation Data wrapper which contains transformation data.
   */
  build(shiftDataset, zoomTransformation) {
    const s = this;

    s.render(shiftDataset, zoomTransformation);
  }

  /**
   * Removes all text elements.
   * @keywords remove, clear, delete, text, overlay
   */
  removeAllText() {
    const s = this;
    s.canvas.selectAll('div').remove();
  }

  /**
   * Builds and updates text overlay by Update-Enter-Exit pattern.
   * If shift width is too small, text overlay will not be rendered.
   * @keywords update, enter, exit, render, build, text, overlay, zoom, scale, horizontal
   * @param {GanttCanvasShift[]} shiftDataset Canvas shifts which contain text data.
   * @param {Transform} zoomTransformation D3 transform which includes x, y and k value.
   */
  public render(shiftDataset: GanttCanvasShift[], zoomTransformation: d3.ZoomTransform): void {
    const shiftDataSetPointer = { dataset: shiftDataset };
    GanttCallBackStackExecuter.execute(this.callback.onManipulateTextDataStream, shiftDataSetPointer);
    GanttCallBackStackExecuter.execute(this.callback.afterManipulateTextDataStream, shiftDataSetPointer);
    this.textOverlayRenderStrategie.render(shiftDataSetPointer.dataset, zoomTransformation, this);
  }

  /**
   * Removes all text and renders all text new by data.
   * @keywords render, rerender, rebuild, update, refresh, text, overlay
   * @param {GanttCanvasShift[]} shiftDataset Canvas shifts which text overlay should be rerendered.
   * @param {Transform} zoomTransformation D3 transform which includes x, y and k value.
   */
  reRender(shiftDataset, zoomTransformation) {
    const s = this;

    s.removeAllText();
    s.render(shiftDataset, zoomTransformation);
  }

  /**
   * Retrieves the text overlay shadow CSS value based on the provided canvas shift data.
   * @param canvasShiftData - The canvas shift data.
   * @returns The text overlay shadow CSS value, or null if no shadow is needed.
   */
  public getTextOverlayShadow(canvasShiftData: GanttCanvasShift): string | null {
    // return null if not relevant
    if (
      !this.config.isTextOverlayShadowEnabled() ||
      this.config.textOverlayShadowMode() === TextOverlayShadowMode.NONE
    ) {
      return null;
    }

    // check contrast ratio
    const colorContrast = GanttColorCalculator.getColorContrastHex(
      this.config.getShiftTextColor(),
      canvasShiftData.color.substring(0, 7)
    );

    // check if a stroke pattern is displayed
    const hasShiftPatternStroke =
      canvasShiftData.pattern && canvasShiftData.patternColor
        ? this.ganttDiagram
            .getShiftFacade()
            .getPatternHandler()
            .getPatternBuilder()
            .isStrokeColorNecessary(canvasShiftData.patternColor, canvasShiftData.color)
        : false;

    // if the color contrast is too low or a stroke pattern is displayed, a shadow is needed
    if (colorContrast < 3 || hasShiftPatternStroke) {
      const shadowOffset = this.config.textOverlayShadowOffset();
      const color = GanttColorCalculator.isDarkColor(this.config.getShiftTextColor())
        ? this.config.textOverlayTextBrightShadowColor()
        : this.config.textOverlayTextDarkShadowColor();
      this.colorContrastCache.set(canvasShiftData.id, colorContrast);

      // return the shadow value based on the shadow mode
      switch (this.config.textOverlayShadowMode()) {
        case TextOverlayShadowMode.CONTOUR:
          return `-${shadowOffset}px -${shadowOffset}px 0 ${color}, ${shadowOffset}px -${shadowOffset}px 0 ${color}, -${shadowOffset}px ${shadowOffset}px 0 ${color}, ${shadowOffset}px ${shadowOffset}px 0 ${color}`;
        case TextOverlayShadowMode.DROP_SHADOW:
        default:
          return `${shadowOffset}px ${shadowOffset}px ${this.config.textOverlayShadowBlur()}px ${color}`;
      }
    }
    return null;
  }

  /**
   * Find specific text element in field of view by id.
   * @keywords text, overlay, selction
   * @param {string} id Shift id to detect text element.
   * @return Found text element.
   */
  getTextElementById(id) {
    const s = this;
    let foundTextElement = null;

    s.canvas.selectAll('div').each(function (d: any) {
      if (id == d.id) {
        foundTextElement = d3.select(this);
      }
    });

    return foundTextElement;
  }

  /**
   * Update text overlay of specific shift. Needed for Resizing shifts.
   * @keywords build, render, text, one, shift, specific, update, rebuild
   * @param shiftDataSet The current CanvasData.
   * @param shiftData Canvas Data of shift acting on.
   * @param textElement The TextElement.
   * @param zoomTransformation D3 transform which includes x, y and k value.
   */
  public buildSmallerText(
    shiftDataSet: GanttCanvasShift[],
    shiftData: GanttCanvasShift,
    textElement: d3.Selection<HTMLDivElement, GanttCanvasShift, d3.BaseType, undefined>,
    zoomTransformation: d3.ZoomTransform
  ): void {
    this.textOverlayRenderStrategie.buildSmallerText(shiftDataSet, shiftData, textElement, zoomTransformation, this);
  }

  /**
   * Removes all text nodes from canvas.
   * @keywords remove, delete, clear, empty ext, overlay
   */
  removeAll() {
    const s = this;
    s.canvas.remove();
  }

  /** Calculates and return a visual neighbor to a shift, needed for calulating width if text div
   * @param {GanttShift[]} shiftDataset ShiftCanvas Data mixed with BlockingInterval Canvas Data
   * @param {number} index Number to start looking from for a neighbor that sets limit to text box with.
   * @param {GanttShift} d Shift that is looking for its right neighbor to determine text box width.
   * @param {GanttConfig} config GanttConfig.
   */
  findNeighbor(shiftDataset: GanttCanvasShift[], index: number, d: GanttCanvasShift, config: GanttConfig) {
    const isSameLine = (object1: GanttCanvasShift, object2: GanttCanvasShift) =>
      object1.y === object2.y ||
      object1.y + config.getLineTop() === object2.y ||
      object1.y - config.getLineTop() === object2.y;
    const isSameStart = (object1: GanttCanvasShift, object2: GanttCanvasShift) => object1.x === object2.x;
    const isSameEnd = (object1: GanttCanvasShift, object2: GanttCanvasShift) =>
      object1.x + object1.width === object2.x + object2.width;

    let next: GanttCanvasShift | undefined;
    let nextNotSameStart: GanttCanvasShift | undefined;
    for (let i = 1; i < 20; i++) {
      const check = shiftDataset[index + i];
      if (check) {
        if (isSameLine(check, d)) {
          if (!next) next = check;
          if (!nextNotSameStart && !isSameStart(check, d)) nextNotSameStart = check;
          if (next && nextNotSameStart) break;
        }
      } else {
        break;
      }
    }

    let prev: GanttCanvasShift | undefined;
    let prevNotSameEnd: GanttCanvasShift | undefined;
    for (let i = 1; i < 20; i++) {
      const check = shiftDataset[index - i];
      if (check) {
        if (isSameLine(check, d)) {
          if (!prev) prev = check;
          if (!prevNotSameEnd && !isSameEnd(check, d)) prevNotSameEnd = check;
          if (prev && prevNotSameEnd) break;
        }
      } else {
        break;
      }
    }

    return { next, nextNotSameStart, prev, prevNotSameEnd };
  }

  /**
   * Finds the next neighbor with higher or same priority in the same line which is NOT at the same x position.
   * @returns distance to next neighbor or undefined if no neighbor is found.
   */
  findNextNeighbor(shiftDataset: GanttCanvasShift[], d: GanttCanvasShift, config: GanttConfig): number | undefined {
    const isSameLine = (object1: GanttCanvasShift, object2: GanttCanvasShift) =>
      object1.y === object2.y ||
      object1.y + config.getLineTop() === object2.y ||
      object1.y - config.getLineTop() === object2.y;
    const sameLineData = shiftDataset.filter((d2) => isSameLine(d, d2));
    const nextShift = sameLineData.find((d2) => d2.x > d.x && !this.isOverlapping(d, d2));
    return nextShift?.x ? nextShift.x - d.x : undefined;
  }

  /**
   * @returns true if there is an ancestor element with higher priority that overlaps the passed element.
   */
  isElemOverlapping(
    shiftDataset: GanttCanvasShift[],
    d: GanttCanvasShift,
    config: GanttConfig,
    type: 'prev' | 'next'
  ): boolean {
    const yWithLineTop = d.y + config.getLineTop();
    const yWithoutLineTop = d.y - config.getLineTop();

    const isSameLine = (object1: GanttCanvasShift, object2: GanttCanvasShift) =>
      object1.y === object2.y || yWithLineTop === object2.y || yWithoutLineTop === object2.y;

    const overlaps = shiftDataset.find((d2) => {
      const d1Start = d.x;
      const d2Start = d2.x;
      return (
        isSameLine(d, d2) && // same line
        this.isOverlapping(d, d2) && // overlap horizontally
        (type === 'prev' ? d2Start < d1Start : d2Start > d1Start) && // d2 starts before d1
        (type === 'prev' ? d.renderPriority < d2.renderPriority : d.renderPriority > d2.renderPriority) // d1 has lower priority
      );
    });
    return !!overlaps;
  }

  public isOverlapping(d: GanttCanvasShift, d2: GanttCanvasShift): boolean {
    const d1Start = d.x;
    const d1End = d.x + d.width;
    const d2Start = d2.x;
    const d2End = d2.x + d2.width;
    return d1Start < d2End && d1End > d2Start;
  }

  //
  // GETTER & SETTER
  //

  /**
   * Helper getter which returns the id of the scroll container this {@link TextOverlay} is assigned to.
   */
  public get scrollContainerId(): EGanttScrollContainer {
    return this._scrollContainerId;
  }

  getCanvas() {
    return this.canvas;
  }

  getParentNode() {
    return this.parentNode;
  }

  getTextOverlayStrategy() {
    return this.textOverlayRenderStrategie;
  }
  /**
   * Changes the strategie for shift Name drawing.
   * @param {IGanttShiftTextOverlayBuilding} strategie New concrete strategie to draw TextOverlay on shifts.
   */
  changeTextOverlayStrategy(strategy: EGanttTextStrategy) {
    const s = this;
    s.textOverlayRenderStrategie.cleanUp(s);
    this.activeType = strategy;
    s.textOverlayRenderStrategie = this.createTextStrategyInstance(strategy);
    s.textOverlayRenderStrategie.init(s);

    GanttCallBackStackExecuter.execute(this.callback.afterTextStrategieChanged, strategy);
  }

  private createTextStrategyInstance(strategy: EGanttTextStrategy): IGanttShiftTextOverlayBuilding {
    switch (strategy) {
      case EGanttTextStrategy.HIDE_OVERLAPPING_LABELS:
      case EGanttTextStrategy.CUT_OFF_LABELS:
        return new TextOverlayInside();
      case EGanttTextStrategy.CUT_OFF_LABELS_MULTIPLE_LINES:
        return new TextOverlayInsideMultipleLines();
      case EGanttTextStrategy.SHOW_ALWAYS_LABELS:
        return new TextOverlayOutside();
      case EGanttTextStrategy.SHOW_NEVER_LABELS:
        return new TextOverlayNoText();
      case EGanttTextStrategy.SHOW_ALWAYS_LABELS_SPLIT:
        return new TextOverlayOutsideSplit();
      case EGanttTextStrategy.SHOW_ALWAYS_LABELS_BG:
        return new TextOverlayOutsideBG();
    }
  }

  addAfterTextStrategieChanged(id: string, func: (strategy: EGanttTextStrategy) => void) {
    this.callback.afterTextStrategieChanged[id] = func;
    func(this.activeType); // send data on subscription
  }
  removeAfterTextStrategieChanged(id) {
    delete this.callback.afterTextStrategieChanged[id];
  }

  public subscribeOnTextDataManipulation(id: string, func: (dataRef: { dataset: GanttCanvasShift[] }) => void) {
    this.callback.onManipulateTextDataStream[id] = func;
  }
  public unsubscribeOnTextDataManipulation(id: string) {
    delete this.callback.onManipulateTextDataStream[id];
  }

  public subscribeAfterTextDataManipulation(id: string, func: (dataRef: { dataset: GanttCanvasShift[] }) => void) {
    this.callback.afterManipulateTextDataStream[id] = func;
  }
  public unsubscribeAfterTextDataManipulation(id: string) {
    delete this.callback.afterManipulateTextDataStream[id];
  }

  /**
   * @deprecated 08.05.2020 SVG to DIV Text Rendering
   * Returns matching substring for text. Such that SubString truncates before given x Postion.
   * Used by text overlay strategies.
   * @param {String} text The String that should match to the xPosWrap.
   * @param {number} xPosWrap xPostion text has to wrap before.
   * @param {Transform} zoom d3 zoom transformation.
   * @param {boolean} behindYAxis shiftStart behind y Axis.
   * @returns {String} Substring of text.
   */
  getTruncatedText(text, xPosWrap, zoom, behindYAxis) {
    const s = this;
    console.warn('using deprecated method');
    const amount = text.length;
    const maxTextWidth = xPosWrap * zoom.k + (behindYAxis ? zoom.x : 0);
    let textWidth = s.fontSizeCalculator.getTextWidth(text);

    if (maxTextWidth > textWidth) return text;
    if (maxTextWidth < 5 || textWidth < 5) return '';

    let i = 0;
    textWidth = 0;
    while (textWidth < maxTextWidth) {
      if (i === amount) {
        i = amount;
        break;
      }
      i++;
      textWidth = s.fontSizeCalculator.getTextWidth(text.slice(0, i));
    }
    i--;
    return i < 2 ? '' : text.slice(0, i);
  }

  getFontSizeCalculator() {
    const s = this;
    return s.fontSizeCalculator;
  }

  getActiveStrategyType(): EGanttTextStrategy {
    return this.activeType;
  }

  public addSplittingShiftsPlugin(splitOverlappingShiftsPlugin: GanttSplitOverlappingShifts): void {
    this.splitOverlappingShiftsPlugin = splitOverlappingShiftsPlugin;
    GanttCallBackStackExecuter.execute(
      this.callback.afterSplitOverlappingShiftsConnection,
      splitOverlappingShiftsPlugin
    );
  }

  subscribeAfterSplitOverlappingShiftsConnection(id, callback) {
    this.callback.afterSplitOverlappingShiftsConnection[id] = callback;
  }
  unSubscribeAfterSplitOverlappingShiftsConnection(id) {
    delete this.callback.afterSplitOverlappingShiftsConnection[id];
  }
}
