import { Component, Input } from '@angular/core';
import { EntryCollection } from '../entry-collection';
import { EntryElement } from '../entry-element';
import { EntryElementValue } from '../entry-element-value';
import { EEntryCollectionEvents } from './entry-collection-events.enum';

@Component({
  selector: 'template-entry-element',
  templateUrl: './entry-element.component.html',
  styleUrls: ['./entry-element.component.scss'],
})
export class EntryElementComponent {
  @Input() entryCollection: EntryCollection;
  @Input() entryElement: EntryElement;
  @Input() resourceId: string;
  @Input() disabled: boolean;
  @Input() editmode: boolean;
  @Input() highlightedKey: string;
  @Input() layoutElement;

  private getIndexOfEntryElement(el: EntryElement): number {
    return this.entryCollection.getEntryElements().findIndex((e: EntryElement) => e.getId() === el.getId());
  }

  onChanges(event): void {
    if (this.entryCollection.getEntryElementTemplates().length > 0) {
      const match: EntryElement = this.entryCollection.getEntryElementTemplates().find((entryElement: EntryElement) => {
        if (
          !(entryElement.getValue() instanceof EntryElementValue) ||
          !Array.isArray(entryElement.getValue<EntryElementValue>().getValue())
        ) {
          return false;
        }
        return entryElement
          .getValue<EntryElementValue>()
          .getValue<EntryElementValue[]>()
          .find((val: EntryElementValue) => {
            if (val.getUuid() !== event.event.getValue().getUuid()) {
              return false;
            }
            val.setValue(event.event.getValue().getValue());

            return true;
          })
          ? true
          : false;
      });
      // only if element was found, set event
      if (match) {
        event.event = match;
      }
    }

    const eventList = this.entryCollection.getEventListener()[EEntryCollectionEvents.VALUE_CHANGED];
    Object.keys(eventList || {}).forEach((key: string) => {
      eventList[key](new CustomEvent(EEntryCollectionEvents.VALUE_CHANGED, { detail: event }));
    });
    // this.valueChange.emit(event);
  }

  onClick(event: MouseEvent, el: EntryElement): void {
    if (!el.isSelectable()) {
      return;
    }
    event.preventDefault();
    event.stopPropagation();
    if (event.shiftKey && this.entryCollection.getLastSelectedIndex() !== null) {
      const curIdx: number = this.getIndexOfEntryElement(el);
      const start: number = Math.min(curIdx, this.entryCollection.getLastSelectedIndex());
      const end: number = Math.max(curIdx, this.entryCollection.getLastSelectedIndex());
      this.entryCollection.getEntryElements().forEach((e: EntryElement) => e.setSelected(false));
      for (let i = start; i <= end; i++) {
        this.entryCollection.getEntryElements()[i].setSelected(true);
      }
    } else if (event.ctrlKey === true) {
      el.setSelected(!el.isSelected());
    } else {
      this.entryCollection
        .getEntryElements()
        .forEach((e: EntryElement) => e.setSelected(el.getId() === e.getId() ? !el.isSelected() : false));
      this.entryCollection.setLastSelectedIndex(this.getIndexOfEntryElement(el));
    }

    if (this.entryCollection.selectedEntries.length === 0) {
      this.entryCollection.setLastSelectedIndex(null);
    }
  }

  isDisabled(el): boolean {
    if (el instanceof EntryElement) {
      return this.disabled || !this.editmode || el.isDisabled();
    }

    return !el.alwaysEnabled && (this.disabled || !this.editmode || el.editable === false);
  }

  /**
   * decides if entryElement is visible or not
   * @param el entryElement
   * @returns boolean
   */
  getVisibility(el): boolean {
    if (!el) {
      return false;
    }

    if (el instanceof EntryElement) {
      return el.isShow();
    }

    return typeof el.show !== 'boolean' || el.show === true;
  }
}
