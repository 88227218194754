import { HierarchicalMenuItem } from '@app-modeleditor/components/template-ui/hierarchical-menu-item';
import { Injectable, OnDestroy } from '@angular/core';
import { Page } from '@app-modeleditor/components/page/page';
import { ETemplateType } from 'frontend/src/dashboard/model/resource/template-type';
import { User } from 'frontend/src/dashboard/user/user';
import { UserService } from 'frontend/src/dashboard/user/data-access/user.service';
import { Tenant } from 'frontend/src/dashboard/view/template-footer/default-footer-components/tenant-footer/tenant';
import { UserTenantService } from 'frontend/src/dashboard/view/template-footer/default-footer-components/tenant-footer/tenant.service';
import { ViewService } from 'frontend/src/dashboard/view/view.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil, takeWhile } from 'rxjs/operators';
import { EColumnMode } from '../column-mode.enum';
import { Menu } from '../menu';
import { HMI } from '../tree-item';
import { TemplateTreeService } from './../tree.service';
import { IRecentlyVisitedNode } from './last-visited-content.component';
import { ConfigService } from '@core/config/config.service';

@Injectable({
  providedIn: 'root',
})
export class LastVisitedContentService implements OnDestroy {
  private $alive = true;
  private recentlyVisited: BehaviorSubject<IRecentlyVisitedNode[]> = new BehaviorSubject<IRecentlyVisitedNode[]>([]);
  private $lastVisited: IRecentlyVisitedNode[] = [];
  private $user: User;
  private $tenants: Tenant[];
  $mainTenant: Tenant;
  private openVisited: Subject<IRecentlyVisitedNode> = new Subject<IRecentlyVisitedNode>();
  private currentContent: {
    page: Page;
    url: string;
    intiial: boolean;
  };

  private blockSaveInHistory = false;

  constructor(
    private $tenantApi: UserTenantService,
    private $userApi: UserService,
    private $viewApi: ViewService,
    private $templateTreeApi: TemplateTreeService,
    private configApi: ConfigService
  ) {
    this.$viewApi
      .getPageContent()
      .pipe(takeWhile(() => this.$alive))
      .subscribe((c) => {
        this.currentContent = c;
      });

    this.$tenantApi
      .selectedTenantChanged()
      .pipe(takeWhile(() => this.$alive))
      .subscribe(() => this.$update());
    this.$tenantApi
      .getAllTenants()
      .pipe(takeWhile(() => this.$alive))
      .subscribe((tenants: Tenant[]) => {
        this.$tenants = tenants;
        this.$update();
      });

    this.$userApi
      .getUser()
      .pipe(takeWhile(() => this.$alive))
      .subscribe((user: User) => {
        this.$user = user;
        this.$mainTenant = user?.getTenant();
        this.$update();
      });
  }

  setRecentlyVisitedNodesFromSettings(nodes: IRecentlyVisitedNode[]): void {
    this.$lastVisited = nodes ? nodes.slice(0, this.configApi.access().templates.Tree.historyLimit) : [];
    this.$update();
  }
  openRecentlyVisitedNode(node: IRecentlyVisitedNode): void {
    this.openVisited.next(node);
  }
  setBlockSaveInHistory(state: boolean): void {
    this.blockSaveInHistory = state;
  }

  onRecentlyVisitedNodeChanged(): Observable<IRecentlyVisitedNode> {
    return this.openVisited.asObservable();
  }

  public getLastVisitedContentNodes(): Observable<IRecentlyVisitedNode[]> {
    return this.recentlyVisited.asObservable();
  }

  private get $combinedTenantIds(): string[] {
    return (this.$tenants || [])
      .filter((t: Tenant) => t.isSelected())
      .map((t: Tenant) => t.getId())
      .concat(this.$user.getTenantId());
  }

  private $update(): void {
    if (!this.$user || this.$lastVisited.length === 0 || this.blockSaveInHistory) {
      return;
    }
    this.$lastVisited.forEach((rcn: IRecentlyVisitedNode) => {
      rcn.tenant = (this.$tenants || []).find((t: Tenant) => t.getId() === rcn.tenantId);

      if (this.$mainTenant && (!rcn.tenantId || this.$user.getTenantId() === rcn.tenantId)) {
        // rcn.tenant = new Tenant().setName(this.$mainTenant?.getName() || 'Hauptmandant').setId(this.$mainTenant.getId());
        rcn.tenantId = this.$mainTenant.getId();
        rcn.tenantName = this.$mainTenant?.getName() || 'Hauptmandant';
      }
    });

    const displayedItems: IRecentlyVisitedNode[] = this.$lastVisited
      .filter((rcn: IRecentlyVisitedNode) => {
        return !rcn.tenant ||
          rcn.tenant.getId() === this.$mainTenant?.getId() ||
          this.$combinedTenantIds.find((id: string) => id === rcn.tenant?.getId())
          ? true
          : false;
      })
      .sort((a: IRecentlyVisitedNode, b: IRecentlyVisitedNode) => {
        return a.time < b.time ? 1 : -1;
      });

    this.recentlyVisited.next(displayedItems);
  }

  ngOnDestroy(): void {
    this.$alive = false;
  }

  public visitNode(node: HMI, parentNode?: HierarchicalMenuItem): void {
    if (!node || this.blockSaveInHistory) {
      return;
    }

    const mode: EColumnMode =
      node.getType() === ETemplateType.MENU_ITEM
        ? EColumnMode.TWO_COLUMNS
        : node.getContent()
        ? EColumnMode.SINGLE_COLUMN
        : null;
    if (mode === null) {
      return;
    }
    const details = `TREE.MODE.${mode}`;
    const path: string[] = this.$templateTreeApi.generateCurrentPath(node);
    const typePräfix = this.currentContent ? `${this.currentContent.page.getName()} - ` : '';
    const type = `${typePräfix}${parentNode?.getName() || 'Sonstiges'}`;
    const match = this.$lastVisited.find(
      (n) =>
        n.name === node.getName() && details === n.details && n.type === type && n.resourceId === node.getResourceId()
    );

    if (match) {
      match.path = path;
      match.details = details;
      match.time = new Date().getTime();
    } else {
      const newRecentlyVisitedNode: IRecentlyVisitedNode = {
        url: this.currentContent?.url,
        tenantId: node.getTenantId(),
        name: node.getName(),
        resourceId: node.getResourceId(),
        details,
        path,
        time: new Date().getTime(),
        type,
      };

      this.$lastVisited.unshift(newRecentlyVisitedNode);
      this.$lastVisited = this.$lastVisited
        .sort((a: IRecentlyVisitedNode, b: IRecentlyVisitedNode) => {
          return a.time < b.time ? 1 : -1;
        })
        .slice(0, this.configApi.access().templates.Tree.historyLimit);
    }
    this.$update();
  }
}
