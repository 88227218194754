import { EGanttInstance } from '@gantt/public-api';
import { GanttLibService } from 'frontend/src/dashboard/gantt/gantt/gantt-lib.service';
import { SaxMsBestGanttActiveSubmenuEntryElementSetting } from 'frontend/src/dashboard/gantt/gantt/saxms-best-gantt.settings';
import { GeneralGanttActionHandler } from 'frontend/src/dashboard/gantt/general/action-handling/action-handler';
import { GanttTemplateData } from 'frontend/src/dashboard/gantt/helper/gantt';
import { Observable, of } from 'rxjs';
import { ExternalGanttPlugin } from '../../external-plugin';
import { GanttPluginHandlerService } from '../../gantt-plugin-handler.service';

export const GanttEarliestStartLatestEndPluginId = 'GanttEarliestStartLatestEndPluginId';

/**
 * Plugin to highlight blocks for a short time.
 * The behavior is that blocks starts flashing when highlightBlocks function is triggered.
 */
export class GanttEarliestStartLatestEndPlugIn extends ExternalGanttPlugin {
  constructor(
    protected ganttPluginHandlerService: GanttPluginHandlerService,
    protected ganttLibService: GanttLibService,
    protected actionHandler: GeneralGanttActionHandler
  ) {
    super(ganttPluginHandlerService, ganttLibService, actionHandler);
  }

  onDestroy(): void {}

  public onInit(templateData: GanttTemplateData, responseData: any): void {
    this.addPlugIn(
      GanttEarliestStartLatestEndPluginId,
      this._ganttLibService.ganttInstanceService.getInstance(EGanttInstance.GANTT_EARLIEST_START_LATEST_END_VISUALIZER)
    );
  }

  public getJsPlugin(): any {
    return this.getPlugInById(GanttEarliestStartLatestEndPluginId);
  }

  public onAction(action: any): void {}
  public executeAction(action: any): Observable<any> {
    return of();
  }
  public injectSettings(submenuElements: SaxMsBestGanttActiveSubmenuEntryElementSetting[]): void {}
}
