import { BestGantt } from '../../main';

/**
 * Handler methods for yAxis events.
 */
export class GanttYAxisHandler {
  constructor(private _ganttDiagram: BestGantt) {}

  /**
   * Updates text entries in y axis.
   * Callback for rendering y axis by scroll.
   * @keywords callback, rerender, yaxis, y axis, scroll render, build, text, overlay, yaxis, y axis
   */
  public rerenderYAxisByScroll(): void {
    const s = this._ganttDiagram;
    s.reRenderYAxisVertical();
    s.getShiftFacade().renderHorizontalLines(s.getRenderDataSetYAxis());
  }
}
