<div class="map-wrapper">
  <!--HACK WICHTIGES TODO-->
  <app-map
    #MapComponent
    *ngIf="mapTemplate && !initLoad"
    [minHeight]="templateNode && templateNode.getMinHeight() ? templateNode.getMinHeight() : 500"
    [(mapTemplate)]="mapTemplate"
    [legendVisible]="legendItems.length > 0"
    (onSearch)="freeTextSearch($event)"
    (onContextMenuEvent)="onContextMenu($event)"
  >
    <ng-template #mapLegendLayerSwitchContainer>
      <mat-button-toggle-group
        *ngIf="templateNode.getMapLayers().length > 1"
        #group="matButtonToggleGroup"
        [value]="templateNode.getMapLayers()[0]"
        (change)="handleLayerSwitch($event)"
      >
        <mat-button-toggle
          *ngFor="let layer of templateNode.getMapLayers()"
          [value]="layer"
          [matTooltip]="layer?.id"
          class="switch-layer-btn"
        >
          <mat-icon>{{ layer.mapLayerType === 'MOVEMENT' ? 'timeline' : 'place' }}</mat-icon>
        </mat-button-toggle>
      </mat-button-toggle-group>
    </ng-template>

    <ng-template #mapLegendPagingContainer>
      <div *ngIf="mapTemplate.timedRangePicker" class="map-legend-page-container">
        <button mat-icon-button class="map-legend-page-nav-btn" (click)="handleTimeRange(false)">
          <mat-icon> keyboard_arrow_left </mat-icon>
        </button>
        <div class="map-legend-page-date-range">
          <template-datepicker
            [id]="templateNode.getId()"
            #templateDatepicker
            [mode]="'range'"
            [placeholder]="'TIMESPAN.headline' | translate"
            (changes)="handleChangeTimeRange($event)"
            class="picker"
            *ngIf="selectedValues && !resetTimePicker"
            [type]="'DATE'"
            [mode]="'range'"
            [selectedValues]="selectedValues"
          ></template-datepicker>
        </div>
        <button mat-icon-button class="map-legend-page-nav-btn" (click)="handleTimeRange(true)">
          <mat-icon> keyboard_arrow_right </mat-icon>
        </button>
      </div>
    </ng-template>

    <ng-template #mapLegendItemsContainer>
      <button
        *ngFor="let item of legendItems"
        class="marker-legend-btn"
        (mouseover)="handleHoverLegendItem(item, true)"
        (mouseout)="handleHoverLegendItem(item, false)"
        (click)="handleLegendClick(item)"
        mat-button
        [ngStyle]="{ 'text-decoration': item.hidden ? 'line-through' : 'none' }"
      >
        <mat-icon>location_on</mat-icon>
        <span>{{ item.label }}</span>
      </button>
    </ng-template>
  </app-map>
</div>
