import { Observable, of } from 'rxjs';

/**
 * Structure to execute local action.
 */
export interface GanttExternalActionRegistration {
  handleLocalAction(localAction: GanttActionWrapper, callBackParams?: any): Observable<any>;
}

/**
 * Action wrapper for internal action handling.
 */
export interface GanttActionWrapper {
  actions: any[];
  actionScope: GanttActionScopes;
  editedBlockIds?: string[];
}

/**
 * Gantt action types.
 */
export enum GanttActionScopes {
  LOCAL = 'LOCAL',
  GLOBAL = 'GLOBAL',
}
