<div class="second-column-wrapper">
  <!-- searchbar -->
  <div *ngIf="parent" #upperContainer>
    <div [style.text-align]="'center'">
      <freetext-filter
        *ngIf="(parent && parent.getFilterSearchUrl()) || parent.getFilters().length > 0"
        [showExtendedFilter]="parent?.isShowExtendedFilter()"
      >
      </freetext-filter>
    </div>
    <div [style.display]="'block'" class="filter-chips-container" *ngIf="parent && filterActive()">
      <app-filterchips-list [node]="parent"> </app-filterchips-list>
    </div>
    <div class="nav-container">
      <button
        *ngIf="!isUpToDate"
        class="navbtn refresh-button"
        [matBadge]="'!'"
        [matBadgeSize]="'small'"
        mat-icon-button
        [matTooltipShowDelay]="1000"
        [matTooltip]="'BUTTON.refresh' | translate"
        [id]="'refreshButton'"
        (click)="initialize()"
      >
        <mat-icon>refresh</mat-icon>
      </button>
      <button
        *ngIf="parent?.getCreateRestUrls() || parent?.getCreateWizardRestUrls()"
        class="nav-btn"
        mat-button
        [id]="'createButton'"
        (click)="createElement()"
      >
        <mat-icon>add</mat-icon> {{ 'FORMS.ITEMS.CREATE' | translate }}
      </button>
    </div>
    <ng-container *ngIf="listsize === 0">
      <div class="empty-elements">
        <span>{{ 'no entries' | translate }}</span>
      </div>
    </ng-container>
  </div>

  <ng-template [ngIf]="!isLoading && !!displayedItems?.length">
    <cdk-virtual-scroll-viewport class="list" [itemSize]="lineCount * 15 + 20 + 1" [style.height]="contentHeight">
      <div *cdkVirtualFor="let _node of displayedItems; trackBy: trackByFn; let idx = index">
        <div class="nodeWrapper" [style.height]="lineCount * 15 + 1 + 20 + 'px'">
          <!-- <span *ngIf="_node">{{idx}} - {{_node?.name}}</span> -->

          <!-- <template-slot *ngIf="_node" [source]="_node"></template-slot> -->

          <template-slot *ngIf="_node; else defaultTextNode" [source]="_node"></template-slot>
          <ng-template #defaultTextNode>
            <div class="fake-node">{{ 'Loading' | translate }}</div>
          </ng-template>
          <!-- <div *ngIf="!_node" class="fake-node">
            lädt...
          </div> -->
        </div>
      </div>
    </cdk-virtual-scroll-viewport>
  </ng-template>

  <mat-spinner *ngIf="isLoading" [diameter]="25"></mat-spinner>

  <div #lowerContainer class="lower-container mat-elevation-z4" *ngIf="hasUpload">
    <app-file-uploader [displayMode]="'icon_and_label'" [disabled]="parent?.isDisabled()" [template]="parent">
    </app-file-uploader>
  </div>
</div>
