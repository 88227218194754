import * as d3 from 'd3';
import { takeUntil } from 'rxjs';
import { BestGantt } from '../../../main';
import { GanttShiftEditRestrictionVisualizer } from '../../shift-edit-general/edit-restrictions/shift-edit-restriction-visualizer';
import { GanttShiftTranslator } from '../shift-translator';
import { IGanttShiftTranslationEvent } from '../translation-events/translation-event.interface';

/**
 * Class which handles the visualization of violated translation restrictions during shift dragging.
 */
export class GanttShiftTranslationRestrictionVisualizer extends GanttShiftEditRestrictionVisualizer {
  private _canvas: d3.Selection<SVGGElement, unknown, SVGSVGElement, unknown> = null;
  private _rect: d3.Selection<SVGRectElement, unknown, SVGGElement, unknown> = null;

  constructor(ganttDiagram: BestGantt) {
    super(ganttDiagram);
  }

  /**
   * Initializes the visualization of violated restrictions with the given shift translator instance.
   * @param shiftTranslator Instance of shift translator to use for the initialization.
   */
  public init(shiftTranslator: GanttShiftTranslator): void {
    shiftTranslator
      .onShiftEditStart()
      .pipe(takeUntil(shiftTranslator.onDestroy))
      .subscribe((event) => this._build(event));
    shiftTranslator
      .onShiftEditEnd()
      .pipe(takeUntil(shiftTranslator.onDestroy))
      .subscribe(() => this._destroy());
  }

  /**
   * Builds an overlay over the shift container to manipulate the cursor during shift dragging.
   * Triggered by shift translation start events.
   * @param event Shift translation start event which triggered this method.
   */
  private _build(event: IGanttShiftTranslationEvent): void {
    if (!this._ganttDiagram.getConfig().shiftEditVisualizeNotAllowed()) return;

    this._canvas = d3
      .select<SVGElement, unknown>(event.originEvent.event.target.node().parentNode as SVGElement)
      .append('g')
      .attr('class', 'gantt-shift-translation-overlay');
    this._rect = this._canvas
      .append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', '100%')
      .attr('height', '100%')
      .style('cursor', 'auto')
      .style('fill', 'transparent');
  }

  /**
   * Destroys a previously built overlay over the shift container.
   * Triggered by shift translation end events.
   */
  private _destroy(): void {
    this._canvas?.remove();
    this._canvas = null;
    this._rect = null;
  }

  public block(): void {
    if (this._rect && this._rect.style('cursor') !== 'not-allowed') {
      this._rect.style('cursor', 'not-allowed');
    }
  }

  public unblock(): void {
    if (this._rect && this._rect.style('cursor') !== 'auto') {
      this._rect.style('cursor', 'auto');
    }
  }
}
