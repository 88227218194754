import { GanttChildren } from 'frontend/src/dashboard/gantt/general/generator/gantt-input.data';
import { GanttColorizer } from '../../color/colorizer';
import { GanttDataRow } from '../data-structure/data-structure';

/**
 * Helper class for going through gantt origin dataset tree structure.
 * @keywords data, dataset, manipulation, iterate, recursive, tree, find, search, query
 * @class
 * @constructor
 * @static
 */
export abstract class DataManipulator {
  /**
   * Iterates over a data set of type T and executes the provided callback functions for each row and child.
   * @template T The type of the data set.
   * @param {T[]} dataSet The data set to iterate over.
   * @param {{ [key: string]: (child: T, level: number, parent: T, index: { index: number }, abort: { abort: boolean }) => void }} rowCallbackFunctions The callback functions to execute for each row.
   * @param {{ [key: string]: (child: T, level: number, parent: T, index: { index: number }) => void }} [rowCallbackAfterGoingDeeper] The callback functions to execute for each row after going deeper.
   * @param {T extends GanttDataRow ? 'child' : T extends GanttChildren ? 'children' : never} [childPropertyName] The name of the property that contains the child rows.
   * @param {T} [firstLevelParent] The parent row of the first level.
   * @returns {T[]} The original data set.
   */
  static iterateOverDataSet<T extends GanttDataRow | GanttChildren>(
    dataSet: T[],
    rowCallbackFunctions: {
      [key: string]: (child: T, level: number, parent: T, index: { index: number }, abort: { abort: boolean }) => void;
    },
    rowCallbackAfterGoingDeeper?: {
      [key: string]: (child: T, level: number, parent: T, index: { index: number }) => void;
    },
    childPropertyName?: T extends GanttDataRow ? 'child' : T extends GanttChildren ? 'children' : never,
    firstLevelParent?: T
  ) {
    if (!childPropertyName)
      childPropertyName = 'child' as T extends GanttDataRow ? 'child' : T extends GanttChildren ? 'children' : never;

    function recursiveRun(
      child: T,
      level: number,
      parent: T | null,
      index: { index: number },
      abort?: { abort: boolean }
    ) {
      const newLevel = level + 1;

      for (const key in rowCallbackFunctions) {
        rowCallbackFunctions[key](child, level, parent, index, abort);
      }
      // going deeper recursively
      if (child[childPropertyName as 'child' | 'children'] && !abort.abort) {
        for (let r = { index: 0 }; r.index < child[childPropertyName as 'child' | 'children'].length; r.index++) {
          recursiveRun(child[childPropertyName as 'child' | 'children'][r.index], newLevel, child, r, abort);
        }
      }

      for (const key in rowCallbackAfterGoingDeeper) {
        rowCallbackAfterGoingDeeper[key](child, level, parent, index);
      }
    }

    const abort = { abort: false };
    for (let i = { index: 0 }; !abort.abort && i.index < dataSet.length; i.index++) {
      const entry = dataSet[i.index];
      recursiveRun(entry, 0, firstLevelParent, i, abort);
    }
    return dataSet;
  }
  /**
   * iterateOverDataSet but with a cloned dataset.
   * @keywords data, dataset, iterate, recursive, tree, callbackstack, clone
   * @param {GanttDataRow[]} dataSet Array of data rows (for example the array in ganttData.ganttEntries)
   * @param {Map<string, function>} rowCallbackFunctions Callback stack which will be executed for each children.
   * @return {GanttDataRow[]} Dataset copy.
   */
  static iterateOverClonedDataSet(dataSet, rowCallbackFunctions) {
    // copy json
    const manipulatedDataSet = JSON.parse(JSON.stringify(dataSet));
    return this.iterateOverDataSet(manipulatedDataSet, rowCallbackFunctions);
  }

  /**
   * Adds new color values to shifts by shift property.
   * @keywords data, dataset, iterate, color, shift, additional data
   * @param {GanttDataRow[]} dataSet Array of data rows (for example the array in ganttData.ganttEntries).
   * @param {string} propertyName Shift property on which the shift color is based on. If selected property is missing, shift gets black color.
   * @return {GanttDataRow[]} Dataset copy.
   */
  public static generateColorByAdditionalData(dataSet, propertyName) {
    const colorize = function (childData) {
      for (let i = 0; i < childData.shifts.length; i++) {
        const shift = childData.shifts[i];
        const data = shift.additionalData[propertyName];
        if (data) shift.color = GanttColorizer.getColorValueByString(data);
        else shift.color = '#000000';
      }
    };
    return this.iterateOverClonedDataSet(dataSet, { colorize: colorize });
  }
}
