import { EntryElementValue } from '@app-modeleditor/components/entry-collection/entry-element-value';
import { EFieldType } from '@app-modeleditor/components/entry-collection/field-type.enum';
import { Datepicker } from '@app-modeleditor/components/template-datepicker/datepicker';
import { BestGantt } from '@gantt/public-api';
import { Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Gantt_General } from '../../general.gantt.component';
import { GanttEssentialPlugIns } from '../../plugin/e-gantt-essential-plugins';
import { GanttXAxisManipulatorPlugIn } from '../../plugin/plugin-list/time-scaling/xaxis-manipulator';

export class NavigationIntervallElement extends Datepicker {
  private _onZoomEndSubscription: Subscription = null;

  constructor(private scope: Gantt_General) {
    super();
    // this.setDisplayBackground(false);
  }

  get(data: any): this {
    const g: BestGantt = this.scope.ganttLibService.bestGantt;

    this.setId(data.id)
      .setName(data.name)
      .onChanges((val: EntryElementValue) => this.changeTime(g, val))
      .buildValue(g)
      .setFieldType(EFieldType.RANGE_PICKER)
      .setAlwaysEnabled(true)
      .setValue(new EntryElementValue().setValue(null))
      .setMinDate(new Date(this.scope.ganttTemplateDataService.getTemplateData().getHierarchicalPlan().startDate))
      .setMaxDate(new Date(this.scope.ganttTemplateDataService.getTemplateData().getHierarchicalPlan().endDate))
      .setUseLocalTime(true);

    // register for changes
    this._onZoomEndSubscription = g
      .getXAxisBuilder()
      .onZoomEnd.pipe(takeUntil(g.onDestroy))
      .subscribe(this.buildValue.bind(this, g));
    return this;
  }

  private buildValue(g: BestGantt): this {
    this.scope.ganttLibService.ngZone.run((_) => {
      this.setValue(
        new EntryElementValue().setValue({
          from: (g.getXAxisBuilder().getCurrentScale().domain()[0] as Date).getTime(),
          to: (g.getXAxisBuilder().getCurrentScale().domain()[1] as Date).getTime(),
        })
      );
    });
    return this;
  }

  private changeTime(g: BestGantt, val: EntryElementValue): void {
    this._onZoomEndSubscription?.unsubscribe();
    let start: Date = new Date(val.getValue<any>().from);
    let end: Date = new Date(val.getValue<any>().to);

    if (!isNaN(start.getTime()) && !isNaN(end.getTime())) {
      // add one day to range because in germany we think of inclusive date bounds.
      // end.setDate(end.getDate() + 1);
      start = this.roundDateDownToFullDay(start);
      end = this.roundUp(end);
      const xAxisManipulatorPlugIn: GanttXAxisManipulatorPlugIn =
        this.scope.ganttPluginHandlerService.getEssentialPlugIn(GanttEssentialPlugIns.XAxisManipulatorPlugIn);
      xAxisManipulatorPlugIn.changeTimeToInterval(start, end);
    }
    this._onZoomEndSubscription = g
      .getXAxisBuilder()
      .onZoomEnd.pipe(takeUntil(g.onDestroy))
      .subscribe(this.buildValue.bind(this, g));
  }

  private roundUp(date: Date) {
    const newDate = new Date(date);
    newDate.setHours(23);
    newDate.setMinutes(59);
    newDate.setSeconds(59);
    newDate.setMilliseconds(999);
    return newDate;
  }

  private roundDateDownToFullDay(date: Date) {
    const newDate = new Date(date);
    newDate.setHours(0);
    newDate.setMinutes(0);
    newDate.setSeconds(0);
    newDate.setMilliseconds(0);
    return newDate;
  }
}
