import { GanttCanvasRow, GanttCanvasShift } from '../../data-handler/data-structure/data-structure';
import { EGanttScrollContainer } from '../../html-structure/scroll-container.enum';

/**
 * Generic type defining all properties a render dataset object should have.
 */
export type IGanttRenderDataSet<T = GanttCanvasRow | GanttCanvasShift> = {
  [EScrollContainerId in EGanttScrollContainer]: T[];
};

/**
 * Data structure defining a render dataset for gantt shifts.
 */
export class GanttRenderDataSetShifts implements IGanttRenderDataSet<GanttCanvasShift> {
  public gantt_scroll_wrapper_default: GanttCanvasShift[] = undefined;
  public gantt_scroll_wrapper_sticky_rows: GanttCanvasShift[] = undefined;
}

/**
 * Data structure defining a render dataset for gantt rows.
 */
export class GanttRenderDataSetYAxis implements IGanttRenderDataSet<GanttCanvasRow> {
  public gantt_scroll_wrapper_default: GanttCanvasRow[] = undefined;
  public gantt_scroll_wrapper_sticky_rows: GanttCanvasRow[] = undefined;
}
