import { EGanttInstance, GanttDataShift } from '@gantt/public-api';
import { GanttLibService } from 'frontend/src/dashboard/gantt/gantt/gantt-lib.service';
import { SaxMsBestGanttActiveSubmenuEntryElementSetting } from 'frontend/src/dashboard/gantt/gantt/saxms-best-gantt.settings';
import { GanttTemplateDataService } from 'frontend/src/dashboard/gantt/general/template-data/gantt-template-data.service';
import { GanttTemplateData } from 'frontend/src/dashboard/gantt/helper/gantt';
import { Observable, of, Subject } from 'rxjs';
import { GeneralGanttActionHandler } from '../../../action-handling/action-handler';
import { GanttResponseHandler } from '../../../response/response-handler';
import { ExternalGanttPlugin } from '../../external-plugin';
import { GanttPluginHandlerService } from '../../gantt-plugin-handler.service';

const GanttPlugInStickyBlocks = 'gantt-plugin-sticky-blocks';

/**
 * PlugIn-Wrapper for StickyBlocks plugin.
 * Blocks stick to the edge of the shift area as soon as they are out of the viewport.
 */
export class GanttStickyBlocksPlugIn extends ExternalGanttPlugin {
  private plugInIsActive: boolean;
  public plugInIsActiveNotification: Subject<boolean> = new Subject<boolean>();

  constructor(
    protected _ganttPluginHandlerService: GanttPluginHandlerService,
    protected _ganttLibService: GanttLibService,
    protected _actionHandler: GeneralGanttActionHandler,
    protected _responseHandler: GanttResponseHandler,
    protected _ganttTemplateDataService: GanttTemplateDataService
  ) {
    super(_ganttPluginHandlerService, _ganttLibService, _actionHandler);
  }

  public onInit(templateData: any, responseData: any) {
    this.addPlugIn(
      GanttPlugInStickyBlocks,
      this._ganttLibService.ganttInstanceService.getInstance(EGanttInstance.STICKY_BLOCKS)
    );
    this._subscribeToStickyBlockClickEvent();
  }

  public onDestroy(): void {}

  public onAction(action: any) {}

  public executeAction(localAction: any): Observable<any> {
    return of(true);
  }

  public isActive(): boolean {
    return this.plugInIsActive;
  }

  public setActive(boolean) {}

  public injectSettings(submenuElements: SaxMsBestGanttActiveSubmenuEntryElementSetting[]): void {}

  /**
   * Subscription of sticky block click event in js gantt.
   */
  private _subscribeToStickyBlockClickEvent() {
    this.getPlugInById(GanttPlugInStickyBlocks).subscribeToStickyBlockClick('handleStickyBlockClick', (shiftId) => {
      const templateData = this._ganttTemplateDataService.getTemplateData();
      this._handleStickyBlockClick(
        this._ganttLibService.bestGantt.getSelectionBoxFacade().getSelectedShifts(),
        templateData
      );
    });
  }

  /**
   * Adds selected sticky blocks to template data.
   */
  private _handleStickyBlockClick(selectedCanvasShifts: any[], templateData: GanttTemplateData) {
    templateData.setSelectedValues(false, this._ganttLibService.backendToGanttOriginInputMapper);

    if (selectedCanvasShifts.length != 0) {
      // add selected block id
      templateData.setSelectedBlock(
        {
          id: selectedCanvasShifts[0].id,
        },
        this._ganttLibService.backendToGanttOriginInputMapper
      );
      // ? templateData.selectedBlock.id = selectedCanvasShifts[0].id;

      // add all shifts
      const selectedShifts: GanttDataShift[] = [];

      for (const canvasShift of selectedCanvasShifts) {
        const foundShift = this._ganttLibService.ganttInstanceService
          .getInstance(EGanttInstance.SHIFT_DATA_FINDER)
          .getShiftById(
            this._ganttLibService.bestGantt.getDataHandler().getOriginDataset().ganttEntries,
            canvasShift.id
          );
        if (!foundShift?.shift) continue;
        selectedShifts.push(foundShift.shift);
      }
      // ? templateData.selectedValues = selectedShifts;
      templateData.setSelectedValues(selectedShifts, this._ganttLibService.backendToGanttOriginInputMapper);
    }
  }
}
