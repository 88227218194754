import { Injectable, Type } from '@angular/core';
import { TemplateAdapter } from '@app-modeleditor/utils/template-factory.service';
import { Adapter } from '../../../core/adapter';
import { ButtonAdapter } from '../button/button-adapter.service';
import { ContextMenuItem } from './context-menu-item';
import { ExecutionType } from './execution-type';

@Injectable({
  providedIn: 'root',
})
export class ContextMenuItemAdapter implements Adapter<ContextMenuItem> {
  constructor(private _buttonAdapter: ButtonAdapter) {}

  adapt(item: any): ContextMenuItem {
    throw new Error('Method not implemented.');
  }
  inherit<T extends ContextMenuItem>(type: Type<T>, item: any): T {
    return this._setProps(this._buttonAdapter.inherit<T>(type, item), item);
  }
  inheritFrom<T extends ContextMenuItem>(scope: TemplateAdapter, type: Type<T>, item: any): T {
    return this._setProps(this._buttonAdapter.inheritFrom<T>(scope, type, item), item);
  }
  applyValues<T>(scope: TemplateAdapter, item: any, values: any): T {
    throw new Error('Method not implemented.');
  }

  private _setProps<T extends ContextMenuItem>(el: T, data: any): T {
    const mappedExecutionType: ExecutionType =
      (data as any).contextMenuItem === 'MULTI_SELECT_ACTION' ? ExecutionType.MULTI : ExecutionType.SINGLE;

    return el
      .setDoubleClickAction(data.doubleClickAction)
      .setExecutionType(mappedExecutionType)
      .setMultiselectId(data.multiselectId);
  }
}
