import { NgModule } from '@angular/core';
import { MaterialModule } from 'frontend/src/dashboard/core/material/material.module';
import { ContextMenuPopupComponent } from './context-menu-popup.component';
import { CommonModule } from '@angular/common';
import { MySharedModule } from 'frontend/src/dashboard/shared/shared.module';
import { TemplateButtonModule } from '@app-modeleditor/components/button/button.module';

@NgModule({
  imports: [CommonModule, MySharedModule, TemplateButtonModule],
  declarations: [ContextMenuPopupComponent],
  providers: [],
  exports: [ContextMenuPopupComponent],
})
export class ContextMenuPopupModule {}
