import { Colorpicker } from '@app-modeleditor/components/color-picker/colorpicker';
import { DateInput } from '@app-modeleditor/components/elements/date-input/date-input';
import { EntryElement } from '@app-modeleditor/components/entry-collection/entry-element';
import { EntryElementValue } from '@app-modeleditor/components/entry-collection/entry-element-value';
import { EFieldType } from '@app-modeleditor/components/entry-collection/field-type.enum';
import { MultiObjectSelector } from '@app-modeleditor/components/selector/multi-object-selector';
import { EDatatype } from 'frontend/src/dashboard/model/entry/datatype.enum';
import { ETemplateType } from 'frontend/src/dashboard/model/resource/template-type';
import { MenuItem } from 'frontend/src/dashboard/view/template-toolbar/menu-item';
import { ToolbarGroup } from 'frontend/src/dashboard/view/template-toolbar/toolbar-group';
import { SaxMsSpreadSheetColumn } from '../../core/saxms-spreadsheet';
import { EDefaultSubmenues } from '../../model/default-submenues.enum';
import { ESpreadsheetDatatypes } from '../../model/table-datatypes.enum';
import { SpreadsheetFilterService } from '../../utils/spreadsheet-filter.service';
import { SpreadsheetToolbar } from './../spreadsheet-toolbar';

export class PredefinedQuickSearchMenu extends MenuItem {
  private quickearchGroup: ToolbarGroup;

  constructor(
    private scope: SpreadsheetToolbar,
    private spreadsheetFilterService: SpreadsheetFilterService,
    private simplifiedQuickSearch: boolean
  ) {
    super();
    this.scope.setToggleAction(this.setName('FILTER.quicksearch'));
    this.setStartOffset(50);
    this.setDisabledScrollHandles(true);

    if (this.scope.scope.getTableTemplate().insideDisabledSubMenues(EDefaultSubmenues.QUICKSEARCH)) {
      return this;
    }

    this.quickearchGroup = new ToolbarGroup().setId('quicksearch').setName('');
    if (this.scope.scope.getColumnUtil().getColumns() && this.scope.scope.getColumnUtil().getColumns().length > 0) {
      this.setToolbarGroups([this.quickearchGroup]);
    }
    this.updateElements();
  }

  public updateElements(): void {
    let leftPosition = 0;

    this.quickearchGroup.setEntryElements([]);
    this.scope.scope
      .getColumnUtil()
      .getColumns()
      .sort((a, b) => {
        if (a.data < b.data) {
          return -1;
        }
        if (a.data > b.data) {
          return 1;
        }
        return 0;
      })
      .forEach((header: SaxMsSpreadSheetColumn, colIndex: number) => {
        const elem: EntryElement = this.simplifiedQuickSearch
          ? this.getSearchElement(header, colIndex, leftPosition)
          : this.getFilterElement(header, colIndex, leftPosition);

        this.quickearchGroup.addEntryElements(elem);

        leftPosition += header.width;
      });
  }

  private getFilterElement(header: SaxMsSpreadSheetColumn, colIndex: number, leftPosition: number): EntryElement {
    const entry: EntryElementValue = new EntryElementValue()
      .setAvailableValues(header.selectOptions || [])
      .setValue(this.spreadsheetFilterService.getQuicksearchValue(colIndex));

    if (header.fieldType !== EFieldType.COMBO_BOX) {
      // no null value for combo box types
      entry.setAvailableValues([null].concat(entry.getAvailableValues()));
    } else {
      entry.setAvailableValues(entry.getAvailableValues());
    }

    const parsedHeader: any = JSON.parse(JSON.stringify(header));
    parsedHeader.type = ETemplateType.ENTRY_ELEMENT;

    if (header.fieldType === EFieldType.MULTI_OBJECT_SELECTOR) {
      parsedHeader.fieldType = EFieldType.OBJECT_SELECTOR;
    }
    if (parsedHeader.fieldType === EFieldType.OBJECT_SELECTOR && !parsedHeader.entryElement) {
      parsedHeader.fieldType = EFieldType.TEXT_FIELD;
    }
    if (parsedHeader.fieldType === EFieldType.COMBO_BOX) {
      parsedHeader.fieldType = EFieldType.MULTI_OBJECT_SELECTOR; // allow multiple filter values
    }
    if (parsedHeader.fieldType === EFieldType.TEXT_AREA) {
      parsedHeader.fieldType = EFieldType.TEXT_FIELD;
    }

    // parse template from backend
    const el: EntryElement = this.scope.scope.getTemplateAdapter().adapt<EntryElement>(parsedHeader);

    const element: EntryElement = this.scope
      .createEntryElement(
        el
          .setId(
            `${this.scope.scope.getTableTemplate().getId()}_quicksearch_${header.filterSortObject.getColumnIndex()}`
          )
          .setName(header.label)
          .setEditable(true)
          .setEnabled(true)
          .setActionRestUrl(header?.entryElement?.getActionRestUrl())
          .setWidth(this.getElementWidth(header))
          .setDisabled(false)
          // show only if header/column is also shown
          .setVisibleByExternalCondition(
            () => !this.scope.scope.getHiddenColumnFilterSet().has(header.filterSortObject.getColumnIndex())
          )
          .setUseLocalTime(header.useLocalTime)
          .onChanges((data: EntryElementValue) => {
            this.spreadsheetFilterService.quicksearch(
              element,
              this.scope.scope.getSpreadsheetHelperUtil().getDatatypeForQuicksearch(header)
            );
          })
          .setValue(entry)
      )
      .setQuickSearchElement(true);

    if (this.isElementFixed(colIndex)) {
      element.setSticky(true).setStickyLeft(leftPosition);
    }

    element.setAvailableFilterConditions(
      this.scope.scope.getSpreadsheetHelperUtil().getFilterConditions(header, element)
    );

    switch (parsedHeader.fieldType) {
      case EFieldType.MULTI_OBJECT_SELECTOR:
        const availableValues: string[] = element.getValue<EntryElementValue>().getAvailableValues();
        const availableOptions = availableValues.map((option) =>
          new EntryElementValue().setValue(new EntryElementValue().setName(option).setValue(option))
        );

        (element as MultiObjectSelector).setAvailableOptions(availableOptions);
        (element as MultiObjectSelector)
          .getValue<EntryElementValue>()
          .setValue(availableOptions.map((option) => option.getValue()));

      case EFieldType.OBJECT_SELECTOR:
        (element as MultiObjectSelector).setShowChipsBelow(false);
        break;
      case EFieldType.COLOR_PICKER:
        (element as any).possibileValues = (header.entryElement as Colorpicker).getPossibileValues();
        break;
    }

    switch (header.datatype) {
      case ESpreadsheetDatatypes.bool:
        element.setFieldType(EFieldType.COMBO_BOX);
        entry.setAvailableValues([null, true, false]);
        break;
      case ESpreadsheetDatatypes.float:
        element.setDatatype(EDatatype.FLOAT);
        break;
      case ESpreadsheetDatatypes.long:
        element.setDatatype(EDatatype.LONG);
        break;
      case ESpreadsheetDatatypes.number:
        element.setDatatype(EDatatype.INTEGER);
        break;
      case ESpreadsheetDatatypes.text:
        element.setDatatype(EDatatype.STRING);
        break;
    }

    return element;
  }

  getSearchElement(header: SaxMsSpreadSheetColumn, colIndex: number, leftPosition: number): EntryElement {
    let fieldType: EFieldType;
    let searchElement: EntryElement;
    const value = new EntryElementValue().setValue(null);

    switch (header.fieldType) {
      case EFieldType.DATE_PICKER:
      case EFieldType.DATE_TIME_PICKER:
        fieldType = EFieldType.DATE_INPUT;
        searchElement = new DateInput()
          .setShowTime(header.fieldType === EFieldType.DATE_TIME_PICKER)
          .onChanges((data: EntryElementValue) => {
            this.spreadsheetFilterService.searchDate(data.getValue(), header);
          });
        value.setValue({ day: null, month: null, year: null, hour: null, minute: null });
        break;
      case EFieldType.CHECK_BOX:
        searchElement = new EntryElement().onChanges((data: EntryElementValue) => {
          this.spreadsheetFilterService.searchBoolean(data.getValue(), header);
        });
        fieldType = EFieldType.COMBO_BOX;
        break;
      default:
        searchElement = new EntryElement().onChanges((data: EntryElementValue) => {
          this.spreadsheetFilterService.searchString(data.getValue(), header);
        });
        fieldType = EFieldType.TEXT_FIELD;
        break;
    }

    searchElement
      .setId(`${this.scope.scope.getTableTemplate().getId()}_quicksearch_${header.filterSortObject.getColumnIndex()}`)
      .setName(header.label)
      .setFieldType(fieldType)
      .setEditable(true)
      .setEnabled(true)
      .setActionRestUrl(header?.entryElement?.getActionRestUrl())
      .setWidth(this.getElementWidth(header))
      .setDisabled(false)
      // show only if header/column is also shown
      .setVisibleByExternalCondition(
        () => !this.scope.scope.getHiddenColumnFilterSet().has(header.filterSortObject.getColumnIndex())
      )
      .setUseLocalTime(header.useLocalTime)
      .setValue(value)
      .setQuickSearchElement(true);
    if (this.isElementFixed(colIndex)) {
      searchElement.setSticky(true).setStickyLeft(leftPosition);
    }

    if (header.fieldType === EFieldType.CHECK_BOX) {
      value.setAvailableValues([null, true, false]);
    }

    return searchElement;
  }

  public readonly onRemoveAllFilters = () => {
    this.spreadsheetFilterService.removeAllFilters();
  };

  private getElementWidth(header: SaxMsSpreadSheetColumn): number {
    return header?.width - 10; // (2px + 7px) padding + 1px border
  }

  private isElementFixed(colIndex: number): boolean {
    return colIndex < this.scope.scope.getTableTemplate().getNumberFixedColumns();
  }
}
