<div class="tooltip" [ngStyle]="{ 'top.px': y, 'left.px': x }">
  <table>
    <ng-container *ngFor="let item of data.resourceElement.attributes">
      <tr *ngIf="item.showInTooltip">
        <td>{{ item.property }}:</td>
        <td>{{ item.value }}</td>
      </tr>
    </ng-container>
  </table>
</div>
