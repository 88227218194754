import { Component, Inject, OnDestroy } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { ViewService } from './../../../../view/view.service';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'ui-dialog',
  templateUrl: './ui.dialog.html',
  styleUrls: ['../ui.dialog.scss'],
  providers: [],
})
export class UiDialog implements OnDestroy {
  public horizon: any;
  public model: any;
  public text: any = '';
  public all = true;
  public selected = true;
  public discard = true;
  public styleRef;
  private isAlive = true;
  constructor(
    public dialogRef: MatDialogRef<UiDialog>,
    public trx: TranslateService,
    public globalViewService: ViewService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.init();
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }

  async init() {
    this.text = (await this.trx.get('WhatToSave?'))['value'];
    if (this.data) {
      if (this.data.text) {
        this.text = this.data.text;
      }
      if (this.data.all == false) {
        this.all = false;
      }

      if (this.data.selected == false) {
        this.selected = false;
      }

      if (this.data.discard == false) {
        this.discard = false;
      }
    }
  }

  ngOnInit(): void {
    this.globalViewService
      .getCurrentTheme()
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((style) => (this.styleRef = style));
  }

  public saveAll(): void {
    this.dialogRef.close('all');
  }

  public discardAll(): void {
    this.dialogRef.close('discard');
  }

  public saveSelected(): void {
    this.dialogRef.close('selected');
  }

  public cancel(): void {
    this.dialogRef.close(null);
  }
}
