import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import {
  getTimeEnumFromString,
  ITimeDurationPickerUnitComponent,
  TimeType,
  toMillisecond,
} from './duration-picker-lib';

@Component({
  selector: 'duration-picker',
  templateUrl: 'duration-picker.html',
  styleUrls: ['duration-picker.scss'],
})
/**
 * Duration Picker with simple Handling
 * @Martin Petzold marttin.petzold@saxms.de
 * <duration-picker [(value)]="2WayBindedValue" [min]="'string'" [max]="'string'" [size]="number"></duration-picker>
 *  or
 * <duration-picker [value]="inputValue" (valueChange)="outputValue" [min]="stringVariable" [max]="stringVariable" [size]="number"></duration-picker>
 *
 *  min and max can be
 *  { millisecond, second, minute, hour, day, week}
 *  input value can be Date or Number
 *  size is how many digits are shown
 */
export class DurationPicker {
  // @Input() value: any;
  @Input() min: string;
  @Input() max: string;
  @Input() size = 2;
  @Input() disabled = false;
  @Input() error = false;

  @Output() valueChange: EventEmitter<any>;
  @Output() getData: EventEmitter<any> = new EventEmitter();

  @ViewChild('millisecond') millisecond: ITimeDurationPickerUnitComponent;
  @ViewChild('second') second: ITimeDurationPickerUnitComponent;
  @ViewChild('minute') minute: ITimeDurationPickerUnitComponent;
  @ViewChild('hour') hour: ITimeDurationPickerUnitComponent;
  @ViewChild('day') day: ITimeDurationPickerUnitComponent;
  @ViewChild('week') week: ITimeDurationPickerUnitComponent;

  public durationElementList: Array<ITimeDurationPickerUnitComponent>;
  public minInput: TimeType = TimeType.MILLISECOND;
  public maxInput: TimeType = TimeType.WEEK;
  public internalValue: Date;
  public overValue: number;
  public delete = false;
  public emtpy = false;

  // dividers
  public SMDivider = true;
  public MHDivider = true;

  public internValueOrigin: any;
  constructor() {
    this.valueChange = new EventEmitter();
  }

  @Input()
  set value(value: any) {
    this.internValueOrigin = value;
    if (typeof value === 'object') {
      this.internalValue = value;
    } else {
      this.internalValue = new Date(value);
    }
    if (this.durationElementList && this.durationElementList.length) {
      this.setDurationEntityRange(this.minInput, this.maxInput, this.min, this.max, this.durationElementList); // where we need to dothis
      this.setFields(this.internalValue, getTimeEnumFromString(this.max));
    }
  }

  get value(): any {
    // transform value for display
    return this.internValueOrigin;
  }

  getFontSize(): number {
    return this.size * 5;
  }

  valueDelete() {
    this.onNumberChanged(-1);
    this.setDelete(true);
    this.clean(this.durationElementList);
    this.valueChange.emit(-1);
  }

  public getDurationElementList(): Array<ITimeDurationPickerUnitComponent> {
    return this.durationElementList;
  }

  ngOnInit() {
    this.getData.emit();
    /**
     * order need to be descending
     */
    this.durationElementList = new Array<any>();
    this.durationElementList.push(this.week);
    this.durationElementList.push(this.day);
    this.durationElementList.push(this.hour);
    this.durationElementList.push(this.minute);
    this.durationElementList.push(this.second);
    this.durationElementList.push(this.millisecond);

    this.calcDividers(this.min, this.max);
  }

  ngOnChanges() {
    this.calcDividers(this.min, this.max);
  }

  ngAfterViewInit() {
    if (typeof this.internValueOrigin === 'object') {
      this.internalValue = this.internValueOrigin;
    } else {
      this.internalValue = new Date(this.internValueOrigin);
    }
  }

  letsInitNow() {
    this.setDurationEntityRange(this.minInput, this.maxInput, this.min, this.max, this.durationElementList); // where we need to dothis
    this.setFields(this.internalValue, getTimeEnumFromString(this.max), true);
    if (Array.isArray(this.durationElementList)) {
      this.durationElementList.forEach((element) => {
        element.size = this.size;
      }, this);
    }
  }

  setDurationEntityRange(
    miI: TimeType,
    maI: TimeType,
    min: string,
    max: string,
    durationElementList: Array<ITimeDurationPickerUnitComponent>
  ) {
    miI = getTimeEnumFromString(min);
    maI = getTimeEnumFromString(max);
    if (miI !== TimeType.ERROR && maI !== TimeType.ERROR && miI <= maI) {
      if (Array.isArray(durationElementList)) {
        durationElementList.forEach((element) => {
          if (element.getTimeType() >= miI && element.getTimeType() <= maI) {
            element.setIsAvailable(true);
          } else {
            element.setIsAvailable(false);
          }
        }, this);
      }
    }
  }

  public calcDividers(min: string, max: string): void {
    if (!(min && max)) return;
    this.SMDivider = min == 'second' && max != min;
    this.MHDivider = (min == 'second' && max != min && max != 'minute') || (min == 'minute' && max != min);
  }

  setDelete(b: boolean) {
    if (b != this.delete) {
      setTimeout(() => {
        this.delete = b;
        this.emtpy = b;
      }, 0);
    }
  }

  checkIfToCalculateAndDo(actual: TimeType, maI: TimeType): number {
    if (actual <= maI) {
      return this.calculateSumRestandValue(toMillisecond[actual]);
    } else {
      return 0;
    }
  }

  setFields(date: Date, maI: TimeType, performance?: boolean) {
    if (date.getTime() >= 0) {
      this.overValue = date.getTime();
      if (this.durationElementList) {
        this.durationElementList.forEach((element) => {
          if (performance) {
            element.setValue(this.checkIfToCalculateAndDo(element.getTimeType(), maI));
          } else {
            element.updateValueBy(this.checkIfToCalculateAndDo(element.getTimeType(), maI));
          }
        }, this);
      }
    } else {
      this.clean(this.durationElementList);
    }
  }

  calculateSumRestandValue(factor: number): number {
    const v = Math.floor(this.overValue / factor);
    this.overValue -= v * factor;
    return v;
  }

  clean(durationElementList: Array<ITimeDurationPickerUnitComponent>) {
    if (Array.isArray(durationElementList)) {
      durationElementList.forEach((element) => element.setValue(''));
    }
  }

  onNumberChanged(e: any) {
    if (e >= 0) {
      this.internalValue.setTime(e);
    } else {
      this.clean(this.durationElementList);
      this.valueChange.emit(-1);
    }
    if (typeof this.value === 'object') {
      this.valueChange.emit(this.internalValue);
    } else {
      this.valueChange.emit(this.internalValue.getTime());
    }
  }
}
