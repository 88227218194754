import { fromEvent, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { BestGantt } from '../../../main';
import { GanttRowSearchExecuter } from '../../../plug-ins/row-search/row-search-executer';
import { GanttYAxisHeadButtonData } from './head-y-axis-button';

/**
 * Class that represents the row search button on the top of y axis.
 * @keywords button, y axis, yaxis, top, headline, container, click, row, search
 * @param {BestGantt} ganttRef Reference to gantt.
 * @param {string} [id=null] Button id.
 */
export class GanttYAxisHeadRowSearchButtonData extends GanttYAxisHeadButtonData {
  private _ganttRef: BestGantt;
  private _rowSearchPlugIn: GanttRowSearchExecuter;

  private _inputContainer: d3.Selection<HTMLDivElement, any, null, undefined>;
  private _searchTextInputContainer: d3.Selection<HTMLDivElement, any, null, undefined>;
  private _showOnlyResultsButtonContainer: d3.Selection<HTMLDivElement, any, null, undefined>;
  private _searchTextInput: d3.Selection<HTMLInputElement, any, null, undefined>;
  private _showOnlyResultsButton: d3.Selection<HTMLInputElement, any, null, undefined>;

  private _searchTextChangeSubscription: Subscription;
  private _showOnlyResultsChangeSubscription: Subscription;
  private _searchTextInputSubscription: Subscription;

  constructor(ganttRef: BestGantt, id: string = null) {
    super(id);

    this._ganttRef = ganttRef;
    this._inputContainer = null;
    this._searchTextInput = null;
    this._showOnlyResultsButton = null;

    this._searchTextChangeSubscription = null;
    this._showOnlyResultsChangeSubscription = null;
    this._searchTextInputSubscription = null;

    this._buildInputContainer();
    this._initStates();

    // wait for row search plug-in to be added
    const s = this;
    const plugInSubscriptionId = 'GanttYAxisHeadRowSearchButtonData_' + new Date().getTime();

    this._ganttRef.getPlugInHandler().subscribeToPlugIns(plugInSubscriptionId, function (plugIn: any) {
      if (plugIn instanceof GanttRowSearchExecuter) {
        s._rowSearchPlugIn = plugIn;
        s._ganttRef.getPlugInHandler().unSubscribeToPlugIns(plugInSubscriptionId);
        s._listenToExternalInputChanges();
      }
    });
  }

  /**
   * Initializes the button states.
   */
  private _initStates(): void {
    const s = this;

    // State 0: Open row search
    this.addState(
      'material-icons md-24 gantt_x-axis_placeholder-btn-row-search',
      () => {
        s._inputContainer.style('visibility', 'inherit');
      },
      'manage_search',
      'Attributsuche (Y-Achse)'
    );

    // State 1: Close row search
    this.addState(
      'material-icons md-24 gantt_x-axis_placeholder-btn-row-search',
      () => {
        s._inputContainer.style('visibility', 'hidden');
      },
      'manage_search',
      'Attributsuche (Y-Achse)'
    );
  }

  /**
   * Builds a container with row search inputs and adds neccesary event handlers.
   */
  private _buildInputContainer(): void {
    const s = this;

    this._inputContainer = this._ganttRef
      .getYAxisHeadBuilder()
      .addDiv('gantt_x-axis_placeholder-div-row-search')
      .style('visibility', 'hidden');

    this._searchTextInputContainer = this._inputContainer.append('div');

    this._showOnlyResultsButtonContainer = this._inputContainer.append('div');

    this._searchTextInput = this._searchTextInputContainer
      .append('input')
      .attr('type', 'text')
      .attr('class', 'gantt_x-axis_placeholder-div-row-search_search-text-input');

    this._searchTextInputSubscription = fromEvent(this._searchTextInput.node(), 'input')
      .pipe(debounceTime(300))
      .subscribe((event: any) => {
        if (s._rowSearchPlugIn) {
          s._rowSearchPlugIn.searchRowByTitleHierarchical(event.target.value);
        }
      });

    this._showOnlyResultsButton = this._showOnlyResultsButtonContainer
      .append('input')
      .attr('id', 'y-axis-search-show-only-results-button')
      .attr('type', 'checkbox')
      .attr('class', 'gantt_x-axis_placeholder-div-row-search_show-only-results-input')
      .on('change', (event) => {
        if (s._rowSearchPlugIn) {
          s._rowSearchPlugIn.setShowOnlyResults(event.target.checked);
          s._rowSearchPlugIn.searchRowByTitleHierarchical(s._searchTextInput.property('value'));
        }
      });

    this._showOnlyResultsButtonContainer
      .append('label')
      .attr('class', 'gantt_x-axis_placeholder-div-row-search_show-only-results-input')
      .attr('for', 'y-axis-search-show-only-results-button')
      .text('Nur Ergebnisse anzeigen');
  }

  /**
   * Subscribes to Observables to keep the input values up to date.
   */
  private _listenToExternalInputChanges(): void {
    this._searchTextChangeSubscription = this._rowSearchPlugIn
      .getSearchTextChangeObservable()
      .subscribe((newText) => this._searchTextInput.property('value', newText));
    this._showOnlyResultsChangeSubscription = this._rowSearchPlugIn
      .getShowOnlyResultsChangeObservable()
      .subscribe((newValue) => this._showOnlyResultsButton.property('checked', newValue));
  }

  /**
   * Method being called when the button gets removed from y axis head.
   */
  public destroy(): void {
    // remove input container
    this._inputContainer.remove();

    // unsubscribe from Observables
    if (this._searchTextChangeSubscription) {
      this._searchTextChangeSubscription.unsubscribe();
    }
    if (this._showOnlyResultsChangeSubscription) {
      this._showOnlyResultsChangeSubscription.unsubscribe();
    }
    if (this._searchTextInputSubscription) {
      this._searchTextInputSubscription.unsubscribe();
    }
  }
}
