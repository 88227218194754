import { IGanttShiftTextOverlayBuilding } from './text-overlay-interface';

/**
 * Strategie renders no text at all.
 * @implements {IGanttShiftTextOverlayBuilding}
 * @class
 */
export class TextOverlayNoText implements IGanttShiftTextOverlayBuilding {
  constructor() {}

  /**
   * @override
   */
  init(executer) {}

  /**
   * @override
   */
  render(dataSet, parentNode, executer) {}

  /**
   * @override
   */
  cleanUp() {}

  /**
   * @override
   */
  buildSmallerText() {}
}
