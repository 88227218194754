import { Observable, Subject, takeUntil } from 'rxjs';
import { GanttConfig } from '../../config/gantt-config';
import { GanttCanvasShift } from '../../data-handler/data-structure/data-structure';
import { DataManipulator } from '../../data-handler/data-tools/data-manipulator';
import { IGanttShiftResizeEvent } from '../../edit-shifts/shift-resizing/resize-events/resize-event.interface';
import { BestGantt } from '../../main';
import { BestGanttPlugIn } from '../gantt-plug-in';
import { GanttTextureCreator } from './gantt-texture-creator';

/**
 * Adds background texture via #url to shifts.
 * Usefull to combine it with index-card plugin.
 * @keywords executer, plugin, textur, texturizer, background, shift, color, rect, headline, pattern, def
 * @plugin texturizer
 * @class
 * @constructor
 * @extends BestGanttPlugIn
 * @requires BestGanttPlugIn
 * @requires GanttTextureCreator
 *
 * @deprecated This plug-in is no longer used (2023-11-29).
 */
export class GanttTexturizer extends BestGanttPlugIn {
  creator: GanttTextureCreator;
  config: GanttConfig;
  patternSuffix: string;
  isHeadlineActive: boolean;
  isCanvasModeActive: boolean;
  manipulatedCanvasShiftDataset: any;

  private _onDestroySubject: Subject<void> = new Subject<void>();

  constructor() {
    super(); // call super-constructor

    this.creator;

    this.config = null;

    /**
     * @type {BestGantt}
     */
    this.ganttDiagram = null;
    this.isHeadlineActive = null;

    this.patternSuffix = '_pattern';
    this.isCanvasModeActive = true; // If shifts are rendered in canvas and not in svg

    this.manipulatedCanvasShiftDataset; // maniuplatedCanvasShiftDataset from sticky row plugin
  }

  public initPlugIn(ganttDiagram: BestGantt): void {
    this.ganttDiagram = ganttDiagram;

    this.config = ganttDiagram.getConfig();
    this.creator = new GanttTextureCreator(this.ganttDiagram.getTextOverlay());
    this.creator.init(
      this,
      this.config,
      this.ganttDiagram.getShiftFacade().getShiftBuilder().getShiftDefsInFrontShifts().node(),
      this.patternSuffix
    );

    this._registerCallbacks();

    // init draw
    this.addHeadlineToAllShifts();
  }

  /**
   * @private
   */
  private _registerCallbacks(): void {
    this.ganttDiagram
      .getShiftFacade()
      .getShiftBuilder()
      .afterShiftRender()
      .pipe(takeUntil(this.onDestroy))
      .subscribe((event) => this._addHeadlineToShifts(event));

    this.ganttDiagram
      .getShiftTranslator()
      .onShiftEditEnd()
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => this.addHeadlineToAllShifts());

    this.ganttDiagram
      .getShiftResizer()
      .onShiftEditUpdate()
      .pipe(takeUntil(this.onDestroy))
      .subscribe((event) => this._addHeadlineToResizedShift(event));
  }

  /**
   * Remove of plugin. Part of plugin lifecycle.
   */
  removePlugIn() {
    const s = this;

    // remove all headline textures
    s.removeHeadlineFromAllShifts();

    s._onDestroySubject.next();
    s._onDestroySubject.complete();

    s.ganttDiagram.rerenderShiftsVertical();
  }

  /**
   * Callback function which will be executed after shift was rendered.
   * @private
   * @param {GanttShiftsAfterRenderEvent} shiftRenderEvent Event data of shift render.
   */
  private _addHeadlineToShifts(shiftRenderEvent) {
    const s = this;
    if (!this.isHeadlineActive) return;
    if (this.isCanvasModeActive) {
      const canvasNode = s.ganttDiagram.getShiftFacade().getShiftCanvas();
      let canvasShiftDataset = s.ganttDiagram.getDataHandler().getCanvasShiftDataset();

      if (s.manipulatedCanvasShiftDataset) {
        // sticky parent row data
        canvasShiftDataset = s.manipulatedCanvasShiftDataset;
      }

      const zoomTransformation = s.ganttDiagram.getShiftFacade().getShiftBuilder().getLastZoomTransformation();

      this.creator.buildCanvasOverlayRectsInsideShift(canvasNode, canvasShiftDataset, zoomTransformation);
    }

    if (!shiftRenderEvent.selection) return;
    shiftRenderEvent.selection
      .filter(function (d) {
        return d.color && s.creator.headlineExists(d.color.replace('#', 'C'));
      })
      .attr('fill', function (d) {
        if (d.selected) return 'url(#selected' + s.patternSuffix + ')';
        if (d.highlighted) return 'url(#highlighted' + s.patternSuffix + ')';
        return 'url(#' + d.color.replace('#', 'C') + s.patternSuffix + ')';
      });
  }

  /**
   * Adds headline texture to all shift of gantt.
   */
  addHeadlineToAllShifts() {
    const s = this;

    s._addHeadlineToShifts({
      selection: s.ganttDiagram
        .getShiftFacade()
        .getShiftGroupOverlay()
        .selectAll<SVGRectElement, GanttCanvasShift>('rect'),
    });
    s.creator.addSelectedAndHighlightedPatterns();
  }

  /**
   * @override
   */
  updatePlugInHeight() {
    const s = this;
    s.addHeadlineToAllShifts();
  }

  /**
   * Removes all headlines from shifts.
   */
  removeHeadlineFromAllShifts() {
    const s = this;
    this.isHeadlineActive = false;
    s.creator.removeAllHeadlines();
  }

  /**
   * Callback function to add headline texture to currently resized shift.
   * @param shiftResizeEvent Shift resize event data.
   */
  private _addHeadlineToResizedShift(shiftResizeEvent: IGanttShiftResizeEvent): void {
    this._addHeadlineToShifts({ selection: shiftResizeEvent.shiftSelection });
  }

  /**
   * Extracts all shift colors and add headline textures for them.
   */
  generateAllHeadlineTextures() {
    const s = this;
    this.isHeadlineActive = true;
    DataManipulator.iterateOverDataSet(s.ganttDiagram.getDataHandler().getOriginDataset().ganttEntries, {
      findAllShiftColors: function (child) {
        if (!child.shifts) return;
        for (let i = 0; i < child.shifts.length; i++) {
          const shift = child.shifts[i];
          if (shift.color) s.getHeadlineTexturizer().createRectHeadline(shift.color, shift.color);
        }
      },
    });
  }

  //
  // OBSERVABLES
  //

  /**
   * Observable which gets triggered when the instance gets destroyed.
   */
  public get onDestroy(): Observable<void> {
    return this._onDestroySubject.asObservable();
  }

  //
  // GETTER & SETTER
  //

  /**
   * @return {GanttTextureCreator} Texture builder.
   */
  getHeadlineTexturizer() {
    return this.creator;
  }
}
