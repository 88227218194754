import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ElementsComponent } from '@app-modeleditor/components/elements/elements.component';
import { ImageModule } from '@app-modeleditor/components/image/image.module';
import { ResourceSelectorModule } from '@app-modeleditor/components/resource-selector/resource-selector.module';
import { MySharedModule } from '../../../shared/shared.module';
import { ButtonSliderModule } from '../button-slider/button-slider.module';
import { TemplateButtonModule } from '../button/button.module';
import { FilterNodeViewerModule } from '../filter-node-viewer/filter-node-viewer.module';
import { TemplateHeadlineModule } from './../template-headline/template-headline.module';
import { TemplateTileButtonModule } from './../tile-button/tile-button.module';
import { SaxmsTooltipModule } from './../tooltip/tooltip.module';
import { ConditionsMenuModule } from './core/conditions-menu/conditions-menu.module';
import { DateInputModule } from './date-input/date-input.module';
import { LabeledTextModule } from './labeled-text/labeled-text.module';
import { NumericInputModule } from './numeric-input/numeric-input.module';
import { PasswordInput } from './password-input/password-input.directive';
import { SortMenuModule } from './sort-menu/sort-menu.module';
import { UnitPipe } from './unit.pipe';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MySharedModule,
    ResourceSelectorModule,
    TemplateButtonModule,
    TemplateHeadlineModule,
    SortMenuModule,
    ButtonSliderModule,
    TemplateTileButtonModule,
    LabeledTextModule,
    FilterNodeViewerModule,
    ImageModule,
    NumericInputModule,
    DateInputModule,
    SaxmsTooltipModule,
    ConditionsMenuModule,
    PasswordInput,
  ],
  declarations: [ElementsComponent, UnitPipe],
  exports: [ElementsComponent],
})
export class ElementsModule {}
