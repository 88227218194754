import {
  ConnectionPositionPair,
  FlexibleConnectedPositionStrategyOrigin,
  Overlay,
  OverlayRef,
} from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType } from '@angular/cdk/portal';
import { ComponentRef, ElementRef, Injectable, Injector } from '@angular/core';
import { CONTAINER_DATA } from './container-data';
import { DefaultOverlayContainer } from './default-overlay-container';
import { IOverlayData } from './overlay-data.interface';
import { IOverlayOptions } from './overlay-options.interface';
import { IOverlayPosition } from './overlay-position.interface';

@Injectable({
  providedIn: 'root',
})
export class OverlayService {
  constructor(private overlay: Overlay) {}

  private instanceOfIOverlayPosition(pos: any): pos is IOverlayPosition {
    return 'x' in pos && 'y' in pos;
  }

  private getPosition(
    pos: ElementRef | PointerEvent | MouseEvent | IOverlayPosition | HTMLElement,
    option: IOverlayOptions
  ) {
    if (!pos) {
      if (option.postion) {
        return this.overlay.position().global().bottom(option.postion.bottom).right(option.postion.right);
      } else {
        return this.overlay.position().global();
      }
    }

    let contextMenuAnchor: FlexibleConnectedPositionStrategyOrigin;
    if (pos instanceof ElementRef) {
      contextMenuAnchor = pos.nativeElement;
    } else if (pos instanceof MouseEvent || pos instanceof PointerEvent || this.instanceOfIOverlayPosition(pos)) {
      contextMenuAnchor = { x: pos.x + 15, y: pos.y + 10 };
    } else if (pos instanceof HTMLElement) {
      contextMenuAnchor = pos;
    }

    return this.overlay
      .position()
      .flexibleConnectedTo(contextMenuAnchor)
      .withPositions(this.getPositions(option.overlayOrigin))
      .withPush(true)
      .withViewportMargin(10);
  }

  /**
   * gets prefered positions for contextmenu
   * @returns ConnectionPositionPair[]
   */
  private getPositions(overlayOrigin: 'bottom' | 'top' = 'top'): ConnectionPositionPair[] {
    if (overlayOrigin === 'top') {
      return [
        {
          originX: 'center',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        },
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'start',
          overlayY: 'top',
        },
      ];
    } else {
      return [
        {
          originX: 'center',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'bottom',
        },
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'end',
          overlayY: 'bottom',
        },
      ];
    }
  }

  public create<T extends DefaultOverlayContainer<any>>(
    popupCmp: ComponentType<T>,
    el: ElementRef | MouseEvent | IOverlayPosition | HTMLElement,
    dataToPass: any,
    options: IOverlayOptions = {},
    injector?: Injector
  ): IOverlayData {
    const positionStrategy = this.getPosition(el, options);

    const overlayRef: OverlayRef = this.overlay.create({
      hasBackdrop: options && typeof options.backdrop === 'boolean' ? options.backdrop : true,
      positionStrategy,
      backdropClass: 'custom-backdrop',
    });

    overlayRef.addPanelClass('saxms-system-message');
    overlayRef.backdropClick().subscribe(() => {
      if (options && options.disableCloseByBackdrop) {
        return;
      }
      overlayRef.dispose();
    });

    const overlayPortal: ComponentPortal<T> = new ComponentPortal(
      popupCmp,
      null,
      this.createInjector(dataToPass, injector)
    );
    const cmpRef: ComponentRef<T> = overlayRef.attach(overlayPortal);
    cmpRef.instance.setRef(overlayRef);

    return { overlayRef: overlayRef, componentRef: cmpRef };
  }

  public createInjector(dataToPass: any, injector?: Injector): Injector {
    return Injector.create({
      providers: [{ provide: CONTAINER_DATA, useValue: dataToPass }],
      parent: injector,
    });
  }
}
