import { DataManipulator } from '../../data-handler/data-tools/data-manipulator';

/**
 * Helps to manage data manipulation for gantt comparison.
 * @keyword plugin, mapper, calculation, compare, data, handler
 * @plugin compare-gantts
 * @class
 * @constructor
 * @static
 */
export class CompareGanttsMapper {
  private constructor() {}

  /**
   * Make sure that both datasets have the same structure.
   * This is a helper function to reuse the CompareGantts.prototype.compareByRowId method
   * to compare gantts by row index
   * @param {GanttData} originDataSet
   * @param {GanttData} dataSet
   */
  static projectRowIdsToDataSet(originDataSet, dataSet) {
    let rowIds = [],
      index = 0;

    function projectRowIds(row) {
      rowIds.push(row.id);
    }
    DataManipulator.iterateOverDataSet(originDataSet.ganttEntries, { projectRowIds: projectRowIds });

    function addNewRowIds(row) {
      row.id = rowIds[index];
      index++;
    }
    DataManipulator.iterateOverDataSet(dataSet.ganttEntries, { addNewRowIds: addNewRowIds });
    return dataSet;
  }
}
