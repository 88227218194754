<span
  *ngIf="entryElement?.getAvailableFilterConditions().length > 0"
  (click)="stopEvent($event)"
  matPrefix
  [matMenuTriggerFor]="conditionsMenu"
  class="icon-wrapper"
  [saxmsTooltip]="'FILTER.FilterCondition.' + entryElement.getCondition().toLowerCase() | translate"
>
  <app-image [label]="getConditionIcon(entryElement.getCondition())"></app-image>
</span>

<mat-menu #conditionsMenu="matMenu" [class]="'condition-menu'">
  <div
    class="condition-wrapper"
    [class.active]="entryElement.getCondition() === condition"
    (click)="handleClick($event, condition)"
    *ngFor="let condition of entryElement?.getAvailableFilterConditions()"
  >
    <app-image [label]="getConditionIcon(condition)"></app-image>
    <div>{{ 'FILTER.FilterCondition.' + condition.toLowerCase() | translate }}</div>
  </div>
</mat-menu>
