import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { ButtonSliderComponent } from './button-slider.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [CommonModule, MatButtonModule, MatInputModule, MatIconModule, MatFormFieldModule, FormsModule],
  declarations: [ButtonSliderComponent],
  exports: [ButtonSliderComponent],
})
export class ButtonSliderModule {}
