import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { SaxmsTooltipModule } from '@app-modeleditor/components/tooltip/tooltip.module';
import { TranslateModule } from '@ngx-translate/core';
import { LastVisitedContentComponent } from './last-visited-content.component';

@NgModule({
  imports: [
    CommonModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    TranslateModule,
    MatDividerModule,
    SaxmsTooltipModule,
  ],
  declarations: [LastVisitedContentComponent],
  exports: [LastVisitedContentComponent],
})
export class LastVisitedContentModule {}
