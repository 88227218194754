import { BestGantt, EGanttInstance } from '@gantt/public-api';
import { GanttLibService } from 'frontend/src/dashboard/gantt/gantt/gantt-lib.service';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';

/**
 * Wrapper for print plugin.
 * Prepares and executes BestGantt for print view.
 */
export class GeneralGanttPrintHandler {
  private printerExecuter;
  public isActive = false;

  constructor(private _ganttLibService: GanttLibService) {
    this.printerExecuter = this._ganttLibService.ganttInstanceService.getInstance(EGanttInstance.PRINTER);
    this.printerExecuter.initPlugIn(this._ganttLibService.bestGantt);
    this.printerExecuter.changeStrategy(
      this._ganttLibService.ganttInstanceService.getInstance(EGanttInstance.PRINT_VIEW_STRATEGY_LW)
    );
  }

  /**
   * Sets gantt to print view.
   */
  public prepareForPrint(): void {
    this.printerExecuter.printViewTransformation();
    of(null)
      .pipe(delay(0))
      .subscribe(() => {
        this.isActive = true;
      });
  }

  public getPrintGantt(): BestGantt {
    return this.printerExecuter.ganttDiagram;
  }
}
