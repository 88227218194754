import { Injectable } from '@angular/core';
import { UiAction } from 'frontend/src/dashboard/model-editor/ui/service/ui-action.enum';
import { Template } from 'frontend/src/dashboard/model/resource/template';
import { Logger } from 'frontend/src/dashboard/shared/utils/logger';
import { Condition } from '../template-actions/template-condition';
import { SharedUiService } from './../../../model-editor/ui/service/shared.ui.service';
import { TemplateService } from './../../utils/template.service';
import { Action } from './action/action';
import { IParameter } from './action/parameter.interface';
import { EValidationRules } from './action/validation-rules.enum';

@Injectable({
  providedIn: 'root',
})
export class TemplateActionService {
  private readonly logger = new Logger();

  constructor(private templateApi: TemplateService, private uiService: SharedUiService) {}

  validate(action: Action): number | string {
    const cond: Condition = new Condition(action.getLocalCondition());

    const d: Template[] = this.templateApi.getTemplates(action.getResourceId() || undefined);

    const valid: number = cond.validate(d);
    if (valid !== -1) {
      this.uiService.trigger({ type: UiAction.MARK_CONDITION, data: cond.keys });
      if (action.getLocalConditionErrorMessage(valid)) {
        return action.getLocalConditionErrorMessage(valid);
      } else if (!action.getLocalConditionErrorMessage(valid)) {
        this.logger.name = 'condition';
        this.logger.error<unknown>('no localConditionErrorMsg for the local condition', action);
      }
      return cond.getError();
    }

    return -1; // is okay;
  }

  checkAction(action: Action): number | string {
    if (!action.getLocalCondition()) {
      return -1;
    }

    // check local conditions
    return this.validate(action);
  }

  public checkActions(actions: Action[]): boolean {
    return (actions || []).find((action: Action) => this.checkSingleAction(action) === false) ? false : true;
  }

  private checkSingleAction(action: Action): boolean {
    if (action.getParameterSelectors()) {
      try {
        const url: string = this.getUrlFromParameterSelectors(
          action.getActionUrl(),
          action.getResourceId(),
          action.getParameterSelectors(),
          true,
          action.getValidationRules()
        );
      } catch (e) {
        return false;
      }
    }
    if (action.getPostParameterSelector()) {
      try {
        const param = this.getPostParameter(action, true);
      } catch (e) {
        return false;
      }
    }

    return true;
  }

  private splitCondition(condition: string): string[] {
    return condition.split(/[\.\[\]]/).filter((item) => item !== '');
  }

  /**
   * gets a single value from any element described by its selection string
   * @param {string} selector - seletion string
   * @param {string} [resourceId] - id of the resource to specify search results
   * @param {EValidationRules[]} [validationRules] - list of rules to improve selection
   * @returns unknown
   */
  public getSingleValueFromSelector<T>(
    selector: string,
    resourceId: string = undefined,
    validationRules: EValidationRules[] = []
  ): T {
    const templateId: string = this._getTemplateId(selector);
    const condition: string[] = this._getCondition(selector);
    const t: Template = this.getTemplateById(templateId, resourceId);

    try {
      return this.getElementAttr(t, condition, validationRules, false) as T;
    } catch (e) {
      // checks whether invalid selectors are allowed or not
      if (this._hasRule(validationRules, EValidationRules.NO_INVALID_SELECTOR)) {
        throw e;
      }
    }
  }

  /**
   * generates url from parameters given by different parameter
   * @param url string url to be parsed
   * @param parameterSelectors IParameter parameter selectors
   * @param validate boolean if its only for validation purposes
   * @param validationRules EValidationRules[] defines a set of rules applied to validation
   */
  public getUrlFromParameterSelectors(
    url: string,
    resourceId: string,
    parameterSelectors: IParameter,
    validate = false,
    validationRules: EValidationRules[] = []
  ): string {
    let newUrl = url;

    for (const key in parameterSelectors) {
      if (url.indexOf(key) === -1) {
        break;
      }

      const templateId: string = this._getTemplateId(parameterSelectors[key]);
      const condition: string[] = this._getCondition(parameterSelectors[key]);
      const t: Template = this.getTemplateById(templateId, resourceId);

      try {
        const value: any = this.getElementAttr(t, condition, validationRules, validate);
        if (!validate) {
          this.logger.name = 'parameter-selector';
          this.logger.info<unknown>(t, condition, url, this.templateApi.getRegistered(), value, templateId, resourceId);
        }
        newUrl = newUrl.replace(key, encodeURIComponent(value));
      } catch (e) {
        if (!validate && parameterSelectors[key] !== '') {
          newUrl = newUrl.replace(key, '');
        }

        // checks whether invalid selectors are allowed or not
        if (this._hasRule(validationRules, EValidationRules.NO_INVALID_SELECTOR)) {
          throw e;
        }
      }
    }
    return newUrl;
  }

  private _getTemplateId(str: string): string {
    if (!str) {
      return null;
    }
    const match: string[] = str.split(/[\[\]]/);
    return match?.length > 0 ? match[1] : null;
  }

  private _getCondition(str: string): string[] {
    if (!str) {
      return null;
    }
    return str
      .substring(str.indexOf(']') + 1, str.length)
      .split(/[\.\[\]]/)
      .filter((item) => item !== '');
  }

  private getTemplateById(templateId: string, resourceId: string): Template {
    return this.templateApi.getElementById(templateId, resourceId);
  }

  public resolvePostParameter(
    selector: string,
    resourceId: string,
    rules: EValidationRules[] = [],
    validate = false
  ): Record<string, any> | null {
    if (!selector) {
      return null;
    }
    const templateId: string = this._getTemplateId(selector);
    const condition: string[] = this._getCondition(selector);

    const t: Template = this.getTemplateById(templateId, resourceId);
    const r: any = this.getElementAttr(t, condition, rules, validate);
    return r;
  }

  public getPostParameter(action: Action, validate?: boolean): any {
    const templateId: string = this._getTemplateId(action.getPostParameterSelector());
    const condition: string[] = this._getCondition(action.getPostParameterSelector());

    const t: Template = this.getTemplateById(templateId, action.getResourceId());
    const r: any = this.getElementAttr(t, condition, action.getValidationRules(), validate);
    return r;
  }

  private _hasRule(validationRules: EValidationRules[], rule: EValidationRules): boolean {
    return (validationRules || []).find((r: EValidationRules) => r === rule) ? true : false;
  }

  /**
   * validates element by given validation rules
   * @param el any
   * @param action Action
   * @param {boolean} final wether its the last check in attributes list or not
   * @returns boolean
   */
  private _validateByRule(el: any, validationRules: EValidationRules[], final = false): boolean {
    if (el === undefined || el === null) {
      if (this._hasRule(validationRules, EValidationRules.NOT_NULL)) {
        return false;
      }
      return true;
    }

    if (el instanceof ArrayBuffer) {
      return true;
    }

    if (Array.isArray(el)) {
      if (final && el.length !== 1 && this._hasRule(validationRules, EValidationRules.EXACTLY_ONE)) {
        return false;
      }

      if (el.length === 0 && this._hasRule(validationRules, EValidationRules.NO_EMPTY_ARRAYS)) {
        return false;
      }
    }

    if (typeof el === 'object') {
      const size = Object.keys(el).length;
      if (final && size !== 1 && this._hasRule(validationRules, EValidationRules.EXACTLY_ONE)) {
        return false;
      }

      if (size === 0 && this._hasRule(validationRules, EValidationRules.NO_EMPTY_OBJECTS)) {
        return false;
      }
    }

    return true;
  }

  private getElementAttr(element: any, attrList: string[], validationRules?: EValidationRules[], validate?: boolean) {
    while (attrList.length > 0) {
      const attr: string = attrList.shift();
      if (!attr || attr === '') {
        continue;
      }

      const arraySelectorStart: number = attr.indexOf('[');
      const arraySelectorEnd: number = attr.indexOf(']');
      if (arraySelectorStart !== -1 && arraySelectorEnd !== -1) {
        element =
          element[attr.substr(0, arraySelectorStart)][
            attr.substr(arraySelectorStart + 1, arraySelectorEnd - arraySelectorStart - 1)
          ];
        continue;
      }

      if (attr === '*') {
        if (!element && validate) {
          throw new Error(`cannot access property "${attr}"`);
        }
        element = element.map((el) => {
          const attributesList: string[] = attrList.slice();
          return this.getElementAttr(el, attributesList, validationRules, validate);
        });
        attrList = [];
      } else {
        if (validationRules?.length > 0) {
          if (!element) {
            throw new Error(`[template-condition] element is null, can't access "${attr}"`);
          }

          if (!this._validateByRule(element[attr], validationRules, attrList.length === 0)) {
            throw new Error(`[template-condition] cant't access property "${attr}" of element`);
          }
        }
        if (!element) {
          return null;
        }

        element = element[attr];
      }
    }
    return element;
  }
}

