import { Action } from '@app-modeleditor/components/button/action/action';
import { EActionType } from '@app-modeleditor/components/button/action/action-type.enum';
import { Button } from '@app-modeleditor/components/button/button';
import { EButtonDisplayType } from '@app-modeleditor/components/button/button-display-type.enum';
import { EMenuMode } from '@app-modeleditor/components/content/content-element/menu-mode.enum';
import { ContextMenuItem } from '@app-modeleditor/components/contextmenu/context-menu-item';
import { ContextMenu } from '@app-modeleditor/components/contextmenu/contextmenu';
import { EntryElement } from '@app-modeleditor/components/entry-collection/entry-element';
import { MenuItem } from 'frontend/src/dashboard/view/template-toolbar/menu-item';
import { Toolbar } from 'frontend/src/dashboard/view/template-toolbar/toolbar';
import { ToolbarGroup } from 'frontend/src/dashboard/view/template-toolbar/toolbar-group';
import { EToolbarItemType } from 'frontend/src/dashboard/view/template-toolbar/toolbar-item-type';
import { IToolbarOptions } from 'frontend/src/dashboard/view/template-toolbar/toolbar-options.interface';
import { of } from 'rxjs';
import { ChartsComponent } from '../chart.component';
import { ExploreToolbar } from './explore-toolbar';
import { LegendQuickSearchToolbar } from './legend-quicksearch-toolbar';
import { ViewToolbar } from './view-toolbar';

export class ChartToolbar extends Toolbar {
  private legendQuickSearch: LegendQuickSearchToolbar;

  constructor(options: IToolbarOptions, private scope: ChartsComponent) {
    super(options);
    this.setParentId(this.scope.getChartId());
    this.setMenuMode(EMenuMode.SHOW).disableShortcuts(false).setClass('saxms-submenu');
    this.build();
  }

  build(compareDisabled = false): void {
    const view: MenuItem = new ViewToolbar(this.scope, compareDisabled).get(this);
    const explore: MenuItem = new ExploreToolbar(this.scope).get(this);
    const settings: MenuItem = new MenuItem()
      .setName('Pending')
      .setIcon('GEAR')
      .setToolbarItemType(EToolbarItemType.NAVIGATOR)
      .setIndex(-100)
      .setNavigationElement(
        new Button()
          .setName('settings')
          .setDisplayType(EButtonDisplayType.ICON_ONLY)
          .setMenu(
            new ContextMenu().setContextMenuItems([
              new ContextMenuItem()
                .setName('BUTTON.toggle_sticky')
                .setIcon('PIN')
                .chainActions(
                  new Action(() => {
                    const part = this.scope.templateService.getElementById(this.scope.widget.parentId);
                    part.setSticky(!part.isSticky());
                    this.scope.contentService.check();
                    return of(null);
                  })
                ),
            ])
          )
      );
    this.legendQuickSearch = new LegendQuickSearchToolbar(this.scope);

    let items = [this.legendQuickSearch.get(this)];
    items = items.concat(
      view,
      explore,
      ...this.predefinedElements(compareDisabled),
      ...this.getAdditionalMenus(),
      settings
    );

    this.setMenuItems(items).setActiveMenuItem(view);
  }

  public disabledCompare(disabled = true): void {
    this.build(disabled);
  }

  public setToggleAction(item: MenuItem): MenuItem {
    return item
      .setDisplayType(EButtonDisplayType.ICON_AND_LABEL)
      .setToolbarItemType(EToolbarItemType.GROUP)
      .chainActions(new Action().setActionType(EActionType.LOCAL).setCb(() => of(this.toggleActiveMenuItem(item))));
  }

  public createEntryElement<T extends EntryElement>(element: T, predefined?: boolean): T {
    if (
      element instanceof Button &&
      !element.getUpdateElementIds().find((id: string) => id === this.scope.widget.id) &&
      !predefined
    ) {
      // console.warn(`[SPREADSHEET] the button has no updateElementId for its own template id`, element);
      element.setUpdateElementIds(element.getUpdateElementIds().concat(this.scope.widget.id));
    }

    return element; // .setContextmenu(this.addContextMenu(element));
  }

  private getAdditionalMenus(): MenuItem[] {
    return (this.scope.widget.additionalSubMenus || []).map((menu) => {
      const item = this.setToggleAction(new MenuItem().setName(menu.name).setId(menu.id));
      item.setToolbarGroups(
        menu.subMenuGroups.map((group) => {
          const g: ToolbarGroup = new ToolbarGroup().setName(group.name);
          // set entry elements
          const entries: EntryElement[] = group.entryElements.map((entry) => {
            const e: EntryElement = this.createEntryElement(this.scope.templateAdapter.adapt(entry));
            // if it is a button, set display type
            if (e instanceof Button) {
              e.setDisplayType(EButtonDisplayType.ICON_WITH_LABEL_BELOW).setEnableBy(() => {
                return e.isAlwaysEnabled() || this.scope.templateActionService.checkActions(e.getChain().getActions());
              });
            }
            return e;
          });

          g.setEntryElements(entries);
          return g;
        })
      );

      if (menu.defaultSelected) {
        this.setActiveMenuItem(item);
      }
      return item;
    });
  }

  private predefinedElements(compareDisabled = false): MenuItem[] {
    const contextItems: ContextMenuItem[] = this.scope.compareChartsInformations.map((info) =>
      new ContextMenuItem()
        .setName(info.name)
        .chainActions(new Action().setCb(() => of(this.scope.addExperimentToCompare(info))))
    );

    const items: MenuItem[] = [];

    if (!compareDisabled) {
      const cmpExp = new MenuItem().setToolbarItemType(EToolbarItemType.NAVIGATOR).setNavigationElement(
        new Button()
          .setEnableBy(() => this.scope.compareChartsInformations.length > 0 && this.scope.toRenderCharts.length < 2)
          .setName('EXPERIMENT.compare')
          .setDisplayType(EButtonDisplayType.ICON_WITH_LABEL_BELOW)
          .setIcon('add')
          .setMenu(new ContextMenu().setContextMenuItems(contextItems))
      );
      items.push(cmpExp);

      items.push(
        new MenuItem().setToolbarItemType(EToolbarItemType.NAVIGATOR).setNavigationElement(
          new Button()
            .setEnableBy(() => this.scope.toRenderCharts.length === 2)
            .setName('EXPERIMENT.compareOff')
            .setDisplayType(EButtonDisplayType.ICON_WITH_LABEL_BELOW)
            .setIcon('delete')
            .chainActions(new Action().setCb(() => of(this.scope.removeCompareExperiment())))
        )
      );
    }
    return items;
  }

  getLegendQuickSearch(): LegendQuickSearchToolbar {
    return this.legendQuickSearch;
  }
}
