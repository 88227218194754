import { BestGantt } from '../../../main';

/**
 * Base class defining all methods a class must have to visualize shift edit restrictions.
 * @template D Type of data the shift edit restriction visualizer needs for visualization.
 */
export class GanttShiftEditRestrictionVisualizer<D = undefined> {
  protected _ganttDiagram: BestGantt;

  constructor(ganttDiagram: BestGantt) {
    this._ganttDiagram = ganttDiagram;
  }

  /**
   * Visualizes the blocking of a shift edit action.
   * @param data Data the shift edit restriction visualizer needs to visualize.
   */
  public block(data: D): void {
    return;
  }

  /**
   * Visualizes the unblocking of a shift edit action.
   * @param data Data the shift edit restriction visualizer needs to visualize.
   */
  public unblock(data: D): void {
    return;
  }
}
