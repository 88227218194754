import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { ContentModule } from '@app-modeleditor/components/content/content.module';
import { DialogComponent } from '@app-modeleditor/components/dialog/dialog.component';
import { ElementsModule } from '@app-modeleditor/components/elements/elements.module';
import { HotspotWrapperModule } from '@app-modeleditor/components/hotspot/hotspot-wrapper.module';
import { ImageModule } from '@app-modeleditor/components/image/image.module';
import { LayoutModule } from '@app-modeleditor/components/layout/layout.module';
import { PageComponent } from '@app-modeleditor/components/page/page.component';
import { TemplateOverlay } from '@app-modeleditor/components/template-overlay/template-overlay.component';
import { TemplateComponent } from '@app-modeleditor/components/template/template.component';
import { TreeComponent } from '@app-modeleditor/components/tree/tree.component';
import { TemplateOverlayService } from '@app-modeleditor/components/tree/tree.template-overlay.service';
import { ModeleditorComponent } from '@app-modeleditor/modeleditor.component';
import { SaxmsSidebarModule } from '@app-modules/saxms-sidebar/saxms-sidebar.module';
// SaxmsModules
import { AngularSplitModule } from 'angular-split';
import { FilterModule } from 'frontend/src/dashboard/core/filter/filter.module';
import { SaxMsGanttModule } from 'frontend/src/dashboard/gantt/gantt/saxms-best-gantt.module';
import { GanttGeneralModule } from 'frontend/src/dashboard/gantt/general/gantt.general.module';
import { PasswordChangeModule } from 'frontend/src/dashboard/password/passwordChange/password-change.module';
import { SelectorModule as SpecialSelectorModule } from 'frontend/src/dashboard/view/navbar/selectors/selector.module';
import { ErrorlogModule } from 'frontend/src/dashboard/view/options/errorlog/errorlog.module';
import { TemplateToolbarModule } from 'frontend/src/dashboard/view/template-toolbar/template-toolbar.module';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { NodeNetworkModule } from '../node-network/node-network.module';
import { TimeDurationPickerModule } from '../shared/duration-picker/time-duration-picker-module';
import { MySharedModule } from '../shared/shared.module';
import { TemplateLoaderComponent } from '../template/feature/template-loader/template-loader.component';
import { TemplateButtonModule } from './components/button/button.module';
import { CalendarModule } from './components/calendar/calendar.module';
import { ColumnListSelectorModule } from './components/column-list-selector/column-list-selector.module';
import { ContextMenuModule } from './components/contextmenu/contextmenu.module';
import { DragNDropModule } from './components/drag-n-drop/drag-n-drop.module';
import { EntryCollectionModule } from './components/entry-collection/entry-collection.module';
import { TemplateEntryElementModule } from './components/entry-collection/entry-element/entry-element.module';
import { FileUploaderModule as NewFileUploaderModule } from './components/file-uploader/file-uploader.module';
import { FileuploaderModule } from './components/fileuploader-old/fileuploader.module';
import { GeneralWidgetComponent } from './components/general-widget/general-widget.component';
import { IndexRegisterModule } from './components/index-register/index-register.module';
import { LightboxModule } from './components/lightbox/lightbox.module';
import { WizardModule } from './components/lightbox/predefined/wizard/wizard.module';
import { ListSelectorModule } from './components/list-selector/list-selector.module';
import { LocationMapModule } from './components/location-map/location-map.module';
import { MenuItemModule } from './components/menu-item/menu-item.module';
import { OperatorSelectorModule } from './components/operator-selector/operator-selector.module';
import { PrintModule } from './components/print/print.module';
import { RangePickerModule } from './components/range-picker/calendar.module';
import { SelectorModule } from './components/selector/selector.module';
import { FullSpreadsheetModule } from './components/spreadsheet/full-spreadsheet/full-spreadsheet.module';
import { StackableModule } from './components/stackable/stackable.module';
import { TemplateBackdropComponent } from './components/template-backdrop/template-backdrop.component';
import { TemplateChatModule } from './components/template-chat/template-chat.module';
import { TemplateDatepickerModule } from './components/template-datepicker/template-datepicker.module';
import { TemplateHeadlineModule } from './components/template-headline/template-headline.module';
import { TemplateImageModule } from './components/template-image/template-image.module';
import { TemplateProgressbarModule } from './components/template-progressbar/template-progressbar.module';
import { TemplateStatebarModule } from './components/template-statebar/template-statebar.module';
import { TemplateUiModule } from './components/template-ui/template-ui.module';
import { TemplateUsersettingsModule } from './components/template-usersettings/template-usersettings.module';
import { TemplateWidgetComponent } from './components/template-widget/template-widget.component';
import { TemplateWidgetContainerComponent } from './components/template-widgetcontainer/template-widgetcontainer.component';
import { SlotModule } from './components/template/slot/slot.module';
import { SaxmsTooltipModule } from './components/tooltip/tooltip.module';
import { EditmodeBarModule } from './components/tree/editmode-bar/editmode-bar.module';
import { LastVisitedContentModule } from './components/tree/last-visited-content/last-visited-content.module';
import { CreateModalModule } from './components/tree/modal/create-modal/create-modal.module';
import { TemplateTreeModule } from './components/tree/template-tree/tree.module';
import { TreeNodeModule } from './components/tree/tree-node/tree-node.module';
import { TreeSecondColumnModule } from './components/tree/tree-second-column/tree-second-column.module';
import { ChartAttributeLegendModule } from './components/widget-modules/chart-module/chart/chart-legend/chart-legend-attribute/chart-legend-attribute.module';
import { ChartLegendDatasetComponent } from './components/widget-modules/chart-module/chart/chart-legend/chart-legend-dataset/chart-legend-dataset.component';
import { WidgetsModule } from './components/widget-modules/widgets.module';

@NgModule({
  imports: [
    CalendarModule,
    CommonModule,
    MatButtonModule,
    MatBadgeModule,
    TemplateUiModule,
    NgScrollbarModule,
    IndexRegisterModule,
    MySharedModule,
    TemplateButtonModule,
    LightboxModule,
    TemplateEntryElementModule,
    // GridModule,
    TemplateChatModule,
    TemplateHeadlineModule,
    MenuItemModule,
    WizardModule,
    PrintModule,
    ImageModule,
    ListSelectorModule,
    SaxmsTooltipModule,
    ColumnListSelectorModule,
    SaxMsGanttModule,
    // RouterModule.forChild(routes)
    FileuploaderModule,
    EditmodeBarModule,
    TimeDurationPickerModule,
    ContentModule,
    ElementsModule,
    LastVisitedContentModule,
    NodeNetworkModule,
    FullSpreadsheetModule,
    GanttGeneralModule,
    LayoutModule,
    AngularSplitModule,
    SaxmsSidebarModule,
    FilterModule,
    TemplateImageModule,
    OperatorSelectorModule,
    TemplateDatepickerModule,
    TemplateProgressbarModule,
    TreeSecondColumnModule,
    TemplateTreeModule,
    SelectorModule,
    EntryCollectionModule,
    WidgetsModule,
    StackableModule,
    CreateModalModule,
    TemplateStatebarModule,
    LocationMapModule,
    NewFileUploaderModule,
    TemplateToolbarModule,
    SpecialSelectorModule,
    ContextMenuModule,
    RangePickerModule,
    DragNDropModule,
    TreeNodeModule,
    ChartAttributeLegendModule,
    HotspotWrapperModule,
    SlotModule,
    PasswordChangeModule,
    ErrorlogModule,
    TemplateUsersettingsModule,
    TemplateLoaderComponent,
  ],
  declarations: [
    ModeleditorComponent,
    TreeComponent,
    DialogComponent,
    TemplateComponent,
    TemplateOverlay,
    PageComponent,
    TemplateBackdropComponent,
    TemplateWidgetContainerComponent,
    TemplateWidgetComponent,
    GeneralWidgetComponent,
    ChartLegendDatasetComponent,
  ],
  exports: [
    ModeleditorComponent,
    TemplateComponent,
    WidgetsModule,
    TemplateWidgetContainerComponent,
    TemplateWidgetComponent,
    GeneralWidgetComponent,
  ],
  providers: [TemplateOverlayService],
})
export class ModelEditorModule {}
