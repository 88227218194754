import { EntryElementValue } from './../../entry-collection/entry-element-value';

export class PieChartEntry extends EntryElementValue {
  private descriptions: EntryElementValue[];
  private legendValues: EntryElementValue[];

  constructor() {
    super();
  }

  public getDescriptions(): EntryElementValue[] {
    return this.descriptions || [];
  }

  public setDescriptions(descriptions: EntryElementValue[]): this {
    this.descriptions = descriptions;
    return this;
  }

  public getLegendValues(): EntryElementValue[] {
    return this.legendValues || [];
  }

  public setLegendValues(legendValues: EntryElementValue[]): this {
    this.legendValues = legendValues;
    return this;
  }
}
