import { Injectable } from '@angular/core';
import { TemplateUiService } from '@app-modeleditor/components/template-ui/template.service';
import { TemplateService } from '@app-modeleditor/utils/template.service';
import { Template } from 'frontend/src/dashboard/model/resource/template';
import { Content } from '../content/content';
import { ContentElement } from '../content/content-element/content-element';
import { ContentPart } from '../content/content-part/content-part';
import { EntryElement } from '../entry-collection/entry-element';
import { IndexPage } from '../index-register/index-page';
import { SavedRecord } from '../template-ui/saved-record';
import { EntryCollection } from './../entry-collection/entry-collection';
import { IndexRegister } from './../index-register/index-register';

@Injectable({
  providedIn: 'root',
})
export class TemplateUnregisterService {
  constructor(private $templateUiApi: TemplateUiService, private $templateApi: TemplateService) {}

  /**
   * unregister indexregister recursivley
   * @param {IndexRegister} register indexregister
   * @param {string} resourceId id of the resource
   * @param {boolean} force whether to force unregistration or not
   * @returns void
   */
  private $unregisterIndexRegister(register: IndexRegister, resourceId: string) {
    register.getIndexPages().forEach((page: IndexPage) => {
      this.$unregisterIndexRegisterPage(page, resourceId, true);
    });
    this.$unregisterAll(register, resourceId, true);
  }

  /**
   * unregister indexregister recursivley
   * @param {IndexPage} page indexpage
   * @param {string} resourceId id of the resource
   * @param {boolean} force whether to force unregistration or not
   * @returns void
   */
  private $unregisterIndexRegisterPage(page: IndexPage, resourceId: string, force = false) {
    if (!page.getContent() || !page.getContent().getContentParts()) {
      return;
    }

    page
      .getContent()
      .getContentParts()
      .forEach((contentPart: ContentPart) => {
        this.unregister(contentPart, resourceId, force);
      });

    this.$unregisterAll(page, resourceId, force);
  }

  /**
   * unregister template recursivley
   * @param {Template} template template
   * @param {string} resourceId id of the resource
   * @param {boolean} force whether to force unregistration or not
   * @returns void
   */
  private $unregisterAll(template: Template, resourceId: string, force: boolean) {
    if (!template) {
      return;
    }

    if (force || template?.isPreventUnregister() === false) {
      this.$templateApi.unregisterTemplate(template.getId(), resourceId);
    }

    if (
      force ||
      (template && !this.$templateUiApi.getMarked().find((m: SavedRecord) => m.getTemplateId() === template.getId()))
    ) {
      this.$templateUiApi.unmarkFromSave(template.getRestUrl() || template.getId());
    }
  }

  /**
   * unregister entrycollection recursivley
   * @param {EntryCollection} ce entrycollection
   * @param {string} resourceId id of the resource
   * @param {boolean} force whether to force unregistration or not
   * @returns void
   */
  private $unregisterEntryCollection(ce: EntryCollection, resourceId: string, force: boolean): void {
    ce.getEntryElements().forEach((entryElement: EntryElement) => {
      this.$unregisterEntryElement(entryElement, resourceId, force);
    });
    this.$unregisterAll(ce, resourceId, force);
  }

  /**
   * unregister contentpart recursivley
   * @param {ContentPart} contentPart contentpart
   * @param {string} resourceId id of the resource
   * @param {boolean} force whether to force unregistration or not
   * @returns void
   */
  private $unregisterContentPart(contentPart: ContentPart, resourceId: string, force = false): void {
    if (contentPart.getContentElements()) {
      contentPart.getContentElements().forEach((contentElement: ContentElement) => {
        this.unregister(contentElement, resourceId, force);
      });
    }
    this.$unregisterAll(contentPart, resourceId, force);
  }

  /**
   * unregister content recursivley
   * @param {Content} content content
   * @param {string} resourceId id of the resource
   * @param {boolean} force whether to force unregistration or not
   * @returns void
   */
  private $unregisterContent(content: Content, resourceId: string): void {
    content?.getContentParts().forEach((contentPart: ContentPart) => {
      this.unregister(contentPart, resourceId, true);
    });
    this.$unregisterAll(content, resourceId, true);
  }

  /**
   * unregister a single entry element
   * @param {EntryElement} entryElement entry-element
   * @param {string} resourceId id of the resource
   * @param {boolean} force whether to force unregistration or not
   * @returns void
   */
  private $unregisterEntryElement(entryElement: EntryElement, resourceId: string, force: boolean): void {
    this.$unregisterAll(entryElement, resourceId, force);
  }

  /**
   * unregister template recursivley
   * @param {Template} template template
   * @param {string} resourceId id of the resource
   * @param {boolean} force whether to force unregistration or not
   * @returns void
   */
  public unregister(template: Template, resourceId: string, force = false): void {
    if (template instanceof IndexRegister) {
      this.$unregisterIndexRegister(template, resourceId);
    } else if (template instanceof Content) {
      this.$unregisterContent(template, resourceId);
    } else if (template instanceof ContentPart) {
      this.$unregisterContentPart(template, resourceId, force);
    } else if (template instanceof EntryCollection) {
      this.$unregisterEntryCollection(template, resourceId, force);
    } else if (template instanceof EntryElement) {
      this.$unregisterEntryElement(template, resourceId, force);
    } else if (template instanceof IndexPage) {
      this.$unregisterIndexRegisterPage(template, resourceId, force);
    } else {
      this.$unregisterAll(template, resourceId, force);
    }
  }
}
