import { takeUntil } from 'rxjs/operators';
import { BestGantt } from '../../main';
import { GanttShiftMouseHoverHandler } from '../shift-mouse-hover/shift-mouse-hover-handler';

export class MainShiftMouseHoverCallbacks {
  private _callbackHandler: GanttShiftMouseHoverHandler;

  constructor(private _ganttDiagram: BestGantt) {
    this._callbackHandler = new GanttShiftMouseHoverHandler(this._ganttDiagram);
  }

  /**
   * Adds mouse hover callbacks.
   */
  public initCallbacks(): void {
    this._ganttDiagram
      .getShiftFacade()
      .shiftMouseOver()
      .pipe(takeUntil(this._ganttDiagram.onDestroy))
      .subscribe((event) => this._callbackHandler.xAxisDateMarkers(event));

    this._ganttDiagram
      .getShiftFacade()
      .shiftMouseOver()
      .pipe(takeUntil(this._ganttDiagram.onDestroy))
      .subscribe(() => this._callbackHandler.deHighlightXAxis());

    this._ganttDiagram
      .getShiftFacade()
      .shiftMouseOver()
      .pipe(takeUntil(this._ganttDiagram.onDestroy))
      .subscribe((event) => this._callbackHandler.markDueDatesMouseOver(event));
    this._ganttDiagram
      .getShiftFacade()
      .shiftMouseOut()
      .pipe(takeUntil(this._ganttDiagram.onDestroy))
      .subscribe(() => this._ganttDiagram.removeDueDateMarks());

    this._ganttDiagram
      .getShiftFacade()
      .shiftMouseOver()
      .pipe(takeUntil(this._ganttDiagram.onDestroy))
      .subscribe((event) => this._callbackHandler.highlightShiftOnHover(event));
    this._ganttDiagram
      .getShiftFacade()
      .shiftMouseOut()
      .pipe(takeUntil(this._ganttDiagram.onDestroy))
      .subscribe(() => this._callbackHandler.removeHighlightShiftOnHover());

    this._ganttDiagram
      .getShiftFacade()
      .shiftMouseOut()
      .pipe(takeUntil(this._ganttDiagram.onDestroy))
      .subscribe(() => this._ganttDiagram.getXAxisBuilder().removeAllDateMarkers());

    this._ganttDiagram
      .getShiftFacade()
      .shiftMouseOut()
      .pipe(takeUntil(this._ganttDiagram.onDestroy))
      .subscribe(() => this._ganttDiagram.getXAxisBuilder().highlightXAxis());
  }
}
