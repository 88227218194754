import { ContentElement } from '@app-modeleditor/components/content/content-element/content-element';
import { ChartLegendItem } from '../chart-legend-item';
import { EChartLegendType } from '../chart-legend-type.enum';

export class ChartLegendTemplate extends ContentElement {
  private legendValues: ChartLegendItem[];
  private chartLegendType: EChartLegendType;
  private changeCB: (data: ChartLegendItem) => void;
  private removeAllCB: () => void;
  private allAllCB: () => void;
  private height: number;

  public getHeight(): number {
    return this.height;
  }

  public setHeight(height: number): ChartLegendTemplate {
    this.height = height;
    return this;
  }

  constructor() {
    super();
  }

  public onRemoveAll(cb: () => void): this {
    this.removeAllCB = cb;
    return this;
  }

  public executeRemoveAll(): void {
    this.removeAllCB();
  }
  public onAddAll(cb: () => void): this {
    this.allAllCB = cb;
    return this;
  }

  public executeAddAll(): void {
    this.allAllCB();
  }

  public onChange(cb: (item: ChartLegendItem) => void): this {
    this.changeCB = cb;
    return this;
  }

  public executeChanges(item: ChartLegendItem): void {
    this.changeCB(item);
  }

  public setChartLegendType(chartLegendType: EChartLegendType): this {
    this.chartLegendType = chartLegendType;
    return this;
  }

  public getChartLegendType(): EChartLegendType {
    return this.chartLegendType;
  }

  public setLegendValues(legendValues: ChartLegendItem[]): this {
    this.legendValues = legendValues;
    return this;
  }

  public updateLegendValues(legendValues: ChartLegendItem[]): this {
    legendValues.map((newValue) => {
      const saveOldItem = this.getLegendValues().find((oldValue) => oldValue.getId() === newValue.getId());
      if (saveOldItem) {
        newValue.setActive(saveOldItem.isActive());
        newValue.setExpanded(saveOldItem.isExpanded());
      }
      return newValue;
    });
    // let temp = this.getLegendValues();
    // const deleteValue = [];
    // this.getLegendValues().forEach((value, index) => {
    //     const foundIndex: number = legendValues.findIndex(newValue => newValue.getId() === value.getId());
    //     if (foundIndex !== -1) {
    //         legendValues.splice(foundIndex, 1);
    //     } else {
    //         deleteValue.push(value);
    //     }
    // });
    // deleteValue.forEach((value) => {
    //     temp = temp.filter(oldValue => oldValue.getId() !== value.getId());

    // })
    this.setLegendValues(legendValues);
    return this;
  }

  public getLegendValues(): ChartLegendItem[] {
    return this.legendValues || [];
  }
}
