<h3 class="nn-legend-headline" (click)="toggleLegend()">
  <mat-icon>{{ showLegend ? 'keyboard_arrow_down' : 'keyboard_arrow_right' }}</mat-icon>
  <span>{{ headline | translate | capitalize }}</span>
</h3>
<div class="nn-legend-wrapper" *ngIf="showLegend">
  <ng-container *ngTemplateOutlet="nnLegendContent"></ng-container>
</div>
<ng-template #nnLegendContent>
  <ng-content select=".content"></ng-content>
</ng-template>
