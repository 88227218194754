import { YAxisDataFinder } from '../data-handler/data-finder/yaxis-data-finder';
import { DataHandler } from '../data-handler/data-handler';
import { GanttCanvasRow } from '../data-handler/data-structure/data-structure';
import { EGanttScrollContainer } from '../html-structure/scroll-container.enum';
import { BestGantt } from '../main';

/**
 * Helper class for {@link RenderDataHandler} to store various data related to the current render data.
 */
export class RenderDataStateStorage {
  private _yPositionRowStorage: Map<string, number> = new Map<string, number>();
  private _yPositionShiftStorage: Map<string, number> = new Map<string, number>();
  private _stickyRowStorage: Set<string> = new Set<string>();
  private _notStickyRowStorage: Set<string> = new Set<string>();
  private _stickyRowShiftStorage: Set<string> = new Set<string>();
  private _notStickyRowShiftStorage: Set<string> = new Set<string>();

  /**
   * @param _ganttDiagram Reference to the {@link BestGantt} instance that should be used for calculations.
   */
  constructor(private _ganttDiagram: BestGantt) {}

  //
  // Y POSITION ROW STORAGE
  //

  /**
   * Sets the specified y position for the row with the specified id.
   * @param rowId Id of the row to set the y position for.
   * @param yPosition Y position of the row with the specified id.
   */
  public setYPositionRow(rowId: string, yPosition: number): void {
    this._yPositionRowStorage.set(rowId, yPosition);
  }

  /**
   * Determines the y position of the row with the specified id.
   * @param rowId Id of the row to determine the y position for.
   * @returns Y position of the row with the specified id.
   */
  public getYPositionRow(rowId: string): number {
    return this._yPositionRowStorage.get(rowId);
  }

  /**
   * Determines the id of the row which is at the specified y position in the specified scroll container.
   * @param yPosition Y position to determine the row for.
   * @param scrollContainerId Id of the scroll container to determine the row on the specified y position for.
   * @returns Id of the row at the specified y position in the specified scroll container (or `undefined` if no row was found at the specified y position).
   */
  public getRowIdByYPosition(yPosition: number, scrollContainerId: EGanttScrollContainer): string {
    return this.getRowByYPosition(yPosition, scrollContainerId)?.id;
  }

  /**
   * Determines the row which is at the specified y position in the specified scroll container.
   * @param yPosition Y position to determine the row for.
   * @param scrollContainerId Id of the scroll container to determine the row on the specified y position for.
   * @returns Row at the specified y position in the specified scroll container (or `undefined` if no row was found at the specified y position).
   */
  public getRowByYPosition(yPosition: number, scrollContainerId: EGanttScrollContainer): GanttCanvasRow {
    const stickyRows = scrollContainerId === EGanttScrollContainer.STICKY_ROWS;
    const rowIds = stickyRows ? this._stickyRowStorage.keys() : this._notStickyRowStorage.keys();

    for (const rowId of rowIds) {
      const row = YAxisDataFinder.getCanvasRowById(this._dataHandler.getYAxisDataset(), rowId);
      if (yPosition >= this.getYPositionRow(rowId) && yPosition < this.getYPositionRow(rowId) + row.height) {
        return row;
      }
    }
    return undefined;
  }

  /**
   * Removes all y positions which are assigned to a row.
   */
  public clearYPositionRowStorage(): void {
    this._yPositionRowStorage.clear();
  }

  //
  // Y POSITION SHIFT STORAGE
  //

  /**
   * Sets the specified y position for the shift with the specified id.
   * @param shiftId Id of the shift to set the y position for.
   * @param yPosition Y position of the shift with the specified id.
   */
  public setYPositionShift(shiftId: string, yPosition: number): void {
    this._yPositionShiftStorage.set(shiftId, yPosition);
  }

  /**
   * Determines the y position of the shift with the specified id.
   * @param shiftId Id of the shift to determine the y position for.
   * @returns Y position of the shift with the specified id.
   */
  public getYPositionShift(shiftId: string): number {
    return this._yPositionShiftStorage.get(shiftId);
  }

  /**
   * Removes all y positions which are assigned to a shift.
   */
  public clearYPositionShiftStorage(): void {
    this._yPositionShiftStorage.clear();
  }

  //
  // STICKY ROW STORAGE
  //

  /**
   * Adds the specified row id to the list of sticky rows.
   * @param stickyRowId Id of the row to be added to the list of sticky rows.
   */
  public addStickyRow(stickyRowId: string): void {
    this._stickyRowStorage.add(stickyRowId);
  }

  /**
   * Adds the specified row id to the list of non-sticky rows.
   * @param notStickyRowId Id of the row to be added to the list of non-sticky rows.
   */
  public addNotStickyRow(notStickyRowId: string): void {
    this._notStickyRowStorage.add(notStickyRowId);
  }

  /**
   * Removes all entries from the list of sticky rows.
   */
  public clearStickyRowStorage(): void {
    this._stickyRowStorage.clear();
    this._notStickyRowStorage.clear();
  }

  /**
   * Determines whether the row with the specified id is sticky or not.
   * @param rowId Id of the row to check.
   * @returns `true` if the row with the specified id is sticky, `false` if not.
   */
  public isRowSticky(rowId: string): boolean {
    return this._stickyRowStorage.has(rowId);
  }

  /**
   * Determines whether the row with the specified id is sticky or not and returns the corresponding scroll container id.
   * @param rowId Id of the row to check.
   * @returns Id of the scroll container the row with the specified id is rendered in.
   */
  public getRowScrollContainer(rowId: string): EGanttScrollContainer {
    return this.isRowSticky(rowId) ? EGanttScrollContainer.STICKY_ROWS : EGanttScrollContainer.DEFAULT;
  }

  /**
   * Determines whether the row with the specified id is inside the scroll container with the specified id or not.
   * @param shiftId Id of the row to check.
   * @param scrollContainerId Id of the scroll container to check.
   * @returns `true` if the row with the specified id is inside the scroll container with the specified id, `false` if not.
   */
  public isRowInScrollContainer(rowId: string, scrollContainerId: EGanttScrollContainer): boolean {
    return this.getRowScrollContainer(rowId) === scrollContainerId;
  }

  //
  // STICKY ROW SHIFT STORAGE
  //

  /**
   * Adds the specified shift id to the list of shifts which are in sticky rows.
   * @param shiftId Id of the shift to be added to the list of shifts which are in sticky rows.
   */
  public addStickyRowShift(shiftId: string): void {
    this._stickyRowShiftStorage.add(shiftId);
  }

  /**
   * Adds the specified shift id to the list of shifts which are in non-sticky rows.
   * @param shiftId Id of the shift to be added to the list of shifts which are in non-sticky rows.
   */
  public addNotStickyRowShift(shiftId: string): void {
    this._notStickyRowShiftStorage.add(shiftId);
  }

  /**
   * Removes all entries from the list of shifts which are in sticky rows.
   */
  public clearStickyRowShiftStorage(): void {
    this._stickyRowShiftStorage.clear();
    this._notStickyRowShiftStorage.clear();
  }

  /**
   * Determines whether the shift with the specified id is in the current render data or not.
   * @param shiftId Id of the shift to check.
   * @returns `true` if the shift with the specified id is in the current render data, `false` if not.
   */
  public isShiftInRenderData(shiftId: string): boolean {
    return this._stickyRowShiftStorage.has(shiftId) || this._notStickyRowShiftStorage.has(shiftId);
  }

  /**
   * Determines whether the shift with the specified id is inside a sticky row or not.
   * @param shiftId Id of the shift to check.
   * @returns `true` if the shift with the specified id is inside a sticky row, `false` if not.
   */
  public isShiftInStickyRow(shiftId: string): boolean {
    return this._stickyRowShiftStorage.has(shiftId);
  }

  /**
   * Determines whether the shift with the specified id is inside a sticky row or not and returns the corresponding scroll container id.
   * @param shiftId Id of the shift to check.
   * @returns Id of the scroll container the shift with the specified id is rendered in.
   */
  public getShiftScrollContainer(shiftId: string): EGanttScrollContainer {
    return this.isShiftInStickyRow(shiftId) ? EGanttScrollContainer.STICKY_ROWS : EGanttScrollContainer.DEFAULT;
  }

  /**
   * Determines whether the shift with the specified id is inside the scroll container with the specified id or not.
   * @param shiftId Id of the shift to check.
   * @param scrollContainerId Id of the scroll container to check.
   * @returns `true` if the shift with the specified id is inside the scroll container with the specified id, `false` if not.
   */
  public isShiftInScrollContainer(shiftId: string, scrollContainerId: EGanttScrollContainer): boolean {
    return this.getShiftScrollContainer(shiftId) === scrollContainerId;
  }

  //
  // GETTER & SETTER
  //

  /**
   * Helper getter which returns the data handler of the current gantt.
   */
  private get _dataHandler(): DataHandler {
    return this._ganttDiagram.getDataHandler();
  }
}
