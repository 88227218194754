import {
  ChangeDetectorRef,
  Component,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ButtonService } from '@app-modeleditor/components/button/button.service';
import { Registered, TemplateService } from '@app-modeleditor/utils/template.service';
import { takeWhile } from 'rxjs/operators';
import { MenuItem } from './../../../../view/template-toolbar/menu-item';

@Component({
  selector: 'app-editmode-bar',
  templateUrl: './editmode-bar.component.html',
  styleUrls: ['./editmode-bar.component.scss'],
})
export class EditmodeBarComponent implements OnInit, OnDestroy, OnChanges {
  private $alive = true;
  private $registered: Record<string, Registered>;
  @Input() menuItem: MenuItem;

  constructor(
    private $zone: NgZone,
    private $templateApi: TemplateService,
    private $cd: ChangeDetectorRef,
    private $actionApi: ButtonService
  ) {
    this.$zone.runOutsideAngular(() => {
      this.$templateApi
        .afterRegisteredChanges()
        .pipe(takeWhile(() => this.$alive))
        .subscribe((reg: Record<string, Registered>) => {
          this.$registered = reg;
          this.$checkEditmode();
        });
    });
  }

  test(): void {}

  ngOnInit(): void {
    this.$checkEditmode();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.$checkEditmode();
    // if(changes.menuItem) {
    // }
  }

  ngOnDestroy(): void {
    this.$alive = false;
  }

  toggleEditMode(): void {
    if (this.editmode) {
      this.$templateApi.releaseEditMode().subscribe();
    } else {
      this.$templateApi.requireEditMode().subscribe();
    }
  }

  showEditModeInfo: boolean;
  editmode: boolean;
  private $checkEditmode(): void {
    if (!this.menuItem || !this.$registered) {
      return;
    }

    const t = this.$registered[this.menuItem.getResourceId()];
    const val: boolean = t && typeof t?.editable === 'boolean' ? t.editable : null;
    if (val === this.editmode) {
      return;
    }

    this.$zone.run(() => {
      this.editmode = val;
      this.showEditModeInfo = typeof val === 'boolean' ? true : false;
      this.$cd.markForCheck();
    });
  }
}
