import { Component, EventEmitter, Input, NgZone, OnDestroy, Output, ViewChild } from '@angular/core';
import { UiService } from '@app-modeleditor/ui.service';
import { ConfigService } from '@core/config/config.service';
import { TranslateService } from '@ngx-translate/core';
import { UtilTooltipMapper } from 'frontend/src/dashboard/gantt/general/generator/mapper/gantt.tooltip.mapper';
import { GlobalUtils } from 'frontend/src/dashboard/global-utils';
import { ContextMenuPopupService } from 'frontend/src/dashboard/popups-services/context-menu-popup/context-menu-popup.service';
import * as L from 'leaflet';
import { of } from 'rxjs';
import { delay, take } from 'rxjs/operators';
import { Action } from '../button/action/action';
import { EPredefinedAction } from '../button/action/predefined-action.enum';
import { TemplateActionService } from '../button/template-action.service';
import { ContextMenuItem } from '../contextmenu/context-menu-item';
import { ContextMenu } from '../contextmenu/contextmenu';
import { ContextmenuService } from '../contextmenu/contextmenu.service';
import { MapTemplate, MarkersTemplate, TransitionLine } from '../map/map';
import { MapComponent } from '../map/map.component';
import { TemplateService } from './../../utils/template.service';
import { ButtonService } from './../button/button.service';
import { TemplateDatepickerComponent } from './../template-datepicker/template-datepicker.components';
import { ILegendItem, IMapTimeFilterRange, TemplateMap } from './location-map';

@Component({
  selector: 'app-location-map',
  templateUrl: './location-map.component.html',
  styleUrls: ['./location-map.component.scss'],
})
export class LocationMapComponent implements OnDestroy {
  @ViewChild('templateDatepicker', {})
  templateDatepicker: TemplateDatepickerComponent;
  @ViewChild('MapComponent', {}) mapComponent: MapComponent;
  private _templateNode: TemplateMap;
  @Input() set templateNode(n: TemplateMap) {
    this._templateNode = n;
    this.mapTemplate = new MapTemplate();
    this.mapTemplate.mapLayers = this.templateNode.getMapLayers();
    this.mapTemplate.timedRangePicker = this.templateNode.isTimedRangePicker();
    // this.mapTemplate.center = ;
    this.mapTemplate.currentMapLayer = this.templateNode.getMapLayers()[0];
    this.mapTemplate.zoom = 5;
    this.mapTemplate.maxZoom = 25;
    const url: string = this.configApi.getRequestUrl();
    this.mapTemplate.restUrl = url + 'rest/map/geo/tile/raster/get?z={z}&x={x}&y={y}';

    // this.mapTemplate.restUrl = 'http://192.168.0.37:80/v1/tile?style=car&daylight=1&scale=4&shift=0&z={z}&x={x}&y={y}';
    // this.mapTemplate.restUrl = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';

    this.mapTemplate.setCenter(51.05089, 13.73832);
    this.mapTemplate.id = GlobalUtils.generateUUID();
    this.getMapTimeFilter();
    this.init();
  }

  get templateNode(): TemplateMap {
    return this._templateNode;
  }
  @Output() onChanges: EventEmitter<any> = new EventEmitter();

  public mapTemplate: MapTemplate;
  public legendItems: ILegendItem[] = [];
  public filterRange: IMapTimeFilterRange = {
    startTime: new Date(),
    startString: '',
    endTime: new Date(),
    endString: '',
  };
  public initLoad = true;
  private resetTimePicker = false;
  private saveMarkers = false;
  public selectedValues: { from: Date; to: Date };
  private unSavedChanges: Map<string, MarkersTemplate> = new Map();

  constructor(
    private uiService: UiService,
    private configApi: ConfigService,
    private buttonService: ButtonService,
    public translate: TranslateService,
    private contextMenuPopupService: ContextMenuPopupService,
    private templateActionApi: TemplateActionService,
    private contextmenuApi: ContextmenuService,
    private templateApi: TemplateService,
    private zone: NgZone
  ) {}

  private init() {
    if (this.templateNode) {
      of(null)
        .pipe(delay(0))
        .subscribe(() => {
          this.getMapData(this.templateNode.value);
        });
    }
  }

  ngOnDestroy(): void {}

  onContextMenu(ev: any): void {
    this.zone.run(() => {
      const contextMenu = new ContextMenu()
        .setId('map_' + this.templateNode.getId() + '_contextmenu')
        .setContextMenuItems(this.getRowContextMenuGroup(ev.latlng));

      this.contextmenuApi.create(ev.originalEvent, contextMenu);
    });
  }

  private getRowContextMenuGroup(position: L.LatLng): ContextMenuItem[] {
    const contextMenuItems: ContextMenuItem[] = [];

    if (this.mapTemplate.currentMapLayer.onMapClickEventRestUrl) {
      contextMenuItems.push(
        new ContextMenuItem()
          .setId(EPredefinedAction.MAP_CREATE_MARKER)
          .setName('MAP.CONTEXTMENU.ACTION.newMarker')
          .setIcon('add')
          .chainActions(new Action().setCb(() => of(this.createNewMarker(position))))
      );
    }

    contextMenuItems.push(
      new ContextMenuItem()
        .setId(EPredefinedAction.MAP_JUMP_TO_LOCATION)
        .setName('MAP.CONTEXTMENU.ACTION.jumpToLocation')
        .setIcon('my_location')
        .chainActions(
          new Action().setCb(() => {
            this.contextMenuPopupService.closePopup();
            this.mapTemplate.getMapRef().panTo(position);
            return of(null);
          })
        )
    );

    contextMenuItems.push(
      new ContextMenuItem()
        .setId(EPredefinedAction.MAP_ZOOM_IN)
        .setName('MAP.CONTEXTMENU.ACTION.zoomIn')
        .setIcon('zoom_in')
        .chainActions(
          new Action().setCb(() => {
            this.contextMenuPopupService.closePopup();
            this.mapTemplate.getMapRef().zoomIn();
            return of(null);
          })
        )
    );

    contextMenuItems.push(
      new ContextMenuItem()
        .setId(EPredefinedAction.MAP_ZOOM_OUT)
        .setName('MAP.CONTEXTMENU.ACTION.zoomOut')
        .setIcon('zoom_out')
        .chainActions(
          new Action().setCb(() => {
            this.contextMenuPopupService.closePopup();
            this.mapTemplate.getMapRef().zoomOut();
            return of(null);
          })
        )
    );

    return contextMenuItems;
  }

  private createNewMarker(position: L.LatLng): void {
    this.contextMenuPopupService.closePopup();
    this.uiService
      .getData(this.mapTemplate.currentMapLayer.onMapClickEventRestUrl, {
        latitude: position.lat,
        longitude: position.lng,
      })
      .pipe(take(1))
      .subscribe((data) => {
        this.saveMarkers = true;
        this.refresh();
      });
  }

  public onMarkerEditOnMap(marker: MarkersTemplate): void {
    this.unSavedChanges.set(marker.resourceId, marker);

    const data = {};
    Array.from(this.unSavedChanges.keys()).forEach((key) => {
      data[key] = {
        latitude: this.unSavedChanges.get(key).postion.lat,
        longitude: this.unSavedChanges.get(key).postion.lng,
      };
    });

    const saveOutput: any = {
      markerChanges: data,
    };

    this.onChanges.emit(saveOutput);
  }

  private jumpToPostion(position: L.LatLng) {
    if (this.mapTemplate.getMapRef()) {
      this.mapTemplate.getMapRef().setView(position, 10);
    }
    // this.mapTemplate.getMapRef().setZoom(10);
    this.mapTemplate.center = position;
    this.mapTemplate.zoom = 10;
  }

  getMapData(data: any): void {
    this.mapTemplate.clearData();
    if (data) {
      for (const mapLayerResultObject of data.mapLayerResultObjects) {
        if (
          this.mapTemplate.currentMapLayer.mapLayerType === mapLayerResultObject.mapLayerType &&
          this.mapTemplate.currentMapLayer.id === mapLayerResultObject.id
        ) {
          this.getMarker(mapLayerResultObject.markers);
          this.getMovements(mapLayerResultObject.movements);
        }
      }
      this.mapTemplate.setChainLineOfMarker();
      this.getLegend(data.legends);
      if (data.centerLatitude && data.centerLongitude && this.mapTemplate.getMarkers()) {
        const newCenter = new L.LatLng(data.centerLatitude, data.centerLongitude);
        this.jumpToPostion(newCenter);
      }

      if (this.mapComponent) {
        this.mapComponent.updateStyle();
      }

      if (this.saveMarkers) {
        this.mapTemplate.getMarkers().forEach((marker) => {
          this.onMarkerEditOnMap(marker);
        });
        this.saveMarkers = false;
      }
      this.initLoad = false;
    }
    this.initLoad = false;
  }

  private getMarker(markers: any[]) {
    if (!markers) {
      return;
    }
    markers.forEach((element) => {
      if (element.location && element.location.latitude && element.location.longitude) {
        const marker = new MarkersTemplate(
          element.name,
          element.location.uuid,
          element.referenceId,
          element.resourceId
        );
        marker.setPostion(element.location.latitude, element.location.longitude);

        if (element.icon) {
          marker.setIcon(this.getMarkerIcon(element.icon));
          marker.updateOptionProperty('icon', this.getMarkerIcon(element.icon));
        }

        if (element.attribute && this.mapTemplate.currentMapLayer.attributeMapping) {
          marker.addTooltip(this.getTooltip(element.attribute));
        }

        if (element.name) {
          marker.addLabel(element.name);
        }

        marker.addColor(element.color);
        // marker.onClickCallback = this.handleActionCall.bind(this);

        marker.buildMarker(this.mapTemplate.getMapRef());
        this.mapTemplate.addMarker(marker);
        if (markers.length === 1) {
          this.mapTemplate.center = marker.postion;
          // this.mapTemplate.getMapRef().setView(this.mapTemplate.center, this.mapTemplate.zoom);
        }
      }
    });
  }

  private getMarkerIcon(iconname: string): string {
    const icon = this.configApi.getIcon(iconname + '_MAP_ICON');
    return icon.getPath();
  }
  private getMovements(movements: any) {
    if (!movements) {
      return;
    }
    movements.forEach((element) => {
      if (
        element.source &&
        element.source.latitude &&
        element.source.longitude &&
        element.destination.latitude &&
        element.destination.longitude
      ) {
        const polyline = new TransitionLine(element.name, GlobalUtils.generateUUID(), element.referenceId);
        polyline.addPostion(new L.LatLng(element.source.latitude, element.source.longitude));
        polyline.addPostion(new L.LatLng(element.destination.latitude, element.destination.longitude));
        polyline.sourceLocationId = element.source.uuid;
        polyline.destinationLocationId = element.destination.uuid;
        polyline.sourceLocationTime = element.source.time;
        polyline.destinationLocationTime = element.destination.time;
        polyline.addColor(element.color);
        if (element.attribute && this.mapTemplate.currentMapLayer.attributeMapping) {
          polyline.addTooltip(this.getTooltip(element.attribute));
        }

        if (element.canonicalName && element.resourceId) {
          polyline.canonicalName = element.canonicalName;
          polyline.resourceId = element.resourceId;
          polyline.onClickCallback = this.handleActionCall.bind(this, polyline.canonicalName, polyline.resourceId);
        }
        polyline.setLineStyle(element.lineType);
        polyline.buildLine(this.mapTemplate.getMapRef());
        this.mapTemplate.addTransitionLine(polyline);
      }
    });
  }

  private getTooltip(attributes: any): HTMLElement {
    const div = document.createElement('div');
    div.innerHTML = UtilTooltipMapper.getTooltipByTemplateData(
      attributes,
      this.mapTemplate.currentMapLayer.attributeMapping
    );
    return div;
  }

  private getLegend(legendData) {
    this.legendItems = [];
    if (!legendData) {
      return;
    }
    for (const legendDataItem of legendData) {
      if (legendDataItem && legendDataItem.name && legendDataItem.referenceId) {
        const legendItem: ILegendItem = {
          label: legendDataItem.name,
          refId: legendDataItem.referenceId,
          hidden: false,
        };
        this.legendItems.push(legendItem);
      }
    }
  }

  private getMapTimeFilter() {
    this.selectedValues = {
      from: new Date(1546300800000),
      to: new Date(1577836800000),
    };
  }

  public handleTimeRange(increase: boolean): void {
    this.resetTimePicker = true;
    const values = this.selectedValues;
    this.selectedValues = null;

    const diff: number = values.to.getTime() - values.from.getTime();
    values.from = new Date(values.from.getTime() + (increase ? diff : -diff));
    values.to = new Date(values.to.getTime() + (increase ? diff : -diff));

    of(null)
      .pipe(delay(0))
      .subscribe(() => {
        this.selectedValues = values;
        this.resetTimePicker = false;
        this.refresh();
      });
  }

  refresh(): void {
    this.templateApi.updateElements([this.templateNode.getId()]);
  }

  public handleChangeTimeRange(event: number[]): void {
    if (event?.length !== 2 || !event[0] || !event[1]) {
      return;
    }

    this.selectedValues.from = new Date(event[0]);
    this.selectedValues.to = new Date(event[1]);
    this.refresh();
  }

  public handleLegendClick(legendItem: ILegendItem): void {
    if (this.templateNode.getLegendsActions().includes('TOGGLE')) {
      legendItem.hidden = !legendItem.hidden;
      this.mapTemplate.getMarkers().forEach((marker) => {
        if (marker.refId === legendItem.refId) {
          marker.toogleState(this.mapTemplate.getMapRef());
        }
      });
      this.mapTemplate.getTransitionLines().forEach((lines) => {
        if (lines.refId === legendItem.refId) {
          lines.toogleState(this.mapTemplate.getMapRef());
        }
      });
    }
  }

  private showLegenitemMarkers(legendItem: ILegendItem) {
    legendItem.hidden = false;
    this.mapTemplate.getMarkers().forEach((marker) => {
      if (marker.refId === legendItem.refId) {
        marker.rebuildWhenDead(this.mapTemplate.getMapRef());
      }
    });
    this.mapTemplate.getTransitionLines().forEach((lines) => {
      if (lines.refId === legendItem.refId) {
        lines.rebuildWhenDead(this.mapTemplate.getMapRef());
      }
    });
  }

  public handleHoverLegendItem(legendItem: ILegendItem, hightlight: boolean): void {
    if (this.templateNode.getLegendsActions().includes('HIGHLIGHT')) {
      this.mapTemplate.getMarkers().forEach((marker) => {
        if (marker.refId !== legendItem.refId) {
          if (hightlight) {
            marker.unsetFocus();
          } else {
            marker.resetFocus();
          }
        }
      });
      this.mapTemplate.getTransitionLines().forEach((lines) => {
        if (lines.refId !== legendItem.refId) {
          if (hightlight) {
            lines.unsetFocus();
          } else {
            lines.resetFocus();
          }
        }
      });
    }
  }

  public handleLayerSwitch(event: any): void {
    this.mapTemplate.currentMapLayer = event.value;
    this.legendItems = [];
    this.refresh();
  }

  private handleActionCall(canonicalName: string, resourceId: string) {
    this.saveDataInSharedUiService(canonicalName, resourceId);

    this.buttonService.onClick(this.mapTemplate.currentMapLayer.onClickAction).subscribe();
  }

  private saveDataInSharedUiService(canonicalName: string, resourceId: string) {
    const resourceObject = {
      canonicalName: canonicalName,
      resourceId: resourceId,
    };
    this.templateNode.setSelectedValue(resourceObject);
  }

  private buildParameterSelectorUrl(action) {
    // ensure that action exists
    if (!action || !action.actionUrl) {
      return null;
    }

    return this.templateActionApi.getUrlFromParameterSelectors(
      action.actionUrl(),
      action.getResourceId(),
      action.parameterSelectors()
    );
  }

  public freeTextSearch(filterInput: string): void {
    this.legendItems.forEach((item) => {
      this.showLegenitemMarkers(item);
    });
    const resultItems = this.legendItems.filter(
      (item) => !item.label.toLowerCase().includes(filterInput.toLowerCase())
    );
    resultItems.forEach((item) => {
      this.handleLegendClick(item);
    });
  }
}
