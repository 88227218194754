import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '@core/config/config.service';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  private log: any = undefined;

  constructor(private http: HttpClient, private configApi: ConfigService) {}

  /**
   *
   * @param queries rest url queries
   *
   * example:
   * rest/log?from=1412476511000&to=1612649311000&limit=100&offset=0
   */
  getBackendLog(queries?: any) {
    let restUrl = 'rest/log';
    let seperator = '?';

    if (queries) {
      for (const key in queries) {
        if (key) {
          const value = queries[key];
          if (value) {
            restUrl += seperator + key + '=' + value;
            seperator = '&';
          }
        }
      }
    }

    return this.http.get(`${restUrl}`).pipe(
      map((data) => {
        this.log = data;
        return data;
      })
    );
  }

  downloadLog(): void {
    const restUrl = 'rest/log/download';
    this.open(restUrl);
  }

  open(uri: string) {
    window.open(this.configApi.getRequestUrl(), '_blank');
  }
}
