<div class="wrapper">
  <div class="view-left">
    <app-setting-control
      [settings]="data.settings"
      [additionalData]="data.additional"
      (changeEvent)="onChanges()"
    ></app-setting-control>
  </div>
  <div class="view-right">
    <app-preview-gantt [settings]="data.settings"></app-preview-gantt>
  </div>
</div>
