<div class="context-menu-wrapper mat-elevation-z8">
  <mat-list>
    <ng-container *ngFor="let group of contextMenuTemplate.groups">
      <h3 mat-subheader>{{ group.name | translate }}</h3>
      <mat-list-item *ngFor="let item of group.items">
        <app-button
          [label]="item.label"
          [labelButton]="true"
          [simpleButton]="true"
          [id]="item.id"
          [disabled]="item.disabled"
          [icon]="item.icon"
          [data]="item"
          (action)="handleClick($event, item)"
        >
        </app-button>
        <!--button (click)="item.callback()" mat-button>
                    <mat-icon *ngIf="item.icon && !item.customIcon">{{item.icon}}</mat-icon>
                    <mat-icon *ngIf="item.icon && item.customIcon" [svgIcon]="getIcon(item)"></mat-icon>
                    {{item.label | translate}}
                </button>-->
      </mat-list-item>
      <mat-divider></mat-divider>
    </ng-container>
    <mat-list-item *ngFor="let item of contextMenuTemplate.generalItems">
      <app-button
        [label]="item.label"
        [simpleButton]="true"
        [labelButton]="true"
        [id]="item.id"
        [disabled]="item.disabled"
        [icon]="item.icon"
        [data]="item"
        (action)="handleClick($event, item)"
      >
      </app-button>
    </mat-list-item>
  </mat-list>
</div>
