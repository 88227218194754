import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SaxmsLightboxModule } from '@app-modules/lightbox-module/lightbox.module';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../core/material/material.module';
import { UiDialog } from '../model-editor/ui/dialogs/ui-dialog/ui.dialog';
import { PopupModule } from '../popups/popup.module';
import { ConfirmDialog } from './dialogs/confirm.dialog';
import { PipeModule } from './pipes/pipe.module';

@NgModule({
  imports: [
    CdkTableModule,
    SaxmsLightboxModule,
    PopupModule,
    TranslateModule,
    PipeModule,
    MaterialModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  declarations: [ConfirmDialog, UiDialog],
  providers: [],
  exports: [
    UiDialog,
    SaxmsLightboxModule,
    PopupModule,
    TranslateModule,
    PipeModule,
    MaterialModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
  ],
})
export class MySharedModule {}
