import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { FileProgress } from '../file-progress/file-progress';
import { FileData } from './filte-data';

@Injectable({
  providedIn: 'root',
})
export class FileProgressService {
  private _fp: FileProgress;
  private _fileProgress: BehaviorSubject<FileProgress> = new BehaviorSubject<FileProgress>(null);

  constructor() {
    this._fileProgress.next((this._fp = new FileProgress()));
  }

  public getFileProgressObject(): FileProgress {
    return this._fp;
  }

  public onFileProgressChanged(): Observable<FileProgress> {
    return this._fileProgress.asObservable();
  }

  public updateFilesInProgress(fp: FileProgress) {
    this._fp.setFiles(fp.getFiles());
  }

  public setByteOfFile(fileId: string, bytes) {
    const file = this._fp.getFiles()[fileId];
    if (file && file instanceof FileData) {
      file.setTransferedBytes(bytes);
      this._fp.addFile(file.getId(), file);
    }
    this.setFileProgress(this._fp);
  }

  public setFileProgress(progress: FileProgress): void {
    this._fp = progress;
    this._fileProgress.next(this._fp);
  }

  public clearFileProgress(): void {
    const tasks: number = this._fp.getTaskKeys().length;
    if (tasks === 0 && this._fp.getNumberOfFilesToTransfer() === this._fp.getNumberOfFilesTransfered()) {
      this._fileProgress.next((this._fp = new FileProgress()));
    } else {
      console.warn('FILE PROGRESS IS NOT COMPLETED!');
    }
  }
}
