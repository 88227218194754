import { GlobalUtils } from 'frontend/src/dashboard/global-utils';
import { EntryElementValue } from './../entry-collection/entry-element-value';

export class CalendarEvent extends EntryElementValue {
  private title: string;
  private backgroundColor: string;
  private textColor: string;
  private editRestUrl: string;
  private directEditRestUrl: string;
  private deleteRestUrl: string;
  private eventId: string;
  private start: Date;
  private end: Date;

  public getStart(): Date {
    return this.start;
  }

  public setStart(start: Date): this {
    this.start = start;
    return this;
  }

  public getEnd(): Date {
    return this.end;
  }

  public setEnd(end: Date): this {
    this.end = end;
    return this;
  }

  constructor() {
    super();
    this.eventId = GlobalUtils.generateUUID();
  }

  public getEventId(): string {
    return this.eventId;
  }

  public setEventId(eventId: string): this {
    this.eventId = eventId;
    return this;
  }

  public getDirectEditRestUrl(): string {
    return this.directEditRestUrl;
  }

  public setDirectEditRestUrl(directEditRestUrl: string): this {
    this.directEditRestUrl = directEditRestUrl;
    return this;
  }

  public getDeleteRestUrl(): string {
    return this.deleteRestUrl;
  }

  public setDeleteRestUrl(deleteRestUrl: string): this {
    this.deleteRestUrl = deleteRestUrl;
    return this;
  }

  public getEditRestUrl(): string {
    return this.editRestUrl;
  }

  public setEditRestUrl(editRestUrl: string): this {
    this.editRestUrl = editRestUrl;
    return this;
  }

  public getTextColor(): string {
    return this.textColor;
  }

  public setTextColor(textColor: string): this {
    this.textColor = textColor;
    return this;
  }

  public getBackgroundColor(): string {
    return this.backgroundColor;
  }

  public setBackgroundColor(backgroundColor: string): this {
    this.backgroundColor = backgroundColor;
    return this;
  }

  public getTitle(): string {
    return this.title;
  }

  public setTitle(title: string): this {
    this.title = title;
    return this;
  }
}
