import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkTreeModule } from '@angular/cdk/tree';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { SlotModule } from '@app-modeleditor/components/template/slot/slot.module';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from 'frontend/src/dashboard/core/material/material.module';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { TreeNodeModule } from '../tree-node/tree-node.module';
import { TreeComponent } from './tree.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    TranslateModule,
    CdkTreeModule,
    TreeNodeModule,
    DragDropModule,
    MatProgressBarModule,
    ScrollingModule,
    NgScrollbarModule,
    SlotModule,
  ],
  declarations: [TreeComponent],
  exports: [TreeComponent],
})
export class TemplateTreeModule {}
