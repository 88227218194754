import {
  TriggerEventFactory,
  TriggerEventFactoryItem,
} from 'frontend/src/dashboard/gantt/general/gantt-actions/event-factory';
import { GanttSingleClickEvent } from './click/single-click';
import { GanttDragEndEvent } from './drag/drag-end';
import { GanttExternalDropEvent } from './external-drop/external-drop';
import { EGanttActionEvent } from './gantt-action-event.enum';
import { GanttResizeEndEvent } from './resize/resize-end';
import { GanttZoomEndEvent } from './zoom/zoom-end';

/**
 * TriggerEventFactory which handles all actions of gantt itself.
 * Add more TriggerEventFactoryItems to extend event handling.
 */
export class TriggerEventGanttFactory extends TriggerEventFactory {
  constructor(templateData: any) {
    super(
      templateData,
      [
        new TriggerEventFactoryItem(EGanttActionEvent.DRAG_END, GanttDragEndEvent),
        new TriggerEventFactoryItem(EGanttActionEvent.RESIZE_END, GanttResizeEndEvent),
        new TriggerEventFactoryItem(EGanttActionEvent.ZOOM_END, GanttZoomEndEvent),
        new TriggerEventFactoryItem(EGanttActionEvent.EXTERNAL_DROP, GanttExternalDropEvent),
        new TriggerEventFactoryItem(EGanttActionEvent.ON_CLICK, GanttSingleClickEvent),
      ],
      []
    );
  }
}
