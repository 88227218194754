export class EventRange {
  private eventStart: Date;
  private eventEnd: Date;

  get startDate(): number {
    return this.getStart().getTime();
  }

  get endDate(): number {
    return this.getEnd().getTime();
  }

  public getStart(): Date {
    return this.eventStart || new Date();
  }

  public setStart(start: Date): this {
    this.eventStart = start;
    return this;
  }

  public getEnd(): Date {
    return this.eventEnd || new Date();
  }

  public setEnd(end: Date): this {
    this.eventEnd = end;
    return this;
  }
}
