import * as d3 from 'd3';
import { Observable, Subject } from 'rxjs';
import { GanttCanvasShift } from '../../../data-handler/data-structure/data-structure';
import { EGanttScrollContainer } from '../../../html-structure/scroll-container.enum';
import { BestGantt } from '../../../main';
import { EGanttShiftEditEventType } from '../../shift-edit-general/edit-events/shift-edit-event-type.enum';
import { GanttShiftEditRestrictionVisualizer } from '../../shift-edit-general/edit-restrictions/shift-edit-restriction-visualizer';
import { EGanttShiftResizeDraggingDirection } from '../resize-events/resize-dragging-direction.enum';
import { IGanttShiftResizeEvent } from '../resize-events/resize-event.interface';

/**
 * Class which handles the building of resize handles on shifts.
 * It is also responsible for the visualization of forbidden resize actions.
 */
export class GanttShiftResizeHandleBuilder extends GanttShiftEditRestrictionVisualizer<EGanttShiftResizeDraggingDirection> {
  private _parentNode: d3.Selection<SVGGElement, unknown, SVGElement, unknown>;

  private _resizeHandles: {
    [key: string]: d3.Selection<SVGRectElement, IResizeHandleData, SVGGElement, unknown>;
  } = {};

  private _onResizeHandleDragStart = new Subject<IGanttShiftResizeEvent>();
  private _onResizeHandleDragUpdate = new Subject<IGanttShiftResizeEvent>();
  private _onResizeHandleDragEnd = new Subject<IGanttShiftResizeEvent>();

  constructor(parentNode: d3.Selection<SVGGElement, unknown, SVGElement, unknown>, ganttDiagram: BestGantt) {
    super(ganttDiagram);
    this._parentNode = parentNode;
  }

  /**
   * Builds resize handles for the specified shift.
   * @param shift Shift to build the resize handles for.
   * @param shiftSelection
   * @param left Flag indicating whether the left resize handle should be built or not.
   * @param right Flag indicating whether the right resize handle should be built or not.
   * @param scrollContainerId
   */
  public buildResizeHandles(
    shift: GanttCanvasShift,
    shiftSelection: d3.Selection<SVGRectElement, GanttCanvasShift, d3.BaseType, undefined>,
    left = true,
    right = true,
    scrollContainerId = EGanttScrollContainer.DEFAULT
  ): void {
    this.removeAllResizeHandles();

    // build left resize handle if specified
    if (left || this._ganttDiagram.getConfig().shiftEditVisualizeNotAllowed()) {
      const orientation = EGanttShiftResizeDraggingDirection.LEFT;
      this._resizeHandles[orientation] = this._buildResizeHandle(
        shift,
        shiftSelection,
        orientation,
        left,
        scrollContainerId
      );
    }
    // build right resize handle if specified
    if (right || this._ganttDiagram.getConfig().shiftEditVisualizeNotAllowed()) {
      const orientation = EGanttShiftResizeDraggingDirection.RIGHT;
      this._resizeHandles[orientation] = this._buildResizeHandle(
        shift,
        shiftSelection,
        orientation,
        right,
        scrollContainerId
      );
    }
  }

  /**
   * Builds resize handle SVG elements.
   * @keywords build, render, resize, handle, rect, change, shift, position
   * @param xPosition Horizontal position of resize handle.
   * @param yPosition Vertical position of resize handle.
   * @param orientation Position of resize handle relative to shift.
   * @param enabled
   * @param scrollContainerId
   * @return D3 selection of built resize handle.
   */
  private _buildResizeHandle(
    shift: GanttCanvasShift,
    shiftSelection: d3.Selection<SVGRectElement, GanttCanvasShift, d3.BaseType, undefined>,
    orientation: EGanttShiftResizeDraggingDirection,
    enabled = true,
    scrollContainerId = EGanttScrollContainer.DEFAULT
  ): d3.Selection<SVGRectElement, IResizeHandleData, SVGGElement, unknown> {
    const shiftCalculator = this._ganttDiagram
      .getShiftFacade()
      .getShiftBuilder(scrollContainerId)
      .getShiftCalculationStrategy();
    const storage: IResizeHandleData = {
      shift: shift,
      shiftSelection: shiftSelection,
      orientation: orientation,
      scrollContainerId: scrollContainerId,
      enabled: enabled,
    };

    const resizeHandle = this._parentNode
      .selectAll('dummy')
      .data([storage])
      .enter()
      .append('rect')
      .attr('class', 'gantt-shift-resize-handle')
      .attr('x', (d) => this._getResizeHandleX(d))
      .attr('y', shiftCalculator.getShiftViewportY(shift) + shift.height / 4)
      .attr('width', (d) => this._getResizeHandleWidth(d))
      .attr('height', shift.height)
      .attr('fill', 'none')
      .style('cursor', 'col-resize')
      .style('pointer-events', 'auto')
      .style('fill', 'transparent')
      .call(
        d3
          .drag()
          .on('start', (event, d: IResizeHandleData) => {
            // update resize handle
            this._onDragStart(resizeHandle);

            // if disabled -> do not trigger events
            if (!d.enabled) {
              this.block(d.orientation);
              return;
            }

            // trigger resize start event
            this._onResizeHandleDragStart.next({
              type: EGanttShiftEditEventType.START,
              event: event,
              shiftSelection: d.shiftSelection,
              orientation: d.orientation,
              scrollContainerId: d.scrollContainerId,
            });
          })
          .on('drag', (event, d: IResizeHandleData) => {
            // if disabled -> do not trigger events
            if (!d.enabled) return;

            // trigger resize update event
            this._onResizeHandleDragUpdate.next({
              type: EGanttShiftEditEventType.UPDATE,
              event: event,
              shiftSelection: d.shiftSelection,
              orientation: d.orientation,
              scrollContainerId: d.scrollContainerId,
            });
          })
          .on('end', (event, d: IResizeHandleData) => {
            // trigger resize end event
            this._onResizeHandleDragEnd.next({
              type: EGanttShiftEditEventType.END,
              event: event,
              shiftSelection: d.shiftSelection,
              orientation: d.orientation,
              scrollContainerId: d.scrollContainerId,
            });

            // if disabled -> do not trigger events
            if (!enabled) {
              this.unblock(d.orientation);
              return;
            }

            // update resize handle
            this._onDragEnd(resizeHandle);
          })
      );
    return resizeHandle;
  }

  /**
   * Calculates the x position of a resize handle by the given resize handle data.
   * @param d Resize handle data to calculate the width for.
   * @returns Resize handle x position.
   */
  private _getResizeHandleX(d: IResizeHandleData): number {
    const ganttConfig = this._ganttDiagram.getConfig();
    const xAxisBuilder = this._ganttDiagram.getXAxisBuilder();

    const currentScale = xAxisBuilder.getCurrentScale();
    const globalScale = xAxisBuilder.getGlobalScale();
    const shiftX = currentScale(xAxisBuilder.pxToTime(d.shift.x, globalScale));
    const shiftWidth = currentScale(xAxisBuilder.pxToTime(d.shift.x + d.shift.width, globalScale)) - shiftX;

    const xPosition = d.orientation === EGanttShiftResizeDraggingDirection.RIGHT ? shiftX + shiftWidth : shiftX;
    const width =
      ganttConfig.shiftEditResizeHandleWidth() * 1.5 >= shiftWidth
        ? shiftWidth / 1.5
        : ganttConfig.shiftEditResizeHandleWidth();
    return d.orientation === EGanttShiftResizeDraggingDirection.RIGHT
      ? xPosition - 3 * (width / 4)
      : xPosition - width / 4;
  }

  /**
   * Calculates the width of a resize handle by the given resize handle data.
   * @param d Resize handle data to calculate the width for.
   * @returns Resize handle width.
   */
  private _getResizeHandleWidth(d: IResizeHandleData): number {
    const ganttConfig = this._ganttDiagram.getConfig();
    const xAxisBuilder = this._ganttDiagram.getXAxisBuilder();

    const currentScale = xAxisBuilder.getCurrentScale();
    const globalScale = xAxisBuilder.getGlobalScale();
    const shiftX = currentScale(xAxisBuilder.pxToTime(d.shift.x, globalScale));
    const shiftWidth = currentScale(xAxisBuilder.pxToTime(d.shift.x + d.shift.width, globalScale)) - shiftX;

    const width =
      ganttConfig.shiftEditResizeHandleWidth() * 1.5 >= shiftWidth
        ? shiftWidth / 1.5
        : ganttConfig.shiftEditResizeHandleWidth();
    return width;
  }

  /**
   * Handles adjustments to the specified resize handle on drag start.
   * @param resizeHandle D3 selection of the resize handle.
   */
  private _onDragStart(resizeHandle: d3.Selection<SVGRectElement, IResizeHandleData, SVGGElement, unknown>): void {
    const shiftCanvasProportions = this._ganttDiagram.getNodeProportionsState().getShiftCanvasProportions();

    resizeHandle
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', shiftCanvasProportions.width)
      .attr('height', shiftCanvasProportions.height);
  }

  /**
   * Handles adjustments to the specified resize handle on drag end.
   * @param resizeHandle D3 selection of the resize handle.
   */
  private _onDragEnd(resizeHandle: d3.Selection<SVGRectElement, IResizeHandleData, SVGGElement, unknown>): void {
    const d = resizeHandle.data()[0];
    const shift = d.shiftSelection.data()[0];

    resizeHandle
      .attr('x', (d) => this._getResizeHandleX(d))
      .attr('y', shift.y + shift.height / 4)
      .attr('width', (d) => this._getResizeHandleWidth(d))
      .attr('height', shift.height);
  }

  /**
   * If allowed in the gantt config, this method visualizes an action which is not allowed by changing the cursor of the resize handle.
   * @param orientation Dragging direction of the resize handle to be blocked.
   */
  public block(orientation: EGanttShiftResizeDraggingDirection): void {
    if (this._ganttDiagram.getConfig().shiftEditVisualizeNotAllowed()) {
      if (this._resizeHandles[orientation].style('cursor') !== 'not-allowed') {
        this._resizeHandles[orientation].style('cursor', 'not-allowed');
      }
    }
  }

  /**
   * If allowed in the gantt config, this method resets the visualization of an action which is not allowed by changing the cursor of the resize handle.
   * @param orientation Dragging direction of the resize handle to be unblocked.
   */
  public unblock(orientation: EGanttShiftResizeDraggingDirection): void {
    if (this._ganttDiagram.getConfig().shiftEditVisualizeNotAllowed()) {
      if (this._resizeHandles[orientation].style('cursor') !== 'col-resize') {
        this._resizeHandles[orientation].style('cursor', 'col-resize');
      }
    }
  }

  /**
   * Removes all resize handle SVG elements.
   * @keywords remove, clear, empty, delete, canvas, resize, handler
   */
  public removeAllResizeHandles(): void {
    for (const key in this._resizeHandles) {
      if (this._resizeHandles[key]) {
        this._resizeHandles[key].remove();
      }
    }
  }

  /**
   * Sets a new parent node to build the resize handles into.
   * @param parentNode Ne wparent node to use.
   */
  public setParentNode(parentNode: d3.Selection<SVGGElement, unknown, SVGElement, unknown>): void {
    this._parentNode = parentNode;
  }

  /**
   * Observable which gets triggered when the dragging of a resize handle starts.
   */
  public get onResizeHandleDragStart(): Observable<IGanttShiftResizeEvent> {
    return this._onResizeHandleDragStart.asObservable();
  }

  /**
   * Observable which gets triggered when the dragging state of a resize handle gets updated.
   */
  public get onResizeHandleDragUpdate(): Observable<IGanttShiftResizeEvent> {
    return this._onResizeHandleDragUpdate.asObservable();
  }

  /**
   * Observable which gets triggered when the dragging of a resize handle ends.
   */
  public get onResizeHandleDragEnd(): Observable<IGanttShiftResizeEvent> {
    return this._onResizeHandleDragEnd.asObservable();
  }
}

/**
 * Data structure which is used to store data in resize handle nodes.
 */
interface IResizeHandleData {
  shift: GanttCanvasShift;
  shiftSelection: d3.Selection<SVGRectElement, GanttCanvasShift, d3.BaseType, undefined>;
  orientation: EGanttShiftResizeDraggingDirection;
  scrollContainerId: EGanttScrollContainer;
  enabled: boolean;
}
