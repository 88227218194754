<ng-container *ngIf="cp">
  <ng-container *ngIf="cp.isDisplayContentpartContainer(); else defaultContentpart">
    <div
      class="stylized"
      [class.closed]="cp.isExpanded() === false"
      [class.fullscreen]="cp.getResizeMode() === 'FIT_PARENT'"
    >
      <mat-accordion>
        <mat-expansion-panel
          (closed)="cp.setExpanded(false)"
          (opened)="cp.setExpanded(true)"
          [expanded]="cp.isExpanded()"
        >
          <mat-expansion-panel-header>
            <mat-panel-title>
              <span class="header-text" [matTooltip]="cp.getName()" [matTooltipShowDelay]="1000">{{
                cp.getName()
              }}</span>
            </mat-panel-title>
            <mat-panel-description> </mat-panel-description>
          </mat-expansion-panel-header>
          <ng-template matExpansionPanelContent>
            <ng-container *ngIf="cp.isExpanded()">
              <ng-container *ngTemplateOutlet="element"></ng-container>
            </ng-container>
          </ng-template>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </ng-container>
  <ng-template #defaultContentpart>
    <div
      class="default-contentpart-container"
      [class.fullscreen]="cp.isFullscreen() || cp.getResizeMode() === 'FIT_PARENT'"
    >
      <ng-container *ngTemplateOutlet="element"></ng-container>
    </div>
  </ng-template>

  <ng-template #element>
    <app-content-element
      [resourceId]="resourceId"
      [contentId]="contentId"
      [root]="root"
      *ngFor="let el of cp.getContentElements()"
      [fullscreen]="cp.isFullscreen() || cp.getResizeMode() === 'FIT_PARENT'"
      [contentElement]="el"
    >
    </app-content-element>
  </ng-template>
</ng-container>
