import { PatternBuilder } from '../../patternBuilder';
import { GanttPatternData } from '../../patternHandler';
import { Pattern } from '../pattern.base';

/**
 * Class which handles the generation of a heart pattern.
 */
export class HeartsPattern extends Pattern {
  /**
   * @param patternBuilder {@link PatternBuilder} instance to be used for the pattern generation.
   */
  constructor(patternBuilder: PatternBuilder) {
    super(patternBuilder);
    this._patternWidth = 12;
    this._patternHeight = 12;
  }

  /**
   * Generates an SVG image containing the pattern and returns it as base64-encoded URL.
   * @param backgroundColor Background color of the pattern.
   * @param patternColor Color of the pattern.
   * @param strokeColor Stroke color of the pattern.
   * @returns SVG image containing the pattern as base64-encoded URL.
   */
  protected _getPatternDataString(backgroundColor: string, patternColor: string, strokeColor: string): string {
    const pattern = this._patternBuilder.getSvgElement(this._patternHeight, this._patternWidth);
    this._patternBuilder.appendBackground(pattern, backgroundColor);
    this._patternBuilder.appendPath(
      pattern,
      'M24 42.7l-2.9-2.63C10.8 30.72 4 24.55 4 17 4 10.83 8.83 6 15 6c3.48 0 6.82 1.62 9 4.17C26.18 7.62 29.52 6 33 6c6.17 0 11 4.83 11 11 0 7.55-6.8 13.72-17.1 23.07L24 42.7z',
      null,
      null,
      'scale(0.25)',
      patternColor,
      strokeColor
    );
    return this._patternBuilder.convertSvgNodeIntoDataString(pattern);
  }

  /**
   * Generates an SVG image containing the pattern and returns it as {@link GanttPatternData} object.
   * @param backgroundColor Background color of the pattern.
   * @param patternColor Color of the pattern.
   * @param strokeColor Stroke color of the pattern.
   * @returns SVG image containing the pattern as {@link GanttPatternData} object.
   */
  public getPatternData(backgroundColor: string, patternColor: string, strokeColor: string): GanttPatternData {
    return new GanttPatternData(
      this._getPatternDataString(backgroundColor, patternColor, strokeColor),
      this._patternHeight,
      this._patternWidth
    );
  }
}
