import { ViewService } from './../../view.service';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'selector',
  templateUrl: './selector.html',
  styleUrls: ['./selector.scss'],
})
export class SelectorComponent implements OnInit {
  public tooltip = '';
  public disabled = false;
  public selected: any;
  public items: Array<any> = [];
  public actions: Array<Action> = [];
  public title = '123';
  public globalStyle = '';
  @Input() maxShownElements = 5;
  @Input() hidden: boolean;
  @Input() prevent = false;
  @Input() display = true;
  @Input() protected showSelected: boolean;
  @Input() advanced: boolean;

  constructor(public globalViewService: ViewService) {}

  onClick(event, item) {
    this.setActive(item);
  }

  setActive(item: any): void {}

  ngOnInit() {
    this.globalViewService.getCurrentTheme().subscribe((style) => {
      this.globalStyle = style;
    });
  }

  /**
   * function is executed if menu will be opened
   */
  onOpen(event) {}

  stopPropagation(event) {
    if (this.prevent) {
      event.stopPropagation();
    }
  }
}

interface Action {
  callback: () => void;
  icon: string;
  title: string;
}
