import { OverlayRef } from '@angular/cdk/overlay';
import { EntryElement } from '@app-modeleditor/components/entry-collection/entry-element';
import { EFieldType } from '../entry-collection/field-type.enum';

export class Colorpicker extends EntryElement {
  private defaultColor: string;
  private ref: OverlayRef;
  private possibileValues: string[] | undefined;
  private resetColor: string | undefined;

  public getPossibileValues(): string[] | undefined {
    return this.possibileValues;
  }

  public setPossibileValues(possibileValues: Record<string, string> | undefined): this {
    this.possibileValues = Object.keys(possibileValues || {}).map((key) => key);
    return this;
  }

  constructor() {
    super();
    this.setFieldType(EFieldType.COLOR_PICKER).setDefaultColor('#4b8c8f');
  }

  public getRef(): OverlayRef {
    return this.ref;
  }

  public setRef(ref: OverlayRef): this {
    this.ref = ref;
    return this;
  }

  public setDefaultColor(defaultColor: string): this {
    this.defaultColor = defaultColor;
    return this;
  }
  public getDefaultColor(): string {
    return this.defaultColor;
  }

  public setResetColor(resetColor: string): this {
    this.resetColor = resetColor;
    return this;
  }

  public getResetColor(): string | undefined {
    return this.resetColor;
  }
}
