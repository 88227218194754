import { Layout } from '../layout';
import { ELayoutType } from '../layout-type.enum';

interface ILayoutContentElement {
  id: string;
  columnIndex: number;
  height: number;
  rowIndex: number;
  width: number;
}
interface ILayoutContent {
  [key: string]: ILayoutContentElement;
}

export class GridLayout extends Layout {
  private columnCount: number;
  private content: ILayoutContent;
  private rowCount: number;
  private height: number;

  getContentElements(): ILayoutContentElement[] {
    const a: ILayoutContentElement[] = Object.keys(this.content)
      .map((key: string) => {
        this.content[key].id = key;
        return this.content[key];
      })
      .sort((c1: ILayoutContentElement, c2: ILayoutContentElement) => {
        if (c1.rowIndex < c2.rowIndex) {
          return -1;
        } else if (c1.rowIndex > c2.rowIndex) {
          return 1;
        } else {
          if (c1.columnIndex < c2.columnIndex) {
            return -1;
          } else {
            return 1;
          }
        }
      });
    return a;
  }

  public getColumnCount(): number {
    return this.columnCount;
  }

  public setColumnCount(columnCount: number): this {
    this.columnCount = columnCount;
    return this;
  }

  public getContent(): ILayoutContent {
    return this.content;
  }

  public setContent(content: ILayoutContent): this {
    this.content = content;
    return this;
  }

  public getRowCount(): number {
    return this.rowCount;
  }

  public setRowCount(rowCount: number): this {
    this.rowCount = rowCount;
    return this;
  }

  public getHeight(): number {
    return this.height;
  }

  public setHeight(height: number): this {
    this.height = height;
    return this;
  }

  constructor() {
    super();
    this.setLayoutType(ELayoutType.GRID_LAYOUT);
  }
}
