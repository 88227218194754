import { ETemplateType } from 'frontend/src/dashboard/model/resource/template-type';
import { ContentElement } from '../content/content-element/content-element';
import { ListSelectorElement } from './list-selector-element';

export class ListSelector extends ContentElement {
  private multiSelect: boolean;
  private selectAllOption: boolean;
  private selectedValues: any;
  private value: ListSelectorElement[];
  private elementData: any;

  public isMultiSelect(): boolean {
    return typeof this.multiSelect === 'boolean' ? this.multiSelect : false;
  }

  public setMultiSelect(multiSelect: boolean): this {
    this.multiSelect = multiSelect;
    return this;
  }

  public isSelectAllOption(): boolean {
    return typeof this.selectAllOption === 'boolean' ? this.selectAllOption : false;
  }

  public setSelectAllOption(selectAllOption: boolean): this {
    this.selectAllOption = selectAllOption;
    return this;
  }

  public setSelectedValues(selectedValues: any): this {
    this.selectedValues = selectedValues;
    return this;
  }

  public getSelectedValues(): any[] {
    return this.selectedValues;
  }

  public setElementData(elementData: any): this {
    this.elementData = elementData;
    return this;
  }

  public getElementData(): any[] {
    return this.elementData;
  }

  public setValue(value: ListSelectorElement[]): this {
    this.value = value;
    return this;
  }

  public getValue(): ListSelectorElement[] {
    return this.value;
  }

  constructor() {
    super();
    this.setType(ETemplateType.LIST_SELECTOR);
  }
}
