import { Observable, Subject } from 'rxjs';
import { DataHandler } from '../data-handler/data-handler';
import { GanttCanvasRow, GanttCanvasShift } from '../data-handler/data-structure/data-structure';
import { NodeProportionsStateConnector } from '../html-structure/node-proportion-state/node-proportion-state-connector';
import { EGanttScrollContainer } from '../html-structure/scroll-container.enum';
import { BestGantt } from '../main';
import { GanttStickyRowHandler } from '../y-axis/sticky-rows/sticky-row-handler';
import { RenderDataCanvasTransform } from './canvas-transform/render-data-canvas-transform';
import { ShiftRenderDataFinder } from './data-finder/render-data-finder-shifts';
import { YAxisRenderDataFinder } from './data-finder/render-data-finder-y-axis';
import { GanttRenderDataSetShifts, GanttRenderDataSetYAxis } from './data-structure/render-data-structure';
import { RenderDataStateStorage } from './render-data-state-storage';

/**
 * Calculator to generate render canvas dataset out of canvas dataset.
 * It cuts out the elements which are not inside the scrolled view of gantt.
 * @keywords render, dataset, view, visible, area, canvas, shifts
 */
export class RenderDataHandler {
  private _stickyRowHandler: GanttStickyRowHandler = undefined;
  private _stateStorage: RenderDataStateStorage = undefined;
  private _canvasTransform: RenderDataCanvasTransform = new RenderDataCanvasTransform();

  private _dataFinderYAxis: YAxisRenderDataFinder = undefined;
  private _dataFinderShifts: ShiftRenderDataFinder = undefined;

  private _renderDataSetShifts: GanttRenderDataSetShifts = undefined;
  private _renderDataSetYAxis: GanttRenderDataSetYAxis = undefined;

  private _onFilterRenderDataSetShiftsSubject = new Subject<GanttRenderDataSetShifts>();

  /**
   * @param _ganttDiagram
   */
  constructor(private _ganttDiagram: BestGantt) {
    this._stateStorage = new RenderDataStateStorage(this._ganttDiagram);

    this._dataFinderYAxis = new YAxisRenderDataFinder(this._ganttDiagram);
    this._dataFinderShifts = new ShiftRenderDataFinder(this._ganttDiagram);
  }

  public init(): void {
    this._stickyRowHandler = new GanttStickyRowHandler(this._ganttDiagram);
    this._stickyRowHandler.init();
  }

  /**
   * Generates the render dataset for the gantt y axis by the given data.
   * @param dataSetYAxis Current y axis canvas dataset.
   */
  public generateRenderDataSetYAxis(dataSetYAxis: GanttCanvasRow[]): void {
    this._renderDataSetYAxis = this._generateRenderDataSetYAxis(dataSetYAxis);
  }

  /**
   * Generates the render dataset for gantt shifts by the given data.
   * @param dataSetShifts Current shift canvas dataset.
   * @param xMin Horizontal start position of current gantt field of view.
   * @param xMax Horizontal end position of current gantt field of view.
   * @param scaleX Horizontal scale.
   * @param yMin Vertical start position of current gantt field of view.
   * @param yMax Vertical end position of current gantt field of view.
   * @param scaleY Vertical scale.
   */
  public generateRenderDataSetShifts(
    dataSetShifts: GanttCanvasShift[],
    xMin: number,
    xMax: number,
    scaleX: number,
    yMin: number,
    yMax: number,
    scaleY: number
  ): void {
    const viewportProportions: IViewPortProportions = { yMin: yMin, yMax: yMax, xMin: xMin, xMax: xMax };
    this._renderDataSetShifts = this._generateRenderDataSetShifts(dataSetShifts, viewportProportions, scaleX, scaleY);
  }

  /**
   * Generates render dataset based on canvas dataset.
   * Works only with y-sorted array for shifts.
   * This function has to be as efficient as possible to make fluid scrolling possible.
   * @keywords render, canvas, view, dataset, data
   * @param currentDataSet Canvas element list with elements which have x, y and width property.
   * @param viewportProportions
   * @param scaleX Horizontal scale.
   * @param scaleY Vertical scale.
   * @returns Generated render dataset for gantt shifts.
   */
  private _generateRenderDataSetShifts(
    currentDataSet: GanttCanvasShift[],
    viewportProportions: Map<EGanttScrollContainer, IViewPortProportions> | IViewPortProportions,
    scaleX: number,
    scaleY: number
  ): GanttRenderDataSetShifts {
    const renderDataSetShifts = new GanttRenderDataSetShifts();
    this._stateStorage.clearYPositionShiftStorage();
    this._stateStorage.clearStickyRowShiftStorage();

    let renderedDataSet: GanttCanvasShift[] = [];
    if (!currentDataSet) return;

    renderedDataSet = currentDataSet.filter((element) => {
      const isShiftInStickyRow = this._isShiftInStickyRow(element, this._renderDataSetYAxis);
      const viewport =
        viewportProportions instanceof Map
          ? isShiftInStickyRow
            ? viewportProportions.get(EGanttScrollContainer.STICKY_ROWS)
            : viewportProportions.get(EGanttScrollContainer.DEFAULT)
          : viewportProportions;

      // check visibility
      if (element.noRender?.length) {
        return false;
      }

      // check if element is inside the view

      // horizontally out of bounds check
      const scaledXStart = element.x * scaleX;
      const scaledXEnd = (element.x + element.width) * scaleX;

      if (scaledXStart < viewport.xMin && scaledXEnd < viewport.xMin) {
        return false;
      }
      if (scaledXStart > viewport.xMax && scaledXEnd > viewport.xMax) {
        return false;
      }

      // vertically out of bounds check
      const y = this._getRecalculatedShiftYValue(element);
      const scaledYStart = y * scaleY;
      const scaledYEnd = (y + element.height) * scaleY;

      if (scaledYStart < viewport.yMin && scaledYEnd < viewport.yMin) {
        return false;
      }
      if (scaledYStart > viewport.yMax && scaledYEnd > viewport.yMax) {
        return false;
      }

      return true;
    });

    // distribute shifts on containers (depending on config)
    const showStickyRows = this._ganttDiagram.getConfig().showStickyRows();
    renderDataSetShifts[EGanttScrollContainer.DEFAULT] = renderedDataSet.filter((shift) => {
      const isShiftNotInStickyRow =
        !showStickyRows || !this._stateStorage.isRowSticky(this._dataHandler.getShiftRowStorage().get(shift.id));
      return isShiftNotInStickyRow;
    });
    renderDataSetShifts[EGanttScrollContainer.STICKY_ROWS] = renderedDataSet.filter((shift) => {
      const isShiftInStickyRow =
        showStickyRows && this._stateStorage.isRowSticky(this._dataHandler.getShiftRowStorage().get(shift.id));
      if (isShiftInStickyRow) {
        this._stateStorage.addStickyRowShift(shift.id);
      } else {
        this._stateStorage.addNotStickyRowShift(shift.id);
      }
      return isShiftInStickyRow;
    });

    // recalculate shift y values
    this._remapShiftYValues(renderDataSetShifts[EGanttScrollContainer.DEFAULT]);
    this._remapShiftYValues(renderDataSetShifts[EGanttScrollContainer.STICKY_ROWS], true);

    return renderDataSetShifts;
  }

  /**
   * Recalculates the y values of the specified shifts when the row y values were manipulated because of sticky rows.
   * These recalculated y values will be saved into the render data state storage.
   * @param renderData Array of canvas shifts with the y values that should be recalculated.
   * @param areShiftsInStickyRows If `true`, the y values of the given shifts will be calculated for the sticky rows scroll container.
   */
  private _remapShiftYValues(renderData: GanttCanvasShift[], areShiftsInStickyRows = false): void {
    renderData.forEach((shift) => {
      const remappedY = areShiftsInStickyRows ? this._getRecalculatedShiftYValue(shift) : shift.y;
      this._stateStorage.setYPositionShift(shift.id, remappedY);
    });
  }

  /**
   * Recalculates the y value of the specified shift when the row y values were manipulated because of sticky rows.
   * @param shift Canvas shift with the y value that should be recalculated.
   * @returns Recalculated y value of the specified shift.
   */
  private _getRecalculatedShiftYValue(shift: GanttCanvasShift): number {
    const rowId = this._dataHandler.getShiftRowStorage().get(shift.id);
    const rowY = this._stateStorage.getYPositionRow(rowId);
    return shift.isFullHeight ? rowY : rowY + this._ganttDiagram.getConfig().getLineTop();
  }

  /**
   * Checks if the specified canvas shift is part of a sticky row or not.
   * @param shift Canvas shift to be checked.
   * @param renderDataSetYAxis Y axis render dataset to use for the check.
   * @returns True if the specified shift is part of a sticky row, otherwise false.
   */
  private _isShiftInStickyRow(shift: GanttCanvasShift, renderDataSetYAxis: GanttRenderDataSetYAxis): boolean {
    const rowId = this._dataHandler.getShiftRowStorage().get(shift.id);
    const shiftRow = renderDataSetYAxis[EGanttScrollContainer.STICKY_ROWS].find((row) => row.id === rowId);
    return !!shiftRow;
  }

  /**
   * Generates render dataset based on canvas dataset.
   * Pays attention only to data-removing in vertical direction.
   * Works only with y-sorted array for shifts, milestones and y axis rows.
   * This function has to be as efficient as possible to make fluid scrolling possible.
   * @keywords render, dataset, image, data, vertical
   * @param currentDataSet Canvas element list with elements which have y property.
   * @returns Generated render dataset for the gantt y axis.
   */
  private _generateRenderDataSetYAxis(currentDataSet: GanttCanvasRow[]): GanttRenderDataSetYAxis {
    const renderDataSetYAxis = new GanttRenderDataSetYAxis();
    this._stateStorage.clearYPositionRowStorage();
    this._stateStorage.clearStickyRowStorage();

    // filter out hidden rows
    currentDataSet = currentDataSet.filter(function (element) {
      return !element.noRender || !element.noRender.length;
    });

    // apply sticky rows
    currentDataSet = this._stickyRowHandler.applyStickyRows(currentDataSet);

    // distribute rows on containers (depending on config)
    const showStickyRows = this._ganttDiagram.getConfig().showStickyRows();
    renderDataSetYAxis[EGanttScrollContainer.DEFAULT] = currentDataSet.filter((row) => !showStickyRows || !row.sticky);
    renderDataSetYAxis[EGanttScrollContainer.STICKY_ROWS] = currentDataSet.filter((row) => {
      if (showStickyRows && row.sticky) {
        this._stateStorage.addStickyRow(row.id);
        return true;
      }
      this._stateStorage.addNotStickyRow(row.id);
      return false;
    });

    // recalculate row y values
    this._remapCanvasRowYValues(renderDataSetYAxis[EGanttScrollContainer.DEFAULT]);
    this._remapCanvasRowYValues(renderDataSetYAxis[EGanttScrollContainer.STICKY_ROWS], true);

    return renderDataSetYAxis;
  }

  /**
   * Takes the given canvas row dataset and maps its y values to an array of continuous y values.
   * These recalculated y values will be saved into the render data state storage.
   * @param rowData Canvas row dataset to remap.
   * @param areRowsSticky If `true`, the y values of the given rows will be calculated for the sticky rows scroll container.
   */
  private _remapCanvasRowYValues(rowData: GanttCanvasRow[], areRowsSticky = false): void {
    if (!rowData || rowData.length <= 0) return;

    if (areRowsSticky) {
      let yOffset = 0;
      rowData.forEach((row) => {
        const remappedY = yOffset;
        this._stateStorage.setYPositionRow(row.id, remappedY);
        yOffset += row.height;
      });
    } else {
      rowData.forEach((row) => this._stateStorage.setYPositionRow(row.id, row.y));
    }
  }

  /**
   * Filters render data set depending on current view.
   * @param ganttDiagram
   * @param offset
   */
  public filterRenderDataSetsByCurrentView(ganttDiagram: BestGantt, offset = 0): void {
    const g = ganttDiagram;
    const viewportProportionsMap = new Map<EGanttScrollContainer, IViewPortProportions>();

    for (const scrollContainerId of Object.values(EGanttScrollContainer)) {
      const nodeProportionsState = new NodeProportionsStateConnector(g.getNodeProportionsState(), scrollContainerId);
      const canvasTransform = this._canvasTransform.access(scrollContainerId);

      const canvasWidth = nodeProportionsState.getShiftViewPortProportions().width;
      const canvasParentHeight = nodeProportionsState.getShiftViewPortProportions().height;

      const scrollTop = nodeProportionsState.getScrollTopPosition();
      canvasTransform.top = scrollTop;

      const viewportProportions: IViewPortProportions = {
        yMin: canvasTransform.top - offset,
        yMax: canvasTransform.top + canvasParentHeight + offset,
        xMin: canvasTransform.left,
        xMax: canvasTransform.left + canvasWidth,
      };
      viewportProportionsMap.set(scrollContainerId, viewportProportions);
    }

    this._renderDataSetShifts = this._generateRenderDataSetShifts(
      g.getDataHandler().getCanvasShiftDataset(),
      viewportProportionsMap,
      this._canvasTransform.getScaleX(),
      this._canvasTransform.getScaleY()
    );

    this._onFilterRenderDataSetShiftsSubject.next(this._renderDataSetShifts);
  }

  //
  // SCROLL CONTAINER HANDLING
  //

  /**
   * Returns a set containing the ids of all available scroll containers.
   * @returns Set containing the ids of all available scroll containers.
   */
  public getAllScrollContainerIds(): Set<EGanttScrollContainer> {
    const scrollContainerIds = new Set<EGanttScrollContainer>();

    for (const scrollContainerId of Object.values(EGanttScrollContainer)) {
      scrollContainerIds.add(scrollContainerId);
    }
    return scrollContainerIds;
  }

  /**
   * Returns a set containing the ids of all scroll containers which are currently used (but may not necessarily be visible).
   * @returns Set containing the ids of all scroll containers which are currently used (but may not necessarily be visible).
   */
  public getActiveScrollContainerIds(): Set<EGanttScrollContainer> {
    const scrollContainerIds = new Set<EGanttScrollContainer>([EGanttScrollContainer.DEFAULT]);

    if (this._ganttDiagram.getConfig().showStickyRows()) {
      scrollContainerIds.add(EGanttScrollContainer.STICKY_ROWS);
    }
    return scrollContainerIds;
  }

  /**
   * Returns a set containing the ids of all scroll containers which are currently visible.
   * @returns Set containing the ids of all scroll containers which are currently visible.
   */
  public getVisibleScrollContainerIds(): Set<EGanttScrollContainer> {
    const scrollContainerIds = new Set<EGanttScrollContainer>([EGanttScrollContainer.DEFAULT]);

    if (
      this._ganttDiagram.getConfig().showStickyRows() &&
      this._renderDataSetYAxis[EGanttScrollContainer.STICKY_ROWS] &&
      this._renderDataSetYAxis[EGanttScrollContainer.STICKY_ROWS].length > 0
    ) {
      scrollContainerIds.add(EGanttScrollContainer.STICKY_ROWS);
    }
    return scrollContainerIds;
  }

  //
  // GETTER & SETTER
  //

  /**
   * Helper getter which returns the data handler of the current gantt.
   */
  private get _dataHandler(): DataHandler {
    return this._ganttDiagram.getDataHandler();
  }

  /**
   * Returns the state storage of this render data handler.
   * @returns State storage of this render data handler.
   */
  public getStateStorage(): RenderDataStateStorage {
    return this._stateStorage;
  }

  /**
   * Returns the canvas transform of this render data handler.
   * @return Canvas transform of this render data handler.
   */
  public getCanvasTransform(): RenderDataCanvasTransform {
    return this._canvasTransform;
  }

  /**
   * Returns the y axis render data finder of this render data handler.
   * @returns Y axis render data finder of this render data handler.
   */
  public getYAxisDataFinder(): YAxisRenderDataFinder {
    return this._dataFinderYAxis;
  }

  /**
   * Returns the shift render data finder of this render data handler.
   * @returns Shift render data finder of this render data handler.
   */
  public getShiftDataFinder(): ShiftRenderDataFinder {
    return this._dataFinderShifts;
  }

  /**
   * Returns the current render dataset for gantt shifts.
   * @returns Current render dataset for gantt shifts.
   */
  public getRenderDataSetShifts(): GanttRenderDataSetShifts {
    return this._renderDataSetShifts;
  }

  /**
   * Returns the current render dataset for gantt rows.
   * @returns Current render dataset for gantt rows.
   */
  public getRenderDataSetYAxis(): GanttRenderDataSetYAxis {
    return this._renderDataSetYAxis;
  }

  public getRenderDataShifts(scrollContainerId: EGanttScrollContainer): GanttCanvasShift[] {
    return this._renderDataSetShifts[scrollContainerId];
  }

  public getRenderDataYAxis(scrollContainerId: EGanttScrollContainer): GanttCanvasRow[] {
    return this._renderDataSetYAxis[scrollContainerId];
  }

  //
  // OBSERVABLES
  //

  /**
   * Observable which will be triggered when a new shift render dataset got generated by filtering
   * shifts by the shift viewport proportions.
   */
  public get onFilterRenderDataSetShifts(): Observable<GanttRenderDataSetShifts> {
    return this._onFilterRenderDataSetShiftsSubject.asObservable();
  }
}

/**
 * Data structure for viewport proportions used to filter shifts by the current viewport.
 */
interface IViewPortProportions {
  xMin: number;
  xMax: number;
  yMin: number;
  yMax: number;
}
