import { TemplateValue } from './value';

export class EntryElementEntry extends TemplateValue {
  private type: string;
  private uuid: string;
  private color: string;

  public getColor(): string {
    return this.color;
  }

  public setColor(color: string): this {
    this.color = color;
    return this;
  }

  public getType(): string {
    return this.type;
  }

  public setType(type: string): this {
    this.type = type;
    return this;
  }

  public getUuid(): string {
    return this.uuid;
  }

  public setUuid(uuid: string): this {
    this.uuid = uuid;
    return this;
  }
}
