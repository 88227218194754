<div #hotspotWidget class="hotspot-wrapper">
  <div class="hotspot-toolbar-container"></div>

  <div class="hotspot-wrapper-content" [ngStyle]="{ height: template.getHeight() }">
    <div
      class="hotspot-wrapper-sidebar-wrapper"
      [ngStyle]="{
        float: currentSubmenu.getSideBar().position === 'RIGHT' ? 'right' : 'left',
        height: template ? template.getHeight() + 'px' : '500px'
      }"
      *ngIf="toolbarContentVisible"
    >
      <ng-container [ngSwitch]="getSideBarType()">
        <ng-container *ngSwitchCase="'region'">
          <template-hotspot-region-sidebar
            [currentAttribute]="currentAttribute"
            (afterAttributeSelect)="afterAttributeSelected($event)"
            [regions]="template.getRegions()"
            [attributeCategories]="template.getAttributeCategories()"
          ></template-hotspot-region-sidebar>
        </ng-container>
        <ng-container *ngSwitchCase="'colorscheme'">
          <template-hotspot-colorscheme-sidebar
            (afterColorValueChanges)="afterColorValueChanges()"
            [currentAttribute]="currentAttribute"
            (afterChangeCurrentAttribute)="afterAttributeSelected($event)"
            [attributeCategories]="template.getAttributeCategories()"
          ></template-hotspot-colorscheme-sidebar>
        </ng-container>
      </ng-container>
    </div>

    <div
      class="hotspot-wrapper-content-element"
      [ngStyle]="{ width: toolbarContentVisible ? 'calc(100% - 208px)' : '100%' }"
    >
      <template-hotspot
        *ngIf="template"
        #hotspotTemplate
        [template]="template"
        (onSelectPolygon)="setCurrentPolygon($event)"
        (onSelectNode)="setCurrentNode($event)"
        [currentAttribute]="currentAttribute"
        (onChanges)="onChanges($event)"
      >
      </template-hotspot>
    </div>
  </div>
</div>
