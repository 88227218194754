import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SaxmsTooltipModule } from '@app-modeleditor/components/tooltip/tooltip.module';
import { MaterialModule } from '@core/material/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSplitModule } from 'angular-split';
import { NgChartsModule } from 'ng2-charts';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { PieChartComponent } from './pie-chart.component';

@NgModule({
  imports: [
    CommonModule,
    NgChartsModule,
    TranslateModule,
    NgScrollbarModule,
    AngularSplitModule,
    SaxmsTooltipModule,
    MaterialModule,
  ],
  declarations: [PieChartComponent],
  exports: [PieChartComponent],
})
export class PieChartModule {}
