import { EntryElement } from '../entry-collection/entry-element';
import { EFieldType } from '../entry-collection/field-type.enum';
import { ESpinnerMode } from './spinner-mode.enum';

export class Spinner extends EntryElement {
  private mode: ESpinnerMode;

  public getMode(): ESpinnerMode {
    return this.mode;
  }

  public setMode(mode: ESpinnerMode): this {
    this.mode = mode;
    return this;
  }

  constructor() {
    super();
    this.setMode(ESpinnerMode.INDETERMINATE).setFieldType(EFieldType.SPINNER);
  }
}
