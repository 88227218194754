export class SaxMsSelectionModel {
  public columnActionsEnable = false;
  public rowActionsEnable = false;
  public batchRowActionsEnable = false;
  public selectionColumns: Map<any, Set<number>> = new Map();
  private hiddenColumnCount = 0;

  constructor(public columns: any[], public rowCount: number, public hiddenColumn: any[]) {}

  public setColumns(columns: any[]) {
    this.columns = columns.slice();
  }

  public isRowActionsEnabled(): boolean {
    return this.rowActionsEnable;
  }

  public setRowCount(rowCount: number) {
    this.rowCount = rowCount;
  }

  public refreshSelection(spreadsheetInstance: any, selectionAreas: any[], rowCount: number, hiddenColumn: any[]) {
    this.rowCount = rowCount;
    this.hiddenColumnCount = hiddenColumn.length;
    this.clearSelection();

    if (!selectionAreas) {
      return;
    }
    for (const area of selectionAreas) {
      area[0] = area[0] === -1 ? 0 : area[0];
      const indices = {
        startCol: area[1] < area[3] ? area[1] : area[3],
        endCol: area[1] > area[3] ? area[1] : area[3],
        startRow: area[0] < area[2] ? area[0] : area[2],
        endRow: area[0] > area[2] ? area[0] : area[2],
      };
      for (indices.startCol; indices.startCol <= indices.endCol; indices.startCol++) {
        if (hiddenColumn.includes(indices.startCol)) {
          continue;
        }

        if (!this.columns[spreadsheetInstance.toPhysicalColumn(indices.startCol)]) {
          continue;
        }
        const propertyName = this.columns[spreadsheetInstance.toPhysicalColumn(indices.startCol)].data;
        let index = JSON.parse(JSON.stringify(indices.startRow));

        for (index; index <= indices.endRow; index++) {
          if (this.selectionColumns.has(propertyName)) {
            this.selectionColumns.get(propertyName).add(spreadsheetInstance.toPhysicalRow(index));
          } else {
            const rowSet = new Set<number>();
            rowSet.add(spreadsheetInstance.toPhysicalRow(index));
            this.selectionColumns.set(propertyName, rowSet);
          }
        }
      }
    }

    this.checkActions();
  }

  public clearSelection() {
    this.rowActionsEnable = false;
    this.columnActionsEnable = false;
    this.batchRowActionsEnable = false;

    this.selectionColumns.clear();
  }

  private checkActions() {
    this.batchRowActionsEnable = false;
    if (!this.selectionColumns.values().next().value) {
      return;
    }
    const selectionRowSize = this.selectionColumns.values().next().value.size;
    this.columnActionsEnable =
      (this.selectionColumns.size === 1 && selectionRowSize === this.rowCount) ||
      (!Array.from(this.selectionColumns.get(Array.from(this.selectionColumns.keys())[0]).values())[0] &&
        this.rowCount === 0);

    if (this.selectionColumns.size === this.columns.length - this.hiddenColumnCount) {
      const rowNumber = Array.from(this.selectionColumns.get(Array.from(this.selectionColumns.keys())[0]))[0];
      for (const key of Array.from(this.selectionColumns.keys())) {
        if (this.selectionColumns.get(key).has(rowNumber)) {
          if (this.selectionColumns.get(key).size === 1) {
            this.rowActionsEnable = true;
            this.batchRowActionsEnable = true;
          } else if (this.selectionColumns.get(key).size > 1) {
            this.batchRowActionsEnable = true;
            this.rowActionsEnable = false;
          }
        } else {
          this.rowActionsEnable = false;
          return;
        }
      }
    }
  }

  public getSelectedCells() {
    const selectedCells = [];

    for (const col of Array.from(this.selectionColumns.keys())) {
      for (const row of Array.from(this.selectionColumns.get(col))) {
        selectedCells.push({
          col: col,
          row: row,
        });
      }
    }

    return selectedCells;
  }
}
