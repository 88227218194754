import { GanttDataRow, GanttDataShift } from '../../../data-handler/data-structure/data-structure';
import { DataManipulator } from '../../../data-handler/data-tools/data-manipulator';
import { ShiftDataSorting } from '../../../data-handler/data-tools/data-sorting';
import { GanttUtilities } from '../../../gantt-utilities/gantt-utilities';
import { GanttSplitOverlappingShiftGroup, GanttSplitOverlappingShifts } from '../split-overlapping-shifts-executer';
import { BaseOverlappingStrategy } from './base';

/**
 * Strategy to sort the blocks so that they are grouped by attribute.
 */
export class GanttSplitOverlappingShiftsByAttributeStrategy implements BaseOverlappingStrategy {
  public readonly strategyType = 'sortByAttribute';

  public splitOverlappingShifts(executer: GanttSplitOverlappingShifts, rowIds?: string[]): void {
    executer.parentShiftHandler.clearParentShiftRowSpanData();

    const splitShifts = (child, level, parent, index) => {
      if (executer.ruleSet.notAffectedRows.includes(child.id) || (rowIds?.length && !rowIds?.includes(child.id)))
        return;

      if (!child.shifts || child.shifts.length < 2) return;
      const noRenderShifts: GanttDataShift[] = child.shifts.filter((shift) => shift.noRender?.length);
      child.shifts = child.shifts.filter((shift) => !shift.noRender?.length);
      const newRows = this.splitRowByAttribute(child, executer.sortAttribute, executer.UUID, noRenderShifts, executer);

      // save reset data
      const newRowEntry = new GanttSplitOverlappingShiftGroup(child.id);
      newRowEntry.groupedRowIds = newRows.filter((d, i) => i > 0).map((d) => d.id);
      executer.shiftGroups.push(newRowEntry);

      // insert new rows
      const parentRow = level == 0 ? parent.ganttEntries : parent.child;
      parentRow.splice(index.index, 1, ...newRows);
      index.index += newRows.length - 1;
    };

    DataManipulator.iterateOverDataSet(
      executer.ganttDiagram.getDataHandler().getOriginDataset().ganttEntries,
      null,
      { splitByAttribute: splitShifts },
      null,
      executer.ganttDiagram.getDataHandler().getOriginDataset()
    );
  }

  private splitRowByAttribute(
    row: GanttDataRow,
    attribute: number,
    pluginId: string,
    noRenderShifts: GanttDataShift[],
    executer: GanttSplitOverlappingShifts
  ): GanttDataRow[] {
    const newRows: GanttDataRow[] = [];

    // update parent shift row span data for the current row
    const parentShiftRowSpanMap = executer.parentShiftHandler.getParentShiftRowSpans(row.shifts);

    // generate map containing blocks sorted by attribute value
    // it also determines the respective start and end of the entire attribute group

    const linkedParentShifts = executer.parentShiftHandler.getLinkedParentShifts(row.shifts);
    const valuesShiftMap = new Map<string, { shifts: GanttDataShift[]; start: number; end: number }>();
    row.shifts.forEach((shift) => {
      const attributeValue = linkedParentShifts.get(shift.id)
        ? linkedParentShifts.get(shift.id).additionalData?.additionalData?.additionalDetails[attribute]?.t2
        : shift.additionalData?.additionalData?.additionalDetails[attribute]?.t2;
      if (valuesShiftMap.has(attributeValue)) {
        const value = valuesShiftMap.get(attributeValue);
        value.shifts.push(shift);
        value.start = Math.min(value.start, shift.timePointStart.getTime());
        value.end = Math.max(value.end, shift.timePointEnd.getTime());
      } else {
        valuesShiftMap.set(attributeValue, {
          shifts: [shift],
          start: shift.timePointStart.getTime(),
          end: shift.timePointEnd.getTime(),
        });
      }
    });

    // clear shifts of original row
    row.shifts = [];

    // create attribute groups for each attribute value
    const attributeGroups: {
      shifts: GanttDataShift[];
      start: number;
      end: number;
      attribute: string;
      rows: GanttDataShift[][];
    }[][] = [[]];

    valuesShiftMap.forEach((value, key) => {
      for (let i = 0; i < attributeGroups.length; i++) {
        if (attributeGroups[i].find((elem) => !(value.start >= elem.end || value.end <= elem.start))) {
          // overlap
          if (i === attributeGroups.length - 1) {
            attributeGroups.push([]); // extend time spans
          }
          continue;
        } else {
          // no overlap
          attributeGroups[i].push({ ...value, rows: [[]], attribute: key });
          break;
        }
      }
    });

    const waitingChildsMap = new Map<string, GanttDataShift[]>();
    attributeGroups.forEach((attributeGroup) => {
      attributeGroup.forEach((line) => {
        const shiftRowIndexMap = new Map<string, number>();
        line.shifts.forEach((shift) => {
          const linkedParentShift = linkedParentShifts.get(shift.id);
          // if shift has linked parent shift -> push shift to same row as parent shift
          if (linkedParentShift) {
            const shiftRowIndex = shiftRowIndexMap.get(linkedParentShift.id);
            // if parent shift already was pushed -> push shift
            if (!isNaN(shiftRowIndex)) {
              executer.parentShiftHandler.insertChildShiftIntoParentShiftInShiftArray(
                shift,
                shiftRowIndex,
                line.rows,
                shiftRowIndexMap
              );
            }
            // if pushing the parent shift is pending -> wait until parent shift is added
            else {
              if (!waitingChildsMap.get(linkedParentShift.id)) waitingChildsMap.set(linkedParentShift.id, []);
              waitingChildsMap.get(linkedParentShift.id).push(shift);
            }
            return;
          }
          // if shift does not have a linked parent shift -> add split shifts in the default way
          for (let i = 0; i < line.rows.length; i++) {
            const virtualParentShifts = executer.parentShiftHandler.getVirtualParentShiftsForRow(
              i,
              shiftRowIndexMap,
              parentShiftRowSpanMap
            );
            if (
              line.rows[i].find((elem) =>
                executer.overlapsShift(elem, shift, waitingChildsMap.get(elem.id), waitingChildsMap.get(shift.id))
              ) ||
              virtualParentShifts.find((elem) => executer.overlapsShift(elem, shift))
            ) {
              // overlap
              if (i === line.rows.length - 1) {
                line.rows.push([]); // extend
              }
              continue;
            } else {
              // no overlap
              line.rows[i].push(shift);
              shiftRowIndexMap.set(shift.id, i);
              break;
            }
          }
          // if shift is parent shift that child shifts are waiting for -> add childs
          if (waitingChildsMap.get(shift.id)) {
            const shiftRowIndex = shiftRowIndexMap.get(shift.id);
            for (const childShift of waitingChildsMap.get(shift.id)) {
              executer.parentShiftHandler.insertChildShiftIntoParentShiftInShiftArray(
                childShift,
                shiftRowIndex,
                line.rows,
                shiftRowIndexMap
              );
            }
            waitingChildsMap.delete(shift.id);
          }
        });
      });
    });

    // create new rows
    attributeGroups.forEach((attributeGroup, index) => {
      const baseRow = index === 0 ? row : GanttUtilities.createNewRowFromRow(row, `${pluginId}_${index}`, 'MEMBER');
      const rowsToPush: GanttDataRow[] = [baseRow];
      attributeGroup.forEach((timeSpan) => {
        timeSpan.rows.forEach((shifts, i) => {
          if (rowsToPush[i]) {
            rowsToPush[i].shifts.push(...shifts);
            rowsToPush[i].shifts.forEach((shift) => {
              // if shift is parent shift -> add row id to parent shift row span data
              if (parentShiftRowSpanMap.get(shift.id)) {
                parentShiftRowSpanMap.get(shift.id).rowId = rowsToPush[i].id;
              }
            });
          } else {
            const newRow = GanttUtilities.createNewRowFromRow(row, `${pluginId}_${index}_${i}`, 'MEMBER');
            newRow.shifts.push(...shifts);
            newRow.shifts.forEach((shift) => {
              // if shift is parent shift -> add row id to parent shift row span data
              if (parentShiftRowSpanMap.get(shift.id)) {
                parentShiftRowSpanMap.get(shift.id).rowId = newRow.id;
              }
            });
            rowsToPush.push(newRow);
          }
        });
      });
      newRows.push(...rowsToPush);
    });

    // Add the calculated parent shift row span data to the parent shift handler.
    executer.parentShiftHandler.addParentShiftRowSpanData(parentShiftRowSpanMap);

    // If the list with with not rendered shifts has been given, add them to first row.
    // The overlapping is not relevant here, because the user doesn't see them ;)
    if (noRenderShifts) {
      newRows[0].shifts = newRows[0].shifts.concat(noRenderShifts);
      ShiftDataSorting.sortJSONListByDate(newRows[0].shifts, 'timePointStart');
    }

    // Add grouping-row-layout if there are additional rows.
    if (newRows.length > 1) {
      const children = newRows[0].child;
      newRows[0].group = newRows[0].child.length ? 'BASE-TREE' : 'BASE-LEAF';
      for (let i = 0; i < newRows.length - 1; i++) {
        newRows[i].child = [];
      }
      // add origin children
      newRows[newRows.length - 1].child = children;
    }

    return newRows;
  }
}
