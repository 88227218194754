import { Action } from '@app-modeleditor/components/button/action/action';
import { Button } from '@app-modeleditor/components/button/button';
import { EButtonDisplayType } from '@app-modeleditor/components/button/button-display-type.enum';
import { ColumnListSelectorElement } from '@app-modeleditor/components/column-list-selector/column-list-selector-element';
import { EEntryElementPosition } from '@app-modeleditor/components/entry-collection/entry-element-position.enum';
import { EGanttTextStrategy, GanttDataRow, GanttDataShift } from '@gantt/public-api';
import { SaxMsBestGanttSettings } from 'frontend/src/dashboard/gantt/gantt/saxms-best-gantt.settings';
import { of } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Gantt_General } from '../../general.gantt.component';
import { GanttEssentialPlugIns } from '../../plugin/e-gantt-essential-plugins';
import { GanttOverlappingShiftsPlugIn } from '../../plugin/plugin-list/overlapping-shifts/overlapping-shifts';
import { VisibleAttributeChangerLightbox } from './visible-attribute-lightbox.element';
import { EGanttBlockAttributeDataType } from '../filter/lightbox/attribute-data-types.enum';

interface IBlockAttribute {
  t1: boolean;
  t2: any;
}

export class VisibleAttributeChangerButton extends Button {
  private lastVisibleIds: number[];
  private $lightbox: VisibleAttributeChangerLightbox;
  constructor(private scope: Gantt_General) {
    super();
  }

  get(data: any): this {
    this.setName('Blocknamen konfigurieren')
      .setAlwaysEnabled(true)
      .setIcon('edit')
      .setDisplayType(EButtonDisplayType.ICON_WITH_LABEL_BELOW)
      .chainActions(
        new Action().setCb(() => {
          this.$lightbox = new VisibleAttributeChangerLightbox(this.scope);
          this.$lightbox.checkEntries(this.lastVisibleIds);

          // set action for applying changes of selection
          this.$lightbox.setOnCustomSaveAction(
            new Button()
              .setPosition(EEntryElementPosition.RIGHT)
              .setName('Anwenden')
              .chainActions(
                new Action().setCb(() => {
                  this.lastVisibleIds = this.$lightbox
                    .getColumnListSelector()
                    .getValue()
                    .getSelectedListEntryElements()
                    .map((e: ColumnListSelectorElement) => parseInt(e.getId()));
                  this.$updateGantt(true);
                  this.saveSettings();
                  return of(this.$lightbox.getRef().close(this.lastVisibleIds));
                })
              )
          );

          this.scope.lightboxApi.open(this.$lightbox);

          return of(null);
        })
      );

    // subscribe to new settings
    this.scope.ganttSettingsService
      .onNewSettings()
      .pipe(takeUntil(this.scope.onDestroy))
      .subscribe((settings: SaxMsBestGanttSettings) => {
        if (!settings) {
          return;
        }
        const newIds: number[] = this.$convertStringToList(settings?.visibleAttributeId);
        if (this.$isDifferent(this.lastVisibleIds, newIds) === false) {
          return;
        }

        this.lastVisibleIds = newIds;

        this.$lightbox?.checkEntries(this.lastVisibleIds);
        this.$updateGantt(true);
      });

    // subscribe to data change -> to update new inserted blocks
    this.scope.ganttTemplateDataService
      .listenToHierarchicalPlanUpdates()
      .pipe(takeUntil(this.scope.onDestroy))
      .subscribe((_) => {
        this.$updateGantt(false); // dont rerender because listenToHierarchicalPlanUpdates is part of update process
      });

    return this;
  }

  /**
   * Updates js gantt and saves settings to backend.
   */
  private $updateGantt(rerender: boolean): void {
    this.$iterateOverAllBlocks(this.$ganttEntries, (block: GanttDataShift) => {
      this.$applyName(this.lastVisibleIds, block);
    });
    const textStrategy = this.scope.ganttLibService.bestGantt.getTextOverlay().getActiveStrategyType();

    // update width map of text split strategy
    if (textStrategy === EGanttTextStrategy.SHOW_ALWAYS_LABELS_SPLIT) {
      const overlappingShiftUpdater: GanttOverlappingShiftsPlugIn =
        this.scope.ganttPluginHandlerService.getEssentialPlugIn(GanttEssentialPlugIns.OverlappingShiftsPlugIn);
      (this.scope.ganttLibService.bestGantt.getTextOverlay().getTextOverlayStrategy() as any).createTextBoxWidthMap();
      if (rerender) {
        overlappingShiftUpdater.resetSplitOverlappingShifts(false);
        overlappingShiftUpdater.splitOverlappingShifts(false);
      }
    }

    if (rerender) {
      this.scope.ganttLibService.bestGantt.update();
    }
  }

  private saveSettings() {
    this.scope.ganttSettingsService.changeSettings({
      visibleAttributeId: this.$convertListToString(this.lastVisibleIds),
    });
    this.scope.ganttSettingsService.saveSettings().subscribe();
  }

  /**
   * getter for gantt entries
   * @returns IGanttEntry[]
   */
  private get $ganttEntries(): GanttDataRow[] {
    return this.scope.ganttLibService.bestGantt.getDataHandler().getOriginDataset().ganttEntries;
  }

  private $iterateOverAllBlocks(entries: GanttDataRow[], cb: (block: GanttDataShift) => void) {
    if (!entries) {
      return;
    }
    entries.forEach((e: GanttDataRow) => {
      e.shifts.forEach((s: GanttDataShift) => {
        cb(s);
      });
      this.$iterateOverAllBlocks(e.child, cb);
    });
  }

  /**
   * checks if list a is different from list b
   * @param {number[]} a list of ids
   * @param {number[]} b list of ids
   * @returns boolean
   */
  private $isDifferent(a: number[], b: number[]): boolean {
    if (a?.length === 0 && b?.length === 0) {
      return false;
    }
    if (a?.length !== b?.length) {
      return true;
    }
    return (a || []).find((_a: number, idx: number) => {
      if (!b[idx] || b[idx] !== _a) {
        return true;
      }
    })
      ? true
      : false;
  }

  /**
   * converts list of numeric ids to comma seperated string
   * @param list list of numeric ids
   * @returns string
   */
  private $convertListToString(list: number[]): string {
    if (!list) {
      return null;
    }
    const mappedList: string[] = list.map((item: number) => item.toString());
    if (mappedList.length === 0) {
      return null;
    }

    return mappedList.reduce((prev: string, cur: string) => `${prev}, ${cur}`);
  }

  /**
   * converts string to list of numeric ids
   * @param {string} s string of ids seperated by comma
   * @returns number[]
   */
  private $convertStringToList(s: string): number[] {
    if (!s || typeof s === 'number') {
      return [];
    }
    return s.split(',').map((item: string) => parseInt(item));
  }

  /**
   * apply name to block
   * @param {number[]} ids list of ids
   * @param {IGanttEntry} block block of gantt
   * @returns void
   */
  private $applyName(ids: number[], block: GanttDataShift): void {
    let displayName = '';
    if (!ids || ids.length === 0) {
      displayName = block?.originName || ' ';
    }

    ids?.forEach((id: number) => {
      const attribute: IBlockAttribute = block.additionalData.additionalData.additionalDetails[id];
      if (attribute) {
        const datatype = this.scope.ganttTemplateDataService.getTemplateData().getAttributeMapping()[id].dataType;
        switch (datatype) {
          case EGanttBlockAttributeDataType.DATE:
            displayName += `${displayName ? ', ' : ''}${this.scope.datePipe.transform(
              parseInt(attribute.t2),
              this.scope.translate.instant('DATE.FORMATS.DATE')
            )}`;
            break;
          case EGanttBlockAttributeDataType.TIME:
            displayName += `${displayName ? ', ' : ''}${this.scope.datePipe.transform(
              parseInt(attribute.t2),
              this.scope.translate.instant('DATE.FORMATS.TIME')
            )}`;
            break;
          case EGanttBlockAttributeDataType.DATE_TIME:
            displayName += `${displayName ? ', ' : ''}${this.scope.datePipe.transform(
              parseInt(attribute.t2),
              this.scope.translate.instant('DATE.FORMATS.DATETIME')
            )}`;
            break;
          case EGanttBlockAttributeDataType.BOOLEAN:
            displayName += `${displayName ? ', ' : ''}${attribute.t2 ? 'ja' : 'nein'}`;
            break;
          default:
            displayName += `${displayName ? ', ' : ''}${attribute.t2 || ''}`;
            break;
        }
      }
    });

    block.name = displayName || '';
  }
}
