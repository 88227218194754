import { Injectable, Type } from '@angular/core';
import { Message } from './message';

@Injectable({
  providedIn: 'root',
})
export class MessageFactoryService {
  parseMessage<T extends Message>(type: Type<T>, data: any): T {
    return new type()
      .setId(data.id)
      .setDuration(data.duration)
      .setTitle(data.title)
      .setType(data.type ? data.type.toLowerCase() : null)
      .setText(data.text);
  }
}
