import { GanttDataContainer } from '../data-structure/data-structure';

export class DataRemover {
  originDataSet: GanttDataContainer;

  constructor(dataSet) {
    this.originDataSet = dataSet;
  }

  /**
   * Removes row from dataset by id.
   * @param {string} rowId
   */
  removeRowById(rowId) {
    const s = this;
    const removeRow = function (rows) {
      for (let i = 0; i < rows.length; i++) {
        const row = rows[i];
        if (row.id == rowId) {
          rows.splice(i, 1);
          return;
        }
        if (row.child && row.child.length) {
          removeRow(row.child);
        }
      }
    };

    removeRow(s.originDataSet.ganttEntries);
  }

  /**
   * Removes rows from dataset by ids on one iteration.
   * @param {string[]} rowIds
   */
  removeRowsByIds(rowIds) {
    const s = this;
    const idList = rowIds.slice();
    const removeRow = function (rows) {
      for (let i = 0; i < rows.length; i++) {
        const row = rows[i];
        if (row.child && row.child.length) {
          removeRow(row.child);
        }
        if (idList.includes(row.id)) {
          idList.splice(idList.indexOf(row.id), 1); // remove from row id list
          rows.splice(i, 1);
          i--; // go back because position of next is shifted by 1
          if (!idList.length) {
            // all rows removed -> now return
            return;
          }
        }
      }
    };

    removeRow(s.originDataSet.ganttEntries);
  }
}
