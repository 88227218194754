import { CdkDrag, CdkDragEnd, CdkDragMove, CdkDragStart } from '@angular/cdk/drag-drop';
import {
  Component,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { LightboxService } from '@app-modeleditor/components/lightbox/lightbox.service';
import { ConfirmLightbox } from '@app-modeleditor/components/lightbox/predefined/confirm-lightbox';
import { GlobalUtils } from 'frontend/src/dashboard/global-utils';
import { ResizeEvent } from 'frontend/src/dashboard/view/resize/resize-event';
import { ResizeService } from 'frontend/src/dashboard/view/resize/resize.service';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { AttributeCategory, Hotspot, Region } from './hotspot';
import { HotspotUtils } from './hotspot-utils';
import { EPolygonModes, EPolygonType, Polygon } from './polygon';
import { EVertexType, InterpolationVertex, Vertex } from './vertex';

@Component({
  selector: 'template-hotspot',
  templateUrl: './hotspot.component.html',
  styleUrls: ['./hotspot.component.scss'],
  providers: [],
})
export class HotspotComponent implements OnInit, OnDestroy, OnChanges {
  hotspotWidget: ElementRef;
  private resizeId: string;
  @ViewChild('hotspotWidget') set c1(hotspotWidget: ElementRef) {
    this.hotspotWidget = hotspotWidget;
    this._resizeApi.complete(this.resizeId);
    if (!hotspotWidget) {
      return;
    }
    this.resizeId = this._resizeApi.create(hotspotWidget.nativeElement, (event: ResizeEvent) => {
      this._checkDimensions(event.getNewWidth(), event.getNewHeight());
    });
  }
  @ViewChild('imageContainer') imageContainer: ElementRef;
  @ViewChild('svgContainer') svgContainer: ElementRef;
  @ViewChildren(CdkDrag) cdkDrags: QueryList<CdkDrag>;
  @Input() template: Hotspot;
  @Input() currentAttribute: AttributeCategory;
  @Output() polygonModeChange: EventEmitter<EPolygonModes> = new EventEmitter();
  @Output() onSelectPolygon: EventEmitter<Polygon> = new EventEmitter();
  @Output() onSelectNode: EventEmitter<Vertex> = new EventEmitter();
  @Output() onChanges: EventEmitter<Hotspot> = new EventEmitter();
  public title = '';
  private hotspotUtil: HotspotUtils;
  private previousPosition: Vertex[];
  public currentPolygon: Polygon;
  public currentNode: Vertex;
  public backgroundImgString = '';
  public editMode = true;
  constructor(private _resizeApi: ResizeService, injector: Injector, private lightboxApi: LightboxService) {}

  ngOnDestroy(): void {
    this._resizeApi.complete(this.resizeId);
  }

  ngOnInit(): void {
    this.hotspotUtil = new HotspotUtils();
  }

  private _checkDimensions(width: number, height: number): void {
    of(null)
      .pipe(delay(0))
      .subscribe(() => {
        this.template.setWidth(width);
        this.template.setHeight(height);
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.currentAttribute) {
      this.getColorOfPolygons();
    }
  }

  public onSelectVertex(event: Event, vertex: Vertex | InterpolationVertex): void {
    this.currentNode = vertex;
    this.onSelectNode.emit(this.currentNode);
  }

  reset() {
    this.cdkDrags.forEach((cdkDrag) => {
      cdkDrag._dragRef['_previewRect'] = undefined;
      cdkDrag._dragRef['_boundaryRect'] = undefined;
    });
  }

  /**
   * handle double click on polygon
   * @param event MouseEvent
   * @param poly Polygon
   * @returns void
   */
  onEditPolygon(event: MouseEvent, poly: Polygon): void {
    if (this.editMode) {
      this.resetEditMode();
      //  this.submenuObject.set(this.interpolationSubmenu.id, this.interpolationSubmenu);
      poly.toggleEdit();
      this.currentNode = null;
      this.currentPolygon = this.template.getPolygons().find((polygon) => polygon.getEditMode() === true);
      this.onSelectPolygon.emit(this.currentPolygon);
    }
  }

  setEditMode(editMode) {
    this.resetEditMode();
    this.editMode = editMode;
  }

  public stopEvents(event: Event): void {
    event.stopPropagation();
    event.stopImmediatePropagation();
  }

  public onAddNewVertex(mouseEvent: MouseEvent): void {
    const newVertex = this.hotspotUtil.convertMouseEventtoVertex(mouseEvent, this.hotspotWidget);
    let minDistance = null;
    const minVertex = null;
    const minVertexNeighbor = null;
    let firstClosestVertex: Vertex;
    let secondClosestVertex: Vertex;
    this.currentPolygon.getVertices().forEach((vertex) => {
      // const distance = this.hotspotUtil.getDistanceToOtherVertex(vertex, newVertex, this.hotspotWidgetElement);
      // if (!minDistance || distance < minDistance) {
      //   minDistance = distance;
      //   firstClosestVertex = vertex;
      // }
      const neighborVertexs = this.currentPolygon.getNeighborVertex(vertex);
      const prevDist = this.hotspotUtil.distToSegment(newVertex, neighborVertexs.prevVertex, vertex);
      const nextDist = this.hotspotUtil.distToSegment(newVertex, vertex, neighborVertexs.nextVertex);
      if (!minDistance || prevDist < minDistance) {
        minDistance = prevDist;
        firstClosestVertex = vertex;
        secondClosestVertex = neighborVertexs.prevVertex;
      }
      if (!minDistance || nextDist < minDistance) {
        minDistance = nextDist;
        firstClosestVertex = vertex;
        secondClosestVertex = neighborVertexs.nextVertex;
      }
    });

    // const neighborVertexs = this.currentPolygon.getNeighborVertex(firstClosestVertex);

    // const prevDist = this.hotspotUtil.distToSegment(newVertex, firstClosestVertex, neighborVertexs.prevVertex);
    // const nextDist = this.hotspotUtil.distToSegment(newVertex, firstClosestVertex, neighborVertexs.nextVertex);

    // secondClosestVertex = (nextDist >= prevDist) ? neighborVertexs.prevVertex : neighborVertexs.nextVertex;

    // if (this.hotspotUtil.getDistanceToOtherVertex(neighborVertexs.nextVertex, newVertex, this.hotspotWidgetElement) >= this.hotspotUtil.getDistanceToOtherVertex(neighborVertexs.prevVertex, newVertex, this.hotspotWidgetElement)) {
    //   secondClosestVertex = neighborVertexs.prevVertex;
    // } else {
    //   secondClosestVertex = neighborVertexs.nextVertex;
    // }

    let newIndex = 0;
    if (Math.abs(firstClosestVertex.getIndex() - secondClosestVertex.getIndex()) > 1) {
      newIndex = this.currentPolygon.getVertices().length;
    } else if (firstClosestVertex.getIndex() > secondClosestVertex.getIndex()) {
      newIndex = firstClosestVertex.getIndex();
    } else {
      newIndex = firstClosestVertex.getIndex() + 1;
    }

    newVertex.setIndex(newIndex);

    this.currentPolygon.getVertices().forEach((vertex) => {
      if (vertex.getIndex() >= newIndex) {
        const newVertexIndex = vertex.getIndex() + 1;
        /* const helperpoint =
          this.currentPolygon.getInterpolationPoints().find(interpolation => interpolation.getIndex() === vertex.getIndex());

        if (helperpoint) {
          helperpoint.setIndex(newVertexIndex);
        }*/
        vertex.setIndex(newVertexIndex);
      }
    });

    this.currentPolygon.getInterpolationPoints().forEach((vertex) => {
      vertex.setIndex(vertex.getOriginVertex().getIndex());
    });

    this.currentPolygon.addVertices(newVertex);
    this.addInterpolationNode(newVertex);

    this.onChanges.emit(this.template);
  }

  public getColorOfPolygons(): void {
    if (this.currentAttribute) {
      this.template.getPolygons().forEach((polygon) => {
        const region = this.template.getRegions().find((regionData) => regionData.getId() === polygon.getRegionKey());
        if (region) {
          const regionValue = region
            .getRegionAttributes()
            .find((value) => value.getAttributeKey() === this.currentAttribute.getId())
            .getValue();
          if (regionValue) {
            polygon.setColor(this.currentAttribute.getColorScheme().getColorByValue(regionValue));
          }
        }
      });
    }
  }
  public getTEstValue(polygon) {
    if (this.currentAttribute) {
      const region = this.template.getRegions().find((regionData) => regionData.getId() === polygon.getRegionKey());
      if (region) {
        const regionValue = region
          .getRegionAttributes()
          .find((value) => value.getAttributeKey() === this.currentAttribute.getId())
          .getValue();
        return regionValue;
      }
    }
  }

  /**
   * reset edit mode for each polygon
   */
  private resetEditMode() {
    (this.template.getPolygons() || []).forEach((innerPoly: Polygon) => innerPoly.setEditMode(false));
  }

  /**
   * transform vertex into absolute coordinates
   * @param vert Vertex
   * @returns {x: number, y: number}
   */
  transformVertex(vert: Vertex | InterpolationVertex): { x: number; y: number } {
    return {
      x: (this.template.getWidth() / 100) * vert.getX(),
      y: (this.template.getHeight() / 100) * vert.getY(),
    };
  }

  /**
   * ToDo: write a pipe for this
   * get vertices from polygon
   * @param poly Polygon
   */
  getVertices(poly: Polygon): string {
    if (poly.getVertices().length === 0) {
      return '';
    }
    let vertices = '';
    poly.getVertices().forEach((vert: Vertex, index) => {
      vertices += this.getVertexString(vert, poly);
    });

    vertices += this.getVertexString(poly.getVertices()[0], poly, true);

    if (poly.getPolygonType() === EPolygonModes.QUADRATIC) {
      poly.getInterpolationPoints().forEach((vert: InterpolationVertex, index) => {
        vertices = vertices.replace(
          this.getVertexString(vert.getOriginVertex(), poly),
          this.getVertexString(vert, poly)
        );
      });
    }

    return vertices;
  }

  getVertexString(vert: Vertex | InterpolationVertex, poly: Polygon, endPoint?: boolean): string {
    const x = this.transformVertex(vert).x;
    const y = this.transformVertex(vert).y;
    switch (vert.getType()) {
      case EVertexType.HELPERVERTEX:
        const helperNode = vert as InterpolationVertex;
        const currentNode = helperNode.getOriginVertex();

        const currentNodeX = this.transformVertex(currentNode).x;
        const currentNodeY = this.transformVertex(currentNode).y;

        let nextIndex = 0;
        if (currentNode.getIndex() + 1 !== poly.getVertices().length) {
          nextIndex = currentNode.getIndex() + 1;
        } else {
          nextIndex = 0;
        }
        const nextNode = poly.getVertices().find((vertex) => vertex.getIndex() === nextIndex);
        const nextNodeX = this.transformVertex(nextNode).x;
        const nextNodeY = this.transformVertex(nextNode).y;
        if (currentNode.getIndex() === 0) {
          return `M ${currentNodeX}, ${currentNodeY} Q${x},${y}  ${nextNodeX}, ${nextNodeY}\n`;
        } else {
          return `L ${currentNodeX}, ${currentNodeY} Q${x},${y}  ${nextNodeX}, ${nextNodeY}\n`;
        }
      default:
        if (vert.getIndex() === 0 && !endPoint) {
          return `M ${x},${y}\n`;
        } else {
          return `L ${x},${y}\n`;
        }
    }
  }
  /**
   * set x and y coordinate of a single vertex
   * @param p Vertex
   * @param distance { x: number, y: number }
   * @returns Vertex
   */
  setVertex(p: Vertex | InterpolationVertex, distance: { x: number; y: number }): Vertex | InterpolationVertex {
    const pixelX: number = (100 / this.hotspotWidget.nativeElement.clientWidth) * distance.x;
    const pixelY: number = (100 / this.hotspotWidget.nativeElement.clientHeight) * distance.y;
    p.setX(p.getX() + pixelX);
    p.setY(p.getY() + pixelY);
    return p;
  }

  /**
   * gets called on each drag move
   * @param event CdkDragMove
   * @param poly Polygon
   * @param el any
   * @param vert Vertex
   * @returns void
   */
  onDragMoved(event: CdkDragMove, poly: Polygon, el: HTMLElement, vert: Vertex): void {
    const distance = (event.source._dragRef as any)._passiveTransform;

    if (!vert) {
      poly.setVertices(
        poly.getVertices().map((p: Vertex) => {
          return this.setVertex(p, distance);
        })
      );

      poly.setInterpolationPoints(
        poly.getInterpolationPoints().map((p: InterpolationVertex) => {
          return this.setVertex(p, distance) as InterpolationVertex;
        })
      );
    } else {
      this.setVertex(vert, distance);
    }

    (this.template.getPolygons() || []).forEach((innerPoly: Polygon) => innerPoly.setMoved(false));
    if (!vert) {
      poly.setMoved(true);
    }
  }

  /**
   * gets called when drag ended
   * @param event CdkDragEnd
   * @param poly Polygon
   * @param el any
   * @param vert Vertex
   * @returns void
   */
  onDragEnd(event: CdkDragEnd, poly: Polygon, el: HTMLElement, vert: Vertex): void {
    const distance = (event.source._dragRef as any)._passiveTransform;

    if (!vert) {
      poly.setVertices(
        poly.getVertices().map((p: Vertex) => {
          poly.changeOriginVertexOfInterpolationPoitns(p);
          return this.setVertex(p, distance);
        })
      );

      poly.setInterpolationPoints(
        poly.getInterpolationPoints().map((p: InterpolationVertex) => {
          return this.setVertex(p, distance) as InterpolationVertex;
        })
      );
    } else {
      this.setVertex(vert, distance);
      poly.changeOriginVertexOfInterpolationPoitns(vert);
    }

    poly.setMoved(false);
    event.source.reset();
    el.style.transform = 'translate(0,0)';
    (event.source._dragRef as any)._previewRect = null;
    if (
      poly
        .getVertices()
        .filter((vertex) => vertex.getX() >= 0 && vertex.getX() <= 100 && vertex.getY() >= 0 && vertex.getY() <= 100)
        .length === 0
    ) {
      this.lightboxApi.open(
        new ConfirmLightbox('HOTSPOT.DIALOG.delete_polygon')
          .setCustomConfirmAction(() => {
            return of(this.handleDeleteAfterDragend(poly));
          })
          .setCustomCancelAction(() => {
            return of(this.resetPolygon(poly));
          })
      );
    }
  }

  private handleDeleteAfterDragend(poly: Polygon) {
    this.template.setPolygons(this.template.getPolygons().filter((p) => p.getId() !== poly.getId()));
    this.onChanges.emit(this.template);
  }
  private resetPolygon(poly: Polygon) {
    this.changeVertex(poly.getVertices().concat(poly.getInterpolationPoints()));
    this.onChanges.emit(this.template);
  }

  private changeVertex(vertices: Vertex[]) {
    this.previousPosition.forEach((oldVertex) => {
      const foundVertex = vertices.find((v) => v.getId() === (oldVertex as any).id);
      if (foundVertex) {
        foundVertex.setX((oldVertex as any).xCoordinate);
        foundVertex.setY((oldVertex as any).yCoordinate);
      }
    });
  }

  public onDragStart(event: CdkDragStart, poly: Polygon) {
    this.previousPosition = JSON.parse(JSON.stringify(poly.getVertices().concat(poly.getInterpolationPoints())));
  }

  public handleLinearMode() {
    if (!this.currentPolygon) return;
    this.currentPolygon.setPolygonType(EPolygonModes.LINE);
    this.polygonModeChange.emit(EPolygonModes.LINE);
  }

  public handleInterpolation(): void {
    if (!this.currentPolygon) return;
    this.currentPolygon.setPolygonType(EPolygonModes.QUADRATIC);
    this.currentPolygon.getVertices().forEach((selectedNode) => {
      this.addInterpolationNode(selectedNode);
    });
    this.polygonModeChange.emit(EPolygonModes.QUADRATIC);
  }

  private addInterpolationNode(selectedNode: Vertex) {
    if (
      this.currentPolygon
        .getInterpolationPoints()
        .find((interpolation) => interpolation.getIndex() === selectedNode.getIndex())
    ) {
      return;
    }
    let nextIndex = 0;
    if (selectedNode.getIndex() + 1 !== this.currentPolygon.getVertices().length) {
      nextIndex = selectedNode.getIndex() + 1;
    } else {
      nextIndex = 0;
    }
    const nextNode = this.currentPolygon.getVertices().find((vertex) => vertex.getIndex() === nextIndex);
    const helperXCoord = (selectedNode.getX() + nextNode.getX()) / 2;
    const helperYCoord = (selectedNode.getY() + nextNode.getY()) / 2;
    const helperNode = new InterpolationVertex(selectedNode, helperXCoord, helperYCoord);
    helperNode.setIndex(selectedNode.getIndex());
    this.currentPolygon.addInterpolationPoints(helperNode);
  }

  public handleDeleteNode(): void {
    if (!this.currentPolygon || !this.currentNode) {
      return;
    }
    this.currentPolygon.setVertices(
      this.currentPolygon.getVertices().filter((node) => node.getIndex() !== this.currentNode.getIndex())
    );
    this.currentPolygon.setInterpolationPoints(
      this.currentPolygon.getInterpolationPoints().filter((node) => node.getIndex() !== this.currentNode.getIndex())
    );

    this.currentPolygon.getVertices().forEach((vertex, index) => {
      const helperVertex = this.currentPolygon
        .getInterpolationPoints()
        .find((interpolationVertex) => interpolationVertex.getIndex() === vertex.getIndex());
      if (helperVertex) {
        helperVertex.setIndex(index);
      }
      vertex.setIndex(index);
    });
    this.onChanges.emit(this.template);
  }

  public handleDeletePolygon(): void {
    if (!this.currentPolygon) {
      return;
    }
    this.template.setPolygons(
      this.template.getPolygons().filter((poly) => poly.getId() !== this.currentPolygon.getId())
    );
    this.currentPolygon = null;
    this.onSelectPolygon.emit(this.currentPolygon);
    this.onChanges.emit(this.template);
  }

  public createNewPolygon(name: string, region: Region, type: EPolygonType): void {
    const polygon = new Polygon()
      .setId(GlobalUtils.generateUUID())
      .setName(name)
      .setVertices(this.getVerticeOfNewPoylgon(type));

    if (region) {
      polygon.setRegionKey(region.getId());
    }

    polygon.getVertices().map((vertex, index) => vertex.setIndex(index));
    this.template.addPolygons(polygon);
    this.onChanges.emit(this.template);
  }

  private getVerticeOfNewPoylgon(type: EPolygonType): Vertex[] {
    const vertices: Vertex[] = [];
    switch (type) {
      case EPolygonType.RECTANGLE:
        vertices.push(this.hotspotUtil.createVertexFromPosition(10, 10, this.hotspotWidget));
        vertices.push(this.hotspotUtil.createVertexFromPosition(10, 60, this.hotspotWidget));
        vertices.push(this.hotspotUtil.createVertexFromPosition(60, 60, this.hotspotWidget));
        vertices.push(this.hotspotUtil.createVertexFromPosition(60, 10, this.hotspotWidget));
        break;
      case EPolygonType.TRIANGLE:
        vertices.push(this.hotspotUtil.createVertexFromPosition(40, 10, this.hotspotWidget));
        vertices.push(this.hotspotUtil.createVertexFromPosition(10, 60, this.hotspotWidget));
        vertices.push(this.hotspotUtil.createVertexFromPosition(70, 60, this.hotspotWidget));
        break;
    }
    return vertices;
  }

  private arrayBufferToBase64(buffer) {
    // var blob = new Blob([buffer], { type: 'application/octet-binary' });
    const reader = new FileReader();
    const self = this;
    reader.onloadend = function (evt: any) {
      const dataurl = evt.target.result;
      self.backgroundImgString = dataurl.substr(dataurl.indexOf(',') + 1);
    };
    reader.readAsDataURL(buffer);
  }

  handleFileInput(files: FileList): void {
    const buf = new Uint8Array([11, 22, 33]);
    this.arrayBufferToBase64(files[0]);
  }
}
