<form
  id="dndForm"
  (drag)="onDrag($event)"
  (dragend)="onDragEnd($event)"
  (dragstart)="onDragStart($event)"
  (dragover)="onDragOver($event)"
  (dragenter)="onDragEnter($event)"
  (dragleave)="onDragLeave($event)"
  (drop)="onDrop($event)"
  class="box has-advanced-upload"
  method="post"
  action=""
  enctype="multipart/form-data"
  (click)="isClicked()"
  #formRef
>
  <input
    class="box__file"
    [disabled]="disabled"
    (change)="handleFileInput($event.target.files, formRef)"
    type="file"
    accept="{{ data?.getAccept() }}"
    name="files[]"
    id="file"
    data-multiple-caption="{count} files selected"
    multiple
  />
  <label for="file" class="label">
    <mat-icon style="float: left">file_upload</mat-icon>
    <ng-container *ngIf="data">
      <strong *ngIf="data?.value?.value === 'noFile' || data?.value?.value == null">{{
        'FILE.UPLOAD.CHOOSE' | translate
      }}</strong>
      <span
        class="inner-label"
        *ngIf="data?.value?.value == ('noFile' || null) || data == null || fileError"
        class="box__dragndrop"
      >
        {{ 'FILE.UPLOAD.DRAG' | translate }}</span
      >
      <span class="inner-label" *ngIf="data?.value?.value !== 'noFile' && data != null && !fileError"
        >{{ data.value.value }}
        <span *ngIf="sizeFormatted">- {{ sizeFormatted }}</span>
      </span>
    </ng-container>
  </label>
</form>
<div id="fileError" *ngIf="fileError">{{ 'FILE.UPLOAD.REJECTED' | translate }}</div>
