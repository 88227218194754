import { NgModule } from '@angular/core';
import { MySharedModule } from '../shared/shared.module';
import { NodeNetworkComponent } from '@app-nodenetwork/node-network.component';
import { NodeNetworkLegendContainer } from '@app-nodenetwork/legend/nn-legend.component';
import { NodeNetworkIconEditDialog } from '@app-nodenetwork/icon-handling/icon-edit.dialog/icon-edit.dialog.component';
import { NodeNetworkEditEdges } from '@app-nodenetwork/edit/node-network-edit-edge/node-network-edit-edge';
import { NodeNetworkEditDialog } from '@app-nodenetwork/edit/node-network-dialog/select-dialog/node-network-edit-dialog';
import { NodeNetworkCreateDialogComponent } from '../model-editor/ui/dialogs/node-network-create-dialog/node-network-create-dialog.component';
import { NodeNetworkDeleteElement } from '@app-nodenetwork/edit/node-network-delete-element/node-network-delete-element';
import { NodeNetworkAddElement } from '@app-nodenetwork/edit/node-network-add-element/node-network-add-element';
import { NodeNetworkEdgeFilter } from '@app-nodenetwork/edge-filter/edge-filter.component';
import { NodeNetworkAttributeLegend } from '@app-nodenetwork/legend/attribute-legend/attribute-legend.component';
import { NodeNetworkIconLegend } from '@app-nodenetwork/legend/icon-legend/icon-legend.component';
import { CinemaDiscreteAxis } from '@app-nodenetwork/micecinema/discrete-axis/discrete-axis.component';
import { NodeNetworkDiagramService } from '@app-nodenetwork/service/node-network.diagram.service';
import { NodeNetworkCinemaService } from '@app-nodenetwork/service/node-network-cinema.service';
import { NodeNetworkEditService } from '@app-nodenetwork/service/node-network.edit.service';
import { NodeNetworkFilterService } from '@app-nodenetwork/service/node-network.filter.service';
import { NodeNetworkImageService } from '@app-nodenetwork/service/node-network.image.service';
import { NodeNetworkService } from '@app-nodenetwork/service/node-network.service';
import { NodeNetworkSaveToServer } from '@app-nodenetwork/edit/node-network-server-save/node-network-server-save';
import { ModelEditorModule } from '../model-editor/model-editor.module';
import { GanttGeneralModule } from 'frontend/src/dashboard/gantt/general/gantt.general.module';

@NgModule({
  imports: [MySharedModule, GanttGeneralModule, ModelEditorModule],
  declarations: [
    NodeNetworkComponent,
    NodeNetworkLegendContainer,
    NodeNetworkIconEditDialog,
    NodeNetworkEditEdges,
    NodeNetworkEditDialog,
    NodeNetworkCreateDialogComponent,
    NodeNetworkDeleteElement,
    NodeNetworkAddElement,
    NodeNetworkEdgeFilter,
    NodeNetworkAttributeLegend,
    NodeNetworkIconLegend,
    CinemaDiscreteAxis,
  ],
  exports: [
    NodeNetworkIconLegend,
    NodeNetworkLegendContainer,
    NodeNetworkAttributeLegend,
    NodeNetworkComponent,
    CinemaDiscreteAxis,
    NodeNetworkEditEdges,
    NodeNetworkEditDialog,
    NodeNetworkCreateDialogComponent,
    NodeNetworkDeleteElement,
    NodeNetworkAddElement,
    NodeNetworkEdgeFilter,
  ],
  providers: [
    NodeNetworkDiagramService,
    NodeNetworkCinemaService,
    NodeNetworkEditService,
    NodeNetworkFilterService,
    NodeNetworkImageService,
    NodeNetworkService,
    NodeNetworkSaveToServer,
  ],
})
export class NodeNetworkModule {}
