<div class="attribute-wrapper" [ngStyle]="{ 'max-height': templateNode.getHeight() + 'px' }">
  <div #activeAttributes class="attributes-area">
    <div class="attribute-header-label">{{ 'CHART.LEGEND.ATTRIBUTE.limited' | translate }}</div>
    <button class="attribute-legend-btn" mat-icon-button (click)="removeAllAttributes()">
      <mat-icon class="attribute-legend-btn-icon" [matTooltip]="'CHART.LEGEND.ATTRIBUTE.removeAll' | translate"
        >remove
      </mat-icon>
    </button>
    <div class="attribute-list" [ngStyle]="{ 'max-height': templateNode.getHeight() / 2 - 20 + 'px' }">
      <ng-container *ngIf="!allAttributeActive()">
        <div
          class="active-attribue-item-label"
          *ngFor="let item of getActiveItems()"
          (click)="handleAttributeClick(item)"
          [matTooltip]="item.getLabel()"
          [matTooltipShowDelay]="2000"
          [style.padding-left]="
            item.getParentItem() ? (allChildDeactive(item.getParentItem()) ? '0px' : '10px') : '0px'
          "
        >
          {{ (item.getParentItem() ? '' : allChildDeactive(item) ? 'CHART.LEGEND.ATTRIBUTE.nothing' : '') | translate }}
          {{ item.getLabel() }}
        </div>
      </ng-container>
      <ng-container *ngIf="allAttributeActive()">
        <div class="active-attribue-item-label">{{ 'CHART.LEGEND.ATTRIBUTE.allActive' | translate }}</div>
      </ng-container>
    </div>
  </div>

  <mat-divider></mat-divider>

  <div class="attributes-area">
    <div class="attribute-header">
      <div class="attribute-header-label">{{ 'CHART.LEGEND.ATTRIBUTE.all_attributes' | translate }}</div>
      <button class="attribute-legend-btn" mat-icon-button>
        <mat-icon
          class="attribute-legend-btn-icon"
          (click)="addAllAttributes()"
          [matTooltip]="'CHART.LEGEND.ATTRIBUTE.addAll' | translate"
          >add</mat-icon
        >
      </button>
    </div>
    <div
      class="attribute-list"
      [ngStyle]="{
        'min-height': templateNode.getHeight() / 2 - 20 + 'px',
        height: templateNode.getHeight() - activeAttributes.offsetHeight - 20 + 'px'
      }"
    >
      <app-chart-attribute-legend-item
        *ngFor="let item of templateNode.getLegendValues()"
        [attributeItem]="item"
        (onAttributeClick)="handleAttributeClick($event)"
        (onToogleAttributeGroup)="handleToogleAttributeGroup($event)"
        (onActiveAttributeGroup)="handleActiveAttributeGroup($event)"
      >
      </app-chart-attribute-legend-item>
    </div>
  </div>
</div>
