import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { SlotModule } from '../template/slot/slot.module';
import { IndexRegisterComponent } from './index-register.component';

@NgModule({
  imports: [CommonModule, MatTabsModule, MatTooltipModule, SlotModule, TranslateModule],
  exports: [IndexRegisterComponent],
  declarations: [IndexRegisterComponent],
})
export class IndexRegisterModule {}
