import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TemplateEntryElementModule } from '@app-modeleditor/components/entry-collection/entry-element/entry-element.module';
import { SlotModule } from '@app-modeleditor/components/template/slot/slot.module';
import { VirtualLayoutComponent } from './virtual-layout.component';

@NgModule({
  imports: [CommonModule, ScrollingModule, SlotModule, TemplateEntryElementModule],
  declarations: [VirtualLayoutComponent],
  exports: [VirtualLayoutComponent],
})
export class VirtualLayoutModule {}
