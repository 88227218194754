import { EdgeFilterOutputItem } from '@app-nodenetwork/edge-filter/edge-filter.component';
import { NodeNetworkMapper } from '@app-nodenetwork/mapper/node-network.mapper';

/**
 * Translates backend data to frontend data for connections.
 */
export class NodeNetworkConnectionMapper {
  public mapper: NodeNetworkMapper;

  constructor() {
    this.mapper = new NodeNetworkMapper();
  }

  /**
   * Adds connection filter to given dataset.
   * @param mappedData Origin dataset of node network.
   * @param originData Backend dataset of node network.
   * @param filterItem Filter item with data which connections to filter.
   * @param currentNodeNetworkLineScale Scale in horizontal and vertical direction.
   * @param scaleWidth Width of scaled nodenetwork.
   * @param scaleHeight Height of scaled nodenetwork.
   */
  public mapConnectionsByEdgeFilter(
    mappedData: any,
    originData: any,
    filterItem: EdgeFilterOutputItem,
    currentNodeNetworkLineScale: number[],
    scaleWidth: number,
    scaleHeight: number
  ): any {
    const connectionsDataset = mappedData.connections;

    // store how often edges will be shown and in which colors
    const edgeUsingCount = {};

    // count, how often each conneciton will be used
    for (const connection of connectionsDataset) {
      if (!edgeUsingCount[connection.id]) {
        edgeUsingCount[connection.id] = { count: 0, products: [], color: [] };
      }

      for (const key in filterItem.edges) {
        if (filterItem.edges[key].indexOf(connection.id) !== -1) {
          edgeUsingCount[connection.id].count += 1;
          edgeUsingCount[connection.id].products.push(key);
          edgeUsingCount[connection.id].color.push(
            filterItem.products.find((element) => {
              return element.id == key;
            }).color
          );
        }
      }
    }

    // add attributes to each connection based on how often it is used
    for (const connection of connectionsDataset) {
      const count = edgeUsingCount[connection.id].count;

      if (count > 0) {
        connection.screen.show = true;
        connection.screen.color = edgeUsingCount[connection.id].color[0];

        if (count > 1) {
          let label = count;
          if (count < 3) {
            label = filterItem.products
              .filter((item) => {
                return edgeUsingCount[connection.id].products.indexOf(item.id) > -1;
              })
              .map((item) => item.name)
              .join('+');
          }

          connection.label = this.addConnectionLabel(
            connection.id,
            JSON.parse(JSON.stringify(originData)),
            label,
            currentNodeNetworkLineScale[0] * scaleWidth,
            currentNodeNetworkLineScale[1] * scaleHeight
          );
        } else connection.label = null;

        if (edgeUsingCount[connection.id].color.length > 1) {
          connection.screen.color = '#000000';
        }
      } else {
        connection.screen.color = null;
        connection.screen.show = false;
        connection.label = null;
      }
    }
    return mappedData;
  }

  /**
   * Adds/refresh label of connection.
   * @param connectionId Id of affected connection.
   * @param originData Backend dataset of node network.
   * @param labelText New label text.
   * @param scaleX Horizontal scale.
   * @param scaleY Vertical scale.
   */
  public addConnectionLabel(connectionId: string, originData, labelText: string, scaleX: number, scaleY: number): any {
    return this.mapper.getEndLabelByConnectionId(connectionId, originData, labelText, scaleX, scaleY);
  }
}
