import { EGanttInstance } from '@gantt/public-api';
import { SaxMsBestGanttActiveSubmenuEntryElementSetting } from 'frontend/src/dashboard/gantt/gantt/saxms-best-gantt.settings';
import { Observable, of } from 'rxjs';
import { GanttChildren, IGanttTemplateValue } from '../../../generator/gantt-input.data';
import { GanttEssentialPlugIns } from '../../e-gantt-essential-plugins';
import { ExternalGanttPlugin } from '../../external-plugin';
import { GanttRestrictBlockDragPlugIn } from '../edit-block/restrictions/restrict-block-drag';
import { GanttTemplateData } from 'frontend/src/dashboard/gantt/helper/gantt';

/**
 * This PlugIn is NOT based on any JS-PlugIn.
 * This plugins is a facade to handle all row restricitons inside the gantt.
 */
export class GanttRowRestrictionPlugIn extends ExternalGanttPlugin {
  public strictEntryTypes: number[];

  private rowRestrictions: RowTypeIdTupel[] = [];

  /**
   * Handles initial registration of row restricitons.
   * @param templateData Gantt template data.
   * @param ganttResponseData Gantt backend hierarchical data.
   */
  public onInit(templateData: GanttTemplateData, ganttResponseData: IGanttTemplateValue): void {
    if (ganttResponseData.hierarchicalPlan && ganttResponseData.hierarchicalPlan.ganttEntries) {
      if (ganttResponseData.hierarchicalPlan.strictEntryTypes) {
        this.strictEntryTypes = ganttResponseData.hierarchicalPlan.strictEntryTypes;
        this.gantt.getShiftTranslator().translationRowLimiter.setStrictEntryTypes(this.strictEntryTypes);
      }
      this.rowRestrictions = this.extractRowRestrictions(ganttResponseData.hierarchicalPlan.ganttEntries);
      // this.addDragRestrictionsByGanttResponseData(ganttResponseData.hierarchicalPlan.ganttEntries, this.rowRestrictions);
      const restrictBlockDragPlugIn: GanttRestrictBlockDragPlugIn = this._ganttPluginHandlerService.getEssentialPlugIn(
        GanttEssentialPlugIns.RestrictBlockDragPlugIn
      );
      restrictBlockDragPlugIn.addBlockDragRestrictionByGanttResponseData(
        ganttResponseData.hierarchicalPlan.ganttEntries
      );
    }
  }

  public onDestroy(): void {}

  /**
   * Extracts row restricitons by given gantt Entries.
   * @param ganttEntries Gantt entries where row restricitons will be extracted.
   */
  public extractRowRestrictions(ganttEntries: GanttChildren[]): RowTypeIdTupel[] {
    const allRowTypesWithId: RowTypeIdTupel[] = [];
    this._ganttLibService.ganttInstanceService.getInstance(EGanttInstance.DATA_MANIPULATOR).iterateOverDataSet(
      ganttEntries,
      {
        getAllRowTypes: (child) => {
          if (child.allowedEntryTypes) {
            allRowTypesWithId.push({
              rowTypes: child.allowedEntryTypes,
              rowId: child.id,
            });
          }
        },
      },
      null,
      'children'
    );
    return allRowTypesWithId;
  }

  /**
   * Updates restrictions for given rows.
   * @param rows Rows where all restrictions should be updated.
   */
  public updateByRowData(rows: GanttChildren[]): void {
    const ganttDiagram = this.gantt;
    // remove restriction data from gantt
    ganttDiagram.getShiftTranslator().translationAreaLimiter.clearShiftSpecificForbiddenAreas();
    // update row restrictions
    const newRowTypesWithId: RowTypeIdTupel[] = this.extractRowRestrictions(rows);
    for (const newRestriction of newRowTypesWithId) {
      const updateIndex = this.rowRestrictions.findIndex((row) => row.rowId == newRestriction.rowId);
      if (updateIndex == -1) this.rowRestrictions.push(newRestriction);
      else this.rowRestrictions.splice(updateIndex, 1, newRestriction);
    }
  }

  /**
   * Returns all existing row restrictions.
   */
  public getRowRestrictions(): RowTypeIdTupel[] {
    return this.rowRestrictions;
  }

  public onAction(action: any) {}

  public executeAction(action: any): Observable<any> {
    return of(null);
  }

  public injectSettings(submenuElements: SaxMsBestGanttActiveSubmenuEntryElementSetting[]): void {}
}

export interface RowTypeIdTupel {
  rowTypes: number[];
  rowId: string;
}

export class GanttShiftTranslationCompleteRowRestrictionItem {
  rowIds: any[] = [];
  shiftId: string;

  constructor(shiftId: string, rowIds: string[]) {
    this.shiftId = shiftId;
    for (const rowId of rowIds) {
      this.rowIds.push({
        intervals: [{ start: null, end: null }],
        rowId: rowId,
      });
    }
  }
}
