import { FlatTreeControl } from '@angular/cdk/tree';
import { Injectable } from '@angular/core';
import { HierarchicalMenuItem } from '@app-modeleditor/components/template-ui/hierarchical-menu-item';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { finalize, switchMap } from 'rxjs/operators';
import { ERequestMethod, RequestService } from '../../../request.service';
import { TemplateAdapter } from './../../../utils/template-factory.service';
import { ETreeSettingsKeys } from './tree-settings-keys.enum';
import { TreeSettingsService } from './tree-settings.service';

@Injectable()
export class FileDatabase {
  dataChange = new BehaviorSubject<HierarchicalMenuItem[]>([]);
  treeControl: FlatTreeControl<HierarchicalMenuItem>;
  get data(): HierarchicalMenuItem[] {
    return this.dataChange.value;
  }

  constructor(
    private requestApi: RequestService,
    private _treeSettingsApi: TreeSettingsService,
    private templateFactory: TemplateAdapter
  ) {}

  public initialize(_data: HierarchicalMenuItem[]): void {
    if (!_data) {
      return;
    }

    this.buildFileTree(null, _data, 0);

    // Notify the change.
    this.dataChange.next(_data);
  }

  private getNodeByAttribute(attrib: string, value: string, nodes: HierarchicalMenuItem[]) {
    for (const node of nodes) {
      if (node[attrib] === value) {
        return node;
      }
      const match: HierarchicalMenuItem = this.getNodeByAttribute(attrib, value, node.getHierachicMenuItems());
      if (match) {
        return match;
      }
    }

    return null;
  }

  private getRecursion(items: HierarchicalMenuItem[]): HierarchicalMenuItem[] {
    return [].concat(
      ...items.map((hmi: HierarchicalMenuItem) => {
        const hmis: HierarchicalMenuItem[] = this.getRecursion(hmi.getHierachicMenuItems());

        return this.treeControl.isExpanded(hmi) ? hmis.concat(hmi) : hmis;
      })
    );
  }

  getOpenedNodeIds(): HierarchicalMenuItem[] {
    return this.getRecursion(this.dataChange.getValue() || []);
  }

  /**
   * returns node by uuid
   * @param uuid uuid
   */
  getNodeByUUID(uuid: string, nodes: HierarchicalMenuItem[]): HierarchicalMenuItem {
    return this.getNodeByAttribute('uuid', uuid, nodes);
  }

  /**
   * returns node by uuid
   * @param uuid uuid
   */
  getNodeById(id: string, nodes: HierarchicalMenuItem[]): HierarchicalMenuItem {
    return this.getNodeByAttribute('id', id, nodes);
  }

  getParentList(hmi: HierarchicalMenuItem): string[] {
    if (!hmi) {
      return [];
    }
    const list: string[] = [hmi.getId()];

    if (hmi && hmi.getParentId()) {
      const parent: HierarchicalMenuItem = this.getNodeById(hmi.getParentId(), this.data);
      return list.concat(...this.getParentList(parent));
    }

    return list;
  }

  public toggleNode(
    node: HierarchicalMenuItem,
    force?: boolean,
    expandedStates?: { [key: string]: boolean },
    skipExpanding?: boolean
  ): Observable<any> {
    // save current state in settings for reloading tree
    if (!skipExpanding) {
      this._treeSettingsApi.updateTreeSettingsKey(
        ETreeSettingsKeys.EXPANDED_NODES,
        this.getExpandedStates(this.data, true),
        true
      );
    }
    if (
      node &&
      node.getSubHierachicMenuItemsRestUrl() &&
      (force ||
        ((!this.treeControl || this.treeControl.isExpanded(node) === true) &&
          node.getHierachicMenuItems().length === 0))
    ) {
      // node.setHierachicMenuItems([]);
      return this.requestApi.call(ERequestMethod.GET, `rest/${node.getSubHierachicMenuItemsRestUrl()}`).pipe(
        switchMap((data: any[]) => {
          const currentHmi: HierarchicalMenuItem = this.templateFactory.adapt(data);
          return this.updateNode(
            node.getUuid(),
            currentHmi.getHierachicMenuItems(),
            currentHmi,
            expandedStates,
            skipExpanding
          );
        })
      );
    }

    return of(null);
  }

  private expandedStates: { [key: string]: boolean } = {};
  public getExpandedStates(nodes?: HierarchicalMenuItem[], trim?: boolean): { [key: string]: boolean } {
    if (!this.treeControl) {
      return null;
    }
    if (!nodes) {
      nodes = this.data;
    }
    nodes.forEach((node: HierarchicalMenuItem) => {
      if (this.treeControl.isExpanded(node)) {
        this.expandedStates[node.getId()] = this.treeControl.isExpanded(node);
      } else if (trim) {
        delete this.expandedStates[node.getId()];
      }
      this.getExpandedStates(node.getHierachicMenuItems(), trim);
    });
    return this.expandedStates;
  }

  updateNode(
    uuid: string,
    items: HierarchicalMenuItem[],
    newHmi?: HierarchicalMenuItem,
    states?: { [key: string]: boolean },
    skipExpanding?: boolean
  ): Observable<any> {
    // @todo: wird erweitert
    const data: HierarchicalMenuItem[] = this.dataChange.getValue();
    const n: HierarchicalMenuItem = this.getNodeByUUID(uuid, data);
    if (!n) {
      return of(null);
    }
    n.setHierachicMenuItems(items);
    if (newHmi) {
      n.setName(newHmi.getName());
    }
    // expand cur node
    if (states) {
      n.setExpanded(states[n.getId()]);
    }

    n.setLeaf(n.getHierachicMenuItems().length > 0 ? false : true);
    // set leaf of current updated hmi
    // n.setLeaf(n.getHierachicMenuItems().length > 0 ? false : true);

    if (states) {
      return forkJoin(
        n.getHierachicMenuItems().map((n) => {
          n.setExpanded(states[n.getId()]);
          if (n.isExpanded()) {
            return this.toggleNode(n, true, states, skipExpanding);
          }
          return of(null);
        })
      ).pipe(
        finalize(() => {
          this.buildFileTree(n, items, n.getLevel() + 1);
          this.dataChange.next(data);
        })
      );
    }

    this.buildFileTree(n, items, n.getLevel() + 1);
    this.dataChange.next(data);
    return of(null);
  }

  /**
   * Build the file structure tree. The `value` is the Json object, or a sub-tree of a Json object.
   * The return value is the list of `FileNode`.
   */
  buildFileTree(parent: HierarchicalMenuItem, obj: HierarchicalMenuItem[], level): void {
    obj.forEach((elem: HierarchicalMenuItem) => {
      elem.addClass('template-tree-node');
      elem.setLevel(level);

      if (parent) {
        // elem.parent = parent.hmi;
      }
      // n.hmi = elem;

      this.buildFileTree(elem, elem.getHierachicMenuItems(), level + 1);
      return elem;
    });
  }
}
