import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ConfigService } from '@core/config/config.service';
import { Config } from 'jodit/src/config';
import { IStorage } from 'jodit/src/types/storage';
import { IStorage as IStorage2 } from 'jodit/types/types/storage';
import { Subject, of } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';
import { EntryElementValue } from './../entry-collection/entry-element-value';
import { RICH_TEXT_EDITOR_TOOLBAR_BUTTONS } from './rich-text-edit-toolbar-items';
import { RichTextEditor } from './rich-text-editor';

@Component({
  selector: 'rich-text-editor',
  templateUrl: './rich-text-editor.component.html',
  styleUrls: ['./rich-text-editor.component.scss'],
})
export class RichTextEditorComponent implements OnInit, OnDestroy {
  @Input() public template: RichTextEditor;
  @Output() public changeEvent: EventEmitter<RichTextEditor> = new EventEmitter<RichTextEditor>();
  private changeTimeoutId: Subject<void> = new Subject<void>();
  private uselessProperty: IStorage | IStorage2 | Config; // unused but needed to prevent compile warnings TODO: remove on higher version of jodit
  public editorConfig = {
    buttons: RICH_TEXT_EDITOR_TOOLBAR_BUTTONS,
    buttonsMD: RICH_TEXT_EDITOR_TOOLBAR_BUTTONS,
    buttonsSM: RICH_TEXT_EDITOR_TOOLBAR_BUTTONS,
    buttonsXS: RICH_TEXT_EDITOR_TOOLBAR_BUTTONS,
    toolbarAdaptive: false,
    enter: 'BR',
    toolbar: true,
    readonly: false,
    language: 'de',
    height: 300,
    minHeight: 300,
    uploader: {
      insertImageAsBase64URI: true,
    },
  };
  public contentString = ``;

  constructor(private configService: ConfigService) {}

  ngOnDestroy() {
    this.changeTimeoutId.next();
    this.changeTimeoutId.complete();
  }

  afterInit() {}

  ngOnInit(): void {
    const isHtmlContent = (this.value || '').includes('<!DOCTYPE html>') || (this.value || '').includes('<html>');
    if (!isHtmlContent) {
      // add break lines only to strings without html tags
      this.contentString = (this.value || '').replace(/\r\n|\r|\n/g, '<br/>');
    } else {
      this.contentString = this.value || '';
    }
    this.editorConfig.language = this.configService.access().language;
    this.editorConfig.toolbar = !this.template.isDisabled();
    this.editorConfig.height = this.template.getHeight();
    this.editorConfig.readonly = this.template.isDisabled();
  }

  get value(): string {
    const r: string =
      this.template && this.template.getValue<EntryElementValue>()
        ? this.template.getValue<EntryElementValue>().getValue<string>()
        : null;
    return r;
  }

  public changeValue(changes: string): void {
    of(null)
      .pipe(delay(400), takeUntil(this.changeTimeoutId))
      .subscribe(() => {
        this.template.setValue(this.template.getValue<EntryElementValue>().setValue(`<html> ${changes} </html>`));
        this.changeEvent.emit(this.template);
      });
  }
}
