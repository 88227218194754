import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SaxMsBestGanttSettings } from 'frontend/src/dashboard/gantt/gantt/saxms-best-gantt.settings';
import { IAdditionalGanttSettingsData } from 'frontend/src/dashboard/gantt/general/gantt-settings/gantt-settings-dialog/gantt-settings.interface';

/**
 * Displays the Gantt setting options with control buttons.
 */
@Component({
  selector: 'app-setting-control',
  templateUrl: './setting-control.component.html',
  styleUrls: ['./setting-control.component.scss'],
})
export class SettingControlComponent implements OnInit {
  @Input() settings: SaxMsBestGanttSettings;
  @Input() additionalData: IAdditionalGanttSettingsData;
  @Output() changeEvent: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  onChanges() {
    this.changeEvent.emit(this.settings);
  }
}
