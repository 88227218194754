import { Injectable, Type } from '@angular/core';
import { TemplateAdapter } from '@app-modeleditor/utils/template-factory.service';
import { Adapter } from '../../../core/adapter';
import { TemplateResourceAdapter } from './../../utils/template-resource-factory.service';
import { ContextMenuItem } from './context-menu-item';
import { ContextMenuItemAdapter } from './context-menu-item.adapter.service';
import { ContextMenu } from './contextmenu';

@Injectable({
  providedIn: 'root',
})
export class ContextMenuAdapter implements Adapter<ContextMenu> {
  constructor(
    private templateResourceFactory: TemplateResourceAdapter,
    private _contextMenuItemAdapter: ContextMenuItemAdapter
  ) {}

  adapt(item: any): ContextMenu {
    throw new Error('Method not implemented.');
  }

  inherit?<T extends ContextMenu>(type: Type<T>, item: any): T {
    const ctx: T = this.templateResourceFactory
      .inherit<T>(type, item)
      .setContextMenuItems(
        (item.contextMenuItems || []).map((i) =>
          this._contextMenuItemAdapter.inherit<ContextMenuItem>(ContextMenuItem, i)
        )
      );
    return ctx;
  }

  inheritFrom?<T extends ContextMenu>(scope: TemplateAdapter, type: Type<T>, item: any): T {
    const ctx: T = this.templateResourceFactory
      .inheritFrom<T>(scope, type, item)
      .setContextMenuItems(
        (item.contextMenuItems || []).map((i) =>
          this._contextMenuItemAdapter.inheritFrom<ContextMenuItem>(scope, ContextMenuItem, i)
        )
      );
    return ctx;
  }

  applyValues<T>(scope: TemplateAdapter, item: any, values: any): T {
    throw new Error('Method not implemented.');
  }
}
