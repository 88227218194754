import { GanttResponse } from '../response';

/**
 * Offers possibility to mark shifts as selected by backend.
 */
export class GanttBlockSelectionUpdate extends GanttResponse {
  public handleResponse(response: any): boolean {
    if (!response.blockSelectUpdate) return false;
    const ganttDiagram = this._ganttLibService.bestGantt;
    ganttDiagram.deselectAllShifts();

    const mapper = this._ganttLibService.backendToGanttOriginInputMapper;

    const selectionIds = [];
    response.blockSelectUpdate.forEach((blockId) => {
      if (mapper.hasOriginShiftClones(blockId)) {
        selectionIds.push(...mapper.getShiftClonesByShiftId(blockId));
      } else {
        selectionIds.push(blockId);
      }
    });

    ganttDiagram.selectShiftsByIDs(selectionIds);
    return true;
  }
}
