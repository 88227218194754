import { EGanttInstance, GanttEditPermissions } from '@gantt/public-api';
import { SaxMsBestGanttActiveSubmenuEntryElementSetting } from 'frontend/src/dashboard/gantt/gantt/saxms-best-gantt.settings';
import { GanttChildren } from 'frontend/src/dashboard/gantt/general/generator/gantt-input.data';
import { Observable, of } from 'rxjs';
import { ExternalGanttPlugin } from '../../../external-plugin';

export const GanttRestrictBlockDragExecuter = 'gantt-plugin-restrict-block-drag-executer';

/**
 * PlugIn-Wrapper for GanttShiftDragLimiter.
 * Handles drag limitation on block level.
 */
export class GanttRestrictBlockDragPlugIn extends ExternalGanttPlugin {
  public onInit(templateData: any, responseData: any) {
    this.addPlugIn(
      GanttRestrictBlockDragExecuter,
      this._ganttLibService.ganttInstanceService.getInstance(EGanttInstance.SHIFT_DRAG_LIMITER)
    );
  }

  public onDestroy(): void {}

  public onAction(action: any) {}

  public executeAction(action: any): Observable<any> {
    return of(null);
  }

  /**
   * Add block restrictions by serverresponse.
   * @param ganttResponseEntries Gantt entries from response.
   */
  public addBlockDragRestrictionByGanttResponseData(ganttResponseEntries: GanttChildren[]): void {
    const s = this;
    this._ganttLibService.ganttInstanceService.getInstance(EGanttInstance.DATA_MANIPULATOR).iterateOverDataSet(
      ganttResponseEntries,
      {
        getAllBlockDragRestricitons: (child) => {
          for (const block of child.blocks || []) {
            if (!block || !block.modificationRestriction) continue;
            s.setBlockDraggingRestrictions(block.id, block.modificationRestriction);
          }
        },
      },
      null,
      'children'
    );
  }

  /**
   * Sets dragging restrictions for one block by backend response.
   * @param blockId Block which will be affected.
   * @param modificationRestriction Dragging restriction.
   */
  private setBlockDraggingRestrictions(blockId: string, modificationRestriction: GanttEditPermissions): void {
    let dragRestriction: GanttRestrictBlockDragModus;
    if (
      modificationRestriction.hasOwnProperty('edit_allow_changeRow') &&
      !modificationRestriction.edit_allow_changeRow &&
      modificationRestriction.hasOwnProperty('edit_allow_move') &&
      !modificationRestriction.edit_allow_move
    )
      dragRestriction = GanttRestrictBlockDragModus.NODRAG;
    else if (
      modificationRestriction.hasOwnProperty('edit_allow_changeRow') &&
      !modificationRestriction.edit_allow_changeRow
    )
      dragRestriction = GanttRestrictBlockDragModus.VERTICAL;
    else if (modificationRestriction.hasOwnProperty('edit_allow_move') && !modificationRestriction.edit_allow_move)
      dragRestriction = GanttRestrictBlockDragModus.HORIZONTAL;
    else this.removeBlockRestriction(blockId);
    if (dragRestriction) {
      this.restrictBlock(blockId, dragRestriction);
    }
  }

  /**
   * Adds drag restriction to given block.
   * @param blockId Affected block.
   * @param dragRestriction Drag restriction.
   */
  public restrictBlock(blockId: string, dragRestriction: GanttRestrictBlockDragModus): void {
    const mapper = this._ganttLibService.backendToGanttOriginInputMapper;
    mapper.getShiftClonesByShiftId(blockId).forEach((id) => {
      this.getPlugInById(GanttRestrictBlockDragExecuter).addShiftDragRestriction(id, dragRestriction);
    });
  }

  /**
   * Removes drag restriction from given block.
   * @param blockId Affected block.
   */
  public removeBlockRestriction(blockId: string): void {
    const mapper = this._ganttLibService.backendToGanttOriginInputMapper;
    mapper.getShiftClonesByShiftId(blockId).forEach((id) => {
      this.getPlugInById(GanttRestrictBlockDragExecuter).removeShiftDragRestriction(id);
    });
  }

  public injectSettings(submenuElements: SaxMsBestGanttActiveSubmenuEntryElementSetting[]): void {}
}

/**
 * Drag restrictions for GanttShiftDragLimiter plugin.
 */
export enum GanttRestrictBlockDragModus {
  VERTICAL = 'VERTICAL',
  HORIZONTAL = 'HORIZONTAL',
  NODRAG = 'NODRAG',
}
