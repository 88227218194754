import {
  TriggerEventFactory,
  TriggerEventFactoryItem,
} from 'frontend/src/dashboard/gantt/general/gantt-actions/event-factory';
import { EGanttActionEvent } from '../../../../gantt-actions/gantt-action-handling/gantt-action-event.enum';
import { ResourceChangeShiftOnClickEvent } from './onlick/shift-onclick';

/**
 * Event factory to create event callbacks by backend configuration.
 * Add more TriggerEventFactoryItems to extend event handling.
 */
export class TriggerEventResourceChangeFactory extends TriggerEventFactory {
  constructor(templateData: any) {
    super(templateData, [new TriggerEventFactoryItem(EGanttActionEvent.ON_CLICK, ResourceChangeShiftOnClickEvent)], []);
  }
}
