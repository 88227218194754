import { Component, Inject, OnChanges, OnDestroy, OnInit } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-create-modal',
  templateUrl: './create.modal.html',
  styleUrls: ['./create.modal.scss'],
  providers: [],
})
export class CreateModalComponent implements OnInit, OnChanges, OnDestroy {
  entries: any[];
  selected: string; // selected url
  constructor(private dialogRef: MatDialogRef<CreateModalComponent>, @Inject(MAT_DIALOG_DATA) data: any) {
    this.entries = [];
    for (const key of Object.keys(data.entries)) {
      this.entries.push({ key: key, data: data.entries[key] });
    }

    this.entries = this.entries.slice();
    this.selected = this.entries[0].data;
  }

  confirm() {
    this.dialogRef.close(this.selected);
  }

  ngOnInit() {}

  ngOnChanges() {}

  ngOnDestroy(): void {
    // Called once, before the instance is destroyed.
    // Add 'implements OnDestroy' to the class.
  }
}
