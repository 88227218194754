/**
 * List of attribute filters.
 */
export class AttributeFilter {
  attributeEntries: AttributeFilterEntry[] = [];
}

/**
 *
 */
export class AttributeFilterEntry {
  type: string;
  values: string[] = [];
}
