<mat-list>
  <mat-list-item class="contentText">
    {{ 'GANTT.blockLabels' | translate }}
    <mat-form-field class="select" appearance="standard">
      <mat-select [(value)]="selectedBlockLabelOption" (selectionChange)="onBlockLabelSelectionChange($event)">
        <mat-option *ngFor="let blockLabelsOption of blockLabelsOptions" [value]="blockLabelsOption.value">
          {{ blockLabelsOption.viewValue | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </mat-list-item>
  <mat-divider></mat-divider>
  <mat-list-item class="contentText">
    {{ '@text-color-shift@' | translate }}
    <app-color-picker
      [color]="settings.textColorShifts"
      (changeEvent)="setBlockLabelColor($event)"
      [resetColor]="'#FFFFFF'"
      [disableAlpha]="true"
    >
    </app-color-picker>
  </mat-list-item>
  <mat-divider></mat-divider>
  <mat-list-item class="contentText">
    {{ 'GANTT.FONTS.fontsize' | translate }}
    <app-slider
      class="sliderContainer"
      [max]="22"
      [min]="4"
      [value]="settings.fontSize"
      [label]="settings.fontSize + ' px'"
      (changeEvent)="fontSizeSliderChange($event)"
    ></app-slider>
  </mat-list-item>
  <mat-divider></mat-divider>
  <mat-list-item class="contentText">
    {{ 'GANTT.behavior-low-contrast' | translate }}
    <mat-form-field class="select" appearance="standard">
      <mat-select
        [(value)]="selectedLowContrastBehaviorOption"
        (selectionChange)="onLowContrastBehaviorOptionChange($event)"
      >
        <mat-option
          *ngFor="let lowContrastBehaviorOption of lowContrastBehaviorOptions"
          [value]="lowContrastBehaviorOption.value"
        >
          {{ lowContrastBehaviorOption.viewValue | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </mat-list-item>
</mat-list>
