<div
  class="attribue-item-label-container"
  [ngStyle]="{
    background: attributeItem.getAvailableValues().length > 0 && !disabledBackground ? '#e1e1e1' : 'transparent'
  }"
>
  <button
    *ngIf="attributeItem.getAvailableValues().length > 0"
    (click)="handleExpanded(attributeItem)"
    class="attribute-item-btn"
    mat-icon-button
  >
    <mat-icon class="attribute-item-btn-icon">
      {{ attributeItem.isExpanded() ? 'keyboard_arrow_down' : 'keyboard_arrow_right' }}</mat-icon
    >
  </button>
  <div
    class="attribue-item-label"
    [ngStyle]="{
      color:
        (attributeItem.getParentItem() && attributeItem.isActive()) || attributeItem.getAvailableValues().length > 0
          ? 'black'
          : '#adadad'
    }"
    [matTooltip]="attributeItem.getLabel()"
    [matTooltipShowDelay]="2000"
    (click)="
      attributeItem.getAvailableValues().length === 0
        ? onAttributeClick.emit(attributeItem)
        : handleExpanded(attributeItem)
    "
  >
    {{ attributeItem.getLabel() }}
  </div>
  <button *ngIf="attributeItem.getAvailableValues().length > 0" class="attribute-item-btn" mat-icon-button>
    <mat-icon
      class="attribute-item-btn-icon"
      [matTooltip]="'CHART.LEGEND.ATTRIBUTE.GROUP.toogleGroup' | translate"
      (click)="onToogleAttributeGroup.emit(attributeItem)"
      >sync</mat-icon
    >
  </button>
  <button *ngIf="attributeItem.getAvailableValues().length > 0" class="attribute-item-btn" mat-icon-button>
    <mat-icon
      class="attribute-item-btn-icon"
      [matTooltip]="'CHART.LEGEND.ATTRIBUTE.GROUP.addGroup' | translate"
      (click)="onActiveAttributeGroup.emit(attributeItem)"
      >add</mat-icon
    >
  </button>
</div>
<div
  class="attribue-item-expand-container"
  *ngIf="attributeItem.isExpanded() && attributeItem.getAvailableValues().length > 0"
>
  <app-chart-attribute-legend-item
    *ngFor="let item of attributeItem.getAvailableValues()"
    (onAttributeClick)="onAttributeClick.emit($event)"
    (onToogleAttributeGroup)="onToogleAttributeGroup.emit($event)"
    (onActiveAttributeGroup)="onActiveAttributeGroup.emit($event)"
    [attributeItem]="item"
  >
  </app-chart-attribute-legend-item>
</div>
