import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { SaxMsSpreadSheetColumn } from '../saxms-spreadsheet';

@Injectable()
export class SaxmsColumnHeaderTemplateService {
  private sortFilterObject: BehaviorSubject<SaxMsSpreadSheetColumn> = new BehaviorSubject<SaxMsSpreadSheetColumn>(null);

  public removeFilterSubject: Subject<SaxMsSpreadSheetColumn>;
  public removeFilterObservable: Observable<SaxMsSpreadSheetColumn>;

  constructor() {
    this.removeFilterSubject = new Subject<SaxMsSpreadSheetColumn>();
    this.removeFilterObservable = this.removeFilterSubject.asObservable();
  }

  public setCurrentSortFilterObjectByColumn(header: SaxMsSpreadSheetColumn): void {
    this.sortFilterObject.next(header);
  }

  public getCurrentSortFilterObjectByColumn(): Observable<SaxMsSpreadSheetColumn> {
    return this.sortFilterObject.asObservable();
  }

  public removeSortFilterObject(header: SaxMsSpreadSheetColumn): void {
    this.removeFilterSubject.next(header);
  }
}
