import { Observable, of, Subject } from 'rxjs';
import { buffer, switchMap, takeUntil, throttleTime } from 'rxjs/operators';
import { GanttChildren, IGanttBlock } from '../generator/gantt-input.data';
import { ITimePeriodInput } from '../plugin/plugin-list/blocking-intervals/responses/interval-update/time-period-input.interface';
import { VisibilityStateService } from '../visibility-state.sevice';
import { EGanttChangeMode } from './gantt-change-mode.enum';
import { IGanttResponse } from './gantt-response';

/**
 * Class which handles the buffering of gantt update notifications.
 */
export class GanttUpdateNotificationBuffer {
  private _onDestroySubject: Subject<void> = new Subject<void>();
  private _notificationSubject: Subject<IGanttResponse> = new Subject<IGanttResponse>();
  private _bufferReleaseSubject: Subject<void> = new Subject<void>();
  private _onBufferReleaseObservable: Observable<IGanttResponse> = null;
  private _lastGanttChangeMode: EGanttChangeMode = null;

  constructor(private readonly _bufferDelay: number, private _visibilityService: VisibilityStateService) {
    if (this._bufferDelay > 0) {
      const timerObservable = this._notificationSubject
        .asObservable()
        .pipe(takeUntil(this.onDestroy))
        .pipe(throttleTime(this._bufferDelay, null, { leading: false, trailing: true }));
      timerObservable.subscribe(() => {
        if (this._visibilityService.isVisible()) {
          this._releaseBuffer();
        }
      });
    }

    this._onBufferReleaseObservable = this._notificationSubject.asObservable().pipe(
      takeUntil(this.onDestroy),
      buffer(this._bufferReleaseSubject.asObservable()),
      switchMap((notifications) => {
        try {
          return this._mergeNotifications(notifications);
        } catch (error) {
          console.error('Error when merging notifications.', error);
          return of(null);
        }
      })
    );

    this._visibilityService.isVisible$().subscribe((isVisible) => {
      if (isVisible) this._releaseBuffer();
    });
  }

  public destroy(): void {
    this._onDestroySubject.next();
    this._onDestroySubject.complete();
  }

  /**
   * Adds a specified update notification to the buffer.
   * @param notification Notification that should be added to the buffer.
   * @param forceBufferRelease Forces a buffer release if true (default = false).
   */
  public addNotification(notification: IGanttResponse, forceBufferRelease = false): void {
    if (!notification) return;
    if (this._lastGanttChangeMode && this._lastGanttChangeMode !== notification.ganttChangeMode) this._releaseBuffer();
    this._lastGanttChangeMode = notification.ganttChangeMode;
    console.log(
      // TODO: remove after DISO-1511 is fixed
      `addNotification forceBufferRelease=${forceBufferRelease}, bufferDelay=${
        this._bufferDelay
      }, isVisible=${this._visibilityService.isVisible()}, releaseBuffer=${!!(
        forceBufferRelease ||
        (this._bufferDelay <= 0 && this._visibilityService.isVisible())
      )}`
    );

    this._notificationSubject.next(notification);
    if (forceBufferRelease || (this._bufferDelay <= 0 && this._visibilityService.isVisible())) this._releaseBuffer();
  }

  /**
   * Triggers a buffer release.
   */
  private _releaseBuffer(): void {
    console.log(`releaseBuffer`); // TODO: remove after DISO-1511 is fixed
    this._bufferReleaseSubject.next();
  }

  /**
   * Merges the data of all specified update notifications into one update notification.
   * **Note:** If multiple notifications reference the same data element (e.g. gantt block/entry with same id), the element with the highest priority will be used.
   * @param notifications Array of notifications to merge ordered by priority (first element = lowest priority, last element = highest priority).
   * @returns Merged update notification.
   */
  private _mergeNotifications(notifications: IGanttResponse[]): Observable<IGanttResponse> {
    // check notifications
    notifications = this._checkNotifications(notifications);
    if (notifications.length <= 0) return of(null);

    // merge notifications
    const mergedNotification = notifications[0];
    if (notifications.length === 1) return of(mergedNotification);
    // merge responses
    for (let i = 1; i < notifications.length; i++) {
      const notification = notifications[i];
      // merge "deletedBlocks"
      if (notification.deletedBlocks && notification.deletedBlocks.length > 0) {
        if (!mergedNotification.deletedBlocks) mergedNotification.deletedBlocks = [];
        this._mergeDeletedBlocks(mergedNotification, notification);
      }
      // merge "addedConnections"
      if (notification.addedConnections && notification.addedConnections.length > 0) {
        for (const addedConnection of notification.addedConnections) {
          const foundIndex = mergedNotification.addedConnections?.findIndex((elem) => elem.id === addedConnection.id);
          if (foundIndex >= 0) mergedNotification.addedConnections.splice(foundIndex, 1);
        }
        if (!mergedNotification.addedConnections) mergedNotification.addedConnections = [];
        mergedNotification.addedConnections.push(...notification.addedConnections);
      }
      // merge "createdValue"
      if ((mergedNotification as any).created)
        ((mergedNotification as any).created as number) = (notification as any).created;
      mergedNotification.createdValue = notification.createdValue;
      // merge "ganttEntries"
      if (notification.ganttEntries && notification.ganttEntries.length > 0) {
        if (!mergedNotification.ganttEntries) mergedNotification.ganttEntries = [];
        for (const ganttEntry of notification.ganttEntries) {
          const foundIndex = mergedNotification.ganttEntries?.findIndex((elem) => elem.id === ganttEntry.id);
          if (foundIndex >= 0)
            mergedNotification.ganttEntries[foundIndex] = this._mergeGanttEntry(
              mergedNotification.ganttEntries[foundIndex],
              ganttEntry
            );
          else mergedNotification.ganttEntries.push(ganttEntry);
        }
      }
      // merge "addedGanttEntries"
      if (notification.addedGanttEntries && notification.addedGanttEntries.length > 0) {
        if (!mergedNotification.addedGanttEntries) mergedNotification.addedGanttEntries = [];
        mergedNotification.addedGanttEntries.push(...notification.addedGanttEntries);
      }
      // merge "deletedGanttEntries"
      if (notification.deletedGanttEntries && notification.deletedGanttEntries.length > 0) {
        if (!mergedNotification.deletedGanttEntries) mergedNotification.deletedGanttEntries = [];
        mergedNotification.deletedGanttEntries.push(...notification.deletedGanttEntries);
      }
      // merge "superBlockDataViews"
      for (const superBlockId in notification.superBlockDataViews) {
        if (!mergedNotification.superBlockDataViews) mergedNotification.superBlockDataViews = {};
        mergedNotification.superBlockDataViews[superBlockId] = notification.superBlockDataViews[superBlockId];
      }
      // merge "ganttTimePeriodInputs"
      if (notification.ganttTimePeriodInputs && notification.ganttTimePeriodInputs.length > 0) {
        if (!mergedNotification.ganttTimePeriodInputs) mergedNotification.ganttTimePeriodInputs = [];
        mergedNotification.ganttTimePeriodInputs = this._mergeGanttTimePeriodInputs(
          mergedNotification.ganttTimePeriodInputs,
          notification.ganttTimePeriodInputs
        );
      }
      // merge "deletedGanttTimePeriodInputs"
      if (notification.deletedGanttTimePeriodInputs && notification.deletedGanttTimePeriodInputs.length > 0) {
        if (!mergedNotification.deletedGanttTimePeriodInputs) mergedNotification.deletedGanttTimePeriodInputs = [];
        mergedNotification.deletedGanttTimePeriodInputs = this._mergeGanttTimePeriodInputs(
          mergedNotification.deletedGanttTimePeriodInputs,
          notification.deletedGanttTimePeriodInputs
        );
      }
    }
    mergedNotification.eventId = null;
    return of(mergedNotification);
  }

  /**
   * Merges the data of 2 deleted blocks lists (prefers the newer data).
   * Removes newer deleted blocks from older notification if it contains them (for a correct merge later).
   * @param mergedNotification Merged notification with older data (will be overwritten if newer data exists).
   * @param newNotification Newer notification.
   */
  private _mergeDeletedBlocks(mergedNotification: IGanttResponse, newNotification: IGanttResponse): void {
    // build map of newer deleted blocks list for faster access
    const newDeletedBlocksMap = new Map<string, IGanttBlock>();
    for (const deletedBlock of newNotification.deletedBlocks) newDeletedBlocksMap.set(deletedBlock.id, deletedBlock);

    // iterate over updated gantt entries & remove all blocks matching one of the deleted blocks
    const iterateOverGanttEntries = (ganttEntries: GanttChildren[]) => {
      for (const ganttEntry of ganttEntries) {
        // iterate over blocks
        if (ganttEntry.blocks && ganttEntry.blocks.length > 0) {
          const removedBlocks: number[] = [];
          for (let i = 0; i < ganttEntry.blocks.length; i++) {
            if (newDeletedBlocksMap.get(ganttEntry.blocks[i].id)) removedBlocks.push(i);
          }
          while (removedBlocks.length > 0) ganttEntry.blocks.splice(removedBlocks.pop(), 1);
        }
        // iterate over children
        if (ganttEntry.children && ganttEntry.children.length > 0) {
          iterateOverGanttEntries(ganttEntry.children);
        }
      }
    };
    iterateOverGanttEntries(mergedNotification.ganttEntries);

    // merge deleted blocks lists
    const mergedDeletedBlocksMap = new Map<string, IGanttBlock>();
    for (const block of mergedNotification.deletedBlocks) mergedDeletedBlocksMap.set(block.id, block);
    for (const block of newNotification.deletedBlocks) mergedDeletedBlocksMap.set(block.id, block);
    mergedNotification.deletedBlocks = Array.from(mergedDeletedBlocksMap.values());
  }

  /**
   * Merges the data of 2 gantt entries (prefers the newer data).
   * @param oldGanttEntry Older gantt entry to merge.
   * @param newGanttEntry Newer gantt entry to merge.
   * @returns Merged gantt entry.
   */
  private _mergeGanttEntry(oldGanttEntry: GanttChildren, newGanttEntry: GanttChildren): GanttChildren {
    const mergedGanttEntry = newGanttEntry;
    // merge "children"
    if (oldGanttEntry.children && oldGanttEntry.children.length > 0) {
      for (const oldChild of oldGanttEntry.children) {
        const foundIndex = mergedGanttEntry.children.findIndex((newChild) => newChild.id === oldChild.id);
        if (foundIndex >= 0)
          mergedGanttEntry.children[foundIndex] = this._mergeGanttEntry(
            oldChild,
            mergedGanttEntry.children[foundIndex]
          );
        else mergedGanttEntry.children.push(oldChild);
      }
    }
    // merge "blocks"
    if (oldGanttEntry.blocks && oldGanttEntry.blocks.length > 0) {
      if (!mergedGanttEntry.blocks) mergedGanttEntry.blocks = [];
      for (const oldBlock of oldGanttEntry.blocks) {
        const foundIndex = mergedGanttEntry.blocks.findIndex((newBlock) => newBlock.id === oldBlock.id);
        if (foundIndex < 0) mergedGanttEntry.blocks.push(oldBlock);
      }
    }
    return mergedGanttEntry;
  }

  /**
   * Merges the data of 2 arrays of gantt time period inputs.
   * @param oldTimePeriodInputs Older gantt time period inputs to merge.
   * @param newTimePeriodInputs Newer gantt time period inputs to merge.
   * @returns Merged gantt time period inputs.
   */
  private _mergeGanttTimePeriodInputs(
    oldTimePeriodInputs: ITimePeriodInput[],
    newTimePeriodInputs: ITimePeriodInput[]
  ): ITimePeriodInput[] {
    const mergedTimePeriodInputs = newTimePeriodInputs;
    const mergedIds = new Set<string>();
    mergedTimePeriodInputs.forEach((timePeriodInput) => mergedIds.add(timePeriodInput.id));

    // merge old gantt time period inputs into array of new ones
    if (oldTimePeriodInputs.length > 0) {
      for (const oldTimePeriodInput of oldTimePeriodInputs) {
        if (!mergedIds.has(oldTimePeriodInput.id)) {
          mergedTimePeriodInputs.push(oldTimePeriodInput);
          mergedIds.add(oldTimePeriodInput.id);
        }
      }
    }
    return mergedTimePeriodInputs;
  }

  /**
   * Checks the passed array of notifications and cleans it up.
   * This method always returns an array where every item is definitely a valid gantt notification.
   * @param notifications Array of notifications to check.
   */
  private _checkNotifications(notifications: IGanttResponse[]): IGanttResponse[] {
    const checkedNotifications: IGanttResponse[] = [];
    if (notifications && notifications.length > 0) {
      for (const notification of notifications) {
        if (notification) {
          checkedNotifications.push(notification);
        }
      }
    }
    return checkedNotifications;
  }

  /**
   * Observable which gets triggered when the instance gets destroyed.
   */
  private get onDestroy(): Observable<void> {
    return this._onDestroySubject.asObservable();
  }

  /**
   * Observable that returns the buffered data after each buffer release.
   */
  public get onBufferRelease(): Observable<IGanttResponse> {
    return this._onBufferReleaseObservable;
  }
}
