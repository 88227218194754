/**
 * Settings to restrict zoom-transformator executer.
 * @keywords plugin, executer, xaxis, x axis, time, format, config, settings, rules, restriction
 * @plugin time-manipulator
 * @class
 * @constructor
 */
export class GanttXAxisZoomConfig {
  matchZoomLevelToNewFormat: boolean;

  constructor() {
    this.matchZoomLevelToNewFormat = false;
  }

  /**
   * @param {boolean} matchZoomLevelToNewFormat If true, plugin zooms x axis to matching time format if global x axis format will be changed.
   */
  matchZoomLevel(matchZoomLevelToNewFormat) {
    this.matchZoomLevelToNewFormat = matchZoomLevelToNewFormat;
  }
}
