import { YAxisDataFinder } from '../../../data-handler/data-finder/yaxis-data-finder';
import { GanttCanvasRow } from '../../../data-handler/data-structure/data-structure';
import { GanttStickyRowStrategy } from './sticky-row-strategy.base';

/**
 * Gantt sticky row strategy that makes the first top parent row of the gantt diagram sticky.
 */
export class GanttStickyFirstRowStrategy extends GanttStickyRowStrategy {
  public init(): void {
    this._resetConfig();
  }

  public destroy(): void {
    this._resetConfig();
  }

  public getManipulatedYAxisDataSet(dataSet: GanttCanvasRow[]): GanttCanvasRow[] {
    const scrollTop = this._ganttDiagram.getNodeProportionsState().getScrollTopPosition() || 0;

    // if not scrolled -> no need for sticky rows
    if (scrollTop === 0) {
      this._removeAllStickyRows(dataSet);
      this._resetConfig();
      return dataSet;
    }

    // get first row
    const firstRow = YAxisDataFinder.getRowByYPosition(dataSet, 0);

    // iterate through all rows and set their respective sticky flag
    let stickyRowHeightsCombined = 0;
    let isPreviousRowSticky = false;
    let firstNotStickyRow: GanttCanvasRow = undefined;
    const newStickyRows = new Set<GanttCanvasRow>();

    for (const row of dataSet) {
      if (row.id === firstRow.id || row.id.startsWith(firstRow.id + this._executer.getSplitPlugInId() || '')) {
        row.sticky = true;
        stickyRowHeightsCombined += row.height;
        newStickyRows.add(row);
        isPreviousRowSticky = true;
      } else {
        row.sticky = false;
        if (isPreviousRowSticky) firstNotStickyRow = row;
        isPreviousRowSticky = false;
      }
    }

    // if sticky rows are last rows
    //   -> no need for sticky rows
    if (!firstNotStickyRow) {
      for (const row of newStickyRows) row.sticky = false;
      this._resetConfig();
      return dataSet;
    }

    // check sticky rows container height limitations
    // max height
    const containerMaxHeightPx = this._getContainerMaxHeightPx(stickyRowHeightsCombined, firstNotStickyRow?.y);
    this._ganttDiagram.getConfig().setStickyRowsContainerMaxHeightPx(containerMaxHeightPx);

    // min height
    const containerMinHeightPx = this._getContainerMinHeightPx(stickyRowHeightsCombined, firstNotStickyRow?.y);
    this._ganttDiagram.getConfig().setStickyRowsContainerMinHeightPx(containerMinHeightPx);

    // return reference to manipulated dataset
    return dataSet;
  }

  /**
   * Determines the maximum height of the sticky rows scroll container by the given values.
   * @param rowHeightsCombined Height of all sticky rows combined (in px).
   * @param firstNotStickyRowY Canvas data y position of the first row below all sticky rows (in px).
   * @returns Maximum height of the sticky rows scroll container determined by the given values (in px).
   */
  private _getContainerMaxHeightPx(rowHeightsCombined: number, firstNotStickyRowY: number = undefined): number {
    const shiftViewportHeight = this._ganttDiagram.getNodeProportionsState().getShiftViewPortProportions().height;
    const scrollTop = this._ganttDiagram.getNodeProportionsState().getScrollTopPosition();

    const containerMaxHeightRelative = this._ganttDiagram.getConfig().stickyRowsContainerMaxHeight();
    const containerMaxHeightRelativePx = containerMaxHeightRelative * shiftViewportHeight;

    const firstNotStickyRowViewportY = isNaN(firstNotStickyRowY) ? undefined : firstNotStickyRowY - scrollTop;

    // if sticky rows are higher than allowed AND there would be a gap between sticky rows container and first non-sticky row
    //   -> set max height to viewport y of first non-sticky row
    if (
      !isNaN(firstNotStickyRowViewportY) &&
      rowHeightsCombined > containerMaxHeightRelativePx &&
      firstNotStickyRowViewportY > containerMaxHeightRelativePx
    ) {
      this._ganttDiagram.getConfig().setStickyRowsAllowUnlimitedContainerHeight(true);
      return Math.max(Math.min(firstNotStickyRowViewportY, shiftViewportHeight), containerMaxHeightRelativePx);
    }
    // else
    //   -> use max height as specified
    this._ganttDiagram.getConfig().setStickyRowsAllowUnlimitedContainerHeight(false);
    return Math.min(rowHeightsCombined, shiftViewportHeight);
  }

  /**
   * Determines the minimum height of the sticky rows scroll container by the given values.
   * @param rowHeightsCombined Height of all sticky rows combined (in px).
   * @param firstNotStickyRowY Canvas data y position of the first row below all sticky rows (in px).
   * @returns Minimum height of the sticky rows scroll container determined by the given values (in px).
   */
  private _getContainerMinHeightPx(rowHeightsCombined: number, firstNotStickyRowY: number = undefined): number {
    const scrollTop = this._ganttDiagram.getNodeProportionsState().getScrollTopPosition();

    const firstNotStickyRowViepwortY = isNaN(firstNotStickyRowY) ? undefined : firstNotStickyRowY - scrollTop;

    // if there are sticky rows AND there could be a gap between sticky rows container and first non-sticky row when
    // resizing manually
    //   -> set min height to viewport y of first non-sticky row
    if (rowHeightsCombined > 0) {
      if (!isNaN(firstNotStickyRowViepwortY) && firstNotStickyRowViepwortY > 0) {
        return firstNotStickyRowViepwortY;
      }
    }

    // else
    //   -> set min height to disabled
    return 0;
  }

  /**
   * Resets the gantt config to the default values of this sticky row strategy.
   */
  private _resetConfig(): void {
    const config = this._ganttDiagram.getConfig();
    config.setStickyRowsAllowUnlimitedContainerHeight(false);
    config.setStickyRowsContainerMinHeightPx(0);
    config.setStickyRowsContainerMaxHeightPx(0);
    config.setStickyRowsContainerOptimalHeightPx(0);
  }
}
