/**
 * actions throw an error if they are:
 * - no null values
 * - no empty arrays allowed
 * - no empty objects allowed
 * - none of the actions in a chain can fail
 */
export enum EValidationRules {
  NOT_NULL = 'NOT_NULL',
  NO_EMPTY_ARRAYS = 'NO_EMPTY_ARRAYS',
  NO_EMPTY_OBJECTS = 'NO_EMPTY_OBJECTS',
  NO_INVALID_SELECTOR = 'NO_INVALID_SELECTOR',
  EXACTLY_ONE = 'EXACTLY_ONE', // only one element in array
}
