import * as d3 from 'd3';
import { Observable, Subject, Subscription } from 'rxjs';
import { sampleTime, takeUntil } from 'rxjs/operators';
import { GanttUtilities } from '../../gantt-utilities/gantt-utilities';
import { NodeProportionsStateConnector } from '../../html-structure/node-proportion-state/node-proportion-state-connector';
import { EGanttScrollContainer } from '../../html-structure/scroll-container.enum';
import { BestGantt } from '../../main';
import { BestGanttPlugIn } from '../gantt-plug-in';

/**
 * Gantt extension to colorize rows by a given time span.
 * @keywords plugin, extension, mark, show, highlight, color, colorize, row
 * @plugin row-colorizer
 * @class
 * @constructor
 * @extends BestGanttPlugIn
 *
 * @requires BestGanttPlugIn
 */
export class GanttRowColorizer extends BestGanttPlugIn {
  private _parentNode: { [id: string]: d3.Selection<SVGGElement, undefined, d3.BaseType, undefined> } = {};

  private _defaultColor = '#4f4f4f';
  private _colorizedRows: GanttRowColorizeEntry[] = [];
  private _currentRenderDataSet: RowColorizeRenderData[] = [];
  private _currentHoveredRowId: string | null = null;
  private _mouseOverSubscription: Subscription;

  private _onDestroySubject: Subject<void> = new Subject<void>();

  constructor() {
    super(); // call super-constructor
  }

  public initPlugIn(ganttDiagram: BestGantt): void {
    this.ganttDiagram = ganttDiagram;

    this._initCanvas();
    this._zoom();
    // register callbacks
    this._subscribeCallbacks();
    this.tooltipHandling();
  }

  private _initCanvas(): void {
    for (const scrollContainerId of Object.values(EGanttScrollContainer)) {
      this._parentNode[scrollContainerId] = this.ganttDiagram
        .getShiftFacade()
        .getCanvasBehindShifts(scrollContainerId)
        .insert('g', '.gantt_weekend-group')
        .attr('class', 'gantt-row-colorizer');
    }
  }

  public update(): void {
    this._build();
  }

  public updatePlugInHeight(): void {
    this._build();
  }

  /**
   * Remove of plugin. Part of plugin lifecycle.
   */
  public removePlugIn(): void {
    // callback unsubscribe
    this._onDestroySubject.next();
    this._onDestroySubject.complete();

    // remove groups
    this._removeAllColorizedRows();

    this._mouseOverSubscription.unsubscribe();
  }

  private _subscribeCallbacks(): void {
    this.ganttDiagram
      .getXAxisBuilder()
      .onZooming.pipe(takeUntil(this.onDestroy))
      .subscribe(() => this._zoom());
    this.ganttDiagram
      .getVerticalScrollHandler()
      .onScrollVerticalUpdate.pipe(takeUntil(this.onDestroy))
      .subscribe(() => this.update());
  }

  private tooltipHandling(): void {
    this._mouseOverSubscription = this.ganttDiagram
      .getShiftFacade()
      .getShiftBuilder()
      .canvasMouseOver()
      .pipe(sampleTime(300))
      .subscribe((e: MouseEvent) => {
        const hoveredElem = this.getHoveredElem(e);
        if (hoveredElem?.tooltip && hoveredElem.rowId !== this._currentHoveredRowId) {
          this._currentHoveredRowId = hoveredElem.rowId;
          this.ganttDiagram.getShiftFacade().getTooltipBuilder().addTooltipToHTMLBody(e.x, e.y, hoveredElem.tooltip);
        } else if (this._currentHoveredRowId && !hoveredElem) {
          this.ganttDiagram.getShiftFacade().getTooltipBuilder().removeAllTooltips();
          this._currentHoveredRowId = null;
        }
      });
  }

  private getHoveredElem(event: MouseEvent): RowColorizeRenderData | undefined {
    return this._currentRenderDataSet.find((elem) => this.isMouseOverElem(elem, event));
  }

  private isMouseOverElem(elem: RowColorizeRenderData, event: MouseEvent): boolean {
    const x = event.offsetX;
    const y = event.offsetY;
    const elemX =
      elem.x * this.ganttDiagram.getXAxisBuilder().getLastZoomEvent().k +
      this.ganttDiagram.getXAxisBuilder().getLastZoomEvent().x;
    const elemWidth = elem.width * this.ganttDiagram.getXAxisBuilder().getLastZoomEvent().k;
    return x >= elemX && x <= elemX + elemWidth && y >= elem.y && y <= elem.y + elem.height;
  }

  public addTimeSpanToColorize(rowId: string, color?: string, from?: number, to?: number, tooltip?: string): void {
    this._colorizedRows.push({ rowId, color, from, to, tooltip });
  }

  private _build(): void {
    const scrollContainerIds = [EGanttScrollContainer.DEFAULT];
    if (this.ganttDiagram.getConfig().showStickyRows()) {
      scrollContainerIds.push(EGanttScrollContainer.STICKY_ROWS);
    }

    for (const scrollContainerId of scrollContainerIds) {
      this._currentRenderDataSet = this._getRenderDataSet(scrollContainerId);

      const selection = this._parentNode[scrollContainerId]
        .selectAll<SVGRectElement, RowColorizeRenderData>('rect')
        .data(this._currentRenderDataSet);

      // update
      selection
        .attr('width', (d) => d.width)
        .attr('height', (d) => d.height)
        .attr('x', (d) => d.x)
        .attr('y', (d) => d.y)
        .style('fill', (d) => d.color)
        .style('opacity', (d) => d.opacity);

      // enter
      selection
        .enter()
        .append('rect')
        .attr('width', (d) => d.width)
        .attr('height', (d) => d.height)
        .attr('x', (d) => d.x)
        .attr('y', (d) => d.y)
        .style('fill', (d) => d.color)
        .style('opacity', (d) => d.opacity);

      // exit
      selection.exit().remove();
    }
  }

  private _getRenderDataSet(scrollContainerId: EGanttScrollContainer): RowColorizeRenderData[] {
    const nodeProportionsState = new NodeProportionsStateConnector(
      this.ganttDiagram.getNodeProportionsState(),
      scrollContainerId
    );

    const renderDataSet: RowColorizeRenderData[] = [];
    const yAxisDataSet = GanttUtilities.filterDataSetByViewPort(
      this.ganttDiagram.getHTMLStructureBuilder().getShiftContainer(scrollContainerId).node(),
      this.ganttDiagram.getRenderDataHandler().getRenderDataYAxis(scrollContainerId),
      this.ganttDiagram.getRenderDataHandler(),
      0,
      nodeProportionsState.getShiftViewPortProportions().height,
      nodeProportionsState.getScrollTopPosition()
    );
    const scale = this.ganttDiagram.getXAxisBuilder().getGlobalScale();

    yAxisDataSet.forEach((row) => {
      const result = this._colorizedRows.filter(
        (rowData: any) => rowData.rowId === row.id || (row.originalResource && row.originalResource === rowData.rowId)
      );
      if (result.length) {
        result.forEach((entry) => {
          const from = entry.from ? entry.from : this.ganttDiagram.getDataHandler().getOriginDataset().minValue;
          const to = entry.to ? entry.to : this.ganttDiagram.getDataHandler().getOriginDataset().maxValue;
          const dateFrom = new Date(from);
          const dateTo = new Date(to);
          const color = entry.color;
          if (
            dateFrom instanceof Date &&
            !isNaN(dateFrom.getTime()) &&
            dateTo instanceof Date &&
            !isNaN(dateTo.getTime())
          ) {
            renderDataSet.push({
              rowId: entry.rowId,
              x: scale(dateFrom),
              y: this.ganttDiagram.getRenderDataHandler().getStateStorage().getYPositionRow(row.id),
              height: row.height,
              width: scale(dateTo) - scale(dateFrom),
              color: color || this._defaultColor,
              opacity: 0.5,
              tooltip: entry.tooltip,
            });
          } else {
            // console.warn("Invalid date to colorize row!");
          }
        });
      }
    });
    return this.filterHorizontalViewport(renderDataSet);
  }

  public clearAll(): void {
    this._colorizedRows = [];
    this._removeAllColorizedRows();
  }

  private filterHorizontalViewport(renderDataSet: RowColorizeRenderData[]): RowColorizeRenderData[] {
    const currentScale = this.ganttDiagram.getXAxisBuilder().getCurrentScale();
    const globalScale = this.ganttDiagram.getXAxisBuilder().getGlobalScale();
    const rangeInPx = currentScale.domain().map((d) => globalScale(d));

    return renderDataSet.filter((d) => !(d.x + d.width < rangeInPx[0] || d.x > rangeInPx[1]));
  }

  /**
   * Callback function to update width of gantt overlays by zoom.
   */
  private _zoom(): void {
    const zoom = this.ganttDiagram.getXAxisBuilder().getLastZoomEvent();
    for (const scrollContainerId in this._parentNode) {
      this._parentNode[scrollContainerId].attr('transform', 'translate(' + zoom.x + ',0) scale(' + zoom.k + ',1)');
    }
    this.update();
  }

  /**
   * Callback that removes all row marking.
   */
  private _removeAllColorizedRows() {
    for (const scrollContainerId in this._parentNode) {
      this._parentNode[scrollContainerId].selectAll('.colorized-row').remove();
    }
  }

  //
  // OBSERVABLES
  //

  /**
   * Observable which gets triggered when the instance gets destroyed.
   */
  private get onDestroy(): Observable<void> {
    return this._onDestroySubject.asObservable();
  }
}

interface GanttRowColorizeEntry {
  rowId: string;
  color: string;
  from: number;
  to: number;
  tooltip?: string;
}

interface RowColorizeRenderData {
  rowId: string;
  x: number;
  y: number;
  width: number;
  height: number;
  color: string;
  opacity: number;
  tooltip?: string;
}
