import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SlotModule } from '@app-modeleditor/components/template/slot/slot.module';
import { WizardPageComponent } from './wizard-page.component';

@NgModule({
  imports: [CommonModule, SlotModule],
  exports: [WizardPageComponent],
  declarations: [WizardPageComponent],
})
export class WizardPageModule {}
