import { ETemplateType } from 'frontend/src/dashboard/model/resource/template-type';
import { WizardContent } from '@app-modeleditor/components/lightbox/predefined/wizard/wizard-content';
import { Lightbox } from '../../lightbox';

export class Wizard extends Lightbox {
  private displayOrderDefault: number[];
  private disableTopNavigation: boolean;

  public isDisableTopNavigation(): boolean {
    return typeof this.disableTopNavigation === 'boolean' ? this.disableTopNavigation : false;
  }

  public setDisableTopNavigation(disableTopNavigation: boolean): this {
    this.disableTopNavigation = disableTopNavigation;
    return this;
  }

  public getContent(): WizardContent {
    return super.getContent() as WizardContent;
  }

  public setContent(content: WizardContent): this {
    super.setContent(content);
    this.setResourceId(this.getResourceId());
    return this;
  }

  public setResourceId(resourceId: string): this {
    super.setResourceId(resourceId);
    this.getContent()?.setResourceId(resourceId);
    return this;
  }

  public getDisplayOrderDefault(): number[] {
    return this.displayOrderDefault;
  }

  public setDisplayOrderDefault(displayOrderDefault: number[]): this {
    this.displayOrderDefault = displayOrderDefault;
    return this;
  }

  constructor() {
    super();
    this.setType(ETemplateType.WIZARD)
      .setDisableSaveButton(true)
      .setDisableCancelButton(true)
      .setBackdropClose(true)
      .setCanOpenExternalWindow(false);
  }
}
