import { Injectable, Type } from '@angular/core';
import { Adapter } from '../../../core/adapter';
import { ContentElementAdapter } from '../structure/content-element-adapter.service';
import { TemplateAdapter } from './../../utils/template-factory.service';
import { EventRange } from './event-range';
import { RangePicker } from './range-picker';

@Injectable({
  providedIn: 'root',
})
export class RangePickerAdapter implements Adapter<RangePicker> {
  constructor(private contentElementAdapter: ContentElementAdapter) {}
  adapt(item: any): RangePicker {
    throw new Error('Method not implemented.');
  }
  inheritFrom?<T extends RangePicker>(scope: TemplateAdapter, type: Type<T>, item: any): T {
    const t: T = this.contentElementAdapter
      .inheritFrom<T>(scope, type, item)
      .setRestriction(item.restrictedRangePickerType)
      .setStartRangeDate(new Date(item.startRangeDate))
      .setEndRangeDate(new Date(item.endRangeDate));

    return t;
  }

  applyValues<T extends RangePicker>(scope: TemplateAdapter, item: T, values: any): T {
    if (!values) {
      return null;
    }

    const range = new EventRange()
      .setStart(values.startDate ? new Date(values.startDate) : null)
      .setEnd(values.endDate ? new Date(values.endDate) : null);
    item.setValue(range);
    return item;
  }

  inherit<T extends RangePicker>(type: Type<T>, data: any): T {
    const t: T = this.contentElementAdapter
      .inherit<T>(type, data)
      .setRestriction(data.restrictedRangePickerType)
      .setStartRangeDate(new Date(data.startRangeDate))
      .setEndRangeDate(new Date(data.endRangeDate));

    return t;
  }
}
