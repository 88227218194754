import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { ContentComponent } from '@app-modeleditor/components/content/content.component';
import { TranslateModule } from '@ngx-translate/core';
import { LayoutModule as OldLayoutModule } from '../layout/layout.module';
import { LayoutModule } from '../structure/layout.module';
import { SlotModule } from '../template/slot/slot.module';
import { FileUploaderModule } from './../file-uploader/file-uploader.module';
import { ContentElementModule } from './content-element/content-element.module';
import { ContentPartModule } from './content-part/content-part.module';
@NgModule({
  imports: [
    CommonModule,
    MatCardModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatTabsModule,
    MatExpansionModule,
    MatGridListModule,
    DragDropModule,
    MatIconModule,
    OldLayoutModule,
    FileUploaderModule,
    LayoutModule,
    MatTooltipModule,
    MatProgressBarModule,
    ContentElementModule,
    ContentPartModule,
    SlotModule,
    TranslateModule,
  ],
  declarations: [ContentComponent],
  exports: [ContentComponent, ContentElementModule, ContentPartModule],
  providers: [],
})
export class ContentModule {}
