import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AutocompleteTextfield } from '@app-modeleditor/components/elements/autocomplete-textfield';
import { EntryElementValue } from '@app-modeleditor/components/entry-collection/entry-element-value';
import { EFieldType } from '@app-modeleditor/components/entry-collection/field-type.enum';
import { GanttTimePeriodExecuter, TimePeriodNoRenderReason } from '@gantt/public-api';
import { GlobalUtils } from 'frontend/src/dashboard/global-utils';
import { BehaviorSubject, debounceTime, distinctUntilChanged, filter, skip } from 'rxjs';
import { Gantt_General } from '../../general.gantt.component';
import { GanttEssentialPlugIns } from '../../plugin/e-gantt-essential-plugins';

type SearchResultMap = Map<GanttTimePeriodExecuter, Map<string, Set<string>>>;

/**
 * Represents an autocomplete textfield for searching interval attributes.
 * @remarks
 * This class extends the AutocompleteTextfield class and provides search functionality for interval attributes.
 * @example
 * const intervalAttributeSearchElement = new IntervalAttributeSearchElement(scope);
 * intervalAttributeSearchElement.get(data);
 */
export class IntervalAttributeSearchElement extends AutocompleteTextfield {
  private search$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private availableValues: string[] = [];
  private readonly indicatorIdentifier: string = GlobalUtils.generateUUID();
  private isSearchActive = false;

  constructor(private scope: Gantt_General) {
    super();
    this.initSearch();
    this.listenForChanges();
  }

  get(data: any): this {
    this.availableValues = data?.availableValues?.length ? data.availableValues : [];
    this.setName(this.getLabel(data))
      .setId(data.id)
      .setAlwaysEnabled(true)
      .setFieldType(EFieldType.AUTOCOMPLETE_TEXT_FIELD)
      .onChanges((val: EntryElementValue) => {
        this.search$.next(val.getValue() ?? '');
      })
      .setFreeSelection(true)
      .setInvalidOnNoOptions(true)
      .setValue(new EntryElementValue().setValue(null))
      .setOptions(this.getFilterList(this.getSearchResultMap('')))
      .setWidth(200);

    // register for changes
    this.onGetOptions((query: string) => {
      this.search$.next(query ?? '');
    });

    return this;
  }

  /**
   * Initializes the search functionality for the interval attribute search element.
   * @remarks
   * This method subscribes to the `search$` observable and calls the `handleSearch` method
   * when the search term changes.
   * @returns void
   */
  private initSearch() {
    this.search$
      .pipe(takeUntilDestroyed(this.scope.destroyRef), skip(1), debounceTime(100), distinctUntilChanged())
      .subscribe(this.handleSearch.bind(this));
  }

  /**
   * Listens for changes in the blocking interval plugin and handles search based on the current search value.
   * @returns {void}
   */
  private listenForChanges() {
    const blockingIntervalPlugIn = this.scope.ganttPluginHandlerService.getEssentialPlugIn(
      GanttEssentialPlugIns.BlockingIntervalPlugIn
    );

    blockingIntervalPlugIn
      ?.onBlockingIntervalChange()
      .pipe(
        takeUntilDestroyed(this.scope.destroyRef),
        filter(() => this.isSearchActive)
      )
      .subscribe(() => {
        this.handleSearch(this.search$.value);
      });
  }

  /**
   * Returns a list of EntryElementValue objects filtered by the search term.
   * @param searchResultMap - The search result map to filter.
   * @returns A list of EntryElementValue objects filtered by the search term.
   */
  private getFilterList(searchResultMap: SearchResultMap): EntryElementValue[] {
    const attributeList: EntryElementValue[] = [];
    this.getAllAttributesByQuery(searchResultMap).forEach((key) => {
      if (!key.toLowerCase().includes(this.search$.value.toLowerCase())) {
        return;
      }
      attributeList.push(new EntryElementValue().setValue(key).setId(key).setColor('#ff8d00').setName(key));
    });

    attributeList.sort((a, b) =>
      a.getName().toLowerCase() < b.getName().toLowerCase()
        ? -1
        : a.getName().toLowerCase() > b.getName().toLowerCase()
        ? 1
        : 0
    );

    return attributeList;
  }

  /**
   * Returns a set of all attributes found in the given search result map.
   * @param searchResultMap - The search result map to extract attributes from.
   * @returns A set of all attributes found in the search result map.
   */
  private getAllAttributesByQuery(searchResultMap: SearchResultMap): Set<string> {
    const allAttributes: Set<string> = new Set<string>();

    searchResultMap.forEach((periodMap) => {
      periodMap.forEach((attributeSet) => {
        attributeSet.forEach((attribute) => {
          if (!allAttributes.has(attribute)) {
            allAttributes.add(attribute);
          }
        });
      });
    });

    return allAttributes;
  }

  /**
   * Handles the search functionality for the interval attribute search element.
   * @param inputValue - The search input value.
   * @returns void
   */
  private handleSearch(inputValue: string): void {
    const searchResultMap = this.getSearchResultMap(inputValue);
    const blockingIntervalPlugIn = this.scope.ganttPluginHandlerService.getEssentialPlugIn(
      GanttEssentialPlugIns.BlockingIntervalPlugIn
    );

    // handle indicator visibility
    if (!!inputValue !== this.isSearchActive) {
      this.isSearchActive = !!inputValue;
      this.scope.toolbar.updateMenuIndicator(this.isSearchActive, this.getId(), this.indicatorIdentifier);
    }

    // update search result list
    this.setOptions(this.getFilterList(searchResultMap));

    // iterate over all executers
    this.getRelevantExecuters().forEach((executer) => {
      // iterate over all periods of the current executer
      executer.getAllMarkedPeriods().forEach((period) => {
        if (!inputValue || searchResultMap.get(executer)?.has(period.id)) {
          // search result map contains period -> show period
          executer.getMarkedPeriodBuilder().removeFromHiddenAreas(period.id, TimePeriodNoRenderReason.ATTRIBUTE_SEARCH);
        } else {
          // search result map does not contain period -> hide period
          executer.getMarkedPeriodBuilder().addToHiddenAreas(period.id, TimePeriodNoRenderReason.ATTRIBUTE_SEARCH);
        }
      });
    });

    // trigger redraw
    blockingIntervalPlugIn?.groupExecuter?.update();
  }

  /**
   * Returns a map of GanttTimePeriodExecuter to a map of period IDs to a set of attribute values that match the search query.
   * @param searchQuery - The search query to match against attribute values.
   * @returns A map of GanttTimePeriodExecuter to a map of period IDs to a set of attribute values that match the search query.
   */
  private getSearchResultMap(searchQuery: string): SearchResultMap {
    const resultMap: SearchResultMap = new Map();
    const executers = this.getRelevantExecuters();
    const allAttributes: Set<string> = new Set<string>();

    /**
     * Adds a value to the set of values associated with a given period ID and executer.
     * If the executer or period ID do not exist in the map, they will be added.
     * @param executer - The GanttTimePeriodExecuter associated with the value.
     * @param periodId - The ID of the period associated with the value.
     * @param value - The value to add to the set.
     */
    const add = (executer: GanttTimePeriodExecuter, periodId: string, value: string) => {
      if (!resultMap.has(executer)) {
        resultMap.set(executer, new Map<string, Set<string>>());
      }
      const periodMap = resultMap.get(executer);
      if (!periodMap.has(periodId)) {
        periodMap.set(periodId, new Set<string>());
      }
      periodMap.get(periodId).add(value);
    };

    for (const executer of executers) {
      executer.getAllMarkedPeriods().forEach((period) => {
        const attributeMap = period.additionalDetails;
        for (const key in attributeMap) {
          const attributeValue = attributeMap[key].t2;
          if (isNaN(+attributeValue) && !allAttributes.has(attributeValue as string)) {
            if (Array.isArray(attributeValue)) {
              // is array of strings
              attributeValue.forEach((value) => {
                if (value.toLowerCase().includes(searchQuery.toLowerCase())) {
                  add(executer, period.id, value);
                }
              });
            } else if ((attributeValue as string).toLowerCase().includes(searchQuery.toLowerCase())) {
              // is string
              add(executer, period.id, attributeValue as string);
            }
          }
        }
      });
    }

    return resultMap;
  }

  /**
   * Returns an array of relevant executers based on the available values.
   * If available values are provided, returns all executers that belong to the specified group IDs.
   * Otherwise, returns all executers.
   * @returns An array of relevant executers.
   */
  private getRelevantExecuters() {
    const blockingIntervalPlugIn = this.scope.ganttPluginHandlerService.getEssentialPlugIn(
      GanttEssentialPlugIns.BlockingIntervalPlugIn
    );

    return this.availableValues.length
      ? blockingIntervalPlugIn.getAllExecutersByGroupIds(this.availableValues)
      : blockingIntervalPlugIn.getAllExecuters();
  }

  /**
   * Returns the label for the given data object.
   * If the data object has a `name` property, it will be used as the label.
   * Otherwise, the default label will be used, which is obtained from the translation service.
   * @param data - The data object for which to get the label.
   * @returns The label for the given data object.
   */
  private getLabel(data: any): string {
    return this.scope.translate.instant('GANTT.attribute-search-intervals', {
      name: data.name || this.scope.translate.instant('@intervals@'),
    });
  }
}
