import { Injectable, OnDestroy } from '@angular/core';
import { GanttSettingsService } from 'frontend/src/dashboard/gantt/general/gantt-settings/service/gantt-settings.service';
import { ILegendData, ILegendDataEvent, ILegendEntry } from 'frontend/src/dashboard/legend/legend.interface';
import { EColorizeStrategyOption } from 'frontend/src/dashboard/saxms-best-gantt/legend/colorize-startegy-option';
import { EFilteredOutDisplayOption } from 'frontend/src/dashboard/saxms-best-gantt/legend/filtered-out-display-option.enum';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { ELegendDataSortingOrder } from './legend-data-sorting.enum';

/**
 * Handles communication between gantt legend and other components.
 */
@Injectable()
export class LegendCommunicationService implements OnDestroy {
  private _alive = true;
  private _isDefaultLegendDataUsed = true;
  private _onUpdateUISubject: BehaviorSubject<boolean> = new BehaviorSubject(null);
  private _onNewLegendDataSubject: Subject<ILegendDataEvent> = new Subject();
  private _onColorizeStrategyChangeSubject: BehaviorSubject<EColorizeStrategyOption> = new BehaviorSubject(null);
  private _onChangeFilterSubject: BehaviorSubject<boolean> = new BehaviorSubject(void 0);
  private _onShowOnlyViewportEntriesChangeSubject: BehaviorSubject<boolean> = new BehaviorSubject(null);
  private _onResetFilterSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _legendData: ILegendData = {
    legendEntries: [],
    originalDataType: null,
  };
  private _legendDataDefaultOrder: string[] = [];
  private _defaultLegendData: ILegendData = {
    legendEntries: [],
    originalDataType: null,
  };
  private _shiftNoRenderId = 'shift_ganttLegendNoRenderID';

  // toolbar states
  private _toolbarStates = new LegendToolbarStates();

  constructor(private _ganttSettingsService: GanttSettingsService) {
    this._init();
  }

  /**
   * Initializes the legend data with the provided initial data.
   * @param initialLegendData The initial legend data to set.
   */
  public initData(initialLegendData: ILegendEntry[]) {
    this.setDefaultLegendData(initialLegendData);
    this.setLegendData(null, true, true);
  }

  /**
   * Initializes the service.
   */
  private _init(): void {
    const initialSortingOrder = this._ganttSettingsService.getGanttSettings().legendEntrySortingOrder
      ? this._ganttSettingsService.getGanttSettings().legendEntrySortingOrder
      : ELegendDataSortingOrder.DEFAULT;
    this.entrySortingOrder = initialSortingOrder;

    this._ganttSettingsService
      .onNewSettings()
      .pipe(takeWhile((_) => this._alive))
      .subscribe((newSettings) => {
        if (newSettings?.legendEntrySortingOrder) {
          this.entrySortingOrder = newSettings.legendEntrySortingOrder;
        }
      });
  }

  /**
   * Destroys the service.
   */
  public ngOnDestroy(): void {
    this._alive = false;
  }

  /**
   * Trigger update UI of legend.
   */
  public updateLegendUI(): void {
    this._onUpdateUISubject.next(true);
  }

  /**
   * Notifies if a legend update is triggered in legend communication service.
   */
  public listenToUIUpdates(): Observable<boolean> {
    return this._onUpdateUISubject.asObservable();
  }

  /**
   * Notifies if new legend data was set in legend communication service.
   */
  public listenToNewLegendData(): Observable<ILegendDataEvent> {
    return this._onNewLegendDataSubject.asObservable();
  }

  /**
   * Notifies if filter reset is triggered.
   */
  public listenToFilterReset(): Observable<boolean> {
    return this._onResetFilterSubject.asObservable();
  }

  /**
   * Triggers an update of colorize strategy.
   * @param strategy new colorize strategy to be set
   */
  public updateColorizeStrategy() {
    this._onColorizeStrategyChangeSubject.next(this.colorizeStrategyOption);
  }

  /**
   * Option for Gantt chart legend to see only entries of view port.
   */
  public setShowOnlyViewPortEntries(isActive: boolean) {
    this.showOnlyViewportEntries = isActive;
    this._onShowOnlyViewportEntriesChangeSubject.next(isActive);
  }

  public listenToShowOnlyViewPortEntries(): Observable<boolean> {
    return this._onShowOnlyViewportEntriesChangeSubject.asObservable();
  }

  /**
   * Triggers an reset of filter.
   */
  public resetFilter() {
    this._onResetFilterSubject.next(true);
  }

  /**
   * Notifies if the colorize strategy has changed.
   */
  public listenToColorizeStrategyChange(): Observable<EColorizeStrategyOption> {
    return this._onColorizeStrategyChangeSubject.asObservable();
  }

  /**
   * Returns legend data.
   */
  public getLegendData(): ILegendData {
    return this._legendData;
  }

  public getShiftNoRenderId(): string {
    return this._shiftNoRenderId;
  }

  /**
   * Returns the filter options whether filtered out elements are hidden or weakened.
   */
  public getFilteredOutDisplayOption(): EFilteredOutDisplayOption {
    return this.hideFilteredOutElements ? EFilteredOutDisplayOption.HIDE : EFilteredOutDisplayOption.WEAKENED;
  }

  public triggerOnChangeFilter(splitShifts = true): void {
    this._onChangeFilterSubject.next(splitShifts);
  }

  public listenForFilterChange(): Observable<boolean> {
    return this._onChangeFilterSubject.asObservable();
  }

  /**
   * Sets legend data to visualize legend inside SaxMsBestGantt component.
   * @param externalLegendData Legend dataset to on witch legend is based.
   * @param useDefaultLegendData
   */
  public setLegendData(externalLegendData: ILegendData, useDefaultLegendData = true, isInit = false): void {
    if (useDefaultLegendData && !externalLegendData) {
      // use copy of default legend data to maintain default entry order
      this._legendData = {
        legendEntries: this._defaultLegendData.legendEntries.slice(),
        originalDataType: this._defaultLegendData.originalDataType,
      };
      this._isDefaultLegendDataUsed = true;
    } else {
      this._legendData = this.updateLegendData(externalLegendData, this._legendData);
      this._legendData.legendEntries = this._legendData.legendEntries.sort((a, b) =>
        a.label > b.label ? 1 : b.label > a.label ? -1 : 0
      ); // sort before adding
      this._isDefaultLegendDataUsed = false;
    }

    this._setCurrentOrderAsDefault();
    this._sortLegendEntries();
    this._onNewLegendDataSubject.next({
      legendEntries: this._legendData.legendEntries,
      hasUpdatedData: true,
      isInit,
    });
  }

  private updateLegendData(newLegendData: ILegendData, oldLegendData: ILegendData): ILegendData {
    return {
      ...newLegendData,
      legendEntries: newLegendData.legendEntries.map((newLegendEntry) => {
        const oldLegendEntry = oldLegendData.legendEntries.find((entry) => entry.id === newLegendEntry.id);
        if (!oldLegendEntry) {
          return newLegendEntry;
        }

        return {
          ...newLegendEntry,
          isActive: oldLegendEntry.isActive,
          noRender: oldLegendEntry.noRender,
        };
      }),
    };
  }

  /**
   * Adds default Legend data which will be used if no specific legend data is given.
   * @param defaultLegendEntries Default legend entries.
   * @param originalDataType Type of data the entries represent.
   */
  public setDefaultLegendData(defaultLegendEntries: ILegendEntry[], originalDataType: string = null): void {
    this._defaultLegendData = {
      legendEntries: defaultLegendEntries,
      originalDataType: originalDataType,
    };
  }

  /**
   * Sort the legend entries in current order.
   */
  private _sortLegendEntries(): void {
    // restore default order
    if (this._toolbarStates.entrySortingOrder === ELegendDataSortingOrder.DEFAULT) {
      this._restoreDefaultOrder();
      return;
    }
    // sort ASC/DESC
    const orderFactor = this._toolbarStates.entrySortingOrder === ELegendDataSortingOrder.DESC ? -1 : 1;
    switch (this._legendData.originalDataType) {
      case 'DATE':
      case 'DATE_TIME': // sort by originalValue (contains date)
        this._legendData.legendEntries.sort((a, b) =>
          a.originalValue > b.originalValue ? 1 * orderFactor : b.originalValue > a.originalValue ? -1 * orderFactor : 0
        );
        break;
      default: // sort alphabetically by label
        this._legendData.legendEntries.sort((a, b) => a.label.localeCompare(b.label) * orderFactor);
        break;
    }
  }

  /**
   * Saves the current legend entry order as default order.
   */
  private _setCurrentOrderAsDefault(): void {
    this._legendDataDefaultOrder = [];
    for (const legendEntry of this._legendData.legendEntries) {
      this._legendDataDefaultOrder.push(legendEntry.id);
    }
  }

  /**
   * Restores the default legend entry order.
   */
  private _restoreDefaultOrder(): void {
    const allLegendEntries = this._legendData.legendEntries;
    this._legendData.legendEntries = [];

    // restore default order
    for (const legendEntryId of this._legendDataDefaultOrder) {
      const foundEntry = allLegendEntries.find((legendEntry) => legendEntry.id === legendEntryId);
      if (!foundEntry) continue;

      this._legendData.legendEntries.push(foundEntry);

      const foundEntryIndex = allLegendEntries.indexOf(foundEntry);
      allLegendEntries.splice(foundEntryIndex, 1);
    }
    // append remaining legend entries (in case some are left)
    for (const remainingLegendEntry of allLegendEntries) {
      this._legendData.legendEntries.push(remainingLegendEntry);
    }
  }

  //
  // GETTER & SETTER (Toolbar States)
  //
  public get isVisibilityChecked(): boolean {
    return this._toolbarStates.isVisibilityChecked;
  }
  public set isVisibilityChecked(value: boolean) {
    this._toolbarStates.isVisibilityChecked = value;
  }

  public get filterActive(): boolean {
    return this._toolbarStates.filterActive;
  }
  public set filterActive(value: boolean) {
    this._toolbarStates.filterActive = value;
  }

  public get searchInput(): string {
    return this._toolbarStates.searchInput;
  }
  public set searchInput(value: string) {
    this._toolbarStates.searchInput = value;
  }

  public get isVisibilityIndeterminateShow(): boolean {
    return this._toolbarStates.isVisibilityIndeterminateShow;
  }
  public set isVisibilityIndeterminateShow(value: boolean) {
    this._toolbarStates.isVisibilityIndeterminateShow = value;
  }

  public get hideSearchResults(): boolean {
    return this._toolbarStates.hideSearchResults;
  }
  public set hideSearchResults(value: boolean) {
    this._toolbarStates.hideSearchResults = value;
  }

  public get colorizeStrategyOption(): EColorizeStrategyOption {
    return this._toolbarStates.colorizeStrategyOption;
  }
  public set colorizeStrategyOption(value: EColorizeStrategyOption) {
    this._toolbarStates.colorizeStrategyOption = value;
  }

  public get hideFilteredOutElements(): boolean {
    return this._toolbarStates.hideFilteredOutElements;
  }
  public set hideFilteredOutElements(value: boolean) {
    this._toolbarStates.hideFilteredOutElements = value;
  }

  public get showOnlyViewportEntries(): boolean {
    return this._toolbarStates.showOnlyViewportEntries;
  }
  public set showOnlyViewportEntries(value: boolean) {
    this._toolbarStates.showOnlyViewportEntries = value;
  }

  public get ignoreEditedEntries(): boolean {
    return this._toolbarStates.ignoreEditedEntries;
  }
  public set ignoreEditedEntries(value: boolean) {
    this._toolbarStates.ignoreEditedEntries = value;
  }

  public get entrySortingOrder(): ELegendDataSortingOrder {
    return this._toolbarStates.entrySortingOrder;
  }
  public set entrySortingOrder(value: ELegendDataSortingOrder) {
    if (!value || value === this._toolbarStates.entrySortingOrder) return;
    this._toolbarStates.entrySortingOrder = value;

    this._sortLegendEntries();
    this._onNewLegendDataSubject.next({
      legendEntries: this._legendData.legendEntries,
      hasUpdatedData: false,
      isInit: false,
    });

    const currentGanttSettings = this._ganttSettingsService.getGanttSettings();
    if (
      !currentGanttSettings.legendEntrySortingOrder ||
      currentGanttSettings.legendEntrySortingOrder !== this._toolbarStates.entrySortingOrder
    ) {
      this._ganttSettingsService.changeSettings({ legendEntrySortingOrder: this._toolbarStates.entrySortingOrder });
      this._ganttSettingsService.saveSettings().subscribe();
    }
  }

  /**
   * Set of noRender ids which will be ignored by the legend filter service.
   * All ids in this set will be handled like they don't exist in any gantt shift.
   */
  public get ignoredNoRenderIds(): Set<string> {
    return this._toolbarStates.ignoredNoRenderIds;
  }

  /**
   * Returns all legend toolbar states.
   * @returns Legend toolbar states.
   */
  public getAllToolbarStates(): LegendToolbarStates {
    return this._toolbarStates;
  }

  /**
   * Applies new values to all toolbar states.
   * @param toolbarStates Toolbar states to use.
   */
  public setAllToolbarStates(toolbarStates: LegendToolbarStates): void {
    this._toolbarStates = toolbarStates;

    this.updateColorizeStrategy();
    this._onShowOnlyViewportEntriesChangeSubject.next(this._toolbarStates.showOnlyViewportEntries);
  }

  public get isDefaultLegendDataUsed(): boolean {
    return this._isDefaultLegendDataUsed;
  }
}

/**
 * Container class for all gantt legend toolbar states.
 */
export class LegendToolbarStates {
  public isVisibilityChecked: boolean;
  public filterActive: boolean;
  public searchInput: string;
  public isVisibilityIndeterminateShow: boolean;
  public hideSearchResults: boolean;
  public colorizeStrategyOption: EColorizeStrategyOption;
  public hideFilteredOutElements: boolean;
  public showOnlyViewportEntries: boolean;
  public ignoreEditedEntries: boolean;
  public entrySortingOrder: ELegendDataSortingOrder;
  public ignoredNoRenderIds: Set<string>;

  constructor() {
    this.isVisibilityChecked = false;
    this.filterActive = false;
    this.searchInput = '';
    this.isVisibilityIndeterminateShow = false;
    this.hideSearchResults = false;
    this.colorizeStrategyOption = EColorizeStrategyOption.DEFAULT;
    this.hideFilteredOutElements = false;
    this.showOnlyViewportEntries = false;
    this.ignoreEditedEntries = false;
    this.entrySortingOrder = ELegendDataSortingOrder.DEFAULT;
    this.ignoredNoRenderIds = new Set<string>();
  }
}
