import { Clipboard } from '@angular/cdk/clipboard';
import {
  AfterViewInit,
  ApplicationRef,
  ChangeDetectorRef,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Injector,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Action } from '@app-modeleditor/components/button/action/action';
import { EActionType } from '@app-modeleditor/components/button/action/action-type.enum';
import { EPredefinedAction } from '@app-modeleditor/components/button/action/predefined-action.enum';
import { ETemplateHandlingType } from '@app-modeleditor/components/button/action/template-handling-type.enum';
import { Button } from '@app-modeleditor/components/button/button';
import { ButtonService } from '@app-modeleditor/components/button/button.service';
import { TemplateActionService } from '@app-modeleditor/components/button/template-action.service';
import { EMenuMode } from '@app-modeleditor/components/content/content-element/menu-mode.enum';
import { ContentService } from '@app-modeleditor/components/content/content.service';
import { ContextmenuService } from '@app-modeleditor/components/contextmenu/contextmenu.service';
import { NumericInput } from '@app-modeleditor/components/elements/numeric-input/numeric-input';
import { EntryCollection } from '@app-modeleditor/components/entry-collection/entry-collection';
import { EntryElement } from '@app-modeleditor/components/entry-collection/entry-element';
import { EntryElementValue } from '@app-modeleditor/components/entry-collection/entry-element-value';
import { EFieldType } from '@app-modeleditor/components/entry-collection/field-type.enum';
import { ALink } from '@app-modeleditor/components/entry-collection/link/link';
import { MaxDurationWarningLink } from '@app-modeleditor/components/entry-collection/link/max-duration-warning-link';
import { ShiftTimeLink } from '@app-modeleditor/components/entry-collection/link/shift-time-link';
import { StartAndEndTimeLink } from '@app-modeleditor/components/entry-collection/link/start-and-end-time-link';
import { FilterElement } from '@app-modeleditor/components/filter-element/filter-element';
import { Lightbox } from '@app-modeleditor/components/lightbox/lightbox';
import { LightboxComponent } from '@app-modeleditor/components/lightbox/lightbox.component';
import { LightboxService } from '@app-modeleditor/components/lightbox/lightbox.service';
import { ConfirmLightbox } from '@app-modeleditor/components/lightbox/predefined/confirm-lightbox';
import { NamedLightbox } from '@app-modeleditor/components/lightbox/predefined/named-lightbox';
import { SelectionBox } from '@app-modeleditor/components/selection-box/selection-box';
import { MultiObjectSelector } from '@app-modeleditor/components/selector/multi-object-selector';
import { TemplateUiService } from '@app-modeleditor/components/template-ui/template.service';
import { TemplateResizeService } from '@app-modeleditor/components/template/template-resize.service';
import { TemplateComponent } from '@app-modeleditor/components/template/template.component';
import { TooltipService } from '@app-modeleditor/components/tooltip/tooltip.service';
import { TemplateTreeService } from '@app-modeleditor/components/tree/tree.service';
import { GridLayout } from '@app-modeleditor/components/widget-modules/grid-layout/grid-layout';
import { SelectionInformation } from '@app-modeleditor/components/widget-modules/grid-layout/selection-information';
import { WidgetGridLayoutService } from '@app-modeleditor/components/widget-modules/grid-layout/widget-grid-layout.service';
import { ERequestMethod } from '@app-modeleditor/request.service';
import { UiService } from '@app-modeleditor/ui.service';
import { TemplateAdapter } from '@app-modeleditor/utils/template-factory.service';
import { TemplateHotkeyService } from '@app-modeleditor/utils/template-hotkey.service';
import { TemplateService } from '@app-modeleditor/utils/template.service';
import { SaxMsSubmenuFavoriteTabService } from '@app-modules/saxms-submenu-elements/saxms-submenu-favorite-tab.service';
import { ConfigService } from '@core/config/config.service';
import { EMessageType, Message } from '@core/message/message';
import { MessageService } from '@core/message/message.service';
import { CloudMessagingService } from '@core/notification/cloud-messaging.service';
import { Notification } from '@core/notification/notification';
import { ENotificationType } from '@core/notification/notification-type.enum';
import { HotTableComponent, HotTableRegisterer } from '@handsontable/angular';
import { TranslateService } from '@ngx-translate/core';
import { GlobalUtils } from 'frontend/src/dashboard/global-utils';
import { ILegendEntry } from 'frontend/src/dashboard/legend/legend.interface';
import { EDatatype } from 'frontend/src/dashboard/model/entry/datatype.enum';
import { Hotkey } from 'frontend/src/dashboard/model/resource/hotkey';
import { EHotkeyType } from 'frontend/src/dashboard/model/resource/hotkey-type.enum';
import { EResizeMode } from 'frontend/src/dashboard/model/resource/template-resize-mode.enum';
import { ETemplateType } from 'frontend/src/dashboard/model/resource/template-type';
import { ContextMenuPopupService } from 'frontend/src/dashboard/popups-services/context-menu-popup/context-menu-popup.service';
import { EResizeType } from 'frontend/src/dashboard/view/resize/resize-type.enum';
import { ResizeService } from 'frontend/src/dashboard/view/resize/resize.service';
import { Tenant } from 'frontend/src/dashboard/view/template-footer/default-footer-components/tenant-footer/tenant';
import { UserTenantService } from 'frontend/src/dashboard/view/template-footer/default-footer-components/tenant-footer/tenant.service';
import { MenuItem } from 'frontend/src/dashboard/view/template-toolbar/menu-item';
import { EToolbarEvents } from 'frontend/src/dashboard/view/template-toolbar/toolbar-events.enum';
import { ToolbarGroup } from 'frontend/src/dashboard/view/template-toolbar/toolbar-group';
import { WindowService } from 'frontend/src/dashboard/view/window/window.service';
import Handsontable from 'handsontable';
import CellRange from 'handsontable/3rdparty/walkontable/src/cell/range';
import {
  AutoColumnSize,
  AutoRowSize,
  HiddenColumns,
  HiddenRows,
  MergeCells,
  MultiColumnSorting,
  NestedRows,
} from 'handsontable/plugins';
import { DetailedSettings as ColumnsDetailedSettings } from 'handsontable/plugins/hiddenColumns';
import { DetailedSettings as RowsDetailedSettings } from 'handsontable/plugins/hiddenRows';
import { DetailedSettings as HeadersDetailedSettings } from 'handsontable/plugins/nestedHeaders';
import { Observable, Observer, Subject, Subscription, forkJoin, of, throwError } from 'rxjs';
import {
  catchError,
  debounceTime,
  delay,
  filter,
  finalize,
  map,
  switchMap,
  take,
  takeUntil,
  takeWhile,
  tap,
  throttleTime,
} from 'rxjs/operators';
import { TableContextmenu } from '../contextmenu';
import { SaxmsColumnHeaderTemplateComponent } from '../core/saxms-column-header-template/saxms-column-header-template.component';
import { SaxmsColumnHeaderTemplateService } from '../core/saxms-column-header-template/saxms-column-header-template.service';
import { IRightClickEvent, SaxMsSpreadSheetColumn } from '../core/saxms-spreadsheet';
import { SaxMsSelectionModel } from '../core/saxmsSelectionModel/saxmsSelectionModel';
import { EStretch } from '../core/stretch.enum';
import { MessageBar } from '../message-bar/message-bar.interface';
import { Table } from '../model/table';
import { ESaxMsColumnUnits } from '../model/table-column-unity.enum';
import { ESpreadsheetDatatypes } from '../model/table-datatypes.enum';
import { ISaxMsEditorContext } from '../model/table-editor-context';
import { ETableEvents } from '../model/table-events.enum';
import { EFilterConditions } from '../model/table-filter-conditions.enum';
import { TableHeader } from '../model/table-header';
import { ESpreadsheetPlugin } from '../model/table-plugin.enum';
import { ISaxMsSpreadsheetRow } from '../model/table-row';
import { ISaxMsSpreadsheetRawRow, ISaxMsSpreadsheetRowEntry, SaxmsRowEntryElement } from '../model/table-row-entry';
import { ETableRowType } from '../model/table-row-type.enum';
import { IChangedTableRow, ITableRowUpdateResponse } from '../model/table-row-update-response.interface';
import { ESortDirection, ISpreadsheetSortObject, SortFilterObject } from '../model/table-sort-filter';
import { ITooltip } from '../model/tooltip.interface';
import {
  IExecuteSeetings,
  ISpreadsheetSaveSettings,
  QuickSearchFilter,
  SaxmsTableOptions,
  SpreadsheetColumnSettings,
  SpreadsheetSaveModel,
  SpreadsheetSaveOutput,
} from '../spreadsheet';
import { ISpreadsheetLightboxResult } from '../spreadsheet-lightbox-result.interface';
import { SpreadsheetSettingsService } from '../spreadsheet-settings.service';
import { SpreadsheetToolbar } from '../toolbar/spreadsheet-toolbar';
import { CellRendererUtil } from '../utils/cell-renderer-util';
import { ColumnUtil } from '../utils/column-util';
import { ScrollHandlerService } from '../utils/scroll-handler.service';
import { SpreadsheetFilterService } from '../utils/spreadsheet-filter.service';
import { SpreadsheetHelperUtil } from '../utils/spreadsheet-helper-util';
import { SpreadsheetHooksUtilService } from '../utils/spreadsheet-hooks-util.service';
import { SpreadsheetZoom } from '../zoom';
import { TableAdapter } from './../model/table-adapter.service';
import { RowDataUtil } from './../utils/row-data-util';
import { ISaxmsSubmenuOutput, SaxMsSubemenuFilterOutput } from './filter-output.interface';

const ROW_HEIGHT = 24;
const DEFAULT_MAX_HEIGHT = 500;
const SCROLLBAR_HEIGHT = 21;

@Component({
  selector: 'app-full-spreadsheet',
  templateUrl: './full-spreadsheet.component.html',
  styleUrls: ['./full-spreadsheet.component.scss'],
  providers: [
    SaxmsColumnHeaderTemplateService,
    SaxMsSubmenuFavoriteTabService,
    CellRendererUtil,
    ColumnUtil,
    RowDataUtil,
    SpreadsheetHelperUtil,
    SpreadsheetHooksUtilService,
    SpreadsheetFilterService,
    ScrollHandlerService,
  ],
})
export class FullSpreadsheetComponent implements OnInit, OnDestroy, AfterViewInit {
  @HostBinding('class.no-scrollbar-y') noScrollbarY = false;
  @HostBinding('class.no-scrollbar-x') noScrollbarX = false;
  @Input() contentId: string;
  @Input('template') set templateData(template: Table) {
    this.tableTemplate = template;
    if (!this.tableTemplate) {
      return;
    }
    this.spreadsheetHelperUtil.setTableTemplate(this.tableTemplate);
    this.addHotKeys();
    this.addEventListener();
    this.setLoading(true);
    this.setRefreshFinished(false);
    this.setLoadingAnimation(true);
    this.initSpreadsheet()
      .pipe(
        takeWhile(() => this.alive),
        tap(() => this.checkDimensions(true)),
        switchMap(() => this.tmpfetchData()),
        tap(() => this.buildToolbar()),
        switchMap(() => this.readSettings())
      )
      .subscribe(() => {
        this.setLoading(false);
        this.toolbar.updateMenuItemsIndications();
      });
  }

  @Input() set editmode(mode: boolean) {
    this.editableModeLocked = !mode;
    this.columnUtil.setEditableModeLocked(this.editableModeLocked);
    this.spreadsheetHooksUtilService.setAutoFillEnabled(!this.editableModeLocked);
    this.enableCellEdit();
    if (this.tableSettings) {
      this.tableSettings.manualColumnMove = !this.editableModeLocked;
      this.updateSettings(this.tableSettings);
    }
  }
  @Output() onChanges: EventEmitter<SpreadsheetSaveOutput> = new EventEmitter();
  @ViewChild('hiddenLink') hiddenLink: ElementRef;
  @ViewChild('spreadSheet') spreadSheet: HotTableComponent;
  @ViewChild('customCellEditor', { read: ViewContainerRef })
  set customCellEditor(c: ViewContainerRef) {
    if (c && c.element && !this.spreadsheetHelperUtil.getSpreadsheetCustomEditor()) {
      this.initEditor(c.element);
    }
  }

  @ViewChild('templateSpreadsheetContainer')
  set templateSpreadsheetContainerInstance(templateSpreadsheetContainer: ElementRef) {
    this.resizeApi.complete(this.resizeIds[0]);
    this.wrappingContainer = templateSpreadsheetContainer;
    if (!templateSpreadsheetContainer) {
      return;
    }

    this.resizeIds[0] = this.resizeApi.create(
      templateSpreadsheetContainer.nativeElement,
      this.triggerCheckDimensions.bind(this)
    );
  }

  @ViewChild('toolbarWrapper') set toolbarWrapperInstance(toolbarWrapper: ElementRef) {
    this.resizeApi.complete(this.resizeIds[1]);
    this.toolbarWrapper = toolbarWrapper;
    if (!toolbarWrapper) {
      return;
    }

    this.resizeIds[1] = this.resizeApi.create(toolbarWrapper.nativeElement, this.triggerCheckDimensions.bind(this), {
      types: [EResizeType.HEIGHT],
    });
  }
  @ViewChild('legendRef') set legendInstance(legendWrapper: ElementRef) {
    this.resizeApi.complete(this.resizeIds[2]);
    this.legendWrapper = legendWrapper;
    if (!legendWrapper) {
      return;
    }
    this.resizeIds[2] = this.resizeApi.create(legendWrapper.nativeElement, this.triggerCheckDimensions.bind(this), {
      types: [EResizeType.HEIGHT],
    });
  }

  /* Handle click of the click of the nestedRows button of the table */
  @HostListener('mouseup', ['$event']) onNestedRowButtonClick(event: Event): void {
    const target = event.target as HTMLElement;
    if (!target) {
      return;
    }
    if (
      target?.classList?.contains('ht_nestingButton') ||
      (target.lastChild as HTMLElement)?.classList?.contains('ht_nestingButton')
    ) {
      this.removeTooltip();
      const rowIndex = this.spreadsheetHelperUtil.getRowIndex(
        parseInt((event.target as HTMLElement).parentElement.getElementsByClassName('rowHeader')[0].innerHTML) - 1
      );
      this.rowCollapseMap.set(rowIndex, (this.nestedRowsPlugin as any).collapsingUI.areChildrenCollapsed(rowIndex));

      this.triggerHeightCalculation();
    }
  }
  @HostListener('document:click', ['$event']) onGlobalClick(event: Event): void {
    if (
      !event
        .composedPath()
        .find((path) => (path as HTMLElement)?.classList?.contains('template-spreadsheet-container')) &&
      !this.isBlockedCloseEventbyKeydown()
    ) {
      of(null)
        .pipe(delay(0))
        .subscribe(() => {
          this.spreadSheetInstance?.deselectCell(); // here
        });
    }
  }

  private alive = false;
  private editableModeLocked = false;
  private rowEditable = false;
  private blockedSaveSettings = false;
  private edited = false;
  private initSelection = false;
  private initFinished = false;
  private refreshFinished = false;
  private initLoadRows = false;
  private queueActive = false;
  private showLoadingAnimation = false;
  private createAObject = false;
  private initiallyScrolled = false;
  private loading = true;
  private initLoading = true;
  private showTooltip = true;
  private openOverInitKey = false;
  private blockedCloseEventbyKeydown = false;

  private offsetWidth: number;
  private gridLayoutSize: number;
  private scrollLeft: number;
  private _maxHeight = DEFAULT_MAX_HEIGHT;
  private minHeight = 35;
  protected _containerPaddingRight: number = null;

  private ngOverride: Subject<void> = new Subject<void>();
  private ngOverrideRowUpdate: Subject<void> = new Subject<void>();
  private cellMouseOverSub: Subject<void> = new Subject<void>();
  private clickTimeoutId: Subject<void> = new Subject<void>();
  private renderTimeoutId: Subject<void> = new Subject<void>();
  private finishedTimeout: Subject<void> = new Subject<void>();
  private triggerCheckDimensions$: Subject<void> = new Subject<void>();

  private toEditRow;
  private tableTemplate: Table;
  private title = '';
  private selectionModel: SaxMsSelectionModel = new SaxMsSelectionModel([], 0, []);
  private spreadsheetsettings: ISpreadsheetSaveSettings;
  private currentValueOfCustomEditor: any;
  private containerWidth: number;
  private resizeIds: string[] = [null, null];
  private toolbarWrapper: ElementRef;
  private wrappingContainer: ElementRef;
  private saveQueue: SpreadsheetSaveOutput[] = [];
  private gridLayout: GridLayout;
  private currentZoom: SpreadsheetZoom;
  private stretch: EStretch;
  private selectionInformation: SelectionInformation;
  private hiddenRowsMap: Map<number, string[]> = new Map();
  private changedRowsAfterAutoSave: ISaxMsSpreadsheetRawRow[] = [];
  private legendWrapper: ElementRef;

  private hotRegisterer: HotTableRegisterer;
  private spreadSheetInstance: Handsontable;
  private sortPlugin: MultiColumnSorting;
  private hiddenColumnsPlugin: HiddenColumns;
  private hiddenRowsPlugin: HiddenRows;
  private nestedRowsPlugin: NestedRows;
  private autoRowSizePlugin: AutoRowSize;
  private autoColumnSizePlugin: AutoColumnSize;
  private mergePlugin: MergeCells;
  public hiddenColumns: ColumnsDetailedSettings = {
    indicators: false,
    columns: [],
  };

  public hiddenRows: RowsDetailedSettings = {
    indicators: false,
    rows: [],
  };

  public get maxHeight(): number {
    return this._maxHeight;
  }

  public set maxHeight(value: number) {
    this._maxHeight = value;
  }

  private useTrimRows = true; // use trim rows for table row update notification -> trims collapsed rows on update if true

  private rowCollapseMap: Map<number, boolean> = new Map();
  public hiddenColumnFilterSet: Set<number> = new Set();
  private sortMap: Map<string, ISpreadsheetSortObject> = new Map();
  private unSavedChanges: Map<string, Map<string, SpreadsheetSaveModel>> = new Map();
  private toolbar: SpreadsheetToolbar;

  public initRowLoad = false;
  public noScrolling = false;

  public calculateTableHeight$ = new Subject<{ skipRerender: boolean; force: boolean }>();
  public tableHeight = 35;
  public afterInitCallback;
  public afterBeginEditingCallback;
  public tableSettings: Handsontable.GridSettings;
  public tableOptions: SaxmsTableOptions;
  public displayedColumns: SaxMsSpreadSheetColumn[] = [];
  public nestedColumnHeaders: HeadersDetailedSettings[][] = [];
  public rowDatasets: ISaxMsSpreadsheetRawRow[];
  public rows: { [key: string]: any }[] = [];
  public tableId: string = GlobalUtils.generateUUID();
  public cellEditorContext: ISaxMsEditorContext = {
    $implicit: '',
    column: null,
    visibile: false,
  };
  public editValueObject: SaxmsRowEntryElement = new SaxmsRowEntryElement();
  public colorClassSet: ILegendEntry[] = [];
  public curBrowserZoom = 1;
  private initialZoomStart: Date;
  private initialZoomEnd: Date;
  private isTooltipVisible = false;

  constructor(
    private spreadsheetHelperUtil: SpreadsheetHelperUtil,
    public columnUtil: ColumnUtil,
    private rowDataUtil: RowDataUtil,
    public cellRendererUtil: CellRendererUtil,
    private tableAdapter: TableAdapter,
    private spreadsheetSettingsService: SpreadsheetSettingsService,
    private templateAdapter: TemplateAdapter,
    private buttonService: ButtonService,
    private systemMessageService: MessageService,
    private configApi: ConfigService,
    private columnHeaderTemplateService: SaxmsColumnHeaderTemplateService,
    private resizeApi: ResizeService,
    private zone: NgZone,
    private cd: ChangeDetectorRef,
    private uiService: UiService,
    public templateApi: TemplateService,
    private templateUiApi: TemplateUiService,
    private widgetGridLayoutService: WidgetGridLayoutService,
    private lightboxApi: LightboxService,
    private templateResizeService: TemplateResizeService,
    private templateTreeService: TemplateTreeService,
    private templateHotkeyService: TemplateHotkeyService,
    private userTenantApi: UserTenantService,
    private windowService: WindowService,
    private contextMenuPopupService: ContextMenuPopupService,
    private contextmenuApi: ContextmenuService,
    private injector: Injector,
    private resolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    public translate: TranslateService,
    public spreadsheetHooksUtilService: SpreadsheetHooksUtilService,
    private fcm: CloudMessagingService,
    public contentService: ContentService,
    private tooltipService: TooltipService,
    private spreadSheetFilterService: SpreadsheetFilterService,
    private scrollHandlerService: ScrollHandlerService,
    public snackbar: MatSnackBar,
    public clipboard: Clipboard,
    private templateActionService: TemplateActionService
  ) {
    this.alive = true;
    this.listenToDimensionChanges().subscribe();
    this.spreadSheetFilterService.spreadSheetComponent = this;
    this.hotRegisterer = new HotTableRegisterer();
    this.afterInitCallback = this.afterInitTable.bind(this);
    this.afterBeginEditingCallback = this.spreadsheetHooksUtilService.afterBeginEditing.bind(
      this.spreadsheetHooksUtilService
    );
    this.spreadsheetHelperUtil.setScope(this).initColumnHeaderIconSet();
    this.spreadsheetHooksUtilService.setScope(this);
    this.rowDataUtil.setScope(this);
    this.spreadsheetsettings = {
      columnSettings: [],
      id: '',
      tableID: '',
    };

    this.rowDataUtil
      .afterDisplayedRowsChanged()
      .pipe(takeWhile(() => this.alive))
      .subscribe((items) => {
        this.rows = items;
        this.triggerHeightCalculation();
      });
  }

  private listenForRowUpdates() {
    this.fcm
      .getMessage()
      .pipe(takeWhile(() => this.alive))
      .subscribe((message: Notification) => {
        switch (message?.getType()) {
          case ENotificationType.UPDATE_TABLE_ROWS_NOTIFICATION:
            this.handleUpdateRowsNotification(message);
            break;
        }
      });
  }

  /**
   * Handles the notification for updating rows in the table.
   * @param {Notification} message The notification message.
   * @returns {void}
   */
  private handleUpdateRowsNotification(message: Notification): void {
    /**
     * There was an old solution where the table was identified by the id in refreshElementIds.
     * This mechanism has been replaced by the tableId property.
     */

    // check if table id is part of the refresh element ids of the notification
    const isInRefreshedElement =
      (!message.getRefreshElementIds()?.length && !message.getTableId()) || // if no refresh element ids are set, the notification is addressed to all tables
      !!message.getRefreshElementIds()?.find((id) => id === this.tableTemplate.getId()) ||
      message.getTableId() === this.tableTemplate.getId();

    const isAddressedToTable =
      !message.getTableResourceId() || // if no table resource id is set, the notification is addressed to all tables
      (this.tableTemplate.getResourceId() && message.getTableResourceId() === this.tableTemplate.getResourceId()) ||
      this.templateApi.getResourceIdsByTemplateId(this.tableTemplate.getId()).includes(message.getTableResourceId());

    if (isAddressedToTable && isInRefreshedElement) {
      this.addRow(message.getResourceId());
    }
  }

  /**
   * checks if row with given resource id exists
   * @param {string} resourceId resource id
   * @returns boolean
   */
  private doesRowExists(resourceId: string): boolean {
    return (this.tableTemplate.getValues() as any).tableRows.find((r) => r.resourceId === resourceId) ? true : false;
  }

  ngAfterViewInit(): void {
    this.initTableSettings();
  }

  ngOnDestroy(): void {
    this.alive = false;
    this.resizeApi.complete(...this.resizeIds);
    this.spreadsheetsettings = null;
    this.alive = false;
    this.columnHeaderTemplateService.setCurrentSortFilterObjectByColumn(null);
    this.hotRegisterer.removeInstance(this.tableId);
    this.toolbar = null;

    this.cellMouseOverSub.next();
    this.cellMouseOverSub.complete();

    this.ngOverride.next();
    this.ngOverride.complete();

    this.renderTimeoutId.next();
    this.renderTimeoutId.complete();

    this.finishedTimeout.next();
    this.finishedTimeout.complete();

    this.ngOverrideRowUpdate.next();
    this.ngOverrideRowUpdate.complete();
    if (this.tableTemplate) {
      this.tableTemplate.removeEventListener(this.tableId, ETableEvents.VALUE_CHANGED);
    }

    this.editValueObject = null;
    this.cellEditorContext = null;
    this.customCellEditor = null;
    this.tableTemplate = null;

    if (this.spreadSheetInstance && !this.spreadSheetInstance.isDestroyed) {
      this.spreadSheetInstance.destroy();
    }

    this.clickTimeoutId.next();
    this.clickTimeoutId.complete();

    Object.keys(this).forEach((key: string) => {
      delete this[key];
    });
  }

  ngOnInit(): void {
    this.cellRendererUtil.setHoveringEnabled(this.configApi.access().templates.Table.enableHover);
    this.listenForRowUpdates();

    this.scrollHandlerService
      .onVerticalScrollStart()
      .pipe(takeWhile(() => this.alive))
      .subscribe(() => {
        this.removeTooltip();
      });

    this.tableHeightCalculation()
      .pipe(takeWhile(() => this.alive))
      .subscribe();

    this.deselectCellOnOpenLightbox()
      .pipe(takeWhile(() => this.alive))
      .subscribe();
  }

  /**
   * update the state in the selectionbox for the managment of the hidden state of the columns
   */
  private changeDataOfSelectionColumn(): void {
    if (!this.toolbar) {
      return;
    }

    const mI = this.toolbar.getMenuItems().find((item) => item.getId() === 'header-visibility-manager');
    mI?.getMenu()
      .getContextMenuItems()
      .forEach((item) => {
        const found: boolean = this.hiddenColumnFilterSet.has(parseInt(item.getId()));
        item.setIcon(!found ? 'visibility' : 'visibility_off');
      });

    this.toolbar.updateElements();

    of(null)
      .pipe(delay(0))
      .subscribe(() => {
        this.toolbar
          ?.getMenuItems()
          ?.filter((m: MenuItem) => m.getId() === 'col-item')
          .find((m: MenuItem) => {
            m.getToolbarGroups()
              .filter((t: ToolbarGroup) => t.getId() === 'col-group')
              .find((t: ToolbarGroup) => {
                t.getEntryElements().find((e: EntryElement) => {
                  if (e instanceof SelectionBox) {
                    const value: EntryElementValue = e.getValue();
                    value.getValue<EntryElementValue[]>().forEach((v: EntryElementValue) => {
                      const found: boolean = this.hiddenColumnFilterSet.has(v.getIndex());
                      v.setChecked(!found);
                    });
                    e.setValue(value);
                  }
                });
              });
          });
      });
  }

  /**
   * set the default filter condition of a specific column for the quicksearch
   * @param column specific column
   * @param element quicksearch entryelement
   */
  private setDefaultFilterCondition(column: SaxMsSpreadSheetColumn, element: EntryElement): void {
    switch (this.spreadsheetHelperUtil.getDatatypeForQuicksearch(column)) {
      case EDatatype.LONG:
        element.setCondition(EFilterConditions.gte);
        break;
      case EDatatype.STRING: {
        const condition =
          element.getFieldType() === EFieldType.MULTI_OBJECT_SELECTOR
            ? EFilterConditions.eq
            : EFilterConditions.contains;
        element.setCondition(condition);
        break;
      }
      case EDatatype.FLOAT:
      case EDatatype.INTEGER:
        element.setCondition(EFilterConditions.gte);
        break;
      case EDatatype.BOOLEAN:
        element.setCondition(EFilterConditions.eq);
        break;
      default:
        element.setCondition(EFilterConditions.contains);
        break;
    }
  }

  /**
   * update the state of the quicksearchmenu
   */
  private changeDataOfQuickSearch(): void {
    of(null)
      .pipe(delay(0))
      .subscribe(() => {
        if (!this.spreadsheetsettings || !this.spreadsheetsettings.columnSettings) {
          return;
        }
        const filteredSettings = this.spreadsheetsettings.columnSettings.filter((setting) =>
          this.columnUtil.getColumns().find((column) => column.id === setting.columnID)
        );
        const customSettings = filteredSettings.map((s) => {
          const matchingColumn = (this.columnUtil.getColumns() || []).find((h) => s.columnID === h.id);
          return { columnId: matchingColumn.id, settings: s };
        });

        if (!this.toolbar) {
          return;
        }

        this.toolbar.getMenuItems().forEach((m: MenuItem) => {
          m.getToolbarGroups()
            .filter((g: ToolbarGroup) => g.getId() === 'quicksearch')
            .forEach((g: ToolbarGroup) => {
              g.getEntryElements().forEach((e: EntryElement, index: number) => {
                // this.spreadsheetsettings.columnSettings.find((s)=>s.columnID === )
                const column = this.columnUtil
                  .getColumns()
                  .find((column) => column.filterSortObject.getColumnIndex() === parseInt(e.getId().split('_').pop()));
                // const setting = customSettings.find((c) => e.getId() === `${this.tableTemplate.getId()}_quicksearch_${(c.settings.orderIdx && isNaN(c.settings.orderIdx)) ? c.settings.orderIdx : c.columnId}`);
                const setting = customSettings.find((c) => c.settings.columnID === column.id);
                const currentQuickSearchValue = this.getCurrentQuicksearchValue(e);
                const newQuickSearchValue = setting?.settings?.quickFilterVal;
                // if (!setting && e.getFieldType() === EFieldType.MULTI_OBJECT_SELECTOR) {

                //   newQuickSearchValue = e.getValue<EntryElementValue>().getAvailableValues();
                // }

                if (setting) {
                  if (e.getFieldType() === EFieldType.MULTI_OBJECT_SELECTOR) {
                    // deactivated (27.01.22)
                    // if (!Array.isArray(newQuickSearchValue)) { // no filter of multiple values is defined -> set all values (deactivate filter)
                    //   const availableValues: string[] = e.getValue<EntryElementValue>().getAvailableValues();
                    //   newQuickSearchValue = availableValues;
                    //   this.updateQuicksearchValue(column, newQuickSearchValue, e);
                    //   e.executeChanges();
                    //   return;
                    // }
                  }
                  if (currentQuickSearchValue == newQuickSearchValue) {
                    return;
                  }

                  if (Array.isArray(currentQuickSearchValue) && Array.isArray(newQuickSearchValue)) {
                    // if values are arrays -> check for same content
                    const currArray = (currentQuickSearchValue as EntryElementValue[])
                      .map((elem) => elem.getValue())
                      .slice()
                      .sort();
                    const isSame =
                      newQuickSearchValue.length === currArray.length &&
                      newQuickSearchValue
                        .slice()
                        .sort()
                        .every(function (value, index) {
                          return value === currArray[index];
                        });
                    if (isSame) {
                      return;
                    }
                  }

                  this.updateQuicksearchValue(column, newQuickSearchValue, e);

                  if (!newQuickSearchValue) {
                    this.setDefaultFilterCondition(column, e);
                  } else {
                    e.setCondition(setting.settings.quickFilterCond as EFilterConditions);
                  }

                  e.executeChanges();
                } else {
                  if (currentQuickSearchValue === null) {
                    return;
                  }

                  this.updateQuicksearchValue(column, null, e);
                  // e.setValue(e.getValue<EntryElementValue>().setValue(null));
                  e.executeChanges();
                }
              });
            });
        });

        // this.noScrollbarX = false;
        // this.noScrollbarY = false;
      });
  }

  /**
   * @param entryElement quicksearch entry element
   * @returns the current value of a quicksearch entry element
   */
  private getCurrentQuicksearchValue(entryElement: EntryElement) {
    if (entryElement.getValue<EntryElementValue>().getValue() instanceof EntryElementValue) {
      return entryElement.getValue<EntryElementValue>().getValue<EntryElementValue>().getName();
    } else {
      return entryElement.getValue<EntryElementValue>().getValue();
    }
  }

  /**
   * update the value of the entry elment of a quicksearch
   * @param column column of the quicksearch field
   * @param setting settings of the column
   * @param entryElement quicksearch entry element
   */
  private updateQuicksearchValue(column: SaxMsSpreadSheetColumn, value: any, entryElement: EntryElement) {
    if (this.configApi.access().templates?.Table?.simplifiedQuickSearch) {
      if (column.fieldType === EFieldType.DATE_PICKER || column.fieldType === EFieldType.DATE_TIME_PICKER) {
        entryElement.setValue(
          new EntryElementValue().setValue({
            day: value?.day || null,
            month: value?.month || null,
            year: value?.year || null,
            hour: value?.hour || null,
            minute: value?.minute || null,
          })
        );
        return;
      }

      if (column.fieldType === EFieldType.CHECK_BOX) {
        entryElement.setValue(entryElement.getValue<EntryElementValue>().setValue(value));
        return;
      }

      const stringValue = typeof value === 'string' ? value : undefined; // fallback for older values if simplified quicksearch is enabled first time
      entryElement.setValue(entryElement.getValue<EntryElementValue>().setValue(stringValue));
      return;
    }

    if (value === null || value === undefined) {
      entryElement.setValue(entryElement.getValue<EntryElementValue>().setValue(null));
      return;
    }

    if (column.fieldType === EFieldType.MULTI_OBJECT_SELECTOR || column.fieldType === EFieldType.OBJECT_SELECTOR) {
      entryElement.setValue(
        entryElement.getValue<EntryElementValue>().setValue(new EntryElementValue().setValue(value).setName(value))
      );
    } else if (column.fieldType === EFieldType.COMBO_BOX) {
      // multiple value filter
      let availableOptions;
      if (!Array.isArray(value) && entryElement.getFieldType() === EFieldType.MULTI_OBJECT_SELECTOR) {
        // ensure backward compatibility to further object selector
        availableOptions = (entryElement as MultiObjectSelector).getAvailableOptions();
      } else {
        availableOptions = value.map((option) =>
          new EntryElementValue().setValue(new EntryElementValue().setName(option).setValue(option))
        );
      }
      entryElement.getValue<EntryElementValue>().setValue(availableOptions.map((option) => option.getValue()));
      entryElement.setValue(entryElement.getValue<EntryElementValue>()); // hack to trigger change
    } else {
      entryElement.setValue(entryElement.getValue<EntryElementValue>().setValue(value));
    }
  }

  /**
   * update the state of the complex column filter elment inside the column-menu-item
   */
  private changeDataOfFilterSubmenuElement(): void {
    if (!this.selectionModel) {
      return;
    }
    const columnIndex = Number.parseInt(Array.from(this.selectionModel.selectionColumns.keys())[0]);
    const column = this.columnUtil.getColumnByIndex(columnIndex, true);
    if (columnIndex !== null && columnIndex !== undefined && !isNaN(columnIndex) && column) {
      // todo: update filter submenu element
      if (!this.toolbar) {
        return;
      }
      const colItem: MenuItem = this.toolbar.getMenuItems().find((m: MenuItem) => m.getId() === 'col-item');
      if (!colItem) {
        return;
      }
      colItem
        .getToolbarGroups()
        .find((t: ToolbarGroup) => t.getId() === 'filter-group')
        ?.getEntryElements()
        .find((e: FilterElement) => {
          if (e.getId() === `${this.tableTemplate.getId()}_table_column_filter`) {
            const values = this.spreadSheetFilterService.getColumnFilterDataByIndex(
              column.filterSortObject.getColumnIndex()
            );
            e.setFirstOperator(values.filterConditionOne)
              .setFirstValue(values.filterValueOne)
              .setSecondValue(values.filterValueTwo)
              .setSecondOperator(values.filterConditionTwo)
              .setLogicalOperator(values.logicOperator);

            e.setAvailableConditions(
              [EFilterConditions.none].concat(this.getSpreadsheetHelperUtil().getFilterConditions(column, e))
            );

            return true;
          }

          return false;
        });
    }
  }

  /**
   * add subscriptions to different services
   * --> templateResizeService: triggered by a resize event of a parent container
   * --> columnHeaderTemplateService: triggered by a click on the column conatiner in the the table
   * --> templateTreeService: triggered by a resize event of the treeservice
   * --> templateUiApi: triggered by the saving of templates
   * --> widgetGridLayoutService (only for combined template used z.b. efw): deliver information about current zoom of a gantt and the width
   * of the legend of chart or the width of the y-axis of a gantt
   */
  private afterInit() {
    this.ngOverride.next();
    this.templateResizeService
      .onResize()
      .pipe(
        takeUntil(this.ngOverride),
        takeWhile(() => this.alive)
      )
      .subscribe((data) => {
        if (
          data.template.id === this.tableTemplate.getId() &&
          this.tableTemplate.getResizeMode() !== EResizeMode.FIT_PARENT
        ) {
          this.maxHeight = data.wrapperHeight - 100;
        }
      });

    this.columnHeaderTemplateService.removeFilterObservable
      .pipe(
        takeUntil(this.ngOverride),
        takeWhile(() => this.alive)
      )
      .subscribe((column: SaxMsSpreadSheetColumn) => this.spreadSheetFilterService.removeFilter(column.id));

    this.templateTreeService.onResize
      .pipe(
        takeUntil(this.ngOverride),
        takeWhile(() => this.alive)
      )
      .subscribe(() => {
        // this.spreadsheet.reselectCell();
        this.rerender(true);
      });

    this.templateUiApi
      .afterSave()
      .pipe(
        takeUntil(this.ngOverride),
        takeWhile(() => this.alive)
      )
      .subscribe(() => {
        if (this.edited === true) {
          this.edited = false;
          if (this.tableTemplate?.isRefreshAfterSave()) {
            this.refreshSpreadsheetData();
          } else {
            // mark all cells as not edited
            this.unSavedChanges.forEach((value) => {
              value.forEach((v) => (v.value.edited = false));
            });

            // render all cells
            this.rerender(true);
          }
          this.unSavedChanges.clear();
        }
      });

    if (this.tableTemplate.getSyncHorizontalScrolling().length) {
      this.widgetGridLayoutService
        .getHorizontalScrollSync()
        .pipe(
          takeUntil(this.ngOverride),
          takeWhile(() => this.alive),
          filter(
            (data) =>
              this.tableTemplate.getSyncHorizontalScrolling().includes(data.templateId) &&
              data.initiator !== this.tableTemplate.getId()
          ),
          map((data) => data.scrollLeft)
        )
        .subscribe((scrollLeft) => {
          this.wtHolder.scrollLeft = scrollLeft;
        });

      if (this.tableTemplate.getSyncVerticalScrolling().length) {
        this.widgetGridLayoutService
          .getVerticalScrollSync()
          .pipe(
            takeUntil(this.ngOverride),
            takeWhile(() => this.alive),
            filter(
              (data) =>
                this.tableTemplate.getSyncVerticalScrolling().includes(data.templateId) &&
                data.initiator !== this.tableTemplate.getId()
            ),
            map((data) => data.scrollTop)
          )
          .subscribe((scrollTop) => {
            this.wtHolder.scrollTop = scrollTop;
          });
      }
    }

    this.widgetGridLayoutService
      .getSelectionInformations()
      .pipe(takeUntil(this.ngOverride))
      .pipe(takeWhile(() => this.alive))
      .subscribe((selectionInformations: SelectionInformation[]) => {
        if (!selectionInformations || selectionInformations.length === 0) {
          return;
        }

        const selectionInformation = selectionInformations.find(
          (info) => info.getContentId() === this.tableTemplate.getId()
        );
        if (!selectionInformation) {
          return;
        }

        this.getSelectedCellsFromInformation(selectionInformation);

        this.initSelection = false;
      });
    if (this.tableTemplate.isListensToTime()) {
      this.widgetGridLayoutService
        .getGridLayout()
        .pipe(takeUntil(this.ngOverride))
        .pipe(takeWhile(() => this.alive))
        .subscribe((layouts: GridLayout[]) => {
          if (!layouts || layouts.length === 0) {
            return;
          }

          this.gridLayout = layouts.find((layout) => layout.getContentId() === this.contentId);
          if (!this.gridLayout) {
            return;
          }

          // update right padding
          this._containerPaddingRight = this.widgetGridLayoutService.paddingRight;

          if (this.gridLayout.getZoom()) {
            if (!this.currentZoom) {
              this.currentZoom = new SpreadsheetZoom(
                new Date(this.gridLayout.getZoom().getStart()),
                new Date(this.gridLayout.getZoom().getEnd())
              );
            } else {
              if (
                this.currentZoom.getStart() !== new Date(this.gridLayout.getZoom().getStart()) ||
                this.currentZoom.getEnd() !== new Date(this.gridLayout.getZoom().getEnd())
              ) {
                this.currentZoom = new SpreadsheetZoom(
                  new Date(this.gridLayout.getZoom().getStart()),
                  new Date(this.gridLayout.getZoom().getEnd())
                );
              } else {
                return;
              }
            }
          }

          if (this.currentZoom && this.gridLayout.getContentId()) {
            this.currentZoom.setContentId(this.gridLayout.getContentId());
          }

          if (this.gridLayout.getGridStart()) {
            this.offsetWidth = this.gridLayout.getGridStart();
          }

          this.noScrolling = this.currentZoom && this.currentZoom.getContentId() === this.contentId;
          this.applyZoom();
        });
      this._containerPaddingRight = this.widgetGridLayoutService.paddingRight;
    }
  }

  /**
   * calc the selection information for the table
   * @param rowResourceId id of the selected row
   * @param columnIndies indies of the selected columns
   * @returns selection information
   */
  private getRowSelectionCoords(
    rowResourceId: string,
    columnIndies: Set<number>
  ): Array<[number, number | string, number, number | string]> {
    const tableRowIndex = this.rowDataUtil
      .getTableRowPlainList()
      .findIndex((tableRow) => tableRow.resourceId === rowResourceId);
    const columnIndexArray = Array.from(columnIndies.values());
    const minValue = Math.min(...columnIndexArray);
    const maxValue = Math.max(...columnIndexArray);
    const rowSelection: Array<[number, number | string, number, number | string]> = [];
    let rowSelectionItemStart = null;
    let rowSelectionItemEnd = null;
    for (let i = minValue; i <= maxValue; i++) {
      if (columnIndies.has(i)) {
        if (rowSelectionItemStart === null) {
          rowSelectionItemStart = i;
        }
        if (i === maxValue) {
          rowSelectionItemEnd = i;
          rowSelection.push([tableRowIndex, rowSelectionItemStart, tableRowIndex, rowSelectionItemEnd]);
          rowSelectionItemStart = null;
        }
      } else {
        if (columnIndies.has(i - 1)) {
          rowSelectionItemEnd = i - 1;
          rowSelection.push([tableRowIndex, rowSelectionItemStart, tableRowIndex, rowSelectionItemEnd]);
          rowSelectionItemStart = null;
        }
      }
    }
    return rowSelection;
  }

  /**
   * select the given coords inthe table
   * @param cellCoords
   */
  private selectCells(cellCoords: Array<[number, number | string, number, number | string]> | CellRange[]) {
    of(null)
      .pipe(delay(0))
      .subscribe(() => {
        if (this.spreadSheetInstance && !this.spreadSheetInstance.isDestroyed) {
          this.spreadSheetInstance.selectCells(cellCoords, false);
        }
      });
  }

  /**
   * applies the gantt zoom to spreadsheet (feature for the combine tempaltes)
   * @returns void
   */
  applyZoom(): void {
    if (!this.tableTemplate) {
      return;
    }
    if (!this.tableTemplate.isListensToTime()) {
      this.triggerHeightCalculation();
      return;
    }

    if (!this.containerWidth && !this.wrappingContainer) {
      return;
    }

    let width: number = this.containerWidth || this.wrappingContainer.nativeElement.clientWidth;
    if (this._containerPaddingRight) {
      width -= this._containerPaddingRight;
    }

    if (!this.currentZoom || !this.currentZoom.apply || isNaN(width) || isNaN(this.offsetWidth)) {
      this.triggerHeightCalculation();
      return;
    }
    const firstColumSize = this.offsetWidth - 50;
    this.currentZoom.apply(width, this.offsetWidth, this.columnUtil.getColumns(), this, firstColumSize);
    this.gridLayoutSize = firstColumSize;
    const columnSetting = this.getColumnSettings(this.columnUtil.getColumnByIndex(0, true));
    columnSetting.column.width = this.gridLayoutSize;

    if (columnSetting.createNew) {
      this.spreadsheetsettings.columnSettings = this.spreadsheetsettings.columnSettings.slice();
      this.spreadsheetsettings.columnSettings.push(columnSetting.column);
    }

    of(null)
      .pipe(delay(0))
      .subscribe(() => {
        this.columnUtil.getColumns().forEach((column) => {
          this.hiddenColumn(column.data, !this.hiddenColumnFilterSet.has(column.filterSortObject.getColumnIndex()));
        });
        this.updateHiddenColumnPlugin();
        this.setColumnsWidths(this.columnUtil.getColumns().map((col) => col.width));
        this.triggerHeightCalculation();
      });
  }

  /**
   * update the settings of the table ( trigger a rerender)
   * @param settings new settings for the table
   */
  private updateSettings(settings: Handsontable.GridSettings) {
    if (this.spreadSheetInstance && !this.spreadSheetInstance.isDestroyed) {
      this.spreadSheetInstance.updateSettings(settings);
      this.handleNestedRows();
    }
  }

  /**
   * collaspe all nested rows after a rerender with was collapse before the rerender
   */
  private handleNestedRows() {
    if (this.nestedRowsPlugin && this.areNestedRowsInTable()) {
      this.rowCollapseMap.forEach((state, index) => {
        if (state) {
          (this.nestedRowsPlugin as any).collapsingUI.collapseChildren(index);
        }
      });
    }
  }

  /**
   * update the columnWidths in the table setting and in the table
   * @param columnWidth new columnwidths (number value will be interpreted in px)
   */
  private setColumnsWidths(columnWidth: number[]) {
    this.updateSettings({ colWidths: columnWidth });
  }

  /**
   * calc the selected coords information after the tbale was inited
   * @param selectionInformation
   */
  private getSelectedCellsFromInformation(selectionInformation: SelectionInformation) {
    if (
      selectionInformation &&
      (selectionInformation.getTemplateId() !== this.tableTemplate.getId() ||
        (this.selectionModel && this.selectionModel.selectionColumns.size === 0))
    ) {
      this.initSelection = true;
      this.selectionInformation = selectionInformation;

      let rowSelection: Array<[number, number | string, number, number | string]> = [];
      this.selectionInformation.getSelectionResource().forEach((columnIndies, resourceId) => {
        rowSelection = rowSelection.concat(this.getRowSelectionCoords(resourceId, columnIndies));
      });
      this.selectCells(this.selectionInformation.getSelectedRangesForSelect());
    }
  }

  /**
   * update the template
   */
  private refreshSpreadsheetData(): void {
    this.setInitFinished(false);
    this.setInitLoadRows(false);

    if (this.currentZoom && this.currentZoom.getContentId() === this.contentId) {
      this.templateApi.updateElements([this.tableTemplate.getId()]);
    } else {
      this.templateApi.refreshElementData([this.tableTemplate.getId()]);
    }
  }

  /**
   * init the custom editor for cells
   * @param customCellEditor the html comtainer the custom editor
   */
  private initEditor(customCellEditor: ElementRef): void {
    this.spreadsheetHelperUtil.initEditor(customCellEditor.nativeElement, this.closeEditor.bind(this));
    this.spreadsheetHelperUtil
      .getCustomEditor()
      .setEnterCallback(this.handleEnterHotKey.bind(this))
      .setTabCallback(this.handleTabKey.bind(this))
      .setHandleEscapeCallback(this.handleEscapeKey.bind(this));
  }

  /**
   * close callback for the custom editor
   * @param colIndex columnindex of cell
   * @param rowIndex rowindex of cell
   * @param value value of cell
   * @param jumpToNextCell jump to next cell yes or no  ( Tab: col + 1 , Enter: row+1)
   */
  private closeEditor(colIndex, rowIndex, value, jumpToNextCell): void {
    if (jumpToNextCell) {
      this.getNextCell(colIndex, rowIndex);

      if (value === 0) {
        const cell = this.rowDataUtil.getCellObjectByPosition(rowIndex, colIndex);
        cell.value = value;
        const entryElement: SaxmsRowEntryElement = new SaxmsRowEntryElement()
          .setValue(cell)
          .setColNumber(colIndex)
          .setRowNumber(rowIndex);

        cell.edited = true;
        this.onTableCellEdit([entryElement]);
      }
    }
    this.spreadsheetHooksUtilService.setSavedKeyForEditor(null);
    this.afterCustomEditorClose();
    this.rerender();
  }

  /**
   * handle the save action after the closing of the custom editor
   */
  private afterCustomEditorClose(): void {
    const column = this.columnUtil.getColumnByIndex(this.editValueObject.getColNumber(), true);

    if (
      column.entryElement &&
      (column.fieldType === EFieldType.MULTI_OBJECT_SELECTOR || column.fieldType === EFieldType.OBJECT_SELECTOR)
    ) {
      this.saveChangeOfCustomEditor();
    }

    if (column.fieldType === EFieldType.DURATION_PICKER) {
      this.saveChangeOfCustomEditor();
    }

    if (this.openOverInitKey && column.fieldType === EFieldType.TEXT_FIELD) {
      this.editEntryElementOfCellEditor(this.editValueObject.getEntryElement());
    }

    if (this.openOverInitKey && column.fieldType === EFieldType.TEXT_AREA) {
      this.editEntryElementOfCellEditor(this.editValueObject.getEntryElement());
    }
  }

  /**
   * calc the next cell and select this cell
   * @param colIndex current visual column index
   * @param rowIndex current visual row index
   */
  private getNextCell(colIndex, rowIndex): void {
    const visualRow = rowIndex;
    const visualCol = colIndex;

    if (this.closeKeyboardEvent === EHotkeyType.TAB) {
      if (this.columnUtil.getColumns().length - 1 >= visualCol + 1) {
        this.spreadSheetInstance.selectCell(visualRow, visualCol + 1);
      } else {
        if (this.rowDataUtil.getDatasetPlainlist().length - 1 >= visualRow + 1) {
          this.spreadSheetInstance.selectCell(visualRow + 1, 0);
        } else {
          if (this.configApi.access().templates.Table.jumpToStart) {
            this.spreadSheetInstance.selectCell(0, 0);
          } else {
            this.spreadSheetInstance.selectCell(visualRow, visualCol);
          }
        }
      }
    } else {
      if (this.rowDataUtil.getDatasetPlainlist().length - 1 >= visualRow + 1) {
        this.spreadSheetInstance.selectCell(visualRow + 1, visualCol);
      } else {
        if (this.columnUtil.getColumns().length - 1 >= visualCol + 1) {
          this.spreadSheetInstance.selectCell(0, visualCol + 1);
        } else {
          if (this.configApi.access().templates.Table.jumpToStart) {
            this.spreadSheetInstance.selectCell(0, 0);
          } else {
            this.spreadSheetInstance.selectCell(visualRow, visualCol);
          }
          // this.spreadSheetInstance.selectCell(visualRow, visualCol);
        }
      }
    }
  }

  /**
   * add function to different hooks of the table, get all information of all required plugins of the table
   * and trigger the calc of the init height of the table
   */
  public afterInitTable(): void {
    if (this.hotRegisterer.getInstance(this.tableId)) {
      this.spreadSheetInstance = this.hotRegisterer.getInstance(this.tableId);
    }

    if (!this.spreadSheetInstance || this.spreadSheetInstance.isDestroyed) {
      return console.error('HANDSONTABLE INSTANCE BROKEN', this.spreadSheetInstance);
    }

    this.addHooks();
    this.getPluginsFromTable();

    this.spreadsheetHelperUtil.setSpreadSheetInstance(this.spreadSheetInstance);

    // update the hidden column information of the table
    if (this.hiddenColumnFilterSet.size > 0) {
      const hiddenColumns = this.columnUtil
        .getColumns()
        .filter((column) => this.hiddenColumnFilterSet.has(column.filterSortObject.getColumnIndex()))
        .map((column) => column.data);
      this.hiddenColumnsPlugin.hideColumns(hiddenColumns);
      this.updateHiddenColumnPlugin();
    }

    // calc the merged cells of the table
    this.rowDataUtil.calcMergedCells();
    if (this.rowDataUtil.getMergeConfig().length > 0) {
      this.mergeRows();
    }

    this.afterInit();
    this.setInitFinished(true);
    this.autoScrollToIndex();
    this.executeSortAndFilter();
    this.adjustWidthOfLastColumn();
    if (this.nestedColumnHeaders?.length > 0) {
      this.updateSettings({
        nestedHeaders: this.tableTemplate.isHideHeader() ? null : this.nestedColumnHeaders,
        manualColumnMove: false,
      });
    }
    this.zone.run(() => {
      this.triggerHeightCalculation();
    });
  }

  /**
   * Trigger after the rendering of the table and build the column-header templates and replace this template with the old once
   */
  private afterViewRender(): void {
    if (!this.initFinished || !this.refreshFinished) {
      this.finishedTimeout.next();
      of(null)
        .pipe(delay(50), takeUntil(this.finishedTimeout))
        .subscribe(() => {
          this.setInitFinished(true);
          this.setRefreshFinished(true);
          this.setLoadingAnimation(false);
        });
    }

    this.buildColumnHeaderTemplate();
    // this.zone.run(() => this.adjustWidthOfLastColumn());
  }

  /**
   * execute merge cells for hierarchical tables
   */
  private mergeRows(): void {
    if (this.mergePlugin && this.rowDataUtil.getMergeConfig().length > 0) {
      if ((this.mergePlugin as any).mergedCellsCollection) {
        this.mergePlugin.clearCollections();
      }
      for (const mergeInfo of this.rowDataUtil.getMergeConfig()) {
        for (const mergedCell of (this.mergePlugin as any).mergedCellsCollection.mergedCells) {
          if (mergedCell.row === mergeInfo.row && mergedCell.col === mergeInfo.col) {
            continue;
          }
        }

        this.merge(
          mergeInfo.row,
          mergeInfo.col,
          mergeInfo.row + (mergeInfo.rowspan - 1),
          mergeInfo.col + (mergeInfo.colspan - 1)
        );
      }
    }
  }

  /**
   * merge multiple cells together
   * @param startRow start row index for the merge
   * @param startColumn start column index for the merge
   * @param endRow end row index for the merge
   * @param endColumn end column index for the merge
   */
  private merge(startRow, startColumn, endRow, endColumn): void {
    this.mergePlugin.merge(startRow, startColumn, endRow, endColumn);
  }

  /**
   * build the column-header templates and replace this template with the old once
   */
  private buildColumnHeaderTemplate(): void {
    if (!this.columnUtil.getColumns() || this.tableTemplate.isHideHeader()) {
      return;
    }
    const rerenderColumn = this.columnUtil
      .getColumns()
      .filter((_column) => _column.filterSortObject.isFilterActive() || _column.filterSortObject.isSortActive());
    const normalColumns = this.columnUtil
      .getColumns()
      .filter((_column) => !_column.filterSortObject.isFilterActive() && !_column.filterSortObject.isSortActive());

    rerenderColumn?.forEach((column) => {
      const columnHeaderTD = this.spreadSheetInstance?.getCell(-1, column.data, true);
      if (!columnHeaderTD) {
        return;
      }
      Handsontable.dom.empty(columnHeaderTD);
      const iconDiv = document.createElement('div');
      this.createFactory(iconDiv, column.data, column);
      columnHeaderTD.appendChild(iconDiv);
    });

    normalColumns?.forEach((column) => {
      const columnHeaderTD = this.spreadSheetInstance?.getCell(-1, column.data, true);
      if (!columnHeaderTD) {
        return;
      }
      const columnHeaderInnerTD = columnHeaderTD.getElementsByClassName('saxms-column-header');
      if (columnHeaderInnerTD.length > 0) {
        columnHeaderInnerTD.item(0).innerHTML = column.label;
      }
    });
  }

  /**
   * create the column-header template for a column and return the component
   * @param node html parent conatiner for the header template
   * @param columnIndex index of the column
   * @param column column information
   * @returns a instance of the SaxmsColumnHeaderTemplateComponent
   */
  private createFactory(
    node: any,
    columnIndex: any,
    column: SaxMsSpreadSheetColumn
  ): ComponentRef<SaxmsColumnHeaderTemplateComponent> {
    const factory: ComponentFactory<SaxmsColumnHeaderTemplateComponent> = this.resolver.resolveComponentFactory(
      SaxmsColumnHeaderTemplateComponent
    );
    const cmp: ComponentRef<SaxmsColumnHeaderTemplateComponent> = factory.create(this.injector, [], node);
    cmp.instance.columnIndex = columnIndex;
    cmp.instance.tableId = this.tableId;
    cmp.instance.showTooltip = this.showTooltip;
    cmp.instance.column = column;

    if (this.spreadsheetHelperUtil.getColumnHeaderIconSet()) {
      cmp.instance.iconSet = this.spreadsheetHelperUtil.getColumnHeaderIconSet();
    }

    cmp.changeDetectorRef.detectChanges();

    // start change detection
    this.appRef.attachView(cmp.hostView);
    return cmp;
  }

  /**
   * update the maxheight of the table and calc the new current height
   */
  private setDynamicHeight() {
    if (this.tableTemplate && this.wrappingContainer) {
      const resizeMode = this.tableTemplate.getResizeMode();
      const height: number =
        resizeMode === EResizeMode.FIT_PARENT
          ? Math.max(this.wrappingContainer?.nativeElement.clientHeight - this.getOffsetHeight(), 75) // FIT_PARENT calculation
          : resizeMode === EResizeMode.OWN_SIZE
          ? this.calcOwnSizeHeight() // OWN_SIZE calculation
          : DEFAULT_MAX_HEIGHT; // DEFAULT height
      if (this.maxHeight === height) {
        return;
      }
      this.zone.run(() => {
        this.maxHeight = height;
      });
      this.triggerHeightCalculation();
    }
  }

  /**
   * Calculates the offset height of the table if the table has the resize mode OWN_SIZE
   * @returns offset height of the table
   */
  private calcOwnSizeHeight(): number {
    if (this.tableTemplate.getMaxTableRows() && !isNaN(this.tableTemplate.getMaxTableRows())) {
      let tableHeaderHeight = 0;
      const currentTableWidth = this.getTableProportions()?.width - 1 || 0;
      const maxWidth = this.spreadSheet?.container?.nativeElement?.offsetWidth || 0;
      const isHorizontalOverflow = currentTableWidth > maxWidth;
      const scrollbarHeight = isHorizontalOverflow ? SCROLLBAR_HEIGHT : 0;

      if (!this.tableTemplate.isHideHeader()) {
        // calc the height of the table header without access to the dom -> not so accurate, but helpful as fallback
        const amountOfNestedHeaders = Math.max((this.nestedColumnHeaders?.length ?? 0) - 1, 0); // -1 to remove the main header

        const staticHeaderHeight =
          amountOfNestedHeaders * ROW_HEIGHT + (this.tableSettings.columnHeaderHeight as number);

        // try to get the height of the table header from the dom
        const dynamicHeaderHeight = (this.spreadSheet?.container?.nativeElement as HTMLElement)
          ?.getElementsByClassName('ht_master')[0]
          ?.getElementsByTagName('thead')[0]
          ?.getBoundingClientRect()?.height;

        tableHeaderHeight = dynamicHeaderHeight ?? staticHeaderHeight;
      }

      return this.tableTemplate.getMaxTableRows() * ROW_HEIGHT + tableHeaderHeight + scrollbarHeight;
    }

    return DEFAULT_MAX_HEIGHT;
  }

  /**
   * get the default column-header containers for the table
   * @param col column index
   * @returns html snippet for the default column-header
   */
  private getColumnHeaderContainer(col: number) {
    const column = this.columnUtil.getColumnByIndex(col, true);
    if (!column) {
      return '-';
    }
    return `<div style="position: relative; width: 100%; height: 100%;" class="saxms-column-header saxms-column-header_${this.tableId}_${col}">${column.label}</div>`;
  }

  /**
   * initialize the table with all settings of the handsontable
   */
  private initTableSettings() {
    this.selectionModel = new SaxMsSelectionModel(
      this.columnUtil.getColumns(),
      this.rowDatasets ? this.rowDatasets.length : 0,
      []
    );

    this.tableSettings = {
      licenseKey: '78ef9-34c40-fc2d0-14726-56841',
      rowHeights: `${ROW_HEIGHT}px`,
      multiColumnSorting: {
        sortEmptyCells: false,
        indicator: false,
        headerAction: false,
      },
      columnHeaderHeight: 30,
      colHeaders: this.tableTemplate.isHideHeader() ? false : this.getColumnHeaderContainer.bind(this),
      rowHeaders: true,
      dropdownMenu: false,
      manualColumnMove: this.tableTemplate.isColumnReorder() && !this.editableModeLocked,
      manualRowMove: false,
      manualColumnResize: true,
      manualRowResize: false,
      autoColumnSize: false,
      autoRowSize: false,
      formulas: null,
      contextMenu: false,
      wordWrap: false,
      fixedColumnsLeft: this.tableTemplate.getNumberFixedColumns(),
      fixedRowsTop: this.tableTemplate.getNumberFixedRowsTop(),
      fixedRowsBottom: this.tableTemplate.getNumberFixedRowsBottom(),
      undo: false,
      mergeCells: true,
      filters: true,
      stretchH: this.stretch || this.hiddenColumns.columns.length > 0 ? EStretch.ALL : EStretch.LAST,
      outsideClickDeselects: false,
      renderer: this.cellRendererUtil.getCustomCellRenderer(),
      viewportRowRenderingOffset: 50,
      viewportColumnRenderingOffset: 50,
      hiddenColumns: this.hiddenColumns,
      hiddenRows: this.hiddenRows,
      nestedHeaders: this.tableTemplate.isHideHeader()
        ? null
        : this.nestedColumnHeaders?.length > 0
        ? this.nestedColumnHeaders
        : null,
      // nestedHeaders:
      nestedRows: true,
      // nestedHeaders: true,
      autoWrapCol: this.configApi.access().templates.Table.jumpToStart,
      autoWrapRow: this.configApi.access().templates.Table.jumpToStart,
      trimRows: [],
      copyPaste: true,
    };
  }

  /**
   * save some required information about the interaction of the table, read the settings of the table
   */
  private initSpreadsheet(): Observable<any> {
    this.title = this.tableTemplate.getName();

    this.tableOptions = {
      searchBar: this.tableTemplate.isSearchable(),
      add: this.tableTemplate.isAddNewRows(),
      editable: this.tableTemplate.isEditable(),
      celleditable: this.tableTemplate.isCellEdit(),
    };

    this.spreadsheetHelperUtil.setTableId(this.tableTemplate.getId()).setTableOptions(this.tableOptions);

    return this.loadSpreadsheetSettings();
  }

  /**
   * try to get the table settings form the backend
   * @returns tablesettings if this are available
   */
  private loadSpreadsheetSettings(): Observable<any> {
    // return if no settings are available
    if (!this.tableTemplate.getSettings()) {
      return of(null);
    }

    // if id was found in settings
    if (this.tableTemplate.getSettings().id) {
      return this.spreadsheetSettingsService.getSpreadsheetSettings(this.tableTemplate.getSettings().id).pipe(
        switchMap((spreadsheetsettings) => {
          // validation of the settings
          if (!this.spreadsheetSettingsService.isValid(spreadsheetsettings)) {
            return this.spreadsheetSettingsService.resetSpreadsheetSettings(this.tableTemplate.getSettings().id);
          }
          return of(spreadsheetsettings);
        }),
        tap((settings) => {
          if (settings) {
            this.spreadsheetsettings = settings;
          }

          this.columnUtil.setSpreadsheetsettings(this.spreadsheetsettings);
        })
      );
    } else {
      // default behaviour if no id was found
      this.spreadsheetsettings.columnSettings = this.tableTemplate.getSettings()?.columnSettings;
      this.spreadsheetsettings.tableID = this.tableTemplate.getSettings()?.tableID;
      this.spreadsheetsettings.id = GlobalUtils.generateUUID();
      this.columnUtil.setSpreadsheetsettings(this.spreadsheetsettings);
      return of(null);
    }
  }

  /**
   * clear the sort settings of a specific column
   * @param columnIndex index of the specific column
   */
  public clearSortSettings(...columns: SaxMsSpreadSheetColumn[]): void {
    columns.forEach((column) => {
      const columnSetting = this.getColumnSettings(column);
      delete columnSetting.column.sort;
    });
    // this.saveSettings().pipe(take(1)).subscribe();
  }

  /**
   * update the loading state of the table
   * @param state new loading state
   */
  private setLoading(state: boolean): void {
    this.zone.run(() => (this.loading = state));
  }

  /**
   * updates data of the spreadsheet
   * @returns Observable<any>
   */
  private tmpfetchData(): Observable<any> {
    if (!this.tableTemplate) {
      return of(null);
    }
    if (this.tableTemplate.getValues()) {
      return this.loadTableData(this.tableTemplate.getValues()).pipe(finalize(() => this.setLoading(false)));
    }

    return of(null);
  }

  /**
   * map all columns and rows information of the table
   * @param tableData given tableData
   * @returns Observable
   */
  private loadTableData(tableData): Observable<any> {
    // setting initial zoom (relevant for widget grid layout)
    if (tableData.scrollStart && tableData.scrollEnd) {
      this.initialZoomStart = new Date(tableData.scrollStart);
      this.initialZoomEnd = new Date(tableData.scrollEnd);
    }

    const tableColumnData = tableData.tableHeaders
      ? (tableData.tableHeaders || []).map((h) => this.tableAdapter.parseTableHeader<TableHeader>(TableHeader, h))
      : this.tableTemplate.getTableHeaders();
    return this.mappingColumns(tableColumnData).pipe(
      tap(() => {
        this.rowDataUtil.buildTableRows(tableData.tableRows);
        this.rows = this.rowDataUtil.getDisplayRows().slice();
        this.selectionModel.setRowCount(this.rows.length);

        of(null)
          .pipe(delay(0))
          .subscribe(() => {
            if (this.rows?.length > 0 && this.areNestedRowsInTable()) {
              ((this.nestedRowsPlugin as any).collapsingUI as any)?.collapseAll();
            } else {
              this.afterViewRender(); // to trigger loading animation calculation even if no rows are available
            }

            if (this.tableTemplate.isFontSizeAutoScale()) {
              this.spreadsheetHelperUtil.calcFontSizeAutoScale(
                this.columnUtil.getColumns(),
                this.rowDataUtil.getDisplayRowsFlatList()
              );
              this.spreadsheetHelperUtil.updateFontSize(this.columnUtil.getColumns());
              this.cellRendererUtil.setFontSizeOfCell(this.spreadsheetHelperUtil.getNumberValueFontsize());
            }

            this.getColorLegend();
            this.setInitLoadRows(true);

            // adjust initial zoom (relevant for widget grid layout)
            if (this.initialZoomStart && this.initialZoomEnd) {
              this.currentZoom = new SpreadsheetZoom(this.initialZoomStart, this.initialZoomEnd);
              this.applyZoom();
            }

            return of(null);
          });
      })
    );
  }

  /**
   * map the color legend information for the table
   */
  private getColorLegend(): void {
    this.colorClassSet = [];
    if (!this.tableTemplate.getColorLegendMap()) {
      return;
    }
    Object.keys(this.tableTemplate.getColorLegendMap()).forEach((key) => {
      const value = this.tableTemplate.getColorLegendMap()[key];
      this.colorClassSet.push({
        id: key,
        label: value,
        color: 'black',
        patternColor: 'black',
        strokeColor: 'black',
        tooltip: value,
        isActive: true,
        className: key,
        noRender: [],
      });
    });
  }

  /**
   * map the columns of the table
   * @param tableColumns raw data of the columns
   * @returns Observable
   */
  private mappingColumns(tableColumns: any[]): Observable<any> {
    return new Observable((observer) => {
      if (this.tableTemplate.isDynamicTableHeaders() || this.initLoading) {
        this.initLoading = false;
        of(null)
          .pipe(delay(0))
          .subscribe(() => {
            if (!this.columnUtil) {
              observer.error();
            }
            this.columnUtil.generateColumns(tableColumns);
            this.columnUtil.buildNestedColumns(tableColumns);

            this.selectionModel.setColumns(this.columnUtil.getColumns());
            this.setColumns(this.columnUtil.getColumns(), undefined, false);
            this.nestedColumnHeaders = this.columnUtil.getNestedColumns();

            if (this.columnUtil.getNestedColumns().length > 0) {
              const newSettings = {
                nestedHeaders: this.nestedColumnHeaders,
                manualColumnMove: false,
              };

              const currentSettings = {
                nestedHeaders: this.spreadSheetInstance?.getSettings()?.nestedHeaders,
                manualColumnMove: this.spreadSheetInstance?.getSettings()?.manualColumnMove,
              };

              if (JSON.stringify(newSettings) !== JSON.stringify(currentSettings)) {
                this.updateSettings({
                  nestedHeaders: this.nestedColumnHeaders,
                  manualColumnMove: false,
                });
              }
            }
            observer.next();
          });
      } else {
        observer.next();
      }
    });
  }

  /**
   * generate the toolbar of the table
   */
  private buildToolbar(): void {
    const showHideBtn = this.configApi.access().templates?.Table?.toolbarButtons?.menuToggle === false ? false : true; // always true if not set
    const tId = `${this.tableTemplate.getId()}-toolbar`;
    this.toolbar = new SpreadsheetToolbar(
      this,
      { showMenuToggleBtn: showHideBtn },
      this.spreadSheetFilterService,
      this.buttonService,
      this.templateActionService
    )
      .setId(tId)
      .get();
    if (!this.tableTemplate) {
      return;
    }
    this.toolbar.addEventListener(this.tableTemplate.getId(), EToolbarEvents.MENU_MODE_CHANGED, (ev: CustomEvent) => {
      this.tableTemplate.setMenuMode(ev.detail.extended);

      this.rerender(true);
    });

    this.toolbar.addEventListener(this.tableTemplate.getId(), EToolbarEvents.ACTIVE_ITEM_CHANGED, (ev: CustomEvent) => {
      of(null)
        .pipe(delay(500))
        .subscribe(() => {
          this.scrollToLeft(this.scrollLeft);
        });
    });
  }

  /**
   * map all user settings information from the backend for a table
   * @returns if all settings finish with mapping
   */
  private readSettings(): Observable<any> {
    if (!this.alive) {
      return of(null);
    }
    return new Observable<any>((oberver) => {
      this.blockedSaveSettings = true;
      if (!this.spreadsheetsettings || this.spreadsheetsettings.columnSettings.length === 0) {
        this.blockedSaveSettings = false;
        oberver.next(null);
        return;
      }
      const settingsPromises: Promise<IExecuteSeetings>[] = [];
      for (const columnSettings of this.spreadsheetsettings.columnSettings) {
        const column = this.columnUtil.getColumnById(columnSettings.columnID);
        if (!column) {
          continue;
        }

        const filterObject: SaxMsSubemenuFilterOutput = {
          columnId: column.data,
          filterConditionOne: columnSettings.filterCond1
            ? EFilterConditions[columnSettings.filterCond1]
            : EFilterConditions.none,
          filterValueOne: columnSettings.filterVal1,
          filterConditionTwo: columnSettings.filterCond2
            ? EFilterConditions[columnSettings.filterCond2]
            : EFilterConditions.none,
          filterValueTwo: columnSettings.filterVal2,
          logicOperator: columnSettings.filterOperator,
          elementType: column.fieldType,
          datatype: column.datatype,
          executeFilter: false,
        };

        const quicksearchFilterObject: QuickSearchFilter = {
          id: this.tableTemplate.getId() + '_quicksearch_' + column.filterSortObject.getColumnIndex(),
          value: {
            value: columnSettings.quickFilterVal,
          },
          condition: columnSettings.quickFilterCond,
        };

        settingsPromises.push(
          this.spreadSheetFilterService.executeFilterSetting(
            columnSettings,
            column,
            filterObject,
            quicksearchFilterObject
          )
        );
      }

      Promise.all(settingsPromises).then((values) => {
        for (const value of values) {
          if (!value) {
            continue;
          }
          if (value.columnSettings.sort && value.columnSettings.sort !== ESortDirection.NONE) {
            value.column.filterSortObject.setSortDirection(value.columnSettings.sort);
            value.column.filterSortObject.setSortActive(
              value.columnSettings.sort === ESortDirection.DESC || value.columnSettings.sort === ESortDirection.ASC
            );
            this.getSortConfigByColumnIndex(
              value.column.id,
              value.column.filterSortObject.getColumnIndex(),
              value.column.filterSortObject
            );
          }

          if (!this.hiddenColumnFilterSet) {
            this.hiddenColumnFilterSet = new Set();
          }

          if (value.columnSettings.hidden) {
            this.hiddenColumnFilterSet.add(value.column.filterSortObject.getColumnIndex());
          } else {
            if (this.hiddenColumnFilterSet.has(value.column.filterSortObject.getColumnIndex())) {
              this.hiddenColumnFilterSet.delete(value.column.filterSortObject.getColumnIndex());
            }
          }

          for (const column of this.columnUtil.getColumns()) {
            if (column.filterSortObject.isSortActive() || column.filterSortObject.isFilterActive()) {
              this.columnHeaderTemplateService.setCurrentSortFilterObjectByColumn(column);
            }
          }
        }

        this.changeDataOfSubmenu();
        this.blockedSaveSettings = false;
        oberver.next();
      });
    });
  }

  /**
   * sort the table by a given column
   * @param columnIndex columnindex of the column
   * @param column column information
   */
  private sortByColumn(columnIndex: number, column: SaxMsSpreadSheetColumn) {
    if (this.spreadSheetInstance && !this.spreadSheetInstance.isDestroyed) {
      this.columnHeaderTemplateService.setCurrentSortFilterObjectByColumn(column);
      const sortConfig = this.getSortConfigByColumnIndex(column.id, columnIndex, column.filterSortObject);
      this.executeSortPlugin(sortConfig);
    }
  }

  /**
   * execute the passed sort config
   * is the passed sort config are empty than the sort will be clear
   * @param sortConfig the current config of the sort
   */
  public executeSortPlugin(sortConfig: any[]): void {
    if (sortConfig.length > 0) {
      if (this.spreadsheetHelperUtil.checkPlugin(ESpreadsheetPlugin.SORT_PLUGIN, 'multiColumnSorting')) {
        this.sortPlugin.sort(sortConfig);
      }
    } else {
      if (
        this.spreadsheetHelperUtil.checkPlugin(ESpreadsheetPlugin.SORT_PLUGIN, 'multiColumnSorting') &&
        this.initFinished
      ) {
        this.sortPlugin.clearSort();
      }
    }
  }

  /**
   * configure the sort object for the multisort
   * @param columnIndex columnindex of the selected column
   * @param sortFilterObject sort information about the selected column
   * @returns the current multisort config for the table
   */
  private getSortConfigByColumnIndex(
    columnId: string,
    columnIndex: number,
    sortFilterObject: SortFilterObject
  ): ISpreadsheetSortObject[] {
    const sort: ISpreadsheetSortObject = {
      column: columnIndex,
      sortOrder: sortFilterObject.getSortDirection(),
    };

    if (
      sortFilterObject.getSortDirection() === ESortDirection.ASC ||
      sortFilterObject.getSortDirection() === ESortDirection.DESC
    ) {
      this.sortMap.set(columnId, sort);
    } else {
      this.sortMap.delete(columnId);
    }

    return this.getSortConfig();
  }

  /**
   * add functions to different hotkeys
   */
  private addHotKeys() {
    this.tableTemplate.setHotkeys([
      new Hotkey()
        .setCtrlKey(true)
        .setKeyCode(EHotkeyType.SPACE)
        .setActions([new Action().setActionType(EActionType.LOCAL).setCb(() => of(this.selectColumn()))]),
      new Hotkey()
        .setStopDefault(false)
        .setKeyCode(EHotkeyType.SPACE)
        .setActions([new Action().setActionType(EActionType.LOCAL).setCb(() => of(this.handleKeyupCheckboxEvent()))]),
    ]);
  }

  /**
   * function for the TAB-Hotkey of the table
   * close the customeditor and save the value
   */
  private handleTabKey(): void {
    if (this.blockedCloseEventbyKeydown) {
      return;
    }

    this.closeKeyboardEvent = EHotkeyType.TAB;
    if (this.editValueObject.getEntryElement()) {
      this.customEditorClose();
      this.spreadSheetInstance.getActiveEditor()?.close();
    }
    this.afterCustomEditorClosebyEnter();
  }

  private closeKeyboardEvent: EHotkeyType;

  /**
   * function for the ENTER-Hotkey of the table
   * close the customeditor and save the value
   */
  private handleEnterHotKey(): void {
    if (this.blockedCloseEventbyKeydown) {
      return;
    }

    this.closeKeyboardEvent = EHotkeyType.ENTER;
    this.afterCustomEditorClosebyEnter();
  }

  /**
   * function for the ESCAPE-Hotkey of the table
   * close the customeditor and reset the value of the editot to the previous value
   */
  private handleEscapeKey() {
    this.currentValueOfCustomEditor = this.editValueObject.getPrevValue();
    if (this.editValueObject.getEntryElement()) {
      this.editValueObject
        .getEntryElement()
        .getValue<EntryElementValue>()
        .setValue(this.editValueObject.getPrevValue());
    }
    // this.saveChangeOfCustomEditor();

    if (this.spreadsheetHelperUtil.getCustomEditor().isOpen) {
      this.customEditorClose();
      this.spreadSheetInstance.getActiveEditor()?.close();
    }
    this.closeKeyboardEvent = EHotkeyType.ESCAPE;
    // if (this.calendarEvent && this.calendarEvent.instance) {
    //   this.calendarEvent.instance.instance.close();
    // }

    this.spreadSheetInstance.getActiveEditor()?.close();
  }

  /**
   * toogle the state of a checkbox editor inside the table if the Space-key was pressed
   */
  private handleKeyupCheckboxEvent(): void {
    if (!this.editValueObject || isNaN(this.editValueObject.getColNumber())) {
      return;
    }

    const column = this.columnUtil.getColumnById(this.editValueObject.getValue().tableHeaderId);
    if (column.fieldType === 'CHECK_BOX' && this.spreadsheetHelperUtil.getCustomEditor().isOpen) {
      this.editValueObject.getValue().value = !this.editValueObject.getValue().value;
      this.editCellEditorChange(this.editValueObject.getValue().value);
    }
  }

  /**
   * add a listener to the value chnage evnt of the template
   */
  private addEventListener() {
    this.tableTemplate.addEventListener(this.tableId, ETableEvents.VALUE_CHANGED, (ev: CustomEvent) => {
      this.zone.run(() => {
        this.setRefreshFinished(false);
        if (this.initLoading === true) {
          return;
        }
        this.setRefreshFinished(false);
        this.setLoadingAnimation(true);
        this.tmpfetchData()
          .pipe(
            switchMap(() => this.readSettings()),
            finalize(() => this.setLoading(false))
          )
          .subscribe(() => {
            of(null)
              .pipe(delay(0))
              .subscribe(() => {
                this.triggerHeightCalculation();
                this.applyZoom();
                // calc the merged cells of the table
                this.rowDataUtil.calcMergedCells();
                if (this.rowDataUtil.getMergeConfig().length > 0) {
                  this.mergeRows();
                }
                this.applyUnsavedValues();
                this.executeSortAndFilter();

                of(null)
                  .pipe(delay(300))
                  .subscribe(() => {
                    this.triggerHeightCalculation(false, true);
                  });
              });
          });
      });
    });
  }

  /**
   * select the current saved cells
   */
  private selectColumn() {
    if (this.selectionModel.getSelectedCells().length === 1) {
      this.spreadSheetInstance.deselectCell();
      this.spreadSheetInstance.selectColumns(
        this.selectionModel.getSelectedCells()[0].col,
        this.selectionModel.getSelectedCells()[0].col
      );
    }
  }

  /**
   * checks the state of buttons inside the toolbar of the table
   * @returns disabled state
   */
  private getButtonDisableState(): boolean {
    return this.editableModeLocked || !this.selectionModel.rowActionsEnable || !this.tableOptions.editable;
  }

  /**
   * TODO: Sebastian
   * @returns
   */
  private getOffsetHeight(): number {
    let offset = 0;
    offset += this.toolbarWrapper?.nativeElement.clientHeight || 0;
    offset += this.legendWrapper?.nativeElement.clientHeight || 0;
    return offset;
  }

  /**
   * calc the new height and width of the table if the resize event was triggered
   */
  private checkDimensions(force?: boolean): void {
    this.setDynamicHeight();
    if (this.containerWidth !== this.wrappingContainer?.nativeElement.clientWidth) {
      this.containerWidth = this.wrappingContainer?.nativeElement.clientWidth;
      if (this.initFinished) {
        this.applyZoom();
      } else {
        this.setDynamicHeight();
      }
      this.spreadSheetInstance?.render();
    }

    this.triggerHeightCalculation(undefined, force);
  }

  /**
   * set the height to the table
   * @param {number} height height of the table
   * @returns void
   */
  private setTableHeight(height: number, skipRerender = false, force = false): Observable<void> {
    // for fullscreen fix
    this.spreadSheetInstance.refreshDimensions();
    height -= 0;
    if (!force && Math.abs(this.tableHeight - height) <= 1) {
      return of(void 0);
    }
    return of(void 0).pipe(
      map(() => {
        this.tableHeight = height;
        if (!skipRerender) {
          this.rerender();
        }
        this.cd?.detectChanges();
        return void 0;
      })
    );
  }

  /**
   * save the data change of cell by the row and column index
   * @param rowIndex row index of the row of the cell
   * @param colIndex column index of the column of the cell
   * @param value new value of the cell
   */
  private changeValueOfEditor(
    physicalRowIndex: number,
    column: SaxMsSpreadSheetColumn,
    value: any,
    stopClose?: boolean
  ) {
    // if (this.blockEditing) {
    //   return;
    // }
    const physicalColumnIndex = column.filterSortObject.getColumnIndex();

    const rowFlatList = [].concat(...this.rows.map((item) => [item].concat(item.__children ? item.__children : [])));

    if (!this.rowDataUtil.getDatasetPlainlist()[physicalRowIndex]) {
      return;
    }

    of(null)
      .pipe(delay(0))
      .subscribe(() => {
        rowFlatList[physicalRowIndex][physicalColumnIndex] = this.rowDataUtil.getConvertedValueOfCell(column, value);
      });

    const cellValues = Object.values(this.rowDataUtil.getDatasetPlainlist()[physicalRowIndex]);
    const cellValue = cellValues.find((value) => value.tableHeaderId === column.id);

    // update internal value -> important for data operations like filtering
    this.getSpreadSheetInstance().setDataAtCell(
      this.getSpreadSheetInstance().toVisualRow(physicalRowIndex),
      column.data,
      this.rowDataUtil.getConvertedValueOfCell(column, value)
    );

    cellValue.edited = true;
    cellValue.value = this.rowDataUtil.getValuetypeAfterChange(column.data, value);
    if (stopClose) {
      return;
    }

    this.closeCustomEditor();
  }

  private closeCustomEditor(): void {
    if (this.spreadsheetHelperUtil.getCustomEditor().isOpen) {
      this.spreadsheetHelperUtil.getCustomEditor().close();
      this.spreadSheetInstance.getActiveEditor().close();
    }

    this.cellEditorContext.visibile = false;
    this.rerender();
  }

  /**
   * update the value of a cell of the table
   * @param stopClose flag to skip the closing of the editor
   */
  private saveChangeOfCustomEditor(stopClose?: boolean, changes = true) {
    // this.customSave = true;
    const value = this.currentValueOfCustomEditor;
    this.editValueObject.getValue().value = value;
    if (changes) {
      this.changeValueOfEditor(this.editValueObject.getRowNumber(), this.cellEditorContext.column, value, stopClose);
    } else {
      this.closeCustomEditor();
    }
    if (!stopClose) {
      this.onTableCellEdit([this.editValueObject]);
    }
  }

  /**
   * add the changed cell to the auto-save queue and start the auto-save queue if not started
   * @param saveOutput save information of a cell
   * @returns
   */
  private handleAutoSave(saveOutputs: SpreadsheetSaveOutput[]) {
    if (!saveOutputs?.length) {
      return;
    }
    this.saveQueue = this.saveQueue.concat(saveOutputs);
    if (!this.showLoadingAnimation) {
      this.setLoadingAnimation(this.saveQueue.length > 1);
    }
    if (this.queueActive && !this.tableTemplate.isEnableAsyncUpdate()) {
      return;
    }

    this.queueActive = true;
    this.zone.run(() => {
      this.checkQueue()
        .pipe(
          finalize(() => {
            this.queueActive = false;
          })
        )
        .subscribe();
    });
  }

  /**
   * reset the cell to the given prev value
   * @param uuid of the cell
   * @param value prev value of the cell
   */
  private resetEditorValueToPrevValue(uuid: string, value: any) {
    const tmpRows: ISaxMsSpreadsheetRawRow[] = this.rowDataUtil.getRowDatasets().slice();
    tmpRows.forEach((row) => {
      const cell = Object.values(row.properties).find((rowEntry) => rowEntry.uuid === uuid);
      if (cell) {
        const column: SaxMsSpreadSheetColumn = this.columnUtil.getColumnByIndex(cell.index, false);
        cell.edited = false;
        cell.value = value;
        this.cellRendererUtil.rerenderCell(column, row.resourceId, value, true);
        return cell;
      }
    });
  }

  /**
   * update the data of the rows of the Hndsontable after the autoSave
   */
  private updateRowsAfterAutoSave() {
    this.changedRowsAfterAutoSave.forEach((changedRow, index) => {
      for (const cell of Object.values(changedRow.properties)) {
        const column = this.columnUtil.getColumnById(cell.tableHeaderId);
        this.rows[index][cell.index] = this.rowDataUtil.getConvertedValueOfCell(column, cell.value);
      }
    });
  }

  private handleSaveRequest(saveOutput: SpreadsheetSaveOutput, isAsync: boolean): Observable<any> {
    return this.uiService.postData(saveOutput.saveModel.restUrl, saveOutput.saveModel.value, null).pipe(
      catchError((err) => {
        this.resetEditorValueToPrevValue(saveOutput.saveModel.value.uuid, saveOutput.saveModel.prevValue);
        return this.checkQueue().pipe(
          switchMap(() => {
            return throwError(() => err);
          })
        );
      }),
      switchMap(() => {
        this.unSavedChanges
          .get(saveOutput.saveModel.value.parentRowId)
          ?.delete(saveOutput.saveModel?.value?.tableHeaderId);
        saveOutput.saveModel.value.edited = false;
        // if async update is enabled, the changed rows will be updated after the auto-save
        return (isAsync ? of(null) : this.getRowUpdate()).pipe(
          switchMap(() => {
            return this.checkQueue();
          })
        );
      })
    );
  }

  /**
   * handle the auto-save queue and update the changed cells
   */
  private checkQueue(): Observable<any> {
    if (this.saveQueue.length === 0) {
      if (this.changedRowsAfterAutoSave.length > 0) {
        this.updateRowsAfterAutoSave();
      }
      this.setLoadingAnimation(false);
      return of(null);
    }

    if (this.tableTemplate.isEnableAsyncUpdate()) {
      const requests = this.saveQueue.map((i) => this.handleSaveRequest(i, true));
      this.saveQueue = [];
      return forkJoin(requests).pipe(
        switchMap(() => {
          return this.getRowUpdate();
        })
      );
    }
    const saveOutput = this.saveQueue.shift();

    return this.handleSaveRequest(saveOutput, false);
  }

  private updateQueue: string[] = [];
  private rowUpdateSub: Subscription;
  addRow(updateResourceId: string): void {
    this.updateQueue.push(updateResourceId);
    if ((!this.rowUpdateSub || this.rowUpdateSub.closed) && this.updateQueue?.length > 0) {
      this.execAddQueue();
    }
  }

  execAddQueue(): void {
    if (this.updateQueue.length === 0) {
      this.triggerCheckDimensions();
      return;
    }
    const resourceId = this.updateQueue.shift();
    this.rowUpdateSub = this.getRowUpdate(resourceId).subscribe(() => {
      this.execAddQueue();
    });
  }

  /**
   * get the changed rows, added rows and deleted rows from the backend after a cell was autosaved
   * @returns
   */
  private getRowUpdate(updateResourceId?: string) {
    if (!this.tableTemplate.getUpdateRowsRestUrl()) {
      return of(null);
    }

    return this.uiService.getData(this.tableTemplate.getUpdateRowsRestUrl()).pipe(
      tap((response: ITableRowUpdateResponse) => {
        if (
          !response.addedTableRows?.length &&
          !response.changedTableRows?.length &&
          !response.removedTableRows?.length
        ) {
          console.warn('empty row update response', response);
          return;
        }
        this.changedRowsAfterAutoSave = this.handeRowUpdate(response, updateResourceId);
        this.rowDataUtil.calcTableRowsFlatlist();
        this.cd.detectChanges(); // important for filter execution TODO: find better solution
        this.executeSortAndFilter();
        of(null)
          .pipe(delay(0))
          .subscribe(() => {
            if (!this.useTrimRows || !this.rowCollapseMap.size) {
              this.handleNestedRows();
            } else {
              this.spreadSheetInstance.updateSettings({ trimRows: [] }); // reset trim rows
              this.handleNestedRows();
              this.triggerHeightCalculation(); // update height because trim row are displayed
            }
          });
      })
    );
  }

  /**
   * handle the tablerows update from the backend
   * @param rowObject object with changedTableRows,removedTableRows,addedRows as properties
   * @returns chnged row for the handsontable
   */
  private handeRowUpdate(rowObject: ITableRowUpdateResponse, updateResourceId?: string): ISaxMsSpreadsheetRawRow[] {
    let changedRows: ISaxMsSpreadsheetRawRow[] = [];
    let tmpTableRows: ISaxMsSpreadsheetRow[] = [];

    // handle changed table rows)
    if (!updateResourceId || (updateResourceId && this.doesRowExists(updateResourceId))) {
      rowObject.changedTableRows.forEach((row) => {
        this.changeValuesOfRow(row as any);
      });
    }

    changedRows = this.rowDataUtil.getRowDatasets().slice();
    tmpTableRows = this.rowDataUtil.getTableRows().slice();

    // handle removed table rows
    rowObject.removedTableRows.forEach((removeRow) => {
      changedRows = changedRows.filter((row) => row.resourceId !== removeRow.resourceId);
      tmpTableRows = tmpTableRows.filter((row) => row.resourceId !== removeRow.resourceId);
      (this.tableTemplate.getValues() as any).tableRows = (this.tableTemplate.getValues() as any).tableRows.filter(
        (row) => row.resourceId !== removeRow.resourceId
      );
    });

    // handle added table rows
    rowObject.addedTableRows.forEach((addedRow) => {
      const properties = {};
      addedRow.tableRowEntries.forEach((entry, i) => (properties[i] = entry));
      const newRow = {
        children: [],
        resourceId: addedRow.resourceId,
        properties: properties,
      };
      changedRows.push(newRow);
      tmpTableRows.push(addedRow as any);
      (this.tableTemplate.getValues() as any)?.tableRows.push(addedRow);
    });

    this.rowDataUtil.setTableRows(tmpTableRows);
    this.rowDataUtil.setRowDatasets(changedRows);

    // trim unfolded rows to prevent flickering
    if (this.useTrimRows && this.rowCollapseMap.size) {
      const trimRows = [];
      this.rowCollapseMap.forEach((val, key) => {
        if (val && key < tmpTableRows.length) {
          const id = tmpTableRows[key].resourceId;
          tmpTableRows.forEach((elem, i) => {
            if ((elem as any).parentRowId === id) {
              trimRows.push(i);
            }
          });
        }
      });

      this.spreadSheetInstance.updateSettings({ trimRows: trimRows });
    }

    // sort by index
    if (tmpTableRows.length && !isNaN(tmpTableRows[0].index)) {
      // check if index property exists
      tmpTableRows.sort((a, b) => a.index - b.index);
    }

    this.rowDataUtil.buildTableRows(tmpTableRows);
    return changedRows;
  }

  /**
   * update all cells of a given row
   * @param oldRow current row in the table
   * @param changeRow updated row from table
   * @param rerenderCell flag of rerender the cell
   */
  private saveValueOfRowCell(
    oldRow: { [key: string]: ISaxMsSpreadsheetRowEntry },
    newRow: IChangedTableRow,
    rerenderCell: boolean
  ) {
    for (const key in oldRow) {
      const oldRowCell = oldRow[key];
      const newRowCell = newRow.tableRowEntries.find(
        (rowCell) => rowCell.uuid === oldRow[key].uuid || rowCell.uniqueId === oldRow[key].uniqueId
      );
      if (!newRowCell) {
        console.error(
          `Error while assigning a table cell! The UUID or UniqueID of the existing tableRowEntry (${oldRow[key].uuid}, ${oldRow[key].uniqueId}) to be updated, could not be found in the new tableRowEntries dataset.`
        );
        continue;
      }

      const column = this.columnUtil.getColumnById(newRowCell.tableHeaderId);
      let skipCell = false;
      if (this.unSavedChanges.get(newRow.resourceId) && this.unSavedChanges.get(newRow.resourceId).size > 0) {
        skipCell = !!this.unSavedChanges.get(newRow.resourceId)?.get(oldRow[key].tableHeaderId);
      }
      const value = this.rowDataUtil.getValueOfCell(column, newRowCell);

      // update old row
      if (newRowCell && !skipCell) {
        oldRowCell.value = value;
        oldRowCell.edited = false;
        if (newRowCell.textStyle || oldRowCell.textStyle) {
          oldRowCell.textStyle = newRowCell.textStyle;
        }
        if (newRowCell.textAlign || oldRowCell.textAlign) {
          oldRowCell.textAlign = newRowCell.textAlign;
        }
        if (newRowCell.color || oldRowCell.color) {
          oldRowCell.color = newRowCell.color;
        }
        if (newRowCell.foreColor || oldRowCell.foreColor) {
          oldRowCell.foreColor = newRowCell.foreColor;
        }
        if (typeof newRowCell.editable === 'boolean') {
          oldRowCell.editable = newRowCell.editable;
          oldRowCell.rowEditable = newRowCell.editable;
        }
        if (rerenderCell) {
          this.cellRendererUtil.rerenderCell(
            column,
            newRow.resourceId,
            value,
            typeof newRowCell.editable === 'boolean' ? newRowCell.editable : true
          );
        }
      }
    }
  }

  /**
   * handle the update of all cell values of a changed row
   * @param changeRow object of the changed row with the cell values
   */
  private changeValuesOfRow(changeRow: ISaxMsSpreadsheetRawRow): void {
    const foundRow = this.rowDataUtil.getRowDatasets().find((row) => row.resourceId === changeRow.resourceId);
    const foundTableRow = this.rowDataUtil.getTableRows().find((row) => row.resourceId === changeRow.resourceId);

    if (foundRow) {
      this.saveValueOfRowCell(foundRow.properties, changeRow as any, true);
    }
    if (foundTableRow) {
      const newIndex = (changeRow as any).index;
      foundTableRow.index = newIndex;
      foundTableRow.defaultColor = changeRow.defaultColor ?? foundTableRow.defaultColor;
      this.saveValueOfRowCell(foundTableRow.spreadsheetRowEntries, changeRow as any, false);
    }
  }

  /**
   * get all needed plugins from the table
   */
  private getPluginsFromTable() {
    this.spreadSheetFilterService.filtersPlugin = this.spreadSheetInstance.getPlugin('filters');
    this.sortPlugin = this.spreadSheetInstance.getPlugin('multiColumnSorting');
    this.hiddenColumnsPlugin = this.spreadSheetInstance.getPlugin('hiddenColumns');
    this.hiddenRowsPlugin = this.spreadSheetInstance.getPlugin('hiddenRows');
    this.mergePlugin = this.spreadSheetInstance.getPlugin('mergeCells');
    this.autoRowSizePlugin = this.spreadSheetInstance.getPlugin('autoRowSize');
    this.autoColumnSizePlugin = this.spreadSheetInstance.getPlugin('autoColumnSize');
    this.nestedRowsPlugin = this.spreadSheetInstance.getPlugin('nestedRows');
    if (this.rows.length > 0 && this.areNestedRowsInTable()) {
      ((this.nestedRowsPlugin as any).collapsingUI as any)?.collapseAll();
    }
  }

  /**
   * set hooks of handsontable after instance is ready
   * runs registered hooks outside angular to ensure that change detection isn't triggered every frame
   * @returns void
   */
  private addHooks(): void {
    this.spreadSheetInstance.addHook('afterViewRender', this.afterViewRender.bind(this));
    this.spreadSheetInstance.addHook(
      'afterColumnResize',
      this.spreadsheetHooksUtilService.afterColumnResize.bind(this.spreadsheetHooksUtilService)
    );
    this.zone.runOutsideAngular(() => {
      this.spreadSheetInstance.addHook(
        'beforeKeyDown',
        this.spreadsheetHooksUtilService.beforeKeyDown.bind(this.spreadsheetHooksUtilService)
      );
      this.spreadSheetInstance.addHook(
        'beforeCopy',
        this.spreadsheetHooksUtilService.beforeCopy.bind(this.spreadsheetHooksUtilService)
      );
      this.spreadSheetInstance.addHook(
        'beforeOnCellMouseDown',
        this.spreadsheetHooksUtilService.beforeOnCellMouseDown.bind(this.spreadsheetHooksUtilService)
      );
      this.spreadSheetInstance.addHook(
        'afterDeselect',
        this.spreadsheetHooksUtilService.afterDeselect.bind(this.spreadsheetHooksUtilService)
      );
      this.spreadSheetInstance.addHook(
        'afterSelectionEnd',
        this.spreadsheetHooksUtilService.afterSelection.bind(this.spreadsheetHooksUtilService)
      );
      this.spreadSheetInstance.addHook(
        'afterAutofill',
        this.spreadsheetHooksUtilService.afterAutofill.bind(this.spreadsheetHooksUtilService)
      );
      this.spreadSheetInstance.addHook(
        'afterPaste',
        this.spreadsheetHooksUtilService.afterPaste.bind(this.spreadsheetHooksUtilService)
      );
      this.spreadSheetInstance.addHook(
        'afterOnCellMouseOver',
        this.spreadsheetHooksUtilService.afterOnCellMouseOver.bind(this.spreadsheetHooksUtilService)
      );
      this.spreadSheetInstance.addHook('afterOnCellMouseOut', this.removeTooltip.bind(this));
      this.spreadSheetInstance.addHook('afterScrollHorizontally', () => {
        this.scrollHandlerService.triggerHorizontalScroll();
        this.spreadsheetHooksUtilService.afterScrollHorizontally();
      });
      this.spreadSheetInstance.addHook('afterScrollVertically', () => {
        this.scrollHandlerService.triggerVerticalScroll();
        this.scrollToTop(this.wtHolder?.scrollTop || 0);
      });
      this.spreadSheetInstance.addHook(
        'afterColumnMove',
        this.spreadsheetHooksUtilService.afterColumnMoved.bind(this.spreadsheetHooksUtilService)
      );
      this.spreadSheetInstance.addHook(
        'afterFilter',
        this.spreadsheetHooksUtilService.afterFilter.bind(this.spreadsheetHooksUtilService)
      );
      this.spreadSheetInstance.addHook(
        'afterColumnSort',
        this.spreadsheetHooksUtilService.afterColumnSort.bind(this.spreadsheetHooksUtilService)
      );
    });
  }

  /**
   * handle the final closing of the customeditor
   */
  public customEditorClose() {
    if (this.spreadsheetHelperUtil.getCustomEditor().isOpen) {
      this.spreadsheetHelperUtil.getCustomEditor().close();
      // this.afterCustomEditorClose.emit();
    }

    this.cellEditorContext.visibile = false;
  }

  /**
   * handle the customeditor after closing the editor with enter or tab
   */
  private afterCustomEditorClosebyEnter() {
    const column = this.columnUtil.getColumnByIndex(this.editValueObject.getColNumber(), true);
    if (column.entryElement) {
      if (
        column.entryElement.getFieldType() === EFieldType.MULTI_OBJECT_SELECTOR ||
        column.entryElement.getFieldType() === EFieldType.TEXT_FIELD
      ) {
        this.customEditorClose();
        this.spreadSheetInstance.getActiveEditor()?.close();
      }
    } else {
      if (column.fieldType === 'CHECK_BOX') {
        this.saveChangeOfCustomEditor();
      } else {
        this.customEditorClose();
        this.spreadSheetInstance.getActiveEditor()?.close();
      }
    }
  }

  /**
   * shor or hide a specific column
   * @param columnIndex column index of specific column
   * @param visible flag for the visibility of the column
   */
  private hiddenColumn(columnIndex: number, visible: boolean) {
    if (this.spreadSheetInstance && !this.spreadSheetInstance.isDestroyed) {
      if (this.spreadsheetHelperUtil.checkPlugin(ESpreadsheetPlugin.HIDDEN_COLUMNS_PLUGIN, 'hiddenColumns')) {
        if (visible) {
          this.hiddenColumnsPlugin.showColumn(columnIndex);
        } else {
          this.hiddenColumnsPlugin.hideColumn(columnIndex);
        }
      }
    }
  }

  /**
   * update the hidden information of the table and handle the streching for the columns
   */
  private updateHiddenColumnPlugin() {
    if (
      !this.spreadSheetInstance ||
      !this.hiddenColumnsPlugin ||
      !this.hiddenColumnsPlugin.enabled ||
      this.displayedColumns.length === this.hiddenColumnFilterSet.size
    ) {
      return;
    }

    this.hiddenColumns.columns = this.hiddenColumnsPlugin.getHiddenColumns();

    const oneIsVisibile =
      this.columnUtil.getColumns().filter((colum) => !this.hiddenColumns.columns.includes(colum.data)).length > 0;

    let stretchH =
      this.hiddenColumnsPlugin.getHiddenColumns() && this.hiddenColumnsPlugin.getHiddenColumns().length > 0
        ? oneIsVisibile
          ? EStretch.LAST
          : EStretch.NONE
        : EStretch.LAST;

    if (this.currentZoom && this.currentZoom.getContentId() === this.contentId) {
      stretchH = EStretch.NONE;
    }

    if (this.stretch !== stretchH) {
      this.updateSettings({ stretchH });
    }

    this.stretch = stretchH;
    this.updateWidthsOfTheQuicksearchMenu();
    this.rerender();
  }

  /**
   * create a new row without dialog or user inputs over the backend
   * @param templateUrl url of createinline request
   */
  private createInlineRow(templateUrl): Observable<any> {
    return this.uiService.getData(templateUrl).pipe(
      take(1),
      tap((result) => {
        this.systemMessageService.show(
          new Message().setText('TABLE.CREATE.SUCCESS').setType(EMessageType.SUCCESS).setDuration(5000)
        );
        this.refreshSpreadsheetData();
      })
    );
  }

  /**
   * creating a new row for the table with user input through the backend
   */
  private createUseExisting(): Observable<any> {
    if (this.tableTemplate.getValuesRestUrl()) {
      return this.uiService.getData(this.tableTemplate.getValuesRestUrl()).pipe(
        switchMap((result: any) => {
          return this.openSelectorDialog(result).pipe(
            switchMap((dialogData: ISpreadsheetLightboxResult) => {
              if (!dialogData || !dialogData.confirmed) {
                return of(undefined);
              }
              const body = {};
              for (const key in result) {
                if (result[key] === dialogData.value) {
                  body[key] = dialogData.value;
                  break;
                }
              }

              return this.uiService.putData(this.tableTemplate.getValuesRestUrl(), body).pipe(
                take(1),
                tap((data) => {
                  this.systemMessageService.show(
                    new Message().setText('TABLE.CREATE.SUCCESS').setType(EMessageType.SUCCESS).setDuration(5000)
                  );
                  this.refreshSpreadsheetData();
                })
              );
            })
          );
        })
      );
    }

    return of(undefined);
  }

  /**
   * create a new row over a dialog
   * user interaction inside the dialog:
   * -> insert a name
   * -> choose the typ of the resource
   * -> select a tenant
   */
  private createOverNewEntryRestUrls(skip?: boolean): Observable<any> {
    this.createAObject = true;
    if (Object.keys(this.tableTemplate.getCreateNewEntryRestUrls()).length === 1) {
      if (this.tableTemplate.isShowNameDialog()) {
        return this.openSelectorDialog(this.tableTemplate.getCreateNewEntryRestUrls(), true)
          .pipe(take(1))
          .pipe(
            switchMap((dialogData: ISpreadsheetLightboxResult) => {
              if (!dialogData || !dialogData.confirmed) {
                return of(undefined);
              }

              return this.uiService
                .getData(dialogData.value, {
                  name: dialogData.name || '',
                  tenant: dialogData.selectedTenant || '',
                })
                .pipe(
                  take(1),
                  switchMap((newElement) => {
                    this.toEditRow = newElement;
                    // skip or check that the answer is not content for the lightbox, but the new table rows.
                    if (skip || this.isTableRowResponse(newElement)) {
                      this.refreshSpreadsheetData();
                      return of(undefined);
                    }
                    return this.openDialog(newElement);
                  })
                );
            })
          );
      } else {
        return this.openTemplateDialog(
          this.tableTemplate.getCreateNewEntryRestUrls()[Object.keys(this.tableTemplate.getCreateNewEntryRestUrls())[0]]
        );
      }
    } else {
      return this.openSelectorDialog(
        this.tableTemplate.getCreateNewEntryRestUrls(),
        this.tableTemplate.isShowNameDialog()
      ).pipe(
        switchMap((dialogData: ISpreadsheetLightboxResult) => {
          if (!dialogData || !dialogData.confirmed) {
            return of(undefined);
          }
          return this.openTemplateDialog(dialogData.value, dialogData.name, dialogData.selectedTenant);
        })
      );
    }
  }

  /**
   * Checks if a response is a response with structure of table rows.
   */
  private isTableRowResponse(response: object): boolean {
    return Array.isArray(response) && response.length && response[0]?.type === 'TableRow';
  }

  /**
   * open selector dialog
   * @param createRestUrls map of restUrls from which to choose
   * @param hasName whether the name will be shown or not
   * @returns Observable<{ confirmed: boolean; value?: string; name?: string }>
   */
  private openSelectorDialog(
    createRestUrls: { [key: string]: string },
    hasName?: boolean
  ): Observable<ISpreadsheetLightboxResult> {
    return new Observable((observer: Observer<ISpreadsheetLightboxResult>) => {
      const l: NamedLightbox = new NamedLightbox(
        () => this._confirmLightbox(l, observer),
        hasName
          ? this.translate.instant('FORMS.ITEMS.CREATE_BY_VALUE', {
              value: this.tableTemplate.getName(),
            })
          : null,
        Object.keys(createRestUrls).map((key: string) =>
          new EntryElementValue().setName(key).setValue(createRestUrls[key])
        ),
        this.tableTemplate.isNameRequired()
      ).setOnCustomCancelAction(
        new Button().setName('Abbrechen').chainActions(
          new Action().setCb(() => {
            observer.next({ confirmed: false });
            observer.complete();
            return of(null);
          })
        )
      );

      const openLightbox: boolean = Object.keys(createRestUrls).length > 1 || this.tableTemplate.isNameRequired();
      if (this.tableTemplate.isEnableTenantSelection() === true) {
        this.userTenantApi
          .getTenantsForResource()
          .pipe(take(1))
          .subscribe((tenants: Tenant[]) => {
            l.addTenantSelector(tenants);
            if (tenants.length === 0 && !openLightbox) {
              this._confirmLightbox(l, observer).subscribe();
              return;
            }
            this.lightboxApi.open(l);
          });
      } else {
        if (!openLightbox) {
          this._confirmLightbox(l, observer).subscribe();
        } else {
          this.lightboxApi.open(l);
        }
      }
    });
  }

  private _confirmLightbox(l: NamedLightbox, observer: Observer<ISpreadsheetLightboxResult>): Observable<any> {
    const cp = l.getContent().getContentParts()[0];
    if (!cp) {
      return of({ confirmed: true });
    }

    const selectedTenant: string = (cp.getContentElements()[0] as EntryCollection)
      .getEntryElements()
      .find((ec: EntryElement) => ec.getId() === 'tenant-selector')
      ?.getValue<EntryElementValue>()
      .getValue<EntryElementValue>()
      .getValue<string>();

    observer.next({
      confirmed: true,
      value: l.getSelectedValue().getValue<EntryElementValue>().getValue(),
      name: l.getNameValue().getValue<string>(),
      selectedTenant: selectedTenant,
    });
    observer.complete();
    return of(l.getRef()?.close());
  }

  /**
   * fetches template data for a dialog
   * @param templateUrl url for the rest call
   * @param label is the name of the instance of the template
   * @param tenant for the instance of the template
   */
  private openTemplateDialog(templateUrl, label?: string, tenant?: string): Observable<any> {
    return this.uiService.getData(templateUrl, label ? { name: label, tenant: tenant || '' } : {}).pipe(
      switchMap((result) => {
        this.toEditRow = result;
        // result = this.appendButtonsToDialog(result, true);
        return this.openDialog(result);
      })
    );
  }

  /**
   * open dialog from json
   * @param content object which will be parsed to a lightbox
   * @returns void
   */
  private openDialog(content: any): Observable<void> {
    content.type = ETemplateType.LIGHTBOX;
    const lightbox: Lightbox = this.templateAdapter.adapt(content);
    lightbox.setOnCustomCancelAction(
      new Button().setName('BUTTON.cancel').chainActions(new Action().setCb(() => this.deleteEntry()))
    );

    const ref: MatDialogRef<LightboxComponent> = this.lightboxApi.open(lightbox);
    return ref.afterClosed().pipe(
      take(1),
      tap((result) => {
        if (result && result.type === EPredefinedAction.CANCEL_LIGHTBOX) {
          this.templateUiApi.unmarkLightboxFromSave(lightbox.getId(), lightbox.getResourceId());
        }
        this.createAObject = false;
        this.refreshSpreadsheetData();
      })
    );
  }

  /**
   * undoes the creation of a new line
   */
  private deleteEntry(): Observable<void> {
    if (!this.tableTemplate.getValuesRestUrl()) {
      console.error(new Error('table has no valueRestUrl'));
      return of(null);
    }
    return this.uiService.deleteData(this.tableTemplate.getValuesRestUrl(), `id=${this.toEditRow.resourceId}`);
  }

  /**
   * navigate to the current selected row
   * @param rowindex of the current selected row
   */
  private openRowEntry(rowindex: number): void {
    if (this.tableTemplate.getCustomOpenAction()) {
      this.zone.run(() => {
        this.saveDataInSharedUiService(rowindex);
        this.buttonService.executeActions([this.tableTemplate.getCustomOpenAction()]).pipe(take(1)).subscribe();
      });
    }
  }

  /**
   * exports table
   * @param filename name of the file
   * @returns void
   */
  public customExport(filename: string): void {
    let csv = '';

    const columns = this.columnUtil
      .getColumns()
      .filter((column) => !this.hiddenColumnFilterSet.has(column.filterSortObject.getColumnIndex()));
    // add title row to csv
    // this.headers.forEach((header: SaxMsSpreadSheetColumn) => {
    for (const column of columns) {
      csv += `${column.label};`;
    }
    csv += '\n';

    // add rows and theire values to the csv file
    this.spreadSheetInstance.getData().forEach((rowEntries: any[], rowIdx: number) => {
      // const rowEntries: any[] = d.filter((prop: any[], colIndex: number) => { return !this.hiddenColumnFilterSet.has(colIndex); });

      columns.forEach((column: SaxMsSpreadSheetColumn) => {
        const columnIndex = column.data;
        if (column.datatype && column.datatype === ESpreadsheetDatatypes.bool) {
          rowEntries[columnIndex] = this.translate.instant(
            `Table.ExportFormat.${
              JSON.parse(JSON.stringify(rowEntries[columnIndex])) === 'true' ? 'Checked' : 'NoChecked'
            }`
          );
        } else {
          if (column.unit === ESaxMsColumnUnits.percent) {
            rowEntries[columnIndex] = rowEntries[columnIndex];
          } else {
            rowEntries[columnIndex] = this.cellRendererUtil.getVisualValueOfCell(
              rowEntries[columnIndex],
              column,
              false
            );
          }
        }

        let value =
          rowEntries[columnIndex] || rowEntries[columnIndex] === false || rowEntries[columnIndex] === 0
            ? rowEntries[columnIndex].toString()
            : '';

        if (
          column.datatype === ESpreadsheetDatatypes.number ||
          column.datatype === ESpreadsheetDatatypes.long ||
          column.datatype === ESpreadsheetDatatypes.float
        ) {
          // replace . with , for excel view of numbers
          value = (value + '').replaceAll('.', ',');
        }

        value = value.replace(/;/g, ',');
        csv += `${value};`;
      });
      csv += '\n';
    });

    // download the file
    this.saveFile(filename, csv);
  }

  /**
   * open confirm dialog before delete action
   * @param rowIndex index of the targeted row
   * @returns void
   */
  private openDeleteDialog(rowIndex: number): void {
    const l: ConfirmLightbox = new ConfirmLightbox(this.translate.instant('@delete@') + '?').setCustomConfirmAction(
      () => this.deleteRowEntry(rowIndex)
    );

    this.lightboxApi.open(l);
  }

  /**
   * opens a dialog to edit the selected row
   * @param rowIndex indx of the selected row
   */
  private editRowEntry(rowIndex: number): Observable<any> {
    const tableRowsFlatlist = this.rowDataUtil.getTableRowPlainList();
    if (tableRowsFlatlist[rowIndex] && tableRowsFlatlist[rowIndex].editEntryRestUrl) {
      return this.buttonService
        .executeActions([
          new Action()
            .setActionType(EActionType.GET_CALL)
            .setActionUrl(tableRowsFlatlist[rowIndex].editEntryRestUrl)
            .setTemplateHandlingType(ETemplateHandlingType.TEMPLATE_LIGHTBOX),
        ])
        .pipe(
          tap((data) => {
            if (this.createAObject && this.toEditRow.resourceId) {
              const deleteAction: Action = new Action()
                .setActionMethod(ERequestMethod.DELETE)
                .setActionType(EActionType.DELETE_CALL)
                .setActionUrl(this.tableTemplate.getRestUrl())
                .setParams({ id: this.toEditRow.resourceId });

              this.buttonService.executeActions([deleteAction]).subscribe(() => {
                this.refreshSpreadsheetData();
              });
            } else {
              // only do a refresh if it wasn't an abort
              if (data?.type !== EPredefinedAction.CANCEL_LIGHTBOX) {
                this.refreshSpreadsheetData();
              }
            }
            this.createAObject = false;
          })
        );
    }

    console.error('NO editEntryRestUrl for row');
    return of(undefined);
  }

  /**
   * reset all table settings
   * @param data default table settings object from the backend
   */
  private resetAllSettings(data): void {
    for (const column of this.columnUtil.getColumns()) {
      if (
        ((this.spreadSheetFilterService.columnFilterMap.has(column.filterSortObject.getColumnIndex()) ||
          this.spreadSheetFilterService.quickSearchFilterMap.has(column.filterSortObject.getColumnIndex())) &&
          column.filterSortObject.isFilterActive()) ||
        column.filterSortObject.isSortActive()
      ) {
        column.filterSortObject.setSortDirection(ESortDirection.NONE);
        column.filterSortObject.setSortActive(false);
        column.filterSortObject.setFilterActive(false);
        this.sortByColumn(column.filterSortObject.getColumnIndex(), column);
        this.spreadSheetFilterService.clearFilterByColumn(column);
      }
    }
    this.spreadSheetFilterService.columnFilterMap.clear();
    this.spreadSheetFilterService.quickSearchFilterMap.clear();
    this.spreadsheetHelperUtil.clearColumnIndexMap();
    this.resetHiddenColumns();
    this.spreadsheetsettings = {
      id: '',
      columnSettings: [],
      tableID: this.tableTemplate.getId(),
    };
    this.tableTemplate.setSettings({
      id: '',
      columnSettings: [],
      tableID: this.tableTemplate.getId(),
    });
    this.initLoading = true;
    this.changeDataOfSubmenu();
    this.templateApi.updateElements([this.tableTemplate.getId()]);
  }

  /**
   * makes all columns visible again
   */
  private resetHiddenColumns() {
    this.hiddenColumnFilterSet.clear();
    if (this.hiddenColumnsPlugin.getHiddenColumns().length > 0) {
      this.hiddenColumnsPlugin.showColumns(
        this.columnUtil.getColumns().map((col) => {
          return col.data;
        })
      );
    }
    this.hiddenColumns.columns = [];
  }

  /**
   * downloads a csv file with the passed name
   * @param filename name of the file
   * @param data string which will be downloaded
   * @returns void
   */
  private saveFile(filename: string, data: string): void {
    const link: any = this.hiddenLink.nativeElement;
    const blob: Blob = new Blob(['\ufeff', data], { type: 'octet/stream' });
    const url: string = window.URL.createObjectURL(blob);
    link.href = url;
    link.download = `${filename}.csv`;
    link.click();
    window.URL.revokeObjectURL(url);
  }

  /**
   * deletes the selected row in the table
   * @param rowIndex index of the selected row
   */
  private deleteRowEntry(rowIndex: number): Observable<any> {
    const extra = 'id=' + this.rowDataUtil.getTableRowPlainList()[rowIndex].resourceId;
    this.removeMarkedCellsByRowIndex(rowIndex);

    return this.uiService.deleteData(this.tableTemplate.getValuesRestUrl(), extra).pipe(
      catchError((err) => {
        if (err.error.codeException === 10015 && this.tableTemplate.getDeleteAction()) {
          return this.buttonService
            .executeActions([this.tableTemplate.getDeleteAction()])
            .pipe(finalize(() => throwError(() => err)));
        }
        return throwError(() => err);
      }),
      finalize(() => this.refreshSpreadsheetData())
    );
  }

  /**
   * Removes all marked cells which are part of the row which should be deleted from the marked list
   * @param rowIndex index of the row which should be deleted
   */
  private removeMarkedCellsByRowIndex(rowIndex: number) {
    const rowInformation = this.rowDataUtil.getTableRowPlainList()[rowIndex];
    const filteredMarked = (this.templateUiApi.getMarked() || []).filter((m) => {
      if (m.getTemplateId() === this.tableTemplate.getId()) {
        // check if marked entry is part of table
        const key = Object.keys(m.getData())[0];
        const isRowToBeDeleted = m.getData()[key]?.parentRowId === rowInformation?.resourceId;
        return !isRowToBeDeleted;
      }
      return true;
    });

    this.templateUiApi.setMarked(filteredMarked);
  }

  /**
   * scrolls to the autoscroll rowindex
   */
  private autoScrollToIndex(): void {
    if (
      !this.spreadSheetInstance ||
      !this.tableTemplate ||
      this.initiallyScrolled ||
      !this.tableTemplate.getAutoScrollableIndex()
    ) {
      return;
    }

    this.scroll(this.tableTemplate.getAutoScrollableIndex(), true);
    this.initiallyScrolled = true;
  }

  /**
   * scroll viewport to specified row
   * @param row number
   * @param col number
   * @param snapBottom boolean
   * @param snapRight boolean
   * @returns boolean
   */
  private scroll(idx: number, snapBottom?: boolean, snapRight?: boolean): void {
    if (isNaN(idx)) {
      return;
    }

    // of(null).pipe(delay(0)).subscribe(() => {
    if (!this.spreadSheetInstance || this.spreadSheetInstance.isDestroyed) {
      return;
    }

    if (idx === -1) {
      idx = this.spreadSheetInstance.countRows() - 1;
    }

    this.spreadSheetInstance.scrollViewportTo(idx, 0, snapBottom, snapRight);
    // });
  }

  /**
   * checks the status of columns and buttons after the editmode has changed
   */
  private enableCellEdit() {
    this.columnUtil.getColumns().forEach((column) => {
      column.readOnly = this.columnUtil.getReadOnly(column);
    });
    this.setColumns(this.columnUtil.getColumns());
    of(null)
      .pipe(delay(0))
      .subscribe(() => {
        this.executeSortAndFilter();
      });

    this.changeDataOfSubmenu();
  }

  /**
   * applies the sorting and filter
   */
  private executeSortAndFilter() {
    // return;
    if (this.spreadSheetInstance && !this.spreadSheetInstance.isDestroyed) {
      const sortConfig = this.getSortConfig();
      if (sortConfig.length > 0) {
        this.executeSortPlugin(sortConfig);
      }
      if (this.spreadSheetFilterService.filterConditions.length > 0) {
        // if (this.spreadsheetHelperUtil.checkPlugin('filtersPlugin', 'filters')) {
        this.spreadSheetFilterService.filterConditions.forEach((fc) => {
          fc.conditions.forEach((condition) => {
            this.spreadSheetFilterService.filtersPlugin.addCondition(
              fc.column,
              condition.name,
              condition.args,
              fc.operation
            );
          });
        });
        this.spreadSheetFilterService.executeFilter();
        // }
      }
    }
  }

  /**
   * calcute the height of the hori scrollbar for a passed table html container
   * @param tableContainer html container of the table
   * @returns height of the hori scrollbar
   */
  public getScrollbarWidth(tableContainer: HotTableComponent, mode: 'horizontal' | 'vertical' = 'horizontal'): number {
    if (tableContainer) {
      const elem: HTMLElement = tableContainer.container.nativeElement;
      if (!elem) {
        return 2;
      }
      const master: Element = elem.getElementsByClassName('ht_master')[0];
      if (!master) {
        return 2;
      }
      const holder: HTMLElement = master.getElementsByClassName('wtHolder')[0] as HTMLElement;
      if (mode === 'horizontal') {
        return holder.offsetHeight - holder.clientHeight + 2;
      } else {
        return holder.offsetWidth - holder.clientWidth + 2;
      }
    }

    return 2;
  }

  /**
   * returns the value of a cell with the given coordinates
   * @param coords of the cell
   * @returns the value of a cell with the given coordinates
   */
  private getPrevValue(coords: Record<string, any>): any {
    return this.rowDataUtil.getTableRowPlainList()[coords.rowNumber].spreadsheetRowEntries[coords.columnNumber].value;
  }

  /**
   * updates the information about the hidden or displayed rows
   * @param rowIndices add/delete to/from the hiddenrowset
   * @param visible flag for the decision whether to add or delete
   * @returns visual rowIndexes from the input rowIndexes
   */
  private handleHiddenRowsMap(rowIndices: number[], visible: boolean, executerId: string): void {
    rowIndices.forEach((rowIndex) => {
      const entry = this.hiddenRowsMap.get(rowIndex);
      if (visible && entry && entry.includes(executerId)) {
        this.hiddenRowsMap.set(
          rowIndex,
          entry.filter((id) => id !== executerId)
        );
        if (!this.hiddenRowsMap.get(rowIndex).length) {
          this.hiddenRowsMap.delete(rowIndex);
        }
      } else if (!visible) {
        if (entry) {
          if (!entry.includes(executerId)) {
            entry.push(executerId);
          }
        } else {
          this.hiddenRowsMap.set(rowIndex, [executerId]);
        }
      }
    });
  }

  private getVisibilityOfRowIndices(): { visible: number[]; hidden: number[] } {
    const visible = [];
    const hidden = [];
    this.rows.forEach((row, index) => {
      if (this.hiddenRowsMap.has(index)) {
        const visualIndex = this.spreadsheetHelperUtil.getVisualRowIndex(index);
        if (visualIndex !== null) {
          hidden.push(visualIndex);
        }
      } else {
        const visualIndex = this.spreadsheetHelperUtil.getVisualRowIndex(index);
        if (visualIndex !== null) {
          visible.push(visualIndex);
        }
      }
    });
    return { visible, hidden };
  }

  /**
   * check if exists a linked column for the edited column
   * true -> manipulate the cell-value of the linked column
   * @param value
   * @returns
   */
  private validateDateLinks(column: SaxMsSpreadSheetColumn, physRowIndex: number, value: number, cell: any): void {
    const dateLinks = this.tableTemplate
      .getLinks()
      .filter((link) => {
        if (
          link instanceof StartAndEndTimeLink ||
          link instanceof ShiftTimeLink ||
          link instanceof MaxDurationWarningLink
        ) {
          return link.getStartId() === column.id || link.getEndId() === column.id;
        }
        return false;
      })
      .map((link) => link as StartAndEndTimeLink | ShiftTimeLink | MaxDurationWarningLink);

    dateLinks.forEach((link) => {
      // const link = this.getDatepickerLink(this.cellEditorContext.column.id, [linkString]);
      if (!link) {
        return;
      }

      const row = this.rowDataUtil.getRowDatasets()[physRowIndex];
      const physRow = this.rowDataUtil.getTableRowPlainList()[physRowIndex];
      const cells = Object.values(physRow.spreadsheetRowEntries);
      const startColumn = this.columnUtil.getColumnById(link.getStartId());
      const startCell = cells.find((cell) => cell.tableHeaderId === startColumn.id);
      const endColumn = this.columnUtil.getColumnById(link.getEndId());
      const endCell = cells.find((cell) => cell.tableHeaderId === endColumn.id);

      if (link instanceof MaxDurationWarningLink && !isNaN(value) && !isNaN(startCell.value) && !isNaN(endCell.value)) {
        const plainStartData = this.rowDataUtil.getDatasetPlainlist()[physRowIndex][startColumn.data];
        const plainEndData = this.rowDataUtil.getDatasetPlainlist()[physRowIndex][endColumn.data];
        if (link.isValid(startCell.value, endCell.value)) {
          if (plainStartData) plainStartData.isInvalid = false;
          if (plainEndData) plainEndData.isInvalid = false;
        } else {
          if (plainStartData) plainStartData.isInvalid = true;
          if (plainEndData) plainEndData.isInvalid = true;
        }
      }

      if (link.getStartId() === column.id) {
        if (!isNaN(value) && value > endCell.value && value !== endCell.value) {
          const diff = value - cell.prevValue;
          let newEnd = (endCell.value || 0) + diff;
          if (newEnd < value) {
            newEnd += value;
          }

          this.editCellAfterValidateLink(
            endColumn,
            row,
            link instanceof StartAndEndTimeLink ? value : newEnd,
            !this.columnUtil.getReadOnly(endColumn) || endCell.rowEditable,
            endCell
          );
        }
      }

      if (link.getEndId() === column.id) {
        if (!isNaN(value) && (!value || value < startCell.value) && value !== startCell.value) {
          const diff = value - cell.prevValue;
          let newStart = (startCell.value || 0) + diff;
          if (newStart > value) {
            newStart -= value;
          }
          this.editCellAfterValidateLink(
            startColumn,
            row,
            link instanceof StartAndEndTimeLink ? value : newStart,
            !this.columnUtil.getReadOnly(startColumn) || startCell.rowEditable,
            startCell
          );
        }
      }
    });
  }

  /**
   * searches if there is a linked datepicker for a column
   * @param colId id of the column
   * @param types array with the linked type of the datepicker
   * @returns if there is a link it will be returned
   */
  private getDatepickerLink(colId: string, types: string[] = ['start', 'end']): StartAndEndTimeLink {
    return this.tableTemplate.getLinks().find((link: ALink) => {
      if (link instanceof StartAndEndTimeLink) {
        if (types.indexOf('start') !== -1) {
          if (types.indexOf('end') !== -1) {
            return link.getStartId() === colId || link.getEndId() === colId;
          } else {
            return link.getStartId() === colId;
          }
        } else if (types.indexOf('end') !== -1) {
          return link.getEndId() === colId;
        }
      }
    }) as StartAndEndTimeLink;
  }

  /**
   * manipulate the cell-value of a linked column
   * @param column destination linked column
   * @param row edited row
   * @param value value of the source linked cell
   * @param editable flag for is the cell editable
   * @param cell specific
   * @returns
   */
  private editCellAfterValidateLink(
    column: SaxMsSpreadSheetColumn,
    row: ISaxMsSpreadsheetRawRow,
    value: any,
    editable: boolean,
    cell: ISaxMsSpreadsheetRowEntry
  ): void {
    if (this.columnUtil.getReadOnly(column) || !cell.rowEditable) {
      return;
    }
    const rowIndex = this.rowDataUtil.getRowDatasets().indexOf(row);
    const prevValue = this.getCurrentValueOfCell(rowIndex, column.filterSortObject.getColumnIndex());
    if (value === cell.value) {
      return;
    }
    const finalValue = value <= 0 ? undefined : value;

    if (finalValue === undefined) {
      return;
    }

    cell.value = finalValue;
    cell.edited = true;
    this.cellRendererUtil.rerenderCell(column, row.resourceId, finalValue, editable);
    const entryElement: SaxmsRowEntryElement = new SaxmsRowEntryElement()
      .setValue(cell)
      .setPrevValue(prevValue)
      .setColNumber(column.data)
      .setRowNumber(rowIndex);

    this.onTableCellEdit([entryElement]);
  }

  /**
   * get the mindate of the picker, if the column has a linked other column
   * @returns
   */

  private getMinDateOfPicker(column: SaxMsSpreadSheetColumn): Date {
    if (!column) {
      return;
    }

    const link = this.getDatepickerLink(column.id, ['end']);
    if (
      (column.fieldType !== EFieldType.DATE_TIME_PICKER &&
        column.fieldType !== EFieldType.DATE_PICKER &&
        column.fieldType !== EFieldType.TIME_PICKER) ||
      !link
    ) {
      return;
    }

    const startColumn = this.columnUtil.getColumnById(link.getStartId());
    const relatedCell =
      this.rowDataUtil.getTableRowPlainList()[this.editValueObject.getRowNumber()].spreadsheetRowEntries[
        startColumn.data
      ];

    return !this.columnUtil.getReadOnly(column) || relatedCell.rowEditable ? new Date(relatedCell.value) : null;
  }

  /**
   * get the maxdate of the picker, if the column has a linked other column
   * @returns
   */
  private getMaxDateOfPicker(column: SaxMsSpreadSheetColumn): Date {
    if (!column) {
      return;
    }
    const link = this.getDatepickerLink(column.id, ['start']);
    if (
      (column.fieldType !== EFieldType.DATE_TIME_PICKER &&
        column.fieldType !== EFieldType.DATE_PICKER &&
        column.fieldType !== EFieldType.TIME_PICKER) ||
      !link
    ) {
      return;
    }

    const endColumn = this.columnUtil.getColumnById(link.getEndId());
    const relatedCell =
      this.rowDataUtil.getTableRowPlainList()[this.editValueObject.getRowNumber()].spreadsheetRowEntries[
        endColumn.data
      ];

    return !this.columnUtil.getReadOnly(column) || relatedCell.rowEditable ? new Date(relatedCell.value) : null;
  }

  /**
   * checks which lines need to be hidden or shown after there has been an interaction with the legend
   */
  private handleColorLegend() {
    const disabledColors = this.colorClassSet.filter((color) => !color.isActive).map((color) => color.id);
    const rows = this.rowDataUtil.getRowDatasets();
    const hiddenRows = rows
      .filter((row) => disabledColors.includes(row.properties[0].defaultColorClass))
      .map((row) => rows.indexOf(row));
    const showRows = rows
      .filter((row) => !disabledColors.includes(row.properties[0].defaultColorClass))
      .map((row) => rows.indexOf(row));
    this.handleVisibilityOfRows(showRows, hiddenRows, 'colorLegend');
    this.rerender(true);
    this.triggerHeightCalculation();
  }

  /**
   * update the quicksearch elements in the toolbar
   */
  private updateWidthsOfTheQuicksearchMenu() {
    let leftPosition = 0;
    this.columnUtil
      .getColumns()
      .filter((column) => !this.hiddenColumnFilterSet.has(column.filterSortObject.getColumnIndex()))
      .forEach((column) => {
        const width = this.spreadSheetInstance.getColWidth(column.data);
        const elem = this.toolbar?.quicksearchMenu
          ?.getToolbarGroups()[0]
          ?.getEntryElements()
          .find(
            (element) =>
              element.getId() ===
              `${this.tableTemplate.getId()}_quicksearch_${column.filterSortObject.getColumnIndex()}`
          )
          ?.setWidth(width - 10);

        // adjust sticky left position
        if (elem?.isSticky()) {
          elem.setStickyLeft(leftPosition);
        }

        leftPosition += width;
      });
    of(null)
      .pipe(delay(0))
      .subscribe(() => {
        this.adjustWidthOfLastColumn();
      });
  }

  // Public Area

  /**
   *deletes all information whether nestedrows are expanded or not
   */
  public clearRowCollapseMap(): this {
    this.rowCollapseMap.clear();
    return this;
  }

  /**
   * Updates the information if a row with child row should be expanded or collapsed
   * Adds a new row entry if row index does not exist.
   * @param rowIndex index o fthe parent row
   * @param collapse status of collapsed or not
   */
  public addRowToCollapseMap(rowIndex: number, collapse?: boolean): this {
    if (this.rowCollapseMap.get(rowIndex) !== undefined) {
      // entry exists
      if (collapse !== undefined) {
        // new value exists
        this.rowCollapseMap.set(rowIndex, collapse);
      }
      return this;
    } else {
      this.rowCollapseMap.set(rowIndex, collapse === undefined ? true : collapse);
    }
    return this;
  }

  private areNestedRowsInTable(): boolean {
    return this.rowCollapseMap.size > 0;
  }

  /**
   * remove the tooltip of a cell
   */
  public removeTooltip(): void {
    this.cellMouseOverSub.next();
    if (!this.isTooltipVisible) {
      return;
    }
    this.isTooltipVisible = false;
    this.setTooltip(null, true);
  }

  /**
   * returns the current value of a cell
   * @param rowIndex of the cell
   * @param colIndex of the cell
   * @returns current value of the cell
   */
  public getCurrentValueOfCell(rowIndex: number, colIndex: number): any {
    return this.rowDataUtil.getDatasetPlainlist()[rowIndex][colIndex].value;
  }

  /**
   * updates the columns within the table and checks if the scaling of the text size
   * @param columns new columns for the table
   * @param skipAutoScale flag to skip the scaling check
   */
  public setColumns(columns: SaxMsSpreadSheetColumn[], skipAutoScale?: boolean, render = true): void {
    this.displayedColumns = columns.slice();

    if (this.initFinished && this.tableTemplate.isFontSizeAutoScale() && !skipAutoScale) {
      this.spreadsheetHelperUtil.calcFontSizeAutoScale(
        this.columnUtil.getColumns(),
        this.rowDataUtil.getDisplayRowsFlatList()
      );
      this.spreadsheetHelperUtil.updateFontSize(this.columnUtil.getColumns());
      this.cellRendererUtil.setFontSizeOfCell(this.spreadsheetHelperUtil.getNumberValueFontsize());
    }

    if (this.spreadSheetInstance && render) {
      this.rerender(true);
    }
  }

  /**
   * checks the width of the last column and adjusts the width of the quick search of the last column
   */
  public adjustWidthOfLastColumn(): void {
    if (!this.spreadSheetInstance || this.spreadSheetInstance.isDestroyed) {
      return;
    }
    const visibleColumns = this.columnUtil
      .getColumns()
      .filter((column) => !this.hiddenColumnFilterSet.has(column.filterSortObject.getColumnIndex()));
    if (visibleColumns.length === 0) {
      return;
    }
    const column: SaxMsSpreadSheetColumn = this.columnUtil.getColumnByIndex(
      visibleColumns[visibleColumns.length - 1].data,
      true
    );
    if (!column) {
      return;
    }

    const columnTd = this.spreadSheetInstance.getCell(-1, column.data);
    if (columnTd) {
      this.toolbar?.quicksearchMenu
        ?.getToolbarGroups()[0]
        ?.getEntryElements()
        .find(
          (element) =>
            element.getId() === `${this.tableTemplate.getId()}_quicksearch_${column.filterSortObject.getColumnIndex()}`
        )
        ?.setWidth(columnTd.clientWidth - 10);
    }
  }

  /**
   * update the width of the grid start
   * @param width width of the grid start (in most the first foremost columns)
   */
  public setGridStart(width: number): void {
    this.widgetGridLayoutService.setGridStart(this.contentId, width);
  }

  /**
   * update the merged cells
   */
  public recalculateMergedCells(): void {
    this.mergePlugin.clearCollections();
    if (this.spreadSheetInstance?.getData().length > 0) {
      this.rowDataUtil.calcMergedCells();
      this.mergeRows();
    }
  }

  /**
   * TODO:Sebatian
   * @returns
   */
  hasOverflow(): boolean {
    if (!this.wrappingContainer) {
      return false;
    }
    const elem: HTMLElement = this.wrappingContainer.nativeElement;
    if (!elem) {
      return false;
    }
    const master: Element = elem.getElementsByClassName('ht_master')[0];
    if (!master) {
      return false;
    }
    const holder: HTMLElement = master.getElementsByClassName('wtHolder')[0] as HTMLElement;
    const spreader: HTMLElement = master.getElementsByClassName('saxms-spreadsheet')[0] as HTMLElement;
    if (!holder || !spreader) {
      return false;
    }
    return spreader.clientWidth > holder.clientWidth ? true : false;
  }

  private getTableProportions(): DOMRect | undefined {
    if (this.spreadSheet) {
      const coreTableElem: HTMLTableElement | undefined = this.spreadSheet.container.nativeElement
        ?.getElementsByClassName('ht_master')[0]
        ?.getElementsByClassName('htCore')[0];
      return coreTableElem?.getBoundingClientRect();
    }
  }

  private tableHeightCalculation(): Observable<void> {
    return this.calculateTableHeight$.pipe(
      throttleTime(500, undefined, { leading: true, trailing: true }),
      switchMap((options) => {
        if (
          !this.spreadsheetHelperUtil?.getSpreadSheetInstance() ||
          this.spreadsheetHelperUtil?.getSpreadSheetInstance().isDestroyed ||
          !this.isAlive() ||
          !this.spreadSheetInstance
        ) {
          return of(void 0);
        }

        if (this.tableTemplate?.isFontSizeAutoScale()) {
          this.spreadsheetHelperUtil.updateFontSize(this.columnUtil.getColumns());
          this.cellRendererUtil.setFontSizeOfCell(this.spreadsheetHelperUtil.getNumberValueFontsize());
        }

        const tableProportions = this.getTableProportions();
        const maxWidth = this.spreadSheet?.container?.nativeElement?.offsetWidth || 0;
        const currentTableHeight = tableProportions?.height || 0;
        const currentTableWidth = tableProportions?.width - 1 || 0;
        let isHorizontalOverflow = currentTableWidth > maxWidth;
        let isVerticalOverflow = currentTableHeight > this.maxHeight;
        let adaptTableHeight = currentTableHeight;
        let adaptTableWidth = currentTableWidth;

        if (isHorizontalOverflow || isVerticalOverflow) {
          const isHorizontalOverflowBefore = isHorizontalOverflow;
          const isVerticalOverflowBefore = isVerticalOverflow;

          if (isHorizontalOverflow) {
            adaptTableHeight += SCROLLBAR_HEIGHT;
            isVerticalOverflow = adaptTableHeight > this.maxHeight;
          }

          if (isVerticalOverflow) {
            adaptTableWidth += SCROLLBAR_HEIGHT;
            isHorizontalOverflow = adaptTableWidth > maxWidth;
          }

          if (isHorizontalOverflow !== isHorizontalOverflowBefore) {
            adaptTableHeight += SCROLLBAR_HEIGHT;
            isVerticalOverflow = adaptTableHeight > this.maxHeight;
          }

          if (isVerticalOverflow !== isVerticalOverflowBefore) {
            adaptTableWidth += SCROLLBAR_HEIGHT;
            isHorizontalOverflow = adaptTableWidth > maxWidth;
          }
        }
        let newTableHeight = currentTableHeight;

        if (adaptTableHeight >= this.minHeight && adaptTableHeight <= this.maxHeight) {
          newTableHeight = adaptTableHeight;
        } else if (adaptTableHeight < this.minHeight) {
          newTableHeight = this.minHeight;
        } else if (adaptTableHeight > this.maxHeight) {
          newTableHeight = this.maxHeight;
        }

        this.noScrollbarX = !isHorizontalOverflow;
        this.noScrollbarY = !isVerticalOverflow;
        return of({ ...options, newTableHeight });
      }),
      switchMap((params) => {
        if (params) {
          return this.setTableHeight(params.newTableHeight, params.skipRerender, params.force);
        } else {
          return of(void 0);
        }
      })
    );
  }

  /**
   * Listens to dimension changes and triggers a check of the dimensions.
   * @returns An Observable that emits when the dimensions need to be checked.
   */
  protected listenToDimensionChanges() {
    return this.triggerCheckDimensions$.pipe(
      takeUntil(this.ngOverrideRowUpdate),
      throttleTime(200, undefined, { leading: false, trailing: true }),
      tap(() => this.checkDimensions())
    );
  }

  /**
   * calculate the tableheight by the visibile rows
   * @param rowCountInput count of rows
   */
  public triggerHeightCalculation(skipRerender = false, force = false): void {
    this.calculateTableHeight$.next({ skipRerender, force });
  }

  /**
   * Triggers a check of the dimensions of the spreadsheet.
   */
  private triggerCheckDimensions(): void {
    this.triggerCheckDimensions$.next(void 0);
  }

  /**
   * DEBUG FUNCTTION
   * @param t
   */
  update(t: number): void {
    if (t === 1) this.spreadSheetInstance.render();
    if (t === 2) this.cd.detectChanges();
    if (t === 3) this.triggerHeightCalculation();
    if (t === 4) this.adjustWidthOfLastColumn();
    if (t === 5) {
      const stretchH: EStretch = EStretch.NONE;
      this.updateSettings({ stretchH });
    }
    if (t === 6) {
      const stretchH: EStretch = EStretch.ALL;
      this.updateSettings({ stretchH });
    }
    if (t === 7) {
      const stretchH: EStretch = EStretch.LAST;
      this.updateSettings({ stretchH });
    }
    if (t === 8) {
      this.templateApi.refreshElementData([this.tableTemplate.getId()]);
    }
    if (t === 9) {
      this.initTableSettings();
    }
    if (t === 10) {
      this.resetOverflow();
    }
  }

  /**
   * resets values for overflow calculation
   * @retuns void
   */
  resetOverflow(): void {
    this.noScrollbarY = this.noScrollbarX = false;
  }

  /**
   * saves the passed setting of the table
   * @returns when the saving is finished
   */
  public saveSettings(): Observable<any> {
    if (!this.blockedSaveSettings && this.tableTemplate.isSaveSettings() === true) {
      // check if table ID is set
      if (!this.spreadsheetsettings.tableID) {
        if (this.tableTemplate.getId()) {
          this.spreadsheetsettings.tableID = this.tableTemplate.getId();
        } else {
          console.error('Table ID not set, cannot be saved!');
          return of(null);
        }
      }

      return this.spreadsheetSettingsService
        .saveSpreadsheetSettings(this.spreadsheetsettings)
        .pipe(debounceTime(300))
        .pipe(
          switchMap((data) => {
            if (data) {
              this.tableTemplate?.setSettings(data);
            }
            return of(data);
          })
        );
    } else {
      return of(null);
    }
  }

  /**
   * add new columns settings to the tables settings
   * @param columnSetting new columnsettings for the table settings
   */
  public addColumnSettingsToTableSettings(columnSetting: SpreadsheetColumnSettings): void {
    this.spreadsheetsettings.columnSettings.push(columnSetting);
  }

  /**
   * searches the column settings for a specific column within the table settings
   * If there are no column settings then default settings are created for the column
   * @param columnIndex index of the column
   * @returns column settings from the tablesetting or default column settings
   */
  public getColumnSettings(column: SaxMsSpreadSheetColumn): {
    createNew: boolean;
    column: SpreadsheetColumnSettings;
  } {
    // const column = this.columnUtil.getColumns().find(h => h.filterSortObject.getColumnIndex() === columnIndex);
    if (!column || !this.spreadsheetsettings) {
      return;
    }
    for (const saveColumn of this.spreadsheetsettings.columnSettings) {
      if (column.id === saveColumn.columnID) {
        return {
          createNew: false,
          column: saveColumn,
        };
      }
    }

    const columnSetting: SpreadsheetColumnSettings = {
      tableID: this.tableTemplate.getId(),
      columnID: column.id,
    };

    return {
      createNew: true,
      column: columnSetting,
    };
  }

  /**
   * handle the double click action on the row headers
   * @param rowIndex row index
   */
  public handleDoubleClick(rowIndex: number): void {
    this.openRowEntry(rowIndex);
  }

  public handleHeaderDblClick(col: number): void {
    const column = this.columnUtil.getColumns().find((column) => column.data === col);

    let direction: ESortDirection = ESortDirection.NONE;
    switch (column.filterSortObject.getSortDirection()) {
      case ESortDirection.ASC:
        direction = ESortDirection.DESC;
        break;
      case ESortDirection.DESC:
        direction = ESortDirection.NONE;
        break;
      default:
        direction = ESortDirection.ASC;
        break;
    }
    column.filterSortObject.setSortDirection(direction);
    column.filterSortObject.setSortActive(direction !== ESortDirection.NONE);

    if (column) {
      this.sortByColumn(col, column);
      this.saveAfterSort(column);
    }
  }

  /**
   * stores row information in the table template so that the backend can access it
   * @param rowIndex index of the row
   */
  public saveDataInSharedUiService(rowIndex: number): void {
    // this.sharedUiService.resourceIds[this.tableTemplate.id] = {};
    const resourceObject = {
      canonicalName: '',
      resourceId: '',
    };
    if (this.rowDataUtil.getTableRowPlainList().length === 0) {
      return;
    }
    for (const key in resourceObject) {
      resourceObject[key] = this.rowDataUtil.getTableRowPlainList()[rowIndex][key];
    }

    const rowCount: number = Array.from(
      this.selectionModel.selectionColumns.get(Array.from(this.selectionModel.selectionColumns.keys())[0])
    ).length;
    this.tableTemplate.addSelectedValue(resourceObject.resourceId, resourceObject.canonicalName);
    if (rowCount === 1) {
      this.tableTemplate.setSelectedValue(resourceObject);
    }
  }

  /**
   * updates the different toolbar menus
   */
  public changeDataOfSubmenu(): void {
    this.changeDataOfFilterSubmenuElement();
    this.changeDataOfQuickSearch();
    this.changeDataOfSelectionColumn();
  }

  /**
   * updates the information for the initial selection
   * @param selectionInformation new selection information
   */
  public refreshSelectionInformation(selectionInformation: SelectionInformation): void {
    this.widgetGridLayoutService.refreshSelectionInformation(this.tableTemplate.getId(), selectionInformation);
  }

  /**
   * trigger the render-function of the table
   * @param force flag so that the render-function is executed immediately and not within a timeout
   */
  public rerender(force?: boolean): void {
    if (!this.spreadSheetInstance || this.spreadSheetInstance.isDestroyed || !this.initFinished) {
      return;
    }
    this.renderTimeoutId.next();
    if (force) {
      this.spreadSheetInstance.render();
    } else {
      of(null)
        .pipe(delay(200), takeUntil(this.renderTimeoutId))
        .subscribe(() => {
          this.spreadSheetInstance.render();
        });
    }
  }

  /**
   * set and show the tooltip information
   * @param tooltip information for the tooltip
   * @returns void
   */
  public setTooltip(obj: ITooltip, remove?: boolean, el?: HTMLElement): void {
    this.zone.run(() => {
      if (el && obj) {
        this.isTooltipVisible = true;
        this.tooltipService.createTooltip(el, 500, {
          text: this.convertTooltip(obj),
        });
      } else {
        this.tooltipService.closeTooltip();
      }
    });
  }

  /**
   * Converts a passed tooltip object to a resulting string
   */
  private convertTooltip(tooltip: ITooltip): string {
    let result = '';
    let value = '';
    const hint = tooltip.hint;
    const error = tooltip.error;
    if (typeof tooltip.value == 'boolean') {
      // boolean
      value = this.translate.instant(`FILTER.${tooltip.value ? 'TRUE' : 'FALSE'}`);
    } else {
      // string|number
      let string = tooltip.value;
      if (!(string === null || string === undefined)) {
        string += ''; // if string is a number
        if (string.includes(';')) {
          // check for list
          let list = '';
          string.split(';').forEach((elem) => (list += `${elem}<br>`));
          value = list;
        } else {
          value = string;
        }
      }
    }
    result += error;
    if (error) {
      result += '<br>';
    }
    result += hint;
    if (hint) {
      result += '<br>';
    }
    result += value;

    return result;
  }

  /**
   * empties the selected cells after the del button has been pressed
   */
  public afterDeleteKey(): void {
    this.currentValueOfCustomEditor = null;
    const changedEntryElements: SaxmsRowEntryElement[] = [];
    const range = this.spreadSheetInstance.getSelectedRangeLast();
    // range for rows
    const rowRange: number[] = this.spreadsheetHelperUtil.range(range.from.row, range.to.row);
    // range for columns
    const columnRange: SaxMsSpreadSheetColumn[] = this.spreadsheetHelperUtil
      .range(range.from.col, range.to.col)
      .map((columnIndex) => this.columnUtil.getColumnByIndex(columnIndex, true));

    columnRange.forEach((column: SaxMsSpreadSheetColumn) => {
      if (column.readOnly || !column.nullable) {
        return;
      }
      rowRange.forEach((rowIndex: number) => {
        // get physical row index
        const phyRowIndex = this.spreadsheetHelperUtil.getRowIndex(rowIndex);
        const cellValues = Object.values(this.rowDataUtil.getDatasetPlainlist()[phyRowIndex]);
        const cellValue = cellValues.find((value) => value.tableHeaderId === column.id);

        if (cellValue?.editable !== false) {
          this.changeValueOfEditor(phyRowIndex, column, null, false);

          const cellEntryElement: SaxmsRowEntryElement = new SaxmsRowEntryElement()
            .setPrevValue(cellValue)
            .setValue(cellValue)
            .setColNumber(column.data)
            .setRowNumber(phyRowIndex)
            .setAutoSave(column.autoSaveCell);

          changedEntryElements.push(cellEntryElement);
        }
      });
    });

    this.onTableCellEdit(changedEntryElements);
    // if (this.editValueObject.getEntryElement()) {
    //   this.editValueObject.getEntryElement().getValue<EntryElementValue>().setValue(null);
    // }
    // this.saveChangeOfCustomEditor();
  }

  /**
   * sets the editor information for the cell currently being edited
   * @param coords =>
   * {
   *  rowNumber: row index of the cell,
   *  columnNumber: col index of the cell,
   *  savedKey: key with which the editor was opened
   * }
   */
  public setEditCell(coords: { rowNumber: number; columnNumber: number; savedKey?: any }): void {
    if (!coords) {
      this.editValueObject = null;
      return;
    }
    const column = this.columnUtil.getColumnByIndex(coords.columnNumber, true);
    const cellValues = Object.values(this.rowDataUtil.getTableRowPlainList()[coords.rowNumber].spreadsheetRowEntries);
    const cellValue = cellValues.find((value) => value.tableHeaderId === column.id);
    let columnEntryElement;
    this.openOverInitKey = !!coords.savedKey;

    if (column && column.entryElement) {
      columnEntryElement = column.entryElement;
      if (coords.savedKey) {
        if (column.fieldType === EFieldType.TEXT_FIELD || column.fieldType === EFieldType.TEXT_AREA) {
          if (this.spreadsheetHelperUtil.isNumberField(column)) {
            // set the init key for numeric inputs
            (columnEntryElement as NumericInput).setSelectedIndex(1);
            columnEntryElement.setValue(new EntryElementValue());
          } else {
            // set the init key for text inputs
            columnEntryElement.setValue(new EntryElementValue().setValue(coords.savedKey));
          }
        } else {
          columnEntryElement.setValue(
            new EntryElementValue().setValue(new EntryElementValue().setName(coords.savedKey).setValue(coords.savedKey))
          );
        }
      } else {
        if (column.fieldType === EFieldType.COMBO_BOX) {
          const value = cellValues.find((value) => value.tableHeaderId === column.id)?.value;
          if (value instanceof EntryElementValue) {
            columnEntryElement.setValue(new EntryElementValue().setValue(value));
          } else {
            columnEntryElement.setValue(
              new EntryElementValue().setValue(new EntryElementValue().setName(value).setValue(value))
            );
          }
        } else {
          columnEntryElement.setValue(
            new EntryElementValue().setValue(cellValues.find((value) => value.tableHeaderId === column.id)?.value)
          );
        }
      }
    }

    this.editValueObject = new SaxmsRowEntryElement()
      .setEntryElement(columnEntryElement)
      .setColNumber(coords.columnNumber)
      .setRowNumber(coords.rowNumber)
      .setPrevValue(this.getPrevValue(coords))
      .setInitKey(coords.savedKey)
      .setValue(cellValue)
      .setMinDate(this.getMinDateOfPicker(column))
      .setMaxDate(this.getMaxDateOfPicker(column));

    this.currentValueOfCustomEditor = this.editValueObject.getValue().value;
  }

  /**
   * ensures that the quick search always scrolls synchronously with the table
   * @param scrollLeft horizental scroll position
   */
  public scrollToLeft(scrollLeft: number): void {
    this.widgetGridLayoutService.setHorizontalScrollSync(this.tableTemplate.getId(), scrollLeft);
    this.scrollLeft = scrollLeft;
    if (this.toolbar?.getActiveMenuItem()?.getId() === this.toolbar?.quicksearchMenu?.getId()) {
      this.toolbar?.setScrollLeft(this.scrollLeft);
    }
  }

  public scrollToTop(scrollTop: number): void {
    this.widgetGridLayoutService.setVerticalScrollSync(this.tableTemplate.getId(), scrollTop);
  }

  /**
   * handle the savin go fthe changes of a cell
   * (only over the autofill feature is the length of the cellElements greater then 1)
   * @param cellElements list of changed cells
   */
  public onTableCellEdit(cellElements: SaxmsRowEntryElement[]): void {
    // this.rerender(true);
    this.removeTooltip();
    const autosaveOutputs: SpreadsheetSaveOutput[] = [];
    cellElements.forEach((cellElement) => {
      const column = this.columnUtil.getColumnByIndex(cellElement.getColNumber(), true);
      const saveEntry: SpreadsheetSaveModel = {
        restUrl: cellElement.getValue().updateRestUrl,
        value: cellElement.getValue(),
        prevValue: cellElement.getPrevValue(),
      };

      switch (column.fieldType) {
        case EFieldType.DATE_PICKER:
        case EFieldType.DATE_TIME_PICKER:
        case EFieldType.TIME_PICKER:
          this.validateDateLinks(column, cellElement.getRowNumber(), cellElement.getValue().value, cellElement);
          break;
        case EFieldType.COMBO_BOX:
          if (cellElement.getEntryElement()) {
            saveEntry.value.value = cellElement
              .getEntryElement()
              .getValue<EntryElementValue>()
              .getValue<EntryElementValue>()
              .getValue();
          } else {
            if (saveEntry.value.value instanceof EntryElementValue) {
              saveEntry.value.value = saveEntry.value.value.getValue();
            }
          }
          break;
      }

      const resourceId = this.rowDataUtil.getTableRowPlainList()[cellElement.getRowNumber()].resourceId;
      if (!this.unSavedChanges.has(resourceId)) {
        const values = new Map<string, SpreadsheetSaveModel>();
        values.set(saveEntry.value.tableHeaderId, saveEntry);
        this.unSavedChanges.set(resourceId, values);
      } else {
        this.unSavedChanges.get(resourceId)?.set(saveEntry.value.tableHeaderId, saveEntry);
      }

      this.edited = true;
      const saveOutput: SpreadsheetSaveOutput = {
        saveModel: saveEntry,
        delete: false,
      };

      // this.buttonService.

      if (column.autoSaveCell) {
        autosaveOutputs.push(saveOutput);
      } else {
        this.setLoadingAnimation(false);
        this.onChanges.emit(saveOutput);
      }
    });

    this.handleAutoSave(autosaveOutputs);
  }

  /**
   * handle the contextmenu of the table
   * @param rightClickEvent information about the right click
   */
  public handleRightClick(rightClickEvent: IRightClickEvent, event: MouseEvent): void {
    event.stopPropagation();
    const tableCtx: TableContextmenu = new TableContextmenu(this).get(rightClickEvent);
    this.contextmenuApi.create(rightClickEvent.htmlTarget, tableCtx);
  }

  /**
   * trigger the edit row function over the contextmenu
   * @param event information on which line the contextmenu was built on
   */
  public editRowEntryByContextMenu(event: IRightClickEvent): void {
    this.editRowEntry(event.rowIndex).subscribe();
  }

  /**
   * trigger the delete row function over the contextmenu
   * @param event information on which line the contextmenu was built on
   */
  public deleteRowEntryByContextMenu(event: IRightClickEvent): void {
    this.openDeleteDialog(event.rowIndex);
  }

  /**
   * toggles the visibility of the toolbar
   * @param visibile flag for the visibility
   */
  public handleMenumode(visibile: boolean): void {
    this.tableTemplate.setMenuMode(visibile ? EMenuMode.SHOW : EMenuMode.HIDE);
    this.contextMenuPopupService.close();
    this.toolbar.setMenuMode(visibile ? EMenuMode.SHOW : EMenuMode.HIDE);
    this.rerender(true);
  }

  /**
   * stops the propagation of events when the date picker opens
   */
  onCalendarOpen(): void {
    this.spreadsheetHelperUtil.getCustomEditor().lock(true, document.body);
    this.blockedCloseEventbyKeydown = true;
  }

  /**
   * unblocks the blocked events and closes the editor when the date picker is closed via the confirm button
   * @param closeMode 'close' => closed via the confirm button, 'backdropClick' => closed via backdropclick
   */
  public handlePickerClose(closeMode: 'backdropClick' | 'close'): void {
    this.spreadsheetHelperUtil.getCustomEditor().lock(false, document.body);
    this.blockedCloseEventbyKeydown = false;
    if (closeMode === 'close') {
      this.customEditorClose();
      this.spreadSheetInstance.getActiveEditor()?.close();
    }
  }

  /**
   * processes the editor changes from the date picker
   * @param event selected dates
   */
  public handleDatePicker(event: number[]): void {
    this.openOverInitKey = false;
    this.currentValueOfCustomEditor = event[0];

    this.saveChangeOfCustomEditor();
  }

  /**
   * processes the editor changes from the entryelements
   * @param entryElement which are change by the editor
   * @param stopClose flag for stop the closing of the editor
   */
  public editEntryElementOfCellEditor(entryElement: EntryElement, stopClose?: boolean, changed = true): void {
    this.openOverInitKey = false;
    this.currentValueOfCustomEditor = entryElement.getValue<EntryElementValue>().getValue();
    this.saveChangeOfCustomEditor(stopClose, changed);
  }

  /**
   * processes the editor changes from a cell
   * @param value value of the changed cell
   * @param stopClose flag for stop the closing of the editor
   */
  public editCellEditorChange(value: any, stopClose?: boolean): void {
    this.openOverInitKey = false;
    this.currentValueOfCustomEditor = value;
    this.saveChangeOfCustomEditor(stopClose);
  }

  /**
   * trigger the function for navigate to the current selected row
   */
  public handleOpenRowEntry(): void {
    const selectionIdx = Array.from(this.selectionModel.selectionColumns.keys())[0];
    const physRowIndex = Array.from(this.selectionModel.selectionColumns.get(selectionIdx))[0];
    this.openRowEntry(physRowIndex);
  }

  /**
   * open the resource behind the current selected row in a external window
   */
  public handleOpenRowEntryInWindow(): void {
    const rowIndex = this.spreadsheetHelperUtil.getRowIndex(
      Array.from(
        this.selectionModel.selectionColumns.get(Array.from(this.selectionModel.selectionColumns.keys())[0])
      )[0]
    );
    this.uiService
      .getData(this.rowDataUtil.getTableRowPlainList()[rowIndex].editEntryRestUrl)
      .pipe(take(1))
      .subscribe((data) => {
        const nameIndex = this.columnUtil.getColumnById(data.nameFieldIdentifier).data;
        let stopFunction = false;
        if (nameIndex || nameIndex === 0) {
          data.name = this.rowDataUtil.getTableRowPlainList()[rowIndex].spreadsheetRowEntries[nameIndex].value;
        }
        while (1) {
          const openBracketIndex = (data.restUrl as string).indexOf('{');
          const closeBracketIndex = (data.restUrl as string).indexOf('}');

          if (openBracketIndex !== -1 && closeBracketIndex !== -1) {
            const replaceString = (data.restUrl as string).slice(openBracketIndex + 1, closeBracketIndex);
            if (data[replaceString]) {
              data.restUrl = (data.restUrl as string).replace(`{${replaceString}}`, data[replaceString]);
            } else {
              console.error(`Error don't find ${replaceString} in ${data}`);
              stopFunction = true;
              break;
            }
          } else {
            break;
          }
        }

        if (!stopFunction) {
          this.windowService.openWindow({ content: data, type: 'tab' });
          // this.deselectCell();
        }
      });
  }

  /**
   * handle the default row-add button in the toolbar
   */
  public addRowEntry(): Observable<any> {
    // this.deselectCell();

    this.noScrollbarY = false;
    this.noScrollbarX = false;
    if (
      this.tableTemplate.isCreateInlineRow() &&
      this.tableTemplate.getCreateNewEntryRestUrls() &&
      Object.keys(this.tableTemplate.getCreateNewEntryRestUrls()).length === 1
    ) {
      return this.createInlineRow(
        this.tableTemplate.getCreateNewEntryRestUrls()[Object.keys(this.tableTemplate.getCreateNewEntryRestUrls())[0]]
      );
    }
    if (this.tableTemplate.getAddTableRowType() === ETableRowType.USE_EXISTING) {
      return this.createUseExisting();
    } else if (this.tableTemplate.getCreateNewEntryRestUrls()) {
      return this.createOverNewEntryRestUrls();
    }

    return of(undefined);
  }

  /**
   * Applies unsaved changes to the table from unSavedChanges map.
   */
  private applyUnsavedValues(): void {
    this.unSavedChanges.forEach((value, key) => {
      value.forEach((elem) => {
        const column = this.columnUtil.getColumnById(elem.value?.tableHeaderId);
        const tableRowIndex = this.rowDataUtil
          .getTableRowPlainList()
          .findIndex((tableRow) => tableRow.resourceId === key);
        this.changeValueOfEditor(tableRowIndex, column, elem.value?.value, false);
      });
    });
  }

  /**
   * handle filtering of the complex filter
   * @param event filter information
   */
  public filterColumnOverSubmenu(event: ISaxmsSubmenuOutput): void {
    const data = event.event;
    this.spreadSheetFilterService.filterColumn(data);
  }

  /**
   * handle the default delete button in the toolbar of the table
   */
  public deleteRowEntryBySubmenu(): void {
    const rowIndex = Array.from(
      this.selectionModel.selectionColumns.get(Array.from(this.selectionModel.selectionColumns.keys())[0])
    )[0];

    this.openDeleteDialog(rowIndex);
  }

  /**
   * edits current selected row
   * @returns void
   */
  public editCurrentSelectedRow(): Observable<void> {
    const rowIndex = this.spreadsheetHelperUtil.getCurrentSelectedRowIndex();
    return this.editRowEntry(rowIndex);
  }

  /**
   * sort the selected column
   */
  public sortColumn(): void {
    if (this.selectionModel.selectionColumns.size === 1) {
      const columnIndex = Number.parseInt(Array.from(this.selectionModel.selectionColumns.keys())[0]);
      const column = this.columnUtil.getColumnByIndex(columnIndex, true);
      switch (column.filterSortObject.getSortDirection()) {
        case ESortDirection.ASC:
          column.filterSortObject.setSortActive(true);
          column.filterSortObject.setSortDirection(ESortDirection.DESC);
          break;
        case ESortDirection.DESC:
          if (this.sortMap.has(column.id)) {
            this.sortMap.delete(column.id);
          }
          column.filterSortObject.setSortActive(false);
          column.filterSortObject.setSortDirection(ESortDirection.NONE);
          this.clearSortSettings(column);
          break;
        default:
          column.filterSortObject.setSortActive(true);
          column.filterSortObject.setSortDirection(ESortDirection.ASC);
          break;
      }
      this.sortByColumn(column.filterSortObject.getColumnIndex(), column);
      this.saveAfterSort(column);
    }
  }

  /**
   * Handles saving settings after column sort
   * @param column column that was sorted
   */
  private saveAfterSort(column: SaxMsSpreadSheetColumn) {
    const columnSetting = this.getColumnSettings(column);
    columnSetting.column.sort = column.filterSortObject.getSortDirection();

    if (columnSetting.createNew) {
      this.spreadsheetsettings.columnSettings.push(columnSetting.column);
    }

    const sortedColumSettings: SpreadsheetColumnSettings[] = [];
    this.sortMap.forEach((sortObject, columnId) => {
      const sortedColumn = this.columnUtil.getColumnById(columnId);
      const columnSetting = this.spreadsheetsettings.columnSettings.find(
        (setting) => setting.columnID === sortedColumn.id
      );
      sortedColumSettings.push(columnSetting);
    });
    const diffSettings = this.spreadsheetsettings.columnSettings.filter(
      (storedSetting) => !sortedColumSettings.find((sortedSetting) => sortedSetting.columnID === storedSetting.columnID)
    );
    this.spreadsheetsettings.columnSettings = sortedColumSettings.concat(diffSettings);

    this.saveSettings().pipe(take(1)).subscribe();
  }

  /**
   * trigger the export of the table as csv file
   */
  public exportAsCSV(): void {
    this.customExport(this.title);
  }

  /**
   * trigger the reset of the settings
   */
  public resetTableSettings(): void {
    this.spreadsheetSettingsService
      .resetSpreadsheetSettings(this.tableTemplate.getId())
      .pipe(takeWhile(() => this.alive))
      .subscribe((data) => {
        this.resetAllSettings(data);
      });
  }

  /**
   * toggle the visibility of the tooltip
   * @returns the current visibility state
   */
  public toggleTooltipState(): boolean {
    this.showTooltip = !this.showTooltip;
    return this.showTooltip;
  }

  /**
   * after slot for table toolbar gets rendered
   * @param instance TemplateComponent
   */
  public afterInitSlot(instance: ComponentRef<TemplateComponent>): void {
    this.toolbar.setReferenceContainer(this.wrappingContainer);
    instance.instance.templateNode = this.toolbar;
  }

  public toggleVisibilityOfHeader(colId: string, state = false): void {
    const column = this.columnUtil.getColumnById(colId);
    this.hiddenColumn(column.data, state);

    const columnSetting = this.getColumnSettings(column);
    columnSetting.column.hidden = !state;

    if (columnSetting.createNew) {
      this.spreadsheetsettings.columnSettings.push(columnSetting.column);
    }

    if (state) {
      this.hiddenColumnFilterSet.delete(column.filterSortObject.getColumnIndex());
    } else {
      this.hiddenColumnFilterSet.add(column.filterSortObject.getColumnIndex());
    }

    this.updateHiddenColumnPlugin();
    this.toolbar.updateMenuItemsIndications();
    this.saveSettings().subscribe();
  }

  /**
   * Make all columns visible again
   */
  public resetVisibilityOfHeaders(): void {
    this.resetHiddenColumns();

    this.spreadsheetsettings.columnSettings.forEach((setting) => (setting.hidden = false));

    this.hiddenColumnFilterSet.clear();

    this.updateHiddenColumnPlugin();
    this.changeDataOfSelectionColumn();
    this.toolbar.updateMenuItemsIndications();
    this.saveSettings().subscribe();
  }

  /**
   * handle the visibility of a column
   * @param columnIndex index of the colum
   * @param visibile visibility of the column
   */
  public hideColum(columnIndex: number, visibile: boolean): void {
    const column = this.columnUtil.getColumnByIndex(columnIndex, true);

    if (visibile || (!visibile && !this.hiddenColumnFilterSet.has(column.filterSortObject.getColumnIndex()))) {
      this.hiddenColumn(columnIndex, visibile);
    }

    const columnSetting = this.getColumnSettings(column);
    columnSetting.column.hidden = !visibile;

    if (columnSetting.createNew) {
      this.spreadsheetsettings.columnSettings = this.spreadsheetsettings.columnSettings.slice();
      this.spreadsheetsettings.columnSettings.push(columnSetting.column);
    }
    if (visibile) {
      this.hiddenColumnFilterSet.delete(column.filterSortObject.getColumnIndex());
    } else {
      this.hiddenColumnFilterSet.add(column.filterSortObject.getColumnIndex());
    }
  }

  /**
   * handle the visibility of rows
   * @param unhideRowIndices indices of the rows to be shown
   * @param hideRowIndices indices of the rows to be hidden
   * @param executerId id of the executer of the action
   */
  public handleVisibilityOfRows(unhideRowIndices: number[], hideRowIndices: number[], executerId: string): void {
    if (this.spreadSheetInstance && !this.spreadSheetInstance.isDestroyed) {
      this.handleHiddenRowsMap(unhideRowIndices, true, executerId);
      this.handleHiddenRowsMap(hideRowIndices, false, executerId);
      const rowVisibility = this.getVisibilityOfRowIndices();

      this.hiddenRowsPlugin.showRows(rowVisibility.visible);
      this.hiddenRowsPlugin.hideRows(rowVisibility.hidden);
    }
  }

  /**
   * handle a single click on an legendEntry
   * @param entry clicked legendentry
   */
  public handleLegendClick(entry: ILegendEntry): void {
    entry.isActive = !entry.isActive;
    this.handleColorLegend();
  }

  /**
   * handle a double click on an legendEntry
   * @param entry clicked legendentry
   */
  public handleLegendDblClick(entry: ILegendEntry): void {
    if (this.colorClassSet.length <= 1) {
      return;
    }
    const activeLegendEntries = this.colorClassSet.filter((legendEntry) => legendEntry.isActive).length;
    const beforeActive = entry.isActive ? true : false;
    this.colorClassSet.forEach((legendEntry) => {
      legendEntry.isActive = legendEntry.id !== entry.id && (activeLegendEntries > 1 || !beforeActive) ? false : true;
    });

    this.handleColorLegend();
  }

  // Getter Area

  /**
   * @returns whether the compoonete is still alive
   */
  public isAlive(): boolean {
    return this.alive;
  }

  /**
   * @returns the current table height in pixel as string
   */
  public getTableWrapperHeight(): string {
    return this.tableHeight + 'px';
  }

  /**
   * @param key type of the toolbar-row-menu-button
   * @returns the status of a toolbar-row-menu-button of a certain type
   */
  public getRowButtonState(key: string): boolean {
    if (this.tableTemplate.insideAllwaysEnableDefaultTableMenuButtons(key)) {
      return false;
    } else {
      switch (key) {
        case 'create':
          return (
            this.editableModeLocked ||
            !this.tableOptions.add ||
            !(
              this.tableTemplate.getAddTableRowType() === ETableRowType.USE_EXISTING ||
              (!!this.tableTemplate.isCreateInlineRow() && !!this.tableTemplate.getCreateNewEntryRestUrls()) ||
              !!this.tableTemplate.getCreateNewEntryRestUrls()
            )
          );
        case 'details':
          return !this.selectionModel.rowActionsEnable;
        case 'edit':
          return this.getButtonDisableState() || !this.rowEditable;
        case 'delete':
          return !(
            this.selectionModel.rowActionsEnable &&
            !this.getButtonDisableState() &&
            this.tableTemplate.getValuesRestUrl()
          );
        case 'open':
        case 'open_new_window':
          return !this.selectionModel.rowActionsEnable;
      }
    }
  }

  /**
   * @returns the localizedValues for a editor of a combobox
   */
  public getOptionOfComboBox(): string[] {
    return Object.keys(this.editValueObject?.getValue()?.localizedValues || {});
  }

  private deselectCellOnOpenLightbox(): Observable<Lightbox> {
    return this.lightboxApi.onDialogOpened().pipe(
      // ignore for lightboxes with simple content -> this should not contain any tables that lead to conflicts with the current selection
      filter((l) => !l.hasSimpleContent()),
      tap(() => {
        this.spreadSheetInstance?.deselectCell();
      })
    );
  }

  /**
   * returns the main holder conatiner of the table
   */
  get wtHolder(): HTMLElement {
    return this.wrappingContainer?.nativeElement
      .getElementsByClassName('ht_master')[0]
      ?.getElementsByClassName('wtHolder')[0];
  }

  /**
   * @returns the current selectionModel
   */
  public getSelectionModel(): SaxMsSelectionModel {
    return this.selectionModel;
  }

  /**
   * @returns the table template
   */
  public getTableTemplate(): Table {
    return this.tableTemplate;
  }

  /**
   * @returns the tbale toolbar
   */
  public getToolbar(): SpreadsheetToolbar {
    return this.toolbar;
  }

  /**
   * @returns the TemplateAdapter service
   */
  public getTemplateAdapter(): TemplateAdapter {
    return this.templateAdapter;
  }

  /**
   * @returns the ButtonService
   */
  public getButtonService(): ButtonService {
    return this.buttonService;
  }

  /**
   * @returns the SystemMessageService
   */
  public getSystemMessageService(): MessageService {
    return this.systemMessageService;
  }

  /**
   * @returns the ConfigService
   */
  public getConfigApi(): ConfigService {
    return this.configApi;
  }

  /**
   * @returns the columnUtil class
   */
  public getColumnUtil(): ColumnUtil {
    return this.columnUtil;
  }

  /**
   * @returns the SpreadsheetHelperUtil class
   */
  public getSpreadsheetHelperUtil(): SpreadsheetHelperUtil {
    return this.spreadsheetHelperUtil;
  }

  /**
   * @returns the RowDataUtil class
   */
  public getRowDataUtil(): RowDataUtil {
    return this.rowDataUtil;
  }

  /**
   * @returns the state if the editmode is locked
   */
  public isEditableModeLocked(): boolean {
    return this.editableModeLocked;
  }

  /**
   * @returns the state if the init of the table is finished
   */
  public isInitFinished(): boolean {
    return this.initFinished;
  }

  /**
   * @returns the current hidden columns
   */
  public getHiddenColumnFilterSet(): Set<number> {
    return this.hiddenColumnFilterSet;
  }

  /**
   * @returns the sort plugin of the table
   */
  public getSortPlugin(): MultiColumnSorting {
    return this.sortPlugin;
  }

  /**
   * @returns the current sort config of the table
   */
  public getSortConfig(): ISpreadsheetSortObject[] {
    const sortConfig: ISpreadsheetSortObject[] = [];
    this.sortMap.forEach((sortValue, columnId) => {
      sortValue.column = this.columnUtil.getColumnById(columnId).data;
      sortConfig.push(sortValue);
    });
    return sortConfig;
  }

  /**
   * @returns the status if there is a loading animation
   */
  public hasLoadingAnimation(): boolean {
    return this.showLoadingAnimation;
  }

  /**
   * @returns the status if the tooltips are visible
   */
  public isShowTooltip(): boolean {
    return this.showTooltip;
  }

  /**
   * @returns the status if closing the editor via key events is prevented
   */
  public isBlockedCloseEventbyKeydown(): boolean {
    return this.blockedCloseEventbyKeydown;
  }

  /**
   * @returns the content id of the template
   */
  public getContentId(): string {
    return this.contentId;
  }

  /**
   * @returns the status if the initial selection has not been executed yet
   */
  public isInitSelection(): boolean {
    return this.initSelection;
  }

  /**
   * @returns the subject for the tooltip
   */
  public getCellMouseOverSub(): Subject<any> {
    return this.cellMouseOverSub;
  }

  /**
   * @returns the subject for the click
   */
  public getClickTimeoutId(): Subject<any> {
    return this.clickTimeoutId;
  }

  /**
   * @returns the auto row size plugin of the table
   */
  public getAutoRowSizePlugin(): AutoRowSize {
    return this.autoRowSizePlugin;
  }

  /**
   * @returns the auto column size plugin of the table
   */
  public getAutoColumnSizePlugin(): AutoColumnSize {
    return this.autoColumnSizePlugin;
  }

  /**
   * @returns the current gridlayout
   */
  public getGridLayout(): GridLayout {
    return this.gridLayout;
  }

  /**
   * @returns the current selectionInformation
   */
  public getSelectionInformation(): SelectionInformation {
    return this.selectionInformation;
  }

  /**
   * @returns the last selected range of the current selection of the table
   */
  public getLastSelectedRanges(): CellRange[] {
    if (this.spreadSheetInstance && !this.spreadSheetInstance.isDestroyed) {
      return this.spreadSheetInstance.getSelectedRange();
    }
  }

  /**
   * @param pluginname
   * @returns the plugin with the passing name
   */
  public getPlugin(pluginname: ESpreadsheetPlugin): any {
    if (pluginname === ESpreadsheetPlugin.FILTERS_PLUGIN) {
      return this.spreadSheetFilterService.filtersPlugin;
    }
    return this[pluginname];
  }

  /**
   * @returns the status if there is a loading animation visible in the table
   */
  public getLoadingState(): boolean {
    return (
      (!this.initFinished && this.hiddenColumnFilterSet.size !== this.displayedColumns.length) ||
      !this.initLoadRows ||
      this.loading ||
      this.showLoadingAnimation
    );
  }

  /**
   * @returns the current table settings
   */
  public getSpreadsheetsettings(): ISpreadsheetSaveSettings {
    return this.spreadsheetsettings;
  }

  /**
   * @returns checks if a column has a numeric input
   */
  public isNumericInput(column: SaxMsSpreadSheetColumn): boolean {
    return this.spreadsheetHelperUtil.isNumberField(column);
  }

  // Setter Area

  /**
   * update the value if the initial rendering is already done
   * @param state
   */
  public setInitFinished(state: boolean): void {
    if (this.initFinished === state) {
      return;
    }
    this.zone.run(() => (this.initFinished = state));
  }

  /**
   * update the value if the refresh rendering is already done
   * @param state
   */
  public setRefreshFinished(state: boolean): void {
    if (this.refreshFinished === state) {
      return;
    }
    this.zone.run(() => (this.refreshFinished = state));
  }

  /**
   * update the state if a row is editable
   * @param state
   */
  public setRowEditable(state: boolean): void {
    this.rowEditable = state;
  }

  /**
   * update the state of the loading animation
   * @param state
   */
  public setLoadingAnimation(state: boolean): void {
    this.zone.run(() => (this.showLoadingAnimation = state));
  }

  /**
   * update the status the initial mapping of the rows is completed
   * @param state
   */
  public setInitLoadRows(state: boolean): void {
    this.zone.run(() => (this.initLoadRows = state));
    // this.cd?.markForCheck();
  }

  /**
   * update current editor and handle the focus of teh editor
   * @param cellEditorContext editor information
   */
  public setCellEditorContext(cellEditorContext: ISaxMsEditorContext): void {
    this.templateHotkeyService.focusTemplate(this.wrappingContainer.nativeElement, this.tableTemplate.getId());

    // set context to null before setting the new context to trigger a rerender of the cell editor
    // sometimes the old context is not removed and the new context is not set
    this.cellEditorContext = null;
    this.cd?.detectChanges();

    this.cellEditorContext = cellEditorContext;
    this.cd?.detectChanges();
  }
  /**
   * update the current selection model
   * @param selectionModel
   */
  public setSelectionModel(selectionModel: SaxMsSelectionModel): this {
    this.selectionModel = selectionModel;
    return this;
  }

  /**
   * update the current gridlayout
   * @param gridLayout
   */
  public setGridLayout(gridLayout: GridLayout): void {
    this.gridLayout = gridLayout;
  }

  /**
   * get maximum number of rows that can be displayed at a time
   * @returns number
   */
  get MAX_ROWS(): number {
    if (!this.rows || this.rows.length === 0) {
      return 0;
    }

    return (
      (this.rows?.length || 0) +
      (this.rows || [])
        .map((r, index: number) => r?.__children?.length || 0)
        .reduce((prev: number, cur: number) => prev + cur)
    );
  }

  /**
   * update the current selectionInformation
   * @param selectionInformation
   */
  public setSelectionInformation(selectionInformation: SelectionInformation): void {
    this.selectionInformation = selectionInformation;
  }

  public isInitLoading(): boolean {
    return this.initLoading;
  }

  public getSpreadSheetInstance(): Handsontable {
    return this.spreadSheetInstance;
  }

  public getBlockedSaveSettings(): boolean {
    return this.blockedSaveSettings;
  }

  public getSortMap(): Map<string, ISpreadsheetSortObject> {
    return this.sortMap;
  }

  protected getMessageBars(): MessageBar[] {
    try {
      return (this.tableTemplate.getValues() as any).messageBars || [];
    } catch (error) {
      return [];
    }
  }
}
