import { ConfigService } from '@core/config/config.service';
import { EGanttInstance, GanttDataRow, GanttDataShift } from '@gantt/public-api';
import { GanttLibService } from 'frontend/src/dashboard/gantt/gantt/gantt-lib.service';
import {
  GanttChildren,
  IGanttAttributeMapping,
  IGanttBlock,
} from 'frontend/src/dashboard/gantt/general/generator/gantt-input.data';
import { GanttRowAdditionalDataMapper } from 'frontend/src/dashboard/gantt/general/generator/mapper/gantt-row-additional-data.mapper';
import { GeneralGanttAdditionalBlockInfo } from 'frontend/src/dashboard/gantt/general/generator/mapper/gantt-to-gantt-origin.mapper';
import { UtilTooltipMapper } from 'frontend/src/dashboard/gantt/general/generator/mapper/gantt.tooltip.mapper';
import { GanttEssentialPlugIns } from 'frontend/src/dashboard/gantt/general/plugin/e-gantt-essential-plugins';
import { GanttPluginHandlerService } from 'frontend/src/dashboard/gantt/general/plugin/gantt-plugin-handler.service';
import { GanttColorizerByAttributePlugIn } from 'frontend/src/dashboard/gantt/general/plugin/plugin-list/block-colorizer/by-attribute/colorizer-by-attribute';
import { EGanttColors } from '../../../../generator/gantt-input.enmu';
import { IGanttResponse } from '../../../gantt-response';
import { GanttRowUpdateSuperblockHandler } from './handler/superblocks';
import { GanttResponseStrategy } from './i-response-strategy';

interface GanttRowUpdateObjectData {
  id: string;
  properties: any;
}

/**
 * Update Strategy to load hierarchical plan from backend and replace all gantt entries by new data.
 */
export class GanttRowUpdateReplaceBlockStrategy implements GanttResponseStrategy {
  private updateSuperblockHandler: GanttRowUpdateSuperblockHandler;
  private blocksToColorize: { id: string; color: string }[] = [];
  private ganttLibBlocks = new Map<string, GanttDataShift>();
  private ganttLibEntries = new Map<string, GanttDataRow>();
  private ganttTemplateBlocks = new Map<string, IGanttBlock>();
  private ganttTemplateEntries = new Map<string, GanttChildren>();

  constructor(
    private _ganttPluginHandlerService: GanttPluginHandlerService,
    private _ganttLibService: GanttLibService,
    private _configService: ConfigService
  ) {
    this.updateSuperblockHandler = new GanttRowUpdateSuperblockHandler(_ganttPluginHandlerService);
  }

  public createMaps() {
    const createGanttMap = (ganttEntry: GanttDataRow) => {
      this.ganttLibEntries.set(ganttEntry.id, ganttEntry);
      ganttEntry.shifts?.forEach((shift) => this.ganttLibBlocks.set(shift.id, shift));
    };

    this._ganttLibService.ganttInstanceService
      .getInstance(EGanttInstance.DATA_MANIPULATOR)
      .iterateOverDataSet(this._ganttLibService.bestGantt.getDataHandler().getOriginDataset().ganttEntries, {
        createGanttMap: createGanttMap.bind(this),
      });

    const createGanttTemplateMap = (ganttEntry: GanttChildren) => {
      this.ganttTemplateEntries.set(ganttEntry.id, ganttEntry);
      ganttEntry.blocks?.forEach((block) => this.ganttTemplateBlocks.set(block.id, block));
    };
    this._ganttPluginHandlerService.getTemplateData().iterateOverAllEntries(createGanttTemplateMap.bind(this));
  }

  /**
   * Updates rows by block input.
   * Pays attention to row restriction.
   * @param response Serverresponse data.
   * @param toolbarHandler Toolbar handler from SaxMSBestGantt.
   * @param externalPlugInHandler Mediator for nearly all components inside generalized gantt.
   * @param attributeMapping Attribute Mapping from gantt template to give the possibility to update block tooltips.
   */
  public update(
    response: IGanttResponse,
    ganttLibService: GanttLibService,
    ganttPluginHandlerService: GanttPluginHandlerService,
    attributeMapping: IGanttAttributeMapping
  ): boolean {
    if (response.ganttEntries?.length) {
      const updatedBlocks = new Set<IGanttBlock>();
      this.createMaps();

      for (const row of response.ganttEntries) {
        this.updateRow(
          row,
          ganttLibService,
          ganttPluginHandlerService,
          attributeMapping,
          (response as any).monthlyDataResponse,
          updatedBlocks,
          false
        );
      }
      const ganttDiagram = ganttLibService.bestGantt;

      const backendMapper = this._ganttLibService.backendToGanttOriginInputMapper;
      backendMapper.reinitaliseBlockClones(ganttDiagram.getDataHandler().getOriginDataset().ganttEntries);

      // update colorize plug-in
      const blockColorizeByAttributePlugIn: GanttColorizerByAttributePlugIn =
        this._ganttPluginHandlerService.getEssentialPlugIn(GanttEssentialPlugIns.BlockColorizeByAttributePlugIn);
      for (const blockData of this.blocksToColorize) {
        const shiftIdCopies = backendMapper.getShiftClonesByShiftId(blockData.id);
        if (shiftIdCopies.length) {
          shiftIdCopies.forEach((id) =>
            blockColorizeByAttributePlugIn.addNewShiftToColorize(id, blockData.color || EGanttColors.DEFAULT_BLOCK)
          );
        }
      }
      this.blocksToColorize = [];

      // super block handling
      this.updateSuperblockHandler.updateSuperBlockPlugin(updatedBlocks);

      // update gantt shifts
      // ganttDiagram.updateAllShifts(null, true, false);

      // update row restricitons
      ganttPluginHandlerService
        .getEssentialPlugIn(GanttEssentialPlugIns.RowRestrictionPlugIn)
        .updateByRowData(response.ganttEntries);
    }
    return true;
  }

  /**
   * Trys to update gantt rows by response data on block level.
   * @param response Serverresponse data.
   * @param responseRowData Backend row definition.
   * @param toolbarHandler Toolbar handler from SaxMSBestGantt.
   * @param externalPlugInHandler Mediator for nearly all components inside generalized gantt.
   * @param attributeMapping Necessary for tooltip mapping.
   */
  private updateRow(
    responseRowData: GanttChildren,
    ganttLibService: GanttLibService,
    ganttPluginHandlerService: GanttPluginHandlerService,
    attributeMapping: IGanttAttributeMapping,
    monthlyDataResponse: boolean,
    updatedBlocks: Set<IGanttBlock>,
    reinitialiseBlockClones = true
  ): void {
    let rowId = responseRowData.id;
    let updateEntry = true;
    let originRowData = this.ganttLibEntries.get(responseRowData.id);

    // check if row exists in gantt
    if (!originRowData) {
      if (responseRowData.alternativeGanttEntryId) {
        originRowData = this.ganttLibEntries.get(responseRowData.alternativeGanttEntryId);
        if (originRowData) {
          updateEntry = false; // disable update if alternative id is used
          rowId = responseRowData.alternativeGanttEntryId;
        } else {
          return; // return if alternative id is not found
        }
      } else {
        return; // return if row is not found
      }
      return;
    }

    const hierarchicalPlanEntry = this.ganttTemplateEntries.get(rowId);

    // UPDATE BLOCKS
    const filteredBlocks = responseRowData.blocks.filter((block) => !!block);
    filteredBlocks.forEach((block) => updatedBlocks.add(block));
    this._updateHierarchicalPlanBlocks(filteredBlocks, hierarchicalPlanEntry);
    const updatedShiftIds = this.updateShifts(rowId, filteredBlocks, ganttLibService, attributeMapping);
    const notUpdatedBlocks = filteredBlocks.filter((block) => !updatedShiftIds.includes(block.id));
    for (const block of notUpdatedBlocks) {
      this.addShift(rowId, block, ganttLibService, attributeMapping, reinitialiseBlockClones);
    }

    if (updateEntry) {
      // UPDATE ROWS
      this._updateHierarchicalPlanEntry(responseRowData, hierarchicalPlanEntry);
      this._updateLibEntry(
        responseRowData,
        originRowData,
        monthlyDataResponse,
        attributeMapping,
        hierarchicalPlanEntry
      );
    }

    // sorting blocks
    this._ganttLibService.ganttInstanceService
      .getInstance(EGanttInstance.SHIFT_DATA_SORTING)
      .sortJSONListByDate(originRowData.shifts, 'timePointStart', 'id');

    // update children, if we get a hierachy as updateRow
    if (responseRowData.children) {
      for (const childRow of responseRowData.children) {
        this.updateRow(
          childRow,
          ganttLibService,
          ganttPluginHandlerService,
          attributeMapping,
          monthlyDataResponse,
          updatedBlocks,
          reinitialiseBlockClones
        );
      }
    }
  }

  /**
   *
   * @param response Serverresponse data.
   * @param rowId Id of row in which the given blcok should be updated.
   * @param ganttBlock Backend data of block which shoul dbe updated.
   * @param toolbarHandler Toolbar handler from SaxMSBestGantt.
   * @param externalPlugInHandler Mediator for nearly all components inside generalized gantt. Interface for JSFactory.
   * @param attributeMapping Necessary for tooltip mapping.
   */
  private addShift(
    rowId: string,
    ganttBlock: IGanttBlock,
    ganttLibService: GanttLibService,
    attributeMapping: IGanttAttributeMapping,
    reinitialiseBlockClones = true
  ): boolean {
    // update js gantt

    const ganttDiagram = ganttLibService.bestGantt;
    const foundRowData = this.ganttLibEntries.get(rowId);
    if (!foundRowData) return false;
    const mapper = this._ganttLibService.backendToGanttOriginInputMapper;
    const mappedBlock = mapper.blockToInput(
      ganttBlock,
      attributeMapping,
      this._ganttPluginHandlerService.getTemplateData().getDefaultBlockTooltipSettings()
    );
    if (mappedBlock) {
      foundRowData.shifts.push(mappedBlock);
      this.ganttLibBlocks.set(mappedBlock.id, mappedBlock);
    }

    if (reinitialiseBlockClones) {
      const backendMapper = this._ganttLibService.backendToGanttOriginInputMapper;
      backendMapper.reinitaliseBlockClones(ganttDiagram.getDataHandler().getOriginDataset().ganttEntries);

      const shiftIdCopies = backendMapper.getShiftClonesByShiftId(ganttBlock.id);
      // update colorize plugin
      const blockColorizeByAttributePlugIn: GanttColorizerByAttributePlugIn =
        this._ganttPluginHandlerService.getEssentialPlugIn(GanttEssentialPlugIns.BlockColorizeByAttributePlugIn);
      if (shiftIdCopies.length) {
        shiftIdCopies.forEach((id) =>
          blockColorizeByAttributePlugIn.addNewShiftToColorize(id, ganttBlock.color || EGanttColors.DEFAULT_BLOCK)
        );
      }
    } else {
      this.blocksToColorize.push({ id: ganttBlock.id, color: ganttBlock.color || EGanttColors.DEFAULT_BLOCK });
    }

    return true;
  }

  private updateShifts(
    rowId: string,
    ganttBlocks: IGanttBlock[],
    ganttLibService: GanttLibService,
    attributeMapping: any
  ): string[] {
    const updatedShiftIds: string[] = [];
    if (!ganttBlocks || ganttBlocks.length <= 0) return updatedShiftIds;

    const backendMapper = this._ganttLibService.backendToGanttOriginInputMapper;
    const blockColorizeByAttributePlugIn: GanttColorizerByAttributePlugIn =
      this._ganttPluginHandlerService.getEssentialPlugIn(GanttEssentialPlugIns.BlockColorizeByAttributePlugIn);

    const shiftUpdate: string[] = [];
    const shiftToBlockMap: { [id: string]: IGanttBlock } = {};
    for (let i = 0; i < ganttBlocks.length; i++) {
      shiftToBlockMap[ganttBlocks[i].id] = ganttBlocks[i];
      shiftUpdate.push(...backendMapper.getShiftClonesByShiftId(ganttBlocks[i].id));
    }

    shiftUpdate.forEach((shiftId) => {
      const shift = this.ganttLibBlocks.get(shiftId);
      const originBlockId = backendMapper.isShiftAClone(shift?.id)
        ? backendMapper.getOriginShiftIdByCloneId(shift?.id)
        : shift?.id;
      const ganttBlock = shiftToBlockMap[originBlockId];
      if (!shift) {
        const success = this.updateSuperblockHandler.updateInsideSuperBlocksPlugIn(ganttBlock);
        if (success && shiftToBlockMap[shiftId]) {
          updatedShiftIds.push(shiftId);
        }
        return;
      }

      shift.timePointEnd = new Date(ganttBlock.end);
      shift.timePointStart = new Date(ganttBlock.start);
      shift.color = ganttBlock.color || EGanttColors.DEFAULT_BLOCK;
      shift.name = ganttBlock.name;
      (shift.originName as any) = ganttBlock.name; // typed to any to override the shift name
      shift.strokeColor = ganttBlock.stroke;
      shift.pattern = ganttBlock.pattern;
      shift.patternColor = ganttBlock.patternColor;
      shift.firstColor = ganttBlock.firstColor;
      shift.secondColor = ganttBlock.secondColor;
      shift.modificationRestriction = ganttBlock.modificationRestriction ? ganttBlock.modificationRestriction : null;
      shift.additionalData = new GeneralGanttAdditionalBlockInfo(
        ganttBlock.details,
        ganttBlock.blockTypes,
        ganttBlock.superBlocks,
        ganttBlock.entryTypes
      );
      this.setBlockTypes(shift, ganttBlock.blockTypes);
      this.setBlockDescription(shift, ganttBlock.details, attributeMapping);
      blockColorizeByAttributePlugIn.addNewShiftToColorize(shiftId, ganttBlock.color || EGanttColors.DEFAULT_BLOCK);

      // super block handling
      if (shiftToBlockMap[shiftId]) {
        updatedShiftIds.push(shiftId);
      }
    });

    return updatedShiftIds;
  }

  /**
   * Overwrites/sets block types to additonal data of mapped shift block for superblock handling.
   * @param block Mapped shift block.
   * @param newBlockTypes New block types.
   */
  private setBlockTypes(block: GanttDataShift, newBlockTypes: number[]): void {
    if (!block.additionalData) block.additionalData = {};
    block.additionalData.blockTypes = newBlockTypes;
  }

  /**
   * Adds new tooltip description to mapped block.
   * @param block Mapped shift block.
   * @param newBlockDetails Backend tooltip data for block.
   * @param attributeMapping Map of tooltip values to insert into tooltips.
   */
  private setBlockDescription(
    block: GanttDataShift,
    newBlockDetails: any,
    attributeMapping: IGanttAttributeMapping
  ): void {
    try {
      block.tooltip = this._ganttLibService.backendToGanttOriginInputMapper.getBlockTooltipByTemplateData(
        newBlockDetails,
        attributeMapping,
        this._ganttPluginHandlerService.getTemplateData().getDefaultBlockTooltipSettings()
      );
    } catch (e) {
      console.warn('Problems during parsing block tooltip response data:' + e);
    }
  }

  private _updateHierarchicalPlanBlocks(ganttBlocks: IGanttBlock[], hierarchicalPlanEntry: GanttChildren): void {
    ganttBlocks.forEach((ganttBlock) => {
      const existingBlock = this.ganttTemplateBlocks.get(ganttBlock.id);
      if (existingBlock) {
        // update existing block
        Object.keys(ganttBlock).forEach((key) => {
          existingBlock[key] = ganttBlock[key];
        });
      } else {
        // add new blocks
        this.ganttTemplateBlocks.set(ganttBlock.id, ganttBlock);
        hierarchicalPlanEntry.blocks.push(ganttBlock);
      }
    });
  }

  private _updateHierarchicalPlanEntry(newEntryFromBackend: GanttChildren, hierarchicalPlanEntry: GanttChildren) {
    if (!hierarchicalPlanEntry) return;

    hierarchicalPlanEntry.name = newEntryFromBackend.name;
    hierarchicalPlanEntry.color = newEntryFromBackend.color;
    hierarchicalPlanEntry.icon = newEntryFromBackend.icon;
    hierarchicalPlanEntry.backgroundColor = newEntryFromBackend.backgroundColor;
    hierarchicalPlanEntry.sIds = newEntryFromBackend.sIds;
    hierarchicalPlanEntry.colorSections = newEntryFromBackend.colorSections;
    if (newEntryFromBackend.allowedEntryTypes != null)
      hierarchicalPlanEntry.allowedEntryTypes = newEntryFromBackend.allowedEntryTypes;
    if (newEntryFromBackend.indicatorColor != null) {
      hierarchicalPlanEntry.indicatorColor = newEntryFromBackend.indicatorColor;
    } // update indicator color
  }

  private _updateLibEntry(
    newEntryFromBackend: GanttChildren,
    libEntry: GanttDataRow,
    monthlyDataResponse: boolean,
    attributeMapping: IGanttAttributeMapping,
    hierarchicalPlanEntry: GanttChildren
  ) {
    const brokenConstraints = monthlyDataResponse
      ? newEntryFromBackend.toolTipDetails?.brokenConstraints || {}
      : GanttRowAdditionalDataMapper.getBrokenConstraintsOfRow(hierarchicalPlanEntry);
    const additionalData = GanttRowAdditionalDataMapper.extractAdditionalDataFromRow(
      newEntryFromBackend,
      attributeMapping,
      this._ganttPluginHandlerService.getTemplateData().getGanttEntryAttributeMappings(),
      brokenConstraints
    );
    const tooltip: string = UtilTooltipMapper.getEntryTooltipByAdditionalRowAttributes(additionalData);
    const subtitleElements = this._ganttLibService.backendToGanttOriginInputMapper.generateEntrySubtitleElementList(
      newEntryFromBackend,
      this._ganttPluginHandlerService.getTemplateData().getGanttEntryAttributeMappings()
    );

    // extract background color if its defined in sections without from and to property and with no tooltip. Because tooltips can only be defined in sections
    const backgroundColorSection = newEntryFromBackend.colorSections?.BACKGROUND_COLOR;
    const backgroundColor =
      backgroundColorSection?.color &&
      !backgroundColorSection?.from &&
      !backgroundColorSection?.to &&
      !backgroundColorSection.tooltip
        ? backgroundColorSection.color
        : undefined;

    const isBrokenConstraint =
      Object.keys(brokenConstraints).length > 0 && !!newEntryFromBackend.toolTipDetails?.brokenConstraints;

    libEntry.name = newEntryFromBackend.name; // set new name
    libEntry.textColor = this._ganttLibService.backendToGanttOriginInputMapper.getEntryTextColor(
      newEntryFromBackend,
      isBrokenConstraint
    ); // set new text color
    libEntry.icon = this._configService.getIcon(newEntryFromBackend.icon); // set new icon
    libEntry.tooltip = tooltip; // set new tooltip
    libEntry.subtitleElements = subtitleElements; // set new subtitle elements
    libEntry.color = backgroundColor || newEntryFromBackend.backgroundColor; // set new row background color
    libEntry.additionalData = additionalData; // set new additionalData
    libEntry.notHideable = newEntryFromBackend.notHideable || false;
    if (newEntryFromBackend.allowedEntryTypes != null) {
      libEntry.allowedEntryTypes = newEntryFromBackend.allowedEntryTypes;
    } // update allowed entry types
    if (newEntryFromBackend.indicatorColor != null) {
      libEntry.indicatorColor = newEntryFromBackend.indicatorColor;
    } // update indicator color
    if (newEntryFromBackend.sampleValues) {
      libEntry.sampleValues = newEntryFromBackend.sampleValues;
    } // update sampleValues
    if (newEntryFromBackend.startCellIndexForSampleValues != null) {
      libEntry.startCellIndexForSampleValues = newEntryFromBackend.startCellIndexForSampleValues;
    } // update startCellIndexForSampleValues
  }
}

