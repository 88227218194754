import { Component } from '@angular/core';
import { ConfigService } from '@core/config/config.service';
import { UserService } from 'frontend/src/dashboard/user/data-access/user.service';
import { ViewService } from './../../view.service';
import { SelectorComponent } from './selector.component';

@Component({
  selector: 'theme-selector',
  templateUrl: './selector.html',
  styleUrls: ['./selector.scss'],
})
export class StyleSelectorComponent extends SelectorComponent {
  constructor(
    public globalViewService: ViewService,
    public globalUserSettingsService: UserService,
    private configApi: ConfigService
  ) {
    super(globalViewService);

    this.title = '@theme@';

    this.items = [
      { id: 'dark-theme', name: 'dark-theme' },
      { id: 'default-theme', name: 'default-theme' },
      { id: 'neutral-theme', name: 'neutral-theme' },
    ];

    const theme: any = this.configApi.access().theme;

    if (theme) {
      this.globalViewService.changeStyle(theme.defaultTheme);
    }
    this.globalUserSettingsService.getValueByType('FREE_TO_USE_1').subscribe((theme) => {
      for (const i of this.items) {
        if (i.id == theme.settingsValue.value) {
          this.setActive(i);
          return;
        }
      }
    });
  }

  public setActive(theme: any): void {
    this.selected = theme;
    this.globalViewService.changeStyle(theme.name);
    this.globalUserSettingsService.updateUserSettings('FREE_TO_USE_1', theme.id).subscribe();
  }
}
