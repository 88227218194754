import { Injectable, Type } from '@angular/core';
import { EntryElementFactory } from '@app-modeleditor/components/entry-collection/entry-factory.service';
import { TemplateAdapter } from '@app-modeleditor/utils/template-factory.service';
import { Adapter } from '../../../core/adapter';
import { ContentElementAdapter } from '../structure/content-element-adapter.service';
import { EntryCollection } from './entry-collection';
import { EntryElement } from './entry-element';
import { EntryElementValue } from './entry-element-value';

@Injectable({
  providedIn: 'root',
})
export class EntryCollectionAdapter implements Adapter<EntryCollection> {
  constructor(private contentElementAdapter: ContentElementAdapter, private entryElementAdapter: EntryElementFactory) {}

  adapt(item: any): EntryCollection {
    throw new Error('Method not implemented.');
  }

  inheritFrom?<T extends EntryCollection>(scope: TemplateAdapter, type: Type<T>, item: any): T {
    if (!item) {
      return null;
    }

    return this.contentElementAdapter
      .inheritFrom<T>(scope, type, item)
      .setEntryElements((item.elements || item.entryElements || []).map((e) => scope.adapt(e).setParentId(item.id)))
      .setEntryElementTemplates((item.entryElementTemplates || []).map((e) => scope.adapt(e)))
      .setIndex(item.index);
  }

  applyValues<T extends EntryCollection>(scope: TemplateAdapter, item: T, values: any): T {
    // default mapping
    Object.keys(values).forEach((key: string) => {
      const entryElement: EntryElement = item.getEntryElements().find((e: EntryElement) => e.getId() === key);
      if (entryElement) {
        entryElement.setValue(this.entryElementAdapter.parseTemplateEntry(entryElement, values[key]));

        // apply resourceId if available
        if (entryElement.getValue<EntryElementValue>().getResourceId()) {
          entryElement.setResourceId(entryElement.getValue<EntryElementValue>().getResourceId());
        }

        if (entryElement.getValue<EntryElementValue>().getResourceId()) {
          entryElement.setCanonicalName(entryElement.getValue<EntryElementValue>().getCanonicalName());
        }
      }
    });

    // in case of dynamic templates
    if (item.getEntryElementTemplates().length > 0) {
      Object.keys(values).forEach((key: string) => {
        item.getEntryElementTemplates().forEach((entryElement: EntryElement) => {
          if (entryElement.getId() !== key) {
            return;
          }

          entryElement.setValue(this.entryElementAdapter.parseTemplateEntry(null, values[Object.keys(values)[0]]));

          item.addEntryElements(
            ...values[key].value.map((val: any) => {
              const ref: EntryElement = entryElement.copy();
              ref.setEditable(true);
              ref.setValue(this.entryElementAdapter.parseTemplateEntry(ref, val));
              return ref;
            })
          );
        });
      });
    }

    return item;
  }
}
