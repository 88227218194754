import { Directive, EventEmitter, Pipe } from '@angular/core';

export enum TimeType {
  MILLISECOND,
  SECOND,
  MINUTE,
  HOUR,
  DAY,
  WEEK,
  ERROR,
}

export const toMillisecond = [1, 1000, 1000 * 60, 1000 * 60 * 60, 1000 * 60 * 60 * 24, 1000 * 60 * 60 * 24 * 7, -1];

export function getTimeEnumFromString(input: string): TimeType {
  switch (input) {
    case 'millisecond':
      return TimeType.MILLISECOND;
    case 'second':
      return TimeType.SECOND;
    case 'minute':
      return TimeType.MINUTE;
    case 'hour':
      return TimeType.HOUR;
    case 'day':
      return TimeType.DAY;
    case 'week':
      return TimeType.WEEK;
    default:
      return TimeType.ERROR;
  }
}

export const supportedUnits: any = {
  millisecond: {
    label: 'Millisecond',
    max: 999,
    min: 0,
    value: 0,
    step: 1,
  },
  second: {
    label: 'Second',
    max: 59,
    min: 0,
    value: 0,
    step: 1,
  },
  minute: {
    label: 'Minute',
    max: 59,
    min: 0,
    value: 0,
    step: 1,
  },
  hour: {
    label: 'Hour',
    max: Number.POSITIVE_INFINITY,
    min: 0,
    value: 0,
    step: 1,
  },
  day: {
    label: 'Day',
    max: Number.POSITIVE_INFINITY,
    min: 0,
    value: 0,
    step: 1,
  },
  week: {
    label: 'week',
    max: Number.POSITIVE_INFINITY,
    min: 0,
    value: 0,
    step: 1,
  },
};

@Directive()
export abstract class ITimeDurationPickerUnitComponent {
  spinner: any;
  name: string;
  label: string;
  max: number;
  min: number;
  step: number;
  inputDisabled: boolean;
  onChange: EventEmitter<number>;
  size: number;

  abstract getTimeType(): TimeType;
  abstract setTimeType(timeType: TimeType);
  abstract getIsAvailable();
  abstract setIsAvailable(value: boolean);
  abstract ngOnInit();
  abstract updateValue();
  abstract getValue();
  abstract setValue(number: any);
  abstract updateValueBy(number: any);
}
@Pipe({ name: 'timeDurationPickerLib' })
// eslint-disable-next-line @angular-eslint/use-pipe-transform-interface
export class TimeDurationPickerLib {}
