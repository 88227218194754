<div
  #window
  class="window mat-typography"
  cdkDrag
  cdkDragBoundary=".cdk-global-overlay-wrapper"
  (cdkDragEnded)="handleDragEnd($event)"
  [cdkDragFreeDragPosition]="{
    x: ganttSettingsService.getGanttSettings().dockWindowProportions.x,
    y: ganttSettingsService.getGanttSettings().dockWindowProportions.y
  }"
>
  <div class="handle" cdkDragHandle></div>
  <div class="content">
    <app-dock #dockWindow [dockViewType]="dockViewModes.UNDOCK"></app-dock>
  </div>
  <div class="resizeHandleBR" #resizeHandle>
    <app-image [label]="'RESIZE_EDGE'"></app-image>
  </div>
</div>
