import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { GanttLibService } from '../../gantt/gantt-lib.service';
import { IGanttBlockEditNotification } from './gantt-edit.interface';

@Injectable()
export class GanttEditService {
  private editMode = false;
  private editModeSubject = new Subject<boolean>();
  private toggleGanttShiftBlockEdit = new Subject<IGanttBlockEditNotification>();

  constructor(private ganttLibService: GanttLibService) {}

  /**
   * Handles deactivation of gantt interaction.
   */
  public deactivateGanttShiftBlockEdit(editedBlockIds: string[]): void {
    this.ganttLibService.bestGantt.blockInteractionByShiftIds(editedBlockIds);
    this.toggleGanttShiftBlockEdit.next({ isEditable: false, editedBlockIds: editedBlockIds });
  }

  /**
   * Handles reactivation of gantt interaction based on previous edit settings.
   */
  public activateGanttShiftBlockEdit(editedBlockIds: string[]): void {
    this.ganttLibService.bestGantt.unBlockInteractionByShiftIds(editedBlockIds);
    this.toggleGanttShiftBlockEdit.next({ isEditable: true, editedBlockIds: editedBlockIds });
  }

  public onEditModeChange(): Observable<boolean> {
    return this.editModeSubject.asObservable();
  }

  public onGanttShiftBlockEditChange(): Observable<IGanttBlockEditNotification> {
    return this.toggleGanttShiftBlockEdit.asObservable();
  }

  public setEditMode(editMode: boolean) {
    if (this.editMode !== editMode) {
      this.editMode = editMode;
      this.editModeSubject.next(this.editMode);
    }
  }

  public isEditMode(): boolean {
    return this.editMode;
  }
}
