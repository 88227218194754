import { EGanttInstance, GanttScrollContainerEvent, GanttYAxisContextMenuEvent } from '@gantt/public-api';
import { GanttLibService } from 'frontend/src/dashboard/gantt/gantt/gantt-lib.service';
import { SaxMsBestGanttActiveSubmenuEntryElementSetting } from 'frontend/src/dashboard/gantt/gantt/saxms-best-gantt.settings';
import { GanttTemplateData } from 'frontend/src/dashboard/gantt/helper/gantt';
import { Observable, of } from 'rxjs';
import { first } from 'rxjs/operators';
import { GeneralGanttActionHandler } from '../../../action-handling/action-handler';
import { GanttContextMenuHandler } from '../../../generator/predefined/context-menu/context-menu';
import { ExternalGanttPlugin } from '../../external-plugin';
import { GanttPluginHandlerService } from '../../gantt-plugin-handler.service';

export const GanttBrokenConstraintsNavigatorBackendType = 'template.ganttplugin.brokenConstraintsNavigator';
export const GanttBrokenConstraintsNavigatorPlugInId = 'gantt-plugin-broken-constraints-navigator';

/**
 * PlugIn-Wrapper for GanttBrokenConstraintsNavigator.
 */
export class GanttBrokenConstraintsNavigatorPlugIn extends ExternalGanttPlugin {
  private _contextMenuEntryId: string = null;

  constructor(
    protected _ganttPluginHandlerService: GanttPluginHandlerService,
    protected _ganttLibService: GanttLibService,
    protected _actionHandler: GeneralGanttActionHandler
  ) {
    super(_ganttPluginHandlerService, _ganttLibService, _actionHandler);
  }

  public onInit(templateData: GanttTemplateData, responseData: any) {
    this.addPlugIn(
      GanttBrokenConstraintsNavigatorPlugInId,
      this._ganttLibService.ganttInstanceService.getInstance(EGanttInstance.BROKEN_CONSTRAINTS_NAVIGATOR)
    );
    this._initOverlay();
    this._initContextMenuEntry();
  }

  /**
   * Initializes the appearance of the overlay.
   */
  private _initOverlay(): void {
    const navigatorExecuter = this.getPlugInById(GanttBrokenConstraintsNavigatorPlugInId);
    navigatorExecuter.setShowLeftRightButtons(true);
    navigatorExecuter.setAutomaticNavigation(true);
    navigatorExecuter.setShowTitle(true, true);
  }

  /**
   * Initializes a new context menu entry & adds it to the row context menu of the gantt.
   */
  private _initContextMenuEntry(): void {
    this._ganttPluginHandlerService
      .getNotifiedIfInitialized()
      .pipe(first((value) => value === true))
      .subscribe(() => {
        this._contextMenuEntryId = `predefined_broken-constraints-navigator_${new Date().getTime()}`;

        const openNavigatorOverlay = (eventData: GanttScrollContainerEvent<GanttYAxisContextMenuEvent>) => {
          const originRowData = this._ganttLibService.ganttInstanceService
            .getInstance(EGanttInstance.Y_AXIS_DATA_FINDER)
            .getRowById(
              this._ganttLibService.bestGantt.getDataHandler().getOriginDataset().ganttEntries,
              eventData.event.rowData.id
            );
          if (!originRowData.data || !originRowData.data.additionalData) return;

          this.openOverlay(originRowData.data.additionalData.originalRowId);
          return of(null);
        };

        const contextMenuHandler: GanttContextMenuHandler = this._ganttPluginHandlerService
          .getPredefinedSettings()
          .getInstance(GanttContextMenuHandler);
        contextMenuHandler.addCustomRowContextMenuItem(
          this._contextMenuEntryId,
          '@broken-constraints-navigator@',
          openNavigatorOverlay,
          this,
          false
        );
      });
  }

  public onDestroy(): void {}

  /**
   * Show the navigation component.
   * @param {string} [rowId=null] Navigate only through broken constraints of the row with the specified id (if null, navigate through all rows).
   */
  public openOverlay(rowId: string = null): void {
    this.getPlugInById(GanttBrokenConstraintsNavigatorPlugInId).openOverlay(rowId);
  }

  public onAction(action: any) {}

  public executeAction(action: any): Observable<any> {
    return of();
  }

  public injectSettings(submenuElements: SaxMsBestGanttActiveSubmenuEntryElementSetting[]): void {}
}
