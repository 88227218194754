import { NodeProportionsStateConnector } from '../../html-structure/node-proportion-state/node-proportion-state-connector';
import { EGanttScrollContainer } from '../../html-structure/scroll-container.enum';
import { BestGantt } from '../../main';

export class GanttShiftScrollEventsHandler {
  ganttDiagram: BestGantt;

  constructor(ganttDiagram) {
    this.ganttDiagram = ganttDiagram;
  }

  /**
   * Recalculates dataset for shift canvas.
   */
  public animateShiftCanvasByScroll(): void {
    const s = this.ganttDiagram;

    s.filterRenderDataSetsByCurrentView();

    // animate canvas
    s.getShiftFacade().rerenderShiftArea(s.getRenderDataSetShifts());
  }

  /**
   * handles fundamental gantt behaviour function on scroll start.
   */
  public handleBehaviorOnScrollStart(): void {
    const s = this.ganttDiagram;

    s.getShiftFacade().closeTooltip();
    s.removeDueDateMarks();
    s.getXAxisBuilder().highlightXAxis();
  }

  /**
   * Stores the current top row in view port after scrolling.
   * Part of mechanism to correct scroll position after data has changed.
   * @param scrollContainerId
   */
  public storeCurrentTopRow(scrollContainerId: EGanttScrollContainer): void {
    const s = this.ganttDiagram;
    const nodeProportionsState = new NodeProportionsStateConnector(s.getNodeProportionsState(), scrollContainerId);

    const scrollTop = nodeProportionsState.getScrollTopPosition();
    const yAxisDataSet = s.getRenderDataHandler().getRenderDataYAxis(scrollContainerId);

    for (const row of yAxisDataSet) {
      const rowY = s.getRenderDataHandler().getStateStorage().getYPositionRow(row.id);
      if (rowY >= scrollTop) {
        // get the first full visible row
        nodeProportionsState.setCurrentTopRowId(row.id);
        nodeProportionsState.setCurrentTopRowOffset(rowY - scrollTop);
        if (row.originalResource) {
          nodeProportionsState.setCurrentTopOriginRowId(row.originalResource);
        } else {
          nodeProportionsState.setCurrentTopOriginRowId('');
        }
        break;
      }
    }
  }
}
