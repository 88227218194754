import { Template } from 'frontend/src/dashboard/model/resource/template';
import { ETemplateType } from 'frontend/src/dashboard/model/resource/template-type';
import { IndexRegister } from '../index-register/index-register';

export class Sidebar extends Template {
  private indexRegister: IndexRegister;
  private position: string;

  public getIndexRegister(): IndexRegister {
    return this.indexRegister;
  }

  public setIndexRegister(indexRegister: IndexRegister): this {
    this.indexRegister = indexRegister;
    return this;
  }

  public getPosition(): string {
    return this.position;
  }

  public setPosition(position: string): this {
    this.position = position;
    return this;
  }

  constructor() {
    super();
    this.setType(ETemplateType.SIDE_BAR);
  }
}
