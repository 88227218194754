import { GanttEssentialPlugIns } from 'frontend/src/dashboard/gantt/general/plugin/e-gantt-essential-plugins';
import { GanttXAxisManipulatorPlugIn } from 'frontend/src/dashboard/gantt/general/plugin/plugin-list/time-scaling/xaxis-manipulator';
import { Observable, of } from 'rxjs';
import { GanttPredefinedLocalAction } from '../predefined-local-action';
import { GanttLibService } from 'frontend/src/dashboard/gantt/gantt/gantt-lib.service';
import { GanttPluginHandlerService } from '../../../../plugin/gantt-plugin-handler.service';
import { EPredefinedAction } from '@app-modeleditor/components/button/action/predefined-action.enum';

export class GanttLocalActionResetZoom extends GanttPredefinedLocalAction {
  constructor(ganttPluginHandlerService: GanttPluginHandlerService, ganttLibService: GanttLibService) {
    super(ganttPluginHandlerService, ganttLibService);
    this.name = EPredefinedAction.RESET_ZOOM_GANTT;
  }

  public executeAction(action: any): Observable<any> {
    if (action.id === this.name) {
      const ganttDataset: any = this.ganttLibService.bestGantt.getDataHandler().getOriginDataset();
      const timeManipulator: GanttXAxisManipulatorPlugIn = this.ganttPluginHandlerService.getEssentialPlugIn(
        GanttEssentialPlugIns.XAxisManipulatorPlugIn
      );
      timeManipulator.changeTimeToInterval(new Date(ganttDataset.minValue), new Date(ganttDataset.maxValue));
    }
    return of(null);
  }
}
