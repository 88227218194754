import { GanttActionWrapper } from 'frontend/src/dashboard/gantt/general/gantt-actions/external-action-registration';
import { GanttEventTrigger } from 'frontend/src/dashboard/gantt/general/gantt-actions/external-event-trigger';
import { takeWhile } from 'rxjs/operators';

/**
 * Callback handling and template data saving for right bubble button.
 */
export class BlockAddAfterEvent extends GanttEventTrigger {
  /**
   * Adds callback with template saving and action handling to gantt.
   * @param ganttDiagram
   */
  public registerInGantt(): void {
    this.executer.addOnBubbleClickedCallback(this.action.id, (blockAddAfterParam) => {
      if (blockAddAfterParam.direction != 'RIGHT' || !blockAddAfterParam.shiftData) return;

      this.saveDataToTemplate(blockAddAfterParam.shiftData.id);
      const actionWrapper: GanttActionWrapper = {
        actionScope: this.action.actionScope,
        actions: this.action.actions,
      };
      this._ganttLibService.ngZone.run(() => {
        this.actionHandler
          .executeActions(actionWrapper)
          .pipe(takeWhile(() => this.actionHandler.isAlive()))
          .subscribe((data) => {
            this.responseHandler.handleUpdateNotification(data as any);
          });
      });
    });
  }

  /**
   * Removes callback from gantt.
   */
  public removeFromGantt(): void {
    super.removeFromGantt();
    this.executer.removeDragEndBeforeMarkerAdding(this.action.id);
  }

  /**
   * Stores event data into template. Allowes access to block which the bubble buttons belongs to.
   * @param shiftId Id of shift which had the activated bubble buttons.
   */
  public saveDataToTemplate(shiftId: string): void {
    // set temaplate data
    if (!this.templateData.getAddBlock()) this.templateData.setAddBlock({});
    this.templateData.getAddBlock().id = shiftId;
  }
}
