import { Action } from '@app-modeleditor/components/button/action/action';
import { EActionType } from '@app-modeleditor/components/button/action/action-type.enum';
import { EPredefinedAction } from '@app-modeleditor/components/button/action/predefined-action.enum';
import { Button } from '@app-modeleditor/components/button/button';
import { Content } from '@app-modeleditor/components/content/content';
import { ContentPart } from '@app-modeleditor/components/content/content-part/content-part';
import { EntryCollection } from '@app-modeleditor/components/entry-collection/entry-collection';
import { EntryElement } from '@app-modeleditor/components/entry-collection/entry-element';
import { EEntryElementPosition } from '@app-modeleditor/components/entry-collection/entry-element-position.enum';
import { EntryElementValue } from '@app-modeleditor/components/entry-collection/entry-element-value';
import { EFieldType } from '@app-modeleditor/components/entry-collection/field-type.enum';
import { Lightbox } from '@app-modeleditor/components/lightbox/lightbox';
import { Observable, of } from 'rxjs';
import { IFilterDisplayData } from './attribute-search.interface';

export class ResetFilterLightbox extends Lightbox {
  private confirm: Button;
  private cancel: Button;
  private filterCheckBoxes: EntryElement[] = [];

  constructor(name: string, private infoText: string, private displayData: IFilterDisplayData[]) {
    super();

    this.setName(name)
      .setResizeable(false)
      .setCanOpenExternalWindow(false)
      .setDisableSaveButton(true)
      .setDisableCancelButton(true);

    this.setContent(
      new Content().setContentParts([
        new ContentPart()
          .setDisplayContentpartContainer(false)
          .setContentElements([
            new EntryCollection().setEntryElements([this.getInfoText(), ...this.getFilterSelection()]),
          ]),
      ])
    );

    this.setButtons(this.getFooterButtons());
  }

  private getInfoText(): EntryElement {
    return new EntryElement()
      .setFieldType(EFieldType.TEXT)
      .setStyle('line-height: 25px;')
      .setValue(new EntryElementValue().setValue(this.infoText));
  }

  private getFilterSelection(): EntryElement[] {
    this.filterCheckBoxes = [];
    this.displayData.forEach((filterData: IFilterDisplayData) => {
      this.filterCheckBoxes.push(
        new EntryElement()
          .setFieldType(EFieldType.CHECK_BOX)
          .setId(filterData.filterId)
          .setValue(new EntryElementValue().setValue(true))
          .setName(filterData.label)
          .setStyle('padding-left: 10px;')
      );
    });

    return this.filterCheckBoxes;
  }

  private getSelectedFilters(): IFilterDisplayData[] {
    return this.displayData.filter((filterData: IFilterDisplayData) => {
      return this.filterCheckBoxes
        .find((filter: EntryElement) => {
          return filter.getId() === filterData.filterId;
        })
        .getValue<EntryElementValue>()
        .getValue();
    });
  }

  private getFooterButtons(): Button[] {
    return [
      (this.cancel = new Button()
        .setName('BUTTONS.CANCEL')
        .setOrder(-999)
        .chainActions(new Action().setActionType(EActionType.PREDEFINED).setId(EPredefinedAction.CANCEL_LIGHTBOX))),
      (this.confirm = new Button()
        .setPosition(EEntryElementPosition.RIGHT)
        .setName('BUTTONS.CONFIRM')
        .setOrder(999)
        .chainActions(new Action().setActionType(EActionType.PREDEFINED).setId(EPredefinedAction.CLOSE_LIGHTBOX))),
    ];
  }

  public setConfirmLabel(confirmLabel: string): this {
    this.confirm.setName(confirmLabel);
    return this;
  }

  public setDisableCancelButton(state: boolean): this {
    super.setDisableCancelButton(state);
    if (this.cancel) {
      this.cancel.setShow(!state);
    }
    return this;
  }

  setConfirmAction(cb: (data: IFilterDisplayData[]) => void): this {
    this.confirm.chainActions(new Action().setCb(() => of(cb(this.getSelectedFilters()))));
    return this;
  }

  setCustomCancelAction(cb: () => Observable<void>): this {
    this.cancel.chainActionsBefore(new Action().setCb(cb));
    this.setOnCustomCancelAction(this.cancel);
    return this;
  }
}
