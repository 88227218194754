import { Observable, Subject, takeUntil } from 'rxjs';
import { GanttConfig } from '../../config/gantt-config';
import { GanttFontSizeCalculator } from '../../font-tools/font-size';
import { BestGanttPlugIn } from '../gantt-plug-in';

/**
 * Adds text columns to y axis of gantt.
 * @keywords plugin, y axis, yaxis, table, excel, data, child, row, executer
 * @plugin add-y-axis-table
 * @class
 * @constructor
 * @extends BestGanttPlugIn
 *
 * @requires GanttFontSizeCalculator
 * @requires BestGanttPlugIn
 * @requires GanttYAxis
 *
 * @deprecated This plug-in is no longer used (2023-11-29).
 */
export class GanttYAxisTableBuilder extends BestGanttPlugIn {
  fontSizeCalculator: GanttFontSizeCalculator;
  canvas: d3.Selection<HTMLDivElement, undefined, d3.BaseType, undefined>;
  config: GanttConfig;
  columnWidth: number;
  tableRowData: any;
  tableColumnsData: any;
  groupedRows: any[];
  positionOfFirstColumnData: any;
  widthOfColumnData: any;
  stickyParentRowAdjustment: any;

  private _onDestroySubject: Subject<void> = new Subject<void>();

  constructor() {
    super(); //call super-constructor

    this.fontSizeCalculator = new GanttFontSizeCalculator();

    this.canvas = null;
    this.config = null;
    this.groupedRows = null;
    this.tableRowData = {};
    this.positionOfFirstColumnData = {};
    this.widthOfColumnData = {};
    this.stickyParentRowAdjustment = {
      connected: false, // get updated yAxisDataset to position area correctly
      yAxisDataset: null,
    };

    // config
    this.columnWidth = 32;
  }

  /**
   * @override
   */
  initPlugIn(ganttDiagram) {
    const s = this;

    s.ganttDiagram = ganttDiagram;

    s.config = s.ganttDiagram.getConfig();
    s.canvas = s.ganttDiagram
      .getHTMLStructureBuilder()
      .getYAxisContainer()
      .append('div')
      .attr('class', 'gantt-y-axis-table-builder')
      .style('position', 'absolute')
      .style('top', s.config.rowFontSize() - 9 + 'px');

    s.ganttDiagram
      .getOpenRowHandler()
      .afterShiftRowOpen.pipe(takeUntil(this.onDestroy))
      .subscribe(() => s._afterChangeRowOpenStatus());
    s.ganttDiagram
      .getOpenRowHandler()
      .afterShiftRowClosed.pipe(takeUntil(this.onDestroy))
      .subscribe(() => s._afterChangeRowOpenStatus());

    s.config.addAfterShiftFontSizeChangedCallback(`updateYAxisTableFontSize ${s.UUID}`, () => {
      s._initFontSizeCalculator();
      s.groupedRows = s._getAllRowsGrouped(); // get all rows from origin data set grouped by hierarchy
      s._calculatePositionOfFirstColumnsByRow();
      s._calculateColumnWidths();
    });
    s._initFontSizeCalculator();

    s.groupedRows = s._getAllRowsGrouped(); // get all rows from origin data set grouped by hierarchy
    s._calculatePositionOfFirstColumnsByRow();
    s._calculateColumnWidths();

    s.ganttDiagram.subscribeOriginDataUpdate(`update-Grouped-Rows-yAxisTable-Plugin ${s.UUID}`, () => {
      s.groupedRows = s._getAllRowsGrouped(); // get all rows from origin data set grouped by hierarchy
      s._calculatePositionOfFirstColumnsByRow();
      s._calculateColumnWidths();
    });
  }

  private _initFontSizeCalculator() {
    const s = this;
    const d3SVG = s.ganttDiagram.getYAxisFacade().getYAxisBuilder().getCanvas().append<SVGSVGElement>('svg');

    s.fontSizeCalculator.init(d3SVG.node(), s.config.rowFontSize());

    d3SVG.remove();
  }

  updateYAxisDataset(datasetReference) {
    const s = this;
    s.stickyParentRowAdjustment.yAxisDataset = datasetReference.yAxisDataset;
    s.update();
  }

  /**
   * @override
   */
  update() {
    const s = this;
    s.canvas.style('top', s.config.rowFontSize() - 9 + 'px');
    s.build();
  }

  /**
   * @override
   */
  updatePlugInHeight() {
    const s = this;
    //s.canvas.style("top", s.config.rowFontSize() - 9 + 'px');
    // s.fontSizeCalculator.updateFontSize(s.config.rowFontSize());
    s.build();
  }

  /**
   * Remove of plugin. Part of plugin lifecycle.
   */
  removePlugIn() {
    const s = this;

    // callback unsubscribe
    s.config.removeAfterShiftFontSizeChangedCallback(`updateYAxisTableFontSize ${s.UUID}`);

    s.config.removeAfterShiftFontSizeChangedCallback(`updateYAxisTableFontSize ${s.UUID}`);

    s.config.removeAfterShiftFontSizeChangedCallback(`updateYAxisTableFontSize ${s.UUID}`);

    s._onDestroySubject.next();
    s._onDestroySubject.complete();

    // remove canvas
    s.canvas.remove();
  }

  /**
   * Removes and builds y axis table group with its text out of y axis table canvas dataset.
   */
  build() {
    const s = this;
    s._removeAll();
    s._renderText();
  }

  /**
   * Adds columns by row id.
   * @param {string} rowId Id of row witch columns are to be added
   * @param {string[]} columnValues Values to be displayed in the columns. Each position of array is a column.
   */
  addDataSetByRowId(rowId, columnValues) {
    if (!rowId || !columnValues) {
      return;
    }
    this.tableRowData[rowId] = columnValues;
  }

  /**
   * Removes all y axis table elements from canvas.
   */
  private _removeAll() {
    this.canvas.selectAll('.gantt-y-axis-table-builder-column').remove();
  }

  /**
   * Calculates the first position of a column in row.
   * Position depends on the widest element of hierarchy group.
   */
  private _calculatePositionOfFirstColumnsByRow() {
    const s = this;
    if (!s.groupedRows) {
      return;
    }

    s.positionOfFirstColumnData = {}; // clear position data set

    // iterate over grouped rows
    s.groupedRows.forEach((group) => {
      let textWidth = 0;
      // iterate over each row and calculate the width of it
      group.children.forEach((child) => {
        const calculatedWidth = group.layer * 8 + Math.ceil(s.fontSizeCalculator.getTextWidth(child.name)) + 50;
        textWidth = Math.max(textWidth, calculatedWidth); // set if row is larger than previous result
      });
      // add text end position property and set the widest point of row group
      group.textEndPosition = textWidth;
    });

    // save row id with text end position of group in data set
    s.groupedRows.forEach((group) => {
      group.children.forEach((child) => (s.positionOfFirstColumnData[child.id] = group.textEndPosition));
    });
  }

  /**
   * Calculates the max width of table column width by the longest value of table row data by each group.
   */
  private _calculateColumnWidths() {
    const s = this;
    if (!s.groupedRows) {
      return;
    }

    s.widthOfColumnData = {}; // reset width dataset

    for (const rowGroup of s.groupedRows) {
      // iterate over all groups
      let maxColumnWidth = 0;

      for (const row of rowGroup.children) {
        // iterate over all rows of group
        const tableData = s.tableRowData[row.id];
        if (tableData) {
          // if table data is available for row
          for (const stringValue of tableData) {
            // check all string values
            maxColumnWidth = Math.max(maxColumnWidth, Math.ceil(s.fontSizeCalculator.getTextWidth(stringValue + ''))); // set widest text
          }
        }
      }

      for (const row of rowGroup.children) {
        // set the widest text width to all rows of the group
        s.widthOfColumnData[row.id] = maxColumnWidth;
      }
    }
  }

  /**
   * @typedef {Object} GroupedRow
   * @property {Array} children Array of children (rows).
   * @property {number} layer Index / Layer in hierarchy.
   */
  /**
   * Returns all rows from origin data set grouped by hierarchy.
   * @return {Array.<GroupedRow>}
   */
  private _getAllRowsGrouped() {
    const s = this;
    const groupedRows = [];

    function getAllChildren(array, layer) {
      groupedRows.push({ children: array, layer: layer });
      for (const child of array) {
        if (child.child.length) {
          getAllChildren(child.child, layer + 1);
        }
      }
    }
    const dataSet = s.ganttDiagram.getDataHandler().getOriginDataset().ganttEntries;
    getAllChildren(dataSet, 0); // go recursively
    return groupedRows;
  }

  removeById(id) {
    delete this.tableRowData[id];
    this._removeAll();
    this.update();
  }

  /**
   * Builds text of y axis table by using canvas of y axis.
   */
  private _renderText() {
    const s = this;

    let yAxisDataset = s.stickyParentRowAdjustment.yAxisDataset;
    if (!yAxisDataset) {
      yAxisDataset = s.ganttDiagram.getDataHandler().getYAxisDataset();
    }

    for (const rowId in s.tableRowData) {
      const canvasRow = yAxisDataset.find((row) => row.id === rowId);
      if (!canvasRow) {
        continue;
      }
      const textEndPositionOfColumn = s.positionOfFirstColumnData[rowId];
      if (!textEndPositionOfColumn) {
        continue;
      }
      const table = s.canvas
        .append('div')
        .attr('class', 'gantt-y-axis-table-builder-column')
        .style('position', 'absolute')
        .style('left', textEndPositionOfColumn + 'px')
        .style('top', canvasRow.y + 'px')
        .append('table')
        .append('tr');

      const columns = s.tableRowData[rowId];
      for (let i = 0; i < columns.length; i++) {
        const columnValue = columns[i];
        table
          .append('td')
          .append('div')
          .style('text-align', isNaN(columnValue) ? 'left' : 'right')
          .style('text-overflow', 'ellipsis')
          .style('overflow', 'hidden')
          .style('font-size', function () {
            if (s.config) return s.config.rowFontSize() + 'px';
          })
          .style('color', function () {
            return canvasRow.textColor;
          })
          .style('width', (s.widthOfColumnData[rowId] || 0) + 'px')
          .text(columnValue);
      }
    }
  }

  //
  // GETTER & SETTER
  //
  /**
   * @param {string} id Table Column datawarpper id.
   * @return {GanttYAxisTableBuilderColumnDataContainer} Datacontainer for column data with given id.
   */
  getDataElementById(id) {
    return this.tableColumnsData[id];
  }
  /**
   * Returns all column data of y axis table.
   * @return {Map<string,GanttYAxisTableBuilderColumnDataContainer>} Column data with save ids.
   */
  getDataElements() {
    return this.tableColumnsData;
  }

  /**
   * Change horizontal position of y axis groups after row closing and opening:
   * Rebuild y axis table and change group  translation to avoid overlapping/big gaps caused by changed text width caused by open/close rows.
   * @private
   */
  private _afterChangeRowOpenStatus() {
    const s = this;
    s._removeAll();
    s._renderText();
  }

  //
  // OBSERVABLES
  //

  /**
   * Observable which gets triggered when the instance gets destroyed.
   */
  private get onDestroy(): Observable<void> {
    return this._onDestroySubject.asObservable();
  }
}
