<saxms-lightbox>
  <div class="headline">
    {{
      title
        ? ('FORMS.ITEMS.CREATE_BY_VALUE' | translate : { value: title } | capitalize)
        : ('FORMS.ITEMS.CREATE_BY_VALUE' | translate | capitalize)
    }}
  </div>
  <div class="content">
    <mat-form-field *ngIf="hasName">
      <input
        [id]="'saxms-ui-dialog-name-input'"
        #name
        [required]="true"
        matInput
        [placeholder]="'FORMS.ITEMS.LABEL' | translate"
        [(ngModel)]="label"
      />
    </mat-form-field>
    <mat-form-field *ngIf="options?.length > 1">
      <mat-select
        [id]="'saxms-ui-dialog-type-select'"
        [placeholder]="'FORMS.ITEMS.SELECT' | translate"
        [(ngModel)]="selectedItem"
      >
        <mat-option *ngFor="let item of options" [value]="item">
          {{ item.title }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="actionbarBottom">
    <button [id]="'saxms-dialog-cancel'" class="submit" mat-raised-button (click)="cancel()">
      {{ 'BUTTON.CANCEL' | translate | capitalize }}
    </button>
    <button
      [id]="'saxms-dialog-confirm'"
      [disabled]="getDisabledStateOfSave()"
      class="submit"
      mat-raised-button
      (click)="create()"
    >
      {{ 'BUTTON.CREATE' | translate | capitalize }}
    </button>
  </div>
</saxms-lightbox>
