import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatDividerModule } from '@angular/material/divider';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { ImageModule } from '@app-modeleditor/components/image/image.module';
import { MultiSelectorVirtualOptionComponent } from './option.component';
import { SelectorComponent } from './selector.component';
import { SaxmsTooltipModule } from '../tooltip/tooltip.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    MatAutocompleteModule,
    MatIconModule,
    MatChipsModule,
    MatButtonModule,
    FormsModule,
    MatFormFieldModule,
    MatTooltipModule,
    MatInputModule,
    MatCheckboxModule,
    MatDividerModule,
    ImageModule,
    ScrollingModule,
    MatMenuModule,
    SaxmsTooltipModule,
    DragDropModule,
  ],
  declarations: [SelectorComponent, MultiSelectorVirtualOptionComponent],
  exports: [SelectorComponent],
})
export class SelectorModule {}
