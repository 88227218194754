import { EntryElement } from '@app-modeleditor/components/entry-collection/entry-element';
import { ETemplateType } from 'frontend/src/dashboard/model/resource/template-type';
import { ESortType } from './sort-type.enum';

export class TableHeader extends EntryElement {
  private columnIndex: number;
  private columnName: string;
  private filter: any;
  private timeValue: number;
  private preferredWidth: number;
  private generatedAutomatically: boolean;
  private requireFullAssignment: boolean;
  private windUpOperation: any;
  private searchable: boolean;
  private sortType: ESortType;
  private sortable: boolean;
  private tableHeaders: TableHeader[];
  private availableValues: any[];
  private entryElement: EntryElement;
  private autoSave: boolean;
  private generalInfoText: string;
  private thousandsSeparator: boolean;
  private nestedTableHeader: any;
  private dateTimeFormat: string;

  public getNestedTableHeader(): any {
    return this.nestedTableHeader;
  }

  public setNestedTableHeader(nestedTableHeader: any): this {
    this.nestedTableHeader = nestedTableHeader;
    return this;
  }

  public isThousandsSeparator(): boolean {
    return typeof this.thousandsSeparator === 'boolean' ? this.thousandsSeparator : false;
  }

  public setThousandsSeparator(thousandsSeparator: boolean): this {
    this.thousandsSeparator = thousandsSeparator;
    return this;
  }

  public isNullable(): boolean {
    return typeof this.nullable === 'boolean' ? this.nullable : true;
  }

  public isAutoSave(): boolean {
    return this.autoSave;
  }

  public setAutoSave(autoSave: boolean): this {
    this.autoSave = autoSave;
    return this;
  }

  public getEntryElement(): EntryElement {
    return this.entryElement;
  }

  public setEntryElement(entryElement: EntryElement): this {
    this.entryElement = entryElement;
    return this;
  }

  public getTableHeaders(): TableHeader[] {
    return this.tableHeaders || [];
  }

  public setTableHeaders(tableHeaders: TableHeader[]): this {
    this.tableHeaders = tableHeaders;
    return this;
  }
  public getAvailableValues(): any[] {
    return this.availableValues || [];
  }

  public setAvailableValues(availableValues: any[]): this {
    this.availableValues = availableValues;
    return this;
  }
  public getColumnIndex(): number {
    return this.columnIndex;
  }

  public setColumnIndex(columnIndex: number): this {
    this.columnIndex = columnIndex;
    return this;
  }

  public getTimeValue(): number {
    return this.timeValue;
  }

  public setTimeValue(timeValue: number): this {
    this.timeValue = timeValue;
    return this;
  }

  public getPreferredWidth(): number {
    return this.preferredWidth;
  }

  public setPreferredWidth(preferredWidth: number): this {
    this.preferredWidth = preferredWidth;
    return this;
  }

  public getColumnName(): string {
    return this.columnName;
  }

  public setColumnName(columnName: string): this {
    this.columnName = columnName;
    return this;
  }

  public getFilter(): any {
    return this.filter;
  }

  public setFilter(filter: any): this {
    this.filter = filter;
    return this;
  }

  public getWindUpOperation(): any {
    return this.windUpOperation;
  }

  public setWindUpOperation(windUpOperation: any): this {
    this.windUpOperation = windUpOperation;
    return this;
  }

  public isGeneratedAutomatically(): boolean {
    return this.generatedAutomatically;
  }

  public setGeneratedAutomatically(generatedAutomatically: boolean): this {
    this.generatedAutomatically = generatedAutomatically;
    return this;
  }

  public isRequireFullAssignment(): boolean {
    return this.requireFullAssignment;
  }

  public setRequireFullAssignment(requireFullAssignment: boolean): this {
    this.requireFullAssignment = requireFullAssignment;
    return this;
  }

  public isSearchable(): boolean {
    return this.searchable;
  }

  public setSearchable(searchable: boolean): this {
    this.searchable = searchable;
    return this;
  }

  public getSortType(): ESortType {
    return this.sortType;
  }

  public setSortType(sortType: ESortType): this {
    this.sortType = sortType;
    return this;
  }

  public isSortable(): boolean {
    return this.sortable;
  }

  public setSortable(sortable: boolean): this {
    this.sortable = sortable;
    return this;
  }

  public getGeneralInfoText(): string {
    return this.generalInfoText;
  }

  public setGeneralInfoText(generalInfoText: string): this {
    this.generalInfoText = generalInfoText;
    return this;
  }

  public getDateTimeFormat(): string {
    return this.dateTimeFormat;
  }

  public setDateTimeFormat(dateTimeFormat: string): this {
    this.dateTimeFormat = dateTimeFormat;
    return this;
  }

  constructor() {
    super();
    this.setType(ETemplateType.TABLE_HEADER);
  }
}
