import * as d3 from 'd3';

import { GanttConfig } from '../../config/gantt-config';
import { YAxisDataFinder } from '../../data-handler/data-finder/yaxis-data-finder';
import { DataHandler } from '../../data-handler/data-handler';
import { GanttUtilities } from '../../gantt-utilities/gantt-utilities';
import { BestGantt } from '../../main';
import { GanttYAxis } from '../../y-axis/y-axis';
import { GanttSplitOverlappingShifts } from '../../plug-ins/split-overlapping-shifts/split-overlapping-shifts-executer';

/**
 * Handles drag and drop of y axis row items.
 * @class
 * @constructor
 * @param {GanttYAxis} yAxisExecuter
 * @param {DataHandler} dataHandler
 * @param {BestGantt} ganttDiagram
 * @param {GanttConfig} config
 */
export class GanttYAxisTranslation {
  yAxisExecuter: GanttYAxis;
  dataHandler: DataHandler;
  ganttDiagram: BestGantt;
  config: GanttConfig;
  yAxisTargetMarker: d3.Selection<HTMLDivElement, undefined, d3.BaseType, undefined>;
  shiftAreaTargetMarker: d3.Selection<HTMLDivElement, undefined, d3.BaseType, undefined>;
  currentDraggedRow: any;
  isDragging: boolean;
  ghostRowWrapper: d3.Selection<HTMLDivElement, undefined, d3.BaseType, undefined>;
  positionOfDraggedRow: number;
  resultPosition: number;
  dragStartPos: any;
  ghostStartPos: any;

  constructor(yAxisExecuter, dataHandler, ganttDiagram, config) {
    this.yAxisExecuter = yAxisExecuter;
    this.dataHandler = dataHandler;
    this.ganttDiagram = ganttDiagram;
    this.config = config;

    this.yAxisTargetMarker = null;
    this.shiftAreaTargetMarker = null;
    this.currentDraggedRow = null;
    this.isDragging = false;
    this.ghostRowWrapper = null;
    this.positionOfDraggedRow = null;
    this.resultPosition = null;

    this.dragStartPos = { x: 0, y: 0 };
    this.ghostStartPos = { x: 0, y: 0 };
  }

  /**
   * Called on translation start event.
   * @param {GanttYAxisDragEventCallbackData} eventWrapper
   */
  translationStart(eventWrapper) {
    const s = this;
    if (!s.yAxisExecuter.getAllowDragDrop()) {
      return;
    }

    s.dragStartPos = {
      x: eventWrapper.event.x,
      y: eventWrapper.event.y,
    };
  }

  /**
   * Called on translation drag event.
   *
   * @param {GanttYAxisDragEventCallbackData} eventWrapper Callback data from y axis drag.
   */
  translationDragging(eventWrapper) {
    const s = this;
    if (!s.yAxisExecuter.getAllowDragDrop()) {
      return;
    }

    const mouseMoveY = eventWrapper.event.y - s.dragStartPos.y;

    if (!s.isDragging) {
      // mechanism to prevent accidental dragging
      if (Math.abs(mouseMoveY) > 5) {
        s.isDragging = true;
        s._translationStartIntern(eventWrapper);
      }
      return;
    }

    const mouseYPosInContainer = s._getMouseYPositionInYAxisContainer(eventWrapper);
    s._setPositionOfTargetMarkerByMouseYPosition(mouseYPosInContainer);
    // move ghost row
    s.ghostRowWrapper.style('top', s.ghostStartPos.y + mouseMoveY + 'px');
  }

  /**
   * Called on translation end event.
   *
   */
  translationEnd() {
    const s = this;
    if (!s.yAxisExecuter.getAllowDragDrop()) {
      return;
    }

    s._changePositionOfRowInDataset(s.currentDraggedRow, s.resultPosition);

    // remove helper objects
    s.ganttDiagram.getHTMLStructureBuilder().getYAxisContainer().selectAll('.overlayDrag').remove();
    s.ganttDiagram.getHTMLStructureBuilder().getShiftContainer().selectAll('.overlayDrag').remove();
    if (s.ghostRowWrapper) {
      s.ghostRowWrapper.remove();
    }
    if (s.yAxisTargetMarker) {
      s.yAxisTargetMarker.remove();
    }
    if (s.shiftAreaTargetMarker) {
      s.shiftAreaTargetMarker.remove();
    }

    // reset flags
    s.currentDraggedRow = null;
    s.isDragging = false;
  }

  /**
   * Handles internal drag start. Is executed separately, because the start is executed delayed.
   * @param {GanttYAxisDragEventCallbackData} eventWrapper
   * @private
   */
  private _translationStartIntern(eventWrapper) {
    const s = this;

    s.currentDraggedRow = YAxisDataFinder.getFirstConnectedRow(
      s.ganttDiagram.getDataHandler().getYAxisDataset(),
      eventWrapper.target.data()[0].id
    );
    s.positionOfDraggedRow = s.ganttDiagram.getDataHandler().getYAxisDataset().indexOf(s.currentDraggedRow);
    const heightOfDraggedRows =
      YAxisDataFinder.getHeightOfMemberRowsByOriginRowID(
        s.currentDraggedRow.id,
        s.ganttDiagram.getDataHandler().getYAxisDataset()
      ) + s.currentDraggedRow.height;
    const clickedAreaHeight = heightOfDraggedRows - 1;
    s._generateOverlays(s.currentDraggedRow.y, clickedAreaHeight, '#7777AA'); // clicked area highlighting
    s._generateGhostRow(s.currentDraggedRow.y, clickedAreaHeight, 0.7);
    s._generateTargetMarkers();
    s._coverAllRowsWithUnequalLayer(s.currentDraggedRow.layer);
  }

  /**
   * Generates overlays on y-axis and shift area by the given top position.
   * @param {number} topPosition top position of overlay
   * @param {number} height height of overlay
   * @param {string} color color of overlay
   * @private
   */
  private _generateOverlays(topPosition, height, color) {
    const s = this;

    // y-axis overlay
    s.ganttDiagram
      .getHTMLStructureBuilder()
      .getYAxisContainer()
      .append('div')
      .attr('class', 'overlayDrag')
      .style('position', 'absolute')
      .style('left', 0)
      .style('top', topPosition + 'px')
      .style('z-index', '2')
      .style('width', '100%')
      .style('height', height + 'px')
      .style('pointer-events', 'none')
      .style('background-color', color)
      .style('opacity', '0.5');

    // shift area overlay
    s.ganttDiagram
      .getHTMLStructureBuilder()
      .getShiftContainer()
      .append('div')
      .attr('class', 'overlayDrag')
      .style('position', 'absolute')
      .style('left', 0)
      .style('top', topPosition + 'px')
      .style('z-index', '2')
      .style('width', '100%')
      .style('height', height + 'px')
      .style('pointer-events', 'none')
      .style('background-color', color)
      .style('opacity', '0.3');
  }

  /**
   * Generates a ghost row of the dragged row.
   * @param {number} topPosition Top position of ghost row
   * @param {number} height Height of ghost row
   * @param {number} opacity Opacity of ghost row
   * @private
   */
  private _generateGhostRow(topPosition, height, opacity) {
    const s = this;

    s.ghostStartPos.y = topPosition;

    s.ghostRowWrapper = this.ganttDiagram
      .getHTMLStructureBuilder()
      .getYAxisContainer()
      .append('div')
      .attr('class', 'ghostRow')
      .style('position', 'absolute')
      .style('left', 0)
      .style('top', topPosition + 'px')
      .style('z-index', '4')
      .style('width', '100%')
      .style('height', height + 'px')
      .style('user-select', 'none')
      .style('border', '2px solid white')
      .style('opacity', opacity);

    // never used & causing errors:
    /*
    const nodes = s.ganttDiagram
      .getYAxisBuilder()
      .getCanvas()
      .selectAll(".rowElement")
      .filter(function (d: any) { return d.y >= topPosition && d.y < topPosition + height; })
      .each(function (d: any) {
        const nodeCopy = d3.select(this).node().cloneNode(true); // copy node
        d3.select(nodeCopy)
          .style("top", d.y - topPosition + 'px')
          .style("cursor", "grabbing");
        s.ghostRowWrapper.node().appendChild(nodeCopy);
      });
    */
  }

  /**
   * Generates target markers to visualize the target position.
   * @private
   */
  private _generateTargetMarkers() {
    const s = this;

    // target marker in y axis
    s.yAxisTargetMarker = this.ganttDiagram
      .getHTMLStructureBuilder()
      .getYAxisContainer()
      .append('div')
      .attr('class', 'targetMarker')
      .style('position', 'absolute')
      .style('left', 0)
      .style('top', -4 + 'px')
      .style('z-index', '3')
      .style('width', '100%')
      .style('height', 6 + 'px')
      .style('pointer-events', 'none')
      .style('background-color', 'blue');

    // target marker in shift area
    s.shiftAreaTargetMarker = this.ganttDiagram
      .getHTMLStructureBuilder()
      .getShiftContainer()
      .append('div')
      .attr('class', 'targetMarker')
      .style('position', 'absolute')
      .style('left', 0)
      .style('top', -4 + 'px')
      .style('z-index', '3')
      .style('width', '100%')
      .style('height', 6 + 'px')
      .style('pointer-events', 'none')
      .style('background-color', 'blue');
  }

  /**
   * Executes the position swap of the dragged row.
   * @param {object} rowToChange Y-axis row data
   * @param {number} newPosition Number of row position in y-axis dataset
   * @private
   */
  private _changePositionOfRowInDataset(rowToChange, newPosition) {
    const s = this;
    if (newPosition == null || !rowToChange) {
      return;
    }

    const canvasDataset = s.ganttDiagram.getDataHandler().getYAxisDataset();
    const originDataSet = s.ganttDiagram.getDataHandler().getOriginDataset().ganttEntries;

    const rowOverTarget =
      newPosition > 0 ? YAxisDataFinder.getFirstConnectedRow(canvasDataset, canvasDataset[newPosition - 1].id) : null;
    const rowUnderTarget =
      newPosition < canvasDataset.length
        ? YAxisDataFinder.getFirstConnectedRow(canvasDataset, canvasDataset[newPosition].id)
        : null;
    let targetIndexPosition = 0;
    const overlappingShiftsPlugins = s.ganttDiagram.getPlugInHandler().getPlugInsOfType(GanttSplitOverlappingShifts);
    const overlappingShiftsPlugin = overlappingShiftsPlugins.length ? overlappingShiftsPlugins[0] : null;

    if (overlappingShiftsPlugin) {
      overlappingShiftsPlugin.resetSplitOverlappingShifts(false); // reset split if plugin is active
    }

    const foundRowData = YAxisDataFinder.getRowById(originDataSet, rowToChange.id);
    if (!foundRowData.data) {
      return;
    }

    const parentData = foundRowData.parent ? foundRowData.parent.child : originDataSet;
    const draggedIndexPosition = parentData.findIndex((row) => row.id == rowToChange.id);

    if (rowOverTarget && rowOverTarget.layer == rowToChange.layer) {
      // push row under this row
      targetIndexPosition = parentData.findIndex((row) => row.id == rowOverTarget.id);
      if (draggedIndexPosition > targetIndexPosition) targetIndexPosition++; // increase if dragged index higher then target index
    } else if (rowUnderTarget && rowUnderTarget.layer == rowToChange.layer) {
      // push row over this row
      targetIndexPosition = parentData.findIndex((row) => row.id == rowUnderTarget.id);
    }

    GanttUtilities.moveArrayElementPosition(parentData, draggedIndexPosition, targetIndexPosition);

    if (overlappingShiftsPlugin) {
      overlappingShiftsPlugin.splitOverlappingShifts(null, true); // split rows if plugin is active
    } else {
      s.ganttDiagram.update();
    }
  }

  /**
   * Calculates the mouse y position in y axis container.
   * @param {GanttYAxisDragEventCallbackData} eventWrapper Callback data from y axis drag.
   * @returns {number} y axis position
   * @private
   */
  private _getMouseYPositionInYAxisContainer(eventWrapper) {
    const s = this;

    const yAxisContainerNode = s.ganttDiagram.getHTMLStructureBuilder().getYAxisContainer().node();

    return (
      eventWrapper.event.sourceEvent.pageY -
      yAxisContainerNode.getBoundingClientRect().top +
      yAxisContainerNode.scrollTop
    ); // y position within the element
  }

  /**
   * Handles covering all rows that are on another layer.
   * @param {number} layer Layer of dragged row.
   * @private
   */
  private _coverAllRowsWithUnequalLayer(layer) {
    const s = this;
    const dataset = s.ganttDiagram.getDataHandler().getYAxisDataset();
    const parentRowIdOfCurrentDraggedRow = YAxisDataFinder.getParentRowById(
      s.ganttDiagram.getDataHandler().getOriginDataset().ganttEntries,
      s.currentDraggedRow.id
    );

    dataset.forEach((rowData) => {
      const parentRowIdOfElement = YAxisDataFinder.getParentRowById(
        s.ganttDiagram.getDataHandler().getOriginDataset().ganttEntries,
        rowData.id
      );
      if (rowData.layer != layer || (rowData.layer > 0 && parentRowIdOfElement != parentRowIdOfCurrentDraggedRow)) {
        s._generateOverlays(rowData.y, rowData.height, 'white');
      }
    });
  }

  /**
   * Handles the target position validation and the rendering of the target markers.
   * @param {number} mouseYPosition Mouse y position in y axis container.
   * @private
   */
  private _setPositionOfTargetMarkerByMouseYPosition(mouseYPosition) {
    const s = this;
    let resultY = 0;
    const dataset = s.ganttDiagram.getDataHandler().getYAxisDataset();
    const originDataSet = s.ganttDiagram.getDataHandler().getOriginDataset().ganttEntries;
    let _resultPosition = dataset.findIndex((row) => mouseYPosition >= row.y && mouseYPosition < row.height + row.y);

    // saving bounds
    _resultPosition = _resultPosition < 0 ? 0 : _resultPosition;
    _resultPosition = _resultPosition > dataset.length ? dataset.length : _resultPosition;

    const rowUnderTarget = _resultPosition < dataset.length ? dataset[_resultPosition] : null;
    const rowOverTarget = _resultPosition > 0 ? dataset[_resultPosition - 1] : null;

    if (
      _resultPosition == s.positionOfDraggedRow || // same position of dragged row
      _resultPosition == s.positionOfDraggedRow + 1 || // same position of dragged row
      (!rowOverTarget && s.currentDraggedRow.layer > 0) || // not allow out of bounds if layer is greater than 0
      (!rowUnderTarget && s.currentDraggedRow.layer > 0) || // not allow out of bounds if layer is greater than 0
      (rowOverTarget && rowOverTarget.originalResource && rowOverTarget.originalResource == s.currentDraggedRow.id) || // row is member row of dragged row
      (rowUnderTarget &&
        rowUnderTarget.layer != s.currentDraggedRow.layer &&
        rowOverTarget &&
        rowOverTarget.layer != s.currentDraggedRow.layer) || // target and dragged row have different layers
      (rowUnderTarget && rowUnderTarget.layer > s.currentDraggedRow.layer) || // target and dragged row have different layers
      (s.currentDraggedRow.layer > 0 &&
        rowUnderTarget &&
        rowUnderTarget.layer == s.currentDraggedRow.layer &&
        YAxisDataFinder.getParentRowById(originDataSet, s.currentDraggedRow.id) !=
          YAxisDataFinder.getParentRowById(originDataSet, rowUnderTarget.id)) ||
      (s.currentDraggedRow.layer > 0 &&
        rowOverTarget &&
        rowOverTarget.layer == s.currentDraggedRow.layer &&
        YAxisDataFinder.getParentRowById(originDataSet, s.currentDraggedRow.id) !=
          YAxisDataFinder.getParentRowById(originDataSet, rowOverTarget.id)) // target and dragged row have same layer but not the same parent row
    ) {
      s.yAxisTargetMarker.style('top', -6 + 'px'); // -> not visualized
      s.shiftAreaTargetMarker.style('top', -6 + 'px'); // -> not visualized
      s.resultPosition = null;
      return;
    }

    if (rowUnderTarget && rowUnderTarget.group == 'MEMBER') {
      // target is between splitted rows
      s.resultPosition = null;
      return;
    }

    resultY = dataset[_resultPosition].y - 3;

    s.yAxisTargetMarker.style('top', resultY + 'px');
    s.shiftAreaTargetMarker.style('top', resultY + 'px');
    s.resultPosition = _resultPosition;
  }
}
