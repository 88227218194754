<mat-form-field class="field" [style.width]="'150px'" [floatLabel]="'never'">
  <mat-label>{{ 'FILTER.ValueField' | translate }}</mat-label>
  <input
    [disabled]="template.isDisabled()"
    [style.text-align]="'right'"
    matInput
    [ngModel]="template.getFirstValue()"
    (ngModelChange)="template.setFirstValue($event)"
    (change)="afterChanges()"
  />
  <button
    [disabled]="template.isDisabled()"
    mat-button
    [style.padding-left]="'2px'"
    [style.padding-right]="'2px'"
    [matMenuTriggerFor]="firstMenu"
    class="filter-condition"
    matPrefix
  >
    {{ 'FILTER.FilterCondition.' + template.getFirstOperator().toLowerCase() | translate }}
  </button>
</mat-form-field>

<mat-menu #firstMenu="matMenu">
  <ng-container *ngFor="let condition of template.getAvailableConditions()">
    <button mat-menu-item (click)="afterChanges(template.setFirstOperator(condition))">
      {{ 'FILTER.FilterCondition.' + condition.toLowerCase() | translate }}
    </button>
  </ng-container>
</mat-menu>

<div class="field">
  <button [disabled]="template.isDisabled()" [matMenuTriggerFor]="logicalMenu" mat-button>
    {{ 'FILTER.Operation.' + template.getLogicalOperator().toUpperCase() | translate }}
  </button>
</div>

<mat-menu #logicalMenu="matMenu">
  <ng-container *ngFor="let operator of template.getAvailableOperators()">
    <button
      [disabled]="template.isDisabled()"
      mat-menu-item
      (click)="afterChanges(template.setLogicalOperator(operator))"
    >
      {{ 'FILTER.Operation.' + operator | translate }}
    </button>
  </ng-container>
</mat-menu>

<mat-form-field class="field" [style.width]="'150px'" [floatLabel]="'never'">
  <mat-label>{{ 'FILTER.ValueField' | translate }}</mat-label>
  <input
    [disabled]="template.isDisabled()"
    [style.text-align]="'right'"
    matInput
    [ngModel]="template.getSecondValue()"
    (ngModelChange)="template.setSecondValue($event)"
    (change)="afterChanges()"
  />
  <button
    [disabled]="template.isDisabled()"
    mat-button
    [style.padding-left]="'2px'"
    [style.padding-right]="'2px'"
    [matMenuTriggerFor]="secondMenu"
    class="filter-condition"
    matPrefix
  >
    {{ 'FILTER.FilterCondition.' + template.getSecondOperator().toLowerCase() | translate }}
  </button>
</mat-form-field>

<mat-menu #secondMenu="matMenu">
  <ng-container *ngFor="let condition of template.getAvailableConditions()">
    <button mat-menu-item (click)="afterChanges(template.setSecondOperator(condition))">
      {{ 'FILTER.FilterCondition.' + condition.toLowerCase() | translate }}
    </button>
  </ng-container>
</mat-menu>
