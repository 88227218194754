import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { ElementsModule } from '@app-modeleditor/components/elements/elements.module';
import { EntryElement } from '@app-modeleditor/components/entry-collection/entry-element';
import { CONTAINER_DATA } from '@app-modeleditor/components/lightbox/overlay/container-data';
import { DefaultOverlayContainer } from '@app-modeleditor/components/lightbox/overlay/default-overlay-container';

/**
 * Displays the content for textarea overlay.
 */
@Component({
  selector: 'app-overlay-content',
  templateUrl: './overlay-content.component.html',
  styleUrls: ['./overlay-content.component.scss'],
  imports: [CommonModule, ElementsModule],
  standalone: true,
})
export class OverlayContentComponent extends DefaultOverlayContainer<EntryElement> {
  constructor(@Inject(CONTAINER_DATA) public entryElement: EntryElement) {
    super();
    this.data = entryElement;
  }

  protected defaultClick(e: MouseEvent): void {
    e.stopImmediatePropagation();
    e.stopPropagation();
  }

  /**
   * Destroys the overlay.
   */
  public close(): void {
    this.getRef().dispose();
  }
}
