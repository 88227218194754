import * as d3 from 'd3';
import {
  BehaviorSubject,
  Observable,
  Subject,
  distinctUntilChanged,
  filter,
  of,
  skip,
  switchMap,
  takeUntil,
} from 'rxjs';
import { GanttCallBackStackExecuter } from '../../callback-tools/callback-stack-executer';
import { GanttCanvasShift, GanttDataRow } from '../../data-handler/data-structure/data-structure';
import { EGanttTextStrategy } from '../../data-handler/data-structure/data-structure-enums';
import { DataManipulator } from '../../data-handler/data-tools/data-manipulator';
import { IGanttShiftResizeEvent } from '../../edit-shifts/shift-resizing/resize-events/resize-event.interface';
import { GanttFontSizeCalculator } from '../../font-tools/font-size';
import { EGanttScrollContainer } from '../../html-structure/scroll-container.enum';
import { BestGantt } from '../../main';
import { GanttRenderDataSetShifts } from '../../render-data-handler/data-structure/render-data-structure';
import { BestGanttPlugIn } from '../gantt-plug-in';
import { IndexCardConfig } from './index-card-config';
import { IndexCardTextBuilder } from './text-creator';
import { IndexCardTextDefault } from './text-creator/text-creator-default';
import { IndexCardTextInside } from './text-creator/text-creator-inside';
import { IIndexCardTextInterface } from './text-creator/text-creator-interface';
import { IndexCardTextNoText } from './text-creator/text-creator-no-text';
import { IndexCardTextOutsideBG } from './text-creator/text-creator-outside-bg';
import { IndexCardTextOutsideSplit } from './text-creator/text-creator-outside-split';
import { IndexCardEvent } from './undo-redo/index-card-event';

/**
 * Main class of index card builder.
 * It gives all gantt shifts a indexcard-like appearance.
 * @keywords plugin, executer, index, card, index-card, kartei, karte, builder, shift, information
 * @plugin index-card
 * @class
 * @constructor
 * @extends BestGanttPlugIn
 *
 * @requires BestGanttPlugIn
 * @requires IndexCardConfig
 * @requires IndexCardTextBuilder
 * @requires GanttFontSizeCalculator
 */
export class IndexCardExecuter extends BestGanttPlugIn {
  private _canvas: { [id: string]: d3.Selection<HTMLCanvasElement, undefined, d3.BaseType, undefined> } = {};
  private _builder: { [id: string]: IndexCardTextBuilder } = {};
  fontSizeCalc: GanttFontSizeCalculator;
  dataSet: any;
  permission: any;
  config: IndexCardConfig;
  private _originTextOverlayStrategy: EGanttTextStrategy;
  callbacks: any;
  private _shiftContentHeightsMap: Map<string, number> = new Map<string, number>();

  private _showIndexCards = new BehaviorSubject<boolean>(false);

  private _onRemoveCallbacksSubject: Subject<void> = new Subject<void>();

  constructor() {
    super(); // call super-constructor

    this.fontSizeCalc = null;

    this.dataSet = {};

    this.permission = {
      showDate: true,
    };

    this.config = new IndexCardConfig();

    this._originTextOverlayStrategy = null;

    this.callbacks = {
      afterDataSetHasChanged: {},
    };
  }

  public initPlugIn(ganttDiagram: BestGantt): void {
    this.ganttDiagram = ganttDiagram;

    this.fontSizeCalc = new GanttFontSizeCalculator();
    this._initTextBuilder();
    this._addCallbacks();
    this.ganttDiagram
      .getConfig()
      .addAfterShiftFontSizeChangedCallback(
        'updateFontSize' + this.UUID,
        this.updateFontSizeFromGanttConfig.bind(this)
      ); // add separately it must also be registered if index cards are in hide mode
    this.config.setFontSize(this.ganttDiagram.getConfig().getShiftFontSize());
    this.config.setLineHeight(this.ganttDiagram.getConfig().getShiftFontSize() + 4);

    this.getFontSizeCalculator().init(
      ganttDiagram.getShiftFacade().getShiftBuilder().getCanvasInFrontShifts().node(),
      this.config.fontSize
    );
  }

  public removePlugIn(): void {
    // callback unsubscribe
    this._removeCallbacks();
    this.ganttDiagram.getConfig().removeAfterShiftFontSizeChangedCallback('updateFontSize' + this.UUID);

    this._removeTextBuilder();
    this.showIndexCards(false);
    this.resetGanttShiftHeight();

    this._onRemoveCallbacksSubject.complete();
  }

  /**
   * Callback registration.
   */
  private _addCallbacks(): void {
    this.ganttDiagram
      .getXAxisBuilder()
      .onZooming.pipe(takeUntil(this.onRemoveCallbacks))
      .subscribe(() => this.rebuild());

    this.ganttDiagram
      .getVerticalScrollHandler()
      .onScrollVerticalUpdate.pipe(takeUntil(this.onRemoveCallbacks))
      .subscribe(() => this.rebuild());

    this.ganttDiagram
      .getAnimator()
      .addGroupTranslationEndCallBack('addIndexCardText' + this.UUID, this.rebuild.bind(this));
    this.ganttDiagram
      .getAnimator()
      .addGroupTranslationBeginCallBack('removeIndexCardText' + this.UUID, this.removeAll.bind(this));

    // * TODO: remove text removal on drag start after merging for v1.6 gantt 07/09/2020
    this.ganttDiagram
      .getShiftTranslator()
      .onShiftEditStart()
      .pipe(takeUntil(this.onRemoveCallbacks))
      .subscribe(() => this.removeAll());
    this.ganttDiagram
      .getShiftTranslator()
      .onShiftEditEnd()
      .pipe(takeUntil(this.onRemoveCallbacks))
      .subscribe(() => this.rebuild());

    this.ganttDiagram
      .getShiftResizer()
      .onShiftEditUpdate()
      .pipe(takeUntil(this.onRemoveCallbacks))
      .subscribe((event) => this._onResize(event));
    this.ganttDiagram
      .getShiftResizer()
      .onShiftEditEnd()
      .pipe(takeUntil(this.onRemoveCallbacks))
      .subscribe(() => this.rebuild());
    this.ganttDiagram
      .getOpenRowHandler()
      .afterShiftRowOpen.pipe(takeUntil(this.onRemoveCallbacks))
      .subscribe(() => this.rebuild());
    this.ganttDiagram
      .getOpenRowHandler()
      .afterShiftRowClosed.pipe(takeUntil(this.onRemoveCallbacks))
      .subscribe(() => this.rebuild());

    this.ganttDiagram
      .getTextOverlay()
      .addAfterTextStrategieChanged('matchIndexCardTextStrategy' + this.UUID, this._matchTextStratgie.bind(this));
    this.ganttDiagram
      .getVerticalScrollHandler()
      .onScrollVerticalUpdate.pipe(takeUntil(this.onRemoveCallbacks))
      .subscribe((event) => this._updateCanvasTop(event.source));
  }

  /**
   * Callback removing.
   */
  private _removeCallbacks(): void {
    this.ganttDiagram.getAnimator().removeGroupTranslationEndCallBack('addIndexCardText' + this.UUID);
    this.ganttDiagram.getAnimator().removeGroupTranslationBeginCallBack('removeIndexCardText' + this.UUID);
    this.ganttDiagram.getTextOverlay().removeAfterTextStrategieChanged('matchIndexCardTextStrategy' + this.UUID);

    this._onRemoveCallbacksSubject.next();
  }

  private _initTextBuilder(): void {
    for (const scrollContainerId of Object.values(EGanttScrollContainer)) {
      this._generateCanvas(scrollContainerId);
      this._builder[scrollContainerId] = new IndexCardTextBuilder(
        this._canvas[scrollContainerId],
        this.fontSizeCalc,
        this.ganttDiagram,
        this,
        scrollContainerId
      );
    }
  }

  private _removeTextBuilder(): void {
    // clean up text builder strategy
    for (const id in this._builder) {
      this._builder[id].cleanUp();
    }

    // remove canvas
    for (const id in this._canvas) {
      this._canvas[id].remove();
    }

    // clear text builder canvas
    this.removeAll();
  }

  public update(): void {
    this._updateCanvasProportions();
    this.rebuild();
  }

  public updatePlugInHeight(): void {
    this._updateCanvasProportions();
    this.rebuild();
  }

  /**
   * Removes text of all index cards.
   */
  removeAll() {
    for (const id in this._builder) {
      this._builder[id].removeAllElements();
    }
  }

  /**
   * Removes and builds all index cards text if index card flag is positive.
   */
  build() {
    if (this.isShowIndexCards()) {
      const scrollContainerIds = this.ganttDiagram.getRenderDataHandler().getActiveScrollContainerIds();
      for (const scrollContainerId of scrollContainerIds) {
        const dataWrapper = new IndexCardExecuterBuildData(
          this.ganttDiagram.getRenderDataHandler().getRenderDataShifts(scrollContainerId),
          this.ganttDiagram.getShiftFacade().getShiftBuilder(scrollContainerId).getLastZoomTransformation()
        );
        this._builder[scrollContainerId].build(this.dataSet, dataWrapper, this.config);
      }
    }
  }

  /**
   * Removes and builds all index card text.
   */
  rebuild() {
    this.removeAll();
    this.build();
  }

  private _updateCanvasProportions(): void {
    const scrollContainerIds = this.ganttDiagram.getRenderDataHandler().getActiveScrollContainerIds();

    for (const scrollContainerId of scrollContainerIds) {
      const shiftViewPortProportions = this.ganttDiagram
        .getNodeProportionsState()
        .getShiftViewPortProportions(scrollContainerId);
      const currentWidth = parseFloat(this._canvas[scrollContainerId].attr('width'));
      const currentHeight = parseFloat(this._canvas[scrollContainerId].attr('height'));

      // if proportions did not change -> do nothing
      if (shiftViewPortProportions.width === currentWidth && shiftViewPortProportions.height === currentHeight) {
        continue;
      }

      this._canvas[scrollContainerId]
        .attr('width', shiftViewPortProportions.width)
        .attr('height', shiftViewPortProportions.height);
    }
  }

  private _updateCanvasTop(scrollContainerId: EGanttScrollContainer): void {
    const scrollTop = this.ganttDiagram.getNodeProportionsState().getScrollTopPosition(scrollContainerId);
    this._canvas[scrollContainerId].style('top', scrollTop + 'px');
  }

  private _resetCanvasTop(): void {
    for (const id in this._canvas) {
      this._canvas[id].style('top', '0px');
    }
  }

  /**
   * Internal function to insert index card canvas into gantt.
   * Canvas will be inserted in front of shift text overlay canvas.
   * @param scrollContainerId
   */
  private _generateCanvas(scrollContainerId: EGanttScrollContainer): void {
    const parentNode = this.ganttDiagram.getTextOverlay(scrollContainerId).getCanvas().node();
    const shiftViewPortProportions = this.ganttDiagram
      .getNodeProportionsState()
      .getShiftViewPortProportions(scrollContainerId);

    this._canvas[scrollContainerId] = d3
      .select<HTMLDivElement, undefined>(parentNode)
      .append('canvas')
      .attr('class', 'gantt-index-card-text-canvas')
      .attr('width', shiftViewPortProportions.width)
      .attr('height', shiftViewPortProportions.height)
      .style('position', 'absolute')
      .style('top', '0px')
      .style('left', '0px');
  }

  /**
   * Adds shift start- and end-date as last line to each index card dataset.
   * @param {Map<string, string[]>} dataSet Dataset of all index cards.
   */
  private _showShiftDate(dataSet) {
    const dateFormat = d3.timeFormat(this.config.getDateFormat());
    const allShifts = this._getAllShiftDates(this.ganttDiagram.getDataHandler().getOriginDataset().ganttEntries);

    for (const key in dataSet) {
      if (allShifts[key] == null) continue;
      // push date to last line in list of lines
      dataSet[key].push(
        dateFormat(new Date(allShifts[key].timePointStart)) + ' - ' + dateFormat(new Date(allShifts[key].timePointEnd))
      );
    }
  }

  /**
   * Internal calculation to extract a map with start and end date of every shift.
   * @param {GanttDataRow[]} dataSet Gantt row dataset.
   */
  private _getAllShiftDates(dataSet) {
    const allShifts = {};

    // go recursively through dataset to find all open shifts
    const getAllShiftData = function (child, level, parent) {
      for (let k = 0; k < child.shifts.length; k++) {
        const shift = child.shifts[k];
        allShifts[shift.id] = shift;
      }
    };
    DataManipulator.iterateOverDataSet(dataSet, { getAllShiftData: getAllShiftData });
    return allShifts;
  }

  /**
   * Callback function which will be triggered if shift will be resized to update index card text.
   * @param eventData resize event data.
   */
  private _onResize(eventData: IGanttShiftResizeEvent): void {
    if (
      !this.isShowIndexCards() ||
      !this.permission.showDate ||
      !eventData.shiftSelection ||
      eventData.shiftSelection.empty()
    )
      return;
    this.build();
  }

  /**
   * Changes shift height to give shifts an index card like appearance.
   * Saves previous origin gantt height.
   * @param {number} height New height of index cards.
   */
  changeGanttRowHeight() {
    this.ganttDiagram.getDataHandler().getRowHeightStorage().setRowHeightsMap(this._calculateLineAmountPerRowMap());
    this._recalculateGanttShiftHeight();
  }

  /**
   * Resets shift height.
   */
  resetGanttShiftHeight() {
    this.ganttDiagram.getDataHandler().getRowHeightStorage().setRowHeightsMap(new Map());
    this._recalculateGanttShiftHeight();
  }

  /**
   * Updates the font size of indexcards to gantt font size settings
   */
  updateFontSizeFromGanttConfig() {
    this.getConfig().setFontSize(this.ganttDiagram.getConfig().getShiftFontSize());
    this.getConfig().setLineHeight(this.ganttDiagram.getConfig().getShiftFontSize() + 4);
    this.getFontSizeCalculator().init(
      this.ganttDiagram.getShiftFacade().getShiftBuilder().getCanvasInFrontShifts().node(),
      this.config.fontSize
    );
    this.rebuild();
  }

  /**
   * Updates gantt after changing row height.
   * Todo: put all height dependencies of gantt as config callback if there is a height change.
   * @param {number} height New shift height.
   */
  private _recalculateGanttShiftHeight() {
    this.ganttDiagram.getDataHandler().initCanvasYAxisData();
    const newHeight = this.ganttDiagram.getRenderDataHandler().getYAxisDataFinder().getRowHeightsCombined();
    this.ganttDiagram.setCanvasHeight(newHeight);
  }

  /**
   * Calculates the height of a shift with a shift content containing the given number of lines of text.
   * @param countOfRows Lines of text the shift content should contain.
   * @returns Height of a shift with a shift content containing the given number of lines of text.
   */
  public getIndexCardShiftHeight(countOfRows: number): number {
    const indexCardHeaderHeight = this.ganttDiagram.getConfig().getTextOverlayHeight();
    return (
      indexCardHeaderHeight +
      this._getIndexCardContentHeight(countOfRows) +
      this.ganttDiagram.getConfig().getLineBottom() +
      this.ganttDiagram.getConfig().getLineTop()
    );
  }

  /**
   * Calculates the height of a shift content containing the given number of lines of text.
   * @param countOfRows Lines of text the shift content should contain.
   * @returns Height of a shift content containing the given number of lines of text.
   */
  private _getIndexCardContentHeight(countOfRows: number): number {
    return this.getConfig().padding_top + this.getConfig().lineHeight * countOfRows + this.getConfig().padding_bottom;
  }

  //
  //  RESTRICTIONS
  //

  /**
   * Activate index card shift appearance.
   * Callbacks will be added/removed.
   * @param showIndexCards Show/Hide index cards.
   */
  public showIndexCards(showIndexCards: boolean): void {
    if (showIndexCards) {
      this.ganttDiagram.getDataHandler().getRowHeightStorage().setRowHeightsMap(this._calculateLineAmountPerRowMap());
      this._addCallbacks();

      const scrollContainerIds = this.ganttDiagram.getRenderDataHandler().getActiveScrollContainerIds();
      for (const scrollContainerId of scrollContainerIds) this._updateCanvasTop(scrollContainerId);

      this.ganttDiagram.getConfig().setShiftBuildingShowShiftContent(true);
      this.ganttDiagram.getDataHandler().subscribeBeforeYAxisDataMapping(`calculateRowHeights_${this.UUID}`, () => {
        this.ganttDiagram.getDataHandler().getRowHeightStorage().setRowHeightsMap(new Map());
        this.ganttDiagram.getDataHandler().getRowHeightStorage().setRowHeightsMap(this._calculateLineAmountPerRowMap());
      });
      this.ganttDiagram
        .getRenderDataHandler()
        .onFilterRenderDataSetShifts.pipe(takeUntil(this.onHideIndexCards))
        .subscribe((renderDataSet) => this._applyShiftContentHeights(renderDataSet));
      this.ganttDiagram
        .getNodeProportionsState()
        .select('shiftViewPortProportionsSticky')
        .pipe(takeUntil(this.onHideIndexCards))
        .subscribe(() => this._updateCanvasProportions());
    } else {
      this.ganttDiagram.getDataHandler().getRowHeightStorage().setRowHeightsMap(new Map());
      this._removeCallbacks();
      this._resetCanvasTop();
      this.ganttDiagram.getConfig().setShiftBuildingShowShiftContent(false);
      this.ganttDiagram.getDataHandler().unSubscribeBeforeYAxisDataMapping(`calculateRowHeights_${this.UUID}`);
    }

    this._showIndexCards.next(showIndexCards);
  }

  /**
   * SHOWS indexCards
   * Wrapper Function for changing the view mode
   * @param {number} [rowHeight = 128] The new Row Height.
   * @param {Map.<String, String[]>} indexCardDataSet The indexCardDataSet.
   */
  showIndexCardsMode() {
    const textStrategy = this.ganttDiagram.getTextOverlay().getActiveStrategyType();
    if (textStrategy === EGanttTextStrategy.SHOW_NEVER_LABELS) {
      this._originTextOverlayStrategy = textStrategy;
      this.ganttDiagram.getShiftFacade().changeTextOverlayStrategy(EGanttTextStrategy.CUT_OFF_LABELS);
    }

    this.ganttDiagram.getHistory().addNewEvent('showIndexCards', new IndexCardEvent(), this, 0);
    this.changeGanttRowHeight();
    this.showIndexCards(true);
    this.build();
  }

  /**
   * HIDES indexCards
   * Wrapper Function for changing the view mode
   */
  hideIndexCardsMode() {
    if (this._originTextOverlayStrategy) {
      this.ganttDiagram.getShiftFacade().changeTextOverlayStrategy(this._originTextOverlayStrategy);
      this._originTextOverlayStrategy = null;
    }

    this.ganttDiagram.getHistory().addNewEvent('hideIndexCards', new IndexCardEvent(), this, 0);
    this.removeAll();
    this.showIndexCards(false);
    this.resetGanttShiftHeight();
    this.ganttDiagram.getVerticalScrollHandler().setVerticalScrollPos(0);
    this.ganttDiagram.update();
  }

  /**
   * Applies the cached shift content heights to the current render data set if possible.
   * @param renderDataSet Render data set to apply the shift content heights to.
   */
  private _applyShiftContentHeights(renderDataSet: GanttRenderDataSetShifts): void {
    for (const key in renderDataSet) {
      const renderData: GanttCanvasShift[] = renderDataSet[key];
      if (!renderData || renderData.length <= 0) continue;

      for (const shift of renderData) {
        if (!shift.label) shift.label = {};
        shift.label.contentHeight = this._shiftContentHeightsMap.get(shift.id);
      }
    }
  }

  /**
   * Calculates a row heights map where all shifts fit into their respective rows (including their content).
   * @param cacheShiftContentHeights If true, the contet heights of all shifts will be cached for further calculations (default is true).
   * @returns Calculated row heights map.
   */
  private _calculateLineAmountPerRowMap(cacheShiftContentHeights = true): Map<string, number> {
    const originDataSet = this.ganttDiagram.getDataHandler().getOriginDataset().ganttEntries;
    const lineAmountPerRowMap = new Map<string, number>();

    const getMaxLineAmountByEntry = (child: GanttDataRow) => {
      let amountOfLines = 0;
      for (const shift of child.shifts) {
        if (shift.noRender && shift.noRender.length > 0) continue;
        const linesInShift = this.dataSet[shift.id];
        const lineAmountOfShift = linesInShift ? linesInShift.length : 0;
        amountOfLines = Math.max(amountOfLines, lineAmountOfShift);
        if (cacheShiftContentHeights) {
          this._shiftContentHeightsMap.set(shift.id, this._getIndexCardContentHeight(lineAmountOfShift));
        }
      }
      if (amountOfLines) {
        lineAmountPerRowMap.set(child.id, this.getIndexCardShiftHeight(amountOfLines));
      }
    };

    if (cacheShiftContentHeights) this._shiftContentHeightsMap.clear();
    DataManipulator.iterateOverDataSet(originDataSet, { getMaxRowAmountByEntry: getMaxLineAmountByEntry });
    return lineAmountPerRowMap;
  }

  //
  //  GETTER & SETTER
  //

  public isShowIndexCards(): boolean {
    return this._showIndexCards.getValue();
  }

  public getCanvas(
    scrollContainerId: EGanttScrollContainer = EGanttScrollContainer.DEFAULT
  ): d3.Selection<HTMLCanvasElement, undefined, d3.BaseType, undefined> {
    return this._canvas[scrollContainerId];
  }

  /**
   * @param {Map<string, String[]>} dataSet has this form:
   *  {
   *      shiftId1:
   *          [
   *              "example content text row 1",
   *              ...
   *          ],
   *      ...,
   *      shiftIdX
   *  }
   */
  setDataSet(dataSet) {
    this.dataSet = dataSet;
    if (this.permission.showDate) this._showShiftDate(this.dataSet);
    GanttCallBackStackExecuter.execute(this.callbacks.afterDataSetHasChanged);
  }

  /**
   * @return {Map<string, string[]>} Dataset with all index card text information.
   */
  getDataSet() {
    return this.dataSet;
  }

  /**
   * @return {IndexCardConfig} Config object with properties.
   */
  getConfig() {
    return this.config;
  }

  /**
   * @return {GanttFontSizeCalculator}
   */
  getFontSizeCalculator() {
    return this.fontSizeCalc;
  }

  /**
   * Sets date format for index card date text line:
   * <a href="https://github.com/d3/d3-time-format">D3 Date formats</a>
   * @param {string} dateFormat D3 date formatting.
   */
  setDateFormat(dateFormat) {
    this.config.setDateFormat(dateFormat);
  }

  /**
   * @param strategyType New strategie to use for building text.
   */
  public changeBuildingStrategie(strategyType: new () => IIndexCardTextInterface): void {
    for (const id in this._builder) {
      this._builder[id].changeIndexCardTextStrategie(new strategyType());
    }
  }

  /**
   * Callback to update indexCard Text Strategie on labelTextStrat Change to match it.
   * @param labelStrategy ShiftLabel Strategie.
   */
  private _matchTextStratgie(labelStrategy: EGanttTextStrategy): void {
    switch (labelStrategy) {
      case EGanttTextStrategy.HIDE_OVERLAPPING_LABELS:
      case EGanttTextStrategy.CUT_OFF_LABELS_MULTIPLE_LINES:
      case EGanttTextStrategy.CUT_OFF_LABELS:
        this.changeBuildingStrategie(IndexCardTextInside);
        break;
      case EGanttTextStrategy.SHOW_ALWAYS_LABELS:
      case EGanttTextStrategy.SHOW_ALWAYS_LABELS_BG:
        this.changeBuildingStrategie(IndexCardTextOutsideBG);
        break;
      case EGanttTextStrategy.SHOW_ALWAYS_LABELS_SPLIT:
        this.changeBuildingStrategie(IndexCardTextOutsideSplit);
        break;
      case EGanttTextStrategy.SHOW_NEVER_LABELS:
        this.changeBuildingStrategie(IndexCardTextNoText);
        break;
      default:
        this.changeBuildingStrategie(IndexCardTextDefault);
    }

    this.rebuild();
  }

  isShowDate() {
    return this.permission.showDate;
  }

  setShowDate(bool) {
    this.permission.showDate = bool;
  }

  //
  // OBSERVABLES
  //

  /**
   * Observable which gets triggered when all callbacks should be removed.
   */
  private get onRemoveCallbacks(): Observable<void> {
    return this._onRemoveCallbacksSubject.asObservable();
  }

  /**
   * Observable which gets triggered when the index cards get hidden.
   */
  private get onHideIndexCards(): Observable<void> {
    return this._showIndexCards.asObservable().pipe(
      skip(1),
      filter((showIndexCards) => !showIndexCards),
      distinctUntilChanged(),
      switchMap(() => of(void 0))
    );
  }

  //
  // CALLBACKS
  //

  subscribeAfterDataSetHasChanged(id, func) {
    this.callbacks.afterDataSetHasChanged[id] = func;
  }

  unSubscribeAfterDataSetHasChanged(id) {
    delete this.callbacks.afterDataSetHasChanged[id];
  }
}

/**
 * Data class to store index card information.
 * @keywords plugin, data, class, index-card, rects
 * @plugin index-card
 */
export class IndexCardExecuterBuildData {
  /**
   * @param shifts
   * @param transform D3 zoom transform data with x, y, k value.
   */
  constructor(public shifts: GanttCanvasShift[], public transform: { x: number; y: number; k: number }) {}
}
