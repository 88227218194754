import { EntryElementValue } from '@app-modeleditor/components/entry-collection/entry-element-value';
import { EFieldType } from '@app-modeleditor/components/entry-collection/field-type.enum';
import { Datepicker } from '@app-modeleditor/components/template-datepicker/datepicker';
import { EGanttInstance } from '@gantt/public-api';
import { takeUntil, takeWhile } from 'rxjs/operators';
import { Gantt_General } from '../../general.gantt.component';

export enum EShiftTimeType {
  START = 'start',
  END = 'end',
}

export class ShiftTimeElement extends Datepicker {
  private hasSelectedShift: boolean;
  constructor(private scope: Gantt_General, private shiftTimeType: EShiftTimeType) {
    super();
  }

  get(data: Record<string, any>): this {
    this.setId(this.getShiftTimeId())
      .setName(data.name)
      .setEnableBy(() => this.hasSelectedShift)
      .setFieldType(EFieldType.DATE_TIME_PICKER)
      .setUseLocalTime(true);

    this.registerIncomingChanges();
    this.getShiftTimeValue();
    return this;
  }

  private getShiftTimeValue() {
    this.getSelectedShift();
  }

  getSelectedShift(): void {
    const selectedShift: any[] = this.scope.ganttLibService.bestGantt.getSelectionBoxFacade().getSelectedShifts();
    this.scope.zone.run(() => (this.hasSelectedShift = selectedShift.length > 0 ? true : false));

    if (selectedShift.length == 1) {
      const foundShiftData = this.scope.ganttLibService.ganttInstanceService
        .getInstance(EGanttInstance.SHIFT_DATA_FINDER)
        .getShiftById(
          this.scope.ganttLibService.bestGantt.getDataHandler().getOriginDataset().ganttEntries,
          selectedShift[0].id
        );
      const shift = foundShiftData?.shift;
      this.changeValue(shift);
    } else {
      this.setValue(new EntryElementValue().setValue(null));
    }
  }

  private registerIncomingChanges() {
    const ganttDiagram = this.scope.ganttLibService.bestGantt;

    ganttDiagram
      .getSelectionBoxFacade()
      .afterSelection.pipe(takeUntil(ganttDiagram.onDestroy))
      .subscribe(() => this.getSelectedShift());

    ganttDiagram
      .getShiftFacade()
      .shiftOnClick()
      .pipe(takeWhile(() => this.scope.ganttLibService.isAlive))
      .subscribe((data) => {
        const targetId: string = data.event.target.data()[0].id;
        this.scope.zone.run(() => (this.hasSelectedShift = true));

        try {
          const shift = this.scope.ganttLibService.ganttInstanceService
            .getInstance(EGanttInstance.SHIFT_DATA_FINDER)
            .getShiftById(ganttDiagram.getDataHandler().getOriginDataset().ganttEntries, targetId).shift;
          this.changeValue(shift);
        } catch (e) {}
      });
  }

  private changeValue(shift: any) {
    this.scope.zone.run(() => {
      switch (this.shiftTimeType) {
        case EShiftTimeType.START:
          this.setValue(new EntryElementValue().setValue(new Date(shift.timePointStart).getTime()));
          break;
        case EShiftTimeType.END:
          this.setValue(new EntryElementValue().setValue(new Date(shift.timePointEnd).getTime()));
          break;
      }
    });
  }

  private getShiftTimeId() {
    switch (this.shiftTimeType) {
      case EShiftTimeType.START:
        return 'GanttBlockStartSubmenuElement';
      case EShiftTimeType.END:
        return 'GanttBlockEndSubmenuElement';
    }
  }
}
