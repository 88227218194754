<div class="wrapper">
  <mat-accordion multi>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'GANTT.dateAndTime' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <app-date-and-time [settings]="settings" (changeEvent)="onChanges()"></app-date-and-time>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'GANTT.ROW.dividingLines' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <app-dividing-lines [settings]="settings" (changeEvent)="onChanges()"></app-dividing-lines>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'GANTT.resources' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <app-rows [settings]="settings" (changeEvent)="onChanges()"></app-rows>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'GANTT.blocks' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <app-blocks
        [settings]="settings"
        [blockAttributes]="additionalData.blockAttributes"
        (changeEvent)="onChanges()"
      ></app-blocks>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'GANTT.tooltips' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <app-tooltips [settings]="settings" (changeEvent)="onChanges()"></app-tooltips>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'GANTT.indexcards' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <app-index-cards [settings]="settings" (changeEvent)="onChanges()"></app-index-cards>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'GANTT.labels' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <app-labels [settings]="settings" (changeEvent)="onChanges()"></app-labels>
    </mat-expansion-panel>
  </mat-accordion>
</div>
