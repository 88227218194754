import { GanttPatternData } from '../../patternHandler';
import { Pattern } from '../pattern.base';

/**
 * Class which handles the generation of a horizontal stripes pattern.
 */
export class HorizontalStripesPattern extends Pattern {
  /**
   * Generates an SVG image containing the pattern and returns it as base64-encoded URL.
   * @param backgroundColor Background color of the pattern.
   * @param patternColor Color of the pattern.
   * @param strokeColor Stroke color of the pattern.
   * @param lineHeight Width of the horizontal stripes in px.
   * @returns SVG image containing the pattern as base64-encoded URL.
   */
  protected _getPatternDataString(
    backgroundColor: string,
    patternColor: string,
    strokeColor: string,
    lineHeight: number
  ): string {
    const pattern = this._patternBuilder.getSvgElement(this._patternHeight, this._patternWidth);
    this._patternBuilder.appendBackground(pattern, backgroundColor);
    this._patternBuilder.appendRect(
      pattern,
      lineHeight,
      10,
      -this._patternBuilder.strokeWidth,
      0,
      patternColor,
      strokeColor
    );
    return this._patternBuilder.convertSvgNodeIntoDataString(pattern);
  }

  /**
   * Generates an SVG image containing the pattern and returns it as {@link GanttPatternData} object.
   * @param backgroundColor Background color of the pattern.
   * @param patternColor Color of the pattern.
   * @param strokeColor Stroke color of the pattern.
   * @param lineHeight Width of the horizontal stripes in px.
   * @returns SVG image containing the pattern as {@link GanttPatternData} object.
   */
  public getPatternData(
    backgroundColor: string,
    patternColor: string,
    strokeColor: string,
    lineHeight: number
  ): GanttPatternData {
    return new GanttPatternData(
      this._getPatternDataString(backgroundColor, patternColor, strokeColor, lineHeight),
      this._patternHeight,
      this._patternWidth
    );
  }
}
