import { HMI } from '../tree/tree-item';

export class ColumnListSelectorElement extends HMI {
  private simpleElement: boolean;
  private floatLeftFieldIdentifiers: { [key: string]: string[] }[];
  private floatLeft: string;

  public getFloatLeft(): string {
    return this.floatLeft;
  }

  public setFloatLeft(floatLeft: string): this {
    this.floatLeft = floatLeft;
    return this;
  }

  public setFloatFieldIdentifiers(identifiers: { [key: string]: string[] }[]): this {
    this.floatLeftFieldIdentifiers = identifiers;
    return this;
  }

  public getFloatFieldIdentifiers(): { [key: string]: string[] }[] {
    return this.floatLeftFieldIdentifiers;
  }

  public isSimpleElement(): boolean {
    return typeof this.simpleElement === 'boolean' ? this.simpleElement : false;
  }

  public setSimpleElement(simpleElement: boolean): this {
    this.simpleElement = simpleElement;
    return this;
  }
}
