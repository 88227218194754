import { Overlay, OverlayConfig, OverlayRef, FlexibleConnectedPositionStrategyOrigin } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ElementRef, Injectable } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';

export interface IPopupPosition {
  x: number;
  y: number;
}

@Injectable({
  providedIn: 'root',
})
export class GeneralPopUpService {
  overlayRef: OverlayRef;
  sub: Subscription;

  constructor(private overlay: Overlay) {}

  public createPopupOnElement(htmlTarget: any, componentPortal: ComponentPortal<any>) {
    this.close();
    this.overlayRef = this.overlay.create(this.getPopupConfig(htmlTarget, 0, 0));
    this.openOverlay(componentPortal);
  }

  public createPopupOnMouse(positionX: number, positionY: number, componentPortal: ComponentPortal<any>) {
    this.close();
    this.overlayRef = this.overlay.create(this.getPopupConfig(null, positionX, positionY));
    this.openOverlay(componentPortal);
  }

  private openOverlay(componentPortal) {
    this.overlayRef.attach(componentPortal);
    this.attachCloseEvent();
  }

  private getPopupConfig(htmlTarget: ElementRef<any> | HTMLElement, x: number, y: number): OverlayConfig {
    const config: OverlayConfig = { hasBackdrop: false };

    const target: FlexibleConnectedPositionStrategyOrigin = htmlTarget || { x, y };
    if (target) {
      config.positionStrategy = this.overlay
        .position()
        .flexibleConnectedTo(target)
        .withPositions([
          {
            originX: 'end',
            originY: 'top',
            overlayX: 'start',
            overlayY: 'top',
          },
          {
            originX: 'start',
            originY: 'top',
            overlayX: 'end',
            overlayY: 'bottom',
          },
        ]);
    }
    return config;
  }

  private attachCloseEvent() {
    this.sub = fromEvent<MouseEvent>(document, 'click')
      .pipe(
        filter((event) => {
          const clickTarget = event.target as HTMLElement;
          return !!this.overlayRef && !this.overlayRef.overlayElement.contains(clickTarget);
        }),
        take(1)
      )
      .subscribe(() => this.close());
  }

  public close() {
    if (this.sub) {
      this.sub.unsubscribe();
    }

    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }
}
