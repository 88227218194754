import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { ButtonService } from '@app-modeleditor/components/button/button.service';
import { HierarchicalMenuItem } from '@app-modeleditor/components/template-ui/hierarchical-menu-item';
import { TemplateUiService } from '@app-modeleditor/components/template-ui/template.service';
import { TemplateOverlayService } from '@app-modeleditor/components/tree/tree.template-overlay.service';
import { ERequestMethod, RequestService } from '@app-modeleditor/request.service';
import { UiService } from '@app-modeleditor/ui.service';
import { TemplateService } from '@app-modeleditor/utils/template.service';
import { ConfigService } from '@core/config/config.service';
import { CloudMessagingService } from '@core/notification/cloud-messaging.service';
import { Notification } from '@core/notification/notification';
import { ENotificationType } from '@core/notification/notification-type.enum';
import { SplitComponent } from 'angular-split';
import { GlobalUtils } from 'frontend/src/dashboard/global-utils';
import { Template } from 'frontend/src/dashboard/model/resource/template';
import { ETemplateType } from 'frontend/src/dashboard/model/resource/template-type';
import { IUserSettings } from 'frontend/src/dashboard/user/data-access/user.service';
import { EUserSettingsKey } from 'frontend/src/dashboard/user/user-seetings-key.enum';
import { Observable, Observer, Subject, Subscription, concat, of, throwError } from 'rxjs';
import { catchError, delay, finalize, map, switchMap, take, takeUntil, takeWhile, tap } from 'rxjs/operators';
import { FreetextFilter } from '../../../core/filter/freetext-filter.enum';
import { FreetextFilterService } from '../../../core/filter/freetext-filter.service';
import { SharedUiService } from '../../../model-editor/ui/service/shared.ui.service';
import { UiAction } from '../../../model-editor/ui/service/ui-action.enum';
import { GridAction } from '../../../moving-grid/grid-action.enum';
import { GridGloablService } from '../../../moving-grid/grid-global.service';
import { GridService } from '../../../moving-grid/grid.service';
import { NodeNetworkType } from '../../../node-network/data/node-network-type.enum';
import { ConfirmDialog } from '../../../shared/dialogs/confirm.dialog';
import { UserService } from '../../../user/data-access/user.service';
import { SharedToolbarService } from '../../../view/navbar/toolbar/shared-toolbar.service';
import { ToolbarAction } from '../../../view/navbar/toolbar/toolbar-action.enum';
import { ActionAdapter } from '../button/action-adapter.service';
import { Action } from '../button/action/action';
import { Content } from '../content/content';
import { Filter } from '../menu-item/filter';
import { EMenuItemHandlingType } from '../menu-item/menu-item-handling-type.enum';
import { Routing } from './../../../core/navigation/routing';
import { ResizeEvent } from './../../../view/resize/resize-event';
import { EResizeType } from './../../../view/resize/resize-type.enum';
import { ResizeService } from './../../../view/resize/resize.service';
import { ViewService } from './../../../view/view.service';
import { TemplateAdapter } from './../../utils/template-factory.service';
import { LightboxService } from './../lightbox/lightbox.service';
import { ConfirmLightbox } from './../lightbox/predefined/confirm-lightbox';
import { EColumnMode } from './column-mode.enum';
import {
  IRecentlyVisitedNode,
  LastVisitedContentComponent,
} from './last-visited-content/last-visited-content.component';
import { LastVisitedContentService } from './last-visited-content/last-visited-content.service';
import { Menu } from './menu';
import { PredefinedItemUtils } from './predefined-item-utils';
import { FileDatabase } from './template-tree/tree-database.service';
import { FileNode } from './template-tree/tree-node';
import { ETreeSettingsKeys } from './template-tree/tree-settings-keys.enum';
import { ITreeSettings } from './template-tree/tree-settings.interface';
import { TreeSettingsService } from './template-tree/tree-settings.service';
import { TreeComponent as TemplateTreeComponent } from './template-tree/tree.component';
import { TestLightbox } from './test/test.lightbox';
import { HMI } from './tree-item';
import { TreeSecondColumnComponent } from './tree-second-column/tree-second-column.component';
import { IHierarchicItem, IHierarchicMenuItem } from './tree.model';
import { TemplateTreeService } from './tree.service';
import { IUpdateTreeOptions } from './update-tree-options';

interface ISplitEvent {
  gutterNum: number;
  sizes: Array<number>;
}
@Component({
  selector: 'app-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.scss'],
  providers: [FileDatabase, TreeSettingsService],
})
export class TreeComponent implements OnInit, OnChanges, OnDestroy {
  hasPendingActions$: Observable<boolean> = this.actionExecuter
    .getPendingButtons()
    .pipe(
      map((item) =>
        this.configApi.access().experimental?.switchingContextWhileExecutingActions === false ? item?.length > 0 : false
      )
    );

  @ViewChild('secondCol') secondColComponent: TreeSecondColumnComponent;
  @ViewChild(MatMenuTrigger) contextmenu: MatMenuTrigger;
  @Input() model: Menu;
  @Input() firstColWidth: number;
  @Input() mode: EColumnMode = EColumnMode.SINGLE_COLUMN;
  private _entryLimit = -1; // number of visible tree entry elements
  public currentActiveId = '';
  public widgets: Array<any> = [];
  toolbarExtended = true;
  showEditmodeBar: boolean;
  public databackup = null;
  public dataset: Array<any> = [];
  public evalution: any;
  public generell: any;
  public planningrun: any;
  public nodeNetwork: any;
  public controlCenter: any;
  public comparisonCharts: any;
  public material: any;
  public activeElementId: any;
  isLoadingContent: boolean;
  private refreshDialog: MatDialogRef<any>;
  modeleditorData: any;
  public solverData;
  public templateOverlayData;
  private openedTreeNodes: any[] = [];
  currentDisplayedItems: any[];
  changer: any;
  nodes: HierarchicalMenuItem[] = [];
  alive = true;
  depthLevel = 0;
  nodeNetworkData;
  widgetData;
  bdeData;
  clickTime: Subject<void> = new Subject<void>();
  selectedNode: HMI;
  @Output() changeElement: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('contentContainer') contentContainer: ElementRef;
  @ViewChild('treeWrapper') treeWrapper: ElementRef;
  @ViewChild('mainSplitter') mainSplitter: SplitComponent;
  @ViewChild('treeSplitter') treeSplitter: SplitComponent;
  @ViewChild('templateTree') templateTree: TemplateTreeComponent;
  @ViewChild(LastVisitedContentComponent)
  lastVisitedContentComponent: LastVisitedContentComponent;
  path: any = {};
  _selectedHMI: HierarchicalMenuItem;
  _filter: string;
  closeContent: () => void;
  private resizeIds: string[] = [null, null];
  @ViewChild('editbarRef') set editBar(editbar: ElementRef) {
    this.$resizeApi.complete(this.resizeIds[0]);
    if (!editbar) {
      return;
    }
    this.resizeIds[0] = this.$resizeApi.create(
      editbar.nativeElement,
      (e: ResizeEvent) => {
        of(null)
          .pipe(delay(0))
          .subscribe(() => {
            this.contentContainerHeight = `calc(100% - ${e.getNewHeight() + 5}px)`;
          });
      },
      { types: [EResizeType.HEIGHT] }
    );
  }

  @ViewChild('modeSelectionRef') set modeSelection(modeSelection: ElementRef) {
    this.$resizeApi.complete(this.resizeIds[1]);
    if (!modeSelection) {
      return;
    }
    this.resizeIds[1] = this.$resizeApi.create(
      modeSelection.nativeElement,
      (e: ResizeEvent) => {
        of(null)
          .pipe(delay(0))
          .subscribe(() => {
            this.modeSelectionWidth = e.getNewWidth();
          });
      },
      { types: [EResizeType.WIDTH] }
    );
  }

  contentContainerHeight: string;
  public modeSelectionWidth: number;

  /** for testing */
  cyPrefix = 'cy-';

  public treeSizes: Array<number> = [20, 20];
  private gutterClickTime: number = new Date().getTime();
  private oldTreeSizes: Array<number> = [20, 20];
  private settings: IUserSettings;
  private currentNavigation: Subscription;
  private lastModelId;
  private id: string;
  checkContextChange: boolean;

  private startTime: number;
  private endTime: number;
  private ngNext: Subject<void> = new Subject<void>();
  private ngInit: Subject<void> = new Subject<void>();
  private content;
  mapOfVisibleNodes: any;
  isFirst: boolean; // whether its the initial tree settings
  private applicationMenuItems: HierarchicalMenuItem;

  test() {
    const t = new TestLightbox();
    this.lightboxApi.open(t);
  }

  constructor(
    public globalViewService: ViewService,
    public globalGridService: GridGloablService,
    public globalToolbarService: SharedToolbarService,
    public sharedUiService: SharedUiService,
    public configApi: ConfigService,
    public gridService: GridService,
    private uiService: UiService,
    private dialog: MatDialog,
    private $resizeApi: ResizeService,
    private freetextFilterService: FreetextFilterService,
    private templateUiService: TemplateUiService,
    private templateOverlayService: TemplateOverlayService,
    private templateTreeService: TemplateTreeService,
    private cd: ChangeDetectorRef,
    private fcm: CloudMessagingService,
    private userSettingsService: UserService,
    private actionExecuter: ButtonService,
    private templateApi: TemplateService,
    private templateFactory: TemplateAdapter,
    private lightboxApi: LightboxService,
    private treeData: FileDatabase,
    private _requestApi: RequestService,
    private _treeSettingsApi: TreeSettingsService,
    private $ngZone: NgZone,
    private $lastVisitedNodeApi: LastVisitedContentService,
    private appMenu: ViewService,
    private actionAdapter: ActionAdapter // private messageApi: MessageService,
  ) {
    this.appMenu
      .getApplicationMenu()
      .pipe(takeWhile(() => this.alive))
      .subscribe((ab) => {
        if (ab) {
          ab.toolbar.menuItems.forEach((element) => {
            if (element.id.includes('template.toolbaritem.systemmanagement')) {
              this.applicationMenuItems = new HierarchicalMenuItem()
                .setId(element.id)
                .setName(element.name)
                .setIcon(element.icon);
              element.groups.forEach((group) => {
                this.applicationMenuItems.setHierachicMenuItems(
                  group.elements.map((item) => {
                    const actions: Action[] = item.globalActions.map((a) =>
                      this.actionAdapter.parseAction<Action>(Action, a)
                    );
                    return new HierarchicalMenuItem()
                      .setId(item.id)
                      .setName(item.name)
                      .setIcon(item.icon)
                      .setExecuters(actions);
                  })
                );
              });
            }
          });
        }
        PredefinedItemUtils.inject(this.model, this.applicationMenuItems);
      });

    this.$lastVisitedNodeApi
      .onRecentlyVisitedNodeChanged()
      .pipe(takeWhile(() => this.alive))
      .subscribe((node: IRecentlyVisitedNode) => {
        this.openRecentlyVisitedNode(node);
      });
    this.$lastVisitedNodeApi
      .getLastVisitedContentNodes()
      .pipe(takeWhile(() => this.alive))
      .subscribe((nodes: IRecentlyVisitedNode[]) => {
        this._treeSettingsApi.updateTreeSettingsKey(ETreeSettingsKeys.PAGEHISTORY, nodes);
      });

    this.closeContent = this.close.bind(this);
    this.templateTreeService.onDeleteElement.pipe(takeWhile(() => this.alive)).subscribe((node: any) => {
      this.afterDelete();
    });

    this.globalViewService
      .getPageContent()
      .pipe(takeWhile(() => this.alive))
      .subscribe((content) => {
        this.content = content;
      });

    this.id = GlobalUtils.generateUUID();

    this.templateTreeService.onForceClick.pipe(takeWhile(() => this.alive)).subscribe((data: any) => {
      this.selectAndClickNode(null, data.element, true, null, true).subscribe();
    });

    this.templateTreeService
      .onUpdateTree()
      .pipe(takeWhile(() => this.alive))
      .subscribe((options: IUpdateTreeOptions) => {
        this.updateTree(options.skipClick).subscribe();
      });

    this.userSettingsService
      .getUserSettings()
      .pipe(takeWhile(() => this.alive))
      .subscribe((settings: IUserSettings) => {
        this.settings = settings;
        if (!settings) {
          return;
        }

        this.userSettingsService.getValueByType('CURRENT_TIMESPAN', true).subscribe((span) => {
          if ((this.settings as any)?.disableReload === true || !span || !span.settingsValue.value) {
            return;
          }

          if (span.settingsValue.value.start === this.startTime && span.settingsValue.value.end === this.endTime) {
            return;
          }
          this.startTime = span.settingsValue.value.start;
          this.endTime = span.settingsValue.value.end;
          this.updateTree().subscribe();
        });

        this.userSettingsService.getValueByType('CURRENT_MODEL', true).subscribe((entry) => {
          if (!entry || entry.settingsValue.value === this.lastModelId) {
            return;
          }

          if (!this.lastModelId) {
            this.lastModelId = entry.settingsValue.value;
            return;
          }
          this.lastModelId = entry.settingsValue.value;
          this.startTime = undefined;
          this.endTime = undefined;
          // this.updateTree().subscribe();
        });

        this.applyFilterSettings();
      });

    // this.globalToolbarService.getAction().pipe(takeWhile(() => this.alive)).subscribe(action => {
    //   if (!action || !this.modeleditorData || !this.modeleditorData.templateMode) {
    //     return;
    //   }
    //   switch (action.type) {
    //     case 'TOOLBAR.lock':
    //       this.templateApi.requireEditMode(this.modeleditorData.id, this.modeleditorData.resourceId, this.modeleditorData.canonicalName)
    //         .subscribe();
    //       break;
    //     case 'TOOLBAR.lock_open':
    //       this.templateApi.releaseEditMode(this.modeleditorData.id, this.modeleditorData.resourceId, this.modeleditorData.canonicalName)
    //         .subscribe();
    //       break;
    //   }
    // });

    this.templateTreeService.onRefresh.pipe(takeWhile(() => this.alive)).subscribe((notification: Notification) => {
      this._handleNotification(notification);
    });

    this.templateUiService.onaction.pipe(takeWhile(() => this.alive)).subscribe((action) => {
      switch (action.type) {
        case 'REFRESH_TREE':
        case 'RELOAD_TREE':
          this.refreshOpenedNodes();
          break;
      }
    });

    // handle path events
    this.fcm
      .getMessage()
      .pipe(takeWhile(() => this.alive))
      .subscribe((result: Notification) => {
        switch (result.getType()) {
          case ENotificationType.UPDATE_ELEMENT_NOTIFICATION:
            result.getUpdateElementIds().forEach((id: string) => {
              if (this.model) {
                const match: HierarchicalMenuItem = this.getCorrespondingHMI(this.model, id);
                if (match) {
                  this.getHierarchicalContent(match).subscribe((c: Content) => {
                    match.setContent(c);
                    if (
                      this._selectedHMI &&
                      this._selectedHMI.getContent() &&
                      this._selectedHMI.getContent().getId() === c.getId()
                    ) {
                      this.setModeleditorData(match);
                    }
                  });
                }
              }
            });
            break;
          case ENotificationType.HMI_CHILD_NODE_NOTIFICATION:
            this.updateHmis(result.getUpdateElementIds()).subscribe();
            break;
          case ENotificationType.FORCED_NOTIFICATION:
            const resourceIds = [...result.getBelongsToResourceIds()];
            if (result.getBelongsToResource()?.getId()) {
              resourceIds.push(result.getBelongsToResource().getId());
            }

            for (const id of resourceIds) {
              if (this.selectedNode?.getResourceId() === id) {
                this.selectAndClickNode(null, this.selectedNode).subscribe();
                break;
              }
            }
            break;
        }

        if (!result || !result.getClazz() || !result.getId()) {
          return;
        }

        this.freetextFilterService.getPath(result.getClazz(), result.getId()).subscribe((route) => {
          this.path = route;
          this.path.currentIndex = 0;
        });
      });

    // subscribe to freetextfilter
    this.templateTreeService.removeFreetextChange
      .pipe(takeWhile(() => this.alive))
      .subscribe((item: IHierarchicMenuItem | IHierarchicItem) => {
        item.filterString = '';
        this.checkFilter(item);
        this.freetextFilterService.applyFilterToObject(item);
        this.templateTreeService.onFilterChange.next(item);
      });

    this.freetextFilterService.onFilterChanged.pipe(takeWhile(() => this.alive)).subscribe((filter: any) => {
      const filterHMI: HMI =
        !this._selectedHMI || filter.id !== this._selectedHMI.getUuid()
          ? this.treeData.getNodeByUUID(filter.id, this.nodes)
          : this._selectedHMI;

      if (!filterHMI) {
        return;
      }
      // this._selectedHMI.children = this._selectedHMI.children.filter(elem => elem.fixed);
      filterHMI.setLastFilterType(filter.type);
      this.checkFilter(filterHMI, filter);
      filterHMI.setFilters(this._updateFilter(filterHMI.getFilters(), filter.filters));
      if (this._selectedHMI && this._selectedHMI.getUuid() === filterHMI.getUuid()) {
        this.freetextFilterService.applyFilterToObject(filterHMI);
        this.templateTreeService.onFilterChange.next(filterHMI);
      }
      // this.saveSettings(true);
    });

    this.sharedUiService._onChangeName.pipe(takeWhile(() => this.alive)).subscribe((changes) => {
      if (!changes || !changes.changes) {
        return;
      }

      if (this.changer) {
        this.changer.name = changes.changes;
        this.changer = null;
      }
      /*
      if (this.selectedNode) {
        this.selectedNode.name = changes.changes;
      }*/
    });

    this.globalToolbarService.onAction.pipe(takeWhile(() => this.alive)).subscribe((action) => {
      switch (action.type) {
        case ToolbarAction.DUPLICATE:
          if (this.selectedNode) {
            this.duplicateElement(this.selectedNode);
          }
          break;
        case ToolbarAction.DELETE:
          if (this.selectedNode) {
            // this.deleteElement(this.selectedNode);
          }
          break;
        case ToolbarAction.CREATE:
          if (this._selectedHMI) {
            this.createElement(this._selectedHMI);
          }
          break;
      }
    });

    this.templateOverlayService._onAddData.subscribe((data) => (this.templateOverlayData = data));
  }

  fakeNotification(): void {
    const n: Notification[] = [
      new Notification().setRefreshElementIds(['template.entryelement.name']).setType(ENotificationType.MESSAGE),
      // .setMessageCategory(ENotificationCategory.)
      // .setMessage('HALLO WELT'),
      // .setBelongsToResource(new Resource().setId('Halloca22e064-1248-4bc6-bbd0-4795e4a2a1a5'))
      // .setResourceId("Halloca22e064-1248-4bc6-bbd0-4795e4a2a1a5")

      // // .setUpdateElementIds(["template.hmi.dispositionorderrequestopen"])
      // .setResourceCanonicalName('de.saxms.disposition.protocol.order.DispositionOrderRequest'),

      // new Notification()
      //   .setType(ENotificationType.FRONTEND_UPDATE_NOTIFICATION)
      //   .setBelongsToResource(new Resource().setId('Halloca22e064-1248-4bc6-bbd0-4795e4a2a1a5'))
      //   .setResourceId("Halloca22e064-1248-4bc6-bbd0-4795e4a2a1a5")
      //   .setMessage('DAS IST EINE FAKE NACHRICHT')
      //   .setUpdateElementIds(["template.hmi.dispositionorderrequestopen"])
      //   .setResourceCanonicalName('de.saxms.disposition.protocol.order.DispositionOrderRequest'),
    ];
    this.fcm.injectNotification(n);
    // this.messageApi.show(new Message()
    //   .setText('Lorem ipsum dolor sit Lorem ipsum dolor sit Lorem ipsum dolor sit Lorem ipsum dolor sit Lorem ipsum dolor sit Lorem ipsum dolor sit Lorem ipsum dolor sit Lorem ipsum dolor sit. Lorem ipsum dolor sit Lorem ipsum dolor sit.Lorem ipsum dolor sit Lorem ipsum dolor sitLorem ipsum dolor sit Lorem ipsum dolor sit . Lorem ipsum dolor sit .Lorem ipsum dolor sit  Lorem ipsum dolor sit Lorem ipsum dolor sit  Lorem ipsum dolor sit .Lorem ipsum dolor sit  Lorem ipsum dolor sit ')
    //   .setDuration(50000).setType(EMessageType.ERROR));
    this.actionExecuter
      .executeActions([
        new Action().setLocalCondition(
          '[template.entryelement.vehiclename].value == [template.entryelement.description].value'
        ),
      ])
      .subscribe();
  }

  /**
   * whether batch mode is disabled or not
   * @returns boolean
   */
  get BatchModeDisabled(): boolean {
    if (this._selectedHMI?.getContent()) {
      return false;
    }

    return true;
  }

  /**
   * whether column mode is disabled or not
   * @returns boolean
   */
  get ColumnModeDisabled(): boolean {
    if (
      this._selectedHMI &&
      (this._selectedHMI.getRestUrl() || this._selectedHMI.getHandlingType() === EMenuItemHandlingType.TOGGLE_HMI_STATE)
    ) {
      return false;
    }

    return true;
  }

  private _handleNotification(notification: Notification): void {
    if (!notification) {
      this.refreshOpenedNodes();
      return;
    }

    // updates notification count
    if (
      this.model.getMenuUpdateNotificationsRestURL() &&
      notification.getType() === ENotificationType.MENU_UPDATE_NOTIFICATION
    ) {
      this.uiService.getData(this.model.getMenuUpdateNotificationsRestURL()).subscribe((listOfNotification: any[]) => {
        this.applyRecursivly(listOfNotification);
      });
    }

    // if updated item is current selected item
    if (
      notification.getType() === ENotificationType.FRONTEND_UPDATE_NOTIFICATION &&
      (!notification.getBelongsToResource() ||
        (notification.getBelongsToResource() &&
          this.selectedNode &&
          notification.getBelongsToResource().getId() === this.selectedNode.getUuid()))
    ) {
      if (notification.getUpdateElementIds().length > 0) {
        return;
        // return this._updateMenuItems(notification.getUpdateElementIds());
      }

      this.refreshOpenedNodes();
      // open dialog
      if (this.refreshDialog) {
        this.refreshDialog.close();
      }

      if (!notification.getBelongsToResource()) {
        this.updateTree().subscribe();
        return;
      }

      // open lightbox if belongs to resource is exactly the same id as current selected node
      if (notification.getResourceCanonicalName() && notification.getResourceId()) {
        const conf: ConfirmLightbox = new ConfirmLightbox('DIALOG.CONFIRM.UPDATE.title').setCustomConfirmAction(() => {
          return this.templateTreeService.getPath(
            notification.getResourceCanonicalName(),
            notification.getResourceId()
          );
        });
        this.lightboxApi.open(conf);
      }
    } else {
      this.refreshOpenedNodes();
    }
  }

  private updateSub: Subject<void> = new Subject<void>();

  private updateHmiQueue: string[];
  private hmiUpdateInProgress: boolean;
  private updateHmis(ids: string[]): Observable<any> {
    this.updateHmiQueue = (this.updateHmiQueue || [])
      .concat(ids)
      .filter((item: string, pos: number, list: string[]) => list.indexOf(item) === pos);
    if (this.hmiUpdateInProgress) {
      return of(null);
    }
    this.hmiUpdateInProgress = true;

    const id: string = this.updateHmiQueue.shift();
    const n: HierarchicalMenuItem = this.treeData.getNodeById(id, this.nodes);
    return this.treeData
      .toggleNode(n, true, this.treeData.getExpandedStates(this.nodes, true))
      .pipe(
        finalize(() => {
          this.hmiUpdateInProgress = false;
          if (this.updateHmiQueue.length > 0) {
            this.updateHmis(this.updateHmiQueue).subscribe();
          }
        })
      )
      .pipe(takeUntil(this.updateSub));
  }

  private getCorrespondingHMI(menu: any, id: string): HierarchicalMenuItem {
    if (!menu) {
      return null;
    }

    for (const hmi of menu.getHierachicMenuItems()) {
      if (hmi.getId() === id) {
        return hmi;
      }
      const match: HierarchicalMenuItem = this.getCorrespondingHMI(hmi, id);
      if (match) {
        return match;
      }
    }
    return null;
  }

  // /**
  //  * check given ids for current selected hmi
  //  * update if it was foud inside one of its sub trees
  //  * @param ids string[]
  //  * @returns void
  //  */
  // private _updateMenuItems(ids: string[]): void {
  //   // if no hmi is selected, return
  //   if (!this._selectedHMI || this.getMode() !== EColumnMode.TWO_COLUMNS) { return; }

  //   ids.forEach((id: string) => {
  //     const found: boolean = this._selectedHMI.getParentList().indexOf(id) === -1 ? false : true;
  //     if (found && this.secondColComponent) {
  //       this.secondColComponent.initialize();
  //     }
  //   });
  // }

  private ngUpdateTree: Subject<void> = new Subject<void>();
  updateTree(skipClick?: boolean): Observable<any> {
    const visibleNodes: { [key: string]: boolean } = this.templateTreeService.getVisibleNodes(this.nodes, {});
    // const selectedNode = this.selectedNode;

    const selectedHMI = this._selectedHMI;
    const selectedNode = this.selectedNode;

    // const selectedNode = this.selectedNode;
    if (!this.model?.getReloadMenuRestURL()) {
      return of(null);
    }
    this.ngUpdateTree.next();
    return this.uiService
      .getData(this.model.getReloadMenuRestURL())
      .pipe(takeUntil(this.ngUpdateTree))
      .pipe(
        switchMap((result) => {
          this.getStartTemplate(result, visibleNodes, false);
          if (!skipClick && selectedHMI) {
            const match = this.getNodeById(selectedHMI.getId(), this.nodes) as HierarchicalMenuItem;
            this.setActiveHMI(match);
            return this.selectAndClickNode(null, match, true, true, true).pipe(
              switchMap(() => {
                if (selectedNode) {
                  return this.selectAndClickNode(null, selectedNode, true, true, true);
                }
                return of(null);
              })
            );
          }

          return of(null);
        })
      );
  }

  /**
   * toggle toolbar and saves changes
   * @returns void
   */
  toggleToolbar(): void {
    this.toolbarExtended = !this.toolbarExtended;
  }

  /**
   * Toggles tree area visibility
   */
  public onGutterClick(event: ISplitEvent): void {
    // Get current time
    const now: number = new Date().getTime();

    // Get index of clicked area
    const index = event.gutterNum - 1;

    // Check for double click
    if (now - this.gutterClickTime < 500) {
      // Tree area visible
      if (this.treeSizes[index]) {
        this.oldTreeSizes[index] = this.treeSizes[index];
        this.treeSizes[index] = 0;
      }
      // Tree area hidden
      else {
        this.treeSizes[index] = this.oldTreeSizes[index];
      }

      // Save settings
      this._treeSettingsApi.updateTreeSettingsKey(ETreeSettingsKeys.TREE_DIMENSIONS, this.treeSizes, true);

      // Trigger tree resize event
      this.templateTreeService.resizeTree();
    }

    // Not a double click
    else {
      this.gutterClickTime = now;
    }
  }

  public onDragEnd(event: ISplitEvent): void {
    switch (event.gutterNum) {
      case 1:
        this.treeSizes[0] = event.sizes[0];
        break;
      case 2:
        this.treeSizes[1] = event.sizes[1];
        break;
    }

    if (this._treeSettings) {
      this._treeSettings[ETreeSettingsKeys.TREE_DIMENSIONS] = this.treeSizes;
      this._treeSettingsApi.updateTreeSettingsKey(ETreeSettingsKeys.TREE_DIMENSIONS, this.treeSizes, true);
    }

    this.templateTreeService.resizeTree();
  }

  public onTransitionEnd(): void {
    this.templateTreeService.resizeTree();
  }

  applyRecursivly(listOfNotificationCounts: any[]): void {
    listOfNotificationCounts.forEach((countItem) => {
      if (!isNaN(countItem.notificationsCount)) {
        this.templateTreeService.setNotificationCount(countItem.id, countItem.notificationsCount);
      }

      if (countItem.hierarchicalMenuItems) {
        this.applyRecursivly(countItem.hierarchicalMenuItems);
      }
    });
  }

  private getNodeById(nodeId: string, nodes: HMI[]): HMI {
    return this.getNodeByAttribute('id', nodeId, nodes);
  }

  /**
   * get nodes by a given attribute
   */
  private getNodeByAttribute(attrib: string, value: string, nodes: HMI[]) {
    for (const node of nodes) {
      if (node[attrib] === value) {
        return node;
      }
      const match: HMI = this.getNodeByAttribute(attrib, value, node.getHierachicMenuItems());
      if (match) {
        return match;
      }
    }

    return null;
  }

  /**
   * returns node by uuid
   * @param uuid uuid
   */
  getNodeByUUID(uuid: string, nodes: HMI[]): HMI {
    return this.getNodeByAttribute('uuid', uuid, nodes);
  }

  /**
   * @param _list list of ids
   * @param _levels current node level
   * @returns Observable<TreeNode>;
   */
  public jumpTo(_list: string[], _levels, preDeliveryAction: Action[] = [], initial = false): Observable<FileNode> {
    const curLevels = _levels;
    const list: string[] = _list.slice();
    if (!list.length || list.length === 0) {
      return throwError(() => new Error('ERROR.no_valid_target'));
    }

    // removes one element from list of nodes
    const cur = list.shift();

    const currentNode: any = typeof cur === 'string' ? this.getNodeById(cur, this.nodes) : cur;
    if (!currentNode) {
      if (_list.length <= 1) {
        return this.getData(cur, preDeliveryAction, initial).pipe(
          switchMap((newNode) => {
            const n: HierarchicalMenuItem = this.templateFactory.adapt(newNode);
            n.setRestUrl(cur);
            return this.jumpToNode(n, curLevels, list, true, preDeliveryAction, initial);
          })
        );
      }
      return of(null);
    }
    return this.jumpToNode(currentNode, curLevels, list, false, preDeliveryAction, initial);
  }

  jumpToNode(
    currentNode: HierarchicalMenuItem,
    curLevels,
    list: string[],
    skipRequest = false,
    preDeliveryActions: Action[] = [],
    initial: boolean
  ): Observable<any> {
    if (list.length === 0) {
      this.setMode(EColumnMode.TWO_COLUMNS);
    }
    if ((list.length === 0 && currentNode.getType() === ETemplateType.MENU_ITEM) || list.length > 0) {
      // ensures that MI is not physically clicked in
      // case of MI is not in the current list of HMI
      // children
      if (list.length === 0 && currentNode.getType() === ETemplateType.MENU_ITEM) {
        this.setMode(EColumnMode.TWO_COLUMNS);
        // set data manually
        return this.selectAndClickNode(null, currentNode, true, true, false, skipRequest, false, initial);
      }
      // get node that matches current hierarchy
      const node: HMI = this.getNodeByUUID(currentNode.getUuid(), curLevels);
      // do a physical click on node
      if (node) {
        const skipDataset: boolean = this.mode === EColumnMode.TWO_COLUMNS ? list.length !== 1 : list.length !== 0;
        return this.selectAndClickNode(null, node, true, true, false, true, false, initial).pipe(
          switchMap((result) => this.jumpTo(list, node.getHierachicMenuItems(), preDeliveryActions, initial))
        );
      }
      return throwError('UNKNOWN');
    } else if (list.length === 0 && currentNode.getType() === ETemplateType.HIERARCHIC_MENU_ITEM) {
      this.setMode(EColumnMode.SINGLE_COLUMN);
      const r = this.getNodeById(currentNode.getId(), this.nodes);
      return this.selectAndClickNode(null, r, true, true, true, skipRequest, false, initial);
    }
  }

  /**
   * selects and clicks on a single node
   * @param {MouseEvent} event MouseEvent
   * @param {HMI} node tree node to click on
   * @param {MatMenuTrigger} menuTrigger trigger for contextmenu to close
   */
  selectAndClickNode(
    event: MouseEvent,
    node: HMI,
    forceVisibility = false,
    preventDoubleClick = false,
    singleton = false,
    skipRequest = false,
    skipDataset = false,
    initial = false
  ): Observable<any> {
    return this._onClick(
      event,
      node,
      forceVisibility,
      preventDoubleClick,
      singleton,
      skipRequest,
      skipDataset,
      initial
    );
  }

  /**
   * executes pre delivery actions before click is validated
   */
  private _beforeClick(node: HMI, initial = false): Observable<boolean> {
    if (!node) {
      return of(null);
    }

    node
      .getPreDeliveryActions()
      .forEach((item) => item.setActionUrl(GlobalUtils.transformUrl(node, item.getActionUrl())));
    // node.setPreDeliveryActions(node.getPreDeliveryActions);
    return this.actionExecuter
      .executeActions(node.getPreDeliveryActions().filter((a) => !(!a.isCallOnReloadingPage() && initial)))
      .pipe(
        map(() => true),
        catchError((e) => {
          return throwError(e);
        })
      );
  }

  /**
   * will be executed on click
   */
  private _onClick(
    event: Event,
    node: HMI,
    forceVisibility?: boolean,
    preventDoubleClick?: boolean,
    singleton?: boolean,
    skipRequest?: boolean,
    skipDataset?: boolean,
    initial = false
  ): Observable<any> {
    if (node?.getExecuters().length > 0) {
      return this.actionExecuter.executeActions(node.getExecuters());
    }

    if (!node) {
      this.isLoadingContent = false;
      if (!skipDataset) {
        this.setModeleditorData(null);
      }
      return of('no node was selected');
    }

    this.checkContextChange = true;
    return this.templateUiService
      .checkContextChange(node, null, this.selectedNode)
      .pipe(
        finalize(() => {
          this.checkContextChange = false;
        }),
        switchMap((change: boolean) => {
          if (!change) {
            return of(false);
          }
          return this._beforeClick(node, initial);
        }),
        switchMap((isChange) => {
          if (isChange === false) {
            return of(null);
          }
          this.applyFilter(node);
          const n: any = node;
          if (n.type === 'HierarchicMenuItem' || n.type === 'Menu') {
            if (!skipDataset) {
              this.setActiveHMI(node as HierarchicalMenuItem);
            }
            // this._selectedHMI._filterValues = this._selectedHMI._filterValues ? this._selectedHMI._filterValues : {};
          }

          // check HMI_TOGGLE
          if (!node.getRestUrl() && !node.getHandlingType() && node.getContent()) {
            this.setMode(EColumnMode.SINGLE_COLUMN);
          }

          if (node.getHandlingType() === EMenuItemHandlingType.TOGGLE_HMI_STATE) {
            this.setMode(EColumnMode.SINGLE_COLUMN);
            this.expandNode(node as HierarchicalMenuItem, !node.isExpanded());
            return of('TOGGLE_HMI_STATE');
          }

          // force visibility
          if (forceVisibility === true) {
            this.expandNode(node as HierarchicalMenuItem, forceVisibility);
          }

          if (this._selectedHMI && !this._selectedHMI.getContent()) {
            this.setMode(EColumnMode.TWO_COLUMNS);
          } else if (!this.modeleditorData || this.getMode() !== EColumnMode.TWO_COLUMNS) {
            this.setActiveNode(null);
          }

          if (
            this.getMode() === EColumnMode.TWO_COLUMNS &&
            !n.content &&
            (n.type === 'HierarchicMenuItem' || n.type === 'Menu')
          ) {
            return this.toggleNode(event, node as HierarchicalMenuItem, true);
          } else {
            return this.onNodeClicked(node as HierarchicalMenuItem, preventDoubleClick, skipRequest, skipDataset);
          }
        })
      )
      .pipe(
        map((result) => {
          // turn off loader
          this.isLoadingContent = false;

          // enable/disable navbar actions
          this.toggleNavbarActions();

          // check if there are children, otherwise disable two-column-mode
          const n: any = node;

          if ((result === null || !n.handlingType || !n.restUrl) && n.type !== 'MenuItem') {
            this.setMode(EColumnMode.SINGLE_COLUMN);
            if (singleton === true) {
              return result;
            }
            this.selectAndClickNode(null, node, null, null, true).subscribe();
          } else {
          }
          return result;
        })
      )
      .pipe(
        finalize(() => {
          // turn off loader
          this.isLoadingContent = false;
        })
      );
  }

  setActiveNode(node: HMI): void {
    this.selectedNode = node;
    this.templateTreeService.setCurrentNode(this.selectedNode);
    this.storeCurrentRoute();
  }

  setActiveHMI(hmi: HierarchicalMenuItem): void {
    if (hmi) {
      hmi.setParentList(this.treeData.getParentList(hmi));
    }
    this._selectedHMI = hmi;
    this.templateTreeService.setCurrentHMI(hmi);
    this.storeCurrentRoute();
  }

  /**
   * stores the current route of the tree
   * @returns void
   */
  private storeCurrentRoute(): void {
    if (this._treeSettings) {
      this._treeSettings[ETreeSettingsKeys.PRE_DELIVERY_ACTION] = this.templateTreeService.getPredeliveryActions();
      this._treeSettings[ETreeSettingsKeys.STORED_ROUTE] = this.templateTreeService.generateCurrentPath();
      this._treeSettingsApi.updateTreeSettingsKey(
        ETreeSettingsKeys.STORED_ROUTE,
        this._treeSettings[ETreeSettingsKeys.STORED_ROUTE],
        true
      );
      this._treeSettingsApi.updateTreeSettingsKey(
        ETreeSettingsKeys.PRE_DELIVERY_ACTION,
        this._treeSettings[ETreeSettingsKeys.PRE_DELIVERY_ACTION],
        true
      );
    }
  }

  setMode(mode: EColumnMode): void {
    this.mode = mode;
    this._treeSettingsApi.updateTreeSettingsKey(ETreeSettingsKeys.MODE, mode, true);
  }

  public getMode(): EColumnMode {
    return this.mode;
  }

  /**
   * handles changes of tree-mode
   * @param mode TreeMode
   */
  onModeChange(mode: EColumnMode): void {
    this.setMode(mode);
    this.emptyData();

    const node: HMI = mode === EColumnMode.TWO_COLUMNS ? this.selectedNode : this._selectedHMI;

    this._treeSettingsApi.updateTreeSettingsKey(ETreeSettingsKeys.MODE, mode, true);
    this.setActiveHMI(this._selectedHMI);
    this.selectAndClickNode(null, node).subscribe();
  }

  private doubleClickId?: string;
  isClicked: boolean;
  /**
   * handles click action on tree nodes
   * @param node TreeNode
   */
  onNodeClicked(
    node: HierarchicalMenuItem,
    preventDoubleClick?: boolean,
    skipRequest?: boolean,
    skipDataset?: boolean
  ): Observable<any> {
    return new Observable<any>((observer: Observer<any>) => {
      this.treeData.toggleNode(node).subscribe(() => {
        if (!node) {
          observer.error('no node selected!');
          observer.complete();
          return;
        }

        this.globalToolbarService.currentElement = node;
        this.setActiveNode(node);
        this.templateUiService.clearAll();

        // check for unsaved changes
        this.checkContextChange = true;
        this.templateUiService
          .checkContextChange(node)
          .pipe(
            finalize(() => {
              this.checkContextChange = false;
            })
          )
          .subscribe((resp) => {
            // this._ngZone.runOutsideAngular(() => {
            this.clickTime.next();
            if (this.isClicked && !preventDoubleClick && this.doubleClickId === node.getUuid()) {
              this.doubleClickId = undefined;
              this.isClicked = false;
              // double click on tree leaf
              this.handleDoubleClick(node);
              observer.next('double click');
              observer.complete();
            } else {
              // single click on tree leaf
              this.doubleClickId = node.getUuid();
              this.isClicked = true;
              of(null)
                .pipe(delay(500), takeUntil(this.clickTime))
                .subscribe(() => {
                  this.isClicked = false;
                  this.ngNext.next();
                  this.handleClick(node, skipRequest, skipDataset)
                    .pipe(
                      takeUntil(this.ngNext),
                      takeUntil(this.clickTime),
                      finalize(() => {
                        this.isLoadingContent = false;
                        observer.complete();
                      })
                    )
                    .subscribe(
                      (res) => {
                        this.doubleClickId = undefined;
                        observer.next('single click');
                      },
                      (err) => {
                        observer.error(err);
                      }
                    );
                });
            }
            // });
          });
      });
    });
  }

  /**
   * close the current active content
   */
  public close(): void {
    this.checkContextChange = true;
    this.templateUiService
      .checkContextChange(null)
      .pipe(
        finalize(() => {
          this.checkContextChange = false;
        })
      )
      .subscribe((change: boolean) => {
        if (!change) {
          return;
        }
        this.setModeleditorData(null);
        if (this.mode === EColumnMode.TWO_COLUMNS) {
          this.setActiveNode(null);
        } else {
          this.setActiveNode(null);
          this.setActiveHMI(null);
        }
      });
  }

  private checkHandlingType(node): Observable<any> {
    this.currentDisplayedItems = null;
    switch (node.handlingType) {
      case 'TEMPLATE':
        return this.handleTemplate(node);
      case 'WIDGETS':
        return this.handleResult(this.uiService.listenToWidgets(), node);
      case 'WIDGETS_BDE':
        return this.handleResult(this.uiService.listenToSteeringWidgets(), node);
      case 'WIDGET_VIEWS':
        return this.handleResult(this.uiService.listenToViews(), node);
      case 'WIDGET_VIEWS_BDE':
        return this.handleResult(this.uiService.listenToSteeringViews(), node);
      case 'PROCESS_VIEW':
        this.setMode(EColumnMode.SINGLE_COLUMN);
        return this.handleResult(this.uiService.listenToNodeNetworkViews(NodeNetworkType.PRODUCT), node);
      case 'MACHINE_VIEW':
        this.setMode(EColumnMode.SINGLE_COLUMN);
        return this.handleResult(this.uiService.listenToNodeNetworkViews(NodeNetworkType.MACHINECOMPLETE), node);
      case 'SOLVER_REPORT_PERSONAL':
      case 'SOLVER_REPORT_FRONTEND_MACHINES':
      case 'SOLVER_REPORT_OVERVIEW_DAY_SHIFT_DEMAND':
      case 'SOLVER_REPORT_BROKEN_RULES':
      case 'SOLVER_REPORT_TIME_DISTRIBUTION_PER_EMPLOYEE':
      case 'SOLVER_REPORT_QUALI_TABLE_EASY':
      case 'SOLVER_REPORT_QUALI_TABLE_GROUP':
        this.emptyData();
        of(null)
          .pipe(delay(0))
          .subscribe(() => {
            this.solverData = { content: node };
          });
        return of('solver');
      case EMenuItemHandlingType.SETTINGS:
        return of(node);
      default:
        return of(`${node.handlingType} is not supported!`);
    }
  }

  // @Todo complex filter
  applyFilter(node: HMI): void {
    if (node.getType() === ETemplateType.HIERARCHIC_MENU_ITEM && node.getFilterSearchUrl()) {
      this.globalToolbarService.trigger({
        type: ToolbarAction.ENABLE,
        data: ToolbarAction.SEARCH,
      });
      this.freetextFilterService.applyFilterToObject(node);
    } else if (this.templateTreeService.getParent(node) && node.getFilterSearchUrl()) {
      this.globalToolbarService.trigger({
        type: ToolbarAction.DISABLE,
        data: ToolbarAction.SEARCH,
      });
      this.freetextFilterService.applyFilterToObject(this.templateTreeService.getParent(node));
    } else if (node.getFilters().length > 0) {
      this.freetextFilterService.applyFilterToObject(node);
    } else {
      this.globalToolbarService.trigger({
        type: ToolbarAction.DISABLE,
        data: ToolbarAction.SEARCH,
      });
    }
  }

  checkFilter(item: any, filter?: any): void {
    if (FreetextFilter.FREETEXT_FILTER === item.lastFilterType || !filter) {
      if (!filter || filter.value === '') {
        item.lastFilterType = null;
      }

      item.filterString = (filter ? filter.value : item.filterString).toLowerCase();
      // this.mark = item.filterString;
      // this.filter = false;
    } else if (FreetextFilter.COMPLEX_FILTER === item.lastFilterType) {
      // this.filter = true;
      for (const fi of item.filters) {
        if (!fi.value) {
          delete fi.value;
        }

        if (!fi.value || typeof fi.value === 'boolean') {
          continue;
        }

        if (Object.keys(fi.value) && Object.keys(fi.value).length === 0) {
          fi.value = null;
        }
      }

      item.filterString = null;
    }

    this.saveFilterSettings(item, filter);
    this.handleTemplate(item).subscribe((res) => {});
    // this.setActive();
  }
  private nodeSettings: any;

  public saveFilterSettings(item: any, filter: any): void {
    if (!this.nodeSettings) {
      this.nodeSettings = {};
    }

    this.nodeSettings[item.id] = filter;
    this.userSettingsService.setKey(this.settings, EUserSettingsKey.TREE_FILTER, this.nodeSettings).subscribe();
  }

  public applyFilterSettings(): void {
    if (!this.nodes || !this.settings) {
      return;
    }

    this.userSettingsService.getKey(this.settings, EUserSettingsKey.TREE_FILTER).subscribe((settings) => {
      this.nodeSettings = settings;
      this.applyFilterRecursivly(this.nodes, this.nodeSettings);
    });
  }

  applyFilterRecursivly(items: HMI[], settings): void {
    if (!items || items.length === 0 || !settings) {
      return;
    }

    items.forEach((item: HMI) => {
      if (settings[item.getId()]) {
        // APPLY FILTER
        item.setFilters(this._updateFilter(item.getFilters(), settings[item.getId()].filters));
        item.setLastFilterType(settings[item.getId()].type);

        if (item.getLastFilterType() === FreetextFilter.COMPLEX_FILTER) {
          item.setFilterString(null);
        } else {
          item.setFilterString(
            !settings[item.getId()].value || settings[item.getId()].value === '' ? null : settings[item.getId()].value
          );
          // if last filter was freetext and it now equals null, than reset filtertype
          if (!item.getFilterString()) {
            item.setLastFilterType(null);
          }
        }
      }
      this.applyFilterRecursivly(item.getHierachicMenuItems(), settings);
    });
  }

  private _updateFilter(a: Filter[], b: any[]): Filter[] {
    return a.map((f: Filter) => {
      const match = b.find((ff) => ff.fieldIdentifier === f.getFieldIdentifier());
      if (match) {
        f.setSortOrder(match.sortOrder);
        f.setValue(match.value);
      }
      return f;
    });
  }

  /**
   * TODO create: add create-function to create new elements
   * @param url createURL of current element
   */
  public createElement(node: HMI): void {
    const n: any = node;
    this.templateTreeService.createElement(n).subscribe();
  }

  /**
   * open dialog
   * @param title title
   * @param hasLabel label
   */
  public openDialog(title: string, hasLabel?: boolean): Promise<any> {
    return new Promise((resolve, reject) => {
      const dialogRef = this.dialog.open(ConfirmDialog, {
        data: {
          title: title,
          text: '',
          hasLabel: hasLabel,
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          resolve(result);
        } else {
          reject(false);
        }
      });
    });
  }

  /**
   * duplicate node
   * @param node IHierarchicMenuItem
   * @returns void
   */
  public duplicateElement(node: HMI): void {
    this.templateTreeService.duplicateElement(node).subscribe((newElement: IHierarchicMenuItem) => {});
  }

  public afterDelete(): void {
    this.setActiveNode(null);
    this.setModeleditorData(null);
    this.toggleNavbarActions();
  }

  /**
   * enable/disable navbar actions
   * @param node TreeNode
   * @returns void
   */
  toggleNavbarActions(): void {
    // actions for hmi
    if (
      this._selectedHMI &&
      (this._selectedHMI.getCreateRestUrls() ||
        (this._selectedHMI.getHandlingType() &&
          (this._selectedHMI.getHandlingType() === EMenuItemHandlingType.WIDGET_VIEWS ||
            this._selectedHMI.getHandlingType() === EMenuItemHandlingType.WIDGET_VIEWS_BDE ||
            this._selectedHMI.getHandlingType() === EMenuItemHandlingType.PROCESS_VIEW)))
    ) {
      this.globalToolbarService.trigger({
        type: ToolbarAction.ENABLE,
        data: ToolbarAction.CREATE,
      });
    } else {
      this.globalToolbarService.trigger({
        type: ToolbarAction.DISABLE,
        data: ToolbarAction.CREATE,
      });
    }

    // selected node
    if (
      this.selectedNode &&
      this.selectedNode.getParentId() &&
      this._selectedHMI &&
      this.selectedNode &&
      this.selectedNode.getCloneRestUrl() &&
      this.selectedNode.getParentId() === this._selectedHMI.getId()
    ) {
      this.globalToolbarService.trigger({
        type: ToolbarAction.ENABLE,
        data: ToolbarAction.DUPLICATE,
      });
    } else {
      this.globalToolbarService.trigger({
        type: ToolbarAction.DISABLE,
        data: ToolbarAction.DUPLICATE,
      });
    }

    if (
      this.selectedNode &&
      this.selectedNode.getParentList().length > 0 &&
      this._selectedHMI &&
      this.selectedNode &&
      (this.selectedNode.getDeleteRestUrl() || this.selectedNode.getType() === ETemplateType.TAB) &&
      this.selectedNode.getParentId() === this._selectedHMI.getId()
    ) {
      this.globalToolbarService.trigger({
        type: ToolbarAction.ENABLE,
        data: ToolbarAction.DELETE,
      });
      this.globalToolbarService.trigger({
        type: ToolbarAction.ENABLE,
        data: ToolbarAction.EDIT,
      });
    } else {
      this.globalToolbarService.trigger({
        type: ToolbarAction.DISABLE,
        data: ToolbarAction.DELETE,
      });
      this.globalToolbarService.trigger({
        type: ToolbarAction.DISABLE,
        data: ToolbarAction.EDIT,
      });
    }
  }

  handleMultipleSelectedNodes(nodes: Array<FileNode>): void {}

  handleDoubleClick(node: any): void {
    switch (node.handlingType) {
      case 'WIDGET_BDE':
      case 'WIDGET':
        // this.globalGridService.trigger({ type: GridAction.ADD_WIDGET, data: node });
        this.globalGridService.trigger({ type: GridAction.POPUP, data: node });
        break;
    }
  }

  setModeleditorData(node): void {
    this.modeleditorData = node;
    this.isLoadingContent = true;
    this.templateTreeService.setContent(this.modeleditorData);
    if (this.modeleditorData?.setShowContentPartNavigation()) {
      this.modeleditorData?.setShowContentPartNavigation(true);
    }
    this.templateApi.currentActiveNode = node;
    of(null)
      .pipe(delay(0))
      .subscribe(() => {
        this.isLoadingContent = false;
        this.$lastVisitedNodeApi.visitNode(this.modeleditorData, this._selectedHMI);
      });
    return node;
  }

  openRecentlyVisitedNode(node: IRecentlyVisitedNode): void {
    if (node.url && node.url !== this.content?.url) {
      this.globalViewService.getPageByTemplate(node.url).subscribe(() => {
        this.jumpTo(node.path, this.nodes).subscribe();
      });
    } else {
      this.jumpTo(node.path, this.nodes).subscribe();
    }
  }

  getMillis(d: Date): number {
    return new Date().getTime() - d.getTime();
  }

  getData(url: string, preDeliveryActions: Action[] = [], initial = false): Observable<any> {
    this.emptyData();
    return this.actionExecuter
      .executeActions(preDeliveryActions.filter((a) => !(!a.isCallOnReloadingPage() && initial)))
      .pipe(
        switchMap(() => {
          // provide elementContent
          return this.uiService.getFromUrl(url).pipe(
            tap((template) => {
              // if (skipDataset) { return of(null); }
              return this.setModeleditorData(this.templateFactory.adapt(template));
            })
          );
        })
      );
  }

  private getHierarchicalContent(node): Observable<Content> {
    if (!node.contentRestUrl) {
      return of(node.content);
    }

    return this._requestApi.call(ERequestMethod.GET, `rest/${node.contentRestUrl}`).pipe(
      map((data) => {
        return this.templateFactory.adapt(data);
      })
    );
  }

  handleClick(node: any, skipRequest?: boolean, skipDataset?: boolean): Observable<any> {
    if (skipRequest || skipDataset) {
      return of(null);
    }

    if ((!node.handlingType || !node.restUrl) && node.type !== 'MenuItem') {
      this.setMode(EColumnMode.SINGLE_COLUMN);
    }

    if (node.content && node.type !== ETemplateType.MENU_ITEM) {
      if (this.mode !== EColumnMode.SINGLE_COLUMN) {
        return of(null);
      }
      this.emptyData();
      if (this.configApi.access().templates.Tree.reloadOnClick === true) {
        this.cd.detectChanges();
      }
      return this.getHierarchicalContent(node).pipe(
        tap((c: Content) => {
          this.$ngZone.run(() => {
            node.content = c;
            this.setModeleditorData(node);
            if (this.modeleditorData && this.modeleditorData.content) {
              (this.modeleditorData.content.contentParts || []).forEach((element) => {
                (element.contentElement || []).forEach((el) => {
                  el.alreadyGotData = false;
                });
              });
            }
          });
        })
      );
    }

    // reset data
    switch (node.handlingType) {
      case 'WIDGET_BDE':
      case 'WIDGET':
        this.globalGridService.trigger({
          type: GridAction.ADD_WIDGET,
          data: node,
        });
        // this.globalGridService.trigger({ type: GridAction.POPUP, data: node });
        return of(true);
      case 'SOLVER_REPORT_PERSONAL':
      case 'SOLVER_REPORT_FRONTEND_MACHINES':
      case 'SOLVER_REPORT_OVERVIEW_DAY_SHIFT_DEMAND':
      case 'SOLVER_REPORT_BROKEN_RULES':
      case 'SOLVER_REPORT_TIME_DISTRIBUTION_PER_EMPLOYEE':
      case 'SOLVER_REPORT_QUALI_TABLE_EASY':
      case 'SOLVER_REPORT_QUALI_TABLE_GROUP':
        this.solverData = { content: node };
        return of(true);

      case 'TEMPLATE_BDE':
        return this.uiService
          .getFromUrl(node.restUrl)
          .pipe(take(1))
          .pipe(
            map((data) => {
              this.bdeData = data;
              return true;
            })
          )
          .pipe(catchError((e) => of(false)));
    }

    if (node && node.restUrl && !node.children) {
      // provide elementContent
      this.isLoadingContent = true;
      return this.getData(node.restUrl).pipe(map((template) => true));
    } else {
      switch (node.type) {
        case 'PROCESS_VIEW':
        case 'MACHINE_VIEW':
          this.nodeNetworkData = node;
          return of(true);
        case 'tab':
          this.widgetData = node;
          this.globalGridService.changeView(node);
          return of(true);
        default:
          return of(`${node.type} is not supported!`);
      }
    }
  }

  public emptyData(): void {
    this.widgetData = this.nodeNetworkData = this.modeleditorData = this.solverData = this.bdeData = null;
  }

  public isDataEmpty(): boolean {
    return this.widgetData || this.nodeNetworkData || this.modeleditorData || this.solverData || this.bdeData;
  }

  handleFavorite(node: FileNode, child: any): void {
    child.favourite = !child.favourite;
    child.actions[0].name = child.favourite ? 'star' : 'star_border';
    const cast: any = node;
    if (cast.handlingType === 'WIDGET_VIEWS') {
      this.gridService.putFavourite(child.id, child.favourite).subscribe();
    } else {
      throw new Error(`steering was removed on 28.04.2021`);
    }
  }

  handleResult(result: Observable<any>, node: any): Observable<any> {
    return result.pipe(
      map((data) => {
        for (const d of data) {
          if (d.type === 'tab') {
            d.actions = [
              {
                name: d.favourite ? 'star' : 'star_border',
                callback: (res) => this.handleFavorite(node, d),
              },
            ];
          }
        }

        node.children = data;
        node.limit = this._entryLimit;

        node.size = data.length;
        node.limit = node.size < node.limit ? node.size : node.limit;

        node.children = node.children.slice();
        if (node.handlingType === 'PROCESS_VIEW' || node.handlingType === 'MACHINE_VIEW') {
          this.emptyData();
          this.nodeNetworkData = node.children[0];
        }
        this.currentDisplayedItems = node.children;

        if (node.size > node.limit) {
          node.lazyLoading = true;
        }
      })
    );
  }

  handleTemplate(node: any): Observable<any> {
    if (node.restUrl) {
      return of('skip requests!');
      // return this.onData(node);
    } else {
      return of(null);
    }
  }

  checkVisibleNodes(node: any): void {
    const n: any = node;
    this.openedTreeNodes = this.openedTreeNodes.filter((_node) => _node.uuid !== node.uuid);

    if (node.leaf === false && n.handlingType) {
      this.openedTreeNodes.push(node);
    }
  }

  /**
   * refresh all nodes
   */
  public refreshOpenedNodes(): void {
    const copy: HierarchicalMenuItem[] = this.treeData.getOpenedNodeIds();
    for (const node of copy) {
      // check children
      this.toggleNode(null, node, node.isExpanded()).subscribe();
    }
  }

  expandNode(node: HierarchicalMenuItem, state: boolean): void {
    node.setExpanded(state);
    if (state === true && this.treeData.treeControl) {
      this.treeData.treeControl.expand(node);
    }
  }

  toggleNode(
    event: Event,
    node: HierarchicalMenuItem,
    visible?: boolean,
    menuTrigger?: MatMenuTrigger
  ): Observable<any> {
    return this.treeData.toggleNode(node).pipe(
      switchMap(() => {
        if (menuTrigger) {
          menuTrigger.closeMenu();
        }

        if (event) {
          event.stopPropagation();
        }

        this.expandNode(node, visible !== undefined && visible !== null ? visible : !node.isExpanded());
        this.checkVisibleNodes(node);
        if (!node.isExpanded()) {
          return of('in hidden state!'); // return if node goes hidden state
        }

        return this.checkHandlingType(node);
      })
    );
  }

  public changeActiveId(event: any): void {
    this.currentActiveId = event;
  }

  /**
   * angulars on changes hook
   * @param changes SimpleChanges
   * @returns void
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.model && changes.model.previousValue?.id !== changes.model.currentValue?.id) {
      this.getStartTemplate(this.model, this.mapOfVisibleNodes, true);
    }
  }

  public handleElement(event): void {
    this.globalToolbarService.trigger({
      type: ToolbarAction.CHANGE_ELEMENT,
      data: !event.type ? null : event,
    });
  }

  public addView(tab: any, resultReport: any): void {
    tab.type = 'tab';
    tab.uuid = tab.id;
    tab.icon = 'DASHBOARD';
    tab.resultReport = resultReport;
    this.evalution.hierachicMenuItems[1].menuItems.push(tab);
  }

  /**
   * map incoming data for tree
   */
  private mapForTree(template: HierarchicalMenuItem, mapOfVisibleNodes: { [key: string]: boolean }): void {
    this.expandNode(template, mapOfVisibleNodes ? mapOfVisibleNodes[template.getId()] || false : false);
    template.getHierachicMenuItems().forEach((element) => {
      this.mapForTree(element, mapOfVisibleNodes);
    });
  }

  public getStartTemplate(template: Menu, mapOfVisibleNodes?: { [key: string]: boolean }, reset = false): void {
    this.model = template instanceof Template ? template : this.templateFactory.adapt<Menu>(template);
    this._treeSettingsApi.setModelId(this.model.getId());
    this.nodes = [];
    this.templateTreeService.setTree(this.model);

    PredefinedItemUtils.inject(this.model, this.applicationMenuItems);

    PredefinedItemUtils.inject(this.model, PredefinedItemUtils.getSettingsMenuItem(this.model));

    if (this.model.getHierachicMenuItems().length === 0) {
      return;
    }
    this.nodes = this.model.getHierachicMenuItems();
    this.templateTreeService.setNodes(this.nodes);
    this.model.getHierachicMenuItems().forEach((hmi: HierarchicalMenuItem) => this.mapForTree(hmi, mapOfVisibleNodes));

    this.applyFilterSettings();
    if (this.nodes.length) {
      // this.expandNode(this.nodes[0], true);
      this.checkHandlingType(this.nodes[0]).subscribe(() => {
        this.onTreeReady(reset);
      });
    }
  }
  private _treeSettings: ITreeSettings;
  private _settingsSub: Subject<void> = new Subject<void>();
  private getTreeSettings(cb?: () => void): void {
    this.isFirst = true;
    this.$lastVisitedNodeApi.setBlockSaveInHistory(true);
    this._treeSettingsApi
      .onSettingsChanged()
      .pipe(takeUntil(this._settingsSub))
      .pipe(takeWhile(() => this.alive))
      .subscribe((treeSettings: { [key: string]: ITreeSettings }) => {
        this._treeSettings = (treeSettings || {})[this.model.getId()];
        // if (this._treeSettings) {
        this.mode = this._treeSettings?.mode || EColumnMode.TWO_COLUMNS;
        this.treeSizes =
          this._treeSettings && this._treeSettings[ETreeSettingsKeys.TREE_DIMENSIONS]
            ? this._treeSettings[ETreeSettingsKeys.TREE_DIMENSIONS]
            : [20, 20];
        this.mapOfVisibleNodes = this._treeSettings ? this._treeSettings[ETreeSettingsKeys.EXPANDED_NODES] : {};
        if (this.isFirst === true) {
          this.isFirst = false;

          if (!this._treeSettings || !this._treeSettings[ETreeSettingsKeys.EXPANDED_NODES]) {
            this.expandNode(this.nodes[0], true);
          }

          this.cd.detectChanges();
          concat(
            ...this.nodes.map((n) => {
              // this.treeData.toggleNode(n, null, this.mapOfVisibleNodes).subscribe();
              return this.treeData.updateNode(
                n.getUuid(),
                n.getHierachicMenuItems(),
                null,
                this.mapOfVisibleNodes,
                true
              );
            })
          )
            .pipe(
              finalize(() => {
                this.cd.detectChanges();
                const ray = this._treeSettings ? this._treeSettings[ETreeSettingsKeys.PRE_DELIVERY_ACTION] ?? [] : [];
                const actions: Action[] = ray
                  .map((a) => this.actionAdapter.parseAction(Action, a))
                  .filter((a: Action) => a.isCallOnReloadingPage() === true);

                this.jumpTo(
                  this._treeSettings ? this._treeSettings[ETreeSettingsKeys.STORED_ROUTE] ?? [] : [],
                  this.nodes,
                  actions ?? [],
                  true
                )
                  .pipe(
                    take(1),
                    catchError((e) => {
                      console.error(e); // Maybe the fetched resource is a frontend generated view (e.g. settings page)
                      return of(null);
                    }),
                    finalize(() => {
                      this.$lastVisitedNodeApi.setBlockSaveInHistory(false);
                      if (treeSettings) {
                        this.$lastVisitedNodeApi.setRecentlyVisitedNodesFromSettings(
                          treeSettings[this.model.getId()][ETreeSettingsKeys.PAGEHISTORY]
                        );
                      }

                      if (cb) {
                        cb();
                      }
                    })
                  )
                  .subscribe();
              })
            )
            .subscribe();
        }
        // }
      });
  }

  private registerJumpingPoints(cb?: () => void): void {
    this.templateTreeService
      .onCurrentRouteChanged()
      .pipe(takeUntil(this._settingsSub))
      .pipe(takeWhile(() => this.alive))
      .subscribe((routing: Routing) => {
        if (!routing) {
          return;
        }

        // clears current selection
        this.setActiveNode(null);
        this.setActiveHMI(null);

        // jump to element in tree
        if (this.currentNavigation) {
          this.currentNavigation.unsubscribe();
        }

        this.currentNavigation = this.jumpTo(routing.getRoutes(), this.nodes)
          .pipe(
            finalize(() => {
              this.templateTreeService.navigateTo(null);
              if (cb) {
                this.cd.detectChanges();
                cb();
              }
            })
          )
          .subscribe();
      });
  }

  private ready: boolean;
  private onTreeReady(reset = false): void {
    if (reset === true) {
      this._settingsSub.next();
      this.getTreeSettings(this.registerJumpingPoints.bind(this));
    }
    if (this.ready === true && reset !== true) {
      return;
    }
    this.ngInit.next();

    this.ready = true;
    // subscribe to jump event
  }

  public changeElem(event: any): void {
    if (event) {
      // this.globalToolbarService.enableAction('save');
      this.activeElementId = event;

      this.changer = this.selectedNode;
      this.changeElement.emit(this.activeElementId);
    } else {
      // this.globalToolbarService.disableAction('save');
    }
  }

  public onCloseTemplateOverlay(event: any): void {
    this.templateOverlayData = null;
    this.templateOverlayService.onCloseTemplateOverlay(event);
  }

  ngOnDestroy(): void {
    this.alive = false;
    this.ngNext.next();
    this.ngNext.complete();
    this.ngInit.next();
    this.ngInit.complete();
    this.clickTime.next();
    this.clickTime.complete();
    this.$resizeApi.complete(...this.resizeIds);
    this.clickTime.complete();
  }

  // calculateTreeColWidth(initial: number):number {
  //   if (!this.treeWrapper) {
  //     return 20;
  //   }
  //   return (100 / this.treeWrapper.nativeElement.clientWidth) * initial;
  // }

  ngOnInit(): void {
    this.sharedUiService.onAction.subscribe((action) => {
      switch (action.type) {
        case UiAction.REFRESH_TREE:
          this.refreshOpenedNodes();
          break;
      }
    });
  }

  public onCollapseTree(): void {
    this.templateTree.onToggleAllNodes();
  }
}
