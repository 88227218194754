import { HttpHeaderResponse, HttpResponse } from '@angular/common/http';
import * as FileSaver from 'file-saver';

/**
 * Saves a file by opening file-save-as dialog in the browser
 * using file-save library.
 * @param blobContent file content as a Blob
 * @param fileName name file should be saved as
 * @param contentType content-type of the file
 */
export const saveFile = (blobContent: Blob, fileName: string, contentType: string): void => {
  const blob: Blob = new Blob([blobContent], { type: contentType });
  FileSaver.saveAs(blob, fileName);
};

/**
 * Derives file name from the http response
 * by looking inside content-disposition
 * @param res http Response
 */
export const getFilename = <T>(res: Response | HttpResponse<T> | HttpHeaderResponse): string => {
  const fallbackName = 'untitled';
  const contentDisposition = res.headers.get('content-disposition') || '';
  if (!contentDisposition) {
    console.error(`missing Content-Disposition header`);
    return fallbackName;
  }

  for (const regex of [/filename="(.+)"/gim, /filename=(.+)/gim]) {
    const parts = regex.exec(contentDisposition);
    if (!parts) {
      continue;
    }
    const match = parts[1]?.trim();
    if (match) return decodeURIComponent(match);
  }
  console.error(`could not parse filename from Content-Disposition header '${contentDisposition}'`);
  return fallbackName;
};

/**
 * returns content-type
 * @param res http Response
 */
export const getContentType = <T>(res: Response | HttpResponse<T>): string => {
  return res.headers.get('content-type');
};
