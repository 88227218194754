import { Injectable, Type } from '@angular/core';
import { EntryElementFactory } from '@app-modeleditor/components/entry-collection/entry-factory.service';
import { RichTextEditor } from './rich-text-editor';

@Injectable({
  providedIn: 'root',
})
export class RichTextEditorAdapter {
  constructor(private entryElementFactory: EntryElementFactory) {}

  public parseEditor<T extends RichTextEditor>(type: Type<T>, data: any): T {
    const t: T = this.entryElementFactory.inherit<T>(type, data).setHeight(data.height);
    return t;
  }
}
