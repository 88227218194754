import { GanttCallBackStackExecuter } from '../callback-tools/callback-stack-executer';
import { BestGanttPlugIn } from '../plug-ins/gantt-plug-in';

/**
 * This class stores and handles gantt plugins.
 * @keywords plugin, plug in, handler, manager, lifecycle, extension, addin, component
 * @class
 * @constructor
 */
export class BestGanttPluginHandler {
  plugIns: Map<string, BestGanttPlugIn>;
  plugInObservers: any;

  constructor() {
    this.plugIns = new Map<string, BestGanttPlugIn>();
    this.plugInObservers = {};
  }

  /**
   * Calls updateYAxis-function on all registered plugins.
   * @keywords update, yaxis, y axis, height, vertical, rows
   */
  updateYAxis() {
    const s = this;
    for (const key in s.plugIns) {
      if (s.plugIns[key].updateYAxis) s.plugIns[key].updateYAxis();
    }
  }
  /**
   * Adds plugin to plugin handler.
   * @keywords add, plud, plugin, plug in, data, handler
   * @param {string} id Unique store-id.
   * @param {BestGanttPlugIn} plugInObject Gantt plugin to add.
   * @param {BestGantt} ganttDiagram Gantt diagram.
   */
  addPlugIn(id, plugInObject, ganttDiagram) {
    const s = this;
    plugInObject.initPlugIn(ganttDiagram);
    s.plugIns[id] = plugInObject;

    GanttCallBackStackExecuter.execute(s.plugInObservers, plugInObject);
  }

  /**
   * Calls update-function on all registered plugins with given parameter.
   * @keywords update, refresh, plugin, plug in
   * @param {any} args Argument which will be put into all plug in update functions as argument.
   */
  updatePlugIns(args = undefined) {
    const s = this;
    for (const key in s.plugIns) {
      if (s.plugIns[key].update) s.plugIns[key].update(args);
    }
  }
  /**
   * Calls updatePlugInHeight-function on all registered plugins with given parameter.
   * @keywords update, refresh, plugin, plug in, height, vertical
   * @param {any} args Argument which will be put into all plug in updatePlugInHeight functions as argument.
   */
  updatePlugInHeight(args = undefined) {
    const s = this;
    for (const key in s.plugIns) {
      if (s.plugIns[key].updatePlugInHeight) s.plugIns[key].updatePlugInHeight(args);
    }
  }
  /**
   * Removes plugin which was stored with given key.
   * @keywords remove, delete, clear, plugin, plug in, data
   * @param {string} pluginId Store-Id.
   */
  removePlugInById(pluginId) {
    const s = this;
    s.plugIns[pluginId].removePlugIn();
    delete s.plugIns[pluginId];
  }

  /**
   * Removes All PlugIns properly.
   */
  removeAllPlugIns() {
    const s = this;
    for (const pluginID in s.plugIns) {
      s.removePlugInById(pluginID);
    }
  }

  //
  // GETTER & SETTER
  //
  /**
   * Replace plugins with new plugin dataset.
   * @keywords set, list, map, plugin, plug in
   * @param {Map<string, BestGanttPlugIn>} plugIns Plugin dataset.
   * @param {BestGantt} ganttDiagram Gantt diagram.
   */
  setPlugIns(plugIns, ganttDiagram) {
    const s = this;

    s.removeAllPlugIns();

    s.plugIns = plugIns;

    for (const key in s.plugIns) {
      s.plugIns[key].setGanttDiagram(ganttDiagram);
    }
  }
  /**
   * @return {Map<string, BestGanttPlugIn>} All stored plugins.
   */
  getPlugIns(): Map<string, BestGanttPlugIn> {
    return this.plugIns;
  }

  /**
   * Returns Array of all PlugIns of given type.
   * @param {BestGanttPlugIn} plugInType
   * @example let indexCardPlugIns = plugInHandler.getPlugInsOfType(IndexCardExecuter)
   * @return {BestGanttPlugIn[]}
   */
  getPlugInsOfType(plugInType) {
    const s = this;
    const typeSet = [];
    for (const key in s.plugIns) {
      s.plugIns[key] instanceof plugInType ? typeSet.push(s.plugIns[key]) : null;
    }
    return typeSet;
  }

  /**
   * Adds observer for new gantt plugIns. <br>
   * On subscription: <b>Sends every currently active plugin once to the callback function.</b> <br>
   * <b>Callback has to check the plugIn Type itself</b>. <br>
   * Callback should unsubscribe as soon as he got what he wants.
   * @param {string} id callback id
   * @param {function} callback context bound callback function
   */
  subscribeToPlugIns(id, callback) {
    const s = this;

    s.plugInObservers[id] = callback;
    for (const plugIn in s.plugIns) {
      callback(s.plugIns[plugIn]);
    }
  }

  /**
   * Unsubscribes Observer by id
   * @param {string} id callback id
   */
  unSubscribeToPlugIns(id) {
    const s = this;
    delete s.plugInObservers[id];
  }
}
