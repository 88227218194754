import { Injectable, Type } from '@angular/core';
import { ColumnLayout } from './column-layout/column-layout';
import { GridLayout } from './grid-layout/grid-layout';
import { Layout } from './layout';
import { ELayoutType } from './layout-type.enum';
import { VirtualLayout } from './virtual-layout/virtual-layout';

@Injectable({
  providedIn: 'root',
})
export class LayoutFactoryService {
  public getLayout(data: any): Layout {
    let t: Layout;
    switch (data.layoutType) {
      case ELayoutType.COLUMN_LAYOUT:
        t = this.parseLayout<ColumnLayout>(ColumnLayout, data)
          .setColumnCount(data.columnCount)
          .setContent(data.content)
          .setColumnWidths(data.columnWidths);
        break;
      case ELayoutType.GRID_LAYOUT:
        t = this.parseLayout<GridLayout>(GridLayout, data)
          .setColumnCount(data.columnCount)
          .setRowCount(data.rowCount)
          .setContent(data.content)
          .setHeight(data.height);
        break;
      case ELayoutType.VIRTUAL_LAYOUT:
        t = this.parseLayout<VirtualLayout>(VirtualLayout, data).setViewportHeight(data.setViewportHeight);
        break;
    }
    return t;
  }

  private parseLayout<T extends Layout>(type: Type<T>, data: any): T {
    const t: T = new type().setResizeMode(data.resizeMode).setType(data.type);
    return t;
  }
}
