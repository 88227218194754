<div *ngIf="!itemsPerRow">{{ entryCollection.getName() }} lädt ...</div>
<div class="fake">
  <div class="fake-container" #fakeContainer>
    <ng-container *ngIf="fakeElement">
      <ng-container *ngTemplateOutlet="contentElement; context: { $implicit: fakeElement, root: fakeContainer }">
      </ng-container>
    </ng-container>
  </div>
</div>
<ng-container *ngIf="itemsPerRow">
  <cdk-virtual-scroll-viewport
    [style.height]="viewportHeight ? viewportHeight : layout.getViewportHeight() + 'px'"
    class="scrollport"
    [itemSize]="itemHeight"
    *ngIf="rows?.length > 0 && itemHeight"
  >
    <div #rowRef *cdkVirtualFor="let entry of rows" class="row" [style.height]="itemHeight + 'px'">
      <ng-container *ngFor="let part of entry.getTemplates()">
        <ng-container *ngTemplateOutlet="contentElement; context: { $implicit: part, root: rowRef }"> </ng-container>
      </ng-container>
    </div>
  </cdk-virtual-scroll-viewport>
</ng-container>
