/**
 * Configuration if save buttons should be visible.
 */
export class SaveButtonRestriction {
  public showSaveButton = false;
  public showSaveAsButton = false;

  public displayAnyButton(): boolean {
    return this.showSaveButton || this.showSaveAsButton;
  }
}
