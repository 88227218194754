import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IGanttSettingsInputData } from 'frontend/src/dashboard/gantt/general/gantt-settings/gantt-settings-dialog/gantt-settings.interface';
import { PreviewGanttService } from './preview-gantt/preview-gantt.service';

/**
 * Displays the Gantt settings menu with control buttons and Gantt preview
 */
@Component({
  selector: 'app-gantt-settings',
  templateUrl: './gantt-settings.component.html',
  styleUrls: ['./gantt-settings.component.scss'],
})
export class GanttSettingsComponent implements OnInit {
  @Input() data: IGanttSettingsInputData;
  @Output() changeEvent: EventEmitter<IGanttSettingsInputData> = new EventEmitter();

  constructor(private previewGanttService: PreviewGanttService) {}

  ngOnInit(): void {}

  onChanges() {
    this.changeEvent.emit(this.data);
    this.previewGanttService.triggerSettingsChanged();
  }
}
