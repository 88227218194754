import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ConfigService } from '@core/config/config.service';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-password-info',
  standalone: true,
  imports: [CommonModule, TranslateModule, MatIconModule],
  templateUrl: './password-info.component.html',
  styleUrls: ['./password-info.component.scss'],
})
export class PasswordInfoComponent {
  private readonly passwordConfig = inject(ConfigService).access().security?.password;
  protected readonly minPasswordLength = this.passwordConfig?.minLength || 8;
  protected readonly maxPasswordLength = this.passwordConfig?.maxLength || 25;
  protected readonly lowercase = this.passwordConfig?.lowercase ?? true;
  protected readonly uppercase = this.passwordConfig?.uppercase ?? true;
  protected readonly decimal = this.passwordConfig?.decimal ?? true;
  protected readonly specialDecodedCharacters = (this.passwordConfig?.specialCharacters || []).join(' ');
}
