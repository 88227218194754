import { BaseContentElement } from '../base/base-content-element';
import { DataType } from '../base/base-template';
import { InputType } from '../mapper';

export interface EntryElement extends BaseContentElement {
  actionOperation: 'SINGLE_OBJECT';
  alwaysEnabled: boolean;
  breakLine: boolean;
  buttonType: 'NONE';
  datatype: string;
  disableArrows: boolean;
  displayType: 'ICON_AND_LABEL';
  editable: boolean;
  enabled: boolean;
  fieldIdentifier: string;
  fieldType: 'BUTTON';
  freeSelection: boolean;
  globalActions: [];
  internUploadButtonAvailible: boolean;
  loadAllOptionsObjectSelector: boolean;
  lowerBound: number;
  multitenant: boolean;
  nullable: boolean;
  position: 'LEFT' | 'RIGHT';
  poweredByGoogle: boolean;
  refreshElementIds: string[];
  required: boolean;
  scrollable: boolean;
  selectable: boolean;
  show: boolean;
  stepWidth: number;
  toolbar: boolean;
  toolbarGroupIndexOrder: number;
  toolbarItemIndexOrder: number;
  type: DataType.entryElement;
  unique: boolean;
  upperBound: number;
  useLocalTime: boolean;
  useMillisecondsForCalendarWeek: boolean;
  uuid: string;
}

export const mapEntryElement = <T extends DataType>(item: InputType<T>): EntryElement => {
  return {
    ...item,
    type: item.type,
  } as EntryElement;
};
