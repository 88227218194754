import { inject, Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { concat, of } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { insertOrUpdateContract } from '../../utils/state-operators/insert-or-update.operator';
import { BaseTemplate, DataType } from './base/base-template';
import { mapItem } from './mapper';
import { TemplateActions } from './template.actions';
import { TemplateService } from './template.service';

export interface ResourceStatus {
  editable: boolean;
  id: string;
}

export interface TemplateStateModel {
  templates: Partial<BaseTemplate>[];
  resources: ResourceStatus[];
}

/**
 * List of supported template types for the new template handling
 */
export const NEW_SUPPORTED_TYPES: string[] = [DataType.breadcrumbs, DataType.fileViewer];

@State<TemplateStateModel>({
  name: 'template',
  defaults: {
    resources: [],
    templates: [],
  },
})
@Injectable()
export class TemplateState {
  private readonly templateService = inject(TemplateService);

  @Selector()
  static resources(state: TemplateStateModel): ResourceStatus[] {
    return state.resources;
  }

  @Selector()
  static templates<T extends BaseTemplate>(state: TemplateStateModel): T[] {
    return state.templates as T[];
  }

  @Action(TemplateActions.AddTemplate)
  addTemplate(ctx: StateContext<TemplateStateModel>, action: TemplateActions.AddTemplate) {
    const resourceId = action.resourceId;
    const template = action.template;

    try {
      const item = mapItem(template);
      const templates = (ctx.getState().templates ?? []).filter((i) => i.id !== item.id).concat(item);
      ctx.patchState({ templates });
    } catch (e) {}
  }
  @Action(TemplateActions.RemoveTemplate)
  removeTemplate(ctx: StateContext<TemplateStateModel>, action: TemplateActions.RemoveTemplate) {
    ctx.patchState({ templates: ctx.getState().templates.filter((i) => i.id !== action.id) });
  }

  @Action(TemplateActions.UpdateResource)
  updateResource(ctx: StateContext<TemplateStateModel>, action: TemplateActions.UpdateResource) {
    const { state, resourceId } = action;

    ctx.setState(
      patch({
        resources: insertOrUpdateContract(resourceId, {
          id: resourceId,
          editable: state,
        }),
      })
    );
  }

  @Action(TemplateActions.RefreshElements)
  refreshElements(ctx: StateContext<TemplateStateModel>, action: TemplateActions.RefreshElements) {
    const ids = action.elements ?? [];
    if (!ids || ids?.length === 0) {
      return of(null);
    }

    const state = ctx.getState();
    const elements = state.templates?.filter(
      (t) => ids.includes(t.id) && NEW_SUPPORTED_TYPES.includes((t.type || '').toLowerCase())
    );
    const requests = elements.map((i) =>
      this.templateService.refreshElement(i.id, i.updateTemplateRestUrl || i.restUrl)
    );

    const templates: BaseTemplate[] = [];
    return concat(...requests).pipe(
      finalize(() =>
        ctx.patchState({ templates: state.templates?.filter((t) => !ids.includes(t.id)).concat(templates) })
      ),
      tap((r) => templates.push(mapItem(r)))
    );
  }
}
