import { GanttCallBackStackExecuter } from '../../callback-tools/callback-stack-executer';
import { ShiftDataFinder } from '../../data-handler/data-finder/shift-data-finder';
import { BestGantt } from '../../main';
import { GanttShiftComponents } from './shift-components';
import { GanttShiftComponentsChainHandler } from './shift-components-chain-handler';
import { GanttShiftComponentsEvent } from './undo-redo/shift-components-event';

/**
 * DataHandler for Shift Component Plugin
 * @author Florian Freier
 * @class
 * @constructor
 * @plugin shift-components
 * @param {BestGantt} ganttDiagram
 * @param {GanttShiftComponentsChainHandler} chainHandler
 * @property {GanttShiftComponentsDataItem[]} shiftComponents
 */
export class GanttShiftComponentsDataHandler {
  ganttDiagram: BestGantt;
  shiftComponentReference: GanttShiftComponents;
  chainHandler: GanttShiftComponentsChainHandler;
  eventLogging: boolean;
  callBack: any;
  superBlockBackendData: any;
  shiftComponents: GanttShiftComponentsDataItem[];

  constructor(ganttDiagram, chainHandler, shiftComponentReference, eventLogging = true) {
    this.ganttDiagram = ganttDiagram;
    this.shiftComponentReference = shiftComponentReference;
    this.chainHandler = chainHandler;
    this.eventLogging = eventLogging;

    this.callBack = {
      afterRemoveComponents: {},
    };

    this.superBlockBackendData = {};

    /* shifComponents data structure:
        [{id: string,
          tooltip: string,
          group: [shiftId1, shiftId2, ... , shiftIdN]
        }]
    */
    this.shiftComponents = [];
  }

  /**
   * Facade Handling all sorts of adding, merging of new and existing components
   * @param {Selection} selection D3 selection of shifts (new ones and components (shifts) of existing component group)
   * @param {string} newGROUPID id for the group to be created
   * @param {boolean} [logEvents] Optional boolean to disable eventLogging.
   */
  AdditionFacade(selection, newGROUPID, logEvents = true) {
    const s = this;
    const canvasData = s.ganttDiagram.getDataHandler().getCanvasShiftDataset();
    const shiftComponents = s.getShiftComponents();
    const shiftsINComponent = selection.filter((shift) => s.isShiftAComponentByID(shift)); // filter selection for shifts that are in a componentGroup

    const shiftsNOTinComponent = selection.filter(function (elem) {
      // filter selection for shifts that are not in a componentGroup
      return shiftsINComponent.indexOf(elem) < 0;
    });

    if (shiftsINComponent.length > 0) {
      // Check that only one group is in selection
      if (!s.areAllComponentsInSameGroup(shiftsINComponent)) {
        // if several existing groups in selection merge them into one
        /* if (logEvents)
          var event = s.ganttDiagram.getHistory().addNewEvent("merge-SEVERAL-into-one-group", new GanttShiftComponentsEvent(), this,
            s.shiftComponentReference,
            JSON.parse(JSON.stringify(s.shiftComponents))); */
        s._handleMoreThanOneGroupInSelection(selection, shiftsINComponent, shiftsNOTinComponent, newGROUPID); // OR split something off
        /* if (logEvents)
          event.addAnotherArgument(JSON.parse(JSON.stringify(s.shiftComponents))); */
        return;
      } else {
        // only one group and other shifts
        var orderedSelection = s._sortSelectionAscending(selection);
        if (
          !s.isComponentFirstOfItsGroupByID(orderedSelection[0]) &&
          !s.isComponentLastOfItsGroupByID(orderedSelection[orderedSelection.length - 1])
        ) {
          /* if (logEvents)
            var event = s.ganttDiagram.getHistory().addNewEvent("merge-into-one-group", new GanttShiftComponentsEvent(), this,
              s.shiftComponentReference,
              JSON.parse(JSON.stringify(s.shiftComponents))); */

          s._handleSplitOff(orderedSelection, shiftsINComponent, newGROUPID);
          /*  if (logEvents)
            event.addAnotherArgument(JSON.parse(JSON.stringify(s.shiftComponents))); */
          return;
        }
      }
    }

    // Handling insert, append, prepend
    const groupReplacer = [];
    for (var i = 0; i < shiftsNOTinComponent.length; i++) {
      // put new shifts in the new group array
      var canvasShift = ShiftDataFinder.getCanvasShiftById(canvasData, shiftsNOTinComponent[i])[0];
      groupReplacer.push(canvasShift);
    }

    if (shiftsINComponent.length > 0) {
      // if some shifts in selection are components
      const groupIdx = s.getComponentGroupIndexByComponentID(shiftsINComponent[0]);
      for (var i = 0; i < shiftComponents[groupIdx].group.length; i++) {
        // put existing components in the new group array
        var canvasShift = ShiftDataFinder.getCanvasShiftById(canvasData, shiftComponents[groupIdx].group[i])[0];
        groupReplacer.push(canvasShift);
      }

      groupReplacer.sort(s._sortByX); // sort the new group array

      for (var i = 0; i < groupReplacer.length; i++) {
        // flatten canvases to IDs
        groupReplacer[i] = groupReplacer[i].id;
      }

      if (logEvents)
        s.ganttDiagram.getHistory().addNewEvent(
          'add-to-exiting-group',
          new GanttShiftComponentsEvent(),
          this,
          s.shiftComponentReference /*,
          JSON.parse(JSON.stringify(s.shiftComponents[groupIdx])),
          newGROUPID,
          JSON.parse(JSON.stringify(groupReplacer))*/
        );

      const groupObject = s._createNewGroupObject(newGROUPID, groupReplacer);
      s._replaceGroupByIndex(groupIdx, groupObject); // replace the group with new sorted array
    }

    // Only if no shifts in selection are alredy a component create a new group
    if (shiftsINComponent.length == 0) {
      var orderedSelection = s._sortSelectionAscending(selection);
      if (logEvents)
        s.ganttDiagram.getHistory().addNewEvent(
          'create-new-shiftCompGroup',
          new GanttShiftComponentsEvent(),
          this,
          s.shiftComponentReference /*,
          newGROUPID,
          JSON.parse(JSON.stringify(orderedSelection))*/
        );

      shiftComponents.push(new GanttShiftComponentsDataItem(newGROUPID, orderedSelection));
    }
  }

  /**
   * Facade Handling all sorts of removing of components and groups
   * <mark> remember to update edges after removal </mark>
   * @param {string[]} selection selected Shifts (components to be removed)
   */
  RemoveFacade(selection) {
    const s = this;

    const shiftsINComponent = selection.filter((shift) => s.isShiftAComponentByID(shift)); // filter selection for shifts that are in a componentGroup

    /* var shiftGroupIDs = new Set();
    for (var i = 0; i < shiftsINComponent.length; i++) {      // backup
      shiftGroupIDs.add(s.getComponentGroupIDByComponentID(shiftsINComponent[i]));
    }

    var groupSnap = [];
    var addToGroupSnap(val, key, map) {
      let idx = s.getComponentGroupIndexByComponentGroupID(val);
      groupSnap.push({
        group: JSON.parse(JSON.stringify(s.shiftComponents[idx])),
        index: idx
      }
      );
    }
    shiftGroupIDs.forEach(addToGroupSnap); */

    const groupSnap = s._historyBackUpSnap(shiftsINComponent);

    s.ganttDiagram.getHistory().addNewEvent(
      'remove-components',
      new GanttShiftComponentsEvent(),
      this,
      s.shiftComponentReference /*,
      groupSnap,
      shiftsINComponent*/
    );

    for (let i = 0; i < shiftsINComponent.length; i++) {
      // delete all selected Components
      s.removeComponentByComponentID(shiftsINComponent[i]);
    }
    s._findAndRemoveAllEmptyGroups();
  }

  private _historyBackUpSnap(shiftsINComponent) {
    const s = this;

    const shiftGroupIDs = new Set();
    for (let i = 0; i < shiftsINComponent.length; i++) {
      // backup
      shiftGroupIDs.add(s.getComponentGroupIDByComponentID(shiftsINComponent[i]));
    }

    const groupSnap = [];
    const addToGroupSnap = function (val, key, map) {
      const idx = s.getComponentGroupIndexByComponentGroupID(val);
      groupSnap.push({
        group: JSON.parse(JSON.stringify(s.shiftComponents[idx])),
        index: idx,
      });
    };
    shiftGroupIDs.forEach(addToGroupSnap);

    return groupSnap;
  }

  /**
   * initializes or replaces shiftComponents with dataSet, if dataSet is suitable replacement (structure)
   * @param {Array.<{id:string, group:string[]}>} dataSet dataSet that is supposed to replace the current shiftComponents or initialize them
   * @return {boolean} true if dataSet was set succesfully, else false
   */
  initializeDataSet(dataSet) {
    const s = this;
    return s._setShiftComponents(dataSet);
  }

  // ====================
  // ==>> PUBLIC METHODS
  // ====================

  /**
   * removes a shiftComponent from its Group by component ID
   * <mark> remember to update edges after removal </mark>
   * consider using <i> removeComponentByComponentIDAndGroupID </i> for better performance
   * @param {string} componentID ID of the component that is in the group to be removed from shiftComponents
   * @return {boolean} returns true if removal was succesful, else false
   */
  removeComponentByComponentID(componentID) {
    const s = this;
    const groupIndex = s.getComponentGroupIndexByComponentID(componentID);
    if (groupIndex == -1) return false;
    const indexInGroup = s.getComponentIndexInGroup_ByGroupIndexAndID(groupIndex, componentID);
    if (indexInGroup == -1) return false;
    s.chainHandler.removeComponentFromGroupChaining(componentID);
    return s._removeComponentByComponentIndexInGroupAndGroupIndex(indexInGroup, groupIndex);
  }

  /**
   *  Removes a Component By it's componentID and corresponding Group ID
   * <mark> remember to update edges after removal </mark>
   * <strong> Faster than using <i>removeComponentByComponentID</i> </strong>
   * @param {string} componentID  ID of the component (a shift) to be removed
   * @param {string} componentGroupID ID of the group of the component to be removed
   * @return {boolean} true if removal was succesful, else false
   */
  removeComponentByComponentIDAndGroupID(componentID, componentGroupID) {
    const s = this;
    const groupIndex = s.getComponentGroupIndexByComponentGroupID(componentGroupID);
    if (groupIndex == -1) return false;
    const indexInGroup = s.getComponentIndexInGroup_ByGroupIndexAndID(groupIndex, componentID);
    if (indexInGroup == -1) return false;
    //s.chainHandler.removeComponentFromGroupChaining(componentID);
    return s._removeComponentByComponentIndexInGroupAndGroupIndex(indexInGroup, groupIndex);
  }

  /**
   * removes a ComponentGroup By its groupID
   *  <mark> remember to update edges after removal </mark>
   * @param {string} componentGroupID ID of the group to get removed
   * @return {boolean} true if succesfully deleted, else false
   */
  removeComponentGroupByComponentGroupID(componentGroupID) {
    const s = this;
    return s._removeShiftComponentGroupByComponentGroupID(componentGroupID);
  }

  /**
   * Returns true if shift is a component in a componnet group, false otherwise
   * @param {string} shiftId Id of a shift
   * @return {boolean}
   */
  isShiftAComponentByID(shiftId) {
    const s = this;
    const shiftComponents = s.getShiftComponents();

    for (let i = 0; i < shiftComponents.length; i++) {
      for (let j = 0; j < shiftComponents[i].group.length; j++) {
        if (shiftId == shiftComponents[i].group[j]) {
          return true;
        }
      }
    }
    return false;
  }

  /**
   * Checks if component is first / start of respective group
   * @param {string} componentId ShiftId that HAS TO BE be a component
   * @return {boolean} if component is first in group true, else false
   */
  isComponentFirstOfItsGroupByID(componentId) {
    const s = this;
    const shiftComponents = s.getShiftComponents();
    const gIdx = s.getComponentGroupIndexByComponentID(componentId);
    if (gIdx != -1) {
      if (shiftComponents[gIdx].group[0] == componentId) return true; // is first
    }
    return false; // it isn't
  }

  /**
   * Checks if component is last / end of respective group
   * @param {string} componentId ShiftId that HAS TO BE be a component
   * @return {boolean} 1 if true, else false
   */
  isComponentLastOfItsGroupByID(componentId) {
    const s = this;
    const shiftComponents = s.getShiftComponents();

    const gIdx = s.getComponentGroupIndexByComponentID(componentId);
    if (gIdx != -1) {
      const groupLen = shiftComponents[gIdx].group.length;
      if (shiftComponents[gIdx].group[groupLen - 1] == componentId) return true; // is last
    }
    return false; // it isn't
  }

  /**
   * Checks if all given IDs are in the same ComponentGroup
   * @param {string[]} components shifts of selection that are alredy in a componentGroup
   * @return {boolean} true if all components are in same group, false otherwise
   */
  areAllComponentsInSameGroup(components) {
    const s = this;

    for (let i = 0; i < components.length; i++) {
      if (s.getComponentGroupIndexByComponentID(components[i]) != s.getComponentGroupIndexByComponentID(components[0]))
        return false;
    }
    return true;
  }

  // ====================
  // ==>> PRIVATE METHODS
  // ====================

  /**
   * Handles merging if more than one existing componentGroup is in selection
   * @param {string[]} selection selected Shifts (new ones and components (shifts) of existing component group)
   * @param {string[]} shiftsINComponent subgroup of selection that is ALREDY in a component
   * @param {string[]} shiftsNOTinComponent subgroup of selection that is NOT in a component
   * @private
   */
  private _handleMoreThanOneGroupInSelection(selection, shiftsINComponent, shiftsNOTinComponent, newGROUPID) {
    const s = this;

    const orderedSelection = s._sortSelectionAscending(selection);

    if (
      shiftsINComponent.indexOf(orderedSelection[0] != -1) &&
      shiftsINComponent.indexOf(orderedSelection[selection.length - 1]) != -1
    ) {
      if (
        s.isComponentFirstOfItsGroupByID(orderedSelection[0]) &&
        s.isComponentLastOfItsGroupByID(orderedSelection[selection.length - 1])
      ) {
        s._mergeSeletionIntoOneGroup(shiftsINComponent, shiftsNOTinComponent, newGROUPID);
        return;
      }
    }

    const event = s.ganttDiagram
      .getHistory()
      .addNewEvent(
        'merge-SEVERAL-into-one-group',
        new GanttShiftComponentsEvent(),
        this,
        s.shiftComponentReference /*, JSON.parse(JSON.stringify(s.shiftComponents))*/
      );

    s._handleSplitOff(orderedSelection, shiftsINComponent, newGROUPID);

    event.addAnotherArgument(JSON.parse(JSON.stringify(s.shiftComponents)));

    return;
  }

  /**
   * merges Selected Shifts and Components into one new Group1
   * @param {string[]} shiftsINComponent shifts that are alredy a component of a group
   * @param {string[]} shiftsNOTinComponent shifts that are no components yet
   * @param {string} newGROUPID ID for the new to be created group
   */
  _mergeSeletionIntoOneGroup(shiftsINComponent, shiftsNOTinComponent, newGROUPID) {
    const s = this;
    const shiftComponents = s.getShiftComponents();
    const canvasData = s.ganttDiagram.getDataHandler().getCanvasShiftDataset();
    const groupIndices = [];
    for (var i = 0; i < shiftsINComponent.length; i++) {
      const index = s.getComponentGroupIndexByComponentID(shiftsINComponent[i]);
      if (groupIndices.indexOf(index) == -1) groupIndices.push(index);
    }

    groupIndices.sort();

    const newGroup = [];
    for (var i = 0; i < groupIndices.length; i++) {
      // add all shifts that are alredy in a comp group
      for (let j = 0; j < shiftComponents[groupIndices[i]].group.length; j++) {
        var canvasShift = ShiftDataFinder.getCanvasShiftById(canvasData, shiftComponents[groupIndices[i]].group[j])[0];
        newGroup.push(canvasShift);
      }
    }

    for (var i = 0; i < shiftsNOTinComponent.length; i++) {
      // add new shifts
      var canvasShift = ShiftDataFinder.getCanvasShiftById(canvasData, shiftsNOTinComponent[i])[0];
      newGroup.push(canvasShift);
    }

    newGroup.sort(s._sortByX);
    for (var i = 0; i < newGroup.length; i++) {
      // flatten canvases to IDs
      newGroup[i] = newGroup[i].id;
    }

    const groupObject = s._createNewGroupObject(newGROUPID, newGroup);

    s._replaceGroupByIndex(groupIndices[0], groupObject); // merge everything into the first group

    for (var i = groupIndices.length - 1; i > 0; i--) {
      // remove other groups
      s._removeShiftComponentGroupByGroupIndex(groupIndices[i]); // do it in reverse because the splicing messes up the indices order
    }
  }

  /**
   * Handles <strong> Special Case </strong> if first shift selected is last shift of a component and last selected is a first shift of a component
   * @param {string[]} selection selected Shifts (new ones and components (shifts) of existing component group)
   * @param {string[]} shiftsINComponent subgroup of selection that is ALREDY in a component
   * @param {string} newGROUPID newGroupID
   * @private
   */
  private _handleSplitOff(selection, shiftsINComponents, newGROUPID) {
    const s = this;
    for (let i = 0; i < shiftsINComponents.length; i++) s.removeComponentByComponentID(shiftsINComponents[i]);

    const doNotLogEvent = false;
    s.AdditionFacade(selection, newGROUPID, doNotLogEvent);
  }

  /**
   * Sorts a given array of ShiftIDs ascending by their position (x) <br>
   * works only if IDs are is in the same row
   * @param {string[]} selection array of shiftIDs that have to be in the same row
   * @return {string[]}  selection sorted ascending by x value
   */
  private _sortSelectionAscending(selection) {
    const s = this;

    const canvasData = s.ganttDiagram.getDataHandler().getCanvasShiftDataset();
    const canvasShifts = [];
    for (var i = 0; i < selection.length; i++) {
      const canvasShift = ShiftDataFinder.getCanvasShiftById(canvasData, selection[i])[0];
      canvasShifts.push(canvasShift);
    }
    canvasShifts.sort(s._sortByX);
    const sortedSelection = [];
    for (var i = 0; i < canvasShifts.length; i++) {
      sortedSelection.push(canvasShifts[i].id);
    }
    return sortedSelection;
  }

  /**
   * Replaces a group in the shiftComponents by the ID of the group and a new group
   * @param {number} index index of the group to be replaced
   * @param {string[]} replacement group that replaces old group
   * @return {boolean} true if replacing was succesful, else false
   * @private
   */
  private _replaceGroupByIndex(index, replacement) {
    const s = this;
    const shiftComponents = s.getShiftComponents();
    if (!replacement.id || !replacement.group) return false;
    if (shiftComponents[index]) {
      shiftComponents[index] = replacement;
      return true;
    }
    return false;
  }

  /**
   * removes a component by its componentID and groupIndex
   * <mark> remember to update edges after removal </mark>
   * @param {number} componentIndex Index of component in its group
   * @param {number} groupIndex Index of Group wherein component is
   * @return {boolean} true if removal was succesful, else false
   * @private
   */
  private _removeComponentByComponentIndexInGroupAndGroupIndex(componentIndex, groupIndex) {
    const s = this;
    const components = s.getShiftComponents();

    if (
      groupIndex != -1 &&
      groupIndex < components.length &&
      components[groupIndex] &&
      components[groupIndex].group[componentIndex]
    ) {
      s.chainHandler.removeComponentFromGroupChaining(components[groupIndex].group[componentIndex]);
      const removedComponent = components[groupIndex].group.splice(componentIndex, 1);
      let returnValue = true;
      if (s._isShiftComponentGroupEmptyByGroupIndex(groupIndex))
        // check if group is empty after removal, if yes remove group
        returnValue = s._removeShiftComponentGroupByGroupIndex(groupIndex);

      GanttCallBackStackExecuter.execute(
        s.callBack.afterRemoveComponents,
        new GanttShiftComponentsAfterDelete(componentIndex, groupIndex, removedComponent[0], s.shiftComponents)
      );
      return returnValue;
    }
    return false;
  }

  /**
   * Removes a shiftComponentGroup by index.
   * @param {number} index index of the group to be removed from shiftComponents
   * @return {boolean} true if remove was succesfull, else false
   * @private
   */
  private _removeShiftComponentGroupByGroupIndex(groupIndex) {
    const s = this;
    const shiftComponents = s.getShiftComponents();
    if (groupIndex != -1 && groupIndex < shiftComponents.length && shiftComponents[groupIndex]) {
      s.chainHandler.removeComponentGroupFromGroupChaining(shiftComponents[groupIndex].id);
      shiftComponents.splice(groupIndex, 1);
      return true;
    }
    return false;
  }

  /**
   * removes ShiftComponentGroup By ComponentGroupID
   *
   * @param {string} componentGroupID ID of the componentGroup to get removed
   * @return {boolean} true if removal was succesfull, else false
   * @private
   */
  private _removeShiftComponentGroupByComponentGroupID(componentGroupID) {
    const s = this;
    const groupIndex = s.getComponentGroupIndexByComponentGroupID(componentGroupID);
    if (groupIndex != -1) {
      return s._removeShiftComponentGroupByGroupIndex(groupIndex);
    }
    return false;
  }

  /**
   * removes a shiftComponent Group by componentID
   * <br><mark> Consider using rather _removeShiftComponentGroupByGroupIndex if you computed index alredy for performance reasons </mark>
   * @param {string} componentID ID of the component that is in the group to be removed from shiftComponents
   * @return {boolean} true if removal was sucesful, else false
   * @private
   */
  private _removeShiftComponentGroupByComponentID(componentID) {
    const s = this;
    let index;
    index = s.getComponentGroupIndexByComponentID(componentID);
    if (index == -1) return false;
    if (s._removeShiftComponentGroupByGroupIndex(index)) return true;
    return false;
  }

  /**
   *  finds and removes all empty groups from ShiftComponents
   * @private
   */
  _findAndRemoveAllEmptyGroups() {
    const s = this;
    const shiftComponents = s.getShiftComponents();

    for (let i = 0; i < shiftComponents.length; i++) {
      if (shiftComponents[i].group.length == 0) s._removeShiftComponentGroupByGroupIndex(i);
    }
  }

  /**
   * checks if componentGroup is empty
   * @param {string} componentGroupID ID of the componentGroup to be checked
   * @return {boolean} true if componentGroup is empty, else false
   * @private
   */
  private _isShiftComponentGroupEmptyByGroupID(componentGroupID) {
    const s = this;
    const shiftComponents = s.getShiftComponents();
    const groupIndex = s.getComponentGroupIndexByComponentGroupID(componentGroupID);
    if (groupIndex != -1) {
      if (shiftComponents[groupIndex].group.length == 0) return true;
      else return false;
    }
    return false;
  }

  /**
   * checks if a group is empty by its groupIndex
   * @param {number} groupIndex Index of the group to check
   * @return {boolean} true if componentGroup is empty, else false
   * @private
   */
  private _isShiftComponentGroupEmptyByGroupIndex(groupIndex) {
    const s = this;
    const shiftComponents = s.getShiftComponents();
    if (groupIndex != -1) {
      if (shiftComponents[groupIndex].group.length == 0) return true;
      else return false;
    }
    return false;
  }

  /**
   * Order Helper: orders canvasShifts by their x Position
   * @param {canvasShift} canvasShift1
   * @param {canvasShift} canvasShift2
   * @return {number}
   * @private
   */
  private _sortByX(canvasShift1, canvasShift2) {
    if (canvasShift1.x < canvasShift2.x) return -1;
    if (canvasShift1.x > canvasShift2.x) return 1;
    return 0;
  }

  /**
   * Creates new Component Group with ID and Components
   * @param {String} id new ID for the Component Group
   * @param {String[]} group Shift IDs that will be components of the new group
   * @return {{id:string, group:string[]}}
   * @private
   */
  private _createNewGroupObject(id, group) {
    const groupObject = {
      id: id,
      group: group,
    };
    return groupObject;
  }

  /**
   *  overrides shiftComponents with given DataSet (no validation)
   * @param {Array.<{id:string, group:string[]}>} dataSet the new dataSet replacing shiftComponents
   * @return {boolean} true if dataSet was set succesfully, else false
   * @private
   */
  _setShiftComponents(dataSet) {
    const s = this;
    s.shiftComponents = null;
    s.shiftComponents = dataSet;
    return true;
  }

  //
  // CALLBACKS
  //
  addAfterRemoveComponentsCallback(id, func) {
    this.callBack.afterRemoveComponents[id] = func;
  }
  removeAfterRemoveComponentsCallback(id) {
    delete this.callBack.afterRemoveComponents[id];
  }

  // ====================
  // ==>> GETTER & SETTER
  // ====================

  /**
   * get shiftComponents
   * @return {Array.<{id:string, group:string[]}>} shiftComponents Data
   */
  getShiftComponents() {
    const s = this;
    return s.shiftComponents;
  }

  /**
   * Finds the index of the component / shift in the group
   * @param {number} groupIndex Index of the group you are looking into
   * @param {string} componentID   id of the shift / component
   * @return {number} index if found, else -1
   */
  getComponentIndexInGroup_ByGroupIndexAndID(groupIndex, componentID) {
    const s = this;
    const shiftComponents = s.getShiftComponents();

    const index = shiftComponents[groupIndex].group.indexOf(componentID);
    return index;
  }

  /**
   * Returns the ComponentGroupIndex by Id,
   * @param {string} componentId ShiftId that should be a component
   * @return {number} ComponentGroupIndex if found, else -1
   */
  getComponentGroupIndexByComponentID(componentID) {
    const s = this;
    const shiftComponents = s.getShiftComponents();

    for (let i = 0; i < shiftComponents.length; i++) {
      for (let j = 0; j < shiftComponents[i].group.length; j++) {
        if (componentID == shiftComponents[i].group[j]) return i;
      }
    }
    return -1;
  }

  /**
   * returns componentGroup Index for given ComponentGroupID
   * @param {string} componentGroupID id of componentShiftGroup
   * @return {number} index of the componentGroup
   */
  getComponentGroupIndexByComponentGroupID(componentGroupID) {
    const s = this;
    const shiftComponents = s.getShiftComponents();

    for (let i = 0; i < shiftComponents.length; i++) {
      if (shiftComponents[i].id == componentGroupID) return i;
    }
    return -1;
  }

  /**
   *
   *
   * @param {string} componentId ID of the component for which groupID is seeked
   * @return {(string|undefined)} returns groupID of componentID, undefined if not found
   */
  getComponentGroupIDByComponentID(componentId) {
    const s = this;
    const shiftComponents = s.getShiftComponents();

    for (let i = 0; i < shiftComponents.length; i++) {
      for (let j = 0; j < shiftComponents[i].group.length; j++) {
        if (componentId == shiftComponents[i].group[j]) return shiftComponents[i].id;
      }
    }
    return undefined;
  }
  /**
   * Returns group in which the componentId is included.
   * @param {string} componentId ID of component.
   * @return {string[]} ComponentId group.
   */
  getComponentIDsByComponentID(componentId) {
    const s = this;
    const shiftComponents = s.getShiftComponents();
    for (let i = 0; i < shiftComponents.length; i++) {
      for (let j = 0; j < shiftComponents[i].group.length; j++) {
        if (componentId == shiftComponents[i].group[j]) return shiftComponents[i].group;
      }
    }
    return [componentId];
  }

  /**
   * returns array with IDs of componets of the componentGroupID
   * @param {string} componentGroupID
   * @return {string[]} IDs of the components in the groupID
   */
  getComponentsByComponentGroupID(componentGroupID) {
    const s = this;
    const shiftComponents = s.getShiftComponents();
    for (let i = 0; i < shiftComponents.length; i++) {
      if (shiftComponents[i].id == componentGroupID) {
        return shiftComponents[i].group;
      }
    }
    return null;
  }

  setSuperBlockBackendData(dataset) {
    this.superBlockBackendData = dataset;
  }
  getSuperBlockBackendData() {
    return this.superBlockBackendData;
  }

  /**
   * Adds given data to stored shift component data.
   * Avoids duplicated data.
   * @param {GanttShiftComponentsDataItem[]} dataSet
   */
  addToShiftComponent(dataSet) {
    const s = this;
    for (let i = 0; i < dataSet.length; i++) {
      var dataItem = dataSet[i];
      const foundItem = s.shiftComponents.find((item) => {
        return item.id == dataItem.id;
      });
      if (foundItem) {
        for (let j = 0; j < dataItem.group.length; j++) {
          const shiftId = dataItem.group[j];
          if (foundItem.group.indexOf(shiftId) == -1) foundItem.group.push(shiftId);
        }
      } else s.shiftComponents.push(dataItem);
    }
  }
}

/**
 * @class
 * @constructor
 * @param {string} id Id of group.
 * @property {string}tooltip Optional Tooltip for shift.
 * @property {string[]} group List of shift ids which belongs to group.
 */
export class GanttShiftComponentsDataItem {
  id: string;
  tooltip: string;
  group: string[];
  details: any;

  constructor(id, group?) {
    this.id = id;
    this.tooltip = '';
    this.group = typeof group !== 'undefined' ? group : [];
    this.details = null;
  }
}

export class GanttShiftComponentsAfterDelete {
  componentIndex: number;
  groupIndex: number;
  removedComponent: GanttShiftComponentsDataItem;
  shiftComponents: GanttShiftComponentsDataItem[];

  constructor(componentIndex, groupIndex, removedComponent, shiftComponents) {
    this.componentIndex = componentIndex;
    this.groupIndex = groupIndex;
    this.removedComponent = removedComponent;
    this.shiftComponents = shiftComponents;
  }
}
