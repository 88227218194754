import { ConfigService } from '@core/config/config.service';
import { LegendCommunicationService } from 'frontend/src/dashboard/gantt/gantt/dock/views/legend/legend-communication.service';
import { GanttLibService } from 'frontend/src/dashboard/gantt/gantt/gantt-lib.service';
import { SaxMsBestGanttToolbarHandler } from 'frontend/src/dashboard/gantt/gantt/saxms-best-gantt-submenu-handler';
import { GanttPluginHandlerService } from 'frontend/src/dashboard/gantt/general/plugin/gantt-plugin-handler.service';
import { IGanttAttributeMapping } from '../../generator/gantt-input.data';
import { GanttTemplateDataService } from '../../template-data/gantt-template-data.service';
import { IGanttResponse } from '../gantt-response';

/**
 * Structure for response handling items.
 * @template T Type of executer which will be passed to the response handling item.
 */
export class GanttResponse<T = any> {
  constructor(
    protected toolbarHandler: SaxMsBestGanttToolbarHandler,
    protected executer: T,
    protected _pluginHandlerService: GanttPluginHandlerService,
    protected attributeMapping: IGanttAttributeMapping,
    protected _ganttLibService: GanttLibService,
    protected _legendCommunicationService: LegendCommunicationService,
    protected _configService: ConfigService,
    protected _templateDataService: GanttTemplateDataService
  ) {}

  public handleResponse(response: IGanttResponse): boolean {
    return false;
  }
}
