<div class="node-network-add-element-menu">
  <mat-icon
    class="draggable-menu-node"
    svgIcon="node-network:nn-node"
    draggable="true"
    *ngIf="elementIsActive('de.saxms.protocol.simulation.step.ProcessStep')"
    (dragstart)="setNodeType($event, 'de.saxms.protocol.simulation.step.ProcessStep')"
  ></mat-icon>

  <mat-icon
    class="draggable-menu-node"
    svgIcon="node-network:nn-start"
    draggable="true"
    *ngIf="elementIsActive('de.saxms.protocol.simulation.step.InitialStep')"
    (dragstart)="setNodeType($event, 'de.saxms.protocol.simulation.step.InitialStep')"
  ></mat-icon>

  <mat-icon
    class="draggable-menu-node"
    svgIcon="node-network:nn-both-start"
    draggable="true"
    *ngIf="elementIsActive('de.saxms.protocol.simulation.step.SplitStep')"
    (dragstart)="setNodeType($event, 'de.saxms.protocol.simulation.step.SplitStep')"
  ></mat-icon>

  <mat-icon
    class="draggable-menu-node"
    svgIcon="node-network:nn-both-end"
    draggable="true"
    *ngIf="elementIsActive('de.saxms.protocol.simulation.step.JoinStep')"
    (dragstart)="setNodeType($event, '', 'de.saxms.protocol.simulation.step.JoinStep')"
  ></mat-icon>

  <mat-icon
    class="draggable-menu-node"
    svgIcon="node-network:nn-decision"
    draggable="true"
    *ngIf="elementIsActive('de.saxms.protocol.simulation.step.DecisionStep')"
    (dragstart)="setNodeType($event, 'de.saxms.protocol.simulation.step.DecisionStep')"
  ></mat-icon>

  <mat-icon
    class="draggable-menu-node"
    svgIcon="node-network:nn-merge"
    draggable="true"
    *ngIf="elementIsActive('de.saxms.protocol.simulation.step.MergeStep')"
    (dragstart)="setNodeType($event, 'de.saxms.protocol.simulation.step.MergeStep')"
  ></mat-icon>

  <mat-icon
    class="draggable-menu-node"
    svgIcon="node-network:nn-end1"
    draggable="true"
    *ngIf="elementIsActive('de.saxms.protocol.simulation.step.FinalStep')"
    (dragstart)="setNodeType($event, 'de.saxms.protocol.simulation.step.FinalStep')"
  ></mat-icon>

  <mat-icon
    class="draggable-menu-node"
    svgIcon="node-network:nn-end2"
    draggable="true"
    *ngIf="elementIsActive('de.saxms.protocol.simulation.step.FlowFinalStep')"
    (dragstart)="setNodeType($event, 'de.saxms.protocol.simulation.step.FlowFinalStep')"
  ></mat-icon>
</div>
