import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'template-image',
  templateUrl: './template-image.component.html',
  styleUrls: ['./template-image.component.scss'],
})
export class TemplateImageComponent implements OnInit {
  @Input() src: string;
  constructor() {}

  ngOnInit() {}
}
