import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { TranslateModule } from '@ngx-translate/core';
import { NumericRangePickerComponent } from './numeric-range-picker.component';
import { OverlayComponent } from './overlay/overlay.component';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    TranslateModule,
    MatButtonModule,
    MatFormFieldModule,
    FormsModule,
  ],
  exports: [NumericRangePickerComponent],
  declarations: [NumericRangePickerComponent, OverlayComponent],
})
export class NumericRangePickerModule {}
