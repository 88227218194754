export enum ELinkType {
  START_AND_END_TIME = 'START_AND_END_TIME',
  SHIFT_TIME = 'SHIFT_TIME',
  MAX_DURATION_WARNING = 'MAX_DURATION_WARNING',
  /**
   * If the linked elements have a time set,
   * then the time of the start time should be shifted by the changed time span of the changed end time.
   * If the start time is changed, nothing should be adjusted.
   *
   * @example
   * // Element_A no value & Element_B 10.1.2024 16:00 => do nothing
   * // Element_A 10.1.2024 16:00 & Element_B no value => do nothing
   * // Element_A 10.1.2024 14:00 & Element_B 10.1.2024 15:00 => change Element_B to 10.1.2024 12:00 => you have to change Element_A 10.1.2024 14:00 to 10.1.2024 11:00
   */
  ADJUST_TIME_DIFFERENCE = 'ADJUST_TIME_DIFFERENCE',
}
