import { Injectable, Injector, Type } from '@angular/core';
import { TemplateAdapter } from '@app-modeleditor/utils/template-factory.service';
import { ButtonAdapter } from '../button/button-adapter.service';
import { Adapter } from '../../../core/adapter';
import { EntryElementFactory } from '../entry-collection/entry-factory.service';
import { Tile } from './tile';

@Injectable({
  providedIn: 'root',
})
export class TileButtonAdapter implements Adapter<Tile> {
  constructor(private _injector: Injector) {}

  adapt(item: any): Tile {
    throw new Error('Method not implemented.');
  }
  inherit?<T extends Tile>(type: Type<T>, item: any): T {
    const t: T = this._getValue(this._injector.get(ButtonAdapter).inherit<T>(type, item), item);
    return;
  }
  inheritFrom?<T extends Tile>(scope: TemplateAdapter, type: Type<T>, item: any): T {
    const t: T = this._getValue(this._injector.get(ButtonAdapter).inheritFrom<T>(scope, type, item), item);
    return t;
  }
  applyValues<T>(scope: TemplateAdapter, item: any, values: any): T {
    throw new Error('Method not implemented.');
  }

  private _getValue<T extends Tile>(data: T, item: any): T {
    data
      .setReceiverDetails(item.receiverDetails)
      .setHoverContent(item.hoverContent)
      .setIconDetails(item.iconDetails)
      .setAdditionalDetails(item.additionalDetails)
      .setDragAndDropEnabled(item.dragAndDropEnabled);
    data.setTags((item.tags || []).map((tag) => this._injector.get(EntryElementFactory).parseTemplateEntry(data, tag)));
    return data;
  }
}
