import * as d3 from 'd3';

/**
 * Calculator to get correct text sizes.
 * @keywords font, size, calculator, characters, char, text
 */
export class GanttFontSizeCalculator {
  private _characters: string[];
  private _sizeData: { [key: string]: number } = {};
  private _triplePointsWidth: number = null;
  private _initNode: SVGElement = null;
  private _fontSize: number = null;

  constructor() {
    this._characters = [
      'A',
      'a',
      'B',
      'b',
      'C',
      'c',
      'D',
      'd',
      'E',
      'e',
      'F',
      'f',
      'G',
      'g',
      'H',
      'h',
      'I',
      'i',
      'J',
      'j',
      'K',
      'k',
      'L',
      'l',
      'M',
      'm',
      'N',
      'n',
      'O',
      'o',
      'P',
      'p',
      'Q',
      'q',
      'R',
      'r',
      'S',
      's',
      'T',
      't',
      'U',
      'u',
      'V',
      'v',
      'W',
      'w',
      'X',
      'x',
      'Y',
      'y',
      'Z',
      'z',
      'ß',
      'Ä',
      'ä',
      'Ö',
      'ö',
      'Ü',
      'ü',
      '0',
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      '.',
      ',',
      ';',
      ':',
      '_',
      '-',
      "'",
      '"',
      '!',
      '§',
      '$',
      '%',
      '&',
      '/',
      '(',
      ')',
      '=',
      '?',
      '*',
      '+',
      '#',
      '€',
      '@',
      '<',
      '>',
      '…',
      '▸',
      '',
      '',
      ' ',
    ];
  }

  /**
   * Initialize font size calculator to calculate size of each character.
   * @keywords init, initial, font, size, characters, char, text
   * @param svgNode SVG DOM node.
   * @param fontSize font size of gantt text.
   */
  public init(svgNode: SVGElement, fontSize: number): void {
    this._fontSize = fontSize;

    this._initNode = svgNode;
    const textContainer = d3
      .select(svgNode)
      .append('text')
      .style('font-size', this._fontSize)
      .style('font-family', 'Roboto,Helvetica Neue,sans-serif')
      .style('font-weight', '400')
      .style('opacity', '0');

    for (let i = 0; i < this._characters.length; i++) {
      textContainer.text(this._characters[i]);
      this._sizeData[this._characters[i]] = textContainer.node().getBBox().width;
    }
    // add white space
    textContainer.text('0 0');
    this._sizeData[' '] = textContainer.node().getBBox().width - 2 * this._sizeData['0'];
    textContainer.text('...');
    this._triplePointsWidth = textContainer.node().getBBox().width;

    textContainer.remove();
  }

  /**
   * Calculates width of text.
   * Longer texts get less accurate.
   * // TODO: improve accuracy, keep performance in mind.
   * @keywords text, font, width, horizontal
   * @param text
   * @return {number} width of given text.
   */
  public getTextWidth(text: string): number {
    let size = 0;
    for (let i = 0; i < text.length; i++) {
      if (this._sizeData[text[i]]) {
        size += this._sizeData[text[i]];
      } else size += this._sizeData['M'] + this._sizeData['l'];
    }
    return size;
  }

  /**
   * Width of string "...". Used by indexCard PlugIn.
   * @returns {number}
   */
  public getTextWidthTriplePoints(): number {
    return this._triplePointsWidth;
  }

  public getFontSize(): number {
    return this._fontSize;
  }

  public updateFontSize(fontSize: number): void {
    this.deleteData();
    this.init(this._initNode, fontSize);
  }

  public deleteData(): void {
    this._sizeData = {};
  }

  public removeInitNode(): void {
    this._initNode = undefined;
  }
}
