import { EntryElement } from '../entry-collection/entry-element';

export class FileUploader extends EntryElement {
  private accept: string;

  public getAccept(): string {
    return this.accept;
  }

  public setAccept(accept: string): this {
    this.accept = accept;
    return this;
  }
}
