import { ColumnListSelectorComponent } from '@app-modeleditor/components/column-list-selector/column-list-selector.component';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'frontend/src/dashboard/view/template-toolbar/menu-item';
import { Toolbar } from 'frontend/src/dashboard/view/template-toolbar/toolbar';
import { ToolbarGroup } from 'frontend/src/dashboard/view/template-toolbar/toolbar-group';
import { IToolbarOptions } from 'frontend/src/dashboard/view/template-toolbar/toolbar-options.interface';
import { of } from 'rxjs';
import { Action } from '../button/action/action';
import { Button } from '../button/button';
import { EButtonDisplayType } from '../button/button-display-type.enum';
import { EntryElement } from '../entry-collection/entry-element';

export class ColumnListSelectorToolbar extends Toolbar {
  constructor(public scope: ColumnListSelectorComponent, options: IToolbarOptions) {
    super(options);
    this.setName(this.scope.data.getHeadline());
  }

  get(): ColumnListSelectorToolbar {
    this.setClass('saxms-submenu').disableShortcuts(false);
    const actioMenu: MenuItem = new MenuItem()
      .defaultGroup(this)
      .setName('LABEL.actions')
      .setToolbarGroups([new ToolbarGroup().setName('LABEL.actions').setEntryElements(this.getActions())]);
    this.setMenuItems([actioMenu]);

    this.setActiveMenuItem(actioMenu);
    return this;
  }

  private getActions(): EntryElement[] {
    // const resetIndices: Button = new Button().setDisplayType(EButtonDisplayType.ICON_WITH_LABEL_BELOW)
    //   .setName('BUTTON.reset').setIcon('cached').chainActions(
    //     new Action().setCb(() => of(this.scope.reset())));

    // const searchBox: TextField = new TextField().setName('Suche').setValue(new EntryElementValue().setValue(''));
    // searchBox.onKeyUp().subscribe((val: { value: EntryElementValue, event: KeyboardEvent }) => {
    //   this.scope.filter(val.value.getValue());
    // });

    const left: Button = new Button()
      .setDisplayType(EButtonDisplayType.ICON_WITH_LABEL_BELOW)
      .setName('BUTTON.move_left')
      .setIcon('keyboard_arrow_left')
      .chainActions(
        new Action().setCb(() => {
          this.scope.moveLeft();
          this.scope.saveChanges();
          return of(null);
        })
      )
      .setEnableBy(
        () =>
          this.scope.selectedElements &&
          this.scope.selectedElements.length > 0 &&
          !this.scope.selectedElementIsInLeftList(this.scope.selectedElements[0])
      );

    const right: Button = new Button()
      .setDisplayType(EButtonDisplayType.ICON_WITH_LABEL_BELOW)
      .setName('BUTTON.move_right')
      .setIcon('keyboard_arrow_right')
      .chainActions(
        new Action().setCb(() => {
          this.scope.moveRight();
          this.scope.saveChanges();
          return of(null);
        })
      )
      .setEnableBy(
        () =>
          this.scope.selectedElements &&
          this.scope.selectedElements.length > 0 &&
          this.scope.selectedElementIsInLeftList(this.scope.selectedElements[0])
      );

    return [left, right];
  }
}
