import { Action } from '@app-modeleditor/components/button/action/action';
import { Button } from '@app-modeleditor/components/button/button';
import { EButtonDisplayType } from '@app-modeleditor/components/button/button-display-type.enum';
import { EntryElement } from '@app-modeleditor/components/entry-collection/entry-element';
import { EntryElementValue } from '@app-modeleditor/components/entry-collection/entry-element-value';
import { EFieldType } from '@app-modeleditor/components/entry-collection/field-type.enum';
import { FilterNodeViewer } from '@app-modeleditor/components/filter-node-viewer/filter-node-viewer';
import { IFilterNodeViewerInputData } from '@app-modeleditor/components/filter-node-viewer/filter-node-viewer-input-data.interface';
import { GlobalUtils } from 'frontend/src/dashboard/global-utils';
import { MenuItem } from 'frontend/src/dashboard/view/template-toolbar/menu-item';
import { ToolbarGroup } from 'frontend/src/dashboard/view/template-toolbar/toolbar-group';
import { of } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Gantt_General } from '../../general.gantt.component';
import { GanttEssentialPlugIns } from '../../plugin/e-gantt-essential-plugins';
import { GanttBlockFilterByConditionPlugIn } from '../../plugin/plugin-list/block-filter-by-condition/block-filter-by-condition';
import { IGanttBlockAttributeData } from '../filter/lightbox/attribute-data.interface';
import { EditFilterLightbox } from '../filter/lightbox/edit-filter-lightbox';
import { FilterManagementLightbox } from '../filter/lightbox/filter-management-lightbox';
import { IFilterNode } from '../filter/lightbox/filter-tree';
import { FrontendFilter } from '../filter/lightbox/frontend-filter';
import { GanttToolbar } from '../gantt-toolbar';

export class ConditionFilterMenuItem {
  private conditionFilterMenuItem: MenuItem = null;
  private filters: FrontendFilter[];
  private lastActiveFilter: FrontendFilter;
  private id: string = GlobalUtils.generateUUID();

  private _isNodeViewerActive = false;

  private _quickFilterToolbarGroup: ToolbarGroup = new ToolbarGroup()
    .setName('@fastFilter@')
    .setEntryElements([])
    .setId('quickFilterToolbarGroup');
  private _filterSelectionToolbarGroup: ToolbarGroup = new ToolbarGroup()
    .setName('@filterSelection@')
    .setId('filterSelectionToolbarGroup');
  private _filterSettingsToolbarGroup: ToolbarGroup = new ToolbarGroup()
    .setName('@filterSettings@')
    .setId('filterSettingsToolbarGroup');
  private _currentFilterToolbarGroup: ToolbarGroup = new ToolbarGroup()
    .setName('@currentFilter@')
    .setId('currentFilterToolbarGroup');

  private _quickFilterButtons: Button[] = [];
  private _filterNodeViewer = new FilterNodeViewer().setFieldType(EFieldType.FILTER_NODE_VIEWER);

  constructor(private ganttScope: Gantt_General, private toolbarScope: GanttToolbar) {
    this.conditionFilterMenuItem = new MenuItem()
      .defaultGroup(this.toolbarScope)
      .setName('BUTTON.filter')
      .setId('gantt-general-filter');
    this.getGeneralEntryElements();
    this._filterNodeViewer.onChanges((_) => {
      this.createFilter(this.lastActiveFilter);
    });
  }

  public getMenuItem(): MenuItem {
    return this.conditionFilterMenuItem;
  }

  private getGeneralEntryElements() {
    const a2: Button = new Button()
      .setName('@manageFilter@')
      .setAlwaysEnabled(true)
      .setId(`manage-filter-${this.ganttScope.ganttTemplateDataService.getTemplateData().getGanttUniqueId()}`)
      .setDisplayType(EButtonDisplayType.ICON_WITH_LABEL_BELOW)
      .setIcon('filter_alt')
      .chainActions(
        new Action().setCb(() => {
          const l: FilterManagementLightbox = new FilterManagementLightbox(this.ganttScope);
          return this.ganttScope.lightboxApi.open(l).afterClosed();
        })
      );

    // toggle last active filter
    const currentFilter: EntryElement = new EntryElement()
      .setEnableBy(() => (this.lastActiveFilter ? true : false))
      .setFieldType(EFieldType.SLIDE_TOGGLE)
      .setName('@filterDeactivated@')
      .setValue(new EntryElementValue().setValue(true))
      .setId(`filter-active-slider-${this.ganttScope.ganttTemplateDataService.getTemplateData().getGanttUniqueId()}`)
      .onChanges((e: EntryElementValue) => {
        this.setFilter(e.getValue() ? this.lastActiveFilter : null);
      });

    // select current active filter
    const select: EntryElement = new EntryElement()
      .setId(`filter-selection-${this.ganttScope.ganttTemplateDataService.getTemplateData().getGanttUniqueId()}`)
      .setFieldType(EFieldType.COMBO_BOX)
      .setName('@filterSelection@')
      .setValue(new EntryElementValue())
      .onChanges((e: EntryElementValue) => {
        if (!e.getValue<EntryElementValue>()) {
          return;
        }
        const m: FrontendFilter = (this.filters || []).find(
          (f: FrontendFilter) => f.getId() === e.getValue<EntryElementValue>().getValue()
        );
        if (m.getId() !== 'null-filter-frontend') {
          this.setFilter(m);
          this.setDateToNodeViewer(m.getNode());
        } else {
          this.setFilter(null);
          this.setDateToNodeViewer(null);
          this.lastActiveFilter = null;
        }
      });

    this._filterSelectionToolbarGroup.setEntryElements([a2, select]);

    // catch changes to frontend filtering
    this.ganttScope.ganttFrontendFilterService
      .onFilterChanged()
      .pipe(takeUntil(this.ganttScope.onDestroy))
      .subscribe((f: FrontendFilter[]) => {
        const defaultFilter = new FrontendFilter().setId('null-filter-frontend').setName('---').setValue('---');
        const filters = [defaultFilter].concat(f);
        this.filters = filters;

        // update dropdown selection
        select.getValue<EntryElementValue>().setAvailableValues(
          (filters || []).map((item: FrontendFilter) => {
            return new EntryElementValue().setName(item.getValue()).setValue(item.getId());
          })
        );

        // update quickfilter buttons
        this._quickFilterButtons = (filters || [])
          .filter((item: FrontendFilter) => item.isQuickfilter())
          .map((item: FrontendFilter) => {
            return new Button()
              .setName(`${item.getValue()}`)
              .setId(
                `quickfilter-filter-${item.getId()}-${this.ganttScope.ganttTemplateDataService
                  .getTemplateData()
                  .getGanttUniqueId()}`
              )
              .setIcon('filter_b_and_w')
              .setDisplayType(EButtonDisplayType.ICON_WITH_LABEL_BELOW)
              .chainActions(
                new Action().setCb(() => {
                  this.setFilter(item);
                  return of(null);
                })
              );
          });

        const lastFilterValue = this.lastActiveFilter?.getNode() ? this.lastActiveFilter.getNode() : null;
        this.setDateToNodeViewer(lastFilterValue);

        this._quickFilterToolbarGroup.setEntryElements(this._quickFilterButtons);
        this._handleFilterGroupView();
      });

    this.ganttScope.ganttFrontendFilterService
      .getCurrentActiveFilter()
      .pipe(takeUntil(this.ganttScope.onDestroy))
      .subscribe((f: FrontendFilter) => {
        this.lastActiveFilter = f || this.lastActiveFilter;
        // update select with current active filter
        const m: EntryElementValue = f
          ? select
              .getValue<EntryElementValue>()
              .getAvailableValues<EntryElementValue[]>()
              .find((e: EntryElementValue) => e.getValue() === f.getId())
          : null;
        select.getValue<EntryElementValue>().setValue(m);

        const ganttFilter: GanttBlockFilterByConditionPlugIn =
          this.ganttScope.ganttPluginHandlerService.getEssentialPlugIn(
            GanttEssentialPlugIns.GanttBlockFilterByConditionPlugIn
          );

        if (ganttFilter && !f) {
          ganttFilter.clearFilter();
          this.setDateToNodeViewer(null);
          this._isNodeViewerActive = false;
        }

        if (ganttFilter && f) {
          ganttFilter.clearFilter();
          ganttFilter.filterByCondition(
            f.getNode(),
            f.isCaseSensitive(),
            f.getFilteredOutElementDisplayOption(),
            f.isHideEmptyRows()
          );
          this.setDateToNodeViewer(f.getNode());
          this._isNodeViewerActive = true;
        }

        // update slide toggle
        currentFilter.getValue<EntryElementValue>().setValue(f ? true : false);
        currentFilter.setName(
          currentFilter.getValue<EntryElementValue>().getValue() === true ? '@filterActivated@' : '@filterDeactivated@'
        );
        this._handleFilterGroupView();
      });

    this._filterSettingsToolbarGroup.setEntryElements([currentFilter]);
    this._currentFilterToolbarGroup.setEntryElements([this._filterNodeViewer]);
    this._handleFilterGroupView();
  }

  private setDateToNodeViewer(node: IFilterNode) {
    const mapping = this.ganttScope.ganttTemplateDataService.getTemplateData().getAttributeMapping() || {};
    const attributeData: IGanttBlockAttributeData[] = Object.keys(mapping).map((index: string) => {
      return {
        name: mapping[index].localization,
        id: mapping[index].id,
        type: mapping[index].dataType,
      };
    });
    const nodeViewerInputData: IFilterNodeViewerInputData = {
      node: node,
      attributeData: attributeData,
    };
    this._filterNodeViewer.updateViewerData(nodeViewerInputData);
  }

  /**
   * Sets the given filter as new current filter and handles the menu badge.
   * @param frontendFilter Filter to set
   */
  public setFilter(frontendFilter: FrontendFilter): void {
    this.ganttScope.ganttFrontendFilterService.setCurrentActiveFilter(frontendFilter);
    if (frontendFilter) {
      this.conditionFilterMenuItem.registerIndicator(this.id);
    } else {
      this.conditionFilterMenuItem.deregisterIndicator(this.id);
    }
  }

  /**
   * Handles which toolbar groups are displayed in toolbar.
   */
  private _handleFilterGroupView() {
    if (!this.conditionFilterMenuItem) return;

    const filterGroup: ToolbarGroup[] = [];
    if (this._quickFilterButtons.length) {
      filterGroup.push(this._quickFilterToolbarGroup);
    }
    filterGroup.push(this._filterSelectionToolbarGroup);
    filterGroup.push(this._filterSettingsToolbarGroup);
    filterGroup.push(this._currentFilterToolbarGroup);

    this.conditionFilterMenuItem.setToolbarGroups(filterGroup);
  }

  private createFilter(f?: FrontendFilter): void {
    const l: EditFilterLightbox = new EditFilterLightbox(this.ganttScope);
    if (f) {
      l.loadFilter(f);
    }
    this.ganttScope.lightboxApi.open(l);
  }
}
