import { updateItem, insertItem, patch, iif } from '@ngxs/store/operators';
import { ResourceStatus } from '../../template/data-access/template.state';

export const insertOrUpdateContract = (id: string, item?: ResourceStatus) => {
  return iif<ResourceStatus[]>(
    (items) => items.some((contract) => contract.id === id),
    updateItem((contract) => contract.id === id, patch(item)),
    insertItem(item)
  );
};
