/**
 * Enum defining all possible sources of pattern events.
 */
export enum EPatternEventSource {
  /**
   * This value represents the method {@link PatternHandler.getPatternAsUrl()} as event source.
   */
  GET_PATTERN_AS_URL = 'getPatternAsUrl',
  /**
   * This value represents the method {@link PatternHandler.getPatternAsSvgImage()} as event source.
   */
  GET_PATTERN_AS_SVG_IMAGE = 'getPatternAsSvgImage',
}
