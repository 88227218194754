import { NodeNetworkDiagramService } from '@app-nodenetwork/service/node-network.diagram.service';
import { millisecondsToDate } from '../../model-editor/ui/output-formatter/date.util';

declare let DiagramWrapper: any;

/**
 * Example diagram handling for line cahrt inside dialog of nodes.
 */
export class DiagramHandler {
  public builder: any = new DiagramWrapper();

  constructor(public diagramService: NodeNetworkDiagramService) {}

  /**
   * Builds linechart at given position by backend data.
   * @param xPos Vertical position.
   * @param yPos Horizontal position.
   * @param parentSVG SVG node in which the diagram will be added.
   * @param nodeId Id of node which will be used to call backend data.
   */
  public buildQueueDiagram(xPos: number, yPos: number, parentSVG: any, nodeId: string): void {
    this.diagramService.getQueueDiagram(nodeId).subscribe((result) => {
      if (!result || !result.data || !result.data[0] || !result.data[0].values[0]) return;

      result.minValueY = result.data[0].values[0].value[1];

      for (const valueItem of result.data) {
        for (const value of valueItem.values) {
          value.value[0] = millisecondsToDate(value.value[0], true);

          result.maxValueY = Math.max(value.value[1], result.maxValueY);
          result.minValueY = Math.min(value.value[1], result.minValueY);
        }
      }

      result.minValueX = result.data[0].values[0].value[0];
      result.maxValueX = result.data[0].values[result.data[0].values.length - 1].value[0];

      this.builder.buildLineChart([xPos, yPos], parentSVG, result);
    });
  }
}
