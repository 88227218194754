import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NodeNetworkType } from '@app-nodenetwork/data/node-network-type.enum';
import { ViewOptions } from '@app-nodenetwork/data/view-options';
import { NodeNetworkUploadObject } from '@app-nodenetwork/service/node-network-upload-object';
import { Observable } from 'rxjs';

@Injectable()
export class NodeNetworkService {
  public uri = 'rest/visualization';

  constructor(public http: HttpClient) {}

  public typeToUri(type: NodeNetworkType): string {
    switch (type) {
      case NodeNetworkType.MACHINE:
        return 'machineView';
      case NodeNetworkType.MACHINECOMPLETE:
        return 'completeMachineView';
      case NodeNetworkType.PRODUCT:
        return 'productView';
    }

    // default
    return 'productView';
  }

  /**
   *
   * @param body: content of sysml-file as String
   */
  public uploadSysmlFile(modelId: string, body: NodeNetworkUploadObject): Observable<any> {
    return this.http.post(`${this.uri}/upload/${modelId}`, body);
  }

  /**
   * all networks
   * @param id
   */
  public getAllModelViews(modelId: string, type: NodeNetworkType): Observable<any> {
    const typeUri = this.typeToUri(type);

    return this.http.get(`` + this.uri + '/' + typeUri + '/' + modelId + '/list');
  }

  /**
   * one network-view with different filter-options
   * @param id
   */
  public getView(modelId: string, id: string, body: ViewOptions, type: NodeNetworkType): Observable<any> {
    const typeUri = this.typeToUri(type);

    return this.http.post(`` + this.uri + '/' + typeUri + '/' + modelId + '/' + id, body);
  }

  /**
   * get list to create tree-menu with filtering
   */
  public getAttributes(modelId: string, id: string, body: ViewOptions, type: NodeNetworkType): Observable<any> {
    const typeUri = this.typeToUri(type);
    return this.http.post(`` + this.uri + '/' + typeUri + '/' + modelId + '/' + id + '/attributes', body);
  }

  /**
   * get list to create tree-menu with filtering
   */
  public getWorkflows(modelId: string, id: string, body: ViewOptions, type: NodeNetworkType): Observable<any> {
    const typeUri = this.typeToUri(type);

    return this.http.post(`` + this.uri + '/' + typeUri + '/' + modelId + '/' + id + '/workflows', body);
  }

  public getNodeNetOptions(): Observable<any> {
    return this.http.get(`` + this.uri + '/options');
  }

  public mapBMWDummyData(dataSet: any): any {
    const mappedData: any = {
      scale: dataSet.scale,
      width: dataSet.width,
      height: dataSet.height,
      productNodeVisualizations: [],
      productConnectionVisualizations: [],
      subgraphs: dataSet.subgraphs,
    };

    // fill productNodeVisualizations
    for (const key in dataSet.nodes) {
      const mappedNode = dataSet.nodes[key];
      mappedNode.nodeID = key;

      mappedData.productNodeVisualizations.push(mappedNode);
    }

    // fill productConnectionVisualizations
    for (const key in dataSet.connections) {
      const connection = dataSet.connections[key];
      connection.connectionID = key;
      mappedData.productConnectionVisualizations.push(connection);
    }
    return mappedData;
  }
}
