import { IGanttShiftTranslationEvent } from '@gantt/lib/best_gantt/script/edit-shifts/shift-translation/translation-events/translation-event.interface';
import { BestGantt, EGanttInstance, GanttCanvasShift, GanttDataRow, GanttDataShift } from '@gantt/public-api';
import { GanttOverlappingShiftsPlugIn } from 'frontend/src/dashboard/gantt/general/plugin/plugin-list/overlapping-shifts/overlapping-shifts';
import { finalize, takeUntil } from 'rxjs';
import { GanttEssentialPlugIns } from '../../../plugin/e-gantt-essential-plugins';
import { GanttSuperBlocksPlugIn } from '../../../plugin/plugin-list/superblocks/superblocks.plugin';
import { IGanttResponse } from '../../../response/gantt-response';
import { GanttActionWrapper } from '../../external-action-registration';
import { GanttEventTrigger } from '../../external-event-trigger';
import { EGanttActionEvent } from '../gantt-action-event.enum';

interface IBlockDragData {
  blockStartTime: number;
  blockId: string;
  originResourceId: string;
}

/**
 * Event which will be fired on drag end of a shift block.
 */
export class GanttDragEndEvent extends GanttEventTrigger {
  public readonly eventName: EGanttActionEvent = EGanttActionEvent.DRAG_END;
  public _draggedShiftDataOnStart: IBlockDragData[] = [];
  public _draggedRowIdOnStart: string = null;

  /**
   * Registers addTranslationEndCallback-callback inside given gantt.
   */
  public registerInGantt(): void {
    const ganttDiagram = this._ganttLibService.bestGantt;

    ganttDiagram
      .getShiftTranslator()
      .onShiftEditStart()
      .pipe(takeUntil(this.onRemoveFromGantt))
      .subscribe((event) => this._storeShiftDataBeforeDrag(event.target.data()[0], ganttDiagram));

    ganttDiagram
      .getShiftTranslator()
      .onShiftEditEnd()
      .pipe(takeUntil(this.onRemoveFromGantt))
      .subscribe((event) => this.handleTranslationEnd(event, ganttDiagram));
  }

  private handleTranslationEnd(dragEndParam: IGanttShiftTranslationEvent, ganttDiagram) {
    const earlyReturn = this.earlyReturnDragEndCallback(dragEndParam, ganttDiagram);
    if (earlyReturn) {
      return;
    }
    const foundShiftData = this._ganttLibService.ganttInstanceService
      .getInstance(EGanttInstance.SHIFT_DATA_FINDER)
      .getShiftById(ganttDiagram.getDataHandler().getOriginDataset().ganttEntries, dragEndParam.target.data()[0].id);

    const movedShiftIds = this.getMovedShiftIds(ganttDiagram);
    if (!movedShiftIds.length) {
      // dataset has not been changed -> update splitted rows and return
      const overlappingShiftUpdater: GanttOverlappingShiftsPlugIn = this._ganttPluginHandlerService.getEssentialPlugIn(
        GanttEssentialPlugIns.OverlappingShiftsPlugIn
      );
      overlappingShiftUpdater.resetSplitOverlappingShifts(false);
      overlappingShiftUpdater.splitOverlappingShifts(true);
      return;
    }

    const selectedShifts = this.saveDataToTemplate(dragEndParam, foundShiftData?.shift, movedShiftIds, ganttDiagram);

    const actionWrapper: GanttActionWrapper = {
      actionScope: this.action.actionScope,
      actions: this.action.actions,
      editedBlockIds: selectedShifts.map((shift) => shift.id),
    };

    this._ganttLibService.ngZone.run(() => {
      this.actionHandler
        .executeActions(actionWrapper)
        .pipe(finalize(() => this.responseHandler.handleShiftSelection()))
        .subscribe((data: IGanttResponse | unknown) => {
          if (!data) return;

          try {
            // prevent shift selection check -> will be done after all actions are executed
            // several actions could want to access selected values
            (data as IGanttResponse).preventShiftSelectionCheck = true;
          } catch (e) {
            console.error('Can not set preventShiftSelectionCheck flag!', data);
          }

          this.responseHandler.handleUpdateNotification(data as IGanttResponse);
        });
    });
  }

  /**
   * Capsules early return logic for dragEndReturn Callback.
   * @param dragEndParam Event data from the drag end event.
   * @param ganttDiagram Reference to the gantt diagram from where the event data originates.
   * @return True if return early, false if continue.
   */
  private earlyReturnDragEndCallback(dragEndParam: IGanttShiftTranslationEvent, ganttDiagram: BestGantt): boolean {
    if (
      !dragEndParam.hasBeenDragged ||
      (ganttDiagram.getShiftTranslator().shiftEditLimiterAdapter.allowDragHorizontal == false &&
        ganttDiagram.getShiftTranslator().shiftEditLimiterAdapter.allowDragVertical == false)
    ) {
      return true;
    }
    if (dragEndParam.hasBeenBlocked) {
      return true;
    }
    const foundShiftData = this._ganttLibService.ganttInstanceService
      .getInstance(EGanttInstance.SHIFT_DATA_FINDER)
      .getShiftById(ganttDiagram.getDataHandler().getOriginDataset().ganttEntries, dragEndParam.target.data()[0].id);
    if (!foundShiftData?.shift) {
      return true;
    }

    // check if action will be executed by correct target row
    if (
      this.action.targetRowIDs &&
      this.action.targetRowIDs.indexOf(foundShiftData.shiftRow.additionalData.originalRowId) == -1
    ) {
      return true;
    }
    if (
      this.action.forBlockType &&
      foundShiftData.shift.additionalData.blockTypes &&
      !this.shiftHasBlocktype(foundShiftData.shift.additionalData.blockTypes, this.action.forBlockType)
    ) {
      return true;
    }
    return false;
  }

  /**
   * Removes all callbacks from gantt.
   */
  public removeFromGantt(): void {
    super.removeFromGantt();
  }

  /**
   * Saves affected block and start and end row.
   * @param callback Event data.
   * @param directlyDraggedShift Shift block directly dragged by mouse.
   * @param movedShiftIds All shift blocks which are moved.
   * @param ganttDiagram BestGantt instance to get selected shift (for multidrag).
   */
  public saveDataToTemplate(
    callback: IGanttShiftTranslationEvent,
    directlyDraggedShift: GanttDataShift,
    movedShiftIds: string[],
    ganttDiagram: BestGantt
  ): GanttDataShift[] {
    const movedDataShifts: { shift: GanttDataShift; shiftRow: GanttDataRow }[] =
      this._ganttLibService.ganttInstanceService
        .getInstance(EGanttInstance.SHIFT_DATA_FINDER)
        .getShiftsByIds(ganttDiagram.getDataHandler().getOriginDataset().ganttEntries, movedShiftIds);

    let selectedShifts: GanttDataShift[] = movedDataShifts.map((shift) => shift.shift);
    selectedShifts = this.filterDragRestrictedShiftsOut(selectedShifts, ganttDiagram);

    // all dragged shifts
    // set to false instead of null to crash buttonService and disable menu buttons
    this.templateData.setSelectedValues(
      selectedShifts.length ? selectedShifts : false,
      this._ganttLibService.backendToGanttOriginInputMapper
    );

    // move origin and target

    // extract row data
    const foundStartRowDataWrapper = this._ganttLibService.ganttInstanceService
      .getInstance(EGanttInstance.Y_AXIS_DATA_FINDER)
      .getRowById(ganttDiagram.getDataHandler().getOriginDataset().ganttEntries, callback.dragStartRowId);
    const foundEndRowDataWrapper = this._ganttLibService.ganttInstanceService
      .getInstance(EGanttInstance.Y_AXIS_DATA_FINDER)
      .getRowById(ganttDiagram.getDataHandler().getOriginDataset().ganttEntries, callback.dragEndRowId);

    // save data to template
    this.templateData.setOriginRow(foundStartRowDataWrapper.data.additionalData.originalRowId);
    this.templateData.setTargetRow(foundEndRowDataWrapper.data.additionalData.originalRowId);
    this.templateData.setSelectedBlock(
      {
        id: callback.target.data()[0].id,
        start: new Date(directlyDraggedShift.timePointStart).getTime(),
        end: new Date(directlyDraggedShift.timePointEnd).getTime(),
      },
      this._ganttLibService.backendToGanttOriginInputMapper
    );

    if (directlyDraggedShift.additionalData && directlyDraggedShift.additionalData.superBlockData) {
      const superBlocks = {};
      for (const superBlockTypeKey in directlyDraggedShift.additionalData.superBlockData) {
        // FIXME: This code/refactoring may be broken, couldnt test it yet.
        superBlocks[superBlockTypeKey] = directlyDraggedShift.additionalData.superBlockData[superBlockTypeKey];
        // this.templateData.selectedBlock["superBlocks[" + superBlockTypeKey + "]"] = shiftData.additionalData.superBlockData[superBlockTypeKey];
      }
      this.templateData.setSuperBlocks(superBlocks);
    }

    return selectedShifts;
  }

  /**
   * Filters out drag restricted shifts.
   * @param selectedShifts Array of shifts to check their restrictions.
   * @param ganttDiagram Reference to the gantt diagram providing the restriction data.
   */
  private filterDragRestrictedShiftsOut(selectedShifts: GanttDataShift[], ganttDiagram: BestGantt): GanttDataShift[] {
    const dragLimiters = ganttDiagram.getShiftEditLimiter().getRegisteredDragLimiterPlugIns();
    const dragRestrictedShiftsFromDragLimiters = [];
    for (const pluginID in dragLimiters) {
      const restrictions = dragLimiters[pluginID].getRestrictions();
      for (const shiftID in restrictions) {
        dragRestrictedShiftsFromDragLimiters.push(shiftID);
      }
    }

    const restrictedShifts = new Set(dragRestrictedShiftsFromDragLimiters);
    selectedShifts = selectedShifts.filter((shift) => !restrictedShifts.has(shift.id) && !shift.disableMove);
    return selectedShifts;
  }

  /**
   * Checks if shift has matching block type to pay attention to superblocks handling.
   * @param blockTypes Block types of shift block to check.
   * @param superBlockType Super block type to check.
   */
  private shiftHasBlocktype(blockTypes: number[], superBlockType: string): boolean {
    const superBlocksPlugIn: GanttSuperBlocksPlugIn = this._ganttPluginHandlerService.getEssentialPlugIn(
      GanttEssentialPlugIns.SuperBlocksPlugIn
    );
    if (!superBlocksPlugIn) return false;
    const superBlockTypeNumber = superBlocksPlugIn.getTypeNumberByType(superBlockType);
    if (superBlockTypeNumber == null) return false;
    return blockTypes.indexOf(superBlockTypeNumber) != -1;
  }

  /**
   * Stores a copy of block data to compare it with shift data on drag end.
   * Used for evaluation of shift movement detection by user.
   */
  private _storeShiftDataBeforeDrag(draggedShift: GanttCanvasShift, ganttDiagram: any) {
    const draggedShiftIds: string[] = (
      ganttDiagram.getSelectionBoxFacade().getSelectedShifts().length
        ? ganttDiagram.getSelectionBoxFacade().getSelectedShifts()
        : [draggedShift]
    ).map((shift: GanttCanvasShift) => shift.id);
    this._draggedShiftDataOnStart = JSON.parse(
      JSON.stringify(this.getBlockDragDataByShiftIds(draggedShiftIds, ganttDiagram))
    );
  }

  /**
   * Generates block drag data for given shift ids.
   * @param draggedShiftIds Ids of shifts to get drag data for.
   * @param ganttDiagram js gantt reference.
   */
  private getBlockDragDataByShiftIds(draggedShiftIds: string[], ganttDiagram: any): IBlockDragData[] {
    const searchResult: { shift: GanttDataShift; shiftRow: GanttDataRow }[] = this._ganttLibService.ganttInstanceService
      .getInstance(EGanttInstance.SHIFT_DATA_FINDER)
      .getShiftsByIds(ganttDiagram.getDataHandler().getOriginDataset().ganttEntries, draggedShiftIds);
    return searchResult.map((elem) => {
      return {
        blockId: elem.shift.id,
        blockStartTime: elem.shift.timePointStart.getTime(),
        originResourceId: elem.shiftRow.originalResource || elem.shiftRow.id,
      };
    });
  }

  /**
   * Checks if shift was moved to another position by user.
   * Returns an array with the IDs of the moved shifts.
   */
  private getMovedShiftIds(ganttDiagram: any): string[] {
    const movedShiftIds = [];
    if (!this._draggedShiftDataOnStart.length) {
      return movedShiftIds;
    }

    const draggedShiftIds = this._draggedShiftDataOnStart.map((elem) => elem.blockId);
    const newBlockDragData = this.getBlockDragDataByShiftIds(draggedShiftIds, ganttDiagram);

    newBlockDragData.forEach((newBlock) => {
      const oldBlock = this._draggedShiftDataOnStart.find((oldBlock) => oldBlock.blockId === newBlock.blockId);
      if (
        oldBlock.blockStartTime !== newBlock.blockStartTime ||
        oldBlock.originResourceId !== newBlock.originResourceId
      ) {
        movedShiftIds.push(newBlock.blockId);
      }
    });

    return movedShiftIds;
  }
}
