import { CdkDragDrop, CdkDragStart, CdkDropList, DragDrop, DragRef } from '@angular/cdk/drag-drop';
import {
  Component,
  HostBinding,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { TemplateService } from '@app-modeleditor/utils/template.service';
import { TranslateService } from '@ngx-translate/core';
import { GlobalUtils } from 'frontend/src/dashboard/global-utils';
import { Template } from 'frontend/src/dashboard/model/resource/template';
import { ETemplateType } from 'frontend/src/dashboard/model/resource/template-type';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { Action } from '../button/action/action';
import { EntryCollection } from '../entry-collection/entry-collection';
import { ButtonService } from './../button/button.service';
import { HierarchicalMenuItem } from './../menu-item/hierarchical-menu-item';
import { DragNDropService } from './drag-n-drop.service';
@Component({
  selector: 'drag-n-drop',
  templateUrl: './drag-n-drop.component.html',
  styleUrls: ['./drag-n-drop.component.scss'],
})
export class DragNDropComponent implements OnDestroy, OnInit, OnChanges {
  @Input() template: Template;
  @Input() disabled: boolean;
  @HostBinding('class.enabled') isEnabled = false;
  @ViewChild(CdkDropList) dropListContainer: CdkDropList;
  @ViewChild('draggableItem') set ref(draggableItem: HTMLElement) {
    if (this.dragReference || !(this.template instanceof Template) || !this.template.getDropZoneActions()) {
      return;
    }

    this.dragReference = this.dragDrop.createDrag(draggableItem);
    this.dragReference.disabled = !this.draggable || this.disabled;
    this.dragReference.beforeStarted.pipe(takeUntil(this.ngUnsubscribe)).subscribe((a) => {
      this.dragNDropApi.setDragSource(this.template);
      this.dropListContainer.connectedTo = this.connections = Object.keys(this.template.getDropZoneActions()).concat(
        this.id
      );
    });
  }
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  private componentId = GlobalUtils.generateUUID();
  private dragReference: DragRef<any>;
  protected active: boolean;
  protected dragPreviewLabel: string;

  constructor(
    private dragNDropApi: DragNDropService,
    private actionApi: ButtonService,
    private dragDrop: DragDrop,
    private templateService: TemplateService,
    private translateService: TranslateService,
    private $zone: NgZone
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.disabled || changes.template) && this.dragReference) {
      this.dragReference.disabled = !this.draggable || this.disabled;
    }
  }

  start(drag: CdkDragStart): void {
    this.dragNDropApi.setDragSource(this.template);
    this.dropListContainer.connectedTo = this.connections = Object.keys(this.template.getDropZoneActions()).concat(
      this.id
    );

    this.handleDragPreviewLabel();
  }

  connections: string[] = [];
  isDropzone: boolean;

  get id(): string {
    return this.template instanceof Template ? this.template.getId() : null;
  }

  setDropzone(dropzone: boolean): void {
    if (this.isDropzone === dropzone) {
      return;
    }
    this.$zone.run(() => {
      this.isDropzone = dropzone;
      this.isEnabled = dropzone;
      if (dropzone) {
        this.dragNDropApi.registerDropList(this.componentId, this.dropListContainer, this.template);
      } else {
        this.dragNDropApi.unRegisterDropList(this.componentId);
      }
      this.isEnabled = dropzone;
    });
  }

  setActive(active: boolean): void {
    if (this.active === active) {
      return;
    }
    this.$zone.run(() => {
      this.active = active;
    });
  }

  ngOnInit(): void {
    if (!(this.template instanceof Template)) {
      return;
    }

    this.$zone.runOutsideAngular(() => {
      this.dragNDropApi
        .getDragSource()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((source: Template) => {
          const keys: string[] = source ? Object.keys(source.getDropZoneActions()) : [];
          this.setDropzone(keys.find((key: string) => key === this.id) ? true : false);

          this.connections = this.isDropzone ? [source.getId(), this.id] : [];
          // this.isDropzone ? Object.keys(source.getDropZoneActions()) : [];

          if (this.dropListContainer) {
            this.dropListContainer.connectedTo = this.connections;
          }
        });

      this.dragNDropApi
        .getCurrentDragTarget()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((target: Template) => {
          this.setActive(
            target && this.template instanceof Template && this.template.getId() === target.getId() ? true : false
          );
        });
    });

    // part for drag sourcesource
    if (!this.template.getDropZoneActions()) {
      return;
    }
  }

  enter(ev: CdkDragDrop<HierarchicalMenuItem>): void {}

  move(ev: any): void {
    this.$zone.runOutsideAngular(() => {
      this.dragNDropApi.isOverContainer(ev);
    });
  }

  release(start?: CdkDragDrop<HierarchicalMenuItem>): void {
    this.dragNDropApi.setDragSource(null);
    this.dragNDropApi.setCurrentDragTarget(null);
  }

  drop(ev: CdkDragDrop<HierarchicalMenuItem>): void {
    if (ev.isPointerOverContainer === false) {
      return;
    }

    const activelyDraggedElement: Template = ev.previousContainer.data[0] || ev.previousContainer.data;
    const selectedTemplates = this.getSelectedTemplates(activelyDraggedElement);
    const allDraggedTemplates = [activelyDraggedElement, ...selectedTemplates];
    const dropZoneActions = this.getDropZoneActions(allDraggedTemplates);

    if (this.template.getId() === activelyDraggedElement.getId() || !dropZoneActions.length) {
      return;
    }

    this.actionApi
      .executeActions(dropZoneActions)
      .pipe(
        finalize(() => {
          this.dragNDropApi.setCurrentDragTarget(null);
        })
      )
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe();
  }

  get draggable(): boolean {
    return this.template instanceof Template && this.template.getDropZoneActions() ? true : false;
  }

  /**
   * Updates the drag preview label based on the selected templates.
   * If more than one template is selected, the label will show the number of selected templates.
   * Otherwise, the label will show the name of the selected template.
   */
  private handleDragPreviewLabel(): void {
    const selectedTemplates = this.getSelectedTemplates(this.template).length + 1;
    this.dragPreviewLabel =
      selectedTemplates > 1
        ? this.translateService.instant('multi-elements', { value: selectedTemplates })
        : this.template.getName();
  }

  /**
   * Returns an array of selected templates based on the provided dragged element.
   * Refers only to the EntryCollection of the dragged element.
   * @param draggedElement The template that was dragged.
   * @returns An array of selected templates (excluding the dragged element).
   */
  private getSelectedTemplates(draggedElement: Template): Template[] {
    let selectedTemplates: Template[] = [];

    const entryCollection = this.templateService.getTemplates().find((template) => {
      // find entry collections
      if (template.getType() === ETemplateType.ENTRY_COLLECTION) {
        const entryElements = (template as EntryCollection).getEntryElements
          ? (template as EntryCollection).getEntryElements()
          : [] || [];

        // find entries
        return !!entryElements.find((entry) => entry.getId() === draggedElement?.getId());
      }
    }) as EntryCollection;

    if (entryCollection?.selectedEntries?.length) {
      selectedTemplates = entryCollection.selectedEntries
        .filter((templateId) => templateId !== draggedElement.getId())
        .map((templateId) => this.templateService.getElementById(templateId));
    }

    return selectedTemplates;
  }

  /**
   * Returns an array of actions that can be performed on the drop zone for the given dragged elements.
   * @param draggedElements An array of Template objects representing the elements being dragged.
   * @returns An array of Action objects representing the actions that can be performed on the drop zone.
   */
  private getDropZoneActions(draggedElements: Template[]): Action[] {
    const targetTemplateId = this.template.getId();
    const allActions: Action[] = [];

    draggedElements.forEach((draggedElement) => {
      const dropZoneActions = draggedElement.getDropZoneActions
        ? draggedElement.getDropZoneActions()[targetTemplateId]
        : [] || [];
      if (dropZoneActions.length) {
        allActions.push(...dropZoneActions);
      }
    });

    return allActions;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
