import { TextOverlayShadowMode } from '@gantt/lib/best_gantt/script/config/config-data/text-overlay-config.data';
import { EGanttTextStrategy, EGanttXAxisPosition } from '@gantt/public-api';
import { EColorizeStrategyOption } from '../../saxms-best-gantt/legend/colorize-startegy-option';
import {
  EGanttTooltipDelayType,
  SaxMsBestGanttZoomGridMode,
  SaxMsBestGanttZoomGridStep,
} from '../general/gantt-settings/gantt-settings-dialog/gantt-style.enum';
import { IAvailableFederalState } from '../general/generator/predefined/holidays/gantt-holiday';
import { EdgesPluginSettings } from '../general/plugin/plugin-list/edges/edges-plugin-settings.enum';
import { EDockViewMode } from './dock/dock.enum';
import { IGanttDockWindowProportions } from './dock/dock.interface';
import { ELegendDataSortingOrder } from './dock/views/legend/legend-data-sorting.enum';

/**
 * Default settings for gantt diagram.
 */
export class SaxMsBestGanttSettings {
  public axesAmount? = 3;
  public visibleAttributeId? = null;
  public axesFormat?: string[] = [];
  public axesLabelPosition?: 'middle' | 'start' | 'end' = 'middle';
  public area? = '';
  public activeSubmenuEntryElements?: SaxMsBestGanttActiveSubmenuEntryElementSetting[] = [];
  public ganttID? = '';

  public compare? = false;
  public colorizeRowSibling? = true;
  public calendar?: SaxMsBestGanttCalendarSettings = {
    showHolidays: false,
    selectedFilterFederalState: 'showAll',
    showTimeIntervals: false,
  };
  public availableFederalStates?: IAvailableFederalState[]; // for local use only
  public calendarColor? = 'pink';
  public disableAnimation? = false;
  public disableTooltips? = false;
  public dropDownFavorites?: any[] = [];
  public enableGanttEntryBackgroundColor? = true;
  public enableGanttEarliestStartLatestEnd? = false;

  public experimentIds?: string[] = [];

  public filterInput? = '';
  public textColorShifts? = '#FFFFFF';
  public fontSizeShifts? = 14;
  public fontSizeRows? = 14;
  public fontSize? = 14;
  public favoriteMenuElements? = '';

  public ganttPluginSettings?: any = {
    edgePluginRendering: EdgesPluginSettings.ALWAYS,
  };
  public ganttEntryWidth? = 280;
  public ganttEntryColorization? = true;

  public highlightSimilarShifts? = true;

  public labelMode?: EGanttTextStrategy = EGanttTextStrategy.CUT_OFF_LABELS;
  public limit? = 0;
  public linesToFront? = false;
  public horizontalLinesColor? = '#FFFFFF';
  public horizontalLinesThickness? = 1;
  public showHorizontalSplitLines? = true;
  public verticalLinesColor? = '#FFFFFF';
  public verticalLinesThickness? = 1;
  public verticalLinesDailyColor? = '#FFFFFF';
  public verticalLinesDailyThickness? = 1;
  public verticalLinesMonthlyColor? = '#FFFFFF';
  public verticalLinesMonthlyThickness? = 1;
  public colorizeStrategyOption?: EColorizeStrategyOption = EColorizeStrategyOption.DEFAULT;
  public legendPosition?: EDockViewMode = EDockViewMode.BOTTOM;
  public legendEntrySortingOrder?: ELegendDataSortingOrder = ELegendDataSortingOrder.DEFAULT;
  public showOnlyViewportInLegend? = false;
  public textOverlayShadowMode? = TextOverlayShadowMode.DROP_SHADOW;

  public offset? = 50;
  public openGanttEntries?: string[] = []; // TODO: Check if this is used?

  public pluginSettings?: any;

  public rowHierarchieColors?: string[] = []; // TODO Check if this is still needed by Florian
  public defaultRowColor? = '#dcdcdc';
  public rowHeight? = 34;
  public roundCorners? = true;
  public rowColorHierarchies?: SaxMsBestGanttRowColorHierarchiePluginSetting = {
    active: false,
    colors: ['#00000000', '#00000000', '#00000000'],
  };
  public blockSortingAttribute?: string = '';

  public scrollStart? = 0;
  public scrollEnd? = 0;
  public showAttribute? = false; // TODO: what is this? never used...
  public selectedColorFilter? = '';
  public showLate? = true;
  public showScrollTimeline? = false;
  public showVerticalLines? = true;
  public showHorizontalLines? = true;
  public shiftHeight? = 34;
  public showCalendar? = true;
  public showWeekends? = true;
  public shiftPadding? = 5;
  public selectColor? = '#5555ff';
  public selectOpacity? = 0.55;
  public showIndexCardTimespan? = false;
  public enableIndexCardView? = false;
  public showIndexCardEmptyAttributes? = false;
  public showIndexCardAttributeNames? = true;
  public activeSuperBlockType?: number = undefined;

  public transparentFreetextSearch? = false;
  public tooltipDelay? = true;
  public showTooltip? = true;
  public tooltipDelayMS? = EGanttTooltipDelayType.SHORT_DELAY;
  public tooltipOpacity? = 0.9; // TODO Check if this is still needed by Florian
  public tooltipFontSize? = 14;
  public scrollableTooltip? = false;

  public weekendColor? = '#adadadBB';
  public holidayColor? = '#c4e4ff80';

  public xAxisMarkerFormat? = '%d.%m. %H:%M:%S';
  public xAxisPosition?: EGanttXAxisPosition = EGanttXAxisPosition.TOP;
  public xaxisVisible? = true;
  public xAxisShowWeekdays? = false;

  public zoomGridMode?: SaxMsBestGanttZoomGridMode = SaxMsBestGanttZoomGridMode.OFF;
  public zoomGridStep?: SaxMsBestGanttZoomGridStep = SaxMsBestGanttZoomGridStep.HOURLY;
  public zoomGridRef? = 0;
  public zoomGridWidth? = 0;
  public showStickyRows? = false;
  public enableFirstRowStickyMode? = false;
  public enableFirstRowWithChildRowsStickyMode? = false;

  public dockBelowSize? = 72;
  public dockRightSize? = 20;
  public dockWindowProportions?: IGanttDockWindowProportions = {
    height: 500,
    width: 450,
    x: 50,
    y: 50,
  };
}

/**
 * Backend data structure to save active submenu entries.
 */
export interface SaxMsBestGanttActiveSubmenuEntryElementSetting {
  id: string;
  value?: string;
}

export interface SaxMsBestGanttRowColorHierarchiePluginSetting {
  active: boolean;
  colors: string[];
}

export interface SaxMsBestGanttCalendarSettings {
  showHolidays: boolean;
  selectedFilterFederalState: string;
  showTimeIntervals: boolean;
}

/**
 * Executer to set initial zoom inside gantt by settings data.
 */
export class SaxMsBestGanttInitialZoomHandler {
  public ganttBuilder;

  constructor(ganttBuilder: any) {
    this.ganttBuilder = ganttBuilder;
  }

  /**
   * Zooms gantt to given timeperiod.
   * @param scrollStart Start timepoint of timeperiod.
   * @param scrollEnd Start endpoint of timeperiod.
   */
  public addGanttTimeSpan(scrollStart: number, scrollEnd: number): void {
    if (scrollStart === scrollEnd || scrollEnd < scrollStart) {
      console.warn('Invalid time span!');
      return;
    }
    scrollStart = scrollStart || this.ganttBuilder.getXAxisBuilder().getCurrentScale().domain()[0];
    scrollEnd = scrollEnd || this.ganttBuilder.getXAxisBuilder().getCurrentScale().domain()[1];
    this.ganttBuilder.setCurrentZoomedTimeSpan(new Date(scrollStart), new Date(scrollEnd));
  }

  /**
   * Facade to set zoom level by settings data.
   * Does nothign if no data has been given.
   * @param settings Gantt settings container.
   */
  public executeInitialZoom(settings: SaxMsBestGanttSettings): void {
    if (!settings) return;
    this.addGanttTimeSpan(settings.scrollStart, settings.scrollEnd);
  }
}
