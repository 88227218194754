import { Injectable } from '@angular/core';
import { Action } from '@app-modeleditor/components/button/action/action';
import { Resource } from 'frontend/src/dashboard/model/resource/resource';
import { ResourceAdapter } from 'frontend/src/dashboard/model/resource/resource-adapter.service';
import { ActionAdapter } from '../../modeleditor/components/button/action-adapter.service';
import { Notification } from './notification';

@Injectable({
  providedIn: 'root',
})
export class NotificationFactoryService {
  constructor(private resourceAdapter: ResourceAdapter, private actionFactory: ActionAdapter) {}

  public parseNotification(data: any): Notification {
    if (!data) {
      return new Notification();
    }
    if (data instanceof Notification) {
      return data;
    }
    const result: Notification = this.resourceAdapter
      .inherit<Notification>(Notification, data)
      .setBelongsToResource(this.resourceAdapter.inherit<Resource>(Resource, data.belongsToResource))
      .setMessage(data.message)
      .setMessageCategory(data.messageCategory)
      .setMessageCode(data.messageCode)
      .setActions(this.getActions(data))
      .setQueueTime(isNaN(data.queueTime) ? null : new Date(data.queueTime))
      .setResourceCanonicalName(data.resourceCanonicalName)
      .setResourceId(data.resourceId)
      .setCreated(new Date(data.created))
      .setId(data.id)
      .setType(data.type)
      .setValidUntil(new Date(data.validUntil))
      .setClazz(data.clazz)
      .setUpdateElementIds(data.updateElementIds)
      .setRefreshElementIds(data.refreshElementIds)
      .setBroadcast(data.broadcast)
      .setFileDownloadRestUrl(data.fileDownloadRestUrl)
      .setZoomEnd(isNaN(data.zoomend) ? null : new Date(data.zoomend))
      .setZoomStart(isNaN(data.zoomstart) ? null : new Date(data.zoomstart))
      .setContentId(data.contentId)
      .setDisableReload(data.disableReload)
      .setLoadingParams(data.loadingParams)
      .setHighlightBlockIds(data.highlightBlockIds)
      .setTableResourceId(data.tableResourceId)
      .setTableId(data.tableId)
      .setBelongsToResourceIds(data.belongsToResourceIds);

    if (result.getBelongsToResource() && data.belongsToResource) {
      result.setBelongsToResource(result.getBelongsToResource().applyObject(data.belongsToResource));
    }

    return result;
  }

  private getActions(data): Action[] {
    const onClickActions: Action[] = (data.onClickActions || []).map((action) =>
      this.actionFactory.parseAction<Action>(Action, action)
    );

    const onClickAction = this.actionFactory.parseAction<Action>(Action, data.onClickAction);

    if (onClickAction) {
      // add onClickAction to onClickActions at first position
      onClickActions.unshift(onClickAction);
    }

    return onClickActions;
  }
}
