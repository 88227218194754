import { Component, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ContextMenuButton, CONTEXT_MENU_TEMPLATE, IContextMenuTemplate } from './context-menu-element.builder';

@Component({
  selector: 'context-menu-popup',
  templateUrl: './context-menu-popup.component.html',
  styleUrls: ['./context-menu-popup.component.scss'],
})
export class ContextMenuPopupComponent {
  constructor(
    public translate: TranslateService,
    @Inject(CONTEXT_MENU_TEMPLATE) public contextMenuTemplate: IContextMenuTemplate
  ) {}

  handleClick(event, item: ContextMenuButton) {
    if (item.callback) {
      item.callback();
    }
  }
}
