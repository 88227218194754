import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class PreviewGanttService {
  private onSettingsChangedSubject = new Subject<null>();

  constructor() {}

  public onSettingsChanged(): Observable<null> {
    return this.onSettingsChangedSubject.asObservable();
  }

  public triggerSettingsChanged(): void {
    this.onSettingsChangedSubject.next(null);
  }
}
