import { GanttColorCalculator } from '../color/color-calculator/color-calculator';
import { ToHexColorConverter } from '../color/color-converter/to-hex-converter';
import { GanttConfig } from '../config/gantt-config';

/**
 * Builder for patterns.
 * @keywords pattern, shift, colorize
 */
export class PatternBuilder {
  private readonly _nameSpace = 'http://www.w3.org/2000/svg';

  /**
   * @param _ganttConfig Config of the gantt diagram to build patterns for.
   */
  constructor(private readonly _ganttConfig: GanttConfig = undefined) {}

  /**
   * Creates a svg element and returns it.
   * @param height Height of the svg element.
   * @param width Width of the svg element.
   * @returns Svg element.
   */
  public getSvgElement(height: number, width: number): SVGSVGElement {
    const svg = document.createElementNS(this._nameSpace, 'svg');
    svg.setAttribute('xmlns', this._nameSpace);
    svg.setAttribute('height', `${height}`);
    svg.setAttribute('width', `${width}`);
    return svg;
  }

  /**
   * Creates a rect element for svg and returns it.
   * @param height Height of the rect element.
   * @param width Width of the rect element.
   * @param x X-coordinate of the rect element.
   * @param y Y-coordinate of the rect element.
   * @param fill Color of the rect element.
   * @returns Rect element.
   */
  private _getRectElement(height: number, width: number, x: number, y: number, fill: string): SVGRectElement {
    const rect = document.createElementNS(this._nameSpace, 'rect');
    rect.setAttribute('height', `${height}`);
    rect.setAttribute('width', `${width}`);
    rect.setAttribute('x', `${x || 0}`);
    rect.setAttribute('y', `${y || 0}`);
    rect.setAttribute('fill', fill || '#000000');
    return rect;
  }

  /**
   * Creates a path element for svg and returns it.
   * @param d Path information of the path element.
   * @param strokeColor Color of the path element.
   * @param strokeWidth Stroke width of the path element.
   * @param transform Transform information of the path element.
   * @param fill Fill color of the path element.
   * @returns Path element.
   */
  private _getPathElement(
    d: string,
    strokeColor: string,
    strokeWidth: number,
    transform: string,
    fill: string
  ): SVGPathElement {
    const path = document.createElementNS(this._nameSpace, 'path');
    path.setAttribute('d', d);
    if (strokeColor) path.setAttribute('stroke', strokeColor);
    if (strokeWidth) path.setAttribute('stroke-width', `${strokeWidth || 0}`);
    if (transform) path.setAttribute('transform', transform);
    if (fill) path.setAttribute('fill', fill);
    return path;
  }

  /**
   * Creates a circle element for svg and returns it.
   * @param r Radius of the circle element.
   * @param x X-coordinate of the circle element.
   * @param y Y-coordinate of the circle element.
   * @param fill Color of the circle element.
   * @returns Circle element.
   */
  private _getCircleElement(r: number, x: number, y: number, fill: string): SVGCircleElement {
    const circle = document.createElementNS(this._nameSpace, 'circle');
    circle.setAttribute('r', `${r}`);
    circle.setAttribute('cx', `${x || 0}`);
    circle.setAttribute('cy', `${y || 0}`);
    circle.setAttribute('fill', fill || '#000000');
    return circle;
  }

  /**
   * Appends a rect element in svg element.
   * @param svgElement Svg object element.
   * @param height Height of the rect element.
   * @param width Width of the rect element.
   * @param x X-coordinate of the rect element.
   * @param y Y-coordinate of the rect element.
   * @param fill Color of the rect element.
   * @param stroke Color of the stroke around the rect element (= separate element).
   */
  public appendRect(
    svgElement: SVGGElement,
    height: number,
    width: number,
    x: number,
    y: number,
    fill: string,
    stroke: string
  ): void {
    const strokeRect = this._getRectElement(height + this.strokeWidth * 2, width + this.strokeWidth * 2, x, y, stroke);
    svgElement.appendChild(strokeRect);

    const rect = this._getRectElement(height, width, x + this.strokeWidth, y + this.strokeWidth, fill);
    svgElement.appendChild(rect);
  }

  /**
   * Appends a path element in svg element.
   * @param svgElement Svg object element.
   * @param d Path information of the path element.
   * @param strokeColor Color of the path element.
   * @param strokeWidth Stroke width of the path element.
   * @param transform Transform information of the path element.
   * @param fill Fill color of the path element.
   * @param stroke Color of the stroke around the path element (= separate element).
   */
  public appendPath(
    svgElement: SVGGElement,
    d: string,
    strokeColor: string,
    strokeWidth: number,
    transform: string,
    fill: string,
    stroke: string
  ): void {
    const strokePath = this._getPathElement(d, stroke, (strokeWidth || 0) + this.strokeWidth * 2, transform, undefined);
    svgElement.appendChild(strokePath);

    const path = this._getPathElement(d, strokeColor, strokeWidth, transform, fill);
    svgElement.appendChild(path);
  }

  /**
   * Appends a circle element in svg element.
   * @param svgElement Svg object element.
   * @param r Radius of the circle element.
   * @param x X-coordinate of the circle element.
   * @param y Y-coordinate of the circle element.
   * @param fill Color of the circle element.
   * @param stroke Color of the stroke around the cricle element (= separate element).
   */
  public appendCircle(svgElement: SVGGElement, r: number, x: number, y: number, fill: string, stroke: string): void {
    const strokeCircle = this._getCircleElement(r + this.strokeWidth, x, y, stroke);
    svgElement.appendChild(strokeCircle);

    const circle = this._getCircleElement(r, x, y, fill);
    svgElement.appendChild(circle);
  }

  /**
   * Appends a background rect in svg element.
   * @param svgElement Svg object element.
   * @param color Color of the background.
   */
  public appendBackground(svgElement: SVGGElement, color: string): void {
    const height = parseFloat(svgElement.getAttribute('height'));
    const width = parseFloat(svgElement.getAttribute('width'));
    const background = this._getRectElement(height, width, 0, 0, color);
    svgElement.appendChild(background);
  }

  /**
   * Converts a svg node into a data string and returns it.
   * @param svgNode Svg object element.
   * @returns Data string.
   */
  public convertSvgNodeIntoDataString(svgNode: SVGSVGElement): string {
    return 'data:image/svg+xml;base64,' + btoa(svgNode.outerHTML);
  }

  /**
   * Determines the stroke color of a pattern by the given color values.
   * @param patternColor Color of the pattern.
   * @param backgroundColor Background color of the pattern.
   * @returns Stroke color of a pattern determined by the given color values.
   */
  public getStrokeColor(patternColor: string, backgroundColor: string): string {
    let strokeColor = 'none';

    // to be sure, try to convert input colors into hex format
    patternColor = ToHexColorConverter.convertColorToHex(patternColor);
    backgroundColor = ToHexColorConverter.convertColorToHex(backgroundColor);

    // if stroke is necessary -> apply stroke to pattern
    if (this.isStrokeColorNecessary(patternColor, strokeColor)) {
      // determine if dark or bright stroke should be used
      const isPatternDark = GanttColorCalculator.isDarkColor(patternColor, 155);
      const isBackgroundDark = GanttColorCalculator.isDarkColor(backgroundColor, 155);

      strokeColor = !isPatternDark && !isBackgroundDark ? this._strokeColorDark : this._strokeColorBright;
    }
    return strokeColor;
  }

  /**
   * Determines the necessity of a stroke color of a pattern by the given color values.
   * @param patternColor Color of the pattern.
   * @param backgroundColor Background color of the pattern.
   * @returns `true` if a stroke color is necessary for a patern with the given color values, `false` otherwise.
   */
  public isStrokeColorNecessary(patternColor: string, backgroundColor: string): boolean {
    // if stroke are not enabled -> don't apply a stroke color
    if (!this._isStrokesEnabled) return false;

    // to be sure, try to convert input colors into hex format
    patternColor = ToHexColorConverter.convertColorToHex(patternColor);
    backgroundColor = ToHexColorConverter.convertColorToHex(backgroundColor);

    // calculate contrast between both colors
    const contrast = GanttColorCalculator.getColorContrastHex(patternColor, backgroundColor);

    // if contrast < threshold value -> apply stroke to pattern
    if (contrast < this._strokeContrastThreshold) return true;

    // else -> don't apply a stroke color
    return false;
  }

  //
  // GETTER & SETTER
  //

  private get _isStrokesEnabled(): boolean {
    if (!this._ganttConfig) return true;
    return this._ganttConfig.isShiftPatternStrokesEnabled();
  }

  private get _strokeContrastThreshold(): number {
    if (!this._ganttConfig) return 1.5;
    return this._ganttConfig.getShiftPatternStrokeContrastThreshold();
  }

  public get strokeWidth(): number {
    if (!this._ganttConfig) return 0.5;
    return this._ganttConfig.getShiftPatternStrokeWidth();
  }

  private get _strokeColorBright(): string {
    if (!this._ganttConfig) return '#ffffff';
    return this._ganttConfig.getShiftPatternStrokeColorBright();
  }

  private get _strokeColorDark(): string {
    if (!this._ganttConfig) return '#000000';
    return this._ganttConfig.getShiftPatternStrokeColorDark();
  }
}
