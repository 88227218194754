import { GanttEssentialPlugIns } from 'frontend/src/dashboard/gantt/general/plugin/e-gantt-essential-plugins';
import { GanttResponse } from 'frontend/src/dashboard/gantt/general/response/responses/response';
import { GanttOverlappingShiftsPlugIn } from '../overlapping-shifts';

/**
 * Response handling for splitting overlapping shifts.
 * Updates priorised Attributes on new Data so they will be considered too.
 */
export class GanttOverlappingShiftsPlugInUpdate extends GanttResponse {
  /**
   * Updates priorised attributes data on new data event.
   * @param response Serverreponse.
   */
  public handleResponse(response: any): boolean {
    if (response.ganttEntries || response.addedGanttEntries) {
      const overlappingShiftsPlugIn: GanttOverlappingShiftsPlugIn = this._pluginHandlerService.getEssentialPlugIn(
        GanttEssentialPlugIns.OverlappingShiftsPlugIn
      );
      overlappingShiftsPlugIn.reInitMatchingStrategy(this._pluginHandlerService.getTemplateData());
      return true;
    } else {
      return false;
    }
  }
}
