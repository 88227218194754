import * as d3 from 'd3';

/**
 * * @description **General / Generic** class / format for Gantt X Axis.
 * @class
 *
 * @param {TimeFormatterTimeFormat[]} formatList Axis timeformat which is valid for different zoom levels.
 * @param {number} [height=24] Height of xAxis.
 * @param {boolean} [renderVerticalLines=false] Wether or not this axis renders Vertical Lines on SHIFT AREA.
 */
export class GanttXAxisFormatGeneral {
  dateFormatList: TimeFormatterTimeFormat[];
  height: number;
  cssClassName: string;
  cssClassNameText: string;
  tickSize: number;
  tickSizeText: number;
  fontSize: number;
  renderVerticalLinesOnShifts: boolean;

  constructor(formatList: TimeFormatterTimeFormat[], height = 24, renderVerticalLines = false) {
    /**
     * @type {TimeFormatterTimeFormat[]}
     */
    this.dateFormatList = formatList || [
      {
        value: 1,
        time: [new TimeFormatterAxisFormat('%Y', d3.timeYear.every(1), 'YEAR')],
      },
      {
        value: 5.3,
        time: [new TimeFormatterAxisFormat('Quartal', d3.timeMonth.every(3), 'MONTH')],
      },
      {
        value: 18,
        time: [new TimeFormatterAxisFormat('%B %y', d3.timeMonth.every(1), 'MONTH')],
      },
      {
        value: 55,
        time: [new TimeFormatterAxisFormat('%B %Y', d3.timeMonth.every(1), 'MONTH')],
      },
      {
        value: 100,
        time: [new TimeFormatterAxisFormat('%V KW', d3.timeMonday.every(1), 'CALENDAR_WEEK')],
      },
      {
        value: 1200,
        time: [
          new TimeFormatterAxisFormat(
            '%d %b %Y',
            d3.timeDay.every(1),
            'DAY',
            ETimeFormatWeekdayStrategy.INLINE,
            '%a, %d %b %Y'
          ),
        ],
      },
    ];

    this.height = height;
    this.cssClassName = '';
    this.cssClassNameText = 'x_axis_text_top';
    this.tickSize = null;
    this.tickSizeText = null;
    this.fontSize = 10;

    this.renderVerticalLinesOnShifts = renderVerticalLines;
  }

  /**
   * Function calculates correct axis format by d3 scale.
   * @kewords calculation, axis, format, scale, tick, time
   * @param {scale} d3Scale D3 function for scaling x axis.
   * @return {TimeFormatterTimeFormat}
   */
  getTickformatByScale(d3Scale: d3.ScaleTime<number, number, never>): TimeFormatterTimeFormat {
    const startDate = d3Scale.domain()[0],
      endDate = new Date(startDate.getTime() + 86400000),
      difference = d3Scale(endDate) - d3Scale(startDate);

    let matchingFormat = this.dateFormatList[0];

    for (let i = 0; i < this.dateFormatList.length; i++) {
      const format = this.dateFormatList[i];
      if (format.value <= difference) {
        matchingFormat = format;
      }
    }
    return matchingFormat;
  }

  /**
   * Sets time format list.
   * @param {TimeFormatterTimeFormat[]} dateFormatList List of date formats.
   */
  setTimeFormat(dateFormatList: TimeFormatterTimeFormat[]) {
    this.dateFormatList = dateFormatList;
  }

  /**
   * Sets axis height.
   * @param {number} height Height in pixel the axis should have.
   */
  setHeight(height: number) {
    this.height = height;
  }

  /**
   * Sets font Size for tick labels
   * @param {number} fontSize TickLabel Font Size.
   */
  setFontSize(fontSize: number) {
    this.fontSize = fontSize;
  }

  /**
   * Sets axis css ClassName for later styling.
   * @param {String} cssClassName The CSS Classname this axis will use to later get styled.
   */
  setCssClassName(cssClassName: string) {
    this.cssClassName = cssClassName;
  }

  /**
   * Sets text-xAxis css Class for corresponding the normal xAxis.
   * @param {String} cssClassNameText
   */
  setCssClassNameText(cssClassNameText: string) {
    this.cssClassNameText = cssClassNameText;
  }

  /**
   * Sets the tickSize for the xAxis.
   * @param {number} number in pixel
   */
  setTickSize(number: number) {
    this.tickSize = number;
  }

  /**
   * Sets the tickSize for the text xAxis.
   * @param {number} number in pixel
   */
  setTickSizeText(number: number) {
    this.tickSizeText = number;
  }

  /**
   * Toggles wether or not axis should render VerticalLines on shift area.
   * @param {boolean} boolean
   */
  setRenderVerticalLines(boolean: boolean) {
    this.renderVerticalLinesOnShifts = boolean;
  }

  renderVerticalLines() {
    return this.renderVerticalLinesOnShifts;
  }

  /**
   * Generates another instance with same values.
   * Note: All properties will contain the same object references like the original.
   * @returns Generated instance with the same values like the original.
   */
  public getShallowClone(): GanttXAxisFormatGeneral {
    const clone = new GanttXAxisFormatGeneral(this.dateFormatList, this.height, this.renderVerticalLinesOnShifts);
    clone.cssClassName = this.cssClassName;
    clone.cssClassNameText = this.cssClassNameText;
    clone.tickSize = this.tickSize;
    clone.tickSizeText = this.tickSizeText;
    clone.fontSize = this.fontSize;
    return clone;
  }
}

/**
 * Parent class of time formatting data which holds data for formatting for one specific zoom level.
 * @keywords data, class, time, formatting, zoom, level, xaxis, x axis
 * @class
 * @constructor
 * @param {number} value Relative scalar of x axis to find correct axis format.
 * @param {TimeFormatterAxisFormat[]} time List of axis ticks.
 */
export class TimeFormatterTimeFormat {
  value: number;
  time: TimeFormatterAxisFormat[];

  constructor(value: number, time: TimeFormatterAxisFormat[]) {
    this.value = value;
    this.time = time;
  }
}

/**
 * This class holds information about tick amount and tick text.
 * @keywords data, class, ticks, amount, format, time, date, xaxis, x axis
 * @class
 * @constructor
 * @param {string} format Date format of tick.
 * @param {d3.timeYear[] | d3.timeMonth[] | d3.timeWeek[] | d3.timeDay[] | d3.timeMinute[] | number[]} ticks Tupel of axis tick text and amount of ticks.
 */
export class TimeFormatterAxisFormat {
  format: string;
  weekdayFormat?: string;
  weekdayStrategy?: ETimeFormatWeekdayStrategy;
  ticks: d3.TimeInterval;
  unit: string;
  css?: string;
  cssText?: string;
  height?: number;
  fontSize?: number;
  tickSize?: number;
  tickSizeText?: number;
  renderVerticalLinesOnShifts?: boolean;

  constructor(
    format: string,
    ticks: d3.TimeInterval,
    unit: string,
    weekdayStrategy = ETimeFormatWeekdayStrategy.NONE,
    weekdayFormat: string = null,
    renderVerticalLines = true
  ) {
    this.format = format;
    this.weekdayStrategy = weekdayStrategy;
    this.weekdayFormat = weekdayFormat;
    this.ticks = ticks;
    this.unit = unit;
    this.renderVerticalLinesOnShifts = renderVerticalLines;
  }

  /**
   * Generates another instance with same values.
   * Note: All properties will contain the same object references like the original.
   * @returns Generated instance with the same values like the original.
   */
  public getShallowClone(): TimeFormatterAxisFormat {
    const clone = new TimeFormatterAxisFormat(
      this.format,
      this.ticks,
      this.unit,
      this.weekdayStrategy,
      this.weekdayFormat
    );
    clone.css = this.css;
    clone.cssText = this.cssText;
    clone.height = this.height;
    clone.fontSize = this.fontSize;
    clone.tickSize = this.tickSize;
    clone.tickSizeText = this.tickSizeText;
    clone.renderVerticalLinesOnShifts = this.renderVerticalLinesOnShifts;
    return clone;
  }
}

/**
 * Enum defining all possible ways of handling the display of weekdays in the x axis.
 */
export enum ETimeFormatWeekdayStrategy {
  NONE = 'none',
  INLINE = 'inline',
  AXIS = 'axis',
}
