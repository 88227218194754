<nn-legend headline="@attributeFilter@">
  <div class="content">
    <div
      class="node-network-legend-item-wrapper"
      *ngFor="let legendItem of colorLabelList"
      [style.opacity]="filteredNodes.indexOf(legendItem.label) > -1 ? '0.3' : null"
    >
      <div class="node-network-legend-color-item" [style.background]="legendItem.color"></div>
      <div
        class="node-network-legend-label"
        [matTooltipShowDelay]="1000"
        matTooltip="{{ legendItem.label }}"
        (click)="filterNode(legendItem)"
      >
        {{ legendItem.label }}
      </div>
    </div>
  </div>
</nn-legend>
