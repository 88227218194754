<saxms-sidebar [bottomNav]="true" [title]="title" [panels]="sidebar" [id]="'_template_notification'">
  <ng-template #categoryTemplate let-panel>
    <ng-container *ngFor="let page of template.getIndexRegister().getIndexPages()">
      <ng-container [ngSwitch]="page.id">
        <ng-container *ngSwitchCase="'template.indexpage.log'">
          <app-log *ngIf="page.uuid === panel.id && page.content" [log]="page"></app-log>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <template-slot *ngIf="page.uuid === panel.id && page.content" [source]="page"></template-slot>
        </ng-container>
      </ng-container>
      <!-- <app-template-ui *ngIf="page.uuid === panel.id && page.content" [model]="page"></app-template-ui> -->
    </ng-container>
  </ng-template>
</saxms-sidebar>
