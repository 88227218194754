import { Injectable, Type } from '@angular/core';
import { IndexPage } from '@app-modeleditor/components/index-register/index-page';
import { TemplateAdapter } from '@app-modeleditor/utils/template-factory.service';
import { TemplateResourceAdapter } from '@app-modeleditor/utils/template-resource-factory.service';
import { Adapter } from '../../../core/adapter';

@Injectable({
  providedIn: 'root',
})
export class IndexPageAdapter implements Adapter<IndexPage> {
  constructor(private _templateResourceAdapter: TemplateResourceAdapter) {}

  adapt(item: any): IndexPage {
    throw new Error('Method not implemented.');
  }
  inherit?<T extends IndexPage>(type: Type<T>, item: any): T {
    return this._getData(this._templateResourceAdapter.inherit<T>(type, item), item);
  }

  inheritFrom?<T extends IndexPage>(scope: TemplateAdapter, type: Type<T>, item: any): T {
    return this._getData(this._templateResourceAdapter.inheritFrom<T>(scope, type, item), item, scope);
  }

  private _getData<T extends IndexPage>(element: T, item: any, scope?: TemplateAdapter): T {
    if (scope) {
      element.setContent(scope.adapt(item.content));
      element.setIcon(item.icon);
    }
    return element;
  }

  applyValues<T>(scope: TemplateAdapter, item: any, values: any): T {
    throw new Error('Method not implemented.');
  }
}
