import { Observable, Subject, takeUntil } from 'rxjs';
import { GanttCanvasRow, GanttCanvasShift } from '../../data-handler/data-structure/data-structure';
import { GanttShiftTranslationGlobalForbiddenAreas } from '../../edit-shifts/shift-translation/translation-restrictions/shift-translation-area-limiter';
import { EGanttScrollContainer } from '../../html-structure/scroll-container.enum';
import { BestGantt } from '../../main';
import { BestGanttPlugIn } from '../gantt-plug-in';

/**
 * Gantt extension to visualize blocked ares during drag and drop shifts.
 * @keywords plugin, extension, mark, show, highlight, color, colorize, limit, restriction, row
 * @plugin row-limiter
 * @class
 * @constructor
 * @extends BestGanttPlugIn
 *
 * @requires BestGanttPlugIn
 */
export class GanttRowLimiterMarker extends BestGanttPlugIn {
  private _canvas: { [id: string]: d3.Selection<SVGGElement, undefined, d3.BaseType, undefined> } = {};
  private _markedAreas: { [id: string]: d3.Selection<SVGRectElement, GanttCanvasRow, d3.BaseType, undefined> } = {};
  private _defs: { [id: string]: d3.Selection<SVGDefsElement, undefined, d3.BaseType, undefined> } = {};
  private _parentNode: { [id: string]: d3.Selection<SVGGElement, undefined, d3.BaseType, undefined> } = {};

  private _defaultPattern = 'stripe';
  private _currentPattern: string = null;
  private readonly _patternData = {
    dot: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPScxMCcgaGVpZ2h0PScxMCc+CjxyZWN0IHdpZHRoPScyJyBoZWlnaHQ9JzInIGZpbGw9J3JlZCcvPgo8cmVjdCB3aWR0aD0nMicgaGVpZ2h0PScyJyBmaWxsPSdub25lJy8+CjxyZWN0IHdpZHRoPScyJyBoZWlnaHQ9JzInIGZpbGw9J3JlZCcvPgo8cmVjdCB3aWR0aD0nMicgaGVpZ2h0PScyJyBmaWxsPSdub25lJy8+CjxyZWN0IHdpZHRoPScyJyBoZWlnaHQ9JzInIGZpbGw9J3JlZCcvPgo8L3N2Zz4=',
    stripe:
      'data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPScxMCcgaGVpZ2h0PScxMCc+CiAgPHJlY3Qgd2lkdGg9JzEwJyBoZWlnaHQ9JzEwJyBmaWxsPSdub25lJy8+CiAgPHBhdGggZD0nTS0xLDEgbDIsLTIKICAgICAgICAgICBNMCwxMCBsMTAsLTEwCiAgICAgICAgICAgTTksMTEgbDIsLTInIHN0cm9rZT0ncmVkJyBzdHJva2Utd2lkdGg9JzEuNScvPgo8L3N2Zz4=',
    cross:
      'data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPSc4JyBoZWlnaHQ9JzgnPgogIDxyZWN0IHdpZHRoPSc4JyBoZWlnaHQ9JzgnIGZpbGw9J25vbmUnLz4KICA8cGF0aCBkPSdNMCAwTDggOFpNOCAwTDAgOFonIHN0cm9rZS13aWR0aD0nMC41JyBzdHJva2U9J3JlZCcvPgo8L3N2Zz4=',
    none: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPSc4JyBoZWlnaHQ9JzgnPgogIDxyZWN0IHdpZHRoPSc4JyBoZWlnaHQ9JzgnIGZpbGw9JyNmZmZmZmYwMCcvPgo8L3N2Zz4=',
    full: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPSc4JyBoZWlnaHQ9JzgnPgogIDxyZWN0IHdpZHRoPSc4JyBoZWlnaHQ9JzgnIGZpbGw9JyNmZjAwMDAnLz4KPC9zdmc+',
  };

  private _onDestroySubject: Subject<void> = new Subject<void>();

  constructor() {
    super(); // call super-constructor
  }

  public initPlugIn(ganttDiagram: BestGantt): void {
    this.ganttDiagram = ganttDiagram;
    this._currentPattern = this._defaultPattern;

    // register callbacks
    this.ganttDiagram
      .getShiftTranslator()
      .onShiftEditStart()
      .pipe(takeUntil(this.onDestroy))
      .subscribe((event) => {
        const targetData = event.target.data()[0];
        this._markRows(targetData, event.blockedData);
        this.ganttDiagram
          .getVerticalScrollHandler()
          .onScrollVerticalUpdate.pipe(takeUntil(this.ganttDiagram.getShiftTranslator().onShiftEditEnd()))
          .subscribe(() => {
            this._removeAllMarkedRows();
            this._markRows(targetData);
          });
      });
    this.ganttDiagram
      .getShiftTranslator()
      .onShiftEditUpdate()
      .pipe(takeUntil(this.onDestroy))
      .subscribe((event) => {
        this._removeAllMarkedRows();
        this._markRows(event.target.data()[0], event.blockedData);
      });

    this.ganttDiagram
      .getShiftTranslator()
      .onShiftEditEnd()
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        this._removeAllMarkedRows();
      });

    this.ganttDiagram
      .getShiftResizer()
      .onShiftEditStart()
      .pipe(takeUntil(this.onDestroy))
      .subscribe((event) => this._markRows(event.shiftSelection.data()[0]));

    this.ganttDiagram
      .getShiftResizer()
      .onShiftEditEnd()
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => this._removeAllMarkedRows());

    this._initCanvas();
  }

  private _initCanvas(): void {
    for (const scrollContainerId of Object.values(EGanttScrollContainer)) {
      this._parentNode[scrollContainerId] = this.ganttDiagram
        .getShiftFacade()
        .getCanvasInFrontShifts(scrollContainerId)
        .insert('g', '.gantt_vertical-line-group')
        .attr('class', 'gantt-row-limiter');

      this._canvas[scrollContainerId] = this._parentNode[scrollContainerId].append('g');

      this._createDefsFromPatternData(this._patternData, scrollContainerId);
    }
  }

  /**
   * Remove of plugin. Part of plugin lifecycle.
   */
  public removePlugIn(): void {
    // callback unsubscribe
    this._onDestroySubject.next();
    this._onDestroySubject.complete();

    // remove groups
    this._removeAllMarkedRows();
  }

  /**
   * Returns all entry types of selected shifts
   */
  private _getAllEntryTypes(draggedShift: GanttCanvasShift): number[] {
    const entryTypes: number[] = [];
    const draggedShifts = this.ganttDiagram.getSelectionBoxFacade().getSelectedShifts();
    if (!draggedShifts.length) {
      return draggedShift.entryTypes;
    } // if no shift is selected, the dragged shift is the only one
    for (const shift of draggedShifts) {
      if (!shift.entryTypes || !shift.entryTypes.length) {
        continue;
      }
      for (const entryType of shift.entryTypes) {
        if (!entryTypes.includes(entryType)) {
          entryTypes.push(entryType);
        }
      }
    }
    return entryTypes;
  }

  /**
   * Callback that marks given gantt rows behind shifts.
   * @param canvasShiftData
   * @param blockedAreaData
   */
  private _markRows(
    canvasShiftData: GanttCanvasShift,
    blockedAreaData: GanttShiftTranslationGlobalForbiddenAreas[] = []
  ): void {
    const entryTypes = this._getAllEntryTypes(canvasShiftData);
    const blockedRowData = this.ganttDiagram
      .getShiftTranslator()
      .translationRowLimiter.getForbiddenRowIdsByShiftEntryTypes(
        entryTypes,
        this.ganttDiagram.getDataHandler().getYAxisDataset()
      );

    const blockedAreaDataFiltered = blockedAreaData.filter((blockedArea) => blockedArea.visualize);

    const scrollContainerIds = [EGanttScrollContainer.DEFAULT];
    if (this.ganttDiagram.getConfig().showStickyRows()) {
      scrollContainerIds.push(EGanttScrollContainer.STICKY_ROWS);
    }

    for (const scrollContainerId of scrollContainerIds) {
      const canvasYAxisDataSet = this.ganttDiagram.getRenderDataHandler().getRenderDataYAxis(scrollContainerId);

      this._markedAreas[scrollContainerId] = this._canvas[scrollContainerId]
        .selectAll<SVGRectElement, GanttCanvasRow>('.gantt-forbidden-area')
        .data(canvasYAxisDataSet);

      // forbidden areas
      this._markedAreas[scrollContainerId]
        .enter()
        .filter(function (d) {
          return (
            blockedAreaDataFiltered
              .map(function (e) {
                return e.rowId;
              })
              .indexOf(d.id) != -1
          );
        })
        .each((d) => {
          const forbiddenIntervals = blockedAreaDataFiltered.find(function (elem) {
            return elem.rowId == d.id;
          }).intervals;
          this._buildMarkedArea(d, forbiddenIntervals);
        });

      // forbidden rows
      for (const blockedRowId of blockedRowData) {
        const rowData = canvasYAxisDataSet.find((row) => row.id === blockedRowId);
        if (rowData) {
          this._buildMarkedArea(rowData, [{ start: null, end: null }]);
        }
      }
    }
  }

  /**
   * Draws the areas in the specific rows
   * @param rowData Json object of targetrow data
   * @param intervals
   */
  private _buildMarkedArea(rowData: GanttCanvasRow, intervals: { start: Date; end: Date }[]): void {
    const scale = this.ganttDiagram.getXAxisBuilder().getCurrentScale();

    for (let i = 0; i < intervals.length; i++) {
      // if start or end is null -> set coordinates
      const scaleStart = intervals[i].start ? scale(new Date(intervals[i].start)) : 0;
      const scaleEnd = intervals[i].end
        ? scale(new Date(intervals[i].end))
        : parseFloat(this.ganttDiagram.getShiftFacade().getShiftBuilder().getCanvasInFrontShifts().attr('width'));
      const width = scaleEnd - scaleStart;
      if (width < 0) {
        continue;
      }

      const scrollContainerId = this.ganttDiagram
        .getRenderDataHandler()
        .getStateStorage()
        .getRowScrollContainer(rowData.id);
      this._canvas[scrollContainerId]
        .append('rect')
        .attr('height', rowData.height)
        .attr('width', width)
        .attr('x', scaleStart)
        .attr('y', this.ganttDiagram.getRenderDataHandler().getStateStorage().getYPositionRow(rowData.id))
        .attr('class', 'gantt-forbidden-area')
        .attr('fill', 'url(#' + this._currentPattern + '-mark-rows-pattern)');
    }
  }

  /**
   * creates defs of a pattern-dataset
   * @param patternData dataset with base64 encoded pattern
   * @param scrollContainerId
   */
  private _createDefsFromPatternData(
    patternData: { [id: string]: string },
    scrollContainerId: EGanttScrollContainer
  ): void {
    this._defs[scrollContainerId] = this._canvas[scrollContainerId].append('defs');
    for (const pattern in patternData) {
      this._defs[scrollContainerId]
        .append('pattern')
        .attr('id', pattern + '-mark-rows-pattern')
        .attr('patternUnits', 'userSpaceOnUse')
        .attr('width', 10)
        .attr('height', 10)
        .append('image')
        .attr('xlink:href', patternData[pattern])
        .attr('width', 10)
        .attr('height', 10)
        .attr('x', 0)
        .attr('y', 0);
    }
  }

  /**
   * Callback that removes all row marking.
   */
  private _removeAllMarkedRows(): void {
    for (const scrollContainerId in this._canvas) {
      // remove global areas
      if (this._markedAreas[scrollContainerId]) {
        this._markedAreas[scrollContainerId].selectAll('.gantt-forbidden-area').remove();
      }

      // remove forbidden rows
      this._canvas[scrollContainerId].selectAll('.gantt-forbidden-area').remove();
    }
  }

  //
  // GETTER & SETTER
  //

  /**
   * Observable which gets triggered when the instance gets destroyed.
   */
  private get onDestroy(): Observable<void> {
    return this._onDestroySubject.asObservable();
  }

  /**
   * sets the pattern of the restricted areas
   * @param pattern none, dot, stripe, cross or full
   */
  public setPattern(pattern: string): void {
    const patterns = ['none', 'dot', 'stripe', 'cross', 'full'];
    patterns.includes(pattern) ? (this._currentPattern = pattern) : (this._currentPattern = this._defaultPattern);
  }
}
