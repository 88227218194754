export class PlanningHorizon {
  public id: string;
  constructor(
    public name: string,
    public description: string,
    id: string,
    public status: string,
    public active: boolean,
    public json: any
  ) {
    this.id = id;
  }
}

export class PlanningAlgorithm {
  public selected = false;
  public settings: PlanningAlgorithmSettings = null;
  constructor(public id: string, public aid: string, public name: string, public description: string) {}
}

export class PlanningAlgorithmSettings {
  constructor(
    public resourceSpecialPermissions: Array<any>,
    public resourceStatus: string,
    public settingsCategories: Array<any>,
    public type: string
  ) {}
}
