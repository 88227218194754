import { Type } from '@angular/core';
import { GlobalUtils } from 'frontend/src/dashboard/global-utils';
import { ETemplateEvents } from './template-events.enum';

export class Resource {
  private id: string;
  private name: string;
  private canonicalName: string;
  private created: Date;
  private resourceId: string;
  private localID: string;
  private eventListener: {
    [event: string]: { [id: string]: (event: CustomEvent) => void };
  };
  private monthlyDataResponse: boolean;

  public isMonthlyDataResponse(): boolean {
    return typeof this.monthlyDataResponse === 'boolean' ? this.monthlyDataResponse : false;
  }

  public setMonthlyDataResponse(monthlyDataResponse: boolean): this {
    this.monthlyDataResponse = monthlyDataResponse;
    return this;
  }

  protected update<T>(propKey: string, val: T): this {
    if (this.isSame(propKey, val) === true) {
      return;
    }

    this[propKey] = val;
    this.registerEvent(ETemplateEvents.UPDATED, val);
    return this;
  }

  private isSame(key: string, val: any): boolean {
    if (Array.isArray(this[key]) || Array.isArray(val)) {
      return this[key]?.length !== val?.length || this[key].find((item, index: number) => item !== val[index])
        ? false
        : true;
    }

    return this[key] === val;
  }

  protected registerEvent<T>(event: string, val: T = null): void {
    Object.keys(this.getEventListener()[`${event}`] || {}).forEach((id: string) => {
      const ev: CustomEvent = new CustomEvent(event, { detail: { val } });
      this.getEventListener()[`${event}`][id](ev);
    });
  }

  /**
   * add an event listener for a specific event
   * @param id identifier for each event
   * @param event event to listen for
   * @param cb callback on event execution
   * @returns void
   */
  public addEventListener(id: string, event: string, cb: (event: CustomEvent) => any): void {
    if (!this.eventListener) {
      this.eventListener = {};
    }
    if (!this.eventListener[event]) {
      this.eventListener[event] = {};
    }
    this.eventListener[event][id] = cb;
  }

  /**
   * removes event listener from template
   * @param id identifier for each event
   * @param event event to listen for
   * @returns void
   */
  public removeEventListener(id: string, event: string = null): void {
    if (!this.eventListener) {
      return;
    }

    if (event) {
      const obj = this.getEventListener()[event] || {};
      delete obj[id];
    } else {
      Object.keys(this.getEventListener()).forEach((eventId) => {
        const obj = this.getEventListener()[eventId];
        delete obj[id];
      });
    }
  }

  public getEventListener(): {
    [event: string]: { [id: string]: (event: CustomEvent) => void };
  } {
    return this.eventListener || {};
  }

  public getLocalID(): string {
    return this.localID;
  }

  public setLocalID(localID: string): this {
    this.localID = localID;
    return this;
  }

  public serialize(n?: Resource, whitelist: string[] = []): Record<string, any> {
    const obj = {};
    const self = n || this;
    Object.keys(self).forEach((key: string) => {
      if (!self[key] || whitelist.includes(key)) {
        obj[key] = null;
      } else if (Array.isArray(self[key])) {
        obj[key] = self[key].map((el: Resource) => (el instanceof Resource ? el.serialize(null, whitelist) : el));
      } else if (self[key] instanceof Resource) {
        obj[key] = self[key].serialize(null, whitelist);
      } else if (typeof self[key] === 'object') {
        if (self[key].constructor.name === 'Object') {
          obj[key] = this.serialize(self[key], whitelist);
        }
      } else if (typeof self[key] === 'function') {
      } else {
        obj[key] = self[key];
      }
    });
    return obj;
  }

  constructor() {
    this.setId(GlobalUtils.generateUUID());
  }

  /**
   * applies a native object to resource
   * @param obj json based object
   * @param options (optional) { overwrite: false; }
   * @returns this
   */
  applyObject(obj: any, options?: any): this {
    Object.keys(obj).forEach((key: string) => {
      if (options?.overwrite || !Object.keys(this).find((propertyKey: string) => propertyKey === key)) {
        this[key] = obj[key];
      }
    });
    return this;
  }

  /**
   * copy one class to another
   * @param type Type<T>
   * @return T
   */
  public copy<T>(type?: Type<T>): T {
    const t: T = type ? new type() : new (this.constructor as new () => T)();
    Object.keys(this).forEach((key: string) => (t[key] = this[key]));

    return t;
  }

  public getCanonicalName(): string {
    return this.canonicalName;
  }

  public setCanonicalName(canonicalName: string): this {
    this.canonicalName = canonicalName;
    return this;
  }

  /**
   * get id of the related resource
   * @returns string
   */
  public getResourceId(): string {
    return this.resourceId;
  }

  /**
   * set the related id of the resource
   * @param resourceId string
   * @returns this
   */
  public setResourceId(resourceId: string): this {
    this.resourceId = resourceId;
    return this;
  }

  /**
   * get identifier of resource
   * @returns string
   */
  public getId(): string {
    return this.id;
  }

  /**
   * set identifier of resource
   * @param id string
   * @returns this
   */
  public setId(id: string): this {
    this.id = id;
    return this;
  }

  /**
   * get name of resource
   * @returns string
   */
  public getName(): string {
    return this.name;
  }

  /**
   * set name of resource
   * @param name string
   * @returns this
   */
  public setName(name: string): this {
    this.update('name', name);
    return this;
  }

  /**
   * get date of creation of resource
   * @returns Date
   */
  public getCreated(): Date {
    return this.created;
  }

  /**
   * set date of creation of resource
   * @param created Date
   * @returns this
   */
  public setCreated(created: Date): this {
    this.created = created;
    return this;
  }
}
