import { GanttDataRow, GanttDataShift } from '../../../data-handler/data-structure/data-structure';
import { DataManipulator } from '../../../data-handler/data-tools/data-manipulator';
import { GanttUtilities } from '../../../gantt-utilities/gantt-utilities';

/**
 * Data calculation for additional rows for gantt.
 * @keywords plugin, compare, additional, row, analyse
 * @plugin compare-gantts
 * @class
 * @constructor
 * @static
 */
export class CompareGanttsAnalyse {
  private constructor() {}

  /**
   * Adds "Free time" row to gantt dataset in which all free times will be shwon by shifts.
   * A free time is a time interval in which no of the compared rows have shifts.
   * @param {GanttData} dataSet
   * @param {GanttDataRow} currentRows All gantt rows flattened in one list.
   * @param {number} addAdditionalDataRowLevel Hierarchical level, which desides, on which depth the additional row should be rendered.
   * @param {Date} timeStart Start time of comparison.
   * @param {Date} timeEnd End time of comparison.
   * @param {string} [rowDescription = "Free time"] Name of row.
   */
  static execute(dataSet, currentRows, addAdditionalDataRowLevel, timeStart, timeEnd, rowDescription) {
    // add additional rows
    function addAdditionalRows(child, level, parent, index) {
      if (currentRows[child.id] && addAdditionalDataRowLevel <= level) {
        const rowData = currentRows[child.id];

        const compareRowData = new CompareGanttsAnalyseRowsInput(rowData.id, rowData.shifts);
        const shifts = CompareGanttsAnalyse.getDifferenceRow(compareRowData, timeStart, timeEnd);

        const similarityRow: GanttDataRow = {
          id: 'similarity-row-id_' + GanttUtilities.generateUniqueID(11),
          name: 'Free time' || rowDescription,
          shifts: shifts || [],
          group: 'NO-GROUP',
          milestones: [],
          child: null,
          open: null,
        };

        var parent = parent ? parent.child : dataSet.ganttEntries;
        parent.splice(index.index, 0, similarityRow);
        index.index += 1;
      }
    }
    DataManipulator.iterateOverDataSet(dataSet.ganttEntries, { addAdditionalRows: addAdditionalRows });
  }

  /**
   * Calculates the time intervals in which no of the compared rows have shifts.
   * @param {CompareGanttsAnalyseRowsInput} comparedShifts
   * @param {Date} timeStart Start time of comparison.
   * @param {Date} timeEnd End time of comparison.
   */
  static getDifferenceRow(comparedShifts, timeStart, timeEnd) {
    comparedShifts = JSON.parse(JSON.stringify(comparedShifts));

    timeStart = new Date(timeStart);
    timeEnd = new Date(timeEnd);

    const allShifts = [];

    // 1. sort shifts by start date
    comparedShifts.allShifts.sort(function (a, b) {
      return new Date(a.timePointStart).getTime() - new Date(b.timePointStart).getTime();
    });

    // 2. combine overlapping shifts
    for (var i = 0; i < comparedShifts.allShifts.length - 1; i++) {
      var shift = comparedShifts.allShifts[i],
        nextShift = comparedShifts.allShifts[i + 1];

      const shiftEnd = new Date(shift.timePointEnd).getTime(),
        nextShiftStart = new Date(nextShift.timePointStart).getTime();

      if (nextShiftStart <= shiftEnd) {
        shift.timePointEnd = nextShift.timePointEnd;
        comparedShifts.allShifts.splice(i + 1, 1);
      }
    }
    // 3. subtract from whole time span
    for (var i = 0; i < comparedShifts.allShifts.length - 1; i++) {
      var shift = comparedShifts.allShifts[i],
        nextShift = comparedShifts.allShifts[i + 1];

      const shiftEndDate = new Date(shift.timePointEnd),
        nextShiftStartDate = new Date(nextShift.timePointStart);

      if (shiftEndDate.getTime() > nextShiftStartDate.getTime()) continue;
      const newShift: GanttDataShift = {
        id: 'compare-difference-row_' + Math.random() * Math.random(),
        timePointStart: shiftEndDate,
        timePointEnd: nextShiftStartDate,
        name: null,
        originName: '',
        tooltip: null,
        color: null,
        firstColor: null,
        secondColor: null,
        noRender: null,
        highlighted: null,
        editable: null,
        additionalData: null,
        modificationRestriction: null,
        opacity: null,
        weaken: null,
        symbols: null,
      };
      allShifts.push(newShift);
    }

    return allShifts;
  }
}

/**
 * Datacontainer to compare shifts rowwise.
 * @class
 * @constructor
 * @param {string} originRowId Row if of origin gantt data.
 * @param {GanttDataShift[]} [allShifts = []] Shift of row.
 */
export class CompareGanttsAnalyseRowsInput {
  originRowId: string;
  allShifts: GanttDataShift[];

  constructor(originRowId, allShifts) {
    this.originRowId = originRowId;
    this.allShifts = allShifts || [];
  }
}
