<nn-legend headline="@icons@">
  <div class="content">
    <div class="nn-legend-item-wrapper" *ngFor="let legendItem of mappedData">
      <div class="nn-icon-image" [style.backgroundImage]="'url(' + rest + legendItem.imageURL + ')'"></div>
      <div class="nn-legend-label" [matTooltipShowDelay]="1000" matTooltip="{{ legendItem.label }}">
        {{ legendItem.label }}
      </div>
    </div>
  </div>
</nn-legend>
