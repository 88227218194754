export enum PatternType {
  CIRCLES_1 = 'CIRCLES_1',
  CIRCLES_2 = 'CIRCLES_2',
  CIRCLES_3 = 'CIRCLES_3',
  CIRCLES_4 = 'CIRCLES_4',
  CIRCLES_5 = 'CIRCLES_5',
  CIRCLES_6 = 'CIRCLES_6',
  CIRCLES_7 = 'CIRCLES_7',
  CIRCLES_8 = 'CIRCLES_8',
  CIRCLES_9 = 'CIRCLES_9',
  DIAGONAL_STRIPE_1 = 'DIAGONAL_STRIPE_1',
  DIAGONAL_STRIPE_2 = 'DIAGONAL_STRIPE_2',
  DIAGONAL_STRIPE_3 = 'DIAGONAL_STRIPE_3',
  DIAGONAL_STRIPE_4 = 'DIAGONAL_STRIPE_4',
  DIAGONAL_STRIPE_5 = 'DIAGONAL_STRIPE_5',
  DIAGONAL_STRIPE_6 = 'DIAGONAL_STRIPE_6',
  DIAGONAL_STRIPE_7 = 'DIAGONAL_STRIPE_7',
  DIAGONAL_STRIPE_8 = 'DIAGONAL_STRIPE_8',
  DIAGONAL_STRIPE_9 = 'DIAGONAL_STRIPE_9',
  DOTS_1 = 'DOTS_1',
  DOTS_2 = 'DOTS_2',
  DOTS_3 = 'DOTS_3',
  DOTS_4 = 'DOTS_4',
  DOTS_5 = 'DOTS_5',
  DOTS_6 = 'DOTS_6',
  DOTS_7 = 'DOTS_7',
  DOTS_8 = 'DOTS_8',
  DOTS_9 = 'DOTS_9',
  DOTS_10 = 'DOTS_10',
  HORIZONTAL_STRIPE_1 = 'HORIZONTAL_STRIPE_1',
  HORIZONTAL_STRIPE_2 = 'HORIZONTAL_STRIPE_2',
  HORIZONTAL_STRIPE_3 = 'HORIZONTAL_STRIPE_3',
  HORIZONTAL_STRIPE_4 = 'HORIZONTAL_STRIPE_4',
  HORIZONTAL_STRIPE_5 = 'HORIZONTAL_STRIPE_5',
  HORIZONTAL_STRIPE_6 = 'HORIZONTAL_STRIPE_6',
  HORIZONTAL_STRIPE_7 = 'HORIZONTAL_STRIPE_7',
  HORIZONTAL_STRIPE_8 = 'HORIZONTAL_STRIPE_8',
  HORIZONTAL_STRIPE_9 = 'HORIZONTAL_STRIPE_9',
  VERTICAL_STRIPE_1 = 'VERTICAL_STRIPE_1',
  VERTICAL_STRIPE_2 = 'VERTICAL_STRIPE_2',
  VERTICAL_STRIPE_3 = 'VERTICAL_STRIPE_3',
  VERTICAL_STRIPE_4 = 'VERTICAL_STRIPE_4',
  VERTICAL_STRIPE_5 = 'VERTICAL_STRIPE_5',
  VERTICAL_STRIPE_6 = 'VERTICAL_STRIPE_6',
  VERTICAL_STRIPE_7 = 'VERTICAL_STRIPE_7',
  VERTICAL_STRIPE_8 = 'VERTICAL_STRIPE_8',
  VERTICAL_STRIPE_9 = 'VERTICAL_STRIPE_9',
  VERTICAL_STRIPE_10 = 'VERTICAL_STRIPE_10',
  HORIZONTAL_AND_DIAGONAL_STRIPE_1 = 'HORIZONTAL_AND_DIAGONAL_STRIPE_1',
  HORIZONTAL_AND_DIAGONAL_STRIPE_2 = 'HORIZONTAL_AND_DIAGONAL_STRIPE_2',
  HORIZONTAL_AND_DIAGONAL_STRIPE_3 = 'HORIZONTAL_AND_DIAGONAL_STRIPE_3',
  HORIZONTAL_AND_DIAGONAL_STRIPE_4 = 'HORIZONTAL_AND_DIAGONAL_STRIPE_4',
  HORIZONTAL_AND_DIAGONAL_STRIPE_5 = 'HORIZONTAL_AND_DIAGONAL_STRIPE_5',
  HORIZONTAL_AND_DIAGONAL_STRIPE_6 = 'HORIZONTAL_AND_DIAGONAL_STRIPE_6',
  HORIZONTAL_AND_DIAGONAL_STRIPE_7 = 'HORIZONTAL_AND_DIAGONAL_STRIPE_7',
  HORIZONTAL_AND_DIAGONAL_STRIPE_8 = 'HORIZONTAL_AND_DIAGONAL_STRIPE_8',
  HORIZONTAL_AND_DIAGONAL_STRIPE_9 = 'HORIZONTAL_AND_DIAGONAL_STRIPE_9',
  CROSSHATCH = 'CROSSHATCH',
  HOUNDSTOOTH = 'HOUNDSTOOTH',
  CARBON = 'CARBON',
  HEARTS = 'HEARTS',
}
