import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import * as L from 'leaflet';
import * as LM from 'leaflet.marker.slideto';
import { Subject, interval, of } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';
import { MapTemplate, MarkersTemplate, TransitionLine } from './map';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css'],
})
export class MapComponent implements OnInit, OnChanges, OnDestroy {
  @Input() mapTemplate: MapTemplate;
  @Input() legendVisible = true;
  @Input() minHeight = 500;
  @Input() playTick = 1000 * 60 * 60;
  @Output() onSearch: EventEmitter<string> = new EventEmitter();
  @Output() onContextMenuEvent: EventEmitter<any> = new EventEmitter();
  @ContentChild('mapLegendPagingContainer') mapLegendPagingContainer: any;
  @ContentChild('mapLegendItemsContainer') mapLegendItemsContainer: any;
  @ContentChild('mapLegendLayerSwitchContainer') mapLegendLayerSwitchContainer: any;

  options: any = {
    zoom: 10,
    center: L.latLng(52.520008, 13.404954),
  };
  // dont remove this
  private dummy = LM;

  public searchString = '';
  public playStartTime: Date;
  private searchTimeoutId: Subject<void> = new Subject<void>();
  private playIntervalId: Subject<void> = new Subject<void>();
  public onPlay;
  constructor() {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.playIntervalId.next();
    this.playIntervalId.complete();
    this.searchTimeoutId.next();
    this.searchTimeoutId.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateStyle();
  }

  public updateStyle() {
    this.checkTransitionlineStyle();
    this.handleLabelVisibility(this.mapTemplate.zoom > 8);
  }
  onMapReady(map: L.Map) {
    if (this.mapTemplate && map) {
      this.setupContextMenu(map);
      this.mapTemplate.setMapRef(map);
      this.options.zoom = this.mapTemplate.zoom;
      this.options.center = this.mapTemplate.center;
      this.options.maxZoom = this.mapTemplate.maxZoom;
      L.tileLayer(this.mapTemplate.restUrl, this.options).addTo(map);
      this.mapTemplate.getMarkers().forEach((marker) => {
        marker.buildMarker(map);
        // marker.markerRef.addTo(map);
      });
      this.mapTemplate.getTransitionLines().forEach((transitionLine) => {
        transitionLine.buildLine(map);
        // transitionLine.polyineDecoratorRef.addTo(map);
      });
      map.setMaxZoom(this.options.maxZoom);
      map.setView(this.options.center, this.options.zoom);
      this.updateStyle();
    }
  }

  private setupContextMenu(map: L.Map) {
    map.on('contextmenu', this.onContextMenu.bind(this));
  }

  private onContextMenu(ev) {
    this.onContextMenuEvent.emit(ev);
  }

  afterZoomEnd(event) {
    this.mapTemplate.zoom = event.sourceTarget._zoom;
    this.updateStyle();
  }

  private handleLabelVisibility(visible: boolean) {
    this.mapTemplate.getMarkers().forEach((marker) => {
      if (visible) {
        marker.markerLabelRef?.openTooltip();
      } else {
        marker.markerLabelRef?.closeTooltip();
      }
    });
  }

  private checkTransitionlineStyle() {
    this.mapTemplate.getTransitionLines().forEach((transitionLine) => {
      transitionLine.reducePixelSizeOfPattern(this.mapTemplate.zoom >= 25 ? 20 : this.mapTemplate.zoom);
      transitionLine.replaceDecorator(this.mapTemplate.getMapRef());
    });
  }

  public handleLegendClick(marker: MarkersTemplate) {
    marker.toogleState(this.mapTemplate.getMapRef());
  }
  public handleLegendClickLine(line: TransitionLine) {
    line.toogleState(this.mapTemplate.getMapRef());
  }

  public toggleStateOfAllElments() {
    this.mapTemplate.getMarkers().forEach((marker) => {
      marker.toogleState(this.mapTemplate.getMapRef());
    });
    this.mapTemplate.getTransitionLines().forEach((lines) => {
      lines.toogleState(this.mapTemplate.getMapRef());
    });

    this.updateStyle();
  }

  public handlePlaytime(value) {
    const time = 1000 * 60 * 60 * value;
    this.playTick = time;
    this.handlePlay();
  }

  public getPlayBtnDisabled() {
    if (this.mapTemplate) {
      this.mapTemplate.getMarkers().filter((marker) => marker.startPlayTime);
    }
    return false;
  }

  public handlePlay() {
    this.onPlay = !this.onPlay;
    const map = this.mapTemplate.getMapRef();

    if (this.onPlay) {
      this.mapTemplate.getMarkers().forEach((marker) => {
        marker.markerRef.removeFrom(map);
      });
      this.mapTemplate.getTransitionLines().forEach((line) => {
        line.polyineDecoratorRef.removeFrom(map);
        line.polyineRef.removeFrom(map);
      });
      this.playStartTime = new Date(this.mapTemplate.getMarkers()[0].startPlayTime.getTime() - this.playTick);

      interval(1000)
        .pipe(takeUntil(this.playIntervalId))
        .subscribe(() => {
          const runnableMarker = this.mapTemplate
            .getMarkers()
            .filter(
              (marker) =>
                marker.startPlayTime &&
                marker.startPlayTime <= this.playStartTime &&
                !marker.isPlayed() &&
                !marker.isRunning()
            );
          runnableMarker.forEach((marker) => {
            const playLines = this.mapTemplate
              .getTransitionLines()
              .filter((line) => line.sourceLocationId === marker.id);
            playLines.forEach((line) => {
              line.polyineDecoratorRef.addTo(map);
              line.polyineRef.addTo(map);
            });
            marker.startFollowMovement(map, this.playTick);
          });
          this.playStartTime = new Date(this.playStartTime.getTime() + this.playTick);
        });
    } else {
      this.playIntervalId.next();
      this.playStartTime = null;
      const runingMarkers = this.mapTemplate.getMarkers().filter((marker) => marker.isRunning());
      runingMarkers.forEach((marker) => {
        marker.stopPlaying(map);
        marker.setReady();
      });

      this.mapTemplate.getMarkers().forEach((marker) => {
        marker.markerRef.removeFrom(map);
        marker.setReady();
      });
      this.mapTemplate.getTransitionLines().forEach((line) => {
        line.polyineDecoratorRef.removeFrom(map);
        line.polyineRef.removeFrom(map);
      });

      this.mapTemplate.getMarkers().forEach((marker) => {
        marker.markerRef.addTo(map);
      });
      this.mapTemplate.getTransitionLines().forEach((line) => {
        line.polyineDecoratorRef.addTo(map);
        line.polyineRef.addTo(map);
      });
    }
  }

  public getPlayStartTime() {
    const day = this.playStartTime.getDate();
    const month = this.playStartTime.getMonth() + 1;
    const year = this.playStartTime.getFullYear();
    const hours = this.playStartTime.getHours();
    const minutes = this.playStartTime.getMinutes();
    const seconds = this.playStartTime.getSeconds();
    return `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}:${
      seconds < 10 ? '0' + seconds : seconds
    } ${day < 10 ? '0' + day : day}.${month < 10 ? '0' + month : month}.${year}`;
  }

  public handleSearch(event) {
    this.searchTimeoutId.next();
    of(null)
      .pipe(delay(500), takeUntil(this.searchTimeoutId))
      .subscribe(() => {
        this.onSearch.emit(event.target.value);
      });
  }

  public stopPropagation(event) {
    event.stopPropagation();
  }
}
