/**
 * Represents a pattern of strokes.
 */
export class GanttStrokePattern {
  private _id: string;
  private _filledArea: number;
  private _unfilledArea: number;

  constructor(id, filledArea, unfilledArea) {
    this._id = id || '';
    this._filledArea = filledArea || 1;
    this._unfilledArea = unfilledArea || 1;
  }

  /**
   * Returns the ID of the stroke pattern.
   * @returns {string}
   */
  getId() {
    return this._id;
  }

  /**
   * Returns a number which specifies a distance for the filled area of the stroke.
   * @returns {number}
   */
  getFilledArea() {
    return this._filledArea;
  }

  /**
   * Returns a number which specifies a distance for the unfilled area of the stroke.
   * @returns {number}
   */
  getUnfilledArea() {
    return this._unfilledArea;
  }
}
