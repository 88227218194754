import * as d3 from 'd3';
import { Subject, fromEvent } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NodeProportionsState } from '../html-structure/node-proportion-state/node-proportion-state.js';
import { ShiftBuilder } from '../shifts/shift-builder.js';
import { VerticalScrollHandler } from '../vertical-scroll/vertical-scroll-handler.js';
import { GanttXAxis } from '../x-axis/x-axis.js';

/**
 * Handles panning in shift area
 */
export class PanningHandler {
  private _canvas: d3.Selection<any, any, null, undefined>;
  private _xAxisBuilder: GanttXAxis;
  private _verticalScrollHandler: VerticalScrollHandler;
  private _nodeProportionHandler: NodeProportionsState;
  private _shiftBuilder: ShiftBuilder;
  private _isPanning: boolean;
  private _startPos: any;
  private _onDestroySubject = new Subject<void>();

  constructor(canvas, xAxisBuilder, verticalScrollHandler, nodeProportionState, shiftBuilder) {
    this._canvas = canvas;
    this._xAxisBuilder = xAxisBuilder;
    this._verticalScrollHandler = verticalScrollHandler;
    this._nodeProportionHandler = nodeProportionState;
    this._shiftBuilder = shiftBuilder;

    this._isPanning = false;
    this._startPos = { x: 0, y: 0 };

    this._listenToClick();
  }

  public destroy(): void {
    this._onDestroySubject.next();
  }

  /**
   * Registers event listeners to handle panning
   */
  _listenToClick() {
    this._canvas.node().addEventListener('mousedown', (e) => {
      if (e.button === 1) {
        this._handlePanningStart(e);
        e.preventDefault();
      }
    });

    this._shiftBuilder.shiftOnMiddleClick().subscribe((event) => this._handlePanningStart(event));

    fromEvent(window, 'mousemove')
      .pipe(takeUntil(this._onDestroySubject.asObservable()))
      .subscribe(this._handlePanning.bind(this));

    fromEvent(window, 'mouseup')
      .pipe(takeUntil(this._onDestroySubject.asObservable()))
      .subscribe(this._onMouseUp.bind(this));
  }

  /**
   * Handles mouse up event
   * @param {MouseEvent} e
   */
  _onMouseUp(e) {
    if (e.button === 1) {
      this._handlePanningEnd(e);
      e.preventDefault();
    }
  }

  /**
   * Scrolls shift canvas by the given coordinates.
   * @param {number} offsetX offset in px
   * @param {number} offsetY offset in px
   */
  _scroll(offsetX, offsetY) {
    if (offsetX) {
      this._xAxisBuilder.scrollHorizontal(-offsetX);
    }
    if (offsetY) {
      this._verticalScrollHandler.setVerticalScrollPos(this._nodeProportionHandler.getScrollTopPosition() - offsetY);
    }
  }

  /**
   * Handles the panning start action.
   * @param {MouseEvent} e mouse event
   */
  _handlePanningStart(e) {
    this._isPanning = true;
    this._canvas.style('cursor', 'move');
    this._startPos.x = e.clientX;
    this._startPos.y = e.clientY;
  }

  /**
   * Handles panning during drag
   * @param {MouseEvent} e mouse event
   */
  _handlePanning(e) {
    if (this._isPanning) {
      const offsetX = e.clientX - this._startPos.x;
      const offsetY = e.clientY - this._startPos.y;
      this._startPos.x = e.clientX;
      this._startPos.y = e.clientY;
      this._scroll(offsetX, offsetY);
    }
  }

  /**
   * Handles the panning end action.
   *
   */
  _handlePanningEnd(e) {
    this._isPanning = false;
    this._canvas.style('cursor', undefined);
    this._xAxisBuilder.setZoomLevel(1); // fit to time grid
  }
}
