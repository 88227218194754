import { inject, Injectable } from '@angular/core';
import { ConfigService } from '@core/config/config.service';
import { RxState } from '@rx-angular/state';
import { selectSlice } from '@rx-angular/state/selections';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ExperimentService2 } from '../data/experiment/experiment.service';
import { HorizonService } from '../horizonselecter/horizon.service';
import { LoadingParams } from './model.data';
import { ModelService } from './model.service';

interface GlobalState {
  loadingModel: LoadingParams;
}

@Injectable({
  providedIn: 'root',
})
export class LoadingStateService extends RxState<GlobalState> {
  private readonly modelService = inject(ModelService);
  private readonly experimentService = inject(ExperimentService2);
  private readonly horizonService = inject(HorizonService);
  private readonly configService = inject(ConfigService);

  private readonly customLoading = new BehaviorSubject<LoadingParams>({ isLoading: false });

  private readonly loading$ = combineLatest([
    this.modelService.getLoadingCount().pipe(startWith(0)),
    this.experimentService.getLoadingCount().pipe(startWith(0)),
    this.horizonService.getLoadingCount().pipe(startWith(0)),
    this.customLoading,
  ]).pipe(
    map(([modelCount, expCount, horCount, customLoading]): LoadingParams => {
      if (customLoading.isLoading) {
        return customLoading;
      }

      if (!this.configService.access().showInitialLoadingAnimation) {
        return { isLoading: false };
      }
      const totalCount = modelCount + expCount + horCount;
      return totalCount === 0 ? { isLoading: false } : { isLoading: true };
    })
  );

  loadingModel$ = this.select(
    selectSlice(['loadingModel']),
    map(({ loadingModel }) => loadingModel)
  );

  constructor() {
    super();
    this.set({ loadingModel: { isLoading: false } });
    this.connect('loadingModel', this.loading$);
  }

  public triggerCustomLoading(loading: LoadingParams) {
    this.customLoading.next(loading);
    if (loading.timeout) {
      setTimeout(() => this.customLoading.next({ isLoading: false }), loading.timeout);
    }
  }
}
