<saxms-lightbox [contentBorder]="false">
  <div class="headline">
    {{ '@icons@' | translate | capitalize }}
  </div>
  <div class="content">
    <div class="nn-icon-edit-lightbox-wrapper">
      <div class="nn-icon-edit-lightbox-list">
        <!-- <p-accordion [styleClass]="'nn-icon-edit-lightbox-list-content'">
                    <p-accordionTab *ngFor="let attributeItem of attributeIconlist" [header]="attributeItem.headline">
                        <mat-button-toggle-group [vertical]="true">
                            <mat-button-toggle *ngFor="let iconItem of attributeItem.icons" (click)="selectedAttributeItem = iconItem">
                                <div class="nn-icon-edit-lightbox-list-icon" [style.backgroundImage]="'url(' + iconItem.imageURL + ')'"></div>
                                <span>{{ iconItem.label }}</span>
                            </mat-button-toggle>
                        </mat-button-toggle-group>
                    </p-accordionTab>
                </p-accordion>-->
      </div>
      <div class="nn-icon-edit-lightbox-showroom">
        <div class="nn-icon-edit-lightbox-upload-icon">
          <div>
            <div class="choose-file-headline">{{ '@uploadNewIcon@' | translate }}</div>
            <input type="file" accept=".jpg, .png, .jpeg, .gif" (change)="importFile($event)" />
          </div>
        </div>
        <div class="nn-icon-edit-lightbox-selectable-icons">
          <div
            class="nn-icon-edit-lightbox-image-wrapper"
            *ngFor="let imgUrl of allSelectableIcons"
            [style.backgroundImage]="'url(' + imgUrl + ')'"
            (click)="onSelectIcon(imgUrl)"
          ></div>
        </div>
      </div>
    </div>
  </div>
  <div class="actionbarBottom">
    <button mat-button (click)="onClose()">
      {{ '@ready@' | translate }}
    </button>
  </div>
</saxms-lightbox>
