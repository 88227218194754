import { BestGantt } from '@gantt/public-api';
import domtoimage from 'dom-to-image-more';
import { from, Observable } from 'rxjs';

export interface IGanttImageGenratorTimePeriod {
  start: Date;
  end: Date;
}

export class GanttImageGenerator {
  private ganttRef: BestGantt;

  constructor(ganttRef: BestGantt) {
    this.ganttRef = ganttRef;
  }

  /**
   * Genrates an image of the referenced gantt in its current state.
   * @return Data URL to the generated image for further use.
   */
  public generateImage(): Observable<string> {
    if (!this.ganttRef) return null;
    return from(domtoimage.toJpeg(this.ganttRef.getParentNode()) as string);
  }

  /**
   * Generates an image of the referenced gantt in the specified time period.
   * @param timePeriod Time period of the referenced gantt to generate an image for.
   * @returns Data URL to the generated image for further use.
   */
  public generateImageOfTimePeriod(timePeriod: IGanttImageGenratorTimePeriod): Observable<string> {
    this.ganttRef.getXAxisBuilder().zoomToTimeSpan(timePeriod.start, timePeriod.end, false);
    return this.generateImage();
  }

  /**
   * Generates images of multiple time periods.
   * @param {IGanttImageGenratorTimePeriod[]} timePeriods Array of time periods to generate images for.
   * @param {string[]} [additionalImgs=null] Array of additional base64-encoded images to append on generated array.
   * @returns {string[]} Observable returning an array of base64-encoded images (generated & appended).
   */
  public generateImagesOfTimePeriods(
    timePeriods: IGanttImageGenratorTimePeriod[],
    additionalImgs: string[] = null
  ): Observable<string[]> {
    if (!this.ganttRef) return null;
    const generateImages = async function (ganttRef: BestGantt): Promise<string[]> {
      const dataUrls: string[] = [];
      for (const timePeriod of timePeriods) {
        ganttRef.getXAxisBuilder().zoomToTimeSpan(timePeriod.start, timePeriod.end, false);
        const result = await domtoimage.toJpeg(ganttRef.getParentNode());
        dataUrls.push(result);
      }
      if (additionalImgs) dataUrls.push(...additionalImgs);
      return dataUrls;
    };
    return from(generateImages(this.ganttRef));
  }

  public setGanttRef(ganttRef: BestGantt): void {
    this.ganttRef = ganttRef;
  }
}
