import { ERequestMethod } from '@app-modeleditor/request.service';
import { Message } from '@core/message/message';
import { Resource } from 'frontend/src/dashboard/model/resource/resource';
import { Observable } from 'rxjs';
import { EActionType } from './action-type.enum';
import { IActionParams, IParameter } from './parameter.interface';
import { ETemplateHandlingType } from './template-handling-type.enum';
import { EValidationRules } from './validation-rules.enum';

export type ConditionType = 'USER_QUERY' | undefined;

export class Action extends Resource {
  private callOnReloadingPage: boolean;
  private actionType: EActionType;
  private actionMethod: ERequestMethod;
  private actionUrl: string;
  private asyncCall: boolean;
  private openWithCreateNameDialog: boolean;
  private openWithConfirmDeleteDialog: boolean;
  private templateHandlingType: ETemplateHandlingType;
  private localConditionErrorMsg: string[];
  private actionPlugInTargetIds: string[];
  private pluginId: string;
  private parameterSelectors: IParameter;
  private postParameterSelector: string;
  private affectsLocalElement: string;
  private localCondition: string;
  public dialogResult: any;
  private params: IActionParams;
  private message: string;
  private messages: Message[];
  private validationRules: EValidationRules[];
  private filterId: string;
  private uploadID: string;
  private cb: (event: MouseEvent) => Observable<any>;
  private clientId: string;
  private authority: string;
  private redirectUri: string;
  private username: string;
  private conditionType?: ConditionType;
  private trueActions?: Action[];
  private falseActions?: Action[];
  private userQueryMessage: string;

  public getClientId(): string {
    return this.clientId;
  }
  public setClientId(clientId: string): this {
    this.clientId = clientId;
    return this;
  }

  public getUsername(): string {
    return this.username;
  }
  public setUsername(username: string): this {
    this.username = username;
    return this;
  }

  public getAuthority(): string {
    return this.authority;
  }
  public setAuthority(authority: string): this {
    this.authority = authority;
    return this;
  }

  public getRedirectUri(): string {
    return this.redirectUri;
  }
  public setRedirectUri(redirectUri: string): this {
    this.redirectUri = redirectUri;
    return this;
  }

  constructor(cb: (event: MouseEvent) => Observable<any> = null) {
    super();
    if (cb) {
      this.setCb(cb);
    }
  }

  public isCallOnReloadingPage(): boolean {
    return typeof this.callOnReloadingPage === 'boolean' ? this.callOnReloadingPage : true;
  }

  public setCallOnReloadingPage(callOnReloadingPage: boolean): this {
    this.callOnReloadingPage = callOnReloadingPage;
    return this;
  }

  public getUploadID(): string {
    return this.uploadID;
  }

  public setUploadID(uploadID: string): this {
    this.uploadID = uploadID;
    return this;
  }

  public getFilterId(): string {
    return this.filterId;
  }

  public setFilterId(filterId: string): this {
    this.filterId = filterId;
    return this;
  }

  public getValidationRules(): EValidationRules[] {
    return this.validationRules || [];
  }

  public setValidationRules(validationRules: EValidationRules[]): this {
    this.validationRules = validationRules;
    return this;
  }

  public getMessages(): Message[] {
    return this.messages || [];
  }

  public setMessages(messages: Message[]): this {
    this.messages = messages;
    return this;
  }

  public getMessage(): string {
    return this.message;
  }

  public setMessage(message: string): this {
    this.message = message;
    return this;
  }

  public getCb(): (event: MouseEvent) => Observable<any> {
    return this.cb;
  }

  public getParams(): IActionParams {
    return this.params;
  }

  public setParams(params: IActionParams): this {
    this.params = params;
    return this;
  }

  public setCb(cb: (event: MouseEvent) => Observable<any>): this {
    this.setActionType(EActionType.LOCAL);
    this.cb = cb;
    return this;
  }

  public getLocalCondition(): string {
    return this.localCondition;
  }

  public setLocalCondition(localCondition: string): this {
    this.localCondition = localCondition;
    return this;
  }

  public getLocalAffectedElement(): string {
    return this.affectsLocalElement;
  }

  public setAffectsLocalElement(affectsLocalElement: string): this {
    this.affectsLocalElement = affectsLocalElement;
    return this;
  }

  public getPostParameterSelector(): string {
    return this.postParameterSelector;
  }

  public setPostParameterSelector(postParameterSelector: string): this {
    this.postParameterSelector = postParameterSelector;
    return this;
  }

  public getParameterSelectors(): IParameter {
    return this.parameterSelectors;
  }

  public setParameterSelectors(parameterSelectors: IParameter): this {
    this.parameterSelectors = parameterSelectors;
    return this;
  }

  public getLocalConditionErrorMessage(index: number): string {
    return !isNaN(index) && this.localConditionErrorMsg ? this.localConditionErrorMsg[index] : null;
  }

  public getLocalConditionErrorMessages(): string[] {
    return this.localConditionErrorMsg || [];
  }

  public setLocalConditionErrorMsg(localConditionErrorMsg: string[]): this {
    this.localConditionErrorMsg = localConditionErrorMsg;
    return this;
  }

  public getActionPlugInTargetIds(): string[] {
    return this.actionPlugInTargetIds || [];
  }

  public setActionPlugInTargetIds(actionPlugInTargetIds: string[]): this {
    this.actionPlugInTargetIds = actionPlugInTargetIds;
    return this;
  }

  public getPluginId(): string {
    return this.pluginId;
  }

  public setPluginId(pluginId: string): this {
    this.pluginId = pluginId;
    return this;
  }

  public getActionType(): EActionType {
    return this.actionType;
  }

  public setActionType(actionType: EActionType): this {
    this.actionType = actionType;
    return this;
  }

  public getActionMethod(): ERequestMethod {
    if (!this.actionMethod) {
      switch (this.actionType) {
        case EActionType.GET_CALL:
          return ERequestMethod.GET;
      }
    }
    return this.actionMethod;
  }

  public setActionMethod(actionMethod: ERequestMethod): this {
    this.actionMethod = actionMethod;
    return this;
  }

  public getActionUrl(): string {
    return this.actionUrl;
  }

  public setActionUrl(actionUrl: string): this {
    this.actionUrl = actionUrl;
    return this;
  }

  public isAsyncCall(): boolean {
    return this.asyncCall;
  }

  public setAsyncCall(asyncCall: boolean): this {
    this.asyncCall = asyncCall;
    return this;
  }

  public isOpenWithCreateNameDialog(): boolean {
    return this.openWithCreateNameDialog;
  }

  public setOpenWithCreateNameDialog(openWithCreateNameDialog: boolean): this {
    this.openWithCreateNameDialog = openWithCreateNameDialog;
    return this;
  }

  public isOpenWithConfirmDeleteDialog(): boolean {
    return this.openWithConfirmDeleteDialog;
  }

  public setOpenWithConfirmDeleteDialog(openWithConfirmDeleteDialog: boolean): this {
    this.openWithConfirmDeleteDialog = openWithConfirmDeleteDialog;
    return this;
  }

  public getTemplateHandlingType(): ETemplateHandlingType {
    return this.templateHandlingType;
  }

  public setTemplateHandlingType(templateHandlingType: ETemplateHandlingType): this {
    this.templateHandlingType = templateHandlingType;
    return this;
  }

  public getTrueActions(): Action[] {
    return this.trueActions;
  }

  public setTrueActions(val: Action[]): this {
    this.trueActions = val;
    return this;
  }

  public getFalseActions(): Action[] {
    return this.falseActions;
  }

  public setFalseActions(val: Action[]): this {
    this.falseActions = val;
    return this;
  }

  public getConditionType(): ConditionType {
    return this.conditionType;
  }

  public setConditionType(val: ConditionType): this {
    this.conditionType = val;
    return this;
  }

  public getUserQueryMessage(): string {
    return this.userQueryMessage;
  }

  public setUserQueryMessage(val: string): this {
    this.userQueryMessage = val;
    return this;
  }
}
