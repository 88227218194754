import {
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { DateUtils } from '@app-modeleditor/utils/date-utils';
import { TemplateService } from '@app-modeleditor/utils/template.service';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { CalendarOptions } from '@fullcalendar/core';
import allLocales from '@fullcalendar/core/locales-all';
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import interactionPlugin from '@fullcalendar/interaction'; // a plugin!
import { TranslateService } from '@ngx-translate/core';
import { of, Subject } from 'rxjs';
import { delay } from 'rxjs/operators';
import { Action } from '../button/action/action';
import { Button } from '../button/button';
import { EDisplayOrientation } from '../content/content-element/display-orientation.enum';
import { EntryElement } from '../entry-collection/entry-element';
import { EFieldType } from '../entry-collection/field-type.enum';
import { TemplateComponent } from '../template/template.component';
import { EntryCollection } from './../entry-collection/entry-collection';
import { EntryElementValue } from './../entry-collection/entry-element-value';
import { ColumnLayout } from './../structure/column-layout/column-layout';
import { EventRange } from './event-range';
import { RangePicker as TemplateCalendar } from './range-picker';
import { ECalendarRestriction } from './range-picker-restriction.enum';

@Component({
  selector: 'template-range-picker',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
})
export class RangePickerComponent implements OnDestroy, OnInit {
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  private actionsInstance: ComponentRef<TemplateComponent>;
  @Input() template: TemplateCalendar;
  @ViewChild('vc', { read: ViewContainerRef }) set vcActions(vc: ViewContainerRef) {
    of(null)
      .pipe(delay(0))
      .subscribe(() => {
        if (!vc) {
          return;
        }
        if (this.actionsInstance) {
          this.actionsInstance.destroy();
        }
        const ref: ComponentRef<TemplateComponent> = this.templateApi.loadComponent(
          TemplateComponent,
          vc,
          this.resolver
        );
        ref.instance.templateNode = this.entryC;
        this.actionsInstance = ref;
      });
  }
  @ViewChild('calendar') set onCalendarReady(calendarComponent: FullCalendarComponent) {
    of(null)
      .pipe(delay(0))
      .subscribe(() => {
        this.applyValues(this.template.getValue());
        this.calendarCmp = calendarComponent;
        this.calendarCmp.getApi().gotoDate(this.template.getValue().getStart());
        this.refresh(true);
      });
  }
  @Output() onChanges: EventEmitter<any> = new EventEmitter();

  calendarOptions: CalendarOptions = {
    unselectAuto: false,
    plugins: [dayGridPlugin, interactionPlugin],
    locales: allLocales,
    headerToolbar: false,
    locale: 'de',
    initialView: 'dayGridMonth',
  };

  dateClicked(info): void {
    const ref: Date = new Date();
    const start: Date = this.template.getValue().getStart() || new Date(ref);
    const end: Date = this.template.getValue().getEnd() || new Date(ref);

    if (end.getTime() === start.getTime()) {
      // check if end is smaller than start, then switch
      if (info.date.getTime() < this.template.getValue().getStart().getTime()) {
        const newEnd: Date = new Date(this.template.getValue().getStart());
        this.template.getValue().setEnd(newEnd);
        this.template.getValue().setStart(new Date(info.date));
      } else {
        this.template.getValue().setEnd(new Date(info.date));
      }
    } else {
      this.template.getValue().setStart(new Date(info.date));
      this.template.getValue().setEnd(new Date(info.date));
      this.calendarCmp.getApi().gotoDate(this.template.getValue().getStart());
    }

    this.refresh();
  }

  get defaultDate(): string {
    return new Date().toISOString();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();

    if (this.actionsInstance) {
      this.actionsInstance.destroy();
    }
  }

  events = [];
  calendarCmp: FullCalendarComponent;
  selectedValues: { from: number; to: number } = {
    from: new Date().getTime(),
    to: new Date().getTime(),
  }; // title picker
  leftBtn: Button;
  rightBtn: Button;
  monthSelect: EntryElement;
  yearSelect: EntryElement;
  weekSelect: EntryElement;

  constructor(
    private tranlsate: TranslateService,
    private templateApi: TemplateService,
    private resolver: ComponentFactoryResolver
  ) {
    this.leftBtn = new Button()
      .setPlaceholder('BUTTON.prev')
      .chainActions(new Action().setCb(() => of(this.prev())))
      .setIcon('keyboard_arrow_left');
    this.rightBtn = new Button()
      .setPlaceholder('BUTTON.next')
      .chainActions(new Action().setCb(() => of(this.next())))
      .setIcon('keyboard_arrow_right');

    this.monthSelect = new EntryElement().setName('CALENDAR.month').setFieldType(EFieldType.COMBO_BOX);
    const v1 = [];
    for (let i = 0; i < 12; i++) {
      v1.push(
        new EntryElementValue()
          .setValue(this.tranlsate.instant('DATES.monthNames')[i])
          .setId(i.toString())
          .setName(this.tranlsate.instant('DATES.monthNames')[i])
      );
    }
    this.monthSelect
      .setValue(new EntryElementValue().setAvailableValues(v1))
      .onChanges((data) => this.onMonthChanged());
    // this.monthSelect.onValuesChanged().pipe(takeUntil(this.ngUnsubscribe)).subscribe((v: EntryElementValue) => of(this.onMonthChanged()));

    this.yearSelect = new EntryElement().setName('CALENDAR.year').setFieldType(EFieldType.COMBO_BOX);
    this.yearSelect.setValue(new EntryElementValue()).onChanges((data) => this.onYearChanged());
    // this.yearSelect.onValuesChanged().pipe(takeUntil(this.ngUnsubscribe)).subscribe((v: EntryElementValue) => of(this.onYearChanged()));

    this.weekSelect = new EntryElement().setDisabled(true).setName('CALENDAR.week').setFieldType(EFieldType.COMBO_BOX);
    const v3 = [];
    for (let i = 1; i < 54; i++) {
      v3.push(new EntryElementValue().setId(i.toString()).setName(i.toString()));
    }
    this.weekSelect.setValue(new EntryElementValue().setAvailableValues(v3)).onChanges((data) => this.onWeeksChanged());
    // this.weekSelect.onValuesChanged().pipe(takeUntil(this.ngUnsubscribe)).subscribe((v: EntryElementValue) => of(this.onWeeksChanged.bind(this)));

    this.entryC = new EntryCollection()
      .setDisplayOrientation(EDisplayOrientation.VERTICAL)
      .setLayout(
        new ColumnLayout().setColumnCount(5).setContent({
          0: [this.leftBtn.getId()],
          1: [this.yearSelect.getId()],
          2: [this.monthSelect.getId()],
          3: [this.weekSelect.getId()],
          4: [this.rightBtn.getId()],
        })
      )
      .setEntryElements([this.leftBtn, this.yearSelect, this.monthSelect, this.weekSelect, this.rightBtn]);
  }

  ngOnInit(): void {
    const v2 = [];
    for (
      let i = this.template.getStartRangeDate().getFullYear();
      i < this.template.getEndRangeDate().getFullYear();
      i++
    ) {
      v2.push(new EntryElementValue().setValue(i.toString()).setId(i.toString()).setName(i.toString()));
    }
    this.yearSelect.getValue<EntryElementValue>().setAvailableValues(v2);
  }

  applyValues(range: EventRange) {
    const startDate: Date = range.getStart() || new Date();
    const year: EntryElementValue = this.yearSelect
      .getValue<EntryElementValue>()
      .getAvailableValues<EntryElementValue[]>()
      .find((e: EntryElementValue) => e.getId() === startDate.getFullYear().toString());
    this.yearSelect.getValue<EntryElementValue>().setValue(year);

    const month: EntryElementValue = this.yearSelect
      .getValue<EntryElementValue>()
      .getAvailableValues<EntryElementValue[]>()
      .find((e: EntryElementValue) => e.getId() === startDate.getMonth().toString());
    this.monthSelect.getValue<EntryElementValue>().setValue(month);

    const week: EntryElementValue = this.yearSelect
      .getValue<EntryElementValue>()
      .getAvailableValues<EntryElementValue[]>()
      .find((e: EntryElementValue) => e.getId() === DateUtils.getNumberOfWeek(startDate).toString());
    this.weekSelect.getValue<EntryElementValue>().setValue(week);
  }

  /**
   * checks restrictions for viewport changes
   * @returns void
   */
  private checkRestrictions(start: Date, end: Date, month: number, year: number, weekNo: number): void {
    switch (this.template.getRestriction()) {
      case ECalendarRestriction.MONTH:
        start = new Date(year, DateUtils.clampMonth(month), 1);
        end = new Date(start);
        end.setMonth(DateUtils.clampMonth(end.getMonth() + 1));
        end.setDate(0);
        end.setFullYear(year);
        break;
      case ECalendarRestriction.YEAR:
        start.setFullYear(year);
        start.setMonth(0);
        start.setDate(1);
        end.setFullYear(year + 1);
        end.setMonth(12);
        end.setDate(0);
        end.setFullYear(year);
        break;
      case ECalendarRestriction.WEEK:
        start = DateUtils.setMonday(DateUtils.getDateByWeek(weekNo, year).start);
        end = new Date(start);
        end.setDate(end.getDate() + 6);
        break;
      case ECalendarRestriction.DAY:
        break;
      default:
        return;
    }
    this.template.getValue().setStart(new Date(start.getTime()));
    this.template.getValue().setEnd(new Date(end.getTime()));
  }

  /**
   * handles changes on week
   * @returns void
   */
  private onWeeksChanged(): void {
    const e: EntryElementValue = this.weekSelect.getValue<EntryElementValue>().getValue<EntryElementValue>();
    if (!e) {
      return;
    }
    const yearId: string = this.yearSelect.getValue<EntryElementValue>().getValue<EntryElementValue>().getId();
    const weekNo: number = parseInt(e.getId(), 10);
    const startDate: Date = DateUtils.getDateByWeek(weekNo, parseInt(yearId, 10)).start;

    this.calendarCmp.getApi().gotoDate(startDate);

    this.refresh();
  }

  /**
   * handles changes on year
   * @returns void
   */
  private onYearChanged(): void {
    const e: EntryElementValue = this.yearSelect.getValue<EntryElementValue>().getValue<EntryElementValue>();
    if (!e) {
      return;
    }
    const year: number = parseInt(e.getId(), 10);
    const startDate: Date = new Date(this.calendarCmp.getApi().getDate());
    startDate.setFullYear(year);

    this.calendarCmp.getApi().gotoDate(startDate);

    this.refresh();
  }

  /**
   * handles changes on month
   * @returns void
   */
  private onMonthChanged(): void {
    const e: EntryElementValue = this.monthSelect.getValue<EntryElementValue>().getValue<EntryElementValue>();

    if (!e) {
      return;
    }
    const month: number = parseInt(e.getId(), 10);
    const startDate: Date = new Date(this.calendarCmp.getApi().getDate());
    startDate.setMonth(month, 1);
    this.calendarCmp.getApi().gotoDate(startDate);

    this.refresh();
  }

  /**
   * handles datepicker changes
   * @returns void
   */
  public onSelectedValuesChanged(event: number[]): void {
    this.template.getValue().setStart(event[0] ? new Date(event[0]) : null);
    this.template.getValue().setEnd(event[0] || event[1] ? new Date(event[1] || event[0]) : null);
    this.refresh();
  }

  entryC: EntryCollection;

  /**
   * selects range from template dates
   * @returns void
   */
  private refresh(skipEmit?: boolean): void {
    const year: number = (this.calendarCmp ? this.calendarCmp.getApi().getDate() : new Date()).getFullYear();
    const month: number = (this.calendarCmp ? this.calendarCmp.getApi().getDate() : new Date()).getMonth();
    const weekNo: number = DateUtils.getNumberOfWeek(
      this.calendarCmp ? this.calendarCmp.getApi().getDate() : new Date()
    );
    let start: Date = this.calendarCmp.getApi().getDate() || this.template.getValue().getStart() || new Date();
    let end: Date = this.template.getValue().getEnd() || new Date();

    // check if restrictions overwrite something
    this.checkRestrictions(start, end, month, year, weekNo);
    start = this.template.getValue().getStart();
    end = this.template.getValue().getEnd();

    // highlight current selection in calendar
    this.highlight();

    // set values of range picker
    this.selectedValues = {
      from: start.getTime(),
      to: end.getTime(),
    };

    // set month picker
    const v1: EntryElementValue = this.monthSelect
      .getValue<EntryElementValue>()
      .getAvailableValues<EntryElementValue[]>()
      .find((e: EntryElementValue) => e.getId() === month.toString());

    this.monthSelect.getValue<EntryElementValue>().setValue(v1);

    // set year picker
    const v2: EntryElementValue = this.yearSelect
      .getValue<EntryElementValue>()
      .getAvailableValues<EntryElementValue[]>()
      .find((e: EntryElementValue) => e.getId() === year.toString());

    this.yearSelect.getValue<EntryElementValue>().setValue(v2);

    // set week of the picker
    const v3: EntryElementValue = this.weekSelect
      .getValue<EntryElementValue>()
      .getAvailableValues<EntryElementValue[]>()
      .find((e: EntryElementValue) => e.getId() === DateUtils.getNumberOfWeek(start).toString());

    this.weekSelect.getValue<EntryElementValue>().setValue(v3);

    if (!skipEmit) {
      this.template.setValue(new EventRange().setStart(new Date(start)).setEnd(new Date(end)));
    }

    this.onChanges.emit();
  }

  highlight() {
    // highlight range
    if (!this.calendarCmp) {
      return;
    }
    // highlight current selection in calendar
    const newEnd: Date = new Date(this.template.getValue().getEnd() || new Date());
    newEnd.setDate(newEnd.getDate() + 1);
    const start: Date = new Date(this.template.getValue().getStart() || new Date());
    this.calendarCmp.getApi().gotoDate(start);
    this.calendarCmp.getApi().select(start, newEnd);
  }

  private next(): void {
    this.calendarCmp.getApi().next();
    this.refresh();
  }
  private prev(): void {
    this.calendarCmp.getApi().prev();
    this.refresh();
  }
}
