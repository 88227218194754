import * as d3 from 'd3';
import { GanttEdgeHandler } from './edge-executer';

/**
 * Responsible to render connection lines between shift elements inside gantt.
 * @keywords builder, egde, plugin, canvas, render, update, elements
 * @plugin edges
 * @param {HTMLNode} parentNode
 */
export class GanttEdgeBuilder {
  parentNode: any;
  executer: GanttEdgeHandler;
  canvas: d3.Selection<any, any, null, undefined>;
  def: d3.Selection<SVGDefsElement, any, null, undefined>;
  lineGenerator: any;
  lineNodeDistance: number;
  strokeShiftMouseOverWidth: number;
  edgeDefaultColor: string;
  arrowHeadSuffixStart: string;
  arrowHeadSuffixEnd: string;
  private edgeDataMap = new Map<string, IGanttEdgeBuilderData>();
  zoomTransformation: any;
  markers: any;
  lineStyle: any;
  startArrowHeadDefs: d3.Selection<any, any, null, undefined>;
  endArrowHeadDefs: d3.Selection<any, any, null, undefined>;

  constructor(parentNode, executer) {
    this.parentNode = parentNode;
    this.executer = executer;
    this.canvas = null;
    this.def = null;

    this.lineGenerator = null;

    this.lineNodeDistance = 16;
    this.strokeShiftMouseOverWidth = 2.5;

    this.edgeDefaultColor = 'blue';

    this.arrowHeadSuffixStart = 'ganttedge_arrowhead_start_';
    this.arrowHeadSuffixEnd = 'ganttedge_arrowhead_end_';

    this.zoomTransformation = { k: 1, x: 0, y: 0 };

    this.markers = {
      arrow: 'M 0 0 L 10 5 L 0 10 z',
      square: 'M 0 0 L 10 0 L 10 10 L 0 10 z',
      rhombus: 'M 0 5 L 5 0 L 10 5 L 5 10 z',
      none: '',
    };

    this.lineStyle = {
      dashed: '4',
      solid: '',
    };
  }

  /**
   * Initialization of theEdgeBuilder
   * Creates a group and initializes "defs" for arrowhead
   */
  init() {
    const s = this;

    s.canvas = d3.select(s.parentNode).append('g').attr('class', 'gantt-edge-group');

    s.def = s.canvas.append('defs');

    s.startArrowHeadDefs = s.def.append('g').attr('class', 'start-arrow-heads');

    s.endArrowHeadDefs = s.def.append('g').attr('class', 'end-arrow-heads');
  }

  /**
   * remove this class
   */
  remove() {
    const s = this;
    s.canvas.remove();
  }

  /**
   * Adds or updates edgeData to the edge dataset.
   * @param edgeId id-string of an edge
   * @param shiftId1 id-string of the start-shift
   * @param shiftId2 id-string of the end-shift
   * @param x1 x-coordinate of edge start-point
   * @param y1 y-coordinate of edge start-point
   * @param x2 x-coordinate of edge end-point
   * @param y2 y-coordinate of edge end-point
   * @param color color of edge
   * @param visible is shift visible (bool)
   * @param typeStart Arrow head type on arrow beginning.
   * @param typeEnd Arrow head type on arrow end.
   * @param lineStyle Arrow line style.
   */
  public addEdge(
    id1: string,
    id2: string,
    edgeId: string,
    x1: number,
    y1: number,
    x2: number,
    y2: number,
    color: string,
    visible: boolean,
    typeStart: number,
    typeEnd: number,
    lineStyle: number,
    clipPath: string
  ): void {
    const edge = this.getEdgeById(edgeId);

    if (edge != null) {
      //update existing edge
      edge.x1 = x1;
      edge.y1 = y1;
      edge.x2 = x2;
      edge.y2 = y2;
      edge.color = color;
      edge.visible = visible;
      edge.typeStart = typeStart;
      edge.typeEnd = typeEnd;
      edge.clipPath = clipPath;
      return;
    }
    const edgeData: IGanttEdgeBuilderData = {
      id1: id1,
      id2: id2,
      edgeId: edgeId,
      x1: x1,
      x2: x2,
      y1: y1,
      y2: y2,
      color: color,
      visible: visible,
      pathPoints: null,
      typeStart: typeStart,
      typeEnd: typeEnd,
      lineStyle: lineStyle,
      lineNodeDistance: null,
      clipPath: clipPath,
    };
    this.edgeDataMap.set(edgeId, edgeData);
  }

  /**
   * build edges wich are in edge-dataset
   */
  public buildEdges(dataset: IGanttEdgeBuilderData[]): void {
    const s = this;

    if (!s.executer.edgeConfig.renderEdges) {
      dataset = [];
    }

    this._createMarkers(dataset);

    const edges = s.canvas.selectAll('.gantt-edge-line').data(
      dataset.filter(function (d) {
        return d.visible;
      })
    );

    edges
      .attr('points', function (d: IGanttEdgeBuilderData) {
        return s.createPolylineCoordinates(s.zoom(d.x1), d.y1, s.zoom(d.x2), d.y2);
      })
      .style('stroke', function (d: IGanttEdgeBuilderData) {
        if (d.color) return d.color;
        return s.edgeDefaultColor;
      })
      .style('stroke-width', function (d: IGanttEdgeBuilderData) {
        if (s.executer.currentMouseOverShiftId) {
          const shiftId = s.executer.currentMouseOverShiftId;
          if (d.id1 === shiftId || d.id2 === shiftId) {
            return s.strokeShiftMouseOverWidth;
          }
        }
      })
      .style('opacity', function (d: IGanttEdgeBuilderData) {
        if (s.executer.currentMouseOverShiftId) {
          const shiftId = s.executer.currentMouseOverShiftId;
          if (d.id1 === shiftId || d.id2 === shiftId) {
            return 1;
          }
        }
      })
      .each(function (d: IGanttEdgeBuilderData) {
        const points = s.createPolylineCoordinates(s.zoom(d.x1), d.y1, s.zoom(d.x2), d.y2);
        return points;
      })
      .attr('clip-path', (d) => d.clipPath);

    edges
      .enter()
      .append('polyline')
      .attr('class', 'gantt-edge-line')
      .attr('marker-end', function (d: IGanttEdgeBuilderData) {
        if (s.executer.edgeConfig.renderArrowsOnEdges) return 'url(#' + s.arrowHeadSuffixEnd + d.edgeId + ')';
      })
      .attr('marker-start', function (d: IGanttEdgeBuilderData) {
        if (s.executer.edgeConfig.renderArrowsOnEdges) return 'url(#' + s.arrowHeadSuffixStart + d.edgeId + ')';
      })
      .attr('stroke-dasharray', function (d: IGanttEdgeBuilderData) {
        return s._getLineStyle(d.lineStyle);
      })
      .attr('points', function (d: IGanttEdgeBuilderData) {
        const points = s.createPolylineCoordinates(s.zoom(d.x1), d.y1, s.zoom(d.x2), d.y2);
        return points;
      })
      .style('stroke', function (d: IGanttEdgeBuilderData) {
        if (d.color) return d.color;
        return s.edgeDefaultColor;
      })
      .style('stroke-width', function (d: IGanttEdgeBuilderData) {
        if (s.executer.currentMouseOverShiftId) {
          const shiftId = s.executer.currentMouseOverShiftId;
          if (d.id1 === shiftId || d.id2 === shiftId) {
            return s.strokeShiftMouseOverWidth;
          }
        }
      })
      .style('opacity', function (d: IGanttEdgeBuilderData) {
        if (s.executer.currentMouseOverShiftId) {
          const shiftId = s.executer.currentMouseOverShiftId;
          if (d.id1 === shiftId || d.id2 === shiftId) {
            return 1;
          }
        }
      })
      .each(function (d: IGanttEdgeBuilderData) {
        d.pathPoints = s.getPathPointsCoordinates(s.zoom(d.x1), d.y1, s.zoom(d.x2), d.y2);
      })
      .attr('clip-path', (d) => d.clipPath);

    edges.exit().remove();
  }

  /**
   * visualization of edge if the gap between start- and endpoint has changed
   * @param edge edge-object
   * @param {float} y1Change change of startpoint
   * @param {float} y1Change change of endpoint
   */
  animateEdge(edge, y1Change, y2Change) {
    const s = this;

    if (!edge) return;

    edge
      .transition()
      .duration(300)
      .attr('points', function (d) {
        return s.createPolylineCoordinates(s.zoom(d.x1), d.y1 + y1Change, s.zoom(d.x2), d.y2 + y2Change);
      });
  }

  /**
   * Creates the arrow heads
   */
  private _createMarkers(buildEdges: IGanttEdgeBuilderData[]): void {
    // define arrowhead
    const markerStart = this.startArrowHeadDefs.selectAll('marker').data(
      buildEdges.filter((d) => {
        return d.visible;
      })
    );

    markerStart
      .enter()
      .append('marker')
      .attr('id', (d) => {
        return this.arrowHeadSuffixStart + d.edgeId;
      })
      .attr('viewBox', '0 0 10 10')
      .attr('refX', '9')
      .attr('refY', '5')
      .attr('markerWidth', '6')
      .attr('markerHeight', '6')
      .attr('orient', 'auto-start-reverse')
      .append('path')
      .attr('d', (d) => {
        return this._getMarkerPath(d.typeStart);
      })
      .style('fill', (d) => {
        if (d.color) {
          return d.color;
        }
        return this.edgeDefaultColor;
      });

    markerStart.select('path').style('fill', (d) => {
      if (d.color) {
        return d.color;
      }
      return this.edgeDefaultColor;
    });

    markerStart.exit().remove();

    const markerEnd = this.endArrowHeadDefs.selectAll('marker').data(
      buildEdges.filter((d) => {
        return d.visible;
      })
    );

    markerEnd
      .enter()
      .append('marker')
      .attr('id', (d) => {
        return this.arrowHeadSuffixEnd + d.edgeId;
      })
      .attr('viewBox', '0 0 10 10')
      .attr('refX', '9')
      .attr('refY', '5')
      .attr('markerWidth', '6')
      .attr('markerHeight', '6')
      .attr('orient', 'auto-start-reverse')
      .append('path')
      .attr('d', (d) => {
        return this._getMarkerPath(d.typeEnd);
      })
      .style('fill', (d) => {
        if (d.color) {
          return d.color;
        }
        return this.edgeDefaultColor;
      });

    markerEnd.select('path').style('fill', (d) => {
      if (d.color) {
        return d.color;
      }
      return this.edgeDefaultColor;
    });

    markerEnd.exit().remove();
  }

  /**
   * Returns the arrow head svg path by marker type.
   * @param {number} markerType 1 = arrow, 2 = rhombus, 3 = square, default = none
   * @returns {string} Svg path.
   */
  private _getMarkerPath(markerType) {
    switch (markerType) {
      case 1:
        return this.markers.arrow;
      case 2:
        return this.markers.rhombus;
      case 3:
        return this.markers.square;
      default:
        return this.markers.none;
    }
  }

  /**
   * Returns the arrow line style by marker type.
   * @param {number} lineStyle 1 = dashed, default = solid
   * @returns {string} Stroke-dasharray.
   */
  private _getLineStyle(lineStyle) {
    switch (lineStyle) {
      case 1:
        return this.lineStyle.dashed;
      default:
        return this.lineStyle.solid;
    }
  }

  /**
   * this function creates a polyline from start- to endpoint and returns it
   *
   * @param {float} x1 x-coordinate of startpoint
   * @param {float} y1 y-coordinate of startpoint
   * @param {float} x2 x-coordinate of endpoint
   * @param {float} y2 y-coordinate of endpoint
   */
  createPolylineCoordinates(x1, y1, x2, y2) {
    const s = this;

    switch (true) {
      case x2 - x1 >= 0: //successively
        if (x2 - x1 < 2 * s.lineNodeDistance && y1 != y2) {
          //the shifts are consecutive and very close to each other
          return (
            x1 +
            ',' +
            y1 +
            ' ' +
            (x1 + s.lineNodeDistance) +
            ',' +
            y1 +
            ' ' +
            (x1 + s.lineNodeDistance) +
            ',' +
            (y1 + (y2 - y1) / 2) +
            ' ' +
            (x2 - s.lineNodeDistance) +
            ',' +
            (y1 + (y2 - y1) / 2) +
            ' ' +
            (x2 - s.lineNodeDistance) +
            ',' +
            y2 +
            ' ' +
            x2 +
            ',' +
            y2
          );
        } else {
          //the shifts are consecutive and have enough space to each other
          // if (x1 == x2 && y1 == y2) return; // do not show edge if start and and the same
          return (
            x1 +
            ',' +
            y1 +
            ' ' +
            (x1 + (x2 - x1) / 2) +
            ',' +
            y1 +
            ' ' +
            (x1 + (x2 - x1) / 2) +
            ',' +
            (y1 + (y2 - y1)) +
            ' ' +
            x2 +
            ',' +
            y2
          );
        }
      case x2 - x1 < 0: //before
        if (y1 == y2) {
          //the shift to be connected is behind it and on the same row or the shift is connected to itself
          return (
            x1 +
            ',' +
            y1 +
            ' ' +
            (x1 + s.lineNodeDistance) +
            ',' +
            y1 +
            ' ' +
            (x1 + s.lineNodeDistance) +
            ',' +
            (y1 + s.lineNodeDistance) +
            ' ' +
            (x2 - s.lineNodeDistance) +
            ',' +
            (y1 + s.lineNodeDistance) +
            ' ' +
            (x2 - s.lineNodeDistance) +
            ',' +
            y2 +
            ' ' +
            x2 +
            ',' +
            y2
          );
        } else {
          //the shift to be connected is behind it and on another row
          return (
            x1 +
            ',' +
            y1 +
            ' ' +
            (x1 + s.lineNodeDistance) +
            ',' +
            y1 +
            ' ' +
            (x1 + s.lineNodeDistance) +
            ',' +
            (y1 + (y2 - y1) / 2) +
            ' ' +
            (x2 - s.lineNodeDistance) +
            ',' +
            (y1 + (y2 - y1) / 2) +
            ' ' +
            (x2 - s.lineNodeDistance) +
            ',' +
            y2 +
            ' ' +
            x2 +
            ',' +
            y2
          );
        }
    }
  }

  /**
   * this function retruns a zoomed x-coordinate of current scale
   *
   * @param {float} coordinate x-coordinate
   */
  zoom(coordinate) {
    const s = this;

    return coordinate * s.zoomTransformation.k + s.zoomTransformation.x;
  }

  /**
   * removes an edge by id from the current dataset
   *
   * @param edgeId id-string of edge to be deleted
   */
  removeEdgeByEdgeID(edgeId: string) {
    this.edgeDataMap.delete(edgeId);
  }

  /**
   * removes all edges in edge-dataset
   */
  removeAllEdges() {
    this.edgeDataMap.clear();
  }
  removeAllEdgeNodes() {
    const s = this;
    s.canvas.selectAll('.gantt-edge-line').remove();
  }

  //
  //  GETTER & SETTER
  //

  setZoomTransformation(zoomTransformation) {
    const s = this;
    s.zoomTransformation = zoomTransformation;
  }

  getEdgeById(edgeId: string): IGanttEdgeBuilderData {
    return this.edgeDataMap.get(edgeId) || null;
  }

  getPathPointsCoordinates(x1, y1, x2, y2): [number, number][] {
    const s = this;

    switch (true) {
      case x2 - x1 > 0: //successively
        if (x2 - x1 < 2 * s.lineNodeDistance && y1 != y2) {
          //the shifts are consecutive and very close to each other
          return [
            [x1, y1],
            [x1 + s.lineNodeDistance, y1],
            [x1 + s.lineNodeDistance, y1 + (y2 - y1) / 2],
            [x2 - s.lineNodeDistance, y1 + (y2 - y1) / 2],
            [x2 - s.lineNodeDistance, y2],
            [x2, y2],
          ];
        } else {
          //the shifts are consecutive and have enough space to each other
          return [
            [x1, y1],
            [x1 + (x2 - x1) / 2, y1],
            [x1 + (x2 - x1) / 2, y2],
            [x2, y2],
          ];
        }
      case x2 - x1 < 0: //before
        if (y1 == y2) {
          //the shift to be connected is behind it and on the same row or the shift is connected to itself
          return [
            [x1, y1],
            [x1 + s.lineNodeDistance, y1],
            [x1 + s.lineNodeDistance, y1 + s.lineNodeDistance],
            [x2 - s.lineNodeDistance, y1 + s.lineNodeDistance],
            [x2 - s.lineNodeDistance, y2],
            [x2, y2],
          ];
        } else {
          //the shift to be connected is behind it and on another row
          return [
            [x1, y1],
            [x1 + s.lineNodeDistance, y1],
            [x1 + s.lineNodeDistance, y1 + (y2 - y1) / 2],
            [x2 - s.lineNodeDistance, y1 + (y2 - y1) / 2],
            [x2 - s.lineNodeDistance, y2],
            [x2, y2],
          ];
        }
    }
  }

  setVisibilityInEdgeDatasetByEdgeId(edgeId, bool) {
    const s = this;
    const edge = s.getEdgeById(edgeId);

    if (edge) {
      edge.visible = bool;
    }
  }

  //
  // GETTER & SETTER
  //

  getGanttEdgeGroup() {
    return this.canvas;
  }

  /**
   * @return {Array.<IGanttEdgeBuilderData>}
   */
  getEdgeData(): IGanttEdgeBuilderData[] {
    return Array.from(this.edgeDataMap.values());
  }

  setEdgeDefaultColor(color) {
    this.edgeDefaultColor = color;
  }

  /**
   * @deprecated
   * @param {boolean} show Decides if edges will be rendered.
   */
  showEdges(show) {
    const s = this;
    if (!show) this.removeAllEdgeNodes();
    else this.buildEdges(s.getEdgeData());
  }
}

export interface IGanttEdgeBuilderData {
  id1: string;
  id2: string;
  edgeId: string;
  x1: number;
  x2: number;
  y1: number;
  y2: number;
  color: string;
  visible: boolean;
  pathPoints: [number, number][];
  typeStart: number;
  typeEnd: number;
  lineStyle: number;
  lineNodeDistance: number;
  clipPath: string;
}
