import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { TemplateButtonModule } from '@app-modeleditor/components/button/button.module';
import { SlotModule } from '@app-modeleditor/components/template/slot/slot.module';
import { TranslateModule } from '@ngx-translate/core';
import { DialogContentComponent } from './dialog-content.component';

@NgModule({
  imports: [CommonModule, MatDialogModule, TemplateButtonModule, DragDropModule, TranslateModule, SlotModule],
  declarations: [DialogContentComponent],
  exports: [DialogContentComponent],
})
export class DialogContentModule {}
