import { NgModule } from '@angular/core';
import { ExperimentFooterModule } from './experiment-footer/experiment-footer.module';
import { HorizonFooterModule } from './horizon-footer/horizon-footer.module';
import { ModelFooterModule } from './model-footer/model-footer.module';
import { SolverFooterModule } from './solver-footer/solver-footer.module';
import { TemplateFooterElementModule } from './template-footer-element/template-footer-element.module';
import { TenantFooterModule } from './tenant-footer/tenant-footer.module';
import { TimespanFooterModule } from './timespan-footer/timespan-footer.module';
import { UserFooterModule } from './user-footer/user-footer.module';
@NgModule({
  imports: [
    ExperimentFooterModule,
    UserFooterModule,
    ModelFooterModule,
    HorizonFooterModule,
    TimespanFooterModule,
    SolverFooterModule,
    TemplateFooterElementModule,
    TenantFooterModule,
  ],
  exports: [
    ExperimentFooterModule,
    UserFooterModule,
    ModelFooterModule,
    HorizonFooterModule,
    TimespanFooterModule,
    SolverFooterModule,
    TemplateFooterElementModule,
    TenantFooterModule,
  ],
})
export class DefaultFooterModule {}
