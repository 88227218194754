import { EGanttScrollContainer } from '../../html-structure/scroll-container.enum';
import { RenderDataCanvasTransformConnector } from './render-data-canvas-transform-connector';

/**
 * Helper class for {@link RenderDataHandler} to store various data related to transformations of the shift viewports.
 */
export class RenderDataCanvasTransform {
  private readonly _top = new Map<EGanttScrollContainer, number>();
  private _left = 0;
  private _scaleX = 1;
  private readonly _scaleY = 1;

  constructor() {
    for (const scrollContainerId of Object.values(EGanttScrollContainer)) {
      this._top.set(scrollContainerId, 0);
    }
  }

  /**
   * Returns the top value of the scroll container with the given id.
   * @returns Top value of the scroll container with the given id.
   */
  public getTop(scrollContainerId: EGanttScrollContainer): number {
    return this._top.get(scrollContainerId);
  }

  /**
   * Sets the top value of the scroll container with the given id.
   * @param scrollContainerId Id of the scroll container to set the top value for.
   * @param top Top value to be set.
   */
  public setTop(scrollContainerId: EGanttScrollContainer, top: number): void {
    this._top.set(scrollContainerId, top);
  }

  /**
   * Returns the left value of all shift viewports (common value).
   * @returns Left value of all shift viewports.
   */
  public getLeft(): number {
    return this._left;
  }

  /**
   * Sets the left value of all shift viewports (common value).
   * @param left Left value to be set.
   */
  public setLeft(left: number): void {
    this._left = left;
  }

  /**
   * Returns the x scale of all shift viewports (common value).
   * @returns X scale of all shift viewports.
   */
  public getScaleX(): number {
    return this._scaleX;
  }

  /**
   * Sets the x scale of all shift viewports (common value).
   * @param scaleX X scale to be set.
   */
  public setScaleX(scaleX: number): void {
    this._scaleX = scaleX;
  }

  /**
   * Returns the y scale of all shift viewports (common value).
   * @returns Y scale of all shift viewports.
   */
  public getScaleY(): number {
    return this._scaleY;
  }

  /**
   * Accesses the canvas transform for the scroll container with the given id.
   * @param scrollContainerId Id of the scroll container to access the canvas transform for.
   * @returns Object providing the canvas transform data for the scroll container with the given id.
   */
  public access(scrollContainerId: EGanttScrollContainer): RenderDataCanvasTransformConnector {
    return new RenderDataCanvasTransformConnector(this, scrollContainerId);
  }
}
