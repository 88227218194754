import { PatternBuilder } from '../patternBuilder';
import { GanttPatternData } from '../patternHandler';

/**
 * Class which handles the generation of a pattern.
 */
export abstract class Pattern {
  /**
   * Height of the generated pattern in px.
   */
  protected _patternHeight = 10;
  /**
   * Width of the generated pattern in px.
   */
  protected _patternWidth = 10;

  /**
   * @param _patternBuilder {@link PatternBuilder} instance to be used for the pattern generation.
   */
  constructor(protected readonly _patternBuilder: PatternBuilder) {}

  /**
   * Generates an SVG image containing the pattern and returns it as base64-encoded URL.
   * @param backgroundColor Background color of the pattern.
   * @param patternColor Color of the pattern.
   * @param strokeColor Stroke color of the pattern.
   * @param numberParams Additional number parameters.
   * @returns SVG image containing the pattern as base64-encoded URL.
   */
  protected abstract _getPatternDataString(
    backgroundColor: string,
    patternColor: string,
    strokeColor: string,
    ...numberParams: number[]
  ): string;

  /**
   * Generates an SVG image containing the pattern and returns it as {@link GanttPatternData} object.
   * @param backgroundColor Background color of the pattern.
   * @param patternColor Color of the pattern.
   * @param strokeColor Stroke color of the pattern.
   * @param numberParams Additional number parameters.
   * @returns SVG image containing the pattern as {@link GanttPatternData} object.
   */
  public abstract getPatternData(
    backgroundColor: string,
    patternColor: string,
    strokeColor: string,
    ...numberParams: number[]
  ): GanttPatternData;
}
