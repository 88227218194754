import { Injectable } from '@angular/core';
import { GanttDataContainer, GanttEditPermissions } from '@gantt/public-api';
import { GanttDataInput } from './../saxms-best-gantt.handler.component';

/**
 * Saves mapped gantt input data and restrictions.
 */
@Injectable()
export class GanttDataStorageService {
  private _mappedGanttData: GanttDataContainer;
  private _editPermissions: GanttEditPermissions = new GanttEditPermissions();

  /**
   * Saves gantt input data inside. Divides data into gantt data and restrictions.
   * @param dataInput SaxMSBestgantt component input.
   */
  public storeGanttData(dataInput: GanttDataInput): GanttDataContainer {
    this.mappedGanttData = dataInput.ganttOrigindata;
    if (dataInput && dataInput.editAllowSettings)
      this.editPermissions = JSON.parse(JSON.stringify(dataInput.editAllowSettings));
    return this.mappedGanttData;
  }

  get mappedGanttData(): GanttDataContainer {
    return this._mappedGanttData;
  }

  set mappedGanttData(data: GanttDataContainer) {
    this._mappedGanttData = data;
  }

  get editPermissions(): GanttEditPermissions {
    return this._editPermissions;
  }

  set editPermissions(editPermissions: GanttEditPermissions) {
    this._editPermissions = editPermissions;
  }
}
