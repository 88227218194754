<ng-container *ngIf="state$ | push as state">
  <div class="notification" [ngClass]="state.type?.toLowerCase()">
    <mat-progress-bar mode="determinate" [value]="100 - (state.durationLeft / state.duration) * 100"></mat-progress-bar>
    <div style="display: flex; align-items: center; justify-content: space-between" class="wrapper">
      <div (click)="toggle(state.id)" class="text" [class.expanded]="state.expanded">
        {{ state.params ? (state.text | translate : state.params) : (state.text | translate) }}
      </div>
      <button (click)="close(state.id)" mat-icon-button>
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
</ng-container>
