<mat-grid-list class="grid-list mat-typography" [cols]="layout.getColumnCount()" rowHeight="fit">
  <mat-grid-tile
    [style.overflow]="'unset'"
    *ngFor="let part of layout.getContentElements()"
    [colspan]="part.width"
    [rowspan]="part.height"
    #ref
  >
    <div class="grid-tile-card mat-elevation-z2" #card [style.padding]="'0px'">
      <div class="grid-inner-content">
        <ng-container
          *ngTemplateOutlet="contentElement; context: { $implicit: getTemplateById(part.id), ref: card, root: card }"
        >
        </ng-container>
      </div>
    </div>
  </mat-grid-tile>
</mat-grid-list>
