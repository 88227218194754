export enum ETemplateType {
  ENTRY_COLLECTION = 'EntryCollection',
  ENTRY_ELEMENT_COLLECTION = 'EntryElementCollection',
  CONTENT_PART = 'ContentPart',
  CONTENT = 'Content',
  CONTENT_HEADER = 'ContentHeader',
  ENTRY_ELEMENT = 'EntryElement',
  CALENDAR = 'Calendar',
  BREADCRUMBS = 'Breadcrumbs',
  TOOLBAR = 'Toolbar',
  APPLICATION_MENU = 'ApplicationMenu',
  LIGHTBOX = 'LightBox',
  TEMPLATE_UI = 'TemplateUi',
  PAGE = 'Page',
  TWO_COLUMN_LIST_SELECTOR = 'TwoColumnListSelector',
  TABLE = 'Table',
  TABLE_HEADER = 'TableHeader',
  TABLE_HEADER_GROUP = 'TableHeaderGroup',
  WIZARD = 'Wizard',
  STACKABLE = 'Stackable',
  WIZARD_PAGE = 'WizardPage',
  TAB = 'tab',
  RANGE_PICKER = 'RangePicker',
  TEXT_FIELD = 'TEXT_FIELD',
  BUTTON = 'BUTTON',
  PRINTER = 'Printer',
  HMI = 'HMI',
  HOT_SPOT_ELEMENT = 'HotSpotElement',
  ITEM_LIST = 'ItemList',
  COMBO_BOX = 'COMBO_BOX',
  CONTENT_WRAPPER = 'ContentWrapper',
  CONTEXT_MENU = 'ContextMenu',
  CONTEXT_MENU_ITEM = 'ContextMenuItem',
  LABELED_TEXT = 'LABELED_TEXT',
  HEADLINE = 'HEADLINE',
  LIST_SELECTOR = 'ListSelector',
  TOGGLE_BUTTON = 'TOGGLE_BUTTON',
  INDEX_REGISTER = 'IndexRegister',
  INDEX_PAGE = 'IndexPage',
  OBJECT_SELECTOR = 'OBJECT_SELECTOR',
  FILE_SYSTEM_ELEMENT = 'FILESYSTEMELEMENT',
  TOOLBAR_ITEM = 'ToolbarItem',
  TOOLBAR_ELEMENT = 'ToolbarElement',
  TOOLBAR_GROUP = 'ToolbarGroup',
  MENU_ITEM = 'MenuItem',
  TILE = 'Tile',
  HIERARCHIC_MENU_ITEM = 'HierarchicMenuItem',
  FILE_VIEWER = 'FileViewer',
  MENU = 'Menu',
  MENU_ELEMENT = 'MenuElement',
  FOOTER_ELEMENT = 'FooterElement',
  STATUS_BAR = 'StatusBar',
  STATUS_ICON = 'StatusIcon',
  GANTT = 'Gantt',
  BASIC_GANTT = 'BasicGantt',
  WIDGET_CONTAINER = 'WidgetContainer',
  SIDE_BAR = 'SideBar',
  MAP = 'Map',
  GANTT_DATE_SELECTOR = 'GanttDateSelector',
  CHAT = 'Chat',
  CHART_LEGEND_DATASET = 'ChartLegendDataset',
  CHART_LEGEND_ATTRIBUTE = 'ChartLegendAttribute',
  PASSWORDCHANGE = 'PASSWORDCHANGE',
  USERSETTINGS = 'USERSETTINGS',
  ERRORLOG = 'ERRORLOG',
  PROGRESS_BAR = 'ProgressBar',
}
