import * as d3 from 'd3';
import { TimeFormatterAxisFormat } from '../x-axis/axis-formats/x-axis-format-general';
import { GanttXAxis } from '../x-axis/x-axis';

/**
 * This class calculates time rasterisation.
 * @keywords time, raster, grid, rasterisation, drag, steps, timepoint
 */
export class TimeGradationHandler {
  private _xAxisBuilder: GanttXAxis;
  /** Grid rasterisation for calculation. */
  private _defaultGrid = ETimeGradationTimeGrid.NONE;
  private _customizedStartDate = new Date();
  private _customizedStepSize: number | ETimeGradationEnumStepSize = 120000;

  constructor(xAxisBuilder: GanttXAxis) {
    this._xAxisBuilder = xAxisBuilder;
  }

  /**
   * This function returns a converted date by given time raster.
   * @keywords raster, date,
   * @param date Date to be formatted.
   * @param grid Grid format.
   */
  public getAliginDateByDate(
    date: Date,
    grid: ETimeGradationTimeGrid = undefined,
    roundingType: ETimeGradationRoundingType = ETimeGradationRoundingType.HALF
  ): Date {
    grid = this._validityCheck(grid);

    const minute = date.getMinutes(),
      second = date.getSeconds(),
      hour = date.getHours();

    switch (grid) {
      case 'minute':
        var timePointMinute = new Date(date);
        timePointMinute.setSeconds(0);
        timePointMinute.setMilliseconds(0);

        switch (roundingType) {
          case ETimeGradationRoundingType.UP:
            if (second > 0) timePointMinute = new Date(timePointMinute.setMinutes(minute + 1));
            break;
          case ETimeGradationRoundingType.DOWN:
            if (second > 0) timePointMinute = new Date(timePointMinute.setMinutes(minute));
            break;
          case ETimeGradationRoundingType.HALF:
            if (second > 30) timePointMinute = new Date(timePointMinute.setMinutes(minute + 1));
            break;
        }
        return timePointMinute;

      case 'hour':
        var timePointHour = new Date(date);
        timePointHour.setSeconds(0);
        timePointHour.setMilliseconds(0);
        timePointHour.setMinutes(0);

        switch (roundingType) {
          case ETimeGradationRoundingType.UP:
            if (minute > 0) timePointHour = new Date(timePointHour.setHours(hour + 1));
            break;
          case ETimeGradationRoundingType.DOWN:
            if (minute > 0) timePointHour = new Date(timePointHour.setHours(hour));
            break;
          case ETimeGradationRoundingType.HALF:
            if (minute > 30) timePointHour = new Date(timePointHour.setHours(hour + 1));
            break;
        }
        return timePointHour;

      case 'day':
        var timePointDay = new Date(date);
        timePointDay.setHours(0);
        timePointDay.setSeconds(0);
        timePointDay.setMilliseconds(0);
        timePointDay.setMinutes(0);

        switch (roundingType) {
          case ETimeGradationRoundingType.UP:
            if (!(hour === 0 && minute === 0 && second === 0 && date.getMilliseconds() === 0))
              timePointDay.setDate(timePointDay.getDate() + 1);
            break;
          case ETimeGradationRoundingType.DOWN:
            if (!(hour === 0 && minute === 0 && second === 0 && date.getMilliseconds() === 0))
              timePointDay.setDate(timePointDay.getDate());
            break;
          case ETimeGradationRoundingType.HALF:
            if (hour > 12) timePointDay.setDate(timePointDay.getDate() + 1);
            break;
        }

        return timePointDay;

      case 'week':
        var timePointWeek = new Date(date);

        timePointWeek.setMilliseconds(0);
        timePointWeek.setSeconds(0);
        timePointWeek.setMinutes(0);
        const day = timePointWeek.getDay();
        let diff;

        switch (roundingType) {
          case ETimeGradationRoundingType.UP: // = round to next monday
            if (day !== 1) diff = timePointWeek.getDate() + (6 - day) + (day == 0 ? -5 : 2); // adjust when day is sunday
            break;
          case ETimeGradationRoundingType.DOWN: // = round to last monday
            if (day !== 1) diff = timePointWeek.getDate() - day + 1;
            break;
          case ETimeGradationRoundingType.HALF:
            if (day === 0 || day > 4 || (day === 4 && timePointWeek.getHours() > 12)) {
              // round to next week
              diff = timePointWeek.getDate() + (6 - day) + (day == 0 ? -5 : 2); // adjust when day is sunday
            } else {
              diff = timePointWeek.getDate() - day + 1;
            }
            break;
        }

        timePointWeek.setHours(0);
        timePointWeek = new Date(timePointWeek.setDate(diff));

        return timePointWeek;

      case 'month':
        var timePointMonth = new Date(date);

        switch (roundingType) {
          case ETimeGradationRoundingType.UP:
            if (
              !(
                timePointMonth.getDate() === 1 &&
                timePointMonth.getHours() === 0 &&
                timePointMonth.getMinutes() === 0 &&
                timePointMonth.getSeconds() === 0 &&
                timePointMonth.getMilliseconds() === 0
              )
            ) {
              timePointMonth = new Date(timePointMonth.getFullYear(), timePointMonth.getMonth() + 1, 1, 0, 0, 0, 0);
            }
            break;
          case ETimeGradationRoundingType.DOWN:
            if (
              !(
                timePointMonth.getDate() === 1 &&
                timePointMonth.getHours() === 0 &&
                timePointMonth.getMinutes() === 0 &&
                timePointMonth.getSeconds() === 0 &&
                timePointMonth.getMilliseconds() === 0
              )
            ) {
              timePointMonth = new Date(timePointMonth.getFullYear(), timePointMonth.getMonth(), 1, 0, 0, 0, 0);
            }
            break;
          case ETimeGradationRoundingType.HALF:
            if (
              timePointMonth > this._getDateOfMiddleOfMonth(timePointMonth.getFullYear(), timePointMonth.getMonth())
            ) {
              timePointMonth = new Date(timePointMonth.getFullYear(), timePointMonth.getMonth() + 1, 1, 0, 0, 0, 0);
            } else {
              timePointMonth = new Date(timePointMonth.getFullYear(), timePointMonth.getMonth(), 1, 0, 0, 0, 0);
            }
            break;
        }
        return timePointMonth;

      case 'quarter':
        var timePointQuarter = new Date(date);
        let monthFrom = timePointQuarter.getMonth();
        monthFrom = monthFrom - (monthFrom % 3);

        switch (roundingType) {
          case ETimeGradationRoundingType.UP:
            if (
              !(
                timePointQuarter.getMonth() % 3 === 0 &&
                timePointQuarter.getDate() === 1 &&
                timePointMonth.getHours() === 0 &&
                timePointMonth.getMinutes() === 0 &&
                timePointMonth.getSeconds() === 0 &&
                timePointMonth.getMilliseconds() === 0
              )
            ) {
              timePointQuarter = new Date(timePointQuarter.getFullYear(), monthFrom + 3, 1, 0, 0, 0, 0);
            }
            break;
          case ETimeGradationRoundingType.DOWN:
            if (
              !(
                timePointQuarter.getMonth() % 3 === 0 &&
                timePointQuarter.getDate() === 1 &&
                timePointMonth.getHours() === 0 &&
                timePointMonth.getMinutes() === 0 &&
                timePointMonth.getSeconds() === 0 &&
                timePointMonth.getMilliseconds() === 0
              )
            ) {
              timePointQuarter = new Date(timePointQuarter.getFullYear(), monthFrom, 1, 0, 0, 0, 0);
            }
            break;
          case ETimeGradationRoundingType.HALF:
            if (
              timePointQuarter.getMonth() % 3 === 2 || // if last month of quarter
              (timePointQuarter.getMonth() % 3 === 1 &&
                timePointQuarter >
                  this._getDateOfMiddleOfMonth(timePointQuarter.getFullYear(), timePointQuarter.getMonth()))
            ) {
              // middle month of quarter and over the half of the month
              timePointQuarter = new Date(timePointQuarter.getFullYear(), monthFrom + 3, 1, 0, 0, 0, 0);
            } else {
              timePointQuarter = new Date(timePointQuarter.getFullYear(), monthFrom, 1, 0, 0, 0, 0);
            }
            break;
        }
        return timePointQuarter;

      case 'customized':
        const result =
          typeof this._customizedStepSize === 'number'
            ? this._handleNumberStepSize(date, roundingType)
            : this._handleEnumStepSize(date, roundingType);
        return result;
      default:
        return new Date(date);
    }
  }

  /**
   * This function returns a converted x coordinate.
   * @param date Date to be formatted.
   * @param grid Grid format.
   */
  public getAliginXCoordinateByDate(date: Date, grid: ETimeGradationTimeGrid = null): number {
    const currentScale = this._xAxisBuilder.getCurrentScale();
    return currentScale(this.getAliginDateByDate(date, grid));
  }

  /**
   * This function returns a converted x coordinate.
   * @param xCoordinate X coordinate to be formatted.
   * @param grid Grid format.
   */
  public getAliginXCoordinateByXCoordinate(
    xCoordinate: number,
    grid: ETimeGradationTimeGrid = undefined,
    roundingType: ETimeGradationRoundingType = ETimeGradationRoundingType.HALF
  ): number {
    const currentScale = this._xAxisBuilder.getCurrentScale();
    const globalScale = this._xAxisBuilder.getGlobalScale();
    const date = this._xAxisBuilder.pxToTime(xCoordinate, globalScale);

    return currentScale(this.getAliginDateByDate(date, grid, roundingType));
  }

  /**
   * This function returns a converted date.
   * @param xCoordinate x coordinate to be formatted.
   * @param grid Grid format.
   */
  public getAliginDateByXCoordinate(xCoordinate: number, grid: ETimeGradationTimeGrid): Date {
    const date = this._xAxisBuilder.pxToTime(xCoordinate, this._xAxisBuilder.getGlobalScale());
    return this.getAliginDateByDate(date, grid);
  }

  /**
   * This function returns a converted timespan.
   * @param timespan [from, to] timespan to be formatted.
   * @param grid Grid format.
   * @returns [from, to] Converted timespan.
   */
  public getAlignedTimeSpanByTimespan(timeSpan: [Date, Date], grid: ETimeGradationTimeGrid = undefined): [Date, Date] {
    let dateFrom = new Date(timeSpan[0]);
    let dateTo = new Date(timeSpan[1]);
    const span = dateTo.getTime() - dateFrom.getTime();
    switch (grid || this._defaultGrid) {
      case 'customized':
        if (typeof this._customizedStepSize !== 'number') break;
        if (span < this._customizedStepSize / 2) {
          // if timespan smaller than half step width
          dateFrom = new Date(dateFrom.getTime() - this._customizedStepSize / 2);
          dateTo = new Date(dateTo.getTime() + this._customizedStepSize / 2);
        }
        break;
      case 'minute':
        if (span < 30000 * 2) {
          // if timespan smaller than 30 seconds
          dateFrom = new Date(dateFrom.getTime() - 30000);
          dateTo = new Date(dateTo.getTime() + 30000);
        }
        break;
      case 'hour':
        if (span < 1800000 * 2) {
          // if timespan smaller than 30 minutes
          dateFrom = new Date(dateFrom.getTime() - 1800000);
          dateTo = new Date(dateTo.getTime() + 1800000);
        }
        break;
      case 'day':
        if (span < 43200000 * 2) {
          // if timespan smaller than 12 hours
          dateFrom = new Date(dateFrom.getTime() - 43200000);
          dateTo = new Date(dateTo.getTime() + 43200000);
        }
        break;
      case 'week':
        const weekDayFrom = dateFrom.getDay() === 0 ? 7 : dateFrom.getDay();
        const weekDayTo = dateTo.getDay() === 0 ? 7 : dateTo.getDay();
        if (span < 302400000 * 2) {
          // if timespan smaller than half week
          dateFrom.setDate(dateFrom.getDate() - weekDayFrom);
          if (weekDayTo - weekDayFrom >= 0) {
            // if same week
            dateTo.setDate(dateTo.getDate() + 7 - weekDayTo);
          } else {
            dateTo.setDate(dateTo.getDate() - weekDayTo);
          }
        }
        break;
      case 'month':
        if (span < 1210000000 * 2) {
          // if timespan smaller than 14 days
          dateFrom.setDate(1); // first half of month
          dateTo.setDate(28); // second half of month
        }
        break;
      case 'quarter':
        if (span < 4061000000 * 2) {
          // if timespan smaller than 47 days
          dateFrom.setMonth(dateFrom.getMonth() - 1);
          dateFrom.setDate(1);
          dateTo.setMonth(dateTo.getMonth() + 1);
          dateTo.setDate(28);
        }
        break;
    }

    const from = new Date(this.getAliginDateByDate(dateFrom, grid));
    const to = new Date(this.getAliginDateByDate(dateTo, grid));
    return [from, to];
  }

  /**
   * Calculates the new date if step size is a number in milliseconds in a customized grid.
   * @param date
   * @returns Rounded date.
   */
  private _handleNumberStepSize(
    date: Date,
    roundingType: ETimeGradationRoundingType = ETimeGradationRoundingType.HALF
  ): Date {
    if (typeof this._customizedStepSize !== 'number') return date;
    let timePointCustom: Date;

    const customizedStartDate = new Date(this._customizedStartDate.getTime());
    if (
      this._customizedStepSize === 7200000 &&
      this._customizedStartDate.getTimezoneOffset() !== date.getTimezoneOffset()
    ) {
      customizedStartDate.setDate(customizedStartDate.getDate() + 1);
    }

    const difference = date.getTime() - customizedStartDate.getTime();
    let overlap = Math.abs(difference % this._customizedStepSize);

    // correct overlap is date before customized start date
    if (date.getTime() < customizedStartDate.getTime()) {
      overlap = this._customizedStepSize - overlap;
    }

    switch (roundingType) {
      case ETimeGradationRoundingType.UP:
        timePointCustom = new Date(date.getTime() + this._customizedStepSize - overlap);
        break;
      case ETimeGradationRoundingType.DOWN:
        timePointCustom = new Date(date.getTime() - overlap);
        break;
      case ETimeGradationRoundingType.HALF:
        if (overlap > this._customizedStepSize / 2) {
          // round up
          timePointCustom = new Date(date.getTime() + this._customizedStepSize - overlap);
        } else {
          timePointCustom = new Date(date.getTime() - overlap);
        }
        break;
    }

    // handle time changes by correcting the aligned date by an hour
    if (this._customizedStepSize > 3600000 && this._customizedStepSize < 86400000) {
      if (customizedStartDate.getTimezoneOffset() !== timePointCustom.getTimezoneOffset()) {
        const currentTimeFormats = this._xAxisBuilder.getCurrentTimeFormats();
        const xAxisTicksStepSizeHours = this._getTimeFormatTicksStepSizeInHours(
          currentTimeFormats[currentTimeFormats.length - 1]
        );
        if (xAxisTicksStepSizeHours > 0) {
          const factors: [number, number] = [-1, 1];
          if (customizedStartDate.getTime() > timePointCustom.getTime()) {
            factors[0] = 1;
            factors[1] = -1;
          }
          for (let i = 0; i < factors.length; i++) {
            if (new Date(timePointCustom.getTime() + 3600000 * factors[i]).getHours() % xAxisTicksStepSizeHours === 0) {
              timePointCustom = new Date(timePointCustom.getTime() + 3600000 * factors[i]);
              break;
            }
          }
        }
      }
    }

    return timePointCustom;
  }

  /**
   * Calculates the step value of a given time format in hours considering the unit.
   * @param timeFormat Time format for calculation.
   * @returns Calculated step value in hours or -1 when error.
   */
  private _getTimeFormatTicksStepSizeInHours(timeFormat: TimeFormatterAxisFormat): number {
    const now = new Date();
    const ticksStepSizeMs = timeFormat.ticks.ceil(now).getTime() - timeFormat.ticks.floor(now).getTime();

    switch (timeFormat.unit) {
      case 'HOUR':
        return ticksStepSizeMs / (d3.timeHour.ceil(now).getTime() - d3.timeHour.floor(now).getTime());
      default:
        // unknown unit or calculation not neccesary
        return -1;
    }
  }

  /**
   * Calculates the new date if step size is an enum in a customized grid.
   * @param date
   * @returns Rounded date.
   */
  private _handleEnumStepSize(
    date: Date,
    roundingType: ETimeGradationRoundingType = ETimeGradationRoundingType.HALF
  ): Date {
    switch (this._customizedStepSize) {
      case 'DAILY':
        const timePointDay = new Date(date);

        // set rest from ref date
        timePointDay.setHours(this._customizedStartDate.getHours());
        timePointDay.setSeconds(this._customizedStartDate.getSeconds());
        timePointDay.setMinutes(this._customizedStartDate.getMinutes());
        timePointDay.setMilliseconds(this._customizedStartDate.getMilliseconds());
        switch (roundingType) {
          case ETimeGradationRoundingType.UP:
            if (date.getTime() > timePointDay.getTime()) timePointDay.setDate(timePointDay.getDate() + 1);
            break;
          case ETimeGradationRoundingType.DOWN:
            if (date.getTime() < timePointDay.getTime()) timePointDay.setDate(timePointDay.getDate() - 1);
            break;
          case ETimeGradationRoundingType.HALF:
            const diff = date.getTime() - timePointDay.getTime();
            if (diff > 43200000) timePointDay.setDate(timePointDay.getDate() + 1);
            if (diff < -43200000) timePointDay.setDate(timePointDay.getDate() - 1);
            break;
        }

        return timePointDay;
      case 'HOURLY':
      case 'WEEKLY':
      case 'MONTHLY':
      case 'QUARTERLY':
      default:
        console.warn(`Time grid step size ${this._customizedStepSize} not implemented yet.`);
    }
    return date;
  }

  /**
   * This function checks the parameter for validity
   * @param grid Grid format.
   */
  private _validityCheck(grid: ETimeGradationTimeGrid): ETimeGradationTimeGrid {
    if (
      grid == 'none' ||
      grid == 'minute' ||
      grid == 'hour' ||
      grid == 'day' ||
      grid == 'week' ||
      grid == 'month' ||
      grid == 'quarter' ||
      grid == 'customized'
    ) {
      return grid;
    } else {
      return this._defaultGrid;
    }
  }

  /**
   * This function returns the time span in milliseconds of given month.
   * @param month The month whose time span is to be calculated.
   * @param year The year whose time span is to be calculated.
   * @returns Millis of month.
   */
  private _getMillisOfMonth(year: number, month: number): number {
    const dateFrom = new Date(year, month, 1, 0, 0, 0, 0);
    const dateTo = new Date(new Date(year, month + 1, 0, 23, 59, 59, 999).getTime());
    return dateTo.getTime() - dateFrom.getTime();
  }

  /**
   * This function returns the date of the middle of the month.
   * @param month The month whose date is to be calculated.
   * @param year The year whose date is to be calculated.
   * @returns Date of the middle of the month.
   */
  private _getDateOfMiddleOfMonth(year: number, month: number): Date {
    const dateFrom = new Date(year, month, 1, 0, 0, 0, 0).getTime();
    return new Date(dateFrom + this._getMillisOfMonth(year, month) / 2);
  }

  //
  // GETTER & SETTER
  //

  /**
   * @param grid
   */
  public setDefaultTimeGrid(grid: ETimeGradationTimeGrid): void {
    this._defaultGrid = this._validityCheck(grid);
  }

  public setCustomizedTimeGrid(
    customizedStartDate: Date | number,
    customizedStepSize: number | ETimeGradationEnumStepSize
  ): void {
    this._customizedStartDate = new Date(customizedStartDate);
    this.setCustomizedStepSize(customizedStepSize);
  }

  public setCustomizedStartDate(customizedStartDate: Date | number): void {
    this._customizedStartDate = new Date(customizedStartDate);
  }

  /**
   * Can be a number or an enum.
   * @param customizedStepSize
   */
  public setCustomizedStepSize(customizedStepSize: number | ETimeGradationEnumStepSize): void {
    this._customizedStepSize = customizedStepSize;
  }

  /**
   * Is true if step size is defined as an enum.
   * @returns {boolean}
   */
  public isStepSizeEnum(): boolean {
    return typeof this._customizedStepSize !== 'number';
  }

  /**
   * @return Current grid.
   */
  public getDefaultTimeGrid() {
    return this._defaultGrid;
  }
}

/**
 * Enum providing all possible values of a default time grid.
 */
export enum ETimeGradationTimeGrid {
  NONE = 'none',
  MINUTE = 'minute',
  HOUR = 'hour',
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  QUARTER = 'quarter',
  CUSTOMIZED = 'customized',
}

/**
 * Enum providing all possible values of an enum step size.
 */
export enum ETimeGradationEnumStepSize {
  HOURLY = 'HOURLY',
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY',
}

/**
 * Enum providing all possible tima gradation rounding types.
 */
export enum ETimeGradationRoundingType {
  UP = 'UP',
  DOWN = 'DOWN',
  HALF = 'HALF',
}
