import { CdkDrag } from '@angular/cdk/drag-drop';
import { Component, Inject, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Action } from '@app-modeleditor/components/button/action/action';
import { Button } from '@app-modeleditor/components/button/button';
import { EButtonDisplayType } from '@app-modeleditor/components/button/button-display-type.enum';
import { Observable, of, Subject } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';
import { ContextMenuItem } from '../../modeleditor/components/contextmenu/context-menu-item';
import { ContextMenu } from '../../modeleditor/components/contextmenu/contextmenu';
import { CONTAINER_DATA, Message } from './message';

@Component({
  selector: 'app-message-overlay',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
  providers: [],
})
export class MessageComponent implements OnInit, OnDestroy {
  closeBtn: Button;
  menuBtn: Button;
  closeTrigger: Subject<void> = new Subject<void>();

  pinBtn: ContextMenuItem;
  constructor(@Inject(CONTAINER_DATA) public message: Message, private zone: NgZone) {
    this.fontSize = this.getFontSize(this.message.getText());
  }

  onDragStarted(event: { source: CdkDrag }): void {
    event.source.getRootElement().classList.remove('animated');
  }

  onDragEnded(event: { source: CdkDrag }): void {
    event.source.getRootElement().classList.add('animated');
  }

  ngOnInit(): void {
    // initialize close btn
    this.closeBtn = new Button().setName('close');
    this.closeBtn.chainActions(new Action().setCb(this.close.bind(this)));

    // create new menu
    const menu: ContextMenu = new ContextMenu();

    // initialize menu
    this.menuBtn = new ContextMenuItem()
      .setIcon('keyboard_arrow_down')
      .setDisplayType(EButtonDisplayType.LABEL_AND_ICON)
      .setName('more')
      .setMenu(menu);

    // add pin button
    this.pinBtn = new ContextMenuItem()
      .setName('BUTTON.pin')
      .setIcon('gps_fixed')
      .chainActions(new Action(() => of(this.pinMessage())));
    menu.addContextMenuItems(this.pinBtn);

    // add custom actions
    if (this.message.getActions().length > 0) {
      this.message.getActions().forEach((action: ContextMenuItem) => {
        menu.addContextMenuItems(action);
      });
    }

    // set close timeout
    this.onLeave(null);
  }

  ngOnDestroy(): void {
    this.closeTrigger.next();
    this.closeTrigger.complete();
  }

  pinMessage(): void {
    this.message.setPinned(!this.message.isPinned());
    if (this.message.isPinned() === true) {
      this.onEnter(null);
      this.pinBtn.setIcon('gps_not_fixed').setName('BUTTON.unpin');
    } else {
      this.onLeave(null);
      this.pinBtn.setIcon('gps_fixed').setName('BUTTON.pin');
    }
  }

  onEnter(event: MouseEvent): void {
    this.closeTrigger.next();
  }

  onLeave(event: MouseEvent): void {
    this.closeTrigger.next();
    if (this.message.isPinned() === true) {
      return;
    }

    this.zone.runOutsideAngular(() => {
      of(null)
        .pipe(delay(this.message.getDuration()), takeUntil(this.closeTrigger))
        .subscribe(() => {
          this.zone.run(() => {
            this.close().subscribe();
          });
        });
    });
  }

  fontSize: string;
  getFontSize(str: string): string {
    if (str.length > 150) {
      return '10px';
    } else if (str.length > 100) {
      return '12px';
    } else if (str.length > 75) {
      return '13px';
    } else {
      return '14px';
    }
  }

  private close(): Observable<any> {
    return of(this.message.getRef().dispose());
  }
}
