import { Zoom } from './zoom';
import { GridSelection } from './selection';

export class GridLayout {
  private zoom: Zoom;
  private gridStart: number;
  private contentId: string;
  private zoomValuesChange: boolean;
  private selection: GridSelection;

  public getSelection(): GridSelection {
    return this.selection;
  }

  public setSelection(selection: GridSelection): this {
    this.selection = selection;
    return this;
  }
  public getZoomValuesChange(): boolean {
    return this.zoomValuesChange;
  }

  public setZoomValuesChange(zoomValuesChange: boolean): this {
    this.zoomValuesChange = zoomValuesChange;
    return this;
  }

  public getZoom(): Zoom {
    return this.zoom;
  }

  public setZoom(zoom: Zoom): this {
    this.zoom = zoom;
    return this;
  }

  public getGridStart(): number {
    return this.gridStart;
  }

  public setGridStart(size: number): this {
    this.gridStart = size;
    return this;
  }

  public getContentId(): string {
    return this.contentId;
  }

  public setContentId(contentId: string): this {
    this.contentId = contentId;
    return this;
  }
}
