<div class="colorpicker-wrapper" *ngIf="template">
  <color-sketch
    [width]="250"
    [class.hidden]="hasPresetColors"
    [color]="currentColor"
    [presetColors]="template.getPossibileValues()"
    (onChangeComplete)="handleChange($event)"
  >
  </color-sketch>
</div>
