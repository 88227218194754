import { DataManipulator } from '../data-handler/data-tools/data-manipulator';

/**
 * This class helps to colorize shifts inside gantt and resets colorizment.
 * @keywords color, filter, shift, additional, data, dataset
 * @class
 * @constructor
 */
export class FilterByColor {
  originShiftColorList: any;

  constructor() {
    this.originShiftColorList = {};
  }

  /**
   * Stores all colors of shifts of given origin dataset.
   * @keywords save, reset, store, origin, dataset, data, gantt, color, list
   * @param {GanttData} ganttOriginData Origin dataset of gantt.
   */
  storeOriginColors(ganttOriginData) {
    const s = this;

    s.originShiftColorList = {};

    const createShiftColorList = function (childData) {
      for (let i = 0; i < childData.shifts.length; i++) {
        const shift = childData.shifts[i];
        s.originShiftColorList[shift.id] = new FilterByColorItem(shift.color);
      }
    };

    DataManipulator.iterateOverDataSet(ganttOriginData.ganttEntries, { createShiftColorList: createShiftColorList });
  }
  /**
   * Colorizes shifts by an additional data value.
   * @keywords color, colorize, shift, data, additional, dataset, info, property
   * @param {BestGantt} ganttDiagram Gantt diagram.
   * @param {string} additionalProperty Additional data property of shift.
   */
  filter(ganttDiagram, additionalProperty) {
    const s = this;

    // get original data of gantt
    const dataHandler = ganttDiagram.getDataHandler();
    const ganttData = dataHandler.getOriginDataset();

    // colorize original dataset
    ganttData.ganttEntries = DataManipulator.generateColorByAdditionalData(ganttData.ganttEntries, additionalProperty);

    dataHandler.setOriginDataset(ganttData);

    s._regenerateDatasets(dataHandler);
    s._rerenderGanttShifts(ganttDiagram);
  }
  /**
   * Colorizes broken due dates of shifts.
   * @keywords broken, duedate, due, date, colorize, color, mark, highlight
   * @param {BestGantt} ganttDiagram Gantt diagram.
   */
  brokenDueDate(ganttDiagram) {
    const s = this;

    // get original data of gantt
    const dataHandler = ganttDiagram.getDataHandler();
    const ganttData = dataHandler.getOriginDataset();

    const colorizeByBrokenDueDate = function (child) {
      for (let i = 0; i < child.shifts.length; i++) {
        const shift = child.shifts[i];

        if (
          !shift.modificationRestriction ||
          (!shift.modificationRestriction.earliestStartTime && shift.modificationRestriction.earliestStartTime != 0) ||
          (!shift.modificationRestriction.latestEndTime && shift.modificationRestriction.latestEndTime != 0)
        ) {
          return;
        }

        const timeStart = new Date(shift.timePointStart),
          timeEnd = new Date(shift.timePointEnd),
          earliestStart = new Date(shift.modificationRestriction.earliestStartTime),
          latestEnd = new Date(shift.modificationRestriction.latestEndTime);

        if (
          !(timeStart.getTime() >= earliestStart.getTime() - 1000 && latestEnd.getTime() + 1000 >= timeEnd.getTime())
        ) {
          shift.color = 'red';
        }
      }
    };

    DataManipulator.iterateOverDataSet(ganttData.ganttEntries, { colorizeByBrokenDueDate: colorizeByBrokenDueDate });

    dataHandler.setOriginDataset(ganttData);

    s._regenerateDatasets(dataHandler);
    s._rerenderGanttShifts(ganttDiagram);
  }
  /**
   * Resets all shift colors to origin colors.
   * @keywords reset, revert, color, colors, original, shift
   * @param {BestGantt} ganttDiagram Gantt diagram.
   */
  resetColors(ganttDiagram) {
    const s = this;

    const dataHandler = ganttDiagram.getDataHandler(),
      ganttDataset = dataHandler.getOriginDataset();

    const setoriginalShiftColors = function (childData) {
      for (let i = 0; i < childData.shifts.length; i++) {
        const shift = childData.shifts[i];
        shift.color = s.originShiftColorList[shift.id].color;
      }
    };
    DataManipulator.iterateOverDataSet(ganttDataset.ganttEntries, { setoriginalShiftColors: setoriginalShiftColors });

    s._regenerateDatasets(dataHandler);
    s._rerenderGanttShifts(ganttDiagram);
  }
  /**
   * Generates new shift and milestone canvas dataset s out of datasets.
   * @private
   * @param {DataHandler} dataHandler Gantt data handler.
   */
  private _regenerateDatasets(dataHandler) {
    dataHandler.initCanvasShiftData();
    dataHandler.initCanvasMileStoneData();
  }
  /**
   * Rerenders all shifts and milesontes of gantt.
   * @private
   * @param {BestGantt} ganttDiagram Gantt diagram.
   */
  private _rerenderGanttShifts(ganttDiagram) {
    ganttDiagram.rerenderShiftsVertical();
    ganttDiagram.rerenderMilestonesVertical();
  }
}

/**
 * Color item.
 * @keywords data, class, color, item, data, save
 * @class
 * @constructor
 * @param {string} color Color value.
 */
export class FilterByColorItem {
  color: string;

  constructor(color) {
    this.color = color;
  }
}
