import { Component, OnInit, Input, ComponentFactoryResolver, ViewContainerRef } from '@angular/core';
import { Widget } from 'frontend/src/dashboard/data/grid/data/widget';

export enum WidgetType {
  BAR,
  TABLE,
  PIE,
  KPI,
  CALENDAR,
  LINE,
  GANTT,
  TEST,
  ERROR,
}
export enum ComponentType {
  TABLES = 'TABLES',
  CHARTS = 'CHARTS',
  GANTT = 'GANTT',
  TEMPLATE = 'TEMPLATE',
  TEST = 'TEST',
  ERROR = 'ERROR',
  COMBINE = 'COMBINE',
}

@Component({
  selector: 'app-general-widget',
  templateUrl: './general-widget.component.html',
  styleUrls: ['./general-widget.component.scss'],
})
export class GeneralWidgetComponent implements OnInit {
  @Input() widget: Widget;
  @Input() widgetComponent: string;
  widgetType = '';
  templateType = '';
  instace: any;

  constructor() {}

  ngOnInit() {
    this.widgetType = this.getComponent();
  }

  getComponent() {
    switch (this.widgetComponent) {
      case 'table':
        this.widget.type = 'table';
        return ComponentType.TABLES;

      case 'piechart':
        this.widget.type = 'pie';
        return ComponentType.CHARTS;

      case 'kpi':
        this.widget.type = 'kpi';
        return ComponentType.TABLES;

      case 'barchart':
        this.widget.type = 'bar';
        return ComponentType.CHARTS;

      case 'linechart':
        this.widget.type = 'line';
        return ComponentType.CHARTS;

      case 'gantt':
        this.widget.type = 'gantt';
        return ComponentType.GANTT;

      case 'template':
        this.widget.type = 'template';
        return ComponentType.TEMPLATE;

      case 'combinedWidget':
        this.widget.type = 'combine';
        return ComponentType.COMBINE;

      case 'error':
        this.widget.type = 'error';
        return ComponentType.ERROR;
    }
  }
}
