import { Component, EventEmitter, Input, OnDestroy } from '@angular/core';
import { of, Subject } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'colorpickerpopup',
  templateUrl: './colorpickerpopup.html',
  styleUrls: ['./colorpickerpopup.scss'],
})
export class ColorpickerPopupComponent implements OnDestroy {
  @Input() widget: any = {};
  @Input() currentColor: any;
  @Input() standartColor: any;
  public onChangeEmitter: EventEmitter<any> = new EventEmitter();
  colorTimeout: Subject<void> = new Subject<void>();
  constructor() {}

  public onChangeColor(color: any): void {
    this.colorTimeout.next();
    this.currentColor = color.value;
    of(null)
      .pipe(delay(300), takeUntil(this.colorTimeout))
      .subscribe(() => {
        // this.widget.callback(color.value);

        this.onChangeEmitter.emit(color.value);
      });
  }
  ngOnDestroy(): void {
    this.colorTimeout.next();
    this.colorTimeout.complete();
  }

  public onClose(success: boolean): void {
    if (this.widget.close) {
      if (!success) {
        this.onChangeEmitter.emit(this.widget.standartColor);
      }
      this.widget.close(success);
    }
  }
}
