export enum EGanttToolbarIdentifier {
  DAY_FORMAT = 'dayformat',
  INDEX_CARD_VIEW = 'indexcardsview',
  SUPER_BLOCK_VIEW = 'superblockview',
  WEEK_FORMAT = 'weekformat',
  ATTRIBUTE_SEARCH_INPUT = 'attributesearchinput',
  ATTRIBUTE_SEACH_INPUT_HIDE_ROWS = 'attributesearchinputhiderows',
  ATTRIBUTE_COLOR_SELECTOR = 'attributecolorselector',
  ATTRIBUTE_SEARCH_MODE = 'attributesearchmode',
  ATTRIBUTE_SEARCH_TYPE = 'attributesearchtype',
  TOGGLE_TRANSFORMABLE = 'toggletransformable',
  ZOOM_ALL = 'zoomAll',
  ZOOM_IN = 'zoomIn',
  ZOOM_OUT = 'zoomOut',
  ZOOM_RESET = 'zoomReset',
  NAVIGATION_START = 'navigation.start',
  NAVIGATION_END = 'navigation.end',
  NAVIGATION_INTERVAL = 'navigation.interval',
  Y_AXIS_SEARCH_NAME = 'yaxis.searchname',
  Y_AXIS_SEARCH_INPUT = 'yaxissearchinput',
  TOGGLE_CHANGE_ROW = 'togglechangerow',
  SELECT_COMPARE = 'selectcompare',
  ADD = 'add',
  DELETE = 'delete',
  INFO = 'info',
  START = 'start',
  END = 'end',
  TOGGLE_BLOCK_ADD = 'toggleblockadd',
  ZOOM_INITIAL = 'zoomInitial',
  ZOOM_CURRENT_DAY = 'zoomCurrentDay',
  ZOOM_CURRENT_WEEK = 'zoomCurrentWeek',
  ZOOM_MONTH = 'zoomMonth',
  TOGGLE_ZOOM_TO_TIME_SELECTION = 'toggleZoomToTimeSelection',
  LABEL_MODEL_SELECTOR = 'labelmodeselector',
  TOGGLE_GANTT_ENTRY_BACKGROUND_COLOR = 'toggleGanttEntryBackgroundColor',
  TOGGLE_GANTT_EARLIEST_START_LATEST_END = 'toggleGanttEarliestStartLatestEnd',
  TOGGLE_SHOW_TOOLTIPS = 'toggleShowTooltips',
  CHANGE_VISIBLE_ATTRIBUTE = 'changevisibleattribute',
  ATTRIBUTE_NAME_SELECTOR = 'attributenameselector',
  COLORIZE_RESOURCES = 'colorizeresources',
  TOOLTIP_SETTINGS = 'tooltipSettings',
  GANTT_INDEX_CARDS_SETTINGS = 'indexCardsSettings',
  /**
   * Filter for interval groups
   */
  FILTER_BLOCKING_INTERVALS = 'filterBlockingIntervals',
  /**
   * Filter for interval by defined attribute values
   */
  TOGGLE_INTERVAL_FILTER_BY_ATTRIBUTE = 'toggleIntervalFilterByAttribute',
}
