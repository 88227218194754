import { Component, Input } from '@angular/core';

@Component({
  selector: 'widgetpopup',
  templateUrl: './widgetPopup.html',
  styleUrls: ['./widgetPopup.scss'],
})
export class WidgetPopupComponent {
  @Input() widget: any = {};
  constructor() {}
}
