import { EGanttScrollContainer } from '../../html-structure/scroll-container.enum';
import { BestGantt } from '../../main';

/**
 * Handler methods for xAxis events.
 * @class
 */
export class GanttXAxisHandler {
  ganttDiagram: BestGantt;

  constructor(ganttDiagram) {
    this.ganttDiagram = ganttDiagram;
  }

  /**
   * Callback to timespan width after zooming.
   * @keywords callback, mark, background, weekends, zoom
   * @private
   * @param {ZoomEvent} zoomEvent D3 ZoomEvent
   */
  _rerenderTimeSpanByZoom(zoomEvent) {
    const s = this.ganttDiagram;
    const currentTimeFormat = s.getXAxisBuilder().getCurrentTimeFormat();

    s.getGlobalTimeSpanMarker()
      .getCanvas(EGanttScrollContainer.DEFAULT)
      .attr('transform', 'translate(' + zoomEvent.transform.x + ',0) scale(' + zoomEvent.transform.k + ', 1)');

    s.getGlobalTimeSpanMarker()
      .getCanvas(EGanttScrollContainer.STICKY_ROWS)
      .attr('transform', 'translate(' + zoomEvent.transform.x + ',0) scale(' + zoomEvent.transform.k + ', 1)');

    s.getGlobalTimeSpanMarker()
      .getXAxisCanvas()
      .attr('transform', 'translate(' + zoomEvent.transform.x + ',0) scale(' + zoomEvent.transform.k + ', 1)');

    if (currentTimeFormat.value > s.config.getGlobalShift()) s.getGlobalTimeSpanMarker().show();
    else s.getGlobalTimeSpanMarker().hide();
  }

  /**
   * @private
   */
  _renderShiftAreaOnZoomEnd(zoomEvent) {
    const self = this;
    const s = this.ganttDiagram;

    const transform = zoomEvent && zoomEvent.transform ? zoomEvent.transform : s.getXAxisBuilder().getLastZoomEvent();

    self.reScaleWhileZoomHelper(transform);
    s.filterRenderDataSetsByCurrentView();

    s.getShiftFacade().scaleHorizontal(zoomEvent);
    s.getShiftFacade().renderShifts(s.getRenderDataSetShifts());
    s.getShiftFacade().getCanvasAnimator().removeCanvasContent();
  }

  /**
   * @private
   */
  _removeShiftAreaSVGElementsBeforeZoom(zoomEvent) {
    const self = this;
    const s = this.ganttDiagram;
    s.removeShiftAreaSVGElements();
    self._rerenderShiftsByZoom(zoomEvent);
  }

  /**
   * Updates canvas shift and milestone elements in horizontal direction.
   * @private
   * @param {ZoomEvent} zoomEvent D3 ZoomEvent.
   */
  _rerenderShiftsByZoom(zoomEvent) {
    const self = this;
    const s = this.ganttDiagram;

    const transform = zoomEvent && zoomEvent.transform ? zoomEvent.transform : s.getXAxisBuilder().getLastZoomEvent();

    self.reScaleWhileZoomHelper(transform);
    s.filterRenderDataSetsByCurrentView();

    s.getShiftFacade().scaleHorizontal(zoomEvent);
    s.getShiftFacade().rerenderShiftArea(s.getRenderDataSetShifts(), transform);
  }

  /**
   * Reusable functions that called while zooming.
   * @param {*} transform D3 transform
   */
  reScaleWhileZoomHelper(transform) {
    const s = this.ganttDiagram;

    const canvasTransform = s.getRenderDataHandler().getCanvasTransform();
    canvasTransform.setScaleX(transform.k);
    canvasTransform.setLeft(transform.x * -1);

    s.getShiftFacade().renderVerticalLines(s.getXAxisBuilder().getVerticalLinesGenerators());
  }

  /**
   * Resizes xAxis Brush Selection to a min width of 2 px if it's smaller, so it doesnt disappear.
   */
  _minWidthOnXAxisBrushSelection() {
    const s = this.ganttDiagram;
    const minBrushSelectionWidth = 3;
    if (
      parseFloat(s.getXAxisBuilder().scrollBar.selection.selectAll('.selection').attr('width')) < minBrushSelectionWidth
    ) {
      s.getXAxisBuilder().scrollBar.selection.selectAll('.selection').attr('width', minBrushSelectionWidth);
    }
  }
}
