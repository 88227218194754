import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { MatLegacySlideToggleChange as MatSlideToggleChange } from '@angular/material/legacy-slide-toggle';
import { EGanttXAxisPosition } from '@gantt/public-api';
import { TranslateService } from '@ngx-translate/core';
import { SaxMsBestGanttSettings } from 'frontend/src/dashboard/gantt/gantt/saxms-best-gantt.settings';
import {
  SaxMsBestGanttZoomGridMode,
  SaxMsBestGanttZoomGridStep,
} from 'frontend/src/dashboard/gantt/general/gantt-settings/gantt-settings-dialog/gantt-style.enum';
import { IAvailableFederalState } from 'frontend/src/dashboard/gantt/general/generator/predefined/holidays/gantt-holiday';

interface IMarkerFormatSelection {
  value: string;
  viewValue: string;
}

interface ITimeRasterSelection {
  value: SaxMsBestGanttZoomGridStep;
  viewValue: string;
}

interface ITimeRasterModeSelection {
  value: SaxMsBestGanttZoomGridMode;
  viewValue: string;
}

@Component({
  selector: 'app-date-and-time',
  templateUrl: './date-and-time.component.html',
  styleUrls: ['./date-and-time.component.scss'],
})
export class DateAndTimeComponent implements OnInit {
  @Input() settings: SaxMsBestGanttSettings;
  @Output() changeEvent: EventEmitter<null> = new EventEmitter();

  public markerFormatOptions: IMarkerFormatSelection[] = [
    {
      value: this.translate.instant('GANTT.TIME_MARKER.VALUES.all_Value'),
      viewValue: 'GANTT.TIME_MARKER.VALUES.all_Name',
    },
    {
      value: this.translate.instant('GANTT.TIME_MARKER.VALUES.allShort_Value'),
      viewValue: 'GANTT.TIME_MARKER.VALUES.allShort_Name',
    },
    {
      value: this.translate.instant('GANTT.TIME_MARKER.VALUES.date_Value'),
      viewValue: 'GANTT.TIME_MARKER.VALUES.date_Name',
    },
    {
      value: this.translate.instant('GANTT.TIME_MARKER.VALUES.allNoYear_Value'),
      viewValue: 'GANTT.TIME_MARKER.VALUES.allNoYear_Name',
    },
    {
      value: this.translate.instant('GANTT.TIME_MARKER.VALUES.allNoYearNoSeconds_Value'),
      viewValue: 'GANTT.TIME_MARKER.VALUES.allNoYearNoSeconds_Name',
    },
    {
      value: this.translate.instant('GANTT.TIME_MARKER.VALUES.time_Value'),
      viewValue: 'GANTT.TIME_MARKER.VALUES.time_Name',
    },
  ];
  public timeRasterStepOptions: ITimeRasterSelection[] = [
    { value: SaxMsBestGanttZoomGridStep.HOURLY, viewValue: 'hour' },
    { value: SaxMsBestGanttZoomGridStep.DAILY, viewValue: 'day' },
    { value: SaxMsBestGanttZoomGridStep.WEEKLY, viewValue: 'week' },
    { value: SaxMsBestGanttZoomGridStep.MONTHLY, viewValue: 'month' },
    { value: SaxMsBestGanttZoomGridStep.QUARTERLY, viewValue: 'quarter' },
    { value: SaxMsBestGanttZoomGridStep.CUSTOM, viewValue: 'custom' },
  ];

  public timeRasterModeOptions: ITimeRasterModeSelection[] = [
    { value: SaxMsBestGanttZoomGridMode.OFF, viewValue: 'off' },
    { value: SaxMsBestGanttZoomGridMode.AUTO, viewValue: 'automatic' },
    { value: SaxMsBestGanttZoomGridMode.STATIC, viewValue: 'static' },
  ];

  public availableFederalStatesOptions: IAvailableFederalState[] = [];
  public selectedMarkerFormatOption: string;
  public selectedTimeRasterStepOption: SaxMsBestGanttZoomGridStep;
  public selectedTimeRasterModeOption: SaxMsBestGanttZoomGridMode;

  public selectedHolidayOption = '';
  public timeRasterMode = SaxMsBestGanttZoomGridMode;
  public timeRasterStep = SaxMsBestGanttZoomGridStep;
  public saxMsBestGanttXAxisPosition = EGanttXAxisPosition;
  public selectedCalendarPosition: EGanttXAxisPosition = EGanttXAxisPosition.TOP;
  public refDate: UntypedFormControl;
  public durationPickerValues: string[] = ['minutes', 'hours', 'days', 'weeks'];

  constructor(private translate: TranslateService) {}

  ngOnInit(): void {
    this.selectedMarkerFormatOption =
      this.markerFormatOptions.find((x) => x.value === this.settings.xAxisMarkerFormat)?.value ||
      this.markerFormatOptions[0].value;
    this.selectedHolidayOption = this.settings.calendar?.selectedFilterFederalState || undefined;
    this.selectedCalendarPosition = this.settings.xAxisPosition || EGanttXAxisPosition.TOP;
    this.selectedTimeRasterStepOption = this.settings.zoomGridStep || SaxMsBestGanttZoomGridStep.HOURLY;
    this.selectedTimeRasterModeOption = this.settings.zoomGridMode || SaxMsBestGanttZoomGridMode.OFF;
    this.refDate = new UntypedFormControl(new Date(this.settings.zoomGridRef || 0));
    this.availableFederalStatesOptions = this.settings.availableFederalStates || [];
  }

  public onMarkerFormatSelectionChange(event: IMarkerFormatSelection): void {
    this.settings.xAxisMarkerFormat = event.value;
    this.changeEvent.emit();
  }

  public setDailyColor(color: string): void {
    this.settings.defaultRowColor = color;
    this.changeEvent.emit();
  }

  public handleWeekendVisibility(event: MatSlideToggleChange): void {
    this.settings.showWeekends = event.checked;
    this.changeEvent.emit();
  }

  public setWeekendColor(color: string): void {
    this.settings.weekendColor = color;
    this.changeEvent.emit();
  }

  public handleHolidayVisibility(event: MatSlideToggleChange): void {
    this.settings.calendar.showHolidays = event.checked;
    this.changeEvent.emit();
  }

  public setHolidayColor(color: string): void {
    this.settings.holidayColor = color;
    this.changeEvent.emit();
  }

  public onHolidaySelectionChange(event: MatSelectChange): void {
    this.settings.calendar.selectedFilterFederalState = event.value;
    this.changeEvent.emit();
  }

  protected handleTimeIntervalVisibility(event: MatSlideToggleChange): void {
    this.settings.calendar.showTimeIntervals = event.checked;
    this.changeEvent.emit();
  }

  public handleCalendarVisibility(event: MatSlideToggleChange): void {
    this.settings.xaxisVisible = event.checked;
    this.changeEvent.emit();
  }

  public onCalendarPositionChange(event: EGanttXAxisPosition): void {
    this.settings.xAxisPosition = event;
    this.changeEvent.emit();
  }

  protected handleCalendarWeekdayVisibility(event: MatSlideToggleChange): void {
    this.settings.xAxisShowWeekdays = event.checked;
    this.changeEvent.emit();
  }

  public onTimeRasterModeSelectionChange(event: ITimeRasterModeSelection): void {
    this.settings.zoomGridMode = event.value;
    this.changeEvent.emit();
  }

  public onTimeRasterStepSelectionChange(event: ITimeRasterSelection): void {
    this.settings.zoomGridStep = event.value;
    this.changeEvent.emit();
  }

  public onRefPointChange(event: any): void {
    this.settings.zoomGridRef = event.value.toDate().getTime();
    this.changeEvent.emit();
  }

  public onStepWidthChange(event: number): void {
    this.settings.zoomGridWidth = event;
    this.changeEvent.emit();
  }
}
