import { EGanttInstance } from '@gantt/public-api';
import { GanttLibService } from 'frontend/src/dashboard/gantt/gantt/gantt-lib.service';
import { GanttTemplateData } from 'frontend/src/dashboard/gantt/helper/gantt';
import { GanttXAxisConfig } from './i-gantt-x-axis-config';

/**
 * Handler wich bundles functionality which affects the gantt config but is not a part of the gantt user settings.
 */
export class GanttConfigHandling {
  constructor(private ganttLibService: GanttLibService) {}

  /**
   * Adds all initial config data by backend template.
   * @param ganttTemplateData backend gantt template.
   */
  public addAllAttributes(ganttTemplateData: GanttTemplateData): void {
    if (ganttTemplateData.getEditAllowSettings()) {
      if (ganttTemplateData.getEditAllowSettings().resizeMinDur)
        this.setResizeMinDur(ganttTemplateData.getEditAllowSettings().resizeMinDur);
      if (ganttTemplateData.getEditAllowSettings().resizeMaxDur)
        this.setResizeMaxDur(ganttTemplateData.getEditAllowSettings().resizeMaxDur);
    }

    // xAxis Settings injected in gantt
    if (ganttTemplateData.getXAxisSettings()) {
      const xAxisData: GanttXAxisConfig[] = ganttTemplateData.getXAxisSettings();

      const axesCollection: any[] = [];
      for (let i = 0; i < xAxisData.length; i++) {
        const axis = xAxisData[i];
        const ganttAxis = this.ganttLibService.ganttInstanceService.getInstance(EGanttInstance.X_AXIS_FORMAT_GENERAL);

        if (axis.dateFormatList) ganttAxis.setTimeFormat(axis.dateFormatList as any);
        if (axis.cssClassName) ganttAxis.setCssClassName(axis.cssClassName);
        if (axis.cssClassNameText) ganttAxis.setCssClassNameText(axis.cssClassNameText);
        if (axis.height) ganttAxis.setHeight(axis.height);
        if (axis.fontSize) ganttAxis.setFontSize(axis.fontSize);
        if (axis.tickSize) ganttAxis.setTickSize(axis.tickSize);
        if (axis.tickSizeText) ganttAxis.setTickSizeText(axis.tickSizeText);
        if (axis.renderVerticalLinesOnShifts) ganttAxis.setRenderVerticalLines(axis.renderVerticalLinesOnShifts);

        axesCollection.push(ganttAxis);
      }

      this.ganttLibService.bestGantt.getConfig().setXAxesConfig(axesCollection);
    }
  }

  /**
   * Sets the min duration of all shift blocks to limit the resize functionality.
   * @param duration
   */
  public setResizeMinDur(duration: number): void {
    this.ganttLibService.bestGantt.getConfig().setMinShiftTimespan(duration);
  }

  /**
   * Sets the max duration of all shift blocks to limit the resize functionality.
   * @param duration
   */
  public setResizeMaxDur(duration: number): void {
    this.ganttLibService.bestGantt.getConfig().setMaxShiftTimespan(duration);
  }
}
