<h1 style="padding: 16px" mat-dialog-title>Zu viele Anfragen!</h1>
<mat-dialog-content>
  <div style="padding: 16px">
    <p>
      Das System hat ein unerwartetes Verhalten festgestellt. Bitte laden Sie den nachfolgenden Log herunter und senden
      Sie diesen an die Saxony Media Solutions GmbH.
    </p>
    <p>Um den Fehler zu beheben, drücken Sie bitte <b>Shift + F5</b>.</p>
    <pre
      style="max-height: 300px; overflow: auto; background: rgb(75, 75, 75); color: rgb(230, 230, 230); padding: 16px"
    ><code *rxLet="data$; let data">{{data | json}}</code></pre>
  </div>
</mat-dialog-content>
<div mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>
    {{ 'BUTTON.close' | translate }}
  </button>
  <a mat-button mat-dialog-close download="saxms-http-request.json" cdkFocusInitial [href]="downloadUri$ | push">
    {{ 'BUTTON.download' | translate }}
  </a>
</div>
