import { Injectable } from '@angular/core';
import { RxState } from '@rx-angular/state';
import { EGanttScrollContainer } from '../scroll-container.enum';
import { INodeProportion } from './node-proportion.interface';

interface NodeProportionsModel {
  shiftCanvasProportions: INodeProportion;
  shiftCanvasProportionsSticky: INodeProportion;
  shiftViewPortProportions: INodeProportion;
  shiftViewPortProportionsSticky: INodeProportion;
  xAxisProportions: INodeProportion;
  yAxisProportions: INodeProportion;
  yAxisProportionsSticky: INodeProportion;
  scrollTopPosition: number; // stores the current scroll top position
  scrollTopPositionSticky: number; // stores the current scroll top position of the sticky rows container
  currentTopRowId: string; // stores the ID of the first fully visible row which is currently on top
  currentTopRowIdSticky: string;
  currentTopRowOffset: number; // stores the offset from the top ViewPort edge to the topmost fully visible row
  currentTopRowOffsetSticky: number;
  currentTopOriginRowId: string; // stores the ID of the first fully visible origin row which is currently on top
  currentTopOriginRowIdSticky: string;
}

/**
 * Stores proportions of html nodes and states
 */
@Injectable()
export class NodeProportionsState extends RxState<NodeProportionsModel> {
  constructor() {
    super();

    const initValues: NodeProportionsModel = {
      shiftCanvasProportions: { height: 0, width: 0 },
      shiftCanvasProportionsSticky: { height: 0, width: 0 },
      shiftViewPortProportions: { height: 0, width: 0 },
      shiftViewPortProportionsSticky: { height: 0, width: 0 },
      xAxisProportions: { height: 0, width: 0 },
      yAxisProportions: { height: 0, width: 0 },
      yAxisProportionsSticky: { height: 0, width: 0 },
      scrollTopPosition: 0,
      scrollTopPositionSticky: 0,
      currentTopRowOffset: 0,
      currentTopRowOffsetSticky: 0,
      currentTopRowId: '',
      currentTopRowIdSticky: '',
      currentTopOriginRowId: '',
      currentTopOriginRowIdSticky: '',
    };

    this.set(initValues);
  }

  /**
   * Returns the canvas proportion of the shift canvas.
   * @param scrollContainerId Id of the scroll container to return the values for.
   * @returns Object {width: number, height: number}
   */
  public getShiftCanvasProportions(
    scrollContainerId: EGanttScrollContainer = EGanttScrollContainer.DEFAULT
  ): INodeProportion {
    switch (scrollContainerId) {
      case EGanttScrollContainer.STICKY_ROWS:
        return this.get('shiftCanvasProportionsSticky');
      case EGanttScrollContainer.DEFAULT:
      default:
        return this.get('shiftCanvasProportions');
    }
  }

  /**
   * @param shiftCanvasProportions
   * @param scrollContainerId Id of the scroll container to set the values for.
   */
  public setShiftCanvasProportions(
    shiftCanvasProportions: INodeProportion,
    scrollContainerId: EGanttScrollContainer = EGanttScrollContainer.DEFAULT
  ): void {
    switch (scrollContainerId) {
      case EGanttScrollContainer.STICKY_ROWS:
        this.set({ shiftCanvasProportionsSticky: shiftCanvasProportions });
        break;
      case EGanttScrollContainer.DEFAULT:
      default:
        this.set({ shiftCanvasProportions });
        break;
    }
  }

  /**
   * Returns the viewport proportion of the shift canvas.
   * This is only the area you can really see.
   * @param scrollContainerId Id of the scroll container to return the values for.
   * @returns Object {width: number, height: number}
   */
  public getShiftViewPortProportions(
    scrollContainerId: EGanttScrollContainer = EGanttScrollContainer.DEFAULT
  ): INodeProportion {
    switch (scrollContainerId) {
      case EGanttScrollContainer.STICKY_ROWS:
        return this.get('shiftViewPortProportionsSticky');
      case EGanttScrollContainer.DEFAULT:
      default:
        return this.get('shiftViewPortProportions');
    }
  }

  /**
   * @param shiftViewPortProportions
   * @param scrollContainerId Id of the scroll container to set the values for.
   */
  public setShiftViewPortProportions(
    shiftViewPortProportions: INodeProportion,
    scrollContainerId: EGanttScrollContainer = EGanttScrollContainer.DEFAULT
  ): void {
    switch (scrollContainerId) {
      case EGanttScrollContainer.STICKY_ROWS:
        this.set({ shiftViewPortProportionsSticky: shiftViewPortProportions });
        break;
      case EGanttScrollContainer.DEFAULT:
      default:
        this.set({ shiftViewPortProportions });
        break;
    }
  }

  public getXAxisProportions(): INodeProportion {
    return this.get('xAxisProportions');
  }

  public setXAxisProportions(xAxisProportions: INodeProportion): void {
    this.set({ xAxisProportions });
  }

  /**
   * @param scrollContainerId Id of the scroll container to return the values for.
   * @returns
   */
  public getYAxisProportions(
    scrollContainerId: EGanttScrollContainer = EGanttScrollContainer.DEFAULT
  ): INodeProportion {
    switch (scrollContainerId) {
      case EGanttScrollContainer.STICKY_ROWS:
        return this.get('yAxisProportionsSticky');
      case EGanttScrollContainer.DEFAULT:
      default:
        return this.get('yAxisProportions');
    }
  }

  /**
   * @param yAxisProportions
   * @param scrollContainerId Id of the scroll container to set the values for.
   */
  public setYAxisProportions(
    yAxisProportions: INodeProportion,
    scrollContainerId: EGanttScrollContainer = EGanttScrollContainer.DEFAULT
  ): void {
    switch (scrollContainerId) {
      case EGanttScrollContainer.STICKY_ROWS:
        this.set({ yAxisProportionsSticky: yAxisProportions });
        break;
      case EGanttScrollContainer.DEFAULT:
      default:
        this.set({ yAxisProportions });
        break;
    }
  }

  /**
   * @param scrollContainerId Id of the scroll container to return the values for.
   * @returns
   */
  public getScrollTopPosition(scrollContainerId: EGanttScrollContainer = EGanttScrollContainer.DEFAULT): number {
    switch (scrollContainerId) {
      case EGanttScrollContainer.STICKY_ROWS:
        return this.get('scrollTopPositionSticky');
      case EGanttScrollContainer.DEFAULT:
      default:
        return this.get('scrollTopPosition');
    }
  }

  /**
   * Setter is only used by vertical scroll handler!
   * If you want to adjust the scroll top position use the setter in vertical scroll handler instead.
   * @param scrollTopPosition
   * @param scrollContainerId Id of the scroll container to set the values for.
   */
  public setScrollTopPosition(
    scrollTopPosition: number,
    scrollContainerId: EGanttScrollContainer = EGanttScrollContainer.DEFAULT
  ): void {
    switch (scrollContainerId) {
      case EGanttScrollContainer.STICKY_ROWS:
        this.set({ scrollTopPositionSticky: scrollTopPosition });
        break;
      case EGanttScrollContainer.DEFAULT:
      default:
        this.set({ scrollTopPosition });
        break;
    }
  }

  /**
   * @param scrollContainerId Id of the scroll container to return the values for.
   * @returns
   */
  public getCurrentTopRowId(scrollContainerId: EGanttScrollContainer = EGanttScrollContainer.DEFAULT): string {
    switch (scrollContainerId) {
      case EGanttScrollContainer.STICKY_ROWS:
        return this.get('currentTopRowIdSticky');
      case EGanttScrollContainer.DEFAULT:
      default:
        return this.get('currentTopRowId');
    }
  }

  /**
   * @param currentTopRowId
   * @param scrollContainerId Id of the scroll container to set the values for.
   */
  public setCurrentTopRowId(
    currentTopRowId: string,
    scrollContainerId: EGanttScrollContainer = EGanttScrollContainer.DEFAULT
  ): void {
    switch (scrollContainerId) {
      case EGanttScrollContainer.STICKY_ROWS:
        this.set({ currentTopRowIdSticky: currentTopRowId });
        break;
      case EGanttScrollContainer.DEFAULT:
      default:
        this.set({ currentTopRowId });
        break;
    }
  }

  /**
   * @param scrollContainerId Id of the scroll container to return the values for.
   * @returns
   */
  public getCurrentTopRowOffset(scrollContainerId: EGanttScrollContainer = EGanttScrollContainer.DEFAULT): number {
    switch (scrollContainerId) {
      case EGanttScrollContainer.STICKY_ROWS:
        return this.get('currentTopRowOffsetSticky');
      case EGanttScrollContainer.DEFAULT:
      default:
        return this.get('currentTopRowOffset');
    }
  }

  /**
   * @param currentTopRowOffset
   * @param scrollContainerId Id of the scroll container to set the values for.
   */
  public setCurrentTopRowOffset(
    currentTopRowOffset: number,
    scrollContainerId: EGanttScrollContainer = EGanttScrollContainer.DEFAULT
  ): void {
    switch (scrollContainerId) {
      case EGanttScrollContainer.STICKY_ROWS:
        this.set({ currentTopRowOffsetSticky: currentTopRowOffset });
        break;
      case EGanttScrollContainer.DEFAULT:
      default:
        this.set({ currentTopRowOffset });
        break;
    }
  }

  /**
   * @param scrollContainerId Id of the scroll container to return the values for.
   * @returns
   */
  public getCurrentTopOriginRowId(scrollContainerId: EGanttScrollContainer = EGanttScrollContainer.DEFAULT): string {
    switch (scrollContainerId) {
      case EGanttScrollContainer.STICKY_ROWS:
        return this.get('currentTopOriginRowIdSticky');
      case EGanttScrollContainer.DEFAULT:
      default:
        return this.get('currentTopOriginRowId');
    }
  }

  /**
   * @param currentTopOriginRowId
   * @param scrollContainerId Id of the scroll container to set the values for.
   */
  public setCurrentTopOriginRowId(
    currentTopOriginRowId: string,
    scrollContainerId: EGanttScrollContainer = EGanttScrollContainer.DEFAULT
  ): void {
    switch (scrollContainerId) {
      case EGanttScrollContainer.STICKY_ROWS:
        this.set({ currentTopOriginRowIdSticky: currentTopOriginRowId });
        break;
      case EGanttScrollContainer.DEFAULT:
      default:
        this.set({ currentTopOriginRowId });
        break;
    }
  }
}
