import { Component, Input, OnInit } from '@angular/core';
import { FilterElement } from './filter-element';

/**
 * @deprecated please use quick filter of table instead
 */
@Component({
  selector: 'app-filter-element',
  templateUrl: './filter-element.component.html',
  styleUrls: ['./filter-element.component.scss'],
})
export class FilterElementComponent implements OnInit {
  @Input() template: FilterElement;

  ngOnInit(): void {}

  afterChanges(cb?: any) {
    this.template.executeChanges();
  }
}
