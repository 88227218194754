<!-- Container for legend entry WITH pointer interaction features -->
<div
  class="entry entryPointer"
  *ngIf="pointerInteraction; else noPointerInteraction"
  matRipple
  [matTooltip]="entryData.tooltip"
  [matTooltipShowDelay]="1000"
  (click)="click()"
>
  <ng-container *ngTemplateOutlet="content"></ng-container>
</div>

<!-- Container for legend entry WITHOUT pointer interaction features -->
<ng-template #noPointerInteraction>
  <div class="entry" [matTooltip]="entryData.tooltip" [matTooltipShowDelay]="1000">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </div>
</ng-template>

<!-- Entry content elements -->
<ng-template #content>
  <!-- Indicator -->
  <div
    [class]="getLegendEntryClassname()"
    #indicator
    [ngStyle]="{
      'background-color': entryData.color,
      'border-color': entryData.strokeColor,
      opacity: entryData.isActive ? '1' : '0.5'
    }"
    [class.indicator-border]="entryData.strokeColor && !entryData.strokePattern"
    [class.indicator-dasharray-border]="entryData.strokeColor && entryData.strokePattern && entryData.pattern"
  ></div>

  <!-- Label -->
  <div class="label" [ngStyle]="{ opacity: entryData.isActive ? '1' : '0.5' }">
    {{ entryData?.label || 'No label' }}
  </div>
</ng-template>
