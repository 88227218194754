import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TemplateDatepickerModule } from '@app-modeleditor/components/template-datepicker/template-datepicker.module';
import { FullCalendarModule } from '@fullcalendar/angular';
import { TranslateModule } from '@ngx-translate/core';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { TemplateButtonModule } from '../button/button.module';
import { EntryCollectionModule } from './../entry-collection/entry-collection.module';
import { RangePickerComponent } from './range-picker.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    FullCalendarModule,
    TemplateDatepickerModule,
    TemplateButtonModule,
    EntryCollectionModule,
    NgScrollbarModule,
  ],
  declarations: [RangePickerComponent],
  exports: [RangePickerComponent],
})
export class RangePickerModule {}
