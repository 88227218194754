import { GanttLibService } from 'frontend/src/dashboard/gantt/gantt/gantt-lib.service';
import { TriggerEventFactory } from 'frontend/src/dashboard/gantt/general/gantt-actions/event-factory';
import { GanttPluginHandlerService } from 'frontend/src/dashboard/gantt/general/plugin/gantt-plugin-handler.service';
import { Observable, of } from 'rxjs';
import { GeneralGanttActionHandler } from '../../action-handling/action-handler';
import { GanttResponseHandler } from '../../response/response-handler';
import { GanttExternalActionRegistration } from '../external-action-registration';
import { GanttEventTrigger } from '../external-event-trigger';
import { TriggerEventGanttFactory } from './event-factory';
import { GanttAction } from './gantt-action.interface';

/**
 * Action handler for gantt actions like block drag-and-drop or zoom events.
 */
export class GanttExternalActionHandler implements GanttExternalActionRegistration {
  public currentActionTriggers: GanttEventTrigger[] = [];
  private triggerEventFactory: TriggerEventFactory;

  constructor(
    private _ganttPluginHandlerService: GanttPluginHandlerService,
    private _ganttLibService: GanttLibService,
    private actionHandler: GeneralGanttActionHandler,
    private responseHandler: GanttResponseHandler,
    templateData: any,
    ganttActions: GanttAction[]
  ) {
    this.triggerEventFactory = new TriggerEventGanttFactory(templateData);
    if (ganttActions) this.currentActionTriggers = this.extractEventTriggersByGanttActions(ganttActions);
  }

  /**
   * Uses list of backend defined actions to register events by using a TriggerEventFactory.
   * @param ganttActions List of backend defined actions.
   */
  private extractEventTriggersByGanttActions(ganttActions: any[]): GanttEventTrigger[] {
    let triggerList: GanttEventTrigger[] = [];
    triggerList = triggerList.concat(
      this.triggerEventFactory.getTriggerEventByActionTypes(
        this._ganttPluginHandlerService,
        this._ganttLibService,
        ganttActions,
        this._ganttLibService.bestGantt,
        this.actionHandler,
        this.responseHandler
      )
    );
    return triggerList;
  }

  public handleLocalAction(localAction: any, callBackParams?: any): Observable<any> {
    return of(null);
  }

  public destroy(): void {
    this.currentActionTriggers.forEach((eventTrigger) => eventTrigger.removeFromGantt());
  }
}
