import { Resource } from './../../../model/resource/resource';
import { EFileLoadingType, EFileState } from './file-state.enum';
export class FileData extends Resource {
  private lastModified: Date;
  private size: number;
  private type: string;
  private content: ArrayBuffer;
  private state: EFileState;
  private transferedBytes: number;
  private loadingType: EFileLoadingType;
  private zippedContent?: boolean;

  public getLoadingType(): EFileLoadingType {
    return this.loadingType;
  }

  public setLoadingType(loadingType: EFileLoadingType): void {
    this.loadingType = loadingType;
  }

  public getTransferedBytes(): number {
    return this.transferedBytes || 0;
  }

  public setTransferedBytes(transferedBytes: number): this {
    this.transferedBytes = transferedBytes;
    return this;
  }

  public getState(): EFileState {
    return this.state;
  }

  public setState(state: EFileState): this {
    this.state = state;
    return this;
  }

  constructor() {
    super();
  }

  public getLastModified(): Date {
    return this.lastModified;
  }

  public setLastModified(lastModified: Date): this {
    this.lastModified = lastModified;
    return this;
  }

  public getSize(): number {
    return this.size;
  }

  public setSize(size: number): this {
    this.size = size;
    return this;
  }

  public getType(): string {
    return this.type;
  }

  public setType(type: string): this {
    this.type = type;
    return this;
  }

  public getContent(): ArrayBuffer {
    return this.content;
  }

  public setContent(content: ArrayBuffer): this {
    this.content = content;
    return this;
  }

  public isZippedContent(): boolean {
    return !!this.zippedContent;
  }

  public setZippedContent(zippedContent: boolean): this {
    this.zippedContent = zippedContent; return this;
  }
}
