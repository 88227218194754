import { GanttStrokePattern } from './strokePattern';

/**
 * Stores and handles GanttStrokePatterns
 */
export class GanttStrokePatternStorage {
  private _storage: Map<string, GanttStrokePattern>;

  constructor() {
    this._storage = new Map();
  }

  /**
   * Adds pattern to the storage
   * @param {GanttStrokePattern} pattern
   */
  addPatternToStorage(pattern) {
    this._storage.set(pattern.getId(), pattern);
  }

  /**
   * Removes pattern from storage
   * @param {string} id
   */
  removePatternFromStorage(id) {
    this._storage.delete(id);
  }

  /**
   * Returns an GanttStrokePattern by id.
   * @param {string} id
   * @returns {GanttStrokePattern}
   */
  getPatternById(id) {
    return this._storage.get(id);
  }
}
