import { BestGantt } from '../../main';
import { GanttXAxis } from '../../x-axis/x-axis';
import { GanttXAxisHandler } from '../x-axis/x-axis-handler';

export class MainXAxisCallbacks {
  ganttDiagram: BestGantt;
  xAxisBuilder: GanttXAxis;
  callbackHandler: GanttXAxisHandler;

  constructor(ganttDiagram, xAxisBuilder) {
    this.ganttDiagram = ganttDiagram;
    this.xAxisBuilder = xAxisBuilder;

    this.callbackHandler = new GanttXAxisHandler(ganttDiagram);
  }

  /**
   * Adds x axis callbacks.
   *
   */
  initCallbacks() {
    this.xAxisBuilder.addToZoomCallback(
      'shiftZoom',
      this.callbackHandler._rerenderShiftsByZoom.bind(this.callbackHandler)
    );
    this.xAxisBuilder.addToZoomStartCallback(
      'shiftZoom',
      this.callbackHandler._removeShiftAreaSVGElementsBeforeZoom.bind(this.callbackHandler)
    );
    this.xAxisBuilder.addToZoomEndCallback(
      'shiftZoomEnd',
      this.callbackHandler._renderShiftAreaOnZoomEnd.bind(this.callbackHandler)
    );
    this.xAxisBuilder.addToZoomCallback(
      'rerenderTimeSpanByZoom',
      this.callbackHandler._rerenderTimeSpanByZoom.bind(this.callbackHandler)
    );
    this.xAxisBuilder.addToZoomCallback(
      `setMinWidthToXAxisBrushHandler`,
      this.callbackHandler._minWidthOnXAxisBrushSelection.bind(this.callbackHandler)
    );
  }
}
