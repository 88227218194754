import { HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ActionAdapter } from '@app-modeleditor/components/button/action-adapter.service';
import { Action } from '@app-modeleditor/components/button/action/action';
import { EActionType } from '@app-modeleditor/components/button/action/action-type.enum';
import { EPredefinedAction } from '@app-modeleditor/components/button/action/predefined-action.enum';
import { ETemplateHandlingType } from '@app-modeleditor/components/button/action/template-handling-type.enum';
import { Button } from '@app-modeleditor/components/button/button';
import { TemplateActionService } from '@app-modeleditor/components/button/template-action.service';
import { MessageService } from '@core/message/message.service';
import { LoadingParams } from 'frontend/src/dashboard/model/model.data';
import { of } from 'rxjs';
import { ActionService, ActionTupel } from './action.service';
import { ID } from './actions';

@Injectable({
  providedIn: 'root',
})
export class ActionMapper {
  private readonly templateActionApi = inject(TemplateActionService);
  private readonly actionFactory = inject(ActionAdapter);
  private readonly messageService = inject(MessageService);
  private readonly actionService = inject(ActionService);

  public getParams(action: Action): HttpParams {
    let params: HttpParams = new HttpParams();
    if (action.getParams()) {
      Object.keys(action.getParams()).forEach((key: string) => {
        params = params.append(key, action.getParams()[key]);
      });
    }
    return params;
  }

  public resolveAction(action: any): Action {
    return action instanceof Action ? action : this.actionFactory.parseAction(Action, action);
  }

  getBody(action: Action) {
    if (!action.getPostParameterSelector()) {
      return null;
    }

    return this.templateActionApi.getPostParameter(this.resolveAction(action));
  }

  public _getUrl(action: Action): string {
    return action.getParameterSelectors()
      ? this.templateActionApi.getUrlFromParameterSelectors(
          action.getActionUrl(),
          action.getResourceId(),
          action.getParameterSelectors(),
          false,
          action.getValidationRules()
        )
      : action.getActionUrl();
  }

  mapActions(actions: Action[], event: MouseEvent = null, button: Button = null): ActionTupel<ID>[] {
    const events: ActionTupel<ID>[] = [];

    // check button for actions
    if (button instanceof Button && button.isShowDeleteConfirmDialog()) {
      events.push(
        this.mapAction(new Action().setId(EPredefinedAction.CHECK_BUTTON).setActionType(EActionType.PREDEFINED), button)
      );
      events.push(
        this.mapAction(
          new Action().setId(EPredefinedAction.CHECK_EXTERNAL_CONDITIONS).setActionType(EActionType.PREDEFINED),
          event,
          button
        )
      );
    }

    actions.forEach((a) => {
      const mapped = this.mapAction(a, event, button);

      // check local conditions
      events.push({
        id: ID.check_local_condition,
        params: [a],
      } as ActionTupel<ID.check_local_condition>);

      if (a.isOpenWithCreateNameDialog() === true) {
        events.push(
          this.mapAction(
            new Action().setId(EPredefinedAction.OPEN_WITH_CREATE_NAME_DIALOG).setActionType(EActionType.PREDEFINED),
            mapped
          )
        );
      } else if (a.isOpenWithConfirmDeleteDialog() === true) {
        events.push(
          this.mapAction(
            new Action().setId(EPredefinedAction.OPEN_WITH_CONFIRM_DELETE_DIALOG).setActionType(EActionType.PREDEFINED),
            mapped
          )
        );
      } else {
        events.push(mapped);
      }
      a.getMessages().map((m) => {
        events.push(
          this.mapAction(
            new Action(() => {
              return of(this.messageService.show(m));
            })
          )
        );
      });
    });

    if (button?.getUpdateElementIds && button.getUpdateElementIds()?.length) {
      events.push(
        this.mapAction(
          new Action().setId(EPredefinedAction.UPDATE_ELEMENT).setActionType(EActionType.PREDEFINED),
          event,
          button
        )
      );
    }

    if (button?.getRefreshElementIds && button.getRefreshElementIds()?.length) {
      events.push(
        this.mapAction(
          new Action().setId(EPredefinedAction.REFRESH_ELEMENT).setActionType(EActionType.PREDEFINED),
          event,
          button
        )
      );
    }

    return events;
  }

  private mapAction(action: Action, event: any = null, button: Button = null): ActionTupel<ID> {
    action = this.resolveAction(action);
    const params = this.getParams(action);
    const body = this.getBody(action);
    const url = this._getUrl(action);
    const method = action.getActionMethod();
    const localId = action.getLocalID();
    /**
     * LOCAL actions first
     */

    if (action.getActionType() === EActionType.LOCAL) {
      return {
        id: ID.local,
        params: [action, event],
      } as ActionTupel<ID.local>;
    }

    if (action.getActionType() === EActionType.PREDEFINED) {
      if (action.getId() === EPredefinedAction.OPEN_WITH_CREATE_NAME_DIALOG) {
        return {
          id: ID.open_with_create_name_dialog,
          params: [action, event],
        } as ActionTupel<ID.open_with_create_name_dialog>;
      }

      if (action.getId() === EPredefinedAction.CHECK_BUTTON) {
        return {
          id: ID.check_button,
          params: [event],
        } as ActionTupel<ID.check_button>;
      }

      if (action.getId() === EPredefinedAction.CHECK_EXTERNAL_CONDITIONS) {
        return {
          id: ID.check_external_condition,
          params: [button],
        } as ActionTupel<ID.check_external_condition>;
      }

      if (action.getId() === EPredefinedAction.OPEN_WITH_CONFIRM_DELETE_DIALOG) {
        return {
          id: ID.open_confirm_delete_dialog,
          params: [event],
        } as ActionTupel<ID.open_confirm_delete_dialog>;
      }

      if (action.getId() === EPredefinedAction.UPDATE_GANTT) {
        return {
          id: ID.update_gantt,
          params: [action],
        } as ActionTupel<ID.update_gantt>;
      }
    }

    if (action.getActionType() === EActionType.SET_DATE_NOW) {
      return {
        id: ID.set_current_date,
        params: [action],
      } as ActionTupel<ID.set_current_date>;
    }

    if (action.getActionType() === EActionType.RELOAD_TREE) {
      return {
        id: ID.reload_tree,
        params: [action.getActionType(), action.getLocalAffectedElement()],
      } as ActionTupel<ID.reload_tree>;
    }

    /**
     * END LOCAL actions
     */

    if (action.getActionType() === EActionType.PREDEFINED) {
      // enable edit
      if (action.getId() === EPredefinedAction.ENABLE_EDIT) {
        return {
          id: ID.toggle_edit_mode,
          params: [button.getId(), button.getResourceId()],
        } as ActionTupel<ID.toggle_edit_mode>;
      }

      // save
      if (action.getId() === EPredefinedAction.SAVE) {
        return {
          id: ID.save,
          params: [],
        } as ActionTupel<ID.save>;
      }

      // logout
      if (action.getId() === EPredefinedAction.LOGOUT) {
        return {
          id: ID.logout,
          params: [],
        } as ActionTupel<ID.logout>;
      }

      // update element
      if (action.getId() === EPredefinedAction.UPDATE_ELEMENT) {
        return {
          id: ID.update_element,
          params: [...button.getUpdateElementIds()],
        } as ActionTupel<ID.update_element>;
      }

      // update element
      if (action.getId() === EPredefinedAction.REFRESH_ELEMENT) {
        return {
          id: ID.refresh_element,
          params: [...button.getRefreshElementIds()],
        } as ActionTupel<ID.refresh_element>;
      }

      // cancel
      if (action.getId() === EPredefinedAction.CANCEL) {
        return {
          id: ID.cancel,
          params: [],
        } as ActionTupel<ID.cancel>;
      }

      // delete
      if (action.getId() === EPredefinedAction.DELETE) {
        return {
          id: ID.delete,
          params: [],
        } as ActionTupel<ID.delete>;
      }

      // clone
      if (action.getId() === EPredefinedAction.CLONE) {
        return {
          id: ID.clone,
          params: [],
        } as ActionTupel<ID.clone>;
      }

      // reset settings
      if (
        [EPredefinedAction.SETTINGS, EPredefinedAction.TOGGLE_USERSETTINGS].includes(
          action.getId() as EPredefinedAction
        )
      ) {
        return {
          id: ID.reset_settings,
          params: [],
        } as ActionTupel<ID.reset_settings>;
      }

      // close lightbox
      if (action.getId() === EPredefinedAction.CLOSE_LIGHTBOX) {
        return {
          id: ID.close_lightbox,
          params: [action.dialogResult],
        } as ActionTupel<ID.close_lightbox>;
      }

      // cancel lightbox
      if (action.getId() === EPredefinedAction.CANCEL_LIGHTBOX) {
        return {
          id: ID.cancel_lightbox,
          params: [],
        } as ActionTupel<ID.cancel_lightbox>;
      }

      // toggle favorite toolbar element
      if (action.getId() === EPredefinedAction.TOGGLE_TOOLBAR_FAVORITE_ELEMENT) {
        return {
          id: ID.toggle_favorite_toolbar_element,
          params: [localId],
        } as ActionTupel<ID.toggle_favorite_toolbar_element>;
      }

      // toggle message center
      if (action.getId() === EPredefinedAction.TOGGLE_MESSAGECENTER) {
        return {
          id: ID.toggle_message_center,
          params: [action.getId()],
        } as ActionTupel<ID.toggle_message_center>;
      }

      // open toolbar group
      if (action.getId() === EPredefinedAction.OPEN_TOOLBAR_GROUP) {
        return {
          id: ID.open_toolbar_group,
          params: [localId],
        } as ActionTupel<ID.open_toolbar_group>;
      }

      // create
      if (action.getId() === EPredefinedAction.CREATE) {
        return {
          id: ID.create,
          params: [],
        } as ActionTupel<ID.create>;
      }

      // close content
      if (action.getId() === EPredefinedAction.CLOSE_CONTENT) {
        return {
          id: ID.close_content,
          params: [],
        } as ActionTupel<ID.close_content>;
      }

      // discard changes
      if (action.getId() === EPredefinedAction.DISCARD_CHANGES) {
        return {
          id: ID.discard_changes,
          params: [],
        } as ActionTupel<ID.discard_changes>;
      }

      // discard changes by template id
      if (action.getId() === EPredefinedAction.DISCARD_CHANGES_BY_ID) {
        return {
          id: ID.discard_changes_by_id,
          params: [action.getActionUrl()],
        } as ActionTupel<ID.discard_changes_by_id>;
      }

      // open web link
      if (action.getId() === EPredefinedAction.OPEN_WEB_LINK) {
        return {
          id: ID.open_web_link,
          params: [action],
        } as ActionTupel<ID.open_web_link>;
      }

      // validate
      if (action.getId() === EPredefinedAction.VALIDATE) {
        return {
          id: ID.validate,
          params: [button],
        } as ActionTupel<ID.validate>;
      }

      // show/hide loading screen
      if (action.getId() === EPredefinedAction.TRIGGER_LOADING_INDICATOR) {
        return {
          id: ID.trigger_loading_indicator,
          params: [action.getParams() as unknown as LoadingParams],
        } as ActionTupel<ID.trigger_loading_indicator>;
      }

      // mark for save
      if (action.getId() === EPredefinedAction.MARK_FOR_SAVE) {
        return {
          id: ID.mark_for_save,
          params: [url, body],
        } as ActionTupel<ID.mark_for_save>;
      }

      if (
        [
          EPredefinedAction.GANTT_ENABLE_EDIT_MODE,
          EPredefinedAction.TOGGLE_DEFAULT_FILTER,
          EPredefinedAction.TOGGLE_PLUGIN_MODE_EDIT,
          EPredefinedAction.TOGGLE_PLUGIN_MODE_CREATE,
          EPredefinedAction.TOGGLE_PLUGIN_MODE_DELETE,
          EPredefinedAction.RESET_ZOOM_GANTT,
          EPredefinedAction.HOTSPOT_RECTANGLETEMPLATE,
          EPredefinedAction.HOTSPOT_TRIANGLETEMPLATE,
          EPredefinedAction.HOTSPOT_POLYGONLINEMODE,
          EPredefinedAction.HOTSPOT_POLYGONINTERPLOTATIONMODE,
          EPredefinedAction.HOTSPOT_POLYGONEDIT,
          EPredefinedAction.HOTSPOT_POLYGONDELETE,
          EPredefinedAction.HOTSPOT_VERTEXDELETE,
          EPredefinedAction.UPDATE_INDEX_CARD_CONFIG,
          EPredefinedAction.GET_AVAILABLE_RESOURCE_ENTRIES,
          EPredefinedAction.GANTT_TOGGLE_BROKEN_CONSTRAINTS,
        ].includes(action.getId() as EPredefinedAction)
      ) {
        // this.actionService.customActionListener.next({ button: action });
      }
    }

    // force reload second tree column
    if (action.getTemplateHandlingType() === ETemplateHandlingType.TEMPLATE_CONTENT_PAGE) {
      return {
        id: ID.open_content_page,
        params: [method, url, body, params],
      } as ActionTupel<ID.open_content_page>;
    }

    // file download
    if (action.getTemplateHandlingType() === ETemplateHandlingType.FILE) {
      return {
        id: ID.download_file,
        params: [method, body, url],
      } as ActionTupel<ID.download_file>;
    }

    // file upload
    if (action.getActionType() === EActionType.FILE_UPLOAD) {
      return {
        id: ID.upload_file,
        params: [method, body, action.getUploadID(), url, params],
      } as ActionTupel<ID.upload_file>;
    }

    // open wizard
    if (action.getTemplateHandlingType() === ETemplateHandlingType.TEMPLATE_WIZARD) {
      return {
        id: ID.open_wizard,
        params: [method, url, body, params],
      } as ActionTupel<ID.open_wizard>;
    }

    // open lightbox
    if (action.getTemplateHandlingType() === ETemplateHandlingType.TEMPLATE_LIGHTBOX) {
      return {
        id: ID.open_lightbox,
        params: [method, url, action.getUploadID(), body, params],
      } as ActionTupel<ID.open_lightbox>;
    }

    // open lightbox
    if (action.getTemplateHandlingType() === ETemplateHandlingType.TEMPLATE_LIGHTBOX) {
      return {
        id: ID.open_lightbox,
        params: [method, url, action.getUploadID(), body, params],
      } as ActionTupel<ID.open_lightbox>;
    }

    // open window
    if (
      [ETemplateHandlingType.TEMPLATE_EXTERNAL_TAB, ETemplateHandlingType.TEMPLATE_EXTERNAL_WINDOW].includes(
        action.getTemplateHandlingType()
      )
    ) {
      return {
        id: ID.open_window,
        params: [
          button,
          method,
          url,
          body,
          params,
          event,
          action.getTemplateHandlingType() === ETemplateHandlingType.TEMPLATE_EXTERNAL_WINDOW ? 'window' : 'tab',
        ],
      } as ActionTupel<ID.open_window>;
    }

    // open lightbox
    if (action.getActionType() === EActionType.CONDITIONAL_ACTION) {
      return {
        id: ID.conditional_action,
        params: [
          button,
          action.getUploadID(),
          action.getConditionType(),
          action.getUserQueryMessage(),
          method,
          url,
          body,
          params,
          action.getTrueActions(),
          action.getFalseActions(),
          event,
        ],
      } as ActionTupel<ID.conditional_action>;
    }

    // navigate tree
    if (action.getActionType() === EActionType.NAVIGATE_TREE) {
      return {
        id: ID.navigate_tree,
        params: [method, url, body, params],
      } as ActionTupel<ID.navigate_tree>;
    }

    // execute solver run
    if (action.getActionType() === EActionType.EXECUTE_SOLVER_RUN) {
      return {
        id: ID.execute_solver_run,
        params: [method, url, body, params],
      } as ActionTupel<ID.execute_solver_run>;
    }

    // execute experiment
    if (action.getActionType() === EActionType.EXECUTE_EXPERIMENT) {
      return {
        id: ID.execute_experiment,
        params: [method, url, body, params],
      } as ActionTupel<ID.execute_experiment>;
    }

    // download file
    if (action.getTemplateHandlingType() === ETemplateHandlingType.FILE) {
      return {
        id: ID.download_file,
        params: [method, url, body],
      } as ActionTupel<ID.download_file>;
    }

    // sso action
    if (action.getActionType() === EActionType.GRAPH_API_ACTION) {
      return {
        id: ID.sso,
        params: [
          action.getActionMethod(),
          action.getActionUrl(),
          action.getClientId(),
          action.getAuthority(),
          action.getRedirectUri(),
        ],
      } as ActionTupel<ID.sso>;
    }

    // sso authenticate action
    if (action.getActionType() === EActionType.GRAPH_API_SILENT_ACTION) {
      return {
        id: ID.sso_silent,
        params: [
          action.getActionMethod(),
          action.getActionUrl(),
          action.getClientId(),
          action.getAuthority(),
          action.getRedirectUri(),
          action.getUsername(),
        ],
      } as ActionTupel<ID.sso_silent>;
    }

    // hotspot
    if (action.getId().includes('hotspot')) {
      return {
        id: ID.hotspot,
        params: [action],
      } as ActionTupel<ID.hotspot>;
    }

    // custom listener
    const save = [EActionType.NEXT_PAGE, EActionType.PREVIOUS_PAGE, EActionType.NAVIGATE_TO_PAGE].includes(
      action.getActionType()
    )
      ? true
      : false;
    return {
      id: ID.custom_listener,
      params: [action, method, url, body, params, save],
    } as ActionTupel<ID.custom_listener>;
  }
}
