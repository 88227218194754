<div class="progress-dialog-wrapper">
  <div class="progress-dialog-header">
    <span class="progress-dialog-headline">
      {{(minimize && fileProgress.getProgress() !== 100 && files?.length) ? fileProgress?.getProgressAsString() : ''}}
      {{'FILE.GENERAL.filemanager'|translate}}</span>
    <button mat-icon-button (click)="handleClose()">
      <mat-icon>close</mat-icon>
    </button>
    <button mat-icon-button (click)="handleMinimize()">
      <mat-icon>{{ !minimize ? 'keyboard_arrow_down' : 'keyboard_arrow_up' }}</mat-icon>
    </button>
  </div>
  <div class="global-progress" *ngIf="!minimize && files?.length">
    <app-file-progress></app-file-progress>
  </div>

  <mat-divider *ngIf="!minimize && fileProgress.getProgress() !== 100"></mat-divider>
  <div class="progress-dialog-files" *ngIf="!minimize">
    <div class="noData" *ngIf="!files?.length">{{'noData' | translate}}</div>
    <cdk-virtual-scroll-viewport #viewport class="scrollport" [itemSize]="itemHeight" [style.height]="scrollHeight">
      <ng-container *cdkVirtualFor="let file of files">
        <div class="file-wrapper" (click)="removeFile(file)">
          <mat-icon class="loading-type-icon">{{getLoadingType(file)}}</mat-icon>
          <span class="file-name">{{getFileName(file)}}</span>
          <mat-icon *ngIf="!isLoading(file)" class="state-icon">{{getStateIcon(file)}}</mat-icon>
          <mat-progress-spinner *ngIf="isLoading(file)" class="state-icon" diameter="24"
            [mode]="(getPercentOfFile(file) == 0 || getPercentOfFile(file) == 100)?'indeterminate': 'determinate'"
            [value]="getPercentOfFile(file)">
          </mat-progress-spinner>
          <!-- <span>{{getTransferedBytes(file)|fileSize}} / {{file.size|fileSize}}</span> -->
          <div class="file-progress-info">
            {{ getPercentOfFile(file) }}% - {{ getTransferedBytes(file) | fileSize }} / {{ file.size | fileSize }}
          </div>
        </div>
        <mat-divider></mat-divider>
      </ng-container>
    </cdk-virtual-scroll-viewport>
  </div>
</div>
