import { IndexCardConfig } from '../index-card-config';
import { IndexCardExecuterBuildData } from '../index-card-executer';
import { IndexCardTextBuilder } from '../text-creator';
import { IIndexCardTextInterface } from './text-creator-interface';

/**
 * Lets index card text flow out of shift, truncates before neighbor shift.
 */
export class IndexCardTextOutsideBG implements IIndexCardTextInterface {
  public init() {
    // nothing
  }

  public build(
    dataSet: { [id: string]: string[] },
    buildData: IndexCardExecuterBuildData,
    config: IndexCardConfig,
    executer: IndexCardTextBuilder
  ): void {
    const s = executer;
    const ctx = s.indexCardExecuter.getCanvas(s.scrollContainerId).node().getContext('2d');
    const ctxFontConfig = `${config.fontSize}px Arial`;
    if (ctx.font !== ctxFontConfig) ctx.font = ctxFontConfig;
    const ellipsis = '…';
    const ellipsisWidth = s.fontSizeCalc.getTextWidth(ellipsis);
    const textContainerWidth = s.ganttDiagram
      .getNodeProportionsState()
      .getShiftViewPortProportions(s.scrollContainerId).width;

    if (!buildData.transform) {
      buildData.transform = { k: 1, x: 0, y: 0 };
    }

    for (const textParent of buildData.shifts) {
      const lines = s.getMatchingLineSet(dataSet, textParent);
      const x = textParent.x * buildData.transform.k + buildData.transform.x + config.padding_left;
      const maxTextWidth = s.getWidthToNextShift(buildData, textParent, textContainerWidth, config);

      for (let i = 0; i < lines.length; i++) {
        const line = lines[i];
        const y =
          s.ganttDiagram.getRenderDataHandler().getStateStorage().getYPositionShift(textParent.id) +
          config.padding_top +
          config.fontSize +
          config.lineHeight * i +
          config.lineHeight -
          s.ganttDiagram.getNodeProportionsState().getScrollTopPosition(s.scrollContainerId);
        const wrappedText =
          maxTextWidth === undefined ? line.text : s.fittingString(line.text, maxTextWidth, ellipsis, ellipsisWidth);
        if (wrappedText) {
          ctx.fillText(wrappedText, x, y);
        }
      }
    }
  }

  public cleanUp() {
    // nothing
  }
}
