import { BehaviorSubject, debounceTime, filter } from 'rxjs';
import { EGanttScrollContainer } from '../html-structure/scroll-container.enum';
import { BestGantt } from '../main';

/**
 * Class responsible for performing vertical scrolling on gantt scroll containers triggered by events from the gantt scroll container overlay.
 *
 * This class handles vertical scrolling in the following cases:
 * - during hovering over an svg shift
 * - during shift translation
 */
export class VerticalScrollByOverlayHandler {
  private readonly _scrollContainerId$ = new BehaviorSubject<EGanttScrollContainer>(undefined);

  /**
   * @param _ganttDiagram Reference to the {@link BestGantt} instance to handle the vertical scrolling by overlay events for.
   */
  constructor(private readonly _ganttDiagram: BestGantt) {}

  /**
   * Initialization of {@link VerticalScrollByOverlayHandler}.
   */
  public init(): void {
    this._ganttDiagram
      .getHTMLStructureBuilder()
      .getVerticalScrollContainerOverlay()
      .on('wheel', (event: WheelEvent) => this._scrollByWheelEvent(event));

    this._scrollContainerId$
      .pipe(filter((scrollContainerId) => scrollContainerId !== undefined))
      .pipe(debounceTime(300))
      .subscribe(() => this._scrollContainerId$.next(undefined));
  }

  /**
   * Destruction and clean-up of {@link VerticalScrollByOverlayHandler}.
   */
  public destroy(): void {
    this._ganttDiagram.getHTMLStructureBuilder().getVerticalScrollContainerOverlay().on('wheel', undefined);

    this._scrollContainerId$.complete();
  }

  /**
   * Perform a vertical scroll on a specific scroll container determined by the given event data.
   * @param event Event data providing information about the vertical scroll to be performed.
   */
  private _scrollByWheelEvent(event: WheelEvent): void {
    const scrollContainerId =
      this._scrollContainerId$.getValue() ||
      this._ganttDiagram.getRenderDataHandler().getYAxisDataFinder().getScrollContainerByPageY(event.pageY);
    this._scrollContainerId$.next(scrollContainerId);

    const scrollTop = this._ganttDiagram.getNodeProportionsState().getScrollTopPosition(scrollContainerId);

    this._ganttDiagram
      .getHTMLStructureBuilder()
      .getVerticalScrollContainer(scrollContainerId)
      .node()
      .scrollTo({ top: scrollTop + event.deltaY });
  }
}
