import { EGanttInstance } from '@gantt/public-api';
import { LegendCommunicationService } from 'frontend/src/dashboard/gantt/gantt/dock/views/legend/legend-communication.service';
import { GanttLibService } from 'frontend/src/dashboard/gantt/gantt/gantt-lib.service';
import { SaxMsBestGanttActiveSubmenuEntryElementSetting } from 'frontend/src/dashboard/gantt/gantt/saxms-best-gantt.settings';
import { GeneralGanttActionHandler } from 'frontend/src/dashboard/gantt/general/action-handling/action-handler';
import { GanttSettingsService } from 'frontend/src/dashboard/gantt/general/gantt-settings/service/gantt-settings.service';
import { GanttResponseHandler } from 'frontend/src/dashboard/gantt/general/response/response-handler';
import { EGanttBlockAttributeDataType } from 'frontend/src/dashboard/gantt/general/toolbar/filter/lightbox/attribute-data-types.enum';
import { ILegendEntry } from 'frontend/src/dashboard/legend/legend.interface';
import { EColorizeStrategyOption } from 'frontend/src/dashboard/saxms-best-gantt/legend/colorize-startegy-option';
import { Observable, of, Subscription } from 'rxjs';
import { ExternalGanttPlugin } from '../../../external-plugin';
import { GanttPluginHandlerService } from '../../../gantt-plugin-handler.service';
import { GanttResponseColorizerByAttribute } from './response/colorizer-by-attribute-response';

export const GanttShiftColorByAttributeExecuter = 'gantt-plugin-shift-color-by-attribute-executer';
const GanttBlockAttributeSelectorSubmenuElement_Input = 'GanttBlockAttributeSelectorSubmenuElement_Input';

/**
 * PlugIn-Wrapper for GanttShiftColorByAttribute.
 * Use this pluginwrapper to change colorizment for shift blocks by attributes.
 */
export class GanttColorizerByAttributePlugIn extends ExternalGanttPlugin {
  public colorStrategy: { [key: string]: { strategy: string } };
  private templateData: any;
  private _entryElementId = 'template.entryelement.attributecolorselector';
  private _colorizeStrategyChangeSubscription: Subscription = null;
  private _isPluginActive = true;
  private _lastPropertyKey: string = null;

  constructor(
    protected _ganttPluginHandlerService: GanttPluginHandlerService,
    protected _ganttLibService: GanttLibService,
    protected _actionHandler: GeneralGanttActionHandler,
    protected _responseHandler: GanttResponseHandler,
    protected _ganttSettingsService: GanttSettingsService,
    protected _legendCommunicationService: LegendCommunicationService
  ) {
    super(_ganttPluginHandlerService, _ganttLibService, _actionHandler);
  }

  public onInit(templateData: any, responseData: any) {
    this.templateData = templateData;
    if (responseData.hasOwnProperty('colorStrategy')) {
      this.colorStrategy = responseData.colorStrategy;
    }
    this.addPlugIn(
      GanttShiftColorByAttributeExecuter,
      this._ganttLibService.ganttInstanceService.getInstance(EGanttInstance.SHIFT_COLOR_BY_ATTRIBUTE)
    );
    const colorByAttributePlugIn = this.getPlugInById(GanttShiftColorByAttributeExecuter);
    colorByAttributePlugIn.setNotAssignedTag(this.actionHandler.translate.instant('@not_assigned@'));
    this._responseHandler.addResponse(GanttResponseColorizerByAttribute, colorByAttributePlugIn);
    this._subscribeToColorizeStrategyChange();
    colorByAttributePlugIn.addAfterAttributeColorizeCallback(
      'GanttColorizerByAttributePlugIn_Refresh',
      this.activateLegend.bind(this)
    );
  }

  public onDestroy(): void {
    this._colorizeStrategyChangeSubscription.unsubscribe();
  }

  public onAction(action: any) {}

  public executeAction(action: any): Observable<any> {
    return of(null);
  }

  /**
   * General function to clorize blocks by given attribute.
   * @param propertyKey Attribute name.
   * @param colorizeByAdditionalDataProperties Path to find attribute value.
   * @param valueProperties
   */
  public colorizeBlocksByAttribute(
    propertyKey: string,
    colorizeByAdditionalDataProperties?: string[],
    valueProperties?: string[]
  ): void {
    const colorByAttributePlugIn = this.getPlugInById(GanttShiftColorByAttributeExecuter);
    if (!colorByAttributePlugIn) return;
    if (!colorizeByAdditionalDataProperties) colorizeByAdditionalDataProperties = [];
    const dataType = this.templateData.attributeMapping[propertyKey]?.dataType || EGanttBlockAttributeDataType.STRING;
    const shiftColorByAttributeParams = this._ganttLibService.ganttInstanceService.getInstance(
      EGanttInstance.SHIFT_COLOR_BY_ATTRIBUTE_PARAMS,
      propertyKey,
      null,
      colorizeByAdditionalDataProperties,
      valueProperties,
      dataType
    );
    const strategyOfAttribute = this.colorStrategy ? this.colorStrategy[propertyKey] : null;
    colorByAttributePlugIn.colorizeByAttribute(shiftColorByAttributeParams, strategyOfAttribute);
    this.gantt.rerenderShiftsVertical();
  }

  /**
   * Concrete implementation to colorize blocks by given attribute.
   * Activates Legend after colorizment.
   * @param propertyKey Attribute name.
   */
  public colorizeGanttBlocksByAttribute(propertyKey: string): void {
    // save colorization
    this._lastPropertyKey = propertyKey;
    if (!this._isPluginActive) {
      return;
    }
    this.colorizeBlocksByAttribute(propertyKey, ['additionalData', 'additionalDetails'], ['t2']);
    this._addSelectedAttributeToGanttSettings(propertyKey);
  }

  /**
   * Gets all values of colorized attribute.
   */
  public getAllAttributeValues(): any[] {
    return this.getPlugInById(GanttShiftColorByAttributeExecuter).getAllAttributeValues();
  }

  public getLastColorizeParams(): any {
    return this.getPlugInById(GanttShiftColorByAttributeExecuter).lastColorizeParams;
  }

  public getLastColorizeStrategy(): { strategy: string } {
    return this.getPlugInById(GanttShiftColorByAttributeExecuter).lastColorizeStrategy;
  }

  public isGanttPlugInActive(): boolean {
    return this.getPlugInById(GanttShiftColorByAttributeExecuter).isActive();
  }

  /**
   * Resets all shift block colorizment to initial color scheme.
   */
  public resetShiftColorize(updateGanttSettings: boolean): void {
    this._legendCommunicationService.setLegendData(null);
    this.getPlugInById(GanttShiftColorByAttributeExecuter).resetColor();
    this.gantt.rerenderShiftsVertical();
    this._lastPropertyKey = null;
    if (updateGanttSettings) {
      this._removeCurrentSelectedAttributeFromGanttSettings();
    }
  }

  public addNewShiftToColorize(shiftId: string, color: string) {
    this.getPlugInById(GanttShiftColorByAttributeExecuter).addOriginShiftColor(shiftId, color);
  }

  public deactivatePlugin(): void {
    this._isPluginActive = false;
    const saveProperty = this._lastPropertyKey;
    this.resetShiftColorize(false);
    this._lastPropertyKey = saveProperty; // reset property -> was deleted on reset
  }

  public activatePlugin(): void {
    this._isPluginActive = true;

    // execute last colorization
    if (this._lastPropertyKey) {
      this.colorizeGanttBlocksByAttribute(this._lastPropertyKey);
    }
  }

  /**
   * Checks if there is a default filter option inside the gantt
   * settings which should be integrated if gantt will be build.
   * @param submenuElements Submenu elements which are stored inside gantt settings.
   */
  public injectSettings(submenuElements: SaxMsBestGanttActiveSubmenuEntryElementSetting[]): void {
    const colorElement: SaxMsBestGanttActiveSubmenuEntryElementSetting = submenuElements.find(
      (element) => element.id == GanttBlockAttributeSelectorSubmenuElement_Input
    );
    if (!colorElement || colorElement.value == null) return;
    this.colorizeGanttBlocksByAttribute(colorElement.value);
  }

  /**
   * Activates legend under the gantt.
   * Based on current color data.
   */
  public activateLegend(): void {
    const plugin = this.getPlugInById(GanttShiftColorByAttributeExecuter);
    if (!plugin) return;
    const allAttributeValues = this.getAllAttributeValues();

    const allLegendData: ILegendEntry[] = [];
    for (const value of allAttributeValues) {
      const item: ILegendEntry = {
        id: `${plugin.getColorizedAttribute()}_${value.value}_${value.color}`.replaceAll(' ', ''),
        label: value.value,
        color: value.color,
        tooltip: value.value,
        isActive: true,
        noRender: [],
        originalValue: value.originalValue,
      };
      allLegendData.push(item);
    }
    if (allLegendData.length) {
      this._legendCommunicationService.setLegendData({
        legendEntries: allLegendData,
        originalDataType: this.getLastColorizeParams().dataType,
      });
    }
  }

  /**
   * Subscription for colorize strategy change in legend.
   */
  private _subscribeToColorizeStrategyChange() {
    this._colorizeStrategyChangeSubscription = this._legendCommunicationService
      .listenToColorizeStrategyChange()
      .subscribe((strategy: EColorizeStrategyOption) => {
        if (!strategy) {
          return;
        }
        this._onColorizeOptionChange(strategy);
      });
  }

  /**
   * Changes the colorize strategy and sends new data to legend.
   * @param colorizeStrategyOption New strategy
   */
  private _onColorizeOptionChange(colorizeStrategyOption: EColorizeStrategyOption) {
    const jsColorizePlugin = this.getPlugInById(GanttShiftColorByAttributeExecuter);
    if (!jsColorizePlugin) {
      return;
    }

    switch (colorizeStrategyOption) {
      case EColorizeStrategyOption.DEFAULT:
        jsColorizePlugin.setUniqueColoringMode(false);
        break;
      case EColorizeStrategyOption.UNIQUE:
        jsColorizePlugin.setUniqueColoringMode(true);
        break;
    }
    jsColorizePlugin.refreshColorization();
    this._ganttLibService.bestGantt.update();
  }

  /**
   * Removes the current selected attribute from gantt settings and saves settings to backend.
   */
  private _removeCurrentSelectedAttributeFromGanttSettings() {
    const settingsHandler = this._ganttSettingsService;
    const activeSubmenuEntryElements = settingsHandler
      .getGanttSettings()
      .activeSubmenuEntryElements.filter((elem) => elem.id !== this._entryElementId);
    settingsHandler.changeSettings({ activeSubmenuEntryElements: activeSubmenuEntryElements });
    settingsHandler.saveSettings().subscribe();
  }

  /**
   * Adds current selected attribute to gantt settings and saves settings to backend,
   */
  private _addSelectedAttributeToGanttSettings(propertyKey: string) {
    const settingsHandler = this._ganttSettingsService;
    const activeSubmenuEntryElements = settingsHandler.getGanttSettings().activeSubmenuEntryElements;
    settingsHandler.changeSettings({
      activeSubmenuEntryElements: activeSubmenuEntryElements.filter((elem) => elem.id !== this._entryElementId),
    }); // remove old value
    const newElement: SaxMsBestGanttActiveSubmenuEntryElementSetting = { id: this._entryElementId, value: propertyKey };
    const newElements = settingsHandler.getGanttSettings().activeSubmenuEntryElements;
    newElements.push(newElement); // set new value
    settingsHandler.changeSettings({ activeSubmenuEntryElements: newElements });
    settingsHandler.saveSettings().subscribe();
  }
}

export interface IColorizationParams {
  propertyKey: string;
  colorizeByAdditionalDataProperties?: string[];
  valueProperties?: string[];
}
