import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { EntryElementValue } from '../entry-collection/entry-element-value';
import { FileData } from '../file-uploader/filte-data';
import { FileUploader } from './fileuploader';

@Component({
  selector: 'saxms-fileuploader',
  templateUrl: './fileuploader.component.html',
  styleUrls: ['./fileuploader.component.scss'],
})
export class FileuploaderComponent implements OnChanges, OnInit {
  fileToUpload: File = null;
  @Input() data: FileUploader;
  @Output() change: EventEmitter<any> = new EventEmitter();
  @Output() onDataChange: EventEmitter<any> = new EventEmitter();

  uploadProgress = 0;

  public sizeOfData: number;
  public sizeFormatted;
  public acceptFormat;
  public dataFormOk;
  public fileError = false;

  ngOnChanges(data: SimpleChanges): void {
    if (data.data && this.data.getValue()) {
      this.checkAllowedFormat();
      this.formDataSize(this.data);
    }
  }

  constructor() {}

  onDrag(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }
  onDragStart(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }
  onDragOver(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    document.getElementById('dndForm').classList.add('over');
  }
  onDragEnter(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }
  onDragLeave(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    document.getElementById('dndForm').classList.remove('over');
  }
  onDrop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.handleFileInput(event.dataTransfer.files);
  }
  onDragEnd(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }

  ngOnInit(): void {
    this.check();
    this.registerResourceId();
  }

  get disabled(): boolean {
    return !this.data.isAlwaysEnabled() && (!this.data.isEnabled() || this.data.isDisabled());
  }

  isClicked(): void {
    document.getElementById('dndForm').classList.add('isClicked');
    of(null)
      .pipe(delay(500))
      .subscribe(() => {
        document.getElementById('dndForm').classList.remove('isClicked');
      });
  }

  handleFileInput(files: FileList, ref: HTMLFormElement = null): void {
    this.data.getValue<EntryElementValue>().setValue(null);
    this.checkAllowedFormat();
    const file = files[0];
    this.data.getValue<EntryElementValue>().setValue(file?.name);
    this.data.setFileToUpload(new FileData());
    this.setSizeFormatted(null);
    if (!files || files.length === 0) {
      this.change.emit(this.data);
      return;
    }
    const reader = new FileReader();
    reader.addEventListener('load', (event: any) => {
      this.data.setFileToUpload(event.target.result);
      this.change.emit(this.data);
      this.formDataSize(this.data);

      // if form ref exists, clear it to reupload same file again
      if (ref) {
        ref.reset();
      }
    });

    reader.readAsArrayBuffer(file);
  }

  private registerResourceId() {
    this.onDataChange.emit({ data: this.data });
    // this.sharedUiService.resourceIds[this.data.id] = this.data;
  }

  check(): boolean {
    const div = document.createElement('div');
    return 'draggable' in div || ('ondragstart' in div && 'ondrop' in div);
  }

  formDataSize(data): void {
    if (data) {
      this.setSizeOfData(data.fileToUpload ? data.fileToUpload.byteLength : 0);

      if (this.getSizeOfData() > 0) {
        if (this.getSizeOfData() > 1024 * 1024 * 1024) {
          this.setSizeFormatted(Math.round((this.getSizeOfData() / 1024 / 1024 / 1024) * 100) / 100 + ' GB');
        } else if (this.getSizeOfData() > 1024 * 1024) {
          this.setSizeFormatted(Math.round((this.getSizeOfData() / 1024 / 1024) * 100) / 100 + ' MB');
        } else {
          this.setSizeFormatted(Math.round((this.getSizeOfData() / 1024) * 100) / 100 + ' KB');
        }
      } else {
        this.setSizeFormatted(null);
      }
    }
  }

  checkAllowedFormat(): void {
    this.setDataFormOk(false);
    this.setFileError(false);
    this.setAcceptFormat(this.data ? this.data.getAccept().split(',') : null);

    if (
      !this.data.getValue() ||
      !this.data.getValue<EntryElementValue>().getValue() ||
      this.data.getValue<EntryElementValue>().getValue() === 'noFile'
    ) {
      this.data.setFileToUpload(new FileData());
      return;
    }

    if (this.getAcceptedFormat() !== null) {
      this.getAcceptedFormat().forEach((element) => {
        if (this.data.getValue<EntryElementValue>().getValue<string>().indexOf(element) !== -1) {
          this.setDataFormOk(true);
        }
      });

      if (!this.dataFormOk && this.data.getValue<EntryElementValue>().getValue() !== 'noFile') {
        this.data.setFileToUpload(new FileData());
        this.setFileError(true);
      }
    }
  }

  getDataFormOk(): boolean {
    return this.dataFormOk;
  }

  setDataFormOk(value: boolean): void {
    this.dataFormOk = value;
  }

  getSizeOfData(): number {
    return this.sizeOfData;
  }

  setSizeOfData(value: number): void {
    this.sizeOfData = value;
  }

  getSizeFormatted(): string {
    return this.sizeFormatted;
  }

  setSizeFormatted(value: string): void {
    this.sizeFormatted = value;
  }

  getAcceptedFormat(): any {
    return this.acceptFormat;
  }

  setAcceptFormat(value: any): any {
    this.acceptFormat = value;
  }

  getFileError(): boolean {
    return this.fileError;
  }

  setFileError(value: boolean): void {
    this.fileError = value;
  }
}
