import { RxPush } from '@rx-angular/template/push';
import { RxLet } from '@rx-angular/template/let';
import { APP_BASE_HREF, CommonModule, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { Injector, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { TemplateButtonModule } from '@app-modeleditor/components/button/button.module';
import { ModelEditorModule as ModelEditorModule2 } from '@app-modeleditor/modeleditor.module';
import { MsalModule } from '@azure/msal-angular';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';
import { RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';
import { MarkdownModule } from 'ngx-markdown';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { APP_ROUTES } from './app.routes';
import { CoreModule } from './core/core.module';
import { ExperimentModule } from './data/experiment/experiment.module';
import { AuthState } from './login/data-access/auth.state';
import { ModelEditorModule } from './model-editor/model-editor.module';
import { GenericOptionsComponent } from './model-editor/ui/generic-options/generic-options.component';
import { SharedUiService } from './model-editor/ui/service/shared.ui.service';
import { TableDialog } from './model-editor/ui/table-view/dialog/table.dialog.component';
import { TableTypeDialog } from './model-editor/ui/table-view/dialog/type-dialog/table-type.dialog.component';
import { TableViewModule } from './model-editor/ui/table-view/table-view.module';
import { ModelToolbarComponent } from './model/model-toolbar.component';
import { ChangePasswordLightboxModule } from './password/change-password-lightbox/change-password-lightbox.module';
import { EqualValidator } from './password/password-match.directives';
import { PasswordService } from './password/password.service';
import { ColorpickerPopupComponent } from './popups/colorpickerpopup/colorpickerpopup.component';
import { WidgetPopupComponent } from './popups/widgetspopup/widgetPopup.component';
import { NotificationState } from './shared/data-access/notification.store';
import { TimeDurationPickerModule } from './shared/duration-picker/time-duration-picker-module';
import { MySharedModule } from './shared/shared.module';
import { SpinnerComponent } from './shared/ui/spinner/spinner.component';
import { TemplateState } from './template/data-access/template.state';
import { GlobalViewModule } from './view/global-view.module';
import { DefaultFooterModule } from './view/template-footer/default-footer-components/default-footer.module';

@NgModule({
  imports: [
    NgxsModule.forRoot([AuthState, NotificationState, TemplateState], {
      developmentMode: !environment.production,
    }),
    NgxsStoragePluginModule.forRoot({
      key: 'auth.token',
    }),
    MsalModule,
    // default stuff
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(APP_ROUTES, { useHash: true }),
    CoreModule.forRoot(),

    // GridModule,
    TimeDurationPickerModule,
    SpinnerComponent,
    TableViewModule,
    ModelEditorModule,
    TemplateButtonModule,
    ModelEditorModule2,
    ReactiveFormsModule,
    RxPush,
    RxLet,
    MySharedModule,
    CommonModule,
    ExperimentModule,

    /**
     * material
     */

    GlobalViewModule,
    DefaultFooterModule,
    ChangePasswordLightboxModule,
    MarkdownModule.forRoot(),
  ],
  declarations: [
    AppComponent,
    ModelToolbarComponent,
    EqualValidator,
    TableDialog,
    TableTypeDialog,
    GenericOptionsComponent,
    ColorpickerPopupComponent,
    WidgetPopupComponent,
  ],
  providers: [
    { provide: APP_BASE_HREF, useValue: '/' },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: { siteKey: environment.captchaSiteKey } as RecaptchaSettings,
    },
    SharedUiService,
    PasswordService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  static injector: Injector;

  constructor(private injector: Injector) {
    AppModule.injector = this.injector;
  }
}
