import { Content } from '@app-modeleditor/components/content/content';
import { ContentPart } from '@app-modeleditor/components/content/content-part/content-part';
import { EntryCollection } from '@app-modeleditor/components/entry-collection/entry-collection';
import { EntryElement } from '@app-modeleditor/components/entry-collection/entry-element';
import { EntryElementValue } from '@app-modeleditor/components/entry-collection/entry-element-value';
import { EFieldType } from '@app-modeleditor/components/entry-collection/field-type.enum';
import { ConfirmLightbox } from '@app-modeleditor/components/lightbox/predefined/confirm-lightbox';
import { GanttEssentialPlugIns } from 'frontend/src/dashboard/gantt/general/plugin/e-gantt-essential-plugins';
import { GanttRestrictBlockDragPlugIn } from 'frontend/src/dashboard/gantt/general/plugin/plugin-list/edit-block/restrictions/restrict-block-drag';
import {
  GanttRowRestrictionPlugIn,
  RowTypeIdTupel,
} from 'frontend/src/dashboard/gantt/general/plugin/plugin-list/row-restriction/row-restriction.plugin';
import { GanttPredefinedSetting } from '../../predefined-setting';
import { takeUntil } from 'rxjs';

export class GanttShiftTranslationRestrictionHandler extends GanttPredefinedSetting {
  public strictEntryTypes: number[];

  private rowRestrictions: RowTypeIdTupel[] = [];

  public onInit(templateData: any, ganttResponseData: any): void {
    const rowRestrictionHandler: GanttRowRestrictionPlugIn = this.ganttPluginHandlerService.getEssentialPlugIn(
      GanttEssentialPlugIns.RowRestrictionPlugIn
    );
    if (ganttResponseData.hierarchicalPlan && ganttResponseData.hierarchicalPlan.ganttEntries) {
      if (ganttResponseData.hierarchicalPlan.strictEntryTypes) {
        this.strictEntryTypes = ganttResponseData.hierarchicalPlan.strictEntryTypes;
      }
      this.rowRestrictions = rowRestrictionHandler.extractRowRestrictions(
        ganttResponseData.hierarchicalPlan.ganttEntries
      );
      this.ganttLibService.bestGantt
        .getShiftTranslator()
        .translationRowLimiter.setStrictEntryTypes(this.strictEntryTypes);
      const restrictBlockDragPlugIn: GanttRestrictBlockDragPlugIn = this.ganttPluginHandlerService.getEssentialPlugIn(
        GanttEssentialPlugIns.RestrictBlockDragPlugIn
      );
      restrictBlockDragPlugIn.addBlockDragRestrictionByGanttResponseData(
        ganttResponseData.hierarchicalPlan.ganttEntries
      );
    }
    this.addNoDropAllowedDialog();
  }

  private addNoDropAllowedDialog(): void {
    this.ganttLibService.bestGantt
      .getShiftTranslator()
      .onShiftEditEnd()
      .pipe(takeUntil(this.ganttLibService.bestGantt.getShiftTranslator().onDestroy))
      .subscribe((dragEndParam) => {
        if (dragEndParam.hasBeenBlocked) {
          this.ganttLibService.ngZone.run((_) => {
            this.actionHandler.lightboxApi.open(
              new ConfirmLightbox('TEXT.attention')
                .setDisableCancelButton(true)
                .setAbsoluteWidth(300)
                .setContent(
                  new Content().setContentParts([
                    new ContentPart()
                      .setDisplayContentpartContainer(false)
                      .setContentElements([
                        new EntryCollection().setEntryElements([
                          new EntryElement()
                            .setFieldType(EFieldType.TEXT)
                            .setValue(
                              new EntryElementValue().setValue(
                                this.actionHandler.translate.instant('TEXT.drop_is_not_allowed')
                              )
                            ),
                        ]),
                      ]),
                  ])
                )
            );
          });
          return;
        }
      });
  }
}
