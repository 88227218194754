import { Injectable } from '@angular/core';
import { ERequestMethod, RequestOptions, RequestService } from '@app-modeleditor/request.service';
import { ConfigService } from '@core/config/config.service';
import { Select } from '@ngxs/store';
import { GlobalUtils } from 'frontend/src/dashboard/global-utils';
import { AuthState } from 'frontend/src/dashboard/login/data-access/auth.state';
import { User } from 'frontend/src/dashboard/user/data-access/user';
import { BehaviorSubject, concat, Observable, of, Subject } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { Tenant } from './tenant';
import { TenantAdapter } from './tenant-adapter.service';
@Injectable({
  providedIn: 'root',
})
export class UserTenantService {
  @Select(AuthState.user) user$!: Observable<User>;
  private _selectedTenants: Subject<Tenant[]> = new Subject<Tenant[]>();
  private _tenants: BehaviorSubject<Tenant[]> = new BehaviorSubject<Tenant[]>([]);
  private _beforeSelectionChanges: {
    [id: string]: (newTenants: Tenant[], curTenants: Tenant[]) => Observable<void>;
  };

  constructor(
    private _requestApi: RequestService,
    private _tenantAdapter: TenantAdapter,
    private _configApi: ConfigService
  ) {
    if (this._configApi.access().templates?.Tenants?.enabled !== false) {
      this.user$
        .pipe(
          tap((u) => {
            if (u) {
              this.initialize().subscribe();
            }
          })
        )
        .subscribe();
    }
  }

  /**
   * triggers before selection will be changed
   * @param id id of the registered callback
   * @param fn ()=>Observable<void>
   * @returns {string} id of the registered observable
   */
  public beforeSelectionChanges(fn: (newTenants: Tenant[], curTenants: Tenant[]) => Observable<void>): string {
    if (!this._beforeSelectionChanges) {
      this._beforeSelectionChanges = {};
    }
    const id: string = GlobalUtils.generateUUID();
    this._beforeSelectionChanges[id] = fn;
    return id;
  }

  private _executeAllBeforeSelectionChanges(tenants: Tenant[]): Observable<void> {
    if (!this._beforeSelectionChanges) {
      return of(null);
    }
    const obs: Observable<void>[] = Object.keys(this._beforeSelectionChanges).map((key: string) =>
      this._beforeSelectionChanges[key](tenants, this._tenants.getValue())
    );
    return concat(...obs);
  }

  public removeBeforeSelectionChangeListener(id: string): void {
    if (!this._beforeSelectionChanges) {
      return;
    }
    delete this._beforeSelectionChanges[id];
  }

  /**
   * intitializes service
   * @returns Observable<any>
   */
  public initialize(): Observable<any> {
    return this._requestApi.call(ERequestMethod.GET, `rest/usertenantsettings/availabletenants/`).pipe(
      catchError(() => {
        return of([]);
      }),
      tap((data: any[]) => {
        // this._tenants.next(this._tenants.getValue().concat(new Tenant().setName('FOO')));
        this._tenants.next(data.map((d: any) => this._tenantAdapter.inherit<Tenant>(Tenant, d)));
      })
    );
  }

  /**
   * gets list of tenants for resource
   * @returns Observable<Tenant[]>
   */
  public getTenantsForResource(): Observable<Tenant[]> {
    return this._requestApi
      .call(ERequestMethod.GET, `rest/usertenantsettings/tenantsforresource/`)
      .pipe(catchError(() => of([])))
      .pipe(
        map((results) => {
          return (results || []).map((r) => this._tenantAdapter.adapt(r));
        })
      );
  }

  /**
   * get all tenants
   * @returns Observable<Tenant[]>
   */
  public getAllTenants(): Observable<Tenant[]> {
    return this._tenants.asObservable();
  }

  public selectedTenantChanged(): Observable<Tenant[]> {
    return this._selectedTenants.asObservable();
  }

  /**
   * set selected user tenants
   * @param tenants Tenant[]
   * @returns {Observable<Tenant[]>} list of selected tenants
   */
  public setSelectedTenants(tenants: Tenant[]): Observable<Tenant[]> {
    return this._executeAllBeforeSelectionChanges(tenants).pipe(
      switchMap(() => {
        return this._requestApi
          .call(
            ERequestMethod.POST,
            `rest/usertenantsettings/saveselectedtenants/`,
            new RequestOptions().setHttpOptions({
              body: tenants.map((t: Tenant) => t.getId()),
            })
          )
          .pipe(
            map((r) => {
              this._selectedTenants.next(tenants);
              this._tenants.next(
                this._tenants.getValue().map((t: Tenant) => {
                  return t.setSelected(tenants.find((_t: Tenant) => _t.getId() === t.getId()) ? true : false);
                })
              );

              return this._tenants.getValue().filter((t: Tenant) => t.isSelected());
            })
          );
      }),
      catchError((e) => {
        return of(this._tenants.getValue().filter((t: Tenant) => t.isSelected()));
      })
    );
  }
}
