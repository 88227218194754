import packageJson from '../../../../package.json';

export const environment = {
  production: true,
  version: packageJson.version,
  user: undefined,
  initialLoadingAnimationDelay: 1000,
  minLogLevel: 3, // 0 = off, 1 = error, 2 = debug, 3 = warn, 4 = log
  captchaSiteKey: '6LcNMNQnAAAAAOkAwkqSSZF-FdSIYq67tluCuU5b',
};
