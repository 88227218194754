import { ChangeDetectorRef, Injectable } from '@angular/core';
import { EntryElementFactory } from '@app-modeleditor/components/entry-collection/entry-factory.service';
import { EFieldType } from '@app-modeleditor/components/entry-collection/field-type.enum';
import { MultiObjectSelector } from '@app-modeleditor/components/selector/multi-object-selector';
import { GridLayout } from '@app-modeleditor/components/widget-modules/grid-layout/grid-layout';
import { SelectionInformation } from '@app-modeleditor/components/widget-modules/grid-layout/selection-information';
import { ERequestMethod, RequestService } from '@app-modeleditor/request.service';
import { TranslateService } from '@ngx-translate/core';
import { EClickType } from 'frontend/src/dashboard/model/resource/click-type.enum';
import { EHotkeyType } from 'frontend/src/dashboard/model/resource/hotkey-type.enum';
import * as moment from 'moment';
import { Observable, forkJoin, of } from 'rxjs';
import { delay, map, take, takeUntil, tap } from 'rxjs/operators';
import { IRightClickEvent, SaxMsSpreadSheetColumn } from '../core/saxms-spreadsheet';
import { SaxMsSelectionModel } from '../core/saxmsSelectionModel/saxmsSelectionModel';
import { FullSpreadsheetComponent } from '../full-spreadsheet/full-spreadsheet.component';
import { EMoveDirection } from '../model/move-direction.enum';
import { ESaxMsColumnUnits } from '../model/table-column-unity.enum';
import { ESpreadsheetDatatypes } from '../model/table-datatypes.enum';
import { ISaxMsEditorContext } from '../model/table-editor-context';
import { EElementType } from '../model/table-element-type.enum';
import { ESpreadsheetPlugin } from '../model/table-plugin.enum';
import { SaxmsRowEntryElement } from '../model/table-row-entry';
import { ESpreadsheetEvents } from '../spreadsheet-events.enum';
import { TemplateActionService } from './../../button/template-action.service';
import { EntryElementValue } from './../../entry-collection/entry-element-value';
import { CellRendererUtil } from './cell-renderer-util';
import { ColumnUtil } from './column-util';
import { RowDataUtil } from './row-data-util';
import { SpreadsheetFilterService } from './spreadsheet-filter.service';
import { SpreadsheetHelperUtil } from './spreadsheet-helper-util';
import CellRange from 'handsontable/3rdparty/walkontable/src/cell/range';

@Injectable()
export class SpreadsheetHooksUtilService {
  private autoFillDirection: 'up' | 'down' | 'left' | 'right';
  private copiedAutoFillRange: CellRange;
  private autoFillEnabled = true;
  private executeAutoFillColRange: number[];
  private executeAutoFillRowRange: number[];
  private scope: FullSpreadsheetComponent;
  private clickInProgress: boolean;
  private lastSelectedCellCoords: { row: number; col: number }; // TODO - maybe later version provides CellCoords interface ?!
  private savedKeyForEditor: string;

  constructor(
    private spreadsheetHelperUtil: SpreadsheetHelperUtil,
    private rowDataUtil: RowDataUtil,
    private columnUtil: ColumnUtil,
    private cellRendererUtil: CellRendererUtil,
    private cd: ChangeDetectorRef,
    private translate: TranslateService,
    private templateActionApi: TemplateActionService,
    private requestApi: RequestService,
    private entryElementFactory: EntryElementFactory,
    private spreadSheetFilterService: SpreadsheetFilterService
  ) {}

  /**
   * handle the autofill feature of the table
   */
  private onAutofill(): void {
    const changedEntryElements: SaxmsRowEntryElement[] = [];
    // get latest selection ranges
    const range = this.copiedAutoFillRange;
    const visStartRowIndex = range.to.row;

    // range for rows
    const rowRange: number[] = this.spreadsheetHelperUtil.range(range.from.row, range.to.row);

    // range for columns
    const colRange: number[] = this.spreadsheetHelperUtil.range(range.from.col, range.to.col);
    const executedCells: Map<number, Set<number>> = new Map();
    const datasetPlainlist = this.rowDataUtil.getDatasetPlainlist();
    // loop over each row which is in the selection
    this.executeAutoFillColRange.forEach((colIndex: number) => {
      const colum = this.columnUtil.getColumnByIndex(colIndex, true);

      // get all cells in the column where changes will be applied to
      // important: get cells by physical index, not by logical index
      const cells: any[] = rowRange.map((rowIndex: number) => {
        const physRowIndex = this.spreadsheetHelperUtil.getRowIndex(rowIndex);
        const physColIndex = this.spreadsheetHelperUtil.getColumnIndex(colIndex);
        return datasetPlainlist[physRowIndex][physColIndex];
      });

      // calculates the offset which will be added to each cell
      const offset: number = this.getWindupValue(colum, cells);
      // initial value which will be applied to the first cell in to column
      const initialValue: number = cells[cells.length - 1].value;

      const rowIndies = [];
      (rowRange as any[]).forEach((change) => {
        if (rowIndies.indexOf(this.spreadsheetHelperUtil.getRowIndex(change[0]))) {
          rowIndies.push(this.spreadsheetHelperUtil.getRowIndex(change[0]));
        }
      });
      // iterator for each the values offset

      // loop over each cell that will be changed
      for (const rowRangeIndex of this.executeAutoFillRowRange) {
        // calculates the offset which will be added to each cell
        const newPhysColIndex: number = this.spreadsheetHelperUtil.getColumnIndex(colIndex);
        const physRowIndex: number = this.spreadsheetHelperUtil.getRowIndex(rowRangeIndex);
        // const rowIndex: number = rowRangeIndex;
        // const copyFromRow = change[0] % rowRange.length;
        const copyFromRow = this.executeAutoFillRowRange.indexOf(rowRangeIndex) % rowRange.length;
        const copyFromCol = this.executeAutoFillColRange.indexOf(colIndex) % colRange.length;

        const copiedRowIndex = this.spreadsheetHelperUtil.getRowIndex(
          (range.from.row > range.to.row ? range.to.row : range.from.row) + copyFromRow
        );
        const copiedColIndex = (range.from.col > range.to.col ? range.to.col : range.from.col) + copyFromCol;

        const copiedColumn = this.columnUtil.getColumnByIndex(copiedColIndex, true);
        const newColumn = this.columnUtil.getColumnByIndex(colIndex, true);

        if (
          newColumn.readOnly ||
          !datasetPlainlist[physRowIndex][newPhysColIndex].rowEditable ||
          newColumn.fieldType !== copiedColumn.fieldType
        ) {
          continue;
        }

        if (executedCells.has(newPhysColIndex) && executedCells.get(newPhysColIndex).has(physRowIndex)) {
          continue;
        }

        if (executedCells.has(newPhysColIndex)) {
          executedCells.set(newPhysColIndex, executedCells.get(newPhysColIndex).add(physRowIndex));
        } else {
          executedCells.set(newPhysColIndex, new Set<number>().add(physRowIndex));
        }

        let copiedValue;
        const prevValue = datasetPlainlist[physRowIndex][newPhysColIndex].value;
        if (this.autoFillDirection === 'down' || this.autoFillDirection === 'up') {
          copiedValue = datasetPlainlist[copiedRowIndex][newPhysColIndex].value;
        } else if (this.autoFillDirection === 'left' || this.autoFillDirection === 'right') {
          copiedValue = datasetPlainlist[physRowIndex][copiedColIndex].value;
        }
        if (
          !newColumn.windup ||
          colRange.indexOf(copiedColIndex) === -1 ||
          this.autoFillDirection === 'left' ||
          this.autoFillDirection === 'right' // TODO: implement windup for left and right in else condition
        ) {
          if (copiedColumn.fieldType !== newColumn.fieldType) {
            continue;
          }
          datasetPlainlist[physRowIndex][newPhysColIndex].value = this.rowDataUtil.getValuetypeAfterChange(
            colIndex,
            copiedValue
          );
          // } else if (colIndex !== newPhysColIndex) {
          //   continue;
        } else {
          //  const multiplier = this.executeAutoFillRowRange.indexOf(
          const down = visStartRowIndex < rowRangeIndex;
          const multiplier = this.executeAutoFillRowRange.indexOf(rowRangeIndex) + 1;
          const inverted = this.scope
            .getSpreadsheetsettings()
            .columnSettings?.find((col) => col.columnID === newColumn.id);
          if (down) {
            datasetPlainlist[physRowIndex][newPhysColIndex].value = initialValue + offset * multiplier;
          } else {
            datasetPlainlist[physRowIndex][newPhysColIndex].value =
              initialValue + -offset * (this.executeAutoFillRowRange.length - multiplier + 1);
          }

          const val = datasetPlainlist[physRowIndex][newPhysColIndex].value;
        }

        // set change detection to true
        const dataset = datasetPlainlist[physRowIndex][newPhysColIndex];
        dataset.edited = true;

        const entryElement: SaxmsRowEntryElement = new SaxmsRowEntryElement()
          .setPrevValue(prevValue)
          .setValue(dataset)
          .setColNumber(newPhysColIndex)
          .setRowNumber(physRowIndex)
          .setAutoSave(newColumn.autoSaveCell);

        // update handsontable rows
        if (
          this.scope.rows[physRowIndex] !== undefined &&
          this.scope.rows[physRowIndex][newPhysColIndex] !== undefined
        ) {
          this.scope.rows[physRowIndex][newPhysColIndex] = datasetPlainlist[physRowIndex][newPhysColIndex].value;
        }

        changedEntryElements.push(entryElement);
        if (
          !this.scope.hasLoadingAnimation() &&
          changedEntryElements.length > 1 &&
          changedEntryElements.find((element) => element.isAutoSave())
        ) {
          this.scope.setLoadingAnimation(true);
        }
      }
    });
    this.scope.onTableCellEdit(changedEntryElements);
  }

  /**
   * calc the windup value of the column for a array of cells
   * @param column column information
   * @param cells cells for the windup calculation
   * @returns the wind up value
   */
  private getWindupValue(column: SaxMsSpreadSheetColumn, cells: any[]): number {
    if (!column.windup) {
      return 0;
    }

    if (cells.length === 1) {
      return column.windup.value;
    }

    let acc = 0;
    for (let i = 0; i < cells.length - 1; i++) {
      acc += cells[i + 1].value - cells[i].value;
    }
    return acc / (cells.length - 1);
  }

  /**
   * reste the selection information of the table
   */
  private resetSelectionInformations() {
    this.scope.getTableTemplate().setSelectedValues({});
    this.scope.getTableTemplate().setSelectedCells({});
    this.scope.getTableTemplate().setSelectedValue({});
  }

  /**
   * update the current selection information of the table
   * @param selectionModel new selection information
   */
  private changeSelection(selectionModel: SaxMsSelectionModel) {
    const tableRowsFlatlist = this.rowDataUtil.getTableRowPlainList();
    this.resetSelectionInformations();
    this.scope.setSelectionModel(selectionModel);
    this.scope.getTableTemplate().setSelectionModel(selectionModel);
    this.scope.setRowEditable(false);
    if (selectionModel.rowActionsEnable || selectionModel.batchRowActionsEnable) {
      for (const rowIndex of Array.from(
        selectionModel.selectionColumns.get(Array.from(selectionModel.selectionColumns.keys())[0])
      )) {
        this.scope.saveDataInSharedUiService(rowIndex);
      }

      const localRowIndex = Array.from(
        selectionModel.selectionColumns.get(Array.from(selectionModel.selectionColumns.keys())[0])
      )[0];
      this.scope.setRowEditable(
        !!tableRowsFlatlist[localRowIndex] && !!tableRowsFlatlist[localRowIndex].editEntryRestUrl
      );
    }

    const markedCellsMap: Map<string, Set<number>> = new Map();
    const selectedRowsIndices = [];
    selectionModel.selectionColumns.forEach((value: Set<number>, key: string) => {
      value.forEach((rowIndex) => {
        if (!rowIndex && rowIndex !== 0) {
          return;
        }
        rowIndex = this.spreadsheetHelperUtil.getVisualRowIndex(rowIndex);
        // rowIndex = this.getRowIndex(rowIndex);
        // changed because of filtering stuff
        const physicalRowIndex = this.spreadsheetHelperUtil.getRowIndex(rowIndex);
        selectedRowsIndices.push(physicalRowIndex);
        if (tableRowsFlatlist[physicalRowIndex]) {
          const columnSet = markedCellsMap.get(tableRowsFlatlist[physicalRowIndex].resourceId) || new Set();
          columnSet.add(parseInt(key));
          markedCellsMap.set(tableRowsFlatlist[physicalRowIndex].resourceId, columnSet);
        } else {
          console.error('Entry for Row Index ' + physicalRowIndex + ' not found');
        }
      });
    });

    markedCellsMap.forEach((value: Set<number>, key: string) => {
      this.scope.getTableTemplate().addSelectedCell(key, Array.from(value));
    });

    let gridLayout: GridLayout = this.scope.getGridLayout();
    if (!gridLayout) {
      gridLayout = new GridLayout();
      this.scope.setGridLayout(gridLayout);
    }

    const selectionInformation = new SelectionInformation()
      .setContentId(this.scope.getContentId())
      .setTemplateId(this.scope.getTableTemplate().getId())
      .setSelectedRanges([])
      .setSelectionResource(markedCellsMap);

    for (const range of this.scope.getLastSelectedRanges() || []) {
      selectionInformation.addSelectedRange(range);
    }

    this.scope.setSelectionInformation(selectionInformation);

    if (!this.scope.isInitSelection()) {
      this.scope.refreshSelectionInformation(selectionInformation);
    }

    this.scope.getTableTemplate().setSelectedCells(JSON.stringify(this.scope.getTableTemplate().getSelectedCells()));
    of(null)
      .pipe(delay(0))
      .subscribe(() => {
        this.scope.changeDataOfSubmenu();
      });
  }

  /**
   * update the width of a column and save the new width in the settings of the column
   * @param columnIndex index of the column (real index)
   * @param width new width
   */
  private columnResize(columnIndex: number, width: number): void {
    const column = this.columnUtil.getColumnByIndex(columnIndex, false);

    const columnSetting = this.scope.getColumnSettings(column);
    const diff = width - column.width;
    columnSetting.column.width = width;
    column.width = width;

    if (columnSetting.createNew) {
      this.scope.addColumnSettingsToTableSettings(columnSetting.column);
    }

    if (columnIndex === 0) {
      this.scope.setGridStart(width);
    }

    this.scope.saveSettings().pipe(take(1)).subscribe();

    // update width of quicksearch entry-element
    let isAfterAdjustedElem = false;
    this.scope
      .getToolbar()
      ?.quicksearchMenu?.getToolbarGroups()[0]
      ?.getEntryElements()
      .forEach((element) => {
        // updating sticky left positions for all sticky elements after the width changing element
        if (isAfterAdjustedElem && element.isSticky()) {
          element.setStickyLeft(element.getStickyLeft() + diff);
        }

        // adjusting width of width changing element
        if (
          element.getId() ===
          `${this.scope.getTableTemplate().getId()}_quicksearch_${column.filterSortObject.getColumnIndex()}`
        ) {
          element.setWidth(width - 10);
          isAfterAdjustedElem = true;
        }
      });

    this.scope.triggerHeightCalculation(true);
    this.scope.adjustWidthOfLastColumn();
  }

  /**
   * save for some column specific keyboard events (z.B. for the init key feature)
   * @param event keyboard event
   * @param column column information
   */
  private handleNoControlKeyCodes(event: KeyboardEvent, column: SaxMsSpreadSheetColumn) {
    if (!column) {
      return;
    }
    this.savedKeyForEditor = null;
    switch (event.code) {
      case EHotkeyType.A:
      case EHotkeyType.B:
      case EHotkeyType.C:
      case EHotkeyType.D:
      case EHotkeyType.E:
      case EHotkeyType.F:
      case EHotkeyType.G:
      case EHotkeyType.H:
      case EHotkeyType.J:
      case EHotkeyType.K:
      case EHotkeyType.L:
      case EHotkeyType.M:
      case EHotkeyType.N:
      case EHotkeyType.O:
      case EHotkeyType.P:
      case EHotkeyType.Q:
      case EHotkeyType.R:
      case EHotkeyType.S:
      case EHotkeyType.T:
      case EHotkeyType.U:
      case EHotkeyType.V:
      case EHotkeyType.W:
      case EHotkeyType.X:
      case EHotkeyType.Y:
      case EHotkeyType.Z:
      case EHotkeyType.Semicolon:
      case EHotkeyType.BracketLeft:
      case EHotkeyType.Quote:
        if (
          column.fieldType !== EFieldType.DATE_TIME_PICKER &&
          column.fieldType !== EFieldType.DATE_PICKER &&
          column.fieldType !== EFieldType.TIME_PICKER &&
          column.datatype !== ESpreadsheetDatatypes.number &&
          column.datatype !== ESpreadsheetDatatypes.float &&
          !event.ctrlKey // do not save key if it is part of a key shortcut
        ) {
          this.savedKeyForEditor = event.key;
        }
        break;
      case EHotkeyType.Digit0:
      case EHotkeyType.Digit1:
      case EHotkeyType.Digit2:
      case EHotkeyType.Digit3:
      case EHotkeyType.Digit4:
      case EHotkeyType.Digit5:
      case EHotkeyType.Digit6:
      case EHotkeyType.Digit7:
      case EHotkeyType.Digit8:
      case EHotkeyType.Digit9:
      case EHotkeyType.Numpad0:
      case EHotkeyType.Numpad1:
      case EHotkeyType.Numpad2:
      case EHotkeyType.Numpad3:
      case EHotkeyType.Numpad4:
      case EHotkeyType.Numpad5:
      case EHotkeyType.Numpad6:
      case EHotkeyType.Numpad7:
      case EHotkeyType.Numpad8:
      case EHotkeyType.Numpad9:
      case EHotkeyType.NumpadSubtract:
      case EHotkeyType.SLASH:
        this.savedKeyForEditor = event.key;
        break;
    }
  }

  /**
   * transform the copied values of the table to a string format and save it in the clipboard
   * @param rowRange row range of the copied values
   * @param colRange column range of the copied values
   */
  private transformCopiedValuesForClipBoard(rowRange: number[], colRange: SaxMsSpreadSheetColumn[]) {
    const valueForClipboard = [];
    rowRange.forEach((rowIndex) => {
      const rowAsString = {};
      colRange
        .filter((c) => {
          if (!this.scope.hiddenColumns?.columns) {
            return true;
          }
          return this.scope.hiddenColumns.columns.indexOf(c.data) === -1;
        })
        .forEach((column) => {
          const cellValue = Object.values(
            this.rowDataUtil.getDatasetPlainlist()[this.spreadsheetHelperUtil.getRowIndex(rowIndex)]
          ).find((cell) => cell.tableHeaderId === column.id).value;

          const sortIndex = column.data;

          if (cellValue === undefined || cellValue === null) {
            if (column.fieldType === EFieldType.TEXT_FIELD) {
              rowAsString[sortIndex] = '';
            } else {
              rowAsString[sortIndex] = cellValue;
            }
          } else {
            switch (column.fieldType) {
              case EFieldType.CHECK_BOX:
                rowAsString[sortIndex] = this.translate.instant(
                  `Table.ExportFormat.${cellValue ? 'Checked' : 'NoChecked'}`
                );
                break;
              default:
                rowAsString[sortIndex] = this.cellRendererUtil.getVisualValueOfCell(cellValue, column, false);
            }
          }
        });

      valueForClipboard.push(rowAsString);
    });

    let clipboardText = '';

    for (const value of valueForClipboard) {
      if (clipboardText) {
        clipboardText = `${clipboardText}\n`;
      }
      Object.values(value).forEach(
        (cell, index) => (clipboardText = `${clipboardText}${index === 0 ? '' : '\t'}${cell}`)
      );
    }

    const pending = this.scope.clipboard.beginCopy(clipboardText);
    let remainingAttempts = 3;
    const attempt = () => {
      const result = pending.copy();
      if (!result && --remainingAttempts) {
        setTimeout(attempt);
      } else {
        if (!result) {
          const txt = this.scope.translate.instant('ERROR.copy_to_clipboard');
          this.scope.snackbar.open(txt, 'ok', { duration: 5000 });
        }

        pending.destroy();
      }
    };
    attempt();
    //   await navigator.clipboard.writeText(clipboardText);
  }

  /**
   * function for the hook after the autofill feature has been triggered
   * @param fillData not in used
   * @param sourceRange
   * @param targetRange
   * @param direction autofill direation (right, left, up or down)
   */
  public afterAutofill(fillData, sourceRange: CellRange, targetRange: CellRange, direction): void {
    if (!this.autoFillEnabled) {
      return;
    }
    // const changes = [];
    // range for rows
    const rowRange: number[] = this.spreadsheetHelperUtil.range(targetRange.from.row, targetRange.to.row);
    // range for columns
    const colRange: number[] = this.spreadsheetHelperUtil.range(targetRange.from.col, targetRange.to.col);
    this.autoFillDirection = direction;
    this.copiedAutoFillRange = sourceRange;
    this.executeAutoFillColRange = colRange;
    this.executeAutoFillRowRange = rowRange;

    this.onAutofill();
    // this.getRows();
    const sortConfig = this.scope.getSortConfig();
    if (sortConfig && sortConfig.length > 0) {
      const checked = this.spreadsheetHelperUtil.checkPlugin(ESpreadsheetPlugin.SORT_PLUGIN, 'multiColumnSorting');
      if (checked) {
        this.scope.getSortPlugin().sort(sortConfig as any);
        if (this.scope.isInitFinished()) {
          if (this.rowDataUtil.getDatasetPlainlist().length > 10000) {
            this.scope.setInitFinished(false);
          }
        }
      }
    }
  }

  /**
   * handles events after scrolled horizontally
   * @returns void
   */
  public afterScrollHorizontally(): void {
    const event = new CustomEvent(ESpreadsheetEvents.SCROLLED_HORIZONTALLY, {
      detail: { scrollLeft: this.scope.wtHolder?.scrollLeft },
    });
    this.scope.scrollToLeft(event.detail.scrollLeft);
  }

  private isNavigationKey(event: KeyboardEvent): boolean {
    return (
      event.key === 'ArrowUp' ||
      event.key === 'ArrowDown' ||
      event.key === 'ArrowLeft' ||
      event.key === 'ArrowRight' ||
      event.key === 'Tab'
    );
  }

  /**
   * function for the hook after a keyboard event was triggered on the handsontable
   * handle some keyboard events
   * @param event keyboard event
   */
  public beforeKeyDown(event: KeyboardEvent): void {
    // this.stopEditingOfCell(event);
    if (!this.scope.getTableTemplate().isEnableSingleClickEdit() && !this.isNavigationKey(event)) {
      event.stopImmediatePropagation();
      return;
    }
    const coords = this.spreadsheetHelperUtil.getSpreadSheetInstance().getSelectedLast();
    if (!coords) {
      return;
    }
    const column = this.columnUtil.getColumnByIndex(coords[1], true);

    if (!column) {
      return;
    }
    if (column.fieldType !== EFieldType.NUMERIC_RANGE_PICKER) {
      this.handleNoControlKeyCodes(event, column);
    }
    const editor = this.spreadsheetHelperUtil.getCustomEditor();
    if (
      !editor?.isOpen &&
      (event.code === EHotkeyType.DELETE || event.code === EHotkeyType.Backspace) &&
      column.nullable
    ) {
      this.scope.setEditCell({
        rowNumber: this.spreadsheetHelperUtil.getRowIndex(coords[0]),
        columnNumber: column.data,
      });
      this.scope.setCellEditorContext({
        column,
        $implicit: null,
        visibile: false,
      });

      this.scope.afterDeleteKey();
      return;
    }
  }

  /**
   * function for the hook after a mouseover event was triggered on the cells of the handsontable
   * create the tooltip for the hovering cell
   * @param event mouse event
   * @param coords coord of the cell
   * @param td container of the cell
   */
  public afterOnCellMouseOver(event: MouseEvent, coords: any, td: HTMLTableCellElement): void {
    if (!this.scope.isShowTooltip()) {
      return;
    }
    // this._ngOverride.next();
    of(null)
      .pipe(delay(1000), takeUntil(this.scope.getCellMouseOverSub()))
      .subscribe(() => {
        this.scope.setTooltip(null);
        if (coords.col > -1 && (td.children.length <= 2 || td.classList.contains('invalidCellValue'))) {
          // this.addCellTemplate(td, coords);
          const physicalRow: number = isNaN(this.spreadsheetHelperUtil.getRowIndex(coords.row))
            ? coords.row
            : this.spreadsheetHelperUtil.getRowIndex(coords.row);
          const physicalColumn: number = isNaN(this.spreadsheetHelperUtil.getColumnIndex(coords.col))
            ? coords.col
            : this.spreadsheetHelperUtil.getColumnIndex(coords.col);
          const visualRow: number = this.spreadsheetHelperUtil.getVisualRowIndex(physicalRow);
          const visualColumn: number = this.spreadsheetHelperUtil.getVisualColumnIndex(physicalColumn);
          const column: SaxMsSpreadSheetColumn = this.columnUtil.getColumnByIndex(visualColumn, true);
          let relatedCell;
          let cellValue;

          const rect = this.spreadsheetHelperUtil.getSpreadSheetInstance().rootElement.getBoundingClientRect();
          let x = event.clientX - rect.left;
          let y = event.screenY - rect.top;
          if (this.scope.getTableTemplate().isHideHeader()) {
            y = event.clientY - rect.top;
          } else {
            y = event.screenY - rect.top;
          }
          if (coords.row > -1) {
            relatedCell = this.rowDataUtil.getDatasetPlainlist()[physicalRow][physicalColumn];

            cellValue = this.cellRendererUtil.getVisualValueOfCell(relatedCell.value, column, true);

            if (column.datatype === ESpreadsheetDatatypes.bool) {
              cellValue = relatedCell.value;
            }
          } else {
            cellValue = column.label;
            if (column.filterSortObject.isSortActive() || column.filterSortObject.isFilterActive()) {
              // this._ngOverride.next();
              // return;
            }
          }

          if (
            this.scope.getAutoRowSizePlugin() &&
            this.scope.getAutoRowSizePlugin().getLastVisibleRow() === visualRow &&
            this.scope.getTableTemplate().isHideHeader()
          ) {
            y = y - 20;
          }
          if (
            this.scope.getAutoColumnSizePlugin() &&
            (this.scope.getAutoColumnSizePlugin().getLastVisibleColumn() + 1 === visualColumn ||
              this.scope.getAutoColumnSizePlugin().getLastVisibleColumn() === visualColumn)
          ) {
            x = x - 30;
          }
          if (
            cellValue ||
            cellValue === false ||
            cellValue === 0 ||
            (relatedCell && relatedCell.tooltip) ||
            td.classList.contains('invalidCellValue') ||
            column.generalInfoText
          ) {
            this.scope.setTooltip(
              {
                value: relatedCell && relatedCell.tooltip ? relatedCell.tooltip : cellValue,
                hint: column.generalInfoText || '',
                error: td.classList.contains('invalidCellValue') ? column.validationRegexInfoText : '',
                top: y,
                left: x,
              },
              false,
              td
            );
          }
        }
      });
  }

  /**
   * function for the hook after the copy event of the handsontable
   * stores the values of the currently selected cell range
   * prepare the copied values for the parse
   */
  public beforeCopy(data: any[]): boolean {
    const lastSelected = this.spreadsheetHelperUtil.getSpreadSheetInstance().getSelectedRangeLast();
    const rowRange: number[] = this.spreadsheetHelperUtil.range(lastSelected.from.row, lastSelected.to.row);

    const colRange: SaxMsSpreadSheetColumn[] = this.spreadsheetHelperUtil
      .range(lastSelected.from.col, lastSelected.to.col)
      .map((colIndex) => this.columnUtil.getColumns().find((col) => col.data === colIndex));

    if (!this.scope.getConfigApi().access().templates?.Table?.nativeClipboard) {
      this.transformCopiedValuesForClipBoard(rowRange, colRange);
      return false;
    }
  }

  /**
   * mapping the correct value of a cell from a copied value
   * convert string value in complex cell values
   * @param data inforamtion over the copied values
   * @param coords coords for the cells of the paste
   */
  public afterPaste(data: any[], coords: any[]): void {
    if (coords.length === 0) {
      return;
    }
    const copySubs: Observable<any>[] = [];
    const changedCells: SaxmsRowEntryElement[] = [];
    const copyCoords = coords[0];
    const rowRange: number[] = this.spreadsheetHelperUtil.range(copyCoords.startRow, copyCoords.endRow);
    const colRange: SaxMsSpreadSheetColumn[] = this.spreadsheetHelperUtil
      .range(copyCoords.startCol, copyCoords.endCol)
      .map((colIndex) => this.columnUtil.getColumns().find((col) => col.data === colIndex));
    const datasetPlainlist = this.rowDataUtil.getDatasetPlainlist();
    rowRange.forEach((rowIndex, arrayRowIndex) => {
      const physicalRowIndex = this.spreadsheetHelperUtil.getRowIndex(rowIndex);
      const idxInArray = arrayRowIndex % data.length;
      const copyValues: any[] = data[idxInArray];
      if (!copyValues) {
        return;
      }
      colRange.forEach((column, arrayColumnIndex) => {
        const releatedColumIndexInValues = arrayColumnIndex % copyValues.length;
        const relatedCopyValue = copyValues[releatedColumIndexInValues];
        const cells = Object.values(datasetPlainlist[physicalRowIndex]);
        const relatedCell = cells.find((cell) => cell.tableHeaderId === column.id);
        const prevValue = relatedCell.value;
        if (column.readOnly || !relatedCell.rowEditable) {
          return;
        }
        let pasteValue;

        copySubs.push(
          this.getObjectSelectorValues(column, relatedCopyValue).pipe(
            tap((options) => {
              pasteValue = this.getPasteValueOfCell(column, relatedCopyValue, options, relatedCell.value);

              if (pasteValue !== relatedCell.value) {
                relatedCell.value = this.rowDataUtil.getValuetypeAfterChange(column.data, pasteValue);
                relatedCell.edited = true;

                const entryElement: SaxmsRowEntryElement = new SaxmsRowEntryElement()
                  .setPrevValue(prevValue)
                  .setValue(relatedCell)
                  .setColNumber(column.data)
                  .setEntryElement(column.entryElement)
                  .setRowNumber(physicalRowIndex);

                // update handsontable rows
                if (
                  this.scope.rows[physicalRowIndex] !== undefined &&
                  this.scope.rows[physicalRowIndex][column.data] !== undefined
                ) {
                  this.scope.rows[physicalRowIndex][column.data] = pasteValue;
                }

                changedCells.push(entryElement);
              }
            })
          )
        );
      });
    });

    forkJoin(copySubs).subscribe(() => {
      this.scope.onTableCellEdit(changedCells);
      this.scope.rerender(true);
    });
  }

  /**
   * calculate the correct number value for a cell, if the column a number field
   * @param column column information
   * @param value value for the calculation
   * @returns the correct number value
   */
  private getNumericValue(column: SaxMsSpreadSheetColumn, value: string) {
    if (isNaN(parseFloat(value))) {
      return null;
    }
    const numberValue = value
      .replaceAll('.', '') // remove thousand separator
      .replace(',', '.'); // replace decimal separator

    return column.unit === ESaxMsColumnUnits.percent ? parseFloat(numberValue) / 100 : parseFloat(numberValue);
  }

  /**
   * transform the copied string value to a object-selector value
   * @param copiedValue copied string value
   * @param objectSelectorOptions available options of a object-selector(or multi-object-selector)
   * @returns a object-selector value
   */
  private getCopiedObjectSelectorValue(copiedValue: string, objectSelectorOptions: any) {
    if (objectSelectorOptions[copiedValue] && objectSelectorOptions[copiedValue] instanceof EntryElementValue) {
      return (objectSelectorOptions[copiedValue] as EntryElementValue).getValue<EntryElementValue>();
    }
    return null;
  }

  /**
   * convert a copied string value from the clipboard to a correct value of a cell
   * @param column column of the cell
   * @param copiedValue string value from the clipboard for a cell
   * @param objectSelectorOptions available options of a object-selector(or multi-object-selector)
   * @returns correct value of a cell
   */
  private getPasteValueOfCell(
    column: SaxMsSpreadSheetColumn,
    copiedValue: string,
    objectSelectorOptions: any,
    currentCellValue: any
  ) {
    let pasteValue: any = null;
    switch (column.fieldType) {
      case EFieldType.TEXT_FIELD:
        if (this.spreadsheetHelperUtil.isNumberField(column)) {
          pasteValue = this.getNumericValue(column, copiedValue);
        } else {
          pasteValue = copiedValue;
        }
        break;
      case EFieldType.OBJECT_SELECTOR:
        pasteValue = this.getCopiedObjectSelectorValue(copiedValue, objectSelectorOptions);
        break;
      case EFieldType.MULTI_OBJECT_SELECTOR: {
        const selectorValues = copiedValue
          .split(', ')
          .map((value) => this.getCopiedObjectSelectorValue(value, objectSelectorOptions))
          .filter((value) => !!value);
        pasteValue = selectorValues && selectorValues.length > 0 ? selectorValues : null;
        break;
      }
      case EFieldType.COMBO_BOX: {
        const entryElement = column.entryElement as MultiObjectSelector;
        pasteValue = entryElement
          .getAvailableOptions()
          .find((option) => option.getValue<EntryElementValue>().getName() === copiedValue);
        if (!entryElement.getValue<EntryElementValue>()) {
          entryElement.setValue(new EntryElementValue());
        }
        entryElement.getValue<EntryElementValue>().setValue(pasteValue.getValue());
        break;
      }
      case EFieldType.CHECK_BOX:
        if (this.translate.instant('Table.ExportFormat.Checked') === copiedValue) {
          pasteValue = true;
        } else if (this.translate.instant('Table.ExportFormat.NoChecked') === copiedValue) {
          pasteValue = false;
        }
        break;
      case EFieldType.DATE_PICKER: {
        const datePickerValue = moment(copiedValue, this.translate.instant('DATE.MOMENT.date'));
        pasteValue = datePickerValue.isValid() ? datePickerValue.toDate().getTime() : currentCellValue;
        break;
      }
      case EFieldType.DATE_TIME_PICKER: {
        const dateTimePickerValue = moment(copiedValue, this.translate.instant('DATE.MOMENT.datetime'));
        pasteValue = dateTimePickerValue.isValid() ? dateTimePickerValue.toDate().getTime() : currentCellValue;
        break;
      }
      case EFieldType.TIME_PICKER: {
        let timeValue = 0;
        const timeParts = copiedValue.split(':');
        timeParts.forEach((timePart, index) => {
          switch (index) {
            case 0:
              timeValue += parseInt(timePart) * 60 * 60 * 1000;
              break;
            case 1:
              timeValue += parseInt(timePart) * 60 * 1000;
              break;
          }
        });
        pasteValue = timeValue !== 0 ? timeValue : currentCellValue;
        break;
      }
      case EFieldType.DURATION_PICKER:
        pasteValue = this.spreadsheetHelperUtil.convertDurationPickerStringToValue(copiedValue, column);
        break;
      default:
        console.error(`COPY PASTE MAPPING FOR ${column.fieldType}`);
        break;
    }
    return pasteValue;
  }

  /**
   * fetches the available options for a cell if the cell is an object selector
   * @param column column of the cell
   * @param value string value from the clipboard for a cell
   * @returns a Observable of the available options or a empty Observable
   */
  private getObjectSelectorValues(column: SaxMsSpreadSheetColumn, value: any): Observable<any> {
    if (
      (column.fieldType === EFieldType.MULTI_OBJECT_SELECTOR || column.fieldType === EFieldType.OBJECT_SELECTOR) &&
      column.entryElement &&
      column.entryElement.getActionRestUrl()
    ) {
      const entryElement = column.entryElement as MultiObjectSelector;
      const url = this.templateActionApi.getUrlFromParameterSelectors(
        entryElement.getActionRestUrl(),
        entryElement.getResourceId(),
        entryElement.getActionURLParameterSelectors()
      );
      const queryParamterFound = url.indexOf('?') !== -1;
      return this.requestApi
        .call(
          ERequestMethod.GET,
          `rest/${url}${
            !value || entryElement.isLoadAllOptionsObjectSelector() === true
              ? ''
              : `${queryParamterFound ? '&' : '?'}value=${value}`
          } `
        )
        .pipe(
          map((result) => {
            const availableValues = {};
            Object.keys(result).forEach(
              (key: string) =>
                (availableValues[key] = this.entryElementFactory.parseTemplateEntry(column.entryElement, result[key]))
            );
            return availableValues;
          })
        );
    }
    return of(null);
  }

  /**
   * save information about the selected cell before start the editing
   * @param instance instance of the handsontable
   * @param rowIndex row index of edit cell
   * @param columnIndex row index of edit cell
   */
  public afterBeginEditing(rowIndex: number, columnIndex: number): void {
    this.scope.removeTooltip();
    const cellEditorContext: ISaxMsEditorContext = {
      $implicit: '',
      column: null,
      visibile: false,
    };

    const column = this.columnUtil.getColumnByIndex(columnIndex, true);
    cellEditorContext.$implicit = this.rowDataUtil.getCellObjectByPosition(rowIndex, columnIndex);
    cellEditorContext.column = column;
    cellEditorContext.visibile = true;
    this.scope.setEditCell({
      rowNumber: this.spreadsheetHelperUtil.getRowIndex(rowIndex),
      columnNumber: column.data,
      savedKey: this.savedKeyForEditor,
    });
    this.scope.setCellEditorContext(cellEditorContext);
    this.spreadsheetHelperUtil.getCustomEditor().setCoords({ rowPos: rowIndex, colPos: columnIndex });
  }

  /**
   * handle the update of the selection information after the selection is chaged in the handsontable
   */
  public afterSelection(): void {
    const spreadSheetInstance = this.spreadsheetHelperUtil.getSpreadSheetInstance();
    if (!spreadSheetInstance) {
      return;
    }

    const selectionModel = this.scope.getSelectionModel();
    selectionModel.refreshSelection(
      spreadSheetInstance,
      spreadSheetInstance.getSelected(),
      spreadSheetInstance.countRows(),
      Array.from(this.scope.getHiddenColumnFilterSet().values()) || []
    );
    this.scope.setSelectionModel(selectionModel);
    this.changeSelection(selectionModel);
  }

  /**
   * clear the current selection information after the deslect hook of the handsontable
   */
  public afterDeselect(): void {
    this.scope.getSelectionModel().clearSelection();
    this.spreadsheetHelperUtil.getSpreadSheetInstance().deselectCell();
    this.changeSelection(this.scope.getSelectionModel());
    this.scope.rerender();
  }

  /**
   * handle the double click or the right click on a cell
   * @param event mouse event
   * @param coords coords of the clicked cell
   * @param td html container of the cell
   * @param controller not used
   */
  public beforeOnCellMouseDown(event: MouseEvent, coords, td: HTMLTableCellElement, controller): void {
    const realCoords = {
      row: this.spreadsheetHelperUtil.getRowIndex(coords.row),
      col: this.spreadsheetHelperUtil.getColumnIndex(coords.col),
    };

    if (event.button === EClickType.MAIN) {
      if (
        this.clickInProgress &&
        this.lastSelectedCellCoords &&
        this.lastSelectedCellCoords.row === realCoords.row &&
        this.lastSelectedCellCoords.col === realCoords.col
      ) {
        this.scope.getClickTimeoutId().next(void 0);
        this.clickInProgress = null;
        // this.afterSelection();
        if (coords.col === -1) {
          this.scope.handleDoubleClick(realCoords.row);
        }

        if (coords.row === -1) {
          this.scope.handleHeaderDblClick(coords.col);
        }
        return;
      }

      this.clickInProgress = true;
      this.lastSelectedCellCoords = realCoords;
      of(null)
        .pipe(delay(300), takeUntil(this.scope.getClickTimeoutId()))
        .subscribe(() => {
          // try to close custom editor if there is any opened and if it is not the same cell
          this.lastSelectedCellCoords = null;
          this.clickInProgress = null;
        });
    }

    if (event.button === 2) {
      event.stopImmediatePropagation();
      event.stopPropagation();
      event.preventDefault();
      const rightClickEvent: IRightClickEvent = {
        rowIndex: this.spreadsheetHelperUtil.getRowIndex(coords.row),
        colIndex: this.spreadsheetHelperUtil.getColumnIndex(coords.col),
        elementType: coords.col === -1 ? EElementType.ROW : coords.row === -1 ? EElementType.COLUMN : EElementType.CELL,
        htmlTarget: td,
      };
      this.scope.handleRightClick(rightClickEvent, event);
    }
  }

  /**
   * calculates column indices after a column has been moved
   * @param {any[]} columns moved column indices
   * @param {number} finalIndex column line number
   * @param {number} dropIndex dropped col index
   * @param {boolean} movePossible whether move is possible or not
   * @param {boolean} orderChanged whether order has changed or not
   * @returns void
   */
  public afterColumnMoved(
    columns: number[],
    finalIndex: number,
    dropIndex: number,
    movePossible: boolean,
    orderChanged: boolean
  ): void {
    if (!orderChanged && columns.length === 0) {
      return;
    }

    // whether the column was moved forward or backward
    const moveDirection: EMoveDirection = columns[0] < finalIndex ? EMoveDirection.FORWARD : EMoveDirection.BACKWARD;
    const newDropIndex: number = moveDirection === EMoveDirection.FORWARD ? dropIndex - 1 : dropIndex;
    const numColumns: number = columns.length;
    const distance: number = finalIndex - columns[0];
    const editStart: number = moveDirection === EMoveDirection.FORWARD ? columns[0] : newDropIndex;
    const editEnd: number = moveDirection === EMoveDirection.FORWARD ? newDropIndex : columns[0];
    const oldColumnIndices: number[] = columns.slice();

    const editedColumns: number[] = Array.from(
      { length: editEnd - editStart + 1 },
      (v, k: number) => k + editStart
    ).filter((n: number) => !columns.includes(n));

    const newColumns: number[] = columns.map((column: number) => column + distance);

    // move the replaced columns
    editedColumns.forEach((column: number) => {
      oldColumnIndices.push(column);
      if (moveDirection === EMoveDirection.FORWARD) {
        newColumns.push(column - numColumns);
      } else {
        newColumns.push(column + numColumns);
      }
    });

    const oldColumnsData = oldColumnIndices.map((colNumber) => {
      return this.columnUtil.getColumnByIndex(colNumber, true);
    });

    oldColumnsData.forEach((column, index) => {
      column.data = newColumns[index];
    });

    oldColumnsData.forEach((oldColumn: SaxMsSpreadSheetColumn, index) => {
      const column = this.columnUtil.getColumnById(oldColumn.id);
      const columnSetting = this.scope.getColumnSettings(column);
      columnSetting.column.orderIdx = newColumns[index];

      if (columnSetting.createNew) {
        this.scope.addColumnSettingsToTableSettings(columnSetting.column);
      }
      this.spreadsheetHelperUtil.addEntryToColumnIndexMap(
        this.scope.getTableTemplate().getId() + '.' + column.id,
        columnSetting.column.orderIdx
      );
    });
    this.scope.getToolbar()?.quicksearchMenu?.updateElements();
    this.scope.saveSettings().pipe(take(1)).subscribe();
    this.scope.adjustWidthOfLastColumn();
    this.scope.rerender();
  }

  /**
   * update the filter information and the table height after a filter was add/changed or removed
   * @param {any[]} conditionsStack the current active filter conditions of the table
   * @returns void
   */
  public afterFilter(conditionsStack: any[]): void {
    if (conditionsStack?.find((conditionItem) => conditionItem.conditions.length > 0)) {
      this.spreadSheetFilterService.filterActive = true;
    }

    this.scope.recalculateMergedCells();
    this.scope.resetOverflow();
    of(null)
      .pipe(
        delay(0),
        tap((_) => this.scope.triggerHeightCalculation())
      )
      .subscribe();
  }

  /**
   * update the merged cells after sor a column
   */
  public afterColumnSort(): void {
    this.scope.recalculateMergedCells();
  }

  /**
   * emit the afterResize function with a new width of a column
   * @param inctance instance of the handsontable
   * @param currentColumn resized column
   * @param newSize new width of the column
   */
  public afterColumnResize(newSize, currentColumn): void {
    const columnIndex = this.columnUtil.getColumnByIndex(currentColumn, true).filterSortObject.getColumnIndex();
    if (this.spreadsheetHelperUtil.getCustomEditor().isOpen) {
      this.spreadsheetHelperUtil.getCustomEditor().close();
      this.spreadsheetHelperUtil.getSpreadSheetInstance().getActiveEditor()?.close();
    }
    this.columnResize(columnIndex, newSize);
    this.cd.detectChanges();
  }

  /**
   * update the last pressed keyboardkey for the editor
   * @param savedKeyForEditor  last pressed keyboardkey
   * @returns the current SpreadsheetHooksUtilService instance
   */
  public setSavedKeyForEditor(savedKeyForEditor: string): this {
    this.savedKeyForEditor = savedKeyForEditor;
    return this;
  }

  /**
   * update the state if the autofill feature active or not
   * @param autoFillEnabled state of autofill feature
   * @returns the current SpreadsheetHooksUtilService instance
   */
  public setAutoFillEnabled(autoFillEnabled: boolean): this {
    this.autoFillEnabled = autoFillEnabled;
    return this;
  }

  /**
   * update the FullSpreadsheetComponent instance as scope
   * @param scope the FullSpreadsheetComponent instance
   */
  public setScope(scope: FullSpreadsheetComponent): void {
    this.scope = scope;
  }

  /**
   * @returns the current last pressed keyboardkey for the editor (z.B. for the init key feature)
   */
  public getSavedKeyForEditor(): string {
    return this.savedKeyForEditor;
  }
}
