import { EntryElement } from '@app-modeleditor/components/entry-collection/entry-element';
import { EntryElementValue } from '@app-modeleditor/components/entry-collection/entry-element-value';
import { EFieldType } from '@app-modeleditor/components/entry-collection/field-type.enum';
import { Gantt_General } from '../../general.gantt.component';

export class ToggleApplyShowTooltipsElement extends EntryElement {
  constructor(private scope: Gantt_General) {
    super();
  }

  get(data: any): this {
    const initEnabled = (data.entry?.value as boolean) || true;
    const name = (data.name as string) || this.scope.translate.instant('@show-tooltips@');

    this.setFieldType(EFieldType.SLIDE_TOGGLE)
      .setName(name)
      .setAlwaysEnabled(true)
      .setId(data.id)
      .setValue(new EntryElementValue().setValue(initEnabled))
      .onChanges((change) => this.handleApplyShowTooltips(change.value));

    this.handleApplyShowTooltips(initEnabled);

    return this;
  }

  private handleApplyShowTooltips(value: boolean) {
    this.scope.ganttLibService.bestGantt.allowTooltip(value);
  }
}
