import { Injectable, Type } from '@angular/core';
import { TemplateAdapter } from '@app-modeleditor/utils/template-factory.service';
import { TemplateResourceAdapter } from '@app-modeleditor/utils/template-resource-factory.service';
import { ConfigService } from '@core/config/config.service';
import {
  IGanttAttributeMapping,
  IGanttAttributeMetadata,
  IGanttVisibleAttributes,
} from '../general/generator/gantt-input.data';
import { GanttTemplateData } from './gantt';

@Injectable({
  providedIn: 'root',
})
export class GanttAdapter {
  constructor(private configApi: ConfigService, private templateResourceFactory: TemplateResourceAdapter) {}

  inheritFrom<T extends GanttTemplateData>(scope: TemplateAdapter, type: Type<T>, data: any): T {
    const t: T = this.templateResourceFactory
      .inheritFrom<T>(scope, type, data)
      .setAdditionalSubMenus(data.additionalSubMenus)
      .setAttributeMapping(data.attributeMapping)
      .setBlockSortings(data.blockSortings)
      .setDefaultFrontEndFilter(data.defaultFrontEndFilter)
      .setEditAllowSettings(data.editAllowSettings)
      .setGanttActions(data.ganttActions)
      .setGanttLegend(data.ganttLegend)
      .setGanttResponseProvider(data.ganttResponseProvider)
      .setGanttType(data.ganttType)
      .setIndexCard(data.indexCard)
      .setHeadline(data.headline)
      .setMenuMode(data.menuMode)
      .setNameFieldIdentifiers(data.nameFieldIdentifiers)
      .setPlugins(data.plugins)
      .setRegisterUserUpdateReceiverExecuter(data.registerUserUpdateReceiverExecuter)
      .setRelatedGantts(data.relatedGantts)
      .setSearchable(data.searchable)
      .setSettings(data.settings)
      .setSuperBlockAttributeMapping(data.superBlockAttributeMapping)
      .setSuperBlockViews(data.superBlockViews)
      .setUpdateReceiverExecuter(data.updateReceiverExecuter)
      .setSaveUrl(data.saveUrl)
      .setPublicHolidaysURL(data.publicHolidaysURL)
      .setPrintLayout(data.printLayout)
      .setResourceAttributeMapping(data.resourceAttributeMapping)
      .setTooltipSettingsURL(data.tooltipSettingsURL)
      .setIndexCardSettingsURL(data.indexCardSettingsURL)
      .setGanttEntryAttributeMappings(data.ganttEntryAttributeMappings)
      .setDefaultBlockTooltipSettings(
        data.defaultBlockTooltipSettings || this.mapAttributeMappingToDefaultBlockTooltipSettings(data.attributeMapping)
      );

    if (t.getContextmenu()) {
      t.getContextmenu().setDefaultBehaviour(false);
    }
    return t;
  }

  /**
   * Generates tooltip settings from attribute mapping
   * @param attributeMapping
   */
  private mapAttributeMappingToDefaultBlockTooltipSettings(
    attributeMapping: IGanttAttributeMapping
  ): IGanttVisibleAttributes {
    const visibleAttributes = {
      attributeMetadata: [],
      readableClassName: 'Tooltips',
    };
    let index = 0;
    for (const attributeID in attributeMapping) {
      const attribute = attributeMapping[attributeID];
      if (attribute.showInTooltip) {
        const attributeMeta: IGanttAttributeMetadata = {
          attributeID: parseInt(attributeID),
          index: index,
          readableClassName: 'Attribute',
        };
        visibleAttributes.attributeMetadata.push(attributeMeta);
        index++;
      }
    }
    return visibleAttributes;
  }
}
