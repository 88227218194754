import { GanttLibService } from 'frontend/src/dashboard/gantt/gantt/gantt-lib.service';
import { GanttPluginHandlerService } from '../../../plugin/gantt-plugin-handler.service';
import { GanttPredefinedLocalAction } from './predefined-local-action';
import { GanttLocalActionResetZoom } from './reset-zoom/reset-zoom';
import { GanttLocalActionToggleBrokenConstraints } from './toggle-broken-constraints/toggle-broken-constrains';
import { Observable, forkJoin, of } from 'rxjs';

export class GanttPredefinedLocalActions {
  private readonly predefinedLocalActionTypes: (typeof GanttPredefinedLocalAction)[] = [
    GanttLocalActionResetZoom,
    GanttLocalActionToggleBrokenConstraints,
  ];
  private predefinedHandlers: GanttPredefinedLocalAction[] = [];

  constructor(ganttPluginHandlerService: GanttPluginHandlerService, ganttLibService: GanttLibService) {
    for (const type of this.predefinedLocalActionTypes) {
      const instance = new type(ganttPluginHandlerService, ganttLibService);
      this.predefinedHandlers.push(instance);
    }
  }

  public handleLocalAction(action: any): Observable<any[]> {
    if (!action || !action.id) return of([]);
    const targetHandlers = this.predefinedHandlers.filter((handler) => handler.name === action.id);
    if (!targetHandlers || targetHandlers.length <= 0) return of([]);
    return forkJoin(targetHandlers.map((handler) => handler.executeAction(action)));
  }
}
