import { Action } from '@app-modeleditor/components/button/action/action';
import { EActionType } from '@app-modeleditor/components/button/action/action-type.enum';
import { Button } from '@app-modeleditor/components/button/button';
import { EButtonDisplayType } from '@app-modeleditor/components/button/button-display-type.enum';
import { Content } from '@app-modeleditor/components/content/content';
import { ContentPart } from '@app-modeleditor/components/content/content-part/content-part';
import { EntryElement } from '@app-modeleditor/components/entry-collection/entry-element';
import { EEntryElementPosition } from '@app-modeleditor/components/entry-collection/entry-element-position.enum';
import { EntryElementValue } from '@app-modeleditor/components/entry-collection/entry-element-value';
import { EFieldType } from '@app-modeleditor/components/entry-collection/field-type.enum';
import { TextField } from '@app-modeleditor/components/entry-collection/text-field/text-field';
import { GlobalUtils } from 'frontend/src/dashboard/global-utils';
import { Hotkey } from 'frontend/src/dashboard/model/resource/hotkey';
import { EHotkeyType } from 'frontend/src/dashboard/model/resource/hotkey-type.enum';
import { Tenant } from 'frontend/src/dashboard/view/template-footer/default-footer-components/tenant-footer/tenant';
import { Observable } from 'rxjs';
import { Lightbox } from '../lightbox';
import { EntryCollection } from './../../entry-collection/entry-collection';

export class NamedLightbox extends Lightbox {
  private selectionBox: EntryElement;
  private nameField: TextField;
  private createBtn: Button;

  constructor(
    private cb: () => Observable<any>,
    title: string,
    availableValues: string[] | EntryElementValue[],
    showNameField = false
  ) {
    super();
    this.setResourceId(this.getResourceId() || GlobalUtils.generateUUID());
    this.setName(title || 'Create with name');
    this.setCanOpenExternalWindow(false);
    this.setNameRequired(showNameField);
    const entries: EntryElement[] = [];
    this.nameField = new TextField()
      .setShow(this.isNameRequired())
      .setFieldType(EFieldType.TEXT_FIELD)
      .setValue(new EntryElementValue())
      .setRequired(true)
      .setAutoFocused(true)
      .setMaxLength(220)
      .setId('name-input')
      .setName('Name');

    this.setHotkeys([
      new Hotkey()
        .setKeyCode(EHotkeyType.ENTER)
        .setActions([new Action().setActionType(EActionType.LOCAL).setCb(this.cb)]),
    ]);

    this.nameField.onKeyUp().subscribe((val: { value: EntryElementValue; event: KeyboardEvent }) => {
      this.createBtn.setDisabled(val.value.getValue<string>() ? false : true);
    });

    entries.push(this.nameField);

    if (availableValues.length !== 0) {
      this.selectionBox = new EntryElement()
        .setId('type-input')
        .setFieldType(EFieldType.COMBO_BOX)
        .setValue(new EntryElementValue().setAvailableValues(availableValues).setValue(availableValues[0]))
        .setName('Typ')
        .setShow(availableValues.length > 1 ? true : false);

      entries.push(this.selectionBox);
    }

    const content = new Content().setParentId(this.getId());
    const contentPart = new ContentPart().setParentId(content.getId());
    const entryCollection = new EntryCollection()
      // .addHotKeyAction(EHotKey.ENTER, new Action().setActionType(EActionType.LOCAL).setCb(this.cb))
      .setParentId(contentPart.getId());

    this.setContent(
      content.setContentParts([
        contentPart
          .setDisplayContentpartContainer(false)
          .setContentElements([
            entryCollection
              .setName('Allgemein')
              .setEntryElements(entries.map((entry) => entry.setParentId(entryCollection.getId()))),
          ]),
      ])
    );

    this.setDisableSaveButton(true);
    this.setDisableCancelButton(true);

    this.createBtn = new Button()
      .setPosition(EEntryElementPosition.RIGHT)
      .setOrder(999)
      .setDisabled(this.isNameRequired() ? true : false)
      .setDisplayType(EButtonDisplayType.LABEL_ONLY)
      .setName('BUTTON.CONFIRM')
      .setId('saxms-dialog-button-create')
      .chainActions(new Action().setActionType(EActionType.LOCAL).setCb(this.cb));

    // this.setKeys([
    //   new Key().setTargetedIds([this.createBtn.getId()]).setKeyCode('Enter'),
    // ]);

    this.setButtons([this.createBtn]);
  }

  /**
   * add EntryElements to the content
   * @param {...EntryElement[]} ees list of EntryElements
   * @returns this
   */
  addEntryElements(...ees: EntryElement[]): this {
    const ec: EntryCollection = this.getContent().getContentParts()[0].getContentElements()[0] as EntryCollection;
    ec.addEntryElements(...ees);
    return this;
  }

  public addTenantSelector(tenants: Tenant[]): this {
    if (tenants?.length > 0) {
      const selectedIndex: number = tenants.findIndex((t) => t.isSelected());
      const av: EntryElementValue[] = (tenants || []).map((t: Tenant) =>
        new EntryElementValue().setName(t.getName()).setValue(t.getId())
      );
      const tenantSelector: EntryElement = new EntryElement()
        .setId('tenant-selector')
        .setFieldType(EFieldType.COMBO_BOX)
        .setValue(new EntryElementValue().setAvailableValues(av).setValue(av[selectedIndex]))
        .setDisabled(av.length === 1)
        .setName('Mandant')
        .setShow(av.length > 1 ? true : false);
      this.addEntryElements(tenantSelector);
    }

    return this;
  }

  /**
   * get currently set name
   * @returns EntryElementValue
   */
  getNameValue(): EntryElementValue {
    if (!this.nameField) {
      return null;
    }
    return this.nameField.getValue<EntryElementValue>();
  }

  /**
   * get currently selected value
   * @returns EntryElementValue
   */
  getSelectedValue(): EntryElementValue {
    if (!this.selectionBox) {
      return null;
    }
    return this.selectionBox.getValue<EntryElementValue>();
  }
}
