import * as d3 from 'd3';
import { GanttCanvasShift } from '../../data-handler/data-structure/data-structure';
import { GanttShiftTranslationLimiter } from './translation-restrictions/shift-translation-limiter';

/**
 * Data management for chained shifts.
 * Chaining means if one shift will be dragged, all chained shifts will also be translated.
 * @keywords translation, chain, shift, edit, together, drag, translation, translate, grouped, group
 */
export class GanttShiftTranslationChain {
  private _chainedElementIds: { [parentId: string]: string[] } = {};
  private _elementCache: d3.Selection<SVGRectElement, GanttCanvasShift, d3.BaseType, undefined>[] = [];
  private _startWidth: { [id: string]: number } = {};
  private _restrictions: ITranslationChainRestrictions = {
    horizontalForbidden: [],
    verticalForbidden: [],
  };

  /**
   * Chain one shift with another by shift ids.
   * @keywords chain, one, single, shift, connect, glue, drag, translation, register, data
   * @param parentId Shift id of first shift.
   * @param chainElementId Shift id of shift which should be chained with first shfit.
   * @param bothWays: If true, the parent child relationship while dragging is active in both ways.
   */
  public setChainedElementbyId(parentId: string, chainElementId: string, bothWays: boolean): void {
    if (parentId === chainElementId) return; // stop shift from getting chained to itself
    const chainedEntries = [{ parentId: parentId, chainElementId: chainElementId }];

    if (bothWays) chainedEntries.push({ parentId: chainElementId, chainElementId: parentId });

    for (let i = 0; i < chainedEntries.length; i++) {
      const c = chainedEntries[i];
      // create map entry if it doesn't exist
      if (!this._chainedElementIds[c.parentId]) this._chainedElementIds[c.parentId] = [];
      // prevent duplication
      else if (this._chainedElementIds[c.parentId].indexOf(c.chainElementId) > -1) return;

      this._chainedElementIds[c.parentId].push(c.chainElementId);
    }
  }

  /**
   * Removes chaining from specific shift.
   * @keywords remove, clear, delete, chain, element, shift, group
   * @param id Shift id.
   * @param completeDeletion If true, all elements with given id will be removed, if false only the parent element with given id.
   */
  public removeChainedElementbyId(id: string, completeDeletion: boolean): void {
    delete this._chainedElementIds[id];

    if (completeDeletion) {
      for (const key in this._chainedElementIds) {
        const element = this._chainedElementIds[key],
          arrayIndex = element.indexOf(id);

        if (arrayIndex > -1) {
          element.splice(arrayIndex, 1);
        }

        if (element.length == 0) {
          delete this._chainedElementIds[key];
        }
      }
    }
  }

  /**
   * Returns all chained shift ids of shift.
   * @keywords chain, elements, get, storage
   * @param id Shift id.
   * @return List of shift ids.
   */
  public getChainedElementsById(id: string): string[] {
    let chainedElements = this._chainedElementIds[id];
    if (!chainedElements) chainedElements = [];
    return chainedElements;
  }

  /**
   * Store currently dragged elements and store there width for later calculation.
   * @keywords cache, elements, internal
   * @param elements D3 selection of elements.
   */
  public setCacheElements(elements: d3.Selection<SVGRectElement, GanttCanvasShift, d3.BaseType, undefined>[]): void {
    this._elementCache = elements;

    for (let i = 0; i < elements.length; i++) {
      const element = elements[i],
        elementData = element.data()[0];
      this._startWidth[elementData.id] = parseFloat(element.attr('width'));
    }
  }

  /**
   * Empty element cache.
   * @keywords remove, clear, empty, data, cache, internal
   */
  public removeCache(): void {
    this._elementCache = [];
  }

  /**
   * Translates all shift elements inside cache.
   * @keywords translate, move, edit, shifts, chain, grouped, chaining
   * @param dx Horizontal position differenz to last position.
   * @param dy Vertical position differenz to last position.
   * @param x Horizontal position.
   * @param stickyRestrictions Restrictions if start/end of shift is sticky.
   * @param dragStartPosition Tupel of x and y- coordinate.
   * @param blockXbool If true, no translation in horizontal direction possible.
   * @param blockYbool If true, no translation in vertical direction possible.
   */
  public translateElements(
    dx: number,
    dy: number,
    x: number,
    stickyRestrictions: GanttShiftTranslationLimiter,
    dragStartPosition: [number, number],
    blockXbool: boolean,
    blockYbool: boolean
  ): void {
    // translate all currently cached elements
    for (let i = 0; i < this._elementCache.length; i++) {
      const element = this._elementCache[i];
      const elementData = element.data()[0];
      const newX = parseFloat(element.attr('x')) + dx;
      const newY = parseFloat(element.attr('y')) + dy;
      let newWidth = parseFloat(element.attr('width'));

      if (
        this._restrictions.horizontalForbidden.indexOf(elementData.id) == -1 &&
        !stickyRestrictions.shiftHasStickyStart(elementData.id) &&
        !blockXbool
      ) {
        element.attr('x', function () {
          return newX;
        });
      }

      if (this._restrictions.verticalForbidden.indexOf(elementData.id) == -1 && !blockYbool) {
        element.attr('y', function () {
          return newY;
        });
      }

      if (!stickyRestrictions.shiftHasNoStickyRestrictions(elementData.id)) {
        if (stickyRestrictions.shiftHasStickyStart(elementData.id)) {
          newWidth = this._startWidth[elementData.id] + x - dragStartPosition[0];
        }
        if (stickyRestrictions.shiftHasStickyEnd(elementData.id)) {
          newWidth = this._startWidth[elementData.id] - x + dragStartPosition[0];
        }
      }

      element.attr('width', newWidth);
    }
  }

  /**
   * Checks if shift is chained by another shift (not necessarily in both ways).
   * @param shiftId Shift id.
   * @returns True if shift is chanined by another shift, false if not.
   */
  private _isChainedByAnotherShift(shiftId: string): boolean {
    for (const shiftKey in this._chainedElementIds) {
      const chainedShifts = this._chainedElementIds[shiftKey];
      if (chainedShifts.indexOf(shiftId) > -1) {
        return true;
      }
    }
    return false;
  }

  /**
   * Get biggest horizontal difference between x coordinate and all chained shift elements.
   * @keywords calculation, maximum, biggest, difference, distance, gap, chain, first, shifts
   * @param shiftXCoordinate X position.
   * @param chainedElements List of D3 selections of shift rects.
   * @returns Biggest horizontal difference between x coordinate and all chained shift elements.
   */
  public calculateDifferenceBetweenShiftAndFirstChainedShift(
    shiftXCoordinate: number,
    chainedElements: d3.Selection<SVGRectElement, GanttCanvasShift, d3.BaseType, undefined>[]
  ): number {
    let biggestDifference = 0;

    for (let i = 0; i < chainedElements.length; i++) {
      const difference = shiftXCoordinate - parseFloat(chainedElements[i].attr('x'));
      if (difference > biggestDifference) {
        biggestDifference = difference;
      }
    }
    return biggestDifference;
  }

  /**
   * Get biggest vertical difference between y coordinate and all chained shift elements.
   * @keywords calculation, maximum, biggest, difference, distance, gap, chain, shifts, vertical
   * @param shiftYCoordinate Y position.
   * @param chainedElements List of D3 selections of shift rects.
   * @returns Biggest vertical difference between y coordinate and all chained shift elements.
   */
  public calculateDifferenceBetweenShiftAndFirstVerticalChainedShift(
    shiftYCoordinate: number,
    chainedElements: d3.Selection<SVGRectElement, GanttCanvasShift, d3.BaseType, undefined>[]
  ): number {
    let biggestDifference = 0;

    for (let i = 0; i < chainedElements.length; i++) {
      const difference = shiftYCoordinate - parseFloat(chainedElements[i].attr('y'));
      if (difference > biggestDifference) {
        biggestDifference = difference;
      }
    }
    return biggestDifference;
  }

  /**
   * Get biggest horizontal difference between specific shift and all chained shift elements.
   * @keywords calculation, maximum, biggest, difference, distance, gap, chain, last, shifts
   * @param shiftXCoordinate X position of shift.
   * @param shiftWidth Width of shift.
   * @param chainedElements List of D3 selections of shift rects.
   * @returns Biggest horizontal difference between specific shift and all chained shift elements.
   */
  public calculateDifferenceBetweenShiftAndLastChainedShift(
    shiftXCoordinate: number,
    shiftWidth: number,
    chainedElements: d3.Selection<SVGRectElement, GanttCanvasShift, d3.BaseType, undefined>[]
  ): number {
    let biggestDifference = 0;
    for (let i = 0; i < chainedElements.length; i++) {
      const difference =
        parseFloat(chainedElements[i].attr('x')) +
        parseFloat(chainedElements[i].attr('width')) -
        (shiftXCoordinate + shiftWidth);
      if (difference > biggestDifference) {
        biggestDifference = difference;
      }
    }
    return biggestDifference;
  }

  /**
   * Get biggest vertical difference between specific shift and all chained shift elements.
   * @keywords calculation, maximum, biggest, difference, distance, gap, chain, last, shifts, horizontal
   * @param shiftYCoordinate Y position.
   * @param shiftHeight Height of shift.
   * @param chainedElements List of D3 selections of shift rects.
   * @returns Biggest vertical difference between specific shift and all chained shift elements.
   */
  public calculateDifferenceBetweenShiftAndLastHorizontalChainedShift(
    shiftYCoordinate: number,
    shiftHeight: number,
    chainedElements: d3.Selection<SVGRectElement, GanttCanvasShift, d3.BaseType, undefined>[]
  ): number {
    let biggestDifference = 0;

    for (let i = 0; i < chainedElements.length; i++) {
      const difference =
        parseFloat(chainedElements[i].attr('y')) +
        parseFloat(chainedElements[i].attr('height')) -
        (shiftYCoordinate + shiftHeight);
      if (difference > biggestDifference) {
        biggestDifference = difference;
      }
    }
    return biggestDifference;
  }

  //
  // RESTRICTIONS
  //

  /**
   * Sets restriction if chain is (in)active in horizontal direction.
   * @param shiftId Id of chained shift.
   * @param allowHorizontalChain Flag if horizontal chaining is active.
   */
  public horizontalChain(shiftId: string, allowHorizontalChain: boolean): void {
    this._restrictChainedElements(shiftId, allowHorizontalChain, this._restrictions.horizontalForbidden);
  }

  /**
   * Sets restriction if chain is (in)active in vertical direction.
   * @param shiftId Id of chained shift.
   * @param allowVerticalChain Flag if vertical chaining is active.
   */
  public verticalChain(shiftId: string, allowVerticalChain: boolean): void {
    this._restrictChainedElements(shiftId, allowVerticalChain, this._restrictions.verticalForbidden);
  }

  /**
   * Internal calculation to add vertical/horizontal chain restrictions.
   * @param shiftId Id of shift to allow/restrict chaining for.
   * @param allowChain Flag indicating if chaining should be allowed or not.
   * @param dataset Dataset where the shift id should be added to/removed from.
   */
  private _restrictChainedElements(shiftId: string, allowChain: boolean, dataset: string[]): void {
    const shiftIdIndex = dataset.indexOf(shiftId);
    if (allowChain) {
      // remove shift id from restrictions
      if (shiftIdIndex != -1) dataset.splice(shiftIdIndex, 1);
    } else {
      if (shiftIdIndex == -1) dataset.push(shiftId);
    }
  }

  /**
   * Returns a value indicating whether the specified shift is chained or not.
   * @param shiftId Id of the shift to check.
   * @return Value indicating whether the specified shift is chained or not.
   */
  public isChained(shiftId: string): boolean {
    return this._chainedElementIds[shiftId] != null || this._isChainedByAnotherShift(shiftId);
  }

  /**
   * Returns a value indicating whether the specified shift is chained horizontally or not.
   * @param shiftId Id of the shift to check.
   * @return Value indicating whether the specified shift is chained horizontally or not.
   */
  public isHorizontalChained(shiftId: string): boolean {
    return this._restrictions.horizontalForbidden.indexOf(shiftId) == -1;
  }

  /**
   * Returns a value indicating whether the specified shift is chained vertcally or not.
   * @param shiftId Id of the shift to check.
   * @return Value indicating whether the specified shift is chained vertically or not.
   */
  public isVerticalChained(shiftId: string): boolean {
    return this._restrictions.verticalForbidden.indexOf(shiftId) == -1;
  }

  //
  //  GETTER & SETTER
  //

  /**
   * Returns the current dataset of chained shifts.
   * @returns Current dataset of chained shifts.
   */
  public getChainedElements(): { [parentId: string]: string[] } {
    return this._chainedElementIds;
  }

  /**
   * Returns all d3 selections of cached elements.
   * @returns All d3 selections of cached elements.
   */
  public getCachedElements(): d3.Selection<SVGRectElement, GanttCanvasShift, d3.BaseType, undefined>[] {
    return this._elementCache;
  }

  /**
   * Replaces the current dataset of chained shifts.
   * @param newDataSet New dataset of chained shifts.
   */
  public setNewChainedElementsData(newDataSet: { [parentId: string]: string[] }): void {
    this._chainedElementIds = newDataSet;
  }
}

/**
 * Data structure for translation chaoin restrictions.
 */
interface ITranslationChainRestrictions {
  horizontalForbidden: string[];
  verticalForbidden: string[];
}
