import { EGanttInstance } from '@gantt/public-api';
import { GanttLibService } from 'frontend/src/dashboard/gantt/gantt/gantt-lib.service';
import { SaxMsBestGanttActiveSubmenuEntryElementSetting } from 'frontend/src/dashboard/gantt/gantt/saxms-best-gantt.settings';
import { GeneralGanttActionHandler } from 'frontend/src/dashboard/gantt/general/action-handling/action-handler';
import { GanttResponseHandler } from 'frontend/src/dashboard/gantt/general/response/response-handler';
import { Observable, of } from 'rxjs';
import { ExternalGanttPlugin } from '../../external-plugin';
import { GanttPluginHandlerService } from '../../gantt-plugin-handler.service';
import { GanttResponseBlockConnection } from './response/block-connection.response';

const GanttEdgePluginExecuter = 'gantt-edge-plugin-executer';

/**
 * PlugIn-Wrapper for GanttEdgeHandler.
 * Provides funcitonality to connect shift blocks by edges/arrows/connections.
 */
export class GanttBlockConnectionsPlugIn extends ExternalGanttPlugin {
  public injectSettings(submenuElements: SaxMsBestGanttActiveSubmenuEntryElementSetting[]): void {}

  constructor(
    protected _ganttPluginHandlerService: GanttPluginHandlerService,
    protected _ganttLibService: GanttLibService,
    protected actionHandler: GeneralGanttActionHandler,
    private responseHandler: GanttResponseHandler
  ) {
    super(_ganttPluginHandlerService, _ganttLibService, actionHandler);
  }

  private connectionData: IBlockConnectionData[] = [];
  private edgeHandler: any;

  /**
   * Inits connections by backend data.
   * @param templateData
   * @param responseData
   */
  public onInit(templateData: any, responseData: any) {
    if (responseData.hierarchicalPlan.hasOwnProperty('connections')) {
      this.connectionData = responseData.hierarchicalPlan.connections;
    }
    this.addPlugIn(
      GanttEdgePluginExecuter,
      this._ganttLibService.ganttInstanceService.getInstance(EGanttInstance.EDGE_HANDLER)
    );
    this.edgeHandler = this.getPlugInById(GanttEdgePluginExecuter);
    this.setConnectionsToBlocks();
    this.responseHandler.addResponse(GanttResponseBlockConnection, this.edgeHandler);
  }

  public onDestroy(): void {}

  public onAction(action: any) {}

  public executeAction(action: any): Observable<any> {
    return of(null);
  }

  /**
   * Add connections by saved connection data.
   * Usefull to init all edges during gantt start.
   */
  private setConnectionsToBlocks() {
    const mapper = this._ganttLibService.backendToGanttOriginInputMapper;

    for (const connection of this.connectionData) {
      const fromBlockIds = mapper.getShiftClonesByShiftId(connection.fromBlockID);
      const toBlockIds = mapper.getShiftClonesByShiftId(connection.toBlockID);

      for (const from of fromBlockIds) {
        for (const to of toBlockIds) {
          this.edgeHandler.createEdgeByIds(
            from,
            to,
            connection.color || '#000000',
            connection.fromLabel,
            connection.toLabel,
            connection.fromType,
            connection.toType,
            connection.style,
            connection.visualizationMode
          );
        }
      }

      /* this.edgeHandler.createEdgeByIds(
        connection.fromBlockID,
        connection.toBlockID,
        connection.color || "#000000",
        connection.fromLabel,
        connection.toLabel,
        connection.fromType,
        connection.toType,
        connection.style,
        connection.visualizationMode,
      ); */
    }
  }
}

/**
 * Datastructure which holds all information to define a connection.
 */
export interface IBlockConnectionData {
  color?: string;
  fromBlockID: string;
  fromLabel?: string;
  fromType: number;
  id?: string;
  style: number;
  toBlockID: string;
  toLabel?: string;
  toType: number;
  visualizationMode: number;
}
