import { EGanttInstance } from '@gantt/public-api';
import { GanttLibService } from 'frontend/src/dashboard/gantt/gantt/gantt-lib.service';
import { SaxMsBestGanttActiveSubmenuEntryElementSetting } from 'frontend/src/dashboard/gantt/gantt/saxms-best-gantt.settings';
import { GeneralGanttActionHandler } from 'frontend/src/dashboard/gantt/general/action-handling/action-handler';
import { GanttResponseHandler } from 'frontend/src/dashboard/gantt/general/response/response-handler';
import { GanttTemplateData } from 'frontend/src/dashboard/gantt/helper/gantt';
import { Observable, of } from 'rxjs';
import { ExternalGanttPlugin } from '../../external-plugin';
import { GanttPluginHandlerService } from '../../gantt-plugin-handler.service';

export const GanttGridLabelsPlugInId = 'GanttGridLabelsPlugInId';

/**
 * Plugin to visualize labels in rows by time grid.
 * The labels are arranged in time columns.
 * The column size depends on the selected time grid.
 */
export class GanttGridLabelsPlugIn extends ExternalGanttPlugin {
  constructor(
    protected ganttPluginHandlerService: GanttPluginHandlerService,
    protected ganttLibService: GanttLibService,
    protected actionHandler: GeneralGanttActionHandler,
    private responseHandler: GanttResponseHandler
  ) {
    super(ganttPluginHandlerService, ganttLibService, actionHandler);
  }

  public onInit(templateData: GanttTemplateData, responseData: any): void {
    this.addPlugIn(
      GanttGridLabelsPlugInId,
      this._ganttLibService.ganttInstanceService.getInstance(EGanttInstance.GANTT_GRID_LABELS)
    );

    // Check if plugin is needed
    if (templateData.getHierarchicalPlan().samplesGrid) {
      const hp = templateData.getHierarchicalPlan();
      this.getPlugInById(GanttGridLabelsPlugInId)?.activate(hp.samplesGrid, hp.startDate, hp.endDate, hp.samplesColor);
    }
  }

  public onDestroy(): void {}

  public onAction(action: any): void {}
  public executeAction(action: any): Observable<any> {
    return of();
  }
  public injectSettings(submenuElements: SaxMsBestGanttActiveSubmenuEntryElementSetting[]): void {}
}
