import { ExperimentService2 } from 'frontend/src/dashboard/data/experiment/experiment.service';
import { HorizonService, IHorizon } from 'frontend/src/dashboard/horizonselecter/horizon.service';
import { ModelService } from 'frontend/src/dashboard/model/model.service';
import { AbstractGridService } from 'frontend/src/dashboard/moving-grid/abstract-grid.service';
import { Observable, of } from 'rxjs';
import { takeWhile, take } from 'rxjs/operators';

/**
 * Service which provides options to load other experiment data.
 * Usefull for comparing different experiemnts.
 */
export class GanttComparator {
  public experimentService: ExperimentService2;
  public modelService: ModelService;
  public horizonService: HorizonService;
  public gridService: AbstractGridService;
  public widgetData: any;
  private alive = true;
  private currentHorizon: IHorizon;

  constructor(
    experimentService: ExperimentService2,
    sharedModelService: ModelService,
    sharedHorizonService: HorizonService,
    gridService: AbstractGridService,
    widgetData?: any
  ) {
    this.experimentService = experimentService;
    this.modelService = sharedModelService;
    this.horizonService = sharedHorizonService;
    this.gridService = gridService;

    if (widgetData) {
      this.widgetData = widgetData;
    }

    this.horizonService
      .getCurrenHorizon()
      .pipe(takeWhile(() => this.alive))
      .subscribe((horizon: IHorizon) => (this.currentHorizon = horizon));
  }

  public getAllExperiments(): Observable<any> {
    if (!this.currentHorizon) {
      return of([]);
    }

    return this.experimentService.getExperiments().pipe(take(1));
  }

  public getGanttDataByExperimentId(experimentId: string): Observable<any> {
    const pagingString = 'offset=' + 0 + '&limit=' + 50;
    return this.gridService.getWidgetData(experimentId, this.widgetData.widget.id, pagingString);
  }
}
