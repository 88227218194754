import { Component, Input, OnInit, ContentChild, TemplateRef } from '@angular/core';
import { GridLayout } from './grid-layout';
import { Template } from 'frontend/src/dashboard/model/resource/template';

@Component({
  selector: 'grid-layout',
  templateUrl: './grid-layout.component.html',
  styleUrls: ['./grid-layout.component.scss'],
})
export class GridLayoutComponent implements OnInit {
  @Input() layout: GridLayout;
  @Input() templates: Template[];
  @ContentChild('contentElement') contentElement: TemplateRef<any>;

  ngOnInit(): void {}

  /**
   * get template by id
   * @param id string
   * @returns Template
   */
  getTemplateById(id: string): Template {
    return (this.templates || []).find((t: Template) => t.getId() === id);
  }
}
