import * as d3 from 'd3';
import { ComponentEvent } from '../../../history/component-event';

/**
 * @constructor
 * @class
 * @extends ComponentEvent
 * @requires ComponentEvent
 * @plugin shift-splitter
 * */
export class GanttEdgeEvent extends ComponentEvent {
  constructor() {
    super();
    /**
     * @type {GanttEdgeHandler}
     */
    this.ref = null;
  }

  /**
   * @override
   * @returns {boolean} true if undo was succesfull, else false
   */
  undo() {
    const s = this;

    function handle(type) {
      const types = {
        createEdgeByIds: function () {
          return s._undocreateEdgeByIds();
        },
        removeEdgeByEdgeId: function () {
          return s._undoRemoveEdgeByEdgeId();
        },
        setColorByEdgeId: function () {
          return s._undoSetColorByEdgeId();
        },
        setStartLabel: function () {
          return s._undoSetStartLabelByEdgeId();
        },
        setEndLabel: function () {
          return s._undoSetEndLabelByEdgeId();
        },
        default: function () {
          console.warn('can not undo: ' + type + '. May not be implemented yet.');
          return false;
        },
      };
      return (types[type] || types['default'])();
    }
    return handle(s.eventName);
  }

  /**
   * @override
   * @returns {boolean} true if redo was succesfull, else false
   */
  redo() {
    const s = this;
    function handle(type) {
      const types = {
        createEdgeByIds: function () {
          return s._redocreateEdgeByIds();
        },
        removeEdgeByEdgeId: function () {
          return s._redoRemoveEdgeByEdgeId();
        },
        setColorByEdgeId: function () {
          return s._redoSetColorByEdgeId();
        },
        setStartLabel: function () {
          return s._redoSetStartLabelByEdgeId();
        },
        setEndLabel: function () {
          return s._redoSetEndLabelByEdgeId();
        },
        default: function () {
          console.warn('can not redo: ' + type + '. May not be implemented yet.');
          return false;
        },
      };
      return (types[type] || types['default'])();
    }
    return handle(s.eventName);
  }

  /**
   * @overrides
   * @return {String}
   */
  getEventDescription() {
    const s = this;
    function handle(type) {
      const types = {
        addTimePointMarker: function () {
          return GanttEdgeEventEventDescription.getAddTimePointMarkerDescription(
            s.ref.ganttDiagram.getConfig().getLanguage(),
            s.arguments
          );
        },
        default: function () {
          console.warn('no description for: ' + s.eventName + ' set. May not be implemented yet.');
          return '';
        },
      };
      return (types[type] || types['default'])();
    }
    return handle(s.eventName);
  }

  private _undocreateEdgeByIds() {
    const s = this;
    s.ref.disableLogging();

    s.ref.removeEdgeByEdgeId(s.arguments[0].edgeId);

    s.ref.enableLogging();
    return true;
  }

  private _redocreateEdgeByIds() {
    const s = this;
    s.ref.disableLogging();
    /**
     * @type {GanttEdgeHandlerConnectedShifts}
     */
    const conObj = s.arguments[0];
    s.ref.createEdgeByIds(conObj.id1, conObj.id2, conObj.color, conObj.labelStart, conObj.labelEnd, conObj.visible);
    s.ref.rebuildEdges();

    s.ref.enableLogging();
    return true;
  }

  private _undoRemoveEdgeByEdgeId() {
    return this._redocreateEdgeByIds();
  }

  private _redoRemoveEdgeByEdgeId() {
    return this._undocreateEdgeByIds();
  }

  private _undoSetColorByEdgeId() {
    const s = this;
    s.ref.disableLogging();
    s.ref.setColorByEdgeId(s.arguments[0], s.arguments[1]);
    s.ref.enableLogging();
    return true;
  }
  private _redoSetColorByEdgeId() {
    const s = this;
    s.ref.disableLogging();
    s.ref.setColorByEdgeId(s.arguments[0], s.arguments[2]);
    s.ref.enableLogging();
    return true;
  }

  private _undoSetStartLabelByEdgeId() {
    const s = this;
    s.ref.disableLogging();
    s.ref.setStartLabelByEdgeId(s.arguments[0], s.arguments[2]);
    s.ref.enableLogging();
    return true;
  }
  private _redoSetStartLabelByEdgeId() {
    const s = this;
    s.ref.disableLogging();
    s.ref.setStartLabelByEdgeId(s.arguments[0], s.arguments[1]);
    s.ref.enableLogging();
    return true;
  }
  private _undoSetEndLabelByEdgeId() {
    const s = this;
    s.ref.disableLogging();
    s.ref.setStartLabelByEdgeId(s.arguments[0], s.arguments[2]);
    s.ref.enableLogging();
    return true;
  }
  private _redoSetEndLabelByEdgeId() {
    const s = this;
    s.ref.disableLogging();
    s.ref.setStartLabelByEdgeId(s.arguments[0], s.arguments[1]);
    s.ref.enableLogging();
    return true;
  }
}

// GETTER and SETTER inherited from Main Class

export class GanttEdgeEventEventDescription {
  // TODO: Event explanations.
  private constructor() {}

  static getAddTimePointMarkerDescription(language, args) {
    const s = this;
    const formatTimeDE = d3.timeFormat('%H:%M:%S am %d %B %Y');
    const formatTimeEN = d3.timeFormat('%H:%M:%S on %B %d %Y');
    const description = '';
    return description;
  }
}
