import { ChangeDetectorRef, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { TemplateOverlayService } from '@app-modeleditor/components/tree/tree.template-overlay.service';
import { UiService } from '@app-modeleditor/ui.service';
import { ConfigService } from '@core/config/config.service';
import { TranslateService } from '@ngx-translate/core';
import { GenericOptionsComponent } from '../model-editor/ui/generic-options/generic-options.component';
import { ModelService } from '../model/model.service';
import { ConfirmDialog } from '../shared/dialogs/confirm.dialog';
import { SharedToolbarService } from '../view/navbar/toolbar/shared-toolbar.service';
import { NodeNetworkBackground } from './background-handler/background.handler';
import { NodeNetworkColorAttribute } from './color/color-attribute.data';
import { NodeNetworkColorizer } from './color/colorizer';
import { AttributeFilter, AttributeFilterEntry } from './data/attribute-filter';
import { NodeNetworkAttributItem } from './data/node-network-filter-item';
import { NodeNetworkAdditionalData, NodeNetworkNode } from './data/node-network-node';
import { SubGraph } from './data/node-network-subgraph';
import { NodeNetworkType } from './data/node-network-type.enum';
import { SourceNode, ViewOptions } from './data/view-options';
import { DiagramHandler } from './diagram/diagram-handler';
import { EdgeFilterOutputItem } from './edge-filter/edge-filter.component';
import { NodeNetworkEditDialog } from './edit/node-network-dialog/select-dialog/node-network-edit-dialog';
import { NodeNetworkSaveToServer } from './edit/node-network-server-save/node-network-server-save';
import { SysmlFileReader } from './file-reader/sysml-file-reader';
import { NodeNetworkIconAttribute } from './icon-handling/icon-data';
import { NodeNetworkIconEditDialog } from './icon-handling/icon-edit.dialog/icon-edit.dialog.component';
import { IconHandler } from './icon-handling/icon-handler';
import { NodeNetworkConnectionMapper } from './mapper/node-network-connection.mapper';
import { NodeNetworkIconMapper } from './mapper/node-network-icon.mapper';
import { NodeNetworkMapper } from './mapper/node-network.mapper';
import { MiceCinema } from './micecinema/mice-cinema';
import { NodeNetworkSearchNode } from './search/search-node';
import { NodeNetworkCinemaService } from './service/node-network-cinema.service';
import { NodeNetworkDiagramService } from './service/node-network.diagram.service';
import { NodeNetworkEditService } from './service/node-network.edit.service';
import { NodeNetworkImageService } from './service/node-network.image.service';
import { NodeNetworkService } from './service/node-network.service';

declare let Executer: any;
const nodeNetworkScaleWidth = 50;
const nodeNetworkScaleHeight = 120;

@Component({
  selector: 'node-network',
  templateUrl: './node-network.component.html',
  styleUrls: ['./node-network.scss'],
  providers: [SysmlFileReader],
})

/**
 * Angular component which is the interface between backend and JS node network.
 */
export class NodeNetworkComponent {
  @Input() data: any;

  // get references to nodes inside template
  @ViewChild('nodeNetworkWrapper') wrapper: ElementRef;
  @ViewChild('nodeNetworkCanvas') canvas: ElementRef;
  @ViewChild('nodeNetworkDragAndDropCanvas') dragAndDropCanvas: ElementRef;
  @ViewChild('nodeNetworkNodeCanvas') nodeCanvas: ElementRef;
  @ViewChild('nodeNetworkTooltipCanvas') tooltipCanvas: ElementRef;
  @ViewChild('actionBar') actionBar: ElementRef;
  @ViewChild('nodeNetworkSelectionCanvas') selectionCanvas: ElementRef;
  @ViewChild('nodeNetworkStartSelectionCanvas') startSelectionCanvas: ElementRef;
  @ViewChild('nodeNetworkMiceCinemaCanvas') nodeNetworkMiceCinemaCanvas: ElementRef;
  @ViewChild('nodeNetworkBackground') nodeNetworkBackground: ElementRef;

  // get node-network fassade-class (from assets/external_JS/node-diagram)
  public nodeNetworkBuilder: any;

  public rebuild = true;
  public mapper: NodeNetworkMapper;
  public iconMapper: NodeNetworkIconMapper;
  public connectionMapper: NodeNetworkConnectionMapper;
  public hierarchicalLayers: SubGraph[][] = [];
  public currentModel: any;
  public currentViewType: NodeNetworkType;
  public currentViewOptions: ViewOptions;
  public currentNodeNetworkData: any;
  public editedNodeNetworkData: any;
  public nodeNetworkColorizer: NodeNetworkColorizer;
  public allAttributes: { type: string; values: string[] }[];
  public allIconAttributes: string[];
  public legendData: any;
  public currentFilterAttribute: NodeNetworkAttributItem;
  public currentIconFilterAttribute: string;
  public hasMachineView: boolean;
  public generalContentWrapperWidth: number;
  public addingElementTypes: { label: string; type: string }[];

  public templateData: any;

  private searchNodeHandler: NodeNetworkSearchNode = new NodeNetworkSearchNode();
  public autocompleteInput: string;
  public nodeSearchSuggestions: NodeNetworkFoundNodeSuggestionData[] = [];

  // filter globals (to execute multiple filters on origin network data)
  public colorAttributeData: NodeNetworkColorAttribute;
  public iconAttributeData: NodeNetworkIconAttribute;
  public filteredConnections: EdgeFilterOutputItem;
  public zoomLevel: NodeNetworkZoomItem;

  // blocks edit button
  public multipleGraphsSelected = false;

  // shows progress bar if true
  public inProgress = false;

  // toggle filter function inside legend
  public enableNNFilter = true;

  // animation wrapper
  public miceCinema: MiceCinema = new MiceCinema();

  public backgroundHandler: NodeNetworkBackground = new NodeNetworkBackground();

  // icon handling
  public iconHandler: IconHandler;

  // diagram builder for nodes
  public diagramHandler: DiagramHandler;

  restApi;

  private storedMappedData;

  private scrollXInterval;
  private scrollYInterval;
  // toolbar interaction states
  public activeEditMode = false;
  public openEdgeFilter = false;
  public isGoDeeperActive = false;
  public isGeneralContentEnabled = false;

  constructor(
    public service: NodeNetworkService,
    public nodeNetworkImageService: NodeNetworkImageService,
    public globalToolbarService: SharedToolbarService,
    public sysmlFileReader: SysmlFileReader,
    public sharedModelService: ModelService,
    public dialog: MatDialog,
    public serverSave: NodeNetworkSaveToServer,
    public editService: NodeNetworkEditService,
    public cinemaService: NodeNetworkCinemaService,
    public uiService: UiService,
    public configApi: ConfigService,
    public diagramService: NodeNetworkDiagramService,
    private trx: TranslateService,
    public templateOverlayService: TemplateOverlayService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private cdRef: ChangeDetectorRef
  ) {
    this.mapper = new NodeNetworkMapper();
    this.iconMapper = new NodeNetworkIconMapper();
    this.connectionMapper = new NodeNetworkConnectionMapper();
    this.nodeNetworkBuilder = new Executer();
    this.currentModel = this.sharedModelService.model;
    this.currentViewType = NodeNetworkType.PRODUCT;
    this.currentViewOptions = new ViewOptions();
    this.nodeNetworkColorizer = new NodeNetworkColorizer();
    this.allAttributes = [];
    this.currentFilterAttribute = new NodeNetworkAttributItem();
    this.addingElementTypes = [];
    this.matIconRegistry.addSvgIcon(
      'product-icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/img/custom/product-icon.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'machine-icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/img/custom/machine-icon.svg')
    );

    this.activeEditMode = false;

    this.iconHandler = new IconHandler(nodeNetworkImageService);
    this.diagramHandler = new DiagramHandler(diagramService);

    this.zoomLevel = new NodeNetworkZoomItem(1, 1);
    this.backgroundHandler = new NodeNetworkBackground();

    this.init();

    this.templateOverlayService._onAfterCloseOverlay.subscribe((event) => this.refresh());
  }

  private init(): void {
    this.restApi = this.configApi.getRequestUrl();
  }

  ngOnInit() {
    this.editService.getSteps().subscribe((resp) => {
      for (const nodeType in resp) {
        this.addingElementTypes.push({ label: nodeType, type: resp[nodeType] });
      }
    });
  }

  // will be executed if node network will be changed inside tree
  // all other node-changes (like going-deeper etc) don't trigger
  // ngOnChanges event
  ngOnChanges() {
    this.nodeNetworkBuilder.removeRenderElementsByScroll();
    this.zoomLevel = new NodeNetworkZoomItem(1, 1);
    // empty icon data
    this.iconAttributeData = null;
    // empty attribute filter data
    this.colorAttributeData = null;

    if (this.nodeNetworkBuilder.getInformationWrapper()) {
      this.nodeNetworkBuilder.getInformationWrapper().deleteAll();
    }

    this.miceCinema.setActive(false);
    this.cinemaService.getCinema().subscribe((eventLog) => {
      if (!eventLog) return;
      if (eventLog.events) this.miceCinema.setEventLog(eventLog.events);
    });

    if (this.data) {
      this.hierarchicalLayers = [];
      if (this.data.type && this.data.type == 'MACHINE_VIEW') {
        this.currentViewType = NodeNetworkType.MACHINECOMPLETE;
        this.enableNNFilter = false;
      } else {
        this.currentViewType = NodeNetworkType.PRODUCT;
        this.enableNNFilter = true;
      }

      this.nodeNetworkBuilder = new Executer();
      if (this.data && this.data.id) {
        this.inProgress = true;
        this.openEdgeFilter = null;
        this.buildNodeNetwork(this.data.id, new ViewOptions(), this.currentViewType).then(
          (resp) => {
            this.inProgress = false;

            this.machineViewExists().then((bool) => {
              this.hasMachineView = bool;
              if (this.currentViewType == NodeNetworkType.MACHINECOMPLETE) this.hasMachineView = false;
            });
          },
          (err) => {
            this.inProgress = false;
          }
        );
      }

      this.resetAttributeList();
      this.resetIconList();
    }
  }

  public getMainHeight(): string {
    let subtractedValue = 70;
    if (this.hierarchicalLayers.length > 0) {
      subtractedValue += 40;
    }
    if (this.miceCinema.isActive() && this.miceCinema.getEventLog()) {
      subtractedValue += 46;
    }

    return 'calc(100% - ' + subtractedValue + 'px)';
  }

  public machineViewExists(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const modelId = this.currentModel.id;

      this.service.getAllModelViews(modelId, NodeNetworkType.MACHINE).subscribe(
        (machineViewList) => {
          resolve(machineViewList.indexOf(this.data.id) > -1);
        },
        (err) => {
          console.error('Error while trying to get all Machine Views');
          console.error(err);
          reject();
        }
      );
    });
  }

  public scaleDiagrammCanvas(value) {
    this.zoomLevel.setNewScale(value, value);
    this.nodeNetworkBuilder.scaleCanvas(this.zoomLevel.scaleX, this.zoomLevel.scaleY, true);
  }

  public resetScaleDiagrammCanvas() {
    this.zoomLevel.reset();
    this.nodeNetworkBuilder.scaleCanvas(this.zoomLevel.scaleX, this.zoomLevel.scaleY, true);
    if (this.miceCinema.isActive()) {
      const isPlaying = this.miceCinema.getTimeEngine().isPlaying();
      this.miceCinema.goToPosition(this.miceCinema.getCurrentIndex());
      if (isPlaying) this.miceCinema.start();
    }
  }

  private getAllPointsByDirection(axe: 'x' | 'y'): number[] {
    let values: number[] = (this.storedMappedData.fields as any[]).map((data) => {
      return data[axe];
    });

    values = values.concat(
      (this.storedMappedData.connections as any[]).map((data) => {
        return axe === 'x' ? data.startPoint[0] : data.startPoint[1];
      })
    );
    values = values.concat(
      (this.storedMappedData.connections as any[]).map((data) => {
        return axe === 'x' ? data.endPoint[0] : data.endPoint[1];
      })
    );
    return values;
  }

  public scaleDiagrammCanvasByZoomAll() {
    const maxXValue = Math.max(...this.getAllPointsByDirection('x'));
    const maxYValue = Math.max(...this.getAllPointsByDirection('y'));

    const xScaleRatio = this.wrapper.nativeElement.clientWidth / maxXValue;
    // const xScaleRatio = (this.wrapper.nativeElement.clientWidth) / maxXValue;
    const yScaleRatio = this.wrapper.nativeElement.clientHeight / maxYValue;
    // const yScaleRatio = (this.wrapper.nativeElement.clientHeight) / maxYValue;

    const newScale = parseFloat(Math.min(xScaleRatio, yScaleRatio).toFixed(2));

    this.resetScaleDiagrammCanvas();

    this.scaleDiagrammCanvas(newScale);
    this.scaleDiagrammCanvas(1);
    this.scrollToPosition(Math.min(...this.getAllPointsByDirection('x')));
  }

  private scrollToPosition(x?: number, y?: number) {
    let maxWidthOfNode = 0;
    let maxHeightOfNode = 0;
    for (const key in this.nodeNetworkBuilder.nodeGenerator.config) {
      if (this.nodeNetworkBuilder.nodeGenerator.config[key].width > maxWidthOfNode) {
        maxWidthOfNode = this.nodeNetworkBuilder.nodeGenerator.config[key].width;
      }
      if (this.nodeNetworkBuilder.nodeGenerator.config[key].height > maxHeightOfNode) {
        maxHeightOfNode = this.nodeNetworkBuilder.nodeGenerator.config[key].height;
      }
    }

    this.wrapper.nativeElement.scrollTop = y ? y - maxHeightOfNode / 2 : 0;
    this.wrapper.nativeElement.scrollLeft = x ? x - maxWidthOfNode / 2 : 0;
  }

  /**
   * execute this function for the first build of the node-network
   * the .renderElementsByScroll() inside should only be executed once for each build,
   * otherwise it causes performance-problems
   */
  public initNodeNetwork(data: any): void {
    try {
      this.nodeNetworkBuilder.setScrollAfterDragMoveCallback(this.scrollAfterDragMove.bind(this));
      this.nodeNetworkBuilder.setAfterNodeDragEndCallback(this.afterDragEnd.bind(this));
      this.nodeNetworkBuilder.removeRenderElementsByScroll();
      this.nodeNetworkBuilder.init();
      this.nodeNetworkBuilder.setParentSVG(this.canvas.nativeElement);
      this.nodeNetworkBuilder.setParentSVGNodes(this.nodeCanvas.nativeElement);
      this.nodeNetworkBuilder.setDragAndDropParent(this.dragAndDropCanvas.nativeElement);
      this.nodeNetworkBuilder.setParentSVGTooltip(this.tooltipCanvas.nativeElement);
      this.nodeNetworkBuilder.setParentSelectionNodes(
        this.startSelectionCanvas.nativeElement,
        this.selectionCanvas.nativeElement
      );
      this.nodeNetworkBuilder.setDataSet(data);
      this.nodeNetworkBuilder.setScrollMask(this.wrapper.nativeElement);
      this.nodeNetworkBuilder.start();
      this.nodeNetworkBuilder.renderElementsByScroll();
      this.nodeNetworkBuilder.scaleCanvas(this.zoomLevel.addedScaleX, this.zoomLevel.addedScaleY, true);

      // init background handler
      this.backgroundHandler.init(this.nodeNetworkBackground.nativeElement, this.selectionCanvas.nativeElement);
      this.backgroundHandler.buildImages();

      // BOSCH ATHEN
      this.addPlugInToNode();
      this.addDiagramToNode();

      // register callbacks
      this.nodeNetworkBuilder.addOnNodeClickCallback('onNodeClickDashboardActions', this.onNodeClickActions.bind(this));
      this.nodeNetworkBuilder.addOnNodeSelectionCallback(
        'onNodeSelectionDashboardActions',
        this.onNodeSelectionActions.bind(this)
      );
      this.nodeNetworkBuilder.addOnReRenderCallback(
        'onNodeReRenderDashboardActions',
        this.onNodeReRenderActions.bind(this)
      );
    } catch (e) {
      console.warn('Error while trying to build node-diagram');
      console.warn(e);
    }
  }

  public reRenderNodeNetwork(data: any): void {
    try {
      this.nodeNetworkBuilder.setDataSet(data);
      this.nodeNetworkBuilder.reRender();
      this.nodeNetworkBuilder.scaleCanvas(this.zoomLevel.addedScaleX, this.zoomLevel.addedScaleY, true);
    } catch (e) {
      console.warn('Error while trying to build node-diagram');
      console.warn(e);
    }
  }

  public mapAllFilters(dataset: any, scaleWidth: number, scaleHeight: number): any {
    const originData = JSON.parse(JSON.stringify(dataset));
    let mappedData = JSON.parse(JSON.stringify(dataset));

    if (this.iconAttributeData) {
      // insert image nodes to show icons
      mappedData = this.iconMapper.mapIconsByAttribute(
        mappedData,
        this.iconAttributeData.type,
        this.iconAttributeData.values,
        this.restApi
      );
    }

    // map data for nodenetwork and pay attention to node color
    mappedData = this.mapper.mapNodeNetworkData(mappedData, scaleWidth, scaleHeight, 'home', this.colorAttributeData);
    this.storedMappedData = mappedData;
    if (this.filteredConnections) {
      mappedData = this.connectionMapper.mapConnectionsByEdgeFilter(
        mappedData,
        originData,
        this.filteredConnections,
        this.nodeNetworkBuilder.getCurrentScale().line,
        scaleWidth,
        scaleHeight
      );
    }

    return mappedData;
  }

  public toggleCurrentViewType(): NodeNetworkType {
    let newViewType: NodeNetworkType = NodeNetworkType.PRODUCT;

    if (this.currentViewType == NodeNetworkType.PRODUCT) {
      newViewType = NodeNetworkType.MACHINE;
    }

    this.currentViewType = newViewType;
    return newViewType;
  }

  public isMachineView(): boolean {
    return this.currentViewType == NodeNetworkType.MACHINE;
  }

  public getSubgraph(): SubGraph[] {
    if (!this.nodeNetworkBuilder) return;

    const selectedNodesData = this.nodeNetworkBuilder.getSelectedNodeData();
    const subGraphs: SubGraph[] = [];

    // iterate over all selected nodes and check which of them has a subgraph
    for (const key in selectedNodesData) {
      const c = selectedNodesData[key];

      for (const graph of c.subgraphs) {
        const subGraph: SubGraph = new SubGraph();
        subGraph.label = c.label;
        subGraph.url = graph;
        subGraph.id = c.id;

        subGraphs.push(subGraph);
      }
    }

    return subGraphs;
  }

  public showParentGraph(layer: SubGraph[]): void {
    let editedData;

    if (this.activeEditMode) {
      editedData = this.nodeNetworkBuilder.getAllEditedData();

      if (editedData && editedData.length > 0) {
        this.saveChanges(editedData).then(
          (result) => {
            this.buildParentOfSubgraph(layer);
          },
          (reject) => {}
        );
      }
    } else {
      this.nodeNetworkBuilder.emptyEditData();
      this.buildParentOfSubgraph(layer);
    }
  }

  public buildParentOfSubgraph(layer: SubGraph[]): void {
    const layerIndex = this.hierarchicalLayers.indexOf(layer);

    if (layerIndex == -1) return;

    if (layerIndex >= 0) {
      this.inProgress = true;

      // remove next siblings from array list
      this.hierarchicalLayers = this.hierarchicalLayers.splice(0, layerIndex);

      const newFilter: ViewOptions = new ViewOptions();

      if (this.hierarchicalLayers.length > 0) {
        const newGraphs: SubGraph[] = this.hierarchicalLayers[this.hierarchicalLayers.length - 1];
        const newGraphIds: SourceNode[] = [];

        for (const graph of newGraphs) {
          const sourcenode = new SourceNode(graph.url, graph.id);
          newGraphIds.push(sourcenode);
        }

        newFilter.sourceNodes = newGraphIds;
      }

      if (layerIndex > 0 && this.hierarchicalLayers.length > 0) {
        this.multipleGraphsSelected = this.hierarchicalLayers[layerIndex - 1].length > 1;
      } else {
        this.multipleGraphsSelected = false;
      }
      this.currentViewOptions = newFilter;

      this.buildNodeNetwork(this.data.id, newFilter, this.currentViewType).then(
        () => {
          // rebuild mice cinema
          this.miceCinema.reBuild();

          // empty search data
          this.autocompleteInput = '';
          this.nodeSearchSuggestions = [];

          // get current state of cinema
          if (this.miceCinema.getCurrentIndex() > 0) {
            this.positionJumpCinema(this.miceCinema.getCurrentIndex());
          }

          this.inProgress = false;

          if (this.currentFilterAttribute) {
            this.colorizeNodeNetworkNodes(this.currentFilterAttribute.type, this.currentFilterAttribute.values);
          }
        },
        (err) => {
          this.inProgress = false;
          console.error(err);
        }
      );
    }
  }

  public buildSubgraph(type: NodeNetworkType): void {
    // get all currently selected subgraphs
    const subgraphs = this.getSubgraph();

    if (subgraphs.length > 0 && this.data.id) {
      if (!this.currentModel) {
        console.warn('No global model selected!');
        return;
      }

      this.multipleGraphsSelected = subgraphs.length > 1;
      this.inProgress = true;

      // get data for subgraph(s)
      //
      const modelId = this.currentModel.id;

      const subGraphURLs: SourceNode[] = [];

      for (const subGraph of subgraphs) {
        const sourcenode = new SourceNode(subGraph.url, subGraph.id);
        subGraphURLs.push(sourcenode);
      }

      // create filter by adding subgraphs to view Options
      const filter: ViewOptions = new ViewOptions();
      filter.sourceNodes = subGraphURLs;

      this.currentViewOptions = filter;

      // get new subgraph data from server
      // and build new graph
      this.buildNodeNetwork(this.data.id, filter, type).then((data) => {
        // rebuild mice cinema
        this.miceCinema.reBuild();
        if (this.miceCinema.getCurrentIndex() > 0) {
          this.positionJumpCinema(this.miceCinema.getCurrentIndex());
        }

        // empty search data
        this.autocompleteInput = '';
        this.nodeSearchSuggestions = [];

        this.hierarchicalLayers.push(subgraphs);

        // stop loading spinner
        this.inProgress = false;

        if (this.currentFilterAttribute) {
          this.colorizeNodeNetworkNodes(this.currentFilterAttribute.type, this.currentFilterAttribute.values);
        }
      });
    }
  }

  public rebuildNodeNetworkView(type: NodeNetworkType): Promise<any> {
    this.inProgress = true;
    return new Promise<any>((resolve, reject) => {
      this.buildNodeNetwork(this.data.id, this.currentViewOptions, type)
        .then(() => {
          this.inProgress = false;
        })
        .catch((err) => {
          this.inProgress = false;
          console.error(err);
        });
    });
  }

  public buildNodeNetwork(
    nodeNetworkId: string,
    filter: ViewOptions,
    type: NodeNetworkType,
    colorAttributeData?: NodeNetworkColorAttribute
  ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      // get new subgraph data from server
      this.service.getView(this.currentModel.id, nodeNetworkId, filter, type).subscribe((data) => {
        this.currentNodeNetworkData = data;

        const mappedData = this.mapper.mapNodeNetworkData(
          data,
          nodeNetworkScaleWidth,
          nodeNetworkScaleHeight,
          'home',
          colorAttributeData
        );

        this.storedMappedData = mappedData;
        // remove all tooltips
        if (this.nodeNetworkBuilder.informationWrapper) {
          this.nodeNetworkBuilder.informationWrapper.deleteAll();
        }

        // rebuild node network
        this.initNodeNetwork(mappedData);

        const modelId = this.currentModel.id;

        this.service.getAttributes(modelId, nodeNetworkId, filter, type).subscribe(
          (allAttributes) => {
            if (allAttributes && allAttributes.attributeEntries) {
              this.allAttributes = allAttributes.attributeEntries;

              if (this.currentFilterAttribute) {
                // set current filter attribute as member of new attribute list
                for (const attribute of this.allAttributes) {
                  if (this.currentFilterAttribute.type == attribute.type) {
                    this.currentFilterAttribute = attribute;
                  }
                }
              }

              this.iconHandler.getIconAttributes(this.allAttributes).then((result) => {
                this.allIconAttributes = result;
              });

              resolve(null);
            }
          },
          (err) => {
            console.error('Error while trying to get node network attributes');
            console.error(err);
            reject();
          }
        );
      });
    });
  }

  public getListOfNodeNetworks(type: NodeNetworkType): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      if (!this.currentModel) {
        console.warn('No global model selected!');
        reject();
      }

      const modelId = this.currentModel.id;

      this.service.getAllModelViews(modelId, type).subscribe(
        (response) => {
          resolve(response);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
  /**
   * Colorizes nodes by attributes.
   * @param attribute
   * @param attributeValues
   */
  public colorizeNodeNetworkNodes(attribute: string, attributeValues: string[]) {
    const colorDataMap = this.nodeNetworkColorizer.setHSLColorMap(attribute, attributeValues, 80);

    this.legendData = colorDataMap;

    if (attribute && attributeValues && attributeValues.length > 0) {
      this.colorAttributeData = new NodeNetworkColorAttribute();
      this.colorAttributeData.attributeName = attribute;
      this.colorAttributeData.attributeColors = colorDataMap;
    } else this.colorAttributeData = null;

    this.editedNodeNetworkData = this.mapAllFilters(
      this.currentNodeNetworkData,
      nodeNetworkScaleWidth,
      nodeNetworkScaleHeight
    );

    this.reRenderNodeNetwork(this.editedNodeNetworkData);
  }

  public filterNodeByAttribute(event: any) {
    const filteredAttributes: string[] = event;

    const newFilterCollection: AttributeFilter = new AttributeFilter();

    const newFilter: AttributeFilterEntry = new AttributeFilterEntry();
    newFilter.type = this.currentFilterAttribute.type;

    for (const filterAttributeValue of filteredAttributes) {
      newFilter.values.push(filterAttributeValue);
    }

    newFilterCollection.attributeEntries.push(newFilter);
    this.currentViewOptions.attributeFilter = newFilterCollection;

    this.buildNodeNetwork(this.data.id, this.currentViewOptions, this.currentViewType).then(() => {
      if (this.currentFilterAttribute) {
        this.colorizeNodeNetworkNodes(this.currentFilterAttribute.type, this.currentFilterAttribute.values);
      }
    });
  }

  public resetAttributeList() {
    // reset node-color by attribute
    this.legendData = null;
    this.currentFilterAttribute = new NodeNetworkAttributItem();
    // reset connection color/filter
    this.filteredConnections = null;
  }

  public resetIconList() {
    this.currentIconFilterAttribute = null;
  }

  public openGeneralContent(): void {
    const selectedNodesData = this.nodeNetworkBuilder.getSelectedNodeData();
    if (Object.keys(selectedNodesData).length != 1) return;

    let restUrl = '';

    for (const selectedNode in selectedNodesData) {
      restUrl = selectedNodesData[selectedNode].additionalData.restUrl;
    }
    this.uiService.getData(restUrl).subscribe((data) => {
      this.templateOverlayService.onOpenTemplateOverlay(data);
    });
  }

  public toggleEditMode(): boolean {
    this.activeEditMode = !this.activeEditMode;
    let editedData: any;

    // deactivate active edit mode
    this.nodeNetworkBuilder.setAddConnectionMode(false);
    // send all edited data to server
    if (!this.activeEditMode) {
      editedData = this.nodeNetworkBuilder.getAllEditedData();

      if (editedData && editedData.length > 0) {
        this.saveChanges(editedData).then(
          (result) => {
            this.nodeNetworkBuilder.emptyEditData();
            this.rebuildNodeNetworkView(this.currentViewType);
          },
          (reject) => {}
        );
      }
    } else {
      if (this.openEdgeFilter) this.onToggleEdgeFilter();
    }

    return this.activeEditMode;
  }

  public async saveChanges(editedData: any): Promise<any> {
    const text = (await this.trx.get('@save_changes?@'))['value'];
    return new Promise<any>((resolve, reject) => {
      const confirmDialog = this.dialog.open(ConfirmDialog, {
        data: {
          title: '@save@',
          text: text,
        },
      });
      confirmDialog.afterClosed().subscribe((result) => {
        if (result) {
          this.serverSave
            .sendData(this.currentNodeNetworkData.viewtype, this.currentNodeNetworkData.viewids, editedData)
            .then(
              (resp) => {
                resolve(true);
              },
              (error) => {
                console.error('Error while trying to save edited node network to server');
                console.error(error);
                reject();
              }
            );
        } else resolve(false);
      });
    });
  }

  public allowDrop(event: any): void {
    event.preventDefault();
  }

  public dropNewNode(event): void {
    const draggedType: string = event.dataTransfer.getData('text');

    const xCoordinate = event.layerX;
    const yCoordinate = event.layerY;

    const dialogRef = this.dialog.open(NodeNetworkEditDialog, {
      data: {
        type: draggedType,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (!result) return;
      const additionalData: NodeNetworkAdditionalData = new NodeNetworkAdditionalData();
      additionalData.editNodeType = result.nodeType;
      if (result.processName) additionalData.editProcessName = result.processName;
      if (result.processType) additionalData.editProcessType = result.processType;
      if (result.subProcessName) additionalData.editHierarchicalProcessName = result.subProcessName;
      additionalData.resourceRequirements = [];
      additionalData.responseProcess = [];
      const shortNodeType = this.mapper.getNodeTypeByFullName(draggedType);

      this.addNewNode(shortNodeType, result.name, xCoordinate, yCoordinate, additionalData);
    });
  }

  public addNewNode(
    type: string,
    label: string,
    x: number,
    y: number,
    additionalData?: NodeNetworkAdditionalData
  ): void {
    // if there are changes, refresh data from server

    if (additionalData == null) {
      additionalData = new NodeNetworkAdditionalData();
      additionalData.headline = label;
      additionalData.resourceRequirements = [];
      additionalData.responseProcess = [];
    }

    const newNode: NodeNetworkNode = new NodeNetworkNode();
    newNode.x = x;
    newNode.y = y;
    newNode.type = type;
    newNode.label = label;
    newNode.parentId = 'home';

    newNode.additionalData = additionalData;

    this.nodeNetworkBuilder.setNewNode(newNode);
  }

  public removeSelectedElements() {
    const selectedNodes: any = this.nodeNetworkBuilder.getSelectedNodeData();
    const screenIdArray: string[] = [];

    for (const screenId in selectedNodes) {
      screenIdArray.push(screenId);
    }

    this.nodeNetworkBuilder.removeNodes(screenIdArray);
    this.nodeNetworkBuilder.removeSelectedConnections();
  }

  public refresh(): void {
    if (!this.currentModel || !this.currentModel.id) return;

    const modelId = this.currentModel.id;
    this.rebuildNodeNetworkView(this.currentViewType);
  }

  public toggleAddCurveMode(): void {
    this.nodeNetworkBuilder.toggleAddConnectionMode();
    this.nodeNetworkBuilder.toggleInfoWrapperMode();
    this.nodeNetworkBuilder.nodeGenerator.deSelectAllNodes();
  }

  public toggleSelectCurveMode(): void {
    this.nodeNetworkBuilder.toggleSelectConnections();
  }

  public subGraphListToString(list: SubGraph[], infix: string): string {
    return list
      .map((item) => {
        return item.label;
      })
      .join(infix);
  }

  /**
   * Open generic options dialog
   */
  public openOptions() {
    // Load options from backend
    this.service.getNodeNetOptions().subscribe((data) => {
      // Check for default values if no current value in viewoptions
      for (const part of data.parts) {
        if (!this.currentViewOptions[part.id]) {
          this.currentViewOptions[part.id] = part.defaultValue;
        }
      }

      // Open options dialog
      const dialogRef = this.dialog.open(GenericOptionsComponent, {
        data: {
          interface: data,
          settings: this.currentViewOptions,
        },
      });

      // After dialog was closed
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          // Save option changes in viewoptions
          for (const key in result) {
            this.currentViewOptions[key] = result[key];
          }

          // Rebuild nodenet
          this.buildNodeNetwork(this.data.id, this.currentViewOptions, this.currentViewType).then(() => {
            if (this.currentFilterAttribute) {
              this.colorizeNodeNetworkNodes(this.currentFilterAttribute.type, this.currentFilterAttribute.values);
            }
          });
        }
      });
    });
  }

  public onToggleEdgeFilter(): void {
    this.openEdgeFilter = !this.openEdgeFilter;

    if (!this.openEdgeFilter) {
      this.removeConnectionFilter();
      this.filteredConnections = null;
    } else {
      if (this.activeEditMode) this.toggleEditMode();
    }
  }

  public addConnectionLabel(connectionId, originData, labelText, scaleX, scaleY): any {
    return this.mapper.getEndLabelByConnectionId(connectionId, originData, labelText, scaleX, scaleY);
  }

  public filterConnections(filterItem: EdgeFilterOutputItem): void {
    if (!this.nodeNetworkBuilder.getDataSet() || !this.nodeNetworkBuilder.getDataSet().connections) return;

    this.filteredConnections = filterItem;

    if (!filterItem.edges || !filterItem.edges) this.filteredConnections = null;

    const mappedData = this.mapAllFilters(this.currentNodeNetworkData, nodeNetworkScaleWidth, nodeNetworkScaleHeight);

    this.initNodeNetwork(mappedData);
  }

  public removeConnectionFilter(): void {
    const dataSet = this.nodeNetworkBuilder.getDataSet();

    for (const connection of dataSet.connections) {
      connection.label = null;
      connection.screen.color = null;
      connection.screen.show = true;
    }

    this.nodeNetworkBuilder.reRender();
  }

  public onToggleCinemaMode(): void {
    this.miceCinema.setActive(!this.miceCinema.isActive());

    if (this.miceCinema.isActive()) {
      this.nodeNetworkBuilder.blockDragAndDrop();
      this.miceCinema.build(this.nodeNetworkMiceCinemaCanvas.nativeElement, this.nodeNetworkBuilder);
    } else {
      this.nodeNetworkBuilder.allowDragAndDrop();
    }
  }

  public buildNodeIcons(value?: any) {
    // rebuild node network with icons
    if (value) {
      // get map of attributes with an image each from backend
      this.nodeNetworkImageService.getNodeImagesByAttribute(value).subscribe((result) => {
        if (!result) return;

        this.iconAttributeData = new NodeNetworkIconAttribute();
        this.iconAttributeData.type = value;
        this.iconAttributeData.values = result;

        const mappedData = this.mapAllFilters(
          this.currentNodeNetworkData,
          nodeNetworkScaleWidth,
          nodeNetworkScaleHeight
        );

        // rebuild node network
        this.initNodeNetwork(mappedData);
      });
    }
    // rebuild node network without icons
    else {
      this.iconAttributeData = null;
      const mappedData = this.mapAllFilters(this.currentNodeNetworkData, nodeNetworkScaleWidth, nodeNetworkScaleHeight);
      this.initNodeNetwork(mappedData);
    }
  }

  public startMiceCinema(): void {
    if (this.miceCinema) this.miceCinema.start();
  }

  public pauseMiceCinema(): void {
    if (this.miceCinema) this.miceCinema.pause();
  }

  public stopMiceCinema(): void {
    if (this.miceCinema) this.miceCinema.stop();
  }

  public fastForwardCinema(): void {
    if (this.miceCinema) this.miceCinema.fastForward();
  }

  public fastRevertCinema(): void {
    if (this.miceCinema) this.miceCinema.fastRevert();
  }

  public positionJumpCinema(index: number): void {
    if (this.miceCinema) this.miceCinema.goToPosition(index);
  }

  // BOSCH ATHEN
  public addDiagramToNode(): void {
    const s = this;

    this.nodeNetworkBuilder
      .getInformationWrapper()
      .addMenuItem('assets/img/custom/zunehmende-diagramm.jpg', s.openDiagram.bind(s), '');
  }

  public openDiagram(data: any, tooltip: any): void {
    this.diagramHandler.buildQueueDiagram(-240, 0, tooltip, data.id);
  }

  // BOSCH ATHEN
  public addPlugInToNode(): void {
    const s = this;

    this.nodeNetworkBuilder
      .getInformationWrapper()
      .addMenuItem('assets/img/custom/plugin.svg', s.openPlugInDialog.bind(s), '');
  }

  public openPlugInDialog(nodeData): void {
    const addPlugInDialog = this.dialog.open(
      // PlugInDialog
      null,
      {
        data: {},
      }
    );
    addPlugInDialog.afterClosed().subscribe((result) => {
      if (result == undefined) {
        return;
      }

      if (!nodeData.additionalData) {
        nodeData.additionalData = {};
      }
      if (!nodeData.additionalData.extensions) {
        nodeData.additionalData.extensions = [];
      }

      let image = 'assets/img/custom/plugin.svg';
      if (result.iconURL) image = result.iconURL;

      // check if there is already a plug inside
      const plugInExists = nodeData.additionalData.extensions.find((item) => {
        return item.type == 'plugIn';
      });

      if (plugInExists) {
        plugInExists.name = result.name;
        plugInExists.type = 'plugIn';
        plugInExists.imgUrl = image;
      } else {
        nodeData.additionalData.extensions.push({
          name: result.name,
          type: 'plugIn',
          imgUrl: image,
        });
      }

      this.nodeNetworkBuilder.reRender();
    });
  }

  public onToggleBackgroundEditMode(): void {
    this.backgroundHandler.activateEditMode(!this.backgroundHandler.getEditMode());
  }

  public onOpenIconDialog(): void {
    const iconLightbox = this.dialog.open(NodeNetworkIconEditDialog, {});

    iconLightbox.afterClosed().subscribe((result) => {
      if (!result) return;
    });
  }

  public searchForNode(event): void {
    if (!event.option || !event.option.value) this.nodeSearchSuggestions = [];
    const node = this.searchNodeHandler.getNodeById(event.option.value.id, this.nodeNetworkBuilder.getDataSet());
    this.wrapper.nativeElement.scrollTop = 1000;
    if (!node || !this.canvas.nativeElement) return;
    this.wrapper.nativeElement.scrollTop = node.y - 40;
    this.wrapper.nativeElement.scrollLeft = node.x - 80;
    this.autocompleteInput = node.label;
  }

  public getSuggestions(event: any): void {
    this.nodeSearchSuggestions = this.searchNodeHandler
      .getNodesByQuery(event.srcElement.value, this.nodeNetworkBuilder.getDataSet(), 10)
      .map((dataItem) => new NodeNetworkFoundNodeSuggestionData(dataItem.label, dataItem));
  }

  private afterDragEnd() {
    clearInterval(this.scrollYInterval);
    clearInterval(this.scrollXInterval);
  }

  private scrollAfterDragMove(dragEvent, node?: any, currentConnections?: any[], scaleValue?: any) {
    const wrapperClientHeight = this.wrapper.nativeElement.clientHeight;
    const wrapperClientWidth = this.wrapper.nativeElement.clientWidth;

    const wrapperScrollWidth = this.wrapper.nativeElement.scrollWidth;
    const wrapperScrollHeight = this.wrapper.nativeElement.scrollHeight;

    const wrapperScrollTop = this.wrapper.nativeElement.scrollTop;
    const wrapperScrollLeft = this.wrapper.nativeElement.scrollLeft;

    // clear interval on each dragging event to avoid the creation of
    // multiple intervals affected by multiple drag-events.
    if (this.scrollXInterval) {
      clearInterval(this.scrollXInterval);
      this.scrollXInterval = null;
    }

    if (this.scrollYInterval) {
      clearInterval(this.scrollYInterval);
      this.scrollXInterval = null;
    }

    if (wrapperScrollWidth > wrapperClientWidth) {
      if (
        this.scrollXInterval &&
        ((dragEvent.x < wrapperClientWidth + wrapperScrollLeft - 50 && dragEvent.x > wrapperScrollLeft + 50) ||
          wrapperScrollLeft === 0)
      ) {
        clearInterval(this.scrollXInterval);
        this.scrollXInterval = null;
      }

      if (dragEvent.x >= wrapperClientWidth + wrapperScrollLeft - 50) {
        this.scrollXInterval = setInterval(() => {
          if (wrapperClientWidth + this.wrapper.nativeElement.scrollLeft >= wrapperScrollWidth - 10) {
            clearInterval(this.scrollXInterval);
            if (node) {
              this.nodeNetworkBuilder.recalculateConections(dragEvent, currentConnections);
            }
          } else {
            this.wrapper.nativeElement.scrollLeft += 10;
            if (node) {
              node.data()[0].x += 10;
              this.nodeNetworkBuilder.recalculateConections(dragEvent, currentConnections);
              node.attr('transform', 'translate(' + node.data()[0].x + ',' + node.data()[0].y + ') ' + scaleValue);
            }
          }
        }, 100);
      }

      if (dragEvent.x <= wrapperScrollLeft + 50 && wrapperScrollLeft !== 0) {
        this.scrollXInterval = setInterval(() => {
          if (this.wrapper.nativeElement.scrollLeft <= 0) {
            clearInterval(this.scrollXInterval);
            if (node) {
              this.nodeNetworkBuilder.recalculateConections(dragEvent, currentConnections);
            }
          } else {
            this.wrapper.nativeElement.scrollLeft -= 10;
            if (node) {
              node.data()[0].x -= 10;
              this.nodeNetworkBuilder.recalculateConections(dragEvent, currentConnections);
              node.attr('transform', 'translate(' + node.data()[0].x + ',' + node.data()[0].y + ') ' + scaleValue);
            }
          }
        }, 100);
      }
    }

    if (wrapperScrollHeight > wrapperClientHeight) {
      if (
        (this.scrollYInterval && dragEvent.y < wrapperClientHeight + wrapperScrollTop - 50) ||
        dragEvent.y > wrapperScrollTop + 50 ||
        wrapperScrollTop === 0
      ) {
        clearInterval(this.scrollYInterval);
      }

      if (dragEvent.y >= wrapperClientHeight + wrapperScrollTop - 50) {
        this.scrollYInterval = setInterval(() => {
          if (wrapperClientHeight + this.wrapper.nativeElement.scrollTop >= wrapperScrollHeight - 10) {
            clearInterval(this.scrollYInterval);
            if (node) {
              this.nodeNetworkBuilder.recalculateConections(dragEvent, currentConnections);
            }
          } else {
            this.wrapper.nativeElement.scrollTop += 10;
            if (node) {
              node.data()[0].y += 10;
              this.nodeNetworkBuilder.recalculateConections(dragEvent, currentConnections);
              node.attr('transform', 'translate(' + node.data()[0].x + ',' + node.data()[0].y + ') ' + scaleValue);
            }
          }
        }, 100);
      }

      if (dragEvent.y <= wrapperScrollTop + 50 && wrapperScrollTop !== 0) {
        this.scrollYInterval = setInterval(() => {
          if (this.wrapper.nativeElement.scrollTop <= 0) {
            clearInterval(this.scrollYInterval);
            if (node) {
              this.nodeNetworkBuilder.recalculateConections(dragEvent, currentConnections);
            }
          } else {
            this.wrapper.nativeElement.scrollTop -= 10;
            if (node) {
              node.data()[0].y -= 10;
              this.nodeNetworkBuilder.recalculateConections(dragEvent, currentConnections);
              node.attr('transform', 'translate(' + node.data()[0].x + ',' + node.data()[0].y + ') ' + scaleValue);
            }
          }
        }, 100);
      }
    }
  }
  public setGoDeeperActivity(state: boolean): void {
    this.isGoDeeperActive = state;
  }

  private handleNodeSelectionCallback(selectedNotes: any[]): void {
    if (selectedNotes.length) {
      this.setGoDeeperActivity(true);
    } else {
      this.setGoDeeperActivity(false);
    }
  }

  public setGeneralContentEnabled(state: boolean): void {
    this.isGeneralContentEnabled = state;
  }

  public onNodeClickActions() {
    this.setGoDeeperActivity(true);
    this.setGeneralContentEnabled(true);
  }

  public onNodeSelectionActions(selectedNotes: any[]): void {
    if (selectedNotes.length) {
      this.setGoDeeperActivity(true);
    } else {
      this.setGoDeeperActivity(false);
    }
    if (selectedNotes.length === 1) {
      this.setGeneralContentEnabled(true);
    } else {
      this.setGeneralContentEnabled(false);
    }
  }

  public onNodeReRenderActions() {
    this.setGoDeeperActivity(false);
    this.setGeneralContentEnabled(false);
  }
}

export class NodeNetworkFoundNodeSuggestionData {
  public label: string;
  public value: any;
  constructor(label: string, value: any) {
    this.label = label;
    this.value = value;
    console.error('NODE NETWORK INSTATIATED');
  }
}

export class NodeNetworkZoomItem {
  scaleX = 1;
  scaleY = 1;
  addedScaleX = 1;
  addedScaleY = 1;

  constructor(x, y) {
    this.setNewScale(x, y);
  }

  public reset() {
    this.scaleX = 1;
    this.scaleY = 1;
    this.addedScaleX = 1;
    this.addedScaleY = 1;
  }

  public setNewScale(x, y): void {
    this.scaleX = x;
    this.scaleY = y;
    this.addedScaleX *= x;
    this.addedScaleY *= y;
  }
}
