import { EHotkeyType } from 'frontend/src/dashboard/model/resource/hotkey-type.enum';
import Handsontable from 'handsontable';
import { SaxMsSpreadSheetColumn } from '../saxms-spreadsheet';

export class CellInformation {
  public colPos: number;
  public rowPos: number;
}
export class CustomEditor {
  private currentCell: CellInformation = new CellInformation();
  private currentColumnSetting: SaxMsSpreadSheetColumn;
  private instance;
  private eventManager;
  private lockHTMLElement;
  private templateHTMLElement;
  private lockFlag = false;
  private jumpToNextCell = true;
  private editor;
  private handleTabCallback: () => void;
  private handleEnterCallback: () => void;
  private handleEscapeCallback: () => void;

  public customEditor;
  public valueCache: any;
  public isOpen = false;
  public blockClose = false;

  constructor() {
    // super();
    this.customEditor = Handsontable.editors.BaseEditor.prototype.extend();
  }

  public setTabCallback(cb: () => void): this {
    this.handleTabCallback = cb;
    return this;
  }
  public setEnterCallback(cb: () => void): this {
    this.handleEnterCallback = cb;
    return this;
  }
  public setHandleEscapeCallback(cb: () => void): this {
    this.handleEscapeCallback = cb;
    return this;
  }

  public getCoords(): CellInformation {
    return this.currentCell;
  }

  public setCoords(currentCell: CellInformation): this {
    this.currentCell = currentCell;
    return this;
  }

  public setBlockClose(state: boolean): this {
    this.blockClose = state;
    return this;
  }

  public close(): void {
    this.setValue(this.valueCache);
  }

  public setValue(value: any, executeClose?: boolean): void {
    this.valueCache = value;
    if (executeClose) {
      this.customEditor.prototype.close();
    }
  }

  public lock(lock: boolean, htmlElement: any): void {
    this.lockFlag = lock;
    if (this.lockFlag) {
      if (htmlElement) {
        this.lockHTMLElement = htmlElement;
      }
      (this.lockHTMLElement as HTMLDivElement).addEventListener('mousedown', this.mousedown.bind(this));
      // this.eventManager.addEventListener(this.lockHTMLElement, 'mousedown', this.mousedown.bind(this));
    } else {
      (this.lockHTMLElement as HTMLDivElement).removeEventListener('mousedown', this.mousedown.bind(this));
    }
  }

  private mousedown(event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
  }

  private isEditKey(keycode) {
    switch (keycode) {
      case 'Backspace':
      case 'ArrowLeft':
      case 'ArrowRight':
      case 'ArrowUp':
      case 'ArrowDown':
      case 'Enter':
      case 'Tab':
      case 'Escape':
      case EHotkeyType.NumpadEnter:
        return true;
      default:
        return false;
    }
  }

  public initEditor(templateElement: HTMLElement, closeCallback: (a, b, c, d) => void): any {
    const _self = this;
    const stopCloseCellClick = function (event, coords, td, controller) {
      if (_self.isOpen && coords.row === _self.currentCell.rowPos && coords.col === _self.currentCell.colPos) {
        Handsontable.dom.stopImmediatePropagation(event);
      } else {
        _self.jumpToNextCell = false;
      }
    };

    const handleKeyDown = function (event, coords, td, controller) {
      if (_self.isEditKey(event.code) && _self.isOpen) {
        if (event.code === EHotkeyType.ENTER || event.code === EHotkeyType.NumpadEnter) {
          _self.handleEnterCallback();
        }
        if (event.code === EHotkeyType.TAB) {
          _self.handleTabCallback();
        }
        if (event.code === EHotkeyType.ESCAPE) {
          _self.handleEscapeCallback();
        }
        Handsontable.dom.stopImmediatePropagation(event);
      }

      if (event.code === 'Delete' && _self.currentColumnSetting.nullable === true) {
        _self.customEditor.prototype.setValue(0);
        _self.close();
      }
    };

    this.customEditor.prototype.init = function () {
      // eslint-disable-next-line prefer-rest-params
      // Handsontable.editors.TextEditor.prototype.init.apply(this, arguments);
      _self.eventManager = this.eventManager;
    };

    // this.customEditor.prototype.prepare = function () {
    //   // eslint-disable-next-line prefer-rest-params
    //   Handsontable.editors.TextEditor.prototype.prepare.apply(this, arguments);
    // };

    this.customEditor.prototype.getValue = function () {
      // eslint-disable-next-line radix
      return _self.valueCache;
    };

    this.customEditor.prototype.setValue = function (value) {
      // eslint-disable-next-line radix
      _self.valueCache = value;
    };

    this.customEditor.prototype.open = function () {
      if (!this.cellProperties) {
        return;
      }
      _self.jumpToNextCell = true;
      _self.editor = this;
      _self.templateHTMLElement = templateElement;
      _self.eventManager = this.eventManager;
      _self.currentColumnSetting = this.cellProperties.__proto__;

      _self.currentCell.colPos = this.cellProperties.col;
      _self.currentCell.rowPos = this.cellProperties.row;
      Handsontable.dom.empty(this.TD);
      const parentDiv = document.createElement('div');

      parentDiv.appendChild(templateElement);
      (this.TD as HTMLElement).appendChild(parentDiv.firstChild);
      if (!_self.instance) {
        _self.instance = this.instance;
        _self.instance.addHook('beforeOnCellMouseDown', stopCloseCellClick);
        _self.instance.addHook('beforeKeyDown', handleKeyDown);
      }
      // register listener
      _self.isOpen = true;
    };

    this.customEditor.prototype.close = function (test) {
      if (_self.isOpen === false) {
        return;
      }
      _self.isOpen = false;

      if (_self.instance) {
        _self.instance.removeHook('beforeKeyDown', handleKeyDown);
        _self.instance.removeHook('beforeOnCellMouseDown', stopCloseCellClick);
        _self.instance = null;
      }

      try {
        Handsontable.dom.empty(this.TD);
      } catch (error) {}

      // (this.TD as HTMLElement).removeChild(templateElement);
      // of(null)
      //   .pipe(delay(0))
      //   .subscribe(() =>
      closeCallback(_self.currentCell.colPos, _self.currentCell.rowPos, _self.valueCache, _self.jumpToNextCell);
      // );
    };

    this.customEditor.prototype.focus = function () {};

    return this.customEditor;
  }
}
