export class LogData {
  rowCount: number;
  logEntries: LogEntry[] = new Array<LogEntry>();
}

export class LogEntry {
  timestamp: number;
  logger: string;
  level: string;
  thread: string;
  message: string;
  throwable: string;
}

export enum LogType {
  ALL,
  DEBUG,
  ERROR,
  FATAL,
  INFO,
  TRACE,
  WARN,
}
