import { OverlayContainer } from '@angular/cdk/overlay';
import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Event, Router, RoutesRecognized } from '@angular/router';
import { TemplateService } from '@app-modeleditor/utils/template.service';
import { Actions, ofActionSuccessful } from '@ngxs/store';
import { NavigationService } from 'frontend/src/dashboard/core/navigation/navigation.service';
import { Subject, Subscription } from 'rxjs';
import { takeUntil, takeWhile } from 'rxjs/operators';
import { AuthenticationService } from './core/authentication/auth.service';
import { CustomMessagingActionService } from './core/custom-messaging-action.service';
import { LanguageService } from './core/provider/language.service';
import { AuthActions } from './login/data-access/auth.actions';
import { LoadingStateService } from './model/loading.service';
import { AssetPathService } from './utils/asset-path.service';
import { SharedToolbarService } from './view/navbar/toolbar/shared-toolbar.service';
import { ViewService } from './view/view.service';
import { WindowInitData } from './view/window/data-types.interface';
import { EMessageType } from './view/window/message-type.enum';
import { WindowService } from './view/window/window.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [],
})
export class AppComponent implements OnDestroy, AfterViewInit {
  private subscriptions: Array<Subscription> = [];
  private initialNavigationSuccess = false;
  private onWindowClosed$: Subject<void> = new Subject();
  protected loadingModel$ = this.loadingStateService.loadingModel$;

  constructor(
    public globalViewService: ViewService,
    public overlayContainer: OverlayContainer,
    public sharedToolbarService: SharedToolbarService,
    public auth: AuthenticationService,
    private router: Router,
    private windowApi: WindowService,
    private activatedRoute: ActivatedRoute,
    private lang: LanguageService,
    private customAction: CustomMessagingActionService,
    private navigationApi: NavigationService,
    private _assetPathService: AssetPathService,
    private actions$: Actions,
    private loadingStateService: LoadingStateService,
    private templateService: TemplateService
  ) {
    this.actions$
      .pipe(ofActionSuccessful(AuthActions.Login))
      .subscribe(() => this.router.navigate(['/', 'guard', 'app'], { queryParamsHandling: 'merge' }));

    this.listenToExternalWindowClose();
    this.handleBrowserWindowClosed();
    this.navigate();
    this.lang.initialize();
    this.customAction.initialize();
    this.navigationApi.initialize();
    this._assetPathService.initialize();
  }

  /**
   * Registers an event listener that takes care of tasks to be executed
   * before the browser window is closed.
   */
  private handleBrowserWindowClosed() {
    addEventListener('beforeunload', (event) => {
      if (!this.windowApi.isWindow()) {
        this.onWindowClosed$.next(); // prevent triggering close actions twice
        this.templateService.getActiveMenuItems().forEach((menuItem) => {
          this.templateService.removeActiveMenuItem(menuItem.getId());
        });
        this.windowApi.sendMessage({ type: EMessageType.CLOSE_ALL_WINDOWS });
      }
    });
  }

  /**
   * Listens to external window close events
   */
  private listenToExternalWindowClose() {
    this.windowApi
      .listenToClosedMenuItems()
      .pipe(takeUntil(this.onWindowClosed$))
      .subscribe((closedMenuItem) => {
        this.templateService.removeActiveMenuItem(closedMenuItem);
      });
  }

  /**
   * handles initial navigation
   */
  navigate(): void {
    this.router.events.pipe(takeWhile(() => !this.initialNavigationSuccess)).subscribe((event: Event) => {
      if (!!event && event instanceof RoutesRecognized) {
        const params = event.state.root.queryParams;
        if (params['target']) {
          const data: WindowInitData = JSON.parse(atob(params['target']));
          this.windowApi.setData(data);
          this.auth.disableAutoRefresh();
          this.router.navigate(['./window'], {
            relativeTo: this.activatedRoute,
            skipLocationChange: true,
          });
        } else if (event.url.startsWith('/reset-password')) {
          // do not redirect to guard if user is on reset password page
        } else {
          this.auth.enableAutoRefresh();
          this.router.navigate(['./guard'], {
            relativeTo: this.activatedRoute,
            skipLocationChange: true,
          });
        }
        this.initialNavigationSuccess = true;
      }
    });
  }

  ngOnDestroy(): void {
    // Called once, before the instance is destroyed.
    // Add 'implements OnDestroy' to the class.
    for (const s of this.subscriptions) {
      s.unsubscribe();
    }

    this.windowApi.destroy();
  }

  ngAfterViewInit(): void {
    let oldStyle;
    this.globalViewService.getCurrentTheme().subscribe((style) => {
      if (oldStyle && oldStyle !== '') {
        this.overlayContainer.getContainerElement().classList.remove(oldStyle);
        document.body.classList.remove(oldStyle);
      }

      document.body.classList.add(style);
      this.overlayContainer.getContainerElement().classList.add(style);
      oldStyle = style;
    });
  }
}
