<div mat-dialog-title class="dialog-title-wrapper">
  <span class="dialog-title-span">{{ 'CHART.VIEW.INTEGRAL.DIALOG.Title' | translate | capitalize }}</span>
</div>
<div mat-dialog-content style="padding: 16px !important">
  <div>
    <mat-form-field>
      <mat-label>{{ 'CHART.VIEW.INTEGRAL.DIALOG.Start' | translate | capitalize }}</mat-label>
      <mat-select [(ngModel)]="outputData.startId" (selectionChange)="startIdChange($event)">
        <mat-option *ngFor="let startGroup of data.startDatasets" [value]="startGroup.id">
          <div class="chart-group-container">
            <div class="chart-group-color-container" mat-mini-fab [style.background]="startGroup.borderColor"></div>
            <span class="chart-group-label" [matTooltip]="startGroup.label">
              {{ startGroup.label }}
            </span>
          </div>
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div>
    <mat-checkbox [(ngModel)]="outputData.connectToOrigin">
      {{ 'CHART.VIEW.INTEGRAL.DIALOG.ConnectOrigin' | translate | capitalize }}</mat-checkbox
    >
  </div>
  <div>
    <mat-form-field>
      <mat-label>{{ 'CHART.VIEW.INTEGRAL.DIALOG.Destination' | translate | capitalize }}</mat-label>
      <mat-select [disabled]="outputData.connectToOrigin" [(ngModel)]="outputData.destinationId">
        <mat-option *ngFor="let startGroup of data.startDatasets" [value]="startGroup.id">
          <div class="chart-group-container">
            <div class="chart-group-color-container" mat-mini-fab [style.background]="startGroup.borderColor"></div>
            <span class="chart-group-label" [matTooltip]="startGroup.label">
              {{ startGroup.label }}
            </span>
          </div>
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="''">{{ '@cancel@' | translate | capitalize }}</button>
  <span class="spacer"></span>
  <button mat-button [disabled]="!isOutputValid()" [mat-dialog-close]="outputData">
    {{ '@add@' | translate | capitalize }}
  </button>
</div>
