import { Injectable, Type } from '@angular/core';
import { TemplateAdapter } from '@app-modeleditor/utils/template-factory.service';
import { Adapter } from '@core/adapter';
import { ContentElementAdapter } from '../structure/content-element-adapter.service';
import { WidgetContainer } from './widget-container';

@Injectable({
  providedIn: 'root',
})
export class WidgetContainerAdapter implements Adapter<WidgetContainer> {
  constructor(private contentElementAdapter: ContentElementAdapter) {}

  adapt(item: any): WidgetContainer {
    throw new Error('Method not implemented.');
  }

  inherit?<T extends WidgetContainer>(type: Type<T>, item: any): T {
    const t: T = this.getData(this.contentElementAdapter.inherit<T>(type, item), item);
    return t;
  }

  inheritFrom?<T>(scope: TemplateAdapter, type: Type<any>, item: any): T {
    const t: T = this.getData(this.contentElementAdapter.inheritFrom(scope, type, item), item);
    return t;
  }

  private getData<T extends WidgetContainer>(element: T, data: Record<string, any>): T {
    element
      .setAdditionalSubMenus(data.additionalSubMenus)
      .setGetWidgetRestUrl(data.getWidgetRestUrl)
      .setCompareRestUrl(data.compareRestUrl);
    return element;
  }

  applyValues<T>(scope: TemplateAdapter, item: any, values: any): T {
    throw new Error('Method not implemented.');
  }
}
