import { Component, OnInit, Input, HostBinding } from '@angular/core';
import { GlobalViewService } from 'frontend/src/dashboard/view/global-view.service';

@Component({
  selector: 'saxms-lightbox',
  templateUrl: './saxms-lightbox.component.html',
  styleUrls: ['./saxms-lightbox.scss-theme.scss'],
})
export class SaxmsLightboxComponent implements OnInit {
  @HostBinding('class') styleRef = false;
  @Input() contentBorder = true;

  constructor(private globalViewService: GlobalViewService) {
    this.styleRef = this.globalViewService.currentStyle;
    this.globalViewService.onChangeStyle.subscribe((style) => {
      this.styleRef = style;
    });
  }

  ngOnInit() {}
}
