import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SaxmsLightboxComponent } from '@app-modules/lightbox-module/components/saxms-lightbox.component';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../../core/material/material.module';

@NgModule({
  imports: [
    CommonModule,
    /**
     * material
     */
    MaterialModule,
    TranslateModule,
    // LanguageModule
  ],
  declarations: [SaxmsLightboxComponent],
  exports: [SaxmsLightboxComponent],
})
export class SaxmsLightboxModule {}
