<ul class="info-container">
  <div class="headline">
    <mat-icon>info</mat-icon>
    <span>{{ 'SECURITY.PASSWORD.policy' | translate }}:</span>
  </div>
  <li *ngIf="lowercase">{{ 'one lowercase' | translate }}</li>
  <li *ngIf="uppercase">{{ 'one uppercase' | translate }}</li>
  <li *ngIf="decimal">{{ 'one number' | translate }}</li>
  <li *ngIf="specialDecodedCharacters">{{ 'one special character' | translate }} {{ specialDecodedCharacters }}</li>
  <li>{{ 'SECURITY.PASSWORD.LENGTH.min' | translate : { value: minPasswordLength } }}</li>
  <li>{{ 'SECURITY.PASSWORD.LENGTH.max' | translate : { value: maxPasswordLength } }}</li>
</ul>
