import * as d3 from 'd3';
import { Observable, Subject, takeUntil } from 'rxjs';
import { GanttCallBackStackExecuter } from '../../callback-tools/callback-stack-executer';
import { ToHexColorConverter } from '../../color/color-converter/to-hex-converter';
import { GanttConfig } from '../../config/gantt-config';
import { GanttCanvasShift } from '../../data-handler/data-structure/data-structure';
import { GanttScrollContainerEvent } from '../../html-structure/scroll-container-event';
import { EGanttScrollContainer } from '../../html-structure/scroll-container.enum';
import { BestGantt } from '../../main';
import { PatternType } from '../../pattern/pattern-type.enum';
import { PatternHandler } from '../../pattern/patternHandler';
import { ShiftBuilder } from '../../shifts/shift-builder';
import { GanttBasicShiftBuilderFacade } from '../../shifts/shiftbuilder-facade.base';
import { GanttXAxis } from '../../x-axis/x-axis';
import { GanttShiftDragVisualizer } from '../shift-drag-visualizer/shift-drag-visualizer';
import { MarkedTimePeriod, TimePeriodNoRenderReason } from './executer-timeperiod-marker';
import { GanttTimePeriodShiftBuildingDefault } from './period-building-strategies/period-building-default';
import { GanttTimePeriodStrokeHandler } from './stroke-handler';

/**
 * Interface between time period executer and period builder functions.
 * @keywords plugin, create, build, time, period, timespan, marker, block, interval
 * @plugin timeperiod-marker
 */
export class GanttTimePeriodMarker extends GanttBasicShiftBuilderFacade {
  private _parentNode: { [id: string]: d3.Selection<SVGSVGElement, undefined, d3.BaseType, undefined> } = {};
  private _canvas: { [id: string]: d3.Selection<SVGGElement, undefined, d3.BaseType, undefined> } = {};

  private _config: GanttConfig = undefined;
  private _strokeHandler: GanttTimePeriodStrokeHandler = undefined;
  private _dragEndPreviewer: GanttShiftDragVisualizer = undefined;

  private _markedAreas: GanttCanvasShift[] = [];
  private _hiddenAreas = new Map<string, TimePeriodNoRenderReason[]>(); // save all timeperiod-ids which are inside collapsed gantt rows

  private _mouseOverActive = '';
  private _isRendered = true;

  private _callBack = {
    mouseOver: {},
    mouseOut: {},
  };

  private _onDestroySubject: Subject<void> = new Subject<void>();

  /**
   * @param _ganttDiagram
   * @param _patternHandler
   */
  constructor(private _ganttDiagram: BestGantt, private _patternHandler: PatternHandler = undefined) {
    super();

    this._initParentNode();

    // this config is independent from the global gantt config (and only for styling purposes for time periods)
    this._config = new GanttConfig();
    this._config.setLineTop(0);
  }

  private _initParentNode(): void {
    for (const scrollContainerId of Object.values(EGanttScrollContainer)) {
      this._parentNode[scrollContainerId] = this._ganttDiagram
        .getShiftFacade()
        .getCanvasBehindShifts(scrollContainerId);
    }
  }

  /**
   * Initializes GanttTimePeriodMarker.
   * @param xAxisBuilder XAxisBuilder of gantt.
   */
  public init(xAxisBuilder: GanttXAxis): void {
    // init stroke handler
    this._strokeHandler = new GanttTimePeriodStrokeHandler(xAxisBuilder);

    // init shift builder
    for (const id in this._parentNode) {
      const scrollContainerId = id as EGanttScrollContainer;

      this._buildCanvas(scrollContainerId);

      const shiftBuilder = new ShiftBuilder(
        null,
        null,
        null,
        null,
        this._ganttDiagram,
        this._ganttDiagram.getTextOverlay(scrollContainerId),
        undefined,
        this._patternHandler ||
          this._ganttDiagram.getShiftFacade().getShiftBuilder(scrollContainerId).getPatternHandler(),
        this._config,
        false
      );
      this._addShiftBuilder(scrollContainerId, shiftBuilder);

      this.getShiftBuilder(scrollContainerId).changeShiftBuildingStrategy(new GanttTimePeriodShiftBuildingDefault());

      this.getShiftBuilder(scrollContainerId)
        .afterShiftRender()
        .pipe(takeUntil(this.onDestroy))
        .subscribe((event) => this._strokeHandler.buildStrokesByEvent(event));

      // int zoom
      this.getShiftBuilder(scrollContainerId).scaleHorizontal({
        transform: this._ganttDiagram.getXAxisBuilder().getLastZoomEvent(),
      });
    }

    // init period drag visualizer
    const shiftBuilderMap: { [id: string]: ShiftBuilder } = {};
    for (const scrollContainerId of this._ganttDiagram.getRenderDataHandler().getAllScrollContainerIds()) {
      shiftBuilderMap[scrollContainerId] = this.getShiftBuilder(scrollContainerId);
    }

    this._dragEndPreviewer = new GanttShiftDragVisualizer(shiftBuilderMap, this._config);
    this._dragEndPreviewer.initPlugIn(this._ganttDiagram);
    this._dragEndPreviewer.setUsedByBlockingInterval(true);
  }

  public destroy(): void {
    super.destroy();

    this._dragEndPreviewer.removePlugIn();
    this.removeAll();

    this._onDestroySubject.next();
    this._onDestroySubject.complete();
  }

  /**
   * Updates height of all time periods.
   * @param {number} newHeight New height of time periods.
   */
  public updatePlugInHeight(dataset: Map<string, MarkedTimePeriod>): void {
    for (const scrollContainerId in this._canvas) {
      this._canvas[scrollContainerId].selectAll('rect').remove();
    }
    this._markedAreas.forEach((element) => {
      element.height = dataset.get(element.id).height;
    });
    this.buildMarkers();
  }

  /**
   * Adds time period by data and build it.
   * @param {string} id Id of time period.
   * @param {number} x Horizontal position.
   * @param {number} y Vertical position.
   * @param {number} width Width of period (without zoom).
   * @param {number} height Height of period.
   * @param {string} color Color of period.
   * @param {GanttTimePeriodStrokeHandlerDataItem} [stroke] Stroke data of time period.
   * @param {string} [tooltip=""] Tooltip for mouseover.
   * @param {string} [name="Blocked"] Name that is rendered on blocking Interval.
   * @param {string} [pattern=null] Name of pattern to apply with the color attribute.
   * @param {string} [patternColor="#333333"] Hex-Color String for pattern Color.
   */
  addMarker(
    id,
    x,
    y,
    width,
    height,
    color,
    stroke,
    tooltip = '',
    name = 'Blocked',
    pattern: PatternType = null,
    patternColor = '#333333',
    sort = true
  ) {
    this.addMarkerData(id, x, y, width, height, color, stroke, tooltip, name, pattern, patternColor, sort);
  }

  /**
   * Builds all time periods which are not inside a collapsed row.
   */
  public buildMarkers(isOverlappingVisualization = false): void {
    if (!this.isRendered()) return;

    for (const scrollContainerId in this._canvas) {
      const renderDataSet = this._getMarkersInViewPort(this._markedAreas, scrollContainerId as EGanttScrollContainer);
      this.getShiftBuilder(scrollContainerId as EGanttScrollContainer).renderShifts(
        renderDataSet,
        this._canvas[scrollContainerId],
        null
      );
      this._canvas[scrollContainerId].selectAll<SVGRectElement, GanttCanvasShift>('rect').each(function () {
        this.classList.add('gantt-area-marker');
        if (isOverlappingVisualization) {
          this.style.pointerEvents = 'none';
        }
      });
    }
  }

  /**
   * Filters list of blocking intervals by viewport
   * @param dataset
   * @param scrollContainerId
   * @returns
   */
  private _getMarkersInViewPort(
    dataset: GanttCanvasShift[],
    scrollContainerId: EGanttScrollContainer
  ): GanttCanvasShift[] {
    const scale = this._ganttDiagram.xAxisBuilder.getGlobalScale();
    const domain = this._ganttDiagram.xAxisBuilder.getCurrentScale().domain();
    const viewportLeftX = scale(domain[0]);
    const viewportRightX = scale(domain[1]);
    const viewportTopY = this._ganttDiagram.getNodeProportionsState().getScrollTopPosition(scrollContainerId);
    const viewportBottomY =
      this._ganttDiagram.getNodeProportionsState().getShiftViewPortProportions(scrollContainerId).height + viewportTopY;

    return dataset.filter((markerItem) => {
      const rowId = this._ganttDiagram.getDataHandler().getStateStorage().getCanvasRowIdByYPosition(markerItem.y);
      if (
        !this._ganttDiagram.getRenderDataHandler().getStateStorage().isRowInScrollContainer(rowId, scrollContainerId)
      ) {
        return false;
      }
      const markerItemY = this._ganttDiagram.getRenderDataHandler().getStateStorage().getYPositionRow(rowId);

      const itemEndPoint = markerItem.x + markerItem.width;
      const itemBottomPoint = markerItemY + markerItem.height;
      return (
        !this.isHidden(markerItem.id) && // filter viewport (only x direction)
        ((markerItem.x >= viewportLeftX && markerItem.x <= viewportRightX) || // left side inside
          (itemEndPoint >= viewportLeftX && itemEndPoint <= viewportRightX) || // right side inside
          (markerItem.x <= viewportLeftX && itemEndPoint >= viewportRightX)) && // item start and end outside // filter viewport (only y direction)
        ((markerItemY >= viewportTopY && markerItemY <= viewportBottomY) || // top side inside
          (itemBottomPoint >= viewportTopY && itemBottomPoint <= viewportBottomY) || // bottom side inside
          (markerItemY <= viewportTopY && itemBottomPoint >= viewportBottomY)) // item top and bottom outside
      );
    });
  }

  /**
   * Adds time period canvas to gantt.
   * @param scrollContainerId
   */
  private _buildCanvas(scrollContainerId: EGanttScrollContainer): void {
    if (this._parentNode[scrollContainerId].select('.gantt_vertical-line-group').empty()) {
      // add canvas at first position
      this._canvas[scrollContainerId] = this._parentNode[scrollContainerId].insert('g', ':first-child');
    } else {
      this._canvas[scrollContainerId] = this._parentNode[scrollContainerId].insert('g', '.gantt_vertical-line-group');
    }

    this._canvas[scrollContainerId].attr('class', 'gantt-area-marker-group');
  }

  /**
   * Removes all time period data and DOM elements.
   * @param {boolean} [keepData=false] Specifies whether to keep the time period data or not.
   */
  public removeAll(keepData = false): void {
    for (const scrollContainerId in this._canvas) {
      this._canvas[scrollContainerId].selectAll('rect').remove();
    }

    if (!keepData) this._markedAreas.length = 0;
  }

  /**
   * Zooms time periods canvas and description elements.
   * @param zoomK k - zoom transformation value (horizontal scaling).
   * @param zoomX x - zoom transformation value (horizontal translation).
   */
  public zoomCanvas(zoomK: number, zoomX: number): void {
    for (const scrollContainerId in this._canvas) {
      this._canvas[scrollContainerId].attr('transform', 'translate(' + zoomX + ',1) scale(' + zoomK + ',1)');
    }
  }

  /**
   * Creates new marker data item, description and stroke item and stores them.
   * @param {string} id Id of time period.
   * @param {number} x Horizontal position.
   * @param {number} y Vertical position.
   * @param {number} width Width of period (without zoom).
   * @param {number} height Height of period.
   * @param {string} color Color of period.
   * @param {GanttTimePeriodStrokeHandlerDataItem} [stroke] Stroke data of time period.
   * @param {string} [tooltip=""] Tooltip for mouseover.
   * @param {string} [name="Blocked"] Name that is rendered on blocking Interval.
   * @param {string} [pattern=null] Name of pattern to apply with the color attribute.
   * @param {string} [patternColor="#333333"] Hex-Color String for pattern Color.
   */
  private addMarkerData(
    id,
    x,
    y,
    width,
    height,
    color,
    stroke,
    tooltip = '',
    name = 'Blocked',
    pattern: PatternType = null,
    patternColor = '#333333',
    sort = true
  ) {
    const newMarkedArea: GanttCanvasShift = {
      id: id,
      x: x,
      y: null,
      width: width,
      height: height,
      layer: null,
      color: ToHexColorConverter.convertColorToHex(color),
      firstColor: null,
      secondColor: null,
      highlighted: null,
      selected: null,
      symbols: null,
      noRender: null,
      strokeColor: null,
      strokePattern: null,
      entryTypes: null,
      blockTypes: null,
      opacity: null,
      weaken: null,
      disableMove: null,
      editable: null,
      isFullHeight: null,
      noRoundedCorners: null,
      tooltip: tooltip,
      name: name,
      pattern: pattern,
      patternColor: ToHexColorConverter.convertColorToHex(patternColor),
      renderPriority: 1,
    };

    this._markedAreas.push(newMarkedArea);
    if (sort) {
      this.sortMarkedAreas();
    }

    if (!y && y != 0) {
      this.addToHiddenAreas(id, TimePeriodNoRenderReason.HIDDEN_COORDINATES);
    } else {
      newMarkedArea.y = y;
    }

    if (stroke) {
      this._strokeHandler.addStrokeDataItem(id, stroke);
    }
  }

  sortMarkedAreas() {
    this._markedAreas = this._markedAreas.sort((a, b) => a.y - b.y);
    this._markedAreas = this._markedAreas.sort((a, b) => a.x - b.x);
  }

  /**
   * Adds a no-render ID to the hidden areas for a given ID. If the ID is not yet in the hidden areas,
   * it will be added with the given no-render ID.
   * @param id - The ID of the hidden area to add the no-render ID to.
   * @param noRenderId - The no-render ID to add to the hidden area.
   */
  addToHiddenAreas(id: string, noRenderReason: TimePeriodNoRenderReason) {
    const reasons = this._hiddenAreas.get(id);
    if (reasons) {
      if (reasons.includes(noRenderReason)) return; // already in hidden areas
      reasons.push(noRenderReason);
    } else {
      this._hiddenAreas.set(id, [noRenderReason]);
    }
  }

  /**
   * Removes a noRenderId from the hidden areas of a given id.
   * @param id - The id of the hidden area to remove the noRenderId from.
   * @param noRenderId - The noRenderId to remove from the hidden area.
   */
  removeFromHiddenAreas(id: string, noRenderReason: TimePeriodNoRenderReason) {
    const reasons = this._hiddenAreas.get(id);
    if (!reasons) return;
    if (!reasons.includes(noRenderReason)) return; // not in hidden areas
    reasons.splice(reasons.indexOf(noRenderReason), 1);
    if (reasons.length === 0) this._hiddenAreas.delete(id);
  }

  /**
   * Determines whether the specified time period marker is hidden.
   * @param id - The ID of the time period marker to check.
   * @returns True if the time period marker is hidden, false otherwise.
   */
  isHidden(id: string) {
    return !!this._hiddenAreas.get(id);
  }

  //
  // GETTER & SETTER
  //

  /**
   * @return {GanttConfig} Config for this period marker.
   */
  getConfig() {
    return this._config;
  }

  /**
   * @return D3 selection of group where all timeperiods will be rendered into.
   */
  public getCanvas(
    scrollContainerId = EGanttScrollContainer.DEFAULT
  ): d3.Selection<SVGGElement, undefined, d3.BaseType, undefined> {
    return this._canvas[scrollContainerId];
  }

  public getCanvasMap(): { [id: string]: d3.Selection<SVGGElement, undefined, d3.BaseType, undefined> } {
    return this._canvas;
  }

  /**
   * @return {GanttCanvasShift[]} All canvas data of time periods.
   */
  getMarkedAreas() {
    return this._markedAreas;
  }

  /**
   * Returns a map of marked areas.
   * The keys of the map are the IDs of the marked areas,
   * and the values are the corresponding GanttCanvasShift objects.
   * @returns {Map<string, GanttCanvasShift>} The map of marked areas.
   */
  getMarkedAreasMap() {
    const map = new Map<string, GanttCanvasShift>();
    this._markedAreas.forEach((area) => {
      map.set(area.id, area);
    });
    return map;
  }

  /**
   * @return {GanttTimePeriodStrokeHandler} Stroke handler.
   */
  public getStrokeHandler(): GanttTimePeriodStrokeHandler {
    return this._strokeHandler;
  }

  /**
   * @param {string} id Id of searched marker data.
   * @return {GanttCanvasShift} Canvas data of given time period.
   */
  getMarkerDataById(id) {
    return this._markedAreas.find(function (item) {
      return item.id == id;
    });
  }

  /**
   * @return Canvas data of given time period.
   */
  public getVisibleMarkerData(): GanttCanvasShift[] {
    return this._markedAreas.filter((markerItem) => {
      return !this.isHidden(markerItem.id); // if timeperiod is visible
    });
  }

  public setMouseOverActive(mouseOverActive: string): void {
    this._mouseOverActive = mouseOverActive;
  }

  /**
   * Returns the current render state.
   * @returns {boolean} Decides if plugin will be rendered or not.
   */
  public isRendered(): boolean {
    return this._isRendered;
  }

  /**
   * Sets the render state.
   * @param {boolean} render New render state.
   */
  public setRender(render: boolean): void {
    if (this._isRendered === render) return;

    this._isRendered = render;
    if (this._isRendered) {
      this.buildMarkers();
    } else {
      this.removeAll(true);
    }
    this._ganttDiagram.rerenderShiftTextOverlay();
  }

  /**
   * Callback gets executed on shiftArea if no shift was hit.
   * Checks if a timeperiod is moused over and executes corresponding mouse Over Callback.
   * TODO: Possibly in the future necessary to debounce the detection for large timePeriod dataSets.
   * @param event Native Mouse Event.
   * @return true if a timeperiod had been hit, else false
   */
  public possibleMouseOverCallback(event: GanttScrollContainerEvent<MouseEvent>): GanttCanvasShift[] {
    const hitTimePeriods: GanttCanvasShift[] = [];

    if (!event || !event.event) {
      return hitTimePeriods;
    }
    const x = event.event.offsetX;
    const y = event.event.offsetY;

    if (!x || !y) {
      return hitTimePeriods;
    }

    let visibleTimePeriods = this._markedAreas.filter((markerItem) => !this.isHidden(markerItem.id));
    visibleTimePeriods = visibleTimePeriods.sort((a, b) => a.x - b.x);

    for (let i = visibleTimePeriods.length - 1; i >= 0; i--) {
      // backwards so that overlapping timePeriods handled right.
      const timePeriod = visibleTimePeriods[i];
      const result = this._hitTimePeriod(timePeriod, x, y, event.source);
      if (result) {
        hitTimePeriods.push(result);
      }
    }

    return hitTimePeriods;
  }

  public mouseOver(timePeriod: GanttCanvasShift, mouseEvent: GanttScrollContainerEvent<MouseEvent>): void {
    if (this._mouseOverActive && this._mouseOverActive !== timePeriod.id) {
      this.mouseOut();
    }
    if (!this._mouseOverActive) {
      // no mouseOver active, but hit, so build it
      this._mouseOverActive = timePeriod.id;
      GanttCallBackStackExecuter.execute(
        this._callBack.mouseOver,
        new GanttTimePeriodMouseOverEvent(timePeriod.id, mouseEvent.event.x, mouseEvent.event.y)
      );
    } else {
      // hit and active do nothing.
      return;
    }
  }

  mouseOut() {
    if (this._mouseOverActive) {
      this._mouseOverActive = '';
      GanttCallBackStackExecuter.execute(this._callBack.mouseOut);
    }
  }

  /**
   * Detects wether or not the timePeriod is hit by a mouseEvent
   * @param {GanttCanvasShift} timePeriod The timePeriod to get checked.
   * @param {number} x Mouse x coordinate
   * @param {number} y Mouse y coordinate
   * @return {boolean|TimePeriod} true if TimePeriod is hit, else false.
   */
  private _hitTimePeriod(
    timePeriod: GanttCanvasShift,
    x: number,
    y: number,
    scrollContainerId: EGanttScrollContainer
  ): GanttCanvasShift | false {
    if (!x || !y) return false;

    const rowId = this._ganttDiagram.getDataHandler().getStateStorage().getCanvasRowIdByYPosition(timePeriod.y);
    if (!this._ganttDiagram.getRenderDataHandler().getStateStorage().isRowInScrollContainer(rowId, scrollContainerId)) {
      return false;
    }
    const timePeriodY = this._ganttDiagram.getRenderDataHandler().getStateStorage().getYPositionRow(rowId);

    const zoomTransform = this.getShiftBuilder(scrollContainerId).getLastZoomTransformation();
    if (y <= timePeriodY || y >= timePeriodY + timePeriod.height) {
      return false;
    }
    const scaledX = x;
    if (
      scaledX <= timePeriod.x * zoomTransform.k + zoomTransform.x ||
      scaledX >= (timePeriod.x + timePeriod.width) * zoomTransform.k + zoomTransform.x
    ) {
      return false;
    }

    return timePeriod;
  }

  public setMarkedAreas(markedAreas: GanttCanvasShift[]): void {
    this._markedAreas = markedAreas;
  }

  //
  // OBSERVABLES
  //

  /**
   * Observable which gets triggered when the instance gets destroyed.
   */
  private get onDestroy(): Observable<void> {
    return this._onDestroySubject.asObservable();
  }

  //
  // CALLBACKS
  //

  addMouseOverCallback(id, func) {
    this._callBack.mouseOver[id] = func;
  }

  removeMouseOverCallback(id) {
    delete this._callBack.mouseOver[id];
  }

  addMouseOutCallback(id, func) {
    this._callBack.mouseOut[id] = func;
  }

  removeMouseOutCallback(id) {
    delete this._callBack.mouseOut[id];
  }
}

/**
 * Custom timePeriod Mouse Over Event.
 * Distributed in the timePeriodMouseOverCallback {@link GanttTimePeriodMarker#possibleMouseOverCallback}
 * @property {String} timePeriodID ID of the TimePeriod.
 * @property {number} x xPosition of mouse.
 * @property {number} y yPosition of mouse.
 */
export class GanttTimePeriodMouseOverEvent {
  constructor(public timePeriodID = '', public x = 0, public y = 0) {}
}
