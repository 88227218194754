import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FileuploaderModule } from '@app-modeleditor/components/fileuploader-old/fileuploader.module';
import { SaxmsLightboxModule } from '@app-modules/lightbox-module/lightbox.module';
import { GanttGeneralModule } from 'frontend/src/dashboard/gantt/general/gantt.general.module';
import { TimeDurationPickerModule } from '../shared/duration-picker/time-duration-picker-module';
import { MySharedModule } from '../shared/shared.module';
import { ToolbarModule } from '../view/navbar/toolbar/toolbar.module';
import { ModelEditorComponent } from './modelEditor.component';
import { UiCreateDialog } from './ui/dialogs/create-dialog/ui.create.dialog';
import { LightboxTableViewDialog } from './ui/lightbox-table-view/lightbox-table-view.component';
import { TableViewModule } from './ui/table-view/table-view.module';

@NgModule({
  imports: [
    TableViewModule,
    TimeDurationPickerModule,
    // GridModule,
    MySharedModule,
    SaxmsLightboxModule,
    ToolbarModule,
    CommonModule,
    GanttGeneralModule,
    FileuploaderModule,
  ],
  declarations: [ModelEditorComponent, LightboxTableViewDialog, UiCreateDialog],
  exports: [LightboxTableViewDialog, ModelEditorComponent],
  providers: [],
})
export class ModelEditorModule {}
