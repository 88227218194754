import * as d3 from 'd3';
import { GanttConfig } from '../../config/gantt-config';
import { EScrollbarArrowDirection } from './scrollbar-arrow-direction.enum';

/**
 * Helper class which is able to build vertical scrollbars for scroll containers.
 */
export class VerticalScrollbarBuilder {
  /**
   * @param _config {@link GanttConfig} to use for building vertical scrollbars.
   */
  constructor(private _config: GanttConfig) {}

  /**
   * Adds style properties for the scrollbar of the specified scroll container.
   * @param scrollContainer D3 selection of the scroll container to add the style properties to.
   */
  public buildVerticalScrollbar(
    scrollContainer: d3.Selection<HTMLDivElement, undefined, d3.BaseType, undefined>
  ): void {
    this._buildVerticalScrollbarStatic(scrollContainer);
  }

  /**
   * Adds style properties for the scrollbar of the specified scroll container.
   * Builds a static scrollbar with fixed width.
   * @param scrollContainer D3 selection of the scroll container to add the style properties to.
   */
  private _buildVerticalScrollbarStatic(
    scrollContainer: d3.Selection<HTMLDivElement, undefined, d3.BaseType, undefined>
  ): void {
    const scrollbarStyle = 'static';
    const scrollbarStyleElementClass = `gantt_scroll_wrapper_scrollbar_style_${scrollbarStyle}`;
    const scrollContainerClass = `scrollbar-${scrollbarStyle}`;

    const head = d3.select(document.head);
    head.selectAll(`.${scrollbarStyleElementClass}`).remove();

    // add CSS class for static scrollbars to scroll container
    if (!scrollContainer.attr('class').includes(scrollContainerClass)) {
      scrollContainer.node().classList.add(scrollContainerClass);
    }

    // no scrollbar if no width is set
    if (this._config.verticalScrollbarWidth <= 0) {
      // standard scrollbar styles
      scrollContainer.style('scrollbar-width', 'none');
      // webkit scrollbar styles (not all browsers support standard)
      head.append('style').attr('class', scrollbarStyleElementClass).html(`
          .gantt_scroll_wrapper.${scrollContainerClass}::-webkit-scrollbar {
            display: none;
          }
        `);

      return;
    }

    // standard scrollbar styles
    scrollContainer.style('scrollbar-width', `${this._config.verticalScrollbarWidth}px`);
    // webkit scrollbar styles (not all browsers support standard)
    // variable styles for vertical scrollbar -> constant styles defined in shifts.css
    const scrollbarButtonWidth = this._config.verticalScrollbarWidth - this._config.verticalScrollbarThumbPadding * 2;
    const scrollbarStyleTag = head.append('style').attr('class', scrollbarStyleElementClass).html(`
        .gantt_scroll_wrapper.${scrollContainerClass}::-webkit-scrollbar {
          width: ${this._config.verticalScrollbarWidth}px;
          background-color: ${this._config.verticalScrollbarTrackColor};
        }
        .gantt_scroll_wrapper.${scrollContainerClass}::-webkit-scrollbar-track {
          border: none;
          background: ${this._config.verticalScrollbarTrackColor};
        }
        .gantt_scroll_wrapper.${scrollContainerClass}::-webkit-scrollbar-thumb {
          border: ${this._config.verticalScrollbarThumbPadding}px solid transparent;
          background: ${this._config.verticalScrollbarThumbColor.normal};
          background-clip: content-box;
          border-radius: 0;
        }
        .gantt_scroll_wrapper.${scrollContainerClass}::-webkit-scrollbar-thumb:hover {
          border: ${this._config.verticalScrollbarThumbPadding}px solid transparent;
          background: ${this._config.verticalScrollbarThumbColor.hover};
          background-clip: content-box;
        }
        .gantt_scroll_wrapper.${scrollContainerClass}::-webkit-scrollbar-thumb:active {
          border: ${this._config.verticalScrollbarThumbPadding}px solid transparent;
          background: ${this._config.verticalScrollbarThumbColor.active};
          background-clip: content-box;
        }
      `);
    if (this._config.verticalScrollbarShowArrowButtons) {
      scrollbarStyleTag.html(
        scrollbarStyleTag.html() +
          `
        .gantt_scroll_wrapper.${scrollContainerClass}::-webkit-scrollbar-button:single-button:vertical {
          background-size: ${scrollbarButtonWidth}px ${scrollbarButtonWidth / 2}px;
          background-repeat: no-repeat;
          background-position: center;
        }
        .gantt_scroll_wrapper.${scrollContainerClass}::-webkit-scrollbar-button:single-button:vertical:decrement {
          background-image: url(${this._generateScrollbarArrow(
            EScrollbarArrowDirection.UP,
            this._config.verticalScrollbarArrowColor.normal
          )});
        }
        .gantt_scroll_wrapper.${scrollContainerClass}::-webkit-scrollbar-button:single-button:vertical:increment {
          background-image: url(${this._generateScrollbarArrow(
            EScrollbarArrowDirection.DOWN,
            this._config.verticalScrollbarArrowColor.normal
          )});
        }
        .gantt_scroll_wrapper.${scrollContainerClass}::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
          background-color: ${this._config.verticalScrollbarThumbColor.normal};
          background-image: url(${this._generateScrollbarArrow(
            EScrollbarArrowDirection.UP,
            this._config.verticalScrollbarArrowColor.hover
          )});
        }
        .gantt_scroll_wrapper.${scrollContainerClass}::-webkit-scrollbar-button:single-button:vertical:increment:hover {
          background-color: ${this._config.verticalScrollbarThumbColor.normal};
          background-image: url(${this._generateScrollbarArrow(
            EScrollbarArrowDirection.DOWN,
            this._config.verticalScrollbarArrowColor.hover
          )});
        }
        .gantt_scroll_wrapper.${scrollContainerClass}::-webkit-scrollbar-button:single-button:vertical:decrement:active {
          background-color: ${this._config.verticalScrollbarThumbColor.active};
          background-image: url(${this._generateScrollbarArrow(
            EScrollbarArrowDirection.UP,
            this._config.verticalScrollbarArrowColor.active
          )});
        }
        .gantt_scroll_wrapper.${scrollContainerClass}::-webkit-scrollbar-button:single-button:vertical:increment:active {
          background-color: ${this._config.verticalScrollbarThumbColor.active};
          background-image: url(${this._generateScrollbarArrow(
            EScrollbarArrowDirection.DOWN,
            this._config.verticalScrollbarArrowColor.active
          )});
        }
      `
      );
    }
  }

  /**
   * Generates a scrollbar arrow facing in the specified direction as SVG image.
   * @param direction Direction the arrow should face in.
   * @param color Color the arrow should have.
   * @returns SVG image of the generated scrollbar arrow (as string).
   */
  private _generateScrollbarArrow(direction: EScrollbarArrowDirection, color: string): string {
    let points: string, width: number, height: number;
    switch (direction) {
      case EScrollbarArrowDirection.UP:
        width = 100;
        height = 50;
        points = `'0,${height} ${width / 2},0 ${width},${height}'`;
        break;
      case EScrollbarArrowDirection.DOWN:
        width = 100;
        height = 50;
        points = `'0,0 ${width / 2},${height} ${width},0'`;
        break;
      case EScrollbarArrowDirection.LEFT:
        width = 50;
        height = 100;
        points = `'${width},0 0,${height / 2} ${width},${height}'`;
        break;
      case EScrollbarArrowDirection.RIGHT:
        width = 50;
        height = 100;
        points = `'0,0 ${width},${height / 2} 0,${height}'`;
        break;
    }
    const scrollbarArrow = `"data:image/svg+xml;utf8,<?xml version='1.0' encoding='UTF-8'?><svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='100' height='50' viewBox='0 0 ${width} ${height}' fill='${color}'><polygon points=${points} /></svg>"`;
    return scrollbarArrow;
  }
}
