import { Observable, Subject, distinctUntilChanged } from 'rxjs';
import { GanttCallBackStackExecuter } from '../callback-tools/callback-stack-executer';
import { ToHexColorConverter } from '../color/color-converter/to-hex-converter';
import { EGanttShiftsMultiDragStrategy } from '../edit-shifts/shift-translation/multi-drag/shifts-multi-drag-strategy.enum';
import { EGanttScrollContainer } from '../html-structure/scroll-container.enum';
import { GanttXAxisFormat1 } from '../x-axis/axis-formats/x-axis-format-1';
import { GanttXAxisFormat2 } from '../x-axis/axis-formats/x-axis-format-2';
import { GanttXAxisFormat3 } from '../x-axis/axis-formats/x-axis-format-3';
import { GanttMilestoneProportions, GanttScrollbarSettings, IGanttYAxisConfig } from './config-data/gantt-config.data';
import { EGanttStickyRowStrategy, GanttRowConfig } from './config-data/row-config.data';
import { EGanttShiftColorMode, GanttShiftConfig } from './config-data/shift-config.data';
import { GanttShiftEditConfig } from './config-data/shift-edit-config.data';
import { TextOverlayConfig, TextOverlayShadowMode } from './config-data/text-overlay-config.data';
import { EGanttXAxisPosition, EGanttXAxisTextAnchor, GanttXAxisConfig } from './config-data/x-axis-config.data';

/**
 * Config data with different settings for gantt. <br>
 * Callback stacks to execute functions if settings change.
 * @keywords config, height, proportions, timeformat, time, fontsize, rules, settings
 */
export class GanttConfig {
  private readonly _xAxisSettings = new GanttXAxisConfig();
  private readonly _rowSettings = new GanttRowConfig();
  private readonly _shiftSettings = new GanttShiftConfig();
  milestone: GanttMilestoneProportions;
  fastRenderingOnScrollAndZoom: boolean;
  maxZoomInMs: number;
  localTimeFormat: any;
  language: string;
  xAxisMarkerFormat: string;
  private _yAxis: IGanttYAxisConfig;
  private _scrollContainer: {
    scrollbar: GanttScrollbarSettings;
  };
  private readonly _shiftEditSettings = new GanttShiftEditConfig();
  textOverflow: any;
  selectColor: string;
  highlightColor: string;
  selectOpacity: number;
  tooltipOpacity: number;
  tooltip: any;
  deHighlightShiftsHover: boolean;
  globalShift: any;
  roundedCorners: any;
  lines: any;
  pattern: any;
  shiftFontSize: number;
  shiftTextColor: string;
  textOverlayHeight: number;
  textOverlay: TextOverlayConfig;
  private _shiftDragOpacity: number;
  shiftStroke: any;
  css: any;
  ganttPluginSettings: any;
  private _calendar: {
    showHolidays: boolean;
    selectedFilterFederalState: string;
    showTimeIntervals: boolean;
  };
  callBack: any;

  private _onShiftHeightChangedSubject = new Subject<number>();
  private _onRowFontSizeChangedSubject = new Subject<number>();
  private _onXAxisShowWeekdaysChangedSubject = new Subject<boolean>();
  private _onXAxisPositionChangedSubject = new Subject<EGanttXAxisPosition>();
  private _onVerticalScrollbarChangedSubject = new Subject<void>();
  private _onShiftEditMultiDragStrategyChangedSubject = new Subject<EGanttShiftsMultiDragStrategy>();

  constructor() {
    this.milestone = new GanttMilestoneProportions(18, 18);

    this.fastRenderingOnScrollAndZoom = false;
    this.maxZoomInMs = 60000;

    this.localTimeFormat = {
      dateTime: '%a %b %e %X %Y',
      date: '%d.%m.%Y',
      time: '%H:%M:%S',
      periods: ['AM', 'PM'],
      days: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
      shortDays: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
      months: [
        'Januar',
        'Februar',
        'März',
        'April',
        'Mai',
        'Juni',
        'Juli',
        'August',
        'September',
        'Oktober',
        'November',
        'Dezember',
      ],
      shortMonths: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
    };
    this.language = 'EN';
    this.xAxisMarkerFormat = '%d.%m. - %H:%M:%S';
    this._yAxis = {
      width: 280,
      scrollEndTimeout: 100,
      indicatorWidth: 6,
      entryColorization: true,
    };
    this._scrollContainer = {
      scrollbar: {
        width: 12,
        thumbPadding: 2,
        thumbColor: 'rgb(192, 192, 192)',
        thumbColorHover: 'rgb(168, 168, 168)',
        thumbColorActive: 'rgb(120, 120, 120)',
        trackColor: 'rgb(241, 241, 241)',
        showArrowButtons: true,
        arrowColor: 'rgb(80, 80, 80)',
        arrowColorHover: 'rgb(80, 80, 80)',
        arrowColorActive: 'rgb(255, 255, 255)',
      },
    };
    this.textOverflow = {
      marginLeft: 2,
      marginRight: 0,
    };
    this.highlightColor = '#f44242';
    this.selectColor = '#5555ff';
    this.selectOpacity = 0.55;
    this.tooltipOpacity = 0.9;
    this.tooltip = {
      delay: 300,
      maxWidth: 1920,
      opacity: 0.8,
      backgroundColor: `rgba(0,0,0,${this.tooltipOpacity})`,
      scrollable: false,
      fontSize: 14,
    };
    this.deHighlightShiftsHover = true;
    this.globalShift = {
      zoomLevel: 1.6,
    };
    this.roundedCorners = {
      radius: 5.5,
    };
    this.lines = {
      horizontal: { isVisible: true, color: '#FFFFFF', thickness: 1, showSplitLines: true },
      vertical: {
        isVisible: true,
        color: '#FFFFFF',
        thickness: 1,
        monthlyColor: '#FFFFFF',
        monthlyThickness: 1,
        dailyColor: '#FFFFFF',
        dailyThickness: 1,
      },
    };
    this.pattern = {
      background: '#000000',
      color: '#616161',
    };

    this.shiftFontSize = 14;
    this.shiftTextColor = '#FFFFFF';
    this.textOverlayHeight = 22;
    this.textOverlay = {
      bgBorderTop: 2,
      bgBorderBottom: 2,
      bgBorderLeft: 2,
      bgBorderRight: 2,
      borderToNextShift: 1,
      text_shadow: {
        offset: 1,
        mode: TextOverlayShadowMode.DROP_SHADOW,
        dark_color: 'black',
        bright_color: 'white',
        blur: 2,
        enable: true,
      },
    };
    this._shiftDragOpacity = 0.85;
    this.shiftStroke = {
      width: 2,
      leaveRightSideOpen: false,
    };

    this.css = {
      xAxis: {},
      yAxis: {
        y_axis: 'gantt_y-axis',
        text: 'gantt_y-Axis-text',
        rows: 'gantt_y-Axis-rows',
        text_item: 'gantt_y-Axis-text_item',
        item: 'gantt_y-axis_item',
      },
      shifts: {
        text_overlay_item: 'gantt-text-overlay-item',
      },
    };

    this.ganttPluginSettings = {
      edgePluginRendering: 'ALWAYS', // see EdgesPluginSettings Enum in Dashboard
    };

    this._shiftDragOpacity = 0.85;
    this._calendar = {
      showHolidays: true,
      selectedFilterFederalState: null,
      showTimeIntervals: true,
    };

    this.callBack = {
      afterShiftFontSizeChanged: {},
      xAxesConfigChanged: {},
      afterXAxesConfigChanged: {},
      afterPluginSettingsUpdate: {},
    };
  }

  //
  // GANTT CSS
  //

  getCSS() {
    return this.css;
  }

  //
  // GANTT X AXIS
  //

  public xAxisTextAnchor(): string {
    return this._xAxisSettings.textAnchor;
  }

  public setXAxisTextAnchor(textAnchor: EGanttXAxisTextAnchor): void {
    this._xAxisSettings.textAnchor = textAnchor;
  }

  xAxisScrollBarHeight() {
    return this._xAxisSettings.scrollBarHeight;
  }

  getXAxisScrollBarArrowWidth() {
    return this._xAxisSettings.scrollBarArrowWidth;
  }

  xAxes() {
    return this._xAxisSettings.axesFormats;
  }

  /**
   * Sets new xAxes Config and notifies observer.
   * @param {GanttXAxisFormatGeneral[]} config
   */
  setXAxesConfig(config) {
    if (config.length === 0) config = [new GanttXAxisFormat1()];
    this._xAxisSettings.axesFormats = config;
    GanttCallBackStackExecuter.execute(this.callBack.xAxesConfigChanged);
    GanttCallBackStackExecuter.execute(this.callBack.afterXAxesConfigChanged);
  }

  getXAxisFormat() {
    return this._xAxisSettings.format;
  }

  setXAxisFormat(format) {
    this._xAxisSettings.format = format;
  }

  getXAxesDefault() {
    return [new GanttXAxisFormat1(), new GanttXAxisFormat2(), new GanttXAxisFormat3()];
  }

  xAxisContainerHeight() {
    return this._xAxisSettings.containerHeight;
  }

  xAxisHeight() {
    return this._xAxisSettings.height;
  }

  xAxisScrollBarY() {
    return this._xAxisSettings.scrollBarY;
  }

  xAxisScrollBarPadding() {
    return this._xAxisSettings.axisScrollBarPadding;
  }

  setXAxisScrollBarHeight(newHeight) {
    this._xAxisSettings.scrollBarHeight = newHeight;
    GanttCallBackStackExecuter.execute(this.callBack.xAxesConfigChanged);
  }

  setXAxisScrollBarPadding(padding) {
    this._xAxisSettings.axisScrollBarPadding = padding;
    GanttCallBackStackExecuter.execute(this.callBack.xAxesConfigChanged);
  }

  isXAxisVisible() {
    return this._xAxisSettings.visible;
  }

  setXAxisVisible(isVisible) {
    this._xAxisSettings.visible = isVisible;
    GanttCallBackStackExecuter.execute(this.callBack.xAxesConfigChanged);
  }

  public xAxisShowWeekdays(): boolean {
    return this._xAxisSettings.showWeekdays;
  }

  public setXAxisShowWeekdays(showWeekdays: boolean): void {
    this._xAxisSettings.showWeekdays = showWeekdays;
    this._onXAxisShowWeekdaysChangedSubject.next(this._xAxisSettings.showWeekdays);
  }

  public xAxisPosition(): EGanttXAxisPosition {
    return this._xAxisSettings.position;
  }

  public setXAxisPosition(xAxisPosition: EGanttXAxisPosition): void {
    this._xAxisSettings.position = xAxisPosition;
    this._onXAxisPositionChangedSubject.next(this._xAxisSettings.position);
  }

  //
  // GANTT ROWS
  //

  public rowHeight(): number {
    return this._rowSettings.rowProportions.height;
  }

  public setRowHeight(height: number): void {
    this._rowSettings.rowProportions.height = height;
    this._onShiftHeightChangedSubject.next(this.shiftHeight());
  }

  public rowFontSize(): number {
    return this._rowSettings.rowProportions.fontSize;
  }

  public setRowFontSize(fontSize: number): void {
    this._rowSettings.rowProportions.fontSize = fontSize;
    this._onRowFontSizeChangedSubject.next(this._rowSettings.rowProportions.fontSize);
  }

  public showStickyRows(): boolean {
    return this._rowSettings.stickyRows.showStickyRows.getValue();
  }

  public setShowStickyRows(bool: boolean): void {
    this._rowSettings.stickyRows.showStickyRows.next(bool);
  }

  public stickyRowStrategy(): EGanttStickyRowStrategy {
    return this._rowSettings.stickyRows.stickyRowStrategy.getValue();
  }

  public setStickyRowStrategy(stickyRowStrategy: EGanttStickyRowStrategy): void {
    this._rowSettings.stickyRows.stickyRowStrategy.next(stickyRowStrategy);
  }

  public stickyRowsContainerMaxHeight(): number {
    return this._rowSettings.stickyRows.containerMaxHeight;
  }

  public stickyRowsContainerMinHeightPx(): number {
    return this._rowSettings.stickyRows.containerLimits.minHeightPx;
  }

  public setStickyRowsContainerMinHeightPx(containerMinHeightPx: number): void {
    this._rowSettings.stickyRows.containerLimits.minHeightPx = containerMinHeightPx;
  }

  public stickyRowsContainerOptimalHeightPx(): number {
    return this._rowSettings.stickyRows.containerLimits.optimalHeightPx;
  }

  public setStickyRowsContainerOptimalHeightPx(containerOptimalHeightPx: number): void {
    this._rowSettings.stickyRows.containerLimits.optimalHeightPx = containerOptimalHeightPx;
  }

  public stickyRowsContainerMaxHeightPx(): number {
    return this._rowSettings.stickyRows.containerLimits.maxHeightPx;
  }

  public setStickyRowsContainerMaxHeightPx(containerMaxHeightPx: number): void {
    this._rowSettings.stickyRows.containerLimits.maxHeightPx = containerMaxHeightPx;
  }

  public stickyRowsAllowUnlimitedContainerHeight(): boolean {
    return this._rowSettings.stickyRows.allowUnlimitedContainerHeight;
  }

  public setStickyRowsAllowUnlimitedContainerHeight(bool: boolean): void {
    this._rowSettings.stickyRows.allowUnlimitedContainerHeight = bool;
  }

  public isDefinedRowBackgroundColorApplied(): boolean {
    return this._rowSettings.isDefinedRowBackgroundColorApplied;
  }

  public setDefinedRowBackgroundColorApplied(bool: boolean): void {
    this._rowSettings.isDefinedRowBackgroundColorApplied = bool;
  }

  public rowBackgroundColor(scrollContainerId = EGanttScrollContainer.DEFAULT): string {
    return this._rowSettings.backgroundColor[scrollContainerId] || this._rowSettings.backgroundColorDefault;
  }

  public setRowBackroundColor(color: string, scrollContainerId = EGanttScrollContainer.DEFAULT): void {
    this._rowSettings.backgroundColor[scrollContainerId] = color;
  }

  public setRowBackgroundColorDefault(color: string): void {
    this._rowSettings.backgroundColorDefault = color;
  }

  //
  // GANTT SHIFTS
  //

  getGlobalShift() {
    return this.globalShift.zoomLevel;
  }

  public shiftHeight(): number {
    return (
      this._rowSettings.rowProportions.height -
      this._shiftSettings.shiftProportions.bottom -
      this._shiftSettings.shiftProportions.top
    );
  }

  shiftDragOpacity() {
    return this._shiftDragOpacity;
  }

  getShiftFontSize() {
    return this.shiftFontSize;
  }
  getShiftTextColor() {
    return this.shiftTextColor;
  }
  setShiftTextColor(color) {
    this.shiftTextColor = color;
  }
  getTextOverlayHeight() {
    return this.textOverlayHeight;
  }

  public getLineBottom(): number {
    return this._shiftSettings.shiftProportions.bottom;
  }

  public setLineBottom(lineBottom: number): void {
    this._shiftSettings.shiftProportions.bottom = lineBottom;
  }

  public getLineTop(): number {
    return this._shiftSettings.shiftProportions.top;
  }

  public setLineTop(lineTop: number): void {
    this._shiftSettings.shiftProportions.bottom = lineTop;
  }

  public getMaxShiftTimespan(): number {
    return this._shiftSettings.shiftProportions.maxTimespan;
  }

  public setMaxShiftTimespan(maxTimespan: number) {
    if (!maxTimespan || maxTimespan < this.getMinShiftTimespan()) maxTimespan = null; // set to default (null = no maximum shift width)
    this._shiftSettings.shiftProportions.maxTimespan = maxTimespan;
  }

  public getMinShiftTimespan(): number {
    return this._shiftSettings.shiftProportions.minTimespan;
  }

  public setMinShiftTimespan(minTimespan: number) {
    if (!minTimespan || minTimespan <= 0) minTimespan = 60000; // set to default (1 min = 60000 ms)
    this._shiftSettings.shiftProportions.minTimespan = minTimespan;
  }

  public getMinShiftRemainderTimespan(): number {
    return this._shiftSettings.shiftProportions.minRemainderTimespan;
  }

  public getMinCanvasShiftWidth(): number {
    return this._shiftSettings.shiftProportions.minRenderWidth;
  }

  public getShiftBuildingRoundedCorners(): boolean {
    return this._shiftSettings.shiftBuilding.roundedCorners;
  }

  public setShiftBuildingRoundedCorners(roundedCorners: boolean): void {
    this._shiftSettings.shiftBuilding.roundedCorners = roundedCorners;
  }

  public getShiftBuildingColorMode(): EGanttShiftColorMode {
    return this._shiftSettings.shiftBuilding.colorMode;
  }

  public setShiftBuildingColorMode(colorMode: EGanttShiftColorMode): void {
    this._shiftSettings.shiftBuilding.colorMode = colorMode;
  }

  public getShiftBuildingShowShiftContent(): boolean {
    return this._shiftSettings.shiftBuilding.showShiftContent;
  }

  public setShiftBuildingShowShiftContent(showShiftContent: boolean): void {
    this._shiftSettings.shiftBuilding.showShiftContent = showShiftContent;
  }

  public getShiftDragStartTolerance(): number {
    return this._shiftSettings.shiftDragging.dragStartTolerance;
  }

  public isShiftPatternStrokesEnabled(): boolean {
    return this._shiftSettings.shiftPatterns.patternStrokes.isStrokesEnabled;
  }

  public getShiftPatternStrokeContrastThreshold(): number {
    return this._shiftSettings.shiftPatterns.patternStrokes.strokeContrastThreshold;
  }

  public getShiftPatternStrokeWidth(): number {
    return this._shiftSettings.shiftPatterns.patternStrokes.strokeWidth;
  }

  public getShiftPatternStrokeColorBright(): string {
    return this._shiftSettings.shiftPatterns.patternStrokes.strokeColorBright;
  }

  public getShiftPatternStrokeColorDark(): string {
    return this._shiftSettings.shiftPatterns.patternStrokes.strokeColorDark;
  }

  //
  // GANTT TEXT OVERLAY
  //

  textOverlayBGBorderTop() {
    return this.textOverlay.bgBorderTop;
  }
  textOverlayBGBorderBottom() {
    return this.textOverlay.bgBorderBottom;
  }
  textOverlayBGBorderLeft() {
    return this.textOverlay.bgBorderLeft;
  }
  textOverlayBGBorderRight() {
    return this.textOverlay.bgBorderRight;
  }
  textOverlayBorderToNextShift() {
    return this.textOverlay.borderToNextShift;
  }
  /**
   * @returns A dark shadow color for the text overlay. (Used for bright background colors)
   */
  textOverlayTextDarkShadowColor() {
    return this.textOverlay.text_shadow.dark_color;
  }
  /**
   * @returns A bright shadow color for the text overlay. (Used for dark background colors)
   */
  textOverlayTextBrightShadowColor() {
    return this.textOverlay.text_shadow.bright_color;
  }
  /**
   * @returns The value of the blur property for the text shadow.
   */
  textOverlayShadowBlur() {
    return this.textOverlay.text_shadow.blur;
  }
  /**
   * Returns the offset value for the text shadow of the text overlay.
   * @returns The offset value for the text shadow.
   */
  textOverlayShadowOffset() {
    return this.textOverlay.text_shadow.offset;
  }
  /**
   * Returns true if the text shadow is enabled.
   * @returns {boolean} The value of the 'enable' property.
   */
  isTextOverlayShadowEnabled() {
    return this.textOverlay.text_shadow.enable;
  }
  /**
   * @returns The display type of the text shadow.
   */
  textOverlayShadowMode() {
    return this.textOverlay.text_shadow.mode;
  }
  /**
   * @param {string} color TextShadow dark color (css coloring options)
   */
  setTextOverlayTextDarkShadowColor(color) {
    this.textOverlay.text_shadow.dark_color = color;
  }
  /**
   * @param {string} color TextShadow bright color (css coloring options)
   */
  setTextOverlayTextBrightShadowColor(color) {
    this.textOverlay.text_shadow.bright_color = color;
  }
  /**
   * @param {number} offset TextShadow Position relative to text in pixel.
   */
  setTextOverlayShadowOffset(offset: number) {
    this.textOverlay.text_shadow.offset = offset;
  }
  /**
   * Sets the display type for the text overlay shadow.
   * @param mode The mode to set for the text overlay shadow.
   */
  setTextOverlayShadowMode(mode: TextOverlayShadowMode) {
    this.textOverlay.text_shadow.mode = mode;
  }
  /**
   * @param {boolean} isEnabled Enable / disable TextOverlay Text Shadows on shifts that have a pattern.
   */
  setTextOverlayShadowEnabled(isEnabled: boolean) {
    this.textOverlay.text_shadow.enable = isEnabled;
  }

  mileStone() {
    return this.milestone;
  }

  colorHighlight() {
    return this.highlightColor;
  }
  colorSelect() {
    return this.selectColor;
  }
  setColorSelect(color) {
    this.selectColor = color;
  }
  colorSelectOpacity() {
    return this.selectOpacity;
  }
  setColorSelectOpacity(opacity) {
    return (this.selectOpacity = opacity);
  }

  xAxisMarkerDateFormat() {
    return this.xAxisMarkerFormat;
  }

  getMaxZoomInMs() {
    return this.maxZoomInMs;
  }

  yAxisWidth() {
    return this._yAxis.width;
  }
  setYAxisWidth(width) {
    this._yAxis.width = width;
  }
  getScrollEndTimeout() {
    return this._yAxis.scrollEndTimeout;
  }
  getRowIndicatorWidth() {
    return this._yAxis.indicatorWidth;
  }
  setRowIndicatorWidth(width) {
    this._yAxis.indicatorWidth = width;
  }
  public get yAxisEntryColorization(): boolean {
    return this._yAxis.entryColorization;
  }
  public set yAxisEntryColorization(value: boolean) {
    this._yAxis.entryColorization = value;
  }

  public get verticalScrollbarWidth(): number {
    return this._scrollContainer.scrollbar.width;
  }
  public set verticalScrollbarWidth(value: number) {
    if (value < 0) value = 0;
    this._scrollContainer.scrollbar.width = value;
    this._onVerticalScrollbarChangedSubject.next();
  }

  public get verticalScrollbarThumbPadding(): number {
    return this._scrollContainer.scrollbar.thumbPadding;
  }
  public set verticalScrollbarThumbPadding(value: number) {
    if (value < 0) value = 0;
    this._scrollContainer.scrollbar.thumbPadding = value;
    this._onVerticalScrollbarChangedSubject.next();
  }

  public get verticalScrollbarThumbColor(): { normal: string; hover: string; active: string } {
    return {
      normal: this._scrollContainer.scrollbar.thumbColor,
      hover: this._scrollContainer.scrollbar.thumbColorHover,
      active: this._scrollContainer.scrollbar.thumbColorActive,
    };
  }
  public set verticalScrollbarThumbColor(value: { normal?: string; hover?: string; active?: string }) {
    if (value.normal) this._scrollContainer.scrollbar.thumbColor = value.normal;
    if (value.hover) this._scrollContainer.scrollbar.thumbColor = value.hover;
    if (value.active) this._scrollContainer.scrollbar.thumbColor = value.active;
    this._onVerticalScrollbarChangedSubject.next();
  }

  public get verticalScrollbarArrowColor(): { normal: string; hover: string; active: string } {
    return {
      normal: this._scrollContainer.scrollbar.arrowColor,
      hover: this._scrollContainer.scrollbar.arrowColorHover,
      active: this._scrollContainer.scrollbar.arrowColorActive,
    };
  }
  public set verticalScrollbarArrowColor(value: { normal?: string; hover?: string; active?: string }) {
    if (value.normal) this._scrollContainer.scrollbar.arrowColor = value.normal;
    if (value.hover) this._scrollContainer.scrollbar.arrowColorHover = value.hover;
    if (value.active) this._scrollContainer.scrollbar.arrowColorActive = value.active;
    this._onVerticalScrollbarChangedSubject.next();
  }

  public get verticalScrollbarTrackColor(): string {
    return this._scrollContainer.scrollbar.trackColor;
  }
  public set verticalScrollbarTrackColor(value: string) {
    this._scrollContainer.scrollbar.trackColor = value;
    this._onVerticalScrollbarChangedSubject.next();
  }

  public get verticalScrollbarShowArrowButtons(): boolean {
    return this._scrollContainer.scrollbar.showArrowButtons;
  }
  public set verticalScrollbarShowArrowButtons(value: boolean) {
    this._scrollContainer.scrollbar.showArrowButtons = value;
    this._onVerticalScrollbarChangedSubject.next();
  }

  areHorizontalLinesVisible() {
    return this.lines.horizontal.isVisible;
  }
  getHorizontalLinesColor() {
    return this.lines.horizontal.color;
  }
  getHorizontalLinesThickness() {
    return this.lines.horizontal.thickness;
  }
  setHorizontalLinesVisible(isVisible) {
    this.lines.horizontal.isVisible = isVisible;
  }
  setHorizontalLinesColor(color) {
    this.lines.horizontal.color = ToHexColorConverter.convertColorToHex(color);
  }
  setHorizontalLinesThickness(thickness) {
    this.lines.horizontal.thickness = thickness;
  }
  isShowHorizontalSplitLines() {
    return this.lines.horizontal.showSplitLines;
  }
  setShowHorizontalSplitLines(showSplitLines) {
    this.lines.horizontal.showSplitLines = showSplitLines;
  }

  areVerticalLinesVisible() {
    return this.lines.vertical.isVisible;
  }
  getVerticalLinesColor() {
    return this.lines.vertical.color;
  }
  getVerticalLinesThickness() {
    return this.lines.vertical.thickness;
  }
  getVerticalLinesMonthlyThickness() {
    return this.lines.vertical.monthlyThickness;
  }
  getVerticalLinesMonthlyColor() {
    return this.lines.vertical.monthlyColor;
  }
  getVerticalLinesDailyThickness() {
    return this.lines.vertical.dailyThickness;
  }
  getVerticalLinesDailyColor() {
    return this.lines.vertical.dailyColor;
  }
  setVerticalLinesVisible(isVisible) {
    this.lines.vertical.isVisible = isVisible;
  }
  setVerticalLinesColor(color) {
    this.lines.vertical.color = ToHexColorConverter.convertColorToHex(color);
  }
  setVerticalLinesThickness(thickness) {
    this.lines.vertical.thickness = thickness;
  }
  setVerticalLinesMonthlyColor(color) {
    this.lines.vertical.monthlyColor = ToHexColorConverter.convertColorToHex(color);
  }
  setVerticalLinesMonthlyThickness(thickness) {
    this.lines.vertical.monthlyThickness = thickness;
  }
  setVerticalLinesDailyColor(color) {
    this.lines.vertical.dailyColor = ToHexColorConverter.convertColorToHex(color);
  }
  setVerticalLinesDailyThickness(thickness) {
    this.lines.vertical.dailyThickness = thickness;
  }

  getLanguage() {
    return this.language;
  }

  getShiftStrokeWidth() {
    return this.shiftStroke.width;
  }
  getShiftStrokeLeaveRightSideOpen() {
    return this.shiftStroke.leaveRightSideOpen;
  }
  setShiftStrokeLeaveRightSideOpen(boolean) {
    this.shiftStroke.leaveRightSideOpen = boolean;
  }

  getPatternColor() {
    return this.pattern.color;
  }
  getPatternBackgroundColor() {
    return this.pattern.background;
  }

  //
  // SHIFT EDITING
  //

  public shiftEditVisualizeNotAllowed(): boolean {
    return this._shiftEditSettings.visualizeNotAllowed;
  }

  public shiftEditVisualizeNotAllowedTolerance(): number {
    return this._shiftEditSettings.visualizeNotAllowedTolerance;
  }

  public shiftEditResizeHandleWidth(): number {
    return this._shiftEditSettings.shiftResize.resizeHandles.width;
  }

  public shiftEditMultiDragStrategy(): EGanttShiftsMultiDragStrategy {
    return this._shiftEditSettings.shiftTranslation.multiDragStrategy;
  }

  public setShiftEditMultiDragStrategy(strategy: EGanttShiftsMultiDragStrategy): void {
    this._shiftEditSettings.shiftTranslation.multiDragStrategy =
      strategy || EGanttShiftsMultiDragStrategy.ONE_LINE_DRAG;
    this._onShiftEditMultiDragStrategyChangedSubject.next(this._shiftEditSettings.shiftTranslation.multiDragStrategy);
  }

  // Miscellaneous
  timeFormat() {
    return this.localTimeFormat;
  }
  textOverflowLeft() {
    return this.textOverflow.marginLeft;
  }
  textOverflowRight() {
    return this.textOverflow.marginRight;
  }
  setTooltipDelay(value) {
    this.tooltip.delay = value;
  }
  tooltipDelay() {
    return this.tooltip.delay;
  }
  tooltipMaxWidth() {
    return this.tooltip.maxWidth;
  }
  getTooltipOpacity() {
    return this.tooltipOpacity;
  }
  isTooltipScrollable() {
    return this.tooltip.scrollable;
  }
  tooltipFontSize() {
    return this.tooltip.fontSize;
  }
  setTooltipOpacity(value) {
    this.tooltipOpacity = value;
    this.tooltip.backgroundColor = `rgba(0,0,0,${value})`;
  }
  setTooltipScrollable(bool: boolean) {
    this.tooltip.scrollable = bool;
  }
  setTooltipFontSize(fontSize: number) {
    this.tooltip.fontSize = fontSize;
  }
  getRoundedCorners() {
    return this.roundedCorners.radius;
  }

  isFastRenderingOnScrollAndZoom() {
    return this.fastRenderingOnScrollAndZoom;
  }
  // Calendar
  public showCalendarHolidays(): boolean {
    return this._calendar.showHolidays;
  }
  public setShowCalendarHolidays(boolean: boolean): void {
    this._calendar.showHolidays = boolean;
  }
  public getCalenderSelectedFilterFederalState(): string {
    return this._calendar.selectedFilterFederalState;
  }
  public setCalenderSelectedFilterFederalState(selectedState: string): void {
    this._calendar.selectedFilterFederalState = selectedState;
  }
  public showCalendarTimeIntervals(): boolean {
    return this._calendar.showTimeIntervals;
  }
  public setShowCalendarTimeIntervals(boolean: boolean): void {
    this._calendar.showTimeIntervals = boolean;
  }

  // SETTER
  setLanguage(language) {
    this.language = language;
  }

  setShiftFontSize(fontSize) {
    this.shiftFontSize = fontSize;
    GanttCallBackStackExecuter.execute(this.callBack.afterShiftFontSizeChanged, this.shiftFontSize);
  }

  setShiftStrokeWidth(strokeWidth) {
    this.shiftStroke.width = strokeWidth;
  }

  //
  // CALLBACK
  //

  addAfterShiftFontSizeChangedCallback(id, func) {
    this.callBack.afterShiftFontSizeChanged[id] = func;
  }
  removeAfterShiftFontSizeChangedCallback(id) {
    delete this.callBack.afterShiftFontSizeChanged[id];
  }

  /**
   * Executes after settings are changed. <br>
   * Register functions that deal with changed values.
   */
  addXAxesConfigChangedCallback(id, func) {
    this.callBack.xAxesConfigChanged[id] = func;
  }
  removeXAxesConfigChangedCallback(id) {
    delete this.callBack.xAxesConfigChanged[id];
  }

  /**
   * Executes after the [xAxesConfigChanged callback]{@link GanttConfig#addXAxesConfigChangedCallback} <br>
   * Register function that run after changes were dealt with. E.g. reRender the xAxis.
   */
  addAfterXAxesConfigChangedCallback(id, func) {
    this.callBack.afterXAxesConfigChanged[id] = func;
  }
  removeAfterXAxesConfigChangedCallback(id) {
    delete this.callBack.afterXAxesConfigChanged[id];
  }

  public setEdgePluginRendering(string: string): void {
    const validValues = ['ALWAYS', 'ONMOUSEOVER', 'ONMOUSEOVERTRANSITIV', 'NONE'];
    if (validValues.includes(string)) {
      this.ganttPluginSettings.edgePluginRendering = string;
      GanttCallBackStackExecuter.execute(this.callBack.afterPluginSettingsUpdate, this.ganttPluginSettings);
    } else {
      console.warn(`provided unknown edgePluginRendering setting ${string}
          defaulting back to previous value`);
    }
  }

  subscribePluginSettingsChanges(id, func) {
    this.callBack.afterPluginSettingsUpdate[id] = func;
  }
  unSubscribePluginSettingsChanges(id) {
    delete this.callBack.afterPluginSettingsUpdate[id];
  }

  public onShiftHeightChanged(): Observable<number> {
    return this._onShiftHeightChangedSubject.asObservable();
  }

  public onRowFontSizeChanged(): Observable<number> {
    return this._onRowFontSizeChangedSubject.asObservable();
  }

  public onShowStickyRowsChanged(): Observable<boolean> {
    return this._rowSettings.stickyRows.showStickyRows.asObservable().pipe(distinctUntilChanged());
  }

  public onStickyRowStrategyChanged(): Observable<EGanttStickyRowStrategy> {
    return this._rowSettings.stickyRows.stickyRowStrategy.asObservable().pipe(distinctUntilChanged());
  }

  public onXAxisShowWeekdaysChanged(): Observable<boolean> {
    return this._onXAxisShowWeekdaysChangedSubject.asObservable();
  }

  public onXAxisPositionChanged(): Observable<EGanttXAxisPosition> {
    return this._onXAxisPositionChangedSubject.asObservable();
  }

  public onVerticalScrollbarSettingsChanged(): Observable<void> {
    return this._onVerticalScrollbarChangedSubject.asObservable();
  }

  public onShiftEditMultiDragStrategyChanged(): Observable<EGanttShiftsMultiDragStrategy> {
    return this._onShiftEditMultiDragStrategyChangedSubject.asObservable();
  }
}
