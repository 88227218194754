import { BaseContentElement } from '../base/base-content-element';
import { DataType } from '../base/base-template';
import { InputType } from '../mapper';
import { EntryElement, mapEntryElement } from './entry-element.mapper';

export interface EntryCollection<T extends DataType> extends BaseContentElement {
  displayOrientation: 'HORIZONTAL';
  editable: boolean;
  entryElements: EntryElement[];
  localActions: [];
  name: string;
  resizeMode: 'FIT_CHILD' | 'FIT_PARENT' | 'OWN_SIZE';
  resourceId: string;
  restUrl: string;
  saveOrder: number;
  type: T;
  updateOnChange: boolean;
  updateTemplateRestUrl: string;
  uuid: string;
  _class: string;
}

export const mapEntryCollection = <T extends DataType>(item: InputType<T>): EntryCollection<T> => {
  const entryElements = item.entryElements?.map((i) => mapEntryElement(i));

  return {
    ...item,
    entryElements,
    type: item.type,
  } as EntryCollection<T>;
};
