import { ColumnListSelectorElement } from '@app-modeleditor/components/column-list-selector/column-list-selector-element';
import { ColumnListSelectorValue } from '@app-modeleditor/components/column-list-selector/column-list-selector-value';
import { Content } from '@app-modeleditor/components/content/content';
import { ContentPart } from '@app-modeleditor/components/content/content-part/content-part';
import { Lightbox } from '@app-modeleditor/components/lightbox/lightbox';
import { Gantt_General } from '../../general.gantt.component';
import { ColumnListSelector } from './../../../../modeleditor/components/column-list-selector/column-list-selector';

export class VisibleAttributeChangerLightbox extends Lightbox {
  private $selector: ColumnListSelector;
  constructor(private scope: Gantt_General) {
    super();
    this.get(null);
  }

  public getColumnListSelector(): ColumnListSelector {
    return this.$selector;
  }

  public checkEntries(listOfSelected: number[]): void {
    const selected: ColumnListSelectorElement[] = listOfSelected
      .map((id) => this.$entries.find((entry) => entry.getId() + '' === id + ''))
      .filter((elem) => !!elem);

    const available: ColumnListSelectorElement[] = this.$entries.filter((entry: ColumnListSelectorElement) => {
      return listOfSelected.find((id: number) => id === parseInt(entry.getId())) ? false : true;
    });

    this.getColumnListSelector().getValue().setAvailableListEntryElements(available);

    this.getColumnListSelector().getValue().setSelectedListEntryElements(selected);
  }

  private get $entries(): ColumnListSelectorElement[] {
    const map: { [key: number]: any } = this.scope.ganttTemplateDataService.getTemplateData().getAttributeMapping();
    return Object.keys(map || {})
      .map((key: string) => {
        const e: ColumnListSelectorElement = new ColumnListSelectorElement()
          .setSimpleElement(true)
          .setFloatLeft(map[key].localization)
          .setId(map[key].id);
        return e;
      })
      .sort((a, b) => a.getFloatLeft().localeCompare(b.getFloatLeft()));
  }

  get(data: any): this {
    this.setName('Sichtbare Attribute konfigurieren');
    this.setCanOpenExternalWindow(false);

    this.$selector = new ColumnListSelector().setName('Attribute konfigurieren');

    this.$selector.setValue(new ColumnListSelectorValue().setAvailableListEntryElements(this.$entries));
    this.setContent(
      new Content().setContentParts([
        new ContentPart().setDisplayContentpartContainer(false).setContentElements([this.$selector]),
      ])
    );
    return this;
  }
}
