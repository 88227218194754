export enum EDockViewMode {
  RIGHT = 'RIGHT',
  BOTTOM = 'BOTTOM',
  UNDOCK = 'UNDOCK',
}

export enum EDockComponent {
  LEGEND = 'LEGEND',
  RESOURCES = 'RESOURCES',
}
