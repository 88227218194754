import { GanttCanvasShift } from '../../data-handler/data-structure/data-structure';
import { PatternType } from '../../pattern/pattern-type.enum';
import { PatternHandler } from '../../pattern/patternHandler';
import { GanttStrokePattern } from '../../pattern/strokes/strokePattern';
import { ShiftBuilder } from '../shift-builder';

export interface IGanttShiftsCalculation {
  executer: ShiftBuilder;

  /**
   * Calculates the width of a shift.
   * @param shiftData Shift canvas data to calculate the width for.
   * @param lastZoomTransformation Data fo the current zoom transformation.
   * @param offset Offset to subtract from shift width (e.g. for shift stroke).
   * @param maxWidth Maximum width of a shift. Useful for SVG when shift width is too high for SVG.
   */
  getShiftWidth(
    shiftData: GanttCanvasShift,
    lastZoomTransformation: d3.ZoomTransform,
    offset?: number,
    maxWidth?: number
  ): number;

  getShiftHeight(shiftData: GanttCanvasShift, offset?: number): number;

  /**
   * Calculates the x position of a shift.
   * @param shiftData Shift canvas data to calculate the x position for.
   * @param lastZoomTransformation Data fo the current zoom transformation.
   * @param offset Offset of rendered x position from original x position (e.g. for shift stroke).
   * @param maxShiftWidth Maximum width of a shift (neccesary for x position correction if a maxmimum width exists).
   */
  getShiftX(
    shiftData: GanttCanvasShift,
    lastZoomTransformation: d3.ZoomTransform,
    offset?: number,
    maxShiftWidth?: number
  ): number;

  /**
   * Calculates the y position of a shift.
   * @param shiftData Shift canvas data to calculate the y position for.
   * @param offset Offset of rendered y position from original y position (e.g. for shift stroke).
   */
  getShiftY(shiftData: GanttCanvasShift, offset?: number): number;

  /**
   * Calculates the y position of a shift in px from the top of the shift viewport.
   * @param shiftData Shift canvas data to calculate the viewport y position for.
   * @param offset Offset of rendered y position from original y position (e.g. for shift stroke).
   */
  getShiftViewportY(shiftData: GanttCanvasShift, offset?: number): number;

  getShiftCornerRadius(shiftData: GanttCanvasShift): number;

  getShiftStrokeColor(shiftData: GanttCanvasShift): string;

  getShiftStrokeWidth(shiftData: GanttCanvasShift): number;

  /**
   * Calculates the dasharray of a shift stroke.
   * @param shiftData Shift canvas data to calculate the dasharray for.
   * @param lastZoomTransformation Data fo the current zoom transformation (neccesary for shift width calculations).
   * @param maxShiftWidth Maximum width of a shift (neccesary for shift width calculations if a maxmimum width exists).
   */
  getShiftStrokeDasharray(
    shiftData: GanttCanvasShift,
    lastZoomTransformation: d3.ZoomTransform,
    maxShiftWidth?: number
  ): number[];

  getShiftFill(shiftData: GanttCanvasShift): IShiftFillData;

  /**
   * Advanced shift position calculation (with more options).
   * @param shiftData Shift canvas data to calculate the rect boundings for.
   * @param lastZoomTransformation Data fo the current zoom transformation.
   * @param offset Shift offset (passed to specific calculation method).
   * @param additionalOffset Additional shift offset (applied directly on returned value).
   * @param clippingWidth Viewport width (to clip invisible shift parts).
   * @param clippingOffset Offset from the viewport borders to clipped areas.
   */
  getShiftRectBoundings(
    shiftData: GanttCanvasShift,
    lastZoomTransformation: d3.ZoomTransform,
    offset?: number,
    additionalOffset?: IRectOffset,
    clippingWidth?: number,
    clippingOffset?: number
  ): IRectBoundings;

  /**
   * Returns all stroke data (color, width, pattern, leaveRightSideOpen).
   * @param shiftData Shift canvas data to get the stroke data for.
   * @param leaveRightSideOpen Specifies whether the right side of the shift should be open or not.
   * @param patternHandler Pattern handler for stroke patterns.
   */
  getStrokeData(shiftData: GanttCanvasShift, leaveRightSideOpen: boolean, patternHandler: PatternHandler): IStrokeData;

  /**
   * Returns the fill color of a shift (in SVG format).
   * @param shiftData Shift canvas data to calculate the fill color for.
   */
  getShiftFillColorForSVG(shiftData: GanttCanvasShift): string;

  /**
   * Returns the fill color of a shift (in HTML Canvas format).
   * @param shiftData Shift canvas data to calculate the fill color for.
   * @param ctx Canvas rendering context (neccesary to render patterns).
   */
  getShiftFillColorForCanvas(shiftData: GanttCanvasShift, ctx: CanvasRenderingContext2D): string | CanvasPattern;

  /**
   * Returns the height of the shift label (= space above shift content).
   * @param shiftData Shift canvas data to calculate the label height for.
   */
  getShiftLabelHeight(shiftData: GanttCanvasShift): number;
}

export interface IRectBoundings {
  x: number;
  y: number;
  width: number;
  height: number;
}

export interface IRectOffset {
  x?: number;
  y?: number;
  width?: number;
  height?: number;
}

export interface IStrokeData {
  strokeColor: string;
  strokeWidth: number;
  strokePattern: GanttStrokePattern;
  leaveRightSideOpen: boolean;
}

export enum EShiftFillType {
  COLOR = 'COLOR',
  PATTERN = 'PATTERN',
}

export interface IShiftFillData {
  type: EShiftFillType; // fill type (color or pattern)
  color: string; // shift color in current state (highlighted, selected)
  basicColor: string; // shift color in default state
  pattern?: PatternType; // pattern id
}
