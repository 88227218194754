import { CdkPortalOutletAttachedRef, ComponentPortal, ComponentType, PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ComponentRef, Input, inject } from '@angular/core';
import { selectSlice } from '@rx-angular/state/selections';
import { RxLet } from '@rx-angular/template/let';
import { RxPush } from '@rx-angular/template/push';
import { LocalState, LocalStateService } from 'frontend/src/dashboard/shared/data-access/local-state.service';
import { map, tap } from 'rxjs/operators';
import { DataType } from '../../data-access/base/base-template';
import { TEMPLATE_DEFINITIONS } from '../../data-access/templates';
import { ReturnType as DataReturnType } from './../../data-access/mapper';
import { ReturnType } from './../../data-access/templates';

interface TemplateLocalState extends LocalState<DataReturnType<DataType>> {
  componentPortal: ComponentPortal<ReturnType<DataType>>;
}

@Component({
  selector: 'template-loader',
  standalone: true,
  template: `
    <ng-container *rxLet="item$ as state">
      <ng-template (attached)="attached($event, state.id)" [cdkPortalOutlet]="portal$ | push"></ng-template>
    </ng-container>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [LocalStateService],
  imports: [CommonModule, RxPush, PortalModule, RxLet],
})
export class TemplateLoaderComponent {
  readonly state = inject(LocalStateService<DataReturnType<DataType>, TemplateLocalState>);

  @Input() set id(id: string) {
    this.state.set({ id });
  }

  portal$ = this.state.select(
    selectSlice(['componentPortal']),
    map(({ componentPortal }) => componentPortal)
  );
  item$ = this.state.item$.pipe(tap((t) => this.loadComponent(t.type.toLowerCase())));

  constructor() {
    this.state.set({ ComponentPortal: null });
  }

  attached(ref: CdkPortalOutletAttachedRef, id: string) {
    ref = ref as ComponentRef<ComponentPortal<ComponentType<ReturnType<DataType>>>>;
  }

  private async loadComponent(key: string): Promise<void> {
    const defs = TEMPLATE_DEFINITIONS;
    if (defs.has(key)) {
      const def = defs.get(key);
      const cmp = await def.loadComponent();
      this.state.set({ componentPortal: new ComponentPortal(cmp) });
    } else {
      console.error(`TemplateLoaderComponent: No component found for key ${key}`);
    }
  }
}
