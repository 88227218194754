import { EntryElementValue } from '@app-modeleditor/components/entry-collection/entry-element-value';
import { EGanttInstance } from '@gantt/public-api';
import { SaxMsBestGanttActiveSubmenuEntryElementSetting } from 'frontend/src/dashboard/gantt/gantt/saxms-best-gantt.settings';
import { Observable, of } from 'rxjs';
import { YAxisSearchHideRowsElement } from '../../../toolbar/elements/y-axis-search-hide-rows.element';
import { YAxisSearchNameElement } from '../../../toolbar/elements/y-axis-search-name.element';
import { ExternalGanttPlugin } from '../../external-plugin';

const GanttRowSearchExecuterId = 'gantt-plugin-y-axis-search-executer';

/**
 * PlugIn-Wrapper for GanttRowSearchExecuter.
 */
export class GanttYAxisSearchPlugIn extends ExternalGanttPlugin {
  public onInit(templateData: any, responseData: any) {
    this.addPlugIn(
      GanttRowSearchExecuterId,
      this._ganttLibService.ganttInstanceService.getInstance(EGanttInstance.ROW_SEARCH)
    );
  }

  public onDestroy(): void {}

  public onAction(action: any) {}

  public executeAction(action: any): Observable<any> {
    return of(null);
  }

  /**
   * Searchs for y axis row by given searchText.
   * @param searchText Search query.
   */
  public searchRowByTitleHierarchical(searchText: string): void {
    this.getPlugInById(GanttRowSearchExecuterId).searchRowByTitleHierarchical(searchText);
  }

  /**
   * Returns all row names of search result as an string array.
   * @param searchText Search query.
   */
  public getArrayOfEntriesByQuery(searchText: string): string[] {
    return this.getPlugInById(GanttRowSearchExecuterId).getArrayOfEntriesByQuery(searchText);
  }

  public injectSettings(submenuElements: SaxMsBestGanttActiveSubmenuEntryElementSetting[]): void {}

  public setShowOnlyResults(bool: boolean) {
    this.getPlugInById(GanttRowSearchExecuterId).setShowOnlyResults(bool);
  }

  public getCurrentSearchText(): string {
    return this.getPlugInById(GanttRowSearchExecuterId).getCurrentSearchText();
  }

  public resetSearch(): void {
    this.getPlugInById(GanttRowSearchExecuterId).reset();
    this.getPlugInById(GanttRowSearchExecuterId).setShowOnlyResults(false);
    this.resetToolbarElements();
  }

  private resetToolbarElements(): void {
    for (const menuItem of this.actionHandler.newToolbar.getMenuItems()) {
      // iterate over menu groups
      for (const group of menuItem.getToolbarGroups()) {
        // iterate over menu group items
        for (const element of group.getEntryElements()) {
          // reset all relevant toolbar elements
          if (element instanceof YAxisSearchNameElement) {
            element.getValue<EntryElementValue>().setValue('');
          }
          if (element instanceof YAxisSearchHideRowsElement) {
            element.getValue<EntryElementValue>().setValue(false);
            element.handleMenuIndicator(false);
          }
        }
      }
    }
  }

  public getNoRenderId(): string {
    return this.getPlugInById(GanttRowSearchExecuterId).getNoRenderId();
  }

  public getSearchTextChangeObservable(): Observable<string> {
    return this.getPlugInById(GanttRowSearchExecuterId).getSearchTextChangeObservable();
  }

  public getShowOnlyResultsChangeObservable(): Observable<boolean> {
    return this.getPlugInById(GanttRowSearchExecuterId).getShowOnlyResultsChangeObservable();
  }
}
