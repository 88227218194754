<mat-list>
  <mat-list-item class="contentText">
    <span>{{ 'GANTT.ROW.showSplitLines' | translate }}</span>
    <mat-slide-toggle (change)="toggleShowSplitLines($event)" [checked]="settings.showHorizontalSplitLines">
    </mat-slide-toggle>
  </mat-list-item>
  <mat-divider></mat-divider>
  <mat-list-item class="contentText">
    {{ '@vertical-lines@' | translate }}
    <mat-slide-toggle (change)="toggleDividingLines($event, 'vertical')" [checked]="settings.showVerticalLines">
    </mat-slide-toggle>
  </mat-list-item>
  <ng-container *ngIf="settings.showVerticalLines">
    <mat-divider></mat-divider>
    <mat-list-item class="contentText firstLevelList">
      <span class="indented">{{ '@color@' | translate }}</span>
      <app-color-picker
        [color]="settings.verticalLinesColor"
        (changeEvent)="setLineColor($event, 'verticalLinesColor')"
        [resetColor]="'#ffffff'"
        [disableAlpha]="true"
      >
      </app-color-picker>
    </mat-list-item>
    <mat-divider></mat-divider>
    <mat-list-item class="contentText firstLevelList">
      <span class="indented">{{ 'GANTT.SETTINGS.thickness' | translate }}</span>
      <app-slider
        class="sliderContainer"
        [max]="5"
        [min]="1"
        [value]="settings.verticalLinesThickness"
        [label]="settings.verticalLinesThickness + ' px'"
        (changeEvent)="setLineThickness($event, 'verticalLinesThickness')"
      ></app-slider>
    </mat-list-item>
    <mat-divider></mat-divider>
    <mat-list-item class="contentText firstLevelList">
      <span class="indented">{{ 'GANTT.SETTINGS.colorFullMonth' | translate }}</span>
      <app-color-picker
        [color]="settings.verticalLinesMonthlyColor"
        (changeEvent)="setLineColor($event, 'verticalLinesMonthlyColor')"
        [resetColor]="'#ffffff'"
        [disableAlpha]="true"
      >
      </app-color-picker>
    </mat-list-item>
    <mat-divider></mat-divider>
    <mat-list-item class="contentText firstLevelList">
      <span class="indented">{{ 'GANTT.SETTINGS.thicknessFullMonth' | translate }}</span>
      <app-slider
        class="sliderContainer"
        [max]="5"
        [min]="1"
        [value]="settings.verticalLinesMonthlyThickness"
        [label]="settings.verticalLinesMonthlyThickness + ' px'"
        (changeEvent)="setLineThickness($event, 'verticalLinesMonthlyThickness')"
      ></app-slider>
    </mat-list-item>
    <mat-divider></mat-divider>
    <mat-list-item class="contentText firstLevelList">
      <span class="indented">{{ 'GANTT.SETTINGS.colorFullDay' | translate }}</span>
      <app-color-picker
        [color]="settings.verticalLinesDailyColor"
        (changeEvent)="setLineColor($event, 'verticalLinesDailyColor')"
        [resetColor]="'#ffffff'"
        [disableAlpha]="true"
      >
      </app-color-picker>
    </mat-list-item>
    <mat-divider></mat-divider>
    <mat-list-item class="contentText firstLevelList">
      <span class="indented">{{ 'GANTT.SETTINGS.thicknessFullDay' | translate }}</span>
      <app-slider
        class="sliderContainer"
        [max]="5"
        [min]="1"
        [value]="settings.verticalLinesDailyThickness"
        [label]="settings.verticalLinesDailyThickness + ' px'"
        (changeEvent)="setLineThickness($event, 'verticalLinesDailyThickness')"
      ></app-slider>
    </mat-list-item>
  </ng-container>
  <mat-divider></mat-divider>
  <mat-list-item class="contentText">
    {{ '@horizontal-lines@' | translate }}
    <mat-slide-toggle (change)="toggleDividingLines($event, 'horizontal')" [checked]="settings.showHorizontalLines">
    </mat-slide-toggle>
  </mat-list-item>
  <ng-container *ngIf="settings.showHorizontalLines">
    <mat-divider></mat-divider>
    <mat-list-item class="contentText firstLevelList">
      <span class="indented">{{ '@color@' | translate }}</span>
      <app-color-picker
        [color]="settings.horizontalLinesColor"
        (changeEvent)="setLineColor($event, 'horizontalLinesColor')"
        [resetColor]="'#ffffff'"
        [disableAlpha]="true"
      >
      </app-color-picker>
    </mat-list-item>
    <mat-divider></mat-divider>
    <mat-list-item class="contentText firstLevelList">
      <span class="indented">{{ 'GANTT.SETTINGS.thickness' | translate }}</span>
      <app-slider
        class="sliderContainer"
        [max]="5"
        [min]="1"
        [value]="settings.horizontalLinesThickness"
        [label]="settings.horizontalLinesThickness + ' px'"
        (changeEvent)="setLineThickness($event, 'horizontalLinesThickness')"
      ></app-slider>
    </mat-list-item>
  </ng-container>
</mat-list>
