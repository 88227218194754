import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { TemplateHeadlineComponent } from './template-headline.component';

@NgModule({
  imports: [CommonModule, MatTooltipModule],
  declarations: [TemplateHeadlineComponent],
  exports: [TemplateHeadlineComponent],
})
export class TemplateHeadlineModule {}
