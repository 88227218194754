import { GanttUtilities } from '../../../gantt-utilities/gantt-utilities';

/**
 * Handler for defining rows with intervals in which different shifts are not allowed to drag.
 * All globalForbiddenRows are blocked for any shift drop.
 * @keywords shift, translation, area, limiter, limit, restriction, rule
 */
export class GanttShiftTranslationAreaLimiter {
  private _globalForbiddenAreas: GanttShiftTranslationGlobalForbiddenAreas[] = [];
  private _shiftSpecificForbiddenAreas: GanttShiftAreaTranslationRestrictions[] = [];
  private _followedRows: GanttShiftRestrictionRowFollowEntry[] = [];
  private _allowDropDefault = true;

  /**
   * Checks if shift has area restrictions.
   * @keywords shift, translation, restriction, area, check, proof
   * @param draggedShiftId Id of dragged shift.
   * @param targetRowId Id of target-row.
   * @param targetTimeStart Date of shift begin.
   * @param targetTimeEnd Date of shift end.
   * @return Data of the restricted area the specified shift is in.
   */
  public checkAreaRestriction(
    draggedShiftId: string,
    targetRowId: string,
    targetTimeStart: Date | number,
    targetTimeEnd: Date | number
  ): GanttShiftRestrictionDetails {
    // convert date values to number values
    targetTimeStart = targetTimeStart instanceof Date ? targetTimeStart.getTime() : targetTimeStart;
    targetTimeEnd = targetTimeEnd instanceof Date ? targetTimeEnd.getTime() : targetTimeEnd;

    let restrictionDetails = new GanttShiftRestrictionDetails(false, null, null, true);

    targetRowId = this.getFollowedRowIdByFollowerRowId(targetRowId); // if row is a follower

    // check global forbidden areas
    for (let i = 0; i < this._globalForbiddenAreas.length; i++) {
      // looking for rowId
      if (this._globalForbiddenAreas[i].rowId == targetRowId) {
        for (let j = 0; j < this._globalForbiddenAreas[i].intervals.length; j++) {
          // iterate over intervals
          const end = this._globalForbiddenAreas[i].intervals[j].end;
          const start = this._globalForbiddenAreas[i].intervals[j].start;
          const allowDrop = this._globalForbiddenAreas[i].intervals[j].allowDrop;
          if (
            this._checkAreaMargins(start.getTime(), end.getTime(), targetTimeStart, targetTimeEnd) &&
            restrictionDetails.allowDrop
          ) {
            // if shift is in restricted area and its allowed to drop shifts in this area -> forbidden drop have higher priority
            restrictionDetails = new GanttShiftRestrictionDetails(true, start, end, allowDrop);
          }
        }
      }
    }

    // check shift-id specific forbidden areas
    for (let i = 0; i < this._shiftSpecificForbiddenAreas.length; i++) {
      // looking for shiftId
      if (this._shiftSpecificForbiddenAreas[i].shiftId == draggedShiftId) {
        for (let j = 0; j < this._shiftSpecificForbiddenAreas[i].rowIds.length; j++) {
          // looking for rowId
          if (this._shiftSpecificForbiddenAreas[i].rowIds[j].rowId == targetRowId) {
            for (let k = 0; k < this._shiftSpecificForbiddenAreas[i].rowIds[j].intervals.length; k++) {
              // iterate over intervals
              const end = this._shiftSpecificForbiddenAreas[i].rowIds[j].intervals[k].end;
              const start = this._shiftSpecificForbiddenAreas[i].rowIds[j].intervals[k].start;
              const allowDrop = this._shiftSpecificForbiddenAreas[i].rowIds[j].intervals[k].allowDrop;
              if (
                this._checkAreaMargins(start.getTime(), end.getTime(), targetTimeStart, targetTimeEnd) &&
                restrictionDetails.allowDrop
              ) {
                // if shift is in restricted area and its allowed to drop shifts in this area -> forbidden drop have higher priority
                restrictionDetails = new GanttShiftRestrictionDetails(true, start, end, allowDrop);
              }
            }
          }
        }
      }
    }
    return restrictionDetails;
  }

  /**
   * Register row id in followedRows data set for following restrictions of another id.
   * @param rowIdToBeFollowed id of row to be followed
   * @param followerRowId id of the follower row
   */
  public followTranslationRestrictionsOfRow(rowIdToBeFollowed: string, followerRowId: string): void {
    if (!rowIdToBeFollowed || !followerRowId) {
      console.warn('Missing arguments.');
      return;
    }
    const entry = this._followedRows.find((entry) => entry.rowIdToBeFollowed === rowIdToBeFollowed);
    if (entry) {
      // entry of row to be followed exists
      if (entry.followerRowIds.includes(followerRowId)) {
        // console.warn("Follower row id is allready registered.");
        return;
      } // follower row id is allready registered
      else {
        entry.followerRowIds.push(followerRowId);
      }
    } else {
      // create new entry
      this._followedRows.push(new GanttShiftRestrictionRowFollowEntry(rowIdToBeFollowed));
      this.followTranslationRestrictionsOfRow(rowIdToBeFollowed, followerRowId); //register new entry
    }
  }

  /**
   * Checks if the shift is in a forbidden area.
   * @returns {boolean} True if shift is in an restricted area
   */
  private _checkAreaMargins(
    intervalStart: number,
    intervalEnd: number,
    targetTimeStart: number,
    targetTimeEnd: number
  ): boolean {
    // check if shiftbegin in area
    if (targetTimeStart >= intervalStart && targetTimeStart <= intervalEnd) return true;
    // check if shiftend in area
    if (targetTimeEnd >= intervalStart && targetTimeEnd <= intervalEnd) return true;
    // check if area is defined if not then the complete row is restricted
    if (intervalStart == null && intervalEnd == null) return true;
    // check if only endarea is defined then the complete row to the intervalEnd is restricted
    if (
      (intervalStart == null && targetTimeEnd <= intervalEnd) ||
      (intervalStart == null && targetTimeStart <= intervalEnd)
    ) {
      return true;
    }
    // check if only startarea is defined then the complete row from intervalStart is restricted
    if (
      (targetTimeStart >= intervalStart && intervalEnd == null) ||
      (targetTimeEnd >= intervalStart && intervalEnd == null)
    ) {
      return true;
    }
    // check if forbidden area between start and end of dragged shift
    if (targetTimeStart <= intervalStart && targetTimeEnd >= intervalEnd && intervalEnd && intervalStart) return true;
    return false;
  }

  //
  //  GETTER & SETTER
  //

  /**
   * Sets area restriction for drag and drop shifts.
   * @keywords area, translation, restriction, limit, shift, allowed
   * @param targetRowId Id of row.
   * @param startDate (optional) Start date of interval. If not set the interval starts at the beginning.
   * @param endDate (optional) End date of interval. If not set the interval ends at the end date of gantt.
   * @param shiftId (optional) Id of shift. If not set restriction is valid for all shifts.
   * @param allowDrop (optional) Decides if shift can drop in restricted area (true) or not (false).
   * @param visualize (optional) If true, the restricted area will be visualized.
   * @returns Interval id of registered restriction.
   */
  public setAreaTranslationRestriction(
    targetRowId: string,
    startDate: Date | number,
    endDate: Date | number,
    shiftId: string,
    allowDrop: boolean,
    visualize = true
  ): string {
    let row;
    // check if date is defined
    startDate = startDate ? new Date(startDate) : null;
    endDate = endDate ? new Date(endDate) : null;

    // check if drop mode is defined
    if (allowDrop === undefined || allowDrop === null) allowDrop = this._allowDropDefault;

    const interval = new GanttShiftTimeIntervalRestrictions(startDate, endDate, allowDrop);

    if (shiftId) {
      // a restriction bound on shift
      for (let i = 0; i < this._shiftSpecificForbiddenAreas.length; i++) {
        // search shift
        if (this._shiftSpecificForbiddenAreas[i].shiftId == shiftId) {
          // is shift available
          row = this._shiftSpecificForbiddenAreas[i].rowIds.find((data) => {
            return data.rowId === targetRowId;
          });
          if (!row) {
            // row doesnt exist -> create new row-entry
            const rowId = new GanttShiftTranslationGlobalForbiddenAreas(targetRowId, visualize);
            rowId.intervals.push(interval);
            this._shiftSpecificForbiddenAreas[i].rowIds.push(rowId);
            return interval.id;
          }
          row.intervals.push(interval);
          return interval.id;
        }
      } // shift doesnt exist -> create new entry
      const rowId = new GanttShiftTranslationGlobalForbiddenAreas(targetRowId, visualize);
      rowId.intervals.push(interval);
      const restrictionData = new GanttShiftAreaTranslationRestrictions(shiftId);
      restrictionData.rowIds.push(rowId);
      this._shiftSpecificForbiddenAreas.push(restrictionData);
      return interval.id;
    } else {
      // a global restriction
      for (let i = 0; i < this._globalForbiddenAreas.length; i++) {
        // search rowid
        if (this._globalForbiddenAreas[i].rowId == targetRowId) {
          // if rowId allready exists
          this._globalForbiddenAreas[i].intervals.push(interval);
          return interval.id;
        }
      } // row doesnt exist -> create new entry
      const entry = new GanttShiftTranslationGlobalForbiddenAreas(targetRowId, visualize);
      entry.intervals.push(interval);
      this._globalForbiddenAreas.push(entry);
    }

    return interval.id;
  }

  /**
   * Removes the restricted area with the given interval id.
   * @param intervalId Interval id of the restricted area to remove.
   */
  public removeRowRestrictionByIntervalId(intervalId: string): void {
    for (let i = 0; i < this._globalForbiddenAreas.length; i++) {
      const globalRow = this._globalForbiddenAreas[i];
      for (let j = 0; j < globalRow.intervals.length; j++) {
        const interval = globalRow.intervals[j];
        if (interval.id === intervalId) {
          globalRow.intervals.splice(j, 1);
          return;
        }
      }
    }
  }

  /**
   * Removes shift row restriction from local restriction dataset.
   * @keywords remove, clear, delete, row, translation, rule
   * @param shiftId Id of shift.
   */
  public removeRowTranslationRestriction(shiftId: string): void {
    if (!shiftId) {
      return;
    }

    for (let i = 0; i < this._shiftSpecificForbiddenAreas.length; i++) {
      if (this._shiftSpecificForbiddenAreas[i].shiftId == shiftId) {
        this._shiftSpecificForbiddenAreas.splice(i, 1);
        return;
      }
    }
  }

  /**
   * Removes all shift specific forbidden areas.
   * @keywords remove, clear, delete, shift, specific
   * @param shiftId Id of shift.
   */
  public clearShiftSpecificForbiddenAreas(): void {
    this._shiftSpecificForbiddenAreas.length = 0;
  }

  /**
   * Sets row restriction dataset.
   * @keywords set, row, translation, restriction, limit, rule
   * @param rowTranslationRestrictions Translation restriction data.
   */
  public setRowTranslationRestrictionFull(rowTranslationRestrictions: GanttShiftAreaTranslationRestrictions[]): void {
    this._shiftSpecificForbiddenAreas = rowTranslationRestrictions;
  }

  /**
   * Returns all restrictions of given shift id.
   * @keywords get, restriciton, id, limit, limitation, shift
   * @param shiftId Id of shift.
   * @return Combination of all global and matching shift restricitons.
   */
  public getRestrictionsByShiftId(shiftId: string): GanttShiftTranslationGlobalForbiddenAreas[] {
    const combinedRowData: GanttShiftTranslationGlobalForbiddenAreas[] = [];

    // copy globalForbiddenAreasDataset
    for (let i = 0; i < this._globalForbiddenAreas.length; i++) {
      const globalForbiddenAreaData: GanttShiftTranslationGlobalForbiddenAreas = structuredClone(
        this._globalForbiddenAreas[i]
      );
      combinedRowData.push(globalForbiddenAreaData);
    }
    // search for shiftId
    const shiftRestrictionIndex = this._shiftSpecificForbiddenAreas
      .map(function (e) {
        return e.shiftId;
      })
      .indexOf(shiftId);

    // if there is a specific restriction for this shift
    if (shiftRestrictionIndex != -1) {
      const specificRestrictionData: GanttShiftTranslationGlobalForbiddenAreas[] = structuredClone(
        this._shiftSpecificForbiddenAreas[shiftRestrictionIndex].rowIds
      );

      for (let i = 0; i < specificRestrictionData.length; i++) {
        const indexOfRowId = combinedRowData
          .map(function (e) {
            return e.rowId;
          })
          .indexOf(specificRestrictionData[i].rowId);

        if (indexOfRowId == -1) {
          // if rowId not exist
          combinedRowData.push(specificRestrictionData[i]); //add restrictionData
        } else {
          // sorting restrictions to existing RowData
          combinedRowData[indexOfRowId].intervals = combinedRowData[indexOfRowId].intervals.concat(
            specificRestrictionData[i].intervals
          );
        }
      }
    }

    // search for followers
    for (let i = 0; i < combinedRowData.length; i++) {
      const followerRows = this.getFollowersOfRowByRowId(combinedRowData[i].rowId);
      for (let j = 0; j < followerRows.length; j++) {
        const followerRowId = followerRows[j];
        const modifiedRowRestrictionData: GanttShiftTranslationGlobalForbiddenAreas = structuredClone(
          combinedRowData[i]
        ); // copy row restriction
        modifiedRowRestrictionData.rowId = followerRowId; // change id
        combinedRowData.push(modifiedRowRestrictionData); // push follower row in combinedRowData set
      }
    }
    return combinedRowData;
  }

  /**
   * Returns all shift-specific restricted areas.
   * @returns Array containing all shift-specific restricted areas.
   */
  public getShiftSpecificForbiddenAreas(): GanttShiftAreaTranslationRestrictions[] {
    return this._shiftSpecificForbiddenAreas;
  }

  /**
   * Returns all global restricted areas.
   * @returns Array containing all global restricted areas.
   */
  public getGlobalForbiddenAreas(): GanttShiftTranslationGlobalForbiddenAreas[] {
    return this._globalForbiddenAreas;
  }

  /**
   * Returns all followers of a row id.
   * @param rowId Id of row wich is followed.
   * @returns Array of follower row ids.
   */
  public getFollowersOfRowByRowId(rowId: string): string[] {
    if (!rowId) {
      console.warn('Missing arguments.');
      return;
    }
    const entry = this._followedRows.find((entry) => entry.rowIdToBeFollowed === rowId);
    if (entry) return entry.followerRowIds;
    return [];
  }

  /**
   * Returns the followed row id of a follower row id.
   * @param rowId Id of follower row.
   * @returns Id of followed row.
   */
  public getFollowedRowIdByFollowerRowId(rowId: string): string {
    if (!rowId) {
      console.warn('Missing arguments.');
      return;
    }

    for (let i = 0; i < this._followedRows.length; i++) {
      for (let j = 0; j < this._followedRows[i].followerRowIds.length; j++) {
        if (this._followedRows[i].followerRowIds[j] === rowId) {
          return this._followedRows[i].rowIdToBeFollowed;
        }
      }
    }
    return rowId;
  }
}

/**
 * Data class which combines a rowId with a timeintervals.
 * @keywords data, class, id, row, timespan, interval
 */
export class GanttShiftTranslationGlobalForbiddenAreas {
  rowId: string;
  intervals: GanttShiftTimeIntervalRestrictions[] = [];
  visualize: boolean;

  constructor(rowId: string, visualize = true) {
    this.rowId = rowId;
    this.visualize = visualize;
  }
}

/**
 * Data class which stores the shiftID with the associated restricted rowIDs.
 * @keywords data, class, id, shiftId, rowId
 */
export class GanttShiftAreaTranslationRestrictions {
  shiftId: string;
  rowIds: GanttShiftTranslationGlobalForbiddenAreas[] = [];

  constructor(shiftId: string) {
    this.shiftId = shiftId;
  }
}

/**
 * Data class wich stores a timeinterval
 * @keywords data, class, date, start, end, interval
 */
export class GanttShiftTimeIntervalRestrictions {
  start: Date;
  end: Date;
  allowDrop: boolean;
  id: string = GanttUtilities.generateUniqueID();

  constructor(start: Date, end: Date, allowDrop: boolean) {
    this.start = start;
    this.end = end;
    this.allowDrop = allowDrop;
  }
}

/**
 * Data class wich stores information about a restriction.
 */
export class GanttShiftRestrictionDetails {
  inArea: boolean;
  areaStart: Date;
  areaEnd: Date;
  allowDrop: boolean;

  constructor(inArea: boolean, areaStart: Date, areaEnd: Date, allowDrop: boolean) {
    this.inArea = inArea;
    this.areaStart = areaStart;
    this.areaEnd = areaEnd;
    this.allowDrop = allowDrop;
  }
}

/**
 * Data class wich stores information about followed rows.
 */
export class GanttShiftRestrictionRowFollowEntry {
  rowIdToBeFollowed: string;
  followerRowIds: string[] = [];

  constructor(rowIdToBeFollowed: string) {
    this.rowIdToBeFollowed = rowIdToBeFollowed;
  }
}
