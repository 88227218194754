<div class="toolbar">
  <div class="actionContainer">
    <div class="actionElement">
      <div class="alignContent">
        <mat-form-field>
          <mat-label>Attributsuche</mat-label>
          <input matInput (keyup)="searchForEntry($event)" autocomplete="off" [(ngModel)]="searchInput" />
          <mat-icon *ngIf="!searchInput" matSuffix>search</mat-icon>
          <button mat-button *ngIf="searchInput" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>
      <div class="alignContent">
        <mat-form-field class="selectionBox" appearance="fill">
          <mat-label>Attributauswahl</mat-label>
          <mat-select (selectionChange)="onSearchAttributeSelectionChange()" [(ngModel)]="selectedAttributeSearchValue">
            <mat-option [value]="'Alle'">Alle</mat-option>
            <mat-option *ngFor="let attribute of resourcesDataService.allAttributes" [value]="attribute"
              >{{ attribute }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
</div>
