import { Injectable } from '@angular/core';
import { environment } from 'frontend/src/environments/environment';

/**
 * Service that provides methods which generate unique paths to asset files.
 */
@Injectable({
  providedIn: 'root',
})
export class AssetPathService {
  private _version: string;

  constructor() {
    this._version = environment.version;
  }

  /**
   * Initializes the service.
   */
  public initialize(): void {
    this._refreshVersion();
  }

  /**
   * Refreshes the currently used version.
   */
  private _refreshVersion(): void {
    if (this._version !== environment.version) this._version = environment.version;
  }

  /**
   * Appends the current environment version to a specified file path to make it unqiue.
   * @param {string} pathWithoutVersion Original file path (e.g. '/assets/config.json').
   * @returns {string} File path with appended version (e.g. '/assets/config.json?version=2.10.7').
   */
  public getPathWithVersion(pathWithoutVersion: string): string {
    if (!this._version) {
      console.warn(`Version is ${this._version}!`);
    }
    return pathWithoutVersion + '?version=' + this._version;
  }

  /**
   * Checks if a specific condition is given and appends the current environment version (or a specified alternative if condition is not given).
   * @param {string} pathWithoutVersion Original file path.
   * @param {function} checkCondition Callback to check whether the condition is given or not.
   * @param {string} alternativeVersion Version string that should be used if the condition is not given.
   * @returns {string} File path with appended version.
   */
  public getPathWithVersionUnderCondition(
    pathWithoutVersion: string,
    checkCondition: () => boolean,
    alternativeVersion: string
  ): string {
    if (checkCondition()) return this.getPathWithVersion(pathWithoutVersion);
    return pathWithoutVersion + '?version=' + alternativeVersion;
  }
}
