import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MySharedModule } from '../shared/shared.module';
@NgModule({
  imports: [
    // LanguageModule,
    MySharedModule,
    TranslateModule,
  ],
  exports: [
    // LanguagePipe
  ],
  providers: [],
})
export class GlobalViewModule {}
