import { EGanttInstance } from '@gantt/public-api';
import { SaxMsBestGanttActiveSubmenuEntryElementSetting } from 'frontend/src/dashboard/gantt/gantt/saxms-best-gantt.settings';
import { Observable, of } from 'rxjs';
import { ExternalGanttPlugin } from '../../external-plugin';
import { GanttTimePointMapper } from './timepoint-marker.mapper';

const GanttTimePointExecuterId = 'gantt-plugin-timepoint-executer';

/**
 * PlugIn-Wrapper for GanttTimePointExecuter.
 * Provides functionality to add time point markers.
 */
export class GanttTimePointMarkerPlugIn extends ExternalGanttPlugin {
  private mapper: GanttTimePointMapper = new GanttTimePointMapper();

  /**
   * Adds global milestones to gantt by backend data.
   */
  public onInit(templateData: any, responseData: any) {
    this.addPlugIn(
      GanttTimePointExecuterId,
      this._ganttLibService.ganttInstanceService.getInstance(EGanttInstance.TIME_POINT, true, 'orange')
    );
    const mappedGlobalMilestones: any = this.mapper.extractTimePointData(responseData.hierarchicalPlan);
    this.getPlugInById(GanttTimePointExecuterId).setTimePointData(mappedGlobalMilestones);
    this.getPlugInById(GanttTimePointExecuterId).build();
  }

  public onDestroy(): void {}

  public onAction(action: any) {}

  public executeAction(action: any): Observable<any> {
    return of(null);
  }

  public injectSettings(submenuElements: SaxMsBestGanttActiveSubmenuEntryElementSetting[]): void {}
}
