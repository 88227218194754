import {
  Component,
  ComponentRef,
  ContentChild,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { TemplateService } from '@app-modeleditor/utils/template.service';
import { GlobalUtils } from 'frontend/src/dashboard/global-utils';
import { Template } from 'frontend/src/dashboard/model/resource/template';
import { EResizeMode } from 'frontend/src/dashboard/model/resource/template-resize-mode.enum';
import { ResizeEvent } from 'frontend/src/dashboard/view/resize/resize-event';
import { EResizeType } from 'frontend/src/dashboard/view/resize/resize-type.enum';
import { ResizeService } from 'frontend/src/dashboard/view/resize/resize.service';
import { TemplateAdapter } from '../../utils/template-factory.service';
import { TemplateUnregisterService } from '../template/template-unregister.service';
import { TemplateComponent } from '../template/template.component';
import { Content } from './content';
import { ContentPart } from './content-part/content-part';
import { ContentService } from './content.service';

const RENDERING_OFFSET = 200;
@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
})
export class ContentComponent implements OnInit, OnDestroy, OnChanges {
  private alive = true;
  c: Content;
  componentId = GlobalUtils.generateUUID();
  @ContentChild('elements') elements: any;
  @ContentChild('headerTemplate') headerTemplate: any;
  content: any;
  @Input() root: HTMLElement;
  @Input() resourceId: string;
  @Input('content') set model(content: any) {
    this.content = content;
    this.c = content instanceof Content ? content : this.templateFactory.adapt(content);
    this.compact = this.c.isCompact();
    this.templateApi.registerTemplate(this.resourceId || this.c.getResourceId(), this.c);
    this.classes = this.c && this.c.getResizeMode() === EResizeMode.FIT_PARENT ? 'fullscreen' : '';
  }
  @HostBinding('class.compact') compact = true;

  @Input() border: boolean;
  @HostBinding('class') classes: string;
  resizeIds = [null];
  @ViewChild('lower') lower: ElementRef;
  @ViewChild('upper') set upper(e: ElementRef) {
    this.resizeService.complete(this.resizeIds[0]);
    if (e) {
      this.resizeIds[0] = this.resizeService.create(
        e.nativeElement,
        (e: ResizeEvent) => {
          if (this.lower?.nativeElement) {
            this.lower.nativeElement.style.height = `calc(100% - ${e.getNewHeight() + 3}px)`;
          }
        },
        { types: [EResizeType.HEIGHT] }
      );
    }
  }

  resize(): void {}

  displayedContentParts = {};
  stickyContents: ContentPart[] = [];
  nonStickyContent: ContentPart[] = [];

  get nonSticky(): ContentPart[] {
    return this.c.getContentParts().filter((item) => !item.isSticky());
  }

  get sticky(): ContentPart[] {
    return this.c.getContentParts().filter((item) => item.isSticky());
  }

  afterHeaderSlotInit(instance: ComponentRef<TemplateComponent>, header: Template): void {
    instance.instance.root = this.root as any;
    instance.instance.templateNode = header;
    instance.instance.resourceId = this.resourceId || this.c.getResourceId();
  }

  afterTemplateSlotInit(instance: ComponentRef<TemplateComponent>, rootContainer?: HTMLElement): void {
    instance.instance.root = rootContainer || (this.root as any);
    instance.instance.resourceId = this.resourceId || this.c.getResourceId();
    instance.instance.contentId = this.c.getId();
    if (instance.location) {
      instance.location.nativeElement.style.display = 'block';
    }
  }

  constructor(
    private _el: ElementRef,
    private templateFactory: TemplateAdapter,
    private templateApi: TemplateService,
    private $unregisterApi: TemplateUnregisterService,
    private contentService: ContentService,
    private resizeService: ResizeService
  ) {}

  /**
   * Angular's on init hook.
   */
  public ngOnInit(): void {
    const element: any = this._el.nativeElement;
    element.setAttribute('id', this.content.id);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.resourceId) {
      this.reRegisterTemplate(changes.resourceId.previousValue, this.resourceId || this.c.getResourceId());
    }
  }

  public ngOnDestroy(): void {
    this.alive = false;
    if (this.c) {
      if (this.c.isPreventUnregister() && this.c.isPreventUnregisterTemp()) {
        this.c.onAllowUnregister.subscribe(() =>
          this.$unregisterApi.unregister(this.c, this.resourceId || this.c.getResourceId())
        );
      } else if (!this.c.isPreventUnregister()) {
        this.$unregisterApi.unregister(this.c, this.resourceId || this.c.getResourceId());
      }
    }

    this.c.removeEventListener(this.componentId, 'afterContentPartsChanged');
    this.resizeService.complete(...this.resizeIds);
  }

  public check(a: { [key: string]: number }, b: { [key: string]: number }, attr: string): number {
    if (a[attr] < b[attr]) {
      return -1;
    }

    if (a[attr] > b[attr]) {
      return 1;
    }

    if (a[attr] === b[attr]) {
      return 0;
    }
  }

  /**
   * Re-registers the template when the resource id changes.
   * @param oldResourceId Resource id under which the template is currently registered.
   * @param newResourceId Resource id under which the template should be re-registered.
   */
  private reRegisterTemplate(oldResourceId: string, newResourceId: string): void {
    this.$unregisterApi.unregister(this.c, oldResourceId);
    this.templateApi.registerTemplate(newResourceId, this.c);
  }
}
