import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { MDUI } from './mdui';
// Helper component to add dynamic components
@Component({
  selector: 'mdui',
  templateUrl: 'mdui-base.html',
})
export class MDUIBase implements OnInit {
  @Input() options: any;
  @Input() onEvent: Subject<void>;
  @Input() itemsPerPage: number;
  @Input() pageIgnore = false;
  @Output() onResult: EventEmitter<any> = new EventEmitter();
  private alive = true;
  localSubject: Subject<void> = new Subject();

  public currentPage = 0;
  public mdui: MDUI;
  public pageSize = 3;

  public pageHandler = false;

  constructor() {}

  public handlePage(event: any) {
    this.currentPage = event.pageIndex;
    this.pageSize = event.pageSize;
    this.onClickPage(event, event.pageIndex + 1);
    this.pageHandler = true;
    this.resetMDUI();

    // Todo decrement stuff
  }

  public resetMDUI() {
    this.mdui = new MDUI(this.options);
    this.mdui.fillQueueByIndex(this.currentPage, this.currentPage + this.pageSize - 1);
    this.localSubject.next();
  }
  /**
   * returns list of pages
   * */
  public getPageCount(): Array<number> {
    if (!this.options) {
      return;
    }

    const pages: Array<number> = [];

    for (let i = 0; i < this.getPageSize(); i++) {
      pages.push(i + 1);
    }

    return pages;
  }

  /**
   * get the number of pages
   */
  public getPageSize(): number {
    if (this.options.settingsCategories) {
      return Math.ceil(this.options.settingsCategories.length / this.itemsPerPage);
    }
    return 0;
  }

  /**
   *
   * @param event handle onClick event
   * @param index clicked index
   */
  public onClickPage(event, index): number {
    return (this.currentPage = index - 1);
  }

  /**
   * handles onClickNext event
   */
  public getNextPage(): number {
    return (this.currentPage =
      this.currentPage + 1 >= this.getPageSize() ? this.getPageSize() - 1 : this.currentPage + 1);
  }

  /**
   * returns options
   */
  public getOptions(): any {
    return this.options.settingsCategories ? this.options.settingsCategories : [];
  }

  /**
   * handles onClickPrevious event
   */
  public getPreviousPage(): number {
    return (this.currentPage = this.currentPage - 1 < 0 ? 0 : this.currentPage - 1);
  }

  /**
   * handle result from child
   * @param event event
   */
  public handleResults(event): void {
    this.mdui.fillOptions(event);
    this.mdui.removeFromQueue(event.id);

    if (this.pageHandler) {
      if (this.mdui.getQueue().length == 0) {
        this.pageHandler = false;
      }
    } else {
      if (this.mdui.getQueue().length == 0) {
        this.onResult.emit(this.mdui.getOptions());
      }
    }
  }
  ngOnInit() {
    this.onEvent.pipe(takeWhile(() => this.alive)).subscribe((event) => {
      this.resetMDUI();
    });
  }

  ngOnDestroy(): void {
    this.alive = false;
  }
}
