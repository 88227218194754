import { Injectable, Injector, Type } from '@angular/core';
import { Adapter } from '@core/adapter';
import { EntryElementFactory } from '@app-modeleditor/components/entry-collection/entry-factory.service';
import { TemplateAdapter } from '@app-modeleditor/utils/template-factory.service';
import { NumericInput } from './numeric-input';

@Injectable({
  providedIn: 'root',
})
export class NumericInputAdapter implements Adapter<NumericInput> {
  constructor(private injector: Injector) {}

  adapt(item: any): NumericInput {
    throw new Error('Method not implemented.');
  }

  inherit<T extends NumericInput>(type: Type<T>, item: any): T {
    const t: T = this._setProps<T>(this.injector.get(EntryElementFactory).inherit(type, item), item);
    return t;
  }

  inheritFrom<T extends NumericInput>(scope: TemplateAdapter, type: Type<T>, item: any): T {
    const t: T = this._setProps<T>(this.injector.get(EntryElementFactory).inheritFrom(scope, type, item), item);
    return t;
  }

  applyValues<T>(scope: TemplateAdapter, item: any, values: any): T {
    throw new Error('Method not implemented.');
  }

  private _setProps<T extends NumericInput>(el: T, item: any): T {
    el.setDisableArrows(item.disableArrows);
    return el;
  }
}
