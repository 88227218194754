import { MySharedModule } from '../../../shared/shared.module';
import { FilterModule } from '../../../core/filter/filter.module';
import { ToolbarComponent } from './toolbar.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [FilterModule, MySharedModule, CommonModule],
  declarations: [ToolbarComponent],
  exports: [ToolbarComponent, FilterModule],
})
export class ToolbarModule {}
