import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TemplateUsersettingsComponent } from './template-usersettings.component';
import { MySharedModule } from 'frontend/src/dashboard/shared/shared.module';
import { MDUIBase } from 'frontend/src/dashboard/model-driven-ui/mdui-base';
import { MDUIEntry } from 'frontend/src/dashboard/model-driven-ui/mdui-entry';
import { MDUIHeadline } from 'frontend/src/dashboard/model-driven-ui/mdui-headline';

@NgModule({
  imports: [CommonModule, MySharedModule],
  declarations: [TemplateUsersettingsComponent, MDUIEntry, MDUIHeadline, MDUIBase],
  exports: [TemplateUsersettingsComponent, MDUIEntry, MDUIHeadline, MDUIBase],
})
export class TemplateUsersettingsModule {}
