<ng-container
  *ngIf="
    fileProgress &&
    fileProgress?.getProgress() !== 100 &&
    fileProgress.getNumberOfBytesToTransfer() &&
    fileProgress.getNumberOfBytesToTransfer() > 0
  "
>
  {{ fileProgress.getProgressAsString() }} - {{ fileProgress.getNumberOfTransferedBytes() | fileSize }}/{{
    fileProgress.getNumberOfBytesToTransfer() | fileSize
  }}
  <mat-progress-bar
    [value]="fileProgress?.getProgress()"
    [mode]="fileProgress?.getProgress() !== 100 && fileProgress?.getProgress() !== 0 ? 'determinate' : 'buffer'"
  >
  </mat-progress-bar>
</ng-container>
