import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from 'frontend/src/dashboard/core/material/material.module';
import { ResourceSelectorComponent } from './resource-selector.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, TranslateModule, MaterialModule],
  declarations: [ResourceSelectorComponent],
  exports: [ResourceSelectorComponent],
})
export class ResourceSelectorModule {}
