import { Content } from '@app-modeleditor/components/content/content';
import { ContentPart } from '@app-modeleditor/components/content/content-part/content-part';
import { EntryCollection } from '@app-modeleditor/components/entry-collection/entry-collection';
import { EntryElement } from '@app-modeleditor/components/entry-collection/entry-element';
import { EntryElementValue } from '@app-modeleditor/components/entry-collection/entry-element-value';
import { EFieldType } from '@app-modeleditor/components/entry-collection/field-type.enum';
import { ConfirmLightbox } from '@app-modeleditor/components/lightbox/predefined/confirm-lightbox';
import { ListSelector } from '@app-modeleditor/components/list-selector/list-selector';
import { ListSelectorElement } from '@app-modeleditor/components/list-selector/list-selector-element';
import { TranslateService } from '@ngx-translate/core';
import { IGanttTimePeriodMouseOverResult } from './gantt-template-data-attribute-mapping.interface';

/**
 * Lightbox for interval selection.
 */
export class BlockingIntervalSelectionLightbox extends ConfirmLightbox {
  public selection: void = null;
  public selector = new ListSelector();

  constructor(private title: string, private translate: TranslateService) {
    super(title);
  }

  /**
   * Must be called for initialization.
   * The intervals to select must be given as parameter.
   */
  public init(intervals: IGanttTimePeriodMouseOverResult[]): this {
    const headline = new EntryCollection().addEntryElements(
      new EntryElement()
        .setFieldType(EFieldType.TEXT)
        .setValue(new EntryElementValue().setValue(this.translate.instant('@chooseIntervalToEdit@')))
    );

    this.setAbsoluteWidth(400).setContent(
      new Content().setContentParts([
        new ContentPart()
          .setDisplayContentpartContainer(false)
          .setContentElements([
            headline,
            this.getRadioButtons(intervals.filter((res) => res.block.name != 'OVERLAP_INTERVAL')),
          ]), // filter out overlap intervals from list
      ])
    );

    return this;
  }

  /**
   * Builds radio buttons.
   */
  private getRadioButtons(intervals: IGanttTimePeriodMouseOverResult[]): ListSelector {
    this.selector.setMultiSelect(false);

    // sort by time
    intervals.sort((a, b) => a.block.x - b.block.x);

    const selections: ListSelectorElement[] = intervals.map((elem, i) => {
      const markerData = elem.interval.getTimePeriodById(elem.block.id);
      const fromLine = `${this.translate.instant('GENERAL.from')}: ${this.getDate(markerData.dateStart)}`;
      const toLine = `${this.translate.instant('GENERAL.to')}: ${this.getDate(markerData.dateEnd)}`;
      return new ListSelectorElement()
        .setSelected(i === 0) // first element is selected
        .setSimpleElement(true)
        .setUuid(elem.block.id)
        .setFloatLeftLines([elem.block.name, fromLine, toLine]);
    });
    this.selector.setValue(selections);
    return this.selector;
  }

  /**
   * Gets a date object and returns a formatted date string.
   */
  private getDate(date: Date): string {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const hour = date.getHours();
    const minute = date.getMinutes();

    return `${this.getTwoDigits(day)}.${this.getTwoDigits(month)}.${year} ${this.getTwoDigits(
      hour
    )}:${this.getTwoDigits(minute)} Uhr`;
  }

  /**
   * Returns a string of number with two digits.
   */
  private getTwoDigits(number: number): string {
    return number < 10 ? '0' + number : number.toString();
  }
}
