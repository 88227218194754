import { EntryElement } from '../entry-collection/entry-element';

export class RichTextEditor extends EntryElement {
  private height: number;

  public getHeight(): number {
    return this.height || 700;
  }

  public setHeight(height: number): this {
    this.height = height;
    return this;
  }
}
