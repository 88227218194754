import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ConfigService } from '@core/config/config.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LoadingSpinnerComponent } from 'frontend/src/dashboard/user/ui/loading-spinner.component';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { PasswordUtils } from '../../password-utils';
import { ChangePasswordResult } from './change-password-result.interface';

/**
 * Component for changing the user's password.
 */
@Component({
  selector: 'app-change-password-form',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    LoadingSpinnerComponent,
    RecaptchaModule,
    RecaptchaFormsModule,
  ],
  templateUrl: './change-password-form.component.html',
  styleUrls: ['./change-password-form.component.scss'],
})
export class ChangePasswordFormComponent implements OnInit {
  @Input() protected showCurrentPasswordField = true;
  @Input() protected useRecaptcha = false;
  @Input() protected set isProcessing(value: boolean) {
    value ? this.formGroup.disable() : this.formGroup.enable();
    this._isProcessing = value;
  }

  @Output() protected emit = new EventEmitter<ChangePasswordResult>();

  private readonly translateService = inject(TranslateService);
  private readonly passwordConfig = inject(ConfigService).access().security?.password;
  protected readonly minPasswordLength = this.passwordConfig?.minLength || 8;
  protected readonly maxPasswordLength = this.passwordConfig?.maxLength || 25;
  protected readonly lowercase = this.passwordConfig?.lowercase ?? true;
  protected readonly uppercase = this.passwordConfig?.uppercase ?? true;
  protected readonly decimal = this.passwordConfig?.decimal ?? true;
  protected readonly specialDecodedCharacters = (this.passwordConfig?.specialCharacters || []).join(' ');
  protected readonly specialCharacters = (this.passwordConfig?.specialCharacters || [])
    .map((item) => `\\${item}`)
    .join('');

  protected currentPasswordVisible = false;
  protected newPasswordVisible = false;
  protected confirmPasswordVisible = false;
  protected _isProcessing = false;
  protected currentPasswordControl = new FormControl('', [Validators.required]);
  protected recaptchaReactive = new FormControl(null, Validators.required);

  protected newPasswordControl = new FormControl('', [
    Validators.required,
    PasswordUtils.testRegex(/[a-z]+/, 'lowercase', this.lowercase),
    PasswordUtils.testRegex(/[A-Z]+/, 'uppercase', this.uppercase),
    PasswordUtils.testRegex(/[0-9]+/, 'decimal', this.decimal),
    PasswordUtils.testRegex(
      new RegExp(`[${this.specialCharacters}]+`),
      'special',
      this.specialCharacters.length ? true : false
    ),
    Validators.minLength(this.minPasswordLength),
    Validators.maxLength(this.maxPasswordLength),
  ]);
  protected confirmPasswordControl = new FormControl('', [Validators.required, this.customValidator('same')]);

  protected formGroup: FormGroup;

  constructor() {
    const group = {
      newPassword: this.newPasswordControl,
      confirmPassword: this.confirmPasswordControl,
    };

    this.formGroup = new FormGroup(group);
  }

  ngOnInit(): void {
    if (this.showCurrentPasswordField) {
      this.formGroup.addControl('currentPassword', this.currentPasswordControl);
    }

    if (this.useRecaptcha) {
      this.formGroup.addControl('recaptchaReactive', this.recaptchaReactive);
    }
  }

  protected onSubmit(): void {
    const result: ChangePasswordResult = {
      oldPassword: this.currentPasswordControl.value || '',
      newPassword: this.newPasswordControl.value || '',
      recaptchaResponse: this.recaptchaReactive.value || '',
    };

    this.emit.emit(result);
  }

  /**
   * Returns an error message based on the given control's validation errors.
   * @param control - The control to check for errors.
   * @returns An error message string, or an empty string if no errors were found.
   */
  protected getErrorMessage(control: AbstractControl) {
    if (control.hasError('required')) {
      return this.translateService.instant('SECURITY.ERRORS.required');
    }

    if (control.hasError('minlength')) {
      return this.translateService.instant('SECURITY.ERRORS.minlength', {
        value: this.minPasswordLength,
      });
    }

    if (control.hasError('maxlength')) {
      return this.translateService.instant('SECURITY.ERRORS.maxlength', {
        value: this.maxPasswordLength,
      });
    }

    if (control.hasError('special')) {
      return this.translateService.instant('SECURITY.ERRORS.special');
    }

    if (control.hasError('decimal')) {
      return this.translateService.instant('SECURITY.ERRORS.decimal');
    }

    if (control.hasError('uppercase')) {
      return this.translateService.instant('SECURITY.ERRORS.uppercase');
    }

    if (control.hasError('lowercase')) {
      return this.translateService.instant('SECURITY.ERRORS.lowercase');
    }

    if (control.hasError('same')) {
      return this.translateService.instant('SECURITY.ERRORS.same');
    }

    return '';
  }
  ƒ;

  /**
   * Returns a custom validator function that checks if the values of the new password and confirm password controls match.
   * @param name The name of the validation error to return if the values do not match.
   * @returns A validator function that returns a validation error object if the values of the new password and confirm password controls do not match, otherwise null.
   */
  private customValidator(name: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const match = this.newPasswordControl?.value === this.confirmPasswordControl?.value;

      return match ? null : { [name]: { value: control.value } };
    };
  }
}
