<div class="hotspot-sidebar-wrapper">
  <mat-form-field class="attribute-category-select">
    <mat-label>{{ 'attribute_category' | translate }}</mat-label>
    <mat-select (selectionChange)="changeAttributeCategory($event)" [value]="currentAttribute?.getId()">
      <mat-option
        [matTooltip]="attributeCategory.getName()"
        [matTooltipShowDelay]="1000"
        *ngFor="let attributeCategory of attributeCategories"
        [value]="attributeCategory.getId()"
      >
        {{ attributeCategory.getName() }}</mat-option
      >
    </mat-select>
  </mat-form-field>

  <div class="opacity-container" *ngIf="currentAttribute">
    <mat-label>{{ 'opacity' | translate }}</mat-label>
    <mat-slider
      class="opacity-slider"
      [ngModel]="currentAttribute.getColorScheme().getOpacity()"
      (change)="changeOpacity($event)"
      max="100"
      thumbLabel="true"
    ></mat-slider>
    <mat-divider></mat-divider>
  </div>

  <div class="style-container" *ngIf="currentAttribute">
    <mat-label>{{ 'style' | translate }}</mat-label>

    <mat-form-field class="colorscheme-style-select">
      <mat-select
        (selectionChange)="changeColorSchemeStyle($event)"
        [disabled]="currentAttribute.getColorScheme().checkStringValue()"
        [value]="currentAttribute.getColorScheme().getStyle()"
      >
        <mat-option
          [matTooltip]="colorStyle"
          [matTooltipShowDelay]="1000"
          *ngFor="let colorStyle of getColorSchemestyles()"
          [value]="colorStyle"
          >{{ colorStyle }}</mat-option
        >
      </mat-select>
    </mat-form-field>
    <mat-divider></mat-divider>
  </div>

  <div class="color-container" *ngIf="currentAttribute">
    <mat-label>{{ 'color_range' | translate }}</mat-label>
    <mat-divider></mat-divider>
    <template-colorscheme-color-slider
      (afterColorValueChanges)="afterColorValueChange()"
      [colorScheme]="currentAttribute?.getColorScheme()"
    ></template-colorscheme-color-slider>
  </div>
</div>
