import { YAxisDataFinder } from '../data-finder/yaxis-data-finder';
import { GanttDataContainer } from '../data-structure/data-structure';

export class DataAdder {
  originDataSet: GanttDataContainer;

  constructor(dataSet) {
    this.originDataSet = dataSet;
  }

  /**
   * Adds a row to origin data set.
   * @param {GanttDataRow} rowData Data of row to add.
   * @param {string} parentRowId id of parent row
   * @param {number} index position of new row in parent row
   * @param {boolean} considerOverlap
   */
  addRowToOriginDataSet(rowData, parentRowId, index = 0, considerOverlap = false) {
    if (!rowData) {
      console.error('No gantt row to add.');
      return false;
    }

    let ganttEntries;
    let realIndex = index;

    if (!parentRowId) {
      // no parent -> top level
      ganttEntries = this.originDataSet.ganttEntries;
    } else {
      const foundRow = YAxisDataFinder.getRowById(this.originDataSet.ganttEntries, parentRowId).data;
      if (!foundRow) {
        console.error('No known parent row ID to add new row.');
        return false;
      }
      ganttEntries = foundRow.child;
    }
    const entryLength = ganttEntries.length - 1;

    if (considerOverlap) {
      // find the real position index of row, considering overlapping
      realIndex = 0;
      for (let i = 0; i < index; i++) {
        if (realIndex > entryLength) {
          console.error('Index greater than amount of entries.');
          return false;
        }
        if (ganttEntries[realIndex].group == 'BASE-LEAF' || ganttEntries[realIndex].group == 'BASE-TREE') {
          do {
            realIndex++;
          } while (ganttEntries[realIndex].group == 'MEMBER');
        } else {
          realIndex++;
        }
      }
    }
    ganttEntries.splice(realIndex, 0, rowData);
    return true;
  }
}
