import { Action } from '@app-modeleditor/components/button/action/action';
import { EActionType } from '@app-modeleditor/components/button/action/action-type.enum';
import { Button } from '@app-modeleditor/components/button/button';
import { EButtonDisplayType } from '@app-modeleditor/components/button/button-display-type.enum';
import { ContextMenuItem } from '@app-modeleditor/components/contextmenu/context-menu-item';
import { ContextMenu } from '@app-modeleditor/components/contextmenu/contextmenu';
import { EntryElement } from '@app-modeleditor/components/entry-collection/entry-element';
import { EntryElementValue } from '@app-modeleditor/components/entry-collection/entry-element-value';
import { EFieldType } from '@app-modeleditor/components/entry-collection/field-type.enum';
import { MenuItem } from 'frontend/src/dashboard/view/template-toolbar/menu-item';
import { Toolbar } from 'frontend/src/dashboard/view/template-toolbar/toolbar';
import { ToolbarGroup } from 'frontend/src/dashboard/view/template-toolbar/toolbar-group';
import { of } from 'rxjs';
import { GeneralChartInformation } from '../general-chart-information';
import { LayoutInformation } from '../LayoutInformation';
import { ChartsComponent } from './../chart.component';

export class ViewToolbar {
  constructor(private scope: ChartsComponent, private disableCompare: boolean) {}

  private getIconButton(): Button {
    return new Button().setDisplayType(EButtonDisplayType.ICON_WITH_LABEL_BELOW);
  }

  /**
   * get ViewToolbar
   * @param t Toolbar
   * @param scope ChartsComponent
   * @returns MenuItem
   */
  public get(t: Toolbar): MenuItem {
    const chartInfo: GeneralChartInformation = this.scope.mainChart;
    const amongstElem: Button = this.getIconButton()
      .chainActions(
        new Action().setCb(() =>
          of(this.scope.handleLayoutSwitch({ event: null, additionalArgs: [LayoutInformation.stack] }))
        )
      )
      .setId(`${chartInfo.chartId}_below`)
      .setIcon('view_stream')
      .setName('CHART.ACTION.COMPARE.below');
    const sidebySideElem: Button = this.getIconButton()
      .chainActions(
        new Action().setCb(() =>
          of(this.scope.handleLayoutSwitch({ event: null, additionalArgs: [LayoutInformation.byside] }))
        )
      )
      .setId(`${chartInfo.chartId}_besides`)
      .setIcon('view_column')
      .setName('CHART.ACTION.COMPARE.besides');

    const typeEntries: EntryElement[] = [];
    if (chartInfo.chartData.chartType == 'pie' || chartInfo.chartData.chartType == 'doughnut') {
      typeEntries.push(
        this.getIconButton()
          .chainActions(
            new Action().setCb(() => of(this.scope.handleTypeClick({ event: null, additionalArgs: ['pie'] })))
          )
          .setEnableBy(() => this.scope.enableToggleButton())
          .setId(`${chartInfo.chartId}_pieType`)
          .setName('CHART.ACTION.TYPES.pie')
          .setIcon('pie_chart')
      );
      typeEntries.push(
        this.getIconButton()
          .chainActions(
            new Action().setCb(() => of(this.scope.handleTypeClick({ event: null, additionalArgs: ['doughnut'] })))
          )
          .setEnableBy(() => this.scope.enableToggleButton())
          .setId(`${chartInfo.chartId}_doughnutType`)
          .setName('CHART.ACTION.TYPES.doughnut')
          .setIcon('donut_large')
      );
    } else {
      typeEntries.push(
        this.getIconButton()
          .chainActions(
            new Action().setCb(() => of(this.scope.handleTypeClick({ event: null, additionalArgs: ['line'] })))
          )
          .setEnableBy(() => this.scope.enableToggleButton())
          .setId(`${chartInfo.chartId}_lineType`)
          .setName('CHART.ACTION.TYPES.line')
          .setIcon('show_chart')
      );
      typeEntries.push(
        this.getIconButton()
          .chainActions(
            new Action().setCb(() => of(this.scope.handleTypeClick({ event: null, additionalArgs: ['bar'] })))
          )
          .setEnableBy(() => this.scope.enableToggleButton())
          .setId(`${chartInfo.chartId}_barType`)
          .setName('CHART.ACTION.TYPES.bar')
          .setIcon('insert_chart')
      );
    }
    typeEntries.push(
      this.getIconButton()
        .chainActions(new Action().setCb(() => of(this.scope.addIntegral())))
        .setEnableBy(() => this.scope.enableIntegralButton())
        .setId(`${chartInfo.chartId}_integral`)
        .setName('CHART.ACTION.TYPES.integral')
        .setIcon('texture')
    );

    const otherEntries: EntryElement[] = [];
    const legendPos: Button = this.getIconButton()
      .setId(`${chartInfo.chartId}_legendPostion`)
      .setIcon('cached')
      .setName('CHART.ACTION.OTHERS.legendposition');

    const switchLegendBtn: ContextMenuItem = new ContextMenuItem()
      .setName(this.scope.legendVisible ? 'Legende ausblenden' : 'Legende einblenden')
      .setIcon('visibility_off');
    const switchLegendLeftRightBtn: ContextMenuItem = new ContextMenuItem()
      .setEnableBy(() => this.scope.legendVisible)
      .setName(this.scope.legendMode === 'left' ? 'Legende rechts' : 'Legende links')
      .setIcon('swap_horiz');

    otherEntries.push(
      legendPos.setMenu(
        new ContextMenu().setContextMenuItems([
          switchLegendLeftRightBtn.chainActions(
            new Action().setCb(() => of(this.toggleLeftRightLegend(switchLegendLeftRightBtn)))
          ),
          switchLegendBtn.chainActions(new Action().setCb(() => of(this.toggleHideLegend(switchLegendBtn)))),
        ])
      )
    );
    otherEntries.push(
      this.getIconButton()
        .setId(`${chartInfo.chartId}_resetSettings`)
        .setIcon('settings_backup_restore')
        .setName('CHART.ACTION.OTHERS.resetsettings')
        .chainActions(new Action().setActionType(EActionType.LOCAL).setCb(() => of(this.scope.resetSettings())))
    );
    otherEntries.push(
      this.getIconButton()
        .setId(`${chartInfo.chartId}_resetIntegrals`)
        .setIcon('INTEGRAL_REMOVE')
        .setName('CHART.ACTION.OTHERS.resetIntegrals')
        .chainActions(new Action().setActionType(EActionType.LOCAL).setCb(() => of(this.scope.resetIntegrals())))
    );
    otherEntries.push(
      new EntryElement()
        .setFieldType(EFieldType.SLIDE_TOGGLE)
        .setId(`${chartInfo.chartId}_labelXAxisVisible`)
        .setName(this.scope.translate.instant('CHART.ACTION.OTHERS.labelXAxisVisible'))
        .onChanges((change: EntryElementValue) => {
          this.scope.toogleXAxis({ event: { data: { toggle: change.getValue<boolean>() } } });
        })
        .setValue(new EntryElementValue().setValue(this.scope.labelXAxisVisible))
    );

    // create groups
    const compareGroup: ToolbarGroup = new ToolbarGroup()
      .setName('Vergleich')
      .setId('chart-toolbar-view-compare')
      .setEntryElements([amongstElem, sidebySideElem]);

    const typeGroup: ToolbarGroup = new ToolbarGroup()
      .setName('CHART.VIEW.Charttype')
      .setId('chart-toolbar-view-type')
      .setEntryElements(typeEntries);

    const otherGroup: ToolbarGroup = new ToolbarGroup()
      .setName('CHART.VIEW.Others')
      .setId('chart-toolbar-view-type')
      .setEntryElements(otherEntries);

    const items = [typeGroup, otherGroup];
    if (!this.disableCompare) {
      items.unshift(compareGroup);
    }

    // create menu items
    const m: MenuItem = new MenuItem()
      .defaultGroup(t)
      .setName('Ansicht')
      .setId('chart-toolbar-view')
      .setToolbarGroups(items);

    return m;
  }

  private toggleHideLegend(btn: Button) {
    btn.setName(this.scope.legendVisible ? 'Legende einblenden' : 'Legende ausblenden');
    this.scope.switchLegend('hidden');
  }

  private toggleLeftRightLegend(btn: Button) {
    this.scope.switchLegend(this.scope.legendMode === 'right' ? 'left' : 'right');
    btn.setName(this.scope.legendMode === 'right' ? 'Legende links' : 'Legende rechts');
  }
}
