import { ImageModule } from '../image/image.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TemplateStatebarComponent } from './template-statebar.component';
import { TemplateStatebarIconComponent } from './template-statebar-elemente/template-statebar-icon.component';
import { MatIconModule } from '@angular/material/icon';
import { MatBadgeModule } from '@angular/material/badge';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';

@NgModule({
  imports: [CommonModule, MatIconModule, MatBadgeModule, ImageModule, MatTooltipModule],
  declarations: [TemplateStatebarComponent, TemplateStatebarIconComponent],
  exports: [TemplateStatebarComponent],
})
export class TemplateStatebarModule {}
