import { Injectable, Type } from '@angular/core';
import { TemplateAdapter } from '@app-modeleditor/utils/template-factory.service';
import { Adapter } from '../../../core/adapter';
import { EntryElementFactory } from './../entry-collection/entry-factory.service';
import { NumericRangePicker } from './numeric-range-picker';

@Injectable({
  providedIn: 'root',
})
export class NumericRangePickerAdapter implements Adapter<NumericRangePicker> {
  constructor(private entryElementAdapter: EntryElementFactory) {}

  adapt(item: any): NumericRangePicker {
    throw new Error('Method not implemented.');
  }
  inherit?<T extends NumericRangePicker>(type: Type<T>, item: any): T {
    const t: T = this.entryElementAdapter.inherit<T>(type, item);
    return t;
  }

  inheritFrom?<T extends NumericRangePicker>(scope: TemplateAdapter, type: Type<T>, item: any): T {
    const t: T = this.entryElementAdapter.inheritFrom<T>(scope, type, item);
    return t;
  }
  applyValues<T>(scope: TemplateAdapter, item: any, values: any): T {
    throw new Error('Method not implemented.');
  }
}
