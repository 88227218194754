export class FunctionsPermission {
  public deleteRoleAllow = false;
  public getRoleAllow = false;
  public createRoleAllow = false;
  public getPermissionRoleAllow = false;
  public editRoleAllow = false;
  public editUserOfRoleAllow = false;
  public editPermissionRoleAllow = false;
  public deleteUserAllow = false;
  public editUserAllow = false;
  public createUserAllow = false;
  public updateRoleAllow = false;

  constructor() {}
}

export class ObjectPermission {
  public getAllow = false;
  public putAllow = false;
  public postAllow = false;
  public deleteAllow = false;
}
