/**
 * Tool to iterate over json properties and execute each one as function.
 */
export abstract class GanttCallBackStackExecuter {
  /**
   * Execution of callback stack.
   * @param {{ [key: string]: (parameter: unknown) => void }} callBackStack JSON object with stored functions.
   * @param {unknown} [parameter=undefined] Parameter which will be included into all functions.
   * @param {string[]} [order=undefined] Specific execution order as array of callback ids.
   */
  public static execute(
    callBackStack: { [key: string]: (parameter: unknown) => void },
    parameter: unknown = undefined,
    order: EGanttCallbackStackExecutionOrder = EGanttCallbackStackExecutionOrder.STANDARD
  ): void {
    if (callBackStack === undefined) {
      console.warn(`undefined callbackStack!`);
    }
    let keys = Object.keys(callBackStack);
    if (order === EGanttCallbackStackExecutionOrder.REVERSE) keys = keys.reverse();
    for (let i = 0; i < keys.length; i++) {
      try {
        callBackStack[keys[i]](parameter);
      } catch (e) {
        console.warn('Error while trying to execute callbackstack: %s', e);
      }
    }
  }

  /**
   * Executes an array of callbacks. Useful for callback Structures where a priority / order of execution is necessary.
   * @param {{ callback: { [key: string]: (parameter: unknown) => void } }[]} callBackStackArray
   * @param {unknown} [parameter=undefined]
   */
  public static executeArray(
    callBackStackArray: { callback: { [key: string]: (parameter: unknown) => void } }[],
    parameter: unknown = undefined
  ): void {
    for (const callback of callBackStackArray) {
      GanttCallBackStackExecuter.execute(callback.callback, parameter);
    }
  }
}

/**
 * Enum containing all possible orders of the execution of callback stacks.
 */
export enum EGanttCallbackStackExecutionOrder {
  STANDARD = 'standard',
  REVERSE = 'reverse',
}
