import { GanttUtilities } from '../../gantt-utilities/gantt-utilities';
import { GanttDataContainer } from '../data-structure/data-structure';
import { DataManipulator } from './data-manipulator';

export class DataEditor {
  originDataSet: GanttDataContainer;
  dataEditorUUID: string;

  constructor(dataSet) {
    this.originDataSet = dataSet;
    this.dataEditorUUID = GanttUtilities.generateUniqueID();
  }

  //////
  // Edit shift functions
  //////

  /**
   * Updates properties of shift in origin data by shift id.
   *
   * @param {GanttUpdateObjectData|GanttUpdateObjectData[]} shiftUpdateData Data structure with id and properties.
   * @param {boolean} [addUnknownProperties] Optional bool to allow adding properties that are not yet present in shift. Forbidden by default.
   * @returns {boolean} If successful, returns true, false otherwise;
   */
  updateShiftPropertiesInOriginDataByShiftId(shiftUpdateData, addUnknownProperties = false) {
    const self = this;
    let returnBool = true;
    if (!Array.isArray(shiftUpdateData)) shiftUpdateData = [shiftUpdateData];
    const editShifts = function (child, level, parent) {
      if (!shiftUpdateData.length) return; // when all shifts have been found
      for (var shift of child.shifts) {
        const foundIndex = shiftUpdateData.findIndex((shiftToUpdate) => shiftToUpdate.id === shift.id);
        if (foundIndex != -1) {
          const shiftUpdateProperties = shiftUpdateData[foundIndex].properties;
          const propertiesToUpdate = Object.getOwnPropertyNames(shiftUpdateProperties);
          for (const property of propertiesToUpdate) {
            if (!addUnknownProperties && !shift.hasOwnProperty(property)) {
              console.error("Property '" + property + "' on shift '" + shift.id + "' not found.");
              returnBool = false;
              continue;
            }
            const propertyToChange = {};
            propertyToChange[property] = shiftUpdateProperties[property];
            self.updateShiftPropertiesInOriginDataByShift(shift, propertyToChange);
          }
          shiftUpdateData.splice(foundIndex, 1);
        }
      }
    };
    DataManipulator.iterateOverDataSet(this.originDataSet.ganttEntries, { editShifts: editShifts });
    if (shiftUpdateData.length) {
      console.warn('Cant find all shifts by id.');
      returnBool = false;
    }
    return returnBool;
  }

  /**
   * Updates shift properties of given shift in origin dataset.
   *
   * @param {*} shift Shift reference of origin dataset.
   * @param {*} properties Properties to be changed. For example: {color: red, opacity: 0.8}
   */
  updateShiftPropertiesInOriginDataByShift(shift, properties) {
    for (const prop in properties) {
      shift[prop] = properties[prop];
    }
  }

  //////
  // Edit row functions
  //////

  /**
   * Updates properties of row in origin data by row id.
   *
   * @param {GanttUpdateObjectData|GanttUpdateObjectData[]} rowUpdateData Data structure with id and properties.
   * @param {boolean} [addUnknownProperties] Optional bool to allow adding properties that are not yet present in row. Forbidden by default.
   * @returns {boolean} If successful, returns true, false otherwise;
   */
  updateRowPropertiesInOriginDataByRowId(rowUpdateData, addUnknownProperties = false) {
    let returnBool = true;
    if (!Array.isArray(rowUpdateData)) rowUpdateData = [rowUpdateData];
    const editRows = function (child, level, parent) {
      if (!rowUpdateData.length) return; // when all rows have been found
      const foundIndex = rowUpdateData.findIndex((rowToUpdate) => rowToUpdate.id === child.id);
      if (foundIndex != -1) {
        const rowUpdateProperties = rowUpdateData[foundIndex].properties;
        const propertiesToUpdate = Object.getOwnPropertyNames(rowUpdateProperties);
        for (const property of propertiesToUpdate) {
          if (!addUnknownProperties && !child.hasOwnProperty(property)) {
            console.error("Property '" + property + "' on row '" + child.id + "' not found.");
            returnBool = false;
            continue;
          }
          child[property] = rowUpdateProperties[property];
        }
        rowUpdateData.splice(foundIndex, 1);
      }
    };
    DataManipulator.iterateOverDataSet(this.originDataSet.ganttEntries, { editRows: editRows });
    if (rowUpdateData.length) {
      console.warn('Cant find all rows by id.');
      returnBool = false;
    }
    return returnBool;
  }

  //////
  // Edit milestones functions
  //////

  /**
   * Updates properties of milestone in origin data by milestone id.
   *
   * @param {GanttUpdateObjectData|GanttUpdateObjectData[]} milestoneUpdateData Data structure with id and properties.
   * @param {boolean} [addUnknownProperties] Optional bool to allow adding properties that are not yet present in shift. Forbidden by default.
   * @returns {boolean} If successful, returns true, false otherwise;
   */
  updateMilestonesPropertiesInOriginDataByMilestoneId(milestoneUpdateData, addUnknownProperties = false) {
    let returnBool = true;
    if (!Array.isArray(milestoneUpdateData)) milestoneUpdateData = [milestoneUpdateData];
    const editMilestones = function (child, level, parent) {
      if (!milestoneUpdateData.length) return; // when all milestones have been found
      for (var milestone of child.milestones) {
        const foundIndex = milestoneUpdateData.findIndex((milestoneToUpdate) => milestoneToUpdate.id === milestone.id);
        if (foundIndex != -1) {
          const milestoneUpdateProperties = milestoneUpdateData[foundIndex].properties;
          const propertiesToUpdate = Object.getOwnPropertyNames(milestoneUpdateProperties);
          for (const property of propertiesToUpdate) {
            if (!addUnknownProperties && !milestone.hasOwnProperty(property)) {
              console.error("Property '" + property + "' on milestone '" + milestone.id + "' not found.");
              returnBool = false;
              continue;
            }
            milestone[property] = milestoneUpdateProperties[property];
          }
          milestoneUpdateData.splice(foundIndex, 1);
        }
      }
    };
    DataManipulator.iterateOverDataSet(this.originDataSet.ganttEntries, { editMilestones: editMilestones });
    if (milestoneUpdateData.length) {
      console.warn('Cant find all milestones by id.');
      returnBool = false;
    }
    return returnBool;
  }

  /**
   * Sets empty rows to no render.
   * No render id will be extended with a given prefix.
   * @param {*} idPrefix
   */
  setEmptyRowsToNoRender(idPrefix = '') {
    const s = this;

    const areThereShiftsToRender = function (shifts) {
      if (!shifts || !shifts.length) return false;
      for (const shift of shifts) {
        if (!shift.hasOwnProperty('noRender') || !shift.noRender.length) {
          return true;
        }
      }
      return false;
    };

    const isRowEmpty = function (rows) {
      if (!rows || !rows.length) {
        return true;
      }
      let noRenderCnt = 0;

      for (const row of rows) {
        if (isRowEmpty(row.child) && !areThereShiftsToRender(row.shifts)) {
          GanttUtilities.registerNoRenderId(row, idPrefix + s.dataEditorUUID);
          noRenderCnt++;
        } else {
          GanttUtilities.removeNoRenderId(row, idPrefix + s.dataEditorUUID);
        }
      }
      return noRenderCnt == rows.length;
    };

    isRowEmpty(this.originDataSet.ganttEntries);
  }

  /**
   * Removes no render ids set by date editor.
   * @param {*} idPrefix
   */
  removeNoRenderIdsOfDataEditor(idPrefix = '') {
    const s = this;
    const removeNoRenderIds = function (rows) {
      rows.forEach((row) => {
        GanttUtilities.removeNoRenderId(row, idPrefix + s.dataEditorUUID);
        if (row.child && row.child.length) {
          removeNoRenderIds(row.child);
        }
      });
    };
    removeNoRenderIds(s.originDataSet.ganttEntries);
  }
}
