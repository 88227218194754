import { GanttConfig } from '../../config/gantt-config';
import { DataHandler } from '../../data-handler/data-handler';
import { GanttCanvasRow } from '../../data-handler/data-structure/data-structure';
import { GanttHTMLStructureBuilder } from '../../html-structure/html-structure-builder';
import { NodeProportionsState } from '../../html-structure/node-proportion-state/node-proportion-state';
import { EGanttScrollContainer } from '../../html-structure/scroll-container.enum';
import { BestGantt } from '../../main';
import { RenderDataHandler } from '../render-data-handler';
import { RenderDataStateStorage } from '../render-data-state-storage';

/**
 * Helper class for {@link RenderDataHandler} to handle complex y axis calculations.
 */
export class YAxisRenderDataFinder {
  /**
   * @param _ganttDiagram Reference to the {@link BestGantt} instance that should be used for calculations.
   */
  constructor(private _ganttDiagram: BestGantt) {}

  /**
   * Calculates the current y position of a row in px from the top of the shift viewport.
   * @param rowId Id of the row to calculate the viewport y position for.
   * @returns Current y position of a row in px from the top of the shift viewport.
   */
  public getRowViewportY(rowId: string): number {
    let viewportOffset = 0;

    const isRowSticky = this._stateStorage.isRowSticky(rowId);
    const scrollContainerId = isRowSticky ? EGanttScrollContainer.STICKY_ROWS : EGanttScrollContainer.DEFAULT;
    viewportOffset -= this._nodeProportionsState.getScrollTopPosition(scrollContainerId);

    const rowY = this._stateStorage.getYPositionRow(rowId);
    return rowY + viewportOffset;
  }

  /**
   * Determines the row which is at the specified page y position.
   * @param pageY Page y position to determine the row for.
   * @param viewportYCorrection Optional value which will be added to the viewport y position after it was calculated from the page y position.
   * @returns Row at the specified page y position (or `undefined` if no row was found at the specified page y position).
   */
  public getRowByPageY(pageY: number, viewportYCorrection = 0): GanttCanvasRow {
    return this.getRowByViewportY(this._getViewportYByPageY(pageY, viewportYCorrection));
  }

  /**
   * Determines the row which is at the specified viewport y position.
   * @param viewportY Viewport y position to determine the row for.
   * @returns Row at the specified viewport y position (or `undefined` if no row was found at the specified viewport y position).
   */
  public getRowByViewportY(viewportY: number): GanttCanvasRow {
    const scrollContainerId = this.getScrollContainerByViewportY(viewportY);
    const scrollTop = this._nodeProportionsState.getScrollTopPosition(scrollContainerId);

    const y = viewportY + scrollTop;
    return this._stateStorage.getRowByYPosition(y, scrollContainerId);
  }

  /**
   * Determines the scroll container which is at the specified page y position.
   * @param pageY Page y position to determine the scroll container for.
   * @returns Id of the scroll container at the specified page y position (or `undefined` if the specified page y is invalid).
   */
  public getScrollContainerByPageY(pageY: number): EGanttScrollContainer {
    return this.getScrollContainerByViewportY(this._getViewportYByPageY(pageY));
  }

  /**
   * Determines the scroll container which is at the specified viewport y position.
   * @param viewportY Viewport y position to determine the scroll container for.
   * @returns Id of the scroll container at the specified viewport y position (or `undefined` if the specified viewport y is invalid).
   */
  public getScrollContainerByViewportY(viewportY: number): EGanttScrollContainer {
    const stickyRowsViewport = this._nodeProportionsState.getShiftViewPortProportions(
      EGanttScrollContainer.STICKY_ROWS
    );

    if (viewportY < 0) {
      return undefined;
    }
    if (this._ganttConfig.showStickyRows() && viewportY <= stickyRowsViewport.height) {
      return EGanttScrollContainer.STICKY_ROWS;
    }
    return EGanttScrollContainer.DEFAULT;
  }

  /**
   * Calculates the combined row heights for each scroll container.
   * @param scrollContainerIds If specified, only the combined row heights of the scroll containers with the specified ids will be calculated.
   * @returns Map containing the combined row heights for each scroll container.
   */
  public getRowHeightsCombined(
    scrollContainerIds: Iterable<EGanttScrollContainer> = undefined
  ): Map<EGanttScrollContainer, number> {
    if (!scrollContainerIds) scrollContainerIds = this._renderDataHandler.getAllScrollContainerIds();

    const rowHeightsCombined = new Map<EGanttScrollContainer, number>();
    for (const scrollContainerId of scrollContainerIds) {
      rowHeightsCombined.set(scrollContainerId, 0);
    }

    this._dataHandler.getYAxisDataset().forEach((row) => {
      if (row.sticky && rowHeightsCombined.has(EGanttScrollContainer.STICKY_ROWS)) {
        rowHeightsCombined.set(
          EGanttScrollContainer.STICKY_ROWS,
          rowHeightsCombined.get(EGanttScrollContainer.STICKY_ROWS) + row.height
        );
      }
      if (rowHeightsCombined.has(EGanttScrollContainer.DEFAULT)) {
        rowHeightsCombined.set(
          EGanttScrollContainer.DEFAULT,
          rowHeightsCombined.get(EGanttScrollContainer.DEFAULT) + row.height
        );
      }
    });

    return rowHeightsCombined;
  }

  //
  // HELPER METHODS
  //

  /**
   * Calculates the viewport y position of the gantt scroll container wrapper by the specified page y position.
   * @param pageY Page y position to determine the viewport y position for.
   * @param viewportYCorrection Optional value which will be added to the viewport y position after it was calculated from the page y position.
   * @returns Viewport y position of the gantt scroll container wrapper by the specified page y position.
   */
  private _getViewportYByPageY(pageY: number, viewportYCorrection = 0): number {
    const viewportProportions = this._htmlStructureBuilder
      .getVerticalScrollContainerOverlay()
      .node()
      .getBoundingClientRect();

    let viewportY = pageY - viewportProportions.y + viewportYCorrection;
    viewportY = Math.max(viewportY, 0);
    viewportY = Math.min(viewportY, viewportProportions.height);

    return viewportY;
  }

  //
  // GETTER & SETTER
  //

  /**
   * Helper getter which returns the HTML structure builder of the current gantt.
   */
  private get _htmlStructureBuilder(): GanttHTMLStructureBuilder {
    return this._ganttDiagram.getHTMLStructureBuilder();
  }

  /**
   * Helper getter which returns the node proportions state of the current gantt.
   */
  private get _nodeProportionsState(): NodeProportionsState {
    return this._ganttDiagram.getNodeProportionsState();
  }

  /**
   * Helper getter which returns the gantt config of the current gantt.
   */
  private get _ganttConfig(): GanttConfig {
    return this._ganttDiagram.getConfig();
  }

  /**
   * Helper getter which returns the data handler of the current gantt.
   */
  private get _dataHandler(): DataHandler {
    return this._ganttDiagram.getDataHandler();
  }

  /**
   * Helper getter which returns the render data handler of the current gantt.
   */
  private get _renderDataHandler(): RenderDataHandler {
    return this._ganttDiagram.getRenderDataHandler();
  }

  /**
   * Helper getter which returns the state storage of the render data handler of the current gantt.
   */
  private get _stateStorage(): RenderDataStateStorage {
    return this._renderDataHandler.getStateStorage();
  }
}
