import { EGanttInstance } from '@gantt/public-api';
import { GanttLibService } from 'frontend/src/dashboard/gantt/gantt/gantt-lib.service';
import { SaxMsBestGanttActiveSubmenuEntryElementSetting } from 'frontend/src/dashboard/gantt/gantt/saxms-best-gantt.settings';
import { GeneralGanttActionHandler } from 'frontend/src/dashboard/gantt/general/action-handling/action-handler';
import { GanttTemplateData } from 'frontend/src/dashboard/gantt/helper/gantt';
import { Observable, of, Subscription } from 'rxjs';
import { IGanttBlockEditNotification } from '../../../gantt-edit/gantt-edit.interface';
import { GanttEditService } from '../../../gantt-edit/gantt-edit.service';
import { ExternalGanttPlugin } from '../../external-plugin';
import { GanttPluginHandlerService } from '../../gantt-plugin-handler.service';

export const GanttBlockLoadingPlugInId = 'GanttBlockLoadingPlugInId';

/**
 * Plugin to visualize the loading progress of blocks.
 */
export class GanttBlockLoadingPlugIn extends ExternalGanttPlugin {
  private blockEditSubscription: Subscription;

  constructor(
    protected ganttPluginHandlerService: GanttPluginHandlerService,
    protected ganttLibService: GanttLibService,
    protected actionHandler: GeneralGanttActionHandler,
    private ganttEditService: GanttEditService
  ) {
    super(ganttPluginHandlerService, ganttLibService, actionHandler);
  }

  public onInit(templateData: GanttTemplateData, responseData: any): void {
    this.addPlugIn(
      GanttBlockLoadingPlugInId,
      this._ganttLibService.ganttInstanceService.getInstance(EGanttInstance.GANTT_BLOCK_LOADING)
    );
    this.blockEditSubscription = this.listenToGanttEditService();
  }

  public onDestroy(): void {
    this.blockEditSubscription?.unsubscribe();
  }

  private listenToGanttEditService(): Subscription {
    return this.ganttEditService
      .onGanttShiftBlockEditChange()
      .subscribe((notification: IGanttBlockEditNotification) => {
        if (notification.isEditable) {
          this.removeBlockLoadingByIds(notification.editedBlockIds);
        } else {
          setTimeout((_) => this.visualizeBlockLoadingByIds(notification.editedBlockIds), 0); // wait for multiple drag callback end
        }
      });
  }

  /**
   * Handles the visualization of loading shifts by the given block ids.
   * @param ids Block ids of js gantt
   */
  public visualizeBlockLoadingByIds(ids: string[]): void {
    this.getPlugInById(GanttBlockLoadingPlugInId)?.visualizeShiftLoadingByIds(ids);
  }

  /**
   * Handles removing the visualization of loading shifts by the given block ids.
   * @param ids Block ids of js gantt
   */
  public removeBlockLoadingByIds(ids: string[]): void {
    this.getPlugInById(GanttBlockLoadingPlugInId)?.removeShiftLoadingByIds(ids);
  }

  public onAction(action: any): void {}
  public executeAction(action: any): Observable<any> {
    return of();
  }
  public injectSettings(submenuElements: SaxMsBestGanttActiveSubmenuEntryElementSetting[]): void {}
}
