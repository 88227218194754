import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ILegendEntry } from './legend.interface';

/**
 * Represents a legend to display a list of legend entries with label and indicators.
 * The entries can be interacted using the mouse.
 */
@Component({
  selector: 'app-legend',
  templateUrl: './legend.component.html',
  styleUrls: ['./legend.component.scss'],
})
export class LegendComponent implements OnInit, OnDestroy {
  @Input() data: ILegendEntry[] = []; // list of legend entries to visualize
  @Input() pointerInteraction: boolean; // decides whether you can interact with the legend entries by using the mouse.

  @Output() onEntryDblClick = new EventEmitter<ILegendEntry>(); // event is triggered when a legend entry is single clicked
  @Output() onEntryClick = new EventEmitter<ILegendEntry>(); // event is triggered when a legend entry is double clicked
  initialized = false;
  private alive = true;

  constructor() {
    this.initialized = true;
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.alive = false;
  }

  /**
   * Is triggered when a legend entry is single clicked.
   */
  public click(entryData: ILegendEntry): void {
    this.onEntryClick.emit(entryData);
  }

  /**
   * Is triggered when a legend entry is double clicked.
   */
  public dblClick(entryData: ILegendEntry): void {
    this.onEntryDblClick.emit(entryData);
  }
}
