import * as Color from 'color';
import * as Rainbow from 'color-rainbow';
export interface HexAlpha {
  hex: string;
  alpha: number;
}

export class ColorScheme {
  // TODO make to lib

  /**
   * ColorScheme Class for Pretty Colors for Angular UIS
   * by MArtin Petzold 2017
   * https://www.npmjs.com/package/color-rainbow
   * https://www.npmjs.com/package/color
   */
  public colorRainbow: any;

  public numberColorRainbow: any;

  public pattern: any;
  public x = 7;
  public ColorHash: any;
  constructor() {
    /**
     * TODO Konstruktor for specifik rb sizes
     */
    this.x = 13; // primenumbers 7/11/13/17/23
    this.colorRainbow = new Rainbow(this.x);
    this.numberColorRainbow = Rainbow.create(this.x);
  }

  /**
   * @param {string} hex Color hex string containing RGB and optional alpha
   * @param {number} [alpha=1] Alpha value of result color
   * @returns {string} rgba color string
   */
  public hexToRgbA(hex: string, alpha?: number): string {
    const hexalpha: HexAlpha = this.splitHex(hex, alpha);
    const hv: number = parseInt('0x' + hexalpha.hex);
    const hs = [(hv >> 16) & 255, (hv >> 8) & 255, hv & 255].join(',');
    return 'rgba(' + hs + ',' + hexalpha.alpha + ')';
  }

  /**
   * @param {string} hex Color hex string containing RGB and optional alpha
   * @param {number} [alpha=1] Alpha value of result color
   * @returns {HexAlpha} Color object containing hex and alpha value
   */
  private splitHex(hex: string, alpha?: number): HexAlpha {
    const hexalpha: HexAlpha = {
      hex: '000000',
      alpha: 0,
    };

    // #RGB
    if (/^#[A-Fa-f0-9]{3}$/.test(hex)) {
      let c: string[] = hex.substring(1).split('');
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      hexalpha.hex = c.join('');
      hexalpha.alpha = alpha === undefined ? 1 : alpha;
    }

    // #RRGGBB
    else if (/^#[A-Fa-f0-9]{6}$/.test(hex)) {
      hexalpha.hex = hex.substring(1);
      hexalpha.alpha = alpha === undefined ? 1 : alpha;
    }

    // #RGBA
    else if (/^#[A-Fa-f0-9]{4}$/.test(hex)) {
      let c: string[] = hex.substring(1, 4).split('');
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      hexalpha.hex = c.join('');
      hexalpha.alpha = parseInt('0x' + hex.substring(4) + hex.substring(4)) / 255;
    }

    // #RRGGBBAA
    else if (/^#[A-Fa-f0-9]{8}$/.test(hex)) {
      hexalpha.hex = hex.substring(1, 7);
      hexalpha.alpha = parseInt('0x' + hex.substring(7)) / 255;
    }

    // Invalid hex string
    else {
      throw new Error(`Invalid hex string: ${hex}`);
    }

    return hexalpha;
  }

  public getRGBAColorFromString(str: string, alpha: number) {
    return this.hexToRgbA(this.StringToColor.next(str), alpha);
  }

  public getHexColorFromString(str: string) {
    return this.StringToColor.next(str);
  }

  private StringToColor = (function () {
    let instance = null;

    return {
      next: function stringToColor(str) {
        if (instance === null) {
          instance = {};
          instance.stringToColorHash = {};
          instance.nextVeryDifferntColorIdx = 0;
          instance.veryDifferentColors = [
            '#000000',
            '#00FF00',
            '#0000FF',
            '#FF0000',
            '#01FFFE',
            '#FFA6FE',
            '#FFDB66',
            '#006401',
            '#010067',
            '#95003A',
            '#007DB5',
            '#FF00F6',
            '#774D00',
            '#90FB92',
            '#0076FF',
            '#D5FF00',
            '#FF937E',
            '#6A826C',
            '#FF029D',
            '#FE8900',
            '#7A4782',
            '#7E2DD2',
            '#85A900',
            '#FF0056',
            '#A42400',
            '#00AE7E',
            '#683D3B',
            '#BDC6FF',
            '#263400',
            '#BDD393',
            '#00B917',
            '#9E008E',
            '#001544',
            '#C28C9F',
            '#FF74A3',
            '#01D0FF',
            '#004754',
            '#E56FFE',
            '#788231',
            '#0E4CA1',
            '#91D0CB',
            '#BE9970',
            '#968AE8',
            '#BB8800',
            '#43002C',
            '#DEFF74',
            '#00FFC6',
            '#FFE502',
            '#620E00',
            '#008F9C',
            '#98FF52',
            '#7544B1',
            '#B500FF',
            '#00FF78',
            '#FF6E41',
            '#005F39',
            '#6B6882',
            '#5FAD4E',
            '#A75740',
            '#A5FFD2',
            '#FFB167',
            '#009BFF',
            '#E85EBE',
          ];
        }

        if (!instance.stringToColorHash[str]) {
          // repeat colordataset if there is to many different data
          instance.nextVeryDifferntColorIdx += 1;
          instance.nextVeryDifferntColorIdx = instance.nextVeryDifferntColorIdx % instance.veryDifferentColors.length;
          instance.stringToColorHash[str] = instance.veryDifferentColors[instance.nextVeryDifferntColorIdx];
        }

        return instance.stringToColorHash[str];
      },
    };
  })();

  public getColorRainbowAsStringArray(): string[] {
    const colorStringArray: string[] = [];
    for (const colorPart of this.numberColorRainbow) {
      colorStringArray.push(this.intToRGB(colorPart.rgbNumber()));
    }
    return colorStringArray;
  }

  /**
   * @return the next Color of the Rainbow Cycle as RGBString
   */
  public getNextColorAsString(): string {
    return this.intToRGB(this.getNextColorAsNumber());
  }

  /**
   * @return the next Color of the Rainbow Cycle as RGBString
   */
  public getColorByNumberAsString(input: number): string {
    return this.intToRGB(this.getColorByNumberAsNumber(input));
  }

  /**
   * @return the next Color of the Rainbow Cycle
   * and two lighter Childcolors as RGBString Array
   */
  public getNextColorFamilyAsStrings(): string[] {
    return this.getColorFamilyAsStrings(this.getNextColor());
  }

  /**
   * @return the next Color of the Rainbow Cycle
   * and two lighter Childcolors as RGBString Array
   */
  public getNextColorFamilyAsStringsByNumber(input: number): string[] {
    return this.getColorFamilyAsStrings(this.getColorByNumber(input));
  }

  /**
   * genarates a Color Family as a RGBStringArray from an Color.js Color
   * @param color color for that the Color Family is generated
   */
  public getColorFamilyAsStrings(color: any): string[] {
    const colorSFamily = [];
    for (const colorChild of this.getColorFamily(color)) {
      colorSFamily.push(this.intToRGB(colorChild));
    }
    return colorSFamily;
  }

  /**
   *
   * @return the next Color of the Rainbow Cycle as Color.js Color
   */
  public getColorByNumber(input: number): any {
    if (this.numberColorRainbow.length < input) {
      return Color(this.numberColorRainbow[input].rgb()).desaturate(0.8);
    } else {
      return Color({ r: 0, g: 0, b: 0 });
    }
  }

  /**
   *
   * @return the next Color of the Rainbow Cycle as RGBnumber
   */
  public getColorByNumberAsNumber(input: number): number {
    return this.getColorByNumber(input);
  }

  /**
   *
   * @return the next Color of the Rainbow Cycle as RGBnumber
   */
  public getNextColorAsNumber(): number {
    return this.getNextColor().rgbNumber();
  }

  /**
   *
   * @return the next Color of the Rainbow Cycle as Color.js Color
   */
  public getNextColor(): any {
    for (let i = 0; i <= Math.round(this.x / 2) - 2; i++) {
      this.colorRainbow.next();
    }
    const color = this.colorRainbow.next();
    return Color(color.rgb()).desaturate(0.8);
  }

  /**
   * genarates a Color Family as a RGBNumberArray from an Color.js Color
   * @param color color for that the Color Family is generated
   */
  public getColorFamily(color: any): number[] {
    const colorFamily = [];
    colorFamily.push(Color(color).lighten(0.05).rgbNumber());
    colorFamily.push(Color(color).lighten(0.25).rgbNumber());
    colorFamily.push(Color(color).lighten(0.5).rgbNumber());
    return colorFamily;
  }

  /**
   *
   * @param i the RGB Color number as #ffffff double Hex per part
   */
  public intToRGB(i: number) {
    const c = (i & 0x00ffffff).toString(16).toUpperCase();
    return '#' + '00000'.substring(0, 6 - c.length) + c;
  }
}
