import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ContextMenuComponent } from './contextmenu.component';
import { TemplateButtonModule } from '../button/button.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, TemplateButtonModule, MatListModule, TranslateModule],
  declarations: [ContextMenuComponent],
  exports: [ContextMenuComponent],
})
export class ContextMenuModule {}
