import { IGanttBlock } from 'frontend/src/dashboard/gantt/general/generator/gantt-input.data';
import { GanttEssentialPlugIns } from 'frontend/src/dashboard/gantt/general/plugin/e-gantt-essential-plugins';
import { GanttPluginHandlerService } from 'frontend/src/dashboard/gantt/general/plugin/gantt-plugin-handler.service';
import { ESuperBlockVisualization } from 'frontend/src/dashboard/gantt/general/plugin/plugin-list/superblocks/superblocks.mapper';
import { GanttSuperBlocksPlugIn } from 'frontend/src/dashboard/gantt/general/plugin/plugin-list/superblocks/superblocks.plugin';

/**
 * Handles update of superblockplugin.
 */
export class GanttRowUpdateSuperblockHandler {
  private superBlockPlugin: GanttSuperBlocksPlugIn;

  constructor(private _ganttPluginHandlerService: GanttPluginHandlerService) {
    this._ganttPluginHandlerService.getNotifiedIfInitialized().subscribe((isInitialized: boolean) => {
      if (!isInitialized) {
        return;
      }
      this.superBlockPlugin = this._ganttPluginHandlerService.getEssentialPlugIn(
        GanttEssentialPlugIns.SuperBlocksPlugIn
      );
    });
  }

  /**
   * Updates the super block plugin with the given set of gantt blocks.
   *
   * @param ganttBlocks - The set of gantt blocks to update the super block plugin with.
   */
  public updateSuperBlockPlugin(ganttBlocks: Set<IGanttBlock>): void {
    if (!this.superBlockPlugin) return;
    this.superBlockPlugin.updateSuperBlockByGanttBlock(ganttBlocks);
  }

  /**
   * Removes all superblock information by given backend data of gantt block.
   * @param ganttBlocks Backend block data.
   */
  public removeFromSuperBlockPlugin(...ganttBlocks: IGanttBlock[]): void {
    if (!this.superBlockPlugin) return;
    for (const ganttBlock of ganttBlocks) {
      this.superBlockPlugin.removeFromSuperBlockByGanttBlock(ganttBlock, false);
    }
  }

  /**
   * Updates block which is not inside the gantt dataset caused
   * by combined component view of superblock plugin.
   * @param ganttBlock Backend block data.
   */
  public updateInsideSuperBlocksPlugIn(ganttBlock: IGanttBlock): boolean {
    if (!this.superBlockPlugin || !ganttBlock) return false;
    // check if there is any active superblock plugin
    const activePlugIn: any = this.superBlockPlugin.getActivePlugInExecuterData();
    if (!activePlugIn) return false;
    // check if "combined"-modus is used
    if (this.superBlockPlugin.superBlockVisualization.get(activePlugIn.id) != ESuperBlockVisualization.COMBINED)
      return false;
    // check if block is part of a superblock
    const foundOriginShift = activePlugIn.combinePlugIn.getShiftComponentById(ganttBlock.id);
    if (!foundOriginShift) return false;
    return true;
  }
}
