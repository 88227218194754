import { Component, Input, OnInit } from '@angular/core';
import { StatusBar } from './status-bar';

@Component({
  selector: 'template-statebar',
  templateUrl: './template-statebar.component.html',
  styleUrls: ['./template-statebar.component.scss'],
})
export class TemplateStatebarComponent implements OnInit {
  @Input() template: StatusBar;

  constructor() {}

  ngOnInit(): void {}
}
