import { ContentElement } from '@app-modeleditor/components/content/content-element/content-element';
import { ETemplateType } from 'frontend/src/dashboard/model/resource/template-type';
import { ColorScheme } from '../hotspot-sidebars/hotspot-colorscheme-sidebar/colorscheme-color-slider/colorscheme';
import { Polygon } from './polygon';

export enum EHotspotSidebar {
  REGION = 'region',
  COLORSCHEME = 'colorscheme',
}
export enum EHotspotPosition {
  RIGHT = 'RIGHT',
  LEFT = 'LEFT',
}

export class AttributeCategory {
  private id: string;
  private name: string;
  private colorScheme: ColorScheme;

  public getId(): string {
    return this.id;
  }
  public setId(id: string): this {
    this.id = id;
    return this;
  }

  public getName(): string {
    return this.name;
  }
  public setName(name: string): this {
    this.name = name;
    return this;
  }

  public getColorScheme(): ColorScheme {
    return this.colorScheme;
  }

  public setColorScheme(colorScheme: ColorScheme): this {
    this.colorScheme = colorScheme;
    return this;
  }
}
export class Region {
  private id: string;
  private name: string;
  private regionAttributes: RegionAttribute[];

  public getId(): string {
    return this.id;
  }
  public setId(id: string): this {
    this.id = id;
    return this;
  }

  public getName(): string {
    return this.name;
  }
  public setName(name: string): this {
    this.name = name;
    return this;
  }

  public setRegionAttributes(regionAttributes: RegionAttribute[]): this {
    this.regionAttributes = regionAttributes;
    return this;
  }

  public getRegionAttributes(): RegionAttribute[] {
    return this.regionAttributes || [];
  }

  public addRegionAttributes(...regionAttributes: RegionAttribute[]): this {
    this.regionAttributes = this.getRegionAttributes().concat(regionAttributes);
    return this;
  }
}

export class RegionAttribute {
  private id: string;
  private attributeKey: string;
  private regionKey: string;
  private value: any;
  private numValue: number;

  public getId(): string {
    return this.id;
  }

  public setId(id: string): this {
    this.id = id;
    return this;
  }

  public getValue(): any {
    return this.value;
  }

  public setValue(value: any): this {
    if (isNaN(parseInt(value))) {
      this.value = value;
    } else {
      this.value = parseInt(value);
    }
    return this;
  }

  public getAttributeKey(): string {
    return this.attributeKey;
  }

  public setAttributeKey(attributeKey: string): this {
    this.attributeKey = attributeKey;
    return this;
  }

  public getRegionKey(): string {
    return this.regionKey;
  }

  public setRegionKey(regionKey: string): this {
    this.regionKey = regionKey;
    return this;
  }
}

export class Hotspot extends ContentElement {
  private polygons: Polygon[];
  private regions: Region[];
  private attributeCategories: AttributeCategory[];
  private width = 1000; // width of the component
  private height = 500; // height of the component
  private imageUrl: string;
  private backgroundImg = '';
  private imageRatio: number;
  private savePolygons: any;
  private saveColorschemes: any;
  public additionalSubMenus: any[];
  private value: any;
  private sideBarMode: EHotspotSidebar;
  private sideBarPosition: EHotspotPosition;
  private sideBarVisible: boolean;
  private currentPolygon: Polygon;

  public getCurrentPolygon(): Polygon {
    return this.currentPolygon;
  }

  public setCurrentPolygon(currentPolygon: Polygon): void {
    this.currentPolygon = currentPolygon;
  }

  public getSideBarPosition(): EHotspotPosition {
    return this.sideBarPosition;
  }

  public setSideBarPosition(sideBarPosition: EHotspotPosition): Hotspot {
    this.sideBarPosition = sideBarPosition;
    return this;
  }

  public isSideBarVisible(): boolean {
    return this.sideBarVisible;
  }

  public setSideBarVisible(sideBarVisible: boolean): Hotspot {
    this.sideBarVisible = sideBarVisible;
    return this;
  }

  public toggleSideBarVisible(): Hotspot {
    this.sideBarVisible = !this.sideBarVisible;
    return this;
  }

  public getSideBarMode(): EHotspotSidebar {
    return this.sideBarMode;
  }

  public setSideBarMode(sideBarMode: EHotspotSidebar): void {
    this.sideBarMode = sideBarMode;
  }

  public getValue(): any {
    return this.value;
  }

  public setValue(value: any): void {
    this.value = value;
  }

  constructor() {
    super();
    this.setType(ETemplateType.HOT_SPOT_ELEMENT);
  }

  public getImageUrl(): string {
    return 'rest/' + this.imageUrl;
  }

  public setImageUrl(imageUrl: string): this {
    this.imageUrl = imageUrl;
    return this;
  }

  public getRegions(): Region[] {
    return this.regions || [];
  }

  public setRegions(regions: Region[]): this {
    this.regions = regions;
    return this;
  }

  public setAttributeCategories(attributeCategories: AttributeCategory[]): this {
    this.attributeCategories = attributeCategories;
    return this;
  }
  public getAttributeCategories(): AttributeCategory[] {
    return this.attributeCategories || [];
  }

  public getPolygons(): Polygon[] {
    return (
      this.polygons.sort(function (a, b) {
        return Number(a.getEditMode()) - Number(b.getEditMode());
      }) || []
    );
  }

  public setPolygons(polygons: Polygon[]): this {
    this.polygons = polygons;
    return this;
  }

  public addPolygons(...polygons: Polygon[]): this {
    this.polygons = this.getPolygons().concat(polygons);
    this.polygons = this.polygons.slice();
    return this;
  }

  public getWidth(): number {
    return this.width;
  }

  public setWidth(width: number): Hotspot {
    this.width = width;
    this.height = this.imageRatio ? this.imageRatio * width : this.height;
    return this;
  }

  public getHeight(): number {
    return this.height;
  }

  public setHeight(height: number): Hotspot {
    this.height = this.imageRatio ? this.imageRatio * this.width : this.height;
    // this.height = height;
    return this;
  }

  public setAdditionalSubMenus(additionalSubMenus: any[]): Hotspot {
    this.additionalSubMenus = additionalSubMenus;
    return this;
  }
  public getAdditionalSubMenus(): any[] {
    return this.additionalSubMenus;
  }

  public prepareSaveForBackend() {
    if (!this.getValue()) {
      return;
    }
    for (const dataObj of this.getValue()['dataObj']) {
      switch (dataObj.type) {
        case 'polygon':
          const polygons: any[] = dataObj.resources;
          const tempSavePolygons: any[] = [];
          for (const polygon of this.polygons) {
            const backendPolygon = polygons.find((poly) => poly.id === polygon.getId());
            if (backendPolygon) {
              backendPolygon.vertices = polygon.getVertices();
              backendPolygon.interpolationVertices = polygon.getInterpolationPoints();
              tempSavePolygons.push(backendPolygon);
            } else {
              // const newPolygon = Object.assign(polygons[0]);
              tempSavePolygons.push(polygon);
            }
          }
          dataObj.resources = tempSavePolygons;
          this.savePolygons = dataObj;
          break;
        case 'attributecategory':
          const attributecategories: any[] = dataObj.resources;
          const tempAttributecategories: any[] = [];
          for (const attributecategory of this.attributeCategories) {
            const backendAttributecategory = attributecategories.find(
              (attributecat) => attributecat.id === attributecategory.getId()
            );
            if (backendAttributecategory) {
              backendAttributecategory.colorScheme.coloredValues = attributecategory
                .getColorScheme()
                .getColoredValues()
                .slice();
              backendAttributecategory.colorScheme.colorSchemeType = attributecategory
                .getColorScheme()
                .getStyle()
                .toString()
                .toUpperCase();
              backendAttributecategory.colorScheme.opacity = attributecategory.getColorScheme().getOpacity() / 100;
              tempAttributecategories.push(backendAttributecategory);
            } else {
              // const newPolygon = Object.assign(polygons[0]);
              tempAttributecategories.push(attributecategory);
            }
          }
          dataObj.resources = tempAttributecategories;
          this.saveColorschemes = dataObj;
          break;
      }
    }
  }

  public setBackgroundImage(data, type) {
    const blob = new Blob([data], { type: type });
    const reader = new FileReader();
    const self = this;
    reader.onloadend = function (evt: any) {
      const dataurl = evt.target.result;
      self.backgroundImg = 'data:image/png;base64,' + dataurl.substr(dataurl.indexOf(',') + 1);
      const image = new Image();
      image.onload = function (evt) {
        self.imageRatio = image.height / image.width;
        self.setHeight(self.imageRatio * self.width);
      };
      image.src = self.backgroundImg;
    };
    reader.readAsDataURL(blob);
  }

  public getBackgroundImage(): string {
    return this.backgroundImg;
  }
}
