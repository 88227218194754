export enum ETimeInterval {
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  QUARTER = 'QUARTER',
}

export enum EMonthList {
  Januar,
  Februar,
  März,
  April,
  Mai,
  Juni,
  Juli,
  August,
  September,
  Oktober,
  November,
  Dezember,
}

export enum EInputOutputState {
  INIT = 'INIT',
  CHANGE = 'CHANGE',
  SYNC = 'SYNC',
}
