import { GanttColorConverter } from '../color-converter/color-converter';
import { IHSLValues } from '../color-data-types.interface';
import { EGanttColorizationRasterStrategy } from './colorization-raster-strategy.enum';

/**
 * Class which generates n colors with maximum difference to each other using HSL colors.
 */
export class GanttColorizationRaster {
  private readonly _smin = 50; // in %
  private readonly _lmin = 35; // in %
  private readonly _lmax = 75; // in %
  private readonly _hstepsmax = 36;

  private _n: number = null;
  private _rasterDimensions: IHSLValues = null;
  private _rasterStepSizes: IHSLValues = null;

  private _strategy = EGanttColorizationRasterStrategy.DEFAULT;
  private _indexMap = new Map<number, number>();
  private _indexMapperCb: (i: number, executer?: GanttColorizationRaster) => number = null;

  private _shuffleFactor = 0;

  constructor(n: number, strategy = EGanttColorizationRasterStrategy.DEFAULT) {
    this.n = n;
    this._rasterDimensions = this._getRasterDimensions();
    this._rasterStepSizes = this._getRasterStepSizes(this._rasterDimensions);

    this._strategy = strategy;
    this._updateIndexMap();
  }

  /**
   * Calculates and returns the raster color represented by the given index (as HSL color).
   * @param index Index of the color to calculate.
   * @returns Calculated color.
   */
  public getHSLColor(index: number): string {
    if (index < 0 || index >= this.n) return null;

    const hslValues = this._getHSLColorValues(index);
    if (!hslValues) return null;

    return `hsl(${hslValues.h}, ${hslValues.s}, ${hslValues.l})`;
  }

  /**
   * Calculates and returns the raster color represented by the given index (as hex color).
   * @param index Index of the color to calculate.
   * @returns Calculated color.
   */
  public getHexColor(index: number): string {
    if (index < 0 || index >= this.n) return null;

    const hslValues = this._getHSLColorValues(index);
    if (!hslValues) return null;

    return GanttColorConverter.hslToHex(hslValues);
  }

  /**
   * Calculates and returns the raster color HSL values represented by the given index.
   * @param index Index of the color to calculate.
   * @returns Calculated HSL color values.
   */
  private _getHSLColorValues(index: number): IHSLValues {
    if (index < 0 || index >= this.n) return null;
    const randomIndex = this._indexMap.get(index);

    const soffset = Math.floor(randomIndex / (this._rasterDimensions.h * this._rasterDimensions.l));
    const hloffset = randomIndex % (this._rasterDimensions.h * this._rasterDimensions.l);
    const loffset = Math.floor(hloffset / this._rasterDimensions.h);
    const hoffset = hloffset % this._rasterDimensions.h;

    const hslValues: IHSLValues = {
      h: hoffset * this._rasterStepSizes.h + this._rasterStepSizes.h / 2,
      s: this._smin + soffset * this._rasterStepSizes.s + this._rasterStepSizes.s / 2,
      l: this._lmin + loffset * this._rasterStepSizes.l + this._rasterStepSizes.l / 2,
    };
    if (this._shuffleFactor !== 0) this._shuffleHSLColorValues(hslValues);
    return hslValues;
  }

  /**
   * Redistributes and modifies generated colors.
   */
  public shuffle(): void {
    this._shuffleColors();
    this._updateIndexMap();
  }

  /**
   * Maps all possible indexes to a random value in the same range.
   */
  private _updateIndexMap(): void {
    this._indexMap.clear();

    switch (this._strategy) {
      case EGanttColorizationRasterStrategy.RANDOM:
        for (let i = 0; i < this.n; i++) {
          let randomIndex = Math.floor(Math.random() * this.n);
          let done = false;
          do {
            if (this._indexMap.has(randomIndex)) {
              randomIndex++;
              if (randomIndex >= this.n) randomIndex = 0;
            } else {
              this._indexMap.set(randomIndex, i);
              done = true;
            }
          } while (!done);
        }
        break;
      case EGanttColorizationRasterStrategy.CUSTOM:
        for (let i = 0; i < this.n; i++) {
          if (this._indexMapperCb) this._indexMap.set(i, this._indexMapperCb(i, this));
          else this._indexMap.set(i, i);
        }
        break;
      case EGanttColorizationRasterStrategy.DEFAULT:
      default:
        for (let i = 0; i < this.n; i++) this._indexMap.set(i, i);
        break;
    }
  }

  /**
   * Modifies the generated colors by changing he shuffle factor.
   */
  private _shuffleColors(): void {
    if (this._strategy !== EGanttColorizationRasterStrategy.RANDOM) return;
    this.shuffleFactor = Math.random();
  }

  /**
   * Shuffles the given HSL color values with the given shuffle factor.
   * @param hslValues HSL color values to shuffle.
   */
  private _shuffleHSLColorValues(hslValues: IHSLValues): void {
    const hshuffle = this._shuffleFactor * 360;
    const sshuffle = (this._shuffleFactor * 2 - 1) * this._rasterStepSizes.s;
    const lshuffle = (this._shuffleFactor * 2 - 1) * this._rasterStepSizes.l;

    hslValues.h += hshuffle;
    hslValues.s = Math.max(hslValues.s + sshuffle, this._smin);
    hslValues.l = Math.max(Math.min(hslValues.l + lshuffle, this._lmax), this._lmin);
  }

  /**
   * Calculates and returns the step counts of each HSL color dimension.
   * @returns Step counts of each HSL color dimension.
   */
  private _getRasterDimensions(): IHSLValues {
    const rasterDimensions: IHSLValues = { h: 1, s: 1, l: 1 };
    let done = false;

    do {
      // if cuboid with current dimensions can represent all n colors -> use these dimensions
      if (rasterDimensions.h * rasterDimensions.s * rasterDimensions.l >= this.n) {
        done = true;
      }
      // else -> increase dimension values and check again
      else {
        if (rasterDimensions.h < this._hstepsmax) {
          rasterDimensions.h++;
        } else {
          rasterDimensions.s += 1;
          rasterDimensions.l += 1;
        }
      }
    } while (!done);

    return rasterDimensions;
  }

  /**
   * Calculates and returns the step sizes of each HSL color dimension.
   * @returns Step sizes of each HSL color dimension.
   */
  private _getRasterStepSizes(rasterDimensions: IHSLValues): IHSLValues {
    const rasterStepSizes: IHSLValues = { h: 0, s: 0, l: 0 };
    rasterStepSizes.h = 360 / rasterDimensions.h;
    rasterStepSizes.s = (100 - this._smin) / rasterDimensions.s;
    rasterStepSizes.l = (100 - this._lmin - (100 - this._lmax)) / rasterDimensions.l;
    return rasterStepSizes;
  }

  /**
   * Maps the specified index into the assigned color index.
   * @param i Index to be mapped.
   * @returns Assigned color index.
   */
  public getColorIndexByIndex(i: number): number {
    if (i < 0 || i >= this.n) return undefined;
    return this._indexMap.get(i);
  }

  /**
   * Maps the specified color index into the assigned index.
   * @param i Color index to be mapped.
   * @returns Assigned index.
   */
  public getIndexByColorIndex(i: number): number {
    if (i < 0 || i >= this.n) return undefined;
    for (const entry of this._indexMap.entries()) {
      if (entry[1] === i) return entry[0];
    }
  }

  /**
   * Amount of colors to be calculated.
   */
  public get n(): number {
    return this._n;
  }

  /**
   * Amount of colors to be calculated.
   */
  private set n(value: number) {
    if (value < 0) value = 0;
    this._n = value;
  }

  /**
   * Color index mapping strategy.
   */
  public get strategy(): EGanttColorizationRasterStrategy {
    return this._strategy;
  }

  /**
   * Callback which is used for custom index-to-color-index mapping.
   */
  public set indexMapperCb(cb: (i: number, executer?: GanttColorizationRaster) => number) {
    if (this._strategy !== EGanttColorizationRasterStrategy.CUSTOM) return;
    this._indexMapperCb = cb;
    this._updateIndexMap();
  }

  /**
   * Factor to manipulate color generation (0 <= shuffleFactor <= 1).
   */
  private set shuffleFactor(value: number) {
    if (value < 0) value = 0;
    if (value > 1) value = 1;
    this._shuffleFactor = value;
  }
}
