import { GanttUtilities } from '../../gantt-utilities/gantt-utilities';
import { GanttShiftDataMapper } from '../data-mapper/shift-data-mapper';
import { GanttCanvasShift, GanttDataRow, GanttDataShift } from '../data-structure/data-structure';
import { DataManipulator } from '../data-tools/data-manipulator';
import { ShiftDataSorting } from '../data-tools/data-sorting';
import { GanttRowHeightStorage } from '../row-height-storage';
import { YAxisDataFinder } from './yaxis-data-finder';

/**
 * Data filtering and handling for shift data.
 * @keywords shift, data, dataset, find, query, search, filter, recursive, iterative, manipulation
 */
export abstract class ShiftDataFinder {
  /**
   * Generates shift canvas dataset out of origin data.
   * @keywords shift, origin, canvas, data, dataset, generate, init, extract
   * @param dataSet Origin dataset.
   * @param d3XScale D3 function for scaling x axis.
   * @param shiftHeight Height of gantt shifts.
   * @param paddingTop Difference between top shift and top row y position.
   * @param paddingBottom Difference between bottom shift and bottom row y position.
   * @param rowHeightStorage Storage of individual row heights.
   * @param shiftRowStorage If specified this shift row storage will be used to store the row id for each shift.
   * @return Shift canvas dataset.
   */
  public static getCanvasShiftData(
    dataSet: GanttDataRow[],
    d3XScale: d3.ScaleTime<number, number>,
    paddingTop: number,
    paddingBottom: number,
    rowHeightStorage: GanttRowHeightStorage,
    shiftRowStorage?: Map<string, string>
  ): GanttCanvasShift[] {
    const newCanvasShiftDataSet = [];

    let globalYPos = 0;

    // go recursively through dataset to find all open shifts
    const getRenderShifts = function (child, level, parent) {
      if ((level == 0 || parent.open) && (!child.noRender || !child.noRender.length)) {
        const rowHeight = rowHeightStorage.getRowHeightById(child.id);
        child.shifts.forEach((shift) => {
          const shiftHeight = shift.isFullHeight ? rowHeight : rowHeight - paddingTop - paddingBottom;
          const yPos = shift.isFullHeight ? globalYPos : globalYPos + paddingTop;
          const newShift = GanttShiftDataMapper.mapOriginToCanvas(shift, shiftHeight, d3XScale, yPos, level);
          shiftRowStorage?.set(shift.id, child.id);
          newCanvasShiftDataSet.push(newShift);
        });
        globalYPos += rowHeight;
      }
    };
    DataManipulator.iterateOverDataSet(dataSet, { getAllShifts: getRenderShifts });

    return newCanvasShiftDataSet;
  }

  /**
   * Returns all shifts of all childs of one row dataset.
   * @keywords shift, origin, data, dataset, row, yaxis, y axis, row, parentid
   * @param {GanttDataRow} parentData Row dataset.
   * @param {number} y Vertical position of row.
   * @param {number} depth Depth of row.
   * @param {scale} d3XScale D3 function for scaling x axis.
   * @param {number} shiftHeight height of gantt shifts.
   * @param {number} paddingTop Difference between top shift and top row y position.
   * @param {number} paddingBottom Difference between bottom shift and bottom row y position.
   * @return {GanttDataShift[]} Shift list.
   */
  static getNewShiftsByParent(parentData, y, depth, d3XScale, paddingTop, paddingBottom, rowHeightStorage) {
    const shifts = [];
    let globalYPos = y;

    parentData.child.forEach((child) => {
      if (child.shifts && (!child.noRender || !child.noRender.length)) {
        const rowHeight = rowHeightStorage.getRowHeightById(child.id);
        child.shifts.forEach((shift) => {
          const shiftHeight = shift.isFullHeight ? rowHeight : rowHeight - paddingTop - paddingBottom;
          const yPos = shift.isFullHeight ? globalYPos : globalYPos + paddingTop;
          const newShift = GanttShiftDataMapper.mapOriginToCanvas(shift, shiftHeight, d3XScale, yPos, depth);
          shifts.push(newShift);
        });
        globalYPos += rowHeight;
      }
    });
    return shifts;
  }

  /**
   * Returns canvas shift by id from given canvas dataset.
   * @keywords shift, canvas, data, dataset, id
   * @param {GanttDataRow} canvasDataset Shift canvas dataset.
   * @param {string} id Id of shfit.
   * @return {GanttDataShift[]} All Shifts with matching id (should be only one).
   */
  static getCanvasShiftById(canvasDataset, id) {
    return canvasDataset.filter(function (shiftData) {
      return shiftData.id == id;
    });
  }

  /**
   * Returns canvas shifts by arry of ids from given canvas dataset.
   * @keywords shift, canvas, data, dataset, id
   * @param {GanttDataRow} canvasDataset Shift canvas dataset.
   * @param {string[]} ids Ids of shfits.
   * @return {GanttDataShift[]} All Shifts with matching id.
   */
  static getCanvasShiftsByIds(canvasDataset: GanttCanvasShift[], ids: string[]): GanttCanvasShift[] {
    return canvasDataset.filter(function (shiftData) {
      return ids.includes(shiftData.id);
    });
  }

  /**
   * Deletes given shifts by id from shift canvas list.
   * @keywords remove, canvas, shift, shiftid, id
   * @param {GanttDataRow} canvasDataset Shift canvas dataset.
   * @param {string[]} ids Id list of shifts.
   */
  static removeCanvasShiftsById(canvasDataset, ids) {
    ids = JSON.parse(JSON.stringify(ids));

    for (let i = 0; i < canvasDataset.length; i++) {
      const index = ids.indexOf(canvasDataset[i].id);
      if (index != -1) {
        canvasDataset = canvasDataset.splice(i, 1);
        ids = ids.splice(index, 1);
        if (ids.length == 0) break;
        i--;
      }
    }
  }

  /**
   * Returns found shift and child in which shift is stored by shift id.
   * @keywords shift, origin, data, dataset, shiftid, id, find
   * @param {GanttDataRow[]} dataSet Origin dataset in which the search will be executed.
   * @param {string} id Shift id.
   * @return {IFoundShiftData}
   */
  static getShiftById(dataSet: GanttDataRow[], id: string): IFoundShiftData {
    for (const shiftRow of dataSet) {
      const shift = shiftRow.shifts.find((shift) => shift.id == id);
      if (shift) {
        return { shift, shiftRow };
      } else {
        const found = ShiftDataFinder.getShiftById(shiftRow.child, id);
        if (found) {
          return found;
        }
      }
    }
  }

  /**
   * Returns found shifts with each child in which the shift is stored by shift ids.
   * It's more performant to use this function once instead of <i>getShiftById</i> muliple times.
   * @keywords shift, shifts, origin, data, dataset, shiftid, id, find
   * @param {GanttDataRow[]} dataSet Origin dataset in which the search will be executed.
   * @param {string[]} id Shift id list.
   * @return {IFoundShiftData[]}
   */
  static getShiftsByIds(dataSet: GanttDataRow[], ids: string[]): IFoundShiftData[] {
    const foundShifts: IFoundShiftData[] = [];
    const idSet = new Set(ids);

    const getShifts = (rows: GanttDataRow[]): void => {
      if (!idSet.size) return;
      for (const shiftRow of rows) {
        const shifts = shiftRow.shifts.filter((shift) => idSet.has(shift.id));
        if (shifts.length) {
          shifts.forEach((shift) => {
            foundShifts.push({ shift, shiftRow });
            idSet.delete(shift.id);
          });
        }
        getShifts(shiftRow.child);
      }
    };

    getShifts(dataSet);
    return foundShifts;
  }

  /**
   * Returns first found shift and child in which shift is stored by shift property value.
   * @keywords shift, origin, data, dataset, find, property, additional, data
   * @param {GanttDataRow[]} dataSet Origin dataset in which the search will be executed.
   * @param {string} property Name of JSON property.
   * @param {any} value Value of JSON property.
   * @return {IFoundShiftData}
   */
  static getShiftByProperty(dataSet, property, value): IFoundShiftData {
    const foundShift: IFoundShiftData = { shift: null, shiftRow: null };

    const findShiftByProperty = function (child, level, parent) {
      for (let i = 0; i < child.shifts.length; i++) {
        if (child.shifts[i][property] == value) {
          // store matching shift and its parent
          foundShift.shift = child.shifts[i];
          foundShift.shiftRow = child;
          break;
        }
      }
    };
    DataManipulator.iterateOverDataSet(dataSet, { findShiftByProperty: findShiftByProperty });
    return foundShift;
  }

  /**
   * Removes shift from origin dataset by shift id.
   * @keywords remove, origin, data, dataset, shift, shiftid, id
   * @param {GanttDataRow[]} dataSet Origin dataset.
   * @param {string} shiftId Shift id.
   * @return {IFoundShiftData} Removed shift data.
   */
  static removeShiftById(dataSet: GanttDataRow[], shiftId: string): IFoundShiftData {
    for (const shiftRow of dataSet) {
      const foundIndex = shiftRow.shifts.findIndex((shift) => shift.id == shiftId);
      if (foundIndex !== -1) {
        const shift = shiftRow.shifts[foundIndex];
        shiftRow.shifts.splice(foundIndex, 1);
        return { shift, shiftRow };
      } else {
        const found = ShiftDataFinder.removeShiftById(shiftRow.child, shiftId);
        if (found) {
          return found;
        }
      }
    }
  }

  /**
   * Removes multiple shifts from origin dataset by shift ids.
   * @keywords remove, origin, data, dataset, shift, shifts, shiftid, id
   * @param {GanttDataRow[]} dataSet Origin dataset.
   * @param {string[]} shiftIds Shift ids.
   * @param {boolean} [cloneShiftIdList=true] If true, this method will use a cloned shift id array (because the array will be manipulated).
   * @return {GanttDataShift[]} List of Removed shift data.
   */
  static removeShiftsById(dataSet: GanttDataRow[], shiftIdsToRemove: string[]): GanttDataShift[] {
    const shiftIds = {};
    for (const id of shiftIdsToRemove) {
      shiftIds[id] = true;
    }
    const removedShifts: GanttDataShift[] = [];

    const getShifts = (rows: GanttDataRow[]): void => {
      if (!rows.length) return;
      for (const shiftRow of rows) {
        shiftRow.shifts = shiftRow.shifts.filter((shift) => {
          if (shiftIds[shift.id]) {
            removedShifts.push(shift);
            return false;
          }
          return true;
        });

        getShifts(shiftRow.child || []);
      }
    };

    getShifts(dataSet);

    return removedShifts;
  }

  /**
   * Puts list of shifts to another child/row.
   * @keywords change, row, shift, shifts, id, y axis, yaxis
   * @param {GanttDataRow[]} dataSet Origin dataset.
   * @param {string[]} newParentId Shift ids.
   * @param {string} shiftId
   * @returns {IFoundShiftData}
   */
  static changeShiftParentById(dataSet, newParentId, shiftId): IFoundShiftData {
    // 1. find shift and remove it from its old parent
    const translatedShift = this.removeShiftById(dataSet, shiftId);

    if (!translatedShift) {
      console.warn('No shiftParent with id: ' + shiftId + ' was found!');
      return;
    }

    // 2. add shift to new parent
    const newParent = YAxisDataFinder.getRowById(dataSet, newParentId).data;
    newParent.shifts.push(translatedShift.shift);
    ShiftDataSorting.sortJSONListByDate(newParent.shifts, 'timePointStart');
    return translatedShift;
  }

  /**
   * Hides all shifts which are not in the specified time period (by setting a noRender id).
   * @param dataSet Dataset to filter.
   * @param timePeriod Timespan in which all shifts should be visible.
   * @param noRenderId noRender id to set.
   */
  static filterShiftsByTimespan(
    dataSet: GanttDataRow[],
    timePeriod: { start: Date; end: Date },
    noRenderId: string
  ): void {
    const filterShifts = (row: GanttDataRow) => {
      if (!row.shifts || row.shifts.length <= 0) return;
      row.shifts.forEach((shift) => {
        if (
          shift.timePointStart.getTime() > timePeriod.end.getTime() ||
          shift.timePointEnd.getTime() < timePeriod.start.getTime()
        )
          GanttUtilities.registerNoRenderId(shift, noRenderId);
      });
    };
    DataManipulator.iterateOverDataSet(dataSet, { filterShifts: filterShifts });
  }

  /**
   * Removes a noRender id from all shifts in the specified dataset.
   * @param dataSet Dataset to remove the noRender id from.
   * @param noRenderId noRender id to remove.
   */
  static removeShiftNoRender(dataSet: GanttDataRow[], noRenderId: string): void {
    const unfilterShifts = (row: GanttDataRow) => {
      if (!row.shifts || row.shifts.length <= 0) return;
      row.shifts.forEach((shift) => {
        GanttUtilities.removeNoRenderId(shift, noRenderId);
      });
    };
    DataManipulator.iterateOverDataSet(dataSet, { unfilterShifts: unfilterShifts });
  }
}

/**
 * Tupel which saves GanttDataShift and GanttDataRow.
 * @interface
 */
export interface IFoundShiftData {
  shift: GanttDataShift;
  shiftRow: GanttDataRow;
}
