import { Injectable } from '@angular/core';
import { IGanttResourcePlan } from 'frontend/src/dashboard/gantt/general/generator/gantt-input.data';
import { GanttTemplateDataService } from 'frontend/src/dashboard/gantt/general/template-data/gantt-template-data.service';
import { Observable, Subject } from 'rxjs';
import { ResourcesBackendToFrontendMapper } from './backend-to-frontend-mapper';
import { IResourceElement } from './components/resource-element/resource-element.interface';
import { IResourceGroup } from './components/resource-element/resource-group.inerface';
import { IResourceListView } from './components/resource-list/resource-list-view.interface';
import { IStatusLegendEntry } from './components/status-legend/status-legend.interface';
import { IResourceFilter } from './resource-filter.interface';
import { IResourcesData } from './resources-data.interface';

@Injectable()
export class ResourcesDataService {
  private _resourcesData: IResourcesData = null;
  private _currentViews: IResourceListView[] = [];
  private _currentActiveFilter: IResourceFilter = { attribute: 'Alle', query: '' };
  private _applyColorization = false;
  private _colorizeWorker = true;
  private _colorizeMachine = true;
  private _allAttributes: string[] = [];
  private _resourceFilterDataChangeSubject: Subject<IResourceFilter> = new Subject();
  private _favoritesChangeSubject: Subject<void> = new Subject();
  private _backendToFrontendMapper = new ResourcesBackendToFrontendMapper();
  private _favorites: string[] = [];

  constructor(private _ganttTemplateDataService: GanttTemplateDataService) {
    // this.resourcesData = this._generateDummyData();
  }

  public destroy(): void {
    this._resourcesData = null;
    this._currentViews = [];
    this._currentActiveFilter = { attribute: 'Alle', query: '' };
    this._applyColorization = false;
    this._colorizeWorker = true;
    this._colorizeMachine = true;
    this._allAttributes = [];
    this._favorites = [];
  }

  public listenForFilterDataChange(): Observable<IResourceFilter> {
    return this._resourceFilterDataChangeSubject.asObservable();
  }

  public listenForFavoritesChange(): Observable<void> {
    return this._favoritesChangeSubject.asObservable();
  }

  public getResourceGroupByResourceId(resourceId: string): IResourceGroup {
    if (!this._resourcesData || !this._resourcesData.resources) {
      return null;
    }
    for (const group of this._resourcesData.resources) {
      const searchResult = group.elements.find((resource) => resource.id === resourceId);
      if (searchResult) {
        return group;
      }
    }
    return null;
  }

  public getCurrentListViewByGroupId(id: string): IResourceListView {
    return this._currentViews.find((view) => view.resourceGroupId === id);
  }

  public setCurrentListViewAttributeByGroupId(id: string, attribute: string): void {
    this.getCurrentListViewByGroupId(id).currentGroupedBy = attribute;
  }

  public setCollapsedByGroupId(id: string, collapsed: boolean): void {
    this.getCurrentListViewByGroupId(id).collapsed = collapsed;
  }

  public setBackendData(backendResourcesData: IGanttResourcePlan): void {
    this.resourcesData = this._backendToFrontendMapper.mapDataSet(
      backendResourcesData,
      this._ganttTemplateDataService.getTemplateData().getResourceAttributeMapping()
    );
  }

  public addFavorite(resourceElementId: string): void {
    if (this.isFavorite(resourceElementId)) return;
    this._favorites.push(resourceElementId);
    this._favoritesChangeSubject.next();
  }

  public removeFavorite(resourceElementId: string): void {
    this._favorites = this._favorites.filter((id) => id != resourceElementId);
    this._favoritesChangeSubject.next();
  }

  public isFavorite(resourceElementId: string): boolean {
    return !!this._favorites.find((id) => id === resourceElementId);
  }

  public getResourceById(resourceId: string): IResourceElement {
    for (const group of this.resources) {
      for (const elem of group.elements) {
        if (elem.id == resourceId) {
          return elem;
        }
      }
    }
    return null;
  }

  private _getAllResourceAttributesByData(data: IResourcesData): string[] {
    const allAttributes: string[] = [];
    const resourceAttributeMapping = this._ganttTemplateDataService.getTemplateData().getResourceAttributeMapping();
    for (const index in resourceAttributeMapping) {
      allAttributes.push(resourceAttributeMapping[index].localization);
    }
    return allAttributes;
  }

  private _generateViewsByResourceData(data: IResourcesData): IResourceListView[] {
    const views: IResourceListView[] = [];

    // push favorites
    views.push({
      resourceGroupId: 'favoritesGroupId',
      currentGroupedBy: null,
      collapsed: false,
    });

    data.resources.forEach((group) => {
      const data: IResourceListView = {
        resourceGroupId: group.id,
        currentGroupedBy: null,
        collapsed: false,
      };
      views.push(data);
    });
    return views;
  }

  set resourcesData(data: IResourcesData) {
    this._currentViews = this._generateViewsByResourceData(data);
    this._allAttributes = this._getAllResourceAttributesByData(data);
    this._resourcesData = data;
  }

  get resourcesData(): IResourcesData {
    return this._resourcesData;
  }

  set resourceFilterQuery(query: string) {
    this._currentActiveFilter.query = query;
    this._resourceFilterDataChangeSubject.next(this._currentActiveFilter);
  }

  set resourceFilterAttribute(attribute: string) {
    this._currentActiveFilter.attribute = attribute;
    this._resourceFilterDataChangeSubject.next(this._currentActiveFilter);
  }

  get currentActiveResourceFilter(): IResourceFilter {
    return this._currentActiveFilter;
  }

  get applyColorization(): boolean {
    return this._applyColorization;
  }

  set applyColorization(bool: boolean) {
    this._applyColorization = bool;
  }

  get colorizeWorker(): boolean {
    return this._colorizeWorker;
  }
  set colorizeWorker(bool: boolean) {
    this._colorizeWorker = bool;
  }

  get colorizeMachine(): boolean {
    return this._colorizeMachine;
  }
  set colorizeMachine(bool: boolean) {
    this._colorizeMachine = bool;
  }

  get statusLegendData(): IStatusLegendEntry[] {
    return this._resourcesData?.statusLegendData;
  }

  get resources(): IResourceGroup[] {
    return this._resourcesData?.resources;
  }

  get allAttributes(): string[] {
    return this._allAttributes;
  }

  get favorites(): string[] {
    return this._favorites;
  }
}
