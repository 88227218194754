import { inject, Injectable, Injector } from '@angular/core';
import { NumericRangePicker } from '@app-modeleditor/components/button-slider/numeric-range-picker';
import { NumericRangePickerAdapter } from '@app-modeleditor/components/button-slider/numeric-range-picker-adapter.service';
import { Button } from '@app-modeleditor/components/button/button';
import { ButtonAdapter } from '@app-modeleditor/components/button/button-adapter.service';
import { SaxMSCalendar } from '@app-modeleditor/components/calendar/cal';
import { CalendarAdapter } from '@app-modeleditor/components/calendar/calendar-adapter.service';
import { Colorpicker } from '@app-modeleditor/components/color-picker/colorpicker';
import { ColumnListSelectorAdater } from '@app-modeleditor/components/column-list-selector/column-list-selector-adapter.service';
import { Content } from '@app-modeleditor/components/content/content';
import { ContentElement } from '@app-modeleditor/components/content/content-element/content-element';
import { ContentAdapter } from '@app-modeleditor/components/content/content-factory.service';
import { ContentPart } from '@app-modeleditor/components/content/content-part/content-part';
import { ContentPartAdapter } from '@app-modeleditor/components/content/content-part/content-part-adapter.service';
import { ContextMenu } from '@app-modeleditor/components/contextmenu/contextmenu';
import { AutocompleteTextfield } from '@app-modeleditor/components/elements/autocomplete-textfield';
import { LabeledText } from '@app-modeleditor/components/elements/labeled-text/labeled-text';
import { LabeledTextAdapter } from '@app-modeleditor/components/elements/labeled-text/labeled-text-adapter.service';
import { NumericInput } from '@app-modeleditor/components/elements/numeric-input/numeric-input';
import { NumericInputAdapter } from '@app-modeleditor/components/elements/numeric-input/numeric-input-adapter.service';
import { EntryCollectionAdapter } from '@app-modeleditor/components/entry-collection/entry-collection-adapter.service';
import { EntryElement } from '@app-modeleditor/components/entry-collection/entry-element';
import { EntryElementValue } from '@app-modeleditor/components/entry-collection/entry-element-value';
import { EntryElementFactory } from '@app-modeleditor/components/entry-collection/entry-factory.service';
import { TextField } from '@app-modeleditor/components/entry-collection/text-field/text-field';
import { TextFieldAdapter } from '@app-modeleditor/components/entry-collection/text-field/text-field-adapter.service';
import { ComboBox } from '@app-modeleditor/components/entry-element/combo-box';
import { EmptyElement } from '@app-modeleditor/components/entry-element/empty-element/empty-element';
import { FileUploader } from '@app-modeleditor/components/fileuploader-old/fileuploader';
import { IndexPage } from '@app-modeleditor/components/index-register/index-page';
import { IndexPageAdapter } from '@app-modeleditor/components/index-register/index-page-adapter.service';
import { IndexRegister } from '@app-modeleditor/components/index-register/index-register';
import { IndexRegisterAdapter } from '@app-modeleditor/components/index-register/index-register-adapter.service';
import { LightboxAdapter } from '@app-modeleditor/components/lightbox/lighbtox-adapter.service';
import { Wizard } from '@app-modeleditor/components/lightbox/predefined/wizard/wizard';
import { WizardAdapter } from '@app-modeleditor/components/lightbox/predefined/wizard/wizard-factory.service';
import { ListSelector } from '@app-modeleditor/components/list-selector/list-selector';
import { ListSelectorAdapterService } from '@app-modeleditor/components/list-selector/list-selector-adapter.service';
import { TemplateMap } from '@app-modeleditor/components/location-map/location-map';
import { LocationMapAdapter } from '@app-modeleditor/components/location-map/location-map-adapter.service';
import { Page } from '@app-modeleditor/components/page/page';
import { RangePicker } from '@app-modeleditor/components/range-picker/range-picker';
import { RangePickerAdapter } from '@app-modeleditor/components/range-picker/range-picker-factory.service';
import { RichTextEditor } from '@app-modeleditor/components/rich-text-editor/rich-text-editor';
import { RichTextEditorAdapter } from '@app-modeleditor/components/rich-text-editor/rich-text-editor-factory.service';
import { SelectionBox } from '@app-modeleditor/components/selection-box/selection-box';
import { MultiObjectSelector } from '@app-modeleditor/components/selector/multi-object-selector';
import { MultiObjectSelectorAdapter } from '@app-modeleditor/components/selector/multi-object-selector-adapter.service';
import { Spinner } from '@app-modeleditor/components/spinner/spinner';
import { Table } from '@app-modeleditor/components/spreadsheet/model/table';
import { TableAdapter } from '@app-modeleditor/components/spreadsheet/model/table-adapter.service';
import { ContentElementAdapter } from '@app-modeleditor/components/structure/content-element-adapter.service';
import { TemplateChat } from '@app-modeleditor/components/template-chat/template-chat';
import { TemplateChatAdapter } from '@app-modeleditor/components/template-chat/template-chat-adapter.service';
import { Datepicker } from '@app-modeleditor/components/template-datepicker/datepicker';
import { DatepickerAdapter } from '@app-modeleditor/components/template-datepicker/datepicker-adapter.service';
import { TemplateDurationPicker } from '@app-modeleditor/components/template-duration-picker/template-duration-picker';
import { TemplatePickerAdapter } from '@app-modeleditor/components/template-picker/template-picker-factory.service';
import { ProgressBar } from '@app-modeleditor/components/template-progressbar/progressbar';
import { ProgressBarAdapter } from '@app-modeleditor/components/template-progressbar/progressbar-factory.service';
import { Sidebar } from '@app-modeleditor/components/template-sidebar/sidebar';
import { SidebarAdapter } from '@app-modeleditor/components/template-sidebar/sidebar-adapter.service';
import { StatusBar } from '@app-modeleditor/components/template-statebar/status-bar';
import { StatusBarAdapter } from '@app-modeleditor/components/template-statebar/status-bar-adapter.service';
import { StatusBarIconAdapter } from '@app-modeleditor/components/template-statebar/status-bar-icon-adapter.service';
import { StatusIcon } from '@app-modeleditor/components/template-statebar/status-icon';
import { WidgetContainer } from '@app-modeleditor/components/template-widget/widget-container';
import { WidgetContainerAdapter } from '@app-modeleditor/components/template-widget/widget-container-adapter.service';
import { Tile } from '@app-modeleditor/components/tile-button/tile';
import { TileButtonAdapter } from '@app-modeleditor/components/tile-button/tile-button-adapter.service';
import { MenuAdapter } from '@app-modeleditor/components/tree/menu-factory.service';
import { Store } from '@ngxs/store';
import { GanttTemplateData } from 'frontend/src/dashboard/gantt/helper/gantt';
import { GanttAdapter } from 'frontend/src/dashboard/gantt/helper/gantt-adapter.service';
import { EDatatype } from 'frontend/src/dashboard/model/entry/datatype.enum';
import { Template } from 'frontend/src/dashboard/model/resource/template';
import { ETemplateType } from 'frontend/src/dashboard/model/resource/template-type';
import { MenuItem } from 'frontend/src/dashboard/view/template-toolbar/menu-item';
import { ToolbarAdapter } from 'frontend/src/dashboard/view/template-toolbar/toolbar-factory.service';
import { Toolbar } from '../../view/template-toolbar/toolbar';
import { ContentHeader } from '../components/content/content-header';
import { EntryCollection } from '../components/entry-collection/entry-collection';
import { Hotspot } from '../components/hotspot/hotspot/hotspot';
import { ColumnListSelector } from './../components/column-list-selector/column-list-selector';
import { ContextMenuAdapter } from './../components/contextmenu/context-menu-adapter.service';
import { PieChart } from './../components/elements/pie-chart/pie-chart';
import { EFieldType } from './../components/entry-collection/field-type.enum';
import { FileViewer } from './../components/file-viewer/file-viewer';
import { Lightbox } from './../components/lightbox/lightbox';
import { TemplatePicker } from './../components/template-picker/template-picker';
import { FooterElement } from './footer-element';
import { TemplateResourceAdapter } from './template-resource-factory.service';

@Injectable({
  providedIn: 'root',
})
export class TemplateAdapter {
  private readonly store: Store = inject(Store);
  constructor(private _injector: Injector) {}

  getType(item: any, values: any) {
    switch (item.type) {
      case ETemplateType.ENTRY_COLLECTION:
      case ETemplateType.CONTENT_HEADER:
        return this._injector.get(EntryCollectionAdapter).applyValues(this, item, values);
      case ETemplateType.TWO_COLUMN_LIST_SELECTOR:
        return this._injector.get(ColumnListSelectorAdater).applyValues(this, item, values);
      case ETemplateType.CALENDAR:
        return this._injector.get(CalendarAdapter).applyValues(this, item, values);
      case ETemplateType.RANGE_PICKER:
        return this._injector.get(RangePickerAdapter).applyValues(this, item, values);
      case ETemplateType.STATUS_BAR:
        return this._injector.get(StatusBarIconAdapter).applyValues(this, item, values);
      case ETemplateType.LIST_SELECTOR:
        return this._injector.get(ListSelectorAdapterService).applyValues(this, item, values);
      case ETemplateType.PROGRESS_BAR:
      case ETemplateType.SIDE_BAR:
      case ETemplateType.MENU:
      case ETemplateType.LIGHTBOX:
      case ETemplateType.TOOLBAR:
      case ETemplateType.PAGE:
      case ETemplateType.WIZARD:
      case ETemplateType.TOOLBAR_GROUP:
      case ETemplateType.TOOLBAR_ITEM:
      case ETemplateType.GANTT:
      case ETemplateType.FOOTER_ELEMENT:
      case ETemplateType.STATUS_ICON:
      case ETemplateType.CONTENT:
      case ETemplateType.CONTENT_PART:
      case ETemplateType.ENTRY_ELEMENT_COLLECTION:
      case ETemplateType.MENU_ITEM:
      case ETemplateType.HIERARCHIC_MENU_ITEM:
      case ETemplateType.WIDGET_CONTAINER:
      case ETemplateType.TOOLBAR_ELEMENT:
      case ETemplateType.TILE:
      case ETemplateType.ENTRY_ELEMENT:
      case ETemplateType.INDEX_REGISTER:
      case ETemplateType.INDEX_PAGE:
      case ETemplateType.TABLE:
      case ETemplateType.CONTEXT_MENU:
      case ETemplateType.CONTEXT_MENU_ITEM:
      case ETemplateType.FILE_VIEWER:
      case ETemplateType.MAP:
      default:
        return item.setValue(values);
    }
  }

  public applyValues<T extends Template>(item: T, values: any) {
    return this.getType(item, values);
  }

  public adapt<T extends Template>(data: any): T {
    if (!data) {
      return null;
    }

    // if (data.id === 'template.entrycollection.addressBar') {
    //   data.type = ETemplateType.BREADCRUMBS;
    // }

    // this.store.dispatch(new TemplateActions.AddTemplate(JSON.parse(JSON.stringify(data))));
    let t: Template;
    switch (data.type) {
      case ETemplateType.SIDE_BAR:
        t = this._injector.get(SidebarAdapter).inheritFrom<Sidebar>(this, Sidebar, data);
        break;
      case ETemplateType.MENU:
        t = this._injector.get(MenuAdapter).parseMenu(data, this);
        break;
      case ETemplateType.MENU_ELEMENT:
        t = this._injector.get(MenuAdapter).parseMenu(data, this);
        t.setType(ETemplateType.MENU);
        break;
      case ETemplateType.LIGHTBOX:
        t = this._injector.get(LightboxAdapter).inheritFrom<Lightbox>(this, Lightbox, data);
        break;
      case ETemplateType.TOOLBAR:
        t = this._injector.get(ToolbarAdapter).inheritFrom<Toolbar>(this, Toolbar, data);
        break;
      case ETemplateType.PAGE:
        t = this._injector
          .get(ContentElementAdapter)
          .inheritFrom<Page>(this, Page, data)
          .setContent(this.adapt(data.content));
        break;
      case ETemplateType.CALENDAR:
        t = this._injector.get(CalendarAdapter).inheritFrom<SaxMSCalendar>(this, SaxMSCalendar, data);
        break;
      case ETemplateType.WIZARD:
        t = this._injector.get(WizardAdapter).parseWizard<Wizard>(Wizard, data);
        break;
      case ETemplateType.TOOLBAR_GROUP:
        t = this._injector.get(ToolbarAdapter).parseToolbarGroup(this, data);
        break;
      case ETemplateType.TOOLBAR_ITEM:
        t = this._injector
          .get(ToolbarAdapter)
          .parseToolbarItem(this, this._injector.get(ButtonAdapter).inheritFrom<MenuItem>(this, MenuItem, data), data);
        break;
      case ETemplateType.GANTT:
        t = this._injector.get(GanttAdapter).inheritFrom<GanttTemplateData>(this, GanttTemplateData, data);
        break;
      case ETemplateType.FOOTER_ELEMENT:
        t = this._injector
          .get(ContentElementAdapter)
          .inheritFrom<FooterElement>(this, FooterElement, data)
          .setContentElement(this.adapt(data.contentElement))
          .setFooterElement(data.footerElement);
        break;
      case ETemplateType.STATUS_BAR:
        t = this._injector.get(StatusBarAdapter).inheritFrom<StatusBar>(this, StatusBar, data);
        break;
      case ETemplateType.STATUS_ICON:
        t = this._injector.get(StatusBarIconAdapter).inheritFrom<StatusIcon>(this, StatusIcon, data);
        break;
      case ETemplateType.CONTENT:
        t = this._injector.get(ContentAdapter).inheritFrom<Content>(this, Content, data);
        break;
      case ETemplateType.CONTENT_PART:
        t = this._injector.get(ContentPartAdapter).inheritFrom<ContentPart>(this, ContentPart, data);
        break;
      case ETemplateType.CONTENT_HEADER:
        t = this._injector.get(EntryCollectionAdapter).inheritFrom<ContentHeader>(this, ContentHeader, data);
        break;
      case ETemplateType.ENTRY_ELEMENT_COLLECTION:
      case ETemplateType.ENTRY_COLLECTION:
      case ETemplateType.BREADCRUMBS:
        if (data.displayType === 'TOOLBAR') {
          t = this._injector.get(ToolbarAdapter).inheritFrom<Toolbar>(this, Toolbar, {
            id: data.id,
            menuItems: [
              {
                // icon: 'home',
                id: 'generic-item',
                groups: [
                  {
                    elements: data.entryElements,
                    index: 0,
                    name: 'Bar',
                    type: 'ToolbarGroup',
                    uuid: 'group-generic-1',
                  },
                ],
                name: '',
                toolbarItemType: 'GROUP',
                type: 'ToolbarItem',
                uuid: 'generic-0-3-4',
              },
            ],
            type: 'Toolbar',
            uuid: data.uuid,
          });
          const toolbar = t as Toolbar;
          toolbar.setActiveMenuItem(toolbar.getMenuItems()[0]);
        } else {
          t = this._injector.get(EntryCollectionAdapter).inheritFrom<EntryCollection>(this, EntryCollection, data);
        }
        break;
      case ETemplateType.MENU_ITEM:
      case ETemplateType.HIERARCHIC_MENU_ITEM:
        t = this._injector.get(MenuAdapter).parseHMI(data, this);
        break;
      case ETemplateType.WIDGET_CONTAINER:
        t = this._injector.get(WidgetContainerAdapter).inheritFrom<WidgetContainer>(this, WidgetContainer, data);
        break;
      case ETemplateType.TOOLBAR_ELEMENT:
      case ETemplateType.TILE:
      case ETemplateType.ENTRY_ELEMENT:
        t = this.getEntryElement(data);
        break;
      case ETemplateType.CHAT:
        t = this._injector.get(TemplateChatAdapter).inheritFrom<TemplateChat>(this, TemplateChat, data);
        break;
      case ETemplateType.INDEX_REGISTER:
        t = this._injector.get(IndexRegisterAdapter).inheritFrom<IndexRegister>(this, IndexRegister, data);
        break;
      case ETemplateType.INDEX_PAGE:
        t = this._injector.get(IndexPageAdapter).inheritFrom<IndexPage>(this, IndexPage, data);
        break;
      case ETemplateType.LIST_SELECTOR:
        t = this._injector.get(ListSelectorAdapterService).inheritFrom<ListSelector>(this, ListSelector, data);
        break;
      case ETemplateType.TABLE:
        t = this._injector.get(TableAdapter).inheritFrom<Table>(this, Table, data);
        break;
      case ETemplateType.HOT_SPOT_ELEMENT:
        t = this._injector
          .get(ContentElementAdapter)
          .inheritFrom<Hotspot>(this, Hotspot, data)
          .setAdditionalSubMenus(data.additionalSubMenus);
        break;
      case ETemplateType.CONTEXT_MENU:
        t = this._injector.get(ContextMenuAdapter).inheritFrom<ContextMenu>(this, ContextMenu, data);
        break;
      case ETemplateType.CONTEXT_MENU_ITEM:
        t = this._injector.get(ButtonAdapter).inheritFrom<Button>(this, Button, data);
        break;
      case ETemplateType.FILE_VIEWER:
        t = this._injector.get(ContentElementAdapter).inheritFrom<FileViewer>(this, FileViewer, data);
        break;
      case ETemplateType.TWO_COLUMN_LIST_SELECTOR:
        t = this._injector
          .get(ColumnListSelectorAdater)
          .inheritFrom<ColumnListSelector>(this, ColumnListSelector, data);
        break;
      case ETemplateType.MAP:
        t = this._injector.get(LocationMapAdapter).inheritFrom<TemplateMap>(this, TemplateMap, data);
        break;
      case ETemplateType.RANGE_PICKER:
        t = this._injector.get(RangePickerAdapter).inheritFrom<RangePicker>(this, RangePicker, data);
        break;
      case ETemplateType.PROGRESS_BAR:
        t = this._injector.get(ProgressBarAdapter).inheritFrom<ProgressBar>(this, ProgressBar, data);
        break;
      default:
        console.error('NOT FOUND!', data);
        t = this._injector.get(TemplateResourceAdapter).inheritFrom<ContentElement>(this, ContentElement, data);
    }
    return t as T;
  }

  private getEntryElement(data: any): EntryElement {
    let t: EntryElement = this._injector.get(EntryElementFactory).inheritFrom<EntryElement>(this, EntryElement, data);
    switch (data.fieldType || data.toolbarElementType) {
      case EFieldType.GANTT_DATE_SELECTOR:
        break;
      case EFieldType.LABELED_TEXT:
        t = this._injector.get(LabeledTextAdapter).inheritFrom<LabeledText>(this, LabeledText, data);
        break;
      case EFieldType.EMPTY_ELEMENT:
        t = this._injector.get(EntryElementFactory).inheritFrom<EmptyElement>(this, EmptyElement, data);
        break;
      case EFieldType.DATE_PICKER:
      case EFieldType.DATE_TIME_PICKER:
      case EFieldType.TIME_PICKER:
      case EFieldType.RANGE_PICKER:
        t = this._injector.get(DatepickerAdapter).inheritFrom<Datepicker>(this, Datepicker, data);
        break;
      case EFieldType.NUMERIC_RANGE_PICKER:
        t = this._injector
          .get(NumericRangePickerAdapter)
          .inheritFrom<NumericRangePicker>(this, NumericRangePicker, data);
        break;
      case EFieldType.PIE_CHART:
        t = this._injector.get(EntryElementFactory).inheritFrom<PieChart>(this, PieChart, data);
        break;
      case EFieldType.SPINNER:
        t = this._injector.get(EntryElementFactory).inheritFrom<Spinner>(this, Spinner, data);
        break;
      case EFieldType.FILE_UPLOAD:
        t = this._injector
          .get(EntryElementFactory)
          .inheritFrom<FileUploader>(this, FileUploader, data)
          .setAccept(data.accept);
        break;
      case EFieldType.TEXT_FIELD:
        if (
          data.datatype === 'Number' ||
          data.datatype === 'Float' ||
          data.datatype === 'Long' ||
          data.datatype === 'Double' ||
          data.datatype === 'Integer' ||
          data.datatype === 'Long' ||
          data.datatype === EDatatype.DOUBLE ||
          data.datatype === EDatatype.FLOAT ||
          data.datatype === EDatatype.INTEGER ||
          data.datatype === EDatatype.LONG
        ) {
          t = this._injector.get(NumericInputAdapter).inheritFrom<NumericInput>(this, NumericInput, data);
          break;
        } else {
          t = this._injector.get(TextFieldAdapter).inheritFrom<TextField>(this, TextField, data);
          break;
        }
      case EFieldType.AUTOCOMPLETE_TEXT_FIELD:
        t = this._injector
          .get(EntryElementFactory)
          .inheritFrom<AutocompleteTextfield>(this, AutocompleteTextfield, data);
        break;
      case EFieldType.DURATION_PICKER:
        t = this._injector
          .get(EntryElementFactory)
          .inheritFrom<TemplateDurationPicker>(this, TemplateDurationPicker, data)
          .setDurationPickerValues(data.durationPickerValues);
        break;
      case EFieldType.RICH_TEXT_EDITOR:
        t = this._injector.get(RichTextEditorAdapter).parseEditor<RichTextEditor>(RichTextEditor, data);
        break;
      case EFieldType.COMBO_BOX:
        t = this._injector.get(EntryElementFactory).inheritFrom<ComboBox>(this, ComboBox, data);
        break;
      case EFieldType.FILE_SYSTEM_ELEMENT:
        t = this._injector.get(TileButtonAdapter).inheritFrom<Tile>(this, Tile, data);
        break;
      case EFieldType.SELECTION_BOX:
        t = this._injector.get(EntryElementFactory).inheritFrom<SelectionBox>(this, SelectionBox, data);
        break;
      case EFieldType.SLIDE_TOGGLE:
      case EFieldType.BUTTON_SLIDER:
        t.setFieldType(EFieldType.SLIDE_TOGGLE).setValue(new EntryElementValue().setValue(!!data.entry?.value));
        break;
      case EFieldType.TOGGLE_BUTTON:
      case EFieldType.BUTTON:
      case EFieldType.RESOURCE_SELECTOR:
      case EFieldType.WEB_LINK:
        t = this._injector.get(ButtonAdapter).inheritFrom<Button>(this, Button, data);
        break;
      case EFieldType.MULTI_OBJECT_SELECTOR:
      case EFieldType.OBJECT_SELECTOR:
        t = this._injector
          .get(MultiObjectSelectorAdapter)
          .inheritFrom<MultiObjectSelector>(this, MultiObjectSelector, data);
        break;
      case EFieldType.COLOR_PICKER:
        t = this._injector
          .get(EntryElementFactory)
          .inheritFrom<Colorpicker>(this, Colorpicker, data)
          .setPossibileValues(data.possibleValues)
          .setDefaultColor(data.defaultColor || '#4b8c8f')
          .setResetColor(data.resetColor);
        break;
      case EFieldType.CALENDAR_MONTH_PICKER:
      case EFieldType.CALENDAR_WEEK_PICKER:
      case EFieldType.CALENDAR_YEAR_PICKER:
        t = this._injector.get(TemplatePickerAdapter).parse<TemplatePicker>(TemplatePicker, data);
        break;
    }
    return t;
  }
}
