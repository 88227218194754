<!-- header -->
<h2
  (click)="onLightboxClicked($event)"
  class="dialog-part"
  #lightboxHeader
  mat-dialog-title
  [cdkDragDisabled]="!lightbox.isDraggable()"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragBoundary=".cdk-global-overlay-wrapper"
  [cdkDragFreeDragPosition]="dragPosition"
>
  <span cdkDragHandle class="text">{{ lightbox.getHeadline() || lightbox.getName() | translate }}</span>
  <span class="content-spacer"></span>
  <app-button [data]="openNewWindowBtn" *ngIf="lightbox.isCanOpenExternalWindow()"></app-button>
  <app-button *ngIf="!lightbox.isDisableClose()" [data]="closeLightboxBtn"></app-button>
</h2>
<!-- content -->
<mat-dialog-content
  (click)="onLightboxClicked($event)"
  class="dialog-part"
  #content
  [style.height]="containerHeight"
  [style.max-height]="containerMaxHeight"
>
  <div
    *ngIf="lightbox.getContent()"
    class="content"
    [ngClass]="lightbox.getContent().getResizeMode() ? [lightbox.getContent().getResizeMode()] : []"
  >
    <template-slot *ngIf="content" (afterInit)="afterInitSlot($event, 0)"></template-slot>
  </div>
</mat-dialog-content>
<!-- actions -->
<mat-dialog-actions
  (click)="onLightboxClicked($event)"
  class="dialog-part"
  #lightboxFooter
  *ngIf="lightbox.NumberOfButtons > 0"
>
  <!-- <div class="content" #vcActions></div> -->
  <template-slot (afterInit)="afterInitSlot($event, 1)"></template-slot>
</mat-dialog-actions>
