import { Content } from '@app-modeleditor/components/content/content';
import { EntryCollection } from '@app-modeleditor/components/entry-collection/entry-collection';
import { EntryElementValue } from '@app-modeleditor/components/entry-collection/entry-element-value';
import { Stackable } from '@app-modeleditor/components/stackable/stackable';
import { ELogicalOperator } from '../../../../../modeleditor/components/filter-element/logical-operator.enum';
import { CONDITIONAL_ENTRY_COLLECTION_ID, LOGICAL_ENTRY_COLLECTION_ID } from './filter-constants';
import { EditFilterLightbox } from './edit-filter-lightbox';
import { IFilterNode } from './filter-tree';

export class FilterResult {
  constructor(private scope?: EditFilterLightbox) {}

  public getTextualResult(): void {}

  public restore(result: IFilterNode, node: Stackable, logic?: string): Stackable {
    if (Array.isArray(result)) {
      // add condition here

      this.scope.addCondition(node, result);
      const el: EntryCollection = node
        .getContent()
        .getContentParts()[0]
        .getContentElements<EntryCollection[]>()
        .find((e: EntryCollection) => e.getId() === CONDITIONAL_ENTRY_COLLECTION_ID);
      el.getEntryElements()[0].getValue<EntryElementValue>().setValue(result[0]); // attribute
      const op: EntryElementValue = this.scope.filterOperators.find(
        (o: EntryElementValue) => o.getValue() === result[1]
      );
      el.getEntryElements()[1].getValue<EntryElementValue>().setValue(op); // operator
      el.getEntryElements()[2].getValue<EntryElementValue>().setValue(result[2]); // input field 1 (value/from)
      el.getEntryElements()[3].getValue<EntryElementValue>().setValue(result[3]); // input field 2 (to)

      if (logic) {
        const lOp: EntryElementValue = this.scope.logicalOperators.find(
          (l: EntryElementValue) => l.getValue() === logic
        );
        this.scope.addLogicalOperator(node, lOp);
      }
      return;
    }

    if (logic) {
      const lOp: EntryElementValue = this.scope.logicalOperators.find((l: EntryElementValue) => l.getValue() === logic);
      this.scope.addLogicalOperator(node, lOp);
    }

    if (result) {
      Object.keys(result).forEach((logicalOperator: string) => {
        const groups: any[] = result[logicalOperator];

        groups.forEach((g: IFilterNode, index: number) => {
          let s: Stackable = node;
          const op: string = index === groups.length - 1 ? null : logicalOperator;

          if (groups.length !== 1 || Array.isArray(groups[0])) {
            s = this.scope.getStackable();
            node.setChildren(node.getChildren().concat(s));
          }

          // create stackable for each element in group
          this.restore(g, s, op);
        });
      });
    }

    return node;
  }

  /**
   * save result as tree of logical operations
   * @param cur Stackable
   * @returns IFilterNode
   */
  public save(cur: Stackable): IFilterNode {
    if (cur.getChildren().length === 0) {
      return null;
    }

    // const r: IFilterNode = {};
    let lastOperator: string = null;
    let node: IFilterNode = null;
    let curNode: IFilterNode = null;

    cur.getChildren().forEach((child: Stackable, index: number) => {
      const childResult: IFilterNode = this.save(child);

      if (child.getContent()) {
        lastOperator = this.getLogicOperator(child) ? this.getLogicOperator(child).getValue() : lastOperator;
      }

      curNode = this.createOrExtendsAND(curNode, child, childResult);

      if (lastOperator === ELogicalOperator.OR) {
        if (!node) {
          node = {};
          node[ELogicalOperator.OR] = [];
        }

        node[ELogicalOperator.OR].push(curNode);
        delete node[ELogicalOperator.AND];

        curNode = null;
      }

      if (index === cur.getChildren().length - 1) {
        if (node && curNode) {
          node[ELogicalOperator.OR].push(curNode);
        }
      }
    });
    if (node) {
      return node;
    }
    if (curNode) {
      return curNode;
    }
    // return r;
  }

  private createOrExtendsAND(n: IFilterNode, s: Stackable, childResult: IFilterNode) {
    if (!n) {
      n = {};
      n[ELogicalOperator.AND] = [];
    }
    n[ELogicalOperator.AND].push(childResult || this.addCondition(s));
    return n;
  }

  private addCondition(n: Stackable) {
    const condition: EntryCollection = this.getCondition(n);
    if (!condition) {
      return null;
    }
    return [
      condition.getEntryElements()[0].getValue<EntryElementValue>().getValue(), // attribute
      condition.getEntryElements()[1].getValue<EntryElementValue>().getValue<EntryElementValue>().getValue(), // operator
      condition.getEntryElements()[2].getValue<EntryElementValue>().getValue(), // input field 1 (value/from)
      condition.getEntryElements()[3].getValue<EntryElementValue>().getValue(), // input field 2 (to)
    ];
  }

  private getEntries(node: Stackable): EntryCollection[] {
    const content: Content = node.getContent();
    if (!content) {
      return [];
    }
    return content.getContentParts()[0].getContentElements<EntryCollection[]>();
  }

  private getCondition(node: Stackable): EntryCollection {
    const operator: EntryCollection = this.getEntries(node).find(
      (e: EntryCollection) => e.getId() === CONDITIONAL_ENTRY_COLLECTION_ID
    );
    return operator;
  }

  private getLogic(node: Stackable): EntryCollection {
    const operator = this.getEntries(node).find((e: EntryCollection) => e.getId() === LOGICAL_ENTRY_COLLECTION_ID);
    return operator;
  }

  private getLogicOperator(node: Stackable): EntryElementValue {
    // get the second element in the collection -> combo box
    return this.getLogic(node)
      ? this.getLogic(node).getEntryElements()[1].getValue<EntryElementValue>().getValue()
      : null;
  }

  private setKey(r: IFilterNode, key: string, cond?: any): IFilterNode {
    if (!r[key]) {
      r[key] = [];
    }

    if (cond) {
      r[key].push(cond);
    }
    return r;
  }
}
