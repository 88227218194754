import { HttpParams } from '@angular/common/http';
import { Type } from '@angular/core';
import { EValidationRules } from '@app-modeleditor/components/button/action/validation-rules.enum';
import { ERequestMethod } from '@app-modeleditor/request.service';
import { Observable } from 'rxjs';
import { CancelAction } from './cancel-action.service';
import { CancelLightboxAction } from './cancel-lightbox-action.service';
import { CheckButtonAction } from './check-button-action.service';
import { CheckExternalConditionAction } from './check-external-conditions-action.service';
import { CheckLocalCondition } from './check-local-condition-action.service';
import { CloneAction } from './clone-action.service';
import { CloseContentAction } from './close-content-action.service';
import { CloseLightboxAction } from './close-lightbox-action.service';
import { ConditionalAction } from './conditional-action.service';
import { CreateAction } from './create-action.service';
import { CustomAction } from './custom-action.service';
import { CustomListenerAction } from './custom-listener-action.service';
import { DeleteAction } from './delete-action.service';
import { DiscardChangesAction } from './discard-changes-action.service';
import { DiscardChangesByIdAction } from './discard-changes-by-id-action.service';
import { DownloadFileAction } from './download-file-action.service';
import { ExecuteExperimentAction } from './execute-experiment-action.service';
import { ExecuteSolverRunAction } from './execute-solver-run-action.service';
import { GenericAction } from './generic-action.service';
import { HotspotAction } from './hotspot-action.service';
import { LocalAction } from './local-action.service';
import { LogoutAction } from './logout-action.service';
import { MarkForSaveAction } from './mark-for-save-action.service';
import { NavigateTreeAction } from './navigate-tree-action.service';
import { OpenConfirmDeleteDialogAction } from './open-confirm-delete-dialog-action.service';
import { OpenContentPageAction } from './open-content-page-action.service';
import { OpenLightboxAction } from './open-lightbox-action.service';
import { OpenToolbarGroupAction } from './open-toolbar-group-action.service';
import { OpenWebLinkAction } from './open-web-link-action.service';
import { OpenWindowAction } from './open-window-action.service';
import { OpenWithCreateNameDialogAction } from './open-with-create-name-dialog-action.service';
import { OpenWizardAction } from './open-wizard-action.service';
import { PrintAction } from './print-action.service';
import { RefreshElementAction } from './refresh-element-action.service';
import { ReloadTreeAction } from './reload-tree-action.service';
import { ResetSettingsAction } from './reset-settings-action.service';
import { SaveAction } from './save-action.service';
import { SetCurrentDateAction } from './set-current-date-action.service';
import { ShowMessageAction } from './show-message-action.service';
import { SsoAction } from './sso-action.service';
import { SsoAuthenticateAction } from './sso-authenticate-action.service';
import { TestAction } from './test-action.service';
import { ToggleEditModeAction } from './toggle-edit-mode-action.service';
import { ToggleFavoriteToolbarElementAction } from './toggle-favorite-toolbar-element-action.service';
import { ToggleMessageCenterAction } from './toggle-message-center-action.service';
import { TriggerLoadingIndicatorAction } from './trigger-loading-indicator-action.service';
import { UpdateElementAction } from './update-element-action.service';
import { UpdateGanttAction } from './update-gantt-action.service';
import { UploadFileAction } from './upload-file-action.service';
import { ValidateAction } from './validate-action.service';

export enum ID {
  print = 'print',
  test = 'test',
  save = 'save',
  sso = 'sso',
  sso_silent = 'sso_silent',
  logout = 'logout',
  generic = 'generic',
  upload_file = 'uploadFile',
  download_file = 'downloadFile',
  conditional_action = 'conditionalAction',
  execute_solver_run = 'executeSolverRun',
  execute_experiment = 'executeExperiment',
  navigate_tree = 'navigateTree',
  open_lightbox = 'openLightbox',
  open_wizard = 'openWizard',
  open_window = 'openWindow',
  toggle_edit_mode = 'toggleEditMode',
  update_element = 'update_element',
  refresh_element = 'refresh_element',
  cancel = 'cancel',
  delete = 'delete',
  reset_settings = 'resetSettings',
  clone = 'clone',
  close_lightbox = 'close_lightbox',
  cancel_lightbox = 'cancel_lightbox',
  open_toolbar_group = 'open_toolbar_group',
  toggle_favorite_toolbar_element = 'toggle_favorite_toolbar_element',
  toggle_message_center = 'toggle_message_center',
  open_web_link = 'open_web_link',
  discard_changes = 'discard_changes',
  close_content = 'close_content',
  create = 'create',
  hotspot = 'hotspot',
  local = 'local',
  set_current_date = 'set_current_date',
  reload_tree = 'reload_tree',
  update_gantt = 'update_gantt',
  custom_listener = 'custom_listener',
  show_message = 'show_message',
  open_content_page = 'open_content_page',
  custom = 'custom',
  open_with_create_name_dialog = 'open_with_create_name_dialog',
  open_confirm_delete_dialog = 'open_confirm_delete_dialog',
  check_local_condition = 'check_local_condition',
  check_button = 'check_button',
  check_external_condition = 'check_external_condition',
  validate = 'validate',
  discard_changes_by_id = 'discard_changes_by_id',
  trigger_loading_indicator = 'trigger_loading_indicator',
  mark_for_save = 'mark_for_save',
}

export type ActionClass<T extends ID> = T extends ID.open_confirm_delete_dialog
  ? OpenConfirmDeleteDialogAction
  : T extends ID.check_external_condition
  ? CheckExternalConditionAction
  : T extends ID.check_button
  ? CheckButtonAction
  : T extends ID.check_local_condition
  ? CheckLocalCondition
  : T extends ID.reload_tree
  ? ReloadTreeAction
  : T extends ID.open_with_create_name_dialog
  ? OpenWithCreateNameDialogAction
  : T extends ID.custom
  ? CustomAction
  : T extends ID.sso
  ? SsoAction
  : T extends ID.sso_silent
  ? SsoAuthenticateAction
  : T extends ID.show_message
  ? ShowMessageAction
  : T extends ID.open_content_page
  ? OpenContentPageAction
  : T extends ID.custom_listener
  ? CustomListenerAction
  : T extends ID.update_gantt
  ? UpdateGanttAction
  : T extends ID.open_web_link
  ? OpenWebLinkAction
  : T extends ID.set_current_date
  ? SetCurrentDateAction
  : T extends ID.hotspot
  ? HotspotAction
  : T extends ID.local
  ? LocalAction
  : T extends ID.discard_changes
  ? DiscardChangesAction
  : T extends ID.close_content
  ? CloseContentAction
  : T extends ID.create
  ? CreateAction
  : T extends ID.print
  ? PrintAction
  : T extends ID.save
  ? SaveAction
  : T extends ID.logout
  ? LogoutAction
  : T extends ID.cancel
  ? CancelAction
  : T extends ID.delete
  ? DeleteAction
  : T extends ID.clone
  ? CloneAction
  : T extends ID.generic
  ? GenericAction
  : T extends ID.upload_file
  ? UploadFileAction
  : T extends ID.download_file
  ? DownloadFileAction
  : T extends ID.conditional_action
  ? ConditionalAction
  : T extends ID.update_element
  ? UpdateElementAction
  : T extends ID.refresh_element
  ? RefreshElementAction
  : T extends ID.execute_solver_run
  ? ExecuteSolverRunAction
  : T extends ID.execute_experiment
  ? ExecuteExperimentAction
  : T extends ID.navigate_tree
  ? NavigateTreeAction
  : T extends ID.open_lightbox
  ? OpenLightboxAction
  : T extends ID.open_window
  ? OpenWindowAction
  : T extends ID.open_wizard
  ? OpenWizardAction
  : T extends ID.open_toolbar_group
  ? OpenToolbarGroupAction
  : T extends ID.toggle_message_center
  ? ToggleMessageCenterAction
  : T extends ID.toggle_favorite_toolbar_element
  ? ToggleFavoriteToolbarElementAction
  : T extends ID.cancel_lightbox
  ? CancelLightboxAction
  : T extends ID.reset_settings
  ? ResetSettingsAction
  : T extends ID.toggle_edit_mode
  ? ToggleEditModeAction
  : T extends ID.close_lightbox
  ? CloseLightboxAction
  : T extends ID.test
  ? TestAction
  : T extends ID.validate
  ? ValidateAction
  : T extends ID.discard_changes_by_id
  ? DiscardChangesByIdAction
  : T extends ID.trigger_loading_indicator
  ? TriggerLoadingIndicatorAction
  : T extends ID.mark_for_save
  ? MarkForSaveAction
  : never;

type ActionClassType<T extends ID> = Type<ActionClass<T>>;

// type Foo<T extends ID> = Parameters<Type<FunctionType<T>>

export type ExecutionParams<T extends ID> = Parameters<ActionClass<T>['execute']>;

export type FunctionType<T extends ID> = ReturnType<ActionClass<T>['execute']>;

interface ActionSet<T extends ID> {
  loadService: () => Promise<ActionClassType<T>>;
}

const ACTIONS = new Map<ID, ActionSet<ID>>()
  .set(ID.hotspot, { loadService: () => import('./hotspot-action.service').then((m) => m.HotspotAction) })
  .set(ID.sso, { loadService: () => import('./sso-action.service').then((m) => m.SsoAction) })
  .set(ID.sso_silent, {
    loadService: () => import('./sso-authenticate-action.service').then((m) => m.SsoAuthenticateAction),
  })
  .set(ID.refresh_element, {
    loadService: () => import('./refresh-element-action.service').then((m) => m.RefreshElementAction),
  })
  .set(ID.check_external_condition, {
    loadService: () => import('./check-external-conditions-action.service').then((m) => m.CheckExternalConditionAction),
  })
  .set(ID.check_button, { loadService: () => import('./check-button-action.service').then((m) => m.CheckButtonAction) })
  .set(ID.check_local_condition, {
    loadService: () => import('./check-local-condition-action.service').then((m) => m.CheckLocalCondition),
  })
  .set(ID.open_confirm_delete_dialog, {
    loadService: () =>
      import('./open-confirm-delete-dialog-action.service').then((m) => m.OpenConfirmDeleteDialogAction),
  })
  .set(ID.open_with_create_name_dialog, {
    loadService: () =>
      import('./open-with-create-name-dialog-action.service').then((m) => m.OpenWithCreateNameDialogAction),
  })
  .set(ID.custom, { loadService: () => import('./custom-action.service').then((m) => m.CustomAction) })
  .set(ID.show_message, { loadService: () => import('./show-message-action.service').then((m) => m.ShowMessageAction) })
  .set(ID.open_content_page, {
    loadService: () => import('./open-content-page-action.service').then((m) => m.OpenContentPageAction),
  })
  .set(ID.custom_listener, {
    loadService: () => import('./custom-listener-action.service').then((m) => m.CustomListenerAction),
  })
  .set(ID.update_gantt, { loadService: () => import('./update-gantt-action.service').then((m) => m.UpdateGanttAction) })
  .set(ID.reload_tree, { loadService: () => import('./reload-tree-action.service').then((m) => m.ReloadTreeAction) })
  .set(ID.set_current_date, {
    loadService: () => import('./set-current-date-action.service').then((m) => m.SetCurrentDateAction),
  })
  .set(ID.create, { loadService: () => import('./create-action.service').then((m) => m.CreateAction) })
  .set(ID.close_content, {
    loadService: () => import('./close-content-action.service').then((m) => m.CloseContentAction),
  })
  .set(ID.discard_changes, {
    loadService: () => import('./discard-changes-action.service').then((m) => m.DiscardChangesAction),
  })
  .set(ID.open_web_link, {
    loadService: () => import('./open-web-link-action.service').then((m) => m.OpenWebLinkAction),
  })
  .set(ID.local, { loadService: () => import('./local-action.service').then((m) => m.LocalAction) })
  .set(ID.print, { loadService: () => import('./print-action.service').then((m) => m.PrintAction) })
  .set(ID.test, { loadService: () => import('./test-action.service').then((m) => m.TestAction) })
  .set(ID.save, { loadService: () => import('./save-action.service').then((m) => m.SaveAction) })
  .set(ID.open_toolbar_group, {
    loadService: () => import('./open-toolbar-group-action.service').then((m) => m.OpenToolbarGroupAction),
  })
  .set(ID.toggle_message_center, {
    loadService: () => import('./toggle-message-center-action.service').then((m) => m.ToggleMessageCenterAction),
  })
  .set(ID.toggle_favorite_toolbar_element, {
    loadService: () =>
      import('./toggle-favorite-toolbar-element-action.service').then((m) => m.ToggleFavoriteToolbarElementAction),
  })
  .set(ID.cancel_lightbox, {
    loadService: () => import('./cancel-lightbox-action.service').then((m) => m.CancelLightboxAction),
  })
  .set(ID.close_lightbox, {
    loadService: () => import('./close-lightbox-action.service').then((m) => m.CloseLightboxAction),
  })
  .set(ID.reset_settings, {
    loadService: () => import('./reset-settings-action.service').then((m) => m.ResetSettingsAction),
  })
  .set(ID.delete, { loadService: () => import('./delete-action.service').then((m) => m.DeleteAction) })
  .set(ID.cancel, { loadService: () => import('./cancel-action.service').then((m) => m.CancelAction) })
  .set(ID.clone, { loadService: () => import('./clone-action.service').then((m) => m.CloneAction) })
  .set(ID.update_element, {
    loadService: () => import('./update-element-action.service').then((m) => m.UpdateElementAction),
  })
  .set(ID.generic, { loadService: () => import('./generic-action.service').then((m) => m.GenericAction) })
  .set(ID.conditional_action, {
    loadService: () => import('./conditional-action.service').then((m) => m.ConditionalAction),
  })
  .set(ID.upload_file, { loadService: () => import('./upload-file-action.service').then((m) => m.UploadFileAction) })
  .set(ID.open_lightbox, {
    loadService: () => import('./open-lightbox-action.service').then((m) => m.OpenLightboxAction),
  })
  .set(ID.open_wizard, { loadService: () => import('./open-wizard-action.service').then((m) => m.OpenWizardAction) })
  .set(ID.open_window, { loadService: () => import('./open-window-action.service').then((m) => m.OpenWindowAction) })
  .set(ID.toggle_edit_mode, {
    loadService: () => import('./toggle-edit-mode-action.service').then((m) => m.ToggleEditModeAction),
  })
  .set(ID.navigate_tree, {
    loadService: () => import('./navigate-tree-action.service').then((m) => m.NavigateTreeAction),
  })
  .set(ID.execute_solver_run, {
    loadService: () => import('./execute-solver-run-action.service').then((m) => m.ExecuteSolverRunAction),
  })
  .set(ID.execute_experiment, {
    loadService: () => import('./execute-experiment-action.service').then((m) => m.ExecuteExperimentAction),
  })
  .set(ID.download_file, {
    loadService: () => import('./download-file-action.service').then((m) => m.DownloadFileAction),
  })
  .set(ID.logout, { loadService: () => import('./logout-action.service').then((m) => m.LogoutAction) })
  .set(ID.trigger_loading_indicator, {
    loadService: () =>
      import('./trigger-loading-indicator-action.service').then((m) => m.TriggerLoadingIndicatorAction),
  })
  .set(ID.validate, { loadService: () => import('./validate-action.service').then((m) => m.ValidateAction) })
  .set(ID.discard_changes_by_id, {
    loadService: () => import('./discard-changes-by-id-action.service').then((m) => m.DiscardChangesByIdAction),
  })
  .set(ID.mark_for_save, {
    loadService: () => import('./mark-for-save-action.service').then((m) => m.MarkForSaveAction),
  });

export interface ExecuteActionDto {
  url: string;
  postParameterSelector: any;
  resourceId: string;
  rules: EValidationRules[];
  actionMethod: ERequestMethod;
  params: HttpParams;
  body: any;
  uploadID: string;
  id: ID;
  event: Event;
}

/**
 * default interface each action should implement
 * accepts a generic type <T> to determinate the return type
 */
export interface Action<C extends ID, T> {
  readonly type: string;
  // describes the chainable function of each action
  execute: (...params: ExecutionParams<C>) => Observable<T>;
}

export const getAction = <T extends ID>(id: T): ActionSet<T> => {
  if (!ACTIONS.has(id)) {
    return null;
  }

  return ACTIONS.get(id);
};
