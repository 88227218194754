import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Inject,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { DateRange, MatCalendar, MatCalendarView } from '@angular/material/datepicker';
import { CONTAINER_DATA } from '@app-modeleditor/components/lightbox/overlay/container-data';
import { DefaultOverlayContainer } from '@app-modeleditor/components/lightbox/overlay/default-overlay-container';
import { DateUtils } from '@app-modeleditor/utils/date-utils';
import { ConfigService } from '@core/config/config.service';
import { EHotkeyType } from 'frontend/src/dashboard/model/resource/hotkey-type.enum';
import moment, { Moment } from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EDatepickerMode } from '../datepicker-mode.enum';
import { ICalendarOptions } from './calendar-options.interface';

@Component({
  selector: 'app-date-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  providers: [],
})
export class DateCalendarComponent extends DefaultOverlayContainer<DateRange<Moment>> implements OnDestroy {
  @HostBinding('class.mat-elevation-z6') elevation = true;
  data: DateRange<Moment>;
  ngOverwrite: Subject<void> = new Subject<void>();
  calRef: ElementRef;
  calElem: MatCalendar<Moment>;
  @ViewChild(MatCalendar) set cal2(cal: MatCalendar<Moment>) {
    this.calElem = cal;
    this.ngOverwrite.next();
    if (this.calElem) {
      this.calElem.stateChanges.pipe(takeUntil(this.ngOverwrite)).subscribe((r) => {
        setTimeout(() => this.rebuildWeeks(), 0);
      });
    }
    setTimeout(() => this.rebuildWeeks(), 0);
  }
  @ViewChild(MatCalendar, { read: ElementRef }) set cal(calRef: ElementRef) {
    this.calRef = calRef;
  }
  alive = true;
  startTime: number;
  endTime: number;
  id: string;
  maxDate: Date = null;
  minDate: Date = null;

  /**
   * build weeks in front of calendar
   */
  private rebuildWeeks(): void {
    if (this.config.access().templates.Calendar?.showCalendarWeeks !== true) {
      return;
    }

    this.calRef?.nativeElement.querySelectorAll('.mat-calendar-table').forEach((element: HTMLTableElement) => {
      const headerRow = element.querySelector('.mat-calendar-table-header').querySelector('tr');

      // remove previous week-numbers
      headerRow.querySelectorAll('.week-number').forEach((week: HTMLElement) => {
        headerRow.removeChild(week);
      });
      const th: HTMLTableCellElement = document.createElement('th');
      th.classList.add('week-number');
      th.innerHTML = 'KW';
      th.style.backgroundColor = 'rgb(223 230 255)';
      th.style.textAlign = 'center';
      headerRow.prepend(th);
      element
        .querySelector('.mat-calendar-body')
        .querySelectorAll('[role="row"]')
        .forEach((item, index: number) => {
          // remove previous week-numbers
          item.querySelectorAll('.week-number').forEach((week: HTMLElement) => {
            item.removeChild(week);
          });

          // @todo: alc week number here
          const td = document.createElement('td');
          td.classList.add('week-number');
          td.style.backgroundColor = 'rgb(223 230 255)';
          td.style.textAlign = 'center';
          td.style.color = '#b7b7b7';
          const v = this.calElem.monthView._weeks[index][0].compareValue;
          const weekNumber = DateUtils.getNumberOfWeek(new Date(v));
          td.innerHTML = `${weekNumber}`;
          item.prepend(td);
        });
    });
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    switch (event.code) {
      case EHotkeyType.ESCAPE:
        this.cancel();
      case EHotkeyType.ARROW_LEFT:
      case EHotkeyType.ARROW_UP:
      case EHotkeyType.ARROW_RIGHT:
      case EHotkeyType.ARROW_DOWN:
      case EHotkeyType.TAB:
      case EHotkeyType.ENTER:
        this.cd.detectChanges();
        break;
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.alive = false;
    this.ngOverwrite.next();
    this.ngOverwrite.complete();
  }

  constructor(
    @Inject(CONTAINER_DATA) public options: ICalendarOptions,
    private cd: ChangeDetectorRef,
    private config: ConfigService
  ) {
    super();
    this.startTime = DateUtils.getTimeFromDate(moment(options.start).toDate());
    this.endTime = DateUtils.getTimeFromDate(moment(options.end).toDate());
    this.data = new DateRange<Moment>(moment(options.start), moment(options.end));
    this.id = options.id;
    this.minDate = options.minDate || null;
    this.maxDate = options.maxDate || null;
  }

  monthSelected(ev: Moment): void {}

  viewChanged(ev: MatCalendarView): void {}

  onChange(ev: Moment): void {
    // if rangepicker
    if (this.options.mode === EDatepickerMode.RANGE) {
      if (this.data.start && !this.data.end) {
        if (ev.toDate().getTime() > this.data.start.toDate().getTime()) {
          this.data = new DateRange(this.data.start, ev);
        } else {
          this.data = new DateRange(ev, this.data.start);
        }
      } else {
        this.data = new DateRange(ev, null);
      }
    } else {
      this.data = new DateRange(ev, ev);
    }
    this.patch();
  }

  patch(): void {
    if (this.options.mode !== 'range' && this.data?.start && Number.isFinite(this.startTime)) {
      let d: Date = new Date(this.data.start.toDate());
      d.setHours(0);
      d.setMinutes(0);
      d.setSeconds(0);
      d.setMilliseconds(0);

      const offset = DateUtils.getOffsetTime(d);
      let correctedStartTime = this.startTime;

      // correction for time changeover
      if (offset > 0 && this.startTime > 2 * 60 * 60 * 1000) {
        // summer -> winter && grater 2 am
        correctedStartTime += offset;
      } else if (offset < 0 && this.startTime > 2 * 60 * 60 * 1000) {
        // winter -> summer && grater 2 am
        correctedStartTime += offset;
      }

      d = new Date(d.getTime() + correctedStartTime);
      this.data = new DateRange(moment(d), moment(d));
    }
  }

  onTimeChanged(event: number, scope: 'start' | 'end'): void {
    if (scope === 'start') {
      this.startTime = event;
    } else {
      this.endTime = event;
    }
    this.patch();
  }

  confirm(): void {
    this._close('close', this.data);
  }

  cancel(): void {
    this._close('backdropClick');
  }
}

