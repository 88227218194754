/**
 * service to get Experiments and spread them oer the app
 */
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserService } from 'frontend/src/dashboard/user/data-access/user.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable()
export class SolverService {
  private currentSolverrun: BehaviorSubject<any>;

  public _refreshObs: Observable<any>;
  public _refreshSub: Subject<void>;
  constructor(private http: HttpClient, private globalUserSettingsService: UserService) {
    this.currentSolverrun = new BehaviorSubject(null);

    this._refreshSub = new Subject<any>();
    this._refreshObs = this._refreshSub.asObservable();
  }

  /**
   * set current solverrun
   * @param solverrun solverrun
   */
  public setCurrentSolverrun(solverrun: any): void {
    if (solverrun) {
      this.globalUserSettingsService.updateUserSettings('CURRENT_SOLVERRUN', solverrun.id).subscribe(
        () => {},
        (err) => {
          console.error(err);
        }
      );
    }
    this.currentSolverrun.next(solverrun);
  }

  public refresh(solverrun?: any): void {
    if (solverrun) {
      this.setCurrentSolverrun(solverrun);
    }
    this._refreshSub.next();
  }

  /**
   * get current solverrun
   */
  public getCurrentSolverrun(): Observable<any> {
    return this.currentSolverrun.asObservable();
  }

  public getSolverRunResult(solverrunId: string) {
    return this.http.get(`rest/staffplanning/results/solverrun/${solverrunId}`);
  }

  public stopAllSolver(): Observable<any> {
    return this.http.get(`rest/staffplanning/solver/stop/all`);
  }

  public stopSolver(resultId: string): Observable<any> {
    return this.http.get(`rest/staffplanning/solver/stop/${resultId}`);
  }

  public getSolverList(params?: SolverRequestParams) {
    return this.getSolverRuns(this.getParams(params));
  }

  private getSolverRuns(params?: HttpParams): Observable<any> {
    return this.http.get(`rest/staffplanning/list/solverrun`, { params });
  }

  public getShifttablePerDay(resultId: string): Observable<any> {
    return this.http.get(`rest/staffplanning/solver/shifttableperday/${resultId}`);
  }
  public getFrontendEmployes(resultId: string): Observable<any> {
    return this.http.get(`rest/staffplanning/solver/frontendemployes/${resultId}`);
  }
  public getFrontendMachines(resultId: string): Observable<any> {
    return this.http.get(`rest/staffplanning/solver/outputfrontendmachines/${resultId}`);
  }
  public getState(resultId: string): Observable<any> {
    return this.http.get(`rest/staffplanning/solver/state/${resultId}`);
  }
  public getResults(modelId: string): Observable<any> {
    return this.http.get(`rest/staffplanning/results/${modelId}`);
  }
  public getEmployeeTable(resultId: string): Observable<any> {
    return this.http.get(`rest/staffplanning/solver/employeetable/${resultId}`);
  }
  public getGeneral(resultId: string): Observable<any> {
    return this.http.get(`rest/staffplanning/solver/general/${resultId}`);
  }
  public getScoresteplist(resultId: string): Observable<any> {
    return this.http.get(`rest/staffplanning/solver/scoresteplist/${resultId}`);
  }
  public getBrokenconstraints(resultId: string): Observable<any> {
    return this.http.get(`rest/staffplanning/solver/brokenconstraints/${resultId}`);
  }
  public getQualificationTableEasy(resultId: string): Observable<any> {
    return this.http.get(`rest/staffplanning/solver/qualificationtableeasy/${resultId}`);
  }
  public getQualificationTableGroup(resultId: string): Observable<any> {
    return this.http.get(`rest/staffplanning/solver/qualificationtablegroup/${resultId}`);
  }
  public takeSolverPlan(resultId: string): Observable<any> {
    return this.http.get(`rest/staffplanning/solver/acceptresult/${resultId}`);
  }
  public addBlockingInterval(resourceId: string, params: HttpParams): Observable<any> {
    return this.http.get(`rest/staffplanning/staff/${resourceId}/blockingtime/addBlockingInterval`, { params });
  }
  public getEmployeeSuggestion(body: any): Observable<any> {
    return this.http.post(`rest/staffplanning/solver/employeesuggestion`, body);
  }
  public postEmployeeSuggestion(body: any): Observable<any> {
    return this.http.post(`rest/staffplanning/solver/accept/employeesuggestion`, body);
  }

  public getFactoryCalendar(params?: HttpParams): Observable<any> {
    return this.http.get(`rest/staffplanning/factorycalendar`, { params });
  }

  public getFactoryBlockingInterval(): Observable<any> {
    const url = encodeURI('rest/staffplanning/factorycalendar?start=0&end=1890687600000');
    return this.http.get(`${url}`);
  }

  /**
   * return HttpParams out of ExperimentRequestParams
   * @param params ExperimentRequestParams
   */
  private getParams(params: SolverRequestParams): HttpParams {
    let httpParams = new HttpParams();
    for (const item in params) {
      httpParams = httpParams.set(item, params[item]);
    }
    return httpParams;
  }
}
export interface SolverRequestParams {
  offset?: number;
  limit?: number;
}

export interface ISolverrun {
  id: string;
  solverResult?: any;
  name?: string;
}
