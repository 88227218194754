import { ActionAdapter } from '../button/action-adapter.service';
import { ContentElementAdapter } from './../structure/content-element-adapter.service';
import { Injectable, Type } from '@angular/core';
import { TemplateAdapter } from '@app-modeleditor/utils/template-factory.service';
import { Adapter } from '../../../core/adapter';
import { TemplateChat } from './template-chat';
import { Action } from '../button/action/action';

@Injectable({
  providedIn: 'root',
})
export class TemplateChatAdapter implements Adapter<TemplateChat> {
  constructor(private _contentElementAdapter: ContentElementAdapter, private _actionAdapter: ActionAdapter) {}

  adapt(item: any): TemplateChat {
    throw new Error('Method not implemented.');
  }
  inherit?<T extends TemplateChat>(type: Type<T>, item: any): T {
    const t: T = this.getData(this._contentElementAdapter.inherit<T>(type, item), item);
    return t;
  }
  inheritFrom?<T extends TemplateChat>(scope: TemplateAdapter, type: Type<T>, item: any): T {
    const t: T = this.getData(this._contentElementAdapter.inheritFrom<T>(scope, type, item), item);
    return t;
  }
  applyValues<T>(scope: TemplateAdapter, item: any, values: any): T {
    throw new Error('Method not implemented.');
  }

  private getData<T extends TemplateChat>(t: T, item): T {
    t.setSendMessageAction(this._actionAdapter.parseAction<Action>(Action, item.sendMessageAction));
    t.setEditMessageURL(item.editMessageURL);
    t.setDeleteMessageURL(item.deleteMessageURL);
    return t;
  }
}
