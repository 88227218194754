import { ContentElement } from '@app-modeleditor/components/content/content-element/content-element';
import { BestGantt } from '@gantt/public-api';
import { GanttXAxisConfig } from 'frontend/src/dashboard/gantt/general/config-handling/i-gantt-x-axis-config';
import { Gantt_General } from 'frontend/src/dashboard/gantt/general/general.gantt.component';
import {
  GanttChildren,
  GanttHierarchicalPlanData,
  IGanttAttributeMapping,
  IGanttBlock,
  IGanttDefaultFrontendFilter,
  IGanttEntryAttributeMapping,
  IGanttLegend,
  IGanttPlugin,
  IGanttTemplateValue,
  IGanttVisibleAttributes,
  ISuperBlockView,
} from 'frontend/src/dashboard/gantt/general/generator/gantt-input.data';
import { BackendToGanttOriginInputMapperService } from 'frontend/src/dashboard/gantt/general/generator/mapper/gantt-to-gantt-origin.mapper';
import { GlobalUtils } from 'frontend/src/dashboard/global-utils';
import { ETemplateType } from 'frontend/src/dashboard/model/resource/template-type';
import { Subject } from 'rxjs';
import { IGanttExternalDropElement } from '../general/gantt-actions/gantt-action-handling/external-drop/external-drop-element.interface';
import { IGanttSelectedTimePeriod } from '../general/plugin/plugin-list/blocking-intervals/gantt-template-data-attribute-mapping.interface';
import { EGanttType } from './gantt-type.enum';
import { HierarchicalPlanHelperFunctions } from './hierarchical-plan-helper-functions';

export class GanttTemplateData extends ContentElement {
  public genericId: string = GlobalUtils.generateUUID();
  private additionalSubMenus: any[];
  private attributeMapping: IGanttAttributeMapping;
  private blockSortings: string[];
  private defaultFrontEndFilter: IGanttDefaultFrontendFilter[];
  private editAllowSettings: any;
  private ganttActions: any[];
  private ganttLegend: IGanttLegend;
  private ganttResponseProvider: string;
  private ganttEntryAttributeMappings: IGanttEntryAttributeMapping;
  private ganttType: EGanttType;
  private indexCard: IGanttVisibleAttributes;
  private indexCardSettingsURL: string;
  private defaultBlockTooltipSettings: IGanttVisibleAttributes;
  private tooltipSettingsURL: string;
  private nameFieldIdentifiers: string;
  private plugins: any[];
  private registerUserUpdateReceiverExecuter: string;
  private relatedGantts: any;
  private searchable: boolean;
  private settings: any;
  private superBlockAttributeMapping: any;
  private superBlockViews: ISuperBlockView[];
  private updateReceiverExecuter: string;
  private printLayout: any;
  private saveUrl: string;
  private publicHolidaysURL: string;
  private value: IGanttTemplateValue;
  private ganttUniqueId: string;
  private disableCurrentZoomSpanSaveCallback: boolean;
  private afterInit: (gantt: BestGantt, cmp: Gantt_General) => void;
  private print: boolean;
  private selectedGanttEntry: GanttChildren;
  private ganttStartTime: number;
  private ganttEndTime: number;
  private componentRef: Gantt_General;
  private selectedBlock: any;
  private selectedValues: any;
  private xAxisSettings: GanttXAxisConfig[];
  private originrow: any;
  private targetrow: any;
  private selectedblock: any; // deprecated (14.06.21) -> use selectedTimePeriodInput
  private selectedTimePeriodInput: IGanttSelectedTimePeriod;
  private selectedTimePeriodInputs: IGanttSelectedTimePeriod[];
  private selectedTimePeriodInputGroupId: string;
  private selectedRow: any;
  private addblock: any;
  private zoom: any;
  public afterGanttEmit: Subject<any>;
  private _hierarchicalPlanHelperFunctions: HierarchicalPlanHelperFunctions = new HierarchicalPlanHelperFunctions();
  private selectedValuesAndResources: IGanttExternalDropElement;
  private resourceAttributeMapping: IGanttAttributeMapping;
  private numberOfInitialResponsesExpected: number;
  private numberOfInitialResponsesReceived: number;
  private creatorCanonicalName: string; // stores target id of created block

  public getNumberOfInitialResponsesExpected(): number {
    return this.numberOfInitialResponsesExpected || 0;
  }

  /**
   * removes none serializeable properties
   * @param {GanttTemplateData} n gantt template
   * @returns Record<string, any>
   */
  public serialize(n?: GanttTemplateData, whitelist: string[] = []): Record<string, any> {
    return super.serialize(n, whitelist.concat(['componentRef']));
  }

  public setNumberOfInitialResponsesExpected(numberOfInitialResponsesExpected: number): this {
    this.numberOfInitialResponsesExpected = numberOfInitialResponsesExpected;
    return this;
  }

  public getNumberOfInitialResponsesReceived(): number {
    return this.numberOfInitialResponsesReceived || 0;
  }

  public setNumberOfInitialResponsesReceived(numberOfInitialResponsesReceived: number): this {
    this.numberOfInitialResponsesReceived = numberOfInitialResponsesReceived;
    return this;
  }

  public getComponentRef(): Gantt_General {
    return this.componentRef;
  }

  public setComponentRef(componentRef: Gantt_General): this {
    this.componentRef = componentRef;
    return this;
  }

  public getSelectedBlock(): any {
    return this.selectedBlock;
  }

  public getXAxisSettings(): GanttXAxisConfig[] {
    return this.xAxisSettings;
  }

  public setXAxisSettings(xAxisSettings: GanttXAxisConfig[]): this {
    this.xAxisSettings = xAxisSettings;
    return this;
  }

  public getSelectedValues(): any {
    return this.selectedValues;
  }

  public getGanttStartTime(): number {
    return this.ganttStartTime;
  }

  public setGanttStartTime(ganttStartTime: number): this {
    this.ganttStartTime = ganttStartTime;
    return this;
  }

  public getGanttEndTime(): number {
    return this.ganttEndTime;
  }

  public setGanttEndTime(ganttEndTime: number): this {
    this.ganttEndTime = ganttEndTime;
    return this;
  }

  public getGanttEntryAttributeMappings(): IGanttEntryAttributeMapping {
    return this.ganttEntryAttributeMappings;
  }

  public setGanttEntryAttributeMappings(ganttEntryAttributeMappings: IGanttEntryAttributeMapping): this {
    this.ganttEntryAttributeMappings = ganttEntryAttributeMappings;
    return this;
  }

  public getGanttUniqueId(): string {
    return this.ganttUniqueId;
  }

  public setGanttUniqueId(ganttUniqueId: string): this {
    this.ganttUniqueId = ganttUniqueId;
    return this;
  }

  public isPrint(): boolean {
    return typeof this.print === 'boolean' ? this.print : false;
  }

  public setPrint(print: boolean): this {
    this.print = print;
    return this;
  }

  constructor() {
    super();
    this.setType(ETemplateType.GANTT);
  }

  public callAfterInit(gantt: BestGantt, cmp: Gantt_General): void {
    if (this.afterInit) {
      this.afterInit(gantt, cmp);
    }
  }

  public setAfterInit(callback: (gantt: BestGantt, cmp: Gantt_General) => void): this {
    this.afterInit = callback;
    return this;
  }

  public getValue(): IGanttTemplateValue {
    return this.value;
  }

  public setDisableCurrentZoomSpanSaveCallback(disableCurrentZoomSpanSaveCallback: boolean): this {
    this.disableCurrentZoomSpanSaveCallback = disableCurrentZoomSpanSaveCallback;
    return this;
  }

  public isDisableCurrentZoomSpanSaveCallback(): any {
    return typeof this.disableCurrentZoomSpanSaveCallback === 'boolean'
      ? this.disableCurrentZoomSpanSaveCallback
      : false;
  }

  public setValue(value: IGanttTemplateValue): this {
    this.value = value;
    return this;
  }

  public getPrintLayout(): any {
    return this.printLayout;
  }

  public setPrintLayout(printLayout: any): this {
    this.printLayout = printLayout;
    return this;
  }

  public getSaveUrl(): string {
    return this.saveUrl;
  }

  public setSaveUrl(saveUrl: string): this {
    this.saveUrl = saveUrl;
    return this;
  }

  public getPublicHolidaysURL(): string {
    return this.publicHolidaysURL;
  }

  public setPublicHolidaysURL(publicHolidaysURL: string): this {
    this.publicHolidaysURL = publicHolidaysURL;
    return this;
  }

  public getAdditionalSubMenus(): any[] {
    return this.additionalSubMenus || [];
  }

  public setAdditionalSubMenus(additionalSubMenus: any[]): this {
    this.additionalSubMenus = additionalSubMenus;
    return this;
  }

  public getAttributeMapping(): IGanttAttributeMapping {
    return this.attributeMapping;
  }

  public setAttributeMapping(attributeMapping: IGanttAttributeMapping): this {
    this.attributeMapping = attributeMapping;
    return this;
  }

  public getBlockSortings(): string[] {
    return this.blockSortings;
  }

  public setBlockSortings(blockSortings: string[]): this {
    this.blockSortings = blockSortings;
    return this;
  }

  public getDefaultFrontEndFilter(): IGanttDefaultFrontendFilter[] {
    return this.defaultFrontEndFilter;
  }

  public setDefaultFrontEndFilter(defaultFrontEndFilter: IGanttDefaultFrontendFilter[]): this {
    this.defaultFrontEndFilter = defaultFrontEndFilter;
    return this;
  }

  public getEditAllowSettings(): any {
    return this.editAllowSettings;
  }

  public setEditAllowSettings(editAllowSettings: any): this {
    this.editAllowSettings = editAllowSettings;
    return this;
  }

  public getSelectedGanttEntry(): GanttChildren {
    return this.selectedGanttEntry;
  }

  public setSelectedGanttEntry(selectedGanttEntry: GanttChildren): this {
    this.selectedGanttEntry = selectedGanttEntry;
    return this;
  }

  public getGanttActions(): any[] {
    return this.ganttActions;
  }

  public setGanttActions(ganttActions: any[]): this {
    this.ganttActions = ganttActions;
    return this;
  }

  public getGanttLegend(): IGanttLegend {
    return this.ganttLegend;
  }

  public setGanttLegend(ganttLegend: IGanttLegend): this {
    this.ganttLegend = ganttLegend;
    return this;
  }

  public getGanttResponseProvider(): string {
    return this.ganttResponseProvider;
  }

  public setGanttResponseProvider(ganttResponseProvider: string): this {
    this.ganttResponseProvider = ganttResponseProvider;
    return this;
  }

  public getGanttType(): EGanttType {
    return this.ganttType;
  }

  public setGanttType(ganttType: EGanttType): this {
    this.ganttType = ganttType;
    return this;
  }

  public getIndexCard(): IGanttVisibleAttributes {
    return this.indexCard;
  }

  public setIndexCard(indexCard: IGanttVisibleAttributes): this {
    this.indexCard = indexCard;
    return this;
  }

  public getDefaultBlockTooltipSettings(): IGanttVisibleAttributes {
    return this.defaultBlockTooltipSettings;
  }

  public setDefaultBlockTooltipSettings(defaultBlockTooltipSettings: IGanttVisibleAttributes): this {
    this.defaultBlockTooltipSettings = defaultBlockTooltipSettings;
    return this;
  }

  public getTooltipSettingsURL(): string {
    return this.tooltipSettingsURL;
  }

  public setTooltipSettingsURL(tooltipSettingsURL: string): this {
    this.tooltipSettingsURL = tooltipSettingsURL;
    return this;
  }

  public getIndexCardSettingsURL(): string {
    return this.indexCardSettingsURL;
  }

  public setIndexCardSettingsURL(indexCardSettingsURL: string): this {
    this.indexCardSettingsURL = indexCardSettingsURL;
    return this;
  }

  public getNameFieldIdentifiers(): string {
    return this.nameFieldIdentifiers;
  }

  public setNameFieldIdentifiers(nameFieldIdentifiers: string): this {
    this.nameFieldIdentifiers = nameFieldIdentifiers;
    return this;
  }

  public getPlugins(): IGanttPlugin[] {
    return this.plugins || [];
  }

  public setPlugins(plugins: IGanttPlugin[]): this {
    this.plugins = plugins;
    return this;
  }

  public getRegisterUserUpdateReceiverExecuter(): string {
    return this.registerUserUpdateReceiverExecuter;
  }

  public setRegisterUserUpdateReceiverExecuter(registerUserUpdateReceiverExecuter: string): this {
    this.registerUserUpdateReceiverExecuter = registerUserUpdateReceiverExecuter;
    return this;
  }

  public getRelatedGantts(): any {
    return this.relatedGantts;
  }

  public setRelatedGantts(relatedGantts: any): this {
    this.relatedGantts = relatedGantts;
    return this;
  }

  public isSearchable(): boolean {
    return this.searchable;
  }

  public setSearchable(searchable: boolean): this {
    this.searchable = searchable;
    return this;
  }

  public getSettings(): any {
    return this.settings;
  }

  public setSettings(settings: any): this {
    this.settings = settings;
    return this;
  }

  /**
   * @deprecated (06.04.2022) use attribute mapping instead
   */
  public getSuperBlockAttributeMapping(): any {
    return this.superBlockAttributeMapping;
  }

  /**
   * @deprecated (06.04.2022) use attribute mapping instead
   */
  public setSuperBlockAttributeMapping(superBlockAttributeMapping: any): this {
    this.superBlockAttributeMapping = superBlockAttributeMapping;
    return this;
  }

  public getSuperBlockViews(): any[] {
    return this.superBlockViews || [];
  }

  public setSuperBlockViews(superBlockViews: any[]): this {
    this.superBlockViews = superBlockViews;
    return this;
  }

  public getUpdateReceiverExecuter(): string {
    return this.updateReceiverExecuter;
  }

  public setUpdateReceiverExecuter(updateReceiverExecuter: string): this {
    this.updateReceiverExecuter = updateReceiverExecuter;
    return this;
  }

  // Gantt Template communication data methods

  public setOriginRow(originRowId: string): void {
    this.originrow = {};

    this.originrow.id = originRowId;
  }

  public setTargetRow(targetRowId: string): void {
    this.targetrow = {};
    this.targetrow.id = targetRowId;
  }

  setSelectedValues(selectedValues: any[] | boolean, mapper: BackendToGanttOriginInputMapperService): void {
    this.selectedValues = {};

    if (Array.isArray(selectedValues)) {
      selectedValues = JSON.parse(JSON.stringify(selectedValues)) as Array<any>;
      selectedValues.forEach((value) => {
        value.id = mapper.mapShiftIdToOriginId(value.id);
      });
    }

    this.selectedValues = selectedValues;
  }

  public setSelectedBlock(selectedBlockData, mapper: BackendToGanttOriginInputMapperService): void {
    // reset first.
    this.selectedBlock = {};

    for (const key in selectedBlockData) {
      if (key === 'id') {
        const shiftId = mapper.mapShiftIdToOriginId(selectedBlockData.id);
        this.selectedBlock['id'] = shiftId;
        continue;
      }
      this.selectedBlock[key] = selectedBlockData[key];
    }
  }

  public setSuperBlocks(superBlocks): void {
    this.selectedBlock.superBlocks = superBlocks;
  }

  public setSelectedRow(rowData): void {
    this.selectedRow = {};

    for (const key in rowData) {
      this.selectedRow[key] = rowData[key];
    }
  }

  /**
   * Small b is not a typo! Big B ist for shifts, small b is for blocking intervals!
   * @deprecated (14.06.21) -> use setSelectedTimePeriodInput
   */
  public setSelectedblock(blockData): void {
    this.selectedblock = {};
    for (const key in blockData) {
      this.selectedblock[key] = blockData[key];
    }
  }

  /**
   * Small b is not a typo! Big B ist for shifts, small b is for blocking intervals!
   * @deprecated (14.06.21) -> use setSelectedTimePeriodInput
   */
  public getSelectedblock(): any {
    return this.selectedblock;
  }

  public setSelectedTimePeriodInput(blockData: IGanttSelectedTimePeriod): void {
    this.selectedTimePeriodInput = blockData;
  }

  public getSelectedTimePeriodInput(): IGanttSelectedTimePeriod {
    return this.selectedTimePeriodInput;
  }

  public setSelectedTimePeriodInputs(blockData: IGanttSelectedTimePeriod[]): void {
    this.selectedTimePeriodInputs = blockData;
  }

  public getSelectedTimePeriodInputs(): IGanttSelectedTimePeriod[] {
    return this.selectedTimePeriodInputs;
  }

  public setSelectedTimePeriodInputGroupId(groupId: string): void {
    this.selectedTimePeriodInputGroupId = groupId;
  }

  public getSelectedTimePeriodInputGroupId(): string {
    return this.selectedTimePeriodInputGroupId;
  }

  public setAddBlock(addBlockData): void {
    this.addblock = {};
    for (const key in addBlockData) {
      this.addblock[key] = addBlockData[key];
    }
  }

  public getAddBlock() {
    return this.addblock;
  }

  public setZoom(zoomData) {
    this.zoom = {};
    for (const key in zoomData) {
      this.zoom[key] = zoomData[key];
    }
  }

  public getHierarchicalPlan(): GanttHierarchicalPlanData {
    return this.value?.hierarchicalPlan;
  }

  public setHierarchicalPlan(hierarchicalPlan: GanttHierarchicalPlanData): void {
    this.value.hierarchicalPlan = hierarchicalPlan;
  }

  /**
   * Returns an entry by the given ID from hierarchical plan.
   */
  public getEntryByIdFromHierarchicalPlan(rowId: string): GanttChildren {
    return this._hierarchicalPlanHelperFunctions.getEntryByIdFromHierarchicalPlan(rowId, this.getHierarchicalPlan());
  }

  /**
   * Removes a block by the given ID from hierarchical plan.
   */
  public removeBlockByIdFromHierarchicalPlan(blockId: string): boolean {
    return this._hierarchicalPlanHelperFunctions.removeBlockByIdFromHierarchicalPlan(
      blockId,
      this.getHierarchicalPlan()
    );
  }

  /**
   * Removes all blocks with the given IDs from hierarchical plan.
   * @param blockIds Ids of the blocks to remove.
   */
  public removeBlocksByIdsFromHierarchicalPlan(blockIds: string[]): void {
    this._hierarchicalPlanHelperFunctions.removeBlocksByIdsFromHierarchicalPlan(blockIds, this.getHierarchicalPlan());
  }

  /**
   * Removes an entry by the given ID from hierarchical plan.
   */
  public removeEntryByIdFromHierarchicalPlan(entryId: string): boolean {
    return this._hierarchicalPlanHelperFunctions.removeEntryByIdFromHierarchicalPlan(
      entryId,
      this.getHierarchicalPlan()
    );
  }

  /**
   * Removes entries by the given ID array from hierarchical plan.
   */
  public removeEntriesByIdFromHierarchicalPlan(entryIds: string[]): void {
    this._hierarchicalPlanHelperFunctions.removeEntriesByIdFromHierarchicalPlan(entryIds, this.getHierarchicalPlan());
  }

  /**
   * Replaces entry by the given ID in hierarchical plan.
   */
  public replaceEntryByIdInHierarchicalPlan(entry: GanttChildren): void {
    this._hierarchicalPlanHelperFunctions.replaceEntryByIdInHierarchicalPlan(entry, this.getHierarchicalPlan());
  }

  /**
   * Returns a block by the given ID from hierarchical plan.
   */
  public getBlockByIdFromHierarchicalPlan(
    blockId: string,
    mapper: BackendToGanttOriginInputMapperService = null
  ): IGanttBlock {
    return this._hierarchicalPlanHelperFunctions.getBlockByIdFromHierarchicalPlan(
      blockId,
      this.getHierarchicalPlan(),
      mapper
    );
  }

  /**
   * Returns an array of objects containing the Gantt block and its corresponding row from the hierarchical plan,
   * given an array of block IDs.
   * @param blockIds An array of block IDs to search for in the hierarchical plan.
   * @returns An array of objects containing the Gantt block and its corresponding row from the hierarchical plan.
   */
  public getBlocksByIdsFromHierarchicalPlan(blockIds: string[]): {
    block: IGanttBlock;
    row: GanttChildren;
  }[] {
    return this._hierarchicalPlanHelperFunctions.getBlocksByIdsFromHierarchicalPlan(
      blockIds,
      this.getHierarchicalPlan()
    );
  }

  /**
   * Sets selected values (block ids) and resources (resource ids) of an external drop event.
   */
  public setSelectedValuesAndResources(selectedValuesAndResources: IGanttExternalDropElement): void {
    this.selectedValuesAndResources = selectedValuesAndResources;
  }

  /**
   * Returns selected values (block ids) and resources (resource ids) of an external drop event.
   */
  public getSelectedValuesAndResources(): IGanttExternalDropElement {
    return this.selectedValuesAndResources;
  }

  /**
   * Sets the attribute mapping of resources.
   */
  public setResourceAttributeMapping(resourceAttributeMapping: IGanttAttributeMapping): this {
    this.resourceAttributeMapping = resourceAttributeMapping;
    return this;
  }

  /**
   * Returns the attribute mapping of resources.
   */
  public getResourceAttributeMapping(): IGanttAttributeMapping {
    return this.resourceAttributeMapping;
  }

  /**
   * Sets the target id of current created block.
   */
  public setCreatorCanonicalName(creatorCanonicalName: string): this {
    this.creatorCanonicalName = creatorCanonicalName;
    return this;
  }

  /**
   * Returns the target id of current created block.
   */
  public getCreatorCanonicalName(): string {
    return this.creatorCanonicalName;
  }

  /**
   * Iterates over all gantt blocks recursively
   * @param callback Callback is called on every block with its own block and the parent row as parameter
   */
  public iterateOverAllBlocks(callback: (block: IGanttBlock, row: GanttChildren) => void): void {
    this._hierarchicalPlanHelperFunctions.iterateOverAllBlocks(callback, this.getHierarchicalPlan().ganttEntries);
  }

  public iterateOverAllEntries(callback: (row: GanttChildren) => void): void {
    this._hierarchicalPlanHelperFunctions.iterateOverAllEntries(callback, this.getHierarchicalPlan().ganttEntries);
  }
}
