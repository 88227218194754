import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AuthenticationService } from '@core/authentication/auth.service';
import { Credentials } from '@core/authentication/credentials';
import { ConfigService } from '@core/config/config.service';
import { Store } from '@ngxs/store';
import { GlobalUtils } from 'frontend/src/dashboard/global-utils';
import { Resource } from 'frontend/src/dashboard/model/resource/resource';
import { BehaviorSubject, Observable, Subject, of, timer } from 'rxjs';
import { catchError, filter, map, take, takeUntil, timeout } from 'rxjs/operators';
import { AuthActions } from '../../login/data-access/auth.actions';
import { IBroadcastMessage, OnWindowClosedMessage, WindowData, WindowInitData } from './data-types.interface';
import { EMessageType } from './message-type.enum';
@Injectable({
  providedIn: 'root',
})
export class WindowService implements OnDestroy {
  private channel: BroadcastChannel;
  public sub: Subject<MessageEvent<IBroadcastMessage>> = new Subject<MessageEvent<IBroadcastMessage>>();
  private _data: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private afterInit: BehaviorSubject<void> = new BehaviorSubject<void>(null);
  public onCredentialsChanged: Subject<Credentials> = new Subject<Credentials>();
  private window: boolean;
  private ngDestroy: Subject<void> = new Subject<void>();
  public onReload: Subject<void> = new Subject();
  private onMenuItemClosed$: Subject<string> = new Subject<string>();
  private onAnyWindowHasFocus$: Subject<void> = new Subject<void>();
  private onWindowClosed$: Subject<string> = new Subject<string>();
  /**
   * A subject that emits its ID as a string value when a window is initialized.
   */
  private windowInitialized$: Subject<string> = new Subject<string>();
  /**
   * A map of open windows, where the keys are window IDs and the values are `WindowData` objects.
   */
  private openWindows = new Map<string, WindowData>();

  reload(): void {
    this.onReload.next();
  }

  constructor(
    private store: Store,
    private configApi: ConfigService,
    private auth: AuthenticationService,
    private zone: NgZone,
    private pageTitle: Title
  ) {
    if (!this.pageTitle.getTitle()) {
      this.pageTitle.setTitle(this.configApi.access().title.label);
    }
    this.initialize();
    this.manageWindows();
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  /**
   * has to be called initally
   */
  initialize(): void {
    const channel: string = this.configApi.access().api.broadcastChannel;
    this.channel = new BroadcastChannel(channel);
    this.afterInit.next();

    this.auth
      .onRefresh()
      .pipe(takeUntil(this.ngDestroy))
      .subscribe((credentials: Credentials) => {
        if (!credentials) {
          return;
        }
        this.sendMessage({
          type: EMessageType.BROADCAST,
          data: {
            credentials: credentials.serialize(),
          },
        });
      });
  }

  onAfterInit(): Observable<void> {
    return this.afterInit.asObservable();
  }

  /**
   * Manages the states of open windows by subscribing to received messages and handling them accordingly.
   * @returns void
   */
  private manageWindows(): void {
    this.receiveMessages()
      .pipe(takeUntil(this.ngDestroy))
      .subscribe((msg) => {
        const broadcastMessage = msg.data;
        const windowData = this.openWindows.get(broadcastMessage.uuid);

        switch (broadcastMessage.type) {
          case EMessageType.FINISHED_INITIALIZATION:
            if (windowData) {
              this.windowInitialized$.next(broadcastMessage.uuid);
              // send initial data if available
              this.sendMessage({
                uuid: broadcastMessage.uuid,
                type: EMessageType.MESSAGE,
                data: windowData,
              });
            }
            break;
          case EMessageType.WINDOW_IS_CLOSED:
            if (windowData) {
              timer(5000) // wait 5 seconds before removing the window from the list, maybe the window is just reloading
                .pipe(takeUntil(this.windowInitialized$.pipe(filter((uuid) => uuid === broadcastMessage.uuid))))
                .subscribe(() => {
                  this.closeWindow(broadcastMessage as OnWindowClosedMessage);
                });
            }
            break;
        }
      });
  }

  /**
   * Returns a boolean indicating whether the current view is a window/tab or the main app.
   * @returns {boolean} True if the current view is a window/tab, false if this is the main app.
   */
  public isWindow(): boolean {
    return this.window;
  }

  /**
   * set data
   * @param data object
   */
  setData(data: WindowInitData): void {
    this.window = true;
    this._data.next(data);
  }

  /**
   * get data observable
   */
  getData(): Observable<WindowInitData> {
    return this._data.asObservable();
  }

  /**
   * Opens a new window with the given data.
   * @param data Optional data to be passed to the new window.
   * @returns The UUID of the newly opened window.
   */
  public openWindow(data: WindowData): string {
    // uuid to identify window
    const _uuid = GlobalUtils.generateUUID();

    const initData: WindowInitData = {
      target: 'window',
      uuid: _uuid,
      language: data.language,
      title: `${data.content?.name || ''}`,
    };

    // encode data to base64
    const base64 = btoa(JSON.stringify(initData));

    // open window with target
    if (data.type === 'window') {
      window.open(
        `#?target=${base64}`,
        `${data?.content?.name ? data.content.name : ''}-${GlobalUtils.generateUUID()}`,
        `_blank,noreferrer,width=${screen.availWidth},height=${screen.availHeight},top=0,left=0`
      );
    } else {
      window.open(`#?target=${base64}`, `_blank`, 'noreferrer,top=0,left=0,popup=false');
    }

    // add window to list of open windows
    this.openWindows.set(_uuid, data);

    return _uuid;
  }

  /**
   * Removes the specified window from the set of open windows and emits an event indicating that the window has been closed.
   * @param msg - The message containing the UUID of the window to close and the ID of the menu item that opened it (if applicable).
   */
  private closeWindow(msg: OnWindowClosedMessage): void {
    this.openWindows.delete(msg.uuid);
    if (msg.menuItemId) {
      this.onMenuItemClosed$.next(msg.menuItemId);
    }
    this.onWindowClosed$.next(msg.uuid);
  }

  /**
   * send message
   * @param msg string
   */
  public sendMessage(msg: IBroadcastMessage): void {
    if (!this.channel) {
      return;
    }

    if (msg.data && msg.data.content && msg.data.content instanceof Resource) {
      const serialized: string = msg.data.content.serialize();
      msg.data.content = serialized;
    }

    this.channel.postMessage(msg);
  }

  handleLocalBroadcast(ev: MessageEvent<IBroadcastMessage>): void {
    const data = ev.data.data;

    if (
      ev.data?.type === EMessageType.CLOSE_ALL_WINDOWS &&
      this.isWindow() &&
      this.configApi.access().templates.closeAllWindowsOnMainWindowClose === true
    ) {
      window?.close();
    } else if (ev.data?.type === EMessageType.FORCE_REFRESH_TOKEN) {
      this.auth.refreshToken().subscribe(() => this.reload());
    } else if (ev.data?.type === EMessageType.FOCUS_BROADCAST && this.isWindow() && document.hasFocus()) {
      // send message that this external window has focus
      this.sendMessage({
        type: EMessageType.HAS_FOCUS,
      });
    } else if (ev.data?.type === EMessageType.HAS_FOCUS && !this.isWindow()) {
      this.onAnyWindowHasFocus$.next();
    } else if (data) {
      if (data.credentials) {
        const newCredentials: Credentials = this.auth.parseCredentials(data.credentials);
        this.zone.run(() => {
          this.store.dispatch(new AuthActions.OverrideCredentials(newCredentials)).pipe(take(1)).subscribe();
        });
      }
    }
  }

  listenToClosedMenuItems(): Observable<string> {
    return this.onMenuItemClosed$.asObservable();
  }

  /**
   * Returns an Observable that emits a string when the window with the specified ID is closed.
   * @param id The ID of the window to listen for close events on.
   * @returns An Observable that emits a string when the window with the specified ID is closed.
   */
  public listenToCloseWindow(id: string): Observable<string> {
    return this.onWindowClosed$.asObservable().pipe(
      filter((uuid: string) => uuid === id),
      take(1)
    );
  }

  /**
   * get subscription to onmessage
   */
  public receiveMessages(): Observable<MessageEvent<IBroadcastMessage>> {
    if (!this.channel.onmessage) {
      this.channel.onmessage = (ev: MessageEvent<IBroadcastMessage>) => {
        this.handleLocalBroadcast(ev);
        this.sub.next(ev);
      };
    }
    return this.sub.asObservable();
  }

  /**
   * destroy channel
   */
  public destroy(): void {
    this.channel.close();
  }

  /**
   * Checks if there is an external window that is currently focused.
   * @returns If yes it returns true, if not it returns false.
   */
  public checkIfAnyWindowHasFocus(): Observable<boolean> {
    this.sendMessage({
      type: EMessageType.FOCUS_BROADCAST,
    });

    return this.onAnyWindowHasFocus$.asObservable().pipe(
      timeout(1000),
      map(() => true),
      catchError(() => of(false)),
      take(1)
    );
  }
}
