import { YAxisDataFinder } from '../../../data-handler/data-finder/yaxis-data-finder';
import { GanttCanvasRow, GanttDataRow } from '../../../data-handler/data-structure/data-structure';
import { DataManipulator } from '../../../data-handler/data-tools/data-manipulator';
import { EGanttScrollContainer } from '../../../html-structure/scroll-container.enum';
import { GanttStickyRowStrategy } from './sticky-row-strategy.base';

/**
 * Gantt sticky row strategy that makes the top parent row of the first visible row sticky.
 */
export class GanttStickyParentRowStrategy extends GanttStickyRowStrategy {
  private _stickyRowCache = new Set<string>();

  public init(): void {
    this._resetConfig();
  }

  public destroy(): void {
    this._resetConfig();
  }

  public getManipulatedYAxisDataSet(dataSet: GanttCanvasRow[]): GanttCanvasRow[] {
    const scrollTop = this._getScrollTopPosition();

    // if not scrolled -> no need for sticky rows
    if (scrollTop === 0) {
      this._removeAllStickyRows(dataSet);
      this._resetConfig();
      this._stickyRowCache.clear();
      return dataSet;
    }

    // get parent row of first visble row
    const firstVisibleRow = YAxisDataFinder.getRowByYPosition(dataSet, scrollTop);
    const parentRow = this._getTopParentRow(firstVisibleRow.id);
    const parentRowId = parentRow.originalResource || parentRow.id;

    // apply new sticky rows
    let stickyRowHeightsCombined = 0;
    let isPreviousRowSticky = false;
    let firstNotStickyRow: GanttCanvasRow = undefined;
    const newStickyRows = new Set<GanttCanvasRow>();
    const newStickyRowCache = new Set<string>();

    for (const row of dataSet) {
      if (row.id === parentRowId || row.id.startsWith(parentRowId + this._executer.getSplitPlugInId() || '')) {
        row.sticky = true;
        stickyRowHeightsCombined += row.height;
        newStickyRows.add(row);
        newStickyRowCache.add(row.id);
        isPreviousRowSticky = true;
      } else {
        row.sticky = false;
        if (isPreviousRowSticky) firstNotStickyRow = row;
        isPreviousRowSticky = false;
      }
    }

    // check sticky rows container height limitations
    const nextParentRowId = this._getNextParentRowId(parentRowId);

    // if sticky rows are last rows OR next non-sticky row is another parent row
    //   -> no need for sticky rows
    if (!firstNotStickyRow || firstNotStickyRow.id === nextParentRowId) {
      for (const row of newStickyRows) row.sticky = false;
      this._resetConfig();
      this._stickyRowCache.clear();
      return dataSet;
    }

    // max height
    const containerMaxHeightPx = this._getContainerMaxHeightPx(
      stickyRowHeightsCombined,
      firstNotStickyRow?.y,
      dataSet.find((row) => row.id === nextParentRowId)?.y
    );
    this._ganttDiagram.getConfig().setStickyRowsContainerMaxHeightPx(containerMaxHeightPx);

    // optimal height
    if (!this._isSameRowIds(this._stickyRowCache, newStickyRowCache)) {
      this._ganttDiagram.getConfig().setStickyRowsContainerOptimalHeightPx(0);
    }
    this._stickyRowCache = newStickyRowCache;

    // min height
    const containerMinHeightPx = this._getContainerMinHeightPx(stickyRowHeightsCombined, firstNotStickyRow?.y);
    this._ganttDiagram.getConfig().setStickyRowsContainerMinHeightPx(containerMinHeightPx);

    return dataSet;
  }

  /**
   * Determines the scrollTop position of the default scroll container by taking into account that
   * the height of the scrollable content may change during the next rendering.
   * @returns Determined scrollTop position of the default scroll container.
   */
  private _getScrollTopPosition(): number {
    const rowHeightsCombined = this._ganttDiagram
      .getRenderDataHandler()
      .getYAxisDataFinder()
      .getRowHeightsCombined([EGanttScrollContainer.DEFAULT])
      .get(EGanttScrollContainer.DEFAULT);
    const shiftViewportHeight = this._ganttDiagram.getNodeProportionsState().getShiftViewPortProportions().height;

    // get default scrollTop
    let scrollTop = this._ganttDiagram.getNodeProportionsState().getScrollTopPosition() || 0;

    // if current combined row height is too low for scrollTop -> set scrollTop to next possible value
    if (scrollTop > rowHeightsCombined - shiftViewportHeight) {
      scrollTop = rowHeightsCombined - shiftViewportHeight;
    }

    // if scrollTop is smaller than 0 -> correct scrollTop to 0
    if (scrollTop < 0) scrollTop = 0;

    return scrollTop;
  }

  /**
   * Finds and returns the top row (hierachically) of the rowID searched for.
   * @param rowId Row id we want the original ancestor of.
   * @return Ancestor of rowId.
   */
  private _getTopParentRow(rowId: string): GanttDataRow {
    let foundParentRow: GanttDataRow = null;
    const findParentRow = function (
      child: GanttDataRow,
      level: number,
      parent: GanttDataRow,
      index: { index: number },
      abort: { abort: boolean }
    ) {
      if (!parent) parent = child;
      if (child.id === rowId || child.originalResource === rowId) {
        foundParentRow = parent;
        abort.abort = true;
      }
    };
    DataManipulator.iterateOverDataSet(this._ganttDiagram.getDataHandler().getOriginDataset().ganttEntries, {
      findParentRow: findParentRow,
    });

    const foundRowId = foundParentRow.originalResource || foundParentRow.id;
    if (foundRowId === rowId) {
      return foundParentRow;
    }
    return this._getTopParentRow(foundRowId);
  }

  /**
   * Finds the parent row which follows the parent row with the specified id and returns its id.
   * @param parentRowId Id of the parent row for which the following parent row should determined.
   * @return Id of the parent row which follows the parent row with the specified id.
   */
  private _getNextParentRowId(parentRowId: string): string {
    const parentRows = this._ganttDiagram.getDataHandler().getOriginDataset().ganttEntries;
    const parentIndex = parentRows.findIndex((row) => row.id === parentRowId);
    if (parentIndex < 0 || parentIndex >= parentRows.length) return null;
    let nextParentRow: GanttDataRow = null;
    for (let i = 0; i < parentRows.length; i++) {
      if (i <= parentIndex) continue;
      const row = parentRows[i];
      if (!row.originalResource) {
        nextParentRow = row;
        break;
      }
    }
    return nextParentRow?.id;
  }

  /**
   * Determines the maximum height of the sticky rows scroll container by the given values.
   * @param rowHeightsCombined Height of all sticky rows combined (in px).
   * @param firstNotStickyRowY Canvas data y position of the first row below all sticky rows (in px).
   * @param nextParentRowY Canvas data y position of the next parent row below all sticky rows (in px).
   * @returns Maximum height of the sticky rows scroll container determined by the given values (in px).
   */
  private _getContainerMaxHeightPx(
    rowHeightsCombined: number,
    firstNotStickyRowY: number = undefined,
    nextParentRowY: number = undefined
  ): number {
    const shiftViewportHeight = this._ganttDiagram.getNodeProportionsState().getShiftViewPortProportions().height;
    const scrollTop = this._ganttDiagram.getNodeProportionsState().getScrollTopPosition();

    const containerMaxHeightRelative = this._ganttDiagram.getConfig().stickyRowsContainerMaxHeight();
    const containerMaxHeightRelativePx = containerMaxHeightRelative * shiftViewportHeight;

    const firstNotStickyRowViewportY = isNaN(firstNotStickyRowY) ? undefined : firstNotStickyRowY - scrollTop;
    const nextParentRowViewportY = isNaN(nextParentRowY) ? undefined : nextParentRowY - scrollTop;

    // if sticky rows are higher than allowed AND there would be a gap between sticky rows container and first non-sticky row
    //   -> set max height to viewport y of first non-sticky row
    if (
      !isNaN(firstNotStickyRowViewportY) &&
      rowHeightsCombined > containerMaxHeightRelativePx &&
      firstNotStickyRowViewportY > containerMaxHeightRelativePx
    ) {
      this._ganttDiagram.getConfig().setStickyRowsAllowUnlimitedContainerHeight(true);
      return Math.max(Math.min(firstNotStickyRowViewportY, shiftViewportHeight - 1), containerMaxHeightRelativePx);
    }
    // else if viewport y of next parent row is smaller than sticky rows height
    //   -> set max height to viewport y of next parent row
    if (
      !isNaN(nextParentRowViewportY) &&
      rowHeightsCombined > nextParentRowViewportY &&
      nextParentRowViewportY < containerMaxHeightRelativePx
    ) {
      this._ganttDiagram.getConfig().setStickyRowsAllowUnlimitedContainerHeight(false);
      return Math.min(nextParentRowViewportY, shiftViewportHeight - 1);
    }
    // else
    //   -> use max height as specified
    this._ganttDiagram.getConfig().setStickyRowsAllowUnlimitedContainerHeight(false);
    return Math.min(rowHeightsCombined, shiftViewportHeight - 1);
  }

  /**
   * Determines the minimum height of the sticky rows scroll container by the given values.
   * @param rowHeightsCombined Height of all sticky rows combined (in px).
   * @param firstNotStickyRowY Canvas data y position of the first row below all sticky rows (in px).
   * @returns Minimum height of the sticky rows scroll container determined by the given values (in px).
   */
  private _getContainerMinHeightPx(rowHeightsCombined: number, firstNotStickyRowY: number = undefined): number {
    const scrollTop = this._ganttDiagram.getNodeProportionsState().getScrollTopPosition();

    const firstNotStickyRowViepwortY = isNaN(firstNotStickyRowY) ? undefined : firstNotStickyRowY - scrollTop;

    // if there are sticky rows AND there could be a gap between sticky rows container and first non-sticky row when
    // resizing manually
    //   -> set min height to viewport y of first non-sticky row
    if (rowHeightsCombined > 0) {
      if (!isNaN(firstNotStickyRowViepwortY) && firstNotStickyRowViepwortY > 0) {
        return firstNotStickyRowViepwortY;
      }
    }

    // else
    //   -> set min height to disabled
    return 0;
  }

  /**
   * Checks if two specified {@link Set}s contain the same row ids.
   * @param ids1 1st {@link Set} to be checked.
   * @param ids2 2nd {@link Set} to be checked.
   * @returns `true` if both {@link Set}s contain the same row ids, `false` if not.
   */
  private _isSameRowIds(ids1: Set<string>, ids2: Set<string>): boolean {
    // if sizes do not match -> not the same rows
    if (ids1.size !== ids2.size) return false;

    // if sizes do match -> check if all ids match
    for (const id1 of ids1.values()) {
      if (!ids2.has(id1)) return false;
    }
    return true;
  }

  /**
   * Resets the gantt config to the default values of this sticky row strategy.
   */
  private _resetConfig(): void {
    const config = this._ganttDiagram.getConfig();
    config.setStickyRowsAllowUnlimitedContainerHeight(false);
    config.setStickyRowsContainerMinHeightPx(0);
    config.setStickyRowsContainerMaxHeightPx(0);
    config.setStickyRowsContainerOptimalHeightPx(0);
  }
}
