import * as d3 from 'd3';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GanttCallBackStackExecuter } from '../../callback-tools/callback-stack-executer';
import { YAxisDataFinder } from '../../data-handler/data-finder/yaxis-data-finder';
import { GanttCanvasShift } from '../../data-handler/data-structure/data-structure';
import { GanttUtilities } from '../../gantt-utilities/gantt-utilities';
import { GanttScrollContainerEvent } from '../../html-structure/scroll-container-event';
import { EGanttScrollContainer } from '../../html-structure/scroll-container.enum';
import { BestGantt } from '../../main';
import { PatternType } from '../../pattern/pattern-type.enum';
import { PatternHandler } from '../../pattern/patternHandler';
import { IShiftClickEvent } from '../../shifts/shift-events.interface';
import { BestGanttPlugIn } from '../gantt-plug-in';
import { GanttSplitOverlappingShifts } from '../split-overlapping-shifts/split-overlapping-shifts-executer';
import { GanttTimePeriodExecuter, TimePeriodAddData } from './executer-timeperiod-marker';
import { GanttTimePeriodStrokeHandlerDataItem } from './stroke-handler';
import { TimePeriodFilter } from './timperiod-filter';

/**
 * Plug in which handles use of multiple executer-timeperiod-markers.
 * Use this class even for single timeperiod handling.
 * Handles special cases like that there can be only one time period handler in editing mode or be build by drag at the same time.
 * @keywords plugin, executer, wrapper, timeperiod, blocking, interval, holiday, illness, free, time
 * @plugin timeperiod-marker
 */
export class GanttTimePeriodGroupExecuter extends BestGanttPlugIn {
  private _periodExecuter: { [id: string]: GanttTimePeriodExecuter } = {};
  private _overlapExecuter: GanttTimePeriodExecuter = undefined;
  private _patternHandler: PatternHandler = undefined;
  private _timePeriodFilter = new TimePeriodFilter(this);

  private _splitOverlappingShiftsPlugin: GanttSplitOverlappingShifts = undefined;

  private _activeIntervalId: string;
  private _drawIntervalAfterSelectionBoxDraw: boolean;
  private _shiftsAreResizing: boolean;
  private _editModePeriodIds: string[];
  private _selectionCallCnt: number;
  private _selections: any[];
  private _overlapPatternType: PatternType;

  private _callBack: any;

  private _onDestroySubject: Subject<void> = new Subject<void>();

  constructor() {
    super(); // call super-constructor

    this._editModePeriodIds = [];
    this._activeIntervalId = null;
    this._selectionCallCnt = 0;
    this._selections = [];

    this._callBack = {
      dragStart: {},
      dragEnd: {},
      // executed after intervals were selected in active plug in
      selectionEnd: {},
      // executed after intervals were removed in active plug in
      afterRemoveCallback: {},
      dragEndBeforeMarkerAdding: {},
      multipleSelectionCallback: null,
    };

    // backend option: do not draw blocking interval after dragging selection box
    this._drawIntervalAfterSelectionBoxDraw = true;
  }

  /**
   * @override
   */
  public initPlugIn(ganttDiagram: BestGantt): void {
    this.ganttDiagram = ganttDiagram;

    for (const scrollContainerId of this.ganttDiagram.getRenderDataHandler().getAllScrollContainerIds()) {
      this.ganttDiagram
        .getShiftFacade()
        .getTextOverlay(scrollContainerId)
        .subscribeOnTextDataManipulation('addTextFromBlockingIntervals' + this.UUID, (dataRef) => {
          this._addBlockingIntervalsToTextRendering(new GanttScrollContainerEvent(scrollContainerId, dataRef));
        });
    }
    this.ganttDiagram
      .getShiftResizer()
      .onShiftEditStart()
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        this._shiftsAreResizing = true;
      });
    this.ganttDiagram
      .getShiftResizer()
      .onShiftEditEnd()
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        this._shiftsAreResizing = false;
        this.ganttDiagram.rerenderShiftTextOverlay();
      });

    this.ganttDiagram
      .getShiftFacade()
      .shiftAreaMouseMiss()
      .pipe(takeUntil(this.onDestroy))
      .subscribe((event) => this._handleMouseOverTimePeriod(event));

    // register force canvas double click callback for time periods which are in edit mode
    this.ganttDiagram.getShiftFacade().addForceCanvasDoubleClickCb(this.UUID, (event) => {
      const hitIntervals = this.getTimePeriodsByMouseEvent(event).filter((elem) => elem.interval.isInEditMode());
      if (hitIntervals && hitIntervals.length > 0) return true;
      return false;
    });

    this._patternHandler = new PatternHandler(
      this.ganttDiagram
        .getShiftFacade()
        .getShiftBuilder()
        .getShiftDefsBehindShifts()
        .select<SVGDefsElement>('defs')
        .node(),
      this.ganttDiagram.getConfig()
    );
    this._initOverlapExecuter();

    // ! may be gone this.ganttDiagram.getXAxisBuilder().addToZoomCallback(`blockingInterval render Overlap ${this.UUID}`, this._renderOverlappingBlockingIntervals.bind(this));

    this.ganttDiagram
      .getPlugInHandler()
      .subscribeToPlugIns(`subscribeShiftSplitPlugin ${this.UUID}`, this._subscribeToShiftSplitPlugin.bind(this));
  }

  /**
   * Calculates the overlapping blocking intervals.
   *
   */
  private _renderOverlappingBlockingIntervals() {
    /**
     * A map of all marked areas by y position.
     * The key is the y position, the value is an array of all marked areas on that y position.
     */
    const markedAreaData: Map<number, GanttCanvasShift[]> = new Map();

    for (const key in this._periodExecuter) {
      const executer = this._periodExecuter[key];
      if (!executer.isRendered()) continue;

      // get all marked areas by y position
      executer.getVisibleMarkerData().forEach((interval) => {
        if (interval.y === null) return; // ignore empty y values
        if (!markedAreaData.get(interval.y)) {
          markedAreaData.set(interval.y, [interval]);
        } else {
          markedAreaData.get(interval.y).push(interval);
        }
      });
    }

    if (!markedAreaData.size) {
      return;
    }

    /**
     * A map of all overlapping areas.
     */
    const overlappingAreas: Map<
      string,
      {
        id1: string;
        marker1: GanttCanvasShift;
        id2: string;
        marker2: GanttCanvasShift;
        rowY: number;
        start: number;
        end: number;
      }
    > = new Map();

    const scale = this.ganttDiagram.getXAxisBuilder().getGlobalScale();

    // check each row for overlapping intervals
    for (const row of markedAreaData.values()) {
      row.forEach((marker) => {
        row.forEach((compare) => {
          if (marker.id === compare.id) return;
          if (marker.y === compare.y) {
            let minShift = marker,
              maxShift = compare;

            if (minShift.x > maxShift.x) {
              minShift = compare;
              maxShift = marker;
            }

            const minShiftEndX = minShift.x + minShift.width;

            if (minShiftEndX > maxShift.x) {
              // overlap
              if (
                overlappingAreas.get(`${minShift.id}-${maxShift.id}`) ||
                overlappingAreas.get(`${maxShift.id}-${minShift.id}`)
              ) {
                // overlap is already in the dataset!
              } else {
                const maxShiftEndX = maxShift.x + maxShift.width;

                overlappingAreas.set(`${minShift.id}-${maxShift.id}`, {
                  id1: minShift.id,
                  marker1: minShift,
                  id2: maxShift.id,
                  marker2: maxShift,
                  rowY: minShift.y,
                  start: maxShift.x,
                  end: maxShiftEndX < minShiftEndX ? maxShiftEndX : minShiftEndX,
                });
              }
            }
          }
        });
      });
    }

    this._overlapExecuter.removeAllTimePeriods();

    const rowYMap = new Map<number, string>();

    const periods: TimePeriodAddData[] = [...overlappingAreas.values()].map((timePeriod): TimePeriodAddData => {
      let rowId = rowYMap.get(timePeriod.rowY);
      if (!rowId) {
        rowId = YAxisDataFinder.getRowByYPosition(
          this.ganttDiagram.getDataHandler().getYAxisDataset(),
          timePeriod.rowY
        )?.id;

        rowYMap.set(timePeriod.rowY, rowId);
      }

      const marker1 = timePeriod.marker1;
      const color1 = marker1.color;
      const marker2 = timePeriod.marker2;
      let color2 = marker2.color;

      if (color1 === color2) {
        color2 = '#333333';
      }

      return {
        rowId: rowId,
        timeStart: this.ganttDiagram.getXAxisBuilder().pxToTime(timePeriod.start, scale),
        timeEnd: this.ganttDiagram.getXAxisBuilder().pxToTime(timePeriod.end, scale),
        intervalId: timePeriod.id1 + timePeriod.id2,
        customColor: color1,
        stroke: null,
        tooltip: marker1.tooltip + '<hr>' + marker2.tooltip,
        name: 'OVERLAP_INTERVAL',
        pattern: this._overlapPatternType,
        patternColor: color2,
      };
    });

    this._overlapExecuter.addTimePeriodsToRow(periods, false, false);

    this.sortAllIntervals();
    this._overlapExecuter.updatePlugInHeight();
  }

  /**
   * Sorts all intervals in the time period marker group.
   */
  public sortAllIntervals() {
    for (const key in this._periodExecuter) {
      const executer = this._periodExecuter[key];
      executer.sortMarkerData();
    }
  }

  /**
   * Just looking for a splitOverlapping Shifts Plugin, it's ID is needed for calculating all the rows that belong to a resource/entry.
   * @param {BestGanttPlugin} bestGanttPlugin
   */
  private _subscribeToShiftSplitPlugin(bestGanttPlugin) {
    try {
      // catch that GanttSplitOverlappingShifts is not defined
      if (!(bestGanttPlugin instanceof GanttSplitOverlappingShifts)) {
        return;
      }
    } catch (e) {
      console.warn(`GanttSplitOverlappingShifts is not defined`, e);
      this.ganttDiagram.getPlugInHandler().unSubscribeToPlugIns(`subscribeShiftSplitPlugin ${this.UUID}`);
      return;
    }

    this.ganttDiagram.getPlugInHandler().unSubscribeToPlugIns(`subscribeShiftSplitPlugin ${this.UUID}`);
    this._splitOverlappingShiftsPlugin = bestGanttPlugin;
    for (const key in this._periodExecuter) {
      const executer = this._periodExecuter[key];
      executer.setSplitId(this._splitOverlappingShiftsPlugin.UUID);
    }
    this._overlapExecuter.setSplitId(this._splitOverlappingShiftsPlugin.UUID);
  }

  updateHeightToMatchResourceHeight() {
    for (const key in this._periodExecuter) {
      const executer = this._periodExecuter[key];
      executer.setSplitId(this._splitOverlappingShiftsPlugin.UUID);
    }
    this._overlapExecuter.setSplitId(this._splitOverlappingShiftsPlugin.UUID);
  }

  /**
   * Special instance of a time-period-executer that visualises overlapping intervals of other instances and their data.
   * The intervals in this instance are those overlapping intervals.
   * They are not hoverable/clickable/draggable/resizable as they ARE NOT in the loop where the usual instances reside.
   *
   */
  private _initOverlapExecuter() {
    this._overlapExecuter = new GanttTimePeriodExecuter();
    this._overlapExecuter.disableLogging();

    this._overlapExecuter.initPlugIn(this.ganttDiagram, this._patternHandler);
    this._overlapExecuter.setColor('#555555');
    this._overlapExecuter.setStroke(null);
    this._overlapExecuter.setMixedEditModeAvailable(false);
    this._overlapExecuter.setOverlapVisualization(true);

    for (const scrollContainerId of this.ganttDiagram.getRenderDataHandler().getAllScrollContainerIds()) {
      // set other class than normal on this canvas to keep the node ontop all the others
      this._overlapExecuter.getMarkedPeriodBuilder().getCanvas(scrollContainerId).attr('class', 'interval-overlaps');
    }

    this._overlapPatternType = PatternType.HORIZONTAL_STRIPE_6;
  }

  /**
   * Remove of plugin. Part of plugin lifecycle.
   */
  public removePlugIn(): void {
    this.deactivateAllIntervals();
    this.closeAllEditMode();

    for (const scrollContainerId of this.ganttDiagram.getRenderDataHandler().getAllScrollContainerIds()) {
      this.ganttDiagram
        .getShiftFacade()
        .getTextOverlay(scrollContainerId)
        .unsubscribeOnTextDataManipulation('addTextFromBlockingIntervals' + this.UUID);
    }

    // remove force canvas double click callback for time periods which are in edit mode
    this.ganttDiagram.getShiftFacade().removeForceCanvasDoubleClickCb(this.UUID);

    for (const key in this._periodExecuter) {
      this._periodExecuter[key].removePlugIn();
    }
    this._overlapExecuter.removePlugIn();

    this._onDestroySubject.next();
    this._onDestroySubject.complete();
  }

  /**
   * Adds new time period executer and init it by given data.
   * Registers own callback functions to new interval.
   * @param {GanttTimePeriodGroupIntervalInput} intervalInput settings for new time period executer.
   * @return {GanttTimePeriodExecuter} Created time period executer.
   */
  public addInterval(intervalInput: GanttTimePeriodGroupIntervalInput): GanttTimePeriodExecuter {
    const pEID = intervalInput.id;
    this._periodExecuter[pEID] = new GanttTimePeriodExecuter();
    this._periodExecuter[pEID].disableLogging();

    this._periodExecuter[pEID].initPlugIn(this.ganttDiagram);
    this._periodExecuter[pEID].setColor(intervalInput.color);
    this._periodExecuter[pEID].setStroke(intervalInput.stroke);
    this._periodExecuter[pEID].setType(intervalInput.type);
    this._periodExecuter[pEID].setMixedEditModeAvailable(intervalInput.mixedEditModeAvailable);
    this._periodExecuter[pEID].addDragStartCallback('_internal_dragStartCallback', this._dragStartCallback.bind(this));
    this._periodExecuter[pEID].addDragEndCallback('_internal_dragEndCallback', this._dragEndCallback.bind(this));
    this._periodExecuter[pEID].selectionEndCallback('_internal_groupCallback', this._afterSelectionCallback.bind(this));
    this._periodExecuter[pEID].afterRemoveCallback('_internal_remove_Callback', this._afterRemoveCallback.bind(this));
    this._periodExecuter[pEID].dragEndBeforeMarkerAdding(
      '_internal__dragEndBeforeMarkerAdding_Callback',
      this._dragEndBeforeMarkerAddingCallback.bind(this)
    );
    this._periodExecuter[pEID].setDrawIntervalAfterSelectionBoxDraw(this._drawIntervalAfterSelectionBoxDraw);

    // add predefined time periods
    if (intervalInput.timePeriods) {
      const periods: TimePeriodAddData[] = intervalInput.timePeriods.map((timePeriod): TimePeriodAddData => {
        return {
          rowId: timePeriod.rowId,
          timeStart: new Date(timePeriod.timeStart),
          timeEnd: new Date(timePeriod.timeEnd),
          intervalId: timePeriod.intervalId,
          customColor: timePeriod.customColor,
          stroke: timePeriod.stroke,
          tooltip: timePeriod.tooltip,
          name: timePeriod.name,
          sIds: timePeriod.sIds,
        };
      });

      this._periodExecuter[pEID].addTimePeriodsToRow(periods);
    }
    this._periodExecuter[pEID].enableLogging();
    this._periodExecuter[pEID].addAfterSelectByClickCallback(
      '_onlyOneSelectedExecuter',
      this._handleMultipleExecuterSelectionByClick.bind(this, this._periodExecuter[pEID])
    );
    this._periodExecuter[pEID].addAfterActiveStatusChanged(
      '_internal_activation_handling',
      this._handleTimePeriodActivation.bind(this, pEID)
    );

    this._periodExecuter[pEID].markerResizer
      .onShiftEditStart()
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        this._shiftsAreResizing = true;
      });
    this._periodExecuter[pEID].markerResizer
      .onShiftEditEnd()
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        this._shiftsAreResizing = false;
      });

    if (this._splitOverlappingShiftsPlugin) {
      this._periodExecuter[pEID].setSplitId(this._splitOverlappingShiftsPlugin.UUID);
    }

    // put overlap executer below all others again.
    for (const scrollContainerId of this.ganttDiagram.getRenderDataHandler().getAllScrollContainerIds()) {
      const intervalGroup = this._overlapExecuter.getMarkedPeriodBuilder().getCanvas(scrollContainerId).node();
      const intervalParentNode = intervalGroup.parentNode as SVGElement;
      const linesNode = d3.select(intervalParentNode).select<SVGGElement>('.gantt_vertical-line-group').node();
      intervalParentNode.insertBefore(intervalGroup, linesNode);
    }

    this.ganttDiagram.rerenderShiftTextOverlay();

    return this._periodExecuter[pEID];
  }

  /**
   * Returns the time periods are hit by mouse.
   */
  public getTimePeriodsByMouseEvent(
    mouseEvent: GanttScrollContainerEvent<MouseEvent>
  ): { block: GanttCanvasShift; interval: GanttTimePeriodExecuter }[] {
    const hitTimePeriods = [];

    for (const executer in this._periodExecuter) {
      const timePeriodMarker = this._periodExecuter[executer];
      if (!timePeriodMarker.isRendered()) continue;
      timePeriodMarker.possibleMouseOverCallback(mouseEvent).forEach((period) =>
        hitTimePeriods.push({
          block: period,
          interval: timePeriodMarker,
        })
      );
    }

    // check overlaps
    this._overlapExecuter?.possibleMouseOverCallback(mouseEvent).forEach((period) =>
      hitTimePeriods.push({
        block: period,
        interval: this._overlapExecuter,
      })
    );

    return hitTimePeriods;
  }

  /**
   * Calculates blocking intervals that are hit by the mouse cursor, decides which is it if multiple are hit and calls corresponding callbacks.
   */
  private _handleMouseOverTimePeriod(mouseEvent: GanttScrollContainerEvent<MouseEvent>): void {
    const hitTimeperiods = this.getTimePeriodsByMouseEvent(mouseEvent);

    if (hitTimeperiods.length === 0) {
      for (const executer in this._periodExecuter) {
        this._periodExecuter[executer].getMarkedPeriodBuilder().mouseOut();
      }
      this._overlapExecuter?.getMarkedPeriodBuilder().mouseOut();
      this.ganttDiagram.getShiftFacade().getCanvasInFrontShifts(mouseEvent.source).style('cursor', 'default');
      return; // !early return
    }

    hitTimeperiods.sort((a, b) => {
      let value;
      value = a.block.x - b.block.x;
      if (value === 0) {
        value = b.block.width - a.block.width;
      }
      return value;
    });
    const lastBlock = hitTimeperiods[hitTimeperiods.length - 1];

    if (lastBlock && lastBlock.block) {
      // apply mouse cursor to main canvas
      lastBlock.interval.getMarkedPeriodBuilder().mouseOver(lastBlock.block, mouseEvent);
      this.ganttDiagram.getShiftFacade().getCanvasInFrontShifts(mouseEvent.source).style('cursor', 'pointer');
    }
  }

  /**
   * Hooks subscribes to textOverlay Rendering, inserts blocking Interval Text into Data Stream.
   * Results in coherent text-name rendering of shifts and blocking intervals.
   * @param event Pointer to textOverlayData about to be rendered.
   */
  private _addBlockingIntervalsToTextRendering(
    event: GanttScrollContainerEvent<{ dataset: GanttCanvasShift[] }>
  ): void {
    if (this._shiftsAreResizing) {
      return; // dont add text during resizing of shifts
    }

    let markedAreaData = [];
    for (const key in this._periodExecuter) {
      const executer: GanttTimePeriodExecuter = this._periodExecuter[key];
      if (!executer.isRendered()) continue;
      const canvasShifts = executer.getVisibleMarkerData();
      markedAreaData = markedAreaData.concat(canvasShifts);
    }

    if (markedAreaData.length === 0) {
      return;
    }

    const offset = 200;

    const canvasTransform = this.ganttDiagram.getRenderDataHandler().getCanvasTransform().access(event.source);
    const lineTop = this.ganttDiagram.getConfig().getLineTop();

    const shiftViewPortProportions = this.ganttDiagram
      .getNodeProportionsState()
      .getShiftViewPortProportions(event.source);
    const scrollTop = this.ganttDiagram.getNodeProportionsState().getScrollTopPosition(event.source);

    const leftSide = canvasTransform.left;
    const rightSide = canvasTransform.left + shiftViewPortProportions.width;
    const upperSide = scrollTop - offset;
    const lowerSide = scrollTop + shiftViewPortProportions.height + lineTop + offset;

    markedAreaData = markedAreaData.sort((a, b) => {
      if (a.y < b.y) return -1;
      if (a.y > b.y) return 1;
      return 0;
    });

    for (const key in this._periodExecuter) {
      const executer = this._periodExecuter[key];
      if (!executer.isRendered()) continue;
      executer._updateTimePeriodYPositions();
    }

    const filtered = this._getBlockingIntervalRenderDataSet(
      markedAreaData,
      leftSide,
      rightSide,
      canvasTransform.scaleX,
      upperSide,
      lowerSide,
      canvasTransform.scaleY,
      event.source
    );

    let filteredData = filtered;
    filteredData = filteredData.map((shift) => {
      const shiftCopy = structuredClone(shift);
      shiftCopy.y += lineTop;
      const targetRowCanvasData = YAxisDataFinder.getRowByYPosition(
        this.ganttDiagram.getDataHandler().getYAxisDataset(),
        shiftCopy.y
      );
      if (targetRowCanvasData && shiftCopy.height > targetRowCanvasData.height) {
        const lastRow = YAxisDataFinder.getRowByYPosition(
          this.ganttDiagram.getDataHandler().getYAxisDataset(),
          targetRowCanvasData.y + shiftCopy.height - 1
        );
        const lastRowHeight = lastRow ? lastRow.height : 30;

        const additionalOffset = shiftCopy.height - lastRowHeight;
        shiftCopy.y = shiftCopy.y + additionalOffset;
        shiftCopy.height = shiftCopy.height - additionalOffset;

        const additionalRenderOffset =
          this.ganttDiagram.getRenderDataHandler().getStateStorage().getYPositionShift(shiftCopy.id) + additionalOffset;
        this.ganttDiagram
          .getRenderDataHandler()
          .getStateStorage()
          .setYPositionShift(shiftCopy.id, additionalRenderOffset);
      }
      return shiftCopy;
    });

    // multiple overlap label handling
    filteredData.forEach((block) => {
      let overlapCount = 1;
      for (const compareBlock of filteredData) {
        if (compareBlock.id != block.id && compareBlock.x === block.x && compareBlock.y === block.y) {
          overlapCount++;
        }
      }
      if (overlapCount > 1) {
        block.name = overlapCount + ' Intervalle';
      }
    });

    event.event.dataset = event.event.dataset.concat(filteredData);

    event.event.dataset = event.event.dataset.sort((a, b) => {
      if (a.x < b.x) return -1;
      if (a.x > b.x) return 1;
      return 0;
    });

    event.event.dataset = event.event.dataset.sort((a, b) => {
      if (a.y < b.y) return -1;
      if (a.y > b.y) return 1;
      return 0;
    });
  }

  /**
   * Equivalent to {@link}GanttRenderer.getRenderDataSet without the side effects of callback stacks
   */
  private _getBlockingIntervalRenderDataSet(
    currentDataSet: GanttCanvasShift[],
    xMin: number,
    xMax: number,
    scaleX: number,
    yMin: number,
    yMax: number,
    scaleY: number,
    scrollContainerId: EGanttScrollContainer
  ): GanttCanvasShift[] {
    const yDataSet: GanttCanvasShift[] = [];
    let renderedDataSet = [];
    if (!currentDataSet) return [];

    for (let i = 0; i < currentDataSet.length; i++) {
      const markedArea = currentDataSet[i];

      // ignore empty y values
      if (markedArea.y == null) continue;

      // ignore marked areas outside of the desired scroll container
      const rowId = YAxisDataFinder.getRowByYPosition(
        this.ganttDiagram.getDataHandler().getYAxisDataset(),
        markedArea.y
      )?.id;
      const sc = this.ganttDiagram.getRenderDataHandler().getStateStorage().getRowScrollContainer(rowId);
      if (sc !== scrollContainerId) continue;

      // if marked area is in viewport -> add it to the dataset
      const markedAreaY = this.ganttDiagram.getRenderDataHandler().getStateStorage().getYPositionRow(rowId);
      if (markedAreaY * scaleY >= yMin) {
        this.ganttDiagram.getRenderDataHandler().getStateStorage().setYPositionShift(markedArea.id, markedAreaY);
        yDataSet.push(currentDataSet[i]);
      }

      // if y position of marked area is bigger than the maximum y of the viewport
      //   -> stop filtering as only marked areas with higher y position will come
      if (markedAreaY * scaleY > yMax) break;
    }
    for (let i = 0; i < yDataSet.length; i++) {
      if ((yDataSet[i].x + yDataSet[i].width) * scaleX >= xMin && yDataSet[i].x * scaleX <= xMax) {
        renderedDataSet.push(yDataSet[i]);
      }
    }

    renderedDataSet = renderedDataSet.filter(function (element) {
      return !element.noRender || !element.noRender.length;
    });

    return renderedDataSet;
  }

  /**
   * Handles the selection of overlapping time periods.
   * @param timePeriodExecuter Executer from which the click event originates.
   * @param event Click event.
   * @param doubleClick If true, the event will be handled as double click event.
   */
  private async _handleMultipleExecuterSelectionByClick(
    timePeriodExecuter: GanttTimePeriodExecuter,
    event: GanttScrollContainerEvent<IShiftClickEvent>
  ): Promise<void> {
    // get all hit intervals which are in edit mode
    const hitIntervals = this.getTimePeriodsByMouseEvent(
      new GanttScrollContainerEvent(event.source, event.event.event)
    ).filter((elem) => elem.interval.isInEditMode());

    // get answer from selection message
    try {
      if (hitIntervals.length > 1 && this._callBack.multipleSelectionCallback) {
        // multiple hit and callback must be set
        const selection: { block: GanttCanvasShift; interval: GanttTimePeriodExecuter } = await new Promise(
          (res, reject) => {
            this._callBack.multipleSelectionCallback({ res: res, reject: reject, intervals: hitIntervals });
          }
        );
        timePeriodExecuter = selection.interval; // set new executer
        selection.interval.handleClick(event.event, selection.block); // handle click on selected block
      } else {
        hitIntervals[0].interval.handleClick(event.event, hitIntervals[0].block);
      }

      for (const key in this._periodExecuter) {
        if (this._periodExecuter[key].UUID === timePeriodExecuter.UUID) {
          GanttCallBackStackExecuter.execute(
            this._callBack.selectionEnd,
            this._periodExecuter[key].getSelectedMarkers()
          );
          continue;
        }
        // For all other executers deselect timePeriods;
        this._periodExecuter[key].deselectTimePeriods();
      }
    } catch (error) {
      return;
    }
  }

  /**
   * Callback function to synchronize (de-)activation inside time period plug ins.
   */
  private _handleTimePeriodActivation(timePeriodExecuterId, activated) {
    const matchingIntervalExecuter = this._periodExecuter[timePeriodExecuterId];
    if (!matchingIntervalExecuter) return;
    this._activeIntervalId = activated ? timePeriodExecuterId : null;
  }

  /**
   * Activates (building time period by drag by) given time period executer.
   * @param {string} intervalId Id of time period executer.
   * @returns {boolean} True if interval id does exist and matching interval handler is activated.
   */
  activateIntervalById(intervalID) {
    this.deactivateAllIntervals();
    if (this._periodExecuter[intervalID]) {
      this._periodExecuter[intervalID].activate();
      this._activeIntervalId = intervalID;
      return true;
    }
    return false;
  }

  /**
   * Deactivates given time period executer.
   * @param {string} intervalId Id of time period executer.
   */
  deactivateIntervalById(intervalId) {
    this._periodExecuter[intervalId].deactivate();
    this._activeIntervalId = null;
  }

  /**
   * Deactivates all time period executer.
   */
  deactivateAllIntervals() {
    for (const key in this._periodExecuter) {
      this._periodExecuter[key].deactivate();
    }
    this._activeIntervalId = null;
  }

  /**
   * Toggle activity of given time period executer.
   * @param {string} intervalId Id of time period executer.
   */
  toggleIntervalById(intervalId) {
    if (this._activeIntervalId == intervalId) {
      this.deactivateIntervalById(intervalId);
      return false;
    } else {
      this.deactivateAllIntervals();
      this.activateIntervalById(intervalId);
      return true;
    }
  }

  /**
   * Activates edit mode of given time period executer.
   * Deactivates all other edit modes.
   * @param {string|string[]} intervalId Id of time period executer or an array of ids.
   */
  editIntervalById(intervalId) {
    this.deactivateAllIntervals();
    this.closeAllEditMode();
    if (Array.isArray(intervalId)) {
      for (const key of intervalId) {
        if (this._periodExecuter[key]) {
          this.toggleEditModeOnMouseOver(key, false);
          this._periodExecuter[key].activateEditMode();
        }
      }
      if (intervalId.length) {
        this._overlapExecuter.activateEditMode();
      }
      this._editModePeriodIds = intervalId;
    } else {
      if (!this._periodExecuter[intervalId]) return;
      this.toggleEditModeOnMouseOver(intervalId, false);
      this._periodExecuter[intervalId].activateEditMode();
      this._editModePeriodIds = [intervalId];
      this._overlapExecuter.activateEditMode();
    }
  }

  /**
   * Toggles edit mode activation on mouse over of timePeriods
   * @param {string} intervalID id of blocking interval to toggle mouse over edit mode
   * @param {boolean} enable true: enables mouse over, false: disables mouse over
   */
  toggleEditModeOnMouseOver(intervalID, enable) {
    const timePeriodExecuter = this._periodExecuter[intervalID];
    timePeriodExecuter.toggleEditModeOnMouseOver(enable);
  }

  /**
   * Toggle edit mode of given time period executer.
   * Deactivates all other edit modes.
   * @param {string|string[]} intervalId Id of time period executer or an array of ids.
   * @return {boolean} True if edit interval mode is now activated.
   */
  toggleEditIntervalById(intervalId) {
    if (!Array.isArray(intervalId)) intervalId = [intervalId];

    const idsToActivateEditMode = [];

    this.deactivateAllIntervals();

    for (const editModeToggleId of intervalId) {
      if (!GanttUtilities.isStringInArray(editModeToggleId, this._editModePeriodIds)) {
        // edit mode must be activated
        idsToActivateEditMode.push(editModeToggleId);
      }
    }

    this.closeAllEditMode();
    this.editIntervalById(idsToActivateEditMode);

    return !!this._editModePeriodIds.length;
  }

  /**
   * Callback to update blocking interval positions as sticky rows manipulate yPositions.
   */
  updateBlockingIntervalsForStickyParentRow(eventData) {
    for (const key in this._periodExecuter) {
      const executer = this._periodExecuter[key];
      executer.setYAxisDataset(eventData.yAxisDataset);
      executer._updateTimePeriodYPositions();
      executer.getMarkedPeriodBuilder().buildMarkers();
    }

    this._overlapExecuter.setYAxisDataset(eventData.yAxisDataset);
    this._overlapExecuter._updateTimePeriodYPositions();
    this._overlapExecuter.getMarkedPeriodBuilder().buildMarkers();
  }

  removeStickyParentRowConnection() {
    for (const key in this._periodExecuter) {
      const executer = this._periodExecuter[key];
      executer.setYAxisDataset(null);
      executer._updateTimePeriodYPositions();
      executer.getMarkedPeriodBuilder().buildMarkers();
    }
    this._overlapExecuter.setYAxisDataset(null);
    this._overlapExecuter._updateTimePeriodYPositions();
    this._overlapExecuter.getMarkedPeriodBuilder().buildMarkers(true);
  }

  /**
   * Close edit mode and activate last activated time period executer.
   */
  finishIntervalEditing() {
    this.closeAllEditMode();
    if (this._activeIntervalId) this.activateIntervalById(this._activeIntervalId);
  }

  /**
   * Opens edit mode for active interval executer.
   */
  editActiveInterval() {
    if (!this._activeIntervalId) return;
    this.editIntervalById(this._activeIntervalId);
  }

  /**
   * Remove selected time periods of execute handler which is in edit mode.
   */
  removeSelectedTimePeriods() {
    if (!this._activeIntervalId) return;
    this.getActiveIntervalPlugIn().removeSelectedTimePeriods();
  }

  /**
   * Deactivate all edit modes of all time period executers.
   */
  closeAllEditMode() {
    this._overlapExecuter.deactivateEditMode();
    for (const periodKey in this._periodExecuter) {
      this._periodExecuter[periodKey].deactivateEditMode();
      this.toggleEditModeOnMouseOver(periodKey, true);
    }
    this._editModePeriodIds = [];
  }

  /**
   * Executes callbackstack.
   */
  private _dragStartCallback(event) {
    GanttCallBackStackExecuter.execute(this._callBack.dragStart, event);
  }

  /**
   * Executes callbackstack.
   */
  private _dragEndCallback(markedPeriod) {
    GanttCallBackStackExecuter.execute(this._callBack.dragEnd, markedPeriod);
  }

  /**
   * Executes callbackstack.
   */
  private _afterSelectionCallback(markedPeriod) {
    this._selectionCallCnt++;
    this._selections.push(markedPeriod);
    let groupFoundThatIsNotEmpty = null;
    if (
      this._selectionCallCnt ===
      Object.keys(this._periodExecuter).filter((key) => this._periodExecuter[key].isInEditMode()).length
    ) {
      // if all timeperiod executers are called
      for (const group of this._selections) {
        if (group.length && !groupFoundThatIsNotEmpty) {
          // take the first group with selected periods
          groupFoundThatIsNotEmpty = true;
          GanttCallBackStackExecuter.execute(this._callBack.selectionEnd, group);
        } else {
          if (group.length) {
            this._periodExecuter[group[0].type].deselectTimePeriods(); // deselect other groups
          }
        }
      }
      if (!groupFoundThatIsNotEmpty) {
        // nothing selected
        GanttCallBackStackExecuter.execute(this._callBack.selectionEnd, []);
      }
      this._selections = [];
      this._selectionCallCnt = 0;
    }
  }

  /**
   * Executes callbackstack.
   */
  private _afterRemoveCallback() {
    GanttCallBackStackExecuter.execute(this._callBack.afterRemoveCallback);
  }

  /**
   * Executes callbackstack.
   */
  private _dragEndBeforeMarkerAddingCallback() {
    GanttCallBackStackExecuter.execute(this._callBack.dragEndBeforeMarkerAdding);
  }

  /**
   * @override
   */
  update() {
    this.getTimePeriodFilter().filter();
    for (const key in this._periodExecuter) {
      if (this._periodExecuter[key].update) this._periodExecuter[key].update();
    }
    this._renderOverlappingBlockingIntervals();
    this._overlapExecuter.update();
  }

  //
  // GETTER & SETTER
  //

  getTimePeriodFilter() {
    return this._timePeriodFilter;
  }

  /**
   * Checks if there is any timeperiod executer which has the edit mode activated.
   */
  isInEditMode() {
    return !!this._editModePeriodIds.length;
  }

  /**
   * Checks if there is any timeperiod executer which has the creation mode activated.
   */
  isActive() {
    return !!this._activeIntervalId;
  }

  /**
   * @param {string} id Time period executer id.
   * @return {GanttTimePeriodExecuter} Time period executer by given id.
   */
  getPeriodExecuterById(id) {
    return this._periodExecuter[id];
  }

  /**
   * Returns a timeperiod by the given id.
   * @param {string} id id of period
   */
  getPeriodById(id) {
    for (const key in this._periodExecuter) {
      if (this._periodExecuter[key].getTimePeriodById) {
        const result = this._periodExecuter[key].getTimePeriodById(id);
        if (result) return result;
      }
    }
    return null;
  }

  /**
   * Checks if there are any registered time period executer.
   */
  hasPeriods() {
    return Object.getOwnPropertyNames(this._periodExecuter).length != 0;
  }

  /**
   * @return {Map<string, GanttTimePeriodExecuter>} All registered time period executer.
   */
  getAllIntervals() {
    return this._periodExecuter;
  }

  /**
   * Returns an array containing the ids of all registered time period executers.
   * @return {string[]} Ids of all registered time period executers.
   */
  public getAllIntervalIds(): string[] {
    const ids: string[] = [];
    for (const id in this._periodExecuter) {
      ids.push(id);
    }
    return ids;
  }

  /**
   * @return {GanttTimePeriodExecuter} Currently active time period executer.
   */
  getActiveIntervalPlugIn() {
    return this._periodExecuter[this._activeIntervalId];
  }

  /**
   * @return {string} Id of active time period executer.
   */
  getActiveIntervalId() {
    return this._activeIntervalId;
  }

  /**
   * @return {string} Id of time period executer which is currently in edit mode.
   */
  geteditModePeriodIds() {
    return this._editModePeriodIds;
  }

  /**
   * @return {GanttCanvasShift[]} All selected time periods.
   */
  getMarkedIntervals() {
    if (!this._activeIntervalId) return [];
    return this.getActiveIntervalPlugIn().getSelectedMarkers();
  }

  /**
   * @param {boolean} setDrawIntervalAfterSelectionBoxDraw
   */
  setDrawIntervalAfterSelectionBoxDraw(drawIntervalAfterSelectionBoxDraw) {
    this._drawIntervalAfterSelectionBoxDraw = drawIntervalAfterSelectionBoxDraw;
    for (const key in this._periodExecuter) {
      this._periodExecuter[key].setDrawIntervalAfterSelectionBoxDraw(drawIntervalAfterSelectionBoxDraw);
    }
  }

  /**
   * Sets the render state of a specific executer by its id.
   * @param {string} executuerId Id of the executer.
   * @param {boolean} render New render state.
   */
  public setRenderById(executerId: string, render: boolean) {
    const executer: GanttTimePeriodExecuter = this._periodExecuter[executerId];
    if (!executer) {
      console.warn(`No executer with id '${executerId}' found!`);
      return;
    }
    if (render === executer.isRendered()) return;
    executer.setRender(render);
    this.update();
  }

  updateAllTimePeriodPositions() {
    for (const intervalKey in this.getAllIntervals()) {
      this.getAllIntervals()[intervalKey].updateAllTimePeriodyPos();
    }
    this._overlapExecuter.updateAllTimePeriodyPos();
  }

  //
  // OBSERVABLES
  //

  /**
   * Observable which gets triggered when the instance gets destroyed.
   */
  private get onDestroy(): Observable<void> {
    return this._onDestroySubject.asObservable();
  }

  //
  // CALLBACKS
  //

  addActivePlugInSelectionEndCallback(id, func) {
    this._callBack.selectionEnd[id] = func;
  }

  removeActivePlugInSelectionEndCallback(id) {
    delete this._callBack.selectionEnd[id];
  }

  addActivePlugInDragStartCallback(id, func) {
    this._callBack.dragStart[id] = func;
  }

  removeActivePlugInDragStartCallback(id) {
    delete this._callBack.dragStart[id];
  }

  addActivePlugInDragEndCallback(id, func) {
    this._callBack.dragEnd[id] = func;
  }

  removeActivePlugInDragEndCallback(id) {
    delete this._callBack.dragEnd[id];
  }

  addActivePlugInAfterRemoveCallback(id, func) {
    this._callBack.afterRemoveCallback[id] = func;
  }

  removeActivePlugInAfterRemoveCallback(id) {
    delete this._callBack.afterRemoveCallback[id];
  }

  dragEndBeforeMarkerAdding(id, func) {
    this._callBack.dragEndBeforeMarkerAdding[id] = func;
  }

  removeDragEndBeforeMarkerAdding(id) {
    delete this._callBack.dragEndBeforeMarkerAdding[id];
  }

  public subscribeToMultipleSelectionCallback(func): void {
    this._callBack.multipleSelectionCallback = func;
  }

  public unsubscribeFromMultipleSelectionCallback(): void {
    this._callBack.multipleSelectionCallback = null;
  }

  //
  // PERMISSIONS
  //

  allowShiftDragDrop(bool) {
    for (const intervalKey in this.getAllIntervals()) {
      this.getAllIntervals()[intervalKey].allowShiftDragDrop(bool);
    }
  }

  allowShiftResizer(bool) {
    for (const intervalKey in this.getAllIntervals()) {
      this.getAllIntervals()[intervalKey].allowShiftResizer(bool);
    }
  }

  allowDraggingVertical(bool) {
    for (const intervalKey in this.getAllIntervals()) {
      this.getAllIntervals()[intervalKey].allowDraggingVertical(bool);
    }
  }

  allowDraggingHorizontal(bool) {
    for (const intervalKey in this.getAllIntervals()) {
      this.getAllIntervals()[intervalKey].allowDraggingHorizontal(bool);
    }
  }
}

/**
 * Data class whith settings to generate interval executer.
 * @keywords data, class, settings, interval, build
 * @plugin timeperiod-marker
 * @property {string} id Id of time period builder.
 * @property {string} type Can be helpfull for backend information transfer.
 * @property {string} color Color of time period builder.
 * @property {GanttTimePeriodStrokeHandlerDataItem} stroke Time period stroke setting.
 * @property {GanttTimePeriodInput[]} timePeriods Concrete time periods.
 */
export class GanttTimePeriodGroupIntervalInput {
  public id: string = undefined;
  public type: string = undefined;
  public color: string = undefined;
  public stroke: GanttTimePeriodStrokeHandlerDataItem = undefined;
  public timePeriods: GanttTimePeriodInput[] = [];
  public mixedEditModeAvailable = false;
}

/**
 * Data class to with all necessary information to create a time period.
 * @keywords data, class, time, period, instance, marker
 * @plugin timeperiod-marker
 * @param {string} rowId Id of row.
 * @param {Date} timeStart Start time point of time period.
 * @param {Date} timeEnd End time point of time period.
 * @param {string} intervalId Unique id of time period.
 * @param {GanttTimePeriodStrokeHandlerDataItem} [stroke] Stroke style of new time period.
 * @param {string} tooltip Tooltip for time period.
 * @param {string} name Name on the blocking interval like on shifts.
 */
export class GanttTimePeriodInput {
  sIds: string[];

  constructor(
    public rowId: string,
    public timeStart: Date,
    public timeEnd: Date,
    public intervalId: string,
    public stroke: GanttTimePeriodStrokeHandlerDataItem,
    public tooltip: string,
    public name: string,
    public customColor: string = null
  ) {}
}
