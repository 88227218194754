import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { EntryElement } from '@app-modeleditor/components/entry-collection/entry-element';
import { EntryElementValue } from '@app-modeleditor/components/entry-collection/entry-element-value';
import { EFieldType } from '@app-modeleditor/components/entry-collection/field-type.enum';
import { TemplateValue } from '@app-modeleditor/components/entry-collection/value';
import { EGanttSubMenuUpdateMessageType } from '@app-modules/saxms-submenu-elements/saxms-submenu.enum';
import {
  IGanttSubMenuSyncMessage,
  IGanttSubMenuUpdateMessage,
} from '@app-modules/saxms-submenu-elements/saxms-submenu.interface';
import { TimePeriodFilterItem } from '@gantt/public-api';
import { GlobalUtils } from 'frontend/src/dashboard/global-utils';
import { Gantt_General } from '../../general.gantt.component';
import { GanttEssentialPlugIns } from '../../plugin/e-gantt-essential-plugins';

interface SyncMessageValue {
  isActive: boolean;
  filters: TimePeriodFilterItem[];
}

/**
 * Represents a toggle interval filter by attribute element in the Gantt chart toolbar.
 * This element allows the user to toggle a filter that hides or shows intervals based on their attributes.
 */
export class ToggleIntervalFilterByAttribute extends EntryElement {
  private isActive = true;
  private filters: TimePeriodFilterItem[] = [];
  private readonly internalId: string = GlobalUtils.generateUUID();

  constructor(private scope: Gantt_General) {
    super();
    this.setId('ToggleIntervalFilterByAttribute');
    this.listenToElementStateUpdates();
  }

  get(data: {
    name: string; // label of element
    entry: {
      filters: TimePeriodFilterItem[];
      value: boolean; // initial value of toggle
    };
  }): this {
    const name = data.name || this.scope.translate.instant('@interval-filter@');
    this.isActive = !!data?.entry?.value;
    this.filters = data?.entry?.filters || [];
    this.setFieldType(EFieldType.SLIDE_TOGGLE)
      .setName(name)
      .setAlwaysEnabled(true)
      .setValue(new EntryElementValue().setValue(this.isActive))
      .onChanges((change: TemplateValue) => this.handleFilter(change.getValue()));

    this.handleFilter(this.isActive);

    return this;
  }

  /**
   * Handles the filter for the toggle interval filter by attribute.
   * @param value - The boolean value to set the filter to.
   */
  private handleFilter(value: boolean) {
    this.isActive = value;
    const blockingIntervalsPlugIn = this.scope.ganttPluginHandlerService.getEssentialPlugIn(
      GanttEssentialPlugIns.BlockingIntervalPlugIn
    );

    this.filters.forEach((filter) => {
      if (this.isActive) {
        // remove filter if toggle is active (filter is not active)
        blockingIntervalsPlugIn.groupExecuter.getTimePeriodFilter().removeFilter(filter);
      } else {
        // add filter if toggle is inactive (filter is active)
        blockingIntervalsPlugIn.groupExecuter.getTimePeriodFilter().addFilter(filter);
      }
    });

    blockingIntervalsPlugIn.groupExecuter.update();

    this.handleSync(this.isActive);
  }

  /**
   * Handles the synchronization of the toggle interval filter by attribute.
   * @param syncValue - A boolean value indicating whether the synchronization is active or not.
   */
  private handleSync(syncValue: boolean) {
    const msgValue: IGanttSubMenuSyncMessage<SyncMessageValue> = {
      internalId: this.internalId,
      value: { isActive: syncValue, filters: this.filters },
    };
    const syncMessage: IGanttSubMenuUpdateMessage<IGanttSubMenuSyncMessage<SyncMessageValue>> = {
      elementId: this.getId(),
      type: EGanttSubMenuUpdateMessageType.SYNC,
      value: msgValue,
    };
    this.scope.submenuService.triggerElementById(syncMessage); // trigger sync
  }

  /**
   * Listens to updates in the state of the element and updates the value and visibility of the element accordingly.
   * @private
   */
  private listenToElementStateUpdates() {
    this.scope.submenuService
      .onUpdateElementState()
      .pipe(takeUntilDestroyed(this.scope.destroyRef))
      .subscribe((activationData: IGanttSubMenuUpdateMessage<IGanttSubMenuSyncMessage<SyncMessageValue>>) => {
        const msgValue = activationData?.value as IGanttSubMenuSyncMessage<SyncMessageValue>;
        if (!activationData || activationData.elementId !== this.getId()) {
          return;
        } else if (
          activationData.type === EGanttSubMenuUpdateMessageType.SYNC &&
          msgValue.internalId !== this.internalId &&
          this.areSameFilters(msgValue.value.filters, this.filters)
        ) {
          const newValue = msgValue.value.isActive;
          this.setValue(new EntryElementValue().setValue(newValue));
          this.isActive = newValue;
        }
      });
  }

  /**
   * Checks if two arrays of TimePeriodFilterItem objects are equal.
   * @param filters1 The first array of TimePeriodFilterItem objects to compare.
   * @param filters2 The second array of TimePeriodFilterItem objects to compare.
   * @returns True if the two arrays are equal, false otherwise.
   */
  private areSameFilters(filters1: TimePeriodFilterItem[], filters2: TimePeriodFilterItem[]): boolean {
    if (filters1.length !== filters2.length) {
      return false;
    }
    for (let i = 0; i < filters1.length; i++) {
      if (filters1[i].id !== filters2[i].id) {
        return false;
      }
    }
    return true;
  }
}
