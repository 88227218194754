import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { EDockViewMode } from './dock.enum';
import { IGanttDockComponent, IGanttDockComponentNotification, IGanttDockViews } from './dock.interface';

@Injectable()
export class GanttDockService {
  private _currentDockViews: IGanttDockViews = { right: [], bottom: [], free: [] };
  private _registeredComponents: IGanttDockComponent[] = [];
  private _availableNotificationsOfComponentIDs: string[] = [];
  private _currentDisplayedComponentRight: IGanttDockComponent = null;
  private _currentDisplayedComponentBottom: IGanttDockComponent = null;
  private _currentDisplayedComponentFree: IGanttDockComponent = null;
  private _onViewChangeSubject: BehaviorSubject<void> = new BehaviorSubject(null);
  private _onComponentNotification: BehaviorSubject<IGanttDockComponentNotification> = new BehaviorSubject(null);
  private _onResizeDockStartSubject: Subject<void> = new Subject();
  private _onResizeDockSubject: Subject<void> = new Subject();
  private _onResizeDockEndSubject: Subject<void> = new Subject();

  constructor() {}

  public onTabChangeByViewMode(componentID: string, viewMode: EDockViewMode): void {
    const component = this.getDockComponentById(componentID);
    switch (viewMode) {
      case EDockViewMode.BOTTOM:
        if (componentID === this._currentDisplayedComponentBottom?.id) {
          break;
        } // nothing has changed
        this._currentDisplayedComponentBottom = component;
      case EDockViewMode.RIGHT:
        if (componentID === this._currentDisplayedComponentRight?.id) {
          break;
        } // nothing has changed
        this._currentDisplayedComponentRight = component;
      case EDockViewMode.UNDOCK:
        if (componentID === this._currentDisplayedComponentFree?.id) {
          break;
        } // nothing has changed
        this._currentDisplayedComponentFree = component;
    }
  }

  public getDockComponentById(componentID: string): IGanttDockComponent {
    return this._registeredComponents.find((comp) => comp.id === componentID);
  }

  public getViewTypeByComponentId(componentID: string): EDockViewMode {
    if (this._currentDockViews.bottom.find((id) => id === componentID)) {
      return EDockViewMode.BOTTOM;
    }
    if (this._currentDockViews.right.find((id) => id === componentID)) {
      return EDockViewMode.RIGHT;
    }
    if (this._currentDockViews.free.find((id) => id === componentID)) {
      return EDockViewMode.UNDOCK;
    }
  }

  public getCurrentDockComponentByViewType(viewType: EDockViewMode): IGanttDockComponent {
    switch (viewType) {
      case EDockViewMode.BOTTOM:
        return this._currentDisplayedComponentBottom;
      case EDockViewMode.RIGHT:
        return this._currentDisplayedComponentRight;
      case EDockViewMode.UNDOCK:
        return this._currentDisplayedComponentFree;
    }
  }

  public listenForDockViewChange(): Observable<void> {
    return this._onViewChangeSubject.asObservable();
  }

  public onResizeDockStart(): void {
    this._onResizeDockStartSubject.next();
  }

  public onResizeDock(): void {
    this._onResizeDockSubject.next();
  }

  public onResizeDockEnd(): void {
    this._onResizeDockEndSubject.next();
  }

  public isThereANotificationForComponent(dockComponentId: string): boolean {
    return !!this._availableNotificationsOfComponentIDs.find((elem) => elem === dockComponentId);
  }

  public registerNotificationByDockComponentID(dockComponentId: string): void {
    if (!this._availableNotificationsOfComponentIDs.find((elem) => elem === dockComponentId)) {
      // if not already exists
      this._availableNotificationsOfComponentIDs.push(dockComponentId);
      const notification: IGanttDockComponentNotification = {
        dockComponentId: dockComponentId,
        isNotified: true,
      };
      this._onComponentNotification.next(notification);
    }
  }

  public removeNotificationByDockComponentID(dockComponentId: string): void {
    if (this._availableNotificationsOfComponentIDs.find((elem) => elem === dockComponentId)) {
      // if exists
      this._availableNotificationsOfComponentIDs = this._availableNotificationsOfComponentIDs.filter(
        (elem) => elem != dockComponentId
      );
      const notification: IGanttDockComponentNotification = {
        dockComponentId: dockComponentId,
        isNotified: false,
      };
      this._onComponentNotification.next(notification);
    }
  }

  public listenToDockNotifications(): Observable<IGanttDockComponentNotification> {
    return this._onComponentNotification.asObservable();
  }

  public registerDockComponent(dockComponent: IGanttDockComponent, view: EDockViewMode): void {
    if (this._registeredComponents.find((comp) => comp.id === dockComponent.id)) {
      return;
    } // component already registered
    this._registeredComponents.push(dockComponent);
    this.addComponentToDockByView(dockComponent, view);
  }

  public isDockBottom(): boolean {
    return !!this._currentDockViews.bottom.length;
  }

  public isDockRight(): boolean {
    return !!this._currentDockViews.right.length;
  }

  public isDockFree(): boolean {
    return !!this._currentDockViews.free.length;
  }

  public listenForResizeDockStart(): Observable<void> {
    return this._onResizeDockStartSubject.asObservable();
  }
  public listenForResizeDock(): Observable<void> {
    return this._onResizeDockSubject.asObservable();
  }
  public listenForResizeDockEnd(): Observable<void> {
    return this._onResizeDockEndSubject.asObservable();
  }

  public getDockComponentsByViewType(viewType: EDockViewMode): IGanttDockComponent[] {
    switch (viewType) {
      case EDockViewMode.BOTTOM:
        return this._currentDockViews.bottom.map((componentId) => this.getDockComponentById(componentId));
      case EDockViewMode.RIGHT:
        return this._currentDockViews.right.map((componentId) => this.getDockComponentById(componentId));
      case EDockViewMode.UNDOCK:
        return this._currentDockViews.free.map((componentId) => this.getDockComponentById(componentId));
    }
  }

  public addComponentToDockByView(dockComponent: IGanttDockComponent, viewMode: EDockViewMode): void {
    // first remove id from views
    for (const view in this._currentDockViews) {
      this._currentDockViews[view] = this._currentDockViews[view].filter((id) => id !== dockComponent.id);
    }
    // check if current view are still valid
    this._checkCurrentDockViews();

    // add component id
    switch (viewMode) {
      case EDockViewMode.BOTTOM:
        if (!this._currentDockViews.bottom.length) {
          this._currentDisplayedComponentBottom = dockComponent;
        } // first registered component is displayed
        this._currentDockViews.bottom.push(dockComponent.id);
        break;
      case EDockViewMode.RIGHT:
        if (!this._currentDockViews.right.length) {
          this._currentDisplayedComponentRight = dockComponent;
        } // first registered component is displayed
        this._currentDockViews.right.push(dockComponent.id);
        break;
      case EDockViewMode.UNDOCK:
        if (!this._currentDockViews.free.length) {
          this._currentDisplayedComponentFree = dockComponent;
        } // first registered component is displayed
        this._currentDockViews.free.push(dockComponent.id);
        break;
    }
    // trigger change
    this._onViewChangeSubject.next(null);
  }

  public resetGanttDock(): void {
    this._currentDockViews = { right: [], bottom: [], free: [] };
    this._registeredComponents = [];
    this._availableNotificationsOfComponentIDs = [];
    this._currentDisplayedComponentRight = null;
    this._currentDisplayedComponentBottom = null;
    this._currentDisplayedComponentFree = null;
    this._onViewChangeSubject.next(null);
  }

  private _checkCurrentDockViews(): void {
    if (!this._currentDockViews.bottom.includes(this._currentDisplayedComponentBottom?.id)) {
      this._currentDisplayedComponentBottom = this._currentDockViews.bottom.length
        ? this.getDockComponentById(this._currentDockViews.bottom[0])
        : null;
    }
    if (!this._currentDockViews.right.includes(this._currentDisplayedComponentRight?.id)) {
      this._currentDisplayedComponentRight = this._currentDockViews.right.length
        ? this.getDockComponentById(this._currentDockViews.right[0])
        : null;
    }
    if (!this._currentDockViews.free.includes(this._currentDisplayedComponentFree?.id)) {
      this._currentDisplayedComponentFree = this._currentDockViews.free.length
        ? this.getDockComponentById(this._currentDockViews.free[0])
        : null;
    }
  }

  get dockComponents(): IGanttDockComponent[] {
    return this._registeredComponents;
  }
}
