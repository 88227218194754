import { ETimeMarkerAnchor } from './x-axis';

/**
 * Calculation of correct text direction at date marker.
 * @calculation helper, calculation, text, direaction, position, marker, time, date, xaxis, x axis
 * @class
 * @constructor
 * @static
 */
export class DateMarker {
  private constructor() {}

  /**
   * Calculation of text direction.
   * @keywords text, direction, position, align, multiple, xaxis, x axis, time
   * @param {number} canvasWidth Width of gantt canvas.
   * @param {number} markerXPos Horizontal position of marker inside canvas.
   * @param {number} textWidth Width of marker text.
   * @param {"start"|"middle"|"end"} textDirection Origin text direction of marker text.
   */
  static getTextDirection(
    canvasWidth: number,
    markerXPos: number,
    textWidth: number,
    textDirection: ETimeMarkerAnchor
  ): string {
    const canvas = {
      xMin: 0,
      xMax: canvasWidth,
    };

    let finalTextDirection = '';

    if (markerXPos < 0 || markerXPos > canvas.xMax) return finalTextDirection;

    switch (textDirection) {
      case ETimeMarkerAnchor.START:
        if (markerXPos + textWidth > canvas.xMax) {
          finalTextDirection = 'end';
        }
        break;

      case ETimeMarkerAnchor.MIDDLE:
        if (markerXPos + textWidth / 2 > canvas.xMax) {
          finalTextDirection = 'start';
        } else if (markerXPos - textWidth / 2 < canvas.xMin) {
          finalTextDirection = 'start';
        }
        break;

      case ETimeMarkerAnchor.END:
        if (markerXPos - textWidth < canvas.xMin) {
          finalTextDirection = ETimeMarkerAnchor.START;
        }
        break;
    }

    return finalTextDirection;
  }
}
