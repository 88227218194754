import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { FileProgressComponent } from './file-progress.component';
import { FileSizePipe } from '../file-uploader/progress-dialog/file-size.pipe';
@NgModule({
  imports: [CommonModule, MatProgressBarModule],
  declarations: [FileProgressComponent, FileSizePipe],
  exports: [FileProgressComponent, FileSizePipe],
})
export class FileProgressModule {}
