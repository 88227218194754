import { ETemplateType } from 'frontend/src/dashboard/model/resource/template-type';
import { ContentElement } from '../content/content-element/content-element';

export class WidgetContainer extends ContentElement {
  private getWidgetRestUrl: string;
  private compareRestUrl: string;
  private additionalSubMenus: any;
  public value;

  constructor() {
    super();
    this.setType(ETemplateType.WIDGET_CONTAINER);
  }

  public getAdditionalSubMenus(): any {
    return this.additionalSubMenus;
  }

  public setAdditionalSubMenus(additionalSubMenus: any): this {
    this.additionalSubMenus = additionalSubMenus;
    return this;
  }

  public getGetWidgetRestUrl(): string {
    return this.getWidgetRestUrl;
  }

  public setGetWidgetRestUrl(getWidgetRestUrl: string): this {
    this.getWidgetRestUrl = getWidgetRestUrl;
    return this;
  }

  public getCompareRestUrl(): string {
    return this.compareRestUrl;
  }

  public setCompareRestUrl(compareRestUrl: string): this {
    this.compareRestUrl = compareRestUrl;
    return this;
  }
}
