import * as d3 from 'd3';
import { BestGantt } from '../main';
import { EGanttScrollContainer } from '../html-structure/scroll-container.enum';

/**
 * Class to highlight gantt background daywise.
 * @keywords time, period, background, canvas
 */
export class GlobalTimeSpanMarker {
  private _parentNode: { [id: string]: SVGSVGElement } = {};
  private _shiftCanvas: { [id: string]: d3.Selection<SVGGElement, undefined, d3.BaseType, undefined> } = {};
  private _xAxisCanvas: d3.Selection<SVGGElement, undefined, d3.BaseType, undefined> = undefined;

  private _allWeekendBars: d3.Selection<SVGRectElement, GanttTimePeriodBackgroundItemInterval, SVGGElement, undefined> =
    undefined;

  private _markedTimespan: GanttTimePeriodBackgroundItem[] = [];
  private _markedTimespanWeekend: GanttTimePeriodBackgroundItemDay[] = [];
  private _markedHolidays: GanttTimePeriodBackgroundItemInterval[] = [];

  private _scale: d3.ScaleTime<number, number> = undefined;

  private _hideWeekends = false;
  private _weekendColor = '#b7b7b795';
  private _holidayColor = '#b7b7b795';
  private _extendToXAxis = true;

  /**
   * @param _ganttDiagram
   */
  constructor(private _ganttDiagram: BestGantt) {
    this._init();
  }

  private _init(): void {
    for (const scrollContainerId of Object.values(EGanttScrollContainer)) {
      this._parentNode[scrollContainerId] = this._ganttDiagram
        .getShiftFacade()
        .getCanvasBehindShifts(scrollContainerId)
        .node();
    }
  }

  /**
   * Calculates all weekend days inside time interval.
   * @private
   * @param {Date} timeStart Start time of interval
   * @param {Date} timeEnd End time of interval.
   * @return {GanttTimePeriodBackgroundItemDay[]} List of days with highlight color.
   */
  private _createWeekendData(timeStart, timeEnd) {
    const s = this;

    const fullDayStart = s._getFullDay(timeStart),
      fullDayEnd = s._getFullDay(timeEnd);

    const markedTimespan = [];

    if (fullDayStart.getTime() >= fullDayEnd.getTime()) return markedTimespan;

    while (fullDayStart.getTime() <= fullDayEnd.getTime()) {
      if (fullDayStart.getDay() == 0 || fullDayStart.getDay() == 6) {
        markedTimespan.push(
          new GanttTimePeriodBackgroundItemDay(new Date(fullDayStart), s._weekendColor, 1, 2, null, 1000)
        );
      }

      // increase starttime by one day
      fullDayStart.setDate(fullDayStart.getDate() + 1);
    }

    markedTimespan.sort(s._markedTimeSpanSorter);
    return markedTimespan;
  }

  /**
   * Calculates full day by given date.
   * @private
   * @param {Date} date
   * @return Full day.
   */
  private _getFullDay(date) {
    const dateValue = new Date(date);

    return new Date(dateValue.getFullYear(), dateValue.getMonth(), dateValue.getDate());
  }

  /**
   * Initializes data and builds canvas.
   * @keywords init, initial, timer, period, background
   * @param {scale} scale D3 function for scaling x axis.
   */
  init(scale) {
    const s = this;
    s._scale = scale;
    s.buildCanvas();
    s._markedTimespanWeekend = s._createWeekendData(scale.domain()[0], scale.domain()[1]);
  }

  /**
   * Builds canvas.
   * @keywords build, canvas, background, parent, group
   */
  public buildCanvas(): void {
    // add canvases over shift containers
    for (const scrollContainerId of Object.values(EGanttScrollContainer)) {
      if (this._shiftCanvas[scrollContainerId]) this._shiftCanvas[scrollContainerId].remove();
      this._shiftCanvas[scrollContainerId] = d3
        .select<SVGSVGElement, undefined>(this._parentNode[scrollContainerId])
        .insert('g', ':first-child')
        .attr('class', 'gantt_weekend-group');
    }

    // add canvas over x axis container
    if (this._xAxisCanvas) this._xAxisCanvas.remove();
    this._xAxisCanvas = this._ganttDiagram
      .getXAxisBuilder()
      .getAxisContainer()
      .insert('g', ':nth-child(2)')
      .attr('class', 'gantt_weekend-group1');

    this._updateCanvasZoom();
  }

  private _reBuildXCanvas() {
    if (this._xAxisCanvas) {
      this._xAxisCanvas.remove();
    }
    this._xAxisCanvas = this._ganttDiagram
      .getXAxisBuilder()
      .getAxisContainer()
      .insert('g', ':nth-child(2)')
      .attr('class', 'gantt_weekend-group1');
  }

  /**
   * Updated Canvas Zoom
   * @keywords build, canvas, background, parent, group
   */
  private _updateCanvasZoom() {
    // zoom on building x Axis Periods as BestGantt.update() rebuilds entire xAxis.
    const lastZoom = this._ganttDiagram.getXAxisBuilder().getLastZoomEvent();
    if (lastZoom) {
      this._xAxisCanvas.attr('transform', 'translate(' + lastZoom.x + ',0) scale(' + lastZoom.k + ', 1)');
      for (const scrollContainerId of Object.values(EGanttScrollContainer)) {
        this._shiftCanvas[scrollContainerId].attr(
          'transform',
          'translate(' + lastZoom.x + ',0) scale(' + lastZoom.k + ', 1)'
        );
      }
    }
  }

  /**
   * Changes visibility state to visible.
   * @keywords visibility, show, hide, display, opacity
   */
  public show(): void {
    for (const scrollContainerId of Object.values(EGanttScrollContainer)) {
      this._build(this._shiftCanvas[scrollContainerId], scrollContainerId);
    }
    if (this._extendToXAxis) {
      this._build(this._xAxisCanvas, undefined);
    }
  }

  /**
   * Changes visibility state to hidden.
   * @keywords visibility, show, hide, display, opacity
   */
  public hide(): void {
    this._removeAll();
  }

  /**
   * Builds highlighted background areas by data if allowed.
   * @keywords build, time, period, background, timepoint, timestart, timeend
   * @param selection
   * @param scrollContainerId
   */
  private _build(
    selection: d3.Selection<SVGGElement, undefined, d3.BaseType, undefined>,
    scrollContainerId: EGanttScrollContainer
  ): void {
    const buildDataSet = this._getBuildDataSet();
    this._allWeekendBars = selection
      .selectAll<SVGRectElement, GanttTimePeriodBackgroundItemInterval>('rect')
      .data(buildDataSet);

    this._allWeekendBars
      .attr('x', (d) => {
        return this._scale(d.dateFrom);
      })
      .attr('y', 0)
      .attr('height', () => {
        if (d3.select(selection.node()).attr('class') === 'gantt_weekend-group1') {
          return this._ganttDiagram.getConfig().xAxisContainerHeight();
        } else {
          return this._ganttDiagram.getNodeProportionsState().getShiftCanvasProportions(scrollContainerId).height;
        }
      })
      .attr('width', (d) => {
        return this._scale(d.dateTo) - this._scale(d.dateFrom);
      })
      .style('fill', (d) => {
        return d.color;
      })
      .style('fill-opacity', (d) => {
        return d.opacity;
      });

    this._allWeekendBars
      .enter()
      .append('rect')
      .attr('x', (d) => {
        return this._scale(d.dateFrom);
      })
      .attr('y', 0)
      .attr('height', () => {
        if (d3.select(selection.node()).attr('class') === 'gantt_weekend-group1') {
          return this._ganttDiagram.getConfig().xAxisContainerHeight();
        } else {
          return this._ganttDiagram.getNodeProportionsState().getShiftCanvasProportions(scrollContainerId).height;
        }
      })
      .attr('width', (d) => {
        return this._scale(d.dateTo) - this._scale(d.dateFrom);
      })
      .style('fill', (d) => {
        return d.color;
      })
      .style('fill-opacity', (d) => {
        return d.opacity;
      })
      .on('mouseover', (event) => {
        if (!event.clientX) return;
        if (!event.clientY) return;
        const currentScale = this._ganttDiagram.getXAxisBuilder().getCurrentScale();
        const eventX =
          event.clientX - this._ganttDiagram.getXAxisBuilder().getCanvas().node().getBoundingClientRect().left;
        const eventDate = this._ganttDiagram.getXAxisBuilder().pxToTime(eventX, currentScale);
        this.toggleTimeSpanTooltip(event, this._getAllTimeSpansOnDate(eventDate, buildDataSet));
      })
      .on('mouseout', () => {
        setTimeout(() => this._ganttDiagram.getShiftFacade().getTooltipBuilder().removeAllTooltips(), 0);
      });

    this._allWeekendBars.exit().remove();
  }

  /**
   * Returns all time span markers including the specified date.
   * @param x Date to filter the markers for.
   * @param markerData Marker data to filter.
   * @returns Filtered marker data.
   */
  private _getAllTimeSpansOnDate(
    date: Date,
    markerData: GanttTimePeriodBackgroundItemInterval[]
  ): GanttTimePeriodBackgroundItemInterval[] {
    const hitMarkers: GanttTimePeriodBackgroundItemInterval[] = [];
    for (const markerItem of markerData) {
      if (markerItem.dateFrom.getTime() <= date.getTime() && markerItem.dateTo.getTime() >= date.getTime()) {
        hitMarkers.push(markerItem);
      }
    }
    hitMarkers.sort((a, b) => a.index - b.index);
    return hitMarkers;
  }

  /**
   * Combines different datasets for rendering.
   * Returns a consistent, filtered and sorted data set.
   * @returns {GanttTimePeriodBackgroundItemInterval[]}
   */
  private _getBuildDataSet(): GanttTimePeriodBackgroundItemInterval[] {
    let buildData: any[] = this._hideWeekends ? [] : this._markedTimespanWeekend;

    // extend with marked time spans
    if (this._ganttDiagram.getConfig().showCalendarTimeIntervals()) {
      buildData = buildData.concat(this._markedTimespan);
    }

    // extend with holidays
    if (this._ganttDiagram.getConfig().showCalendarHolidays()) {
      buildData = buildData.concat(this._getHolidayDataSet());
    }

    // make data set consistent
    buildData = this._parseToBuildDataSet(buildData);

    // filter by viewport
    buildData = this._filterDataSetByViewPort(buildData);

    // sort
    buildData.sort(this._markedTimeSpanSorter);

    return buildData;
  }

  /**
   * Filters the given dataset by viewport.
   * @param {GanttTimePeriodBackgroundItemInterval[]} dataSet
   * @returns {GanttTimePeriodBackgroundItemInterval[]}
   */
  private _filterDataSetByViewPort(dataSet) {
    const s = this;
    const currentDomain = s._ganttDiagram.getXAxisBuilder().getCurrentScale().domain();
    const viewportStartDate = currentDomain[0].getTime();
    const viewportEndDate = currentDomain[1].getTime();

    return dataSet.filter((elem) => {
      const elemStartDate = elem.dateFrom.getTime();
      const elemEndDate = elem.dateTo.getTime();
      return (
        (elemStartDate >= viewportStartDate && elemStartDate <= viewportEndDate) ||
        (elemEndDate >= viewportStartDate && elemEndDate <= viewportEndDate) ||
        (elemStartDate <= viewportStartDate && elemEndDate >= viewportEndDate)
      );
    });
  }

  /**
   * Converts the given dataset of GanttTimePeriodBackgroundItemInterval and GanttTimePeriodBackgroundItemDay elements to a consistent one.
   * @param {GanttTimePeriodBackgroundItemDay[] | GanttTimePeriodBackgroundItemInterval[]} dataSet
   * @returns {GanttTimePeriodBackgroundItemInterval[]}
   */
  private _parseToBuildDataSet(dataSet) {
    const s = this;
    return dataSet.map((elem) => {
      let mappedElem = null;
      if (elem instanceof GanttTimePeriodBackgroundItemDay) {
        const dateFrom = new Date(elem.date);
        dateFrom.setHours(0, 0, 0, 0);
        const dateTo = new Date(elem.date);
        dateTo.setHours(23, 59, 59, 999);
        mappedElem = new GanttTimePeriodBackgroundItemInterval(
          dateFrom,
          dateTo,
          elem.color,
          elem.opacity,
          elem.startXAxis,
          elem.endXAxis,
          elem.name,
          elem.tooltipData,
          elem.index,
          null
        );
      } else if (elem instanceof GanttTimePeriodBackgroundItemInterval) {
        mappedElem = elem;
      }
      return mappedElem;
    });
  }

  /**
   * Returns a filtered holiday data set.
   * @returns {GanttTimePeriodBackgroundItemInterval[]}
   */
  private _getHolidayDataSet() {
    const s = this;
    return s._markedHolidays.filter((holiday) => {
      const selectedFedState = s._ganttDiagram.getConfig().getCalenderSelectedFilterFederalState();

      if (selectedFedState === 'nationwide') {
        return holiday?.federalStates?.length === 21; // because there are 21 predefined federal states
      } else if (selectedFedState === 'showAll') {
        return true;
      } else {
        return holiday?.federalStates?.includes(selectedFedState);
      }
    });
  }

  private toggleTimeSpanTooltip(event, hitMarkers: GanttTimePeriodBackgroundItemInterval[]) {
    // filter out marker items without name
    hitMarkers = hitMarkers.filter((markerData) => markerData.name);
    if (hitMarkers.length <= 0) return;

    // toggle tooltip
    const colorDivSize = this._ganttDiagram.getConfig().tooltipFontSize() * 0.8;
    let tooltip = `
      <table>`;
    for (const hitMarker of hitMarkers) {
      tooltip += `
      <tr>
        <td colspan="2" style="font-weight: bold">
          <div style="width: ${colorDivSize}px; height: ${colorDivSize}px; background-color: ${hitMarker.color}; display: inline-block; margin-right: 1px;"></div>
          ${hitMarker.name}
        </td>
      </tr>`;
      if (hitMarker.tooltipData && hitMarker.tooltipData.length > 0) {
        for (const tooltipItem of hitMarker.tooltipData) {
          tooltip += `
          <tr>
            <td> ${tooltipItem.name}: </td>
            <td> ${tooltipItem.value instanceof Array ? tooltipItem.value.join(', ') : tooltipItem.value} </td>
          </tr>`;
        }
      }
    }
    tooltip += `
      </table>
    `;

    this._ganttDiagram.getShiftFacade().getTooltipBuilder().addTooltipToHTMLBody(event.clientX, event.clientY, tooltip);
  }

  /**
   * Adds height to canvas.
   * @keywords add, canvas, height, vertical
   * @param height Height to add.
   */
  public addToCanvasHeight(
    height: number,
    scrollContainerId: EGanttScrollContainer = EGanttScrollContainer.DEFAULT
  ): void {
    if (this._shiftCanvas[scrollContainerId].select('rect').empty()) return;
    const prevHeight = parseInt(this._shiftCanvas[scrollContainerId].select('rect').attr('height'));
    this._setCanvasHeight(height + prevHeight, scrollContainerId);
  }

  /**
   * Removes canvas with all of its nodes.
   * @keywords remove, delete, clear, elements, background
   */
  private _removeAll(): void {
    for (const scrollContainerId of Object.values(EGanttScrollContainer)) {
      this._shiftCanvas[scrollContainerId].selectAll('rect').remove();
    }
    this._xAxisCanvas.selectAll('rect').remove();
  }

  /**
   * Removes nodes and builds nodes with highlighted background areas.
   * @keywords rebuild, build, rerender, update, vertical
   */
  public rebuild(): void {
    this._removeAll();
    this._reBuildXCanvas();
    this._updateCanvasZoom();
    this.show();
  }

  /**
   * Adds one marked day to dataset.
   * @keywords add, plus, new, time, period, background, color
   * @param {Date} dayDate Date of Day to mark.
   * @param {string} color Markercolor.
   */
  addDay(dayDate, color, startXAxis = undefined, endXAxis = undefined, index: number) {
    const s = this;
    const timeSpan = new GanttTimePeriodBackgroundItemDay(dayDate, color, 1, null, null, index);

    if (typeof startXAxis !== 'undefined') {
      timeSpan.startXAxis = startXAxis;
    }
    if (typeof endXAxis !== 'undefined') {
      timeSpan.endXAxis = endXAxis;
    }

    s._markedTimespan.push(timeSpan);
    s._markedTimespan.sort(s._markedTimeSpanSorter);
  }

  public addInterval(
    from: number | Date,
    to: number | Date,
    color: string,
    opacity = 1,
    name: string = undefined,
    tooltipData: IGanttGlobalTimeSpanTooltipItem[] = undefined,
    startXAxis: number = undefined,
    endXAxis: number = undefined,
    index = 0
  ): void {
    const s = this;
    const timeSpan = new GanttTimePeriodBackgroundItemInterval(
      from,
      to,
      color,
      opacity,
      null,
      null,
      name,
      tooltipData,
      index,
      null
    );

    if (typeof startXAxis !== 'undefined') {
      timeSpan.startXAxis = startXAxis;
    }
    if (typeof endXAxis !== 'undefined') {
      timeSpan.endXAxis = endXAxis;
    }

    s._markedTimespan.push(timeSpan);
    s._markedTimespan.sort(s._markedTimeSpanSorter);
  }

  public addHoliday(from: number | Date, to: number | Date, name: string, federalStates: string[]): void {
    const s = this;

    const holiday = new GanttTimePeriodBackgroundItemInterval(
      from,
      to,
      s._holidayColor,
      0.6,
      null,
      null,
      null,
      null,
      1001,
      federalStates
    );
    holiday.tooltipData = [{ name: 'Bundesländer', value: federalStates }];
    holiday.name = name;
    holiday.startXAxis = 2;

    s._markedHolidays.push(holiday);
    s._markedHolidays.sort(s._markedTimeSpanSorter);
  }

  setHolidayColor(color) {
    const s = this;
    this._holidayColor = color;
    for (const holiday of s._markedHolidays) {
      holiday.color = s._holidayColor;
    }
  }

  /**
   * Sorts marked TimeSpans after inserts to keep them in order.
   * @param {GanttTimePeriodBackgroundItemDay|GanttTimePeriodBackgroundItemInterval} a
   * @param {GanttTimePeriodBackgroundItemDay|GanttTimePeriodBackgroundItemInterval} b
   * @returns {number}
   */
  private _markedTimeSpanSorter(a, b) {
    if (a.index === b.index) {
      const first = a.dateFrom || a.date;
      const second = b.dateFrom || b.date;
      let compare = 0;
      if (first.getTime() < second.getTime()) compare = -1;
      if (first.getTime() > second.getTime()) compare = 1;
      return compare;
    }
    return a.index - b.index;
  }

  /**
   * Updates Global TimeSpan Markers, e.g called on zooming
   */
  public update(): void {
    const scale = this._ganttDiagram.getXAxisBuilder().getGlobalScale();
    this.setScale(scale);
    this.rebuild();
  }

  //
  // GETTER & SETTER
  //

  /**
   * Toggle rendering TimePeriods to x Axis or not.
   * @param {boolean} bool
   */
  extendPeriodToXAxis(bool) {
    this._extendToXAxis = bool;
  }

  public setWeekendColor(color: string) {
    this._weekendColor = color;
    for (let i = 0; i < this._markedTimespanWeekend.length; i++) {
      this._markedTimespanWeekend[i].color = color;
    }
    this.rebuild();
  }

  /**
   * Sets canvas height.
   * @param canvasHeightMap New canvas height.
   */
  public setCanvasHeight(canvasHeightMap: Map<EGanttScrollContainer, number>): void {
    for (const scrollContainerId of canvasHeightMap.keys()) {
      this._setCanvasHeight(canvasHeightMap.get(scrollContainerId), scrollContainerId);
    }
  }

  private _setCanvasHeight(height: number, scrollContainerId: EGanttScrollContainer): void {
    if (this._shiftCanvas[scrollContainerId].select('rect').empty()) return;
    this._shiftCanvas[scrollContainerId].selectAll('rect').attr('height', height);
  }

  public getCanvas(
    scrollContainerId: EGanttScrollContainer = EGanttScrollContainer.DEFAULT
  ): d3.Selection<SVGGElement, undefined, d3.BaseType, undefined> {
    return this._shiftCanvas[scrollContainerId];
  }

  getXAxisCanvas() {
    return this._xAxisCanvas;
  }

  /**
   * @param {scale} scale D3 function for scaling x axis.
   */
  setScale(scale) {
    const s = this;
    s._scale = scale;
  }

  /**
   * @param {boolean} isHidden Sets visibility of marked ares at highest priority level.
   */
  setHideWeekends(isHidden) {
    const s = this;
    s._hideWeekends = isHidden;
  }
}

export abstract class GanttTimePeriodBackgroundItem {
  public color: string;
  public opacity: number;
  public startXAxis: number;
  public endXAxis: number;
  public name: string;
  public tooltipData: IGanttGlobalTimeSpanTooltipItem[];
  public index: number;

  constructor(
    color: string,
    opacity: number,
    startXAxis: number,
    endXAxis: number,
    name: string,
    tooltipData: IGanttGlobalTimeSpanTooltipItem[],
    index: number
  ) {
    this.color = color;
    this.opacity = opacity;
    this.startXAxis = startXAxis;
    this.endXAxis = endXAxis;
    this.name = name;
    this.tooltipData = tooltipData;
    this.index = index;
  }
}

export class GanttTimePeriodBackgroundItemDay extends GanttTimePeriodBackgroundItem {
  public date: Date;

  constructor(
    date: number | Date,
    color: string,
    opacity: number,
    startXAxis: number,
    endXAxis: number,
    index: number
  ) {
    super(color, opacity, startXAxis, endXAxis, null, null, index);
    this.date = new Date(date);
  }
}

export class GanttTimePeriodBackgroundItemInterval extends GanttTimePeriodBackgroundItem {
  public dateFrom: Date;
  public dateTo: Date;
  public federalStates: string[];

  constructor(
    dateFrom: number | Date,
    dateTo: number | Date,
    color: string,
    opacity: number,
    startXAxis: number,
    endXAxis: number,
    name: string,
    tooltipData: IGanttGlobalTimeSpanTooltipItem[],
    index: number,
    federalStates: string[]
  ) {
    super(color, opacity, startXAxis, endXAxis, name, tooltipData, index);
    this.dateFrom = new Date(dateFrom);
    this.dateTo = new Date(dateTo);
    this.federalStates = federalStates || [];
  }
}

export interface IGanttGlobalTimeSpanTooltipItem {
  name: string;
  value: string | string[];
}
