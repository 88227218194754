<div style="width: 100%; height: 100%">
  <ng-container *ngIf="state$ | push as state">
    <notification-item
      [text]="item.text"
      [id]="item.id"
      [type]="item.type"
      [duration]="item.duration"
      [params]="item.params"
      [@inOutAnimation]
      *rxFor="let item of state.notification; trackBy: trackMessages"
    ></notification-item>
  </ng-container>
</div>
