import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BubblePaginationComponent } from './bubble-pagination.component';
import { MySharedModule } from 'frontend/src/dashboard/shared/shared.module';

@NgModule({
  imports: [CommonModule, MySharedModule],
  declarations: [BubblePaginationComponent],
  exports: [BubblePaginationComponent],
})
export class BubblePaginationModule {}
