import { ETemplateType } from 'frontend/src/dashboard/model/resource/template-type';
import { ContentElement } from '../content/content-element/content-element';
import { ContextMenu } from '../contextmenu/contextmenu';
import { EntryElement } from './entry-element';
import { EntryElementValue } from './entry-element-value';

export class EntryCollection extends ContentElement {
  protected entryElements: EntryElement[];
  private contextMenues: ContextMenu[];
  private index: number;
  private preventNewLines: boolean;
  private entryElementTemplates: EntryElement[];
  private value: EntryElementValue;
  private lastSelectedIndex: number;

  public isValid(): boolean {
    return this.getEntryElements().find((t: EntryElement) => !t.isValid()) ? false : true;
  }

  public getInvalidTemplates(): EntryElement[] {
    return this.getEntryElements().filter((t: EntryElement) => !t.isValid());
  }

  public getLastSelectedIndex(): number {
    if (this.selectedEntries.length === 0) {
      return null;
    }
    return this.lastSelectedIndex;
  }

  public setLastSelectedIndex(lastSelectedIndex: number): this {
    this.lastSelectedIndex = lastSelectedIndex;
    return this;
  }

  public getValue(): EntryElementValue {
    return this.value;
  }

  public setValue(value: EntryElementValue): this {
    this.value = value;
    return this;
  }

  public getEntryElementTemplates(): EntryElement[] {
    return this.entryElementTemplates || [];
  }

  public setEntryElementTemplates(entryElementTemplates: EntryElement[]): this {
    this.entryElementTemplates = entryElementTemplates;
    return this;
  }

  public isPreventNewLines(): boolean {
    return typeof this.preventNewLines ? this.preventNewLines : false;
  }

  public setPreventNewLines(preventNewLines: boolean): this {
    this.preventNewLines = preventNewLines;
    return this;
  }

  /**
   * @Public
   * get current selected entry-elements
   * @returns string[]
   */
  public get selectedEntries(): string[] {
    return this.getEntryElements()
      .filter((e: EntryElement) => e && e.isSelected() === true)
      .map((e: EntryElement) => e.getId());
  }

  /**
   * @Public
   * get current selected resource ids of entry elements
   * @returns string[]
   */
  public get selectedResourceIds(): string[] {
    return this.getEntryElements()
      .filter((e: EntryElement) => e.isSelected() === true)
      .map((e: EntryElement) => e.getResourceId());
  }

  public getIndex(): number {
    return this.index;
  }

  public setIndex(index: number): this {
    this.index = index;
    return this;
  }

  public getContextMenues(): ContextMenu[] {
    return this.contextMenues || [];
  }

  public getContextMenuFor(entryElementId: string): ContextMenu {
    return this.getContextMenues().find((m: ContextMenu) => m.getBelongsToElement() === entryElementId);
  }

  public setContextMenues(contextMenues: ContextMenu[]): this {
    this.contextMenues = contextMenues;
    return this;
  }

  constructor() {
    super();
    this.setType(ETemplateType.ENTRY_COLLECTION);
  }

  public getEntryElements<T extends EntryElement[]>(): T {
    return (
      (this.entryElements || [])
        // order items
        .sort((a: EntryElement, b: EntryElement) => {
          if (!(a instanceof EntryElement)) {
            return -1;
          }
          if (a.getOrder() === b.getOrder()) {
            return 0;
          } else if (a.getOrder() < b.getOrder()) {
            return -1;
          } else {
            return 1;
          }
        }) as T
    );
  }

  public addEntryElements(...entryElements: EntryElement[]): this {
    this.entryElements = this.getEntryElements().concat(entryElements);
    return this;
  }

  public setEntryElements(entryElements: EntryElement[]): this {
    this.entryElements = entryElements;
    this.getEntryElements().forEach((e: EntryElement) => e.setParentId(this.getId()));
    return this;
  }

  public removeEntryElement(entryElement: EntryElement): this {
    this.setEntryElements(this.getEntryElements().filter((entry) => entry.getId() !== entryElement.getId()));
    return this;
  }
}
