import * as d3 from 'd3';
import { GanttCallBackStackExecuter } from '../../callback-tools/callback-stack-executer';
import { GanttUtilities } from '../../gantt-utilities/gantt-utilities';
import { BestGantt } from '../../main';

/**
 * Mouse handler to give possibility to remove edges inside gantt by user action.
 * @class
 * @constructor
 * @plugin edges
 * @param {BestGantt} ganttDiagram
 * @param {HTMLNode} parentNode
 */
export class GanttEdgeRemover {
  parentNode: any;
  ganttDiagram: BestGantt;
  lineCoordinates: GanttEdgeRemoverCoordinates[];
  removerActivity: boolean;
  edgeData: any[];
  callBack: any;
  removerGroup: d3.Selection<any, any, null, undefined>;
  line: d3.Selection<any, any, null, undefined>;
  draw: any;
  cover: d3.Selection<any, any, null, undefined>;

  constructor(ganttDiagram, parentNode) {
    this.parentNode = parentNode;
    this.ganttDiagram = ganttDiagram;
    this.lineCoordinates = [];
    this.removerActivity = false; //mechanism to avoid double execution

    this.edgeData = [];

    this.callBack = {
      onCutConnection: {},
    };
  }

  /**
   * this function starts the remover mode
   * it creates the cover over the shifts and handles the "cutline"
   */
  startRemoverMode() {
    const s = this;
    this.removerActivity = true;

    s.removerGroup = s.parentNode.append('g').attr('class', 'gantt-edge-remover-group');

    let startMouse, endMouse;

    s.draw = d3
      .drag()
      .on('start', function (event) {
        GanttUtilities.dispatchD3EventToOutside(d3.select(this), event);
        startMouse = d3.pointer(event);
      })
      .on('drag', function (event) {
        GanttUtilities.dispatchD3EventToOutside(d3.select(this), event);
        s.lineCoordinates = [];
        endMouse = d3.pointer(event);
        s.lineCoordinates.push(new GanttEdgeRemoverCoordinates(startMouse, endMouse));
        s.drawLine();
      })
      .on('end', function (event) {
        GanttUtilities.dispatchD3EventToOutside(d3.select(this), event);
        s.removeConnection(startMouse, endMouse);
        s.removeLine();
      });

    this.cover = s.removerGroup
      .append('rect') //build a reactionArea
      .attr('height', s.parentNode.attr('height'))
      .attr('width', s.parentNode.attr('width'))
      .style('opacity', '0')
      .call(s.draw);
  }
  /**
   * this function creates the line to "sever" the connection
   */
  drawLine() {
    const s = this;

    this.line = s.removerGroup.selectAll('.gantt-edge-removerLine').data(s.lineCoordinates);

    s.line
      .attr('x1', function (d) {
        return d.x1;
      })
      .attr('y1', function (d) {
        return d.y1;
      })
      .attr('x2', function (d) {
        return d.x2;
      })
      .attr('y2', function (d) {
        return d.y2;
      });

    s.line
      .enter()
      .append('line')
      .attr('class', 'gantt-edge-removerLine')
      .style('stroke', 'red') // colour the line
      .style('stroke-width', 3)
      .attr('x1', function (d) {
        return d.x1;
      })
      .attr('y1', function (d) {
        return d.y1;
      })
      .attr('x2', function (d) {
        return d.x2;
      })
      .attr('y2', function (d) {
        return d.y2;
      });

    s.line.exit().remove();
  }
  /**
   * this function removes the "cut-line"
   */
  removeLine() {
    if (!this.line) return;
    this.line.remove();
  }

  /**
   * this function handles the removing of the connections
   * @param {*} startMouse start mouseposition
   * @param {*} endMouse end mouseposition
   */
  removeConnection(startMouse, endMouse) {
    const s = this;

    if (!endMouse) return; //only click without dragging
    const edgeIdsToDelete = [];

    for (let i = 0; i < s.edgeData.length; i++) {
      //looking in edgeData
      for (let ii = 0; ii < s.edgeData[i].pathPoints.length - 1; ii++) {
        //get the single paths
        const check = s.intersectionChecker(
          //checking for intersection
          [s.edgeData[i].pathPoints[ii], s.edgeData[i].pathPoints[ii + 1]], //singlepath of connection
          [startMouse, endMouse] //path of cut-line
        );
        if (check == true) {
          //if there is a point of intersection
          edgeIdsToDelete.push(s.edgeData[i].edgeId);
          break;
        }
      }
    }

    for (const edgeId of edgeIdsToDelete) {
      GanttCallBackStackExecuter.execute(s.callBack.onCutConnection, edgeId);
    }
  }
  /**
   * this function checks if there is a intersection
   * @param {*} line1 singlepath of connection
   * @param {*} line2 path of cut-line
   */
  intersectionChecker(line1: number[][], line2: number[][]) {
    let intersecX: number, intersecY: number;

    if (line1[0][0] == line1[1][0]) {
      //if the singlepath a vertical line
      const xLine2start = line2[0][0] - line1[0][0]; //shift of the y-axis
      const xLine2end = line2[1][0] - line1[0][0];
      var m = -(line2[1][1] - line2[0][1]) / (xLine2end - xLine2start); //increase
      var t = -line2[0][1] - m * xLine2start; //intersec y

      intersecX = line1[0][0]; //intersection x
      intersecY = -t; //intersection y

      //checking surrounding
      if (
        (intersecX >= line2[0][0] &&
          intersecX <= line2[1][0] &&
          intersecY >= line1[0][1] &&
          intersecY <= line1[1][1]) ||
        (intersecX <= line2[0][0] &&
          intersecX >= line2[1][0] &&
          intersecY >= line1[0][1] &&
          intersecY <= line1[1][1]) ||
        (intersecX <= line2[0][0] &&
          intersecX >= line2[1][0] &&
          intersecY <= line1[0][1] &&
          intersecY >= line1[1][1]) ||
        (intersecX >= line2[0][0] && intersecX <= line2[1][0] && intersecY <= line1[0][1] && intersecY >= line1[1][1])
      ) {
        return true;
      }
    }
    if (line1[0][1] == line1[1][1]) {
      //if the singlepath horizontal line
      const yLine2start = line2[0][1] - line1[1][1];
      const yLine2end = line2[1][1] - line1[1][1];
      var m = -(yLine2end - yLine2start) / (line2[1][0] - line2[0][0]);
      var t = -yLine2start - m * line2[0][0];

      intersecX = -t / m;
      intersecY = line1[0][1];

      if (
        (intersecX >= line1[0][0] &&
          intersecX <= line1[1][0] &&
          intersecY >= line2[0][1] &&
          intersecY <= line2[1][1]) ||
        (intersecX <= line1[0][0] &&
          intersecX >= line1[1][0] &&
          intersecY >= line2[0][1] &&
          intersecY <= line2[1][1]) ||
        (intersecX <= line1[0][0] &&
          intersecX >= line1[1][0] &&
          intersecY <= line2[0][1] &&
          intersecY >= line2[1][1]) ||
        (intersecX >= line1[0][0] && intersecX <= line1[1][0] && intersecY <= line2[0][1] && intersecY >= line2[1][1])
      ) {
        return true;
      }
    }
  }

  /**
   * removes all elements of remover
   */
  removeAll() {
    if (this.removerActivity) this.removerGroup.remove();
    this.removerActivity = false;
  }

  /**
   * add functions to onCutConnectionCallback
   */
  addOnCutConnectionCallback(id, func) {
    const s = this;
    s.callBack.onCutConnection[id] = func;
  }

  removeOnCutConnectionCallback(id) {
    const s = this;
    delete s.callBack.onCutConnection[id];
  }

  setEdgeData(edgeData) {
    this.edgeData = edgeData;
  }

  isRemoverActive() {
    return this.removerActivity;
  }
}

/**
 * this function remembers the coordinates of the mouseposition
 * @param {*} startMouse start mouseposition
 * @param {*} endMouse end mouseposition
 */
export class GanttEdgeRemoverCoordinates {
  x1: number;
  y1: number;
  x2: number;
  y2: number;

  constructor(startMouse, endMouse) {
    this.x1 = startMouse[0];
    this.y1 = startMouse[1];
    this.x2 = endMouse[0];
    this.y2 = endMouse[1];
  }
}
