<ng-container *ngIf="layout && templates?.length > 0; else without">
  <ng-container [ngSwitch]="layout.getLayoutType()">
    <!-- column layout -->
    <ng-container *ngSwitchCase="'COLUMN_LAYOUT'">
      <column-layout class="full-width" [layout]="layout" [templates]="templates">
        <ng-template #contentElement let-template let-numElements="numElements" let-root="root">
          <ng-container
            *ngTemplateOutlet="element; context: { $implicit: template, numElements: numElements, root: root }"
          >
          </ng-container>
        </ng-template>
      </column-layout>
    </ng-container>
    <!-- virtual layout -->
    <ng-container *ngSwitchCase="'VIRTUAL_LAYOUT'">
      <virtual-layout
        [disabled]="disabled"
        class="full-width"
        [layout]="layout"
        [templates]="templates"
        [entryCollection]="entryCollection"
      >
        <ng-template #contentElement let-template let-root="root">
          <ng-container *ngTemplateOutlet="element; context: { $implicit: template, root: root }"> </ng-container>
        </ng-template>
      </virtual-layout>
    </ng-container>
    <!-- column layout -->
    <ng-container *ngSwitchCase="'GRID_LAYOUT'">
      <grid-layout class="full-width" [layout]="layout" [templates]="templates">
        <ng-template #contentElement let-template let-ref="ref" let-root="root">
          <ng-container *ngTemplateOutlet="element; context: { $implicit: template, ref: ref, root: root }">
          </ng-container>
        </ng-template>
      </grid-layout>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <b>{{ layout.getLayoutType() }}</b> {{ 'NOT_IMPLEMENTED.layout' | translate }}
      <ng-container *ngTemplateOutlet="without"></ng-container>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #without>
  <div *ngIf="positionMarker" style="display: inline-block; flex-grow: 1"></div>
  <ng-container *ngFor="let template of templates">
    <ng-container *ngTemplateOutlet="element; context: { $implicit: template }"></ng-container>
  </ng-container>
</ng-template>
