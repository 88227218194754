import { DateUtils } from '@app-modeleditor/utils/date-utils';
import { EntryElementValue } from '../entry-collection/entry-element-value';

export class TemplatePickerValue extends EntryElementValue {
  private month: number;
  private year: number;
  private week: number;
  private start: Date;
  private end: Date;
  private showMillis: boolean;

  public isShowMillis(): boolean {
    return typeof this.showMillis === 'boolean' ? this.showMillis : false;
  }

  public setShowMillis(showMillis: boolean): this {
    this.showMillis = showMillis;
    return this;
  }

  public getStart(): Date {
    return this.start;
  }

  public setStart(start: Date): this {
    this.start = start;
    return this;
  }

  public getEnd(): Date {
    return this.end;
  }

  public setEnd(end: Date): this {
    this.end = end;
    return this;
  }

  public get startTime(): number {
    return this.getStart()?.getTime();
  }

  public get endTime(): number {
    return this.getEnd()?.getTime();
  }

  public getMonth(): number {
    return this.month || 0;
  }

  public setMonth(month: number): this {
    this.month = month;
    this.setValue(new Date(this.getYear(), month).getTime());
    return this;
  }

  public getYear(real = false): number {
    if (real) {
      return this.year;
    }
    return this.year || new Date().getFullYear();
  }

  public setYear(year: number): this {
    this.year = year;
    this.setValue(new Date(this.year, this.getMonth()).getTime());
    return this;
  }

  public getWeek(): number {
    return this.week;
  }

  public setWeek(week: number): this {
    this.week = week;
    const range = DateUtils.getDateByWeek(week, this.getYear());
    this.setValue(this.week);
    this.setStart(range.start);
    this.setEnd(range.end);

    return this;
  }
}
