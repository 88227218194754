export enum EColorSchemenStyles {
  LINEAR = 'linear',
  STEP = 'step',
  EXPLICIT = 'explicit',
}

export interface ILinearColorOutput {
  color1: string;
  color2: string;
  weight: number;
}
export class ColorScheme {
  private style: EColorSchemenStyles = EColorSchemenStyles.EXPLICIT;
  private id: string;
  private name: string;
  private defaultColor: string;
  private linearColorString: string;
  private coloredValues: ColorValue[];
  private minValue = 0;
  private maxValue: number;
  private opacity = 75;

  public setColoredValues(...colorValues: ColorValue[]): this {
    this.coloredValues = colorValues;
    this.coloredValues = this.coloredValues.sort((a, b) => {
      return a.getValue() - b.getValue();
    });
    this.calcMaxValue();
    this.calcLinearColorString();
    return this;
  }

  public getColoredValues(): ColorValue[] {
    return this.coloredValues || [];
  }

  public addColoredValues(...colorValues: ColorValue[]): this {
    this.coloredValues = this.getColoredValues()
      .concat(colorValues)
      .sort((a, b) => {
        return a.getValue() - b.getValue();
      });
    this.coloredValues = this.coloredValues.sort((a, b) => {
      return a.getValue() - b.getValue();
    });
    this.calcMaxValue();
    this.calcLinearColorString();
    return this;
  }

  public getMaxValue(): number {
    return this.maxValue;
  }

  private calcMaxValue() {
    this.maxValue = this.coloredValues[this.coloredValues.length - 1].getValue();
    // this.getColoredValues().forEach((colorValue) => {
    //     this.maxValue += colorValue.getValue();
    // });
  }

  public setStyle(style: EColorSchemenStyles): this {
    this.style = style;
    return this;
  }

  public getStyle(): EColorSchemenStyles {
    return this.style;
  }

  public setOpacity(opacity: number): this {
    this.opacity = opacity;
    return this;
  }

  public getOpacity(): number {
    return this.opacity;
  }
  public setId(id: string): this {
    this.id = id;
    return this;
  }

  public getId(): string {
    return this.id;
  }

  public setName(name: string): this {
    this.name = name;
    return this;
  }

  public getName(): string {
    return this.name;
  }

  public setDefaultColor(defaultColor: string): this {
    this.defaultColor = defaultColor;
    return this;
  }

  public getDefaultColor(): string {
    return this.defaultColor;
  }

  public setLinearColorString(linearColorString: string): this {
    this.linearColorString = linearColorString;
    return this;
  }

  public getLinearColorString(): string {
    return this.linearColorString;
  }

  public calcNewColorValues(newPercents: number[]) {
    let valuePercent = 0;

    newPercents.forEach((percent, index) => {
      valuePercent += percent / 100;
      const newValue = parseInt((valuePercent * this.maxValue).toFixed(0));
      this.coloredValues[index].setValue(newValue);
    });
  }

  public checkStringValue() {
    let disabled = false;
    this.getColoredValues().forEach((colorValue) => {
      if (isNaN(parseInt(colorValue.getValue()))) {
        disabled = true;
      }
    });
    return disabled;
  }

  public calcLinearColorString() {
    let linearColorString = 'linear-gradient(90deg, ';
    this.coloredValues.forEach((colorValue, index) => {
      const color = colorValue.getColor() ? colorValue.getColor() : this.getDefaultColor();
      linearColorString += `${color} ${(colorValue.getValue() / this.maxValue) * 100}%`;
      if (index !== this.coloredValues.length - 1) {
        linearColorString += `, `;
      } else {
        linearColorString += `)`;
      }
    });
    this.linearColorString = linearColorString;
  }

  public getColorByValue(value: any): string {
    switch (this.style) {
      case EColorSchemenStyles.STEP:
        let foundColorValue: ColorValue;
        this.coloredValues.forEach((colorValue, index) => {
          const minValue = index === 0 ? this.minValue : this.coloredValues[index - 1].getValue();
          const maxValue = colorValue.getValue();
          if (value <= maxValue && value >= minValue) {
            foundColorValue = colorValue;
          }
        });
        return foundColorValue ? foundColorValue.getColor() : this.defaultColor;
      case EColorSchemenStyles.EXPLICIT:
        const colorValue = this.coloredValues.find((colorValue) => colorValue.getValue() === value);
        return colorValue ? colorValue.getColor() : this.defaultColor;
      case EColorSchemenStyles.LINEAR:
        return this.getLinearColorByValue(value);
    }
  }

  private hexToRgbArray(hex: string) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)] : null;
  }

  private getLinearColorByValue(value: number): string {
    let color1 = null;
    let color2 = null;
    let weight = 0;
    let firstColorValue: ColorValue;
    let secondColorValue: ColorValue;

    this.coloredValues.forEach((colorValue, index) => {
      if (this.coloredValues[index - 1]) {
        if (value <= colorValue.getValue() && value > this.coloredValues[index - 1].getValue()) {
          firstColorValue = this.coloredValues[index - 1];
          secondColorValue = colorValue;
        }
      } else {
        if (value <= colorValue.getValue()) {
          firstColorValue = colorValue;
          secondColorValue = colorValue;
        }
      }
    });

    color1 = firstColorValue ? firstColorValue.getColor() : this.defaultColor;
    color2 = secondColorValue ? secondColorValue.getColor() : this.defaultColor;
    if (firstColorValue && secondColorValue && firstColorValue !== secondColorValue) {
      weight = (value - secondColorValue.getValue()) / (firstColorValue.getValue() - secondColorValue.getValue());
    } else {
      weight = 1;
    }
    return `rgb(${this.mixColor(this.hexToRgbArray(color1), this.hexToRgbArray(color2), weight).join()})`;
  }

  private mixColor(color1, color2, weight) {
    const w1 = weight;
    const w2 = 1 - w1;
    const rgb = [
      Math.round(color1[0] * w1 + color2[0] * w2),
      Math.round(color1[1] * w1 + color2[1] * w2),
      Math.round(color1[2] * w1 + color2[2] * w2),
    ];
    return rgb;
  }
}

export class ColorValue {
  private id: string;
  private color: string;
  private attributeValue: any;
  private colorSchemeRef: string;
  private attributeValueType: string;

  public setColorSchemeRef(colorSchemeRef: string): this {
    this.colorSchemeRef = colorSchemeRef;
    return this;
  }

  public getColorSchemeRef(): string {
    return this.colorSchemeRef;
  }
  public setAttributeValueType(attributeValueType: string): this {
    this.attributeValueType = attributeValueType;
    return this;
  }

  public getAttributeValueType(): string {
    return this.attributeValueType;
  }

  public setId(id: string): this {
    this.id = id;
    return this;
  }

  public getId(): string {
    return this.id;
  }

  public setColor(color: string): this {
    this.color = color;
    return this;
  }

  public getColor(): string {
    return this.color;
  }

  public getValue(): any {
    if (isNaN(parseInt(this.attributeValue))) {
      return this.attributeValue;
    } else {
      return parseInt(this.attributeValue);
    }
  }

  public setValue(value: any): this {
    this.attributeValue = value;
    return this;
  }
}
