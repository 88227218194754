import { Injectable } from '@angular/core';
/**
 * Importer for sysml files.
 */
@Injectable()
export class SysmlFileReader {
  /**
   * Imports file from user input and sends it to server.
   * @param file File which was imported.
   */
  public readInFile(file: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      try {
        const fileReader: FileReader = new FileReader();

        fileReader.onloadend = function (e) {
          // you can perform an action with read data here
          resolve(fileReader.result);
        };

        fileReader.readAsText(file);
      } catch (e) {
        console.error('Error while trying to read in file');
        console.error(e);
        reject(e);
      }
    });
  }
}
