import { Component, OnInit } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ConfigService } from '@core/config/config.service';
import { LanguageService } from '@core/provider/language.service';
import { TranslateService } from '@ngx-translate/core';
import { SystemMessageComponent } from 'frontend/src/dashboard/system/system-messages/system-message.component';
import { UserService } from 'frontend/src/dashboard/user/data-access/user.service';
import { ViewService } from 'frontend/src/dashboard/view/view.service';
import { Subject } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

const DARK_THEME = 'dark-theme';

@Component({
  selector: 'app-template-usersettings',
  templateUrl: './template-usersettings.component.html',
  styleUrls: ['./template-usersettings.component.scss'],
})
export class TemplateUsersettingsComponent implements OnInit {
  categories: any;
  private _isDarkMode: boolean;
  alive = false;
  localSubject: Subject<void> = new Subject();

  constructor(
    public systemMessage: MatSnackBar,
    public userSettingsService: UserService,
    private translate: TranslateService,
    private languageService: LanguageService,
    private viewService: ViewService,
    private configService: ConfigService
  ) {}

  ngOnInit() {
    this.alive = true;
    this.viewService
      .getCurrentTheme()
      .pipe(takeWhile(() => this.alive))
      .subscribe((style) => (this._isDarkMode = style === DARK_THEME));

    this.userSettingsService
      .getUserSettings()
      .pipe(takeWhile(() => this.alive))
      .subscribe((settings) => {
        if (!settings) {
          return;
        }
        this.categories = settings;
        this.categories.settingsCategories = this.categories.settingsCategories.filter((category) => category.visible);
      });
  }

  ngOnDestroy(): void {
    this.alive = false;
    this.localSubject.next();
    this.localSubject.complete();
  }

  notifyChildren() {
    this.localSubject.next();
  }

  async handleResults(data) {
    const text = this.translate.instant('saveSuccess');

    this.userSettingsService.saveUserSettings(data.id, data).subscribe((result) => {
      // set language
      this.languageService.setLang(result.settingsCategories[0].settingsEntries[0].settingsValue.value);

      this.systemMessage.openFromComponent(SystemMessageComponent, {
        duration: 5000,
        data: {
          type: 'success',
          text: text,
        },
      });
    });
  }

  get isDarkModeAvailable(): boolean {
    return this.configService.access().theme.darkmode;
  }

  get isDarkMode(): boolean {
    return this._isDarkMode;
  }

  set isDarkMode(value: boolean) {
    this.viewService.changeStyle(value ? DARK_THEME : this.configService.access().theme.defaultTheme);
  }
}
