import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Action } from '@app-modeleditor/components/button/action/action';
import { Button } from '@app-modeleditor/components/button/button';
import { EButtonDisplayType } from '@app-modeleditor/components/button/button-display-type.enum';
import { Content } from '@app-modeleditor/components/content/content';
import { ContentElement } from '@app-modeleditor/components/content/content-element/content-element';
import { EDisplayOrientation } from '@app-modeleditor/components/content/content-element/display-orientation.enum';
import { ContentPart } from '@app-modeleditor/components/content/content-part/content-part';
import { EntryCollection } from '@app-modeleditor/components/entry-collection/entry-collection';
import { EntryElement } from '@app-modeleditor/components/entry-collection/entry-element';
import { EEntryElementPosition } from '@app-modeleditor/components/entry-collection/entry-element-position.enum';
import { EntryElementValue } from '@app-modeleditor/components/entry-collection/entry-element-value';
import { EFieldType } from '@app-modeleditor/components/entry-collection/field-type.enum';
import { ButtonGroup } from '@app-modeleditor/components/entry-element/buttons-group';
import { EFilterOperator } from '@app-modeleditor/components/filter-element/filter-operator.enum';
import { ELogicalOperator } from '@app-modeleditor/components/filter-element/logical-operator.enum';
import { EDisplaySize } from '@app-modeleditor/components/filter-node-viewer/displaySize.enum';
import { FilterNodeViewer } from '@app-modeleditor/components/filter-node-viewer/filter-node-viewer';
import { Lightbox } from '@app-modeleditor/components/lightbox/lightbox';
import { Stackable } from '@app-modeleditor/components/stackable/stackable';
import { ColumnLayout } from '@app-modeleditor/components/structure/column-layout/column-layout';
import { Gantt_General } from 'frontend/src/dashboard/gantt/general/general.gantt.component';
import { EDatatype } from 'frontend/src/dashboard/model/entry/datatype.enum';
import { of } from 'rxjs';
import { EGanttBlockAttributeDataType } from './attribute-data-types.enum';
import { IGanttBlockAttributeData } from './attribute-data.interface';
import { CONDITIONAL_ENTRY_COLLECTION_ID, LOGICAL_ENTRY_COLLECTION_ID } from './filter-constants';
import { EFilteredOutElementDisplayOption } from './filter-options.enum';
import { FilterResult } from './filter-result';
import { IFilterNode } from './filter-tree';
import { FrontendFilter } from './frontend-filter';

export class EditFilterLightbox extends Lightbox {
  private root: Stackable;
  private namePart: EntryElement;
  private capitalizationPart: EntryElement;
  private hideEmptyRowsPart: EntryElement;
  private filteredElementDisplayTypePart: EntryElement;
  private filterStringPart: FilterNodeViewer;
  private frontendFilter: FrontendFilter;

  constructor(private scope: Gantt_General) {
    super();
    this._init();
  }

  /**
   * Sets initial values to filter lightbox.
   */
  private _init(): void {
    this.setName(this.scope.translate.instant('GANTT.FILTER.edit'))
      .setWidth(90)
      .setResizeable(false)
      .setDisableSaveButton(true)
      .setFullscreen(true)
      .setDisableCancelButton(true)
      .setContent(this.getLightboxContent())
      .setAdditionalButtons([
        new Button()
          .setName(this.scope.translate.instant('BUTTON.clear'))
          .chainActions(new Action().setCb(() => of(this.clearGroups()))),
        new Button()
          .setName(this.scope.translate.instant('BUTTON.save'))
          .setPosition(EEntryElementPosition.RIGHT)
          .chainActions(new Action().setCb(() => of(this.save()))),
      ]);
  }

  /**
   * saves current conditions as IFilterNode
   * @returns IFilterNode
   */
  private save(): IFilterNode {
    const result: IFilterNode = new FilterResult().save(this.root);
    const ff: FrontendFilter = (this.frontendFilter || new FrontendFilter()).setNode(result);

    if (!this.frontendFilter) {
      // if this is a new filter -> set initial public and quick filter value
      ff.setPublicFilter(false).setQuickfilter(false).setFixed(false);
    }
    ff.setValue(this.root.getName());

    const caseSensitiveValue = this.capitalizationPart.getValue<EntryElementValue>()?.getValue() ? true : false; // get case sensitve value from checkbox
    const hideEmptyRowsValue = this.hideEmptyRowsPart.getValue<EntryElementValue>()?.getValue() ? true : false; // get case sensitve value from checkbox
    let filteredOutDisplayOption = this.filteredElementDisplayTypePart
      .getValue<EntryElementValue>()
      .getValue<EFilteredOutElementDisplayOption>(); // get filtered out value from radio buttons
    filteredOutDisplayOption = filteredOutDisplayOption
      ? filteredOutDisplayOption
      : (filteredOutDisplayOption = EFilteredOutElementDisplayOption.HIDE); // if unset -> set hide as initial value

    // set values to filter
    ff.setCaseSensitive(caseSensitiveValue);
    ff.setHideEmptyRows(hideEmptyRowsValue);
    ff.setFilteredOutElementDisplayOption(filteredOutDisplayOption);
    ff.setFilterString(JSON.stringify(ff.getNode())); // generate and set filter string

    this.scope.ganttFrontendFilterService.addNewFilterToFilterList(ff);
    this.scope.ganttFrontendFilterService.refreshCurrentActiveFilter(); // refresh if current selected value has changed

    this.getRef().close();

    return result;
  }

  /**
   * Loads filter in light box.
   */
  public loadFilter(item: FrontendFilter): void {
    this.frontendFilter = item;
    this.root = this.getStackable();
    // add first node
    const c: Content = this.createEmptyContent([this.root]);
    c.addContentPartsAtIndex(0, this.getNamePart());
    c.addContentPartsAtIndex(2, this.getConditionAddButton());
    this.setContent(c);
    new FilterResult(this).restore(item.getNode(), this.root);
    this.root.setName(item.getValue());
    this.namePart.getValue<EntryElementValue>().setValue(item.getValue());
    this.capitalizationPart.setValue(new EntryElementValue().setValue(item.isCaseSensitive()));
    this.hideEmptyRowsPart.setValue(new EntryElementValue().setValue(item.isHideEmptyRows()));
    this.filteredElementDisplayTypePart.setValue(
      new EntryElementValue().setValue(
        item.getFilteredOutElementDisplayOption()
          ? item.getFilteredOutElementDisplayOption()
          : EFilteredOutElementDisplayOption.HIDE
      )
    );
    this.getFilterConditionsString();
    this.rootFlatlist = this.getRootFlatlist(this.root).filter(
      (child) => child.getContent() && child.getContent().getContentParts()[0].getContentElements().length > 0
    );
  }

  /**
   * resets lightbox content
   * @returns void
   */
  private clearGroups(): void {
    this.root.setChildren([]);
    this.root.setContent(null);
    this.addGroup(this.root);
  }

  public rootFlatlist: Stackable[] = [];

  private getRootFlatlist(item: Stackable): Stackable[] {
    let rootFlatlistTmp = [item];

    item.getChildren().forEach((childItem) => {
      rootFlatlistTmp = rootFlatlistTmp.concat(this.getRootFlatlist(childItem));
    });

    return rootFlatlistTmp;
  }

  /**
   * get content of the lightbox
   * @returns Content
   */
  private getLightboxContent(): Content {
    // define trees root
    this.root = this.getStackable();
    // add first node
    this.addGroup(this.root);
    const c: Content = this.createEmptyContent([this.root]);
    c.addContentPartsAtIndex(0, this.getNamePart());
    c.addContentPartsAtIndex(2, this.getConditionAddButton());
    return c;
  }

  private getConditionAddButton(): ContentPart {
    const action: Button = new Button()
      .setName(this.scope.translate.instant('CONDITION.add'))
      .setIcon('add')
      .chainActions(new Action().setCb(() => of(this.addGroup(null))));
    return new ContentPart()
      .setDisplayContentpartContainer(false)
      .setContentElements([
        new EntryCollection().setDisplayOrientation(EDisplayOrientation.VERTICAL).addEntryElements(action),
      ]);
  }

  private getNamePart(): ContentPart {
    const filterName = this.getHeadline() || this.scope.translate.instant('GANTT.FILTER.new');
    this.namePart = new EntryElement()
      .onChanges((e: EntryElementValue) => {
        this.root.setName(e.getValue());
      })
      .setFieldType(EFieldType.TEXT_FIELD)
      .setName(this.scope.translate.instant('GANTT.FILTER.name'))
      .setValue(new EntryElementValue().setValue(filterName));

    this.root.setName(filterName);

    let hideEmptyRowsValue = false;
    let caseSensitiveValue = false;
    if (this.frontendFilter) {
      caseSensitiveValue = this.frontendFilter.isCaseSensitive();
      hideEmptyRowsValue = this.frontendFilter.isHideEmptyRows();
    }

    const optionHeadline = new EntryElement()
      .setFieldType(EFieldType.HEADLINE)
      .setValue(new EntryElementValue().setValue(this.scope.translate.instant('GANTT.FILTER.options')));
    this.capitalizationPart = new EntryElement()
      .setFieldType(EFieldType.CHECK_BOX)
      .setValue(new EntryElementValue().setValue(null))
      .setName(this.scope.translate.instant('GANTT.FILTER.upperLowerCase'));
    this.hideEmptyRowsPart = new EntryElement()
      .setFieldType(EFieldType.CHECK_BOX)
      .setValue(new EntryElementValue().setValue(null))
      .setName(this.scope.translate.instant('GANTT.FILTER.hideEmptyLines'));
    this.filteredElementDisplayTypePart = new ButtonGroup()
      .setFieldType(EFieldType.RADIOBUTTONS)
      .setValue(new EntryElementValue().setValue(EFilteredOutElementDisplayOption.HIDE))
      .setDisplayType(EButtonDisplayType.LABEL_ONLY)
      .setName(this.scope.translate.instant('GANTT.FILTER.filteredElements'))
      .addAvailableButtons(
        new Button()
          .setName(this.scope.translate.instant('GANTT.FILTER.hide'))
          .setValue(new EntryElementValue().setValue(EFilteredOutElementDisplayOption.HIDE))
          .chainActions(
            new Action().setCb(() =>
              of(
                this.filteredElementDisplayTypePart.setValue(
                  new EntryElementValue().setValue(EFilteredOutElementDisplayOption.HIDE)
                )
              )
            )
          ),
        new Button()
          .setName(this.scope.translate.instant('GANTT.FILTER.weaken'))
          .setValue(new EntryElementValue().setValue(EFilteredOutElementDisplayOption.WEAKEN))
          .chainActions(
            new Action().setCb(() =>
              of(
                this.filteredElementDisplayTypePart.setValue(
                  new EntryElementValue().setValue(EFilteredOutElementDisplayOption.WEAKEN)
                )
              )
            )
          )
      );

    const separator = new EntryElement().setFieldType(EFieldType.SEPARATOR);

    const constraintsHeadline = new EntryElement()
      .setFieldType(EFieldType.HEADLINE)
      .setValue(new EntryElementValue().setValue(this.scope.translate.instant('GANTT.FILTER.partialCondition')));
    this.filterStringPart = new FilterNodeViewer().setDisplaySize(EDisplaySize.LARGE).setValue(new EntryElementValue());

    const action: Button = new Button()
      .setName(this.scope.translate.instant('CONDITION.add'))
      .setIcon('add')
      .chainActions(new Action().setCb(() => of(this.addGroup(null))));
    return new ContentPart().setDisplayContentpartContainer(false).setContentElements([
      new EntryCollection()
        .setDisplayOrientation(EDisplayOrientation.VERTICAL)
        .setLayout(new ColumnLayout().setColumnCount(5).setContent({ 0: [this.namePart.getId()] }))
        .setEntryElements([this.namePart]),
      new EntryCollection().setEntryElements([optionHeadline]),
      new EntryCollection()
        .setDisplayOrientation(EDisplayOrientation.VERTICAL)
        .addEntryElements(
          this.capitalizationPart,
          this.hideEmptyRowsPart,
          this.filteredElementDisplayTypePart,
          separator,
          constraintsHeadline,
          this.filterStringPart
        ),
    ]);
  }

  private getNodesParent(parent: Stackable, node: Stackable, contentId: string): Stackable {
    if (node.getContent() && node.getId() === contentId) {
      return parent;
    }

    for (const s of node.getChildren()) {
      const match: Stackable = this.getNodesParent(node, s, contentId);
      if (match) {
        return match;
      }
    }
    return null;
  }

  get filterOperators(): EntryElementValue[] {
    const filterOperators: EntryElementValue[] = [];
    for (const item in EFilterOperator) {
      if (isNaN(Number(item))) {
        filterOperators.push(
          new EntryElementValue()
            .setId(item)
            .setName(this.scope.translate.instant(`FILTER.FilterCondition.${item}`))
            .setValue(item)
        );
      }
    }

    return filterOperators;
  }

  public addCondition(stackable: Stackable, result?: any) {
    if (!stackable.getContent()) {
      stackable.setContent(this.createEmptyContent());
    }
    const part: ContentPart = stackable.getContent().getContentParts()[0];

    const mapping = this.scope.ganttTemplateDataService.getTemplateData().getAttributeMapping();
    const names: string[] = Object.keys(mapping)
      .map((index: string) => mapping[index].localization)
      .sort();

    const attributeData: IGanttBlockAttributeData[] = Object.keys(mapping).map((index: string) => {
      return {
        name: mapping[index].localization,
        id: mapping[index].id,
        type: mapping[index].dataType || EGanttBlockAttributeDataType.STRING,
      };
    });
    const firstSelectedAttribute = attributeData[0];

    const firstOperators = this.getOperatorsByDataType(firstSelectedAttribute.type);
    const firstSelectedOperator = firstOperators[0];

    const collection: EntryCollection = new EntryCollection()
      .setDisplayOrientation(EDisplayOrientation.VERTICAL)
      .setId(CONDITIONAL_ENTRY_COLLECTION_ID)
      .setFieldIdentifier('template-condition');
    const valueField: EntryElement = new EntryElement()
      .setName(this.scope.translate.instant('value'))
      .setFieldType(EFieldType.TEXT_FIELD)
      .setValue(new EntryElementValue())
      .onChanges(() => {
        this.getFilterConditionsString();
      });
    const valueField2: EntryElement = new EntryElement()
      .setName(this.scope.translate.instant('GANTT.FILTER.to'))
      .setFieldType(EFieldType.TEXT_FIELD)
      .setValue(new EntryElementValue())
      .onChanges(() => {
        this.getFilterConditionsString();
      });

    const selectionBox: EntryElement = new EntryElement()
      .setName(this.scope.translate.instant('attribute'))
      .setFieldType(EFieldType.COMBO_BOX)
      .setValue(new EntryElementValue().setAvailableValues(names).setValue(firstSelectedAttribute.name))
      .onChanges((change: EntryElement) => {
        const selectedValue = change.getValue();
        const selectedDataType = attributeData.find((data) => data.name === selectedValue).type;
        this.handleOperatorDropDownByDataType(selectedDataType, operatorBox);
        const selectedOperator = operatorBox
          .getValue<EntryElementValue>()
          .getValue<EntryElementValue>()
          .getValue<string>();

        this.handleInputFieldsByOperatorAndAttributeType(
          valueField,
          valueField2,
          EFilterOperator[selectedOperator],
          selectedDataType
        );
      });

    const operatorBox: EntryElement = new EntryElement()
      .setName(this.scope.translate.instant('GANTT.FILTER.filteroperation'))
      .setFieldType(EFieldType.COMBO_BOX)
      .setValue(new EntryElementValue().setAvailableValues(firstOperators).setValue(firstSelectedOperator))
      .onChanges((change: EntryElement) => {
        const selectedValue: EFilterOperator = change.getValue<EntryElementValue>().getValue();
        const selectedAttributeName = selectionBox.getValue<EntryElementValue>().getValue();
        const selectedAttributeType = attributeData.filter((attribute) => attribute.name === selectedAttributeName)[0]
          .type;
        this.handleInputFieldsByOperatorAndAttributeType(valueField, valueField2, selectedValue, selectedAttributeType);
      });

    if (result) {
      // handle selection options and inputs by result
      const operator = result[1];
      const selectedAttributeType = attributeData.find((attribute) => attribute.name === result[0]).type;

      this.handleInputFieldsByOperatorAndAttributeType(
        valueField,
        valueField2,
        EFilterOperator[operator],
        selectedAttributeType
      );
      this.handleOperatorDropDownByDataType(selectedAttributeType, operatorBox);
    } else {
      this.handleInputFieldsByOperatorAndAttributeType(
        valueField,
        valueField2,
        firstSelectedOperator.getValue(),
        firstSelectedAttribute.type
      );
    }

    const deleteConditionBtn: Button = new Button()
      .setId('delete-condition')
      .setName(this.scope.translate.instant('CONDITION.delete'))
      .setIcon('delete')
      .setEnableBy(() => this.rootFlatlist.length > 1)
      .setDisplayType(EButtonDisplayType.ICON_ONLY)
      .chainActions(
        new Action().setCb(() => {
          this.deleteGroup(stackable);
          this.checkAllGroups(null, this.root);
          this.getFilterConditionsString();
          return of(null);
        })
      );

    collection
      .setLayout(
        new ColumnLayout()
          .setColumnCount(5)
          .setColumnWidths({ 0: 25, 1: 10, 2: 30, 3: 25, 4: 10 })
          .setContent({
            0: [selectionBox.getId()],
            1: [operatorBox.getId()],
            2: [valueField.getId()],
            3: [valueField2.getId()],
            4: [deleteConditionBtn.getId()],
          })
      )
      .setEntryElements([selectionBox, operatorBox, valueField, valueField2, deleteConditionBtn]);

    /* if (parent.getGroups().length > 0) {
      part.setContentElements(part.getContentElements().concat(logicalOperationCollection));
    }*/
    part.setContentElements(part.getContentElements().concat(collection));
  }

  public getFilterConditionsString() {
    if (!this.filterStringPart) {
      return;
    }
    const result: IFilterNode = new FilterResult().save(this.root);

    const mapping = this.scope.ganttTemplateDataService.getTemplateData().getAttributeMapping();
    const attributeData: IGanttBlockAttributeData[] = Object.keys(mapping).map((index: string) => {
      return {
        name: mapping[index].localization,
        id: mapping[index].id,
        type: mapping[index].dataType || EGanttBlockAttributeDataType.STRING,
      };
    });
    this.filterStringPart.updateViewerData({ node: result, attributeData: attributeData });
  }

  private handleInputFieldsByOperatorAndAttributeType(
    field1: EntryElement,
    field2: EntryElement,
    operatorValue: EFilterOperator,
    attributeType: EGanttBlockAttributeDataType
  ) {
    const defaultDateTime = new Date();
    defaultDateTime.setHours(12);
    defaultDateTime.setMinutes(0);
    defaultDateTime.setSeconds(0);
    defaultDateTime.setMilliseconds(0);

    switch (operatorValue) {
      case EFilterOperator.begins_with:
      case EFilterOperator.ends_with:
      case EFilterOperator.not_contains:
      case EFilterOperator.contains:
        field1.setFieldType(EFieldType.TEXT_FIELD);
        field1.setName(this.scope.translate.instant('value'));
        field2.setShow(false);
        field1.setShow(true);
        field1.setDatatype(EDatatype.STRING);
        break;

      case EFilterOperator.gt:
      case EFilterOperator.gte:
      case EFilterOperator.lt:
      case EFilterOperator.lte:
        field2.setShow(false);
        field1.setShow(true);

        switch (attributeType) {
          case EGanttBlockAttributeDataType.DATE:
            field1
              .setName(this.scope.translate.instant('date'))
              .setFieldType(EFieldType.DATE_PICKER)
              .setUseLocalTime(true)
              .setValue(new EntryElementValue().setValue(defaultDateTime.getTime()));
            break;
          case EGanttBlockAttributeDataType.TIME:
            field1
              .setName(this.scope.translate.instant('time'))
              .setFieldType(EFieldType.TIME_PICKER)
              .setUseLocalTime(true)
              .setValue(new EntryElementValue().setValue(defaultDateTime.getTime()));
            break;
          case EGanttBlockAttributeDataType.DATE_TIME:
            field1
              .setName(this.scope.translate.instant('date'))
              .setFieldType(EFieldType.DATE_TIME_PICKER)
              .setUseLocalTime(true)
              .setValue(new EntryElementValue().setValue(defaultDateTime.getTime()));
            break;
          default:
            field1.setName(this.scope.translate.instant('value'));
            field1.setFieldType(EFieldType.TEXT_FIELD);
            field1.setDatatype(EDatatype.FLOAT);
            break;
        }
        break;

      case EFilterOperator.empty:
      case EFilterOperator.not_empty:
      case EFilterOperator.none:
        field1.setFieldType(EFieldType.TEXT_FIELD);
        field1.setName(this.scope.translate.instant('value'));
        field1.setShow(false);
        field2.setShow(false);
        break;

      case EFilterOperator.between:
      case EFilterOperator.not_between:
        field1.setShow(true);
        field1.setName(this.scope.translate.instant('GENERAL.from'));
        field2.setShow(true);

        switch (attributeType) {
          case EGanttBlockAttributeDataType.DATE:
            field1
              .setFieldType(EFieldType.DATE_PICKER)
              .setUseLocalTime(true)
              .setValue(new EntryElementValue().setValue(defaultDateTime.getTime()));
            field2
              .setFieldType(EFieldType.DATE_PICKER)
              .setUseLocalTime(true)
              .setValue(new EntryElementValue().setValue(defaultDateTime.getTime()));
            break;
          case EGanttBlockAttributeDataType.TIME:
            field1
              .setFieldType(EFieldType.TIME_PICKER)
              .setUseLocalTime(true)
              .setValue(new EntryElementValue().setValue(defaultDateTime.getTime()));
            field2
              .setFieldType(EFieldType.TIME_PICKER)
              .setUseLocalTime(true)
              .setValue(new EntryElementValue().setValue(defaultDateTime.getTime()));
            break;
          case EGanttBlockAttributeDataType.DATE_TIME:
            field1
              .setFieldType(EFieldType.DATE_TIME_PICKER)
              .setUseLocalTime(true)
              .setValue(new EntryElementValue().setValue(defaultDateTime.getTime()));
            field2
              .setFieldType(EFieldType.DATE_TIME_PICKER)
              .setUseLocalTime(true)
              .setValue(new EntryElementValue().setValue(defaultDateTime.getTime()));
            break;
          default:
            field1.setFieldType(EFieldType.TEXT_FIELD);
            field1.setDatatype(EDatatype.FLOAT);
            field2.setFieldType(EFieldType.TEXT_FIELD);
            field2.setDatatype(EDatatype.FLOAT);
            break;
        }
        break;
      case EFilterOperator.eq:
      case EFilterOperator.neq:
        field1.setShow(true);
        field2.setShow(false);

        switch (attributeType) {
          case EGanttBlockAttributeDataType.DATE:
            field1
              .setName(this.scope.translate.instant('date'))
              .setFieldType(EFieldType.DATE_PICKER)
              .setUseLocalTime(true)
              .setValue(new EntryElementValue().setValue(defaultDateTime.getTime()));
            break;
          case EGanttBlockAttributeDataType.TIME:
            field1
              .setName(this.scope.translate.instant('time'))
              .setFieldType(EFieldType.TIME_PICKER)
              .setUseLocalTime(true)
              .setValue(new EntryElementValue().setValue(defaultDateTime.getTime()));
            break;
          case EGanttBlockAttributeDataType.DATE_TIME:
            field1
              .setName(this.scope.translate.instant('date'))
              .setFieldType(EFieldType.DATE_TIME_PICKER)
              .setUseLocalTime(true)
              .setValue(new EntryElementValue().setValue(defaultDateTime.getTime()));
            break;
          case EGanttBlockAttributeDataType.BOOLEAN:
            field1.setFieldType(EFieldType.CHECK_BOX).setValue(new EntryElementValue().setValue(null)).setName('');
            break;
          case EGanttBlockAttributeDataType.NUMBER:
            field1.setFieldType(EFieldType.TEXT_FIELD);
            field1.setDatatype(EDatatype.FLOAT);
          default:
            field1.setName(this.scope.translate.instant('value'));
            field1.setFieldType(EFieldType.TEXT_FIELD);
            break;
        }
        break;
    }
    this.getFilterConditionsString();
  }

  private handleOperatorDropDownByDataType(
    attributeDataType: EGanttBlockAttributeDataType,
    operatorDropDown: EntryElement
  ) {
    const operators = this.getOperatorsByDataType(attributeDataType);
    operatorDropDown.setValue(new EntryElementValue().setAvailableValues(operators).setValue(operators[0]));
  }

  private getOperatorsByDataType(dataType: EGanttBlockAttributeDataType): EntryElementValue[] {
    const filterOperators: EntryElementValue[] = [];
    let allowedTypes: EFilterOperator[] = [];
    switch (dataType) {
      case EGanttBlockAttributeDataType.BOOLEAN:
        allowedTypes = [EFilterOperator.eq, EFilterOperator.neq, EFilterOperator.none];
        break;
      case EGanttBlockAttributeDataType.DATE:
      case EGanttBlockAttributeDataType.TIME:
      case EGanttBlockAttributeDataType.DATE_TIME:
        allowedTypes = [
          EFilterOperator.eq,
          EFilterOperator.neq,
          EFilterOperator.none,
          EFilterOperator.gt,
          EFilterOperator.lte,
          EFilterOperator.gte,
          EFilterOperator.lt,
          EFilterOperator.not_between,
          EFilterOperator.between,
        ];
        break;
      case EGanttBlockAttributeDataType.NUMBER:
        allowedTypes = [
          EFilterOperator.eq,
          EFilterOperator.neq,
          EFilterOperator.none,
          EFilterOperator.by_value,
          EFilterOperator.gt,
          EFilterOperator.lte,
          EFilterOperator.gte,
          EFilterOperator.lt,
          EFilterOperator.not_between,
          EFilterOperator.between,
        ];
        break;
      case EGanttBlockAttributeDataType.STRING:
      default:
        allowedTypes = [
          EFilterOperator.eq,
          EFilterOperator.neq,
          EFilterOperator.none,
          EFilterOperator.begins_with,
          EFilterOperator.contains,
          EFilterOperator.empty,
          EFilterOperator.ends_with,
          EFilterOperator.not_contains,
          EFilterOperator.not_empty,
        ];
        break;
    }

    for (const item of allowedTypes) {
      if (isNaN(Number(item))) {
        filterOperators.push(
          new EntryElementValue()
            .setId(item)
            .setName(this.scope.translate.instant(`FILTER.FilterCondition.${item}`))
            .setValue(item)
        );
      }
    }
    return filterOperators;
  }

  private deleteCondition(part: ContentPart, collections: EntryCollection[]): void {
    part.setContentElements(
      part
        .getContentElements()
        .filter((ce: ContentElement) => !collections.find((c: EntryCollection) => c.getId() === ce.getId()))
    );
  }

  public addGroup(stackable: Stackable, skipCheck?: boolean): Stackable {
    const s: Stackable = this.getStackable();
    this.addCondition(s);
    s.setParent(stackable || this.root);
    (stackable || this.root).addChildren(s);

    if (!skipCheck) {
      this.checkAllGroups(null, this.root);
    }
    this.rootFlatlist = this.getRootFlatlist(this.root).filter((child) => child.getContent());
    this.getFilterConditionsString();
    return s;
  }

  private deleteGroup(stackable: Stackable): void {
    const parent: Stackable = this.getNodesParent(null, this.root, stackable.getId()) || this.root;
    /*
    if (stackable.getChildren().length > 0) {
      this.scope.lightboxApi.open(new ConfirmLightbox('BUTTON.delete').setCustomConfirmAction(() => {
        parent.setChildren(parent.getChildren().filter((g: Stackable) => g.getId() !== stackable.getId()));
        return of(null);
      }));
    } else {

    }*/
    this.realDelete(this.root, stackable.getId());
    if (parent && !parent.getContent() && parent.getChildren().length === 0) {
      this.realDelete(this.root, parent.getId());
    }
    this.rootFlatlist = this.getRootFlatlist(this.root).filter((child) => child.getContent());
    // this.checkAllGroups(null, this.root);
  }

  private realDelete(node: Stackable, id: string): void {
    const children: Stackable[] = node.getChildren().filter((s: Stackable) => s.getId() !== id);
    node.setChildren(children);
    children.forEach((s: Stackable) => this.realDelete(s, id));
  }

  /**
   * creates a stackable
   * @returns Stackable
   */
  public getStackable(): Stackable {
    const stackable: Stackable = new Stackable();
    stackable.onDrop().subscribe((event: CdkDragDrop<Stackable>) => {
      // const prev: Stackable = event.previousContainer.data;
      const cur: Stackable = event.container.data;
      this.checkAllGroups(null, this.root);
      this.rootFlatlist = this.getRootFlatlist(this.root).filter((child) => child.getContent());
      this.getFilterConditionsString();
    });

    stackable.onClick().subscribe((event: Stackable) => {
      this.executeForEach(this.root, (n: Stackable) => n.setSelected(false));
      event.setSelected(true);
    });

    return stackable;
  }

  private executeForEach(node: Stackable, cb: (Stackable) => void) {
    cb(node);
    node.getChildren().forEach((c: Stackable) => {
      this.executeForEach(c, cb);
    });
  }

  get logicalOperators(): EntryElementValue[] {
    const logicalOperators: EntryElementValue[] = [];
    for (const item in ELogicalOperator) {
      if (isNaN(Number(item))) {
        logicalOperators.push(new EntryElementValue().setValue(item).setName(`FILTER.Operation.${item}`));
      }
    }
    return logicalOperators;
  }

  private getLogicalOperatorContent(logic?: EntryElementValue): EntryCollection {
    const logicalEntry: EntryElement = new EntryElement()
      .setName(this.scope.translate.instant('GANTT.FILTER.link'))
      .setFieldType(EFieldType.COMBO_BOX)
      .setValue(
        new EntryElementValue().setAvailableValues(this.logicalOperators).setValue(logic || this.logicalOperators[0])
      )
      .onChanges(() => {
        this.getFilterConditionsString();
      });

    // create empty elements to fill the empty columns
    const emptyElem1: EntryElement = new EntryElement().setFieldType(EFieldType.EMPTY_ELEMENT);
    const emptyElem2: EntryElement = new EntryElement().setFieldType(EFieldType.EMPTY_ELEMENT);

    const logicalOperationCollection: EntryCollection = new EntryCollection()
      .setDisplayOrientation(EDisplayOrientation.VERTICAL)
      .setId(LOGICAL_ENTRY_COLLECTION_ID)
      .setLayout(
        new ColumnLayout()
          .setColumnCount(3)
          .setColumnWidths({ 0: 45, 1: 10, 2: 45 })
          .setContent({ 0: [emptyElem1.getId()], 1: [logicalEntry.getId()], 2: [emptyElem2.getId()] })
      )
      .setFieldIdentifier('logical-operator')
      .setEntryElements([emptyElem1, logicalEntry, emptyElem2]);

    return logicalOperationCollection;
  }

  /**
   * creates an empty Content
   * @param ce ContentElement[]
   * @returns Content
   */
  public createEmptyContent(ce?: ContentElement[]): Content {
    return new Content().setContentParts([
      new ContentPart().setDisplayContentpartContainer(false).setContentElements(ce),
    ]);
  }

  public addLogicalOperator(stackable: Stackable, logic?: EntryElementValue): void {
    if (!stackable.getContent() /* && stackable.getChildren().length !== 0*/) {
      stackable.setContent(this.createEmptyContent());
    }
    const part: ContentPart = stackable.getContent().getContentParts()[0];
    const ces: EntryCollection[] = part.getContentElements<EntryCollection[]>();
    if (ces.find((s: EntryCollection) => s.getId() === LOGICAL_ENTRY_COLLECTION_ID)) {
      return;
    }
    part.addContentElements(this.getLogicalOperatorContent(logic));
  }

  private removeEmptyContent(stackable: Stackable) {
    if (!stackable.getContent()) {
      return;
    }

    const collections: EntryCollection[] = stackable
      .getContent()
      .getContentParts()[0]
      .getContentElements<EntryCollection[]>();
    if (
      collections.length === 0 ||
      (collections.length === 1 &&
        stackable.getChildren().length === 0 &&
        collections.find((e: EntryCollection) => e.getId() === LOGICAL_ENTRY_COLLECTION_ID))
    ) {
      stackable.setContent(null);
    }

    if (!stackable.getContent() && stackable.getChildren().length === 0) {
      this.deleteGroup(stackable);
    }
  }

  /**
   * removes the logical operator from content
   * @param stackable Stackable
   * @returns void
   */
  private removeLogicalOperator(stackable: Stackable): void {
    if (!stackable.getContent()) {
      return;
    }
    const part: ContentPart = stackable.getContent().getContentParts()[0];
    part.setContentElements(
      part
        .getContentElements<EntryCollection[]>()
        .filter((ce: EntryCollection) => ce.getId() !== LOGICAL_ENTRY_COLLECTION_ID)
    );
  }

  /**
   * copy content from one stackable to another
   * @param from Stackable
   * @param to Stackable
   * @returns Content
   */
  private copyContent(from: Stackable, to: Stackable): Content {
    const c: Content = from.getContent() ? from.getContent().copy(Content) : this.createEmptyContent();
    const cEntryColl: EntryCollection = c
      .getContentParts()[0]
      .getContentElements<EntryCollection[]>()
      .find((e: EntryCollection) => e.getId() === CONDITIONAL_ENTRY_COLLECTION_ID);
    if (cEntryColl) {
      const btn: Button = cEntryColl.getEntryElements<Button[]>().find((e: Button) => e.getId() === 'delete-condition');
      btn
        // .setDisabled(this.root.getId() === to.getId())
        .setEnableBy(() => this.rootFlatlist.length > 1)
        .overwriteChain(
          new Action().setCb(() => {
            this.deleteGroup(to);
            this.checkAllGroups(null, this.root);
            this.getFilterConditionsString();
            return of(null);
          })
        );
    }

    to.setContent(c);
    return c;
  }

  applyContentAndKeepOperator(s: Stackable, c: EntryCollection) {
    const cur: Content = s.getContent();
    /* if (cur) {
       const part: ContentPart = cur.getContentParts()[0];
       const es: EntryCollection[] = [c].concat(part.getContentElements<EntryCollection[]>());
       part.setContentElements(es);
     }*/

    s.setContent(this.createEmptyContent([c]));
  }

  /**
   * check groups and add logical operators
   * @param parent Stackable
   * @param stackable Stackable
   * @returns void
   */
  private checkAllGroups(parent: Stackable, stackable: Stackable): void {
    // execute check for all groups of each child
    stackable.getChildren().forEach((s: Stackable, index: number) => {
      this.checkAllGroups(stackable, s);
    });

    // fix problems of empty groups
    if (parent && parent.getChildren().length === 1 && !stackable.getContent() && stackable.getChildren().length > 0) {
      // parent.setChildren(stackable.getChildren());
      // parent.setChildren(stackable.getChildren());
    }

    // starts first iteration with deepest child
    // merge contents for groups
    if (parent && parent.getChildren().length > 0 && parent.getContent()) {
      const ns: Stackable = this.getStackable();
      this.copyContent(parent, ns);
      ns.setParent(parent);
      parent.setContent(null);
      parent.setChildren(parent.getChildren().concat(ns));
      // check for newly created node

      this.checkAllGroups(parent, ns);
    }

    // delete empty groups
    if (parent && stackable.getChildren().length === 0 && !stackable.getContent()) {
      this.deleteGroup(stackable);
    }

    // move single contents one hierarchy up if there is no content
    this.checkParentReverse(stackable, parent);

    // add/remove logical operators
    const idx: number = parent ? parent.getChildren().findIndex((s: Stackable) => s.getId() === stackable.getId()) : 0;
    if (parent && idx !== parent.getChildren().length - 1) {
      this.addLogicalOperator(stackable);
    } else {
      this.removeLogicalOperator(stackable || this.root);
    }

    if (this.root.getId() !== stackable.getId()) {
      this.removeEmptyContent(stackable);
    }
  }

  private checkParentReverse(stackable: Stackable, parent: Stackable) {
    if (!parent) {
      return;
    }
    if (this.root.getId() === parent.getId() || parent.getChildren().length > 1) {
      return;
    }
    if (parent.getChildren()[0].getId() === stackable.getId()) {
      this.copyContent(stackable, parent);
      this.copyChildren(stackable, parent);
      this.checkParentReverse(parent, parent.getParent());
    }
  }

  private copyChildren(from: Stackable, to: Stackable) {
    to.setChildren(
      from.getChildren().map((child) => {
        child.setParentId(to.getId());
        child.setParent(to);
        return child;
      })
    );
  }
}
