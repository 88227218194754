<div class="hotspot-region-sidebar-wrapper">
  <form class="example-form">
    <mat-form-field class="attribute-search">
      <input
        [formControl]="searchControl"
        autocomplete="off"
        matInput
        placeholder="Search"
        [value]="searchString"
        (keyup)="searchRegion($event)"
      />
    </mat-form-field>
  </form>

  <button mat-icon-button class="region-sidebat-save-btn">
    <mat-icon>save</mat-icon>
  </button>

  <mat-form-field class="attribute-category-select">
    <mat-label>{{ 'attribute_category' | translate }}</mat-label>
    <mat-select (selectionChange)="changeAttributeCategory($event)" [value]="currentAttribute?.getId()">
      <mat-option
        [matTooltip]="attributeCategory.getName()"
        [matTooltipShowDelay]="1000"
        *ngFor="let attributeCategory of attributeCategories"
        [value]="attributeCategory.getId()"
        >{{ attributeCategory.getName() }}</mat-option
      >
    </mat-select>
  </mat-form-field>

  <mat-list class="region-list">
    <mat-list-item class="region-list-item" *ngFor="let region of getRegions()">
      <div
        *ngIf="currentAttribute"
        class="region-color"
        [ngStyle]="{ 'background-color': getCurrentRegionColor(region) }"
      ></div>
      <div class="region-label">{{ region.getName() }}</div>
      <div *ngIf="currentAttribute" class="region-value">{{ getCurrentAttributeValue(region) }}</div>
      <mat-divider></mat-divider>
    </mat-list-item>
  </mat-list>
</div>
