import { EChartLegendType } from './chart-legend-type.enum';
import { ChartDataGroup } from '../generalChart/chart.mapper';

export class ChartLegendItem {
  private type: EChartLegendType;
  private id: string;
  private label: string;
  private key: string;
  private color: string;
  private value: any;
  private active: boolean;
  private parentItem: ChartLegendItem;
  private availableValues: ChartLegendItem[];
  private manageByBackend: boolean;
  private expanded: boolean;
  private datasetItem: ChartDataGroup;

  public getDatasetItem(): ChartDataGroup {
    return this.datasetItem;
  }

  public setDatasetItem(datasetItem: ChartDataGroup): ChartLegendItem {
    this.datasetItem = datasetItem;
    return this;
  }

  public isExpanded(): boolean {
    return this.expanded;
  }

  public setExpanded(expanded: boolean): void {
    this.expanded = expanded;
  }
  public toogleExpanded(): ChartLegendItem {
    this.expanded = !this.expanded;
    return this;
  }

  public toogleActive(): ChartLegendItem {
    this.active = !this.active;
    return this;
  }
  public setActive(active: boolean): ChartLegendItem {
    this.active = active;
    return this;
  }
  public setType(type: EChartLegendType): ChartLegendItem {
    this.type = type;
    return this;
  }
  public setValue(value: any): ChartLegendItem {
    this.value = value;
    return this;
  }
  public setId(id: string): ChartLegendItem {
    this.id = id;
    return this;
  }
  public setColor(color: string): ChartLegendItem {
    this.color = color;
    return this;
  }
  public setKey(key: string): ChartLegendItem {
    this.key = key;
    return this;
  }
  public setLabel(label: string): ChartLegendItem {
    this.label = label;
    return this;
  }
  public setAvailableValues(availableValues: ChartLegendItem[]): ChartLegendItem {
    this.availableValues = availableValues;
    return this;
  }
  public setManageByBackend(manageByBackend: boolean): ChartLegendItem {
    this.manageByBackend = manageByBackend;
    return this;
  }
  public setParentItem(parentItem: ChartLegendItem): ChartLegendItem {
    this.parentItem = parentItem;
    return this;
  }

  public getParentItem(): ChartLegendItem {
    return this.parentItem;
  }

  public isActive(): boolean {
    return this.active;
  }
  public getType(): EChartLegendType {
    return this.type;
  }
  public getId(): string {
    return this.id;
  }
  public getKey(): string {
    return this.key;
  }
  public getColor(): string {
    return this.color;
  }
  public getLabel(): string {
    return this.label;
  }
  public getValue(): any {
    return this.value;
  }
  public getAvailableValues(): ChartLegendItem[] {
    return this.availableValues || [];
  }
  public getManageByBackend(): boolean {
    return this.manageByBackend;
  }
}
