import { IGanttResponse } from '../../../gantt-response';
import { RowUpdateSortShiftBlocks } from './sort-shiftblocks';

/**
 * Response preparation which handes data preparation of incoming response gantt data.
 */
export class GanttResponsePreparationHandler {
  /**
   * Executes preparation operations.
   * @param response Server response.
   */
  public prepareResponse(response: IGanttResponse) {
    return new RowUpdateSortShiftBlocks().sortShiftBlocksByTime(response);
  }
}
