import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { TemplateUiComponent } from '@app-modeleditor/components/template-ui/template-ui.component';
import { TranslateModule } from '@ngx-translate/core';
import { ContentModule } from '../content/content.module';
import { TemplateSidebarModule } from '../template-sidebar/template-sidebar.module';
import { EditmodeBarModule } from '../tree/editmode-bar/editmode-bar.module';
import { SlotModule } from './../template/slot/slot.module';
import { SaxmsTooltipModule } from './../tooltip/tooltip.module';
import { ToolbarRowModule } from './toolbar-row/toolbar-row.module';

@NgModule({
  imports: [
    CommonModule,
    ContentModule,
    MatButtonModule,
    MatIconModule,
    SaxmsTooltipModule,
    TemplateSidebarModule,
    SlotModule,
    EditmodeBarModule,
    ToolbarRowModule,
    TranslateModule,
  ],
  declarations: [TemplateUiComponent],
  exports: [TemplateUiComponent],
  providers: [],
})
export class TemplateUiModule {}
