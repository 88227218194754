import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { FileUploaderModule } from '@app-modeleditor/components/file-uploader/file-uploader.module';
import { SlotModule } from '@app-modeleditor/components/template/slot/slot.module';
import { TranslateModule } from '@ngx-translate/core';
import { ImageModule } from '@app-modeleditor/components/image/image.module';
import { FilterModule } from 'frontend/src/dashboard/core/filter/filter.module';
import { MaterialModule } from 'frontend/src/dashboard/core/material/material.module';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { FilterchipsListModule } from '../filterchips-list/filterchips-list.module';
import { TreeNodeModule } from '../tree-node/tree-node.module';
import { TreeSecondColumnComponent } from './tree-second-column.component';

@NgModule({
  imports: [
    CommonModule,
    ScrollingModule,
    FilterModule,
    MaterialModule,
    TranslateModule,
    TreeNodeModule,
    FilterchipsListModule,
    MatBadgeModule,
    ImageModule,
    NgScrollbarModule,
    FileUploaderModule,
    SlotModule,
  ],
  declarations: [TreeSecondColumnComponent],
  exports: [TreeSecondColumnComponent],
})
export class TreeSecondColumnModule {}
