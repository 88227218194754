import { ITemplate } from '../content/template';
import { ContentElement } from '../content/content-element/content-element';

export interface ILegendItem {
  id?: string;
  label: string;
  refId: string;
  hidden: boolean;
}

export interface ITemplateMap extends ITemplate {
  restUrl: string;
  legendsActions: string[];
  mapLayers: any[];
  selectedValue?: any;
  minHeight?: number;
  timedRangePicker?: boolean;
}

export class TemplateMap extends ContentElement {
  private legendsActions: string[];
  private mapLayers: any[];
  private selectedValue: any;
  private minHeight: number;
  private timedRangePicker: boolean;
  public value: any;

  constructor() {
    super();
  }

  setLegendsActions(legendsActions: string[]): this {
    this.legendsActions = legendsActions;
    return this;
  }
  setMapLayers(mapLayers: any[]): this {
    this.mapLayers = mapLayers;
    return this;
  }
  setSelectedValue(selectedValue: any): this {
    this.selectedValue = selectedValue;
    return this;
  }
  setMinHeight(minHeight: number): this {
    this.minHeight = minHeight;
    return this;
  }
  setTimedRangePicker(timedRangePicker: boolean): this {
    this.timedRangePicker = timedRangePicker;
    return this;
  }

  getLegendsActions(): string[] {
    return this.legendsActions;
  }
  getMapLayers(): any[] {
    return this.mapLayers;
  }

  getSelectedValue(): any {
    return this.selectedValue;
  }
  getMinHeight(): number {
    return this.minHeight;
  }

  isTimedRangePicker(): boolean {
    return this.timedRangePicker;
  }
}

export interface IMapTimeFilterRange {
  startTime: Date;
  startString: string;
  endTime: Date;
  endString: string;
}

export enum EMapLayerType {
  MARKER = 'MARKER',
  MOVEMENT = 'MOVEMENT',
}
