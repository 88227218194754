import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatLegacySlideToggleChange as MatSlideToggleChange } from '@angular/material/legacy-slide-toggle';
import { MatLegacySliderChange as MatSliderChange } from '@angular/material/legacy-slider';
import { SaxMsBestGanttSettings } from 'frontend/src/dashboard/gantt/gantt/saxms-best-gantt.settings';

@Component({
  selector: 'app-dividing-lines',
  templateUrl: './dividing-lines.component.html',
  styleUrls: ['./dividing-lines.component.scss'],
})
export class DividingLinesComponent implements OnInit {
  @Input() settings: SaxMsBestGanttSettings;
  @Output() changeEvent: EventEmitter<null> = new EventEmitter();

  constructor(private cdRef: ChangeDetectorRef) {}

  ngOnInit(): void {}

  public setLineColor(color: string, property: string) {
    this.settings[property] = color;
    this.changeEvent.emit();
  }

  public setLineThickness(event: MatSliderChange, property: string) {
    this.settings[property] = event.value;
    this.cdRef.detectChanges();
    this.changeEvent.emit();
  }

  public toggleDividingLines(event: MatSlideToggleChange, direction: string): void {
    if (direction === 'horizontal') {
      this.settings.showHorizontalLines = event.checked;
    } else {
      this.settings.showVerticalLines = event.checked;
    }
    this.changeEvent.emit();
  }

  public toggleShowSplitLines(event: MatSlideToggleChange): void {
    this.settings.showHorizontalSplitLines = event.checked;
    this.changeEvent.emit();
  }
}
