import { DateSelectorElement } from '@app-modeleditor/components/gantt-date-selector/date-selector-element';
import { takeUntil } from 'rxjs/operators';
import { Gantt_General } from '../../general.gantt.component';
import { Subscription } from 'rxjs';

export class CalendarWeekSelectorElement extends DateSelectorElement {
  private _ganttOnZoomEndSubscription: Subscription = null;
  private _ganttChangeTimeSpanSubscription: Subscription = null;

  constructor(private scope: Gantt_General) {
    super();
  }

  get(data: any): CalendarWeekSelectorElement {
    this.scope.ganttPluginHandlerService
      .getNotifiedIfInitialized()
      .pipe(takeUntil(this.scope.onDestroy))
      .subscribe((isInitialized: boolean) => {
        if (!isInitialized) {
          return;
        }
        this.calcTimes();
        this.registerGanttCallBacks();
      });

    this.onChanges((data) => {
      // this.changeTimeSpan();
      if (data.type === 'internal' && data.from && data.to) {
        this.calcTimes(new Date(data.from), new Date(data.to));
      }
    });

    return this.setId(data.id).setName(data.name);
  }

  private calcTimes(start?: Date, end?: Date) {
    this.unregisterGanttCallBacks();
    const diagram = this.scope.ganttLibService.bestGantt;
    const timeStart: Date = new Date(diagram.getDataHandler().getOriginDataset().minValue);
    const timeEnd: Date = new Date(diagram.getDataHandler().getOriginDataset().maxValue);

    const currentTimeSpan = this.getCurrentZoomTimeSpan(diagram);

    if (start && end) {
      currentTimeSpan.from = start;
      currentTimeSpan.to = end;
      diagram.getXAxisBuilder().translateToTimeSpanAdapted(start, end);
      // this.scope.submenuService.onElementActiveStateChanged(this.getId(), true, currentTimeSpan);
      this.setCurrentTimeSpanFrom(currentTimeSpan.from)
        .setCurrentTimeSpanTo(currentTimeSpan.to)
        .setTimeSpanFrom(timeStart)
        .setTimeSpanTo(timeEnd);
    } else {
      this.setCurrentTimeSpanFrom(currentTimeSpan.from)
        .setCurrentTimeSpanTo(currentTimeSpan.to)
        .setTimeSpanFrom(timeStart)
        .setTimeSpanTo(timeEnd);
      this.executeChanges({ type: 'external' });
    }

    this.registerGanttCallBacks();
  }

  private unregisterGanttCallBacks() {
    this._ganttOnZoomEndSubscription?.unsubscribe();
    this._ganttOnZoomEndSubscription = null;
    this._ganttChangeTimeSpanSubscription?.unsubscribe();
    this._ganttChangeTimeSpanSubscription = null;
  }

  private registerGanttCallBacks() {
    const diagram = this.scope.ganttLibService.bestGantt;
    this.unregisterGanttCallBacks();

    this._ganttOnZoomEndSubscription = diagram
      .getXAxisBuilder()
      .onZoomEnd.pipe(takeUntil(diagram.onDestroy))
      .subscribe(() => {
        this.scope.ganttLibService.ngZone.run((_) => {
          this.changeTimeSpan();
        });
      });
    this._ganttChangeTimeSpanSubscription = diagram
      .getXAxisBuilder()
      .onChangeTimeSpan.pipe(takeUntil(diagram.onDestroy))
      .subscribe(() => {
        this.scope.ganttLibService.ngZone.run((_) => {
          this.changeTimeSpan();
        });
      });
  }

  private changeTimeSpan() {
    const diagram = this.scope.ganttLibService.bestGantt;
    const currentTimeSpan = this.getCurrentZoomTimeSpan(diagram);
    // this.scope.submenuService.onElementActiveStateChanged(this.getId(), true, currentTimeSpan);
    this.calcTimes();
  }

  private getCurrentZoomTimeSpan(diagram: any): any {
    const currentTimeSpan = diagram.getXAxisBuilder().getCurrentScale().domain();
    return { from: currentTimeSpan[0], to: currentTimeSpan[1] };
  }
}
