export class Zoom {
  private created: Date;
  private start: Date;
  private end: Date;

  constructor() {
    this.created = new Date();
  }

  public getCreated(): Date {
    return this.created;
  }

  public setCreated(created: Date): this {
    this.created = created;
    return this;
  }

  public getStart(): Date {
    return this.start;
  }

  public setStart(start: Date): this {
    this.start = start;
    return this;
  }

  public getEnd(): Date {
    return this.end;
  }

  public setEnd(end: Date): this {
    this.end = end;
    return this;
  }
}
