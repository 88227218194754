import { ComponentEvent } from '../../../history/component-event';

/**
 * @constructor
 * @class
 * @extends ComponentEvent
 * @requires ComponentEvent
 * @plugin timeperiod-marker
 * */
export class GanttXAxisZoomTransformatorEvent extends ComponentEvent {
  constructor() {
    super();
    /**
     * @type {GanttXAxisZoomTransformator}
     */
    this.ref = null;
  }

  /**
   * @override
   * @returns {boolean} true if undo was succesfull, else false
   */
  undo() {
    const s = this;

    function handle(type) {
      const types = {
        setFormatToWeeks: function () {
          return s._undoFormatWeeks();
        },
        setFormatToDays: function () {
          return s._undoFormatDays();
        },
        resetFormat: function () {
          return s._undoReset();
        },
        setZoomToTimeSpan: function () {
          return s._undoTimeSpan();
        },
        default: function () {
          console.warn('can not undo: ' + type + '. May not be implemented yet.');
          return false;
        },
      };
      return (types[type] || types['default'])();
    }
    return handle(s.eventName);
  }

  /**
   * @override
   * @returns {boolean} true if redo was succesfull, else false
   */
  redo() {
    const s = this;
    function handle(type) {
      const types = {
        setFormatToWeeks: function () {
          return s._redoFormatWeeks();
        },
        setFormatToDays: function () {
          return s._redoFormatDays();
        },
        resetFormat: function () {
          return s._redoReset();
        },
        setZoomToTimeSpan: function () {
          return s._redoTimeSpan();
        },
        default: function () {
          console.warn('can not redo: ' + type + '. May not be implemented yet.');
          return false;
        },
      };
      return (types[type] || types['default'])();
    }
    return handle(s.eventName);
  }

  private _undoFormatWeeks() {
    const s = this;
    s.ref.disableLogging();
    const activeFormat = s.arguments[0];

    if (!activeFormat) s.ref.removeFormat();
    if (activeFormat === 'DAY') s.ref.setFormatToDays();

    s.ref.enableLogging();
    return true;
  }
  private _redoFormatWeeks() {
    const s = this;
    s.ref.disableLogging();
    s.ref.setFormatToWeeks();
    s.ref.enableLogging();
    return true;
  }

  private _undoFormatDays() {
    const s = this;
    s.ref.disableLogging();
    const activeFormat = s.arguments[0];

    if (!activeFormat) s.ref.removeFormat();
    if (activeFormat === 'WEEK') s.ref.setFormatToWeeks();
    s.ref.enableLogging();
    return true;
  }
  private _redoFormatDays() {
    const s = this;
    s.ref.disableLogging();
    s.ref.setFormatToDays();
    s.ref.enableLogging();
    return true;
  }

  private _undoReset() {
    const s = this;
    s.ref.disableLogging();
    const activeFormat = s.arguments[0];

    if (activeFormat === 'WEEK') s.ref.setFormatToWeeks();
    if (activeFormat === 'DAY') s.ref.setFormatToDays();
    s.ref.enableLogging();
    return true;
  }
  private _redoReset() {
    const s = this;
    s.ref.disableLogging();
    s.ref.removeFormat();
    s.ref.enableLogging();
    return true;
  }

  private _undoTimeSpan() {
    const s = this;
    s.ref.disableLogging();
    const prevScaling = s.arguments[0];
    s.ref.setZoomToTimeSpan(prevScaling[0], prevScaling[1]);
    s.ref.enableLogging();
    return true;
  }
  private _redoTimeSpan() {
    const s = this;
    s.ref.disableLogging();
    const newScalingStart = s.arguments[1];
    const newScalingEnd = s.arguments[2];
    s.ref.setZoomToTimeSpan(newScalingStart, newScalingEnd);
    s.ref.enableLogging();
    return true;
  }

  // GETTER and SETTER inherited from Main Class

  /**
   * @overrides
   * @return {String}
   */
  getEventDescription() {
    const s = this;
    function handle(type) {
      const types = {
        /* 'addTimePeriodToRow': function () { return GanttXAxisZoomTransformatorEventDescription.getDescription(s.ref.ganttDiagram.getConfig().getLanguage(), s.arguments); },
         */ default: function () {
          console.warn('no description for: ' + s.eventName + ' set. May not be implemented yet.');
          return '';
        },
      };
      return (types[type] || types['default'])();
    }
    return handle(s.eventName);
  }
}

export class GanttXAxisZoomTransformatorEventEventDescription {
  // TODO: Event explanations.
  private constructor() {}

  static getDescription(language, args) {
    return '';
  }
}
