import { ALink } from '@app-modeleditor/components/entry-collection/link/link';
import { InputType } from 'frontend/src/dashboard/template/data-access/mapper';
import { StructuralElement } from '../structural-element';
import { EMenuMode } from './menu-mode.enum';

export class ContentElement extends StructuralElement {
  private showTitle: boolean;
  private color: string;
  protected menuMode: EMenuMode;
  private headline: string;
  private links: ALink[];
  private _class: string;
  private dto: InputType<any>;

  public getLinks(): ALink[] {
    return this.links || [];
  }

  public setLinks(links: ALink[]): this {
    this.links = links;
    return this;
  }

  public get_Class(): string {
    return this._class;
  }

  public set_Class(_class: string): this {
    this._class = _class;
    return this;
  }

  public getHeadline(): string {
    return this.headline;
  }

  public setHeadline(headline: string): this {
    this.headline = headline;
    return this;
  }

  public getName(): string {
    return this.getHeadline() || super.getName();
  }

  public getMenuMode(): EMenuMode {
    return this.menuMode;
  }

  public setMenuMode(menuMode: EMenuMode): this {
    this.menuMode = menuMode;
    return this;
  }

  public getColor(): string {
    return this.color;
  }

  public setColor(color: string): this {
    this.color = color;
    return this;
  }

  /**
   * Backend data of the content element
   */
  public getDto(): InputType<any> {
    return this.dto;
  }

  public setDto(dto: InputType<any>): this {
    this.dto = dto;
    return this;
  }

  /**
   * whether the title is shown or not
   * @returns boolean
   */
  public isShowTitle(): boolean {
    return this.showTitle ? true : false;
  }

  public setShowTitle(showTitle: boolean): this {
    this.showTitle = showTitle;
    return this;
  }
}
