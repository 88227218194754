export enum EGanttActionEvent {
  DRAG_END = 'DRAG_END',
  RESIZE_END = 'RESIZE_END',
  ZOOM_END = 'ZOOM_END',
  EXTERNAL_DROP = 'EXTERNAL_DROP',
  MOVE_END = 'MOVE_END',
  BLOCK_ADD_BEFORE = 'BLOCK_ADD_BEFORE',
  BLOCK_ADD_AFTER = 'BLOCK_ADD_AFTER',
  ON_CLICK = 'ON_CLICK',
}
