import { Observable, Subject, takeUntil } from 'rxjs';
import { GanttCanvasRow } from '../../data-handler/data-structure/data-structure';
import { GanttUtilities } from '../../gantt-utilities/gantt-utilities';
import { NodeProportionsStateConnector } from '../../html-structure/node-proportion-state/node-proportion-state-connector';
import { EGanttScrollContainer } from '../../html-structure/scroll-container.enum';
import { BestGantt } from '../../main';
import { BestGanttPlugIn } from '../gantt-plug-in';
import { RowRasterColorEvent } from './undo-redo/row-raster-color-event';

/**
 * Handles colorizing of every odd row to generate better overview.
 * @keywords plugin, executer, odd, row, color, overview, sibling, row, toggle
 * @plugin row-raster-color
 * @class
 * @constructor
 * @extends BestGanttPlugIn
 *
 * @requires BestGanttPlugIn
 */
export class RowRasterColorExecuter extends BestGanttPlugIn {
  private _canvas: { [id: string]: d3.Selection<SVGGElement, undefined, d3.BaseType, undefined> };
  private _colorizedRowItems: { [id: string]: d3.Selection<SVGRectElement, GanttCanvasRow, d3.BaseType, undefined> };

  public readonly canvasClass = 'gantt-row-colorize';
  private _defaultRowRasterColor = '#b7b7b795';
  private _active = false;

  private _onDestroySubject: Subject<void> = new Subject<void>();

  constructor() {
    super(); // call super-constructor

    this._canvas = {};
    this._colorizedRowItems = {};
  }

  /**
   * Initialization of plugin. Part of plugin lifecycle.
   * Acitvates row colorizment by default.
   * @param ganttDiagram Gantt diagram which will be affected by this plugin.
   */
  public initPlugIn(ganttDiagram: BestGantt): void {
    this.ganttDiagram = ganttDiagram;

    // init canvas
    this._initCanvas();

    // init callbacks
    this.ganttDiagram
      .getVerticalScrollHandler()
      .onScrollVerticalUpdate.pipe(takeUntil(this.onDestroy))
      .subscribe(() => this._buildRowColors());
    this.ganttDiagram
      .getOpenRowHandler()
      .afterShiftRowOpen.pipe(takeUntil(this.onDestroy))
      .subscribe(() => this._buildRowColors());
    this.ganttDiagram
      .getOpenRowHandler()
      .afterShiftRowClosed.pipe(takeUntil(this.onDestroy))
      .subscribe(() => this._buildRowColors());

    this._buildRowColors();
  }

  private _initCanvas(): void {
    for (const scrollContainerId of Object.values(EGanttScrollContainer)) {
      const classBeforeInsert = this.ganttDiagram.getGlobalTimeSpanMarker().getCanvas(scrollContainerId).attr('class');

      this._canvas[scrollContainerId] = this.ganttDiagram
        .getShiftFacade()
        .getCanvasBehindShifts(scrollContainerId)
        .insert('g', '.' + classBeforeInsert)
        .attr('class', this.canvasClass);
    }
  }

  /**
   * Remove of plugin. Part of plugin lifecycle.
   */
  public removePlugIn(): void {
    // callback unsubscribe
    this._onDestroySubject.next();
    this._onDestroySubject.complete();

    // remove rows
    for (const scrollContainerId in this._canvas) {
      this._canvas[scrollContainerId].remove();
      this._canvas[scrollContainerId] = undefined;
    }
  }

  public update(): void {
    this._buildRowColors();
  }

  /**
   * Adds row background colors by adding rects.
   */
  private _buildRowColors(): void {
    // remove all colorized rows
    this._removeAll();

    const scrollContainerIds = [EGanttScrollContainer.DEFAULT];
    if (this.ganttDiagram.getConfig().showStickyRows()) {
      scrollContainerIds.push(EGanttScrollContainer.STICKY_ROWS);
    }

    for (const scrollContainerId of scrollContainerIds) {
      const nodeProportionsState = new NodeProportionsStateConnector(
        this.ganttDiagram.getNodeProportionsState(),
        scrollContainerId
      );

      const dataSet = GanttUtilities.filterDataSetByViewPort(
        this.ganttDiagram.getHTMLStructureBuilder().getShiftContainer(scrollContainerId).node(),
        this.ganttDiagram.getRenderDataHandler().getRenderDataYAxis(scrollContainerId),
        this.ganttDiagram.getRenderDataHandler(),
        0,
        nodeProportionsState.getShiftViewPortProportions().height,
        nodeProportionsState.getScrollTopPosition()
      );

      this._colorizedRowItems[scrollContainerId] = this._canvas[scrollContainerId]
        .selectAll<SVGRectElement, GanttCanvasRow>('dummy')
        .data(dataSet);

      this._colorizedRowItems[scrollContainerId]
        .attr('y', (d) => {
          const y = this.ganttDiagram.getRenderDataHandler().getStateStorage().getYPositionRow(d.id);
          return y;
        })
        .attr('width', this.ganttDiagram.getNodeProportionsState().getShiftViewPortProportions().width)
        .attr('height', (d) => {
          return d.height;
        })
        .attr('fill', (d) => {
          return this.ganttDiagram.getConfig().isDefinedRowBackgroundColorApplied() && d.color
            ? d.color
            : this._defaultRowRasterColor;
        })
        .style('opacity', (d) => {
          if (!d.color || !this.ganttDiagram.getConfig().isDefinedRowBackgroundColorApplied()) {
            return 1;
          } else {
            if (!this._active) {
              return 0.7;
            }
            const index = this.ganttDiagram
              .getDataHandler()
              .getYAxisDataset()
              .findIndex((row) => row.id === d.id);
            return index % 2 == 0 ? 0.7 : 0.5;
          }
        });

      this._colorizedRowItems[scrollContainerId]
        .enter()
        .filter((d: GanttCanvasRow) => {
          const rowNumber = this.ganttDiagram
            .getDataHandler()
            .getYAxisDataset()
            .findIndex((row) => row.id === d.id);
          if (this._active) {
            return !!(
              rowNumber % 2 == 0 ||
              (this.ganttDiagram.getConfig().isDefinedRowBackgroundColorApplied() && rowNumber % 2 != 0 && d.color)
            );
          } else {
            return !!(this.ganttDiagram.getConfig().isDefinedRowBackgroundColorApplied() && d.color);
          }
        })
        .append('rect')
        .attr('y', (d) => {
          const y = this.ganttDiagram.getRenderDataHandler().getStateStorage().getYPositionRow(d.id);
          return y;
        })
        .attr('width', this.ganttDiagram.getNodeProportionsState().getShiftViewPortProportions().width)
        .attr('height', (d) => {
          return d.height;
        })
        .attr('class', 'gantt-row-colorize-item')
        .attr('fill', (d) => {
          return this.ganttDiagram.getConfig().isDefinedRowBackgroundColorApplied() && d.color
            ? d.color
            : this._defaultRowRasterColor;
        })
        .style('opacity', (d) => {
          if (!d.color || !this.ganttDiagram.getConfig().isDefinedRowBackgroundColorApplied()) {
            return 1;
          } else {
            if (!this._active) {
              return 0.7;
            }
            const index = this.ganttDiagram
              .getDataHandler()
              .getYAxisDataset()
              .findIndex((row) => row.id === d.id);
            return index % 2 == 0 ? 0.7 : 0.5;
          }
        });
    }
  }

  /**
   * Removes all row colorize rects.
   */
  private _removeAll(): void {
    for (const scrollContainerId in this._canvas) {
      this._canvas[scrollContainerId].selectAll('.gantt-row-colorize-item').remove();
    }
  }

  public updatePlugInHeight(): void {
    this._buildRowColors();
  }

  //
  // GETTER & SETTER
  //

  /**
   * Toggle function to show/hide row colorizing.
   */
  public setActive(bool: boolean): void {
    this.ganttDiagram
      .getHistory()
      .addNewEvent('activateRowRasterColorizer', new RowRasterColorEvent(), this, this._active /* , bool*/);
    this._active = bool;

    this._buildRowColors();
  }

  public setDefaultRowRasterColor(color: string): void {
    this._defaultRowRasterColor = color;
  }

  //
  // OBSERVABLES
  //

  /**
   * Observable which gets triggered when the instance gets destroyed.
   */
  private get onDestroy(): Observable<void> {
    return this._onDestroySubject.asObservable();
  }
}
