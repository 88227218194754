import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FileProgress } from '@app-modeleditor/components/file-progress/file-progress';
import { DefaultOverlayContainer } from '@app-modeleditor/components/lightbox/overlay/default-overlay-container';
import { TranslateService } from '@ngx-translate/core';
import { takeWhile } from 'rxjs/operators';
import { FileProgressService } from '../file-progress.service';
import { EFileLoadingType, EFileState } from '../file-state.enum';
import { FileData } from '../filte-data';

@Component({
  selector: 'app-progress-dialog',
  templateUrl: './progress-dialog.component.html',
  styleUrls: ['./progress-dialog.component.scss'],
})
export class ProgressDialogComponent extends DefaultOverlayContainer<any> implements OnInit, OnDestroy {
  private alive = false;
  public minimize = false;
  public files: FileData[] = [];
  public fileProgress: FileProgress;
  scrollHeight = '0px';
  itemHeight = 41;
  @Output() close: EventEmitter<any> = new EventEmitter();
  @ViewChild(CdkVirtualScrollViewport) scrollport: CdkVirtualScrollViewport;

  constructor(
    private fileProgressService: FileProgressService,
    private _cd: ChangeDetectorRef,
    private translate: TranslateService
  ) { super(); }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.alive = false;
  }
  private _calcScrollHeight(): void {
    this.scrollHeight = `${(this.files || []).length * this.itemHeight}px`;
  }

  ngOnInit(): void {
    this.alive = true;
    this.fileProgressService
      .onFileProgressChanged()
      .pipe(takeWhile(() => this.alive))
      .subscribe((fileProgress: FileProgress) => {
        this.fileProgress = fileProgress;
        this.files = (Object.values(this.fileProgress.getFiles()) as FileData[]).slice();
        this._calcScrollHeight();
        this.scrollport?.checkViewportSize();
        this._cd.detectChanges();
      });
  }

  handleMinimize(): void {
    this.minimize = !this.minimize;
  }

  handleClose(): void {
    this.getRef()?.dispose();
    this.fileProgressService.setFileProgress(new FileProgress());
  }

  removeFile(file: FileData): void {
    if (file instanceof FileData) {
      if (file.getState() === EFileState.LOADING) {
        return;
      }
      this.removeFielFromProgress(file);
      this.files = this.files.filter((_file) => _file.getId() !== file.getId());
      this._calcScrollHeight();
    }
  }

  removeFielFromProgress(file: FileData): void {
    const fp = this.fileProgressService.getFileProgressObject();
    fp.setNumberOfFilesToTransfer(fp.getNumberOfFilesToTransfer() - 1);

    if (file.getState() !== EFileState.FAILED) {
      fp.setNumberOfBytesToTransfer(fp.getNumberOfBytesToTransfer() - file.getSize());
      fp.setNumberOfTransferedBytes(fp.getNumberOfTransferedBytes() - file.getTransferedBytes());
    }
    if (file.getState() === EFileState.COMPLETED) {
      fp.setNumberOfFilesTransfered(fp.getNumberOfFilesTransfered() - 1);
    }
    fp.removeFile(file.getId());
    this.fileProgressService.setFileProgress(fp);
  }

  getStateIcon(file: any): string {
    if (file instanceof FileData) {
      switch (file.getState()) {
        case EFileState.LOADING:
          return 'loop';
        case EFileState.COMPLETED:
          return 'done';
        case EFileState.FAILED:
          return 'warning';
        default:
          return 'hourglass_empty';
      }
    }
    return 'hourglass_empty';
  }

  isLoading(file: any): boolean {
    if (file instanceof FileData) {
      switch (file.getState()) {
        case EFileState.LOADING:
          return true;
      }
    }
    return false;
  }

  getPercentOfFile(file: any): string {
    if (file instanceof FileData && file.getSize()) {
      return ((file.getTransferedBytes() / file.getSize()) * 100).toFixed(2);
    }
    return '0';
  }

  getTransferedBytes(file: any): number {
    if (file instanceof FileData) {
      return file.getTransferedBytes();
    }
    return 0;
  }
  getLoadingType(file: any): EFileLoadingType {
    if (file instanceof FileData) {
      return file.getLoadingType();
    }
    return file.loadingType;
  }

  protected getFileName(file: any): string {
    if (file instanceof FileData) {
      return file.isZippedContent() ? this.translate.instant('FILE.GENERAL.uploadFiles') : file.getName();
    }
    return file.name;
  }
}
