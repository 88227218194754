import { ConfigService } from '@core/config/config.service';
import { GanttSplitOverlappingShifts } from '@gantt/lib/best_gantt/script/plug-ins/split-overlapping-shifts/split-overlapping-shifts-executer';
import { EGanttInstance } from '@gantt/public-api';
import { GanttLibService } from 'frontend/src/dashboard/gantt/gantt/gantt-lib.service';
import { SaxMsBestGanttActiveSubmenuEntryElementSetting } from 'frontend/src/dashboard/gantt/gantt/saxms-best-gantt.settings';
import { GeneralGanttActionHandler } from 'frontend/src/dashboard/gantt/general/action-handling/action-handler';
import { GanttResponseHandler } from 'frontend/src/dashboard/gantt/general/response/response-handler';
import { Observable, of } from 'rxjs';
import { GanttTemplateDataService } from '../../../template-data/gantt-template-data.service';
import { ExternalGanttPlugin } from '../../external-plugin';
import { GanttPluginHandlerService } from '../../gantt-plugin-handler.service';
import { GanttOverlappingShiftsPlugInMapper, IPrioritisedAttribute } from './overlapping-shifts.mapper';
import { GanttOverlappingShiftsPlugInUpdate } from './responses/overlap-update-new-data.response';

const GanttPlugInOverlappingShiftsHandler = 'gantt-plugin-overlapping-shifts-handler';

/**
 * PlugIn-Wrapper for GanttSplitOverlappingShifts.
 */
export class GanttOverlappingShiftsPlugIn extends ExternalGanttPlugin {
  constructor(
    protected _ganttPluginHandlerService: GanttPluginHandlerService,
    protected _ganttLibService: GanttLibService,
    protected _actionHandler: GeneralGanttActionHandler,
    private _responseHandler: GanttResponseHandler,
    private _configApi: ConfigService,
    private _ganttTemplateDataService: GanttTemplateDataService
  ) {
    super(_ganttPluginHandlerService, _ganttLibService, _actionHandler);
  }

  public onInit(templateData: any, responseData: any) {
    this.addPlugIn(
      GanttPlugInOverlappingShiftsHandler,
      this._ganttLibService.ganttInstanceService.getInstance(EGanttInstance.SPLIT_OVERLAPPING_SHIFTS)
    );
    this._responseHandler.addResponse(
      GanttOverlappingShiftsPlugInUpdate,
      this.getPlugInById(GanttPlugInOverlappingShiftsHandler)
    );
    this.addMatchingStrategy(templateData);
    if (templateData.blockOverlap) {
      this.setAllRowsToOverlap();
    }
    if (this._configApi.access().templates.Gantt.globalOverlapBtn) {
      this.getPlugInById(GanttPlugInOverlappingShiftsHandler).showButtonInWhiteArea();
    }
  }

  public onDestroy(): void {}

  /**
   * Provides which sorting strategy should be used by backend configuration.
   * @param templateData Gantt template data.
   */
  private addMatchingStrategy(templateData: any): void {
    if (!templateData.blockSortings) return;
    const ganttLibPlugIn: GanttSplitOverlappingShifts = this.getPlugInById(GanttPlugInOverlappingShiftsHandler);
    const ganttEntries = this.gantt.getDataHandler().getOriginDataset().ganttEntries;
    const priorityList: IPrioritisedAttribute[] = GanttOverlappingShiftsPlugInMapper.getBlockSortings(
      ganttEntries,
      templateData.blockSortings,
      this._ganttLibService.ganttInstanceService,
      ['additionalData']
    );
    for (const attribute of priorityList) {
      ganttLibPlugIn.addPrioritisedAttribute(attribute);
    }
  }

  public reInitMatchingStrategy(templateData: any): void {
    this.getPlugInById(GanttPlugInOverlappingShiftsHandler).clearPrioritisedAttributes();
    this.addMatchingStrategy(templateData);
  }

  public changeStrategy(strategy: 'default' | 'prioritized' | 'sortByAttribute'): void {
    const ganttLibPlugIn: GanttSplitOverlappingShifts = this.getPlugInById(GanttPlugInOverlappingShiftsHandler);
    const wasActiveBefore = ganttLibPlugIn.isSplitActive;

    if (ganttLibPlugIn.strategy.strategyType === strategy) {
      return;
    } // strategy is already set
    if (wasActiveBefore) {
      this.resetSplitOverlappingShifts();
    }

    switch (strategy) {
      case 'default':
        ganttLibPlugIn.strategy = this._ganttLibService.ganttInstanceService.getInstance(
          EGanttInstance.SPLIT_OVERLAPPING_SHIFTS_DEFAULT_STRATEGY
        );
        break;
      case 'prioritized':
        ganttLibPlugIn.strategy = this._ganttLibService.ganttInstanceService.getInstance(
          EGanttInstance.SPLIT_OVERLAPPING_SHIFTS_PRIORITIZED_STRATEGY
        );
        break;
      case 'sortByAttribute':
        ganttLibPlugIn.strategy = this._ganttLibService.ganttInstanceService.getInstance(
          EGanttInstance.SPLIT_OVERLAPPING_SHIFTS_BY_ATTRIBUTE_STRATEGY
        );
        break;
    }

    if (wasActiveBefore) {
      this.splitOverlappingShifts(true);
    }
  }

  /**
   * Sets the attribute by which the sortByAttribute strategy should be sorted.
   */
  public setSortAttribute(attribute: string): void {
    const ganttLibPlugIn: GanttSplitOverlappingShifts = this.getPlugInById(GanttPlugInOverlappingShiftsHandler);
    const attributeMapping = this._ganttTemplateDataService.getTemplateData().getAttributeMapping();
    const attributeKey = Object.keys(attributeMapping).find((key) => attributeMapping[key].localization === attribute);

    ganttLibPlugIn.sortAttribute = parseInt(attributeKey);
  }

  /**
   * Allows shift overlapping for all rows.
   */
  private setAllRowsToOverlap(): void {
    // get all gantt rows
    this._ganttLibService.ganttInstanceService
      .getInstance(EGanttInstance.DATA_MANIPULATOR)
      .iterateOverDataSet(this.gantt.getDataHandler().getOriginDataset().ganttEntries, {
        extractIds: (rowData) => {
          this.getPlugInById(GanttPlugInOverlappingShiftsHandler).addNotAffectedRow(rowData.id);
        },
      });
  }

  /**
   * Splits all overlapping shifts inside the gantt by initially slected strategy.
   * @param updateGantt If true, gantt will be updated after split.
   */
  public splitOverlappingShifts(updateGantt?: boolean): void {
    this.getPlugInById(GanttPlugInOverlappingShiftsHandler).splitOverlappingShifts(null, updateGantt);
  }

  /**
   * Executes shift split at selected row.
   * @param rowIds Affected rows by rowIds.
   * @param updateGantt If true, gantt will be updated after split.
   */
  public splitOverlappingShiftsByRowIds(rowIds: string[], updateGantt?: boolean): void {
    this.getPlugInById(GanttPlugInOverlappingShiftsHandler).splitOverlappingShiftByRowIds(rowIds, updateGantt);
  }

  /**
   * Reverts splitting and allows overlapping shfits for all rows.
   * @param updateGantt If true, gantt will be updated after revert splitting.
   */
  public resetSplitOverlappingShifts(updateGantt?: boolean): void {
    this.getPlugInById(GanttPlugInOverlappingShiftsHandler)?.resetSplitOverlappingShifts(updateGantt);
  }

  /**
   * Reverts splitting and allows overlapping shfits for given rows.
   * @param rowIds Affected rows by rowIds.
   * @param updateGantt If true, gantt will be updated after revert splitting.
   */
  public resetSplitOverlappingShiftsByRowIds(rowIds: string[], updateGantt?: boolean): OverlappingShiftGroupData[] {
    return this.getPlugInById(GanttPlugInOverlappingShiftsHandler).resetSplitOverlappingShiftsByRowIds(
      rowIds,
      updateGantt
    );
  }

  /**
   * Reverts and splits all shfits again.
   * Does a gantt update afterwards.
   */
  public resplitOverlappingShifts(): void {
    if (!this.getPlugInById(GanttPlugInOverlappingShiftsHandler)?.isSplitActive) return;
    this.resetSplitOverlappingShifts(false);
    this.splitOverlappingShifts(true);
  }

  /**
   * (De-)Select gantt rows which are affected by shift overlapping.
   * @param rowId RowId of row to toggle.
   * @param updatePlugIn If true, overlapping shift plugin will be updated. Should be false, if toggleNotAffectedRows will called initially.
   */
  public toggleNotAffectedRows(rowId: string, updatePlugIn: boolean): void {
    if (updatePlugIn) this.resetSplitOverlappingShifts(false);
    this.getPlugInById(GanttPlugInOverlappingShiftsHandler).toggleNotAffectedRows(rowId);
    if (updatePlugIn) this.splitOverlappingShifts(true);
  }

  /**
   * Returns true if row is affected of splitting.
   * @param {string} rowId row id
   * @returns {boolean}
   */
  public isRowAffected(rowId: string): boolean {
    return this.getPlugInById(GanttPlugInOverlappingShiftsHandler).isRowAffected(rowId);
  }

  public onAction(action: any) {}

  public executeAction(action: any): Observable<any> {
    return of(null);
  }

  public injectSettings(submenuElements: SaxMsBestGanttActiveSubmenuEntryElementSetting[]): void {}
}

export interface OverlappingShiftGroupData {
  originRowId: string;
  groupedRowIds: string[];
}
