import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { TemplateButtonModule } from '@app-modeleditor/components/button/button.module';
import { LogComponent } from './log.component';

@NgModule({
  declarations: [LogComponent],
  imports: [CommonModule, MatToolbarModule, MatListModule, TemplateButtonModule],
  exports: [LogComponent],
})
export class LogModule {}
