import * as d3 from 'd3';
import { GanttConfig } from '../config/gantt-config';

/**
 * Builder for tooltips inside gantt.
 * Not necessarily only for shifts.
 * @keywords tooltip, builder, executer, shift
 * @class
 * @constructor
 * @param {HTMLNode} shiftParentNode Defaults to html body if not set.
 * @param {GanttConfig} config Gantt config.
 */
export class TooltipBuilder {
  shiftParentNode: HTMLElement;
  allTooltips: d3.Selection<any, any, null, undefined>[];
  eventID: NodeJS.Timeout;
  config: GanttConfig;
  show: boolean;
  tooltipMargin: number;
  tooltipPadding: number;
  tooltipMaxWidth: number;
  isMouseOverTooltip: boolean;

  constructor(shiftParentNode, config) {
    this.shiftParentNode = shiftParentNode;
    this.allTooltips = [];
    this.eventID = null;
    this.config = config;
    this.tooltipMargin = 10;
    this.tooltipPadding = 10;
    this.tooltipMaxWidth = 550;
    this.show = true;
    this.isMouseOverTooltip = false;
  }

  /**
   * Build tooltip at given position.
   * @keywords add, tooltip, build, generate, show, description, box
   * @param {number} mouseXCanvas Horizontal mouse position inside canvas.
   * @param {number} mouseYCanvas Vertical mouse position inside canvas.
   * @param {string} htmlContent Content of tooltip which will be rendered as HTML.
   * @param {HTMLNode} [parentNode] Parent of tooltip. Defaults to given parent node if not set.
   */
  addTooltip(mouseXCanvas, mouseYCanvas, htmlContent, parentNode, noPointerEvents) {
    const s = this;
    clearTimeout(this.eventID);
    s.removeAllTooltips();

    if (!s.show) return;
    if (!parentNode) parentNode = s.shiftParentNode;
    const screenNode = d3.select<HTMLBodyElement, any>('body').node();
    const screenHeight = screenNode.clientHeight;
    const screenWidth = screenNode.clientWidth;
    const tooltip = s._buildTooltipElement(parentNode, htmlContent, screenWidth, screenHeight, noPointerEvents);

    tooltip.node().addEventListener('mouseover', () => {
      // use default event listener here, d3 does not support it
      this.isMouseOverTooltip = true;
    });

    tooltip
      .on('mouseleave', () => {
        this.isMouseOverTooltip = false;
        s.removeAllTooltips();
      })
      .on('click', () => {
        s.removeAllTooltips();
      });

    this.eventID = setTimeout(() => {
      const tooltipWidth = tooltip.node().offsetWidth;
      const tooltipHeight = tooltip.node().offsetHeight;
      const position = s._getTooltipPosition(
        mouseXCanvas,
        mouseYCanvas,
        tooltipWidth,
        tooltipHeight,
        screenWidth,
        screenHeight
      );
      tooltip
        .style('left', position.x + 'px')
        .style('top', position.y + 'px')
        .attr('class', 'gantt-tooltip-margin gantt-tooltip-start');

      const tooltipContentHeight = tooltip.select<any>('.gantt-tooltip').node().scrollHeight;
      const rawTooltipHeight = tooltipHeight - 2 * s.tooltipPadding - 2; // without padding and border

      if (!s.config.isTooltipScrollable() && rawTooltipHeight < tooltipContentHeight) {
        s._buildMoreContentIndicator(tooltip);
      }
    }, this.config.tooltipDelay());
  }

  private _buildMoreContentIndicator(tooltip: d3.Selection<HTMLDivElement, unknown, null, undefined>): void {
    const s = this;
    tooltip
      .append('div')
      .attr('class', 'gantt-tooltip-more-content-indicator')
      .style('width', `calc(100% - ${2 * s.tooltipPadding + s.tooltipMargin}px)`)
      .style('margin', `${s.tooltipMargin}px`)
      .text('...');
  }

  /**
   * Calculates correct tooltip direction if tooltip would overlap gantt.
   * @private
   * @param {number} x Horizontal origin position of tooltip.
   * @param {number} y Vertical origin position of tooltip.
   * @param {number} width Width of tooltip.
   * @param {number} height Height of tooltip.
   * @param {number} parentWidth
   * @param {number} parentHeight
   * @return {TooltipDirectionCorrector} Correction coordinates for tooltip.
   */
  private _getTooltipPosition(x, y, width, height, parentWidth, parentHeight) {
    const s = this;
    const attributes = new TooltipDirectionCorrector();

    // horizontal
    if (x + width > parentWidth) {
      // not enough space to the right check
      if (x - width < 0) {
        // not enough space to the left check
        attributes.x = 0; // not enough space for tooltip width
      } else {
        attributes.x = x - width; // tooltip to the left of mouse
      }
    } else {
      attributes.x = x; // tooltip to the right of mouse
    }

    // vertical direction
    const halfTooltipHeight = height / 2;
    const yCenter = y - halfTooltipHeight;
    if (height > parentHeight || yCenter < 0) {
      // not enough space for tooltip height or above mouse
      attributes.y = 0;
    } else if (yCenter + height > parentHeight) {
      // not enough space for tooltip height or below mouse
      attributes.y = parentHeight - height;
    } else {
      attributes.y = yCenter;
    }

    return attributes;
  }

  /**
   * Renders tooltip HTML elements inside given node.
   * @private
   * @param {HTMLNode} parentNode Parent of tooltip node.
   * @param {any} htmlContent
   * @param {number} screenWidth
   * @param {number} screenHeight
   * @return {Selection} D3 selection of tooltip elements.
   */
  private _buildTooltipElement(parentNode, htmlContent, screenWidth, screenHeight, noPointerEvents) {
    const s = this;

    const tooltip = d3
      .select(parentNode)
      .append('div')
      .attr('class', 'gantt-tooltip-margin')
      .style('pointer-events', s.config.isTooltipScrollable() && !noPointerEvents ? 'auto' : 'none')
      .style('padding', s.tooltipMargin + 'px')
      .style('max-width', Math.min(screenWidth - 2 * (s.tooltipMargin + s.tooltipPadding), s.tooltipMaxWidth) + 'px')
      .style('max-height', screenHeight - 2 * (s.tooltipMargin + s.tooltipPadding) + 'px')
      .style('left', '-10000px')
      .style('top', '-10000px');

    tooltip
      .append('div')
      .attr('class', 'gantt-tooltip')
      .style('width', 'auto')
      .style('padding', s.tooltipPadding + 'px')
      .style('background-color', s.config.tooltip.backgroundColor)
      .style('border', () => `1px solid ${s.config.tooltip.backgroundColor}`)
      .style('font-size', () => `${s.config.tooltipFontSize()}px`)
      .style('line-height', () => `${s.config.tooltipFontSize() + 6}px`)
      .style('box-shadow', () => `2px 2px 2px ${s.config.tooltip.backgroundColor}`)
      // .style("word-break", "break-word")
      .style('overflow-y', s.config.isTooltipScrollable() ? 'auto' : 'hidden')
      .html(htmlContent);

    s.allTooltips.push(tooltip);
    return tooltip;
  }

  /**
   * Function wrapper to render tooltip to body of current HTML document.
   * @param {number} mouseXBody Horizontal mouse position inside body.
   * @param {number} mouseYBody Vertical mouse position inside body.
   * @param {string} htmlContent Content of tooltip which will be rendered as HTML.
   * @param {HTMLNode} [parentNode = null]
   */
  addTooltipToHTMLBody(mouseXBody, mouseYBody, htmlContent, parentNode = null, noPointerEvents = false) {
    const s = this;
    if (!s.show) {
      return;
    }
    s.addTooltip(mouseXBody, mouseYBody, htmlContent, parentNode, noPointerEvents);
  }

  /**
   * Removes all tooltip elements and all tooltip data.
   * @keywords remove, delete, clear, empty, tooltips, description, box
   */
  removeAllTooltips() {
    const s = this;
    clearTimeout(this.eventID);
    if (!s.allTooltips.length || s.isMouseOverTooltip) {
      return;
    }

    const toBeRemovedTooltips = s.allTooltips;
    s.allTooltips = [];

    const remove = (_) => {
      toBeRemovedTooltips.forEach((tooltip) => tooltip.remove());
    };

    setTimeout(remove, 100);

    // adding gantt-tooltip-end class for css fade-out animation
    toBeRemovedTooltips.forEach((tooltip) => tooltip.attr('class', 'gantt-tooltip-margin gantt-tooltip-end'));
  }

  //
  // RESTRICTIONS
  //
  /**
   * Decides if tooltips should be rendered.
   * @keywords restriction, show, hide, display, visibility, tooltips
   * @param {boolean} showTooltips Show tooltips or not.
   */
  showTooltips(showTooltips) {
    const s = this;
    s.show = showTooltips;
  }
}

/**
 * Class which holds x and y coordinate.
 * @keywords data, class, tooltip, direction, position, tupel
 * @class
 * @constructor
 */
export class TooltipDirectionCorrector {
  x: number;
  y: number;

  constructor() {
    this.x = 0;
    this.y = 0;
  }
}
