<mat-list>
  <mat-list-item class="contentText">
    <span>{{ '@daily-color@' | translate }}</span>
    <app-color-picker
      [color]="settings.defaultRowColor"
      (changeEvent)="setDailyColor($event)"
      [resetColor]="'#dcdcdc'"
      [disableAlpha]="false"
    >
    </app-color-picker>
  </mat-list-item>
  <mat-divider></mat-divider>
  <mat-list-item class="contentText">
    {{ '@show-weekends@' | translate }}
    <mat-slide-toggle (change)="handleWeekendVisibility($event)" [checked]="settings.showWeekends"></mat-slide-toggle>
  </mat-list-item>
  <ng-container *ngIf="settings.showWeekends">
    <mat-divider></mat-divider>
    <mat-list-item class="contentText firstLevelList">
      <span class="indented">{{ '@weekend-color@' | translate }}</span>
      <app-color-picker
        [color]="settings.weekendColor"
        (changeEvent)="setWeekendColor($event)"
        [resetColor]="'#adadadbb'"
        [disableAlpha]="false"
      >
      </app-color-picker>
    </mat-list-item>
  </ng-container>
  <mat-divider></mat-divider>
  <mat-list-item class="contentText">
    {{ '@show-holidays@' | translate }}
    <mat-slide-toggle (change)="handleHolidayVisibility($event)" [checked]="settings.calendar.showHolidays">
    </mat-slide-toggle>
  </mat-list-item>
  <ng-container *ngIf="settings.calendar.showHolidays">
    <mat-divider></mat-divider>
    <mat-list-item class="contentText firstLevelList">
      <span class="indented">{{ '@holiday-color@' | translate }}</span>
      <app-color-picker
        [color]="settings.holidayColor"
        (changeEvent)="setHolidayColor($event)"
        [resetColor]="'#c4e4ff80'"
        [disableAlpha]="false"
      >
      </app-color-picker>
    </mat-list-item>
    <mat-divider></mat-divider>
    <mat-list-item class="contentText firstLevelList">
      <span class="indented">{{ '@choose-federal-state-holidays@' | translate }}</span>
      <mat-form-field class="select" appearance="standard">
        <mat-select
          [(value)]="selectedHolidayOption"
          (selectionChange)="onHolidaySelectionChange($event)"
          [placeholder]="'GANTT.FILTER.nothingSelected' | translate"
        >
          <mat-option *ngFor="let holidayOption of availableFederalStatesOptions" [value]="holidayOption.value">
            {{ holidayOption.viewValue }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </mat-list-item>
  </ng-container>
  <mat-divider></mat-divider>
  <mat-list-item class="contentText">
    {{ '@show-time-intervals@' | translate }}
    <mat-slide-toggle (change)="handleTimeIntervalVisibility($event)" [checked]="settings.calendar.showTimeIntervals">
    </mat-slide-toggle>
  </mat-list-item>
  <mat-divider></mat-divider>
  <mat-list-item class="contentText">
    {{ 'GANTT.CALENDAR.calendarVisible' | translate }}
    <mat-slide-toggle (change)="handleCalendarVisibility($event)" [checked]="settings.xaxisVisible"></mat-slide-toggle>
  </mat-list-item>
  <ng-container *ngIf="settings.xaxisVisible">
    <mat-divider></mat-divider>
    <mat-list-item class="contentText firstLevelList">
      <span class="indented">{{ 'GANTT.CALENDAR.calendarShiftArea' | translate }}</span>
      <mat-form-field class="select" appearance="standard">
        <mat-select [(value)]="selectedCalendarPosition" (selectionChange)="onCalendarPositionChange($event.value)">
          <mat-option [value]="saxMsBestGanttXAxisPosition.TOP">
            {{ 'GANTT.CALENDAR.above' | translate }}
          </mat-option>
          <mat-option [value]="saxMsBestGanttXAxisPosition.BOTTOM">
            {{ 'GANTT.CALENDAR.below' | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </mat-list-item>
    <mat-divider></mat-divider>
    <mat-list-item class="contentText firstLevelList">
      <span class="indented">{{ 'GANTT.TIME_MARKER.markerFormat' | translate }}</span>
      <mat-form-field class="select" appearance="standard">
        <mat-select [(value)]="selectedMarkerFormatOption" (selectionChange)="onMarkerFormatSelectionChange($event)">
          <mat-option *ngFor="let markerFormatOption of markerFormatOptions" [value]="markerFormatOption.value">
            {{ markerFormatOption.viewValue | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </mat-list-item>
    <mat-divider></mat-divider>
    <mat-list-item class="contentText firstLevelList">
      <span class="indented">{{ '@show-weekdays@' | translate }}</span>
      <mat-slide-toggle (change)="handleCalendarWeekdayVisibility($event)" [checked]="settings.xAxisShowWeekdays">
      </mat-slide-toggle>
    </mat-list-item>
  </ng-container>
  <mat-divider></mat-divider>
  <mat-list-item class="contentText">
    <span>{{ 'GANTT.ELEMENTS.zoomAndScroll' | translate }}</span>
    <mat-form-field class="select" appearance="standard">
      <mat-select [(value)]="selectedTimeRasterModeOption" (selectionChange)="onTimeRasterModeSelectionChange($event)">
        <mat-option *ngFor="let timeRasterModeOption of timeRasterModeOptions" [value]="timeRasterModeOption.value">
          {{ timeRasterModeOption.viewValue | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </mat-list-item>
  <ng-container *ngIf="selectedTimeRasterModeOption === timeRasterMode.STATIC">
    <mat-divider></mat-divider>
    <mat-list-item class="contentText firstLevelList">
      <span class="indented">{{ '@rasterization@' | translate }}</span>
      <mat-form-field class="select" appearance="standard">
        <mat-select
          [(value)]="selectedTimeRasterStepOption"
          (selectionChange)="onTimeRasterStepSelectionChange($event)"
        >
          <mat-option *ngFor="let timeRasterStepOption of timeRasterStepOptions" [value]="timeRasterStepOption.value">
            {{ timeRasterStepOption.viewValue | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </mat-list-item>
    <ng-container *ngIf="selectedTimeRasterStepOption === timeRasterStep.CUSTOM">
      <mat-divider></mat-divider>
      <mat-list-item class="contentText secondLevelList">
        <span class="indented"
          ><span class="indented">{{ '@reference-point@' | translate }}</span></span
        >
        <mat-form-field class="select" appearance="standard">
          <input
            (dateChange)="onRefPointChange($event)"
            (dateInput)="onRefPointChange($event)"
            matInput
            [matDatepicker]="picker"
            [formControl]="refDate"
          />
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </mat-list-item>
      <mat-divider></mat-divider>
      <mat-list-item class="contentText secondLevelList">
        <span class="indented"
          ><span class="indented">{{ '@step-width@' | translate }}</span></span
        >
        <div class="durPicker">
          <template-duration-picker
            id="duration-picker"
            [displayed]="durationPickerValues"
            [millis]="settings.zoomGridWidth"
            (millisChange)="onStepWidthChange($event)"
          >
          </template-duration-picker>
        </div>
      </mat-list-item>
    </ng-container>
  </ng-container>
</mat-list>
