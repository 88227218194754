import * as d3 from 'd3';
import { Observable, Subject } from 'rxjs';
import { GanttCallBackStackExecuter } from '../../callback-tools/callback-stack-executer';
import { YAxisDataFinder } from '../../data-handler/data-finder/yaxis-data-finder';
import { GanttDataShift } from '../../data-handler/data-structure/data-structure';
import { ShiftDataSorting } from '../../data-handler/data-tools/data-sorting';
import { GanttUtilities } from '../../gantt-utilities/gantt-utilities';
import { GanttYAxisHeadButtonData } from '../../x-axis/head-y-axis/buttons/head-y-axis-button';
import { BestGanttPlugIn } from '../gantt-plug-in';
import { NotAffectedRowsIndicator } from './not-affected-rows-indicator';
import { GanttSplitOverlappingShiftsParentShiftHandler } from './parent-shift-handler';
import { BaseOverlappingStrategy } from './strategies/base';
import { GanttSplitOverlappingShiftsDefaultStrategy } from './strategies/default';
import { GanttSplitOverlappingShiftsEvent } from './undo-redo/split-overlapping-shifts-event';

/**
 * @keywords plugin, executer
 * @class
 * @constructor
 * @extends BestGanttPlugIn
 *
 * @property {any} ruleSet
 *
 * @requires BestGanttPlugIn
 */
export class GanttSplitOverlappingShifts extends BestGanttPlugIn {
  shiftGroups: GanttSplitOverlappingShiftGroup[];
  active: boolean;
  ruleSet: RuleSet;
  callbacks: {
    afterSplit: { [id: string]: () => void };
    overlapCompareMethods: { callback: { [id: string]: (overlapIndicator: IOverlappingShiftsIndicator) => void } }[];
  };
  splitCallbackCounter: number;
  notAffectedRowsIndicator: NotAffectedRowsIndicator;
  strategy: BaseOverlappingStrategy;
  sortAttribute: number;
  private _parentShiftHandler = new GanttSplitOverlappingShiftsParentShiftHandler(this);
  private isReset = true;

  private _onDestroySubject: Subject<void> = new Subject<void>();

  constructor() {
    super();

    /**
     * @type {BestGantt}
     */
    this.ganttDiagram = null;
    this.shiftGroups = [];
    this.active = true;

    this.ruleSet = {
      prioritiseAttributes: [],
      notAffectedRows: [],
    };

    this.callbacks = {
      afterSplit: {},
      overlapCompareMethods: [], // not a mistake, these callbacks are ordered
    };

    this.splitCallbackCounter = 0;

    this.notAffectedRowsIndicator = null;

    this.strategy = new GanttSplitOverlappingShiftsDefaultStrategy();
  }

  /**
   * @override
   */
  initPlugIn(ganttDiagram) {
    const s = this;
    s.ganttDiagram = ganttDiagram;
    s.notAffectedRowsIndicator = new NotAffectedRowsIndicator(s.ganttDiagram, this.ruleSet.notAffectedRows, this);

    s.ganttDiagram.getTextOverlay().addSplittingShiftsPlugin(s);
  }

  /**
   * Puts overlapping shifts into new rows.
   * Main function of this plugin.
   * @param {string[]} [rowIds] Row id to split. Defaults to all gantt entries.
   * @param {boolean} [updateAfterSplit] If set to false, update of gantt will not be executed.
   */
  splitOverlappingShifts(rowIds, updateAfterSplit = true) {
    const s = this;
    if (!s.active) {
      if (updateAfterSplit) s.ganttDiagram.update(); // execute update anyway
      return;
    }
    if (!s.isReset) {
      // If the lines are already split  -> reset first
      if (updateAfterSplit) s.ganttDiagram.update(); // execute update anyway
      return;
    }

    s.isReset = false;
    s.ganttDiagram.getHistory().addNewEvent('splitOverlappingShifts', new GanttSplitOverlappingShiftsEvent(), this);
    s.parentShiftHandler.subscribeToShiftHeightManipulation();
    // if there are no priorities use default strategy
    const prevStrategy = s.strategy;
    if (!s.ruleSet.prioritiseAttributes || s.ruleSet.prioritiseAttributes.length === 0)
      s.strategy = new GanttSplitOverlappingShiftsDefaultStrategy();
    s.strategy.splitOverlappingShifts(s, rowIds);
    s.strategy = prevStrategy;

    const maxAmountARowIsSplit = d3.max(s.shiftGroups.map((group) => group.groupedRowIds.length));
    s.ganttDiagram
      .getYAxisFacade()
      .getYAxisBuilder()
      .setMaxRowsForResource(maxAmountARowIsSplit || 5);
    ShiftDataSorting.sortOriginShifts(s.ganttDiagram.getDataHandler().getOriginDataset().ganttEntries);

    s.ganttDiagram.getDataHandler().initCanvasYAxisData();
    s._transferAllRowRestrictionsToMemberRows();
    if (updateAfterSplit) {
      s.ganttDiagram.update();
    } else {
      s.ganttDiagram.getDataHandler().initCanvasShiftData();
    }

    GanttCallBackStackExecuter.execute(s.callbacks.afterSplit);
  }

  /**
   * Puts overlapping shifts into new rows.
   * Main function of this plugin.
   * @param {string[]} rowIds Start point of calculation. Defaults to all gantt entries.
   * @param {boolean} [updateAfterSplit] If set to false, update of gantt will not be executed.
   */
  splitOverlappingShiftByRowIds(rowIds: string[], updateAfterSplit = true) {
    const s = this;
    let isHardReset = false;
    if (!s.active) {
      return;
    }
    if (!s.isReset) {
      s.resetSplitOverlappingShifts();
      isHardReset = true;
    }

    if (rowIds?.length && !isHardReset) {
      s.strategy.splitOverlappingShifts(s, rowIds);
      s.ganttDiagram.getDataHandler().initCanvasYAxisData();
      s._transferAllRowRestrictionsToMemberRows();
      ShiftDataSorting.sortOriginShifts(s.ganttDiagram.getDataHandler().getOriginDataset().ganttEntries);
    } else {
      if (isHardReset) s.splitOverlappingShifts(null, updateAfterSplit);
    }

    if (updateAfterSplit) {
      s.ganttDiagram.update();
    } else {
      s.ganttDiagram.getDataHandler().initCanvasShiftData();
    }
  }

  showButtonInWhiteArea() {
    const s = this;

    const button = new GanttYAxisHeadButtonData('overlapping-shifts-btn');
    button.addState(
      'saxms-icon md-24 collapse_all_rows-btn',
      () => {
        s.ruleSet.notAffectedRows.length = 0; // reference must remain
        s.resetSplitOverlappingShifts(false);
        s.active = false;
        s.ganttDiagram.update();
      },
      'collapse_rows',
      'Block-Überlappung aus'
    );
    button.addState(
      'saxms-icon md-24 collapse_all_rows-btn',
      () => {
        s.ruleSet.notAffectedRows.length = 0; // reference must remain
        s.active = true;
        s.splitOverlappingShifts(null, false);
        s.ganttDiagram.update();
      },
      'expand_rows',
      'Block-Überlappung ein'
    );

    s.ganttDiagram.getYAxisHeadBuilder().addButton(button);
  }

  /**
   * Function sets row restrictions to member rows. The member rows will be registerd as follower of origin row in translation area limiter.
   */
  _transferAllRowRestrictionsToMemberRows() {
    for (let i = 0; i < this.shiftGroups.length; i++) {
      const originRowId = this.shiftGroups[i].originRowId;
      for (let j = 0; j < this.shiftGroups[i].groupedRowIds.length; j++) {
        const memberRowId = this.shiftGroups[i].groupedRowIds[j];
        this.ganttDiagram
          .getShiftTranslator()
          .translationAreaLimiter.followTranslationRestrictionsOfRow(originRowId, memberRowId);
      }
    }
  }

  /**
   * Returns true if row is affected of splitting.
   * @param {string} id row id
   * @returns {boolean}
   */
  isRowAffected(id) {
    return !this.ruleSet.notAffectedRows.includes(id);
  }

  /**
   * Checks if the specified shift overlaps with any shift in the given array.
   * @param {GanttDataShift} shiftToCheck Shift to check the overlapping for.
   * @param {GanttDataShift[]} shiftList Shift list which has been sorted by start date.
   * @param {Map<string, GanttDataShift[]>} [shiftChildMap=null] Map which contains all child shifts in shiftList ordered by their parent shift id.
   * @return {GanttDataShift} First found shift that overlaps with the given shift (null if there is no overlapping).
   */
  public overlapsInsideShiftList(
    shiftToCheck: GanttDataShift,
    shiftList: GanttDataShift[],
    shiftChildMap: Map<string, GanttDataShift[]> = null
  ): GanttDataShift {
    if (!shiftList) return null;
    for (const shift of shiftList) {
      if (this.overlapsShift(shift, shiftToCheck, shiftChildMap?.get(shift.id), shiftChildMap?.get(shiftToCheck.id)))
        return shift;
    }
    return null;
  }

  /**
   * Checks if 2 shifts are overlapping each other.
   * @param {GanttDataShift} shift1 First shift to check.
   * @param {GanttDataShift} shift2 Second shift to check.
   * @param {GanttDataShift[]} [shiftChildren1=null] Child shifts of first shift (will be checked for overlapping if first and second shift are not overlapping each other).
   * @param {GanttDataShift[]} [shiftChildren2=null] Child shifts of second shift (will be checked for overlapping if first and second shift are not overlapping each other).
   * @param {boolean} [executeSplitCallbacks=true] If true, all registered overlap compare methods will be executed when both shifts are not overlapping each other.
   * @return {boolean}
   */
  public overlapsShift(
    shift1: GanttDataShift,
    shift2: GanttDataShift,
    shiftChildren1: GanttDataShift[] = null,
    shiftChildren2: GanttDataShift[] = null,
    executeSplitCallbacks = true
  ): boolean {
    if (!(shift1 && shift2)) return false;
    let minShift = shift1,
      maxShift = shift2;

    if (shift1.timePointStart.getTime() > shift2.timePointStart.getTime()) {
      minShift = shift2;
      maxShift = shift1;
    }

    let overlap = minShift.timePointEnd.getTime() > maxShift.timePointStart.getTime();

    // if shifts are overlapping return true, if not check for other registered overlap reasons
    if (overlap) return overlap;

    // if childs shifts are specified -> check if shifts overlap child shifts
    if (shiftChildren1?.length) {
      for (const shiftChild1 of shiftChildren1) {
        overlap = this.overlapsShift(shift2, shiftChild1, null, null, false);
        if (overlap) return overlap;
      }
    }
    if (shiftChildren2?.length) {
      for (const shiftChild2 of shiftChildren2) {
        overlap = this.overlapsShift(shift1, shiftChild2, null, null, false);
        if (overlap) return overlap;
      }
    }

    if (executeSplitCallbacks && this.splitCallbackCounter) {
      const zoom = this.ganttDiagram.getXAxisBuilder().getLastZoomEvent();
      const scale = this.ganttDiagram.getXAxisBuilder().getGlobalScale();

      const overlapIndicator: IOverlappingShiftsIndicator = {
        overlapping: false,
        minShift: minShift,
        maxShift: maxShift,
        zoom: zoom,
        scale: scale,
      };

      GanttCallBackStackExecuter.executeArray(this.callbacks.overlapCompareMethods, overlapIndicator);

      return overlapIndicator.overlapping;
    }
  }

  /**
   * @param {boolean} [updateAfterReset] If set to false, update of gantt will not be executed.
   */
  resetSplitOverlappingShifts(updateAfterReset = true) {
    const s = this;
    if (!s.active) {
      return;
    }

    s.ganttDiagram
      .getHistory()
      .addNewEvent('resetSplitOverlappingShifts', new GanttSplitOverlappingShiftsEvent(), this);

    s.parentShiftHandler.unsubscribeFromShiftHeightManipulation();
    s._combineGroupedRows(s.shiftGroups);
    s.shiftGroups.length = 0;
    s.ganttDiagram.getDataHandler().initCanvasShiftData();
    s.ganttDiagram.getDataHandler().initCanvasYAxisData();

    if (updateAfterReset) {
      s.ganttDiagram.update();
    }
    s.isReset = true;
  }

  /**
   * Resets overlapping shifts only inside given row.
   * @param {string[]} rowIds Start point of calculation. Defaults to all gantt entries. Can be an origin row id or an overlapping row id.
   * @param {boolean} [updateAfterReset] If set to false, update of gantt will not be executed.
   * @return {GanttSplitOverlappingShiftGroup[]} Reset shift group information.
   */
  resetSplitOverlappingShiftsByRowIds(rowIds, updateAfterReset = true) {
    const s = this;

    // @Florian: add history event
    // s.ganttDiagram.getHistory().addNewEvent("resetSplitOverlappingShifts", new GanttSplitOverlappingShiftsEvent(), this);
    if (!s.active) {
      return;
    }
    const matchingGroups = [];

    if (rowIds?.length) {
      for (let i = 0; i < s.shiftGroups.length; i++) {
        if (
          rowIds.indexOf(s.shiftGroups[i].originRowId) > -1 ||
          s.shiftGroups[i].groupedRowIds.find(function (groupId) {
            return rowIds.indexOf(groupId) > -1;
          })
        ) {
          matchingGroups.push(s.shiftGroups.splice(i, 1)[0]);
          i--;
        }
      }
      // remove group
      s._combineGroupedRows(matchingGroups);
      s.ganttDiagram.getDataHandler().initCanvasShiftData();
      s.ganttDiagram.getDataHandler().initCanvasYAxisData();
    }

    if (updateAfterReset) {
      s.ganttDiagram.update();
    }
    s.isReset = true;
    return matchingGroups;
  }

  /**
   * Combines the rows in the given groups by appending their shifts to the origin row's shifts array.
   * If the additional row is open, the origin row is also set to open.
   * If the additional row is the last row in the group, its child property is assigned to the origin row's child property.
   * @param groups An array of GanttSplitOverlappingShiftGroup objects representing the groups of rows to combine.
   */
  private _combineGroupedRows(groups: GanttSplitOverlappingShiftGroup[]) {
    const s = this;
    for (let i = 0; i < groups.length; i++) {
      const group = groups[i];
      const originRow = YAxisDataFinder.getRowById(
        s.ganttDiagram.getDataHandler().getOriginDataset().ganttEntries,
        group.originRowId
      );
      if (!originRow.data) {
        // console.warn("No origin row with id %s found.", group.originRowId);
        continue;
      }
      for (let j = 0; j < group.groupedRowIds.length; j++) {
        const additionalRowId = group.groupedRowIds[j];
        const additionalRow = YAxisDataFinder.deleteRowById(
          s.ganttDiagram.getDataHandler().getOriginDataset().ganttEntries,
          additionalRowId
        );
        originRow.data.shifts = originRow.data.shifts.concat(additionalRow.data.shifts);
        if (additionalRow.data.open) originRow.open = true;
        if (j == group.groupedRowIds.length - 1) {
          originRow.data.child = additionalRow.data.child;
        }
      }
      ShiftDataSorting.sortJSONListByDate(originRow.data.shifts, 'timePointStart');
      originRow.data.group = 'NO-GROUP';
    }
  }

  /**
   * @override
   */
  update() {
    const s = this;
    s.notAffectedRowsIndicator.update();
  }

  /**
   * @override
   */
  updatePlugInHeight() {}

  /**
   * @override
   */
  public removePlugIn(): void {
    this.resetSplitOverlappingShifts();
    this.notAffectedRowsIndicator.remove();

    this._onDestroySubject.next();
    this._onDestroySubject.complete();
  }

  /**
   * @param {string} rowId Id of row which should not be part of the calculation.
   */
  addNotAffectedRow(rowId) {
    const rowIndex = this.ruleSet.notAffectedRows.indexOf(rowId);
    if (rowIndex == -1) this.ruleSet.notAffectedRows.push(rowId);
  }

  //
  // GETTER & SETTER
  //

  public get parentShiftHandler(): GanttSplitOverlappingShiftsParentShiftHandler {
    return this._parentShiftHandler;
  }

  getOriginRowIdByOverlapingRowId(rowId) {
    const s = this;
    const indexOfDeleteStart = rowId.indexOf(s.UUID);
    if (indexOfDeleteStart == -1) return rowId;
    return rowId.substr(0, indexOfDeleteStart);
  }

  toggleNotAffectedRows(notAffectedRowId) {
    const s = this;
    const foundIndex = s.ruleSet.notAffectedRows.indexOf(notAffectedRowId);
    if (foundIndex == -1) s.ruleSet.notAffectedRows.push(notAffectedRowId);
    else s.ruleSet.notAffectedRows.splice(foundIndex, 1);
    this.notAffectedRowsIndicator.build();
  }

  /**
   * @param {PrioritisedAttribute} prioritisedAttribute
   */
  addPrioritisedAttribute(prioritisedAttribute) {
    this.ruleSet.prioritiseAttributes.push(prioritisedAttribute);
  }
  clearPrioritisedAttributes() {
    this.ruleSet.prioritiseAttributes = [];
  }

  subscribeAfterSplit(id, func) {
    this.callbacks.afterSplit[id] = func;
  }

  unSubscribeAfterSplit(id) {
    delete this.callbacks.afterSplit[id];
  }

  public addOverlapCompareMethod(
    id: string,
    func: (overlapIndicator: IOverlappingShiftsIndicator) => void,
    priority: number
  ): void {
    this.splitCallbackCounter++;
    GanttUtilities.addCallbackWithPriority(this.callbacks.overlapCompareMethods, id, func, priority);
  }

  public removeOverlapCompareMethod(id: string): void {
    this.splitCallbackCounter--;
    GanttUtilities.removeCallbackWithPriority(this.callbacks.overlapCompareMethods, id);
  }

  get isSplitActive(): boolean {
    return !this.isReset;
  }

  //
  // OBSERVABLES
  //

  /**
   * Observable which gets triggered when the instance gets destroyed.
   */
  public get onDestroy(): Observable<void> {
    return this._onDestroySubject.asObservable();
  }
}

/**
 * Data class to store additional rows.
 * @class
 * @constructor
 * @keywords
 * @param {string} originRowId
 */
export class GanttSplitOverlappingShiftGroup {
  originRowId: string;
  groupedRowIds: string[];

  constructor(originRowId) {
    this.originRowId = originRowId;
    this.groupedRowIds = [];
  }
}

/**
 * @constructor
 * @param {string[]} propertyList List of JSON properties which have to be called inside a shift to get value for prioritising.
 * @param {string} value
 */
export class PrioritisedAttribute {
  propertyList: string[];
  value: string;

  constructor(propertyList, value) {
    this.propertyList = propertyList;
    this.value = value;
  }
}

export interface IOverlappingShiftsIndicator {
  overlapping: boolean;
  minShift: GanttDataShift;
  maxShift: GanttDataShift;
  zoom: d3.ZoomTransform;
  scale: d3.ScaleTime<number, number, never>;
}

export interface RuleSet {
  /**
   * Sorted list of attributes which will be prioritised.
   */
  prioritiseAttributes: {
    /**
     * Path to attribute.
     */
    propertyList: string[];
    /**
     * Attribute value.
     */
    value: string;
  }[];
  /**
   * List of shift ids which will be not splitted.
   */
  notAffectedRows: string[];
}
