import { NgModule } from '@angular/core';
import { CoreLayoutModule } from './core/core-layout.module';
import { GridLayoutModule } from './grid-layout/grid-layout.module';
import { VirtualLayoutModule } from './virtual-layout/virtual-layout.module';

@NgModule({
  imports: [CoreLayoutModule, GridLayoutModule, VirtualLayoutModule],
  exports: [CoreLayoutModule, GridLayoutModule, VirtualLayoutModule],
})
export class LayoutModule {}
