import { EGanttInstance } from '@gantt/public-api';
import { GanttLibService } from 'frontend/src/dashboard/gantt/gantt/gantt-lib.service';
import { SaxMsBestGanttActiveSubmenuEntryElementSetting } from 'frontend/src/dashboard/gantt/gantt/saxms-best-gantt.settings';
import { GeneralGanttActionHandler } from 'frontend/src/dashboard/gantt/general/action-handling/action-handler';
import { GanttPluginHandlerService } from 'frontend/src/dashboard/gantt/general/plugin/gantt-plugin-handler.service';
import { GanttResponseHandler } from 'frontend/src/dashboard/gantt/general/response/response-handler';
import { Observable } from 'rxjs';
import { ExternalGanttPlugin } from '../../external-plugin';
import { GanttShiftCreatorActionHandler } from './event-handling/event-registration';

/**
 * @deprecated 12.07.2022 -> can be removed later
 */
export const GanttShiftCreatorBackendTypeOld = 'template.ganttplugin.shift';
export const GanttShiftCreatorBackendType = 'template.ganttplugin.blockCreator';

const GanttShiftCreator = 'gantt-shift-creator-plugin';

/**
 * PlugIn-Wrapper for GanttShiftCreator.
 * Provides funcitonality to create shifts by drawing them in gantt.
 */
export class GanttShiftCreatorPlugIn extends ExternalGanttPlugin {
  public injectSettings(submenuElements: SaxMsBestGanttActiveSubmenuEntryElementSetting[]): void {}
  private registration: GanttShiftCreatorActionHandler;
  private shiftCreatorData = null;

  constructor(
    protected _ganttPluginHandlerService: GanttPluginHandlerService,
    protected _ganttLibService: GanttLibService,
    private responseHandler: GanttResponseHandler,
    actionHandler: GeneralGanttActionHandler,
    backendPluginData: any,
    private templateData: any
  ) {
    super(_ganttPluginHandlerService, _ganttLibService, actionHandler);
    this.shiftCreatorData = this.findIntervalInputData(backendPluginData);
  }

  /**
   * Inits connections by backend data.
   * @param templateData
   * @param responseData
   */
  public onInit(templateData: any, responseData: any) {
    this.addPlugIn(
      GanttShiftCreator,
      this._ganttLibService.ganttInstanceService.getInstance(EGanttInstance.SHIFT_CREATOR)
    );

    this.registration = new GanttShiftCreatorActionHandler(
      this._ganttPluginHandlerService,
      this._ganttLibService,
      this.getPlugInById(GanttShiftCreator),
      this.actionHandler,
      this.responseHandler,
      this.templateData,
      this.shiftCreatorData
    );
  }

  public onDestroy(): void {
    this.registration.destroy();
  }

  public onAction(action: any) {}

  public executeAction(action: any): Observable<any> {
    return this.registration.handleLocalAction(action);
  }

  /**
   * Extracts shift creator data from template by comparing all elements of the plugin list.
   * @param backendPluginData Plugin part of template data.
   */
  private findIntervalInputData(backendPluginData: any[]): any {
    return backendPluginData.find(
      (pluginData) =>
        pluginData.id === GanttShiftCreatorBackendType || pluginData.id === GanttShiftCreatorBackendTypeOld
    );
  }
}
