import * as d3 from 'd3';
import { ShiftDataFinder } from '../../../../data-handler/data-finder/shift-data-finder';
import { YAxisDataFinder } from '../../../../data-handler/data-finder/yaxis-data-finder';
import { GanttCanvasShift } from '../../../../data-handler/data-structure/data-structure';
import { BestGantt } from '../../../../main';
import { GanttShiftsBuildingDefault } from '../../../../shifts/shift-build-strategies/shift-build-default';
import { IGanttShiftTranslationEvent } from '../../translation-events/translation-event.interface';
import { GanttShiftsMultiDragStrategy } from './shift-multi-drag-strategy.interface';

/**
 * Concrete implementation for multi-drag.
 */
export class GanttShiftsOneLineDragStrategy extends GanttShiftsMultiDragStrategy {
  private readonly _shiftOverlaySVGBuilder = new GanttShiftsBuildingDefault();
  private _resetTargetShiftData: GanttShiftsOneLineDragStrategyOriginData;
  private _movingShifts: d3.Selection<SVGRectElement, GanttCanvasShift, d3.BaseType, undefined>;
  private _startPointTargetShift: number;
  private _isCanvasShiftStrategy = true;

  constructor(ganttDiagram: BestGantt) {
    super(ganttDiagram);
  }

  public executeTranslationStart(dragStartEvent: IGanttShiftTranslationEvent): void {
    const targetData = dragStartEvent.target.data()[0];

    if (targetData && !targetData.selected) return;
    if (
      !this._ganttDiagram.getShiftTranslator().shiftEditLimiterAdapter.allowDragVertical &&
      !this._ganttDiagram.getShiftTranslator().shiftEditLimiterAdapter.allowDragHorizontal
    ) {
      return;
    }

    let additionalDraggedShifts = this._ganttDiagram
      .getSelectionBoxFacade()
      .getSelectedShifts()
      .filter(function (selectedShift) {
        return selectedShift.id !== targetData.id;
      });
    if (additionalDraggedShifts.length === 0) return;

    additionalDraggedShifts = this._filterDragRestrictedShiftsOut(additionalDraggedShifts);

    if (this._isCanvasShiftStrategy) {
      this._shiftOverlaySVGBuilder.renderShifts(
        additionalDraggedShifts,
        this._ganttDiagram.getShiftFacade().getShiftGroupOverlay(),
        this._ganttDiagram.getShiftFacade().getShiftBuilder()
      );
      for (const shift of additionalDraggedShifts) {
        this._renderGhostShiftDuringDrag(shift);
      }
      this._ganttDiagram.rerenderShiftsVertical();
    }

    const shiftIds = additionalDraggedShifts.map(function (shift) {
      return shift.id;
    });
    this._startPointTargetShift = targetData.x;

    if (this._ganttDiagram.getConfig().getShiftBuildingShowShiftContent()) {
      this._ganttDiagram.getShiftFacade().getShiftGroupOverlay().selectAll('.gantt_shift_contents').remove();
    }

    this._movingShifts = this._ganttDiagram
      .getShiftFacade()
      .getShiftGroupOverlay()
      .selectAll<SVGRectElement, GanttCanvasShift>('rect')
      .filter((d) => {
        return !!d && !!d.id && shiftIds.indexOf(d.id) != -1;
      })
      .attr('class', 'gantt-multidrag-shift-overlay')
      .attr('clip-path', null);

    this._translateShiftRectToYPosition(
      this._movingShifts,
      this._ganttDiagram.getRenderDataHandler().getShiftDataFinder().getShiftViewportY(targetData.id)
    );

    this._resetTargetShiftData = this._generateResetData(targetData);
  }

  public executeDuringTranslation(draggingEvent: IGanttShiftTranslationEvent): void {
    if (!this._movingShifts || this._movingShifts.size() == 0) return;
    if (
      !this._ganttDiagram.getShiftTranslator().shiftEditLimiterAdapter.allowDragVertical &&
      !this._ganttDiagram.getShiftTranslator().shiftEditLimiterAdapter.allowDragHorizontal
    ) {
      return;
    }

    this._translateShiftRectToYPosition(this._movingShifts, parseFloat(draggingEvent.target.attr('y')));
    this._translateShiftRectByXDifference(this._movingShifts, draggingEvent.originEvent.event.event.dx);
  }

  public executeTranslationEnd(dragEndEvent: IGanttShiftTranslationEvent): void {
    if (this._isCanvasShiftStrategy) {
      this._ganttDiagram.getShiftFacade().getShiftGroupOverlay().selectAll('.gantt-multidrag-shift-overlay').remove();
    }

    if (!dragEndEvent.hasBeenDragged || !this._movingShifts || this._movingShifts.size() == 0) return;

    if (
      !this._ganttDiagram.getShiftTranslator().shiftEditLimiterAdapter.allowDragVertical &&
      !this._ganttDiagram.getShiftTranslator().shiftEditLimiterAdapter.allowDragHorizontal
    ) {
      return;
    }

    const targetShiftData = dragEndEvent.target.data()[0];
    const targetRowData = YAxisDataFinder.getCanvasRowById(
      this._ganttDiagram.getDataHandler().getYAxisDataset(),
      dragEndEvent.dragEndRowId
    );

    if (this._shiftsBlockedByRowId(this._movingShifts, targetRowData.id)) {
      // reset all shifts to origin position
      console.info(`shift was blocked from drag`);
      this._resetShiftDrag(this._resetTargetShiftData);
    } else {
      this._translateShiftRectToYPosition(
        this._movingShifts,
        this._ganttDiagram.getRenderDataHandler().getYAxisDataFinder().getRowViewportY(targetRowData.id) +
          this._ganttDiagram.getConfig().getLineTop()
      );
      this._updateShiftData(this._movingShifts, targetRowData.id, this._startPointTargetShift, targetShiftData.x);
    }
    this._ganttDiagram.renderShifts();
    this._movingShifts = null;
  }

  /**
   * Filters out shifts from additionaldraggedShifts that are registered in an DragLimiter.
   * @param {any[]} additionalDraggedShifts
   * @return {any[]} additionalDraggedShifts
   */
  private _filterDragRestrictedShiftsOut(additionalDraggedShifts) {
    const dragLimiters = this._ganttDiagram.getShiftEditLimiter().getRegisteredDragLimiterPlugIns();
    const dragRestrictedShiftsFromDragLimiters = [];
    for (const pluginID in dragLimiters) {
      const restrictions = dragLimiters[pluginID].getRestrictions();
      for (const shiftid in restrictions) {
        dragRestrictedShiftsFromDragLimiters.push(shiftid);
      }
    }

    const restrictedShifts = new Set(dragRestrictedShiftsFromDragLimiters);
    additionalDraggedShifts = additionalDraggedShifts.filter(
      (shift) => !restrictedShifts.has(shift.id) && !shift.disableMove && shift.editable
    );
    return additionalDraggedShifts;
  }

  private _translateShiftRectToYPosition(
    shiftBlocks: d3.Selection<SVGRectElement, GanttCanvasShift, d3.BaseType, undefined>,
    yPosition: number
  ) {
    if (this._ganttDiagram.getShiftTranslator().shiftEditLimiterAdapter.allowDragVertical) {
      shiftBlocks.attr('y', yPosition);
      return shiftBlocks;
    }
  }

  private _translateShiftRectByXDifference(
    shiftBlocks: d3.Selection<SVGRectElement, GanttCanvasShift, d3.BaseType, undefined>,
    xDifference: number
  ) {
    if (this._ganttDiagram.getShiftTranslator().shiftEditLimiterAdapter.allowDragHorizontal) {
      shiftBlocks.attr('x', function () {
        return parseFloat(d3.select(this).attr('x')) + xDifference;
      });
    }
    return shiftBlocks;
  }

  private _updateShiftData(
    shiftBlocks: d3.Selection<SVGRectElement, GanttCanvasShift, d3.BaseType, undefined>,
    newRowId: string,
    originX: number,
    newX: number
  ) {
    shiftBlocks.each((d) => {
      const foundShift = ShiftDataFinder.changeShiftParentById(
        this._ganttDiagram.getDataHandler().getOriginDataset().ganttEntries,
        newRowId,
        d.id
      );

      if (foundShift.shift) {
        const scale = this._ganttDiagram.getXAxisBuilder().getGlobalScale();
        const timeDifference =
          this._ganttDiagram.getXAxisBuilder().pxToTime(newX, scale).getTime() -
          this._ganttDiagram.getXAxisBuilder().pxToTime(originX, scale).getTime();

        foundShift.shift.timePointStart = new Date(
          new Date(foundShift.shift.timePointStart).getTime() + timeDifference
        );
        foundShift.shift.timePointEnd = new Date(new Date(foundShift.shift.timePointEnd).getTime() + timeDifference);
      }
    });

    this._ganttDiagram.getDataHandler().initCanvasShiftData();
  }

  /**
   * Checks if there are row restrictions by given rect selection.
   * @param shiftBlocks D3 Selection of shift rects.
   * @param rowId Id of new row.
   */
  private _shiftsBlockedByRowId(
    shiftBlocks: d3.Selection<SVGRectElement, GanttCanvasShift, d3.BaseType, undefined>,
    rowId: string
  ): boolean {
    const s = this;
    let blocked = false;
    shiftBlocks.each(function (d) {
      const scale = s._ganttDiagram.getXAxisBuilder().getCurrentScale();
      const startDate = s._ganttDiagram.getXAxisBuilder().pxToTime(parseFloat(d3.select(this).attr('x')), scale);
      const endDate = s._ganttDiagram
        .getXAxisBuilder()
        .pxToTime(parseFloat(d3.select(this).attr('width')) + parseFloat(d3.select(this).attr('x')), scale);
      const areaRestriction = s._ganttDiagram
        .getShiftTranslator()
        .translationAreaLimiter.checkAreaRestriction(d.id, rowId, startDate, endDate).allowDrop;
      const translationRestriction = s._ganttDiagram
        .getShiftTranslator()
        .translationRowLimiter.isShiftDropAllowed(
          d.entryTypes,
          s._ganttDiagram.getDataHandler().getYAxisDataset(),
          rowId
        );
      if (!(translationRestriction && areaRestriction)) {
        blocked = true;
      }
    });
    return blocked;
  }

  /**
   * @param {ShiftCanvasData} dragTarget Shift data of dragged shift rect.
   */
  private _generateResetData(dragTarget) {
    const s = this;
    const foundShiftData = ShiftDataFinder.getShiftById(
      s._ganttDiagram.getDataHandler().getOriginDataset().ganttEntries,
      dragTarget.id
    );
    if (!foundShiftData.shift || !foundShiftData.shiftRow) return;
    return new GanttShiftsOneLineDragStrategyOriginData(
      dragTarget.id,
      foundShiftData.shiftRow.id,
      foundShiftData.shift.timePointStart,
      foundShiftData.shift.timePointEnd
    );
  }

  /**
   * @param {GanttShiftsOneLineDragStrategyOriginData} resetData
   */
  private _resetShiftDrag(resetData) {
    const s = this;

    if (!resetData) return;
    const foundShift = ShiftDataFinder.changeShiftParentById(
      s._ganttDiagram.getDataHandler().getOriginDataset().ganttEntries,
      resetData.originRowId,
      resetData.shiftId
    );
    if (!foundShift.shift) return;
    foundShift.shift.timePointStart = resetData.originTimePointStart;
    foundShift.shift.timePointEnd = resetData.originTimePointEnd;

    s._ganttDiagram.getDataHandler().initCanvasShiftData();
    s._ganttDiagram.rerenderShiftsVertical();
  }

  /**
   * Sets opacity to shift during on drag start.
   * This will cause the canvas to reRender this shift translucent and thus preserve to original position for better UX.
   * On Drag End we reset the opacity value again! {@link GanttShiftTranslationFacade#_resetGhostShifOnDragEnd}
   * @param {GanttCanvasShift} shiftData
   */
  private _renderGhostShiftDuringDrag(shiftData) {
    const updateData = new Map<string, Partial<GanttCanvasShift>>();
    updateData.set(shiftData.id, {
      opacity: 0.2,
    });

    this._ganttDiagram.getDataHandler().updateCanvasShiftPropertiesInCanvasDataByShiftId(updateData);
  }
}

/**
 * @constructor
 */
export class GanttShiftsOneLineDragStrategyOriginData {
  shiftId: string;
  originRowId: string;
  originTimePointStart: Date;
  originTimePointEnd: Date;

  constructor(shiftId, originRowId, originTimePointStart, originTimePointEnd) {
    this.shiftId = shiftId;
    this.originRowId = originRowId;
    this.originTimePointStart = new Date(originTimePointStart);
    this.originTimePointEnd = new Date(originTimePointEnd);
  }
}
