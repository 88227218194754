import * as d3 from 'd3';
import { GanttEdgeHandler } from './edge-executer';

/**
 * Add labels to arrows start or end to add text to edges.
 * @keywords label, text, overlay, description, arrow, edge, plugin, builder
 * @plugin edges
 * @class
 * @constructor
 * @param {HTMLNode} parentNode
 */
export class GanttEdgeLabeler {
  parentNode: any;
  executer: GanttEdgeHandler;
  edgeLabels: GanttEdgeLabelData[];
  zoomTransformation: any;
  canvas: d3.Selection<any, any, null, undefined>;
  labelStartX: number;
  labelStartY: number;
  labelEndX: number;
  labelEndY: number;
  notEnoughSpaceText: string;
  labelStorage: any;
  labelEnd: d3.Selection<any, any, null, undefined>;
  labelStart: d3.Selection<any, any, null, undefined>;

  constructor(parentNode, executer) {
    this.parentNode = parentNode;
    this.executer = executer;

    this.edgeLabels = [];
    this.zoomTransformation = { k: 1, x: 0, y: 0 };
    this.canvas = null;

    //adjustments
    this.labelStartX = 3;
    this.labelStartY = -5;

    this.labelEndX = 5;
    this.labelEndY = -5;

    this.notEnoughSpaceText = '..';

    this.labelStorage = {};
  }

  /**
   * Adds a label to the beginning of an edge.
   * @param id1
   * @param id2
   * @param {string} text labeltext
   * @param {float} xPos x-coordinate of the label
   * @param {float} yPos y-coordinate of the label
   * @param {string} edgeId id of an edge
   * @param {boolean} visible if label is visible
   * @param clipPathUrl
   */
  public addStartLabel(id1, id2, text, xPos, yPos, edgeId, visible, clipPathUrl: string): void {
    if (!text) {
      return;
    }

    let label = this.getLabelByEdgeId(edgeId);

    //update existing label
    if (label != null) {
      label.labelStart = new GanttEdgeLabel(text, xPos, yPos, clipPathUrl);
      label.visible = visible;
      return;
    }

    label = new GanttEdgeLabelData(id1, id2, edgeId, visible);
    label.labelStart = new GanttEdgeLabel(text, xPos, yPos, clipPathUrl);

    this.edgeLabels.push(label);
  }

  /**
   * Adds a label to the end of an edge.
   * @param id1
   * @param id2
   * @param {string} text labeltext
   * @param {float} xPos x-coordinate of the label
   * @param {float} yPos y-coordinate of the label
   * @param {string} edgeId id of an edge
   * @param {boolean} visible if label is visible
   * @param clipPathUrl
   */
  public addEndLabel(id1, id2, text, xPos, yPos, edgeId, visible, clipPathUrl: string): void {
    if (!text) {
      return;
    }

    let label = this.getLabelByEdgeId(edgeId);

    //update existing label
    if (label != null) {
      label.labelEnd = new GanttEdgeLabel(text, xPos, yPos, clipPathUrl);
      label.visible = visible;
      return;
    }

    label = new GanttEdgeLabelData(id1, id2, edgeId, visible);
    label.labelEnd = new GanttEdgeLabel(text, xPos, yPos, clipPathUrl);

    this.edgeLabels.push(label);
  }

  /**
   * Init the edge labeler
   * Creates groups
   */
  init() {
    const s = this;
    s.canvas = d3.select(s.parentNode).append('g').attr('class', 'gantt_edge_label_group');

    s.labelStart = s.canvas.append('g').attr('class', 'gantt_edge_Startlabel_group');

    s.labelEnd = s.canvas.append('g').attr('class', 'gantt_edge_Endlabel_group');
  }

  /**
   * remove the edge labeler
   * remove groups
   */
  remove() {
    const s = this;
    s.canvas.remove();
  }

  /**
   * builds the labels in canves
   */
  public build(dataset: GanttEdgeLabelData[]): void {
    const s = this;

    if (!s.executer.edgeConfig.renderEdges) {
      dataset = [];
    }

    dataset = s.mergeOverlappingLabels(dataset);

    const buildStartLabel = s.labelStart.selectAll('text').data(
      dataset.filter(function (d) {
        return d.visible && d.labelStart != null;
      })
    );

    buildStartLabel
      .enter()
      .append('text')
      .attr('x', function (d: GanttEdgeLabelData) {
        return s._calculateXCoordinate(d.labelStart.x) + s.labelStartX;
      })
      .attr('y', function (d: GanttEdgeLabelData) {
        return d.labelStart.y + s.labelStartY;
      })
      .text(function (d: GanttEdgeLabelData) {
        if (d.labelStart && d.labelEnd) {
          if (!s._checkingLabelSpace(d.edgeId)) return s.notEnoughSpaceText;
        }
        return d.labelStart.label;
      })
      .attr('clip-path', (d) => d.labelStart.clipPathUrl);

    buildStartLabel
      .attr('x', function (d: GanttEdgeLabelData) {
        return s._calculateXCoordinate(d.labelStart.x) + s.labelStartX;
      })
      .attr('y', function (d: GanttEdgeLabelData) {
        return d.labelStart.y + s.labelStartY;
      })
      .text(function (d: GanttEdgeLabelData) {
        if (d.labelStart && d.labelEnd) {
          if (!s._checkingLabelSpace(d.edgeId)) return s.notEnoughSpaceText;
        }
        return d.labelStart.label;
      })
      .attr('clip-path', (d) => d.labelStart.clipPathUrl);

    buildStartLabel.exit().remove();

    const buildEndLabel = s.labelEnd.selectAll<any, any>('text').data(
      dataset.filter(function (d) {
        return d.visible && d.labelEnd != null;
      })
    );

    buildEndLabel
      .enter()
      .append('text')
      .text(function (d: GanttEdgeLabelData) {
        //text before coordinates because getBBox-function
        if (d.labelStart && d.labelEnd) {
          if (!s._checkingLabelSpace(d.edgeId)) return s.notEnoughSpaceText;
        }
        return d.labelEnd.label;
      })
      .attr('x', function (d: GanttEdgeLabelData) {
        return s._calculateXCoordinate(d.labelEnd.x) - s.labelEndX - this.getBBox().width;
      })
      .attr('y', function (d: GanttEdgeLabelData) {
        return d.labelEnd.y + s.labelEndY;
      })
      .attr('clip-path', (d) => d.labelEnd.clipPathUrl);

    buildEndLabel
      .text(function (d: GanttEdgeLabelData) {
        //text before coordinates because getBBox-function
        if (d.labelStart && d.labelEnd) {
          if (!s._checkingLabelSpace(d.edgeId)) return s.notEnoughSpaceText;
        }
        return d.labelEnd.label;
      })
      .attr('x', function (d: GanttEdgeLabelData) {
        return s._calculateXCoordinate(d.labelEnd.x) - s.labelEndX - this.getBBox().width;
      })
      .attr('y', function (d: GanttEdgeLabelData) {
        return d.labelEnd.y + s.labelEndY;
      })
      .attr('clip-path', (d) => d.labelEnd.clipPathUrl);

    s.splitOverlappingLabels(dataset);

    buildEndLabel.exit().remove();
  }

  /**
   * Calculates and merges labels that are overlapping because they enter the same shift.
   */
  mergeOverlappingLabels(dataset) {
    const s = this;

    const startLabels = {};
    const endLabels = {};

    for (let i = 0; i < dataset.length; i++) {
      const edge = dataset[i];
      if (!edge.visible) {
        continue;
      }

      if (edge.labelStart) {
        startLabels[edge.id1] ? startLabels[edge.id1].push(edge.edgeId) : (startLabels[edge.id1] = [edge.edgeId]);
      }
      if (edge.labelEnd) {
        endLabels[edge.id2] ? endLabels[edge.id2].push(edge.edgeId) : (endLabels[edge.id2] = [edge.edgeId]);
      }
    }

    for (const label in startLabels) {
      if (startLabels[label].length > 1) {
        const toMerge = dataset.filter((edge) => startLabels[label].includes(edge.edgeId));
        const newMergedLabel = toMerge.map((edge) => edge.labelStart.label).join(', ');
        toMerge.map((edge) => {
          if (!s.labelStorage[edge.edgeId]) {
            s.labelStorage[edge.edgeId] = { startLabel: null, endLabel: null };
          }
          s.labelStorage[edge.edgeId].startLabel = edge.labelStart.label;
        });
        toMerge.forEach((edge) => (edge.labelStart.label = ''));
        toMerge[0].labelStart.label = newMergedLabel;
      }
    }

    for (const label in endLabels) {
      if (endLabels[label].length > 1) {
        const toMerge = dataset.filter((edge) => endLabels[label].includes(edge.edgeId));

        const newMergedLabel = toMerge.map((edge) => edge.labelEnd.label).join(', ');
        toMerge.map((edge) => {
          if (!s.labelStorage[edge.edgeId]) {
            s.labelStorage[edge.edgeId] = { startLabel: null, endLabel: null };
          }
          s.labelStorage[edge.edgeId].endLabel = edge.labelEnd.label;
        });
        toMerge.forEach((edge) => (edge.labelEnd.label = ''));
        toMerge[0].labelEnd.label = newMergedLabel;
      }
    }

    return dataset;
  }

  splitOverlappingLabels(dataset) {
    const s = this;

    for (const label in s.labelStorage) {
      const labelDef = s.edgeLabels.find((edge) => edge.edgeId == label);

      if (s.labelStorage[label].startLabel) {
        labelDef.labelStart.label = s.labelStorage[label].startLabel;
      }
      if (s.labelStorage[label].endLabel) {
        labelDef.labelEnd.label = s.labelStorage[label].endLabel;
      }
    }

    s.labelStorage = {};
  }

  /**
   * calculates the zoom transformed x-coordinate
   *
   * @param {float} x x-coordinate
   */
  _calculateXCoordinate(x) {
    const s = this;
    return x * s.zoomTransformation.k + s.zoomTransformation.x;
  }

  /**
   * checks if the labeltext fits in the available space and returns a bool
   *
   * @param {string} edgeId id of an edge
   */
  _checkingLabelSpace(edgeId) {
    const s = this;

    for (let i = 0; i < s.edgeLabels.length; i++) {
      if (s.edgeLabels[i].edgeId == edgeId) {
        const startLabelWidthAndHeight = s._getTextWidthAndHeight(s.edgeLabels[i].labelEnd.label);
        const endLabelWidthAndHeight = s._getTextWidthAndHeight(s.edgeLabels[i].labelStart.label);

        const beginningOfStartLabelX = s._calculateXCoordinate(s.edgeLabels[i].labelStart.x) + s.labelStartX;
        const endOfStartLabelX = beginningOfStartLabelX + startLabelWidthAndHeight[0];

        const endOfEndLabelX = s._calculateXCoordinate(s.edgeLabels[i].labelEnd.x) - s.labelEndX;
        const beginningOfEndLabelX = endOfEndLabelX - endLabelWidthAndHeight[0];

        const startLabelTopY = s.edgeLabels[i].labelStart.y - startLabelWidthAndHeight[1];
        const startLabelBottomY = s.edgeLabels[i].labelStart.y;

        const endLabelTopY = s.edgeLabels[i].labelEnd.y - endLabelWidthAndHeight[1];
        const endLabelBottomY = s.edgeLabels[i].labelEnd.y;

        if (
          (endOfStartLabelX > beginningOfEndLabelX &&
            beginningOfStartLabelX < endOfEndLabelX &&
            startLabelTopY < endLabelBottomY &&
            startLabelBottomY > endLabelTopY) ||
          (startLabelTopY == endLabelTopY &&
            beginningOfStartLabelX > beginningOfEndLabelX &&
            s.edgeLabels[i].labelEnd.x > s.edgeLabels[i].labelStart.x)
        ) {
          return false;
        } else {
          return true;
        }
      }
    }
  }

  /**
   * callculates the width and height of a textstring and retruns them as an array
   *
   * @param {string} text text to proof
   */
  _getTextWidthAndHeight(text) {
    const s = this;
    let object;

    const tmpTxt = s.canvas
      .append('text')
      .text(text)
      .each(function () {
        object = this;
      });

    object = object.getBBox();
    tmpTxt.remove();

    return [object.width, object.height];
  }

  /**
   * Removes edge label for edgeId.
   * @param {String} edgeId
   * @param {ComponentEvent} event Undo redo event for deleting edges.
   */
  removeLabelByEdgeId(edgeId, event) {
    const s = this;

    for (let i = 0; i < s.edgeLabels.length; i++) {
      if (s.edgeLabels[i].edgeId == edgeId) {
        if (event) event.addAnotherArgument(JSON.parse(JSON.stringify(s.edgeLabels[i])));
        s.edgeLabels.splice(i, 1);
        return;
      }
    }
  }

  /**
   * removes all labels from edgeLabels-array
   */
  removeAllLabels() {
    const s = this;
    s.edgeLabels = [];
  }
  //
  //  GETTER & SETTER
  //
  setZoomTransformation(zoomTransformation) {
    const s = this;
    s.zoomTransformation = zoomTransformation;
  }

  setVisibilityInEdgeLabelsDatasetByEdgeId(edgeId, bool) {
    const s = this;
    const label = s.getLabelByEdgeId(edgeId);

    if (label) {
      label.visible = bool;
    }
  }

  getLabelByEdgeId(edgeId) {
    const s = this;

    for (let i = 0; i < s.edgeLabels.length; i++) {
      if (s.edgeLabels[i].edgeId == edgeId) {
        return s.edgeLabels[i];
      }
    }
    return null;
  }

  setStartLabelByEdgeId(edgeId, text) {
    const s = this;
    const label = s.getLabelByEdgeId(edgeId);
    label.labelStart.label = text;
  }

  setEndLabelByEdgeId(edgeId, text) {
    const s = this;
    const label = s.getLabelByEdgeId(edgeId);
    label.labelEnd.label = text;
  }

  /**
   * @private
   * @returns {String[]}
   */
  getLabels() {
    return this.edgeLabels;
  }
}

/**
 * Data of a label.
 */
export class GanttEdgeLabel {
  /**
   *
   * @param label labeltext
   * @param xPos x-coordinate
   * @param yPos y-coordinate
   * @param clipPathUrl
   */
  constructor(public label: string, public x: number, public y: number, public clipPathUrl: string) {}
}

/**
 * Dataset of a label.
 */
export class GanttEdgeLabelData {
  public labelStart: GanttEdgeLabel = undefined;
  public labelEnd: GanttEdgeLabel = undefined;

  /**
   * @param id1
   * @param id2
   * @param edgeId id of an edge
   * @param visible if label is visible
   */
  constructor(public id1: string, public id2: string, public edgeId: string, public visible: boolean) {}
}
