<mat-form-field>
  <input
    autocomplete="off"
    name="my-default-template-picker"
    [disabled]="entryElement.isDisabled()"
    (change)="afterChanged($event.target.value)"
    (keyup)="afterKeyUp($event)"
    [value]="displayedValue || ''"
    matInput
    [placeholder]="placeholder | translate"
    [id]="'12345-09877'"
    #ref
    (click)="open()"
  />
  <button mat-icon-button matSuffix (click)="open()" [disabled]="entryElement.isDisabled()">
    <mat-icon>date_range</mat-icon>
  </button>
  <mat-hint *ngIf="hint">
    <span *ngIf="hint.start"></span>{{ hint.start | date : ('DATE.FORMATS.DATE' | translate)
    }}<span *ngIf="hint.end"> - {{ hint.end | date : ('DATE.FORMATS.DATE' | translate) }}</span>
  </mat-hint>
</mat-form-field>
