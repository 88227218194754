import { HttpParams } from '@angular/common/http';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { ERequestMethod, RequestOptions, RequestService } from '@app-modeleditor/request.service';
import { TemplateService } from '@app-modeleditor/utils/template.service';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { CalendarOptions, DateRangeInput } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import interactionPlugin from '@fullcalendar/interaction'; // a plugin!
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Observable, Observer, of } from 'rxjs';
import { delay, finalize, switchMap, tap } from 'rxjs/operators';
import { Action } from '../button/action/action';
import { Button } from '../button/button';
import { ContextMenuItem } from '../contextmenu/context-menu-item';
import { ContextmenuService } from '../contextmenu/contextmenu.service';
import { IButton } from '../dialog/dialog.component';
import { Lightbox } from '../lightbox/lightbox';
import { LightboxService } from '../lightbox/lightbox.service';
import { TemplateAdapter } from './../../utils/template-factory.service';
import { ContextMenu } from './../contextmenu/contextmenu';
import { NamedLightbox } from './../lightbox/predefined/named-lightbox';
import { SaxMSCalendar } from './cal';
import { CalendarEvent } from './calendar-event';
import { CalendarUtil } from './calendar-util.ts';

@Component({
  selector: 'calendar-wrapper',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  providers: [],
})
export class CalendarComponent implements OnInit, OnDestroy, OnChanges {
  // reference to fullcalendar
  calendarCmp: FullCalendarComponent;
  // stores all calendar events
  calendarEvents: CalendarEvent[];
  // main class for calendar
  templateElement: SaxMSCalendar;
  // whether component is loading or not
  isLoading: boolean;
  // valid range which is naviatable
  range: DateRangeInput;

  private ctxMenu: ContextMenu;
  private hoveredEvent: CalendarEvent;

  calendarOptions: CalendarOptions = {
    initialView: 'dayGridMonth',
    locale: this.translate.instant('LOCALIZATION.short'),
    droppable: false,
    eventStartEditable: false,
    height: 800,
    plugins: [dayGridPlugin, interactionPlugin],
    headerToolbar: false,
    selectable: true,
    displayEventTime: false,
    select: this.afterDatesSelected.bind(this),
    eventMouseEnter: this.enterEvent.bind(this),
    eventMouseLeave: this.leaveEvent.bind(this),
    eventClick: this.eventClicked.bind(this),
  };

  eventDragStop(a): void {}

  @ViewChild('picker') picker: MatDatepicker<null>;

  // hooks into initialization of fullcalendar
  @ViewChild('calendarRef') set onCalendarReady(calendarComponent: FullCalendarComponent) {
    of(null)
      .pipe(delay(0))
      .subscribe(() => {
        this.calendarCmp = calendarComponent;
        this.calendarCmp.getApi().updateSize();
      });
  }
  // hooks into data binding
  @Input('data') set localData(d: SaxMSCalendar) {
    this.templateElement = d;
    this.range = {
      start: this.templateElement.getStartDate(),
      end: this.templateElement.getEndDate(),
    };

    this.calendarOptions.validRange = this.range;
    this.mapData();
  }

  @Input() values: CalendarEvent[];

  private ctx: ContextMenu;

  constructor(
    private requestApi: RequestService,
    private lightboxApi: LightboxService,
    private templateFactory: TemplateAdapter,
    private tempalteApi: TemplateService,
    private translate: TranslateService,
    private contextmenuApi: ContextmenuService
  ) {}

  ngOnDestroy(): void {}

  /**
   * ng on changes lifecycle
   * @param changes SimpleChanges
   * @returns void
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data) {
      this.mapData();
    }

    if (changes.values) {
      this.mapData();
    }
  }

  /**
   * ng on init lifecycle
   * @returns void
   */
  ngOnInit(): void {
    this.mapData();
    this.calendarOptions.editable = this.templateElement.isEditable();
  }

  mapData() {
    this.hoveredEvent = null;

    if (!this.templateElement) {
      return;
    }
    this.calendarEvents = this.templateElement.getValue();
    this.calendarOptions.events = this.calendarEvents as any;

    this.calendarEvents.forEach((event) => {
      const rgbRegRex = new RegExp('rgb|#');
      // fix the bordercolor of the events for the case that the # ist missing for hex colors
      if (!rgbRegRex.test(event.getColor())) {
        event.setColor('#' + event.getColor());
      }
      // fix the background of the events for the case that the # ist missing for hex colors
      if (!rgbRegRex.test(event.getBackgroundColor())) {
        event.setBackgroundColor('#' + event.getBackgroundColor());
      }

      if (!event.getTextColor()) {
        event.setTextColor(CalendarUtil.invertColor(event.getBackgroundColor()));
      }
    });
  }

  /**
   * handles click on event
   * @param event any
   * @returns void
   */
  eventClicked(event: any): void {
    const cur: CalendarEvent = this.findCalendarByEvent(event);
    if (!cur) {
      return;
    }

    this.editCalendarEvent(cur).subscribe();
  }

  editCalendarEvent(cur: CalendarEvent) {
    return this.requestApi
      .call(ERequestMethod.GET, `rest/${cur.getEditRestUrl()}`)
      .pipe(
        switchMap((lightbox) => {
          const l: Lightbox = this.templateFactory.adapt(lightbox);
          return this.lightboxApi
            .open(l)
            .afterClosed()
            .pipe(
              tap((afterClose: IButton) => {
                this.update();
              })
            );
        })
      )
      .pipe(finalize(() => this.closeCtx()));
  }

  /**
   * handles click on date
   * @param event any
   * @returns void
   */
  dateClicked(event: any): void {
    // if (this.templateElement.isAddNewCalendarEntry() === false) {
    //   return;
    // }
    // this.createEntry(event).subscribe();
  }

  afterDatesSelected(event: any): void {
    if (this.templateElement.isAddNewCalendarEntry() === false) {
      return;
    }
    this.createEntry(event).subscribe();
  }

  public handleCurrentDateBtn() {
    this.picker.open();
  }

  public handleMonthChangeByDatepicker(normalizedMonth: Date, datepicker: MatDatepicker<moment.Moment>) {
    const date = this.currentDate;
    date.setMonth(normalizedMonth.getMonth());
    date.setFullYear(normalizedMonth.getFullYear());
    this.calendarCmp.getApi().gotoDate(date);
    datepicker.close();
  }

  private createEntry(event: any): any {
    return new Observable<any>((observer: Observer<any>) => {
      const keys: string[] = this.templateElement.getCreateRestUrls()
        ? Object.keys(this.templateElement.getCreateRestUrls())
        : [];
      const lbl: string =
        keys.length === 1
          ? this.translate.instant('FORMS.ITEMS.CREATE_BY_VALUE', {
              value: keys[0],
            })
          : 'Abwesenheit erstellen';
      if (keys.length === 1 && !this.templateElement.isNameRequired()) {
        this.onConfirm(observer, keys[0], null, event).subscribe();
      } else {
        const namedLightbox: NamedLightbox = new NamedLightbox(
          () =>
            this.onConfirm(
              observer,
              namedLightbox.getSelectedValue().getValue<string>(),
              namedLightbox,
              event,
              namedLightbox.getNameValue()?.getValue()
            ),
          this.templateElement.getCreateCustomTitle() ? this.templateElement.getCreateCustomTitle() : lbl,
          keys,
          this.templateElement.isNameRequired()
        ).setOnCustomCancelAction(
          new Button().chainActions(
            new Action().setCb(() => {
              observer.next(null);
              observer.complete();
              return of(this.closeCtx(namedLightbox));
            })
          )
        );

        this.lightboxApi.open(namedLightbox);
      }
    });
  }

  private closeCtx(lightbox?: Lightbox) {
    if (this.ctxMenu) {
      this.ctxMenu.getRef().dispose();
    }

    if (lightbox) {
      lightbox.getRef().close();
    }
  }

  private onConfirm(
    observer: Observer<any>,
    value: string,
    namedLightbox: NamedLightbox,
    createEvent: any,
    name?: string
  ): Observable<any> {
    let params: HttpParams = new HttpParams();
    if (createEvent) {
      let start;
      let end;
      if (createEvent instanceof MouseEvent) {
        const eventElt = (createEvent.target as HTMLElement).closest('.fc-day,.fc-daygrid-day');
        if (!eventElt) {
          return;
        }

        const d = new Date(eventElt.getAttribute('data-date'));
        d.setHours(0, 0, 0, 0);
        const dEnd = new Date(d);
        dEnd.setDate(dEnd.getDate() + 1);
        start = d;
        end = dEnd;
      } else {
        start = createEvent.start ? new Date(createEvent.start) : createEvent.date;
        end = createEvent.start ? new Date(createEvent.end) : createEvent.date;
      }

      params = params.set('start', start.getTime().toString());
      params = params.set('end', end.getTime().toString());
      if (name) {
        params = params.set('name', name);
      }
    }
    return this.requestApi
      .call(
        ERequestMethod.GET,
        `rest/${this.templateElement.getCreateRestUrls()[value]}`,
        new RequestOptions().setHttpOptions({
          params,
        })
      )
      .pipe(
        switchMap((json) => {
          const lightbox: Lightbox = this.templateFactory.adapt(json);
          return this.lightboxApi
            .open(lightbox)
            .afterClosed()
            .pipe(
              tap(() => {
                this.update();
              })
            );
        })
      )
      .pipe(
        finalize(() => {
          observer.next(null);
          observer.complete();
          this.closeCtx(namedLightbox);
        })
      );
  }

  handleContextmenu(event: MouseEvent): void {
    this.closeCtx();
    const match: CalendarEvent = this.hoveredEvent
      ? this.calendarEvents.find((event: CalendarEvent) => event.getEventId() === this.hoveredEvent.getEventId())
      : null;

    this.ctx = new ContextMenu().setContextMenuItems([
      new ContextMenuItem()
        .setName('CALENDAR.CONTEXTMENU.addEvent')
        .setIcon('add')
        .setDisabled(false)
        .chainActions(new Action().setCb(() => this.createEntry(match || event))),
      new ContextMenuItem()
        .setName('CALENDAR.CONTEXTMENU.editEvent')
        .setIcon('edit')
        .setDisabled(!match)
        .chainActions(new Action().setCb(() => this.editCalendarEvent(match))),
      new ContextMenuItem()
        .setName('CALENDAR.CONTEXTMENU.deleteEvent')
        .setIcon('delete')
        .setDisabled(!match)
        .chainActions(new Action().setCb(() => this.deleteCalendarEvent(match))),
    ]);

    this.contextmenuApi.create(event, this.ctx);
  }

  findCalendarByEvent(event): CalendarEvent {
    if (
      event &&
      event.event &&
      event.event._def &&
      event.event._def.extendedProps &&
      event.event._def.extendedProps.eventId
    ) {
      return this.calendarEvents.find(
        (ev: CalendarEvent) => ev.getEventId() === event.event._def.extendedProps.eventId
      );
    }
    return null;
  }

  enterEvent(event: any) {
    this.hoveredEvent = this.findCalendarByEvent(event);
  }

  leaveEvent(event: any) {
    this.hoveredEvent = null;
  }

  /**
   * handles delete of event
   * @param event any
   * @returns void
   */
  deleteEvent(event: any): void {
    const cur: CalendarEvent = this.findCalendarByEvent(event);
    if (!cur) {
      return;
    }
    this.deleteCalendarEvent(cur).subscribe();
  }

  deleteCalendarEvent(cur: CalendarEvent): Observable<any> {
    return this.requestApi
      .call(ERequestMethod.DELETE, `rest/${cur.getDeleteRestUrl()}`)
      .pipe(
        tap(() => {
          this.update();
        })
      )
      .pipe(finalize(() => this.closeCtx()));
  }

  private update() {
    if (this.templateElement.getId()) {
      this.tempalteApi.refreshElementData([this.templateElement.getId()]);
    }
  }

  /**
   * updates an event
   * @param event any
   * @returns void
   */
  updateEvent(event: any): void {
    const cur: CalendarEvent = this.findCalendarByEvent(event);
    if (!cur) {
      return;
    }
    let params: HttpParams = new HttpParams();
    params = params.set('start', new Date(event.event.start).getTime().toString());
    params = params.set('end', new Date(event.event.end).getTime().toString());
    this.requestApi
      .call(
        ERequestMethod.POST,
        `rest/${cur.getDirectEditRestUrl()}`,
        new RequestOptions().setHttpOptions({
          params,
        })
      )
      .subscribe((r) => {
        cur.setEnd(new Date(event.event.end)).setStart(new Date(event.event.start));
      });
  }

  /**
   * get current data
   * @returns Date
   */
  get currentDate(): Date {
    if (!this.calendarCmp || !this.calendarCmp.getApi()) {
      return null;
    }
    return this.calendarCmp.getApi().getDate();
  }

  /**
   * set calendar to previous month
   * @returns void
   */
  prev(): void {
    this.calendarCmp.getApi().prev();
  }

  /**
   * set calendar to next month
   * @returns void
   */
  next(): void {
    this.calendarCmp.getApi().next();
  }

  getNavigationBtnState(btnType: 'next' | 'prev') {
    if (!this.calendarCmp || !this.calendarCmp.getApi()) {
      return true;
    }

    const start: number = this.range.start ? (this.range.start as Date).getTime() : 0;
    const end: number = this.range.end ? (this.range.end as Date).getTime() : 0;

    if (btnType === 'prev' && start === 0) {
      return true;
    }

    const nextDate = this.currentDate;
    const nextValueNumber = btnType === 'next' ? 1 : -1;
    switch (this.calendarCmp.getApi().view.type) {
      case 'dayGridMonth':
        nextDate.setMonth(nextDate.getMonth() + nextValueNumber);
        break;
      case 'dayGridWeek':
        // nextDate.setMonth(nextDate.getMonth() + nextValueNumber);
        break;
      case 'timeGridDay':
        break;
      case 'listWeek':
        break;
    }

    return !(start <= nextDate.getTime() && nextDate.getTime() <= end);
  }
}
