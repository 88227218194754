import { DataManipulator } from '../../data-handler/data-tools/data-manipulator';
import { BestGantt } from '../../main';
import { GanttShiftDragLimiter } from '../shift-drag-limiter/shift-drag-limiter-executer';
import { GanttOverlay } from './overlay-executer';

/**
 * @constructor
 * @param {BestGantt} ganttDiagram
 */
export class GanttOverlayBlockRestrictor {
  ganttDiagram: BestGantt;
  shiftDragLimiter: GanttShiftDragLimiter;
  blocksInside: GanttOverlayInsideTimeSpan[];

  constructor(ganttDiagram) {
    this.ganttDiagram = ganttDiagram;

    this.shiftDragLimiter;
    this.blocksInside;
  }

  init() {
    this.shiftDragLimiter = new GanttShiftDragLimiter();
    this.shiftDragLimiter.initPlugIn(this.ganttDiagram);
  }

  /**
   * @param {GanttOverlayPlugInData[]} overlayDataset
   */
  registerRestrictions(overlayDataset) {
    const s = this;
    const foundRestriction = overlayDataset.find(function (dataItem) {
      return dataItem.restriction && dataItem.restriction.dragMode;
    });
    if (!foundRestriction) return;
    const blocksInside = this.getAllBlocksInsideTimeSpans(overlayDataset);
    s.blocksInside = blocksInside;
    this._insertDragModes(blocksInside);
  }

  /**
   * @private
   * @param {GanttOverlayRestriciton} restrictions
   * @param {GanttOverlayInsideTimeSpan[]} blocksInsideTimeSpan
   */
  _insertDragModes(blocksInsideTimeSpan) {
    for (let i = 0; i < blocksInsideTimeSpan.length; i++) {
      const restrictionData = blocksInsideTimeSpan[i];
      if (!restrictionData.overlay.restriction || !restrictionData.overlay.restriction.dragMode) continue;

      const dragMode = restrictionData.overlay.restriction.dragMode;
      if (dragMode.completeInside) {
        for (var j = 0; j < restrictionData.completelyIn.length; j++) {
          this.shiftDragLimiter.addShiftDragRestriction(restrictionData.completelyIn[j].id, dragMode.completeInside);
        }
      }
      if (dragMode.startInside) {
        for (var j = 0; j < restrictionData.startIn.length; j++) {
          this.shiftDragLimiter.addShiftDragRestriction(restrictionData.startIn[j].id, dragMode.startInside);
        }
      }
      if (dragMode.endInside) {
        for (var j = 0; j < restrictionData.endIn.length; j++) {
          this.shiftDragLimiter.addShiftDragRestriction(restrictionData.endIn[j].id, dragMode.endInside);
        }
      }
    }
  }

  /**
   * @param {GanttOverlayPlugInData[]} overlay
   */
  getAllBlocksInsideTimeSpans(overlay) {
    const s = this;
    const originData = s.ganttDiagram.getDataHandler().getOriginDataset();
    const shiftsInside = [];

    for (let i = 0; i < overlay.length; i++) {
      shiftsInside.push(new GanttOverlayInsideTimeSpan(overlay[i]));
    }

    DataManipulator.iterateOverDataSet(originData.ganttEntries, {
      filterBlocksInsideTimeSpan: function (child) {
        for (let i = 0; i < child.shifts.length; i++) {
          const shift = child.shifts[i];
          for (let j = 0; j < shiftsInside.length; j++) {
            const timeSpanData = shiftsInside[j];
            // both inside
            if (
              shift.timePointStart.getTime() >= timeSpanData.overlay.timeStart.getTime() &&
              shift.timePointEnd.getTime() <= timeSpanData.overlay.timeEnd.getTime()
            ) {
              timeSpanData.completelyIn.push(shift);
            }
            // start inside
            else if (
              shift.timePointStart.getTime() <= timeSpanData.overlay.timeEnd.getTime() &&
              shift.timePointStart.getTime() >= timeSpanData.overlay.timeStart.getTime() &&
              shift.timePointEnd.getTime() > timeSpanData.overlay.timeEnd.getTime()
            ) {
              timeSpanData.startIn.push(shift);
            }
            // end inside
            else if (
              shift.timePointEnd.getTime() >= timeSpanData.overlay.timeStart.getTime() &&
              shift.timePointEnd.getTime() <= timeSpanData.overlay.timeEnd.getTime() &&
              shift.timePointStart.getTime() < timeSpanData.overlay.timeStart.getTime()
            ) {
              timeSpanData.endIn.push(shift);
            }
          }
        }
      },
    });

    return shiftsInside;
  }

  /**
   * Removes all drag restrictions set by this instance.
   */
  removeAllRestrictions() {
    const s = this;
    s.shiftDragLimiter.removeAllShiftDragRestriction();
    s.blocksInside = null;
  }

  initRestrictions(overlayDataset) {
    const s = this;
    s.registerRestrictions(overlayDataset);
  }
}

/**
 * @constructor
 * @param {*}
 */
export class GanttOverlayInsideTimeSpan {
  overlay: GanttOverlay;
  completelyIn: any[];
  startIn: any[];
  endIn: any[];

  constructor(overlay) {
    this.overlay = overlay;
    this.completelyIn = [];
    this.startIn = [];
    this.endIn = [];
  }
}
