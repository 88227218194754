import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EGanttSubMenuUpdateMessageType } from '@app-modules/saxms-submenu-elements/saxms-submenu.enum';
import { IGanttSubMenuUpdateMessage } from '@app-modules/saxms-submenu-elements/saxms-submenu.interface';
import { SaxMsSubmenuService } from '@app-modules/saxms-submenu-elements/saxms-submenu.service';
import { ConfigService } from '@core/config/config.service';
import {
  SaxMsBestGanttActiveSubmenuEntryElementSetting,
  SaxMsBestGanttSettings,
} from 'frontend/src/dashboard/gantt/gantt/saxms-best-gantt.settings';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { debounceTime, delay, switchMap, takeUntil } from 'rxjs/operators';
import { GanttPluginHandlerService } from '../../plugin/gantt-plugin-handler.service';

/**
 * Service which provides interface to backend to store and recieve gantt settings.
 */
@Injectable()
export class GanttSettingsService {
  private _ganttSettings: SaxMsBestGanttSettings = new SaxMsBestGanttSettings();
  private _ganttID: string = null;
  private readonly _rest: string = 'rest/ganttsettings/';
  private $newSettingsSubject: BehaviorSubject<SaxMsBestGanttSettings> = new BehaviorSubject<SaxMsBestGanttSettings>(
    null
  );
  private _timeOutSubject: Subject<void> = new Subject<void>();
  private changedAttributes: string[] = [];

  constructor(private http: HttpClient, private configApi: ConfigService) {}

  /**
   * Save settings into backend.
   * @param settings Gantt settings which will be send to the server.
   */
  public saveSettings(): Observable<any> {
    if (!this._ganttID) {
      console.warn('No gantt ID set. Can not save settings!');
      return of(null);
    }

    return this._postSettingsToBackend(this._combineSettingsWithGanttID(this.getGanttSettings())).pipe(
      debounceTime(500) // debounce to handle multiple save executions in a short interval
    );
  }

  /**
   * Should be used to change attributes in the Gantt settings.
   * Remembers which attributes have been changed.
   * When saving the next time, only the changed attributes can be saved back to the backend.
   * Depending on config setting (saveOnlyChangedSettings)
   * @param attributes Attributes to be changed
   */
  public changeSettings(attributes: SaxMsBestGanttSettings) {
    for (const attribute in attributes) {
      this._ganttSettings[attribute] = attributes[attribute];
      if (!this.changedAttributes.includes(attribute)) {
        this.changedAttributes.push(attribute);
      }
    }
  }

  public notifyAboutNewSettings(settings: SaxMsBestGanttSettings): void {
    this.$newSettingsSubject.next(settings);
  }

  public onNewSettings(): Observable<SaxMsBestGanttSettings> {
    return this.$newSettingsSubject.asObservable();
  }

  /**
   * Get settings from backend.
   */
  public getSettingsFromBackend(): Observable<any> {
    return this._getSettings(this._ganttID);
  }

  /**
   * Integrate all properties of one settings object into another
   * @param originSettings Settings in which new settings will be integrated.
   * @param newSettings New settings.
   */
  public combineSettings(originSettings: any, newSettings: any): any {
    for (const key in originSettings) {
      if (newSettings.hasOwnProperty(key)) {
        originSettings[key] = newSettings[key];
      }
    }
    return originSettings;
  }

  /**
   * Injects settings into all plugins.
   * @param submenuService
   * @param actionHandler Necessary to get interface to inject settings all plugins.
   */
  public activeSubMenuElementsBySettings(
    submenuService: SaxMsSubmenuService,
    ganttPluginHandlerService: GanttPluginHandlerService
  ): void {
    const submenuElements: SaxMsBestGanttActiveSubmenuEntryElementSetting[] =
      this._ganttSettings.activeSubmenuEntryElements;
    ganttPluginHandlerService.injectSubmenuElements(submenuElements);
    for (const submenuElement of submenuElements) {
      const message: IGanttSubMenuUpdateMessage = {
        elementId: submenuElement.id,
        type: EGanttSubMenuUpdateMessageType.ACTION,
        value: submenuElement.value,
      };
      submenuService.triggerElementById(message);
    }
  }

  /**
   * Read settings from backend by given ganttID.
   * @param ganttID To get specific settings.
   */
  private _getSettings(ganttID: string): Observable<any> {
    return this.http.get(this._rest + `read/${ganttID}`);
  }

  /**
   * Saves settings to backend.
   * @param body Settings object.
   */
  private _postSettingsToBackend(body: any): Observable<any> {
    this._timeOutSubject.next();
    const bodyCopy = JSON.parse(JSON.stringify(body)); // use copy of body, as the data may change during the delay time

    // debounce save request
    return of(null).pipe(
      delay(1000),
      takeUntil(this._timeOutSubject),
      switchMap((_) => {
        let newBody;
        if (this.configApi.access().templates.Gantt.saveOnlyChangedSettings) {
          // set only necessary attributes to save
          newBody = { ganttID: this._ganttID };
          for (const attr of this.changedAttributes) {
            newBody[attr] = bodyCopy[attr];
          }
        } else {
          // set all attributes
          newBody = bodyCopy;
        }

        // clear changed attributes
        this.changedAttributes = [];
        return this.post(newBody);
      })
    );
  }

  /**
   * Post settings to backend.
   * @param settings
   * @returns
   */
  public post(settings: SaxMsBestGanttSettings) {
    return this.http.post(this._rest + `save`, settings);
  }

  /**
   * Adds gantt id to settings.
   * @param settings Gantt settings.
   */
  private _combineSettingsWithGanttID(settings: SaxMsBestGanttSettings): any {
    settings['ganttID'] = this._ganttID;
    return settings;
  }

  public setGanttID(ganttID: string) {
    this._ganttID = ganttID;
  }

  public getGanttSettings(): SaxMsBestGanttSettings {
    return this._ganttSettings;
  }

  public setGanttSettings(ganttSettings: SaxMsBestGanttSettings) {
    this._ganttSettings = ganttSettings;
    this.notifyAboutNewSettings(ganttSettings);
  }
}
