import { GanttResponse } from 'frontend/src/dashboard/gantt/general/response/responses/response';
import { GanttBlockFilterPlugIn } from '../block-filter';

/**
 * Responsible to refilter new blocks everytime
 * the backend provides them by response.
 */
export class GanttResponseBlockFilterUpdate extends GanttResponse {
  public handleResponse(response: any): boolean {
    if (!response.ganttEntries) return true;
    (this.executer as GanttBlockFilterPlugIn).filterAllBlocks(false);
    return true;
  }
}
