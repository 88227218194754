import { ShiftDataFinder } from '../data-handler/data-finder/shift-data-finder';

/**
 * Gantt Trashcan. TODO: Document this.
 * @class
 */
export class GanttTrash {
  removedShifts: any[];

  constructor() {
    this.removedShifts = [];
  }

  removeShiftsById(dataset, shiftIds) {
    const s = this;

    const newRemovedShifts = ShiftDataFinder.removeShiftsById(dataset, shiftIds);
    s.removedShifts = s.removedShifts.concat(newRemovedShifts);

    return dataset;
  }
}
