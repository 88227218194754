import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ContentService {
  public onCheck = new Subject<void>();

  public check(): void {
    this.onCheck.next(void 0);
  }
}
