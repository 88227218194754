import { Action } from '@app-modeleditor/components/button/action/action';
import { Button } from '@app-modeleditor/components/button/button';
import { EButtonDisplayType } from '@app-modeleditor/components/button/button-display-type.enum';
import { of } from 'rxjs';
import { Gantt_General } from '../../general.gantt.component';
import { GanttEssentialPlugIns } from '../../plugin/e-gantt-essential-plugins';
import { GanttXAxisManipulatorPlugIn } from '../../plugin/plugin-list/time-scaling/xaxis-manipulator';

export enum EZoomGanttButton {
  ZOOMIN = 'zoomIn',
  ZOOMOUT = 'zoomOut',
  ZOOMRESET = 'zoomReset',
  ZOOMALL = 'zoomAll',
  ZOOMCURRENTWEEK = 'zoomCurrentWeek',
  ZOOMCURRENTMONTH = 'zoomCurrentMonth',
  ZOOMCURRENTDAY = 'zoomCurrentDay',
}
export class ZoomElement extends Button {
  constructor(private scope: Gantt_General, private zoomGanttButton: EZoomGanttButton) {
    super();
    this.setDisplayBackground(false);
  }

  get(data: any): this {
    return this.setIcon(this.getZoomIcon())
      .setName(data.name)
      .setId(data.id)
      .setAlwaysEnabled(true)
      .setDisplayType(EButtonDisplayType.ICON_WITH_LABEL_BELOW)
      .setEnableBy(() => this.getEnableState())
      .chainActions(new Action().setCb(() => of(this.executeZoom(this.zoomGanttButton))));
  }

  private executeZoom(zoomGanttButton: EZoomGanttButton) {
    switch (zoomGanttButton) {
      case EZoomGanttButton.ZOOMIN:
        this.scope.ganttLibService.bestGantt.getXAxisBuilder().zoomIn();
        break;
      case EZoomGanttButton.ZOOMOUT:
        this.scope.ganttLibService.bestGantt.getXAxisBuilder().zoomOut();
        break;
      case EZoomGanttButton.ZOOMRESET:
      case EZoomGanttButton.ZOOMALL:
        this.resetZoom();
        break;
      case EZoomGanttButton.ZOOMCURRENTWEEK:
        this.goToCurrentWeek();
        break;
      case EZoomGanttButton.ZOOMCURRENTMONTH:
        this.goToCurrentMonth();
        break;
      case EZoomGanttButton.ZOOMCURRENTDAY:
        this.goToCurrentDay();
        break;
    }
  }

  private resetZoom() {
    const ganttDataset: any = this.scope.ganttLibService.bestGantt.getDataHandler().getOriginDataset();
    const timeManipulator: GanttXAxisManipulatorPlugIn = this.scope.ganttPluginHandlerService.getEssentialPlugIn(
      GanttEssentialPlugIns.XAxisManipulatorPlugIn
    );
    timeManipulator.changeTimeToInterval(new Date(ganttDataset.minValue), new Date(ganttDataset.maxValue));
  }

  private goToCurrentWeek(): void {
    const ganttDataset: any = this.scope.ganttLibService.bestGantt.getDataHandler().getOriginDataset();
    const week: Date[] = this.getWeekByDate(ganttDataset.currentTime || new Date());
    const timeManipulator: GanttXAxisManipulatorPlugIn = this.scope.ganttPluginHandlerService.getEssentialPlugIn(
      GanttEssentialPlugIns.XAxisManipulatorPlugIn
    );
    if (timeManipulator.getIsZoomForTimePointScrollEnabled()) {
      timeManipulator.changeTimeToInterval(new Date(week[0]), week[week.length - 1]);
    } else {
      this.scope.ganttLibService.bestGantt.getXAxisBuilder().scrollToTimePoint(new Date(week[0]));
    }
    this.scope.ganttLibService.bestGantt.getXAxisBuilder().runChangeTimeSpanCallback();
  }

  private goToCurrentMonth(): void {
    const timeManipulator: GanttXAxisManipulatorPlugIn = this.scope.ganttPluginHandlerService.getEssentialPlugIn(
      GanttEssentialPlugIns.XAxisManipulatorPlugIn
    );
    const ganttDataset: any = this.scope.ganttLibService.bestGantt.getDataHandler().getOriginDataset();
    const fullMonth = new Date();
    const fullMonthStart: Date = new Date(fullMonth.setDate(1));
    fullMonthStart.setHours(0, 0, 0);
    const fullMonthEnd = new Date(fullMonth.getFullYear(), fullMonth.getMonth() + 1, 0);
    fullMonthEnd.setHours(23, 59, 59);
    if (timeManipulator.getIsZoomForTimePointScrollEnabled()) {
      timeManipulator.changeTimeToInterval(fullMonthStart, fullMonthEnd);
    } else {
      this.scope.ganttLibService.bestGantt.getXAxisBuilder().scrollToTimePoint(fullMonthStart);
    }

    this.scope.ganttLibService.bestGantt.getXAxisBuilder().runChangeTimeSpanCallback();
  }

  private getZoomIcon() {
    switch (this.zoomGanttButton) {
      case EZoomGanttButton.ZOOMIN:
        return 'zoom_in';
      case EZoomGanttButton.ZOOMOUT:
        return 'zoom_out';
      case EZoomGanttButton.ZOOMRESET:
        return 'youtube_searched_for';
      case EZoomGanttButton.ZOOMALL:
        return 'zoom_out_map';
      case EZoomGanttButton.ZOOMCURRENTWEEK:
        return 'CALENDAR_WEEK';
      case EZoomGanttButton.ZOOMCURRENTMONTH:
        return 'CALENDAR_MONTH';
      case EZoomGanttButton.ZOOMCURRENTDAY:
        return 'today';
    }
  }

  private getEnableState(): boolean {
    switch (this.zoomGanttButton) {
      case EZoomGanttButton.ZOOMCURRENTMONTH:
        return this.isInsideCurrentMonth();
      case EZoomGanttButton.ZOOMCURRENTWEEK:
        return this.isInsideCurrentWeek();
      case EZoomGanttButton.ZOOMCURRENTDAY:
        return this.currentDayInGanttInterval();
      default:
        return true;
    }
  }

  private currentDayInGanttInterval(): boolean {
    const ganttDataset: any = this.scope.ganttLibService.bestGantt.getDataHandler().getOriginDataset();
    const currentTime = ganttDataset.currentTime || new Date();
    const fullDayStart = currentTime.setHours(0, 0, 0);
    const fullDayEnd = currentTime.setHours(23, 59, 59);

    const insideLowerBound: boolean = ganttDataset.minValue.getTime() < fullDayEnd;
    const insideUpperBound: boolean = ganttDataset.maxValue.getTime() > fullDayStart;

    return insideLowerBound && insideUpperBound;
  }

  private isInsideCurrentWeek(): boolean {
    const ganttDataset: any = this.scope.ganttLibService.bestGantt.getDataHandler().getOriginDataset();
    const fullWeek = this.getWeekByDate(ganttDataset.currentTime || new Date());
    return !(
      ganttDataset.minValue.getTime() > fullWeek[1] || ganttDataset.maxValue.getTime() < fullWeek[fullWeek.length - 1]
    );
  }

  private isInsideCurrentMonth(): boolean {
    const ganttDataset: any = this.scope.ganttLibService.bestGantt.getDataHandler().getOriginDataset();
    const fullMonth = new Date();
    const fullMonthStart: Date = new Date(fullMonth.setDate(1));
    fullMonthStart.setHours(0, 0, 0);
    const fullMonthEnd = new Date(fullMonth.getFullYear(), fullMonth.getMonth() + 1, 0);
    fullMonthEnd.setHours(23, 59, 59);
    const ganttStartTime = ganttDataset.minValue.getTime();
    const ganttEndTime = ganttDataset.maxValue.getTime();
    return (
      (fullMonthStart >= ganttStartTime && fullMonthStart <= ganttEndTime) ||
      (fullMonthEnd >= ganttStartTime && fullMonthEnd <= ganttEndTime) ||
      (fullMonthStart <= ganttStartTime && fullMonthEnd >= ganttEndTime)
    );
  }

  private goToCurrentDay(): void {
    const ganttDataset: any = this.scope.ganttLibService.bestGantt.getDataHandler().getOriginDataset();
    const timeManipulator: GanttXAxisManipulatorPlugIn = this.scope.ganttPluginHandlerService.getEssentialPlugIn(
      GanttEssentialPlugIns.XAxisManipulatorPlugIn
    );
    const currentTime = ganttDataset.currentTime || new Date();
    if (timeManipulator.getIsZoomForTimePointScrollEnabled()) {
      timeManipulator.changeTimeToInterval(
        new Date(currentTime.setHours(0, 0, 0)),
        new Date(currentTime.setHours(23, 59, 59))
      );
    } else {
      this.scope.ganttLibService.bestGantt.getXAxisBuilder().scrollToTimePoint(new Date(currentTime.setHours(0, 0, 0)));
    }
    this.scope.ganttLibService.bestGantt.getXAxisBuilder().runChangeTimeSpanCallback();
  }

  private getWeekByDate(fromDate: Date): Date[] {
    const formattedDate = new Date(fromDate.setHours(0, 0, 0));
    const sunday = new Date(formattedDate.setDate(formattedDate.getDate() - formattedDate.getDay() + 1)),
      result = [new Date(sunday)];
    while (sunday.setDate(sunday.getDate() + 1) && sunday.getDay() !== 1) {
      result.push(new Date(sunday));
    }
    result[result.length - 1] = new Date(result[result.length - 1].setHours(23, 59, 59));
    return result;
  }

  private getFullMonth(min: Date, max: Date) {
    if (min.getDate() > 1) {
      if (min.getMonth() === max.getMonth()) {
        return min;
      } else {
        return new Date(min.getFullYear(), min.getMonth() + 1, 1);
      }
    }
    return min;
  }
}
