import { inject, Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngxs/store';
import { RxState } from '@rx-angular/state';
import { selectSlice } from '@rx-angular/state/selections';
import { BaseTemplate } from 'frontend/src/dashboard/template/data-access/base/base-template';
import { TemplateState } from 'frontend/src/dashboard/template/data-access/template.state';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, takeWhile } from 'rxjs/operators';
import { TemplateActions } from '../../template/data-access/template.actions';
export interface LocalState<T extends BaseTemplate> {
  id: string | null;
  disabled: boolean;
  template: T | null;
}

@Injectable()
export class LocalStateService<C extends BaseTemplate, T extends LocalState<C>>
  extends RxState<T>
  implements OnDestroy
{
  private readonly store = inject(Store);

  private readonly resources$ = this.store.select(TemplateState.resources).pipe(takeWhile(() => this.isAlive));

  private isAlive = true; // important for subscriptions DO NOT REMOVE!

  private readonly id$ = this.select(
    selectSlice(['id']),
    map(({ id }) => id),
    filter((id) => id !== null)
    // tap(id => console.log('ID CHANGED TO', id)),
  );
  private readonly templates$ = this.store.select(TemplateState.templates).pipe(
    takeWhile(() => this.isAlive),
    distinctUntilChanged()
    // tap(id => console.log('TEMPLATES CHNAGED TO', id, this.uuid)),
  );

  private readonly template$: Observable<C> = combineLatest([this.templates$, this.id$]).pipe(
    map(([items, id]) => items.find((item) => item.id === id) as C)
  );

  disabled$ = this.select(
    selectSlice(['disabled']),
    map(({ disabled }) => disabled)
  ).pipe(takeWhile(() => this.isAlive));
  item$: Observable<C> = this.select(
    selectSlice(['template']),
    map(({ template }) => template),
    filter((item) => item !== null)
  ).pipe(takeWhile(() => this.isAlive));

  private readonly disabledState$: Observable<boolean> = combineLatest([this.item$, this.resources$]).pipe(
    takeWhile(() => this.isAlive),
    map(([item, resources]) => resources.find((r) => r.id === item.resourceId)),
    map((resource) => (resource?.editable === false ? true : false))
  );

  constructor() {
    super();
    const t = { id: null, template: null, disabled: true } as T;
    this.set(t);
    this.connect('template', this.template$);
    this.connect('disabled', this.disabledState$);
  }

  ngOnDestroy(): void {
    this.store.dispatch(new TemplateActions.RemoveTemplate(this.get('id')));
    this.isAlive = false;
  }
}
