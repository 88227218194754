import { GanttActionWrapper } from 'frontend/src/dashboard/gantt/general/gantt-actions/external-action-registration';
import { GanttEventTrigger } from 'frontend/src/dashboard/gantt/general/gantt-actions/external-event-trigger';
import { EGanttActionEvent } from 'frontend/src/dashboard/gantt/general/gantt-actions/gantt-action-handling/gantt-action-event.enum';
import { takeWhile } from 'rxjs/operators';
import { GanttBlockingIntervalsMode } from '../../blocking-intervals.mode';

/**
 * Action handling of blocking interval drag end event.
 * DragEnd means the dragging of an existing block in the edit mode.
 */
export class BlockingIntervalEditDragEndEvent extends GanttEventTrigger {
  public readonly eventName: EGanttActionEvent = EGanttActionEvent.MOVE_END;

  /**
   * Adds dragEndBeforeMarkerAdding-callback to gantt to save template data and execute actions.
   * the callback will be registered by the (backend) given action id.
   * @param ganttDiagram BestGantt where callbacks will be registered inside.
   */
  public registerInGantt(): void {
    this.executer.addEditDragEndCallback(this.action.id, (dragEndParam) => {
      if (dragEndParam.hasBeenDragged) {
        const block = dragEndParam.event.subject;
        const blockdata = this.executer.getMarkedPeriodById(block.id);
        this.saveDataToTemplateDragEndExistingBlock(blockdata, this._ganttLibService.bestGantt);
        const actionWrapper: GanttActionWrapper = {
          actionScope: this.action.actionScope,
          actions: this.action.actions,
        };
        this._ganttLibService.ngZone.run(() => {
          this.actionHandler
            .executeActions(actionWrapper)
            .pipe(takeWhile(() => this.actionHandler.isAlive()))
            .subscribe((data) => {
              this.responseHandler.handleUpdateNotification(data as any);
            });
        });
      }
    });
  }

  /**
   * Removes dragEndBeforeMarkerAdding callback from gantt.
   * @param ganttDiagram BestGantt where callbacks will be removed from.
   */
  public removeFromGantt(): void {
    super.removeFromGantt();
    this.executer.removeEditDragEndCallback(this.action.id);
  }

  public saveDataToTemplateDragEndExistingBlock(blockData: any, ganttDiagram: any): void {
    // set temaplate data
    this.templateData.setTargetRow(blockData.yId);

    this.templateData.setSelectedblock({
      // TODO: must be removed as soon as the backend uses the selectedTimePeriodInput and selectedTimePeriodInputGroup attribute (14.06.21)
      id: blockData.id,
      end: blockData.dateEnd.getTime(),
      start: blockData.dateStart.getTime(),
      ganttTimePeriodGroupIntervalInputId: this.executer.additionalData.ganttTimePeriodGroupIntervalInputId,
    });

    this.templateData.setSelectedTimePeriodInput({
      id: blockData.id,
      groupId: this.executer.additionalData.ganttTimePeriodGroupIntervalInputId,
      type: this.executer.additionalData.type,
      end: blockData.dateEnd.getTime(),
      start: blockData.dateStart.getTime(),
    });

    this.templateData.setSelectedTimePeriodInputGroupId(
      this.executer.additionalData.ganttTimePeriodGroupIntervalInputId
    );

    return;
  }

  /**
   * Checks if action will be executed by plugin mode.
   * @param activatedByAction Action to execute.
   */
  private isInCorrectPlugInMode(activatedByAction: any): boolean {
    if (!activatedByAction) return true;
    switch (activatedByAction.id) {
      case GanttBlockingIntervalsMode.CREATE:
        return this.executer.isActive();
      case GanttBlockingIntervalsMode.EDIT:
        return this.executer.isInEditMode();
    }
  }
}
