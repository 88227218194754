import * as d3 from 'd3';
import { Observable, Subject, filter, takeUntil } from 'rxjs';
import { ShiftDataFinder } from '../../data-handler/data-finder/shift-data-finder';
import { YAxisDataFinder } from '../../data-handler/data-finder/yaxis-data-finder';
import { GanttCanvasRow, GanttCanvasShift, GanttDataShift } from '../../data-handler/data-structure/data-structure';
import { ShiftDataSorting } from '../../data-handler/data-tools/data-sorting';
import { GanttScrollContainerEvent } from '../../html-structure/scroll-container-event';
import { BestGantt } from '../../main';
import { IShiftDragEvent } from '../../shifts/shift-events.interface';
import { ETimeGradationRoundingType } from '../../time-gradation-handler/time-gradation-handler';
import { ETimeMarkerAnchor } from '../../x-axis/x-axis';
import { EGanttShiftEditEventType } from '../shift-edit-general/edit-events/shift-edit-event-type.enum';
import { GanttShiftEditor } from '../shift-edit-general/shift-editor';
import { GanttShiftsMultiDragHandler } from './multi-drag/shifts-multi-drag-handler';
import { GanttScrollDuringTranslation } from './scroll-handling/scroll-during-translation';
import {
  IGanttShiftEditEventAdditionalData,
  IGanttShiftTranslationEvent,
  IGanttShiftTranslationManipulationEvent,
  ITypedShiftDragEvent,
} from './translation-events/translation-event.interface';
import { GanttShiftTranslationAreaLimiter } from './translation-restrictions/shift-translation-area-limiter';
import { GanttShiftTranslationLimiter } from './translation-restrictions/shift-translation-limiter';
import { GanttShiftTranslationRestrictionVisualizer } from './translation-restrictions/shift-translation-restriction-visualizer';
import { GanttShiftTranslationRowLimiter } from './translation-restrictions/shift-translation-row-limiter';
import { IGanttShiftTranslationRaster } from './translation-restrictions/translation-raster/translation-raster.interface';
import { IGanttShiftTranslationStateModel } from './translation-state/translation-state-model.interface';

/**
 * Core Class for shift translation handling (normally triggered by shift drag and drop).
 */
export class GanttShiftTranslator extends GanttShiftEditor<
  ITypedShiftDragEvent,
  IGanttShiftTranslationEvent,
  IGanttShiftTranslationStateModel,
  GanttShiftTranslationLimiter,
  GanttShiftTranslationRestrictionVisualizer
> {
  private _canvas: d3.Selection<SVGGElement, undefined, d3.BaseType, undefined>;

  private _translationScrollHandler: GanttScrollDuringTranslation;
  private _translationMultiDragHandler: GanttShiftsMultiDragHandler;
  private _dragRaster: IGanttShiftTranslationRaster = { dragRasterization: null, dragRasterStart: null };

  private _onShiftTranslationManipulationSubject = new Subject<IGanttShiftTranslationManipulationEvent>();

  constructor(ganttDiagram: BestGantt) {
    const initialShiftEditState: IGanttShiftTranslationStateModel = {
      originShiftData: null,
      dragStartRow: null,

      hasBeenDragged: false,

      currentShiftPos: [0, 0],

      shiftEndPoint: 0,
      differenceToShiftEnd: 0,

      dragStartPosition: [0, 0],
      dragEndPosition: [0, 0],
      dragEndViewportPosition: [0, 0],
      dragCoordinate: [0, 0],

      ghostShiftOpacityBackup: null,

      initialDragStartScrollTop: null,

      differenceBetweenFirstChainedElement: 0,
      differenceBetweenLastChainedElement: 0,
      differenceBetweenFirstHorizontalChainedElement: 0,
      differenceBetweenLastHorizontalChainedElement: 0,
      blockChainedElementsX: false,
      blockChainedElementsY: false,

      visualizeNotAllowed: false,
    };
    super(
      ganttDiagram,
      initialShiftEditState,
      new GanttShiftTranslationLimiter(ganttDiagram),
      new GanttShiftTranslationRestrictionVisualizer(ganttDiagram)
    );

    this._translationScrollHandler = new GanttScrollDuringTranslation(this._ganttDiagram);
    this._translationMultiDragHandler = new GanttShiftsMultiDragHandler(this._ganttDiagram, this);
  }

  /**
   * Enabled shift drag event handling.
   * @override
   * @param minStepWidth If specified, this value will be the step width of the drag raster.
   * @param dragRasterStart If specified, this value will be the origin of the drag raster.
   */
  public build(minStepWidth: number = null, dragRasterStart: number = null): void {
    // initialize restriction visualizer
    this._shiftEditRestrictionVisualizer.init(this);

    // initialize drag raster
    this._translationScrollHandler.init(this);

    if (minStepWidth != null) {
      this._dragRaster.dragRasterization = minStepWidth;
    }

    if (dragRasterStart != null) {
      this._dragRaster.dragRasterStart = dragRasterStart;
    }

    // shift drag events
    this._ganttDiagram
      .getShiftFacade()
      .shiftDragStart()
      .pipe(takeUntil(this.onDestroy))
      .subscribe((event) => this._startTranslationCb(event));
    this._ganttDiagram
      .getShiftFacade()
      .shiftDragging()
      .pipe(takeUntil(this.onDestroy))
      .subscribe((event) => this._updateTranslationCb(event));
    this._ganttDiagram
      .getShiftFacade()
      .shiftDragEnd()
      .pipe(takeUntil(this.onDestroy))
      .pipe(filter((event) => event.event.hasBeenDragged))
      .subscribe((event) => this._finishTranslationCb(event));
  }

  //
  // TRANSLATION EVENT CALLBACKS
  //

  /**
   * Callback to handle shift translation start.
   * @keywords callback, translation, drag, dragstart, shift
   * @param event Drag start event which triggered this callback.
   */
  private _startTranslationCb(event: GanttScrollContainerEvent<IShiftDragEvent>): void {
    const draggedShiftData: GanttCanvasShift = event.event.target.data()[0];

    // deselect all shifts if a shift is dragged witch is not selected
    if (!draggedShiftData.selected) this._ganttDiagram.deselectAllShifts();

    // ignore event if translation is not allowed for this shift
    if (this._shiftEditLimiterAdapter.hasNoDragIdByShiftId(draggedShiftData.id)) {
      return;
    }

    this._ganttDiagram.getShiftFacade().closeTooltip();

    // perform actual shift translation start
    this.start(event);

    this._handleChainedShiftsDragStart(event.event.event, draggedShiftData);
    this._renderGhostShiftDuringDrag(draggedShiftData);

    this._shiftEditState.set({
      initialDragStartScrollTop: parseInt(this._ganttDiagram.getShiftFacade().getShiftCanvas().node().style.top) || 0,
    });

    this._triggerEvent(EGanttShiftEditEventType.START, event);

    this._ganttDiagram.rerenderShiftsVertical();
  }

  /**
   * Callback to handle shift translation.
   * @keywords callback, translation, drag, dragging, shift
   * @param event Drag update event which triggered this callback.
   */
  private _updateTranslationCb(event: GanttScrollContainerEvent<IShiftDragEvent>): void {
    const draggedShiftData: GanttCanvasShift = event.event.target.data()[0];

    // ignore event if translation is not allowed for this shift
    if (this._shiftEditLimiterAdapter.hasNoDragIdByShiftId(draggedShiftData.id)) {
      return;
    }

    this._ganttDiagram.getShiftFacade().closeTooltip();
    this._ganttDiagram.getXAxisBuilder().removeAllDateMarkers();
    this._ganttDiagram.getShiftResizer().removeAllResizeHandles();

    // perform actual shift translation update
    this.update(event);

    this._triggerEvent(
      EGanttShiftEditEventType.UPDATE,
      event,
      { on: true },
      { newCoordinates: this._shiftEditState.get('dragCoordinate') }
    );
  }

  /**
   * Callback to handle shift translation.
   * @keywords callback, translation, drag, dragend, shift
   * @param event Drag end event which triggered this callback.
   */
  private _finishTranslationCb(event: GanttScrollContainerEvent<IShiftDragEvent>): void {
    const draggedShiftData: GanttCanvasShift = event.event.target.data()[0];

    // ignore event if translation is not allowed for this shift
    if (this._shiftEditLimiterAdapter.hasNoDragIdByShiftId(draggedShiftData.id)) {
      return;
    }

    // trigger translation manipulation subject
    const manipulationEvent = this._buildManipulationEvent(event);
    this._onShiftTranslationManipulationSubject.next(manipulationEvent);

    // perform actual shift translation end
    const e = event.event as ITypedShiftDragEvent;
    e.type = EGanttShiftEditEventType.END;
    const finalCoordinates = this._finish(e, manipulationEvent.yManipulation);

    if (!finalCoordinates) return;

    this._resetGhostShiftOnDragEnd(draggedShiftData);

    const chainedElements = this._chainHandler.getCachedElements();
    const allMovingElements = [event.event.target, ...chainedElements];
    this._chainHandler.getCachedElements().forEach((elem) => elem.attr('class', 'gantt_shifts')); // reset class of chained shifts (important for drag mechanism)

    const targetRow = YAxisDataFinder.getRowByYPosition(this._dataHandler.getYAxisDataset(), finalCoordinates[1]);
    const isAnyMovingShiftBlocked = this._shiftEditLimiterAdapter.isAnyMovingShiftBlocked(allMovingElements, targetRow);

    if (isAnyMovingShiftBlocked) {
      this._resetPositionsOfAllMovingElements(allMovingElements);

      const hasBeenDragged = this._shiftEditState.get('hasBeenDragged');
      this._triggerEvent(
        EGanttShiftEditEventType.END,
        event,
        { on: true, after: true },
        { dragEndRowId: targetRow.id, hasBeenBlocked: isAnyMovingShiftBlocked, hasBeenDragged: hasBeenDragged }
      );

      this._shiftEditState.reset();
      return;
    }

    this._roundYValuesOnShifts(allMovingElements, targetRow);

    const gridDifference =
      this._shiftEditState.get('dragStartPosition')[0] -
      this._currentScale(this._shiftEditState.get('originShiftData').timePointStart);
    const positionDifference = this._getPositionDifference(event.event.target);
    const newTargetParentRowId = this._updateTargetData(draggedShiftData, positionDifference, gridDifference);
    const newRowIdsOfChainedElements = this._updateChainedElements(
      draggedShiftData,
      positionDifference,
      gridDifference
    );

    const allNewParentRowIds = Array.from(new Set([newTargetParentRowId, ...newRowIdsOfChainedElements]));

    this._sortDatasets(allNewParentRowIds, draggedShiftData);

    // trigger events
    const hasBeenDragged = this._shiftEditState.get('hasBeenDragged');
    this._triggerEvent(
      EGanttShiftEditEventType.END,
      event,
      { on: true, after: true },
      { dragEndRowId: targetRow.id, hasBeenBlocked: isAnyMovingShiftBlocked, hasBeenDragged: hasBeenDragged }
    );

    this._shiftEditState.reset();

    this._ganttDiagram.getTooltipBuilder().removeAllTooltips();

    this._ganttDiagram.getShiftFacade().getShiftBuilder().setLastDragEvent(event.event.event);
    this._ganttDiagram.getXAxisBuilder().removeAllDateMarkers();
    this._ganttDiagram.rerenderShiftsVertical();
  }

  //
  // TRANSLATION EVENT HANDLING
  //

  /**
   * Handles the start of a shift translation.
   * @param event Translation start event.
   */
  public start(event: GanttScrollContainerEvent<IShiftDragEvent>): void {
    const e = event.event as ITypedShiftDragEvent;
    e.type = EGanttShiftEditEventType.START;

    this._start(e);
  }

  /**
   * Handles the update of a shift translation.
   * @param event Translation update event.
   */
  public update(event: GanttScrollContainerEvent<IShiftDragEvent>): void {
    const e = event.event as ITypedShiftDragEvent;
    e.type = EGanttShiftEditEventType.UPDATE;

    this._update(e);
  }

  /**
   * Handles the end of a shift translation.
   * @param event Translation end event.
   */
  public finish(event: GanttScrollContainerEvent<IShiftDragEvent>): void {
    const e = event.event as ITypedShiftDragEvent;
    e.type = EGanttShiftEditEventType.END;

    this._finish(e);
  }

  protected _start(event: ITypedShiftDragEvent): void {
    const draggedShiftData: GanttCanvasShift = event.target.data()[0];

    // remove clipping from shift
    event.target.attr('clip-path', null);

    // determine shift and row data of dragged shift
    const foundShiftData = ShiftDataFinder.getShiftById(
      this._dataHandler.getOriginDataset().ganttEntries,
      draggedShiftData.id
    );
    const originShiftData = foundShiftData.shift;
    const dragStartRow = foundShiftData.shiftRow;
    this._shiftEditState.set({ originShiftData: originShiftData, dragStartRow: dragStartRow });

    // add dragging element into a overlayer-group
    // to hold it in foreground
    this._moveSVGShiftToForeground(event.target);

    // set drag opacity on target before dragging
    event.target.style('opacity', draggedShiftData.opacity * this._ganttConfig.shiftDragOpacity());

    const initialX = parseFloat(event.target.attr('x'));
    const initialY = parseFloat(event.target.attr('y'));
    this._shiftEditState.set({ dragStartPosition: [initialX, initialY] });

    // time gradation handling -> set ref date if customized enum time grid
    if (
      this._timeGradationHandler.isStepSizeEnum() &&
      this._timeGradationHandler.getDefaultTimeGrid() === 'customized'
    ) {
      this._timeGradationHandler.setCustomizedStartDate(originShiftData.timePointStart);
    }

    this._shiftEditState.set({
      currentShiftPos: [initialX, initialY],
      dragCoordinate: [initialX, initialY],
    });

    const initialWidth = parseFloat(event.target.attr('width'));
    this._shiftEditState.set({
      shiftEndPoint: initialWidth + initialX,
      differenceToShiftEnd: initialWidth - event.event.x,
    });
  }

  protected _update(event: ITypedShiftDragEvent): void {
    const dragCoordinates = this._shiftEditState.get('dragCoordinate');
    const originDragCoordinates: [number, number] = [dragCoordinates[0], dragCoordinates[1]];

    // correctify drag.x-Event
    const currentShiftPos = this._shiftEditState.get('currentShiftPos');
    const eventX = currentShiftPos[0] + event.event.dx;
    const eventY = currentShiftPos[1] + event.event.dy;
    this._shiftEditState.set({ currentShiftPos: [eventX, eventY] });

    // check restrictions & manipulate drag coordinates
    const dragX = eventX;
    const dragY = eventY;

    const restrictedDragCoordinates = this._getRestrictedDragCoordinates(dragX, dragY, event);
    if (this._shiftEditLimiterAdapter.allowDragHorizontal) dragCoordinates[0] = restrictedDragCoordinates[0];
    if (this._shiftEditLimiterAdapter.allowDragVertical) dragCoordinates[1] = restrictedDragCoordinates[1];

    this._shiftEditState.set({ hasBeenDragged: true });

    // apply drag coordinates on shift
    const shiftStrokeWidth = parseFloat(event.target.attr('stroke-width'));
    event.target.attr('x', dragCoordinates[0]).attr('y', dragCoordinates[1] + shiftStrokeWidth / 2);

    const dragStartCoordinates = this._shiftEditState.get('dragStartPosition');
    this._shiftGroupOverlay
      .selectAll('.additionalShiftElements')
      .attr(
        'transform',
        `translate(${dragCoordinates[0] - dragStartCoordinates[0]},${dragCoordinates[1] - dragStartCoordinates[1]})`
      );

    this._moveShiftOnHoverElementToPosition(dragCoordinates[0], dragCoordinates[1]);
    const diffX = dragCoordinates[0] - originDragCoordinates[0];
    const diffY = dragCoordinates[1] - originDragCoordinates[1];

    // moving chained elements
    this._translateChainedElements(diffX, diffY, event.event);

    // mark relevant shift dates
    this._markShiftDates(event);

    // handle restriction visualization
    this._handleRestrictionVisualization(event);
  }

  /**
   * Handles shift edit end events.
   * Note: Unlike its declaration, this method returns values for internal purposes.
   * @param event Edit end event.
   * @returns Final drag coordinates of the shift translation.
   */
  protected _finish(event: ITypedShiftDragEvent, yManipulation = 0): [number, number] {
    const draggedShiftData: GanttCanvasShift = event.target.data()[0];

    event.target.attr('class', 'gantt_shifts'); // !important for shift dragging mechanism

    // reset drag opacity on target after dragging
    event.target.style('opacity', draggedShiftData.opacity / this._ganttConfig.shiftDragOpacity());

    let targetRow: GanttCanvasRow = null;
    if (this._shiftEditLimiterAdapter.allowDragVertical) {
      targetRow = this._renderDataHandler
        .getYAxisDataFinder()
        .getRowByPageY(event.event.sourceEvent.pageY, yManipulation);
    } else {
      // if vertical drag is blocked -> take start row
      targetRow = this._dataHandler.getYAxisDataset().find((e) => e.id === this._shiftEditState.get('dragStartRow').id);
    }

    const dragCoordinates = this._shiftEditState.get('dragCoordinate');

    const finalViewportCoordinates: [number, number] = [0, 0];
    finalViewportCoordinates[0] = dragCoordinates[0];
    finalViewportCoordinates[1] = this._renderDataHandler.getYAxisDataFinder().getRowViewportY(targetRow.id);

    const finalCoordinates: [number, number] = [this._shiftEditState.get('dragCoordinate')[0], targetRow.y];

    event.target.attr('x', finalViewportCoordinates[0]).attr('y', finalViewportCoordinates[1]);

    this._moveShiftOnHoverElementToPosition(
      finalViewportCoordinates[0],
      finalViewportCoordinates[1] + this._ganttConfig.getLineTop()
    );

    this._shiftEditState.set({
      dragEndPosition: [finalCoordinates[0], finalCoordinates[1] + this._ganttConfig.getLineTop()],
      dragEndViewportPosition: [
        finalViewportCoordinates[0],
        finalViewportCoordinates[1] + this._ganttConfig.getLineTop(),
      ],
    });

    this._moveSVGShiftToBackground(event.target);

    return finalCoordinates;
  }

  //
  // RESTRICTION HANDLING
  //

  /**
   * Checks all translation restrictions and calculates drag coordinates which do not violate any restrictions.
   * @param dragX X coordinate as received from the drag event.
   * @param dragY Y coordinate as received from the drag event.
   * @param event Event data of the dragged shift.
   * @returns Drag coordinates which do not violate any resize restrictions.
   */
  private _getRestrictedDragCoordinates(dragX: number, dragY: number, event: ITypedShiftDragEvent): [number, number] {
    const draggedShiftData = event.target.data()[0];
    const shiftStrokeWidth = parseFloat(event.target.attr('stroke-width'));

    const drag1 = [dragX, dragY];

    // 1) Handle time gradation.
    const drag2 = [drag1[0] - shiftStrokeWidth / 2, drag1[1]];
    if (this._shiftEditLimiterAdapter.allowDragHorizontal && this._dragRaster.dragRasterization) {
      drag2[0] = this._shiftEditLimiterAdapter.getGradiatedXCoordinate(drag2[0]);
    }
    drag2[0] += shiftStrokeWidth / 2;

    // 2) If existing, apply sticky restrictions.
    const drag3 = [drag2[0], drag2[1]];
    drag3[0] = this._getStickyShiftXCoordinate(drag2[0], event.target);

    // 3) Check if coordinates are outside the shift canvas.
    const canvasWidth = parseFloat(this._shiftCanvas.attr('width'));
    const canvasHeight = parseFloat(this._shiftCanvas.style('height'));
    const shiftWidth = parseFloat(event.target.attr('width'));
    const shiftHeight = parseFloat(event.target.attr('height'));

    const drag4 = this._getShiftCoordinatesInsideContainer(
      drag3[0],
      drag3[1],
      canvasWidth,
      canvasHeight,
      shiftWidth,
      shiftHeight
    );

    // if translation got blocked -> visualize it by changing the cursor style
    this._blockTranslationCursorIfVisibleDiff(drag3[0], drag4[0]);
    this._blockTranslationCursorIfVisibleDiff(drag3[1], drag4[1]);

    // 4) Check coordinates for violated ES/LE restrictions.
    const drag5: [number, number] = [drag4[0], drag4[1]];
    drag5[0] = this._getEsLeConformingXCoordinate(
      drag5[0] - shiftStrokeWidth / 2,
      shiftWidth + shiftStrokeWidth,
      draggedShiftData
    );
    drag5[0] += shiftStrokeWidth / 2;
    this._shiftEditState.set({ blockChainedElementsX: drag4[0] !== drag5[0] });

    // if translation got blocked -> visualize it by changing the cursor style
    this._blockTranslationCursorIfVisibleDiff(drag4[0], drag5[0]);
    this._blockTranslationCursorIfVisibleDiff(drag4[1], drag5[1]);

    return drag5;
  }

  /**
   * Sticky shift handling during drag.
   * If sticky restrictions apply to dragged shift width and x values are updated.
   * @param dragX X coordinate as received from the drag event.
   * @param shiftSelection D3 selection of the dragged shift.
   * @returns Drag coordinates conforming to sticky shift restrictions.
   */
  private _getStickyShiftXCoordinate(
    dragX: number,
    shiftSelection: d3.Selection<SVGRectElement, GanttCanvasShift, d3.BaseType, undefined>
  ): number {
    const shiftData = shiftSelection.data()[0];
    let newX = dragX;

    if (!this._shiftEditLimiterAdapter.shiftHasNoStickyRestrictions(shiftData.id)) {
      let newWidth = parseFloat(shiftSelection.attr('width'));

      if (this._shiftEditLimiterAdapter.shiftHasStickyStart(shiftData.id)) {
        // fix startpoint
        newX = this._shiftEditState.get('dragStartPosition')[0];
        newWidth = dragX + this._shiftEditState.get('differenceToShiftEnd');
      }

      if (this._shiftEditLimiterAdapter.shiftHasStickyEnd(shiftData.id)) {
        // fix endpoint
        newWidth = this._shiftEditState.get('shiftEndPoint') - newX;
      }

      shiftSelection.attr('width', newWidth);
    }

    return newX;
  }

  /**
   * Checks if the shift is dragged out of the shift container and corrects its x and y values if neccesary.
   * @param dragX X coordinate as received from the drag event.
   * @param dragY Y coordinate as received from the drag event.
   * @param canvasWidth Width of the shift canvas.
   * @param canvasHeight Height of the shift canvas.
   * @param shiftWidth Shift width.
   * @param shiftHeight Shift height.
   * @returns Drag coordinates conforming to shift-drag-out-of-container rules.
   */
  private _getShiftCoordinatesInsideContainer(
    dragX: number,
    dragY: number,
    canvasWidth: number,
    canvasHeight: number,
    shiftWidth: number,
    shiftHeight: number
  ): [number, number] {
    const dragCoordinates: [number, number] = [dragX, dragY];
    const timespanRefDate = this._currentScale.domain()[0];
    const minVisibleShiftRemainderWidth =
      this._currentScale(new Date(timespanRefDate.getTime() + this._ganttConfig.getMinShiftRemainderTimespan())) -
      this._currentScale(timespanRefDate);

    // shift out of box left side
    if (
      dragX - this._shiftEditState.get('differenceBetweenFirstChainedElement') <
        0 + -1 * shiftWidth + minVisibleShiftRemainderWidth &&
      this._shiftEditLimiterAdapter.allowDragHorizontal
    ) {
      dragCoordinates[0] =
        0 +
        -1 * shiftWidth +
        minVisibleShiftRemainderWidth +
        this._shiftEditState.get('differenceBetweenFirstChainedElement');
      dragCoordinates[0] = this._shiftEditLimiterAdapter.getGradiatedXCoordinate(
        dragCoordinates[0],
        ETimeGradationRoundingType.UP
      );
      this._shiftEditState.set({ blockChainedElementsX: true });
    } else {
      this._shiftEditState.set({ blockChainedElementsX: false });
    }

    // shift out of box right side
    if (
      dragX + minVisibleShiftRemainderWidth + this._shiftEditState.get('differenceBetweenLastChainedElement') >=
        canvasWidth &&
      this._shiftEditLimiterAdapter.allowDragHorizontal
    ) {
      dragCoordinates[0] =
        canvasWidth - minVisibleShiftRemainderWidth - this._shiftEditState.get('differenceBetweenLastChainedElement');
      dragCoordinates[0] = this._shiftEditLimiterAdapter.getGradiatedXCoordinate(
        dragCoordinates[0],
        ETimeGradationRoundingType.DOWN
      );
      this._shiftEditState.set({ blockChainedElementsX: true });
    }

    // shift out of box upper side
    if (
      dragCoordinates[1] - this._shiftEditState.get('differenceBetweenFirstHorizontalChainedElement') < 0 &&
      this._shiftEditLimiterAdapter.allowDragVertical
    ) {
      dragCoordinates[1] = 0 + this._shiftEditState.get('differenceBetweenFirstHorizontalChainedElement');
      this._shiftEditState.set({ blockChainedElementsY: true });
    } else {
      this._shiftEditState.set({ blockChainedElementsY: false });
    }

    // shift out of box lower side
    if (
      dragY + this._shiftEditState.get('differenceBetweenLastHorizontalChainedElement') > canvasHeight - shiftHeight &&
      this._shiftEditLimiterAdapter.allowDragVertical
    ) {
      dragCoordinates[1] =
        canvasHeight - shiftHeight - this._shiftEditState.get('differenceBetweenLastHorizontalChainedElement');
      this._shiftEditState.set({ blockChainedElementsY: true });
    }

    return dragCoordinates;
  }

  /**
   * Checks the specified x coordinate if it violates ES/LE restrictions and corrects it if necessary.
   * @param x X coordinate as received from the drag event.
   * @param shiftWidth Width of the dragged shift.
   * @param shift Canvas data of the dragged shift.
   * @returns Drag x value conforming to the ES/LE restrictions of the shift.
   */
  private _getEsLeConformingXCoordinate(x: number, shiftWidth: number, shift: GanttCanvasShift): number {
    x = this._shiftEditLimiterAdapter.getEarliestStartConformingXCoordinate(
      x - this._shiftEditState.get('differenceBetweenFirstChainedElement'),
      shift
    );
    x =
      this._shiftEditLimiterAdapter.getLatestEndConformingXCoordinate(
        x + shiftWidth + this._shiftEditState.get('differenceBetweenLastChainedElement'),
        shift
      ) - shiftWidth;
    return x;
  }

  //
  // RESTRICTION VISUALIZATION
  //

  /**
   * Sets the flag for visualizing violated restrictions if the specified pixel values have a visible difference.
   * @param val1 1st pixel value.
   * @param val2 2nd pixel value.
   */
  private _blockTranslationCursorIfVisibleDiff(val1: number, val2: number): void {
    if (this._shiftEditLimiterAdapter.isVisiblePixelDiff(val1, val2)) {
      this._shiftEditState.set({ visualizeNotAllowed: true });
    }
  }

  /**
   * Handles the visualization of violated restrictions depending on the current value of the corresponing state.
   * @param event Event data to use for the visualization.
   */
  private _handleRestrictionVisualization(event: ITypedShiftDragEvent): void {
    const tolerance = this._ganttConfig.shiftEditVisualizeNotAllowedTolerance();
    let visualizeHorizontal = false;
    let visualizeVertical = false;

    if (this._shiftEditLimiterAdapter.allowDragHorizontal) {
      const mouseX = event.event.sourceEvent.offsetX;
      const shiftX = parseFloat(event.target.attr('x'));
      const shiftWidth = parseFloat(event.target.attr('width'));
      visualizeHorizontal = mouseX < shiftX - tolerance || mouseX > shiftX + shiftWidth + tolerance;
    }
    if (this._shiftEditLimiterAdapter.allowDragVertical) {
      const mouseY = event.event.sourceEvent.offsetY;
      const shiftY = parseFloat(event.target.attr('y'));
      const shiftHeight = parseFloat(event.target.attr('height'));
      visualizeVertical = mouseY < shiftY - tolerance || mouseY > shiftY + shiftHeight + tolerance;
    }

    if (this._shiftEditState.get('visualizeNotAllowed') && (visualizeHorizontal || visualizeVertical)) {
      this._shiftEditRestrictionVisualizer.block();
    } else {
      this._shiftEditRestrictionVisualizer.unblock();
    }

    this._shiftEditState.set({ visualizeNotAllowed: false });
  }

  //
  // EVENT BUILDING
  //

  /**
   * Triggers an output event by the given data.
   * @param type Type of event (start, update or end).
   * @param originEvent Input event.
   * @param triggerOptions Object containing the kind of event that should be triggered (before, on, and/or after).
   * @param additionalData Object containing optional event data.
   */
  private _triggerEvent(
    type: EGanttShiftEditEventType,
    originEvent: GanttScrollContainerEvent<IShiftDragEvent>,
    triggerOptions: { before?: boolean; on?: boolean; after?: boolean } = { on: true },
    additionalData: IGanttShiftEditEventAdditionalData = {}
  ): void {
    const eventData = this._buildOutputEvent(type, originEvent, additionalData);
    if (triggerOptions.before) this._beforeShiftEditSubject.next(eventData);
    if (triggerOptions.on) this._onShiftEditSubject.next(eventData);
    if (triggerOptions.after) this._afterShiftEditSubject.next(eventData);
  }

  /**
   * Builds an ouput event by the given data.
   * @param type Type of event (start, update or end).
   * @param originEvent Input event.
   * @param additionalData Object containing optional event data.
   * @returns Output event built with the given data.
   */
  private _buildOutputEvent(
    type: EGanttShiftEditEventType,
    originEvent: GanttScrollContainerEvent<IShiftDragEvent>,
    additionalData: IGanttShiftEditEventAdditionalData = {}
  ): IGanttShiftTranslationEvent {
    const draggedShiftData: GanttCanvasShift = originEvent.event.target.data()[0];

    return {
      type: type,
      target: originEvent.event.target,
      originEvent: originEvent,
      dragStartRowId: this._shiftEditState.get('dragStartRow').id,
      dragEndRowId: type === EGanttShiftEditEventType.END ? additionalData.dragEndRowId : undefined,
      newCoordinates: type === EGanttShiftEditEventType.UPDATE ? additionalData.newCoordinates : undefined,
      hasBeenBlocked: type === EGanttShiftEditEventType.END ? additionalData.hasBeenBlocked : undefined,
      hasBeenDragged: type === EGanttShiftEditEventType.END ? additionalData.hasBeenDragged : undefined,
      blockedData: this._shiftEditLimiterAdapter.translationAreaLimiter.getRestrictionsByShiftId(draggedShiftData.id),
    };
  }

  /**
   * Builds a translation manipulation event by the given data.
   * @param originEvent Input event.
   * @returns Translation manipulation event built with the given data.
   */
  private _buildManipulationEvent(
    originEvent: GanttScrollContainerEvent<IShiftDragEvent>
  ): IGanttShiftTranslationManipulationEvent {
    const draggedShiftData: GanttCanvasShift = originEvent.event.target.data()[0];

    return {
      type: EGanttShiftEditEventType.END,
      target: originEvent.event.target,
      originEvent: originEvent,
      dragStartRowId: this._shiftEditState.get('dragStartRow').id,
      blockedData: this._shiftEditLimiterAdapter.translationAreaLimiter.getRestrictionsByShiftId(draggedShiftData.id),
      yManipulation: 0,
    };
  }

  //
  // HELPER METHODS
  //

  /**
   * Handles the rendering of relevant shift dates on the x axis.
   * @param event Edit update event.
   */
  private _markShiftDates(event: ITypedShiftDragEvent): void {
    const canvasShiftData = event.target.data()[0];
    const shiftData = this._shiftEditState.get('originShiftData');
    if (!shiftData || !canvasShiftData) return;

    // render current start & end dates
    const strokeWidth = parseFloat(event.target.attr('stroke-width')) || 0;
    const shiftStartX = parseFloat(event.target.attr('x')) - strokeWidth / 2;
    const shiftEndX = shiftStartX + parseFloat(event.target.attr('width')) + strokeWidth;
    const shiftStartDate = this._xAxisBuilder.pxToTime(shiftStartX, this._currentScale);
    const shiftEndDate = this._xAxisBuilder.pxToTime(shiftEndX, this._currentScale);

    this._xAxisBuilder.addMarkerByDate(shiftEndDate, ETimeMarkerAnchor.START, null, 'white', true);
    this._xAxisBuilder.addMarkerByDate(shiftStartDate, ETimeMarkerAnchor.END, null, 'white', true);

    // if drag horizontal is active && if neccesary -> mark original shift start & end dates
    if (
      this._shiftEditLimiterAdapter.allowDragHorizontal &&
      shiftStartDate.getTime() !== shiftData.timePointStart.getTime() &&
      shiftEndDate.getTime() !== shiftData.timePointEnd.getTime()
    ) {
      this._xAxisBuilder.addMarkerByDate(shiftData.timePointEnd, ETimeMarkerAnchor.START, null, 'gainsboro', true);
      this._xAxisBuilder.addMarkerByDate(shiftData.timePointStart, ETimeMarkerAnchor.END, null, 'gainsboro', true);
    }

    // render ES/LE markers
    this._ganttDiagram.markShiftDueDates(shiftData.id, true);
  }

  /**
   * Moves the specified shifts to the foreground.
   * @param target D3 selection of the shifts which should be moved to the foreground.
   */
  private _moveSVGShiftToForeground(
    target: d3.Selection<SVGRectElement, GanttCanvasShift, d3.BaseType, undefined>
  ): void {
    let shiftGroup = this._shiftGroupOverlay;

    const parentNode = target.node().parentNode;
    if (parentNode) {
      shiftGroup = d3.select(target.node().parentNode as any);
    }

    target.attr('class', 'gantt_shift_drag'); // !important for shift dragging mechanism

    this._canvas = shiftGroup.append('g').lower().attr('class', 'gantt-shift-drag-group');
    this._canvas.node().appendChild(target.node());
  }

  /**
   * Moves the specified shifts to the background.
   * @param target D3 selection of the shifts which should be moved to the background.
   */
  private _moveSVGShiftToBackground(
    target: d3.Selection<SVGRectElement, GanttCanvasShift, d3.BaseType, undefined>
  ): void {
    this._canvas.remove();
    this._shiftGroupOverlay.node().appendChild(target.node());
  }

  /**
   * Moves the shift cover element for hover and click visualization to the given position.
   * @param x
   * @param y
   */
  private _moveShiftOnHoverElementToPosition(x: number, y: number): void {
    this._htmlStructureBuilder
      .getShiftContainer()
      .selectAll('.gantt_highlight_on_hover')
      .style('left', x + 'px')
      .style('top', y + 'px');
  }

  /**
   * Changes coordinates of chained shift.
   * @param dragEvent D3 DragEvent of type "drag".
   */
  private _translateChainedElements(
    diffX: number,
    diffY: number,
    dragEvent: d3.D3DragEvent<SVGRectElement, GanttCanvasShift, null>
  ): void {
    this._chainHandler.translateElements(
      diffX,
      diffY,
      this._shiftEditLimiterAdapter.allowDragHorizontal ? dragEvent.x : 0,
      this._shiftEditLimiterAdapter,
      this._shiftEditState.get('dragStartPosition'),
      this._shiftEditState.get('blockChainedElementsX'),
      this._shiftEditState.get('blockChainedElementsY')
    );
  }

  /**
   * Sorts canvas and origin Datasets after dragging of shifts.
   * @param allNewParentRowIds
   * @param shiftData
   */
  private _sortDatasets(allNewParentRowIds: string[], shiftData: GanttCanvasShift): void {
    for (const rowId of allNewParentRowIds) {
      ShiftDataSorting.sortOriginShiftsByRowId(this._dataHandler.getOriginDataset().ganttEntries, rowId);
    }
    ShiftDataSorting.sortShiftCanvasByYValue(this._dataHandler.getCanvasShiftDataset(), shiftData.y);
  }

  /**
   * Updates the single shift that was dragged with the mouse.
   * @param targetData
   * @param positionDifference
   * @param gridDifference Difference to x grid value as a number.
   */
  private _updateTargetData(
    targetData: GanttCanvasShift,
    positionDifference: [number, number],
    gridDifference: number
  ): string {
    const originData = ShiftDataFinder.getShiftById(this._dataHandler.getOriginDataset().ganttEntries, targetData.id),
      shiftOriginData = originData.shift,
      canvasShiftData = ShiftDataFinder.getCanvasShiftById(this._dataHandler.getCanvasShiftDataset(), targetData.id)[0];

    this._handleHorizontalDrag(shiftOriginData, canvasShiftData, positionDifference, gridDifference);
    const newRowId = this._handleVerticalDrag(
      shiftOriginData,
      canvasShiftData,
      this._shiftEditState.get('dragEndViewportPosition')
    );

    return newRowId;
  }

  /**
   * Updates data of chained Shifts after chained target was dragged.
   * @param positionDifference
   * @param gridDifference
   */
  private _updateChainedElements(
    targetData: GanttCanvasShift,
    positionDifference: [number, number],
    gridDifference: number
  ): string[] {
    const chainedElementIDs = JSON.parse(JSON.stringify(this._chainHandler.getChainedElementsById(targetData.id)));

    const newRowIds = [];
    for (const elementId of chainedElementIDs) {
      const originData = ShiftDataFinder.getShiftById(this._dataHandler.getOriginDataset().ganttEntries, elementId),
        shiftOriginData = originData.shift,
        canvasShiftData = ShiftDataFinder.getCanvasShiftById(this._dataHandler.getCanvasShiftDataset(), elementId)[0];

      this._handleHorizontalDrag(shiftOriginData, canvasShiftData, positionDifference, gridDifference);
      const newRowId = this._handleVerticalDrag(
        shiftOriginData,
        canvasShiftData,
        this._shiftEditState.get('dragEndViewportPosition')
      );
      newRowIds.push(newRowId);
    }

    return Array.from(new Set(newRowIds));
  }

  /**
   * Updates data on shift for horizontal movement (x values and width).
   * @param shiftOriginData
   * @param canvasShiftData
   * @param positionDifference
   * @param gridDifference
   */
  private _handleHorizontalDrag(
    shiftOriginData: GanttDataShift,
    canvasShiftData: GanttCanvasShift,
    positionDifference: [number, number],
    gridDifference: number
  ): void {
    if (
      !(this._chainHandler.isHorizontalChained(shiftOriginData.id) && this._shiftEditLimiterAdapter.allowDragHorizontal)
    ) {
      return;
    }

    const originStartDate =
      this._currentScale(new Date(shiftOriginData.timePointStart)) + positionDifference[0] + gridDifference;
    const originEndDate =
      this._currentScale(new Date(shiftOriginData.timePointEnd)) + positionDifference[0] + gridDifference;

    if (canvasShiftData) {
      // add new coordinates to canvas shift data
      const updateData = new Map<string, Partial<GanttCanvasShift>>();
      updateData.set(canvasShiftData.id, {
        x: (originStartDate - this._zoomTransform.x) / this._zoomTransform.k,
      });
      this._dataHandler.updateCanvasShiftPropertiesInCanvasDataByShiftId(updateData);

      this._handleStickyCanvasShift(canvasShiftData, positionDifference);
    }

    // do not update origin timePointEnd if shift has sticky end (because sticky end causes unchanged timepointend everytime)
    if (!this._shiftEditLimiterAdapter.shiftHasStickyEnd(canvasShiftData.id)) {
      shiftOriginData.timePointEnd = this._xAxisBuilder.pxToTime(originEndDate, this._currentScale);
    }

    shiftOriginData.timePointStart = this._xAxisBuilder.pxToTime(originStartDate, this._currentScale);
  }

  /**
   * Handles sticky shifts.
   * @param canvasShiftData
   * @param positionDifference
   */
  private _handleStickyCanvasShift(canvasShiftData: GanttCanvasShift, positionDifference: [number, number]): void {
    if (this._shiftEditLimiterAdapter.shiftHasStickyStart(canvasShiftData.id)) {
      const updateData = new Map<string, Partial<GanttCanvasShift>>();

      updateData.set(canvasShiftData.id, {
        width: canvasShiftData.width - positionDifference[0],
        x: canvasShiftData.x + positionDifference[0],
      });

      this._dataHandler.updateCanvasShiftPropertiesInCanvasDataByShiftId(updateData);
    }
    if (this._shiftEditLimiterAdapter.shiftHasStickyEnd(canvasShiftData.id)) {
      const updateData = new Map<string, Partial<GanttCanvasShift>>();

      updateData.set(canvasShiftData.id, {
        width: canvasShiftData.width + positionDifference[0],
      });

      this._dataHandler.updateCanvasShiftPropertiesInCanvasDataByShiftId(updateData);
    }
  }

  /**
   * Updates data on shift for vertical movement (y-values, position in hierachical dataset)
   * @param shiftOriginData Origin data of the dragged shift.
   * @param canvasShiftData Canvas data of the dragged shift.
   * @param positionDifference Array ([dx, dy]) defining the difference of the new shift position to the old shift position.
   */
  private _handleVerticalDrag(
    shiftOriginData: GanttDataShift,
    canvasShiftData: GanttCanvasShift,
    shiftViewportCoordinates: [number, number]
  ): string {
    if (
      !(this._chainHandler.isVerticalChained(shiftOriginData.id) && this._shiftEditLimiterAdapter.allowDragVertical)
    ) {
      return;
    }

    // put data into dataset
    if (canvasShiftData) {
      const newCanvasShiftParent = this._renderDataHandler
        .getYAxisDataFinder()
        .getRowByViewportY(shiftViewportCoordinates[1]);

      ShiftDataFinder.changeShiftParentById(
        this._ganttDiagram.getDataHandler().getOriginDataset().ganttEntries,
        newCanvasShiftParent.id,
        shiftOriginData.id
      );

      // add new coordinates to canvas shift data
      const newCanvasYPosition = newCanvasShiftParent.y + this._ganttDiagram.getConfig().getLineTop();
      const updateData = new Map<string, Partial<GanttCanvasShift>>();

      updateData.set(canvasShiftData.id, {
        y: newCanvasYPosition,
      });

      this._dataHandler.updateCanvasShiftPropertiesInCanvasDataByShiftId(updateData);

      return newCanvasShiftParent.id;
    }
  }

  /**
   * Calculates x and y position differences the shift has been dragged from start to end.
   * @param shiftSelection If specified, this d3 selection of the dragged shift will be used for stroke width calculations.
   * @return Array containing the x and y position diferences.
   */
  private _getPositionDifference(
    shiftSelection: d3.Selection<SVGRectElement, GanttCanvasShift, d3.BaseType, undefined> = null
  ): [number, number] {
    // validation if shift has not been dragged
    if (!this._shiftEditState.get('hasBeenDragged')) {
      return [0, 0];
    }

    // calculate position difference
    const dragStartPosition = this._shiftEditState.get('dragStartPosition');
    const dragEndPosition = this._shiftEditState.get('dragEndPosition');
    const shiftStrokeWidth = parseFloat(shiftSelection?.attr('stroke-width')) || 0;

    const positionDifference: [number, number] = [
      dragEndPosition[0] - dragStartPosition[0] - shiftStrokeWidth / 2,
      dragEndPosition[1] + this._ganttDiagram.getConfig().getLineTop() - dragStartPosition[1],
    ];
    return positionDifference;
  }

  /**
   * Sets opacity to shift during on drag start.
   * This will cause the canvas to reRender this shift translucent and thus preserve to original position for better UX.
   * On Drag End we reset the opacity value again! {@link GanttShiftTranslationFacade#_resetGhostShiftOnDragEnd}
   * @param shiftData Canvas data of the dragged shift.
   */
  private _renderGhostShiftDuringDrag(shiftData: GanttCanvasShift): void {
    this._shiftEditState.set({ ghostShiftOpacityBackup: shiftData.opacity });
    const shiftIds = [shiftData.id];

    // add chained elements
    this._chainHandler.getCachedElements().forEach((shift) => shiftIds.push(shift.data()[0].id));
    const updateData = new Map<string, Partial<GanttCanvasShift>>();

    shiftIds.forEach((id) => {
      updateData.set(id, {
        opacity: 0.2,
      });
    });

    this._ganttDiagram.getDataHandler().updateCanvasShiftPropertiesInCanvasDataByShiftId(updateData);
  }

  /**
   * Resets opacity to shift on dragEnd.
   * @param shiftData Canvas data of the dragged shift.
   */
  private _resetGhostShiftOnDragEnd(shiftData: GanttCanvasShift): void {
    const shiftIds = [shiftData.id];

    // add chained elements
    this._chainHandler.getCachedElements().forEach((shift) => shiftIds.push(shift.data()[0].id));
    const updateData = new Map<string, Partial<GanttCanvasShift>>();
    shiftIds.forEach((id) => {
      updateData.set(id, {
        opacity: this._shiftEditState.get('ghostShiftOpacityBackup'),
      });
    });

    this._ganttDiagram.getDataHandler().updateCanvasShiftPropertiesInCanvasDataByShiftId(updateData);
    this._shiftEditState.set({ ghostShiftOpacityBackup: null });
  }

  /**
   * Rounds y Values on svg shifts to nearest row and then offsets them accordingly.
   * Updating the svg shift positions.
   * @param allMovingElements D3 selection of all translated shifts.
   * @param targetRow Canvas data of the row the shifts should be translatoed to.
   */
  private _roundYValuesOnShifts(
    allMovingElements: d3.Selection<SVGRectElement, GanttCanvasShift, d3.BaseType, undefined>[],
    targetRow: GanttCanvasRow
  ): void {
    for (const currentShift of allMovingElements) {
      const roundedY = targetRow.y + this._ganttDiagram.getConfig().getLineTop();
      currentShift.attr('y', roundedY);
    }
  }

  /**
   * Resets svg shift positions if drag was blocked.
   * @param allMovingElements D3 selection of all shifts which should be reset.
   */
  private _resetPositionsOfAllMovingElements(
    allMovingElements: d3.Selection<SVGRectElement, GanttCanvasShift, d3.BaseType, undefined>[]
  ): void {
    for (let i = 0; i < allMovingElements.length; i++) {
      const element = allMovingElements[i];

      element
        .attr('x', (d) => {
          return d.x * this._zoomTransform.k + this._zoomTransform.x;
        })
        .attr('y', (d) => {
          return d.y;
        })
        .attr('width', (d) => {
          return d.width * this._zoomTransform.k;
        });
    }
  }

  /**
   * Builds and visualizes chained shifts during drag, handles restrictions on them.
   * @param dragEvent Drag start event which triggered the execution of this method.
   * @param draggedShiftData Canvas data of the dragged shift.
   */
  private _handleChainedShiftsDragStart(
    dragEvent: d3.D3DragEvent<SVGRectElement, GanttCanvasShift, null>,
    draggedShiftData: GanttCanvasShift
  ): void {
    // get all chained elements
    const chainedElementIds = this._chainHandler.getChainedElementsById(draggedShiftData.id);
    const chainedShifts = [];
    this._shiftGroupOverlay.selectAll('gantt_shifts').each(function (d: GanttCanvasShift) {
      if (chainedElementIds.indexOf(d.id) > -1) {
        chainedShifts.push(d3.select(this));
      }
    });
    this._chainHandler.setCacheElements(chainedShifts);

    const differenceBetweenFirstChainedElement = this._chainHandler.calculateDifferenceBetweenShiftAndFirstChainedShift(
      dragEvent.x,
      chainedShifts
    );
    const differenceBetweenLastChainedElement = this._chainHandler.calculateDifferenceBetweenShiftAndLastChainedShift(
      dragEvent.x,
      draggedShiftData.width,
      chainedShifts
    );
    this._shiftEditState.set({
      differenceBetweenFirstChainedElement: differenceBetweenFirstChainedElement,
      differenceBetweenLastChainedElement: differenceBetweenLastChainedElement,
    });

    const differenceBetweenFirstHorizontalChainedElement =
      this._chainHandler.calculateDifferenceBetweenShiftAndFirstVerticalChainedShift(dragEvent.y, chainedShifts);
    const differenceBetweenLastHorizontalChainedElement =
      this._chainHandler.calculateDifferenceBetweenShiftAndLastHorizontalChainedShift(
        dragEvent.y,
        draggedShiftData.width,
        chainedShifts
      );

    this._shiftEditState.set({
      differenceBetweenFirstHorizontalChainedElement: differenceBetweenFirstHorizontalChainedElement,
      differenceBetweenLastHorizontalChainedElement: differenceBetweenLastHorizontalChainedElement,
    });

    if (chainedShifts.length) {
      this._chainHandler.getCachedElements().forEach((elem) => elem.attr('class', 'gantt_shift_drag_chained')); // set other class of chained shifts (important for drag mechanism)
    }
  }

  //
  // OBSERVABLES
  //

  /**
   * Observable which gets triggered when the shift translator allows manipulation form outside.
   */
  public onShiftTranslationManipulation(): Observable<IGanttShiftTranslationManipulationEvent> {
    return this._onShiftTranslationManipulationSubject.asObservable();
  }

  //
  // GETTER & SETTER
  //

  /**
   * Returns the translation area limiter of this shift translator.
   */
  public get translationAreaLimiter(): GanttShiftTranslationAreaLimiter {
    return this._shiftEditLimiterAdapter.translationAreaLimiter;
  }

  /**
   * Returns the translation row limiter of this shift translator.
   */
  public get translationRowLimiter(): GanttShiftTranslationRowLimiter {
    return this._shiftEditLimiterAdapter.translationRowLimiter;
  }
}
