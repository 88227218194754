<!-- SINGLE -->
<mat-form-field [formGroup]="formGroup" [id]="id" *ngIf="type !== 'TIME'" [hideRequiredMarker]="hideRequiredMarker">
  <mat-label>{{ getRequiredMarker() + placeholder }}</mat-label>
  <ng-container *ngIf="type !== 'TIME'">
    <input
      [required]="!!required"
      autocomplete="off"
      (dblclick)="openCalendarPicker($event, dateField)"
      (focusout)="parseDateString(true)"
      [autofocus]="autofocus"
      *ngIf="mode !== 'range'"
      (change)="parseDateString(true)"
      #dateField
      matInput
      formControlName="start"
      [placeholder]="getRequiredMarker() + placeholder"
    />

    <mat-date-range-input *ngIf="mode === 'range'">
      <input
        [required]="!!required"
        (focusout)="parseDateString(true)"
        (dblClick)="openCalendarPicker($event, start)"
        autocomplete="off"
        [autofocus]="autofocus"
        (change)="parseDateString()"
        #start
        matStartDate
        formControlName="start"
        placeholder="Start"
      />
      <input
        [required]="!!required"
        (focusout)="parseDateString(true)"
        (dblclick)="openCalendarPicker($event, end)"
        (change)="parseDateString()"
        #end
        matEndDate
        formControlName="end"
        placeholder="Ende"
      />
    </mat-date-range-input>
  </ng-container>
  <app-sort-menu matPrefix *ngIf="entryElement?.isEnableSort()" [element]="entryElement"></app-sort-menu>
  <app-conditions-menu
    matPrefix
    *ngIf="entryElement?.getAvailableFilterConditions().length > 0"
    [entryElement]="entryElement"
  >
  </app-conditions-menu>
  <button
    [class.picker-open]="calendarRef"
    #btn
    *ngIf="type !== 'TIME' && !disabled"
    mat-icon-button
    matSuffix
    (click)="openCalendarPicker($event, btn._elementRef)"
  >
    <mat-icon>{{ mode === 'range' ? 'date_range' : 'today' }}</mat-icon>
  </button>
</mat-form-field>

<app-time-picker
  [id]="id"
  *ngIf="type === 'TIME'"
  [disabled]="disabled"
  (timeChanged)="parseTimeString($event)"
  [label]="placeholder"
  [time]="selectedValues.from"
  [required]="!!required"
>
</app-time-picker>
