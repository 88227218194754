import { GanttCallBackStackExecuter } from '../../callback-tools/callback-stack-executer';
import { ToHexColorConverter } from '../../color/color-converter/to-hex-converter';
import { YAxisDataFinder } from '../data-finder/yaxis-data-finder';
import { DataHandler } from '../data-handler';
import { GanttCanvasRow, GanttDataRow } from '../data-structure/data-structure';
import { DataManipulator } from '../data-tools/data-manipulator';

/**
 * Mapper class for y axis data.
 * @keywords yaxis, row, child creation, creator, mapper, data, canvas, helper
 */
export abstract class YAxisDataMapper {
  /**
   * Maps all y axis origin data to y axis canvas data.
   * @param dataSet Gantt origin dataset.
   * @param dataHandler
   * @returns
   */
  public static getCanvasYAxisData(dataSet: GanttDataRow[], dataHandler: DataHandler): GanttCanvasRow[] {
    const rowHeightStorage = dataHandler.getRowHeightStorage();
    const beforeYAxisDataMappingCallbacks = dataHandler.callBack.beforeYAxisDataMapping;

    GanttCallBackStackExecuter.execute(beforeYAxisDataMappingCallbacks, null);

    const newCanvasYAxisDataSet = [];

    let globalYPos = 0;

    const getRenderYAxis = function (child: GanttDataRow, level, parent) {
      const amountOfRenderedChildren = YAxisDataFinder.getIdsOfNewRenderedRowsAfterOpenParentRow(child).length;

      if ((level == 0 || parent.open) && (!child.noRender || !child.noRender.length)) {
        const rowHeight = rowHeightStorage.getRowHeightById(child.id);
        const newRow = YAxisDataMapper.mapOriginToCanvas(
          child,
          rowHeight,
          globalYPos,
          level,
          amountOfRenderedChildren == 0,
          child.group,
          child.color,
          child.stroke
        );

        if (!newRow.leaf || newRow.group == 'BASE-TREE') newRow.open = child.open;

        newCanvasYAxisDataSet.push(newRow);
        globalYPos += rowHeight;
      }
    };

    DataManipulator.iterateOverDataSet(dataSet, { getAllYAxisData: getRenderYAxis });
    return newCanvasYAxisDataSet;
  }

  /**
   * @keywords yaxis, row, child, creation, creator, mapper, data, canvas, origin
   * @param rowData Row origin data.
   * @param rowHeight Height of row.
   * @param y Vertical position of row.
   * @param level Depth of row.
   * @param leaf Defines, if row has childs.
   * @param group Attribute for groupe attribute. Could be
   * @param color Color of row.
   * @param stroke Stroke color of row.
   */
  public static mapOriginToCanvas(
    rowData: GanttDataRow,
    rowHeight: number,
    y: number,
    level: number,
    leaf: boolean,
    group: string,
    color: string,
    stroke: string
  ): GanttCanvasRow {
    const newRow: GanttCanvasRow = {
      id: rowData.id,
      height: rowHeight,
      symbols: [],
      text: rowData.name,
      subtitleElements: rowData.subtitleElements,
      y: y,
      layer: level,
      leaf: leaf,
      group: group,
      color: ToHexColorConverter.convertColorToHex(color),
      stroke: ToHexColorConverter.convertColorToHex(stroke),
      textColor: ToHexColorConverter.convertColorToHex(rowData.textColor),
      tooltip: rowData.tooltip,
      noRender: rowData.noRender ? rowData.noRender.slice() : [],
      allowedEntryTypes: rowData.allowedEntryTypes,
      originalResource: rowData.originalResource ? rowData.originalResource : null,
      icon: rowData.icon ? rowData.icon : null,
      indicatorColor: rowData.indicatorColor ? ToHexColorConverter.convertColorToHex(rowData.indicatorColor) : null,
      stickyChild: rowData.stickyChild ? rowData.stickyChild : false,
      sampleValues: rowData.sampleValues || [],
      startCellIndexForSampleValues: rowData.startCellIndexForSampleValues || 0,
    };

    return newRow;
  }
}
