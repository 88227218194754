import * as d3 from 'd3';
import { Observable, Subject, takeUntil } from 'rxjs';
import { GanttCallBackStackExecuter } from '../../callback-tools/callback-stack-executer';
import { GanttUtilities } from '../../gantt-utilities/gantt-utilities';
import { EGanttScrollContainer } from '../../html-structure/scroll-container.enum';
import { BestGantt } from '../../main';
import { TooltipBuilder } from '../../tooltip/tooltip-builder';
import { BestGanttPlugIn } from '../gantt-plug-in';
import { GanttTimePointEvent } from './undo-redo/timepoint-marker-event';

/**
 * Marks timepoint inside gantt by drawing a line.
 * @keywords plugin, executer, time, point, marker, date, line, vertical
 * @plugin timepoint-marker
 */
export class GanttTimePointExecuter extends BestGanttPlugIn {
  private _canvasShifts: d3.Selection<SVGSVGElement, undefined, d3.BaseType, undefined> = undefined;
  private _canvasXAxis: d3.Selection<SVGGElement, undefined, d3.BaseType, undefined> = undefined;

  private _timePoints: GanttTimePoint[] = [];
  private _animation = {
    enabled: false,
    animationType: 'CONTINUOUS',
  };
  private _showToolTips = true;
  private _callbacks = {
    tooltip: {},
    tooltipOut: {},
  };
  private _tooltipBuilder: TooltipBuilder = undefined;
  private _hoveredTimePoint: GanttTimePoint = undefined;

  private _onDestroySubject: Subject<void> = new Subject<void>();

  /**
   * @param _renderOn Activates/Deactivates Timepoint marking. Defaults to true.
   * @param _color Color for timePoints with no own color / default color.
   */
  constructor(private _renderOn = true, private _color = '#000000') {
    super(); // call super-constructor
  }

  public initPlugIn(ganttDiagram: BestGantt): void {
    this.ganttDiagram = ganttDiagram;

    this._initCanvasShift();
    this._initCanvasXAxis();

    // init callbacks
    this.ganttDiagram
      .getXAxisBuilder()
      .onZooming.pipe(takeUntil(this.onDestroy))
      .subscribe(() => this.build());
    this.ganttDiagram
      .getOpenRowHandler()
      .afterShiftRowOpen.pipe(takeUntil(this.onDestroy))
      .subscribe(() => this.build());
    this.ganttDiagram
      .getOpenRowHandler()
      .afterShiftRowClosed.pipe(takeUntil(this.onDestroy))
      .subscribe(() => this.build());
    this.ganttDiagram
      .getVerticalScrollHandler()
      .onScrollVerticalUpdate.pipe(takeUntil(this.onDestroy))
      .subscribe(() => this.build(true, false));

    this.build();
    this._tooltipBuilder = this.ganttDiagram.getTooltipBuilder();
    this._addToolTipCallbacks();
  }

  /** Adds tooltip Callbacks*/
  private _addToolTipCallbacks(): void {
    this._callbacks.tooltip['openTooltip'] = this._openTooltip.bind(this);
    this._callbacks.tooltipOut['openTooltip'] = this._closeTooltip.bind(this);
  }

  /** Removes tooltip Callbacks and currently open tooltips. */
  private _removeToolTipCallbacks(): void {
    delete this._callbacks.tooltip['openTooltip'];
    this._closeTooltip();
  }

  /**
   * Renders name and date as tooltip.
   * @param mouseOverEvent
   */
  private _openTooltip(mouseOverEvent: MouseEvent): void {
    if (!this._hoveredTimePoint || !mouseOverEvent) return;

    const dateFormat = d3.timeFormat(this.ganttDiagram.getConfig().xAxisMarkerFormat);
    const mouseOffset = [mouseOverEvent.x, mouseOverEvent.y];
    this._tooltipBuilder.addTooltipToHTMLBody(
      mouseOffset[0],
      mouseOffset[1],
      `${this._hoveredTimePoint.name} : ${dateFormat(this._hoveredTimePoint.date)}`
    );
  }

  /** Removes tooltip. */
  private _closeTooltip(): void {
    this._tooltipBuilder.removeAllTooltips();
  }

  /**
   * Remove of plugin. Part of plugin lifecycle.
   */
  public removePlugIn(): void {
    // callback unsubscribe
    this._onDestroySubject.next();
    this._onDestroySubject.complete();

    // remove groups
    this._canvasShifts.remove();
    this._canvasXAxis.remove();

    this._removeToolTipCallbacks();
  }

  /**
   * Builds canvas inside gantt shift area.
   */
  private _initCanvasShift(): void {
    this._canvasShifts = this.ganttDiagram
      .getShiftFacade()
      .getShiftWrapperOverlay()
      .append<SVGSVGElement>('svg')
      .attr('class', 'gantt-timepoint-marker-shift')
      .attr('width', '100%')
      .attr('height', '100%')
      .style('position', 'absolute')
      .style('left', '0px');

    for (const scrollContainerId of this.ganttDiagram.getRenderDataHandler().getAllScrollContainerIds()) {
      this._canvasShifts.append('g').attr('class', this._getCanvasShiftClass(scrollContainerId));
    }
  }

  private _getCanvasShiftClass(scrollContainerId: EGanttScrollContainer): string {
    let canvasShiftClass = 'gantt-timepoint-marker-shift_';
    switch (scrollContainerId) {
      case EGanttScrollContainer.STICKY_ROWS:
        canvasShiftClass += 'sticky-rows';
        break;
      case EGanttScrollContainer.DEFAULT:
      default:
        canvasShiftClass += 'default';
        break;
    }
    return canvasShiftClass;
  }

  /**
   * Builds canvas inside gantt x axis area.
   */
  private _initCanvasXAxis(): void {
    this._canvasXAxis = this.ganttDiagram
      .getXAxisBuilder()
      .getCanvas()
      .insert('g', '.gantt_x-axis-container')
      .attr('class', 'gantt-timepoint-marker-x_axis');
  }

  /**
   * Builds all line elements by timepoints to gantt.
   * @param buildShiftCanvas If `true`, this method will rerender the shift canvas (default is `true`).
   * @param buildXAxisCanvas If `true`, this method will rerender the x axis canvas (default is `true`).
   */
  public build(buildShiftCanvas = true, buildXAxisCanvas = true): void {
    if (buildShiftCanvas) this._renderShiftCanvas();
    if (buildXAxisCanvas) this._renderXAxisCanvas();
  }

  /**
   * Builds all line elements by timepoints to gantt shift area.
   */
  private _renderShiftCanvas(): void {
    for (const scrollContainerId of this.ganttDiagram.getRenderDataHandler().getActiveScrollContainerIds()) {
      this.render(
        this._canvasShifts.select(`.${this._getCanvasShiftClass(scrollContainerId)}`),
        this.ganttDiagram.getNodeProportionsState().getShiftViewPortProportions(scrollContainerId).height,
        this.ganttDiagram.getShiftFacade().getShiftBuilder(scrollContainerId).getClipPathHandler().clipPathUrl
      );
    }
  }

  /**
   * Builds all line elements by timepoints to gantt x axis.
   */
  private _renderXAxisCanvas(): void {
    this.render(this._canvasXAxis, this.ganttDiagram.getNodeProportionsState().getXAxisProportions().height);
  }

  private _getRenderDataset(dataset: GanttTimePoint[], canvasHeight: number): GanttTimePointRenderData[] {
    const scale = this.ganttDiagram.getXAxisBuilder().getCurrentScale();
    return dataset.map((elem) => {
      return new GanttTimePointRenderData(
        elem.id,
        Math.round(scale(elem.date)),
        isNaN(canvasHeight) ? 1000 : Math.max(canvasHeight, 0),
        elem.color,
        elem.showToolTip
      );
    });
  }

  /**
   * Builds all line elements by timepoints to given canvas.
   * @param {selection} canvasSelection D3 selection of canvas where timepoints should be marked.
   */
  render(
    canvasSelection: d3.Selection<SVGGElement, undefined, d3.BaseType, undefined>,
    canvasHeight: number,
    clipPathUrl: string = undefined
  ): void {
    const s = this;
    if (!s._renderOn) return;
    const dataSetArray = s._getRenderDataset(
      s._timePoints.filter((elem) => s._isTimePointInCurrentDomain(elem)),
      canvasHeight
    );

    // same lines invisible, slightly thicker for easier mouseOver
    const invisibleHitLines = canvasSelection.selectAll('line').data(dataSetArray);

    const lines = canvasSelection.selectAll('line').data(dataSetArray);

    lines
      .attr('x1', (d) => d.x)
      .attr('x2', (d) => d.x)
      .attr('y2', (d) => d.y);

    lines
      .enter()
      .append('line')
      .attr('x1', (d) => d.x)
      .attr('x2', (d) => d.x)
      .attr('y1', 0)
      .attr('y2', (d) => d.y)
      .attr('class', 'gantt-timepoint-marker-item')
      .attr('clip-path', clipPathUrl)
      .style('pointer-events', 'none')
      .style('stroke', (d) => d.color);

    lines.exit().remove();

    invisibleHitLines.attr('x1', (d) => d.x).attr('x2', (d) => d.x);

    invisibleHitLines
      .enter()
      .append('line')
      .attr('x1', (d) => d.x)
      .attr('x2', (d) => d.x)
      .attr('y1', 0)
      .attr('y2', (d) => d.y)
      .attr('clip-path', clipPathUrl)
      .style('stroke-width', 6)
      .style('stroke', 'transparent')
      .on('mouseover', function (event, d) {
        const i = invisibleHitLines.nodes().indexOf(this);
        if (!s._showToolTips || !d.showToolTip) return null;
        s._hoveredTimePoint = s._timePoints.find((timePoint) => timePoint.id === d.id);
        // make visible Line Thicker on hover
        lines
          .filter(function (k, j) {
            return j === i;
          })
          .style('opacity', 1)
          .attr('stroke-width', 3);

        GanttCallBackStackExecuter.execute(s._callbacks.tooltip, event);
      })
      .on('mouseout', function (event, d) {
        const i = invisibleHitLines.nodes().indexOf(this);
        if (!s._showToolTips || !d.showToolTip) return null;
        s._hoveredTimePoint = null;
        lines
          .filter(function (k, j) {
            return j === i;
          })
          .style('opacity', 0.6)
          .attr('stroke-width', 2);
        GanttCallBackStackExecuter.execute(s._callbacks.tooltipOut, d);
      });

    invisibleHitLines.exit().remove();
  }

  /**
   * Removes all lines from given canvas.
   * @param {selection} canvasSelection D3 selection of canvas node.
   */
  removeAll(canvasSelection) {
    canvasSelection.selectAll('line').on('mouseover', null).on('mouseout', null).remove();
  }

  public update(): void {
    if (this._canvasXAxis) {
      this._canvasXAxis.remove();
    }
    this._initCanvasXAxis();
    this.build();
  }

  /**
   * Checks if given timepoint is inside given D3 scale domain.
   * @param {Date} timePoint Timepoint to check.
   * @param {scale} currentScale D3 scale of x axis.
   */
  private _isTimePointInCurrentDomain(timePoint) {
    const scale = this.ganttDiagram.getXAxisBuilder().getCurrentScale();
    return scale.domain()[0] <= timePoint.date && timePoint.date <= scale.domain()[1];
  }

  //
  // GETTER & SETTER
  //

  /**
   * @param {Date} date - JS Date Object or String e.g "2018,4,12:13".
   * @param {string} [id="GanttUtilities.generateUniqueID()"] - Unique id for timepoint.
   * @param {string} [name = ""] - Name / description. Used as tooltip.
   * @param {string} [color = this.color] Color of timePoint, defaults to plugIn wide color.
   * @param {boolean} [showToolTip = true] Wether or not to show tooltips.
   */
  addTimePoint(id = GanttUtilities.generateUniqueID(), date, name = '', color = this._color, showToolTip = true) {
    this.removeTimePoint(id);
    this._timePoints.push(new GanttTimePoint(id, date, name, color, showToolTip));
  }

  /**
   * Sets the timePoints DataSet
   * @param {Array.<{string, Date}>} data
   */
  setTimePointData(data) {
    this._timePoints = [];
    for (const timepoint of data) {
      this._timePoints.push(
        new GanttTimePoint(
          timepoint.id || GanttUtilities.generateUniqueID(),
          timepoint.timePoint,
          timepoint.name,
          timepoint.color,
          timepoint.showToolTip
        )
      );
    }
  }

  /**
   * Removes a timePoint by its ID
   * @param {String} id
   */
  removeTimePoint(id) {
    if (this._hoveredTimePoint && this._hoveredTimePoint.id === id) {
      this._closeTooltip();
    }
    this._timePoints = this._timePoints.filter((elem) => elem.id !== id);
  }

  /**
   * Activates/Deactivates Timepoint marking.
   * @param {boolean} renderOn
   */
  setRender(renderOn) {
    this._renderOn = renderOn;
    if (!renderOn) {
      for (const scrollContainerId of this.ganttDiagram.getRenderDataHandler().getActiveScrollContainerIds()) {
        this.removeAll(this._canvasShifts.select(`.${this._getCanvasShiftClass(scrollContainerId)}`));
      }
      this.removeAll(this._canvasXAxis);
    } else this.build();
  }

  /**
   * Toggle wether plugin shows tooltips or not.
   * @param {boolean} boolean Toggle.
   */
  renderToolTips(boolean) {
    this._showToolTips = boolean;
  }

  /**
   * Sets color of timepoint markers.
   * @param {string} color Color value.
   */
  setColor(color) {
    this.ganttDiagram
      .getHistory()
      .addNewEvent('changeTPMarkerColor', new GanttTimePointEvent(), this, this._color /*, color*/);

    this._color = color;
    this.build();
  }

  /**
   * Enable / disable the animation
   * @param boolean
   */
  public toggleAnimation(boolean: boolean): void {
    this._animation.enabled = boolean;
  }

  /**
   * Sets the animation Type for TimePoint Animations.
   * @param animationType Animation Type, invalid values default to clock.
   */
  public setAnimationType(animationType = EGanttTimePointAnimationType.CLOCK): void {
    const animationTypes = Object.values(EGanttTimePointAnimationType);
    if (!animationTypes.includes(animationType)) animationType = EGanttTimePointAnimationType.CLOCK;
    this._animation.animationType = animationType;
  }

  //
  // OBSERVABLES
  //

  /**
   * Observable which gets triggered when the instance gets destroyed.
   */
  private get onDestroy(): Observable<void> {
    return this._onDestroySubject.asObservable();
  }
}

/**
 * Gantt Timepoint wrapper.
 */
export class GanttTimePoint {
  public date: Date;

  /**
   * @param id
   * @param date Date / Position of marker.
   * @param name Name / Description. Used as tooltip.
   * @param color Color value of marker.
   * @param showToolTip Show tooltip or not. Defaults true.
   */
  constructor(
    public id: string,
    date: Date | number,
    public name: string,
    public color: string,
    public showToolTip = true
  ) {
    if (!date) console.error('no date provided');
    if (!date) date = Date.now();
    this.date = new Date(date);
  }
}

/**
 * Data structure containing all neccesary render data for one time point marker.
 */
class GanttTimePointRenderData {
  constructor(
    public id: string,
    public x: number,
    public y: number,
    public color: string,
    public showToolTip: boolean
  ) {}
}

/**
 * Enum defining all possible animation types for time point markers.
 */
export enum EGanttTimePointAnimationType {
  CONTINUOUS = 'CONTINUOUS',
  CLOCK = 'CLOCK',
}
