import { ComponentEvent } from '../../../history/component-event';

/**
 * @constructor
 * @class
 * @extends ComponentEvent
 * @requires ComponentEvent
 * @plugin timeperiod-marker
 * */
export class GanttShiftColorByAttributeEvent extends ComponentEvent {
  constructor() {
    super();
    /**
     * @type {GanttShiftColorByAttribute}
     */
    this.ref = null;
  }

  /**
   * @override
   * @returns {boolean} true if undo was succesfull, else false
   */
  undo() {
    const s = this;

    function handle(type) {
      const types = {
        colorizeShiftsByAttribute: function () {
          return s._undoColorizeByShiftAttribute();
        },
        resetColor: function () {
          return s._undoResetColor();
        },
        default: function () {
          console.warn('can not undo: ' + type + '. May not be implemented yet.');
          return false;
        },
      };
      return (types[type] || types['default'])();
    }
    return handle(s.eventName);
  }

  /**
   * @override
   * @returns {boolean} true if redo was succesfull, else false
   */
  redo() {
    const s = this;
    function handle(type) {
      const types = {
        colorizeShiftsByAttribute: function () {
          return s._redoColorizeByShiftAttribute();
        },
        resetColor: function () {
          return s._redoResetColor();
        },
        default: function () {
          console.warn('can not redo: ' + type + '. May not be implemented yet.');
          return false;
        },
      };
      return (types[type] || types['default'])();
    }
    return handle(s.eventName);
  }

  private _undoColorizeByShiftAttribute() {
    const s = this;
    s.ref.disableLogging();

    const prevColorAttribute = s.arguments[0];
    const specifColorBooleanOld = s.arguments[3];
    const emptyAttributeColor = s.arguments[2];
    if (!prevColorAttribute) s.ref.resetColor();
    else {
      s.ref.colorizeByAttribute(prevColorAttribute, emptyAttributeColor, specifColorBooleanOld);
    }

    s.ref.enableLogging();
    s.ganttDiagram.rerenderShiftsVertical();
    return true;
  }
  private _redoColorizeByShiftAttribute() {
    const s = this;
    s.ref.disableLogging();

    const attribute = s.arguments[1];
    const specifColorBooleanNew = s.arguments[4];
    const emptyAttributeColor = s.arguments[2];
    s.ref.colorizeByAttribute(attribute, emptyAttributeColor, specifColorBooleanNew);

    s.ref.enableLogging();
    s.ganttDiagram.rerenderShiftsVertical();
    return true;
  }
  private _undoResetColor() {
    const s = this;
    s.ref.disableLogging();

    const attribute = s.arguments[0];
    const specifColorBooleanOld = s.arguments[1];
    s.ref.colorizeByAttribute(attribute, undefined, specifColorBooleanOld);

    s.ref.enableLogging();
    s.ganttDiagram.rerenderShiftsVertical();
    return true;
  }
  private _redoResetColor() {
    const s = this;
    s.ref.disableLogging();
    s.ref.resetColor();
    s.ref.enableLogging();
    s.ganttDiagram.rerenderShiftsVertical();
    return true;
  }

  /**
   * @override
   * @return {String}
   */
  getEventDescription() {
    const s = this;
    function handle(type) {
      const types = {
        /* 'addTimePeriodToRow': function () { return GanttShiftColorByAttributeEventDescription.getAddTimePeriodToRowDescription(s.ref.ganttDiagram.getConfig().getLanguage(), s.arguments); },
        'removeSelectedTPs': function () { return GanttShiftColorByAttributeEventDescription.getRemoveSelectedTPsDescription(s.ref.ganttDiagram.getConfig().getLanguage(), s.arguments); },
        'changeTPColor': function () { return GanttShiftColorByAttributeEventDescription.getChangeTPColorDescription(s.ref.ganttDiagram.getConfig().getLanguage(), s.arguments); }, */
        default: function () {
          console.warn('no description for: ' + s.eventName + ' set. May not be implemented yet.');
          return '';
        },
      };
      return (types[type] || types['default'])();
    }
    return handle(s.eventName);
  }
}

// GETTER and SETTER inherited from Main Class

export class GanttShiftColorByAttributeEventDescription {
  // TODO: Event explanations.
  private constructor() {}

  static getAddTimePeriodToRowDescription(language, args) {
    return '';
  }
  static getRemoveSelectedTPsDescription(language, args) {
    return '';
  }
  static getChangeTPColorDescription(language, args) {
    return '';
  }
}
