import { BestGantt } from '../../main';

/**
 * Handles resizing of DOM nodes in Gantt.
 */
export class ResizeHandler {
  private _shiftContainerResizeObserver: ResizeObserver = null;
  private _shiftContainerResizeTimer: NodeJS.Timeout = null;

  /**
   * @param _ganttDiagram Reference to the gantt diagram to handle the resizing of DOM nodes for.
   */
  constructor(private _ganttDiagram: BestGantt) {
    this._observeShiftContainer();
  }

  /**
   * Observes the proportions of shift container.
   */
  private _observeShiftContainer(): void {
    if (window.ResizeObserver) {
      this._shiftContainerResizeObserver = new ResizeObserver(this._onShiftContainerSizeChange.bind(this));
      this._shiftContainerResizeObserver.observe(
        this._ganttDiagram.getHTMLStructureBuilder().getVerticalScrollContainerWrapper().node()
      );
    } else {
      console.warn('Shift container size can not be observed!');
    }
  }

  /**
   * Is triggered when the shift container proportions has changed.
   * Handles the execution of the functionalities once the proportions of the container have changed.
   */
  private _onShiftContainerSizeChange(): void {
    clearTimeout(this._shiftContainerResizeTimer);
    // throttle calls
    this._shiftContainerResizeTimer = setTimeout(() => {
      this._ganttDiagram.update();
    }, 300);
  }

  /**
   * Must be called as soon as the gantt diagram is destroyed.
   * Handles the unobserve of the observer.
   */
  public destroy(): void {
    this._shiftContainerResizeObserver.unobserve(
      this._ganttDiagram.getHTMLStructureBuilder().getVerticalScrollContainerWrapper().node()
    );
  }
}
