import { EntryElementValue } from './../../../../../modeleditor/components/entry-collection/entry-element-value';
import { EFilteredOutElementDisplayOption } from './filter-options.enum';
import { IFilterNode } from './filter-tree';

export class FrontendFilter extends EntryElementValue {
  private quickfilter: boolean;
  private caseSensitive: boolean;
  private hideEmptyRows: boolean;
  private filteredOutElementDisplayOption: EFilteredOutElementDisplayOption;
  private publicFilter: boolean;
  private node: IFilterNode;
  private filterString: string;
  private fixed: boolean;

  public isQuickfilter(): boolean {
    return this.quickfilter;
  }

  public setQuickfilter(quickfilter: boolean): this {
    this.quickfilter = quickfilter;
    return this;
  }

  public isPublicFilter(): boolean {
    return this.publicFilter;
  }

  public setPublicFilter(publicFilter: boolean): this {
    this.publicFilter = publicFilter;
    return this;
  }

  public getNode(): IFilterNode {
    return this.node;
  }

  public setNode(node: IFilterNode): this {
    this.node = node;
    return this;
  }

  public getFilterString(): string {
    return this.filterString;
  }

  public setFilterString(filterString: string): this {
    this.filterString = filterString;
    return this;
  }

  public isCaseSensitive(): boolean {
    return this.caseSensitive;
  }

  public setCaseSensitive(caseSensitive: boolean): this {
    this.caseSensitive = caseSensitive;
    return this;
  }

  public isHideEmptyRows(): boolean {
    return this.hideEmptyRows;
  }

  public setHideEmptyRows(hideEmptyRows: boolean): this {
    this.hideEmptyRows = hideEmptyRows;
    return this;
  }

  public getFilteredOutElementDisplayOption(): EFilteredOutElementDisplayOption {
    return this.filteredOutElementDisplayOption;
  }

  public setFilteredOutElementDisplayOption(filteredOutElementDisplayOption: EFilteredOutElementDisplayOption): this {
    this.filteredOutElementDisplayOption = filteredOutElementDisplayOption;
    return this;
  }

  public isFixed(): boolean {
    return this.fixed;
  }

  public setFixed(fixed: boolean): this {
    this.fixed = fixed;
    return this;
  }
}
