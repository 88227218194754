import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NodeProportionsState } from './best_gantt/script/html-structure/node-proportion-state/node-proportion-state';
import { BestGantt } from './best_gantt/script/main';
import { GanttOverlayService } from './services/overlay-service/overlay.service';

@Component({
  selector: 'lib-gantt',
  template: `
    <!-- Gantt Wrapper -->
    <div #ganttWrapperContainer class="gantt_wrapper"></div>
  `,
  styleUrls: [
    './gantt.component.scss',
    './best_gantt/style/animation.css',
    './best_gantt/style/extensions.css',
    './best_gantt/style/selection-box.css',
    './best_gantt/style/shift-overlay.css',
    './best_gantt/style/shifts.css',
    './best_gantt/style/size-handler.css',
    './best_gantt/style/tooltip.css',
    './best_gantt/style/x-axis.css',
    './best_gantt/style/y-axis.css',
    './best_gantt/script/plug-ins/edges/edge-style.css',
    './best_gantt/script/plug-ins/setting-bubbles/setting-bubble-style.css',
    './best_gantt/style/efw-printing.css',
    './best_gantt/script/plug-ins/print-view/style/lw-printing.css',
    './best_gantt/script/plug-ins/shift-loading/shift-loading.css',
  ],
  encapsulation: ViewEncapsulation.None, // make styles accessible to elements generated by d3
  providers: [NodeProportionsState],
})
export class GanttComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('ganttWrapperContainer') ganttWrapperContainer: ElementRef;

  private _instance: BestGantt;

  constructor(private _overlayApi: GanttOverlayService, private nodeProportionsState: NodeProportionsState) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this._instance.destroy();
  }

  ngAfterViewInit(): void {
    this._instance = new BestGantt(
      this.ganttWrapperContainer.nativeElement,
      this._overlayApi,
      this.nodeProportionsState
    );
  }

  get instance(): BestGantt {
    return this._instance;
  }
}
