import { EntryElement } from '@app-modeleditor/components/entry-collection/entry-element';
import { EFieldType } from '@app-modeleditor/components/entry-collection/field-type.enum';

export class DateSelectorElement extends EntryElement {
  private currentTimeSpanFrom: Date;
  private currentTimeSpanTo: Date;
  private timeSpanFrom: Date;
  private timeSpanTo: Date;

  public getTimeSpanFrom(): Date {
    return this.timeSpanFrom;
  }

  public setTimeSpanFrom(timeSpanFrom: Date): this {
    this.timeSpanFrom = timeSpanFrom;
    return this;
  }

  public getTimeSpanTo(): Date {
    return this.timeSpanTo;
  }

  public setTimeSpanTo(timeSpanTo: Date): this {
    this.timeSpanTo = timeSpanTo;
    return this;
  }

  public getCurrentTimeSpanFrom(): Date {
    return this.currentTimeSpanFrom;
  }

  public setCurrentTimeSpanFrom(startDate: Date): this {
    this.currentTimeSpanFrom = startDate;
    return this;
  }

  public getCurrentTimeSpanTo(): Date {
    return this.currentTimeSpanTo;
  }

  public setCurrentTimeSpanTo(endDate: Date): this {
    this.currentTimeSpanTo = endDate;
    return this;
  }

  constructor() {
    super();
    this.setFieldType(EFieldType.GANTT_DATE_SELECTOR);
  }
}
