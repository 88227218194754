<h2>{{ chart.getName() }}</h2>
<as-split [style.height]="height" class="wrapper mat-elevation-z4">
  <as-split-area class="description" [size]="25">
    <ng-scrollbar [minThumbSize]="8" [appearance]="'compact'" class="scroller" [track]="'all'" [visibility]="'hover'">
      <ng-container *ngFor="let d of description || []">
        <div class="row" [saxmsTooltip]="d.getDescription()" [style.color]="d.getColor()">{{ d.getDescription() }}</div>
      </ng-container>
    </ng-scrollbar>
  </as-split-area>
  <as-split-area class="chart" #chartContainer [size]="75">
    <div #chartRef class="chart-outter-wrapper">
      <div
        #chartWrapper
        [ngStyle]="{ width: chartWidth + 'px', height: chartHeight + 'px', left: chartLeft + 'px' }"
        class="chart-wrapper"
      >
        <canvas
          *ngIf="data"
          (mouseleave)="closeTooltip()"
          baseChart
          class="chart-canvas"
          [data]="data"
          [type]="chartType"
          [options]="options"
          [legend]="true"
        >
        </canvas>
      </div>
    </div>
  </as-split-area>
</as-split>
