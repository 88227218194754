import { Injectable, Type } from '@angular/core';
import { TemplateAdapter } from '@app-modeleditor/utils/template-factory.service';
import { Adapter } from '../../../core/adapter';
import { TemplateResourceAdapter } from './../../utils/template-resource-factory.service';
import { TemplateMap } from './location-map';

@Injectable({
  providedIn: 'root',
})
export class LocationMapAdapter implements Adapter<TemplateMap> {
  constructor(private _templateResourceFactory: TemplateResourceAdapter) {}
  adapt(item: any): TemplateMap {
    throw new Error('Method not implemented.');
  }

  inherit?<T extends TemplateMap>(type: Type<T>, item: any): T {
    const t: T = this._templateResourceFactory
      .inherit<T>(type, item)
      .setMapLayers(item.mapLayers)
      .setLegendsActions(item.legendsActions)
      .setMinHeight(item.minHeight)
      .setTimedRangePicker(item.timedRangePicker);
    return t;
  }

  inheritFrom?<T extends TemplateMap>(scope: TemplateAdapter, type: Type<T>, item: any): T {
    const t: T = this._templateResourceFactory
      .inheritFrom<T>(scope, type, item)
      .setMapLayers(item.mapLayers)
      .setLegendsActions(item.legendsActions)
      .setMinHeight(item.minHeight)
      .setTimedRangePicker(item.timedRangePicker);
    return t;
  }

  applyValues<T>(scope: TemplateAdapter, item: TemplateMap, values: any): T {
    throw new Error('Method not implemented.');
  }
}
