import { InjectionToken } from '@angular/core';

export class ContextMenuButton {
  public id: string;
  public name: string;
  public disabled: boolean;
  public callback: any;
  public enabled = true;
  public icon: string;
  public customIcon: boolean;
  public globalActions: any[] = [];
  public actions: any[] = [];
  public localActions: any[] = [];
  public updateElementIds: string[];
  public data: any;
  public type = 'ContextMenuItem';
}

export interface IContextMenuGroup {
  id: string;
  name: string;
  items: ContextMenuButton[];
}

export interface IContextMenuTemplate {
  id: string;
  groups?: IContextMenuGroup[];
  generalItems?: ContextMenuButton[];
}

export const CONTEXT_MENU_TEMPLATE = new InjectionToken<IContextMenuTemplate>('CONTEXT_MENU_ITEMS');

export function createContextMenuButton(
  id: string,
  label: string,
  callback,
  disabled: boolean,
  entryElement: any,
  icon?: string,
  customIcon?: boolean
): ContextMenuButton {
  const contextMenuButton: ContextMenuButton = new ContextMenuButton();
  contextMenuButton.id = id;
  contextMenuButton.name = label;
  contextMenuButton.icon = icon;
  contextMenuButton.customIcon = customIcon;
  contextMenuButton.callback = callback;
  contextMenuButton.disabled = disabled;

  if (entryElement) {
    contextMenuButton.data = entryElement;
    contextMenuButton.data['enabled'] = contextMenuButton.enabled;
    contextMenuButton.updateElementIds = entryElement.updateElementIds;
    contextMenuButton.globalActions = entryElement.globalActions;
    contextMenuButton.localActions = entryElement.localActions;
    contextMenuButton.actions = entryElement.actions;
    contextMenuButton.id = entryElement.id;
    contextMenuButton.icon = entryElement.icon;
  }

  return contextMenuButton;
}
