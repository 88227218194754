import { Content } from '@app-modeleditor/components/content/content';
import { ContentPart } from '@app-modeleditor/components/content/content-part/content-part';
import { EntryCollection } from '@app-modeleditor/components/entry-collection/entry-collection';
import { IndexPage } from '@app-modeleditor/components/index-register/index-page';
import { IndexRegister } from '@app-modeleditor/components/index-register/index-register';
import { HMI } from '@app-modeleditor/components/tree/tree-item';
import { ETemplateType } from 'frontend/src/dashboard/model/resource/template-type';
import { ChartDataGroup } from '../generalChart/chart.mapper';
import { ChartLegendItem } from './chart-legend-item';
import { EChartLegendType } from './chart-legend-type.enum';
import { ChartAttributeLegend } from './chart-legend/chart-attribute-legend';
import { ChartDatasetLegend } from './chart-legend/chart-dataset-legend';

export class ChartLegendWrapperTemplate extends HMI {
  private legendItems: Map<EChartLegendType, ChartLegendItem[]> = new Map();
  private attributeIndexPage: IndexPage;
  private datasetIndexPage: IndexPage;
  private chartData: ChartDataGroup[] = [];
  private hiddenDatasets: Set<any>;
  private hiddenAttributes: Set<any>;
  private legendIndexRegister: IndexRegister;
  private quickFilterEntryCollection: EntryCollection;
  private chartDatasetLegend: ChartDatasetLegend;
  private chartAttributeLegend: ChartAttributeLegend;
  private widgetRefId: string;
  private height: number;

  public getHeight(): number {
    return this.height;
  }

  public setHeight(height: number): ChartLegendWrapperTemplate {
    this.height = height;
    const tabHeaderHeight = 31;
    const newHeight = Math.max(height - tabHeaderHeight, 300 - tabHeaderHeight);
    this.chartDatasetLegend.setHeight(newHeight);
    this.chartAttributeLegend.setHeight(newHeight);
    return this;
  }

  private legendItemClickCB: (item: ChartLegendItem, chartLegendWrapper: ChartLegendWrapperTemplate) => void;

  constructor() {
    super();

    this.chartAttributeLegend = new ChartAttributeLegend()
      .onChange(this.handleAttributeLegendClick.bind(this))
      .onAddAll(this.handleAddAllAttributeClick.bind(this))
      .onRemoveAll(this.handleRemoveAllAttributeClick.bind(this));

    this.chartDatasetLegend = new ChartDatasetLegend().onChange(this.handleChartDataset.bind(this));

    this.getAttributeIndexPage();
    this.getChartDatasetIndexPage();

    this.legendIndexRegister = new IndexRegister()
      .setType(ETemplateType.INDEX_REGISTER)
      .setTabResizeDynamic(true)
      .setIndexPages([this.datasetIndexPage, this.attributeIndexPage]);

    this.setType(ETemplateType.CONTENT);
    this.setContent(
      new Content().addContentParts(
        new ContentPart().setDisplayContentpartContainer(false).addContentElements(this.legendIndexRegister)
      )
    );
  }

  public setChartData(chartData: ChartDataGroup[]): ChartLegendWrapperTemplate {
    this.chartData = chartData.slice();
    return this;
  }
  public getChartData(): ChartDataGroup[] {
    return this.chartData;
  }

  public setWidgetRefId(widgetRefId: string): ChartLegendWrapperTemplate {
    this.widgetRefId = widgetRefId;
    return this;
  }
  public getWidgetRefId(): string {
    return this.widgetRefId;
  }

  public getHiddenAttributes(): Set<any> {
    return this.hiddenAttributes;
  }
  public getHiddenDatasets(): Set<any> {
    return this.hiddenDatasets;
  }

  public updateHiddenAttributes(hiddenAttributes: Set<any>) {
    this.hiddenAttributes = hiddenAttributes;
    this.chartAttributeLegend.getLegendItemPlainList().forEach((item) => {
      item.setActive(!this.hiddenAttributes.has(item.getId()));
    });
  }

  public mapLegend(
    legendInformations: any,
    chartData: ChartDataGroup[],
    hiddenDatasets: Set<any>,
    hiddenAttributes: Set<any>
  ): ChartLegendWrapperTemplate {
    this.hiddenAttributes = hiddenAttributes;
    this.hiddenDatasets = hiddenDatasets;
    this.chartData = chartData;
    if (legendInformations) {
      const allowedLegendTypes: string[] = legendInformations.allowedLegendTypes;
      if (allowedLegendTypes.includes(EChartLegendType.DATASET)) {
        this.legendItems.set(
          EChartLegendType.DATASET,
          this.chartData.map((chartDataGroup) => {
            return this.mapLegendItems(chartDataGroup, EChartLegendType.DATASET);
          })
        );

        this.chartDatasetLegend.updateLegendValues(this.legendItems.get(EChartLegendType.DATASET));
      }
      if (allowedLegendTypes.includes(EChartLegendType.ATTRIBUTE)) {
        const attributeValues = legendInformations.attributeMetadata.map((attribute) => {
          return this.mapLegendItems(attribute, EChartLegendType.ATTRIBUTE);
        });
        this.legendItems.set(EChartLegendType.ATTRIBUTE, attributeValues);

        this.chartAttributeLegend
          .setLegendItemPlainList(this.getLegendItemsPlainList(attributeValues))
          .updateLegendValues(attributeValues);
      }
      // set height to trigger height calculation for legends
      this.setHeight(this.height);
    }

    return this;
  }

  private mapChartDataGroup(item: ChartDataGroup): ChartLegendItem {
    return new ChartLegendItem()
      .setId(item.localID)
      .setLabel(item.label)
      .setActive(!this.hiddenDatasets.has(item.localID))
      .setColor(item.backgroundColor)
      .setType(EChartLegendType.DATASET)
      .setDatasetItem(item);
  }

  private mapAttributes(
    item: any,
    backendManaged?: boolean,
    parentKey?: string,
    parentItem?: ChartLegendItem
  ): ChartLegendItem {
    const legendItem: ChartLegendItem = new ChartLegendItem()
      .setId(`${parentKey || item.key}/${item.value}`)
      .setKey(parentKey || item.key)
      .setValue(item.value)
      .setActive(!this.hiddenAttributes.has(`${parentKey || item.key}/${item.value}`))
      .setLabel(item.name || item.strValue)
      .setType(EChartLegendType.ATTRIBUTE)
      .setManageByBackend(item.backendManaged)
      .setParentItem(parentItem);

    legendItem.setAvailableValues(
      (item.availableValues || []).map((childItem) => {
        return this.mapAttributes(childItem, backendManaged, item.key, legendItem);
      })
    );
    return legendItem;
  }

  private mapLegendItems(item: any | ChartDataGroup, mappingType: EChartLegendType): ChartLegendItem {
    switch (mappingType) {
      case EChartLegendType.DATASET:
        return this.mapChartDataGroup(item);
      case EChartLegendType.ATTRIBUTE:
        return this.mapAttributes(item);
      case EChartLegendType.PARENTGROUP:
        return;
      default:
    }
  }

  private getAttributeIndexPage() {
    this.attributeIndexPage = new IndexPage()
      .setId(`${EChartLegendType.ATTRIBUTE}_${this.getId()}`)
      .setName('Attribute')
      .setType(ETemplateType.INDEX_PAGE)
      .setContent(
        new Content().addContentParts(
          new ContentPart().setDisplayContentpartContainer(false).addContentElements(this.chartAttributeLegend)
        )
      );
  }

  private getLegendItemsPlainList(items: ChartLegendItem[]): ChartLegendItem[] {
    return [].concat(...items.map((item) => [item].concat(this.getLegendItemsPlainList(item.getAvailableValues()))));
  }

  private handleAddAllAttributeClick() {
    this.hiddenAttributes.clear();
    this.executeLegendItemClick(null, this);
  }

  private handleRemoveAllAttributeClick() {
    this.chartAttributeLegend.getLegendItemPlainList().forEach((legendItem) => {
      legendItem.setActive(false);
      this.hiddenAttributes.add(legendItem.getId());
    });
    this.executeLegendItemClick(null, this);
  }

  private handleAttributeLegendClick(item: ChartLegendItem) {
    this.getLegendItemsPlainList([item]).forEach((legendItem) => {
      if (legendItem.isActive()) {
        this.hiddenAttributes.delete(legendItem.getId());
      } else {
        this.hiddenAttributes.add(legendItem.getId());
      }
    });
    this.executeLegendItemClick(item, this);
  }

  private getChartDatasetIndexPage() {
    this.datasetIndexPage = new IndexPage()
      .setId(`${EChartLegendType.DATASET}_${this.getId()}`)
      .setName('Datensatz')
      .setType(ETemplateType.INDEX_PAGE)
      .setContent(
        new Content().addContentParts(
          new ContentPart().setDisplayContentpartContainer(false).addContentElements(this.chartDatasetLegend)
        )
      );
  }

  private getHiddenStateoFDataGroup(dataset: ChartDataGroup, item: ChartLegendItem) {
    if (dataset.stacklessVisible) {
      dataset.hidden = !item.isActive();
    } else {
      dataset.stackedLineLegendHidden = !item.isActive();
    }
    if (dataset.stack && dataset.originType == 'line') {
      dataset.calc = item.isActive();
    }
  }

  private handleChartDataset(item: ChartLegendItem) {
    const chartGroup = this.chartData.find((_chartGroup) => _chartGroup.localID === item.getId());
    if (chartGroup) {
      this.getHiddenStateoFDataGroup(chartGroup, item);
      if (item.isActive()) {
        this.hiddenDatasets.delete(item.getId());
      } else {
        this.hiddenDatasets.add(item.getId());
      }
    }

    this.executeLegendItemClick(item, this);
  }

  public onLegendItemClick(
    cb: (item: ChartLegendItem, chartLegendWrapper: ChartLegendWrapperTemplate) => void
  ): ChartLegendWrapperTemplate {
    this.legendItemClickCB = cb;
    return this;
  }

  public executeLegendItemClick(item: ChartLegendItem, chartLegendWrapper: ChartLegendWrapperTemplate): void {
    this.legendItemClickCB(item, chartLegendWrapper);
  }
}
