import { Overlay, OverlayRef, PositionStrategy } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { FileData } from '../filte-data';
import { ProgressDatasourceService } from './datasource.service';
import { ProgressComponent } from './progress.component';

@Injectable({
  providedIn: 'root',
})
export class ProgressService {
  private manager: OverlayRef;

  constructor(private overlay: Overlay, private datasourceApi: ProgressDatasourceService) {
    // this.createManager();
  }

  public updateFile(file: FileData): void {
    this.datasourceApi.setItems(
      this.datasourceApi.getValue().map((f: FileData) => (f.getId() === file.getId() ? file : f))
    );
  }

  public addFile(file: FileData): void {
    this.datasourceApi.setItems(this.datasourceApi.getValue().concat(file));
  }

  public removeFile(id: string): void {
    this.datasourceApi.setItems(this.datasourceApi.getValue().filter((f: FileData) => f.getId() !== id));
  }

  public minimizeManager(): void {
    this.datasourceApi.setOpened(false);
  }

  public expandManager(): void {
    this.datasourceApi.setOpened(true);
  }

  public closeManager(): void {
    this.manager.dispose();
  }

  public createManager() {
    if (this.manager) {
      return;
    }
    const positionStrategy: PositionStrategy = this.overlay.position().global().bottom('0px').right('5px');

    this.manager = this.overlay.create({
      hasBackdrop: false,
      positionStrategy,
      backdropClass: 'custom-backdrop',
    });
    this.manager.addPanelClass('saxms-fileupload-progress');

    const overlayPortal: ComponentPortal<ProgressComponent> = new ComponentPortal(ProgressComponent, null);
    const cmpRef = this.manager.attach(overlayPortal);

    // remove message from list of cmp got destroyed
    cmpRef.onDestroy(() => {});

    // this.updateMessages();
  }
}
