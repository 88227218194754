import { Resource } from 'frontend/src/dashboard/model/resource/resource';
import { ContextMenuItem } from './context-menu-item';

export class ContextMenuGroup extends Resource {
  private contextMenuItems: ContextMenuItem[];

  public getContextMenuItems(): ContextMenuItem[] {
    return this.contextMenuItems || [];
  }

  public setContextMenuItems(contextMenuItems: ContextMenuItem[]): this {
    this.contextMenuItems = contextMenuItems;
    return this;
  }

  public addContextMenuItems(...contextMenuItems: ContextMenuItem[]): this {
    this.contextMenuItems = this.getContextMenuItems().concat(contextMenuItems);
    return this;
  }
}
