import { Injectable } from '@angular/core';
import { ContextChangeLightbox } from '@app-modeleditor/components/lightbox/predefined/context-change-lightbox';
import { UiService } from '@app-modeleditor/ui.service';
import { TemplateService } from '@app-modeleditor/utils/template.service';
import { ConfigService } from '@core/config/config.service';
import { EMessageType, Message } from '@core/message/message';
import { MessageService } from '@core/message/message.service';
import { GlobalUtils } from 'frontend/src/dashboard/global-utils';
import { Template } from 'frontend/src/dashboard/model/resource/template';
import { BehaviorSubject, Observable, Observer, Subject, forkJoin, of, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { SharedUiService } from '../../../model-editor/ui/service/shared.ui.service';
import { UiAction } from '../../../model-editor/ui/service/ui-action.enum';
import { SharedToolbarService } from '../../../view/navbar/toolbar/shared-toolbar.service';
import { ToolbarAction } from '../../../view/navbar/toolbar/toolbar-action.enum';
import { EntryCollection } from '../entry-collection/entry-collection';
import { EntryElement } from '../entry-collection/entry-element';
import { EntryElementValue } from '../entry-collection/entry-element-value';
import { Lightbox } from '../lightbox/lightbox';
import { LightboxService } from '../lightbox/lightbox.service';
import { HMI } from '../tree/tree-item';
import { ETemplateMode } from './../../../model/resource/template-mode.enum';
import { ButtonService } from './../button/button.service';
import { IAfterSave } from './after-save.interface';
import { IContextChangeOptions } from './context-change-options.interface';
import { HierarchicalMenuItem } from './hierarchical-menu-item';
import { SavedRecord } from './saved-record';

@Injectable({
  providedIn: 'root',
})
export class TemplateUiService {
  public onaction: Subject<any> = new Subject<any>();
  public forceUpdateIds: any[];
  private onAfterSave: Subject<IAfterSave> = new Subject<IAfterSave>();
  private _currentLightbox: Lightbox;
  private _marked: BehaviorSubject<SavedRecord[]> = new BehaviorSubject<SavedRecord[]>([]);

  onMarkedChanged(): Observable<any[]> {
    return this._marked.asObservable();
  }

  setMarked(marked: SavedRecord[]): void {
    this._marked.next(marked);
  }

  setCustomActionHandler(handler: ButtonService): void {
    this._customActionHandler = handler;
  }

  constructor(
    private modelService: UiService,
    private toolbarService: SharedToolbarService,
    private uiService: SharedUiService,
    private messageApi: MessageService,
    private _lightboxApi: LightboxService,
    private _templateApi: TemplateService,
    private _messageApi: MessageService,
    private _customActionHandler: ButtonService,
    private configService: ConfigService
  ) {
    this._lightboxApi.getOpenedLightboxes().subscribe((lightboxes: Lightbox[]) => {
      this._currentLightbox = !lightboxes || lightboxes.length === 0 ? null : lightboxes.pop();
    });

    this._lightboxApi.onDialogClosed().subscribe((lightbox: Lightbox) => {
      this.unmarkLightboxFromSave(lightbox.getId(), lightbox.getResourceId());
    });

    this.toolbarService.getAction().subscribe((action) => {
      if (!action) {
        return;
      }

      switch (action.type) {
        case ToolbarAction.SAVE:
          this.saveAll().subscribe();
          break;
      }
    });
  }

  public afterSave(): Observable<IAfterSave> {
    return this.onAfterSave.asObservable();
  }

  /**
   * return marked data
   */
  getMarked(): SavedRecord[] {
    return this._marked.getValue() || [];
  }

  /**
   * marks element for save
   * @param _data dataset
   * @param path string[]
   * @param order saveOrder
   */
  markForSave(_data, path?: string[], node?: Template, resourceId?: string): void {
    if (_data instanceof Event) {
      return;
    }

    const marked: SavedRecord[] = this.getMarked();
    const key: string = path.shift();
    // if not exists
    let el: any = this.checkIfExists(marked, key);
    // key already exists
    if (!el) {
      const r = new SavedRecord();
      const d = r.getData();
      d[key] = {};

      r.setResourceId(resourceId || null)
        .setLightboxId(
          this._currentLightbox ? this._currentLightbox.getId() : node instanceof Lightbox ? node.getId() : null
        )
        .setTemplateId(node ? node.getId() : null)
        .setData(d)
        .setSaveOrder(_data?.saveOrder ? _data.saveOrder : node ? node.getSaveOrder() : null);

      marked.push(r);

      // if length of path is already zero, apply _data
      if (path.length === 0) {
        marked[marked.length - 1].getData()[key] = _data;
        return this.setMarked(marked);
      }
      el = marked[marked.length - 1].getData()[key];
      // key doesn't exists
    } else {
      if (path.length === 0) {
        el.getData()[key] = _data;
        return this.setMarked(marked);
      }
      el = el.getData()[key];
    }

    // compute as long as length of path is not zero
    while (path.length > 0) {
      const k: string = path.shift();
      if (path.length === 0) {
        el[k] = _data;
        break;
      } else if (!el[k]) {
        el[k] = {};
      }
      el = el[k];
    }

    this.setMarked(marked);
  }

  /**
   * checks if key already exists
   * @param _list list
   * @param key key
   */
  private checkIfExists(_list: SavedRecord[], key: string): SavedRecord {
    for (const _el of _list) {
      for (const _key of Object.keys(_el.getData())) {
        if (_key === key) {
          return _el;
        }
      }
    }

    return null;
  }

  public hasUnsavedChangesInResourceId(resourceId: string): boolean {
    return (this._marked.getValue() || []).find((m) => m.getResourceId() === resourceId) ? true : false;
  }

  /**
   * unmark everything related to the given resurces id
   * @param resourceId resourceId to unmark changes from
   * @returns void
   */
  public unmarkResourceIdFromSave(resourceId: string): void {
    this.setMarked((this.getMarked() || []).filter((m) => (m.getResourceId() === resourceId ? false : true)));
  }

  /**
   * unmark everything related to the given template id
   * @param templateId templateId to unmark changes from
   * @returns void
   */
  public unmarkTemplateIdFromSave(templateId: string): void {
    this.setMarked((this.getMarked() || []).filter((m) => m.getTemplateId() !== templateId));
  }

  /**
   * unmark everything related to the given resurces id
   * @param lightboxId lightbox to unmark changes from
   * @returns void
   */
  public unmarkLightboxFromSave(lightboxId: string, resourceId: string): void {
    this.setMarked(
      (this.getMarked() || []).filter((m) =>
        m.getLightboxId() === lightboxId && m.getResourceId() === resourceId ? false : true
      )
    );
  }

  /**
   * unmarks element from save
   * @param key elements key
   */
  unmarkFromSave(key): void {
    const newMarked = [];
    if (!this.getMarked()) {
      return;
    }
    for (const el of this.getMarked()) {
      if (key !== Object.keys(el.getData())[0]) {
        newMarked.push(el);
      }
    }

    this.setMarked(newMarked.slice());
  }

  /**
   * clear marked changes
   * @param {Template} templateNode template the elements belongs to
   * @param {string[]} listOfEntries list of elements to remove. Leave empty to clear everything
   * @param {boolean} forceClear whether the value should be cleared or reapplied to changes
   * @returns void
   */
  public clearChangesOfTemplate(templateNode: Template, listOfEntries: string[] = undefined, forceClear = false): void {
    if (!(templateNode instanceof EntryCollection)) {
      return;
    }
    const changes: SavedRecord[] = this._marked.getValue();
    const templateChange = changes.find((change) => change.getResourceId() === templateNode.getResourceId());

    if (!templateChange) {
      return;
    }
    const dataKey: string = Object.keys(templateChange.getData())[0];
    templateNode
      .getEntryElements()
      .filter((entryElement: EntryElement) =>
        !listOfEntries ? true : listOfEntries.includes(entryElement.getId()) ? true : false
      )
      .forEach((entryElement: EntryElement) => {
        if (Object.keys(templateChange.getData()[dataKey] || {}).includes(entryElement.getId())) {
          if (forceClear) {
            delete templateChange.getData()[dataKey][entryElement.getId()];
          } else {
            templateChange.getData()[dataKey][entryElement.getId()].value = entryElement
              .getValue<EntryElementValue>()
              ?.getValue();
          }
        }
      });

    if (Object.keys(templateChange.getData()[dataKey] || {}).length === 0) {
      delete templateChange.getData()[dataKey];
    }
  }

  /**
   * triggers an action
   * @param action any
   */
  public trigger(action: any): void {
    this.onaction.next(action);
  }

  /**
   * clear all unsaved data
   */
  public clearAll(): boolean {
    this.setMarked([]);
    return true;
  }

  private clearObservable(observer, data) {
    observer.next(data);
    observer.complete();
    return of(null);
  }

  private customSave(node, cb: () => void = null): Observable<any> {
    return (
      node && node.onCustomSaveAction
        ? this._customActionHandler?.onClick(node.onCustomSaveAction)
        : this.saveAll({ skipUpdate: true })
    ).pipe(switchMap(() => of(cb ? cb() : true)));
  }

  /**
   * checks template for changes
   * @returns Observable<boolean>
   */
  public checkContextChange(
    targetNode: HMI | HierarchicalMenuItem,
    options: IContextChangeOptions = null,
    currentNode: HMI | HierarchicalMenuItem = null
  ): Observable<boolean> {
    if (!targetNode) {
      return of(true);
    }
    // no unsaved data
    if (this.getMarked().length === 0) {
      return of(true);
    }

    if (currentNode.getTemplateMode() === ETemplateMode.REQUIRE_EDIT_MODE) {
      return this.customSave(currentNode);
    }

    // if context has unsaved data show lightbox and use custom save action of current node
    return new Observable((observer: Observer<any>) => {
      const l: ContextChangeLightbox = new ContextChangeLightbox(options?.title, options?.text);
      if (currentNode instanceof HierarchicalMenuItem && currentNode?.getOnCustomSaveAction()) {
        l.setCustomSaveAction(() => {
          return this.customSave(currentNode, () => this.clearObservable(observer, this.clearAll()));
        });
      }

      l.setCustomDiscardAction(() => this.clearObservable(observer, this.clearAll()));
      l.setCustomCancelAction(() => this.clearObservable(observer, false));

      this._lightboxApi.open(l);
    });
  }

  public canBeSaved(): boolean {
    if (!this._marked || this.getMarked().length === 0 || this._getInvalidTemplates().length > 0) {
      return false;
    }

    return true;
  }

  private _getInvalidTemplates(): Template[] {
    return (this.getMarked() || [])
      .map((r) => this._templateApi.getElementById(r.getTemplateId(), r.getResourceId()))
      .filter((t: Template) => t && !t.isValid() && t.isEdited());
  }

  private _getInvalidNames(templates: Template[]): string {
    return (templates || [])
      .filter((t) => !!t)
      .reduce(
        (prev: Template[], t: Template) =>
          t.getInvalidTemplates().length > 0
            ? [...prev, ...t.getInvalidTemplates().map((t) => t.getName())]
            : [...prev, t.getName()],
        []
      )
      .join(', ');
  }

  /**
   * save all templates
   * @param options ITemplateSaveOptions
   */
  public saveAll(options?: ITemplateSaveOptions): Observable<boolean> {
    // check for unsaved data
    if (this.getMarked().length === 0) {
      return of(false);
    }

    const templates: Template[] = this._getInvalidTemplates();

    if (templates.length > 0) {
      this._messageApi.show(
        new Message()
          .setType(EMessageType.ERROR)
          .setText(`TEMPLATE.cant_update_lists`)
          .setParams({ value: this._getInvalidNames(templates) })
      );

      return throwError(() => new Error('Templates not valid'));
    }

    // sort marked data
    this.setMarked(
      this.getMarked().sort((a, b) => {
        if (a.getSaveOrder() < b.getSaveOrder()) {
          return -1;
        }
        if (a.getSaveOrder() > b.getSaveOrder()) {
          return 1;
        }
        return 0;
      })
    );

    const saveRequests = this.getSaveRequests();

    // disable save button
    this.toolbarService.trigger({
      type: ToolbarAction.DISABLE,
      data: ToolbarAction.SAVE,
    });

    if (saveRequests.length === 0) {
      this.clearAll();
      return of(true);
    }
    // join all async save operations
    return forkJoin(saveRequests).pipe(
      map((result: IAfterSave[]) => {
        // reset data
        this.clearAll();
        this.uiService.trigger({ type: UiAction.REFRESH_TREE, data: null });
        this.toolbarService.trigger({
          type: ToolbarAction.FINISH_SAVE,
          data: null,
        });
        this.toolbarService.trigger({
          type: ToolbarAction.UPDATE_MAP,
          data: null,
        });

        if (!options || !options.skipUpdate) {
          this.forceUpdate(this.forceUpdateIds);
        }
        // show success message
        this.messageApi.show(
          new Message().setType(EMessageType.INFO).setText('SYSTEM.MESSAGE.SAVE.success').setDuration(7500)
        );

        const res = (result || []).find((entry) => typeof entry.response === 'string');
        this.onAfterSave.next(res);
        return true;
      })
    );
    // disable save button
  }

  /**
   * Retrieves an array of observables representing save requests.
   * If advanced save is enabled, it creates a payload with the necessary data and sends a single save request.
   * Otherwise, it creates an array of observables for each save operation.
   *
   * @returns An array of observables representing save requests.
   */
  private getSaveRequests(): Observable<unknown>[] {
    let subs: Observable<unknown>[] = [];
    const isAdvancedSaveEnabled = this.configService.access()?.advancedSave?.enabled || false;
    const validUrlFilter = (el: SavedRecord) => {
      const url = Object.keys(el.getData())[0];
      return !!url && url !== 'undefined';
    };

    if (isAdvancedSaveEnabled) {
      const payload: { url: string; data: any; saveId: string }[] = [];
      this.getMarked()
        .filter(validUrlFilter)
        .forEach((el) => {
          const url = Object.keys(el.getData())[0];
          payload.push({
            url: url,
            data: el.getData()[url],
            saveId: GlobalUtils.generateUUID(),
          });
        });

      if (payload.length) {
        const request = this.modelService.postData(this.configService.access().advancedSave.url, payload);

        subs = [request];
      }
    } else {
      // fill array with all async save operations
      subs = this.getMarked()
        .filter(validUrlFilter)
        .map((el) => {
          const url = Object.keys(el.getData())[0];
          return this.modelService.postData(url, el.getData()[url]).pipe(
            catchError((e) => {
              if (el.isSkipOnError()) {
                return of(null);
              }
              return throwError(e);
            }),
            map((res) => {
              return {
                response: res,
                savedRecord: el,
              };
            })
          );
        });
    }
    return subs;
  }

  forceRefeshData(listOfIds: string[]): void {
    if (!listOfIds) {
      return;
    }

    this._templateApi.refreshElementData(listOfIds);

    listOfIds = null;
  }

  /**
   * force to update tempaltes by id
   * @param listOfIds string[]
   */
  forceUpdate(listOfIds: string[]) {
    if (!listOfIds) {
      return;
    }

    // for (const updateId of listOfIds) {
    // this.modeleditorService.reload(updateId);
    this._templateApi.updateElements(listOfIds);
    // }
    // new UpdateElementsAction(this.injector, listOfIds).execute();
    listOfIds = null;
  }
}

export interface ITemplateSaveOptions {
  skipUpdate: boolean;
}
