import { GanttXAxisFormatGeneral } from './x-axis-format-general';

/**
 * * @description Predifend x-Axis #1. <br>
 * **Low** level of detail. <br>
 * Uses **default dateFormat** List from Base Class
 * @extends {GanttXAxisFormatGeneral}
 */
export class GanttXAxisFormat1 extends GanttXAxisFormatGeneral {
  constructor() {
    super(null);

    this.cssClassNameText = 'x_axis_text_top';
    this.renderVerticalLinesOnShifts = false;
  }
}
