<div class="tree-wrapper mat-typography custom-scrollbar" #treeWrapper [id]="model?.getId()">
  <ng-container *ngIf="treeWrapper">
    <as-split
      [gutterSize]="8"
      [direction]="'horizontal'"
      #mainSplitter
      (dragEnd)="onDragEnd($event)"
      (gutterClick)="onGutterClick($event)"
      useTransition="true"
      (transitionEnd)="onTransitionEnd()"
    >
      <as-split-area [order]="1" [size]="treeSizes[0]">
        <!-- tree -->
        <div [style.width]="'100%'" [style.padding]="'5px 0 0 0'" class="wrapper-container main mat-elevation-z8">
          <!-- SELECT COLUMN ROW -->
          <div #modeSelectionRef class="mode-selection">
            <!-- enough space - list elements -->
            <ng-container *ngIf="modeSelectionWidth > 140; else modeSelectionMenu">
              <ng-container *ngTemplateOutlet="modeSelectionList"></ng-container>
            </ng-container>
            <!-- miss space - menu -->
            <ng-template #modeSelectionMenu>
              <button
                mat-icon-button
                class="mode-select-menu"
                [matTooltip]="'more' | translate"
                [matTooltipShowDelay]="750"
                [matMenuTriggerFor]="modeSelectionMenuElements"
              >
                <app-image [label]="'more_vert'"></app-image>
              </button>
              <mat-menu #modeSelectionMenuElements="matMenu">
                <ng-container *ngTemplateOutlet="modeSelectionList"></ng-container>
              </mat-menu>
            </ng-template>

            <!-- row content -->
            <ng-template #modeSelectionList>
              <button
                mat-icon-button
                class="collapse-tree-column-button"
                (click)="onCollapseTree()"
                [matTooltipShowDelay]="450"
                [matTooltip]="'TREE.COLLAPSE' | translate"
              >
                <app-image *ngIf="!allNodesCollapsed" [label]="'unfold_less'"></app-image>
                <app-image *ngIf="allNodesCollapsed" [label]="'unfold_more'"></app-image>
              </button>
              <mat-button-toggle-group
                class="column-toggle-group"
                #group="matButtonToggleGroup"
                [disabled]="hasPendingActions$ | async"
                (change)="onModeChange($event.value)"
              >
                <mat-button-toggle
                  [matTooltipShowDelay]="450"
                  [matTooltip]="'TREE.MODE.SINGLE_COLUMN' | translate"
                  [disabled]="BatchModeDisabled"
                  class="mode-toggle"
                  value="SINGLE_COLUMN"
                  [checked]="getMode() === 'SINGLE_COLUMN'"
                >
                  <app-image [label]="'THREE_COLUMNS'"></app-image>
                </mat-button-toggle>
                <mat-button-toggle
                  [matTooltipShowDelay]="450"
                  [matTooltip]="'TREE.MODE.TWO_COLUMNS' | translate"
                  id="toggle-two-button"
                  [disabled]="ColumnModeDisabled"
                  class="mode-toggle"
                  value="TWO_COLUMNS"
                  [checked]="getMode() === 'TWO_COLUMNS'"
                >
                  <app-image [label]="'TWO_COLUMNS'"></app-image>
                </mat-button-toggle>
              </mat-button-toggle-group>
              <app-last-visited-content class="last-visited-button"></app-last-visited-content>
            </ng-template>
          </div>
          <!-- TREE -->
          <div class="tree-wrapper-column" #outerWrapper>
            <template-tree
              #templateTree
              [selected]="_selectedHMI"
              [nodes]="nodes"
              [root]="outerWrapper"
              (allcollapsed)="allNodesCollapsed = $event"
            >
            </template-tree>
          </div>
        </div>
      </as-split-area>

      <as-split-area
        [order]="2"
        *ngIf="getMode() === 'TWO_COLUMNS' && _selectedHMI"
        [size]="treeSizes[0] + treeSizes[1] < 100 ? treeSizes[1] : 100 - treeSizes[0]"
      >
        <div class="tree-wrapper-column second">
          <app-tree-second-column
            #secondCol
            [displayedItems]="currentDisplayedItems"
            (onCreate)="createElement(_selectedHMI)"
            [parent]="_selectedHMI"
            [selectedResourceId]="modeleditorData?.getResourceId()"
          >
          </app-tree-second-column>
        </div>
      </as-split-area>
      <as-split-area
        [order]="3"
        class="third-content"
        #thirdAreatem
        [size]="
          getMode() === 'TWO_COLUMNS' && _selectedHMI
            ? treeSizes[0] + treeSizes[1] < 100
              ? 100 - treeSizes[0] - treeSizes[1]
              : 0
            : 100 - treeSizes[0]
        "
      >
        <div class="wrapper-container content" #contentContainer [style.height]="contentContainerHeight">
          <mat-progress-bar *ngIf="!(!isLoadingContent || checkContextChange)" class="content-loader" mode="buffer">
          </mat-progress-bar>
          <ng-container *ngIf="!isLoadingContent || checkContextChange">
            <app-template-ui
              [onCloseCb]="closeContent"
              #modeleditorUiRef
              [style.width]="'100%'"
              *ngIf="modeleditorData"
              [model]="modeleditorData"
            >
            </app-template-ui>
            <!-- Node Networks -->
            <node-network *ngIf="nodeNetworkData" [data]="nodeNetworkData"></node-network>
            <app-template-overlay
              *ngIf="templateOverlayData"
              [data]="templateOverlayData"
              (close)="onCloseTemplateOverlay($event)"
            >
            </app-template-overlay>
          </ng-container>
          <!-- <template-sidebar [style.top]="'41px'" [style.height]="'calc(100% - 41px)'" [style.right]="scrollbarWidth+'px'"
          [template]="modeleditorData?.sideBar" *ngIf="sidebarEnabled && modeleditorData?.sideBar">
        </template-sidebar> -->
        </div>
        <div #editbarRef>
          <!-- <app-editmode-bar *ngIf="!selectedNode?.isHiddenTemplate('EDIT_MODE_BAR')" [menuItem]="selectedNode">
          </app-editmode-bar> -->
        </div>
      </as-split-area>
    </as-split>
  </ng-container>
</div>
