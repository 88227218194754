import * as d3 from 'd3';
import { ComponentEvent } from '../../../history/component-event';

/**
 * @constructor
 * @class
 * @extends ComponentEvent
 * @requires ComponentEvent
 * @plugin shift-mover
 */
export class GanttShiftMoverEvent extends ComponentEvent {
  constructor() {
    super();
    /**
     * @type {GanttShiftMover}
     */
    this.ref = null;
  }

  /**
   * @override
   * @returns {boolean} true if undo was succesfull, else false
   */
  undo() {
    const s = this;
    let success = false;
    switch (s.eventName) {
      case 'moveShift': {
        success = s._updateShiftTimeUndo(s.arguments[0], s.arguments[1]);
        break;
      }
      default:
        console.warn('can not undo: ' + s.eventName + '. May not be implemented yet.');
    }
    return success;
  }

  /**
   * @override
   * @returns {boolean} true if redo was succesfull, else false
   */
  redo() {
    const s = this;
    let success = false;
    switch (s.eventName) {
      case 'moveShift': {
        success = s._updateShiftTimeRedo(s.arguments[0], s.arguments[2], s.arguments[3], s.arguments[4]);
        break;
      }
      default:
        console.warn('can not redo: ' + s.eventName + '. May not be implemented yet.');
    }
    return success;
  }

  /**
   *
   * @param {GanttDataShift} dataShift
   * @member {GanttShiftMover} ref
   * @returns {boolean} true if undo succesful, else false
   */
  private _updateShiftTimeUndo(dataShift, rowID) {
    const s = this;
    s.ref.changeDataSet(dataShift.id, rowID, dataShift.timePointStart, dataShift.timePointEnd);
    s.ref.ganttDiagram.rerenderShiftsVertical();
    return true;
  }

  /**
   *
   *
   * @param {GanttDataShift} dataShift
   * @param {String} rowID
   * @param {Date} timePointStart
   * @param {Date} timePointEnd
   * @returns {boolean} true if redo succesful, else false
   */
  private _updateShiftTimeRedo(dataShift, rowID, timePointStart, timePointEnd) {
    const s = this;
    s.ref.changeDataSet(dataShift.id, rowID, timePointStart, timePointEnd);
    s.ref.ganttDiagram.rerenderShiftsVertical();
    return true;
  }

  // GETTER and SETTER inherited from Main Class
  /**
   * @override
   *
   * @returns {String} description of event.
   */
  getEventDescription() {
    const s = this;
    let description = '';
    switch (s.eventName) {
      case 'moveShift': {
        description = ShiftMoverEventDescription.getMoveShiftDescription(
          s.ref.ganttDiagram.getConfig().getLanguage(),
          s.arguments
        );
        break;
      }
      default:
        console.warn('no description for: ' + s.eventName + ' set. May not be implemented yet.');
    }
    return description;
  }
}

export class ShiftMoverEventDescription {
  private constructor() {}

  static getMoveShiftDescription(language, args) {
    const s = this;
    const formatTimeDE = d3.timeFormat('%H:%M:%S am %d %B %Y');
    const formatTimeEN = d3.timeFormat('%H:%M:%S on %B %d %Y');
    let description = '';
    if (language === 'DE') {
      description =
        'Bewege Schicht: ' +
        args[0].id +
        ' aus Reihe ' +
        args[1] +
        ' in Reihe ' +
        args[2] +
        '.\n' +
        'Ändere die Zeit dieser Schicht von ' +
        formatTimeDE(new Date(args[0].timePointStart)) +
        ' bis ' +
        formatTimeDE(new Date(args[0].timePointEnd)) +
        ' zu  ' +
        formatTimeDE(args[3]) +
        ' bis ' +
        formatTimeDE(args[4]) +
        '.';
    }
    if (language === 'EN') {
      description =
        'Moving Shift: ' +
        args[0].id +
        ' from row ' +
        args[1] +
        ' to row ' +
        args[2] +
        '.\n' +
        'Changing same shifts time from ' +
        formatTimeEN(new Date(args[0].timePointStart)) +
        ' - ' +
        formatTimeEN(new Date(args[0].timePointEnd)) +
        ' to ' +
        formatTimeEN(args[3]) +
        ' - ' +
        formatTimeEN(args[4]) +
        '.';
    }
    return description;
  }
}
