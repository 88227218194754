import { Injectable, Type } from '@angular/core';
import { Content } from '@app-modeleditor/components/content/content';
import { EntryCollectionAdapter } from '@app-modeleditor/components/entry-collection/entry-collection-adapter.service';
import { TemplateAdapter } from '@app-modeleditor/utils/template-factory.service';
import { Adapter } from '../../../core/adapter';
import { StructuralAdapter } from '../structure/structural-factory.service';
import { ContentHeader } from './content-header';
import { ContentPart } from './content-part/content-part';
import { ContentPartAdapter } from './content-part/content-part-adapter.service';

@Injectable({
  providedIn: 'root',
})
export class ContentAdapter implements Adapter<Content> {
  constructor(
    private _structuralAdapter: StructuralAdapter,
    private _entryCollectionAdapter: EntryCollectionAdapter,
    private _contentPartAdapter: ContentPartAdapter
  ) {}

  adapt(item: any): Content {
    throw new Error('Method not implemented.');
  }

  inherit?<T extends Content>(type: Type<T>, item: any): T {
    const t: T = this._getData(this._structuralAdapter.inherit<T>(type, item), item);
    return t;
  }

  inheritFrom?<T extends Content>(scope: TemplateAdapter, type: Type<T>, item: any): T {
    const t: T = this._getData(this._structuralAdapter.inheritFrom<T>(scope, type, item), item, scope);
    return t;
  }

  applyValues<T>(scope: TemplateAdapter, item: any, values: any): T {
    throw new Error('Method not implemented.');
  }

  private _getData<T extends Content>(element: T, item: any, scope?: TemplateAdapter): T {
    const t: T = element
      .setContentHeader(
        this._entryCollectionAdapter.inheritFrom<ContentHeader>(scope, ContentHeader, item.contentHeader)
      )
      .setContentParts(
        (item.contentParts || []).map((h) => {
          const part: ContentPart = this._contentPartAdapter.inheritFrom(scope, ContentPart, h);
          return part;
        })
      );
    return t;
  }
}
