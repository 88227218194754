import { Injectable, Type } from '@angular/core';
import { TemplateAdapter } from '@app-modeleditor/utils/template-factory.service';
import { Adapter } from '../../../core/adapter';
import { ButtonAdapter } from '../button/button-adapter.service';
import { Datepicker } from './datepicker';

@Injectable({
  providedIn: 'root',
})
export class DatepickerAdapter implements Adapter<Datepicker> {
  constructor(private _entryElementAdapter: ButtonAdapter) {}

  adapt(item: any): Datepicker {
    throw new Error('Method not implemented.');
  }

  inherit?<T extends Datepicker>(type: Type<T>, item: any): T {
    return this._setProperties(this._entryElementAdapter.inherit(type, item), item);
  }

  inheritFrom?<T extends Datepicker>(scope: TemplateAdapter, type: Type<T>, item: any): T {
    return this._setProperties(this._entryElementAdapter.inheritFrom(scope, type, item), item);
  }

  applyValues<T>(scope: TemplateAdapter, item: any, values: any): T {
    throw new Error('Method not implemented.');
  }

  private _setProperties<T extends Datepicker>(item: T, data: any): T {
    item
      .setMinDate(data.minDate ? new Date(data.minDate) : null)
      .setMaxDate(data.maxDate ? new Date(data.maxDate) : null)
      .setDateTimeFormat(data.dateTimeFormat);
    return item;
  }
}
