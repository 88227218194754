import { Action } from '@app-modeleditor/components/button/action/action';
import { Button } from '@app-modeleditor/components/button/button';
import { EButtonDisplayType } from '@app-modeleditor/components/button/button-display-type.enum';
import { of } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { Gantt_General } from '../../general.gantt.component';
import { GanttEssentialPlugIns } from '../../plugin/e-gantt-essential-plugins';
import { GanttXAxisFormatPlugIn } from '../../plugin/plugin-list/time-formatting/xaxis-formatter';

export class TimeFormatElement extends Button {
  constructor(private scope: Gantt_General, private timeFormat: 'WEEK' | 'DAY') {
    super();
    this.setDisplayBackground(false);
    this.setAlwaysEnabled(true);
    const i = this.scope.ganttResultData.response.hierarchicalPlan;
  }

  get(data: any): this {
    return this.setIcon(this.getTimeFormatIcon())
      .setName(data.name)
      .setId(data.id)
      .setDisplayType(EButtonDisplayType.ICON_WITH_LABEL_BELOW)

      .chainActions(new Action().setCb(() => of(this.toggleTimeFormat(this.timeFormat))));
  }

  private getTimeFormatIcon() {
    switch (this.timeFormat) {
      case 'WEEK':
        return 'date_range';
      case 'DAY':
        return 'today';
    }
  }

  public toggleTimeFormat(type: 'WEEK' | 'DAY'): void {
    const timeManipulator: GanttXAxisFormatPlugIn = this.scope.ganttPluginHandlerService.getEssentialPlugIn(
      GanttEssentialPlugIns.XAxisFormatterPlugIn
    );

    timeManipulator.activeFormat.pipe(takeWhile(() => this.isAlive())).subscribe((activeFormat) => {
      this.setSelected(activeFormat === this.timeFormat);
    });

    switch (type) {
      case 'WEEK':
        timeManipulator.toggleFullWeekFormat();
        break;
      case 'DAY':
        timeManipulator.toggleFullDayFormat();
        break;
    }
  }
}
