<div class="saxms-selector">
  <div class="list-container">
    <ng-container *ngFor="let item of template.getValue()?.getValue() || []; let i = index">
      <div class="saxms-selector-item">
        <mat-checkbox class="selector-item" [checked]="item.isChecked()" (change)="onItemClicked($event, item)">
          <span class="name">{{ item.getName() }} </span>
          <span *ngIf="item.getCreated()" class="date">
            {{ item.getCreated() | date : 'dd.MM.yyyy HH:mm' }}
          </span>
        </mat-checkbox>
      </div>
    </ng-container>
  </div>
  <button [matMenuTriggerFor]="menuElement" mat-button class="expand">
    <mat-icon>arrow_drop_down</mat-icon>
  </button>
</div>

<mat-menu [overlapTrigger]="false" #menuElement="matMenu" [yPosition]="'below'">
  <ng-container *ngFor="let item of template.getValue()?.getValue() || []; let i = index">
    <div [style.padding]="'5px'">
      <mat-checkbox
        (click)="stopPropagation($event)"
        [checked]="item.isChecked()"
        (change)="onItemClicked($event, item)"
      >
        <mat-icon *ngIf="item.getIcon()" class="iconItem">{{ item.getIcon() }}</mat-icon>
        <span class="name">{{ item.getName() }} </span>
        <span *ngIf="item.getCreated()" class="date">
          {{ item.getCreated() | date : 'dd.MM.yyyy HH:mm' }}
        </span>
      </mat-checkbox>
      <mat-divider></mat-divider>
    </div>
  </ng-container>
  <mat-divider></mat-divider>
</mat-menu>
