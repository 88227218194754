import { Component, ContentChild, Input, OnDestroy, OnInit } from '@angular/core';
import { takeWhile } from 'rxjs/operators';
import { SaxmsSidebarService } from './saxms-sidebar.service';

@Component({
  selector: 'saxms-sidebar',
  templateUrl: './saxms-sidebar.component.html',
  styleUrls: ['./saxms-sidebar.component.scss'],
})
export class SaxmsSidebarComponent implements OnInit, OnDestroy {
  @Input() panels: ISaxmsSidebarPanel[];
  @Input() _toggle: boolean;
  @ContentChild('categoryTemplate') categoryTemplate: any;
  @Input() title: string;
  _currentPanel: ISaxmsSidebarPanel;
  @Input() id: string;
  @Input() isActive: boolean;
  @Input() bottomNav: boolean;
  fullscreen: boolean;
  private alive = true;
  activeSidebar;

  constructor(private sidenav: SaxmsSidebarService) {
    this.sidenav
      .getCurrentActiveSidebar()
      .pipe(takeWhile(() => this.alive))
      .subscribe((activeSidebar: any) => {
        this.activeSidebar = activeSidebar;
        this._currentPanel = (this.panels || []).find((p) => p.id === activeSidebar);
      });
  }

  ngOnDestroy(): void {
    this.alive = false;
    Object.keys(this).map((k) => (this[k] = null));
  }

  /**
   * toggles between fullscreen mode and normal mode
   * @returns void
   */
  toggleFullscreen(): void {
    this.fullscreen = !this.fullscreen;
  }

  ngOnInit(): void {}

  setCurrentPanel(panel: ISaxmsSidebarPanel): void {
    this.sidenav.toggle(panel.id);
  }
}

export interface ISaxmsSidebarPanel {
  id: string;
  title: string;
  icon?: string;
  hidePanel?: boolean;
  selected?: boolean;
  categories?: ISaxmsSidebarPanelCategory[];
}

export interface ISaxmsSidebarPanelCategory {
  title: string;
  text: string;
}
