import { HSLColor } from './hsl-color';

/**
 * Colorizer to add color filter to nodes of node network.
 */
export class NodeNetworkColorizer {
  // current colorMap
  private colorMap: any;

  /**
   * Creates color map for all values of given attribute.
   * @param attribute Attribute to colorize.
   * @param attributeValues All attribute values.
   * @param lightness HSL-value for lightness.
   */
  public setHSLColorMap(attribute: string, attributeValues: string[], lightness: number): any {
    this.colorMap = {};

    if (!attributeValues || !attributeValues.length) {
      return this.colorMap;
    }

    let index = 0;
    const ratio: number = 360 / attributeValues.length;
    if (lightness == undefined) {
      lightness = 80;
    }

    for (const attribute of attributeValues) {
      const hslColor: HSLColor = new HSLColor(ratio * index, 100, lightness);
      this.colorMap[attribute.toString()] = hslColor.toHex();
      index++;
    }
    return this.colorMap;
  }

  /**
   * Returns color map.
   */
  public getColorMap(): any {
    return this.colorMap;
  }
}
