import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SystemMessageComponent } from '../system/system-messages/system-message.component';
import { AbstractGridService } from './abstract-grid.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

// TODO won't work stable
@Injectable({
  providedIn: 'root',
})
export class GridService extends AbstractGridService {
  private loader;
  sub: Subscription;

  constructor(
    public http: HttpClient,
    public dialog: MatDialog,
    public systemMessage: MatSnackBar,
    private translate: TranslateService
  ) {
    super();
  }

  /**
   * edit an existing tab by id
   * @param resultReportId id of the resultReport
   * @param body tab object
   */
  public editTab(resultReportId: string, body: any): Observable<any> {
    return this.http.post(`rest/resultreport/${resultReportId}/tab/update`, body);
  }

  public getResultReport(id: string): Observable<any> {
    return this.http.get(`rest/experiment/${id}/result`);
  }

  public putFavourite(id: string, favourite: boolean): Observable<any> {
    return this.http.get(`rest/resultreport/tab/${id}?isFavorite=${favourite}`);
  }

  /**
   * creates a new tab
   * @param id uuid of the new tab
   * @param body tab object
   */
  public addTab(id: string, body: any): Observable<any> {
    return this.http.put(`rest/resultreport/${id}/tab`, body);
  }

  /**
   * get data to fill widget
   * @param experimentId experiment ID
   * @param widgetId widget ID
   */
  public getWidgetData(experimentId: string, widgetId: string, extra?: string): Observable<any> {
    return this.getR(experimentId, widgetId, extra);
  }

  getR(experimentId: string, widgetId: string, extra?: string): Observable<any> {
    const uri = 'rest/resultreport/experiment/';
    const fExtra = extra ? '?' + encodeURI(extra) : '';

    return this.http.get(`rest/resultreport/experiment/${experimentId}/widgetdata/${widgetId}${fExtra}`).pipe(
      catchError((err) => {
        if (err.error.exceptionType === 'ExperimentResultNotAvailableException') {
          // return this.getR(experimentId, widgetId, extra);
          return throwError(err);
        } else {
          // this.showMessage('@noServerDataArrived@');
          return throwError(err);
        }
      })
    );
  }

  async showMessage(value) {
    const text = (await this.translate.get(value))['value'];

    this.systemMessage.openFromComponent(SystemMessageComponent, {
      duration: 5000,
      data: {
        type: 'error',
        text: text,
      },
    });
  }

  public getResultWidgetDataByRefId(experimentId: string, widgetRefId: string, extra?: string): Observable<any> {
    const uri = 'rest/resultreport/experiment/';
    const fExtra = extra ? '?' + encodeURI(extra) : '';

    return this.http.get(`rest/resultreport/experiment/${experimentId}/widgetdatabyref/${widgetRefId}${fExtra}`).pipe(
      catchError((err) => {
        this.showMessage('@noServerDataArrived@');
        return throwError(err);
      })
    );
  }

  /**
   * get widget template
   * @param experimentId experiment ID
   * @param widgetId widget ID
   */
  public getWidgetTemplate(experimentId: string, widgetId: string): Observable<any> {
    return this.http.get(`rest/resultreport/experiment/${experimentId}/widgettemplate/${widgetId}`);
  }

  public postWidgetTemplate(experimentId: string, widgetId: string, body: any): Observable<any> {
    return this.http.post(`rest/resultreport/experiment/${experimentId}/widgettemplate/${widgetId}`, body);
  }

  public deleteView(resultReportId: string, tabId: string): Observable<any> {
    return this.http.delete(`rest/resultreport/${resultReportId}/tab/${tabId}`);
  }
}
