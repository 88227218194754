import * as d3 from 'd3';
import { Observable, Subject, takeUntil } from 'rxjs';
import { GanttCallBackStackExecuter } from '../callback-tools/callback-stack-executer';
import { GanttConfig } from '../config/gantt-config';
import { DataManipulator } from '../data-handler/data-tools/data-manipulator';
import { GanttFontSizeCalculator } from '../font-tools/font-size';
import { GanttUtilities } from '../gantt-utilities/gantt-utilities';
import { NodeProportionsState } from '../html-structure/node-proportion-state/node-proportion-state';
import { ETimeGradationTimeGrid, TimeGradationHandler } from '../time-gradation-handler/time-gradation-handler';
import {
  ETimeFormatWeekdayStrategy,
  GanttXAxisFormatGeneral,
  TimeFormatterAxisFormat,
  TimeFormatterTimeFormat,
} from './axis-formats/x-axis-format-general';
import { DateMarker } from './date-marker';
import { ZoomHandler } from './zoom-handler';

/**
 * X-Axis builder and handler for horizontal brush/zoom.
 * @keywords xaxis, x axis, axes, horizontal, time, date, scale, builder, executer, zoom, top
 * @param {HTMLNode} parentNode
 * @param {GanttConfig} config
 * @param {NodeProportionsState} nodeProportionState Handles the html node proportions of gantt diagram.
 */
export class GanttXAxis {
  parentNode: HTMLDivElement;
  timeGradationHandler: TimeGradationHandler;
  nodeProportionState: NodeProportionsState;
  automaticTimeGrid: boolean;
  canvas: d3.Selection<SVGSVGElement, undefined, d3.BaseType, undefined>;
  ganttAxisItems: GanttAxisItem[];
  ganttXAxisGenerators: d3.Axis<Date | d3.NumberValue>[];
  currentScale: d3.ScaleTime<number, number>;
  globalScale: d3.ScaleTime<number, number>;
  animatedZoomTryCnt: number;
  currentTimeFormats: TimeFormatterAxisFormat[];
  axisContainer: d3.Selection<SVGGElement, undefined, d3.BaseType, undefined>;
  axisBackground: d3.Selection<SVGRectElement, undefined, d3.BaseType, undefined>;
  markerContainer: d3.Selection<SVGGElement, undefined, d3.BaseType, undefined>;
  allMarkers: MarkerItem[];
  scrollBar: ScrollBarItem;
  brush: d3.BrushBehavior<unknown>;
  brushWidthScale: number;
  arrowLeftBtn: d3.Selection<SVGRectElement, unknown, null, undefined>;
  arrowRightBtn: d3.Selection<SVGRectElement, unknown, null, undefined>;
  arrowLeftBtnActive: boolean;
  arrowRightBtnActive: boolean;
  globalTimeFormat: TimeFormatterTimeFormat;
  zoom: d3.ZoomBehavior<Element, unknown>;
  fitTimeGridTimeout: NodeJS.Timeout;
  callBack: any;
  config: GanttConfig;
  timeFormatList: TimeFormatterTimeFormat;
  fontSizeCalculator: GanttFontSizeCalculator;
  svgWidth: number;
  whileResizing: boolean;
  arrowBtnTimer: NodeJS.Timeout;
  markerLineContainer: d3.Selection<SVGGElement, undefined, d3.BaseType, undefined>;
  markerTextContainer: d3.Selection<SVGGElement, undefined, d3.BaseType, undefined>;
  private zoomHandler: ZoomHandler;
  private _isScrollingHorizontal = false;

  private _onZoomStartSubject = new Subject<any>();
  private _onZoomingSubject = new Subject<any>();
  private _onZoomEndSubject = new Subject<any>();
  private _onChangeTimespanSubject = new Subject<void>();

  constructor(
    parentNode: HTMLDivElement,
    config: GanttConfig,
    nodeProportionState: NodeProportionsState,
    private onDestroy: Observable<void>
  ) {
    this.parentNode = parentNode;
    this.timeGradationHandler = new TimeGradationHandler(this);
    this.nodeProportionState = nodeProportionState;
    this.automaticTimeGrid = false;

    this.canvas = null;

    this.ganttAxisItems = [];
    this.ganttXAxisGenerators = [];

    this.currentScale = null;
    this.globalScale = null;
    this.animatedZoomTryCnt = 0;

    this.currentTimeFormats = [];

    this.axisContainer = null;
    this.axisBackground = null;

    this.markerContainer = null;
    this.allMarkers = [];

    this.scrollBar = null;
    this.brush = null;
    this.brushWidthScale = 1; // Is necessary because brush width is not the same as that of the shift area. (scrollbar arrows)
    this.arrowLeftBtn = null;
    this.arrowRightBtn = null;
    this.arrowLeftBtnActive = true;
    this.arrowRightBtnActive = true;

    this.globalTimeFormat = null;

    this.zoom = null;

    this.fitTimeGridTimeout = null;

    this.callBack = {
      zoomStart: {},
      zoom: {},
      zoomEnd: {},
      changeTimeSpan: {},
    };

    this.config = config;

    this.timeFormatList = null;

    this.fontSizeCalculator = this._initFontSizeCalculator();
    this.svgWidth = 0;
    this.whileResizing = false; // True during resize

    this.arrowBtnTimer = null;
    this._handleFakeZoomEvent();
  }

  private _initFontSizeCalculator() {
    const s = this;
    s.fontSizeCalculator = new GanttFontSizeCalculator();
    const initSVG = d3.select(s.parentNode).append<SVGSVGElement>('svg');
    s.fontSizeCalculator.init(initSVG.node(), 10);
    s.fontSizeCalculator.removeInitNode();
    initSVG.remove();
    return s.fontSizeCalculator;
  }
  /**
   * Build x axis by generating and render multiple D3 axes based on global scale.
   * @keywords render, build, add, xaxis, x axis, axes, format, time, date, tick, scale
   */
  renderAxis() {
    const s = this;

    const xAxesConfig = Array<GanttXAxisFormatGeneral>(...s.config.xAxes());
    s.timeFormatList = new TimeFormatterTimeFormat(999999999, []);

    s.currentTimeFormats = s._chooseTimeFormat(xAxesConfig);

    s._createWeekdayClones(s.currentTimeFormats, xAxesConfig);
    const axisGenerators = s._createAxesGenerators(s.currentTimeFormats, xAxesConfig);
    const axisTextGenerators = s._createTextGenerators(s.currentTimeFormats, xAxesConfig);

    s._buildAllAxes(axisGenerators, axisTextGenerators, xAxesConfig, s.currentTimeFormats);
  }

  /**
   * Builds Axes and text Axis from generated config data.
   * @param {D3AxisGenerator[]} axisGenerators
   * @param {D3AxisGenerator[]} axisTextGenerators
   * @param {GanttXAxisFormatGeneral[]} xAxesConfig
   * @param {TimeFormatterTimeFormat[]} timeFormats
   */
  private _buildAllAxes(
    axisGenerators: d3.Axis<Date | d3.NumberValue>[],
    axisTextGenerators,
    xAxesConfig,
    timeFormats
  ) {
    const s = this;
    let yCumulation = 0;
    let yPos = 0;

    for (let i = 0; i < axisGenerators.length; i++) {
      const xAxisGenerator = axisGenerators[i];
      const xAxisTextGenerator = axisTextGenerators[i];
      const xAxisConfig = xAxesConfig[i];
      const timeFormat = timeFormats[i];

      const axis = s._buildAxis(xAxisConfig.cssClassName, yPos, xAxisGenerator);
      const axisText = s._buildText(xAxisConfig, yPos, xAxisTextGenerator, timeFormat);
      s._appendFirstAndLastLabelTicks(xAxisConfig, yPos, timeFormat);

      s.ganttAxisItems.push(new GanttAxisItem(xAxisGenerator, axis, xAxisConfig.renderVerticalLines()));
      s.ganttXAxisGenerators.push(xAxisGenerator);
      s.ganttAxisItems.push(new GanttAxisItem(xAxisGenerator, axisText));

      yPos = yCumulation + xAxisConfig.height;
      yCumulation += xAxisConfig.height;
    }
  }

  /**
   * Due to shifting dates to the left first tick is not in dataset, we have to add it manually.
   * @param {GanttXAxisFormatGeneral} xAxisConfig
   * @param {number} yPos
   * @param {TimeFormatterTimeFormat} timeFormat
   */
  private _appendFirstAndLastLabelTicks(xAxisConfig, yPos, timeFormat) {
    const s = this;

    const startEndLabelTicks = s.axisContainer
      .append('g')
      .attr('class', 'x_axis_text ' + xAxisConfig.cssClassNameText)
      .style('pointer-events', 'none');

    const format = this._useWeekdayTimeFormat(timeFormat, xAxisConfig) ? timeFormat.weekdayFormat : timeFormat.format;
    let formatFunction = d3.timeFormat(format);
    if (format === 'Quartal') {
      formatFunction = s._quarterFormatter;
    }

    s._appendFirstTick(startEndLabelTicks, yPos, xAxisConfig, formatFunction, timeFormat);
    s._appendLastTick(startEndLabelTicks, yPos, xAxisConfig, formatFunction, timeFormat);
  }

  /**
   * Appends first tick on xAxis.
   * @param {D3Selection} startEndLabelTicks
   * @param {number} yPos
   * @param {GanttXAxisFormatGeneral} xAxisConfig
   * @param {function} formatFunction
   * @param {TimeFormatterAxisFormat} timeFormat
   */
  private _appendFirstTick(startEndLabelTicks, yPos, xAxisConfig, formatFunction, timeFormat: TimeFormatterAxisFormat) {
    const s = this;

    startEndLabelTicks
      .append('text')
      .attr('text-anchor', 'start')
      .attr('x', function () {
        const maxXPosition = s.currentScale(s.currentScale.ticks(timeFormat.ticks)[0]);
        if (isNaN(maxXPosition)) {
          return s.currentScale(s.currentScale.domain()[1]) * 0.5;
        }
        const textWidth = s.fontSizeCalculator.getTextWidth(formatFunction(s.currentScale.domain()[0]));
        return textWidth > maxXPosition ? maxXPosition - textWidth : maxXPosition * 0.5 - textWidth * 0.5;
      })
      .attr('y', function () {
        return yPos + (xAxisConfig.tickSizeText || 6) + xAxisConfig.fontSize || 10;
      })
      .style('font-size', function () {
        return xAxisConfig.fontSize || 10;
      })
      .text(function () {
        const minCurrentZoom = 0;
        return formatFunction(s.pxToTime(minCurrentZoom, s.currentScale));
      });
  }

  /**
   * Appends last tick on xAxis.
   * @param {D3Selection} startEndLabelTicks
   * @param {number} yPos
   * @param {GanttXAxisFormatGeneral} xAxisConfig
   * @param {function} formatFunction
   * @param {TimeFormatterAxisFormat} timeFormat
   */
  private _appendLastTick(startEndLabelTicks, yPos, xAxisConfig, formatFunction, timeFormat: TimeFormatterAxisFormat) {
    const s = this;
    startEndLabelTicks
      .append('text')
      .attr('text-anchor', 'start')
      .attr('x', function () {
        const lastTick = s.currentScale(
          s.currentScale.ticks(timeFormat.ticks)[s.currentScale.ticks(timeFormat.ticks).length - 1]
        );
        if (isNaN(lastTick)) return -300;
        const textWidth = s.fontSizeCalculator.getTextWidth(formatFunction(s.currentScale.domain()[0]));
        const pos = lastTick + (s.svgWidth - lastTick) * 0.5;
        return pos - textWidth * 0.5 < lastTick + 2 ? lastTick + 2 : pos - textWidth * 0.5;
      })
      .attr('y', function () {
        return yPos + (xAxisConfig.tickSizeText || 6) + xAxisConfig.fontSize || 10;
      })
      .style('font-size', function () {
        return xAxisConfig.fontSize || 10;
      })
      .text(function () {
        let lastTick = s.currentScale(
          s.currentScale.ticks(timeFormat.ticks)[s.currentScale.ticks(timeFormat.ticks).length - 1]
        );
        if (isNaN(lastTick)) lastTick = s.currentScale.range()[1];
        return formatFunction(s.pxToTime(lastTick, s.currentScale));
      });
  }

  /**
   * Builds an axis.
   * @param {String} cssClassName
   * @param {number} yPos
   * @param {D3AxisGenerator} axisGenerator
   */
  private _buildAxis(cssClassName, yPos, axisGenerator: d3.Axis<Date | d3.NumberValue>) {
    return this.axisContainer
      .append('g')
      .attr('class', 'x_axis ' + cssClassName)
      .attr('transform', function () {
        return 'translate(0, ' + yPos + ')';
      })
      .call(axisGenerator);
  }

  /**
   * Builds an text-axis.
   * @param {String} cssClassName
   * @param {number} yPos
   * @param {D3AxisGenerator} axisGenerator
   * @param {TimeFormatterAxisFormat} timeFormat
   */
  private _buildText(axisConfig, yPos, axisTextGenerator, timeFormat: TimeFormatterAxisFormat) {
    const s = this;
    return s.axisContainer
      .append('g')
      .attr('class', 'x_axis_text ' + axisConfig.cssClassName)
      .attr('transform', function () {
        const xTransl = s._calculateTickdistance(timeFormat.ticks)[0] / 2;
        return 'translate(' + xTransl + ', ' + yPos + ')';
      })
      .style('font-size', function () {
        return axisConfig.fontSize || 10;
      })
      .style('pointer-events', 'none')
      .call(axisTextGenerator);
  }

  /**
   * Checks if the specified axis formats and configurations require the display of weekdays and generates clones of them if necessary.
   * @param timeFormats Time formats to manipulate.
   * @param xAxesConfig X axis configurations to manipulate.
   */
  private _createWeekdayClones(timeFormats: TimeFormatterAxisFormat[], xAxesConfig: GanttXAxisFormatGeneral[]): void {
    const doubleTimeFormats = new Map<number, TimeFormatterAxisFormat>();
    const doubleAxesConfigs = new Map<number, GanttXAxisFormatGeneral>();

    for (let i = 0; i < timeFormats.length; i++) {
      let hasWeekdayAxis = false;

      // if an additional weekday axis is needed -> add weekday axis first
      if (this.config.xAxisShowWeekdays() && timeFormats[i].weekdayStrategy === ETimeFormatWeekdayStrategy.AXIS) {
        const doubleTimeFormat = timeFormats[i].getShallowClone();
        doubleTimeFormats.set(i, doubleTimeFormat);

        const doubleAxesConfig = xAxesConfig[i].getShallowClone();
        doubleAxesConfig.cssClassName += 'x_axis_weekdays';
        doubleAxesConfig.height = 20;
        doubleAxesConfig.tickSize = doubleAxesConfig.height;
        doubleAxesConfig.tickSizeText = 4;
        doubleAxesConfigs.set(i, doubleAxesConfig);

        hasWeekdayAxis = true;
      }

      if (hasWeekdayAxis) {
        const originTimeFormat = timeFormats[i];
        timeFormats[i] = originTimeFormat.getShallowClone();

        const originAxesConfig = xAxesConfig[i];
        xAxesConfig[i] = originAxesConfig.getShallowClone();
      }
    }

    const timeFormatsLength = timeFormats.length;
    let added = 0;
    for (let i = 0; i < timeFormatsLength; i++) {
      if (doubleTimeFormats.get(i)) {
        timeFormats.splice(i + added, 0, doubleTimeFormats.get(i));
        xAxesConfig.splice(i + added, 0, doubleAxesConfigs.get(i));
        added++;
      }
    }
  }

  /**
   * Creates the axes generators from config data.
   * @param {TimeFormatterAxisFormat[]} timeFormats
   * @param {GanttXAxisFormatGeneral[]} xAxesConfig
   */
  private _createAxesGenerators(
    timeFormats: TimeFormatterAxisFormat[],
    xAxesConfig: GanttXAxisFormatGeneral[]
  ): d3.Axis<Date | d3.NumberValue>[] {
    const axisGenerators: d3.Axis<Date | d3.NumberValue>[] = [];
    for (let i = 0; i < timeFormats.length; i++) {
      axisGenerators[i] = d3
        .axisBottom(this.currentScale)
        .tickFormat((d, i) => '')
        .ticks(timeFormats[i].ticks)
        .tickSize((i === timeFormats.length - 1 ? 50 : xAxesConfig[i].tickSize) || 24)
        .scale(this.currentScale);
    }
    return axisGenerators;
  }

  /**
   * Creates the text-axes generators from config data.
   *  @param {TimeFormatterAxisFormat[]} timeFormats
   * @param {GanttXAxisFormatGeneral[]} xAxesConfig
   */
  private _createTextGenerators(
    timeFormats: TimeFormatterAxisFormat[],
    xAxesConfig: GanttXAxisFormatGeneral[]
  ): d3.Axis<Date | d3.NumberValue>[] {
    const axisTextGenerators: d3.Axis<Date | d3.NumberValue>[] = [];
    for (let i = 0; i < timeFormats.length; i++) {
      const format = this._useWeekdayTimeFormat(timeFormats[i], xAxesConfig[i])
        ? timeFormats[i].weekdayFormat
        : timeFormats[i].format;
      let textTickFormat = d3.timeFormat(format);

      if (format === 'Quartal') {
        textTickFormat = this._quarterFormatter;
      }

      axisTextGenerators[i] = d3
        .axisBottom(this.currentScale)
        .tickFormat(textTickFormat)
        .tickValues(
          this.currentScale
            .ticks(timeFormats[i].ticks)
            .slice(0, this.currentScale.ticks(timeFormats[i].ticks).length - 1)
        )
        .tickSize(xAxesConfig[i].tickSizeText || 6)
        .scale(this.currentScale);
    }

    return axisTextGenerators;
  }

  /**
   * Checks if the specified axis should use a weekday time format or not.
   * @param timeFormat Time format of the axis to check.
   * @param xAxisConfig X axis configuration of the axis to check.
   * @returns True if the specified axis should use a weekday time format, false if not.
   */
  private _useWeekdayTimeFormat(timeFormat: TimeFormatterAxisFormat, xAxisConfig: GanttXAxisFormatGeneral): boolean {
    return (
      this.config.xAxisShowWeekdays() &&
      ((xAxisConfig.cssClassName.includes('x_axis_weekdays') &&
        timeFormat.weekdayStrategy === ETimeFormatWeekdayStrategy.AXIS) ||
        timeFormat.weekdayStrategy === ETimeFormatWeekdayStrategy.INLINE) &&
      !!timeFormat.weekdayFormat
    );
  }

  /**
   * Chooses timeFormat from global or from config.
   * @param {Array.<GanttXAxisFormatGeneral>}
   * @returns {TimeFormatterAxisFormat[]}
   */
  private _chooseTimeFormat(xAxesConfig: GanttXAxisFormatGeneral[]): TimeFormatterAxisFormat[] {
    const s = this;
    let timeFormat = [];
    if (s.globalTimeFormat) {
      timeFormat = s.globalTimeFormat.time;
    } else {
      const configTimeFormats = [];
      for (const xAxisConfig of xAxesConfig) {
        for (const timeFormatterAxisFormat of xAxisConfig.getTickformatByScale(s.currentScale).time) {
          configTimeFormats.push(timeFormatterAxisFormat);
        }
      }
      timeFormat = configTimeFormats;
    }
    return timeFormat;
  }

  /**
   * Quarter formatter for dates.
   * Used by xAxis format "Quartal"
   * @param {date} date
   * @return {String}
   */
  private _quarterFormatter(date: Date): string {
    const date2 = new Date(date);
    const q = Math.ceil((date2.getMonth() + 1) / 3);
    return q + '. Quartal';
  }

  /**
   * Remove and rebuild D3 axes.
   * @keywords rerender, axis, axes, xaxis, x axis, update, refresh
   */
  reRenderAxis() {
    const s = this;

    for (let i = 0; i < s.ganttAxisItems.length; i++) {
      s.ganttAxisItems[i].axisSelection.remove();
    }

    s.axisContainer.selectAll('.x_axis_text').remove();

    s.ganttAxisItems.length = 0;
    s.ganttAxisItems = [];

    s.ganttXAxisGenerators.length = 0;
    s.ganttXAxisGenerators = [];
    s.renderAxis();
  }

  /**
   * Calculates translation of text axis to put text in middle between two ticks.
   * @private
   * @param {d3.TimeInterval} ticksInterval TimeInterval to generate ticks.
   */
  private _calculateTickdistance(ticksInterval: d3.TimeInterval) {
    const s = this;
    const ticks = s.currentScale.ticks(ticksInterval);
    const spaces = [];
    for (let i = 0; i < ticks.length - 1; i++) {
      spaces.push(s.currentScale(ticks[i + 1]) - s.currentScale(ticks[i]));
    }
    return spaces.length > 0 ? spaces : [0];
  }
  /**
   * Adds axis-/ gantt scrollbar to selection.
   * Based on D3's Brush function.
   * @keywords scroll, bar, horizontal, zoom, scale, brush, brushx
   * @param {Selection} selection D3 selection which is the parent for the horizontal scroll bar.
   */
  addScrollBar(selection: d3.Selection<SVGSVGElement, undefined, d3.BaseType, undefined>, width: number) {
    const s = this;
    if (width < 2 * s.config.getXAxisScrollBarArrowWidth()) {
      return;
    }
    const originWidth = width;
    const adaptedWidth = width - 2 * s.config.getXAxisScrollBarArrowWidth();
    s.brushWidthScale = originWidth / adaptedWidth;

    if (s.config.xAxisScrollBarHeight() === 0) {
      s.scrollBar.selection.remove();
      return;
    }

    const brush = d3
      .brushX()
      .extent([
        [0, 0],
        [adaptedWidth, s.config.xAxisScrollBarHeight()],
      ])
      .on('start', function (event) {
        if (!event.sourceEvent || (event.sourceEvent && event.sourceEvent.type === 'zoom')) return; // ignore undefined sourceEvent and brush-by-zoom
        s.zoomHandler?.triggerZoom(event as d3.D3ZoomEvent<Element, null>);
      })
      .on('brush end', function (event) {
        if (!event.sourceEvent || (event.sourceEvent && event.sourceEvent.type === 'zoom')) {
          return; // ignore undefined sourceEvent and brush-by-zoom
        }

        // get width of scrollbar
        const sel: [number, number] = event.selection
          ? event.selection.map((x) => x * s.brushWidthScale)
          : s.globalScale.range();

        if (sel[0] > width - 5) {
          // if scrollbar is at the end, set beginning 5 px before end
          sel[0] = width - 5;
        }

        s.currentScale.domain(sel.map(s.globalScale.invert, s.globalScale));
        s.reRenderAxis();
        s._validateScrollArrows();

        let selectionRange = sel[1] - sel[0];
        if (selectionRange < 0.01) {
          selectionRange = 0.01;
        }

        const zoomEvent = d3.zoomIdentity.scale(width / selectionRange).translate(-sel[0], 0);

        s.axisContainer.call(s.zoom.transform, zoomEvent);

        const fakeEvent = {
          transform: zoomEvent,
        };
        s.zoomHandler?.triggerZoom(fakeEvent as d3.D3ZoomEvent<Element, null>);
      })
      .on('end', function (event) {
        if (!event.sourceEvent || (event.sourceEvent && event.sourceEvent.type === 'zoom')) {
          return; // ignore undefined sourceEvent and brush-by-zoom
        }

        const selectionValues = event.selection;
        if (!selectionValues) {
          s.setZoomLevel(1);
          return;
        }

        s.zoomToTimeSpan(
          s.pxToTime(selectionValues[0] * s.brushWidthScale, s.getGlobalScale()),
          s.pxToTime(selectionValues[1] * s.brushWidthScale, s.getGlobalScale())
        );
      });

    const scrollBar = selection
      .append('g')
      .attr('class', 'brush')
      .attr(
        'transform',
        `translate(${s.config.getXAxisScrollBarArrowWidth()}, ${
          s.config.isXAxisVisible() ? s.config.xAxisScrollBarY() : 0
        })`
      )
      .call(brush);

    s._buildScrollbarArrowButtons(selection, adaptedWidth);

    s.scrollBar = new ScrollBarItem(scrollBar, brush);
  }

  /**
   * Replaces the invert() function of d3.scale, because in d3 the conversion is inaccurate.
   * This seems to be a bug. An issue has been created for this: https://github.com/d3/d3-scale/issues/268
   * As soon as this is fixed, invert() can be used again.
   */
  public pxToTime(px: number, scale: d3.ScaleTime<number, number>): Date {
    const msPerPx = (scale.domain()[1].getTime() - scale.domain()[0].getTime()) / (scale.range()[1] - scale.range()[0]);
    return new Date(scale.domain()[0].getTime() + px * msPerPx);
  }

  /**
   * Builds arrows as buttons to the left and right of the scrollbar.
   * @param {D3 canvas selection} selection build canvas
   * @param {number} adaptedWidth width of scrollbar
   */
  private _buildScrollbarArrowButtons(selection, adaptedWidth) {
    const s = this;
    s.arrowLeftBtnActive = true;
    s.arrowRightBtnActive = true;

    s.arrowLeftBtn = selection
      .append('g')
      .attr('transform', `translate(0, ${s.config.isXAxisVisible() ? s.config.xAxisScrollBarY() : 0})`)
      .attr('class', 'xScrollBarArrowLeft');

    s.arrowLeftBtn
      .append('rect')
      .attr('class', 'xAxisScrollBarArrow')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', s.config.getXAxisScrollBarArrowWidth())
      .attr('height', s.config.xAxisScrollBarHeight())
      .attr('fill', '#f1f1f1')
      .on('mousedown', function () {
        s._onScrollArrowPressedStart('LEFT');
      })
      .on('mouseup', function () {
        s._onScrollArrowPressedEnd();
      })
      .on('mouseout', function () {
        s._onScrollArrowPressedEnd();
      });

    s.arrowLeftBtn
      .append('path')
      .attr('class', 'xAxisScrollBarArrowPath')
      .attr('d', 'M11 4l-4 4 4 4z')
      .attr('fill', '#505050')
      .style('pointer-events', 'none');

    s.arrowRightBtn = selection
      .append('g')
      .attr(
        'transform',
        `translate(${adaptedWidth + s.config.getXAxisScrollBarArrowWidth()}, ${
          s.config.isXAxisVisible() ? s.config.xAxisScrollBarY() : 0
        })`
      )
      .attr('class', 'xScrollBarArrowRight');

    s.arrowRightBtn
      .append('rect')
      .attr('class', 'xAxisScrollBarArrow')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', s.config.getXAxisScrollBarArrowWidth())
      .attr('height', s.config.xAxisScrollBarHeight())
      .attr('fill', '#f1f1f1')
      .on('mousedown', function () {
        s._onScrollArrowPressedStart('RIGHT');
      })
      .on('mouseup', function () {
        s._onScrollArrowPressedEnd();
      })
      .on('mouseout', function () {
        s._onScrollArrowPressedEnd();
      });

    s.arrowRightBtn
      .append('path')
      .attr('class', 'xAxisScrollBarArrowPath')
      .attr('d', 'M8 12l4-4-4-4z')
      .attr('fill', '#505050')
      .style('pointer-events', 'none');
  }

  /**
   * Handles the event that is triggered when pressing on an arrow.
   * @param {"LEFT"|"RIGHT"} position position of arrow
   */
  private _onScrollArrowPressedStart(position: 'LEFT' | 'RIGHT'): void {
    const s = this;

    const fakeEvent = {
      type: 'start',
      transform: this.getLastZoomEvent(),
    };
    s.zoomHandler.triggerZoom(fakeEvent as d3.D3ZoomEvent<Element, null>);

    s._onScrollArrowPressed(position, true);
  }

  private _onScrollArrowPressed(position: 'LEFT' | 'RIGHT', isFirstEvent = false): void {
    const s = this;

    const timeoutMs = isFirstEvent ? 100 : 50;

    if (s.arrowBtnTimer) clearTimeout(s.arrowBtnTimer);
    s.arrowBtnTimer = setTimeout(() => {
      s._onScrollArrowPressed(position);
    }, timeoutMs);

    let scrollDistance = 30;

    if (position === 'LEFT') {
      scrollDistance *= -1;
    } else if (position === 'RIGHT') {
    } else {
      return;
    }

    s.scrollHorizontal(scrollDistance);
    const fakeEvent = {
      type: 'zoom',
      transform: this.getLastZoomEvent(),
    };
    s.zoomHandler.triggerZoom(fakeEvent as d3.D3ZoomEvent<Element, null>);
  }

  /**
   * Handles the event that is triggered when pressing end on an arrow.
   */
  private _onScrollArrowPressedEnd(): void {
    const s = this;
    clearTimeout(s.arrowBtnTimer);

    const fakeEvent = {
      type: 'end',
      transform: this.getLastZoomEvent(),
    };

    this.zoomHandler?.triggerZoom(fakeEvent as d3.D3ZoomEvent<Element, null>);
  }

  /**
   * Controls whether the scrollbar arrows can be selected or not.
   */
  private _validateScrollArrows() {
    const s = this;
    if (!s.arrowLeftBtn || !s.arrowRightBtn) {
      return;
    }
    const isLeftStop = s.getGlobalScale().domain()[0].getTime() === s.currentScale.domain()[0].getTime();

    if (isLeftStop && s.arrowLeftBtnActive) {
      s.arrowLeftBtnActive = false;
      s.arrowLeftBtn.style('pointer-events', 'none');
      s.arrowLeftBtn.select('path').attr('fill', '#a3a3a3');
    } else if (!isLeftStop && !s.arrowLeftBtnActive) {
      s.arrowLeftBtnActive = true;
      s.arrowLeftBtn.style('pointer-events', 'auto');
      s.arrowLeftBtn.select('path').attr('fill', '#505050');
    }

    const isRightStop = s.getGlobalScale().domain()[1].getTime() === s.currentScale.domain()[1].getTime();
    if (isRightStop && s.arrowRightBtnActive) {
      s.arrowRightBtnActive = false;
      s.arrowRightBtn.style('pointer-events', 'none');
      s.arrowRightBtn.select('path').attr('fill', '#a3a3a3');
    } else if (!isRightStop && !s.arrowRightBtnActive) {
      s.arrowRightBtnActive = true;
      s.arrowRightBtn.style('pointer-events', 'auto');
      s.arrowRightBtn.select('path').attr('fill', '#505050');
    }
  }

  /**
   * Fits the time span to the current grid.
   * @param {BrushEvent} d3Event
   */
  private _fitTimeGridOnMouseScroll(d3Event) {
    const s = this;
    clearTimeout(s.fitTimeGridTimeout);
    if (s.timeGradationHandler.getDefaultTimeGrid() == 'none') {
      return;
    } // return if there is no time grid set

    s.fitTimeGridTimeout = setTimeout((_) => {
      if (d3Event.sourceEvent?.shiftKey || d3Event.sourceEvent?.ctrlKey || d3Event.sourceEvent?.altKey) {
        s.setZoomLevel(1); // correct zoom time-grid on zoom by alt/strg-key if grid is active
      }
    }, 200);
  }

  /**
   * Adds zoom listener (by scroll event) to x axis.
   * @private
   */
  private _addZoomListener(width: number) {
    const s = this;

    const zoom = d3
      .zoom()
      .filter(function (event) {
        return event.ctrlKey || event.altKey || event.shiftKey || event.type === 'mousedown';
      })
      .wheelDelta(function (event) {
        return -event.deltaY * (event.deltaMode === 1 ? 0.05 : event.deltaMode ? 1 : 0.002);
      }) // disable fast zoom when Ctrl is pressed
      .scaleExtent([1, s._getMaxZoomScale()])
      .translateExtent([
        [0, 0],
        [width, 20],
      ])
      .on('start', (e) => s.zoomHandler?.triggerZoom(e))
      .on('zoom', (e) => s.zoomHandler?.triggerZoom(e))
      .on('end', (e) => s.zoomHandler?.triggerZoom(e));

    s.axisContainer.call(zoom);

    s.zoom = zoom;
  }

  /**
   * Event handler for zoom start events.
   * @param event Zoom start event.
   */
  private _onZoomStart(event): void {
    const s = this;
    // execute all callback functions
    GanttCallBackStackExecuter.execute(s.callBack.zoomStart, event);
    s._onZoomStartSubject.next(event);
  }

  /**
   * Event handler for zoom events.
   * @param event Zoom event.
   */
  private _onZoom(event): void {
    const s = this;

    if (event.sourceEvent && event.sourceEvent.shiftKey && !s._isScrollingHorizontal) {
      // zooming while holding shift pans left and right instead of zooming
      s.scrollHorizontal(event.sourceEvent.wheelDelta / -3);
      return;
    }

    let t = event.transform;
    const scrollDomain = t.rescaleX(s.globalScale).domain();
    s.currentScale.domain(scrollDomain);
    s.reRenderAxis();

    if (s.scrollBar) {
      t = event.transform;
      const scaledRange = s.currentScale
        .range()
        .map(t.invertX, t)
        .map((x: any) => x / s.brushWidthScale) as [number, number];
      s.scrollBar.selection.call(s.scrollBar.brush.move, scaledRange);
      s._validateScrollArrows();
    }

    // execute all callback functions
    GanttCallBackStackExecuter.execute(s.callBack.zoom, event);
    s._onZoomingSubject.next(event);
    s._checkForTimeGrid(); // fit the grid for automatic zoom grid
  }

  /**
   * Event handler for zoom end events.
   * @param event Zoom end event.
   */
  private _onZoomEnd(event): void {
    const s = this;
    const eventType = event.sourceEvent && event.sourceEvent.type ? event.sourceEvent.type : null;
    s._fitTimeGridOnMouseScroll(event);

    if (eventType === 'mouseup') s.setZoomLevel(1); // fit to grid if drag y-axis by mouse

    // execute all callback functions
    GanttCallBackStackExecuter.execute(s.callBack.zoomEnd, event);
    s._onZoomEndSubject.next(event);
  }

  /**
   * Builds canvas and axes of x axis by generating a scale based on given time interval.
   * @keywords build, execute, scale, init, initialization, update, rebuild
   * @param {Date} timeStart Start timepoint of x axis.
   * @param {Date} timeEnd End timepoint of x axis.
   */
  build(timeStart: Date, timeEnd: Date) {
    const s = this;
    const parentNodeProportions = s.nodeProportionState.getXAxisProportions();
    s.globalScale = d3
      .scaleTime()
      .range([0, parentNodeProportions.width])
      .domain([new Date(timeStart), new Date(timeEnd)]);

    s.currentScale = s.globalScale.copy();

    s._createCanvas(parentNodeProportions.width);
    s._initZoomHandler();
  }

  /**
   * Scrolls horizontally depending on the given distance.
   * Positive distance scrolls to the right, negative distance scrolls to the left.
   * @param {number} distanceInPx Distance to scroll in px
   */
  scrollHorizontal(distanceInPx: number): boolean {
    const s = this;
    if (s._isScrollingHorizontal) return false; // do not scroll when already scrolling
    if (!distanceInPx) {
      return false;
    }
    this._isScrollingHorizontal = true;

    const distanceInMs = s.pxToTime(distanceInPx, s.currentScale).getTime() - s.pxToTime(0, s.currentScale).getTime();
    const dateFrom = new Date(s.currentScale.domain()[0].getTime() + distanceInMs);
    const dateTo = new Date(s.currentScale.domain()[1].getTime() + distanceInMs);
    if (
      // check if scroll is possible
      s.getGlobalScale().domain()[0] &&
      s.getGlobalScale().domain()[1] &&
      s.getGlobalScale().domain()[0].getTime() <= dateFrom.getTime() &&
      s.getGlobalScale().domain()[1].getTime() >= dateTo.getTime()
    ) {
      s.zoomToTimeSpan(dateFrom, dateTo, false);
      this._isScrollingHorizontal = false;
      return true;
    } else {
      if (distanceInPx > 0) {
        // correct right
        const newDistance =
          s.currentScale(s.getGlobalScale().domain()[1].getTime()) -
          s.currentScale(s.currentScale.domain()[1].getTime());
        if (newDistance) {
          this._isScrollingHorizontal = false;
          s.scrollHorizontal(newDistance);
          return true;
        }
      } else if (distanceInPx < 0) {
        // correct left
        const newDistance =
          s.currentScale(s.getGlobalScale().domain()[0].getTime()) -
          s.currentScale(s.currentScale.domain()[0].getTime());
        if (newDistance) {
          this._isScrollingHorizontal = false;
          s.scrollHorizontal(newDistance);
          return true;
        }
      }
      s.zoomToTimeSpan(s.currentScale.domain()[0], s.currentScale.domain()[1], false);
      this._isScrollingHorizontal = false;
      return false;
    }
  }

  /**
   * Dispatch a customEvent to track the position of scroll bar from outside.
   * Used for test purposes.
   * @param {D3-Selection} d3Selection Selection of scrollbar
   * @param {number[]} currentRange start and and position of scrollbar in px
   * @param {number[]} startRange start and and position of scrollbar on start in px
   */
  private _dispatchScrollEvent(d3Selection, currentRange, startRange) {
    if (isNaN(startRange[0]) || isNaN(startRange[1]) || isNaN(currentRange[0]) || isNaN(currentRange[1])) {
      return;
    }

    d3Selection.dispatch(`gantt-horizontal-scroll`, {
      bubbles: true,
      detail: {
        fromX: currentRange[0],
        toX: currentRange[1],
        fromXDiff: currentRange[0] - startRange[0],
        toXDiff: currentRange[1] - startRange[1],
      },
    });
  }

  /**
   * Returns the max zoom scale level for current gantt.
   * @returns {number} zoom scale
   */
  private _getMaxZoomScale() {
    const s = this;
    const rangeInMinutes =
      (s.getGlobalScale().domain()[1].getTime() - s.getGlobalScale().domain()[0].getTime()) / s.config.getMaxZoomInMs();
    return rangeInMinutes;
  }

  /**
   * Builds x axis canvas, renders axis and inits listeners.
   * @private
   */
  private _createCanvas(width: number) {
    const s = this;
    d3.select(s.parentNode).style('height', parseInt(s.config.xAxisContainerHeight() + 'px'));

    s.canvas = d3
      .select<HTMLDivElement, undefined>(s.parentNode)
      .append<SVGSVGElement>('svg')
      .attr('width', function () {
        return width;
      })
      .attr('height', parseInt(s.config.xAxisContainerHeight() + 'px'));

    s.axisContainer = s.canvas
      .append('g')
      .attr('class', 'gantt_x-axis-container')
      .style('display', s.config.isXAxisVisible() ? 'unset' : 'none');

    // we need this to make the parent group "bigger"
    // to execute the zoom listener on it
    s.axisBackground = s.axisContainer
      .append('rect')
      .attr('width', width)
      .attr('height', s.config.xAxisScrollBarY())
      .attr('class', 'gantt_x-axis_background');

    s.markerContainer = s.canvas.append('g').attr('class', 'gantt_x-axis_marker-container');

    s.markerLineContainer = s.markerContainer.append('g').attr('class', 'gantt_x-marker-line_container');

    s.markerTextContainer = s.markerContainer.append('g').attr('class', 'gantt_x-marker-text_container');

    s.svgWidth = width;
    s.renderAxis();
    s._addZoomListener(width);
    s.addScrollBar(s.canvas, width);
  }

  /**
   * Adds marker by position to x axis.
   * @keywords add, marker, points, time, xaxis, x axis, horizontal
   * @param {number} xPos Horizontal position of marker.
   * @param {"start"|"middle"|"end"} textAnchor Anchor of marker text.
   * @param {string} [text] Text at marker. Shows formatted date if not set.
   * @return {MarkerItem} Marker.
   */
  addMarkerByPoints(xPos: number, textAnchor: ETimeMarkerAnchor, text: string = null) {
    if (this.whileResizing) return;
    const s = this;
    const date = s.pxToTime(xPos, s.currentScale);
    return s.addMarkerByDate(date, textAnchor, text);
  }

  /**
   * Adds marker by position to x axis while resizing.
   * @keywords add, marker, points, time, xaxis, x axis, horizontal
   * @param {number} xPos Horizontal position of marker.
   * @param {"start"|"middle"|"end"} textAnchor Anchor of marker text.
   * @param {string} [text] Text at marker. Shows formatted date if not set.
   * @return {MarkerItem} Marker.
   */
  addMarkerByPointsWhileResizing(xPos: number, textAnchor: ETimeMarkerAnchor, text: string = null) {
    const s = this;
    const date = s.pxToTime(xPos, s.currentScale);
    return s.addMarkerByDate(date, textAnchor, text, null, true);
  }

  /**
   * Adds marker by date to x axis.
   * @keywords add, marker, date, time, xaxis, x axis, horizontal
   * @param {Date} timePoint Timepoint of marker.
   * @param {"start"|"middle"|"end"} textAnchor Anchor of marker text.
   * @param {string} [text] Text at marker. Shows formatted date if not set.
   * @param {string} [color]
   * @param {boolean} [isCalledByResizer] True if addMarkerByPointsWhileResizing() is calling this function (to differentiate between resizing or normal event call).
   * @return {MarkerItem} Marker.
   */
  addMarkerByDate(
    timePoint: Date | number,
    textAnchor: ETimeMarkerAnchor,
    text = null,
    color = 'white',
    isCalledByResizer = false
  ) {
    if (this.whileResizing && !isCalledByResizer) return; // Return during resize if the function is not called by resize event. To prevent that onMouseover call(doubled values).
    const s = this;
    let textDirection = textAnchor;
    if (!textDirection) textDirection = ETimeMarkerAnchor.MIDDLE;
    let x = s.getCurrentScale()(new Date(timePoint));
    const dateFormat = d3.timeFormat(s.config.xAxisMarkerDateFormat());

    const markerLine = s.markerLineContainer
      .append('line')
      .attr('class', 'gantt_x-axis_line_marker')
      .attr('x1', x)
      .attr('x2', x)
      .attr('y1', 0)
      .attr('y2', s.config.xAxisContainerHeight())
      .style('stroke', color);

    const markerText = s.markerTextContainer
      .append('text')
      .attr('class', 'gantt_x-axis_text_marker')
      .attr('x', x)
      .text(function () {
        if (text) return '\xa0' + text + '\xa0';
        return '\xa0' + dateFormat(new Date(timePoint)) + '\xa0';
      })
      .attr('text-anchor', function () {
        const width = d3.select(this).node().getBBox().width;
        return DateMarker.getTextDirection(parseInt(s.canvas.attr('width')), x, width, textAnchor);
      })
      .style('fill', color);

    const textBounding = markerText.node().getBBox();
    const textWidth = textBounding.width;

    if (markerText.attr('text-anchor') == 'end') {
      x -= textWidth;
    } else if (markerText.attr('text-anchor') == 'middle') {
      x -= textWidth / 2;
    }

    markerText.attr('y', function () {
      const y = s.config.xAxisHeight() + s.config.xAxisScrollBarPadding() - s.config.xAxisScrollBarPadding() * 0.2;
      return y;
    });

    const markerItem = new MarkerItem(x, textBounding, markerLine, markerText, GanttUtilities.generateUniqueID(15));

    s.allMarkers.push(markerItem);
    s._checkForTextCollision();

    return markerItem;
  }

  /**
   * Initializes the zoom handler for the x-axis.
   * @private
   */
  private _initZoomHandler() {
    this.zoomHandler = new ZoomHandler(this.axisContainer.node());
    this.zoomHandler
      .onZoomStart()
      .pipe(takeUntil(this.onDestroy))
      .subscribe((zoomEvent) => {
        this._onZoomStart(zoomEvent);
      });

    this.zoomHandler
      .onZoom()
      .pipe(takeUntil(this.onDestroy))
      .subscribe((zoomEvent) => {
        this._onZoom(zoomEvent);
      });

    this.zoomHandler
      .onZoomEnd()
      .pipe(takeUntil(this.onDestroy))
      .subscribe((zoomEvent) => {
        this._onZoomEnd(zoomEvent);
      });
  }

  /**
   * Checks if the x-axis text collides with other texts and adjusts the height of the text.
   */
  private _checkForTextCollision() {
    const s = this;
    const checkedMarkerItems = [];

    const _isCollision = (itemToCheck) => {
      const itemToCheckStart = itemToCheck.startPoint;
      const itemToCheckEnd = itemToCheckStart + itemToCheck.textBounding.width;
      const itemToCheckTop = itemToCheck.textSelection.attr('y');
      const itemToCheckBottom = itemToCheckTop + itemToCheck.textBounding.height;

      for (const markerItem of checkedMarkerItems) {
        const markerItemStart = markerItem.startPoint;
        const markerItemEnd = markerItemStart + markerItem.textBounding.width;
        const markerItemTop = markerItem.textSelection.attr('y');
        const markerItemBottom = markerItemTop + markerItem.textBounding.height;
        if (
          ((markerItemStart >= itemToCheckStart && markerItemStart <= itemToCheckEnd) ||
            (markerItemEnd >= itemToCheckStart && markerItemEnd <= itemToCheckEnd)) && // check horizontal
          ((markerItemTop >= itemToCheckTop && markerItemTop <= itemToCheckBottom) ||
            (markerItemBottom >= itemToCheckTop && markerItemBottom <= itemToCheckBottom)) // check vertical
        ) {
          return true;
        }
      }
      return false;
    };

    s.allMarkers.forEach((item) => {
      while (_isCollision(item)) {
        item.textSelection.attr('y', parseFloat(item.textSelection.attr('y')) - 12); // set text 12 px higher
      }
      checkedMarkerItems.push(item);
    });
  }

  /**
   * Removes all existing date markers inside gantt.
   * Clears marker data.
   * @keywords remove, delete, clear, date, markers, all
   */
  removeAllDateMarkers() {
    const s = this;

    s.markerTextContainer.selectAll('.gantt_x-axis_text_marker').remove();

    s.markerLineContainer.selectAll('.gantt_x-axis_line_marker').remove();

    s.allMarkers = [];
  }

  /**
   * Removes a date marker by it's ID visually and from the allMarkers.
   * @param {String} markerID The ID of the marker.
   */
  removeDateMarkerByID(markerID: string) {
    const s = this;
    const index = s.allMarkers.findIndex((marker) => marker.id === markerID);
    const markerToDelete = s.allMarkers[index];
    if (!markerToDelete) return;
    markerToDelete.lineSelection.remove();
    markerToDelete.textSelection.remove();
    s.allMarkers.splice(index, 1);
  }

  /**
   * Removes canvas with all child nodes.
   * @keywords remove, removeall, clear, delete, canvas, elements
   */
  removeAll() {
    const s = this;
    s.canvas.remove();
  }

  /**
   * Callback function to bring x axis opacity to default level.
   */
  public highlightXAxis(): void {
    this.getAxisContainer()
      .transition()
      .style('opacity', '1')
      .on('end', function () {
        d3.select(this).style('opacity', null);
      });
  }

  /**
   * Changes zoom of gantt diagram by zoom level.
   * @keywords set, zoom, defined, level, time, scale
   * @param {number} zoomLevel Zoom level.
   */
  setZoomLevel(zoomLevel: number) {
    const s = this;
    const currentStartTime = this.currentScale.domain()[0].getTime();
    const currentEndTime = this.currentScale.domain()[1].getTime();
    const globalTimeSpan = currentEndTime - currentStartTime;
    const zoomedSpan = globalTimeSpan / zoomLevel;
    const newStartDate = new Date(currentStartTime + (globalTimeSpan - zoomedSpan) / 2);
    const newEndDate = new Date(currentStartTime + (globalTimeSpan + zoomedSpan) / 2);
    const adaptedTimeSpan = this.timeGradationHandler.getAlignedTimeSpanByTimespan([newStartDate, newEndDate]);
    const adaptedTimeSpanFixed = this.correctDateIfOutOfRange(adaptedTimeSpan);
    const adaptedStartDate = adaptedTimeSpanFixed[0];
    const adaptedEndDate = adaptedTimeSpanFixed[1];
    const newScale =
      this.currentScale.range()[1] / (this.globalScale(adaptedEndDate) - this.globalScale(adaptedStartDate));
    const offsetX = -this.globalScale(adaptedStartDate);
    if (currentStartTime === adaptedStartDate.getTime() && currentEndTime === adaptedEndDate.getTime()) {
      return; // zoom has not changed
    }
    if (adaptedEndDate.getTime() - adaptedStartDate.getTime() >= this.config.getMaxZoomInMs()) {
      // zoom only if time span is larger than max zoom range
      this.axisContainer
        .transition('GanttXAxis_setZoomLevel')
        .call(this.zoom.transform, d3.zoomIdentity.scale(newScale).translate(offsetX, 0))
        .on('end', function () {
          s._checkIfAnimatedZoomPerformedCorrectly(adaptedTimeSpanFixed);
        }); // zoom
    }
  }

  /**
   * Zooms to start/end interval with an animation.
   * @param {Date} start
   * @param {Date} end
   * @param {boolean} animated Specifies whether the zoom should be animated (true) or jump (false).
   */
  public zoomToTimeSpan(start: Date, end: Date, animated = true): void {
    const s = this;
    const newStartDate = new Date(start.getTime());
    const newEndDate = new Date(end.getTime());
    const adaptedTimeSpan = this.timeGradationHandler.getAlignedTimeSpanByTimespan([newStartDate, newEndDate]);
    const adaptedTimeSpanFixed = this.correctDateIfOutOfRange(adaptedTimeSpan);
    const adaptedStartDate = adaptedTimeSpanFixed[0];
    const adaptedEndDate = adaptedTimeSpanFixed[1];
    const newScale =
      this.currentScale.range()[1] / (this.globalScale(adaptedEndDate) - this.globalScale(adaptedStartDate));
    const offsetX = -this.globalScale(adaptedStartDate);

    if (animated) {
      this.axisContainer
        .transition('GanttXAxis_zoomToTimeSpan')
        .call(this.zoom.transform, d3.zoomIdentity.scale(newScale).translate(offsetX, 0))
        .on('end', function () {
          s._checkIfAnimatedZoomPerformedCorrectly(adaptedTimeSpanFixed);
        }); // zoom animated
    } else {
      this.axisContainer.call(this.zoom.transform, d3.zoomIdentity.scale(newScale).translate(offsetX, 0)); // jump to new time span
    }
  }

  /**
   * Checks whether a zoom action was performed correctly. If check fails a new attempt is started.
   * @param {Date[]} resultTimeSpan
   */
  private _checkIfAnimatedZoomPerformedCorrectly(resultTimeSpan: Date[]) {
    this.animatedZoomTryCnt++;
    if (
      this.currentScale.domain()[0].getTime() !== resultTimeSpan[0].getTime() ||
      this.currentScale.domain()[1].getTime() !== resultTimeSpan[1].getTime()
    ) {
      if (this.animatedZoomTryCnt < 4) {
        // abort after 3 tries
        console.warn('The zoom was not performed correctly. A new attempt is started.');
        this.zoomToTimeSpan(resultTimeSpan[0], resultTimeSpan[1]);
        return;
      }
    }
    this.animatedZoomTryCnt = 0;
  }

  /**
   * Wrapper function to increase zoom level of gantt.
   * @keywords zoom, in, scale, into, set, time, interval, xaxis, x axis
   */
  zoomIn() {
    const s = this;
    s.setZoomLevel(2);
  }
  /**
   * Wrapper function to decrease zoom level of gantt.
   * @keywords zoom, out, scale, set, time, interval, xaxis, x axis
   */
  zoomOut() {
    const s = this;
    s.setZoomLevel(0.5);
  }

  /**
   * Set horizontal zoom to all visible shifts in dataset.
   * @param {GanttDataRow} dataSet GanttDataRow
   */
  zoomToAllVisibleShiftsByDataSet(dataSet) {
    const s = this;
    let earliestTime, latestTime;

    const _getEarliestAndLatestShift = (entry) => {
      if (entry.shifts && entry.shifts.length) {
        entry.shifts.forEach((shift) => {
          if (!shift.noRender.length) {
            const shiftTimeStart = shift.timePointStart.getTime();
            if (!earliestTime || shiftTimeStart < earliestTime) {
              earliestTime = shiftTimeStart;
            }
            const shiftTimeEnd = shift.timePointEnd.getTime();
            if (!latestTime || shiftTimeEnd > latestTime) {
              latestTime = shiftTimeEnd;
            }
          }
        });
      }
    };

    DataManipulator.iterateOverDataSet(dataSet, { getEarliestAndLatestShift: _getEarliestAndLatestShift });
    if (earliestTime && latestTime) {
      s.zoomToTimeSpan(new Date(earliestTime), new Date(latestTime));
    }
  }

  /**
   * Corrects the time span if out of range.
   * @param {Date} startDate Begin of time span.
   * @param {Date} endDate End of time span.
   * @returns {Date[]} corrected time span.
   */
  correctDateIfOutOfRange(timespan: Date[]): Date[] {
    let startDate = timespan[0];
    let endDate = timespan[1];
    let startDateManipulated = false;
    let endDateManipulated = false;

    const diff = endDate.getTime() - startDate.getTime();
    if (startDate.getTime() > endDate.getTime()) {
      const backup = startDate;
      startDate = endDate;
      endDate = backup;
    }
    if (this.globalScale.domain()[0] > startDate) {
      startDate = this.globalScale.domain()[0];
      startDateManipulated = true;
    }
    if (this.globalScale.domain()[1] < endDate) {
      endDate = this.globalScale.domain()[1];
      endDateManipulated = true;
    }

    // If the time span results in 0 due to the correction. Then a time shift is to be applied.
    if (
      startDate.getTime() === endDate.getTime() &&
      diff <= this.globalScale.domain()[1].getTime() - this.globalScale.domain()[0].getTime()
    ) {
      console.warn('The time span is 0. A time shift is applied.');
      if (startDateManipulated) {
        endDate = new Date(startDate.getTime() + diff);
      } else if (endDateManipulated) {
        startDate = new Date(endDate.getTime() - diff);
      }
    }

    return [startDate, endDate];
  }

  /**
   * Automatically checks for the appropriate time grid for zooming and scrolling and sets the grid.
   */
  private _checkForTimeGrid() {
    if (!this.automaticTimeGrid) return;

    const currentZoomedTimeSpan = this.currentScale.domain()[1].getTime() - this.currentScale.domain()[0].getTime();

    switch (true) {
      case currentZoomedTimeSpan >= 23650000000: // about 3 quarters
        this.timeGradationHandler.setDefaultTimeGrid(ETimeGradationTimeGrid.QUARTER);
        break;
      case currentZoomedTimeSpan >= 5270000000: // about 2 month
        this.timeGradationHandler.setDefaultTimeGrid(ETimeGradationTimeGrid.MONTH);
        break;
      case currentZoomedTimeSpan >= 1200000000: // about 2 weeks
        this.timeGradationHandler.setDefaultTimeGrid(ETimeGradationTimeGrid.WEEK);
        break;
      case currentZoomedTimeSpan >= 172000000: // about 2 days
        this.timeGradationHandler.setDefaultTimeGrid(ETimeGradationTimeGrid.DAY);
        break;
      case currentZoomedTimeSpan >= 7200000: // 2 hours
        this.timeGradationHandler.setDefaultTimeGrid(ETimeGradationTimeGrid.HOUR);
        break;
      case currentZoomedTimeSpan >= 120000: // 2 minutes
        this.timeGradationHandler.setDefaultTimeGrid(ETimeGradationTimeGrid.MINUTE);
        break;
      default:
        this.timeGradationHandler.setDefaultTimeGrid(ETimeGradationTimeGrid.NONE);
        break;
    }
  }

  /**
   * Scrolls to the given time point without zooming
   * @param {Date} timePoint Time at which to scroll.
   */
  scrollToTimePoint(timePoint: Date) {
    if (!GanttUtilities.isValidDate(timePoint)) {
      console.warn('An invalid date should be set. in gantt!');
      return;
    }

    const timeSpanInMs = this.currentScale.domain()[1].getTime() - this.currentScale.domain()[0].getTime();
    const end = new Date(timePoint.getTime() + timeSpanInMs);
    this.zoomToTimeSpan(timePoint, end, false);
  }

  /**
   * Translates and zooms gantt that view area matches with given interval.
   * The values are adjusted to the currently active time grid.
   * @keywords translate, time, date, span, predefined, fix, start, end, interval
   * @param {Date} startDate Start date of interval.
   * @param {Date} endDate End date of interval.
   */
  translateToTimeSpanAdapted(startDate, endDate) {
    const adaptedTimeSpan = this.timeGradationHandler.getAlignedTimeSpanByTimespan([startDate, endDate]);
    this.zoomToTimeSpan(adaptedTimeSpan[0], adaptedTimeSpan[1], false);
  }

  public runChangeTimeSpanCallback(): void {
    GanttCallBackStackExecuter.execute(this.callBack.changeTimeSpan, null);
    this._onChangeTimespanSubject.next();
  }

  /**
   * Handles fake zoom events for test purposes.
   */
  private _handleFakeZoomEvent() {
    const s = this;

    let scaledStartRange = [0, 0];
    let isZooming = false;

    // dispatch fake event

    s.addToZoomStartCallback('handleFakeZoomEvent', (e) => {
      if (isZooming) return;
      isZooming = true;
      const scrollbar = s.canvas?.select('.selection');
      if (scrollbar.size() < 1) return; // ignore event when no scrollbar exists
      const startX = parseFloat(scrollbar?.attr('x'));
      const endX = startX + parseFloat(scrollbar?.attr('width'));
      scaledStartRange = [startX, endX];
    });

    s.addToZoomEndCallback('handleFakeZoomEvent', (e) => {
      isZooming = false;
      const scrollbar = s.canvas?.select('.selection');
      if (scrollbar.size() < 1) return; // ignore event when no scrollbar exists
      const startX = parseFloat(scrollbar?.attr('x'));
      const endX = startX + parseFloat(scrollbar?.attr('width'));
      const scaledEndRange = [startX, endX];
      s._dispatchScrollEvent(s.canvas, scaledEndRange, scaledStartRange);
    });

    // listen to fake event
    s.parentNode.addEventListener('execute-gantt-horizontal-scroll', s._executeZoomByFakeScrollEvent.bind(s));
  }

  /**
   * Executes the result of fake zoom events for test purposes.
   */
  private _executeZoomByFakeScrollEvent(event) {
    const s = this;
    const data = event.detail;
    const globalDomain = s.getGlobalScale().domain();
    const scrollbarWidth = s.svgWidth / s.brushWidthScale;
    const msPerPx = (globalDomain[1].getTime() - globalDomain[0].getTime()) / scrollbarWidth;

    if (!isNaN(data.fromX) && !isNaN(data.toX)) {
      const fromTime = new Date(globalDomain[0].getTime() + data.fromX * msPerPx);
      const toTime = new Date(globalDomain[0].getTime() + data.toX * msPerPx);
      s.zoomToTimeSpan(fromTime, toTime, false);
    } else if (!isNaN(data.fromXDiff) && !isNaN(data.toXDiff)) {
      const localDomain = s.getCurrentScale().domain();
      const fromTimeDiff = new Date(localDomain[0].getTime() + data.fromXDiff * msPerPx);
      const toTimeDiff = new Date(localDomain[1].getTime() + data.toXDiff * msPerPx);
      s.zoomToTimeSpan(fromTimeDiff, toTimeDiff, false);
    } else {
      console.error('Invalid properties on execute-gantt-horizontal-scroll event!');
    }
  }

  //
  // SIZE UPDATE
  //
  /**
   * Refreshs x axis by new domain.
   */
  updateSize() {
    const s = this;
    const globalDomain = s.getGlobalScale().domain();
    const currentDomain = s.getCurrentScale().domain();
    const node = d3.select(s.parentNode).node();
    const newHeight = node.clientHeight;
    const newWidth = node.clientWidth;

    // set only if proportions have changed
    if (
      s.nodeProportionState.getXAxisProportions().height !== newHeight ||
      s.nodeProportionState.getXAxisProportions().width !== newWidth
    ) {
      s.nodeProportionState.setXAxisProportions({
        height: newHeight,
        width: newWidth,
      });
      s.removeAll();
      s.build(globalDomain[0], globalDomain[1]);
      s.zoomToTimeSpan(currentDomain[0], currentDomain[1], false);
    }
  }

  //
  // CALLBACKS
  //

  /**
   * @deprecated since 2023-04-20 (use Observable instead)
   */
  addToZoomStartCallback(id, func) {
    this.callBack.zoomStart[id] = func;
  }
  /**
   * @deprecated since 2023-04-20 (use Observable instead)
   */
  removeZoomStartCallback(id) {
    delete this.callBack.zoomStart[id];
  }

  /**
   * @deprecated since 2023-04-20 (use Observable instead)
   */
  addToZoomCallback(id, func) {
    this.callBack.zoom[id] = func;
  }
  /**
   * @deprecated since 2023-04-20 (use Observable instead)
   */
  removeZoomCallback(id) {
    delete this.callBack.zoom[id];
  }

  /**
   * @deprecated since 2023-04-20 (use Observable instead)
   */
  addToZoomEndCallback(id, func) {
    this.callBack.zoomEnd[id] = func;
  }
  /**
   * @deprecated since 2023-04-20 (use Observable instead)
   */
  removeZoomEndCallback(id) {
    delete this.callBack.zoomEnd[id];
  }

  /**
   * @deprecated since 2023-04-20 (use Observable instead)
   */
  addChangeTimeSpanCallback(id, func) {
    this.callBack.changeTimeSpan[id] = func;
  }
  /**
   * @deprecated since 2023-04-20 (use Observable instead)
   */
  removeChangeTimeSpanCallback(id) {
    delete this.callBack.changeTimeSpan[id];
  }

  public get onZoomStart(): Observable<any> {
    return this._onZoomStartSubject.asObservable();
  }

  public get onZooming(): Observable<any> {
    return this._onZoomingSubject.asObservable();
  }

  public get onZoomEnd(): Observable<any> {
    return this._onZoomEndSubject.asObservable();
  }

  public get onChangeTimeSpan(): Observable<void> {
    return this._onChangeTimespanSubject.asObservable();
  }

  //
  // GETTER & SETTER
  //
  /**
   * @return {Transform} D3 zoom transform data with x, y, k value.
   */
  public getLastZoomEvent(): d3.ZoomTransform {
    return this.axisContainer?.node() ? d3.zoomTransform(this.axisContainer.node()) : new d3.ZoomTransform(1, 0, 0);
  }

  /**
   * @return {Selection} D3 selection of x axis group.
   */
  getAxisContainer() {
    return this.axisContainer;
  }

  /**
   * @returns {Array.<GanttAxisItem>}
   */
  getXAxisItems(): GanttAxisItem[] {
    return this.ganttAxisItems;
  }

  public getVerticalLinesGenerators(): d3.Axis<Date>[] {
    const s = this;
    const xAxisItems = s.getXAxisItems();
    const generators = [];

    for (let i = 0; i < xAxisItems.length; i++) {
      const item = xAxisItems[i];
      if (item.renderVerticalLines) {
        generators.push(item.generator);
      }
    }
    return generators;
  }

  /**
   * Returns the currently visible time span of the gantt chart.
   * @returns {Date[]}
   */
  getCurrentZoomedTimeSpan(): Date[] {
    const s = this;
    return s.currentScale.domain();
  }
  /**
   * @return {TimeFormatterAxisFormat[]} Current time formats shown on x axis.
   */
  getCurrentTimeFormats(): TimeFormatterAxisFormat[] {
    return this.currentTimeFormats;
  }
  /**
   * @return {scale} D3 scale of currently zoomed time interval.
   */
  getCurrentScale() {
    return this.currentScale;
  }
  /**
   * @return {scale} D3 scale of global gantt time interval.
   */
  getGlobalScale() {
    return this.globalScale;
  }
  /**
   * @return {TimeFormatterTimeFormat} List of current axis formats.
   */
  getCurrentTimeFormat(): TimeFormatterTimeFormat {
    return this.timeFormatList;
  }
  /**
   * @return {Selection} D3 selection of x axis canvas.
   */
  getCanvas() {
    return this.canvas;
  }
  /**
   * @param {TimeFormatterTimeFormat} globalTimeFormat Fix format which overwrites time formatlist.
   */
  setGlobalTimeFormat(globalTimeFormat: TimeFormatterTimeFormat) {
    this.globalTimeFormat = globalTimeFormat;
  }
  /**
   * @param {String} grid Set a zoom grid manually. Can be: "none"|"minute"|"hour"|"day"|"week"|"month".
   */
  setStaticZoomGrid(grid: ETimeGradationTimeGrid) {
    this.automaticTimeGrid = false;
    this.timeGradationHandler.setDefaultTimeGrid(grid);
  }
  /**
   * @param {Boolean} bool Set a zoom grid automaticly by current zoom.
   */
  setAutomaticZoomGrid(bool) {
    this.automaticTimeGrid = bool;
  }
  setCustomizedTimeGrid(customizedStartDate, customizedStepSize) {
    this.timeGradationHandler.setCustomizedTimeGrid(customizedStartDate, customizedStepSize);
  }
  /**
   * Removes global timeformat.
   */
  removeGlobalTimeFormat() {
    this.globalTimeFormat = null;
  }
  /**
   * @return {GanttParentNodeProportions | null} Width and height of x axis parent node.
   */
  setWhileResizing(bool) {
    this.whileResizing = bool;
  }

  /**
   * Sets text align of all x axis ticks.
   * @keywords tick, align, xaxis, x axis, marker, mark
   * @param {"start"|"middle"|"end"} textAnchorValue Text align of x axis ticks.
   */
  changeTickAlign(textAnchorValue) {
    const s = this;
    const validValues = ['start', 'middle', 'end'];
    if (validValues.includes(textAnchorValue)) {
      s.config.setXAxisTextAnchor(textAnchorValue);
      s.reRenderAxis();
    }
  }
}

/**
 * Data class to store relevant D3 elements for x axis.
 * @class
 * @constructor
 * @keywords data, class, generator, axis, xaxis, x axis
 * @param {axis} generator D3 axis generator.
 * @param {Selection} axisSelection D3 selection of axis elements.
 * @param {boolean} [renderVerticalLines=false] Wether or not this AxisItem renders ticks on shift area.
 */
export class GanttAxisItem {
  generator: d3.Axis<Date>;
  axisSelection: d3.Selection<any, any, null, undefined>;
  renderVerticalLines: boolean;

  constructor(generator, axisSelection, renderVerticalLines = false) {
    this.generator = generator;
    this.axisSelection = axisSelection;
    this.renderVerticalLines = renderVerticalLines;
  }
}

/**
 * Data class to store relevant D3 elements for brush.
 * @class
 * @constructor
 * @keywords data, class, scroll, bar, horizontal, brush
 * @param {Selection} selection D3 element selection.
 * @param {brush} brush D3 brush functionality.
 */
export class ScrollBarItem {
  selection: d3.Selection<any, any, null, undefined>;
  brush: d3.BrushBehavior<any>;

  constructor(selection, brush) {
    this.selection = selection;
    this.brush = brush;
  }
}

/**
 * Data class to store relevant information for x-axis markers.
 * @class
 * @constructor
 * @keywords data, class, marker, mark, item, selection
 * @param {number} start Horizontal position.
 * @param {SVGRect} textBounding bounding of text.
 * @param {string} id An ID to identifie the marker.
 * @param {selection} d3Selection D3 selection of elements of marker item.
 */
export class MarkerItem {
  startPoint: number;
  textBounding: SVGRect;
  id: string;
  lineSelection: d3.Selection<any, any, null, undefined>;
  textSelection: d3.Selection<any, any, null, undefined>;

  constructor(start, textBounding, d3Selection, d3TextSelection, id) {
    this.startPoint = start;
    this.textBounding = textBounding;
    this.lineSelection = d3Selection;
    this.textSelection = d3TextSelection;
    this.id = id;
  }
}

export enum ETimeMarkerAnchor {
  START = 'start',
  MIDDLE = 'middle',
  END = 'end',
}
