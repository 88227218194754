import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatLegacySliderChange as MatSliderChange } from '@angular/material/legacy-slider';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
})
export class SliderComponent implements OnInit {
  @Input() min: number;
  @Input() max: number;
  @Input() value: number;
  @Input() label: string;
  @Input() step: number;
  @Output() changeEvent: EventEmitter<MatSliderChange> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  public onSliderChange(event: MatSliderChange) {
    this.changeEvent.emit(event);
  }
}
