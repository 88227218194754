import { BaseContentElement } from '../base/base-content-element';
import { DataType } from '../base/base-template';
import { InputType, mapItem } from '../mapper';

export interface Menu extends BaseContentElement {
  type: DataType.menu;
  canonicalName: string;
  depth: number;
  hierachicMenuItems: string[];
  hotkeys: [];
  menuUpdateNotificationsRestURL: string;
  name: string;
  nameRequired: boolean;
  nameUniqued: boolean;
  preDeliveryActions: [];
  reloadMenuRestURL: string;
  resizeMode: 'FIT_CHILD';
  startTemplate: boolean;
  templateMode: 'EDITABLE';
  uuid: string;
}

export const mapMenu = <T extends DataType>(item: InputType<T>): Menu => {
  const hmis = item?.hierachicMenuItems?.map((i: InputType<T>) => mapItem(i)) ?? [];

  return {
    ...item,
    hierachicMenuItems: hmis,
    id: item.id,
    type: DataType.menu,
  } as Menu;
};
