import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { TranslateModule } from '@ngx-translate/core';
import { OverlayComponent } from './overlay/overlay.component';
import { TemplatePickerComponent } from './template-picker.component';
import { TemplatePickerService } from './template-picker.service';

@NgModule({
  imports: [
    CommonModule,
    OverlayModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    TranslateModule,
  ],
  declarations: [TemplatePickerComponent, OverlayComponent],
  exports: [TemplatePickerComponent],
  providers: [TemplatePickerService],
})
export class TemplatePickerModule {}
