import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ChartLegendItem } from '../../chart-legend-item';

@Component({
  selector: 'app-chart-attribute-legend-item',
  templateUrl: './chart-attribute-legend-item.component.html',
  styleUrls: ['./chart-attribute-legend-item.component.scss'],
})
export class ChartAttributeLegendItemComponent implements OnInit {
  @Input() attributeItem: ChartLegendItem;
  @Input() disabledBackground = false;

  @Output() onAttributeClick: EventEmitter<ChartLegendItem> = new EventEmitter();
  @Output() onToogleAttributeGroup: EventEmitter<ChartLegendItem> = new EventEmitter();
  @Output() onActiveAttributeGroup: EventEmitter<ChartLegendItem> = new EventEmitter();

  public expanded = false;
  constructor() {}

  ngOnInit() {}

  handleExpanded(item: ChartLegendItem) {
    item.toogleExpanded();
  }
}
