import {
  GanttSplitOverlappingShifts,
  IOverlappingShiftsIndicator,
} from '../../split-overlapping-shifts/split-overlapping-shifts-executer';
import { IndexCardConfig } from '../index-card-config';
import { IndexCardExecuterBuildData } from '../index-card-executer';
import { IndexCardTextBuilder } from '../text-creator';
import { IndexCardTextDefault } from './text-creator-default';
import { IIndexCardTextInterface } from './text-creator-interface';

/**
 * Lets index card text flow out of shift, truncates before neighbor shift.
 */
export class IndexCardTextOutsideSplit implements IIndexCardTextInterface {
  private _splitOverlappingShiftsPlugin: GanttSplitOverlappingShifts = undefined;
  private _textBoxWidthMap: Map<string, number> = undefined;
  private _executer: IndexCardTextBuilder = undefined;

  public init(executer: IndexCardTextBuilder): void {
    const s = executer;
    this._executer = s;

    s.ganttDiagram
      .getPlugInHandler()
      .subscribeToPlugIns(`getSplitOverlappingShiftPlugin ${s.indexCardExecuter.UUID}`, (bestGanttPlugin) => {
        try {
          // catch that GanttSplitOverlappingShifts is not defined
          if (!(bestGanttPlugin instanceof GanttSplitOverlappingShifts)) {
            return;
          }
        } catch (e) {
          console.warn(`GanttSplitOverlappingShifts is not defined`, e);
          console.warn(
            `Cann't apply >>IndexCardTextOutsideSplit<< Text Strategie for Index Card Mode, reverting to default.`
          );
          s.ganttDiagram
            .getPlugInHandler()
            .unSubscribeToPlugIns(`getSplitOverlappingShiftPlugin ${s.indexCardExecuter.UUID}`);
          s.changeIndexCardTextStrategie(new IndexCardTextDefault());
          return;
        }

        s.ganttDiagram
          .getPlugInHandler()
          .unSubscribeToPlugIns(`getSplitOverlappingShiftPlugin ${s.indexCardExecuter.UUID}`);

        this._splitOverlappingShiftsPlugin = bestGanttPlugin;

        this._splitOverlappingShiftsPlugin.addOverlapCompareMethod(
          `overlapMethodFromIndexCardText ${s.indexCardExecuter.UUID}`,
          this._overlapCompareIndexCard.bind(this),
          10
        );

        this._initCallbacks();
        if (!this._textBoxWidthMap) {
          this._createTextBoxWidthMap();
        }
      });
  }

  /**
   * Creates a map with shift id and associated max width of text box in px.
   */
  private _createTextBoxWidthMap(): void {
    const s = this._executer;

    this._textBoxWidthMap = new Map();

    const textBoxDataSet = s.indexCardExecuter.dataSet;

    for (const shiftId in textBoxDataSet) {
      let textBoxWidth = 0;
      for (const line of textBoxDataSet[shiftId]) {
        textBoxWidth = Math.max(textBoxWidth, s.fontSizeCalc.getTextWidth(line));
      }
      this._textBoxWidthMap.set(shiftId, textBoxWidth);
    }
  }

  private _initCallbacks(): void {
    const s = this._executer;

    s.indexCardExecuter.subscribeAfterDataSetHasChanged(
      `createTextBoxWidthMap ${s.indexCardExecuter.UUID}`,
      this._createTextBoxWidthMap.bind(this)
    );
  }

  private _removeCallbacks(): void {
    const s = this._executer;

    s.indexCardExecuter.unSubscribeAfterDataSetHasChanged(`createTextBoxWidthMap ${s.indexCardExecuter.UUID}`);
  }

  private _overlapCompareIndexCard(overlapData: IOverlappingShiftsIndicator): void {
    const s = this._executer;

    let overlap = false;

    if (!overlapData.overlapping) {
      if (!overlap && s.indexCardExecuter.isShowIndexCards()) {
        // check additionally the text of index cards for overlapping to decide about splitting
        const zoom = s.ganttDiagram.getXAxisBuilder().getLastZoomEvent();
        const scale = s.ganttDiagram.getXAxisBuilder().getGlobalScale();
        const shiftStartMin = scale(overlapData.minShift.timePointStart.getTime()) * zoom.k + zoom.x;
        const shiftStartMax = scale(overlapData.maxShift.timePointStart.getTime()) * zoom.k + zoom.x;

        const endpointOfShiftWithText = shiftStartMin + (this._textBoxWidthMap.get(overlapData.minShift.id) | 0);

        overlap = endpointOfShiftWithText > shiftStartMax;
      }
      overlapData.overlapping = overlap;
    }
  }

  public build(
    dataSet: { [id: string]: string[] },
    buildData: IndexCardExecuterBuildData,
    config: IndexCardConfig,
    executer: IndexCardTextBuilder
  ): void {
    const s = executer;
    const ctx = s.indexCardExecuter.getCanvas(s.scrollContainerId).node().getContext('2d');
    const ctxFontConfig = `${config.fontSize}px Arial`;
    if (ctx.font !== ctxFontConfig) ctx.font = ctxFontConfig;
    const ellipsis = '…';
    const ellipsisWidth = s.fontSizeCalc.getTextWidth(ellipsis);
    const textContainerWidth = s.ganttDiagram
      .getNodeProportionsState()
      .getShiftViewPortProportions(s.scrollContainerId).width;

    if (!buildData.transform) {
      buildData.transform = { k: 1, x: 0, y: 0 };
    }

    for (const textParent of buildData.shifts) {
      const lines = s.getMatchingLineSet(dataSet, textParent);
      const xPos = textParent.x * buildData.transform.k + buildData.transform.x + config.padding_left;
      const maxTextWidth = s.getWidthToNextShift(buildData, textParent, textContainerWidth, config);
      for (let i = 0; i < lines.length; i++) {
        const line = lines[i];
        const y =
          s.ganttDiagram.getRenderDataHandler().getStateStorage().getYPositionShift(textParent.id) +
          config.padding_top +
          config.fontSize +
          config.lineHeight * i +
          config.lineHeight -
          s.ganttDiagram.getNodeProportionsState().getScrollTopPosition(s.scrollContainerId);
        const wrappedText =
          maxTextWidth === undefined ? line.text : s.fittingString(line.text, maxTextWidth, ellipsis, ellipsisWidth);
        if (wrappedText) {
          ctx.fillText(wrappedText, xPos, y);
        }
      }
    }
  }

  public cleanUp(executer: IndexCardTextBuilder): void {
    const s = executer;

    s.ganttDiagram
      .getPlugInHandler()
      .unSubscribeToPlugIns(`getSplitOverlappingShiftPlugin ${s.indexCardExecuter.UUID}`);

    this._removeCallbacks();

    if (this._splitOverlappingShiftsPlugin) {
      this._splitOverlappingShiftsPlugin.removeOverlapCompareMethod(
        `overlapMethodFromIndexCardText ${s.indexCardExecuter.UUID}`
      );
    }
  }
}
