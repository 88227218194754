import { EntryElement } from '@app-modeleditor/components/entry-collection/entry-element';

export class NumericInput extends EntryElement {
  private disableArrows: boolean;
  private selectedIndex: number;

  public getSelectedIndex(): number {
    return this.selectedIndex;
  }

  public setSelectedIndex(selectedIndex: number): void {
    this.selectedIndex = selectedIndex;
  }
  /**
   * whether arrows to increase/decrease numeric values are disabled or not
   * @returns boolean
   */
  public isDisableArrows(): boolean {
    return typeof this.disableArrows === 'boolean' ? this.disableArrows : true;
  }

  /**
   * disable/enable arrows to increase/decrease numeric values
   * @param disableArrows boolean
   * @returns this
   */
  public setDisableArrows(disableArrows: boolean): this {
    this.disableArrows = disableArrows;
    return this;
  }

  constructor() {
    super();
  }
}
