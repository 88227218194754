<ng-container *ngIf="label">
  <ng-container *ngIf="image?.isCustom(); else defaultImage">
    <ng-container *ngIf="image.getNamespace() !== 'customFont'; else customFontIcon">
      <mat-icon
        [class.disabled]="disabled"
        #iconRef
        aria-hidden="false"
        matBadgeSize="small"
        [matBadge]="badgeCount"
        [svgIcon]="image.getValue()"
      >
      </mat-icon>
    </ng-container>
  </ng-container>
</ng-container>

<!-- using custom SVG -->
<ng-template #defaultImage>
  <mat-icon [class.disabled]="disabled" #iconRef matBadgeSize="small" aria-hidden="false" [matBadge]="badgeCount">
    {{ image ? image.getLabel() : label }}
  </mat-icon>
</ng-template>

<!-- using custom SaxMS Icon Font -->
<ng-template #customFontIcon>
  <mat-icon
    class="saxms-icon"
    [ngClass]="image.getLabel()"
    [class.disabled]="disabled"
    #iconRef
    aria-hidden="false"
    matBadgeSize="small"
    [matBadge]="badgeCount"
  >
    {{ image ? image.getLabel() : label }}
  </mat-icon>
</ng-template>
