<div #splitter class="gantt-split-wrapper" [style.height]="'100%'">
  <as-split
    [gutterSize]="8"
    [direction]="'horizontal'"
    [useTransition]="false"
    [disabled]="!hasLegend()"
    class="gantt-vertical-split"
    (dragStart)="resizeDockStart()"
    (drag)="resizeDock()"
    (dragEnd)="resizeDockEnd($event, dockViewModes.RIGHT)"
  >
    <as-split-area [size]="100 - ganttSettingsService.getGanttSettings().dockRightSize">
      <as-split
        [gutterSize]="8"
        [direction]="'vertical'"
        [useTransition]="false"
        [disabled]="!hasLegend()"
        unit="pixel"
        class="gantt-vertical-split"
        (dragStart)="resizeDockStart()"
        (drag)="resizeDock()"
        (dragEnd)="resizeDockEnd($event, dockViewModes.BOTTOM)"
      >
        <as-split-area [size]="'*'">
          <lib-gantt #ganttLib></lib-gantt>
        </as-split-area>
        <as-split-area
          *ngIf="ganttDockService.isDockBottom()"
          [size]="ganttSettingsService.getGanttSettings().dockBelowSize"
          [minSize]="24"
        >
          <app-dock #dockBottom [dockViewType]="dockViewModes.BOTTOM"></app-dock>
        </as-split-area>
      </as-split>
    </as-split-area>
    <as-split-area
      *ngIf="ganttDockService.isDockRight()"
      [size]="ganttSettingsService.getGanttSettings().dockRightSize"
    >
      <app-dock #dockRight [dockViewType]="dockViewModes.RIGHT"></app-dock>
    </as-split-area>
  </as-split>
</div>
