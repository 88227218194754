import * as d3 from 'd3';
import { DataHandler } from '../data-handler/data-handler';
import {
  GanttCanvasRow,
  GanttCanvasShift,
  GanttDataRow,
  GanttDataShift,
} from '../data-handler/data-structure/data-structure';
import { RenderDataHandler } from '../render-data-handler/render-data-handler';

/**
 * Static utility methods for gantt and development.
 * @static
 * @class
 */
export abstract class GanttUtilities {
  private static readonly _charSet = 'BCDFGHJKLMNPQRSTVWXYZbcdfghjklmnpqrstvwxyz0123456789';

  /**
   * @description Returns a randomly created UUID <br>
   * [SOUCE: Stackoverflow]{@link https://stackoverflow.com/a/1349462} about random Strings
   * @param {number} [length=8] Length of the returned ID.
   * @param {char[]} [charSet] charset used
   * @returns {String}
   */
  static generateUniqueID(len = 8, charset = this._charSet) {
    const charSet = charset || this._charSet; // Charset without vowels so no real / pronouncable words can be created
    let randomString = '';
    for (let i = 0; i < len; i++) {
      const randomPoz = Math.floor(Math.random() * charSet.length);
      randomString += charSet.substring(randomPoz, randomPoz + 1);
    }
    return randomString;
  }

  /**
   * Prints amount of DOM nodes to the console
   */
  static logDOMNodeAmount() {
    console.info({ nodeAmount: document.getElementsByTagName('*').length });
  }

  /**
   * Calculates the boundings of a text.
   * @param {String} textToCheck Text whose size is to be determined.
   * @param {HTMLElement} svgNodeToTest SVG node for testing.
   * @param {String} classNameOfText If the text belongs to a class, the styling is considered.
   * @returns An object with textboundings.
   */
  static getBoundingsOfText(textToCheck, svgNodeToTest, classNameOfText, fontSize = null) {
    const text = d3
      .select(svgNodeToTest)
      .append('text')
      .attr('class', classNameOfText)
      .style('font-size', function () {
        if (fontSize) {
          return fontSize;
        }
      })
      .text(textToCheck);

    const bbox = text.node().getBBox();
    text.remove();

    return bbox;
  }

  /**
   * Adds an id to the weaken array in object data.
   * @param {object} objectData Data of the object that should not be weaken.
   * @param {string} weakenId Id to be set.
   */
  static registerWeakenId(objectData, weakenId) {
    // console.info(`register no RENDER ID`, objectData)
    if (this.isStringInArray(weakenId, objectData.weaken)) return;
    objectData.weaken.push(weakenId);
  }

  /**
   * Removes an id from the weaken array in object data.
   * @param {object} objectData Data of the object that should weaken.
   * @param {string} noRenderId Id to be set.
   */
  static removeWeakenId(objectData, weakenId) {
    const weakenArray = objectData.weaken;
    objectData.weaken = weakenArray.filter((e) => e !== weakenId);
  }

  /**
   * Adds an id to the noRender array in object data.
   * @param objectData Data of the object that should not be rendered.
   * @param noRenderId Id to be set.
   */
  static registerNoRenderId<T extends INoRenderBaseType>(objectData: T, noRenderId: string): void {
    if (this.isStringInArray(noRenderId, objectData.noRender) || objectData.notHideable) return;
    objectData.noRender.push(noRenderId);
  }

  static registerNoRenderIdOnCanvasShift(dataHandler: DataHandler, objectData, noRenderId) {
    if (this.isStringInArray(noRenderId, objectData.noRender)) return;

    const newNoRenderArray = [...objectData.noRender, ...[noRenderId]];

    const updateData = new Map<string, Partial<GanttCanvasShift>>();

    updateData.set(objectData.id, {
      noRender: newNoRenderArray,
    });

    dataHandler.updateCanvasShiftPropertiesInCanvasDataByShiftId(updateData);
  }

  /**
   * Removes an id from the noRender array in object data.
   * @param {object} objectData Data of the object that should not be rendered.
   * @param {string} noRenderId Id to be set.
   */
  static removeNoRenderId(objectData, noRenderId) {
    const noRenderArray = objectData.noRender;
    objectData.noRender = noRenderArray.filter((e) => e !== noRenderId);
  }

  static removeNoRenderIdOnCanvasShift(dataHandler: DataHandler, objectData, noRenderId) {
    const noRenderArray = objectData.noRender;
    const newNoRenderArray = noRenderArray.filter((e) => e !== noRenderId);
    const updateData = new Map<string, Partial<GanttCanvasShift>>();

    updateData.set(objectData.id, {
      noRender: newNoRenderArray,
    });

    dataHandler.updateCanvasShiftPropertiesInCanvasDataByShiftId(updateData);
  }

  /**
   * Indicates if a string is an entry of an string array
   * @param {string} str String to search for.
   * @param {string[]} strArray Array to be searched.
   * @returns {boolean}
   */
  static isStringInArray(str, strArray) {
    str = str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&'); // replace invalid regular expressions
    for (let j = 0; j < strArray.length; j++) {
      if (typeof strArray[j] === 'string' || strArray[j] instanceof String) {
        const value = strArray[j].replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&'); // replace invalid regular expressions
        if (value.match(str)) {
          return true;
        } else if (value.localeCompare(str) === 0) {
          return true;
        } // Check with other function if matching is not working correctly. E.g. for strings containing ".".
      }
    }
    return false;
  }

  /**
   * Use this to clear timeOuts captured in an object.
   * @param {Object} timeOutObject Object timeout references are stored in.
   */
  static clearTimeouts(timeOutObject) {
    for (const timeOut in timeOutObject) {
      clearTimeout(timeOutObject[timeOut]);
    }
  }

  /**
   * @description Prints a callbackStack to the console for easier debugging. <br>
   * Use this to make sure the callback is <b>properly initialised and cleared.</b>
   * @param {Object} callbackStack CallbackStack to debug.
   * @param {string} name name of the callbackstack
   */
  static debugCallbackStack(callbackStack, name = 'default') {
    let counter = 0;
    console.info(name);
    for (const callbackID in callbackStack) {
      console.info(`->${counter} CallbackID: ${callbackID}`);
      console.info('--->' + callbackStack[callbackID]);
      counter++;
    }

    if (Object.keys(callbackStack).length === 0 && callbackStack.constructor === Object) {
      console.info(`The Callbackstack ${name} is EMPTY!`);
    }
  }

  /**
   * Unpacks scrollEvent, returning scrollTop value
   * @param {Event} scrollEvent
   * @returns {number} scrollTop
   */
  static getScrollTopByScrollEvent(scrollEvent) {
    const target = scrollEvent.target;
    if (!target) {
      return 0;
    }
    const scrollTop = target.scrollTop;
    if (scrollTop === undefined) {
      console.warn(`scrollTop undefined`);
      return 0;
    }
    return scrollTop;
  }

  /**
   * Copies / Duplicates a given row.
   * Used for overlapping shifts row creation and sticky Row generation.
   * @param {GanttSplitOverlappingShifts} scope Reference to GanttSplitOverlappingShifts to use calculation functions.
   * @param {GanttDataRow} originRow Original row data.
   * @param {number} rowIndex Used to create unique row id.
   * @param {string} group Group attribute.
   * @returns {GanttDataRow} Copy of given row.
   */
  static createNewRowFromRow(originRow: GanttDataRow, idSuffix: string = '_' + this.generateUniqueID(), group: string) {
    const newRow: GanttDataRow = {
      id: originRow.id + idSuffix,
      open: originRow.open,
      additionalData: this.cloneObject(originRow.additionalData),
      textColor: originRow.textColor,
      tooltip: originRow.tooltip,
      noRender: [...originRow.noRender],
      allowedEntryTypes: [...(originRow.allowedEntryTypes || [])],
      group: group ? group : null,
      originalResource: originRow.id,
      stroke: originRow.stroke,
      color: originRow.color,
      indicatorColor: originRow.indicatorColor,
      sampleValues: [...originRow.sampleValues],
      startCellIndexForSampleValues: originRow.startCellIndexForSampleValues,
      name: null,
      milestones: [],
      shifts: [],
      child: [],
      notHideable: originRow.notHideable,
    };
    return newRow;
  }

  /**
   * Copies / Duplicates a given shift.
   */
  static createNewShiftFromShift(originShift: GanttDataShift): GanttDataShift {
    return {
      id: originShift.id,
      name: originShift.name,
      originName: originShift.name,
      symbols: [...originShift.symbols],
      additionalData: this.cloneObject(originShift.additionalData),
      noRender: [...originShift.noRender],
      timePointStart: new Date(originShift.timePointStart),
      timePointEnd: new Date(originShift.timePointEnd),
      tooltip: originShift.tooltip,
      color: originShift.color,
      secondColor: originShift.secondColor,
      firstColor: originShift.firstColor,
      highlighted: originShift.highlighted,
      strokePattern: originShift.strokePattern,
      strokeColor: originShift.strokeColor,
      pattern: originShift.pattern,
      patternColor: originShift.patternColor,
      selected: originShift.selected,
      entryTypes: [...originShift.entryTypes],
      blockTypes: [...originShift.blockTypes],
      opacity: originShift.opacity,
      weaken: originShift.weaken,
      disableMove: originShift.disableMove,
      stickyBlockType: originShift.stickyBlockType,
      strokeWidth: originShift.strokeWidth,
      modificationRestriction: originShift.modificationRestriction,
      editable: originShift.editable,
      isFullHeight: originShift.isFullHeight,
      noRoundedCorners: originShift.noRoundedCorners,
    };
  }

  /**
   * Finds row that is visually at the top of the container based on the scrollValue.
   * @param yAxisDataSet
   * @param scrollTop current scrolltop value
   * @param renderDataHandler If specified, the y positions of the rows will be obtained from this {@link RenderDataHandler} instance (default is `undefined`).
   * @return
   */
  public static findTopRow(
    yAxisDataSet: GanttCanvasRow[] = [],
    scrollTop = 0,
    renderDataHandler: RenderDataHandler = undefined
  ): GanttCanvasRow {
    if (!yAxisDataSet || !yAxisDataSet.length) {
      return null;
    }
    const closest = yAxisDataSet
      .map((row) => {
        return renderDataHandler ? renderDataHandler.getStateStorage().getYPositionRow(row.id) : row.y;
      })
      .reduce((prev, curr) => {
        return Math.abs(curr - scrollTop) < Math.abs(prev - scrollTop) ? curr : prev;
      });

    const topRow = yAxisDataSet.find(
      (row) => (renderDataHandler ? renderDataHandler.getStateStorage().getYPositionRow(row.id) : row.y) === closest
    );
    return topRow;
  }

  /**
   * Method that removes all Callbacks from a collection of callbackStacks.
   * Useful to securely cleanup observers on destroy so the garbage collection can remove the objects.
   * @param {any} callbackStackCollection
   */
  static unsubscribeObservers(callbackStackCollection) {
    for (const callbackType in callbackStackCollection) {
      const callbackStack = callbackStackCollection[callbackType];
      for (const callback in callbackStack) {
        delete callbackStack[callback];
      }
    }
  }

  /**
   * Filters a render data set by view port proportions and returns the filtered data set.
   * @param node HTML node in which the data is rendered
   * @param dataSet Render data set to filter by view port
   * @param renderDataHandler If specified, the row y values provided by the render data handler will be used.
   * @param offset The offset in pixels that should also be rendered
   * @param nodeHeight
   * @param nodeScrollTop
   * @returns filtered render data set.
   */
  public static filterDataSetByViewPort(
    node: HTMLElement,
    dataSet: GanttCanvasRow[],
    renderDataHandler: RenderDataHandler = null,
    offset = 0,
    nodeHeight: number = null,
    nodeScrollTop: number = null
  ): GanttCanvasRow[] {
    const yMin = (nodeScrollTop != null ? nodeScrollTop : node.scrollTop) - offset;
    const yMax = (nodeHeight != null ? nodeHeight : node.getBoundingClientRect().height) + 2 * offset + yMin;
    // filter data set to render only elements that are in view port
    return dataSet.filter((d) => {
      // vertically out of bounds check
      const startY = (renderDataHandler ? renderDataHandler.getStateStorage().getYPositionRow(d.id) : d.y) || 0;
      const endY = startY + (d.height || 0);
      if (startY < yMin && endY < yMin) {
        return false;
      }
      if (startY > yMax && endY > yMax) {
        return false;
      }
      return true;
    });
  }

  static cloneObject(obj: any): any {
    const clone = Array.isArray(obj) ? [] : {};
    for (const i in obj) {
      if (typeof obj[i] == 'object' && obj[i] != null) {
        clone[i] = this.cloneObject(obj[i]);
      } else {
        clone[i] = obj[i];
      }
    }
    return clone;
  }

  /**
   * Checks if the given date parameter is a valid date object.
   * @param {Date} d
   */
  static isValidDate(d) {
    return d instanceof Date;
  }

  /**
   * Use for moving elements within arrays by old and new index position.
   * @param {any[]} arr Array in which the elements are to be moved.
   * @param {number} old_index Current position of the element
   * @param {number} new_index New position of the element
   */
  static moveArrayElementPosition(arr, old_index, new_index) {
    while (old_index < 0) {
      old_index += arr.length;
    }
    while (new_index < 0) {
      new_index += arr.length;
    }
    if (new_index >= arr.length) {
      let k = new_index - arr.length;
      while (k-- + 1) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
  }

  /**
   * Use this method to manage Callback Stacks with priorities / order.
   * HIGHER numbers equal a LOWER priority.
   *
   * Useful for example to propagate a value through several callback methods in a set order.
   * See {@see TextOverlayOutsideSplit} for an example.
   *
   * @param {any[]} array Array that contains the callbacks, needed to maintain an order.
   * @param {string} id Id of the callback to be registered, if such id callback is alredy registered it's ignored.
   * @param {function} func Callback method to register.
   * @param {number} order priority number for callback.
   */
  static addCallbackWithPriority(array, id, func, order) {
    if (order == undefined) {
      console.error('No order given!');
      return;
    }
    const callbackFunc = { callback: {}, order: 0 };
    callbackFunc.callback[id] = func;
    callbackFunc.order = order;
    let el: any;
    if (
      array
        .map((el) => el.callback)
        .map((el) => Object.keys(el))
        .flat()
        .find((el) => el == id)
    ) {
      console.warn(`Callback ${id} is alredy in priorised CallbackStack ${array.map(el.callback)}`);
      return;
    }
    array.push(callbackFunc);
    array.sort((a, b) => a.order - b.order);
  }

  static removeCallbackWithPriority(array, id) {
    const index = array.findIndex((func) => func.callback.hasOwnProperty(id));
    array.splice(index, 1);
  }

  /*
   * Dispatches d3 drag events to outside.
   * This is necessary because d3 blocks the propagation of mouse events during drag.
   * But events are important for testing.
   * So the source events are wrapped in a custom event and dispatched.
   *
   * @param {D3Selection} d3Selection
   * @param {D3Event} d3Event
   */
  static dispatchD3EventToOutside(d3Selection, d3Event) {
    const sourceEvent = d3Event.sourceEvent;
    const className = 'gantt_shifts_in-front-canvas';
    const containerHeight = document.getElementsByClassName(className)[0].clientHeight;
    const containerWidth = document.getElementsByClassName(className)[0].clientWidth;

    const relativeY = (100 / containerHeight) * sourceEvent.offsetY;
    const relativeX = (100 / containerWidth) * sourceEvent.offsetX;

    d3Selection.dispatch(`gantt-${sourceEvent.type}`, {
      bubbles: true,
      detail: {
        relativeY,
        relativeX,
        className,
      },
    });
  }

  /**
   * Converts a value into a hex value.
   * @param {any} c
   * @returns hex value
   */
  static convertToHex(c) {
    const hex = c.toString(16);
    return hex.length == 1 ? '0' + hex : hex;
  }

  /**
   * Converts RGB-colors to a hex color string.
   * @param {number} r Value red 0-255
   * @param {number} g Value green 0-255
   * @param {number} b Value blue 0-255
   * @returns {string} hex string
   */
  static rgbToHex(r, g, b) {
    return '#' + this.convertToHex(r) + this.convertToHex(g) + this.convertToHex(b);
  }

  /**
   * Converts a hex string into a rgb value object.
   * @param {string} hex Hex string
   * @returns {r: number, g: number, b: number}
   */
  static hexToRgb(hex) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  }

  /*
   * Helper function to lighten or darken a given color.
   * @param {string} col color to change
   * @param {number} amt amount of influence to the color (- darker, + lighter)
   * @returns {string} new color string
   */
  static lightenDarkenColor(col, amt) {
    let usePound = false;
    if (col[0] == '#') {
      col = col.slice(1);
      usePound = true;
    }

    const num = parseInt(col, 16);

    let r = (num >> 16) + amt;
    if (r > 255) r = 255;
    else if (r < 0) r = 0;
    r = r.toString(16);
    r = r.length === 1 ? '0' + r : r;

    let g = ((num >> 8) & 0x00ff) + amt;
    if (g > 255) g = 255;
    else if (g < 0) g = 0;
    g = g.toString(16);
    g = g.length === 1 ? '0' + g : g;

    let b = (num & 0x0000ff) + amt;
    if (b > 255) b = 255;
    else if (b < 0) b = 0;
    b = b.toString(16);
    b = b.length === 1 ? '0' + b : b;

    return (usePound ? '#' : '') + r + g + b;
  }
}

export interface INoRenderBaseType {
  noRender?: string[];
  notHideable?: boolean;
}
