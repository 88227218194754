import {
  HttpErrorResponse,
  HttpHandler,
  HttpHeaderResponse,
  HttpHeaders,
  HttpInterceptor,
  HttpProgressEvent,
  HttpRequest,
  HttpResponse,
  HttpSentEvent,
  HttpUserEvent,
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { GlobalUtils } from 'frontend/src/dashboard/global-utils';
import { environment } from 'frontend/src/environments/environment';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';
import { AuthActions } from '../login/data-access/auth.actions';
import { AuthState } from '../login/data-access/auth.state';
import { AssetPathService } from '../utils/asset-path.service';
import { AuthenticationService } from './authentication/auth.service';
import { ConfigService } from './config/config.service';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  isRefreshingToken = false;
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(
    private store: Store,
    private injector: Injector,
    private configApi: ConfigService,
    private assetPathService: AssetPathService,
    private translate: TranslateService
  ) {}

  addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
    let headers: HttpHeaders = req.headers;
    headers = headers.set('Authorization', `bearer ${token}`);

    if (this.configApi.access().customHttpHeaders === true) {
      headers = headers
        .set('saxms-timestamp', `${new Date().getTime()}`)
        .set('tenant-id', `${environment.user?.tenantId ?? ''}`)
        .set('user-id', `${environment.user?.id ?? ''}`);
    }

    return req.clone({ headers });
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
    /**
     * calls to local filesystem
     */

    if (req.url.indexOf('assets/') !== -1) {
      if (req.url.indexOf('?') === -1) {
        const newUrl: any = {
          url: this.assetPathService.getPathWithVersionUnderCondition(
            req.url,
            () => {
              return this.configApi.access() ? true : false;
            },
            GlobalUtils.generateUUID()
          ),
          urlWithParams: this.assetPathService.getPathWithVersionUnderCondition(
            req.url,
            () => {
              return environment ? true : false;
            },
            GlobalUtils.generateUUID()
          ),
        };
        req = Object.assign(req, newUrl);
      }
      return next.handle(req);
    }

    /**
     * authentication requests
     */
    if (req.url.indexOf('oauth2/token') !== -1) {
      const authUrl: string = this.configApi.getAuthUrl();
      const newUrl = {
        url: authUrl + req.url,
        urlWithParams: authUrl + req.urlWithParams,
      };
      req = Object.assign(req, newUrl);
      return this.handleRequest(req, next);
    }

    /**
     * web requests
     */
    const restUrl: string = this.configApi.getRequestUrl();

    const newUrl = {
      url: restUrl + req.url,
      urlWithParams: restUrl + req.urlWithParams,
    };
    req = Object.assign(req, newUrl);
    return this.handleRequest(req, next);
  }

  handleRequest(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    const token = this.store.selectSnapshot(AuthState.token);
    return next.handle(this.addToken(req, token)).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse) {
          switch (error.status) {
            case 400:
              return this.handle400Error(error);
            case 401:
              return this.handle401Error(req, next);
            case 403:
              return this.handle403Error(error);
          }
        }
        return throwError(() => error);
      })
    );
  }

  handle400Error(error): Observable<any> {
    if (error && error.status === 400 && error.error && error.error.error === 'invalid_grant') {
      // If we get a 400 and the error message is 'invalid_grant', the token is no longer valid so logout.
      return this.logoutUser(error);
    }
    return throwError(() => error);
  }

  handle401Error(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    const authService = this.injector.get(AuthenticationService);
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;

      // Reset here so that the following requests wait until the token
      // comes back from the refreshToken call.
      this.tokenSubject.next(null);
      return (
        this.store
          .dispatch(new AuthActions.Refresh())
          .pipe(
            switchMap(() => {
              const token = this.store.selectSnapshot(AuthState.token);
              if (token) {
                this.tokenSubject.next(token);
                return next.handle(this.addToken(req, token));
              }
              // If we don't get a new token, we are in trouble so logout.
              return this.logoutUser('requesting an oauth2 token failed!');
            })
          )
          // If there is an exception calling 'refreshToken', bad news so logout.
          .pipe(catchError((error) => this.logoutUser(error)))
          .pipe(finalize(() => (this.isRefreshingToken = false)))
      );
    } else {
      return this.tokenSubject
        .pipe(filter((token) => token != null))
        .pipe(take(1))
        .pipe(
          switchMap((token) => {
            if (token !== '' && token) {
              return next.handle(this.addToken(req, token));
            }
          })
        );
    }
  }

  handle403Error(error): Observable<any> {
    if (error?.error?.localizedMessage === 'invalid_grant') {
      // catch cryptic error message
      error.error.localizedMessage = this.translate.instant(
        'SYSTEM.MESSAGE.EXCEPTION.UserNameOrWrongPasswordException'
      );
    }
    return throwError(() => error);
  }

  logoutUser(error?): Observable<any> {
    const authService = this.injector.get(AuthenticationService);
    authService.signOut().subscribe();
    return throwError(() => new Error('refresh token is invalid'));
  }
}
