import { Injectable, Type } from '@angular/core';
import { ResourceAdapter } from 'frontend/src/dashboard/model/resource/resource-adapter.service';
import { ActionAdapter } from '../button/action-adapter.service';
import { Action } from '../button/action/action';
import { Button } from '../button/button';
import { ButtonAdapter } from '../button/button-adapter.service';
import { ContextMenuItem } from '../contextmenu/context-menu-item';
import { ContextMenuItemAdapter } from '../contextmenu/context-menu-item.adapter.service';
import { Filter } from '../menu-item/filter';
import { HierarchicalMenuItem } from '../template-ui/hierarchical-menu-item';
import { TemplateAdapter } from './../../utils/template-factory.service';
import { TemplateResourceAdapter } from './../../utils/template-resource-factory.service';
import { Menu } from './menu';
import { HMI } from './tree-item';

@Injectable({
  providedIn: 'root',
})
export class MenuAdapter {
  constructor(
    private templateResourceFactory: TemplateResourceAdapter,
    private actionAdapter: ActionAdapter,
    private _resourceAdapter: ResourceAdapter,
    private _contextMenuItemAdapter: ContextMenuItemAdapter,
    private _buttonAdapter: ButtonAdapter
  ) {}

  public getHMI<T extends HMI>(type: Type<T>, data: any, scope: TemplateAdapter): T {
    const t: T = this.templateResourceFactory.inherit<T>(type, data);
    t.setContent(scope.adapt(data.content))
      .setGetDataAutomatically(false)
      .setIcons(data.icons)
      .setLeaf(data.leaf)
      .setTenantId(data.tenantId)
      .setCloneRestUrl(data.cloneRestUrl)
      .setAdditionalContextMenuItems(
        (data.additionalContextMenuItems || []).map((i) =>
          this._contextMenuItemAdapter.inherit<ContextMenuItem>(ContextMenuItem, i)
        )
      )
      .setInvisibleDefaultContextMenuItems(data.invisibleDefaultContextMenuItems)
      .setCreateWizardRestUrls(data.createRestWizardUrls)
      .setFilterSearchUrl(data.filterSearchUrl)
      .setHandlingType(data.handlingType)
      .setEditRestUrl(data.editRestUrl)
      .setDeleteRestUrl(data.deleteRestUrl)
      .setCreateRestUrls(data.createRestUrls)
      .setAdditionalDisplayElements(data.additionalDisplayElements)
      .setIcon(data.icon)
      .setUniqueTemplateId(data.uniqueTemplateId)
      .setHideContentPartNavigation(data.hideContentPartNavigation)
      .setShowContentPartNavigation(data.showContentPartNavigation)
      .setTenantName(data.tenantName)
      .setMultitenant(data.multitenant)
      .setShowNotificationsCount(data.showNotificationsCount)
      .setNotificationsCount(data.notificationsCount)
      .setFreetextSearchUrl(data.freetextSearchUrl)
      .setSubHierachicMenuItemsRestUrl(data.subHierachicMenuItemsRestUrl)
      .setFilters((data.filters || []).map((f) => this.parseFilter(scope, f)))
      .setHierachicMenuItems(
        (data.hierachicMenuItems || []).map((h) => {
          const el = scope.adapt(h);
          return el;
        })
      );

    if (data.hiddenDefaultTemplateElements) {
      t.setHiddenDefaultTemplateElements(data.hiddenDefaultTemplateElements);
    }

    if (data.preDeliveryActions) {
      t.setPreDeliveryActions(
        (data.preDeliveryActions || []).map((item) => this.actionAdapter.parseAction<Action>(Action, item))
      );
    }

    return t;
  }

  public parseHMI(data, scope: TemplateAdapter): HierarchicalMenuItem {
    const onCustomSaveActionBtn = this._buttonAdapter.inherit<Button>(Button, data.onCustomSaveAction);
    if (onCustomSaveActionBtn instanceof Button) {
      onCustomSaveActionBtn.setResourceId(data.resourceId);
    }

    return this.getHMI<HierarchicalMenuItem>(HierarchicalMenuItem, data, scope)
      .setNameRequired(data.nameRequired)
      .setContentRestUrl(data.contentRestUrl)
      .setToolbarModifier(data.toolbarModifier)
      .setRestListSizeUrl(data.restListSizeUrl)
      .setForceReloadByReOpen(data.forceReloadByReOpen)
      .setUpdateContentRestUrl(data.updateContentRestUrl)
      .setCreatedFieldIdentifier(data.createdFieldIdentifier)
      .setNameFieldIdentifier(data.nameFieldIdentifier)
      .setNameUnique(data.nameUnique)
      .setSideBar(scope.adapt(data.sideBar))
      .setShowExtendedFilter(data.showExtendedFilter)
      .setOnCustomSaveAction(onCustomSaveActionBtn)
      .setOnCustomCloseTemplateAction(
        data.onCustomCloseTemplateAction
          ? this.actionAdapter.parseAction<Action>(Action, data.onCustomCloseTemplateAction)
          : undefined
      );
  }

  private parseFilter(scope: TemplateAdapter, data: any): Filter {
    return this._resourceAdapter
      .inherit<Filter>(Filter, data)
      .setDisableFreeTextSearch(data.disableFreeTextSearch)
      .setFieldIdentifier(data.fieldIdentifier)
      .setFilterOperator(data.filterOperator)
      .setFreeTextSearchFilter(data.freeTextSearchFilter)
      .setIgnoreNullValues(data.ignoreNullValues)
      .setAvailableValues(data.availableValues)
      .setEnableSort(data.enableSort)
      .setSortOrder(data.sortOrder)
      .setDisplayFilterElement(scope.adapt(data.displayFilterElement))
      .setRequireFullAssignment(data.requireFullAssignment)
      .setViewFieldIdentifier(data.viewFieldIdentifier);
  }

  public parseMenu(data, scope: TemplateAdapter): Menu {
    return this.getHMI<Menu>(Menu, data, scope)
      .setMenuUpdateNotificationsRestURL(data.menuUpdateNotificationsRestURL)
      .setReloadMenuRestURL(data.reloadMenuRestURL);
  }
}
