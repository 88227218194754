import { ETemplateType } from 'frontend/src/dashboard/model/resource/template-type';
import { ContentElement } from '../content/content-element/content-element';

export class FileViewer extends ContentElement {
  constructor() {
    super();
    this.setType(ETemplateType.FILE_VIEWER);
    this.setResizeable(true);
  }
}
