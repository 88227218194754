import { GanttCallBackStackExecuter } from '../../callback-tools/callback-stack-executer';
import { IFoundShiftData, ShiftDataFinder } from '../../data-handler/data-finder/shift-data-finder';
import { GanttDataRow, GanttDataShift } from '../../data-handler/data-structure/data-structure';
import { ShiftDataSorting } from '../../data-handler/data-tools/data-sorting';
import { GanttUtilities } from '../../gantt-utilities/gantt-utilities';
import { GanttShiftComponents } from './shift-components';

/**
 * Extension for shift component chain handler to combine components to one block.
 * @keywords plugin, combine, components, subshift, supershift, part, doi,
 * @plugin shift-components
 * @class
 * @constructor
 * @param {GanttShiftComponents} componentHandler
 * @param {GanttShiftComponentsCombineChainRules} rulesSet
 */
export class SuperBlockBuilder {
  componentHandler: GanttShiftComponents;
  private _combineShiftComponents: boolean;
  combinedShiftsList: any;
  rules: GanttShiftComponentsCombineChainRules;
  callBack: any;
  active: boolean;

  constructor(componentHandler, rulesSet) {
    this.componentHandler = componentHandler;

    this._combineShiftComponents = false;

    this.combinedShiftsList = {};

    this.rules = rulesSet || new GanttShiftComponentsCombineChainRules();

    this.callBack = {
      afterCombineShifts: {},
    };

    this.active = false;
  }

  /**
   * Combines registered chained shifts to one shift.
   * Works like an "active"-mode, all necessary callbacks will be registered.
   * @param {boolean} [update] If false, gantt will not be updated after combine.
   */
  combineChainedShifts(update = false) {
    const s = this;
    s._combineShiftComponents = true;
    s._registerCallbacks();

    if (!s.componentHandler.dataHandler.shiftComponents) return;
    const originDataSet = s.componentHandler.ganttDiagram.getDataHandler().getOriginDataset().ganttEntries;
    let shiftIds = [];

    s.componentHandler.dataHandler.shiftComponents.forEach((shiftGroup) => {
      shiftIds = [...shiftIds, ...shiftGroup.group];
    });

    const shiftMap = this.removeShiftsByIds(originDataSet, shiftIds);

    s.componentHandler.dataHandler.shiftComponents.forEach((shiftGroup) => {
      const shiftComponents = shiftGroup.group.flatMap((shiftId) => {
        const shift = shiftMap.get(shiftId);
        if (!shift) {
          console.warn(`Shift component with id ${shiftId} was not found and is ignored`);
          return [];
        }

        return shift;
      });

      if (shiftComponents[0]?.shift) {
        const combinedShift = s._createCombinedShift(
          shiftGroup.id,
          shiftComponents,
          shiftGroup.tooltip,
          shiftGroup.details
        );

        s.combinedShiftsList[shiftGroup.id] = {
          shiftComponents: shiftComponents.map((foundResult) => foundResult.shift),
          newShift: combinedShift,
        };

        shiftComponents[0].shiftRow.shifts.push(combinedShift);
      }
    });

    ShiftDataSorting.sortOriginShifts(originDataSet);

    s.active = true;

    GanttCallBackStackExecuter.execute(s.callBack.afterCombineShifts, { combinedShiftList: s.combinedShiftsList });
    if (update) s.componentHandler.ganttDiagram.update();
  }

  /**
   * Registers the callbacks.
   * @private
   */
  private _registerCallbacks() {
    const s = this;
    s.componentHandler.addAfterChainingCallback(
      'GanttShiftComponentsCombineChains_modus',
      s.combineChainedShifts.bind(s, false)
    );
    s.componentHandler.addAfterRemoveGroupCallback(
      'GanttShiftComponentsCombineChains_modus',
      s._afterSplitRemoveCombinedShift.bind(s)
    );
    s.componentHandler.addAfterChangeActivationStateCallback(
      'GanttShiftComponentsCombineChains_modus',
      s._activateByExecuter.bind(s)
    );
    s.componentHandler
      .getShiftComponentDataHandler()
      .addAfterRemoveComponentsCallback('GanttShiftComponentsCombineChains_modus', s._updateShiftCombine.bind(s));
  }

  /**
   * Removes the callbacks.
   * @private
   */
  private _removeCallbacks() {
    const s = this;
    s.componentHandler.removeAfterChainingCallback('GanttShiftComponentsCombineChains_modus');
    s.componentHandler.removeAfterRemoveGroupCallback('GanttShiftComponentsCombineChains_modus');
    s.componentHandler.removeAfterChangeActivationStateCallback('GanttShiftComponentsCombineChains_modus');
    s.componentHandler
      .getShiftComponentDataHandler()
      .removeAfterRemoveComponentsCallback('GanttShiftComponentsCombineChains_modus');
  }

  /**
   * Creates data for combined shift out of chained shifts.
   * @private
   * @param {String} shiftId Id of combined shift.
   * @param {IFoundShiftData[]} combinedShiftListData List of shifts which will be combined by creating new shift.
   * @param {string} [tooltip] Tooltip of combined block. Takes tooltip of first shift block of given combinedShiftListData as default.
   * @param {any} [details] additionalDetails
   * @returns {GanttDataShift}
   */
  private _createCombinedShift(shiftId, combinedShiftListData: IFoundShiftData[], tooltip, details) {
    const s = this;
    // const newShift: GanttDataShift = structuredClone(combinedShiftListData[0].shift);
    const newShift: GanttDataShift = GanttUtilities.createNewShiftFromShift(combinedShiftListData[0].shift); // faster then structuredClone
    newShift.id = shiftId;
    newShift.timePointStart = new Date(newShift.timePointStart);
    newShift.timePointEnd = new Date(combinedShiftListData[combinedShiftListData.length - 1].shift.timePointEnd);
    if (s.componentHandler.getShiftComponentDataHandler().getSuperBlockBackendData()) {
      const superblockDefinition = s.componentHandler.getShiftComponentDataHandler().getSuperBlockBackendData()[
        shiftId
      ];
      if (superblockDefinition) {
        newShift.strokeColor = superblockDefinition.stroke;
      }
    }

    if (tooltip) newShift.tooltip = tooltip;
    if (details && newShift.additionalData?.additionalData?.additionalDetails)
      newShift.additionalData.additionalData.additionalDetails = details.additionalDetails;

    return newShift;
  }

  /**
   * Splits combined Shifts
   * @param {Map<string, GanttShiftComponentsCombineChainShiftData>} [combinedShiftsList] List of the combined shifts. Uses all combined shifts if not given.
   * @param {boolean} [update] If false, gantt will not be updated after combine.
   */
  splitCombinedShifts(combinedShiftsList, update = false) {
    const s = this;
    if (!combinedShiftsList) {
      combinedShiftsList = this.combinedShiftsList;
    }
    const originDataSet = s.componentHandler.ganttDiagram.getDataHandler().getOriginDataset().ganttEntries;
    s._combineShiftComponents = false;
    s._removeCallbacks();

    const allShifts = ShiftDataFinder.getShiftsByIds(originDataSet, Object.keys(combinedShiftsList));
    allShifts.forEach((foundShift) => {
      const shiftGroup = combinedShiftsList[foundShift.shift.id];
      shiftGroup.shiftComponents.forEach((shift) => {
        s._transferStyleByRuleset(shiftGroup.newShift, shift);
        foundShift.shiftRow.shifts.push(shift);
      });
    });

    ShiftDataSorting.sortOriginShifts(originDataSet);

    // remove all shifts
    ShiftDataFinder.removeShiftsById(
      s.componentHandler.ganttDiagram.getDataHandler().getOriginDataset().ganttEntries,
      Object.keys(combinedShiftsList)
    );

    // empty save json
    for (const combinedShift in combinedShiftsList) {
      if (combinedShiftsList.hasOwnProperty(combinedShift)) {
        delete combinedShiftsList[combinedShift];
      }
    }
    s.active = false;
    if (update) s.componentHandler.ganttDiagram.update();
  }

  /**
   * After split removes combined shift.
   * @private
   */
  private _afterSplitRemoveCombinedShift() {
    const s = this;
    const selectedShifts = s.componentHandler.ganttDiagram.getSelectionBoxFacade().getSelectedShifts();
    const foundGroups = {};
    for (let i = 0; i < selectedShifts.length; i++) {
      const shift = selectedShifts[i];

      if (s.combinedShiftsList[shift.id]) {
        foundGroups[shift.id] = JSON.parse(JSON.stringify(s.combinedShiftsList[shift.id]));
        s.combinedShiftsList[shift.id] = undefined;
      }
    }

    for (const groupId in foundGroups) {
      const foundGroup = foundGroups[groupId];
      s.componentHandler.getShiftComponentDataHandler().RemoveFacade(
        foundGroup.shiftComponents.map(function (component) {
          return component.id;
        })
      );
    }
    s.splitCombinedShifts(foundGroups);
    s._combineShiftComponents = true;
    s._registerCallbacks();
    s.componentHandler.reRenderEdges();
  }

  /**
   * Callback function to handle (de-)activation of executer.
   * @private
   * @param {GanttShiftComponentActivation} activateParam
   */
  private _activateByExecuter(activateParam) {
    const s = this;
    if (!activateParam.activate) {
      s.splitCombinedShifts(null);
      s._combineShiftComponents = true;
      s._registerCallbacks();
    }
  }

  /**
   * Calbback function to handle component remove.
   * @private
   * @param {GanttShiftComponentsAfterDelete} activateParam
   */
  private _updateShiftCombine(activateParam) {
    const s = this;
    // find matching data inside combined view an remove it

    // TODO: update combined shift also after remove one component of it
    for (const combinedShiftId in s.combinedShiftsList) {
      const combinedInfo = s.combinedShiftsList[combinedShiftId];
      const deleteIndex = combinedInfo.shiftComponents.findIndex(function (component) {
        return component.id == activateParam.removedComponent;
      });
      if (deleteIndex > -1) {
        combinedInfo.shiftComponents.splice(deleteIndex, 1);
        if (combinedInfo.shiftComponents.length == 0) {
          ShiftDataFinder.removeShiftsById(
            s.componentHandler.ganttDiagram.getDataHandler().getOriginDataset().ganttEntries,
            [combinedShiftId]
          );
          delete s.combinedShiftsList[combinedShiftId];
        }
        break;
      }
    }
  }

  private removeShiftsByIds(dataSet: GanttDataRow[], shiftIdsToRemove: string[]): Map<string, IFoundShiftData> {
    const shiftIds = {};
    for (const id of shiftIdsToRemove) {
      shiftIds[id] = true;
    }
    const removedShifts = new Map<string, IFoundShiftData>();

    const getShifts = (rows: GanttDataRow[]): void => {
      if (!rows.length) return;
      for (const shiftRow of rows) {
        shiftRow.shifts = shiftRow.shifts.filter((shift) => {
          if (shiftIds[shift.id]) {
            removedShifts.set(shift.id, { shift, shiftRow });
            return false;
          }
          return true;
        });

        getShifts(shiftRow.child || []);
      }
    };

    getShifts(dataSet);

    return removedShifts;
  }

  /**
   * Transfers rules from newShift to shift by the ruleset.
   * @param {Object} newShift
   * @param {Object} shift
   * @private
   */
  private _transferStyleByRuleset(newShift, shift) {
    const s = this;
    if (s.rules.revertCombining.transferColor) shift.color = newShift.color;
    if (s.rules.revertCombining.transferHighlighted) shift.highlighted = newShift.highlighted;
  }

  /**
   * Toggles the shift combination.
   *
   * @param {boolean} activate activates / deactivates toggle shift combination
   */
  toggleShiftCombination(activate) {
    const s = this;
    if (!activate) activate = !this._combineShiftComponents;
    if (activate) s.combineChainedShifts();
    else s.splitCombinedShifts(null);
  }

  /**
   * @param {boolean} [onlyIfActive] If true, reCombine will only be executed if plugin is active.
   */
  reCombine(onlyIfActive) {
    const s = this;
    if (onlyIfActive && !s.active) return;
    s.splitCombinedShifts(false);
    s.combineChainedShifts();
  }

  //
  // CALLBACK
  //
  addAfterCombineShiftsCallback(id, func) {
    this.callBack.afterCombineShifts[id] = func;
  }

  removeAfterCombineShiftsCallback(id) {
    delete this.callBack.afterCombineShifts[id];
  }

  //
  // GETTER & SETTER
  //
  /**
   * Checks if it's activated or not.
   * @returns {boolean} true if its activated, else false
   */
  isActivated() {
    const s = this;
    return s._combineShiftComponents;
  }

  /**
   * Checks weather or not a shift is a shift by ID.
   * @param {String} shiftId
   * @returns {boolean} true if shiftID is a shift, else false.
   */
  isShiftById(shiftId) {
    const s = this;
    return !!s.combinedShiftsList[shiftId];
  }

  /**
   * Gets the group corresponding to the shfitComponent by ID
   * @param {String} shiftComponentId ID of the shiftComponent
   * @returns {null | group}
   */
  getGroupByComponentId(shiftComponentId) {
    const s = this;
    for (const groupId in s.combinedShiftsList) {
      const group = s.combinedShiftsList[groupId];
      for (let i = 0; i < group.shiftComponents; i++) {
        if (group.shiftComponents[i].id == shiftComponentId) return group;
      }
    }
    return null;
  }

  /**
   * Gets the new Shift corresponding to shiftComponent ID.
   *
   * @param {String} shiftComponentId ID of the shiftComponent
   * @returns {boolean | shift}
   */
  getNewShiftByShiftComponentId(shiftComponentId) {
    const s = this;
    for (const shiftId in s.combinedShiftsList) {
      if (!s.combinedShiftsList[shiftId] || !s.combinedShiftsList[shiftId].shiftComponents) continue;
      const foundShiftComponent = s.combinedShiftsList[shiftId].shiftComponents.find(function (shiftComponent) {
        return shiftComponent.id == shiftComponentId;
      });
      if (foundShiftComponent) return s.combinedShiftsList[shiftId].newShift;
    }
  }

  getShiftComponentById(shiftComponentId) {
    const s = this;
    for (const shiftId in s.combinedShiftsList) {
      if (!s.combinedShiftsList[shiftId] || !s.combinedShiftsList[shiftId].shiftComponents) continue;
      return s.combinedShiftsList[shiftId].shiftComponents.find(function (shiftComponent) {
        return shiftComponent.id == shiftComponentId;
      });
    }
  }

  /**
   * @param {String} shiftId Id of shift.
   * @returns {GanttDataShift} Dataset of combined shift.
   */
  getCombinedShiftDataById(shiftId) {
    const s = this;
    return s.combinedShiftsList[shiftId];
  }
}

/**
 * Data class to store shift data.
 * @keywords plugin, data, class, combined, shift
 * @plugin shift-components
 * @class
 * @constructor
 * @param {GanttDataShift} newShift Shift which will be calculated from (multiple) shiftComponents.
 * @param {ShiftData[]} shiftComponents Original shift data components.
 */
export class GanttShiftComponentsCombineChainShiftData {
  newShift: GanttDataShift;
  shiftComponents: any[];

  constructor(newShift, shiftComponents) {
    this.newShift = newShift;
    this.shiftComponents = shiftComponents;
  }
}

/**
 * Data class which contains rules which regulate data transfer of combined shift.
 * Example: If combined shifts color will change and combined shift will be split into its shifts,
 * regulate if all split shifts will get color of combined shift or not.
 * @plugin shift-components
 * @keywords plugin, data, class, combine, rules, restriction
 * @class
 * @constructor
 */
export class GanttShiftComponentsCombineChainRules {
  revertCombining: any;

  constructor() {
    this.revertCombining = {
      transferColor: false,
      transferHighlighted: false,
    };
  }
}
