import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GlobalUtils } from 'frontend/src/dashboard/global-utils';
import { Subject } from 'rxjs';
import { HorizonService } from '../horizonselecter/horizon.service';
import { ModelService } from '../model/model.service';
import { DatexPipe } from '../shared/pipes/datex.pipe';

@Component({
  selector: 'mdui-entry',
  templateUrl: 'mdui-entry.html',
  styleUrls: ['mdui.scss'],
  providers: [DatexPipe],
})
export class MDUIEntry implements OnInit {
  @Input() options: any;
  @Input() onEvent: Subject<any>;
  @Output() onResults: EventEmitter<any> = new EventEmitter();
  @Input() id: string = GlobalUtils.generateUUID(); // identifier

  public type = 'text';
  public value: any;
  public lowerType: string;
  public upperType: string;
  public label: string;
  public min: number;
  public max: number;
  public step: number;
  public unit: string;
  public dateFormat = 'yyyy-mm-ddTHH:mm:ss';

  constructor(
    public datexPipe: DatexPipe,
    public globalHorizonService: HorizonService,
    public globalModelService: ModelService
  ) {}

  public modelId: string;
  public experimentId: string;
  public horizonId: string;

  ngOnInit() {
    this.onEvent.subscribe((event) => {
      if (this.options.settingsValue) {
        this.options.settingsValue.value = this.value;
      }
      this.onResults.emit(this.options);
    });

    this.getType();
  }

  public validate(event) {}

  public getTyp(inp): string {
    let typ = inp.split('.');
    switch (typ[typ.length - 1]) {
      case 'String':
        typ = 'text';
        break;
      case 'Integer':
      case 'Double':
      case 'Float':
        typ = 'number';
        break;

      case 'Date':
        typ = 'datetime-local';
        break;
      default:
        typ = 'text';
        break;
    }
    return typ;
  }

  public validateSettingsEntryType(entryTypes: Array<string>): void {
    for (const type of entryTypes) {
      switch (type) {
        case 'CURRENT_EXPERIMENT':
          break;
        case 'CURRENT_MODEL':
          break;
        case 'CURRENT_HORIZON':
          break;
      }
    }
  }

  public getType() {
    if (this.options.settingsValue) {
      this.type = this.getTyp(this.options.settingsValue.clazz);
      this.value = this.options.settingsValue.value;

      if (this.type === 'datetime-local') {
        this.value = this.datexPipe.transform(this.options.settingsValue.value, this.dateFormat);
      }
      this.label = this.options.label;
    }

    if (this.options.upperBound) {
      this.max = this.options.upperBound.value;
    }
    if (this.options.lowerBound) {
      this.min = this.options.lowerBound.value;
    }
    if (this.options.stepWidth) {
      this.step = this.options.stepWidth.value;
    }
    if (this.options.unit) {
      this.unit = this.options.unit;
    }

    if (this.options.upperSettingsValue) {
      this.upperType = this.getTyp(this.options.upperSettingsValue.clazz);
    }

    if (this.options.lowerSettingsValue) {
      this.lowerType = this.getTyp(this.options.lowerSettingsValue.clazz);
    }
  }
}
