import { GanttLibService } from 'frontend/src/dashboard/gantt/gantt/gantt-lib.service';
import { SaxMsBestGanttPlugIn } from 'frontend/src/dashboard/gantt/gantt/plugin/saxms-best-gantt.plugin';
import { SaxMsBestGanttActiveSubmenuEntryElementSetting } from 'frontend/src/dashboard/gantt/gantt/saxms-best-gantt.settings';
import { GanttPluginHandlerService } from 'frontend/src/dashboard/gantt/general/plugin/gantt-plugin-handler.service';
import { Observable } from 'rxjs';
import { GanttTemplateData } from '../../helper/gantt';
import { GeneralGanttActionHandler } from '../action-handling/action-handler';
import { IGanttTemplateValue } from '../generator/gantt-input.data';
import { GanttInitGeneralPlugin, GanttOnActionGeneralPlugin } from './i-general-gantt-plugin-lc';

/**
 * Structure for all plugin-wrapper which will be registered inside SaxmsBestGantt.
 * Provides lifecycle.
 */
export abstract class ExternalGanttPlugin
  extends SaxMsBestGanttPlugIn
  implements GanttInitGeneralPlugin, GanttOnActionGeneralPlugin
{
  private id: string;

  constructor(
    protected _ganttPluginHandlerService: GanttPluginHandlerService,
    protected _ganttLibService: GanttLibService,
    protected actionHandler: GeneralGanttActionHandler
  ) {
    super(_ganttLibService);
  }

  //
  // LIFECYCLE
  //

  /**
   * Initialization of plugin.
   * Useful to load config data and register JS instances.
   * @param templateData Backend template data.
   * @param responseData Backend gantt data.
   */
  public abstract onInit(templateData: GanttTemplateData, responseData: IGanttTemplateValue);

  /**
   * @deprecated
   * Interface for menu actions.
   * @param action Local action.
   */
  public abstract onAction(action: any);

  /**
   * Function to handle local action input.
   * @param action Local action.
   */
  public abstract executeAction(action: any): Observable<any>;

  /**
   * Handle submenu element input (if necessary).
   * Brigde between plug ins and submenu.
   * @param submenuElements Submenu elements.
   */
  public abstract injectSettings(submenuElements: SaxMsBestGanttActiveSubmenuEntryElementSetting[]): void;

  /**
   * Cleanup just before Angular destroys the directive or component.
   * Unsubscribe Observables and detach event handlers to avoid memory leaks.
   */
  public abstract onDestroy(): void;
}
