export enum TextOverlayShadowMode {
  DROP_SHADOW = 'DROP_SHADOW',
  CONTOUR = 'CONTOUR',
  NONE = 'NONE',
}

export interface TextOverlayConfig {
  bgBorderTop: number;
  bgBorderBottom: number;
  bgBorderLeft: number;
  bgBorderRight: number;
  borderToNextShift: number;
  text_shadow: {
    dark_color: string;
    bright_color: string;
    blur: number;
    enable: boolean;
    offset: number;
    mode: TextOverlayShadowMode;
  };
}
