<ng-scrollbar [visibility]="'hover'" [track]="'vertical'">
  <div class="title">
    <div #vc class="template-elements"></div>
  </div>
  <!-- calendar -->

  <full-calendar [options]="calendarOptions" (dateClick)="dateClicked($event)" #calendar> </full-calendar>
  <!-- footer -->
  <div class="footer">
    <template-datepicker
      [disabled]="template.getRestriction() !== 'DAY'"
      class="date-range"
      [id]="'calendar-title-picker'"
      [mode]="'range'"
      [placeholder]="'DATE.CHOOSE.DATE' | translate"
      (changes)="onSelectedValuesChanged($event)"
      [type]="'DATE'"
      [mode]="'range'"
      [selectedValues]="selectedValues"
    >
    </template-datepicker>
  </div>
</ng-scrollbar>
