<div
  #entryElementRef
  [ngClass]="[
    'entry-element-wrapper',
    entryCollection.getDisplayOrientation() || '',
    entryCollection.getResizeMode() || '',
    entryElement.getWidth() ? 'own-width' : '',
    entryElement.hasNoPadding() ? 'no-padding' : ''
  ]"
  *ngIf="getVisibility(entryElement)"
  [class.selected]="entryElement.isSelected()"
  [class.is-on-the-left]="!entryElement.getPosition() || entryElement.getPosition() === 'left'"
  [class.is-on-the-right]="entryElement.getPosition() === 'right'"
  [style.width]="entryElement.getWidth() ? entryElement.getWidth() + 'px !important' : 'foo'"
>
  <entry-element
    (click)="onClick($event, entryElement)"
    [resourceId]="resourceId || entryElement.getResourceId()"
    [disabled]="isDisabled(entryElement)"
    (valueChange)="onChanges($event)"
    [highlightedKey]="highlightedKey"
    [el]="entryElement"
    [template]="entryElement"
    [layoutElement]="layoutElement"
  >
  </entry-element>
</div>
