/**
 * Generic class which stores all shift edit states.
 * @template IModelType Type of state model the state manager should use.
 */
export class GanttShiftEditState<IModelType extends object> {
  /**
   * Initial values saved for reset.
   */
  private readonly _initValues: IModelType;
  /**
   * Current values.
   */
  private _values: IModelType = undefined;

  /**
   * @param initValues Initial values of the state model.
   */
  constructor(initValues: IModelType) {
    this._initValues = initValues;
    this.reset();
  }

  /**
   * Updates the values assigned to the specified keys.
   * @param values Object containing all keys of values to be changed with their new values.
   */
  public set(values: Partial<IModelType>): void {
    if (!values) return;
    for (const key in values) {
      this._values[key] = values[key];
    }
  }

  /**
   * Returns the value for the specified key.
   * @param key Key to return the value for.
   * @returns Value assigned to the specified key.
   */
  public get<K extends keyof IModelType>(key: K): IModelType[K] {
    return this._values[key];
  }

  /**
   * Resets the whole storage to default values.
   */
  public reset(): void {
    this._values = structuredClone(this._initValues);
  }

  /**
   * Returns all keys of the state model.
   * @returns All keys of the state model as array of strings.
   */
  public keys(): string[] {
    return Object.keys(this._initValues);
  }
}
