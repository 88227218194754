import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { TemplateButtonModule } from '@app-modeleditor/components/button/button.module';
import { ImageModule } from '@app-modeleditor/components/image/image.module';
import { SaxmsTooltipModule } from '@app-modeleditor/components/tooltip/tooltip.module';
import { TranslateModule } from '@ngx-translate/core';
import { ToolbarSortPipe } from './template-toolbar-sort.pipe';
import { TemplateToolbarComponent } from './template-toolbar.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    MatBadgeModule,
    DragDropModule,
    MatMenuModule,
    TemplateButtonModule,
    MatProgressSpinnerModule,
    ImageModule,
    SaxmsTooltipModule,
  ],
  declarations: [ToolbarSortPipe, TemplateToolbarComponent],
  exports: [TemplateToolbarComponent],
  providers: [],
})
export class TemplateToolbarModule {}
