import { Observable, Observer } from 'rxjs';
import { OverlayRef } from '@angular/cdk/overlay';

export class PopUpOverlayRef {
  constructor(private overlayRef: OverlayRef) {}

  close() {
    this.overlayRef.dispose();
  }
}
