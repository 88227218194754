import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { ModelFooterComponent } from './model-footer.component';

@NgModule({
  imports: [CommonModule, TranslateModule, MatTooltipModule],
  exports: [ModelFooterComponent],
  declarations: [ModelFooterComponent],
})
export class ModelFooterModule {}
