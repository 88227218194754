import { GanttDataRow, GanttDataShift } from '../../data-handler/data-structure/data-structure';
import { ShiftDataSorting } from '../../data-handler/data-tools/data-sorting';
import { GanttUtilities } from '../../gantt-utilities/gantt-utilities';
import { GanttSplitOverlappingShifts } from './split-overlapping-shifts-executer';

/**
 * Helper class which contains all strategy-indepenent methods to handle parent shifts and their child shifts.
 */
export class GanttSplitOverlappingShiftsParentShiftHandler {
  private _scope: GanttSplitOverlappingShifts = null;
  private _parentShiftRowSpanMap = new Map<string, IParentShiftRowSpanData>();

  constructor(scope: GanttSplitOverlappingShifts) {
    this._scope = scope;
  }

  /**
   * Generates a map of child shifts and their linked parent shifts.
   * @param shiftsInput Shift data to generate the map for.
   * @returns
   */
  public getLinkedParentShifts(shiftsInput: GanttDataShift[]): Map<string, GanttDataShift> {
    const linkedParentShifts = new Map<string, GanttDataShift>();
    if (!shiftsInput?.length) return linkedParentShifts;
    for (const shift of shiftsInput) {
      if (shift.linkedParentBlock) {
        const linkedParentShift = shiftsInput.find((elem) => elem.id === shift.linkedParentBlock);
        if (linkedParentShift) linkedParentShifts.set(shift.id, linkedParentShift);
      }
    }
    return linkedParentShifts;
  }

  /**
   * Generates a map of parent shifts and their linked child shifts.
   * @param shiftsInput Shift data to generate the map for.
   * @returns
   */
  public getLinkedChildShifts(shiftsInput: GanttDataShift[]): Map<string, GanttDataShift[]> {
    const linkedChildShifts = new Map<string, GanttDataShift[]>();
    if (!shiftsInput?.length) return linkedChildShifts;
    for (const shift of shiftsInput) {
      if (shift.linkedParentBlock) {
        const linkedParentShift = shift.linkedParentBlock;
        if (!linkedChildShifts.get(linkedParentShift)) linkedChildShifts.set(linkedParentShift, []);
        linkedChildShifts.get(linkedParentShift).push(shift);
      }
    }
    return linkedChildShifts;
  }

  /**
   * Calculates the row spans of all given parent shifts.
   * @param shifts Shift data to generate the parent shift row spans for.
   * @returns Map containing all paremt shift row spans ordered by their ids.
   */
  public getParentShiftRowSpans(shifts: GanttDataShift[]): Map<string, IParentShiftRowSpanData> {
    const parentShiftRowSpanMap = new Map<string, IParentShiftRowSpanData>();
    if (!shifts || shifts.length <= 0) return parentShiftRowSpanMap;
    const linkedChildShifts = this.getLinkedChildShifts(shifts);
    for (const shift of shifts) {
      // check if shift is parent shift
      if (!linkedChildShifts.get(shift.id)) continue;
      // perform virtual shift splitting to determine parent shift row span
      const virtualSplittingMap = new Map<number, GanttDataShift[]>();
      let parentShiftRowSpan = 0;
      for (const childShift of linkedChildShifts.get(shift.id)) {
        let rowIndex = 0;
        let addedChild = false;
        while (!addedChild) {
          const overlappingShift = this._scope.overlapsInsideShiftList(childShift, virtualSplittingMap.get(rowIndex));
          if (overlappingShift) {
            rowIndex++;
            continue;
          }
          if (!virtualSplittingMap.get(rowIndex)) virtualSplittingMap.set(rowIndex, []);
          virtualSplittingMap.get(rowIndex).push(childShift);
          addedChild = true;
        }
        if (rowIndex + 1 > parentShiftRowSpan) parentShiftRowSpan = rowIndex + 1;
      }
      parentShiftRowSpanMap.set(shift.id, { rowSpan: parentShiftRowSpan, shiftDataRef: shift });
    }
    return parentShiftRowSpanMap;
  }

  /**
   * Returns an array of parent shifts which are not on the specified row but their row span overlaps with this row.
   * @param rowIndex Index of the row to search the shifts for.
   * @param shiftRowIndexMap Map of shift ids and the rows they are located in.
   * @param parentShiftRowSpanMap Map of parent shift ids and their row spans.
   * @param parentShiftMap Map of parent shift ids and references to their shift data.
   * @returns
   */
  public getVirtualParentShiftsForRow(
    rowIndex: number,
    shiftRowIndexMap: Map<string, number>,
    parentShiftRowSpanMap: Map<string, IParentShiftRowSpanData>
  ): GanttDataShift[] {
    const virtualParentShifts: GanttDataShift[] = [];
    for (const parentShiftId of parentShiftRowSpanMap.keys()) {
      const originIndex = shiftRowIndexMap.get(parentShiftId);
      if ((!originIndex && originIndex !== 0) || originIndex < 0) continue; // parent shift not added yet
      if (originIndex > rowIndex) continue; // parent shift row span begins below the specified index
      const rowSpanIndex = originIndex + parentShiftRowSpanMap.get(parentShiftId).rowSpan - 1;
      if (rowSpanIndex < rowIndex) continue; // parent shift row span end above the specified row
      virtualParentShifts.push(parentShiftRowSpanMap.get(parentShiftId).shiftDataRef);
    }
    return virtualParentShifts;
  }

  /**
   * Helper method which handles shift splitting of child shifts inside of parent shifts (independent from strategy).
   * @param shift Shift to insert.
   * @param rowIndex Index of the first row inside the row span of the parent shift.
   * @param allRowsRef Reference to all rows.
   * @param originRowRef Reference to the origin row.
   * @param shiftRowMapRef Refrence to the shift-row map (optional).
   * @param shiftRowIndexMapRef Reference to the shift-rowIndex map (optional).
   */
  public insertChildShiftIntoParentShift(
    shift: GanttDataShift,
    rowIndex: number,
    allRowsRef: GanttDataRow[],
    originRowRef: GanttDataRow,
    shiftRowIndexMapRef: Map<string, number> = null
  ): void {
    let rowInsertIndex = rowIndex;
    let rowFound = false;
    while (!rowFound) {
      if (rowInsertIndex >= allRowsRef.length) {
        const rowIdSuffix = `${this._scope.UUID}_PARENT-${shift.linkedParentBlock}-${shift.id}`;
        const insertRow = GanttUtilities.createNewRowFromRow(originRowRef, rowIdSuffix, 'MEMBER');
        allRowsRef.push(insertRow);
        insertRow.shifts.push(shift);
        shiftRowIndexMapRef?.set(shift.id, allRowsRef.length - 1);
        rowFound = true;
      }
      const overlappingShift = this._scope.overlapsInsideShiftList(
        shift,
        allRowsRef[rowInsertIndex].shifts.filter((elem) => elem.id !== shift.linkedParentBlock)
      );
      if (overlappingShift) {
        rowInsertIndex++;
        continue;
      }
      const insertRow = allRowsRef[rowInsertIndex];
      insertRow.shifts.push(shift);
      ShiftDataSorting.sortJSONListByDate(insertRow.shifts, 'timePointStart');
      shiftRowIndexMapRef?.set(shift.id, rowInsertIndex);
      rowFound = true;
    }
  }

  /**
   * Similar to {@link insertChildShiftIntoParentShift()} but works with shift arrays instead of row arrays.
   * @param shift Shift to insert.
   * @param rowIndex Index of the first row inside the row span of the parent shift.
   * @param allRowsRef Reference to all rows.
   * @param shiftRowIndexMapRef Reference to the shift-rowIndex map (optional).
   */
  public insertChildShiftIntoParentShiftInShiftArray(
    shift: GanttDataShift,
    rowIndex: number,
    allRowsRef: GanttDataShift[][],
    shiftRowIndexMapRef: Map<string, number> = null
  ): void {
    let rowInsertIndex = rowIndex;
    let rowFound = false;
    while (!rowFound) {
      if (rowInsertIndex >= allRowsRef.length) {
        const insertRow: GanttDataShift[] = [];
        allRowsRef.push(insertRow);
        insertRow.push(shift);
        shiftRowIndexMapRef?.set(shift.id, allRowsRef.length - 1);
        rowFound = true;
      }
      const overlappingShift = this._scope.overlapsInsideShiftList(
        shift,
        allRowsRef[rowInsertIndex].filter((elem) => elem.id !== shift.linkedParentBlock)
      );
      if (overlappingShift) {
        rowInsertIndex++;
        continue;
      }
      const insertRow = allRowsRef[rowInsertIndex];
      insertRow.push(shift);
      ShiftDataSorting.sortJSONListByDate(insertRow, 'timePointStart');
      shiftRowIndexMapRef?.set(shift.id, rowInsertIndex);
      rowFound = true;
    }
  }

  /**
   * Handles the manipulation of the shift render dataset so parent shifts with a row span >= 2 reach over multiple lines.
   */
  public visualizeParentShiftRowSpans(): void {
    const canvasShiftData = this._scope.ganttDiagram.getDataHandler().getCanvasShiftDataset();
    // generate list of visible row ids
    const yAxisDataSet = this._scope.ganttDiagram.getRenderDataSetYAxis();
    const visibleRowList: string[] = [];
    for (const scrollContainerId of this._scope.ganttDiagram.getRenderDataHandler().getActiveScrollContainerIds()) {
      visibleRowList.push(...(yAxisDataSet[scrollContainerId] || []).map((canvasRow) => canvasRow.id));
    }
    // manipulate shift heights
    for (const canvasShift of canvasShiftData) {
      const rowSpanData = this._parentShiftRowSpanMap.get(canvasShift.id);
      // if shift is parent shift with row span >= 2 rows -> manipulate shift height
      if (rowSpanData && rowSpanData.rowSpan >= 2) {
        let manipulatedShiftHeight = 0;
        if (canvasShift.hasOwnProperty('originHeight')) {
          manipulatedShiftHeight = canvasShift.originHeight;
        } else {
          manipulatedShiftHeight = canvasShift.height;
          canvasShift.originHeight = canvasShift.height;
        }

        const originRowIndex = visibleRowList.findIndex((rowId) => rowId === rowSpanData.rowId);
        for (let i = 2; i <= rowSpanData.rowSpan; i++) {
          const rowIndex = originRowIndex + i - 1;
          manipulatedShiftHeight += this._scope.ganttDiagram
            .getDataHandler()
            .getRowHeightStorage()
            .getRowHeightById(visibleRowList[rowIndex]);
        }
        canvasShift.height = manipulatedShiftHeight;
      }
      // else -> check if shift has manipulated height and reset it
      else {
        if (canvasShift.hasOwnProperty('originHeight')) {
          canvasShift.height = canvasShift.originHeight;
          delete canvasShift.originHeight;
        }
      }
    }
  }

  /**
   * Handles the manipulation of parent shift heights after each shift render dataset update.
   */
  public subscribeToShiftHeightManipulation(): void {
    this._scope.ganttDiagram
      .getDataHandler()
      .subscribeSetShiftDataSet(
        `${this._scope.UUID}_parentShiftHeightManipulation`,
        this.visualizeParentShiftRowSpans.bind(this)
      );
  }

  /**
   * Stops the manipulation of parent shift heights after each shift render dataset update.
   */
  public unsubscribeFromShiftHeightManipulation(): void {
    this._scope.ganttDiagram
      .getDataHandler()
      .unSubscribeSetShiftDataSet(`${this._scope.UUID}_parentShiftHeightManipulation`);
  }

  /**
   * Adds parent shift row span visualization data.
   * @param parentShiftRowSpanMap Parent shift visualization data to add as map of parent shift id and the respective data.
   */
  public addParentShiftRowSpanData(parentShiftRowSpanMap: Map<string, IParentShiftRowSpanData>): void {
    for (const entry of parentShiftRowSpanMap.entries()) {
      this._parentShiftRowSpanMap.set(entry[0], entry[1]);
    }
  }

  /**
   * Clears all parent shift row span visualization data.
   */
  public clearParentShiftRowSpanData(): void {
    this._parentShiftRowSpanMap.clear();
  }
}

/**
 * Container structure for parent shift row span data.
 */
export interface IParentShiftRowSpanData {
  rowSpan: number;
  rowId?: string;
  shiftDataRef: GanttDataShift;
}
