import { EGanttScrollContainer } from '../../html-structure/scroll-container.enum';
import { RenderDataCanvasTransform } from './render-data-canvas-transform';

/**
 * Helper class for {@link RenderDataCanvasTransform} to map its values to a specific scroll container in real time.
 */
export class RenderDataCanvasTransformConnector {
  /**
   * @param _canvasTransform Reference to the {@link RenderDataCanvasTransform} instance to map the values for.
   * @param _scrollContainerId Id of the scroll container to map the values for.
   */
  constructor(
    private readonly _canvasTransform: RenderDataCanvasTransform,
    private readonly _scrollContainerId: EGanttScrollContainer
  ) {}

  /**
   * Canvas top value.
   */
  public get top(): number {
    return this._canvasTransform.getTop(this._scrollContainerId);
  }

  /**
   * Canvas top value.
   */
  public set top(val: number) {
    this._canvasTransform.setTop(this._scrollContainerId, val);
  }

  /**
   * Canvas left value.
   */
  public get left(): number {
    return this._canvasTransform.getLeft();
  }

  /**
   * Canvas left value.
   */
  public set left(val: number) {
    this._canvasTransform.setLeft(val);
  }

  /**
   * Canvas x scale.
   */
  public get scaleX(): number {
    return this._canvasTransform.getScaleX();
  }

  /**
   * Canvas x scale.
   */
  public set scaleX(val: number) {
    this._canvasTransform.setScaleX(val);
  }

  /**
   * Canvas y scale.
   */
  public get scaleY(): number {
    return this._canvasTransform.getScaleY();
  }
}
