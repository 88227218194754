import { CdkDragEnd, CdkDragMove } from '@angular/cdk/drag-drop';
import { Component, ComponentRef, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatLegacyButton as MatButton } from '@angular/material/legacy-button';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { EDisplayOrientation } from '@app-modeleditor/components/content/content-element/display-orientation.enum';
import { SortOrder } from '@app-modeleditor/components/elements/sort-menu/sort-order.enum';
import { EntryCollection } from '@app-modeleditor/components/entry-collection/entry-collection';
import { EntryElement } from '@app-modeleditor/components/entry-collection/entry-element';
import { EntryElementValue } from '@app-modeleditor/components/entry-collection/entry-element-value';
import { EntryElementFactory } from '@app-modeleditor/components/entry-collection/entry-factory.service';
import { EFieldType } from '@app-modeleditor/components/entry-collection/field-type.enum';
import { TemplateComponent } from '@app-modeleditor/components/template/template.component';
import { TemplateAdapter } from '@app-modeleditor/utils/template-factory.service';
import { Subject, of } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';
import { FreetextFilter } from './freetext-filter.enum';
import { FreetextFilterService } from './freetext-filter.service';

@Component({
  selector: 'freetext-filter',
  templateUrl: './freetext-filter.component.html',
  styleUrls: ['./freetext-filter.component.scss'],
})
export class FreetextFilterComponent implements OnInit, OnDestroy {
  private $ngNext: Subject<void> = new Subject<void>();
  $changeTimeoutSub: Subject<void> = new Subject<void>(); // timeout for keyevents to be noticed
  @Input() showExtendedFilter = true;
  @ViewChild('filterfield') filterfield: ElementRef;
  @ViewChild('filterTrigger') filterTrigger: MatButton;
  @ViewChild('extendedFilter') extendedFilter: ElementRef;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  private $ngDestroy: Subject<void> = new Subject<void>();
  isOpen = false;
  private panel: HTMLDivElement;
  private panelWidth: number;

  // dataset that handles the component
  data: any = {
    placeholder: '',
    value: '',
    id: null,
    filters: [],
    type: null,
  };

  constructor(
    private freetextFilterService: FreetextFilterService,
    private _templateAdapter: TemplateAdapter,
    private _entryElementAdapter: EntryElementFactory
  ) {}

  onSelectionChanges(): void {}

  /**
   * clear all inputs
   */
  clearAll(): void {
    const d = JSON.parse(JSON.stringify(this.data));

    for (const filter of d.filters) {
      if (filter.value && filter.value.value) {
        filter.value.value = null;
      } else if (filter.value && !(Object.keys(filter.value) && Object.keys(filter.value).length === 0)) {
        filter.value = null;
      }

      delete filter.value;

      if (filter?.displayFilterElement?.fieldType === EFieldType.RANGE_PICKER) {
        delete filter.displayFilterElement.value; // = { from: null, to: null };
      }

      filter.filterOperator = filter.filterOperator ? 'NONE' : null;
      filter.sortOrder = SortOrder.none;
    }

    this.data = d;
    this.complexFilter(true);
  }

  /**
   * unsubscribe from observers
   */
  ngOnDestroy(): void {
    this.$ngDestroy.next();
    this.$ngDestroy.complete();
    this.$ngNext.next();
    this.$ngNext.complete();
    this.$changeTimeoutSub.next();
    this.$changeTimeoutSub.complete();
  }

  onClose(event): void {}

  /**
   * initializes the dataset when a filter is applied
   */
  ngOnInit(): void {
    this.freetextFilterService
      .getFilterNode()
      .pipe(takeUntil(this.$ngDestroy))
      .subscribe((filterObject: any) => {
        this._map(filterObject);
        of(null)
          .pipe(delay(0))
          .subscribe(() => {
            if (!filterObject) {
              return;
            }
            this.data.placeholder = filterObject.name;

            if (this.data.id !== filterObject.uuid) {
              this.changed = false;
            }
            this.data.id = filterObject.uuid;

            if (!this.changed || !filterObject.filterString) {
              this.data.value = filterObject.filterString ? filterObject.filterString : '';
              this.changed = true;
            }

            if (filterObject.filters) {
              this.data.filters = filterObject.filters;
            }
          });
      });
  }

  changed = false;

  c: EntryCollection;
  _map(filterObject): any {
    this.$ngNext.next();
    const eCollection: EntryCollection = new EntryCollection().setDisplayOrientation(EDisplayOrientation.VERTICAL);
    this.c = eCollection.setEntryElements(
      filterObject.filters.map((f) => {
        const e: EntryElement = f.displayFilterElement
          ? this._templateAdapter.adapt(f.displayFilterElement)
          : new EntryElement();
        e.setFieldType(e.getFieldType() || EFieldType.TEXT_FIELD)
          .setName(f.name)
          .setEnableSort(f.enableSort)
          .setSortOrder(f.sortOrder)
          .setId(f.id)
          .setValue(new EntryElementValue().setValue(this.getValue(f)));

        if (f.availableValues) {
          e.getValue<EntryElementValue>().setAvailableValues(f.availableValues);
        }

        e.onChanges((val: EntryElementValue) => {
          f.value = val.getValue();
        });

        return e;
      })
    );
  }

  getValue(f): EntryElementValue {
    if (Array.isArray(f.value)) {
      return f.value.map((val) => this._entryElementAdapter.parseEntryValue(EntryElementValue, val));
    }
    return f.value;
  }

  afterSlotInit(instance: ComponentRef<TemplateComponent>): void {}

  /**
   * check if the menu overlay open before the trigger
   * @returns
   */
  isMenuPositionLeft(): boolean {
    if (!this.panel) {
      return false;
    }
    return !this.panel.classList.contains('mat-menu-after');
  }

  /**
   * on opening menu
   * @returns void
   */
  onOpen(): void {
    this.isOpen = !this.isOpen;
    this.panel = (this.trigger as any)._overlayRef.hostElement.getElementsByClassName(
      'filter-overlay-panel'
    )[0] as HTMLDivElement;
    this.panelWidth = this.panel.offsetWidth;
  }

  onPickerChange(event, filter): void {
    if (event && event.dateString) {
      const start = new Date(
        event.selectedDates[0] < event.selectedDates[1] ? event.selectedDates[0] : event.selectedDates[1]
      );
      const end = new Date(
        event.selectedDates[0] > event.selectedDates[1] ? event.selectedDates[0] : event.selectedDates[1]
      );
      end.setDate(end.getDate() + 1);

      filter.value = {
        start: start.getTime(),
        end: end.getTime(),
      };
    } else {
      filter.value = null;
    }
  }

  /**
   * handles prevent click
   * @param event event
   */
  onPreventClick(event): void {
    event.stopPropagation();
  }

  /**
   * simple freetext filter
   */
  freetextFilter(): void {
    this.$changeTimeoutSub.next();

    of(null)
      .pipe(delay(500), takeUntil(this.$changeTimeoutSub))
      .subscribe(() => {
        this.data.type = FreetextFilter.FREETEXT_FILTER;
        // discuss with rico
        for (const f of this.data.filters) {
          if (f.value) {
            delete f.value;
          }
        }
        this.freetextFilterService.changeFilter(this.data);
      });
  }

  /**
   * complex filter for many arguments
   */
  complexFilter(reset?: boolean): void {
    this.data.type = reset ? null : FreetextFilter.COMPLEX_FILTER;
    let _reset = true;

    for (const f of this.data.filters) {
      //
      const e = this.c.getEntryElements().find((item) => item.getId() === f.id);
      if (e?.isEnableSort() && !reset) {
        f.sortOrder = e.getSortOrder();
      }

      if (f.value && f.value.value) {
        f.value = f.value.value;
      }

      if (f.displayFilterElement?.fieldType === EFieldType.RANGE_PICKER) {
        delete f.displayFilterElement.value;
      }

      if (f.displayFilterElement && f.displayFilterElement.fieldType === 'CHECK_BOX') {
        f.value = f.value === false ? undefined : f.value;
      }

      if (f.value || f.enableSort) {
        _reset = typeof reset === 'boolean' ? reset : false;
      }
    }

    this.data.type = _reset === true ? _reset : this.data.type;

    this.data.value = '';

    this.trigger.closeMenu();
    this.freetextFilterService.changeFilter(this.data);
  }

  onMove(event: CdkDragMove): void {
    this.handleDrag(event.distance);
    event.source.reset();
    event.event.preventDefault();
  }

  afterDrag(event: CdkDragEnd): void {
    this.handleDrag(event.distance);
    event.source.reset();
  }

  handleDrag(distance) {
    if (distance) {
      const delta = this.isMenuPositionLeft() ? -distance.x : distance.x;
      this.panel.style.width = `${this.panelWidth + delta}px`;
    }
  }
}
