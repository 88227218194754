import { Component, EventEmitter, Input, Output } from '@angular/core';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'app-template-overlay',
  templateUrl: './template-overlay.html',
  styleUrls: ['./template-overlay.scss'],
})
export class TemplateOverlay {
  @Input() data: any;
  @Output() close: EventEmitter<any> = new EventEmitter<any>();

  public slideOut: boolean;

  constructor() {
    this.slideOut = false;
  }

  public closeWrapper(event: any) {
    this.slideOut = true;
    // wait until animation has finished
    of(null)
      .pipe(delay(900))
      .subscribe(() => {
        this.close.emit(event);
        this.slideOut = false;
      });
  }
}
