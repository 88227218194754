<div
  #resource
  class="element-wrapper"
  [style.backgroundColor]="elementData.color"
  (click)="onClick($event)"
  [class.disabled]="disabled"
  (contextmenu)="onRightClick($event)"
  dragResource
  resourceTooltip
>
  <div class="selectionArea" [class.selection]="selected" [matMenuTriggerFor]="rightMenu"></div>
  <div class="text">{{ elementData.name }}</div>
  <div class="attributeListContainer" *ngIf="visibleAttributes.length">
    <ul class="attributeList">
      <li *ngFor="let attribute of visibleAttributes">
        {{ getAttributeValueByKey(attribute) }}
      </li>
    </ul>
  </div>
</div>

<mat-menu #rightMenu="matMenu">
  <button (click)="setFavorite(false)" *ngIf="isFavorite()" mat-menu-item>
    <mat-icon>star_border</mat-icon>
    <span>Favorit entfernen</span>
  </button>
  <button (click)="setFavorite(true)" *ngIf="!isFavorite()" mat-menu-item>
    <mat-icon>grade</mat-icon>
    <span>Als Favorit setzen</span>
  </button>
</mat-menu>
