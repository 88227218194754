import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { MDUI } from './mdui';

@Component({
  selector: 'mdui-headline',
  templateUrl: 'mdui-headline.html',
  styleUrls: ['mdui.scss'],
})
export class MDUIHeadline implements OnInit, OnDestroy {
  @Input() options: any;
  public visible = true;
  public entries: any[];
  public subCategories: any[];
  @Input() onEvent: Subject<any>;
  localSubject: Subject<void> = new Subject();
  @Output() onResults = new EventEmitter();
  @Input() id: string; // identifier

  public mdui: MDUI;

  constructor() {
    this.entries = [];
  }

  public handleResults(event): void {
    this.mdui.fillOptions(event);
    this.mdui.removeFromQueue(event.id);

    if (this.mdui.getQueue().length == 0) {
      this.onResults.emit(this.mdui.getOptions());
    }
  }

  onOpen(event: any) {}

  ngOnDestroy(): void {
    this.localSubject.next();
    this.localSubject.complete();
  }

  ngOnInit() {
    this.onEvent.subscribe((event) => {
      this.mdui = new MDUI(this.options);
      this.mdui.fillQueue();
      this.localSubject.next();
    });
  }
}
