import {
  HttpHandler,
  HttpHeaderResponse,
  HttpInterceptor,
  HttpProgressEvent,
  HttpRequest,
  HttpResponse,
  HttpSentEvent,
  HttpUserEvent,
} from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/operators';
import DialogComponent from '../shared/ui/dialog/dialog.component';
import { ConfigService } from './config/config.service';

const WHITE_LIST = ['./assets'];
const REQUEST_COUNT = 10;
const REQUEST_TIMEFRAME = 2000;

interface RequestItem {
  count: number;
  time: string;
}

@Injectable({
  providedIn: 'root',
})
export class HttpNetworkInterceptor implements HttpInterceptor {
  private dialogRef: MatDialogRef<DialogComponent> | null = null;
  private requestMap = new Map<string, RequestItem>();

  constructor(private config: ConfigService, private zone: NgZone, private dialog: MatDialog) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
    return this.updateMap(req).pipe(
      catchError((e) => {
        if (!this.dialogRef) {
          this.zone.run(() => {
            this.dialogRef = this.dialog.open(DialogComponent, {
              data: req,
            });
            this.dialogRef
              .afterClosed()
              .pipe(take(1))
              .subscribe(() => (this.dialogRef = null));
          });
        }
        return throwError(() => e);
      }),
      switchMap(() => next.handle(req))
    );
  }

  private updateMap(req: HttpRequest<any>): Observable<void> {
    if (!this.config.access() || !this.config.access()?.security?.limitRequests) {
      return of(void 0);
    }

    const whitelist = this.config.access().security.limitRequests?.whitelist ?? WHITE_LIST;
    const amount = this.config.access().security.limitRequests?.amount ?? REQUEST_COUNT;
    const time = this.config.access().security.limitRequests?.time ?? REQUEST_TIMEFRAME;

    const isWhitelisted = whitelist.find((item) => req.url.includes(item));
    if (!(req instanceof HttpRequest) || isWhitelisted) {
      return of(void 0);
    }

    const now = new Date();
    const url = req.urlWithParams;
    const item = this.requestMap.get(url) ?? { count: 0, time: now.toISOString() };

    const diff = now.getTime() - new Date(item.time).getTime();

    if (diff <= time) {
      // new request within the set time interval
      item.count += 1;
    } else {
      // reset counter and time
      item.count = 1;
      item.time = now.toISOString();
    }

    if (item.count > amount && diff <= time) {
      console.groupCollapsed('Too many requests to the same endpoint');
      console.trace('');
      console.groupEnd();
      return throwError(() => new Error('too many requests!'));
    }

    this.requestMap.set(url, item);

    return of(void 0);
  }
}
