import { Component } from '@angular/core';
import { ButtonComponent } from './../button/button.component';
import { Tile } from './tile';

@Component({
  selector: 'app-tile-button',
  templateUrl: './tile-button.component.html',
  styleUrls: ['./../button/button.component.scss', './tile-button.component.scss'],
  providers: [],
})
export class TileButtonComponent extends ButtonComponent {
  btn: Tile;

  afterClick(event: MouseEvent): void {
    // double click
    super.switchClicks(event);
  }

  more(event: MouseEvent): void {
    if (this.btn.getContextmenu() && !this.DISABLED) {
      this.contextMenuApi.create(event, this.btn.getContextmenu());
    }
    event.stopPropagation();
  }

  get tooltip(): string {
    if (!this.btn || !this.btn.getHoverContent()) {
      return '';
    }

    return (
      Object.keys(this.btn.getHoverContent())
        .map((key: string) => `${key}: ${this.btn.getHoverContent()[key]}`)
        .join('<br>') || ''
    );
  }
}
