import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TemplateTreeService } from '@app-modeleditor/components/tree/tree.service';
import { EUserSettingsKey } from 'frontend/src/dashboard/user/user-seetings-key.enum';
import { IUserSettings, UserService } from 'frontend/src/dashboard/user/data-access/user.service';
import { ViewService } from 'frontend/src/dashboard/view/view.service';
import { BehaviorSubject, concat, Observable, of, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { Select } from '@ngxs/store';
import { AuthState } from '../../login/data-access/auth.state';
import { User } from '../../user/data-access/user';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private page: string;
  private initialized: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _settings: IUserSettings;
  private _generalSettings: any;
  @Select(AuthState.user) user$!: Observable<User>;
  private user: User;

  constructor(
    private viewApi: ViewService,
    private treeApi: TemplateTreeService,
    private _userSettingsApi: UserService
  ) {
    this._userSettingsApi.getUserSettings().subscribe((settings: IUserSettings) => {
      this._settings = settings;
      if (!settings) {
        return;
      }
      this._userSettingsApi.getKey(this._settings, EUserSettingsKey.GENERAL).subscribe((sett) => {
        this._generalSettings = sett;
        if (this.page === sett?.page) {
          return;
        }
        this.page = sett?.page;

        this.init().subscribe();
      });
    });

    this.viewApi.getPageContent().subscribe((content) => {
      this.setPage(content ? this.treeApi.transformUrl(content.page || content, content.url) : null);
    });
  }

  private storeSettings(generalSettings: any): Observable<any> {
    if (!this._settings) {
      return of(null);
    }
    return this._userSettingsApi.setKey(this._settings, EUserSettingsKey.GENERAL, generalSettings, true);
  }

  private init() {
    if (!this._settings || !this.user) {
      return of(this.treeApi.navigateTo(null));
    }

    const page: string = this.page;

    return concat(this.viewApi.getPageByTemplate(page, true))
      .pipe(
        catchError((e) => {
          this.setInitialized(true);
          if (e instanceof HttpErrorResponse) {
            if (e.error.codeException === 40002) {
              this.setPage(null);
              return of(null);
            }
          }
          return throwError(e);
        })
      )
      .pipe(
        finalize(() => {
          this.setInitialized(true);
        })
      );
  }

  setInitialized(state: boolean): void {
    this.initialized.next(state);
  }

  /**
   * whether the navigation is initially done
   * @returns Observable<boolean>
   */
  onInitialization(): Observable<boolean> {
    return this.initialized.asObservable();
  }

  /**
   * initalize service and waits for authentication to be trtiggered
   * @returns void
   */
  public initialize(): void {
    this.user$.subscribe((user) => {
      this.user = user;
      this.init().subscribe();
    });
  }

  /**
   * set last visited page
   * @param page string
   * @returns void
   */
  public setPage(page: string): void {
    this.page = page;
    if (this.initialized.getValue() === true) {
      if (!this._generalSettings) {
        this._generalSettings = {};
      }
      this._generalSettings.page = this.page;
      this.storeSettings(this._generalSettings).subscribe();
    }
  }

  public getPage(): string {
    return this.page;
  }
}
