import { Component, Inject, OnInit } from '@angular/core';
import { EntryElementValue } from '@app-modeleditor/components/entry-collection/entry-element-value';
import { CONTAINER_DATA } from '@app-modeleditor/components/lightbox/overlay/container-data';
import { DefaultOverlayContainer } from '@app-modeleditor/components/lightbox/overlay/default-overlay-container';
import { Colorpicker } from '../colorpicker';

@Component({
  selector: 'app-colorpicker-popup-container',
  templateUrl: './colorpicker-popup-container.component.html',
  styleUrls: ['./colorpicker-popup-container.component.scss'],
})
export class ColorpickerPopupContainerComponent extends DefaultOverlayContainer<Colorpicker> implements OnInit {
  data: Colorpicker;

  constructor(@Inject(CONTAINER_DATA) public containerData: Colorpicker) {
    super();
    if (containerData) {
      this.data = containerData;
    }
  }

  reset(): void {
    this.data.getValue<EntryElementValue>().setValue(this.data?.getResetColor() || null);
    this.close();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  protected afterChanged(): void {
    // close only if preset colors palette is available
    if (this.containerData?.getPossibileValues()?.length > 0) {
      this.close();
    }
  }

  protected close(): void {
    this._close('backdropClick', this.data);
  }
}
