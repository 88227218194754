import { EGanttInstance } from '@gantt/public-api';
import { GanttActionWrapper } from 'frontend/src/dashboard/gantt/general/gantt-actions/external-action-registration';
import { GanttEventTrigger } from 'frontend/src/dashboard/gantt/general/gantt-actions/external-event-trigger';
import { EGanttActionEvent } from 'frontend/src/dashboard/gantt/general/gantt-actions/gantt-action-handling/gantt-action-event.enum';
import { takeWhile } from 'rxjs/operators';
import { GanttBlockingIntervalsMode } from '../../blocking-intervals.mode';

/**
 * Action handling of blocking interval drag end event.
 * Drag end means the timepoint if user "drops" the mouse after creating a blocking interval.
 */
export class BlockingIntervalDragEndEvent extends GanttEventTrigger {
  public readonly eventName: EGanttActionEvent = EGanttActionEvent.DRAG_END;

  /**
   * Adds dragEndBeforeMarkerAdding-callback to gantt to save template data and execute actions.
   * the callback will be registered by the (backend) given action id.
   * @param ganttDiagram BestGantt where callbacks will be registered inside.
   */
  public registerInGantt(): void {
    this.executer.dragEndBeforeMarkerAdding(this.action.id, (dragEndParam) => {
      const foundRowData: any = this._ganttLibService.ganttInstanceService
        .getInstance(EGanttInstance.Y_AXIS_DATA_FINDER)
        .getRowById(this._ganttLibService.bestGantt.getDataHandler().getOriginDataset().ganttEntries, dragEndParam.yId);
      if (!foundRowData.data) return;
      if (this.isInCorrectPlugInMode(this.action.activatedByAction)) {
        this.executer.deactivate();
        this.saveDataToTemplateDragEndNewlyCreated(dragEndParam, foundRowData.data, this._ganttLibService.bestGantt);
        const actionWrapper: GanttActionWrapper = {
          actionScope: this.action.actionScope,
          actions: this.action.actions,
        };

        this._ganttLibService.ngZone.run(() => {
          this.actionHandler
            .executeActions(actionWrapper)
            .pipe(takeWhile(() => this.actionHandler.isAlive()))
            .subscribe((data) => {
              this.responseHandler.handleUpdateNotification(data as any);
            });
        });
      }
    });
  }

  /**
   * Removes dragEndBeforeMarkerAdding callback from gantt.
   * @param ganttDiagram BestGantt where callbacks will be removed from.
   */
  public removeFromGantt(): void {
    super.removeFromGantt();
    this.executer.removeDragEndBeforeMarkerAdding(this.action.id);
  }

  /**
   * Bundles functionality to store event data to template.
   * Necessary for global action handling.
   * @param timePeriodData timePeriodData.
   * @param ganttRowData Data of row in which the blocking interval has been created.
   * @param ganttDiagram BestGantt.
   */
  public saveDataToTemplateDragEndNewlyCreated(timePeriodData: any, ganttRowData: any, ganttDiagram: any): void {
    // set temaplate data
    /* ?
     if (!this.templateData.selectedRow) this.templateData.selectedRow = {};
    if (!this.templateData.selectedblock) this.templateData.selectedblock = {}; */

    const foundRowDataWrapper = this._ganttLibService.ganttInstanceService
      .getInstance(EGanttInstance.Y_AXIS_DATA_FINDER)
      .getRowById(ganttDiagram.getDataHandler().getOriginDataset().ganttEntries, timePeriodData.yId);
    if (ganttRowData.additionalData) {
      // ? this.templateData.selectedRow.canonicalName = ganttRowData.additionalData.canonicalName;
      this.templateData.setSelectedRow({
        id: foundRowDataWrapper.data.additionalData.originalRowId,
        canonicalName: ganttRowData.additionalData.canonicalName,
      });
    } else {
      this.templateData.setSelectedRow({
        id: foundRowDataWrapper.data.additionalData.originalRowId,
      });
    }

    this.templateData.setSelectedblock({
      // TODO: must be removed as soon as the backend uses the selectedTimePeriodInput attribute (14.06.21)
      start: timePeriodData.dateStart.getTime(),
      end: timePeriodData.dateEnd.getTime(),
    });

    this.templateData.setSelectedTimePeriodInput({
      id: null,
      type: timePeriodData.type,
      groupId: timePeriodData.groupId,
      start: timePeriodData.dateStart.getTime(),
      end: timePeriodData.dateEnd.getTime(),
    });

    // this.templateData.selectedblock.start = callback.dateStart.getTime();
    // Fthis.templateData.selectedblock.end = callback.dateEnd.getTime();
  }

  /**
   * Checks if action will be executed by plugin mode.
   * @param activatedByAction Action to execute.
   */
  private isInCorrectPlugInMode(activatedByAction: any): boolean {
    if (!activatedByAction) return true;
    switch (activatedByAction.id) {
      case GanttBlockingIntervalsMode.CREATE:
        return this.executer.isActive();
      case GanttBlockingIntervalsMode.EDIT:
        return this.executer.isInEditMode();
    }
  }
}
