import {
  Component,
  ElementRef,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { Template } from 'frontend/src/dashboard/model/resource/template';
import { EResizeMode } from 'frontend/src/dashboard/model/resource/template-resize-mode.enum';
import * as JSZip from 'jszip';
import { forkJoin, from, Observable, Observer, of, Subject } from 'rxjs';
import { finalize, map, switchMap, takeUntil } from 'rxjs/operators';
import { Action } from '../button/action/action';
import { Button } from '../button/button';
import { EButtonDisplayType } from '../button/button-display-type.enum';
import { EDisplayOrientation } from '../content/content-element/display-orientation.enum';
import { ContextMenu } from '../contextmenu/contextmenu';
import { EPredefinedContextMenuItem } from '../contextmenu/predefined-item.enum';
import { EntryCollection } from '../entry-collection/entry-collection';
import { EEntryElementPosition } from '../entry-collection/entry-element-position.enum';
import { Lightbox } from '../lightbox/lightbox';
import { ContextMenuItem } from './../contextmenu/context-menu-item';
import { LightboxService } from './../lightbox/lightbox.service';
import { FileProgressService } from './file-progress.service';
import { EFileType } from './file-state.enum';
import { EFileUploadMode } from './file-upload-mode.enum';
import { FileUploaderService } from './file-uploader.service';
import { ProgressDialogService } from './progress-dialog/progress-dialog.service';

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss'],
})
export class FileUploaderComponent implements OnChanges, OnInit, OnDestroy {
  // @Output() fileDropEvent: EventEmitter<File[]> = new EventEmitter<File[]>();
  @Input() template: Template;
  @ViewChild('uploadFolderTrigger') uploadFolderTrigger: ElementRef;
  @ViewChild('uploadFileTrigger') uploadFileTrigger: ElementRef;
  @Input() disabled: boolean;
  @Input() displayMode: EFileUploadMode = EFileUploadMode.DRAG_AND_DROP;
  showDropzone: boolean;
  isProcessing: boolean;
  private ngNext: Subject<void> = new Subject<void>();
  private ngDestroy: Subject<void> = new Subject<void>();
  dragOver: boolean;
  currentUploadStatus: string;

  get displayDragAndDrop(): boolean {
    return this.displayMode === EFileUploadMode.DRAG_AND_DROP ? true : false;
  }

  get displayButton(): boolean {
    return this.displayMode === EFileUploadMode.ICON_ONLY || this.displayMode === EFileUploadMode.ICON_AND_LABEL
      ? true
      : false;
  }
  private fileUploadAction: Action = new Action().setCb(() => of(this.openFileDialog()));
  @Input() uploadCollection: EntryCollection = new EntryCollection()
    .setResizeMode(EResizeMode.FIT_PARENT)
    .setDisplayOrientation(EDisplayOrientation.VERTICAL)
    .setGetDataAutomatically(false)
    .setEntryElements([
      new Button()
        .setId('upload-btn')
        .setName('FILE.GENERAL.upload')
        .setIcon('file_upload')
        .setDisplayType(
          this.displayMode === EFileUploadMode.ICON_ONLY
            ? EButtonDisplayType.ICON_ONLY
            : EButtonDisplayType.ICON_AND_LABEL
        )
        .chainActions(this.fileUploadAction),
    ]);

  constructor(
    private fileUploadApi: FileUploaderService,
    private _fileProgressApi: FileProgressService,
    private _lightboxApi: LightboxService,
    private progressDialogService: ProgressDialogService,
    private zone: NgZone
  ) { }

  ngOnInit(): void {
    this.init();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.template) {
      this.init();
    }

    if (changes.disabled) {
    }
  }

  /**
   * executed after input of file dialog was changed
   * @param {Event} event event of html input
   * @returns vpid
   */
  filesChanged(event: Event): void {
    const files: FileList = (event.target as HTMLInputElement).files;
    const _files: File[] = [];
    for (let i = 0; i < files.length; i++) {
      _files.push(files.item(i));
    }

    if (_files.length > 0) {
      this.progressDialogService.openProgressDialog();
    }

    this.currentUploadStatus = `FILE.GENERAL.uploading ${_files.length} FILE.GENERAL.file_s.`;
    if (_files.length === 0) {
      return;
    }

    const bytes: number = _files.map((f: File) => f.size).reduce((prev: number, cur: number) => prev + cur);
    this._fileProgressApi.setFileProgress(
      this._fileProgressApi
        .getFileProgressObject()
        .setNumberOfFilesToTransfer(
          this._fileProgressApi.getFileProgressObject().getNumberOfFilesToTransfer() + _files.length
        )
        .setNumberOfBytesToTransfer(this._fileProgressApi.getFileProgressObject().getNumberOfBytesToTransfer() + bytes)
    );
    this.fileUploadApi
      .onFileDropEvent(this.template, _files, false)
      .pipe(
        finalize(() => {
          const el: HTMLInputElement = event?.target as any;
          if (el) {
            el.value = null;
          }
        })
      )
      .subscribe(() => {
        this.currentUploadStatus = null;
      });
  }

  ngOnDestroy(): void {
    this.ngNext.next();
    this.ngNext.complete();
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  /**
   * applies contextmenu for file upload stuff
   * @returns void
   */
  private _applyCtx(): void {
    if (this.template && this.template.getFileUploadActions().length > 0) {
      const ctx: ContextMenu = this.template.getContextmenu() || new ContextMenu();
      let _item: ContextMenuItem = ctx
        .getContextMenuItems()
        .find((item: ContextMenuItem) => item.getId() === EPredefinedContextMenuItem.FILE_UPLOAD);
      if (!_item) {
        _item = new ContextMenuItem()
          .setOrder(-10)
          .setId(EPredefinedContextMenuItem.FILE_UPLOAD)
          .setIcon('file_upload')
          .setName('FILE.GENERAL.upload')
          .chainActions(this.fileUploadAction);
        ctx.addContextMenuItems(_item);
        this.template.setContextmenu(ctx);
      }
    }
  }

  init(): void {
    this.ngNext.next();
    // if (this.template &&
    //   this.template.getFileUploadActions().length > 0) {
    this._applyCtx();

    this.fileUploadApi
      .getDragState()
      .pipe(takeUntil(this.ngNext))
      .subscribe((state: boolean) => {
        this.showDropzone =
          this.template &&
          this.template instanceof Template &&
          this.template.getFileUploadActions().length > 0 &&
          state;
      });
    // }
  }

  /**
   * shows up native file dialog from html input
   * @param {MouseEevent} event mouse event
   * @returns void
   */
  openFileDialog(event: MouseEvent = null): void {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    // this.uploadTrigger?.nativeElement.click();
    const l: Lightbox = new Lightbox();
    l.setName(`${this.template.getName()}`)
      .setDisableSaveButton(true)
      .setDisableCancelButton(true)
      .setAdditionalButtons([
        new Button()
          .setPosition(EEntryElementPosition.RIGHT)
          .setName('FILE.GENERAL.upload')
          .setIcon('CMS_FILE')
          .setDisplayType(EButtonDisplayType.ICON_AND_LABEL)
          .chainActions(new Action().setCb(this.uploadFileAction(l))),
        // new Button().setPosition(EEntryElementPosition.RIGHT).setName('FILE.GENERAL.uploadFolder').setIcon('CMS_FOLDER').setDisplayType(EButtonDisplayType.ICON_AND_LABEL)
        //   .chainActions(new Action().setCb(this.uploadFolderAction(l))),
      ]);

    this._lightboxApi.open(l);
    // this._cd.detectChanges();
  }

  /**
   * uplaods a single folder by opening html file chooser
   * @param {Lightbox} l lightbox
   * @returns () => Observable<void>
   */
  private uploadFolderAction(l: Lightbox): () => Observable<void> {
    return (): Observable<void> => {
      l.getRef().close();
      return of(this.uploadFolderTrigger?.nativeElement.click());
    };
  }
  /**
   * uplaods multiple files by opening html file chooser
   * @param {Lightbox} l lightbox
   * @returns () => Observable<void>
   */
  private uploadFileAction(l: Lightbox): () => Observable<void> {
    return (): Observable<void> => {
      l.getRef().close();
      return of(this.uploadFileTrigger?.nativeElement.click());
    };
  }

  /**
   * handles drop of files
   * @param event DragEvent
   * @returns void
   */
  onDrop(event: DragEvent): void {
    const data: DataTransfer = event.dataTransfer;
    this.progressDialogService.openProgressDialog();
    this.getFileEntry(data.items).subscribe();
  }

  /**
   * creates confirm lightbox for chossing file or folder upload
   * @returns Observable<number>
   */
  private _confirmLightbox(skip?: boolean): Observable<EFileType> {
    return new Observable<any>((observer: Observer<any>) => {
      if (skip) {
        observer.next(EFileType.FILE);
        return observer.complete();
      }

      const l: Lightbox = new Lightbox();
      l.setName('FILE.GENERAL.upload')
        .setDisableSaveButton(true)
        .setDisableCancelButton(true)
        .setAdditionalButtons([
          new Button()
            .setName("FILE.GENERAL.keepFolder")
            .chainActions(new Action().setCb(() => of(l.getRef().close(EFileType.DIRECTORY)))),
          new Button()
            .setName("FILE.GENERAL.uploadFilesOnly")
            .chainActions(new Action().setCb(() => of(l.getRef().close(EFileType.FILE)))),
        ]);
      this.zone.run(() =>
        this._lightboxApi
          .open(l)
          .afterClosed()
          .subscribe((r: EFileType) => {
            observer.next(r);
            observer.complete();
          })
      );
    });
  }

  private traverseFileTree(item: FileSystemEntry, path = ""): Observable<File[]> {
    return new Observable<File[]>((observer) => {
      if (item.isFile) {
        (item as FileSystemFileEntry).file((file) => {
          (file as any).filepath = path + file.name;
          observer.next([file]);
          observer.complete();
        });
      } else if (item.isDirectory) {
        const dirReader = (item as FileSystemDirectoryEntry).createReader();

        this.fileUploadApi.readAllEntriesInDir(dirReader, (fsEntries) => {
          if (!fsEntries?.length) {
            observer.next([]);
            observer.complete();
            return;
          }

          const resolvedEntries$ = fsEntries.map((fsEntry) => this.traverseFileTree(fsEntry, path + item.name + "/"));
          forkJoin(resolvedEntries$).subscribe((r) => {
            observer.next([].concat(...r));
            observer.complete();
          });
        });
      }
    });
  }

  private initFileProgress(files: File[]): void {
    const numberOfBytes: number = files
      .map((f: File) => f.size)
      .reduce((prev: number, cur: number) => prev + cur);

    this._fileProgressApi.setFileProgress(
      this._fileProgressApi
        .getFileProgressObject()
        .setNumberOfFilesToTransfer(
          this._fileProgressApi
            .getFileProgressObject()
            .getNumberOfFilesToTransfer() + files.length
        )
        .setNumberOfBytesToTransfer(
          this._fileProgressApi
            .getFileProgressObject()
            .getNumberOfBytesToTransfer() + numberOfBytes
        )
    );
  }

  private getFileEntry(dataList: DataTransferItemList): Observable<File[]> {
    const files: DataTransferItem[] = Object.keys(dataList).map(
      (key: string) => dataList[key]
    );
    const results = files.map((f: DataTransferItem) => f.webkitGetAsEntry());
    const isDirectory = results.some((r: FileSystemEntry) => r.isDirectory);

    return this._confirmLightbox(!isDirectory).pipe(
      switchMap((type: EFileType) => {

        const files$ = forkJoin(
          results.map((fsEntry: FileSystemEntry) => this.traverseFileTree(fsEntry))
        ).pipe(
          map((res: File[][]) => {
            const files: File[] = [].concat(...res);
            this.initFileProgress(files);
            return files;
          })
        );

        switch (type) {
          case EFileType.DIRECTORY:
            return files$.pipe(

              switchMap((files: File[]) => {

                if (this.template.getDirectoryUploadActions().length) { // new implementation

                  // generate zip file
                  const zip = new JSZip();
                  files.forEach((file: File) => {
                    zip.file((file as any).filepath, file);
                  });

                  return from(zip.generateAsync({ type: "blob" })).pipe(
                    switchMap((content) => {
                      const file = new File([content], "upload.zip");

                      return this.fileUploadApi.onFileDropEvent(
                        this.template,
                        [file],
                        true
                      );
                    })
                  );
                } else { // old implementation
                  return forkJoin(
                    results.map((it: FileSystemEntry) =>
                      this.fileUploadApi.onFolderDrop(
                        it,
                        this.template.getResourceId()
                      )
                    )
                  ).pipe(
                    finalize(() => {
                      this.fileUploadApi.updateHmi();
                    })
                  );
                }
              })
            );

          case EFileType.FILE:
            return files$.pipe(
              switchMap((files: File[]) => {
                if (!files.length) { return of(null); }

                return this.fileUploadApi.onFileDropEvent(
                  this.template,
                  files,
                  false
                );
              })
            );
          default:
            return of(null);
        }
      })
    );
  }

  onDragEnter(e: DragEvent): void {
    this.dragOver = true;
  }

  onDragOver(e: DragEvent): void {
    this.dragOver = true;
  }

  onDragLeave(e: DragEvent): void {
    this.dragOver = false;
  }

  _ngOverride: Subject<void> = new Subject<void>();
  _currenOb: Observer<void>;

  get DISABLED(): boolean {
    if (this.template && this.template instanceof Template) {
      return this.template.isDisabled() || this.disabled;
    }

    return false;
  }
}
