import { Injectable } from '@angular/core';
import { GlobalUtils } from 'frontend/src/dashboard/global-utils';
import { BehaviorSubject, Observable } from 'rxjs';
import { SaxMsSubmenuElementItem } from './saxms-submenu-element-item';
import { IGanttElementActivationStateChanged, IGanttSubMenuUpdateMessage } from './saxms-submenu.interface';

export interface SaxMsServiceObject {
  elementId: string;
  disabled: boolean;
  color?: string;
  data?: any;
}

@Injectable()
export class SaxMsSubmenuService {
  private dataChange: BehaviorSubject<SaxMsServiceObject>;
  private favoriteDataChange: BehaviorSubject<SaxMsSubmenuElementItem[]>;
  private elementActiveStateChanged: BehaviorSubject<IGanttElementActivationStateChanged>;
  private updateElementState: BehaviorSubject<IGanttSubMenuUpdateMessage>;
  private _busy: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private busyStack: string[];
  private favoriteSubmenuElements: SaxMsSubmenuElementItem[] = [];
  private id = GlobalUtils.generateUUID();
  constructor() {
    this.dataChange = new BehaviorSubject(null);
    this.favoriteDataChange = new BehaviorSubject(null);
    this.elementActiveStateChanged = new BehaviorSubject(null);
    this.updateElementState = new BehaviorSubject(null);
  }

  unsetBusy(id: string) {
    if (!this.busyStack) {
      return;
    }
    this.busyStack = this.busyStack.filter((stack: string) => stack !== id);
    this._busy.next(this.busyStack && this.busyStack.length > 0);
  }

  setBusy(id: string): void {
    if (!this.busyStack) {
      this.busyStack = [];
    }
    this.busyStack = this.busyStack.filter((stack: string) => stack !== id);
    this.busyStack.push(id);
    this._busy.next(this.busyStack && this.busyStack.length > 0);
  }

  public reset() {
    this.dataChange = new BehaviorSubject(null);
    this.favoriteDataChange = new BehaviorSubject(null);
    this.elementActiveStateChanged = new BehaviorSubject(null);
    this.updateElementState = new BehaviorSubject(null);
    this.favoriteSubmenuElements = [];
    this._busy = new BehaviorSubject<boolean>(false);
  }

  public get busy(): Observable<boolean> {
    return this._busy.asObservable();
  }

  public triggerDataChange(action: SaxMsServiceObject): void {
    this.dataChange.next(action);
  }

  public onAction(): Observable<SaxMsServiceObject> {
    return this.dataChange.asObservable();
  }

  public onElementActiveStateChanged(elementId: string, isActive: boolean, value?: any): void {
    this.elementActiveStateChanged.next({
      elementId: elementId,
      isActive: isActive,
      value: value,
    });
  }

  public triggerElementById(updateMessage: IGanttSubMenuUpdateMessage): void {
    this.updateElementState.next(updateMessage);
  }

  public addElementToFavoriteTab(element: SaxMsSubmenuElementItem) {
    this.favoriteSubmenuElements.push(element);
    this.favoriteDataChange.next(this.favoriteSubmenuElements);
  }
  public resetElementOfFavoriteTab(elements: SaxMsSubmenuElementItem[]) {
    this.favoriteSubmenuElements = elements.slice();
    this.favoriteDataChange.next(elements);
  }

  public onFavoriteSubmenuElementsChange(): Observable<SaxMsSubmenuElementItem[]> {
    return this.favoriteDataChange.asObservable();
  }

  public onActiveStateSubmenuElementsChange(): Observable<IGanttElementActivationStateChanged> {
    return this.elementActiveStateChanged.asObservable();
  }

  public onUpdateElementState(): Observable<IGanttSubMenuUpdateMessage> {
    return this.updateElementState.asObservable();
  }
}
