import { Component, EventEmitter, Output } from '@angular/core';
import { GanttSettingsService } from 'frontend/src/dashboard/gantt/general/gantt-settings/service/gantt-settings.service';
import { LegendCommunicationService } from '../legend-communication.service';
import { ELegendDataSortingOrder } from '../legend-data-sorting.enum';

@Component({
  selector: 'app-legend-toolbar',
  templateUrl: './legend-toolbar.component.html',
  styleUrls: ['./legend-toolbar.component.scss'],
})
export class LegendToolbarComponent {
  @Output() visibilityChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() showOnlyVisibleElementsChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onHideFilteredOutElements: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onSearchForEntry: EventEmitter<any> = new EventEmitter<any>();
  @Output() onHideSearchResults: EventEmitter<any> = new EventEmitter<any>();
  @Output() onClearSearch: EventEmitter<null> = new EventEmitter<null>();
  @Output() onIgnoreEditedEntries: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onResetIgnoredEntries: EventEmitter<null> = new EventEmitter<null>();

  constructor(
    public legendCommunicationService: LegendCommunicationService,
    private ganttSettingsService: GanttSettingsService
  ) {}

  public toggleVisibility() {
    this.visibilityChange.emit(this.legendCommunicationService.isVisibilityChecked);
  }

  public toggleShowOnlyVisibleElements() {
    this.showOnlyVisibleElementsChange.emit(this.legendCommunicationService.showOnlyViewportEntries);
  }

  public onDisplayOptionChange() {
    this.onHideFilteredOutElements.emit(this.legendCommunicationService.hideFilteredOutElements);
  }

  public searchForEntry(event: any) {
    this.onSearchForEntry.emit(event);
  }

  public clearSearch() {
    this.legendCommunicationService.searchInput = '';
    this.onClearSearch.emit();
  }

  public toggleHideFilteredOutEntries(event: any): void {
    this.onHideSearchResults.emit(event);
  }

  protected toggleIgnoreEditedEntries(): void {
    this.onIgnoreEditedEntries.emit();
  }

  protected resetIgnoredEntries(): void {
    this.onResetIgnoredEntries.emit();
  }

  /**
   * Is called on colorize option change.
   */
  public onColorizeOptionChange() {
    this.legendCommunicationService.updateColorizeStrategy();
    this.ganttSettingsService.changeSettings({
      colorizeStrategyOption: this.legendCommunicationService.colorizeStrategyOption,
    });
    this.ganttSettingsService.saveSettings().subscribe();
  }

  protected _toggleEntrySortingOrder(): void {
    switch (this.legendCommunicationService.entrySortingOrder) {
      case ELegendDataSortingOrder.ASC:
        this.legendCommunicationService.entrySortingOrder = ELegendDataSortingOrder.DESC;
        break;
      case ELegendDataSortingOrder.DESC:
        this.legendCommunicationService.entrySortingOrder = ELegendDataSortingOrder.DEFAULT;
        break;
      default:
        this.legendCommunicationService.entrySortingOrder = ELegendDataSortingOrder.ASC;
        break;
    }
  }
}
