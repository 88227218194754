import { Action } from '@app-modeleditor/components/button/action/action';
import { EActionType } from '@app-modeleditor/components/button/action/action-type.enum';
import { Button } from '@app-modeleditor/components/button/button';
import { EButtonDisplayType } from '@app-modeleditor/components/button/button-display-type.enum';
import { EntryElement } from '@app-modeleditor/components/entry-collection/entry-element';
import { Toolbar } from 'frontend/src/dashboard/view/template-toolbar/toolbar';
import { of } from 'rxjs';
import { ToolbarGroup } from './toolbar-group';
import { EToolbarItemType } from './toolbar-item-type';

export class MenuItem extends Button {
  private active: boolean;
  private index: number;
  private navigationElement: Button;
  private groups: ToolbarGroup[];
  private toolbarItemType: EToolbarItemType;
  private display: boolean;
  private startOffset: number;
  private endOffset: number;
  private disabledScrollHandles: boolean;
  private shortcut: boolean;
  private emptyGroupText: string;

  public isEmpty(debug = false): boolean {
    if (this.getToolbarGroups().length === 0) {
      return true;
    }
    if (this.getToolbarGroups().filter((g: ToolbarGroup) => g.getEntryElements().length > 0).length === 0) {
      return true;
    }

    return false;
  }

  public getEmptyGroupText(): string {
    return this.emptyGroupText;
  }

  public setEmptyGroupText(emptyGroupText: string): this {
    this.emptyGroupText = emptyGroupText;
    return this;
  }

  public isShortcut(): boolean {
    return typeof this.shortcut === 'boolean' ? this.shortcut : false;
  }

  public setShortcut(shortcut: boolean): this {
    this.shortcut = shortcut;
    return this;
  }

  public hasDisabledScrollHandles(): boolean {
    return typeof this.disabledScrollHandles === 'boolean' ? this.disabledScrollHandles : false;
  }

  public setDisabledScrollHandles(disabledScrollHandles: boolean): this {
    this.disabledScrollHandles = disabledScrollHandles;
    return this;
  }

  public getEndOffset(): number {
    return this.endOffset;
  }

  public setEndOffset(endOffset: number): this {
    this.endOffset = endOffset;
    return this;
  }

  /**
   * get starting offset of toolbar row
   * @returns number
   */
  public getStartOffset(): number {
    return this.startOffset;
  }

  /**
   * set scroll offset of toolbar row
   * @parm startOffset number
   * @returns this
   */
  public setStartOffset(startOffset: number): this {
    this.startOffset = startOffset;
    return this;
  }

  constructor() {
    super();
    this.setToolbarItemType(EToolbarItemType.GROUP).setDisplayBackground(false);
  }

  defaultGroup(t: Toolbar): this {
    return this.setDisplayType(EButtonDisplayType.ICON_AND_LABEL)
      .setDisplayBackground(false)
      .setToolbarItemType(EToolbarItemType.GROUP)
      .chainActions(new Action().setActionType(EActionType.LOCAL).setCb(() => of(t.toggleActiveMenuItem(this))));
  }

  public getNavigationElement(): Button {
    return this.navigationElement;
  }

  public setNavigationElement(navigationElement: Button): this {
    this.navigationElement = navigationElement;
    return this;
  }

  public isDisplay(): boolean {
    return typeof this.display === 'boolean' ? this.display : true;
  }

  public setDisplay(display: boolean): this {
    this.display = display;
    return this;
  }

  public getToolbarGroups(): ToolbarGroup[] {
    return this.groups || [];
  }

  public setToolbarGroups(groups: ToolbarGroup[]): this {
    this.groups = groups;
    return this;
  }

  public addToolbarGroups(...groups: ToolbarGroup[]): this {
    this.groups = this.getToolbarGroups().concat(groups);
    return this;
  }

  public getToolbarItemType(): EToolbarItemType {
    return this.toolbarItemType;
  }

  public setToolbarItemType(toolbarItemType: EToolbarItemType): this {
    this.toolbarItemType = toolbarItemType;
    return this;
  }

  public isActive(): boolean {
    return this.active;
  }

  public setActive(active: boolean): this {
    this.active = active;
    return this;
  }

  public getIndex(): number {
    return this.index;
  }

  public setIndex(index: number): this {
    this.index = index;
    return this;
  }

  public isEntryElementInside(entryElement: EntryElement): boolean {
    for (const group of this.getToolbarGroups()) {
      const entry = group.getEntryElements().find((e) => e.getId() === entryElement.getId());
      if (entry) {
        return true;
      }
    }
    return false;
  }

  /**
   * Remove all filters, if null corresponding button should not be rendered.
   */
  public readonly onRemoveAllFilters: () => void | null = null;
}
