import { DatePipe } from '@angular/common';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { SortOrder } from '@app-modeleditor/components/elements/sort-menu/sort-order.enum';
import { EFieldType } from '@app-modeleditor/components/entry-collection/field-type.enum';
import { FreetextFilter } from '@core/filter/freetext-filter.enum';
import { FreetextFilterService } from '@core/filter/freetext-filter.service';
import { takeWhile } from 'rxjs/operators';
import { IFilters, IHierarchicItem, IHierarchicMenuItem } from '../tree.model';

export enum EFilterChipItemType {
  FREETEXT = 'freetext',
  EXTRAFILTER = 'extrafilter',
}

export interface IFilterChipItem {
  label: string;
  fieldIdentifier?: string;
  type: EFilterChipItemType;
  value: any;
  color?: string;
  borderColor?: string;
  sortOrder?: string;
}

@Component({
  selector: 'app-filterchips-list',
  templateUrl: './filterchips-list.component.html',
  styleUrls: ['./filterchips-list.component.scss'],
})
export class FilterchipsListComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input() node: IHierarchicMenuItem | IHierarchicItem;
  @Input() twoRowLayout = false;
  @Output() afterCalcContainerWidth: EventEmitter<number> = new EventEmitter();

  sortOrder = SortOrder;

  public filterChips: IFilterChipItem[] = [];
  public containerWidth = 0;
  private $alive = false;
  private $blockReload;

  constructor(private datePipe: DatePipe, private freetextFilterService: FreetextFilterService) {}

  ngOnInit(): void {
    this.$alive = true;
    this.freetextFilterService.onFilterChanged.pipe(takeWhile(() => this.$alive)).subscribe((filter: any) => {
      if (!filter || (filter && filter.id !== this.node.uuid)) {
        return;
      }

      if (!this.$blockReload) {
        this.getFilterChipsItems();
      }

      this.$blockReload = false;
    });

    this.getFilterChipsItems();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.node) {
      this.getFilterChipsItems();
    }
  }

  ngOnDestroy(): void {
    this.$alive = false;
    Object.keys(this || {}).forEach((key: string) => {
      delete this[key];
    });
  }

  ngAfterViewInit(): void {
    this.afterCalcContainerWidth.emit(this.containerWidth);
  }

  getFilterChipsItems(): void {
    const filterChips = [];

    if (this.node.filterString && this.node.lastFilterType === FreetextFilter.FREETEXT_FILTER) {
      filterChips.push({
        label: this.node.filterString,
        value: this.node.filterString,
        type: EFilterChipItemType.FREETEXT,
        borderColor: this.stringToColour(EFilterChipItemType.FREETEXT, 0.6),
        color: this.stringToColour(EFilterChipItemType.FREETEXT, 0.2),
      });
    }

    if ((this.node as IHierarchicItem).filters && this.node.lastFilterType === FreetextFilter.COMPLEX_FILTER) {
      for (const filterItem of (this.node as IHierarchicItem).filters) {
        if (filterItem.value || (filterItem.enableSort && filterItem.sortOrder !== 'NONE')) {
          filterChips.push({
            sortOrder: filterItem.sortOrder,
            label: filterItem.name + ': ' + (this.getFilterChipValue(filterItem) || ''),
            value: filterItem.value || '',
            fieldIdentifier: filterItem.fieldIdentifier,
            type: EFilterChipItemType.EXTRAFILTER,
            borderColor: this.stringToColour(filterItem.fieldIdentifier, 0.6),
            color: this.stringToColour(filterItem.fieldIdentifier, 0.2),
          });
        }
      }
    }
    this.filterChips = filterChips.slice();
    this.getWrapperWidth();
  }

  private getFilterChipValue(filter: IFilters) {
    if (filter.displayFilterElement) {
      switch (filter.displayFilterElement.fieldType) {
        case EFieldType.RANGE_PICKER:
          return this.getDateRangeValue(filter);
        default:
          if (Array.isArray(filter.value)) {
            return filter.value.map((val) => val.name).reduce((prev: string, cur: string) => prev + ', ' + cur);
          }
          return filter.value || '';
      }
    } else {
      return filter.value || '';
    }
  }

  /**
   * Returns a string representation of the date range for the given filter.
   * If either the 'from' or 'to' date is missing, an empty string is returned.
   * @param filter - The filter object to get the date range for.
   * @returns A string representation of the date range, formatted as 'dd.MM.yyyy - dd.MM.yyyy'.
   */
  private getDateRangeValue(filter: IFilters): string {
    if (!filter?.value?.from || !filter?.value?.to) {
      return '--';
    }

    const start = new Date(filter.value.from);
    const end = new Date(filter.value.to);
    end.setDate(end.getDate() - 1);
    return `${this.datePipe.transform(start, 'dd.MM.yyyy')} - ${this.datePipe.transform(end, 'dd.MM.yyyy')}`;
  }

  private stringToColour(str, alpha: number) {
    if (!str) {
      str = '';
    }
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    let colour = '#';
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xff;
      colour += ('00' + value.toString(16)).substr(-2);
    }
    return this.hexToRGB(colour, alpha);
  }

  private hexToRGB(hex: string, alpha: number) {
    const r = parseInt(hex.slice(1, 3), 16),
      g = parseInt(hex.slice(3, 5), 16),
      b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
      return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
    } else {
      return 'rgb(' + r + ', ' + g + ', ' + b + ')';
    }
  }

  getWrapperWidth(): void {
    if (this.filterChips && this.filterChips.length > 0) {
      this.containerWidth = Math.ceil(this.filterChips.length / 2) * 103;
    } else {
      this.containerWidth = 0;
    }

    this.afterCalcContainerWidth.emit(this.containerWidth);
  }

  removeFilter(node: IHierarchicMenuItem | IHierarchicItem, filterChip: IFilterChipItem): void {
    switch (filterChip.type) {
      case EFilterChipItemType.FREETEXT:
        node.filterString = '';
        break;
      case EFilterChipItemType.EXTRAFILTER:
        const filterValue = (this.node as IHierarchicItem).filters.find(
          (filter) => filter.fieldIdentifier === filterChip.fieldIdentifier
        );
        filterValue.value = null;
        filterValue.sortOrder = SortOrder.none;
        break;
    }

    if (this.twoRowLayout) {
      this.$blockReload = true;
      this.filterChips = this.filterChips.filter((fc) => fc.fieldIdentifier !== filterChip.fieldIdentifier);
    }

    this.freetextFilterService.changeFilter({
      id: this.node.uuid,
      value: node.filterString,
      filters: (this.node as IHierarchicItem).filters,
      type: this.node.lastFilterType,
    });
  }
}
