<div class="duration-picker" [id]="id" [ngStyle]="{ 'overflow-x': simpleInput ? 'hidden' : 'auto' }">
  <ng-container *ngIf="!simpleInput">
    <label class="title"> {{ title }} </label>
    <ng-container *ngFor="let zone of zones">
      <ng-container *ngIf="zone.enabled">
        <mat-form-field class="picker">
          <input
            #ref1
            [min]="0"
            [type]="'number'"
            [disabled]="disabled"
            matInput
            (change)="transformBack()"
            (blur)="onBlur()"
            [(ngModel)]="zone.value"
          />
          <span matSuffix>{{ 'PICKER.DURATION.' + zone.key | translate }}</span>

          <!-- <mat-hint [align]="'end'">{{
            "PICKER.DURATION." + zone.key | translate
          }}</mat-hint> -->
          <app-conditions-menu
            matPrefix
            *ngIf="entryElement?.getAvailableFilterConditions().length > 0"
            [entryElement]="entryElement"
          ></app-conditions-menu>
        </mat-form-field>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="simpleInput">
    <div class="simple-picker-wrapper">
      <ng-container *ngFor="let zone of zones">
        <ng-container *ngIf="zone.enabled">
          <input
            #ref2
            class="simple-input"
            [min]="0"
            [type]="'number'"
            [disabled]="disabled"
            (keyup.enter)="onBlur()"
            [ngStyle]="{ width: simpleInputWidth }"
            (change)="transformBack()"
            (blur)="onBlur()"
            [(ngModel)]="zone.value"
          />
          <div class="simple-label">
            {{ 'PICKER.DURATION.' + zone.key | translate }}
          </div>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
</div>
