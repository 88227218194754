import { Layout } from '../layout';
import { ELayoutType } from '../layout-type.enum';

export class VirtualLayout extends Layout {
  private viewportHeight: number;

  /**
   * get max height of the rendered viewport
   * @returns number
   */
  public getViewportHeight(): number {
    return this.viewportHeight || 500;
  }

  /**
   * sets the max height of the current render viewport
   * @param viewportHeight number
   * @returns this
   */
  public setViewportHeight(viewportHeight: number): this {
    this.viewportHeight = viewportHeight;
    return this;
  }

  constructor() {
    super();
    this.setLayoutType(ELayoutType.VIRTUAL_LAYOUT);
  }
}
