import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { TemplateProgressbarComponent } from './template-progressbar.component';

@NgModule({
  imports: [CommonModule, TranslateModule, MatFormFieldModule, MatTooltipModule],
  schemas: [],
  exports: [TemplateProgressbarComponent],
  declarations: [TemplateProgressbarComponent],
})
export class TemplateProgressbarModule {}
