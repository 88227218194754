import { Observable, Subject, takeUntil } from 'rxjs';
import { GanttCallBackStackExecuter } from '../../callback-tools/callback-stack-executer';
import { GanttConfig } from '../../config/gantt-config';
import { GanttCanvasShift, GanttDataContainer } from '../../data-handler/data-structure/data-structure';
import { BestGantt } from '../../main';
import { GanttEdgeHandler } from '../edges/edge-executer';
import { BestGanttPlugIn } from '../gantt-plug-in';
import { GanttShiftComponentVisualizer } from './shift-component-visualizer';
import { GanttShiftComponentsChainHandler } from './shift-components-chain-handler';
import { GanttShiftComponentsDataHandler } from './shift-components-data-handler';
import { SuperBlockBuilder } from './superBlockBuilder';

/**
 * Shift Component Plugin
 * @author Florian Freier
 * @class
 * @constructor
 * @extends BestGanttPlugIn
 * @requires BestGanttPlugIn
 */
export class GanttShiftComponents extends BestGanttPlugIn {
  edgeBuilder: GanttEdgeHandler;
  combinePlugIn: SuperBlockBuilder;
  chainComponents: boolean;
  renderEdges: boolean;
  callBack: any;
  deactivated: boolean;
  timeOut: number;
  timeOutRowOpen: NodeJS.Timeout;
  componentVisualizer: GanttShiftComponentVisualizer;
  dataSet: GanttDataContainer;
  config: GanttConfig;
  componentChainHandler: GanttShiftComponentsChainHandler;
  dataHandler: GanttShiftComponentsDataHandler;

  private _onRemoveComponentVisCbsSubject: Subject<void> = new Subject<void>();

  /**
   * @param renderEdges Setting if edges should be rendered or not.
   * @param chainComponents Setting if components should be chained.
   */
  constructor(renderEdges = true, chainComponents = true) {
    super(); // call super-constructor

    this.edgeBuilder = null;
    this.combinePlugIn = null;

    this.chainComponents = chainComponents;
    this.renderEdges = renderEdges;

    this.callBack = {
      afterChainingCallback: {},
      beforeRemoveGroupCallback: {},
      afterRemoveGroupCallback: {},
      afterChangeActivationState: {},
    };

    this.deactivated = false;

    this.timeOut = null;
    this.timeOutRowOpen = null;
  }

  /**
   * Plugin Initialization.
   * @param ganttDiagram the ganttDiagram
   */
  public initPlugIn(ganttDiagram: BestGantt): void {
    this.ganttDiagram = ganttDiagram;

    this.dataSet = this.ganttDiagram.getDataHandler().getOriginDataset();
    this.config = this.ganttDiagram.getConfig();

    this.combinePlugIn = new SuperBlockBuilder(this, null);
    this.componentChainHandler = new GanttShiftComponentsChainHandler(this.ganttDiagram); // chain shifts together for dragging
    this.dataHandler = new GanttShiftComponentsDataHandler(this.ganttDiagram, this.componentChainHandler, this);
    this.componentChainHandler._initDataHandler(this.dataHandler);
    this.componentVisualizer = new GanttShiftComponentVisualizer(this.ganttDiagram, this.dataHandler);

    /* s.ganttDiagram.getXAxisBuilder().addToZoomEndCallback(`reRender mixed superblock vis ${s.UUID}`, () => {
      clearTimeout(s.timeOut);
      s.timeOut = setTimeout(() => {
        s.componentVisualizer.regenerateData();
        s.componentVisualizer.reRender();
      }, 1000);
    });

    s.ganttDiagram.getXAxisBuilder().addToZoomStartCallback(`reRender mixed superblock vis ${s.UUID}`, () => {
      s.componentVisualizer.removeAll();
    }); */

    this.edgeBuilder = new GanttEdgeHandler();
    this.edgeBuilder.initPlugIn(ganttDiagram);
  }

  public update(): void {
    if (!this.deactivated) {
      this.componentVisualizer.regenerateData();
      this.componentVisualizer.reRender();
    }
  }

  /**
   * gets activated by button press in html
   * handles selection and adds edges for groups to edgeHandler
   * @param {string} id ID for the group to be created
   */
  addComponentMarking(id) {
    this._createComponentGroupFromSelection(id);
    this.reRenderEdges();
  }

  /**
   * gets activated by button press in html
   * handles removing of selected components
   */
  removeComponentMarking() {
    GanttCallBackStackExecuter.execute(this.callBack.beforeRemoveGroupCallback, {});
    const tmp = this.ganttDiagram.getSelectionBoxFacade().getSelectedShifts();
    const selectedShifts = JSON.parse(JSON.stringify(tmp));
    if (selectedShifts.length < 1) {
      // less than 1 shifts in selection do nothing
      return;
    }
    if (!this.areShiftsInSameRowBySelection()) return; // check entire selection is in the same row, if not do nothing

    for (let i = 0; i < selectedShifts.length; i++) {
      // flatten canvasData to IDs
      selectedShifts[i] = selectedShifts[i].id;
    }

    this.getShiftComponentDataHandler().RemoveFacade(selectedShifts);
    this.reRenderEdges();
    GanttCallBackStackExecuter.execute(this.callBack.afterRemoveGroupCallback, {});
  }

  /**
   * Combines given shifts to one new group with given id.
   * @param {GanttShiftComponentsDataItem[]} dataSet
   */
  createComponentByData(dataSet) {
    this.getShiftComponentDataHandler().addToShiftComponent(dataSet);
    this.reRenderEdges();
  }

  /**
   * Will be executed if plugin will be removed from gantt.
   * End of lifecycle.
   * @keywords init, initialize, plugin, plug in, lifecycle, end, finish, kill, terminate
   */
  public removePlugIn(): void {
    this._onRemoveComponentVisCbsSubject.next();
    this._onRemoveComponentVisCbsSubject.complete();
  }

  // ====================
  // ==>> PUBLIC METHODS
  // ====================

  /**
   * Checks if array of canvasData is in the same Row
   * @param {JSON[]} canvasDataArray  array of canvasData To Check if all are in the same row (same y Position)
   * @return {boolean} true if all canvasShifts are in same row (same y position), else false
   */
  areShiftsInSameRowByCanvasDataArray(canvasDataArray) {
    return this._areShiftsInSameRow(canvasDataArray);
  }

  /**
   * Checks if selection is in the same Row
   * @return {boolean} true if selection is in same row (same y position), else false
   */
  areShiftsInSameRowBySelection() {
    const selectedShifts = this.ganttDiagram.getSelectionBoxFacade().getSelectedShifts();
    return this._areShiftsInSameRow(selectedShifts);
  }

  /**
   * rerenders ShiftComponent Edges and reChains Components
   */
  reRenderEdges(reChain = true) {
    const canvasDataMap = this.ganttDiagram.getDataHandler().getCanvasShiftDataMap();
    if (this.chainComponents && !this.deactivated && reChain) this.reChainComponents();
    this.getShiftComponentEdgeHandler().removeAllEdges();
    this._addEdgesForComponentGroups(canvasDataMap);
  }

  /**
   * Creates Chains for all component groups
   */
  reChainComponents() {
    if (this.deactivated) return;
    this._chainAllComponents();
    GanttCallBackStackExecuter.execute(this.callBack.afterChainingCallback, {});
  }

  // REMOVE METHODS

  /**
   * Removes a shift component with the given component ID.
   * @param componentID The ID of the component to remove.
   * @param rerenderEdges Whether to re-render the edges after removing the component. Defaults to true.
   * @returns True if the component was successfully removed, false otherwise.
   */
  removeComponentByComponentID(componentID, rerenderEdges = true) {
    const dataHandler = this.getShiftComponentDataHandler();
    if (dataHandler.removeComponentByComponentID(componentID)) {
      if (rerenderEdges) this.reRenderEdges();
      return true;
    } else return false;
  }

  /**
   * removes a component by its id and corresponding group id and reRenders Edges
   * @param {string} componentID ID of the component to get removed
   * @param {string} componentGroupID ID of the Group of the component to get removed
   * @return {boolean} true if removal was succesful, else false
   */
  removeComponent_ByComponentID_and_ComponentGroupID(componentID, componentGroupID) {
    const dataHandler = this.getShiftComponentDataHandler();
    if (dataHandler.removeComponentByComponentIDAndGroupID(componentID, componentGroupID)) {
      this.reRenderEdges();
      return true;
    } else return false;
  }

  /**
   * removes a componentGroup by its ID and reDraws Edges
   * @param {string} componentGroupID ID of the componentGroup to get removed
   * @return {boolean} true if removal was succesfull, else false
   */
  removeComponentGroupByComponentGroupID(componentGroupID) {
    if (this.getShiftComponentDataHandler().removeComponentGroupByComponentGroupID(componentGroupID)) {
      this.reRenderEdges();
      return true;
    }
    return false;
  }

  /**
   * Activates the drawing of edges for components.
   * @param {boolean} showEdges true to show edges, else false
   */
  showEdges(showEdges) {
    if (!showEdges) this.getShiftComponentEdgeHandler().removeAllEdges();
    else
      this.getShiftComponentEdgeHandler().rebuildEdges(
        this.getShiftComponentEdgeHandler().getConnectedObjectsDataSet()
      );
    this.renderEdges = showEdges;
  }

  // ====================
  // ==>> PRIVATE METHODS
  // ====================

  /**
   * creates or appends componentGroups from selection
   * @param {String} id id of group to be created
   * @private
   */
  private _createComponentGroupFromSelection(id) {
    const tmp = this.ganttDiagram.getSelectionBoxFacade().getSelectedShifts();
    if (tmp.length < 1) {
      // less then 1 shifts in selection do nothing
      return;
    }

    const selectedShifts = JSON.parse(JSON.stringify(tmp)); // work on copy

    if (!this.areShiftsInSameRowBySelection()) return; // check entire selection is in the same row, if not do nothing

    for (let i = 0; i < selectedShifts.length; i++) {
      // flatten canvasData to IDs
      selectedShifts[i] = selectedShifts[i].id;
    }

    this.getShiftComponentDataHandler().AdditionFacade(selectedShifts, id); // give group and id to dataHandler
    GanttCallBackStackExecuter.execute(this.callBack.afterChainingCallback, {});
  }

  /**
   * Helper: are Components in Same Row
   * @param {JSON[]} array array of objects with y attribute
   * @return {boolean} true if all components in same row, else false
   * @private
   */
  private _areShiftsInSameRow(array) {
    const firstY = array[0].y;
    for (let i = 0; i < array.length; i++) {
      if (array[i].y != firstY) return false;
    }
    return true;
  }

  /**
   * adds edges for group to the edgeBuilder
   * @param {string[]} componentGroup a Component Group aka Shift of Components
   * @private
   */
  private _addEdgesForGroupToEdgeBuilder(componentGroup, canvasShiftDataMap: Map<string, GanttCanvasShift>) {
    const connectionColor = 'green';
    for (let i = 0; i < componentGroup.length; i++) {
      if (i < componentGroup.length - 1) {
        this.getShiftComponentEdgeHandler().createEdgeByIds(
          componentGroup[i],
          componentGroup[i + 1],
          connectionColor,
          null,
          null,
          null,
          null,
          null,
          null,
          canvasShiftDataMap
        );
      }
    }
  }

  /**
   * implementation for chaining all components
   * @private
   */
  private _chainAllComponents() {
    if (this.chainComponents) this.getShiftComponentChainHandler().chainAllComponents();
  }
  /**
   * draws edges between shiftComponents
   * @private
   */
  private _addEdgesForComponentGroups(canvasShiftDataMap: Map<string, GanttCanvasShift>) {
    const shiftComponents = this.getShiftComponentDataHandler().getShiftComponents();
    for (const index in shiftComponents) {
      const componentGroup = shiftComponents[index];
      this._addEdgesForGroupToEdgeBuilder(componentGroup.group, canvasShiftDataMap);
    }
    if (this.renderEdges && !this.deactivated)
      this.getShiftComponentEdgeHandler().rebuildEdges(
        this.getShiftComponentEdgeHandler().getConnectedObjectsDataSet()
      );
    else this.getShiftComponentEdgeHandler().removeAllEdges();
  }

  // ====================
  // ==>> CALLBACKS
  // ====================
  addAfterChainingCallback(id, func) {
    this.callBack.afterChainingCallback[id] = func;
  }
  removeAfterChainingCallback(id) {
    delete this.callBack.afterChainingCallback[id];
  }

  addAfterRemoveGroupCallback(id, func) {
    this.callBack.afterRemoveGroupCallback[id] = func;
  }
  removeAfterRemoveGroupCallback(id) {
    delete this.callBack.afterRemoveGroupCallback[id];
  }

  addBeforeRemoveGroupCallback(id, func) {
    this.callBack.beforeRemoveGroupCallback[id] = func;
  }
  removeBeforeRemoveGroupCallback(id) {
    delete this.callBack.beforeRemoveGroupCallback[id];
  }

  addAfterChangeActivationStateCallback(id, func) {
    this.callBack.afterChangeActivationState[id] = func;
  }
  removeAfterChangeActivationStateCallback(id) {
    delete this.callBack.afterChangeActivationState[id];
  }

  // ====================
  // ==>> GETTER & SETTER
  // ====================

  /**
   * @return {GanttShiftComponentsDataHandler} returns ShiftComponentsDataHandler
   */
  getShiftComponentDataHandler() {
    return this.dataHandler;
  }

  /**
   * @return {GanttShiftComponentsChainHandler} returns ShiftComponentChainHandler
   */
  getShiftComponentChainHandler() {
    return this.componentChainHandler;
  }

  /**
   * @return {GanttEdgeHandler} returns ShiftComponentEdgeHandler
   */
  getShiftComponentEdgeHandler() {
    return this.edgeBuilder;
  }

  /**
   * @return {GanttShiftComponentVisualizer} returns GanttShiftComponentVisualizer
   */
  getShiftComponentVisualizer() {
    return this.componentVisualizer;
  }

  /**
   * @return {SuperBlockBuilder} returns SuperBlockBuilder
   */
  getSuperBlockBuilder() {
    return this.combinePlugIn;
  }

  /**
   * sets dataSet as new ShiftComponentDataSet
   * @param {Array.<{id:string, group:string[]}>} dataSet the new dataSet to be set
   * @return {boolean} true if succesfully set, else false
   */
  setShiftComponentDataSet(dataSet) {
    const dataHandler = this.getShiftComponentDataHandler();
    if (dataHandler.initializeDataSet(dataSet)) {
      this._chainAllComponents();
      this.reRenderEdges();
      return true;
    } else return false;
  }

  //
  // RESTRICTIONS
  //
  /**
   * @param {boolean} activate
   * @param {"COMBINED"|"SUPERBLOCK"|"NONE"|"ARROWS"|"MIXED"} [combineModus]
   */
  activate(activate, combineModus, superBlockBackendData) {
    this.deactivated = !activate;

    this._removeComponentVisCallbacks();

    // handle chaining
    if (this.deactivated) {
      clearTimeout(this.timeOut);
      clearTimeout(this.timeOutRowOpen);
      this.getShiftComponentChainHandler().removeAllComponentsFromChaining();
      this.getShiftComponentEdgeHandler().getEdgeBuilder().showEdges(false);
      this._removeComponentVisCallbacks();
    } else {
      // const drawArrows = combineModus && combineModus === "ARROWS";

      switch (combineModus) {
        case 'COMBINED':
        case 'SUPERBLOCK':
          this.combinePlugIn.combineChainedShifts();
          // s.getShiftComponentChainHandler().chainAllComponents();
          this.renderEdges = false;
          break;
        case 'ARROWS':
          this.combinePlugIn.splitCombinedShifts(null);
          this.combinePlugIn.combineChainedShifts();
          this.getShiftComponentChainHandler().chainAllComponents();
          this.renderEdges = true;
          break;
        case 'MIXED':
          this.combinePlugIn.splitCombinedShifts(null);
          this.getShiftComponentChainHandler().chainAllComponents();
          this.componentVisualizer.visualizeRelatedComponents(superBlockBackendData);
          this._registerComponentVisCallbacks();
          this.renderEdges = false;
          break;

        default: // NONE
          this.getShiftComponentChainHandler().removeAllComponentsFromChaining();
          this.combinePlugIn.splitCombinedShifts(null);
          break;
      }
    }

    this.getShiftComponentEdgeHandler().getEdgeBuilder().showEdges(this.renderEdges);

    // handle edges
    this.reRenderEdges(false);
    GanttCallBackStackExecuter.execute(
      this.callBack.afterChangeActivationState,
      new GanttShiftComponentActivation(activate)
    );
  }

  private _registerComponentVisCallbacks(): void {
    this.ganttDiagram
      .getXAxisBuilder()
      .onZooming.pipe(takeUntil(this.onRemoveComponentVisCbs))
      .subscribe(() => {
        this.componentVisualizer.regenerateData();
        this.componentVisualizer.reRender();
      });

    this.ganttDiagram
      .getXAxisBuilder()
      .onZoomEnd.pipe(takeUntil(this.onRemoveComponentVisCbs))
      .subscribe(() => {
        this.componentVisualizer.regenerateData();
        this.componentVisualizer.reRender();
      });

    this.ganttDiagram
      .getVerticalScrollHandler()
      .onScrollVerticalUpdate.pipe(takeUntil(this.onRemoveComponentVisCbs))
      .subscribe(() => {
        this.componentVisualizer.regenerateData();
        this.componentVisualizer.reRender();
      });

    this.ganttDiagram
      .getOpenRowHandler()
      .afterShiftRowOpen.pipe(takeUntil(this.onRemoveComponentVisCbs))
      .subscribe(() => {
        this.timeOutRowOpen = setTimeout(() => {
          this.componentVisualizer.regenerateData();
          this.componentVisualizer.reRender();
        }, 400);
      });

    this.ganttDiagram
      .getOpenRowHandler()
      .afterShiftRowClosed.pipe(takeUntil(this.onRemoveComponentVisCbs))
      .subscribe(() => {
        this.componentVisualizer.regenerateData();
        this.componentVisualizer.reRender();
      });
  }

  private _removeComponentVisCallbacks(): void {
    this._onRemoveComponentVisCbsSubject.next();
    clearTimeout(this.timeOut);
    clearTimeout(this.timeOutRowOpen);
    this.componentVisualizer.remove();
  }

  //
  // OBSERVABLES
  //

  private get onRemoveComponentVisCbs(): Observable<void> {
    return this._onRemoveComponentVisCbsSubject.asObservable();
  }
}

/**
 * @class
 * @constructor
 * @param {boolean} activate
 */
export class GanttShiftComponentActivation {
  activate: boolean;

  constructor(activate) {
    this.activate = activate;
  }
}
