import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { Action } from '@app-modeleditor/components/button/action/action';
import { EActionType } from '@app-modeleditor/components/button/action/action-type.enum';
import { Button } from '@app-modeleditor/components/button/button';
import { EEntryElementPosition } from '@app-modeleditor/components/entry-collection/entry-element-position.enum';
import { ActionService } from 'frontend/src/dashboard/shared/data-access/actions/action.service';
import { Subject, of } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';
import { Wizard } from './wizard';
import { WizardPage } from './wizard-page';

@Component({
  selector: 'app-wizard-lightbox',
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.component.scss'],
})
export class WizardComponent implements OnInit, OnDestroy {
  @Input() wizard: Wizard;
  @ViewChild(MatStepper) stepper: MatStepper;
  currentPageIndex = 0;
  recalc = false;
  private _ngDestroy: Subject<void> = new Subject<void>();
  changingView: boolean;
  constructor(private actionApi: ActionService, private cd: ChangeDetectorRef) {
    this.actionApi.customActionListener.pipe(takeUntil(this._ngDestroy)).subscribe((action) => {
      if (!action || !action.button) {
        return;
      }
      switch (action.button.getActionType()) {
        case EActionType.NEXT_PAGE:
        case EActionType.PREVIOUS_PAGE:
        case EActionType.NAVIGATE_TO_PAGE:
          if (this.stepper) {
            this.changingView = true;
            this.stepper.linear = false;
            this.stepper.selected.completed = true;
            if (this.wizard.isDisableTopNavigation()) {
              this.stepper.steps.forEach((step) => (step.editable = true));
            }
            // if index is the same, reload current page
            if (this.currentPageIndex === action.content) {
              this.recalc = true;
              this.cd.detectChanges();
              this.recalc = false;
            }
            this.stepper.selectedIndex = action.content;
            this.changingView = false;
            if (this.wizard.isDisableTopNavigation()) {
              this.stepper.steps.forEach((step) => (step.editable = false));
            }
          }
          break;
        case EActionType.CLOSE_WIZARD:
        case EActionType.EXECUTE_EXPERIMENT:
          this.wizard.getRef()?.close();
          break;
        default:
          console.warn('NOT IMPLEMENTED', action.button.getActionType());
          break;
      }
    });
  }

  onSelectionChanged(event: StepperSelectionEvent): void {
    this.stepper.linear = true;
    this._changePage(event.selectedIndex);
  }

  /**
   * change wizard page
   * @param pageIndex index of the page the wizard have to render
   * @returns void
   */
  private _changePage(pageIndex: number): void {
    if (isNaN(pageIndex)) {
      return;
    }

    this.currentPageIndex = pageIndex;

    if (this.stepper && this.stepper.selected) {
      this.stepper.selected.completed = false;
    }

    const page: WizardPage = this.wizard.getContent().getWizardPage()[this.currentPageIndex];

    // set resourceId to wizards resourceId
    page.setResourceId(this.wizard.getResourceId());

    const btns: Button[] = page.getAdditionalButtons().map((b: Button) => {
      if (b.getEntry() && b.getEntry().getValue()) {
        b.setName(b.getEntry().getValue<string>());
      }
      return b;
    });
    if (page.getBackButton()) {
      btns.unshift(page.getBackButton());
    }
    if (page.getNextButton()) {
      btns.push(page.getNextButton());
    }

    this.wizard.setAdditionalButtons(btns);
    this.wizard.reinitialize();
    this.wizard.getDefaultButtons().forEach((btn: Button) => {
      if (
        btn
          .getChain()
          .getActions()
          .find((a: Action) => a.getActionType() === EActionType.NEXT_PAGE)
      ) {
        btn.setPosition(EEntryElementPosition.RIGHT);
      }
    });
  }

  ngOnInit(): void {
    of(null)
      .pipe(delay(0))
      .subscribe(() => {
        this._changePage(0);
      });
  }

  ngOnDestroy(): void {
    this._ngDestroy.next();
    this._ngDestroy.complete();
    this.stepper = null;
  }
}
