import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SolverFooterComponent } from './solver-footer.component';
import { TemplateDatepickerModule } from '@app-modeleditor/components/template-datepicker/template-datepicker.module';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';

@NgModule({
  imports: [CommonModule, TranslateModule, MatTooltipModule, MatProgressSpinnerModule, TemplateDatepickerModule],
  exports: [SolverFooterComponent],
  declarations: [SolverFooterComponent],
})
export class SolverFooterModule {}
