import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { PipeModule } from '../../../shared/pipes/pipe.module';
import { MySharedModule } from '../../../shared/shared.module';
import { TableViewComponent } from './table-view.component';

@NgModule({
  imports: [TranslateModule, PipeModule, MySharedModule],
  declarations: [TableViewComponent],
  exports: [TableViewComponent],
  providers: [],
})
export class TableViewModule {}
