import { GanttCanvasRow, GanttCanvasShift, GanttDataContainer } from './data-structure/data-structure';

/**
 * Helper class for {@link DataHandler} to store various data related to the current data.
 */
export class DataStateStorage {
  private _canvasRowStorage: Map<string, GanttCanvasRow> = new Map<string, GanttCanvasRow>();
  private _canvasRowYStorage: Map<number, string> = new Map<number, string>();

  //
  // UPDATE HANDLING
  //

  /**
   * Updates the data state storage by the given datasets.
   * @param originDataSet Origin dataset to update the data state storage for.
   * @param canvasRowDataSet Canvas row dataset to update the data state storage for.
   * @param canvasShiftDataSet Canvas shift dataset to update the data state storage for.
   */
  public update(
    originDataSet: GanttDataContainer,
    canvasRowDataSet: GanttCanvasRow[],
    canvasShiftDataSet: GanttCanvasShift[]
  ): void {
    // update origin data
    // if (originDataSet) this._updateOriginData(originDataSet);
    // update canvas row data
    if (canvasRowDataSet) this._updateCanvasRowData(canvasRowDataSet);
    // update canvas shift data
    // if (canvasShiftDataSet) this._updateCanvasShiftData(canvasShiftDataSet);
  }

  /**
   * Updates the data state storage for canvas rows by the given dataset.
   * @param canvasRowDataSet Canvas row dataset to update the data state storage for.
   */
  private _updateCanvasRowData(canvasRowDataSet: GanttCanvasRow[]): void {
    // clear current data
    this._canvasRowStorage.clear();
    this._canvasRowYStorage.clear();

    // add new data
    for (const row of canvasRowDataSet) {
      this._canvasRowStorage.set(row.id, row);

      for (let y = row.y; y < row.y + row.height; y++) {
        this._canvasRowYStorage.set(y, row.id);
      }
    }
  }

  //
  // CANVAS ROW STATE STORAGE
  //

  /**
   * Returns the canvas row with the specified id (or `undefined` if there is no canvas row with the specified id).
   * @param rowId Id of the canvas row to be returned.
   * @returns Canvas row with the specified id (or `undefined` if there is no canvas row with the specified id).
   */
  public getCanvasRowById(rowId: string): GanttCanvasRow {
    return this._canvasRowStorage.get(rowId);
  }

  /**
   * Determines the canvas row at the specified y position (or `undefined` if there is no canvas row at the specified y position).
   * @param y Y position to determine the canvas row for.
   * @returns Canvas row at the specified y position (or `undefined` if there is no canvas row at the specified y position).
   */
  public getCanvasRowByYPosition(y: number): GanttCanvasRow {
    const yPos = Math.max(Math.floor(y), 0);
    const rowId = this._canvasRowYStorage.get(yPos);
    return this.getCanvasRowById(rowId);
  }

  /**
   * Determines the id of the canvas row at the specified y position (or `undefined` if there is no canvas row at the specified y position).
   * @param y Y position to determine the id of the canvas row for.
   * @returns Id of the canvas row at the specified y position (or `undefined` if there is no canvas row at the specified y position).
   */
  public getCanvasRowIdByYPosition(y: number): string {
    return this.getCanvasRowByYPosition(y)?.id;
  }
}
