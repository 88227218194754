import { Injectable } from '@angular/core';
import { MenuItem } from 'frontend/src/dashboard/view/template-toolbar/menu-item';
import { Observable, Subject } from 'rxjs';

/**
 * Optional service which handles the registration of indicators to the gantt toolbar.
 */
@Injectable({
  providedIn: 'root',
})
export class GanttToolbarIndicatorService {
  private readonly _indicatorIdentifier = 'GanttToolbarIndicatorService_IndicatorIdentifier';

  private _indicatorRegistrations: Map<string, IGanttToolbarIndicatorRegistration[]> = new Map();
  private _newMenuItems: MenuItem[] = [];
  private _deletedMenuItems: MenuItem[] = [];

  private _onRegistrationDataChangeSubject: Subject<string> = new Subject();

  constructor() {}

  /**
   * Adds a new indicator registration to the specified entry element id in the specified menu item.
   * @param registrationId Registration id to register indicator for.
   * @param menuItem Menu item to register indicator for.
   * @param elementId Entry element id to register indicator for.
   */
  public addIndicatorRegistration(registrationId: string, menuItem: MenuItem, elementId: string): void {
    if (!this._indicatorRegistrations.get(registrationId)) this._indicatorRegistrations.set(registrationId, []);
    const indicatorRegistrations = this._indicatorRegistrations.get(registrationId);

    // add to existing menu item if possible
    for (const registration of indicatorRegistrations) {
      if (registration.menuItem.getId() === menuItem.getId()) {
        const foundId = registration.elementIds.find((id) => id === elementId);
        const foundIdIndex = registration.elementIds.indexOf(foundId);
        if (foundIdIndex < 0) {
          registration.elementIds.push(elementId);
          this._newMenuItems.push(registration.menuItem);
        }
        this._indicatorRegistrations.set(registrationId, indicatorRegistrations);
        this._onRegistrationDataChangeSubject.next(registrationId);
        return;
      }
    }
    // else: create new registration entry
    indicatorRegistrations.push({
      menuItem: menuItem,
      elementIds: [elementId],
    });
    this._newMenuItems.push(menuItem);
    this._indicatorRegistrations.set(registrationId, indicatorRegistrations);
    this._onRegistrationDataChangeSubject.next(registrationId);
  }

  /**
   * Removes all indicator registrations for the specified entry element id.
   * @param registrationId Registration id to remove the registration for.
   * @param elementId Entry element id to remove the registration for.
   */
  public removeIndicatorRegistration(registrationId: string, elementId: string): void {
    if (!this._indicatorRegistrations.get(registrationId)) return;
    const indicatorRegistrations = this._indicatorRegistrations.get(registrationId);

    for (const registration of indicatorRegistrations) {
      const foundElement = registration.elementIds.find((id) => id === elementId);
      if (foundElement) registration.elementIds.splice(registration.elementIds.indexOf(foundElement), 1);
      // remove registration if empty
      if (registration.elementIds.length <= 0) {
        this._deletedMenuItems.push(registration.menuItem);
        indicatorRegistrations.splice(indicatorRegistrations.indexOf(registration), 1);
      }
    }
    this._indicatorRegistrations.set(registrationId, indicatorRegistrations);
    this._onRegistrationDataChangeSubject.next(registrationId);
  }

  /**
   * Applies all changes that have been made since the last update.
   */
  public updateIndicatorRegistrations(): void {
    // remove deleted menu items
    for (const menuItem of this._deletedMenuItems) {
      menuItem.deregisterIndicator(this._indicatorIdentifier);
    }
    this._deletedMenuItems = [];
    // add new menu items
    for (const menuItem of this._newMenuItems) {
      menuItem.registerIndicator(this._indicatorIdentifier);
    }
    this._newMenuItems = [];
  }

  /**
   * An Observable which gets triggered every time the registration data chenges.
   */
  public get onRegistrationDataChange(): Observable<string> {
    return this._onRegistrationDataChangeSubject.asObservable();
  }
}

/**
 * Data structure to keep all entry element ids and their assigned menu item.
 */
export interface IGanttToolbarIndicatorRegistration {
  menuItem: MenuItem;
  elementIds: string[];
}
