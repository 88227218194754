<nn-legend headline="@attributeFilter@">
  <div class="content">
    <div class="node-network-filter-items" *ngIf="itemList.length > 0">
      <div class="node-network-filter-item-wrapper" *ngFor="let item of itemList">
        <div class="node-network-filter-color-item" [style.background]="colorMap[item.id]"></div>
        <div
          class="node-network-filter-label"
          [matTooltipShowDelay]="1000"
          matTooltip="{{ item.name }}"
          (click)="outputSelectedFilterItems(item, $event)"
          [style.opacity]="filteredItemElementsCache[item.id] ? 1 : 0.5"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
    <div class="node-network-filter-select">
      <mat-checkbox (change)="onSelectAll($event)">{{ '@selectAll@' | translate | capitalize }}</mat-checkbox
      ><br />
      <mat-checkbox [(ngModel)]="showColors" (change)="emitOutputItem()">{{
        '@colors@' | translate | capitalize
      }}</mat-checkbox>
    </div>
  </div>
</nn-legend>
