import { Injectable, Type } from '@angular/core';
import { TemplateAdapter } from '@app-modeleditor/utils/template-factory.service';
import { Adapter } from '../../../core/adapter';
import { ContentElementAdapter } from '../structure/content-element-adapter.service';
import { StatusBar } from './status-bar';

@Injectable({
  providedIn: 'root',
})
export class StatusBarAdapter implements Adapter<StatusBar> {
  constructor(private _contentElementAdapter: ContentElementAdapter) {}

  adapt(item: any): StatusBar {
    throw new Error('Method not implemented.');
  }
  inherit?<T extends StatusBar>(type: Type<T>, item: any): T {
    const t: T = this._getData(this._contentElementAdapter.inherit<T>(type, item), item);
    return t;
  }
  inheritFrom?<T extends StatusBar>(scope: TemplateAdapter, type: Type<T>, item: any): T {
    const t: T = this._getData(this._contentElementAdapter.inheritFrom<T>(scope, type, item), item, scope);
    return t;
  }

  applyValues<T>(scope: TemplateAdapter, item: any, values: any): T {
    throw new Error('Method not implemented.');
  }

  private _getData<T extends StatusBar>(elem: T, item: any, scope?: TemplateAdapter): T {
    if (scope) {
      elem.setStatusIcons((item.statusIcons || []).map((icon) => scope.adapt(icon)));
    }
    return elem;
  }
}
