import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { TranslateModule } from '@ngx-translate/core';
import { InfoMenuComponent } from './info-menu.component';

@NgModule({
  imports: [CommonModule, MatIconModule, MatMenuModule, TranslateModule, MatButtonModule],
  declarations: [InfoMenuComponent],
  exports: [InfoMenuComponent],
})
export class InfoMenuModule {}
