import { AutocompleteTextfield } from '@app-modeleditor/components/elements/autocomplete-textfield';
import { EntryElementValue } from '@app-modeleditor/components/entry-collection/entry-element-value';
import { EFieldType } from '@app-modeleditor/components/entry-collection/field-type.enum';
import { takeUntil } from 'rxjs/operators';
import { Gantt_General } from '../../general.gantt.component';
import { GanttEssentialPlugIns } from '../../plugin/e-gantt-essential-plugins';
import { GanttYAxisSearchPlugIn } from '../../plugin/plugin-list/y-axis-search/y-axis-search';

export class YAxisSearchNameElement extends AutocompleteTextfield {
  private typingTimer: NodeJS.Timeout;
  private searchString: string;

  constructor(private scope: Gantt_General) {
    super();
  }

  get(data: any): this {
    // const g: any = this.scope.ganttLibService.bestGantt;
    this.setName('GANTT.attribute-search-y-axis')
      .setId(data.id)
      .setAlwaysEnabled(true)
      .setFieldType(EFieldType.AUTOCOMPLETE_TEXT_FIELD)
      .onChanges((val: EntryElementValue) => {
        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout((_) => this.handleYAxisSearch(val.getValue()), 300);
      })
      .setFreeSelection(true)
      .setInvalidOnNoOptions(true)
      .setValue(new EntryElementValue().setValue(null));

    // register for changes
    this.listenToExternalChanges();
    this.onGetOptions((tag: string) => {
      this.setOptions(this.handleFilterList(tag));
      this.handleYAxisSearch(tag);
    });

    return this;
  }

  private handleFilterList(tag: string) {
    // update suggestions
    const yAxisSearchPlugIn: GanttYAxisSearchPlugIn = this.scope.ganttPluginHandlerService.getEssentialPlugIn(
      GanttEssentialPlugIns.YAxisSearchPlugIn
    );

    return yAxisSearchPlugIn.getArrayOfEntriesByQuery(tag).map((value) => {
      return new EntryElementValue().setValue(value).setId(value).setName(value);
    });
  }

  /**
   * Callback function to execute search.
   * @param data Submenu callback.
   */
  private handleYAxisSearch(inputValue: string): void {
    if (this.searchString === inputValue) {
      return;
    }

    this.searchString = inputValue;

    try {
      const yAxisSearchPlugIn: GanttYAxisSearchPlugIn = this.scope.ganttPluginHandlerService.getEssentialPlugIn(
        GanttEssentialPlugIns.YAxisSearchPlugIn
      );
      yAxisSearchPlugIn.searchRowByTitleHierarchical(inputValue);
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * Updates the value if it gets changed from outside.
   */
  private listenToExternalChanges(): void {
    const yAxisSearchPlugIn: GanttYAxisSearchPlugIn = this.scope.ganttPluginHandlerService.getEssentialPlugIn(
      GanttEssentialPlugIns.YAxisSearchPlugIn
    );

    yAxisSearchPlugIn
      .getSearchTextChangeObservable()
      .pipe(takeUntil(this.scope.onDestroy))
      .subscribe((newText) => this.handleExternalValueChange(newText));
  }

  private handleExternalValueChange(newText: string): void {
    this.scope.ganttLibService.ngZone.run(() => {
      const currentValue: EntryElementValue = this.getValue();
      if (currentValue.getValue() !== newText) {
        currentValue.setValue(newText);
        this.executeChanges();
      }
    });
  }
}
