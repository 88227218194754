<div class="saxms-sidebar-wrapper" [class.fullscreen]="fullscreen" [class.enlarged]="_currentPanel ? true : false">
  <div class="panel-wrapper" [style.width]="45 * panels?.length + 'px'">
    <ng-container *ngFor="let panel of panels">
      <button
        [matTooltip]="panel.title | translate"
        mat-button
        class="panel mat-elevation-z4"
        [ngClass]="_currentPanel?.id === panel.id ? 'selected' : ''"
        *ngIf="!panel.hidePanel"
        (click)="setCurrentPanel(panel)"
      >
        <app-image *ngIf="panel.icon; else titleThenIcon" [label]="panel.icon"></app-image>
        <ng-template #titleThenIcon>
          <span class="text">{{ panel.title | translate }}</span>
        </ng-template>
      </button>
    </ng-container>
  </div>
  <div class="content-wrapper mat-elevation-z8 mat-typography">
    <ng-container *ngIf="_currentPanel">
      <div class="panel-content-wrapper" [class.bottom-nav]="bottomNav">
        <div class="panel-content">
          <ng-container *ngTemplateOutlet="categoryTemplate; context: { $implicit: _currentPanel, enlarged: true }">
          </ng-container>
        </div>
      </div>
      <div class="panels-background" *ngIf="bottomNav"></div>
    </ng-container>
  </div>
</div>
