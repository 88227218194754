import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AdministrativeUserService {
  constructor(public http: HttpClient) {}

  /**
   * gets admini user
   * @returns Observable<any>
   */
  get(id?: string, extra?: string): Observable<any> {
    const fId = id ? '/' + id : '';
    const fExtra = extra ? '?' + encodeURI(extra) : '';
    return this.http.get(`rest/administrative/user/${fId ? fId : ''}${fExtra ? fExtra : ''}`);
  }
  /**
   * get list size
   * @param extra any
   * @returns Observable<any>
   */
  getListSize(extra?: string): Observable<any> {
    const fExtra = extra ? '?' + encodeURI(extra) : '';
    return this.http.get(`rest/administrative/user/list/size${fExtra}`);
  }

  /**
   * deletes admin user
   * @param id string
   * @returns Observable<any>
   */
  delete(id: string): Observable<any> {
    return this.http.delete(`rest/administrative/user/${id}`);
  }

  /**
   * edit the admin user
   * @param id string
   * @param body any
   * @returns Observable<any>
   */
  create(id: string, body: any): Observable<any> {
    return this.http.put(`rest/administrative/user/${id}`, body);
  }

  /**
   * edit the admin user
   * @param id string
   * @param body any
   * @returns Observable<any>
   */
  public edit(id: string, body: any): Observable<any> {
    return this.http.post(`rest/administrative/user/${id}`, body);
  }
}
