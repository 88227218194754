import { GanttDataInput } from 'frontend/src/dashboard/gantt/gantt/saxms-best-gantt.handler.component';

/**
 * Stroage for mapped gantt data,
 */
export class GanttDataStorage {
  private _ganttInputData: GanttDataInput;

  public get ganttInputData(): GanttDataInput {
    return this._ganttInputData;
  }
  public set ganttInputData(value: GanttDataInput) {
    this._ganttInputData = value;
  }
}
