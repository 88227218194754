import { EntryElement } from '@app-modeleditor/components/entry-collection/entry-element';
import { EntryElementValue } from '@app-modeleditor/components/entry-collection/entry-element-value';
import { EFieldType } from '@app-modeleditor/components/entry-collection/field-type.enum';
import { EGanttSubMenuUpdateMessageType } from '@app-modules/saxms-submenu-elements/saxms-submenu.enum';
import {
  IGanttSubMenuSyncMessage,
  IGanttSubMenuUpdateMessage,
} from '@app-modules/saxms-submenu-elements/saxms-submenu.interface';
import { SaxMsBestGanttSettings } from 'frontend/src/dashboard/gantt/gantt/saxms-best-gantt.settings';
import { GlobalUtils } from 'frontend/src/dashboard/global-utils';
import { takeUntil } from 'rxjs/operators';
import { Gantt_General } from '../../general.gantt.component';
import { GanttEssentialPlugIns } from '../../plugin/e-gantt-essential-plugins';
import { GanttEarliestStartLatestEndPlugIn } from '../../plugin/plugin-list/earliest-start-latest-end-visualizer/earliest-start-latest-end-visualizer';
import { EGanttToolbarIdentifier } from '../gantt-toolbar-identifier';

export class ToggleEarliestStartLatestEndElement extends EntryElement {
  private _internalId: string = GlobalUtils.generateUUID();

  constructor(private scope: Gantt_General) {
    super();
  }

  get(data: any): this {
    const initEnabled =
      this.scope.ganttSettingsService.getGanttSettings().enableGanttEarliestStartLatestEnd ||
      (data.entry?.value as boolean) ||
      false;
    const name = (data.name as string) || this.scope.translate.instant('@show-earliest-start-latest-end@');

    this.setFieldType(EFieldType.SLIDE_TOGGLE)
      .setName(name)
      .setAlwaysEnabled(true)
      .setId(EGanttToolbarIdentifier.TOGGLE_GANTT_EARLIEST_START_LATEST_END)
      .setValue(new EntryElementValue().setValue(initEnabled))
      .onChanges((change) => this.handleApplyEarliestStartLatestEnd(change.value, true));

    this.scope.ganttSettingsService
      .onNewSettings()
      .pipe(takeUntil(this.scope.onDestroy))
      .subscribe((settings: SaxMsBestGanttSettings) => {
        if (!settings) {
          return;
        }
        const value = this.scope.ganttSettingsService.getGanttSettings().enableGanttEarliestStartLatestEnd;
        this.handleApplyEarliestStartLatestEnd(value);
        this.setValue(new EntryElementValue().setValue(value));
      });

    this._subscribeToElementStateUpdates();

    return this;
  }

  private handleApplyEarliestStartLatestEnd(bool: boolean, save?: boolean) {
    const value: IGanttSubMenuSyncMessage = {
      internalId: this._internalId,
      value: bool,
    };
    const message: IGanttSubMenuUpdateMessage = {
      elementId: this.getId(),
      type: EGanttSubMenuUpdateMessageType.SYNC,
      value: value,
    };
    this.scope.submenuService.triggerElementById(message); // trigger sync
    const plugin: GanttEarliestStartLatestEndPlugIn = this.scope.ganttPluginHandlerService.getEssentialPlugIn(
      GanttEssentialPlugIns.GanttEarliestStartLatestEndVisualizer
    );
    plugin.getJsPlugin().setActive(bool);

    this.scope.ganttLibService.bestGantt.update();

    if (save) {
      this._saveValue(bool);
    }
  }

  /**
   * Saves the selected value into settings and to backend.
   */
  private _saveValue(value: boolean) {
    this.scope.ganttSettingsService.changeSettings({
      enableGanttEarliestStartLatestEnd: !!value,
    });
    this.scope.ganttSettingsService.saveSettings().subscribe();
  }

  private _subscribeToElementStateUpdates() {
    this.scope.submenuService
      .onUpdateElementState()
      .pipe(takeUntil(this.scope.onDestroy))
      .subscribe((activationData: IGanttSubMenuUpdateMessage) => {
        if (!activationData || activationData.elementId !== this.getId()) {
          return;
        }
        switch (activationData.type) {
          case EGanttSubMenuUpdateMessageType.ACTION:
            break;
          case EGanttSubMenuUpdateMessageType.UPDATE:
            this._syncElement(activationData.value);
            break;
          case EGanttSubMenuUpdateMessageType.SYNC:
            if (activationData.value.internalId === this._internalId) {
              return;
            } // element notifies itself
            this._syncElement(activationData.value.value);
            break;
        }
      });
  }

  /**
   * Synchronizes elements of sub menu with each other.
   */
  private _syncElement(selectedValue: boolean) {
    this.setValue(new EntryElementValue().setValue(!!selectedValue));
  }
}
