import { EGanttInstance, GanttInstanceService } from '@gantt/public-api';
import { IGanttBlock, IGanttDetails } from 'frontend/src/dashboard/gantt/general/generator/gantt-input.data';
import { UtilTooltipMapper } from 'frontend/src/dashboard/gantt/general/generator/mapper/gantt.tooltip.mapper';
import { GanttTemplateData } from 'frontend/src/dashboard/gantt/helper/gantt';

/**
 * Mapper which extracts superblock data by backend data.
 */
export class GanttSuperBlocksMapper {
  /**
   * Extracts super block data and stores it.
   * @param input Backend data.
   * @param JSFactory Factory to prive Javascript fucntionality.
   */
  public getSuperBlocksByBackendData(
    templateData: GanttTemplateData,
    responseData: any,
    JSFactory: GanttInstanceService
  ): SuperBlockData[] {
    const storage: SuperBlockStorage = new SuperBlockStorage();
    JSFactory.getInstance(EGanttInstance.DATA_MANIPULATOR).iterateOverDataSet(
      responseData.hierarchicalPlan.ganttEntries,
      { extractSuperBlockData: this.extractSuperBlockData.bind(this, storage, templateData, responseData) },
      null,
      'children'
    );

    const extractedBlocks = storage.getCopiedData();
    return extractedBlocks;
  }

  /**
   * Extracts super block data by given row.
   * @param child Given gantt child row.
   */
  private extractSuperBlockData(
    storage: SuperBlockStorage,
    templateData: GanttTemplateData,
    responseData: any,
    child: any
  ): void {
    if (!child.blocks) return;

    for (const block of child.blocks) {
      if (
        !block ||
        (block.superBlocks && Object.keys(block.superBlocks).length === 0 && block.superBlocks.constructor === Object)
      )
        continue;

      for (const type in block.superBlocks || []) {
        const superBlock = block.superBlocks[type];
        storage.addSuperBlockShiftData(
          type,
          block.id,
          superBlock,
          this.extractSuperBlockTooltip(superBlock, templateData, responseData),
          this.extractSuperBlockDetails(superBlock, responseData)
        );
      }
    }
  }

  public extractSuperBlockTooltip(superblockId: string, templateData: GanttTemplateData, responseData: any): string {
    const superBlockTooltipInformation = responseData.hierarchicalPlan.superBlockDataViews;
    let tooltip: string;

    if (superBlockTooltipInformation[superblockId]) {
      const tooltipRows = superBlockTooltipInformation[superblockId].details;
      tooltip = UtilTooltipMapper.getBlockTooltipByTemplateData(
        tooltipRows,
        templateData.getSuperBlockAttributeMapping() || templateData.getAttributeMapping(),
        templateData.getDefaultBlockTooltipSettings()
      );
    }
    return tooltip;
  }

  public extractSuperBlockDetails(superblockId: string, responseData: any): IGanttDetails {
    const superBlockTooltipInformation = responseData.hierarchicalPlan.superBlockDataViews;
    let details: IGanttDetails;

    if (superBlockTooltipInformation[superblockId]) {
      details = superBlockTooltipInformation[superblockId].details;
    }
    return details;
  }

  /**
   * Provides superblock data by given block.
   * @param block Given block of which the superblock data should be extracted.
   */
  public getSuperBlockDataByBlock(block: IGanttBlock): SuperBlockData[] {
    const storage: SuperBlockStorage = new SuperBlockStorage();
    if (!block || (Object.keys(block.superBlocks).length === 0 && block.superBlocks.constructor === Object))
      return null;
    for (const type in block.superBlocks) {
      const superBlockId = block.superBlocks[type];
      storage.addSuperBlockShiftData(type, block.id, superBlockId);
    }
    return storage.getCopiedData();
  }

  /**
   * Configures how to show combined components/blocks.
   */
  public getDisplayTypeByBackEndData(plugInData: any): ESuperBlockVisualization {
    if (!plugInData.displayType) return null;
    switch (plugInData.displayType) {
      case 'COMBINED':
        return ESuperBlockVisualization.COMBINED;
      case 'SUPERBLOCK':
        return ESuperBlockVisualization.COMBINED; // same as COMBINED but COMBINED is deprecated
      case 'ARROWS':
        return ESuperBlockVisualization.ARROWS;
      case 'NONE':
        return ESuperBlockVisualization.NONE;
      case 'MIXED':
        return ESuperBlockVisualization.MIXED;
    }
  }
}

/**
 * Storage class which holds super block shift data.
 * This in necessary, because all super block shift data will be removed inside mapped gantt format.
 */
export class SuperBlockStorage {
  private _superBlocks: SuperBlockData[] = [];

  public addSuperBlockShiftData(
    type: string,
    shiftId: string,
    superBlockId: string,
    tooltip?: string,
    details?: IGanttDetails
  ) {
    let matches = false;
    for (const superBlock of this.superBlocks) {
      if (superBlock.matchesSuperBlock(type, superBlockId, shiftId)) {
        matches = true;
        break;
      }
    }
    if (!matches) {
      this.superBlocks.push(new SuperBlockData(type, superBlockId, shiftId, tooltip, details));
    }
  }

  public getCopiedData(): SuperBlockData[] {
    return JSON.parse(JSON.stringify(this._superBlocks));
  }

  public get superBlocks(): SuperBlockData[] {
    return this._superBlocks;
  }
  public set superBlocks(value: SuperBlockData[]) {
    this._superBlocks = value;
  }
}

/**
 * Data structure for one super block group.
 * Bundles information which blocks are inside one group.
 */
export class SuperBlockData {
  public group: string[] = [];
  public type: string;
  public id: string;
  public tooltip: string;
  public details: IGanttDetails;
  constructor(type: string, superBlockId: string, shiftId?: string, tooltip?: string, details?: IGanttDetails) {
    this.type = type;
    this.id = superBlockId;
    if (shiftId) this.addShiftId(shiftId);
    if (tooltip) this.tooltip = tooltip;
    if (details) this.details = details;
  }

  public addShiftId(shiftId): boolean {
    if (this.group.indexOf(shiftId) == -1) {
      this.group.push(shiftId);
      return true;
    }
    return false;
  }

  public equalsType(type: string): boolean {
    return this.type == type;
  }

  public equalsSuperBlockId(superBlockId: string): boolean {
    return this.id == superBlockId;
  }

  public matchesSuperBlock(type, superBlockId, shiftId): boolean {
    if (this.equalsType(type) && this.equalsSuperBlockId(superBlockId)) {
      this.addShiftId(shiftId);
      return true;
    } else return false;
  }
}

/**
 * Visual settings how to show combined blocks.
 */
export enum ESuperBlockVisualization {
  MIXED = 'MIXED',
  SUPERBLOCK = 'SUPERBLOCK',
  COMBINED = 'COMBINED', // same as SUPERBLOCK (deprecated)
  ARROWS = 'ARROWS',
  NONE = 'NONE',
}
