import { TimePeriodFilterItem } from './data-access/time-period-filter-item.interface';
import { MarkedTimePeriod, TimePeriodNoRenderReason } from './executer-timeperiod-marker';
import { GanttTimePeriodGroupExecuter } from './executer-timeperiod-marker-group';

/**
 * Represents a filter for marked time periods in a Gantt chart.
 * Allows adding and removing filters for specific time period intervals,
 * and filtering the marked periods based on the set filters.
 */
export class TimePeriodFilter {
  /**
   * A map of time period filter items, keyed by ganttTimePeriodGroupIntervalInputId of MarkedTimePeriod.
   */
  private filters = new Map<string, TimePeriodFilterItem[]>();
  /**
   * Indicates whether the time period filter is clear (not active) or not.
   */
  private isClear = true;

  constructor(private groupExecuter: GanttTimePeriodGroupExecuter) {}

  /**
   * Adds a new time period filter to the collection of filters for a given interval ID.
   * If a filter with the same ID already exists for the interval, the method does nothing.
   *
   * @param filter - The time period filter to add.
   */
  public addFilter(filter: TimePeriodFilterItem) {
    if (!this.filters.has(filter.intervalId)) {
      // create new array for interval ID
      this.filters.set(filter.intervalId, []);
    } else if (this.filters.get(filter.intervalId).some((f) => f.id === filter.id)) {
      // already added
      return;
    }
    // add filter to array of filters for the interval ID
    this.filters.get(filter.intervalId).push(filter);
  }

  /**
   * Removes a filter from the list of filters for a specific interval.
   * @param filter - The filter to remove.
   */
  public removeFilter(filter: TimePeriodFilterItem) {
    // If the interval ID is not in the map, there are no filters for this interval ID.
    if (!this.filters.has(filter.intervalId)) {
      return;
    }
    // remove filter from array of filters for the interval ID
    this.filters.set(
      filter.intervalId,
      this.filters.get(filter.intervalId).filter((f) => f.id !== filter.id)
    );

    // If the array of filters for the interval ID is now empty, remove the interval ID from the map.
    if (this.filters.get(filter.intervalId).length === 0) {
      this.filters.delete(filter.intervalId);
    }
  }

  /**
   * Filters the marked periods based on the filters set for each interval.
   * If a period matches a filter, it is hidden from the Gantt chart.
   * If a period does not match any filters, it is shown on the Gantt chart.
   */
  public filter() {
    if (!this.needsToBeFiltered()) {
      return;
    }

    Object.values(this.groupExecuter.getAllIntervals()).forEach((executer) => {
      const intervalId = executer.additionalData?.ganttTimePeriodGroupIntervalInputId;
      const filters = this.filters.get(intervalId) || [];

      executer.getAllMarkedPeriods().forEach((period) => {
        if (this.doesFilterMatch(filters, period)) {
          executer.getMarkedPeriodBuilder().addToHiddenAreas(period.id, TimePeriodNoRenderReason.FILTERED_OUT);
        } else {
          executer.getMarkedPeriodBuilder().removeFromHiddenAreas(period.id, TimePeriodNoRenderReason.FILTERED_OUT);
        }
      });
    });
  }

  /**
   * Determines whether the time period filter needs to be applied.
   * @returns {boolean} True if the filter needs to be applied, false otherwise.
   */
  private needsToBeFiltered(): boolean {
    if (this.isClear && this.filters.size === 0) {
      // nothing to do
      return false;
    } else if (this.isClear && this.filters.size > 0) {
      this.isClear = false; // mark as dirty
    } else if (!this.isClear && this.filters.size === 0) {
      this.isClear = true;
    }
    return true;
  }

  /**
   * Checks if a given time period matches any of the provided filters.
   * @param filters - An array of TimePeriodFilterItem objects to match against.
   * @param period - The MarkedTimePeriod object to check for matches.
   * @returns A boolean indicating whether the period matches any of the filters.
   */
  private doesFilterMatch(filters: TimePeriodFilterItem[], period: MarkedTimePeriod): boolean {
    return filters.some((filter) => {
      if (period?.additionalDetails && period.additionalDetails[filter.attributeKey]?.t2 === filter.attributeValue) {
        return true;
      }
    });
  }
}
