<saxms-lightbox>
  <div class="headline">
    {{ '@nodenet_options@' | translate | capitalize }}
  </div>
  <div class="content">
    <div *ngFor="let part of interface.parts" class="generic-options-input-wrapper">
      <mat-form-field class="generic-options-input">
        <mat-select [(ngModel)]="settings[part.id]" placeholder="{{ part.name }}">
          <mat-option *ngFor="let entry of part.entries" [value]="entry.id">
            {{ entry.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="actionbarBottom">
    <button mat-button (click)="accept()">
      {{ '@save@' | translate }}
    </button>
    <button mat-button (click)="cancel()">
      {{ '@cancel@' | translate }}
    </button>
  </div>
</saxms-lightbox>
