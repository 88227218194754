<!-- new concept for layout driven contents-->
<!-- <button (click)="test()">test</button> -->
<ng-container *ngIf="nt">
  <core-layout
    [positionMarker]="hasPositionMarker"
    [ngClass]="['core-layout', nt.getDisplayOrientation() || '']"
    [style.height]="!nt.getLayout() && nt.getResizeMode() === 'FIT_PARENT' ? 'calc(100% - 1px)' : 'unset'"
    [entryCollection]="nt"
    [elementRef]="elementRef"
    [resourceId]="resourceId"
    [editmode]="editmode"
    [disabled]="disabled"
    [class.prevent-new-line]="nt.isPreventNewLines() === true"
    [layout]="nt.getLayout()"
    [templates]="nt.getEntryElements()"
    #elementRef
  >
    <ng-template #contentElement let-template let-numElements="numElements">
      <ng-container
        *ngTemplateOutlet="
          collection;
          context: { $implicit: template, template: nt, layoutElement: elementRef, numElements: numElements }
        "
      >
      </ng-container>
    </ng-template>
  </core-layout>
</ng-container>
<ng-template #collection let-el let-template="template" let-layoutElement="layoutElement" let-numElements="numElements">
  <div
    #entryElement
    [ngClass]="[
      'entry-element-wrapper',
      nt.getDisplayOrientation() || '',
      nt.getResizeMode() || '',
      el.getWidth() ? 'own-width' : '',
      el.hasNoPadding() ? 'no-padding' : '',
      el.isSticky() && el.isQuickSearchElement() ? 'is-sticky' : '',
      el.isQuickSearchElement() ? 'is-quick-search-element' : ''
    ]"
    *ngIf="getVisibility(el)"
    [class.selected]="el.isSelected()"
    [style.height]="getElementHeight(numElements)"
    [class.is-on-the-left]="!el.getPosition() || el.getPosition() === 'left'"
    [class.is-on-the-right]="el.getPosition() === 'right'"
    [style.width]="el.getWidth() ? el.getWidth() + 'px !important' : 'foo'"
    [style.left]="el.isSticky() && el.isQuickSearchElement() ? el.getStickyLeft() + 'px' : ''"
  >
    <drag-n-drop [template]="el" [disabled]="!editmode">
      <entry-element
        (contextmenu)="onClick($event, el, true)"
        (click)="onClick($event, el)"
        [resourceId]="resourceId"
        [disabled]="isDisabled(el)"
        (valueChange)="onChanges($event)"
        [highlightedKey]="highlightedKey"
        [el]="el"
        [template]="template"
        [layoutElement]="layoutElement"
      >
      </entry-element>
    </drag-n-drop>
  </div>
</ng-template>
