import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '@core/authentication/auth.service';
import { LanguageService } from 'frontend/src/dashboard/core/provider/language.service';
import { Tenant } from 'frontend/src/dashboard/view/template-footer/default-footer-components/tenant-footer/tenant';
import { environment } from 'frontend/src/environments/environment';
import { BehaviorSubject, Observable, Observer, of, Subject } from 'rxjs';
import { map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { TenantAdapter } from '../../view/template-footer/default-footer-components/tenant-footer/tenant-adapter.service';
import { AdministrativeUserService } from './administrative-user.service';
import { User } from '../user';
import { EUserSettingsKey } from '../user-seetings-key.enum';
import { User as IUser } from './user';

export interface PasswordChangeResponse {
  errorList: string[];
  forceChangePassword: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private ngNext: Subject<void> = new Subject<void>();
  public uriUserSettings = 'rest/usersettings/';
  public administrativeUri = 'rest/administrative/user';
  public data: JSON;
  private _userSettings: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public userSettings: any; // current usersettings
  alive = true;

  public _onChangeUser: Observable<any>;
  public _onChangeUserSub: Subject<any>;
  private _user: BehaviorSubject<User> = new BehaviorSubject<User>(null);
  public uri = 'rest/user/';

  constructor(
    public http: HttpClient,
    private auth: AuthenticationService,
    private languageProvider: LanguageService,
    public admin: AdministrativeUserService,
    private tenantAdapter: TenantAdapter
  ) {
    this._onChangeUserSub = new Subject<any>();
    this._onChangeUser = this._onChangeUserSub.asObservable();
  }

  /**
   * change the password of the current user
   * @param {string} oldPassword current password
   * @param {string} newPassword new password
   * @returns Observable<any>
   */
  public changePassword(oldPassword: string, newPassword: string): Observable<PasswordChangeResponse> {
    return this.http.post<PasswordChangeResponse>('rest/user/changepassword/', {
      oldPassword,
      newPassword,
    });
  }

  public refreshUsersettings(disableReload = false): void {
    this._getUserSettings().subscribe((settings: IUserSettings) => {
      settings[0].disableReload = disableReload;
      this.setUserSettings(settings[0]);
    });
  }

  // private members
  /**
   * getting settings entry
   * @param category category
   * @param x type
   */
  private _getSettingsEntries(category: any, x: string): any {
    for (const entry of category.settingsEntries) {
      for (const type of entry.settingsEntryType) {
        if (type == x) {
          return entry;
        }
      }
    }
    return null;
  }

  public fetchUser(): Observable<IUser> {
    return this.http.get<IUser[]>(`rest/user`).pipe(
      map((u) => (u?.length > 0 ? u[0] : null)),
      tap((u) => {
        const user = this.parseUser(u);
        this.languageProvider.setLang(user.getLanguage());
        this._user.next(user);
        // initially get usersettings
        this.refreshUsersettings();
      })
    );
  }

  private parseUser(user: Record<string, any>): User {
    const u: User = new User();
    u.setName(user.name)
      .setId(user.id)
      .setFirstname(user.firstname)
      .setFullname(user.fullname)
      .setTenantId(user.tenantId)
      .setTenant(this.tenantAdapter.inherit(Tenant, user.tenant))
      .setLanguage(user.Language);

    // set main tenant
    if (user.tenenat) {
      u.setTenant(this.tenantAdapter.adapt(user.tenant));
    }

    environment.user = u;
    return u;
  }

  private _insertValueForType(key: string, value: string): Observable<any> {
    return this.getValueByType(key).pipe(
      map((entry) => {
        entry.settingsValue.value = value;
        return this.userSettings;
      })
    );
  }

  public getUserSettings(): Observable<IUserSettings> {
    return this._userSettings.asObservable();
  }

  /**
   * start gettings value
   * @param x type
   */
  private _startGettingValues(x: string): void {
    let result;
    for (const categ of this.userSettings.settingsCategories) {
      result = this._getSubSettingsCategory(categ, x);
      if (result) {
        return result;
      }
    }
    return null;
  }

  /**
   * getting subsettings categories
   * @param category category
   * @param x type
   * @returns void
   */
  private _getSubSettingsCategory(category: any, x: string): void {
    let result = this._getSettingsEntries(category, x);
    if (result) {
      return result;
    }

    for (const subCat of category.subSettingsCategories) {
      result = this._getSubSettingsCategory(subCat, x);
      if (result) {
        return result;
      }
    }

    return null;
  }

  // public members
  /**
   * get subscription to user
   * @returns Observable<IUser>
   */

  public getUser(): Observable<User> {
    return this._user.asObservable();
  }

  /**
   * updates usersettings
   * @param key string
   * @param value any
   */
  public updateUserSettings(key: string, value: any): Observable<IUserSettings> {
    return this._insertValueForType(key, value).pipe(
      switchMap((usersettings: IUserSettings) => {
        return this.saveUserSettings(usersettings.id, usersettings);
      })
    );
  }

  public setKey(settings: IUserSettings, key: EUserSettingsKey, value: any, skip?: boolean): Observable<IUserSettings> {
    if (!settings) {
      return of(undefined);
    }
    if (settings.keyValueStore) {
      settings.keyValueStore[key] = JSON.stringify(value);
    }
    return this.saveUserSettings(settings.id, settings, skip);
  }

  public getKey(settings: IUserSettings, key: EUserSettingsKey): Observable<any> {
    try {
      return of(JSON.parse(settings.keyValueStore[key]) || null);
    } catch (e) {
      return of(null);
    }
  }

  /**
   * get values by type
   */
  public getValueByType(x: string, forceUpdate?: boolean): Observable<any> {
    return new Observable<any>((observer: Observer<any>) => {
      if (this.userSettings && !forceUpdate) {
        observer.next(this._startGettingValues(x));
        observer.complete();
      } else {
        this.getUserSettings()
          .pipe(takeUntil(this.ngNext))
          .subscribe((settings) => {
            if (!settings) {
              return;
            }
            this.userSettings = settings;
            this.ngNext.next();
            observer.next(this._startGettingValues(x));
            observer.complete();
          });
      }
    });
  }

  public postRolesToUser(id: string, body: any): Observable<any> {
    return this.http.post(`rest/user/${id}/role/update`, body);
  }

  public getFunctionPermission(id: string): Observable<any> {
    return this.http.get(`rest/permission/user/functionpermission`);
  }

  /**
   * creates user
   * @param id string
   * @param body any
   */
  public createUser(id: string, body: any): Observable<any> {
    return this.http.put(`rest/user/${id}`, body);
  }

  public getOptionsPermissions(): Observable<any> {
    return this.http.get(`rest/user/permission`);
  }

  /**
   * reactivates blocked account
   * @param user IUser
   */
  public reactiveUser(user: User): Observable<any> {
    const body = {
      userID: user.getId(),
      newPassword: user.getPassword(),
      email: user.getEmail(),
    };

    return this.http.post(`${this.uri}reactivate`, body);
  }

  getValue(key: string): string {
    return '';
  }

  public load(): void {}

  /**
   * set user-settings
   * @param settings any
   * @returns void
   */
  public setUserSettings(settings: IUserSettings): void {
    this._userSettings.next(settings);
  }

  private _getUserSettings(): Observable<IUserSettings> {
    return this.http.get(`rest/usersettings/`).pipe(map((settings: IUserSettings) => settings));
  }

  public saveUserSettings(id: string, usersettings: IUserSettings, skip?: boolean): Observable<IUserSettings> {
    return of(null).pipe(
      switchMap(() => {
        return this.http.post(`rest/usersettings/${id}`, usersettings).pipe(
          map((settings: IUserSettings) => {
            if (!skip) {
              this.setUserSettings(settings);
            }
            return settings;
          })
        );
      })
    );
  }

  // protected member
}

export interface IUserSettings {
  id: string;
  settingsCategories: ISettingsCategory[];
  user: User;
  keyValueStore: { [key: string]: string };
}

export interface ISettingsCategory {
  id: string;
  isUsed: boolean;
  name: string;
  description: string;
  settingsEntries: ISettingsEntry[];
}

export interface ISettingsEntry {
  id: string;
  label: string;
  settingsValue: ISettingsVale;
}

export interface ISettingsVale {
  id: string;
  value: any;
}
