import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MySharedModule } from 'frontend/src/dashboard/shared/shared.module';
import { MapModule } from '../map/map.module';
import { TemplateDatepickerModule } from '../template-datepicker/template-datepicker.module';
import { LocationMapComponent } from './location-map.component';

@NgModule({
  imports: [CommonModule, MySharedModule, TemplateDatepickerModule, MapModule],
  declarations: [LocationMapComponent],
  exports: [LocationMapComponent],
})
export class LocationMapModule {}
