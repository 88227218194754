import { Injectable, Type } from '@angular/core';
import { EntryElementFactory } from '@app-modeleditor/components/entry-collection/entry-factory.service';
import { ResourceAdapter } from 'frontend/src/dashboard/model/resource/resource-adapter.service';
import { EntryElementValue } from './../../entry-collection/entry-element-value';
import { PieChartEntry } from './pie-chart-entry';

@Injectable({
  providedIn: 'root',
})
export class ChartFactoryService {
  constructor(private resourceAdapter: ResourceAdapter) {}

  private getValue(scope: EntryElementFactory, data: Record<string, any>): EntryElementValue {
    return scope.parseEntryValue(EntryElementValue, data);
  }

  private getPieChartEntry(scope: EntryElementFactory, data: Record<string, any>): PieChartEntry {
    const entry: PieChartEntry = new PieChartEntry();
    if (!data) {
      return entry;
    }

    entry
      .setDescriptions((data.descriptions || []).map((d) => this.getValue(scope, d)))
      .setLegendValues((data.legendValues || []).map((d) => this.getValue(scope, d)));

    return entry;
  }

  public inheritFrom<T extends EntryElementValue>(
    type: Type<T>,
    scope: EntryElementFactory,
    data: Record<string, any>
  ): EntryElementValue {
    return this.resourceAdapter.inherit<T>(type, data).setValue(this.getPieChartEntry(scope, data.value));
  }
}
