import { Layout } from '../layout';
import { ELayoutType } from '../layout-type.enum';

interface ILayoutColumnWidths {
  [key: string]: number;
}
export interface ILayoutColumnHeights {
  [key: string]: number[];
}
interface ILayoutContent {
  [key: string]: string[];
}

export class ColumnLayout extends Layout {
  private columnCount: number;
  private columnWidths: ILayoutColumnWidths;
  private content: ILayoutContent;
  private columnHeights: ILayoutColumnHeights; // top
  private resizeable: boolean;

  /**
   * is Layout resizeable
   * @returns boolean resizeable
   */
  public isResizeable(): boolean {
    return typeof this.resizeable === 'boolean' ? this.resizeable : false;
  }

  /**
   * set Layout is resizeable
   * @param resizeable
   * @returns this
   */
  public setResizeable(resizeable: boolean): this {
    this.resizeable = resizeable;
    return this;
  }

  constructor() {
    super();
    this.setLayoutType(ELayoutType.COLUMN_LAYOUT);
  }

  /**
   * get column count
   * @returns number
   */
  public getColumnCount(): number {
    return this.columnCount;
  }

  /**
   * set column count
   * @param columnCount number
   * @returns this
   */
  public setColumnCount(columnCount: number): this {
    this.columnCount = columnCount;
    return this;
  }

  /**
   * get column widths
   * @returns ILayoutColumnWidths
   */
  public getColumnWidths(): ILayoutColumnWidths {
    return this.columnWidths;
  }

  /**
   * get column width at given index
   * @param index number
   * @returns ILayoutColumnWidths
   */
  public getColumnWidthAtIndex(index: number): number {
    return this.columnWidths ? this.columnWidths[index] : null;
  }

  /**
   * set column widths
   * @param columnWidths ILayoutColumnWidths
   * @returns this
   */
  public setColumnWidths(columnWidths: ILayoutColumnWidths): this {
    this.columnWidths = columnWidths;
    return this;
  }

  /**
   * get column heights
   * @returns ILayoutColumnHeights
   */
  public getColumnHeights(): ILayoutColumnHeights {
    return this.columnHeights;
  }

  /**
   * get column height at given column index at row index
   * @param index number
   * @returns ILayoutColumnHeights
   */
  public getColumnHeightAtIndex(colIndex: number, rowIndex: number): number {
    let gridHeight = 1;
    if (this.columnHeights) {
      if (this.columnHeights[colIndex]) {
        gridHeight = this.columnHeights[colIndex][rowIndex] ? this.columnHeights[colIndex][rowIndex] : 1;
      }
    }
    return gridHeight;
  }

  /**
   * set column heights
   * -> each column has multiple heights
   *    for each element in the row
   * @param columnWidths ILayoutColumnHeights
   * @returns this
   */
  public setColumnHeights(columnHeights: ILayoutColumnHeights): this {
    this.columnHeights = columnHeights;
    return this;
  }

  /**
   * get content
   * @returns ILayoutContent
   */
  public getContent(): ILayoutContent {
    return this.content;
  }

  /**
   * set content
   * @param content ILayoutContent
   * @returns this
   */
  public setContent(content: ILayoutContent): this {
    this.content = content;
    return this;
  }

  /**
   * get max number of rows from all columns
   * @returns number array
   */
  public getMaxRowCount(): number {
    let maxCount = 0;

    for (let index = 0; index < this.getColumnCount(); index++) {
      const row = this.content[index] ? this.content[index] : new Array<string>();
      maxCount = row.length > maxCount ? row.length : maxCount;
    }

    return maxCount;
  }
}
