<div [ngClass]="styleRef">
  <div class="dialog-title-wrapper">
    <div mat-dialog-title>
      <h2 mat-dialog-title>{{ text | translate | capitalize }}</h2>
    </div>
  </div>
  <mat-dialog-content> </mat-dialog-content>
  <mat-dialog-actions>
    <button [id]="'saxms-dialog-cancel'" mat-button (click)="cancel()">{{ 'cancel' | translate | capitalize }}</button>
    <button [id]="'saxms-dialog-save-all'" mat-button color="primary" *ngIf="all" (click)="saveAll()">
      {{ 'all' | translate | capitalize }}
    </button>
    <button [id]="'saxms-dialog-save-selected'" mat-button color="primary" *ngIf="selected" (click)="saveSelected()">
      {{ 'selected' | translate | capitalize }}
    </button>
    <button [id]="'saxms-dialog-discard'" mat-button color="warn" *ngIf="discard" (click)="discardAll()">
      {{ 'discard' | translate | capitalize }}
    </button>
  </mat-dialog-actions>
</div>
