import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { TranslateModule } from '@ngx-translate/core';
import { SaxmsTooltipModule } from '../tooltip/tooltip.module';
import { DialogContentModule } from './content/dialog-content.module';
import { LightboxComponent } from './lightbox.component';

@NgModule({
  imports: [CommonModule, MatDialogModule, DialogContentModule, DragDropModule, SaxmsTooltipModule, TranslateModule],
  declarations: [LightboxComponent],
  exports: [LightboxComponent],
})
export class LightboxModule {}
