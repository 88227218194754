<div class="colorPickerContainer">
  <!--<p-colorPicker inline="true" [ngModel]="widget.chartColor" (onChange)="onChangeColor($event)"></p-colorPicker>
    -->
  <div>
    <button class="actionBtn" mat-button (click)="onClose(true)">
      {{ 'ok' | translate }}
    </button>
    <button class="actionBtn" mat-button (click)="onClose(false)">
      {{ 'cancel' | translate }}
    </button>
  </div>
</div>
