import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'virtual-option',
  templateUrl: './option.component.html',
  styleUrls: ['./option.component.scss'],
})
export class MultiSelectorVirtualOptionComponent {
  @Input() option: any;
  @Input() selected: boolean;
  @Input() name: string;
  @Input() multi: boolean;
  @Input() color: string;
  @Input() textColor: string;
  @Output() afterClick: EventEmitter<any> = new EventEmitter<any>();
}
