import { EConnectionType } from 'frontend/src/dashboard/gantt/general/toolbar/filter/lightbox/connection-type.enum';
import { IFilterNode } from 'frontend/src/dashboard/gantt/general/toolbar/filter/lightbox/filter-tree';
import { EFilterNodeViewerElementType } from './filter-node-viewer-element-types.enum';
import { IFilterNodeViewerData } from './filter-node-viewer.interface';
import { IGanttBlockAttributeData } from 'frontend/src/dashboard/gantt/general/toolbar/filter/lightbox/attribute-data.interface';
import { EGanttBlockAttributeDataType } from 'frontend/src/dashboard/gantt/general/toolbar/filter/lightbox/attribute-data-types.enum';
import { EFilterOperator } from '../filter-element/filter-operator.enum';
import { GanttRowAdditionalDataMapper } from 'frontend/src/dashboard/gantt/general/generator/mapper/gantt-row-additional-data.mapper';

/**
 * Converts a given filter node into node viewer format.
 */
export class FilterNodeToViewerConverter {
  // Object of comparator signs to be displayed
  private comparatorSigns = {
    begins_with: 'beginnt mit',
    between: 'zwischen',
    by_value: 'hat den Wert',
    contains: 'enthält',
    empty: 'ist leer',
    ends_with: 'endet auf',
    eq: '=', // Equal
    gt: '>', // Greater than
    gte: '≥', // Greater than or equal
    lt: '<', // Less than
    lte: '≤', // Less than or equal
    none: 'none', // None(no filter)
    not_between: 'nicht zwischen',
    not_contains: 'enthält nicht',
    not_empty: 'nicht leer',
    neq: '≠', // Not equal
  };

  private attributeData: IGanttBlockAttributeData[] = [];

  /**
   * Converts a given filter node into node viewer format.
   * @param node Filter node to be converted
   */
  public convertNodeToViewerFormat(node: IFilterNode, attributes: IGanttBlockAttributeData[]): IFilterNodeViewerData[] {
    if (!node || !attributes) {
      return null;
    }
    this.attributeData = attributes;
    return this._iterateOverCondition([node], EConnectionType.AND, true, 0);
  }

  /**
   * Iterates over filter node recursively an generates a string of the given filter node.
   * @param conArray Array of filter nodes
   * @param connectionType Current connection type of filter node condition
   * @param first Is this the first condition? Relevant for first condition without brackets.
   */
  private _iterateOverCondition(
    conArray: IFilterNode[],
    connectionType: EConnectionType,
    first: boolean,
    level: number
  ): IFilterNodeViewerData[] {
    let viewerData: IFilterNodeViewerData[] = [];
    const firstCopy = !!first;
    const nextLevel = level + 1;

    if (conArray.length > 1) {
      if (!firstCopy) {
        viewerData.push({ type: EFilterNodeViewerElementType.BRACKET, level: level, value: '(' });
      } else {
        first = false;
      }
    }

    conArray.forEach((con, index) => {
      if (!Array.isArray(con)) {
        // its a connection
        if (con?.OR) {
          viewerData = [...viewerData, ...this._iterateOverCondition(con.OR, EConnectionType.OR, first, nextLevel)];
        }
        if (con?.AND) {
          viewerData = [...viewerData, ...this._iterateOverCondition(con.AND, EConnectionType.AND, first, nextLevel)];
        }
      } else {
        // its a condition
        viewerData = [...viewerData, ...this._getSingleConditionAsNodeViewerData(con, level)];
      }
      // check results by connection type
      if (conArray.length - 1 != index) {
        if (connectionType === EConnectionType.OR) {
          viewerData.push({ type: EFilterNodeViewerElementType.CONNECTION, level: level, value: 'ODER' });
        } else {
          viewerData.push({ type: EFilterNodeViewerElementType.CONNECTION, level: level, value: 'UND' });
        }
      }
    });
    if (conArray.length > 1 && !firstCopy) {
      viewerData.push({ type: EFilterNodeViewerElementType.BRACKET, level: level, value: ')' });
    }
    return viewerData;
  }

  /**
   * Generates a node viewer data array of a given filter node condition.
   * @param condition Single filter node condition
   */
  private _getSingleConditionAsNodeViewerData(condition: string[], level: number): IFilterNodeViewerData[] {
    if (condition.length != 4) return []; // return if condition not complete

    // extract attribute, comparator and value of condition
    const attribute = { type: EFilterNodeViewerElementType.ATTRIBUTE, level: level, value: condition[0] };
    const comparator = {
      type: EFilterNodeViewerElementType.COMPARATOR,
      level: level,
      value: this.comparatorSigns[condition[1]],
    };
    const result = { type: EFilterNodeViewerElementType.VALUE, level: level, value: this.getConditionValue(condition) };

    return [attribute, comparator, result];
  }

  /**
   * Returns the condition value by comparator and attribute type.
   */
  private getConditionValue(condition: string[]): string {
    const comparatorType = EFilterOperator[condition[1]];
    const attributeType = this.getTypeOfAttribute(condition[0]);

    if (!attributeType) {
      return '';
    }

    let conditionValue = '';
    if (attributeType == EGanttBlockAttributeDataType.DATE) {
      conditionValue = GanttRowAdditionalDataMapper.getDateStrig(condition[2]);
    } else if (attributeType == EGanttBlockAttributeDataType.TIME) {
      conditionValue = GanttRowAdditionalDataMapper.getTimeString(condition[2], true);
    } else if (attributeType == EGanttBlockAttributeDataType.DATE_TIME) {
      conditionValue = this.getDateString(new Date(condition[2]));
    } else if (attributeType == EGanttBlockAttributeDataType.BOOLEAN) {
      conditionValue = condition[2] ? 'wahr' : 'falsch';
    } else {
      conditionValue = condition[2];
    }

    if (comparatorType === EFilterOperator.between || comparatorType === EFilterOperator.not_between) {
      if (attributeType == EGanttBlockAttributeDataType.DATE) {
        conditionValue += ' - ' + GanttRowAdditionalDataMapper.getDateStrig(condition[3]);
      } else if (attributeType == EGanttBlockAttributeDataType.TIME) {
        conditionValue += ' - ' + GanttRowAdditionalDataMapper.getTimeString(condition[3], true);
      } else if (attributeType == EGanttBlockAttributeDataType.DATE_TIME) {
        conditionValue += ' - ' + this.getDateString(new Date(condition[3]));
      } else {
        conditionValue += ' - ' + condition[3];
      }
    }

    if (
      comparatorType === EFilterOperator.none ||
      comparatorType === EFilterOperator.empty ||
      comparatorType === EFilterOperator.not_empty
    ) {
      conditionValue = ''; // no condition value required
    }

    return conditionValue;
  }

  /**
   * Returns the data type of an attribute by the given attribute type.
   */
  private getTypeOfAttribute(attributeName: string): EGanttBlockAttributeDataType {
    const attribute = this.attributeData.find((attribute) => attribute.name === attributeName);
    if (!attribute) {
      return null;
    }
    return attribute.type;
  }

  /**
   * Returns a date as string by a given date object.
   */
  private getDateString(date: Date): string {
    return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()} ${
      date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
    }:${date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()}`;
  }
}
