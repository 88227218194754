import { EGanttInstance } from '@gantt/public-api';
import { GanttLibService } from 'frontend/src/dashboard/gantt/gantt/gantt-lib.service';
import { SaxMsBestGanttActiveSubmenuEntryElementSetting } from 'frontend/src/dashboard/gantt/gantt/saxms-best-gantt.settings';
import { GeneralGanttActionHandler } from 'frontend/src/dashboard/gantt/general/action-handling/action-handler';
import {
  GanttChildren,
  GanttHierarchicalPlanData,
} from 'frontend/src/dashboard/gantt/general/generator/gantt-input.data';
import { GanttTemplateDataService } from 'frontend/src/dashboard/gantt/general/template-data/gantt-template-data.service';
import { GanttTemplateData } from 'frontend/src/dashboard/gantt/helper/gantt';
import { Observable, of, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ExternalGanttPlugin } from '../../external-plugin';
import { GanttPluginHandlerService } from '../../gantt-plugin-handler.service';

const GanttRowColorizerId = 'gantt-plugin-row-colorizer';

/**
 * PlugIn-Wrapper for GanttRowColorizer.
 * Provides function to colorize specific rows by a given time span.
 */
export class GanttRowColorizerPlugIn extends ExternalGanttPlugin {
  private _listenToDataChangesSubscription: Subscription = null;

  constructor(
    protected _ganttPluginHandlerService: GanttPluginHandlerService,
    protected _ganttLibService: GanttLibService,
    protected _actionHandler: GeneralGanttActionHandler,
    protected _ganttTemplateDataService: GanttTemplateDataService
  ) {
    super(_ganttPluginHandlerService, _ganttLibService, _actionHandler);
  }

  /**
   * Extracts area restrictions from backend data and draws them into gantt.
   * @param templateData
   * @param responseData
   */
  public onInit(templateData: GanttTemplateData, responseData: any): void {
    this.addPlugIn(
      GanttRowColorizerId,
      this._ganttLibService.ganttInstanceService.getInstance(EGanttInstance.ROW_COLORIZER)
    );
    const plugin = this.getPlugInById(GanttRowColorizerId);
    plugin.clearAll();
    this._searchForColorizedRowsRecursive(
      this._ganttTemplateDataService.getTemplateData()?.getHierarchicalPlan()?.ganttEntries
    );
    this._subscribeToDataChanges();
  }

  public onAction(action: any): void {}
  public executeAction(action: any): Observable<any> {
    return of(null);
  }
  public injectSettings(submenuElements: SaxMsBestGanttActiveSubmenuEntryElementSetting[]): void {}

  public onDestroy(): void {
    this._listenToDataChangesSubscription.unsubscribe();
  }

  private _subscribeToDataChanges(): void {
    this._listenToDataChangesSubscription = this._ganttTemplateDataService
      .listenToHierarchicalPlanUpdates()
      .pipe(takeUntil(this._ganttPluginHandlerService.onDestroy))
      .subscribe((hierarchicalPlan: GanttHierarchicalPlanData) => {
        const plugin = this.getPlugInById(GanttRowColorizerId);
        plugin?.clearAll();
        this._searchForColorizedRowsRecursive(hierarchicalPlan?.ganttEntries);
      });
  }

  private _searchForColorizedRowsRecursive(entries: GanttChildren[]) {
    if (!entries || !Array.isArray(entries) || !entries.length) {
      return;
    }
    entries.forEach((entry) => {
      this._handlePlannablePeriods(entry);
      this._handleRowTimeSpans(entry);
      if (entry.children) {
        this._searchForColorizedRowsRecursive(entry.children);
      }
    });
  }

  private _handlePlannablePeriods(entry: GanttChildren): void {
    const plugin = this.getPlugInById(GanttRowColorizerId);
    if (entry.plannablePeriodStart && !isNaN(entry.plannablePeriodStart)) {
      plugin?.addTimeSpanToColorize(entry.id, null, null, entry.plannablePeriodStart);
    }
    if (entry.plannablePeriodEnd && !isNaN(entry.plannablePeriodEnd)) {
      plugin?.addTimeSpanToColorize(entry.id, null, entry.plannablePeriodEnd, null);
    }
  }

  private _handleRowTimeSpans(entry: GanttChildren): void {
    const plugin = this.getPlugInById(GanttRowColorizerId);
    const backGroundSection = entry.colorSections?.BACKGROUND_COLOR;
    // extract background color if its defined in sections with from or to property or with a tooltip
    const isSection =
      backGroundSection?.color && (backGroundSection?.from || backGroundSection?.to || backGroundSection?.tooltip);
    if (isSection) {
      plugin?.addTimeSpanToColorize(
        entry.id,
        backGroundSection.color,
        backGroundSection.from,
        backGroundSection.to,
        backGroundSection.tooltip
      );
    }
  }
}
