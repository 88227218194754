import { ContentElement } from '../content/content-element/content-element';
import { IndexPage } from './index-page';

export class IndexRegister extends ContentElement {
  private indexPages: IndexPage[];
  private tabResizeDynamic: boolean;

  constructor() {
    super();
    this.setPreventUnregister(true);
  }

  public getIndexPages(): IndexPage[] {
    return this.indexPages || [];
  }

  public isTabResizeDynamic(): boolean {
    return typeof this.tabResizeDynamic === 'boolean' ? this.tabResizeDynamic : true;
  }

  public setIndexPages(indexPages: IndexPage[]): this {
    this.indexPages = indexPages;
    return this;
  }
  public addIndexPages(indexPage: IndexPage): this {
    this.indexPages.push(indexPage);
    return this;
  }

  public setTabResizeDynamic(tabResizeDynamic: boolean): this {
    this.tabResizeDynamic = tabResizeDynamic;
    return this;
  }
}
