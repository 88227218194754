import * as d3 from 'd3';
import { Subscription } from 'rxjs';
import { EGanttShiftColorMode } from '../../config/config-data/shift-config.data';
import { ShiftDataFinder } from '../../data-handler/data-finder/shift-data-finder';
import { YAxisDataFinder } from '../../data-handler/data-finder/yaxis-data-finder';
import { GanttCanvasRow, GanttCanvasShift, GanttDataRow } from '../../data-handler/data-structure/data-structure';
import { DataManipulator } from '../../data-handler/data-tools/data-manipulator';
import { GanttShiftTranslationChain } from '../../edit-shifts/shift-translation/shift-translation-chain';
import { GanttYAxisContextMenuEvent } from '../../y-axis/y-axis';
import { GanttShiftsAfterRenderEvent, ShiftBuilder } from '../shift-builder';
import { IRectBoundings, IStrokeData } from '../shift-calculation-strategies/shift-calculation.interface';
import { IShiftClickEvent } from '../shift-events.interface';
import { GanttShiftsBuildingDefault } from './shift-build-default';
import { IGanttShiftsBuilding } from './shift-build-interface';

/**
 * Default shift building using canvas for better performance.
 */
export class GanttShiftsBuildingCanvas implements IGanttShiftsBuilding {
  canvas: d3.Selection<HTMLCanvasElement, undefined, d3.BaseType, undefined> = null;
  shiftDataSet: GanttCanvasShift[] = null;
  private _shiftOverlaySVGBuilder: GanttShiftsBuildingDefault;
  currentSVGShift: GanttCanvasShift = null;
  isMouseOutOfShift = false;
  chainHandler: GanttShiftTranslationChain = null;
  timeout: number = null;
  d3Event: MouseEvent;
  shiftMouseMiss: number;
  lastMoveEvent: MouseEvent;
  private _subscriptions: { [key: string]: Subscription } = {};

  constructor(svgParentNode: d3.Selection<SVGGElement, undefined, d3.BaseType, undefined> = undefined) {
    this._shiftOverlaySVGBuilder = new GanttShiftsBuildingDefault(svgParentNode, true);
  }

  /**
   * @override
   */
  public init(executer: ShiftBuilder): void {
    const s = executer;
    const self = this;
    executer.preloadPatterns();
    this.shiftMouseMiss = Date.now();
    self.chainHandler = s.chainHandler;
    executer.getCanvasInFrontShifts().on('mousemove', self._handleCanvasMouseOver.bind(self, s));
    executer.getCanvasInFrontShifts().on('mouseleave', self._handleCanvasMouseOut.bind(self, s));

    executer.getCanvasInFrontShifts().on('contextmenu', self._handleRowContextMenu.bind(self, s));
    executer.getCanvasInFrontShifts().on('dblclick', self._handleCanvasDoubleClick.bind(self, s));

    this._subscriptions['CanvasReRenderSVGShiftOnUpdate'] = executer
      .onUpdate()
      .subscribe((event) => self._handleCanvasMouseOver(s, event));
  }

  public resizeChange(executer: ShiftBuilder, boolean: boolean) {
    const s = this;
    if (!boolean) {
      // reRender SVG
      s.currentSVGShift = null;
      s._shiftOverlaySVGBuilder.removeAllShifts(executer);
      s._handleCanvasMouseOver(executer, null);
    }
  }

  private _renderSvgOverlay(shiftId, executer: ShiftBuilder): void {
    const s = this;
    const shiftIds = [shiftId].concat(s.chainHandler.getChainedElementsById(shiftId)); // build also chained shifts as SVG for interaction handling
    const hoveredCanvasShifts = ShiftDataFinder.getCanvasShiftsByIds(s.shiftDataSet, shiftIds);
    s.currentSVGShift = hoveredCanvasShifts.find((shift) => shift.id === shiftId);
    s._shiftOverlaySVGBuilder.renderShifts(hoveredCanvasShifts, undefined, executer);
  }

  /**
   * @override
   */
  public renderShifts(
    dataSet: GanttCanvasShift[],
    parentNode: d3.Selection<any, any, null, undefined>,
    executer: ShiftBuilder
  ): void {
    const s = executer;
    const self = this;
    if (!dataSet) return;
    self.shiftDataSet = dataSet;
    self.canvas = executer.getShiftRenderCanvas();
    self.removeCanvasContent(executer);

    const shiftBlock = self.canvas.node().getContext('2d');
    const leaveRightStrokeSideOpen = s.ganttConfig.getShiftStrokeLeaveRightSideOpen();
    let rectBoundings: IRectBoundings, fillColor, strokeData: IStrokeData, opacity, weaken, selected;

    for (let i = 0; i < dataSet.length; i++) {
      const canvasShiftData = dataSet[i];
      const roundCornerRadius = s.getShiftCalculationStrategy().getShiftCornerRadius(canvasShiftData) || 0;
      if (canvasShiftData.width < 0) continue;
      if (canvasShiftData.hasOwnProperty('noRender') && canvasShiftData.noRender.length) continue;
      rectBoundings = s
        .getShiftCalculationStrategy()
        .getShiftRectBoundings(
          canvasShiftData,
          s.getLastZoomTransformation(),
          s.getShiftCalculationStrategy().getShiftStrokeWidth(canvasShiftData),
          { y: s.nodeProportionsState.getScrollTopPosition() },
          s.nodeProportionsState.getShiftViewPortProportions().width,
          10
        );
      fillColor = s.getShiftCalculationStrategy().getShiftFillColorForCanvas(canvasShiftData, shiftBlock);
      strokeData = s
        .getShiftCalculationStrategy()
        .getStrokeData(canvasShiftData, leaveRightStrokeSideOpen, s.getPatternHandler());
      opacity = canvasShiftData.opacity;
      weaken = canvasShiftData.weaken || [];

      const secondColor =
        s.ganttConfig.getShiftBuildingColorMode() === EGanttShiftColorMode.SHOW_MULTIPLE_COLORS &&
        canvasShiftData.secondColor
          ? canvasShiftData.secondColor
          : null;

      self._roundRect(
        shiftBlock,
        rectBoundings,
        roundCornerRadius,
        fillColor,
        strokeData,
        opacity,
        weaken,
        s.ganttConfig.getShiftBuildingShowShiftContent(),
        s.getShiftCalculationStrategy().getShiftLabelHeight(canvasShiftData),
        secondColor,
        canvasShiftData.highlighted
      );

      if (strokeData.strokeColor) {
        self._renderInvisibleRectOnTopForStroke(
          shiftBlock,
          rectBoundings,
          roundCornerRadius,
          strokeData,
          selected,
          opacity,
          weaken
        );
      }

      if (canvasShiftData.selected) {
        self._renderBlueRectOnTopIfSelected(
          shiftBlock,
          rectBoundings,
          roundCornerRadius,
          strokeData,
          canvasShiftData,
          opacity,
          s.ganttConfig.colorSelectOpacity(),
          weaken
        );
      }
    }

    s.afterShiftRenderEvent(new GanttShiftsAfterRenderEvent(null, s.getLastZoomTransformation()));
  }

  private _renderInvisibleRectOnTopForStroke(
    shiftBlock,
    rectBoundings: IRectBoundings,
    roundCornerRadius,
    strokeData,
    selected,
    opacity,
    weaken
  ): void {
    const self = this;
    shiftBlock.strokeStyle = strokeData.strokeColor;

    const strokeWidth = strokeData.strokeWidth;
    strokeData.strokeWidth = strokeWidth;
    shiftBlock.lineWidth = strokeWidth;

    self._roundRect(
      shiftBlock,
      rectBoundings,
      roundCornerRadius,
      '#00000000',
      strokeData,
      opacity,
      weaken,
      null,
      null,
      null,
      null
    );
    shiftBlock.stroke();
  }

  private _renderBlueRectOnTopIfSelected(
    shiftBlock,
    rectBoundings: IRectBoundings,
    roundCornerRadius,
    strokeData,
    canvasShift,
    opacity,
    selectedOpacity,
    weaken
  ): void {
    const self = this;

    rectBoundings.width -= strokeData.strokeWidth;
    rectBoundings.x += strokeData.strokeWidth * 0.5;
    rectBoundings.y += strokeData.strokeWidth * 0.5;
    rectBoundings.height -= strokeData.strokeWidth;
    const newOpacity = (weaken.length ? 0.3 : opacity) * selectedOpacity;
    self._roundRect(
      shiftBlock,
      rectBoundings,
      roundCornerRadius,
      canvasShift.selected,
      strokeData,
      newOpacity,
      [],
      null,
      null,
      null,
      null
    );
  }

  /**
   * @private
   * @param {any} ctx
   * @param {any} rectBoundings
   * @param {any} radius
   * @param {any} fill
   * @param {any} strokeData
   */
  private _roundRect(
    ctx: CanvasRenderingContext2D,
    rectBoundings: IRectBoundings,
    radius: number,
    fill: string,
    strokeData,
    opacity,
    weaken,
    showShiftContent: boolean,
    textOverlayHeight,
    secondColor,
    highlighted
  ): void {
    const x = rectBoundings.x,
      y = rectBoundings.y,
      height = rectBoundings.height,
      width = rectBoundings.width, // min width of 1 px
      xWidth = x + width,
      yHeight = y + height;

    const isRoundRect = width > 6 && height > 6;

    if (isRoundRect) {
      // no rounded borders for small shifts in width / height
      ctx.beginPath();
      ctx.moveTo(x + radius, y);
      ctx.lineTo(xWidth - radius, y);
      ctx.quadraticCurveTo(xWidth, y, xWidth, y + radius);
      ctx.lineTo(xWidth, yHeight - radius);
      ctx.quadraticCurveTo(xWidth, yHeight, xWidth - radius, yHeight);
      ctx.lineTo(x + radius, yHeight);
      ctx.quadraticCurveTo(x, yHeight, x, yHeight - radius);
      ctx.lineTo(x, y + radius);
      ctx.quadraticCurveTo(x, y, x + radius, y);
      ctx.closePath();
    } else {
      ctx.beginPath();
      ctx.rect(x, y, width, height);
    }

    if (fill) {
      ctx.fillStyle = fill;
      ctx.globalAlpha = weaken.length ? 0.3 : opacity;
      ctx.fill();
    }

    if (secondColor && !highlighted) {
      const heightWithoutShiftContent = showShiftContent ? textOverlayHeight : height;
      if (isRoundRect) {
        ctx.beginPath();
        ctx.moveTo(xWidth - radius, y);
        ctx.quadraticCurveTo(xWidth, y, xWidth, y + radius);
        ctx.lineTo(xWidth, y + heightWithoutShiftContent - radius);
        ctx.quadraticCurveTo(xWidth, y + heightWithoutShiftContent, xWidth - radius, y + heightWithoutShiftContent);
        ctx.lineTo(x + radius, y + heightWithoutShiftContent);
        ctx.closePath();
      } else {
        ctx.beginPath();
        ctx.moveTo(xWidth, y);
        ctx.lineTo(xWidth, y + heightWithoutShiftContent);
        ctx.lineTo(x, y + heightWithoutShiftContent);
        ctx.closePath();
      }

      ctx.fillStyle = secondColor;
      ctx.globalAlpha = weaken.length ? 0.3 : opacity;
      ctx.fill();
    }

    if (strokeData && strokeData.strokeColor) {
      ctx.strokeStyle = strokeData.strokeColor;
      if (width < 10) strokeData.strokeWidth = (strokeData.strokeWidth * width) / 10;
      ctx.lineWidth = strokeData.strokeWidth;

      if (strokeData.leaveRightSideOpen) {
        ctx.setLineDash([width - radius, height - radius, width + radius + height]);
      } else if (strokeData.strokePattern) {
        ctx.setLineDash([strokeData.strokePattern.getFilledArea(), strokeData.strokePattern.getUnfilledArea()]);
      } else {
        ctx.setLineDash([]); // reset line dash
      }
      ctx.stroke();
    }

    if (showShiftContent) {
      const contentHeight = height - textOverlayHeight;
      if (contentHeight > 3) {
        // build white area only if it is large enough
        ctx.beginPath();
        ctx.moveTo(x, y + textOverlayHeight);
        ctx.lineTo(xWidth, y + textOverlayHeight);
        ctx.lineTo(xWidth, yHeight - radius);
        ctx.quadraticCurveTo(xWidth, yHeight, xWidth - radius, yHeight);
        ctx.lineTo(x + radius, yHeight);
        ctx.quadraticCurveTo(x, yHeight, x, yHeight - radius);
        ctx.lineTo(x, y + textOverlayHeight);
        ctx.closePath();
        if (fill) {
          ctx.fillStyle = '#FFFFFF';
          ctx.globalAlpha = weaken.length ? 0.3 : opacity;
          ctx.fill();
        }
      }
    }
  }

  /**
   * @param executer
   * @param event
   */
  private _handleCanvasMouseOver(executer: ShiftBuilder, event: MouseEvent): void {
    const s = this;
    if (event) {
      executer.canvasMouseOverEvent(event);
    }
    s.d3Event = event;
    if (!s.d3Event) {
      if (executer.getLastDragEvent()) {
        s.d3Event = executer.getLastDragEvent().sourceEvent;
        executer.setLastDragEvent(null);
      } else if (s.lastMoveEvent) {
        s.d3Event = s.lastMoveEvent;
        s.lastMoveEvent = null;
      }
    }

    if (!s.d3Event) {
      return;
    }

    if (s.timeout) {
      clearTimeout(s.timeout);
    }

    const hoveredShifts = this._getHoveredShifts(executer);
    if (hoveredShifts && hoveredShifts.length) {
      if (executer.isStopShiftHovering()) {
        return;
      }
      if (s.timeout) {
        clearTimeout(s.timeout);
      }
      const lastHoveredShift = hoveredShifts[hoveredShifts.length - 1];
      if (this.currentSVGShift) {
        if (lastHoveredShift.id != this.currentSVGShift.id) {
          // only for callback purposes
          this.currentSVGShift = null;
          // trigger mouseout
          this._removeAllResizeHandlesAfterMouseOut(executer);
          this._removeAllSVGOvelayShiftsAfterMouseOut(executer);
          executer.shiftMouseOutEvent(event); // trigger mouseOut callbacks
          this.currentSVGShift = lastHoveredShift;
          // trigger mouseover
          this._renderSvgOverlay(lastHoveredShift.id, executer);
        }
      } else {
        this.currentSVGShift = lastHoveredShift;
        // trigger mouseover
        executer.shiftMouseOverShiftOnCanvasEvent(event); // special MouseOver Callback
        this._renderSvgOverlay(lastHoveredShift.id, executer);
      }
    } else {
      // no shifts were mouse overed
      if (this.currentSVGShift) {
        this.currentSVGShift = null;
        // trigger mouseout
        this._removeAllResizeHandlesAfterMouseOut(executer);
        this._removeAllSVGOvelayShiftsAfterMouseOut(executer);
        executer.shiftMouseOutEvent(event); // trigger mouseOut callbacks
        executer.enterCanvasEvent(event); // trigger enter canvas
      }
      executer.shiftAreaMouseMissEvent(s.lastMoveEvent);
    }
    s.lastMoveEvent = event;
  }

  /**
   * @private
   * @param {ShiftBuilder} executer
   * @param {any} event
   */
  private _handleRowContextMenu(executer: ShiftBuilder, event: any): void {
    const s = executer;
    const self = this;
    const row = self._getRowByMouse(s);
    if (!row || !executer.getAllowShiftClicks()) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }
    s.rowOnContextMenuEvent(new GanttYAxisContextMenuEvent(event, row));
  }

  /**
   * Handles canvas double click events and dispatches them to the shift builder if necessary.
   * @param executer Reference to the shift builder instance which will be triggered.
   * @param event Canvas double click event.
   */
  private _handleCanvasDoubleClick(executer: ShiftBuilder, event: MouseEvent): void {
    const s = executer;
    const forceEvent = this._executeForceCanvasDoubleClickCbs(executer, event);
    if (forceEvent || !this._isMouseOverShifts(executer)) s.canvasDoubleClickEvent(event);
  }

  /**
   * Executes force canvas double click callbacks registered in the given shift builder instance and determines if the given event should be dispatched or not.
   * @param executer Shift builder instance containing the callbacks.
   * @param event Double click event to pass to the callbacks.
   * @returns True if the given event should be dispatched, false otherwise.
   */
  private _executeForceCanvasDoubleClickCbs(executer: ShiftBuilder, event: MouseEvent): boolean {
    const s = executer;
    for (const cb of s.getForceCanvasDoubleClickCbs()) {
      if (cb(event)) return true;
    }
    return false;
  }

  /**
   * @param executer
   * @param shift
   * @returns
   */
  private _isMouseOverThisShift(executer: ShiftBuilder, shift: GanttCanvasShift): boolean {
    const shiftY = executer.renderDataHandler.getStateStorage().getYPositionShift(shift.id);
    return (
      this.d3Event.offsetX >=
        shift.x * executer.getLastZoomTransformation().k + executer.getLastZoomTransformation().x &&
      this.d3Event.offsetX <=
        shift.x * executer.getLastZoomTransformation().k +
          executer.getLastZoomTransformation().x +
          shift.width * executer.getLastZoomTransformation().k &&
      this.d3Event.offsetY >= shiftY &&
      this.d3Event.offsetY <= shiftY + shift.height
    );
  }

  /**
   * Determines the row which was hit by the last saved {@link MouseEvent}.
   * Useful for clicks on the background of the shhift canvas.
   * @param executer {@link ShiftBuilder} instance executing this strategy.
   * @returns Row which was hit by the last saved {@link MouseEvent} (or `null` if no row was hit).
   */
  private _getRowByMouse(executer: ShiftBuilder): GanttCanvasRow {
    const s = executer;

    const y = this.d3Event.offsetY;
    let foundRow = s.renderDataHandler
      .getRenderDataYAxis(s.scrollContainerId)
      .find(
        (row) =>
          y >= s.renderDataHandler.getStateStorage().getYPositionRow(row.id) &&
          y < s.renderDataHandler.getStateStorage().getYPositionRow(row.id) + row.height
      );
    if (!foundRow) {
      return null;
    }
    if (foundRow.originalResource) {
      foundRow = s.renderDataHandler
        .getRenderDataYAxis(s.scrollContainerId)
        .find((row) => row.id == foundRow.originalResource);
    }
    return foundRow;
  }

  /**
   * @private
   * @param {ShiftBuidler} executer
   * @param {GanttCanvasShift} shift
   * @return {boolean}
   */
  private _isMouseOverResizeHandles(executer: ShiftBuilder, shift: GanttCanvasShift): boolean {
    const self = this;
    const mouseX = self.d3Event.offsetX;
    const mouseY = self.d3Event.offsetY;
    const yAxisDataSet = executer.dataHandler.getYAxisDataset();
    const rowCanvasData = YAxisDataFinder.getRowByYPosition(yAxisDataSet, shift.y);
    const resizeHandleLeftX =
      shift.x * executer.getLastZoomTransformation().k +
      executer.getLastZoomTransformation().x -
      executer.ganttConfig.shiftEditResizeHandleWidth() / 2;
    const resizeHandleRightX =
      shift.x * executer.getLastZoomTransformation().k +
      executer.getLastZoomTransformation().x +
      shift.width * executer.getLastZoomTransformation().k -
      executer.ganttConfig.shiftEditResizeHandleWidth() / 2;
    const resizeHandleWidth = executer.ganttConfig.shiftEditResizeHandleWidth();
    const resizeHandleY = shift.y - executer.nodeProportionsState.getScrollTopPosition() + rowCanvasData.height / 2;
    const resizeHandleHeight = rowCanvasData.height / 2;

    return (
      ((mouseX >= resizeHandleLeftX && mouseX <= resizeHandleLeftX + resizeHandleWidth) || // left handle
        (mouseX >= resizeHandleRightX && mouseX <= resizeHandleRightX + resizeHandleWidth)) && // right handle
      mouseY >= resizeHandleY &&
      mouseY <= resizeHandleY + resizeHandleHeight
    );
  }

  /**
   * @param executer
   * @param event
   */
  private _handleCanvasMouseOut(executer: ShiftBuilder, event: MouseEvent): void {
    const s = executer;
    if (!s.getShiftsAreResizing() && !this._isMouseOutTriggeredByShift(executer, event)) {
      this.currentSVGShift = null;
      this._shiftOverlaySVGBuilder.removeAllShifts(executer);
    }
  }

  /**
   * @param executer
   * @param event
   * @returns
   */
  private _isMouseOutTriggeredByShift(executer: ShiftBuilder, event: MouseEvent): boolean {
    if (event.relatedTarget) {
      const relatedTarget = d3.select<d3.BaseType, unknown>(event.relatedTarget as d3.BaseType);
      const data = relatedTarget.data()?.[0];
      if (typeof data === 'object') {
        const shiftId = (data as any).id;
        if (shiftId) {
          return executer.renderDataHandler.getStateStorage().isShiftInRenderData(shiftId);
        }
      }
    }
    return false;
  }

  /**
   * Clears the canvas.
   */
  removeCanvasContent(executer: ShiftBuilder) {
    const s = this;
    const context = s.canvas.node().getContext('2d');
    const proportions = executer.nodeProportionsState.getShiftViewPortProportions();
    context.clearRect(0, 0, proportions.width, proportions.height);
  }

  /**
   * @override
   */
  public removeAllShifts(executer: ShiftBuilder): void {
    const s = this;
    if (!s.canvas) return;
    s.currentSVGShift = null;
    s.removeCanvasContent(executer);
    s._shiftOverlaySVGBuilder.removeAllShifts(executer);
  }

  /**
   * @override
   */
  public preloadPatterns(executer: ShiftBuilder, originDataSet: GanttDataRow[]): void {
    const s = executer;
    const self = this;
    let cntPatterns = 0;
    let cntLoadings = 0;
    const patternList = [];

    const registerPattern = function (child, level, foundShift, shiftIndex) {
      for (const shift of child.shifts) {
        if (shift.pattern) {
          const shiftColor: string = executer.getBasicShiftColorByCanvasShift(shift);
          const patternId = shift.pattern + shiftColor + shift.patternColor;
          if (patternList.indexOf(patternId) == -1) {
            // mechanism to load same pattern only once
            patternList.push(patternId);
            cntPatterns++;
            const img = s.getPatternHandler().getPatternAsSvgImage(shift.pattern, shiftColor, shift.patternColor);
            if (img?.complete) {
              // image is already loaded
              cntLoadings++;
            } else {
              img.onload = function () {
                cntLoadings++;
                if (cntPatterns == cntLoadings) {
                  // when all patterns are loaded
                  executer.reBuildShifts(self.shiftDataSet);
                }
              };
            }
          }
        }
      }
    };
    DataManipulator.iterateOverDataSet(originDataSet, {
      registerPattern: registerPattern,
    });
  }

  /**
   * @override
   */
  initCallbacks(executer: ShiftBuilder) {
    const s = executer;
    const self = this;
    self._subscriptions['CanvasReRenderSVGNodeOnClickDirectly'] = s
      .shiftOnClickDirectly(1)
      .subscribe((eventWrapper) => {
        const target = eventWrapper.target;
        const shiftID = target.data()[0].id;
        self._renderSvgOverlay(shiftID, executer);
      });

    self._subscriptions['CanvasReRenderSVGNodeOnContextMenu'] = s
      .shiftOnContextMenu()
      .subscribe((eventWrapper: IShiftClickEvent) => {
        setTimeout(() => {
          const target = eventWrapper.target;
          const shiftID = target.data()[0].id;
          self._renderSvgOverlay(shiftID, executer);
        }, 0);
      });

    self._subscriptions['CanvasReRenderSVGNodeOnDoubleClick'] = s.shiftAfterOnDoubleClick().subscribe((event) => {
      const shiftID = event.target.data()[0].id;
      self._renderSvgOverlay(shiftID, executer);
    });
  }

  /**
   * @param executer
   */
  private _removeAllResizeHandlesAfterMouseOut(executer: ShiftBuilder): void {
    executer.getCanvasInFrontShifts().selectAll('.gantt-shift-resize-handle').remove(); // remove resize handles
  }

  /**
   * @private
   * @param {ShiftBuilder} executer
   */
  _removeAllSVGOvelayShiftsAfterMouseOut(executer: ShiftBuilder) {
    this._shiftOverlaySVGBuilder.removeAllShifts(executer);
  }

  /**
   * Determines if the mouse is hovering over any shift in the shift dataset or not.
   * @param executer Shift builder instance to use for the check.
   * @returns True if mouse is hovering over any shift, false otherwise.
   */
  private _isMouseOverShifts(executer: ShiftBuilder): boolean {
    const hoveredShifts = this._getHoveredShifts(executer);
    if (hoveredShifts && hoveredShifts.length > 0) return true;
    return false;
  }

  /**
   * Determines an array of all canvas shifts the mouse is currently hovering over.
   * @param executer Shift builder instance to use for the check.
   * @returns Array containing all canvas shifts the mouse is currently hovering over.
   */
  private _getHoveredShifts(executer: ShiftBuilder): GanttCanvasShift[] {
    return this.shiftDataSet?.filter((shift) => {
      return this._isMouseOverThisShift(executer, shift) || this._isMouseOverResizeHandles(executer, shift);
    });
  }

  /**
   * @override
   */
  removeCallbacks(executer: ShiftBuilder) {
    const s = executer;
    const self = this;
    self._subscriptions['CanvasReRenderSVGShiftOnUpdate'].unsubscribe();
    self._subscriptions['CanvasReRenderSVGNodeOnClickDirectly'].unsubscribe();
    self._subscriptions['CanvasReRenderSVGNodeOnContextMenu'].unsubscribe();
    self._subscriptions['CanvasReRenderSVGNodeOnDoubleClick'].unsubscribe();

    executer.getCanvasInFrontShifts().on('mousemove', null);
    executer.getCanvasInFrontShifts().on('mouseleave', null);
  }

  /**
   * @override
   */
  clearTimeouts() {
    const s = this;
    clearTimeout(s.timeout);
  }
}
