<ng-container *ngIf="wizard">
  <mat-horizontal-stepper
    class="mat-elevation-z6"
    [linear]="true"
    #stepper
    (selectionChange)="onSelectionChanged($event)"
    [labelPosition]="'bottom'"
  >
    <ng-container *ngFor="let page of wizard.getContent().getWizardPage(); let pageIdx = index">
      <mat-step [label]="page.getName()" [editable]="changingView || wizard.isDisableTopNavigation() === false">
        <!-- <ng-template matStepLabel>{{page.getName()}}</ng-template> -->
        <app-wizard-page
          style="margin-top: 5px; display: block"
          *ngIf="!recalc && pageIdx === currentPageIndex"
          [wizardPage]="page"
        >
        </app-wizard-page>
      </mat-step>
    </ng-container>
  </mat-horizontal-stepper>
</ng-container>
