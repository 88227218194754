import FloatParsing from './float-parsing';
import { DEFAULT_PERCENT_STEP } from './parsing-constants';
import { saveDivide, saveMultiply } from './parsing-utils';

class PercentParsing extends FloatParsing {
  public inputToValue(input?: string): number | undefined {
    return saveDivide(super.inputToValue(input), 100);
  }
  public valueToInput(value: number): string {
    return super.valueToInput(saveMultiply(value, 100));
  }
  protected get decimalsCount(): number | undefined {
    return super.decimalsCount && Math.max(0, super.decimalsCount - 2);
  }
  public get step(): number {
    if (super.isExplicitStep) {
      return super.step;
    } else {
      return DEFAULT_PERCENT_STEP;
    } // iterate by 1% not by 1=100%;
  }
}

export default PercentParsing;
