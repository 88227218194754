import { IGanttResponse } from '../../../gantt-response';

/**
 * Sorts shift blocks of gantt response.
 */
export class RowUpdateSortShiftBlocks {
  /**
   * Sorts blocks of gantt entries of repsonse data by date.
   * Since the response has no hieraarchies inside the gantt Entries,
   * the sorting will happend only on a flat list.
   * @param response Server response.
   */
  public sortShiftBlocksByTime(response: IGanttResponse): any {
    if (!response.ganttEntries) return response;
    for (const entry of response.ganttEntries) {
      if (!entry.blocks) continue;
      entry.blocks.sort((a, b) => a?.start - b?.start);
    }
    return response;
  }
}
