import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SlotModule } from '@app-modeleditor/components/template/slot/slot.module';
import { AngularSplitModule } from 'angular-split';
import { MySharedModule } from 'frontend/src/dashboard/shared/shared.module';
import { GlobalViewModule } from 'frontend/src/dashboard/view/global-view.module';
import { NgChartsModule } from 'ng2-charts';
import { BubblePaginationModule } from './chart/bubble-pagination/bubble-pagination.module';
import { ChartLegendComponent } from './chart/chart-legend/chart-legend.component';
import { ChartsComponent } from './chart/chart.component';
import { ChartIntegralDialogComponent } from './chart/generalChart/chart.extensions/chart-integral-dialog/chart-integral-dialog.component';
import { ChartCompareDialog } from './chart/generalChart/chart.extensions/chart.compare.dialog';
import { ChartOptionDialog } from './chart/generalChart/chart.extensions/chart.option.dialog';
import { TooltipPopupComponent } from './chart/tooltip-popup/tooltip-popup.component';
import { GeneralChartComponent } from './chart/generalChart/generalChart.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgChartsModule,
    GlobalViewModule,
    MySharedModule,
    BubblePaginationModule,
    AngularSplitModule,
    SlotModule,
  ],
  declarations: [
    ChartIntegralDialogComponent,
    ChartLegendComponent,
    ChartOptionDialog,
    ChartCompareDialog,
    GeneralChartComponent,
    ChartsComponent,
    TooltipPopupComponent,
  ],
  exports: [ChartOptionDialog, ChartCompareDialog, GeneralChartComponent, ChartsComponent],
})
export class SaxMsChartModule {}
