<ng-container *ngIf="headline && (headline.getEntry() || headline.getValue())">
  <h2 class="headline" [matTooltip]="Value" [matTooltipShowDelay]="1000" *ngIf="!stretched" [style.color]="Color">
    {{ Value }}
  </h2>

  <div
    #headlineContainerRef
    *ngIf="stretched"
    [style.color]="Color"
    [style.height]="Size"
    [style.fontSize]="Size"
    [style.line-height]="Size"
    class="singleton"
  >
    {{ Value }}
  </div>
</ng-container>
