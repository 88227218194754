import { Injectable } from '@angular/core';
import { CloudMessagingService } from '@core/notification/cloud-messaging.service';
import { Notification } from '@core/notification/notification';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { GridLayout } from './grid-layout';
import { SelectionInformation } from './selection-information';
import { Zoom } from './zoom';

@Injectable({
  providedIn: 'root',
})
export class WidgetGridLayoutService {
  private layouts: BehaviorSubject<GridLayout[]> = new BehaviorSubject<GridLayout[]>([]);
  private selectionInformations: BehaviorSubject<SelectionInformation[]> = new BehaviorSubject<SelectionInformation[]>(
    []
  );
  private _paddingRight: number = null;
  private onHorizontalScrollSync$ = new Subject<{ templateId: string; scrollLeft: number; initiator: string }>();
  private onVerticalScrollSync$ = new Subject<{ templateId: string; scrollTop: number; initiator: string }>();

  private horizontalScrollEndSubject = new Subject<void>();
  private verticalScrollEndSubject = new Subject<void>();
  private _isHorizontalScrolling = false;
  private _isVerticalScrolling = false;
  private verticalScrollInitiator = '';
  private horizontalScrollInitiator = '';

  constructor(private fcm: CloudMessagingService) {
    // listen to gantts zoom event
    this.fcm.getMessage().subscribe((event: Notification) => {
      if (event && event.getZoomStart() && event.getZoomEnd()) {
        const layout: GridLayout =
          this.layouts.value.find((layout) => layout.getContentId() === event.getContentId()) || new GridLayout();
        layout
          .setZoom(new Zoom().setStart(new Date(event.getZoomStart())).setEnd(new Date(event.getZoomEnd())))
          .setContentId(event.getContentId());
        this.addGridLayout(layout);
      }
    });
  }

  public getHorizontalScrollSync(): Observable<{ templateId: string; scrollLeft: number; initiator: string }> {
    return this.onHorizontalScrollSync$.asObservable();
  }

  public getVerticalScrollSync(): Observable<{ templateId: string; scrollTop: number; initiator: string }> {
    return this.onVerticalScrollSync$.asObservable();
  }

  public setHorizontalScrollSync(templateId: string, scrollLeft: number): void {
    this.handleHorizontalScrollInitiator(templateId);
    this.onHorizontalScrollSync$.next({ templateId, scrollLeft, initiator: this.horizontalScrollInitiator });
  }

  public setVerticalScrollSync(templateId: string, scrollTop: number): void {
    this.handleVerticalScrollInitiator(templateId);
    this.onVerticalScrollSync$.next({ templateId, scrollTop, initiator: this.verticalScrollInitiator });
  }

  private handleHorizontalScrollInitiator(templateId: string): void {
    if (!this._isHorizontalScrolling) {
      this._isHorizontalScrolling = true;
      this.horizontalScrollInitiator = templateId;

      this.onHorizontalScrollSync$.pipe(takeUntil(this.horizontalScrollEndSubject), debounceTime(100)).subscribe(() => {
        this._isHorizontalScrolling = false;

        this.horizontalScrollInitiator = '';
        this.horizontalScrollEndSubject.next();
      });
    }
  }

  private handleVerticalScrollInitiator(templateId: string): void {
    if (!this._isVerticalScrolling) {
      this._isVerticalScrolling = true;
      this.verticalScrollInitiator = templateId;

      this.onVerticalScrollSync$.pipe(takeUntil(this.verticalScrollEndSubject), debounceTime(100)).subscribe(() => {
        this._isVerticalScrolling = false;

        this.verticalScrollInitiator = '';
        this.verticalScrollEndSubject.next();
      });
    }
  }

  public getSelectionInformations(): Observable<SelectionInformation[]> {
    return this.selectionInformations.asObservable();
  }

  public refreshSelectionInformation(templateId: string, selectionInformation: SelectionInformation): void {
    const foundSelectionInformation =
      this.selectionInformations.value.find(
        (selectionInformation) => selectionInformation.getContentId() === templateId
      ) || new SelectionInformation();
    foundSelectionInformation.setContentId(templateId);
    foundSelectionInformation.setSelectionResource(selectionInformation.getSelectionResource());
    foundSelectionInformation.setSelectedRanges(selectionInformation.getSelectedRanges());
    this.addSelectionInformation(foundSelectionInformation);
  }

  private addSelectionInformation(selectionInformation: SelectionInformation): void {
    const selectionInformations = this.selectionInformations.value || [];
    let foundSelectionInformation = this.selectionInformations.value.find(
      (data) => data.getContentId() === selectionInformation.getContentId()
    );
    if (foundSelectionInformation) {
      foundSelectionInformation = selectionInformation;
    } else {
      selectionInformations.push(selectionInformation);
    }

    // this.selectionInformations.next(selectionInformations);
  }

  /**
   * get grid layout
   * @returns Observable<GridLayout>
   */
  public getGridLayout(): Observable<GridLayout[]> {
    return this.layouts.asObservable();
  }

  /**
   * add grid layout to list
   * @param layout GridLayout
   * @returns void
   */
  private addGridLayout(layout: GridLayout): void {
    const layouts = this.layouts.value || [];
    let foundLayout = this.getLayout(layout.getContentId());
    if (foundLayout) {
      foundLayout = layout;
    } else {
      layouts.push(layout);
    }
    this.layouts.next(layouts);
  }

  private getLayout(contentId: string): GridLayout {
    return (this.layouts.value || []).find((layout: GridLayout) => layout.getContentId() === contentId);
  }

  /**
   * set layouts starting width
   * @param contentId string
   * @param width number
   * @returns IGridLayoutSize
   */
  public setGridStart(contentId: string, width: number): void {
    const foundLayout = this.getLayout(contentId);
    this.addGridLayout(
      foundLayout
        ? foundLayout.setGridStart(width).setContentId(contentId)
        : new GridLayout().setGridStart(width).setContentId(contentId).setContentId(contentId)
    );
  }

  public get paddingRight(): number {
    return this._paddingRight;
  }
  public set paddingRight(value: number) {
    this._paddingRight = value;

    const layouts = this.layouts.value || [];
    this.layouts.next(layouts);
  }
}
