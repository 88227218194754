import { GanttConfig } from '../config/gantt-config';

/**
 * Stores the heights of individual rows in gantt chart.
 */
export class GanttRowHeightStorage {
  private _rowHeightsMap: Map<string, number>;
  private _ganttConfig: GanttConfig;

  constructor(ganttConfig) {
    this._rowHeightsMap = new Map();
    this._ganttConfig = ganttConfig;
  }

  setRowHeightsMap(map: Map<string, number>): void {
    this._rowHeightsMap = map;
  }

  getRowHeightsMap(): Map<string, number> {
    return this._rowHeightsMap;
  }

  getRowHeightById(id: string) {
    return this._rowHeightsMap.get(id) || this._ganttConfig.rowHeight();
  }

  setRowHeightById(id: string, height: number) {
    return this._rowHeightsMap.set(id, height);
  }
}
