export enum EFieldType {
  SEPARATOR = 'SEPARATOR',
  SEPARATOREMPTY = 'SEPARATOREMPTY',
  EMPTY_ELEMENT = 'PLACEHOLDER',

  TEXT = 'TEXT',
  TEXT_FIELD = 'TEXT_FIELD',
  TEXT_AREA = 'TEXT_AREA',
  AUTOCOMPLETE_TEXT_FIELD = 'AUTOCOMPLETE_TEXT_FIELD',
  LABELED_TEXT = 'LABELED_TEXT',
  RICH_TEXT_EDITOR = 'TEXT_AREA_EDITOR',

  BUTTON = 'BUTTON',
  BUTTON_SLIDER = 'BUTTON_SLIDER',
  TOGGLE_BUTTON = 'TOGGLE_BUTTON',
  RADIOBUTTONS = 'RADIOBUTTONS',
  TOGGLE_BUTTON_GROUP = 'TOGGLE_BUTTON_GROUP',

  COMBO_BOX = 'COMBO_BOX',
  COMBO_BOX_MULTIPLE_SELECT = 'COMBO_BOX_MULTIPLE_SELECT',
  CHECK_BOX = 'CHECK_BOX',

  SLIDE_TOGGLE = 'SLIDE_TOGGLE',
  SLIDER = 'SLIDER',

  PREDEFINED = 'PREDEFINED',
  /**
   * @deprecated use RANGE_PICKER instead (15.08.2023)
   */
  DATE_INTERVALL_PICKER = 'DATE_INTERVALL_PICKER',
  DATE_PICKER = 'DATE_PICKER',
  DATE_TIME_PICKER = 'DATE_TIME_PICKER',
  TIME_PICKER = 'TIME_PICKER',
  DURATION_PICKER = 'DURATION_PICKER',
  RANGE_PICKER = 'RANGE_PICKER',
  CALENDAR_YEAR_PICKER = 'YEAR_PICKER',
  CALENDAR_WEEK_PICKER = 'CALENDERWEEK_PICKER',
  CALENDAR_MONTH_PICKER = 'CALENDERMONTH_PICKER',

  FILE_SYSTEM_ELEMENT = 'FILESYSTEMELEMENT',

  MULTI_OBJECT_SELECTOR = 'MULTI_OBJECT_SELECTOR',
  OBJECT_SELECTOR = 'OBJECT_SELECTOR',

  COLOR_PICKER = 'COLOR_PICKER',
  SELECTION_BOX = 'SELECTION_BOX',

  HEADLINE = 'HEADLINE',
  PIE_CHART = 'PIE_CHART',
  FILTER_ELEMENT = 'FILTER_ELEMENT',
  GANTT_DATE_SELECTOR = 'GANTT_DATE_SELECTOR',
  GANTT_SETTINGS = 'GANTT_SETTINGS',
  TRANSFER_GANTT_SETTINGS = 'TRANSFER_GANTT_SETTINGS',
  RESOURCE_SELECTOR = 'RESOURCE_SELECTOR',
  SPINNER = 'SPINNER',
  FILE_UPLOAD = 'FILE_UPLOAD',
  COLORSELECTOR = 'COLORSELECTOR',
  FILTER_NODE_VIEWER = 'FILTER_NODE_VIEWER',
  NUMERIC_RANGE_PICKER = 'NUMERIC_RANGE_PICKER',
  WEB_LINK = 'WEB_LINK',
  DATE_INPUT = 'DATE_INPUT',
  IMAGE = 'IMAGE',
}
