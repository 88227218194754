import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { FileProgress } from '../file-progress/file-progress';
import { FileProgressService } from './file-progress.service';

@Injectable({
  providedIn: 'root',
})
export class FileUtilsService {
  constructor(private _fileProgressApi: FileProgressService) {}

  /**
   * read a local file into memory
   * @returns Observable<CustomEvent>
   */
  public readLocalFile(file: File): Observable<CustomEvent> {
    return new Observable<CustomEvent>((observer: Observer<CustomEvent>) => {
      const fileSize = file.size;
      const chunkSize = 50000000; // in bytes
      let offset = 0;
      const self = this; // we need a reference to the current object
      let chunkReaderBlock = null;
      let finalBytes: Uint8Array = new Uint8Array(0);
      this._fileProgressApi.setFileProgress(
        this._fileProgressApi.getFileProgressObject().addTask('readFile', `... lese Datei ${file.name}`)
      );

      const readEventHandler = (evt) => {
        if (evt.target.error == null) {
          offset += chunkSize;
          observer.next(new CustomEvent('5', { detail: evt.target.result }));
          finalBytes = this._appendBuffer(finalBytes, new Uint8Array(evt.target.result));
          this._fileProgressApi.setFileProgress(
            this._fileProgressApi.getFileProgressObject().addTask('readFile', `... lese Datei ${file.name}`)
          );
        } else {
          observer.error(new CustomEvent('error', { detail: evt.target.error }));
          return;
        }
        if (offset >= fileSize) {
          observer.next(new CustomEvent('ready', { detail: this._typedArrayToBuffer(finalBytes) }));
          this._fileProgressApi.setFileProgress(this._fileProgressApi.getFileProgressObject().removeTask('readFile'));
          return;
        }

        chunkReaderBlock(offset, chunkSize, file);
      };

      chunkReaderBlock = (_offset: number, length: number, _file: File) => {
        const r: FileReader = new FileReader();
        const blob: Blob = _file.slice(_offset, length + _offset);
        r.onload = readEventHandler;
        r.readAsArrayBuffer(blob);
      };

      // const oldWayOfDoing = (file) => {
      //   const r: FileReader = new FileReader();
      //   r.onload = ((evt: any) => {
      //     // observer.next(new CustomEvent('ready', { detail: evt.target.result }));
      //   });
      //   r.readAsArrayBuffer(file);
      // };

      // oldWayOfDoing(file);
      // now let's start the read with the first block
      chunkReaderBlock(offset, chunkSize, file);
    });
  }

  /**
   * converts typed array to arraybuffer
   * @param array Uint8Array
   * @returns ArrayBuffer
   */
  private _typedArrayToBuffer(array: Uint8Array): ArrayBuffer {
    return array.buffer.slice(array.byteOffset, array.byteLength + array.byteOffset);
  }

  /**
   * append one buffer to another
   * @param buffer1 Uint8Array
   * @param buffer2 Uint8Array
   * @returns Uint8Array
   */
  private _appendBuffer(buffer1: Uint8Array, buffer2: Uint8Array): Uint8Array {
    const tmp = new Uint8Array(buffer1.byteLength + buffer2.byteLength);
    tmp.set(new Uint8Array(buffer1), 0);
    tmp.set(new Uint8Array(buffer2), buffer1.byteLength);
    return tmp;
  }
}
