<!-- default picker button -->
<ng-container *ngIf="!isSpreadsheetContext; else spreadsheet_picker">
  <button #btnRef mat-stroked-button *ngIf="picker" (click)="openPicker()" [disabled]="disabled">
    <mat-icon [style.color]="color">fiber_manual_record</mat-icon>
    {{ picker.getName() | translate }}
  </button>
</ng-container>

<!-- spreadsheet picker button -->
<ng-template #spreadsheet_picker>
  <div class="spreadsheet-btn" #btnRef mat-icon-button *ngIf="picker" [style.background]="color || 'white'"></div>
</ng-template>
