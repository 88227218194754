import * as d3 from 'd3';
import { Observable, Subject, takeUntil } from 'rxjs';
import { ETimeGridType } from '../../data-handler/data-structure/data-structure-enums';
import { GanttFontSizeCalculator } from '../../font-tools/font-size';
import { GanttUtilities } from '../../gantt-utilities/gantt-utilities';
import { EGanttScrollContainer } from '../../html-structure/scroll-container.enum';
import { BestGantt } from '../../main';
import { BestGanttPlugIn } from '../gantt-plug-in';

/**
 * Plugin to visualize labels in rows by time grid.
 * The labels are arranged in time columns.
 * The column size depends on the selected time grid.
 */
export class GanttGridLabels extends BestGanttPlugIn {
  private _isActive = false;
  private _canvas: { [id: string]: d3.Selection<HTMLCanvasElement, undefined, d3.BaseType, undefined> } = {};
  private _gridColumns: IGanttGridColumnData[] = [];
  private _textColor = 'black';
  private _fontSizeCalculatorSmall: GanttFontSizeCalculator = undefined;
  private _fontSizeCalculatorMedium: GanttFontSizeCalculator = undefined;
  private _fontSizeCalculatorBig: GanttFontSizeCalculator = undefined;
  private _fontSizeSmall = 8;
  private _fontSizeMedium = 11;
  private _fontSizeBig = 14;
  private _currentFontSize = this._fontSizeBig;
  private _lineDash = [5, 5];

  private _onDeactivationSubject: Subject<void> = new Subject<void>();

  constructor() {
    super();
  }

  public initPlugIn(ganttDiagram: BestGantt): void {
    this.ganttDiagram = ganttDiagram;
  }

  public update(): void {
    this._updateCanvasProportions();
    if (this._isActive) {
      this._render();
    }
  }

  public updatePlugInHeight(): void {
    this._updateCanvasProportions();
    if (this._isActive) {
      this._render();
    }
  }

  public removePlugIn(): void {
    this.deactivate();

    this._onDeactivationSubject.complete();
  }

  /**
   * Activates the grid labels.
   * @param {ETimeGridType} gridType column size depends on this given time grid
   * @param {number} startDate grid start date in milliseconds
   * @param {number} endDate end date of gantt in milliseconds
   * @param {string} color text color of labels
   * @param {number[]} lineDash array of dash segments (solid line = [], no line = [0, 1]): [line, gap, line, gap, ...]
   */
  public activate(
    gridType: ETimeGridType,
    startDate: number,
    endDate: number,
    color = 'black',
    lineDash: number[] = [2, 2]
  ): void {
    this._isActive = true;
    this._textColor = color;
    if (!this._fontSizeCalculatorBig) {
      this._initFontSizeCalculators();
    }
    this._lineDash = lineDash;
    this._gridColumns = this._calculateGridColumns(gridType, startDate, endDate);
    this._generateCanvas();
    this._addCallbacks();
    this._render();
  }

  /**
   * Deactivates the grid labels.
   */
  public deactivate(): void {
    this._isActive = false;
    this._onDeactivationSubject.next();
    for (const id in this._canvas) this._canvas[id]?.remove();
  }

  /**
   * Registers all necessary callbacks to display the labels.
   */
  private _addCallbacks(): void {
    this.ganttDiagram
      .getVerticalScrollHandler()
      .onScrollVerticalUpdate.pipe(takeUntil(this.onDeactivation))
      .subscribe(() => this._updateCanvasScrollPosition());
    this.ganttDiagram
      .getXAxisBuilder()
      .onZooming.pipe(takeUntil(this.onDeactivation))
      .subscribe(() => this._render());
    this.ganttDiagram.getVerticalScrollHandler().onScrollVerticalUpdate.subscribe(() => this._render());
    this.ganttDiagram
      .getOpenRowHandler()
      .afterShiftRowOpen.pipe(takeUntil(this.onDeactivation))
      .subscribe(() => this._render());
    this.ganttDiagram
      .getOpenRowHandler()
      .afterShiftRowClosed.pipe(takeUntil(this.onDeactivation))
      .subscribe(() => this._render());
    this.ganttDiagram
      .getNodeProportionsState()
      .select('shiftViewPortProportionsSticky')
      .pipe(takeUntil(this.onDeactivation))
      .subscribe(() => this._updateCanvasProportions());
  }

  /**
   * Inits font size calculators for different font sizes.
   */
  private _initFontSizeCalculators(): void {
    this._fontSizeCalculatorSmall = new GanttFontSizeCalculator();
    this._fontSizeCalculatorMedium = new GanttFontSizeCalculator();
    this._fontSizeCalculatorBig = new GanttFontSizeCalculator();

    const d3SVG = this.ganttDiagram.getShiftFacade().getCanvasInFrontShifts().append<SVGSVGElement>('svg');

    this._fontSizeCalculatorSmall.init(d3SVG.node(), this._fontSizeSmall);
    this._fontSizeCalculatorMedium.init(d3SVG.node(), this._fontSizeMedium);
    this._fontSizeCalculatorBig.init(d3SVG.node(), this._fontSizeBig);

    d3SVG.remove();
  }

  /**
   * Generates a HTML-Canvas element in the text overlay container.
   */
  private _generateCanvas(): void {
    for (const scrollContainerId of Object.values(EGanttScrollContainer)) {
      const textOverlayCanvas = this.ganttDiagram.getTextOverlay(scrollContainerId).getCanvas().node();
      const shiftViewPortProportions = this.ganttDiagram
        .getNodeProportionsState()
        .getShiftViewPortProportions(scrollContainerId);

      // generate canvas
      this._canvas[scrollContainerId] = d3
        .select<d3.BaseType, undefined>(textOverlayCanvas.parentNode as d3.BaseType)
        .append('canvas')
        .attr('class', 'gantt-grid-labels-canvas')
        .attr('width', shiftViewPortProportions.width)
        .attr('height', shiftViewPortProportions.height)
        .style('position', 'absolute')
        .style('top', '0px')
        .style('left', '0px');
    }
  }

  /**
   * Updates proportions of the canvas (width and height).
   */
  private _updateCanvasProportions(): void {
    if (!this._isActive) return;

    const scrollContainerIds = this.ganttDiagram.getRenderDataHandler().getActiveScrollContainerIds();
    for (const scrollContainerId of scrollContainerIds) {
      const shiftViewPortProportions = this.ganttDiagram
        .getNodeProportionsState()
        .getShiftViewPortProportions(scrollContainerId);
      const currentWidth = parseFloat(this._canvas[scrollContainerId].attr('width'));
      const currentHeight = parseFloat(this._canvas[scrollContainerId].attr('height'));

      if (currentWidth === shiftViewPortProportions.width && currentHeight === shiftViewPortProportions.height) {
        continue;
      }

      this._canvas[scrollContainerId]
        ?.attr('width', shiftViewPortProportions.width)
        .attr('height', shiftViewPortProportions.height);
    }
  }

  /**
   * Updates scroll position of the canvas on scroll.
   */
  private _updateCanvasScrollPosition(): void {
    if (!this._isActive) return;

    const scrollContainerIds = this.ganttDiagram.getRenderDataHandler().getActiveScrollContainerIds();
    for (const scrollContainerId of scrollContainerIds) {
      this._canvas[scrollContainerId]?.style(
        'top',
        this.ganttDiagram.getNodeProportionsState().getScrollTopPosition(scrollContainerId) + 'px'
      );
    }
  }

  /**
   * Calculates the grid column proportions by the given grid type from start to end.
   * @param gridType column size depends on this given time grid
   * @param startDate calculation start date (reference date) in milliseconds
   * @param endDate  calculation end date in milliseconds
   * @returns Array of columns
   */
  private _calculateGridColumns(
    gridType: ETimeGridType,
    startDate: number | Date,
    endDate: number | Date
  ): IGanttGridColumnData[] {
    const columnList = [];

    if (!this._isGridValid(gridType)) {
      return columnList;
    }

    startDate = new Date(startDate);
    endDate = new Date(endDate);

    let inProgress = true;
    let timeCursor = new Date(startDate);
    let index = 0;

    while (inProgress) {
      const from = timeCursor;

      // handle end time point
      const to = this._getNextDateByGrid(timeCursor, gridType);
      timeCursor = new Date(to); // from value for next column
      to.setMilliseconds(-1); // next day -1 millisecond
      columnList.push({ from, to, index });

      // check if out of scope
      if (timeCursor >= endDate) {
        inProgress = false;
      }
      index++;
    }

    return columnList;
  }

  /**
   * Calculates the next column start date by the given grid.
   * @param {Date} refDate From this date the next column is calculated
   * @param {ETimeGridType} grid Time span width of the column
   * @returns {Date} Start date of the next column
   */
  private _getNextDateByGrid(refDate: Date, grid: ETimeGridType): Date {
    const nextDate = new Date(refDate);

    switch (grid) {
      case 'Hourly':
        nextDate.setHours(refDate.getHours() + 1);
        break;
      case 'Daily':
        nextDate.setDate(refDate.getDate() + 1);
        break;
      case 'Weekly':
        nextDate.setDate(refDate.getDate() + 7);
        break;
      case 'Monthly':
        nextDate.setMonth(refDate.getMonth() + 1);
        break;
      case 'Quarterly':
        nextDate.setMonth(refDate.getMonth() + 3);
        break;
      default:
        console.warn(`${grid} is not a known grid type for grid labels!`);
        return null;
    }
    return nextDate;
  }

  /**
   * Checks wether the grid type is processable.
   * @param {ETimeGridType} gridType Grid type to check
   * @returns {boolean} True if valid
   */
  private _isGridValid(gridType: ETimeGridType): boolean {
    if (
      gridType === 'Hourly' ||
      gridType === 'Daily' ||
      gridType === 'Weekly' ||
      gridType === 'Monthly' ||
      gridType === 'Quarterly'
    ) {
      return true;
    }
    console.warn(`${gridType} is not a known grid type for grid labels!`);
    return false;
  }

  /**
   * Renders the labels on canvas.
   */
  private _render(): void {
    if (!this._isActive) {
      return;
    }

    const gridColumnsRenderData = this._mapGridColumnsToRenderData();
    const lineRenderData = this._generateLineRenderData(gridColumnsRenderData);

    const scrollContainerIds = this.ganttDiagram.getRenderDataHandler().getActiveScrollContainerIds();
    for (const scrollContainerId of scrollContainerIds) {
      const ctx = this._canvas[scrollContainerId].node().getContext('2d');
      const textRenderData = this._generateTextRenderData(gridColumnsRenderData, scrollContainerId);
      const viewPortHeight = this.ganttDiagram
        .getNodeProportionsState()
        .getShiftViewPortProportions(scrollContainerId).height;
      const canvasHeight = this.ganttDiagram
        .getNodeProportionsState()
        .getShiftCanvasProportions(scrollContainerId).height;
      const lineHeight = canvasHeight < viewPortHeight ? canvasHeight : viewPortHeight;

      this._clearCanvas(scrollContainerId);

      // canvas settings for lines
      ctx.lineWidth = 1;
      ctx.fillStyle = 'none';
      ctx.strokeStyle = 'black';
      ctx.setLineDash(this._lineDash);

      // render lines
      for (const d of lineRenderData) {
        ctx.beginPath();
        ctx.moveTo(d.x, 0);
        ctx.lineTo(d.x, lineHeight);
        ctx.stroke();
      }

      // canvas settings for text
      ctx.font = this._currentFontSize + 'px Arial';
      ctx.fillStyle = this._textColor;
      ctx.lineWidth = 1.5;
      ctx.strokeStyle = 'white';
      ctx.setLineDash([]);

      // render text
      textRenderData.forEach((d) => {
        ctx.strokeText(d.text, d.x, d.y);
        ctx.fillText(d.text, d.x, d.y);
      });
    }
  }

  /**
   * Maps the grid column dates from dates to pixel based values relative to the current zoom level.
   * Also filter the dataset by the current view port.
   * @returns mapped grid columns
   */
  private _mapGridColumnsToRenderData(): IGanttGridColumnRenderData[] {
    const currentTimeSpan = this.ganttDiagram.getCurrentZoomedTimeSpan();
    const currentScale = this.ganttDiagram.getXAxisBuilder().getCurrentScale();
    const renderData = this._gridColumns
      .filter((d) => !(d.from > currentTimeSpan.to || d.to < currentTimeSpan.from)) // filter current time span
      .map((d) => {
        return { fromX: currentScale(d.from), toX: currentScale(d.to), index: d.index };
      });

    return renderData;
  }

  /**
   * Generates a render dataset to render the text labels in canvas.
   * @param gridColumnsRenderData grid render data
   * @param scrollContainerId
   * @returns Render data array
   */
  private _generateTextRenderData(
    gridColumnsRenderData: IGanttGridColumnRenderData[],
    scrollContainerId: EGanttScrollContainer
  ): IGanttGridTextRenderData[] {
    let textRenderData = [];
    const filteredYAxisDataset = GanttUtilities.filterDataSetByViewPort(
      this.ganttDiagram.getHTMLStructureBuilder().getYAxisContainer(scrollContainerId).node(),
      this.ganttDiagram.getRenderDataHandler().getRenderDataYAxis(scrollContainerId),
      this.ganttDiagram.getRenderDataHandler(),
      0,
      this.ganttDiagram.getNodeProportionsState().getShiftViewPortProportions(scrollContainerId).height,
      this.ganttDiagram.getNodeProportionsState().getScrollTopPosition(scrollContainerId)
    );

    if (!gridColumnsRenderData.length) {
      // nothing to render
      return textRenderData;
    }

    let smallestFontSize = this._fontSizeBig;

    // generate render data
    filteredYAxisDataset.forEach((row) => {
      // check if data valid
      if (isNaN(row.startCellIndexForSampleValues) || !Array.isArray(row.sampleValues) || !row.sampleValues.length) {
        return;
      }

      let sampleValuesIndex = 0;

      for (
        let i = row.startCellIndexForSampleValues;
        i < row.startCellIndexForSampleValues + row.sampleValues.length;
        i++
      ) {
        // get column
        const foundColumnData = gridColumnsRenderData.find((d) => d.index === i);

        if (foundColumnData) {
          // calculate proportions
          let text = row.sampleValues[sampleValuesIndex];
          const columnWidth = foundColumnData.toX - foundColumnData.fromX;

          // calculate max font size
          let [textWidth, fontSize] = this._getMaxFontSize(text, columnWidth);
          if (!fontSize) {
            // try to use single sign if text not fit
            text = '…';
            [textWidth, fontSize] = this._getMaxFontSize(text, columnWidth);
          }

          if (fontSize && textWidth <= columnWidth) {
            // if text fits in column
            const rowHeight = this.ganttDiagram.getDataHandler().getRowHeightStorage().getRowHeightById(row.id);
            const x = columnWidth / 2 + foundColumnData.fromX;
            const y =
              this.ganttDiagram.getRenderDataHandler().getStateStorage().getYPositionRow(row.id) +
              rowHeight / 2 -
              this.ganttDiagram.getNodeProportionsState().getScrollTopPosition(scrollContainerId);

            // add data
            textRenderData.push({ text, x, y, fontSize });

            // set smallest fontsize if current size is smaller
            smallestFontSize = Math.min(smallestFontSize, fontSize);
          }
        }
        sampleValuesIndex++;
      }
    });

    // set font size calculator based on smallest font size in dataset
    this._currentFontSize = smallestFontSize;
    let fontSizeCalculator = this._fontSizeCalculatorBig;
    if (smallestFontSize === this._fontSizeMedium) {
      fontSizeCalculator = this._fontSizeCalculatorMedium;
    } else if (smallestFontSize === this._fontSizeSmall) {
      fontSizeCalculator = this._fontSizeCalculatorSmall;
    }

    // finally set render data based on the smallest font size
    textRenderData = textRenderData.map((d) => {
      return {
        text: d.text,
        x: d.x - fontSizeCalculator.getTextWidth(d.text) / 2,
        y: d.y + smallestFontSize / 3,
      };
    });

    return textRenderData;
  }

  /**
   * Calculates the max possible fontsize of the given text.
   * @param {string} text text to be proofed
   * @param {number} maxWidth Max possible with of text
   * @returns {number[]} max text width an font size ([0,0] if does not suitable)
   */
  private _getMaxFontSize(text: string, maxWidth: number): [number, number] {
    let textWidth = this._fontSizeCalculatorBig.getTextWidth(text);
    let fontSize = this._fontSizeBig;
    if (textWidth > maxWidth) {
      textWidth = this._fontSizeCalculatorMedium.getTextWidth(text);
      fontSize = this._fontSizeMedium;
      if (textWidth > maxWidth) {
        textWidth = this._fontSizeCalculatorSmall.getTextWidth(text);
        fontSize = this._fontSizeSmall;
        if (textWidth > maxWidth) {
          textWidth = 0;
          fontSize = 0;
        }
      }
    }
    return [textWidth, fontSize];
  }

  /**
   * Generates a render dataset to render the lines between the grid columns in canvas.
   * @param gridColumnsRenderData grid render data
   * @returns Render data array (currently only the x positions of the lines)
   */
  private _generateLineRenderData(gridColumnsRenderData: IGanttGridColumnRenderData[]): IGanttGridLineRenderData[] {
    const lineRenderData = [];

    // get x positions of separation lines
    for (const d of gridColumnsRenderData) {
      if (d.index === 0) lineRenderData.push({ x: Math.round(d.fromX) + 0.5 });
      lineRenderData.push({ x: Math.round(d.toX) + 0.5 });
    }

    return lineRenderData;
  }

  /**
   * Clears the canvas.
   * @param scrollContainerId
   */
  private _clearCanvas(scrollContainerId: EGanttScrollContainer): void {
    const shiftViewPortProportions = this.ganttDiagram
      .getNodeProportionsState()
      .getShiftViewPortProportions(scrollContainerId);
    this._canvas[scrollContainerId]
      .node()
      .getContext('2d')
      .clearRect(0, 0, shiftViewPortProportions.width, shiftViewPortProportions.height);
  }

  //
  // OBSERVABLES
  //

  public get onDeactivation(): Observable<void> {
    return this._onDeactivationSubject.asObservable();
  }
}

interface IGanttGridColumnData {
  from: Date;
  to: Date;
  index: number;
}

interface IGanttGridColumnRenderData {
  fromX: number;
  toX: number;
  index: number;
}

interface IGanttGridLineRenderData {
  x: number;
}

interface IGanttGridTextRenderData {
  text: string;
  x: number;
  y: number;
}
