<div #dockWrapper class="dockWrapper">
  <div class="menuBar mat-typography">
    <!-- <div class="dockViewIcons">
      <button (click)="ganttDockService.toggleViewMode(viewModeTypes.RIGHT)" mat-icon-button matTooltipShowDelay="300"
        matTooltip="Dock rechts anzeigen"
        [ngClass]="(ganttDockService.currentDockViewMode==viewModeTypes.RIGHT)?'buttonSelected':'buttonDeselected'">
        <app-image class="icon" [label]="'VIEW_POSITION_RIGHT'"></app-image>
      </button>
      <button (click)="ganttDockService.toggleViewMode(viewModeTypes.BOTTOM)" mat-icon-button matTooltipShowDelay="300"
        matTooltip="Dock unten anzeigen"
        [ngClass]="(ganttDockService.currentDockViewMode==viewModeTypes.BOTTOM)?'buttonSelected':'buttonDeselected'">
        <app-image class="icon" [label]="'VIEW_POSITION_BOTTOM'"></app-image>
      </button>
      <button (click)="ganttDockService.toggleViewMode(viewModeTypes.UNDOCK)" mat-icon-button matTooltipShowDelay="300"
        matTooltip="Dock als separates Fenster"
        [ngClass]="(ganttDockService.currentDockViewMode==viewModeTypes.UNDOCK)?'buttonSelected':'buttonDeselected'">
        <app-image class="icon" [label]="'VIEW_POSITION_FREE'"></app-image>
      </button>
    </div> -->
    <div class="dockViewIcons">
      <button
        *ngIf="!menuOpen"
        (click)="menuOpen = true"
        mat-icon-button
        matTooltipShowDelay="300"
        matTooltip="Menü einblenden"
      >
        <app-image class="icon" [label]="'OPTIONS_OPEN'"></app-image>
      </button>
      <button
        *ngIf="menuOpen"
        (click)="menuOpen = false"
        mat-icon-button
        matTooltipShowDelay="300"
        matTooltip="Menü ausblenden"
      >
        <app-image class="icon" [label]="'OPTIONS_CLOSE'"></app-image>
      </button>
    </div>
    <div class="tabContainer">
      <ng-container *ngFor="let component of ganttDockService.getDockComponentsByViewType(dockViewType)">
        <div
          class="tab"
          [ngStyle]="{ 'width.%': 100 / ganttDockService.getDockComponentsByViewType(dockViewType).length }"
          [class.tabSelected]="ganttDockService.getCurrentDockComponentByViewType(dockViewType)?.id === component.id"
        >
          <div *ngIf="ganttDockService.isThereANotificationForComponent(component.id)" class="notificationAvailable">
            <mat-icon>fiber_manual_record </mat-icon>
          </div>
          <button
            (click)="ganttDockService.onTabChangeByViewMode(component.id, dockViewType)"
            mat-button
            (contextmenu)="onRightClick($event, component)"
          >
            {{ component.name }}
          </button>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="viewPort mat-typography">
    <saxms-best-gantt-legend
      *ngIf="ganttDockService.getCurrentDockComponentByViewType(dockViewType)?.type === dockComponentTypes.LEGEND"
      #BestGanttLegend
      [menuOpen]="menuOpen"
    >
    </saxms-best-gantt-legend>
    <app-resources
      *ngIf="ganttDockService.getCurrentDockComponentByViewType(dockViewType)?.type === dockComponentTypes.RESOURCES"
      [menuOpen]="menuOpen"
    >
    </app-resources>
  </div>

  <div
    style="visibility: hidden; position: fixed"
    [style.left]="contextMenuPosition.x"
    [style.top]="contextMenuPosition.y"
    [matMenuTriggerFor]="rightMenu"
  ></div>

  <mat-menu #rightMenu="matMenu">
    <ng-template matMenuContent let-item="item">
      <button
        mat-menu-item
        [disabled]="dockViewType === viewModeTypes.RIGHT"
        (click)="onDockChange(viewModeTypes.RIGHT, item)"
      >
        <app-image class="icon" [label]="'VIEW_POSITION_RIGHT'"></app-image>
        <span>Rechts anheften</span>
      </button>
      <button
        mat-menu-item
        [disabled]="dockViewType === viewModeTypes.BOTTOM"
        (click)="onDockChange(viewModeTypes.BOTTOM, item)"
      >
        <app-image class="icon" [label]="'VIEW_POSITION_BOTTOM'"></app-image>
        <span>Unten anheften</span>
      </button>
      <button
        mat-menu-item
        [disabled]="dockViewType === viewModeTypes.UNDOCK"
        (click)="onDockChange(viewModeTypes.UNDOCK, item)"
      >
        <app-image class="icon" [label]="'VIEW_POSITION_FREE'"></app-image>
        <span>Als separates Fenster</span>
      </button>
    </ng-template>
  </mat-menu>
</div>
