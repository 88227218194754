import { GanttLibService } from 'frontend/src/dashboard/gantt/gantt/gantt-lib.service';
import { GeneralGanttActionHandler } from 'frontend/src/dashboard/gantt/general/action-handling/action-handler';
import { GanttPluginHandlerService } from 'frontend/src/dashboard/gantt/general/plugin/gantt-plugin-handler.service';
import { GanttResponseHandler } from '../response/response-handler';
import { GanttEventTrigger } from './external-event-trigger';
import { EGanttActionEvent } from './gantt-action-handling/gantt-action-event.enum';

/**
 * Abstract structure for all event trigger factories.
 * A TriggerEventFactory instantiates given actions and force them to gantt registration.
 */
export abstract class TriggerEventFactory {
  constructor(
    private templateData: any,
    protected triggerEvents: TriggerEventFactoryItem[],
    protected predefinedEvents: (typeof GanttEventTrigger)[]
  ) {}

  /**
   * Creates and returns event triggers by given generic backend actions.
   * @param gantt BestGantt instance to register events in.
   * @param actions List of backend defined actions.
   * @param executer Gantt or JS plugin which should be inserted into events.
   * @param actionHandler Action handler to access all relevant data.
   * @param responseHandler To connect (global) actions with response handling.
   */
  public getTriggerEventByActionTypes(
    ganttPluginHandlerService: GanttPluginHandlerService,
    ganttLibService: GanttLibService,
    actions: any[] = [],
    executer: any,
    actionHandler: GeneralGanttActionHandler,
    responseHandler: GanttResponseHandler
  ): GanttEventTrigger[] {
    const allTriggerEvents: GanttEventTrigger[] = [];
    for (const actionEvent of actions) {
      for (const triggerEventWrapper of this.triggerEvents) {
        if (this.containsActionType(actionEvent, triggerEventWrapper.eventName)) {
          const eventTrigger: GanttEventTrigger = triggerEventWrapper.createInstance(
            ganttPluginHandlerService,
            executer,
            actionEvent,
            actionHandler,
            this.templateData,
            ganttLibService,
            responseHandler
          );
          eventTrigger.registerInGantt();
          allTriggerEvents.push(eventTrigger);
        }
      }
    }
    return allTriggerEvents;
  }

  /**
   * Creates and returns predefined event triggers.
   * @param gantt BestGantt instance to register events in.
   * @param executer Gantt or JS plugin which should be inserted into events.
   * @param actionHandler Action handler to access all relevant data.
   * @param responseHandler To connect (global) actions with response handling.
   */
  public getPredefinedEvents(
    ganttPluginHandlerService: GanttPluginHandlerService,
    ganttLibService: GanttLibService,
    executer: any,
    actionHandler: GeneralGanttActionHandler,
    responseHandler: GanttResponseHandler
  ): GanttEventTrigger[] {
    const allPredefinedEvents: GanttEventTrigger[] = [];
    for (const event of this.predefinedEvents) {
      const eventTrigger: GanttEventTrigger = new event(
        executer,
        actionHandler,
        this.templateData,
        responseHandler,
        ganttLibService,
        ganttPluginHandlerService
      );
      eventTrigger.registerInGantt();
      allPredefinedEvents.push(eventTrigger);
    }
    return allPredefinedEvents;
  }

  /**
   * Checks if given action contains given action type.
   * @param action Backend action definition.
   * @param actionType Type to check.
   */
  private containsActionType(action: any, actionType: string): boolean {
    if (!Array.isArray(action.actionTypes)) return false;
    return action.actionTypes.indexOf(actionType) > -1;
  }
}

/**
 * Structure to insert events into a TriggerEventFactory.
 * Combines event name and matching trigger.
 */
export class TriggerEventFactoryItem {
  constructor(public eventName: EGanttActionEvent, public triggerEvent: typeof GanttEventTrigger) {}

  /**
   * Creates instance by given triggerEvent.
   * @param executer Gantt or JS plugin which should be inserted into event.
   * @param actionEvent Backend defined action.
   * @param actionHandler Action handler to access all relevant data.
   * @param templateData Backend gantt template data.
   * @param responseHandler To connect (global) action with response handling.
   */
  public createInstance(
    ganttPluginHandlerService: GanttPluginHandlerService,
    executer: any,
    actionEvent: any,
    actionHandler: GeneralGanttActionHandler,
    templateData: any,
    ganttLibService: GanttLibService,
    responseHandler: GanttResponseHandler
  ): GanttEventTrigger {
    return new this.triggerEvent(
      executer,
      actionHandler,
      templateData,
      responseHandler,
      ganttLibService,
      ganttPluginHandlerService,
      actionEvent
    );
  }
}
