export enum ENotificationCode {
  NONE = -1,
  CLONE_MODEL = 11111,
  BDE_PLAN_RCALCULATION_FAILED = 20002,
  BDE_RECALCULATION_FAILED = 20003,
  BDE_AUTO_STEERING_RECALCULATION_SUCCESSFUL = 20004,
  BDE_STEERING_RECALCULATION_SUCCESSFUL_PLANNER_DECISION_REQUIRED = 20005,
  BDE_STEERING_RECALCULATION_SUCCESSFUL_NO_HANDLING_REQUIRED = 20006,
  BDE_SUGGESTED_PLAN_PUBLISHED = 20007,
  SOLVER_NEW_SOLVER_RESULT = 501739,
  STOP_SOLVER = 100000,
  ACCEPT_SOLVER_RESULT = 100001,
  CACLULATE_WORKING_TIME_FINISHED = 100002,
  TIMELINE_PUBLISH_PLAN = 22222,
  TIMELINE_REIMPORT = 33333,
  TIMELINE_SYNCHRONIZE = 44444,
  TIMELINE_DELETE = 55555,
  EXPERIMENT = 8888,
}
