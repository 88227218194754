export enum EFilterConditions {
  begins_with = 'begins_with',
  between = 'between',
  by_value = 'by_value',
  contains = 'contains',
  empty = 'empty',
  ends_with = 'ends_with',
  eq = 'eq', // Equal
  gt = 'gt', // Greater than
  gte = 'gte', // Greater than or equal
  lt = 'lt', // Less than
  lte = 'lte', // Less than or equal
  none = 'none', // None(no filter)
  not_between = 'not_between',
  not_contains = 'not_contains',
  not_empty = 'not_empty',
  neq = 'neq', // Not equal
}
