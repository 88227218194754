import { GanttCallBackStackExecuter } from '../../callback-tools/callback-stack-executer';
import { GanttCanvasShift } from '../../data-handler/data-structure/data-structure';
import { BestGantt } from '../../main';
import { IShiftClickEvent } from '../../shifts/shift-events.interface';

/**
 * Handler methods for shift click events.
 */
export class GanttShiftClickEventsHandler {
  constructor(private _ganttDiagram: BestGantt) {}

  /**
   * @keywords select, shift, click, mark, callback
   * @param callBackData
   */
  public selectShiftByClick(callBackData: IShiftClickEvent): void {
    this._selectShiftByEvent(
      callBackData.target.data()[0],
      !callBackData.event.shiftKey && !callBackData.event.ctrlKey
    );
  }

  /**
   * @keywords select, shift, context, menu, right, click, mark, callback
   * @param callBackData
   */
  public selectShiftByContextMenu(callBackData: IShiftClickEvent): void {
    const s = this._ganttDiagram;
    if (!callBackData.target || !callBackData.target.data()) return;
    const isSelected = s
      .getSelectionBoxFacade()
      .getSelectedShifts()
      .find((s) => s.id === callBackData.target.data()[0].id)
      ? true
      : false;
    if (!isSelected) {
      this._selectShiftByEvent(callBackData.target.data()[0], true);
    }
    GanttCallBackStackExecuter.execute(s.callbacks.selectShiftsByRightClick, callBackData);
  }

  /**
   * @param shiftData
   * @param removeOldSelection
   */
  private _selectShiftByEvent(shiftData: GanttCanvasShift, removeOldSelection: boolean): void {
    const s = this._ganttDiagram;

    const canvasDataSet = s.getDataHandler().getCanvasShiftDataset();
    const data = canvasDataSet.filter((shift) => shift.id === shiftData.id)[0];
    const originDataSet = s.getDataHandler().getOriginDataset().ganttEntries;

    // single click without using shift or ctrl key
    if (removeOldSelection) {
      s.getSelectionBoxFacade().deselectAllShifts(canvasDataSet, originDataSet);
      s.getSelectionBoxFacade().selectShiftById(canvasDataSet, originDataSet, shiftData.id);
    } else {
      // shift or ctrl key
      const isSelectedBefore = !!data.selected;
      if (isSelectedBefore) {
        s.getSelectionBoxFacade().deselectShiftById(canvasDataSet, originDataSet, shiftData.id);
      } else {
        s.getSelectionBoxFacade().selectShiftById(canvasDataSet, originDataSet, shiftData.id);
      }
    }

    s.rerenderShiftsVertical();
  }
}
