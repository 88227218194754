import { Pipe, PipeTransform } from '@angular/core';
import { EDatatype } from 'frontend/src/dashboard/model/entry/datatype.enum';
import { EUnit } from 'frontend/src/dashboard/model/entry/unit.enum';
import { EntryElement } from '../entry-collection/entry-element';

@Pipe({
  name: 'unitPipe',
  pure: true,
})
export class UnitPipe implements PipeTransform {
  private timeoutId: number;
  transform(
    curVal: any,
    direction: 'forward' | 'backward',
    entryElement: EntryElement,
    attribute?: string,
    inputRef?: HTMLInputElement
  ): any {
    let value = curVal;
    // number of initial decimals if no step width is given
    if (value === null || value === undefined) {
      return curVal;
    }
    let defaultNumberOfDecimals = 1;

    // min and max values
    let min: number = entryElement.getLowerBound();
    let max: number = entryElement.getUpperBound();
    let step: number = entryElement.getStepWidth();
    switch (entryElement.getDatatype()) {
      case EDatatype.BOOLEAN:
        if (typeof value === 'string') {
          value = value === 'true' ? true : false;
        }
        break;
      case EDatatype.DOUBLE:
      case EDatatype.LONG:
      case EDatatype.FLOAT:
        if (typeof value === 'string') {
          // remove dots and commas
          value = value.split('.').join('').split(',').join('.');
          value = parseFloat(value);
        }
        // check if value is in bounds
        value = !isNaN(min) && value < min ? min : !isNaN(max) && value > max ? max : value;
        switch (entryElement.getUnit()) {
          case EUnit.PERCENTAGE:
            // add offset if necessary
            min = !isNaN(entryElement.getLowerBound())
              ? direction === 'forward'
                ? entryElement.getLowerBound() * 100
                : entryElement.getLowerBound() / 100
              : null;
            max = !isNaN(entryElement.getUpperBound())
              ? direction === 'forward'
                ? entryElement.getUpperBound() * 100
                : entryElement.getUpperBound() / 100
              : null;
            step = !isNaN(entryElement.getStepWidth())
              ? direction === 'forward'
                ? entryElement.getStepWidth() * 100
                : entryElement.getStepWidth() / 100
              : null;
            value = direction === 'forward' ? value * 100 : value / 100;
            defaultNumberOfDecimals = 2;
            break;
        }

        const selectionStart: number = inputRef?.selectionStart;
        const selectionEnd: number = inputRef?.selectionEnd;
        break;
      case EDatatype.INTEGER:
        // check if value is in bounds
        value = !isNaN(min) && value < min ? min : !isNaN(max) && value > max ? max : value;
        break;
      case EDatatype.STRING:
        value = value.toString();
        break;
    }

    switch (attribute) {
      case 'min':
        return min;
      case 'max':
        return max;
      case 'step':
        return step;
      case 'float':
        const decimals: number = entryElement.getStepWidth()
          ? this._countDecimals(entryElement.getStepWidth())
          : defaultNumberOfDecimals;
        const r: string = parseFloat(value).toFixed(decimals);
        return r;
    }
    return value;
  }

  private _countDecimals(value: number): number {
    if (Math.floor(value) !== value) {
      const splitted: string[] = value.toString().split('.');
      if (splitted[1]) {
        return splitted[1].length || 0;
      }
    }

    return 0;
  }
}
