import { Injectable } from '@angular/core';
import { TemplateService } from '@app-modeleditor/utils/template.service';
import { CloudMessagingService } from '@core/notification/cloud-messaging.service';
import { Notification } from '@core/notification/notification';
import { ENotificationType } from '@core/notification/notification-type.enum';
import { ActionMapper } from 'frontend/src/dashboard/shared/data-access/actions/action-mapper';
import {
  ActionService,
  ExecuteResult,
  LastInTuple,
} from 'frontend/src/dashboard/shared/data-access/actions/action.service';
import { ID } from 'frontend/src/dashboard/shared/data-access/actions/actions';
import { Logger } from 'frontend/src/dashboard/shared/utils/logger';
import { BehaviorSubject, Observable, Subject, of, throwError } from 'rxjs';
import { catchError, filter, finalize, last, map, switchMap, take, tap } from 'rxjs/operators';
import { TemplateAdapter } from './../../utils/template-factory.service';
import { ActionAdapter } from './action-adapter.service';
import { Action } from './action/action';
import { ActionChain } from './action/action-chain';
import { Button } from './button';

export enum EButtonLifecycle {
  BEFORE_REQUEST = 'before-request',
  AFTER_REQUEST = 'after-request',
  BEFORE_ACTION = 'before-action',
  AFTER_ACTION = 'after-action',
}

export interface IButtonHook {
  lifecycle: EButtonLifecycle;
  data: any;
  id: string;
}

@Injectable({
  providedIn: 'root',
})
export class ButtonService {
  private readonly logger = new Logger('buttons');
  public hook: Subject<IButtonHook> = new Subject<IButtonHook>();
  private executingBtns = new BehaviorSubject<Button[]>([]);

  constructor(
    private templateFactory: TemplateAdapter,
    private actionFactory: ActionAdapter,
    private fcm: CloudMessagingService,
    private executorService: ActionService,
    private mapper: ActionMapper,
    private templateService: TemplateService
  ) {
    this.fcm.getMessage().subscribe((message: Notification) => {
      if (message.getType() === ENotificationType.AUTHENTICATE_GRAPH_API && message.getActions()?.length) {
        this.executeActions(message.getActions()).subscribe();
      } else {
        this.updateBtn(message.getId());
      }
    });

    this.listenToCloseMenuItemActions();
  }

  public getPendingButtons(): Observable<Button[]> {
    return this.executingBtns.asObservable();
  }

  /**
   * Listens for CloseMenuItem events and executes the associated actions.
   */
  private listenToCloseMenuItemActions() {
    this.templateService.onClosedMenuItemAction().subscribe((action) => {
      this.executeActions([action]).subscribe();
    });
  }

  private updateBtn(pendingId: string): void {
    this.executingBtns
      .getValue()
      .forEach((btn) => btn.setPendingNotification(btn.getPendingNotification().filter((item) => item !== pendingId)));
    this.executingBtns.next(this.executingBtns.getValue());
  }

  private addPending(btn: Button): void {
    this.executingBtns.getValue().push(btn);
    this.executingBtns.next(this.executingBtns.getValue());
  }

  private removePending(btn: Button): void {
    this.executingBtns.next(this.executingBtns.getValue().filter((item) => item.getId() !== btn.getId()));
  }

  private hasPending(btnId: string): Observable<boolean> {
    return this.executingBtns.asObservable().pipe(
      map((items) =>
        (items.find((item) => item.getId() === btnId)?.getPendingNotification()?.length || 0) > 0 ? true : false
      ),
      filter((item) => item === false)
    );
  }

  private setHook(id: string, cycle: EButtonLifecycle, data: any) {
    this.hook.next({ lifecycle: cycle, data: data, id: id });
  }

  private resolveButton(btn: any): Button {
    if (btn instanceof Button) {
      return btn;
    }

    return this.templateFactory.adapt(btn);
  }

  public resolveAction(action: any): Action {
    if (!(action instanceof Action)) {
      this.logger.warn<unknown>('action is not an instance of Action', action);
    }
    return action instanceof Action ? action : this.actionFactory.parseAction(Action, action);
  }

  /**
   * executes a series of actions defined as button
   * @param button IButton
   * @returns Observable
   */
  onClick<C extends ID[]>(
    button: any,
    forceList?: any[],
    options?: { overrideActions?: boolean; event?: MouseEvent }
  ): ExecuteResult<LastInTuple<C>> {
    // constructs a reference to class
    const btn: Button = this.resolveButton(button);

    // add pending
    if (btn instanceof Button) {
      this.addPending(btn);
    }
    this.logger.info<unknown>('EXECUTE BUTTON', btn, forceList, options);
    // actions which will be defined locally

    const forcedActions: Action[] = (forceList || []).map((f) => this.resolveAction(f));
    this.setHook(btn.getId(), EButtonLifecycle.BEFORE_REQUEST, null);
    // get chain and extends it by forced actions
    const l: ActionChain = options?.overrideActions
      ? new ActionChain(...forcedActions)
      : btn.getChain().addActions(...forcedActions);

    return this.executeActions(l.getActions(), options?.event, btn)
      .pipe(
        map((result) => {
          this.setHook(btn.getId(), EButtonLifecycle.AFTER_REQUEST, result);
          return result;
        }),
        catchError((e: Error) => {
          this.setHook(btn.getId(), EButtonLifecycle.AFTER_REQUEST, e);
          return throwError(() => e);
        })
      )
      .pipe(
        tap((result) =>
          this.hasPending(btn.getId()).pipe(
            switchMap(() => of(result)),
            take(1)
          )
        ),
        finalize(() => this.removePending(btn))
      );
  }

  /**
   * executes actions in sequence
   * @param actions Action[]
   * @returns Observable<any>
   */
  public executeActions<C extends ID[]>(
    actions: Action[],
    event: MouseEvent = null,
    button: Button = null
  ): ExecuteResult<LastInTuple<C>> {
    const chain = this.mapper.mapActions(
      actions.map((a) => this.resolveAction(a)),
      event,
      button
    );

    return this.executorService.executeActions(...chain).pipe(last()) as ExecuteResult<LastInTuple<C>>;
  }
}
