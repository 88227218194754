import { EntryElement } from '../entry-collection/entry-element';
import { EntryElementValue } from '../entry-collection/entry-element-value';
import { EFieldType } from '../entry-collection/field-type.enum';

export class AutocompleteTextfield extends EntryElement {
  private onOptionsCB: (tag: string) => void;
  private options: EntryElementValue[];
  private invalidOnNoOptions: boolean;

  public isInvalidOnNoOptions(): boolean {
    return !!this.invalidOnNoOptions;
  }

  public setInvalidOnNoOptions(invalidOnNoOptions: boolean): this {
    this.invalidOnNoOptions = invalidOnNoOptions;
    return this;
  }

  public getOptions(): EntryElementValue[] {
    return this.options || [];
  }

  public setOptions(options: EntryElementValue[]): this {
    this.options = options;
    return this;
  }

  constructor() {
    super();
    this.setFieldType(EFieldType.AUTOCOMPLETE_TEXT_FIELD);
  }

  public onGetOptions(cb: (tag: string) => void): this {
    this.onOptionsCB = cb;
    return this;
  }

  public triggerOptions(tag: string): void {
    if (this.onOptionsCB) {
      this.onOptionsCB(tag);
    }
  }
}
