import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { TranslateModule } from '@ngx-translate/core';
import { SaxmsTooltipModule } from '../tooltip/tooltip.module';
import { TemplateChatComponent } from './template-chat.component';
import { MessageItemComponent } from './message-item/message-item.component';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';

@NgModule({
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatButtonModule,
    TranslateModule,
    MatMenuModule,
    MatIconModule,
    MatToolbarModule,
    MatInputModule,
    SaxmsTooltipModule,
    MatTooltipModule,
  ],
  exports: [TemplateChatComponent],
  declarations: [TemplateChatComponent, MessageItemComponent],
})
export class TemplateChatModule {}
