import { IHSLValues, IRGBValues } from '../color-data-types.interface';

/**
 * Class providing static methods for color converting.
 */
export abstract class GanttColorConverter {
  /**
   * Helper function to convert HSL color values to a hex color string.
   * @param hslValues Color values of the HSL color to convert.
   * @returns Hex color string.
   */
  public static hslToHex(hslValues: IHSLValues): string {
    const rgbValues = GanttColorConverter.hslToRgb(hslValues);
    return GanttColorConverter.rgbToHex(rgbValues);
  }

  /**
   * Helper function to convert RGB color values to a hex color string.
   * @param rgbValues Color values of the RGB color to convert.
   * @returns Hex color string.
   */
  public static rgbToHex(rgbValues: IRGBValues): string {
    const rhex = Math.round(rgbValues.r).toString(16).padStart(2, '0');
    const ghex = Math.round(rgbValues.g).toString(16).padStart(2, '0');
    const bhex = Math.round(rgbValues.b).toString(16).padStart(2, '0');
    return `#${rhex}${ghex}${bhex}`;
  }

  /**
   * Helper function to convert a hex color string to RGB color values.
   * @param hexValue Hex color string to be converted.
   * @returns RGB color values.
   */
  public static hexToRgb(hexValue: string): IRGBValues {
    const rgbValues = { r: 128, g: 128, b: 128 };

    let c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hexValue)) {
      c = hexValue.substring(1).split('');
      if (c.length == 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = '0x' + c.join('');

      rgbValues.r = (c >> 16) & 255;
      rgbValues.g = (c >> 8) & 255;
      rgbValues.b = c & 255;
    }
    // console.warn(`invalid color value provided`, hexValue);
    return rgbValues;
  }

  /**
   * Helper function to convert HSL color values to RGB color values.
   * @param hslValues Color values of the HSL color to convert.
   * @returns RGB color values.
   */
  public static hslToRgb(hslValues: IHSLValues): IRGBValues {
    const h = hslValues.h;
    const s = hslValues.s / 100;
    const l = hslValues.l / 100;

    const a = s * Math.min(l, 1 - l);
    const rgbval = (v: 'r' | 'g' | 'b') => {
      const n = v === 'g' ? 8 : v === 'b' ? 4 : 0;
      const k = (n + h / 30) % 12;
      return l - a * Math.max(-1, Math.min(k - 3, 9 - k, 1));
    };

    return {
      r: rgbval('r') * 255,
      g: rgbval('g') * 255,
      b: rgbval('b') * 255,
    };
  }
}
