import { GanttTimePeriodExecuter, TimePeriodAddData } from '@gantt/public-api';
import { IGanttResponse } from 'frontend/src/dashboard/gantt/general/response/gantt-response';
import { GanttResponse } from 'frontend/src/dashboard/gantt/general/response/responses/response';
import { GanttEssentialPlugIns } from '../../../../e-gantt-essential-plugins';
import { GanttBlockingIntervalsPlugIn, IBlockingIntervalAdditionalData } from '../../blocking-intervals.plugin';
import { ITimePeriodInput } from './time-period-input.interface';

/**
 * Reponse handling to update blocking interval data.
 */
export class GanttResponseBlockingIntervalUpdate extends GanttResponse<GanttTimePeriodExecuter> {
  /**
   * Handles response by given change strategy.
   * @param response Backend response.
   */
  public handleResponse(response: IGanttResponse): boolean {
    switch (response.ganttChangeMode) {
      case 'REPLACE_GANTT_ENTRIES':
        // replace/affect only this entries, which are inside gantt entry dataset.
        if (
          response.ganttEntries &&
          response.updatedPluginData &&
          response.updatedPluginData['template.ganttplugin.blockinginterval'] &&
          response.updatedPluginData['template.ganttplugin.blockinginterval'] instanceof Array &&
          response.updatedPluginData['template.ganttplugin.blockinginterval'].length > 0
        ) {
          const blockingIntervalPlugIn: GanttBlockingIntervalsPlugIn = this._pluginHandlerService.getEssentialPlugIn(
            GanttEssentialPlugIns.BlockingIntervalPlugIn
          );
          const blockIngIntervalGroup = response.updatedPluginData['template.ganttplugin.blockinginterval'][0];
          const arrayOfAttributeMappingTypes = blockingIntervalPlugIn.getArrayOfAttributeMappingTypes();
          for (const blockingIntervalType in blockIngIntervalGroup) {
            if (blockingIntervalType != this.executer.getAdditionalData().type) continue;
            const responseBlockingIntervals: ITimePeriodInput[] = blockIngIntervalGroup[blockingIntervalType];
            for (const entry of response.ganttEntries) {
              const matchingIntervals = responseBlockingIntervals.filter(
                (interval) => interval.ganttEntryId == entry.id
              );
              const mappedIntervals = blockingIntervalPlugIn.intervalMapper.getIntervalInputByBackendData(
                matchingIntervals,
                arrayOfAttributeMappingTypes,
                blockingIntervalType
              );
              this.executer.removeAllTimePeriodsFromRow(entry.id);

              const periods: TimePeriodAddData[] = mappedIntervals.map((periodInput): TimePeriodAddData => {
                return {
                  rowId: periodInput.rowId,
                  timeStart: periodInput.timeStart,
                  timeEnd: periodInput.timeEnd,
                  intervalId: periodInput.intervalId,
                  customColor: periodInput.customColor,
                  stroke: periodInput.stroke,
                  additionalDetails: periodInput.details,
                  tooltip: periodInput.tooltip,
                  name: periodInput.name,
                  sIds: periodInput.sIds,
                };
              });
              this.executer.addTimePeriodsToRow(periods, false);
            }
          }
        }
        break;
      case 'REPLACE_BLOCKS':
      default:
        // check if response has all necessary data
        // TODO: ganttTimePeriodInput ist deprecated, use ganttTimePeriodInputs instead
        if (response.ganttTimePeriodInput && response.ganttTimePeriodInput.ganttTimePeriodGroupIntervalInputId) {
          console.warn('Property ganttTimePeriodInput is deprecated use ganttTimePeriodInputs instead!');
          // check if executer matches response
          if (
            (this.executer.getAdditionalData() as IBlockingIntervalAdditionalData)
              .ganttTimePeriodGroupIntervalInputId != response.ganttTimePeriodInput.ganttTimePeriodGroupIntervalInputId
          )
            return false;
          this.updateBlockingIntervals(response.ganttTimePeriodInput, this.executer);
          return true;
        } else if (
          response.ganttTimePeriodInputs &&
          Array.isArray(response.ganttTimePeriodInputs) &&
          response.ganttTimePeriodInputs.length
        ) {
          // check if executer matches response
          for (const ganttTimePeriodInput of response.ganttTimePeriodInputs) {
            const additionalData = this.executer.getAdditionalData() as IBlockingIntervalAdditionalData;
            if (
              !ganttTimePeriodInput.ganttTimePeriodGroupIntervalInputId ||
              additionalData.ganttTimePeriodGroupIntervalInputId !=
                ganttTimePeriodInput.ganttTimePeriodGroupIntervalInputId
            ) {
              continue;
            } else {
              this.updateBlockingIntervals(ganttTimePeriodInput, this.executer);
            }
          }
          return true;
        } else if (
          response.updatedPluginData &&
          response.updatedPluginData['template.ganttplugin.blockinginterval'] &&
          response.updatedPluginData['template.ganttplugin.blockinginterval'] instanceof Array
        ) {
          for (const blockIngIntervalGroup of response.updatedPluginData['template.ganttplugin.blockinginterval']) {
            for (const blockingIntervalType in blockIngIntervalGroup) {
              if (blockingIntervalType != this.executer.getAdditionalData().type) continue;
              const allBlockingIntervalInstances: any[] = blockIngIntervalGroup[blockingIntervalType];
              for (const blockingIntervalInstanceResponse of allBlockingIntervalInstances) {
                // replace interval if does exist
                this.updateBlockingIntervals(blockingIntervalInstanceResponse, this.executer);
              }
            }
          }
        } else if (this.getDeletedTimePeriodInput(response)) {
          const blockingIntervalPlugIn: GanttBlockingIntervalsPlugIn = this._pluginHandlerService.getEssentialPlugIn(
            GanttEssentialPlugIns.BlockingIntervalPlugIn
          );
          this.getDeletedTimePeriodInput(response).forEach((val: ITimePeriodInput) => {
            // remove from template data
            this.checkSelectionInTemplateData(val.id);

            // remove from js gantt
            blockingIntervalPlugIn.getGeneratedBlockingIntervalIdsByRealId(val.id).forEach((id: string) => {
              this.executer.deselectTimePeriodById(id);
              this.executer.removeTimePeriodById(id, false);
            });
          });
        }
    }

    return true;
  }

  private getDeletedTimePeriodInput(response: IGanttResponse): ITimePeriodInput[] {
    if (response.deleteGanttTimePeriodInput) {
      return [response.deleteGanttTimePeriodInput];
    }
    if (response.deleteGanttTimePeriodInputs) {
      return response.deleteGanttTimePeriodInputs;
    }
    return response.deletedGanttTimePeriodInputs || [];
  }

  /**
   * Checks if a selected interval is in template data and removes it from there.
   * @param originIntervalId id of interval
   */
  private checkSelectionInTemplateData(originIntervalId: string): void {
    const templateData = this._pluginHandlerService.getTemplateData();

    if (
      templateData.getSelectedblock() &&
      templateData.getSelectedblock().id &&
      templateData.getSelectedblock().id === originIntervalId
    ) {
      templateData.setSelectedblock({}); // TODO: must be removed as soon as the backend uses the selectedTimePeriodInput attribute (14.06.21)
    }

    if (
      templateData.getSelectedTimePeriodInput() &&
      templateData.getSelectedTimePeriodInput().id &&
      templateData.getSelectedTimePeriodInput().id === originIntervalId
    ) {
      templateData.setSelectedTimePeriodInput(null);
    }

    templateData.setSelectedTimePeriodInputs(
      templateData.getSelectedTimePeriodInputs()?.filter((elem) => elem.id !== originIntervalId)
    );
  }

  /**
   * Removes blocking interval ifit already does exist and add replace it with a new one.
   * @param ganttTimePeriodInput Backend response data of a time period input.
   * @param intervalExecuter GanttTimePeriodExecuter
   */
  private updateBlockingIntervals(
    ganttTimePeriodInput: ITimePeriodInput,
    intervalExecuter: GanttTimePeriodExecuter
  ): void {
    if (!intervalExecuter) return;
    const blockingIntervalPlugIn: GanttBlockingIntervalsPlugIn = this._pluginHandlerService.getEssentialPlugIn(
      GanttEssentialPlugIns.BlockingIntervalPlugIn
    );
    const intervals = blockingIntervalPlugIn.getGeneratedBlockingIntervalIdsByRealId(ganttTimePeriodInput.id);
    // create new interval if not existing

    if (intervals.length) {
      // remove old blocking interval
      intervals.forEach((intervalId) => {
        this.executer.removeTimePeriodById(intervalId, false);
        blockingIntervalPlugIn.intervalMapper.getMultipleIntervalIds().delete(intervalId);
      });
    }

    try {
      const blockingIntervalPlugIn: GanttBlockingIntervalsPlugIn = this._pluginHandlerService.getEssentialPlugIn(
        GanttEssentialPlugIns.BlockingIntervalPlugIn
      );
      const arrayOfAttributeMappingTypes = blockingIntervalPlugIn.getArrayOfAttributeMappingTypes();
      const periodInputs = blockingIntervalPlugIn.intervalMapper.getIntervalInputByBackendData(
        [ganttTimePeriodInput],
        arrayOfAttributeMappingTypes,
        intervalExecuter.getType()
      );

      const periods: TimePeriodAddData[] = periodInputs.map((periodInput): TimePeriodAddData => {
        return {
          rowId: periodInput.rowId,
          timeStart: periodInput.timeStart,
          timeEnd: periodInput.timeEnd,
          intervalId: periodInput.intervalId,
          customColor: periodInput.customColor,
          stroke: periodInput.stroke,
          additionalDetails: periodInput.details,
          tooltip: periodInput.tooltip,
          name: periodInput.name,
          sIds: periodInput.sIds,
        };
      });

      intervalExecuter.addTimePeriodsToRow(periods, false);
    } catch (e) {
      console.warn(e);
    }
  }
}
