import { Component, Inject } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { ResourceRequirement } from '@app-nodenetwork/data/node-network-node';
import { NodeNetworkEditService } from '@app-nodenetwork/service/node-network.edit.service';

@Component({
  selector: 'node-network-edit-dialog',
  templateUrl: './node-network-edit-dialog.html',
  styleUrls: ['./node-network-edit-dialog.scss'],
})

/**
 * Angular component to show edit dialog content to specify the new node.
 */
export class NodeNetworkEditDialog {
  styleRef: any;
  resRequirements: ResourceRequirement[];
  nodeType: string;
  nodeSubTypes: { label: string; type: string }[];
  selectedSubType: string;

  nodeProcessTypes: { label: string; type: string }[];
  selectedProcessType: string;

  inputName: string;
  processName: string;
  subProcessName: string;

  constructor(
    public dialogRef: MatDialogRef<NodeNetworkEditDialog>,
    public editService: NodeNetworkEditService,
    @Inject(MAT_DIALOG_DATA) public injectedData: any
  ) {
    this.resRequirements = [];
    this.nodeType = injectedData.type;
    this.nodeSubTypes = [];
    this.nodeProcessTypes = [];
  }

  ngOnInit() {
    if (!this.nodeType) this.dialogRef.close(false);

    this.editService.getStepSubtypes(this.nodeType).subscribe((subTypes) => {
      for (const type in subTypes) {
        this.nodeSubTypes.push({ label: type, type: subTypes[type] });
      }

      if (this.nodeSubTypes.length == 0) {
        this.getProcessTypes(this.nodeType);
      }
    });
  }

  /**
   * Calls process types from backend by selected node type.
   * @param nodeType Backend node type.
   */
  public getProcessTypes(nodeType: string): void {
    this.editService.getStepProcesses(nodeType).subscribe(
      (processes) => {
        for (const process in processes) {
          this.nodeProcessTypes.push({ label: process, type: processes[process] });
        }
      },
      (err) => {
        console.error('Error while trying to get processes of step: ' + nodeType);
        console.error(err);
      }
    );
  }

  /**
   * Execution function to add the specified node.
   */
  public add(): void {
    const result = {
      processType: this.selectedProcessType,
      nodeType: this.nodeType,
      name: this.inputName,
      processName: this.processName,
      subProcessName: this.subProcessName,
    };

    if (!result.name) result.name = 'New';
    if (this.selectedSubType) result.nodeType = this.selectedSubType;
    this.dialogRef.close(result);
  }
}
