<div
  (click)="release()"
  *ngIf="template.isDraggable(); else defaultContent"
  cdkDropList
  [cdkDropListDisabled]="disabled"
  [cdkDropListData]="[template]"
  (cdkDropListEntered)="enter($event)"
  (cdkDropListDropped)="drop($event)"
  [id]="id"
  [style.all]="'inherit'"
  #wrappingContainer
  [class.highlighted]="active"
  [class.is-dropzone]="isDropzone"
  [class.draggable]="draggable"
>
  <!-- draggable item connected to droplist -->
  <div *cdkDragPlaceholder></div>
  <div
    #draggableItem
    (cdkDragMoved)="move($event)"
    (cdkDragStarted)="start($event)"
    (cdkDragReleased)="release($event)"
    [cdkDragDisabled]="!draggable || disabled"
    [class.active]="active"
    class="inner-dropzone"
    [cdkDragData]="template"
    cdkDrag
  >
    <div *cdkDragPreview class="preview">{{ dragPreviewLabel }}</div>
    <div class="placeholder" *cdkDragPlaceholder></div>
    <ng-container *ngTemplateOutlet="defaultContent"></ng-container>
  </div>
</div>

<ng-template #defaultContent>
  <ng-content></ng-content>
</ng-template>
