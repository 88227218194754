import { EGanttShiftsMultiDragStrategy } from '../../edit-shifts/shift-translation/multi-drag/shifts-multi-drag-strategy.enum';

/**
 * Container class for gantt shift edit config.
 * @param shiftResize Gantt shift resize config.
 * @param shiftTranslation Gantt shift translation config.
 * @param visualizeNotAllowed Flag indicationg if forbidden shift edit actions should be visualized or not.
 * @param visualizeNotAllowedTolerance Pixel value of the tolerance area for the visualization of forbidden shift edit actions.
 */
export class GanttShiftEditConfig {
  public readonly shiftResize = new GanttShiftResizeConfig();
  public readonly shiftTranslation = new GanttShiftTranslationConfig();
  public visualizeNotAllowed = true;
  public visualizeNotAllowedTolerance = 20;
}

/**
 * Container class for gantt shift resize config.
 * @param resizeHandles Resize handle config.
 */
export class GanttShiftResizeConfig {
  public readonly resizeHandles = new GanttShiftResizeHandleConfig();
}

/**
 * Container class for gantt shift resize handle config.
 * @param width Resize handle width.
 */
export class GanttShiftResizeHandleConfig {
  public width = 20;
}

/**
 * Container class for gantt shift resize config.
 * @param multiDragStrategy Active strategy for dragging multiple shifts at once.
 */
export class GanttShiftTranslationConfig {
  public multiDragStrategy = EGanttShiftsMultiDragStrategy.ONE_LINE_DRAG;
}
