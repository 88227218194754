import { EntryElement } from '../entry-collection/entry-element';
import { EButtonDisplayType } from '../button/button-display-type.enum';
import { Button } from '../button/button';

export class ButtonGroup extends EntryElement {
  private displayType: EButtonDisplayType;
  private availableButtons: Button[];
  constructor() {
    super();
  }

  public setDisplayType(type: EButtonDisplayType): this {
    this.displayType = type;
    return this;
  }

  public getDisplayType(): EButtonDisplayType {
    return this.displayType;
  }

  public getAvailableButtons(): Button[] {
    return this.availableButtons || [];
  }
  public addAvailableButtons(...availableButtons: Button[]): this {
    availableButtons.forEach((btn) => btn.setDisplayType(this.getDisplayType()));
    this.availableButtons = this.getAvailableButtons().concat(availableButtons);
    return this;
  }
}
