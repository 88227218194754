import { EGanttChangeMode } from '../../gantt-change-mode.enum';
import { IGanttResponse } from '../../gantt-response';
import { GanttResponse } from '../response';
import { GanttAddEntriesResponse } from './add-entries/gantt-add-entries-response';
import { GanttResponsePreparationHandler } from './data-preparation/preparation-handler';
import { GanttDeleteEntriesResponse } from './delete-entries/delete-entries-response';
import { GanttDeleteBlocksResponse } from './delete/delete-blocks';
import { GanttResponseStrategy } from './strategies/i-response-strategy';
import { GanttRowUpdateRefreshGanttEntryStrategy } from './strategies/refresh-gantt-entries.strategy';
import { GanttRowUpdateRefreshGanttStrategy } from './strategies/refresh-gantt.strategy';
import { GanttRowUpdateReplaceBlockStrategy } from './strategies/replace-blocks.strategy';

/**
 * Handling of repemoving/adding/replacing rows and shift blocks by backend data.
 */
export class GanttResponseRowUpdate extends GanttResponse {
  private responsePreparationHandler: GanttResponsePreparationHandler = new GanttResponsePreparationHandler();

  /**
   * Handles incoming server messages by using different strategies.
   * @param response Server response/data.
   */
  public handleResponse(response: IGanttResponse): boolean {
    const ganttDiagram = this._ganttLibService.bestGantt;

    if (response.ganttChangeMode && response.ganttChangeMode === EGanttChangeMode.REFRESH_GANTT) {
      return new GanttRowUpdateRefreshGanttStrategy().update(
        response,
        this._ganttLibService,
        this._pluginHandlerService,
        this.attributeMapping
      );
    }
    let handledResponse = false;

    response = this.responsePreparationHandler.prepareResponse(response);

    // delete entries
    if (response.deletedGanttEntries) {
      const deleteEntries: GanttDeleteEntriesResponse = new GanttDeleteEntriesResponse(
        this.executer,
        this._pluginHandlerService
      );
      deleteEntries.handleResponse(response);
      handledResponse = true;
    }

    // add new gantt entries
    if (response.addedGanttEntries) {
      const addNewGanttEntries: GanttAddEntriesResponse = new GanttAddEntriesResponse(
        this.executer,
        this._pluginHandlerService,
        this.attributeMapping,
        this._ganttLibService
      );
      addNewGanttEntries.handleResponse(response);

      const backendMapper = this._ganttLibService.backendToGanttOriginInputMapper;
      backendMapper.reinitaliseBlockClones(ganttDiagram.getDataHandler().getOriginDataset().ganttEntries);

      ganttDiagram.getShiftFacade().preloadPatterns();
      handledResponse = true;
    }

    // delete blocks
    if (response.deletedBlocks) {
      const deleteBlocks: GanttDeleteBlocksResponse = new GanttDeleteBlocksResponse(
        this._pluginHandlerService,
        this._ganttLibService
      );
      deleteBlocks.handleResponse(response);

      const backendMapper = this._ganttLibService.backendToGanttOriginInputMapper;
      backendMapper.reinitaliseBlockClones(ganttDiagram.getDataHandler().getOriginDataset().ganttEntries);
      handledResponse = true;
    }

    // update gantt entries
    if (response.ganttEntries) {
      let activeStrategy: GanttResponseStrategy = new GanttRowUpdateReplaceBlockStrategy(
        this._pluginHandlerService,
        this._ganttLibService,
        this._configService
      );
      if (response.ganttChangeMode) {
        switch (response.ganttChangeMode) {
          case EGanttChangeMode.REFRESH_GANTT:
            activeStrategy = new GanttRowUpdateRefreshGanttStrategy();
            break;
          case EGanttChangeMode.REPLACE_GANTT_ENTRIES:
            activeStrategy = new GanttRowUpdateRefreshGanttEntryStrategy();
            break;
          case EGanttChangeMode.REPLACE_BLOCKS:
            activeStrategy = new GanttRowUpdateReplaceBlockStrategy(
              this._pluginHandlerService,
              this._ganttLibService,
              this._configService
            );
            break;
          default:
            console.error('No known gantt change mode.');
        }
      }
      activeStrategy.update(response, this._ganttLibService, this._pluginHandlerService, this.attributeMapping);
      ganttDiagram.getShiftFacade().preloadPatterns();
      handledResponse = true;
    }

    if (handledResponse) {
      this._triggerDataUpdate();
    }

    return handledResponse;
  }

  private _triggerDataUpdate(): void {
    this._ganttLibService.bestGantt.updatedOriginData();
    this._templateDataService.triggerHierarchicalPlanUpdateNotification();
  }
}
