<div class="wrapper" [ngStyle]="{ 'max-height': templateNode.getHeight() + 'px' }">
  <div class="item-wrapper" *ngFor="let item of templateNode.getLegendValues()" (click)="handleDataset(item)">
    <div
      class="item-color-indentifier"
      [class]="getClass(item)"
      [ngStyle]="{ background: getColor(item), 'border-color': getColor(item) }"
    ></div>

    <div class="item-label" [ngStyle]="{ 'text-decoration': item.isActive() ? 'none' : 'line-through' }">
      {{ item.getLabel() }}
    </div>
  </div>
</div>
