import { EntryElement } from '../entry-collection/entry-element';
import { EFieldType } from '../entry-collection/field-type.enum';
import { TemplatePickerValue } from './template-picker-value';

export class TemplatePicker extends EntryElement {
  protected value: TemplatePickerValue;
  private showMillisForCalendarWeek: boolean;

  public isUseMillisForCalendarWeek(): boolean {
    return typeof this.showMillisForCalendarWeek === 'boolean' ? this.showMillisForCalendarWeek : false;
  }

  public useMillisForCalendarWeeks(showMillis: boolean): this {
    this.showMillisForCalendarWeek = showMillis;
    return this;
  }

  constructor() {
    super();
    this.setFieldType(EFieldType.CALENDAR_WEEK_PICKER);
  }
}
