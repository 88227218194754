import { Component, Input, NgZone, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { TemplateService } from '@app-modeleditor/utils/template.service';
import { GlobalUtils } from 'frontend/src/dashboard/global-utils';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EntryCollection } from '../entry-collection/entry-collection';
import { EntryElement } from '../entry-collection/entry-element';
import { ALink } from '../entry-collection/link/link';

@Component({
  selector: 'a-entry-element',
  template: '',
})
export abstract class AEntryElement implements OnInit, OnChanges, OnDestroy {
  @Input() entryElement: EntryElement;
  private componentId: string;
  private _ngDestroy: Subject<void> = new Subject<void>();
  private curParent: EntryCollection;

  /**
   * get components unique-id
   * @returns string
   */
  public getComponentId(): string {
    return this.componentId;
  }

  constructor(protected templateApi: TemplateService, protected zone: NgZone) {
    this.componentId = GlobalUtils.generateUUID();
    this.zone.runOutsideAngular(() => {
      this.templateApi
        .afterRegisteredChanges()
        .pipe(takeUntil(this._ngDestroy))
        .subscribe(() => {
          this._init();
        });
    });
  }

  private _init(): void {
    if (!this.entryElement) {
      return;
    }
    const parent: EntryCollection = this.templateApi.getElementById<EntryCollection>(
      this.entryElement.getParentId(),
      this.entryElement.getResourceId()
    );
    if (this.curParent?.getId() === parent?.getId()) {
      return;
    }
    this.curParent = parent;
    if (!parent || parent.getLinks().length === 0) {
      return;
    }
    this.zone.run(() => {
      this._afterLinksLoaded(parent.getLinks());
    });
  }

  /**
   * fired after links get loaded
   * @param {T extends ALink} links list of links connected to elements parent
   * @returns void
   */
  abstract _afterLinksLoaded<T extends ALink>(links: T[]): void;

  /**
   * angular on-destroy hook
   * @returns void
   */
  ngOnDestroy(): void {
    this._ngDestroy.next();
    this._ngDestroy.complete();
  }

  /**
   * angular on-init hook
   * @returns void
   */
  ngOnInit(): void {
    this._init();
  }

  /**
   * angular on-changes hook
   * @param {SimpleChanges} changes changes made on this component
   * @returns void
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.entryElement) {
      this._init();
    }
  }
}
