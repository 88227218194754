import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { ModelService } from '../../model/model.service';
import { NodeNetworkColorizer } from '../color/colorizer';
import { NodeNetworkFilterService } from '../service/node-network.filter.service';
import { NodeNetworkEdgeFilterCache } from './edge-filter.cache';

@Component({
  selector: 'edge-filter',
  templateUrl: './edge-filter.component.html',
  styleUrls: ['./edge-filter.scss'],
})

/**
 * Component to create filter menu for node network connections.
 */
export class NodeNetworkEdgeFilter {
  public itemList: EdgeFilterMenuItem[] = [];
  public filteredItems: { name: string; id: string; color: string }[] = [];
  public filteredItemElementsCache: any = {};
  public itemCache: NodeNetworkEdgeFilterCache;

  public showLegend = true;

  public showColors = true;

  public colorMap: any;

  public nodeNetworkColorizer: NodeNetworkColorizer;

  @Input() containerId: string;
  @Input() viewOptions: any;
  @Output() filteredConnections: EventEmitter<EdgeFilterOutputItem> = new EventEmitter();

  constructor(public filterService: NodeNetworkFilterService, public sharedModelService: ModelService) {
    this.nodeNetworkColorizer = new NodeNetworkColorizer();
    this.itemCache = new NodeNetworkEdgeFilterCache();
  }

  ngOnInit() {
    if (!this.containerId) return;
    this.filterService
      .getCompleteMachineViewProducts(this.sharedModelService.model.id, this.containerId, this.viewOptions)
      .subscribe((result) => {
        this.colorMap = this.nodeNetworkColorizer.setHSLColorMap(
          'selectedEdges',
          result.map((item) => {
            return item.id;
          }),
          50
        );
        this.itemList = result;
      });
    this.deselectAll();
  }

  /**
   * Provides data about filtered connections.
   * @param selectedItem Selected menu item.
   */
  public getFilteredItems(selectedItem: EdgeFilterMenuItem): Promise<any> {
    return new Promise<any>((resolve) => {
      const returnItem = JSON.parse(JSON.stringify(selectedItem));
      returnItem.color = this.colorMap[returnItem.id];

      // try get index of selectedItem indide all filtered items
      // to remove it if it exists
      const itemIndex = this.filteredItems.findIndex((filteredItem) => {
        return filteredItem.id === selectedItem.id;
      });

      if (itemIndex === -1) {
        this.filteredItems.push(returnItem);
        // if item is not cached, get it from backend
        if (!this.itemCache.getById(returnItem.id)) {
          this.filterService
            .getCompleteMachineViewConnections(
              this.sharedModelService.model.id,
              this.containerId,
              returnItem.id,
              this.viewOptions
            )
            .subscribe((result) => {
              this.filteredItemElementsCache[returnItem.id] = result;
              this.itemCache.addById(returnItem.id, result);
              resolve(true);
            });
        } else {
          this.filteredItemElementsCache[returnItem.id] = this.itemCache.getById(returnItem.id);
          resolve(true);
        }
      } else {
        this.filteredItems.splice(itemIndex, 1);
        delete this.filteredItemElementsCache[returnItem.id];
        resolve(true);
      }
    });
  }

  /**
   * Handles selection of one filter item.
   * @param selectedItem Selected menu item.
   */
  public outputSelectedFilterItems(selectedItem: EdgeFilterMenuItem): void {
    this.getFilteredItems(selectedItem).then((success) => {
      this.emitOutputItem();
    });
  }

  /**
   * Handles multiplse selection of filter items.
   * @param selectedItems List of selected menu items.
   */
  public getMultipleFilteredItems(selectedItems: EdgeFilterMenuItem[]): void {
    const promiseStack: Promise<any>[] = [];

    for (const item of selectedItems) {
      promiseStack.push(this.getFilteredItems(item));
    }

    Promise.all(promiseStack).then((values) => {
      this.emitOutputItem();
    });
  }

  /**
   * Emits all visible connections and selected menu items.
   */
  public emitOutputItem(): void {
    // clone product list
    const clonedProducts = JSON.parse(JSON.stringify(this.filteredItems));

    if (!this.showColors) {
      // remove all colors
      clonedProducts.map((item) => {
        item.color = '#000000';
      });
    }

    const output: EdgeFilterOutputItem = new EdgeFilterOutputItem();
    output.products = clonedProducts;
    output.edges = this.filteredItemElementsCache;
    this.filteredConnections.emit(output);
  }

  /**
   * Toggle (de-)select all connections.
   * @param event Checkbox event.
   */
  public onSelectAll(event: MatCheckboxChange): void {
    if (event.checked) this.selectAll();
    else this.deselectAll();
  }

  /**
   * Selects all items, shows all connections.
   */
  public selectAll(): void {
    this.filteredItems = [];
    this.getMultipleFilteredItems(this.itemList);
  }

  /**
   * Deselects all items, hides all connections.
   */
  public deselectAll(): void {
    this.filteredItems = [];
    this.filteredItemElementsCache = {};
    this.emitOutputItem();
  }

  /**
   * Shows/hides legend/menu.
   */
  public toggleLegend(): void {
    this.showLegend = !this.showLegend;
  }
}

/**
 * Structure to output items.
 */
export class EdgeFilterOutputItem {
  products: { name: string; id: string; color: string }[] = [];
  edges: any[] = [];
}

/**
 * Description of menu item structure.
 */
export interface EdgeFilterMenuItem {
  name: string;
  id: string;
}
