<div class="node-diagram-action-bar" #actionBar>
  <button
    mat-icon-button
    (click)="scaleDiagrammCanvas(1.1)"
    matTooltip="{{ '@zoomOut@' | translate | capitalize }}"
    [matTooltipShowDelay]="1000"
    [disabled]="miceCinema.isActive() && miceCinema.getEventLog()"
  >
    <mat-icon>zoom_in</mat-icon>
  </button>
  <button
    mat-icon-button
    (click)="scaleDiagrammCanvas(0.9)"
    matTooltip="{{ '@zoomIn@' | translate | capitalize }}"
    [matTooltipShowDelay]="1000"
    [disabled]="miceCinema.isActive() && miceCinema.getEventLog()"
  >
    <mat-icon>zoom_out</mat-icon>
  </button>
  <button
    mat-icon-button
    (click)="scaleDiagrammCanvasByZoomAll()"
    matTooltip="{{ '@zoomAll@' | translate | capitalize }}"
    [matTooltipShowDelay]="1000"
    [disabled]="miceCinema.isActive() && miceCinema.getEventLog()"
  >
    <mat-icon>zoom_out_map</mat-icon>
  </button>
  <button
    mat-icon-button
    [disabled]="currentViewType != 1 || multipleGraphsSelected"
    (click)="toggleEditMode()"
    matTooltip="{{ '@edit@' | translate | capitalize }}"
    [matTooltipShowDelay]="1000"
  >
    <mat-icon [style.color]="this.activeEditMode ? 'blue' : null">mode_edit</mat-icon>
  </button>
  <button
    mat-icon-button
    #goDeeperButton
    class="node-diagram-go-deeper"
    matTooltip="{{ '@goDeeper@' | translate | capitalize }}"
    [matTooltipShowDelay]="1000"
    (click)="buildSubgraph(currentViewType)"
    [disabled]="isMachineView() || !isGoDeeperActive"
  >
    <mat-icon>subdirectory_arrow_right</mat-icon>
  </button>
  <button mat-icon-button (click)="rebuildNodeNetworkView(toggleCurrentViewType())" [disabled]="!hasMachineView">
    <mat-icon
      *ngIf="!isMachineView()"
      svgIcon="machine-icon"
      matTooltip="{{ '@goTo@' | translate | capitalize }} {{ '@machine-view@' | translate | capitalize }}"
      [matTooltipShowDelay]="1000"
    ></mat-icon>
    <mat-icon
      *ngIf="isMachineView()"
      svgIcon="product-icon"
      matTooltip="{{ '@goTo@' | translate | capitalize }} {{ '@product-view@' | translate | capitalize }}"
      [matTooltipShowDelay]="1000"
    ></mat-icon>
  </button>
  <button mat-icon-button (click)="openOptions()">
    <mat-icon>settings_applications</mat-icon>
  </button>
  <button mat-icon-button (click)="openGeneralContent()" [disabled]="!isGeneralContentEnabled">
    <mat-icon>info_outline</mat-icon>
  </button>
  <button mat-icon-button>
    <mat-icon [style.color]="this.openEdgeFilter ? 'blue' : null" (click)="onToggleEdgeFilter()">filter_list</mat-icon>
  </button>

  <mat-form-field>
    <input
      matInput
      placeholder="{{ '@search@' | translate | capitalize }}"
      [matAutocomplete]="auto"
      (keyup)="getSuggestions($event)"
      [(ngModel)]="autocompleteInput"
    />
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="searchForNode($event)">
      <mat-option *ngFor="let suggestion of nodeSearchSuggestions" [value]="suggestion.value">
        {{ suggestion.label }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <mat-form-field>
    <mat-select [placeholder]="'@attributeFilter@' | translate | capitalize" [(ngModel)]="currentFilterAttribute">
      <mat-option (click)="colorizeNodeNetworkNodes('', [])"> </mat-option>
      <mat-option
        *ngFor="let nodeAttribute of allAttributes"
        [value]="nodeAttribute"
        (click)="colorizeNodeNetworkNodes(nodeAttribute.type, nodeAttribute.values)"
      >
        {{ nodeAttribute.type }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field>
    <mat-select
      [placeholder]="'@icons@' | translate | capitalize"
      [(ngModel)]="currentIconFilterAttribute"
      [disabled]="!allIconAttributes || allIconAttributes.length == 0"
    >
      <mat-option (click)="buildNodeIcons()"> </mat-option>
      <mat-option
        *ngFor="let iconAttribute of allIconAttributes"
        [value]="iconAttribute"
        (click)="buildNodeIcons(iconAttribute)"
      >
        {{ iconAttribute }}
      </mat-option>
      <mat-option (click)="onOpenIconDialog()"> {{ 'edit' | translate | capitalize }}... </mat-option>
    </mat-select>
  </mat-form-field>
  <button mat-icon-button [disabled]="miceCinema?.getEventLog().length == 0">
    <mat-icon [style.color]="this.miceCinema.isActive() ? 'blue' : null" (click)="onToggleCinemaMode()"
      >movie_filter
    </mat-icon>
  </button>
  <button mat-icon-button>
    <mat-icon (click)="onToggleBackgroundEditMode()" [style.color]="backgroundHandler.getEditMode() ? 'blue' : ''">
      insert_photo</mat-icon
    >
  </button>
</div>
<div class="node-diagram-link-bar" class="node-diagram-link-bar" *ngIf="hierarchicalLayers.length > 0">
  <div #nodeNetworkStepList id="div_step_list" class="node-diagram-step-list">
    <button
      mat-button
      *ngFor="let layer of hierarchicalLayers"
      class="node-diagram-step-list-item"
      (click)="showParentGraph(layer)"
      matTooltip="{{ subGraphListToString(layer, ' + ') }}"
      [matTooltipShowDelay]="1000"
    >
      <span>
        {{ subGraphListToString(layer, ' + ') }}
      </span>
    </button>
  </div>
</div>
<mat-progress-bar mode="indeterminate" *ngIf="inProgress"></mat-progress-bar>

<div class="node-network-edit-menu" *ngIf="activeEditMode">
  <node-network-add-element [elements]="addingElementTypes"></node-network-add-element>
  <hr />
  <node-network-delete-element (removeNodes)="removeSelectedElements()"></node-network-delete-element>
  <hr />
  <node-network-edit-edge
    (editEdges)="nodeNetworkBuilder.toggleCurveHandler()"
    (addEdges)="toggleAddCurveMode()"
    (selectEdges)="toggleSelectCurveMode()"
  ></node-network-edit-edge>
</div>

<div class="node-network-legend-wrapper">
  <nn-attribute-legend
    *ngIf="colorAttributeData"
    [data]="legendData"
    [enableFilterByClick]="enableNNFilter"
    (filterNodes)="filterNodeByAttribute($event)"
  >
  </nn-attribute-legend>
  <edge-filter
    *ngIf="openEdgeFilter"
    [containerId]="data.id"
    [viewOptions]="currentViewOptions"
    (filteredConnections)="filterConnections($event)"
    headline="@edges@"
  ></edge-filter>
  <nn-icon-legend *ngIf="iconAttributeData" [data]="iconAttributeData" [restURL]="restApi"></nn-icon-legend>
</div>

<div
  #nodeNetworkWrapper
  [ngClass]="miceCinema.isActive() ? 'node-network-wrapper node-network-cinema' : 'node-network-wrapper'"
  [style.height]="getMainHeight()"
>
  <div id="node-diagram-div-wrapper">
    <svg
      #nodeNetworkCanvas
      height="100%"
      width="100%"
      class="svg_canvas"
      (dragover)="allowDrop($event)"
      (drop)="dropNewNode($event)"
    >
      <defs>
        <marker id="arrow" markerWidth="10" markerHeight="10" refX="8" refY="3" markerUnits="strokeWidth" orient="auto">
          <path style="stroke: none; fill: black" d="M0,0 L0,6 L9,3 z" />
        </marker>
      </defs>
      <g class="main_canvas" transform="translate(0,0)">
        <g #nodeNetworkBackground class="background-image_holder"></g>
        <rect x="0" y="0" fill="transparent" #nodeNetworkStartSelectionCanvas></rect>
        <g #nodeNetworkDragAndDropCanvas class="drag-and-drop_canvas"></g>
        <g #nodeNetworkNodeCanvas class="node_canvas"></g>
        <g #nodeNetworkMiceCinemaCanvas class="node_mice-cinema"></g>
        <g #nodeNetworkTooltipCanvas class="tooltip_canvas"></g>
        <g #nodeNetworkSelectionCanvas></g>
      </g>
    </svg>
  </div>
</div>
<cinema-discrete-axis
  *ngIf="miceCinema.isActive() && miceCinema.getEventLog()"
  [eventLog]="miceCinema.getEventLog()"
  [eventPosition]="miceCinema.getCurrentIndex()"
  (start)="startMiceCinema()"
  (pause)="pauseMiceCinema()"
  (stop)="stopMiceCinema()"
  (fastForward)="fastForwardCinema()"
  (fastRevert)="fastRevertCinema()"
  (fastForwardToItem)="positionJumpCinema($event)"
></cinema-discrete-axis>
