import { FilterShiftsByAttribute } from '@gantt/lib/best_gantt/script/plug-ins/filter-shifts-by-attribute/filter-shifts-by-attribute';
import { EGanttInstance } from '@gantt/public-api';
import { GanttLibService } from 'frontend/src/dashboard/gantt/gantt/gantt-lib.service';
import { SaxMsBestGanttActiveSubmenuEntryElementSetting } from 'frontend/src/dashboard/gantt/gantt/saxms-best-gantt.settings';
import { EGanttBlockAttributeDataType } from 'frontend/src/dashboard/gantt/general/toolbar/filter/lightbox/attribute-data-types.enum';
import { Observable, of } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GeneralGanttActionHandler } from '../../../action-handling/action-handler';
import { GanttTemplateDataService } from '../../../template-data/gantt-template-data.service';
import { ExternalGanttPlugin } from '../../external-plugin';
import { GanttPluginHandlerService } from '../../gantt-plugin-handler.service';
import { IBlockFilterByAttributeSearchResult } from './block-filter-by-attribute.interface';

export const FilterShiftsByAttributeExecuter = 'filter-shifts-by-attribute-executer';

/**
 * Handling of attribut filter for blocks.
 * Based on FilterShiftsByAttribute-PlugIn.
 * Will be used with freetext search inside gantt menu.
 */
export class GanttBlockFilterByAttributePlugIn extends ExternalGanttPlugin {
  constructor(
    protected _ganttPluginHandlerService: GanttPluginHandlerService,
    protected _ganttLibService: GanttLibService,
    protected _actionHandler: GeneralGanttActionHandler,
    protected _ganttTemplateDataService: GanttTemplateDataService
  ) {
    super(_ganttPluginHandlerService, _ganttLibService, _actionHandler);
  }

  public onInit(templateData: any, responseData: any) {
    this.addPlugIn(
      FilterShiftsByAttributeExecuter,
      this._ganttLibService.ganttInstanceService.getInstance(EGanttInstance.FILTER_SHIFTS_BY_ATTRIBUTE)
    );
    this.handleFilterOnDataChange();
  }

  public onDestroy(): void {}

  public onAction(action: any) {}

  public executeAction(action: any): Observable<any> {
    return of(null);
  }

  /**
   * Executes attribute filter to all shift blocks by given query.
   * @param query Freetext to search in all attributes.
   * @return Returns list of all matching shift block ids that are hidden.
   */
  public filterBlocksByQuery(query: string, updateGantt = true): IBlockFilterByAttributeSearchResult {
    const plugin = this.getPlugInById(FilterShiftsByAttributeExecuter) as FilterShiftsByAttribute;
    const attributeMapping = this._ganttPluginHandlerService.getTemplateData().getAttributeMapping();
    return plugin.filterShiftsByAttributeQuery(query, attributeMapping, updateGantt);
  }

  public getPlugin(): FilterShiftsByAttribute {
    return this.getPlugInById(FilterShiftsByAttributeExecuter);
  }

  private handleFilterOnDataChange(): void {
    this._ganttTemplateDataService
      .listenToHierarchicalPlanUpdates()
      .pipe(takeUntil(this._ganttPluginHandlerService.onDestroy))
      .subscribe((_) => {
        const plugin = this.getPlugInById(FilterShiftsByAttributeExecuter) as FilterShiftsByAttribute;
        if (plugin.isFilterActive()) {
          const currentQuery = plugin.getCurrentQuery();
          this.filterBlocksByQuery(currentQuery, false);
        }
      });
  }

  /**
   * Returns first 10 found block attribute values.
   * Use this function to show suggestions for user search.
   * @param query Freetext to search in all attributes.
   */
  public getAllPropertyAttributesByQuery(query: string): Map<string, string[]> {
    const attributeMapping = this._ganttPluginHandlerService.getTemplateData().getAttributeMapping();
    const queryData: Map<string, string[]> = this.getPlugInById(
      FilterShiftsByAttributeExecuter
    ).getAllPropertyAttributesByQuery(query, attributeMapping);
    return queryData;
  }

  /**
   * Option to hide rows inside gantt which has no matching shiftblocks
   * and are empty after the search.
   * @param hideRows If true all rows with no shift blocks inside will be hidden.
   */
  public setHideEmptyRows(hideRows: boolean): void {
    const currentQuery = this.getPlugInById(FilterShiftsByAttributeExecuter).getCurrentQuery();
    const attributeMapping = this._ganttPluginHandlerService.getTemplateData().getAttributeMapping();
    this.getPlugInById(FilterShiftsByAttributeExecuter).setHideEmptyRows(hideRows);
    this.getPlugInById(FilterShiftsByAttributeExecuter).filterShiftsByAttributeQuery(currentQuery, attributeMapping);
  }

  public injectSettings(submenuElements: SaxMsBestGanttActiveSubmenuEntryElementSetting[]): void {}
}

export interface EGanttBlockFilterByAttributePlugInData {
  property: string;
  value: string;
  type: EGanttBlockAttributeDataType;
}
