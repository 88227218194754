import { BaseTemplate, DataType } from './base/base-template';
import { Breadcrumbs, mapBreadcrumbs } from './breadcrumbs/breadcrumbs.mapper';
import { EntryCollection, mapEntryCollection } from './entry-collection/entry-collection.mapper';
import { EntryElement, mapEntryElement } from './entry-collection/entry-element.mapper';
import { FileViewer, mapFileViewer } from './file-viewer/file-viewer.mapper';
import { HierarchicalMenuItem, mapHierarchicalMenuItem } from './menu/hierachic-menu-item';
import { Menu, mapMenu } from './menu/menu';

export type ReturnType<T> = T extends DataType.menu
  ? Menu
  : T extends DataType.hierarchicMenuItem
  ? HierarchicalMenuItem
  : T extends DataType.breadcrumbs
  ? Breadcrumbs
  : T extends DataType.entryElement
  ? EntryElement
  : T extends DataType.entryCollection
  ? EntryCollection<T>
  : T extends DataType.fileViewer
  ? FileViewer
  : BaseTemplate;

export interface MapFunction<T extends DataType> {
  call: (item: InputType<T>) => ReturnType<T>;
}

export type MapFunctionType<T extends DataType> = (item: InputType<T>) => ReturnType<T>;

export const fnMap: Map<DataType, MapFunctionType<DataType>> = new Map<DataType, MapFunctionType<DataType>>([
  [DataType.menu, mapMenu],
  [DataType.hierarchicMenuItem, mapHierarchicalMenuItem],
  [DataType.breadcrumbs, mapBreadcrumbs],
  [DataType.entryCollection, mapEntryCollection],
  [DataType.entryElement, mapEntryElement],
  [DataType.fileViewer, mapFileViewer],
]);

export interface InputType<T extends DataType> {
  [key: string]: any;
  type: T;
}

export const mapItem = <T extends DataType>(data: InputType<T>): ReturnType<T> => {
  const type: DataType = data.type.toLowerCase() as DataType;

  if (!fnMap.has(type)) {
    throw new Error(`"${type}" cannot be mapped by mapItem(data: InputType)`);
  }

  const fn = fnMap.get(type);
  const item = fn(data) as ReturnType<T>;
  return item;
};
