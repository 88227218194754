import { Component, Input } from '@angular/core';
import { NodeNetworkIconAttribute } from '@app-nodenetwork/icon-handling/icon-data';

@Component({
  selector: 'nn-icon-legend',
  templateUrl: './icon-legend.component.html',
  styleUrls: ['./icon-legend.scss'],
})
/**
 * Angular component to build legend for icons.
 */
export class NodeNetworkIconLegend {
  @Input() public set data(data: NodeNetworkIconAttribute) {
    for (const key in data.values) {
      this.mappedData.push(new NNIconLegendItem(data.values[key], key));
    }
  }
  @Input() headline: string;
  @Input('restURL') rest: string;

  public mappedData: Array<NNIconLegendItem> = [];
  public showLegend = true;

  /**
   * Opens/closes icon legend.
   */
  public toggleLegend(): void {
    this.showLegend = !this.showLegend;
  }
}

/**
 * Data structure for one legend entry.
 */
export class NNIconLegendItem {
  public imageURL: string;
  public label: string;

  constructor(imageURL: string, label: string) {
    this.imageURL = imageURL;
    this.label = label;
  }
}
