export class Widget {
  public id: string;
  public component: string;
  public widget: any;
  public template: any;
  public widgetBaseId: string;
  public position: WidgetProportion;
  public data: any;
  public experimentId: string;
  public experimentName: string;
  public oldStoreWidgetId: string;
  public widgetSettings: any;
  public compare: boolean;
  public type: string;
  public componentRef: any;
  public templateLayout: any;
  public contentId: string;
  public templateRestUrl: string;
  public compareRestUrl: string;
  public dataRestUrl: string;
  public settings: any;
  public additionalSubMenus: any;
  public parentId: string;
}

export class WidgetProportion {
  public width = 6;
  public height = 12;
  public left = 2;
  public top = 2;
  public toSmall = false;
}
