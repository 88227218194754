import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SaxmsSidebarModule } from '@app-modules/saxms-sidebar/saxms-sidebar.module';
import { SlotModule } from '../template/slot/slot.module';
import { LogModule } from './log/log.module';
import { TemplateSidebarComponent } from './template-sidebar.component';

@NgModule({
  imports: [CommonModule, SaxmsSidebarModule, SlotModule, LogModule],
  exports: [TemplateSidebarComponent],
  declarations: [TemplateSidebarComponent],
})
export class TemplateSidebarModule {}
