import { ZoomTransform } from 'd3';
import { ShiftDataFinder } from '../data-handler/data-finder/shift-data-finder';
import { DataHandler } from '../data-handler/data-handler';
import { GanttCanvasShift, GanttDataRow } from '../data-handler/data-structure/data-structure';
import { RenderDataHandler } from '../render-data-handler/render-data-handler';

/**
 * Calculation for selection box handling.
 * @keywords helper, calculation, selection, box, shift, marker
 */
export abstract class GanttSelectShiftCalculator {
  /**
   * Finds that shifts which are inside given selection box.
   * @keywords calculation, coordinaes, selection, box, shift, mark, select
   * @param corner1 Coordinate of top left corner.
   * @param corner2 Coordinate of bottom right corner.
   * @param transform
   * @param canvasShiftDataset All shifts with render information.
   * @param originShiftDataset Gantt origin dataset.
   * @param markerColor Color which selected shifts get.
   * @param dataHandler DataHandler
   * @param renderDataHandler
   * @param ignoredShiftIds
   * @return List of all marked canvas shifts.
   */
  static selectShiftsByCoordinates<T extends GanttSelectShiftBaseType>(
    corner1: number[],
    corner2: number[],
    transform: ZoomTransform,
    canvasShiftDataset: T[],
    originShiftDataset: GanttDataRow[],
    markerColor: string,
    dataHandler: DataHandler,
    renderDataHandler: RenderDataHandler,
    ignoredShiftIds: string[] = []
  ): T[] {
    const selectedShifts = [];
    const selectedShiftIds = [];

    const smallestValue = [0, 0],
      biggestValue = [0, 0];

    if (corner1[0] < corner2[0]) {
      smallestValue[0] = corner1[0];
      biggestValue[0] = corner2[0];
    } else {
      smallestValue[0] = corner2[0];
      biggestValue[0] = corner1[0];
    }

    if (corner1[1] < corner2[1]) {
      smallestValue[1] = corner1[1];
      biggestValue[1] = corner2[1];
    } else {
      smallestValue[1] = corner2[1];
      biggestValue[1] = corner1[1];
    }
    const toBeSelectedShiftIDs = new Map<string, Partial<GanttCanvasShift>>();
    const notSelectedShiftIds = new Map<string, Partial<GanttCanvasShift>>();

    for (const shift of canvasShiftDataset) {
      const shiftY =
        renderDataHandler.getStateStorage().getYPositionShift(shift.id) ?? // if shift is in viewport -> get y position from render data handler
        renderDataHandler.getShiftDataFinder().getShiftYByRowIdByShift(shift) ?? // shift is not in viewport -> try to calculate y position
        shift.y; // fallback to shift.y if shift is not in viewport && cannot be calculated

      if ((shift.noRender && shift.noRender.length) || ignoredShiftIds.includes(shift.id)) {
        if (dataHandler) {
          notSelectedShiftIds.set(shift.id, {
            selected: null,
          });
        } else {
          shift.selected = null;
        }
        continue;
      }

      const shiftLeftX = shift.x * transform.k + transform.x;
      const shiftRightX = (shift.x + shift.width) * transform.k + transform.x;
      const shiftTopY = shiftY;
      const shiftBottomY = shiftY + shift.height;

      if (
        shiftLeftX <= biggestValue[0] &&
        shiftRightX >= smallestValue[0] &&
        shiftTopY <= biggestValue[1] &&
        shiftBottomY >= smallestValue[1]
      ) {
        if (dataHandler) {
          toBeSelectedShiftIDs.set(shift.id, {
            selected: markerColor,
          });
        } else {
          shift.selected = markerColor; // mark at canvas dataset
        }
        selectedShifts.push(shift);
        selectedShiftIds.push(shift.id);
      }
    }

    if (dataHandler) {
      for (const shift of dataHandler.getCanvasShiftDataset()) {
        if (toBeSelectedShiftIDs.get(shift.id) || notSelectedShiftIds.get(shift.id)) continue;
      }
    }

    if (dataHandler) {
      dataHandler.updateCanvasShiftPropertiesInCanvasDataByShiftId(toBeSelectedShiftIDs);
      dataHandler.updateCanvasShiftPropertiesInCanvasDataByShiftId(notSelectedShiftIds);
    }

    // mark shifts in origin dataset
    const selectedShiftsInOriginDataSet = ShiftDataFinder.getShiftsByIds(originShiftDataset, selectedShiftIds);
    selectedShiftsInOriginDataSet.forEach(function (foundShift) {
      foundShift.shift.selected = markerColor;
    });

    const setMarkerColorArray = selectedShiftsInOriginDataSet.map((originShift) => {
      return {
        id: originShift?.shift?.id,
        properties: {
          selected: markerColor,
        },
      };
    });

    if (dataHandler) {
      dataHandler.updateShiftPropertiesInOriginDataByShiftId(setMarkerColorArray);
    }

    return selectedShifts;
  }
}

/**
 * Base type for shift selection calculations.
 */
interface GanttSelectShiftBaseType {
  id: string;
  x: number;
  y: number;
  width: number;
  height?: number;
  noRender?: string[];
  selected?: string;
}
