import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { EntryElementValue } from './../entry-collection/entry-element-value';
import { SelectionBox } from './selection-box';
import { SelectionBoxItem } from './selection-box-item';

@Component({
  selector: 'app-selection-box',
  templateUrl: './selection-box.component.html',
  styleUrls: ['./selection-box.component.scss'],
})
export class SelectionBoxComponent implements OnInit {
  @Input() template: SelectionBox;
  @Output() selectionChanged: EventEmitter<SelectionBoxItem> = new EventEmitter<SelectionBoxItem>();
  constructor() {}

  onItemClicked(event: MatCheckboxChange, item: SelectionBoxItem) {
    item.setChecked(event.checked);
    this.selectionChanged.emit(item);
  }

  ngOnInit() {}

  stopPropagation(event: MouseEvent): void {
    event.stopPropagation();
  }
}
