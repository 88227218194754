import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { TranslateModule } from '@ngx-translate/core';
import { ImageModule } from '@app-modeleditor/components/image/image.module';
import { LabeledTextComponent } from './labeled-text.component';

@NgModule({
  imports: [CommonModule, TranslateModule, MatIconModule, MatMenuModule, ImageModule],
  exports: [LabeledTextComponent],
  declarations: [LabeledTextComponent],
})
export class LabeledTextModule {}
