import { EEntryElementEvents } from '@app-modeleditor/components/entry-collection/entry-element-events.enum';
import { ContentElement } from '../content/content-element/content-element';
import { ETemplateType } from './../../../model/resource/template-type';
import { ColumnListSelectorElement } from './column-list-selector-element';
import { ColumnListSelectorValue } from './column-list-selector-value';

export class ColumnListSelector extends ContentElement {
  private value: ColumnListSelectorValue;

  public getValue(): ColumnListSelectorValue {
    return this.value;
  }

  public setValue(value: ColumnListSelectorValue): this {
    this.value = value;
    if (!this.getEventListener()[EEntryElementEvents.VALUE_CHANGED]) {
      return;
    }
    Object.keys(this.getEventListener()[EEntryElementEvents.VALUE_CHANGED] || {}).forEach((id: string) => {
      this.getEventListener()[EEntryElementEvents.VALUE_CHANGED][id](
        new CustomEvent(EEntryElementEvents.VALUE_CHANGED, { detail: { value } })
      );
    });
    return this;
  }

  constructor() {
    super();
    this.setType(ETemplateType.TWO_COLUMN_LIST_SELECTOR);
  }

  public moveLeft(element: ColumnListSelectorElement): void {
    this.getValue().setSelectedListEntryElements(
      this.getValue()
        .getSelectedListEntryElements()
        .filter((item: ColumnListSelectorElement) => item.getId() !== element.getId())
    );

    this.getValue().setAvailableListEntryElements(this.getValue().getAvailableListEntryElements().concat(element));
  }

  public moveRight(element: ColumnListSelectorElement): void {
    this.getValue().setAvailableListEntryElements(
      this.getValue()
        .getAvailableListEntryElements()
        .filter((item: ColumnListSelectorElement) => item.getId() !== element.getId())
    );

    this.getValue().setSelectedListEntryElements(this.getValue().getSelectedListEntryElements().concat(element));
  }

  public isSelectedElementInList(list: ColumnListSelectorElement[], item: ColumnListSelectorElement): boolean {
    return list.find((element: ColumnListSelectorElement) => element.getId() === item.getId()) ? true : false;
  }

  public moveFromTo(a: ColumnListSelectorElement[], b: ColumnListSelectorElement[], from: number, to: number): void {
    const element: ColumnListSelectorElement = a.splice(from, 1)[0];
    b.splice(to, 0, element);
  }

  moveInArray(list: ColumnListSelectorElement[], from: number, to: number) {
    list.splice(to, 0, list.splice(from, 1)[0]);
    return list;
  }
}
