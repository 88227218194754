import { GanttPatternData } from '../../patternHandler';
import { Pattern } from '../pattern.base';

/**
 * Class which handles the generation of a carbon pattern.
 */
export class CarbonPattern extends Pattern {
  /**
   * Generates an SVG image containing the pattern and returns it as base64-encoded URL.
   * @param backgroundColor Background color of the pattern.
   * @param patternColor Color of the pattern.
   * @param strokeColor Stroke color of the pattern.
   * @returns SVG image containing the pattern as base64-encoded URL.
   */
  protected _getPatternDataString(backgroundColor: string, patternColor: string, strokeColor: string): string {
    const pattern = this._patternBuilder.getSvgElement(this._patternHeight, this._patternWidth);
    this._patternBuilder.appendBackground(pattern, backgroundColor);
    this._patternBuilder.appendRect(pattern, 3, 3, 3, 3, patternColor, strokeColor);
    this._patternBuilder.appendRect(pattern, 2, 3, 3, 4, patternColor, strokeColor);
    return this._patternBuilder.convertSvgNodeIntoDataString(pattern);
  }

  /**
   * Generates an SVG image containing the pattern and returns it as {@link GanttPatternData} object.
   * @param backgroundColor Background color of the pattern.
   * @param patternColor Color of the pattern.
   * @param strokeColor Stroke color of the pattern.
   * @returns SVG image containing the pattern as {@link GanttPatternData} object.
   */
  public getPatternData(backgroundColor: string, patternColor: string, strokeColor: string): GanttPatternData {
    return new GanttPatternData(
      this._getPatternDataString(backgroundColor, patternColor, strokeColor),
      this._patternHeight,
      this._patternWidth
    );
  }
}
