import { Pipe, PipeTransform } from '@angular/core';

// Tell Angular2 we're creating a Pipe with TypeScript decorators
@Pipe({
  name: 'sort',
})
export class SortPipe implements PipeTransform {
  transform(items: any[], suffix: string, reverse?: boolean): any {
    if (!items || !suffix) {
      return items;
    }

    const it = items.sort((a: any, b: any): any => {
      if (a[suffix] < b[suffix]) {
        return -1;
      } else if (a[suffix] > b[suffix]) {
        return 1;
      } else {
        return 0;
      }
    });

    return reverse ? it.reverse() : it;
  }
}
