import { ChartData } from './generalChart/chart.mapper';
import { ChartLegendWrapperTemplate } from './chart-legend/chart-legend-wrapper-template';

export class GeneralChartInformation {
  index: number;
  chartData: ChartData;
  legend: ChartLegendWrapperTemplate;
  experimentId: string;
  chartId: string;
  widgetRefId: string;
  experimentName?: string;
  dataRestUrl: string;
  type: string;
}
