import { ListRange } from '@angular/cdk/collections';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { EActionType } from '@app-modeleditor/components/button/action/action-type.enum';
import { EPredefinedAction } from '@app-modeleditor/components/button/action/predefined-action.enum';
import { SortOrder } from '@app-modeleditor/components/elements/sort-menu/sort-order.enum';
import { IAfterSave } from '@app-modeleditor/components/template-ui/after-save.interface';
import { HierarchicalMenuItem } from '@app-modeleditor/components/template-ui/hierarchical-menu-item';
import { TemplateUiService } from '@app-modeleditor/components/template-ui/template.service';
import { Routing } from '@core/navigation/routing';
import { CloudMessagingService } from '@core/notification/cloud-messaging.service';
import { Notification } from '@core/notification/notification';
import { ENotificationType } from '@core/notification/notification-type.enum';
import { awaitingFirstCb } from 'frontend/src/dashboard/custom-rx-operators';
import { ActionService } from 'frontend/src/dashboard/shared/data-access/actions/action.service';
import { EResizeType } from 'frontend/src/dashboard/view/resize/resize-type.enum';
import { ResizeService } from 'frontend/src/dashboard/view/resize/resize.service';
import { UserTenantService } from 'frontend/src/dashboard/view/template-footer/default-footer-components/tenant-footer/tenant.service';
import { Observable, Subject, Subscription, of } from 'rxjs';
import { delay, takeUntil, takeWhile, tap } from 'rxjs/operators';
import { TemplateTreeService } from '../tree.service';
import { ConfigService } from './../../../../core/config/config.service';
import { TemplateAdapter } from './../../../utils/template-factory.service';

@Component({
  selector: 'app-tree-second-column',
  templateUrl: './tree-second-column.component.html',
  styleUrls: ['./tree-second-column.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TreeSecondColumnComponent implements OnInit, OnChanges, OnDestroy {
  // parent of the second-tree-column
  parent: HierarchicalMenuItem;
  @Input('parent') set _parent(newParent: HierarchicalMenuItem) {
    if (
      newParent &&
      this.parent &&
      newParent.getUuid() === this.parent.getUuid() &&
      this.parent.getRestUrl() === newParent.getRestUrl()
    ) {
      if (newParent.isForceReloadByReOpen() === true) {
        this.isUpToDate = false;
      }
      return;
    }

    this.parent = newParent;
    this.cd.detectChanges();
    this.initialize();
  }

  @Input() selectedResourceId: string;
  private ngScrollListener: Subject<void> = new Subject<void>();
  @Output('onCreate') _createElement: EventEmitter<any> = new EventEmitter<any>();
  viewport: CdkVirtualScrollViewport;
  @ViewChild(CdkVirtualScrollViewport) set myViewport(viewport: CdkVirtualScrollViewport) {
    this.viewport = viewport;
    this.ngScrollListener.next();
    if (!this.viewport) {
      return;
    }

    this.zone.runOutsideAngular(() => {
      this.viewport.renderedRangeStream.pipe(takeUntil(this.ngScrollListener)).subscribe((r: ListRange) => {
        this.currentRenderedRange = r;
        this.getBatch();
      });
    });
  }
  currentBatch: Subscription;
  @Input() static: boolean;
  @Input() displayedItems: HierarchicalMenuItem[] = [];

  protected isLoading = false;
  private alive = true;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  @ContentChild('tItem') tItem: TemplateRef<any>;
  isUpToDate = true;
  private resizeIds: string[] = [null, null];
  upperContainer: ElementRef;
  lowerContainer: ElementRef;
  @ViewChild('upperContainer') set c1(upperContainer: ElementRef) {
    this.upperContainer = upperContainer;
    this.resizeApi.complete(this.resizeIds[0]);
    if (!upperContainer) {
      return;
    }
    this.resizeIds[0] = this.resizeApi.create(upperContainer.nativeElement, this._checkContentDimensions.bind(this), {
      types: [EResizeType.HEIGHT],
    });
  }
  @ViewChild('lowerContainer') set c2(lowerContainer: ElementRef) {
    this.lowerContainer = lowerContainer;
    this.resizeApi.complete(this.resizeIds[1]);
    if (!lowerContainer) {
      return;
    }
    this.resizeIds[1] = this.resizeApi.create(lowerContainer.nativeElement, this._checkContentDimensions.bind(this), {
      types: [EResizeType.HEIGHT],
    });
  }

  get hasUpload(): boolean {
    return this.parent?.getFileUploadActions().length > 0 ? true : false;
  }

  constructor(
    private templateTreeApi: TemplateTreeService,
    private templateAdapter: TemplateAdapter,
    private fcmApi: CloudMessagingService,
    private cd: ChangeDetectorRef,
    private templateUiApi: TemplateUiService,
    private tenantApi: UserTenantService,
    private resizeApi: ResizeService,
    private configApi: ConfigService,
    private zone: NgZone,
    private actionService: ActionService
  ) {
    this.tenantApi
      .selectedTenantChanged()
      .pipe(takeWhile(() => this.alive))
      .subscribe(() => {
        this.initialize();
      });

    this.templateTreeApi
      .onCurrentRouteChanged()
      .pipe(takeWhile(() => this.alive))
      .subscribe((route: Routing) => {
        if (!route || route.getRoutes().length < 2) {
          return;
        }
        const r: string = route.getRoutes()[route.getRoutes().length - 2];
        if (this.parent?.getId() === r) {
          this.initialize();
        }
      });

    this.templateUiApi
      .afterSave()
      .pipe(takeWhile(() => this.alive))
      .subscribe((res: IAfterSave) => {
        if (res.response) {
          this.displayedItems
            .find(
              (i: HierarchicalMenuItem) => i && this.selectedResourceId && i.getResourceId() === this.selectedResourceId
            )
            ?.setName(res.response);
        }
      });
    this.templateUiApi.onaction.pipe(takeWhile(() => this.alive)).subscribe((action) => {
      switch (action.type) {
        case 'REFRESH_TREE':
        case 'RELOAD_TREE':
          this.isUpToDate = false;
          break;
      }
    });

    this.actionService.customActionListener.pipe(takeWhile(() => this.alive)).subscribe((action) => {
      switch (action?.button?.id) {
        case EPredefinedAction.FORCE_RELOAD_SECOND_TREE_COLUMN:
          this.initialize();
          break;
      }
    });
    this.fcmApi
      .getMessage()
      .pipe(takeWhile(() => this.alive))
      .subscribe((result: Notification) => {
        if (!result) {
          return;
        }

        switch (result.getType()) {
          case ENotificationType.HMI_CHILD_NODE_NOTIFICATION:
            if (result.getUpdateElementIds().indexOf(this.parent.getId())) {
              this.initialize();
            }
            break;
          case ENotificationType.FRONTEND_UPDATE_NOTIFICATION:
            if (result.getUpdateElementIds().length > 0) {
              return this._updateMenuItems(result.getUpdateElementIds());
            } else if (
              result.getBelongsToResource() &&
              this.parent &&
              result.getBelongsToResource().getId() === this.parent.getUuid()
            ) {
              this.initialize();
            }

            break;
          case ENotificationType.MENU_UPDATE_NOTIFICATION:
            if (result.getUpdateElementIds().length > 0) {
              return this._updateMenuItems(result.getUpdateElementIds());
            }
            break;
          case ENotificationType.RELOAD_TREE_NOTIFICATION:
            this.templateUiApi.trigger({ type: EActionType.RELOAD_TREE, data: null });
            break;
        }
      });

    this.templateTreeApi.onCreateElement.pipe(takeWhile(() => this.alive)).subscribe(() => {
      this.initialize();
    });

    this.templateTreeApi.onDeleteElement.pipe(takeWhile(() => this.alive)).subscribe((node: any) => {
      this.initialize();
    });

    this.templateTreeApi.onDuplicateElement.pipe(takeWhile(() => this.alive)).subscribe(() => {
      this.initialize();
    });

    this.templateTreeApi.onFilterChange.pipe(takeWhile(() => this.alive)).subscribe((e) => {
      if (!e) {
        return;
      }
      this.parent = e;
      this.initialize();
    });
  }

  private curLineHeight: number;
  get lineCount(): number {
    const cur = !this.displayedItems?.length ? 0 : this.displayedItems[0]?.getRowCount() || 0;
    if (this.curLineHeight !== cur) {
      this.curLineHeight = cur;
      this.viewport?.checkViewportSize();
    }

    return cur;
  }

  private _checkContentDimensions(): void {
    const a: number = this.upperContainer?.nativeElement.clientHeight || 0;
    const b: number = this.lowerContainer?.nativeElement.clientHeight || 0;
    const height = `calc(100% - ${a + b}px)`;
    if (this.contentHeight !== height) {
      of(null)
        .pipe(delay(0))
        .subscribe(() => {
          this.contentHeight = `calc(100% - ${a + b}px)`;
        });
    }
  }

  /**
   * check given ids for current selected hmi
   * update if it was foud inside one of its sub trees
   * @param ids string[]
   * @returns void
   */
  private _updateMenuItems(ids: string[]): void {
    // if no hmi is selected, return
    if (!this.parent) {
      return;
    }

    const match = ids.find((id: string) => {
      return this.parent.getParentList().indexOf(id) !== -1;
    });

    if (match) {
      this.initialize();
    }
  }

  selectNode(): void {
    (this.displayedItems || []).forEach((node: HierarchicalMenuItem) => {
      if (!node) {
        return;
      }
      node.addClass('leaf-node');

      node.setSelected(
        node && this.selectedResourceId && this.selectedResourceId === node.getResourceId() ? true : false
      );
    });
  }

  onNodeClicked(node: HierarchicalMenuItem): void {
    this.templateTreeApi.forceClick(node);
  }

  contentHeight = null;

  ngOnDestroy(): void {
    this.alive = false;
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.ngScrollListener.next();
    this.ngScrollListener.complete();
    this.resizeApi.complete(...this.resizeIds);

    Object.keys(this || {}).forEach((key: string) => {
      delete this[key];
    });
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.static) {
      this.cd.detectChanges();
      this.initialize();
    }

    if (changes.selectedResourceId) {
      this.selectNode();
    }
  }

  public initialize(): void {
    if (!this.static) {
      this.setDisplayedItems([]);
      this.getListsize();
      this.isUpToDate = true;
    }
  }

  listsize: number;
  currentRenderedRange: ListRange;
  private batchElement: HierarchicalMenuItem;
  /**
   * get listsize of component
   */
  getListsize(): void {
    this.ngUnsubscribe.next();
    this.lastRenderedRange = null;
    if (!this.parent.getRestListSizeUrl()) {
      return;
    }
    this.batchElement = this.parent.copy(HierarchicalMenuItem);
    this.zone.runOutsideAngular(() => {
      this.templateTreeApi
        .getListsize(this.parent as any)
        .pipe(
          awaitingFirstCb((val) => (this.isLoading = val)),
          takeUntil(this.ngUnsubscribe)
        )
        .subscribe((listsize: number) => {
          this.listsize = listsize;
          this.lastRenderedRange = null;
          this.setDisplayedItems(Array.from({ length: listsize }, (elem: number, index: number) => (elem = null)));
          this.getBatch();
        });
    });
  }

  private lastRenderedRange: ListRange;
  /**
   * get batch of displayrange
   */
  getBatch(listRange: ListRange = null): void {
    if (this.static || !this.listsize || !this.currentRenderedRange) {
      return;
    }

    if (this.configApi.access().templates.Tree.ignorePagination) {
      this.currentRenderedRange = { start: 0, end: 99999 };
    }

    const r: ListRange = this.currentRenderedRange || listRange || this.viewport.getRenderedRange();

    if (!this.lastRenderedRange || this.lastRenderedRange.start !== r.start || this.lastRenderedRange.end !== r.end) {
      this.currentBatch?.unsubscribe();
      this.lastRenderedRange = r;
      this.currentBatch = this.doRequest(this.lastRenderedRange.start, this.lastRenderedRange.end)
        .pipe(takeWhile(() => this.alive))
        .subscribe();
    }
  }

  /**
   * do request
   * @param start number
   * @param end number
   */
  doRequest(start: number, end: number): Observable<any> {
    if (this.displayedItems.slice(start, end).every((element: HierarchicalMenuItem) => element !== null)) {
      return of(null);
    }

    const offset = start; // start;
    const limit = end - start; // this.listsize; // end - start;
    return this.templateTreeApi.getItems(this.batchElement as any, offset, limit).pipe(
      takeUntil(this.ngUnsubscribe),
      tap((items: any[]) => {
        // add parent
        items.forEach((item, i: number) => {
          if (this.displayedItems[offset + i] instanceof HierarchicalMenuItem) {
            return;
          }
          const hItem: HierarchicalMenuItem = this.templateAdapter.adapt<HierarchicalMenuItem>(item);
          hItem.setRegisterable(false);
          hItem.setParentId(this.parent.getId()).setGetDataAutomatically(false);
          this.displayedItems[offset + i] = hItem;
        });
        this.setDisplayedItems(this.displayedItems.slice());
      })
    );
  }

  createElement(): void {
    this._createElement.emit(this.parent);
  }

  public trackByFn(index: number, item: HierarchicalMenuItem): any {
    return item ? item.getUuid() : index;
  }

  private setDisplayedItems(items: HierarchicalMenuItem[]): void {
    this.zone.run(() => {
      this.displayedItems = items;
      this.selectNode();
      this.cd.detectChanges();
    });
  }

  public filterActive(): boolean {
    return (
      this.parent
        .getFilters()
        .some(
          (filter) =>
            filter.getValue() !== undefined || (filter.isEnableSort() && filter.getSortOrder() !== SortOrder.none)
        ) ||
      (this.parent.getFilterString() && this.parent.getFilterString() !== '')
    );
  }
}
