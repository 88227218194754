<saxms-lightbox>
  <div class="headline">
    {{ 'NODE.add' | translate | capitalize }}
  </div>
  <div class="content">
    <div>
      <mat-form-field>
        <input matInput placeholder="{{ '@name@' | translate | capitalize }}" [(ngModel)]="inputName" />
      </mat-form-field>
    </div>
    <div *ngIf="nodeSubTypes.length > 0">
      <mat-form-field>
        <mat-select placeholder="SubType" [(ngModel)]="selectedSubType" (change)="getProcessTypes(selectedSubType)">
          <mat-option *ngFor="let subType of nodeSubTypes" [value]="subType.type">
            {{ subType.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div *ngIf="nodeProcessTypes.length > 0">
      <mat-form-field>
        <input matInput placeholder="{{ '@processName@' | translate | capitalize }}" [(ngModel)]="processName" />
      </mat-form-field>
    </div>
    <div *ngIf="nodeProcessTypes.length > 0">
      <mat-form-field>
        <mat-select placeholder="{{ '@processType@' | translate | capitalize }}" [(ngModel)]="selectedProcessType">
          <mat-option *ngFor="let processType of nodeProcessTypes" [value]="processType.type">
            {{ processType.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div *ngIf="selectedProcessType === 'de.saxms.protocol.simulation.process.HierarchicalProcess'">
      <mat-form-field>
        <input matInput placeholder="{{ '@subprocessName@' | translate | capitalize }}" [(ngModel)]="subProcessName" />
      </mat-form-field>
    </div>
  </div>
  <div class="actionbarBottom">
    <button
      mat-button
      (click)="add()"
      [disabled]="
        (nodeSubTypes.length != 0 || nodeProcessTypes.length != 0) && !selectedProcessType && !selectedSubType
      "
    >
      add
    </button>
  </div>
</saxms-lightbox>
