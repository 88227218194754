import { BestGantt } from '../main';
import { GanttUtilities } from '../gantt-utilities/gantt-utilities';

/**
 * Superclass which provides plugin lifecycle as bluprint for all gantt plugins.
 * @keywords plugin, plug-in, plug in, addin, add-in, extension, lifecycle
 * @class
 * @abstract
 */
export class BestGanttPlugIn {
  ganttDiagram: BestGantt;
  eventLogging: boolean;
  UUID: string;
  additionalData: any;

  constructor() {
    /**
     * @type {BestGantt}
     */
    this.ganttDiagram = null;
    this.eventLogging = true;
    /**
     * @member {String} UUID Unique identifier of plugin. Usefull to avoid overwriting callback stack registrations by multiple plugin instances.
     */
    this.UUID = '_PlugIn_' + GanttUtilities.generateUniqueID(11);

    // for dashboard integration to store backend data
    this.additionalData = {};
  }

  /**
   * @description Initializes all plugin functionality. <br>
   * <b>Start of lifecycle.</b> <br>
   * This method <b>should do these tasks</b>:
   * <ul>
   * <li>subscribe to all needed observables → use a <b>_initCallbacks</b> method.</li>
   * <li>initialize dependencies</li>
   * <li>initialize necessary html structures</li>
   * </ul>
   * @keywords init, initialize, plugin, plug in, lifecycle, start
   * @param {BestGantt}
   */
  public initPlugIn(ganttDiagram: BestGantt): void {}

  /**
   * Updates plugin to react to new conditions inside gantt.
   * @keywords init, initialize, plugin, plug in, lifecycle, update, refresh
   */
  public update(): void {}

  /**
   * Updates plugin to react to new height of gantt.
   * @keywords init, initialize, plugin, plug in, lifecycle, update, refresh, height, vertical
   */
  public updatePlugInHeight(): void {}

  /**
   * @description Will be executed when plugin is being removed from gantt. <br>
   * <b>End of lifecycle.</b> <br>
   *  This method <b>should do these tasks</b>:
   * <ul>
   * <li>remove all visual elements plugin is responsible for</li>
   * <li>unsubscribe itself from all observables → use a <b>_unsubscribeCallbacks</b> method.</li>
   * <li>unsubscribe its observers → use a <b>_unsubscribeObservers</b> method.</li>
   * <li>clear timeOuts / timeIntervals → use <b>{@link GanttUtilities#clearTimeouts}</b> for that</li>
   * </ul>
   * @keywords init, initialize, plugin, plug in, lifecycle, end, finish, kill, terminate
   */
  public removePlugIn(): void {}

  /**
   * Set Gantt diagram which will be affected by this plugin.
   * @param {BestGantt} ganttDiagram Gantt diagram.
   */
  public setGanttDiagram(ganttDiagram: BestGantt): void {
    this.ganttDiagram = ganttDiagram;
  }

  /**
   * Can be executed to enable event logging used for undo-redo for the PlugIn
   */
  public enableLogging(): void {
    this.eventLogging = true;
  }

  /**
   * Can be executed to enable event logging used for undo-redo for the PlugIn
   */
  public disableLogging(): void {
    this.eventLogging = false;
  }

  /**
   * Stores any data inside gantt plugin. Helpful for dashboard integration.
   * @param {any} additionalData Dataset which should be stored inside plugin.
   */
  public setAdditionalData(additionalData): void {
    this.additionalData = additionalData;
  }

  /**
   * Access to additional data.
   * @return {any} Dataset which has been stored inside plugin.
   */
  public getAdditionalData(): any {
    return this.additionalData;
  }

  public getUUID(): string {
    return this.UUID;
  }
}
