import { BaseContentElement } from '../base/base-content-element';
import { DataType } from '../base/base-template';
import { InputType } from '../mapper';

export interface FileViewer extends BaseContentElement {
  type: DataType.fileViewer;
}

export const mapFileViewer = <T extends DataType>(item: InputType<T>): FileViewer => {
  return {
    ...item,
    type: item.type,
  } as FileViewer;
};
