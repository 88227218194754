<mat-toolbar
  class="saxms-template-toolbar"
  [class.hidden]="template?.getMenuMode() === 'HIDE'"
  [ngClass]="template.getClass()"
  #outerToolbarContainer
>
  <mat-toolbar-row class="main" *ngIf="template && template.getMenuMode() === 'SHOW'" #innerWrapper>
    <div class="group-wrapper" #a>
      <!-- menu button -->
      <app-button
        *ngIf="template.isMainMenu()"
        class="toolbar-navigator"
        [data]="mainMenuMenuButton"
        [icon]="mainMenuMenuButton.getIcon()"
      >
      </app-button>

      <!-- logo -->
      <img *ngIf="template.getLogo()" [src]="template.getLogo()" class="logo" />

      <!-- title -->
      <span
        [style.color]="template?.getTitleColor() + ' !important'"
        class="toolbar-title"
        *ngIf="template?.getTitle()"
        >{{ template.getTitle() | translate }}</span
      >

      <!-- shortcuts -->
      <div
        *ngIf="template.isShortcutsEnabled()"
        class="shortcuts"
        cdkDropListOrientation="horizontal"
        cdkDropList
        #shortcutList="cdkDropList"
        [cdkDropListData]="shortcuts"
        (cdkDropListDropped)="drop($event)"
      >
        <!--[cdkDropListConnectedTo]="[groupList]"-->
        <div
          class="drag-element"
          *ngFor="let shortcut of shortcuts"
          #menuTrigger="matMenuTrigger"
          [matMenuTriggerFor]="contextMenu"
          [matMenuTriggerData]="{ item: shortcut }"
          (contextmenu)="onContextMenu($event, menuTrigger)"
          (click)="onMenuClicked(menuTrigger)"
          cdkDrag
          [cdkDragDisabled]="true"
        >
          <div class="shortcut-placeholder" *cdkDragPlaceholder></div>
          <ng-container *ngIf="shortcut.isShow() === true">
            <ng-container *ngTemplateOutlet="tSwitch; context: { $implicit: shortcut }"></ng-container>
          </ng-container>
        </div>
      </div>
    </div>
    <!-- menu elements -->
    <ng-container *ngIf="template">
      <!-- groups -->
      <div
        class="group-wrapper"
        [style.display]="'inline-block'"
        [style.width]="containerSize"
        #b
        style="position: relative"
      >
        <!-- name -->
        <div class="upper">
          <ng-container *ngFor="let group of shortcutItems">
            <app-button
              class="toolbar-group-toggle"
              [class.is-active]="template.getActiveMenuItem()?.getId() === group.getId()"
              [data]="group"
              [icon]="group.getIcon()"
              (action)="checkScrollActions($event)"
            >
            </app-button>
          </ng-container>
          <span
            class="toolbar-name"
            #toolbarNameSpan
            [style.color]="template?.getTitleColor() + ' !important'"
            *ngIf="template?.getName()"
            >{{ template.getName() | translate }}</span
          >
        </div>
        <div class="lower">
          <app-button
            [class.selected]="selectedContextMenuItem"
            *ngIf="itemMenuBtn?.getMenu()?.getContextMenuItems()?.length > 0"
            #extenderBtn
            class="toolbar-group-toggle extender-btn"
            [data]="itemMenuBtn"
          ></app-button>
          <div class="inner-container" #innerContainer [style.width]="innerContainerWidth">
            <ng-container *ngFor="let group of displayedToolbarItems">
              <app-button
                class="toolbar-group-toggle"
                [class.is-active]="template.getActiveMenuItem()?.getId() === group.getId()"
                [data]="group"
                [icon]="group.getIcon()"
                (action)="checkScrollActions($event)"
              >
              </app-button>
            </ng-container>
          </div>
        </div>
      </div>
      <!-- content spacer -->
      <span class="content-spacer"></span>

      <!-- navigator -->
      <div class="group-wrapper" style="overflow: hidden" #c>
        <ng-container *ngFor="let item of toolbarNavigators">
          <ng-container *ngIf="item.isShow()">
            <app-button
              class="toolbar-navigator"
              [class.is-active]="item.isActive()"
              [data]="item.getNavigationElement()"
              [icon]="item.getIcon()"
            >
            </app-button>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>
  </mat-toolbar-row>

  <mat-toolbar-row
    *ngIf="template?.getMenuMode() === 'HIDE' && (mousein || dragging)"
    class="hidden-elements hidden-toolbar"
  >
    <span class="hidden-toolbar-title" *ngIf="showHiddenToolbarTitle">{{ template?.getName() }}</span>
    <span class="content-spacer"></span>
    <ng-container *ngFor="let item of template.getPredefinedMenuItems()">
      <app-button
        [@inOutAnimation]
        [style.margin-left]="left + 'px'"
        cdkDrag
        (cdkDragStarted)="dragging = true"
        (cdkDragEnded)="dragEnded($event)"
        cdkDragBoundary=".hidden-toolbar"
        cdkDragLockAxis="x"
        [disabled]="dragging"
        class="mat-elevation-z2 toolbar-navigator spacer hidden"
        [style.overflow]="'hidden'"
        [class.is-active]="item.isActive()"
        [data]="item.getNavigationElement()"
        [icon]="item.getIcon()"
      ></app-button>
    </ng-container>
  </mat-toolbar-row>

  <mat-toolbar-row
    *ngIf="showToolbarRow"
    class="actions"
    #main
    [style.padding-left]="toolbarPaddingLeft + 'px'"
    [style.padding-right]="toolbarPaddingRight + 'px'"
    [class.extended]="!template?.getExtendAsOverlay() && template?.isExtended()"
  >
    <button
      *ngIf="onRemoveAllFilters"
      class="remove-filters"
      mat-button
      (click)="onRemoveAllFilters()"
      [saxmsTooltip]="'Table.Action.resetAllFilters' | translate"
    >
      <mat-icon>filter_alt_off</mat-icon>
    </button>
    <button
      *ngIf="canScrollLeft && !template.isExtended() && !template.getActiveMenuItem().hasDisabledScrollHandles()"
      class="scroll left"
      mat-button
      (click)="scrollLeft($event)"
    >
      <mat-icon>keyboard_arrow_left</mat-icon>
    </button>
    <button
      *ngIf="canScrollRight && !template.isExtended() && !template.getActiveMenuItem().hasDisabledScrollHandles()"
      class="scroll right"
      mat-button
      (click)="scrollRight($event)"
    >
      <mat-icon>keyboard_arrow_right</mat-icon>
    </button>
    <div #scrollContainer class="scroll-container" #actionBar>
      <ng-container *ngIf="!template.getActiveMenuItem().isEmpty(); else defaultText">
        <ng-container *ngFor="let group of template.getActiveMenuItem().getToolbarGroups()">
          <ng-container
            *ngTemplateOutlet="
              tGroup;
              context: {
                $implicit: group,
                extended: !template.getExtendAsOverlay() && template.isExtended(),
                resourceId: template.getActiveMenuItem().getResourceId() || resourceId
              }
            "
          >
          </ng-container>
        </ng-container>
      </ng-container>
      <!-- default text -->
      <ng-template #defaultText>
        <ng-container *ngIf="template.getActiveMenuItem().getEmptyGroupText()">
          <div class="empty-text">
            {{ template.getActiveMenuItem().getEmptyGroupText() | translate }}
          </div>
        </ng-container>
      </ng-template>
    </div>
    <ng-container>
      <button
        *ngIf="(canScrollLeft || canScrollRight) && !template.isExtended()"
        class="opener mat-elevation-z2"
        (click)="toggleExtend()"
      >
        <mat-icon>keyboard_arrow_down</mat-icon>
      </button>
      <ng-container *ngIf="template.getExtendAsOverlay() && template.isExtended()">
        <ng-container *ngTemplateOutlet="extenderTemplate"></ng-container>
      </ng-container>
      <ng-container *ngIf="!template.getExtendAsOverlay() && template.isExtended()">
        <button class="closer closer-no-overlay mat-elevation-z2" (click)="toggleExtend()">
          <mat-icon>keyboard_arrow_up</mat-icon>
        </button>
      </ng-container>
    </ng-container>
  </mat-toolbar-row>
</mat-toolbar>

<!-- extender -->
<ng-template #extenderTemplate>
  <div
    class="extender mat-elevation-z4"
    [style.padding-left]="toolbarPaddingLeft + 'px'"
    [style.padding-right]="toolbarPaddingRight + 'px'"
    [style.width]="'calc(100% - ' + (toolbarPaddingLeft + toolbarPaddingRight) + 'px)'"
  >
    <ng-container *ngIf="template.getActiveMenuItem().getToolbarGroups().length > 0">
      <ng-container *ngFor="let group of template.getActiveMenuItem().getToolbarGroups()">
        <ng-container
          *ngTemplateOutlet="
            tGroup;
            context: {
              $implicit: group,
              extended: true,
              resourceId: template.getActiveMenuItem().getResourceId() || resourceId
            }
          "
        >
        </ng-container>
      </ng-container>
    </ng-container>
    <button class="closer closer-overlay mat-elevation-z2" (click)="toggleExtend()">
      <mat-icon>keyboard_arrow_up</mat-icon>
    </button>
  </div>
</ng-template>
<!-- element switcher -->
<ng-template #tSwitch let-element>
  <ng-container *ngIf="element.getFieldType() === 'BUTTON' || element.getFieldType() === 'TOGGLE_BUTTON'">
    <ng-container *ngTemplateOutlet="tButton; context: { $implicit: element }"></ng-container>
  </ng-container>
  <ng-container *ngIf="element.getFieldType() === 'PREDEFINED'">
    <ng-container *ngTemplateOutlet="tPredefined; context: { $implicit: element }"></ng-container>
  </ng-container>
</ng-template>

<!-- button element -->
<ng-template #tButton let-button>
  <!--todo-->
  <app-button class="toolbar-button" [data]="button" [icon]="button.getIcon()"></app-button>
</ng-template>

<!-- group -->
<ng-template #tGroup let-group let-resourceId="resourceId" let-extended="extended">
  <ng-container *ngIf="group.getEntryElements().length > 0">
    <div
      cdkDropList
      #groupList="cdkDropList"
      [cdkDropListData]="group.getEntryElements()"
      (cdkDropListDropped)="drop($event)"
      cdkDropListOrientation="horizontal"
      [cdkDropListConnectedTo]="[shortcutList]"
      class="toolbar-group"
      [class.extended]="extended"
    >
      <span class="title">{{ group.getName() | translate }}</span>
      <ng-container *ngTemplateOutlet="tContent; context: { $implicit: group, resourceId: resourceId }"></ng-container>
    </div>
  </ng-container>
</ng-template>

<mat-menu #contextMenu="matMenu" yPosition="below">
  <ng-template matMenuContent let-item="item">
    <button mat-menu-item (click)="addToShortcuts(item)" *ngIf="!findElementInShortcuts(item.getId())">
      <mat-icon>star</mat-icon>
      {{ 'TOOLBAR.CONTEXTMENU.SHORTCUT.add' | translate }}
    </button>
    <button mat-menu-item (click)="removeFromShortcuts(item)" *ngIf="findElementInShortcuts(item.getId())">
      <mat-icon>star</mat-icon>
      {{ 'TOOLBAR.CONTEXTMENU.SHORTCUT.remove' | translate }}
    </button>
  </ng-template>
</mat-menu>
