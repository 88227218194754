import { Observable, Subject, takeUntil } from 'rxjs';
import { GanttUtilities } from '../../gantt-utilities/gantt-utilities';
import { NodeProportionsStateConnector } from '../../html-structure/node-proportion-state/node-proportion-state-connector';
import { EGanttScrollContainer } from '../../html-structure/scroll-container.enum';
import { BestGantt } from '../../main';
import { BestGanttPlugIn } from '../gantt-plug-in';
import { IndexCardTextBuilder } from '../index-card/text-creator';
import { IndexCardTextDefault } from '../index-card/text-creator/text-creator-default';
import { RowRasterColorExecuter } from '../row-raster-color/row-raster-color-executer';
import { GanttSplitOverlappingShifts } from '../split-overlapping-shifts/split-overlapping-shifts-executer';

/**
 * Handles colorization of rows in shift Area by their hierarchie.
 * @keywords plugin, executer, row, color, overview, toggle, hierarchie
 * @plugin row-color-hierarchie
 * @class
 * @constructor
 * @extends BestGanttPlugIn
 * @requires BestGanttPlugIn
 */
export class RowColorHierarchieExecuter extends BestGanttPlugIn {
  private _canvas: { [id: string]: d3.Selection<SVGGElement, undefined, d3.BaseType, undefined> };
  private _colorizedRowItems: { [id: string]: d3.Selection<SVGRectElement, IRowColorData, d3.BaseType, undefined> };
  private _splitOverlappingShiftsPlugin: GanttSplitOverlappingShifts = undefined;
  private _width = 1000;
  private _levelByIdMap: { [id: string]: number } = {};
  private _settings: { active: boolean; colors: string[] } = { active: false, colors: [] };
  private _executer: IndexCardTextBuilder;

  private _onDestroySubject: Subject<void> = new Subject<void>();

  constructor() {
    super(); // call super-constructor

    this._canvas = {};
    this._colorizedRowItems = {};
  }

  /**
   * Initialization of plugin. Part of plugin lifecycle.
   * @param ganttDiagram Gantt diagram which will be affected by this plugin.
   */
  public initPlugIn(ganttDiagram: BestGantt): void {
    // Initialise every color transparent.
    for (let i = 0; i < 3; i++) {
      this._settings.colors[i] = '#00000000';
    }

    this.ganttDiagram = ganttDiagram;

    this._initCanvas();

    this._width = parseInt(this.ganttDiagram.getShiftFacade().getCanvasBehindShifts().attr('width'));

    // init callbacks
    this.ganttDiagram
      .getVerticalScrollHandler()
      .onScrollVerticalUpdate.pipe(takeUntil(this.onDestroy))
      .subscribe(() => this._buildRowColors());
    this.ganttDiagram
      .getOpenRowHandler()
      .afterShiftRowOpen.pipe(takeUntil(this.onDestroy))
      .subscribe(() => this._buildRowColors());
    this.ganttDiagram
      .getOpenRowHandler()
      .afterShiftRowClosed.pipe(takeUntil(this.onDestroy))
      .subscribe(() => this._buildRowColors());
    this.ganttDiagram.subscribeOriginDataUpdate('updateLevelMap' + this.UUID, this._initLevelMap.bind(this));

    this.ganttDiagram
      .getPlugInHandler()
      .subscribeToPlugIns(
        'subscribeRowRasterColorExecuterPlugin' + this.UUID,
        this._subscribeToRowRasterColorPlugin.bind(this)
      );
    this.ganttDiagram
      .getPlugInHandler()
      .subscribeToPlugIns(`subscribeShiftSplitPlugin ${this.UUID}`, this._subscribeToShiftSplitPlugin.bind(this));

    this._initLevelMap();

    this._buildRowColors();
  }

  private _initCanvas(classBeforeInsert: string = undefined): void {
    for (const scrollContainerId of Object.values(EGanttScrollContainer)) {
      classBeforeInsert =
        classBeforeInsert || this.ganttDiagram.getGlobalTimeSpanMarker().getCanvas(scrollContainerId).attr('class');

      this._canvas[scrollContainerId] = this.ganttDiagram
        .getShiftFacade()
        .getCanvasBehindShifts(scrollContainerId)
        .insert('g', '.' + classBeforeInsert)
        .attr('class', 'gantt-row-colors-by-hierarchie');
    }
  }

  private _removeCanvas(): void {
    for (const scrollContainerId in this._canvas) {
      this._canvas[scrollContainerId].remove();
      this._canvas[scrollContainerId] = undefined;
    }
  }

  /**
   * Called for each new PlugIn, waits for a {RowRasterColorExecuter}. Then unsubscribes.
   * @callback
   * @param {BestGanttPlugIn} plugin Any PlugIn.
   */
  private _subscribeToRowRasterColorPlugin(bestGanttPlugin) {
    try {
      // catch that RowRasterColorExecuter is not defined
      if (!(bestGanttPlugin instanceof RowRasterColorExecuter)) {
        return;
      }
    } catch (e) {
      this.ganttDiagram.getPlugInHandler().unSubscribeToPlugIns('subscribeRowRasterColorExecuterPlugin' + this.UUID);
      return;
    }

    this.ganttDiagram.getPlugInHandler().unSubscribeToPlugIns('subscribeRowRasterColorExecuterPlugin' + this.UUID);
    const rowRasterExecuterPlugIn = bestGanttPlugin;

    // move this plugIn node Behind RowRasterColorExecuter
    this._removeCanvas();
    this._initCanvas(rowRasterExecuterPlugIn.canvasClass);
  }

  private _subscribeToShiftSplitPlugin(bestGanttPlugin) {
    try {
      // catch that GanttSplitOverlappingShifts is not defined
      if (!(bestGanttPlugin instanceof GanttSplitOverlappingShifts)) {
        return;
      }
    } catch (e) {
      console.warn(`GanttSplitOverlappingShifts is not defined`, e);
      console.warn(
        `Cann't apply >>IndexCardTextOutsideSplit<< Text Strategie for Index Card Mode, reverting to default.`
      );
      this.ganttDiagram.getPlugInHandler().unSubscribeToPlugIns(`subscribeShiftSplitPlugin ${this.UUID}`);
      this._executer.changeIndexCardTextStrategie(new IndexCardTextDefault());
      return;
    }

    this.ganttDiagram.getPlugInHandler().unSubscribeToPlugIns(`subscribeShiftSplitPlugin ${this.UUID}`);
    this._splitOverlappingShiftsPlugin = bestGanttPlugin;
    this._splitOverlappingShiftsPlugin.subscribeAfterSplit('updateLevelMap' + this.UUID, this._initLevelMap.bind(this));
    this._splitOverlappingShiftsPlugin.subscribeAfterSplit('rebuild' + this.UUID, this._buildRowColors.bind(this));
  }

  /**
   * Remove of plugin. Part of plugin lifecycle.
   */
  public removePlugIn(): void {
    // callback unsubscribe
    this.ganttDiagram.getPlugInHandler().unSubscribeToPlugIns('subscribeRowRasterColorExecuterPlugin' + this.UUID);
    this.ganttDiagram.getPlugInHandler().unSubscribeToPlugIns(`subscribeShiftSplitPlugin ${this.UUID}`);
    this._splitOverlappingShiftsPlugin.unSubscribeAfterSplit('updateLevelMap' + this.UUID);
    this._splitOverlappingShiftsPlugin.unSubscribeAfterSplit('rebuild' + this.UUID);

    this._onDestroySubject.next();
    this._onDestroySubject.complete();

    // remove rows
    this._removeCanvas();
  }

  /**
   * Generates a map of row ids with associated levels.
   */
  private _initLevelMap() {
    this._levelByIdMap = {};

    const rowDataSet = this.ganttDiagram.getDataHandler().getOriginDataset().ganttEntries;

    const recursiveLevelFinder = (level, entries) => {
      for (const entry of entries) {
        this._levelByIdMap[entry.id] = level;
        if (entry.child.length) {
          recursiveLevelFinder(level + 1, entry.child);
        }
      }
    };
    recursiveLevelFinder(0, rowDataSet);
  }

  public update(): void {
    // update gantt-width
    this._width = parseFloat(
      this.ganttDiagram.getShiftFacade().getShiftBuilder().getCanvasInFrontShifts().attr('width')
    );
    this._buildRowColors();
  }

  /**
   * Adds row background colors by adding rects.
   * Colors are based of the level in the row Hierarchie.
   */
  private _buildRowColors() {
    if (!this._settings.active) return;
    this._removeAll();

    const scrollContainerIds = [EGanttScrollContainer.DEFAULT];
    if (this.ganttDiagram.getConfig().showStickyRows()) {
      scrollContainerIds.push(EGanttScrollContainer.STICKY_ROWS);
    }

    for (const scrollContainerId of scrollContainerIds) {
      if (this._settings.colors.length < 1) return;

      const dataSet = this._getCalculatedDataSet(scrollContainerId);

      this._colorizedRowItems[scrollContainerId] = this._canvas[scrollContainerId]
        .selectAll<SVGRectElement, IRowColorData>('dummy')
        .data(dataSet);

      this._colorizedRowItems[scrollContainerId]
        .attr('y', function (d) {
          return d.y;
        })
        .attr('width', this._width)
        .attr('height', function (d) {
          return d.height;
        });

      this._colorizedRowItems[scrollContainerId]
        .enter()
        .append('rect')
        .attr('y', function (d) {
          return d.y;
        })
        .attr('width', this._width)
        .attr('height', function (d) {
          return d.height;
        })
        .attr('fill', (d) => {
          if (d.color) return d.color;
        })
        .attr('class', 'gantt-row-colorize-hierarchie-item');
    }
  }

  private _getCalculatedDataSet(scrollContainerId: EGanttScrollContainer): IRowColorData[] {
    const nodeProportionsState = new NodeProportionsStateConnector(
      this.ganttDiagram.getNodeProportionsState(),
      scrollContainerId
    );

    return GanttUtilities.filterDataSetByViewPort(
      this.ganttDiagram.getHTMLStructureBuilder().getShiftContainer(scrollContainerId).node(),
      this.ganttDiagram.getRenderDataHandler().getRenderDataYAxis(scrollContainerId),
      this.ganttDiagram.getRenderDataHandler(),
      0,
      nodeProportionsState.getShiftViewPortProportions().height,
      nodeProportionsState.getScrollTopPosition()
    )
      .map((rowData) => {
        const level = this._levelByIdMap[rowData.id];
        const color = this.getColorByHierarchieLevel(level);
        return {
          y: this.ganttDiagram.getRenderDataHandler().getStateStorage().getYPositionRow(rowData.id),
          height: rowData.height,
          color: color,
        };
      })
      .filter((row) => row.color != '#00000000');
  }

  /**
   * Removes all row colorize rects.
   */
  private _removeAll(): void {
    for (const scrollContainerId in this._canvas) {
      this._canvas[scrollContainerId].selectAll('.gantt-row-colorize-hierarchie-item').remove();
    }
  }

  public updatePlugInHeight(): void {
    this._buildRowColors();
  }

  //
  // GETTER & SETTER
  //

  /**
   * Toggle function to show/hide row colorizing.
   * @param {boolean} bool Toggling.
   */
  setActive(bool) {
    this._settings.active = bool;
    if (!this._settings.active) this._removeAll();
    else this._buildRowColors();
  }

  /**
   * @param {string[]} colors Array of hex color values.
   */
  setRowHierarchieColor(colors) {
    this._settings.colors = [...colors];
    this._buildRowColors();
  }

  /**
   * Returns color as <b>hex string</b> based on the hierarchie defined color.
   * If no color is defined for level, returns <i>transparent</i> color.
   * @param {number} level Level of the row.
   * @return {string} Hex color value.
   */
  getColorByHierarchieLevel(level) {
    const colorArray = this._settings.colors;
    if (colorArray.length > 0) {
      return colorArray[level] ? colorArray[level] : '#00000000';
    }
    return null;
  }

  //
  // OBSERVABLES
  //

  /**
   * Observable which gets triggered when the instance gets destroyed.
   */
  private get onDestroy(): Observable<void> {
    return this._onDestroySubject.asObservable();
  }
}

/**
 * Data structure which contains all necessary data to colorize a row.
 */
interface IRowColorData {
  y: number;
  height: number;
  color: string;
}
