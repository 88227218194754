import * as d3 from 'd3';

/**
 * Handler to animate opening/closing of the yaxis without performance lags.
 * Therefore, the area to translate will be cut out of the origin canvas and pasted into a new ("temporary") canvas.
 * This temporary canvas will be animated via CSS-animation.
 */
export class GanttCanvasAnimator {
  private _temporaryAnimationCanvas: d3.Selection<HTMLCanvasElement, unknown, HTMLElement, any>;

  constructor() {}

  /**
   * Changes position of drawed elements inside given canvas-HTML-node fluently.
   * @param {HTMLNode} originCanvasNode Canvas node.
   * @param {number} cutHeight Vertical position to cut through the canvas and translate all elements beyond this point.
   * @param {number} translationY Translation distance.
   * @param {number} [animationDuration] Animation time in milliseconds. Defaults to 100.
   * @param {function} [afterAnimation] Optional callback function which will be executed after animation.
   */
  changeAreaPosition(
    originCanvasNode: HTMLCanvasElement,
    cutHeight,
    translationY,
    animationDuration = 100,
    afterAnimation
  ) {
    const s = this;

    const canvasDimensions = originCanvasNode.getBoundingClientRect();
    const ctx = originCanvasNode.getContext('2d');

    const canvasTopPosition = parseFloat(d3.select(originCanvasNode).style('top'));
    const copiedArea = ctx.getImageData(
      0,
      cutHeight - canvasTopPosition,
      canvasDimensions.width,
      canvasDimensions.height
    );

    ctx.clearRect(0, cutHeight - canvasTopPosition, canvasDimensions.width, canvasDimensions.height);

    // copy given canvas
    s._temporaryAnimationCanvas = d3
      .select<HTMLDivElement, undefined>(originCanvasNode.parentNode as HTMLDivElement)
      .append('canvas')
      .attr('class', d3.select(originCanvasNode).attr('class'))
      .attr('width', d3.select(originCanvasNode).attr('width'))
      .attr('height', d3.select(originCanvasNode).attr('height'))
      .style('top', canvasTopPosition + 'px');

    const ctxCopy = s._temporaryAnimationCanvas.node().getContext('2d');
    ctxCopy.putImageData(copiedArea, 0, cutHeight - canvasTopPosition);

    // translate canvas
    s._temporaryAnimationCanvas
      .transition()
      .duration(300)
      .style('top', canvasTopPosition + translationY + 'px');
  }

  /**
   * Removes temporary canvas node.
   */
  clearTemporaryAnimationCanvas() {
    if (!this._temporaryAnimationCanvas) return;
    this._temporaryAnimationCanvas.remove();
    this._temporaryAnimationCanvas = null;
  }
}
