import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { takeWhile } from 'rxjs/operators';
import { ViewService } from './../../../view/view.service';

@Component({
  selector: 'generic-options',
  templateUrl: './generic-options.html',
  styleUrls: ['./generic-options.scss'],
  providers: [],
})
export class GenericOptionsComponent implements OnInit, OnDestroy {
  public interface: any;
  public settings: any;

  public styleRef;
  private isAlive = true;

  constructor(
    public dialogRef: MatDialogRef<GenericOptionsComponent>,
    public globalViewService: ViewService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.interface = data.interface;
    this.settings = JSON.parse(JSON.stringify(data.settings));
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }

  ngOnInit() {
    this.globalViewService
      .getCurrentTheme()
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((style) => (this.styleRef = style));
  }

  public accept() {
    this.dialogRef.close(this.settings);
  }

  public cancel() {
    this.dialogRef.close();
  }
}
