import { GanttCallBackStackExecuter } from '../../callback-tools/callback-stack-executer';
import { ToHexColorConverter } from '../../color/color-converter/to-hex-converter';
import { GanttColorizationRaster } from '../../color/colorization-raster/colorization-raster';
import { EGanttColorizationRasterStrategy } from '../../color/colorization-raster/colorization-raster-strategy.enum';
import { GanttColorizer } from '../../color/colorizer';
import { GanttDataRow } from '../../data-handler/data-structure/data-structure';
import { DataManipulator } from '../../data-handler/data-tools/data-manipulator';
import { GanttDateUtilities } from '../../gantt-utilities/date-utilities';
import { BestGantt } from '../../main';
import { BestGanttPlugIn } from '../gantt-plug-in';
import { EGanttShiftColorizationStrategy } from './colorization-strategy.enum';
import { GanttShiftColorByAttributeEvent } from './undo-redo/shiftcolor-by-attribute-event';

/**
 * Colorizes shifts inside gantt by additional attribute.
 * @keywords plugin, executer, color, attribute, shift, colorize, reset, canvas
 * @plugin shiftcolor-by-attribute
 * @class
 * @constructor
 * @extends BestGanttPlugIn
 * @property {GanttShiftColorByAttributeSpecificColor[]} specificColors
 *
 * @requires BestGanttPlugIn
 * @requires DataManipulator
 * @requires GanttCallBackStackExecuter
 * @requires GanttColorizer
 */
export class GanttShiftColorByAttribute extends BestGanttPlugIn {
  notAssignedTag: string;
  private _allAttributes: string[] = [];
  private _originShiftColors: { [id: string]: string };
  callBack: any;
  colorAttribute: string;
  private _existingAttributeValues: GanttShiftColorByAttributeValue[] = [];
  useSpecificColors: any;
  specificColors: GanttShiftColorByAttributeSpecificColor[];
  private _uniqueColoringMode = false;
  private _isActive = false;
  private _lastColorizeParams: GanttShiftColorByAttributeParams = null;
  private _lastColorizeStrategy: { strategy: EGanttShiftColorizationStrategy } = null;
  private colorizeNotAssignedShifts = false;
  private _colorizationRaster: GanttColorizationRaster = null;

  constructor() {
    super(); // call super-constructor

    /**
     * @type {BestGantt}
     */
    this.ganttDiagram = null;
    this.notAssignedTag = 'not assigned';

    this._originShiftColors = {};
    this.callBack = {
      afterAttributeColorize: {},
      afterResetColor: {},
    };
    this.colorAttribute = null;

    this.useSpecificColors = null;
    this.specificColors = [];
  }

  /**
   * @override
   */
  public initPlugIn(ganttDiagram: BestGantt): void {
    const s = this;
    s.ganttDiagram = ganttDiagram;
    s.storeOriginShiftColor();
    // init callbacks
    this.addAfterAttributeColorizeCallback('preloadShiftPatterns', () =>
      s.ganttDiagram.getShiftFacade().preloadPatterns()
    );
    s.ganttDiagram.subscribeOriginDataUpdate('updateAttributeColorize' + s.UUID, s.refreshColorization.bind(s));
  }

  /**
   * Remove of plugin. Part of plugin lifecycle.
   */
  removePlugIn() {
    const s = this;
    s.ganttDiagram.unSubscribeOriginDataUpdate('updateAttributeColorize' + s.UUID);
  }

  refreshColorization() {
    const s = this;
    if (!s._lastColorizeParams || !s._isActive) return;
    this.resetColor(false);
    this.colorizeByAttribute(this._lastColorizeParams, this.lastColorizeStrategy);
    // s.ganttDiagram.rerenderShiftsVertical();
  }

  /**
   * Saves all shift colors of current gantt (to reset them if necessary).
   */
  storeOriginShiftColor() {
    const s = this;
    if (!s.ganttDiagram) return;
    DataManipulator.iterateOverDataSet(s.ganttDiagram.getDataHandler().getOriginDataset().ganttEntries, {
      storeOriginColors: function (child: GanttDataRow) {
        for (let i = 0; i < child.shifts.length; i++) {
          const shift = child.shifts[i];
          s._originShiftColors[shift.id] = shift.color;
        }
      },
    });
  }

  /**
   * Resets all shift colors to origin colors.
   * If origin color wasn't found, shift color will be black.
   */
  resetColor(initCanvasData = true) {
    const s = this;
    s.ganttDiagram
      .getHistory()
      .addNewEvent(
        'resetColor',
        new GanttShiftColorByAttributeEvent(),
        this,
        s.colorAttribute /* , s.useSpecificColors*/
      );
    s._isActive = false;
    DataManipulator.iterateOverDataSet(s.ganttDiagram.getDataHandler().getOriginDataset().ganttEntries, {
      resetShiftColor: function (child: GanttDataRow) {
        for (let i = 0; i < child.shifts.length; i++) {
          const shift = child.shifts[i];
          if (shift.disableColorization) continue; // ignore shifts with disabled colorization
          shift.color = s._originShiftColors[shift.id] || '#000000';
        }
      },
    });
    s.colorAttribute = null;
    s._existingAttributeValues = [];

    if (initCanvasData) {
      s.ganttDiagram.getDataHandler().initCanvasShiftData();
    }
  }

  /**
   * Adds a origin shift color by shift id on runtime.
   * @param {string} shiftId Id of shift.
   * @param {string} originShiftColor Origin color of shift.
   */
  public addOriginShiftColor(shiftId: string, originShiftColor: string): void {
    this._originShiftColors[shiftId] = originShiftColor || '#000000';
  }

  /**
   * Collects all shift attributes from shift additional data.
   * @keywords calc, calculation, extract, shift, attribute, data, additional, dataset
   */
  private _calcAllShiftAttributes(): void {
    const s = this;
    s._allAttributes = [];

    DataManipulator.iterateOverDataSet(s.ganttDiagram.getDataHandler().getOriginDataset().ganttEntries, {
      addAllAttributes: function (child: GanttDataRow) {
        for (let i = 0; i < child.shifts.length; i++) {
          const shift = child.shifts[i];
          if (shift.disableColorization) continue; // ignore shifts with disabled colorization
          for (const attribute in shift.additionalData) {
            if (s._allAttributes.indexOf(attribute) == -1) {
              s._allAttributes.push(attribute);
            }
          }
        }
      },
    });
  }

  /**
   * Returns all shift attributes from shift additional data.
   * @return {string[]} List of all shift attributes.
   */
  public getAllAttributes(): string[] {
    const s = this;
    if (!s._allAttributes || s._allAttributes.length == 0) {
      s._calcAllShiftAttributes();
    }
    return s._allAttributes;
  }

  /**
   * Colorize all gantt shifts by propertyKey.
   * The shift color will be calculated by additional data value string.
   * @param {GanttShiftColorByAttributeParams} shiftColorByAttributeParams
   * @param {{ strategy: EGanttShiftColorizationStrategy }} strategy
   */
  public colorizeByAttribute(
    shiftColorByAttributeParams: GanttShiftColorByAttributeParams,
    strategy: { strategy: EGanttShiftColorizationStrategy }
  ): void {
    const propertyKey = shiftColorByAttributeParams.propertyKey;

    this._isActive = true;
    this._lastColorizeParams = shiftColorByAttributeParams;
    this._lastColorizeStrategy = strategy;
    this._existingAttributeValues = [];

    if (this._uniqueColoringMode) {
      this._colorizeByAttributeUnqiue(shiftColorByAttributeParams, strategy);
    } else {
      this._colorizeByAttributeNotUnique(shiftColorByAttributeParams, strategy);
    }
    this.ganttDiagram.getDataHandler().initCanvasShiftData();

    this.colorAttribute = propertyKey;
    GanttCallBackStackExecuter.execute(this.callBack.afterAttributeColorize, propertyKey);
  }

  /**
   * Colorize all gantt shifts by propertyKey with a unique color.
   * The shift color will be calculated by additional data value string.
   * @param {GanttShiftColorByAttributeParams} shiftColorByAttributeParams
   * @param {{ strategy: EGanttShiftColorizationStrategy }} strategy
   */
  private _colorizeByAttributeUnqiue(
    shiftColorByAttributeParams: GanttShiftColorByAttributeParams,
    strategy: { strategy: EGanttShiftColorizationStrategy }
  ): void {
    const propertyKey = shiftColorByAttributeParams.propertyKey;
    const emptyAttributeColor = shiftColorByAttributeParams.emptyAttributeColor;
    const pathToProperties = shiftColorByAttributeParams.pathToProperties;
    const valueProperties = shiftColorByAttributeParams.valueProperties;
    const dataType = shiftColorByAttributeParams.dataType;
    const uniqueColors = this._getUniqueColors(shiftColorByAttributeParams);

    DataManipulator.iterateOverDataSet(this.ganttDiagram.getDataHandler().getOriginDataset().ganttEntries, {
      addAllAttributes: (child: GanttDataRow) => {
        for (const shift of child.shifts) {
          if (shift.disableColorization) continue; // ignore shifts with disabled colorization
          let notAssigned = false;
          const additionalDataEntry = this._getAdditionalDataEntry(shift, pathToProperties);
          const additionalDataValue = this._getTargetAttribute(
            additionalDataEntry[propertyKey],
            valueProperties,
            dataType
          );
          if (additionalDataValue.value) {
            shift.color = this._getShiftColorCategory(additionalDataValue.value, strategy, uniqueColors);
          } else {
            notAssigned = true;
            shift.color = this.colorizeNotAssignedShifts
              ? emptyAttributeColor
              : this._originShiftColors[shift.id] || '#000000';
            additionalDataValue.value = this.notAssignedTag;
          }
          const color = notAssigned ? emptyAttributeColor : shift.color;
          this._addExistingAttributeValue(additionalDataValue.value, color, additionalDataValue.originalValue);
        }
      },
    });
  }

  /**
   * Colorize all gantt shifts by propertyKey with a backend-defined color.
   * The shift color will be calculated by additional data value string.
   * @param {GanttShiftColorByAttributeParams} shiftColorByAttributeParams
   * @param {{ strategy: EGanttShiftColorizationStrategy }} strategy
   */
  private _colorizeByAttributeNotUnique(
    shiftColorByAttributeParams: GanttShiftColorByAttributeParams,
    strategy: { strategy: EGanttShiftColorizationStrategy }
  ): void {
    const propertyKey = shiftColorByAttributeParams.propertyKey;
    const emptyAttributeColor = shiftColorByAttributeParams.emptyAttributeColor;
    const pathToProperties = shiftColorByAttributeParams.pathToProperties;
    const valueProperties = shiftColorByAttributeParams.valueProperties;
    const dataType = shiftColorByAttributeParams.dataType;

    DataManipulator.iterateOverDataSet(this.ganttDiagram.getDataHandler().getOriginDataset().ganttEntries, {
      addAllAttributes: (child: GanttDataRow) => {
        for (const shift of child.shifts) {
          if (shift.disableColorization) continue; // ignore shifts with disabled colorization
          const additionalDataEntry = this._getAdditionalDataEntry(shift, pathToProperties);
          const additionalDataValue = this._getTargetAttribute(
            additionalDataEntry[propertyKey],
            valueProperties,
            dataType
          );
          let notAssigned = false;
          if (strategy && strategy.strategy === 'CUSTOM_ATTRIBUTE_COLOR') {
            // special case, colors are predefined by backend
            const color = additionalDataValue.value ? additionalDataEntry[propertyKey].t3 : null;
            if (color) {
              shift.color = ToHexColorConverter.convertColorToHex(color);
            } else {
              notAssigned = true;
              shift.color = this.colorizeNotAssignedShifts
                ? emptyAttributeColor
                : this._originShiftColors[shift.id] || '#000000';
              additionalDataValue.value = this.notAssignedTag;
            }
          } else if (additionalDataValue.value) {
            // get colors for other strategies
            shift.color = this._getShiftColor(additionalDataValue.value, strategy);
          } else {
            // if property value is not set
            notAssigned = true;
            shift.color = this.colorizeNotAssignedShifts
              ? emptyAttributeColor
              : this._originShiftColors[shift.id] || '#000000';
            additionalDataValue.value = this.notAssignedTag;
          }
          const color = notAssigned ? emptyAttributeColor : shift.color;
          this._addExistingAttributeValue(additionalDataValue.value, color, additionalDataValue.originalValue);
        }
      },
    });
  }

  /**
   * Generates a map of value-color pairs for shift colorization.
   * @param shiftColorByAttributeParams Shift colorization parameters.
   * @returns Map of value-color pairs for shift colorization.
   */
  private _getUniqueColors(shiftColorByAttributeParams: GanttShiftColorByAttributeParams): Map<string, string> {
    const propertyKey = shiftColorByAttributeParams.propertyKey;
    const emptyAttributeColor = shiftColorByAttributeParams.emptyAttributeColor;
    const pathToProperties = shiftColorByAttributeParams.pathToProperties;
    const valueProperties = shiftColorByAttributeParams.valueProperties;
    const dataType = shiftColorByAttributeParams.dataType;

    // extract all values
    const uniqueValues = new Set<string>();
    DataManipulator.iterateOverDataSet(this.ganttDiagram.getDataHandler().getOriginDataset().ganttEntries, {
      calcUniqueAttributeAmount: (child: GanttDataRow) => {
        for (const shift of child.shifts) {
          if (shift.disableColorization) continue; // ignore shifts with disabled colorization
          shift.color = emptyAttributeColor;
          const additionalDataEntry = this._getAdditionalDataEntry(shift, pathToProperties);
          const additionalDataValue = this._getTargetAttribute(
            additionalDataEntry[propertyKey],
            valueProperties,
            dataType
          );
          if (additionalDataValue.value) {
            uniqueValues.add(additionalDataValue.value);
          }
        }
      },
    });

    // generate a color for each value
    const uniqueColors = new Map<string, string>();
    const uniqueValuesList = Array.from(uniqueValues.values()).sort((a, b) => a.localeCompare(b));

    const colorizationRasterStrategy = this._uniqueColoringMode
      ? EGanttColorizationRasterStrategy.RANDOM
      : EGanttColorizationRasterStrategy.CUSTOM;

    if (
      !this._uniqueColoringMode ||
      colorizationRasterStrategy !== this._colorizationRaster?.strategy ||
      this._colorizationRaster?.n !== uniqueValues.size
    ) {
      this._colorizationRaster = new GanttColorizationRaster(uniqueValues.size, colorizationRasterStrategy);
      if (!this._uniqueColoringMode) {
        this._colorizationRaster.indexMapperCb = this._valueHashIndexMapperCb.bind(this, uniqueValuesList);
      }
    }

    for (let i = 0; i < uniqueValuesList.length; i++) {
      uniqueColors.set(uniqueValuesList[i], this._colorizationRaster.getHexColor(i));
    }

    return uniqueColors;
  }

  /**
   * Callback for custom colorization raster index mapping which uses hashes of string values to determine the color index.
   * @param uniqueValues Array of string values to generate the color indexes for.
   * @param i Current index which should be mapped into a color index.
   * @param executer Reference to the executing instance of the colorization raster.
   * @returns Mapped color index.
   */
  private _valueHashIndexMapperCb(uniqueValues: string[], i: number, executer: GanttColorizationRaster): number {
    if (i >= uniqueValues.length) return i;
    const uniqueValue = uniqueValues[i];

    // generate hash number of string
    let hash = 0;
    for (let j = 0; j < uniqueValue.length; j++) {
      hash = uniqueValue.charCodeAt(j) + ((hash << 5) - hash);
    }

    // assign index to value based on hash number
    let done = false;
    let index = Math.abs(hash) % uniqueValues.length;
    do {
      if (executer.getIndexByColorIndex(index) !== undefined) {
        index++;
        if (index >= uniqueValues.length) index = 0;
      } else {
        done = true;
      }
    } while (!done);

    return index;
  }

  /**
   * @private
   * Navigates to the desired path.
   * @param {Object} shift Shift entry of origin data.
   * @param {string[]} pathToProperties Individually path names.
   * @returns {Object} Target object.
   */
  private _getAdditionalDataEntry(shift, pathToProperties) {
    if (!pathToProperties) return shift.additionalData;

    let additionalDataEntry = shift.additionalData;

    for (const path of pathToProperties) {
      additionalDataEntry = additionalDataEntry[path];
      if (additionalDataEntry == null) break;
    }
    return additionalDataEntry;
  }

  /**
   * Extracts the specific color of shift by strategy.
   * @param {string} additionalDataValue Name of attribute.
   * @param {{strategy: EGanttShiftColorizationStrategy}} strategy Used colorize strategy.
   * @returns {string} hex color string
   */
  private _getShiftColor(additionalDataValue: string, strategy: { strategy: EGanttShiftColorizationStrategy }): string {
    const strategyOption = strategy ? strategy.strategy : EGanttShiftColorizationStrategy.DEFAULT;

    switch (strategyOption) {
      case EGanttShiftColorizationStrategy.ATTR_COLOR:
        return this._getAttributeColor(additionalDataValue, strategy, null);
      case EGanttShiftColorizationStrategy.DEFAULT:
      case EGanttShiftColorizationStrategy.RANDOM:
      default:
        return GanttColorizer.getColorValueByString(additionalDataValue);
    }
  }

  private _getShiftColorCategory(
    additionalDataValue: string,
    strategy: { strategy: EGanttShiftColorizationStrategy },
    uniqueColors: Map<string, string>
  ): string {
    const strategyOption = strategy ? strategy.strategy : EGanttShiftColorizationStrategy.DEFAULT;
    switch (strategyOption) {
      case EGanttShiftColorizationStrategy.ATTR_COLOR:
        return this._getAttributeColor(additionalDataValue, strategy, uniqueColors);
      case EGanttShiftColorizationStrategy.DEFAULT:
      case EGanttShiftColorizationStrategy.RANDOM:
      default:
        return uniqueColors.get(additionalDataValue);
    }
  }

  /**
   * Extracts the specific attribute color of shift by attribute value.
   * @param {string} additionalDataValue Name of attribute.
   * @param {Object} attributeColorStrategy Object of attribute color strategy.
   * @returns {string} hex color string
   */
  private _getAttributeColor(additionalDataValue, attributeColorStrategy, uniqueColors: Map<string, string>) {
    let color;
    if (attributeColorStrategy.caseSensitive) {
      color = attributeColorStrategy.attrColor[additionalDataValue];
    } else {
      const attributeColors = JSON.parse(JSON.stringify(attributeColorStrategy.attrColor));
      color =
        attributeColors[
          Object.keys(attributeColors).find((key) => key.toLowerCase() === additionalDataValue.toLowerCase())
        ];
    }
    if (!color) {
      if (this._uniqueColoringMode) {
        color = this._getShiftColorCategory(
          additionalDataValue,
          { strategy: attributeColorStrategy.defaultStrategy },
          uniqueColors
        );
      } else {
        color = this._getShiftColor(additionalDataValue, { strategy: attributeColorStrategy.defaultStrategy });
      }
    }
    return color;
  }

  /**
   * Colorize all shifts again.
   */
  update() {}

  /**
   * @private
   * Extracts the target attribute of additional data entry.
   * @param {Object} value Target entry with attributes.
   * @param {string[]} propertyPath Path to target attribute.
   * @param {EGanttBlockAttributeDataType} dataType type of attribute value.
   * @returns {string} Target attribute.
   */
  private _getTargetAttribute(
    value: any,
    propertyPath: string[],
    dataType: string
  ): { value: string; originalValue: unknown } {
    if (!propertyPath || !value) {
      return {
        value: value,
        originalValue: value,
      };
    }
    let originalValue = value;
    for (const valueProperty of propertyPath) {
      originalValue = originalValue[valueProperty];
    }
    let currentValue = originalValue;
    switch (dataType) {
      case 'STRING':
      case 'NUMBER':
        break;
      case 'BOOLEAN':
        currentValue = currentValue ? 'ja' : 'nein';
        break;
      case 'DATE':
      case 'TIME':
      case 'DATE_TIME':
        if (isNaN(currentValue)) {
          return {
            value: currentValue,
            originalValue: originalValue,
          };
        }
        if (typeof currentValue != 'number') {
          originalValue = parseInt(originalValue);
          currentValue = parseInt(currentValue);
        }
        currentValue = dataType !== 'TIME' ? GanttDateUtilities.getDateString(new Date(originalValue)) : '';
        currentValue += dataType === 'DATE_TIME' ? ' ' : '';
        currentValue += dataType !== 'DATE' ? GanttDateUtilities.getTimeString(new Date(originalValue)) + ' Uhr' : '';
        break;
      default:
        break;
    }

    return {
      value: currentValue,
      originalValue: originalValue,
    };
  }

  /**
   * @private
   * @param {string} attributeName
   * @param {string} value
   * @param {string} [emptyAttributeColor]
   * @returns {string} Color value.
   */
  private _getSpecificColorByAttributeValue(attributeName, value, emptyAttributeColor = '#000000') {
    const s = this;
    const foundColorItem = s.specificColors.find(function (dataItem) {
      return dataItem.attributeName == attributeName && dataItem.value == value;
    });
    if (!foundColorItem) return emptyAttributeColor;
    return foundColorItem.color;
  }

  //
  // CALLBACKS
  //
  addAfterAttributeColorizeCallback(id, func) {
    this.callBack.afterAttributeColorize[id] = func;
  }
  removeAfterAttributeColorizeCallback(id) {
    delete this.callBack.afterAttributeColorize[id];
  }

  addAfterResetColorCallback(id, func) {
    this.callBack.afterResetColor[id] = func;
  }
  removeAfterResetColorCallback(id) {
    delete this.callBack.afterResetColor[id];
  }

  //
  // GETTER & SETTER
  //

  public isActive(): boolean {
    return this._isActive;
  }

  /**
   * @param {GanttShiftColorByAttributeSpecificColor[]}
   */
  setSpecificColors(specificColors) {
    this.specificColors = specificColors;
  }

  /**
   * Set a tag if attribute value not exists in shift.
   * @param {String} value Tag for not assigned attribute values.
   */
  setNotAssignedTag(value) {
    this.notAssignedTag = value;
  }

  activateSpecificColors(useSpecificColors) {
    this.useSpecificColors = useSpecificColors;
  }

  /**
   * Returns attribute on which gantt shifts are currently colorized.
   * @return {string} Shift attribute.
   */
  getColorizedAttribute() {
    return this.colorAttribute;
  }

  /**
   * Returns all exisiting values of all shift attributes inside gantt.
   */
  public getAllAttributeValues(): GanttShiftColorByAttributeValue[] {
    return this._existingAttributeValues;
  }

  private _addExistingAttributeValue(value: string, color: string, originalValue: unknown) {
    const foundValue = this._existingAttributeValues.find((elem) => elem.value === value && elem.color === color);
    if (foundValue) {
      foundValue.value = value;
      foundValue.color = color;
      foundValue.originalValue = originalValue;
    } else {
      this._existingAttributeValues.push({
        value: value,
        color: color,
        originalValue: originalValue,
      });
    }
  }

  /**
   * Sets mode to use unique colors for gantt, those are not persistend across different data!
   */
  public setUniqueColoringMode(boolean: boolean): void {
    this._uniqueColoringMode = boolean;
  }

  public isColorizeNotAssignedShifts(): boolean {
    return this.colorizeNotAssignedShifts;
  }

  public setColorizeNotAssignedShifts(bool: boolean): void {
    this.colorizeNotAssignedShifts = bool;
  }

  public get lastColorizeParams(): GanttShiftColorByAttributeParams {
    return this._lastColorizeParams;
  }

  public get lastColorizeStrategy(): { strategy: EGanttShiftColorizationStrategy } {
    return this._lastColorizeStrategy;
  }
}

/**
 * @interface
 */
export interface GanttShiftColorByAttributeValue {
  value: string;
  color: string;
  originalValue: unknown;
}

/**
 * @class
 * @constructor
 */
export class GanttShiftColorByAttributeSpecificColor {
  attributeName: string;
  value: any;
  color: string;

  constructor(attributeName, value, color) {
    this.attributeName = attributeName;
    this.value = value;
    this.color = color;
  }
}

/**
 * @class
 * @constructor
 */
export class GanttShiftColorByAttributeParams {
  propertyKey: string;
  emptyAttributeColor: string;
  pathToProperties: any;
  valueProperties: any;
  dataType: string;

  constructor(propertyKey, emptyAttributeColor, pathToProperties, valueProperties, dataType) {
    this.propertyKey = propertyKey;
    this.emptyAttributeColor = emptyAttributeColor || '#000000';
    this.pathToProperties = pathToProperties;
    this.valueProperties = valueProperties;
    this.dataType = dataType || 'STRING';
  }
}
