import { Injectable, Injector, Type } from '@angular/core';
import { TemplateAdapter } from '@app-modeleditor/utils/template-factory.service';
import { ResourceAdapter } from './../../../../model/resource/resource-adapter.service';
import { Adapter } from '../../../../core/adapter';
import { Tenant } from './tenant';

@Injectable({
  providedIn: 'root',
})
export class TenantAdapter implements Adapter<Tenant> {
  constructor(private injector: Injector) {}

  adapt(item: any): Tenant {
    const t: Tenant = this._applyProperties(this.injector.get(ResourceAdapter).inherit<Tenant>(Tenant, item), item);
    return t;
  }

  inherit?<T extends Tenant>(type: Type<T>, item: any): T {
    if (!item) {
      return null;
    }
    const t: T = this._applyProperties(this.injector.get(ResourceAdapter).inherit<T>(type, item), item);
    return t;
  }

  inheritFrom?<T extends Tenant>(scope: TemplateAdapter, type: Type<T>, item: any): T {
    if (!item) {
      return null;
    }
    const t: T = this._applyProperties(this.injector.get(ResourceAdapter).inheritFrom<T>(scope, type, item), item);
    return t;
  }

  applyValues<T>(scope: TemplateAdapter, item: any, values: any): T {
    throw new Error('Method not implemented.');
  }

  private _applyProperties<T extends Tenant>(t: T, data: any): T {
    t.setSelected(data.selected);
    return t;
  }
}
