<div
  id="navigator-container"
  class="navigator-container"
  [ngClass]="{ 'navigator-container-drag-handle-padding': !_titleAsDragHandle }"
  cdkDrag
  [cdkDragBoundary]="_ganttRef.getParentNode()"
  [cdkDragFreeDragPosition]="_dragPosition"
>
  <ng-container *ngIf="_showTitle">
    <div *ngIf="_titleAsDragHandle" class="navigator-title navigator-title-drag-handle info-text" cdkDragHandle>
      {{ _generateTitleText() }}
    </div>

    <div *ngIf="!_titleAsDragHandle" class="navigator-title info-text">
      {{ _generateTitleText() }}
    </div>

    <mat-divider
      class="navigator-divider"
      [ngClass]="{ 'navigator-divider-drag-handle-padding': !_titleAsDragHandle }"
    ></mat-divider>
  </ng-container>

  <mat-form-field class="navigator-element" appearance="standard">
    <mat-label>Regelbruch</mat-label>
    <mat-select
      [(value)]="_selectedBrokenConstraint"
      (valueChange)="_validateShiftSelection()"
      matTooltipPosition="below"
      [matTooltip]="_generateBrokenConstraintTooltipText(_selectedBrokenConstraint)"
    >
      <mat-option
        [value]="allBrokenConstraints.value"
        [matTooltip]="_generateBrokenConstraintTooltipText(allBrokenConstraints.label)"
        matTooltipPosition="after"
        >{{ allBrokenConstraints.label }}</mat-option
      >
      <mat-option
        *ngFor="let brokenConstraint of _availableBrokenConstraints"
        [value]="brokenConstraint"
        [matTooltip]="_generateBrokenConstraintTooltipText(brokenConstraint)"
        matTooltipPosition="after"
        >{{ brokenConstraint }}</mat-option
      >
    </mat-select>
  </mat-form-field>

  <button
    mat-icon-button
    class="navigator-element"
    *ngIf="_showLeftRightButtons"
    (click)="_navigateToPreviousShift()"
    [disabled]="_getNumberOfSelectedShift() <= 1 ? true : false"
  >
    <mat-icon>navigate_before</mat-icon>
  </button>

  <mat-form-field class="navigator-element" appearance="standard">
    <mat-label>Schicht</mat-label>
    <mat-select
      [(value)]="_selectedShift"
      (valueChange)="_automaticNavigation && _triggerNavigationEvent(_selectedShift)"
      matTooltipPosition="below"
      [matTooltip]="_generateShiftTooltipText(_selectedShift)"
    >
      <mat-option
        *ngIf="
          !_availableShifts.get(_selectedBrokenConstraint) || _availableShifts.get(_selectedBrokenConstraint).length < 1
        "
        [value]="noShifts.value"
        [matTooltip]="_generateShiftTooltipText(noShifts.value)"
        matTooltipPosition="after"
        >---</mat-option
      >
      <mat-option
        *ngFor="let shift of _availableShifts.get(_selectedBrokenConstraint)"
        [value]="shift"
        [matTooltip]="_generateShiftTooltipText(shift)"
        matTooltipPosition="after"
        >{{ _generateShiftLabel(shift) }})</mat-option
      >
    </mat-select>
  </mat-form-field>

  <button
    mat-icon-button
    class="navigator-element"
    *ngIf="_showLeftRightButtons"
    (click)="_navigateToNextShift()"
    [disabled]="_getNumberOfSelectedShift() >= _getTotalAvailableShiftCount() ? true : false"
  >
    <mat-icon>navigate_next</mat-icon>
  </button>

  <button
    mat-icon-button
    class="navigator-element"
    *ngIf="_showNavigationButton"
    (click)="_triggerNavigationEvent(_selectedShift)"
  >
    <mat-icon>search</mat-icon>
  </button>

  <div class="navigator-element navigator-shift-counter info-text" *ngIf="_showShiftCounter">
    {{ _getNumberOfSelectedShift() }}/{{ _getTotalAvailableShiftCount() }}
  </div>

  <mat-icon
    class="navigator-close-button"
    matRipple
    [matRippleCentered]="true"
    [matRippleUnbounded]="true"
    (click)="_close('close')"
    >close</mat-icon
  >

  <mat-icon *ngIf="!_titleAsDragHandle" class="navigator-drag-handle" cdkDragHandle>open_with</mat-icon>
</div>
