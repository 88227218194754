/**
 * Static utility methods for date and time calculations.
 */
export abstract class GanttDateUtilities {
  /**
   * Converts the specified date value to a time string.
   * @param value Date value to generate the time string for.
   * @returns Time string.
   */
  public static getTimeString(date: Date | number): string {
    if (typeof date === 'number') date = new Date(date);

    const hours = date.getHours();
    const minutes = date.getMinutes();
    return `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}`;
  }

  /**
   * Converts the specified date value to a date string.
   * @param value Date value to generate the date string for.
   * @returns Date string.
   */
  public static getDateString(date: Date | number): string {
    if (typeof date === 'number') date = new Date(date);

    const day = date.getDate();
    const month = date.getMonth() + 1;
    return `${day < 10 ? '0' + day : day}.${month < 10 ? '0' + month : month}.${date.getFullYear()}`;
  }
}
