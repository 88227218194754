import * as d3 from 'd3';
import { Observable, Subject, filter, interval, take } from 'rxjs';
import { GanttConfig } from '../config/gantt-config';
import { EPatternEventSource } from './events/pattern-event-source.enum';
import { PatternImageEvent } from './events/pattern-image-event';
import { PatternType } from './pattern-type.enum';
import { PatternBuilder } from './patternBuilder';
import { CirclesPattern } from './patterns/custom/circlesPattern';
import { DiagonalStripesPattern } from './patterns/custom/diagonalStripesPattern';
import { DotsPattern } from './patterns/custom/dotsPattern';
import { HorizontalAndDiagonalStripesPattern } from './patterns/custom/horizontalAndDiagonalStripesPattern';
import { HorizontalStripesPattern } from './patterns/custom/horizontalStripesPattern';
import { VerticalStripesPattern } from './patterns/custom/verticalStripesPattern';
import { CarbonPattern } from './patterns/other/carbonPattern';
import { CrosshatchPattern } from './patterns/other/crosshatchPattern';
import { HeartsPattern } from './patterns/other/heartsPattern';
import { HoundstoothPattern } from './patterns/other/houndstoothPattern';
import { LightDiagonalStripesPattern } from './patterns/other/lightDiagonalStripesPattern';
import { LightVerticalStripesPattern } from './patterns/other/lightVerticalStripesPattern';
import { SmallDotsPattern } from './patterns/other/smallDotsPattern';
import { GanttStrokePattern } from './strokes/strokePattern';
import { GanttStrokePatternStorage } from './strokes/strokePatternStorage';

/**
 * Handler for patterns.
 * @keywords pattern, shift, colorize
 */
export class PatternHandler {
  defsNode: any;
  config: GanttConfig;
  patternBuilder: PatternBuilder;
  strokePatternStorage: GanttStrokePatternStorage;
  patternImages: GanttPatternImage[];

  private readonly _onImageComplete$ = new Subject<PatternImageEvent>();

  constructor(defsNode?: SVGDefsElement, ganttConfig?: GanttConfig) {
    this.defsNode = defsNode; // HTML node of a defs element
    this.config = ganttConfig;
    this.patternBuilder = new PatternBuilder(ganttConfig);
    this.strokePatternStorage = new GanttStrokePatternStorage();
    this.patternImages = []; // array with GanttPattern images (SVG)

    this._createStrokePatterns();
  }

  /**
   * Generates a pattern with the specified properties and returns it as base64-encoded URL.
   * @param patterntype Type of the pattern to be generated.
   * @param backgroundColor Background color of the pattern to be generated.
   * @param patternColor Color of the pattern to be generated.
   * @param interpolationOptions If specified, these options cause the colors of the pattern to be interpolated as if there was an overlay with the specified properties.
   * @returns Pattern as base64-encoded URL.
   */
  public getPatternAsUrl(
    patterntype: PatternType,
    backgroundColor: string,
    patternColor: string,
    interpolationOptions: { color: string; opacity: number } = undefined
  ): string {
    const colors = {
      pattern: patternColor,
      background: backgroundColor,
      stroke: this.patternBuilder.getStrokeColor(patternColor, backgroundColor),
    };

    if (interpolationOptions) {
      for (const key in colors) {
        const interpolation = d3.interpolate({ colors: [colors[key]] }, { colors: [interpolationOptions.color] });
        colors[key] = d3.color(interpolation(interpolationOptions.opacity).colors[0]).formatHex();
      }
    }
    const id = patterntype + colors.background + colors.pattern;
    const fillValue = 'url(#' + id + ')';

    this._checkForPatternImageString(
      id,
      patterntype,
      colors.background,
      colors.pattern,
      colors.stroke,
      EPatternEventSource.GET_PATTERN_AS_URL
    );

    return fillValue;
  }

  /**
   * Generates a pattern with the specified properties and returns it as {@link HTMLImageElement} with base64-encoded source URL.
   * @param patterntype Type of the pattern to be generated.
   * @param backgroundColor Background color of the pattern to be generated.
   * @param patternColor Color of the pattern to be generated.
   * @param interpolationOptions If specified, these options cause the colors of the pattern to be interpolated as if there was an overlay with the specified properties.
   * @returns Pattern as {@link HTMLImageElement} with base64-encoded source URL.
   */
  public getPatternAsSvgImage(
    patterntype: PatternType,
    backgroundColor: string,
    patternColor: string,
    interpolationOptions: { color: string; opacity: number } = undefined
  ): HTMLImageElement {
    const colors = {
      pattern: patternColor,
      background: backgroundColor,
      stroke: this.patternBuilder.getStrokeColor(patternColor, backgroundColor),
    };

    if (interpolationOptions) {
      for (const key in colors) {
        const interpolation = d3.interpolate({ colors: [colors[key]] }, { colors: [interpolationOptions.color] });
        colors[key] = d3.color(interpolation(interpolationOptions.opacity).colors[0]).formatHex();
      }
    }

    const id = patterntype + colors.background + colors.pattern;

    const patternImage = this._checkForPatternImageString(
      id,
      patterntype,
      colors.background,
      colors.pattern,
      colors.stroke,
      EPatternEventSource.GET_PATTERN_AS_SVG_IMAGE
    ).img;

    return patternImage;
  }

  /**
   * Returns a url string of a given pattern for css styling.
   * @param {string} patterntype the type of the pattern
   * @param {string} backgroundColor(optional) background color of the pattern
   * @param {string} patternColor(optional) color of the pattern
   * @returns {string} url string
   */
  getPatternAsCssUrl = async function (patterntype: PatternType, backgroundColor, patternColor) {
    return new Promise((resolve) => {
      const id = patterntype + backgroundColor + patternColor;
      const patternImage = this._checkForPatternImageString(id, patterntype, backgroundColor, patternColor).img;
      patternImage.onload = function () {
        const url = patternImage.src;
        resolve(`url("${url}")`);
      };
    });
  };

  /**
   * Creates a pattern image as SVG and pushes it to the patternImages array.
   * @param patternType The type of the pattern.
   * @param id Id of the pattern.
   * @param backgroundColor Background color of the pattern.
   * @param patternColor Color of the pattern.
   * @param strokeColor Stroke color of the pattern.
   * @returns
   */
  private _createPatternImageDataString(
    patternType: PatternType,
    id: string,
    backgroundColor: string,
    patternColor: string,
    strokeColor: string
  ): GanttPatternImage {
    switch (patternType) {
      // circles
      case 'CIRCLES_1':
        return new GanttPatternImage(
          id,
          new CirclesPattern(this.patternBuilder).getPatternData(backgroundColor, patternColor, strokeColor, 1, 1, 1)
        );
      case 'CIRCLES_2':
        return new GanttPatternImage(
          id,
          new CirclesPattern(this.patternBuilder).getPatternData(
            backgroundColor,
            patternColor,
            strokeColor,
            1.5,
            1.5,
            1.5
          )
        );
      case 'CIRCLES_3':
        return new GanttPatternImage(
          id,
          new CirclesPattern(this.patternBuilder).getPatternData(backgroundColor, patternColor, strokeColor, 2, 2, 2)
        );
      case 'CIRCLES_4':
        return new GanttPatternImage(
          id,
          new CirclesPattern(this.patternBuilder).getPatternData(
            backgroundColor,
            patternColor,
            strokeColor,
            2.5,
            2.5,
            2.5
          )
        );
      case 'CIRCLES_5':
        return new GanttPatternImage(
          id,
          new CirclesPattern(this.patternBuilder).getPatternData(backgroundColor, patternColor, strokeColor, 3, 3, 3)
        );
      case 'CIRCLES_6':
        return new GanttPatternImage(
          id,
          new CirclesPattern(this.patternBuilder).getPatternData(
            backgroundColor,
            patternColor,
            strokeColor,
            3.5,
            3.5,
            3.5
          )
        );
      case 'CIRCLES_7':
        return new GanttPatternImage(
          id,
          new CirclesPattern(this.patternBuilder).getPatternData(backgroundColor, patternColor, strokeColor, 4, 4, 4)
        );
      case 'CIRCLES_8':
        return new GanttPatternImage(
          id,
          new CirclesPattern(this.patternBuilder).getPatternData(
            backgroundColor,
            patternColor,
            strokeColor,
            4.5,
            4.5,
            4.5
          )
        );
      case 'CIRCLES_9':
        return new GanttPatternImage(
          id,
          new CirclesPattern(this.patternBuilder).getPatternData(backgroundColor, patternColor, strokeColor, 5, 5, 5)
        );
      // diagonal Stripes
      case 'DIAGONAL_STRIPE_1':
        return new GanttPatternImage(
          id,
          new DiagonalStripesPattern(this.patternBuilder).getPatternData(backgroundColor, patternColor, strokeColor, 1)
        );
      case 'DIAGONAL_STRIPE_2':
        return new GanttPatternImage(
          id,
          new DiagonalStripesPattern(this.patternBuilder).getPatternData(
            backgroundColor,
            patternColor,
            strokeColor,
            1.5
          )
        );
      case 'DIAGONAL_STRIPE_3':
        return new GanttPatternImage(
          id,
          new DiagonalStripesPattern(this.patternBuilder).getPatternData(backgroundColor, patternColor, strokeColor, 2)
        );
      case 'DIAGONAL_STRIPE_4':
        return new GanttPatternImage(
          id,
          new DiagonalStripesPattern(this.patternBuilder).getPatternData(
            backgroundColor,
            patternColor,
            strokeColor,
            2.5
          )
        );
      case 'DIAGONAL_STRIPE_5':
        return new GanttPatternImage(
          id,
          new DiagonalStripesPattern(this.patternBuilder).getPatternData(backgroundColor, patternColor, strokeColor, 3)
        );
      case 'DIAGONAL_STRIPE_6':
        return new GanttPatternImage(
          id,
          new DiagonalStripesPattern(this.patternBuilder).getPatternData(
            backgroundColor,
            patternColor,
            strokeColor,
            3.5
          )
        );
      case 'DIAGONAL_STRIPE_7':
        return new GanttPatternImage(
          id,
          new DiagonalStripesPattern(this.patternBuilder).getPatternData(
            backgroundColor,
            patternColor,
            strokeColor,
            4.5
          )
        );
      case 'DIAGONAL_STRIPE_8':
        return new GanttPatternImage(
          id,
          new DiagonalStripesPattern(this.patternBuilder).getPatternData(
            backgroundColor,
            patternColor,
            strokeColor,
            4.75
          )
        );
      case 'DIAGONAL_STRIPE_9':
        return new GanttPatternImage(
          id,
          new LightDiagonalStripesPattern(this.patternBuilder).getPatternData(
            backgroundColor,
            patternColor,
            strokeColor
          )
        );
      // dots
      case 'DOTS_1':
        return new GanttPatternImage(
          id,
          new DotsPattern(this.patternBuilder).getPatternData(backgroundColor, patternColor, strokeColor, 1)
        );
      case 'DOTS_2':
        return new GanttPatternImage(
          id,
          new DotsPattern(this.patternBuilder).getPatternData(backgroundColor, patternColor, strokeColor, 2)
        );
      case 'DOTS_3':
        return new GanttPatternImage(
          id,
          new DotsPattern(this.patternBuilder).getPatternData(backgroundColor, patternColor, strokeColor, 3)
        );
      case 'DOTS_4':
        return new GanttPatternImage(
          id,
          new DotsPattern(this.patternBuilder).getPatternData(backgroundColor, patternColor, strokeColor, 4)
        );
      case 'DOTS_5':
        return new GanttPatternImage(
          id,
          new DotsPattern(this.patternBuilder).getPatternData(backgroundColor, patternColor, strokeColor, 5)
        );
      case 'DOTS_6':
        return new GanttPatternImage(
          id,
          new DotsPattern(this.patternBuilder).getPatternData(backgroundColor, patternColor, strokeColor, 6)
        );
      case 'DOTS_7':
        return new GanttPatternImage(
          id,
          new DotsPattern(this.patternBuilder).getPatternData(backgroundColor, patternColor, strokeColor, 7)
        );
      case 'DOTS_8':
        return new GanttPatternImage(
          id,
          new DotsPattern(this.patternBuilder).getPatternData(backgroundColor, patternColor, strokeColor, 8)
        );
      case 'DOTS_9':
        return new GanttPatternImage(
          id,
          new DotsPattern(this.patternBuilder).getPatternData(backgroundColor, patternColor, strokeColor, 9)
        );
      case 'DOTS_10':
        return new GanttPatternImage(
          id,
          new SmallDotsPattern(this.patternBuilder).getPatternData(backgroundColor, patternColor, strokeColor)
        );
      // horizontal stripes
      case 'HORIZONTAL_STRIPE_1':
        return new GanttPatternImage(
          id,
          new HorizontalStripesPattern(this.patternBuilder).getPatternData(
            backgroundColor,
            patternColor,
            strokeColor,
            1
          )
        );
      case 'HORIZONTAL_STRIPE_2':
        return new GanttPatternImage(
          id,
          new HorizontalStripesPattern(this.patternBuilder).getPatternData(
            backgroundColor,
            patternColor,
            strokeColor,
            2
          )
        );
      case 'HORIZONTAL_STRIPE_3':
        return new GanttPatternImage(
          id,
          new HorizontalStripesPattern(this.patternBuilder).getPatternData(
            backgroundColor,
            patternColor,
            strokeColor,
            3
          )
        );
      case 'HORIZONTAL_STRIPE_4':
        return new GanttPatternImage(
          id,
          new HorizontalStripesPattern(this.patternBuilder).getPatternData(
            backgroundColor,
            patternColor,
            strokeColor,
            4
          )
        );
      case 'HORIZONTAL_STRIPE_5':
        return new GanttPatternImage(
          id,
          new HorizontalStripesPattern(this.patternBuilder).getPatternData(
            backgroundColor,
            patternColor,
            strokeColor,
            5
          )
        );
      case 'HORIZONTAL_STRIPE_6':
        return new GanttPatternImage(
          id,
          new HorizontalStripesPattern(this.patternBuilder).getPatternData(
            backgroundColor,
            patternColor,
            strokeColor,
            6
          )
        );
      case 'HORIZONTAL_STRIPE_7':
        return new GanttPatternImage(
          id,
          new HorizontalStripesPattern(this.patternBuilder).getPatternData(
            backgroundColor,
            patternColor,
            strokeColor,
            7
          )
        );
      case 'HORIZONTAL_STRIPE_8':
        return new GanttPatternImage(
          id,
          new HorizontalStripesPattern(this.patternBuilder).getPatternData(
            backgroundColor,
            patternColor,
            strokeColor,
            8
          )
        );
      case 'HORIZONTAL_STRIPE_9':
        return new GanttPatternImage(
          id,
          new HorizontalStripesPattern(this.patternBuilder).getPatternData(
            backgroundColor,
            patternColor,
            strokeColor,
            9
          )
        );
      // vertical stripes
      case 'VERTICAL_STRIPE_1':
        return new GanttPatternImage(
          id,
          new VerticalStripesPattern(this.patternBuilder).getPatternData(backgroundColor, patternColor, strokeColor, 1)
        );
      case 'VERTICAL_STRIPE_2':
        return new GanttPatternImage(
          id,
          new VerticalStripesPattern(this.patternBuilder).getPatternData(backgroundColor, patternColor, strokeColor, 2)
        );
      case 'VERTICAL_STRIPE_3':
        return new GanttPatternImage(
          id,
          new VerticalStripesPattern(this.patternBuilder).getPatternData(backgroundColor, patternColor, strokeColor, 3)
        );
      case 'VERTICAL_STRIPE_4':
        return new GanttPatternImage(
          id,
          new VerticalStripesPattern(this.patternBuilder).getPatternData(backgroundColor, patternColor, strokeColor, 4)
        );
      case 'VERTICAL_STRIPE_5':
        return new GanttPatternImage(
          id,
          new VerticalStripesPattern(this.patternBuilder).getPatternData(backgroundColor, patternColor, strokeColor, 5)
        );
      case 'VERTICAL_STRIPE_6':
        return new GanttPatternImage(
          id,
          new VerticalStripesPattern(this.patternBuilder).getPatternData(backgroundColor, patternColor, strokeColor, 6)
        );
      case 'VERTICAL_STRIPE_7':
        return new GanttPatternImage(
          id,
          new VerticalStripesPattern(this.patternBuilder).getPatternData(backgroundColor, patternColor, strokeColor, 7)
        );
      case 'VERTICAL_STRIPE_8':
        return new GanttPatternImage(
          id,
          new VerticalStripesPattern(this.patternBuilder).getPatternData(backgroundColor, patternColor, strokeColor, 8)
        );
      case 'VERTICAL_STRIPE_9':
        return new GanttPatternImage(
          id,
          new VerticalStripesPattern(this.patternBuilder).getPatternData(backgroundColor, patternColor, strokeColor, 9)
        );
      case 'VERTICAL_STRIPE_10':
        return new GanttPatternImage(
          id,
          new LightVerticalStripesPattern(this.patternBuilder).getPatternData(
            backgroundColor,
            patternColor,
            strokeColor
          )
        );
      // horizontal and diagonal stripes
      case 'HORIZONTAL_AND_DIAGONAL_STRIPE_1':
        return new GanttPatternImage(
          id,
          new HorizontalAndDiagonalStripesPattern(this.patternBuilder).getPatternData(
            backgroundColor,
            patternColor,
            strokeColor,
            1
          )
        );
      case 'HORIZONTAL_AND_DIAGONAL_STRIPE_2':
        return new GanttPatternImage(
          id,
          new HorizontalAndDiagonalStripesPattern(this.patternBuilder).getPatternData(
            backgroundColor,
            patternColor,
            strokeColor,
            2
          )
        );
      case 'HORIZONTAL_AND_DIAGONAL_STRIPE_3':
        return new GanttPatternImage(
          id,
          new HorizontalAndDiagonalStripesPattern(this.patternBuilder).getPatternData(
            backgroundColor,
            patternColor,
            strokeColor,
            3
          )
        );
      case 'HORIZONTAL_AND_DIAGONAL_STRIPE_4':
        return new GanttPatternImage(
          id,
          new HorizontalAndDiagonalStripesPattern(this.patternBuilder).getPatternData(
            backgroundColor,
            patternColor,
            strokeColor,
            4
          )
        );
      case 'HORIZONTAL_AND_DIAGONAL_STRIPE_5':
        return new GanttPatternImage(
          id,
          new HorizontalAndDiagonalStripesPattern(this.patternBuilder).getPatternData(
            backgroundColor,
            patternColor,
            strokeColor,
            5
          )
        );
      case 'HORIZONTAL_AND_DIAGONAL_STRIPE_6':
        return new GanttPatternImage(
          id,
          new HorizontalAndDiagonalStripesPattern(this.patternBuilder).getPatternData(
            backgroundColor,
            patternColor,
            strokeColor,
            6
          )
        );
      case 'HORIZONTAL_AND_DIAGONAL_STRIPE_7':
        return new GanttPatternImage(
          id,
          new HorizontalAndDiagonalStripesPattern(this.patternBuilder).getPatternData(
            backgroundColor,
            patternColor,
            strokeColor,
            7
          )
        );
      case 'HORIZONTAL_AND_DIAGONAL_STRIPE_8':
        return new GanttPatternImage(
          id,
          new HorizontalAndDiagonalStripesPattern(this.patternBuilder).getPatternData(
            backgroundColor,
            patternColor,
            strokeColor,
            8
          )
        );
      case 'HORIZONTAL_AND_DIAGONAL_STRIPE_9':
        return new GanttPatternImage(
          id,
          new HorizontalAndDiagonalStripesPattern(this.patternBuilder).getPatternData(
            backgroundColor,
            patternColor,
            strokeColor,
            9
          )
        );

      // other
      case 'CROSSHATCH':
        return new GanttPatternImage(
          id,
          new CrosshatchPattern(this.patternBuilder).getPatternData(backgroundColor, patternColor, strokeColor)
        );
      case 'HOUNDSTOOTH':
        return new GanttPatternImage(
          id,
          new HoundstoothPattern(this.patternBuilder).getPatternData(backgroundColor, patternColor, strokeColor)
        );
      case 'CARBON':
        return new GanttPatternImage(
          id,
          new CarbonPattern(this.patternBuilder).getPatternData(backgroundColor, patternColor, strokeColor)
        );
      case 'HEARTS':
        return new GanttPatternImage(
          id,
          new HeartsPattern(this.patternBuilder).getPatternData(backgroundColor, patternColor, strokeColor)
        );
      default:
        console.error('Pattern: Type ' + patternType + ' not found!');
        return null;
    }
  }

  /**
   * Generates an {@link HTMLImageElement} containing the specified pattern as SVG image.
   * @param patternDataString base64-encoded URL containing the pattern as SVG image.
   * @param patternId Id of the pattern to create the {@link HTMLImageElement} for (used for events).
   * @param source Value representing the calling method (used for events).
   * @returns {} {@link HTMLImageElement} containing the pattern as SVG image.
   */
  private _convertDataStringIntoImage(
    patternDataString: string,
    patternId: string,
    source: EPatternEventSource
  ): HTMLImageElement {
    const img = new Image();
    img.src = patternDataString;

    interval(10)
      .pipe(filter(() => img.complete))
      .pipe(take(1))
      .subscribe(() => this._onImageComplete$.next(new PatternImageEvent(patternId, source, img)));

    return img;
  }

  /**
   * Checks if pattern allready exist. If not the function creates a new pattern and push it into the patternImages array.
   * @param id Id of the pattern.
   * @param patterntype Type of the pattern.
   * @param backgroundColor Background color of the pattern.
   * @param patternColor Color of the pattern.
   * @param strokeColor Stroke color of the pattern.
   * @param source Value representing the calling method.
   * @returns A data string of svg pattern element.
   */
  private _checkForPatternImageString(
    id: string,
    patterntype: PatternType,
    backgroundColor: string,
    patternColor: string,
    strokeColor: string,
    source: EPatternEventSource
  ): GanttPatternImage {
    let patternImage = this.patternImages.find((x) => x.id === id);

    if (!patternImage) {
      if (!backgroundColor) backgroundColor = this.config ? this.config.getPatternBackgroundColor() : '#000000';
      if (!patternColor) patternColor = this.config ? this.config.getPatternColor() : '#616161';
      patternImage = this._createPatternImageDataString(patterntype, id, backgroundColor, patternColor, strokeColor);
      if (patternImage) {
        this.patternImages.push(patternImage);
        if (this.defsNode) {
          this._createDefsFromPatternData(id, patternImage.patternData);
        }
        patternImage.img = this._convertDataStringIntoImage(patternImage.patternData.patternDataString, id, source);
      }
    }
    if (patternImage) {
      return patternImage;
    } else {
      return null;
    }
  }

  /**
   * Creates a pattern for svg fill property.
   * @param id Id of the pattern.
   * @param patternData Dataset with base64-encoded pattern and pattern boundings.
   */
  private _createDefsFromPatternData(id: string, patternData: GanttPatternData): void {
    d3.select(this.defsNode)
      .append('pattern')
      .attr('id', id)
      .attr('patternUnits', 'userSpaceOnUse')
      .attr('width', patternData.patternWidth)
      .attr('height', patternData.patternHeight)
      .append('image')
      .attr('xlink:href', patternData.patternDataString)
      .attr('href', patternData.patternDataString)
      .attr('width', patternData.patternWidth)
      .attr('height', patternData.patternHeight)
      .attr('x', 0)
      .attr('y', 0);
  }

  /**
   * Creates initial predefined stroke patterns
   */
  private _createStrokePatterns() {
    this.getStrokePatternStorage().addPatternToStorage(new GanttStrokePattern('PATTERN_1', 5, 5));
    this.getStrokePatternStorage().addPatternToStorage(new GanttStrokePattern('PATTERN_2', 8, 8));
    this.getStrokePatternStorage().addPatternToStorage(new GanttStrokePattern('PATTERN_3', 10, 5));
    this.getStrokePatternStorage().addPatternToStorage(new GanttStrokePattern('PATTERN_4', 10, 10));
    this.getStrokePatternStorage().addPatternToStorage(new GanttStrokePattern('PATTERN_5', 5, 15));
  }

  //
  // GETTER & SETTER
  //

  public getPatternBuilder(): PatternBuilder {
    return this.patternBuilder;
  }

  public getStrokePatternStorage(): GanttStrokePatternStorage {
    return this.strokePatternStorage;
  }

  //
  // OBSERVABLES
  //

  /**
   * {@link Observable} which will emit a {@link PatternImageEvent} every time a newly created
   * {@link HTMLImageElement} finished loading its image content.
   */
  public get onImageComplete$(): Observable<PatternImageEvent> {
    return this._onImageComplete$.asObservable();
  }
}

/**
 * Structure of saved pattern image data in patternImages array
 * @param {string} id Id of the pattern element.
 * @param {GanttPatternData} patternData Object of pattern information.
 */
export class GanttPatternImage {
  id: string;
  patternData: GanttPatternData;
  img: HTMLImageElement;

  constructor(id, patternData) {
    this.id = id;
    this.patternData = patternData;
    this.img = null;
  }
}

/**
 * Structure of pattern information.
 * @param {string} patternDataString Data image string.
 * @param {number} patternHeight Height of pattern.
 * @param {number} patternWidth Width of pattern.
 */
export class GanttPatternData {
  patternDataString: string;
  patternWidth: number;
  patternHeight: number;

  constructor(patternDataString, patternHeight, patternWidth) {
    this.patternDataString = patternDataString;
    this.patternWidth = patternWidth;
    this.patternHeight = patternHeight;
  }
}
