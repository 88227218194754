import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SaxMsSubmenuElementItem } from './saxms-submenu-element-item';

export interface SaxMsServiceObject {
  elementId: string;
  disabled: boolean;
  data?: any;
}

@Injectable()
export class SaxMsSubmenuFavoriteTabService {
  private favoriteDataChange: BehaviorSubject<SaxMsSubmenuElementItem[]>;
  private disableContextmenu: BehaviorSubject<boolean>;
  private changeContextmenuToDelete: BehaviorSubject<boolean>;
  private favoriteSubmenuElements: SaxMsSubmenuElementItem[] = [];
  constructor() {
    this.favoriteDataChange = new BehaviorSubject(null);
    this.disableContextmenu = new BehaviorSubject(false);
    this.changeContextmenuToDelete = new BehaviorSubject(false);
  }

  public getFabMenuelements(): SaxMsSubmenuElementItem[] {
    return this.favoriteSubmenuElements;
  }

  public addElementToFavoriteTab(element: SaxMsSubmenuElementItem): void {
    this.favoriteSubmenuElements.push(element);
    this.favoriteDataChange.next(this.favoriteSubmenuElements);
  }

  public removeElementToFavoriteTab(element: SaxMsSubmenuElementItem): void {
    this.favoriteSubmenuElements = this.favoriteSubmenuElements.filter((tabElement) => tabElement !== element);

    this.favoriteDataChange.next(this.favoriteSubmenuElements);
  }
  public resetElementOfFavoriteTab(elements: SaxMsSubmenuElementItem[]): void {
    this.favoriteSubmenuElements = elements.slice();

    this.favoriteDataChange.next(elements);
  }
  public onFavoriteSubmenuElementsChange(): Observable<SaxMsSubmenuElementItem[]> {
    return this.favoriteDataChange.asObservable();
  }
  public disableContextMenuChange(): Observable<boolean> {
    return this.disableContextmenu.asObservable();
  }
  public disableContextMenu(disabled: boolean): void {
    this.disableContextmenu.next(disabled);
  }
  public activeDeleteContextMenuChange(): Observable<boolean> {
    return this.changeContextmenuToDelete.asObservable();
  }
  public activeDeleteContextMenu(active: boolean): void {
    this.changeContextmenuToDelete.next(active);
  }
}
