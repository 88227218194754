import { AttributeFilter } from '@app-nodenetwork/data/attribute-filter';

/**
 * Datastructure to describe a sublevel node network if user has "gone deeper" into node.
 */
export class ViewOptions {
  // Array of graph-ids
  currentViews: string[] = [];

  // Array of subgraph-node-ids
  sourceNodes: SourceNode[] = [];

  // Filter entries for attributes
  attributeFilter: AttributeFilter = new AttributeFilter();
}

/**
 * Description of node which in which the user has gone deeper.
 */
export class SourceNode {
  public constructor(private graphId: string, private nodeId?: string) {}

  public getNodeId(): string {
    return this.nodeId;
  }

  public setNodeId(nodeId: string) {
    this.nodeId = nodeId;
  }

  public getGraphId(): string {
    return this.graphId;
  }

  public setGraphId(graphId: string) {
    this.graphId = graphId;
  }
}
