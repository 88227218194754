import { ColumnListSelector } from '@app-modeleditor/components/column-list-selector/column-list-selector';
import { ColumnListSelectorElement } from '@app-modeleditor/components/column-list-selector/column-list-selector-element';
import { ColumnListSelectorValue } from '@app-modeleditor/components/column-list-selector/column-list-selector-value';
import { Content } from '@app-modeleditor/components/content/content';
import { ContentPart } from '@app-modeleditor/components/content/content-part/content-part';
import { IGanttAttributeData, IGanttVisibleAttributes } from '../../generator/gantt-input.data';
import { Lightbox } from './../../../../modeleditor/components/lightbox/lightbox';

export class EditTooltipLightbox extends Lightbox {
  private selector: ColumnListSelector;
  private attributeData: IGanttAttributeData[];
  private visibleAttributes: IGanttVisibleAttributes;

  constructor() {
    super();
  }

  public getColumnListSelector(): ColumnListSelector {
    return this.selector;
  }

  private getAttributesForListSelector(isVisible: boolean): ColumnListSelectorElement[] {
    const attributesInTooltip = this.attributeData.filter((attribute) =>
      isVisible
        ? this.visibleAttributes.attributeMetadata.find((elem) => elem.attributeID === attribute.id)
        : !this.visibleAttributes.attributeMetadata.find((elem) => elem.attributeID === attribute.id)
    );
    return attributesInTooltip.map((attribute, index) => {
      let i = index;

      // set existing index if visible
      if (isVisible) {
        i = this.visibleAttributes.attributeMetadata.find((elem) => elem.attributeID === attribute.id)?.index;
      }
      return new ColumnListSelectorElement()
        .setSimpleElement(true)
        .setFloatLeft(attribute.localization)
        .setId(attribute.id + '')
        .setIndex(i);
    });
  }

  get(attributeData: IGanttAttributeData[], visibleAttributes: IGanttVisibleAttributes): this {
    this.attributeData = attributeData;
    this.visibleAttributes = visibleAttributes;

    this.setName('@edit-tooltip@');
    this.selector = new ColumnListSelector().setName('@customize-tooltip@');
    this.setCanOpenExternalWindow(false);

    this.selector.setValue(
      new ColumnListSelectorValue()
        .setSelectedListEntryElements(
          this.getAttributesForListSelector(true).sort((a, b) => a.getIndex() - b.getIndex())
        )
        .setAvailableListEntryElements(
          this.getAttributesForListSelector(false).sort((a, b) => a.getFloatLeft().localeCompare(b.getFloatLeft()))
        )
    );
    this.setContent(
      new Content().setContentParts([
        new ContentPart().setDisplayContentpartContainer(false).setContentElements([this.selector]),
      ])
    );
    return this;
  }
}
