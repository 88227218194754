<div
  style="display: flex; flex-direction: column; position: sticky; top: 0; z-index: 100"
  [style.height]="c.isCompact() ? '100%' : 'auto'"
>
  <div #upper class="sticky-content mat-elevation-z8" *ngIf="sticky?.length > 0">
    <ng-container *ngFor="let part of sticky">
      <ng-container *ngTemplateOutlet="element; context: { $implicit: part, contentId: content.id }"> </ng-container>
    </ng-container>
  </div>
  <div style="height: 100%; display: flex; flex-direction: column" class="elem-root" #elemRoot *ngIf="c.isCompact()">
    <ng-container *ngTemplateOutlet="cpSwitcher; context: { myRoot: elemRoot }"></ng-container>
  </div>
</div>

<ng-container *ngIf="!c.isCompact()">
  <div class="lower" #lower [style.height]="c.getResizeMode() === 'FIT_PARENT' ? '100%' : 'bla'" style="display: block">
    <ng-container *ngIf="c && c.getContentHeader()">
      <template-slot *ngIf="c.getContentHeader()" (afterInit)="afterHeaderSlotInit($event, c.getContentHeader())">
      </template-slot>
    </ng-container>

    <ng-container *ngTemplateOutlet="cpSwitcher"></ng-container>
  </div>
</ng-container>

<ng-template #element let-element let-ref="ref" let-root="root">
  <template-slot
    [source]="element"
    *ngIf="element; else loadingTemplate"
    (afterInit)="afterTemplateSlotInit($event, root)"
  ></template-slot>
  <ng-template #loadingTemplate>
    <div [style.text-align]="'center'">{{ 'loadElement' | translate }} {{ element.getName() }} ...</div>
    <mat-progress-bar mode="buffer"></mat-progress-bar>
  </ng-template>
</ng-template>

<ng-template #cpSwitcher let-myRoot="myRoot">
  <!-- with layoutcollection -->
  <ng-container *ngIf="content.layoutCollection">
    <div *ngFor="let layout of content.layoutCollection.layout">
      <div class="column" *ngFor="let col of layout.content | keyvalue" [style.width]="100 / layout.columnCount + '%'">
        <ng-container *ngFor="let id of col.value">
          <ng-container *ngFor="let part of nonSticky">
            <ng-container *ngIf="part.getId() === id">
              <ng-container
                *ngTemplateOutlet="
                  element;
                  context: {
                    $implicit: part,
                    parent: content.id,
                    root: myRoot || root
                  }
                "
              >
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </ng-container>

  <!-- without layout -->
  <ng-container *ngIf="!content.layout && !content.layoutCollection">
    <ng-container *ngFor="let part of nonSticky">
      <div class="inner-root" [class.compact]="c.isCompact()" #innerRoot>
        <ng-container
          *ngTemplateOutlet="
            element;
            context: {
              $implicit: part,
              contentId: content.id,
              root: c.isCompact() ? innerRoot : myRoot || root
            }
          "
        >
        </ng-container>
      </div>
    </ng-container>
  </ng-container>

  <!-- new concept for layout driven contents-->
  <ng-container *ngIf="c.getLayout()">
    <core-layout class="core-layout" [layout]="c.getLayout()" [templates]="nonSticky" #elementRef>
      <ng-template #contentElement let-template let-numElements="numElements" let-ref="ref" let-root="root">
        <ng-container
          *ngTemplateOutlet="
            element;
            context: {
              $implicit: template,
              ref: ref,
              contentId: c.getId(),
              root: root
            }
          "
        >
        </ng-container>
      </ng-template>
    </core-layout>
  </ng-container>
</ng-template>
