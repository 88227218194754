<div [ngClass]="styleRef" class="confirm-dialog">
  <h2 class="dialog-title" mat-dialog-title>{{ title | translate | capitalize }}</h2>
  <mat-dialog-content>
    <div *ngIf="hasLabel">
      <mat-form-field>
        <input type="text" [placeholder]="'FORMS.ITEMS.LABEL' | translate" matInput [(ngModel)]="label" />
      </mat-form-field>
    </div>
    <div>{{ text | capitalize }}</div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button *ngIf="closeButton" [id]="'saxms-dialog-cancel'" mat-button (click)="close()">
      {{ 'close' | translate | capitalize }}
    </button>
    <button *ngIf="confirmButton" [id]="'saxms-dialog-confirm'" color="primary" mat-button (click)="confirm()">
      {{ 'confirm' | translate | capitalize }}
    </button>
  </mat-dialog-actions>
</div>
