import * as d3 from 'd3';
import { GanttShiftAdding } from '../../add-shift-elements/add-shifts';
import { GanttCallBackStackExecuter } from '../../callback-tools/callback-stack-executer';
import { GanttConfig } from '../../config/gantt-config';
import { ShiftDataFinder } from '../../data-handler/data-finder/shift-data-finder';
import { GanttCanvasShift, GanttDataShift } from '../../data-handler/data-structure/data-structure';
import { BestGanttPlugIn } from '../gantt-plug-in';
import { GanttShiftMoverEvent } from './undo-redo/shift-mover-event';

/**
 * @param {boolean} [eventLogging=true] wether component should log events or not
 * @keywords plugin, executer, shift, move, translate, change, position,
 * @plugin shift-mover
 * @class
 * @constructor
 * @extends BestGanttPlugIn
 * @requires BestGanttPlugIn
 * @requires GanttShiftMoverEvent
 *
 * @deprecated This plug-in is no longer used (2023-11-29).
 */
export class GanttShiftMover extends BestGanttPlugIn {
  shiftDataSet: any[];
  parentNode: any;
  config: GanttConfig;
  animation: boolean;
  moveAnimationDuration: number;
  callBack: any;
  xScale: d3.ScaleTime<number, number>;
  rowDataset: any[];
  tempShift: d3.Selection<any, any, null, undefined>;

  constructor() {
    super(); // call super-constructor

    /**
     * @type {BestGantt}
     */
    this.ganttDiagram = null;
    this.shiftDataSet = null;
    this.parentNode = null;
    this.config = null;

    this.animation = true;
    this.moveAnimationDuration = 1000;

    this.callBack = {
      afterMoveAnimation: {},
    };
  }

  initPlugIn(ganttDiagram) {
    const s = this;

    s.ganttDiagram = ganttDiagram;

    s.config = s.ganttDiagram.getConfig();

    s.parentNode = s.ganttDiagram.getShiftFacade().getShiftBuilder().getCanvasInFrontShifts();

    s.xScale = s.ganttDiagram.xAxisBuilder.getGlobalScale();

    s.rowDataset = s.ganttDiagram.getDataHandler().getYAxisDataset();
  }

  /**
   * main-function
   * handles all possible szenarios of moving visible Shifts
   * @param {String} shiftID id of shift that should be moved
   * @param {String} newRowID id of row that is target of the shift
   * @param {Date} [timePointStart] timepoint of target
   * @param {Date} [timePointEnd] timepointend of target
   */
  moveVisibleShift(shiftID, newRowID, timePointStart, timePointEnd) {
    const s = this;

    let targetPosition = [];
    const originShift = ShiftDataFinder.getShiftById(
      s.ganttDiagram.getDataHandler().getOriginDataset().ganttEntries,
      shiftID
    ).shift;
    const originRow = ShiftDataFinder.getShiftById(
      s.ganttDiagram.getDataHandler().getOriginDataset().ganttEntries,
      shiftID
    ).shiftRow;
    const transformInformation = this.ganttDiagram.getShiftFacade().getShiftBuilder().getLastZoomTransformation();
    s.shiftDataSet = s.ganttDiagram.getDataHandler().getCanvasShiftDataset();

    if (!timePointStart && !timePointEnd) {
      timePointStart = new Date(originShift.timePointStart);
      timePointEnd = new Date(originShift.timePointEnd);
    } else {
      timePointStart = s._parseTimePointStart(timePointStart, timePointEnd, originShift);
      timePointEnd = s._parseTimePointEnd(timePointStart, timePointEnd, originShift);
    }

    s.ganttDiagram
      .getHistory()
      .addNewEvent(
        'moveShift',
        new GanttShiftMoverEvent(),
        this,
        JSON.parse(JSON.stringify(originShift)) /* , originRow.id, newRowID, timePointStart, timePointEnd*/
      );

    // animation
    if (this.animation) {
      for (let k = 0; k < s.shiftDataSet.length; k++) {
        if (s.shiftDataSet[k].id == shiftID) {
          // if shift to move is visible

          const canvasShift = s.shiftDataSet[k]; // canvas-shiftData
          var tempShift = s._createTempShift(canvasShift);
          targetPosition = s._getTargetCoordinatesOfShiftMove(
            newRowID,
            s.rowDataset,
            timePointStart,
            transformInformation
          );

          if (!s._isRowVisible(newRowID, s.rowDataset)) {
            // if target-row is not visible
            s.shiftDataSet.splice(k, 1); // remove canvasShift from shiftDataSet
            s.ganttDiagram.rerenderShiftsVertical();
            targetPosition = s._getCoordinatesByUnvisibleRow(newRowID, timePointStart, timePointEnd, s.rowDataset);
            s._tempShiftMoveAnimationWithUnvisibleTarget(
              tempShift,
              targetPosition,
              shiftID,
              newRowID,
              timePointStart,
              timePointEnd
            );
            return;
          }

          // shiftAnimation if target-row is visible
          s.shiftDataSet.splice(k, 1); // remove canvasShift from shiftDataSet
          s.ganttDiagram.rerenderShiftsVertical();
          s._tempShiftMoveAnimation(tempShift, targetPosition, shiftID, newRowID, timePointStart, timePointEnd);

          return;
        }
      }

      if (s._isRowVisible(newRowID, s.rowDataset)) {
        // targetRow is visible

        var startingPosition = s._getCoordinatesByUnvisibleRow(
          originRow.id,
          originShift.timePointStart,
          originShift.timePointEnd,
          s.rowDataset
        );
        var tempShift = s._createSmallTempShift(startingPosition, originShift);
        targetPosition = s._getTargetCoordinatesOfShiftMove(
          newRowID,
          s.rowDataset,
          timePointStart,
          transformInformation
        );
        s._tempShiftMoveAnimation(tempShift, targetPosition, shiftID, newRowID, timePointStart, timePointEnd);
      } else {
        // startingRow and targetRow are not visible
        var startingPosition = s._getCoordinatesByUnvisibleRow(
          originRow.id,
          originShift.timePointStart,
          originShift.timePointEnd,
          s.rowDataset
        );
        var tempShift = s._createSmallTempShift(startingPosition, originShift);
        targetPosition = s._getCoordinatesByUnvisibleRow(newRowID, timePointStart, timePointEnd, s.rowDataset);
        s._tempShiftMoveAnimationWithUnvisibleStartAndTarget(
          tempShift,
          startingPosition,
          targetPosition,
          shiftID,
          newRowID,
          timePointStart,
          timePointEnd
        );
      }
    } else {
      // without animation
      s.changeDataSet(shiftID, newRowID, timePointStart, timePointEnd);
      s.ganttDiagram.rerenderShiftsVertical();
    }
  }

  /**
   *
   * @private
   * @param {String} rowID row where shift will get moved to.
   * @param {any} rowData the row data.
   * @param {Date} timePointStart beginning of shift at new location.
   * @param {Date} transformInformation ending of shift at new location.
   * @returns {number[]} [x,y] coordinates.
   */
  private _getTargetCoordinatesOfShiftMove(rowID, rowData, timePointStart, transformInformation) {
    const s = this;
    let yCoordinate, xCoordinate;

    for (let i = 0; i < rowData.length; i++) {
      if (rowID == rowData[i].id) {
        yCoordinate = rowData[i].y + s.config.getLineTop();
        break;
      }
    }
    xCoordinate = s.xScale(timePointStart) * transformInformation.k + transformInformation.x;

    return [xCoordinate, yCoordinate];
  }

  /**
   * parses the timePoint End
   * @private
   * @param {Date} timePointStart
   * @param {Date} timePointEnd
   * @param {GanttDataShift} shiftData
   * @returns {Date}
   */
  private _parseTimePointEnd(timePointStart, timePointEnd, shiftData: GanttDataShift) {
    if (!timePointEnd) {
      // if timePointEnd is not defined
      const timeDiff = new Date(shiftData.timePointEnd).getTime() - new Date(shiftData.timePointStart).getTime();
      const startTimeInMs = timePointStart.getTime();
      const newTimePointEnd = new Date();
      newTimePointEnd.setTime(startTimeInMs + timeDiff);

      return newTimePointEnd;
    } else {
      return timePointEnd;
    }
  }

  /**
   * parses the timePointStart
   * @private
   * @param {Date} timePointStart
   * @param {Date} timePointEnd
   * @param {GanttDataShift} shiftData
   * @returns {Date}
   */
  private _parseTimePointStart(timePointStart, timePointEnd, shiftData: GanttDataShift) {
    if (!timePointStart) {
      // if timePointStart is not defined
      const timeDiff = new Date(shiftData.timePointEnd).getTime() - new Date(shiftData.timePointStart).getTime();
      const endTimeInMs = timePointEnd.getTime();
      const newTimePointStart = new Date();
      newTimePointStart.setTime(endTimeInMs - timeDiff);

      return newTimePointStart;
    } else {
      return timePointStart;
    }
  }

  /**
   * Checks wether a row is visible or not.
   * @private
   * @param {String} rowID The row ID to get checked for visibility.
   * @param {any} rowDataSet The rowDataSet.
   * @returns {boolean} true if row is visible, else false.
   */
  private _isRowVisible(rowID, rowDataSet) {
    const visible = rowDataSet.filter(function (rowData) {
      if (rowData.id == rowID) {
        return true;
      }
    });

    if (visible.length > 0) {
      return true;
    } else return false;
  }

  /**
   *
   *
   * @param {GanttCanvasShift} canvasShift
   * @returns {*} Reference to a temporary rect resembling a shift for animation.
   */
  private _createTempShift(canvasShift: GanttCanvasShift) {
    const s = this;
    const transformInformation = this.ganttDiagram.getShiftFacade().getShiftBuilder().getLastZoomTransformation();

    s.tempShift = s.parentNode
      .append('rect')
      .attr('x', canvasShift.x * transformInformation.k + transformInformation.x)
      .attr('y', canvasShift.y)
      .attr('rx', function () {
        return s.config.getRoundedCorners();
      })
      .attr('ry', function () {
        return s.config.getRoundedCorners();
      })
      .attr('height', canvasShift.height)
      .attr('width', canvasShift.width * transformInformation.k)
      .style('fill', function () {
        if (canvasShift.selected) {
          return s.config.colorSelect();
        }
        return canvasShift.color;
      });
    return s.tempShift;
  }

  private _createSmallTempShift(position, originShift) {
    const s = this;
    const transformInformation = this.ganttDiagram.getShiftFacade().getShiftBuilder().getLastZoomTransformation();
    const shiftCanvasData = ShiftDataFinder.getCanvasShiftById(
      s.ganttDiagram.getDataHandler().getCanvasShiftDataset(),
      originShift.id
    );

    s.tempShift = s.parentNode
      .append('rect')
      .attr('x', position[0])
      .attr('y', position[1] + shiftCanvasData.height / 2)
      .attr('rx', function () {
        return s.config.getRoundedCorners();
      })
      .attr('ry', function () {
        return s.config.getRoundedCorners();
      })
      .attr('height', 0.01)
      .attr('width', 0.01)
      .style('fill', originShift.color);
    return s.tempShift;
  }

  private _tempShiftMoveAnimation(tempShift, newPosition, shiftID, newRowID, timePointStart, timePointEnd) {
    const s = this;
    const shiftCanvasData = ShiftDataFinder.getCanvasShiftById(
      s.ganttDiagram.getDataHandler().getCanvasShiftDataset(),
      shiftID
    );
    const transformInformation = this.ganttDiagram.getShiftFacade().getShiftBuilder().getLastZoomTransformation();

    tempShift
      .transition()
      .ease(d3.easeSin)
      .duration(s.moveAnimationDuration)
      .attr('x', newPosition[0])
      .attr('y', newPosition[1])
      .attr('height', shiftCanvasData.height)
      .attr('width', function () {
        return (s.xScale(timePointEnd) - s.xScale(timePointStart)) * transformInformation.k;
      })
      .on('end', function () {
        s.changeDataSet(shiftID, newRowID, timePointStart, timePointEnd);
        this.remove(); // remove tempShift
        s.ganttDiagram.rerenderShiftsVertical();
        GanttCallBackStackExecuter.execute(s.callBack.afterMoveAnimation);
      });
  }

  private _tempShiftMoveAnimationWithUnvisibleTarget(
    tempShift,
    newPosition,
    shiftID,
    newRowID,
    timePointStart,
    timePointEnd
  ) {
    const s = this;
    let width;
    const height = tempShift.attr('height');

    tempShift
      .transition()
      .ease(d3.easeSin)
      .duration(s.moveAnimationDuration)
      .attr('x', newPosition[0])
      .attr('y', newPosition[1])
      .attr('width', function () {
        const transformInformation = s.ganttDiagram.getShiftFacade().getShiftBuilder().getLastZoomTransformation();
        width = (s.xScale(timePointEnd) - s.xScale(timePointStart)) * transformInformation.k;
        return width;
      })

      .on('end', function () {
        tempShift
          .transition()
          .ease(d3.easeSin)
          .duration(500)
          .attr('x', newPosition[0] + width / 2)
          .attr('y', newPosition[1] + height / 2)
          .attr('width', 0.01)
          .attr('height', 0.01)
          // .style("opacity", 0)
          .on('end', function () {
            s.changeDataSet(shiftID, newRowID, timePointStart, timePointEnd);
            this.remove(); // remove tempShift
            GanttCallBackStackExecuter.execute(s.callBack.afterMoveAnimation);
          });
      });
  }

  private _tempShiftMoveAnimationWithUnvisibleStartAndTarget(
    tempShift,
    startPosition,
    endPosition,
    shiftID,
    newRowID,
    timePointStart,
    timePointEnd
  ) {
    const s = this;
    let width;
    const shiftCanvasData = ShiftDataFinder.getCanvasShiftById(
      s.ganttDiagram.getDataHandler().getCanvasShiftDataset(),
      shiftID
    );

    tempShift
      .transition()
      .ease(d3.easeSin)
      .duration(s.moveAnimationDuration / 2)
      .attr('x', (endPosition[0] - startPosition[0]) / 2 + startPosition[0])
      .attr('y', (endPosition[1] - startPosition[1]) / 2 + startPosition[1])
      .attr('height', shiftCanvasData.height)
      .attr('width', function () {
        const transformInformation = s.ganttDiagram.getShiftFacade().getShiftBuilder().getLastZoomTransformation();
        width = (s.xScale(timePointEnd) - s.xScale(timePointStart)) * transformInformation.k;
        return width;
      })

      .on('end', function () {
        tempShift
          .transition()
          .ease(d3.easeSin)
          .duration(500)
          .attr('x', endPosition[0] + width / 2)
          .attr('y', endPosition[1] + shiftCanvasData.height / 2)
          .attr('width', 0.01)
          .attr('height', 0.01)
          // .style("opacity", 0)
          .on('end', function () {
            s.changeDataSet(shiftID, newRowID, timePointStart, timePointEnd);
            this.remove(); // remove tempShift
            GanttCallBackStackExecuter.execute(s.callBack.afterMoveAnimation);
          });
      });
  }

  changeDataSet(shiftID, newRowID, timePointStart, timePointEnd) {
    const s = this;

    // validate date
    timePointStart = new Date(timePointStart);

    const shift = JSON.parse(
      JSON.stringify(
        ShiftDataFinder.getShiftById(s.ganttDiagram.getDataHandler().getOriginDataset().ganttEntries, shiftID).shift
      )
    ); // create coppy of shift
    ShiftDataFinder.removeShiftById(s.ganttDiagram.getDataHandler().getOriginDataset().ganttEntries, shiftID); // remove old shift

    // update new shift
    shift.timePointStart = timePointStart;
    shift.timePointEnd = timePointEnd;

    GanttShiftAdding.addShiftByRowID(timePointStart, timePointEnd, newRowID, shift, s.ganttDiagram); // add modified shift
  }

  private _getCoordinatesByUnvisibleRow(rowID, timePointStart, timePointEnd, rowData) {
    const s = this;
    const parentRowID = s._findLastVisibleRow(rowID, s.ganttDiagram.getDataHandler().getOriginDataset().ganttEntries);
    let yCoordinate = null;

    for (let i = 0; i < rowData.length; i++) {
      if (parentRowID == rowData[i].id) {
        yCoordinate = rowData[i].y + s.config.getLineTop();
        break;
      }
    }

    const transformInformation = s.ganttDiagram.getShiftFacade().getShiftBuilder().getLastZoomTransformation();

    let scaleX = s.xScale(new Date(timePointStart)) * transformInformation.k + transformInformation.x;

    if (scaleX < 0) {
      // if x-coordinate outside of canvas (left)
      scaleX = 0;
    }
    if (scaleX > s.parentNode.attr('width')) {
      // if x-coordinate outside of canvas (right)
      scaleX =
        s.parentNode.attr('width') -
        (s.xScale(new Date(timePointEnd)) - s.xScale(new Date(timePointStart))) * transformInformation.k;
    }
    return [scaleX, yCoordinate];
  }

  private _findLastVisibleRow(rowID, dataSet) {
    let pathContainer = [];
    let found = false;

    const findOpenRow = function (parent, array) {
      array.push(parent);
      for (let i = 0; i < parent.child.length; i++) {
        if (parent.child[i].id == rowID) {
          const row = parent;
          pathContainer = array.slice();
          found = true;
          return;
        } else {
          if (parent.child[i].child.length > 0) {
            findOpenRow(parent.child[i], array);
          }
        }
      }
    };

    for (var i = 0; i < dataSet.length; i++) {
      if (!found) {
        findOpenRow(dataSet[i], []);
      }
    }

    for (var i = 0; i < pathContainer.length; i++) {
      if (!pathContainer[i].open) {
        return pathContainer[i].id;
      }
    }
  }

  //
  // GETTER & SETTER
  //
  setAnimation(bool) {
    if (bool) {
      this.animation = true;
    } else {
      this.animation = false;
    }
  }

  setAnimationDuration(duration) {
    this.moveAnimationDuration = duration;
  }

  //
  // CALLBACKS
  //
  afterMoveAnimation(id, func) {
    const s = this;
    s.callBack.afterMoveAnimation[id] = func;
  }
}
