import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { GlobalUtils } from 'frontend/src/dashboard/global-utils';

export class PasswordUtils {
  public static testRegex(nameRe: RegExp, name: string, active = true): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!active) {
        return null;
      }

      const match = nameRe.test(control.value);
      return match ? null : { [name]: { value: control.value } };
    };
  }

  public static getPasswordConfig(passwordConfig: Record<string, any>): Record<string, any> {
    const conf: Record<string, any> = {};
    conf.minPasswordLength = passwordConfig?.minLength || 8;
    conf.maxPasswordLength = passwordConfig?.maxLength || 25;
    conf.lowercase = typeof passwordConfig?.lowercase === 'boolean' ? passwordConfig?.lowercase : true;
    conf.uppercase = typeof passwordConfig?.uppercase === 'boolean' ? passwordConfig?.uppercase : true;
    conf.specialCharacters = passwordConfig?.specialCharacters;
    conf.regex = (passwordConfig?.specialCharacters || []).map((item) => `\\${item}`).join('');

    conf.decimal = typeof passwordConfig?.decimal === 'boolean' ? passwordConfig?.decimal : true;
    return conf;
  }

  /**
   * generates a password defined by config
   * @param {Record<string, any>} config configuration for the password strength
   * @returns string
   */
  public static generate(config: Record<string, any>): string | undefined {
    if (!config) {
      return undefined;
    }

    const numbers: string[] = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    const lowerCase: string[] = [
      'a',
      'b',
      'c',
      'd',
      'e',
      'f',
      'g',
      'h',
      'i',
      'j',
      'k',
      'l',
      'm',
      'n',
      'o',
      'p',
      'q',
      'r',
      's',
      't',
      'u',
      'v',
      'w',
      'x',
      'y',
      'z',
    ];
    const upperCase: string[] = [
      'A',
      'B',
      'C',
      'D',
      'E',
      'F',
      'G',
      'H',
      'I',
      'J',
      'K',
      'L',
      'M',
      'M',
      'O',
      'P',
      'Q',
      'R',
      'S',
      'T',
      'U',
      'V',
      'W',
      'X',
      'Y',
      'Z',
    ];

    const characters: string[] = []
      .concat(config.specialCharacters ? config.specialCharacters : [])
      .concat(config.decimal ? numbers : [])
      .concat(config.lowercase ? lowerCase : [])
      .concat(config.uppercase ? upperCase : []);

    function g() {
      let pw = '';
      const pwLength: number = GlobalUtils.randomIntBetween(config.minPasswordLength, config.maxPasswordLength);
      for (let i = 0; i < pwLength; i++) {
        pw += characters[GlobalUtils.randomIntBetween(0, characters.length - 1)];
      }

      const valid: boolean[] = [];
      if (config.lowercase) {
        valid.push(Array.from(pw).find((c: string) => c.charCodeAt(0) >= 97 && c.charCodeAt(0) <= 172) ? true : false);
      }
      if (config.uppercase) {
        valid.push(Array.from(pw).find((c: string) => c.charCodeAt(0) >= 65 && c.charCodeAt(0) <= 90) ? true : false);
      }
      if (config.specialCharacters) {
        valid.push(Array.from(pw).find((c: string) => config.specialCharacters.includes(c)) ? true : false);
      }
      if (config.decimal) {
        valid.push(Array.from(pw).find((c: string) => c.charCodeAt(0) >= 48 && c.charCodeAt(0) <= 57) ? true : false);
      }

      if (valid.includes(false)) {
        return g();
      } else {
        return pw;
      }
    }

    return g();
  }

  public static validate(password: string, config: Record<string, any>): boolean {
    const valid: boolean[] = [];
    if (password.length < config.minPasswordLength || password.length > config.maxPasswordLength) {
      valid.push(false);
    }
    if (config.lowercase) {
      valid.push(
        Array.from(password).find((c: string) => c.charCodeAt(0) >= 97 && c.charCodeAt(0) <= 172) ? true : false
      );
    }
    if (config.uppercase) {
      valid.push(
        Array.from(password).find((c: string) => c.charCodeAt(0) >= 65 && c.charCodeAt(0) <= 90) ? true : false
      );
    }
    if (config.specialCharacters) {
      valid.push(Array.from(password).find((c: string) => config.specialCharacters.includes(c)) ? true : false);
    }
    if (config.decimal) {
      valid.push(
        Array.from(password).find((c: string) => c.charCodeAt(0) >= 48 && c.charCodeAt(0) <= 57) ? true : false
      );
    }

    return valid.includes(false) ? false : true;
  }
}
