import { Component, Inject, OnInit } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { TranslateService } from '@ngx-translate/core';
import { ChartDataGroup } from '../../chart.mapper';

export interface ChartIntegralOutput {
  startId?: string;
  destinationId?: string;
  connectToOrigin?: boolean;
  color?: string;
}

export interface ChartIntegralInput {
  startDatasets: ChartDataGroup[];
  destinationsDatasets: ChartDataGroup[];
}
@Component({
  selector: 'app-chart-integral-dialog',
  templateUrl: './chart-integral-dialog.component.html',
  styleUrls: ['./chart-integral-dialog.component.scss'],
})
export class ChartIntegralDialogComponent implements OnInit {
  public outputData: ChartIntegralOutput = {
    connectToOrigin: true,
  };
  constructor(
    public dialogRef: MatDialogRef<ChartIntegralDialogComponent>,
    public translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: ChartIntegralInput
  ) {}

  ngOnInit() {}

  startIdChange(event: MatSelectChange) {
    this.data.destinationsDatasets = this.data.destinationsDatasets.filter((data) => data.id != event.value).slice();
  }

  public isOutputValid() {
    return (
      this.outputData.startId &&
      this.outputData.startId !== this.outputData.destinationId &&
      (this.outputData.destinationId || this.outputData.connectToOrigin)
    );
  }
}
