import { Action } from '@app-modeleditor/components/button/action/action';
import { Resource } from 'frontend/src/dashboard/model/resource/resource';
import { LoadingParams } from '../../model/model.data';
import { ENotificationCategory } from './notification-category.enum';
import { ENotificationCode } from './notification-code.enum';
import { ENotificationType } from './notification-type.enum';

export class Notification extends Resource {
  private belongsToResource: Resource;

  /**
   * An array of string IDs representing the resources that this notification belongs to.
   */
  private belongsToResourceIds: string[];
  private message: string;
  private messageCategory: ENotificationCategory;
  private messageCode: ENotificationCode;
  private actions: Action[];
  private queueTime: Date;
  private resourceCanonicalName: string;
  private type: ENotificationType;
  private updateElementIds: string[];
  private refreshElementIds: string[];
  private broadcast: boolean;
  private zoomEnd: Date;
  private zoomStart: Date;
  private contentId: string;
  private clazz: string;
  private validUntil: Date;
  private highlightBlockIds: string[];
  private fileDownloadRestUrl: string;
  private disableReload: boolean;
  /**
   * used for TRIGGER_LOADING_INDICATOR notification
   */
  private loadingParams: LoadingParams;
  /**
   * used for checking if notification belongs to a specific table
   * for UPDATE_TABLE_ROWS_NOTIFICATION
   */
  private tableResourceId: string;
  /**
   * used for checking if notification belongs to a specific table
   * for UPDATE_TABLE_ROWS_NOTIFICATION
   *
   * There was an old solution where the table was identified by the id in refreshElementIds.
   * This mechanism has been replaced by the tableId property.
   */
  private tableId: string;

  public isDisableReload(): boolean {
    return typeof this.disableReload === 'boolean' ? this.disableReload : false;
  }

  public setDisableReload(disableReload: boolean): this {
    this.disableReload = disableReload;
    return this;
  }

  public getFileDownloadRestUrl(): string {
    return this.fileDownloadRestUrl;
  }

  public setFileDownloadRestUrl(fileDownloadRestUrl: string): this {
    this.fileDownloadRestUrl = fileDownloadRestUrl;
    return this;
  }

  public getValidUntil(): Date {
    return this.validUntil;
  }

  public setValidUntil(validUntil: Date): this {
    this.validUntil = validUntil;
    return this;
  }

  public getClazz(): string {
    return this.clazz;
  }

  public setClazz(clazz: string): this {
    this.clazz = clazz;
    return this;
  }

  public getRefreshElementIds(): string[] {
    return this.refreshElementIds || [];
  }

  public setRefreshElementIds(refreshElementIds: string[]): this {
    this.refreshElementIds = refreshElementIds;
    return this;
  }

  public getContentId(): string {
    return this.contentId;
  }

  public setContentId(contentId: string): this {
    this.contentId = contentId;
    return this;
  }

  public getZoomStart(): Date {
    return this.zoomStart;
  }

  public setZoomStart(zoomStart: Date): this {
    this.zoomStart = zoomStart;
    return this;
  }

  public getZoomEnd(): Date {
    return this.zoomEnd;
  }

  public setZoomEnd(zoomEnd: Date): this {
    this.zoomEnd = zoomEnd;
    return this;
  }

  public isBroadcast(): boolean {
    return this.broadcast ? true : false;
  }

  public setBroadcast(broadcast: boolean): this {
    this.broadcast = broadcast;
    return this;
  }

  public getUpdateElementIds(): string[] {
    return this.updateElementIds || [];
  }

  public setUpdateElementIds(updateElementIds: string[]): this {
    this.updateElementIds = updateElementIds;
    return this;
  }

  public getBelongsToResource(): Resource {
    return this.belongsToResource;
  }

  public setBelongsToResource(belongsToResource: Resource): this {
    this.belongsToResource = belongsToResource;
    return this;
  }

  public getBelongsToResourceIds(): string[] {
    return this.belongsToResourceIds || [];
  }

  public setBelongsToResourceIds(belongsToResourceIds: string[]): this {
    this.belongsToResourceIds = belongsToResourceIds;
    return this;
  }

  public getMessage(): string {
    return this.message;
  }

  public setMessage(message: string): this {
    this.message = message;
    return this;
  }

  public getMessageCategory(): ENotificationCategory {
    return this.messageCategory;
  }

  public setMessageCategory(messageCategory: ENotificationCategory): this {
    this.messageCategory = messageCategory;
    return this;
  }

  public getMessageCode(): ENotificationCode {
    return this.messageCode;
  }

  public setMessageCode(messageCode: ENotificationCode): this {
    this.messageCode = messageCode;
    return this;
  }

  public getActions(): Action[] {
    return this.actions;
  }

  public setActions(actions: Action[]): this {
    this.actions = actions;
    return this;
  }

  public getQueueTime(): Date {
    return this.queueTime;
  }

  public setQueueTime(queueTime: Date): this {
    this.queueTime = queueTime;
    return this;
  }

  public getResourceCanonicalName(): string {
    return this.resourceCanonicalName;
  }

  public setResourceCanonicalName(resourceCanonicalName: string): this {
    this.resourceCanonicalName = resourceCanonicalName;
    return this;
  }

  public getType(): ENotificationType {
    return this.type;
  }

  public setType(type: ENotificationType): this {
    this.type = type;
    return this;
  }

  public getHighlightBlockIds(): string[] {
    return this.highlightBlockIds;
  }

  public setHighlightBlockIds(highlightBlockIds: string[]): this {
    this.highlightBlockIds = highlightBlockIds;
    return this;
  }

  public getLoadingParams(): LoadingParams {
    return this.loadingParams;
  }

  public setLoadingParams(loadingParams: LoadingParams): this {
    this.loadingParams = loadingParams;
    return this;
  }

  public getTableResourceId(): string {
    return this.tableResourceId;
  }

  public setTableResourceId(tableResourceId: string): this {
    this.tableResourceId = tableResourceId;
    return this;
  }

  public getTableId(): string {
    return this.tableId;
  }

  public setTableId(tableId: string): this {
    this.tableId = tableId;
    return this;
  }
}
