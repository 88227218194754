export class ToHexColorConverter {
  private constructor() {}

  /**
   * takes color string and returns Hex color
   * @param {string} colorInput
   * @returns {string} colorHex
   */
  static convertColorToHex(colorString: string): string | undefined {
    if (!colorString) {
      return undefined;
    }
    const DEFAULT_COLOR = '#ff4444';

    let colorHex = DEFAULT_COLOR;

    if (ToHexColorConverter._getRegEx()['REG_HEX'].test(colorString)) {
      // HEX
      colorHex = colorString;
    } else if (ToHexColorConverter._getRegEx()['REG_HEX_A'].test(colorString)) {
      // HEX Alpha
      colorHex = colorString;
    } else if (ToHexColorConverter._getRegEx()['REG_RGB'].test(colorString)) {
      // RGB
      const regProc = ToHexColorConverter._regExpExecArrayToNumberArray(
        ToHexColorConverter._getRegEx()['REG_EXTRACT_RGB'].exec(colorString)
      );
      if (colorString.indexOf('%') > -1) {
        // rgb with percentages
        colorHex = ToHexColorConverter._rgbaToHex(
          Math.round(regProc[1] * 2.55),
          Math.round(regProc[2] * 2.55),
          Math.round(regProc[3] * 2.55)
        );
      } else {
        // rgb with 255
        colorHex = ToHexColorConverter._rgbaToHex(regProc[1], regProc[2], regProc[3]);
      }
    } else if (ToHexColorConverter._getRegEx()['REG_RGBA'].test(colorString)) {
      // RGBA
      const regProc = ToHexColorConverter._regExpExecArrayToNumberArray(
        ToHexColorConverter._getRegEx()['REG_EXTRACT_RGBA'].exec(colorString)
      );
      colorHex = ToHexColorConverter._rgbaToHex(regProc[1], regProc[2], regProc[3], regProc[4]);
      if (colorString.indexOf('%') > -1) {
        // rgb with percentages
        colorHex = ToHexColorConverter._rgbaToHex(
          Math.round(regProc[1] * 2.55),
          Math.round(regProc[2] * 2.55),
          Math.round(regProc[3] * 2.55),
          regProc[4]
        );
      } else {
        // rgb with 255
        colorHex = ToHexColorConverter._rgbaToHex(regProc[1], regProc[2], regProc[3], regProc[4]);
      }
    } else if (ToHexColorConverter._getRegEx()['REG_HSL'].test(colorString)) {
      // HSL
      const regProc = ToHexColorConverter._regExpExecArrayToNumberArray(
        ToHexColorConverter._getRegEx()['REG_EXTRACT_HSL'].exec(colorString)
      );
      colorHex = ToHexColorConverter._hslaToHex(regProc[1], regProc[2], regProc[3]);
    } else if (ToHexColorConverter._getRegEx()['REG_HSLA'].test(colorString)) {
      // HSLA
      const regProc = ToHexColorConverter._regExpExecArrayToNumberArray(
        ToHexColorConverter._getRegEx()['REG_EXTRACT_HSLA'].exec(colorString)
      );
      colorHex = ToHexColorConverter._hslaToHex(regProc[1], regProc[2], regProc[3], regProc[4]);
    } else {
      // Color Name or default
      const newColorHex = ToHexColorConverter._colorNameToHex(colorString);
      if (newColorHex) {
        colorHex = newColorHex;
      }
    }

    return colorHex;
  }

  /**
   * Returns COLOR FORMAT REGEX
   */
  static _getRegEx() {
    return {
      // check
      REG_HEX: /^#([\da-f]{3}){1,2}$/i,
      REG_HEX_A: /^#([\da-f]{4}){1,2}$/i,
      REG_RGB:
        /^rgb\((((((((1?[1-9]?\d)|10\d|(2[0-4]\d)|25[0-5]),\s?)){2}|((((1?[1-9]?\d)|10\d|(2[0-4]\d)|25[0-5])\s)){2})((1?[1-9]?\d)|10\d|(2[0-4]\d)|25[0-5]))|((((([1-9]?\d(\.\d+)?)|100|(\.\d+))%,\s?){2}|((([1-9]?\d(\.\d+)?)|100|(\.\d+))%\s){2})(([1-9]?\d(\.\d+)?)|100|(\.\d+))%))\)$/i,
      REG_RGBA:
        /^rgba\((((((((1?[1-9]?\d)|10\d|(2[0-4]\d)|25[0-5]),\s?)){3})|(((([1-9]?\d(\.\d+)?)|100|(\.\d+))%,\s?){3}))|(((((1?[1-9]?\d)|10\d|(2[0-4]\d)|25[0-5])\s){3})|(((([1-9]?\d(\.\d+)?)|100|(\.\d+))%\s){3}))\/\s)((0?\.\d+)|[01]|(([1-9]?\d(\.\d+)?)|100|(\.\d+))%)\)$/i,
      REG_HSL:
        /^hsl\(((((([12]?[1-9]?\d)|[12]0\d|(3[0-5]\d))(\.\d+)?)|(\.\d+))(deg)?|(0|0?\.\d+)turn|(([0-6](\.\d+)?)|(\.\d+))rad)((,\s?(([1-9]?\d(\.\d+)?)|100|(\.\d+))%){2}|(\s(([1-9]?\d(\.\d+)?)|100|(\.\d+))%){2})\)$/i,
      REG_HSLA:
        /^hsla\(((((([12]?[1-9]?\d)|[12]0\d|(3[0-5]\d))(\.\d+)?)|(\.\d+))(deg)?|(0|0?\.\d+)turn|(([0-6](\.\d+)?)|(\.\d+))rad)(((,\s?(([1-9]?\d(\.\d+)?)|100|(\.\d+))%){2},\s?)|((\s(([1-9]?\d(\.\d+)?)|100|(\.\d+))%){2}\s\/\s))((0?\.\d+)|[01]|(([1-9]?\d(\.\d+)?)|100|(\.\d+))%)\)$/i,

      // process
      REG_EXTRACT_RGB: /^rgb\((\d{1,3})%?,\s*(\d{1,3})%?,\s*(\d{1,3})%?\)$/,
      REG_EXTRACT_RGBA: /^rgba\((\d{1,3})%?,\s*(\d{1,3})%?,\s*(\d{1,3})%?,\s*((?:0*(?:\.\d+)?|1(\.0*)?))\)$/,
      REG_EXTRACT_HSL: /^hsl\((\d+),\s*([\d.]+)%,\s*([\d.]+)%\)$/,
      REG_EXTRACT_HSLA: /^hsla\((\d+),\s*([\d.]+)%,\s*([\d.]+)%,\s*((?:0*(?:\.\d+)?|1(\.0*)?))\)$/,
    };
  }

  /**
   * converts color name to hex from colorList array
   * @param {string} color
   * @returns
   */
  static _colorNameToHex(color) {
    const colorValue = ToHexColorConverter._getColorList()[color.toLowerCase()];
    if (typeof colorValue != 'undefined') {
      return colorValue;
    }
    return false;
  }

  /**
   * converts rgb(a) to hex
   * @param {int} r
   * @param {int} g
   * @param {int} b
   * @param {float} a optional
   * @returns {string} hexColor
   */
  static _rgbaToHex(r, g, b, a = 1) {
    let rs: string = parseInt(r).toString(16);
    let gs: string = parseInt(g).toString(16);
    let bs: string = parseInt(b).toString(16);
    let as: string = Math.round(a * 255).toString(16);
    if (rs.length == 1) rs = '0' + rs;
    if (gs.length == 1) gs = '0' + gs;
    if (bs.length == 1) bs = '0' + bs;
    if (as.length == 1) as = '0' + as;
    if (as == 'ff') return '#' + rs + gs + bs;
    return '#' + rs + gs + bs + as;
  }

  /**
   * converts hsl(a) to hex
   * @param {int} h
   * @param {int} s
   * @param {int} l
   * @param {float} a optional
   * @returns {string} hexColor
   */
  static _hslaToHex(h, s, l, a = 1) {
    s /= 100;
    l /= 100;

    let c = (1 - Math.abs(2 * l - 1)) * s,
      x = c * (1 - Math.abs(((h / 60) % 2) - 1)),
      m = l - c / 2,
      r = 0,
      g = 0,
      b = 0;

    if (0 <= h && h < 60) {
      r = c;
      g = x;
      b = 0;
    } else if (60 <= h && h < 120) {
      r = x;
      g = c;
      b = 0;
    } else if (120 <= h && h < 180) {
      r = 0;
      g = c;
      b = x;
    } else if (180 <= h && h < 240) {
      r = 0;
      g = x;
      b = c;
    } else if (240 <= h && h < 300) {
      r = x;
      g = 0;
      b = c;
    } else if (300 <= h && h < 360) {
      r = c;
      g = 0;
      b = x;
    }

    return ToHexColorConverter._rgbaToHex((r + m) * 255, (g + m) * 255, (b + m) * 255, a);
  }

  static _getColorList() {
    return {
      aliceblue: '#f0f8ff',
      antiquewhite: '#faebd7',
      aqua: '#00ffff',
      aquamarine: '#7fffd4',
      azure: '#f0ffff',
      beige: '#f5f5dc',
      bisque: '#ffe4c4',
      black: '#000000',
      blanchedalmond: '#ffebcd',
      blue: '#0000ff',
      blueviolet: '#8a2be2',
      brown: '#a52a2a',
      burlywood: '#deb887',
      cadetblue: '#5f9ea0',
      chartreuse: '#7fff00',
      chocolate: '#d2691e',
      coral: '#ff7f50',
      cornflowerblue: '#6495ed',
      cornsilk: '#fff8dc',
      crimson: '#dc143c',
      cyan: '#00ffff',
      darkblue: '#00008b',
      darkcyan: '#008b8b',
      darkgoldenrod: '#b8860b',
      darkgray: '#a9a9a9',
      darkgreen: '#006400',
      darkkhaki: '#bdb76b',
      darkmagenta: '#8b008b',
      darkolivegreen: '#556b2f',
      darkorange: '#ff8c00',
      darkorchid: '#9932cc',
      darkred: '#8b0000',
      darksalmon: '#e9967a',
      darkseagreen: '#8fbc8f',
      darkslateblue: '#483d8b',
      darkslategray: '#2f4f4f',
      darkturquoise: '#00ced1',
      darkviolet: '#9400d3',
      deeppink: '#ff1493',
      deepskyblue: '#00bfff',
      dimgray: '#696969',
      dodgerblue: '#1e90ff',
      firebrick: '#b22222',
      floralwhite: '#fffaf0',
      forestgreen: '#228b22',
      fuchsia: '#ff00ff',
      gainsboro: '#dcdcdc',
      ghostwhite: '#f8f8ff',
      gold: '#ffd700',
      goldenrod: '#daa520',
      gray: '#808080',
      grey: '#808080',
      green: '#008000',
      greenyellow: '#adff2f',
      honeydew: '#f0fff0',
      hotpink: '#ff69b4',
      'indianred ': '#cd5c5c',
      indigo: '#4b0082',
      ivory: '#fffff0',
      khaki: '#f0e68c',
      lavender: '#e6e6fa',
      lavenderblush: '#fff0f5',
      lawngreen: '#7cfc00',
      lemonchiffon: '#fffacd',
      lightblue: '#add8e6',
      lightcoral: '#f08080',
      lightcyan: '#e0ffff',
      lightgoldenrodyellow: '#fafad2',
      lightgrey: '#d3d3d3',
      lightgreen: '#90ee90',
      lightpink: '#ffb6c1',
      lightsalmon: '#ffa07a',
      lightseagreen: '#20b2aa',
      lightskyblue: '#87cefa',
      lightslategray: '#778899',
      lightsteelblue: '#b0c4de',
      lightyellow: '#ffffe0',
      lime: '#00ff00',
      limegreen: '#32cd32',
      linen: '#faf0e6',
      magenta: '#ff00ff',
      maroon: '#800000',
      mediumaquamarine: '#66cdaa',
      mediumblue: '#0000cd',
      mediumorchid: '#ba55d3',
      mediumpurple: '#9370d8',
      mediumseagreen: '#3cb371',
      mediumslateblue: '#7b68ee',
      mediumspringgreen: '#00fa9a',
      mediumturquoise: '#48d1cc',
      mediumvioletred: '#c71585',
      midnightblue: '#191970',
      mintcream: '#f5fffa',
      mistyrose: '#ffe4e1',
      moccasin: '#ffe4b5',
      navajowhite: '#ffdead',
      navy: '#000080',
      oldlace: '#fdf5e6',
      olive: '#808000',
      olivedrab: '#6b8e23',
      orange: '#ffa500',
      orangered: '#ff4500',
      orchid: '#da70d6',
      palegoldenrod: '#eee8aa',
      palegreen: '#98fb98',
      paleturquoise: '#afeeee',
      palevioletred: '#d87093',
      papayawhip: '#ffefd5',
      peachpuff: '#ffdab9',
      peru: '#cd853f',
      pink: '#ffc0cb',
      plum: '#dda0dd',
      powderblue: '#b0e0e6',
      purple: '#800080',
      rebeccapurple: '#663399',
      red: '#ff0000',
      rosybrown: '#bc8f8f',
      royalblue: '#4169e1',
      saddlebrown: '#8b4513',
      salmon: '#fa8072',
      sandybrown: '#f4a460',
      seagreen: '#2e8b57',
      seashell: '#fff5ee',
      sienna: '#a0522d',
      silver: '#c0c0c0',
      skyblue: '#87ceeb',
      slateblue: '#6a5acd',
      slategray: '#708090',
      snow: '#fffafa',
      springgreen: '#00ff7f',
      steelblue: '#4682b4',
      tan: '#d2b48c',
      teal: '#008080',
      thistle: '#d8bfd8',
      tomato: '#ff6347',
      turquoise: '#40e0d0',
      violet: '#ee82ee',
      wheat: '#f5deb3',
      white: '#ffffff',
      whitesmoke: '#f5f5f5',
      yellow: '#ffff00',
      yellowgreen: '#9acd32',
    };
  }

  /**
   * Converts Array of type RegExpExecArray to Array of numbers using parseFloat
   * @param {RegExpExecArray} array Array to convert
   * @returns {number[]} Converted array
   */
  static _regExpExecArrayToNumberArray(array: RegExpExecArray) {
    return array.map((elem) => parseFloat(elem));
  }
}
