import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { TranslateModule } from '@ngx-translate/core';
import { SlotModule } from '../template/slot/slot.module';
import { ColumnListSelectorComponent } from './column-list-selector.component';

@NgModule({
  imports: [
    CommonModule,
    MatFormFieldModule,
    FormsModule,
    MatInputModule,
    DragDropModule,
    TranslateModule,
    ScrollingModule,
    MatButtonModule,
    MatIconModule,
    SlotModule,
    MatListModule,
  ],
  declarations: [ColumnListSelectorComponent],
  exports: [ColumnListSelectorComponent],
})
export class ColumnListSelectorModule {}
