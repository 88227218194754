import * as d3 from 'd3';

import { ETimeFormatWeekdayStrategy, GanttXAxisFormatGeneral, TimeFormatterAxisFormat } from './x-axis-format-general';

/**
 * @description Predifend x-Axis #2. <br>
 * **Medium** level of detail.
 * @extends {GanttXAxisFormatGeneral}
 */
export class GanttXAxisFormat2 extends GanttXAxisFormatGeneral {
  constructor() {
    super(null);

    this.cssClassNameText = 'x_axis_text_center';
    this.renderVerticalLinesOnShifts = true;

    this.dateFormatList = [
      {
        value: 1,
        time: [new TimeFormatterAxisFormat('Quartal', d3.timeMonth.every(3), 'MONTH')],
      },
      {
        value: 5.3,
        time: [new TimeFormatterAxisFormat('%B %y', d3.timeMonth.every(1), 'MONTH')],
      },
      {
        value: 18,
        time: [new TimeFormatterAxisFormat('%V KW', d3.timeMonday.every(1), 'CALENDAR_WEEK')],
      },
      {
        value: 100,
        time: [
          new TimeFormatterAxisFormat(
            '%d %b %Y',
            d3.timeDay.every(1),
            'DAY',
            ETimeFormatWeekdayStrategy.INLINE,
            '%a, %d %b %Y'
          ),
        ],
      },
      {
        value: 1200,
        time: [new TimeFormatterAxisFormat('%H:00', d3.timeHour.every(1), 'HOUR')],
      },
      {
        value: 130000,
        time: [new TimeFormatterAxisFormat('%H:%M', d3.timeMinute.every(1), 'MINUTE')],
      },
    ];
  }
}
