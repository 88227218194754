import { GanttCanvasShift } from '../../../data-handler/data-structure/data-structure';
import { BestGantt } from '../../../main';
import { TimeGradationHandler } from '../../../time-gradation-handler/time-gradation-handler';
import { GanttShiftEditLimiter } from './shift-edit-limiter';

/**
 * Basic class which provides access to the general shift edit limiter and can be extended to handle special shift edit restrictions.
 */
export class GanttShiftEditLimiterAdapter {
  protected _ganttDiagram: BestGantt;

  constructor(ganttDiagram: BestGantt) {
    this._ganttDiagram = ganttDiagram;
  }

  //
  // EARLIEST START / LATEST END
  //

  /**
   * Determines whether ES or LE should be handled during the editing of the specified shift or not.
   * @param shift Shift to check the restrcitions for.
   */
  public handleEsLeForShift(shift: GanttCanvasShift): boolean {
    return this._shiftEditLimiter.handleEsLeForShift(shift);
  }

  /**
   * Determines whether ES should be handled during the editing of the specified shift or not.
   * @param shift Shift to check the restrcitions for.
   */
  public handleEarliestStartForShift(shift: GanttCanvasShift): boolean {
    return this._shiftEditLimiter.handleEarliestStartForShift(shift);
  }

  /**
   * Determines whether LE should be handled during the editing of the specified shift or not.
   * @param shift Shift to check the restrcitions for.
   */
  public handleLatestEndForShift(shift: GanttCanvasShift): boolean {
    return this._shiftEditLimiter.handleLatestEndForShift(shift);
  }

  /**
   * Checks the specified date if it violates earliest start restrictions and corrects it if necessary.
   * @param date Date to check.
   * @param shift Canvas shift data containing the restrictions.
   * @returns Checked and corrected date.
   */
  public getEarliestStartConformingDate(date: Date, shift: GanttCanvasShift): Date {
    return this._shiftEditLimiter.getEarliestStartConformingDate(date, shift);
  }

  /**
   * Checks the specified date if it violates latest end restrictions and corrects it if necessary.
   * @param date Date to check.
   * @param shift Canvas shift data containing the restrictions.
   * @returns Checked and corrected date.
   */
  public getLatestEndConformingDate(date: Date, shift: GanttCanvasShift): Date {
    return this._shiftEditLimiter.getLatestEndConformingDate(date, shift);
  }

  /**
   * Checks the specified x coordinate if it violates earliest start restrictions and corrects it if necessary.
   * @param x X coordinate to check.
   * @param shift Canvas shift data containing the restrictions.
   * @returns Checked and corrected x coordinate.
   */
  public getEarliestStartConformingXCoordinate(x: number, shift: GanttCanvasShift): number {
    return this._shiftEditLimiter.getEarliestStartConformingXCoordinate(x, shift);
  }

  /**
   * Checks the specified x coordinate if it violates latest end restrictions and corrects it if necessary.
   * @param x X coordinate to check.
   * @param shift Canvas shift data containing the restrictions.
   * @returns Checked and corrected x coordinate.
   */
  public getLatestEndConformingXCoordinate(x: number, shift: GanttCanvasShift): number {
    return this._shiftEditLimiter.getLatestEndConformingXCoordinate(x, shift);
  }

  //
  // GENERAL HELPER METHODS
  //

  /**
   * Checks if the specified pixel values have a visible difference.
   * @param val1 1st pixel value.
   * @param val2 2nd pixel value.
   * @returns True if the difference is visible, false otheriwse.
   */
  public isVisiblePixelDiff(val1: number, val2: number): boolean {
    return Math.abs(val1 - val2) >= this._ganttDiagram.getConfig().shiftEditVisualizeNotAllowedTolerance();
  }

  //
  // GETTER & SETTER
  //

  /**
   * Returns the time gradation handler instance for shift editing.
   * @returns Time gradation handler instance for shift editing.
   */
  public get timeGradationHandler(): TimeGradationHandler {
    return this._shiftEditLimiter.timeGradationHandler;
  }

  /**
   * Helper getter which returns the global shift edit limiter of the current gantt.
   */
  protected get _shiftEditLimiter(): GanttShiftEditLimiter {
    return this._ganttDiagram.getShiftEditLimiter();
  }
}
