<mat-tab-group
  mat-stretch-tabs
  [class.fullscreen]="templateNode.getResizeMode() === 'FIT_PARENT'"
  #element
  [(selectedIndex)]="selectedTabIndex"
  [id]="templateNode.getId()"
  #tabs
  (selectedIndexChange)="onChangeTabIndex($event, element)"
  [ngClass]="{ dynamicTab: templateNode.isTabResizeDynamic() }"
>
  <mat-tab *ngFor="let tab of templateNode.getIndexPages(); let i = index">
    <ng-template mat-tab-label>
      <div class="index-label" [id]="tab.getId()" [matTooltip]="tab.getName() | translate">
        {{ tab.getName() | translate }}
      </div>
    </ng-template>
    <ng-template matTabContent>
      <div class="tab-inner-content">
        <template-slot [source]="tab" *ngIf="tab && i === selectedTabIndex" (afterInit)="afterSlotInit($event, tab)">
        </template-slot>
      </div>
    </ng-template>
  </mat-tab>
</mat-tab-group>
