import { CdkDrag } from '@angular/cdk/drag-drop';
import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { Lightbox } from './lightbox';
import { Observable, Subject } from 'rxjs';

interface ICoordinates {
  x: number;
  y: number;
}

interface IDragEvent {
  distance: ICoordinates;
  source: CdkDrag;
  event: MouseEvent;
  delta?: ICoordinates;
  pointerPosition?: ICoordinates;
}

@Component({
  selector: 'app-lightbox',
  templateUrl: './lightbox.component.html',
  styleUrls: ['./lightbox.component.scss'],
})
export class LightboxComponent implements OnDestroy, OnInit {
  private isFullscreen = this.lightbox.isFullscreen();
  private dialogContent;
  @ViewChild('dialogContent') set dialogsContent(dialogContent: ElementRef) {
    this.dialogContent = dialogContent;
    this._applyAbsoluteWidth();
    this._applyAbsoluteHeight();
  }

  protected absoluteWidth: string = null;
  protected absoluteHeight: string = null;
  private width: number = null;
  private height: number = null;
  protected dragPosition = { x: 0, y: 0 };

  private _alive = true;
  private _onDestroySubject = new Subject<void>();

  constructor(@Inject(MAT_DIALOG_DATA) public lightbox: Lightbox, public dialogRef: MatDialogRef<LightboxComponent>) {}

  ngOnInit(): void {}

  /**
   * sets a lightbox to fullscreen
   * @returns void
   */
  fullscreen(): void {
    if (this.isFullscreen) {
      this.lightbox?.getRef()?.updateSize('auto', 'auto');
      this.isFullscreen = false;
      this._applyAbsoluteWidth();
      this._applyAbsoluteHeight();
    } else {
      this.lightbox?.getRef()?.updateSize('100vw', '100vh');
      this.dragPosition = { x: 0, y: 0 };
      this.isFullscreen = true;
      this._applyAbsoluteWidth();
      this._applyAbsoluteHeight();
    }
  }

  /**
   * Applies the current absolute width on the lightbox.
   */
  private _applyAbsoluteWidth(): void {
    if (this.isFullscreen) {
      this.absoluteWidth = null;
    } else if (this.width) {
      this.absoluteWidth = `${this.width}px`;
    } else {
      if (!isNaN(this.lightbox.getAbsoluteWidth())) {
        this.absoluteWidth = `${this.lightbox.getAbsoluteWidth()}px`;
      } else if (!isNaN(this.lightbox.getWidth()) && this.dialogContent) {
        this.absoluteWidth = `${(screen.width / 100) * this.lightbox.getWidth()}px`;
      }
    }
  }
  /**
   * Applies the current absolute height on the lightbox.
   */
  private _applyAbsoluteHeight(): void {
    if (this.isFullscreen) {
      this.absoluteHeight = null;
    } else if (this.height) {
      this.absoluteHeight = `${this.height}px`;
    } else if (!isNaN(this.lightbox.getHeight())) {
      this.absoluteHeight = `${this.lightbox.getHeight()}vh`;
    }
  }

  ngOnDestroy(): void {
    this._alive = false;
    this._onDestroySubject.next();
    this._onDestroySubject.complete();
    this.dialogContent = null;

    // clean up whole component
    Object.keys(this).forEach((key) => {
      if (key === '_onDestroySubject') return;
      this[key] = null;
    });
  }

  // close(result?: any): void {
  //   this.dialogContent.close(result);
  //   this.dialogRef.close(result);
  // }

  onMove(event: IDragEvent): void {
    this.handleDrag(event.distance);
    event.source.reset();
    event.event.preventDefault();
  }

  onDragStart(event: IDragEvent): void {
    this.isFullscreen = false;
    const el: HTMLElement = this.dialogContent.elementRef.nativeElement;
    this.width = el.clientWidth;
    this.height = el.clientHeight;
    this.lightbox?.getRef()?.updateSize('auto', 'auto');
  }

  afterDrag(event: IDragEvent): void {
    const el: HTMLElement = this.handleDrag(event.distance);
    this.width = el.clientWidth;
    this.height = el.clientHeight;
    event.source.reset();
  }

  public handleDrag(distance: ICoordinates): HTMLElement {
    const el: HTMLElement = this.dialogContent.elementRef.nativeElement;
    if (!this.width) {
      this.width = el.clientWidth;
    }

    if (!this.height) {
      this.height = el.clientHeight;
    }

    this.absoluteWidth = `${this.width + distance.x * 2}px`;
    this.absoluteHeight = this.height + distance.y * 2 < 200 ? '120px' : `${this.height + distance.y * 2}px`;

    return el;
  }

  /**
   * Flag indicating whether the component is alive or not.
   */
  public get alive(): boolean {
    return this._alive;
  }

  /**
   * Observable which gets triggered when the component gets destroyed.
   */
  public get onDestroy(): Observable<void> {
    return this._onDestroySubject.asObservable();
  }
}
