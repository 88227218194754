import { OverlayRef } from '@angular/cdk/overlay';
import { InjectionToken } from '@angular/core';
import { ContextMenuItem } from '@app-modeleditor/components/contextmenu/context-menu-item';
import { GlobalUtils } from 'frontend/src/dashboard/global-utils';

export class Message {
  private duration: number;
  private title: string;
  private text: string;
  private id: string;
  private ref: OverlayRef;
  private type: EMessageType;
  private actions: ContextMenuItem[];
  private params: { [key: string]: string };
  private created: Date;
  private pinned: boolean;

  public isPinned(): boolean {
    return typeof this.pinned === 'boolean' ? this.pinned : false;
  }

  public setPinned(pinned: boolean): this {
    this.pinned = pinned;
    return this;
  }

  public getCreated(): Date {
    return this.created;
  }

  public setCreated(created: Date): this {
    this.created = created;
    return this;
  }

  constructor(_title?: string, _text?: string, _duration?: number) {
    this.title = _title;
    this.text = _text;
    this.duration = _duration;
    this.id = GlobalUtils.generateUUID();
    this.type = EMessageType.DEFAULT;
  }

  public getActions(): ContextMenuItem[] {
    return this.actions || [];
  }

  public setActions(actions: ContextMenuItem[]): this {
    this.actions = actions;
    return this;
  }

  public getParams(): { [key: string]: string } {
    return this.params || null;
  }

  public setParams(params: { [key: string]: string }): this {
    this.params = params;
    return this;
  }

  public getType(): EMessageType {
    return this.type;
  }

  public setType(type: EMessageType): this {
    this.type = type;
    return this;
  }

  public getRef(): OverlayRef {
    return this.ref;
  }

  public setRef(ref: OverlayRef): this {
    this.ref = ref;
    return this;
  }

  public getId(): string {
    return this.id;
  }

  public setId(id: string): this {
    this.id = id;
    return this;
  }

  public getDuration(): number {
    return this.duration || 7500;
  }

  public setDuration(duration: number): this {
    this.duration = duration;
    return this;
  }

  public getTitle(): string {
    return this.title;
  }

  public setTitle(title: string): this {
    this.title = title;
    return this;
  }

  public getText(): string {
    return this.text;
  }

  public setText(text: string): this {
    this.text = text;
    return this;
  }
}

export const CONTAINER_DATA = new InjectionToken<any>('CONTAINER_DATA');

export enum EMessageType {
  LOG = 'log',
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info',
  DEFAULT = 'default',
  WARN = 'warn',
}
