import { Resource } from 'frontend/src/dashboard/model/resource/resource';
import { Action } from './action';

export class ActionChain extends Resource {
  private chain: Action[];

  public getActions(): Action[] {
    return this.chain || [];
  }

  public setActions(chain: Action[]): this {
    this.chain = chain;
    return this;
  }

  public addActions(...actions: Action[]): this {
    this.setActions(this.getActions().concat(actions || []));
    return this;
  }

  constructor(...actions: Action[]) {
    super();
    this.setActions(actions);
  }
}
