import { GanttCanvasRow } from '../../../data-handler/data-structure/data-structure';
import { BestGantt } from '../../../main';
import { GanttStickyRowHandler } from '../sticky-row-handler';

/**
 * Basic class defining all methods and properties a gantt sticky row strategy should have.
 * This class can be used as sticky row strategy with no effect on the gantt without extending it.
 */
export class GanttStickyRowStrategy {
  /**
   * @param _ganttDiagram
   * @param _executer
   */
  constructor(protected _ganttDiagram: BestGantt, protected _executer: GanttStickyRowHandler) {}

  //
  // LIFE CYCLE
  //

  /**
   * Initializes the {@link GanttStickyRowStrategy}.
   * Executes tasks that should be done before the first render data generation starts.
   */
  public init(): void {
    // nothing
  }

  /**
   * Destroys the {@link GanttStickyRowStrategy}.
   * Executes tasks that should be done after the last render data generation.
   */
  public destroy(): void {
    // nothing
  }

  //
  // STICKY ROWS HANDLING
  //

  /**
   * Manipulates the given dataset of gantt rows according to the implemented bahavior.
   * @param dataSet Manipulated dataset of gantt rows containing information about sticky rows.
   */
  public getManipulatedYAxisDataSet(dataSet: GanttCanvasRow[]): GanttCanvasRow[] {
    return dataSet;
  }

  //
  // UTILITY
  //

  /**
   * Removes all sticky rows from the given row dataset.
   * @param dataSet Row dataset to remove all sticky rows from.
   */
  protected _removeAllStickyRows(dataSet: GanttCanvasRow[]): void {
    for (const row of dataSet) {
      if (row.sticky) row.sticky = false;
    }
  }
}
