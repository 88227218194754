import { CdkDrag, CdkDragDrop } from '@angular/cdk/drag-drop';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { Action } from '../button/action/action';
import { Button } from '../button/button';
import { EButtonDisplayType } from '../button/button-display-type.enum';
import { EDisplayOrientation } from '../content/content-element/display-orientation.enum';
import { EntryCollection } from '../entry-collection/entry-collection';
import { EntryElement } from '../entry-collection/entry-element';
import { EntryElementValue } from '../entry-collection/entry-element-value';
import { TemplateComponent } from '../template/template.component';
import { ColumnListSelector } from './column-list-selector';
import { ColumnListSelectorElement } from './column-list-selector-element';
import { ColumnListSelectorToolbar } from './column-list-selector-toolbar';

@Component({
  selector: 'app-column-list-selector',
  templateUrl: './column-list-selector.component.html',
  styleUrls: ['./column-list-selector.component.scss'],
})
export class ColumnListSelectorComponent implements OnInit, OnChanges, OnDestroy {
  searchTermLeft: string;
  searchTermRight: string;

  sortLeft = 'none';
  sortRight = 'none';

  // template
  @Input() data: ColumnListSelector;
  @ContentChild('contentRef') contentRef: TemplateRef<any>;
  // data for the template
  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();
  selectedElements: ColumnListSelectorElement[];
  @ViewChildren('rootElement') set tableview(list: QueryList<ElementRef>) {
    of(null)
      .pipe(delay(0))
      .subscribe(() => {
        if (list.first) {
          this._setItemHeight(list.first.nativeElement.clientHeight + 1);
        }
      });
  }

  onIndexChange(event, inst: CdkVirtualScrollViewport): void {
    // inst.checkViewportSize();
  }
  @ViewChild('todoScroller') todoScroller: CdkVirtualScrollViewport;
  private _setItemHeight(height: number): void {
    if (this.itemHeight === height) {
      return;
    }
    this.itemHeight = height;
  }

  afterInitSlot(instance: ComponentRef<TemplateComponent>): void {
    instance.instance.templateNode = this.toolbar = new ColumnListSelectorToolbar(this, {
      showMenuToggleBtn: true,
    }).get();
  }

  toolbar: ColumnListSelectorToolbar;
  lastSelectedType: 'left' | 'right';
  lastSelectedItem: ColumnListSelectorElement;

  constructor(private cd: ChangeDetectorRef) {
    this.collection = new EntryCollection().setDisplayOrientation(EDisplayOrientation.VERTICAL).setEntryElements([
      new Button()
        .setEnableBy(() => this.selectedListItems?.length > 0)
        .setIcon('first_page')
        .setName('BUTTON.move_all_left')
        .setDisplayType(EButtonDisplayType.ICON_ONLY)
        .chainActions(
          new Action().setCb(() => {
            this.moveAllLeft();
            this.saveChanges();
            return of(null);
          })
        ),
      new Button()
        .setEnableBy(
          () => this.selectedElements?.length > 0 && !this.selectedElementIsInLeftList(this.selectedElements[0])
        )
        .setIcon('chevron_left')
        .setName('BUTTON.move_selected_left')
        .setDisplayType(EButtonDisplayType.ICON_ONLY)
        .chainActions(
          new Action().setCb(() => {
            this.moveLeft();
            return of(null);
          })
        ),
      new Button()
        .setEnableBy(
          () => this.selectedElements?.length > 0 && this.selectedElementIsInLeftList(this.selectedElements[0])
        )
        .setIcon('chevron_right')
        .setName('BUTTON.move_selected_right')
        .setDisplayType(EButtonDisplayType.ICON_ONLY)
        .chainActions(
          new Action().setCb(() => {
            this.moveRight();
            return of(null);
          })
        ),
      new Button()
        .setEnableBy(() => this.availableListItems?.length > 0)
        .setIcon('last_page')
        .setName('BUTTON.move_all_right')
        .setDisplayType(EButtonDisplayType.ICON_ONLY)
        .chainActions(
          new Action().setCb(() => {
            this.moveAllRight();
            this.saveChanges();
            return of(null);
          })
        ),
    ]);
  }

  collection: EntryCollection;
  selectedListItems: ColumnListSelectorElement[] = [];
  availableListItems: ColumnListSelectorElement[] = [];

  ngOnInit(): void {
    this.filterLists();
  }

  itemHeight = 56;

  afterSlotInit(instance: ComponentRef<TemplateComponent>, root: HTMLElement): void {
    instance.instance.root = root;
  }

  ngOnChanges(): void {
    this.filterLists();
  }

  isRefreshing: boolean;
  public reset(): void {
    this.isRefreshing = true;
    this.cd.detectChanges();
    this.isRefreshing = false;
  }

  filterLists(): void {
    if (this.data.getValue()) {
      this.selectedListItems = this.filterList(
        this.data.getValue().getSelectedListEntryElements(),
        this.searchTermRight
      );
      this.availableListItems = this.filterList(
        this.data.getValue().getAvailableListEntryElements(),
        this.searchTermLeft
      );
    }
  }

  private filterList(list: ColumnListSelectorElement[], term: string): ColumnListSelectorElement[] {
    return (list || []).filter((e: ColumnListSelectorElement) => {
      if (!term || term === '') {
        return true;
      }

      if (e.isSimpleElement()) {
        return (e.getFloatLeft() || '').toLowerCase().indexOf((term || '').toLowerCase()) !== -1;
      }

      if (
        e.getContent() &&
        e.getContent().getContentParts().length > 0 &&
        e.getContent().getContentParts()[0].getContentElements().length > 0
      ) {
        const elem = e.getContent().getContentParts()[0].getContentElements()[0];
        if (elem instanceof EntryCollection) {
          const ee: EntryElement = elem.getEntryElements()[0];
          if (
            (ee.getValue<EntryElementValue>().getValue<string>() || '')
              .toLowerCase()
              .indexOf((term || '').toLowerCase()) === -1
          ) {
            return false;
          }
        }
      }
      return true;
    });
  }

  public trackByFn(index: number, item: ColumnListSelectorElement): any {
    return item.getUuid();
  }

  ngOnDestroy(): void {}

  dragging: boolean;
  onDragStart(e: CdkDrag, item: ColumnListSelectorElement, items: ColumnListSelectorElement[]): void {
    this.lastSelectedItem = item;
    // reset selection if drag starts in another list
    if (
      this.selectedElements &&
      (!this.selectedElements.find((e) => e.getId() === item.getId()) ||
        (this.selectedElements.length && !items.find((e) => e.getId() === this.selectedElements[0].getId())))
    ) {
      this.selectedElements = [];
    }

    this.selectedElements = (this.selectedElements || [])
      .filter((e: ColumnListSelectorElement) => e.getId() !== item.getId())
      .concat(item);

    this.dragging = true;
  }

  isSelected(item: ColumnListSelectorElement): boolean {
    if (!this.selectedElements) {
      return false;
    }
    return this.selectedElements.find((e: ColumnListSelectorElement) => e.getId() === item.getId()) ? true : false;
  }

  getBorderDefinition(item: ColumnListSelectorElement, list: ColumnListSelectorElement[], index: number): string[] {
    if (!this.isSelected(item)) {
      return [];
    }

    const definitions: string[] = [];
    if (index === 0 || !this.isSelected(list[index - 1])) {
      definitions.push('top');
    }

    if (index === list.length - 1 || !this.isSelected(list[index + 1])) {
      definitions.push('bottom');
    }
    return definitions;
  }

  dblClick(
    event: MouseEvent,
    item: ColumnListSelectorElement,
    items: ColumnListSelectorElement[],
    type: 'left' | 'right'
  ): void {
    this.selectedElements = [item];
    if (type === 'left') {
      this.moveRight();
    } else {
      this.moveLeft();
    }
  }

  select(
    event: MouseEvent,
    item: ColumnListSelectorElement,
    items: ColumnListSelectorElement[],
    type: 'left' | 'right'
  ): void {
    // if(event.shiftKey
    if (this.lastSelectedType !== type || !this.selectedElements) {
      this.selectedElements = [];
    }
    this.lastSelectedType = type;
    if (event) {
      if (this.selectedElements.length > 0 && this.selectedElements[0].getId() !== item.getId() && event.shiftKey) {
        const minIdx: number = Math.min(
          ...this.selectedElements.map((e) => items.findIndex((_e) => e.getId() === _e.getId()))
        );
        const newIdx: number = items.findIndex((_e) => item.getId() === _e.getId());
        const start: number = minIdx < newIdx ? minIdx : newIdx;
        const end: number = newIdx > minIdx ? newIdx : minIdx;
        this.selectedElements = [].concat(...items.slice(start, end + 1));
        return;
      }

      if (this.selectedElements.length > 0 && event.ctrlKey) {
        if (this.isSelected(item)) {
          this.selectedElements = this.selectedElements.filter(
            (e: ColumnListSelectorElement) => e.getId() !== item.getId()
          );
        } else {
          this.selectedElements = this.selectedElements.concat(item);
        }
        return;
      }
    }

    if (this.selectedElements.length > 1) {
      this.selectedElements = [item];
    } else if (this.isSelected(item)) {
      this.selectedElements = [];
    } else {
      this.selectedElements = [item];
    }
  }

  private exclude(entryList: ColumnListSelectorElement[]) {
    return entryList.map((entry) => {
      const cloned = entry.copy(ColumnListSelectorElement);
      // cloned.setContent(null);
      return cloned.serialize();
    });
  }

  public moveAllLeft(): void {
    // const available: ColumnListSelectorElement[] = this.availableListItems;
    const selected: ColumnListSelectorElement[] = this.selectedListItems;

    this.data
      .getValue()
      .setAvailableListEntryElements(this.data.getValue().getAvailableListEntryElements().concat(selected));
    this.data.getValue().setSelectedListEntryElements(
      this.data
        .getValue()
        .getSelectedListEntryElements()
        .filter(
          (item) =>
            !this.data
              .getValue()
              .getAvailableListEntryElements()
              .find((_item) => _item.getId() === item.getId())
        )
    );

    this.filterLists();
  }

  public moveAllRight(): void {
    const available: ColumnListSelectorElement[] = this.availableListItems;
    // const selected: ColumnListSelectorElement[] = this.selectedListItems;

    this.data
      .getValue()
      .setSelectedListEntryElements(this.data.getValue().getSelectedListEntryElements().concat(available));
    this.data.getValue().setAvailableListEntryElements(
      this.data
        .getValue()
        .getAvailableListEntryElements()
        .filter(
          (item) =>
            !this.data
              .getValue()
              .getSelectedListEntryElements()
              .find((_item) => _item.getId() === item.getId())
        )
    );

    this.filterLists();
  }

  public moveLeft(index?: number): void {
    // remove selected from previous list
    const available: ColumnListSelectorElement[] = this.data.getValue().getAvailableListEntryElements();
    const selected: ColumnListSelectorElement[] = this.data.getValue().getSelectedListEntryElements();

    this.data
      .getValue()
      .setSelectedListEntryElements(selected.filter((item: ColumnListSelectorElement) => !this.isSelected(item)));

    available.splice(isNaN(index) ? available.length - 1 : index, 0, ...this.selectedElements);
    this.data.getValue().setAvailableListEntryElements(available);
    // clear selection
    this.selectedListItems = this.data.getValue().getSelectedListEntryElements();
    this.availableListItems = this.data.getValue().getAvailableListEntryElements();

    this.saveChanges();
  }

  public moveRight(index?: number): void {
    // remove selected from previous list
    const available: ColumnListSelectorElement[] = this.data.getValue().getAvailableListEntryElements();
    const selected: ColumnListSelectorElement[] = this.data.getValue().getSelectedListEntryElements();

    this.data
      .getValue()
      .setAvailableListEntryElements(available.filter((item: ColumnListSelectorElement) => !this.isSelected(item)));

    selected.splice(isNaN(index) ? selected.length - 1 : index, 0, ...this.selectedElements);
    this.data.getValue().setSelectedListEntryElements(selected);
    this.selectedListItems = this.data.getValue().getSelectedListEntryElements();
    this.availableListItems = this.data.getValue().getAvailableListEntryElements();
    // clear selection
    this.saveChanges();
  }

  public selectedElementIsInLeftList(item: ColumnListSelectorElement): boolean {
    return this.availableListItems.find((element: ColumnListSelectorElement) => element.getId() === item.getId())
      ? true
      : false;
  }

  get numberOfAvailableItems(): string {
    if ((this.availableListItems || []).length === this.data.getValue().getAvailableListEntryElements().length) {
      return this.data.getValue().getAvailableListEntryElements().length.toString();
    }

    return `${this.availableListItems.length}|${this.data.getValue().getAvailableListEntryElements().length}`;
  }

  get numberOfSelectedItems(): string {
    if ((this.selectedListItems || []).length === this.data.getValue().getSelectedListEntryElements().length) {
      return this.data.getValue().getSelectedListEntryElements().length.toString();
    }

    return `${this.selectedListItems.length}|${this.data.getValue().getSelectedListEntryElements().length}`;
  }

  onFormChange(): void {
    if (this.data.getValue()) {
      const clonedList = this.selectedListItems.slice();
      this.onChange.emit(this.exclude(clonedList));
    }
  }

  private _move(curIndex: number, list: ColumnListSelectorElement[]) {
    // find current index of element in current dropped list
    // const offset: number = event.previousIndex - this._getSelectedIndex(event, list);
    const draggedSmaller: number =
      list.findIndex((l) => l.getId() === this.lastSelectedItem.getId()) < curIndex ? -1 : 0;
    const numberOfSmallerIdx: number = this.selectedElements
      .map((e) => list.findIndex((l) => l.getId() === e.getId()))
      .filter((i) => i < curIndex).length;
    // // get current index with offset applied
    //  const offsetIdx: number = event.currentIndex - offset;
    const offsetIdx = curIndex;
    // remove all selected items from the list
    list = list.filter((e: ColumnListSelectorElement) =>
      this.selectedElements.find((_e: ColumnListSelectorElement) => _e.getId() === e.getId()) ? false : true
    );

    // clamp idx in min and max
    let idx: number = offsetIdx < 0 ? 0 : offsetIdx;

    // subtract all elements where the index is smaller than the pivot index
    idx -= draggedSmaller ? numberOfSmallerIdx - 1 : numberOfSmallerIdx;

    list.splice(idx, 0, ...this.selectedElements);
    return list;
  }

  onDragEnd(e: Event): void {
    this.dragging = false;
  }

  test(): void {
    this.todoScroller.checkViewportSize();
  }

  drop(event: CdkDragDrop<ColumnListSelectorElement[]>, viewPort: CdkVirtualScrollViewport): void {
    const viewPortStart: number =
      viewPort && viewPort instanceof CdkVirtualScrollViewport ? viewPort.getRenderedRange().start : 0;
    const currentIndex: number = event.currentIndex + viewPortStart;
    const previousIndex: number = event.previousIndex + viewPortStart;

    // in case there are selected elements
    // only move if possible
    if (this.selectedElements.length) {
      // if its the same container
      if (event.previousContainer === event.container) {
        if (currentIndex === previousIndex) {
          return;
        }
        if (this.selectedElementIsInLeftList(this.selectedElements[0])) {
          const calculcatedIdx: number = this.availableListItems.findIndex(
            (e) => e.getId() === this.lastSelectedItem.getId()
          );
          const offset: number = calculcatedIdx - previousIndex;
          this.data
            .getValue()
            .setAvailableListEntryElements(
              this._move(currentIndex + offset, this.data.getValue().getAvailableListEntryElements())
            );
          this.availableListItems = this.data.getValue().getAvailableListEntryElements();
        } else {
          const calculcatedIdx: number = this.selectedListItems.findIndex(
            (e) => e.getId() === this.lastSelectedItem.getId()
          );
          const offset: number = calculcatedIdx - previousIndex;
          this.data
            .getValue()
            .setSelectedListEntryElements(
              this._move(currentIndex + offset, this.data.getValue().getSelectedListEntryElements())
            );
          this.selectedListItems = this.data.getValue().getSelectedListEntryElements();
        }
      } else {
        if (this.selectedElementIsInLeftList(this.selectedElements[0])) {
          const calculcatedIdx: number = this.availableListItems.findIndex(
            (e) => e.getId() === this.lastSelectedItem.getId()
          );
          const offset: number = calculcatedIdx - previousIndex;
          this.moveRight(currentIndex + offset);
        } else {
          const calculcatedIdx: number = this.selectedListItems.findIndex(
            (e) => e.getId() === this.lastSelectedItem.getId()
          );
          const offset: number = calculcatedIdx - previousIndex;
          this.moveLeft(currentIndex + offset);
        }
      }
    }

    this.saveChanges();
    this.filterLists();
    this.sortLeft = this.sortRight = 'none';
  }

  public saveChanges(): void {
    if (this.data && this.data.getValue()) {
      const clonedList = this.selectedListItems.slice();
      this.data.setValue(this.data.getValue());
      this.onChange.emit(this.exclude(clonedList));
    }
  }

  sortLeftItems(items: ColumnListSelectorElement[], dir: 'none' | 'asc' | 'desc'): 'asc' | 'desc' {
    dir = dir == 'asc' ? 'desc' : 'asc';
    this.data.getValue().setAvailableListEntryElements(this.sortItems(items, dir));
    return dir;
  }

  sortRightItems(items: ColumnListSelectorElement[], dir: 'none' | 'asc' | 'desc'): 'asc' | 'desc' {
    dir = dir == 'asc' ? 'desc' : 'asc';
    this.data.getValue().setSelectedListEntryElements(this.sortItems(items, dir));
    return dir;
  }

  /**
   * sorts the list
   */
  private sortItems(items: ColumnListSelectorElement[], dir: 'asc' | 'desc'): ColumnListSelectorElement[] {
    const multiplier = dir === 'asc' ? 1 : -1;
    return items.sort((a, b) => {
      if (a.getFloatLeft() < b.getFloatLeft()) {
        return -1 * multiplier;
      }

      if (a.getFloatLeft() > b.getFloatLeft()) {
        return 1 * multiplier;
      }

      return 0;
    });
  }
}
