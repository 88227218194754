import { CdkDragDrop, copyArrayItem, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  AfterViewInit,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  NgZone,
  OnDestroy,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { Action } from '@app-modeleditor/components/button/action/action';
import { EActionType } from '@app-modeleditor/components/button/action/action-type.enum';
import { EPredefinedAction } from '@app-modeleditor/components/button/action/predefined-action.enum';
import { EBadgePosition } from '@app-modeleditor/components/button/badge-position.enum';
import { Button } from '@app-modeleditor/components/button/button';
import { EButtonDisplayType } from '@app-modeleditor/components/button/button-display-type.enum';
import { ContextMenuItem } from '@app-modeleditor/components/contextmenu/context-menu-item';
import { ContextMenu } from '@app-modeleditor/components/contextmenu/contextmenu';
import { EntryElement } from '@app-modeleditor/components/entry-collection/entry-element';
import { TranslateService } from '@ngx-translate/core';
import { GlobalUtils } from 'frontend/src/dashboard/global-utils';
import { Template } from 'frontend/src/dashboard/model/resource/template';
import { ETemplateEvents } from 'frontend/src/dashboard/model/resource/template-events.enum';
import { ToolbarGroup } from 'frontend/src/dashboard/view/template-toolbar/toolbar-group';
import { Subject, Subscription, fromEvent, of } from 'rxjs';
import { debounceTime, delay, takeUntil } from 'rxjs/operators';
import { ResizeEvent } from '../resize/resize-event';
import { EResizeType } from '../resize/resize-type.enum';
import { ResizeService } from '../resize/resize.service';
import { ViewService } from '../view.service';
import { ANIMATIONS } from './hider.animation';
import { MenuItem } from './menu-item';
import { Toolbar } from './toolbar';
import { EToolbarEvents } from './toolbar-events.enum';
import { EToolbarItemType } from './toolbar-item-type';

const MAX_SHORTCUTS = 3;
@Component({
  selector: 'template-toolbar',
  templateUrl: './template-toolbar.component.html',
  styleUrls: ['./template-toolbar.component.scss'],
  animations: ANIMATIONS,
})
export class TemplateToolbarComponent implements OnDestroy, AfterViewInit {
  @Input() reference: HTMLElement;
  @ContentChild('tPredefined') tPredefined: TemplateRef<any>;
  @ContentChild('tContent') tContent: TemplateRef<any>;
  private resizeIds: string[] = [null, null, null, null, null, null];
  @ViewChild('scrollContainer') set c1(scrollContainer: ElementRef) {
    this._resizeApi.complete(this.resizeIds[0]);
    if (scrollContainer) {
      this.resizeIds[0] = this._resizeApi.create(scrollContainer.nativeElement, this.checkScrollActions.bind(this));
    }
  }

  @Input() resourceId: string;

  private ngDestroy: Subject<void> = new Subject<void>();
  private componentId: string = GlobalUtils.generateUUID();
  template: Toolbar;
  @Input('template') set Foo(template: Toolbar) {
    this.template = template;
    if (this.template) {
      // this.template.removeEventListener(this.componentId, EToolbarEvents.ACTIVE_ITEM_CHANGED);
      // this.template.addEventListener(this.componentId, EToolbarEvents.ACTIVE_ITEM_CHANGED, (ev: CustomEvent) => {
      //   of(null).pipe(delay(500)).subscribe(() => {
      //     this.checkScrollActions();
      //   });
      // });

      this.template.addEventListener(this.componentId, ETemplateEvents.UPDATED, () => {
        if (this.innerContainer) {
          of(null)
            .pipe(delay(0))
            .subscribe(() => {
              this.calcDisplayedToolbarItems(this.innerContainer.nativeElement.clientWidth);
            });
        }
      });

      this.template.addEventListener(this.componentId, EToolbarEvents.SCROLLED, (ev: CustomEvent) => {
        this.setScrollPosition(ev.detail.scrollLeft);
      });
    }
  }

  dragEnded(ev: any): void {
    this.left += ev.distance.x;
    setTimeout(() => {
      this.dragging = false;
    }, 500);
    ev.source._dragRef.reset();
  }

  left = 25;
  dragging = false;
  mouse: { x: number; y: number } = { x: 0, y: 0 };
  @HostListener('document:mousemove', ['$event']) mousemove(event: MouseEvent): void {
    this.mouse.x = event.x;
    this.mouse.y = event.y;
    const rect: DOMRect = this.template?.getReferenceContainer()?.nativeElement.getBoundingClientRect();
    if (!rect) {
      return;
    }

    let mousein: boolean;
    if (
      this.mouse.x >= rect.x &&
      this.mouse.x <= rect.x + rect.width &&
      this.mouse.y >= rect.y &&
      this.mouse.y <= rect.y + rect.height
    ) {
      mousein = true;
    } else {
      mousein = false;
    }

    if (mousein !== this.mousein) {
      this.$zone.run(() => (this.mousein = mousein));
    }
  }
  mousein: boolean;

  private $scrollSub: Subscription;
  @ViewChild('outerToolbarContainer', { read: ElementRef })
  outerToolbarContainer: ElementRef;
  @Output() afterShortcutsChanged: EventEmitter<string[]> = new EventEmitter<string[]>();
  actionBar: ElementRef;
  @ViewChild('actionBar') set actionBarRef(actionBar: ElementRef) {
    this.actionBar = actionBar;
    this.$scrollSub?.unsubscribe();
    if (!actionBar) {
      return;
    }
    this.$zone.runOutsideAngular(() => {
      this.$scrollSub = fromEvent(actionBar.nativeElement, 'scroll')
        .pipe(debounceTime(350))
        .subscribe((e: Event) => {
          this.checkScrollActions(e);
        });
    });
  }
  @ViewChild('a') set c2(a: ElementRef) {
    this._resizeApi.complete(this.resizeIds[1]);
    this.a = a;
    if (!a) {
      return;
    }

    this.resizeIds[1] = this._resizeApi.create(a.nativeElement, this._checkContainerSize.bind(this));
  }
  c: ElementRef;
  a: ElementRef;
  @ViewChild('b') set c3(b: ElementRef) {
    this._resizeApi.complete(this.resizeIds[3]);
    if (!b) {
      return;
    }
    this.resizeIds[3] = this._resizeApi.create(b.nativeElement, () => {
      of(null)
        .pipe(delay(0))
        .subscribe(() => {});
    });
  }

  private innerContainer: ElementRef;
  @ViewChild('innerContainer') set iContainer(innerContainer: ElementRef) {
    this._resizeApi.complete(this.resizeIds[4]);
    this.innerContainer = innerContainer;
    if (!innerContainer) {
      return;
    }
    this.resizeIds[4] = this._resizeApi.create(
      innerContainer.nativeElement,
      (e: ResizeEvent) => {
        this.$zone.run(() => {
          of(null)
            .pipe(delay(0))
            .subscribe(() => {
              this.calcDisplayedToolbarItems(innerContainer.nativeElement.clientWidth);
            });
        });
      },
      { types: [EResizeType.WIDTH] }
    );
  }

  @ViewChild('c') set c4(c: ElementRef) {
    this._resizeApi.complete(this.resizeIds[2]);
    this.c = c;
    if (!c) {
      return;
    }
    this.resizeIds[2] = this._resizeApi.create(c.nativeElement, this._checkContainerSize.bind(this));
  }

  innerContainerWidth: string;
  @ViewChild('extenderBtn', { read: ElementRef }) set extenderBtn(extenderBtn: ElementRef) {
    this._resizeApi.complete(this.resizeIds[5]);
    if (!extenderBtn) {
      return;
    }
    this.resizeIds[5] = this._resizeApi.create(
      extenderBtn.nativeElement,
      (e: ResizeEvent) => {
        this.$zone.run(() => {
          of(null)
            .pipe(delay(0))
            .subscribe(() => {
              this.innerContainerWidth = `calc(100% - ${extenderBtn.nativeElement.clientWidth + 5}px)`;
            });
        });
      },
      { types: [EResizeType.WIDTH] }
    );
  }

  initialized = false;
  itemMenuBtn: Button;
  constructor(
    private _resizeApi: ResizeService,
    private $zone: NgZone,
    private translate: TranslateService,
    private viewService: ViewService
  ) {
    this.itemMenuBtn = new Button()
      .setBadgePosition(EBadgePosition.ABOVE_AND_BEFORE)
      .setName('Menü')
      .setDisplayType(EButtonDisplayType.ICON_ONLY)
      .setIcon('menu');
  }

  containerSize: string;
  private _checkContainerSize(): void {
    if (!this.a || !this.c) {
      return;
    }
    of(null)
      .pipe(delay(0))
      .subscribe(() => {
        this.containerSize = `calc(100% - ${this.a?.nativeElement.clientWidth + this.c?.nativeElement.clientWidth}px)`;
      });
  }

  ngAfterViewInit(): void {
    of(null)
      .pipe(delay(0))
      .subscribe(() => {
        this.initialized = true;
      });
  }

  get showToolbarRow(): boolean {
    const state: boolean = this.template?.getActiveMenuItem() && this.template?.getMenuMode() === 'SHOW' ? true : false;
    return state;
  }

  protected toggleExtend(): void {
    this.template.setExtended(!this.template.isExtended());
  }

  private sortByIndex(items: MenuItem[]): MenuItem[] {
    return items.sort((a: MenuItem, b: MenuItem) => (a.getIndex() < b.getIndex() ? -1 : 1));
  }

  get shortcutItems(): MenuItem[] {
    return this._getItems().filter((i: MenuItem) => i.isShortcut());
  }

  get toolbarGroups(): MenuItem[] {
    return this._getItems().filter((i: MenuItem) => !i.isShortcut());
  }

  displayedToolbarItems: MenuItem[];
  selectedContextMenuItem: ContextMenuItem;

  private predctWidth(btn: Button): number {
    const name: string = this.translate.instant(btn.getName() || '');
    switch (btn.getDisplayType()) {
      case EButtonDisplayType.ICON_ONLY:
        return 40;
      case EButtonDisplayType.ICON_AND_LABEL:
        if (btn.getIcon()) {
          return 54 + 7.79 * name.length;
        }
    }

    return 30 + 7.79 * name.length;
  }

  calcDisplayedToolbarItems(maxWidth: number): void {
    let counter = 0;
    const notDisplayedToolbarItems: ContextMenuItem[] = [];
    this.displayedToolbarItems = this.toolbarGroups
      .filter((g: MenuItem) => {
        counter += this.predctWidth(g);
        if (counter <= maxWidth) {
          return true;
        } else {
          notDisplayedToolbarItems.push(g.copy(ContextMenuItem));
        }
      })
      .sort((a, b) => (a.getIndex() || 0) - (b.getIndex() || 0));

    if (!this.itemMenuBtn.getMenu()) {
      this.itemMenuBtn.setMenu(new ContextMenu());
    }

    this.itemMenuBtn.getMenu().setContextMenuItems(notDisplayedToolbarItems);
    this.selectedContextMenuItem = notDisplayedToolbarItems.find(
      (i: ContextMenuItem) => i.getId() === this.template.getActiveMenuItem()?.getId()
    );
    this.itemMenuBtn
      .setBadge(notDisplayedToolbarItems.length)
      .setDisplayType(this.selectedContextMenuItem ? EButtonDisplayType.ICON_AND_LABEL : EButtonDisplayType.ICON_ONLY)
      .setName(this.selectedContextMenuItem ? this.selectedContextMenuItem.getName() : 'Menü');
  }

  private _getItems(): MenuItem[] {
    if (!this.initialized) {
      return [];
    }

    const items: MenuItem[] = (this.template ? this.template.getMenuItems() : []).filter(
      (m: MenuItem) => m.getToolbarItemType() === EToolbarItemType.GROUP
    );
    return this.sortByIndex(items);
  }

  get toolbarNavigators(): MenuItem[] {
    const items: MenuItem[] = this.template
      ? this.template.getMenuItems().filter((m: MenuItem) => m.getToolbarItemType() === EToolbarItemType.NAVIGATOR)
      : [];

    const r = this.sortByIndex(items).concat(this.template.getPredefinedMenuItems());
    return r;
  }

  get entries(): EntryElement[] {
    return [].concat(
      ...this.template
        .getMenuItems()
        .map((m: MenuItem) => [].concat(...m.getToolbarGroups().map((g: ToolbarGroup) => g.getEntryElements())))
    );
  }

  get shortcuts(): EntryElement[] {
    const es: EntryElement[] = this.template
      .getShortcuts()
      .map((sId: string) => this.entries.find((e: EntryElement) => e.getId() === sId))
      .filter((s: EntryElement) => s !== undefined);
    return es;
  }

  get mainMenuMenuButton(): Button {
    return new Button()
      .setDisplayBackground(false)
      .setIcon('menu')
      .setDisplayType(EButtonDisplayType.ICON_ONLY)
      .chainActions(new Action().setId(EPredefinedAction.TOGGLE_USERSETTINGS).setActionType(EActionType.PREDEFINED));
  }

  /**
   * Provides the left padding of the toolbar row containing the actions.
   */
  protected get toolbarPaddingLeft(): number {
    return this.template?.getActiveMenuItem()?.getStartOffset()
      ? this.template.getActiveMenuItem().getStartOffset()
      : 16;
  }

  /**
   * Provides the right padding of the toolbar row containing the actions.
   */
  protected get toolbarPaddingRight(): number {
    return this.template?.getActiveMenuItem()?.getEndOffset()
      ? this.template?.getActiveMenuItem()?.getEndOffset()
      : this.template?.getActiveMenuItem()?.getStartOffset()
      ? 0
      : 16;
  }

  ngOnDestroy(): void {
    this.$scrollSub?.unsubscribe();
    this.ngDestroy.next();
    this.ngDestroy.complete();
    this._ngOverride.next();
    this._ngOverride.complete();

    this._resizeApi.complete(...this.resizeIds);
    this.template.removeEventListener(this.componentId);
  }

  scrollLeft(event: MouseEvent): void {
    this.actionBar.nativeElement.scrollLeft -= 200;
    this.checkScrollActions();
  }
  scrollRight(event: MouseEvent): void {
    this.actionBar.nativeElement.scrollLeft += 200;
    this.checkScrollActions();
  }

  canScrollLeft: boolean;
  canScrollRight: boolean;

  private setScrollPosition(scrollLeft: number): void {
    this.actionBar.nativeElement.scrollLeft = scrollLeft;
  }

  private _ngOverride: Subject<void> = new Subject<void>();
  checkScrollActions(event: Event = null): void {
    this._ngOverride.next();
    of(null)
      .pipe(delay(350), takeUntil(this._ngOverride))
      .subscribe(() => {
        this.$zone.run(() => {
          this.canScrollRight =
            !this.actionBar ||
            this.actionBar.nativeElement.scrollLeft + this.actionBar.nativeElement.clientWidth >=
              this.actionBar.nativeElement.scrollWidth
              ? false
              : true;

          this.canScrollLeft = !this.actionBar || this.actionBar.nativeElement.scrollLeft === 0 ? false : true;
        });
      });
  }

  /**
   * handles drop event
   * @param event CdkDragDrop<string[]>
   * @returns void
   */
  drop(event: CdkDragDrop<string[]>): void {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else if (this.template.getShortcuts().length < MAX_SHORTCUTS) {
      copyArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
    }

    this.saveShortcuts();
  }

  private saveShortcuts(): void {
    // perform save
    this.afterShortcutsChanged.emit(this.template.getShortcuts());
  }

  /**
   * try to add element to shortcuts
   * @param item ITemplateToolbarElement
   * @returns void
   */
  addToShortcuts(item: EntryElement): void {
    if (this.template.getShortcuts().length >= MAX_SHORTCUTS) {
      return;
    }

    const match: string = this.template.getShortcuts().find((shortcutId: string) => shortcutId === item.getId());
    if (match) {
      return;
    }

    this.template.addShortcuts(item.getId());
    this.saveShortcuts();
  }

  /**
   * try to find element in shortcuts
   * @param id string
   * @returns ITemplateToolbarElement
   */
  findElementInShortcuts(id: string): boolean {
    return this.template.getShortcuts().find((shortcutId: string) => shortcutId === id) ? true : false;
  }

  /**
   * remove item from shortcuts
   * @param item ITemplateToolbarElement
   * @returns void
   */
  removeFromShortcuts(item: Template): void {
    this.viewService.toggleShortcut(item.getId());
    this.saveShortcuts();
  }

  /**
   * handles contextmenu
   * @param event Event
   * @param ctx MatMenuTrigger
   * @returns void
   */
  onContextMenu(event: Event, ctx: MatMenuTrigger): void {
    event.preventDefault();
    ctx.openMenu();
  }

  onMenuClicked(ctx: MatMenuTrigger): void {
    ctx.closeMenu();
  }

  /**
   * Delegate for onRemoveAllFilters on active menu item, if null button should not be rendered.
   */
  protected get onRemoveAllFilters() {
    return this.template?.getActiveMenuItem()?.onRemoveAllFilters;
  }
}
