import { EntryElement } from '@app-modeleditor/components/entry-collection/entry-element';
import { EntryElementValue } from '@app-modeleditor/components/entry-collection/entry-element-value';
import { EFieldType } from '@app-modeleditor/components/entry-collection/field-type.enum';
import { EGanttSubMenuUpdateMessageType } from '@app-modules/saxms-submenu-elements/saxms-submenu.enum';
import {
  IGanttSubMenuSyncMessage,
  IGanttSubMenuUpdateMessage,
} from '@app-modules/saxms-submenu-elements/saxms-submenu.interface';
import { GlobalUtils } from 'frontend/src/dashboard/global-utils';
import { takeUntil } from 'rxjs/operators';
import { Gantt_General } from '../../general.gantt.component';
import { GanttEssentialPlugIns } from '../../plugin/e-gantt-essential-plugins';
import { GanttIndexCardsPlugIn } from '../../plugin/plugin-list/index-cards/index-cards.plugin';

export class IndexCardElement extends EntryElement {
  private _internalId: string = GlobalUtils.generateUUID();

  constructor(private scope: Gantt_General) {
    super();
  }

  get(data: any): EntryElement {
    this.setId(data.id)
      .setName(data.name)
      .setAlwaysEnabled(true)
      .setFieldType(EFieldType.SLIDE_TOGGLE)
      .onChanges((val: EntryElementValue) => {
        const value: IGanttSubMenuSyncMessage = { internalId: this._internalId, value: val.getValue() };
        const message: IGanttSubMenuUpdateMessage = {
          elementId: this.getId(),
          type: EGanttSubMenuUpdateMessageType.SYNC,
          value: value,
        };
        this.scope.submenuService.triggerElementById(message); // trigger sync
        this._toggleMode(val.getValue());
        this._saveValue(val.getValue());
      })
      .setValue(new EntryElementValue().setValue(false));

    this._subscribeToElementStateUpdates();
    this._subscribeToIndexCardActivation();

    return this;
  }

  public _toggleMode(state: boolean): void {
    const indexCardPlugIn: GanttIndexCardsPlugIn = this.scope.ganttPluginHandlerService.getEssentialPlugIn(
      GanttEssentialPlugIns.IndexCardBuilderPlugIn
    );
    if (!indexCardPlugIn) return;

    if (state === true) {
      indexCardPlugIn.activateIndexCardView();
    } else {
      indexCardPlugIn.deactivateIndexCardView();
    }
  }

  private _subscribeToIndexCardActivation() {
    const indexCardPlugIn: GanttIndexCardsPlugIn = this.scope.ganttPluginHandlerService.getEssentialPlugIn(
      GanttEssentialPlugIns.IndexCardBuilderPlugIn
    );

    indexCardPlugIn
      .getActivationNotification()
      .pipe(takeUntil(this.scope.onDestroy))
      .subscribe((value: boolean) => {
        if (value === this.getValue<EntryElementValue>().getValue()) {
          return;
        } // value already set
        if (value) {
          indexCardPlugIn.activateIndexCardView();
          this._syncElement(true);
        } else {
          indexCardPlugIn.deactivateIndexCardView();
          this._syncElement(false);
        }
      });
  }

  private _subscribeToElementStateUpdates() {
    this.scope.submenuService
      .onUpdateElementState()
      .pipe(takeUntil(this.scope.onDestroy))
      .subscribe((activationData: IGanttSubMenuUpdateMessage) => {
        if (!activationData || activationData.elementId !== this.getId()) {
          return;
        }
        switch (activationData.type) {
          case EGanttSubMenuUpdateMessageType.ACTION:
            break;
          case EGanttSubMenuUpdateMessageType.UPDATE:
            break;
          case EGanttSubMenuUpdateMessageType.SYNC:
            if (activationData.value.internalId === this._internalId) {
              return;
            } // element notifies itself
            this._syncElement(activationData.value.value);
            break;
        }
      });
  }

  /**
   * Synchronizes elements of sub menu with each other.
   */
  private _syncElement(selectedValue: boolean) {
    this.setValue(new EntryElementValue().setValue(selectedValue));
  }

  /**
   * Saves the selected value into settings and to backend.
   */
  private _saveValue(value: boolean) {
    this.scope.ganttSettingsService.changeSettings({ enableIndexCardView: !!value });
    this.scope.ganttSettingsService.saveSettings().subscribe();
  }
}
