import { BestGantt } from '@gantt/public-api';
import { GanttLibService } from '../gantt-lib.service';

/**
 * Wrapper to add gantt plugin into the gantt.
 */
export class SaxMsBestGanttCustomPlugIn {
  public id: string;
  public plugIn: SaxMsBestGanttPlugIn;

  constructor(id: string, plugIn: SaxMsBestGanttPlugIn) {
    this.id = id;
    this.plugIn = plugIn;
  }
}

/**
 * Basic wrapper-structure for all custom gantt plugins.
 */
export abstract class SaxMsBestGanttPlugIn {
  protected plugInIdList: string[];
  protected gantt: BestGantt;

  constructor(ganttLibService?: GanttLibService) {
    this.plugInIdList = [];
    if (ganttLibService.bestGantt) this.gantt = ganttLibService.bestGantt;
  }

  /**
   * Add JavaScript gantt plugin to wrapper structure.
   * @param id Storage key of plugin.
   * @param plugIn JavaScript gantt plugin instance.
   * @param gantt BestGantt instance in which the plug in should be added. If not set, it uses the gantt given inside constructor.
   */
  public addPlugIn(id: string, plugIn: any, gantt?: any): any {
    if (gantt) this.gantt = gantt;

    if (this.plugInIdList.indexOf(id) != -1) {
      console.warn('Overwriting PlugIn with Id: ' + id);
    }

    this.plugInIdList.push(id);
    this.gantt.addPlugIn(id, plugIn);
    return plugIn;
  }

  /**
   * Returns JavaScript gantt plugin registered by given plugInId if inside storage.
   * @param plugInId Storage id (which will be set in { @see SaxMsBestGanttPlugIn#addPlugIn }.
   */
  public getPlugInById(plugInId?: string): any {
    if (!plugInId && (!this.plugInIdList || this.plugInIdList.length == 0)) {
      return;
    }
    if (!plugInId) plugInId = this.plugInIdList[0];

    return this.gantt.getPlugIns()[plugInId];
  }

  /**
   * Returns the plugin storage with all registered plugins.
   */
  public getAllPlugIns(): any[] {
    const plugIns: any[] = [];
    for (const plugInId of this.getAllPlugInIds()) {
      if (this.getPlugInById(plugInId)) plugIns.push(this.getPlugInById(plugInId));
    }
    return plugIns;
  }

  /**
   * Returns all storage ids.
   */
  public getAllPlugInIds(): string[] {
    return this.plugInIdList;
  }
}
