import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-template-backdrop',
  templateUrl: './template-backdrop.component.html',
  styleUrls: ['./template-backdrop.component.scss'],
})
export class TemplateBackdropComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
