import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DragNDropComponent } from './drag-n-drop.component';
import { DragNDropDirective } from './drag-n-drop.directive';
import { ImageModule } from '@app-modeleditor/components/image/image.module';

@NgModule({
  imports: [CommonModule, DragDropModule, DragDropModule, ImageModule],
  declarations: [DragNDropComponent, DragNDropDirective],
  exports: [DragNDropComponent, DragNDropDirective],
})
export class DragNDropModule {}
