import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GlobalSidenavService {
  public isActive: string;
  public _activeObs: Observable<any>;
  public _activeSub: Subject<any>;

  public _onRefresh: Observable<any>;
  public _onRefreshSub: Subject<any>;

  constructor() {
    this._activeSub = new Subject<any>();
    this._activeObs = this._activeSub.asObservable();
  }

  public activate(view: any): void {
    this.isActive = view;
    this._activeSub.next(view);
  }
}
