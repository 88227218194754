import * as d3 from 'd3';
import { Observable, Subject } from 'rxjs';
import { GanttConfig } from '../config/gantt-config';
import { YAxisDataFinder } from '../data-handler/data-finder/yaxis-data-finder';
import { GanttCanvasRow } from '../data-handler/data-structure/data-structure';
import { BestGantt } from '../main';
import { GanttAnimator, GanttAnimatorTranslationEvent } from '../render/animator';
import { GanttCanvasAnimator } from '../render/canvas-animator';

/**
 * Helper class to handle and bundle row opening and closing handling.
 * The main principle is to animate row opening/closing and rebuild the gantt afterwards
 * to be sure that all visual elements are consistent to the data.
 *
 * NOTE: Animation will only be performed in the default scroll container. Child rows in other
 * scroll containers will only be added/removed.
 *
 * @keywords open, row, calculation, handler, manipulator, collapse, open, close
 */
export class GanttOpenRowHandler {
  private readonly _animator = new GanttAnimator();
  private readonly _canvasAnimator = new GanttCanvasAnimator();

  private _targetData: GanttCanvasRow = undefined;
  private _blockRowAction = false;
  private _removedGanttHeight = 0;

  private _beforeShiftRowOpenSubject = new Subject<GanttOpenRowHandlerAnimationEvent>();
  private _beforeShiftRowClosedSubject = new Subject<GanttOpenRowHandlerAnimationEvent>();
  private _afterShiftRowOpenSubject = new Subject<void>();
  private _afterShiftRowClosedSubject = new Subject<void>();

  /**
   * @param _ganttDiagram Reference to the {@link BestGantt} to handle the opening/closing of rows for.
   */
  constructor(private _ganttDiagram: BestGantt) {}

  /**
   * Registrates callbacks to rows. Should be executed during init class.
   * @keywords add, open, close, row, ability, init, initialize, register
   */
  public addOpenRowAbility(): void {
    this._animator.addGroupTranslationEndCallBack('_activateRowActionOnOpen', this._activateRowAction.bind(this));
    this._animator.setGroupTranslationEndPrioCallBack(this._toggleOpenStatus.bind(this));
  }

  /**
   * Toggles open property of row data.
   */
  private _toggleOpenStatus(): void {
    const foundCanvasRow = YAxisDataFinder.getCanvasRowById(
      this._ganttDiagram.getDataHandler().getYAxisDataset(),
      this._targetData.id
    );
    if (foundCanvasRow && !foundCanvasRow.leaf) {
      // normally, _targetData should be the exact same as foundCanvasRow,
      // but just to make sure if pointer will be lost...
      this._targetData.open = !this._targetData.open;
      foundCanvasRow.open = this._targetData.open;
    }
  }

  /**
   * Facade to handle row opening and closing.
   * @keywords toggle, rows, click, event, target, data, onclick, open, close
   * @param clickEvent Event which will be fired after row click.
   * @param targetData Data of clicked row.
   */
  public toggleRowsByClick(clickEvent: PointerEvent, targetData: GanttCanvasRow): void {
    if (
      !clickEvent.target ||
      this._blockRowAction ||
      (targetData.leaf && (!targetData.group || targetData.group == 'NO-GROUP'))
    )
      return;
    this._targetData = targetData;
    this._blockRowAction = true;
    const clickedRow = d3.select<HTMLElement, GanttCanvasRow>(clickEvent.target as HTMLElement);
    let rowCanvasData = clickedRow.data()[0];
    let rowId = rowCanvasData.id;

    if (rowCanvasData.group && rowCanvasData.group != 'NO-GROUP') {
      const firstConnectedRow = YAxisDataFinder.getFirstConnectedRow(
        this._ganttDiagram.getDataHandler().getYAxisDataset(),
        rowCanvasData.id
      );
      if (firstConnectedRow) {
        firstConnectedRow.open = !firstConnectedRow.open;
      } else rowCanvasData.open = !rowCanvasData.open;

      // check if rows are stuck together
      const lastConnectedRow = YAxisDataFinder.getLastConnectedRow(
        this._ganttDiagram.getDataHandler().getYAxisDataset(),
        rowCanvasData.id
      );
      if (lastConnectedRow) {
        // row id will be changed here
        rowId = lastConnectedRow.id;
        rowCanvasData = lastConnectedRow;
        this._targetData = rowCanvasData;
      }
    }

    const childRows = YAxisDataFinder.getRowById(
      this._ganttDiagram.getDataHandler().getOriginDataset().ganttEntries,
      rowId
    ).data;
    const childIds = YAxisDataFinder.getIdsOfNewRenderedRowsAfterOpenParentRow(childRows);

    if (!childRows.child || childIds.length == 0) {
      this._blockRowAction = false;
      return;
    }

    let newYAxisItemsHeight = 0;
    childIds.forEach((id) => {
      return (newYAxisItemsHeight += this._ganttDiagram.getDataHandler().getRowHeightStorage().getRowHeightById(id));
    });

    if (!rowCanvasData.open) {
      this._ganttDiagram.addToGanttHeight(newYAxisItemsHeight);
      this._openRow(newYAxisItemsHeight, rowCanvasData, clickEvent);
    } else if (rowCanvasData.open) {
      this._closeRow(rowCanvasData, clickEvent);
    }

    this._callFunctionWhileAnimationInPlugIns(rowCanvasData);
  }

  /**
   * Facade to handle all action after row opening, including animation handling.
   * @param itemsHeight height of children rows
   * @param rowCanvasData Canvas data of target row.
   * @param mouseEvent
   */
  private _openRow(itemsHeight: number, rowCanvasData: GanttCanvasRow, mouseEvent: PointerEvent): void {
    const shiftY = rowCanvasData.y + this._config.getLineTop();
    const translationY = itemsHeight;

    const scrollTop = this._ganttDiagram.getNodeProportionsState().getScrollTopPosition();
    const shiftViewportY = shiftY - scrollTop;

    this._animator.removeGroupTranslationEndCallBack('_updateDataSetAfterClosingShiftChild');
    this._animator.addGroupTranslationBeginCallBack(
      '_animateNewElementsBeforeOpeningChilds',
      this._animateNewElementsBeforeOpeningChilds.bind(this)
    );
    this._animator.addGroupTranslationEndCallBack(
      '_updateDataSetAfterOpenShiftChild',
      this._updateDataSetAfterOpenShiftChild.bind(this)
    );

    const animatorWithoutCallback = new GanttAnimator();
    animatorWithoutCallback.translateTextOverlayAnimation(
      this._ganttDiagram.getTextOverlay().getCanvas(),
      shiftY,
      translationY,
      null,
      null
    );
    animatorWithoutCallback.translateListAnimation(
      this._ganttDiagram.getYAxisFacade().getYAxisBuilder().getCanvas().select('.rowWrapper'),
      this._ganttDiagram.getYAxisFacade().getYAxisBuilder().getCanvas().select('.textWrapper'),
      rowCanvasData.y,
      translationY,
      null,
      null
    );
    this._animator.translateShiftAnimation(
      this._ganttDiagram.getShiftFacade().getShiftGroupOverlay(),
      shiftViewportY,
      translationY,
      mouseEvent,
      rowCanvasData
    );

    this._canvasAnimator.changeAreaPosition(
      this._ganttDiagram.getShiftFacade().getShiftCanvas().node(),
      rowCanvasData.y + rowCanvasData.height,
      translationY,
      null,
      null
    );

    this._beforeShiftRowOpenSubject.next(new GanttOpenRowHandlerAnimationEvent(translationY, rowCanvasData.y));
  }

  /**
   * Facade to handle all action after row closing, including animation handling.
   * @param rowCanvasData Canvas data of target row.
   * @param mouseEvent
   */
  private _closeRow(rowCanvasData: GanttCanvasRow, mouseEvent: PointerEvent): void {
    const shiftY = rowCanvasData.y + this._config.getLineTop();

    const scrollTop = this._ganttDiagram.getNodeProportionsState().getScrollTopPosition();
    const shiftViewportY = shiftY - scrollTop;

    const removingChildren = this._ganttDiagram
      .getDataHandler()
      .removeAllChildren(rowCanvasData.y, rowCanvasData.layer);
    this._removedGanttHeight = 0;
    removingChildren.forEach(
      (rowId) =>
        (this._removedGanttHeight += this._ganttDiagram.getDataHandler().getRowHeightStorage().getRowHeightById(rowId))
    );

    this._ganttDiagram.filterRenderDataSetsByCurrentView();

    this._animator.removeGroupTranslationBeginCallBack('_animateNewElementsBeforeOpeningChilds');
    this._animator.removeGroupTranslationEndCallBack('_updateDataSetAfterOpenShiftChild');
    this._animator.addGroupTranslationEndCallBack(
      '_updateDataSetAfterClosingShiftChild',
      this._updateDataSetAfterClosingShiftChild.bind(this)
    );

    this._removeElementsBeforeClosingChilds(rowCanvasData, this._removedGanttHeight);

    const animatorWithoutCallback = new GanttAnimator();
    animatorWithoutCallback.translateTextOverlayAnimation(
      this._ganttDiagram.getTextOverlay().getCanvas(),
      shiftY,
      -this._removedGanttHeight,
      mouseEvent,
      null
    );
    animatorWithoutCallback.translateListAnimation(
      this._ganttDiagram.getYAxisFacade().getYAxisBuilder().getCanvas().select('.rowWrapper'),
      this._ganttDiagram.getYAxisFacade().getYAxisBuilder().getCanvas().select('.textWrapper'),
      rowCanvasData.y,
      -this._removedGanttHeight,
      mouseEvent,
      null
    );
    this._animator.translateShiftAnimation(
      this._ganttDiagram.getShiftFacade().getShiftGroupOverlay(),
      shiftViewportY,
      -this._removedGanttHeight,
      mouseEvent,
      rowCanvasData
    );
    this._canvasAnimator.changeAreaPosition(
      this._ganttDiagram.getShiftFacade().getShiftCanvas().node(),
      rowCanvasData.y + rowCanvasData.height,
      -this._removedGanttHeight,
      null,
      null
    );

    this._beforeShiftRowClosedSubject.next(
      new GanttOpenRowHandlerAnimationEvent(-this._removedGanttHeight, rowCanvasData.y)
    );
  }

  /**
   * Renders canvas elements before opening row to make canvas elements visible during animation (and not only afterwards).
   * Matches new gantt height.
   * @param event Callback parameter which contains origin event and target canvas row data.
   */
  private _animateNewElementsBeforeOpeningChilds(event: GanttAnimatorTranslationEvent): void {
    if (!event.event) return;
    const clickedElement = d3.select(event.event.target);
    let clickedElementData: any = clickedElement.data()[0];
    if (event.data) clickedElementData = event.data;

    // const animationShiftGroup = this._ganttDiagram
    //   .getShiftFacade()
    //   .getShiftBuilder()
    //   .getCanvasInFrontShifts()
    //   .append('g')
    //   .attr('id', 'gantt-shifts-animation-group');

    // // synchronize zoom: animation-shiftgroup with shiftgroup
    // const zoomTransformation = this._ganttDiagram.getShiftFacade().getShiftBuilder().getShiftGroup().attr('transform');
    // animationShiftGroup.attr('transform', zoomTransformation);

    // render new yAxis-Items
    const newYAxisItems = this._ganttDiagram
      .getDataHandler()
      .getNewYAxisItemsByParentPosition(clickedElementData.id, clickedElementData.y);

    const animationYAxisListGroup = this._ganttDiagram
      .getYAxisFacade()
      .getYAxisBuilder()
      .getCanvas()
      .append('div')
      .attr('id', 'gantt-yaxis-list-animation-group');

    this._ganttDiagram.getYAxisFacade().getYAxisBuilder().render(newYAxisItems, animationYAxisListGroup);
  }

  /**
   * Removes canvas elements before closing row to avoid overlapping elements.
   * Matches new gantt height.
   * @param parentData Data of closed row.
   * @param heightToDelete Value to decrease gantt height.
   */
  private _removeElementsBeforeClosingChilds(parentData: GanttCanvasRow, heightToDelete: number): void {
    const startPointY = parentData.y + parentData.height;

    const allShifts = this._ganttDiagram.getShiftFacade().getShiftGroupOverlay().selectAll('rect');
    const allTextOverlay = this._ganttDiagram.getTextOverlay().getCanvas().selectAll('div');
    const yAxisCanvas = this._ganttDiagram.getYAxisFacade().getYAxisBuilder().getCanvas();
    const yAxisElements = yAxisCanvas.select('.rowWrapper').selectAll('.rowElement');
    const yAxisText = yAxisCanvas.select('.textWrapper').selectAll('.textElement');
    const yAxisStrokes = yAxisCanvas.selectAll('.strokeContainer');

    const removeElementsByY = (elements: d3.BaseType[], useViewportY = false): void => {
      const scrollTop = this._ganttDiagram.getNodeProportionsState().getScrollTopPosition();
      elements.forEach((element) => {
        const d = d3.select<d3.BaseType, { y: number }>(element).data()[0];
        const dy = useViewportY ? d.y - scrollTop : d.y;
        if (dy >= startPointY && dy < startPointY + heightToDelete) {
          d3.select(element).remove();
        }
      });
    };

    removeElementsByY(allShifts.nodes(), true);
    removeElementsByY(allTextOverlay.nodes());
    removeElementsByY(yAxisElements.nodes());
    removeElementsByY(yAxisText.nodes());
    removeElementsByY(yAxisStrokes.nodes());

    // empty canvas
    const shiftCanvas = this._ganttDiagram.getShiftFacade().getShiftCanvas();
    const canvasTopPosition = parseFloat(shiftCanvas.style('top'));
    const ctx = shiftCanvas.node().getContext('2d');
    ctx.clearRect(0, startPointY - canvasTopPosition, parseInt(shiftCanvas.attr('width')), heightToDelete);
  }

  /**
   * Facade to update data and canvas elements after opening animation has finished.
   * @param event Callback parameter which contains origin event and target canvas row data.
   */
  private _updateDataSetAfterOpenShiftChild(event: GanttAnimatorTranslationEvent): void {
    if (!event.event) return;
    const clickedElement = d3.select(event.event.target);
    let clickedElementData: any = clickedElement.data()[0];
    if (event.data) clickedElementData = event.data;
    this._ganttDiagram.getDataHandler().openShiftRow(clickedElementData.id, clickedElementData.y);

    // remove animation groups
    // this._ganttDiagram.getShiftFacade().getShiftBuilder().getCanvas().select('#gantt-shifts-animation-group').remove();
    // this._ganttDiagram.getYAxisBuilder().getCanvas().select('#gantt-yaxis-text-animation-group').remove();
    this._ganttDiagram
      .getYAxisFacade()
      .getYAxisBuilder()
      .getCanvas()
      .select('#gantt-yaxis-list-animation-group')
      .remove();

    this._ganttDiagram.filterRenderDataSetsByCurrentView();
    this._ganttDiagram.reRenderYAxisVertical();

    this._ganttDiagram.getShiftFacade().reBuildShifts(this._ganttDiagram.getRenderDataSetShifts());
    this._canvasAnimator.clearTemporaryAnimationCanvas();
    this._ganttDiagram.getYAxisFacade().reBuildList(this._ganttDiagram.getRenderDataSetYAxis());

    this._afterShiftRowOpenSubject.next();

    // final gantt update
    this._ganttDiagram.update();
  }

  /**
   * Facade to update data and canvas elements after closing animation has finished.
   * @param event Callback parameter which contains origin event and target canvas row data.
   */
  private _updateDataSetAfterClosingShiftChild(event: GanttAnimatorTranslationEvent): void {
    if (!event.event) return;

    const clickedElement = d3.select(event.event.target);
    let clickedElementData: any = clickedElement.data()[0];
    if (event.data) clickedElementData = event.data;

    this._ganttDiagram
      .getDataHandler()
      .closeShiftRow(clickedElementData.id, clickedElementData.y + this._config.getLineTop());

    this._ganttDiagram.filterRenderDataSetsByCurrentView();
    this._ganttDiagram.reRenderYAxisVertical();

    // rerender dataset
    this._ganttDiagram.getShiftFacade().reBuildShifts(this._ganttDiagram.getRenderDataSetShifts());
    this._canvasAnimator.clearTemporaryAnimationCanvas();
    this._ganttDiagram.getYAxisFacade().reBuildList(this._ganttDiagram.getRenderDataSetYAxis());

    // change gantt height
    this._ganttDiagram.addToGanttHeight(-this._removedGanttHeight);

    this._afterShiftRowClosedSubject.next();

    // final gantt update
    this._ganttDiagram.update();
  }

  /**
   * Special plugin action which calls all "animation()" functions of all plugins (if possible).
   * @param clickedElementData Data of clicked row.
   */
  private _callFunctionWhileAnimationInPlugIns(clickedElementData: GanttCanvasRow): void {
    // FIXME: only in edge plugin implemented, turn this into a normal callback!
    for (const key in this._ganttDiagram.getPlugIns()) {
      if (
        this._ganttDiagram.getPlugIns()[key].animation &&
        typeof this._ganttDiagram.getPlugIns()[key].animation == 'function'
      ) {
        this._ganttDiagram.getPlugIns()[key].animation(clickedElementData);
      }
    }
  }

  /**
   * Remove block for open/close rows.
   */
  private _activateRowAction() {
    this._blockRowAction = false;
  }

  //
  // GETTER & SETTER
  //

  /**
   * Helper getter which returns the gantt config of the current gantt.
   */
  private get _config(): GanttConfig {
    return this._ganttDiagram.getConfig();
  }

  public getAnimator(): GanttAnimator {
    return this._animator;
  }

  //
  // OBSERVABLES
  //

  public get beforeShiftRowOpen(): Observable<GanttOpenRowHandlerAnimationEvent> {
    return this._beforeShiftRowOpenSubject.asObservable();
  }

  public get beforeShiftRowClosed(): Observable<GanttOpenRowHandlerAnimationEvent> {
    return this._beforeShiftRowClosedSubject.asObservable();
  }

  public get afterShiftRowOpen(): Observable<void> {
    return this._afterShiftRowOpenSubject.asObservable();
  }

  public get afterShiftRowClosed(): Observable<void> {
    return this._afterShiftRowClosedSubject.asObservable();
  }
}

/**
 * Data structure containing information about a row open/close event.
 */
class GanttOpenRowHandlerAnimationEvent {
  constructor(public readonly translationY: number, public readonly positionY: number) {}
}
