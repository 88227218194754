<div [ngClass]="styleRef">
  <div class="dialog-title-wrapper">
    <div mat-dialog-title>
      <h2 mat-dialog-title>{{ 'SIMULATION.CHANGES.SIMULATE' | translate }}</h2>
    </div>
  </div>
  <div mat-dialog-content>
    <form class="example-form">
      <mat-form-field class="example-full-width">
        <input matInput placeholder="Name" #value />
      </mat-form-field>
    </form>
  </div>
  <div mat-dialog-actions>
    <button mat-button (click)="onClick(value.value)">{{ 'BUTTONS.CONFIRM' | translate }}</button>
    <button mat-button (click)="onCancel()">{{ 'BUTTONS.CANCEL' | translate }}</button>
  </div>
</div>
