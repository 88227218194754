import * as d3 from 'd3';
import { GanttCallBackStackExecuter } from '../callback-tools/callback-stack-executer';
import { GanttConfig } from '../config/gantt-config';
import { ShiftDataFinder } from './data-finder/shift-data-finder';
import { YAxisDataFinder } from './data-finder/yaxis-data-finder';
import { YAxisDataMapper } from './data-mapper/yaxis-data-mapper';
import { DataStateStorage } from './data-state-storage';
import { GanttCanvasRow, GanttCanvasShift, GanttDataContainer } from './data-structure/data-structure';
import { CanvasDataEditor } from './data-tools/canvas-data-editor';
import { DataAdder } from './data-tools/data-adder';
import { DataEditor } from './data-tools/data-editor';
import { DataManipulator } from './data-tools/data-manipulator';
import { DataRemover } from './data-tools/data-remover';
import { GanttRowHeightStorage } from './row-height-storage';

/**
 * Main execution class for data handling on gantt.
 * Holds and processes gantt origin data and canvas data.
 * @keywords data, dataset, handle, handler, handling, manipulation, management, search, canvas, origin, init
 */
export class DataHandler {
  private _stateStorage = new DataStateStorage();

  private _xAxisScale: d3.ScaleTime<number, number>;
  private _config: GanttConfig;

  private _originDataSet: GanttDataContainer;
  private _canvasShiftDataSet: GanttCanvasShift[] = [];
  private _canvasYAxisDataSet: GanttCanvasRow[] = [];

  private _rowHeightStorage: GanttRowHeightStorage;
  private readonly _shiftRowStorage = new Map<string, string>();

  private _dataEditor: DataEditor;
  private _canvasDataEditor: CanvasDataEditor;
  private _dataAdder: DataAdder;
  private _dataRemover: DataRemover;

  callBack: any;

  /**
   * @param originDataSet Gantt origin dataset.
   * @param xAxisScale D3 function for scaling x axis.
   * @param config Gantt config.
   */
  constructor(originDataSet: GanttDataContainer, xAxisScale: d3.ScaleTime<number, number>, config: GanttConfig) {
    this._originDataSet = originDataSet;
    this._xAxisScale = xAxisScale;
    this._rowHeightStorage = new GanttRowHeightStorage(config);

    this._config = config;

    this.callBack = {
      setShiftDataSet: {},
      updateCanvasShiftData: {},
      beforeYAxisDataMapping: {},
      beforeShiftDataMapping: {},
    };

    this._dataEditor = new DataEditor(this._originDataSet);
    this._canvasDataEditor = new CanvasDataEditor(this);
    this._dataAdder = new DataAdder(this._originDataSet);
    this._dataRemover = new DataRemover(this._originDataSet);
  }

  //
  // INIT FUNCTIONS
  //

  /**
   * Initialize canvas shift data based on (global) dataset.
   * @keywords data, dataset, canvas, shift
   */
  public initCanvasShiftData(): void {
    this._shiftRowStorage.clear();
    GanttCallBackStackExecuter.execute(this.callBack.beforeShiftDataMapping, null);
    this.setShiftDataset(
      ShiftDataFinder.getCanvasShiftData(
        this._originDataSet.ganttEntries,
        this._xAxisScale,
        this._config.getLineTop(),
        this._config.getLineBottom(),
        this.getRowHeightStorage(),
        this._shiftRowStorage
      )
    );
  }

  /**
   * Initialize canvas y axis data based on (global) dataset
   * @keywords data, dataset, canvas, init, yaxis, y axis
   */
  public initCanvasYAxisData(): void {
    this.setYAxisDataset(YAxisDataMapper.getCanvasYAxisData(this._originDataSet.ganttEntries, this));
  }

  //
  // EDITOR FUNCTIONS
  //

  /**
   * Updates properties of shift in origin data by shift id.
   *
   * @param {GanttUpdateObjectData|GanttUpdateObjectData[]} shiftUpdateData Data structure with id and properties.
   * @param {boolean} [addUnknownProperties] Optional bool to allow adding properties that are not yet present in shift. Forbidden by default.
   * @returns {boolean} If successful, returns true, false otherwise;
   */
  updateShiftPropertiesInOriginDataByShiftId(shiftUpdateData, addUnknownProperties = false) {
    this._dataEditor.updateShiftPropertiesInOriginDataByShiftId(shiftUpdateData, addUnknownProperties);
  }

  /**
   * Updates shift properties of given shift in origin dataset.
   *
   * @param {*} shift Shift reference of origin dataset.
   * @param {*} properties Properties to be changed. For example: {color: red, opacity: 0.8}
   */
  updateShiftPropertiesInOriginDataByShift(shift, properties) {
    this._dataEditor.updateShiftPropertiesInOriginDataByShift(shift, properties);
  }

  updateCanvasShiftPropertiesInCanvasDataByShiftId(
    shiftUpdateData: Map<string, Partial<GanttCanvasShift>>,
    addUnknownProperties = false
  ) {
    this._canvasDataEditor.editCanvasShiftById(shiftUpdateData, addUnknownProperties);
  }

  /**
   * Updates properties of row in origin data by row id.
   *
   * @param {GanttUpdateObjectData|GanttUpdateObjectData[]} rowUpdateData Data structure with id and properties.
   * @param {boolean} [addUnknownProperties] Optional bool to allow adding properties that are not yet present in row. Forbidden by default.
   * @returns {boolean} If successful, returns true, false otherwise;
   */
  updateRowPropertiesInOriginDataByRowId(rowUpdateData, addUnknownProperties = false) {
    this._dataEditor.updateRowPropertiesInOriginDataByRowId(rowUpdateData, addUnknownProperties);
  }

  /**
   * Adds new child shifts to canvas shift dataset. Used during opening rows.
   * @private
   * @param {FoundRowData} parentInfo Row data.
   * @param {number} parentY Vertical position of row.
   */
  _addShiftItemsByParentPosition(parentInfo, parentY, idsNewRenderedRowsAfterOpenParentRow) {
    const s = this;
    const rowId = parentInfo.data.id;
    const rowHeight = s.getRowHeightStorage().getRowHeightById(rowId);
    let renderedRowsHeight = 0;
    idsNewRenderedRowsAfterOpenParentRow.forEach(
      (id) => (renderedRowsHeight += s.getRowHeightStorage().getRowHeightById(id))
    );

    const newShifts = s._getCanvasShiftsByParentRow(rowId, parentY);
    s.setShiftDataset(s._changeYValue(s.getCanvasShiftDataset(), parentY + rowHeight, renderedRowsHeight));
    s.setShiftDataset(s.insertNewElementsIntoDataSet(newShifts, s.getCanvasShiftDataset()));
  }

  /**
   * Returns canvas shifts from childs of given parent row.
   * @keywords get, shift, data, dataset, canvas, parent, search, find
   * @param {string} parentId Row id.
   * @param {number} parentY Vertical position of row.
   * @return {GanttCanvasShift[]} Canvas shifts.
   */
  private _getCanvasShiftsByParentRow(parentId: string, parentY: number): GanttCanvasShift[] {
    const rowHeight = this.getRowHeightStorage().getRowHeightById(parentId);

    const parentInfo = YAxisDataFinder.getRowById(this._originDataSet.ganttEntries, parentId);
    return ShiftDataFinder.getNewShiftsByParent(
      parentInfo.data,
      parentY + rowHeight,
      parentInfo.depth,
      this._xAxisScale,
      this._config.getLineTop(),
      this._config.getLineBottom(),
      this.getRowHeightStorage()
    );
  }

  /**
   * Adds new child y axis rows to canvas row dataset. Used during opening rows.
   * @private
   * @param {FoundRowData} parentInfo Row data.
   * @param {number} parentY Vertical position of row.
   */
  _addYAxisItemsByParentPosition(parentInfo, parentY, idsNewRenderedRowsAfterOpenParentRow) {
    const s = this;
    const rowId = parentInfo.data.id;
    const rowHeight = s.getRowHeightStorage().getRowHeightById(rowId);
    let renderedRowsHeight = 0;
    idsNewRenderedRowsAfterOpenParentRow.forEach(
      (id) => (renderedRowsHeight += s.getRowHeightStorage().getRowHeightById(id))
    );

    const newRows = s.getNewYAxisItemsByParentPosition(parentInfo.data.id, parentY);
    s.setYAxisDataset(s._changeYValue(s.getYAxisDataset(), parentY + rowHeight, renderedRowsHeight));
    s.setYAxisDataset(s.insertNewElementsIntoDataSet(newRows, s.getYAxisDataset()));
  }

  /**
   * Returns canvas rows by all childs of a given parent row.
   * @keywords yaxis, y axis, parent, canvas, row, child, new
   * @param {string} parentId Row id.
   * @param {number} parentY Vertical position of row.
   * @return {GanttCanvasRow[]} Canvas shifts.
   */
  getNewYAxisItemsByParentPosition(parentId, parentY) {
    const s = this;
    const rowHeight = s.getRowHeightStorage().getRowHeightById(parentId);
    const parentInfo = YAxisDataFinder.getRowById(s._originDataSet.ganttEntries, parentId);
    return s.getNewRowsByParent(parentInfo.data, parentY + rowHeight, parentInfo.depth);
  }

  /**
   * Removes all elements between two y positions from dataset.
   * @keywords remove, yaxis, y axis, yposition, range, start, end, vertical
   * @param {number} yStart Start of range.
   * @param {number} yEnd End of range.
   * @param {Array.<Object>} dataSet Canvas dataset filled with objects which has y property.
   * @return {number} Number of deleted children.
   */
  removeElementsByYRange(yStart, yEnd, dataSet) {
    const s = this;
    if (!dataSet) return 0;

    let currentY = -1;
    let removingChildCounter = 0;

    for (let i = 0; i < dataSet.length; i++) {
      const shift = dataSet[i];
      if (shift.y > yStart && shift.y <= yEnd) {
        dataSet.splice(i, 1);
        i--;

        if (shift.y != currentY) {
          currentY = shift.y;
          removingChildCounter++;
        }
      }
    }
    return removingChildCounter;
  }

  /**
   * Removes all child rows.
   * @keywords remove, yaxis, y axis, rows, child
   * @param parentY Vertical position of row.
   * @param parentDepth Level of row.
   * @param dataSet
   * @return Ids of removed children.
   */
  public removeAllChildren(parentY: number, parentDepth: number, dataSet: GanttCanvasRow[] = undefined): string[] {
    let currentY = -1;
    const removingChildren: string[] = [];
    let breakFlag = false;

    let data = this.getYAxisDataset();
    if (dataSet) data = dataSet;

    if (!data) return removingChildren;

    for (let i = 0; i < data.length; i++) {
      const row = data[i];
      if (row.y > parentY && row.layer > parentDepth) {
        breakFlag = true;
        if (row.y != currentY) {
          currentY = row.y;
          removingChildren.push(row.id);
        }
      } else if (breakFlag) break;
    }

    return removingChildren;
  }

  /**
   * Remove shifts by parent y position and remove count.
   * @keywords remove, shift, yposition, y, child
   * @param {number} parentY Vertical position of row.
   * @param {number[]} removedChildren Ids of deleted children.
   * @param {string} parentRowId Id of parent row
   */
  removeAllShiftChildren(parentY, removedChildren, parentRowId) {
    const s = this;
    const parentRowHeight = s.getRowHeightStorage().getRowHeightById(parentRowId);
    let removedRowsHeight = 0;
    removedChildren.forEach((id) => (removedRowsHeight += s.getRowHeightStorage().getRowHeightById(id)));

    const removedChildCount = s.removeElementsByYRange(parentY, parentY + removedRowsHeight, s.getCanvasShiftDataset());
    if (!removedChildren.length) {
      // removedChildren = removedChildCount;
      // removedChildren.forEach(id => removedRowsHeight += s.getRowHeightStorage().getRowHeightById(id));
    }
    s._changeYValue(s.getCanvasShiftDataset(), parentY + parentRowHeight, -removedRowsHeight);
  }

  /**
   * Remove y axs rows by parent y position and remove count.
   * @keywords yposition, row, child, yaxis, y axis, remove
   * @param {number} parentY Vertical position of row.
   * @param {number} removedChilds Count of deleted Childs
   */
  removeAllYAxisChildren(parentY, removedChildren, parentRowId) {
    const s = this;
    const parentRowHeight = s.getRowHeightStorage().getRowHeightById(parentRowId);
    let removedRowsHeight = 0;
    removedChildren.forEach((id) => (removedRowsHeight += s.getRowHeightStorage().getRowHeightById(id)));
    s.removeElementsByYRange(parentY, parentY + removedRowsHeight, s.getYAxisDataset());
    s._changeYValue(s.getYAxisDataset(), parentY + parentRowHeight, -removedRowsHeight);
  }

  /**
   * Creates canvas rows by all childs of a parent row.
   * @keywords canvas, row, child, create, new, add
   * @param {GanttDataRow} parentData Data of parent row.
   * @param {number} parentY Vertical position of row.
   * @param {number} parentDepth Level of row.
   * @return {GanttDataRow[]} All childs of parent row.
   */
  getNewRowsByParent(parentData, parentY, parentDepth) {
    const s = this;
    let cntNewRow = 0;

    const rows = [];
    for (let i = 0; i < parentData.child.length; i++) {
      if (!parentData.child[i].noRender || !parentData.child[i].noRender.length) {
        // if row is not to be rendered
        const child = parentData.child[i];
        const rowHeight = s._config.rowHeight();
        const newRow: GanttCanvasRow = {
          id: child.id,
          height: rowHeight,
          y: parentY + cntNewRow * rowHeight,
          text: child.name,
          subtitleElements: child.subtitleElements,
          layer: parentDepth,
          leaf: child.child.length == 0,
          group: child.group || 'NO-GROUP',
          color: child.color,
          stroke: child.stroke,
          textColor: child.textColor,
          tooltip: child.tooltip,
          noRender: child.noRender ? child.noRender.slice() : [],
          allowedEntryTypes: child.allowedEntryTypes,
          sampleValues: child.sampleValues,
          startCellIndexForSampleValues: child.startCellIndexForSampleValues,
        };

        rows.push(newRow);
        cntNewRow++;
      }
    }
    return rows;
  }

  /**
   * Increase all y coordinates of canvas elements by value
   * @private
   * @param {Array.<Object>} canvasDataSet Canvas dataset filled with objects which has y property.
   * @param {number} yStart Start of increasing.
   * @param {number} increaseValue Value to increase.
   * @return {Array.<Object>} Updates dataset.
   */
  _changeYValue(canvasDataSet, yStart, increaseValue) {
    if (!canvasDataSet || canvasDataSet.length == 0) return [];

    for (let i = 0; i < canvasDataSet.length; i++) {
      const shift = canvasDataSet[i];

      if (shift.y >= yStart) shift.y += increaseValue;
    }
    return canvasDataSet;
  }

  /**
   * Merges a dataset of canvas elements into another dataset by y position.
   * @keywords merge, data, dataset, manipulation, y, yposition, yaxis, y axis
   * @param {Array.<Object>} newShiftDataSet All canvas elements which should be merged into other dataset.
   * @param {Array.<Object>} dataSet Canvas dataset filled with objects which has y property. Has to be sorted by y.
   * @return {Array.<Object>} Merged dataset.
   */
  insertNewElementsIntoDataSet(newShiftDataSet, dataSet) {
    if (!newShiftDataSet || newShiftDataSet.length == 0) return dataSet;

    const yStart = newShiftDataSet[0].y;

    // if dataSet is empty
    if (dataSet.length == 0) {
      dataSet = newShiftDataSet;
      return dataSet;
    }

    // if newShiftDataSet has only elements bigger then dataSet
    if (dataSet[dataSet.length - 1].y < newShiftDataSet[0].y) {
      dataSet = d3.merge([dataSet, newShiftDataSet]);
      return dataSet;
    }

    for (let i = 0; i < dataSet.length; i++) {
      if (dataSet[i].y > yStart) {
        dataSet.splice(i, 0, ...newShiftDataSet);
        break;
      }
    }
    return dataSet;
  }

  /**
   * Close all rows of gantt diagram in origin data.
   */
  collapseAllRows() {
    const s = this;
    const closeRow = function (row) {
      s._changeOpenState(row, false);
    };

    DataManipulator.iterateOverDataSet(this._originDataSet.ganttEntries, { collapseAllRows: closeRow });
  }

  /**
   * Expands all rows of gantt diagram in origin data.
   */
  expandAllRows() {
    const s = this;
    const openRow = function (row) {
      s._changeOpenState(row, true);
    };

    DataManipulator.iterateOverDataSet(this._originDataSet.ganttEntries, { expandAllRows: openRow });
  }

  /**
   * Facade for all data handling which happens if user opens row.
   * @keywords open, row, data, dataset, manipulation
   * @param {string} elementId Id of row.
   * @param {number} elementY Vertical Position of row.
   */
  openShiftRow(elementId, elementY) {
    const s = this;

    const parentInfo = YAxisDataFinder.getRowById(s._originDataSet.ganttEntries, elementId);
    // set open-flag to true
    s._changeOpenState(parentInfo.data, true);

    if (parentInfo.data.group == 'MEMBER') {
      const originalRow = YAxisDataFinder.getRowById(s._originDataSet.ganttEntries, parentInfo.data.originalResource);
      s._changeOpenState(originalRow.data, true);
    }

    const idsNewRenderedRowsAfterOpenParentRow = YAxisDataFinder.getIdsOfNewRenderedRowsAfterOpenParentRow(
      parentInfo.data
    );

    // add shift and yaxis items
    s._addShiftItemsByParentPosition(parentInfo, elementY, idsNewRenderedRowsAfterOpenParentRow);
    s._addYAxisItemsByParentPosition(parentInfo, elementY, idsNewRenderedRowsAfterOpenParentRow);
  }

  /**
   * Facade for all data handling which happens if user closes row.
   * @keywords close, row, data, dataset, manipulation
   * @param {string} elementId Id of row.
   * @param {number} elementY Vertical Position of row.
   */
  closeShiftRow(elementId, elementY) {
    const s = this;
    const parentInfo = YAxisDataFinder.getRowById(s._originDataSet.ganttEntries, elementId).data;

    const closedRowIds = YAxisDataFinder.getIdsOfNewRenderedRowsAfterOpenParentRow(parentInfo);

    // set close-property to all childdata recursively
    s._changeOpenState(parentInfo, false);

    if (parentInfo.group == 'MEMBER') {
      const originalRow = YAxisDataFinder.getRowById(s._originDataSet.ganttEntries, parentInfo.originalResource);
      s._changeOpenState(originalRow.data, false);
    }

    const close = function (child) {
      if (child.open && child.child) closedRowIds.length += child.child.length;
      s._changeOpenState(child, false);
    };
    DataManipulator.iterateOverDataSet([parentInfo], { close: close });

    // do not change the order of these functions,
    // removeAllYAxisChildren has to be the last function
    s.removeAllShiftChildren(elementY, closedRowIds, elementId);
    s.removeAllYAxisChildren(elementY, closedRowIds, elementId);
  }

  /**
   * Get all shifts of a row recursively.
   * @kewords all shifts, shifts, row, children, get, iterate, dataset
   * @param {GanttDataRow} dataSet Row data element (which possibly contains childs).
   * @return {GanttDataRow[]} All shifts inside given dataset.
   */
  getAllShifts(dataSet) {
    const s = this;

    const allShifts = [];

    const findAllShifts = function (row) {
      for (let i = 0; i < row.shifts.length; i++) {
        allShifts.push(row.shifts[i]);
      }
    };

    DataManipulator.iterateOverDataSet(dataSet, { findAllShifts: findAllShifts });
    return allShifts;
  }

  /**
   * Changes state of row.
   * @private
   * @param {GanttDataRow} childObject Row with open property.
   * @param {boolean} bool Sets status.
   */
  _changeOpenState(childObject, bool) {
    const s = this;
    childObject.open = bool;
  }

  /**
   * Returns true if a row is hidden because its not in the canvasRowDataset.
   * That means the Parent Rows of this row need to be opened to make it not hidden.
   * @param {String} rowId RowId
   * @return {boolean} true if row is hidden (not in canvasRowDataset)
   */
  isRowHidden(rowId) {
    const s = this;
    const foundCanvasRow = s.getYAxisDataset().find((row) => row.id === rowId);
    return !!foundCanvasRow;
  }

  /**
   * Opens that rows (recursively) which ids are inside idList.
   * @keywords open, row, rows
   * @param {string[]} idList List of row ids.
   */
  openRowsByIdList(idList) {
    const s = this;
    const openRowById = function (child) {
      if (idList.indexOf(child.id) != -1) {
        child.open = true;
      }
    };
    DataManipulator.iterateOverDataSet(s._originDataSet.ganttEntries, { openRowById: openRowById });
  }

  /**
   * Finds the (recursive) parents of the provided rowIds.
   * Returns list of all parentRows with no duplicates.
   * @keywords open, row, rows
   * @param {String[]|String} rowIds RowIds or just one rowId
   */
  getAllParentsOfRowsByIds(rowIds) {
    const s = this;

    if (!Array.isArray(rowIds)) {
      rowIds = [rowIds];
    }

    const parentRowIds = [];

    for (const rowId of rowIds) {
      parentRowIds.push(...YAxisDataFinder.getAllParentsOfRowById(s.getOriginDataset().ganttEntries, rowId));
    }

    const parentRowIdsRemovedDuplicates = Array.from(new Set(parentRowIds));

    return parentRowIdsRemovedDuplicates;
  }

  /**
   * Sets editable flag to all given shifts.
   */
  setShiftsEditable(shiftIds: string[], editable: boolean): void {
    const s = this;
    const canvasDataSet = s.getCanvasShiftDataset();
    const originDataSet = s.getOriginDataset().ganttEntries;

    if (Array.isArray(shiftIds) && shiftIds.length) {
      // update origin data (to be consistent the canvas data)
      const shifts = ShiftDataFinder.getShiftsByIds(originDataSet, shiftIds);
      shifts.forEach((shift) => (shift.shift.editable = editable));

      // update canvas data
      const canvasShifts = canvasDataSet.filter((shift) => shiftIds.includes(shift.id));
      canvasShifts.forEach((shift) => (shift.editable = editable));
    }
  }

  //
  // GETTER & SETTER
  //

  /**
   * Returns the state storage of this data handler.
   * @returns State storage of this data handler.
   */
  public getStateStorage(): DataStateStorage {
    return this._stateStorage;
  }

  getOriginDataset() {
    return this._originDataSet;
  }

  public setOriginDataset(dataSet: GanttDataContainer): void {
    this._originDataSet = dataSet;
  }

  public getCanvasShiftDataset(): GanttCanvasShift[] {
    return this._canvasShiftDataSet;
  }

  public getCanvasShiftDataMap(): Map<string, GanttCanvasShift> {
    const map = new Map<string, GanttCanvasShift>();
    this.getCanvasShiftDataset().forEach((shift) => {
      map.set(shift.id, shift);
    });
    return map;
  }

  public setShiftDataset(dataSet: GanttCanvasShift[]): void {
    this._canvasShiftDataSet = dataSet;
    GanttCallBackStackExecuter.execute(this.callBack.setShiftDataSet, this._canvasShiftDataSet);
  }

  public getYAxisDataset(): GanttCanvasRow[] {
    return this._canvasYAxisDataSet;
  }

  public setYAxisDataset(dataSet: GanttCanvasRow[]): void {
    this._canvasYAxisDataSet = dataSet;
    this._stateStorage.update(null, this._canvasYAxisDataSet, null);
  }

  getRowHeightStorage() {
    return this._rowHeightStorage;
  }

  public getShiftRowStorage(): Map<string, string> {
    return this._shiftRowStorage;
  }

  getScale() {
    return this._xAxisScale;
  }

  setScale(d3Scale) {
    this._xAxisScale = d3Scale;
  }

  //
  // CALLBACKS
  //

  subscribeSetShiftDataSet(id, func) {
    this.callBack.setShiftDataSet[id] = func;
  }

  unSubscribeSetShiftDataSet(id) {
    delete this.callBack.setShiftDataSet[id];
  }

  subscribeCanvasShiftDataUpdates(id, func: (updateData: Map<string, Partial<GanttCanvasShift>>) => void) {
    this.callBack.updateCanvasShiftData[id] = func;
  }

  unSubscribeCanvasShiftDataUpdates(id) {
    delete this.callBack.updateCanvasShiftData[id];
  }

  subscribeBeforeYAxisDataMapping(id, func) {
    this.callBack.beforeYAxisDataMapping[id] = func;
  }

  unSubscribeBeforeYAxisDataMapping(id) {
    delete this.callBack.beforeYAxisDataMapping[id];
  }

  subscribeBeforeShiftDataMapping(id, func) {
    this.callBack.beforeShiftDataMapping[id] = func;
  }

  unSubscribeBeforeShiftDataMapping(id) {
    delete this.callBack.beforeShiftDataMapping[id];
  }

  getDataAdder() {
    return this._dataAdder;
  }

  getDataEditor() {
    return this._dataEditor;
  }

  getDataRemover() {
    return this._dataRemover;
  }

  /**
   * Returns Array of IDs for all rows currently open.
   * @return {String[]} IDs of rows that are open.
   */
  getOpenChilds() {
    const s = this;
    return YAxisDataFinder.getAllOpenChilds(s._originDataSet.ganttEntries);
  }

  /**
   * Returns Array of GanttDataRow(s) for all rows currently open.
   * @returns {GanttDataRow[]} RowData of rows that are open.
   */
  getOpenChildrenDataRows() {
    const s = this;
    return YAxisDataFinder.getAllOpenChildrenDataRows(s._originDataSet.ganttEntries);
  }
}

/**
 * (Not implemented yet)
 * Row symbol.
 * @interface
 */
export interface GanttDataSymbol {
  iconName: string;
  tooltip: string;
}

/**
 * Data structure for updating properties.
 * @keywords update, datastructure, properties
 * @interface
 */
export interface GanttUpdateObjectData {
  id: string;
  properties: any;
}
