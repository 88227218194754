import { Injectable } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { TranslateService } from '@ngx-translate/core';
import { Moment } from 'moment';

@Injectable()
export class CustomDateAdapter extends MomentDateAdapter {
  private lang: string;
  constructor(private translate: TranslateService) {
    super('de-DE'); // set default locale
    this.lang = this.translate.instant('LOCALIZATION.SHORT');
    this.setLocale(this.translate.instant('LOCALIZATION.LONG'));
    this.translate.onLangChange.subscribe((key) => {
      this.lang = this.translate.instant('LOCALIZATION.SHORT');
      this.setLocale(this.translate.instant('LOCALIZATION.LONG'));
    });
  }

  public format(date: Moment, displayFormat: string): string {
    return date.locale(this.lang).format(this.translate.instant(`DATE.NGX.date`));
  }

  ngOnDestroy(): void {
    console.error('DESTRUCT THIS HERE');
  }
}
