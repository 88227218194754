<div
  cdkDragRootElement=".cdk-overlay-pane"
  (cdkDragEnded)="onDragEnded($event)"
  (cdkDragStarted)="onDragStarted($event)"
  cdkDrag
  (mouseenter)="onEnter($event)"
  (mouseleave)="onLeave($event)"
  class="message-wrapper mat-elevation-z8"
  [ngClass]="message.getType()"
>
  <div class="content main" #imageContainer>
    <div class="text" [style.font-size]="fontSize">
      {{
        message.getParams() ? (message.getText() | translate : message.getParams()) : (message.getText() | translate)
      }}
    </div>
  </div>
  <div class="content links">
    <div class="link">
      <app-button [data]="closeBtn"></app-button>
    </div>
    <div class="link" *ngIf="menuBtn.getMenu()?.getContextMenuItems()?.length > 0">
      <app-button [data]="menuBtn"></app-button>
    </div>
  </div>
</div>
