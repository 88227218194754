import { YAxisDataFinder } from '../data-finder/yaxis-data-finder';
import { GanttCanvasShift, GanttDataContainer, GanttDataRow } from '../data-structure/data-structure';
import { DataManipulator } from './data-manipulator';

/**
 * This class handles data sorting.
 * @keywords sort, data, dataset, helper, calculation, alphabetic
 */
export abstract class ShiftDataSorting {
  /**
   * Sorts shifts of row by start time point.
   * @keywords sort, origin, data, dataset, shifts, row, start, time
   * @param {GanttDataRow[]} originData Origin dataset.
   * @param {string} rowId Id of row of which shifts should be sorted.
   */
  static sortOriginShiftsByRowId(originData, rowId) {
    const foundRow = YAxisDataFinder.getRowById(originData, rowId);
    if (!foundRow.data) return;

    ShiftDataSorting.sortJSONListByDate(foundRow.data.shifts, 'timePointStart');
  }

  /**
   * Sorts all shifts inside origin dataset by time point start.
   * @keywords sort, origin, data, dataset, shifts, row, start, time
   * @param {GanttDataRow[]} originData Origin dataset.
   */
  static sortOriginShifts(originData) {
    const sortFunction = function (child) {
      if (child.shifts) ShiftDataSorting.sortJSONListByDate(child.shifts, 'timePointStart');
    };
    DataManipulator.iterateOverDataSet(originData, { filter: sortFunction });
  }

  /**
   * Sorts all canvas shifts inside origin dataset by y.
   * @keywords sort, canvas, shifts, row, y, y position
   * @param {GanttCanvasShift[]} canvasShiftData Origin dataset.
   */
  static sortCanvasShiftsByY(canvasShiftData) {
    ShiftDataSorting.sortJSONListByNumber(canvasShiftData, 'y');
  }

  /**
   * Sorts all canvas shifts with the given y value in the given dataset to the right position.
   * @param shiftCanvasDataset Dataset with canvas shift data to be sorted.
   * @param yValue Y value of the canvas shift data to be sorted.
   * @returns Reference to the dataset containing the sorted canvas shift data.
   */
  public static sortShiftCanvasByYValue(shiftCanvasDataset: GanttCanvasShift[], yValue: number): GanttCanvasShift[] {
    const extractedShifts = [];
    // 1. extract and remove all canvas shifts with given y value
    for (let i = 0; i < shiftCanvasDataset.length; i++) {
      const canvasShift = shiftCanvasDataset[i];
      if (canvasShift.y === yValue) {
        extractedShifts.push(canvasShift);
        shiftCanvasDataset.splice(i, 1);
        i--;
      }
    }
    if (extractedShifts.length == 0) return;
    // 2. sort extracted shifts
    extractedShifts.sort(function (a, b) {
      return a.x - b.x;
    });
    // 3. insert sorted canvas shifts to correct position
    const firstShiftIndexBiggerY = shiftCanvasDataset.findIndex(function (canvasShift) {
      return canvasShift.y > yValue;
    });
    if (firstShiftIndexBiggerY == -1) {
      shiftCanvasDataset.push(...extractedShifts);
    } else if (firstShiftIndexBiggerY == 0) {
      shiftCanvasDataset.unshift(...extractedShifts);
    } else {
      shiftCanvasDataset.splice(firstShiftIndexBiggerY - 1, 0, ...extractedShifts);
    }
  }

  /**
   * Sorts a JSON list by a property which has a date as value.
   * @keywords sort, canvas, shifts, row, date, time, timepoint
   * @param jsonList List of JSON objects
   * @param timeProperty Property of objects in JSON list which has a date as value.
   * @param altStringProperty String property of objects in JSON list which will be used to sort objects with the same date value.
   * @returns Sorted JSON list.
   */
  public static sortJSONListByDate<T extends object>(
    jsonList: T[],
    timeProperty: keyof T,
    altStringProperty: keyof T = null
  ): T[] {
    if (!jsonList || jsonList.length <= 0) return [];
    if (jsonList.length === 1) return jsonList;

    let diff: number;
    return jsonList.sort(function (a, b) {
      diff = (a[timeProperty] as Date).getTime() - (b[timeProperty] as Date).getTime();
      if (altStringProperty && diff === 0)
        diff = (a[altStringProperty] as string).localeCompare(b[altStringProperty] as string);
      return diff;
    });
  }

  /**
   * Sorts a JSON list by a property which has a number as value.
   * @keywords sort, json, number, int
   * @param jsonList List of JSON objects
   * @param numberProperty Property of objects in JSON list which has a number as value.
   */
  public static sortJSONListByNumber<T extends object>(jsonList: T[], numberProperty: keyof T): void {
    jsonList.sort(function (a, b) {
      return (a[numberProperty] as number) - (b[numberProperty] as number);
    });
  }

  /**
   * Returns biggest date of JSON list.
   * @param jsonArray JSON list.
   * @param property Name of JSON property wihchh has a Date as value.
   * @return Biggest date of JSON list.
   */
  public static getBiggestDateValue<T extends object>(jsonArray: T[], property: keyof T): T {
    let biggestValue = this.sortJSONListByDate(jsonArray, property)[jsonArray.length - 1];
    if (!biggestValue) biggestValue = null;
    return biggestValue;
  }

  /**
   * Returns smallest date of JSON list.
   * @param jsonArray JSON list.
   * @param property Name of JSON property wihch has a Date as value.
   * @return Smallest date of JSON list.
   */
  public static getSmallestDateValue<T extends object>(jsonArray: T[], property: keyof T): T {
    let smallestValue = this.sortJSONListByDate(jsonArray, property)[0];
    if (!smallestValue) smallestValue = null;
    return smallestValue;
  }

  /**
   * Sorts all rows alphabetically inside their parent, starting on the specified hierachical level.
   * @param {GanttDataContainer} dataSet Unsorted dataset.
   * @param {EGanttDataSortingOrder} [order="ASC"] Sorting order.
   * @param {number} [startLevel=0] Hierachical level from where this method should sort all rows recursively.
   * @param {EGanttDataSortingChildRowStrategy} [childRowStrategy="NONE"] Specifies how to handle rows with child rows.
   */
  public static sortRowsAlphabeticallyByName(
    dataSet: GanttDataContainer,
    order: EGanttDataSortingOrder = EGanttDataSortingOrder.ASC,
    startLevel = 0,
    childRowStrategy = EGanttDataSortingChildRowStrategy.NONE
  ): void {
    if (!dataSet.ganttEntries || dataSet.ganttEntries.length <= 0) return;

    const sortRows = function (
      rows: GanttDataRow[],
      order: EGanttDataSortingOrder,
      startLevel: number,
      childRowStrategy: EGanttDataSortingChildRowStrategy
    ): void {
      if (!rows || rows.length <= 0) return;
      const orderFactor = order === EGanttDataSortingOrder.DESC ? -1 : 1;

      if (startLevel <= 0) {
        rows.sort((a, b) => {
          const aHasChilds = a.child && a.child.length > 0;
          const bHasChilds = b.child && b.child.length > 0;
          if (childRowStrategy !== 0) {
            if (aHasChilds && !bHasChilds) return -1 * childRowStrategy;
            if (bHasChilds && !aHasChilds) return 1 * childRowStrategy;
          }
          return a.name.localeCompare(b.name) * orderFactor;
        });
      }

      for (const row of rows) {
        if (row.child) sortRows(row.child, order, startLevel > 0 ? startLevel - 1 : 0, childRowStrategy);
      }
    };
    sortRows(dataSet.ganttEntries, order, startLevel, childRowStrategy);
  }

  /**
   * Sorts all rows by a custom order.
   * @param {GanttDataContainer} dataSet Unsorted dataset.
   * @param {IGanttDataSortingRowOrderEntry[]} customOrder Custom sorting order (tree structure of row ids).
   */
  public static sortRowsByCustomOrder(
    dataSet: GanttDataContainer,
    customOrder: IGanttDataSortingRowOrderEntry[]
  ): void {
    const sortRows = function (rows: GanttDataRow[], order: IGanttDataSortingRowOrderEntry[]): void {
      if (!rows || rows.length <= 0 || !order || order.length <= 0) return;
      if (order.length !== rows.length)
        console.warn('Array lengths do not match. The sorting may not perform correctly.');

      rows.sort((a, b) => {
        const ia = order.indexOf(order.find((e) => e.id === a.id));
        const ib = order.indexOf(order.find((e) => e.id === b.id));
        return ia - ib;
      });

      for (let i = 0; i < rows.length; i++) {
        if (rows[i].child && rows[i].child.length >= 0 && order[i].child && order[i].child.length >= 0) {
          sortRows(rows[i].child, order[i].child);
        }
      }
    };
    sortRows(dataSet.ganttEntries, customOrder);
  }
}

export interface IGanttDataSortingRowOrderEntry {
  id: string;
  child: IGanttDataSortingRowOrderEntry[];
}

export enum EGanttDataSortingOrder {
  ASC = 'ASC', // ascending
  DESC = 'DESC', // descending
}

export enum EGanttDataSortingChildRowStrategy {
  NONE = 0, // don't group parents with childs
  GROUP_TOP = 1, // group parents with childs on top of parents without childs
  GROUP_BOTTOM = -1, // group parents with childs on bottom of parents without childs
}
