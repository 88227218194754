import { Injectable, Type } from '@angular/core';
import { TemplateAdapter } from '@app-modeleditor/utils/template-factory.service';
import { EDisplayOrientation } from '../content/content-element/display-orientation.enum';
import { StructuralElement } from '../content/structural-element';
import { Adapter } from '../../../core/adapter';
import { TemplateResourceAdapter } from './../../utils/template-resource-factory.service';
import { LayoutFactoryService } from './layout-factory.service';

@Injectable({
  providedIn: 'root',
})
export class StructuralAdapter implements Adapter<StructuralElement> {
  constructor(private templateResourceFactory: TemplateResourceAdapter, private layoutFactory: LayoutFactoryService) {}

  adapt(item: any): StructuralElement {
    throw new Error('Method not implemented.');
  }

  inheritFrom<T extends StructuralElement>(scope: TemplateAdapter, type: Type<T>, item: any): T {
    if (!item) {
      return null;
    }
    const t: T = this.templateResourceFactory
      .inheritFrom<T>(scope, type, item)
      .setDisplayOrientation(item.displayOrientation || EDisplayOrientation.HORIZONTAL)
      // .setLayout(new Layout().parseIn(data.layout))
      .setFullscreen(item.fullscreen);

    if (item.layout) {
      t.setLayout(this.layoutFactory.getLayout(item.layout));
    }
    return t;
  }

  applyValues<T extends StructuralElement>(scope: TemplateAdapter, item: T, values: any): T {
    throw new Error('Method not implemented.');
  }

  public inherit<T extends StructuralElement>(type: Type<T>, data: any): T {
    if (!data) {
      return null;
    }
    const t: T = this.templateResourceFactory
      .inherit<T>(type, data)
      .setDisplayOrientation(data.displayOrientation || EDisplayOrientation.HORIZONTAL)
      // .setLayout(new Layout().parseIn(data.layout))
      .setFullscreen(data.fullscreen);

    if (data.layout) {
      t.setLayout(this.layoutFactory.getLayout(data.layout));
    }
    return t;
  }
}
