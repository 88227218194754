import { DEFAULT_NUMERIC_INPUT_MAX_CHARACTERS, DEFAULT_STEP } from './parsing-constants';
import ParsingStrategy from './parsing-strategy';
import { correctStepRaster, isIncorrectStepRaster, saveAdd } from './parsing-utils';

abstract class NumberParsing implements ParsingStrategy<number> {
  private _max = Infinity;
  private _min = -Infinity;
  private _explicitStep = undefined;
  private _maxCharacters = DEFAULT_NUMERIC_INPUT_MAX_CHARACTERS;

  /**
   * Init parsing by some constants.
   * @param param0 bounds of accepted values (min, max), step for decrement increment, maxCharacters no matter if before or after comma
   */
  public init({
    min,
    max,
    step,
    maxCharacters,
  }: {
    min?: number;
    max?: number;
    step?: number;
    maxCharacters?: number;
  }) {
    if (isFinite(min)) {
      this._min = min;
    }
    if (isFinite(max)) {
      this._max = max;
    }
    if (isFinite(step) && step !== 0) {
      this._explicitStep = step;
    }
    if (isFinite(maxCharacters)) {
      this._maxCharacters = maxCharacters;
    }
  }

  public correctOnTheFly(input?: string, fallbackInput?: string, correctValue = true): string {
    // Will be used by overriding method in child class
    return (input?.length || 0) < this.maxCharacters || fallbackInput === undefined ? input : fallbackInput;
  }

  public abstract inputToValue(input?: string): number | undefined;
  public abstract valueToInput(value: number): string;

  protected finiteOrUndefined(value?: number): number | undefined {
    return isFinite(value) ? value : undefined;
  }

  /**
   * Validates input if it is between bounds.
   * It returns error object, e.g. { max: 4 } if this is not the case.
   * If validation passed it will return undefined.
   * @param input
   * @returns
   */
  public validate(input?: string): { [x: string]: any } | undefined {
    if (input === undefined) return undefined;
    const value = this.inputToValue(input);
    if (value < this.min) {
      return { min: this.valueToInput(this.min) };
    }
    if (value > this.max) {
      return { max: this.valueToInput(this.max) };
    }
    if (this.isExplicitStep && isIncorrectStepRaster(value, this.step)) {
      return { step: this.valueToInput(this.step) };
    }
    return undefined;
  }

  public inputToClipboardValue(input: string): string {
    return input.replace(/\./g, '');
  }

  protected addThousandPoints(value: string) {
    return value
      .split('')
      .map((num, idx, { length }) => ((length - 1 - idx) % 3 === 0 && length - idx !== 1 ? `${num}.` : num))
      .join('');
  }

  /**
   * This method is correcting the input to delete a number rather than a point by "Backspace" and "Delete".
   * It is assuring that only the last comma is kept.
   * It is ment to be invoked by onKeyDown. The the returned correctedInput
   * is ment to be processed by the normal input pipe (correctOnTheFly etc.)
   * @param key onKeydown key
   * @param param1 selectionFromTo
   * @param input current input string
   * @returns corrected input string with number deleted rather than point and corrected selectionFromTo or [] if no correction necessary
   */
  public getCorrectedDeletionInput(
    key: string,
    [from, to]: [number, number],
    input: string
  ): [string?, [number, number]?] {
    if (from !== to || !input) {
      return [];
    }

    if (['Backspace', 'Delete'].includes(key)) {
      let deletedIndex = from + (key === 'Backspace' ? -1 : 0);
      const inputChars = input.split('');
      if (inputChars[deletedIndex] !== '.') {
        return [];
      }

      deletedIndex += key === 'Backspace' ? -1 : +1;
      inputChars.splice(deletedIndex, 1);

      return [inputChars.join(''), key === 'Backspace' ? [from - 1, to - 1] : [from + 1, to + 1]];
    } else if (key === ',' && input.includes(',')) {
      if (input[from] === ',') return [input, [from + 1, to + 1]];
      const inputChars = input.split('');

      inputChars.splice(from, 0, ',');
      const deleteIndex = inputChars.findIndex((char, idx) => char === ',' && idx !== from);
      inputChars.splice(deleteIndex, 1);

      return [inputChars.join(''), [from, to]];
    }

    return [];
  }

  /**
   * After correctOnTheFly the cursor position has to be recomputed.
   * @param param0 [from, to] selection in input field
   * @param input manual corrected input by user
   * @param correctedInput correction of user input
   * @returns [from, to] selection in input field for corrected input
   */
  public correctSelectionFromTo([from, to]: [number, number], input: string, correctedInput: string): [number, number] {
    if ([from, to, input, correctedInput].some((checked) => checked == null)) {
      return [from, to];
    }

    // adoption if auto correction has added one character
    if (correctedInput.length - input.length === 1) {
      return [from + 1, to + 1];
    }

    // adoption if auto correction has removed one character
    if (correctedInput.length - input.length === -1) {
      const removed = input
        .split('')
        .find((char, idx, { length }) => idx === length - 1 || correctedInput[idx] !== char);

      if (removed.match(/[0-9]/) && input.length < this.maxCharacters) {
        return [from, to]; // number removed => keep jumped cursor position
      } else {
        return [from - 1, to - 1]; // invalid character removed => reset to cursor position before
      }
    }
    return [from, to];
  }

  /**
   * Increments or decrements inputted value by step and corrects value according to min and max and raster defined by step.
   * @param value value to be in/decremented, can be out of bound or raster => will be adopted
   * @param isIncrement increment = true, decrement = false
   * @returns new incremented or decremented value but only if it would change after min max correction otherwise undefined.
   */
  public incrementDecrement(value: number, isIncrement: boolean): number | undefined {
    let _value = value || 0;

    _value = saveAdd(_value, isIncrement ? this.step : -this.step);

    _value = Math.max(this.min, Math.min(this.max, _value));
    if (this.isExplicitStep) {
      _value = correctStepRaster(_value, this.step);
    }

    return _value !== value ? _value : undefined;
  }

  public formatValue(value: string): string {
    return value;
  }

  public get max(): number {
    return this._max;
  }
  public get min(): number {
    return this._min;
  }
  public get step(): number {
    return this._explicitStep || DEFAULT_STEP;
  }
  protected get isExplicitStep(): boolean {
    return this._explicitStep !== undefined;
  }
  public get maxCharacters(): number {
    return this._maxCharacters;
  }

  /**
   * Negative values are only allowed if min < 0.
   * If min is not explicitely set then min is 0 and negative values are not allowed.
   */
  public get allowNegative(): boolean {
    return this.min < 0;
  }
}

export default NumberParsing;
