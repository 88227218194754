import { Action } from '@app-modeleditor/components/button/action/action';
import { Resource } from './resource';

export class Hotkey extends Resource {
  private ctrlKey: boolean;
  private shiftKey: boolean;
  private keyCode: string;
  private actions: Action[];
  private stopDefault: boolean;
  private altKey: boolean;

  public isAltKey(): boolean {
    return typeof this.altKey === 'boolean' ? this.altKey : false;
  }

  public setAltKey(altKey: boolean): this {
    this.altKey = altKey;
    return this;
  }

  public isStopDefault(): boolean {
    return typeof this.stopDefault === 'boolean' ? this.stopDefault : true;
  }

  public setStopDefault(stopDefault: boolean): this {
    this.stopDefault = stopDefault;
    return this;
  }

  public getActions(): Action[] {
    return this.actions || [];
  }

  public setActions(actions: Action[]): this {
    this.actions = actions;
    return this;
  }

  public isCtrlKey(): boolean {
    return typeof this.ctrlKey === 'boolean' ? this.ctrlKey : false;
  }

  public setCtrlKey(ctrlKey: boolean): this {
    this.ctrlKey = ctrlKey;
    return this;
  }

  public isShiftKey(): boolean {
    return typeof this.shiftKey === 'boolean' ? this.shiftKey : false;
  }

  public setShiftKey(shiftKey: boolean): this {
    this.shiftKey = shiftKey;
    return this;
  }

  public getKeyCode(): string {
    return this.keyCode;
  }

  public setKeyCode(keyCode: string): this {
    this.keyCode = keyCode;
    return this;
  }
}
