import { Action } from '@app-modeleditor/components/button/action/action';
import { EPredefinedAction } from '@app-modeleditor/components/button/action/predefined-action.enum';
import { Button } from '@app-modeleditor/components/button/button';
import { EButtonDisplayType } from '@app-modeleditor/components/button/button-display-type.enum';
import { EButtonLifecycle, IButtonHook } from '@app-modeleditor/components/button/button.service';
import { EMenuMode } from '@app-modeleditor/components/content/content-element/menu-mode.enum';
import { EntryElement } from '@app-modeleditor/components/entry-collection/entry-element';
import { EntryElementValue } from '@app-modeleditor/components/entry-collection/entry-element-value';
import { EFieldType } from '@app-modeleditor/components/entry-collection/field-type.enum';
import { MenuItem } from 'frontend/src/dashboard/view/template-toolbar/menu-item';
import { Toolbar } from 'frontend/src/dashboard/view/template-toolbar/toolbar';
import { ToolbarGroup } from 'frontend/src/dashboard/view/template-toolbar/toolbar-group';
import { EToolbarItemType } from 'frontend/src/dashboard/view/template-toolbar/toolbar-item-type';
import { IToolbarOptions } from 'frontend/src/dashboard/view/template-toolbar/toolbar-options.interface';
import { of } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Gantt_General } from '../general.gantt.component';
import { IGanttResponse } from '../response/gantt-response';
import { AttributeColorSelectorElement } from './elements/attribute-color-selector.element';
import { AttributeHideRowsElement } from './elements/attribute-search/attribute-hide-rows-element';
import { AttributeSearchInputElement } from './elements/attribute-search/attribute-search-input.element';
import { FilterWarningElement } from './elements/attribute-search/filter-warning.element';
import { ZoomToVisibleBlocksElement } from './elements/attribute-search/zoom-to-visible-blocks.element';
import { CalendarWeekSelectorElement } from './elements/calendar-week-selector.element';
import { EditIndexCardAttributesButton } from './elements/edit-index-card-attributes-button.element';
import { EditTooltipButton } from './elements/edit-tooltip-button.element';
import { FilterBlockingIntervalsElement } from './elements/filter-blocking-intervals.element';
import { IndexCardElement } from './elements/index-card-element';
import { IntervalAttributeSearchElement } from './elements/interval-attribute-search.element';
import { LabelModelSelector } from './elements/label-model-selector-element';
import { NavigationIntervallElement } from './elements/navigation-intervall.element';
import { ResourceColorizeToggleElement } from './elements/resource-colorize-toggle.element';
import { EShiftTimeType, ShiftTimeElement } from './elements/shift-time-element';
import { ShowAttributeKeyOnIndexCardsElement } from './elements/show-attribute-key-on-index-cards.element';
import { ToggleApplyShowTooltipsElement } from './elements/show-tooltips.element';
import { SuperBlockViewElement } from './elements/super-block-view.element';
import { TimeFormatElement } from './elements/time-format-element';
import { ToggleApplyRowBackgroundColorElement } from './elements/toggle-apply-row-backround-color.element';
import { ToggleIntervalFilterByAttribute } from './elements/toggle-interval-filter-by-attribute';
import { ToggleEarliestStartLatestEndElement } from './elements/toggle-show-ealiest-start-latest-end';
import { ToggleZoomToTimeSelectionElement } from './elements/toggle-zoom-to-time-selection';
import { ToogleAddBlock } from './elements/toogle_add-block.element';
import { VisibleAttributeChangerButton } from './elements/visible-attribute-button.element';
import { YAxisSearchHideRowsElement } from './elements/y-axis-search-hide-rows.element';
import { YAxisSearchNameElement } from './elements/y-axis-search-name.element';
import { EZoomGanttButton, ZoomElement } from './elements/zoom-element';
import { EGanttToolbarIdentifier } from './gantt-toolbar-identifier';
import { EGanttToolbarStaticMenuItem } from './gantt-toolbar-static-menu-items';
import { ConditionFilterMenuItem } from './items/condition-filter-menu-item';

export class GanttToolbar extends Toolbar {
  private toolbarMenuItems: EGanttToolbarStaticMenuItem[] = [];
  private conditionFilter: ConditionFilterMenuItem;

  constructor(options: IToolbarOptions, private scope: Gantt_General) {
    super(options);
    this.setParentId(this.scope.ganttTemplateDataService.getTemplateData()?.getId());

    if (this.scope.configApi.access().templates.Gantt.FrontendFilter === true) {
      this.toolbarMenuItems.push(EGanttToolbarStaticMenuItem.CONDITION_FILTER);
    }
    this.setClass('saxms-submenu').disableShortcuts(false);
    this.get();

    const items: number = this.getMenuItems().filter(
      (m: MenuItem) => m.getToolbarItemType() === EToolbarItemType.GROUP && m.getName() !== 'print'
    )?.length;
    if (items > 0) {
      this.setFavoriteMenuEnabled(true);
      this.createFavoriteMenu();
    }
    this.setResourceIdToAllEntryElements(this.scope.ganttTemplateDataService.getTemplateData().getResourceId());
  }

  public getPrintAction(): MenuItem {
    return new MenuItem()
      .setIcon('print')
      .setShortcut(true)
      .setName('print')
      .setDisplayType(EButtonDisplayType.ICON_ONLY)
      .chainActions(new Action().setCb(() => of(this.scope.printGantt())));
  }

  private setResourceIdToAllEntryElements(resourceId: string): void {
    this.getMenuItems()?.forEach((m: MenuItem) => {
      m.getToolbarGroups()?.forEach((g: ToolbarGroup) => {
        g.getEntryElements()?.forEach((e: EntryElement) => {
          e.setResourceId(resourceId);
        });
      });
    });
  }

  private getPredefinedEntryElements(data: any): EntryElement[] {
    const entries: EntryElement[] = [];
    const e: EntryElement = new EntryElement().setFieldType(EFieldType.BUTTON).setName(data.id).setId(data.id);
    switch (data.fieldIdentifier) {
      case EGanttToolbarIdentifier.WEEK_FORMAT:
        entries.push(new TimeFormatElement(this.scope, 'WEEK').get(data));
        break;
      case EGanttToolbarIdentifier.TOGGLE_CHANGE_ROW:
        // e = this.getIconButton(data);
        console.error(data, 'currently not implemented');
        break;
      case EGanttToolbarIdentifier.DAY_FORMAT:
        entries.push(new TimeFormatElement(this.scope, 'DAY').get(data));
        break;
      case EGanttToolbarIdentifier.GANTT_INDEX_CARDS_SETTINGS:
        entries.push(new EditIndexCardAttributesButton(this.scope).get());
        break;
      case EGanttToolbarIdentifier.INDEX_CARD_VIEW:
        entries.push(new IndexCardElement(this.scope).get(data));
        entries.push(new ShowAttributeKeyOnIndexCardsElement(this.scope).get(data));
        break;
      case EGanttToolbarIdentifier.SUPER_BLOCK_VIEW:
        entries.push(new SuperBlockViewElement(this.scope).get(data));
        break;
      case EGanttToolbarIdentifier.TOGGLE_GANTT_ENTRY_BACKGROUND_COLOR:
        entries.push(new ToggleApplyRowBackgroundColorElement(this.scope).get(data));
        break;
      case EGanttToolbarIdentifier.TOGGLE_SHOW_TOOLTIPS:
        entries.push(new ToggleApplyShowTooltipsElement(this.scope).get(data));
        break;
      case EGanttToolbarIdentifier.ATTRIBUTE_SEARCH_INPUT:
        // done
        const searchInputElem = new AttributeSearchInputElement(this.scope);
        entries.push(searchInputElem.get(data));
        entries.push(new FilterWarningElement(this.scope, searchInputElem).get(data));
        entries.push(new AttributeHideRowsElement(this.scope).get(data));
        entries.push(new ZoomToVisibleBlocksElement(this.scope.ganttLibService).get(data));
        break;
      case EGanttToolbarIdentifier.ATTRIBUTE_SEACH_INPUT_HIDE_ROWS:
        break;
      case EGanttToolbarIdentifier.ATTRIBUTE_COLOR_SELECTOR:
        // done
        entries.push(new AttributeColorSelectorElement(this.scope).get(data));
        break;
      case EGanttToolbarIdentifier.ATTRIBUTE_SEARCH_MODE:
        break;
      case EGanttToolbarIdentifier.ZOOM_IN:
        entries.push(new ZoomElement(this.scope, EZoomGanttButton.ZOOMIN).get(data));
        break;
      case EGanttToolbarIdentifier.ZOOM_OUT:
        entries.push(new ZoomElement(this.scope, EZoomGanttButton.ZOOMOUT).get(data));
        break;
      case EGanttToolbarIdentifier.ZOOM_RESET:
        entries.push(new ZoomElement(this.scope, EZoomGanttButton.ZOOMRESET).get(data));
        break;
      case EGanttToolbarIdentifier.NAVIGATION_START:
        break;
      case EGanttToolbarIdentifier.NAVIGATION_END:
        break;
      case EGanttToolbarIdentifier.NAVIGATION_INTERVAL:
        // done
        entries.push(new NavigationIntervallElement(this.scope).get(data));
        break;
      case EGanttToolbarIdentifier.Y_AXIS_SEARCH_NAME:
        // done
        entries.push(new YAxisSearchNameElement(this.scope).get(data));
        entries.push(new YAxisSearchHideRowsElement(this.scope).get(data));
        break;
      case EGanttToolbarIdentifier.TOGGLE_GANTT_EARLIEST_START_LATEST_END:
        entries.push(new ToggleEarliestStartLatestEndElement(this.scope).get(data));
        break;
      case EGanttToolbarIdentifier.Y_AXIS_SEARCH_INPUT:
        break;
      case EGanttToolbarIdentifier.SELECT_COMPARE:
        break;
      case EGanttToolbarIdentifier.ADD:
        break;
      case EGanttToolbarIdentifier.DELETE:
        break;
      case EGanttToolbarIdentifier.INFO:
        break;
      case EGanttToolbarIdentifier.START:
        entries.push(new ShiftTimeElement(this.scope, EShiftTimeType.START).get(data));
        // e.setFieldType(EFieldType.DATE_TIME_PICKER).setValue(new EntryElementValue().setValue(null));
        break;
      case EGanttToolbarIdentifier.END:
        entries.push(new ShiftTimeElement(this.scope, EShiftTimeType.END).get(data));
        // e.setFieldType(EFieldType.DATE_TIME_PICKER).setValue(new EntryElementValue().setValue(null));
        break;
      case EGanttToolbarIdentifier.TOGGLE_BLOCK_ADD:
        entries.push(new ToogleAddBlock(this.scope).get(data));
        break;
      case EGanttToolbarIdentifier.ZOOM_INITIAL:
        break;
      case EGanttToolbarIdentifier.TOGGLE_ZOOM_TO_TIME_SELECTION:
        entries.push(new ToggleZoomToTimeSelectionElement(this.scope).get(data));
        break;
      case EGanttToolbarIdentifier.ZOOM_CURRENT_DAY:
        entries.push(new ZoomElement(this.scope, EZoomGanttButton.ZOOMCURRENTDAY).get(data));
        break;
      case EGanttToolbarIdentifier.ZOOM_CURRENT_WEEK:
        entries.push(new ZoomElement(this.scope, EZoomGanttButton.ZOOMCURRENTWEEK).get(data));
        break;
      case EGanttToolbarIdentifier.ZOOM_MONTH:
        entries.push(new ZoomElement(this.scope, EZoomGanttButton.ZOOMCURRENTMONTH).get(data));
        break;
      case EGanttToolbarIdentifier.ATTRIBUTE_SEARCH_TYPE:
        break;
      case EGanttToolbarIdentifier.TOGGLE_TRANSFORMABLE:
      case EGanttToolbarIdentifier.ZOOM_ALL:
        // entries.push(this.getIconButton(data).setIcon('zoom_out_map');
        break;
      case EGanttToolbarIdentifier.LABEL_MODEL_SELECTOR:
        entries.push(new LabelModelSelector(this.scope, e));
        // entries.push(this.getIconButton(data));
        break;
      case EGanttToolbarIdentifier.ATTRIBUTE_NAME_SELECTOR:
        entries.push(new VisibleAttributeChangerButton(this.scope).get(data));
        break;
      case EGanttToolbarIdentifier.COLORIZE_RESOURCES:
        entries.push(...new ResourceColorizeToggleElement(this.scope).getGroup());
        break;
      case EGanttToolbarIdentifier.TOOLTIP_SETTINGS:
        entries.push(new EditTooltipButton(this.scope).get());
        break;
      case EGanttToolbarIdentifier.FILTER_BLOCKING_INTERVALS:
        entries.push(new FilterBlockingIntervalsElement(this.scope).get(data));
        entries.push(new IntervalAttributeSearchElement(this.scope).get(data));
        break;
      case EGanttToolbarIdentifier.TOGGLE_INTERVAL_FILTER_BY_ATTRIBUTE:
        entries.push(new ToggleIntervalFilterByAttribute(this.scope).get(data));
        break;
      default:
        console.error('NOT PART OF GANTT TOOLBAR', data);
        break;
    }
    return entries;
  }

  saveFavoriteElements(entryElements: EntryElement[]): void {
    let favoriteString = '';
    entryElements.forEach((element, index) => {
      if (index === entryElements.length - 1) {
        favoriteString += element.getId();
      } else {
        favoriteString += element.getId() + ';';
      }
    });

    this.scope.ganttSettingsService.changeSettings({ favoriteMenuElements: favoriteString });
    this.scope.ganttSettingsService.saveSettings().subscribe();
  }

  get(): void {
    const origin: string = this.scope.ganttTemplateDataService.getTemplateData().getHeadline();
    this.setName(origin);

    if (this.scope.ganttTemplateDataService.getTemplateData().getAdditionalSubMenus) {
      let defaultSelected: MenuItem;
      // make copy of menu items because dataset will be manipulated (parsing SLIDE_TOGGLE to BUTTON because action handling)
      const menuItems: MenuItem[] = JSON.parse(
        JSON.stringify(this.scope.ganttTemplateDataService.getTemplateData().getAdditionalSubMenus())
      ).map((menu) => {
        const groups: ToolbarGroup[] = menu.subMenuGroups.map((group) => {
          let items: EntryElement[] = [];
          if (group.entryElements) {
            for (const entry of group.entryElements) {
              if (entry.fieldType === EFieldType.PREDEFINED) {
                const predefined = this.getPredefinedEntryElements(entry);
                items = items.concat(predefined);
                continue;
              }
              const notPredefinedElement: MenuItem = this.scope.templatefactory.adapt<MenuItem>(entry);
              // hack because local frontend buttons are not predfined buttons
              if (
                notPredefinedElement.getFieldType() === EFieldType.BUTTON ||
                entry.toolbarElementType === EFieldType.BUTTON
              ) {
                notPredefinedElement.setDisabled(false);
                notPredefinedElement.setDisplayType(EButtonDisplayType.ICON_WITH_LABEL_BELOW);
                notPredefinedElement.setEnableBy(() => this.getEditMode(notPredefinedElement as Button));
              }
              // TODO: Sebastian
              this.scope.buttonService.hook.pipe(takeUntil(this.scope.onDestroy)).subscribe((response: IButtonHook) => {
                if (
                  response.lifecycle == EButtonLifecycle.AFTER_REQUEST &&
                  response.id == notPredefinedElement.getId()
                ) {
                  this.scope.ganttPluginHandlerService
                    .getResponseHandler()
                    .handleUpdateNotification(response.data as IGanttResponse);
                }
              });

              if (entry.fieldType === EFieldType.TOGGLE_BUTTON) {
                (notPredefinedElement as Button).setDisplayType(EButtonDisplayType.ICON_WITH_LABEL_BELOW);

                notPredefinedElement.chainActions(
                  // new Action().setCb(() => of(this.executeButtonAction(notPredefineElement))),
                  new Action().setCb(() =>
                    of((notPredefinedElement as Button).setSelected(!(notPredefinedElement as Button).isSelected()))
                  )
                );
                notPredefinedElement.setFreeSelection(true);
              }
              if (notPredefinedElement.getFieldType() === EFieldType.SLIDE_TOGGLE) {
                entry.fieldType = 'BUTTON';
                notPredefinedElement.setLocalID(EPredefinedAction.TOGGLE_DEFAULT_FILTER);
                notPredefinedElement.setFieldIdentifier(
                  entry.localActions[0]?.filterId || notPredefinedElement.getFieldIdentifier()
                );

                // call click if enabled by backend
                if (notPredefinedElement.getValue<EntryElementValue>().getValue()) {
                  this.scope.actionHandler.buttonService.onClick(entry).subscribe();
                }
                (notPredefinedElement as EntryElement).onChanges((data) => {
                  this.scope.actionHandler.buttonService.onClick(entry).subscribe();
                });
              }

              items.push(notPredefinedElement);
            }
          }

          items = items.concat(this.getDefaultGroupItems(group));

          const g: ToolbarGroup = new ToolbarGroup().setName(group.name).setId(group.id).setEntryElements(items);

          return g;
        });
        const m = new MenuItem().defaultGroup(this).setName(menu.name).setId(menu.id).setToolbarGroups(groups);
        defaultSelected = menu.defaultSelected === true ? m : defaultSelected;
        return m;
      });
      // add print action
      if (
        this.scope.ganttTemplateDataService.getTemplateData().isPrint() ||
        this.scope.configApi.access().templates.Gantt.print
      ) {
        this.addMenuItems(this.getPrintAction());
      }

      // predefined menu items
      for (const menuItemID of this.toolbarMenuItems) {
        const preItem: MenuItem = this.getStaticMenuItemByID(menuItemID);
        this.addMenuItems(preItem);
        // this.setActiveMenuItem(preItem);
      }

      if (this.scope.saxmsBestGanttComponent && this.scope.saxmsBestGanttComponent.showSettingsButton) {
        menuItems.push(this.getSettingMenu());
      }
      this.addMenuItems(...menuItems)
        .setActiveMenuItem(defaultSelected)
        .setMenuMode(
          this.scope.ganttTemplateDataService.getTemplateData().getMenuMode() ? EMenuMode.SHOW : EMenuMode.HIDE
        );
    }
  }

  private getStaticMenuItemByID(id: EGanttToolbarStaticMenuItem): MenuItem {
    switch (id) {
      case EGanttToolbarStaticMenuItem.CONDITION_FILTER:
        this.conditionFilter = new ConditionFilterMenuItem(this.scope, this);
        return this.conditionFilter.getMenuItem();
      default:
        console.error('NOT PART OF STATIC MENU ITEM', id);
        return null;
    }
  }

  private getSettingMenu(): MenuItem {
    const settingsBtn: Button = new Button()
      .setIcon('settings')
      .setDisplayType(EButtonDisplayType.ICON_ONLY)
      .setName('settings')
      .chainActions(new Action().setCb(() => of(this.scope.showNewGanttSettingsDialog())));
    const settings: MenuItem = new MenuItem()
      .setNavigationElement(settingsBtn)
      .setToolbarItemType(EToolbarItemType.NAVIGATOR);

    return settings;
  }

  /**
   * get predefined groups with its items
   * @param group JSON
   * @returns EntryElement[]
   */
  private getDefaultGroupItems(group: any): EntryElement[] {
    const items: EntryElement[] = [];
    switch (group.id) {
      case 'template.submenugroup.calendarweekselector':
        const n: CalendarWeekSelectorElement = new CalendarWeekSelectorElement(this.scope).get(group);
        items.push(n);
        break;
    }

    return items;
  }

  private getEditMode(button: Button): boolean {
    let enabled = true;
    enabled = this.scope.templateActionService.checkActions(button.getGlobalActions());
    return enabled;
  }

  private executeButtonAction(data: Button): void {
    // const ganttActionWrapper: GanttActionWrapper = {
    //   actions: data.getLocalActions(),
    //   actionScope: GanttActionScopes.LOCAL,
    // };
    // this.scope.actionHandler.executeActions(ganttActionWrapper).subscribe(
    //   data => {
    //   },
    // );
  }

  public handleFavoriteMenu(favoriteItens: string[]): void {
    this.addItemsFromSettingsToFavoriteMenu(favoriteItens, this.saveFavoriteElements.bind(this));
  }

  public getConditionFilter(): ConditionFilterMenuItem {
    return this.conditionFilter;
  }

  public setFavoriteOptionsItems(): void {
    this.getMenuItems().forEach((menuItem) => {
      menuItem.getToolbarGroups().forEach((group) => {
        group.getEntryElements().forEach((element) => {
          if (element instanceof AttributeColorSelectorElement) {
            element.setFavoriteFromSettings();
          }
        });
      });
    });
  }
}
