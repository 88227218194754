import { EGanttScrollContainer } from './scroll-container.enum';

/**
 * Interface defining all properties and methods a class should have to be a data container for {@link GanttHTMLStructureBuilder}.
 */
interface IHTMLStructureData {
  /**
   * Removes all nodes from the DOM.
   */
  removeStructure(): void;
}

/**
 * Class which holds gantt HTML structure.
 * @keywords data, class, structure, storage, save, div, html
 */
export class GanttHTMLStructureData implements IHTMLStructureData {
  public xAxis: d3.Selection<HTMLDivElement, undefined, d3.BaseType, undefined> = undefined;
  public scrollContainerWrapper: d3.Selection<HTMLDivElement, undefined, d3.BaseType, undefined> = undefined;
  public scrollContainerOverlay: d3.Selection<HTMLDivElement, undefined, d3.BaseType, undefined> = undefined;
  public readonly scrollContainer: { [id: string]: GanttScrollContainerHTMLStructureData } = {};

  constructor(scrollContainerIds: string[] = Object.values(EGanttScrollContainer)) {
    for (const id of scrollContainerIds) {
      this.scrollContainer[id] = new GanttScrollContainerHTMLStructureData();
    }
  }

  public removeStructure(): void {
    this.xAxis?.remove();
    this.scrollContainerWrapper?.remove();
    this.scrollContainerOverlay?.remove();

    this.xAxis = undefined;
    this.scrollContainerWrapper = undefined;
    this.scrollContainerOverlay = undefined;

    for (const id in this.scrollContainer) {
      this.scrollContainer[id].removeStructure();
    }
  }
}

/**
 * Class which holds gantt scroll container HTML structure.
 * @keywords data, class, structure, storage, save, div, html
 */
export class GanttScrollContainerHTMLStructureData implements IHTMLStructureData {
  public scrollContainer: d3.Selection<HTMLDivElement, undefined, d3.BaseType, undefined> = undefined;
  public yAxis: d3.Selection<HTMLDivElement, undefined, d3.BaseType, undefined> = undefined;
  public shifts: d3.Selection<HTMLDivElement, undefined, d3.BaseType, undefined> = undefined;

  public removeStructure(): void {
    this.yAxis?.remove();
    this.shifts?.remove();
    this.scrollContainer?.remove();

    this.yAxis = undefined;
    this.shifts = undefined;
    this.scrollContainer = undefined;
  }
}
