import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
export interface ISelectFavroriteItem {
  key: string;
  value: string[];
}
@Injectable({
  providedIn: 'root',
})
export class SelectionFavoriteService {
  private favoriteSelectionsChange: BehaviorSubject<ISelectFavroriteItem[]>;
  private favoriteSelectionsMenuItems: ISelectFavroriteItem[] = [];

  constructor() {
    this.favoriteSelectionsChange = new BehaviorSubject(null);
  }

  public addSelectionItemToFavorite(dropDownMenuId: string, selectionItemId: string): void {
    if (!this.favoriteSelectionsMenuItems) {
      this.favoriteSelectionsMenuItems = [];
    }
    let favoriteItem: ISelectFavroriteItem = this.favoriteSelectionsMenuItems.find(
      (item) => item.key === dropDownMenuId
    );
    if (!favoriteItem) {
      favoriteItem = {
        key: dropDownMenuId,
        value: [selectionItemId],
      };
      this.favoriteSelectionsMenuItems.push(favoriteItem);
    } else {
      if (!favoriteItem.value.includes(selectionItemId)) {
        favoriteItem.value.push(selectionItemId);
      }
    }
    this.favoriteSelectionsChange.next(this.favoriteSelectionsMenuItems);
  }

  public removeSelectionItemFromFavorite(dropDownMenuId: string, selectionItemId: string) {
    const favoriteItem: ISelectFavroriteItem = this.favoriteSelectionsMenuItems.find(
      (item) => item.key === dropDownMenuId
    );
    if (!favoriteItem) {
      return;
    } else {
      favoriteItem.value = favoriteItem.value.filter((itemId) => itemId !== selectionItemId).slice();

      if (favoriteItem.value.length === 0) {
        this.favoriteSelectionsMenuItems = this.favoriteSelectionsMenuItems
          .filter((item) => item.key !== favoriteItem.key)
          .slice();
      }
    }
    this.favoriteSelectionsChange.next(this.favoriteSelectionsMenuItems);
  }

  public setFavoriteSelectionsMenuItem(settings: ISelectFavroriteItem[]): void {
    this.favoriteSelectionsMenuItems = settings;
    this.favoriteSelectionsChange.next(this.favoriteSelectionsMenuItems);
  }

  public getFavoriteSelectionsMenuItemById(): Observable<ISelectFavroriteItem[]> {
    return this.favoriteSelectionsChange.asObservable();
  }
}
