import { Injectable } from '@angular/core';
import { EntryElementValue } from '@app-modeleditor/components/entry-collection/entry-element-value';
import { EFieldType } from '@app-modeleditor/components/entry-collection/field-type.enum';
import { DetailedSettings } from 'handsontable/plugins/mergeCells';
import { BehaviorSubject, Observable } from 'rxjs';
import { SaxMsSpreadSheetColumn } from '../core/saxms-spreadsheet';
import { FullSpreadsheetComponent } from '../full-spreadsheet/full-spreadsheet.component';
import { ESaxMsColumnUnits } from '../model/table-column-unity.enum';
import { ESpreadsheetDatatypes } from '../model/table-datatypes.enum';
import { ISaxMsSpreadsheetRow } from '../model/table-row';
import { ISaxMsSpreadsheetRawRow, ISaxMsSpreadsheetRowEntry } from '../model/table-row-entry';
import { SpreadsheetSaveModel } from '../spreadsheet';
import { ColumnUtil } from './column-util';
import { SpreadsheetHelperUtil } from './spreadsheet-helper-util';

@Injectable()
export class RowDataUtil {
  private rowChange = new BehaviorSubject<Record<string, any>[]>([]);
  private rowDatasets: ISaxMsSpreadsheetRawRow[] = [];
  private displayedRows: { [key: string]: any }[] = [];
  private displayRowsFlatList: { [key: string]: any }[] = [];
  private datasetPlainlist: { [key: string]: ISaxMsSpreadsheetRowEntry }[] = [];
  private tableRows: ISaxMsSpreadsheetRow[] = [];
  private tableRowsFlatlist: ISaxMsSpreadsheetRow[] = [];
  private scope: FullSpreadsheetComponent;
  private mergeConfig: DetailedSettings[] = [];

  constructor(private columnUtil: ColumnUtil, private spreadsheetHelperUtil: SpreadsheetHelperUtil) {}

  /**
   * gets executed whenever displayed rows gets updated
   * @returns Observable<Record<string, any>[]>
   */
  public afterDisplayedRowsChanged(): Observable<Record<string, any>[]> {
    return this.rowChange.asObservable();
  }

  /**
   * returns how high a cell is
   * @param cellId id of the cell
   * @param columnIndex column index of the cell
   * @param rowIndex row index of the cell
   */
  private getCellHeight(cellId: string, columnIndex: number, rowIndex: number): number {
    let cellHeigth = 0;
    for (let i = rowIndex; i < this.rowDatasets.length; i++) {
      const rowIndex2 = this.spreadsheetHelperUtil.getVisualRowIndex(i);
      const colIndex2 = columnIndex;
      if (rowIndex2 !== null && colIndex2 !== null && this.datasetPlainlist[rowIndex2][colIndex2]) {
        if (this.datasetPlainlist[rowIndex2][colIndex2].uuid === cellId) {
          cellHeigth++;
        } else {
          return cellHeigth;
        }
      } else {
        return cellHeigth;
      }
    }
    return cellHeigth;
  }

  /**
   * mapping of the value of the cell by the entryElement
   * @param column information of the column
   * @param cellEntry information of the cell
   * @returns the value of the cell in the correct format by the entryElement
   */
  private getRowEntryValueFromEntryElement(column: SaxMsSpreadSheetColumn, cellEntry: any) {
    if (!cellEntry.value && column.entryElement.getFieldType() !== EFieldType.TEXT_FIELD) {
      return null;
    }

    if (column.fieldType === EFieldType.COMBO_BOX) {
      // return rowentry.value;
      if (cellEntry.value instanceof EntryElementValue) {
        return cellEntry.value;
      }
      return new EntryElementValue().setName(cellEntry.value).setValue(cellEntry.value);
    }

    switch (column.entryElement.getFieldType()) {
      case EFieldType.OBJECT_SELECTOR:
        return new EntryElementValue()
          .setId(cellEntry.value.id || cellEntry.value)
          .setName(cellEntry.value.name || cellEntry.value)
          .setCreated(cellEntry.value.created);
      case EFieldType.MULTI_OBJECT_SELECTOR:
        return (cellEntry.value as any[]).map((v) => {
          return new EntryElementValue().setId(v.id).setName(v.name).setCreated(v.created);
        });
      default:
        return this.getRowEntryValue(cellEntry, column);
    }
  }

  /**
   * mapping of the value of the cell by the entryElement
   * @param cellEntry information of the cell
   * @param column information of the column
   * @returns the value of the cell in the correct format by the dataT
   */
  private getRowEntryValue(cellEntry: any, column: SaxMsSpreadSheetColumn): any {
    if (cellEntry.value || !isNaN(cellEntry.value)) {
      switch (column.datatype) {
        case ESpreadsheetDatatypes.long:
        case ESpreadsheetDatatypes.date: {
          if (cellEntry.value === null || cellEntry.value === null) {
            return null;
          }
          const date = new Date(cellEntry.value);
          date.setSeconds(0);
          date.setMilliseconds(0);
          return date.getTime();
        }
        default:
          return cellEntry.value;
      }
    } else {
      return null;
    }
  }

  /**
   * calc a plainlist of the row dataset
   */
  private calcDatasetFlatlist(): void {
    this.datasetPlainlist = [].concat(
      ...this.rowDatasets.map((item) => [item.properties].concat(item.children ? item.children : []))
    );
  }

  public getConvertedValueOfCell(column: SaxMsSpreadSheetColumn, value: any): any {
    if (column.fieldType === EFieldType.COMBO_BOX) {
      if (value instanceof EntryElementValue) {
        return value.getValue();
      }
      return value;
    }

    if (column.entryElement) {
      switch (column.entryElement.getFieldType()) {
        case EFieldType.OBJECT_SELECTOR: {
          const entryValue = value as EntryElementValue;
          return entryValue ? entryValue.getName() : '';
        }
        case EFieldType.MULTI_OBJECT_SELECTOR: {
          let visibleValue = '';
          const values = (value as EntryElementValue[]) || [];
          values.forEach((v, index) => {
            visibleValue += index === values.length - 1 ? `${v.getName()}` : `${v.getName()}, `;
          });
          return visibleValue;
        }
        default:
          if (column.unit === ESaxMsColumnUnits.percent) {
            return parseFloat((value * 100).toFixed(this.spreadsheetHelperUtil.getFixedCount(column)));
          } else {
            return column.datatype === ESpreadsheetDatatypes.bool ? value + '' : value;
          }
      }
    } else {
      if (column.unit === ESaxMsColumnUnits.percent) {
        return parseFloat((value * 100).toFixed(this.spreadsheetHelperUtil.getFixedCount(column)));
      } else {
        return column.datatype === ESpreadsheetDatatypes.bool ? value + '' : value;
      }
    }
  }

  /**
   * convert the datasets to table rows for the handsontable
   */
  public convertToTableRows(): void {
    // this.scope.clearRowCollapseMap();
    const temp = [];
    this.rowDatasets.map((datasetRow: ISaxMsSpreadsheetRawRow, index) => {
      const row: { [key: string]: any } = {};
      const newDatasetRow = {};
      let i = 0; // real index
      Object.keys(datasetRow.properties).forEach((key: string, index: number) => {
        const rowEntry = datasetRow.properties[key];
        const column = this.columnUtil.getColumnById(rowEntry.tableHeaderId);
        if (column) {
          // if (column.unit === ESaxMsColumnUnits.percent) {
          //   row[rowEntry.index] = rowEntry.value * 100;
          // } else {
          //   row[rowEntry.index] = (column.datatype === ESpreadsheetDatatypes.bool) ? rowEntry.value + '' : rowEntry.value;
          // }

          row[rowEntry.index] = this.getConvertedValueOfCell(column, rowEntry.value);
          newDatasetRow[i] = datasetRow.properties[key];
          i++;
        }
      });
      if (datasetRow.children && datasetRow.children.length > 0) {
        row['__children'] = datasetRow.children.map((child) => {
          const childRow: { [key: string]: any } = {};
          Object.keys(child).forEach((key: string, index: number) => {
            const childCellEntry = child[key];
            const column = this.columnUtil.getColumnByIndex(childCellEntry.index, true);
            if (column) {
              childRow[childCellEntry.index] = this.getConvertedValueOfCell(column, childCellEntry.value);
              // childRow[childCellEntry.index] = (column.unit === ESaxMsColumnUnits.percent) ? childCellEntry.value * 100 : childCellEntry.value;
            }
          });
          return childRow;
        });
        this.scope.addRowToCollapseMap(
          this.getTableRowPlainList().find((plainRow) => plainRow.resourceId === datasetRow.resourceId)?.index ?? index
        );
      }

      temp.push(row);
      return newDatasetRow;
    });

    this.displayedRows = temp.slice();
    this.rowChange.next(this.displayedRows);
    this.displayRowsFlatList = [].concat(
      ...this.displayedRows.map((item) => [item].concat(item._children ? item._children : []))
    );

    if (this.scope.getTableTemplate().isFontSizeAutoScale()) {
      this.spreadsheetHelperUtil.calcFontSizeAutoScale(this.columnUtil.getColumns(), this.displayRowsFlatList);
      this.spreadsheetHelperUtil.updateFontSize(this.columnUtil.getColumns());
    }

    this.scope.setInitLoadRows(true);
  }

  /**
   * calc a plainlist of the table rows
   */
  public calcTableRowsFlatlist(): void {
    this.tableRowsFlatlist = [].concat(
      ...this.tableRows.map((item) => [item].concat(item.children ? item.children : []))
    );
  }

  /**
   * return the cell object by row index and column index
   * @param row rowindex of the cell
   * @param col columindex of the cell
   */
  public getCellObjectByPosition(rowIndex: number, columnIndex: number): ISaxMsSpreadsheetRowEntry {
    const realRowIndex = this.spreadsheetHelperUtil.getRowIndex(rowIndex);
    const realColumnIndex = this.spreadsheetHelperUtil.getColumnIndex(columnIndex);
    if (
      realRowIndex >= 0 &&
      realColumnIndex >= 0 &&
      this.datasetPlainlist[realRowIndex] &&
      this.datasetPlainlist[realRowIndex][realColumnIndex]
    ) {
      return this.datasetPlainlist[realRowIndex][realColumnIndex];
    }
  }

  /** build rows
   * @param rowData any
   * @returns void
   */
  public buildTableRows(rowData: any): void {
    const spreadsheetsettings = this.scope.getSpreadsheetsettings();
    const checkMap = spreadsheetsettings
      ? spreadsheetsettings.columnSettings.filter((settings) => settings.orderIdx != null).length > 0
      : false;

    if (!rowData) {
      return;
    }

    const tempRows: ISaxMsSpreadsheetRawRow[] = [];
    const tempTableRows = [];
    const tmpMap: Map<string, SpreadsheetSaveModel[]> = new Map(); // this.getUnsaveData();

    // while (rowData.length > 0 && tempRows.length < 10000) {
    rowData.forEach((row: any, index: number) => {
      const rowObject: ISaxMsSpreadsheetRawRow = {
        resourceId: row.resourceId,
        properties: row.spreadsheetRowEntries || {},
        children: [],
      };

      for (const rowentry of row.tableRowEntries || []) {
        const column = this.columnUtil.getColumns().find((column) => column.id === rowentry.tableHeaderId);
        if (!column) {
          continue;
        }

        const colIdx = this.columnUtil.getColIndex(column.id);
        const saveOrder = column?.saveOrder;

        rowentry.index = colIdx;

        rowObject.properties[rowentry.index] = rowentry;
        rowObject.properties[rowentry.index].saveOrder = saveOrder;
        rowObject.properties[rowentry.index].tableHeaderId = rowentry.tableHeaderId;
        rowObject.properties[rowentry.index].parentRowId = row.resourceId;
        // rowObject[rowentry.index].uuid = uuid();
        if (typeof rowentry.editable === 'boolean') {
          rowObject.properties[rowentry.index].rowEditable = rowentry.editable;
        } else {
          rowObject.properties[rowentry.index].rowEditable = row.rowEditable;
        }
        rowObject.properties[rowentry.index].value = column.entryElement
          ? this.getRowEntryValueFromEntryElement(column, rowentry)
          : this.getRowEntryValue(rowentry, column);
        rowObject.properties[rowentry.index].edited = !!rowentry.edited;
        if (row.defaultColor) {
          rowObject.properties[rowentry.index].defaultColorClass = row.defaultColor;
        }
      }

      if (tmpMap.size > 0 && tmpMap.has(row.resourceId)) {
        for (const tmpRowEntry of tmpMap.get(row.resourceId)) {
          const column = this.columnUtil
            .getColumns()
            .find((column) => column.filterSortObject.getColumnIndex() === tmpRowEntry.value.index);
          if (!column) {
            continue;
          }
          rowObject.properties[tmpRowEntry.value.index].value = column.entryElement
            ? this.getRowEntryValueFromEntryElement(column, tmpRowEntry.value)
            : this.getRowEntryValue(tmpRowEntry.value, column);
          rowObject.properties[tmpRowEntry.value.index].edited = true;
          if (row.defaultColor) {
            rowObject.properties[tmpRowEntry.value.index].defaultColorClass = row.defaultColor;
          }
        }

        tmpMap.delete(row.resourceId);
      }

      const tableRow: ISaxMsSpreadsheetRow = row;
      // if (!(this.tableTemplate.getValues() instanceof TableValue)) {
      tableRow.spreadsheetRowEntries = rowObject.properties;
      // }
      tempTableRows.push(tableRow);
      if (row.parentRowId) {
        const parentRow = tempRows.find((tr) => tr.resourceId === row.parentRowId);
        if (parentRow) {
          parentRow.children.push(rowObject.properties);
        }
      } else {
        tempRows.push(rowObject);
      }
    });

    this.rowDatasets = tempRows.slice();
    this.tableRows = tempTableRows.slice();
    this.calcDatasetFlatlist();
    this.calcTableRowsFlatlist();
    this.convertToTableRows();
    this.scope.spreadsheetHooksUtilService.afterSelection();
  }

  /**
   * calculate the cells for merge of hierarchial tables
   */
  public calcMergedCells(): void {
    if (this.rowDatasets && this.columnUtil.getColumns()) {
      this.mergeConfig = [];
      this.columnUtil.getColumns().forEach((element) => {
        let cellHeight = 1;
        for (let i = 0; i < this.rowDatasets.length; i = i + cellHeight) {
          if (this.datasetPlainlist[i][element.filterSortObject.getColumnIndex()]) {
            const rowIndex = this.spreadsheetHelperUtil.getVisualRowIndex(i);
            if (!rowIndex && rowIndex !== 0) {
              continue;
            }
            const colIndex = element.filterSortObject.getColumnIndex();
            cellHeight = this.getCellHeight(
              this.datasetPlainlist[rowIndex][element.filterSortObject.getColumnIndex()].uuid,
              colIndex,
              i
            );
            if (cellHeight > 1) {
              if (rowIndex !== null && colIndex !== null) {
                this.mergeConfig.push({
                  row: rowIndex,
                  col: colIndex,
                  rowspan: cellHeight,
                  colspan: 1,
                });
              }
            } else {
              cellHeight = 1;
            }
          }
        }
      });
    }
  }

  /**
   * convert the value by the datatype
   * @param columnIndex columnindex of the edited cell
   * @param value value of the cell
   */
  public getValuetypeAfterChange(columnIndex: number, value: any) {
    const column = this.columnUtil.getColumnByIndex(columnIndex, true);
    if (column.entryElement) {
      switch (column.entryElement.getFieldType()) {
        case EFieldType.MULTI_OBJECT_SELECTOR:
        case EFieldType.OBJECT_SELECTOR:
          return value;
        case EFieldType.NUMERIC_RANGE_PICKER:
          if (value === null) {
            const list: any = column.entryElement.getValue<EntryElementValue>().getValue();
            list.forEach((element) => (element.value = 0));
          }
          return structuredClone(value || column.entryElement.getValue<EntryElementValue>().getValue());
      }
    }
    switch (column.unit) {
      case ESaxMsColumnUnits.percent:
        return !isNaN(value) ? parseFloat(value) : null;
    }
    switch (column.datatype) {
      case ESpreadsheetDatatypes.date:
      case ESpreadsheetDatatypes.long:
      case ESpreadsheetDatatypes.number:
        return !isNaN(value) ? parseInt(value) : null;
      case ESpreadsheetDatatypes.float:
        return !isNaN(value) ? parseFloat((value + '').replace(',', '.')) : null;
      case ESpreadsheetDatatypes.text:
        return value || '';
      case ESpreadsheetDatatypes.bool:
        return typeof value === 'boolean' || value === 'true' ? value : false;
    }
  }

  /**
   * update the FullSpreadsheetComponent scope of the service
   * @param scope
   */
  public setScope(scope: FullSpreadsheetComponent): void {
    this.scope = scope;
  }

  /**
   * update the tablerows
   * @param tableRows
   */
  public setTableRows(tableRows: ISaxMsSpreadsheetRow[]): void {
    this.tableRows = tableRows;
    this.calcTableRowsFlatlist();
  }

  /**
   * update the row dataset
   * @param rowDatasets
   */
  public setRowDatasets(rowDatasets: ISaxMsSpreadsheetRawRow[]): void {
    this.rowDatasets = rowDatasets;
    this.calcDatasetFlatlist();
  }

  /**
   * @returns the row dataset
   */
  public getRowDatasets(): ISaxMsSpreadsheetRawRow[] {
    return this.rowDatasets;
  }

  /**
   * @returns the displayed rows
   */
  public getDisplayRows(): { [key: string]: any }[] {
    return this.displayedRows;
  }

  /**
   * @returns the displayed rows flatlist
   */
  public getDisplayRowsFlatList(): { [key: string]: any }[] {
    return this.displayRowsFlatList;
  }

  /**
   * @returns the table rows
   */
  public getTableRows(): ISaxMsSpreadsheetRow[] {
    return this.tableRows;
  }

  /**
   * @returns the dataset plainlist
   */
  public getDatasetPlainlist(): { [key: string]: ISaxMsSpreadsheetRowEntry }[] {
    // only for resting right now
    return this.datasetPlainlist;
  }

  /**
   * @returns the table rows plainlist
   */
  public getTableRowPlainList(): ISaxMsSpreadsheetRow[] {
    return this.tableRowsFlatlist;
  }

  /**
   * @returns the current mergeconfig
   */
  public getMergeConfig(): DetailedSettings[] {
    return this.mergeConfig;
  }

  /**
   * mapping the value of a changed cell
   * @param column column information
   * @param changeRowCell cell information
   * @returns mapped value of the cell
   */
  public getValueOfCell(column: SaxMsSpreadSheetColumn, changeRowCell: any): any {
    return column.entryElement
      ? this.getRowEntryValueFromEntryElement(column, changeRowCell)
      : this.getRowEntryValue(changeRowCell, column);
  }
}
