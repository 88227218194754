import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { SaxmsTooltipModule } from '@app-modeleditor/components/tooltip/tooltip.module';
import { TranslateModule } from '@ngx-translate/core';
import { ImageModule } from '@app-modeleditor/components/image/image.module';
import { ConditionsMenuComponent } from './conditions-menu.component';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    MatMenuModule,
    TranslateModule,
    MatButtonModule,
    ImageModule,
    SaxmsTooltipModule,
  ],
  declarations: [ConditionsMenuComponent],
  exports: [ConditionsMenuComponent],
})
export class ConditionsMenuModule {}
