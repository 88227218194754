import { BestGantt } from '../../main';
import { GanttShiftComponentsDataHandler } from './shift-components-data-handler';

/**
 * Chain Handler for Shift Component Plugin
 * @author Florian Freier
 * @plugin shift-components
 * @class
 * @constructor
 * @param {BestGantt} ganttDiagram the ganttDiagram
 */
export class GanttShiftComponentsChainHandler {
  ganttDiagram: BestGantt;
  dataHandler: GanttShiftComponentsDataHandler;

  constructor(ganttDiagram) {
    this.ganttDiagram = ganttDiagram;
    this.dataHandler = null;
  }

  // ====================
  // ==>> PUBLIC FUNCTIONS
  // ====================

  /**
   * Takes an array of shiftIDs (should be group attribute of a groupObject) and chains each shift with all other shifts of its group
   * @param {string[]} componentGroup Array of shiftIDs belonging to a group
   */
  chainNewComponentGroup(componentGroup) {
    const s = this;
    for (let i = 0; i < componentGroup.length; i++) {
      for (let j = 0; j < componentGroup.length; j++) {
        if (i != j)
          s.ganttDiagram.getShiftTranslationChain().setChainedElementbyId(componentGroup[i], componentGroup[j], false);
      }
    }
  }

  /**
   * removes all chainings the component has to other components and all chainings other components have on this component
   * @param {string} componentID Component ID chaining gets removed on
   * @return {boolean} true if removal was succesful, else false
   */
  removeComponentFromGroupChaining(componentID) {
    const s = this;
    return s._removeComponentFromGroupChaining(componentID);
  }

  /**
   * removes all chainings the whithin the componentGroup
   * @param {string} componentGroupID componentGroupID chaining gets removed on
   * @return {boolean} true if removal was succesful, else false
   */
  removeComponentGroupFromGroupChaining(componentGroupID) {
    const s = this;
    return s._removeComponentGroupFromGroupChaining(componentGroupID);
  }

  /**
   * Chain all Components that are in a group together
   */
  chainAllComponents() {
    const s = this;
    s._chainAllComponents();
  }

  /**
   * Clears all shift chaining of component groups.
   */
  removeAllComponentsFromChaining() {
    const s = this;
    s._removeChainsForAllComponents();
  }

  // ====================
  // ==>> PRIVATE FUNCTIONS
  // ====================

  /**
   * Iterates over all componentGroups, chaines components within each group together
   * @private
   */
  private _chainAllComponents() {
    const s = this;
    const componentGroups = s.dataHandler.getShiftComponents();
    for (let i = 0; i < componentGroups.length; i++) {
      for (let j = 0; j < componentGroups[i].group.length; j++) {
        for (let k = 0; k < componentGroups[i].group.length; k++) {
          if (k != j)
            s.ganttDiagram
              .getShiftTranslationChain()
              .setChainedElementbyId(componentGroups[i].group[j], componentGroups[i].group[k], false);
        }
      }
    }
  }

  /**
   * Removes all chained shifts of all shift components.
   * @private
   */
  private _removeChainsForAllComponents() {
    const s = this;
    const componentGroups = s.dataHandler.getShiftComponents();
    for (let i = 0; i < componentGroups.length; i++) {
      for (let j = 0; j < componentGroups[i].group.length; j++) {
        s.ganttDiagram.getShiftTranslationChain().removeChainedElementbyId(componentGroups[i].group[j], true);
      }
    }
  }

  /**
   * Actual implementation of removeComponentFromGroupChaining.
   * @private
   * @param {string} componentID ID of the component the chaining gets removed from.
   * @return {boolean} True if removal was succesful, else false.
   */
  private _removeComponentFromGroupChaining(componentID) {
    const s = this;
    const allChainings = s.ganttDiagram.getShiftTranslationChain().getChainedElements();

    if (!allChainings[componentID]) return false;

    const chainedLen = allChainings[componentID].length;
    for (let i = chainedLen - 1; i >= 0; i--) {
      const chainedID = allChainings[componentID][i];
      if (!allChainings[chainedID]) break;
      const length = allChainings[chainedID].length;
      let removeFlag = false;

      for (let j = length - 1; j >= 0; j--) {
        if (allChainings[chainedID][j] == componentID) {
          // if chain back to component remove
          removeFlag = true;
          allChainings[chainedID].splice(j, 1);
          if (allChainings[chainedID].length == 0)
            // if group empty after remove, delete group
            s.ganttDiagram.getShiftTranslationChain().removeChainedElementbyId(chainedID, null);
        }
      }
      if (removeFlag) allChainings[componentID].splice(i, 1);
    }

    if (allChainings[componentID].length == 0) {
      //if group empty after remove, delete group
      s.ganttDiagram.getShiftTranslationChain().removeChainedElementbyId(componentID, null);
    }

    // update ChainedIDs DataSet
    s.ganttDiagram.getShiftTranslationChain().setNewChainedElementsData(allChainings);
    return true;
  }

  /**
   * Actual implementation of removeComponentGroupFromGroupChaining
   * @private
   * @param {string} componentGroupID ID of the componentGroup the chaining gets removed from
   * @return {boolean} true if removal was succesful, else false
   */
  private _removeComponentGroupFromGroupChaining(componentGroupID) {
    const s = this;
    const components = s.dataHandler.getComponentsByComponentGroupID(componentGroupID);

    for (let i = 0; i < components.length; i++) {
      s.removeComponentFromGroupChaining(components[i]);
    }
    return true;
  }

  /**
   * Initializes reference to the Chain Data Handler
   * @private
   * @param {GanttShiftComponentsDataHandler} dataHandler
   */
  _initDataHandler(dataHandler) {
    const s = this;
    s.dataHandler = dataHandler;
  }

  // ====================
  // ==>> GETTER & SETTER
  // ====================
}
