import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { ImageModule } from '@app-modeleditor/components/image/image.module';
import { SaxmsTooltipModule } from '@app-modeleditor/components/tooltip/tooltip.module';
import { GanttModule } from '@gantt/public-api';
import { AngularSplitModule } from 'angular-split';
import { LegendModule } from 'frontend/src/dashboard/legend/legend.module';
import { MySharedModule } from 'frontend/src/dashboard/shared/shared.module';
import { DockWindowComponent } from './dock/dock-window/dock-window.component';
import { DockComponent } from './dock/dock.component';
import { LegendToolbarComponent } from './dock/views/legend/legend-toolbar/legend-toolbar.component';
import { SaxMsBestGanttLegend } from './dock/views/legend/saxms-best-gantt.legend.component';
import { ResourceElementComponent } from './dock/views/resources/components/resource-element/resource-element.component';
import { ResourceTooltipComponent } from './dock/views/resources/components/resource-element/resource-tooltip/resource-tooltip.component';
import { ResourceTooltipDirective } from './dock/views/resources/components/resource-element/resource-tooltip/resource-tooltip.directive';
import { ResourceListComponent } from './dock/views/resources/components/resource-list/resource-list.component';
import { StatusLegendComponent } from './dock/views/resources/components/status-legend/status-legend.component';
import { DragResourceElementComponent } from './dock/views/resources/drag/drag-resource-element/drag-resource-element.component';
import { DragDirective } from './dock/views/resources/drag/drag.directive';
import { ResourceSelectionBoxDirective } from './dock/views/resources/resource-selection-box.directive';
import { ResourcesToolbarComponent } from './dock/views/resources/resources-toolbar/resources-toolbar.component';
import { ResourcesComponent } from './dock/views/resources/resources.component';
import { SelectionBoxComponent } from './dock/views/resources/selection-box/selection-box.component';
import { SaxMsBestGanttComponent } from './saxms-best-gantt.handler.component';

@NgModule({
  imports: [
    MySharedModule,
    ScrollingModule,
    MatRippleModule,
    ImageModule,
    AngularSplitModule,
    LegendModule,
    GanttModule,
    SaxmsTooltipModule,
  ],
  declarations: [
    SaxMsBestGanttComponent,
    SaxMsBestGanttLegend,
    LegendToolbarComponent,
    ResourceSelectionBoxDirective,
    ResourcesComponent,
    ResourcesToolbarComponent,
    DockComponent,
    DockWindowComponent,
    StatusLegendComponent,
    ResourceListComponent,
    ResourceElementComponent,
    DragDirective,
    DragResourceElementComponent,
    SelectionBoxComponent,
    ResourceTooltipDirective,
    ResourceTooltipComponent,
  ],
  exports: [SaxMsBestGanttComponent, SaxMsBestGanttLegend],
  providers: [],
})
export class SaxMsGanttModule {}
