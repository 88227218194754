import { of } from 'rxjs';
import { Action } from '../button/action/action';
import { EButtonDisplayType } from '../button/button-display-type.enum';
import { ContextMenuGroup } from '../contextmenu/context-menu-group';
import { ContextMenuItem } from '../contextmenu/context-menu-item';
import { ContextMenu } from '../contextmenu/contextmenu';
import { IRightClickEvent } from './core/saxms-spreadsheet';
import { FullSpreadsheetComponent } from './full-spreadsheet/full-spreadsheet.component';
import { EDefaultSubmenues } from './model/default-submenues.enum';
import { EDefaultTableButton } from './model/default-table-button.enum';
import { EMenuMode } from './model/menu-mode.enum';

export class TableContextmenu extends ContextMenu {
  private foo = {
    curse: 1,
  };

  constructor(private scope: FullSpreadsheetComponent) {
    super();
    this.setId('table_' + this.scope.getTableTemplate().getId() + '_contextmenu');
  }

  get(rightClickEvent: IRightClickEvent): this {
    const groups: ContextMenuGroup[] = [this.ctxMenuGroup1(this)];
    if (!this.scope.getTableTemplate().insideDisabledSubMenues(EDefaultSubmenues.ROW)) {
      groups.push(this.ctxMenuGroup2(this, rightClickEvent));
    }

    if (this.scope.getTableTemplate().getContextmenu()) {
      groups.push(this.ctxMenuGroup3(this));
    }
    this.setContextMenuGroup(groups);
    return this;
  }

  private ctxMenuGroup1(ctx: ContextMenu): ContextMenuGroup {
    const g: ContextMenuGroup = new ContextMenuGroup()
      .setId('view_context_menu_group')
      .setName('Table.Contextmenu.viewActions');

    const item: ContextMenuItem = this.getContextMenuItem(ctx)
      .setName(
        this.scope.getToolbar().getMenuMode() === EMenuMode.HIDE
          ? 'Table.Action.showToolbar'
          : 'Table.Action.hiddenToolbar'
      )
      .setIcon(this.scope.getToolbar().getMenuMode() === EMenuMode.HIDE ? 'visibility' : 'visibility_off')
      .setId(this.scope.getToolbar().getMenuMode() === EMenuMode.HIDE ? 'showToolbar_context' : 'hiddenToolbar_context')
      .chainActionsBefore(
        new Action().setCb(() =>
          of(this.scope.handleMenumode(this.scope.getToolbar().getMenuMode() === EMenuMode.HIDE ? true : false))
        )
      );

    g.setContextMenuItems(g.getContextMenuItems().concat(item));

    return g;
  }

  private get _rowActionsEnabled(): boolean {
    if (!this.scope.getTableTemplate().getSelectedValue()) {
      return false;
    }
    const match = this.scope
      .getRowDataUtil()
      .getTableRowPlainList()
      .find((r) => r.resourceId === this.scope.getTableTemplate().getSelectedValue().resourceId);
    return this.scope.getSelectionModel().rowActionsEnable && match && match.editEntryRestUrl ? true : false;
  }

  private ctxMenuGroup2(ctx: ContextMenu, rightClickEvent: IRightClickEvent): ContextMenuGroup {
    const g: ContextMenuGroup = new ContextMenuGroup()
      .setId('row_context_menu_group')
      .setName('Table.Contextmenu.rowActions');

    if (this.scope.getTableTemplate().isEditable()) {
      if (!this.scope.getTableTemplate().insideDisableDefaultTableMenuButtons(EDefaultTableButton.EDIT)) {
        g.setContextMenuItems(
          g.getContextMenuItems().concat(
            this.getContextMenuItem(ctx)
              .setEnableBy(() => this._rowActionsEnabled)
              .setName('Table.Action.edit')
              .setIcon('edit')
              .setId('edit_row')
              .chainActionsBefore(new Action().setCb(() => of(this.scope.editRowEntryByContextMenu(rightClickEvent))))
          )
        );
      }
    } else {
      if (!this.scope.getTableTemplate().insideDisableDefaultTableMenuButtons(EDefaultTableButton.DETAILS)) {
        g.setContextMenuItems(
          g.getContextMenuItems().concat(
            this.getContextMenuItem(ctx)
              .setEnableBy(() => this.scope.getSelectionModel().rowActionsEnable)
              .setName('Table.Action.details')
              .setIcon('visibility')
              .setId('row_details')
              .chainActionsBefore(new Action().setCb(() => of(this.scope.editRowEntryByContextMenu(rightClickEvent))))
          )
        );
      }
    }

    if (!this.scope.getTableTemplate().insideDisableDefaultTableMenuButtons(EDefaultTableButton.DELETE)) {
      g.setContextMenuItems(
        g.getContextMenuItems().concat(
          this.getContextMenuItem(ctx)
            .setDisabled(!this.scope.getSelectionModel().rowActionsEnable || !this.scope.tableOptions.editable)
            .setName('Table.Action.delete')
            .setIcon('delete')
            .setId('delete_row')
            .chainActionsBefore(new Action().setCb(() => of(this.scope.deleteRowEntryByContextMenu(rightClickEvent))))
        )
      );
    }

    if (!this.scope.getTableTemplate().insideDisableDefaultTableMenuButtons(EDefaultTableButton.CREATE)) {
      g.setContextMenuItems(
        g.getContextMenuItems().concat(
          this.getContextMenuItem(ctx)
            .setDisabled(!this.scope.tableOptions.add)
            .setName('Table.Action.add')
            .setIcon('ADDROW')
            .setId('add_row')
            .chainActionsBefore(new Action().setCb(() => of(this.scope.addRowEntry())))
        )
      );
    }

    return g;
  }
  private ctxMenuGroup3(ctx: ContextMenu): ContextMenuGroup {
    const g: ContextMenuGroup = new ContextMenuGroup()
      .setId(this.scope.getTableTemplate().getContextmenu().getId())
      .setName(this.scope.getTableTemplate().getContextmenu().getName());

    this.scope
      .getTableTemplate()
      .getContextmenu()
      .getContextMenuItems()
      .forEach((item) => {
        g.setContextMenuItems(
          g.getContextMenuItems().concat(
            this.scope
              .getTemplateAdapter()
              .adapt<ContextMenuItem>(item)
              .chainActions(new Action().setCb(() => of(ctx.getRef().dispose())))
          )
        );
      });

    return g;
  }

  private getContextMenuItem(ctx: ContextMenu): ContextMenuItem {
    const item: ContextMenuItem = new ContextMenuItem()
      .setDisplayType(EButtonDisplayType.ICON_AND_LABEL)
      .chainActions(new Action().setCb(() => of(ctx.getRef().dispose())));
    return item;
  }
}
