import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatLegacyRadioChange as MatRadioChange } from '@angular/material/legacy-radio';
import { EDisplayOrientation } from '@app-modeleditor/components/content/content-element/display-orientation.enum';
import { TemplateService } from '@app-modeleditor/utils/template.service';
import { Template } from 'frontend/src/dashboard/model/resource/template';
import { iif, of, Subject } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';
import { ListSelector } from './list-selector';

@Component({
  selector: 'app-list-selector',
  templateUrl: './list-selector.component.html',
  styleUrls: ['./list-selector.component.scss'],
  providers: [],
})
export class ListSelectorComponent implements OnInit, OnDestroy {
  @Input() template: ListSelector;
  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();
  allSelected: any = false;
  indeterminate = false;
  checkToggle = false;
  filterValue = '';
  displayValues: any[] = [];

  private onKeyUpTimeout: Subject<void> = new Subject<void>();

  constructor(protected _templateApi: TemplateService, private _cd: ChangeDetectorRef) {}

  ngOnDestroy(): void {
    this.onKeyUpTimeout.next();
    this.onKeyUpTimeout.complete();
  }

  get disabled(): boolean {
    if (this.template instanceof Template) {
      return this.template.isDisabled();
    }

    return false;
  }
  isFinished = true;
  toggleOrientation(orientation?: EDisplayOrientation): void {
    this.isFinished = false;
    this.template.setDisplayOrientation(
      orientation ||
        (this.template.getDisplayOrientation() === EDisplayOrientation.HORIZONTAL
          ? EDisplayOrientation.VERTICAL
          : EDisplayOrientation.HORIZONTAL)
    );
    this._cd.detectChanges();
    this.isFinished = true;
  }

  get isVerticalOrientation(): boolean {
    return this.template.getDisplayOrientation() === EDisplayOrientation.VERTICAL ? true : false;
  }

  handleFilterList(event: Event): void {
    this.onKeyUpTimeout.next();
    of(null)
      .pipe(delay(350), takeUntil(this.onKeyUpTimeout))
      .subscribe(() => {
        this.filterValue = (event.target as any).value;
        this.displayValues = this.template
          .getValue()
          .filter(
            (value) =>
              (value.getFloatLeftLines() &&
                (value.getFloatLeftLines() as any[]).filter((stringValue) =>
                  (stringValue as string).toLowerCase().includes(this.filterValue.toLowerCase())
                ).length > 0) ||
              (value.getFloatLeft() && value.getFloatLeft().toLowerCase().includes(this.filterValue.toLowerCase())) ||
              (value.getFloatFull() && value.getFloatFull().toLowerCase().includes(this.filterValue.toLowerCase()))
          );
      });
  }

  ngOnInit(): void {
    this.template.setSelectedValues({});
    this.displayValues = this.template.getValue();

    of(null)
      .pipe(delay(0))
      .subscribe(() => {
        const g = this.template.getValue().find((el) => el.isSelected());
        this.template.setElementData({ selectedIndex: g });
        this.onChangePerformed(null, true);
      });
  }

  /**
   * reverts checked state
   */
  toggleCheck(): void {
    this.checkToggle = !this.checkToggle;

    for (const entry of this.displayValues) {
      entry.setSelected(this.checkToggle);
    }

    this.onChangePerformed(null);
  }

  get numberOfSelected(): number {
    return this.template.getValue().filter((i) => i.isSelected())?.length;
  }

  onChangePerformed(group: MatRadioChange, skipEmit?: boolean): void {
    if (group) {
      this.template.setElementData({ selectedIndex: group.value });
      this.template.getValue().forEach((element) => {
        element.setSelected(element.getUuid() === group.value.uuid);
      });
      this.checkResult(skipEmit);
    } else {
      this.checkResult(skipEmit);
    }

    this.template.setSelectedValues({});
    if (this.template.getValue()) {
      this.template.getValue().forEach((element) => {
        if (element.isSelected()) {
          this.template.getSelectedValues()[element.getResourceId()] = element.getResourceClazz();
          this.template.getSelectedValues()['selectedIndex'] = group?.value;
        }
      });
    }
  }

  checkResult(skipEmit: boolean): void {
    const result = this.template.getValue()
      ? this.template
          .getValue()
          .filter((data) => data.isSelected())
          .map((data) => data.getResourceId())
      : [];

    if (result.length === 0) {
      this.allSelected = false;
      this.indeterminate = false;
    } else if (result.length === this.template.getValue().length) {
      this.allSelected = true;
      this.indeterminate = false;
    } else {
      this.indeterminate = true;
    }

    if (skipEmit) {
      return;
    }

    this.onChange.emit(result);
  }
}
