import { EntryElement } from '@app-modeleditor/components/entry-collection/entry-element';
import { EntryElementValue } from '@app-modeleditor/components/entry-collection/entry-element-value';
import { EFieldType } from '@app-modeleditor/components/entry-collection/field-type.enum';
import { EGanttSubMenuUpdateMessageType } from '@app-modules/saxms-submenu-elements/saxms-submenu.enum';
import {
  IGanttSubMenuSyncMessage,
  IGanttSubMenuUpdateMessage,
} from '@app-modules/saxms-submenu-elements/saxms-submenu.interface';
import { EGanttInstance } from '@gantt/public-api';
import { GlobalUtils } from 'frontend/src/dashboard/global-utils';
import { takeUntil } from 'rxjs/operators';
import { Gantt_General } from '../../general.gantt.component';
import { GanttChildren } from '../../generator/gantt-input.data';
import { GanttEssentialPlugIns } from '../../plugin/e-gantt-essential-plugins';
import { GanttColorizerByAttributePlugIn } from '../../plugin/plugin-list/block-colorizer/by-attribute/colorizer-by-attribute';

export class AttributeColorSelectorElement extends EntryElement {
  constructor(private scope: Gantt_General) {
    super();
  }

  private _internalId: string = GlobalUtils.generateUUID();

  get(data: any): this {
    this.setName(data.name)
      .onChanges((val: EntryElementValue) => {
        this.colorizeByAttribute(val.getValue());

        const value: IGanttSubMenuSyncMessage = {
          internalId: this._internalId,
          value: val.getValue(),
        };
        const message: IGanttSubMenuUpdateMessage = {
          elementId: this.getId(),
          type: EGanttSubMenuUpdateMessageType.SYNC,
          value: value,
        };

        this.scope.submenuService.triggerElementById(message); // trigger sync
      })
      .setId(data.id)
      .setFieldType(EFieldType.COMBO_BOX)
      .setAlwaysEnabled(true)
      .setValue(new EntryElementValue().setValue(null).setAvailableValues([null].concat(this.getAvailableValues())));

    this._listenToActivationChanges();
    return this;
  }

  private _listenToActivationChanges() {
    this.scope.submenuService
      .onUpdateElementState()
      .pipe(takeUntil(this.scope.onDestroy))
      .subscribe((activationData: IGanttSubMenuUpdateMessage) => {
        if (!activationData || activationData.elementId !== this.getId()) {
          return;
        }
        switch (activationData.type) {
          case EGanttSubMenuUpdateMessageType.ACTION:
            this._handleInitialSelection(activationData.value, true);
            break;
          case EGanttSubMenuUpdateMessageType.UPDATE:
            this._updateAttributeList(activationData.value);
            break;
          case EGanttSubMenuUpdateMessageType.SYNC:
            if (activationData.value.internalId === this._internalId) {
              return;
            } // element notifies itself
            this._syncElement(activationData.value.value);
            break;
        }
      });
  }

  /**
   * Handles the initial selection of the colorized blocks set by gantt settings.
   */
  private _handleInitialSelection(value: any, handleColorizing: boolean) {
    this.setFavoriteFromSettings();
    if (!value) {
      return;
    }
    const ganttPluginHandlerService = this.scope.ganttPluginHandlerService;
    const ganttColoizerByAttributePlugIn: GanttColorizerByAttributePlugIn =
      ganttPluginHandlerService.getEssentialPlugIn(GanttEssentialPlugIns.BlockColorizeByAttributePlugIn);

    const match: EntryElementValue = this.getValue<EntryElementValue>()
      .getAvailableValues<any[]>()
      .find((e: EntryElementValue) => e && e.getValue() === value);
    if (!match) {
      // if attribute is not in list
      this.getValue<EntryElementValue>().setValue(null);
      ganttColoizerByAttributePlugIn.resetShiftColorize(false);
      return;
    }
    this.getValue<EntryElementValue>().setValue(match);

    if (handleColorizing) {
      this.colorizeByAttribute(match);
    }
  }

  /**
   * Updates the attribute list.
   */
  private _updateAttributeList(newAttributeList: EntryElementValue[]): void {
    let currentValue = null;
    let colorize = false;

    if (this.getValue<EntryElementValue>().getValue<EntryElementValue>()) {
      currentValue = this.getValue<EntryElementValue>().getValue<EntryElementValue>().getValue();
    }

    // check if value from settings is the same value witch is selected in combo box
    for (const element of this.scope.ganttSettingsService.getGanttSettings().activeSubmenuEntryElements) {
      if (element.id === this.getId()) {
        if (element.value !== currentValue) {
          // if its not the same value -> try to synchronize combo box value
          currentValue = element.value;
          colorize = true;
        }
        break;
      }
    }

    this.setValue(new EntryElementValue().setValue(null).setAvailableValues([null].concat(newAttributeList)));
    this._handleInitialSelection(currentValue, colorize);
  }

  /**
   * Synchronizes elements of sub menu with each other.
   */
  private _syncElement(selectedValue: EntryElementValue) {
    if (!selectedValue) {
      this.getValue<EntryElementValue>().setValue(null);
    } else {
      this._handleInitialSelection(selectedValue.getValue(), false);
    }
  }

  /**
   * Callback function to execute colorization of shift blocks by selected attribute.
   * @param data Event data of triggered submenu element.
   */
  private colorizeByAttribute(selectedValue: EntryElementValue) {
    const ganttPluginHandlerService = this.scope.ganttPluginHandlerService;

    ganttPluginHandlerService
      .getNotifiedIfInitialized()
      .pipe(takeUntil(this.scope.onDestroy))
      .subscribe((isInitialized: boolean) => {
        if (!isInitialized) {
          return;
        }
        const ganttColoizerByAttributePlugIn: GanttColorizerByAttributePlugIn =
          ganttPluginHandlerService.getEssentialPlugIn(GanttEssentialPlugIns.BlockColorizeByAttributePlugIn);
        if (selectedValue === null) {
          ganttColoizerByAttributePlugIn.resetShiftColorize(true);
          return;
        } else {
          ganttColoizerByAttributePlugIn.colorizeGanttBlocksByAttribute(selectedValue.getValue());
          return;
        }
      });
  }

  private getAvailableValues(): EntryElementValue[] {
    const detailKeys: number[] = [];
    let availableValues: EntryElementValue[] = [];
    this.scope.ganttLibService.ganttInstanceService.getInstance(EGanttInstance.DATA_MANIPULATOR).iterateOverDataSet(
      this.scope.ganttResultData.response.hierarchicalPlan.ganttEntries,
      {
        extractAllAdditionalAttributes: (child: GanttChildren) => {
          if (!child.blocks) return;
          for (const block of child.blocks) {
            if (!block || block.disableColorization) {
              continue;
            }
            for (const detailKey in block.details.additionalDetails) {
              if (detailKey.match(/^[0-9]+$/) && detailKeys.indexOf(+detailKey) == -1) {
                // faster parseInt
                detailKeys.push(+detailKey); // faster parseInt
                const attributeData = this.scope.ganttTemplateDataService.getTemplateData().getAttributeMapping()[
                  detailKey
                ];

                // do not add attribute colorizment if localization is empty.
                if (attributeData && attributeData.localization == '') {
                  continue;
                }
                availableValues.push(
                  new EntryElementValue()
                    .setValue(detailKey)
                    .setName(attributeData ? attributeData.localization : detailKey)
                    .setId(detailKey)
                );
              }
            }
          }
        },
      },
      null,
      'children'
    );

    // sort by name
    availableValues = availableValues.sort((a, b) => {
      if (a.getName() < b.getName()) {
        return -1;
      }
      if (a.getName() > b.getName()) {
        return 1;
      }
    });

    return availableValues;
  }

  public setFavoriteFromSettings(): void {
    const settingItem = this.scope.ganttSettingsService
      .getGanttSettings()
      .dropDownFavorites.find((item) => item.key === this.getId());
    if (settingItem) {
      this.getValue<EntryElementValue>()
        .getAvailableValues<any[]>()
        .forEach((element: EntryElementValue) => {
          if (element) {
            element.setFavorite(this.getFavoriteStateOfSettings(element.getId(), settingItem));
          }
        });
      this.sortByFavorite();
    }
  }

  private getFavoriteStateOfSettings(valueId: string, settingItem: any): boolean {
    if (settingItem) {
      if (settingItem.value.includes(valueId)) {
        return true;
      }
    }
    return false;
  }

  public executeFavoriteChange(event: Event, entry: EntryElementValue): void {
    event.stopPropagation();
    event.preventDefault();
    entry.setFavorite(!entry.isFavorite());
    const settingItem = this.scope.ganttSettingsService
      .getGanttSettings()
      .dropDownFavorites.find((item) => item.key === this.getId());
    if (settingItem) {
      if (settingItem.value.includes(entry.getId()) && !entry.isFavorite()) {
        settingItem.value = settingItem.value.filter((value) => value !== entry.getId());
        if (settingItem.value.length === 0) {
          this.scope.ganttSettingsService.getGanttSettings().dropDownFavorites = this.scope.ganttSettingsService
            .getGanttSettings()
            .dropDownFavorites.filter((item) => item.key !== this.getId());
        }
      } else {
        if (entry.isFavorite() && !settingItem.value.includes(entry.getId())) {
          settingItem.value.push(entry.getValue());
        }
      }
    } else {
      if (entry.isFavorite()) {
        this.scope.ganttSettingsService.getGanttSettings().dropDownFavorites.push({
          key: this.getId(),
          value: [entry.getId()],
        });
      }
    }
    this.scope.ganttSettingsService.changeSettings({
      dropDownFavorites: this.scope.ganttSettingsService.getGanttSettings().dropDownFavorites,
    });
    this.scope.ganttSettingsService.saveSettings().subscribe();
    this.sortByFavorite();
  }

  private sortByFavorite() {
    this.getValue<EntryElementValue>().setAvailableValues(
      this.getValue<EntryElementValue>()
        .getAvailableValues<any[]>()
        .sort((a: EntryElementValue, b: EntryElementValue) => {
          if (a && a.getValue() === null) {
            return -1;
          }
          return a && b && a.isFavorite() === b.isFavorite() ? 0 : a && a.isFavorite() ? -1 : 1;
        })
    );
  }
}
