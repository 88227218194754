<ng-container *ngIf="btn">
  <button
    [style.background-color]="btn.getColor()"
    [class.execution-btn-spinner]="isExecuting"
    class="main"
    matRipple
    [id]="btn.getId()"
    [saxmsTooltip]="tooltip"
    [disabled]="getDisabledState()"
    [ngClass]="ICON.replace('custom:', '').toLowerCase()"
    (click)="afterClick($event)"
  >
    <ng-container *ngIf="icon">
      <app-image class="big" [label]="ICON"></app-image>
    </ng-container>
    <button
      *ngIf="btn.getContextmenu()"
      [disabled]="disabled || !editMode"
      mat-icon-button
      class="menu"
      (click)="more($event)"
    >
      <mat-icon>more_vert</mat-icon>
    </button>
    <div class="content">
      <!-- show three lines of text if there is enough space (no tags, no receivers) -->
      <div [ngClass]="btn.getTags()?.length || btn.getReceiverDetails()?.length ? 'description' : 'three-line-clamp'">
        {{ NAME | translate }}
      </div>
      <div class="tags" *ngIf="btn.getTags()?.length">
        <ng-container *ngFor="let tag of btn.getTags()">
          <span class="tag" [style.background-color]="tag.getColor()">
            {{ tag.getValue() }}
          </span>
        </ng-container>
      </div>
      <div class="receivers" *ngIf="btn.getReceiverDetails()?.length">
        <ng-container *ngFor="let receiver of btn.getReceiverDetails(); let idx = index">
          <span class="receiver-item">
            {{ receiver }}
          </span>
        </ng-container>
      </div>
    </div>
    <div class="footer">
      <div class="footer-wider" *ngIf="btn.getAdditionalDetails().length > 0">
        <span class="footer-item" *ngFor="let detail of btn.getAdditionalDetails()?.slice(0, 5)">{{ detail }}</span>
      </div>
      <ng-container *ngFor="let key of btn.getIconDetails() | keyvalue">
        <app-image class="detail-icon" [badge]="key.value" [label]="key.key"></app-image>
      </ng-container>
    </div>
  </button>
</ng-container>
