import {
  Component,
  ComponentRef,
  ContentChild,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { TemplateComponent } from '@app-modeleditor/components/template/template.component';
import { TemplateService } from '@app-modeleditor/utils/template.service';
import { Subject } from 'rxjs';
import { ContentElement } from './content-element';

@Component({
  selector: 'app-content-element',
  templateUrl: './content-element.component.html',
  styleUrls: ['./content-element.component.scss'],
})
export class ContentElementComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('templatewrapper') templatewrapper: ElementRef;
  @Input('contentElement') ce: ContentElement;
  @ContentChild('elementsInner') elements: any;
  @HostBinding('class') hostClass;
  @Input() fullscreen: boolean;
  @Input() root;
  @Input() resourceId: string;
  @Input() contentId: string;
  private ngDestroy: Subject<void> = new Subject<void>();

  constructor(private _templateApi: TemplateService) {}

  /**
   * register contentElement and add display orientation
   */
  register(): void {
    if (this.ce) {
      // this._templateApi.registerTemplate(this.resourceId, this.ce);
      if (!this.ce['restUrl']) {
        return;
      }

      if (this.ce.getDisplayOrientation()) {
        this.hostClass = this.ce.getDisplayOrientation().toLowerCase();
      }
    }
  }

  afterSlotInit(instance: ComponentRef<TemplateComponent>, ce: ContentElement): void {
    instance.instance.resourceId = this.resourceId || ce.getResourceId();
    instance.instance.root = this.root;
    instance.instance.contentId = this.contentId;
  }

  ngOnChanges(simpleChanges: SimpleChanges): void {
    if (simpleChanges.ContentElement) {
      this.register();
    }
  }

  /**
   * angulars on init hook
   * @returns void
   */
  ngOnInit(): void {
    this.register();
  }

  /**
   * angular on destroy hook
   * @returns void
   */
  ngOnDestroy(): void {
    if (this.ce) {
      // this._templateApi.unregisterTemplate(this.ce.getId());
    }
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }
}
