import { ComponentRef, Injectable } from '@angular/core';
import { OverlayService } from '@app-modeleditor/components/lightbox/overlay/overlay.service';
import { ProgressDialogComponent } from './progress-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class ProgressDialogService {
  private componentRef: ComponentRef<ProgressDialogComponent>;
  constructor(private overlayService: OverlayService) {}

  public openProgressDialog(): void {
    if (!this.componentRef) {
      this.componentRef = this.overlayService.create(ProgressDialogComponent, null, null, {
        overlayOrigin: 'bottom',
        disableCloseByBackdrop: true,
        backdrop: false,
        postion: {
          bottom: '5px',
          right: '5px',
        },
      }).componentRef;

      this.componentRef.onDestroy((_) => (this.componentRef = null));
    }
  }
}
