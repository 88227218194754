<div
  class="wrapper"
  [class.print-ready]="printer.isBusy()"
  [class.landscape]="printer.isToggled()"
  [class.portrait]="!printer.isToggled()"
>
  <div
    *ngIf="printer.hasLivePreview()"
    cdkDrag
    [style.margin-left]="'-' + width / 2 + 'px'"
    [style.margin-top]="'-' + height / 2 + 'px'"
    [style.height]="height + 'px'"
    [style.width]="width + 'px'"
    class="paper mat-elevator-z4"
    #wrapper
    [class.print-ready]="true"
    [style.transform]="getTransformation(wrapper)"
  >
    <div #displayContainer></div>
  </div>
  <div
    *ngIf="!printer.hasLivePreview()"
    [style.height]="height + 'px'"
    [style.width]="width + 'px'"
    class="outside-viewport paper mat-elevator-z4"
    #wrapper
    [class.print-ready]="true"
  >
    <div #displayContainer></div>
  </div>
</div>
