import { BestGantt } from '../../../../main';
import { IGanttShiftTranslationEvent } from '../../translation-events/translation-event.interface';

/**
 * Abstract superclass for multi drag strategies.
 */
export abstract class GanttShiftsMultiDragStrategy {
  protected _ganttDiagram: BestGantt;

  constructor(ganttDiagram: BestGantt) {
    this._ganttDiagram = ganttDiagram;
  }

  /**
   * @param dragStartEvent
   */
  public abstract executeTranslationStart(dragStartEvent: IGanttShiftTranslationEvent): void;

  /**
   * @param draggingEvent
   */
  public abstract executeDuringTranslation(draggingEvent: IGanttShiftTranslationEvent): void;

  /**
   * @param dragEndEvent
   */
  public abstract executeTranslationEnd(dragEndEvent: IGanttShiftTranslationEvent): void;
}
