import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { ConfigService } from '@core/config/config.service';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { AuthActions } from '../../login/data-access/auth.actions';
import { AuthState } from '../../login/data-access/auth.state';
import { ChangePasswordLightboxComponent } from '../../password/change-password-lightbox/change-password-lightbox.component';

type CanActivateResult = boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree>;

@Injectable({ providedIn: 'root' })
export class AuthGuard  {
  changePasswordDialogRef: MatDialogRef<ChangePasswordLightboxComponent>;

  constructor(
    private store: Store,
    private router: Router,
    private configService: ConfigService,
    private dialog: MatDialog,
    private msalService: MsalService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): CanActivateResult {
    return this.store.dispatch(new AuthActions.GetUser()).pipe(
      catchError((e) =>
        this.store.dispatch(new AuthActions.Logout()).pipe(
          tap(() => {
            if (!state.url.includes('auth')) {
              this.router.navigate(['/', 'auth', 'login']);
            }
          })
        )
      ),
      switchMap(() => this.store.select(AuthState.user)),
      map((u) => {
        if (
          this.configService.access().security?.enablePasswordExpiration &&
          this.store.selectSnapshot(AuthState.isExpired) &&
          !this.msalService.instance?.getAllAccounts()?.length // if not logged in with SSO
        ) {
          if (!this.changePasswordDialogRef) {
            this.changePasswordDialogRef = this.dialog.open(ChangePasswordLightboxComponent, {
              disableClose: true,
            });
            this.changePasswordDialogRef.afterClosed().subscribe(() => {
              this.changePasswordDialogRef = null;
              // this.router.navigate(['/', 'app']);
            });
          }
        }

        return true;
      })
    );
  }
}
