import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, OperatorFunction } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RequestService {
  constructor(private http: HttpClient) {}

  /**
   * calls a rest service
   * @param method ERequestMethod
   * @param url string
   * @param options RequestOptions
   */
  public call(method: ERequestMethod, url: string, options?: RequestOptions): Observable<any> {
    const opt: RequestOptions = options || new RequestOptions();
    return this.http.request(method, url, opt.getHttpOptions());
  }
}

export enum ERequestMethod {
  GET = 'GET',
  POST = 'POST',
  DELETE = 'DELETE',
  UPDATE = 'UPDATE',
}

export class RequestOptions {
  pipe(arg0: OperatorFunction<unknown, void>): RequestOptions {
    throw new Error('Method not implemented.');
  }
  private httpOptions: any;

  public getHttpOptions(): any {
    return this.httpOptions || {};
  }

  public setHttpOptions(httpOptions: any): this {
    this.httpOptions = httpOptions;
    return this;
  }
  constructor() {}
}
