import { BestGantt } from '../main';

/**
 * Abstract Main Class for Component Events for undo / redo.
 *
 * @author Florian Freier
 * @constructor
 * @class
 * @abstract
 */
export class ComponentEvent {
  timeStamp: number;
  eventName: string;
  arguments: any;
  ref: any;
  ganttDiagram: BestGantt;

  constructor() {
    if (new.target === ComponentEvent) {
      // make sure this class doesnt get instanciated.
      throw new TypeError('Cannot construct Abstract instances directly');
    }
    this.timeStamp = Date.now();
    this.eventName = null;
    this.arguments = null;
    this.ref = null;

    /**
     * @type {BestGantt}
     */
    this.ganttDiagram = null;
  }

  /**
   * Undo method. Implemented by extending class.
   * @abstract
   * @keywords abstract undo component
   * @returns {boolean} true if undo was succesfull, else false
   */
  undo() {
    console.error('not implemented yet!');
    return false;
  }

  /**
   * Redo method. Implemented by extending class.
   * @abstract
   * @keywords abstract redo component
   * @returns {boolean} true if redo was succesfull, else false
   */
  redo() {
    console.error('not implemented yet!');
    return false;
  }

  getEventDescription() {
    console.error('MISSING DESCRIPTION METHOD IMPLEMENTATION!');
  }

  // GETTER & SETTER
  /**
   * Return the name of the event.
   * @returns {String} name of event
   */
  getName() {
    return this.eventName;
  }
  /**
   * Returns the reference to the component.
   * @returns {any} reference to the component
   */
  getReference() {
    return this.ref;
  }
  /**
   * Returns all given arguments in an array.
   * @returns {any[]} array of all arguments
   */
  getArguments() {
    return this.arguments;
  }
  /**
   * Returns the timestamp when event was invoked.
   * @returns {number} milliseconds unix time
   */
  getTimepoint() {
    return this.timeStamp;
  }

  /**
   * Sets the reference to the component that invoked the event.
   * @param {any} reference reference to the component that invoked event
   */
  setReference(reference) {
    this.ref = reference;
  }
  /**
   * Sets name of the event.
   * @param {String} name the name of the event
   */
  setName(name) {
    this.eventName = name;
  }
  /**
   * Sets the arguments for the event.
   * @param {any[]} args Array of the arguments for the event.
   */
  setArguments(args) {
    this.arguments = args;
  }
  /**
   * Sets a reference to the ganttDiagram for the Event.
   * @param {BestGantt} ganttDiagram Reference to the GanttDiagram.
   */
  setGanttReference(ganttDiagram) {
    this.ganttDiagram = ganttDiagram;
  }

  addAnotherArgument(arg) {
    if (this.ref.eventLogging) this.arguments.push(arg);
  }
  addMoreArguments(args) {
    if (this.ref.eventLogging) {
      for (let i = 0; i < args.length; i++) this.addAnotherArgument(args[i]);
    }
  }
}
