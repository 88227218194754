import { FocusMonitor } from '@angular/cdk/a11y';
import { DecimalPipe } from '@angular/common';
import { Component, ElementRef, HostBinding, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, AbstractControlDirective, FormBuilder, FormGroup, NgControl } from '@angular/forms';
import { MatLegacyFormFieldControl as MatFormFieldControl } from '@angular/material/legacy-form-field';
import { EntryElementValue } from '@app-modeleditor/components/entry-collection/entry-element-value';
import { Subject } from 'rxjs';
import { IDateSet } from './date-input.interface';

@Component({
  selector: 'app-date-input',
  templateUrl: './date-input.component.html',
  styleUrls: ['./date-input.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: DateInputComponent }],
})
export class DateInputComponent implements OnInit, OnDestroy, MatFormFieldControl<EntryElementValue> {
  @Input() set showTime(bool: boolean) {
    this._showTime = bool;
    if (this.entryElementValue) {
      this.setDateInput(this.entryElementValue);
    }
    this.stateChanges.next();
  }

  @Input()
  set value(input: EntryElementValue) {
    this.entryElementValue = input;
    this.setDateInput(input);
    this.stateChanges.next();
  }

  @ViewChild('hour') hourInput: ElementRef<HTMLInputElement>;

  static nextId = 0;
  public _showTime = false;
  public entryElementValue: EntryElementValue;

  @HostBinding() id = `date-input-${DateInputComponent.nextId++}`;
  parts: FormGroup;
  stateChanges = new Subject<void>();
  placeholder: string;
  ngControl: NgControl | AbstractControlDirective;
  focused = false;
  empty: boolean;
  shouldLabelFloat: boolean;
  required: boolean;
  disabled: boolean;
  errorState: boolean;
  controlType?: string;
  autofilled?: boolean;
  userAriaDescribedBy?: string;
  onChange = (_: any) => {};

  constructor(
    private fb: FormBuilder,
    private focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    private decimalPipe: DecimalPipe
  ) {}
  ngOnDestroy(): void {
    this.stateChanges.complete();
    this.focusMonitor.stopMonitoring(this._elementRef);
  }
  setDescribedByIds(ids: string[]): void {
    const controlElement = this._elementRef.nativeElement.querySelector('.group')!;
    controlElement.setAttribute('aria-describedby', ids.join(' '));
  }
  onContainerClick(event: MouseEvent): void {}
  ngOnInit(): void {}

  onFocusIn(event: FocusEvent) {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  onFocusOut(event: FocusEvent) {
    if (!this._elementRef.nativeElement.contains(event.relatedTarget as Element)) {
      this.focused = false;
      this.stateChanges.next();
    }
  }

  private setDateInput(input: EntryElementValue): void {
    const group = {
      day:
        input.getValue<IDateSet>().day !== null
          ? this.decimalPipe.transform(input.getValue<IDateSet>().day, '2.0-0')
          : '',
      month:
        input.getValue<IDateSet>().month !== null
          ? this.decimalPipe.transform(input.getValue<IDateSet>().month, '2.0-0')
          : '',
      year: input.getValue<IDateSet>().year !== null ? input.getValue<IDateSet>().year + '' : '',
    };
    if (this._showTime) {
      group['hour'] =
        input.getValue<IDateSet>().hour !== null
          ? this.decimalPipe.transform(input.getValue<IDateSet>().hour, '2.0-0')
          : '';
      group['minute'] =
        input.getValue<IDateSet>().minute !== null
          ? this.decimalPipe.transform(input.getValue<IDateSet>().minute, '2.0-0')
          : '';
    }
    this.parts = this.fb.group(group);
    this.parts.setValue(group);
  }

  handleInput(event: InputEvent, control: AbstractControl, nextElement?: HTMLInputElement, isYear = false): void {
    if (isYear && this._showTime) {
      nextElement = this.hourInput.nativeElement;
    }

    const isDot = event.data === '.' || event.data === ':';

    control.setValue(control.value.replace(/\D/g, '')); // remove all non-digits
    const n = this.parts.value;
    const day = isNaN(parseInt(n.day)) ? null : parseInt(n.day);
    const month = isNaN(parseInt(n.month)) ? null : parseInt(n.month);
    const year = isNaN(parseInt(n.year)) ? null : parseInt(n.year);
    const hour = isNaN(parseInt(n.hour)) ? null : parseInt(n.hour);
    const minute = isNaN(parseInt(n.minute)) ? null : parseInt(n.minute);
    this.entryElementValue.setValue({ day, month, year, hour, minute });

    if (
      (control.value && nextElement && control.value.length === (isYear ? 4 : 2)) ||
      (isDot && nextElement && control.value.length)
    ) {
      this.autoFocusNext(control, nextElement, undefined, isYear);
    }
    this.onChange(this.value);
  }

  autoFocusNext(control: AbstractControl, nextElement?: HTMLInputElement, event?: KeyboardEvent, isYear = false): void {
    if (isYear && this._showTime) {
      nextElement = this.hourInput.nativeElement;
    }

    if (
      (!control.errors && nextElement && !event) ||
      (control.value?.length || 0) === (event?.target as HTMLInputElement)?.selectionStart
    ) {
      control.setValue(isYear ? control.value : this.decimalPipe.transform(control.value, '2.0-0')); // add leading zero
      event?.preventDefault();
      this.focusMonitor.focusVia(nextElement, 'program');
      if ((event?.target as HTMLInputElement)?.selectionStart) {
        (event.target as HTMLInputElement).selectionStart = nextElement.value.length; // set cursor to end
      }
    }
  }

  autoFocusPrev(control: AbstractControl, prevElement: HTMLInputElement, event?: KeyboardEvent, isYear = false): void {
    if ((event?.target as HTMLInputElement)?.selectionStart === 0) {
      control.setValue(isYear ? control.value : this.decimalPipe.transform(control.value, '2.0-0')); // add leading zero
      event?.preventDefault();
      this.focusMonitor.focusVia(prevElement, 'program');
    }
  }
}
