import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { PipeModule } from 'frontend/src/dashboard/shared/pipes/pipe.module';
import { DataSelectorComponent } from './data-selector.component';
import { ExperimentSelectorComponent } from './experiment-selector.component';
import { ExtraSelectorComponent } from './extra-selector.component';
import { HelpSelectorComponent } from './help-selector.component';
import { HorizonSelecterComponent } from './horizon-selector.component';
import { ModelSelectorComponent } from './model-selector.component';
import { SelectorComponent } from './selector.component';
import { SolverSelectorComponent } from './solver-selector.component';
import { StyleSelectorComponent } from './style-selector.component';
@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    MatDividerModule,
    MatSelectModule,
    FormsModule,
    MatMenuModule,
    MatIconModule,
    PipeModule,
    MatTooltipModule,
    MatButtonModule,
    MatListModule,
  ],
  declarations: [
    DataSelectorComponent,
    SolverSelectorComponent,
    StyleSelectorComponent,
    SelectorComponent,
    HorizonSelecterComponent,
    HelpSelectorComponent,
    ModelSelectorComponent,
    ExtraSelectorComponent,
    ExperimentSelectorComponent,
  ],
  exports: [
    DataSelectorComponent,
    SolverSelectorComponent,
    StyleSelectorComponent,
    SelectorComponent,
    HorizonSelecterComponent,
    HelpSelectorComponent,
    ModelSelectorComponent,
    ExtraSelectorComponent,
    ExperimentSelectorComponent,
  ],
  providers: [],
})
export class SelectorModule {}
