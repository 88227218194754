import { Observable, of } from 'rxjs';
import { GanttPluginHandlerService } from '../../../plugin/gantt-plugin-handler.service';
import { GanttLibService } from 'frontend/src/dashboard/gantt/gantt/gantt-lib.service';

export class GanttPredefinedLocalAction {
  public name: string;

  constructor(
    protected ganttPluginHandlerService: GanttPluginHandlerService,
    protected ganttLibService: GanttLibService
  ) {}

  public executeAction(action: any): Observable<any> {
    return of(null);
  }
}
