import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { PipeModule } from 'frontend/src/dashboard/shared/pipes/pipe.module';
import { ListSelectorComponent } from './list-selector.component';

@NgModule({
  imports: [
    CommonModule,
    MatRadioModule,
    MatCheckboxModule,
    MatFormFieldModule,
    FormsModule,
    TranslateModule,
    MatTooltipModule,
    PipeModule,
    ScrollingModule,
    MatInputModule,
  ],
  declarations: [ListSelectorComponent],
  exports: [ListSelectorComponent],
})
export class ListSelectorModule {}
