import { inject, Injectable, Type } from '@angular/core';
import { TemplateAdapter } from '@app-modeleditor/utils/template-factory.service';
import { Store } from '@ngxs/store';
import { Adapter } from '../../../core/adapter';
import { ContentElement } from '../content/content-element/content-element';
import { EMenuMode } from '../content/content-element/menu-mode.enum';
import { LinkAdapter } from '../entry-collection/link/link-adapter.service';
import { ILink } from '../entry-collection/link/link.interface';
import { StructuralAdapter } from './structural-factory.service';

@Injectable({
  providedIn: 'root',
})
export class ContentElementAdapter implements Adapter<ContentElement> {
  private readonly store = inject(Store);

  constructor(private structuralFactoryService: StructuralAdapter, private _linkAdapter: LinkAdapter) {}

  adapt(item: any): ContentElement {
    if (!item) {
      return null;
    }

    return this._addData(this.structuralFactoryService.inherit<ContentElement>(ContentElement, item), item);
  }

  public inheritFrom<T extends ContentElement>(scope: TemplateAdapter, type: Type<T>, item: any): T {
    if (!item) {
      return null;
    }

    return this._addData(this.structuralFactoryService.inheritFrom<T>(scope, type, item), item);
  }

  private _addData<T extends ContentElement>(element: T, data: any): T {
    const t: T = element
      .setShowTitle(data.showTitle)
      .setResizeable(data.resizeable)
      .setMenuMode(data.menuMode || EMenuMode.SHOW)
      .setColor(data.color)
      .set_Class(data._class)
      .setHeadline(data.headline)
      .setDto(data);

    const links: ILink[] = data.links;

    if (links) {
      element.setLinks(links.map((link) => this._linkAdapter.adapt(link)));
    }

    return t;
  }

  applyValues<T extends ContentElement>(scope: TemplateAdapter, item: T, values: any): T {
    throw new Error('Method not implemented.');
  }

  public inherit<T extends ContentElement>(type: Type<T>, data: any): T {
    if (!data) {
      return null;
    }
    return this._addData(this.structuralFactoryService.inherit<T>(type, data), data);
  }
}
