import * as d3 from 'd3';
import { Observable, Subject, takeUntil } from 'rxjs';
import { EGanttXAxisPosition } from '../config/config-data/x-axis-config.data';
import { GanttConfig } from '../config/gantt-config';
import { BestGantt } from '../main';
import { GanttHTMLStructureData, GanttScrollContainerHTMLStructureData } from './html-structure.data';
import { NodeProportionsState } from './node-proportion-state/node-proportion-state';
import { ResizeHandler } from './resize-handler/resize-handler';
import { EGanttScrollContainer } from './scroll-container.enum';
import { GanttScrollContainerSizeHandler } from './size-handler/scroll-container-size-handler';
import { GanttSizeHandler } from './size-handler/size-handler';
import { VerticalScrollbarBuilder } from './vertical-scrollbar/vertical-scrollbar-builder';

/**
 * This class builds, handles and stores data about the HTML-structure of the gantt diagram.
 * @keywords structure, html, div, container, builder, resize, headline
 */
export class GanttHTMLStructureBuilder {
  private _parentNode: d3.Selection<HTMLElement, undefined, d3.BaseType, undefined>;
  private _htmlContainer = new GanttHTMLStructureData();

  private _verticalScrollbarBuilder: VerticalScrollbarBuilder;

  private _sizeHandler: GanttSizeHandler;
  private _scrollContainerSizeHandler: GanttScrollContainerSizeHandler;
  private _resizeHandler: ResizeHandler;

  private _onDestroySubject: Subject<void> = new Subject<void>();

  /**
   * @param _ganttDiagram Reference to the gantt diagram to handle the HTML structure for.
   */
  constructor(private _ganttDiagram: BestGantt) {
    this._verticalScrollbarBuilder = new VerticalScrollbarBuilder(this._config);
  }

  /**
   * Builds gantt html structure.
   * @keywords structure, html, div, container, builder, initial, axes, headline
   * @param parentNode DOM node in which the structure should be rendered into.
   * @param ganttUpdateCallback Function stack which will be executed after resizing width of y axis.
   */
  public init(parentNode: HTMLElement, ganttYAxisCallback: (() => void)[], ganttUpdateCallback: (() => void)[]): void {
    this._parentNode = d3.select(parentNode);

    // build x axis container
    this._buildXAxisContainer(this._parentNode);

    // build scroll container wrapper
    this._buildScrollContainerWrapper(this._parentNode);

    // build scroll containers
    this._buildDefaultScrollContainer(this._htmlContainer.scrollContainerWrapper);
    this._buildStickyScrollContainer(this._htmlContainer.scrollContainerWrapper);

    // build scroll container overlay
    this._buildScrollContainerOverlay(this._htmlContainer.scrollContainerWrapper);

    // init update callbacks
    this._addUpdateCallbacks();

    // build size-handler
    this._initSizeHandler(ganttYAxisCallback, ganttUpdateCallback);
    this._initScrollContainerSizeHandler();
    this._initResizeHandler();
  }

  private _addUpdateCallbacks(): void {
    this._config.addXAxesConfigChangedCallback('updateShiftWrapperAndYAxisHeight', () => {
      // resize ShiftContain and yAxis vertical according to xAxesHeightChange
      const xAxisContainerHeight = this._config.isXAxisVisible()
        ? this._config.xAxisContainerHeight()
        : this._config.xAxisScrollBarHeight();
      this._htmlContainer.xAxis.style('height', function () {
        if (xAxisContainerHeight) {
          return `${xAxisContainerHeight}px`;
        }
      });
      this._htmlContainer.xAxis.select('.gantt_x-axis').style('height', function () {
        if (xAxisContainerHeight) {
          return `${xAxisContainerHeight}px`;
        }
      });
      this._htmlContainer.xAxis.select('.gantt_x-axis_placeholder').style('height', function () {
        if (xAxisContainerHeight) {
          return `${xAxisContainerHeight}px`;
        }
      });
      this._updateScrollContainerWrapper();
    });

    this._config
      .onVerticalScrollbarSettingsChanged()
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        // update scrollbar
        this._addXAxisScrollbarPlaceholder();
        this._verticalScrollbarBuilder.buildVerticalScrollbar(
          this.getVerticalScrollContainer(EGanttScrollContainer.DEFAULT)
        );
        this._verticalScrollbarBuilder.buildVerticalScrollbar(
          this.getVerticalScrollContainer(EGanttScrollContainer.STICKY_ROWS)
        );

        // update x axis width
        const leftWidth = parseInt(
          this._htmlContainer.scrollContainer[EGanttScrollContainer.DEFAULT].yAxis.style('width')
        );
        this._htmlContainer.xAxis?.select('.gantt_x-axis').style('width', () => {
          if (this._config.yAxisWidth()) {
            return `calc(100% - ${leftWidth + this._config.verticalScrollbarWidth}px)`;
          }
        });

        // update sticky row scroll container width
        this._updateStickyScrollContainer();
      });

    this._config.addXAxesConfigChangedCallback('GanttHTMLStructureBuilder_update-scrollbar-placeholder', () => {
      const scrollbarPlaceholder = this.getVerticalScrollbarPlaceholderContainer();
      if (scrollbarPlaceholder.empty()) return; // no placeholder to update

      // update height
      const currentHeight = scrollbarPlaceholder.node().clientHeight;
      const newHeight = this._config.isXAxisVisible() ? this._config.xAxisContainerHeight() : 0;
      if (currentHeight !== newHeight) {
        scrollbarPlaceholder.style('height', `${newHeight}px`);
      }

      // update height of bottom border (white area above vertical scrollbar)
      const currentBottomBorderHeight = parseInt(scrollbarPlaceholder.node().style.borderBottomWidth);
      const newBottomBorderHeight = this._config.xAxisScrollBarHeight();
      if (currentBottomBorderHeight !== newBottomBorderHeight) {
        scrollbarPlaceholder.style('border-bottom', `${this._config.xAxisScrollBarHeight()}px solid white`);
      }
    });

    // insert/detach scroll container for sticky rows when the config changes
    this._config
      .onShowStickyRowsChanged()
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => this._updateStickyScrollContainer());

    // update position of x axis container when the config changes
    this._config
      .onXAxisPositionChanged()
      .pipe(takeUntil(this.onDestroy))
      .subscribe((xAxisPosition) => this.setXAxisPosition(xAxisPosition));
  }

  /**
   * Initializes the size handler to handle the resizing of scroll containers and their children.
   * @param ganttYAxisCallback
   * @param ganttUpdateCallback
   */
  private _initSizeHandler(ganttYAxisCallback: (() => void)[], ganttUpdateCallback: (() => void)[]): void {
    this._sizeHandler = new GanttSizeHandler(this._config, this._nodeProportionsState, this);
    this._sizeHandler.init(ganttUpdateCallback, ganttYAxisCallback);
  }

  /**
   * Initializes the {@link GanttScrollContainerSizeHandler} to handle changes in scroll container sizes.
   */
  private _initScrollContainerSizeHandler(): void {
    this._scrollContainerSizeHandler = new GanttScrollContainerSizeHandler(this, this._config);
  }

  /**
   * Initializes the resize handler to listen to proportion changes of the scroll container wrapper.
   */
  private _initResizeHandler(): void {
    this._resizeHandler = new ResizeHandler(this._ganttDiagram);
  }

  //
  // LIFE CYCLE: BUILD
  //

  /**
   * Builds the x axis wrapper.
   * @param parent D3 selection of the HTML node to build the x axis wrapper into.
   */
  private _buildXAxisContainer(parent: d3.Selection<HTMLElement, undefined, d3.BaseType, undefined>): void {
    this._htmlContainer.xAxis = parent.append('div').attr('class', 'gantt_x-axis_wrapper');

    // build x-Axis placeholder / y-axis head
    this._htmlContainer.xAxis
      .append('div')
      .attr('class', 'gantt_x-axis_placeholder')
      .style('width', () => {
        if (this._config.yAxisWidth()) {
          return this._config.yAxisWidth() + 'px';
        }
      })
      .style('height', () => {
        if (this._config.xAxisContainerHeight()) {
          return this._config.xAxisContainerHeight() + 'px';
        }
      });

    // build x-Axis container
    this._htmlContainer.xAxis
      .append('div')
      .attr('class', 'gantt_x-axis')
      .style('width', () => {
        if (this._config.yAxisWidth()) {
          return `calc(100% - ${this._config.yAxisWidth() + this._config.verticalScrollbarWidth}px)`;
        }
      })
      .style('height', () => {
        if (this._config) {
          return this._config.xAxisContainerHeight() + 'px';
        }
      });

    // build scrollbar placeholder
    this._addXAxisScrollbarPlaceholder();

    this._nodeProportionsState.setXAxisProportions({
      height: this._htmlContainer.xAxis.node().clientHeight,
      width: this._htmlContainer.xAxis.node().clientWidth,
    });
  }

  private _buildScrollContainerWrapper(parent: d3.Selection<HTMLElement, undefined, d3.BaseType, undefined>): void {
    this._htmlContainer.scrollContainerWrapper = parent.append('div').attr('class', 'gantt_scroll_container_wrapper');
    this._updateScrollContainerWrapper();
  }

  /**
   * Builds the wrapper for elements in front of all scroll containers.
   * @param parent D3 selection of the HTML node to build the scroll container overlay into.
   */
  private _buildScrollContainerOverlay(parent: d3.Selection<HTMLDivElement, undefined, d3.BaseType, undefined>): void {
    this._htmlContainer.scrollContainerOverlay = parent.append('div').attr('class', 'gantt_scroll_wrapper_overlay');
  }

  /**
   * Builds a scroll wrapper containing wrappers for y axis and shifts.
   * @param parent D3 selection of the HTML node to build the scroll container into.
   */
  private _buildDefaultScrollContainer(parent: d3.Selection<HTMLDivElement, undefined, d3.BaseType, undefined>): void {
    const htmlContainer = this._htmlContainer.scrollContainer[EGanttScrollContainer.DEFAULT];
    htmlContainer.scrollContainer = parent
      .append('div')
      .attr('class', `gantt_scroll_wrapper ${EGanttScrollContainer.DEFAULT}`)
      .style('height', '100%');

    this._verticalScrollbarBuilder.buildVerticalScrollbar(htmlContainer.scrollContainer);

    // build y axis and shift wrapper
    this._buildYAxisContainer(htmlContainer.scrollContainer, htmlContainer);
    this._buildShiftContainer(htmlContainer.scrollContainer, htmlContainer);
  }

  /**
   * Builds a scroll wrapper for sticky rows containing wrappers for y axis and shifts.
   * @param parent D3 selection of the HTML node to build the scroll container into.
   * @param htmlContainer HTML structure data container to store d3 selections of built elements into.
   */
  private _buildStickyScrollContainer(parent: d3.Selection<HTMLDivElement, undefined, d3.BaseType, undefined>): void {
    const htmlContainer = this._htmlContainer.scrollContainer[EGanttScrollContainer.STICKY_ROWS];
    htmlContainer.scrollContainer = parent
      .append('div')
      .attr('class', `gantt_scroll_wrapper ${EGanttScrollContainer.STICKY_ROWS}`)
      .style('height', '10px');

    this._verticalScrollbarBuilder.buildVerticalScrollbar(htmlContainer.scrollContainer);

    // build y axis and shift wrapper
    this._buildYAxisContainer(htmlContainer.scrollContainer, htmlContainer);
    this._buildShiftContainer(htmlContainer.scrollContainer, htmlContainer);

    // detach scroll container if sticky rows is disabled
    this._updateStickyScrollContainer();
  }

  /**
   * Builds a shift wrapper.
   * @param parent D3 selection of the HTML node to build the shift wrapper into.
   * @param htmlContainer HTML structure data container to store d3 selections of built elements into.
   */
  private _buildShiftContainer(
    parent: d3.Selection<HTMLDivElement, undefined, d3.BaseType, undefined>,
    htmlContainer: GanttScrollContainerHTMLStructureData
  ): void {
    htmlContainer.shifts = parent.append('div').attr('class', 'gantt_shifts_wrapper');
  }

  /**
   * Builds a y axis wrapper.
   * @param parent D3 selection of the HTML node to build the y axis wrapper into.
   * @param htmlContainer HTML structure data container to store d3 selections of built elements into.
   */
  private _buildYAxisContainer(
    parent: d3.Selection<HTMLDivElement, undefined, d3.BaseType, undefined>,
    htmlContainer: GanttScrollContainerHTMLStructureData
  ): void {
    htmlContainer.yAxis = parent
      .append('div')
      .attr('class', 'gantt_y-axis')
      .style('width', () => {
        if (this._config.yAxisWidth() != undefined) {
          this._nodeProportionsState.setYAxisProportions({
            width: this._config.yAxisWidth(),
            height: this._nodeProportionsState.getYAxisProportions().height,
          });
          return this._config.yAxisWidth() + 'px';
        }
      });
  }

  private _addXAxisScrollbarPlaceholder(): void {
    const scrollbarPlaceholderClass = 'gantt_x-axis_placeholder-right';
    this._htmlContainer.xAxis.selectAll(`.${scrollbarPlaceholderClass}`).remove(); // remove if already existing

    if (this._config.verticalScrollbarWidth <= 0) return; // no placeholder needed

    // build placeholder
    this._htmlContainer.xAxis
      .append('div')
      .attr('class', scrollbarPlaceholderClass)
      .style('width', `${this._config.verticalScrollbarWidth}px`)
      .style('height', () => {
        if (this._config) {
          const height = this._config.isXAxisVisible() ? this._config.xAxisContainerHeight() : 0;
          return `${height}px`;
        }
      })
      .style('border-bottom', `${this._config.xAxisScrollBarHeight()}px solid white`);
  }

  //
  // LIFE CYCLE: UPDATE
  //

  /**
   * Updates the proportions of the default scroll container.
   */
  private _updateScrollContainerWrapper(): void {
    const xAxisHeight = this._config.xAxisContainerHeight();
    this.getVerticalScrollContainerWrapper().style('height', `calc(100% - ${xAxisHeight}px)`);
  }

  /**
   * Updates the visibility of the sticky scroll container.
   */
  private _updateStickyScrollContainer(): void {
    const scrollContainerOverlay = this._htmlContainer.scrollContainerOverlay;
    const stickyHtmlStructure = this._htmlContainer.scrollContainer[EGanttScrollContainer.STICKY_ROWS];

    // update visibility
    if (this._config.showStickyRows() && !stickyHtmlStructure.scrollContainer.node().isConnected) {
      this.getVerticalScrollContainerWrapper()
        .node()
        .insertBefore(stickyHtmlStructure.scrollContainer.node(), scrollContainerOverlay?.node());
    } else if (stickyHtmlStructure.scrollContainer.node().isConnected) {
      const node = stickyHtmlStructure.scrollContainer.node();
      node.parentNode.removeChild(node);
    }

    // update proportions
    if (this._config.showStickyRows()) {
      stickyHtmlStructure.scrollContainer.style('width', `calc(100% - ${this._config.verticalScrollbarWidth}px)`);
    }
  }

  //
  // LIFE CYCLE: DESTROY
  //

  /**
   * Removes structure from DOM.
   * @keywords remove, delete, clear, structure, html, div, container
   */
  public removeStructure(): void {
    this._htmlContainer.removeStructure();
    this._sizeHandler?.removeStructure();
  }

  /**
   * Removes all observable subscriptions.
   */
  public destroy(): void {
    this._resizeHandler.destroy();

    this._onDestroySubject.next();
    this._onDestroySubject.complete();
  }

  //
  // OBSERVABLES
  //

  /**
   * Observable which gets triggered when the instance gets destroyed.
   */
  private get onDestroy(): Observable<void> {
    return this._onDestroySubject.asObservable();
  }

  //
  // GETTER & SETTER
  //

  /**
   * Helper getter which returns the gantt config of the current gantt.
   */
  protected get _config(): GanttConfig {
    return this._ganttDiagram.getConfig();
  }

  /**
   * Helper getter which returns the node proportions state of the current gantt.
   */
  private get _nodeProportionsState(): NodeProportionsState {
    return this._ganttDiagram.getNodeProportionsState();
  }

  /**
   * Returns the current size handler of the gantt.
   * @returns Current size handler of the gantt.
   */
  public getSizeHandler(): GanttSizeHandler {
    return this._sizeHandler;
  }

  /**
   * Returns the current {@link GanttScrollContainerSizeHandler} of the gantt.
   * @returns Current {@link GanttScrollContainerSizeHandler} of the gantt.
   */
  public getScrollContainerSizeHandler(): GanttScrollContainerSizeHandler {
    return this._scrollContainerSizeHandler;
  }

  /**
   * Returns the parent node of the gantt.
   * @returns Parent node of the gantt.
   */
  public getParentNode(): d3.Selection<HTMLElement, undefined, d3.BaseType, undefined> {
    return this._parentNode;
  }

  /**
   * Returns the x axis container of the gantt.
   * @returns X axis container of the gantt.
   */
  public getXAxisContainer(): d3.Selection<HTMLDivElement, undefined, d3.BaseType, undefined> {
    return this._htmlContainer.xAxis;
  }

  /**
   * Returns the wrapper container for all scroll containers of the gantt.
   * @returns Wrapper container for all scroll containers of the gantt.
   */
  public getVerticalScrollContainerWrapper(): d3.Selection<HTMLDivElement, undefined, d3.BaseType, undefined> {
    return this._htmlContainer.scrollContainerWrapper;
  }

  /**
   * Returns the vertical scroll container overlay of the gantt.
   * @returns Vertical scroll container overlay of the gantt.
   */
  public getVerticalScrollContainerOverlay(): d3.Selection<HTMLDivElement, undefined, d3.BaseType, undefined> {
    return this._htmlContainer.scrollContainerOverlay;
  }

  /**
   * Returns the vertical scroll container of the gantt.
   * @param scrollContainerId Id of the scroll container which should be returned.
   * @returns Vertical scroll container of the gantt.
   */
  public getVerticalScrollContainer(
    scrollContainerId: EGanttScrollContainer = EGanttScrollContainer.DEFAULT
  ): d3.Selection<HTMLDivElement, undefined, d3.BaseType, undefined> {
    return this._htmlContainer.scrollContainer[scrollContainerId].scrollContainer;
  }

  /**
   * Returns the y axis container of the gantt.
   * @param scrollContainerId Id of the scroll container which contains the wanted y axis container.
   * @returns Y axis container of the gantt.
   */
  public getYAxisContainer(
    scrollContainerId: EGanttScrollContainer = EGanttScrollContainer.DEFAULT
  ): d3.Selection<HTMLDivElement, undefined, d3.BaseType, undefined> {
    return this._htmlContainer.scrollContainer[scrollContainerId].yAxis;
  }

  /**
   * Returns the shift container of the gantt.
   * @param scrollContainerId Id of the scroll container which contains the wanted shift container.
   * @returns Shift container of the gantt.
   */
  public getShiftContainer(
    scrollContainerId: EGanttScrollContainer = EGanttScrollContainer.DEFAULT
  ): d3.Selection<HTMLDivElement, undefined, d3.BaseType, undefined> {
    return this._htmlContainer.scrollContainer[scrollContainerId].shifts;
  }

  /**
   * Returns the y axis placeholder container of the gantt.
   * @returns Y axis placeholder container of the gantt.
   */
  public getYAxisPlaceholderContainer(): d3.Selection<d3.BaseType, undefined, d3.BaseType, undefined> {
    return this._htmlContainer.xAxis.select('.gantt_x-axis_placeholder');
  }

  /**
   * Returns the vertical scrollbar placeholder container of the gantt.
   * @returns Vertical scrollbar placeholder container of the gantt.
   */
  public getVerticalScrollbarPlaceholderContainer(): d3.Selection<HTMLDivElement, undefined, d3.BaseType, undefined> {
    return this._htmlContainer.xAxis.select('.gantt_x-axis_placeholder-right');
  }

  /**
   * Moves x axis container above or below the shifts and y axis area.
   * @param position New position of the x axis container.
   */
  public setXAxisPosition(position: EGanttXAxisPosition = EGanttXAxisPosition.TOP): void {
    const parent = this._htmlContainer.xAxis.node().parentNode;
    const xAxis = this._htmlContainer.xAxis.node();

    // cache scroll top values of scroll containers
    const scrollTopMap = this._getScrollContainerScrollTopMap();

    // apply x axis position
    switch (position) {
      case EGanttXAxisPosition.BOTTOM:
        parent.appendChild(xAxis);
        break;
      case EGanttXAxisPosition.TOP:
      default:
        parent.insertBefore(xAxis, parent.firstChild);
    }

    // update other containers according to the new x axis position
    this._applyScrollContainerScrollTopMap(scrollTopMap);
  }

  /**
   * Generates a map containing the current scrollTop values of all scroll containers.
   * @returns Map containing the current scrollTop values of all scroll containers.
   */
  private _getScrollContainerScrollTopMap(): Map<EGanttScrollContainer, number> {
    const scrollTopMap = new Map<EGanttScrollContainer, number>();
    for (const scrollContainerId of Object.values(EGanttScrollContainer)) {
      const scrollContainer = this._htmlContainer.scrollContainer[scrollContainerId].scrollContainer.node();
      scrollTopMap.set(scrollContainerId, scrollContainer.scrollTop);
    }
    return scrollTopMap;
  }

  /**
   * Takes a map containing scrollTop values for specific scroll containers and applies these values to them.
   * @param scrollTopMap Map containing the scrollTop values of specific scroll containers.
   */
  private _applyScrollContainerScrollTopMap(scrollTopMap: Map<EGanttScrollContainer, number>): void {
    for (const scrollContainerId of scrollTopMap.keys()) {
      const scrollContainer = this._htmlContainer.scrollContainer[scrollContainerId].scrollContainer.node();
      scrollContainer.scrollTop = scrollTopMap.get(scrollContainerId);
    }
  }
}
