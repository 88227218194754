import { Button } from '@app-modeleditor/components/button/button';
import { Content } from '@app-modeleditor/components/content/content';
import { EEntryElementPosition } from '@app-modeleditor/components/entry-collection/entry-element-position.enum';
import { Template } from 'frontend/src/dashboard/model/resource/template';
import { ETemplateType } from 'frontend/src/dashboard/model/resource/template-type';

interface INextPage {
  [key: number]: string;
}

export class WizardPage extends Template {
  private additionalButtons: Button[];
  private contentRestUrl: string;
  private index: number;
  private nextButton: Button;
  private nextPages: INextPage;
  private previousPages: INextPage;
  private content: Content;
  private backButton: Button;

  public getBackButton(): Button {
    return this.backButton;
  }

  public setBackButton(backButton: Button): this {
    this.backButton = backButton;
    if (backButton && !backButton.getName()) {
      this.backButton.setName('Zurück');
    }
    return this;
  }

  constructor() {
    super();
    this.setType(ETemplateType.WIZARD_PAGE);
  }

  public getContent(): Content {
    return this.content;
  }

  public setContent(content: Content): this {
    this.content = content;
    return this;
  }

  public getPreviousPages(): INextPage {
    return this.previousPages;
  }

  public setPreviousPages(previousPages: INextPage): this {
    this.previousPages = previousPages;
    return this;
  }

  public getAdditionalButtons(): Button[] {
    return this.additionalButtons || [];
  }

  public setAdditionalButtons(additionalButtons: Button[]): this {
    this.additionalButtons = additionalButtons;
    return this;
  }

  public getContentRestUrl(): string {
    return this.contentRestUrl;
  }

  public setContentRestUrl(contentRestUrl: string): this {
    this.contentRestUrl = contentRestUrl;
    return this;
  }

  public getIndex(): number {
    return this.index;
  }

  public setIndex(index: number): this {
    this.index = index;
    return this;
  }

  public getNextButton(): Button {
    return this.nextButton;
  }

  public setNextButton(nextButton: Button): this {
    this.nextButton = nextButton;
    if (nextButton) {
      this.nextButton.setPosition(EEntryElementPosition.RIGHT);
    }
    if (nextButton && !nextButton.getName()) {
      this.nextButton.setName('Weiter');
    }
    return this;
  }

  public getNextPages(): INextPage {
    return this.nextPages;
  }

  public setNextPages(nextPages: INextPage): this {
    this.nextPages = nextPages;
    return this;
  }
}
