import { Component, ComponentRef, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NumericRangePicker } from '../button-slider/numeric-range-picker';
import { EntryElementValue } from '../entry-collection/entry-element-value';
import { OverlayCloseEvent } from '../lightbox/overlay/overlay-close.interface';
import { IOverlayData } from '../lightbox/overlay/overlay-data.interface';
import { OverlayService } from '../lightbox/overlay/overlay.service';
import { OverlayComponent } from './overlay/overlay.component';

@Component({
  selector: 'app-numeric-range-picker',
  templateUrl: './numeric-range-picker.component.html',
  styleUrls: ['./numeric-range-picker.component.scss'],
})
export class NumericRangePickerComponent implements OnInit, OnDestroy {
  @Input() element: NumericRangePicker;
  @Output() change: EventEmitter<{ value: EntryElementValue; changed: boolean }> = new EventEmitter<{
    changed: boolean;
    value: EntryElementValue;
  }>();
  ref: IOverlayData;
  constructor(private el: ElementRef, private overlayApi: OverlayService) {}

  openPicker(): void {
    this.ref = this.overlayApi.create(OverlayComponent, this.el.nativeElement, this.element, {});
    const prev = JSON.stringify(this.element.getValue());
    // data.componentRef.close
    this.ref.componentRef.instance.afterClosed$.subscribe((d: OverlayCloseEvent<NumericRangePicker>) => {
      if (prev !== JSON.stringify(d.data.getValue())) {
        this.change.emit({ value: d.data.getValue(), changed: true });
      } else {
        this.change.emit({ changed: false, value: undefined });
      }
    });
  }

  get value(): string {
    const value = this.element?.getValue<EntryElementValue>()?.getValue<any[]>();
    if (Array.isArray(value)) {
      const visual: string = value
        .filter((v) => v.value > 0)
        .map((v) => `${v.value} x ${v.name}`)
        .reduce((prev: string, cur: string) => `${prev}; ${cur}`);
      return visual;
    }
    return '';
  }

  ngOnInit(): void {
    this.openPicker();
  }

  ngOnDestroy(): void {
    if (this.ref?.componentRef?.instance) {
      this.ref.componentRef.instance.close();
    }
  }
}
