import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ColumnLayoutModule } from '../column-layout/column-layout.module';
import { CoreLayoutComponent } from './core-layout.component';
import { GridLayoutModule } from '../grid-layout/grid-layout.module';
import { VirtualLayoutModule } from '../virtual-layout/virtual-layout.module';
import { SlotModule } from '@app-modeleditor/components/template/slot/slot.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, ColumnLayoutModule, GridLayoutModule, VirtualLayoutModule, SlotModule, TranslateModule],
  declarations: [CoreLayoutComponent],
  exports: [CoreLayoutComponent],
})
export class CoreLayoutModule {}
