import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { Button } from '@app-modeleditor/components/button/button';
import { EButtonDisplayType } from '@app-modeleditor/components/button/button-display-type.enum';
import { ContentElement } from '@app-modeleditor/components/content/content-element/content-element';
import { EntryCollection } from '@app-modeleditor/components/entry-collection/entry-collection';
import { IndexPage } from '@app-modeleditor/components/index-register/index-page';

@Component({
  selector: 'app-log',
  templateUrl: './log.component.html',
  styleUrls: ['./log.component.scss'],
})
export class LogComponent implements OnInit {
  @Input() log: IndexPage;
  btn: Button;
  entries: any;
  states = {};
  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    const ce: ContentElement[] = this.log.getContent().getContentParts()[0].getContentElements();
    this.btn = (ce[0] as EntryCollection).getEntryElements()[0] as Button;
    this.requestLog(ce[1].getRestUrl());
    this.btn.setDisplayType(EButtonDisplayType.ICON_ONLY);
  }

  private requestLog(url: string): void {
    this.http.get(`rest/${url}`).subscribe((l) => {
      this.entries = l;
    });
  }
}
