<div class="wrapper">
  <div class="headline">
    <h2>{{ 'GANTT.preview' | translate }}</h2>
  </div>
  <div class="ganttContainer">
    <saxms-best-gantt
      [ganttData]="ganttData"
      [disableCurrentZoomSpanSaveCallback]="true"
      (ganttBuilder)="setGanttInstance($event)"
    ></saxms-best-gantt>
  </div>
</div>
