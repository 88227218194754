import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'node-network-edit-edge',
  templateUrl: './node-network-edit-edge.html',
  styleUrls: ['./node-network-edit-edge.scss'],
})

/**
 * Angular component of node network edit menu to provide buttons to add, select or edit edges.
 */
export class NodeNetworkEditEdges {
  @Output() editEdges: EventEmitter<Event>;
  @Output() addEdges: EventEmitter<Event>;
  @Output() selectEdges: EventEmitter<Event>;

  public edit: boolean;
  public add: boolean;
  public select: boolean;

  constructor() {
    this.editEdges = new EventEmitter();
    this.addEdges = new EventEmitter();
    this.selectEdges = new EventEmitter();

    this.edit = false;
    this.add = false;
  }

  /**
   * Emits edit curve event.
   * @param event User click.
   */
  public onclickEditCurve(event: MouseEvent): void {
    this.editEdges.emit(event);
    this.edit = !this.edit;
  }

  /**
   * Emits add curve event.
   * @param event User click.
   */
  public onclickAddCurve(event: MouseEvent): void {
    this.addEdges.emit(event);
    this.add = !this.add;
  }

  /**
   * Emits select curve event.
   * @param event User click.
   */
  public onclickSelectCurve(event: MouseEvent): void {
    this.selectEdges.emit(event);
    this.select = !this.select;
  }
}
