import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { TranslateModule } from '@ngx-translate/core';
import { PipeModule } from 'frontend/src/dashboard/shared/pipes/pipe.module';
import { ConditionsMenuModule } from '../elements/core/conditions-menu/conditions-menu.module';
import { SortMenuModule } from '../elements/sort-menu/sort-menu.module';
import { DateCalendarComponent } from './calendar/calendar.component';
import { CustomDateAdapter } from './custom-date-adapter.service';
import { TemplateDatepickerComponent } from './template-datepicker.components';
import { TimePickerModule } from './time-picker/time-picker.module';
import { TimePipe } from './time-picker/time.pipe';

@NgModule({
  imports: [
    CommonModule,
    MatAutocompleteModule,
    TranslateModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    MatInputModule,
    MatCardModule,
    MatFormFieldModule,
    FormsModule,
    MatButtonModule,
    MatSelectModule,
    MatIconModule,
    PipeModule,
    TimePickerModule,
    ConditionsMenuModule,
    SortMenuModule,
  ],
  providers: [
    { provide: DateAdapter, useClass: CustomDateAdapter },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    TimePipe,
  ],
  exports: [TemplateDatepickerComponent],
  declarations: [TemplateDatepickerComponent, DateCalendarComponent],
})
export class TemplateDatepickerModule {}
