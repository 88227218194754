import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Action } from '@app-modeleditor/components/button/action/action';
import { EActionType } from '@app-modeleditor/components/button/action/action-type.enum';
import { ETemplateHandlingType } from '@app-modeleditor/components/button/action/template-handling-type.enum';
import { ButtonService } from '@app-modeleditor/components/button/button.service';
import { UiService } from '@app-modeleditor/ui.service';
import { UserService } from 'frontend/src/dashboard/user/data-access/user.service';
import { Subscription } from 'rxjs';
import { ExperimentService2, IExperiment } from '../../../data/experiment/experiment.service';
import { HorizonService } from '../../../horizonselecter/horizon.service';
import { SharedUiService } from '../../../model-editor/ui/service/shared.ui.service';
import { ModelService } from '../../../model/model.service';
import { SharedToolbarService } from '../toolbar/shared-toolbar.service';
import { ToolbarAction } from '../toolbar/toolbar-action.enum';
import { ViewService } from './../../view.service';
import { SelectorComponent } from './selector.component';
import { GlobalSidenavService } from 'frontend/src/dashboard/view/options/global-sidenav.service';

@Component({
  selector: 'experiment-selector',
  templateUrl: './selector.html',
  styleUrls: ['./selector.scss'],
  providers: [],
})
export class ExperimentSelectorComponent extends SelectorComponent implements OnInit, OnDestroy {
  public model: any;
  public horizon: any;
  public scenario: any;
  public offset = 0;
  public limit = 5;
  public subscriptions: Array<Subscription> = [];
  private alive = true;
  private _refreshSub: Subscription;
  experiments: IExperiment[];
  constructor(
    public sharedModelService: ModelService,
    public sharedHorizonService: HorizonService,
    public dialog: MatDialog,
    public systemMessage: MatSnackBar,
    public globalViewService: ViewService,
    public sharedToolbarService: SharedToolbarService,
    public userService: UserService,
    public templateService: UiService,
    private experimentService2: ExperimentService2,
    private http: HttpClient,
    private uiService: SharedUiService,
    private actionApi: ButtonService,
    private globalSidenavService: GlobalSidenavService
  ) {
    super(globalViewService);
    this.title = '@experiments@';
    this.actions = [
      {
        callback: this.addExperiments.bind(this),
        icon: 'add',
        title: '@new_experiment@',
      },
      {
        callback: this.showExperimentsView.bind(this),
        icon: 'more',
        title: 'moreExperiments',
      },
      {
        callback: this.gotoExperiment.bind(this),
        icon: 'launch',
        title: 'SIMULATION.GOTO',
      },
    ];
  }

  gotoExperiment() {
    this.http.get(`rest/steering/goto/experiment`).subscribe((result) => {
      this.sharedModelService.getModel(this.sharedModelService.model.id).subscribe((model) => {
        this.sharedModelService.setCurrentModel(model);
      });
    });
  }

  public setActive(experiment: IExperiment): void {
    if (experiment === this.selected || (this.selected && experiment && this.selected.id === experiment.id)) {
      return;
    }

    this.selected = experiment;
    this.experimentService2.setCurrentExperiment(experiment);
  }

  /**
   * shows detailed view for experiments
   */
  public showExperimentsView(): void {
    this.globalSidenavService.activate('experiments');
  }

  /**
   * creates a new experiment from wizard
   */
  public addExperiments(): void {
    this.addExp(this.scenario);
  }

  /**
   * open addExpeirment Dialog
   */
  public addExp(scenario) {
    this.actionApi
      .executeActions([
        new Action()
          .setActionType(EActionType.GET_CALL)
          .setActionUrl(`template/experimentsettings/wizard`)
          .setTemplateHandlingType(ETemplateHandlingType.TEMPLATE_WIZARD),
      ])
      .subscribe();

    /*
    this.templateService.getExperimentsettingsWizard().subscribe(data => {
      this.wizard.open(data);
    });*/
  }

  ngOnDestroy() {
    this.alive = false;
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
    this.removeActions();
  }

  public removeActions() {
    this.sharedToolbarService.trigger({ type: ToolbarAction.DISABLE, data: ToolbarAction.PLANNING_RUN });
  }

  public addActions(): void {
    this.sharedToolbarService.trigger({ type: ToolbarAction.ENABLE, data: ToolbarAction.PLANNING_RUN });
    this.sharedToolbarService.trigger({ type: ToolbarAction.ENABLE, data: ToolbarAction.INTERACTIVE_PLANNING_RUN });
  }

  ngOnInit() {
    this.addActions();

    this.experimentService2.getExperiments().subscribe((experiments: IExperiment[]) => {
      this.items = this.experiments = experiments;
    });

    this.subscriptions.push(
      this.experimentService2.getCurrentExperiment().subscribe((experiment: IExperiment) => {
        this.selected = experiment;
        this.uiService.changeWidgets();
      })
    );

    this.subscriptions.push(
      this.sharedToolbarService.onAction.subscribe((action) => {
        switch (action.type) {
          case ToolbarAction.PLANNING_RUN:
            // this.addExperiments();
            break;
          case ToolbarAction.REFRESH:
            break;
        }
      })
    );
  }
}
