import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatStepperModule } from '@angular/material/stepper';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { WizardPageModule } from './wizard-page.module';
import { WizardComponent } from './wizard.component';

@NgModule({
  imports: [CommonModule, MatTabsModule, MatStepperModule, WizardPageModule],
  exports: [WizardComponent],
  declarations: [WizardComponent],
})
export class WizardModule {}
