import { Observable, Subject, takeUntil } from 'rxjs';
import { ShiftDataFinder } from '../../data-handler/data-finder/shift-data-finder';
import { YAxisDataFinder } from '../../data-handler/data-finder/yaxis-data-finder';
import { GanttUtilities } from '../../gantt-utilities/gantt-utilities';
import { EGanttScrollContainer } from '../../html-structure/scroll-container.enum';
import { BestGantt } from '../../main';
import { BestGanttPlugIn } from '../gantt-plug-in';

/**
 * Plugin to visualize loading of gantt blocks.
 */
export class GanttShiftLoading extends BestGanttPlugIn {
  private _canvas: d3.Selection<HTMLDivElement, undefined, d3.BaseType, undefined> = undefined;
  private _isActive = false;
  private _registeredIds: string[] = [];
  private _spinnerSize = 16;

  private _onDestroySubject: Subject<void> = new Subject<void>();

  constructor() {
    super();
  }

  public initPlugIn(ganttDiagram: BestGantt): void {
    this.ganttDiagram = ganttDiagram;
    if (!this._isActive) {
      return;
    }
    this._initCanvas();

    // add callbacks
    this.ganttDiagram
      .getXAxisBuilder()
      .onZooming.pipe(takeUntil(this.onDestroy))
      .subscribe(() => this._build());
    this.ganttDiagram
      .getVerticalScrollHandler()
      .onScrollVerticalUpdate.pipe(takeUntil(this.onDestroy))
      .subscribe(() => this._build());
  }

  public update(): void {
    this._build();
  }

  public removePlugIn(): void {
    if (this._canvas) {
      this._canvas.remove();
    }
    // remove callbacks
    this._onDestroySubject.next();
  }

  /**
   * Handles visualization of loading shifts
   * @param ids shift ids to visualize
   */
  public visualizeShiftLoadingByIds(ids: string[]): void {
    if (!Array.isArray(ids) || !ids.length) {
      return;
    }
    this._registeredIds = this._registeredIds.concat(ids);
    this._setActive(true);
    this._weakenShiftAndRemoveNameByIds(ids);
    this._build();

    // refresh view
    this.ganttDiagram.getDataHandler().initCanvasShiftData();
    this.ganttDiagram.rerenderShiftsVertical();
  }

  /**
   * Handles removing the visualization of loading shifts
   * @param ids shift ids to remove from visualization
   */
  public removeShiftLoadingByIds(ids: string[]): void {
    if (!Array.isArray(ids) || !ids.length) {
      return;
    }
    ids.forEach((id) => (this._registeredIds = this._registeredIds.filter((elem) => elem != id))); // remove given ids

    if (!this._registeredIds.length) {
      this._setActive(false);
    }

    this._removeWeakeningShiftByIds(ids);
    this._build();

    // refresh view
    this.ganttDiagram.getDataHandler().initCanvasShiftData();
    this.ganttDiagram.rerenderShiftsVertical();
  }

  /**
   * Activates or deactivates this plugin
   * @param bool Must be true for activation
   */
  private _setActive(bool: boolean): void {
    if (this._isActive === bool) {
      return;
    }
    this._isActive = bool;
    if (this._isActive) {
      this.initPlugIn(this.ganttDiagram);
    } else {
      this.removePlugIn();
    }
  }

  /**
   * Canvas creation.
   */
  private _initCanvas(): void {
    this._canvas = this.ganttDiagram
      .getShiftFacade()
      .getShiftWrapperOverlay()
      .append('div')
      .attr('class', 'shiftLoadingVisualization');
  }

  /**
   * Calculates a render dataset for visualization.
   * @returns
   */
  private _getDataSet(): IShiftLoadingSpinnerData[] {
    const transform = this.ganttDiagram.getXAxisBuilder().getLastZoomEvent();
    const renderDataSet: IShiftLoadingSpinnerData[] = [];
    const halfSpinnerSize = this._spinnerSize / 2;

    const renderDataSetShifts = this.ganttDiagram.getRenderDataHandler().getRenderDataSetShifts();
    for (const key in renderDataSetShifts) {
      renderDataSetShifts[key].forEach((shift) => {
        const width = shift.width * transform.k;

        if (this._registeredIds.includes(shift.id) && width > this._spinnerSize) {
          const shiftRow = YAxisDataFinder.getRowByYPosition(
            this.ganttDiagram.getDataHandler().getYAxisDataset(),
            shift.y
          );
          const scrollContainerId = this.ganttDiagram
            .getRenderDataHandler()
            .getStateStorage()
            .getRowScrollContainer(shiftRow.id);
          const shiftViewportY =
            -this.ganttDiagram.getNodeProportionsState().getScrollTopPosition(scrollContainerId) +
            this.ganttDiagram.getRenderDataHandler().getStateStorage().getYPositionRow(shiftRow.id) +
            (shift.y - shiftRow.y);

          const x = shift.x * transform.k + transform.x;
          renderDataSet.push({
            x: x + width / 2 - halfSpinnerSize,
            y: shiftViewportY + shift.height / 2 - halfSpinnerSize,
            scrollContainerId: scrollContainerId,
          });
        }
      });
    }
    return renderDataSet;
  }

  /**
   * Executes weakness of shifts.
   * Also remove name property of shift data.
   * @param ids shift ids
   */
  private _weakenShiftAndRemoveNameByIds(ids: string[]): void {
    const shifts = ShiftDataFinder.getShiftsByIds(
      this.ganttDiagram.getDataHandler().getOriginDataset().ganttEntries,
      ids
    );
    shifts.forEach((shift) => {
      shift.shift.name = '';
      GanttUtilities.registerWeakenId(shift.shift, this.UUID);
    });
  }

  /**
   * Removes weakness of given shifts.
   * @param ids shift ids
   */
  private _removeWeakeningShiftByIds(ids: string[]): void {
    const shifts = ShiftDataFinder.getShiftsByIds(
      this.ganttDiagram.getDataHandler().getOriginDataset().ganttEntries,
      ids
    );
    shifts.forEach((shift) => GanttUtilities.removeWeakenId(shift.shift, this.UUID));
  }

  /**
   * Builds loading visualization.
   */
  private _build(): void {
    if (!this._isActive) {
      return;
    }
    const selection = this._canvas
      .selectAll<HTMLDivElement, IShiftLoadingSpinnerData>('.shiftLoadingSpinner')
      .data(this._getDataSet())
      .style('left', (d) => d.x + 'px')
      .style('top', (d) => d.y + 'px')
      .style('clip-path', (d) =>
        this.ganttDiagram
          .getShiftFacade()
          .getShiftBuilder(d.scrollContainerId)
          .getClipPathHandler()
          .getHtmlClipPath({ x: d.x, y: d.y, width: this._spinnerSize, height: this._spinnerSize })
      );

    selection
      .enter()
      .append('div')
      .attr('class', 'shiftLoadingSpinner')
      .style('left', (d) => d.x + 'px')
      .style('top', (d) => d.y + 'px')
      .style('clip-path', (d) =>
        this.ganttDiagram
          .getShiftFacade()
          .getShiftBuilder(d.scrollContainerId)
          .getClipPathHandler()
          .getHtmlClipPath({ x: d.x, y: d.y, width: this._spinnerSize, height: this._spinnerSize })
      );

    selection.exit().remove();
  }

  //
  // OBSERVABLES
  //

  /**
   * Observable which gets triggered when the instance gets destroyed.
   */
  private get onDestroy(): Observable<void> {
    return this._onDestroySubject.asObservable();
  }
}

/**
 * Data structure which contains all necessary data to render a shift loading spinner.
 */
interface IShiftLoadingSpinnerData {
  x: number;
  y: number;
  scrollContainerId: EGanttScrollContainer;
}
