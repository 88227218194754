import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class TemplateOverlayService {
  public _onAddData: Observable<any>;
  public _onAddDataSub: Subject<any>;

  public _onAfterCloseOverlay: Observable<any>;
  public _onAfterCloseOverlaySub: Subject<any>;

  constructor() {
    this._onAddDataSub = new Subject<any>();
    this._onAddData = this._onAddDataSub.asObservable();

    this._onAfterCloseOverlaySub = new Subject<any>();
    this._onAfterCloseOverlay = this._onAfterCloseOverlaySub.asObservable();
  }

  public onOpenTemplateOverlay(data: any): void {
    this._onAddDataSub.next(data);
  }

  public onCloseTemplateOverlay(event: any): void {
    this._onAfterCloseOverlaySub.next(event);
  }
}
