import { takeUntil } from 'rxjs';
import { EGanttStickyRowStrategy } from '../../config/config-data/row-config.data';
import { GanttCanvasRow } from '../../data-handler/data-structure/data-structure';
import { GanttUtilities } from '../../gantt-utilities/gantt-utilities';
import { BestGantt } from '../../main';
import { GanttSplitOverlappingShifts } from '../../plug-ins/split-overlapping-shifts/split-overlapping-shifts-executer';
import { GanttStickyFirstRowStrategy } from './sticky-row-strategies/sticky-first-row-strategy';
import { GanttStickyFirstRowWithChildRowsStrategy } from './sticky-row-strategies/sticky-first-row-with-child-rows-strategy';
import { GanttStickyParentRowStrategy } from './sticky-row-strategies/sticky-parent-row-strategy';
import { GanttStickyRowStrategy } from './sticky-row-strategies/sticky-row-strategy.base';

/**
 * Class which handles sticky gantt rows.
 */
export class GanttStickyRowHandler {
  private readonly _callbackId = 'GanttStickyRowHandler_' + GanttUtilities.generateUniqueID();

  private _strategy: GanttStickyRowStrategy = undefined;
  private _splitPlugInId: string = undefined;

  /**
   * @param _ganttDiagram Reference to the gantt diagram to handle sticky rows for.
   */
  constructor(private _ganttDiagram: BestGantt) {}

  //
  // LIFE CYCLE
  //

  /**
   * Initializes the sticky row handler.
   */
  public init(): void {
    this._initCallbacks();
  }

  /**
   * Initializes all necessary callbacks.
   */
  private _initCallbacks(): void {
    this._ganttDiagram
      .getPlugInHandler()
      .subscribeToPlugIns(this._callbackId, (plugIn) => this._connectOverlappingShiftsPlugin(plugIn));

    this._ganttDiagram
      .getConfig()
      .onStickyRowStrategyChanged()
      .pipe(takeUntil(this._ganttDiagram.onDestroy))
      .subscribe((strategy) => this._initStrategy(strategy));
  }

  /**
   * Logic that connects and subscribes to splitOverlappingShiftsPlugin
   */
  private _connectOverlappingShiftsPlugin(plugIn): void {
    try {
      // catch that GanttSplitOverlappingShifts is not defined
      if (!(plugIn instanceof GanttSplitOverlappingShifts)) {
        return;
      }
    } catch (e) {
      console.warn(`GanttSplitOverlappingShifts is not defined`, e);
      this._ganttDiagram.getPlugInHandler().unSubscribeToPlugIns(this._callbackId);
      return;
    }

    this._ganttDiagram.getPlugInHandler().unSubscribeToPlugIns(this._callbackId);

    this._splitPlugInId = plugIn.getUUID();
  }

  /**
   * Initializes a sticky row strategy of the specified type as currently used strategy.
   * @param strategy Type of strategy which should be initialized.
   */
  private _initStrategy(strategy: EGanttStickyRowStrategy): void {
    // if existing -> destroy old strategy
    if (this._strategy) {
      this._strategy.destroy();
      this._strategy = undefined;
    }

    // determine type of new strategy
    let strategyType: typeof GanttStickyRowStrategy = GanttStickyRowStrategy;
    switch (strategy) {
      case EGanttStickyRowStrategy.STICKY_FIRST_ROW:
        strategyType = GanttStickyFirstRowStrategy;
        break;
      case EGanttStickyRowStrategy.STICKY_FIRST_ROW_WITH_CHILD_ROWS:
        strategyType = GanttStickyFirstRowWithChildRowsStrategy;
        break;
      case EGanttStickyRowStrategy.STICKY_PARENT_ROWS:
      default:
        strategyType = GanttStickyParentRowStrategy;
        break;
    }

    // initialize new strategy
    this._strategy = new strategyType(this._ganttDiagram, this);
    this._strategy.init();
  }

  //
  // STICKY ROW HANDLING
  //

  /**
   * Applies updated sticky row flags to the given y axis dataset according to the current strategy.
   * @param dataSet Y axis dataset to apply the updated sticky row flags for.
   * @returns Y axis dataset with updated sticky row flags.
   */
  public applyStickyRows(dataSet: GanttCanvasRow[]): GanttCanvasRow[] {
    // if sticky rows are disabled -> return dataset as is
    if (!this._ganttDiagram.getConfig().showStickyRows()) {
      return dataSet;
    }

    // else (sticky rows are enabled) -> apply sticky row flags and return updated dataset
    dataSet = this._strategy.getManipulatedYAxisDataSet(dataSet);
    return dataSet;
  }

  //
  // GETTER & SETTER
  //

  public getSplitPlugInId(): string {
    return this._splitPlugInId;
  }
}
