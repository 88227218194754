import { Injectable, Type } from '@angular/core';
import { Message } from '@core/message/message';
import { MessageFactoryService } from '@core/message/message-factory.service';
import { ResourceAdapter } from 'frontend/src/dashboard/model/resource/resource-adapter.service';
import { Action } from './action/action';

@Injectable({
  providedIn: 'root',
})
export class ActionAdapter {
  constructor(private resourceAdapter: ResourceAdapter, private messageFactory: MessageFactoryService) {}

  public parseAction<T extends Action>(type: Type<T>, data: any): T {
    if (!data) {
      return null;
    }

    const a: T = this.resourceAdapter
      .inherit<T>(type, data)
      .setActionType(data.actionType)
      .setActionMethod(data.actionMethod)
      .setActionUrl(data.actionUrl)
      .setAsyncCall(data.asyncCall)
      .setLocalID(data.localID)
      .setOpenWithCreateNameDialog(data.openCreateWithNameDialog)
      .setOpenWithConfirmDeleteDialog(data.showConfirmDeleteDialog)
      .setTemplateHandlingType(data.templateHandlingType)
      .setLocalConditionErrorMsg(data.localConditionErrorMsg)
      .setAffectsLocalElement(data.affectsLocalElement)
      .setLocalCondition(data.localCondition)
      .setActionPlugInTargetIds(data.actionPlugInTargetIds)
      .setPluginId(data.pluginId)
      .setFilterId(data.filterId)
      .setClientId(data.clientId)
      .setUsername(data.username)
      .setAuthority(data.authority)
      .setRedirectUri(data.redirectUri)
      .setValidationRules(data.validationRules)
      .setMessages((data.messages || []).map((m) => this.messageFactory.parseMessage<Message>(Message, m)))
      .setUploadID(data.uploadID)
      .setConditionType(data.conditionType)
      .setTrueActions(
        Array.isArray(data.trueActions)
          ? (data.trueActions as object[]).map((action) => this.parseAction(Action, action))
          : undefined
      )
      .setFalseActions(
        Array.isArray(data.falseActions)
          ? (data.falseActions as object[]).map((action) => this.parseAction(Action, action))
          : undefined
      )
      .setUserQueryMessage(data.userQueryMessage);
    if (typeof data.callOnReloadingPage === 'boolean') {
      a.setCallOnReloadingPage(data.callOnReloadingPage);
    }
    if (data.parameterSelectors) {
      a.setParameterSelectors(data.parameterSelectors);
    }
    if (data.postParameterSelector) {
      a.setPostParameterSelector(data.postParameterSelector);
    }

    return a;
  }
}
