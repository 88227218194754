import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ColorEvent } from 'ngx-color';

@Component({
  selector: 'app-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
})
export class ColorPickerComponent implements OnInit {
  currentSelection: string;

  @Input() color: string;
  @Input() resetColor?: string;
  @Input() disableAlpha?: boolean = false;
  @Output() changeEvent: EventEmitter<string> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {
    this.currentSelection = this.color;
  }

  confirm() {
    this.changeEvent.emit(this.currentSelection);
  }

  reset() {
    this.changeEvent.emit(this.resetColor || this.color);
  }

  onColorChange(event: ColorEvent) {
    this.currentSelection = this.disableAlpha ? event.color.hex : this.addAlpha(event.color.hex, event.color.rgb.a);
  }

  /**
   * Adds an alpha channel to a given hex color.
   * @param color Hex color string
   * @param opacity Opacity value between 0 and 1
   * @returns Hex color string with alpha channel
   */
  private addAlpha(color: string, opacity: number): string {
    const hexOpacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
    return color + hexOpacity.toString(16).toUpperCase();
  }
}
