import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { FileData } from '../filte-data';

@Injectable({
  providedIn: 'root',
})
export class ProgressDatasourceService {
  private opened: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private items: BehaviorSubject<FileData[]> = new BehaviorSubject<FileData[]>([]);

  isOpened(): Observable<boolean> {
    return this.opened.asObservable();
  }

  public setOpened(opened: boolean): void {
    this.opened.next(opened);
  }

  public toggle(): void {
    this.opened.next(!this.opened.getValue());
  }

  getValue(): FileData[] {
    return this.items.getValue();
  }

  setItems(items: FileData[]): void {
    this.items.next(items);
  }

  getItems(): Observable<FileData[]> {
    return this.items.asObservable();
  }
}
