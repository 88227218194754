import { Observable, Subject, takeUntil } from 'rxjs';
import { GanttConfig } from '../../config/gantt-config';
import { GanttCanvasShift } from '../../data-handler/data-structure/data-structure';
import { GanttUtilities } from '../../gantt-utilities/gantt-utilities';
import { BestGantt } from '../../main';
import { BestGanttPlugIn } from '../gantt-plug-in';

/**
 * Plugin to highlight blocks for a short time.
 * The behavior is that blocks starts flashing when highlightBlocks function is triggered.
 */
export class GanttBlockHighlighter extends BestGanttPlugIn {
  private _currentOverlays: string[] = [];

  private _onDestroySubject: Subject<void> = new Subject<void>();

  constructor() {
    super();
  }

  /**
   * @override
   */
  public initPlugIn(ganttDiagram: BestGantt): void {
    this.ganttDiagram = ganttDiagram;

    // init callbacks
    this.ganttDiagram
      .getXAxisBuilder()
      .onZooming.pipe(takeUntil(this.onDestroy))
      .subscribe(() => this._updateOnZoom());
    this.ganttDiagram
      .getVerticalScrollHandler()
      .onScrollVerticalUpdate.pipe(takeUntil(this.onDestroy))
      .subscribe(() => this._updateOnZoom());
  }

  /**
   * @override
   */
  public update(): void {
    this._buildOverlays();
  }

  /**
   * @override
   */
  public updatePlugInHeight(): void {
    this.update();
  }

  /**
   * @override
   */
  public removePlugIn(): void {
    this._removeOverlays();

    this._onDestroySubject.next();
    this._onDestroySubject.complete();
  }

  /**
   * Triggers the highlighting (flashing) of the given blocks by shiftIds.
   * @param {string[]} blockIds Ids of blocks to highlight
   */
  public highlightBlocks(blockIds: string[]): void {
    if (!blockIds || !Array.isArray(blockIds)) {
      return;
    }
    this._currentOverlays = blockIds;
    this._buildOverlays();
    this._blink();
  }

  /**
   * Updates highlight overlays on zoom.
   */
  private _updateOnZoom(): void {
    this._buildOverlays();
  }

  /**
   * Builds the overlays of the blocks in the currentOverlays dataset.
   */
  private _buildOverlays(): void {
    if (!this._currentOverlays.length) {
      return;
    }
    const lastZoomTransformation = this.ganttDiagram.getShiftFacade().getShiftBuilder().getLastZoomTransformation();
    const canvasShiftDataset = this.ganttDiagram.getDataHandler().getCanvasShiftDataset();

    const highlightBlocks = this._shiftGroupOverlay.selectAll('.highlightBlockOverlays').data(
      canvasShiftDataset.filter((d) => {
        return this._currentOverlays.includes(d.id) && (!d.noRender || !d.noRender.length);
      })
    );

    highlightBlocks
      .attr('width', (d) => {
        return d.width * lastZoomTransformation.k;
      })
      .attr('height', (d) => {
        return d.height || this._config.shiftHeight();
      })
      .attr('x', (d) => {
        return d.x * lastZoomTransformation.k + lastZoomTransformation.x;
      })
      .attr('y', (d) => {
        let y = this.ganttDiagram.getRenderDataHandler().getShiftDataFinder().getShiftViewportY(d.id);
        if (isNaN(y)) y = d.y;
        return y;
      })
      .attr('rx', (d) => {
        if (this.ganttDiagram.getConfig().getShiftBuildingRoundedCorners() && !d.noRoundedCorners) {
          return this._config.getRoundedCorners();
        }
      })
      .attr('ry', (d) => {
        if (this.ganttDiagram.getConfig().getShiftBuildingRoundedCorners() && !d.noRoundedCorners) {
          return this._config.getRoundedCorners();
        }
      })
      .attr('clip-path', (d) => {
        const scrollContainerId = this.ganttDiagram
          .getRenderDataHandler()
          .getStateStorage()
          .getShiftScrollContainer(d.id);
        return this.ganttDiagram.getShiftFacade().getShiftBuilder(scrollContainerId).getClipPathHandler()?.clipPathUrl;
      });

    highlightBlocks
      .enter()
      .append('rect')
      .attr('class', 'highlightBlockOverlays')
      .style('fill', 'transparent')
      .style('fill-opacity', 0.8)
      .attr('width', (d) => {
        return d.width * lastZoomTransformation.k;
      })
      .attr('height', (d) => {
        return d.height || this._config.shiftHeight();
      })
      .attr('x', (d) => {
        return d.x * lastZoomTransformation.k + lastZoomTransformation.x;
      })
      .attr('y', (d) => {
        let y = this.ganttDiagram.getRenderDataHandler().getShiftDataFinder().getShiftViewportY(d.id);
        if (isNaN(y)) y = d.y;
        return y;
      })
      .attr('rx', (d) => {
        if (this.ganttDiagram.getConfig().getShiftBuildingRoundedCorners() && !d.noRoundedCorners) {
          return this._config.getRoundedCorners();
        }
      })
      .attr('ry', (d) => {
        if (this.ganttDiagram.getConfig().getShiftBuildingRoundedCorners() && !d.noRoundedCorners) {
          return this._config.getRoundedCorners();
        }
      })
      .attr('clip-path', (d) => {
        const scrollContainerId = this.ganttDiagram
          .getRenderDataHandler()
          .getStateStorage()
          .getShiftScrollContainer(d.id);
        return this.ganttDiagram.getShiftFacade().getShiftBuilder(scrollContainerId).getClipPathHandler()?.clipPathUrl;
      });

    highlightBlocks.exit().remove();
  }

  /**
   * Starts the flash animation of the overlays.
   * If its done, the overlays will be removed by this function.
   */
  private _blink(): void {
    this._shiftGroupOverlay
      .selectAll<SVGRectElement, GanttCanvasShift>('.highlightBlockOverlays')
      .transition()
      .duration(500)
      .style('fill', function (d) {
        return GanttUtilities.lightenDarkenColor(d.color, 80);
      })
      .transition()
      .duration(500)
      .style('fill', function (d) {
        return GanttUtilities.lightenDarkenColor(d.color, -80);
      })
      .transition()
      .duration(500)
      .style('fill', function (d) {
        return GanttUtilities.lightenDarkenColor(d.color, 80);
      })
      .transition()
      .duration(500)
      .style('fill', function (d) {
        return GanttUtilities.lightenDarkenColor(d.color, -80);
      })
      .transition()
      .duration(500)
      .style('fill', function (d) {
        return GanttUtilities.lightenDarkenColor(d.color, 80);
      })
      .transition()
      .duration(500)
      .style('fill', 'transparent')
      .on('end', () => {
        this._removeOverlays();
      });
  }

  /**
   * Function to remove the overlays.
   */
  _removeOverlays() {
    if (!this._currentOverlays.length) {
      return;
    }
    this._currentOverlays = [];
    this._shiftGroupOverlay.selectAll('.highlightBlockOverlays').remove();
  }

  //
  // GETTER & SETTER
  //

  /**
   * Helper getter which returns the {@link GanttConfig} of the current {@link BestGantt}.
   */
  private get _config(): GanttConfig {
    return this.ganttDiagram.getConfig();
  }

  /**
   * Helper getter which returns the shift group overlay selection of the current {@link BestGantt}.
   */
  private get _shiftGroupOverlay(): d3.Selection<SVGGElement, undefined, d3.BaseType, undefined> {
    return this.ganttDiagram.getShiftFacade().getShiftGroupOverlay();
  }

  //
  // OBSERVABLES
  //

  /**
   * Observable which gets triggered when the instance gets destroyed.
   */
  private get onDestroy(): Observable<void> {
    return this._onDestroySubject.asObservable();
  }
}
