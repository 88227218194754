import { EGanttInstance } from '@gantt/public-api';
import { SaxMsBestGanttActiveSubmenuEntryElementSetting } from 'frontend/src/dashboard/gantt/gantt/saxms-best-gantt.settings';
import { Observable, of } from 'rxjs';
import { ExternalGanttPlugin } from '../../external-plugin';

export const GanttBlockMoverExecuter = 'gantt-plugin-shift-mover-executer';

/**
 * PlugIn-Wrapper for GanttShiftMover.
 * This provides a facade to change vertical and horizonatl position of shifts.
 */
export class GanttBlockMoverPlugIn extends ExternalGanttPlugin {
  public onInit(templateData: any, responseData: any) {
    this.addPlugIn(
      GanttBlockMoverExecuter,
      this._ganttLibService.ganttInstanceService.getInstance(EGanttInstance.SHIFT_MOVER)
    );
  }

  public onDestroy(): void {}

  /**
   * Moves shift block to given timepoint and row.
   * @param shiftId Id of shift to move.
   * @param targetGanttRowID New row.
   * @param timePointStart New starttimepoint of shiftblock.
   * @param timePointEnd New endtimepoint of shiftblock.
   */
  public moveShift(shiftId: string, targetGanttRowID: string, timePointStart: Date, timePointEnd: Date) {
    console.warn(`I think this method is never called, just checking.`);
    this.getPlugInById(GanttBlockMoverExecuter).moveVisibleShift(
      shiftId,
      targetGanttRowID,
      timePointStart,
      timePointEnd
    );
  }

  public onAction(action: any) {}

  public executeAction(action: any): Observable<any> {
    return of(null);
  }

  public injectSettings(submenuElements: SaxMsBestGanttActiveSubmenuEntryElementSetting[]): void {}
}
