import * as d3 from 'd3';
import { Observable, Subject, takeUntil } from 'rxjs';
import { GanttCallBackStackExecuter } from '../../callback-tools/callback-stack-executer';
import { ShiftDataFinder } from '../../data-handler/data-finder/shift-data-finder';
import { EGanttStickyBlockType } from '../../data-handler/data-structure/data-structure-enums';
import { DataManipulator } from '../../data-handler/data-tools/data-manipulator';
import { GanttFontSizeCalculator } from '../../font-tools/font-size';
import { GanttUtilities } from '../../gantt-utilities/gantt-utilities';
import { GanttScrollContainerEvent } from '../../html-structure/scroll-container-event';
import { EGanttScrollContainer } from '../../html-structure/scroll-container.enum';
import { TooltipBuilder } from '../../tooltip/tooltip-builder';
import { BestGanttPlugIn } from '../gantt-plug-in';

/**
 * @keywords plugin, executer, sticky, shift, block
 * @class
 * @constructor
 * @extends BestGanttPlugIn
 * @param {GanttShiftsMultiDragStrategy} [multiDragStrategy]
 *
 * @requires BestGanttPlugIn
 */
export class GanttStickyBlocks extends BestGanttPlugIn {
  fontSizeCalculator: GanttFontSizeCalculator;
  canvas: d3.Selection<SVGSVGElement, undefined, d3.BaseType, undefined>;
  active: boolean;
  tooltipBuilder: TooltipBuilder;
  stickyBlocksOutOfView: GanttStickyBlock[];
  stickyBlockSize: number;
  textOverlayTimer: NodeJS.Timeout;

  private _onDestroySubject: Subject<void> = new Subject<void>();

  callBack: any;

  constructor() {
    super(); // call super-constructor
    this.fontSizeCalculator = new GanttFontSizeCalculator();

    this.ganttDiagram = null;
    this.canvas = null;
    this.active = false;
    this.tooltipBuilder = null;

    this.stickyBlocksOutOfView = [];

    this.stickyBlockSize = 20;

    this.textOverlayTimer = null;

    this.callBack = {
      onClick: {},
    };
  }

  /**
   * @override
   */
  initPlugIn(ganttDiagram) {
    const s = this;
    s.ganttDiagram = ganttDiagram;
    s.tooltipBuilder = s.ganttDiagram.getShiftFacade().getTooltipBuilder();

    s._generateSVGNode();
    s._generateDropShadowFilter();

    // init fontsize calculator
    s.fontSizeCalculator.init(s.canvas.node(), 14);

    s._registerCallBacks();
    s._execute(true);
  }

  public update(): void {
    this._execute();
  }

  /**
   * Update reaction to gantt height change of plugin. Part of plugin lifecycle.
   */
  updatePlugInHeight() {
    this.update();
  }

  public removePlugIn(): void {
    this._removeCallBacks();

    this._onDestroySubject.next();
    this._onDestroySubject.complete();

    this.canvas.remove();
  }

  /**
   * Contains all callbacks which will be registered inside gantt.
   */
  private _registerCallBacks(): void {
    this.ganttDiagram.subscribeOriginDataUpdate(`getStickyBlocks_${this.UUID}`, this._execute.bind(this, true));
    this.ganttDiagram
      .getXAxisBuilder()
      .onZoomEnd.pipe(takeUntil(this.onDestroy))
      .subscribe(() => this._execute());
    this.ganttDiagram
      .getXAxisBuilder()
      .onZooming.pipe(takeUntil(this.onDestroy))
      .subscribe(() => this._execute());
    this.ganttDiagram
      .getSelectionBoxFacade()
      .afterDeselection.pipe(takeUntil(this.onDestroy))
      .subscribe(() => this._updateSelectionOfStickyBlocks());
    this.ganttDiagram
      .getVerticalScrollHandler()
      .onScrollVerticalUpdate.pipe(takeUntil(this.onDestroy))
      .subscribe(() => this._execute());
  }

  /**
   * Removes all callbacks which are registered inside gantt.
   */
  private _removeCallBacks(): void {
    this.ganttDiagram.unSubscribeOriginDataUpdate(`getStickyBlocks_${this.UUID}`);
  }

  /**
   * Generates the svg node in which all elements are built.
   */
  private _generateSVGNode(): void {
    // generate svg node
    this.canvas = this.ganttDiagram
      .getShiftFacade()
      .getShiftWrapperOverlay()
      .append<SVGSVGElement>('svg')
      .attr('class', 'sticky-block-canvas')
      .attr('height', '100%')
      .attr('width', '100%')
      .style('position', 'absolute')
      .style('left', '0')
      .style('pointer-events', 'none');
    // generate groups for each scroll container
    for (const scrollContainerId of this.ganttDiagram.getRenderDataHandler().getAllScrollContainerIds()) {
      this.canvas
        .append('g')
        .attr('class', this._getCanvasGroupClass(scrollContainerId))
        .attr('clip-path', () => {
          return this.ganttDiagram.getShiftFacade().getShiftBuilder(scrollContainerId).getClipPathHandler().clipPathUrl;
        });
    }
  }

  /**
   * Determines the class of the svg group element which contains all sticky blocks of the scroll
   * container with the specified id.
   * @param scrollContainerId Id of the scroll container to determine the svg group class for.
   * @returns Class of the svg group element of the scroll container with the specified id.
   */
  private _getCanvasGroupClass(scrollContainerId: EGanttScrollContainer): string {
    return `sticky-block-group_${scrollContainerId.replace('gantt_scroll_wrapper_', '')}`;
  }

  /**
   * Generates a filter in svg defs for drop shadows.
   */
  private _generateDropShadowFilter() {
    const s = this;

    // generate filter for drop shadows
    const defs = s.canvas.append('defs');

    defs
      .append('filter')
      .attr('id', `shadow_left_${s.UUID}`)
      .attr('height', '200%')
      .attr('width', '200%')
      .attr('x', '-50%')
      .attr('y', '-50%')
      .append('feDropShadow')
      .attr('dx', -2)
      .attr('dy', 0)
      .attr('flood-color', '#2F2F2F')
      .attr('stdDeviation', 1);

    defs
      .append('filter')
      .attr('id', `shadow_right_${s.UUID}`)
      .attr('height', '200%')
      .attr('width', '200%')
      .attr('x', '-50%')
      .attr('y', '-50%')
      .append('feDropShadow')
      .attr('dx', 2)
      .attr('dy', 0)
      .attr('flood-color', '#2F2F2F')
      .attr('stdDeviation', 1);
  }

  /**
   * Iterates over origin dataset and searches for sticky blocks there.
   * Found sticky blocks are pushed into stickyBlocksOutOfView array.
   * @returns {boolean} True if sticky blocks are inside dataset.
   */
  private _getAllStickyBlocks() {
    const s = this;
    const currentZoomedTimeSpan = s.ganttDiagram.getCurrentZoomedTimeSpan();
    let stickyShiftsInsideDataSet = false; // flag to check if sticky blocks are in dataset
    let rightSideBlocks = 0;

    // clear arrays
    s.stickyBlocksOutOfView = [];

    const getAllStickyBlocks = function (child) {
      let positionLeftSide = -1; // horizontal position of left side sticky block in row
      let positionRightSide = -1; // horizontal position of right side sticky block in row

      if (child.shifts && child.shifts.length) {
        child.shifts.forEach((shift) => {
          if (shift.stickyBlockType) {
            stickyShiftsInsideDataSet = true;
            const side = s._getSideWhereBlockIsHidden(shift, currentZoomedTimeSpan);
            if (!side || (shift.noRender && shift.noRender.length)) {
              return;
            }
            let position;

            // sticky block found
            if (side === 'RIGHT') {
              positionRightSide++;
              position = positionRightSide;
              rightSideBlocks++;
            }
            if (side === 'LEFT') {
              positionLeftSide++;
              position = positionLeftSide;
            }

            const stickyBlock = new GanttStickyBlock( // generate sticky block object
              shift.id,
              shift.color,
              shift.selected,
              shift.highlighted,
              shift.name,
              shift.tooltip,
              shift.noRender,
              shift.strokeColor,
              shift.strokePattern,
              shift.pattern,
              shift.patternColor,
              shift.weaken,
              child.id,
              position,
              side
            );
            s.stickyBlocksOutOfView.push(stickyBlock);
          }
        });
      }
    };

    DataManipulator.iterateOverDataSet(s.ganttDiagram.getDataHandler().getOriginDataset().ganttEntries, {
      getAllStickyBlocks: getAllStickyBlocks,
    });

    s.stickyBlocksOutOfView.forEach((block) => {
      if (block.side === 'RIGHT') {
        block.position = rightSideBlocks - block.position;
      }
    }); // recalculate position of right side blocks for right date order

    return stickyShiftsInsideDataSet;
  }

  /**
   * Handles search and build of sticky blocks.
   * @param checkForStickyBlocks flag for executing the check if sticky blocks exist
   */
  private _execute(checkForStickyBlocks = false): void {
    if (this.active || (!this.active && checkForStickyBlocks === true)) {
      this._removeAll();
      this.active = this._getAllStickyBlocks();
      this._buildStickyBlocks();
    }
  }

  /**
   * Helper function that proofs on which side the hidden block is located.
   * @param {GanttDataShift} shift shift data
   * @param {Object} currentZoomedTimeSpan start and end date objects of the current time span
   */
  private _getSideWhereBlockIsHidden(shift, currentZoomedTimeSpan) {
    const s = this;
    let side = null;
    if (shift.timePointEnd < currentZoomedTimeSpan.from) {
      side = 'LEFT';
    } else if (shift.timePointStart > currentZoomedTimeSpan.to) {
      side = 'RIGHT';
    }

    switch (shift.stickyBlockType) {
      case EGanttStickyBlockType.STICKY_LEFT:
        if (side === 'LEFT') {
          return side;
        }
        break;
      case EGanttStickyBlockType.STICKY_RIGHT:
        if (side === 'RIGHT') {
          return side;
        }
        break;
      case EGanttStickyBlockType.STICKY_BOTH:
        if (side) {
          return side;
        }
        break;
    }
    return null; // shift is in viewport or side is not relevant
  }

  /**
   * Builds sticky blocks as svg elements into SVG canvas node.
   */
  private _buildStickyBlocks(): void {
    const s = this;
    const halfStickyBlockSize = s.stickyBlockSize / 2;

    for (const scrollContainerId of this.ganttDiagram.getRenderDataHandler().getActiveScrollContainerIds()) {
      const filteredYAxisDataset = GanttUtilities.filterDataSetByViewPort(
        s.ganttDiagram.getHTMLStructureBuilder().getYAxisContainer(scrollContainerId).node(),
        s.ganttDiagram.getRenderDataSetYAxis()[scrollContainerId],
        s.ganttDiagram.getRenderDataHandler(),
        0,
        s.ganttDiagram.getNodeProportionsState().getShiftViewPortProportions(scrollContainerId).height,
        s.ganttDiagram.getNodeProportionsState().getScrollTopPosition(scrollContainerId)
      );
      const filteredStickyBlockArray = s.stickyBlocksOutOfView.filter((stickyBlock) =>
        filteredYAxisDataset.find((item) => item.id == stickyBlock.rowId)
      );
      const canvasWidth = s.ganttDiagram.getNodeProportionsState().getShiftViewPortProportions(scrollContainerId).width;

      const stickyBlocks = s.canvas
        .select(`.${this._getCanvasGroupClass(scrollContainerId)}`)
        .selectAll<SVGRectElement, GanttStickyBlock>('.stickyBlocks')
        .data(filteredStickyBlockArray);

      stickyBlocks
        .attr('y', function (d) {
          const row = filteredYAxisDataset.find((item) => item.id == d.rowId);
          const y =
            s.ganttDiagram.getRenderDataHandler().getYAxisDataFinder().getRowViewportY(row.id) +
            row.height / 2 -
            halfStickyBlockSize;
          return y;
        })
        .attr('rx', halfStickyBlockSize)
        .attr('ry', halfStickyBlockSize)
        .attr('stroke', function (d) {
          if (d.strokeColor) {
            return d.strokeColor;
          }
        })
        .attr('fill', function (d) {
          return s._getFillColor(d, scrollContainerId);
        });

      stickyBlocks
        .enter()
        .append('rect')
        .attr('class', 'stickyBlocks')
        .attr('x', function (d) {
          if (d.side === 'LEFT') {
            return 5 + d.position * halfStickyBlockSize;
          } else if (d.side === 'RIGHT') {
            return canvasWidth - s.stickyBlockSize - 5 - d.position * halfStickyBlockSize;
          }
        })
        .attr('y', function (d) {
          const row = filteredYAxisDataset.find((item) => item.id == d.rowId);
          const y =
            s.ganttDiagram.getRenderDataHandler().getYAxisDataFinder().getRowViewportY(row.id) +
            row.height / 2 -
            halfStickyBlockSize;
          return y;
        })
        .attr('rx', halfStickyBlockSize)
        .attr('ry', halfStickyBlockSize)
        .attr('height', s.stickyBlockSize)
        .attr('width', s.stickyBlockSize)
        .attr('stroke', function (d) {
          if (d.strokeColor) {
            return d.strokeColor;
          }
        })
        .attr('stroke-width', function (d) {
          if (d.strokeColor) {
            return 2;
          }
        })
        .attr('stroke-dasharray', function (d) {
          if (d.strokePattern) {
            const strokePattern = s.ganttDiagram
              .getShiftFacade()
              .getShiftBuilder(scrollContainerId)
              .getPatternHandler()
              .getStrokePatternStorage()
              .getPatternById(d.strokePattern);
            if (strokePattern) {
              return strokePattern.getFilledArea() + ' ' + strokePattern.getUnfilledArea();
            }
          }
          return undefined;
        })
        .attr('fill', function (d) {
          return s._getFillColor(d, scrollContainerId);
        })
        .attr('filter', function (d) {
          return `url(#shadow_${d.side === 'LEFT' ? 'left' : 'right'}_${s.UUID})`;
        })
        .style('pointer-events', 'all')
        .on('mouseover', function (event, d) {
          s._handleMouseOver(d, d3.select(this), new GanttScrollContainerEvent(scrollContainerId, event));
        })
        .on('mouseout', function (event, d) {
          s._handleMouseOut(d, scrollContainerId);
        })
        .on('click', function (event, d) {
          s._handleClick(d, new GanttScrollContainerEvent(scrollContainerId, event));
        })
        .each(function (d) {
          if (d.side === 'RIGHT') {
            d3.select(this).lower();
          }
        });

      stickyBlocks.exit().remove();
    }
  }

  /**
   * Handles the click event of sticky blocks.
   * @param {GanttStickyBlock} data Data of clicked sticky block
   * @param {Event} event Click event
   */
  private _handleClick(data: GanttStickyBlock, event: GanttScrollContainerEvent<PointerEvent>): void {
    const s = this;
    const canvasDataSet = s.ganttDiagram.getDataHandler().getCanvasShiftDataset();
    const originDataSet = s.ganttDiagram.getDataHandler().getOriginDataset().ganttEntries;

    if (!event.event.shiftKey && !event.event.ctrlKey) {
      s.ganttDiagram.getSelectionBoxFacade().deselectAllShifts(canvasDataSet, originDataSet);
    }

    s.ganttDiagram.getSelectionBoxFacade().selectShiftById(canvasDataSet, originDataSet, data.shiftId);
    s._updateSelectionOfStickyBlocks();

    GanttCallBackStackExecuter.execute(s.callBack.onClick, data.shiftId);
  }

  /**
   * Handles the mouse over event of sticky blocks.
   * @param {GanttStickyBlock} data Data of hovered sticky block
   * @param {d3Selection} d3Selection Selection of sticky block in d3.js
   * @param event
   */
  private _handleMouseOver(
    data: GanttStickyBlock,
    d3Selection: d3.Selection<SVGRectElement, GanttStickyBlock, d3.BaseType, undefined>,
    event: GanttScrollContainerEvent<MouseEvent>
  ): void {
    const s = this;
    const halfStickyBlockSize = s.stickyBlockSize / 2;
    const textWidth = s.fontSizeCalculator.getTextWidth(data.name) + 15;
    const sizeOfStickyBlock = textWidth > 70 ? textWidth : 70;
    const canvasWidth = s.ganttDiagram.getNodeProportionsState().getShiftViewPortProportions(event.source).width;

    // toggle tooltip
    s.tooltipBuilder.addTooltipToHTMLBody(event.event.clientX, event.event.clientY, data.tooltip, null, true);

    s.canvas
      .select(`.${this._getCanvasGroupClass(event.source)}`)
      .selectAll<SVGRectElement, GanttStickyBlock>('.stickyBlocks')
      .filter(function (d) {
        return d.rowId == data.rowId && d.side === data.side && d.position >= data.position;
      })
      .transition()
      .ease(d3.easeLinear)
      .duration(150)
      .attr('x', function (d) {
        if (d.side === 'LEFT') {
          const position = 5 + d.position * halfStickyBlockSize;
          return d.position == data.position ? position : position + sizeOfStickyBlock - 5;
        } else if (d.side === 'RIGHT') {
          const position = canvasWidth - s.stickyBlockSize - 5 - d.position * halfStickyBlockSize;
          return d.position == data.position
            ? position - sizeOfStickyBlock + s.stickyBlockSize
            : position - sizeOfStickyBlock + 5;
        }
      })
      .attr('width', function (d) {
        return d.position == data.position ? sizeOfStickyBlock : s.stickyBlockSize;
      });

    s.textOverlayTimer = setTimeout(
      () => s._createTextOverlay(data, d3Selection, sizeOfStickyBlock, event.source),
      150
    );
  }

  /**
   * Handles the mouse out event of sticky blocks.
   * @param {GanttStickyBlock} data Data of hovered sticky block
   */
  private _handleMouseOut(data: GanttStickyBlock, scrollContainerId: EGanttScrollContainer): void {
    const s = this;
    const halfStickyBlockSize = s.stickyBlockSize / 2;
    const canvasWidth = s.ganttDiagram.getNodeProportionsState().getShiftViewPortProportions().width;

    s.tooltipBuilder.removeAllTooltips();

    s._removeTextOverlay();
    clearTimeout(s.textOverlayTimer);

    s.canvas
      .select(`.${this._getCanvasGroupClass(scrollContainerId)}`)
      .selectAll<SVGRectElement, GanttStickyBlock>('.stickyBlocks')
      .filter(function (d) {
        return d.rowId === data.rowId && d.side === data.side;
      })
      .transition()
      .ease(d3.easeLinear)
      .duration(150)
      .attr('x', function (d) {
        if (d.side === 'LEFT') {
          return 5 + d.position * halfStickyBlockSize;
        } else if (d.side === 'RIGHT') {
          return canvasWidth - s.stickyBlockSize - 5 - d.position * halfStickyBlockSize;
        }
      })
      .attr('width', s.stickyBlockSize);
  }

  /**
   * Builds the SVG text overlays for sticky blocks.
   * @param d Data of sticky block
   * @param d3Selection Selection of sticky block in d3.js
   */
  private _createTextOverlay(
    d: GanttStickyBlock,
    d3Selection: d3.Selection<SVGRectElement, GanttStickyBlock, d3.BaseType, undefined>,
    sizeOfStickyBlock: number,
    scrollContainerId: EGanttScrollContainer
  ): void {
    const halfStickyBlockSize = this.stickyBlockSize / 2;
    const canvasWidth = this.ganttDiagram
      .getNodeProportionsState()
      .getShiftViewPortProportions(scrollContainerId).width;

    this._removeTextOverlay();

    this.canvas
      .select(`.${this._getCanvasGroupClass(scrollContainerId)}`)
      .append('text')
      .attr('class', 'stickyBlockText')
      .attr('x', () => {
        if (d.side === 'LEFT') {
          return 15 + d.position * halfStickyBlockSize;
        } else if (d.side === 'RIGHT') {
          return canvasWidth - 12 - d.position * halfStickyBlockSize - sizeOfStickyBlock + this.stickyBlockSize;
        }
      })
      .attr('y', parseInt(d3Selection.attr('y')) + this.stickyBlockSize - 5)
      .attr('fill', 'white')
      .attr('font-weight', '400')
      .attr('font-size', '14')
      .attr('filter', () => {
        return `url(#shadow_${d.side === 'LEFT' ? 'left' : 'right'}_${this.UUID})`;
      })
      .style('pointer-events', 'none')
      .text(d.name);
  }

  /**
   * Removes all text overlays of sticky blocks.
   */
  private _removeTextOverlay(): void {
    this.canvas.selectAll('g').selectAll('.stickyBlockText').remove();
  }

  /**
   * Returns the fill color of sticky blocks.
   * @param {GanttStickyBlock} d Data of sticky block
   * @param {number} i Index of sticky block in array
   * @param {d3Selection} d3Selection Selection of sticky block in d3.js
   * @returns {string} Fill color of sticky block as string
   */
  private _getFillColor(d, scrollContainerId: EGanttScrollContainer): string {
    const s = this;

    const patternHandler = s.ganttDiagram.getShiftFacade().getShiftBuilder(scrollContainerId).getPatternHandler();
    const config = s.ganttDiagram.getConfig();
    if (d.highlighted) {
      return d.highlighted;
    }
    if (d.pattern) {
      if (d.selected) {
        const selectColorInterpolation = d3.interpolate({ colors: [d.color] }, { colors: [d.selected] });
        const interpolatedHexColor = d3.color(selectColorInterpolation(config.colorSelectOpacity()).colors[0]).hex();
        const patternSelectInterpolation = d3.interpolate({ colors: [d.patternColor] }, { colors: [d.selected] });
        const interpolatedPatternHexColor = d3
          .color(patternSelectInterpolation(config.colorSelectOpacity()).colors[0])
          .hex();
        return patternHandler.getPatternAsUrl(d.pattern, interpolatedHexColor, interpolatedPatternHexColor);
      }
      return patternHandler.getPatternAsUrl(d.pattern, d.color, d.patternColor);
    }
    if (d.selected) {
      const selectColorInterpolation = d3.interpolate({ colors: [d.color] }, { colors: [d.selected] });
      return selectColorInterpolation(config.colorSelectOpacity()).colors[0];
    }
    return d.color;
  }

  /**
   * Updates the selection property of all sticky blocks by origin dataset.
   */
  private _updateSelectionOfStickyBlocks() {
    const s = this;
    const originDataSet = s.ganttDiagram.getDataHandler().getOriginDataset().ganttEntries;
    s.stickyBlocksOutOfView.forEach(
      (block) => (block.selected = ShiftDataFinder.getShiftById(originDataSet, block.shiftId).shift.selected)
    );
    s._buildStickyBlocks();
  }

  /**
   * Removes all sticky block SVG elements.
   */
  private _removeAll() {
    this.canvas.selectAll('g').selectAll('.stickyBlocks').remove();
  }

  //
  // OBSERVABLES
  //

  /**
   * Observable which gets triggered when the instance gets destroyed.
   */
  private get onDestroy(): Observable<void> {
    return this._onDestroySubject.asObservable();
  }

  //
  // CALLBACKS
  //

  subscribeToStickyBlockClick(id, func) {
    this.callBack.onClick[id] = func;
  }

  unsubscribeToStickyBlockClick(id) {
    delete this.callBack.onClick[id];
  }
}

/**
 * Data type of a sticky block.
 * @param {String} shiftId
 * @param {String} color
 * @param {String} selected
 * @param {String} highlighted
 * @param {String} name
 * @param {String[]} noRender
 * @param {String} strokeColor
 * @param {String} pattern
 * @param {String} patternColor
 * @param {String[]} weaken
 * @param {String} rowId
 * @param {number} position
 * @param {"RIGHT"|"LEFT"} side
 */
export class GanttStickyBlock {
  shiftId: string;
  color: string;
  selected: string;
  highlighted: string;
  name: string;
  tooltip: string;
  noRender: string[];
  strokeColor: string;
  strokePattern: string;
  pattern: string;
  patternColor: string;
  weaken: string[];
  rowId: string;
  position: number;
  side: string;

  constructor(
    shiftId,
    color,
    selected,
    highlighted,
    name,
    tooltip,
    noRender,
    strokeColor,
    strokePattern,
    pattern,
    patternColor,
    weaken,
    rowId,
    position,
    side
  ) {
    this.shiftId = shiftId;
    this.color = color;
    this.selected = selected;
    this.highlighted = highlighted;
    this.name = name;
    this.tooltip = tooltip;
    this.noRender = noRender;
    this.strokeColor = strokeColor;
    this.strokePattern = strokePattern;
    this.pattern = pattern;
    this.patternColor = patternColor;
    this.weaken = weaken;
    this.rowId = rowId;
    this.position = position;
    this.side = side;
  }
}
