import { HttpClient } from '@angular/common/http';
import { GanttTemplateData } from 'frontend/src/dashboard/gantt/helper/gantt';
import { GlobalUtils } from 'frontend/src/dashboard/global-utils';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { GanttPredefinedSetting } from '../predefined-setting';
import { GanttHoliday, IAvailableFederalState, IBackendAvailableFederalStates } from './gantt-holiday';

export class GanttMarkedHolidays extends GanttPredefinedSetting {
  public onInit(templateData: GanttTemplateData, ganttResponseData: any): void {
    if (!ganttResponseData.hierarchicalPlan) return;
    this.fetchAvailableFederalStates(templateData);
    this.fetchHolidayData(templateData);
  }

  private fetchHolidayData(templateData: GanttTemplateData) {
    if (!this.configService.access().templates.Gantt.requests.holidays) {
      // return if deactivated in config
      return;
    }
    if (!templateData.hasOwnProperty('restUrl')) {
      console.warn('No gantt template rest URL!');
      return;
    }
    const http: HttpClient = this.ganttPluginHandlerService.http;
    const ganttId = templateData.getId();

    const urlParts = templateData.getRestUrl().split('/');
    const templateId = urlParts[1];
    const originData = this.ganttLibService.bestGantt.getDataHandler().getOriginDataset();
    const startDate = originData.minValue;
    const endDate = originData.maxValue;
    const defaultRestUrl = `template/${templateId}/gantt/${ganttId}/startdate/${startDate.getTime()}/enddate/${endDate.getTime()}/holidays`;
    const restUrl = templateData.getPublicHolidaysURL()
      ? GlobalUtils.transformUrl(templateData, templateData.getPublicHolidaysURL())
      : defaultRestUrl;

    http
      .get('rest/' + restUrl)
      .pipe(
        catchError((e) => {
          return of([]);
        }),
        tap((holidays) => {
          this.addHolidaysToGantt(holidays as GanttHoliday[]);
        })
      )
      .subscribe((data) => {});
  }

  private addHolidaysToGantt(holidays: GanttHoliday[]) {
    for (const holiday of holidays) {
      if (!holiday.federalstates) {
        continue;
      }
      this.ganttLibService.bestGantt
        .getGlobalTimeSpanMarker()
        .addHoliday(new Date(holiday.start), new Date(holiday.end), holiday.name, holiday.federalstates);
    }
  }

  public fetchAvailableFederalStates(templateData: GanttTemplateData): void {
    const restUrl = `rest/template/${this.getGanttTemplateId(
      templateData
    )}/gantt/${templateData.getId()}/holidayRegions`;
    const availableFederalStatesOptions: IAvailableFederalState[] = [
      { value: 'showAll', viewValue: `-- ${this.actionHandler.translate.instant('GANTT.LEGEND.show_all')} --` },
      { value: 'nationwide', viewValue: `-- ${this.actionHandler.translate.instant('GANTT.SETTINGS.nationwide')} --` },
    ];

    this.ganttPluginHandlerService.http
      .get<IBackendAvailableFederalStates>(restUrl)
      .pipe(catchError((e) => of([])))
      .subscribe((response) => {
        for (const [region, name] of Object.entries(response)) {
          availableFederalStatesOptions.push({ value: name, viewValue: name });
        }
        this.ganttPluginHandlerService
          .getGanttSettingsService()
          .changeSettings({ availableFederalStates: availableFederalStatesOptions });
      });
  }

  private getGanttTemplateId(templateData: GanttTemplateData): string {
    const urlParts = templateData.getRestUrl().split('/');
    return urlParts[1];
  }
}
