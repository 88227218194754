import { EOpenType } from '@app-modeleditor/components/entry-collection/open-type.enum';
import { EActionOperation } from 'frontend/src/dashboard/model/entry/action-operation.enum';
import { EDatatype } from 'frontend/src/dashboard/model/entry/datatype.enum';
import { EUnit } from 'frontend/src/dashboard/model/entry/unit.enum';
import { Template } from 'frontend/src/dashboard/model/resource/template';
import { ETemplateType } from 'frontend/src/dashboard/model/resource/template-type';
import { SortOrder } from '../elements/sort-menu/sort-order.enum';
import { EFilterConditions } from '../spreadsheet/model/table-filter-conditions.enum';
import { EDurationPickerUnit } from '../template-duration-picker/duration-picker-unit.enum';
import { EntryElementEntry } from './entry-element-enty';
import { EEntryElementEvents } from './entry-element-events.enum';
import { EEntryElementPosition } from './entry-element-position.enum';
import { EntryElementValue } from './entry-element-value';
import { EFieldType } from './field-type.enum';
import { EFloatingLabel } from './floating-label.enum';
export class EntryElement extends Template {
  private actionRestUrl: string;
  private actionOperation: EActionOperation;
  private datatype: EDatatype;
  private poweredByGoogle: boolean;
  private fieldType: EFieldType;
  private lowerBound: number;
  private required: boolean;
  private stepWidth: number;
  private order: number;
  private tooltip: string;
  private toolbar: boolean;
  private toolbarGroupIndexOrder: boolean;
  private toolbarItemIndexOrder: boolean;
  private upperBound: number;
  private useLocalTime: boolean;
  private validationRegex: string;
  private validationRegexInfoText: string;
  private entry: EntryElementEntry;
  protected value: EntryElementValue | string;
  private placeholder: string;
  private infoText: string;
  private web_url: string;
  private unit: EUnit;
  private openType: EOpenType;
  private icon: string;
  private align: any;
  private selected: boolean;
  private floatingLabel: EFloatingLabel;
  private optionsFavorite: boolean;
  private changeCBs: Array<(data: any) => void>;
  private destroyCB: () => void;
  private alive: boolean;
  private actionURLParameterSelectors: { [key: string]: string };
  private selectable: boolean;
  private durationPickerValues: EDurationPickerUnit[];
  private simple: boolean;
  private rows: number;
  private autoFocused: boolean;
  private position: EEntryElementPosition;
  private width: number;
  private noPadding: boolean;
  private shortId: string;
  private availableFilterConditions: EFilterConditions[];
  private condition: EFilterConditions;
  private refreshElementIds: string[];
  private updateElementIds: string[];
  private notEditableInfoText: string;
  private maxLength: number;
  private allowContextMenuWhenDisabled: boolean;
  protected freeSelection: boolean;
  protected nullable: boolean;
  private sortOrder: SortOrder;
  private enableSort: boolean;
  private style: string;
  private password: boolean;
  /**
   * URL to get the value for the entry element.
   * Is called when the entry element is created.
   */
  private valueRestUrl: string;

  public isEnableSort(): boolean {
    return typeof this.enableSort === 'boolean' ? this.enableSort : false;
  }

  public setEnableSort(enableSort: boolean): this {
    this.enableSort = enableSort;
    return this;
  }

  public getSortOrder(): SortOrder {
    return this.sortOrder || SortOrder.none;
  }

  public setSortOrder(sortOrder: SortOrder): this {
    this.sortOrder = sortOrder;
    return this;
  }

  public isNullable(): boolean {
    return typeof this.nullable === 'boolean' ? this.nullable : false;
  }

  public setNullable(nullable: boolean): this {
    this.nullable = nullable;
    return this;
  }

  public getMaxLength(): number {
    return this.maxLength;
  }

  public setMaxLength(maxLength: number): this {
    this.maxLength = maxLength;
    return this;
  }

  public isFreeSelection(): boolean {
    return typeof this.freeSelection === 'boolean' ? this.freeSelection : false;
  }

  public setFreeSelection(freeSelection: boolean): this {
    this.freeSelection = freeSelection;
    return this;
  }

  public isAllowContextMenuWhenDisabled(): boolean {
    return typeof this.allowContextMenuWhenDisabled === 'boolean' ? this.allowContextMenuWhenDisabled : false;
  }

  public setAllowContextMenuWhenDisabled(allowContextMenuWhenDisabled: boolean): this {
    this.allowContextMenuWhenDisabled = allowContextMenuWhenDisabled;
    return this;
  }

  public getNotEditableInfoText(): string {
    return this.notEditableInfoText;
  }

  public setNotEditableInfoText(notEditableInfoText: string): this {
    this.notEditableInfoText = notEditableInfoText;
    return this;
  }

  private editableInfoText: string;

  public getEditableInfoText(): string {
    return this.editableInfoText;
  }

  public setEditableInfoText(editableInfoText: string): this {
    this.editableInfoText = editableInfoText;
    return this;
  }

  public getCondition(): EFilterConditions {
    return this.condition;
  }

  public setCondition(condition: EFilterConditions): this {
    this.condition = condition;
    return this;
  }

  public getAvailableFilterConditions(): EFilterConditions[] {
    return this.availableFilterConditions || [];
  }

  public setAvailableFilterConditions(availableFilterConditions: EFilterConditions[]): this {
    this.availableFilterConditions = availableFilterConditions;
    return this;
  }

  public getRefreshElementIds(): string[] {
    return this.refreshElementIds || [];
  }

  public setRefreshElementIds(refreshElementIds: string[]): this {
    this.refreshElementIds = refreshElementIds;
    return this;
  }

  public getUpdateElementIds(): string[] {
    return this.updateElementIds || [];
  }

  public setUpdateElementIds(updateElementIds: string[]): this {
    this.updateElementIds = updateElementIds;
    return this;
  }

  /**
   * get unique short identifier
   * @returns string
   */
  public getShortId(): string {
    return this.shortId;
  }

  /**
   * set unique short identifier
   * @param shortId string
   * @returns this
   */
  public setShortId(shortId: string): this {
    this.shortId = shortId;
    return this;
  }

  /**
   * whether the entry-element has a small padding on the right or not
   * @returns boolean
   */
  public hasNoPadding(): boolean {
    return typeof this.noPadding === 'boolean' ? this.noPadding : false;
  }

  /**
   * whether to disable the small padding to the right
   * @param noPadding boolean
   * @returns this
   */
  public setNoPadding(noPadding: boolean): this {
    this.noPadding = noPadding;
    return this;
  }

  public getWidth(): number {
    return this.width;
  }

  public setWidth(width: number): this {
    this.width = width;
    return this;
  }

  public getPosition(): EEntryElementPosition {
    return this.position;
  }

  public setPosition(position: EEntryElementPosition): this {
    this.position = position;
    return this;
  }

  public isAutoFocused(): boolean {
    return typeof this.autoFocused === 'boolean' ? this.autoFocused : false;
  }

  public setAutoFocused(autoFocused: boolean): this {
    this.autoFocused = autoFocused;
    return this;
  }

  public getRows(): number {
    return isNaN(this.rows) ? 4 : this.rows;
  }

  public setRows(rows: number): this {
    this.rows = rows;
    return this;
  }

  public getOrder(): number {
    return isNaN(this.order) ? 0 : this.order;
  }

  public setOrder(order: number): this {
    this.order = order;
    return this;
  }

  public isSimple(): boolean {
    return typeof this.simple === 'boolean' ? this.simple : false;
  }

  public setSimple(simple: boolean): this {
    this.simple = simple;
    return this;
  }

  public isShow(): boolean {
    if (this.getValue<EntryElementValue>() && typeof this.getValue<EntryElementValue>().isShow() === 'boolean') {
      return this.getValue<EntryElementValue>().isShow();
    }

    return super.isShow();
  }

  public isSelectable(): boolean {
    return typeof this.selectable === 'boolean' ? this.selectable : false;
  }

  public setSelectable(selectable: boolean): this {
    this.selectable = selectable;
    return this;
  }

  public isPoweredByGoogle(): boolean {
    return typeof this.poweredByGoogle === 'boolean' ? this.poweredByGoogle : false;
  }

  public setPoweredByGoogle(poweredByGoogle: boolean): this {
    this.poweredByGoogle = poweredByGoogle;
    return this;
  }

  public getActionURLParameterSelectors(): { [key: string]: string } {
    return this.actionURLParameterSelectors;
  }

  public setActionURLParameterSelectors(selectors: { [key: string]: string }): this {
    this.actionURLParameterSelectors = selectors;
    return this;
  }

  constructor() {
    super();
    this.setType(ETemplateType.ENTRY_ELEMENT);
    this.alive = true;
  }

  /**
   * set callback which will be executes after changes
   * @param cb: (data: EntryElementValue) => void
   * @returns this
   */
  public onChanges(cb: (data: any) => void): this {
    this.changeCBs = this.getChangeCbs().concat(cb);
    return this;
  }

  /**
   * set callback which will be executes after changes
   * @param cb: (data: EntryElementValue) => void
   * @returns this
   */
  public onDestroy(cb: () => void): this {
    this.destroyCB = cb;
    return this;
  }

  /**
   * executes changes if any callback is given
   * @returns void
   */
  public destroy(): void {
    if (this.destroyCB) {
      this.destroyCB();
    }
    this.setElementRef(null);
    this.alive = false;
  }

  private getChangeCbs(): Array<(data: EntryElementValue) => void> {
    return this.changeCBs || [];
  }

  /**
   * executes changes if any callback is given
   * @returns void
   */
  public executeChanges(data?: any): void {
    this.getChangeCbs().forEach((cb: (data: EntryElementValue) => void) => {
      cb(data !== undefined && data !== null ? data : this.getValue());
    });
  }

  public isAlive(): boolean {
    return this.alive;
  }

  public getFloatingLabel(): EFloatingLabel {
    return this.floatingLabel ? this.floatingLabel : EFloatingLabel.AUTO;
  }

  public setFloatingLabel(floatingLabel: EFloatingLabel): this {
    this.floatingLabel = floatingLabel;
    return this;
  }

  public isSelected(): boolean {
    return typeof this.selected === 'boolean' ? this.selected : false;
  }

  public setSelected(selected: boolean): this {
    this.update('selected', selected);

    Object.keys(this.getEventListener()[`${EEntryElementEvents.SELECTION_CHANGED}`] || {}).forEach((id: string) => {
      const ev: CustomEvent = new CustomEvent(EEntryElementEvents.SELECTION_CHANGED, { detail: { selected } });
      this.getEventListener()[`${EEntryElementEvents.SELECTION_CHANGED}`][id](ev);
    });

    return this;
  }

  public isOptionsFavorite(): boolean {
    return this.optionsFavorite;
  }

  public setOptionsFavorite(optionsFavorite: boolean): this {
    this.optionsFavorite = optionsFavorite;
    return this;
  }

  public getActionRestUrl(): string {
    return this.actionRestUrl;
  }

  public setActionRestUrl(actionRestUrl: string): this {
    this.actionRestUrl = actionRestUrl;
    return this;
  }

  /**
   * returns name from entry if exists
   */
  public getName(): string {
    if (this.getEntry()?.getName()) {
      return this.getEntry().getName();
    }

    if (this.getValue<EntryElementValue>()?.getName()) {
      return this.getValue<EntryElementValue>().getName();
    }

    return super.getName();
  }

  public getTooltip(): string {
    return this.tooltip;
  }

  public setTooltip(tooltip: string): this {
    this.tooltip = tooltip;
    return this;
  }

  public getDatatype(): EDatatype {
    return this.datatype || EDatatype.STRING;
  }

  public setDatatype(datatype: EDatatype): this {
    this.datatype = datatype;
    return this;
  }

  public getInfoText(disabled = false): string {
    if ((this.isDisabled() || disabled) && this.getNotEditableInfoText()) {
      return this.getNotEditableInfoText();
    } else if (this.getEditableInfoText()) {
      return this.getEditableInfoText();
    }
    return this.infoText;
  }

  public setInfoText(infoText: string): this {
    this.infoText = infoText;
    return this;
  }

  public getWeb_url(): string {
    return this.web_url;
  }

  public setWeb_url(web_url: string): this {
    this.web_url = web_url;
    return this;
  }

  public getUnit(): EUnit {
    return this.unit;
  }

  public setUnit(unit: EUnit): this {
    this.unit = unit;
    return this;
  }

  public getOpenType(): EOpenType {
    return this.openType;
  }

  public setOpenType(openType: EOpenType): this {
    this.openType = openType;
    return this;
  }

  public getIcon(): string {
    return this.icon;
  }

  public setIcon(icon: string): this {
    this.icon = icon;
    return this;
  }

  public getAlign(): any {
    return this.align;
  }

  public setAlign(align: any): this {
    this.align = align;
    return this;
  }

  public getPlaceholder(): string {
    return this.placeholder;
  }

  public setPlaceholder(placeholder: string): this {
    this.placeholder = placeholder;
    return this;
  }

  public getValue<T extends EntryElementValue | EntryElementEntry | string>(): T {
    if (!this.value && this.getEntry()) {
      return this.getEntry() as T;
    }
    return this.value as T;
  }

  public setValue(value: EntryElementValue | string, emitValueChangeEvent = true): this {
    this.value = value;

    if (emitValueChangeEvent) {
      Object.keys(this.getEventListener()[`${EEntryElementEvents.VALUE_CHANGED}`] || {}).forEach((id: string) => {
        const ev: CustomEvent = new CustomEvent(EEntryElementEvents.VALUE_CHANGED, { detail: { value } });
        this.getEventListener()[`${EEntryElementEvents.VALUE_CHANGED}`][id](ev);
      });
    }

    return this;
  }

  public getEntry(): EntryElementEntry {
    return this.entry;
  }

  public setEntry(entry: EntryElementEntry): this {
    this.entry = entry;
    return this;
  }

  public getActionOperation(): EActionOperation {
    return this.actionOperation;
  }

  public setActionOperation(actionOperation: EActionOperation): this {
    this.actionOperation = actionOperation;
    return this;
  }

  public getFieldType(): EFieldType {
    return this.fieldType;
  }

  public setFieldType(fieldType: EFieldType): this {
    if (fieldType === EFieldType.CHECK_BOX) {
      this.setDatatype(EDatatype.BOOLEAN);
    }
    this.fieldType = fieldType;
    return this;
  }

  public getLowerBound(): number {
    if (this.getValue() instanceof EntryElementValue && !isNaN(this.getValue<EntryElementValue>().getLowerBound())) {
      return this.getValue<EntryElementValue>().getLowerBound();
    }
    return this.lowerBound;
  }

  public isRequired(): boolean {
    return this.required ? true : false;
  }

  public setRequired(required: boolean): this {
    this.required = required;
    return this;
  }

  public getStepWidth(): number {
    if (this.getValue() instanceof EntryElementValue && !isNaN(this.getValue<EntryElementValue>().getStepWidth())) {
      return this.getValue<EntryElementValue>().getStepWidth();
    }

    if (isNaN(this.stepWidth)) {
      return 1;
    }

    return this.stepWidth;
  }

  public setStepWidth(stepWidth: number): this {
    this.stepWidth = stepWidth;
    return this;
  }
  public setUpperBound(upperBound: number): this {
    this.upperBound = upperBound;
    return this;
  }
  public setLowerBound(lowerBound: number): this {
    this.lowerBound = lowerBound;
    return this;
  }

  public isToolbar(): boolean {
    return this.toolbar ? true : false;
  }

  public isToolbarGroupIndexOrder(): boolean {
    return this.toolbarGroupIndexOrder ? true : false;
  }

  public setToolbarGroupIndexOrder(toolbarGroupIndexOrder: boolean): this {
    this.toolbarGroupIndexOrder = toolbarGroupIndexOrder;
    return this;
  }

  public isToolbarItemIndexOrder(): boolean {
    return this.toolbarItemIndexOrder ? true : false;
  }

  public setToolbarItemIndexOrder(toolbarItemIndexOrder: boolean): this {
    this.toolbarItemIndexOrder = toolbarItemIndexOrder;
    return this;
  }

  public getUpperBound(): number {
    if (this.getValue() instanceof EntryElementValue && !isNaN(this.getValue<EntryElementValue>().getUpperBound())) {
      return this.getValue<EntryElementValue>().getUpperBound();
    }
    return this.upperBound;
  }

  public isUseLocalTime(): boolean {
    return this.useLocalTime ? true : false;
  }

  public setUseLocalTime(useLocalTime: boolean): this {
    this.useLocalTime = useLocalTime;
    return this;
  }

  public getValidationRegex(): string {
    return this.validationRegex;
  }

  public setValidationRegex(validationRegex: string): this {
    this.validationRegex = validationRegex;
    return this;
  }

  public getValidationRegexInfoText(): string {
    return this.validationRegexInfoText;
  }

  public setValidationRegexInfoText(validationRegexInfoText: string): this {
    this.validationRegexInfoText = validationRegexInfoText;
    return this;
  }

  public getDurationPickerValues(): EDurationPickerUnit[] {
    return this.durationPickerValues;
  }
  public setDurationPickerValues(durationPickerValues: EDurationPickerUnit[]): this {
    this.durationPickerValues = durationPickerValues;
    return this;
  }

  public getStyle(): string {
    return this.style;
  }

  public setStyle(style: string): this {
    this.style = style;
    return this;
  }

  public isPassword(): boolean {
    return typeof this.password === 'boolean' ? this.password : false;
  }

  public setPassword(password: boolean): this {
    this.password = password;
    return this;
  }

  public getValueRestUrl(): string {
    return this.valueRestUrl;
  }

  public setValueRestUrl(valueRestUrl: string): this {
    this.valueRestUrl = valueRestUrl;
    return this;
  }
}
