import { GanttResponse } from 'frontend/src/dashboard/gantt/general/response/responses/response';
import { IGanttSuperBlocksResponseExecuter } from '../superblocks.plugin';

export class GanttPlugInSuperBlockHandlerResponse extends GanttResponse<IGanttSuperBlocksResponseExecuter> {
  public handleResponse(response: any): boolean {
    let handledResponse = false;

    if (response.superBlockDataViews) {
      const groupHandler = this.executer.plugin;
      const newSuperBlockBackendData = response.superBlockDataViews;
      const oldSuperBlockBackendData = groupHandler.getSuperBlockBackendData();
      const mergedData = {
        ...oldSuperBlockBackendData,
        ...newSuperBlockBackendData,
      };

      groupHandler.setSuperBlockBackendData(mergedData);

      for (let i = 0; i < groupHandler.shiftComponentExecuters.length; i++) {
        const components = groupHandler.shiftComponentExecuters[i].plugin
          .getShiftComponentDataHandler()
          .getShiftComponents();
        for (const group of components) {
          if (mergedData[group.id]) {
            const fakeObject = { hierarchicalPlan: { superBlockDataViews: mergedData } };
            group.tooltip = this.executer.mapper.extractSuperBlockTooltip(
              group.id,
              this.executer.templateData,
              fakeObject
            );
            group.details = this.executer.mapper.extractSuperBlockDetails(group.id, fakeObject);
          }
        }
      }

      handledResponse = true;
    }

    return handledResponse;
  }
}
