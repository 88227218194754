import { Injectable, NgZone } from '@angular/core';
import { ERequestMethod, RequestOptions, RequestService } from '@app-modeleditor/request.service';
import { IActiveMenuItem } from '@app-modeleditor/utils/active-menu-item.interface';
import { AuthenticationService } from '@core/authentication/auth.service';
import { ConfigService as ConfigApi } from '@core/config/config.service';
import { EMessageType as EDefaultMessageType, Message } from '@core/message/message';
import { MessageService } from '@core/message/message.service';
import { Select } from '@ngxs/store';
import { EMessageType } from 'frontend/src/dashboard/view/window/message-type.enum';
import { WindowService } from 'frontend/src/dashboard/view/window/window.service';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { delay, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { AuthState } from '../../login/data-access/auth.state';
import { Logger } from '../../shared/utils/logger';
import { User } from '../../user/data-access/user';
import { EMessagingType } from '../messaging-type.enum';
import { Notification } from './notification';
import { ENotificationCategory } from './notification-category.enum';
import { NotificationFactoryService } from './notification-factory.service';
import { ENotificationType } from './notification-type.enum';

const POLLING_TIME = 15000;
const POLLING_URL = `longpolling`;
const ACKNOWLEDGE_URL = `push/acknowledge`;

@Injectable({
  providedIn: 'root',
})
export class CloudMessagingService {
  private readonly fcmLogger = new Logger('fcm');
  private readonly pollingLogger = new Logger('polling');
  private ngNext: Subject<void> = new Subject<void>();
  public currentMessage: Subject<Notification> = new Subject<Notification>();
  private pollingTimeout: Subject<void> = new Subject<void>(); // strores current polling timeout
  private notification: BehaviorSubject<Notification[]> = new BehaviorSubject<Notification[]>([]);
  @Select(AuthState.user) user$!: Observable<User>;
  private activeMenuItems: IActiveMenuItem[] = [];

  constructor(
    private windowService: WindowService,
    private configApi: ConfigApi,
    private requestApi: RequestService,
    private notificationFactory: NotificationFactoryService,
    private _zone: NgZone,
    private messageApi: MessageService,
    private authService: AuthenticationService
  ) {
    this.windowService
      .onAfterInit()
      .pipe(filter((r) => r !== null))
      .subscribe(() => {
        this.windowService.receiveMessages().subscribe((msg) => {
          if (msg && msg.data && msg.data.type === 'BROADCAST') {
            msg.data.data.isBroadcast = true;
            this.handleResult(this.mapPayload(msg.data.data).map((n: Notification) => n.setBroadcast(true)));
          }
        });
      });

    this.user$
      .pipe(
        tap((u) => {
          if (!u) {
            this.pause();
          } else {
            this.resume();
          }
        })
      )
      .subscribe();
  }

  /**
   * get listener to received messages
   */
  public getMessage(): Observable<Notification> {
    return this.currentMessage.asObservable();
  }

  /**
   * get multiple notification by there id
   * @param ids string[]
   * @returns Notification[]
   */
  private getNotificationById(ids: string[]): Observable<Notification[]> {
    if (!ids || ids.length === 0) {
      return of([]);
    }

    return this.requestApi
      .call(ERequestMethod.POST, `rest/push/data`, new RequestOptions().setHttpOptions({ body: ids }))
      .pipe(
        map((data: { [key: string]: any }) => {
          return this.mapPayload(data);
        })
      );
  }

  /**
   * init fcm
   */
  public initialize(): void {
    this.resume();
  }

  private mapPayload(payload: any): Notification[] {
    if (Array.isArray(payload)) {
      return payload.map((m) => this.notificationFactory.parseNotification(m));
    } else if (payload && Object.keys(payload).length > 0) {
      return [this.notificationFactory.parseNotification(payload)];
    }

    return [];
  }

  /**
   * do polling request
   * @returns Observable<Notification[]>
   */
  private doPollingRequest(): Observable<Notification[]> {
    return this.longPollingRequest$.pipe(
      switchMap((payload: unknown) => {
        this.pollingLogger.info('raw payload', payload);
        const notifications: Notification[] = this.mapPayload(payload);
        return this.checkForNotification(notifications);
      })
    );
  }

  /**
   * enables longpolling
   * @param options Options
   * @returns void
   */
  private runPolling(): void {
    this._zone.runOutsideAngular(() => {
      this.doPollingRequest()
        .pipe(takeUntil(this.ngNext))
        .subscribe(
          (result: Notification[]) => {
            this.handleResult(result);
            this.runPolling();
          },
          (err) => {
            of(null)
              .pipe(delay(POLLING_TIME), takeUntil(this.pollingTimeout))
              .subscribe(() => {
                this.runPolling();
              });
            this.pollingLogger.error(err);
          }
        );
    });
  }

  /**
   * pause messaging
   * @returns void
   */
  public pause(): void {
    this.ngNext.next();
    this.pollingTimeout.next();
  }

  /**
   * resume messaging
   * @returns Promise<void>
   */
  public resume(): Promise<void> {
    if (!this.configApi.access().messaging.enabled) {
      return;
    }

    if (this.configApi.access().messaging.method === EMessagingType.FCM) {
    } else if (this.configApi.access().messaging.method === EMessagingType.POLLING) {
      this.runPolling();
    }
  }

  /**
   * confirm messages
   * @param messageIds list of message ids
   * @returns Observable<void>
   */
  public confirmMessages(messageIds: string[]): Observable<any> {
    return this.requestApi.call(
      ERequestMethod.POST,
      `rest/${ACKNOWLEDGE_URL}`,
      new RequestOptions().setHttpOptions({ body: messageIds })
    );
  }

  public injectNotification(notification: Notification[]): void {
    this.handleResult(notification);
  }

  private checkForNotification(notifications: Notification[]): Observable<Notification[]> {
    if (!notifications || notifications.length === 0) {
      return of([]);
    }
    // manipulates system state
    const systemNotif: Notification[] = [];

    // only for informing user
    const userNotif: Notification[] = [];
    notifications.forEach((n: Notification) => {
      switch (n.getType()) {
        case ENotificationType.FRONTEND_UPDATE_NOTIFICATION:
        case ENotificationType.GANTT_UPDATE_NOTIFICATION:
        case ENotificationType.HIGHLIGHT_GANTT_BLOCK_NOTIFICATION:
        case ENotificationType.USER_SETTINGS_CHANGED:
        case ENotificationType.EXPERIMENT_UDPATED:
        case ENotificationType.MENU_UPDATE_NOTIFICATION:
        case ENotificationType.UPDATE_ELEMENT_NOTIFICATION:
        case ENotificationType.HMI_CHILD_NODE_NOTIFICATION:
        case ENotificationType.FORCED_NOTIFICATION:
        case ENotificationType.FILE_DOWNLOAD_NOTIFICATION:
        case ENotificationType.RELOAD_TREE_NOTIFICATION:
        case ENotificationType.AUTHENTICATE_GRAPH_API:
        case ENotificationType.TRIGGER_LOADING_INDICATOR:
        case ENotificationType.REFRESH_PAGE:
        case ENotificationType.UPDATE_TABLE_ROWS_NOTIFICATION:
          systemNotif.push(n);
          break;
        default:
          userNotif.push(n);
          break;
      }
    });

    return this.getNotificationById(userNotif.map((n: Notification) => n.getId())).pipe(
      map((n: Notification[]) => {
        return this.mapPayload(n).concat(systemNotif);
      })
    );
  }

  /**
   * handles result
   * @param notifications Notification[]
   * @returns void
   */
  private handleResult(notifications: Notification[]): void {
    if (!notifications) {
      return;
    }

    if (!Array.isArray(notifications)) {
      this.fcmLogger.error('handleResult(ISaxmsNotification[]) expected an array of notification', notifications);
      return;
    }

    if (notifications.length === 0) {
      return;
    }

    this.fcmLogger.info(notifications);

    this._zone.run(() => {
      /**
       * The number of non-hidden notifications with a message.
       */
      const count: number = notifications.filter(
        (n: Notification) => n.getMessage() && n.getType() !== ENotificationType.HIDDEN_NOTIFICATION
      ).length;

      if (count > 3) {
        this.messageApi.show(
          new Message()
            .setType(EDefaultMessageType.INFO)
            .setText('SYSTEM.MESSAGE.NOTIFICATIONS.MULTIPLE.message')
            .setParams({ count: `${count}` })
            .setDuration(10000)
            .setTitle('SYSTEM.MESSAGE.NOTIFICATIONS.MULTIPLE.title')
        );
      }

      notifications.forEach((n: Notification) => {
        if (!n.isBroadcast()) {
          this.windowService.sendMessage({
            type: EMessageType.BROADCAST,
            data: n,
          });
        }

        this.currentMessage.next(n);

        if (!n.getMessage() || notifications.filter((n: Notification) => n.getMessage()).length > 3) {
          return;
        }

        this.messageApi.show(
          new Message()
            .setType(this.mapNotificationCategoryToMessageType(n.getMessageCategory()))
            .setText(n.getMessage())
            .setDuration(7000)
        );
      });
    });
  }

  /**
   * Maps notification category to message type for displaying different colors
   */
  private mapNotificationCategoryToMessageType(category: ENotificationCategory): EDefaultMessageType {
    switch (category) {
      case ENotificationCategory.ERROR:
        return EDefaultMessageType.ERROR;
      case ENotificationCategory.SUCCESS:
        return EDefaultMessageType.SUCCESS;
      case ENotificationCategory.WARN:
        return EDefaultMessageType.WARN;
      case ENotificationCategory.LOG:
        return EDefaultMessageType.LOG;
      default:
        return EDefaultMessageType.INFO;
    }
  }

  /**
   * get all notification and its changes
   * @returns Observable<Notification[]>
   */
  public getNotification(): Observable<Notification[]> {
    return this.notification.asObservable();
  }

  /**
   * set all notification and its changes
   * @returns void
   */
  public setNotification(notification: Notification[]): void {
    this.notification.next(notification);
  }

  /**
   * send token to server
   * @param token token from fcm
   * @returns Observable<void>
   */
  private sendTokenToServer(token: string): Observable<any> {
    if (!this.configApi.access().messaging.enabled) {
      return of(null);
    }
    return this.requestApi.call(ERequestMethod.GET, `rest/fcm/register/${encodeURIComponent(token)}`);
  }

  /**
   * get all messages
   * @returns Observable<Notification[]>
   */
  public getAllUnreadMessages(): Observable<Notification[]> {
    if (!this.configApi.access().messaging.enabled) {
      return of([]);
    }

    const specificNotificationTypes = this.configApi.access()?.templates?.NotificationCenter?.allowedTypes || [];

    if (specificNotificationTypes.length) {
      // fetch only specific notification types
      const options = new RequestOptions().setHttpOptions({ body: { types: specificNotificationTypes } });
      return this.requestApi
        .call(ERequestMethod.POST, `rest/push/by/type`, options)
        .pipe(map((payload) => this.mapPayload(payload)));
    } else {
      return this.requestApi.call(ERequestMethod.GET, `rest/push/all`).pipe(map((payload) => this.mapPayload(payload)));
    }
  }

  setActiveMenuItems(menuItemData: IActiveMenuItem[]): void {
    this.activeMenuItems = menuItemData;
  }

  private get longPollingRequest$(): Observable<unknown> {
    if (this.configApi.access().longPollingWithActiveTemplates === true) {
      const body = {
        userId: this.authService.getCurrentCredentials().getId(),
        activeTemplates: this.activeMenuItems,
      };

      return this.requestApi.call(
        ERequestMethod.POST,
        `rest/${POLLING_URL}`,
        new RequestOptions().setHttpOptions({ body })
      );
    } else {
      return this.requestApi.call(ERequestMethod.GET, `rest/${POLLING_URL}`);
    }
  }
}
