<!-- RANGE -->
<ng-container *ngIf="options && data">
  <!-- <ng-container *ngIf="datepicker.getDatepickerMode() === 'range'"> -->
  <ng-container *ngIf="type !== 'TIME'">
    <mat-calendar
      [id]="id"
      class="my-cal"
      #calendar
      [startAt]="data.start"
      [tabindex]="0"
      [selected]="data"
      [minDate]="minDate"
      [maxDate]="maxDate"
      [comparisonStart]="data.start"
      [comparisonEnd]="data.end"
      (selectedChange)="onChange($event)"
      (monthSelected)="monthSelected($event)"
      (viewChanged)="viewChanged($event)"
    ></mat-calendar>
  </ng-container>
  <!-- </ng-container> -->
  <ng-container *ngIf="options.type === 'DATETIME'">
    <form [tabindex]="1" class="time">
      <app-time-picker
        [appearance]="'appearance'"
        (timeChanged)="onTimeChanged($event, 'start')"
        [id]="id"
        [time]="startTime"
        [label]="(mode === 'range' ? '@start@' : '@time@') | translate"
      ></app-time-picker>
      <!-- </ng-container> -->
      <app-time-picker
        [appearance]="'outline'"
        (timeChanged)="onTimeChanged($event, 'end')"
        [time]="endTime"
        *ngIf="mode === 'range'"
        [label]="'@end@' | translate"
      >
      </app-time-picker>
    </form>
  </ng-container>
</ng-container>
<div class="flex">
  <div class="spacer"></div>
  <button mat-button (click)="confirm()"><mat-icon>done</mat-icon>{{ 'BUTTON.confirm' | translate }}</button>
</div>
