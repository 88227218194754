import { Action } from '@app-modeleditor/components/button/action/action';
import { Button } from '@app-modeleditor/components/button/button';
import { EButtonDisplayType } from '@app-modeleditor/components/button/button-display-type.enum';
import { GlobalUtils } from 'frontend/src/dashboard/global-utils';
import { Observable, of } from 'rxjs';
import { take } from 'rxjs/operators';
import { Gantt_General } from '../../../general.gantt.component';
import { GanttEssentialPlugIns } from '../../../plugin/e-gantt-essential-plugins';
import { AttributeSearchInputElement } from './attribute-search-input.element';
import { EFilterType } from './attribute-search.enum';
import { IFilterDisplayData, IFilterTypeData } from './attribute-search.interface';
import { ResetFilterLightbox } from './reset-filter-lightbox';

/**
 * Button to zoom on all visible shifts in gantt diagram.
 */
export class FilterWarningElement extends Button {
  private uniqueIdentifier: string = GlobalUtils.generateUUID();
  private noRenderIdsOfHiddenResults: string[] = [];

  constructor(private generalGantt: Gantt_General, private searchInputElem: AttributeSearchInputElement) {
    super();
  }

  get(data: any): this {
    const blockFilterByAttributePlugIn = this.generalGantt.ganttPluginHandlerService
      .getEssentialPlugIn(GanttEssentialPlugIns.GanttBlockFilterByAttributePlugIn)
      .getPlugin();
    const blockFilterPlugIn = this.generalGantt.ganttPluginHandlerService.getEssentialPlugIn(
      GanttEssentialPlugIns.BlockFilterPlugIn
    );

    blockFilterByAttributePlugIn.subscribeToHiddenResults(
      'FilterWarningElement_' + this.uniqueIdentifier,
      (res: { [shiftId: string]: string[] }) => {
        this.noRenderIdsOfHiddenResults = [];

        // map no render ids of hidden results
        if (Object.keys(res || {}).length) {
          for (const [key, value] of Object.entries(res)) {
            value.forEach((id) => {
              if (!blockFilterPlugIn.isFilterInvertibleById(id) && !this.noRenderIdsOfHiddenResults.includes(id)) {
                this.noRenderIdsOfHiddenResults.push(id);
              }
            });
          }
          if (this.noRenderIdsOfHiddenResults.length > 0) {
            this.setDisplayType(EButtonDisplayType.ICON_WITH_LABEL_BELOW);
            return;
          }
        }
        this.setDisplayType(EButtonDisplayType.HIDDEN);
      }
    );

    return this.setIcon('warning')
      .setColor('#ff8d00')
      .setName(this.generalGantt.translate.instant('TEXT.hidden_results_headline'))
      .setId(`hidden-results`)
      .setAlwaysEnabled(true)
      .setDisplayType(EButtonDisplayType.HIDDEN)
      .chainActions(new Action().setCb(() => this.openLightbox()));
  }

  private openLightbox(): Observable<null> {
    this.generalGantt.lightboxApi.open(this.getLightBox()).afterClosed().pipe(take(1)).subscribe();

    return of(null);
  }

  private getLightBox(): ResetFilterLightbox {
    const displayData = this.getDisplayData(this.getAffectedFilters());

    return new ResetFilterLightbox(
      'TEXT.hidden_results_headline',
      this.generalGantt.translate.instant('TEXT.hidden_results'),
      displayData
    )
      .setConfirmLabel(this.generalGantt.translate.instant('@resetSelectedFilters@'))
      .setConfirmAction((selection: IFilterDisplayData[]) => {
        const blockFilter = this.generalGantt.ganttPluginHandlerService.getEssentialPlugIn(
          GanttEssentialPlugIns.BlockFilterPlugIn
        );
        const legendFilter = this.generalGantt.getLegendCommunicationService();
        const rowSearch = this.generalGantt.ganttPluginHandlerService.getEssentialPlugIn(
          GanttEssentialPlugIns.YAxisSearchPlugIn
        );
        const conditionFilter = this.generalGantt.ganttPluginHandlerService.getEssentialPlugIn(
          GanttEssentialPlugIns.GanttBlockFilterByConditionPlugIn
        );

        selection.forEach((elem) => {
          if (elem.type === EFilterType.BLOCK_FILTER) {
            blockFilter.resetFilterById(elem.filterId);
          } else if (elem.type === EFilterType.LEGEND_FILTER) {
            legendFilter.resetFilter();
          } else if (elem.type === EFilterType.Y_AXIS_FILTER) {
            rowSearch.resetSearch();
          } else if (elem.type === EFilterType.CONDITION_FILTER) {
            conditionFilter.resetAll();
          }
        });
        this.searchInputElem.refresh();
      })
      .setAbsoluteWidth(400);
  }

  private resetAllFilters(): void {
    // reset block filter
    this.generalGantt.ganttPluginHandlerService
      .getEssentialPlugIn(GanttEssentialPlugIns.BlockFilterPlugIn)
      .resetFilters();

    // reset legend filter
    this.generalGantt.getLegendCommunicationService().resetFilter();

    // reset row search
    this.generalGantt.ganttPluginHandlerService
      .getEssentialPlugIn(GanttEssentialPlugIns.YAxisSearchPlugIn)
      .resetSearch();

    // reset condition filter
    this.generalGantt.ganttPluginHandlerService
      .getEssentialPlugIn(GanttEssentialPlugIns.GanttBlockFilterByConditionPlugIn)
      .resetAll();
  }

  private getAffectedFilters(): IFilterTypeData[] {
    const resultingFilters: IFilterTypeData[] = [];

    const blockFilter = this.generalGantt.ganttPluginHandlerService.getEssentialPlugIn(
      GanttEssentialPlugIns.BlockFilterPlugIn
    );
    const legendFilter = this.generalGantt.getLegendCommunicationService();
    const rowSearch = this.generalGantt.ganttPluginHandlerService.getEssentialPlugIn(
      GanttEssentialPlugIns.YAxisSearchPlugIn
    );
    const conditionFilter = this.generalGantt.ganttPluginHandlerService.getEssentialPlugIn(
      GanttEssentialPlugIns.GanttBlockFilterByConditionPlugIn
    );

    const blockFilterIds = this.noRenderIdsOfHiddenResults.filter((res) => blockFilter.getNoRenderIds().includes(res));

    if (blockFilterIds.length) {
      resultingFilters.push({ type: EFilterType.BLOCK_FILTER, filterIds: blockFilterIds });
    }
    if (this.noRenderIdsOfHiddenResults.includes(legendFilter.getShiftNoRenderId())) {
      resultingFilters.push({ type: EFilterType.LEGEND_FILTER });
    }
    if (this.noRenderIdsOfHiddenResults.includes(rowSearch.getNoRenderId())) {
      resultingFilters.push({ type: EFilterType.Y_AXIS_FILTER });
    }
    if (this.noRenderIdsOfHiddenResults.includes(conditionFilter.getNoRenderId())) {
      resultingFilters.push({ type: EFilterType.CONDITION_FILTER });
    }

    return resultingFilters;
  }

  private getDisplayData(filterTypeData: IFilterTypeData[]): IFilterDisplayData[] {
    const resultingDisplayData: IFilterDisplayData[] = [];
    filterTypeData.forEach((filterType) => {
      switch (filterType.type) {
        case EFilterType.BLOCK_FILTER:
          const blockFilter = this.generalGantt.ganttPluginHandlerService.getEssentialPlugIn(
            GanttEssentialPlugIns.BlockFilterPlugIn
          );
          filterType.filterIds.forEach((id) => {
            const data: IFilterDisplayData = {
              type: EFilterType.BLOCK_FILTER,
              filterId: id,
              label: `${blockFilter.getFilterValueById(id)}`,
            };
            resultingDisplayData.push(data);
          });
          break;
        case EFilterType.LEGEND_FILTER:
          resultingDisplayData.push({
            type: EFilterType.LEGEND_FILTER,
            label: this.generalGantt.translate.instant('GANTT.legend'),
            filterId: GlobalUtils.generateUUID(),
          });
          break;
        case EFilterType.Y_AXIS_FILTER:
          resultingDisplayData.push({
            type: EFilterType.Y_AXIS_FILTER,
            label: this.generalGantt.translate.instant('GANTT.attribute-search-y-axis'),
            filterId: GlobalUtils.generateUUID(),
          });
          break;
        case EFilterType.CONDITION_FILTER:
          resultingDisplayData.push({
            type: EFilterType.CONDITION_FILTER,
            label: this.generalGantt.translate.instant('GANTT.attribute-filter'),
            filterId: GlobalUtils.generateUUID(),
          });
          break;
      }
    });

    return resultingDisplayData;
  }
}
