import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { SlotModule } from '@app-modeleditor/components/template/slot/slot.module';
import { ContentElementComponent } from './content-element.component';

@NgModule({
  imports: [CommonModule, MatIconModule, SlotModule],
  declarations: [ContentElementComponent],
  exports: [ContentElementComponent],
})
export class ContentElementModule {}
