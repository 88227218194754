import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { takeWhile } from 'rxjs/operators';
import { ViewService } from './../../view/view.service';

@Component({
  selector: 'confirm-dialog',
  templateUrl: 'confirm.dialog.html',
  styleUrls: ['confirm.dialog.scss'],
  providers: [],
})
export class ConfirmDialog implements OnInit, OnDestroy {
  public title = '';
  public text = '';
  public styleRef: string;
  label = '';
  hasLabel: boolean;
  public closeButton = true;
  public confirmButton = true;
  private isAlive = true;

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialog>,
    public globalViewService: ViewService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.title = this.data.title ? this.data.title : '';
    this.text = this.data.text ? this.data.text : '';
    this.closeButton = this.data.closeButton != null ? this.data.closeButton : true;
    this.confirmButton = this.data.confirmButton != null ? this.data.confirmButton : true;
    this.hasLabel = this.data.hasLabel;
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }

  ngOnInit(): void {
    this.globalViewService
      .getCurrentTheme()
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((style) => (this.styleRef = style));
  }

  public close() {
    this.dialogRef.close({ confirm: false, label: this.label });
  }

  public confirm() {
    this.dialogRef.close({ confirm: true, label: this.label });
  }
}
