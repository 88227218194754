import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TemplateButtonModule } from '../button/button.module';
import { SlotModule } from '../template/slot/slot.module';
import { StackableComponent } from './stackable.component';
import { MaterialModule } from '@core/material/material.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, DragDropModule, TemplateButtonModule, SlotModule, MaterialModule, TranslateModule],
  declarations: [StackableComponent],
  exports: [StackableComponent],
})
export class StackableModule {}
