import { Action } from '@app-modeleditor/components/button/action/action';
import { EActionType } from '@app-modeleditor/components/button/action/action-type.enum';
import { Button } from '@app-modeleditor/components/button/button';
import { EButtonDisplayType } from '@app-modeleditor/components/button/button-display-type.enum';
import { of } from 'rxjs';
import { FullSpreadsheetComponent } from '../../full-spreadsheet/full-spreadsheet.component';

export class ResetSettingsElement extends Button {
  constructor(private spreadsheetComponent: FullSpreadsheetComponent) {
    super();

    this.setIcon('clear_all')
      .setName('Table.Settings.Reset')
      .setId('resetSettingsElementBtn')
      .setAlwaysEnabled(true)
      .setDisplayType(EButtonDisplayType.ICON_WITH_LABEL_BELOW)
      .chainActions(
        new Action().setActionType(EActionType.LOCAL).setCb(() => of(this.spreadsheetComponent.resetTableSettings()))
      );
  }
}
