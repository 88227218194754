<ng-container *ngIf="pages.length > 1">
  <button class="nav-btn" (click)="prevPage()" [disabled]="currentPage?.pageIndex <= 0" mat-icon-button>
    <mat-icon>keyboard_arrow_left</mat-icon>
  </button>
  <ng-container *ngFor="let page of pages">
    <button
      *ngIf="
        pages.length <= 7 ||
        page.pageIndex == 0 ||
        (currentPage.pageIndex > pages.length - 5 && page.pageIndex >= pages.length - 5) ||
        (currentPage.pageIndex < 4 && page.pageIndex <= 4) ||
        page.pageIndex === currentPage.pageIndex - 1 ||
        page.pageIndex === currentPage.pageIndex ||
        page.pageIndex === currentPage.pageIndex + 1 ||
        page.pageIndex === pages.length - 1
      "
      mat-mini-fab
      class="bubble-page"
      (click)="onPageClick(page)"
      [ngClass]="{ 'active-page': currentPage && page.pageIndex === currentPage.pageIndex }"
    >
      {{ page.labelIndex }}
    </button>

    <button
      id="moreMenuBtn"
      *ngIf="getMoreNextPageMenuBtnState(page)"
      [matMenuTriggerFor]="moreNextPageMenu"
      mat-mini-fab
      class="bubble-page"
    >
      ...
    </button>

    <button
      id="prevMenuBtn"
      *ngIf="getMorePrevPageMenuBtnState(page)"
      [matMenuTriggerFor]="morePrevPageMenu"
      mat-mini-fab
      class="bubble-page"
    >
      ...
    </button>
  </ng-container>

  <button class="nav-btn" (click)="nextPage()" [disabled]="currentPage?.pageIndex >= pages.length - 1" mat-icon-button>
    <mat-icon>keyboard_arrow_right</mat-icon>
  </button>
</ng-container>

<mat-menu #morePrevPageMenu="matMenu">
  <ng-container *ngFor="let page of pages">
    <button
      mat-menu-item
      *ngIf="
        currentPage.pageIndex > pages.length - 5
          ? page.pageIndex < pages.length - 5
          : page.pageIndex < currentPage.pageIndex - 1 && page.pageIndex > 0
      "
      class="bubble-page-menu-item"
      (click)="onPageClick(page)"
    >
      {{ page.labelIndex }}
    </button>
  </ng-container>
</mat-menu>

<mat-menu #moreNextPageMenu="matMenu">
  <ng-container *ngFor="let page of pages">
    <button
      mat-menu-item
      *ngIf="
        currentPage.pageIndex < 4
          ? page.pageIndex >= 5 && page.pageIndex < pages.length - 1
          : page.pageIndex > currentPage.pageIndex + 1 && page.pageIndex < pages.length - 1
      "
      class="bubble-page-menu-item"
      (click)="onPageClick(page)"
    >
      {{ page.labelIndex }}
    </button>
  </ng-container>
</mat-menu>
