import { Observable, Subject } from 'rxjs';
import { DataManipulator } from '../../data-handler/data-tools/data-manipulator';
import { GanttUtilities } from '../../gantt-utilities/gantt-utilities';
import { BestGanttPlugIn } from '../gantt-plug-in';

/**
 * Colorizes rows inside y axis by text search.
 * If row text matches search text, the row gets new background color.
 * If a row childs text matches search text but row is not open, the row gets stroke.
 * @keywords plugin, executer, row, search, yaxis, y axis, text, search
 * @plugin row-search
 * @class
 * @constructor
 * @extends BestGanttPlugIn
 *
 * @requires BestGanttPlugIn
 */
export class GanttRowSearchExecuter extends BestGanttPlugIn {
  markerColor: string;
  markerColorStorage: Map<string, string>;
  strokeColorStorage: Map<string, string>;
  strokeColor: string;
  currentSearchText: string;
  showOnlyResults: boolean;

  private _searchTextChangeSubject: Subject<string>;
  private _showOnlyResultsChangeSubject: Subject<boolean>;

  constructor() {
    super(); // call super-constructor

    /**
     * @type {BestGantt}
     */
    this.ganttDiagram = null;
    this.markerColor = '#6495ED';
    this.markerColorStorage = new Map(); // saves origin values
    this.strokeColorStorage = new Map(); // saves origin values
    this.strokeColor = '#0808FF';
    this.currentSearchText = '';
    this.showOnlyResults = false;

    this._searchTextChangeSubject = new Subject<string>();
    this._showOnlyResultsChangeSubject = new Subject<boolean>();
  }

  /**
   * Initialization of plugin. Part of plugin lifecycle.
   * @param {BestGantt} ganttDiagram Gantt diagram which will be affected by this plugin.
   */
  initPlugIn(ganttDiagram) {
    const s = this;
    s.ganttDiagram = ganttDiagram;
    s.ganttDiagram.subscribeOriginDataUpdate('updateSearchResult' + s.UUID, s._updateSearch.bind(s));
  }

  update() {
    const s = this;
  }

  /**
   * Remove of plugin. Part of plugin lifecycle.
   */
  removePlugIn() {
    const s = this;

    // delete marked rows
    s.reset();
    s.ganttDiagram.getDataHandler().initCanvasYAxisData();
    s.ganttDiagram.getYAxisFacade().getYAxisBuilder().render(s.ganttDiagram.getDataHandler().getYAxisDataset());
    s.ganttDiagram.unSubscribeOriginDataUpdate('updateSearchResult' + s.UUID);
  }

  private _updateSearch() {
    const s = this;
    // don't trigger gantt update because an update is already triggered after the call.
    s.searchRowByTitleHierarchical(s.currentSearchText, false);
  }

  /**
   * Checks all rows (opened and closed ones) if row text matches search text.
   * @param {string} searchText Search query to find row.
   */
  searchRowByTitleHierarchical(searchText: string, updateGantt = true) {
    if (!this.currentSearchText && !searchText) {
      // search not active
      return;
    }

    this._setCurrentSearchText(searchText);
    const rowIDsFull = [];
    const rowIDsStroked = [];

    const _search = function (child) {
      if (this._isStringContained(child, searchText)) {
        const id = child.originalResource || child.id;
        if (!rowIDsFull.includes(id)) {
          rowIDsFull.push(id);
        }
      }
      if (this._rowHasSearchChild(child.child, searchText)) {
        const id = child.originalResource || child.id;
        if (!rowIDsStroked.includes(id)) {
          rowIDsStroked.push(id);
        }
      }
    };

    if (!searchText) {
      this.reset();
    } else {
      DataManipulator.iterateOverDataSet(this.ganttDiagram.getDataHandler().getOriginDataset().ganttEntries, {
        markRow: _search.bind(this),
      });
      this._applyChangesToOriginDataSet(rowIDsFull, rowIDsStroked, updateGantt);
    }
  }

  /**
   * Applies all changes to origin dataset.
   * @param {string[]} rowIDsFull row ids
   * @param {string[]} rowIDsStroked row ids
   */
  private _applyChangesToOriginDataSet(rowIDsFull, rowIDsStroked, updateGantt = true) {
    const s = this;

    const _change = function (child) {
      const id = child.originalResource || child.id;
      const isColored = rowIDsFull.includes(id);
      const isStroked = rowIDsStroked.includes(id);

      // save origin values
      if (child.color && child.color !== '#6495ED') {
        this.markerColorStorage.set(id, child.color);
      }

      if (child.stroke && child.stroke !== '#0808FF') {
        this.strokeColorStorage.set(id, child.stroke);
      }

      if (isColored) {
        child.color = this.markerColor;
      } else {
        child.color = this.markerColorStorage.get(id) || null;
      }
      if (isStroked) {
        child.stroke = this.strokeColor;
      } else {
        child.stroke = null;
      }
      if (!isColored && !isStroked && this.showOnlyResults) {
        GanttUtilities.registerNoRenderId(child, this.UUID);
      } else {
        GanttUtilities.removeNoRenderId(child, this.UUID);
      }
    };

    DataManipulator.iterateOverDataSet(this.ganttDiagram.getDataHandler().getOriginDataset().ganttEntries, {
      changeToMarkRow: _change.bind(this),
    });
    if (updateGantt) {
      this.ganttDiagram.update();
    }
  }

  /**
   * Returns all row names of search result as an string array.
   * @param query Search query.
   */
  getArrayOfEntriesByQuery(query) {
    const results = [];
    const _getEntries = function (child) {
      if (this._isStringContained(child, query)) {
        const name = child.name;
        if (name && !GanttUtilities.isStringInArray(name, results)) {
          // if not empty and not already exists
          results.push(child.name);
        }
      }
    };
    DataManipulator.iterateOverDataSet(this.ganttDiagram.getDataHandler().getOriginDataset().ganttEntries, {
      getEntries: _getEntries.bind(this),
    });
    return results;
  }

  /**
   * Checks if given row has a child row which matches given search text.
   * @private
   * @param {GanttDataRow[]} rowChilds
   * @param {string} searchText
   */
  private _rowHasSearchChild(rowChilds, searchText) {
    const s = this;
    let match = false;

    const matchingChild = function (child) {
      if (s._isStringContained(child, searchText)) match = true;
    };
    DataManipulator.iterateOverDataSet(rowChilds, { matchingChild: matchingChild });

    return match;
  }

  /**
   * Checks if text contains searchText (case insensitive).
   * @private
   * @param {GanttDataRow} row Search base.
   * @param {string} searchText Search query.
   * @return {boolean} True if text contains searchText.
   */
  private _isStringContained(row, searchText) {
    if (row.originalResource) {
      // only check origin rows
      return false;
    }

    // check for name
    const check = (val) => val.toLowerCase().indexOf(searchText.toLowerCase()) != -1;
    if (check(row.name)) {
      return true;
    }

    // check for attributes
    if (row.additionalData && Array.isArray(row.additionalData.attributes)) {
      for (const attr of row.additionalData.attributes) {
        if (check(attr.value)) {
          return true;
        }
      }
    }
    return false;
  }

  /**
   * Resets all colorizment of rows.
   */
  reset() {
    const s = this;
    this._setCurrentSearchText('');
    const resetChildStyle = function (child) {
      const id = child.originalResource || child.id;
      child.color = s.markerColorStorage.get(id) || null;
      child.stroke = s.strokeColorStorage.get(id) || null;
      GanttUtilities.removeNoRenderId(child, s.UUID);
    };
    DataManipulator.iterateOverDataSet(s.ganttDiagram.getDataHandler().getOriginDataset().ganttEntries, {
      matchingChild: resetChildStyle,
    });
    this.markerColorStorage = new Map();
    this.strokeColorStorage = new Map();
    this.ganttDiagram.update();
  }

  //
  // GETTER & SETTER
  //
  /**
   * @param {string} color Color of rows which matches text.
   */
  setMarkerColor(color) {
    this.markerColor = color;
  }
  /**
   * @param {string} color Stroke color of rows which contain childs rows which match text.
   */
  setStrokeColor(color) {
    this.strokeColor = color;
  }

  setShowOnlyResults(bool) {
    if (this.showOnlyResults !== bool) {
      this.showOnlyResults = bool;
      this._showOnlyResultsChangeSubject.next(this.showOnlyResults);
    }
  }

  getCurrentSearchText() {
    return this.currentSearchText;
  }

  private _setCurrentSearchText(searchText: string) {
    if (this.currentSearchText !== searchText) {
      this.currentSearchText = searchText;
      this._searchTextChangeSubject.next(this.currentSearchText);
    }
  }

  getNoRenderId(): string {
    return this.UUID;
  }

  public getSearchTextChangeObservable(): Observable<string> {
    return this._searchTextChangeSubject.asObservable();
  }

  public getShowOnlyResultsChangeObservable(): Observable<boolean> {
    return this._showOnlyResultsChangeSubject.asObservable();
  }
}
