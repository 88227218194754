import { EGanttInstance } from '@gantt/public-api';
import { GanttLibService } from 'frontend/src/dashboard/gantt/gantt/gantt-lib.service';
import { SaxMsBestGanttActiveSubmenuEntryElementSetting } from 'frontend/src/dashboard/gantt/gantt/saxms-best-gantt.settings';
import { GanttPluginHandlerService } from 'frontend/src/dashboard/gantt/general/plugin/gantt-plugin-handler.service';
import { Observable, of, Subject } from 'rxjs';
import { GeneralGanttActionHandler } from '../../../action-handling/action-handler';
import { GanttResponseHandler } from '../../../response/response-handler';
import { ExternalGanttPlugin } from '../../external-plugin';

export const GanttShiftDragVisualizer = 'gantt-preview-shift-drag-target';

/**
 * PlugIn-Wrapper for StickyRows.
 * Provides sticky row / ganttentrie functionality.
 */
export class GanttShiftDragVisualizerPlugIn extends ExternalGanttPlugin {
  private templateData: any;
  private responseData: any;
  private plugInIsActive: boolean;
  public plugInIsActiveNotification: Subject<boolean> = new Subject<boolean>();

  constructor(
    protected _ganttPluginHandlerService: GanttPluginHandlerService,
    protected _ganttLibService: GanttLibService,
    actionHandler: GeneralGanttActionHandler,
    private responseHandler: GanttResponseHandler
  ) {
    super(_ganttPluginHandlerService, _ganttLibService, actionHandler);
  }

  public onInit(templateData: any, responseData: any) {
    const shiftBuilder = {};
    for (const scrollContainerId of this.gantt.getRenderDataHandler().getAllScrollContainerIds()) {
      shiftBuilder[scrollContainerId] = this.gantt.getShiftFacade().getShiftBuilder(scrollContainerId);
    }

    this.addPlugIn(
      GanttShiftDragVisualizer,
      this._ganttLibService.ganttInstanceService.getInstance(EGanttInstance.SHIFT_DRAG_VISUALIZER, shiftBuilder)
    );
    this.templateData = templateData;
    this.responseData = responseData;
  }

  public onDestroy(): void {}

  public onAction(action: any) {}

  public executeAction(localAction: any): Observable<any> {
    return of(true);
  }

  public isActive(): boolean {
    return this.plugInIsActive;
  }

  public injectSettings(submenuElements: SaxMsBestGanttActiveSubmenuEntryElementSetting[]): void {}
}
