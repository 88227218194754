import { UiService } from '@app-modeleditor/ui.service';
import { GanttChildren, IGanttAttributeMapping } from 'frontend/src/dashboard/gantt/general/generator/gantt-input.data';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { UtilTooltipMapper } from '../../../generator/mapper/gantt.tooltip.mapper';
import { GanttPluginHandlerService } from '../../gantt-plugin-handler.service';
import { IIntervalInput, ITimeIntervalStroke, ITimePeriod } from './blocking-intervals.plugin';
import {
  IGanttSelectedTimePeriod,
  IGanttTimePeriodGroupIntervalInputs,
} from './gantt-template-data-attribute-mapping.interface';
import { ITimePeriodInput } from './responses/interval-update/time-period-input.interface';

/**
 * Mapper to transform backend data into plugin configuration data.
 */
export class GanttBlockingIntervalsMapper {
  private _multipleIntervalIds: Map<string, string> = new Map<string, string>();

  constructor(private _ganttPluginHandlerService: GanttPluginHandlerService) {}

  /**
   * Maps backend data to structure data for GanttTimePeriodExecuter creation.
   * @param timePeriodData
   */
  getIntervalByBackendData(timePeriodData: IGanttTimePeriodGroupIntervalInputs): IIntervalInput {
    return {
      id: timePeriodData.clazz,
      type: timePeriodData.clazz,
      color: timePeriodData.color,
      stroke: this.getIntervalStroke(timePeriodData),
      timePeriods: [],
      mixedEditModeAvailable: timePeriodData.inEditMode || false, // wether or not on mouse over blocking interval enables edit mode for this type
    };
  }

  /**
   * Extracts stroke style by timeperiod backend data.
   * Uses color, and stroke width. If stroke width is not given, use 1 as default value.
   * @param timePeriodData
   */
  getIntervalStroke(timePeriodData: ITimePeriodInput | IGanttTimePeriodGroupIntervalInputs): ITimeIntervalStroke {
    if (!timePeriodData.periodStroke) return null;
    return {
      color: timePeriodData.periodStroke.color,
      width: timePeriodData.periodStroke.width || 1,
    };
  }

  /**
   * Returns the backend defined id of an mapped interval id.
   */
  getOriginIdByGeneratedId(generatedId: string): string {
    return this.getMultipleIntervalIds().get(generatedId);
  }

  /**
   * Interface to call backend to recieve time period data.
   * @param restURL Rest url to get data. Will be provided by template data.
   * @param uiService Service to connect to backend.
   * @param timeperiodExecuterDefinition List of backend definitions for time period handler.
   */
  getIntervalTimePeriodsByRestURL(
    restURL: string,
    uiService: UiService,
    timeperiodExecuterDefinition: IGanttTimePeriodGroupIntervalInputs[]
  ): Observable<IMappedTimePeriods[]> {
    return uiService.getData(restURL).pipe(
      catchError((e) => of([])),
      map((intervalTimePeriods: ITimePeriodInput[]) => {
        return this.mapBackendIntervalsToTimePeriods(intervalTimePeriods, timeperiodExecuterDefinition);
      })
    );
  }

  /**
   * Maps all time periods of all blocking interval types.
   * @param backendIntervalTimePeriods Backend data to define blocking interval data.
   * @param timeperiodExecuterDefinition Backend data to define all TimePeriodExecuters. Necessary to extract each attribute mapping.
   */
  mapBackendIntervalsToTimePeriods(
    backendIntervalTimePeriods: ITimePeriodInput[],
    timeperiodExecuterDefinition: IGanttTimePeriodGroupIntervalInputs[]
  ): IMappedTimePeriods[] {
    const mappedTimePeriods: IMappedTimePeriods[] = [];
    for (const intervalTypeItem of backendIntervalTimePeriods) {
      for (const intervalType in intervalTypeItem) {
        try {
          const intervals: ITimePeriodInput[] = intervalTypeItem[intervalType];
          const type = this.getIntervalTypeByBackendName(intervalType);
          const mappedIntervals: IMappedTimePeriods = {
            timePeriods: this.getIntervalInputByBackendData(intervals, timeperiodExecuterDefinition, type),
            type: type,
          };
          mappedTimePeriods.push(mappedIntervals);
        } catch (e) {
          console.error(e);
        }
      }
    }
    return mappedTimePeriods;
  }

  /**
   * Maps given time periods from unique id to origin id of backend.
   * @param intervals
   * @returns
   */
  mapIntervalsBackToOriginId(intervals: IGanttSelectedTimePeriod[]): IGanttSelectedTimePeriod[] {
    return intervals.map((interval) => {
      return {
        id: this.getMultipleIntervalIds().get(interval.id) || interval.id, // interval.id for downgrading
        groupId: interval.groupId,
        type: interval.type,
      };
    });
  }

  /**
   * Removes "class" from interval type name.
   * Necessary for response and action handling (otherwise names would not match).
   * @param backendIntervalType Interval type name.
   */
  getIntervalTypeByBackendName(backendIntervalType: string): string {
    return backendIntervalType.replace('class ', '');
  }

  /**
   * Maps concrete timeperiods by backend data to insert into TimePeriodExecuters.
   * @param intervalData List of backend definitions for concrete time periods.
   * @param timeperiodExecuterDefinition List of all executer backend definitions inside template data.
   * @param intervalType Type of interval to map.
   */
  public getIntervalInputByBackendData(
    intervalData: ITimePeriodInput[],
    timeperiodExecuterDefinition: IGanttTimePeriodGroupIntervalInputs[],
    intervalType: string
  ): ITimePeriod[] {
    const mappedIntervals: ITimePeriod[] = [];
    const attributeMapping = this.getAttributeMappingByPeriodType(timeperiodExecuterDefinition, intervalType);

    for (const interval of intervalData) {
      if (interval.start >= interval.end) continue;

      if (interval.hasOwnProperty('ganttEntryId')) {
        // if this is a unique blocking interval id
        mappedIntervals.push({
          rowId: interval.ganttEntryId,
          timeStart: new Date(interval.start),
          timeEnd: new Date(interval.end),
          intervalId: interval.id,
          name: interval.name,
          descriptionData: null,
          customColor: interval.customColor,
          tooltip: interval.details
            ? UtilTooltipMapper.getTooltipByTemplateData(interval.details.additionalDetails, attributeMapping)
            : '',
          details: interval.details.additionalDetails,
          stroke: this.getIntervalStroke(interval),
          sIds: interval.sIds,
        });
      } else if (
        (interval.ganttEntryIds && interval.ganttEntryIds.length > 0) ||
        (interval.ganttEntryResourceIds && interval.ganttEntryResourceIds.length > 0)
      ) {
        // blocking interval with same id used in multiple gantt rows
        if (interval.ganttEntryIds && interval.ganttEntryIds.length > 0) {
          for (const ganttRowId of interval.ganttEntryIds) {
            const newIntervalId = ganttRowId + '-' + interval.id; // generate new blocking interval id

            this._multipleIntervalIds.set(newIntervalId, interval.id);
            mappedIntervals.push({
              rowId: ganttRowId,
              timeStart: new Date(interval.start),
              timeEnd: new Date(interval.end),
              intervalId: newIntervalId,
              name: interval.name,
              descriptionData: null,
              customColor: interval.customColor,
              tooltip: interval.details
                ? UtilTooltipMapper.getTooltipByTemplateData(interval.details.additionalDetails, attributeMapping)
                : '',
              details: interval.details.additionalDetails,
              stroke: this.getIntervalStroke(interval),
              sIds: interval.sIds,
            });
          }
        }
        // blocking interval with same id used in multiple gantt rows with the specified ganttEntryResourceIds
        if (interval.ganttEntryResourceIds && interval.ganttEntryResourceIds.length > 0) {
          for (const ganttEntryResourceId of interval.ganttEntryResourceIds) {
            this._ganttPluginHandlerService.getTemplateData().iterateOverAllEntries(
              ((ganttEntry: GanttChildren) => {
                if (ganttEntry.ganttEntryResourceId && ganttEntry.ganttEntryResourceId === ganttEntryResourceId) {
                  const newIntervalId = `${ganttEntry.id}-${ganttEntry.ganttEntryResourceId}-${interval.id}`; // generate new blocking interval id

                  this._multipleIntervalIds.set(newIntervalId, interval.id);
                  mappedIntervals.push({
                    rowId: ganttEntry.id,
                    timeStart: new Date(interval.start),
                    timeEnd: new Date(interval.end),
                    intervalId: newIntervalId,
                    name: interval.name,
                    descriptionData: null,
                    customColor: interval.customColor,
                    tooltip: interval.details
                      ? UtilTooltipMapper.getTooltipByTemplateData(interval.details.additionalDetails, attributeMapping)
                      : '',
                    details: interval.details.additionalDetails,
                    stroke: this.getIntervalStroke(interval),
                    sIds: interval.sIds,
                  });
                }
              }).bind(this)
            );
          }
        }
      } else {
        continue;
      }
    }
    return mappedIntervals;
  }

  /**
   * Extracts attribute map of given timeperiod definition.
   * @param timeperiodExecuterDefinition List of all executer backend definitions inside template data.
   * @param intervalType Searched type to extract attribute map.
   */
  getAttributeMappingByPeriodType(
    timeperiodExecuterDefinition: IGanttTimePeriodGroupIntervalInputs[],
    intervalType: string
  ): IGanttAttributeMapping {
    for (const attributeMap of timeperiodExecuterDefinition) {
      if (attributeMap.clazz === intervalType) {
        return attributeMap.attributeMapping;
      }
    }
    console.error('Cant find attribute map for ' + intervalType);
    return null;
  }

  getMultipleIntervalIds(): Map<string, string> {
    return this._multipleIntervalIds;
  }
}

/**
 * Data container to store a list of timePeriods with its type.
 */
export interface IMappedTimePeriods {
  timePeriods: ITimePeriod[];
  type: string;
}
