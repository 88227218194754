import { Overlay, OverlayRef, PositionStrategy } from '@angular/cdk/overlay';
import { DOCUMENT } from '@angular/common';
import { ComponentRef, Directive, ElementRef, Inject, Injector, NgZone, OnDestroy, OnInit } from '@angular/core';
import { IOverlayOptions } from '@app-modeleditor/components/lightbox/overlay/overlay-options.interface';
import { OverlayService } from '@app-modeleditor/components/lightbox/overlay/overlay.service';
import { GanttCanvasShift, GanttDataShift } from '@gantt/public-api';
import { GanttLibService } from 'frontend/src/dashboard/gantt/gantt/gantt-lib.service';
import { fromEvent, Observable, of, Subject, Subscription } from 'rxjs';
import { debounceTime, delay, takeUntil } from 'rxjs/operators';
import { ResourceElementComponent } from '../components/resource-element/resource-element.component';
import { IResourceElement } from '../components/resource-element/resource-element.interface';
import { ResourceCommunicationService } from '../resource-communication.service';
import { DragResourceElementComponent } from './drag-resource-element/drag-resource-element.component';

@Directive({
  selector: '[dragResource]',
})
export class DragDirective implements OnInit, OnDestroy {
  private _element: HTMLElement;
  private _subscriptions: Subscription[] = [];
  private _currentOverlayRef: OverlayRef;
  private _componentRef: ComponentRef<DragResourceElementComponent>;
  private _isDragging = false;
  private _onMouseMove: Subject<MouseEvent> = new Subject();
  private _currentResourceElement: IResourceElement;

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    private _elementRef: ElementRef,
    private _overlay: Overlay,
    private _host: ResourceElementComponent,
    @Inject('InternalOverlayService') private _overlayService: OverlayService,
    private _ganttLibService: GanttLibService,
    private _ngZone: NgZone,
    private _resourceCommunicationService: ResourceCommunicationService,
    private injector: Injector
  ) {}

  ngOnInit(): void {
    this._element = this._elementRef.nativeElement as HTMLElement;
    this._ngZone.runOutsideAngular((_) => this.initDrag());
    this._throttledMouseMoveHandling();
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach((s) => {
      if (s) s.unsubscribe();
    });
  }

  initDrag(): void {
    const dragStart$ = fromEvent<MouseEvent>(this._element, 'mousedown');
    const dragEnd$ = fromEvent<MouseEvent>(this._document, 'mouseup');
    const drag$ = fromEvent<MouseEvent>(this._document, 'mousemove').pipe(takeUntil(dragEnd$));

    let dragSub: Subscription;

    // dragStart
    const dragStartSub = dragStart$.subscribe((event: MouseEvent) => {
      event.stopPropagation();

      // drag
      dragSub = drag$.subscribe((event: MouseEvent) => {
        if (!this._isDragging) {
          this._isDragging = true;
          this._checkSelection();
          this.createOverlay(event, this._host.elementData);
          this._currentResourceElement = this._host.elementData;
        }
        event.stopPropagation();
        this.updatePosition(event);
        this._onMouseMove.next(event);
      });
    });

    // dragEnd
    const dragEndSub = dragEnd$.subscribe((event: MouseEvent) => {
      // event.preventDefault();
      // event.stopPropagation();
      if (this._isDragging) {
        // real drag end
        this._isDragging = false;
        this._componentRef.instance.dropped = true;
        this._handleDropEvent(event);
        this._currentResourceElement = undefined;
      }
      // if (this._currentOverlayRef) { this._currentOverlayRef.dispose(); }
      if (dragSub) {
        dragSub.unsubscribe();
      }
    });

    this._subscriptions.push.apply(this._subscriptions, [dragStartSub, dragSub, dragEndSub]);
  }

  private getPositionStrategy(event: MouseEvent): PositionStrategy {
    const halfDragObjectWidth = this._componentRef?.instance.width ? this._componentRef.instance.width / 2 : 0;
    const dragObjectHeight = this._componentRef?.instance.height ? this._componentRef.instance.height : 0;

    return this._overlay
      .position()
      .global()
      .top(event.pageY - dragObjectHeight + 5 + 'px')
      .left(event.pageX - halfDragObjectWidth + 'px');
  }

  private updatePosition(event: MouseEvent) {
    this._currentOverlayRef.updatePositionStrategy(this.getPositionStrategy(event));
    this._currentOverlayRef.updatePosition();
  }

  private createOverlay(event: MouseEvent, elementData: IResourceElement) {
    const overlayOptions: IOverlayOptions = {
      backdrop: false,
      positionStrategy: this.getPositionStrategy(event),
    };
    this._ngZone.run((_) => {
      const creation = this._overlayService.create(
        DragResourceElementComponent,
        this._host.resourceElement,
        elementData,
        overlayOptions,
        this.injector
      );
      this._currentOverlayRef = creation.overlayRef;
      this._componentRef = creation.componentRef;
    });
  }

  private _handleDropEvent(mouseEvent: MouseEvent) {
    const target: GanttCanvasShift = this._ganttLibService.bestGantt.getShiftByMousePosition(mouseEvent);
    if (!target) {
      const sourceBounding: DOMRect = this._elementRef.nativeElement.getBoundingClientRect();
      const x = sourceBounding.x + sourceBounding.width / 2;
      const y = sourceBounding.y + sourceBounding.height / 2;
      this._runResourceDropAnimation(x, y).subscribe((_) => {
        this._currentOverlayRef.dispose();
      });
    } else {
      // drop successful
      this._runResourceDropAnimation(mouseEvent.x, mouseEvent.y).subscribe((_) => {
        this._currentOverlayRef.dispose();
        const draggedResourceIds = this._resourceCommunicationService.currentSelectedResources.length
          ? this._resourceCommunicationService.currentSelectedResources
          : [this._host.elementData.id];
        this._ganttLibService.triggerExternalDrop(target, draggedResourceIds);

        // clear selection
        this._resourceCommunicationService.clearSelectedResources();
        this._resourceCommunicationService.emitSingleSelection();
      });
    }
    this._ganttLibService.bestGantt.deHighlightAllShifts();
    this._ganttLibService.bestGantt.getDataHandler().initCanvasShiftData();
    this._ganttLibService.bestGantt.rerenderShiftsVertical();
  }

  private _throttledMouseMoveHandling() {
    const subscription = this._onMouseMove.pipe(debounceTime(50)).subscribe((event: MouseEvent) => {
      this._ganttLibService.bestGantt.deHighlightAllShifts();
      const target: GanttCanvasShift = this._ganttLibService.bestGantt.getShiftByMousePosition(event);
      if (target) {
        const originShift: GanttDataShift = this._ganttLibService.bestGantt.getShiftById(target.id);
        originShift.highlighted = this._currentResourceElement?.color || '#3477eb';
      }

      this._ganttLibService.bestGantt.getDataHandler().initCanvasShiftData();
      this._ganttLibService.bestGantt.rerenderShiftsVertical();
    });
    this._subscriptions.push(subscription);
  }

  private _runResourceDropAnimation(x: number, y: number): Observable<null> {
    const overlayElement = this._currentOverlayRef.overlayElement;

    // move back transition
    overlayElement.style.transition = 'all 0.3s';
    overlayElement.style.marginLeft = x + 'px';
    overlayElement.style.marginTop = y + 'px';
    overlayElement.style.transform = 'scale(0.2)';
    overlayElement.style.opacity = '0';
    of(null)
      .pipe(delay(350))
      .subscribe(() => {
        this._currentOverlayRef.dispose();
      });
    return of(null).pipe(delay(350));
  }

  private _checkSelection() {
    const dragResourceId = this._host.elementData.id;
    if (!this._resourceCommunicationService.currentSelectedResources.includes(dragResourceId)) {
      this._resourceCommunicationService.clearSelectedResources();
      this._resourceCommunicationService.emitSingleSelection();
    }
  }
}
