import { EGanttInstance } from '@gantt/public-api';
import { SaxMsBestGanttActiveSubmenuEntryElementSetting } from 'frontend/src/dashboard/gantt/gantt/saxms-best-gantt.settings';
import { Observable, of, Subject } from 'rxjs';
import { ExternalGanttPlugin } from '../../external-plugin';

const GanttXAxisZoomTransformatorId = 'gantt-x-axis-zoom-transformator';

/**
 * PlugIn-Wrapper for GanttXAxisZoomTransformator.
 * Handles x axis format transformation.
 */
export class GanttXAxisFormatPlugIn extends ExternalGanttPlugin {
  public activeFormat: Subject<string> = new Subject<string>();

  public onInit(templateData: any, responseData: any) {
    this.addPlugIn(
      GanttXAxisZoomTransformatorId,
      this._ganttLibService.ganttInstanceService.getInstance(EGanttInstance.X_AXIS_ZOOM_TRANSFORMATOR, false)
    );
  }

  public onDestroy(): void {}

  public onAction(action: any) {}

  public executeAction(action: any): Observable<any> {
    return of(null);
  }

  /**
   * (De-)Activates a static full day x axis format which is independent of the current zoom level.
   */
  public toggleFullDayFormat(): void {
    const timeFormatter = this.getPlugInById(GanttXAxisZoomTransformatorId);
    if (timeFormatter.getActiveFormat() == 'DAY') {
      timeFormatter.removeFormat();
      this.activeFormat.next(null);
    } else {
      timeFormatter.setFormatToDays();
      this.activeFormat.next('DAY');
    }
  }

  /**
   * (De-)Activates a static full week x axis format which is independent of the current zoom level.
   */
  public toggleFullWeekFormat(): void {
    const timeFormatter = this.getPlugInById(GanttXAxisZoomTransformatorId);
    if (timeFormatter.getActiveFormat() == 'WEEK') {
      timeFormatter.removeFormat();
      this.activeFormat.next(null);
    } else {
      timeFormatter.setFormatToWeeks();
      this.activeFormat.next('WEEK');
    }
  }

  public injectSettings(submenuElements: SaxMsBestGanttActiveSubmenuEntryElementSetting[]): void {}
}
