import FloatParsing from './float-parsing';

class IntegerParsing extends FloatParsing {
  public inputToValue(input?: string): number | undefined {
    return super.finiteOrUndefined(
      parseInt(
        input.replace(/\./g, '') // remove thousand points
      )
    );
  }

  protected get decimalsCount(): number {
    return 0;
  }
}

export default IntegerParsing;
