<div class="dateSelectorMainClass">
  <div class="timeSpanSelectorClass" (mousemove)="onMouseMove($event)">
    <div
      [ngStyle]="{ 'width.px': config.selectorWidth, 'height.px': config.selectorHeight }"
      class="timeSpanSelectorElement"
    >
      <div
        #navigateLeftButton
        (click)="navigate('LEFT')"
        [ngStyle]="{ 'width.px': config.navButtonWidth }"
        class="navigateLeft navigation"
      >
        <mat-icon>keyboard_arrow_left</mat-icon>
      </div>
      <div
        #selectorContentContainer
        class="selectorContentContainer"
        [ngStyle]="{ 'width.px': timeSpanContentContainerWidth }"
      >
        <div #elementContainer class="timeSpanSelector">
          <div
            #startRestrictionBlock
            class="restrictionBlock restrictionBlockStart"
            [ngStyle]="{ 'width.px': startRestrictionBlockWidth }"
          ></div>
          <div
            #endRestrictionBlock
            class="restrictionBlock restrictionBlockEnd"
            [ngStyle]="{ 'width.px': endRestrictionBlockWidth + 1 }"
          ></div>
          <div
            #elementSelector
            [ngStyle]="{ 'transition-duration': draggingSelector ? '0s' : '0.25s' }"
            class="elementSelector"
            (mousedown)="moveStartElementSelector($event)"
            (dblclick)="onSelectorDblClick($event)"
          >
            <div
              class="elementSelectorHandle elementSelectorHandleLeft"
              (mousedown)="handleElementSelectorHandle($event, 'LEFT')"
            ></div>
            <div
              class="elementSelectorHandle elementSelectorHandleRight"
              (mousedown)="handleElementSelectorHandle($event, 'RIGHT')"
            ></div>
          </div>
          <div
            class="timeSpanElement"
            *ngFor="let item of viewData"
            [ngStyle]="{ 'width.px': timeSpanElementWidth }"
            (dblclick)="focusElement(item.elementId)"
          >
            <div class="textBoxClass">
              <p class="timeSpanElementTitle">{{ item.title }}</p>
              <p class="timeSpanElementSubtitle">{{ item.subtitle }}</p>
            </div>
          </div>
        </div>
      </div>
      <div
        #navigateRightButton
        (click)="navigate('RIGHT')"
        [ngStyle]="{ 'width.px': config.navButtonWidth }"
        class="navigateRight navigation"
      >
        <mat-icon>keyboard_arrow_right</mat-icon>
      </div>
    </div>
  </div>
  <div class="timeIntervalSelectionClass">
    <button
      mat-flat-button
      [class.buttonActive]="selectedCategory === timeIntervalType.WEEK"
      color="basic"
      (click)="toggleTimeInterval('WEEK')"
    >
      {{ 'CALENDAR.week' | translate }}
    </button>
    <button
      mat-flat-button
      [class.buttonActive]="selectedCategory === timeIntervalType.MONTH"
      color="basic"
      (click)="toggleTimeInterval('MONTH')"
    >
      {{ 'CALENDAR.month' | translate }}
    </button>
    <button
      mat-flat-button
      [class.buttonActive]="selectedCategory === timeIntervalType.QUARTER"
      color="basic"
      (click)="toggleTimeInterval('QUARTER')"
    >
      {{ 'CALENDAR.quarter' | translate }}
    </button>
  </div>
</div>
