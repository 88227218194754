import {
  AfterViewInit,
  Component,
  ElementRef,
  HostBinding,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { TemplateService } from '@app-modeleditor/utils/template.service';
import { ConfigService } from '@core/config/config.service';
import { Template } from 'frontend/src/dashboard/model/resource/template';
import { EResizeMode } from 'frontend/src/dashboard/model/resource/template-resize-mode.enum';
import { ETemplateType } from 'frontend/src/dashboard/model/resource/template-type';
import { ResizeService } from 'frontend/src/dashboard/view/resize/resize.service';
import { MenuItem } from 'frontend/src/dashboard/view/template-toolbar/menu-item';
import { ToolbarGroup } from 'frontend/src/dashboard/view/template-toolbar/toolbar-group';
import { ViewService } from 'frontend/src/dashboard/view/view.service';
import { Observable, Subject, Subscription, fromEvent, of } from 'rxjs';
import { debounceTime, delay, takeUntil } from 'rxjs/operators';
import { TemplateAdapter } from '../../utils/template-factory.service';
import { ContentPart } from '../content/content-part/content-part';
import { TemplateUnregisterService } from '../template/template-unregister.service';
import { HMI } from '../tree/tree-item';
import { IAfterSave } from './after-save.interface';
import { TemplateUiService } from './template.service';

@Component({
  selector: 'app-template-ui',
  templateUrl: './template-ui.component.html',
  styleUrls: ['./template-ui.component.scss'],
})
export class TemplateUiComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  model: any;
  showModifier = true; // whether the additional toolbar is shown or not
  private templateId: string;
  private templateRegistrationResourceId: string = null;
  private $scrollSub: Subscription;
  @ViewChild('templateUiWrapperRef', { read: ElementRef })
  templateUiWrapperRef: ElementRef;
  lowerScrollArea: ElementRef;
  @ViewChild('lowerScrollArea') set c2(lowerScrollArea: ElementRef) {
    this.$resizeApi.complete(this.resizeIds[1]);
    this.lowerScrollArea = lowerScrollArea;
    if (!lowerScrollArea) {
      return;
    }
    this.resizeIds[1] = this.$resizeApi.create(lowerScrollArea.nativeElement, () => {
      of(null)
        .pipe(delay(0))
        .pipe(takeUntil(this.onDestroy))
        .subscribe(() => {
          this.scrollbarWidth = this.$getScrollbarWidth(lowerScrollArea?.nativeElement);
          this.$checkContentParts();
        });
    });

    this.$scrollSub?.unsubscribe();
    this.$zone.runOutsideAngular(() => {
      fromEvent(this.lowerScrollArea.nativeElement, 'scroll')
        .pipe(takeUntil(this.onDestroy), debounceTime(350))
        .subscribe((e) => {
          this.$zone.run(() => {
            this.$checkContentParts();
          });
        });
    });
  }
  scrollbarWidth: number;

  get isSidebarEnabled(): boolean {
    return this.nt instanceof HMI && this.nt?.getSideBar() ? true : false;
  }

  ngOnChanges(changes: SimpleChanges): void {}

  get hasStickyContents(): boolean {
    return this.nt
      ?.getContent()
      .getContentParts()
      .find((p) => p.isSticky())
      ? true
      : false;
  }

  private $getScrollbarWidth(container: HTMLElement): number {
    if (container) {
      return container.offsetWidth - container.clientWidth;
    }
    return 0;
  }

  private resizeIds: string[] = [null, null];
  upperScrollArea: ElementRef;
  @ViewChild('upperScrollArea') set c1(upperScrollArea: ElementRef) {
    this.$resizeApi.complete(this.resizeIds[0]);
    this.upperScrollArea = upperScrollArea;
    if (!upperScrollArea) {
      return;
    }
    this.resizeIds[0] = this.$resizeApi.create(upperScrollArea.nativeElement, this.$resize.bind(this));
  }
  @Input() onCloseCb: () => void;
  @Input('model') set dataModel(model: any) {
    this.model = model;
    if (model?.getType() === ETemplateType.MENU_ITEM) {
      this.templateApi.setActiveMenuItem(model);
    }

    this.nt = this.model instanceof Template ? (this.model as HMI) : this.templateFactory.adapt(this.model);
    if (this.nt) {
      this.templateId = this.nt.getId();
      this.templateRegistrationResourceId = this.resourceId || this.model.resourceId;
      this.templateApi.registerTemplate(this.templateRegistrationResourceId, this.nt);
      this.templateApi.checkEditMode(this.nt);
    }

    this.classes =
      this.nt && this.nt.getContent() && this.nt.getContent().getResizeMode() === EResizeMode.FIT_PARENT
        ? 'fullscreen'
        : '';
    this.$resize();
  }

  isContentPartNavigationEnabled(): boolean {
    return this.nt instanceof HMI && this.nt?.isShowContentPartNavigation();
  }

  get name(): string {
    const nt: any = this.nt;
    return nt.getName() || (nt.title ? nt.getTitle() : nt.headline ? nt.getHeadline() : '');
  }

  @Input() root: HTMLElement;
  @Input() resourceId: string;
  private _onDestroySubject = new Subject<void>();
  nt: HMI;
  @HostBinding('class') classes: string;
  private $initialized = false;
  currentScrolledCp: string[] = [];
  templateUiHeight: number;
  parts: ContentPart[] = [];
  upperScrollAreaHeight: number;
  constructor(
    private templateApi: TemplateService,
    private viewApi: ViewService,
    private templateFactory: TemplateAdapter,
    public elementRef: ElementRef,
    private $zone: NgZone,
    private $resizeApi: ResizeService,
    private $unregisterApi: TemplateUnregisterService,
    private templateUiApi: TemplateUiService,
    private configService: ConfigService
  ) {
    this.templateUiApi
      .afterSave()
      .pipe(takeUntil(this.onDestroy))
      .subscribe((res: IAfterSave) => {
        if (res && this.nt instanceof HMI && this.nt.getResourceId() === res.savedRecord.getResourceId()) {
          this.nt?.setName(res.response);
        }
      });
  }

  /**
   * handle resizing of the component
   * @returns void
   */
  private $resize(): void {
    if (!this.$initialized) {
      return;
    }

    of(null)
      .pipe(delay(0))
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        const diff: number = this.upperScrollArea?.nativeElement.clientHeight || 0;
        this.lowerScrollArea.nativeElement.style.height = `calc(100% - ${diff > 0 ? diff + 1 : 0}px)`;
        this.templateUiHeight = this.templateUiWrapperRef?.nativeElement.clientHeight;

        this.upperScrollAreaHeight = this.upperScrollArea?.nativeElement.clientHeight || 0;
        this.$checkContentParts();
      });
  }

  ngOnInit(): void {
    this.$initialized = true;
  }

  onClose(): void {
    this.onCloseCb();
  }

  scrollIntoView(cp: ContentPart): void {
    if (!cp.getElementRef()) {
      return;
    }
    cp.setExpanded(true);
    cp.getElementRef().nativeElement.scrollIntoView();
    // cp.getElementRef().nativeElement.offsetTop
  }

  /**
   * checks contentparts for visibility in DOM
   * @param {ContentPart[]} parts list of contentparts which are currently visible
   * @param {HTMLElement} container reference element
   * @returns void
   */
  private $afterScroll(parts: ContentPart[], container: HTMLElement): void {
    if (!container) {
      return;
    }
    const domRect: DOMRect = container.getBoundingClientRect();
    this.currentScrolledCp = parts
      .slice()
      .filter((cp: ContentPart) => {
        if (!cp.getElementRef()) {
          return;
        }
        const rect: DOMRect = cp.getElementRef().nativeElement.getBoundingClientRect();

        if (!(rect.top > domRect.bottom || rect.bottom < domRect.top)) {
          return cp;
        }
      })
      .map((cp: ContentPart) => cp.getId());
  }

  /**
   * checks and gets contentparts which are currently visible
   * @returns void
   */
  private $checkContentParts(): void {
    const parts: ContentPart[] = this.nt?.getContent()?.getContentParts() || [];
    this.$afterScroll(parts, this.lowerScrollArea?.nativeElement);
    of(null)
      .pipe(delay(0))
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        this.parts = parts;
      });
  }

  ngOnDestroy(): void {
    this._onDestroySubject.next();
    this._onDestroySubject.complete();
    this.modifyToolbar(true);
    this.root = null;
    delete this.root;
    this.$scrollSub?.unsubscribe();
    this.$resizeApi.complete(...this.resizeIds);

    // check if template is registered and unregister it
    // do not unregister hierarchic menu items because they must be available for the whole session
    // if hierarchic menu item is inside template-ui, the batch table is displayed, if the batch table gets destroyed the menu item is still available
    // this is a special case where the template is shown twice (tree item and table)
    if (this.nt && this.nt?.getType() !== ETemplateType.HIERARCHIC_MENU_ITEM) {
      this.$unregisterApi.unregister(this.nt, this.templateRegistrationResourceId);
    }

    if (this.model?.getType() === ETemplateType.MENU_ITEM) {
      this.templateApi.removeActiveMenuItem(this.model.getId());
    }

    Object.keys(this).forEach((key) => {
      delete this[key];
    });
  }

  ngAfterViewInit(): void {
    this.modifyToolbar();
    this.$resize();
  }

  private modifyToolbar(reverse?: boolean) {
    if (
      !this.model ||
      !this.model.toolbarModifier ||
      this.configService.access().templates.toolbar?.version !== 'old'
    ) {
      return;
    }

    this.toggleToolbarElement(this.model.toolbarModifier, reverse);
    this.setToolbarItem(this.model.toolbarModifier, reverse);
    this.setToolbarGroup(this.model.toolbarModifier, reverse);
    this.setToolbarElement(this.model.toolbarModifier, reverse);
  }

  private toggleToolbarElement(mods, reverse) {
    // disable/ enable elements
    of(null)
      .pipe(delay(0))
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        if (!mods || !mods.disabledToolbarElements) {
          return;
        }
        this.viewApi.toggleElementStates(mods.disabledToolbarElements, !reverse);
      });
  }

  private setToolbarItem(mods, reverse) {
    // add / remove items
    of(null)
      .pipe(delay(0))
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        if (!mods || !mods.additionalToolbarItems) {
          return;
        }
        mods.additionalToolbarItems.forEach((item: any) => {
          if (reverse) {
            this.viewApi.deleteAppToolbarItem(item.id);
          } else {
            const i: MenuItem = this.templateFactory.adapt(item);
            i.setResourceId(this.resourceId || this.model.resourceId);
            this.viewApi.setAppToolbarItem(i);
            this.viewApi.toggleActiveMenuItem(i.getId(), this.viewApi.isLastStateOfToolbarOpen());
          }
        });
      });
  }

  private setToolbarGroup(mods, reverse) {
    // add / remove groups
    of(null)
      .pipe(delay(0))
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        if (!mods || !mods.additionalToolbarGroups) {
          return;
        }
        for (const key of Object.keys(mods.additionalToolbarGroups)) {
          mods.additionalToolbarGroups[key].forEach((g) => {
            const tGroup: ToolbarGroup = this.templateFactory.adapt(g);
            if (reverse) {
              this.viewApi.deleteAppToolbarGroup(tGroup.getId());
            } else {
              this.viewApi.setAppToolbarGroup(key, tGroup);
            }
          });
        }
      });
  }

  private setToolbarElement(mods, reverse) {
    // add / remove elements
    of(null)
      .pipe(delay(0))
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        if (!mods || !mods.additionalToolbarElements) {
          return;
        }
        for (const key of Object.keys(mods.additionalToolbarElements)) {
          mods.additionalToolbarElements[key].forEach((el) => {
            const t: Template = this.templateFactory.adapt(el);
            if (reverse) {
              this.viewApi.deleteAppToolbarElement(t.getId());
            } else {
              this.viewApi.setAppToolbarElement(t);
            }
          });
        }
      });
  }

  get hasEditBarMode(): boolean {
    return this.nt?.isHiddenTemplate && !this.nt?.isHiddenTemplate('EDIT_MODE_BAR');
  }

  /**
   * Observable which gets triggered when the component gets destroyed.
   */
  private get onDestroy(): Observable<void> {
    return this._onDestroySubject.asObservable();
  }
}
