import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class TemplatePickerService {
  private _inputs: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private _outputs: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor() {}

  getParams(type) {
    return type === 'INPUT' ? this._inputs.asObservable() : this._outputs.asObservable();
  }

  setParams(type, params) {
    if (type === 'INPUT') {
      this._inputs.next(params);
    } else {
      this._outputs.next(params);
    }
  }
}
