<!-- <button (click)="test()">rerender</button> -->
<ng-container *ngIf="!isLoading() && templateNode; else templateLoader">
  <ng-container *ngTemplateOutlet="elementTemplate"></ng-container>
</ng-container>

<ng-template #elementTemplate>
  <button
    [saxmsTooltip]="'Es ist eine Aktualisierung verfügbar'"
    *ngIf="templateNode.isNeedsUpdate()"
    (click)="$forceGetData(true)"
    mat-icon-button
    [matBadgeSize]="'small'"
    class="refresh-needed"
    [matBadge]="'!'"
  >
    <mat-icon>refresh</mat-icon>
  </button>
  <drag-n-drop [template]="templateNode" [disabled]="!editMode">
    <ng-container *ngTemplateOutlet="defaultNode"></ng-container>
  </drag-n-drop>
</ng-template>

<ng-template #defaultNode>
  <div
    (click)="onClick($event)"
    (contextmenu)="openCtx($event)"
    [ngClass]="templateNode.resizeMode === 'FIT_PARENT' ? 'fullscreen' : 'inherit-all'"
    [id]="templateNode.id"
    [style.display]="isShow() === true ? 'inherit' : 'none'"
    [attr.data-rec-name]="templateNode?.getName()"
  >
    <!-- area for uploading files-->
    <app-file-uploader *ngIf="hasFileUploadVisible" [disabled]="isDisabled" [template]="templateNode">
    </app-file-uploader>
    <mat-icon [style.color]="'red'" *ngIf="isHighlighted" class="highlighted">priority_high</mat-icon>
    <ng-container [ngSwitch]="templateNode.type">
      <!-- EntryCollection -->
      <ng-container *ngSwitchCase="'EntryCollection'">
        <ng-container *ngTemplateOutlet="entryCollection"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'EntryElementCollection'">
        <ng-container *ngTemplateOutlet="entryCollection"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'ToolbarGroup'">
        <ng-container *ngTemplateOutlet="entryCollection"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'ContentHeader'">
        <mat-card class="content-header">
          <div class="content">
            <ng-container *ngTemplateOutlet="entryCollection"></ng-container>
          </div>
        </mat-card>
      </ng-container>
      <!-- Entry Element-->
      <ng-container *ngSwitchCase="'EntryElement'">
        <template-entry-element [entryElement]="templateNode" *ngIf="templateNode"></template-entry-element>
      </ng-container>
      <!-- ContentPart -->
      <ng-container *ngSwitchCase="'ContentPart'">
        <app-content-part [resourceId]="resourceId" [contentId]="contentId" [contentPart]="templateNode" [root]="root">
        </app-content-part>
      </ng-container>
      <!-- ListSelector -->
      <ng-container *ngSwitchCase="'ListSelector'">
        <app-list-selector
          *ngIf="templateNode.value"
          (onChange)="onChanges(templateNode.doNotTrackChanges, $event, templateNode.restUrl)"
          [template]="templateNode"
        >
        </app-list-selector>
      </ng-container>
      <!-- IndexRegister -->
      <ng-container *ngSwitchCase="'IndexRegister'">
        <app-index-register [templateNode]="templateNode" [resourceId]="resourceId" [root]="root"> </app-index-register>
      </ng-container>
      <!-- IndexPage -->
      <ng-container *ngSwitchCase="'IndexPage'">
        <app-template-ui [resourceId]="resourceId || templateNode.resourceId" [root]="root" [model]="templateNode">
        </app-template-ui>
      </ng-container>
      <!-- Wizard -->
      <ng-container *ngSwitchCase="'Wizard'">
        <app-wizard-lightbox [wizard]="templateNode"></app-wizard-lightbox>
      </ng-container>
      <!--TemplateUi-->
      <ng-container *ngSwitchCase="'TemplateUi'">
        <app-template-ui [resourceId]="resourceId || templateNode.resourceId" [root]="root" [model]="templateNode">
        </app-template-ui>
      </ng-container>
      <!--HierarchicMenuItem-->
      <ng-container *ngSwitchCase="'HierarchicMenuItem'">
        <!--<app-template-ui [resourceId]="resourceId|| templateNode.resourceId"
            [root]="root" [model]="templateNode">
          </app-template-ui>-->
        <template-tree-node
          [mode]="'tree'"
          [style.height]="'20px'"
          [node]="templateNode"
          [selected]="templateNode.isSelected()"
        >
        </template-tree-node>
      </ng-container>
      <!-- ContentWrapper -->
      <ng-container *ngSwitchCase="'ContentWrapper'">
        <app-template-ui [resourceId]="resourceId || templateNode.resourceId" [root]="root" [model]="templateNode">
        </app-template-ui>
      </ng-container>
      <!-- Content -->
      <ng-container *ngSwitchCase="'Content'">
        <app-template-ui [resourceId]="resourceId || templateNode.resourceId" [root]="root" [model]="templateNode">
        </app-template-ui>
      </ng-container>
      <!-- ListSelectorEntryElement -->
      <ng-container *ngSwitchCase="'ListSelectorEntryElement'">
        <app-template-ui [resourceId]="resourceId || templateNode.resourceId" [root]="root" [model]="templateNode">
        </app-template-ui>
      </ng-container>
      <!-- Page -->
      <ng-container *ngSwitchCase="'Page'">
        <app-page [page]="templateNode"></app-page>
      </ng-container>
      <!-- Image -->
      <!--<ng-container *ngSwitchCase="'Image'">
        <template-image [path]="templateNode.imageName"></template-image>
      </ng-container>-->
      <!-- CHAT -->
      <ng-container *ngSwitchCase="'Chat'">
        <template-chat *ngIf="templateNode.value" [template]="templateNode" (afterSend)="refresh()"> </template-chat>
      </ng-container>
      <!-- MenuElement -->
      <ng-container *ngSwitchCase="'MenuElement'">
        <!-- <app-tree [model]="templateNode" [firstColWidth]="250"></app-tree> -->
      </ng-container>
      <!-- Menu -->
      <ng-container *ngSwitchCase="'Menu'">
        <ng-container *ngIf="configApi.access().templates?.Tree?.version === 'experimental'; else defaultMenu">
          <template-loader [id]="templateNode.id"></template-loader>
        </ng-container>
        <ng-template #defaultMenu>
          <app-tree [model]="templateNode" [firstColWidth]="250"></app-tree>
        </ng-template>
      </ng-container>
      <!-- HEADLINE -->
      <ng-container *ngSwitchCase="'HEADLINE'">
        <template-headline [headline]="templateNode"></template-headline>
      </ng-container>
      <!-- BREADCRUMBS -->
      <ng-container *ngSwitchCase="'Breadcrumbs'">
        <template-loader [id]="templateNode.id"></template-loader>
      </ng-container>
      <!-- Table -->
      <ng-container *ngSwitchCase="'Table'">
        <app-full-spreadsheet
          [editmode]="editMode"
          (onChanges)="getEditedEntries($event)"
          [template]="templateNode"
          [contentId]="contentId"
        >
        </app-full-spreadsheet>
      </ng-container>
      <!-- Printer  -->
      <ng-container *ngSwitchCase="'Printer'">
        <template-print [printer]="templateNode"></template-print>
      </ng-container>
      <!-- TwoColumnListSelector  -->
      <ng-container *ngSwitchCase="'TwoColumnListSelector'">
        <app-column-list-selector
          *ngIf="templateNode.value"
          (onChange)="onChanges(templateNode.doNotTrackChanges, $event, templateNode.restUrl)"
          [data]="templateNode"
        >
        </app-column-list-selector>
      </ng-container>
      <!-- Calendar -->
      <ng-container *ngSwitchCase="'Calendar'">
        <calendar-wrapper *ngIf="templateNode.value" [data]="templateNode" [values]="templateNode.getValue()">
        </calendar-wrapper>
      </ng-container>
      <!-- Gantt -->
      <ng-container *ngSwitchCase="'Gantt'">
        <gantt-general
          *ngIf="true"
          [eventList]="_notificationQueue"
          [data]="templateNode"
          [contentId]="contentId"
          [root]="root"
        >
        </gantt-general>
      </ng-container>
      <!-- LightBox  -->
      <ng-container *ngSwitchCase="'LightBox'">
        <app-template-ui [resourceId]="resourceId || templateNode.resourceId" [root]="root" [model]="templateNode">
        </app-template-ui>
      </ng-container>
      <!-- WidgetContainer -->
      <ng-container *ngSwitchCase="'WidgetContainer'">
        <app-template-widgetcontainer
          *ngIf="templateNode.value"
          [widgetTemplate]="templateNode"
          [contentId]="contentId"
        >
        </app-template-widgetcontainer>
      </ng-container>
      <!-- StatusBar -->
      <ng-container *ngSwitchCase="'StatusBar'">
        <template-statebar [template]="templateNode" *ngIf="templateNode.value"></template-statebar>
      </ng-container>
      <!-- Map -->
      <ng-container *ngSwitchCase="'Map'">
        <app-location-map
          *ngIf="templateNode?.value"
          [templateNode]="templateNode"
          (onChanges)="onChanges(templateNode.doNotTrackChanges, $event, templateNode.restUrl)"
        ></app-location-map>
      </ng-container>
      <!--  Viewer -->
      <ng-container *ngSwitchCase="'FileViewer'">
        <template-loader [id]="templateNode.id"></template-loader>
      </ng-container>

      <!-- Stackable -->
      <ng-container *ngSwitchCase="'Stackable'">
        <app-stackable [stackable]="templateNode">
          <ng-template #content let-node>
            <app-template-ui [model]="node" [resourceId]="resourceId || node.resourceId"></app-template-ui>
          </ng-template>
        </app-stackable>
      </ng-container>

      <!-- RangePicker -->
      <ng-container *ngSwitchCase="'RangePicker'">
        <template-range-picker *ngIf="templateNode.value" [template]="templateNode"> </template-range-picker>
      </ng-container>

      <!-- Hotspot -->
      <ng-container *ngSwitchCase="'HotSpotElement'">
        <template-hotspot-wrapper *ngIf="templateNode.value" [template]="templateNode"> </template-hotspot-wrapper>
      </ng-container>

      <!-- MenuItem -->
      <ng-container *ngSwitchCase="'MenuItem'">
        <!--<menu-item [template]="templateNode"></menu-item>-->
        <template-tree-node
          *ngIf="templateNode"
          [style.height]="'20px'"
          [node]="templateNode"
          [selected]="templateNode.isSelected()"
        >
        </template-tree-node>
      </ng-container>

      <!-- Progressbar -->
      <ng-container *ngSwitchCase="'ProgressBar'">
        <app-template-progressbar [template]="templateNode"></app-template-progressbar>
      </ng-container>
      <!-- Toolbar -->
      <ng-container *ngSwitchCase="'Toolbar'">
        <ng-container *ngIf="configApi.access().templates?.Toolbar?.version === 'experimental'; else defaultToolbar">
          <template-loader [id]="templateNode.id"></template-loader>
        </ng-container>
        <ng-template #defaultToolbar>
          <template-toolbar [template]="templateNode" [resourceId]="resourceId">
            <ng-template #tContent let-item let-resourceId="resourceId">
              <app-template [templateNode]="item" [resourceId]="resourceId"></app-template>
            </ng-template>
          </template-toolbar>
        </ng-template>
      </ng-container>
      <!-- ChartLegendDataset -->
      <ng-container *ngSwitchCase="'ChartLegendDataset'">
        <app-chart-legend-dataset [templateNode]="templateNode"> </app-chart-legend-dataset>
      </ng-container>
      <!-- ChartLegendAttribute -->
      <ng-container *ngSwitchCase="'ChartLegendAttribute'">
        <app-chart-legend-attribute [templateNode]="templateNode"> </app-chart-legend-attribute>
      </ng-container>
      <!-- ChartLegendAttribute -->
      <ng-container *ngSwitchCase="'PASSWORDCHANGE'">
        <change-password></change-password>
      </ng-container>
      <!-- ChartLegendAttribute -->
      <ng-container *ngSwitchCase="'USERSETTINGS'">
        <app-template-usersettings></app-template-usersettings>
      </ng-container>
      <!-- ChartLegendAttribute -->
      <ng-container *ngSwitchCase="'ERRORLOG'">
        <errorlog></errorlog>
      </ng-container>
      <!-- Default -->
      <ng-container *ngSwitchDefault>
        <strong>{{ templateNode.type }}</strong>
        {{ 'NOT_IMPLEMENTED.templateUI' | translate }}
      </ng-container>
    </ng-container>
  </div>
</ng-template>

<ng-template #templateLoader>
  <div class="template-loader">
    <mat-progress-bar *ngIf="!errorFlag" [id]="templateNode?.id + '-loading-spinner'" mode="buffer"> </mat-progress-bar>
    <div class="loading-bar-container">
      <span>{{
        (errorFlag ? (errorMessage ? errorMessage : 'TEMPLATE.Loading.error_name') : 'TEMPLATE.load_name')
          | translate : { name: templateNode?.name || '' }
      }}</span>
    </div>
  </div>
</ng-template>

<ng-template #entryCollection>
  <template-entry-collection
    [resourceId]="resourceId"
    [disabled]="isDisabled"
    [template]="templateNode"
    [highlightedKey]="highlightedKey"
    (valueChange)="onChanges(!$event.track, $event.event, $event.url, $event.template)"
    [r]="true"
  >
  </template-entry-collection>
</ng-template>

<!-- area to resize content -->
<ng-container
  *ngIf="
    templateNode?.getResizeMode() !== 'FIT_PARENT' &&
    templateNode?.isResizeable() &&
    templateNode?.getType() !== 'LightBox' &&
    templateNode?.getType() !== 'Wizard'
  "
>
  <div cdkDropList class="drag-area">
    <div
      class="inner"
      cdkDragLockAxis="y"
      cdkDrag
      (cdkDragStarted)="dragResizeStart($event)"
      (cdkDragEnded)="dragResizeEnd($event)"
    >
      <div cdkDragHandle style="width: 100%; height: 100%; display: flex; align-items: center; justify-content: center">
        <mat-icon class="drag-icon">more_horiz</mat-icon>
      </div>
    </div>
  </div>
</ng-container>
