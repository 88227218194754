import { Component, Input } from '@angular/core';
import { EntryElement } from '@app-modeleditor/components/entry-collection/entry-element';

@Component({
  selector: 'app-info-menu',
  templateUrl: './info-menu.component.html',
  styleUrls: ['./info-menu.component.scss'],
})
export class InfoMenuComponent {
  @Input() enabled: boolean;
  @Input() disabled: boolean;
  @Input() entryElement: EntryElement;

  constructor() {
    this.enabled = true;
  }
}
