import { Injectable, OnDestroy, inject } from '@angular/core';
import { ConfigService } from '@core/config/config.service';
import { BehaviorSubject, Observable, Subject, timer } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Injectable()
/**
 * Service that tracks the visibility state of the document and provides an Observable that emits a boolean value indicating whether the visibility state has changed.
 */
export class VisibilityStateService implements OnDestroy {
  private configService = inject(ConfigService);
  private triggerHidden$: Subject<null> = new Subject<null>();
  private triggerVisible$: Subject<null> = new Subject<null>();
  private readonly sleepTime = isNaN(this.configService.access()?.templates?.Gantt?.sleepTime)
    ? 0
    : this.configService.access()?.templates?.Gantt?.sleepTime;
  private readonly _isEnabled = this.sleepTime > 0;
  private visibilityChange$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    this._isEnabled ? !document.hidden : true
  );

  /**
   * A function that is called when the visibility of the page changes.
   */
  private visibilityListener = () => {
    this.checkVisibility();
  };

  constructor() {
    this.checkVisibility(); // initial check

    if (this._isEnabled) {
      document.addEventListener('visibilitychange', this.visibilityListener);
    }
  }

  ngOnDestroy(): void {
    if (this._isEnabled) {
      document.removeEventListener('visibilitychange', this.visibilityListener);
    }

    this.triggerHidden$.complete();
    this.triggerVisible$.complete();
    this.visibilityChange$.complete();
  }

  /**
   * Handles the hidden state of the visibility service by setting the visibility state to false
   * after the specified sleep time has elapsed, unless the triggerVisible$ or triggerHidden$ observables emit.
   */
  private handleHidden(): void {
    timer(this.sleepTime * 1000) // sleep after the specified time in seconds
      .pipe(takeUntil(this.triggerHidden$), takeUntil(this.triggerVisible$))
      .subscribe(() => {
        this.setVisibilityState(false);
      });
  }

  /**
   * Handles the visibility state of the page by setting the visibility state to true after a delay of 0.5 seconds.
   * This delay is to ensure that the user stays on the page before setting the visibility state.
   */
  private handleVisible(): void {
    timer(500) // wake up after 0.5 second (to be sure that user stays on the page)
      .pipe(takeUntil(this.triggerHidden$), takeUntil(this.triggerVisible$))
      .subscribe(() => {
        this.setVisibilityState(true);
      });
  }

  /**
   * Checks the visibility state of the document and triggers the appropriate event handlers.
   * If the service is disabled, sets the visibility state to true.
   */
  public checkVisibility(): void {
    if (!this._isEnabled) {
      this.setVisibilityState(true);
      return;
    }

    if (document.hidden) {
      this.triggerHidden$.next(null);
      this.handleHidden();
    } else {
      this.triggerVisible$.next(null);
      this.handleVisible();
    }
  }

  /**
   * Sets the visibility state of the component.
   * @param state The new visibility state.
   */
  private setVisibilityState(state: boolean): void {
    this.visibilityChange$.next(state);
  }

  /**
   * Returns an Observable that emits a boolean value indicating whether the visibility state has changed.
   * @returns An Observable that emits a boolean value indicating whether the visibility state has changed.
   */
  public isVisible$(): Observable<boolean> {
    return this.visibilityChange$.asObservable().pipe(distinctUntilChanged());
  }

  /**
   * Returns a boolean indicating whether the visibility state is currently visible or not.
   * @returns {boolean} The current visibility state.
   */
  public isVisible(): boolean {
    return this.visibilityChange$.getValue();
  }

  /**
   * Returns a boolean indicating whether the visibility state is enabled or not.
   * @returns {boolean} - True if the visibility state is enabled, false otherwise.
   */
  public isEnabled(): boolean {
    return this._isEnabled;
  }
}
