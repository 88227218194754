import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GanttLibService } from 'frontend/src/dashboard/gantt/gantt/gantt-lib.service';
import { GanttChildren, IGanttBlock, IGanttVisibleAttributes } from '../generator/gantt-input.data';
import { UtilTooltipMapper } from '../generator/mapper/gantt.tooltip.mapper';
import { GanttTemplateDataService } from '../template-data/gantt-template-data.service';

/**
 * Service to handle customized tooltips in gantt.
 */
@Injectable()
export class GanttTooltipService {
  constructor(
    private http: HttpClient,
    private ganttTemplateDataService: GanttTemplateDataService,
    private ganttLibService: GanttLibService
  ) {}

  /**
   * Fetches block tooltip settings from backend and updates the new tooltip configuration in js gantt.
   */
  public getBlockTooltipSettingsFromBackend(): void {
    if (this.ganttTemplateDataService.getTemplateData().isPrint()) return;

    const url = this.ganttTemplateDataService.getTemplateData().getTooltipSettingsURL();
    if (url) {
      this.http.get('rest/' + url).subscribe((blockTooltipSettings: IGanttVisibleAttributes) => {
        if (blockTooltipSettings) {
          this.ganttTemplateDataService.getTemplateData().setDefaultBlockTooltipSettings(blockTooltipSettings);
          this.updateTooltipsOfJsGantt();
        }
      });
    }
  }

  /**
   * Stores the new block tooltip settings to backend.
   * Handles the tooltip configuration changes in js gantt.
   * @param visibleAttributes new tooltip settings
   */
  public saveBlockTooltipSettingsToBackend(visibleAttributes: IGanttVisibleAttributes): void {
    this.ganttTemplateDataService.getTemplateData().setDefaultBlockTooltipSettings(visibleAttributes);
    this.updateTooltipsOfJsGantt();
    const url = this.ganttTemplateDataService.getTemplateData().getTooltipSettingsURL();

    if (url) {
      this.http.put('rest/' + url, visibleAttributes).subscribe();
    }
  }

  /**
   * Updates the block tooltip properties of js gantt.
   */
  public updateTooltipsOfJsGantt(): void {
    const mapper = this.ganttLibService.backendToGanttOriginInputMapper;
    const attributeMapping = this.ganttTemplateDataService.getTemplateData().getAttributeMapping();
    const callback = (block: IGanttBlock, row: GanttChildren) => {
      mapper.getShiftClonesByShiftId(block.id).forEach((shiftId) => {
        const jsGanttBlock = this.ganttLibService.bestGantt.getShiftById(shiftId);
        if (jsGanttBlock != null) {
          jsGanttBlock.tooltip = block.details
            ? mapper.getBlockTooltipByTemplateData(
                block.details,
                attributeMapping,
                this.ganttTemplateDataService.getTemplateData().getDefaultBlockTooltipSettings()
              )
            : '';
        }
      });
    };
    this.ganttTemplateDataService.getTemplateData().iterateOverAllBlocks(callback);
  }
}
