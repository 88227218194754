import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import { DateUtils } from '@app-modeleditor/utils/date-utils';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { map, startWith, takeWhile, throttleTime } from 'rxjs/operators';
import { TimePipe } from './time.pipe';

@Component({
  selector: 'app-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss'],
  providers: [],
})
export class TimePickerComponent implements OnDestroy, OnInit, OnChanges {
  @Input() disabled = false;
  @Input() label: string;
  @Input() appearance = 'standard';
  @Input() required = false;
  @Input() time: number;
  @Input() id: string;

  @Output() timeChanged: EventEmitter<number> = new EventEmitter<number>();

  formGroup: UntypedFormGroup;
  filteredOptions: Observable<number[]>;
  alive = true;
  times: number[];
  twelveHourFormat: boolean;
  newValue: Subject<number> = new Subject();

  constructor(private timePipe: TimePipe, private translate: TranslateService) {
    this.twelveHourFormat = this.translate.currentLang === 'de' ? false : true;
    this.translate.onLangChange.pipe(takeWhile(() => this.alive)).subscribe((l: any) => {
      this.twelveHourFormat = l === 'de' ? false : true;
    });

    this.formGroup = new UntypedFormGroup({
      start: new UntypedFormControl(),
    });

    this.newValue
      .pipe(throttleTime(500, null, { leading: false, trailing: true }))
      .pipe(takeWhile(() => this.alive))
      .subscribe((r) => {
        this.emit(r);
      });

    this.times = DateUtils.getTimeSteps(900000);
    // apply observable for filtering timesteps
    this.filteredOptions = this.formGroup.get('start').valueChanges.pipe(
      takeWhile(() => this.alive),
      startWith(''),
      map((value: string) => {
        if (!value) {
          return this.times;
        }
        return this.times
          .filter((t: number) => {
            const transformed: string = this.timePipe.transform(t, ['hours', 'minutes']);
            if (transformed.includes(value)) {
              return true;
            }
          })
          .sort((t1: number, t2: number) => {
            const t1T: string = this.timePipe.transform(t1, ['hours', 'minutes']);
            const t2T: string = this.timePipe.transform(t2, ['hours', 'minutes']);
            return t1T.indexOf(value) < t2T.indexOf(value) ? -1 : 1;
          });
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.time) {
      this.init();
    }

    if (changes.disabled) {
      if (this.disabled === true) {
        this.formGroup.get('start').disable();
      } else {
        this.formGroup.get('start').enable();
      }
    }
  }

  init(): void {
    if (this.time === this.lastEmit) {
      return;
    }
    this.formGroup.get('start').setValue(this.timePipe.transform(this.time, ['hours', 'minutes']), {
      emitValue: false,
    });
  }

  ngOnInit(): void {
    this.init();
  }

  ngOnDestroy(): void {
    this.alive = false;
    this.newValue.complete();
  }

  onTimeChanged(event: MatAutocompleteSelectedEvent): void {
    const value: number = event.option.value;
    const v: number = typeof value === 'string' ? DateUtils.getMillisFromTime(value) : value;

    // typeof value === "string" ? DateUtils.getMillisFromTime(value) : value;
    this.newValue.next(v);
  }

  lastEmit: number;
  emit(value: number): void {
    if (this.lastEmit === value) {
      return;
    }
    this.lastEmit = value;
    this.timeChanged.emit(value);
  }

  afterChanged(event: KeyboardEvent): void {
    const value: string = this.formGroup.get('start').value;
    const v: number = typeof value === 'string' ? DateUtils.getMillisFromTime(value) : value;
    this.newValue.next(v);
  }
}
