import { EntryElement } from './../../entry-collection/entry-element';
export class SaxmsRowEntryElement {
  // physical row number
  private rowNumber = 0;
  private colNumber = 0;
  private value: any = {};
  private valueChanged: boolean;
  private prevValue: any;
  private entryElement: EntryElement;
  private autoSave: boolean;
  private initKey: string;
  private minDate: Date;
  private maxDate: Date;

  public getMinDate(): Date {
    return this.minDate;
  }

  public setMinDate(minDate: Date): this {
    this.minDate = minDate;
    return this;
  }
  public getMaxDate(): Date {
    return this.maxDate;
  }

  public setMaxDate(maxDate: Date): this {
    this.maxDate = maxDate;
    return this;
  }

  public getInitKey(): string {
    return this.initKey;
  }

  public setInitKey(initKey: string): this {
    this.initKey = initKey;
    return this;
  }

  public isAutoSave(): boolean {
    return this.autoSave;
  }

  public setAutoSave(autoSave: boolean): this {
    this.autoSave = autoSave;
    return this;
  }

  public isValueChanged(): boolean {
    return this.valueChanged;
  }

  public setValueChanged(valueChanged: boolean): this {
    this.valueChanged = valueChanged;
    return this;
  }

  public getPrevValue(): any {
    return this.prevValue;
  }

  public setPrevValue(prevValue: any): this {
    this.prevValue = prevValue;
    return this;
  }

  public getEntryElement(): EntryElement {
    return this.entryElement;
  }

  public setEntryElement(entryElement: EntryElement): this {
    this.entryElement = entryElement;
    return this;
  }
  getValue(): any {
    return this.value;
  }
  getColNumber(): any {
    return this.colNumber;
  }
  getRowNumber(): any {
    return this.rowNumber;
  }

  setValue(value): SaxmsRowEntryElement {
    this.value = value;
    return this;
  }
  setColNumber(colNumber: number): SaxmsRowEntryElement {
    this.colNumber = colNumber;

    return this;
  }

  /**
   * sets the physical row number
   * @param {number} rowNumber physical row number
   * @returns SaxmsRowEntryElement
   */
  setRowNumber(rowNumber: number): SaxmsRowEntryElement {
    this.rowNumber = rowNumber;
    return this;
  }
}

export enum ETextStyle {
  BOLD = 'bold',
  UNDERLINE = 'underline',
  ITALIC = 'italic',
}

export enum ETextAlign {
  LEFT = 'left',
  RIGHT = 'right',
  CENTER = 'center',
}

export class ISaxMsSpreadsheetRowEntry {
  value: any;
  index: number;
  id: string;
  uuid: string;
  uniqueId: string;
  color?: string; // html color
  defaultColor?: string; // css class
  tableHeaderId: string;
  tooltip: string;
  defaultColorClass?: string;
  availableValues?: string[];
  updateRestUrl?: string;
  type?: string;
  foreColor?: string;
  textStyle?: ETextStyle[];
  textAlign?: ETextAlign;
  leftPadding?: number;
  saveOrder?: number;
  rowEditable?: boolean;
  edited?: boolean;
  editable?: boolean;
  parentRowId?: string;
  isInvalid?: boolean;
}

export interface ISaxMsSpreadsheetRawRow {
  resourceId: string;
  defaultColor?: string;
  properties: {
    [key: string]: ISaxMsSpreadsheetRowEntry;
  };
  children?: {
    [cKey: string]: ISaxMsSpreadsheetRowEntry;
  }[];
}
export class SaxMsSpreadsheetRowEntry {
  private value: any;
  private index: number;
  private id: string;
  private uuid: string;
  private color: string;
  private tooltip: string;
  private defaultColorClass: string;
  private availableValues: string[];
  private updateRestUrl: string;
  private type: string;
  private edited = false;

  isEdited() {
    return this.edited;
  }
  setEdited(edited: boolean): SaxMsSpreadsheetRowEntry {
    this.edited = edited;
    return this;
  }
  setValue(value: any): SaxMsSpreadsheetRowEntry {
    this.value = value;
    return this;
  }
  setIndex(index: number): SaxMsSpreadsheetRowEntry {
    this.index = index;
    return this;
  }
  setUpdateRestUrl(updateRestUrl: string): SaxMsSpreadsheetRowEntry {
    this.updateRestUrl = updateRestUrl;
    return this;
  }
  setType(type: string): SaxMsSpreadsheetRowEntry {
    this.type = type;
    return this;
  }
  getValue(): any {
    return this.value;
  }
  getIndex(): number {
    return this.index;
  }
  getId(): string {
    return this.id;
  }
  getUuid(): string {
    return this.uuid;
  }
  setUuid(uuid: string): SaxMsSpreadsheetRowEntry {
    this.uuid = uuid;
    return this;
  }
  setColor(color: string): SaxMsSpreadsheetRowEntry {
    this.color = color;
    return this;
  }
  getColor(): string {
    return this.color;
  }
  getUpdateRestUrl(): string {
    return this.updateRestUrl;
  }
  getType(): string {
    return this.type;
  }
  getTooltip(): string {
    return this.tooltip;
  }
  getDefaultColorClass(): string {
    return this.defaultColorClass;
  }
  setDefaultColorClass(defaultColorClass: string): SaxMsSpreadsheetRowEntry {
    this.defaultColorClass = defaultColorClass;
    return this;
  }
  getAvailableValues(): any[] {
    return this.availableValues;
  }
}
