import { IndexCardConfig } from '../index-card-config';
import { IndexCardExecuterBuildData } from '../index-card-executer';
import { IndexCardTextBuilder } from '../text-creator';

/**
 * Default TextOverlay building strategie.
 */
export class IndexCardTextDefault {
  public init(): void {
    // nothing
  }

  public build(
    dataSet: { [id: string]: string[] },
    buildData: IndexCardExecuterBuildData,
    config: IndexCardConfig,
    executer: IndexCardTextBuilder
  ): void {
    const s = executer;
    const ctx = s.indexCardExecuter.getCanvas(s.scrollContainerId).node().getContext('2d');
    const ctxFontConfig = `${config.fontSize}px Arial`;
    if (ctx.font !== ctxFontConfig) ctx.font = ctxFontConfig;
    const ellipsis = '…';
    const ellipsisWidth = s.fontSizeCalc.getTextWidth(ellipsis);
    const textContainerWidth = s.ganttDiagram
      .getNodeProportionsState()
      .getShiftViewPortProportions(executer.scrollContainerId).width;

    if (!buildData.transform) {
      buildData.transform = { k: 1, x: 0, y: 0 };
    }

    for (const textParent of buildData.shifts) {
      const lines = s.getMatchingLineSet(dataSet, textParent);
      const x = textParent.x * buildData.transform.k + buildData.transform.x + config.padding_left;
      const containerWidth = this._getContainerWidthByLineSet(lines, executer);
      const shiftWidth = textParent.width * buildData.transform.k - config.padding_left;
      const maxTextWidth = s.getWidthToNextShift(buildData, textParent, textContainerWidth, config);

      if (containerWidth > shiftWidth) {
        continue;
      }
      for (let i = 0; i < lines.length; i++) {
        const line = lines[i];
        const y =
          executer.ganttDiagram.getRenderDataHandler().getStateStorage().getYPositionShift(textParent.id) +
          config.padding_top +
          config.fontSize +
          config.lineHeight * i +
          config.lineHeight -
          s.ganttDiagram.getNodeProportionsState().getScrollTopPosition(executer.scrollContainerId);
        const wrappedText =
          maxTextWidth === undefined ? line.text : s.fittingString(line.text, maxTextWidth, ellipsis, ellipsisWidth);
        if (wrappedText) {
          ctx.fillText(wrappedText, x, y);
        }
      }
    }
  }

  /**
   * Calculates the width of the container by its text content.
   * @param lineSet Set of text lines
   * @param executer
   * @returns width of text container
   */
  private _getContainerWidthByLineSet(lineSet: { text: string; id: string }[], executer: IndexCardTextBuilder): number {
    const s = executer;
    let width = 0;
    lineSet.forEach((line) => (width = Math.max(width, s.fontSizeCalc.getTextWidth(line.text))));
    return width;
  }

  public cleanUp(): void {
    // nothing
  }
}
