import { ALink } from './link';
import { ELinkType } from './link-type.enum';

export class MaxDurationWarningLink extends ALink {
  private duration: number;

  public getDuration(): number {
    return this.duration;
  }

  public setDuration(duration: number): this {
    this.duration = duration;
    return this;
  }

  constructor(startId: string = null, endId: string = null, duration: number = null) {
    super();
    this.setType(ELinkType.MAX_DURATION_WARNING);
    if (startId) {
      this.setStartId(startId);
    }
    if (endId) {
      this.setEndId(endId);
    }
    if (duration) {
      this.setDuration(duration);
    }
  }

  public isValid(startTime: number, endTime: number): boolean {
    return endTime - startTime <= this.duration;
  }
}
