/**
 * Class that represents a button on the top of y axis.
 * @keywords button, y axis, yaxis, top, headline, container, click
 * @param {string} [id=null] Id of button which will be saved inside data.
 */
export class GanttYAxisHeadButtonData {
  private _id: string;
  private _states: IGanttYAxisHeadButtonState[];

  constructor(id: string = null) {
    this._id = id ? id : 'button_id_' + new Date().getTime();
    this._states = [];
  }

  /**
   * Adds a new button state.
   * @param className Class name of the button in this state.
   * @param callback Function which will be executed when the button was pressed.
   * @param content Content of the button in this state.
   * @param tooltip Tooltip of the button in this state.
   */
  public addState(className: string, callback: () => void, content: string, tooltip: string) {
    this._states.push({
      className: className,
      callback: callback,
      content: content,
      tooltip: tooltip,
    });
  }

  /**
   * Executes the callback function of the specified button state.
   * @param state Index of the state containing the callback.
   */
  public callback(state: number): void {
    if (this.getStateCount() > state) this._states[state].callback();
  }

  /**
   * Method being called when the button gets removed from y axis head.
   * This method only exists to get overwritten by child classes.
   */
  public destroy(): void {}

  //
  // GETTER & SETTER
  //
  public getId(): string {
    return this._id;
  }
  public getStateCount(): number {
    return this._states.length;
  }

  public getClassName(state: number): string {
    return this.getStateCount() > state ? this._states[state].className : null;
  }
  public getContent(state: number): string {
    return this.getStateCount() > state ? this._states[state].content : null;
  }
  public getTooltipText(state: number): string {
    return this.getStateCount() > state ? this._states[state].tooltip : null;
  }
}

export interface IGanttYAxisHeadButtonState {
  className: string;
  callback: () => void;
  content: string;
  tooltip: string;
}
