import { EntryElement } from '@app-modeleditor/components/entry-collection/entry-element';
import { EntryElementValue } from '@app-modeleditor/components/entry-collection/entry-element-value';
import { EFieldType } from '@app-modeleditor/components/entry-collection/field-type.enum';
import { Gantt_General } from '../../general.gantt.component';
import { GanttEssentialPlugIns } from '../../plugin/e-gantt-essential-plugins';
import { GanttXAxisManipulatorPlugIn } from '../../plugin/plugin-list/time-scaling/xaxis-manipulator';

/**
 * Handles switching whether to scroll at a time or to scroll at the time and zoom the selection.
 */
export class ToggleZoomToTimeSelectionElement extends EntryElement {
  constructor(private scope: Gantt_General) {
    super();
  }

  get(data: any): this {
    const initEnabled = (data.entry?.value as boolean) || true;
    const name = (data.name as string) || this.scope.translate.instant('@customize-zoom@');

    this.setFieldType(EFieldType.SLIDE_TOGGLE)
      .setAlwaysEnabled(true)
      .setName(name)
      .setId(data.id)
      .setValue(new EntryElementValue().setValue(initEnabled))
      .onChanges((change) => this.setIsZoomForTimePointScrollEnabled(change.value));

    this.setIsZoomForTimePointScrollEnabled(initEnabled);

    return this;
  }

  private setIsZoomForTimePointScrollEnabled(value: boolean) {
    const timeManipulator: GanttXAxisManipulatorPlugIn = this.scope.ganttPluginHandlerService.getEssentialPlugIn(
      GanttEssentialPlugIns.XAxisManipulatorPlugIn
    );
    if (timeManipulator) {
      timeManipulator.setIsZoomForTimePointScrollEnabled(value);
    }
  }
}
