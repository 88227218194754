import * as d3 from 'd3';
import { GanttCanvasShift } from '../../data-handler/data-structure/data-structure';
import { BestGantt } from '../../main';
import { ETimeGradationTimeGrid, TimeGradationHandler } from '../../time-gradation-handler/time-gradation-handler';
import { ETimeMarkerAnchor } from '../../x-axis/x-axis';

export class ShiftSplitByClick {
  private _reactionArea: d3.Selection<SVGGElement, undefined, d3.BaseType, undefined> = undefined;
  private _cover: d3.Selection<SVGRectElement, undefined, d3.BaseType, undefined> = undefined;
  private _marker: d3.Selection<SVGPathElement, undefined, d3.BaseType, undefined> = undefined;

  private _timeGradiation: TimeGradationHandler = undefined;

  private _joinBool = false;
  private _mouseAlignToGridCoordinate: [number, number] = undefined;
  private _showMarker = true;
  private _shiftMarkerColor = 'red';
  private _shiftMarkerWidth = 2;
  private _grid: ETimeGradationTimeGrid = undefined;
  private _active = false;
  private _additionalData: { [key: string]: unknown } = {};

  private _onSplitCb: { [id: string]: (inputData: ShiftSplitByClickData, joinBool: boolean) => void } = {};

  constructor(
    private _ganttDiagram: BestGantt,
    private _parentNode: d3.Selection<SVGGElement, undefined, d3.BaseType, undefined>
  ) {
    this._timeGradiation = new TimeGradationHandler(this._ganttDiagram.getXAxisBuilder());
  }

  /**
   * creates a reaction-area that triggers the mouseMoveHandler
   * @param joinBool true == chain shifts together
   * @param grid Can be "minute", "hour", "day" or "customized"
   */
  public startSplitByClickMode(joinBool: boolean, grid: ETimeGradationTimeGrid = undefined): void {
    if (grid) this._grid = grid;
    this._joinBool = joinBool;

    if (this._active) return;
    this._active = true;

    this._reactionArea = this._parentNode.append('g').attr('class', 'gantt-split-by-click-group');

    // build reactionArea
    this._cover = this._reactionArea
      .append('rect') // build a reactionArea
      .attr('height', this._parentNode.attr('height'))
      .attr('width', this._parentNode.attr('width'))
      .style('opacity', '0')
      .style('pointer-events', 'auto')
      .on('mousemove', (event) => {
        this._mouseMoveHandler(d3.pointer(event));
      })
      .on('click', (event) => {
        // if user clicks next to marker
        const mouseAlignToGrid = this._aliginToGrid(d3.pointer(event), this._grid);
        const shift = this._checkForShift(d3.pointer(event));
        const alignShift = this._checkForShift(mouseAlignToGrid);
        if (shift) {
          if (shift != alignShift) return; // if shifts are not the same
          this._mouseClickHandler(mouseAlignToGrid, shift);
        }
      });
  }

  /**
   * handles the functions on mouse-move-event
   * @param mouse mouse-coordinates
   */
  private _mouseMoveHandler(mouse: [number, number]): void {
    this._mouseAlignToGridCoordinate = this._aliginToGrid(mouse, this._grid);

    if (this._showMarker) {
      this._ganttDiagram.getXAxisBuilder().removeAllDateMarkers(); // remove timemarker
      this._ganttDiagram
        .getXAxisBuilder()
        .addMarkerByPoints(this._mouseAlignToGridCoordinate[0], ETimeMarkerAnchor.END); // draw timemarker
    }

    const shift = this._checkForShift(mouse);
    const markerInShift = this._checkForShift(this._mouseAlignToGridCoordinate);

    if (shift && markerInShift) {
      // if mouse hit a shift
      if (shift != markerInShift) return; // if shifts are not the same
      this._removeMarkerOnShift();
      this._buildMarkerOnShift(this._mouseAlignToGridCoordinate, shift);
    } else {
      this._removeMarkerOnShift();
    }
  }

  /**
   * this function checks if mouse hits a shift and returns it
   * @param mouse mouse-coordinates
   */
  private _checkForShift(mouse: [number, number]): GanttCanvasShift {
    const zoomTransformation = this._ganttDiagram.getShiftFacade().getShiftBuilder().getLastZoomTransformation();

    const shiftDataSet = this._ganttDiagram.getDataHandler().getCanvasShiftDataset();

    const realMouseX = (mouse[0] - zoomTransformation.x) / zoomTransformation.k;
    const mouseY = mouse[1];

    for (let i = 0; i < shiftDataSet.length; i++) {
      const shiftY = this._ganttDiagram
        .getRenderDataHandler()
        .getShiftDataFinder()
        .getShiftViewportY(shiftDataSet[i].id);
      if (
        realMouseX >= shiftDataSet[i].x &&
        realMouseX <= shiftDataSet[i].x + shiftDataSet[i].width && // check horizontal-line
        mouseY >= shiftY &&
        mouseY <= shiftY + shiftDataSet[i].height // check vertical-line
      ) {
        return shiftDataSet[i];
      }
    }
  }

  /**
   * this function builds a marker-line on a shift
   * @param coordinates mouse-coordinates
   * @param shift canvas-shift-data
   */
  private _buildMarkerOnShift(coordinates: [number, number], shift: GanttCanvasShift): void {
    const shiftY = this._ganttDiagram.getRenderDataHandler().getShiftDataFinder().getShiftViewportY(shift.id);
    const shiftHeight = shift.height;

    const scrollContainerId = this._ganttDiagram
      .getRenderDataHandler()
      .getStateStorage()
      .getShiftScrollContainer(shift.id);
    const clipPathUrl = this._ganttDiagram
      .getShiftFacade()
      .getShiftBuilder(scrollContainerId)
      .getClipPathHandler().clipPathUrl;

    // build marker
    this._marker = this._reactionArea
      .append<SVGPathElement>('path')
      .attr('class', 'gantt-shift-split-by-click-marker')
      .attr('d', 'M' + coordinates[0] + ' ' + shiftY + ' L' + coordinates[0] + ' ' + (shiftY + shiftHeight))
      .attr('clip-path', clipPathUrl)
      .style('stroke', this._shiftMarkerColor)
      .style('stroke-width', this._shiftMarkerWidth)
      .style('opacity', this._showMarker ? 1 : 0)
      .on('click', () => {
        // if user clicks on marker
        this._mouseClickHandler(this._mouseAlignToGridCoordinate, shift);
      });
  }

  /**
   * handles the execution of functions of mouse-click-event
   * @param mouse mouse-coordinates
   * @param shift canvas-shift-data
   */
  private _mouseClickHandler(mouse: [number, number], shift: GanttCanvasShift): void {
    const inputData = this._generateNewInputData(mouse, shift);
    for (const func in this._onSplitCb) {
      // execute functions in callback
      this._onSplitCb[func](inputData, this._joinBool);
    }
  }

  /**
   * remove shift-marker-line
   */
  private _removeMarkerOnShift(): void {
    d3.selectAll('.gantt-shift-split-by-click-marker').remove();
  }

  /**
   * this function generates a input-data to call the "setInputDataToSplitByProportion"-function
   * @param clickCoordinates coordinates of mouseclick
   * @param shift shift-canvas-data
   */
  private _generateNewInputData(clickCoordinates: [number, number], shift: GanttCanvasShift): ShiftSplitByClickData {
    const scale = this._ganttDiagram.getXAxisBuilder().getCurrentScale();

    const shiftProportions = this._calculateShiftProportions(clickCoordinates, shift), // get proportions
      shiftId = shift.id,
      color1 = shift.color,
      color2 = this._lightenDarkenColor(color1, 10),
      name = shift.name,
      tooltip = shift.tooltip;

    // create data
    const data1 = { color: color1, proportion: shiftProportions[0], name: name, idSuffix: 'piece1', tooltip: tooltip };
    const data2 = { color: color2, proportion: shiftProportions[1], name: name, idSuffix: 'piece2', tooltip: tooltip };

    return new ShiftSplitByClickData(
      shiftId,
      [data1, data2],
      this._ganttDiagram.getXAxisBuilder().pxToTime(clickCoordinates[0], scale)
    );
  }

  /**
   * this function calculates the shiftProportions to split the shift in two pieces and returns an array of two proportions
   * @param clickCoordinates coordinates of mouseclick
   * @param shift shift-canvas-data
   */
  private _calculateShiftProportions(clickCoordinates: [number, number], shift: GanttCanvasShift): [number, number] {
    const zoomTransformation = this._ganttDiagram.getShiftFacade().getShiftBuilder().getLastZoomTransformation();
    const realMouseX = (clickCoordinates[0] - zoomTransformation.x) / zoomTransformation.k; // calculate the untransformed coordinates of click-coordinates

    // calculate
    const clickOnShift = realMouseX - shift.x;
    const proportion1 = clickOnShift / shift.width;
    const proportion2 = (shift.width - clickOnShift) / shift.width;

    return [proportion1, proportion2];
  }

  /**
   * this function terminates the shift-split-mode
   */
  public exitShiftSplitMode(): void {
    this._active = false;
    this._joinBool = false;
    this._ganttDiagram.getXAxisBuilder().removeAllDateMarkers();
    if (!this._reactionArea) return;
    this._reactionArea.remove();
  }

  /**
   * this function gets a hex-color and returns it brighter or darker
   * col must be a hex color!
   * @param col hex-color
   * @param amt change
   */
  private _lightenDarkenColor(col: string, amt: number): string {
    let usePound = false;

    if (col[0] == '#') {
      col = col.slice(1);
      usePound = true;
    }

    const num = parseInt(col, 16);
    let r = (num >> 16) + amt;

    if (r > 255) r = 255;
    else if (r < 0) r = 0;

    let b = ((num >> 8) & 0x00ff) + amt;

    if (b > 255) b = 255;
    else if (b < 0) b = 0;

    let g = (num & 0x0000ff) + amt;

    if (g > 255) g = 255;
    else if (g < 0) g = 0;

    return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
  }

  /**
   * this function converts the mouse-coordinates to grid and returns the fitted coordinates
   * @param mouse mouse-coordinates
   * @param screenRuling optional parameter can be "minute", "hour", "day" or "customized"
   */
  private _aliginToGrid(mouse: [number, number], screenRuling: ETimeGradationTimeGrid): [number, number] {
    const zoomTransformation = this._ganttDiagram.getShiftFacade().getShiftBuilder().getLastZoomTransformation();
    const timePoint = this._ganttDiagram.xAxisBuilder.pxToTime(
      (mouse[0] - zoomTransformation.x) / zoomTransformation.k,
      this._ganttDiagram.xAxisBuilder.getGlobalScale()
    );
    const newX = this._timeGradiation.getAliginXCoordinateByDate(timePoint, screenRuling);
    return [newX, mouse[1]];
  }

  /**
   * @param grid Grid for mouse movement for pointer.
   * @return True if split mode has been activated.
   */
  public toggleSplitModeByClick(grid: ETimeGradationTimeGrid): boolean {
    if (!this._active) this.startSplitByClickMode(false, grid || this._grid);
    else this.exitShiftSplitMode();
    return this._active;
  }

  //
  // CALLBACK
  //

  public addOnSplitCallback(id: string, func: (inputData: ShiftSplitByClickData, joinBool: boolean) => void): void {
    this._onSplitCb[id] = func;
  }

  public removeOnSplitCallback(id: string): void {
    delete this._onSplitCb[id];
  }

  //
  // GETTER & SETTER
  //

  public isActive(): boolean {
    return this._active;
  }

  /**
   * Stores any data inside. Helpful for dashboard integration.
   * @param additionalData Dataset which should be stored inside shift splitter.
   */
  public setAdditionalData(additionalData: { [key: string]: unknown }): void {
    this._additionalData = additionalData;
  }

  /**
   * Access to additional data.
   * @return Dataset which has been stored inside.
   */
  public getAdditionalData(): { [key: string]: unknown } {
    return this._additionalData;
  }

  /**
   * Set visibility of marker.
   * @param show
   */
  public setShowMarker(show: boolean): void {
    this._showMarker = show;
  }

  /**
   * @param startDate
   * @param stepSize In Milliseconds.
   */
  public setCustomizedTimeGrid(startDate: Date, stepSize: number): void {
    this._timeGradiation.setCustomizedTimeGrid(startDate, stepSize);
  }
}

export class ShiftSplitByClickData {
  constructor(public shiftID: string, public changeDefinition: IShiftSplitByClickShiftPart[], public cutDate: Date) {}
}

interface IShiftSplitByClickShiftPart {
  color: string;
  proportion: number;
  name: string;
  idSuffix: string;
  tooltip: string;
}
