import { Component, Input, ViewChild } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { ConfigService } from '@core/config/config.service';
import { IconConfig } from '../../../core/config/icon-config';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
  providers: [],
})
export class ImageComponent {
  label: string;
  @Input('label') set _label(label: string) {
    this.label = label;
    this.image = this.configApi.getIcon(label);
  }
  badgeCount: string;
  @Input() disabled: boolean;
  @Input('badge') set _badgeCount(badgeCount: string) {
    if (!badgeCount || badgeCount === '') {
      this.badgeCount = null;
      return;
    }
    const b: number = parseInt(badgeCount);

    if (isNaN(b)) {
      return;
    }

    if (b > 9) {
      this.badgeCount = '+9';
      return;
    }

    this.badgeCount = badgeCount;
    return;
  }
  image: IconConfig;
  @Input() color: string;
  @ViewChild('iconRef') set iconRef(ref: MatIcon) {
    if (!ref) {
      return;
    }
    ref._elementRef.nativeElement.style.fill = `${this.color} !important`;
    ref._elementRef.nativeElement.classList.add(`image-${this.label}`);
  }

  constructor(private configApi: ConfigService) {}
}
