import { EGanttInstance, GanttDataRow, GanttDataShift, GanttInstanceService } from '@gantt/public-api';

/**
 * Static mapper for GanttOverlappingShiftsPlugIn.
 * Will be used if weighted sorting strategy is selected to extract
 * all existing attribute values of attribute which should be weighted.
 */
export const GanttOverlappingShiftsPlugInMapper = {
  /**
   * Bundles all other mapping functions to get alist of priorised attributes and values.
   * @param ganttOriginData List of rows which is the database for the extraction of the priorised attributes.
   * @param backendBlockSortingData List of eighted attributes which will be provided by backend.
   * @param additionalBlockProperties Additional data inside blocks which will be used to extract attributes for weighting block sort.
   */
  getBlockSortings: function (
    ganttOriginData: GanttDataRow[],
    backendBlockSortingData: any[],
    JSFactory: GanttInstanceService,
    additionalBlockProperties?: string[]
  ): IPrioritisedAttribute[] {
    if (!backendBlockSortingData) return [];
    try {
      const allSplitAttributes: string[][] = [];
      for (const sortingItem of backendBlockSortingData) {
        allSplitAttributes.push(
          additionalBlockProperties ? additionalBlockProperties.concat(sortingItem.split('.')) : sortingItem.split('.')
        );
      }
      return GanttOverlappingShiftsPlugInMapper.extractAllValuesOfProperties(
        ganttOriginData,
        allSplitAttributes,
        JSFactory
      );
    } catch (e) {
      console.warn('Error while trying to map shift overlapping attributes: ' + e);
      return [];
    }
  },
  /**
   * Extracts priorised attributes from block of given rows.
   * @param ganttOriginData Affected rows.
   * @param shiftBlockPropertiesList List of weighted attributes.
   */
  extractAllValuesOfProperties: function (
    ganttOriginData: GanttDataRow[],
    shiftBlockPropertiesList: string[][],
    JSFactory: GanttInstanceService
  ): IPrioritisedAttribute[] {
    const priorityLists: IPrioritisedAttribute[][] = [];
    for (let i = 0; i < shiftBlockPropertiesList.length; i++) {
      priorityLists.push([]);
    }
    JSFactory.getInstance(EGanttInstance.DATA_MANIPULATOR).iterateOverDataSet(ganttOriginData, {
      extractValues: (child) => {
        if (!child.shifts) return;
        for (const shift of child.shifts) {
          let index = 0;
          for (const shiftBlockProp of shiftBlockPropertiesList) {
            const foundShiftValue = GanttOverlappingShiftsPlugInMapper.getShiftValue(shift, shiftBlockProp);
            if (foundShiftValue != null) {
              priorityLists[index].push({
                propertyList: shiftBlockProp,
                value: foundShiftValue,
              });
            }
            index++;
          }
        }
      },
    });
    const sortedPriorityLists: IPrioritisedAttribute[][] = [];
    for (const priorityList of priorityLists) {
      // sort
      priorityList.sort((a, b) => {
        const lowerA = a.value.toLowerCase();
        const lowerB = b.value.toLowerCase();
        return lowerA < lowerB ? -1 : lowerA > lowerB ? 1 : 0;
      });
      // remove duplicates
      const sortedPriorityList = priorityList.filter((entry, i) => {
        return i == 0 || priorityList[i - 1].value != entry.value;
      });
      sortedPriorityLists.push(sortedPriorityList);
    }
    return [].concat(...sortedPriorityLists);
  },
  /**
   * Extracts block attribute value by given probertyList, which will be executed iterative.
   * @param shiftBlockData Block of which all data will be extracted.
   * @param probertyList List of properties, which will be called layer by layer.
   */
  getShiftValue: function (shiftBlockData: GanttDataShift, probertyList: string[]): string {
    let foundValue = shiftBlockData[probertyList[0]];
    if (foundValue == null) return null;
    for (let i = 1; i < probertyList.length; i++) {
      let anyProp: any = probertyList[i];
      if (anyProp.match(/^[0-9]+$/) != null) {
        anyProp = parseInt(anyProp);
      }
      if (foundValue[anyProp] == null) return null;
      foundValue = foundValue[anyProp];
    }
    return foundValue;
  },
};

/**
 * Structure to store priorised attributes.
 */
export interface IPrioritisedAttribute {
  propertyList: string[];
  value: string;
}
