<mat-progress-bar *ngIf="isLoading" [color]="'primary'" mode="indeterminate"></mat-progress-bar>
<div class="headline">
  <button mat-icon-button (click)="prev()" [disabled]="getNavigationBtnState('prev')">
    <mat-icon>keyboard_arrow_left</mat-icon>
  </button>
  <button mat-icon-button (click)="next()" [disabled]="getNavigationBtnState('next')">
    <mat-icon>keyboard_arrow_right</mat-icon>
  </button>
  <ng-container *ngIf="currentDate && range">
    <input
      matInput
      class="invisible-date-input"
      [min]="range.start"
      [max]="range.end"
      [matDatepicker]="picker"
      disabled
    />
    <button mat-button (click)="handleCurrentDateBtn()">
      {{ ('DATES.monthNames' | translate)[currentDate.getMonth()] }},
      {{ currentDate.getFullYear() }}
    </button>
    <mat-datepicker
      #picker
      disabled="false"
      startView="multi-year"
      [startAt]="currentDate"
      (monthSelected)="handleMonthChangeByDatepicker($event, picker)"
    ></mat-datepicker>
  </ng-container>
</div>

<full-calendar
  [options]="calendarOptions"
  (contextmenu)="handleContextmenu($event)"
  (dateClick)="dateClicked($event)"
  (drop)="updateEvent($event)"
  (eventDragStop)="eventDragStop($event)"
  #calendarRef
>
</full-calendar>
