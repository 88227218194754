import { Directive, ElementRef, Input, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { takeWhile } from 'rxjs/operators';
import { PasswordInputToggleButton } from './password-input-toggle-button.component';

/**
 * This directive turns a normal input into a password input showing points instead of readable text.
 * Furthermore, it adds a clickable icon behind the input to toggle between password and readable mode.
 */
@Directive({
  selector: '[passwordInput]',
  standalone: true,
})
export class PasswordInput implements OnInit, OnDestroy {
  @Input() passwordInput = true;

  private alive = true;

  constructor(private elementRef: ElementRef<HTMLInputElement>, private viewContainerRef: ViewContainerRef) {}

  private updateInputType(showPassword: boolean) {
    this.elementRef.nativeElement.type = showPassword ? 'text' : 'password';
  }

  ngOnInit(): void {
    if (!this.passwordInput) return;
    this.updateInputType(false);
    this.elementRef.nativeElement.autocomplete = 'new-password'; // disable browser password autofill

    const toggleButton = this.viewContainerRef.createComponent(PasswordInputToggleButton);
    this.toggleButtonContainer.appendChild(toggleButton.location.nativeElement);
    toggleButton.instance.showPasswordChange$
      .pipe(takeWhile(() => this.alive))
      .subscribe((showPassword) => this.updateInputType(showPassword));
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  private get toggleButtonContainer() {
    return this.elementRef.nativeElement.parentNode.parentNode;
  }
}
