import { Action } from '@app-modeleditor/components/button/action/action';
import { Button } from '@app-modeleditor/components/button/button';
import { EButtonDisplayType } from '@app-modeleditor/components/button/button-display-type.enum';
import { EEntryElementPosition } from '@app-modeleditor/components/entry-collection/entry-element-position.enum';
import { Observable, of } from 'rxjs';
import { Gantt_General } from '../../general.gantt.component';
import { IGanttAttributeData } from '../../generator/gantt-input.data';
import { EditTooltipLightbox } from './edit-tooltip-lightbox';

export class EditTooltipButton extends Button {
  private lightBox: EditTooltipLightbox;
  private attributeMapping: IGanttAttributeData[];
  private attributesOrderIds: string[];
  constructor(private scope: Gantt_General) {
    super();
  }

  get(): this {
    this.attributeMapping = Object.values(this.scope.ganttTemplateDataService.getTemplateData().getAttributeMapping());
    this.setName('@customize-tooltip@')
      .setAlwaysEnabled(true)
      .setIcon('EDIT_TOOLTIP')
      .setDisplayType(EButtonDisplayType.ICON_WITH_LABEL_BELOW)
      .chainActions(new Action().setCb(() => this.openTooltipLightBox()));
    return this;
  }

  private openTooltipLightBox(): Observable<any> {
    this.lightBox = new EditTooltipLightbox().get(
      this.attributeMapping,
      this.scope.ganttTemplateDataService.getTemplateData().getDefaultBlockTooltipSettings()
    );

    // set action for applying changes of selection
    this.lightBox.setOnCustomSaveAction(
      new Button()
        .setPosition(EEntryElementPosition.RIGHT)
        .setName('@save@')
        .chainActions(
          new Action().setCb(() => {
            const selectedElements = this.lightBox.getColumnListSelector().getValue().getSelectedListEntryElements();
            const visibleAttributes = {
              attributeMetadata: selectedElements.map((elem, index) => {
                return {
                  attributeID: parseInt(elem.getId()),
                  index: index,
                  readableClassName: 'Attribute',
                };
              }),
              readableClassName: 'Tooltips',
            };
            this.scope.ganttTooltipService.saveBlockTooltipSettingsToBackend(visibleAttributes);
            return of(this.lightBox.getRef().close(this.attributesOrderIds));
          })
        )
    );

    this.scope.lightboxApi.open(this.lightBox);
    return of(null);
  }
}
