import { Component, Input, OnInit } from '@angular/core';
import { StatusIcon } from './../status-icon';

@Component({
  selector: 'template-statebar-icon',
  templateUrl: './template-statebar-icon.component.html',
  styleUrls: ['./template-statebar-icon.component.scss'],
})
export class TemplateStatebarIconComponent implements OnInit {
  @Input() element: StatusIcon;

  constructor() {}

  ngOnInit() {}
}
