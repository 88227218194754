import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressDialogComponent } from './progress-dialog.component';
import { FileSizePipe } from './file-size.pipe';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { FileProgressModule } from '@app-modeleditor/components/file-progress/file-progress.module';
import { MatDividerModule } from '@angular/material/divider';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatProgressBarModule,
    FileProgressModule,
    MatDividerModule,
    ScrollingModule,
    MatProgressSpinnerModule,
    TranslateModule,
  ],
  declarations: [ProgressDialogComponent],
  exports: [ProgressDialogComponent],
})
export class ProgressDialogModule {}
