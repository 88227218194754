import { ButtonService } from '@app-modeleditor/components/button/button.service';
import { LightboxService } from '@app-modeleditor/components/lightbox/lightbox.service';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Observable, of, throwError } from 'rxjs';
import { catchError, delay, finalize } from 'rxjs/operators';
import { GanttActionScopes, GanttActionWrapper } from '../gantt-actions/external-action-registration';
import { GanttEditService } from '../gantt-edit/gantt-edit.service';
import { GanttLoadingSpinner } from '../general.gantt.component';
import { GanttPluginHandlerService } from '../plugin/gantt-plugin-handler.service';
import { GanttToolbar } from '../toolbar/gantt-toolbar';
import { GanttGlobalSendToBackendAction } from './send-to-backend/send-to-backend.action';

/**
 * Mediator for generalized gantt.
 * Handles local and global action handling
 * inside which holds all plugin data.
 */
export class GeneralGanttActionHandler {
  private backendActionHandler: GanttGlobalSendToBackendAction;
  private _newToolbar: GanttToolbar;
  private alive = true;

  constructor(
    public buttonService: ButtonService,
    public lightboxApi: LightboxService,
    public translate: TranslateService,
    private _loadingSpinner: GanttLoadingSpinner,
    private _ganttPluginHandlerService: GanttPluginHandlerService,
    private ganttEditService: GanttEditService
  ) {
    this.backendActionHandler = new GanttGlobalSendToBackendAction(this.buttonService);
  }

  public isAlive(): boolean {
    return this.alive ? true : false;
  }

  /**
   * Central function to execute global and local actions.
   * Uses deactivation handler to avoid user interaction during server request.
   * Notifies all plugins via plugin handler.
   * @param ganttActionWrapper
   */
  public executeActions(ganttActionWrapper: GanttActionWrapper): Observable<any[]> {
    if (!ganttActionWrapper.actions) return of(null);
    this.showLoadingspinner(true);
    const editedBlockIds = ganttActionWrapper.editedBlockIds || [];

    this.ganttEditService.deactivateGanttShiftBlockEdit(editedBlockIds);
    if (ganttActionWrapper.actionScope == GanttActionScopes.LOCAL) {
      const plugInActions: any[] = ganttActionWrapper.actions.filter((action) => action.pluginId);
      const ganttActions: any[] = ganttActionWrapper.actions.filter((action) => !action.pluginId);
      // check if action is inside plugin
      return forkJoin(
        ...plugInActions.map((action) => this._ganttPluginHandlerService.executePlugInAction(action)),
        ...ganttActions.map((action) => this._ganttPluginHandlerService.executeGanttAction(action))
      ).pipe(
        finalize(() => {
          this.ganttEditService.activateGanttShiftBlockEdit(editedBlockIds);
          this.showLoadingspinner(false);
        })
      );
    }
    if (ganttActionWrapper.actionScope == GanttActionScopes.GLOBAL) {
      return this.backendActionHandler
        .execute(ganttActionWrapper.actions)
        .pipe(
          catchError((error) => {
            return throwError(error);
          })
        )
        .pipe(
          finalize(() => {
            this.ganttEditService.activateGanttShiftBlockEdit(editedBlockIds);
            this.showLoadingspinner(false);
          })
        );
    }
    /* this.ganttEditService.activateGanttShiftBlockEdit();
    this.showLoadingspinner(false); */
    return of(null);
  }

  /**
   * Shows loading spinner inside gantt menu to show user if there is a serverrequest which has no response.
   * @param show If true, loading spinner will be shown.
   */
  public showLoadingspinner(show: boolean): void {
    of(null)
      .pipe(delay(0))
      .subscribe(() => {
        if (this._loadingSpinner) this._loadingSpinner.show = show;
      });
  }

  onDestroy() {
    this.alive = false;
    this._ganttPluginHandlerService.ngOnDestroy();
  }

  get newToolbar(): GanttToolbar {
    return this._newToolbar || null;
  }

  set newToolbar(toolbar: GanttToolbar) {
    this._newToolbar = toolbar;
  }
}
