<div class="toolbar">
  <div class="actionContainer">
    <div class="actionElement">
      <div class="alignContent">
        <section>
          <mat-checkbox
            (change)="toggleVisibility()"
            [(ngModel)]="legendCommunicationService.isVisibilityChecked"
            [indeterminate]="legendCommunicationService.isVisibilityIndeterminateShow"
            labelPosition="after"
          >
            {{ 'GANTT.LEGEND.show_all' | translate }}</mat-checkbox
          >
        </section>
      </div>
      <div class="alignContent">
        <section>
          <mat-slide-toggle
            [(ngModel)]="legendCommunicationService.hideFilteredOutElements"
            (change)="onDisplayOptionChange()"
          >
            {{ 'GANTT.LEGEND.hide_elements' | translate }}
          </mat-slide-toggle>
        </section>
      </div>
    </div>
    <div class="actionElement">
      <div class="alignContent">
        <mat-form-field>
          <mat-label>{{ '@attribute-search@' | translate }}</mat-label>
          <input
            matInput
            (keyup)="searchForEntry($event)"
            autocomplete="off"
            [(ngModel)]="legendCommunicationService.searchInput"
          />
          <mat-icon *ngIf="!legendCommunicationService.searchInput" matSuffix>search</mat-icon>
          <button
            mat-button
            *ngIf="legendCommunicationService.searchInput"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="clearSearch()"
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>
      <div class="alignContent">
        <section>
          <mat-slide-toggle
            (change)="toggleHideFilteredOutEntries($event)"
            [(ngModel)]="legendCommunicationService.hideSearchResults"
          >
            {{ 'GANTT.LEGEND.filter_search_result' | translate }}
          </mat-slide-toggle>
        </section>
      </div>
    </div>
    <div class="actionElement">
      <div class="alignContent">
        <section>
          <button mat-button class="toolbar-button" (click)="_toggleEntrySortingOrder()">
            <div class="saxms-icon" [ngSwitch]="legendCommunicationService.entrySortingOrder">
              <ng-container *ngSwitchCase="'DEFAULT'"> sort_by_alpha_custom_unsorted </ng-container>
              <ng-container *ngSwitchCase="'ASC'"> sort_by_alpha_custom_asc </ng-container>
              <ng-container *ngSwitchCase="'DESC'"> sort_by_alpha_custom_desc </ng-container>
            </div>
            <mat-label>
              {{ 'GANTT.LEGEND.SORT_ENTRIES.sortEntries' | translate }}
            </mat-label>
          </button>
        </section>
      </div>
    </div>
    <div class="actionElement">
      <div class="alignContent">
        <mat-radio-group
          class="colorizeRadioGroup"
          aria-label="Einfärbung"
          [(ngModel)]="legendCommunicationService.colorizeStrategyOption"
          (change)="onColorizeOptionChange()"
        >
          <mat-radio-button value="DEFAULT">{{ 'GANTT.LEGEND.color_statically' | translate }}</mat-radio-button>
          <mat-radio-button value="UNIQUE">{{ 'GANTT.LEGEND.color_individually' | translate }}</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
    <div class="actionElement">
      <div class="alignContent">
        <section>
          <mat-checkbox
            (change)="toggleShowOnlyVisibleElements()"
            [(ngModel)]="legendCommunicationService.showOnlyViewportEntries"
            labelPosition="after"
          >
            {{ 'GANTT.LEGEND.show_only_visible_elements' | translate }}</mat-checkbox
          >
        </section>
      </div>
    </div>
    <div class="actionElement">
      <div class="alignContent">
        <section>
          <mat-checkbox (change)="toggleIgnoreEditedEntries()"
            [(ngModel)]="legendCommunicationService.ignoreEditedEntries" labelPosition="after">
            {{'GANTT.LEGEND.ignore_edited_entries' | translate}}</mat-checkbox>
          <button mat-button class="toolbar-button space-left" (click)="resetIgnoredEntries()"
            [disabled]="!legendCommunicationService.ignoreEditedEntries"
            [saxmsTooltip]="'GANTT.LEGEND.refresh_filter_tooltip' | translate">
            <mat-icon>
              replay
            </mat-icon>
            <mat-label>
              {{ 'GANTT.LEGEND.refresh_filter' | translate }}
            </mat-label>
          </button>
        </section>
      </div>
    </div>
  </div>
</div>