import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ExperimentService2, IExperiment } from '../../data/experiment/experiment.service';

/**
 * Provides line chart data for diagrams inside node network diagram dialogs.
 */
@Injectable()
export class NodeNetworkDiagramService {
  public uri = 'rest/animation/diagram/';
  currentExperiment: IExperiment;
  constructor(public http: HttpClient, public experimentservice2: ExperimentService2) {
    this.experimentservice2
      .getCurrentExperiment()
      .subscribe((experiment: IExperiment) => (this.currentExperiment = experiment));
  }

  /**
   * Calls data for queue diagram by given node id.
   * @param nodeId Id of node.
   */
  public getQueueDiagram(nodeId: string): Observable<any> {
    const experimentid = this.currentExperiment.id;
    const resturl = this.uri + experimentid + '/queue/' + nodeId;

    return this.http.get(`` + resturl);
  }

  /**
   * Calls data for workload diagram by given node id.
   * @param nodeId Id of node.
   */
  public getWorkloadDiagram(nodeId: string): Observable<any> {
    const experimentid = this.currentExperiment.id;
    const resturl = this.uri + experimentid + '/workload/' + nodeId;

    return this.http.get(`` + resturl);
  }
}
