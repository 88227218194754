import { GanttCallBackStackExecuter } from '../../callback-tools/callback-stack-executer';
import { DataManipulator } from '../../data-handler/data-tools/data-manipulator';
import { GanttUtilities } from '../../gantt-utilities/gantt-utilities';
import { BestGanttPlugIn } from '../gantt-plug-in';
import { CompareGanttsAnalyse } from './additional-rows/compare-gantts-free-time';
import { CompareGanttsMapper } from './compare-gantts.mapper';
import { CompareGanttsEvent } from './undo-redo/compare-gantts-event';

/**
 * Plugin in which combines multiple gantt datasets row by row inside one gantt to compare them.
 * @keywords compare, gantt, gantts, data, differences, executer, rowwise
 * @plugin compare-gantts
 * @class
 * @constructor
 * @extends BestGanttPlugIn
 *
 * @requires BestGanttPlugIn
 * @requires CompareGanttsMapper
 * @requires GanttData
 *
 * @deprecated This plug-in is no longer used (2023-11-29).
 */
export class CompareGantts extends BestGanttPlugIn {
  ganttOriginalDataSet: any;
  compareDatasets: CompareGanttsDataItem[];
  differencesRow: boolean;
  freeTimesRow: boolean;
  uuid: string;
  addAdditionalDataRowLevel: number;
  callBack: any;

  constructor() {
    super(); //call super-constructor

    /**
     * @type {BestGantt}
     */
    this.ganttDiagram = null;
    this.ganttOriginalDataSet;
    this.compareDatasets = [];

    this.differencesRow = false;
    this.freeTimesRow = false;

    this.uuid = '_compare-' + GanttUtilities.generateUniqueID(11);

    this.addAdditionalDataRowLevel = 0;

    this.callBack = {
      afterCompareDatasetAdded: {},
    };
  }

  /**
   * @override
   */
  initPlugIn(ganttDiagram) {
    const s = this;
    s.ganttDiagram = ganttDiagram;
  }

  /**
   * Remove of plugin. Part of plugin lifecycle.
   */
  removePlugIn() {
    const s = this;

    s.reset();
  }

  /**
   * @param {GanttData} ganttDataSet Origin gantt dataset of compared gantt.
   * @param {string} id Unique save id of given gantt dataset.
   */
  addCompareDataSet(ganttDataSet, id) {
    const s = this;

    if (!id || !ganttDataSet) return;

    if (!s.ganttOriginalDataSet)
      s.ganttOriginalDataSet = JSON.parse(JSON.stringify(s.ganttDiagram.getDataHandler().getOriginDataset()));

    s.compareDatasets.push(new CompareGanttsDataItem(id, ganttDataSet));
  }

  /**
   * Compares multiple gantt datasets row wise by matching identical row ids.
   * @param {CompareGanttsDataItem[]} compareDatasets List of items which holds data for each gantt dataset to compare with origin gantt.
   * @param {GanttData} [ganttOriginDataSet] Dataset of gantt which will be compares with given datasets.
   * @param {boolean} [comparedShiftsNotEditable] Restrict if shifts of compared gantt dataset should be editable or not.
   */
  compareByRowId(compareDatasets, ganttOriginDataSet, comparedShiftsNotEditable) {
    const s = this;

    if (ganttOriginDataSet) s.ganttOriginalDataSet = ganttOriginDataSet;

    // take stored datasets for default
    if (!compareDatasets) compareDatasets = s.compareDatasets;

    // copy the current dataset to add comparison rows to it
    let finalDataset = JSON.parse(JSON.stringify(s.ganttOriginalDataSet));

    for (var i = 0; i < compareDatasets.length; i++) {
      var dataSet = compareDatasets[i].dataSet,
        dataSetId = compareDatasets[i].id,
        // make comparison data flat to aviod another recursive search
        dataList = s._flattenHierachy(JSON.parse(JSON.stringify(dataSet.ganttEntries)), true);

      var matchingRow;
      // add comparison row if it matches the row id
      function addToFinalDataset(row, level, parent, index) {
        matchingRow = dataList.find(function (rowData) {
          return rowData.id == row.id;
        });
        if (matchingRow) {
          row.group = row.child.length ? 'BASE-TREE' : 'BASE-LEAF';
          matchingRow = JSON.parse(JSON.stringify(matchingRow));
          matchingRow.id += s.uuid + dataSetId;
          matchingRow.name = '> ' + matchingRow.name;
          matchingRow.group = 'MEMBER';
          matchingRow.child = JSON.parse(JSON.stringify(row.child));

          // tag all shifts with unique ids
          for (let j = 0; j < matchingRow.shifts.length; j++) {
            const shift = matchingRow.shifts[j];
            shift.id += s.uuid + dataSetId;

            if (comparedShiftsNotEditable) s.blockShiftsTranslationByShiftId(shift.id);
          }

          if (level == 0) {
            finalDataset.ganttEntries.splice(index.index + 1 + i, 0, matchingRow);
            index.index += 1;
          } else if (parent) {
            parent.child.splice(index.index + 1 + i, 0, matchingRow);
            index.index += 1;
          }
        }
      }

      DataManipulator.iterateOverDataSet(finalDataset.ganttEntries, { addToFinalDataset: addToFinalDataset });
    }

    s._prepareRowGroupData(finalDataset);

    finalDataset = s.addAdditionalDataRow(finalDataset);

    s.ganttDiagram.getDataHandler().setOriginDataset(finalDataset);
    s.ganttDiagram.update();

    GanttCallBackStackExecuter.execute(s.callBack.afterCompareDatasetAdded, s.compareDatasets);
  }

  /**
   * Compares multiple gantt datasets row wise by matching row indexes of global ganttOriginalDataSet with compareDatasets.
   * @param {boolean} [comparedShiftsNotEditable] Restrict if shifts of compared gantt dataset should be editable or not.
   */
  compareByRowIndex(comparedShiftsNotEditable) {
    const s = this;

    const finalDataset = JSON.parse(JSON.stringify(s.ganttOriginalDataSet)),
      mappedDatasets = [];

    for (let i = 0; i < s.compareDatasets.length; i++) {
      const dataSet = JSON.parse(JSON.stringify(s.compareDatasets[i].dataSet));

      CompareGanttsMapper.projectRowIdsToDataSet(finalDataset, dataSet);
      mappedDatasets.push(new CompareGanttsDataItem(s.compareDatasets[i].id, dataSet));
    }

    if (mappedDatasets.length > 0) {
      s.ganttDiagram
        .getHistory()
        .addNewEvent('compareGantts', new CompareGanttsEvent(), this, comparedShiftsNotEditable);
      s.compareByRowId(mappedDatasets, null, comparedShiftsNotEditable);
    } else s.reset();
  }

  /**
   * Puts child dataset from BASE-TREE (first group row) to last group element
   * to keep correct datastructure.
   * @private
   * @param {GanttData} finalDataset Combined gantt origin dataset.
   */
  private _prepareRowGroupData(finalDataset) {
    const s = this;

    const rowList = s._flattenHierachy(finalDataset.ganttEntries, false);

    for (let i = 0; i < rowList.length; i++) {
      const rowData = rowList[i];

      if (rowData.id.indexOf(s.uuid) != -1) continue;
      const lastgroupRow = s._findLastArrayElement(rowList, 'id', rowData.id + s.uuid);
      lastgroupRow.child = rowData.child;
    }

    // remove unnecessary data to avoid data difficulties
    function removeData(row, level, parent, index) {
      if (row.group != 'MEMBER') row.child = [];
      else {
        // check if current row is the last row which belongs to the group
        let nextSibling;

        if (parent) nextSibling = parent.child[index.index + 1];
        else nextSibling = finalDataset.ganttEntries[index.index + 1];

        if (nextSibling && nextSibling.group == 'MEMBER') row.child = [];
      }
    }

    DataManipulator.iterateOverDataSet(finalDataset.ganttEntries, { removeData: removeData });
  }

  /**
   * Finds last listelement of list inside JSON which is inside a list.
   * @private
   * @param {any[]} array List which is filled with JSON objects.
   * @param {string} attribute JSON property which contains list.
   * @param {string|number} value Searched value.
   */
  private _findLastArrayElement(array, attribute, value) {
    for (let i = array.length - 1; i > 0; i--) {
      if (array[i][attribute].indexOf(value) != -1) return array[i];
    }
  }

  /**
   * Removes all comparison data from gantt data.
   * Rebuilds gantt afterwards.
   */
  reset() {
    const s = this;

    if (!s.ganttOriginalDataSet) return;
    s.ganttDiagram.getHistory().addNewEvent('resetCompareGantts', new CompareGanttsEvent(), this);
    s.ganttDiagram.getDataHandler().setOriginDataset(s.ganttOriginalDataSet);
    s.ganttDiagram.update();
  }

  /**
   * Function to combine gantt dataset with comparison gantt datasets by matching id of rows.
   * (In progress:) Adds additional dataset to show free times between compared datasets in a new row.
   * @param {GanttData} compareDataSet Gantt comparison datasets:
   */
  addAdditionalDataRow(compareDataSet) {
    const s = this;

    let currentRows = {},
      currentGroupRow: any = {};

    const finalDataSet = JSON.parse(JSON.stringify(compareDataSet));

    // find all groups
    function findComparedRows(child) {
      if (child.id.indexOf(s.uuid) == -1) {
        if (currentGroupRow.id) currentRows[currentGroupRow.id] = currentGroupRow;

        currentGroupRow = {};
        currentGroupRow.id = child.id;
        currentGroupRow.shifts = child.shifts || [];
        currentGroupRow.groupedRows = [child];
      } else {
        currentGroupRow.shifts = currentGroupRow.shifts.concat(child.shifts);
        currentGroupRow.groupedRows.push(child);
      }
    }
    DataManipulator.iterateOverDataSet(finalDataSet.ganttEntries, { findComparedRows: findComparedRows });
    // add additional rows
    if (s.freeTimesRow)
      CompareGanttsAnalyse.execute(
        finalDataSet,
        currentRows,
        s.addAdditionalDataRowLevel,
        compareDataSet.minValue,
        compareDataSet.maxValue,
        null
      );

    return finalDataSet;
  }

  /**
   * Helps to work with gantt data tree structure:
   * Change hierarchie to one list with pointers to origin data.
   * @private
   * @param {GanttDataRow[]} ganttDataSet List of rows which will be flattened.
   * @param {boolean} [removeChilds] If true, removes all childs from given rows of ganttDataSet.
   */
  private _flattenHierachy(ganttDataSet, removeChilds) {
    const rowList = [];

    const addRowToMap = function (child) {
      rowList.push(child);
    };
    DataManipulator.iterateOverDataSet(ganttDataSet, { rowToMap: addRowToMap });

    if (removeChilds) {
      for (let i = 0; i < rowList.length; i++) {
        rowList[i].child = [];
      }
    }

    return rowList;
  }

  /**
   * Removes comparison dataset by save id.
   * @param {string} id Save id of comparison dataset.
   */
  removeCompareDataSetById(id) {
    const s = this;
    const datasetIndex = s.compareDatasets.findIndex(function (dataset) {
      return dataset.id == id;
    });

    s.compareDatasets.splice(datasetIndex, 1);
  }

  /**
   * @param {string} id Save id of comparison dataset
   * @return {CompareGanttsDataItem} Comparison dataset. Undefined if save id was not found.
   */
  getCompareDataset(id) {
    const s = this;
    const foundData = s.compareDatasets.find(function (dataset) {
      return dataset.id == id;
    });
    return foundData;
  }

  /**
   * Collects all shift ids from given (hierarchical) gantt origin dataset.
   * @private
   * @param {GanttData} ganttDataSet Gantt origin data.
   */
  private _getAllShiftIds(ganttDataset) {
    const s = this;
    const allShiftIds = [];
    const getAllShiftIds = function (child, level, parent, index) {
      for (let i = 0; i < child.shifts.length; i++) {
        allShiftIds.push(child.shifts[i].id);
      }
    };
    DataManipulator.iterateOverDataSet(ganttDataset.ganttEntries, { getAllShiftIds: getAllShiftIds });

    return allShiftIds;
  }

  /**
   * Forbids dragging for shift with given shiftId.
   * @param {string} shiftId Id of shift which will be restricted.
   */
  blockShiftsTranslationByShiftId(shiftId) {
    const s = this;
    s.ganttDiagram.getShiftTranslator().shiftEditLimiterAdapter.addNoDragIdByShiftId(shiftId, s.uuid);
  }
  //
  // GETTER & SETTER
  //
  /**
   * @return {CompareGanttsDataItem[]} All gantt compare datasets.
   */
  getCompareDatasets() {
    return this.compareDatasets;
  }

  //
  // RESTRICTIONS
  //
  /**
   * @param {boolean} status Show free time row which shows free times between compared gantt rows.
   */
  showFreeTimesRow(status) {
    const s = this;
    s.freeTimesRow = status;
  }

  addDifferencesRow(status) {
    const s = this;
    s.differencesRow = status;
  }

  /**
   * Defines on which hierarchical level all additional rows (like free time row) should be rendered.
   * Defaults to 0.
   * @param {number} additionalDataRowLevel Hierarchical level.
   */
  addDifferencesRowLevel(additionalDataRowLevel) {
    const s = this;
    s.addAdditionalDataRowLevel = additionalDataRowLevel;
  }

  //
  // CALLBACKS
  //
  addAfterCompareDatasetAddedCallback(id, func) {
    this.callBack.afterCompareDatasetAdded[id] = func;
  }
  removeAfterCompareDatasetAddedCallback(id) {
    delete this.callBack.afterCompareDatasetAdded[id];
  }
}

/**
 * Class which holds one gantt dataset for comparison.
 * @class
 * @constructor
 * @param {string} id Unique id to save dataset.
 * @param {GanttData} dataSet Gantt origin dataset.
 */
export class CompareGanttsDataItem {
  id: string;
  dataSet: any;

  constructor(id, dataSet) {
    this.id = id;
    this.dataSet = dataSet;
  }
}
