import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class WebStorageService {
  private storageName = 'saxms-storage';

  public setStorageName(name: string) {
    this.storageName = name;
  }

  public getStorageName(): string {
    return this.storageName;
  }

  constructor() {}

  /**
   * add item to web-storage
   * @param key key
   * @param value value
   */
  public addItem(key: string, value: any): Observable<any> {
    return this.synchStore().pipe(
      map((store) => {
        store[key] = value;
        localStorage.setItem(this.getStorageName(), JSON.stringify(store));
        return value;
      })
    );
  }

  public removeItem(key: string): Observable<any> {
    return of(localStorage.removeItem(key));
  }

  private synchStore() {
    let store: any = localStorage.getItem(this.getStorageName());
    try {
      store = JSON.parse(store);
      if (!store) {
        store = {};
      }
      return of(store);
    } catch (e) {
      throw e;
    }
  }

  /**
   * get item by key
   * @param key key
   */
  public getItem(key: string): Observable<any> {
    return this.synchStore().pipe(
      map((store) => {
        return store[key];
      })
    );
  }
}
