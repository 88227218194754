import * as d3 from 'd3';
import { Observable, Subject, takeUntil } from 'rxjs';
import { GanttConfig } from '../config/gantt-config';
import { YAxisDataFinder } from '../data-handler/data-finder/yaxis-data-finder';
import { GanttCanvasRow } from '../data-handler/data-structure/data-structure';
import { GanttUtilities } from '../gantt-utilities/gantt-utilities';
import { NodeProportionsStateConnector } from '../html-structure/node-proportion-state/node-proportion-state-connector';
import { EGanttScrollContainer } from '../html-structure/scroll-container.enum';
import { BestGantt } from '../main';
import { RenderDataHandler } from '../render-data-handler/render-data-handler';
import { TooltipBuilder } from '../tooltip/tooltip-builder';
import { GanttOpenRowHandler } from './open-row-handler';

/**
 * Main execution class for Gantt y axis.
 * @keywords y axis, yaxis, builder, executer, vertical, rows, children
 */
export class GanttYAxis {
  private _canvas: d3.Selection<HTMLDivElement, undefined, d3.BaseType, undefined> = undefined;
  private _allListItems: d3.Selection<HTMLDivElement, GanttCanvasRow, d3.BaseType, undefined> = undefined;
  private _allTextItems: d3.Selection<HTMLDivElement, GanttCanvasRow, d3.BaseType, undefined> = undefined;
  private _allStrokeItems: d3.Selection<HTMLDivElement, GanttCanvasRow, d3.BaseType, undefined> = undefined;

  private _nodeProportionsState: NodeProportionsStateConnector;
  tooltipBuilder: TooltipBuilder;

  allowDragDrop: boolean;
  showTooltip: boolean;
  rowColor: string;
  maxRowsForResource: number;
  lineColor: string;
  lineThickness: number;
  allowClicks: boolean;
  private defaultTextColor = '#FFFFFF';
  private onlyDefaultTextColor = false;
  private customRippleEffectClass: string = null; // overwrites standard class if set

  private _onClickShiftsSubject = new Subject<PointerEvent>();
  private _onDragStartSubject = new Subject<GanttYAxisDragEvent>();
  private _onDragUpdateSubject = new Subject<GanttYAxisDragEvent>();
  private _onDragEndSubject = new Subject<GanttYAxisDragEvent>();
  private _onContextMenuSubject = new Subject<GanttYAxisContextMenuEvent>();

  /**
   * @param _ganttDiagram
   * @param _parentNode D3 selection of the HTML node in which the y axis will be rendered.
   * @param _scrollContainerId
   */
  constructor(
    private _ganttDiagram: BestGantt,
    private _parentNode: d3.Selection<HTMLDivElement, undefined, d3.BaseType, undefined>,
    private readonly _scrollContainerId: EGanttScrollContainer = EGanttScrollContainer.DEFAULT
  ) {
    this._nodeProportionsState = new NodeProportionsStateConnector(
      this._ganttDiagram.getNodeProportionsState(),
      this._scrollContainerId
    );

    this._canvas = null;

    /*
    ! tooltipBuilder is a hard dependency, its provided after ShiftBuilderFacade is initialized
     */
    this.tooltipBuilder = null;

    this.allowDragDrop = true;
    this.allowClicks = true;
    this.showTooltip = true;

    this.rowColor = '#7f7f7f';
    this.maxRowsForResource = 5;
    this.lineColor = '#FFFFFF';
    this.lineThickness = 1;
  }

  /**
   * Builds SVG structure of y axis
   * @keywords build, render, add, init, initialize, yaxis, y axis, rows
   */
  build() {
    this._canvas = this._parentNode
      .append('div')
      .style('width', '100%')
      .style('height', 10000 + 'px')
      .style('white-space', 'nowrap');

    this._registerCallBacks();
  }

  hasScrollBar() {
    return (
      this._nodeProportionsState.getYAxisProportions().height >
      this._nodeProportionsState.getShiftCanvasProportions().height
    );
  }

  /**
   * Registers all callback functions.
   * @private
   */
  private _registerCallBacks() {
    this._ganttConfig
      .onRowFontSizeChanged()
      .pipe(takeUntil(this._ganttDiagram.onDestroy))
      .subscribe((fontSize) => {
        this._allListItems?.selectAll('.rowElement').style('font-size', fontSize + 'px');
        this._allListItems?.selectAll('.subtitleRow').style('font-size', fontSize - 3 + 'px');
      });
  }

  /**
   * Builds y axis. Wrapper for rendering rects and text of y axis.
   * NOTE: If {@link parentNodeList} is set, the y position of the rendered rows won't be obtained from {@link RenderDataHandler}!
   * @keywords build, render, vertical, axis, yaxis, y axis, row, children, text
   * @param dataSet Data with necessary information to build rows.
   * @param parentNodeList Optional, D3 selection for row rects.
   */
  public render(
    dataSet: GanttCanvasRow[],
    parentNodeList: d3.Selection<HTMLDivElement, undefined, d3.BaseType, undefined> = null
  ): void {
    let filterOffset = 0;
    const topRow = YAxisDataFinder.getRowByYPosition(
      dataSet,
      this._nodeProportionsState.getScrollTopPosition(),
      parentNodeList ? undefined : this._renderDataHandler
    );
    if (topRow) {
      const topRowOriginId = topRow.originalResource || topRow.id;
      filterOffset = YAxisDataFinder.getHeightOfMemberRowsByOriginRowID(topRowOriginId, dataSet) + topRow.height;
    }
    const filteredDataSet = GanttUtilities.filterDataSetByViewPort(
      this._parentNode.node(),
      dataSet,
      this._ganttDiagram.getRenderDataHandler(),
      filterOffset,
      this._nodeProportionsState.getShiftViewPortProportions().height,
      this._nodeProportionsState.getScrollTopPosition()
    );

    this._renderList(filteredDataSet, parentNodeList);
  }

  /**
   * Builds rect elements for rows. Uses Update-Enter-Exit pattern.
   * NOTE: If {@link parent} is set, the y position of the rendered rows won't be obtained from {@link RenderDataHandler}!
   * @keywords render, build, enter, update, exit, refresh, list, rect, row
   * @param dataSet Data with necessary information to build rows.
   * @param parent Optional, D3 selection for row rects.
   */
  private _renderList(
    dataSet: GanttCanvasRow[],
    parent: d3.Selection<HTMLDivElement, undefined, d3.BaseType, undefined> = null
  ): void {
    const s = this;
    const parentNode = parent || s._canvas;

    const scrollTop = s._nodeProportionsState.getScrollTopPosition();
    const topRow = GanttUtilities.findTopRow(dataSet, scrollTop, parent ? undefined : s._renderDataHandler);
    let rowIndex: number;
    let rowToMove: GanttCanvasRow;
    let maxPos: number;
    let minPos: number;
    let yPositionsOfRowsBelongingToResource: number[];
    if (topRow) {
      rowIndex = dataSet.findIndex((row) => row.id == topRow.id);
      if (topRow.originalResource) {
        // if top Row is a split row, use its origin row instead
        rowIndex = dataSet.findIndex((row) => row.id == topRow.originalResource);
      }

      if (dataSet[rowIndex] && dataSet[rowIndex + 1]) {
        const nextRow = dataSet[rowIndex + 1];
        if (nextRow.originalResource && nextRow.originalResource == dataSet[rowIndex].id) {
          rowToMove = dataSet[rowIndex];
          yPositionsOfRowsBelongingToResource = dataSet
            .filter((row) => row.originalResource == rowToMove.id || row.id == rowToMove.id)
            .map((row) => (parent ? row.y : s._renderDataHandler.getStateStorage().getYPositionRow(row.id)));
          maxPos = d3.max(yPositionsOfRowsBelongingToResource);
          minPos = d3.min(yPositionsOfRowsBelongingToResource);
        }
      }
    }

    s._buildRows(dataSet, parentNode, !parent);
    s._buildText(dataSet, parentNode, rowToMove, yPositionsOfRowsBelongingToResource, minPos, maxPos, !parent);

    s._buildStroke(dataSet, parentNode, !parent);
  }

  private _buildRows(
    dataSet: GanttCanvasRow[],
    parent: d3.Selection<HTMLDivElement, undefined, d3.BaseType, undefined>,
    useExternalY: boolean
  ): void {
    const s = this;

    let parentNode = parent.select<HTMLDivElement>('.rowWrapper');

    if (parentNode.empty()) {
      parentNode = parent.append('div').attr('class', 'rowWrapper');
    }

    parentNode.style('width', '100%').style('height', '100%');

    s._allListItems = parentNode.selectAll<HTMLDivElement, GanttCanvasRow>('.rowElement').data(dataSet);

    s._allListItems
      .style('top', function (d) {
        const y = useExternalY ? s._renderDataHandler.getStateStorage().getYPositionRow(d.id) : d.y;
        return y - s.lineThickness + 'px';
      })
      .style('height', function (d) {
        if (d.group == 'MEMBER') return d.height + 'px';
        return d.height - s.lineThickness + 'px';
      })
      .style('font-size', function () {
        if (s._ganttConfig) {
          return s._ganttConfig.rowFontSize() + 'px';
        } else return 14 + 'px';
      })
      .style('cursor', function (d) {
        return s.getAllowDragDrop() ? 'grab' : 'default';
      })
      .style('border-top', function (d) {
        if (d.group !== 'MEMBER') return `${s.lineThickness}px solid ${s.lineColor}`;
        else return null;
      })
      .style('background', s.getRowBackgroundColor.bind(s));

    s._allListItems
      .enter()
      .append('div')
      .attr('class', 'rowElement')
      .style('position', 'absolute')
      .style('left', 0)
      .style('top', function (d) {
        const y = useExternalY ? s._renderDataHandler.getStateStorage().getYPositionRow(d.id) : d.y;
        return y - s.lineThickness + 'px';
      })
      .style('width', '100%')
      .style('height', function (d) {
        if (d.group == 'MEMBER') return d.height + 'px';
        return d.height - s.lineThickness + 'px';
      })
      .style('cursor', function (d) {
        return s.getAllowDragDrop() ? 'grab' : 'default';
      })
      .style('font-size', function () {
        if (s._ganttConfig) {
          return s._ganttConfig.rowFontSize() + 'px';
        } else return 14 + 'px';
      })
      .style('border-top', function (d) {
        if (d.group !== 'MEMBER') return `${s.lineThickness}px solid ${s.lineColor}`;
        else return null;
      })
      .style('background', s.getRowBackgroundColor.bind(s));

    s._allListItems.exit().remove();
  }

  private _buildText(
    dataSet: GanttCanvasRow[],
    parent: d3.Selection<HTMLDivElement, undefined, d3.BaseType, undefined>,
    rowToMove: GanttCanvasRow,
    yPositionsOfRowsBelongingToResource: number[],
    minPos: number,
    maxPos: number,
    useExternalY: boolean
  ): void {
    const s = this;

    let parentNode = parent.select<HTMLDivElement>('.textWrapper');

    if (parentNode.empty()) {
      parentNode = parent.append('div').attr('class', 'textWrapper');
    }

    parentNode.style('width', '100%').style('height', '100%');

    const scrollTop = s._nodeProportionsState.getScrollTopPosition();

    this._allTextItems = parentNode.selectAll<HTMLDivElement, GanttCanvasRow>('.textElement').data(dataSet);

    // update
    this._allTextItems
      .style('top', function (d) {
        const y = useExternalY ? s._renderDataHandler.getStateStorage().getYPositionRow(d.id) : d.y;
        return y - s.lineThickness + 'px';
      })
      .style('height', function (d) {
        if (d.group == 'MEMBER') return d.height + 'px';
        return d.height - s.lineThickness + 'px';
      })
      .style('font-size', function () {
        if (s._ganttConfig) {
          return s._ganttConfig.rowFontSize() + 'px';
        } else return 14 + 'px';
      })
      .each(function (d) {
        const cutoff = s._calculateStickyPositionOffset(
          d,
          rowToMove,
          scrollTop,
          yPositionsOfRowsBelongingToResource,
          minPos,
          maxPos,
          useExternalY
        );
        s._updateTable(d3.select(this), d, cutoff);
      });

    // enter
    this._allTextItems
      .enter()
      .append('div')
      .attr('class', 'textElement')
      .style('position', 'absolute')
      .style('left', 0)
      .style('top', function (d) {
        const y = useExternalY ? s._renderDataHandler.getStateStorage().getYPositionRow(d.id) : d.y;
        return y - s.lineThickness + 'px';
      })
      .style('width', '100%')
      .style('height', function (d) {
        if (d.group == 'MEMBER') return d.height + 'px';
        return d.height - s.lineThickness + 'px';
      })
      .style('font-size', function () {
        if (s._ganttConfig) {
          return s._ganttConfig.rowFontSize() + 'px';
        } else return 14 + 'px';
      })
      .each(function (d) {
        const cutoff = s._calculateStickyPositionOffset(
          d,
          rowToMove,
          scrollTop,
          yPositionsOfRowsBelongingToResource,
          minPos,
          maxPos,
          useExternalY
        );
        s._buildTable(d3.select(this), d, cutoff);
      })
      .on('click', function (event, d) {
        // avoid to trigger click and drag
        if (event.defaultPrevented) return;
        s._openRowHandler.toggleRowsByClick(event, d);
        s._onClickShiftsSubject.next(event);
      })
      .on('contextmenu', function (event, d) {
        if (!s.allowClicks) {
          event.preventDefault();
          event.stopPropagation();
          return;
        }
        s._onContextMenuSubject.next(new GanttYAxisContextMenuEvent(event, d));
      })
      .on('mouseenter', function (event, d) {
        const mouse = [event.x, event.y];
        const mouseEvent = new GanttYAxisMouseEvent(d, mouse);
        s._onMouseEnter(mouseEvent, d3.select(this).node());
      })
      .on('mouseleave', function (event, d) {
        s._onMouseLeave(d);
      })
      .call(
        d3
          .drag()
          .on('start', function (event) {
            GanttUtilities.dispatchD3EventToOutside(d3.select(this), event);
            if (s.allowDragDrop) {
              s._onDragStartSubject.next(new GanttYAxisDragEvent(event, d3.select(this)));
            }
          })
          .on('drag', function (event) {
            GanttUtilities.dispatchD3EventToOutside(d3.select(this), event);
            if (s.allowDragDrop) {
              s._onDragUpdateSubject.next(new GanttYAxisDragEvent(event, d3.select(this)));
            }
          })
          .on('end', function (event) {
            GanttUtilities.dispatchD3EventToOutside(d3.select(this), event);
            if (s.allowDragDrop) {
              s._onDragEndSubject.next(new GanttYAxisDragEvent(event, d3.select(this)));
            }
          })
      );

    // exit
    this._allTextItems.exit().remove();
  }

  /**
   * Calculates position offset for table for the row currently at the top to achive a sticky text behavior such that the name of the resource is always visible,
   * even if the main row of the split entry is scrolled out of view.
   * This scroll behavior is smooth.
   * @param d row currently inspected.
   * @param rowToMove Row that will be moved.
   * @param scrollTop Current scrolltop position.
   * @param yPositions yPosition of rows that belong to the resource that will be moved
   * @param minPos Min Position for row.
   * @param maxPos Max Position for row.
   * @param useExternalY
   */
  private _calculateStickyPositionOffset(
    d: GanttCanvasRow,
    rowToMove: GanttCanvasRow,
    scrollTop: number,
    yPositions: number[],
    minPos: number,
    maxPos: number,
    useExternalY: boolean
  ): number {
    const y = useExternalY ? this._renderDataHandler.getStateStorage().getYPositionRow(d.id) : d.y;
    let cutoff;
    if (rowToMove && d.id === rowToMove.id) {
      cutoff = scrollTop - y;
      if (cutoff < 0) cutoff = 0;
      if (cutoff > maxPos - y) cutoff = maxPos - y;
      return cutoff;
    }
    return undefined;
  }

  private _buildTable(listContainer, canvasRowData, cutoff) {
    const s = this;

    const table = listContainer
      .append('table')
      .attr('class', 'tableEntry')
      .style('line-height', '50%')
      .style('table-layout', 'fixed')
      .style('height', '100%')
      .style('width', '100%')
      .style('pointer-events', 'none')
      .style('color', function () {
        return s.onlyDefaultTextColor ? s.defaultTextColor : canvasRowData.textColor;
      })
      .style('opacity', () => (canvasRowData.group == 'MEMBER' ? 0.4 : 1))
      .style('padding-left', function () {
        return canvasRowData.layer * 15 + 5 + 'px';
      })
      .style('position', () => {
        if (cutoff) {
          return 'absolute';
        } else {
          return 'relative';
        }
      })
      .style('top', () => (cutoff ? cutoff + 'px' : null));

    const tableRow = table.append('tr');

    const col1 = tableRow
      .append('td')
      .attr('class', 'colArrow')
      .style('width', '16px')
      .style('min-width', '16px')
      .text(s._getArrow(canvasRowData));

    const col2 = tableRow
      .append('td')
      .attr('class', 'colIcon')
      .style('width', `${canvasRowData.icon && canvasRowData.icon.label ? '16' : '0'}px`)
      .append('span')
      .attr(
        'class',
        `${canvasRowData.icon && canvasRowData.icon.namespace == 'customFont' ? 'saxms-icon' : 'material-icons'} md-24`
      )
      .style('font-size', `16px`)
      .style('line-height', `24px`)
      .style('width', `${canvasRowData.icon && canvasRowData.icon.label ? '16' : '0'}px`)
      .text(function () {
        if (canvasRowData.icon && canvasRowData.icon.label) {
          return canvasRowData.icon.label;
        }
        return null;
      });

    const col3 = tableRow
      .append('td')
      .attr('class', 'colText')
      .append('div')
      .attr('class', 'textContentContainer')
      .style('line-height', 'normal');

    const titleRow = col3
      .append('div')
      .attr('class', 'titleRow')
      .style('white-space', 'nowrap')
      .style('overflow', 'hidden')
      .style('text-overflow', 'ellipsis')
      .style('color', canvasRowData.icon ? this.defaultTextColor : undefined) // use default color if icon set to colorize icon only
      .text(canvasRowData.text);

    const subtitleRow = col3
      .append('div')
      .attr('class', 'subtitleRow')
      .style('white-space', 'nowrap')
      .style('overflow', 'hidden')
      .style('text-overflow', 'ellipsis')
      .style('color', this.onlyDefaultTextColor ? this.defaultTextColor + '77' : 'gainsboro')
      .style('font-weight', '300')
      .style('font-size', function () {
        if (s._ganttConfig) {
          return s._ganttConfig.rowFontSize() - 3 + 'px';
        } else return 11 + 'px';
      })
      .text(s._getSubtitleText(canvasRowData.subtitleElements));

    const col4 = tableRow
      .append('td')
      .attr('class', 'colCtxBtn')
      .style('width', `0px`)
      .style('visibility', 'hidden')
      .append('span')
      .attr(
        'class',
        `material-icons md-24 ${this.customRippleEffectClass ? this.customRippleEffectClass : 'yAxisRippleEffect'}`
      )
      .style('font-size', `16px`)
      .style('line-height', `24px`)
      .style('color', this.onlyDefaultTextColor ? this.defaultTextColor : 'white')
      .style('width', `16px`);

    if (canvasRowData.group !== 'MEMBER') {
      col4.text('more_vert').style('pointer-events', 'auto').style('cursor', 'pointer');
    } else {
      col4.text('').style('pointer-events', 'none').style('cursor', 'auto');
    }
  }

  private _updateTable(listContainer, canvasRowData, cutoff) {
    const s = this;

    const table = listContainer
      .selectAll('.tableEntry')
      .style('color', function () {
        return s.onlyDefaultTextColor ? s.defaultTextColor : canvasRowData.textColor;
      })
      .style('opacity', () => (canvasRowData.group == 'MEMBER' ? 0.4 : 1))
      .style('padding-left', function () {
        return canvasRowData.layer * 15 + 5 + 'px';
      })
      .style('position', () => {
        if (cutoff) {
          return 'absolute';
        } else {
          return 'relative';
        }
      })
      .style('top', () => (cutoff ? cutoff + 'px' : null));

    const tableRow = table.selectAll('tr');

    const col1 = tableRow.selectAll('.colArrow').text(s._getArrow(canvasRowData));

    const col2 = tableRow
      .selectAll('.colIcon')
      .style('width', `${canvasRowData.icon && canvasRowData.icon.label ? '16' : '0'}px`)
      .selectAll('span')
      .attr(
        'class',
        `${canvasRowData.icon && canvasRowData.icon.namespace == 'customFont' ? 'saxms-icon' : 'material-icons'} md-24`
      )
      .style('width', `${canvasRowData.icon && canvasRowData.icon.label ? '16' : '0'}px`)
      .style('font-size', `16px`)
      .style('line-height', `24px`)
      .text(function () {
        if (canvasRowData.icon && canvasRowData.icon.label) {
          return canvasRowData.icon.label;
        }
        return null;
      });

    const title = tableRow
      .selectAll('.titleRow')
      .style('color', canvasRowData.icon ? this.defaultTextColor : undefined)
      .text(canvasRowData.text);

    const subtitle = tableRow
      .selectAll('.subtitleRow')
      .style('color', this.onlyDefaultTextColor ? this.defaultTextColor + '77' : 'gainsboro')
      .text(s._getSubtitleText(canvasRowData.subtitleElements));

    const col4 = tableRow
      .selectAll('.colCtxBtn')
      .style('visibility', 'hidden')
      .selectAll('span')
      .attr(
        'class',
        `material-icons md-24 ${this.customRippleEffectClass ? this.customRippleEffectClass : 'yAxisRippleEffect'}`
      )
      .style('color', this.onlyDefaultTextColor ? this.defaultTextColor : 'white')
      .on('click', function (event) {
        s._onContextMenuSubject.next(new GanttYAxisContextMenuEvent(event, canvasRowData));
      });

    if (canvasRowData.group !== 'MEMBER') {
      col4.text('more_vert').style('pointer-events', 'auto').style('cursor', 'pointer');
    } else {
      col4.text('').style('pointer-events', 'none').style('cursor', 'auto');
    }
  }

  private _buildStroke(
    dataSet: GanttCanvasRow[],
    parentNode: d3.Selection<HTMLDivElement, undefined, d3.BaseType, undefined>,
    useExternalY: boolean
  ): void {
    const s = this;

    s._allStrokeItems = parentNode
      .selectAll<HTMLDivElement, GanttCanvasRow>('.strokeContainer')
      .data(dataSet.filter((elem) => elem.group !== 'MEMBER' && elem.stroke));

    s._allStrokeItems
      .style('height', function (d) {
        const heightOfDraggedRows = YAxisDataFinder.getHeightOfMemberRowsByOriginRowID(d.id, dataSet) + d.height;
        const wholeRowHeight = heightOfDraggedRows - 1;
        return wholeRowHeight + 'px';
      })
      .style('top', function (d) {
        const y = useExternalY ? s._renderDataHandler.getStateStorage().getYPositionRow(d.id) : d.y;
        return y + 'px';
      })
      .style('border', function (d) {
        return `3px solid ${d.stroke}`;
      });

    s._allStrokeItems
      .enter()
      .append('div')
      .attr('class', 'strokeContainer')
      .style('width', '100%')
      .style('height', function (d) {
        const heightOfDraggedRows = YAxisDataFinder.getHeightOfMemberRowsByOriginRowID(d.id, dataSet) + d.height;
        const wholeRowHeight = heightOfDraggedRows - 1;
        return wholeRowHeight + 'px';
      })
      .style('position', 'absolute')
      .style('top', function (d) {
        const y = useExternalY ? s._renderDataHandler.getStateStorage().getYPositionRow(d.id) : d.y;
        return y + 'px';
      })
      .style('z-index', '5')
      .style('pointer-events', 'none')
      .style('box-sizing', 'border-box')
      .style('border', function (d) {
        return `3px solid ${d.stroke}`;
      });

    s._allStrokeItems.exit().remove();
  }

  /**
   * Calculates row text: arrow by opening status and group info.
   * @private
   * @param {GanttCanvasRow} rowData Data with necessary information to build row text.
   */
  private _getArrow(rowData) {
    if (rowData.group == 'MEMBER' || (rowData.leaf && rowData.group != 'BASE-TREE')) return '  ';
    let openStatus = '▸';
    if (rowData.open) openStatus = '▾';

    return openStatus;
  }

  private _getSubtitleText(subtitleArray) {
    let text = '';

    if (!subtitleArray || !subtitleArray.length) {
      return '';
    }

    for (const subtitle of subtitleArray) {
      text += ` ${subtitle}`;
    }

    return text;
  }

  /**
   * Removes row rects, text and y axis data.
   * @keywords delete, clear, remove, empty, rect, text, row, children
   */
  removeList() {
    this._allListItems = null;
  }

  /**
   * Removes and rebuilds row rects and text.
   * @keywords refresh, rebuild, rerender, update
   * @param {GanttCanvasRow[]} dataSet Data with necessary information to build rows.
   */
  public reBuildList(dataSet: GanttCanvasRow[]): void {
    this.removeList();
    this.render(dataSet);
  }

  /**
   * Sets height of canvas node.
   * @keywords canvas, height, set, define, vertical
   * @param height New height of canvas node.
   */
  public setCanvasHeight(height: number): void {
    this._canvas.style('height', height + 'px');
    this._nodeProportionsState.setYAxisProportions({
      width: this._nodeProportionsState.getYAxisProportions().width,
      height: height,
    });
  }

  /**
   * Adds height to height of canvas node.
   * @keywords canvas, height, add, define, vertical
   * @param height Height to add.
   */
  public addToCanvasHeight(height: number): void {
    const prevHeight = parseInt(this._canvas.style('height'));
    const newHeight = height + prevHeight;
    this._canvas.style('height', newHeight + 'px');
    this._nodeProportionsState.setYAxisProportions({
      width: this._nodeProportionsState.getYAxisProportions().width,
      height: newHeight,
    });
  }

  /**
   * Removes all row rects and text nodes.
   * @keywords delete, clear, remove, empty, rect, text, row, children, canvas
   * @param {number} height Height to add.
   */
  removeAll() {
    this._canvas.remove();
  }

  /**
   * EventHandler wich is called on mouse over on an entry element.
   *
   * @param event Object with entry data and mouse coordinates.
   * @param {HTMLNode} node Node of hovered container.
   */
  private _onMouseEnter(event, node) {
    if (this.showTooltip && event.data.tooltip) {
      this.tooltipBuilder.addTooltipToHTMLBody(event.mouse[0], event.mouse[1], event.data.tooltip, node);
    }
    this._showCtxBtn(this.allowClicks, event.data);
  }

  /**
   * EventHandler wich is called on mouse out of an entry element.
   *
   * @param d Object with entry data.
   */
  private _onMouseLeave(d) {
    if (this.showTooltip) {
      this.tooltipBuilder.removeAllTooltips();
    }
    this._showCtxBtn(false, d);
  }

  /**
   * Toggles context menu button of row by data.
   * @param show If true the button is displayed
   * @param d Data of gantt row
   */
  private _showCtxBtn(show: boolean, d: GanttCanvasRow): void {
    const id = d.group === 'MEMBER' ? d.originalResource : d.id;

    const textItem = this._allTextItems.filter(function (item) {
      return item.id === id;
    });
    // reappend the text item to ensure it is on the top
    textItem.node().parentNode.appendChild(textItem.node());

    textItem
      .selectAll('.tableEntry')
      .selectAll('tr')
      .selectAll('.colCtxBtn')
      .style('visibility', () => (show ? 'visible' : 'hidden'))
      .style('width', () => (show ? '28px' : '0px'));
  }

  private getRowBackgroundColor(rowData: GanttCanvasRow): string {
    const customColor = rowData.color;
    let resultingColor =
      this._ganttConfig.yAxisEntryColorization && this._ganttConfig.isDefinedRowBackgroundColorApplied() && customColor
        ? customColor
        : this.rowColor;
    if (rowData.indicatorColor) {
      resultingColor = `linear-gradient(to right, ${rowData.indicatorColor} 0%,${rowData.indicatorColor} 5px,${resultingColor} 5px,${resultingColor} 100%)`;
    }
    return resultingColor;
  }

  //
  // SIZE UPDATE
  //

  /**
   * Update horizontal size of y axis.
   * @param {GanttCanvasRow[]} dataSet Data with necessary information to build rows.
   */
  updateSize(dataSet) {
    this.render(dataSet);
  }

  //
  // GETTER & SETTER
  //

  /**
   * Helper getter which returns the gantt config of the current gantt.
   */
  protected get _ganttConfig(): GanttConfig {
    return this._ganttDiagram.getConfig();
  }

  /**
   * Helper getter which returns the render data handler of the current gantt.
   */
  private get _renderDataHandler(): RenderDataHandler {
    return this._ganttDiagram.getRenderDataHandler();
  }

  /**
   * Helper getter which returns the open row handler of the current gantt.
   */
  private get _openRowHandler(): GanttOpenRowHandler {
    return this._ganttDiagram.getOpenRowHandler();
  }

  setTooltipBuilder(tooltipBuilder) {
    this.tooltipBuilder = tooltipBuilder;
  }

  getCanvas() {
    return this._canvas;
  }

  public getParentNode(): d3.Selection<HTMLDivElement, undefined, d3.BaseType, undefined> {
    return this._parentNode;
  }

  public setRowColor(color: string): void {
    this.rowColor = color;
  }

  public getRowColor(): string {
    return this.rowColor;
  }

  public setLineStyle(lineStyle: { color?: string; thickness?: number }): void {
    if (lineStyle.color) this.lineColor = lineStyle.color;
    if (lineStyle.thickness) this.lineThickness = lineStyle.thickness;
  }

  public getLineStyle(): { color: string; thickness: number } {
    return {
      color: this.lineColor,
      thickness: this.lineThickness,
    };
  }

  public setDefaultTextColor(color: string): void {
    this.defaultTextColor = color;
  }

  public setOnlyDefaultTextColor(bool: boolean): void {
    this.onlyDefaultTextColor = bool;
  }

  /**
   * Overwrites standard CSS class for ripple effect.
   * @param class Class to overwrite stamdard with.
   */
  public setCustomRippleEffectClass(cssClass: string): void {
    this.customRippleEffectClass = cssClass;
  }

  setAllowDragDrop(bool) {
    this.allowDragDrop = bool;
  }

  getAllowDragDrop() {
    return this.allowDragDrop;
  }

  setAllowClicks(bool: boolean): void {
    this.allowClicks = bool;
  }

  getAllowClicks(): boolean {
    return this.allowClicks;
  }

  public getScrollTop(): number {
    return this._parentNode.node().scrollTop;
  }

  setMaxRowsForResource(number = 5) {
    if (isNaN(number)) {
      this.maxRowsForResource = 5;
    } else {
      this.maxRowsForResource = number + 1;
    }
  }

  //
  // OBSERVABLES
  //

  public get onClickShifts(): Observable<PointerEvent> {
    return this._onClickShiftsSubject.asObservable();
  }

  public get onDragStart(): Observable<GanttYAxisDragEvent> {
    return this._onDragStartSubject.asObservable();
  }

  public get onDragUpdate(): Observable<GanttYAxisDragEvent> {
    return this._onDragUpdateSubject.asObservable();
  }

  public get onDragEnd(): Observable<GanttYAxisDragEvent> {
    return this._onDragEndSubject.asObservable();
  }

  public get onContextMenu(): Observable<GanttYAxisContextMenuEvent> {
    return this._onContextMenuSubject.asObservable();
  }
}

export class GanttYAxisMouseEvent {
  constructor(public data: any, public mouse: any) {}
}

/**
 * Stores data to describe drag event.
 * @keywords data, class, drag, event, yaxis, y axis, edit
 */
export class GanttYAxisDragEvent {
  /**
   * @param event Drag event.
   * @param target D3 selection of drag target element.
   */
  constructor(
    public event: d3.D3DragEvent<HTMLDivElement, GanttCanvasRow, undefined>,
    public target: d3.Selection<any, any, null, undefined>
  ) {}
}

/**
 * Stores data to describe contecxt menu event.
 */
export class GanttYAxisContextMenuEvent {
  /**
   * @param event Context menu event.
   * @param rowData Clicked row.
   */
  constructor(public event: MouseEvent, public rowData: GanttCanvasRow) {}
}
