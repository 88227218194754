import * as d3 from 'd3';
import { Observable, Subject } from 'rxjs';
import { EGanttShiftColorMode } from '../config/config-data/shift-config.data';
import { GanttConfig } from '../config/gantt-config';
import { DataHandler } from '../data-handler/data-handler';
import { GanttCanvasRow, GanttCanvasShift } from '../data-handler/data-structure/data-structure';
import { GanttShiftTranslationChain } from '../edit-shifts/shift-translation/shift-translation-chain';
import { GanttUtilities } from '../gantt-utilities/gantt-utilities';
import { GanttHTMLStructureBuilder } from '../html-structure/html-structure-builder';
import { NodeProportionsStateConnector } from '../html-structure/node-proportion-state/node-proportion-state-connector';
import { INodeProportion } from '../html-structure/node-proportion-state/node-proportion.interface';
import { EGanttScrollContainer } from '../html-structure/scroll-container.enum';
import { BestGantt } from '../main';
import { PatternHandler } from '../pattern/patternHandler';
import { RenderDataHandler } from '../render-data-handler/render-data-handler';
import { GanttYAxisContextMenuEvent } from '../y-axis/y-axis';
import { IGanttShiftsBuilding } from './shift-build-strategies/shift-build-interface';
import { GanttShiftsBuildingNULL } from './shift-build-strategies/shift-build-null';
import { GanttShiftsCalculationDefault } from './shift-calculation-strategies/shift-calculation-default';
import { IGanttShiftsCalculation } from './shift-calculation-strategies/shift-calculation.interface';
import { GanttShiftClipPathHandler } from './shift-clip-paths/shift-clip-path-handler';
import { IShiftClickEvent, IShiftDragEvent } from './shift-events.interface';
import { TextOverlay } from './text-overlay';

/**
 * Main builder for gantt shifts.
 * @keywords shift, executer, builder, canvas, origin, rect, vertical, horizontal, line
 */
export class ShiftBuilder {
  private _nodeProportionsState: NodeProportionsStateConnector;

  private _shiftDefsInFrontShifts: d3.Selection<SVGGElement, undefined, d3.BaseType, undefined> = undefined;
  private _shiftDefsBehindShifts: d3.Selection<SVGGElement, undefined, d3.BaseType, undefined> = undefined;
  private _allShifts: d3.Selection<SVGRectElement, GanttCanvasShift, d3.BaseType, undefined> = undefined;
  private _allShiftContents: d3.Selection<SVGPathElement, GanttCanvasShift, d3.BaseType, undefined> = undefined;
  private _allShiftSecondColorOverlays: d3.Selection<SVGPathElement, GanttCanvasShift, d3.BaseType, undefined> =
    undefined;

  private _horizontalLineGroup: d3.Selection<SVGGElement, undefined, d3.BaseType, undefined> = undefined;
  private _allHorizontalLines: d3.Selection<SVGLineElement, GanttCanvasRow, d3.BaseType, undefined> = undefined;
  private _verticalLineGroup: d3.Selection<SVGGElement, undefined, d3.BaseType, undefined> = undefined;

  private _dragDropShifts = true;
  private _allowShiftClicks = true;
  private _shiftsAreResizing = false;
  private _stopShiftHovering = new Set<string>();

  private _shiftBuildingStrategy: IGanttShiftsBuilding = new GanttShiftsBuildingNULL();
  private _shiftCalculationStrategy: IGanttShiftsCalculation;
  private _clipPathHandler: GanttShiftClipPathHandler = undefined;

  private _lastZoomTransformation = new d3.ZoomTransform(1, 0, 0);
  private _hasBeenDragged = false;
  private _lastParentNodeProportions: INodeProportion = { width: 0, height: 0 };
  private _lastDragEvent: d3.D3DragEvent<SVGRectElement, GanttCanvasShift, undefined> = undefined;
  private _singleClickTimeout: NodeJS.Timeout;

  private _forceCanvasDoubleClickCbs: { [key: string]: (event: MouseEvent) => boolean } = {};

  private _subjects = {
    update: new Subject<any>(),

    shiftMouseOver: new Subject<MouseEvent>(),
    shiftMouseOut: new Subject<MouseEvent>(),

    shiftDragStart: new Subject<IShiftDragEvent>(),
    shiftDragging: new Subject<IShiftDragEvent>(),
    manipulateShiftDragEnd: new Subject<IShiftDragEvent>(),
    shiftDragEnd: new Subject<IShiftDragEvent>(),

    afterShiftRender: new Subject<GanttShiftsAfterRenderEvent>(),

    shiftOnMouseDown: new Subject<d3.ClientPointEvent>(),

    // multiple subjects sorted by priority (execution direction is from lowest to highest)
    shiftOnClickDirectly: new Array<Subject<IShiftClickEvent>>(),

    shiftOnClick: new Subject<IShiftClickEvent>(),
    shiftOnMiddleClick: new Subject<d3.ClientPointEvent>(),
    shiftAfterOnClick: new Subject<IShiftClickEvent>(),
    shiftOnDoubleClick: new Subject<IShiftClickEvent>(),
    shiftAfterOnDoubleClick: new Subject<IShiftClickEvent>(),

    shiftOnContextMenu: new Subject<IShiftClickEvent>(),

    rowOnContextMenu: new Subject<GanttYAxisContextMenuEvent>(),

    blockedShiftDragStart: new Subject<IShiftDragEvent>(),
    blockedShiftDragging: new Subject<IShiftDragEvent>(),
    blockedShiftDragEnd: new Subject<IShiftDragEvent>(),

    shiftAreaMouseMiss: new Subject<MouseEvent>(),
    shiftMouseOverShiftOnCanvas: new Subject<d3.ClientPointEvent>(),
    enterCanvas: new Subject<d3.ClientPointEvent>(),
    canvasMouseOver: new Subject<MouseEvent>(),
    canvasDoubleClick: new Subject<MouseEvent>(),
  };

  /**
   * @param _parentNode Parent div of shifts.
   * @param _canvasInFrontShifts SVG node in front of shifts.
   * @param _canvasBehindShifts SVG node behind shifts.
   * @param _shiftRenderCanvas Canvas Node where shifts will be rendered in.
   * @param _ganttDiagram
   * @param _textOverlay
   * @param _scrollContainerId
   * @param _altPatternHandler Alternative {@link PatternHandler} to use. If not specified, this {@link ShiftBuilder} will use the default {@link PatternHandler} of the gantt.
   * @param _altGanttConfig Alternative {@link GanttConfig} to use instead of the {@link GanttConfig} of the passed {@link BestGantt} instance.
   * @param useClipPathHandler If `true`, this {@link ShiftBuilder} will build a clip path to clip rendered svg shifts to the proportions of the container they are rendered in (default is `true`).
   */
  constructor(
    private _parentNode: d3.Selection<HTMLDivElement, undefined, d3.BaseType, undefined>,
    private _canvasInFrontShifts: d3.Selection<SVGSVGElement, undefined, d3.BaseType, undefined>,
    private _canvasBehindShifts: d3.Selection<SVGSVGElement, undefined, d3.BaseType, undefined>,
    private _shiftRenderCanvas: d3.Selection<HTMLCanvasElement, undefined, d3.BaseType, undefined>,
    private _ganttDiagram: BestGantt,
    private _textOverlay: TextOverlay,
    private readonly _scrollContainerId: EGanttScrollContainer = EGanttScrollContainer.DEFAULT,
    private _altPatternHandler: PatternHandler = undefined,
    private _altGanttConfig: GanttConfig = undefined,
    useClipPathHandler = true
  ) {
    this._nodeProportionsState = new NodeProportionsStateConnector(
      this._ganttDiagram.getNodeProportionsState(),
      this._scrollContainerId
    );
    this._shiftCalculationStrategy = new GanttShiftsCalculationDefault(this, this._ganttDiagram);

    // if possible, init clip path handler
    if (useClipPathHandler) {
      this._clipPathHandler = new GanttShiftClipPathHandler(
        this._ganttDiagram,
        this._getShiftGroupOverlayDefs().select('defs'),
        this._scrollContainerId
      );
    }
  }

  /**
   * Initialize shift builder. Add node structure.
   * @keywords init, initial, shift, canvas, parent, group, vertical, horizontal, line
   * @param strategy ShiftBuilding strategy to use.
   */
  public init(strategy: IGanttShiftsBuilding): void {
    if (this._canvasBehindShifts) {
      this._verticalLineGroup = this._canvasBehindShifts.append('g').attr('class', 'gantt_vertical-line-group');

      this._horizontalLineGroup = this._canvasBehindShifts.append('g').attr('class', 'gantt_horizontal-line-group');
    }

    this._shiftDefsBehindShifts = this._canvasBehindShifts.append('g').attr('class', 'gantt_defs');
    this._shiftDefsBehindShifts.append('defs');

    this._shiftDefsInFrontShifts = this._canvasInFrontShifts.append('g').attr('class', 'gantt_defs');
    this._shiftDefsInFrontShifts.append('defs');

    this.changeShiftBuildingStrategy(strategy);
    this._shiftBuildingStrategy.init(this);
  }

  public update(): void {
    this.onUpdateEvent({});
  }

  public destroy(): void {
    this._completeSubjects();
  }

  /**
   * Completes all shift builder subjects.
   */
  private _completeSubjects(): void {
    for (const key in this._subjects) {
      const value = this._subjects[key];
      if (value instanceof Subject) value.complete();
      else if (value instanceof Array) value.forEach((s) => s.complete());
    }
  }

  /**
   * Builds and updates shifts by Update-Enter-Exit pattern.
   * Uses a concrete shift Building Strategy to render.
   * @keywords render, shifts, canvas, rect, build, add, update, enter, exit, drag, click, onlick
   * @param dataSet Canvas shift data.
   * @param parentNode D3 selection of parent node for shift rendering.
   * @param renderText
   */
  public renderShifts(dataSet: GanttCanvasShift[], parentNode, renderText = true): void {
    this._shiftBuildingStrategy.renderShifts(dataSet, parentNode, this);
    if (renderText) {
      this._textOverlay.reRender(dataSet, this._lastZoomTransformation);
    }
  }

  /**
   * Adds one single shift.
   * @keywords add, shift, build, render, single, one
   * @param dataSet Data of shift which should be added.
   * @param parentNode D3 selection of parent node for shift rendering.
   */
  public addShift(dataSet: GanttCanvasShift, parentNode = undefined): void {
    if (!dataSet) return;

    let parent = this.getShiftGroupOverlay();

    if (parentNode) parent = parentNode;

    const newAddedShift = parent.datum(dataSet).append('rect');

    this.updateShift(newAddedShift);

    newAddedShift
      .on('mouseover', (event) => {
        this.shiftMouseOverEvent(event);
      })
      .on('mouseout', (event) => {
        this.shiftMouseOutEvent(event);
      })
      .call(
        d3
          .drag()
          .on('start', (event) => {
            GanttUtilities.dispatchD3EventToOutside(newAddedShift, event);
            if (this._dragDropShifts) {
              this.shiftDragStartEvent({ event, target: newAddedShift, hasBeenDragged: this._hasBeenDragged });
            } else {
              this.blockedShiftDragStartEvent({ event, target: newAddedShift, hasBeenDragged: this._hasBeenDragged });
            }
          })
          .on('drag', (event) => {
            GanttUtilities.dispatchD3EventToOutside(newAddedShift, event);
            if (this._dragDropShifts) {
              this._hasBeenDragged = true;
              this.shiftDraggingEvent({ event, target: newAddedShift, hasBeenDragged: this._hasBeenDragged });
            } else {
              this.blockedShiftDraggingEvent({ event, target: newAddedShift, hasBeenDragged: this._hasBeenDragged });
            }
          })
          .on('end', (event, d: any) => {
            GanttUtilities.dispatchD3EventToOutside(newAddedShift, event);
            if (this._dragDropShifts) {
              this.manipulateShiftDragEndEvent({ event, target: newAddedShift, hasBeenDragged: this._hasBeenDragged });
              this.shiftDragEndEvent({ event, target: newAddedShift, hasBeenDragged: this._hasBeenDragged });

              // Execute onclick function if there was no drag
              if (!this._hasBeenDragged && this._allowShiftClicks && d.editable) {
                this.shiftOnClickEvent({ event, target: newAddedShift });
              }
              this._hasBeenDragged = false;
            } else {
              this.blockedShiftDragEndEvent({ event, target: newAddedShift, hasBeenDragged: this._hasBeenDragged });
              if (this._allowShiftClicks && d.editable) {
                this.shiftOnClickEvent({ event, target: newAddedShift });
              }
            }
          })
      );

    this.afterShiftRenderEvent(new GanttShiftsAfterRenderEvent(undefined, this._lastZoomTransformation));
  }

  /**
   * Update attributes of one single shift by shift data.
   * @keywords shift, one, single, update, rerender, rebuild
   * @param d3ShiftSelection D3 selection of shift to update.
   */
  public updateShift(d3ShiftSelection): void {
    d3ShiftSelection
      .attr('width', function (d) {
        return d.width;
      })
      .attr('height', function (d) {
        return d.height;
      })
      .attr('x', function (d) {
        return d.x;
      })
      .attr('y', function (d) {
        let y = this.renderDataHandler.getStateStorage().getYPositionShift(d.id);
        if (!y && y !== 0) y = d.y;
        return y;
      })
      .attr('fill', function (d) {
        if (d.highlighted) return d.highlighted;
        if (d.selected) return d.selected;
        if (d.pattern) return this.patternHandler.getPatternAsUrl(d.pattern, d.color, d.patternColor);
        return d.color;
      });

    this.afterShiftRenderEvent(new GanttShiftsAfterRenderEvent(d3ShiftSelection, this._lastZoomTransformation));
  }

  /**
   * Updates the shift color of one single or all shifts by shift data.
   * @keywords shift, color, udpate, rerender, refresh, fill
   * @param d3ShiftSelection D3 shift selection. If not set, all shifts will be updated.
   */
  public updateShiftColor(d3ShiftSelection = undefined): void {
    if (!d3ShiftSelection) {
      d3ShiftSelection = this.getShiftGroupOverlay().selectAll('rect');
    }

    d3ShiftSelection.attr('fill', (d) => {
      if (d.highlighted) return d.highlighted;
      if (d.selected) return d.selected;
      if (d.pattern) return this._patternHandler.getPatternAsUrl(d.pattern, d.color, d.patternColor);
      return d.color;
    });
  }

  /**
   * Removes all shift rects.
   * @keywords remove, delete, clear, all, shifts
   */
  public removeAllShifts(): void {
    this._shiftBuildingStrategy.removeAllShifts(this);
  }

  /**
   * Removes and builds all shifts.
   * @keywords rebuild, build, rerender, render, refresh, update, shift, shifts
   */
  public reBuildShifts(dataSet: GanttCanvasShift[]): void {
    this.removeAllShifts();
    this.renderShifts(dataSet, null);
  }

  /**
   * Builds and updates horizontal lines by Update-Enter-Exit pattern.
   * @keywords horizontal, lines, render, build, add, axis
   * @param dataSet Row canvas dataset.
   */
  public renderHorizontalLines(dataSet: GanttCanvasRow[]): void {
    if (!dataSet) return;

    dataSet = GanttUtilities.filterDataSetByViewPort(
      this.htmlStructureBuilder.getShiftContainer(this._scrollContainerId).node(),
      dataSet,
      this.renderDataHandler,
      0,
      this.nodeProportionsState.getShiftViewPortProportions().height,
      this.nodeProportionsState.getScrollTopPosition()
    );

    const canvasWidth = this._canvasInFrontShifts.attr('width');

    this._allHorizontalLines = this._horizontalLineGroup
      .selectAll<SVGLineElement, GanttCanvasRow>('line')
      .data(dataSet);

    this._allHorizontalLines
      .attr('y1', (d) => {
        let y = this.renderDataHandler.getStateStorage().getYPositionRow(d.id);
        if (!y && y !== 0) y = d.y;
        return y;
      })
      .attr('y2', (d) => {
        let y = this.renderDataHandler.getStateStorage().getYPositionRow(d.id);
        if (!y && y !== 0) y = d.y;
        return y;
      })
      .attr('x2', canvasWidth)
      .attr('stroke-width', this.ganttConfig.getHorizontalLinesThickness())
      .attr('stroke', (d) => {
        return d.group === 'MEMBER' && !this.ganttConfig.isShowHorizontalSplitLines()
          ? undefined
          : this.ganttConfig.getHorizontalLinesColor();
      })
      .attr('stroke-dasharray', (d) => {
        if (d.group == 'MEMBER') return '10 8';
      });

    this._allHorizontalLines
      .enter()
      .append('line')
      .attr('x1', 0)
      .attr('x2', canvasWidth)
      .attr('y1', (d) => {
        let y = this.renderDataHandler.getStateStorage().getYPositionRow(d.id);
        if (!y && y !== 0) y = d.y;
        return y;
      })
      .attr('y2', (d) => {
        let y = this.renderDataHandler.getStateStorage().getYPositionRow(d.id);
        if (!y && y !== 0) y = d.y;
        return y;
      })
      .attr('class', 'gantt-shift-horizontal-lines')
      .attr('stroke-width', this.ganttConfig.getHorizontalLinesThickness())
      .attr('stroke', (d) => {
        return d.group === 'MEMBER' && !this.ganttConfig.isShowHorizontalSplitLines()
          ? undefined
          : this.ganttConfig.getHorizontalLinesColor();
      })
      .attr('stroke-dasharray', (d) => {
        if (d.group == 'MEMBER') return '10 8';
      });

    this._allHorizontalLines.exit().remove();

    this.checkHorizontalLinesVisibility();
  }

  /**
   * Updates horizontal line visibility by config value.
   * @keywords check, proof, horizontal, lines, visibility, opacity, show, hide
   */
  public checkHorizontalLinesVisibility(): void {
    this._horizontalLineGroup.style('visibility', () => {
      if (!this.ganttConfig.areHorizontalLinesVisible()) return 'hidden';
    });
  }

  /**
   * Builds (and updates) vertical lines of gantt diagram by using the same generators as x axis.
   * @keywords render, update, rebuild, refresh, vertical, lines, axis
   * @param xAxisGenerators Array of D3 axis calculators.
   */
  public renderVerticalLines(xAxisGenerators: d3.Axis<Date>[]): void {
    const s = this;
    s._verticalLineGroup.selectAll('g').remove();
    const canvasHeight = s.nodeProportionsState.getShiftCanvasProportions().height;
    for (let i = xAxisGenerators.length - 1; i >= 0; i--) {
      const generator = xAxisGenerators[i];
      generator.tickFormat(undefined).tickSize(canvasHeight);

      const ticks = s._verticalLineGroup.append('g').call(generator);

      ticks.selectAll('line').each(function (d) {
        const config = s._getVerticalLineConfigurationByDate(d);
        d3.select(this).style('stroke-width', config.thickness).style('stroke', config.color);
      });

      ticks.selectAll('path').remove(); // removes black lines ontop and left of shiftcontainer
      ticks.selectAll('text').remove();
    }
    s.checkVerticalLinesVisibility();
  }

  /**
   * Returns a specific configuration of a vertical line depending on the time to be displayed.
   * @param {Date} date time for which the line should be rendered
   * @returns {GanttAxisLineConfiguration} resulting line configuration
   */
  private _getVerticalLineConfigurationByDate(date) {
    const weekDay = date.getDay();
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    const milliseconds = date.getMilliseconds();

    if (this.ganttConfig.getXAxisFormat() === 'WEEK' && weekDay !== 1) {
      // if weekly view -> remove monthly lines
      return new GanttAxisLineConfiguration('#ffffff00', 1);
    }

    if (!hours && !minutes && !seconds && !milliseconds) {
      // all values equals 0 => full day
      if (day === 1) {
        // full month
        return new GanttAxisLineConfiguration(
          this.ganttConfig.getVerticalLinesMonthlyColor(),
          this.ganttConfig.getVerticalLinesMonthlyThickness()
        );
      } // full day
      return new GanttAxisLineConfiguration(
        this.ganttConfig.getVerticalLinesDailyColor(),
        this.ganttConfig.getVerticalLinesDailyThickness()
      );
    }
    return new GanttAxisLineConfiguration(
      this.ganttConfig.getVerticalLinesColor(),
      this.ganttConfig.getVerticalLinesThickness()
    );
  }

  /**
   * Returns the shift color by the current shift color mode of the given shift.
   * @returns Hex color code of the shift.
   */
  public getBasicShiftColorByCanvasShift(shift: GanttCanvasShift): string {
    let shiftColor: string;
    switch (this.ganttConfig.getShiftBuildingColorMode()) {
      case EGanttShiftColorMode.SHOW_DEFAULT_COLOR:
        shiftColor = shift.color;
        break;
      case EGanttShiftColorMode.SHOW_MULTIPLE_COLORS:
      case EGanttShiftColorMode.SHOW_FIRST_COLOR:
        shiftColor = shift.firstColor || shift.color;
        break;
      case EGanttShiftColorMode.SHOW_SECOND_COLOR:
        shiftColor = shift.secondColor || shift.color;
        break;
      default:
        shiftColor = shift.color;
        break;
    }

    return shiftColor;
  }

  /**
   * Updates vertical line visibility by config value.
   * @keywords check, vertical, lines, visibility, show, hide, display
   */
  public checkVerticalLinesVisibility(): void {
    this._verticalLineGroup.style('visibility', () => {
      if (!this.ganttConfig.areVerticalLinesVisible()) return 'hidden';
    });
  }

  /**
   * Scales horozontal lines by zoom event.
   * @keywords zoom, scale, event, horizotnal, lines, time
   * @param zoomEvent Zoom Event with transform values.
   */
  public scaleHorizontal(zoomEvent: { transform: d3.ZoomTransform }): void {
    if (zoomEvent && zoomEvent.transform) this._lastZoomTransformation = zoomEvent.transform;
  }

  /**
   * Removes canvas with all its content.
   * @keywords remove, delete, clear, all
   */
  public removeAll(): void {
    this._canvasInFrontShifts.remove();
  }

  public preloadPatterns(): void {
    const originDataSet = this.dataHandler.getOriginDataset().ganttEntries;
    this._shiftBuildingStrategy.preloadPatterns(this, originDataSet);
  }

  public clearTimeouts(): void {
    this._shiftBuildingStrategy.clearTimeouts();
  }

  //
  // RESTRICTIONS
  //

  /**
   * Restricts shift dragging.
   * @keywords allow, forbid, restriction, drag, translation, shift
   * @param allowDragDrop Allow or forbid shift drag and drop.
   */
  public setDragDrop(allowDragDrop: boolean): void {
    this._dragDropShifts = allowDragDrop;
  }

  /**
   * @return Drag-and-Drop permission. If true, drag and drop is allowed.
   */
  public getDragDrop(): boolean {
    return this._dragDropShifts;
  }

  public getAllowShiftClicks(): boolean {
    return this._allowShiftClicks;
  }

  public setAllowShiftClicks(bool: boolean): void {
    this._allowShiftClicks = bool;
  }

  public getShiftsAreResizing(): boolean {
    return this._shiftsAreResizing;
  }

  /**
   * Set to true from a callback while a shift is resized.
   */
  public setShiftsAreResizing(boolean: boolean): void {
    this._shiftsAreResizing = boolean;
    this._shiftBuildingStrategy.resizeChange(this, boolean);
  }

  public addStopShiftHovering(...ids: string[]): void {
    for (const id of ids) {
      this._stopShiftHovering.add(id);
    }
  }

  public removeStopShiftHovering(...ids: string[]): void {
    for (const id of ids) {
      this._stopShiftHovering.delete(id);
    }
  }

  public isStopShiftHovering(): boolean {
    return this._stopShiftHovering.size > 0;
  }

  //
  // GETTER & SETTER
  //

  /**
   * Helper getter which returns the gantt config of the current gantt.
   */
  public get ganttConfig(): GanttConfig {
    return this._altGanttConfig || this._ganttDiagram.getConfig();
  }

  /**
   * Helper getter which returns the data handler of the current gantt.
   */
  public get dataHandler(): DataHandler {
    return this._ganttDiagram.getDataHandler();
  }

  /**
   * Helper getter which returns the render data handler of the current gantt.
   */
  public get renderDataHandler(): RenderDataHandler {
    return this._ganttDiagram.getRenderDataHandler();
  }

  /**
   * Helper getter which returns the HTML structure builder of the current gantt.
   */
  public get htmlStructureBuilder(): GanttHTMLStructureBuilder {
    return this._ganttDiagram.getHTMLStructureBuilder();
  }

  /**
   * Helper getter which returns the node proportions state of the current gantt.
   */
  public get nodeProportionsState(): NodeProportionsStateConnector {
    return this._nodeProportionsState;
  }

  /**
   * Helper getter which returns the shift translation chain handler of the current gantt.
   */
  public get chainHandler(): GanttShiftTranslationChain {
    return this._ganttDiagram.getShiftTranslationChain();
  }

  /**
   * Helper getter which returns the {@link PatternHandler} of the current gantt.
   */
  private get _patternHandler(): PatternHandler {
    return this._altPatternHandler || this._ganttDiagram.getShiftFacade().getPatternHandler();
  }

  /**
   * Helper getter which returns the id of the scroll container this {@link ShiftBuilder} is assigned to.
   */
  public get scrollContainerId(): EGanttScrollContainer {
    return this._scrollContainerId;
  }

  public getShiftBuildingStrategy(): IGanttShiftsBuilding {
    return this._shiftBuildingStrategy;
  }

  /**
   * Changes the shift Building strategy to a new one.
   * @param strategy Concrete implementation of Interface.
   */
  public changeShiftBuildingStrategy(strategy: IGanttShiftsBuilding): void {
    this._shiftBuildingStrategy.removeCallbacks(this);
    this._shiftBuildingStrategy = strategy;
    this._shiftBuildingStrategy.initCallbacks(this);
  }

  public getShiftCalculationStrategy(): IGanttShiftsCalculation {
    return this._shiftCalculationStrategy;
  }

  public setShiftCalculationStrategy(strategy: IGanttShiftsCalculation): void {
    this._shiftCalculationStrategy = strategy;
  }

  /**
   * Returns the current {@link GanttShiftClipPathHandler} instance or `null` if there is no instance.
   * @returns The current {@link GanttShiftClipPathHandler} instance or `null` if there is no instance.
   */
  public getClipPathHandler(): GanttShiftClipPathHandler {
    return this._clipPathHandler;
  }

  /**
   * @return Parent node.
   */
  public getParentNode(): HTMLDivElement {
    return this._parentNode.node();
  }

  /**
   * @return D3 selection of <b>svg</b> canvas infront of shiftRenderCanvas.
   */
  public getCanvasInFrontShifts(): d3.Selection<SVGSVGElement, undefined, d3.BaseType, undefined> {
    return this._canvasInFrontShifts;
  }

  /**
   * @return D3 selection of <b>svg</b> canvas nehind of shiftRenderCanvas.
   */
  public getCanvasBehindShifts(): d3.Selection<SVGSVGElement, undefined, d3.BaseType, undefined> {
    return this._canvasBehindShifts;
  }

  /**
   * @return D3 selection of the shiftRenderCanvas as <b>canvas</b>.
   */
  public getShiftRenderCanvas(): d3.Selection<HTMLCanvasElement, undefined, d3.BaseType, undefined> {
    return this._shiftRenderCanvas;
  }

  /**
   * Returns the shared svg container for rendering svg shifts.
   * @returns The shared svg container for rendering svg shifts.
   */
  public getShiftGroupOverlay(): d3.Selection<SVGGElement, undefined, d3.BaseType, undefined> {
    return this._ganttDiagram.getShiftFacade().getShiftGroupOverlay();
  }

  public getShiftWrapperOverlay(): d3.Selection<HTMLDivElement, undefined, d3.BaseType, undefined> {
    return this._ganttDiagram.getShiftFacade().getShiftWrapperOverlay();
  }

  /**
   * Returns the d3 selection of the wrapper element containing the `defs` element in the upper svg shifts layer.
   * @returns The d3 selection of the wrapper element containing the `defs` element in the upper svg shifts layer.
   */
  public getShiftDefsInFrontShifts(): d3.Selection<SVGGElement, undefined, d3.BaseType, undefined> {
    return this._shiftDefsInFrontShifts;
  }

  /**
   * Returns the d3 selection of the wrapper element containing the `defs` element in the lower svg shifts layer.
   * @returns The d3 selection of the wrapper element containing the `defs` element in the lower svg shifts layer.
   */
  public getShiftDefsBehindShifts(): d3.Selection<SVGGElement, undefined, d3.BaseType, undefined> {
    return this._shiftDefsBehindShifts;
  }

  /**
   * Returns the d3 selection of the wrapper element containing the `defs` element in the scroll container-independent svg shifts layer.
   * @returns The d3 selection of the wrapper element containing the `defs` element in the scroll container-independent svg shifts layer.
   */
  private _getShiftGroupOverlayDefs(): d3.Selection<SVGGElement, undefined, d3.BaseType, undefined> {
    return this._ganttDiagram.getShiftFacade().getShiftGroupOverlayDefs();
  }

  /**
   * @returns D3 selection of all shifts.
   */
  public getAllShifts(): d3.Selection<SVGRectElement, GanttCanvasShift, d3.BaseType, undefined> {
    return this._allShifts;
  }

  public setAllShifts(allShifts: d3.Selection<SVGRectElement, GanttCanvasShift, d3.BaseType, undefined>): void {
    this._allShifts = allShifts;
  }

  public getAllShiftContents(): d3.Selection<SVGPathElement, GanttCanvasShift, d3.BaseType, undefined> {
    return this._allShiftContents;
  }

  public setAllShiftContents(
    allShiftContents: d3.Selection<SVGPathElement, GanttCanvasShift, d3.BaseType, undefined>
  ): void {
    this._allShiftContents = allShiftContents;
  }

  public getAllShiftSecondColorOverlays(): d3.Selection<SVGPathElement, GanttCanvasShift, d3.BaseType, undefined> {
    return this._allShiftSecondColorOverlays;
  }

  public setAllShiftSecondColorOverlays(
    allShiftSecondColorOverlays: d3.Selection<SVGPathElement, GanttCanvasShift, d3.BaseType, undefined>
  ): void {
    this._allShiftSecondColorOverlays = allShiftSecondColorOverlays;
  }

  /**
   * @return Last D3 zoom transform data with x, y, k value.
   */
  public getLastZoomTransformation(): d3.ZoomTransform {
    return this._lastZoomTransformation;
  }

  public getHasBeenDragged(): boolean {
    return this._hasBeenDragged;
  }

  public setHasBeenDragged(hasBeenDragged: boolean): void {
    this._hasBeenDragged = hasBeenDragged;
  }

  public getLastDragEvent(): d3.D3DragEvent<SVGRectElement, GanttCanvasShift, undefined> {
    return this._lastDragEvent;
  }

  public setLastDragEvent(event: d3.D3DragEvent<SVGRectElement, GanttCanvasShift, undefined>): void {
    this._lastDragEvent = event;
  }

  public getSingleClickTimeout(): NodeJS.Timeout {
    return this._singleClickTimeout;
  }

  public setSingleClickTimeout(singleClickTimeout: NodeJS.Timeout): void {
    this._singleClickTimeout = singleClickTimeout;
  }

  public getPatternHandler(): PatternHandler {
    return this._patternHandler;
  }

  /**
   * @return Width and height of x axis parent node.
   */
  public getParentNodeProportions(): INodeProportion {
    if (this._parentNode.empty()) return this._lastParentNodeProportions;
    if (this.getParentNode().clientWidth > 0 && this.getParentNode().clientHeight > 0) {
      this._lastParentNodeProportions = {
        width: this.getParentNode().clientWidth,
        height: this.getParentNode().clientHeight,
      };
      return this._lastParentNodeProportions;
    } else return this._lastParentNodeProportions;
  }

  //
  // OBSERVABLE EVENTS
  //

  public onUpdateEvent(event: any): void {
    this._subjects.update.next(event);
  }

  public shiftMouseOverEvent(event: MouseEvent): void {
    this._subjects.shiftMouseOver.next(event);
  }

  public shiftMouseOutEvent(event: MouseEvent): void {
    this._subjects.shiftMouseOut.next(event);
  }

  public shiftDragStartEvent(event: IShiftDragEvent): void {
    this._subjects.shiftDragStart.next(event);
  }

  public shiftDraggingEvent(event: IShiftDragEvent): void {
    this._subjects.shiftDragging.next(event);
  }

  public manipulateShiftDragEndEvent(event: IShiftDragEvent): void {
    this._subjects.manipulateShiftDragEnd.next(event);
  }

  public shiftDragEndEvent(event: IShiftDragEvent): void {
    this._subjects.shiftDragEnd.next(event);
  }

  public afterShiftRenderEvent(event: GanttShiftsAfterRenderEvent): void {
    this._subjects.afterShiftRender.next(event);
  }

  public shiftOnMouseDownEvent(event: d3.ClientPointEvent): void {
    this._subjects.shiftOnMouseDown.next(event);
  }

  public shiftOnClickDirectlyEvent(event: IShiftClickEvent): void {
    // call next() for every Subject in array (order: from lowest to highest)
    this._subjects.shiftOnClickDirectly.forEach((elem) => elem.next(event));
  }

  public shiftOnClickEvent(event: IShiftClickEvent): void {
    this._subjects.shiftOnClick.next(event);
  }

  public shiftOnMiddleClickEvent(event: d3.ClientPointEvent): void {
    this._subjects.shiftOnMiddleClick.next(event);
  }

  public shiftAfterOnClickEvent(event: IShiftClickEvent): void {
    this._subjects.shiftAfterOnClick.next(event);
  }

  public shiftOnDoubleClickEvent(event: IShiftClickEvent): void {
    this._subjects.shiftOnDoubleClick.next(event);
  }

  public shiftAfterOnDoubleClickEvent(event: IShiftClickEvent): void {
    this._subjects.shiftAfterOnDoubleClick.next(event);
  }

  public shiftOnContextMenuEvent(event: IShiftClickEvent): void {
    this._subjects.shiftOnContextMenu.next(event);
  }

  public rowOnContextMenuEvent(event: GanttYAxisContextMenuEvent): void {
    this._subjects.rowOnContextMenu.next(event);
  }

  public blockedShiftDragStartEvent(event: IShiftDragEvent): void {
    this._subjects.blockedShiftDragStart.next(event);
  }

  public blockedShiftDraggingEvent(event: IShiftDragEvent): void {
    this._subjects.blockedShiftDragging.next(event);
  }

  public blockedShiftDragEndEvent(event: IShiftDragEvent): void {
    this._subjects.blockedShiftDragEnd.next(event);
  }

  public shiftAreaMouseMissEvent(event: MouseEvent): void {
    this._subjects.shiftAreaMouseMiss.next(event);
  }

  public shiftMouseOverShiftOnCanvasEvent(event: d3.ClientPointEvent): void {
    this._subjects.shiftMouseOverShiftOnCanvas.next(event);
  }

  public enterCanvasEvent(event: d3.ClientPointEvent): void {
    this._subjects.enterCanvas.next(event);
  }

  public canvasMouseOverEvent(event: MouseEvent): void {
    this._subjects.canvasMouseOver.next(event);
  }

  public canvasDoubleClickEvent(event: MouseEvent): void {
    this._subjects.canvasDoubleClick.next(event);
  }

  //
  // OBSERVABLES
  //

  public onUpdate(): Observable<any> {
    return this._subjects.update.asObservable();
  }

  public shiftMouseOver(): Observable<MouseEvent> {
    return this._subjects.shiftMouseOver.asObservable();
  }

  public shiftMouseOut(): Observable<MouseEvent> {
    return this._subjects.shiftMouseOut.asObservable();
  }

  public shiftDragStart(): Observable<IShiftDragEvent> {
    return this._subjects.shiftDragStart.asObservable();
  }

  public shiftDragging(): Observable<IShiftDragEvent> {
    return this._subjects.shiftDragging.asObservable();
  }

  public manipulateShiftDragEnd(): Observable<IShiftDragEvent> {
    return this._subjects.manipulateShiftDragEnd.asObservable();
  }

  public shiftDragEnd(): Observable<IShiftDragEvent> {
    return this._subjects.shiftDragEnd.asObservable();
  }

  public afterShiftRender(): Observable<GanttShiftsAfterRenderEvent> {
    return this._subjects.afterShiftRender.asObservable();
  }

  public shiftOnMouseDown(): Observable<d3.ClientPointEvent> {
    return this._subjects.shiftOnMouseDown.asObservable();
  }

  public shiftOnClickDirectly(priority = 0): Observable<IShiftClickEvent> {
    if (priority < 0) {
      console.error('Priority has to be 0 or higher!');
      return;
    }
    if (!this._subjects.shiftOnClickDirectly[priority]) {
      this._subjects.shiftOnClickDirectly[priority] = new Subject<IShiftClickEvent>();
    }
    return this._subjects.shiftOnClickDirectly[priority].asObservable();
  }

  public shiftOnClick(): Observable<IShiftClickEvent> {
    return this._subjects.shiftOnClick.asObservable();
  }

  public shiftOnMiddleClick(): Observable<d3.ClientPointEvent> {
    return this._subjects.shiftOnMiddleClick.asObservable();
  }

  public shiftAfterOnClick(): Observable<IShiftClickEvent> {
    return this._subjects.shiftAfterOnClick.asObservable();
  }

  public shiftOnDoubleClick(): Observable<IShiftClickEvent> {
    return this._subjects.shiftOnDoubleClick.asObservable();
  }

  public shiftAfterOnDoubleClick(): Observable<IShiftClickEvent> {
    return this._subjects.shiftAfterOnDoubleClick.asObservable();
  }

  public shiftOnContextMenu(): Observable<IShiftClickEvent> {
    return this._subjects.shiftOnContextMenu.asObservable();
  }

  public rowOnContextMenu(): Observable<GanttYAxisContextMenuEvent> {
    return this._subjects.rowOnContextMenu.asObservable();
  }

  public blockedShiftDragStart(): Observable<IShiftDragEvent> {
    return this._subjects.blockedShiftDragStart.asObservable();
  }

  public blockedShiftDragging(): Observable<IShiftDragEvent> {
    return this._subjects.blockedShiftDragging.asObservable();
  }

  public blockedShiftDragEnd(): Observable<IShiftDragEvent> {
    return this._subjects.blockedShiftDragEnd.asObservable();
  }

  public shiftAreaMouseMiss(): Observable<MouseEvent> {
    return this._subjects.shiftAreaMouseMiss.asObservable();
  }

  public shiftMouseOverShiftOnCanvas(): Observable<d3.ClientPointEvent> {
    return this._subjects.shiftMouseOverShiftOnCanvas.asObservable();
  }

  public enterCanvas(): Observable<d3.ClientPointEvent> {
    return this._subjects.enterCanvas.asObservable();
  }

  public canvasMouseOver(): Observable<MouseEvent> {
    return this._subjects.canvasMouseOver.asObservable();
  }

  public canvasDoubleClick(): Observable<MouseEvent> {
    return this._subjects.canvasDoubleClick.asObservable();
  }

  //
  // CALLBACKS
  //

  public addForceCanvasDoubleClickCb(id: string, cb: (event: MouseEvent) => boolean): void {
    this._forceCanvasDoubleClickCbs[id] = cb;
  }

  public removeForceCanvasDoubleClickCb(id: string): void {
    delete this._forceCanvasDoubleClickCbs[id];
  }

  public getForceCanvasDoubleClickCbs(): ((event: MouseEvent) => boolean)[] {
    return Object.values(this._forceCanvasDoubleClickCbs);
  }
}

/**
 * Event parameter for callback functions after shift render.
 * @keywords data, call, callback, event, render, transform, shift
 * @param {Selection} selection All visible shifts.
 * @param {Transform} transform D3 zoom transform data with x, y, k value.
 */
export class GanttShiftsAfterRenderEvent {
  selection: d3.Selection<any, any, d3.BaseType, undefined>;
  transform: d3.ZoomTransform;

  constructor(selection, transform) {
    this.selection = selection;
    this.transform = transform;
  }
}

export class GanttAxisLineConfiguration {
  color: string;
  thickness: number;

  constructor(color, thickness) {
    this.color = color;
    this.thickness = thickness;
  }
}
