import * as d3 from 'd3';
import { GanttCanvasShift } from '../../data-handler/data-structure/data-structure';
import { BestGantt } from '../../main';
import { PatternType } from '../../pattern/pattern-type.enum';
import { PatternHandler } from '../../pattern/patternHandler';
import { ShiftBuilder } from '../shift-builder';
import {
  EShiftFillType,
  IGanttShiftsCalculation,
  IRectBoundings,
  IRectOffset,
  IShiftFillData,
  IStrokeData,
} from './shift-calculation.interface';

/**
 * Shift calculation strategy for default shift building with one scroll container.
 */
export class GanttShiftsCalculationDefault implements IGanttShiftsCalculation {
  protected _canvasPatternCache: Map<string, CanvasPattern> = new Map<string, CanvasPattern>();

  constructor(protected _executer: ShiftBuilder, protected _ganttDiagram: BestGantt) {}

  public getShiftWidth(
    shiftData: GanttCanvasShift,
    lastZoomTransformation: d3.ZoomTransform,
    offset = 0,
    maxWidth: number = null
  ): number {
    let width = shiftData.width * lastZoomTransformation.k - offset;
    if (maxWidth && width > maxWidth) {
      // shift width is higher than maximum -> make width smaller
      const x = this.getShiftX(shiftData, lastZoomTransformation, offset);
      if (width > maxWidth) width = x + width + 50;
      if (width > maxWidth && x > 0) width = 50000;
    }
    return Math.max(width, 1); // min width of 1 px
  }

  public getShiftHeight(shiftData: GanttCanvasShift, offset = 0): number {
    return (shiftData.height || this._executer.ganttConfig.shiftHeight()) - offset;
  }

  public getShiftX(
    shiftData: GanttCanvasShift,
    lastZoomTransformation: d3.ZoomTransform,
    offset = 0,
    maxShiftWidth: number = null
  ): number {
    let x = shiftData.x * lastZoomTransformation.k + lastZoomTransformation.x + offset / 2;
    const shiftWidth = this.getShiftWidth(shiftData, lastZoomTransformation, offset);
    if (maxShiftWidth && shiftWidth > maxShiftWidth) {
      // shift width is higher than maximum -> correct x for smaller width
      if (shiftWidth > maxShiftWidth && x < 0) x = -50;
    }
    return x;
  }

  public getShiftY(shiftData: GanttCanvasShift, offset = 0): number {
    const shiftY = this._executer.renderDataHandler.getStateStorage().getYPositionShift(shiftData.id);
    return shiftY + offset / 2;
  }

  public getShiftViewportY(shiftData: GanttCanvasShift, offset = 0): number {
    return this._executer.renderDataHandler.getShiftDataFinder().getShiftViewportY(shiftData.id) + offset / 2;
  }

  public getShiftCornerRadius(shiftData: GanttCanvasShift): number {
    if (this._executer.ganttConfig.getShiftBuildingRoundedCorners() && !shiftData.noRoundedCorners) {
      return this._executer.ganttConfig.getRoundedCorners();
    }
    return null;
  }

  public getShiftStrokeColor(shiftData: GanttCanvasShift): string {
    return shiftData.strokeColor || null;
  }

  public getShiftStrokeWidth(shiftData: GanttCanvasShift): number {
    return shiftData.strokeWidth
      ? shiftData.strokeWidth
      : shiftData.strokeColor
      ? this._executer.ganttConfig.getShiftStrokeWidth()
      : 0;
  }

  public getShiftStrokeDasharray(
    shiftData: GanttCanvasShift,
    lastZoomTransformation: d3.ZoomTransform,
    maxShiftWidth: number = null
  ): number[] {
    if (this._executer.ganttConfig.getShiftStrokeLeaveRightSideOpen()) {
      const width = this.getShiftWidth(
        shiftData,
        lastZoomTransformation,
        this.getShiftStrokeWidth(shiftData),
        maxShiftWidth
      );
      const height = this.getShiftHeight(shiftData);
      const radius = this.getShiftCornerRadius(shiftData);
      return [width - radius, height - radius, width + radius + height];
    } else if (shiftData.strokePattern) {
      const strokePattern = this._executer
        .getPatternHandler()
        .getStrokePatternStorage()
        .getPatternById(shiftData.strokePattern);
      if (strokePattern) {
        return [strokePattern.getFilledArea(), strokePattern.getUnfilledArea()];
      }
    }
    return undefined;
  }

  public getShiftFill(shiftData: GanttCanvasShift): IShiftFillData {
    const basicColor = this._executer.getBasicShiftColorByCanvasShift(shiftData);
    if (shiftData.highlighted) {
      return { type: EShiftFillType.COLOR, color: shiftData.highlighted, basicColor: basicColor };
    }
    if (shiftData.selected && this.getShiftWidth(shiftData, this._executer.getLastZoomTransformation()) < 8) {
      return { type: EShiftFillType.COLOR, color: shiftData.selected, basicColor: basicColor };
    }
    if (shiftData.pattern) {
      return {
        type: EShiftFillType.PATTERN,
        color: shiftData.patternColor,
        pattern: shiftData.pattern,
        basicColor: basicColor,
      };
    }
    if (shiftData.color) {
      return { type: EShiftFillType.COLOR, color: basicColor, basicColor: basicColor };
    }
    return { type: EShiftFillType.COLOR, color: '#000000', basicColor: '#000000' };
  }

  public getShiftRectBoundings(
    shiftData: GanttCanvasShift,
    lastZoomTransformation: d3.ZoomTransform,
    offset = 0,
    additionalOffset: IRectOffset = null,
    clippingWidth: number = null,
    clippingOffset = 0
  ): IRectBoundings {
    const x = this.getShiftX(shiftData, lastZoomTransformation);
    const width = this.getShiftWidth(shiftData, lastZoomTransformation);
    let cutoffX = x,
      cutoffWidth = width;

    if (clippingWidth && clippingWidth > 0) {
      // handle clipping
      if (x < 0) {
        // handle left side clipping
        cutoffX = -clippingOffset;
        cutoffWidth += x + clippingOffset;
      }

      if (cutoffX + cutoffWidth > clippingWidth) {
        // handle right side clipping
        cutoffWidth = clippingWidth - cutoffX + clippingOffset;
      }
    }

    return {
      x: cutoffX + offset / 2 - (additionalOffset?.x || 0),
      y: this.getShiftY(shiftData, offset) - (additionalOffset?.y || 0),
      width: cutoffWidth - offset - (additionalOffset?.width || 0),
      height: this.getShiftHeight(shiftData, offset) - (additionalOffset?.height || 0),
    };
  }

  public getStrokeData(
    shiftData: GanttCanvasShift,
    leaveRightSideOpen: boolean,
    patternHandler: PatternHandler
  ): IStrokeData {
    return {
      strokeColor: this.getShiftStrokeColor(shiftData),
      strokeWidth: this.getShiftStrokeWidth(shiftData),
      strokePattern: patternHandler.getStrokePatternStorage().getPatternById(shiftData.strokePattern),
      leaveRightSideOpen: leaveRightSideOpen,
    };
  }

  public getShiftFillColorForSVG(shiftData: GanttCanvasShift): string {
    const shiftFill = this.getShiftFill(shiftData);
    if (shiftData.highlighted) return shiftFill.color;

    switch (shiftFill.type) {
      case EShiftFillType.PATTERN:
        if (shiftData.selected) {
          const interpolationOptions = {
            color: shiftData.selected,
            opacity: this._executer.ganttConfig.colorSelectOpacity(),
          };
          return this._executer
            .getPatternHandler()
            .getPatternAsUrl(shiftFill.pattern, shiftFill.basicColor, shiftFill.color, interpolationOptions);
        }
        return this._executer
          .getPatternHandler()
          .getPatternAsUrl(shiftFill.pattern, shiftFill.basicColor, shiftData.patternColor);
        break;
      case EShiftFillType.COLOR:
      default:
        if (shiftData.selected) {
          const selectColorInterpolation = d3.interpolate(
            { colors: [shiftFill.basicColor] },
            { colors: [shiftData.selected] }
          );
          return selectColorInterpolation(this._executer.ganttConfig.colorSelectOpacity()).colors[0];
        }
        return shiftFill.color;
        break;
    }
  }

  public getShiftFillColorForCanvas(
    shiftData: GanttCanvasShift,
    ctx: CanvasRenderingContext2D
  ): string | CanvasPattern {
    const shiftFill = this.getShiftFill(shiftData);

    switch (shiftFill.type) {
      case EShiftFillType.PATTERN:
        return this._getCachedCanvasPattern(
          shiftFill.pattern,
          shiftFill.basicColor,
          shiftFill.color,
          this._executer.getPatternHandler(),
          ctx
        );
        break;
      case EShiftFillType.COLOR:
      default:
        return shiftFill.color;
        break;
    }
  }

  private _getCachedCanvasPattern(
    shiftPattern: PatternType,
    shiftColor: string,
    shiftPatternColor: string,
    patternHandler: PatternHandler,
    ctx: CanvasRenderingContext2D
  ): string | CanvasPattern {
    const patternId = shiftPattern + shiftColor + shiftPatternColor;
    let pattern: string | CanvasPattern = this._canvasPatternCache.get(patternId);
    if (!pattern) {
      pattern = ctx.createPattern(
        patternHandler.getPatternAsSvgImage(shiftPattern, shiftColor, shiftPatternColor),
        'repeat'
      );
      // loading of the content of HTMLImageElement is asynchronous and can cause ctx.createPattern() to return null
      // when HTMLImageElement.complete is not true
      //    -> check if pattern could be created -> if so, use the pattern but use shift color otherwise until image is loaded
      if (!!pattern && pattern instanceof CanvasPattern) {
        this._canvasPatternCache.set(patternId, pattern);
      } else {
        pattern = shiftColor;
      }
    }
    return pattern;
  }

  public getShiftLabelHeight(shiftData: GanttCanvasShift): number {
    const minLabelHeight = this.executer.ganttConfig.getTextOverlayHeight();
    let labelHeight = minLabelHeight;

    if (shiftData.label?.labelHeight) {
      labelHeight = shiftData.label.labelHeight;
    }

    if (shiftData.label?.contentHeight && labelHeight + shiftData.label.contentHeight > shiftData.height) {
      labelHeight = shiftData.height - shiftData.label.contentHeight;
      if (shiftData.label.lineHeight) labelHeight -= labelHeight % shiftData.label.lineHeight;
    }

    return Math.max(labelHeight, minLabelHeight);
  }

  //
  // GETTER & SETTER
  //

  public get executer(): ShiftBuilder {
    return this._executer;
  }
  public set executer(executer: ShiftBuilder) {
    this._executer = executer;
  }
}
