import { tap } from 'rxjs/operators';
import { Observable, forkJoin, concat, of } from 'rxjs';
import { ButtonService } from '@app-modeleditor/components/button/button.service';

/**
 * Handles executeion of global actions.
 * Executes global actions sequential.
 */
export class GanttGlobalSendToBackendAction {
  constructor(private buttonService: ButtonService) {}

  /**
   * Sends stack of given global actions sequentially to the backend.
   * @param actions List of global actions.
   */
  public execute(actions: any[]): Observable<any[]> {
    return concat(...actions.map((action) => this.buttonService.executeActions([action])));
    // return forkJoin(
    //   // handle synch calls
    //   concat(...actions.filter(action => !action.asyncCall).map(action => this.buttonService.executeAction(action))),
    //   // handle asynch calls
    //   forkJoin(actions.filter(action => action.asyncCall).map(action => this.buttonService.executeAction(action))),
    // );
  }
}
