import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ERequestMethod, RequestService } from '@app-modeleditor/request.service';
import { BehaviorSubject, Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { IConfig } from './config.interface';
import { IconConfig } from './icon-config';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private configCache: BehaviorSubject<IConfig> = new BehaviorSubject<IConfig>(null); // stores latest config
  private iconCache: IconConfig[];

  constructor(
    private requestApi: RequestService,
    private mdIconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer
  ) {}

  /**
   * acces api of config
   * @returns IConfig
   */
  public access(): IConfig {
    return this.configCache.value;
  }

  private setConfig(conf: IConfig): void {
    // enable/disable global debug mode
    this.configCache.next(conf);
  }

  public getConfig(): Observable<IConfig> {
    return this.configCache.asObservable();
  }

  /**
   * get icon from icon registry
   * @returns IconConfig
   */
  public getIcon(key: string): IconConfig {
    if (!key) {
      return;
    }
    const convertedKey: string = key.split(':')[key.split(':').length - 1];
    return this.iconCache.find((icon: IconConfig) => icon.getName() === convertedKey);
  }

  /**
   * register icons for material usage
   * @returns void
   */
  private registerIcons(icons: IconConfig[]): void {
    icons.forEach((icon: IconConfig) => {
      if (icon.isCustom() !== true) {
        return;
      }
      this.mdIconRegistry.addSvgIconInNamespace(
        icon.getNamespace(),
        icon.getLabel(),
        this.sanitizer.bypassSecurityTrustResourceUrl(icon.getPath())
      );
    });
  }

  private loadFiles(): Observable<void> {
    return forkJoin(
      this.requestApi.call(ERequestMethod.GET, './assets/config.json'),
      this.requestApi
        .call(ERequestMethod.GET, './assets/icon-config.json')
        .pipe(
          map((iconData: any) =>
            Object.keys(iconData).map((key: string) => this._getIconConfig(iconData[key]).setName(key))
          )
        )
    ).pipe(
      map((configs: [IConfig, any]) => {
        this.setConfig(configs[0]);
        this.iconCache = configs[1];
        this.registerIcons(this.iconCache);
        return;
      })
    );
  }

  private _getIconConfig(data: any): IconConfig {
    return new IconConfig().setName(data.name).setNamespace(data.namespace).setLabel(data.label).setPath(data.path);
  }

  public async load(): Promise<void> {
    await this.loadFiles().toPromise();
    return;
  }

  public getRequestUrl(): string {
    const url = this.access()?.api.requests || '';
    if (url.includes('*')) {
      const origin = window.location.origin;
      return url.replace('*', origin);
    } else {
      return url;
    }
  }

  public getAuthUrl(): string {
    const url = this.access()?.api.auth || '';
    if (url.includes('*')) {
      const origin = window.location.origin;
      return url.replace('*', origin);
    } else {
      return url;
    }
  }

  public isLoginMethodSelectionActive() {
    return !!this.access().sso.enabled;
  }
}
