import { GanttXAxisFormat1 } from '../../x-axis/axis-formats/x-axis-format-1';
import { GanttXAxisFormat2 } from '../../x-axis/axis-formats/x-axis-format-2';
import { GanttXAxisFormat3 } from '../../x-axis/axis-formats/x-axis-format-3';
import { GanttXAxisFormatGeneral } from '../../x-axis/axis-formats/x-axis-format-general';

/**
 * Container class for gantt x axis config.
 */
export class GanttXAxisConfig {
  public format = EGanttXAxisFormat.DAY;
  public visible = true;
  public axisScrollBarPadding = 20;
  public scrollBarHeight = 15;
  public scrollBarArrowWidth = 18;
  public textAnchor = EGanttXAxisTextAnchor.MIDDLE;
  public axesFormats: GanttXAxisFormatGeneral[] = [
    new GanttXAxisFormat1(),
    new GanttXAxisFormat2(),
    new GanttXAxisFormat3(),
  ];
  public showWeekdays = false;
  public position = EGanttXAxisPosition.TOP;

  public get scrollBarY(): number {
    return this.height + this.axisScrollBarPadding;
  }
  public get height(): number {
    return this.axesFormats.reduce((totalHeight, axis) => (totalHeight += axis.height), 0);
  }
  public get containerHeight(): number {
    return this.scrollBarY + this.scrollBarHeight;
  }
}

export enum EGanttXAxisPosition {
  TOP = 'TOP',
  BOTTOM = 'BOTTOM',
}

export enum EGanttXAxisFormat {
  DAY = 'DAY',
  WEEK = 'WEEK',
}

export enum EGanttXAxisTextAnchor {
  START = 'start',
  MIDDLE = 'middle',
  END = 'end',
}
