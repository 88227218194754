import { EGanttScrollContainer } from './scroll-container.enum';

/**
 * Wrapper class for any kind of event to additionally store the scroll container the wrapped
 * event originates from.
 * @template T Type of the wrapped event.
 */
export class GanttScrollContainerEvent<T> {
  /**
   * @param source Id of the scroll container the wrapped event originates from.
   * @param event Event to be wrapped.
   */
  constructor(public source: EGanttScrollContainer, public event: T) {}
}
