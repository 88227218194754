/**
 * The type of execution for context menu actions.
 */
export enum ExecutionType {
  /**
   * Actions will be executed for the selected item.
   */
  SINGLE = 'SINGLE',
  /**
   * Actions will be executed for all selected items.
   */
  MULTI = 'MULTI',
}
