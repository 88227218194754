import { Injectable, Type } from '@angular/core';
import { Action } from '@app-modeleditor/components/button/action/action';
import { Adapter } from '@core/adapter';
import { GlobalUtils } from 'frontend/src/dashboard/global-utils';
import { Hotkey } from 'frontend/src/dashboard/model/resource/hotkey';
import { ResourceAdapter } from 'frontend/src/dashboard/model/resource/resource-adapter.service';
import { Template } from 'frontend/src/dashboard/model/resource/template';
import { ActionAdapter } from '../components/button/action-adapter.service';
import { TemplateAdapter } from './template-factory.service';
@Injectable({
  providedIn: 'root',
})
export class TemplateResourceAdapter implements Adapter<Template> {
  constructor(private resourceAdapter: ResourceAdapter, private actionFactory: ActionAdapter) {}

  adapt(item: any): Template {
    throw new Error('Method not implemented.');
  }

  inheritFrom?<T extends Template>(scope: TemplateAdapter, type: Type<T>, item: any): T {
    if (!item) {
      return null;
    }
    const t: T = this.getItem(scope, this.resourceAdapter.inheritFrom<T>(scope, type, item), item);
    return t;
  }

  private _createKeys(keys: any): Hotkey[] {
    return (keys || []).map((key) =>
      new Hotkey()
        .setCtrlKey(key.ctrlKey)
        .setShiftKey(key.shiftKey)
        .setAltKey(key.altKey)
        .setStopDefault(key.stopDefault)
        .setKeyCode(key.keyCode)
        .setActions((key.actions || []).map((a) => this.actionFactory.parseAction(Action, a)))
    );
  }

  private getItem<T extends Template>(scope: TemplateAdapter, element: T, item: any): T {
    element
      .setParentId(item.parentId)
      .setRestUrl(item.restUrl)
      .setOnFrontendValueChangeActions(
        (item.onFrontendValueChangeActions || []).map((v) => this.actionFactory.parseAction<Action>(Action, v))
      )
      .setSaveOrder(item.saveOrder)
      .setPreventUnregister(item.preventUnregister)
      .setVisibilityConditions(item.visibilityConditions)
      .setType(item.type)
      .setGetDataAutomatically(item.getDataAutomatically)
      .setUuid(item.uuid || GlobalUtils.generateUUID())
      .setEditable(item.editable)
      .setDisabled(item.disabled)
      .setDoNotTrackChanges(item.doNotTrackChanges)
      .setResizeMode(item.resizeMode || element.getResizeMode())
      .setAlwaysEnabled(item.alwaysEnabled)
      .setUpdateOnChange(item.updateOnChange)
      .setShow(item.show)
      .setCompact(item.compact)
      .setFieldIdentifier(item.fieldIdentifier)
      .setEnabled(item.enabled)
      .setUpdateTemplateRestUrl(item.updateTemplateRestUrl)
      .setTemplateMode(item.templateMode)
      .setCanonicalName(item.canonicalName)
      .setSticky(item.sticky)
      .setEnableConditions(item.enableConditions)
      .setFileUploadActions((item.fileUploadActions || []).map((a) => this.actionFactory.parseAction(Action, a)))
      .setDirectoryUploadActions(
        (item.directoryUploadActions || []).map((a) => this.actionFactory.parseAction(Action, a))
      );

    if (item.hotkeys) {
      element.setHotkeys(this._createKeys(item.hotkeys));
    }

    if (item.droppedZoneActions) {
      const actions: { [key: string]: Action[] } = {};
      Object.keys(item.droppedZoneActions).forEach((key: string) => {
        actions[key] = item.droppedZoneActions[key].map((a) => this.actionFactory.parseAction<Action>(Action, a));
      });
      element.setDropZoneActions(actions);
    }

    // set context menu
    if (item.contextMenu && scope) {
      element.setContextmenu(scope.adapt(item.contextMenu));
    }

    // if ()

    return element;
  }

  applyValues<T extends Template>(scope: TemplateAdapter, item: T, values: any): T {
    throw new Error('Method not implemented.');
  }

  public inherit<T extends Template>(type: Type<T>, data: any): T {
    if (!data) {
      return null;
    }

    const t: T = this.getItem(null, this.resourceAdapter.inherit(type, data), data);

    return t;
  }
}
