import { HttpClient } from '@angular/common/http';
import { ElementRef, Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ContextMenuItem } from '@app-modeleditor/components/contextmenu/context-menu-item';
import { ContextmenuService } from '@app-modeleditor/components/contextmenu/contextmenu.service';
import { EntryElementValue } from '@app-modeleditor/components/entry-collection/entry-element-value';
import { UiService } from '@app-modeleditor/ui.service';
import { EMessageType, Message } from '@core/message/message';
import { MessageService } from '@core/message/message.service';
import { Routing } from '@core/navigation/routing';
import { Notification } from '@core/notification/notification';
import { TranslateService } from '@ngx-translate/core';
import { FreetextFilter } from 'frontend/src/dashboard/core/filter/freetext-filter.enum';
import { SharedUiService } from 'frontend/src/dashboard/model-editor/ui/service/shared.ui.service';
import { ETemplateType } from 'frontend/src/dashboard/model/resource/template-type';
import { GridService } from 'frontend/src/dashboard/moving-grid/grid.service';
import { ConfirmDialog } from 'frontend/src/dashboard/shared/dialogs/confirm.dialog';
import { UserService } from 'frontend/src/dashboard/user/data-access/user.service';
import { User } from 'frontend/src/dashboard/user/user';
import { Tenant } from 'frontend/src/dashboard/view/template-footer/default-footer-components/tenant-footer/tenant';
import { WindowService } from 'frontend/src/dashboard/view/window/window.service';
import { BehaviorSubject, Observable, Observer, Subject, of, throwError } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { Action } from '../button/action/action';
import { ContextMenu } from '../contextmenu/contextmenu';
import { TemplateDialogService } from '../dialog/dialog.service';
import { EntryCollection } from '../entry-collection/entry-collection';
import { EntryElement } from '../entry-collection/entry-element';
import { Wizard } from '../lightbox/predefined/wizard/wizard';
import { HierarchicalMenuItem } from '../template-ui/hierarchical-menu-item';
import { TemplateUiService } from '../template-ui/template.service';
import { UserTenantService } from './../../../view/template-footer/default-footer-components/tenant-footer/tenant.service';
import { TemplateAdapter } from './../../utils/template-factory.service';
import { LightboxComponent } from './../lightbox/lightbox.component';
import { LightboxService } from './../lightbox/lightbox.service';
import { ConfirmLightbox } from './../lightbox/predefined/confirm-lightbox';
import { NamedLightbox } from './../lightbox/predefined/named-lightbox';
import { IContextmenuActions } from './contextmenu-actions.interface';
import { Menu } from './menu';
import { CreateModalComponent } from './modal/create-modal/create.modal';
import { HMI } from './tree-item';
import { IHierarchicItem, IHierarchicMenuItem } from './tree.model';
import { IUpdateTreeOptions } from './update-tree-options';
@Injectable({
  providedIn: 'root',
})
export class TemplateTreeService {
  public onResize: Observable<any>; // tree resize event
  public resizeSubject: Subject<void>;
  public removeFreetextChange: Observable<IHierarchicMenuItem | IHierarchicItem>;
  private removeFreetextSubject: Subject<IHierarchicMenuItem | IHierarchicItem>;
  private tree: BehaviorSubject<Menu> = new BehaviorSubject<Menu>(null);

  public onForceClick: Subject<any | any> = new Subject<IHierarchicMenuItem | IHierarchicItem>(); // force click on tree node
  public onCreateElement: Subject<void> = new Subject<void>(); // create element trigger
  public onDeleteElement: Subject<IHierarchicMenuItem> = new Subject<IHierarchicMenuItem>(); // delete element trigger
  public onDuplicateElement: Subject<void> = new Subject<void>(); // delete element trigger
  public onFilterChange: Subject<any> = new Subject<any>(); // filter change trigger
  public onRefresh: Subject<Notification> = new Subject<Notification>();
  private currentSelectedHMI: BehaviorSubject<HierarchicalMenuItem> = new BehaviorSubject<HierarchicalMenuItem>(null);
  private currentSelectedNode: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private _navigation: BehaviorSubject<Routing> = new BehaviorSubject<Routing>(null);
  private _currentContent: BehaviorSubject<HierarchicalMenuItem> = new BehaviorSubject<HierarchicalMenuItem>(null);
  onNotificationCount: Subject<any> = new Subject<any>();
  private nodes: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  private path: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  private currentUser: User;

  constructor(
    public gridService: GridService,
    public sharedUiService: SharedUiService,
    private uiService: UiService,
    private dialog: MatDialog,
    private window: WindowService,
    private http: HttpClient,
    private translate: TranslateService,
    private lightboxApi: LightboxService,
    private tDialogService: TemplateDialogService,
    private templateFactory: TemplateAdapter,
    private _userTenantApi: UserTenantService,
    private templateUiService: TemplateUiService,
    private _userApi: UserService,
    private messageApi: MessageService,
    private contextMenuApi: ContextmenuService
  ) {
    this.removeFreetextSubject = new Subject<any>();
    this.resizeSubject = new Subject<any>();
    this.onResize = this.resizeSubject.asObservable();
    this.removeFreetextChange = this.removeFreetextSubject.asObservable();

    this._userTenantApi.selectedTenantChanged().subscribe(() => {
      this.updateTree({ skipClick: this._skipClick });
    });
    this._userApi.getUser().subscribe((user: User) => {
      this.currentUser = user;
    });
    const id: string = this._userTenantApi.beforeSelectionChanges((newTenants: Tenant[], curTenants: Tenant[]) =>
      this._beforeTenantChanges(newTenants, curTenants)
    );
  }
  private _skipClick: boolean;

  /**
   * intercepts execution of tenant selection
   */
  private _beforeTenantChanges(newTenants: Tenant[], curTenants: Tenant[]): Observable<void> {
    this._skipClick = false;
    if (!this._currentContent.getValue()) {
      return of(null);
    }
    const tenantId: string = this._currentContent.getValue()?.getTenantId();

    if (!tenantId) {
      return of(null);
    }

    const tenantName: string = curTenants.find((t: Tenant) => t.getId() === tenantId)?.getName();
    const available: boolean =
      this.currentUser?.getTenantId() === tenantId || newTenants.find((t: Tenant) => tenantId === t.getId())
        ? true
        : false;
    // if tenant is available in newly selected tenants, skip and execute chain as expected
    if (available) {
      return of(null);
    }

    return this.templateUiService
      .checkContextChange(this._currentContent.getValue(), {
        title: `${tenantName} ausblenden`,
        text: `Sie sind dabei ${tenantName} auszublenden, es wurden jedoch noch nicht gespeicherten Änderungen erkannt.`,
      })
      .pipe(
        switchMap((change: boolean) => {
          // do nothing and throw error to stop tenants from changing
          if (!change) {
            return throwError('do not change tenant');
          }

          // set content to null because tenant changed
          this._skipClick = true;
          this.forceClick(null);
          return of(null);
        })
      );
  }

  setNotificationCount(id: string, count: string): void {
    this.onNotificationCount.next({ id, count });
  }

  contextmenuActions: IContextmenuActions = {
    create: false,
    edit: false,
    duplicate: false,
    delete: false,
  };

  public setTree(tree: Menu): void {
    this.tree.next(tree);
  }

  public getTree(): Observable<Menu> {
    return this.tree.asObservable();
  }

  getCurrentSelectedNode(): HierarchicalMenuItem {
    return this.currentSelectedNode.getValue();
  }

  handleRightClick(node: any): void {
    // create
    this.contextmenuActions.create =
      node.createRestUrls ||
      (node.handlingType &&
        (node.handlingType === 'WIDGET_VIEWS' ||
          node.handlingType === 'WIDGET_VIEWS_BDE' ||
          node.handlingType === 'PROCESS_VIEW'))
        ? true
        : false;

    // clone
    this.contextmenuActions.duplicate = node.cloneRestUrl ? true : false;

    // delete
    this.contextmenuActions.delete = node.deleteRestUrl || node.type === 'tab' ? true : false;

    // edit
    this.contextmenuActions.edit = node.editRestUrl ? true : false;
  }

  private getAction(ctx, node, key): ContextMenuItem {
    const btn: ContextMenuItem = new ContextMenuItem()
      .setName(`BUTTON.${key}`)
      .setDisabled(this.contextmenuActions[key] === false ? true : false);
    switch (key) {
      case 'delete':
        btn.setIcon('delete');
        btn.chainActions(new Action(this.deleteElement.bind(this, node)), new Action(this.closeCtx.bind(this, ctx)));
        break;
      case 'edit':
        btn.setIcon('edit');
        break;
      case 'create':
        btn.setIcon('add');
        btn.chainActions(new Action(this.createElement.bind(this, node)), new Action(this.closeCtx.bind(this, ctx)));
        break;
      case 'duplicate':
        btn.setIcon('filter_none');
        btn.chainActions(new Action(this.duplicateElement.bind(this, node)), new Action(this.closeCtx.bind(this, ctx)));
        break;
    }
    return btn;
  }

  closeCtx(ctx: ContextMenu): Observable<any> {
    return of(ctx.getRef().dispose());
  }

  /**
   * open template node in a fullscreen dialog
   * @param node Node
   */
  openFullscreen(node: any): Observable<any> {
    // only for testing
    // node.content.contentParts[0].displayContentpartContainer = false;
    // node.content.contentParts[0].enableFullscreen = true;

    return (!node.restUrl ? of(null) : this.uiService.getFromUrl(node.restUrl)).pipe(
      tap((template) => {
        this.forceClick();
        this.tDialogService.openDialog(template || node, { fullscreen: true });
      })
    );
  }

  private _updateTree: Subject<IUpdateTreeOptions> = new Subject<IUpdateTreeOptions>();

  updateTree(options: IUpdateTreeOptions = { skipClick: false }): void {
    this._updateTree.next(options);
  }

  onUpdateTree(): Observable<IUpdateTreeOptions> {
    return this._updateTree.asObservable();
  }

  onContextMenu(event: MouseEvent, node: HierarchicalMenuItem, el: ElementRef | MouseEvent | HTMLElement): void {
    this.handleRightClick(node);
    const ctxMenu: ContextMenu = new ContextMenu();

    // add actions to contextmenu
    ctxMenu.addContextMenuItems(
      ...Object.keys(this.contextmenuActions).map((key: string) => this.getAction(ctxMenu, node, key))
    );

    ctxMenu.addContextMenuItems(
      new ContextMenuItem()
        .setName('BUTTON.open_fullscreen')
        .setIcon('FULLSCREEN')
        .setDisabled(!node.getContent())
        .chainActions(new Action(this.openFullscreen.bind(this, node)), new Action(this.closeCtx.bind(this, ctxMenu)))
    );

    if ((node.getRestUrl() && node.getType() === ETemplateType.MENU_ITEM) || node.getContent()) {
      ctxMenu.addContextMenuItems(
        new ContextMenuItem()
          .setName('BUTTON.open_window')
          .setIcon('open_in_new')
          .chainActions(
            new Action(this.openExternalWindow.bind(this, node)),
            new Action(this.closeCtx.bind(this, ctxMenu))
          )
      );
    }

    this.contextMenuApi.create(el, ctxMenu);
  }

  public getNodeById(nodeId: string, nodes: HierarchicalMenuItem[]) {
    for (const node of nodes) {
      if (node.getId() === nodeId) {
        return node;
      }
      const match: HierarchicalMenuItem = this.getNodeById(nodeId, node.getHierachicMenuItems());
      if (match) {
        return match;
      }
    }
    return null;
  }

  getParent(node: HMI): HMI {
    return this.getNodeById(node.getParentId(), this.nodes.getValue());
  }

  public setNodes(nodes: any): void {
    this.nodes.next(nodes);
  }

  public getNodes(): Observable<any[]> {
    return this.nodes.asObservable();
  }

  /**
   * will be executed as soon as the current routing was changed
   * @returns Observable<Routing>
   */
  public onCurrentRouteChanged(): Observable<Routing> {
    return this._navigation.asObservable();
  }

  public getCurrentHMI(): Observable<HierarchicalMenuItem> {
    return this.currentSelectedHMI.asObservable();
  }

  public setCurrentHMI(hmi: HierarchicalMenuItem): void {
    this.currentSelectedHMI.next(hmi);
  }

  public setCurrentNode(node: any): void {
    this.currentSelectedNode.next(node);
  }

  public setContent(node: HierarchicalMenuItem): void {
    this._currentContent.next(node);
  }

  /**
   * get hierarchical list of node ids
   * @param node Node
   * @returns string[]
   */
  private getListOfNodes(node): string[] {
    const listOfNodes: string[] = [];
    let cur = node;
    while (cur) {
      listOfNodes.push(cur.id);
      cur = this.getParent(cur);
    }

    return listOfNodes.reverse();
  }

  /**
   * @deprecated use function in global utils instead
   */
  public transformUrl(node, url: string, additionalAttributes?: { [key: string]: any }): string {
    // find all keys
    const keys: string[] = [];
    let l = url.slice();
    while (true) {
      const start = l.indexOf('{');
      const end = l.indexOf('}');
      const k = l.substr(start + 1, end - (start + 1));
      if (!k) {
        break;
      }
      keys.push(k);
      l = l.substr(end + 1, l.length - (end + 1));
    }

    keys.forEach((key: string) => {
      if (node[key] === null || node[key] === undefined) {
        // check if additional arguments are given
        if (additionalAttributes && additionalAttributes[key] !== null && additionalAttributes[key] !== undefined) {
          url = url.replace(`{${key}}`, additionalAttributes[key]);
        }
        return;
      }
      url = url.replace(`{${key}}`, node[key]);
    });
    return url;
  }

  public generateCurrentPath(selectedNode: HMI = null): string[] {
    const _selectedNode = selectedNode || this.currentSelectedNode.value;
    const path: string[] = []
      .concat(this.getListOfNodes(this.currentSelectedHMI.value))
      .concat(
        _selectedNode
          ? _selectedNode.type === 'MenuItem'
            ? this.transformUrl(_selectedNode, _selectedNode.restUrl)
            : []
          : []
      );
    return path;
  }

  public getPredeliveryActions(): string[] {
    return this.currentSelectedNode
      .getValue()
      ?.getPreDeliveryActions()
      ?.map((a) => JSON.parse(JSON.stringify(a)));
  }

  public getCurrentNode(): Observable<any> {
    return this.currentSelectedNode.asObservable();
  }

  public refresh(notification?: Notification): void {
    this.onRefresh.next(notification);
  }

  /**
   * navigates to specific tree item
   * @param {Routing} curRoute current route
   * @returns void
   */
  public navigateTo(curRoute: Routing): void {
    this._navigation.next(curRoute);
  }

  public resizeTree(): void {
    this.resizeSubject.next();
  }

  public onTreeResized(): Observable<void> {
    return this.resizeSubject.asObservable();
  }

  public getTemplate(node: any): Observable<any> {
    if (!node.restUrl) {
      return of(null);
    }

    return this.http.get(node.restUrl);
  }

  getWizardData(url: string): Observable<any> {
    return this.uiService.getData(url).pipe(
      map((wizardData) => {
        return { wizard: wizardData };
      })
    );
  }

  private create(observer, node): Observable<any> {
    const entries: EntryElement[] = this.currentLightbox
      ? (
          this.currentLightbox.getContent().getContentParts()[0].getContentElements()[0] as EntryCollection
        ).getEntryElements()
      : [];
    const nameInput: EntryElement = entries.find((e: EntryElement) => e.getId() === 'name-input');
    const typeBox: EntryElement = entries.find((e: EntryElement) => e.getId() === 'type-input');
    const tenantSelector: EntryElement = entries.find((e: EntryElement) => e.getId() === 'tenant-selector');

    const type: string = typeBox
      ? node.createRestUrls[typeBox.getValue<EntryElementValue>().getValue<string>()]
      : node.createRestUrls[Object.keys(node.createRestUrls)[0]];

    const tenant: string = tenantSelector
      ?.getValue<EntryElementValue>()
      .getValue<EntryElementValue>()
      .getValue<string>();

    return this._create(
      observer,
      nameInput ? nameInput.getValue<EntryElementValue>().getValue<string>() : null,
      type,
      node,
      tenant
    ).pipe(
      tap(() => {
        if (this.currentLightbox) {
          this.currentLightbox.getRef().close();
        }
      })
    );
  }

  private currentLightbox: NamedLightbox;

  /**
   * create element
   * @param node IHierarchicMenuItem
   * @returns void
   */
  public createElement(node: HierarchicalMenuItem): Observable<any> {
    return this.templateUiService.checkContextChange(node).pipe(
      switchMap((change: boolean) => {
        if (!change) {
          return of('no changes!');
        }

        if (node.getCreateWizardRestUrls()) {
          if (Object.keys(node.getCreateWizardRestUrls()).length > 1) {
            // this.lightboxApi.open(Wizard)
            const di: MatDialogRef<CreateModalComponent> = this.dialog.open(CreateModalComponent, {
              data: {
                entries: node.getCreateWizardRestUrls(),
              },
            });
            return di.beforeClosed().pipe(
              switchMap((responseUrl) => {
                return this.getWizardData(responseUrl).pipe(
                  switchMap((d) => {
                    const w: Wizard = this.templateFactory.adapt(d.wizard);
                    return this.lightboxApi.open(w).afterClosed();
                  })
                );
              })
            );
          } else {
            const url: string = node.getCreateWizardRestUrls()[Object.keys(node.getCreateWizardRestUrls())[0]];
            return this.getWizardData(url).pipe(
              switchMap((d) => {
                const w: Wizard = this.templateFactory.adapt(d.wizard);
                return this.lightboxApi.open(w).afterClosed();
              })
            );
          }
        }
        return new Observable<any>((observer: Observer<any>) => {
          // new
          const availableValues: string[] = Object.keys(node.getCreateRestUrls());
          const lbl: string = node.isNameRequired() ? 'Name' : null;
          const cb = () => this.create(observer, node);
          if (availableValues.length <= 1 && !lbl) {
            this.currentLightbox = null;
            cb().subscribe();
          } else {
            this.currentLightbox = new NamedLightbox(cb, lbl, availableValues, node.isNameRequired());
            // tenant stuff
            if (node.isMultitenant() === false) {
              this._userTenantApi.getTenantsForResource().subscribe((tenants: Tenant[]) => {
                const selected = tenants.find((t: Tenant) => this.currentUser.getTenantId() === t.getId());
                this.currentLightbox?.addTenantSelector(tenants);
              });
            }
            const ref: MatDialogRef<LightboxComponent> = this.lightboxApi.open(this.currentLightbox);
            this.currentLightbox.setName(
              this.translate.instant('FORMS.ITEMS.CREATE_BY_VALUE', {
                value: node.getName(),
              })
            );
            ref
              .afterClosed()
              .pipe(take(1))
              .subscribe(() => {
                observer.complete();
                this.currentLightbox = null;
              });
          }
        });
      })
    );
  }

  public getVisibleNodes(nodes: HMI[], map: { [key: string]: boolean }): { [key: string]: boolean } {
    if (!nodes) {
      return;
    }
    nodes.map((node) => {
      map[node.getId()] = node.isExpanded();
      this.getVisibleNodes(node.getHierachicMenuItems(), map);
      return node;
    });

    return map;
  }

  private _create(observer: Observer<any>, name: string, id: string, node: any, tenant?: string) {
    return this.uiService.getData(id, name ? { name: name || '', tenant: tenant || '' } : null).pipe(
      tap((newElement) => {
        this.onCreateElement.next();

        this.messageApi.show(
          new Message()
            .setType(EMessageType.SUCCESS)
            .setText(
              this.translate.instant('SYSTEM.MESSAGE.CREATE.MESSAGE', {
                value: name || node.name || '',
              })
            )
            .setDuration(7500)
            .setTitle('DIALOG.CREATE.title')
        );
        this.getPath(newElement.canonicalName, newElement.resourceId).subscribe((result) => {
          observer.next(newElement);
        });
      })
    );
  }

  /**
   * force click on element
   * @param element IHierarchicMenuItem | IHierarchicItem
   * @param menu MatMenuTrigger
   * @returns void
   */
  public forceClick(element = null): void {
    this.onForceClick.next({ element });
  }

  public deleteCurrentElement(): Observable<any> {
    return this.deleteElement(this.currentSelectedNode.value);
  }

  /**
   * delete element
   * @param node IHierarchicMenuItem
   * @returns void
   */
  public deleteElement(node: HMI): Observable<any> {
    if (!node || !node.getDeleteRestUrl()) {
      return of(null);
    }
    // const templateId: string =

    return new Observable<any>((observer: Observer<any>) => {
      this.templateUiService.checkContextChange(node).subscribe((change: boolean) => {
        if (!change) {
          observer.next('no changes!');
          observer.complete();
          return;
        }

        const l: ConfirmLightbox = new ConfirmLightbox(
          this.translate.instant('BUTTON.delete_by_value', {
            name: node.getName() || '',
          })
        );
        l.setCustomCancelAction(() => {
          const e = new Error();
          e.message = 'Aktion abgebrochen.';
          e.name = 'Löschen';
          observer.error(e);
          return of(null);
        }).setCustomConfirmAction(() => {
          return this.uiService
            .deleteData(
              this.transformUrl(node, node.getDeleteRestUrl(), {
                hierarchicalMenuItemId: this.currentSelectedHMI.getValue().getId(),
                templateId: this.tree.getValue().getId(),
              })
            )
            .pipe(
              catchError((e) => {
                observer.error(e);
                observer.complete();
                return throwError(new Error('err'));
              }),
              tap((r) => {
                this.onDeleteElement.next(node as any);
                observer.next(r);
                observer.complete();
              })
            );
        });
        this.lightboxApi.open(l);
      });
    });
  }

  public openDialog(title: string, hasLabel?: boolean): Observable<any> {
    const dialogRef = this.dialog.open(ConfirmDialog, {
      data: {
        title: title,
        text: '',
        hasLabel: hasLabel,
      },
    });

    return dialogRef.afterClosed();
  }

  /**
   * duplicate element
   * @param node IHierarchicMenuItem
   * @returns void
   */
  public duplicateElement(node: HMI): Observable<any> {
    if (!node.getCloneRestUrl()) {
      return throwError('no cloneRestUrl!');
    }

    const nameRequired = (node as HierarchicalMenuItem).isNameRequired
      ? (node as HierarchicalMenuItem).isNameRequired()
      : true;

    const l: NamedLightbox = new NamedLightbox(
      () => {
        return this.uiService
          .getData(this.transformUrl(node, node.getCloneRestUrl()), {
            name: l.getNameValue().getValue() || null,
          })
          .pipe(
            switchMap((newElement: IHierarchicMenuItem) => {
              this.onDuplicateElement.next();
              return this.getPath(newElement.canonicalName, newElement.resourceId).pipe(
                map((result) => {
                  l?.getRef()?.close();
                  return newElement;
                })
              );
            })
          );
      },
      this.translate.instant('BUTTON.duplicate_by_value', {
        name: node.getName() || '',
      }),
      [],
      nameRequired
    );

    this.lightboxApi.open(l);
    return l.getRef().afterClosed();

    // return new NameDialog(this.tDialog, this.translate.instant('BUTTON.duplicate_by_value', { name: node.getName() || '' })).open().getRef().afterClosed().pipe(switchMap((result: IDialogResult) => {
    //   if (result && result.type === 'confirm') {
    //     return this.uiService.getData(this.transformUrl(node, node.getCloneRestUrl()), { name: result.value.name || null }).pipe(switchMap((newElement: IHierarchicMenuItem) => {
    //       this.onDuplicateElement.next();
    //       return this.getPath(newElement.canonicalName, newElement.resourceId).pipe(map((result) => {
    //         return newElement;
    //       }));
    //     }));
    //   } else {
    //     return of(null);
    //   }
    // }));
  }

  /**
   * open external window
   * @param node IHierarchicMenuItem
   * @returns void
   */
  public openExternalWindow(node: IHierarchicMenuItem | IHierarchicItem, type: 'window' | 'tab' = 'window'): void {
    this.window.openWindow({ content: node, type, language: this.currentUser?.getLanguage() });
  }

  /**
   * get list size
   * @param element IHierarchicItem
   * @returns Observable<number>
   */
  public getListsize(element: IHierarchicItem): Observable<number> {
    if (!element.restListSizeUrl) {
      return throwError('no restListSizeUrl');
    }

    if (element.lastFilterType == FreetextFilter.COMPLEX_FILTER && element.filterSearchUrl) {
      return this.uiService.listSizeFromFilter(element.filterSearchUrl, element.filters);
    } else if (element.lastFilterType === FreetextFilter.FREETEXT_FILTER && element.freetextSearchUrl) {
      return this.uiService.listSize(element.freetextSearchUrl, element.filterString);
    } else {
      return this.uiService.getFromUrl(element.restListSizeUrl);
    }
  }

  /**
   * get chunks of date
   * @param element IHierarchicItem
   * @param offset number
   * @param listsize number
   * @return Observable<any>
   */
  public getItems(element: IHierarchicItem, offset: number, listsize: number): Observable<any> {
    if (element.lastFilterType == FreetextFilter.COMPLEX_FILTER && element.filterSearchUrl) {
      return this.uiService.postToUrl(element.filterSearchUrl, element.filters, offset, listsize);
    } else if (element.lastFilterType === FreetextFilter.FREETEXT_FILTER && element.freetextSearchUrl) {
      return this.uiService.getFromUrl(element.freetextSearchUrl, offset, listsize, element.filterString);
    } else {
      return this.uiService.getFromUrl(element.restUrl, offset, listsize);
    }
  }

  getPath(canonicalName: string, resourceId: string): Observable<any> {
    return this.http.get(`rest/template/{templateId}/path/${canonicalName}/${resourceId}`).pipe(
      map((result: any) => {
        if (result) {
          const routing: Routing = new Routing()
            .setRoutes(
              result.route
                .slice(1)
                .map((el) => el.id)
                .concat(result.targetContent ? [] : result.template.restUrl)
            )
            .setTargetContent(result.targetContent);
          this.navigateTo(routing);
        }
        return result;
      })
    );
  }
}
