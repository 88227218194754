export class ErrorStack {
  private stack: CustomEventLog[];
  private limit: number;

  constructor() {
    this.limit = -1;
  }

  /**
   * set storage limit
   * @param limit number
   * @returns ErrorStack
   */
  public setLimit(limit: number): ErrorStack {
    this.limit = limit || -1;
    return this;
  }

  /**
   * add a new event
   * @param event Event
   * @returns ErrorStack
   */
  public add(mouseDownEvent: Event, MouseUpEvent: Event): ErrorStack {
    if (!this.stack) {
      this.stack = [];
    }
    this.stack.unshift(
      new CustomEventLog()
        .setMouseDownElement(mouseDownEvent.target['outerHTML'])
        .setMouseUpElement(MouseUpEvent.target['outerHTML'])
    );
    this.trimStack();
    return this;
  }

  /**
   * get all events
   * @returns Event[]
   */
  public get(): CustomEventLog[] {
    return this.stack || [];
  }

  /**
   * ensures stack limit
   */
  private trimStack(): ErrorStack {
    if (this.limit === -1) {
      return this;
    }

    if (this.stack && this.stack.length > this.limit) {
      this.stack = this.stack.slice(0, this.limit);
    }

    return this;
  }
}

export class CustomEventLog {
  private mouseUpElement: string;
  private mouseDownElement: string;

  public getMouseUpElement(): string {
    return this.mouseUpElement;
  }

  public setMouseUpElement(mouseUpElement: string): this {
    this.mouseUpElement = mouseUpElement;
    return this;
  }

  public getMouseDownElement(): string {
    return this.mouseDownElement;
  }

  public setMouseDownElement(mouseDownElement: string): this {
    this.mouseDownElement = mouseDownElement;
    return this;
  }
}
