/**
 * Description to edit a step inside backend.
 */
export class StepUpdateItem {
  type: ENodeNetworkStepUpdateType;
  objectId: string;
  stepName: string;
  processName: string;
  hierarchicalProcessName: string;
  stepClazz: string;
  processClazz: string;

  edge: Edge;
}

/**
 * Description add a connection inside backend.
 */
export class Edge {
  idFrom: string;
  idTo: string;
}

/**
 * All edit possibilities for nodenetwork steps.
 */
export enum ENodeNetworkStepUpdateType {
  ADD_STEP,
  DELETE_STEP,
  EDIT_STEP,
  ADD_EDGE,
  REMOVE_EDGE,
  EDIT_EDGE,
}

/**
 * All edit possibilities for connections.
 * The product means a colleciton of edges and nodes.
 */
export enum ENodeNetworkProductUpdateType {
  ADD_PRODUCT,
  DELETE_PRODUCT,
  ADD_EDGE,
  REMOVE_EDGE,
}
