import { Injectable, Type } from '@angular/core';
import { TemplateAdapter } from '@app-modeleditor/utils/template-factory.service';
import { Adapter } from '@core/adapter';
import { EDisplayOrientation } from '../content/content-element/display-orientation.enum';
import { ContentElementAdapter } from '../structure/content-element-adapter.service';
import { MenuAdapter } from '../tree/menu-factory.service';
import { HMI } from '../tree/tree-item';
import { ListSelector } from './list-selector';
import { ListSelectorElement } from './list-selector-element';

@Injectable({
  providedIn: 'root',
})
export class ListSelectorAdapterService implements Adapter<ListSelector> {
  constructor(private contentElementAdapter: ContentElementAdapter, private menuAdapter: MenuAdapter) {}
  adapt(item: any): ListSelector {
    throw new Error('Method not implemented.');
  }
  inherit?<T>(type: Type<any>, item: Record<string, any>): T {
    throw new Error('Method not implemented.');
  }
  inheritFrom?<T extends ListSelector>(scope: TemplateAdapter, type: Type<T>, item: Record<string, any>): T {
    const t: T = this.contentElementAdapter
      .inheritFrom(scope, type, item)
      .setMultiSelect(item.multiSelect)
      .setDisplayOrientation(item.displayOrientation || EDisplayOrientation.VERTICAL)
      .setSelectAllOption(item.setSelectAllOption)
      .setSelectedValues(item.selectedValues || {})
      .setElementData(item.elementData || {});
    return t;
  }
  applyValues<T>(scope: TemplateAdapter, item: ListSelector, values: Record<string, any>[]): T {
    const input = values.map((elem) => {
      const t = this.menuAdapter
        .getHMI(ListSelectorElement, elem, scope)
        .setFloatLeft(elem.floatLeft)
        .setFloatRight(elem.floatRight)
        .setResourceClazz(elem.resourceClazz)

        .setSelected(elem.selected)
        .setFloatLeftLines(elem.floatLeftLines)
        .setFloatRightLines(elem.floatRightLines)
        .setSimpleElement(elem.simpleElement);

      if (elem.floatFull) {
        t.setFloatFull(elem.floatFull);
      }
      return t;
    });

    item.setValue(input);
    return;
  }
}
