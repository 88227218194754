import * as d3 from 'd3';
import { Observable, Subject, takeUntil } from 'rxjs';
import { GanttConfig } from '../../config/gantt-config';
import { GanttUtilities } from '../../gantt-utilities/gantt-utilities';
import { GanttHTMLStructureBuilder } from '../html-structure-builder';
import { NodeProportionsState } from '../node-proportion-state/node-proportion-state';
import { EGanttScrollContainer } from '../scroll-container.enum';

/**
 * Size handler trigger to change width of y axis/shift space inside gantt.
 * @keywords size, yaxis, y axis, width, change, resize
 */
export class GanttSizeHandler {
  private _parentNode: d3.Selection<HTMLDivElement, undefined, d3.BaseType, undefined> = undefined;
  private _verticalSizeHandle: d3.Selection<HTMLDivElement, undefined, d3.BaseType, undefined> = undefined;
  private _horizontalSizeHandle: d3.Selection<HTMLDivElement, undefined, d3.BaseType, undefined> = undefined;

  private _onDestroySubject: Subject<void> = new Subject<void>();

  private _afterYAxisResizeEndSubject: Subject<number> = new Subject<number>();

  /**
   * @param _config The Gantt Config.
   * @param _nodeProportionState Handles the HTML node proportions of gantt diagram.
   * @param _htmlStructureBuilder
   */
  constructor(
    private _config: GanttConfig,
    private _nodeProportionState: NodeProportionsState,
    private _htmlStructureBuilder: GanttHTMLStructureBuilder
  ) {}

  /**
   * Initializes data and builds gantt size handler.
   * @keywords init, initialize, parent, drag, resize
   * @param htmlStructureBuilder HTML structure builder providing all containers of the gantt.
   * @param ganttUpdateCallback List of functions which will be executed during and after dragging.
   */
  public init(ganttUpdateCallback: (() => void)[], ganttYAxisUpdateCallback: (() => void)[]): void {
    // build parent node
    this._parentNode = this._htmlStructureBuilder
      .getVerticalScrollContainerOverlay()
      .append('div')
      .attr('class', 'gantt_size-handler-container');

    // build vertical size handle
    this._verticalSizeHandle = this._buildVerticalSizeHandle(
      this._parentNode,
      ganttUpdateCallback,
      ganttYAxisUpdateCallback
    );
    this._updateVerticalSizeHandle();

    // build horizontal size handle
    this._horizontalSizeHandle = this._buildHorizontalSizeHandle(this._parentNode, ganttUpdateCallback);
    this._updateHorizontalSizeHandle();

    this._initCallbacks();
  }

  private _initCallbacks(): void {
    // remove/add horizontal size handle when sticky row config changes
    this._config
      .onShowStickyRowsChanged()
      .pipe(takeUntil(this.onDestroy))
      .subscribe((showStickyRows) => {
        const node = this._horizontalSizeHandle.node();
        if (showStickyRows) this._parentNode.node().appendChild(node);
        else node.parentNode?.removeChild(node);
      });

    // update the position of the horizontal resize handle when the height of the sticky rows container changes
    this._nodeProportionState
      .select('shiftViewPortProportionsSticky')
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        if (!this._config.showStickyRows()) return;
        this._updateHorizontalSizeHandle();
      });
  }

  private _buildVerticalSizeHandle(
    parentNode: d3.Selection<HTMLDivElement, undefined, d3.BaseType, undefined>,
    ganttUpdateCallback: (() => void)[],
    ganttYAxisUpdateCallback: (() => void)[]
  ): d3.Selection<HTMLDivElement, undefined, d3.BaseType, undefined> {
    const s = this;

    const verticalHandle = parentNode
      .append('div')
      .attr('class', 'gantt-size-handler gantt-size-handler-vertical')
      .call(
        d3
          .drag()
          .on('start', function (event) {
            GanttUtilities.dispatchD3EventToOutside(d3.select(this), event);
            this.classList.add('gantt-size-handler-dragging');
          })
          .on('drag', function (event) {
            GanttUtilities.dispatchD3EventToOutside(d3.select(this), event);
            s._changeDivWidth(s._htmlStructureBuilder.getYAxisContainer(), verticalHandle, event);
            for (let i = 0; i < ganttYAxisUpdateCallback.length; i++) {
              ganttYAxisUpdateCallback[i]();
            }
          })
          .on('end', function (event) {
            GanttUtilities.dispatchD3EventToOutside(d3.select(this), event);
            s._changeDivWidth(s._htmlStructureBuilder.getYAxisContainer(), verticalHandle, event);
            for (let i = 0; i < ganttUpdateCallback.length; i++) {
              ganttUpdateCallback[i]();
            }
            this.classList.remove('gantt-size-handler-dragging');
            s._afterYAxisResizeEndSubject.next(s._config.yAxisWidth());
          })
      );

    return verticalHandle;
  }

  private _buildHorizontalSizeHandle(
    parentNode: d3.Selection<HTMLDivElement, undefined, d3.BaseType, undefined>,
    ganttUpdateCallback: (() => void)[]
  ): d3.Selection<HTMLDivElement, undefined, d3.BaseType, undefined> {
    const s = this;

    const horizontalHandle = parentNode
      .append('div')
      .attr('class', 'gantt-size-handler gantt-size-handler-horizontal')
      .call(
        d3
          .drag()
          .on('start', function (event) {
            GanttUtilities.dispatchD3EventToOutside(d3.select(this), event);
            this.classList.add('gantt-size-handler-dragging');
          })
          .on('drag', function (event) {
            GanttUtilities.dispatchD3EventToOutside(d3.select(this), event);
            s._changeDivHeight(
              s._htmlStructureBuilder.getVerticalScrollContainer(EGanttScrollContainer.STICKY_ROWS),
              horizontalHandle,
              event
            );
          })
          .on('end', function (event) {
            GanttUtilities.dispatchD3EventToOutside(d3.select(this), event);
            s._changeDivHeight(
              s._htmlStructureBuilder.getVerticalScrollContainer(EGanttScrollContainer.STICKY_ROWS),
              horizontalHandle,
              event
            );
            for (let i = 0; i < ganttUpdateCallback.length; i++) {
              ganttUpdateCallback[i]();
            }
            this.classList.remove('gantt-size-handler-dragging');
            s._afterYAxisResizeEndSubject.next(s._config.yAxisWidth());
          })
      );

    // remove horizontal size handle when sticky rows are disabled
    if (!this._config.showStickyRows()) {
      horizontalHandle.node().parentNode.removeChild(horizontalHandle.node());
    }
    return horizontalHandle;
  }

  /**
   * Updates the positions of all size handles when the proportions of gantt containers changed.
   */
  public updateSize(): void {
    this._updateVerticalSizeHandle();
    this._updateHorizontalSizeHandle();
  }

  /**
   * Updates the position of the vertical size handle according to the current y axis proportions.
   */
  private _updateVerticalSizeHandle(): void {
    this._verticalSizeHandle.style('left', () => {
      const yAxisWidth = this._config.yAxisWidth();
      return `${yAxisWidth - 10}px`;
    });
  }

  /**
   * Updates the position of the horizontal size handle according to the current scroll container proportions.
   */
  private _updateHorizontalSizeHandle(): void {
    const scrollContainerHeight = parseFloat(
      this._htmlStructureBuilder.getVerticalScrollContainer(EGanttScrollContainer.STICKY_ROWS).style('height')
    );

    this._horizontalSizeHandle
      .style('top', `${scrollContainerHeight - 10}px`)
      .style('width', `calc(100% - ${this._config.verticalScrollbarWidth}px)`)
      .style('visibility', scrollContainerHeight < 10 ? 'hidden' : undefined);
  }

  /**
   * Internal function to calculate div width changing.
   * @param selection
   * @param event
   */
  private _changeDivWidth(
    div: d3.Selection<HTMLDivElement, undefined, d3.BaseType, undefined>,
    handle: d3.Selection<HTMLDivElement, undefined, d3.BaseType, undefined>,
    event: d3.D3DragEvent<any, any, any>
  ): void {
    if (event.x > 20) {
      this.changeDivSize(parseInt(div.style('width')) + event.dx, undefined);
      handle.style('left', `${parseInt(handle.style('left')) + event.dx}px`);
    }
  }

  /**
   * Internal function to calculate div height changing.
   * @param selection
   * @param event
   */
  private _changeDivHeight(
    div: d3.Selection<HTMLDivElement, undefined, d3.BaseType, undefined>,
    handle: d3.Selection<HTMLDivElement, undefined, d3.BaseType, undefined>,
    event: { y: number; dy: number }
  ): void {
    const parentNodeHeight = this._htmlStructureBuilder.getParentNode().node().clientHeight;
    const xAxisHeight = this._config.xAxisContainerHeight();

    const minDivHeight = Math.max(this._config.stickyRowsContainerMinHeightPx(), 10);
    const maxDivHeightRelativePx = (parentNodeHeight - xAxisHeight) * this._config.stickyRowsContainerMaxHeight();
    const maxDivHeightPx = this._config.stickyRowsContainerMaxHeightPx();
    const maxDivHeight =
      (maxDivHeightPx > 0 && maxDivHeightRelativePx > maxDivHeightPx) ||
      this._config.stickyRowsAllowUnlimitedContainerHeight()
        ? maxDivHeightPx
        : maxDivHeightRelativePx;

    if (event.y >= 0 && event.y <= maxDivHeight) {
      let newDivHeight = div.node().clientHeight + event.dy;
      if (newDivHeight < minDivHeight) newDivHeight = minDivHeight;
      if (newDivHeight > maxDivHeight) newDivHeight = maxDivHeight;

      this._config.setStickyRowsContainerOptimalHeightPx(newDivHeight);
      this.changeDivSize(undefined, newDivHeight);
      handle.style('top', `${newDivHeight - 10}px`);
    }
  }

  /**
   * Sets y axis width by pixel Value.
   * @param pxWidthYAxis Value in px.
   * @param pxHeightStickyScrollContainer
   */
  public changeDivSize(pxWidthYAxis: number = undefined, pxHeightStickyScrollContainer: number = undefined): void {
    if (!isNaN(pxWidthYAxis) && pxWidthYAxis >= 0) {
      const leftWidth = pxWidthYAxis;
      const leftWidthInPx = leftWidth + 'px';

      for (const scrollContainerId of Object.values(EGanttScrollContainer)) {
        this._htmlStructureBuilder.getYAxisContainer(scrollContainerId).style('width', leftWidthInPx);
      }
      this._htmlStructureBuilder.getYAxisPlaceholderContainer().style('width', leftWidthInPx);
      this._htmlStructureBuilder
        .getXAxisContainer()
        .select('.gantt_x-axis')
        .style('width', `calc(100% - ${leftWidth + this._config.verticalScrollbarWidth}px)`);

      this._config.setYAxisWidth(leftWidth);
      this._nodeProportionState.setYAxisProportions({
        width: leftWidth,
        height: this._nodeProportionState.getYAxisProportions().height,
      });
    }
    if (!isNaN(pxHeightStickyScrollContainer) && pxHeightStickyScrollContainer >= 0) {
      const topHeight = pxHeightStickyScrollContainer;
      const topHeightInPx = `${topHeight}px`;

      this._htmlStructureBuilder
        .getVerticalScrollContainer(EGanttScrollContainer.STICKY_ROWS)
        .style('height', topHeightInPx);
    }
  }

  /**
   * Removes structure from DOM.
   * @keywords remove, delete, clear, structure, html, div, container
   */
  public removeStructure(): void {
    this._verticalSizeHandle?.remove();
    this._horizontalSizeHandle?.remove();
    this._parentNode?.remove();

    this._onDestroySubject.next();
    this._onDestroySubject.complete();
  }

  //
  // OBSERVABLES
  //

  /**
   * Observable which gets triggered when the instance gets destroyed.
   */
  private get onDestroy(): Observable<void> {
    return this._onDestroySubject.asObservable();
  }

  /**
   * Observable which will be triggered every time the y axis got resized.
   * It will pass the new y axis width as value.
   */
  public get afterYAxisResizeEnd(): Observable<number> {
    return this._afterYAxisResizeEndSubject.asObservable();
  }
}
