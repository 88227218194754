import { HttpClient } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { UiService } from '@app-modeleditor/ui.service';
import { TemplateService } from '@app-modeleditor/utils/template.service';
import { SaxmsSidebarService } from '@app-modules/saxms-sidebar/saxms-sidebar.service';
import { EMessageType, Message } from '@core/message/message';
import { MessageService } from '@core/message/message.service';
import { Notification } from '@core/notification/notification';
import { ENotificationType } from '@core/notification/notification-type.enum';
import { of } from 'rxjs';
import { delay, takeWhile } from 'rxjs/operators';
import { CloudMessagingService } from './../../../core/notification/cloud-messaging.service';
import { IBackendChatMessage, IChatMessageResponse, IMessageDisplay } from './chat.interface';
import { TemplateChat } from './template-chat';

@Component({
  selector: 'template-chat',
  templateUrl: './template-chat.component.html',
  styleUrls: ['./template-chat.component.scss'],
})
export class TemplateChatComponent implements OnInit, OnChanges, OnDestroy {
  @Input() template: TemplateChat; // data-object
  @Input() disabled: boolean; // disables send button
  @Output() afterSend: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('messageContainer') messageContainer: ElementRef;
  @ViewChild('wrapper') wrapper: ElementRef;
  public messages: IMessageDisplay[] = []; // keeps all displayed messages
  private alive: boolean;

  /**
   * constructor
   * @param uiService UiService
   * @param systemMessageService SystemMessageService
   */
  constructor(
    private uiService: UiService,
    private messageApi: MessageService,
    private templateApi: TemplateService,
    private fcm: CloudMessagingService,
    private sidenav: SaxmsSidebarService,
    private http: HttpClient
  ) {
    this.alive = true;
    this.fcm
      .getMessage()
      .pipe(takeWhile(() => this.alive))
      .subscribe((msg: Notification) => this.handleNotification(msg));
  }

  private handleNotification(notification: Notification) {
    if (
      notification &&
      notification.getBelongsToResource() &&
      this.template.getResourceId() === notification.getBelongsToResource().getId()
    ) {
      if (notification.getType() === ENotificationType.MENU_UPDATE_NOTIFICATION) {
        // old mechanism -> fetches all messages from backend again
        this.refresh();
      } else if (notification.getType() === ENotificationType.UPDATE_CHAT_NOTIFICATION) {
        // receives only the new message
        const incoming = notification.getBelongsToResource() as IChatMessageResponse;
        this.addMessageToDataSet(this.parseBackendToDisplay(incoming.chatEntry));
      }
    }
  }

  close(): void {
    this.sidenav.toggle('_template_notification');
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  /**
   * OnInit lifecycle
   */
  ngOnInit(): void {
    this.checkMessages();
  }

  /**
   * OnChanges lifecycle
   */
  ngOnChanges(changes: SimpleChanges): void {
    // this.checkMessages();
  }

  /**
   * check if there are messages in the template
   */
  public checkMessages(): void {
    if (!this.template.getValue()) {
      return;
    }

    this.messages = (this.template.getValue() || []).map(this.parseBackendToDisplay);

    // scroll to bottom of wrapper
    this.scrollDown();
  }

  /**
   * Adds an incoming message to the data set.
   */
  private addMessageToDataSet(message: IMessageDisplay): void {
    const amountBefore = this.messages.length;
    this.messages = this.messages.filter((m) => m.id !== message.id);
    this.messages.push(message);
    this.messages.sort((a, b) => a.timestamp - b.timestamp);

    if (amountBefore !== this.messages.length) {
      // scroll down only when new message was added
      this.scrollDown();
    }
  }

  private parseBackendToDisplay(message: IBackendChatMessage): IMessageDisplay {
    return {
      id: message.resourceId,
      sender: message.senderName,
      timestamp: message.sendTime,
      orientation: message.positioning,
      content: message.displayText,
      color: message.color,
      deleted: message.deleted,
      edited: message.edited,
    };
  }

  scrollDown(): void {
    // scroll to bottom of wrapper
    of(null)
      .pipe(delay(0))
      .subscribe(() => {
        this.messageContainer.nativeElement.scrollTo(0, this.messageContainer.nativeElement.scrollHeight);
      });
  }

  /**
   * refreshes data
   * @returns void
   */
  private refresh(): void {
    console.warn('update');
    this.http.get(`rest/${this.template.getRestUrl()}`).subscribe((r: IBackendChatMessage[]) => {
      this.template.setValue(r);
      this.checkMessages();
    });
  }

  /**
   * handle sending of messages
   * @param message textfield
   */
  sendMessage(textField: HTMLInputElement): void {
    if (!textField.value.length) {
      return;
    } // prevent sending empty messages
    // disable buttons
    this.disabled = true;
    // construct url
    const url = `${this.template.getSendMessageAction().getActionUrl()}`;
    // send url with message
    this.uiService.getData(url, { messagetext: textField.value }).subscribe(
      (res) => {
        // emit output value
        this.afterSend.emit(res);
        // enable buttons
        this.disabled = false;
        // reset input
        textField.value = '';
        // show a success message
        this.messageApi.show(
          new Message().setType(EMessageType.SUCCESS).setText('SYSTEM.MESSAGE.SEND.success').setDuration(7500)
        );
        // reload component
        // this.messages = this.messages.concat(res);
        // this.scrollDown();
        // this.templateApi.updateElements([this.template.getId()]);
        // this.modeleditorService.reload(this.template.id);
      },
      (err) => {
        // enable buttons on error
        this.disabled = false;
        // show error message
        this.messageApi.show(
          new Message().setType(EMessageType.ERROR).setText(err.error.exceptionType).setDuration(7500)
        );
        console.error(err);
      }
    );
  }

  /**
   * Deletes a chat message
   * @param message message to be deleted
   */
  deleteMessage(message: IMessageDisplay): void {
    if (!this.template.getDeleteMessageURL()) {
      return;
    }
    this.uiService.deleteData(this.template.getDeleteMessageURL(), `id=${message.id}`).subscribe(
      (res) => {
        message.deleted = true;
        // this.refresh();
      },
      (err) => {
        this.messageApi.show(
          new Message().setType(EMessageType.ERROR).setText(err.error.exceptionType).setDuration(7500)
        );
        console.error(err);
      }
    );
  }

  /**
   * Edits a chat message
   */
  editMessage(editEvent: { message: IMessageDisplay; editedText: string }): void {
    if (!this.template.getEditMessageURL()) {
      return;
    }
    this.uiService
      .postData(this.template.getEditMessageURL(), {
        messageId: editEvent.message.id,
        editedText: editEvent.editedText,
      })
      .subscribe(
        (res) => {
          editEvent.message.edited = true;
          editEvent.message.content = editEvent.editedText;
          // this.refresh();
        },
        (err) => {
          this.messageApi.show(
            new Message().setType(EMessageType.ERROR).setText(err.error.exceptionType).setDuration(7500)
          );
          console.error(err);
        }
      );
  }
}
