import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Template } from 'frontend/src/dashboard/model/resource/template';
import { of, Subject } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';
import { SaxMsSubmenuService } from '../../../modules/saxms-submenu-elements/saxms-submenu.service';
import { DateSelectorElement } from './date-selector-element';
import { EInputOutputState, EMonthList, ETimeInterval } from './date-selector.enum';
import { IDateSelectorConfig, InputDataType, IViewData, TimeSpanType } from './date-selector.interface';

@Component({
  selector: 'app-date-selector-element',
  templateUrl: './date-selector-element.component.html',
  styleUrls: ['./date-selector-element.component.scss'],
})
export class DateSelectorElementComponent implements OnInit, OnDestroy {
  @Input() data: DateSelectorElement;
  @Output() onChanges: EventEmitter<any> = new EventEmitter();
  @ViewChild('selectorContentContainer') selectorContentContainer: ElementRef;
  @ViewChild('elementSelector') elementSelector: ElementRef;
  @ViewChild('navigateLeftButton') navigateLeftButton: ElementRef;
  @ViewChild('navigateRightButton') navigateRightButton: ElementRef;
  @ViewChild('elementContainer') elementContainer: ElementRef;

  @HostListener('document:mouseup', ['$event']) onMouseUp(event: MouseEvent): void {
    this.draggingSelectorRight = false;
    this.draggingSelectorLeft = false;
    this.isSelectorMoving = false;
    if (this.draggingSelector) {
      this.emitDateChange(this.getElementSelectorTimeSpan());
      this.draggingSelector = false;
    }
  }

  public config: IDateSelectorConfig = {
    staticNumberOfEntries: 5,
    numberOfEntries: null,
    selectorWidth: 366,
    selectorHeight: 30,
    navButtonWidth: 13,
    snapping: true,
    minSelectionWidth: 8,
    selectionColor: '#0000ff',
    buttonColor: '#999999',
  };

  public viewData: IViewData[] = [];
  public draggingSelector = false;
  public selectedCategory: string = ETimeInterval.WEEK;
  public timeIntervalType = ETimeInterval;
  public currentButtonColorRight: string;
  public startRestrictionBlockWidth = 0;
  public endRestrictionBlockWidth = 0;
  public timeSpanContentContainerWidth: number;

  private timeSpan: TimeSpanType;
  private currentTimeSpan: TimeSpanType = { from: null, to: null };
  private draggingSelectorRight = false;
  private draggingSelectorLeft = false;
  private selectorPositionX = 0;
  private destroy: Subject<void> = new Subject<any>();
  private dragStartSelectorPositionX: number;
  private handleDragStartX = 0;
  private lastDragEventX = 0;
  private elementSelectorStartWidth = 0;
  private dragStartMouseXInTimeSpanElement = 0;
  private elementSelectorWidth: number;
  private timeSpanElementWidth: number;
  private isSelectorMoving = false;
  private timeSpanStartDate: Date;
  private timeSpanEndDate: Date;

  constructor(private submenuService: SaxMsSubmenuService, private zone: NgZone, private cd: ChangeDetectorRef) {}

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
    this.emitTimeout.next();
    this.emitTimeout.complete();
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.handleInputData(this.data as any);
    this.init();
    if (this.data instanceof Template) {
      this.data.onChanges((data) => {
        if (data.type === 'external') {
          this.handleInputData(this.data as any);
        }
      });
    }

    this.submenuService
      .onAction()
      .pipe()
      .pipe(takeUntil(this.destroy))
      .subscribe((data) => {
        if (data) {
          if (data.elementId === 'DateSelectorElementID') {
            this.handleInputData(data.data);
          }
        }
      });

    this.syncWithGantt();
    of(null)
      .pipe(delay(0))
      .subscribe(() => {
        this.scrollToCurrentView();
      });
  }

  private init(): void {
    this.calculateProportions();
    this.checkForNavigationRestrictions();
    this.setTimeSpanStartAndEndDate();
    this.setElementSelector(this.currentTimeSpan);
    this.handleRestrictions();
    this.handleNavigationButtonColor();
  }

  private calculateProportions(): void {
    this.currentButtonColorRight = this.config.buttonColor;
    this.viewData = this.calculateViewData();
    this.timeSpanContentContainerWidth = this.config.selectorWidth - 2 * this.config.navButtonWidth;
    this.timeSpanElementWidth = this.timeSpanContentContainerWidth / this.config.numberOfEntries;

    this.elementSelectorWidth = this.timeSpanElementWidth;
    this.applySelectorProportions();
    this.cd.detectChanges();
  }

  private handleInputData(data: InputDataType): void {
    if (this.data instanceof DateSelectorElement) {
      this.timeSpan = { from: data.timeSpanFrom, to: data.timeSpanTo };
      this.setCurrentTimeSpanValues({ from: data.currentTimeSpanFrom, to: data.currentTimeSpanTo });
      this.init();
    } else {
      switch (data.state) {
        case EInputOutputState.INIT:
          this.timeSpan = { from: data.timeSpanFrom, to: data.timeSpanTo };
          this.setCurrentTimeSpanValues({ from: data.currentTimeSpanFrom, to: data.currentTimeSpanTo });
          this.init();
          break;
        case EInputOutputState.CHANGE:
          this.setCurrentTimeSpan({ from: data.currentTimeSpanFrom, to: data.currentTimeSpanTo });
          this.handleNavigationButtonColor();
          this.cd.detectChanges();
          break;
        default:
          break;
      }
    }
  }

  private syncWithGantt(): void {
    this.emit({ type: 'internal' });
    this.onChanges.emit({ state: EInputOutputState.SYNC });
  }

  emitTimeout: Subject<void> = new Subject<void>();
  private emit(data) {
    this.emitTimeout.next();
    if (this.data instanceof Template) {
      of(null)
        .pipe(delay(450), takeUntil(this.emitTimeout))
        .subscribe(() => {
          this.data.executeChanges(data);
        });
    }
  }

  private emitDateChange(changedDate: TimeSpanType): void {
    this.emit({ type: 'internal', from: changedDate.from, to: changedDate.to });
    this.onChanges.emit({ state: EInputOutputState.CHANGE, from: changedDate.from, to: changedDate.to });
  }

  public moveStartElementSelector(event: MouseEvent): void {
    this.draggingSelector = true;
    this.isSelectorMoving = true;
    this.handleDragStartX = event.x;
    event.preventDefault();
  }

  private scrollToCurrentView(): void {
    if (this.selectorPositionX > this.timeSpanContentContainerWidth) {
      this.scrollToPosition(this.selectorPositionX - this.timeSpanElementWidth / 2);
    } else {
      this.scrollToPosition(0);
    }
    this.init();
  }

  public navigate(direction: string): void {
    switch (direction) {
      case 'RIGHT':
        // rotate
        this.rotate(direction);

        break;
      case 'LEFT':
        this.rotate(direction);

        break;
    }
    this.checkForNavigationRestrictions();
    this.handleNavigationButtonColor();
  }

  private checkForNavigationRestrictions(): void {
    const scrollLeft = this.selectorContentContainer.nativeElement.scrollLeft;
    // use round values to avoid rounding errors with the decimal place
    if (
      Math.round(scrollLeft + this.timeSpanContentContainerWidth) ===
      Math.round(this.elementContainer.nativeElement.getBoundingClientRect().width)
    ) {
      this.navigateRightButton.nativeElement.classList.add('navigationEnd');
    } else {
      this.navigateRightButton.nativeElement.classList.remove('navigationEnd');
    }

    if (scrollLeft === 0) {
      this.navigateLeftButton.nativeElement.classList.add('navigationEnd');
    } else {
      this.navigateLeftButton.nativeElement.classList.remove('navigationEnd');
    }
  }

  private calculateViewData(): IViewData[] {
    const viewData: IViewData[] = [];
    let dateFrom = new Date(this.timeSpan.from);
    const dateTo = new Date(this.timeSpan.to);

    switch (this.selectedCategory) {
      case ETimeInterval.WEEK:
        const firstDayOfFromWeek = new Date(dateFrom);
        firstDayOfFromWeek.setMilliseconds(0);
        firstDayOfFromWeek.setSeconds(0);
        firstDayOfFromWeek.setMinutes(0);
        firstDayOfFromWeek.setHours(0);
        const weekDay = dateFrom.getDay() === 0 ? 7 : dateFrom.getDay();
        firstDayOfFromWeek.setDate(dateFrom.getDate() - weekDay + 1);

        let lastDayOfToWeek = new Date(dateTo);
        lastDayOfToWeek.setMilliseconds(0);
        lastDayOfToWeek.setSeconds(0);
        lastDayOfToWeek.setMinutes(0);
        lastDayOfToWeek.setHours(0);
        if (dateTo.getDay() != 0) {
          lastDayOfToWeek.setDate(dateTo.getDate() + 7 - dateTo.getDay());
        }
        lastDayOfToWeek.setDate(lastDayOfToWeek.getDate() + 1);
        lastDayOfToWeek = new Date(lastDayOfToWeek.getTime() - 1);

        const numberOfDays = Math.round((lastDayOfToWeek.getTime() - firstDayOfFromWeek.getTime()) / 86400000);

        let weekDayFrom = dateFrom.getDay();
        if (weekDayFrom === 0) weekDayFrom = 7; // if sunday
        let weekDayTo = dateTo.getDay();
        if (weekDayTo === 0) weekDayTo = 7; // if sunday
        const numberOfWeeks = Math.round(numberOfDays / 7);
        let year = firstDayOfFromWeek.getFullYear();
        let numberOfWeek = this.getNumberOfWeek(dateFrom);

        for (let i = 0; i < numberOfWeeks; i++) {
          viewData.push({
            title: 'KW' + numberOfWeek,
            subtitle: year.toString(),
            elementId: this.getUUID(),
            meta: {
              year: year,
              weekNumber: numberOfWeek,
            },
          });
          dateFrom = new Date(dateFrom.getTime() + 604800000);
          numberOfWeek = this.getNumberOfWeek(dateFrom);
          if (numberOfWeek === 1) year++;
        }
        break;
      case ETimeInterval.MONTH:
        const numberOfMonths =
          dateTo.getMonth() - dateFrom.getMonth() + 12 * (dateTo.getFullYear() - dateFrom.getFullYear()) + 1;
        let startMonth = dateFrom.getMonth();
        let startYear = dateFrom.getFullYear();
        for (let i = 0; i < numberOfMonths; i++) {
          viewData.push({
            title: EMonthList[startMonth],
            subtitle: startYear.toString(),
            elementId: this.getUUID(),
            meta: {
              year: startYear,
              month: startMonth,
            },
          });
          startMonth = (startMonth + 1) % 12;
          if (startMonth === 0) startYear++;
        }
        break;
      case ETimeInterval.QUARTER:
        const fromQuarter = Math.ceil((dateFrom.getMonth() + 1) / 3);
        const toQuarter = Math.ceil((dateTo.getMonth() + 1) / 3);
        const years = dateTo.getFullYear() - dateFrom.getFullYear();
        const numberOfQuarters = years * 4 + toQuarter - fromQuarter + 1;

        let currentQuarter = Math.ceil((dateFrom.getMonth() + 1) / 3) - 1;
        let _startYear = dateFrom.getFullYear();
        for (let i = 0; i < numberOfQuarters; i++) {
          const quarter = (currentQuarter % 4) + 1;
          viewData.push({
            title: quarter + '. Quartal',
            subtitle: _startYear.toString(),
            elementId: this.getUUID(),
            meta: {
              year: _startYear,
              quarter: quarter,
            },
          });
          currentQuarter++;
          if (quarter === 4) _startYear++;
        }
        break;
      default:
        break;
    }
    this.config.numberOfEntries =
      viewData.length < this.config.staticNumberOfEntries ? viewData.length : this.config.staticNumberOfEntries;
    return viewData;
  }

  public handleElementSelectorHandle(event: MouseEvent, direction: string): void {
    this.handleDragStartX = event.screenX;
    this.elementSelectorStartWidth = this.elementSelectorWidth;
    this.draggingSelector = true;
    switch (direction) {
      case 'RIGHT':
        this.dragStartMouseXInTimeSpanElement = this.selectorPositionX + this.elementSelectorWidth;
        this.draggingSelectorRight = true;
        break;
      case 'LEFT':
        this.dragStartMouseXInTimeSpanElement = this.selectorPositionX;
        this.draggingSelectorLeft = true;
        this.dragStartSelectorPositionX = this.selectorPositionX;
        break;
      default:
        break;
    }
    event.preventDefault();
    event.stopPropagation();
  }

  private handleNavigationButtonColor(): void {
    const currentScrollPosition = this.selectorContentContainer.nativeElement.scrollLeft;

    if (currentScrollPosition > this.selectorPositionX) {
      this.navigateLeftButton.nativeElement.classList.add('navSelection');
    } else {
      this.navigateLeftButton.nativeElement.classList.remove('navSelection');
    }
    if (
      currentScrollPosition + this.timeSpanContentContainerWidth <
      this.selectorPositionX + this.elementSelectorWidth
    ) {
      this.navigateRightButton.nativeElement.classList.add('navSelection');
    } else {
      this.navigateRightButton.nativeElement.classList.remove('navSelection');
    }
  }

  public onMouseMove(event: MouseEvent): void {
    if (this.draggingSelectorLeft || this.draggingSelectorRight || this.isSelectorMoving) {
      const restrictionEndX = this.viewData.length * this.timeSpanElementWidth - this.endRestrictionBlockWidth;
      if (this.draggingSelectorRight) {
        const xOffset = event.screenX - this.handleDragStartX;
        const snap = this.getSnapDistance(xOffset);
        let newWidth = this.elementSelectorStartWidth + xOffset + snap;
        if (newWidth + this.selectorPositionX > restrictionEndX) {
          // handle out of bounds right
          this.elementSelectorWidth = restrictionEndX - this.selectorPositionX;
        } else {
          if (newWidth < this.config.minSelectionWidth) {
            newWidth = this.config.minSelectionWidth;
          }
          this.elementSelectorWidth = newWidth;
        }
      }
      if (this.draggingSelectorLeft) {
        const xOffset = event.screenX - this.handleDragStartX;
        const snap = this.getSnapDistance(xOffset);
        let newSelectorPositionX = this.dragStartSelectorPositionX + xOffset + snap;
        let newElementSelectorWidth = this.elementSelectorStartWidth - xOffset - snap;
        if (newSelectorPositionX < this.startRestrictionBlockWidth) {
          // handle out of bounds left
          this.selectorPositionX = this.startRestrictionBlockWidth;
          this.elementSelectorWidth = newElementSelectorWidth + newSelectorPositionX - this.startRestrictionBlockWidth;
        } else {
          if (newElementSelectorWidth < this.config.minSelectionWidth) {
            newSelectorPositionX += newElementSelectorWidth - this.config.minSelectionWidth;
            newElementSelectorWidth = this.config.minSelectionWidth;
          }

          this.selectorPositionX = newSelectorPositionX;
          this.elementSelectorWidth = newElementSelectorWidth;
        }
      }

      if (this.isSelectorMoving) {
        const xOffset = event.x - this.handleDragStartX;
        this.handleDragStartX = event.x;
        let newPositionX = this.selectorPositionX + xOffset;

        if (newPositionX < this.startRestrictionBlockWidth) {
          newPositionX = this.startRestrictionBlockWidth;
        }
        if (newPositionX + this.elementSelectorWidth > restrictionEndX) {
          newPositionX = restrictionEndX - this.elementSelectorWidth;
        }
        this.selectorPositionX = newPositionX;
      }

      this.handleNavigationButtonColor();
      const timeSpan = this.getElementSelectorTimeSpan();
      this.setCurrentTimeSpanValues(timeSpan);
      this.applySelectorProportions();
    }
  }

  private applySelectorProportions(): void {
    this.elementSelector.nativeElement.style.left = `${this.selectorPositionX}px`;
    this.elementSelector.nativeElement.style.width = `${this.elementSelectorWidth}px`;
  }

  private getElementSelectorTimeSpan(): TimeSpanType {
    let fromDate,
      toDate,
      handleLeftCurrentFieldNumberFrom,
      handleRightCurrentFieldNumberFrom,
      timeSpanInMilliFrom,
      handleLeftCurrentDateFrom,
      timeSpanInMilliTo,
      handleRightCurrentDateFrom,
      millisPerPixelTo,
      millisPerPixelFrom,
      handleLeftCurrentYearFrom,
      handleRightCurrentYearFrom;
    switch (this.selectedCategory) {
      case ETimeInterval.WEEK:
        // handle left
        handleLeftCurrentFieldNumberFrom = this.getCurrentFieldNumberByXPosition(this.selectorPositionX + 1);
        const handleLeftCurrentWeekFrom = this.viewData[handleLeftCurrentFieldNumberFrom].meta.weekNumber;
        handleLeftCurrentYearFrom = this.viewData[handleLeftCurrentFieldNumberFrom].meta.year;

        handleLeftCurrentDateFrom = this.getRangeOfWeek(handleLeftCurrentWeekFrom, handleLeftCurrentYearFrom).from;

        millisPerPixelFrom =
          this.getMillisOfWeek(handleLeftCurrentWeekFrom, handleLeftCurrentYearFrom) / this.timeSpanElementWidth;

        // handle right
        handleRightCurrentFieldNumberFrom = this.getCurrentFieldNumberByXPosition(
          this.selectorPositionX + this.elementSelectorWidth
        );
        const handleRightCurrentWeekFrom = this.viewData[handleRightCurrentFieldNumberFrom].meta.weekNumber;
        handleRightCurrentYearFrom = this.viewData[handleRightCurrentFieldNumberFrom].meta.year;

        handleRightCurrentDateFrom = this.getRangeOfWeek(handleRightCurrentWeekFrom, handleRightCurrentYearFrom).from;

        millisPerPixelTo =
          this.getMillisOfWeek(handleRightCurrentWeekFrom, handleRightCurrentYearFrom) / this.timeSpanElementWidth;

        // get coordinates from selector
        fromDate = new Date(
          (this.selectorPositionX - handleLeftCurrentFieldNumberFrom * this.timeSpanElementWidth) * millisPerPixelFrom +
            handleLeftCurrentDateFrom.getTime() +
            1
        );
        toDate = new Date(
          (this.elementSelectorWidth +
            this.selectorPositionX -
            handleRightCurrentFieldNumberFrom * this.timeSpanElementWidth) *
            millisPerPixelTo +
            handleRightCurrentDateFrom.getTime() -
            1
        );
        return { from: fromDate, to: toDate };
      case ETimeInterval.MONTH:
        // handle left
        handleLeftCurrentDateFrom = new Date(this.timeSpanStartDate);

        handleLeftCurrentFieldNumberFrom = this.getCurrentFieldNumberByXPosition(this.selectorPositionX);
        const handleLeftCurrentMonthFrom = this.viewData[handleLeftCurrentFieldNumberFrom].meta.month;
        handleLeftCurrentYearFrom = this.viewData[handleLeftCurrentFieldNumberFrom].meta.year;

        handleLeftCurrentDateFrom.setFullYear(handleLeftCurrentYearFrom);
        handleLeftCurrentDateFrom.setMonth(handleLeftCurrentMonthFrom);

        // handle right
        handleRightCurrentDateFrom = new Date(this.timeSpanStartDate);
        handleRightCurrentFieldNumberFrom = this.getCurrentFieldNumberByXPosition(
          this.selectorPositionX + this.elementSelectorWidth
        );
        const handleRightCurrentMonthFrom = this.viewData[handleRightCurrentFieldNumberFrom].meta.month;
        handleRightCurrentYearFrom = this.viewData[handleRightCurrentFieldNumberFrom].meta.year;
        handleRightCurrentDateFrom.setFullYear(handleRightCurrentYearFrom);
        handleRightCurrentDateFrom.setMonth(handleRightCurrentMonthFrom);

        timeSpanInMilliFrom = this.getMillisOfMonth(handleLeftCurrentYearFrom, handleLeftCurrentMonthFrom);
        millisPerPixelFrom = timeSpanInMilliFrom / this.timeSpanElementWidth;

        timeSpanInMilliTo = this.getMillisOfMonth(handleRightCurrentYearFrom, handleRightCurrentMonthFrom);
        millisPerPixelTo = timeSpanInMilliTo / this.timeSpanElementWidth;

        // get coordinates from selector
        fromDate = new Date(
          (this.selectorPositionX - handleLeftCurrentFieldNumberFrom * this.timeSpanElementWidth) * millisPerPixelFrom +
            handleLeftCurrentDateFrom.getTime() +
            1
        );
        toDate = new Date(
          (this.elementSelectorWidth +
            this.selectorPositionX -
            handleRightCurrentFieldNumberFrom * this.timeSpanElementWidth) *
            millisPerPixelTo +
            handleRightCurrentDateFrom.getTime()
        );

        return { from: fromDate, to: toDate };
      case ETimeInterval.QUARTER:
        // Left handle
        handleLeftCurrentFieldNumberFrom = this.getCurrentFieldNumberByXPosition(this.selectorPositionX + 1);
        const quarterYearFrom = this.viewData[handleLeftCurrentFieldNumberFrom].meta.year;
        const quarterFrom = this.viewData[handleLeftCurrentFieldNumberFrom].meta.quarter;
        timeSpanInMilliFrom = 0;
        handleLeftCurrentDateFrom = new Date(quarterYearFrom, (quarterFrom - 1) * 3, 1, 0, 0, 0, 0);

        for (let i = 1; i < 4; i++) {
          timeSpanInMilliFrom += this.getMillisOfMonth(quarterYearFrom, quarterFrom * 3 - i);
        }
        millisPerPixelFrom = timeSpanInMilliFrom / this.timeSpanElementWidth;

        // Right handle
        handleRightCurrentFieldNumberFrom = this.getCurrentFieldNumberByXPosition(
          this.selectorPositionX + this.elementSelectorWidth
        );
        const quarterYearTo = this.viewData[handleRightCurrentFieldNumberFrom].meta.year;
        const quarterTo = this.viewData[handleRightCurrentFieldNumberFrom].meta.quarter;

        handleRightCurrentDateFrom = new Date(quarterYearTo, (quarterTo - 1) * 3, 1, 0, 0, 0, 0);

        timeSpanInMilliTo = this.getMillisOfQuarter(quarterYearTo, quarterTo);
        millisPerPixelTo = timeSpanInMilliTo / this.timeSpanElementWidth;

        // get coordinates from selector
        fromDate = new Date(
          (this.selectorPositionX - handleLeftCurrentFieldNumberFrom * this.timeSpanElementWidth) * millisPerPixelFrom +
            handleLeftCurrentDateFrom.getTime() +
            1
        );
        toDate = new Date(
          (this.elementSelectorWidth +
            this.selectorPositionX -
            handleRightCurrentFieldNumberFrom * this.timeSpanElementWidth) *
            millisPerPixelTo +
            handleRightCurrentDateFrom.getTime()
        );
        return { from: fromDate, to: toDate };

      default:
        return;
    }
  }

  private getSnapDistance(xOffset: number, snapRadius = 5): number {
    const currentMousePositionInTimeSpanElement = this.dragStartMouseXInTimeSpanElement + xOffset;
    if (this.config.snapping) {
      const distanceToSnap = currentMousePositionInTimeSpanElement % this.timeSpanElementWidth;
      if (distanceToSnap < snapRadius) {
        return -distanceToSnap;
      }
      if (distanceToSnap > this.timeSpanElementWidth - snapRadius) {
        return this.timeSpanElementWidth - distanceToSnap;
      }
    }
    return 0;
  }

  public onSelectorDblClick(event: MouseEvent): void {
    const x = event.offsetX + this.selectorPositionX;
    const elemPos = Math.floor(x / this.timeSpanElementWidth);
    if (elemPos < this.viewData.length) {
      this.focusElement(this.viewData[elemPos].elementId);
    }
  }

  public focusElement(elementId: string): void {
    for (let i = 0; i < this.viewData.length; i++) {
      if (this.viewData[i].elementId == elementId) {
        if (i === 0 && i === this.viewData.length - 1) {
          // first element calculate the start and end restriction
          this.selectorPositionX = this.startRestrictionBlockWidth;
          this.elementSelectorWidth =
            this.timeSpanElementWidth - this.startRestrictionBlockWidth - this.endRestrictionBlockWidth;
          break;
        }
        if (i === 0) {
          // first element calculate the start restriction
          this.selectorPositionX = this.startRestrictionBlockWidth;
          this.elementSelectorWidth = this.timeSpanElementWidth - this.startRestrictionBlockWidth;
          break;
        }
        if (i === this.viewData.length - 1) {
          // last element calculate the end restriction
          this.selectorPositionX = i * this.timeSpanElementWidth;
          this.elementSelectorWidth = this.timeSpanElementWidth - this.endRestrictionBlockWidth;
          break;
        }
        this.selectorPositionX = i * this.timeSpanElementWidth;
        this.elementSelectorWidth = this.timeSpanElementWidth;
        this.handleNavigationButtonColor();
        break;
      }
    }
    const timeSpan = this.getElementSelectorTimeSpan();
    this.setCurrentTimeSpanValues(timeSpan);
    this.applySelectorProportions();
    this.emitDateChange(timeSpan);
  }

  private rotate(direction: string): void {
    const currentScrollPosition = this.selectorContentContainer.nativeElement.scrollLeft;

    this.selectorContentContainer.nativeElement.scrollTo({
      left:
        direction == 'RIGHT'
          ? currentScrollPosition + this.timeSpanElementWidth
          : currentScrollPosition - this.timeSpanElementWidth,
      // behavior: "smooth",
    });
  }

  private scrollToPosition(positionX: number): void {
    this.selectorContentContainer.nativeElement.scrollTo({
      left: positionX,
      // behavior: "smooth",
    });
  }

  private getNumberOfWeek(date: Date): number {
    date = new Date(date);
    date.setHours(0, 0, 0, 0);
    // Thursday in current week decides the year.
    date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
    // January 4 is always in week 1.
    const week1 = new Date(date.getFullYear(), 0, 4);
    // Adjust to Thursday in week 1 and count number of weeks from date to week1.
    return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + ((week1.getDay() + 6) % 7)) / 7);
  }

  public toggleTimeInterval(interval: string): void {
    this.selectedCategory = interval;
    this.init();
    this.scrollToCurrentView();
  }

  private getMillisOfWeek(weekNo: number, y: number): number {
    let rangeOfWeek, rangeIsFrom, rangeIsTo;
    rangeOfWeek = this.getRangeOfWeek(weekNo, y);
    rangeIsFrom = rangeOfWeek.from.getTime();
    rangeIsTo = rangeOfWeek.to.getTime();
    return rangeIsTo - rangeIsFrom + 1;
  }

  private getRangeOfWeek(w: number, y: number): TimeSpanType {
    const simple = new Date(y, 0, 1 + (w - 1) * 7);
    const dow = simple.getDay();
    const ISOweekStart = simple;
    if (dow <= 4) ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    else ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    const dateFrom = ISOweekStart;
    let dateTo = new Date(dateFrom);
    dateTo.setDate(dateFrom.getDate() + 7);
    dateTo = new Date(dateTo.getTime() - 1);
    return { to: dateTo, from: dateFrom };
  }

  private setElementSelector(currentTimeSpan: TimeSpanType): void {
    let startYear,
      fieldAmountStart,
      pixelsPerMilliStart,
      startPositionInField,
      fieldAmountEnd,
      pixelsPerMilliEnd,
      endPositionInField;

    switch (this.selectedCategory) {
      case ETimeInterval.WEEK:
        // startDate
        startYear = currentTimeSpan.from.getFullYear();
        const startWeekNumber = this.getNumberOfWeek(currentTimeSpan.from);
        const weekTimeSpanStart = this.getMillisOfWeek(startWeekNumber, startYear);
        // if last week of year is also in the next year
        if (startWeekNumber > 51 && currentTimeSpan.from.getMonth() === 0) {
          startYear--;
        }
        // if first week of year is also in the last year
        if (startWeekNumber === 1 && currentTimeSpan.from.getMonth() === 11) {
          startYear++;
        }
        fieldAmountStart = 0;

        // get field number
        for (let i = 0; i < this.viewData.length; i++) {
          if (this.viewData[i].meta.year === startYear && this.viewData[i].meta.weekNumber === startWeekNumber) {
            fieldAmountStart = i;
            break;
          }
        }
        pixelsPerMilliStart = this.timeSpanElementWidth / weekTimeSpanStart;
        const millisOfStartWeek =
          currentTimeSpan.from.getTime() - this.getRangeOfWeek(startWeekNumber, startYear).from.getTime();

        // endDate
        let endYear = currentTimeSpan.to.getFullYear();
        const endWeekNumber = this.getNumberOfWeek(currentTimeSpan.to);
        const weekTimeSpanEnd = this.getMillisOfWeek(endWeekNumber, endYear);
        fieldAmountEnd = 0;

        // if last week of year is also in the next year
        if (endWeekNumber > 51 && currentTimeSpan.to.getMonth() === 0) {
          endYear--;
        }
        // if first week of year is also in the last year
        if (endWeekNumber === 1 && currentTimeSpan.to.getMonth() === 11) {
          endYear++;
        }

        // get field number
        for (let i = 0; i < this.viewData.length; i++) {
          if (this.viewData[i].meta.year === endYear && this.viewData[i].meta.weekNumber === endWeekNumber) {
            fieldAmountEnd = i;
            break;
          }
        }

        pixelsPerMilliEnd = this.timeSpanElementWidth / weekTimeSpanEnd;
        const millisOfEndWeek =
          currentTimeSpan.to.getTime() - this.getRangeOfWeek(endWeekNumber, endYear).from.getTime();
        // set selector
        this.selectorPositionX = fieldAmountStart * this.timeSpanElementWidth + millisOfStartWeek * pixelsPerMilliStart;
        this.elementSelectorWidth =
          fieldAmountEnd * this.timeSpanElementWidth + millisOfEndWeek * pixelsPerMilliEnd - this.selectorPositionX;
        break;
      case ETimeInterval.MONTH:
        // get start month x position
        startYear = this.timeSpanStartDate.getFullYear();
        const startMonth = this.timeSpanStartDate.getMonth();
        const currentYear = this.currentTimeSpan.from.getFullYear();
        const currentMonth = this.currentTimeSpan.from.getMonth();

        fieldAmountStart = currentMonth - startMonth + (currentYear - startYear) * 12;

        const millisOfStartMonth = this.getMillisOfMonth(currentYear, currentMonth);
        pixelsPerMilliStart = this.timeSpanElementWidth / millisOfStartMonth;
        startPositionInField =
          (this.currentTimeSpan.from.getTime() - new Date(currentYear, currentMonth, 1, 0, 0, 0, 0).getTime()) *
          pixelsPerMilliStart;

        // get End month x position
        const currentYearEnd = this.currentTimeSpan.to.getFullYear();
        const currentMonthEnd = this.currentTimeSpan.to.getMonth();

        fieldAmountEnd = currentMonthEnd - startMonth + (currentYearEnd - startYear) * 12;

        const millisOfEndtMonth = this.getMillisOfMonth(currentYearEnd, currentMonthEnd);
        pixelsPerMilliEnd = this.timeSpanElementWidth / millisOfEndtMonth;
        endPositionInField =
          (this.currentTimeSpan.to.getTime() - new Date(currentYearEnd, currentMonthEnd, 1, 0, 0, 0, 0).getTime()) *
          pixelsPerMilliEnd;

        // set selector
        this.selectorPositionX = startPositionInField + fieldAmountStart * this.timeSpanElementWidth;

        this.elementSelectorWidth =
          endPositionInField + fieldAmountEnd * this.timeSpanElementWidth - this.selectorPositionX;

        break;
      case ETimeInterval.QUARTER:
        startYear = this.timeSpanStartDate.getFullYear();
        const startQuarter = this.viewData[0].meta.quarter;

        // get start quarter x position
        const quarterToSetFrom = Math.ceil((currentTimeSpan.from.getMonth() + 1) / 3);
        const yearToSetFrom = currentTimeSpan.from.getFullYear();
        fieldAmountStart = quarterToSetFrom - startQuarter + (yearToSetFrom - startYear) * 4;
        const millisOfQuarterFrom = this.getMillisOfQuarter(yearToSetFrom, quarterToSetFrom);
        pixelsPerMilliStart = this.timeSpanElementWidth / millisOfQuarterFrom;
        startPositionInField =
          (this.currentTimeSpan.from.getTime() -
            new Date(yearToSetFrom, (quarterToSetFrom - 1) * 3, 1, 0, 0, 0, 0).getTime()) *
          pixelsPerMilliStart;

        // get end quarter x position
        const quarterToSetTo = Math.ceil((currentTimeSpan.to.getMonth() + 1) / 3);
        const yearToSetTo = currentTimeSpan.to.getFullYear();
        fieldAmountEnd = quarterToSetTo - startQuarter + (yearToSetTo - startYear) * 4;
        const millisOfQuarterTo = this.getMillisOfQuarter(yearToSetTo, quarterToSetTo);
        pixelsPerMilliEnd = this.timeSpanElementWidth / millisOfQuarterTo;
        endPositionInField =
          (this.currentTimeSpan.to.getTime() -
            new Date(yearToSetTo, (quarterToSetTo - 1) * 3, 1, 0, 0, 0, 0).getTime()) *
          pixelsPerMilliEnd;

        // set selector
        this.selectorPositionX = startPositionInField + fieldAmountStart * this.timeSpanElementWidth;

        this.elementSelectorWidth =
          endPositionInField + fieldAmountEnd * this.timeSpanElementWidth - this.selectorPositionX;
        break;
    }
    this.applySelectorProportions();
  }

  private getCurrentFieldNumberByXPosition(xPosition: number): number {
    const fieldNumber = Math.ceil(xPosition / this.timeSpanElementWidth) - 1;
    return fieldNumber < 0 ? 0 : fieldNumber;
  }

  private setTimeSpanStartAndEndDate(): void {
    const dateCopyFrom = new Date(this.timeSpan.from);
    let dateCopyTo = new Date(this.timeSpan.to);

    switch (this.selectedCategory) {
      case ETimeInterval.WEEK:
        dateCopyFrom.setMilliseconds(0);
        dateCopyFrom.setSeconds(0);
        dateCopyFrom.setMinutes(0);
        dateCopyFrom.setHours(0);
        var day = dateCopyFrom.getDay(),
          diff = dateCopyFrom.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
        this.timeSpanStartDate = new Date(dateCopyFrom.setDate(diff));

        dateCopyTo.setMilliseconds(999);
        dateCopyTo.setSeconds(59);
        dateCopyTo.setMinutes(59);
        dateCopyTo.setHours(23);
        var dayTo = dateCopyTo.getDay();
        if (dayTo == 0) dayTo = 7;
        var lastday = dateCopyTo.getDate() - (dayTo - 1) + 6;

        this.timeSpanEndDate = new Date(dateCopyTo.setDate(lastday));
        break;
      case ETimeInterval.MONTH:
        dateCopyFrom.setMilliseconds(0);
        dateCopyFrom.setSeconds(0);
        dateCopyFrom.setMinutes(0);
        dateCopyFrom.setHours(0);
        dateCopyFrom.setDate(1);
        this.timeSpanStartDate = dateCopyFrom;

        dateCopyTo = new Date(dateCopyTo.getFullYear(), dateCopyTo.getMonth() + 1, 0);
        dateCopyTo.setMilliseconds(999);
        dateCopyTo.setSeconds(59);
        dateCopyTo.setMinutes(59);
        dateCopyTo.setHours(23);
        this.timeSpanEndDate = dateCopyTo;
        break;
      case ETimeInterval.QUARTER:
        dateCopyFrom.setMilliseconds(0);
        dateCopyFrom.setSeconds(0);
        dateCopyFrom.setMinutes(0);
        dateCopyFrom.setHours(0);
        dateCopyFrom.setDate(1);
        let monthFrom = dateCopyFrom.getMonth();
        monthFrom = monthFrom - (monthFrom % 3);
        dateCopyFrom.setMonth(monthFrom);
        this.timeSpanStartDate = dateCopyFrom;

        let monthTo = dateCopyTo.getMonth();
        monthTo = monthTo + (2 - (monthTo % 3));
        dateCopyTo = new Date(dateCopyTo.getFullYear(), monthTo + 1, 0);
        dateCopyTo.setMilliseconds(999);
        dateCopyTo.setSeconds(59);
        dateCopyTo.setMinutes(59);
        dateCopyTo.setHours(23);
        this.timeSpanEndDate = dateCopyTo;
        break;

      default:
        return;
    }
    this.handleNavigationButtonColor();
  }

  private handleRestrictions(): void {
    const timeDiffInMilliStart = this.timeSpan.from.getTime() - this.timeSpanStartDate.getTime();
    const timeDiffInMilliEnd = this.timeSpanEndDate.getTime() - this.timeSpan.to.getTime();
    let pixelsPerMilliStart, pixelsPerMilliEnd;
    switch (this.selectedCategory) {
      case ETimeInterval.WEEK:
        // calculate start restriction block
        pixelsPerMilliStart =
          this.timeSpanElementWidth /
          this.getMillisOfWeek(this.viewData[0].meta.weekNumber, this.viewData[0].meta.year);
        // calculate end restriction block
        pixelsPerMilliEnd =
          this.timeSpanElementWidth /
          this.getMillisOfWeek(
            this.viewData[this.viewData.length - 1].meta.weekNumber,
            this.viewData[this.viewData.length - 1].meta.year
          );
        break;
      case ETimeInterval.MONTH:
        // calculate start restriction block
        pixelsPerMilliStart =
          this.timeSpanElementWidth / this.getMillisOfMonth(this.viewData[0].meta.year, this.viewData[0].meta.month);
        // calculate end restriction block
        pixelsPerMilliEnd =
          this.timeSpanElementWidth /
          this.getMillisOfMonth(
            this.viewData[this.viewData.length - 1].meta.year,
            this.viewData[this.viewData.length - 1].meta.month
          );
        break;
      case ETimeInterval.QUARTER:
        // calculate start restriction block
        pixelsPerMilliStart =
          this.timeSpanElementWidth /
          this.getMillisOfQuarter(this.viewData[0].meta.year, this.viewData[0].meta.quarter);
        // calculate end restriction block
        pixelsPerMilliEnd =
          this.timeSpanElementWidth /
          this.getMillisOfQuarter(
            this.viewData[this.viewData.length - 1].meta.year,
            this.viewData[this.viewData.length - 1].meta.quarter
          );
        break;
    }
    this.startRestrictionBlockWidth = pixelsPerMilliStart * timeDiffInMilliStart;
    this.endRestrictionBlockWidth = pixelsPerMilliEnd * timeDiffInMilliEnd;
  }

  // setter & getter

  private getMillisOfMonth(year: number, month: number): number {
    const dateFrom = new Date(year, month, 1, 0, 0, 0, 0);
    const dateTo = new Date(new Date(year, month + 1, 0, 23, 59, 59, 999).getTime());
    return dateTo.getTime() - dateFrom.getTime();
  }

  private getMillisOfQuarter(year: number, quarter: number): number {
    let timeSpanInMilli = 0;
    for (let i = 1; i < 4; i++) {
      timeSpanInMilli += this.getMillisOfMonth(year, quarter * 3 - i);
    }
    return timeSpanInMilli;
  }

  private getCurrentTimeSpan(): TimeSpanType {
    return this.currentTimeSpan;
  }

  private setCurrentTimeSpan(currentTimeSpan: TimeSpanType): void {
    this.setCurrentTimeSpanValues(currentTimeSpan);
    this.setElementSelector(currentTimeSpan);
  }

  private setCurrentTimeSpanValues(currentTimeSpan: TimeSpanType): void {
    this.currentTimeSpan.from = currentTimeSpan.from;
    this.currentTimeSpan.to = currentTimeSpan.to;
  }

  private getUUID(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0,
        v = c == 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }
}
