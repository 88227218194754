import { CdkDragEnd } from '@angular/cdk/drag-drop';
import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, Inject, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DefaultOverlayContainer } from '@app-modeleditor/components/lightbox/overlay/default-overlay-container';
import { GanttSettingsService } from 'frontend/src/dashboard/gantt/general/gantt-settings/service/gantt-settings.service';
import { fromEvent, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DockComponent } from '../dock.component';
import { EDockViewMode } from '../dock.enum';
import { GanttDockService } from '../gantt-dock.service';

@Component({
  selector: 'app-dock-window',
  templateUrl: './dock-window.component.html',
  styleUrls: ['./dock-window.component.scss'],
})
export class DockWindowComponent extends DefaultOverlayContainer<any> implements OnInit, OnDestroy {
  @ViewChild('window') windowElement: ElementRef;
  @ViewChild('resizeHandle', { static: true }) resizeHandle: ElementRef;
  @ViewChild('dockWindow') dockWindow: DockComponent;

  public dockViewModes = EDockViewMode;
  private _minWidthHeight = 400;
  private startScreenPosition: number[] = [0, 0];
  private startWindowSize: number[] = [0, 0];
  private _subscriptions: Subscription[] = [];
  private _isDragging = false;

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    public _ganttDockService: GanttDockService,
    public ganttSettingsService: GanttSettingsService,
    private _ngZone: NgZone
  ) {
    super();
  }

  ngOnInit(): void {
    // register dragEvents
    this._ngZone.runOutsideAngular((_) => {
      this._initDrag();
    });
  }

  ngAfterViewInit(): void {
    this._applyWindowProportions();
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach((s) => {
      if (s) s.unsubscribe();
    });
  }

  public handleDragEnd(event: CdkDragEnd): void {
    const position = event.source.getFreeDragPosition();
    const proportions = this.ganttSettingsService.getGanttSettings().dockWindowProportions;
    this.ganttSettingsService.changeSettings({
      dockWindowProportions: { x: position.x, y: position.y, height: proportions.height, width: proportions.width },
    });
    this.ganttSettingsService.saveSettings().subscribe();
  }

  private _initDrag(): void {
    const _resizeHandleDragStart = fromEvent<MouseEvent>(this.resizeHandle.nativeElement, 'mousedown');
    const _resizeHandleDragEnd = fromEvent<MouseEvent>(this._document, 'mouseup');
    const _resizeHandleDrag = fromEvent<MouseEvent>(this._document, 'mousemove').pipe(takeUntil(_resizeHandleDragEnd));

    let dragSub: Subscription;

    // dragStart
    const dragStartSub = _resizeHandleDragStart.subscribe((event: MouseEvent) => {
      event.stopPropagation();
      if (!this._isDragging) {
        this._isDragging = true;
      }
      this._resizeStart(event);
      // drag
      dragSub = _resizeHandleDrag.subscribe((event: MouseEvent) => {
        event.stopPropagation();
        this._resize(event);
      });
    });

    // dragEnd
    const dragEndSub = _resizeHandleDragEnd.subscribe((event: MouseEvent) => {
      if (this._isDragging) {
        // real drag end
        this._isDragging = false;
        this._resizeEnd(event);
      }
      // if (this._currentOverlayRef) { this._currentOverlayRef.dispose(); }
      if (dragSub) {
        dragSub.unsubscribe();
      }
    });

    this._subscriptions.push.apply(this._subscriptions, [dragStartSub, dragSub, dragEndSub]);
  }

  private _resizeStart(event: MouseEvent): void {
    this.startScreenPosition[0] = event.screenX;
    this.startScreenPosition[1] = event.screenY;
    this.startWindowSize[0] = this.ganttSettingsService.getGanttSettings().dockWindowProportions.height;
    this.startWindowSize[1] = this.ganttSettingsService.getGanttSettings().dockWindowProportions.width;
    this._ganttDockService.onResizeDockStart();
  }

  private _resize(event: MouseEvent): void {
    const distanceX = this.startScreenPosition[0] - event.screenX;
    const distanceY = this.startScreenPosition[1] - event.screenY;
    const newHeight = this.startWindowSize[0] - distanceY;
    const newWidth = this.startWindowSize[1] - distanceX;
    this.ganttSettingsService.getGanttSettings().dockWindowProportions.height =
      newHeight < this._minWidthHeight ? this._minWidthHeight : newHeight;
    this.ganttSettingsService.getGanttSettings().dockWindowProportions.width =
      newWidth < this._minWidthHeight ? this._minWidthHeight : newWidth;
    this.windowElement.nativeElement.style.width =
      this.ganttSettingsService.getGanttSettings().dockWindowProportions.width + 'px';
    this.windowElement.nativeElement.style.height =
      this.ganttSettingsService.getGanttSettings().dockWindowProportions.height + 'px';
    this._ganttDockService.onResizeDock();
  }

  private _resizeEnd(event: MouseEvent): void {
    this._ganttDockService.onResizeDockEnd();
    const proportions = this.ganttSettingsService.getGanttSettings().dockWindowProportions;
    this.ganttSettingsService.changeSettings({ dockWindowProportions: proportions });
    this.ganttSettingsService.saveSettings().subscribe();
  }

  private _applyWindowProportions(): void {
    const proportions = this.ganttSettingsService.getGanttSettings().dockWindowProportions;
    this.windowElement.nativeElement.style.height = proportions.height + 'px';
    this.windowElement.nativeElement.style.width = proportions.width + 'px';
  }
}
