import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { ColorButtonModule } from '@app-modeleditor/components/color-picker/color-button/color-button.module';
import { ElementsModule } from '@app-modeleditor/components/elements/elements.module';
import { ImageModule } from '@app-modeleditor/components/image/image.module';
import { NumericRangePickerModule } from '@app-modeleditor/components/numeric-range-picker/numeric-range-picker.module';
import { SelectorModule } from '@app-modeleditor/components/selector/selector.module';
import { TemplateDatepickerModule } from '@app-modeleditor/components/template-datepicker/template-datepicker.module';
import { TemplateDurationPickerModule } from '@app-modeleditor/components/template-duration-picker/template-duration-picker.module';
import { SlotModule } from '@app-modeleditor/components/template/slot/slot.module';
import { SaxmsTooltipModule } from '@app-modeleditor/components/tooltip/tooltip.module';
import { HotTableModule } from '@handsontable/angular';
import { LegendModule } from 'frontend/src/dashboard/legend/legend.module';
import { ContextMenuPopupModule } from 'frontend/src/dashboard/popups-services/context-menu-popup/context-menu-popup.module';
import { MySharedModule } from 'frontend/src/dashboard/shared/shared.module';
import { SaxmsColumnHeaderTemplateComponent } from '../core/saxms-column-header-template/saxms-column-header-template.component';
import { CustomEditorComponent } from '../customEditor/customEditor.component';
import { TextareaOverlayComponent } from '../elements/textarea-overlay/textarea-overlay.component';
import { MessageBarComponent } from '../message-bar/message-bar.component';
import { FullSpreadsheetComponent } from './full-spreadsheet.component';

@NgModule({
  imports: [
    ClipboardModule,
    CommonModule,
    MySharedModule,
    TemplateDatepickerModule,
    TemplateDurationPickerModule,
    ContextMenuPopupModule,
    SlotModule,
    MatSnackBarModule,
    HotTableModule,
    SelectorModule,
    ImageModule,
    SaxmsTooltipModule,
    ElementsModule,
    LegendModule,
    NumericRangePickerModule,
    MessageBarComponent,
    TextareaOverlayComponent,
    ColorButtonModule,
  ],
  declarations: [FullSpreadsheetComponent, SaxmsColumnHeaderTemplateComponent, CustomEditorComponent],
  exports: [FullSpreadsheetComponent],
})
export class FullSpreadsheetModule {}
