<div class="leaflet-map-wrapper">
  <div
    class="leaflet-map-container"
    [ngStyle]="{ 'min-height': minHeight + 'px' }"
    leaflet
    [leafletOptions]="options"
    (leafletMapReady)="onMapReady($event)"
    (leafletMapZoomEnd)="afterZoomEnd($event)"
  ></div>
  <div class="leaflet-map-legend-container" [ngStyle]="{ height: legendVisible ? '80px' : '30px' }">
    <div class="leaflet-map-legend-layer-switch-container">
      <ng-container *ngTemplateOutlet="mapLegendLayerSwitchContainer"> </ng-container>
    </div>
    <div class="leaflet-map-legend-page-container">
      <ng-container *ngTemplateOutlet="mapLegendPagingContainer"> </ng-container>
    </div>
    <div *ngIf="legendVisible" class="leaflet-map-legend-marker-container">
      <ng-container *ngTemplateOutlet="mapLegendItemsContainer"> </ng-container>
    </div>
    <div class="leaflet-map-legend-button-container">
      <button class="icon-btn" mat-icon-button (click)="toggleStateOfAllElments()">
        <mat-icon>visibility</mat-icon>
      </button>
      <button
        *ngIf="!onPlay && mapTemplate.currentMapLayer?.mapLayerType === 'MOVEMENT'"
        class="icon-btn"
        [matMenuTriggerFor]="playTimeMenu"
        [disabled]="mapTemplate.zoom < 4"
        mat-icon-button
      >
        <mat-icon>play_arrow</mat-icon>
      </button>
      <button
        *ngIf="onPlay && mapTemplate.currentMapLayer?.mapLayerType === 'MOVEMENT'"
        class="icon-btn"
        (click)="handlePlay()"
        mat-icon-button
      >
        <mat-icon>stop</mat-icon>
      </button>

      <mat-menu #playTimeMenu="matMenu">
        <button mat-menu-item (click)="handlePlaytime(1)">1 {{ 'hour' | translate }}</button>
        <button mat-menu-item (click)="handlePlaytime(5)">5 {{ 'hours' | translate }}</button>
        <button mat-menu-item (click)="handlePlaytime(10)">10 {{ 'hours' | translate }}</button>
        <button mat-menu-item (click)="handlePlaytime(15)">15 {{ 'hours' | translate }}</button>
        <button mat-menu-item (click)="handlePlaytime(24)">24 {{ 'hours' | translate }}</button>
      </mat-menu>

      <button class="icon-btn" *ngIf="legendVisible" mat-icon-button [matMenuTriggerFor]="searchmenu">
        <mat-icon>search</mat-icon>
      </button>

      <div *ngIf="playStartTime" class="playtime-span">
        {{ getPlayStartTime() }}
      </div>
    </div>
  </div>
</div>

<mat-menu #searchmenu="matMenu">
  <div class="menu-item" mat-menu-item (click)="stopPropagation($event)">
    <mat-form-field>
      <input matInput placeholder="Input" (keydown)="handleSearch($event)" />
    </mat-form-field>
  </div>
  <!--<ng-container mat-menu-item>
        <mat-form-field>
            <input matInput placeholder="Input" (change)="handleSearch($event)">
        </mat-form-field>
    </ng-container>-->
</mat-menu>
