import { GanttDataRow, GanttDataShift } from '../../../data-handler/data-structure/data-structure';
import { DataManipulator } from '../../../data-handler/data-tools/data-manipulator';
import { ShiftDataSorting } from '../../../data-handler/data-tools/data-sorting';
import { GanttUtilities } from '../../../gantt-utilities/gantt-utilities';
import { GanttSplitOverlappingShiftGroup, GanttSplitOverlappingShifts } from '../split-overlapping-shifts-executer';
import { BaseOverlappingStrategy } from './base';

/**
 * @deprecated Bad performance. Use GanttSplitOverlappingShiftsByAttributeStrategy if you want to split by an attribute.
 */
export class GanttSplitOverlappingShiftsPrioritizedStrategy implements BaseOverlappingStrategy {
  public readonly strategyType = 'prioritized';

  /**
   * @param {GanttSplitOverlappingShifts} scope Reference to GanttSplitOverlappingShifts to use calculation functions.
   * @param {string[]} [ganttRowIds] Affected Row Ids. If not set, all rows will be split.
   */
  public splitOverlappingShifts(scope: GanttSplitOverlappingShifts, ganttRowIds?: string[]): void {
    const s = this;
    scope.parentShiftHandler.clearParentShiftRowSpanData();

    const priorityExtraction = (child: GanttDataRow, level, parent, index) => {
      if (scope.ruleSet.notAffectedRows.includes(child.id) || (ganttRowIds?.length && !ganttRowIds.includes(child.id)))
        return;
      const priorityShiftList = new Map<string, { shifts: GanttDataShift[]; yIndex: number }>();
      // init priority-objects by read rules
      scope.ruleSet.prioritiseAttributes.forEach((rule) =>
        priorityShiftList.set(rule.value, { shifts: [], yIndex: 0 })
      );
      // add least priority to collect all shift blocks which matches no priority value
      priorityShiftList.set(null, { shifts: [], yIndex: 0 });

      const noRenderShifts = [];

      if (!child.shifts || child.shifts.length < 2) return;
      for (const shift of child.shifts) {
        if (shift.noRender && shift.noRender.length) {
          noRenderShifts.push(shift);
          continue;
        }
        let ruleFound = false;
        for (const rule of scope.ruleSet.prioritiseAttributes) {
          const shiftValue = s._getShiftValue(shift, rule.propertyList);
          const matchingPriorityShiftData = priorityShiftList.get(shiftValue);
          if (matchingPriorityShiftData) {
            matchingPriorityShiftData.shifts.push(shift);
            ruleFound = true;
            break;
          }
        }
        if (!ruleFound) {
          // put shifts which do not match any priority on end
          priorityShiftList.get(null).shifts.push(shift);
        }
      }

      const allRows = s._calculateAllRowsWithoutShiftOverlapping(scope, child, priorityShiftList, noRenderShifts);

      // save reset data
      const newRowEntry = new GanttSplitOverlappingShiftGroup(child.id);
      newRowEntry.groupedRowIds = allRows
        .filter(function (d, i) {
          return i > 0;
        })
        .map(function (d) {
          return d.id;
        });
      scope.shiftGroups.push(newRowEntry);

      // insert new rows
      const parentRow = level == 0 ? parent.ganttEntries : parent.child;
      parentRow.splice(index.index, 1, ...allRows);
      index.index += allRows.length - 1;
    };
    DataManipulator.iterateOverDataSet(
      scope.ganttDiagram.getDataHandler().getOriginDataset().ganttEntries,
      null,
      { priorityExtraction: priorityExtraction },
      null,
      scope.ganttDiagram.getDataHandler().getOriginDataset()
    );
  }

  /**
   * Generates a list of gantt rows which are based on given gantt row and have no overlapping shifts.
   * @private
   * @param {GanttSplitOverlappingShifts} scope Reference to GanttSplitOverlappingShifts to use calculation functions.
   * @param {GanttDataRow} row Original row data.
   * @param {GanttSplitOverlappingShiftsPriorisedStrategyDataItem[]} priorityList Shifts which are partitioned by priorities.
   * @param {GanttDataShift[]} [noRenderShifts] List of shifts which will be not rendered.
   */
  private _calculateAllRowsWithoutShiftOverlapping(
    scope: GanttSplitOverlappingShifts,
    row: GanttDataRow,
    priorityList: Map<
      string,
      {
        shifts: GanttDataShift[];
        yIndex: number;
      }
    >,
    noRenderShifts: GanttDataShift[]
  ): GanttDataRow[] {
    // 0. Initialize map of linked parent shifts.
    const linkedParentShifts = scope.parentShiftHandler.getLinkedParentShifts(row.shifts);
    const parentShiftRowSpanMap = scope.parentShiftHandler.getParentShiftRowSpans(row.shifts);
    // 1. Empty all shifts of origin row to base all calculations of given rowInfo
    row.shifts = [];
    // 2. Start with one (given) row
    const allRows: GanttDataRow[] = [row];
    // 3. Check all given shifts iteratively if they do overlap.
    // Pay attention to priority.
    const shiftRowIndexMap = new Map<string, number>();
    const waitingChildsMap = new Map<string, GanttDataShift[]>();
    priorityList.forEach((priorityItem) => {
      for (let j = 0; j < priorityItem.shifts.length; j++) {
        const shift = priorityItem.shifts[j];
        // if shift has linked parent shift -> push shift to same row as parent shift
        const linkedParentShift = linkedParentShifts.get(shift.id);
        if (linkedParentShift) {
          const shiftRowIndex = shiftRowIndexMap.get(linkedParentShift.id);
          // if parent shift already was pushed -> push shift
          if (!isNaN(shiftRowIndex)) {
            scope.parentShiftHandler.insertChildShiftIntoParentShift(
              shift,
              shiftRowIndex,
              allRows,
              row,
              shiftRowIndexMap
            );
          }
          // if pushing the parent shift is pending -> wait until parent shift is added
          else {
            if (!waitingChildsMap.get(linkedParentShift.id)) waitingChildsMap.set(linkedParentShift.id, []);
            waitingChildsMap.get(linkedParentShift.id).push(shift);
          }
          continue;
        }
        const overlappingShift = scope.overlapsInsideShiftList(
          shift,
          [
            ...scope.parentShiftHandler.getVirtualParentShiftsForRow(
              priorityItem.yIndex,
              shiftRowIndexMap,
              parentShiftRowSpanMap
            ),
            ...allRows[priorityItem.yIndex].shifts,
          ],
          waitingChildsMap
        );
        // Integrate shift if there is no overlapping.
        if (!overlappingShift) {
          allRows[priorityItem.yIndex].shifts.push(shift);
          shiftRowIndexMap.set(shift.id, priorityItem.yIndex);
        } else {
          // Check if block belongs to current partition, because partitions can be mixed.
          // The shifts are not sorted by date all the time, because they will be added by priority-order.
          // So you have to check all shifts inside the row for overlapping.
          if (
            priorityItem.shifts.find(function (priotrityItem) {
              return priotrityItem.id == overlappingShift.id;
            })
          ) {
            // Add new row if necessary.
            let rowFound = false;
            let rowInsertIndex = priorityItem.yIndex;
            while (!rowFound) {
              if (rowInsertIndex > allRows.length - 1) {
                const overlappingVirtualParentShift = scope.overlapsInsideShiftList(
                  shift,
                  scope.parentShiftHandler.getVirtualParentShiftsForRow(
                    rowInsertIndex,
                    shiftRowIndexMap,
                    parentShiftRowSpanMap
                  )
                );
                // Add new row and insert shift into row.
                const rowIdSuffix = scope.UUID + '_' + rowInsertIndex;
                const insertRow = GanttUtilities.createNewRowFromRow(row, rowIdSuffix, 'MEMBER');
                allRows.push(insertRow);
                if (overlappingVirtualParentShift) {
                  rowInsertIndex++;
                  continue;
                }
                insertRow.shifts.push(shift);
                shiftRowIndexMap.set(shift.id, allRows.length - 1);
                rowFound = true;
              } else {
                // If there is a overlapping, go to next row and repeat process.
                if (
                  scope.overlapsInsideShiftList(
                    shift,
                    [
                      ...scope.parentShiftHandler.getVirtualParentShiftsForRow(
                        rowInsertIndex,
                        shiftRowIndexMap,
                        parentShiftRowSpanMap
                      ),
                      ...allRows[rowInsertIndex].shifts,
                    ],
                    waitingChildsMap
                  )
                ) {
                  rowInsertIndex++;
                } else {
                  const insertRow = allRows[rowInsertIndex];
                  insertRow.shifts.push(shift);
                  ShiftDataSorting.sortJSONListByDate(insertRow.shifts, 'timePointStart');
                  shiftRowIndexMap.set(shift.id, rowInsertIndex);
                  rowFound = true;
                }
              }
            }
          }
          // If there is a overlapping with other priorities,
          // the whole partition has to be moved.
          else {
            // Remove all inserted shifts from current row.
            for (let rowIndex = priorityItem.yIndex; rowIndex < allRows.length; rowIndex++) {
              // in this case -> set is faster then array
              const shiftsToRemove = new Set(priorityItem.shifts.map((shift) => shift.id));
              this._removeShiftsFromRow(shiftsToRemove, allRows[rowIndex]);
              this._removeShiftsFromMaps(shiftsToRemove, shiftRowIndexMap, waitingChildsMap);
            }
            // increase counter
            priorityItem.yIndex++;
            // add new row if necessary
            if (priorityItem.yIndex > allRows.length - 1) {
              const rowIdSuffix = scope.UUID + '_' + priorityItem.yIndex;
              const newRow = GanttUtilities.createNewRowFromRow(row, rowIdSuffix, 'MEMBER');
              allRows.push(newRow);
            }
            // reset for loop
            j = -1;
            continue;
          }
        }
        // if shift is parent shift that child shifts are waiting for -> add childs
        if (waitingChildsMap.get(shift.id)) {
          const shiftRowIndex = shiftRowIndexMap.get(shift.id);
          for (const childShift of waitingChildsMap.get(shift.id)) {
            scope.parentShiftHandler.insertChildShiftIntoParentShift(
              childShift,
              shiftRowIndex,
              allRows,
              row,
              shiftRowIndexMap
            );
          }
          waitingChildsMap.delete(shift.id);
        }
        // if shift is parent shift -> add row id to parent shift row span data
        if (parentShiftRowSpanMap.get(shift.id)) {
          parentShiftRowSpanMap.get(shift.id).rowId = allRows[shiftRowIndexMap.get(shift.id)].id;
        }
      }
    });

    // Add the calculated parent shift row span data to the parent shift handler.
    scope.parentShiftHandler.addParentShiftRowSpanData(parentShiftRowSpanMap);

    // If the list with with not rendered shifts has been given, add them to first row.
    if (noRenderShifts) {
      allRows[0].shifts = allRows[0].shifts.concat(noRenderShifts);
      ShiftDataSorting.sortJSONListByDate(allRows[0].shifts, 'timePointStart');
    }

    // Add grouping-row-layout if there are additional rows.
    if (allRows.length > 1) {
      const children = allRows[0].child;
      allRows[0].group = allRows[0].child.length ? 'BASE-TREE' : 'BASE-LEAF';
      for (let i = 0; i < allRows.length - 1; i++) {
        allRows[i].child = [];
      }
      // add origin childs
      allRows[allRows.length - 1].child = children;
    }
    return allRows;
  }

  /**
   * Helper function to remove given shifts from given row.
   * @private
   * @param {string[]} shifts Shift ids to remove from row.
   * @param {GanttDataRow} row Row which will be affected by removal.
   */
  private _removeShiftsFromRow(shiftIdsToRemove: Set<string>, row: GanttDataRow): void {
    row.shifts = row.shifts.filter((shift) => {
      return !shiftIdsToRemove.has(shift.id);
    });
  }

  /**
   * Helper function to remove given shifts from temporary maps.
   * @param shifts Shift ids to remove.
   * @param shiftRowIndexMap Map of shift ids and their row indexes.
   * @param waitingChildsMap Map of parent shift ids and their waiting child shifts.
   */
  private _removeShiftsFromMaps(
    shifts: Set<string>,
    shiftRowIndexMap: Map<string, number>,
    waitingChildsMap: Map<string, GanttDataShift[]>
  ): void {
    // remove shift id from shiftRowIndexMap
    for (const shiftId of shifts) shiftRowIndexMap.delete(shiftId);
    // remove shift id from waitingChildsMap
    for (const waitingChilds of waitingChildsMap.values()) {
      for (let i = 0; i < waitingChilds.length; i++) {
        if (shifts.has(waitingChilds[i].id)) waitingChilds.splice(i, 1);
      }
    }
  }

  /**
   * @private
   * @param {GanttDataShift} shiftData
   * @param {string[]} probertyList
   * @returns Found value. If value doesnt exist, returns null.
   */
  private _getShiftValue(shiftData: GanttDataShift, probertyList: string[]) {
    let foundValue = shiftData[probertyList[0]];
    if (foundValue == null) return null;
    for (let i = 1; i < probertyList.length; i++) {
      const currentProperty = probertyList[i];
      const valueProperty = foundValue[currentProperty];
      if (valueProperty == null) return null;
      foundValue = valueProperty;
    }
    return foundValue;
  }
}

/**
 * Data structure for priorised shifts overlapping calculation.
 * @constructor
 * @param {string} priorityValue
 */
export interface IGanttSplitOverlappingShiftsPriorisedStrategyDataItem {
  priorityValue: string;
  shifts: any[];
  yIndex: number;
}
