import { Component, OnInit, Input, OnDestroy, ContentChild } from '@angular/core';

@Component({
  selector: 'app-customEditor',
  templateUrl: './customEditor.component.html',
  styleUrls: ['./customEditor.component.scss'],
})
export class CustomEditorComponent implements OnInit, OnDestroy {
  @Input() id;
  @Input() cellEditorContext;
  @ContentChild('cellEditor') cellEditor: any;
  constructor() {}
  ngOnDestroy(): void {}

  ngOnInit() {}
}
