<mat-list>
  <mat-list-item class="contentText">
    {{ 'GANTT.SHIFT.roundedCorners' | translate }}
    <mat-slide-toggle (change)="handleRoundedCorners($event)" [checked]="settings.roundCorners"></mat-slide-toggle>
  </mat-list-item>
  <mat-divider></mat-divider>
  <mat-list-item class="contentText">
    {{ 'GANTT.SHIFT.blockHeight' | translate }}
    <app-slider
      class="sliderContainer"
      [max]="settings.rowHeight / 2"
      [min]="1"
      [value]="blockHeight"
      [label]="blockHeightLabel"
      (changeEvent)="blockHeightSliderChange($event)"
    ></app-slider>
  </mat-list-item>
  <mat-divider></mat-divider>
  <mat-list-item class="contentText">
    {{ '@selection-color@' | translate }}
    <app-color-picker
      [color]="settings.selectColor"
      (changeEvent)="setSelectionColor($event)"
      [resetColor]="'#5555ff'"
      [disableAlpha]="true"
    >
    </app-color-picker>
  </mat-list-item>
  <mat-divider></mat-divider>
  <mat-list-item class="contentText">
    {{ 'GANTT.SHIFT.transparentSelection' | translate }}
    <app-slider
      class="sliderContainer"
      [max]="1"
      [min]="0"
      [step]="0.05"
      [value]="selectionTransparency"
      [label]="selectionTransparencyLabel"
      (changeEvent)="selectionTransparencyChange($event)"
    ></app-slider>
  </mat-list-item>
  <mat-divider></mat-divider>
  <mat-list-item class="contentText">
    <span>{{ 'GANTT.ELEMENTS.shiftConnections' | translate }}</span>
    <mat-form-field class="select" appearance="standard">
      <mat-select [(value)]="selectedBlockConnectionOption" (selectionChange)="onBlockConnectionOptionChange($event)">
        <mat-option *ngFor="let blockConnectionOption of blockConnectionOptions" [value]="blockConnectionOption.value">
          {{ blockConnectionOption.viewValue | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </mat-list-item>
  <mat-divider></mat-divider>
  <mat-list-item class="contentText">
    <span>{{ 'GANTT.ELEMENTS.sortByAttribute' | translate }}</span>
    <mat-form-field class="select" appearance="standard">
      <mat-select
        [(value)]="selectedBlockSortingOption"
        (selectionChange)="onBlockSortingAttributeChange($event.value)"
      >
        <mat-option *ngFor="let blockSortingOption of blockSortingOptions" [value]="blockSortingOption">
          {{ blockSortingOption }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </mat-list-item>
</mat-list>
