import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { DOCUMENT } from '@angular/common';
import { ComponentRef, Directive, ElementRef, Inject, Injector, NgZone, OnDestroy, OnInit } from '@angular/core';
import { IOverlayOptions } from '@app-modeleditor/components/lightbox/overlay/overlay-options.interface';
import { OverlayService } from '@app-modeleditor/components/lightbox/overlay/overlay.service';
import { GanttLibService } from 'frontend/src/dashboard/gantt/gantt/gantt-lib.service';
import { fromEvent, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ResourceCommunicationService } from './resource-communication.service';
import { ResourcesComponent } from './resources.component';
import { ISelectionBoxFenceCoordinates } from './selection-box/selection-box-fence-coordinates';
import { ISelectionBoxProportions } from './selection-box/selection-box-proportions.interface';
import { SelectionBoxComponent } from './selection-box/selection-box.component';

@Directive({
  selector: '[resourceSelectionBox]',
})
export class ResourceSelectionBoxDirective implements OnInit, OnDestroy {
  private _element: HTMLElement;
  private _subscriptions: Subscription[] = [];
  private _currentOverlayRef: OverlayRef;
  private _startMouseEvent: MouseEvent;
  private _isDragging = false;
  private _componentRef: ComponentRef<SelectionBoxComponent>;

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    private _elementRef: ElementRef,
    private _overlay: Overlay,
    private _host: ResourcesComponent,
    @Inject('InternalOverlayService') private _overlayService: OverlayService,
    private _ganttLibService: GanttLibService,
    private _ngZone: NgZone,
    private _resourceCommunicationService: ResourceCommunicationService,
    private injector: Injector
  ) {}

  ngOnInit(): void {
    this._element = this._elementRef.nativeElement as HTMLElement;
    this._ngZone.runOutsideAngular((_) => this.initDrag());
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach((s) => {
      if (s) s.unsubscribe();
    });
  }

  initDrag(): void {
    const dragStart$ = fromEvent<MouseEvent>(this._element, 'mousedown');
    const dragEnd$ = fromEvent<MouseEvent>(this._document, 'mouseup');
    const drag$ = fromEvent<MouseEvent>(this._document, 'mousemove').pipe(takeUntil(dragEnd$));

    let dragSub: Subscription;

    // dragStart
    const dragStartSub = dragStart$.subscribe((event: MouseEvent) => {
      this._startMouseEvent = event;
      // drag
      dragSub = drag$.subscribe((event: MouseEvent) => {
        if (!this._isDragging) {
          this._isDragging = true;
          this.createOverlay(event);
          if (this._startMouseEvent.shiftKey || this._startMouseEvent.ctrlKey) {
          } else {
            this._resourceCommunicationService.clearSelectedResources();
          }
          this._resourceCommunicationService.emitSingleSelection();
          return;
        }
        event.preventDefault();
        this._componentRef.instance.setProportions(this._getNewSelectionBoxProportionsByMouseEvent(event));
      });
    });

    // dragEnd
    const dragEndSub = dragEnd$.subscribe((event: MouseEvent) => {
      if (this._isDragging) {
        // real drag end
        this._isDragging = false;
        const fence = this._getSelectionBoxFenceBySelectionBoxProportions(
          this._getNewSelectionBoxProportionsByMouseEvent(event)
        );
        this._resourceCommunicationService.emitSelectionFence(fence);
        this._currentOverlayRef.dispose();
      }
      if (dragSub) {
        dragSub.unsubscribe();
      }
    });

    this._subscriptions.push.apply(this._subscriptions, [dragStartSub, dragSub, dragEndSub]);
  }

  private createOverlay(event: MouseEvent) {
    const overlayOptions: IOverlayOptions = {
      backdrop: false,
    };
    const proportions = this._getNewSelectionBoxProportionsByMouseEvent(event);
    this._ngZone.run((_) => {
      const creation = this._overlayService.create(
        SelectionBoxComponent,
        null,
        proportions,
        overlayOptions,
        this.injector
      );
      this._currentOverlayRef = creation.overlayRef;
      this._componentRef = creation.componentRef;
    });
  }

  private _getNewSelectionBoxProportionsByMouseEvent(event: MouseEvent): ISelectionBoxProportions {
    let y = this._startMouseEvent.pageY;
    let height = event.pageY - this._startMouseEvent.pageY;
    if (height < 0) {
      y = y + height;
      height = -height;
    }
    let width = event.pageX - this._startMouseEvent.pageX;
    let x = this._startMouseEvent.pageX;
    if (width < 0) {
      x = x + width;
      width = -width;
    }
    const proportions: ISelectionBoxProportions = {
      height: height,
      width: width,
      y: y,
      x: x,
    };

    return proportions;
  }

  private _getSelectionBoxFenceBySelectionBoxProportions(
    proportions: ISelectionBoxProportions
  ): ISelectionBoxFenceCoordinates {
    const fence: ISelectionBoxFenceCoordinates = {
      topLeft: [proportions.x, proportions.y],
      bottomRight: [proportions.x + proportions.width, proportions.y + proportions.height],
    };
    return fence;
  }
}
