import { Pipe, PipeTransform } from '@angular/core';

// Tell Angular2 we're creating a Pipe with TypeScript decorators
@Pipe({
  name: 'cut',
})
export class CutPipe implements PipeTransform {
  transform(items: any, offset: number, limit: number): any {
    if (!items) {
      return;
    }

    const temp = [];
    limit--;
    while (limit >= 0) {
      if (offset + limit < items.length) {
        temp.push(items[offset + limit]);
      }
      limit--;
    }
    return temp.reverse();
  }
}
