import { EntryElementValue } from '@app-modeleditor/components/entry-collection/entry-element-value';

export class ChartLegendQuickfilter extends EntryElementValue {
  private readableClassName: string;
  private originalFilter: string;
  private position: number;
  private hideToMe: boolean;
  private hiddenAttributes: string[];
  private myFilter: boolean;
  private publicFilter: boolean;
  private selected: boolean;

  public getReadableClassName(): string {
    return this.readableClassName;
  }

  public setReadableClassName(readableClassName: string): this {
    this.readableClassName = readableClassName;
    return this;
  }

  public getOriginalFilter(): string {
    return this.originalFilter;
  }

  public setOriginalFilter(originalFilter: string): this {
    this.originalFilter = originalFilter;
    return this;
  }

  public getPosition(): number {
    return this.position;
  }

  public setPosition(position: number): this {
    this.position = position;
    return this;
  }

  public isHideToMe(): boolean {
    return this.hideToMe;
  }

  public setHideToMe(hideToMe: boolean): this {
    this.hideToMe = hideToMe;
    return this;
  }

  public getHiddenAttributes(): string[] {
    return this.hiddenAttributes || [];
  }

  public setHiddenAttributes(hiddenAttributes: string[]): this {
    this.hiddenAttributes = hiddenAttributes;
    return this;
  }

  public isMyFilter(): boolean {
    return this.myFilter;
  }

  public setMyFilter(myFilter: boolean): this {
    this.myFilter = myFilter;
    return this;
  }

  public isPublicFilter(): boolean {
    return this.publicFilter;
  }

  public setPublicFilter(publicFilter: boolean): this {
    this.publicFilter = publicFilter;
    return this;
  }

  public isSelected(): boolean {
    return this.selected;
  }

  public setSelected(selected: boolean): this {
    this.selected = selected;
    return this;
  }
}
