import { Template } from 'frontend/src/dashboard/model/resource/template';

export class Condition {
  data = '';
  keys: string[] = [];
  errorStack: any[] = [];

  constructor(str: string) {
    this.data = str;
  }

  /**
   * Validate dataset based on condition
   * @param dataset dataset
   */
  public validate(dataset: any[]): number {
    if (!this.data) {
      return -1;
    }

    const orConditions: string[] = this.data.split(' || ');

    const conditionsIndices: number[] = orConditions.map((orCondition: string) => {
      const ands: string[] = orCondition.split(' && ');
      this.errorStack = [];
      this.keys = [];

      return ands.findIndex((and: string) => this.checkCondition(dataset, and) === false);
    });
    const m: number = conditionsIndices.find((id: number) => id === -1);

    if (m) {
      return -1;
    }
    return conditionsIndices[0];
  }

  /**
   * get error message for condition
   * @returns string
   */
  public getError(): string {
    let msg = '';
    for (const e of this.errorStack) {
      msg += `${e.name ? e.name : e} `;
    }

    return msg;
  }

  /**
   * Get value of object based on key
   * @param dataset dataset
   * @param condition condition
   */
  private getKeyValue(dataset: Template[], condition: string): string {
    if (condition[0] !== '[') {
      this.errorStack.push(condition);
      return eval(condition);
    }
    const start: number = condition.indexOf('[');
    const end: number = condition.indexOf(']');

    if (start === -1 || end === -1) {
      return null;
    }

    const key: string = condition.substr(start + 1, end - (start + 1));

    const rest: string = condition.substr(end + 1, condition.length - (end + 1));
    const parts: string[] = rest.split('.');

    const identifier = this.getIdentifier(dataset, key);
    return this.accessProperty(identifier, parts);
  }

  /**
   * Get element from dataset for specified id
   * @param dataset dataset
   * @param key id
   */
  getIdentifier(dataset: any[], key: string): any {
    for (const k of dataset) {
      // Check if element has the correct id
      if (key === k.id) {
        this.errorStack.push(k);
        this.keys.push(k.id);
        return k.selectedValues || k.value || k.elementData;
      }
      // Check if an entry element has the correct id
      const entryElements = k.entryElements || [];
      const e = entryElements.find((e) => e.id === key);
      if (e) {
        this.errorStack.push(e);
        this.keys.push(e.id);
        return k.selectedValues || e.value || e.elementData;
      }
    }

    return null;
  }

  private isEmpty(obj): boolean {
    if (typeof obj !== 'object' || !obj) {
      return false;
    }

    for (const key of Object.keys(obj)) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  /**
   * access each properties entry inside dataset
   * @param dataset dataset
   * @param properties list of nested element key
   */
  private accessProperty(obj: any, properties: string[]): any {
    while (properties.length > 0) {
      const current = properties.shift();
      if (current === '') {
        continue;
      }

      if (obj === null || obj === undefined) {
        return null;
      }
      obj = obj[current];
    }

    if (obj === null || obj === undefined) {
      return null;
    }

    if (typeof obj === 'boolean') {
      return obj;
    }

    const d: Date = new Date(obj);
    if (d instanceof Date && !isNaN(d.getTime())) {
      return d.getTime();
    }

    return this.isEmpty(obj) ? null : obj || null;
  }

  /**
   * check if condition matches dataset from elements
   * @param dataset contains data of real elements
   * @param condition string that contains the expression to validate
   */
  private checkCondition(dataset: Template[], condition: string): boolean {
    // part[1] => "<=|>=..." part[2] => condition to check against
    const parts: string[] = condition.split(' ');
    // elements key

    const elementKey = this.getKeyValue(dataset, parts[0]);
    this.errorStack.push(parts[1]);
    const elementKey2 = this.getKeyValue(dataset, parts[2]);
    const result: boolean = this.checkValue(elementKey, parts[1], elementKey2);

    return result;
  }

  private checkValue(a, operator, b): boolean {
    switch (operator) {
      case '==':
      case '===':
        return a == b;
      case '!=':
      case '!==':
        return a != b;
      case '<':
        return a < b;
      case '>':
        return a > b;
      case '>=':
      case '>==':
        return a >= b;
      case '<=':
      case '<==':
        return a <= b;
      default:
        throw Error(`operator "${operator}" was not found!`);
    }
    return true;
  }
}
