import { Component, ComponentRef, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Action } from '@app-modeleditor/components/button/action/action';
import { EActionType } from '@app-modeleditor/components/button/action/action-type.enum';
import { Button } from '@app-modeleditor/components/button/button';
import { EButtonDisplayType } from '@app-modeleditor/components/button/button-display-type.enum';
import { EDisplayOrientation } from '@app-modeleditor/components/content/content-element/display-orientation.enum';
import { TemplateComponent } from '@app-modeleditor/components/template/template.component';
import { IHierarchicItem } from '@app-modeleditor/components/tree/tree.model';
import { TemplateHotkeyService } from '@app-modeleditor/utils/template-hotkey.service';
import { EResizeType } from 'frontend/src/dashboard/view/resize/resize-type.enum';
import { ResizeService } from 'frontend/src/dashboard/view/resize/resize.service';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { Lightbox } from '../lightbox';
import { WindowService } from './../../../../view/window/window.service';
import { EPredefinedAction } from './../../button/action/predefined-action.enum';
import { EntryCollection } from './../../entry-collection/entry-collection';

@Component({
  selector: 'app-dialog-content',
  templateUrl: './dialog-content.component.html',
  styleUrls: ['./dialog-content.component.scss'],
})
export class DialogContentComponent implements OnInit, OnDestroy {
  private instance: ComponentRef<TemplateComponent>;
  private actionsInstance: ComponentRef<TemplateComponent>;
  closeLightboxBtn: Button;
  openNewWindowBtn: Button;
  @Input() lightbox: Lightbox;
  @Input() dragPosition: { x: number; y: number } = { x: 0, y: 0 };
  private content: ElementRef;
  @ViewChild('content') set _content(c: ElementRef) {
    this.content = c;
    if (this.instance && c) {
      of(null)
        .pipe(delay(0))
        .subscribe(() => {
          this.instance.instance.root = c.nativeElement;
        });
    }
  }
  private resizeIds: string[] = [null, null];
  lightboxHeaderRef: ElementRef;
  @ViewChild('lightboxHeader') set c1(lightboxHeaderRef: ElementRef) {
    this.lightboxHeaderRef = lightboxHeaderRef;
    this._resizeApi.complete(this.resizeIds[0]);
    if (!lightboxHeaderRef) {
      return;
    }
    this.resizeIds[0] = this._resizeApi.create(lightboxHeaderRef.nativeElement, this._checkDimensions.bind(this), {
      types: [EResizeType.HEIGHT],
    });
  }
  lightboxFooterRef: ElementRef;
  @ViewChild('lightboxFooter') set c2(lightboxFooterRef: ElementRef) {
    this.lightboxFooterRef = lightboxFooterRef;
    this._resizeApi.complete(this.resizeIds[1]);
    if (!lightboxFooterRef) {
      return;
    }
    this.resizeIds[1] = this._resizeApi.create(lightboxFooterRef.nativeElement, this._checkDimensions.bind(this), {
      types: [EResizeType.HEIGHT],
    });
  }

  offset = 4;
  // combined height of header and footer including an extra offset
  combinedHeight: number;
  containerMaxHeight: string;
  containerHeight: string;
  constructor(
    public elementRef: ElementRef,
    private windowApi: WindowService,
    private _hotkeyApi: TemplateHotkeyService,
    private _resizeApi: ResizeService
  ) {}

  private _checkDimensions(): void {
    const height: number =
      (this.lightboxFooterRef?.nativeElement.clientHeight || 0) +
      (this.lightboxHeaderRef?.nativeElement.clientHeight || 0) +
      (this.offset || 0);
    if (this.combinedHeight !== height) {
      of(null)
        .pipe(delay(0))
        .subscribe(() => {
          this._hotkeyApi.focusTemplate(this.elementRef.nativeElement, this.lightbox.getId());
          this.combinedHeight = height;
          this.containerMaxHeight = `calc(100vh - ${this.combinedHeight + 5}px)`;
          this.containerHeight = `calc(100% - ${this.combinedHeight + 5}px)`;
        });
    }
  }

  onLightboxClicked(event: MouseEvent): void {
    this._hotkeyApi.focusTemplate(event.target as HTMLElement, this.lightbox.getId());
  }

  getActions(resourceId?: string): EntryCollection {
    return new EntryCollection()
      .setResourceId(this.lightbox.getResourceId())
      .setPreventNewLines(true)
      .setDisplayOrientation(EDisplayOrientation.HORIZONTAL)
      .setEntryElements(this.lightbox.getDefaultButtons());
  }

  ngOnInit(): void {
    this.lightbox.setReinitializationCB(() => {
      if (this.actionsInstance) {
        this.actionsInstance.instance.templateNode = this.getActions();
      }
    });

    this.closeLightboxBtn = this.lightbox.getCustomCancelButton()
      ? this.lightbox
          .getCustomCancelButton()
          .copy(Button)
          .setIcon('close')
          .setName('close')
          .setDisplayType(EButtonDisplayType.ICON_ONLY)
      : (this.lightbox.getOnCustomCancelAction() ? this.lightbox.getOnCustomCancelAction().copy(Button) : new Button())
          .setIcon('close')
          .setName('close')
          .setDisplayType(EButtonDisplayType.ICON_ONLY);

    // filter for cancel lightbox actions to be sure that only one action is executed
    if (this.closeLightboxBtn.removeActions(EPredefinedAction.CANCEL_LIGHTBOX, EPredefinedAction.CLOSE_LIGHTBOX)) {
      this.closeLightboxBtn.chainActions(
        new Action().setId(EPredefinedAction.CANCEL_LIGHTBOX).setActionType(EActionType.PREDEFINED)
      );
    }

    this.openNewWindowBtn = new Button()
      .setDisplayType(EButtonDisplayType.ICON_ONLY)
      .setIcon('open_in_new')
      .chainActions(
        new Action().setCb(() => {
          const clone: Lightbox = this.lightbox.copy(Lightbox);
          clone.setCanOpenExternalWindow(false).setResizeable(false).setDisableClose(true);
          this.windowApi.openWindow({ content: clone as unknown as IHierarchicItem, type: 'tab' });

          return of(null);
        })
      );
  }

  afterInitSlot(instance: ComponentRef<TemplateComponent>, index: number): void {
    switch (index) {
      case 0:
        this.instance = instance;
        this.instance.instance.templateNode = this.lightbox;
        if (this.content) {
          this.instance.instance.root = this.content.nativeElement;
        }
        break;
      case 1:
        this.actionsInstance = instance;
        this.actionsInstance.instance.templateNode = this.getActions();
        break;
      default:
        break;
    }
  }

  close(result?: any): void {
    // this.instance?.destroy();
    // this.actionsInstance?.destroy();
  }

  ngOnDestroy(): void {
    this._resizeApi.complete(...this.resizeIds);
  }
}
