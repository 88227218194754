<time-duration-picker
  #picker
  [inputDisabled]="disabled"
  returnedValueUnit="hour"
  returnedValueUnit="millisecond"
  (onChange)="onNumberChanged($event)"
  class="row"
>
  <div class="time-duration-picker" style="height: 50px; display: flex" [sytle.color]="'red'">
    <div class="time-duration-picker-input-wrapper">
      <time-duration-picker-unit
        #week
        [name]="'week'"
        [label]="'@weekShort@' | translate"
        [min]="0"
        [step]="1"
      ></time-duration-picker-unit>
    </div>
    <div class="time-duration-picker-input-wrapper">
      <time-duration-picker-unit
        #day
        [name]="'day'"
        [label]="'@dayShort@' | translate"
        [min]="0"
        [step]="1"
      ></time-duration-picker-unit>
    </div>
    <div class="time-duration-picker-input-wrapper">
      <time-duration-picker-unit
        #hour
        [name]="'hour'"
        [label]="'@hourShort@' | translate"
        [min]="0"
        [step]="1"
      ></time-duration-picker-unit>
    </div>
    <div class="time-duration-picker-input-wrapper time-duration-divider" *ngIf="MHDivider">
      <div class="time-duration-picker-input-placeholder"></div>
      <span class="time-duration-divider-content">:</span>
    </div>
    <div class="time-duration-picker-input-wrapper">
      <time-duration-picker-unit
        #minute
        [name]="'minute'"
        [label]="'@minuteShort@' | translate"
        [min]="0"
        [step]="1"
      ></time-duration-picker-unit>
    </div>
    <div class="time-duration-picker-input-wrapper time-duration-divider" *ngIf="SMDivider">
      <div class="time-duration-picker-input-placeholder"></div>
      <span class="time-duration-divider-content">:</span>
    </div>
    <div class="time-duration-picker-input-wrapper">
      <time-duration-picker-unit
        #second
        [name]="'second'"
        [label]="'@secondShort@' | translate"
        [min]="0"
        [step]="1"
      ></time-duration-picker-unit>
    </div>
    <div class="time-duration-picker-input-wrapper">
      <time-duration-picker-unit
        #millisecond
        [name]="'millisecond'"
        [label]="'@millisecondShort@' | translate"
        [min]="0"
        [max]="999"
        [step]="1"
      ></time-duration-picker-unit>
    </div>

    <!-- <div>
            <button [ngStyle]=
            "{'width':getFontSize()+'px',
            'height':getFontSize()+'px'}" class="delete-spinner-button" *ngIf="delete" pButton type="button" (click)="valueDelete()" icon="fa-close"></button>
        </div> -->
  </div>
  <div *ngIf="error" style="color: red">{{ '@unhandled_condition_exception@' | translate }}</div>
</time-duration-picker>
