import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { EPredefinedAction } from '@app-modeleditor/components/button/action/predefined-action.enum';
import { TemplateUiService } from '@app-modeleditor/components/template-ui/template.service';
import { Registered, TemplateService as TemplateApiService } from '@app-modeleditor/utils/template.service';
import { GlobalUtils } from 'frontend/src/dashboard/global-utils';
import { ETemplateEvents } from 'frontend/src/dashboard/model/resource/template-events.enum';
import { of, Subject, throwError } from 'rxjs';
import { catchError, delay, finalize, takeUntil } from 'rxjs/operators';
import { TemplateAdapter } from '../../utils/template-factory.service';
import { HierarchicalMenuItem } from '../template-ui/hierarchical-menu-item';
import { SavedRecord } from '../template-ui/saved-record';
import { IHierarchicItem } from '../tree/tree.model';
import { TemplateTreeService } from '../tree/tree.service';
import { ContextmenuService } from './../contextmenu/contextmenu.service';
import { Action } from './action/action';
import { EActionType } from './action/action-type.enum';
import { Button } from './button';
import { EButtonDisplayType } from './button-display-type.enum';
import { ButtonLine } from './button-line';
import { ButtonService } from './button.service';
import { EClickType } from './click-type.enum';
import { TemplateActionService } from './template-action.service';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[style.display]': "displayType === 'HIDDEN'? 'none': ''",
  },
  providers: [],
})
export class ButtonComponent implements OnInit, OnChanges, OnDestroy {
  @Input('data') set buttonData(data) {
    this.btn = data instanceof Button ? data : this.templateFactory.adapt<Button>(data);
    this.checkProps();
    this.btn.addEventListener(this.componentId, ETemplateEvents.UPDATED, (c: CustomEvent) => {
      this.checkProps();
    });
    this.init();
  }

  @Input() disabled: boolean;
  @Output() action: EventEmitter<any> = new EventEmitter<any>();
  @Input() stretched: any;
  @Input() badge: string;
  @Input() icon: any;
  @Input() image;
  @Input() iconFontSize: number;
  @Input() label: string;
  @Input() toggleImage;
  @Input() toggleName: string;
  @Input() labelButton: boolean;
  @Input() simpleButton = false;

  data: any;
  btn: Button;
  toggleState = true;
  badgeCount: string;
  editMode = true;
  hmi: HierarchicalMenuItem;
  currentNode: IHierarchicItem;
  isExecuting: boolean;
  marked: SavedRecord[] = [];
  selected: boolean;
  isIndicator = false;
  lines: ButtonLine[];
  COLOR: string;
  NAME: string;
  TOOLTIP: string;
  displayType: EButtonDisplayType;
  clickTimeout: Subject<void> = new Subject<void>();
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  private componentId: string = GlobalUtils.generateUUID();
  private registered: Record<string, Registered>;
  private inExecution: boolean;

  constructor(
    public templateUiService: TemplateUiService,
    private buttonService: ButtonService,
    private tTreeService: TemplateTreeService,
    private templateApi: TemplateApiService,
    private templateFactory: TemplateAdapter,
    protected contextMenuApi: ContextmenuService,
    private elRef: ElementRef,
    private _ngZone: NgZone,
    private _cd: ChangeDetectorRef,
    private templateActionService: TemplateActionService
  ) {
    this.setExecuting(false);
  }

  ngAfterViewInit(): void {}

  private checkProps(): void {
    let somethingChanged = false;
    const count: string = this.btn.getBadge() > 9 ? '+9' : this.btn.getBadge() ? this.btn.getBadge().toString() : null;
    if (this.badgeCount !== count) {
      this.badgeCount = count;
      somethingChanged = true;
    }

    // is needed to trigger change detection on value change
    if (this.isIndicator !== this.btn.isIndicator()) {
      this.isIndicator = this.btn.isIndicator();
      somethingChanged = true;
    }

    if (this.COLOR !== this.btn.getColor()) {
      this.COLOR = this.btn.getColor();
      somethingChanged = true;
    }

    if (this.NAME !== this._getName()) {
      this.NAME = this._getName();
      somethingChanged = true;
    }

    if (this.TOOLTIP !== this.btn.getTooltip()) {
      this.TOOLTIP = this.btn.getTooltip();
      somethingChanged = true;
    }

    if (this.displayType !== this.btn.getDisplayType()) {
      this.displayType = this.btn.getDisplayType();
      somethingChanged = true;
    }

    if (this.selected !== this.btn.isSelected()) {
      this.selected = this.btn.isSelected();
      somethingChanged = true;
    }

    if (this.lines !== this.btn.getLines()) {
      this.lines = this.btn.getLines();
      somethingChanged = true;
    }

    if (somethingChanged) {
      this._cd.markForCheck();
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.clickTimeout.next();
    this.clickTimeout.complete();
    this.btn.removeEventListener(this.componentId, ETemplateEvents.UPDATED);
  }

  private _checkState(): boolean {
    return this.getDisabledState() || this.isExecuting;
  }

  get DISABLED(): boolean {
    return this._checkState();
  }

  get ICON(): string {
    return this._getIcon();
  }

  private _getName(): string {
    if (this.btn.getEntry()?.getValue() && !this.btn.getToggleEntry()) {
      return this.btn.getEntry().getValue();
    }

    // to check selected state instead of editmode, the freeSelection property must be set to true (relevant for gantt toolbar toggle buttons)
    const isToggled =
      ((this.btn.isFreeSelection() && this.btn.isSelected()) || (!this.btn.isFreeSelection() && this.editMode)) &&
      !!this.btn.getToggleName();
    return isToggled ? this.btn.getToggleName() : this.btn.getEntry()?.getValue() || this.btn.getName();
  }

  private _getIcon(): string {
    if (this.editMode && this.icon) {
      return this.icon;
    }

    if (this.btn) {
      return !this.editMode && this.btn.getToggleIcon() ? this.btn.getToggleIcon() : this.btn.getIcon();
    }

    return null;
  }

  protected init(): void {
    if (!this.registered || !this.btn) {
      return;
    }

    const resourceId: string = this.templateApi.findResourceByTemplate(this.btn.getId());
    let r: Registered = this.registered[resourceId];

    if (this.btn.getParentId()) {
      const parentResourceId: string = this.templateApi.findResourceByTemplate(this.btn.getParentId());
      if (parentResourceId !== resourceId && parentResourceId !== 'undefined') {
        r = this.registered[parentResourceId];
      }
    }

    const editmode: boolean = r && typeof r.editable === 'boolean' ? r.editable : true;
    if (editmode !== this.editMode) {
      this.editMode = editmode;

      this.checkProps();
      this._cd.markForCheck();
    }
  }

  /**
   * changes life cycle
   */
  ngOnChanges(changes: SimpleChanges): void {
    this.resize();
  }

  /**
   * resize event
   */
  resize(): void {
    if (!this.stretched) {
      return;
    }

    of(null)
      .pipe(delay(0))
      .subscribe(() => {
        this.iconFontSize = Math.min(Math.max(this.stretched.clientWidth / 2.5, 24), 40);
      });
  }

  /**
   * get state for disabled buttons
   */
  getDisabledState(): boolean {
    if ((this.btn as any).alwaysEnabled) {
      return false;
    }

    const _list: Action[] = this.btn.getChain().getActions(); // .concat(this.data.globalActions ? this.data.globalActions : []);
    this.toggleState = true;

    for (let i = 0; i < _list.length; i++) {
      const local = _list[i];
      if (local.getActionType() !== EActionType.PREDEFINED) {
        continue;
      }
      switch (local.getId()) {
        case EPredefinedAction.SAVE:
        case EPredefinedAction.CANCEL:
          if (_list.length !== 1) {
            break;
          }
          this.toggleState = this.marked.length > 0;
          break;
        case EPredefinedAction.CLONE:
          if (
            _list.length !== 1 ||
            ((!this.currentNode || !this.currentNode.cloneRestUrl) && (!this.hmi || !this.hmi.getCloneRestUrl()))
          ) {
            return true;
          }
          break;
        case EPredefinedAction.DELETE:
          // if (this.btn.getId().includes("template.entryelement.delete")) {
          //   break;
          // }
          if (
            _list.length !== 1 ||
            ((!this.currentNode || !this.currentNode.deleteRestUrl) && (!this.hmi || !this.hmi.getDeleteRestUrl()))
          ) {
            return true;
          }
          break;
        case EPredefinedAction.CREATE:
          if (
            _list.length !== 1 ||
            ((!this.currentNode || !this.currentNode.createRestUrls) && (!this.hmi || !this.hmi.getCreateRestUrls()))
          ) {
            return true;
          }
          break;
        case EPredefinedAction.EDIT:
          if (_list.length !== 1 || (!this.currentNode && !this.hmi)) {
            return true;
          }
          break;
      }
    }

    if (!this.btn) {
      return true;
    }

    if (!this.templateActionService.checkActions(this.btn.getChain().getActions())) {
      return true;
    }

    return (
      !this.btn.isAlwaysEnabled() &&
      (this.isExecuting ||
        !this.toggleState ||
        !this.editMode ||
        !this.btn.isEnabled() ||
        this.btn.isDisabled() ||
        this.disabled)
    );
  }

  /**
   * init life cycle
   */
  ngOnInit(): void {
    this.resize();

    this._ngZone.runOutsideAngular(() => {
      this.templateUiService
        .onMarkedChanged()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((marked: SavedRecord[]) => {
          this.marked = marked;
          this._cd.markForCheck();
        });

      this.templateApi
        .afterRegisteredChanges()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((r: Record<string, Registered>) => {
          this.registered = r;
          this.init();
        });
    });

    this.tTreeService
      .getCurrentHMI()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((hmi: HierarchicalMenuItem) => {
        this.hmi = hmi;
      });

    this.tTreeService
      .getCurrentNode()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((node: IHierarchicItem) => {
        this.currentNode = node;
      });
    // check local actions for predefined actions
  }

  switchClicks(event: MouseEvent): void {
    if (this.btn.getDoubleClickActions().length === 0) {
      return this.execute(event, EClickType.SINGLE_CLICK);
    }

    this.clickTimeout.next();
    if (this.inExecution) {
      this.inExecution = null;
      // doubleClick
      this.execute(event, EClickType.DOUBLE_CLICK);
      return;
    }

    this.inExecution = true;
    of(null)
      .pipe(
        delay(450),
        takeUntil(this.clickTimeout),
        finalize(() => {
          // this.inExecution = null;
        })
      )
      .subscribe(() => {
        this.inExecution = null;
        this.execute(event, EClickType.SINGLE_CLICK);
      });
  }

  setExecuting(state: boolean): void {
    this.isExecuting = state;
  }

  /**
   * handles initial click
   */
  private execute(event: MouseEvent = null, type: EClickType = EClickType.SINGLE_CLICK): void {
    if (this.DISABLED === true) {
      return;
    }

    if (this.btn instanceof Button && this.btn.getMenu()) {
      this.contextMenuApi.create(this.elRef, this.btn.getMenu());
      return;
    }

    this.setExecuting(true);
    this.buttonService
      .onClick(this.btn, type === EClickType.DOUBLE_CLICK ? this.btn.getDoubleClickActions() : null, {
        overrideActions: type === EClickType.DOUBLE_CLICK ? true : false,
        event,
      })
      .pipe(
        catchError((e) => {
          return throwError(e);
        }),
        finalize(() => {
          this.setExecuting(false);
          this._cd.detectChanges();
        })
      )
      .subscribe((data) => {
        this.action.emit({ element: this.btn, response: data });
      });
  }
}
