export enum EMessageType {
  BROADCAST = 'BROADCAST',
  FINISHED_INITIALIZATION = 'FINISHED_INITIALIZATION',
  MESSAGE = 'MESSAGE',
  CLOSE = 'CLOSE',
  FORCE_REFRESH_TOKEN = 'FORCE_REFRESH_TOKEN',
  CLOSE_ALL_WINDOWS = 'CLOSE_ALL_WINDOWS',
  WINDOW_IS_CLOSED = 'WINDOW_IS_CLOSED',
  /**
   * To ask if any external window has focus
   */
  FOCUS_BROADCAST = 'FOCUS_BROADCAST',
  /**
   * To tell that this window has focus
   */
  HAS_FOCUS = 'HAS_FOCUS',
}
