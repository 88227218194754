import { ComponentEvent } from '../../../history/component-event';

/**
 * @constructor
 * @class
 * @extends ComponentEvent
 * @requires ComponentEvent
 * @plugin timeperiod-marker
 * */

export class GanttSplitOverlappingShiftsEvent extends ComponentEvent {
  ref: any;

  constructor() {
    super();
    /**
     * @type {GanttSplitOverlappingShifts}
     */
    this.ref = null;
  }

  /**
   * @override
   * @returns {boolean} true if undo was succesfull, else false
   */
  undo() {
    const s = this;

    function handle(type) {
      const types = {
        splitOverlappingShifts: function () {
          return s._undoSplitOverlappingShifts();
        },
        resetSplitOverlappingShifts: function () {
          return s._redoSplitOverlappingShifts();
        },
        default: function () {
          console.warn('can not undo: ' + type + '. May not be implemented yet.');
          return false;
        },
      };
      return (types[type] || types['default'])();
    }
    return handle(s.eventName);
  }

  /**
   * @override
   * @returns {boolean} true if redo was succesfull, else false
   */
  redo() {
    const s = this;
    function handle(type) {
      const types = {
        splitOverlappingShifts: function () {
          return s._redoSplitOverlappingShifts();
        },
        resetSplitOverlappingShifts: function () {
          return s._undoSplitOverlappingShifts();
        },
        default: function () {
          console.warn('can not redo: ' + type + '. May not be implemented yet.');
          return false;
        },
      };
      return (types[type] || types['default'])();
    }
    return handle(s.eventName);
  }

  private _undoSplitOverlappingShifts() {
    const s = this;
    s.ref.disableLogging();
    s.ref.resetSplitOverlappingShifts();
    s.ref.enableLogging();
    return true;
  }
  private _redoSplitOverlappingShifts() {
    const s = this;
    s.ref.disableLogging();
    s.ref.splitOverlappingShifts();
    s.ref.enableLogging();
    return true;
  }

  /**
   * @override
   * @return {String}
   */
  getEventDescription() {
    const s = this;
    function handle(type) {
      const types = {
        colorizeShiftById: function () {
          return GanttSplitOverlappingShiftsEventDescription.getColorizeShiftByIdDescription(
            s.ref.ganttDiagram.getConfig().getLanguage(),
            s.arguments
          );
        },
        default: function () {
          console.warn('no description for: ' + s.eventName + ' set. May not be implemented yet.');
          return '';
        },
      };
      return (types[type] || types['default'])();
    }
    return handle(s.eventName);
  }
}

// GETTER and SETTER inherited from Main Class

export class GanttSplitOverlappingShiftsEventDescription {
  // TODO: Event explanations.

  private constructor() {}

  static getColorizeShiftByIdDescription(language, args) {
    let description = '';
    language === 'DE' ? (description = 'Schicht mit ID ' + args[0] + ' in Farbe ' + args[1] + ' einfärben.') : null;
    language === 'EN' ? (description = 'Colorize shift with ID ' + args[0] + ' ' + args[1] + '.') : null;
    return description;
  }
}
