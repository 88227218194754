import * as d3 from 'd3';
import { EGanttScrollContainer } from '../../../html-structure/scroll-container.enum';
import { BestGantt } from '../../../main';
import { RowRasterColorExecuter } from '../../row-raster-color/row-raster-color-executer';
import { GanttCurrentTimePointMarker } from '../../timepoint-marker/executer-current-timepoint-marker';
import { IGanttPrintViewStrategie } from './printing-strategie-interface';

export class GanttPrintViewStrategyLW implements IGanttPrintViewStrategie {
  public ganttDiagram: BestGantt;
  private _config: IGanttPrintViewStrategyLWConfig;

  constructor() {
    this.ganttDiagram = null;

    this._config = {
      xAxis: {
        showScrollbar: false,
      },
      yAxis: {
        defaultBackgroundColor: '#f6f6f6',
        defaultTextColor: '#000000',
        rowColor: '#ffffff',
        lineStyle: {
          color: '#000000',
        },
      },
      shiftCanvas: {
        defaultBackgroundColor: '#ffffff',
        weekendColor: '#e0e0e095',
        defaultRowRasterColor: '#f0f0f095',
        lineColor: '#000000',
      },
    };
  }

  public printView(ganttDiagram: BestGantt): void {
    if (!ganttDiagram) return;
    this.ganttDiagram = ganttDiagram;

    this._buildPrintView();
  }

  public update(): void {
    // nothing
  }

  public destroy(): void {
    // nothing
  }

  private _buildPrintView(): void {
    // always disable sticky rows
    this.ganttDiagram.getConfig().setShowStickyRows(false);

    // remove scrollbar from html structure
    this.ganttDiagram.getHTMLStructureBuilder().getXAxisContainer().select('.gantt_x-axis_placeholder-right').remove();
    this.ganttDiagram
      .getHTMLStructureBuilder()
      .getXAxisContainer()
      .select('.gantt_x-axis')
      .style('width', `calc(100% - ${this.ganttDiagram.getNodeProportionsState().getYAxisProportions().width}px)`);

    // apply CSS classes
    this._applyCSSClasses();

    // disable x axis scrolling
    if (!this._config.xAxis.showScrollbar) {
      this.ganttDiagram.getConfig().setXAxisScrollBarHeight(0);
      this.ganttDiagram.getConfig().setXAxisScrollBarPadding(0);
    }

    // optimize colors for printing
    this.ganttDiagram.getGlobalTimeSpanMarker().setWeekendColor(this._config.shiftCanvas.weekendColor);

    for (const scrollContainerId of Object.values(EGanttScrollContainer)) {
      const yAxis = this.ganttDiagram.getYAxisFacade().getYAxisBuilder(scrollContainerId);
      yAxis.setRowColor(this._config.yAxis.rowColor);
      yAxis.setLineStyle(this._config.yAxis.lineStyle);
      yAxis.setDefaultTextColor(this._config.yAxis.defaultTextColor);
      yAxis.setOnlyDefaultTextColor(true);
      yAxis.setCustomRippleEffectClass('yAxisRippleEffect_print-lw');
      yAxis.getParentNode().style('background-color', this._config.yAxis.defaultBackgroundColor);
    }

    const yAxisHead = this.ganttDiagram.getYAxisHeadBuilder();
    d3.select(yAxisHead.getParentNode())
      .style('background-color', this._config.shiftCanvas.defaultBackgroundColor)
      .style('opacity', '1');
    yAxisHead.removeAll();

    this.ganttDiagram.getConfig().setRowBackgroundColorDefault(this._config.shiftCanvas.defaultBackgroundColor);
    this.ganttDiagram.getShiftFacade().updateCanvasBackgroundColor();
    const rowRasterColorExecuters: RowRasterColorExecuter[] = this.ganttDiagram
      .getPlugInHandler()
      .getPlugInsOfType(RowRasterColorExecuter);
    for (const executer of rowRasterColorExecuters) {
      executer.setDefaultRowRasterColor(this._config.shiftCanvas.defaultRowRasterColor);
    }
    d3.select(this.ganttDiagram.getShiftFacade().getParentNode().node()).style(
      'background-color',
      this._config.shiftCanvas.defaultBackgroundColor
    );

    this.ganttDiagram.getConfig().setHorizontalLinesColor(this._config.shiftCanvas.lineColor);
    this.ganttDiagram.getConfig().setVerticalLinesColor(this._config.shiftCanvas.lineColor);
    this.ganttDiagram.getConfig().setVerticalLinesDailyColor(this._config.shiftCanvas.lineColor);
    this.ganttDiagram.getConfig().setVerticalLinesMonthlyColor(this._config.shiftCanvas.lineColor);

    // remove current timepoint marker
    const currentTimePointMarkerExecuters: GanttCurrentTimePointMarker[] = this.ganttDiagram
      .getPlugInHandler()
      .getPlugInsOfType(GanttCurrentTimePointMarker);
    for (const executer of currentTimePointMarkerExecuters) {
      executer.setRender(false);
    }
  }

  /**
   * Replaces relevant CSS classes with special printing classes.
   */
  private _applyCSSClasses(): void {
    // x axis container
    const xAxisCSS = Array.from(this.ganttDiagram.getParentNode().getElementsByClassName('gantt_x-axis'));
    for (const element of xAxisCSS) {
      element.classList.remove('gantt_x-axis');
      element.classList.add('gantt_x-axis_print-lw');
    }
    // scroll container
    const scrollContainerCSS = this.ganttDiagram.getHTMLStructureBuilder().getVerticalScrollContainer();
    scrollContainerCSS.node().classList.remove('gantt_scroll_wrapper');
    scrollContainerCSS.node().classList.add('gantt_scroll_wrapper_print-lw');
  }
}

export interface IGanttPrintViewStrategyLWConfig {
  xAxis: {
    showScrollbar: boolean;
  };
  yAxis: {
    defaultBackgroundColor: string;
    defaultTextColor: string;
    rowColor: string;
    lineStyle: {
      color?: string;
      thickness?: number;
    };
  };
  shiftCanvas: {
    defaultBackgroundColor: string;
    weekendColor: string;
    defaultRowRasterColor: string;
    lineColor: string;
  };
}
