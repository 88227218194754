import { ETemplateType } from 'frontend/src/dashboard/model/resource/template-type';
import { Action } from '../button/action/action';
import { ContentElement } from '../content/content-element/content-element';
import { IBackendChatMessage } from './chat.interface';

export class TemplateChat extends ContentElement {
  private value: IBackendChatMessage[];
  private sendMessageAction: Action;
  private editMessageURL: string;
  private deleteMessageURL: string;

  public getSendMessageAction(): Action {
    return this.sendMessageAction;
  }

  public setSendMessageAction(sendMessageAction: Action): this {
    this.sendMessageAction = sendMessageAction;
    return this;
  }

  public getEditMessageURL(): string {
    return this.editMessageURL;
  }

  public setEditMessageURL(editMessageURL: string): this {
    this.editMessageURL = editMessageURL;
    return this;
  }

  public getDeleteMessageURL(): string {
    return this.deleteMessageURL;
  }

  public setDeleteMessageURL(deleteMessageURL: string): this {
    this.deleteMessageURL = deleteMessageURL;
    return this;
  }

  public getValue(): IBackendChatMessage[] {
    return this.value;
  }

  public setValue(value: IBackendChatMessage[]): this {
    this.value = value;
    return this;
  }

  constructor() {
    super();
    this.setType(ETemplateType.CHAT);
  }
}
