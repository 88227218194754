import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PrintComponent } from './print.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
@NgModule({
  imports: [CommonModule, DragDropModule],
  exports: [PrintComponent],
  declarations: [PrintComponent],
})
export class PrintModule {}
