import { Injectable, Type } from '@angular/core';
import { Adapter } from '@core/adapter';
import { TemplateAdapter } from '@app-modeleditor/utils/template-factory.service';
import { Resource } from './resource';
import { Template } from './template';
import { GlobalUtils } from 'frontend/src/dashboard/global-utils';
/**
 * this service is capable of mapping any kind of resource
 * to a valid resource-class
 */
@Injectable({
  providedIn: 'root',
})
export class ResourceAdapter implements Adapter<Resource> {
  private _hooks: { [id: string]: (item: Resource, data: any) => void } = {};

  public addHook(templateId: string, hook: (item: Resource, data: any) => void): void {
    this._hooks[templateId] = hook;
  }

  public removeHook(templateId: string): void {
    delete this._hooks[templateId];
  }

  private _getHook(id: string): (item: Resource, data: any) => void {
    return this._hooks[id];
  }

  adapt(item: any): Resource {
    throw new Error('Method not implemented.');
  }

  applyValues<T extends Template>(scope: TemplateAdapter, item: T, values: Record<string, any>): T {
    throw new Error('Method not implemented.');
  }

  private _createResource<T extends Resource>(item: T, data: any): T {
    item
      .setId(data.id || GlobalUtils.generateUUID())
      .setName(data.name)
      .setLocalID(data.localID)
      .setMonthlyDataResponse(data.monthlyDataResponse)
      .setCanonicalName(data.canonicalName)
      .setResourceId(data.resourceId);
    if (data.created) {
      item.setCreated(new Date(data.created));
    }

    if (this._getHook(item.getId())) {
      this._getHook(item.getId())(item, data);
    }
    return item;
  }

  /**
   * basic element with id and name
   * @param type Type<T extends Resource>
   * @param data JSON
   * @returns T
   */
  public inherit<T extends Resource>(type: Type<T>, data: any): T {
    if (!data) {
      return null;
    }
    const r: T = this._createResource<T>(new type(), data);

    return r;
  }

  /**
   * basic element with id and name
   * @param type Type<T extends Resource>
   * @param data JSON
   * @returns T
   */
  public inheritFrom<T extends Resource>(scope: TemplateAdapter, type: Type<T>, data: any): T {
    if (!data) {
      return null;
    }
    const r: T = this._createResource<T>(new type(), data);

    return r;
  }
}
