import * as d3 from 'd3';
import { EGanttScrollContainer } from '../../html-structure/scroll-container.enum';
import { BestGantt } from '../../main';

/**
 * This class handels all DOM element operations for the time period editing.
 * @keywords plugin, edit, editor, change, timeperiod, blocking, interval
 * @plugin timeperiod-marker
 * @class
 * @constructor
 */
export class GanttTimePeriodEditor {
  private _placeholderGroup: { [id: string]: d3.Selection<HTMLElement, undefined, d3.BaseType, undefined> } = {};
  private _stateManagement = {
    position: EGanttTimePeriodGroupPosition.BACK,
  };

  /**
   * @param _ganttDiagram
   * @param _periodParent
   */
  constructor(
    private _ganttDiagram: BestGantt,
    private _periodParent: { [id: string]: d3.Selection<SVGGElement, undefined, d3.BaseType, undefined> }
  ) {}

  /**
   * Brings all time periods to first layer (in front of shifts) to make them better draggable.
   */
  public bringPeriodsToFront(): void {
    if (this._stateManagement.position === EGanttTimePeriodGroupPosition.FRONT) {
      return;
    }

    for (const scrollContainerId in this._periodParent) {
      // append placeholder-group
      this._createPlaceholderGroup(scrollContainerId as EGanttScrollContainer);
      this._ganttDiagram
        .getShiftFacade()
        .getCanvasInFrontShifts(scrollContainerId as EGanttScrollContainer)
        .node()
        .appendChild(this._periodParent[scrollContainerId].node());

      d3.select(this._periodParent[scrollContainerId].node()).attr('opacity', 0.85);
    }

    this._stateManagement.position = EGanttTimePeriodGroupPosition.FRONT;
  }

  /**
   * Copys group back to origin position.
   */
  public bringPeriodsBack(): void {
    if (this._stateManagement.position === EGanttTimePeriodGroupPosition.BACK) {
      return;
    }

    for (const scrollContainerId in this._periodParent) {
      this._ganttDiagram
        .getShiftFacade()
        .getCanvasBehindShifts(scrollContainerId as EGanttScrollContainer)
        .node()
        .insertBefore(this._periodParent[scrollContainerId].node(), this._placeholderGroup[scrollContainerId].node());
      d3.select(this._periodParent[scrollContainerId].node()).attr('opacity', 1);

      this._placeholderGroup[scrollContainerId].remove();
      this._placeholderGroup[scrollContainerId] = undefined;
    }

    this._stateManagement.position = EGanttTimePeriodGroupPosition.BACK;
  }

  /**
   * Appends a placeholder-group to save the origin position
   * @param scrollContainerId
   */
  private _createPlaceholderGroup(scrollContainerId: EGanttScrollContainer): void {
    const placeholderGroupNode = document.createElement('g');
    placeholderGroupNode.classList.add('gantt-area-marker-placeholder-group');
    this._placeholderGroup[scrollContainerId] = d3.select(placeholderGroupNode);

    this._ganttDiagram
      .getShiftFacade()
      .getCanvasBehindShifts(scrollContainerId)
      .node()
      .insertBefore(placeholderGroupNode, this._periodParent[scrollContainerId].node());
  }
}

/**
 * Enum defining all possible positions for gantt time period groups.
 */
enum EGanttTimePeriodGroupPosition {
  FRONT = 'front',
  BACK = 'back',
}
