<app-dialog-content
  [class.resizeable]="lightbox.isResizeable() && !lightbox.isFullscreen()"
  [style.max-width]="'100%'"
  [style.min-width]="'420px'"
  [style.min-height]="'110px'"
  [style.height]="absoluteHeight"
  [style.width]="absoluteWidth"
  #dialogContent
  [lightbox]="lightbox"
  [dragPosition]="dragPosition"
>
</app-dialog-content>
<div
  [saxmsTooltip]="'LIGHTBOX.resize' | translate"
  class="resizer"
  (cdkDragStarted)="onDragStart($event)"
  (dblclick)="fullscreen()"
  *ngIf="lightbox.isResizeable() && !lightbox.isFullscreen()"
  [cdkDragDisabled]="!lightbox.isResizeable()"
  cdkDrag
  (cdkDragMoved)="onMove($event)"
  (cdkDragEnded)="afterDrag($event)"
>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
    <title>Icon_Inputfield-Resize</title>
    <path
      d="M12.41,11.21a.5.5,0,0,1,.71,0,.48.48,0,0,1,0,.7l-.71.71a.48.48,0,0,1-.7,0,.5.5,0,0,1,0-.71Z"
      fill-rule="evenodd"
    />
    <path d="M12.41,8.38a.5.5,0,1,1,.71.71L9.59,12.62a.5.5,0,1,1-.71-.71Z" fill-rule="evenodd" />
    <path d="M12.77,5.2a.49.49,0,1,1,.7.7L6.4,13a.49.49,0,1,1-.7-.7Z" fill-rule="evenodd" />
  </svg>
</div>
