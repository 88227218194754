<div class="wrapper mat-elevation-z8" [style.height]="opened ? 'auto' : '0px'">
  <span
    [matBadge]="badgeCount > 9 ? '+9' : badgeCount"
    [matTooltip]="'Dateimanager'"
    [matTooltipShowDelay]="1000"
    class="toggle"
    (click)="toggleMode()"
  >
    <mat-icon>{{ opened ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</mat-icon>
  </span>
  <mat-list>
    <div class="header">{{ 'FILE.GENERAL.filemanager' | translate }}</div>
    <div class="content">
      <ng-container *ngIf="files.length === 0">
        <span [style.display]="'block'" [style.padding]="'20px'">{{
          'FILE.GENERAL.no_pending_files' | translate
        }}</span></ng-container
      >
      <ng-container *ngFor="let item of files">
        <mat-list-item class="file-item">
          <span>
            {{ item.getName() }}
          </span>
          <span class="spacer"></span>
          <ng-container [ngSwitch]="item.getState()">
            <ng-container *ngSwitchCase="'COMPLETED'">
              <span [style.color]="'#62d659'">
                {{ 'FILE.UPLOAD.STATE.' + item.getState().toLocaleLowerCase() | translate }}</span
              >
            </ng-container>
            <ng-container *ngSwitchCase="'FAILED'">
              <span [style.color]="'red'">
                {{ 'FILE.UPLOAD.STATE.' + item.getState().toLocaleLowerCase() | translate }}</span
              >
            </ng-container>
            <ng-container *ngSwitchCase="'LOADING'">
              <mat-progress-spinner [mode]="'indeterminate'" [diameter]="24"></mat-progress-spinner>
              {{ 'FILE.UPLOAD.STATE.' + item.getState().toLocaleLowerCase() | translate }}
            </ng-container>
            <ng-container *ngSwitchDefault>
              <mat-progress-spinner [mode]="'indeterminate'" [diameter]="24"></mat-progress-spinner>
            </ng-container>
          </ng-container>
          <span class="spacer"></span>
          <button class="action-item" mat-icon-button (click)="removeItem(item)">
            <mat-icon>delete</mat-icon>
          </button>
        </mat-list-item>
        <mat-divider></mat-divider>
      </ng-container>
    </div>
  </mat-list>
</div>
