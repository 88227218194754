import { OverlayRef } from '@angular/cdk/overlay';
import { Template } from 'frontend/src/dashboard/model/resource/template';
import { ETemplateType } from 'frontend/src/dashboard/model/resource/template-type';
import { ContextMenuGroup } from './context-menu-group';
import { ContextMenuItem } from './context-menu-item';

export class ContextMenu extends Template {
  private contextMenuItems: ContextMenuItem[];
  private belongsToElement: string;
  private contextMenuGroups: ContextMenuGroup[];
  private ref: OverlayRef;
  private defaultBehaviour: boolean;
  private externalMenuItems: ContextMenuItem[];
  private closedByClick: boolean;

  public isClosedByClick(): boolean {
    return typeof this.closedByClick === 'boolean' ? this.closedByClick : true;
  }

  public setClosedByClick(closedByClick: boolean): this {
    this.closedByClick = closedByClick;
    return this;
  }

  public getExternalMenuItems(): ContextMenuItem[] {
    return this.externalMenuItems || [];
  }

  public setExternalMenuItems(externalMenuItems: ContextMenuItem[]): this {
    this.externalMenuItems = externalMenuItems;
    return this;
  }

  public isDefaultBehaviour(): boolean {
    return typeof this.defaultBehaviour === 'boolean' ? this.defaultBehaviour : true;
  }

  public setDefaultBehaviour(defaultBehaviour: boolean): this {
    this.defaultBehaviour = defaultBehaviour;
    return this;
  }

  public getRef(): OverlayRef {
    return this.ref;
  }

  public setRef(ref: OverlayRef): this {
    this.ref = ref;
    return this;
  }

  public getContextMenuGroups(): ContextMenuGroup[] {
    return this.contextMenuGroups || [];
  }

  public setContextMenuGroup(contextMenuGroups: ContextMenuGroup[]): this {
    this.contextMenuGroups = contextMenuGroups;
    return this;
  }

  public getContextMenuItems(): ContextMenuItem[] {
    return (this.contextMenuItems || []).concat(this.getExternalMenuItems());
    // .sort((a: ContextMenuItem, b: ContextMenuItem) => a.getOrder() === b.getOrder() ? 0 : a?.getOrder() < b?.getOrder() ? -1 : 1);
  }

  public setContextMenuItems(contextMenuItems: ContextMenuItem[]): this {
    this.contextMenuItems = contextMenuItems;
    return this;
  }

  public addContextMenuItems(...contextMenuItems: ContextMenuItem[]): this {
    this.contextMenuItems = this.getContextMenuItems().concat(contextMenuItems);
    return this;
  }

  public addContextMenuGroups(...contextMenuGroups: ContextMenuGroup[]): this {
    this.contextMenuGroups = this.getContextMenuGroups().concat(contextMenuGroups);
    return this;
  }

  public getBelongsToElement(): string {
    return this.belongsToElement;
  }

  public setBelongsToElement(belongsToElement: string): this {
    this.belongsToElement = belongsToElement;
    return this;
  }

  constructor() {
    super();
    this.setType(ETemplateType.CONTEXT_MENU);
  }
}
