import { EResizeMode } from 'frontend/src/dashboard/model/resource/template-resize-mode.enum';
import { ETemplateType } from 'frontend/src/dashboard/model/resource/template-type';
import { Content } from '../content/content';
import { ContentElement } from '../content/content-element/content-element';
import { ContentPart } from '../content/content-part/content-part';
import { EMenuItemHandlingType } from '../menu-item/menu-item-handling-type.enum';
import { HierarchicalMenuItem } from '../template-ui/hierarchical-menu-item';
import { Menu } from './menu';

export class PredefinedItemUtils {
  public static inject(template: Menu, item: HierarchicalMenuItem): void {
    if (!item) {
      return;
    }
    if (template && template.getId().indexOf('admin') !== -1) {
      template.setHierachicMenuItems(
        template
          .getHierachicMenuItems()
          .filter((hmi: HierarchicalMenuItem) => hmi.getId() !== item.getId())
          .concat(item)
      );
    }
  }

  public static getSettingsMenuItem(template: Menu): HierarchicalMenuItem {
    if (template && template.getId().indexOf('admin') !== -1) {
      const items = [
        new HierarchicalMenuItem()
          .setName('Passwort ändern')
          .setResizeMode(EResizeMode.FIT_CHILD)
          .setContent(
            new Content().setContentParts([
              new ContentPart()
                .setName('Passwort ändern')
                .setContentElements([new ContentElement().setType(ETemplateType.PASSWORDCHANGE)]),
            ])
          ),
        new HierarchicalMenuItem()
          .setName('Nutzereinstellungen')
          .setContent(
            new Content().setContentParts([
              new ContentPart()
                .setName('Nutzereinstellungen')
                .setContentElements([new ContentElement().setType(ETemplateType.USERSETTINGS)]),
            ])
          ),
        new HierarchicalMenuItem()
          .setName('Fehlerlog')
          .setContent(
            new Content().setContentParts([
              new ContentPart()
                .setName('Fehlerlog')
                .setContentElements([new ContentElement().setType(ETemplateType.ERRORLOG)]),
            ])
          ),
      ];
      const menuItem = template.getHierachicMenuItems().find((item) => item.getId().indexOf('settings') !== -1);
      if (menuItem) {
        menuItem.setHandlingType(EMenuItemHandlingType.SETTINGS);
        menuItem.setHierachicMenuItems(items);
      }

      return menuItem;
    }
  }
}
