import { Injectable, Type } from '@angular/core';
import { EntryElementFactory } from '@app-modeleditor/components/entry-collection/entry-factory.service';
import { TemplateAdapter } from '@app-modeleditor/utils/template-factory.service';
import { EActionOperation } from 'frontend/src/dashboard/model/entry/action-operation.enum';
import { EntryElementValue } from '../entry-collection/entry-element-value';
import { EFieldType } from '../entry-collection/field-type.enum';
import { ActionAdapter } from './action-adapter.service';
import { Action } from './action/action';
import { EActionType } from './action/action-type.enum';
import { EPredefinedAction } from './action/predefined-action.enum';
import { EValidationRules } from './action/validation-rules.enum';
import { Button } from './button';
import { EButtonDisplayType } from './button-display-type.enum';

@Injectable({
  providedIn: 'root',
})
export class ButtonAdapter {
  constructor(private actionFactory: ActionAdapter, private entryElementFactory: EntryElementFactory) {}

  public inheritFrom<T extends Button>(scope: TemplateAdapter, type: Type<T>, data: any): T {
    if (!data) {
      return null;
    }
    return this.getBtn(this.entryElementFactory.inheritFrom<T>(scope, type, data), data);
  }

  /**
   * only for WEB_VIEW
   * @returns void
   */
  private getWindowType(openType): string {
    switch (openType) {
      case 'TAB':
        return '_blank';
      case 'WINDOW':
        return '_self';
      case 'NEW':
        return '';
      case 'IFRAME':
      default:
        break;
    }
  }

  private getBtn<T extends Button>(btn: T, data: any): T {
    const button = btn
      .setEditable(data.editable)
      .setShowDeleteConfirmDialog(data.showDeleteConfirmDialog)
      .setLocalActions((data.localActions || []).map((a) => this.actionFactory.parseAction<Action>(Action, a)))
      .setGlobalActions((data.globalActions || []).map((a) => this.actionFactory.parseAction<Action>(Action, a)))
      .setDisplayBackground(data.displayBackground)
      .setToggleIcon(data.toggleIcon)
      .setToggleEntry(this.entryElementFactory.parseEntryValue<EntryElementValue>(EntryElementValue, data.toggleEntry))
      .setToggleName(data.toggleName)
      .setDoubleClickActions(
        (data.doubleClickActions || []).map((a) => this.actionFactory.parseAction<Action>(Action, a))
      )
      .setDisplayType(data.displayType || EButtonDisplayType.ICON_AND_LABEL)
      .setBadge(data.badge)
      .setPendingNotification(data.pendingNotification)
      .setUnsavedTemplateIds(data.waitForUnsavedTemplateIds)
      .setId(data.id);

    if (data.color) {
      btn.setColor(data.color);
    } else if (data.iconColor) {
      btn.setColor(data.iconColor);
    }

    if (data.actionOperation) {
      switch (data.actionOperation) {
        case EActionOperation.BATCH_OBJECTS:
          button
            .getChain()
            .getActions()
            .forEach((a) =>
              a.setValidationRules(
                a
                  .getValidationRules()
                  .concat(
                    EValidationRules.NOT_NULL,
                    EValidationRules.NO_EMPTY_ARRAYS,
                    EValidationRules.NO_EMPTY_OBJECTS
                  )
              )
            );
          break;
        case EActionOperation.SINGLE_OBJECT:
          button
            .getChain()
            .getActions()
            .forEach((a) =>
              a.setValidationRules(
                a
                  .getValidationRules()
                  .concat(
                    EValidationRules.NOT_NULL,
                    EValidationRules.NO_EMPTY_ARRAYS,
                    EValidationRules.EXACTLY_ONE,
                    EValidationRules.NO_EMPTY_OBJECTS
                  )
              )
            );
          break;
      }
    }

    if (button.getOpenType() && button.getFieldType() === EFieldType.WEB_LINK) {
      // button.setDisplayType(EButtonDisplayType.LABEL_ONLY);
      button.setLocalActions(
        button.getLocalActions().concat(
          new Action()
            .setId(EPredefinedAction.OPEN_WEB_LINK)
            .setActionType(EActionType.PREDEFINED)
            .setParams({
              webLink: btn.getWeb_url(),
              openType: this.getWindowType(btn.getOpenType()),
            })
        )
      );
    }
    return button;
  }

  public inherit<T extends Button>(type: Type<T>, data: any): T {
    if (!data) {
      return null;
    }
    return this.getBtn(this.entryElementFactory.inherit<T>(type, data), data);
  }
}
