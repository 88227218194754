import { ChartDatasetLegend } from './../chart-legend/chart-dataset-legend';
import { Component, OnInit, Input } from '@angular/core';
import { ChartLegendTemplate } from '../chart-legend/chart-legend-template';
import { ChartLegendItem } from '../chart-legend-item';

@Component({
  selector: 'app-chart-legend-dataset',
  templateUrl: './chart-legend-dataset.component.html',
  styleUrls: ['./chart-legend-dataset.component.scss'],
})
export class ChartLegendDatasetComponent implements OnInit {
  @Input() templateNode: ChartDatasetLegend;

  constructor() {}
  ngOnInit() {}

  handleDataset(item: ChartLegendItem) {
    item.toogleActive();
    this.templateNode.executeChanges(item);
  }

  getColor(item: ChartLegendItem) {
    // if (item.getDatasetItem().originType !== 'line') {
    return item.isActive() ? item.getColor() : '#D3D3D3';
    // }
    // return 'transparent';
  }

  getClass(item: ChartLegendItem) {
    if (item.getDatasetItem().originType) {
      switch (item.getDatasetItem().originType) {
        case 'line':
          if (!item.getDatasetItem().lineStyle) {
            return `${item.getDatasetItem().originType.toLowerCase()}-dataset-item solid-dataset-item`;
          }
          return `${item.getDatasetItem().originType.toLowerCase()}-dataset-item ${item
            .getDatasetItem()
            .lineStyle.toLowerCase()}-dataset-item`;

        default:
          return `${item.getDatasetItem().originType.toLowerCase()}-dataset-item`;
      }
    }
  }
}
