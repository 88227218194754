import { Action } from '@app-modeleditor/components/button/action/action';
import { EntryElement } from '@app-modeleditor/components/entry-collection/entry-element';
import { GanttLibService } from 'frontend/src/dashboard/gantt/gantt/gantt-lib.service';
import { GeneralGanttActionHandler } from 'frontend/src/dashboard/gantt/general/action-handling/action-handler';
import { TriggerEventFactory } from 'frontend/src/dashboard/gantt/general/gantt-actions/event-factory';
import { GanttExternalActionRegistration } from 'frontend/src/dashboard/gantt/general/gantt-actions/external-action-registration';
import { GanttEventTrigger } from 'frontend/src/dashboard/gantt/general/gantt-actions/external-event-trigger';
import { GanttPluginHandlerService } from 'frontend/src/dashboard/gantt/general/plugin/gantt-plugin-handler.service';
import { GanttResponseHandler } from 'frontend/src/dashboard/gantt/general/response/response-handler';
import { MenuItem } from 'frontend/src/dashboard/view/template-toolbar/menu-item';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { TriggerEventBlockingIntervalFactory } from './event-factory';

/**
 * Handles user event registration for blocking interval plugin wrapper.
 */
export class GanttBlockingIntervalActionHandler implements GanttExternalActionRegistration {
  private _alive = true;
  private currentActionTriggers: GanttEventTrigger[] = [];
  private triggerEventFactory: TriggerEventFactory;

  private readonly _indicatorIdentifier = 'GanttBlockingIntervalActionHandler_IndicatorIdentifier';

  private ganttBlockEditPermissions: {
    dragDrop: boolean;
    draggingVertical: boolean;
    draggingHorizontal: boolean;
    resize: boolean;
  };

  // ids to find menu groups of blocking interval creation
  private _blockingIntervalMenuGroupIds: string[] = [
    'template.submenugroup.createblockinginterval',
    'template.submenugroup.createvehicleblockinginterval',
    'template.submenugroup.workerreservations',
  ];
  // additional menu group ids which are only relevant for indicators
  private _indicatorRelevantMenuGroupIds: string[] = ['template.submenugroup.editdeleteblockinginterval'];
  // entry element ids to ignore in methods for create buttons (e.g. for edit buttons in same group)
  private _createButtonIgnoreIds: string[] = [
    'template.entryelement.editReservation',
    'template.entryelement.editvehicleBlockingInterval',
  ];

  constructor(
    private _ganttLibService: GanttLibService,
    private _ganttPluginHandlerService: GanttPluginHandlerService,
    private ganttTimePeriodGroupExecuter: any,
    private actionHandler: GeneralGanttActionHandler,
    private responseHandler: GanttResponseHandler,
    private templateData: any,
    blockingIntervalData: any
  ) {
    this.triggerEventFactory = new TriggerEventBlockingIntervalFactory(templateData);
    this.currentActionTriggers = this.extractEventTriggersByGanttActions(
      blockingIntervalData.ganttTimePeriodGroupIntervalInputs
    );

    this.ganttTimePeriodGroupExecuter.dragEndBeforeMarkerAdding('deselectButtons', () => {
      this._deactivateAllCreateButtons();
    });
  }

  public destroy(): void {
    this.ganttTimePeriodGroupExecuter.removeDragEndBeforeMarkerAdding('deselectButtons');
    this._alive = false;
  }

  /**
   * Extracts and registers local and predefined actions by backend definition.
   * @param ganttIntervalActions Backend definiton of actions.
   */
  private extractEventTriggersByGanttActions(ganttIntervalActions: any[]): GanttEventTrigger[] {
    let triggerList: GanttEventTrigger[] = [];
    // add predefined actions
    const allIntervalHandler: any = this.ganttTimePeriodGroupExecuter.getAllIntervals();
    for (const intervalExecuterClazz in allIntervalHandler) {
      triggerList = triggerList.concat(
        this.triggerEventFactory.getPredefinedEvents(
          this._ganttPluginHandlerService,
          this._ganttLibService,
          allIntervalHandler[intervalExecuterClazz],
          this.actionHandler,
          this.responseHandler
        )
      );
    }
    // add local actions
    for (const ganttAction of ganttIntervalActions) {
      const intervalExecuter = this.ganttTimePeriodGroupExecuter.getPeriodExecuterById(ganttAction.clazz);
      if (!intervalExecuter) continue;
      triggerList = triggerList.concat(
        this.triggerEventFactory.getTriggerEventByActionTypes(
          this._ganttPluginHandlerService,
          this._ganttLibService,
          ganttAction.ganttPluginActions,
          intervalExecuter,
          this.actionHandler,
          this.responseHandler
        )
      );
    }

    return triggerList;
  }

  /**
   * Local action handling to switch plugin state to (de-)activate edit mode.
   * @param localAction Local action which will be executed.
   * @param callBackParams
   */
  public handleLocalAction(localAction: any, callBackParams?: any): Observable<any> {
    if (localAction.actionType == 'PREDEFINED') {
      switch (localAction.id) {
        case 'togglepluginmodecreate':
          this.ganttTimePeriodGroupExecuter.toggleIntervalById(localAction.actionPlugInTargetIds[0]);
          this._activateCreateButtonByLocalAction(localAction);
          return of(true);
        case 'togglepluginmodeedit':
          this._deactivateOtherIntervalEditButtons(localAction);
          const editModeIsActive: boolean = this.ganttTimePeriodGroupExecuter.toggleEditIntervalById(
            localAction.actionPlugInTargetIds
          );
          this._registerIndicatorByLocalAction(localAction, editModeIsActive);
          this.toggleShiftInteractionDuringEditMode(!editModeIsActive);
          this._deactivateAllCreateButtons();
          if (!editModeIsActive) {
            this.deselectPeriodsInTemplate();
          }
          return of(true);
      }
    }
    return of(null);
  }

  private deselectPeriodsInTemplate() {
    this.templateData.setSelectedblock({}); // TODO: must be removed as soon as the backend uses the selectedTimePeriodInput attribute (14.06.21)
    this.templateData.setSelectedTimePeriodInput(null);
    this.templateData.setSelectedTimePeriodInputGroupId(null);
    this.templateData.setSelectedTimePeriodInputs(null);
  }

  /**
   * Activates the visual selection of the button witch has triggered the given local action.
   */
  private _activateCreateButtonByLocalAction(localAction: any) {
    // iterate over menu items
    for (const menuItem of this.actionHandler.newToolbar.getMenuItems()) {
      // iterate over menu groups
      for (const group of menuItem.getToolbarGroups()) {
        if (this._blockingIntervalMenuGroupIds.includes(group.getId().toLowerCase())) {
          // if group is part of blockingIntervalMenuGroupIds array
          // iterate over menu group items
          for (const element of group.getEntryElements()) {
            const elem = element as any;
            const localActions = elem.localActions as Action[];

            if (!this._createButtonIgnoreIds.includes(element.getId())) {
              // special case, because edit button is in createvehicleblockinginterval group and should not be deselected
              of(null)
                .pipe(delay(0))
                .subscribe(() => {
                  element.setSelected(false); // deselect button if local action parameter is not in button element
                  this._registerIndicatorOnEntryElement(null, element, false);
                });
            }
            for (const action of localActions) {
              if (JSON.stringify(localAction) === JSON.stringify(action)) {
                // check if local action parameter is also in button element
                of(null)
                  .pipe(delay(0))
                  .subscribe(() => {
                    const isActive =
                      this.ganttTimePeriodGroupExecuter.getActiveIntervalId() === localAction.actionPlugInTargetIds[0];
                    element.setSelected(isActive); // set if active
                    this._registerIndicatorOnEntryElement(menuItem, element, isActive);
                  });
                break;
              }
            }
          }
        } else {
          continue;
        }
      }
    }
  }

  /**
   * Deactivates the visual selection of all buttons in the create create blocking interval group.
   */
  private _deactivateAllCreateButtons() {
    // iterate over menu items
    for (const menuItem of this.actionHandler.newToolbar.getMenuItems()) {
      // iterate over menu groups
      for (const group of menuItem.getToolbarGroups()) {
        if (this._blockingIntervalMenuGroupIds.includes(group.getId().toLowerCase())) {
          // if group is part of blockingIntervalMenuGroupIds array
          // iterate over menu group items
          for (const element of group.getEntryElements()) {
            if (!this._createButtonIgnoreIds.includes(element.getId())) {
              // special case, because edit button is in createvehicleblockinginterval group and should not be deselected
              of(null)
                .pipe(delay(0))
                .subscribe(() => {
                  element.setSelected(false); // set selection of all buttons in this group to false
                  this._registerIndicatorOnEntryElement(null, element, false);
                });
            }
          }
        } else {
          continue;
        }
      }
    }
  }

  /**
   * Registers/deregisters indicator registrations by a given local action.
   * @param localAction Local action to search for.
   * @param register Specifies whether to register or to deregister elements with the specified local action.
   */
  private _registerIndicatorByLocalAction(localAction: any, register: boolean) {
    // iterate over menu items
    for (const menuItem of this.actionHandler.newToolbar.getMenuItems()) {
      // iterate over menu groups
      for (const group of menuItem.getToolbarGroups()) {
        // check if group is relevant for blocking interval elements
        if (this.indicatorRelevantGroupIds.includes(group.getId().toLowerCase())) {
          // iterate over menu group items
          for (const element of group.getEntryElements()) {
            const elem = element as any;
            const localActions = elem.localActions as Action[];
            for (const action of localActions) {
              // check if entry element has specified local action
              if (JSON.stringify(localAction) === JSON.stringify(action)) {
                of(null)
                  .pipe(delay(0))
                  .subscribe(() => {
                    this._registerIndicatorOnEntryElement(menuItem, element, register);
                  });
                break;
              }
            }
          }
        }
      }
    }
  }

  /**
   * Returns the sub menu group of reservation blocking interval creation.
   */
  private _getCreateReservationBlockingIntervalSubMenuGroup(): EntryElement[] {
    // get menu
    const blockIntervalMenu = this.actionHandler.newToolbar
      .getMenuItems()
      .find((menuItem) => menuItem.getId() === 'vehicleblockingintervalSubMenu');
    if (!blockIntervalMenu) return [];
    // get group of menu
    const group = blockIntervalMenu
      .getToolbarGroups()
      .find((group) => group.getId() === 'template.submenugroup.createblockinginterval');
    if (!group) return [];
    // return entries of menu group
    return group.getEntryElements();
  }

  /**
   * Looking for other interval edit buttons and inactivates them by setting toggle to false.
   * @param localAction
   */
  private _deactivateOtherIntervalEditButtons(localAction: any) {
    const currentTargets = localAction.actionPlugInTargetIds;
    // new toolbar
    if (this.actionHandler.newToolbar) {
      this.actionHandler.newToolbar.getMenuItems().forEach((item) => {
        item.getToolbarGroups().forEach((group) => {
          group.getEntryElements().forEach((entry) => {
            const groupEntry = entry as any;
            if (groupEntry.hasOwnProperty('localActions') && Array.isArray(groupEntry.localActions)) {
              groupEntry.localActions.forEach((action) => {
                if (action.localID === 'togglepluginmodeedit') {
                  const targets = action.actionPlugInTargetIds;
                  if (
                    !(
                      targets.length === currentTargets.length &&
                      targets.every((value, index) => value === currentTargets[index])
                    )
                  ) {
                    // not same targetIds -> another button
                    if (groupEntry.selected) {
                      groupEntry.selected = false;
                      this._registerIndicatorOnEntryElement(null, groupEntry, false);
                    }
                  }
                }
              });
            }
          });
        });
      });
    }
  }

  /**
   * (De-)Registers an indicator for a specific entry element in a specific menu item.
   * @param menuItem Menu item to register the indicator for (not neccesary for deregistration).
   * @param entryElement Entry element to (de-)register the indicator for.
   * @param register Specifies whether to register or to deregister the entry element (default = true).
   * @param updateIndicators Specifies wherther to update the indicators after applying the changes or not (default = true).
   */
  private _registerIndicatorOnEntryElement(
    menuItem: MenuItem,
    entryElement: EntryElement,
    register = true,
    updateIndicators = true
  ): void {
    if (register) {
      this._ganttPluginHandlerService.toolbarIndicatorService.addIndicatorRegistration(
        this._indicatorIdentifier,
        menuItem,
        entryElement.getId()
      );
    } else {
      this._ganttPluginHandlerService.toolbarIndicatorService.removeIndicatorRegistration(
        this._indicatorIdentifier,
        entryElement.getId()
      );
    }
    if (updateIndicators) this._ganttPluginHandlerService.toolbarIndicatorService.updateIndicatorRegistrations();
  }

  /**
   * Disables shift movement during blocking interval edit-mode, resets it on exit edit-mode.
   * @param allowShiftEdit Activates shift if true, deactivates shifts on false.
   */
  private toggleShiftInteractionDuringEditMode(allowShiftEdit: boolean): void {
    const ganttDiagram = this._ganttLibService.bestGantt;

    if (allowShiftEdit) {
      // restore shift interaction state
      ganttDiagram.allowShiftDragDrop(this.ganttBlockEditPermissions.dragDrop);
      ganttDiagram.allowDraggingVertical(this.ganttBlockEditPermissions.draggingVertical);
      ganttDiagram.allowDraggingHorizontal(this.ganttBlockEditPermissions.draggingHorizontal);
      ganttDiagram.allowShiftResizer(this.ganttBlockEditPermissions.resize);
    } else {
      this.ganttBlockEditPermissions = {
        // save current shift interaction state
        dragDrop: ganttDiagram.getShiftFacade().getShiftBuilder().getDragDrop(),
        draggingVertical: ganttDiagram.getShiftTranslator().shiftEditLimiterAdapter.allowDragVertical,
        draggingHorizontal: ganttDiagram.getShiftTranslator().shiftEditLimiterAdapter.allowDragHorizontal,
        resize: ganttDiagram.getShiftResizer().shiftEditLimiterAdapter.allowShiftResizing,
      };
      // disable shift interaction
      ganttDiagram.allowShiftDragDrop(false);
      ganttDiagram.allowDraggingVertical(false);
      ganttDiagram.allowDraggingHorizontal(false);
      ganttDiagram.allowShiftResizer(false);
    }
  }

  /**
   * Returns an array which contains all menu group ids which are relevant for indicators.
   */
  private get indicatorRelevantGroupIds(): string[] {
    const relevantGroupIds: string[] = [];
    relevantGroupIds.push(...this._blockingIntervalMenuGroupIds);
    relevantGroupIds.push(...this._indicatorRelevantMenuGroupIds);
    return relevantGroupIds;
  }
}
