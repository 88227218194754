import {
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ContentChild,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { EntryCollection } from '@app-modeleditor/components/entry-collection/entry-collection';
import { EntryElement } from '@app-modeleditor/components/entry-collection/entry-element';
import { GlobalUtils } from 'frontend/src/dashboard/global-utils';
import { Template } from 'frontend/src/dashboard/model/resource/template';
import { ResizeEvent } from 'frontend/src/dashboard/view/resize/resize-event';
import { EResizeType } from 'frontend/src/dashboard/view/resize/resize-type.enum';
import { ResizeService } from 'frontend/src/dashboard/view/resize/resize.service';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { ElementsComponent } from './../../elements/elements.component';
import { VirtualLayout } from './virtual-layout';
import { VirtualRow } from './virtual-row';

@Component({
  selector: 'virtual-layout',
  templateUrl: './virtual-layout.component.html',
  styleUrls: ['./virtual-layout.component.scss'],
})
export class VirtualLayoutComponent implements OnInit, OnDestroy, OnChanges {
  @Input() layout: VirtualLayout;
  @Input() templates: Template[];
  @ContentChild('contentElement') contentElement: TemplateRef<any>;
  rows: VirtualRow[] = [];
  @Input() entryCollection: EntryCollection;
  @Input() disabled: boolean;
  itemsPerRow: number;
  viewportHeight: string;
  itemWidth: number;
  itemHeight: number;
  componentId: string = GlobalUtils.generateUUID();
  private resizeIds: string[] = [null, null];
  @ViewChild('fakeContainer') fakeContainer: ElementRef;
  containerWidth: number;
  fakeElement: Template;

  constructor(private _el: ElementRef, private _resizeApi: ResizeService, private _cd: ChangeDetectorRef) {}

  private _checkItemHeight(newWidth: number, newHeight: number): void {
    if (this.itemWidth !== newWidth || this.itemHeight !== newHeight) {
      of(null)
        .pipe(delay(0))
        .subscribe(() => {
          this.itemWidth = newWidth;
          this.itemHeight = newHeight;
          this._checkItemsPerRow();
        });
    }
  }

  private _checkItemsPerRow(): void {
    if (!this.containerWidth) {
      return;
    }
    of(null)
      .pipe(delay(0))
      .subscribe(() => {
        const itemsPerRow: number = Math.floor(this._el?.nativeElement.clientWidth / this.itemWidth);
        if (isNaN(itemsPerRow) || this.itemsPerRow === itemsPerRow) {
          return;
        }
        this.itemsPerRow = itemsPerRow;
        this._cd.detectChanges();
        this.initialize();
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.templates) {
      this.initialize();
    }
  }

  ngOnInit(): void {
    this.resizeIds[1] = this._resizeApi.create(
      this._el.nativeElement,
      (event: ResizeEvent) => {
        this.containerWidth = event.getNewWidth();
        this._checkItemsPerRow();
      },
      { types: [EResizeType.WIDTH] }
    );
    this.fakeElement = this.templates[0];

    of(null)
      .pipe(delay(0))
      .subscribe(() => {
        if (!this.fakeContainer) {
          return;
        }
        this.resizeIds[0] = this._resizeApi.create(this.fakeContainer.nativeElement, (event: ResizeEvent) => {
          this._checkItemHeight(event.getNewWidth(), event.getNewHeight());
        });
      });
  }

  ngOnDestroy(): void {
    this._resizeApi.complete(...this.resizeIds);
  }

  afterInitSlot(instance: ComponentRef<ElementsComponent>, el: EntryElement): void {
    instance.instance.elementData = el as any;
    instance.instance.disabled = this.disabled;
  }

  initialize(): void {
    if (isNaN(this.itemsPerRow) || !this.itemsPerRow) {
      return;
    }
    const rows: VirtualRow[] = [];
    this.templates.forEach((t: Template, index: number) => {
      if (index % this.itemsPerRow === 0) {
        const newRow: VirtualRow = new VirtualRow();
        rows.push(newRow);
      }

      rows[rows.length - 1].addTemplates(t);
    });
    this.viewportHeight =
      rows?.length * this.itemHeight < this.layout.getViewportHeight()
        ? `${rows.length * this.itemHeight}px`
        : undefined;

    if (rows?.length !== this.rows?.length) {
      this.rows = rows;
      this._cd.detectChanges();
    } else {
      this.rows = rows;
    }
  }
}
