<div class="chart-tooltip-wrapper">
  <div class="chart-tooltip-label" *ngIf="label">{{ label.tooltipLabel ? label.tooltipLabel : label.label }}</div>
  <div class="chart-tooltip-element-wrapper">
    <ng-container class="chart-tooltip-element" *ngFor="let item of chartDataGroups">
      <div class="chart-tooltip-element" *ngIf="item.data[labelIndex]">
        <div class="chart-tooltip-element-color" [ngStyle]="{ 'background-color': item.backgroundHexColor }"></div>
        <div class="chart-tooltip-element-label">{{ item.label }} : {{ item.data[labelIndex] }}</div>
      </div>
    </ng-container>
  </div>
  <div *ngIf="chartDataGroups.length > 1" class="chart-tooltip-stacked-value">
    <div>{{ 'sum' | translate }}:</div>
    <div>{{ getStackedValue() }}</div>
  </div>
</div>
