<!--<div *ngIf="widget.component != 'colorPicker'" class="popupContainer">
    <exporter [widgetComponent]="widget.component" [widget]="widget"></exporter>
</div>

<div *ngIf="widget.component == 'colorPicker'"  class="colorPickerContainer" >

    <p-colorPicker inline="true" [ngModel]="widget.chartColor" (onChange)="onChangeColor($event)"></p-colorPicker>

    <div> 
        <button class="actionBtn" mat-button (click)="onClose(true)">
            OK
        </button>
        <button class="actionBtn" mat-button (click)="onClose(false)">
            cancel
        </button>
    </div>
</div>-->
<button mat-icon-button *ngIf="closeButton" class="close" (click)="closeDialog()"><mat-icon>close</mat-icon></button>
<div #popupContent class="test"></div>
