<div
  *ngIf="formElement.getValue()"
  #labeledText
  class="labeled-text no-stretch"
  [class.auto-height]="breakLine"
  [class.has-align]="formElement.getAlign()"
  [class.compact]="compact === true"
>
  <mat-icon *ngIf="formElement.getInfoText(disabled)" [matMenuTriggerFor]="infoMenu" class="info-icon custom"
    >info
  </mat-icon>
  <ng-container
    *ngIf="
      (formElement?.getEntry()?.getValue() && !formElement.getName()) || formElement.getName() === '';
      else defaultText
    "
  >
    <span
      [style.color]="formElement?.getEntry()?.getColor()"
      [class]="formElement.getAlign() ? formElement.getAlign() : 'left text'"
      [class.break-line]="!breakLine"
    >
      {{ formElement?.getEntry()?.getValue() | translate }}
    </span>
  </ng-container>
  <ng-template #defaultText>
    <span [id]="formElement.getId()" [class]="'left text'" [class.break-line]="!breakLine">
      <ng-container *ngIf="Value.getValue() !== null && Value.getValue() !== undefined; else defaultText">
        <b>{{ formElement.getName() | translate }}:</b>
      </ng-container>
      <ng-template #defaultText>{{ formElement.getName() | translate }}</ng-template>
    </span>
    <span class="spacer" *ngIf="compact === true"></span>
    <span
      [style.color]="Value?.getColor()"
      [class]="formElement.getAlign() ? formElement.getAlign() : 'left text'"
      [class.break-line]="!breakLine"
      *ngIf="Value"
    >
      {{ Value.getValue() ? (StringValue.toString() | translate) : Value.getValue() }}
    </span>
  </ng-template>
</div>

<mat-menu #infoMenu="matMenu">
  <p class="info-box">
    {{ formElement.getInfoText(disabled) }}
  </p>
</mat-menu>
