import { BestGanttPlugIn } from '../gantt-plug-in';
import { SettingBubblesBuilder } from './setting-bubble-builder';

/**
 * @class
 * @constructor
 * @extends BestGanttPlugIn
 *
 * @requires BestGanttPlugIn
 * @requires SettingBubblesBuilder
 *
 * @deprecated This plug-in is no longer used (2023-11-29).
 */
export class SettingBubblesExecuter extends BestGanttPlugIn {
  settingBubblesBuilder: SettingBubblesBuilder;
  canvasShift: d3.Selection<SVGSVGElement, undefined, d3.BaseType, undefined>;
  selectedShiftProportions: any;
  isActive: boolean;
  private _subscriptions: any;

  constructor() {
    super(); //call super-constructor

    this.canvasShift = null;
    /**
     * @type {BestGantt}
     */
    this.ganttDiagram = null;
    this.selectedShiftProportions = null;

    this.settingBubblesBuilder;

    this.isActive = false;

    this._subscriptions = {
      shiftOnClick: null,
    };
  }

  /**
   * Intialize the settingBubbles.
   * @param {BestGantt} ganttDiagram
   */
  initPlugIn(ganttDiagram) {
    const s = this;
    s.ganttDiagram = ganttDiagram;

    s.canvasShift = s.ganttDiagram //get the shiftCanvas
      .getShiftFacade()
      .getShiftBuilder()
      .getCanvasInFrontShifts();

    s.settingBubblesBuilder = new SettingBubblesBuilder(s.canvasShift);
    s.activate();
  }

  activate() {
    const s = this;
    // callback subscribe
    s._subscriptions.shiftOnClick = s.ganttDiagram
      .getShiftFacade()
      .getShiftBuilder()
      .shiftOnClick()
      .subscribe((event) => s.buildTheBubbles(event)); //OnClick-event who is triggered by clicking on a shift
    s.ganttDiagram.getXAxisBuilder().addToZoomCallback('transformingTheBubbles' + this.UUID, s.transforming.bind(s)); //called by zooming

    s.isActive = true;
  }

  deActivate() {
    const s = this;
    // callback unsubscribe
    s._subscriptions.shiftOnClick.unsubscribe(); //OnClick-event who is triggered by clicking on a shift
    s.ganttDiagram.getXAxisBuilder().removeZoomCallback('transformingTheBubbles' + this.UUID); //called by zooming

    //remove existing bubbles
    s.settingBubblesBuilder.remove();
    s.isActive = false;
  }

  setActivation = function (activate) {
    const s = this;
    if (s.isActive == activate) return;
    if (activate) this.activate();
    else this.deActivate();
  };

  /**
   * Remove of plugin. Part of plugin lifecycle.
   */
  removePlugIn() {
    const s = this;
    s.deActivate();
  }

  /**
   * This function handles how the bubbles are build.
   * @param {*} data Information of click-event and rect-data.
   */
  buildTheBubbles(data) {
    const s = this;
    const transform = s.ganttDiagram.getShiftFacade().getShiftBuilder().getLastZoomTransformation();
    s.selectedShiftProportions = s.getParametersFromShiftBox(data);
    s.settingBubblesBuilder.build(s.selectedShiftProportions, transform, data.event.subject);
  }
  /**
   * This function calculates the actual proportions of the shift-box from the data.
   * The proportions will be returned in JSON-format.
   * @param {*} data Information of click-event and rect-data.
   */
  getParametersFromShiftBox(data) {
    let parameters;
    const transformInformation = this.ganttDiagram.getShiftFacade().getShiftBuilder().getLastZoomTransformation();
    const height = parseFloat(data.event.subject.height);
    const width = parseFloat(data.event.subject.width);

    parameters = {
      height: height,
      width: width,
      x: data.event.subject.x,
      y: data.event.subject.y,
      shiftBox: data.srcElement,
    };
    return parameters;
  }
  /**
   * This function handles the bubbles when zooming and scrolling.
   */
  transforming() {
    const s = this;

    if (!s.selectedShiftProportions) return;
    const transform = s.ganttDiagram.getShiftFacade().getShiftBuilder().getLastZoomTransformation(); //get scale- and transform-information
    s.settingBubblesBuilder.adjustTheCircles(s.selectedShiftProportions, transform);
  }

  //
  // CALLBACK
  //
  addOnBubbleClickedCallback = function (id, func) {
    this.settingBubblesBuilder.callback.onBubbleClicked[id] = func;
  };
  removeOnBubbleClickedCallback = function (id) {
    delete this.settingBubblesBuilder.callback.onBubbleClicked[id];
  };

  //
  // GETTER & SETTER
  //
  getBubbleBuilder = function () {
    return this.settingBubblesBuilder;
  };
}
