import { DataHandler } from '../../data-handler/data-handler';
import { GanttCanvasShift } from '../../data-handler/data-structure/data-structure';
import { NodeProportionsState } from '../../html-structure/node-proportion-state/node-proportion-state';
import { EGanttScrollContainer } from '../../html-structure/scroll-container.enum';
import { BestGantt } from '../../main';
import { RenderDataHandler } from '../render-data-handler';
import { RenderDataStateStorage } from '../render-data-state-storage';

/**
 * Helper class for {@link RenderDataHandler} to handle complex shift calculations.
 */
export class ShiftRenderDataFinder {
  /**
   * @param _ganttDiagram Reference to the {@link BestGantt} instance that should be used for calculations.
   */
  constructor(private _ganttDiagram: BestGantt) {}

  /**
   * Calculates the current y position of a shift in px from the top of the shift viewport.
   * @param shiftId Id of the shift to calculate the viewport y position for.
   * @returns Current y position of a shift in px from the top of the shift viewport.
   */
  public getShiftViewportY(shiftId: string): number {
    let viewportOffset = 0;

    const isShiftInStickyRow = this._stateStorage.isShiftInStickyRow(shiftId);
    const scrollContainerId = isShiftInStickyRow ? EGanttScrollContainer.STICKY_ROWS : EGanttScrollContainer.DEFAULT;
    viewportOffset -= this._nodeProportionsState.getScrollTopPosition(scrollContainerId);

    const shiftY = this._stateStorage.getYPositionShift(shiftId);
    return shiftY + viewportOffset;
  }

  /**
   * Tries to calculate the y position of a shift by the given {@link GanttCanvasShift} and the id of the row it is assigned to.
   * This method can be used in cases when the y position of a shift outside the viewport is needed as {@link RenderDataHandler} only
   * provides the y positions of canvas shifts which are in the current viewport.
   * @param shift {@link GanttCanvasShift} to calculate the y position for.
   * @returns Y position of the shift with the given {@link GanttCanvasShift} or `undefined` if the calculation fails.
   */
  public getShiftYByRowIdByShift(shift: { id: string; isFullHeight?: boolean }): number {
    const rowId = this._dataHandler.getShiftRowStorage().get(shift.id);
    if (!rowId) return undefined;
    const rowY = this._stateStorage.getYPositionRow(rowId);
    const shiftY = shift.isFullHeight ? rowY : rowY + this._ganttDiagram.getConfig().getLineTop();
    return shiftY;
  }

  /**
   * Filters the current canvas shift dataset for shifts which are in the scroll container with the specified id and returns them.
   * This method can be used in cases when all canvas shifts in a scroll container are needed as {@link RenderDataHandler} only
   * provides canvas shifts which are in the current viewport.
   * @param scrollContainerId Id of the scroll container to filter all canvas shifts for.
   * @returns Array of all canvas shifts which are in the specified scroll container.
   */
  public getShiftsByScrollContainer(scrollContainerId: EGanttScrollContainer): GanttCanvasShift[] {
    const shiftRowStorage = this._dataHandler.getShiftRowStorage();
    const shifts = this._dataHandler.getCanvasShiftDataset();

    const filteredShifts: GanttCanvasShift[] = [];

    for (const shift of shifts) {
      const rowId = shiftRowStorage.get(shift.id);
      if (this._stateStorage.isRowInScrollContainer(rowId, scrollContainerId)) {
        filteredShifts.push(shift);
      }
    }
    return filteredShifts;
  }

  //
  // GETTER & SETTER
  //

  /**
   * Helper getter which returns the node proportions state of the current gantt.
   */
  private get _nodeProportionsState(): NodeProportionsState {
    return this._ganttDiagram.getNodeProportionsState();
  }

  /**
   * Helper getter which returns the data handler of the current gantt.
   */
  private get _dataHandler(): DataHandler {
    return this._ganttDiagram.getDataHandler();
  }

  /**
   * Helper getter which returns the render data handler of the current gantt.
   */
  private get _renderDataHandler(): RenderDataHandler {
    return this._ganttDiagram.getRenderDataHandler();
  }

  /**
   * Helper getter which returns the state storage of the render data handler of the current gantt.
   */
  private get _stateStorage(): RenderDataStateStorage {
    return this._renderDataHandler.getStateStorage();
  }
}
