<div *ngIf="isAvailable" class="time-duration-picker-unit-wrapper">
  <div style="height: 15px">
    <span class="time-duration-picker-unit-label">{{ label }}</span>
  </div>
  <div style="height: 25px" class="time-duration-picker-unit-input-wrapper">
    <input
      id="number"
      class="time-duration-picker-unit-input"
      type="number"
      #spinner
      [ngStyle]="{
        width: getElementWidth() + 'px',
        'font-size': getFontSize() + 'px',
        'box-sizing': 'border-box'
      }"
      [ngModel]="value"
      (focus)="releaseButtons($event)"
      (blur)="blurring($event)"
      (ngModelChange)="onInputChange($event)"
      [attr.disabled]="inputDisabled ? '' : null"
      [style.color]="inputDisabled ? 'rgba(0, 0, 0, 0.56)' : 'null'"
    />
  </div>
</div>
