import { ETemplateType } from 'frontend/src/dashboard/model/resource/template-type';
import { ContentElement } from '../content/content-element/content-element';
import { StatusIcon } from './status-icon';

export class StatusBar extends ContentElement {
  private value: StatusIcon[];
  private statusIcons: StatusIcon[];

  public getStatusIcons(): StatusIcon[] {
    return this.statusIcons || [];
  }

  public setStatusIcons(statusIcons: StatusIcon[]): this {
    this.statusIcons = statusIcons;
    this.setValues(statusIcons);
    return this;
  }

  public getValues(): StatusIcon[] {
    return this.value || [];
  }

  public setValues(values: StatusIcon[]): this {
    this.value = values;
    return this;
  }

  constructor() {
    super();
    this.setType(ETemplateType.STATUS_BAR).setAutomaticallySetValues(true);
  }
}
