export const RICH_TEXT_EDITOR_TOOLBAR_BUTTONS: string[] = [
  'source',
  '|',
  'bold',
  'strikethrough',
  'underline',
  'italic',
  'eraser',
  '|',
  'superscript',
  'subscript',
  '|',
  'ul',
  'ol',
  '|',
  'outdent',
  'indent',
  '|',
  'font',
  'fontsize',
  'brush',
  'paragraph',
  '|',
  // 'image',
  // 'file',
  'table',
  'link',
  '|',
  'align',
  'undo',
  'redo',
];
