import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timePipe',
})
export class TimePipe implements PipeTransform {
  transform(millis: number, formats: string[] = ['hours', 'minutes'], twelveHours = false): string | undefined {
    if (isNaN(millis) || millis === null || millis === undefined) {
      return undefined;
    }
    let rest = twelveHours && millis > 43200000 ? millis - 43200000 : millis;
    let value = this.format(rest, 3600000);
    const hours = value.value;
    rest = value.rest;
    value = this.format(rest, 60000);
    const minutes = value.value;
    rest = value.rest;
    value = this.format(rest, 1000);
    const seconds = value.value;
    rest = value.rest;

    if (!formats) {
      return ``;
    }
    let formattedString = ``;

    if (formats.includes('hours')) {
      formattedString += hours;
    }

    if (formats.includes('minutes')) {
      if (formats.includes('hours')) {
      }
      formattedString += ':';
      formattedString += minutes;
    }

    if (formats.includes('seconds')) {
      if (formats.includes('hours') || formats.includes('minutes')) {
      }
      formattedString += ':';
      formattedString += seconds;
    }

    if (twelveHours) {
      formattedString += `${millis > 43200000 ? ' pm' : ' am'}`;
    }

    return formattedString;
  }

  private format(millis: number, diff: number): { value: string; rest: number; num: number } {
    let rest = millis;
    const num = Math.floor(rest / diff);
    let value: string = num.toString();
    value = value.length !== 2 ? `0${value}` : value;
    rest = rest % diff;
    return { value, rest, num };
  }
}
