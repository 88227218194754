<mat-list>
  <mat-list-item class="contentText">
    {{ 'GANTT.INDEXCARDS.timespan' | translate }}
    <mat-slide-toggle (change)="handleTimeSpan($event)" [checked]="settings.showIndexCardTimespan"></mat-slide-toggle>
  </mat-list-item>
  <mat-divider></mat-divider>
  <mat-list-item class="contentText">
    {{ 'GANTT.INDEXCARDS.emptyAttributes' | translate }}
    <mat-slide-toggle (change)="handleShowEmptyAttributes($event)" [checked]="settings.showIndexCardEmptyAttributes">
    </mat-slide-toggle>
  </mat-list-item>
</mat-list>
