import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { EntryElement } from '@app-modeleditor/components/entry-collection/entry-element';
import { EFieldType } from '@app-modeleditor/components/entry-collection/field-type.enum';
import { Table } from '@app-modeleditor/components/spreadsheet/model/table';
import { LanguageService } from '@core/provider/language.service';
import { TranslateService } from '@ngx-translate/core';
import { TimeFormatterService } from 'frontend/src/dashboard/model-editor/ui/output-formatter/time.formatter';
import { EDatatype } from 'frontend/src/dashboard/model/entry/datatype.enum';
import Handsontable from 'handsontable';
import { BasePlugin } from 'handsontable/plugins';
import { CustomEditor } from '../core/Customeditor/custom-editor';
import { SaxmsColumnHeaderIconSet } from '../core/saxms-column-header-template/saxms-column-header-template.component';
import { SaxMsSpreadSheetColumn } from '../core/saxms-spreadsheet';
import { FullSpreadsheetComponent } from '../full-spreadsheet/full-spreadsheet.component';
import { CopyPasteModel } from '../model/copy-paste-model';
import { ESaxMsColumnUnits } from '../model/table-column-unity.enum';
import { ESpreadsheetDatatypes } from '../model/table-datatypes.enum';
import { EFilterConditions } from '../model/table-filter-conditions.enum';
import { ESpreadsheetPlugin } from '../model/table-plugin.enum';
import { SaxmsTableOptions } from '../spreadsheet';

@Injectable()
export class SpreadsheetHelperUtil {
  private spreadSheetInstance: Handsontable;
  private tableId: string;
  private tableOptions: SaxmsTableOptions;
  private tableTemplate: Table;
  private columnIndexMap: Map<string, number> = new Map();
  private spreadsheetCustomEditor;
  private customEditor: CustomEditor = new CustomEditor();
  private rowCopyValues: any[] = [];
  private beforeCopyExecute = false;
  private numberMaxValueString = '';
  private numberValueFontsize = 14;
  private scope: FullSpreadsheetComponent;
  private columnHeaderIconSet: SaxmsColumnHeaderIconSet;

  constructor(
    private timeFormatterService: TimeFormatterService,
    private datePipe: DatePipe,
    private languageService: LanguageService,
    private translate: TranslateService
  ) {}

  /**
   * convert value to string by the duration picker part value uni
   * @param unit duration picker part unit
   * @param stringLength char length of the unit
   * @param value value
   * @returns the converted value
   */
  private convertDurationPickerPartValueToString(unit: string, stringLength: number, value: number): string {
    let stringValue = '';
    const tmpValue = value + unit + ' ';
    const nullCharsCount = stringLength - (tmpValue.length - 1);
    for (let i = 0; i < nullCharsCount; i++) {
      stringValue += '0';
    }
    stringValue += tmpValue;
    return stringValue;
  }

  /**
   * search the longest string the cells
   * @param columns columns of the table
   * @param rows rows of the table
   */
  private getNumberMaxValueString(columns: SaxMsSpreadSheetColumn[], rows: { [key: string]: any }[]) {
    this.numberMaxValueString = '';
    for (const col of columns) {
      if (col.datatype !== ESpreadsheetDatatypes.number && col.datatype !== ESpreadsheetDatatypes.float) {
        continue;
      }

      rows.forEach((row) => {
        if (col.listenToAutoFontSize) {
          const cellValue = this.scope.cellRendererUtil.getVisualValueOfCell(row[col.data], col, false);
          const cellValueLength = (cellValue + '').length;
          if (cellValueLength > this.numberMaxValueString.length) {
            this.numberMaxValueString = cellValue + '';
          }
        }
      });
    }
  }

  /**
   * setup the custom editor
   * @param wrapperElement parent html container of the editor
   * @param closeCallback close callback for the editor
   */
  public initEditor(
    wrapperElement: HTMLElement,
    closeCallback: (colIndex: number, rowIndex: number, value: any, closeByEnter: boolean) => void
  ): void {
    this.spreadsheetCustomEditor = this.customEditor.initEditor(wrapperElement, closeCallback);
  }

  /**
   * clear the column index map
   */
  public clearColumnIndexMap(): void {
    this.columnIndexMap.clear();
  }

  /**
   * add a index for a specific column id to the column index map
   * @param columnId id of the column
   * @param index index of the column (origin or reorder ist possible)
   */
  public addEntryToColumnIndexMap(columnId: string, index: number): void {
    this.columnIndexMap.set(columnId, index);
  }

  /**
   * remove a specific column id from the column index map
   * @param columnId id of the column
   */
  public removeEntryToColumnIndexMap(columnId: string): void {
    this.columnIndexMap.delete(columnId);
  }

  /**
   * returns a date string by the column field type
   * @param value value of the cell
   * @param columnSettings column object
   */
  public mappingDateString(value: any, columnSettings: SaxMsSpreadSheetColumn): string {
    const dateValue: Date = columnSettings.useLocalTime ? new Date(value) : this.getUtcDate(new Date(value));
    if (columnSettings.dateTimeFormat) {
      return this.datePipe.transform(
        dateValue,
        columnSettings.dateTimeFormat,
        undefined,
        this.languageService.getCurrentLang()
      );
    }

    switch (columnSettings.fieldType) {
      case 'DATE_TIME_PICKER':
        return this.timeFormatterService.dateToDateTimeString(dateValue);
      case 'DATE_PICKER':
        return this.timeFormatterService.dateToDateString(dateValue);
      case 'DURATION_PICKER':
        return this.mappingDurationPickerValue(parseFloat(value), columnSettings);
      case 'TIME_PICKER':
        return this.timeFormatterService.dateToTimeString(dateValue);
      default:
        return value !== 0 ? value : '';
    }
  }

  /**
   * convert a value in a duration picker string value (z.B. 4h 20m)
   * @param cellValue value of the cell as number
   * @param columnSettings information of the column
   * @returns duration picker string value
   */
  public mappingDurationPickerValue(cellValue: number, columnSettings: SaxMsSpreadSheetColumn): string {
    if (!columnSettings.durationPickerValues) {
      return '';
    }

    const { weeks, days, hours, minutes, seconds, millis } = this.translate.instant('PICKER.DURATION') as {
      [unitShortForm: string]: string;
    };

    let simpleInputStringValue = '';
    let rest = cellValue;
    let currentValue;
    if (columnSettings.durationPickerValues.find((value) => value === 'weeks')) {
      currentValue = Math.floor(rest / 604800000);
      simpleInputStringValue += this.convertDurationPickerPartValueToString(weeks, 2, currentValue);
      rest = rest % 604800000;
    }

    if (columnSettings.durationPickerValues.find((value) => value === 'days')) {
      currentValue = Math.floor(rest / 86400000);
      simpleInputStringValue += this.convertDurationPickerPartValueToString(days, 2, currentValue);
      rest = rest % 86400000;
    }

    if (columnSettings.durationPickerValues.find((value) => value === 'hours')) {
      currentValue = Math.floor(rest / 3600000);
      simpleInputStringValue += this.convertDurationPickerPartValueToString(hours, 2, currentValue);
      rest = rest % 3600000;
    }

    if (columnSettings.durationPickerValues.find((value) => value === 'minutes')) {
      currentValue = Math.floor(rest / 60000);

      simpleInputStringValue += this.convertDurationPickerPartValueToString(minutes, 2, currentValue);
      rest = rest % 60000;
    }

    if (columnSettings.durationPickerValues.find((value) => value === 'seconds')) {
      currentValue = Math.floor(rest / 1000);

      simpleInputStringValue += this.convertDurationPickerPartValueToString(seconds, 2, currentValue);
      rest = rest % 1000;
    }

    if (columnSettings.durationPickerValues.find((value) => value === 'millis')) {
      simpleInputStringValue += this.convertDurationPickerPartValueToString(millis, 3, rest);
    }

    return simpleInputStringValue;
  }

  /**
   * transform a string vlaue of a durationpicker in a number value
   * @param stringValue string value of the duration picker
   * @param columnSettings column
   * @returns a durationpicker string as number
   */
  public convertDurationPickerStringToValue(stringValue: string, columnSettings: SaxMsSpreadSheetColumn): number {
    if (!columnSettings.durationPickerValues || !stringValue) {
      return null;
    }

    const { weeks, days, hours, minutes, seconds, millis } = this.translate.instant('PICKER.DURATION') as {
      [unitShortForm: string]: string;
    };

    let durationPickerValue = 0;
    if (columnSettings.durationPickerValues.find((value) => value === 'weeks')) {
      durationPickerValue += parseInt(stringValue.substring(0, stringValue.indexOf(weeks))) * 604800000;
    }
    if (columnSettings.durationPickerValues.find((value) => value === 'days')) {
      durationPickerValue +=
        parseInt(stringValue.substring(stringValue.indexOf(weeks) + 1, stringValue.indexOf(days))) * 86400000;
    }
    if (columnSettings.durationPickerValues.find((value) => value === 'hours')) {
      durationPickerValue +=
        parseInt(stringValue.substring(stringValue.indexOf(days) + 1, stringValue.indexOf(hours))) * 3600000;
    }
    if (columnSettings.durationPickerValues.find((value) => value === 'minutes')) {
      durationPickerValue +=
        parseInt(stringValue.substring(stringValue.indexOf(hours) + 1, stringValue.indexOf(minutes))) * 60000;
    }
    if (columnSettings.durationPickerValues.find((value) => value === 'seconds')) {
      durationPickerValue +=
        parseInt(stringValue.substring(stringValue.indexOf(minutes) + 1, stringValue.indexOf(seconds))) * 1000;
    }
    if (columnSettings.durationPickerValues.find((value) => value === 'millis')) {
      durationPickerValue += parseInt(
        stringValue.substring(stringValue.indexOf(seconds) + 1, stringValue.indexOf(millis))
      );
    }

    return durationPickerValue;
  }

  /**
   * check if a plugin is existing and it is enabled or not
   * update the handsontable settings for the plugin
   * @param pluginName name of the plugin
   * @param settingsEntryName plugin name in the settings object
   * @returns flag is the plugin existing and enabled
   */
  public checkPlugin(pluginName: ESpreadsheetPlugin, settingsEntryName: string): boolean {
    // return true;
    const plugin: BasePlugin = this.scope.getPlugin(pluginName);
    if (plugin) {
      if (!plugin.enabled && this.spreadSheetInstance) {
        plugin.enablePlugin();
        // if (this.spreadSheetInstance && !this.spreadSheetInstance.isDestroyed && !this.settings[settingsEntryName]) {
        //   this.settings[settingsEntryName] = true;
        //   const entry = {};
        //   entry[settingsEntryName] = true;
        //   // this.updateSettings(entry);
        // }
      }
      return true;
    }
    return false;
  }

  /**
   * handle the calculation ofthe scaling of the font size
   * @param columns columns of the table
   * @param rows rows of the table
   */
  public calcFontSizeAutoScale(columns: SaxMsSpreadSheetColumn[], rows: { [key: string]: any }[]): void {
    this.getNumberMaxValueString(columns, rows);
  }

  /**
   * update the current font size of the table
   * @param columns columns of the table
   */
  public updateFontSize(columns: SaxMsSpreadSheetColumn[]): void {
    let minWidth = 0;

    columns.forEach((col) => {
      if (minWidth == 0 || (minWidth && col.width > 0 && col.width < minWidth)) {
        minWidth = col.width;
      }
    });

    if (!minWidth) {
      return;
    }

    const letterWidth = minWidth / this.numberMaxValueString.length;
    if (letterWidth >= 10) {
      this.numberValueFontsize = 14;
    } else if (letterWidth >= 7) {
      this.numberValueFontsize = 12;
    } else if (letterWidth >= 6) {
      this.numberValueFontsize = 11;
    } else if (letterWidth >= 4) {
      this.numberValueFontsize = 9;
    } else if (letterWidth >= 2) {
      this.numberValueFontsize = 8;
    }
  }

  public getFilterConditions(header: SaxMsSpreadSheetColumn, element: EntryElement): EFilterConditions[] {
    switch (this.getDatatypeForQuicksearch(header)) {
      case EDatatype.LONG:
        element?.setCondition(EFilterConditions.gte);
        return [
          EFilterConditions.gte,
          EFilterConditions.lte,
          EFilterConditions.eq,
          EFilterConditions.lt,
          EFilterConditions.gt,
        ];
      case EDatatype.STRING:
        if (header.fieldType === EFieldType.COMBO_BOX) {
          element?.setCondition(EFilterConditions.eq);
          return [
            EFilterConditions.eq,
            EFilterConditions.contains,
            EFilterConditions.not_contains,
            EFilterConditions.begins_with,
            EFilterConditions.ends_with,
          ];
        } else {
          element?.setCondition(EFilterConditions.contains);
          return [
            EFilterConditions.contains,
            EFilterConditions.not_contains,
            EFilterConditions.begins_with,
            EFilterConditions.ends_with,
          ];
        }
      case EDatatype.FLOAT:
      case EDatatype.INTEGER:
        element?.setCondition(EFilterConditions.gte);
        return [
          EFilterConditions.gte,
          EFilterConditions.lte,
          EFilterConditions.eq,
          EFilterConditions.lt,
          EFilterConditions.gt,
        ];
      case EDatatype.BOOLEAN:
        element?.setCondition(EFilterConditions.eq);
        return [EFilterConditions.eq, EFilterConditions.neq];
      default:
        element?.setCondition(EFilterConditions.contains);
        return [
          EFilterConditions.contains,
          EFilterConditions.not_contains,
          EFilterConditions.begins_with,
          EFilterConditions.ends_with,
        ];
    }
  }

  /**
   * init the sort and filter icons for the column header templates
   */
  public initColumnHeaderIconSet(): void {
    this.columnHeaderIconSet = {
      sortAsc: this.scope.getConfigApi().getIcon('SORTUP').getName(),
      sortDesc: this.scope.getConfigApi().getIcon('SORTDOWN').getName(),
      filter: this.scope.getConfigApi().getIcon('FILTERTABLE').getName(),
      filterDesc: this.scope.getConfigApi().getIcon('FILTERSORTDOWNTABLE').getName(),
      filterAsc: this.scope.getConfigApi().getIcon('FILTERSORTUPTABLE').getName(),
      svgIcons: true,
    };
  }

  /**
   * updates the flag which determines if a copied function was executed before a change event occurred
   * @param beforeCopyExecute new state of the flag
   * @returns the SpreadsheetHelperUtil instance
   */
  public setBeforeCopyExecute(beforeCopyExecute: boolean): this {
    this.beforeCopyExecute = beforeCopyExecute;
    return this;
  }

  /**
   * update the copied values for the paste in the future
   * @param rowCopyValues copied values
   * @returns the SpreadsheetHelperUtil instance
   */
  public setRowCopyValues(rowCopyValues: CopyPasteModel[]): this {
    this.rowCopyValues = rowCopyValues;
    return this;
  }

  /**
   * update the table options
   * @param tableOptions options of the table
   * @returns the SpreadsheetHelperUtil instance
   */
  public setTableOptions(tableOptions: SaxmsTableOptions): this {
    this.tableOptions = tableOptions;
    return this;
  }

  /**
   * update the tbale id
   * @param tableId id of the table
   * @returns the SpreadsheetHelperUtil instance
   */
  public setTableId(tableId: string): this {
    this.tableId = tableId;
    return this;
  }

  /**
   * update the spreadsheetinstance
   * @param spreadSheetInstance
   * @returns the SpreadsheetHelperUtil instance
   */
  public setSpreadSheetInstance(spreadSheetInstance: Handsontable): this {
    this.spreadSheetInstance = spreadSheetInstance;
    return this;
  }

  /**
   * update the table template information
   * @param tableTemplate
   * @returns the SpreadsheetHelperUtil instance
   */
  public setTableTemplate(tableTemplate: Table): this {
    this.tableTemplate = tableTemplate;
    return this;
  }

  /**
   * upadte the table component as scope
   * @param scope FullSpreadsheetComponent instance
   * @returns the SpreadsheetHelperUtil instance
   */
  public setScope(scope: FullSpreadsheetComponent): this {
    this.scope = scope;
    return this;
  }

  /**
   * calc the decimal places of a column
   * @param columnSettings column information
   * @returns number ob the decimal places of a column (default return 1)
   */
  public getFixedCount(columnSettings: SaxMsSpreadSheetColumn): number {
    if (!isNaN(columnSettings.stepWidth)) {
      const stepWidth: string[] = columnSettings.stepWidth.toString().split('.');
      return stepWidth.length > 1 && parseInt(stepWidth[1]) !== 0 ? stepWidth[1].length : 0;
    }
    return 0;
  }

  /**
   * convert a date to the UTCDate
   * @param date
   * @returns the UTCDate of the passed date
   */
  public getUtcDate(date: Date): Date {
    const utcDate = new Date(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds(),
      date.getUTCMilliseconds()
    );

    // utcDate.set
    return utcDate;
  }

  /**
   * returns the datatype of a quicksearch of a column
   * @param column column of the quicksearch
   * @returns datatype of a quicksearch
   */
  public getDatatypeForQuicksearch(column: SaxMsSpreadSheetColumn): EDatatype {
    switch (column.datatype) {
      case ESpreadsheetDatatypes.bool:
        return EDatatype.BOOLEAN;
      case ESpreadsheetDatatypes.text:
        return EDatatype.STRING;
      case ESpreadsheetDatatypes.float:
        return EDatatype.FLOAT;
      case ESpreadsheetDatatypes.number:
        return EDatatype.INTEGER;
      case ESpreadsheetDatatypes.date:
      case ESpreadsheetDatatypes.long:
        return EDatatype.LONG;
    }
  }

  /**
   * checks if the column is a numeric column
   * @param column
   * @returns the value if the column is numeric
   */
  public isNumberField(column: SaxMsSpreadSheetColumn): boolean {
    switch (column.unit) {
      case ESaxMsColumnUnits.percent:
      case ESaxMsColumnUnits.euro:
        return true;
    }
    switch (column.datatype) {
      case ESpreadsheetDatatypes.float:
      case ESpreadsheetDatatypes.number:
      case ESpreadsheetDatatypes.long:
        return true;
      default:
        return false;
    }
  }

  /**
   * fill array with values from start to end
   * @param start number
   * @param end number
   * @returns number[]
   */
  public range(start: number, end: number): number[] {
    if (start === end) {
      return [start];
    }

    if (start === -1) {
      start = 0;
    }

    if (start > end) {
      const temp: number = start;
      start = end;
      end = temp;
    }

    return [start, ...this.range(start + 1, end)];
  }

  /**
   * returns real row index
   * @param index visible index
   */
  public getRowIndex(index: number): number {
    if (this.spreadSheetInstance && !this.spreadSheetInstance.isDestroyed) {
      return this.spreadSheetInstance.toPhysicalRow(index);
    }
  }

  /**
   * gets currently selected row index in table
   * @retruns number
   */
  public getCurrentSelectedRowIndex(): number {
    const selection: number[] = this.spreadSheetInstance.getSelectedLast();
    if (Array.isArray(selection)) {
      return this.spreadSheetInstance.toPhysicalRow(selection[0]);
    }

    return undefined;
  }

  /**
   * returns real column index
   * @param index visible index
   */
  public getColumnIndex(index: number): number {
    if (this.spreadSheetInstance && !this.spreadSheetInstance.isDestroyed) {
      return this.spreadSheetInstance.toPhysicalColumn(index);
    }
  }

  /**
   * returns visual row index
   * @param index visible index
   */
  public getVisualRowIndex(index: number): number {
    if (this.spreadSheetInstance && !this.spreadSheetInstance.isDestroyed) {
      return this.spreadSheetInstance.toVisualRow(index);
    }
    return null;
  }

  /**
   * returns visual column index
   * @param index visible index
   */
  public getVisualColumnIndex(index: number): number {
    if (this.spreadSheetInstance && !this.spreadSheetInstance.isDestroyed) {
      return this.spreadSheetInstance.toVisualColumn(index);
    }
    return null;
  }

  /**
   * @returns the current tabletemplate
   */
  public getTableTemplate(): Table {
    return this.tableTemplate;
  }

  /**
   * @returns the current real handsontable editor
   */
  public getSpreadsheetCustomEditor(): any {
    return this.spreadsheetCustomEditor;
  }

  /**
   * @returns the current font size of the table
   */
  public getNumberValueFontsize(): number {
    return this.numberValueFontsize;
  }

  /**
   * @returns the current custom editor
   */
  public getCustomEditor(): CustomEditor {
    return this.customEditor;
  }

  /**
   * @returns the current value if a copied function was executed
   */
  public isBeforeCopyExecute(): boolean {
    return this.beforeCopyExecute;
  }

  /**
   * @returns the current copied value of the table
   */
  public getRowCopyValues(): CopyPasteModel[] {
    return this.rowCopyValues;
  }

  /**
   * @returns the filter and sort icon of the column-header-templates
   */
  public getColumnHeaderIconSet(): SaxmsColumnHeaderIconSet {
    return this.columnHeaderIconSet;
  }

  /**
   * @returns the current tableoptions
   */
  public getTableOptions(): SaxmsTableOptions {
    return this.tableOptions;
  }

  /**
   * @returns the current table id
   */
  public getTableId(): string {
    return this.tableId;
  }

  /**
   * @returns the current instance of the handsontable
   */
  public getSpreadSheetInstance(): Handsontable {
    return this.spreadSheetInstance;
  }

  /**
   * @returns the current the column index map
   */
  public getColumnIndexMap(): Map<string, number> {
    return this.columnIndexMap;
  }
}
