import * as d3 from 'd3';
import { EGanttShiftColorMode } from '../../config/config-data/shift-config.data';
import { GanttCanvasShift, GanttDataRow } from '../../data-handler/data-structure/data-structure';
import { GanttUtilities } from '../../gantt-utilities/gantt-utilities';
import { GanttShiftsAfterRenderEvent, ShiftBuilder } from '../shift-builder';
import { IShiftClickEvent } from '../shift-events.interface';
import { IGanttShiftsBuilding } from './shift-build-interface';

/**
 * Default shift building.
 */
export class GanttShiftsBuildingDefault implements IGanttShiftsBuilding {
  private readonly maxShiftWidth = 20000000;

  /**
   * @param _parentNode If specified, shifts will be rendered in this container instead of the default container.
   * @param _isOverlay If `true`, the parent node will be treated as non-scrollable overlay.
   */
  constructor(
    private _parentNode: d3.Selection<SVGGElement, undefined, d3.BaseType, undefined> = undefined,
    private readonly _isOverlay = false
  ) {}

  /**
   * @override
   */
  public init(executer: ShiftBuilder): void {}

  /**
   * @override
   */
  public renderShifts(
    dataSet: GanttCanvasShift[],
    parentNode: d3.Selection<SVGGElement, undefined, d3.BaseType, undefined>,
    executer: ShiftBuilder
  ): void {
    const s = executer;
    const self = this;

    if (!dataSet) return;

    const parent = this._getParentNode(executer, parentNode);

    let dragStartEvent, draggedElementCoordinates, disableTargetMove, isToleranceOvercome;

    s.setAllShifts(parent.selectAll<SVGRectElement, GanttCanvasShift>('.gantt_shifts').data(dataSet));

    s.getAllShifts()
      .attr('width', function (d) {
        return s
          .getShiftCalculationStrategy()
          .getShiftWidth(d, s.getLastZoomTransformation(), s.getShiftCalculationStrategy().getShiftStrokeWidth(d));
      })
      .attr('height', function (d) {
        return s
          .getShiftCalculationStrategy()
          .getShiftHeight(d, s.getShiftCalculationStrategy().getShiftStrokeWidth(d));
      })
      .attr('x', function (d) {
        return s
          .getShiftCalculationStrategy()
          .getShiftX(d, s.getLastZoomTransformation(), s.getShiftCalculationStrategy().getShiftStrokeWidth(d));
      })
      .attr('y', (d) => {
        if (this._isOverlay) {
          return s
            .getShiftCalculationStrategy()
            .getShiftViewportY(d, s.getShiftCalculationStrategy().getShiftStrokeWidth(d));
        }
        return s.getShiftCalculationStrategy().getShiftY(d, s.getShiftCalculationStrategy().getShiftStrokeWidth(d));
      })
      .attr('rx', function (d) {
        return s.getShiftCalculationStrategy().getShiftCornerRadius(d);
      })
      .attr('ry', function (d) {
        return s.getShiftCalculationStrategy().getShiftCornerRadius(d);
      })
      // use fill as an attribute here, d3.js has problems with .style() to update
      .attr('fill', function (d) {
        return s.getShiftCalculationStrategy().getShiftFillColorForSVG(d);
      })
      .attr('stroke', function (d) {
        return s.getShiftCalculationStrategy().getShiftStrokeColor(d);
      })
      .attr('stroke-width', function (d) {
        return s.getShiftCalculationStrategy().getShiftStrokeWidth(d);
      });

    s.getAllShifts()
      .enter()
      .append('rect')
      .attr('class', 'gantt_shifts')
      .attr('width', function (d) {
        return s
          .getShiftCalculationStrategy()
          .getShiftWidth(
            d,
            s.getLastZoomTransformation(),
            s.getShiftCalculationStrategy().getShiftStrokeWidth(d),
            self.maxShiftWidth
          );
      })
      .attr('height', function (d) {
        return (d.height || s.ganttConfig.shiftHeight()) - s.getShiftCalculationStrategy().getShiftStrokeWidth(d);
      })
      .attr('x', function (d) {
        return s
          .getShiftCalculationStrategy()
          .getShiftX(
            d,
            s.getLastZoomTransformation(),
            s.getShiftCalculationStrategy().getShiftStrokeWidth(d),
            self.maxShiftWidth
          );
      })
      .attr('y', (d) => {
        if (this._isOverlay) {
          return s
            .getShiftCalculationStrategy()
            .getShiftViewportY(d, s.getShiftCalculationStrategy().getShiftStrokeWidth(d));
        }
        return s.getShiftCalculationStrategy().getShiftY(d, s.getShiftCalculationStrategy().getShiftStrokeWidth(d));
      })
      .attr('fill', function (d) {
        return s.getShiftCalculationStrategy().getShiftFillColorForSVG(d);
      })
      .attr('stroke', function (d) {
        return s.getShiftCalculationStrategy().getShiftStrokeColor(d);
      })
      .attr('stroke-width', function (d) {
        return s.getShiftCalculationStrategy().getShiftStrokeWidth(d);
      })
      .attr('stroke-dasharray', function (d) {
        return s
          .getShiftCalculationStrategy()
          .getShiftStrokeDasharray(d, s.getLastZoomTransformation(), self.maxShiftWidth);
      })
      .attr('rx', function (d) {
        return s.getShiftCalculationStrategy().getShiftCornerRadius(d);
      })
      .attr('ry', function (d) {
        return s.getShiftCalculationStrategy().getShiftCornerRadius(d);
      })
      .attr('clip-path', s.getClipPathHandler()?.clipPathUrl)
      .on('mouseover', function (event) {
        s.shiftMouseOverEvent(event);
      })
      .on('mouseout', function (event) {
        s.shiftMouseOutEvent(event);
      })
      .on('contextmenu', function (event, d) {
        if (!s.getAllowShiftClicks() || !d.editable) {
          event.preventDefault();
          event.stopPropagation();
          return;
        }
        s.shiftOnContextMenuEvent({ event, target: d3.select(this) });
      })
      .on('mousedown', function (event, d) {
        if (!s.getAllowShiftClicks() || !d.editable) {
          return;
        }
        if (event.button === 1) {
          s.shiftOnMiddleClickEvent(event);
          event.preventDefault();
        } else if (event.button === 0 || event.button === 2) {
          s.shiftOnMouseDownEvent(event);
        }
      })
      .on('click', function (event, d) {
        if (!s.getAllowShiftClicks() || !d.editable) {
          return;
        }
        s.shiftOnClickDirectlyEvent({ event, target: d3.select(this) });
        self._clearSingleClickTimeout(s);

        s.setSingleClickTimeout(
          setTimeout(self._singleClickHandler, 400, s, {
            event,
            target: d3.select(this),
          })
        );
      })
      .on('dblclick', function (event, d) {
        if (!s.getAllowShiftClicks() || !d.editable) {
          return;
        }
        self._clearSingleClickTimeout(s);
        self._doubleClickHandler(s, { event, target: d3.select(this) });
      })
      .call(
        d3
          .drag<SVGRectElement, GanttCanvasShift>()
          .on('start', function (event, d: GanttCanvasShift) {
            GanttUtilities.dispatchD3EventToOutside(d3.select(this), event);
            isToleranceOvercome = false;
            draggedElementCoordinates = d3.pointer(event);
            dragStartEvent = event;
            disableTargetMove = !!d.disableMove || !d.editable;
          })
          .on('drag', function (event) {
            GanttUtilities.dispatchD3EventToOutside(d3.select(this), event);
            const mouse = d3.pointer(event);
            if (s.getDragDrop() && isToleranceOvercome && !disableTargetMove) {
              // only executed if start dragging
              if (!s.getHasBeenDragged()) {
                s.setHasBeenDragged(true);
                if (s.ganttConfig.getShiftBuildingShowShiftContent()) {
                  parent.selectAll('.gantt_shift_contents').remove();
                }
                s.shiftDragStartEvent({ event, target: d3.select(this), hasBeenDragged: s.getHasBeenDragged() });
              }
              // executed while dragging
              s.shiftDraggingEvent({ event, target: d3.select(this), hasBeenDragged: s.getHasBeenDragged() });
            } else {
              if (!s.getHasBeenDragged()) {
                s.blockedShiftDragStartEvent({ event, target: d3.select(this), hasBeenDragged: s.getHasBeenDragged() });
              }
              s.blockedShiftDraggingEvent({ event, target: d3.select(this), hasBeenDragged: s.getHasBeenDragged() });
            }

            if (
              Math.abs(draggedElementCoordinates[0] - mouse[0]) > s.ganttConfig.getShiftDragStartTolerance() ||
              Math.abs(draggedElementCoordinates[1] - mouse[1]) > s.ganttConfig.getShiftDragStartTolerance()
            ) {
              isToleranceOvercome = true;
            }
          })
          .on('end', function (event, d: GanttCanvasShift) {
            GanttUtilities.dispatchD3EventToOutside(d3.select(this), event);
            if (s.getDragDrop() && !disableTargetMove) {
              // if (s.ganttConfig.getShiftBuildingShowShiftContent()) self._buildShiftContent(s, parent, dataSet);
              s.shiftDragEndEvent({ event, target: d3.select(this), hasBeenDragged: s.getHasBeenDragged() });
            } else {
              s.blockedShiftDragEndEvent({ event, target: d3.select(this), hasBeenDragged: s.getHasBeenDragged() });
            }
            if (!s.getHasBeenDragged() && s.getAllowShiftClicks() && d.editable) {
              // Execute onclick function if there was no drag
            }
            s.setHasBeenDragged(false);
            isToleranceOvercome = false;
            disableTargetMove = false;
          })
      );

    s.getAllShifts().exit().remove();

    const additionalShiftElements = parent.append('g').attr('class', 'additionalShiftElements');

    if (executer.ganttConfig.getShiftBuildingColorMode() === EGanttShiftColorMode.SHOW_MULTIPLE_COLORS) {
      self._drawSecondColor(s, additionalShiftElements, dataSet);
    }

    if (s.ganttConfig.getShiftBuildingShowShiftContent()) self._buildShiftContent(s, parent, dataSet);

    s.afterShiftRenderEvent(new GanttShiftsAfterRenderEvent(parent.selectAll('rect'), s.getLastZoomTransformation()));
  }

  private _drawSecondColor(
    executer: ShiftBuilder,
    parent: d3.Selection<any, any, d3.BaseType, undefined>,
    dataSet: GanttCanvasShift[]
  ): void {
    const s = executer;
    const self = this;

    s.setAllShiftSecondColorOverlays(
      parent
        .selectAll<SVGPathElement, GanttCanvasShift>('.gantt_shift_second_color_overlay')
        .data(dataSet.filter((d) => d.secondColor !== undefined))
    );

    s.getAllShiftSecondColorOverlays()
      .attr('d', (d) => {
        return self._getSecondColorOverlayPath(executer, d);
      })
      .attr('fill', function (d) {
        if (d.highlighted) {
          return d.highlighted;
        }
        if (d.selected) {
          const selectColorInterpolation = d3.interpolate({ colors: [d.secondColor] }, { colors: [d.selected] });
          return selectColorInterpolation(s.ganttConfig.colorSelectOpacity()).colors[0];
        }
        return d.secondColor;
      });

    s.getAllShiftSecondColorOverlays()
      .enter()
      .append('path')
      .attr('class', 'gantt_shift_second_color_overlay')
      .attr('d', (d) => {
        return self._getSecondColorOverlayPath(executer, d);
      })
      .attr('fill', function (d) {
        if (d.highlighted) {
          return d.highlighted;
        }
        if (d.selected) {
          const selectColorInterpolation = d3.interpolate({ colors: [d.secondColor] }, { colors: [d.selected] });
          return selectColorInterpolation(s.ganttConfig.colorSelectOpacity()).colors[0];
        }
        return d.secondColor;
      })
      .attr('clip-path', s.getClipPathHandler()?.clipPathUrl)
      .style('pointer-events', 'none');

    s.getAllShiftSecondColorOverlays().exit().remove();
  }

  private _getSecondColorOverlayPath(s: ShiftBuilder, d: GanttCanvasShift): string {
    const self = this;

    const strokeWidth = d.strokeColor ? s.ganttConfig.getShiftStrokeWidth() : 0;
    const x = s
      .getShiftCalculationStrategy()
      .getShiftX(d, s.getLastZoomTransformation(), strokeWidth, self.maxShiftWidth);
    const y = this._isOverlay
      ? s.getShiftCalculationStrategy().getShiftViewportY(d)
      : s.getShiftCalculationStrategy().getShiftY(d);
    let width = s
      .getShiftCalculationStrategy()
      .getShiftWidth(d, s.getLastZoomTransformation(), strokeWidth, self.maxShiftWidth);
    if (width < 0) {
      width = 0;
    }
    const height = s.getShiftCalculationStrategy().getShiftHeight(d, strokeWidth);
    const heightWithoutShiftContent = s.ganttConfig.getShiftBuildingShowShiftContent()
      ? s.getShiftCalculationStrategy().getShiftLabelHeight(d)
      : height;
    const round = s.getShiftCalculationStrategy().getShiftCornerRadius(d);

    let path: string;
    if (round && width - 2 * round > 0) {
      path = `
        M${x + width - round},${y}
        q${round},${0} ${round},${round}
        v${heightWithoutShiftContent - 2 * round}
        q${0},${round} ${-round},${round}
        h${-width + 2 * round}
        z
        `;
    } else {
      path = `
          M${x + width},${y}
          v${heightWithoutShiftContent}
          h${-width}
          z
          `;
    }
    return path;
  }

  /**
   * Handles a single click event on a shift.
   * Calls CallbackStacks accordingly.
   * @param {ShiftBuilder} executer - The ShiftBuilder instance.
   * @param {IShiftClickEvent} dragEvent - The single click event.
   */
  private _singleClickHandler(executer: ShiftBuilder, dragEvent: IShiftClickEvent): void {
    const s = executer;

    s.shiftOnClickEvent(dragEvent);
    s.shiftAfterOnClickEvent(dragEvent);
  }

  /**
   * Handles a double click event on a shift.
   * Calls CallbackStacks accordingly.
   * @param {ShiftBuilder} executer - The ShiftBuilder instance.
   * @param {IShiftClickEvent} dragEvent - The double click event.
   */
  private _doubleClickHandler(executer: ShiftBuilder, dragEvent: IShiftClickEvent): void {
    const s = executer;

    s.shiftOnDoubleClickEvent(dragEvent);
    s.shiftAfterOnDoubleClickEvent(dragEvent);
  }

  /**
   * Clears the single click timeout if it exists.
   * @param {ShiftBuilder} executer - The ShiftBuilder instance.
   */
  private _clearSingleClickTimeout(executer: ShiftBuilder): void {
    const s = executer;

    if (s.getSingleClickTimeout()) {
      clearTimeout(s.getSingleClickTimeout());
      s.setSingleClickTimeout(undefined);
    }
  }

  /**
   * Builds a white content field into shifts
   * @param {ShiftBuilder} executer
   * @param {D3Selection} parent
   * @param {GanttCanvasShift[]} dataSet
   */
  private _buildShiftContent(
    executer: ShiftBuilder,
    parent: d3.Selection<any, any, d3.BaseType, undefined>,
    dataSet: GanttCanvasShift[]
  ): void {
    const s = executer;
    const self = this;

    s.setAllShiftContents(parent.selectAll<SVGPathElement, GanttCanvasShift>('.gantt_shift_contents').data(dataSet));

    s.getAllShiftContents()
      .attr('d', (d) => {
        const strokeWidth = s.getShiftCalculationStrategy().getShiftStrokeWidth(d);
        const x =
          s.getShiftCalculationStrategy().getShiftX(d, s.getLastZoomTransformation(), 0, self.maxShiftWidth) +
          strokeWidth;
        const shiftY = this._isOverlay
          ? s.getShiftCalculationStrategy().getShiftViewportY(d)
          : s.getShiftCalculationStrategy().getShiftY(d);
        const y = shiftY + s.getShiftCalculationStrategy().getShiftLabelHeight(d) + strokeWidth / 2;
        let width =
          s.getShiftCalculationStrategy().getShiftWidth(d, s.getLastZoomTransformation(), 0, self.maxShiftWidth) -
          2 * strokeWidth;
        if (width < 0) {
          width = 0;
        }
        const height =
          s.getShiftCalculationStrategy().getShiftHeight(d) -
          s.getShiftCalculationStrategy().getShiftLabelHeight(d) -
          1.5 * strokeWidth;
        if (height < 3) {
          return null;
        } // build white area only if it is large enough
        const round = s.getShiftCalculationStrategy().getShiftCornerRadius(d);

        let path: string;
        if (round && width - 2 * round > 0) {
          path = `
        M${x},${y}
        h${width}
        v${height - round}
        q0,${round} -${round},${round}
        h-${width - 2 * round < 0 ? width : width - 2 * round}
        q-${round},0 -${round},-${round}
        z
        `;
        } else {
          path = `
          M${x},${y}
          h${width}
          v${height}
          h-${width}
          z
          `;
        }
        return path;
      })
      .attr('fill', function (d) {
        if (d.selected) {
          const selectColorInterpolation = d3.interpolate({ colors: ['#FFFFFF'] }, { colors: [d.selected] });
          return selectColorInterpolation(s.ganttConfig.colorSelectOpacity()).colors[0];
        }
        return 'white';
      });

    s.getAllShiftContents()
      .enter()
      .append('path')
      .attr('class', 'gantt_shift_contents')
      .attr('d', (d) => {
        const strokeWidth = s.getShiftCalculationStrategy().getShiftStrokeWidth(d);
        let x =
          s.getShiftCalculationStrategy().getShiftX(d, s.getLastZoomTransformation(), 0, self.maxShiftWidth) +
          strokeWidth;
        const shiftY = this._isOverlay
          ? s.getShiftCalculationStrategy().getShiftViewportY(d)
          : s.getShiftCalculationStrategy().getShiftY(d);
        const y = shiftY + s.getShiftCalculationStrategy().getShiftLabelHeight(d) + strokeWidth / 2;
        let width =
          s.getShiftCalculationStrategy().getShiftWidth(d, s.getLastZoomTransformation(), 0, self.maxShiftWidth) -
          2 * strokeWidth;
        if (width < 0) {
          width = 0;
        }
        const height =
          s.getShiftCalculationStrategy().getShiftHeight(d) -
          s.getShiftCalculationStrategy().getShiftLabelHeight(d) -
          1.5 * strokeWidth;
        if (height < 3) {
          return null;
        } // build white area only if it is large enough
        const round = s.getShiftCalculationStrategy().getShiftCornerRadius(d);
        let path;

        /*
          After a certain zoom level shifts are wider than 20,000,000 pixels.
          That is too much for svg. This handles these cases by building them smaller again.
        */
        if (width > 20000000 && x < 0) x = -50;
        if (width > 20000000 && x > 0)
          x = Math.round(d.x * s.getLastZoomTransformation().k + s.getLastZoomTransformation().x);
        if (round && width - 2 * round > 0) {
          path = `
        M${x},${y}
        h${width}
        v${height - round}
        q0,${round} -${round},${round}
        h-${width - 2 * round < 0 ? width : width - 2 * round}
        q-${round},0 -${round},-${round}
        z
        `;
        } else {
          path = `
          M${x},${y}
          h${width}
          v${height}
          h-${width}
          z
          `;
        }
        return path;
      })
      .attr('fill', function (d) {
        if (d.selected) {
          const selectColorInterpolation = d3.interpolate({ colors: ['#FFFFFF'] }, { colors: [d.selected] });
          return selectColorInterpolation(s.ganttConfig.colorSelectOpacity()).colors[0];
        }
        return 'white';
      })
      .attr('clip-path', s.getClipPathHandler()?.clipPathUrl)
      .style('pointer-events', 'none');

    s.getAllShiftContents().exit().remove();
  }

  public removeAllShifts(executer: ShiftBuilder): void {
    const s = executer;
    const parent = this._getParentNode(executer);

    parent.selectAll('.gantt_shifts').remove();

    s.setAllShifts(undefined);

    parent.selectAll('.gantt_shift_contents').remove();

    parent.selectAll('.additionalShiftElements').remove();

    s.getShiftWrapperOverlay().selectAll('.gantt_highlight_on_hover').remove();

    s.setAllShiftContents(undefined);
  }

  /**
   * @override
   */
  public initCallbacks(executer: ShiftBuilder): void {}

  /**
   * @override
   */
  public removeCallbacks(executer: ShiftBuilder): void {}

  /**
   * @override
   */
  public preloadPatterns(executer: ShiftBuilder, originDataSet: GanttDataRow[]): void {}

  /**
   * @override
   */
  public clearTimeouts(): void {}

  public resizeChange(executer: ShiftBuilder, boolean: boolean): void {}

  //
  // GETTER & SETTER
  //

  private _getParentNode(
    executer: ShiftBuilder,
    customParentNode: d3.Selection<SVGGElement, undefined, d3.BaseType, undefined> = undefined
  ): d3.Selection<SVGGElement, undefined, d3.BaseType, undefined> {
    return customParentNode || this._parentNode || executer.getShiftGroupOverlay();
  }
}
