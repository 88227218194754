import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  ComponentRef,
  DestroyRef,
  ElementRef,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
  inject,
} from '@angular/core';
import { ButtonService } from '@app-modeleditor/components/button/button.service';
import { TemplateActionService } from '@app-modeleditor/components/button/template-action.service';
import { EMenuMode } from '@app-modeleditor/components/content/content-element/menu-mode.enum';
import { LightboxService } from '@app-modeleditor/components/lightbox/lightbox.service';
import { TemplateComponent } from '@app-modeleditor/components/template/template.component';
import { GridLayout } from '@app-modeleditor/components/widget-modules/grid-layout/grid-layout';
import { WidgetGridLayoutService } from '@app-modeleditor/components/widget-modules/grid-layout/widget-grid-layout.service';
import { TemplateAdapter } from '@app-modeleditor/utils/template-factory.service';
import { Registered, TemplateService } from '@app-modeleditor/utils/template.service';
import { SaxMsSubmenuFavoriteTabService } from '@app-modules/saxms-submenu-elements/saxms-submenu-favorite-tab.service';
import { SaxMsSubmenuService } from '@app-modules/saxms-submenu-elements/saxms-submenu.service';
import { ConfigService } from '@core/config/config.service';
import { Notification } from '@core/notification/notification';
import { TranslateService } from '@ngx-translate/core';
import { SelectionFavoriteService } from 'frontend/src/dashboard/gantt/general/selection-favorite-service.service';
import { GanttAdapter } from 'frontend/src/dashboard/gantt/helper/gantt-adapter.service';
import { Template } from 'frontend/src/dashboard/model/resource/template';
import { EResizeType } from 'frontend/src/dashboard/view/resize/resize-type.enum';
import { ResizeService } from 'frontend/src/dashboard/view/resize/resize.service';
import { Observable, Subject, Subscription, concat, of } from 'rxjs';
import { delay, filter, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { OverlayService } from '../../modeleditor/components/lightbox/overlay/overlay.service';
import { PrintLightbox } from '../../modeleditor/components/print/print-lightbox';
import { DockWindowService } from '../gantt/dock/dock-window/dock-window.service';
import { EDockComponent, EDockViewMode } from '../gantt/dock/dock.enum';
import { IGanttDockComponent } from '../gantt/dock/dock.interface';
import { GanttDockService } from '../gantt/dock/gantt-dock.service';
import { LegendCommunicationService } from '../gantt/dock/views/legend/legend-communication.service';
import { LegendFilterService } from '../gantt/dock/views/legend/legend-filter.service';
import { ResourceCommunicationService } from '../gantt/dock/views/resources/resource-communication.service';
import { ResourcesDataService } from '../gantt/dock/views/resources/resources-data.service';
import { GanttDataStorageService } from '../gantt/gantt-data/data-storage.service';
import { GanttLibService } from '../gantt/gantt-lib.service';
import { SaxMsBestGanttCustomPlugIn } from '../gantt/plugin/saxms-best-gantt.plugin';
import { SaxMsBestGanttToolbarHandler } from '../gantt/saxms-best-gantt-submenu-handler';
import { GenericGanttAction, SaxMsBestGanttComponent } from '../gantt/saxms-best-gantt.handler.component';
import { SaxMsBestGanttSettings } from '../gantt/saxms-best-gantt.settings';
import { GanttTemplateData } from '../helper/gantt';
import { GeneralGanttActionHandler } from './action-handling/action-handler';
import { GanttConfigHandling } from './config-handling/gantt-config-handling';
import { GanttActionService } from './gantt-action.service';
import { GanttEditService } from './gantt-edit/gantt-edit.service';
import { EGanttLoadingState } from './gantt-loading-state.enum';
import { GanttSettingsDialog } from './gantt-settings/gantt-settings-dialog/gantt-settings-dialog';
import { IExitSettingsEvent } from './gantt-settings/gantt-settings-dialog/gantt-settings.interface';
import { EGanttLightboxExitType } from './gantt-settings/gantt-settings-dialog/gantt-style.enum';
import { GanttSettingsService } from './gantt-settings/service/gantt-settings.service';
import { PluginSettingsIntegratorService } from './gantt-settings/service/plugin-settings-integrator.service';
import { GanttTooltipService } from './gantt-tooltips/gantt-tooltip.service';
import { GanttDataStorage } from './generator/gantt-data-storage';
import { GanttMappedTemplateData, GanttTemplateGenerator } from './generator/gantt-generator';
import { IGanttResourcePlan } from './generator/gantt-input.data';
import { GanttMarkedHolidays } from './generator/predefined/holidays/holidays';
import { LoadingStateService } from './loading-state.service';
import { GanttEssentialPlugIns } from './plugin/e-gantt-essential-plugins';
import { GanttPluginHandlerService } from './plugin/gantt-plugin-handler.service';
import { GanttOverlappingShiftsPlugIn } from './plugin/plugin-list/overlapping-shifts/overlapping-shifts';
import { GeneralGanttPrintHandler } from './print/print-handler';
import { PrintLightboxStrategyGanttLW } from './print/print-lightbox-strategies/print-lightbox-strategy-gantt-lw';
import { IGanttResponse } from './response/gantt-response';
import { GeneralGanttSaveHandler } from './save-handler/save-handler';
import { GanttTemplateDataService } from './template-data/gantt-template-data.service';
import { GanttFrontendFilterAdapter } from './toolbar/filter/lightbox/filter.service';
import { GanttToolbar } from './toolbar/gantt-toolbar';
import { VisibilityStateService } from './visibility-state.sevice';

@Component({
  selector: 'gantt-general',
  templateUrl: './general.gantt.html',
  styleUrls: ['./general.gantt.style.scss'],
  providers: [
    SaxMsSubmenuFavoriteTabService,
    GanttActionService,
    LoadingStateService,
    GanttFrontendFilterAdapter,
    PluginSettingsIntegratorService,
    SaxMsSubmenuService,
    GanttTemplateDataService,
    GanttPluginHandlerService,
    ResourceCommunicationService,
    ResourcesDataService,
    GanttTooltipService,
    GanttSettingsService,
    GanttLibService,
    GanttDataStorageService,
    LegendFilterService,
    LegendCommunicationService,
    { provide: 'InternalOverlayService', useClass: OverlayService },
    GanttEditService,
    GanttDockService,
    DockWindowService,
    VisibilityStateService,
  ],
})

/**
 * Component which is the interface between the saxmsbest gantt interface and generalized gantt backend data.
 */
export class Gantt_General extends Template implements OnInit, OnDestroy, OnChanges {
  @Input() contentId: string;
  /**
   * Insert gantt parent node.
   * @param rootNode Reference to parent node.
   */

  rootNode: HTMLElement;
  @Input('root') set updateRoot(rootNode: HTMLElement) {
    this._resizeApi.complete(this.resizeIds[2]);
    this.rootNode = rootNode;
    if (!this.rootNode) {
      return;
    }
    this.resizeIds[2] = this._resizeApi.create(this.rootNode, this._recalcContainerProportions.bind(this), {
      types: [EResizeType.HEIGHT],
    });
  }

  afterInitSlot(instance: ComponentRef<TemplateComponent>): void {
    this.toolbar.setReferenceContainer(this.ganttWrapper);
    instance.instance.templateNode = this.toolbar;
  }

  /**
   * Insert gantt template data for generalized gantt from backend.
   * @param ganttTemplateData Backend data.
   */
  @Input() private set data(template: GanttTemplateData) {
    this.ganttTemplateDataService.setTemplateData(template);
    this.ganttSettingsService.setGanttID(this.ganttTemplateDataService.getTemplateData().getId());
  }
  @Input() eventList: Notification[];
  private ganttWrapper: ElementRef;
  private toolbarContainer: ElementRef;
  private resizeIds: string[] = [null, null, null];
  @ViewChild('ganttWrapper') set c1(ganttWrapper: ElementRef) {
    this.ganttWrapper = ganttWrapper;
    this._resizeApi.complete(this.resizeIds[0]);
    if (!ganttWrapper) {
      return;
    }
    this.resizeIds[0] = this._resizeApi.create(ganttWrapper.nativeElement, this._recalcContainerProportions.bind(this));
  }
  @ViewChild('toolbarContainer') set c2(toolbarContainer: ElementRef) {
    this._resizeApi.complete(this.resizeIds[1]);
    this.toolbarContainer = toolbarContainer;
    if (!toolbarContainer) {
      return;
    }
    this.resizeIds[1] = this._resizeApi.create(
      toolbarContainer.nativeElement,
      this._recalcContainerProportions.bind(this)
    );
  }
  @ViewChild(SaxMsBestGanttComponent)
  saxmsBestGanttComponent: SaxMsBestGanttComponent;

  public toolbar: GanttToolbar;
  public dataStorage: GanttDataStorage = new GanttDataStorage();
  public loadingSpinner: GanttLoadingSpinner = new GanttLoadingSpinner();
  public plugInInput: SaxMsBestGanttCustomPlugIn[] = [];
  public genericActions: GenericGanttAction[] = [];
  public actionHandler: GeneralGanttActionHandler;
  public rootWidth: string;
  public rootHeight: string;
  public generalGanttSaveHandler: GeneralGanttSaveHandler;
  public printHandler: GeneralGanttPrintHandler;
  public ganttResultData: GanttMappedTemplateData;
  public debuggable: Date;
  public containerHeight = '';
  public gridLayout: GridLayout;
  public isReady: boolean;
  public readonly destroyRef = inject(DestroyRef);

  private _loadingState: EGanttLoadingState;
  private _ganttGenerator: GanttTemplateGenerator;
  private _listenToFavorites = false;
  private _blockSaveOfFavoriteTab = true;
  private _settingsLoaded = false;
  private _loaded = false;
  private _initFavoriteMenu = true;
  private _afterGanttEmit: Subject<Gantt_General> = new Subject<Gantt_General>();
  private _ngUnsubscribe: Subject<void> = new Subject<void>();
  private _onDestroySubject = new Subject<void>();
  private _gridLayoutSize = 0;
  private _ganttConfigHandling: GanttConfigHandling;
  private _containerWidth: number;
  private _legendFilterChangeSubscription: Subscription = null;
  private readonly ganttLegendId = 'ganttLegend';
  private readonly ganttResourcesId = 'ganttResources';

  constructor(
    public ganttAdapter: GanttAdapter,
    public ganttFrontendFilterService: GanttFrontendFilterAdapter,
    public lightboxApi: LightboxService,
    public templatefactory: TemplateAdapter,
    public configApi: ConfigService,
    public translate: TranslateService,
    public submenuService: SaxMsSubmenuService,
    public ganttLibService: GanttLibService,
    public ganttSettingsService: GanttSettingsService,
    public ganttPluginHandlerService: GanttPluginHandlerService,
    public buttonService: ButtonService,
    public ganttTemplateDataService: GanttTemplateDataService,
    public datePipe: DatePipe,
    public ganttResourcesDataService: ResourcesDataService,
    public ganttTooltipService: GanttTooltipService,
    public zone: NgZone,
    public templateActionService: TemplateActionService,
    private _cd: ChangeDetectorRef,
    private _contextMenuService: GanttActionService,
    private _submenuFavoriteTabService: SaxMsSubmenuFavoriteTabService,
    private _selectionFavoriteService: SelectionFavoriteService,
    private _widgetGridLayoutService: WidgetGridLayoutService,
    private _templateApi: TemplateService,
    private _pluginSettingsIntegratorService: PluginSettingsIntegratorService,
    private _legendCommunicationService: LegendCommunicationService,
    private _resizeApi: ResizeService,
    private _ganttDockService: GanttDockService,
    private loadingStateService: LoadingStateService,
    private http: HttpClient,
    private ganttEditService: GanttEditService,
    private _dockWindowService: DockWindowService,
    protected visibilityStateService: VisibilityStateService
  ) {
    super();
    this.loadingStateService
      .afterExpectedChanged()
      .pipe(takeUntil(this.onDestroy))
      .subscribe((count: Record<string, number>) => {
        this.expected = count;
        this.updateCount();
      });
    this.loadingStateService
      .afterReceivedChanged()
      .pipe(takeUntil(this.onDestroy))
      .subscribe((count: Record<string, number>) => {
        this.received = count;
        this.updateCount();
      });

    this._ganttGenerator = new GanttTemplateGenerator();
    this.genericActions = this._createGanttActions();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.eventList) {
      this._validateNotification();
    }

    if (changes.ganttTemplateData) {
      this._initData();
    }

    if (changes.root && this.rootNode) {
      this._resizeApi.complete(this.resizeIds[2]);
      if (!this.rootNode) {
        return;
      }
      this.resizeIds[2] = this._resizeApi.create(this.rootNode, this._recalcContainerProportions.bind(this), {
        types: [EResizeType.HEIGHT],
      });
    }
  }

  ngOnInit(): void {
    this.setLoaded(false);
    this._subscribeToGridLayoutAlignment();
    this._subcribeToLegendFilterChange();
    this.subscribeToGanttEditMode();
    // this._loadingState = EGanttLoadingState.SCRIPTS;

    of(true)
      .pipe(
        takeUntil(this.onDestroy),
        filter((ready: boolean) => ready === true),
        take(1),
        switchMap((_) => this.fetchSettings()),
        switchMap((_) => this._initData())
      )
      .subscribe((ready: boolean) => {
        this.isReady = ready;
        this.setLoaded(ready);
      });

    this.handleEditMode();
  }

  ngOnDestroy(): void {
    // unregister ids from resize
    this._resizeApi.complete(...this.resizeIds);

    if (this.actionHandler && this.ganttLibService.bestGantt) {
      this.actionHandler.onDestroy();
      this.ganttResourcesDataService.destroy();
      this.ganttLibService.destroy();
    }
    this._ngUnsubscribe.next();
    this._ngUnsubscribe.complete();
    this._afterGanttEmit.complete();

    this._onDestroySubject.next();
    this._onDestroySubject.complete();
    this._settingsLoaded = false;
    this._legendFilterChangeSubscription.unsubscribe();
    this._ganttDockService.resetGanttDock();
    this.submenuService.reset();
  }

  public onGanttClicked(event: MouseEvent): void {
    this._contextMenuService.closeCtx();
  }

  active: boolean;
  public resize(): void {
    if (!this.rootNode) {
      return;
    }

    this.active = true;
    const newRootWidth = (this.rootNode as any).clientWidth + 'px';
    const newRootHeight = (this.rootNode as any).clientHeight - 6 + 'px';

    // propagate resize to gantt
    this.rootWidth = newRootWidth;
    this.rootHeight = newRootHeight;

    this.active = false;
    this._cd.detectChanges();
  }

  public handleMenumode(visible: boolean): void {
    this.ganttTemplateDataService.getTemplateData().setMenuMode(visible ? EMenuMode.SHOW : EMenuMode.HIDE);
  }

  /**
   * Reloads whole gantt template.
   */
  public refreshGantt(): void {
    this._templateApi.updateElements([this.ganttTemplateDataService.getTemplateData().getId()]);
  }

  /**
   * Print gantt template by rebuild gantt with print style and menu.
   */
  public printGantt(): void {
    const printLightBoxStrategy = new PrintLightboxStrategyGanttLW(this, this.ganttLibService.bestGantt);
    const printLightbox: PrintLightbox = new PrintLightbox(printLightBoxStrategy);
    this.lightboxApi.open(printLightbox);

    const g: GanttTemplateData = this.templatefactory
      .adapt(this.ganttTemplateDataService.getTemplateData())
      .applyObject(this.ganttTemplateDataService.getTemplateData()) as GanttTemplateData;
    g.setRegisterable(false);

    printLightbox.getPrinter().setPrintableElement(g);
    g.setAfterInit((g, scope: Gantt_General) => {
      printLightBoxStrategy.setPrintGanttScope(scope);
      printLightBoxStrategy.setPrintGanttRef(scope.saxmsBestGanttComponent.ganttLib.instance);
      printLightbox.setPrintRef(scope.ganttWrapper);
      // apply shift colorization on print preview
      const colorizeByAttributePlugIn = this.ganttPluginHandlerService.getEssentialPlugIn(
        GanttEssentialPlugIns.BlockColorizeByAttributePlugIn
      );

      const blockingIntervalsPlugin = this.ganttPluginHandlerService.getEssentialPlugIn(
        GanttEssentialPlugIns.BlockingIntervalPlugIn
      );

      printLightBoxStrategy.applyFilteredIntervals(blockingIntervalsPlugin?.getRenderMap());

      if (colorizeByAttributePlugIn.isGanttPlugInActive()) {
        printLightBoxStrategy.applyColorByAttribute(
          colorizeByAttributePlugIn.getLastColorizeParams(),
          colorizeByAttributePlugIn.getLastColorizeStrategy()
        );
      }
    });
    g.setPrint(true);

    // If the print dialog is cancelled, the browser will not recognize a visibility statechange
    // -> so check visibility state after print
    printLightbox.afterPrint(() => this.visibilityStateService.checkVisibility());
  }

  public isLoaded(): boolean {
    return this._loaded;
  }

  public setLoaded(loaded): void {
    this._loaded = loaded;
  }

  /**
   * Inserts toolbarHandler from saxms best gantt to provide access to BestGantt-instance and submenu handling.
   * Will be called from template if gantt is ready for custom plugins and custom menus.
   * @param toolbar Toolbarhandler from created BestGantt.
   */
  public receiveToolbar(toolbar: SaxMsBestGanttToolbarHandler): void {
    this._loadingState = EGanttLoadingState.TOOLBAR;
    // init plugin handler
    this.actionHandler = new GeneralGanttActionHandler(
      this.buttonService,
      this.lightboxApi,
      this.translate,
      this.loadingSpinner,
      this.ganttPluginHandlerService,
      this.ganttEditService
    );
    this.ganttPluginHandlerService.init(toolbar, this.actionHandler, this.ganttResultData.response);

    this._ganttConfigHandling = new GanttConfigHandling(this.ganttLibService);
    this._ganttConfigHandling.addAllAttributes(this.ganttTemplateDataService.getTemplateData());

    this._legendCommunicationService.initData(this._ganttGenerator.initialLegendData);
    this._blockSaveOfFavoriteTab = false;
    this._afterGanttEmit.next(this);
    if (this.ganttTemplateDataService.getTemplateData().isPrint()) {
      this._activatePrintMode();
    }

    this.splitShifts(false);

    of(null)
      .pipe(delay(0))
      .subscribe(() => {
        this._buildToolbar();
        this._changeSizeOfYAxis();

        const gantt = this.ganttLibService.bestGantt;
        this._initWidgetGridLayoutService();
        this._integrateSettings();
        this._subscribeGanttSettingsCallbacks();
        // check queue
        this._validateNotification();
        if (this.ganttTemplateDataService.getTemplateData().callAfterInit) {
          this.ganttTemplateDataService.getTemplateData().callAfterInit(gantt, this);
        }
      });
  }

  /**
   * Initializes values and callbacks related to WidgetGridLayoutService.
   */
  private _initWidgetGridLayoutService(): void {
    const bestGantt = this.ganttLibService.bestGantt;

    // update grid start when y axis width changes
    bestGantt.afterYAxisResizeEnd.pipe(takeUntil(this.onDestroy)).subscribe((yAxisWidth) => {
      this._widgetGridLayoutService.setGridStart(this.contentId, yAxisWidth);
    });
    // update right padding when scrollbar width changes
    this._widgetGridLayoutService.paddingRight = bestGantt.getConfig().verticalScrollbarWidth;
    bestGantt
      .getConfig()
      .onVerticalScrollbarSettingsChanged()
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        const currentScrollbarPadding = this._widgetGridLayoutService.paddingRight;
        const newScrollbarPadding = bestGantt.getConfig().verticalScrollbarWidth;
        if (currentScrollbarPadding !== newScrollbarPadding) {
          this._widgetGridLayoutService.paddingRight = newScrollbarPadding;
        }
      });
  }

  public getLegendCommunicationService(): LegendCommunicationService {
    return this._legendCommunicationService;
  }

  public getGanttDockService(): GanttDockService {
    return this._ganttDockService;
  }

  public getLegendDockWrapper(): ElementRef {
    const legendViewMode = this._ganttDockService.getViewTypeByComponentId(this.ganttLegendId);
    switch (legendViewMode) {
      case EDockViewMode.BOTTOM:
        return this.saxmsBestGanttComponent.dockBottom.dockWrapper;
      case EDockViewMode.RIGHT:
        return this.saxmsBestGanttComponent.dockRight.dockWrapper;
      case EDockViewMode.UNDOCK:
        return this._dockWindowService.dockWindow.dockWrapper;
    }
  }

  public showNewGanttSettingsDialog(): void {
    const ganttSettingsLightbox = new GanttSettingsDialog(
      JSON.parse(JSON.stringify(this.ganttSettingsService.getGanttSettings())),
      this.lightboxApi,
      this.ganttSettingsService,
      this.configApi,
      this.ganttTemplateDataService
    );
    this.lightboxApi
      .open(ganttSettingsLightbox)
      .afterClosed()
      .pipe(take(1))
      .subscribe((event: IExitSettingsEvent) => {
        if (!event || event.type === EGanttLightboxExitType.DISCARD) {
          return;
        } else if (event?.type === EGanttLightboxExitType.RESET) {
          this.changeGanttSettings(new SaxMsBestGanttSettings());

          // fetch available federal states again
          this.ganttPluginHandlerService
            .getPredefinedSettings()
            .getInstance<GanttMarkedHolidays>(GanttMarkedHolidays)
            .fetchAvailableFederalStates(this.ganttTemplateDataService.getTemplateData());
          return;
        } else if (event?.type === EGanttLightboxExitType.SAVE) {
          this.changeGanttSettings(event.settings);
          return;
        }
      });
  }

  private changeGanttSettings(settings: SaxMsBestGanttSettings): void {
    this.ganttSettingsService.setGanttSettings(JSON.parse(JSON.stringify(settings)));
    this.ganttSettingsService.changeSettings(this.ganttSettingsService.getGanttSettings());
    this._pluginSettingsIntegratorService.integratePluginSettings(settings);
    this.ganttSettingsService.saveSettings().subscribe((data) => {
      this._pluginSettingsIntegratorService.integratePluginSettings(settings);
    });
  }

  public getLoadingString(): string {
    let loadingString =
      this.ganttTemplateDataService.getTemplateData()?.getName() ||
      this.ganttTemplateDataService.getTemplateData()?.getHeadline() ||
      'Plantafel';

    switch (this._loadingState) {
      case EGanttLoadingState.SCRIPTS:
        return (loadingString += ': Ressourcen werden geladen ...');
      case EGanttLoadingState.TOOLBAR:
        return (loadingString += ': Werkzeugleiste wird geladen ...');
      case EGanttLoadingState.SETTINGS:
        return (loadingString += ': Einstellungen werden geladen ...');
      default:
        return loadingString;
    }
  }

  private handleEditMode(): void {
    this._templateApi
      .afterRegisteredChanges()
      .pipe(takeUntil(this.onDestroy))
      .subscribe((registered: Record<string, Registered>) => {
        if (registered[this.ganttTemplateDataService.getTemplateData()?.getGanttUniqueId()]) {
          const isEditMode =
            registered[this.ganttTemplateDataService.getTemplateData()?.getGanttUniqueId()]?.editable ?? true;
          this.ganttEditService.setEditMode(isEditMode);
        }
      });
  }

  private _buildToolbar() {
    this.ganttPluginHandlerService.addCustomListener(this._ngUnsubscribe);
    if (this.ganttTemplateDataService.getTemplateData().getMenuMode() === EMenuMode.NONE) {
      return;
    }
    const showMenuToggleBtn = this.configApi.access().templates.Gantt.toolbarButtons?.menuToggle ?? true;
    this.toolbar = new GanttToolbar({ showMenuToggleBtn: showMenuToggleBtn }, this);
    this.toolbar.setMenuMode(this.ganttTemplateDataService.getTemplateData().getMenuMode());
    this.actionHandler.newToolbar = this.toolbar;
  }

  private _recalcContainerProportions(): void {
    const newWidth = this.ganttWrapper?.nativeElement.clientWidth || 0;
    const newHeight = `calc(100% - ${this.toolbarContainer?.nativeElement.clientHeight || 0}px)`;
    const newRootHeight = this.rootNode?.clientHeight - 6 + 'px';

    if (newHeight != this.containerHeight || newWidth != this._containerWidth || this.rootHeight !== newRootHeight) {
      this.containerHeight = newHeight;
      this._containerWidth = newWidth;
      this.resize();
    }
  }

  private subscribeToGanttEditMode() {
    this.ganttEditService
      .onEditModeChange()
      .pipe(takeUntil(this.onDestroy))
      .subscribe((isEditable: boolean) => {
        if (!isEditable) {
          // deselect selected blocks
          this.ganttLibService.bestGantt.deselectAllShifts();
          this.ganttTemplateDataService
            .getTemplateData()
            .setSelectedBlock({}, this.ganttLibService.backendToGanttOriginInputMapper);
          this.ganttTemplateDataService
            .getTemplateData()
            .setSelectedValues(false, this.ganttLibService.backendToGanttOriginInputMapper);
        }
      });
  }

  private fetchSettings(): Observable<boolean> {
    this._loadingState = EGanttLoadingState.SETTINGS;
    return this.ganttSettingsService.getSettingsFromBackend().pipe(
      takeUntil(this._ngUnsubscribe),
      tap((settings: SaxMsBestGanttSettings) => {
        if (settings) {
          this.ganttSettingsService.setGanttSettings(
            JSON.parse(
              JSON.stringify(
                this.ganttSettingsService.combineSettings(this.ganttSettingsService.getGanttSettings(), settings)
              )
            )
          );
        }
      }),
      switchMap((_) => of(true))
    );
  }

  /**
   * Requests gantt settings from backend and trys to include them into gantt.
   * This includes also the favorite menu elements (hopefully not for the next submenu version).
   */
  private _integrateSettings(): void {
    if (!this.actionHandler) {
      return;
    }

    try {
      // activate favorite menu listeing after call settings the first time
      if (!this._listenToFavorites) {
        this._listenToSubmenuFavoriteHandling();
        this._listenToFavorites = true;
      }
      const isPrintPreview = this.printHandler && this.printHandler.isActive;

      if (!isPrintPreview) {
        this.ganttSettingsService.activeSubMenuElementsBySettings(this.submenuService, this.ganttPluginHandlerService);
        this._selectionFavoriteService.setFavoriteSelectionsMenuItem(
          this.ganttSettingsService.getGanttSettings().dropDownFavorites
        );
        this._settingsLoaded = true;
        if (this.toolbar) {
          const favoriteItems = this.ganttSettingsService.getGanttSettings().favoriteMenuElements.split(';');
          this.toolbar.handleFavoriteMenu(favoriteItems);
        }
      }
      this._pluginSettingsIntegratorService.integratePluginSettings(this.ganttSettingsService.getGanttSettings());
      if (!isPrintPreview) {
        this._ganttDockService.addComponentToDockByView(
          this._ganttDockService.getDockComponentById(this.ganttLegendId),
          this.ganttSettingsService.getGanttSettings().legendPosition || EDockViewMode.BOTTOM
        );
      }
    } catch (e) {
      console.warn('Problems during parsing gantt settings: %s', e);
    }
  }

  /**
   * Sets unique gantt id which will be used for response handling for push notification etc...
   * Should be set once.
   * @param uniqueId Unique id of gantt chart.
   */
  private _setGanttUniqueId(uniqueId: string): void {
    if (this.ganttTemplateDataService.getTemplateData().getGanttUniqueId() != null) {
      console.warn('Gantt unique id will be overwritten!');
    }
    this.ganttTemplateDataService.getTemplateData().setGanttUniqueId(uniqueId);
    this.ganttTemplateDataService.getTemplateData().setResourceId(uniqueId);
  }

  /**
   * Adds timeperiod of gantt to template to provide it for the backend.
   * @param startTime Start of gantt time period.
   * @param endTime End of gantt time period.
   */
  private _setTemplateGanttTimes(startTime: number, endTime: number): void {
    this.ganttTemplateDataService.getTemplateData().setGanttStartTime(new Date(startTime).getTime());
    this.ganttTemplateDataService.getTemplateData().setGanttEndTime(new Date(endTime).getTime());
  }

  /**
   * Subscribes in the JS Gantt on yAxis Width Change.
   * Updates GanttSettings than and send new data to backend.
   */
  private _subscribeGanttSettingsCallbacks(): void {
    this.ganttLibService.bestGantt.afterYAxisResizeEnd.pipe(takeUntil(this.onDestroy)).subscribe((width) => {
      this.ganttSettingsService.changeSettings({ ganttEntryWidth: width });
      this.ganttSettingsService.saveSettings().subscribe();
    });
  }

  /**
   * Handles integration of new favorite submenu elements to favorite tab of submenu.
   */
  private _listenToSubmenuFavoriteHandling(): void {
    console.warn('Warning deprecated method called!');
    this._submenuFavoriteTabService
      .onFavoriteSubmenuElementsChange()
      .pipe(takeUntil(this.onDestroy))
      .subscribe((favoriteTabElements) => {
        if (this._blockSaveOfFavoriteTab || !this._settingsLoaded || this._initFavoriteMenu) {
          return;
        }
        if (favoriteTabElements && favoriteTabElements.length > 0 && !this._blockSaveOfFavoriteTab) {
          let favoriteMenuElementString = favoriteTabElements[0].data.id;
          favoriteTabElements.forEach((favoriteTabElement, index) => {
            if (index > 0) {
              favoriteMenuElementString += ';' + favoriteTabElement.data.id;
            }
          });
          this.ganttSettingsService.changeSettings({
            favoriteMenuElements: favoriteMenuElementString,
          });
        } else {
          this.ganttSettingsService.changeSettings({
            favoriteMenuElements: '',
          });
        }
        if (this._settingsLoaded) {
          this.ganttSettingsService.saveSettings().subscribe();
        }
      });

    this._selectionFavoriteService
      .getFavoriteSelectionsMenuItemById()
      .pipe(takeUntil(this.onDestroy))
      .subscribe((favorites) => {
        if (!this._settingsLoaded) {
          return;
        }

        if (!favorites) {
          this.ganttSettingsService.changeSettings({ dropDownFavorites: [] });
        } else {
          this.ganttSettingsService.changeSettings({
            dropDownFavorites: favorites,
          });
        }
        if (this._settingsLoaded) {
          this.ganttSettingsService.saveSettings().subscribe();
        }
      });
  }

  /**
   * Creates generic buttons for template driven gantt.
   * @returns
   */
  private _createGanttActions(): GenericGanttAction[] {
    const a = [];
    if (this.configApi.access().templates.Gantt.print) {
      const printAction: GenericGanttAction = new GenericGanttAction()
        .setId('print')
        .setLabel('print')
        .setIcon('print')
        .setAction(this.printGantt.bind(this));
      a.push(printAction);
    }
    return a;
  }

  private _validateNotification(): void {
    if (
      !this.ganttTemplateDataService?.getTemplateData() ||
      !this.eventList ||
      this.eventList?.length === 0 ||
      !this.ganttPluginHandlerService.getResponseHandler()
    ) {
      return;
    }

    while (this.eventList?.length > 0) {
      const event: Notification = this.eventList.shift();
      const eventResource = event.getBelongsToResource() as any;
      const ganttId: string = eventResource?.ganttId;

      if (ganttId != this.ganttTemplateDataService.getTemplateData().getId()) {
        console.info(`Response not for this gantt!`, ganttId, event);
        continue;
      }

      if (event?.getBelongsToResource()?.isMonthlyDataResponse()) {
        this.loadingStateService.setReceived('shifts', 1);
        this.updateCount();
      }

      this.ganttPluginHandlerService.getResponseHandler().handleResponse(event, false, true);
    }
  }

  /**
   * merge two objects into each other
   * @param a current combined object scope
   * @param b new object which should be merged
   * @returns any
   */
  private _combineObjects(a: any, b: any): any {
    if (!b) {
      return a;
    }

    const curObj = a || {};
    Object.keys(b).forEach((key: string) => {
      // if new objects key is not set
      if (!b[key]) {
        // if current object key is not set
      } else if (!curObj[key]) {
        curObj[key] = b[key];
        // if new object key is an array
      } else if (Array.isArray(curObj[key])) {
        curObj[key] = curObj[key].concat(b[key]);
        // if new object key is an object
      } else if (typeof b[key] === 'object') {
        curObj[key] = this._combineObjects(curObj[key], b[key]);
      }
    });
    return curObj;
  }

  /**
   * Updates width of y-axis to align with other components in dashboard.
   */
  private _subscribeToGridLayoutAlignment() {
    this._widgetGridLayoutService
      .getGridLayout()
      .pipe(takeUntil(this.onDestroy))
      .subscribe((layouts: GridLayout[]) => {
        const layout = layouts.find((layout) => layout.getContentId() == this.contentId);
        this.gridLayout = layout;
        if (layout && layout.getGridStart() && layout.getContentId() === this.contentId) {
          this._gridLayoutSize = layout.getGridStart();
          this._changeSizeOfYAxis();
        }
      });
  }

  private expected: Record<string, number>;
  private received: Record<string, number>;

  private updateCount(): void {
    if (!this.toolbar) {
      return;
    }

    let result = '';
    let unfullfilled = false;
    Object.keys(this.expected).forEach((key: string) => {
      result = `${result} ${this.translate.instant(`LABEL.${key}`)} (${this.received[key] || 0}/${this.expected[key]})`;
      if ((this.received[key] || 0) >= this.expected[key]) {
        return;
      }
      unfullfilled = true;
    });

    if (unfullfilled) {
      this.toolbar.setName(`${this.headline} - ${result}`);
    } else {
      this.toolbar.setName(`${this.headline} - Komplett`);
    }

    this._cd.markForCheck();
  }

  get headline(): string {
    if (this.ganttTemplateDataService.getTemplateData().getHeadline()) {
      return `${this.ganttTemplateDataService.getTemplateData().getHeadline()}`;
    }
    return '';
  }

  private fetchData(list): void {
    if (!list) {
      return;
    }
    concat(
      ...list.map((item) =>
        this.http.get<IGanttResponse>(`rest/${item}`).pipe(
          tap((r) => {
            this.loadingStateService.setReceived('shifts', 1);
            this.ganttPluginHandlerService.getResponseHandler().handleUpdateNotification(r, false);
          })
        )
      )
    ).subscribe();
  }

  private _initData(): Observable<boolean> {
    if (!this.ganttTemplateDataService.getTemplateData()) {
      return of(false);
    }

    return of(true).pipe(
      delay(0),
      tap(() => {
        // only for print view
        this._afterGanttEmit =
          this.ganttTemplateDataService.getTemplateData().afterGanttEmit || new Subject<Gantt_General>();
        this._ngUnsubscribe.next();
        // checks notification

        this.ganttTemplateDataService.getTemplateData().setComponentRef(this);
        this.generalGanttSaveHandler = new GeneralGanttSaveHandler(this.ganttTemplateDataService.getTemplateData());

        const mappedGantt = this._ganttGenerator.getGanttByTemplateData(
          this.ganttTemplateDataService.getTemplateData(),
          this.ganttLibService.backendToGanttOriginInputMapper
        );

        this.fetchData(mappedGantt.response.supplementGanttResponseUrls);

        const resourcePlanData: IGanttResourcePlan = mappedGantt.response.resourcePlan;

        this._initGanttDock(this.ganttTemplateDataService.getTemplateData(), resourcePlanData);

        this.loadingStateService.setExpected('shifts', mappedGantt.response.supplementGanttResponseUrls?.length || 0);

        this._setGanttUniqueId(mappedGantt.response.ganttUniqueId);

        if (mappedGantt?.response?.hierarchicalPlan) {
          const plan = mappedGantt.response.hierarchicalPlan;
          this._setTemplateGanttTimes(plan.startDate, plan.endDate);
        }

        this.ganttResultData = mappedGantt;
        this.dataStorage.ganttInputData = {
          editAllowSettings: mappedGantt.editAllowSettings,
          ganttOrigindata: mappedGantt.ganttData,
        };

        this.ganttFrontendFilterService.init(this.ganttTemplateDataService.getTemplateData().getId());
        this.ganttTooltipService.getBlockTooltipSettingsFromBackend();
      })
    );
  }

  private _changeSizeOfYAxis() {
    if (this._gridLayoutSize && this.actionHandler) {
      const gantt = this.ganttLibService.bestGantt;
      const settings = this.ganttSettingsService.getGanttSettings();
      if (settings.ganttEntryWidth === this._gridLayoutSize) {
        return;
      } // nothing has changed
      settings.ganttEntryWidth = this._gridLayoutSize;
      gantt.getHTMLStructureBuilder().getSizeHandler().changeDivSize(this._gridLayoutSize);
      gantt.update();
      gantt.updateYAxis();
    }
  }

  /**
   * Bundles print activation and necessary style changes.
   * @param scope Scope to reference to print gantt component.
   */
  private _activatePrintMode(): Gantt_General {
    this.ganttPluginHandlerService.getNotifiedIfInitialized().subscribe((isInitialized: boolean) => {
      if (!isInitialized) {
        return;
      }
      const plugIn = this.ganttPluginHandlerService.getEssentialPlugIn(GanttEssentialPlugIns.IndexCardBuilderPlugIn);
      plugIn.setShowTexturizer(false);
      this.printHandler = new GeneralGanttPrintHandler(this.ganttLibService);
      this.printHandler.prepareForPrint();
    });
    return this;
  }

  /**
   * Subscription to listen to legend filter change.
   */
  private _subcribeToLegendFilterChange() {
    this._legendFilterChangeSubscription = this._legendCommunicationService
      .listenForFilterChange()
      .subscribe((splitShifts: boolean) => {
        if (splitShifts === false) {
          return;
        }
        this.splitShifts(true);
      });
  }

  /**
   * Splits overlapping shifts in the Gantt chart.
   * @param resplit - Whether to re-split already split shifts.
   */
  private splitShifts(resplit: boolean) {
    const overlappingShiftsPlugIn: GanttOverlappingShiftsPlugIn = this.ganttPluginHandlerService.getEssentialPlugIn(
      GanttEssentialPlugIns.OverlappingShiftsPlugIn
    );
    if (!overlappingShiftsPlugIn) return;

    if (resplit) {
      overlappingShiftsPlugIn.resplitOverlappingShifts();
    } else {
      overlappingShiftsPlugIn.splitOverlappingShifts(true);
    }
  }

  private _initGanttDock(templateData: GanttTemplateData, resourcePlanData: IGanttResourcePlan) {
    // register dock components

    // legend
    this._initGanttLegend(templateData);

    // resources
    if (resourcePlanData) {
      const resourcesDockComponent: IGanttDockComponent = {
        id: this.ganttResourcesId,
        name: 'Ressourcen',
        type: EDockComponent.RESOURCES,
      };
      this.ganttResourcesDataService.setBackendData(resourcePlanData);
      this._ganttDockService.registerDockComponent(resourcesDockComponent, EDockViewMode.RIGHT);
    }
  }

  public restoreGanttLegend(): void {
    this._initGanttLegend(this.ganttTemplateDataService.getTemplateData());
  }

  private _initGanttLegend(templateData: GanttTemplateData): void {
    if (templateData.getGanttLegend()) {
      // gantt contains a legend
      const legendDockComponent: IGanttDockComponent = {
        id: this.ganttLegendId,
        name: 'Legende',
        type: EDockComponent.LEGEND,
      };
      this._ganttDockService.registerDockComponent(legendDockComponent, EDockViewMode.BOTTOM);
    }
  }

  /**
   * Observable which gets triggered when the component gets destroyed.
   */
  public get onDestroy(): Observable<void> {
    return this._onDestroySubject.asObservable();
  }
}

/**
 * Class to activate loading spinner inside gantt submenu.
 */
export class GanttLoadingSpinner {
  show = false;
}
