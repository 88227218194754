import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, map, startWith, tap } from 'rxjs/operators';

/**
 * Custom rx-pipe-operator, not changing pipe but providing a side effect.
 * @param cb Call back function invoked with true when Observable is initialized
 * and invoked with false when first response is going through pipe.
 */
export function awaitingFirstCb(cb: (isWaiting: boolean) => void) {
  return function <T>(request: Observable<T>) {
    const _request = new Subject<void>();
    _request
      .pipe(
        map(() => false),
        startWith(true),
        distinctUntilChanged()
      )
      .subscribe(cb);
    return request.pipe(tap({ next: () => _request.next(), complete: () => _request.complete() }));
  };
}
