import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgxJoditModule } from 'ngx-jodit';
import { RichTextEditorComponent } from './rich-text-editor.component';
@NgModule({
  imports: [CommonModule, FormsModule, NgxJoditModule],
  declarations: [RichTextEditorComponent],
  exports: [RichTextEditorComponent],
})
export class RichTextEditorModule {}
