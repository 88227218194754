import { takeUntil } from 'rxjs/operators';
import { BestGantt } from '../../main';
import { GanttShiftClickEventsHandler } from '../shift-click-events/shift-click-events-handler';

export class MainShiftClickEventCallbacks {
  private _callbackHandler: GanttShiftClickEventsHandler;

  constructor(private _ganttDiagram: BestGantt) {
    this._callbackHandler = new GanttShiftClickEventsHandler(this._ganttDiagram);
  }

  /**
   * Adds shift click callbacks.
   */
  public initCallbacks(): void {
    this._ganttDiagram
      .getShiftFacade()
      .shiftOnClickDirectly(0)
      .pipe(takeUntil(this._ganttDiagram.onDestroy))
      .subscribe((event) => this._callbackHandler.selectShiftByClick(event.event));
    this._ganttDiagram
      .getShiftFacade()
      .shiftOnClickDirectly(1)
      .pipe(takeUntil(this._ganttDiagram.onDestroy))
      .subscribe(() => this._ganttDiagram.getTooltipBuilder().removeAllTooltips());

    this._ganttDiagram
      .getShiftFacade()
      .shiftOnContextMenu()
      .pipe(takeUntil(this._ganttDiagram.onDestroy))
      .subscribe((event) => this._callbackHandler.selectShiftByContextMenu(event.event));
    this._ganttDiagram
      .getShiftFacade()
      .shiftOnContextMenu()
      .pipe(takeUntil(this._ganttDiagram.onDestroy))
      .subscribe(() => this._ganttDiagram.getTooltipBuilder().removeAllTooltips());
  }
}
