import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { ETemplateType } from 'frontend/src/dashboard/model/resource/template-type';
import { Observable, Subject } from 'rxjs';
import { Button } from '../button/button';
import { HierarchicalMenuItem } from '../template-ui/hierarchical-menu-item';

export class Stackable extends HierarchicalMenuItem {
  private stackables: Stackable[];
  private parent: Stackable;
  private actions: Button[];
  private dropAction: Subject<CdkDragDrop<Stackable>> = new Subject<CdkDragDrop<Stackable>>();
  private clickEvent: Subject<Stackable> = new Subject<Stackable>();

  public getParent(): Stackable {
    return this.parent;
  }

  public setParent(parent: Stackable): void {
    this.parent = parent;
  }

  public onClick(): Observable<Stackable> {
    return this.clickEvent.asObservable();
  }

  public click(event: Stackable): this {
    this.clickEvent.next(event);
    return this;
  }

  public onDrop(): Observable<CdkDragDrop<Stackable>> {
    return this.dropAction.asObservable();
  }

  public drop(event: CdkDragDrop<Stackable>): void {
    this.dropAction.next(event);
  }

  public getActions(): Button[] {
    return this.actions || [];
  }

  public setActions(actions: Button[]): this {
    this.actions = actions;
    return this;
  }

  public getConntection(): string[] {
    return [this.getId()].concat(
      ...this.getChildren().map((g: Stackable) => {
        return [].concat(g.getConntection());
      })
    );
  }

  public getChildren(): Stackable[] {
    return this.stackables || [];
  }

  public setChildren(groups: Stackable[]): this {
    this.stackables = groups;
    return this;
  }

  public addChildAtIndex(group: Stackable, index: number): this {
    if (!this.stackables) {
      this.stackables = [];
    }
    this.stackables.splice(index, 0, group);
    return this;
  }

  public addChildren(...g: Stackable[]): this {
    this.stackables = this.getChildren().concat(g);
    return this;
  }

  constructor() {
    super();
    this.setType(ETemplateType.STACKABLE).setDraggable(false);
  }
}
