import { Template } from 'frontend/src/dashboard/model/resource/template';
import { Layout } from '../structure/layout';
import { EDisplayOrientation } from './content-element/display-orientation.enum';

export class StructuralElement extends Template {
  private displayOrientation: EDisplayOrientation;
  private layout: Layout;
  private fullscreen: boolean;

  public isFullscreen(): boolean {
    return this.fullscreen;
  }

  public setFullscreen(fullscreen: boolean): this {
    this.fullscreen = fullscreen;
    return this;
  }

  public getLayout(): Layout {
    return this.layout;
  }

  public setLayout(layout: Layout): this {
    this.layout = layout;
    return this;
  }

  /**
   * get current display orientation of template
   * @returns EDisplayOrientation
   */
  public getDisplayOrientation(): EDisplayOrientation {
    return this.displayOrientation;
  }

  /**
   * set display orientation of template
   * @param displayOrientation EDisplayOrientation
   * @return this
   */
  public setDisplayOrientation(displayOrientation: EDisplayOrientation): this {
    this.displayOrientation = displayOrientation;
    return this;
  }
}
