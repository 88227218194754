import { ComponentEvent } from '../../../history/component-event';

/**
 * @constructor
 * @class
 * @extends ComponentEvent
 * @requires ComponentEvent
 * @plugin timepoint-marker
 * */
export class IndexCardEvent extends ComponentEvent {
  constructor() {
    super();
    /**
     * @type {IndexCardExecuter}
     */
    this.ref = null;
  }

  /**
   * @override
   * @returns {boolean} true if undo was succesfull, else false
   */
  undo = function () {
    const s = this;

    function handle(type) {
      const types = {
        showIndexCards: function () {
          return s._undoShowIndexCards();
        },
        hideIndexCards: function () {
          return s._undoHideIndexCards();
        },
        default: function () {
          console.warn('can not undo: ' + type + '. May not be implemented yet.');
          return false;
        },
      };
      return (types[type] || types['default'])();
    }
    return handle(s.eventName);
  };

  /**
   * @override
   * @returns {boolean} true if redo was succesfull, else false
   */
  redo = function () {
    const s = this;
    function handle(type) {
      const types = {
        showIndexCards: function () {
          return s._redoShowIndexCards();
        },
        hideIndexCards: function () {
          return s._redoHideIndexCards();
        },
        default: function () {
          console.warn('can not redo: ' + type + '. May not be implemented yet.');
          return false;
        },
      };
      return (types[type] || types['default'])();
    }
    return handle(s.eventName);
  };

  /**
   * @overrides
   * @return {String}
   */
  getEventDescription = function () {
    const s = this;
    function handle(type) {
      const types = {
        showIndexCards: function () {
          return IndexCardEventDescription.getShowIndexCardDescription(
            s.ref.ganttDiagram.getConfig().getLanguage(),
            s.arguments
          );
        },
        hideIndexCards: function () {
          return IndexCardEventDescription.getHideIndexCardDescription(
            s.ref.ganttDiagram.getConfig().getLanguage(),
            s.arguments
          );
        },
        default: function () {
          console.warn('no description for: ' + s.eventName + ' set. May not be implemented yet.');
          return '';
        },
      };
      return (types[type] || types['default'])();
    }
    return handle(s.eventName);
  };

  // GETTER and SETTER inherited from Main Class

  private _undoShowIndexCards = function () {
    const s = this;
    s.ref.disableLogging();
    s.ref.hideIndexCardsMode();
    s.ref.enableLogging();
    return true;
  };

  private _redoShowIndexCards = function () {
    const s = this;
    s.ref.disableLogging();
    s.ref.showIndexCardsMode();
    s.ref.enableLogging();
    return true;
  };

  private _undoHideIndexCards = function () {
    const s = this;
    s.ref.disableLogging();
    s.ref.showIndexCardsMode();
    s.ref.enableLogging();
    return true;
  };

  private _redoHideIndexCards = function () {
    const s = this;
    s.ref.disableLogging();
    s.ref.hideIndexCardsMode();
    s.ref.enableLogging();
    return true;
  };
}

export class IndexCardEventDescription {
  // TODO: Event explanations.
  private constructor() {}

  static getShowIndexCardDescription = function (lang, args) {
    let description = '';
    if (lang == 'DE') description = 'Karteikarten Ansicht aktivieren.';
    if (lang == 'EN') description = 'Activate index card view.';

    return description;
  };
  static getHideIndexCardDescription = function (lang, args) {
    let description = '';
    if (lang == 'DE') description = 'Karteikarten Ansicht deaktivieren.';
    if (lang == 'EN') description = 'Deactivate index card view.';

    return description;
  };
}
