import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'frontend/src/environments/environment';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { delay, finalize, map, switchMap } from 'rxjs/operators';
import { IUserSettings, UserService } from '../user/data-access/user.service';

@Injectable({
  providedIn: 'root',
})
export class HorizonService {
  private _currentHorizon: BehaviorSubject<IHorizon> = new BehaviorSubject<IHorizon>(null);
  private currentHorizon: IHorizon;
  private horizons: IHorizon[];
  private _horizons: BehaviorSubject<IHorizon[]> = new BehaviorSubject<IHorizon[]>([]);
  private lastHorizonId: string;
  private readonly isLoadingModel$ = new BehaviorSubject<number>(0);

  constructor(public http: HttpClient, public userService: UserService) {
    this.userService.getUserSettings().subscribe((usersettings: IUserSettings) => {
      this.userService.getValueByType('CURRENT_HORIZON', true).subscribe((entry) => {
        if (!entry || entry.settingsValue.value === this.lastHorizonId) {
          return;
        }

        this.lastHorizonId = entry.settingsValue.value;
        this._init();
      });
    });
  }

  getLoadingCount(): Observable<number> {
    return this.isLoadingModel$.asObservable();
  }

  /**
   * set horizons
   * @param horizons IHorizon[]
   * @returns void
   */
  setHorizons(horizons: IHorizon[]): void {
    this.horizons = horizons || [];
    this._horizons.next(this.horizons);
  }

  /**
   * set current selected horizon
   * @param horizon IHorizon
   * @returns void
   */
  setCurrentHorizon(horizon: IHorizon): void {
    if (this.currentHorizon === horizon || (this.currentHorizon && horizon && this.currentHorizon.id === horizon.id)) {
      return;
    }
    this.isLoadingModel$.next(this.isLoadingModel$.value + 1);
    this.userService
      .updateUserSettings('CURRENT_HORIZON', horizon ? horizon.id : '')
      .pipe(
        finalize(() =>
          of(void 0)
            .pipe(delay(environment.initialLoadingAnimationDelay))
            .subscribe(() => this.isLoadingModel$.next(this.isLoadingModel$.value - 1))
        )
      )
      .subscribe((result) => {
        this.currentHorizon = horizon;
        this._currentHorizon.next(horizon);
      });
  }

  /**
   * initialized horizon service
   * @returns void
   */
  private _init(): void {
    this._getHorizons()
      .pipe(
        switchMap((horizons: IHorizon[]) => {
          this.setHorizons(horizons);

          if (horizons.length > 0) {
            // this.setActive(this.getLatestModel(models));
            return this.userService.getValueByType('CURRENT_HORIZON', true).pipe(
              switchMap((entry) => {
                for (const horizon of horizons) {
                  if (entry.settingsValue.value === horizon.id) {
                    return of(horizon);
                  }
                }
                return of(horizons[0]);
              })
            );
          }
          return of(null);
        })
      )
      .subscribe((horizon: IHorizon) => {
        this.setCurrentHorizon(horizon);
      });
  }

  /**
   * get all horizons
   * @returns Observable<IHorizon[]>
   */
  getHorizons(): Observable<IHorizon[]> {
    return this._horizons.asObservable();
  }

  /**
   * get currently selected horizon
   * @returns Observable<IHorizon>
   */
  getCurrenHorizon(): Observable<IHorizon> {
    return this._currentHorizon.asObservable();
  }

  /**
   * get horizon(s) by id
   * @param id string
   * @returns Observable<IHorizon[]>
   */
  private _getHorizons(id?: string): Observable<IHorizon[]> {
    return this.http.get(`${'rest/planninghorizon/'}${id || ''}`).pipe(map((horizon: IHorizon[]) => horizon));
  }

  /**
   * connects a model to planninghorizons
   * @param horizonId id of the planninghorizon
   * @param modelId id of the model
   */
  public connectModel(horizonId: string, modelId: string): Observable<any> {
    return this.http.get(`rest/planninghorizon/${horizonId}/add/model/${modelId}`);
  }

  postAlgorithm(id?: string, body?: any): Observable<any> {
    const fId = id ? '/' + id : '';
    return this.http.post(`rest/planninghorizon/algorithm${fId}`, body);
  }

  public getFilterString(filter: string, fArgs: any): string {
    let filterString = 'filter=';
    let count = 0;
    for (const l of fArgs) {
      if (count == 0) {
        filterString += l + " like '%" + filter + "%'";
        count++;
      } else {
        filterString += ' or ' + l + " like '%" + filter + "%'";
      }
    }

    return filterString;
  }

  public getOrderString(order: any): string {
    let orderString = '';
    if (order) {
      orderString = '&order=' + order.attr + ' ' + order.order;
    }

    return orderString;
  }

  putHorzion(id: string, body: any) {
    return this.http.put(`rest/planninghorizon/${id}`, body);
  }

  postHorzion(id: string, body: any) {
    return this.http.post(`rest/planninghorizon/${id}`, body);
  }

  deleteHorzion(id: string) {
    return this.http.delete(`rest/planninghorizon/${id}`);
  }

  getPermissions() {
    return this.http.get(`rest/planninghorizon/permission`);
  }

  getAlgorithmSettings(id: string) {
    return this.http.get(`rest/planningalgorithmsetting/${id}`);
  }
}

export interface IHorizon {
  id: string;
  name: string;
}
