import { takeUntil } from 'rxjs';
import { NodeProportionsStateCalculator } from '../../../html-structure/node-proportion-state/node-proportion-state-calculator';
import { BestGantt } from '../../../main';
import { GanttShiftTranslator } from '../shift-translator';
import { IGanttShiftTranslationEvent } from '../translation-events/translation-event.interface';

/**
 * Helper class handling the vertical scrolling of the gantt during shift translation.
 */
export class GanttScrollDuringTranslation {
  private _ganttDiagram: BestGantt;
  private _shiftTranslator: GanttShiftTranslator = null;
  private _nodeProportionsState: NodeProportionsStateCalculator;

  private _draggedShiftProportions: { x: number; y: number; width: number; height: number } = null;
  private readonly _threshold = 5;
  private readonly _scrollAdd = 10;
  private _scrollInterval: NodeJS.Timeout = null;

  constructor(ganttDiagram: BestGantt) {
    this._ganttDiagram = ganttDiagram;
    this._nodeProportionsState = new NodeProportionsStateCalculator(this._ganttDiagram.getNodeProportionsState());
  }

  /**
   * Initializes the vertical scrolling by listening to translation events.
   * @param shiftTranslator Reference to the calling shift translator instance.
   */
  public init(shiftTranslator: GanttShiftTranslator): void {
    this._shiftTranslator = shiftTranslator;

    this._shiftTranslator
      .onShiftEditStart()
      .pipe(takeUntil(this._shiftTranslator.onDestroy))
      .subscribe((event) => this._getShiftCanvasProportions(event));
    this._shiftTranslator
      .onShiftEditUpdate()
      .pipe(takeUntil(this._shiftTranslator.onDestroy))
      .subscribe((event) => this._scrollDuringTranslation(event));
    this._shiftTranslator
      .onShiftEditEnd()
      .pipe(takeUntil(this._shiftTranslator.onDestroy))
      .subscribe(() => this._resetDraggingStorage());
  }

  /**
   * Callback method for shift translation start events.
   * Determines the proportions (x, y, width, height) of the translated shift.
   * @param event Translation start event which triggered the execution of this method.
   */
  private _getShiftCanvasProportions(event: IGanttShiftTranslationEvent): void {
    const draggedShiftData = event.target.data()[0];
    this._draggedShiftProportions = {
      width: draggedShiftData.width,
      height: draggedShiftData.height,
      x: draggedShiftData.x,
      y: draggedShiftData.y,
    };
  }

  /**
   * Callback method for shift translation update events.
   * Performs a vertical scroll if neccesary.
   * @param event Translation update event which triggered the execution of this method.
   */
  private _scrollDuringTranslation(event: IGanttShiftTranslationEvent): void {
    if (!this._draggedShiftProportions) return;

    clearInterval(this._scrollInterval);

    this._draggedShiftProportions.y = event.newCoordinates[1];
    const bottomPosition = this._draggedShiftProportions.height + this._draggedShiftProportions.y;
    const viewportOverlayHeight = this._nodeProportionsState.getShiftViewportOverlayProportions().height;

    // scroll downwards
    if (bottomPosition > viewportOverlayHeight - this._threshold) {
      this._changeScrollPosition(true);
      this._scrollInterval = setInterval(() => {
        this._changeScrollPosition(true);
        // stop interval if bottom has been reached
        const scrollLowerPosition =
          this._nodeProportionsState.getScrollTopPosition() +
          this._nodeProportionsState.getShiftViewPortProportions().height;
        if (scrollLowerPosition >= this._nodeProportionsState.getShiftCanvasProportions().height)
          clearInterval(this._scrollInterval);
      }, 10);
    }
    // scroll upwards
    else if (this._draggedShiftProportions.y < this._threshold) {
      this._changeScrollPosition(false);
      this._scrollInterval = setInterval(() => {
        this._changeScrollPosition(false);
        // stop interval if top has been reached
        if (this._nodeProportionsState.getScrollTopPosition() <= 0) {
          clearInterval(this._scrollInterval);
        }
      }, 10);
    }
  }

  /**
   * Performs a vertical scroll.
   * @param downwards If true, the gantt will be scrolled downwards. If false, the gantt will be scrolled upwards.
   */
  private _changeScrollPosition(downwards = true): void {
    const downwardsMultiply = downwards ? 1 : -1;
    const newPosition = this._nodeProportionsState.getScrollTopPosition() + this._scrollAdd * downwardsMultiply;
    this._ganttDiagram.getVerticalScrollHandler().setVerticalScrollPos(newPosition);
  }

  /**
   * Callback method for shift translation end events.
   * Resets cached data.
   * @param event Translation end event which triggered the execution of this method.
   */
  private _resetDraggingStorage(): void {
    clearInterval(this._scrollInterval);
    this._draggedShiftProportions = null;
  }
}
