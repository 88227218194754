import { Action } from '@app-modeleditor/components/button/action/action';
import { EButtonDisplayType } from '@app-modeleditor/components/button/button-display-type.enum';
import { EntryElement } from '@app-modeleditor/components/entry-collection/entry-element';
import { MenuItem } from 'frontend/src/dashboard/view/template-toolbar/menu-item';
import { Toolbar } from 'frontend/src/dashboard/view/template-toolbar/toolbar';
import { ToolbarGroup } from 'frontend/src/dashboard/view/template-toolbar/toolbar-group';
import { IToolbarOptions } from 'frontend/src/dashboard/view/template-toolbar/toolbar-options.interface';
import { of } from 'rxjs';
import { HotspotWrapperComponent } from './hotspot-wrapper.component';

export class HotspotToolbar extends Toolbar {
  constructor(options: IToolbarOptions, private scope: HotspotWrapperComponent) {
    super(options);
  }

  public get(): HotspotToolbar {
    this.setClass('saxms-submenu').disableShortcuts(false);
    // .onHide(() => this.scope.template.toggleSideBarVisible());

    this.scope.template.getAdditionalSubMenus().forEach((menu) => {
      switch (menu.id) {
        case 'view':
          this.addMenuItems(
            this.getSidebarMenuAction(menu).chainActions(new Action().setCb(() => of(this.scope.setEditMode(false))))
          );
          break;
        case 'edit':
          this.addMenuItems(
            this.getSidebarMenuAction(menu).chainActions(new Action().setCb(() => of(this.scope.setEditMode(true))))
          );
          break;
      }
    });
    return this;
  }

  private getEnableState(menu: any) {
    return true;
  }

  public getSidebarMenuAction(menu: any) {
    const editItem: MenuItem = new MenuItem()
      .setName(menu.name)
      .setDisplayType(EButtonDisplayType.LABEL_ONLY)
      .defaultGroup(this)
      .setEnableBy(() => {
        return this.getEnableState(menu);
      });

    const groups: ToolbarGroup[] = menu.subMenuGroups.map((group) => {
      const items: EntryElement[] = [];
      for (const entry of group.entryElements) {
        const itemElement: MenuItem = this.scope.templatefactory
          .adapt<MenuItem>(entry)
          .setDisplayType(EButtonDisplayType.ICON_WITH_LABEL_BELOW);
        if ((group.id as string).includes('polygon_edit') || (entry.fieldIdentifier as string) === 'polygonDelete') {
          itemElement.setEnableBy(() => !!this.scope.currentPolygon);
        }
        if ((entry.fieldIdentifier as string) === 'vertexDelete') {
          itemElement.setEnableBy(() => !!this.scope.currentNode);
        }
        items.push(itemElement);
      }
      const g: ToolbarGroup = new ToolbarGroup().setName(group.name).setId(group.id).setEntryElements(items);
      return g;
    });

    editItem
      .chainActions(
        new Action().setCb(() => of(this.scope.template.setSideBarVisible(this.getActiveMenuItem() === editItem))),
        new Action().setCb(() =>
          of(this.scope.template.setSideBarMode(menu.sideBar.id.toLowerCase().replace('template.sidebar.', '')))
        ),
        new Action().setCb(() => of(this.scope.template.setSideBarPosition(menu.sideBar.position)))
      )
      .setToolbarGroups(groups);

    return editItem;
  }
}
