import { CommonModule, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { TranslateModule } from '@ngx-translate/core';
import { DateInputComponent } from './date-input.component';

@NgModule({
  declarations: [DateInputComponent],
  imports: [TranslateModule, CommonModule, MatInputModule, FormsModule, ReactiveFormsModule],
  providers: [DecimalPipe],
  exports: [DateInputComponent],
})
export class DateInputModule {}
