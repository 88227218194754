export class SortFilterObject {
  private filterActive = false;
  private sortActive = false;
  private sortDirection: ESortDirection = ESortDirection.NONE;
  private columnIndex: number;
  private tableId: string;

  isFilterActive(): boolean {
    return this.filterActive;
  }

  isSortActive(): boolean {
    return this.sortActive;
  }

  getSortDirection(): ESortDirection {
    return this.sortDirection;
  }
  getColumnIndex(): number {
    return this.columnIndex;
  }

  getTableId(): string {
    return this.tableId;
  }

  setFilterActive(filterActive: boolean): SortFilterObject {
    this.filterActive = filterActive;
    return this;
  }

  setSortActive(sortActive: boolean): SortFilterObject {
    this.sortActive = sortActive;
    return this;
  }

  setSortDirection(sortDirection: ESortDirection): SortFilterObject {
    this.sortDirection = sortDirection;
    return this;
  }
  setColumnIndex(columnIndex: number): SortFilterObject {
    this.columnIndex = columnIndex;
    return this;
  }

  setTableId(tableId: string): SortFilterObject {
    this.tableId = tableId;
    return this;
  }
}

export interface ISpreadsheetSortObject {
  column: number;
  sortOrder: string;
}

export enum ESortDirection {
  NONE = 'none',
  ASC = 'asc',
  DESC = 'desc',
}
