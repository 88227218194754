import { IGanttAdditionalRowAttribute, IGanttAdditionalRowData, IGanttBrokenConstraints } from '@gantt/public-api';
import { EGanttBlockAttributeDataType } from '../../toolbar/filter/lightbox/attribute-data-types.enum';
import { GanttChildren, IGanttAttributeMapping, IGanttEntryAttributeMapping } from '../gantt-input.data';

/**
 * Mapper to extract data of a backend row.
 */
export abstract class GanttRowAdditionalDataMapper {
  /**
   * Extracts additional data from backend row and returns it.
   * @param row Row from backend
   * @param attributeMapping General gantt attribute mapping
   * @param entryAttributeMapping Entry attribute mapping
   * @returns Extracted data
   */
  public static extractAdditionalDataFromRow(
    row: GanttChildren,
    attributeMapping: IGanttAttributeMapping,
    entryAttributeMapping: IGanttEntryAttributeMapping,
    brokenConstraints: IGanttBrokenConstraints
  ): IGanttAdditionalRowData {
    const additionalData: IGanttAdditionalRowData = {
      additionalData: row.details?.additionalDetails,
      brokenConstraints: brokenConstraints || {},
      originalRowId: row.id,
      canonicalName: row.canonicalName,
      attributes: this.getRowAttributes(row, attributeMapping, entryAttributeMapping),
    };

    return additionalData;
  }

  /**
   * Collects all constraints of blocks in the passed row and returns them.
   * @param existingBackendRow Row whose blocks are to be searched
   */
  public static getBrokenConstraintsOfRow(existingBackendRow: GanttChildren): IGanttBrokenConstraints {
    const constraintsList: string[] = [];
    const constraintsObject: IGanttBrokenConstraints = {};

    existingBackendRow.blocks?.forEach((block) => {
      if (block.details?.brokenConstraints) {
        for (const constraint in block.details.brokenConstraints) {
          const obj = block.details.brokenConstraints[constraint];
          if (obj.t1 && !constraintsList.includes(obj.t2)) {
            constraintsList.push(obj.t2);
          }
        }
      }
    });
    constraintsList.forEach((constrain, i) => {
      const key = `${i + 1}. Regelbruch`;
      constraintsObject[key] = { t1: true, t2: constrain };
    });

    return constraintsObject;
  }

  /**
   * Extracts all row attributes of a backend row using attribute mapping.
   * @param row Row from backend
   * @param attributeMapping General gantt attribute mapping
   * @param entryAttributeMapping Entry attribute mapping
   * @returns Extracted data
   */
  private static getRowAttributes(
    row: GanttChildren,
    attributeMapping: IGanttAttributeMapping,
    entryAttributeMapping: IGanttEntryAttributeMapping
  ): IGanttAdditionalRowAttribute[] {
    const attributes: IGanttAdditionalRowAttribute[] = [];
    const tooltipDetails = row.toolTipDetails?.additionalDetails;
    const details = row.details?.additionalDetails;

    // extract from tooltipDetails
    if (tooltipDetails && attributeMapping) {
      for (const key in row.toolTipDetails.additionalDetails) {
        const result = this.getRowKeyValueByAdditionalDetails(
          key,
          row.toolTipDetails.additionalDetails[key].t2,
          attributeMapping,
          row.toolTipDetails.additionalDetails[key].t1
        );
        attributes.push(result);
      }
    }

    // extract from details
    if (details) {
      for (const key in row.details.additionalDetails) {
        let mapping = null;
        if (!isNaN(+key) && entryAttributeMapping && entryAttributeMapping[row.canonicalName]) {
          mapping = entryAttributeMapping[row.canonicalName];
        }
        const showInTooltip = row.details.additionalDetails[key].t1 && !tooltipDetails; // show only if tooltipDetails are not defined
        const result = this.getRowKeyValueByAdditionalDetails(
          key,
          row.details.additionalDetails[key].t2,
          mapping,
          showInTooltip
        );
        attributes.push(result);
      }
    }

    return attributes;
  }

  /**
   * Collects data about the key, value and showInTooltip and returns it as an object.
   * @param key Raw key value
   * @param value Raw value
   * @param attributeMapping
   * @param showInTooltip Decides if entry should be visible in tooltip
   */
  private static getRowKeyValueByAdditionalDetails(
    key: string,
    value: string | number,
    attributeMapping: IGanttAttributeMapping,
    showInTooltip: boolean
  ): IGanttAdditionalRowAttribute {
    const valueDataType: EGanttBlockAttributeDataType =
      attributeMapping && attributeMapping[key] && attributeMapping[key]?.dataType
        ? attributeMapping[key]?.dataType
        : EGanttBlockAttributeDataType.STRING;
    const result = { key: '', value: '', showInTooltip: showInTooltip };
    result.key = this.getAttributeKey(key, attributeMapping);

    if (!(value === null) || !(value === undefined)) {
      result.value = value === '' ? value : this.getAttributeValueByDataType(value, valueDataType);
    }
    return result;
  }

  /**
   * Extracts the attribute key of an attribute.
   * @param attributeNumber
   * @param attributeMapping
   */
  private static getAttributeKey(attributeNumber: string, attributeMapping: IGanttAttributeMapping): string {
    if (attributeMapping && attributeNumber.match(/^[0-9]+$/) != null && attributeMapping[+attributeNumber]) {
      // faster parseInt
      const attributeNameWrapper = attributeMapping[attributeNumber];
      if (attributeNameWrapper.localization) return attributeNameWrapper.localization;
    }
    return attributeNumber;
  }

  /**
   * Extracts the attribute value of an attribute.
   * @param value
   * @param valueDataType
   */
  public static getAttributeValueByDataType(
    value: string | number | boolean,
    valueDataType: EGanttBlockAttributeDataType
  ): string {
    switch (valueDataType) {
      case EGanttBlockAttributeDataType.DATE:
        return GanttRowAdditionalDataMapper.getDateStrig(value);
      case EGanttBlockAttributeDataType.TIME:
        return GanttRowAdditionalDataMapper.getTimeString(value);
      case EGanttBlockAttributeDataType.DATE_TIME:
        return (
          GanttRowAdditionalDataMapper.getDateStrig(value) + ' ' + GanttRowAdditionalDataMapper.getTimeString(value)
        );
      case EGanttBlockAttributeDataType.BOOLEAN:
        if (typeof value !== 'boolean') {
          value = value === 'true' ? true : false;
        }
        return value ? 'ja' : 'nein';
      default:
        return value + '';
    }
  }

  /**
   * Converts an attribute value to a time string.
   * @param value Attribute value to generate the time string for.
   * @param utc If true, the local time zone will be ignored.
   * @returns Time string or the specified value as string if it does not contain a date.
   */
  public static getTimeString(value: string | number | boolean, utc = false): string {
    if (isNaN(value as number)) {
      return value + '';
    }
    if (typeof value != 'number') {
      value = parseInt(value as string);
    }

    const date = new Date(value);
    const hours = utc ? date.getUTCHours() : date.getHours();
    const minutes = utc ? date.getUTCMinutes() : date.getMinutes();
    return `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}`;
  }

  /**
   * Converts an attribute value to a date string.
   * @param value Attribute value to generate the date string for.
   * @returns Date string or the specified value as string if it does not contain a date.
   */
  public static getDateStrig(value: string | number | boolean): string {
    if (isNaN(value as number)) {
      return value + '';
    }
    if (typeof value != 'number') {
      value = parseInt(value as string);
    }

    const date = new Date(value);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    return `${day < 10 ? '0' + day : day}.${month < 10 ? '0' + month : month}.${date.getFullYear()}`;
  }
}
