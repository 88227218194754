import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Action } from '@app-modeleditor/components/button/action/action';
import { EPredefinedAction } from '@app-modeleditor/components/button/action/predefined-action.enum';
import { ContextMenuAdapter } from '@app-modeleditor/components/contextmenu/context-menu-adapter.service';
import { UiService } from '@app-modeleditor/ui.service';
import { ConfigService } from '@core/config/config.service';
import { LegendCommunicationService } from 'frontend/src/dashboard/gantt/gantt/dock/views/legend/legend-communication.service';
import { GanttLibService } from 'frontend/src/dashboard/gantt/gantt/gantt-lib.service';
import { GanttPlugInAction } from 'frontend/src/dashboard/gantt/gantt/plugin/i-saxms-best-gantt.plugin';
import { SaxMsBestGanttToolbarHandler } from 'frontend/src/dashboard/gantt/gantt/saxms-best-gantt-submenu-handler';
import { SaxMsBestGanttActiveSubmenuEntryElementSetting } from 'frontend/src/dashboard/gantt/gantt/saxms-best-gantt.settings';
import { GanttTemplateData } from 'frontend/src/dashboard/gantt/helper/gantt';
import { ActionMapper } from 'frontend/src/dashboard/shared/data-access/actions/action-mapper';
import { ActionService } from 'frontend/src/dashboard/shared/data-access/actions/action.service';
import { ID } from 'frontend/src/dashboard/shared/data-access/actions/actions';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ResourceCommunicationService } from '../../gantt/dock/views/resources/resource-communication.service';
import { GeneralGanttActionHandler } from '../action-handling/action-handler';
import { GanttActionService } from '../gantt-action.service';
import { GanttActionScopes, GanttActionWrapper } from '../gantt-actions/external-action-registration';
import { GanttExternalActionHandler } from '../gantt-actions/gantt-action-handling/gantt-action-handler';
import { GanttEditService } from '../gantt-edit/gantt-edit.service';
import { GanttSettingsService } from '../gantt-settings/service/gantt-settings.service';
import { GanttTooltipService } from '../gantt-tooltips/gantt-tooltip.service';
import { IGanttPlugin, IGanttTemplateValue } from '../generator/gantt-input.data';
import { GanttPredefinedLocalActions } from '../generator/predefined/local-actions/predefined-local-action-handler';
import { GanttPredefinedSettings } from '../generator/predefined/predefined-settings-handler';
import { LoadingStateService } from '../loading-state.service';
import { IGanttResponse } from '../response/gantt-response';
import { GanttResponseHandler } from '../response/response-handler';
import { GanttTemplateDataService } from '../template-data/gantt-template-data.service';
import { GanttToolbarIndicatorService } from '../toolbar/gantt-toolbar-indicator.service';
import { VisibilityStateService } from '../visibility-state.sevice';
import { GanttEssentialPlugIns, PluginIdToInstanceMap } from './e-gantt-essential-plugins';
import { ExternalGanttPlugin } from './external-plugin';
import { GanttAreaOverlayPlugIn } from './plugin-list/area-overlay/area-overlay.plugin';
import {
  GanttBlockAddButtonBackendType,
  GanttBlockAddButtonPlugIn,
} from './plugin-list/block-add-button/block-add-button.plugin';
import { GanttBlockAdditionalMarkersPlugIn } from './plugin-list/block-additional-markers/block-additional-markers';
import { GanttColorizerByAttributePlugIn } from './plugin-list/block-colorizer/by-attribute/colorizer-by-attribute';
import { GanttBlockConnectionsPlugIn } from './plugin-list/block-connections/block-connections';
import {
  GanttShiftCreatorBackendType,
  GanttShiftCreatorBackendTypeOld,
  GanttShiftCreatorPlugIn,
} from './plugin-list/block-creator/block-creator';
import { GanttBlockFilterByConditionPlugIn } from './plugin-list/block-filter-by-condition/block-filter-by-condition';
import { GanttBlockFilterPlugIn } from './plugin-list/block-filter/block-filter';
import { GanttBlockFilterByAttributePlugIn } from './plugin-list/block-filter/block-filter-by-attribute';
import { GanttBlockHighlighterPlugIn } from './plugin-list/block-highlighter/block-highlighter';
import { GanttBlockLoadingPlugIn } from './plugin-list/block-loading/block-loading';
import { GanttBlockMoverPlugIn } from './plugin-list/block-mover/block-mover';
import {
  BlockingIntervalBackendType,
  GanttBlockingIntervalsPlugIn,
} from './plugin-list/blocking-intervals/blocking-intervals.plugin';
import {
  GanttBrokenConstraintsNavigatorBackendType,
  GanttBrokenConstraintsNavigatorPlugIn,
} from './plugin-list/broken-constraints-navigator/broken-constraints-navigator.plugin';
import { GanttEarliestStartLatestEndPlugIn } from './plugin-list/earliest-start-latest-end-visualizer/earliest-start-latest-end-visualizer';
import { GanttRestrictBlockDragPlugIn } from './plugin-list/edit-block/restrictions/restrict-block-drag';
import { GanttGridLabelsPlugIn } from './plugin-list/grid-labels/grid-labels';
import { GanttIndexCardsPlugIn } from './plugin-list/index-cards/index-cards.plugin';
import { GanttOverlappingShiftsPlugIn } from './plugin-list/overlapping-shifts/overlapping-shifts';
import {
  GanttResourceChangeBackendType,
  GanttResourceChangePlugIn,
} from './plugin-list/resource-change/resource-change.plugin';
import { GanttRowColorizerPlugIn } from './plugin-list/row-colorizer/row-colorizer.plugin';
import { GanttRowRestrictionPlugIn } from './plugin-list/row-restriction/row-restriction.plugin';
import { GanttShiftDragVisualizerPlugIn } from './plugin-list/shift-drag-visualizer/shift-drag-visualizer.plugin';
import { GanttStickyBlocksPlugIn } from './plugin-list/sticky-blocks/sticky-blocks.plugin';
import { GanttSuperBlocksPlugIn } from './plugin-list/superblocks/superblocks.plugin';
import { GanttXAxisFormatPlugIn } from './plugin-list/time-formatting/xaxis-formatter';
import { GanttXAxisManipulatorPlugIn } from './plugin-list/time-scaling/xaxis-manipulator';
import { GanttCurrentTimePointMarkerPlugIn } from './plugin-list/timepoint-marker/current-timepoint-marker.plugin';
import { GanttTimePointMarkerPlugIn } from './plugin-list/timepoint-marker/timepoint-marker.plugin';
import { GanttYAxisSearchPlugIn } from './plugin-list/y-axis-search/y-axis-search';

/**
 * Stores and manages all gantt plugins.
 * Triggers plugin lifecycle.
 * Holds reference to gantt diagram.
 */
@Injectable()
export class GanttPluginHandlerService implements OnDestroy {
  private _externalPlugIns: Map<string, ExternalGanttPlugin> = new Map();
  private _toolbarHandler: SaxMsBestGanttToolbarHandler = null; // TODO: outsourcing
  private _actionHandler: GeneralGanttActionHandler = null; // TODO: outsourcing
  private _backendInput: IGanttTemplateValue = null; // TODO: outsourcing
  private _externalActionHandler: GanttExternalActionHandler = null;
  private _responseHandler: GanttResponseHandler = null;
  private _backendPluginData: IGanttPlugin[] = [];
  private _predefinedSettings: GanttPredefinedSettings = null;
  private _predefinedLocalActions: GanttPredefinedLocalActions = null;
  private _afterInitialized: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _onDestroySubject = new Subject<void>();

  constructor(
    private _uiService: UiService,
    private _contextMenuService: GanttActionService,
    private _contextMenuAdapter: ContextMenuAdapter,
    private _ganttTemplateDataService: GanttTemplateDataService,
    private _ganttLibService: GanttLibService,
    private _actionService: ActionService,
    private _actionMapper: ActionMapper,
    private _ganttSettingsService: GanttSettingsService,
    private _legendCommunicationService: LegendCommunicationService,
    private _resourceCommunicationService: ResourceCommunicationService,
    private _configService: ConfigService,
    private _ganttToolbarIndicatorService: GanttToolbarIndicatorService,
    private _ganttTooltipService: GanttTooltipService,
    private _visibilityService: VisibilityStateService,
    public http: HttpClient,
    public loadingStateService: LoadingStateService,
    public ganttEditService: GanttEditService
  ) {}

  public ngOnDestroy(): void {
    this.getExternalPlugins().forEach((plugin) => plugin.onDestroy());
    this._externalActionHandler?.destroy();
    this._responseHandler?.destroy();

    this._onDestroySubject.next();
    this._onDestroySubject.complete();
    this._externalPlugIns = new Map();
    this._toolbarHandler = null;
    this._actionHandler = null;
    this._backendInput = null;
    this._externalActionHandler = null;
    this._responseHandler = null;
    this._backendPluginData = [];
    this._predefinedSettings = null;
    this._predefinedLocalActions = null;
    this._afterInitialized = new BehaviorSubject<boolean>(false);
  }

  public init(
    toolbarHandler: SaxMsBestGanttToolbarHandler,
    actionHandler: GeneralGanttActionHandler,
    backendInput: IGanttTemplateValue
  ) {
    this._toolbarHandler = toolbarHandler;
    this._actionHandler = actionHandler;
    this._backendInput = backendInput;

    this._extractPluginDataFromTemplate(this._ganttTemplateDataService.getTemplateData());

    this._createInstanceOfResponseHandler();
    this._createInstanceOfExternalActionHandler();

    this._addEssentialPlugins();
    this._addPlugsInByBackendData(this._backendPluginData);

    this._createInstanceOfPredefinedSettings();
    this._createInstanceOfPredefinedLocalActions();

    this._afterInitialized.next(true);
  }

  /**
   * Notifies all registered callbacks that this instance is ready to rumble.
   * Useful if there is functionality from outside which has to wait until the GanttPluginHandlerService is ready.
   */
  public getNotifiedIfInitialized(): Observable<boolean> {
    return this._afterInitialized.asObservable();
  }

  public addCustomListener(ngUnsubscribe?: Subject<void>) {
    let listener = this._actionService.customActionListener.asObservable();
    if (ngUnsubscribe) {
      listener = listener.pipe(takeUntil(ngUnsubscribe));
    }

    listener.pipe(takeUntil(this.onDestroy)).subscribe((buttonAction) => {
      switch (buttonAction.button.id) {
        case EPredefinedAction.UPDATE_GANTT:
          this.handleUpdateGanttNotification(buttonAction);
          return;
        case EPredefinedAction.GET_AVAILABLE_RESOURCE_ENTRIES:
          // execute request
          const action = buttonAction.button as Action;
          this._actionService
            .executeAction(
              ID.generic,
              action.getActionMethod(),
              this._actionMapper._getUrl(action),
              this._actionMapper.getBody(action),
              this._actionMapper.getParams(action)
            )
            .subscribe((data: string[]) => {
              // trigger highlight resources
              this._resourceCommunicationService.emitHighlight(data);
            });
          return;
      }

      const ganttActionWrapper: GanttActionWrapper = {
        actions: [buttonAction.button],
        actionScope: GanttActionScopes.LOCAL,
      };
      this._actionHandler.executeActions(ganttActionWrapper).pipe(takeUntil(this.onDestroy)).subscribe();
    });
  }

  /**
   * Handles the predefined gantt update action.
   * @param buttonAction Action data container.
   */
  private handleUpdateGanttNotification(buttonAction: {
    button: Action;
    content?: IGanttResponse;
    blockIds?: string[];
  }): void {
    // if action was executed already -> handle result and return
    if (buttonAction.content) {
      this._responseHandler.handleUpdateNotification(buttonAction.content);
      this._ganttTemplateDataService
        .getTemplateData()
        .setSelectedBlock({}, this._ganttLibService.backendToGanttOriginInputMapper);
      return;
    }

    // map block ids to js gantt block ids
    const blockIds = buttonAction.blockIds;
    let mappedBlockIds = [];
    const backendMapper = this._ganttLibService.backendToGanttOriginInputMapper;
    blockIds.forEach((id) => (mappedBlockIds = mappedBlockIds.concat(backendMapper.getShiftClonesByShiftId(id))));

    // restrict blocks to update
    this.ganttEditService.deactivateGanttShiftBlockEdit(mappedBlockIds);

    // execute request
    const action = buttonAction.button;
    this._actionService
      .executeAction(
        ID.generic,
        action.getActionMethod(),
        this._actionMapper._getUrl(action),
        this._actionMapper.getBody(action),
        this._actionMapper.getParams(action)
      )
      .subscribe((data: any) => {
        this.ganttEditService.activateGanttShiftBlockEdit(mappedBlockIds);
        this._responseHandler.handleUpdateNotification(data);
        this._ganttTemplateDataService
          .getTemplateData()
          .setSelectedBlock({}, this._ganttLibService.backendToGanttOriginInputMapper);
      });
  }

  /**
   * Automatically creates plugins by backend template plugin data.
   * Add all non-essential plugins to this list.
   * @param backendPluginData List of plugins to create by backend template data.
   */
  public _addPlugsInByBackendData(backendPluginData: IGanttPlugin[]): void {
    if (!this._backendPluginData) {
      return;
    }
    for (const pluginData of backendPluginData) {
      switch (pluginData.id) {
        case BlockingIntervalBackendType:
          this.addPlugIn(
            BlockingIntervalBackendType,
            new GanttBlockingIntervalsPlugIn(
              this,
              this._ganttLibService,
              this._responseHandler,
              this._actionHandler,
              backendPluginData,
              this._uiService,
              this._ganttTemplateDataService.getTemplateData(),
              this._contextMenuAdapter,
              this._configService,
              this.ganttEditService
            )
          );
          break;
        case GanttResourceChangeBackendType:
          this.addPlugIn(
            GanttResourceChangeBackendType,
            new GanttResourceChangePlugIn(
              this,
              this._ganttLibService,
              this._responseHandler,
              this._actionHandler,
              backendPluginData
            )
          );
          break;
        case GanttBlockAddButtonBackendType:
          this.addPlugIn(
            GanttBlockAddButtonBackendType,
            new GanttBlockAddButtonPlugIn(
              this,
              this._ganttLibService,
              this._actionHandler,
              this._responseHandler,
              pluginData
            )
          );
          break;
        case GanttShiftCreatorBackendType:
        case GanttShiftCreatorBackendTypeOld:
          this.addPlugIn(
            pluginData.id,
            new GanttShiftCreatorPlugIn(
              this,
              this._ganttLibService,
              this._responseHandler,
              this._actionHandler,
              this._backendPluginData,
              this._ganttTemplateDataService.getTemplateData()
            )
          );
          break;
        case GanttBrokenConstraintsNavigatorBackendType:
          this.addPlugIn(
            GanttBrokenConstraintsNavigatorBackendType,
            new GanttBrokenConstraintsNavigatorPlugIn(this, this._ganttLibService, this._actionHandler)
          );
          break;
      }
    }
  }

  /**
   * Returns pre registered essential plugin.
   * @param id Storage-Id of essential plugin.
   */
  public getEssentialPlugIn<Plugin extends GanttEssentialPlugIns>(id: Plugin): PluginIdToInstanceMap<Plugin> {
    return this._externalPlugIns.get(id) as PluginIdToInstanceMap<Plugin>;
  }

  /**
   * Adds plugin to external plugin storage.
   * It will be automatically initialized and affected from each onaction-event.
   * @param id Storage id to guarantee the possibility of access plugin.
   * @param plugin PlugIn instance to insert.
   */
  public addPlugIn(id: string, plugin: ExternalGanttPlugin): void {
    if (this._externalPlugIns.get(id)) console.warn('PlugIn %s does already exist and will be overwritten', id);
    this._externalPlugIns.set(id, plugin);
    plugin.onInit(this._ganttTemplateDataService.getTemplateData(), this._backendInput);
  }

  /**
   * Returns all external plugins.
   */
  public getExternalPlugins(): ExternalGanttPlugin[] {
    const plugInList: ExternalGanttPlugin[] = [];
    this._externalPlugIns.forEach((plugin: ExternalGanttPlugin) => plugInList.push(plugin));
    return plugInList;
  }

  /**
   * Returns plugin by storage Id.
   * @param plugInId PluginID.
   */
  public getPlugInById(plugInId: string): ExternalGanttPlugin {
    return this._externalPlugIns.get(plugInId);
  }

  /**
   * Executes action which is coupled with a plugin.
   * @param action An action.
   */
  public executePlugInAction(action: GanttPlugInAction | any): Observable<any> {
    const plugin: ExternalGanttPlugin = this.getPlugInById(action.pluginId);
    if (!plugin) {
      return of(null);
    }
    return plugin.executeAction(action);
  }

  /**
   * Propagates given local action to all plugins and predefined actions.
   * @param action Local action.
   */
  public executeGanttAction(action: any): Observable<any> {
    this._externalPlugIns.forEach((plugin) => plugin.executeAction(action));
    this._predefinedLocalActions.handleLocalAction(action);
    return this._externalActionHandler.handleLocalAction(action);
  }

  /**
   * Propagates given gantt submenu elements to all registered plugins.
   * (To make funny stuff with it).
   * @param submenuElements Submenu Elements which should be inserted in all elements.
   */
  public injectSubmenuElements(submenuElements: SaxMsBestGanttActiveSubmenuEntryElementSetting[]): void {
    this._externalPlugIns.forEach((plugin) => plugin.injectSettings(submenuElements));
  }

  /**
   * Observable which gets triggered when the service gets destroyed.
   */
  public get onDestroy(): Observable<void> {
    return this._onDestroySubject.asObservable();
  }

  public getResponseHandler(): GanttResponseHandler {
    return this._responseHandler;
  }

  public getTemplateData(): GanttTemplateData {
    return this._ganttTemplateDataService.getTemplateData();
  }

  public getUiService(): UiService {
    return this._uiService;
  }

  public getExternalActionHandler(): GanttExternalActionHandler {
    return this._externalActionHandler;
  }

  public getGanttSettingsService(): GanttSettingsService {
    return this._ganttSettingsService;
  }

  public getGanttTooltipService(): GanttTooltipService {
    return this._ganttTooltipService;
  }

  private _extractPluginDataFromTemplate(templateData: GanttTemplateData) {
    this._backendPluginData = templateData.getPlugins();
  }

  private _createInstanceOfExternalActionHandler() {
    this._externalActionHandler = new GanttExternalActionHandler(
      this,
      this._ganttLibService,
      this._actionHandler,
      this._responseHandler,
      this._ganttTemplateDataService.getTemplateData(),
      this._backendInput.ganttActions
    );
  }

  private _createInstanceOfResponseHandler() {
    this._responseHandler = new GanttResponseHandler(
      this._toolbarHandler,
      this,
      this._ganttTemplateDataService.getTemplateData().getAttributeMapping(),
      this._ganttLibService,
      this._legendCommunicationService,
      this._configService,
      this._ganttTemplateDataService,
      this._visibilityService
    );
  }

  private _createInstanceOfPredefinedSettings() {
    this._predefinedSettings = new GanttPredefinedSettings(
      this,
      this._ganttLibService,
      this._backendInput,
      this._ganttTemplateDataService.getTemplateData(),
      this._actionHandler,
      this._contextMenuService,
      this._configService
    );
  }

  private _createInstanceOfPredefinedLocalActions(): void {
    this._predefinedLocalActions = new GanttPredefinedLocalActions(this, this._ganttLibService);
  }

  public getPredefinedSettings(): GanttPredefinedSettings {
    return this._predefinedSettings;
  }

  public get toolbarIndicatorService(): GanttToolbarIndicatorService {
    return this._ganttToolbarIndicatorService;
  }

  /**
   * Registration of all plugins which does exists exactly one time and are independant of backend temapltes "additionalplugin" data.
   * Pay attention to dependencies - some plugins wrappers might need other essential plugins to init.
   */
  private _addEssentialPlugins(): void {
    this.addPlugIn(
      GanttEssentialPlugIns.BlockColorizeByAttributePlugIn,
      new GanttColorizerByAttributePlugIn(
        this,
        this._ganttLibService,
        this._actionHandler,
        this._responseHandler,
        this._ganttSettingsService,
        this._legendCommunicationService
      )
    );
    this.addPlugIn(
      GanttEssentialPlugIns.SuperBlocksPlugIn,
      new GanttSuperBlocksPlugIn(
        this,
        this._ganttLibService,
        this._actionHandler,
        this._responseHandler,
        this._ganttSettingsService
      )
    );
    this.addPlugIn(
      GanttEssentialPlugIns.XAxisManipulatorPlugIn,
      new GanttXAxisManipulatorPlugIn(this, this._ganttLibService, this._actionHandler)
    );
    // this.addPlugIn(GanttEssentialPlugIns.YAxisTableBuilderPlugIn, new GanttYAxisTablePlugIn(this, this._ganttLibService, this._actionHandler)); // deprecated
    this.addPlugIn(
      GanttEssentialPlugIns.IndexCardBuilderPlugIn,
      new GanttIndexCardsPlugIn(
        this,
        this._ganttLibService,
        this._actionHandler,
        this._responseHandler,
        this.http,
        this._configService
      )
    );
    this.addPlugIn(
      GanttEssentialPlugIns.OverlappingShiftsPlugIn,
      new GanttOverlappingShiftsPlugIn(
        this,
        this._ganttLibService,
        this._actionHandler,
        this._responseHandler,
        this._configService,
        this._ganttTemplateDataService
      )
    );
    this.addPlugIn(
      GanttEssentialPlugIns.XAxisFormatterPlugIn,
      new GanttXAxisFormatPlugIn(this, this._ganttLibService, this._actionHandler)
    );
    this.addPlugIn(
      GanttEssentialPlugIns.BlockAdditionalMarkersPlugIn,
      new GanttBlockAdditionalMarkersPlugIn(this, this._ganttLibService, this._actionHandler, this._responseHandler)
    );
    this.addPlugIn(
      GanttEssentialPlugIns.YAxisSearchPlugIn,
      new GanttYAxisSearchPlugIn(this, this._ganttLibService, this._actionHandler)
    );
    this.addPlugIn(
      GanttEssentialPlugIns.BlockMoverPlugIn,
      new GanttBlockMoverPlugIn(this, this._ganttLibService, this._actionHandler)
    );
    this.addPlugIn(
      GanttEssentialPlugIns.RestrictBlockDragPlugIn,
      new GanttRestrictBlockDragPlugIn(this, this._ganttLibService, this._actionHandler)
    );
    this.addPlugIn(
      GanttEssentialPlugIns.BlockFilterPlugIn,
      new GanttBlockFilterPlugIn(this, this._ganttLibService, this._actionHandler, this._responseHandler)
    );
    this.addPlugIn(
      GanttEssentialPlugIns.GanttBlockFilterByAttributePlugIn,
      new GanttBlockFilterByAttributePlugIn(
        this,
        this._ganttLibService,
        this._actionHandler,
        this._ganttTemplateDataService
      )
    );
    this.addPlugIn(
      GanttEssentialPlugIns.RowRestrictionPlugIn,
      new GanttRowRestrictionPlugIn(this, this._ganttLibService, this._actionHandler)
    );
    this.addPlugIn(
      GanttEssentialPlugIns.AreaOverlayPlugIn,
      new GanttAreaOverlayPlugIn(this, this._ganttLibService, this._actionHandler)
    );
    this.addPlugIn(
      GanttEssentialPlugIns.TimePointMarkerPlugIn,
      new GanttTimePointMarkerPlugIn(this, this._ganttLibService, this._actionHandler)
    );
    this.addPlugIn(
      GanttEssentialPlugIns.CurrentTimePointMarkerPlugIn,
      new GanttCurrentTimePointMarkerPlugIn(this, this._ganttLibService, this._actionHandler)
    );
    this.addPlugIn(
      GanttEssentialPlugIns.BlockConnectionsPlugIn,
      new GanttBlockConnectionsPlugIn(this, this._ganttLibService, this._actionHandler, this._responseHandler)
    );
    this.addPlugIn(
      GanttEssentialPlugIns.GanttShiftDragVisualizerPlugIn,
      new GanttShiftDragVisualizerPlugIn(this, this._ganttLibService, this._actionHandler, this._responseHandler)
    );
    this.addPlugIn(
      GanttEssentialPlugIns.GanttBlockFilterByConditionPlugIn,
      new GanttBlockFilterByConditionPlugIn(this, this._ganttLibService, this._actionHandler)
    );
    this.addPlugIn(
      GanttEssentialPlugIns.GanttStickyBlocksPlugIn,
      new GanttStickyBlocksPlugIn(
        this,
        this._ganttLibService,
        this._actionHandler,
        this._responseHandler,
        this._ganttTemplateDataService
      )
    );
    this.addPlugIn(
      GanttEssentialPlugIns.GanttRowColorizerPlugin,
      new GanttRowColorizerPlugIn(this, this._ganttLibService, this._actionHandler, this._ganttTemplateDataService)
    );
    this.addPlugIn(
      GanttEssentialPlugIns.GanttBlockHighlighter,
      new GanttBlockHighlighterPlugIn(this, this._ganttLibService, this._actionHandler)
    );
    this.addPlugIn(
      GanttEssentialPlugIns.GanttEarliestStartLatestEndVisualizer,
      new GanttEarliestStartLatestEndPlugIn(this, this._ganttLibService, this._actionHandler)
    );
    this.addPlugIn(
      GanttEssentialPlugIns.GanttBlockLoading,
      new GanttBlockLoadingPlugIn(this, this._ganttLibService, this._actionHandler, this.ganttEditService)
    );
    this.addPlugIn(
      GanttEssentialPlugIns.GanttGridLabels,
      new GanttGridLabelsPlugIn(this, this._ganttLibService, this._actionHandler, this._responseHandler)
    );
  }
}
