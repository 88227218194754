import { Component, Input } from '@angular/core';
import { EntryElement } from '@app-modeleditor/components/entry-collection/entry-element';
import { SortOrder } from './sort-order.enum';

@Component({
  selector: 'app-sort-menu',
  templateUrl: './sort-menu.component.html',
  styleUrls: ['./sort-menu.component.scss'],
})
export class SortMenuComponent {
  @Input() element: EntryElement;

  sortOrder = SortOrder;

  toggleFilter(): void {
    if (this.element.getSortOrder() === SortOrder.asc) {
      this.element.setSortOrder(SortOrder.desc);
    } else if (this.element.getSortOrder() === SortOrder.desc) {
      this.element.setSortOrder(SortOrder.none);
    } else {
      this.element.setSortOrder(SortOrder.asc);
    }
  }
}
