import { CdkDropList } from '@angular/cdk/drag-drop';
import { Injectable } from '@angular/core';
import { Template } from 'frontend/src/dashboard/model/resource/template';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DragNDropService {
  private dragTarget: BehaviorSubject<Template> = new BehaviorSubject<Template>(null);
  private dragSource: BehaviorSubject<Template> = new BehaviorSubject<Template>(null);
  private dragSources: BehaviorSubject<Template[]> = new BehaviorSubject<Template[]>([]);
  private dropLists: {
    [key: string]: { list: CdkDropList; template: Template };
  } = {};

  public registerDropList(id: string, list: CdkDropList, template: Template): void {
    this.dropLists[id] = { list, template };
  }

  public unRegisterDropList(id: string): void {
    delete this.dropLists[id];
  }

  public isOverContainer(ev): boolean {
    const match: string = Object.keys(this.dropLists).find((id: string) => {
      const el: CdkDropList = this.dropLists[id].list;

      return el._dropListRef._isOverContainer(ev.pointerPosition.x, ev.pointerPosition.y) ? true : false;
    });

    if (match) {
      this.setCurrentDragTarget(this.dropLists[match].template);
      return true;
    }

    this.setCurrentDragTarget(null);

    return false;
  }

  public setCurrentDragTarget(target: Template): void {
    this.dragTarget.next(target);
  }

  public getCurrentDragTarget(): Observable<Template> {
    return this.dragTarget.asObservable();
  }

  public setDragSource(dragSource: Template): void {
    this.dragSource.next(dragSource);
  }

  public getDragSource(): Observable<Template> {
    return this.dragSource.asObservable();
  }

  public setDragSources(...t: Template[]): void {
    const list: Template[] = (this.dragSources.getValue() || [])
      .concat(t)
      .filter(
        (t: Template, index: number, l: Template[]) =>
          l.findIndex((innerT: Template) => innerT.getId() === t.getId()) === index
      );
    this.dragSources.next(list);
  }

  public getDragSources(): Observable<Template[]> {
    return this.dragSources.asObservable();
  }

  constructor() {}
}
