import { RxPush } from '@rx-angular/template/push';
import { RxLet } from '@rx-angular/template/let';
import { RxFor } from '@rx-angular/template/for';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Store } from '@ngxs/store';
import { RxState } from '@rx-angular/state';
import { Notification, NotificationState } from '../../data-access/notification.store';
import { NotificationItemComponent } from '../notification-item/notification-item.component';
import { NOTIFICATION_OVERLAY_ANIMATION } from './notification-overlay.animation';

interface LocalState {
  notification: Notification[];
}

const INITIAL_LOCAL_STATE: LocalState = {
  notification: [],
};

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, NotificationItemComponent, RxLet, RxPush, RxFor],
  selector: 'notification-overlay',
  templateUrl: './notification-overlay.component.html',
  styleUrls: ['./notification-overlay.component.scss'],
  providers: [RxState],
  animations: [NOTIFICATION_OVERLAY_ANIMATION],
})
export class NotificationComponent {
  private readonly state: RxState<LocalState> = inject(RxState<LocalState>);
  private readonly store: Store = inject(Store);

  state$ = this.state.select();

  constructor() {
    this.state.set(INITIAL_LOCAL_STATE);
    this.state.connect('notification', this.store.select(NotificationState.notification));
  }

  trackMessages(index: number, m: Notification) {
    return m.id;
  }
}
