import { Injectable } from '@angular/core';
import { Action } from '@app-modeleditor/components/button/action/action';
import { TemplateBackdropService } from '@app-modeleditor/components/template-backdrop/template-backdrop.service';
import { ERequestMethod, RequestService } from '@app-modeleditor/request.service';
import { ConfigService } from '@core/config/config.service';
import { Select } from '@ngxs/store';
import { Template } from 'frontend/src/dashboard/model/resource/template';
import { IUserSettings, UserService } from 'frontend/src/dashboard/user/data-access/user.service';
import { EUserSettingsKey } from 'frontend/src/dashboard/user/user-seetings-key.enum';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, finalize, switchMap, tap } from 'rxjs/operators';
import { AuthState } from '../login/data-access/auth.state';
import { User } from '../user/user';
import { TemplateAdapter } from './../modeleditor/utils/template-factory.service';
import { MenuItem } from './template-toolbar/menu-item';
import { Toolbar } from './template-toolbar/toolbar';
import { ToolbarGroup } from './template-toolbar/toolbar-group';
import { EToolbarItemType } from './template-toolbar/toolbar-item-type';

@Injectable({
  providedIn: 'root',
})
export class ViewService {
  private pageContent: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public menuToggleState: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private currentActiveStyle = new BehaviorSubject<any>(null);

  // handles application menu
  private appMenu: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  // handles current active menu item
  private currentActiveMenuItem: MenuItem;
  private appToolbar: BehaviorSubject<Toolbar> = new BehaviorSubject<Toolbar>(null);
  private usersettings: IUserSettings;

  @Select(AuthState.user) user$!: Observable<User>;

  public getCurrentTheme = () => this.currentActiveStyle.asObservable().pipe(distinctUntilChanged());

  constructor(
    private configApi: ConfigService,
    private templateBackdropService: TemplateBackdropService,
    private requestApi: RequestService,
    private userApi: UserService,
    private templateFactory: TemplateAdapter
  ) {
    this.getCurrentTheme().subscribe((style) => {
      this.userApi.setKey(this.usersettings, EUserSettingsKey.theme, style).subscribe();
    });

    this.userApi.getUserSettings().subscribe((usersettings: IUserSettings) => {
      this.usersettings = usersettings;

      // apply default theme
      this.userApi
        .getKey(this.usersettings, EUserSettingsKey.theme)
        .pipe(distinctUntilChanged())
        .subscribe((theme) => this.changeStyle(theme ?? this.configApi.access().theme?.defaultTheme));

      this.setAppToolbar(this.appToolbar.getValue());
    });

    this.user$
      .pipe(
        tap((u) => {
          if (!u) {
            this.templateBackdropService.hideBackdrop();
          }
        }),
        filter((u) => u !== null)
      )
      .subscribe((u) => {
        this.requestApi.call(ERequestMethod.GET, `rest/template/applicationmenu`).subscribe((appMenu) => {
          this.appMenu.next(appMenu);
        });
      });
  }

  /**
   * set current active toolbar group
   * @returns void
   */
  public toggleActiveMenuItem(groupId: string, state?: boolean): void {
    const t: Toolbar = this.appToolbar.getValue();
    if (!t) {
      return;
    }
    if (
      state === false ||
      (state !== true && this.currentActiveMenuItem && this.currentActiveMenuItem.getId() === groupId)
    ) {
      this.currentActiveMenuItem = null;
      this.saveLastStateOfToolbar(false).subscribe();
    } else {
      const item: MenuItem = t.getMenuItems().find((m: MenuItem) => m.getId() === groupId);
      this.currentActiveMenuItem = item;
      this.saveLastStateOfToolbar(true).subscribe();
    }
    this.setAppToolbar(t);
  }

  lastStateOfToolbar: boolean;

  public isLastStateOfToolbarOpen() {
    return this.lastStateOfToolbar;
  }
  public saveLastStateOfToolbar(state: boolean) {
    this.lastStateOfToolbar = state;
    if (!this.usersettings) {
      return of(null);
    }

    return this.userApi
      .setKey(this.usersettings, EUserSettingsKey.TOOLBAR_OPEN_STATE, state)
      .pipe(tap((usersettings: IUserSettings) => (this.usersettings = usersettings)));
  }

  private saveShortcuts(t: Toolbar): Observable<IUserSettings> {
    if (!t || !this.usersettings) {
      return of(null);
    }

    return this.userApi
      .setKey(this.usersettings, EUserSettingsKey.TOOLBAR_SHORTCUTS, t.getShortcuts())
      .pipe(tap((usersettings: IUserSettings) => (this.usersettings = usersettings)));
  }

  private applySettings(t: Toolbar): Observable<string[]> {
    if (!t || !this.usersettings) {
      return of(null);
    }

    return this.userApi.getKey(this.usersettings, EUserSettingsKey.TOOLBAR_OPEN_STATE).pipe(
      switchMap((state: boolean) => {
        return this.userApi.getKey(this.usersettings, EUserSettingsKey.TOOLBAR_SHORTCUTS).pipe(
          tap((shortcuts: string[]) => {
            this.lastStateOfToolbar = state || false;
            t.setShortcuts(shortcuts);
          })
        );
      })
    );

    // return this.userApi.getKey(this.usersettings, EUserSettingsKey.TOOLBAR_SHORTCUTS).pipe(tap((shortcuts: string[]) => {

    //   this.userApi.getKey(this.usersettings, EUserSettingsKey.TOOLBAR_OPEN_STATE).pipe(tap((state: boolean) => {
    //   }));
    //   /**
    //    * const templates: EntryElement[] = (shortcuts || []).map((sId: string) =>
    //     t.getMenuItems().find((m: MenuItem) =>
    //       m.getToolbarGroups().find((g: ToolbarGroup) =>
    //         g.getEntryElements().find((e: EntryElement) => e.getId() === sId))));
    //         */
    //   t.setShortcuts(shortcuts);
    // }));
  }

  /**
   * toggle states of all given elements
   * @param ids string[]
   * @param state boolean
   * @returns void
   */
  public toggleElementStates(ids: string[], state: boolean): void {
    const t: Toolbar = this.appToolbar.getValue();
    if (!t) {
      return;
    }
    t.getMenuItems().forEach((m: MenuItem) => {
      if (ids.indexOf(m.getId()) !== -1) {
        return m.setDisabled(state);
      }

      m.getToolbarGroups().forEach((g: ToolbarGroup) => {
        if (ids.indexOf(g.getId()) !== -1) {
          return g.setDisabled(state);
        }

        g.getEntryElements().forEach((e: Template) => {
          if (ids.indexOf(t.getId()) !== -1) {
            return t.setDisabled(state);
          }
        });
      });
    });

    this.setAppToolbar(t);
  }

  /**
   * set an toolbar element
   * @param element Template
   * @returns void
   */
  public setAppToolbarElement(element: Template): void {
    const t: Toolbar = this.appToolbar.getValue();
    if (!t) {
      return;
    }
    t.getMenuItems().forEach((m: MenuItem) => {
      m.getToolbarGroups().forEach((g: ToolbarGroup) => {
        if (g.getEntryElements().find((e: Template) => e.getId() === element.getId())) {
          g.setEntryElements(g.getEntryElements().filter((e: Template) => e.getId() !== element.getId()));
        }
      });
    });
    this.setAppToolbar(t);
  }

  /**
   * deletes an toolbar element by its id
   * @param id string
   * @returns void
   */
  public deleteAppToolbarElement(id: string): void {
    const t: Toolbar = this.appToolbar.getValue();
    if (!t) {
      return;
    }
    t.getMenuItems().forEach((m: MenuItem) => {
      m.getToolbarGroups().forEach((g: ToolbarGroup) => {
        g.setEntryElements(g.getEntryElements().filter((e: Template) => e.getId() !== id));
      });
    });

    this.setAppToolbar(t);
  }

  /**
   * adds a group to a given toolbar item
   * @param itemId string id of
   * @param group ToolbarGroup
   * @returns void
   */
  public setAppToolbarGroup(itemId: string, group: ToolbarGroup): void {
    const t: Toolbar = this.appToolbar.getValue();
    if (!t) {
      return;
    }
    t.setMenuItems(
      t.getMenuItems().map((m: MenuItem) => {
        return m.getId() === itemId
          ? m.setToolbarGroups(
              m
                .getToolbarGroups()
                .filter((g: ToolbarGroup) => g.getId() !== group.getId())
                .concat(group)
            )
          : m;
      })
    );
  }

  /**
   * deletes an toolbar group
   * @param id string
   * @returns void
   */
  public deleteAppToolbarGroup(id: string): void {
    const t: Toolbar = this.appToolbar.getValue();
    if (!t) {
      return;
    }
    t.setMenuItems(
      t
        .getMenuItems()
        .map((m: MenuItem) => m.setToolbarGroups(m.getToolbarGroups().filter((g: ToolbarGroup) => g.getId() !== id)))
    );
  }

  /**
   * deletes an toolbar item by its id
   * @param id string
   * @returns void
   */
  public deleteAppToolbarItem(id: string): void {
    const t: Toolbar = this.appToolbar.getValue();
    if (!t) {
      return;
    }
    const item = t.getMenuItems().find((mItem: MenuItem) => mItem.getId() === id);
    t.setMenuItems(t.getMenuItems().filter((mItem: MenuItem) => mItem.getId() !== id));
    if (this.currentActiveMenuItem && this.currentActiveMenuItem.getId() === id) {
      this.currentActiveMenuItem = t
        .getMenuItems()
        .find((m: MenuItem) => m.getToolbarItemType() === EToolbarItemType.GROUP);
    }

    this.setAppToolbar(t);
  }

  public toggleShortcut(id: string): void {
    const t: Toolbar = this.appToolbar.getValue();
    if (!t) {
      return;
    }
    let cuts: string[] = [];

    if (t.getShortcuts().find((shortcutId: string) => shortcutId === id)) {
      cuts = t.getShortcuts().filter((m: string) => m !== id);
    } else {
      cuts = t.getShortcuts().concat(id);
    }

    t.setShortcuts(cuts.filter((m: string) => m !== undefined && m !== null));

    this.saveShortcuts(t).subscribe(() => {
      this.setAppToolbar(t);
    });
  }

  /**
   * overrides an tollbar item
   * @param item Menuitem
   * @returns void
   */
  public setAppToolbarItem(item: MenuItem): void {
    const t: Toolbar = this.appToolbar.getValue();
    if (!t) {
      return;
    }
    t.setMenuItems(
      t
        .getMenuItems()
        .filter((mItem: MenuItem) => mItem.getId() !== item.getId())
        .concat(item)
    );
    this.setAppToolbar(t);
  }

  /**
   * set toolbar
   * @param toolbar Toolbar
   * @returns void
   */
  public setAppToolbar(toolbar?: Toolbar): void {
    if (!toolbar) {
      return;
    }

    if (this.lastStateOfToolbar && !this.currentActiveMenuItem) {
      this.currentActiveMenuItem = toolbar
        .getMenuItems()
        .find((m: MenuItem) => m.getToolbarItemType() === EToolbarItemType.GROUP);
    }

    this.applySettings(toolbar).subscribe(() => {
      toolbar.setTitle(this.configApi.access().title.label);
      toolbar.setTitleColor(this.configApi.access().title.color);
      toolbar.setActiveMenuItem(this.currentActiveMenuItem);
      this.appToolbar.next(
        this.setCurrentActivePage(toolbar, this.pageContent.getValue() ? this.pageContent.getValue().url : null)
      );
    });
  }

  private setCurrentActivePage(t: Toolbar, url: string): Toolbar {
    if (!t) {
      return t;
    }
    t.setMenuItems(
      t.getMenuItems().map((m: MenuItem) => {
        if (m.getToolbarItemType() !== EToolbarItemType.NAVIGATOR) {
          return m;
        }

        const match: Action = m
          .getNavigationElement()
          .getChain()
          .getActions()
          .find((a: Action) => {
            return a.getActionUrl() === url;
          });
        m.setActive(match ? true : false);

        return m;
      })
    );

    return t;
  }

  /**
   * get changes on toolbar
   * @returns Observable<Toolbar>
   */
  public getAppToolbar(): Observable<Toolbar> {
    return this.appToolbar.asObservable();
  }

  public changeStyle(style: any): void {
    this.currentActiveStyle.next(style);
  }

  /**
   * get template by url and switch page
   * @param templateUrl url to template
   */
  public getPageByTemplate(templateUrl: string, initial?: boolean, contentPage = null): Observable<any> {
    if (!templateUrl) {
      return of(null);
    }

    return (contentPage ? of(contentPage) : this.requestApi.call(ERequestMethod.GET, `rest/${templateUrl}`))
      .pipe(
        tap((template) => {
          this.pageContent.next({ page: this.templateFactory.adapt(template), url: templateUrl, initial });
        })
      )
      .pipe(
        finalize(() => {
          // check current active navigation element
          this.setAppToolbar(this.setCurrentActivePage(this.appToolbar.getValue(), templateUrl));
        })
      );
  }

  /**
   * change state of settings menu
   * @param state state
   */
  public toggleSettings(state: boolean): void {
    if (state === null) {
      state = !this.menuToggleState.value;
    }

    this.menuToggleState.next(state);
  }

  /**
   * subscribe to state of settings menu
   */
  public getSettingsMenuState(): Observable<boolean> {
    return this.menuToggleState.asObservable();
  }

  public getPageContent(): Observable<any> {
    return this.pageContent.asObservable();
  }

  /**
   * get applicationmenu
   */
  public getApplicationMenu(): Observable<any> {
    return this.appMenu.asObservable();
  }
}
