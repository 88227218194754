import { ComponentEvent } from '../../../history/component-event';
import { GanttShiftComponentsDataHandler, GanttShiftComponentsDataItem } from '../shift-components-data-handler';

/**
 * @constructor
 * @class
 * @extends ComponentEvent
 * @requires ComponentEvent
 * @plugin shift-components
 * */
export class GanttShiftComponentsEvent extends ComponentEvent {
  ref: GanttShiftComponentsDataHandler;

  constructor() {
    super();
    /**
     * @type {GanttShiftComponentsDataHandler}
     */
    this.ref = null;
  }

  /**
   * @override
   * @returns {boolean} true if undo was succesfull, else false
   */
  undo() {
    const s = this;

    function handle(type) {
      const types = {
        'create-new-shiftCompGroup': function () {
          return s._undoCreateComponents();
        },
        'add-to-exiting-group': function () {
          return s._undoAddToExistingGroup();
        },
        'merge-into-one-group': function () {
          return s._undoMergeIntoOneGroup();
        },
        'merge-SEVERAL-into-one-group': function () {
          return s._undoMergeSeveralIntoOneGroup();
        },
        'remove-components': function () {
          return s._undoRemoveComponents();
        },
        default: function () {
          console.warn('can not undo: ' + type + '. May not be implemented yet.');
          return false;
        },
      };
      return (types[type] || types['default'])();
    }
    return handle(s.eventName);
  }

  /**
   * @override
   * @returns {boolean} true if redo was succesfull, else false
   */
  redo() {
    const s = this;
    function handle(type) {
      const types = {
        'create-new-shiftCompGroup': function () {
          return s._redoCreateComponents();
        },
        'add-to-exiting-group': function () {
          return s._redoAddToExistingGroup();
        },
        'merge-into-one-group': function () {
          return s._redoMergeIntoOneGroup();
        },
        'merge-SEVERAL-into-one-group': function () {
          return s._redoMergeSeveralIntoOneGroup();
        },
        'remove-components': function () {
          return s._redoRemoveComponents();
        },
        default: function () {
          console.warn('can not redo: ' + type + '. May not be implemented yet.');
          return false;
        },
      };
      return (types[type] || types['default'])();
    }
    return handle(s.eventName);
  }

  /**
   * @overrides
   * @return {String}
   */
  getEventDescription() {
    const s = this;
    function handle(type) {
      const types = {
        'create-new-shiftCompGroup': function () {
          return GanttShiftComponentsEventDescription.getCreateNewComponentsDescription(
            s.ref.ganttDiagram.getConfig().getLanguage(),
            s.arguments
          );
        },
        /* 'add-to-exiting-group' : function(){ return "" },
        'merge-into-one-group' : function(){ return "" },
        'merge-SEVERAL-into-one-group' : function(){ return "" },
        'remove-components' : function() { return "" }, */
        default: function () {
          console.warn('no description for: ' + s.eventName + ' set. May not be implemented yet.');
          return '';
        },
      };
      return (types[type] || types['default'])();
    }
    return handle(s.eventName);
  }

  private _undoCreateComponents() {
    const s = this;
    const success = s.ref.removeComponentGroupByComponentGroupID(s.arguments[1]);
    s.arguments[0].reRenderEdges();
    return success;
  }
  private _redoCreateComponents() {
    const s = this;
    s.ref.shiftComponents.push(
      new GanttShiftComponentsDataItem(s.arguments[1] /*, JSON.parse(JSON.stringify(s.arguments[2])*/)
    );
    s.arguments[0].reRenderEdges();
    return true;
  }

  private _undoAddToExistingGroup() {
    const s = this;
    const shiftComponents = s.ref.getShiftComponents();
    const oldID = s.arguments[1].id;
    const newID = s.arguments[2];
    const oldGroup = s.arguments[1].group;

    const groupIndex = s.ref.getComponentGroupIndexByComponentGroupID(newID);
    s.arguments[0].getShiftComponentChainHandler().removeComponentGroupFromGroupChaining(newID);

    shiftComponents[groupIndex] = new GanttShiftComponentsDataItem(oldID, JSON.parse(JSON.stringify(oldGroup)));
    s.arguments[0].reRenderEdges();
    return true;
  }
  private _redoAddToExistingGroup() {
    const s = this;

    const shiftComponents = s.ref.getShiftComponents();
    const oldID = s.arguments[1].id;
    const newID = s.arguments[2];
    const newGroup = s.arguments[3];

    const groupIndex = s.ref.getComponentGroupIndexByComponentGroupID(oldID);
    s.arguments[0].getShiftComponentChainHandler().removeComponentGroupFromGroupChaining(oldID);

    shiftComponents[groupIndex] = new GanttShiftComponentsDataItem(newID, JSON.parse(JSON.stringify(newGroup)));
    s.arguments[0].reRenderEdges();
    return true;
  }

  private _undoMergeIntoOneGroup() {
    const s = this;

    // TODO: remove Chaining fuer [2]
    const shiftComponents = s.ref.getShiftComponents();
    shiftComponents.forEach((obj) =>
      s.arguments[0].getShiftComponentChainHandler().removeComponentGroupFromGroupChaining(obj.id)
    );

    s.ref._setShiftComponents(s.arguments[1]);
    s.arguments[0].reRenderEdges();
    return true;
  }

  private _redoMergeIntoOneGroup() {
    const s = this;

    const shiftComponents = s.ref.getShiftComponents();
    shiftComponents.forEach((obj) =>
      s.arguments[0].getShiftComponentChainHandler().removeComponentGroupFromGroupChaining(obj.id)
    );

    s.ref._setShiftComponents(s.arguments[2]);
    s.arguments[0].reRenderEdges();
    return true;
  }

  private _undoMergeSeveralIntoOneGroup() {
    const s = this;
    return s._undoMergeIntoOneGroup();
  }

  private _redoMergeSeveralIntoOneGroup() {
    const s = this;
    return s._redoMergeIntoOneGroup();
  }

  private _undoRemoveComponents() {
    const s = this;
    const oldGroups = JSON.parse(JSON.stringify(s.arguments[1]));
    const removedMap = new Set(s.arguments[2]);

    const removedGroups = []; // groups that had all components removed and thus were destroyed
    // figure out which groups were deleted because all their components were deleted
    for (let i = 0; i < oldGroups.length; i++) {
      let groupLength = oldGroups[i].group.group.length;
      for (let j = 0; j < oldGroups[i].group.group.length; j++) {
        if (removedMap.has(oldGroups[i].group.group[j])) groupLength--;
      }
      if (groupLength === 0) removedGroups.push(oldGroups[i]);
    }

    const notRemovedGroups = oldGroups.filter((group) => !removedGroups.includes(group));

    const shiftComponents = s.ref.getShiftComponents();
    // recreate / insert component groups first, so we dont mess up index order / access
    removedGroups.forEach((group) => shiftComponents.splice(group.index, 0, group.group));

    // replace remaining removed groups with old components
    notRemovedGroups.forEach((group) => shiftComponents.splice(group.index, 1, group.group));

    s.arguments[0].reRenderEdges();

    return true;
  }
  private _redoRemoveComponents() {
    const s = this;
    for (
      let i = 0;
      i < s.arguments[2].length;
      i++ // delete all selected Components
    )
      s.ref.removeComponentByComponentID(s.arguments[2][i]);

    s.ref._findAndRemoveAllEmptyGroups();
    s.arguments[0].reRenderEdges();
    return true;
  }
}

// GETTER and SETTER inherited from Main Class

export class GanttShiftComponentsEventDescription {
  private constructor() {}

  static getCreateNewComponentsDescription(language, args) {
    const s = this;
    let description;

    let shiftString = '';

    const stringBuilder = function (element, index) {
      if (index === 0) shiftString += element;
      shiftString += ', ' + element;
    };

    args[2].forEach(stringBuilder);

    if (language === 'DE') {
      description =
        'Schicht-Gruppe ' + args[1] + ' \n erstellt mit den Schichten \n' + shiftString + ' als Komponenten.';
    }
    if (language === 'EN')
      description = 'Creating shift-group ' + args[1] + ' \n with shifts \n' + shiftString + ' as components.';
    return description;
  }
}
