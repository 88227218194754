import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { FlatTreeControl } from '@angular/cdk/tree';
import {
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { HierarchicalMenuItem } from '@app-modeleditor/components/template-ui/hierarchical-menu-item';
import { TemplateComponent } from '@app-modeleditor/components/template/template.component';
import { GlobalUtils } from 'frontend/src/dashboard/global-utils';
import { ResizeEvent } from 'frontend/src/dashboard/view/resize/resize-event';
import { ResizeService } from 'frontend/src/dashboard/view/resize/resize.service';
import { Observable, Subject, of } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { FileDatabase } from './tree-database.service';
const RENDERING_OFFSET = 100;

@Component({
  selector: 'template-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.scss'],
  providers: [],
})
export class TreeComponent implements OnChanges, OnDestroy {
  @Input() root: HTMLElement;
  @Input() nodes: HierarchicalMenuItem[];
  selectedNode: HierarchicalMenuItem;
  private componentId: string = GlobalUtils.generateUUID();
  @Input() set selected(selected: HierarchicalMenuItem) {
    this.selectedNode = selected;
    this.selectNode(this.dataSource ? this.dataSource.data : []);
  }
  @Output() allcollapsed = new EventEmitter<boolean>();
  @Output() nodeDrag: EventEmitter<any> = new EventEmitter<any>();
  treeFlattener: MatTreeFlattener<HierarchicalMenuItem, HierarchicalMenuItem>;
  dataSource: MatTreeFlatDataSource<HierarchicalMenuItem, HierarchicalMenuItem>;
  @ContentChild('tItem') tItem: TemplateRef<any>;
  private _ngNext: Subject<void> = new Subject<void>();
  private resizeId: string;
  public allNodesCollapsed = false;

  @ViewChild(CdkVirtualScrollViewport) virtualScroller: CdkVirtualScrollViewport;
  @ViewChild('wrapperContainer') wrapperContainer: ElementRef;

  constructor(public database: FileDatabase, private _cd: ChangeDetectorRef, private _resizeApi: ResizeService) {
    this.initialize();
  }

  selectNode(nodes: HierarchicalMenuItem[]): void {
    nodes.forEach((n: HierarchicalMenuItem) => {
      n.setSelected(this.selectedNode && this.selectedNode.getId() === n.getId() ? true : false);
      this.selectNode(n.getHierachicMenuItems());
    });
  }

  getLevel(level: number): number[] {
    return Array.from({ length: level }).map((i, idx) => idx);
  }

  /**
   * checks whether a given node is expanded or not
   * @param node FileFlatNode
   */
  isExpanded(node: HierarchicalMenuItem): boolean {
    const expanded: boolean = this.database.treeControl.isExpanded(node);
    this.getNode(this.dataSource.data, node.getId()).setVisibility(expanded);
    return expanded;
  }

  /**
   * gets node in tree
   * @param list FileNode[]
   * @param id string
   * @returns FileNode
   */
  private getNode(list: HierarchicalMenuItem[], id: string): HierarchicalMenuItem {
    for (const node of list) {
      if (node.getId() === id) {
        return node;
      }

      const match = this.getNode(node.getHierachicMenuItems(), id);
      if (match) {
        return match;
      }
    }
    return null;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.nodes) {
      this._rerender(true);
    }
  }

  ngAfterViewInit(): void {
    this.init();
  }

  init(): void {
    this._resizeApi.complete(this.resizeId);
    if (!this.wrapperContainer) {
      return;
    }
    this.resizeId = this._resizeApi.create(this.wrapperContainer.nativeElement, (ev: ResizeEvent) => {
      this.virtualScroller?.checkViewportSize();
    });
  }

  private _rerender(init?: boolean) {
    this.forceRefresh = true;
    this._cd.detectChanges();
    if (init) {
      this.initialize();
    }
    this.forceRefresh = false;
  }

  get numTreeNodes(): number {
    return document.querySelectorAll('.node').length;
  }

  ngOnDestroy(): void {
    this._ngNext.next();
    this._ngNext.complete();
    this._resizeApi.complete(this.resizeId);
  }

  initialize(): void {
    this.treeFlattener = new MatTreeFlattener(this.transformer, this._getLevel, this._isExpandable, this._getChildren);

    this.database.treeControl = new FlatTreeControl<HierarchicalMenuItem>(this._getLevel, this._isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.database.treeControl, this.treeFlattener);

    this.database.initialize(this.nodes);

    this._ngNext.next();
    this.database.dataChange.pipe(takeUntil(this._ngNext)).subscribe((data: HierarchicalMenuItem[]) => {
      this.dataSource.data = data;
      this.selectNode(this.dataSource.data);
    });
  }

  trackByFn(index: number, hmi: HierarchicalMenuItem) {
    return hmi.getId();
  }

  /**
   * after slot for tree nodee gets rendered
   * @param instance TemplateComponent
   * @param item HierarchicalMenuItem
   * @returns void
   */
  afterInitSlot(instance: ComponentRef<TemplateComponent>, item: HierarchicalMenuItem): void {}

  forceRefresh: boolean;
  public onExpand(event: MouseEvent, node: HierarchicalMenuItem): void {
    node.setExpanded(!node.isExpanded());
    if (node.isExpanded()) {
      this.database.treeControl.expand(node);
    } else {
      this.database.treeControl.collapse(node);
    }
    this.database.toggleNode(node).subscribe();
    this.allcollapsed.emit(this.isAllCollapsed());
  }

  hasChild = (_: number, _nodeData: HierarchicalMenuItem) => _nodeData.isExpandable();

  transformer = (node: HierarchicalMenuItem, level: number) => {
    if (node.isExpanded() === true) {
      if (this.database.treeControl.isExpanded(node) !== true) {
        this.database.treeControl.expand(node);
        // this.database.toggleNode(node).subscribe();
      }
    }
    return node;
  };

  private _getLevel = (node: HierarchicalMenuItem): number => node.getLevel();

  private _isExpandable = (node: HierarchicalMenuItem): boolean => node.isExpandable();

  private _getChildren = (node: HierarchicalMenuItem): Observable<HierarchicalMenuItem[]> =>
    of(node.getHierachicMenuItems());

  /**
   * collapse or expand all nodes with childs
   */
  public onToggleAllNodes(): void {
    // collapse all open nodes - Function
    const collapseNode = (node: HierarchicalMenuItem) => {
      if (node.isExpanded()) {
        this.database
          .toggleNode(node)
          .pipe(first())
          .subscribe(() => {
            this._getChildren(node)
              .pipe(first())
              .subscribe((nodes) =>
                nodes.forEach((node) => {
                  collapseNode(node);
                })
              );
            node.setExpanded(false);
            this.database.treeControl.collapse(node);
          });
      }
    };

    // expand all nodes - Function
    const expandNode = (node: HierarchicalMenuItem) => {
      if (!node.isExpanded() && node.isExpandable()) {
        this.database.treeControl.expand(node);
        this.database
          .toggleNode(node)
          .pipe(first())
          .subscribe(() => {
            node.setExpanded(true);
            this._getChildren(node)
              .pipe(first())
              .subscribe((nodes) =>
                nodes.forEach((node) => {
                  expandNode(node);
                })
              );
          });
      }
    };

    if (!this.isAllCollapsed()) {
      this.dataSource.data.forEach((node) => {
        collapseNode(node);
      });
      this.allcollapsed.emit(true);
    } else {
      this.dataSource.data.forEach((node) => {
        expandNode(node);
      });
      // # expand all - does not setExpanded
      // this.database.treeControl.expandAll();
      this.allcollapsed.emit(false);
    }
  }

  /**
   * check if all nodes are collapsed
   * @returns {boolean} collapsed
   */
  public isAllCollapsed(): boolean {
    let value = true;
    this.dataSource.data.forEach((node) => {
      if (node.isExpanded()) {
        value = false;
      }
    });
    return value;
  }
}
