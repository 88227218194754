import { Observable, of } from 'rxjs';
import { GanttPredefinedLocalAction } from '../predefined-local-action';
import { EPredefinedAction } from '@app-modeleditor/components/button/action/predefined-action.enum';
import { GanttPluginHandlerService } from '../../../../plugin/gantt-plugin-handler.service';
import { GanttLibService } from 'frontend/src/dashboard/gantt/gantt/gantt-lib.service';

export class GanttLocalActionToggleBrokenConstraints extends GanttPredefinedLocalAction {
  constructor(ganttPluginHandlerService: GanttPluginHandlerService, ganttLibService: GanttLibService) {
    super(ganttPluginHandlerService, ganttLibService);
    this.name = EPredefinedAction.GANTT_TOGGLE_BROKEN_CONSTRAINTS;
  }

  public executeAction(action: any): Observable<any> {
    if (action.id !== this.name) return of(null);

    // toggle visibility of broken constraints
    const backendMapper = this.ganttLibService.backendToGanttOriginInputMapper;
    backendMapper.mapBrokenConstraintsToTooltips = !backendMapper.mapBrokenConstraintsToTooltips;

    // update gantt tooltips
    this.ganttPluginHandlerService.getGanttTooltipService().updateTooltipsOfJsGantt();

    return of(null);
  }
}
