import * as d3 from 'd3';
import { ETimeFormatWeekdayStrategy, GanttXAxisFormatGeneral, TimeFormatterAxisFormat } from './x-axis-format-general';

/**
 * @description Predifend x-Axis #3. <br>
 * **High** level of detail.
 * @extends {GanttXAxisFormatGeneral}
 */
export class GanttXAxisFormat3 extends GanttXAxisFormatGeneral {
  constructor() {
    super(null);

    this.cssClassNameText = 'x_axis_text_bottom';
    this.renderVerticalLinesOnShifts = true;

    this.dateFormatList = [
      {
        value: 1,
        time: [new TimeFormatterAxisFormat('%b', d3.timeMonth.every(1), 'MONTH')],
      },
      {
        value: 2,
        time: [new TimeFormatterAxisFormat('%B', d3.timeMonth.every(1), 'MONTH')],
      },
      {
        value: 5.3,
        time: [new TimeFormatterAxisFormat('%V KW', d3.timeMonday.every(1), 'CALENDAR_WEEK')],
      },
      {
        value: 18,
        time: [new TimeFormatterAxisFormat('%d', d3.timeDay.every(1), 'DAY', ETimeFormatWeekdayStrategy.AXIS, '%a')],
      },
      {
        value: 32,
        time: [new TimeFormatterAxisFormat('%d%b', d3.timeDay.every(1), 'DAY', ETimeFormatWeekdayStrategy.AXIS, '%a')],
      },
      {
        value: 35,
        time: [new TimeFormatterAxisFormat('%d %b', d3.timeDay.every(1), 'DAY', ETimeFormatWeekdayStrategy.AXIS, '%a')],
      },
      {
        value: 40,
        time: [new TimeFormatterAxisFormat('%d %b', d3.timeDay.every(1), 'DAY', ETimeFormatWeekdayStrategy.AXIS, '%a')],
      },
      {
        value: 60,
        time: [
          new TimeFormatterAxisFormat('%d %b %Y', d3.timeDay.every(1), 'DAY', ETimeFormatWeekdayStrategy.AXIS, '%a'),
        ],
      },
      {
        value: 100,
        time: [new TimeFormatterAxisFormat('%H:00', d3.timeHour.every(8), 'HOUR')],
      },
      {
        value: 140,
        time: [new TimeFormatterAxisFormat('%H:00', d3.timeHour.every(6), 'HOUR')],
      },
      {
        value: 220,
        time: [new TimeFormatterAxisFormat('%H:00', d3.timeHour.every(4), 'HOUR')],
      },
      {
        value: 330,
        time: [new TimeFormatterAxisFormat('%H:00', d3.timeHour.every(2), 'HOUR')],
      },
      {
        value: 760,
        time: [new TimeFormatterAxisFormat('%H:00', d3.timeHour.every(1), 'HOUR')],
      },
      {
        value: 1200,
        time: [new TimeFormatterAxisFormat('%H:%M', d3.timeMinute.every(30), 'MINUTE')],
      },
      {
        value: 2400,
        time: [new TimeFormatterAxisFormat('%H:%M', d3.timeMinute.every(15), 'MINUTE')],
      },
      {
        value: 7800,
        time: [new TimeFormatterAxisFormat('%H:%M', d3.timeMinute.every(10), 'MINUTE')],
      },
      {
        value: 16000,
        time: [new TimeFormatterAxisFormat('%H:%M', d3.timeMinute.every(5), 'MINUTE')],
      },
      {
        value: 54000,
        time: [new TimeFormatterAxisFormat('%H:%M', d3.timeMinute.every(1), 'MINUTE')],
      },
      {
        value: 130000,
        time: [new TimeFormatterAxisFormat('%H:%M:%S', d3.timeSecond.every(30), 'SECOND')],
      },
      {
        value: 260000,
        time: [new TimeFormatterAxisFormat('%H:%M:%S', d3.timeSecond.every(15), 'SECOND')],
      },
      {
        value: 700000,
        time: [new TimeFormatterAxisFormat('%H:%M:%S', d3.timeSecond.every(5), 'SECOND')],
      } /* ,
      {
        value: 150000,
        time: [new TimeFormatterAxisFormat("%M:%S", [d3.timeSecond, 5], "SECOND")]
      } */,
    ];
  }
}
