<div class="mice-cinema-discrete-axis">
  <div class="mice-cinema-discrete-axis-steering-btns">
    <button mat-icon-button (click)="onFastRevert()" [disabled]="!running">
      <mat-icon>fast_rewind</mat-icon>
    </button>
    <button mat-icon-button (click)="onPlay()">
      <mat-icon>play_arrow</mat-icon>
    </button>
    <button mat-icon-button (click)="onPause()" [disabled]="!running">
      <mat-icon>pause</mat-icon>
    </button>
    <button mat-icon-button (click)="onStop()">
      <mat-icon>stop</mat-icon>
    </button>
    <button mat-icon-button (click)="onFastForward()" [disabled]="!running">
      <mat-icon>fast_forward</mat-icon>
    </button>
  </div>
  <div class="mice-cinema-discrete-axis-clock">
    {{ timestamp | millisecondsToDate : true | date : 'yyyy-MM-dd HH:mm' }}
  </div>
  <button mat-icon-button class="mice-cinema-discrete-axis-change-position-btn" [disabled]="_eventPosition == 0">
    <mat-icon>keyboard_arrow_left</mat-icon>
  </button>
  <div class="mice-cinema-discrete-axis-list" #eventItemsWrapper>
    <div
      *ngFor="let eventItem of reducedEventLog; let i = index"
      [ngClass]="i == 0 ? 'mice-cinema-discrete-selected-axis-item' : 'mice-cinema-discrete-axis-item'"
      [matTooltip]="
        (eventItem.timestamp | millisecondsToDate : true | date : 'yyyy-MM-dd HH:mm') + ': ' + eventItem.eventType
      "
      [style.left]="i * 30 + 'px'"
      [style.backgroundColor]="getStyleByEventType(eventItem.eventType).color"
      (click)="onFastForwardToItem(i)"
    >
      <mat-icon>{{ getStyleByEventType(eventItem.eventType).matIcon }}</mat-icon>
    </div>
  </div>
  <button
    mat-icon-button
    class="mice-cinema-discrete-axis-change-position-btn"
    [disabled]="_eventPosition == eventLog.length - 1"
  >
    <mat-icon>keyboard_arrow_right</mat-icon>
  </button>
  <div class="mice-cinema-step-count">{{ _eventPosition }} / {{ eventLog.length }}</div>
</div>
