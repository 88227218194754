import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { EMessageType, Message } from '@core/message/message';
import { MessageService } from '@core/message/message.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ExperimentService2, IExperiment } from '../../../data/experiment/experiment.service';
import { ExperimentTestDialog } from './dialogs/toolbar-dialog/toolbar-dialog.component';
import { ToolbarAction } from './toolbar-action.enum';

@Injectable()
export class SharedToolbarService {
  public currentElement: any;
  public selectGrid = false;
  private currentExperiment: IExperiment;
  public onAction: Observable<any>;
  onActionSub: Subject<any>;

  action: BehaviorSubject<any>;

  constructor(
    private http: HttpClient,
    private dialog: MatDialog,
    private experimentService2: ExperimentService2,
    private messageApi: MessageService
  ) {
    this.onActionSub = new Subject<any>();
    this.onAction = this.onActionSub.asObservable();

    this.action = new BehaviorSubject(null);

    this.onAction.subscribe((action: any) => {
      switch (action.type) {
        case ToolbarAction.SIMULATE_CHANGES:
          this.simulateChanges();
          break;
        case ToolbarAction.OPTIMIZE_CHANGES:
          this.optimizeChanges();
          break;
        case ToolbarAction.SIMULATE_CHANGES_WITH_NAME:
          this.simulateChangesWithName();
          break;
      }
    });

    this.experimentService2
      .getCurrentExperiment()
      .subscribe((experiment: IExperiment) => (this.currentExperiment = experiment));
  }

  getGroups() {}

  simulateChanges() {
    if (!this.currentExperiment) {
      this.messageApi.show(
        new Message().setType(EMessageType.ERROR).setText(`EXPERIMENT.RUN.ERROR.NO_EXPERIMENT`).setDuration(7500)
      );

      return;
    }
    this.messageApi.show(
      new Message().setType(EMessageType.INFO).setText(`EXPERIMENT.RUN.SIMULATE_CHANGES`).setDuration(7000)
    );
    this.http.get(`rest/experiment/${this.currentExperiment.id}/execute/override/`).subscribe((result) => {
      this.handleResult(result, ToolbarAction.REFRESH);
    });
  }

  optimizeChanges() {
    if (!this.currentExperiment) {
      this.messageApi.show(
        new Message().setType(EMessageType.ERROR).setText(`EXPERIMENT.RUN.ERROR.NO_EXPERIMENT`).setDuration(7000)
      );
      return;
    }

    this.messageApi.show(
      new Message().setType(EMessageType.INFO).setText(`EXPERIMENT.RUN.OPTIMIZE_CHANGES`).setDuration(7000)
    );
    this.http.get(`rest/experiment/${this.currentExperiment.id}/execute/optimize/`).subscribe((result) => {
      this.handleResult(result, ToolbarAction.REFRESH);
    });
  }

  simulateChangesWithName() {
    if (!this.currentExperiment) {
      this.messageApi.show(
        new Message().setType(EMessageType.ERROR).setText(`EXPERIMENT.RUN.ERROR.NO_EXPERIMENT`).setDuration(7000)
      );
      return;
    }
    const dialogRef = this.dialog.open(ExperimentTestDialog);
    dialogRef.afterClosed().subscribe((experimentName) => {
      if (!experimentName) {
        return;
      }
      this.messageApi.show(
        new Message()
          .setParams({ value: experimentName })
          .setType(EMessageType.INFO)
          .setText(`EXPERIMENT.RUN.SIMULATE_CHANGES_WITH_NAME`)
          .setDuration(7000)
      );
      this.http
        .get(`rest/experiment/${this.currentExperiment.id}/execute/interactive/${experimentName}`)
        .subscribe((result) => {
          this.handleResult(result, ToolbarAction.REFRESH);
        });
    });
  }

  handleResult(result, action: ToolbarAction) {
    this.trigger({ type: action });
  }

  /**
   * subscribe to toolbar events
   */
  getAction(): Observable<any> {
    return this.action.asObservable();
  }

  /**
   * triggers an action
   * @param data ToolbarAction
   */
  public trigger(action: any): any {
    if (action.type === ToolbarAction.DUPLICATE) {
    }
    this.onActionSub.next(action);
    this.action.next(action);
  }
}
