import { Action } from '@app-modeleditor/components/button/action/action';
import { EPredefinedAction } from '@app-modeleditor/components/button/action/predefined-action.enum';
import { EGanttInstance } from '@gantt/public-api';
import { SaxMsBestGanttActiveSubmenuEntryElementSetting } from 'frontend/src/dashboard/gantt/gantt/saxms-best-gantt.settings';
import { Observable, of } from 'rxjs';
import { ExternalGanttPlugin } from '../../external-plugin';

const GanttAreaOverlayExecuter = 'gantt-plugin-area-overlay-executer';

/**
 * PlugIn-Wrapper for GanttOverlay.
 * Provides function to overlay rows during defined teimperiods with a colorized overlay rect.
 */
export class GanttAreaOverlayPlugIn extends ExternalGanttPlugin {
  private notEditableIntervals: null;

  /**
   * Extracts area restrictions from backend data and draws them into gantt.
   * @param templateData
   * @param responseData
   */
  public onInit(templateData: any, responseData: any) {
    this.addPlugIn(
      GanttAreaOverlayExecuter,
      this._ganttLibService.ganttInstanceService.getInstance(EGanttInstance.GANTT_OVERLAY)
    );

    if (!responseData.hierarchicalPlan.notEditableIntervals) return;
    const notEditableIntervals = responseData.hierarchicalPlan.notEditableIntervals.map((intervalData) => {
      return {
        timeStart: new Date(intervalData.start),
        timeEnd: new Date(new Date(intervalData.end).getTime() - 1), // (Date-1) -> next day is not in time end
        restriction: this.extractRestrictions(intervalData),
        color: intervalData.color,
        opacity: intervalData.opacity / 100, // Backend sends value [0,100]
      };
    });

    this.notEditableIntervals = notEditableIntervals;

    this.updateIntervals();
  }

  public onDestroy(): void {}

  public onAction(action: any) {}

  public executeAction(action: Action): Observable<any> {
    if (action.getId() !== EPredefinedAction.GANTT_ENABLE_EDIT_MODE) {
      // TODO: do something with the incoming data

      return of();
    }

    return of(null);
  }

  public updateIntervals() {
    this.getPlugInById(GanttAreaOverlayExecuter).clearData();
    if (this.notEditableIntervals) {
      this.getPlugInById(GanttAreaOverlayExecuter).addOverlays(this.notEditableIntervals);
    }
  }

  /**
   * Extracts restriction data by backend data.
   * So it sets drag restrictions for different block positions realtive to overlay.
   * @param notEditableIntervals Time pperiods which should be overlayed.
   */
  private extractRestrictions(notEditableIntervals: any): IGanttOverlayRestriciton {
    if (!notEditableIntervals || !notEditableIntervals.overlappingDefinition) return null;
    const restrictions: IGanttOverlayRestriciton = {
      dragMode: {
        completeInside: null,
        startInside: null,
        endInside: null,
      },
      resizeMode: null,
    };
    switch (notEditableIntervals.overlappingDefinition) {
      case 'BLOCK_START_END':
        restrictions.dragMode.completeInside = 'NODRAG';
        restrictions.dragMode.startInside = 'NODRAG';
        restrictions.dragMode.endInside = 'NODRAG';
        break;
      case 'BLOCK_START':
        restrictions.dragMode.completeInside = 'NODRAG';
        restrictions.dragMode.startInside = 'NODRAG';
        restrictions.dragMode.endInside = null;
        break;
      case 'BLOCK_END':
        restrictions.dragMode.completeInside = 'NODRAG';
        restrictions.dragMode.endInside = 'NODRAG';
        break;
    }
    return restrictions;
  }

  public injectSettings(submenuElements: SaxMsBestGanttActiveSubmenuEntryElementSetting[]): void {}
}

/**
 * Data structure to define overlay.
 */
export interface IGanttOverlayPlugInData {
  timeStart: Date;
  timeEnd: Date;
  restriction: IGanttOverlayRestriciton;
  color: string;
  opacity: number;
}

/**
 * Overlay drag restrictions for shift blocks.
 */
export interface IGanttOverlayRestriciton {
  dragMode: {
    completeInside: 'VERTICAL' | 'HORIZONTAL' | 'NODRAG';
    startInside: 'VERTICAL' | 'HORIZONTAL' | 'NODRAG';
    endInside: 'VERTICAL' | 'HORIZONTAL' | 'NODRAG';
  };
  resizeMode: 'LEFT' | 'RIGHT';
}
