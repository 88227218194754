import { OverlayRef } from '@angular/cdk/overlay';
import { ComponentRef, Directive, ElementRef, Inject, Injector, NgZone, OnDestroy, OnInit } from '@angular/core';
import { IOverlayOptions } from '@app-modeleditor/components/lightbox/overlay/overlay-options.interface';
import { OverlayService } from '@app-modeleditor/components/lightbox/overlay/overlay.service';
import { fromEvent, of, Subject, Subscription } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';
import { ResourceElementComponent } from '../resource-element.component';
import { IResourceTooltipData } from './resource-tooltip-data.interface';
import { ResourceTooltipComponent } from './resource-tooltip.component';

@Directive({
  selector: '[resourceTooltip]',
})
export class ResourceTooltipDirective implements OnInit, OnDestroy {
  private _currentOverlayRef: OverlayRef;
  private _componentRef: ComponentRef<ResourceTooltipComponent>;
  private _subscriptions: Subscription[] = [];
  private _timerId: Subject<void> = new Subject<void>();

  constructor(
    private _elementRef: ElementRef,
    private _host: ResourceElementComponent,
    private _ngZone: NgZone,
    @Inject('InternalOverlayService') private _overlayService: OverlayService,
    private injector: Injector
  ) {}

  ngOnInit(): void {
    this._initTooltip();
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach((s) => {
      if (s) s.unsubscribe();
    });
    this._timerId.next();
    this._timerId.complete();
  }

  private _initTooltip() {
    const mouseEnter$ = fromEvent<MouseEvent>(this._elementRef.nativeElement, 'mouseenter');
    const mouseLeave$ = fromEvent<MouseEvent>(this._elementRef.nativeElement, 'mouseleave');

    const mouseEnterSub = mouseEnter$.subscribe((event: MouseEvent) => {
      of(null)
        .pipe(delay(300), takeUntil(this._timerId))
        .subscribe(() => {
          this._createTooltip(event);
        });
    });

    const mouseLeaveSub = mouseLeave$.subscribe((event: MouseEvent) => {
      this._timerId.next();
      if (this._currentOverlayRef) {
        this._currentOverlayRef.dispose();
        this._currentOverlayRef = null;
      }
    });

    this._subscriptions = [mouseEnterSub, mouseLeaveSub];
  }

  private _createTooltip(event: MouseEvent) {
    const overlayOptions: IOverlayOptions = {
      backdrop: false,
    };
    const data: IResourceTooltipData = { event: event, resourceElement: this._host.elementData };
    this._ngZone.run((_) => {
      const creation = this._overlayService.create(ResourceTooltipComponent, null, data, overlayOptions, this.injector);
      this._currentOverlayRef = creation.overlayRef;
      this._componentRef = creation.componentRef;
    });
  }
}
