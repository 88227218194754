import { of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { SaxMsSpreadSheetColumn } from './core/saxms-spreadsheet';
import { FullSpreadsheetComponent } from './full-spreadsheet/full-spreadsheet.component';

export class SpreadsheetZoom {
  private start: Date;
  private end: Date;
  private contentId = '';
  private instance: FullSpreadsheetComponent;
  public getContentId(): string {
    return this.contentId;
  }

  public setContentId(contentId: string): this {
    this.contentId = contentId;
    return this;
  }

  public getStart(): Date {
    return this.start;
  }

  public setStart(start: Date): this {
    this.start = start;
    return this;
  }

  public getEnd(): Date {
    return this.end;
  }

  public setEnd(end: Date): this {
    this.end = end;
    return this;
  }

  constructor(start: Date, end: Date) {
    this.start = start;
    this.end = end;
  }

  public apply(
    width: number,
    offset: number,
    cols: SaxMsSpreadSheetColumn[],
    instance: FullSpreadsheetComponent,
    firstColumSize: number
  ): SaxMsSpreadSheetColumn[] {
    if (!cols) {
      return [];
    }

    this.instance = instance;
    // let totalWidth: number = width - offset - 4 - 14;
    let totalWidth: number = width - offset;
    let firstColumnsNumber = cols.filter((col) => isNaN(col.timeValue) === true).length;
    // filter out columns without a timeValue and those which are out of range
    const visibleColCount: number = cols.filter(
      (col: SaxMsSpreadSheetColumn) => col.timeValue >= this.start.getTime() && col.timeValue < this.end.getTime()
    ).length;
    const avgWidth: number = totalWidth / visibleColCount;
    // set widths of tiemValue columns
    let i = 0;
    let reminder = 0;
    let reminderFirstColumns = 0;
    let firstColumnCount = 1;

    const newCols = cols
      .slice() // to avoid mutating the original array
      .sort((col1: SaxMsSpreadSheetColumn, col2: SaxMsSpreadSheetColumn) => {
        if (isNaN(col1.timeValue) === true && isNaN(col2.timeValue) === true) {
          if (!isNaN(col1.preferedWidth) && isNaN(col2.preferedWidth)) {
            return -1;
          } else if (isNaN(col1.preferedWidth) && !isNaN(col2.preferedWidth)) {
            return 1;
          } else {
            return 0;
          }
        }
        return 0;
      })
      .map((col: SaxMsSpreadSheetColumn, index: number) => {
        const t: Date = new Date(col.timeValue);
        // first column without timestamp
        if (isNaN(col.timeValue) === true) {
          if (col.preferedWidth) {
            col.width = col.preferedWidth;
            firstColumSize -= col.preferedWidth;
            firstColumnsNumber -= 1;
          } else {
            reminderFirstColumns = Math.ceil(firstColumSize / firstColumnsNumber) * firstColumnsNumber - firstColumSize;
            col.width = Math.ceil(firstColumSize / firstColumnsNumber);
            firstColumnCount++;
          }

          if (firstColumnCount === firstColumnsNumber) {
            col.width -= reminderFirstColumns;
          }

          // fallback
          if (firstColumnsNumber === 0) {
            col.width = firstColumSize + col.preferedWidth;
          }
          this.hideColumn(instance, col.filterSortObject.getColumnIndex(), true);
          // in range values
        } else if (col.timeValue >= this.start.getTime() && col.timeValue < this.end.getTime()) {
          this.hideColumn(instance, col.filterSortObject.getColumnIndex(), true);
          col.width = Math.floor(avgWidth);
          totalWidth -= avgWidth;
          reminder += avgWidth % 1;

          // if the reminder gets bigger than 1, add an extra pixel on the next column
          if (reminder >= 1) {
            col.width++;
            reminder = reminder % 1;
          }

          if (i === visibleColCount - 1) {
            col.width = Math.ceil(avgWidth);
          }

          i++;
          // out of range columns
        } else {
          col.width = 0;
          this.hideColumn(instance, col.filterSortObject.getColumnIndex(), false);
        }
        return col;
      });

    return newCols;
  }

  /**
   * hide column after short delay
   * @param instance SaxMsSpreadsheetComponent
   * @param columnIndex number
   * @param visibility boolean
   * @returns void
   */
  private hideColumn(instance: FullSpreadsheetComponent, columnIndex: number, visibility: boolean) {
    of(null)
      .pipe(delay(0))
      .subscribe(() => {
        instance.hideColum(columnIndex, visibility);
      });
  }
}
