import { OverlayRef } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import { Action } from '@app-modeleditor/components/button/action/action';
import { ETemplateHandlingType } from '@app-modeleditor/components/button/action/template-handling-type.enum';
import { ButtonService } from '@app-modeleditor/components/button/button.service';
import { ContextMenuItem } from '@app-modeleditor/components/contextmenu/context-menu-item';
import { ContextMenu } from '@app-modeleditor/components/contextmenu/contextmenu';
import { ContextmenuService } from '@app-modeleditor/components/contextmenu/contextmenu.service';
import { Observable, concat, of } from 'rxjs';
import { GanttLibService } from '../gantt/gantt-lib.service';
import { GanttTemplateData } from '../helper/gantt';
import { GanttTemplateDataService } from './template-data/gantt-template-data.service';

@Injectable()
export class GanttActionService {
  constructor(
    private contextMenuService: ContextmenuService,
    private _actionExecuter: ButtonService,
    private ganttLibService: GanttLibService,
    private templateDataService: GanttTemplateDataService
  ) {}

  public createCtxByEvent(
    templateData: GanttTemplateData,
    event: MouseEvent,
    ctxId: string,
    id: string,
    label: string,
    cb?: any,
    disabled?: boolean
  ): OverlayRef {
    event.stopPropagation();
    event.preventDefault();

    const ctx: ContextMenu = new ContextMenu().setDefaultBehaviour(false).setId(ctxId);
    ctx.setContextMenuItems([
      new ContextMenuItem().setId(id).setName(label).chainActions(new Action().setCb(cb)).setDisabled(disabled),
    ]);
    return this.contextMenuService.create(event, ctx);
  }

  public openDefault(
    event: MouseEvent,
    ctx: ContextMenu,
    sIds: string[],
    isBlockElement: boolean,
    staticItems: ContextMenuItem[] = []
  ): void {
    event.preventDefault();
    event.stopImmediatePropagation();
    if (!ctx) {
      return;
    }

    let customItems: ContextMenuItem[] = [];

    if (sIds) {
      customItems = ctx.getContextMenuItems().filter((item: ContextMenuItem) => {
        return sIds.includes(item.getShortId());
      });
    } else if (isBlockElement) {
      customItems = ctx.getContextMenuItems(); // Show all entries when a block is right clicked with no sIds
    }

    ctx.setContextMenuItems([...staticItems, ...customItems]);

    // If there is an action that opens an external window, then all shifts must be deselected
    this.checkForExternalViewAction(ctx.getContextMenuItems());

    this.contextMenuService.create(event, ctx);
  }

  /**
   * Checks if there is an action that opens an external window and adds a callback to the action stack to deselect all shifts
   * @param menuItems The menu items to check
   */
  private checkForExternalViewAction(menuItems: ContextMenuItem[]): void {
    menuItems.forEach((item: ContextMenuItem) => {
      const externalViewAction = this.getExternalViewAction(item);
      if (externalViewAction) {
        this.addCallbackToActionStack(item);
      }
    });
  }

  /**
   * Returns the action that opens an external window
   * @param menuItem The menu item to check
   */
  private getExternalViewAction(menuItem: ContextMenuItem): Action {
    return menuItem
      .getGlobalActions()
      .find(
        (action: Action) =>
          action.getTemplateHandlingType() === ETemplateHandlingType.TEMPLATE_EXTERNAL_TAB ||
          action.getTemplateHandlingType() === ETemplateHandlingType.TEMPLATE_EXTERNAL_WINDOW
      );
  }

  /**
   * Adds a callback to the action stack of the menu item to deselect all shifts
   * @param menuItem The menu item to add the callback to
   */
  private addCallbackToActionStack(menuItem: ContextMenuItem): void {
    const actionId = 'afterExternalViewAction';

    menuItem.setGlobalActions([
      ...menuItem.getGlobalActions().filter((a) => a.getId() !== actionId), // Remove the action if it already exists
      new Action().setId(actionId).setCb(this.getDeselectAllShiftsCallback()),
    ]);
  }

  /**
   * @returns A callback that deselects all shifts
   */
  private getDeselectAllShiftsCallback(): () => Observable<any> {
    return () => {
      this.ganttLibService?.bestGantt?.deselectAllShifts();
      this.templateDataService
        ?.getTemplateData()
        ?.setSelectedBlock({}, this.ganttLibService.backendToGanttOriginInputMapper);
      this.templateDataService
        ?.getTemplateData()
        ?.setSelectedValues(false, this.ganttLibService.backendToGanttOriginInputMapper);
      return of(console.log('deselect on external view'));
    };
  }

  /**
   * Executes a double click action for the context menu specified in the given gantt template data.
   * @param templateData Template data cobtaining the context menu to execute the double click action for.
   * @param sIds Short ids to check before executing.
   */
  public executeDoubleClickAction(templateData: GanttTemplateData, sIds: string[]): void {
    const ctx: ContextMenu = templateData.getContextmenu().copy(ContextMenu);
    if (!ctx) return;

    this.executeDoubleClickActionOnContextMenu(ctx, sIds);
  }

  /**
   * Executes a double click action for a custom context menu.
   * @param ctx Context menu to execute the double click action for.
   * @param sIds Short ids to check before executing.
   */
  public executeDoubleClickActionOnContextMenu(ctx: ContextMenu, sIds: string[]): void {
    if (sIds) {
      const ctxItems: ContextMenuItem[] = ctx.getContextMenuItems().filter((item: ContextMenuItem) => {
        return sIds.includes(item.getShortId()) && item.isDoubleClickAction();
      });

      if (ctxItems) {
        concat(...ctxItems.map((item: ContextMenuItem) => this._actionExecuter.onClick(item))).subscribe();
      }
    }
  }

  closeCtx(): void {
    this.contextMenuService.close();
  }
}
