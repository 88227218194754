import { Pipe, PipeTransform } from '@angular/core';

// Tell Angular2 we're creating a Pipe with TypeScript decorators
@Pipe({
  name: 'fileSize',
})
export class FileSizePipe implements PipeTransform {
  private units: string[] = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];

  transform(bytes = 0, precision = 1): string {
    let result: string;

    if (isNaN(parseFloat(String(bytes))) || !isFinite(bytes)) {
      result = '?';
    } else {
      let unit = 0;

      while (bytes >= 1024) {
        bytes /= 1024;
        unit++;
      }

      result = bytes.toFixed(+precision) + ' ' + this.units[unit];
    }
    return result;
  }
}
