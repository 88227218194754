import { Component, Input } from '@angular/core';
import { Spinner } from './spinner';

@Component({
  selector: 'app-loading-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class LoadingSpinnerComponent {
  @Input() spinner: Spinner;
}
