import { BubbleDataPoint, Chart, ChartTypeRegistry, Plugin, Point } from 'chart.js';

export default class AxisTickPlugin implements Plugin {
  id = 'axis-tick-plugin';

  private getAxesOffset(scale) {
    if (scale.type !== 'category') {
      return 0;
    }
    if (scale.ticks.length == 0) {
      return 0;
    }

    if (scale.ticks.length === 1) {
      return scale.getPixelForTick(0);
    } else {
      return scale.getPixelForTick(1) - scale.getPixelForTick(0);
    }
  }
  private draw(gridLineItem, chartInstance) {
    const { ctx } = chartInstance;
    const axisTicks = chartInstance.options.axisTick;
    const scale = chartInstance.scales[axisTicks.scaleID];

    ctx.save();

    ctx.beginPath();
    ctx.moveTo(gridLineItem.x1 + this.getAxesOffset(scale), gridLineItem.y2);
    ctx.lineTo(gridLineItem.x1 + this.getAxesOffset(scale), gridLineItem.y2 + axisTicks.tickLength);
    ctx.strokeStyle = axisTicks.tickColor;
    ctx.stroke();
  }

  private addTickLabel(gridLineItemStart, gridLineItemEnd, labelText, chartInstance) {
    const { ctx } = chartInstance;
    ctx.save();

    ctx.font = '10px sans-serif';
    ctx.fillStyle = 'black';
    ctx.fillText(
      labelText,
      gridLineItemStart.x1 + (gridLineItemEnd.x1 - gridLineItemStart.x1) / 2,
      gridLineItemStart.y2 + 30
    );
  }

  afterDraw?(
    chartInstance: Chart<keyof ChartTypeRegistry, (number | [number, number] | Point | BubbleDataPoint)[], unknown>
  ): void {
    const axisTicks = (chartInstance.options as any).axisTick;
    if (!axisTicks) {
      return;
    }
    const generalChartOptions = chartInstance.options;
    const _chartInstance = chartInstance;
    const _scale = _chartInstance.scales[axisTicks.scaleID];
    if (axisTicks && axisTicks.scaleID && generalChartOptions && generalChartOptions.scales.x.display) {
      axisTicks.additionalLabelTicks.forEach((labelObject, index) => {
        const tick = labelObject.index;
        if (!(_scale as any)._gridLineItems || (_scale as any)._gridLineItems[tick] === undefined) {
          return;
        }

        if (index !== axisTicks.additionalLabelTicks.length - 1) {
          this.draw((_scale as any)._gridLineItems[tick], chartInstance);
        }

        if (index === 0) {
          this.addTickLabel(
            (_scale as any)._gridLineItems[0],
            (_scale as any)._gridLineItems[tick],
            labelObject.label,
            chartInstance
          );
        } else {
          const prevTick = axisTicks.additionalLabelTicks[index - 1].index;
          this.addTickLabel(
            (_scale as any)._gridLineItems[prevTick + 1],
            (_scale as any)._gridLineItems[tick],
            labelObject.label,
            chartInstance
          );
        }
      });
    }
  }
}
