import {
  GanttChildren,
  GanttHierarchicalPlanData,
  IGanttBlock,
} from 'frontend/src/dashboard/gantt/general/generator/gantt-input.data';
import { BackendToGanttOriginInputMapperService } from '../general/generator/mapper/gantt-to-gantt-origin.mapper';

/**
 * Collection of helper function to find or remove entries or blocks of hierarchical plan.
 */
export class HierarchicalPlanHelperFunctions {
  /**
   * Returns an entry by the given ID from hierarchical plan.
   */
  public getEntryByIdFromHierarchicalPlan(rowId: string, hierarchicalPlan: GanttHierarchicalPlanData): GanttChildren {
    if (!hierarchicalPlan) {
      console.warn('HierarchicalPlan does not exist!');
      return null;
    }

    const _findEntry = (rowId: string, rows: GanttChildren[]): GanttChildren => {
      if (!rows) {
        return null;
      }
      for (const row of rows) {
        if (row.id === rowId) {
          return row;
        }
        const result = _findEntry(rowId, row.children);
        if (result) {
          return result;
        }
      }
      return null;
    };

    return _findEntry(rowId, hierarchicalPlan.ganttEntries);
  }

  /**
   * Removes a block by the given ID from hierarchical plan.
   */
  public removeBlockByIdFromHierarchicalPlan(blockId: string, hierarchicalPlan: GanttHierarchicalPlanData): boolean {
    if (!hierarchicalPlan) {
      console.warn('HierarchicalPlan does not exist!');
      return null;
    }

    const _removeBlock = (blockId: string, rows: GanttChildren[]) => {
      if (!rows) {
        return false;
      }
      for (const row of rows) {
        for (let i = 0; i < row.blocks.length; i++) {
          const block = row.blocks[i];
          if (block?.id === blockId) {
            row.blocks.splice(i, 1); // remove block
            return true;
          }
        }
        const result = _removeBlock(blockId, row.children);
        if (result) {
          return result;
        }
      }
      return false;
    };
    return _removeBlock(blockId, hierarchicalPlan.ganttEntries);
  }

  /**
   * Removes all blocks with the given IDs from hierarchical plan.
   * @param blockIds Ids of the blocks to remove.
   */
  public removeBlocksByIdsFromHierarchicalPlan(blockIds: string[], hierarchicalPlan: GanttHierarchicalPlanData): void {
    if (!hierarchicalPlan) {
      console.warn('HierarchicalPlan does not exist!');
      return;
    }
    const blocksToDelete = blockIds?.slice();
    const _removeBlocks = (blockIds: string[], rows: GanttChildren[]): void => {
      if (!rows || !blockIds || blockIds.length <= 0) return;
      for (const row of rows) {
        for (let i = 0; i < row.blocks.length; i++) {
          const block = row.blocks[i];
          if (blockIds.includes(block?.id)) {
            row.blocks.splice(i, 1); // remove block
            blockIds.splice(blockIds.indexOf(block?.id));
            if (blockIds.length <= 0) return;
          }
        }
        _removeBlocks(blockIds, row.children);
      }
    };
    _removeBlocks(blocksToDelete, hierarchicalPlan.ganttEntries);
  }

  /**
   * Removes an entry by the given ID from hierarchical plan.
   */
  public removeEntryByIdFromHierarchicalPlan(entryId: string, hierarchicalPlan: GanttHierarchicalPlanData): boolean {
    if (!hierarchicalPlan) {
      console.warn('HierarchicalPlan does not exist!');
      return null;
    }

    const _removeEntry = (entryId: string, entries: GanttChildren[]) => {
      if (!entries) {
        return false;
      }
      for (let i = 0; i < entries.length; i++) {
        const entry = entries[i];
        if (entry.id === entryId) {
          entries.splice(i, 1); // remove block
          return true;
        }
        const result = _removeEntry(entryId, entry.children);
        if (result) {
          return result;
        }
      }
      return false;
    };
    return _removeEntry(entryId, hierarchicalPlan.ganttEntries);
  }

  public replaceEntryByIdInHierarchicalPlan(
    newEntry: GanttChildren,
    hierarchicalPlan: GanttHierarchicalPlanData
  ): boolean {
    if (!hierarchicalPlan) {
      console.warn('HierarchicalPlan does not exist!');
      return null;
    }

    const _replaceEntry = (entries: GanttChildren[]) => {
      if (!entries) {
        return false;
      }
      for (let i = 0; i < entries.length; i++) {
        const entry = entries[i];
        if (entry.id === newEntry.id) {
          entries.splice(i, 1, newEntry); // remove old entry and set new one instead
          return true;
        }
        const result = _replaceEntry(entry.children);
        if (result) {
          return result;
        }
      }
      return false;
    };

    return _replaceEntry(hierarchicalPlan.ganttEntries);
  }

  /**
   * Removes entries by the given ID array from hierarchical plan.
   */
  public removeEntriesByIdFromHierarchicalPlan(entryIds: string[], hierarchicalPlan: GanttHierarchicalPlanData): void {
    if (!hierarchicalPlan) {
      console.warn('HierarchicalPlan does not exist!');
      return null;
    }
    const entryIdList = entryIds.slice();
    const _removeEntry = (entries: GanttChildren[]) => {
      if (!entries) {
        return;
      }
      for (let i = 0; i < entries.length; i++) {
        const entry = entries[i];
        if (entry.children && entry.children.length) {
          _removeEntry(entry.children);
        }
        if (entryIdList.includes(entry.id)) {
          entryIdList.splice(entryIdList.indexOf(entry.id), 1); // remove from entry id list
          entries.splice(i, 1);
          i--; // go back because position of next is shifted by 1
          if (!entryIdList.length) {
            // all entries removed -> now return
            return;
          }
        }
      }
    };

    _removeEntry(hierarchicalPlan.ganttEntries);
  }

  /**
   * Returns a block by the given ID from hierarchical plan.
   */
  public getBlockByIdFromHierarchicalPlan(
    blockId: string,
    hierarchicalPlan: GanttHierarchicalPlanData,
    mapper: BackendToGanttOriginInputMapperService = null
  ): IGanttBlock {
    if (!hierarchicalPlan) {
      console.warn('HierarchicalPlan does not exist!');
      return null;
    }

    const mappedBlockId = mapper ? mapper.mapShiftIdToOriginId(blockId) : blockId;

    const _getBlock = (blockId: string, rows: GanttChildren[]): IGanttBlock => {
      if (!rows) {
        return null;
      }
      for (const row of rows) {
        for (let i = 0; i < row.blocks.length; i++) {
          const block = row.blocks[i];
          if (block.id === blockId) {
            // block found
            return block;
          }
        }
        const result = _getBlock(blockId, row.children);
        if (result) {
          return result;
        }
      }
      return null;
    };
    return _getBlock(mappedBlockId, hierarchicalPlan.ganttEntries);
  }

  /**
   * Iterates over all gantt blocks recursive of the given entry list.
   * @param callback Callback is called on every block with its own block and the parent row as parameter
   * @param ganttEntries Root element of gantt entry to iterate
   */
  public iterateOverAllBlocks(
    callback: (block: IGanttBlock, row: GanttChildren) => void,
    ganttEntries: GanttChildren[]
  ): void {
    ganttEntries.forEach((entry: GanttChildren) => {
      if (entry.children?.length) {
        this.iterateOverAllBlocks(callback, entry.children);
      }
      if (entry.blocks?.length) {
        entry.blocks.forEach((block: IGanttBlock) => {
          callback(block, entry);
        });
      }
    });
  }

  /**
   * Returns an array of objects containing the block and its corresponding row from the hierarchical plan
   * that match the given block IDs.
   *
   * @param blockIds - The IDs of the blocks to retrieve.
   * @param hierarchicalPlan - The hierarchical plan to search for the blocks.
   * @returns An array of objects containing the block and its corresponding row.
   */
  public getBlocksByIdsFromHierarchicalPlan(
    blockIds: string[],
    hierarchicalPlan: GanttHierarchicalPlanData
  ): { block: IGanttBlock; row: GanttChildren }[] {
    if (!hierarchicalPlan) {
      console.warn('HierarchicalPlan does not exist!');
      return;
    }
    const blocksToDelete = blockIds?.slice();
    const result: { block: IGanttBlock; row: GanttChildren }[] = [];
    const _removeBlocks = (blockIds: string[], rows: GanttChildren[]): void => {
      if (!rows || !blockIds || blockIds.length <= 0) return;
      for (const row of rows) {
        for (let i = 0; i < row.blocks.length; i++) {
          const block = row.blocks[i];
          if (blockIds.includes(block?.id)) {
            result.push({ block, row });
            blockIds.splice(blockIds.indexOf(block?.id));
            if (blockIds.length <= 0) return;
          }
        }
        _removeBlocks(blockIds, row.children);
      }
    };
    _removeBlocks(blocksToDelete, hierarchicalPlan.ganttEntries);

    return result;
  }

  public iterateOverAllEntries(callback: (row: GanttChildren) => void, ganttEntries: GanttChildren[]): void {
    ganttEntries.forEach((entry: GanttChildren) => {
      if (entry.children?.length) {
        this.iterateOverAllEntries(callback, entry.children);
      }
      callback(entry);
    });
  }
}
