import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { EntryElement } from '@app-modeleditor/components/entry-collection/entry-element';

@Component({
  selector: 'app-input-error',
  templateUrl: './error-menu.component.html',
  styleUrls: ['./error-menu.component.scss'],
})
export class ErrorMenuComponent {
  @Input() control: UntypedFormControl;
  @Input() entryElement: EntryElement;

  /**
   * get error message
   * @returns string
   */
  get errorMsg(): string {
    if (this.control.touched && !this.control.dirty) {
    }

    return this.control.hasError('required') ? `FORMS.VALIDATION.ERROR.required` : ``;
  }
}
