import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { Subscription } from 'rxjs';
import { IResourceFilter } from '../../resource-filter.interface';
import { ResourcesDataService } from '../../resources-data.service';
import { IResourceElement } from '../resource-element/resource-element.interface';
import { IResourceGroup } from '../resource-element/resource-group.inerface';
import { IGroupByAttributeElement } from './resource-group-by-attribute-element.interface';

@Component({
  selector: 'app-resource-list',
  templateUrl: './resource-list.component.html',
  styleUrls: ['./resource-list.component.scss'],
})
export class ResourceListComponent implements OnInit, OnDestroy {
  public attributes: string[] = [];
  public resourceGroup: IResourceGroup;
  public currentSelectedAttribute: string = null;
  public groupByAttributeList: IGroupByAttributeElement[] = [];
  public filteredElements: IResourceElement[] = [];
  public visibleResourceAttributes: string[] = [];
  public intermediateStateOfVisibleResourceAttributes = false;
  public allResourceAttributesVisible = false;

  private _filterDataChangeSubscription: Subscription = null;

  @Input('resourceGroup') set _resourceGroup(group: IResourceGroup) {
    this._extractAttributesFromGroup(group);
    this.resourceGroup = group;

    this.filteredElements = this._getFilteredElementsByFilterData(
      this._resourcesDataService.currentActiveResourceFilter
    );
    const currentSelectedAttribute = this.getCurrentSelectedAttribute();
    if (currentSelectedAttribute) {
      this.groupByAttributeList = this._generateGroupByAttributeList(currentSelectedAttribute);
    }
  }

  constructor(private _resourcesDataService: ResourcesDataService) {}

  ngOnInit(): void {
    this._subscribeToFilterDataChange();
  }

  ngOnDestroy(): void {
    this._filterDataChangeSubscription.unsubscribe();
  }

  public sortByAttribute(attributeProperty: string, event: MouseEvent): void {
    this._resourcesDataService.setCurrentListViewAttributeByGroupId(this.resourceGroup.id, attributeProperty);
    if (attributeProperty) {
      this.groupByAttributeList = this._generateGroupByAttributeList(attributeProperty);
    }
  }

  public getCurrentSelectedAttribute(): string {
    return this._resourcesDataService.getCurrentListViewByGroupId(this.resourceGroup.id)?.currentGroupedBy;
  }

  public isViewCollapsed(): boolean {
    return this._resourcesDataService.getCurrentListViewByGroupId(this.resourceGroup.id)?.collapsed;
  }

  public toggleCollapse(): void {
    this._resourcesDataService.setCollapsedByGroupId(
      this.resourceGroup.id,
      !this._resourcesDataService.getCurrentListViewByGroupId(this.resourceGroup.id).collapsed
    );
  }

  public onVisibleResourceAttributeChange(event: MatCheckboxChange): void {
    if (event.checked) {
      this.visibleResourceAttributes.push(event.source.value);
    } else {
      this.visibleResourceAttributes = this.visibleResourceAttributes.filter((attr) => attr !== event.source.value);
    }

    this.visibleResourceAttributes.sort((a, b) => a.localeCompare(b));
    this.allResourceAttributesVisible = this.visibleResourceAttributes.length === this.attributes.length;
    this.intermediateStateOfVisibleResourceAttributes =
      this.visibleResourceAttributes.length !== this.attributes.length && this.visibleResourceAttributes.length !== 0;
  }

  public onSelectAllVisibleResourceAttributes(event: MatCheckboxChange): void {
    if (event.checked) {
      this.visibleResourceAttributes = this.attributes;
      this.allResourceAttributesVisible = true;
      this.intermediateStateOfVisibleResourceAttributes = false;
    } else {
      this.visibleResourceAttributes = [];
      this.allResourceAttributesVisible = false;
      this.intermediateStateOfVisibleResourceAttributes = false;
    }
  }

  public isAttributeSelected(attribute: string): boolean {
    return this.visibleResourceAttributes.includes(attribute);
  }

  private _extractAttributesFromGroup(group: IResourceGroup): void {
    this.attributes = [];
    group.elements.forEach((element) => {
      element.attributes.forEach((attribute) => {
        if (!this.attributes.includes(attribute.property)) {
          this.attributes.push(attribute.property);
        }
      });
    });
    this.attributes.sort((a, b) => a.localeCompare(b));
  }

  private _generateGroupByAttributeList(attributeProperty): IGroupByAttributeElement[] {
    const list: IGroupByAttributeElement[] = [];
    const unassigned: IGroupByAttributeElement = { attributeValue: 'Nicht zugeordnet', elements: [], collapsed: false };
    this.filteredElements.forEach((element) => {
      const foundAttribute = element.attributes.find((attribute) => attribute.property === attributeProperty);
      if (foundAttribute?.value) {
        // attribute value exists
        const existingEntry = list.find((listElem) => listElem.attributeValue === foundAttribute.value);
        if (existingEntry) {
          // only add element to existing entry
          existingEntry.elements.push(element);
        } else {
          // create new entry
          list.push({ attributeValue: foundAttribute.value, elements: [element], collapsed: false });
        }
      } else {
        // attribute value not exists
        unassigned.elements.push(element);
      }
    });

    // push unassigned elements as last if contains elements
    if (unassigned.elements.length) {
      list.push(unassigned);
    }
    return list;
  }

  private _getFilteredElementsByFilterData(filter: IResourceFilter): IResourceElement[] {
    return this.resourceGroup.elements.filter((element) => {
      if (filter.attribute === 'Alle') {
        return element.attributes.find((attr) => attr.value.toLowerCase().includes(filter.query.toLocaleLowerCase()));
      } else {
        const attribute = element.attributes.find((attr) => filter.attribute === attr.property);
        if (!attribute) {
          return false;
        }
        return attribute.value.toLowerCase().includes(filter.query.toLocaleLowerCase());
      }
    });
  }

  private _subscribeToFilterDataChange(): void {
    this._filterDataChangeSubscription = this._resourcesDataService
      .listenForFilterDataChange()
      .subscribe((filter: IResourceFilter) => {
        this.filteredElements = this._getFilteredElementsByFilterData(filter);
        const currentSelectedAttribute = this.getCurrentSelectedAttribute();
        if (currentSelectedAttribute) {
          this.groupByAttributeList = this._generateGroupByAttributeList(currentSelectedAttribute);
        }
      });
  }
}
