import { Injectable, Type } from '@angular/core';
import { Action } from '@app-modeleditor/components/button/action/action';
import { EActionType } from '@app-modeleditor/components/button/action/action-type.enum';
import { EPredefinedAction } from '@app-modeleditor/components/button/action/predefined-action.enum';
import { Button } from '@app-modeleditor/components/button/button';
import { ButtonAdapter } from '@app-modeleditor/components/button/button-adapter.service';
import { EButtonDisplayType } from '@app-modeleditor/components/button/button-display-type.enum';
import { ContextMenuItem } from '@app-modeleditor/components/contextmenu/context-menu-item';
import { ContextMenu } from '@app-modeleditor/components/contextmenu/contextmenu';
import { EntryCollectionAdapter } from '@app-modeleditor/components/entry-collection/entry-collection-adapter.service';
import { EntryElement } from '@app-modeleditor/components/entry-collection/entry-element';
import { EntryElementValue } from '@app-modeleditor/components/entry-collection/entry-element-value';
import { EFieldType } from '@app-modeleditor/components/entry-collection/field-type.enum';
import { ContentElementAdapter } from '@app-modeleditor/components/structure/content-element-adapter.service';
import { Adapter } from '@core/adapter';
import { TranslateService } from '@ngx-translate/core';
import { TemplateAdapter } from './../../modeleditor/utils/template-factory.service';
import { MenuItem } from './menu-item';
import { Toolbar } from './toolbar';
import { ToolbarGroup } from './toolbar-group';
import { EToolbarItemType } from './toolbar-item-type';

@Injectable({
  providedIn: 'root',
})
export class ToolbarAdapter implements Adapter<Toolbar> {
  constructor(
    private entryCollectionAdapter: EntryCollectionAdapter,
    private _templateResourceAdapter: ContentElementAdapter,
    private _buttonAdapter: ButtonAdapter,
    private translate: TranslateService
  ) {}

  adapt(item: any): Toolbar {
    throw new Error('Method not implemented.');
  }

  inherit?<T extends Toolbar>(type: Type<T>, item: any): T {
    const t: T = this._applyValues(this._templateResourceAdapter.inherit<T>(type, item), item);
    return t;
  }
  inheritFrom?<T extends Toolbar>(scope: TemplateAdapter, type: Type<T>, item: any): T {
    const t: T = this._applyValues(this._templateResourceAdapter.inheritFrom<T>(scope, type, item), item, scope);
    return t;
  }

  applyValues<T>(scope: TemplateAdapter, item: any, values: any): T {
    throw new Error('Method not implemented.');
  }

  private _applyValues<T extends Toolbar>(el: T, data: any, scope: TemplateAdapter = null): T {
    el.setMainMenu(data.mainMenu).setMaxShortcuts(data.maxShortcuts).setExtendable(data.extendable);

    if (scope) {
      el.setMenuItems(
        (data.menuItems || []).map((m) =>
          this.parseToolbarItem(scope, this._buttonAdapter.inheritFrom<MenuItem>(scope, MenuItem, m), m, el)
        )
      );
    }

    el.setTitle(data.title);
    return el;
  }

  public parseToolbarItem(scope: TemplateAdapter, item: MenuItem, data: any, el: Toolbar = null): MenuItem {
    const nav: MenuItem =
      data.navigationElement instanceof MenuItem
        ? data.navigationElement
        : this.parseNavigationElement(scope, data.navigationElement);

    if (nav) {
      nav.setDisplayType(
        data.toolbarItemType === EToolbarItemType.NAVIGATOR
          ? EButtonDisplayType.ICON_ONLY
          : EButtonDisplayType.ICON_AND_LABEL
      );
    }

    const t: MenuItem = (el ? item.defaultGroup(el) : item)
      // .defaultGroup()
      .setActive(data.active)
      .setIndex(data.index)
      .setNavigationElement(nav)
      .setToolbarGroups((data.groups || []).map((g) => this.parseToolbarGroup(scope, g)))
      .setToolbarItemType(data.toolbarItemType)
      .setDisplay(data.display);

    if (data.toolbarItemType === EToolbarItemType.GROUP) {
      return t
        .chainActions(
          new Action()
            .setId(EPredefinedAction.OPEN_TOOLBAR_GROUP)
            .setLocalID(data.id)
            .setActionType(EActionType.PREDEFINED)
        )
        .setDisplayType(EButtonDisplayType.ICON_AND_LABEL);
    } else {
      item.setDisplayType(EButtonDisplayType.ICON_ONLY);
    }

    return t;
  }

  parseToolbarGroup(scope: TemplateAdapter, data: any): ToolbarGroup {
    const group: ToolbarGroup = this.entryCollectionAdapter
      .inheritFrom<ToolbarGroup>(scope, ToolbarGroup, data)
      .setEntryElements((data.elements || []).map((e) => this.parseToolbarGroupElement(scope, e)));
    return group;
  }

  public parseNavigationElement(scope: TemplateAdapter, data): MenuItem {
    if (!data) {
      return null;
    }

    let t: MenuItem = scope.adapt(data);

    if (data.toolbarElementType === 'PREDEFINED') {
      t = t.copy(MenuItem).setDisplayType(EButtonDisplayType.ICON_ONLY);
      const action: Action = new Action().setActionType(EActionType.PREDEFINED);
      switch (data.id) {
        case 'template.toolbarelement.messagecenter':
          t.chainActions(action.setId(EPredefinedAction.TOGGLE_MESSAGECENTER).setActionType(EActionType.PREDEFINED));
          break;
        case 'template.toolbarelement.usersettings':
          t.chainActions(action.setId(EPredefinedAction.TOGGLE_USERSETTINGS).setActionType(EActionType.PREDEFINED));
          break;
        default:
          console.error(`there is no factory for ${data.toolbarElementType}`);
      }
    }

    return t;
  }

  public parseToolbarGroupElement(scope: TemplateAdapter, data): EntryElement {
    let entryElement: EntryElement;
    switch (data.toolbarElementType || data.fieldType) {
      case EFieldType.TOGGLE_BUTTON:
        entryElement = scope
          .adapt<Button>(data)
          .setFieldType(EFieldType.BUTTON)
          .setDisplayType(EButtonDisplayType.ICON_WITH_LABEL_BELOW);
        break;
      case EFieldType.BUTTON:
        entryElement = scope
          .adapt<Button>(data)
          .setFieldType(EFieldType.BUTTON)
          .setDisplayType(EButtonDisplayType.ICON_WITH_LABEL_BELOW);
        break;
      case EFieldType.PREDEFINED:
        entryElement = scope
          .adapt<Button>(data)
          .setFieldType(EFieldType.BUTTON)
          .setFieldType(EFieldType.PREDEFINED)
          .setId(data.id);
        break;
      default:
        entryElement = scope.adapt<Button>(data).setId(data.id).setValue(new EntryElementValue());
        break;
    }

    const contextMenuItems: ContextMenuItem[] = [];
    if (!data.disableContextMenu) {
      contextMenuItems.push(
        new ContextMenuItem()
          .setIcon('star_border')
          .setDisplayType(EButtonDisplayType.ICON_AND_LABEL)
          .setName(this.translate.instant('TOOLBAR.CONTEXTMENU.SHORTCUT.toggle'))
          .chainActions(
            new Action()
              .setActionType(EActionType.PREDEFINED)
              .setId(EPredefinedAction.TOGGLE_TOOLBAR_FAVORITE_ELEMENT)
              .setLocalID(entryElement.getId())
          )
      );
    }
    const ctx: ContextMenu = new ContextMenu();
    ctx.setContextMenuItems(contextMenuItems);
    entryElement.setContextmenu(ctx);

    return entryElement;
  }
}
