import { EGanttInstance } from '@gantt/public-api';
import { GanttLibService } from 'frontend/src/dashboard/gantt/gantt/gantt-lib.service';
import { GanttPlugInAction } from 'frontend/src/dashboard/gantt/gantt/plugin/i-saxms-best-gantt.plugin';
import { SaxMsBestGanttActiveSubmenuEntryElementSetting } from 'frontend/src/dashboard/gantt/gantt/saxms-best-gantt.settings';
import { GanttPluginHandlerService } from 'frontend/src/dashboard/gantt/general/plugin/gantt-plugin-handler.service';
import { Observable, of } from 'rxjs';
import { GeneralGanttActionHandler } from '../../../action-handling/action-handler';
import { GanttExternalActionRegistration } from '../../../gantt-actions/external-action-registration';
import { GanttResponseHandler } from '../../../response/response-handler';
import { ExternalGanttPlugin } from '../../external-plugin';
import { GanttBlockAddActionHandler } from './event-handling/block-add-registration';

export const GanttBlockAddButtonBackendType = 'template.ganttplugin.blockaddbutton';
const GanttBlockAddButtonExecuter = 'gantt-plugin-block-add-button-executer';

/**
 * PlugIn-Wrapper for SettingBubblesExecuter.
 * If activated, it draws one button ("bubble") at the left and right side of clicked shift block.
 * Not used (yet).
 */
export class GanttBlockAddButtonPlugIn extends ExternalGanttPlugin {
  constructor(
    protected _ganttPluginHandlerService: GanttPluginHandlerService,
    protected _ganttLibService: GanttLibService,
    actionHandler: GeneralGanttActionHandler,
    private responseHandler: GanttResponseHandler,
    private pluginData: any
  ) {
    super(_ganttPluginHandlerService, _ganttLibService, actionHandler);
  }

  private registration: GanttExternalActionRegistration;

  /**
   * Extracts button actions by backend data.
   * @param templateData
   * @param responseData
   */
  public onInit(templateData: any, responseData: any) {
    this.addPlugIn(
      GanttBlockAddButtonExecuter,
      this._ganttLibService.ganttInstanceService.getInstance(EGanttInstance.SETTING_BUBBLES)
    );
    // deactivate modus by default
    this.activateBlockAddModus(false);
    this.extractAddButtonsByActions();

    this.registration = new GanttBlockAddActionHandler(
      this._ganttPluginHandlerService,
      this._ganttLibService,
      this.getPlugInById(GanttBlockAddButtonExecuter),
      this.actionHandler,
      this.responseHandler,
      templateData,
      this.pluginData
    );
  }

  public onDestroy(): void {}

  public onAction(action: any) {}

  /**
   * Handles local action to activate this plugin.
   * @param action Local action.
   */
  public executeAction(action: any): Observable<any> {
    if (action.pluginId && action.pluginId == GanttBlockAddButtonBackendType) {
      const blockAddAction: GanttBlockAddActivationAction = action;
      this.activateBlockAddModus(blockAddAction.activateModus);
    }
    return of(null);
  }

  /**
   * Activates bubbles on block click.
   * @param activate
   */
  private activateBlockAddModus(activate: boolean): void {
    this.getPlugInById(GanttBlockAddButtonExecuter).setActivation(activate);
  }

  /**
   * Configures if only left or right button should be visible.
   */
  private extractAddButtonsByActions(): void {
    let showBeforeButton = false;
    let showAfterButton = false;
    for (const action of this.pluginData.ganttActions) {
      if (action.actionTypes.indexOf(GanttBlockAddButtonPosition.BLOCK_ADD_BEFORE) != -1) {
        showBeforeButton = true;
      }
      if (action.actionTypes.indexOf(GanttBlockAddButtonPosition.BLOCK_ADD_AFTER) != -1) {
        showAfterButton = true;
      }
    }
    this.getPlugInById(GanttBlockAddButtonExecuter).getBubbleBuilder().showLeftButton(showBeforeButton);
    this.getPlugInById(GanttBlockAddButtonExecuter).getBubbleBuilder().showRightButton(showAfterButton);
  }

  public injectSettings(submenuElements: SaxMsBestGanttActiveSubmenuEntryElementSetting[]): void {}
}

/**
 * Visual options to show left and/or right button.
 */
export enum GanttBlockAddButtonPosition {
  BLOCK_ADD_BEFORE = 'BLOCK_ADD_BEFORE',
  BLOCK_ADD_AFTER = 'BLOCK_ADD_AFTER',
}

/**
 * Typed local action.
 */
export abstract class GanttBlockAddActivationAction implements GanttPlugInAction {
  pluginId: string;
  activateModus: boolean;
}
