import { Injectable, Type } from '@angular/core';
import { EntryElementFactory } from './../entry-collection/entry-factory.service';
import { TemplatePicker } from './template-picker';

@Injectable({
  providedIn: 'root',
})
export class TemplatePickerAdapter {
  constructor(private entryElementFactory: EntryElementFactory) {}

  parse<T extends TemplatePicker>(type: Type<T>, data: any): T {
    const t: T = this.entryElementFactory.inherit<T>(type, data);
    if (typeof data.useMillisecondsForCalendarWeek === 'boolean') {
      t.useMillisForCalendarWeeks(data.useMillisecondsForCalendarWeek);
      // remove when backend removes thgis
    } else if (typeof data.useMilliseconds === 'boolean') {
      t.useMillisForCalendarWeeks(data.useMilliseconds);
    }
    return t;
  }

  public getDateOfWeek(week: number, year: number): Date {
    const d: number = 1 + (week - 1) * 7; // 1st of January + 7 days for each week
    return new Date(year, 0, d);
  }

  public getWeek(_date: Date): number {
    const date = new Date(_date.getTime());
    date.setHours(0, 0, 0, 0);
    // Thursday in current week decides the year.
    date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
    // January 4 is always in week 1.
    const week1 = new Date(date.getFullYear(), 0, 4);
    // Adjust to Thursday in week 1 and count number of weeks from date to week1.
    return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + ((week1.getDay() + 6) % 7)) / 7);
  }
}
