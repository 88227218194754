import { Component, ComponentRef, Input, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { TemplateComponent } from '@app-modeleditor/components/template/template.component';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { ERequestMethod, RequestService } from '../../../../request.service';
import { WizardPage } from './wizard-page';
import { TemplateAdapter } from './../../../../utils/template-factory.service';
import { HMI } from './../../../tree/tree-item';

@Component({
  selector: 'app-wizard-page',
  templateUrl: './wizard-page.component.html',
  styleUrls: ['./wizard-page.component.scss'],
})
export class WizardPageComponent implements OnInit, OnDestroy {
  @Input() wizardPage: WizardPage;
  private _ngDestroy: Subject<void> = new Subject<void>();

  constructor(private requestApi: RequestService, private templateFatory: TemplateAdapter) {}

  ngAfterViewInit(): void {
    this.changeView();
  }

  private changeView(): void {
    this.getPageContent()
      .pipe(takeUntil(this._ngDestroy))
      .subscribe((c: HMI) => {
        this.wizardPageContent = c;
      });
  }
  wizardPageContent: HMI;

  afterSlotInit(instance: ComponentRef<TemplateComponent>): void {
    this.wizardPageContent.setResourceId(this.wizardPage.getResourceId());
    instance.instance.templateNode = this.wizardPageContent;
    instance.instance.resourceId = this.wizardPage.getResourceId();
  }

  /**
   * get page from wizard
   * @returns Observable<Content>
   */
  private getPageContent(): Observable<HMI> {
    return this.requestApi.call(ERequestMethod.GET, `rest/${this.wizardPage.getContentRestUrl()}`).pipe(
      map((c: any) => {
        const hmi: HMI = new HMI().setContent(this.templateFatory.adapt(c));
        // hmi.getContent().getContentParts().forEach((part) => part.setContentElements([]));
        return hmi;
      })
    );
  }

  onClick(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.wizardPage) {
    }
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this._ngDestroy.next();
    this._ngDestroy.complete();
  }
}
