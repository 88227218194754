import { Injectable, Type } from '@angular/core';
import { TemplateAdapter } from '@app-modeleditor/utils/template-factory.service';
import { TemplateResourceAdapter } from '@app-modeleditor/utils/template-resource-factory.service';
import { EResizeMode } from 'frontend/src/dashboard/model/resource/template-resize-mode.enum';
import { Adapter } from '../../../core/adapter';
import { IndexRegister } from '../index-register/index-register';
import { Sidebar } from './sidebar';

@Injectable({
  providedIn: 'root',
})
export class SidebarAdapter implements Adapter<Sidebar> {
  constructor(private _templateAdapter: TemplateResourceAdapter) {}

  adapt(item: any): Sidebar {
    throw new Error('Method not implemented.');
  }

  inherit<T extends Sidebar>(type: Type<T>, item: any): T {
    const t: T = this._applyData(this._templateAdapter.inherit<T>(type, item), item);
    return t;
  }
  inheritFrom<T extends Sidebar>(scope: TemplateAdapter, type: Type<T>, item: any): T {
    const t: T = this._applyData(this._templateAdapter.inheritFrom<T>(scope, type, item), item, scope);
    return t;
  }

  private _applyData<T extends Sidebar>(item: T, data: any, scope?: TemplateAdapter): T {
    item.setPosition(data.position);
    if (scope) {
      item.setIndexRegister(scope.adapt<IndexRegister>(data.indexRegister));

      item
        .getIndexRegister()
        .getIndexPages()
        .forEach((p) => {
          p.setResizeMode(EResizeMode.FIT_PARENT);
          p.getContent().setResizeMode(EResizeMode.FIT_PARENT);
        });
    }

    return item;
  }

  applyValues<T>(scope: TemplateAdapter, item: any, values: any): T {
    throw new Error('Method not implemented.');
  }
}
