<mat-menu #infoMenu="matMenu" class="customize">
  <p class="info-box">
    {{ btn.getInfoText(DISABLED) }}
  </p>
</mat-menu>

<ng-container *ngIf="btn && displayType; else defaultBtn">
  <ng-container [ngSwitch]="displayType" *ngIf="displayType !== 'HIDDEN'">
    <ng-container *ngSwitchCase="'ICON_ONLY'">
      <button
        class="app-button"
        [class.bg]="btn.isDisplayBackground()"
        aria-hidden="false"
        [matBadgePosition]="btn?.getBadgePosition()"
        [matBadge]="badgeCount"
        [saxmsTooltip]="TOOLTIP || NAME | translate"
        [id]="btn.getId()"
        mat-icon-button
        [disabled]="DISABLED"
        (click)="switchClicks($event)"
        [class.selected]="selected"
      >
        <ng-container *ngTemplateOutlet="innerBtn"></ng-container>
        <mat-icon *ngIf="isIndicator" class="indicator">fiber_manual_record </mat-icon>
      </button>
    </ng-container>
    <ng-container *ngSwitchCase="'SPINNER'">
      <button
        class="app-button"
        [class.bg]="btn.isDisplayBackground()"
        aria-hidden="false"
        [matBadgePosition]="btn?.getBadgePosition()"
        [matBadge]="badgeCount"
        [saxmsTooltip]="TOOLTIP || NAME | translate"
        [id]="btn.getId()"
        mat-icon-button
        [disabled]="DISABLED"
        (click)="switchClicks($event)"
        [class.selected]="selected"
        [class.execution-btn-spinner]="true"
      >
        <!-- <div
          style="
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 100%;
          "
        > -->
        <mat-icon>pending</mat-icon>
        <!-- <mat-progress-spinner [mode]="'indeterminate'" [diameter]="24"></mat-progress-spinner> -->
        <!-- </div> -->
      </button>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <button
        class="app-button mat-elevation-z2"
        [style.max-width]="btn.getMaxWidth() + 'px'"
        [class.bg]="btn.isDisplayBackground()"
        [class.execution-btn-spinner]="isExecuting"
        [class.selected]="selected"
        aria-hidden="false"
        [matBadgePosition]="btn?.getBadgePosition()"
        [matBadge]="badgeCount"
        [class.label-below]="displayType === 'ICON_WITH_LABEL_BELOW'"
        [saxmsTooltip]="TOOLTIP || NAME | translate"
        [id]="btn.getId()"
        mat-stroked-button
        [disabled]="DISABLED"
        (click)="switchClicks($event)"
      >
        <ng-container *ngTemplateOutlet="innerBtn"></ng-container>
        <!-- <mat-spinner *ngIf="isBtnExecuting()" class="execution-btn-spinner" [diameter]="20"></mat-spinner> -->
        <mat-icon *ngIf="isIndicator" class="indicator">fiber_manual_record </mat-icon>
      </button>
    </ng-container>
  </ng-container>

  <button class="info-14" mat-icon-button matPrefix *ngIf="btn.getInfoText(DISABLED)" [matMenuTriggerFor]="infoMenu">
    <mat-icon *ngIf="btn.getInfoText(DISABLED)" class="info-icon">info </mat-icon>
  </button>
</ng-container>

<!-- inner part of the button -->
<ng-template #innerBtn>
  <ng-container [ngSwitch]="displayType">
    <ng-container *ngSwitchCase="'ICON_ONLY'">
      <app-image [label]="ICON"></app-image>
    </ng-container>

    <ng-container *ngSwitchCase="'LABEL_ONLY'">
      <mat-label>{{ NAME | translate }}</mat-label>
    </ng-container>

    <ng-container *ngSwitchCase="'ICON_AND_LABEL'">
      <app-image [label]="ICON" [style.color]="COLOR"></app-image>
      <mat-label [class.icon-with-label]="ICON">{{ NAME | translate }}</mat-label>
    </ng-container>

    <ng-container *ngSwitchCase="'ICON_BEHIND_LABEL'">
      <mat-label>{{ NAME | translate }}</mat-label>
      <app-image [label]="ICON" [style.color]="COLOR"></app-image>
    </ng-container>
    <ng-container *ngSwitchCase="'LABEL_AND_ICON'">
      <mat-label>{{ NAME | translate }}</mat-label>
      <app-image [label]="ICON" [style.color]="btn.getColor()"></app-image>
    </ng-container>
    <ng-container *ngSwitchCase="'ICON_WITH_LABEL_BELOW'">
      <app-image [label]="ICON" [style.color]="COLOR"></app-image>
      <mat-label>{{ NAME | translate }}</mat-label>
    </ng-container>
    <ng-container *ngSwitchCase="'DYNAMIC'">
      <span class="image-wrapper">
        <app-image class="dynamic-icon" [label]="btn.getIcon()" *ngIf="btn.getIcon()"></app-image>
      </span>
      <div class="line-wrapper">
        <ng-container *ngFor="let line of lines || []">
          <div
            #lineRef
            class="line"
            [style.height]="100 / lines.length + '%'"
            [style.line-height]="16 + 'px'"
            [style.font-size]="lines.length === 2 ? '12px' : lines.length === 1 ? '14px' : '10px'"
          >
            <app-image class="line-image" [label]="line.getIcon()" *ngIf="line.getIcon()"></app-image>
            <span class="line-text" *ngIf="line.getText()">{{ line.getText() }}</span>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>
</ng-template>

<!-- old button concept -->
<ng-template #defaultBtn> no longer supported </ng-template>
