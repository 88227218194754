import { ElementRef } from '@angular/core';
import { Action } from '@app-modeleditor/components/button/action/action';
import { ContextMenu } from '@app-modeleditor/components/contextmenu/contextmenu';
import { FileData } from '@app-modeleditor/components/file-uploader/filte-data';
import { Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { Hotkey } from './hotkey';
import { Resource } from './resource';
import { ETemplateEvents } from './template-events.enum';
import { ETemplateMode } from './template-mode.enum';
import { EResizeMode } from './template-resize-mode.enum';
import { ETemplateType } from './template-type';

export class Template extends Resource {
  protected restUrl: string;
  private fileToUpload: FileData;
  private saveOrder: number;
  private type: ETemplateType;
  private uuid: string;
  private editable: boolean;
  private disabled: boolean;
  private resizeable: boolean;
  private parentId: string;
  private resizeMode: EResizeMode;
  private alwaysEnabled: boolean;
  private updateOnChange: boolean;
  private show: boolean;
  private fieldIdentifier: string;
  private enabled: boolean;
  private templateMode: ETemplateMode;
  private fileUploadActions: Action[];
  private directoryUploadActions: Action[];
  private enableConditions: any;
  private enableBy: () => boolean;
  private contextMenu: ContextMenu;
  private doNotTrackChanges: boolean;
  private visibilityConditions: { [key: string]: string };
  private onFrontendValueChangeActions: Action[];
  private classList: string[];
  private getDataAutomatically: boolean;
  private dropZoneActions: { [key: string]: Action[] };
  private enabledByExternalCondition: () => boolean;
  private visibleByExternalCondition: () => boolean;
  private alreadyGotData: boolean;
  private updateTemplateRestUrl: string;
  private automaticallySetValues: boolean;
  private needsUpdate: boolean;
  private dragResize: boolean;
  private hotkeys: Hotkey[];
  private draggable: boolean;
  private valid: boolean;
  private preventUnregister: boolean;
  private preventUnregisterTemp = false;
  private onAllowUnregisterSubject = new Subject<void>();
  private registerable: boolean; // whether the template can be registered or not
  private sticky: boolean;
  private stickyLeft: number;
  private quickSearchElement: boolean;
  private compact: boolean;
  private edited: boolean;

  public isCompact(): boolean {
    return typeof this.compact === 'boolean' ? this.compact : false;
  }

  public setCompact(compact: boolean): this {
    this.compact = compact;
    return this;
  }

  public isSticky(): boolean {
    return typeof this.sticky === 'boolean' ? this.sticky : false;
  }

  public setSticky(sticky: boolean): this {
    this.sticky = sticky;
    return this;
  }

  public isQuickSearchElement(): boolean {
    return typeof this.quickSearchElement === 'boolean' ? this.quickSearchElement : false;
  }

  public setQuickSearchElement(quickSearchElement: boolean): this {
    this.quickSearchElement = quickSearchElement;
    return this;
  }

  /**
   * Sets element sticky on left side to the given position
   * @param position Position in px
   */
  public setStickyLeft(position: number): this {
    this.stickyLeft = position;
    return this;
  }

  public getStickyLeft(): number {
    return this.stickyLeft;
  }

  /**
   * whether the template is registerable or not
   * @returns boolean
   */
  public isRegisterable(): boolean {
    return typeof this.registerable === 'boolean' ? this.registerable : true;
  }

  /**
   * set whether the template is registerable or not
   * @param {boolean} registerable state whether true or false
   * @returns void
   */
  public setRegisterable(registerable: boolean): this {
    this.registerable = registerable;
    return this;
  }

  /**
   * whether the template prevents to unregister on removing ui
   * @returns boolean
   */
  public isPreventUnregister(): boolean {
    if (this.isRegisterable() === false) {
      return false;
    }
    return typeof this.preventUnregister === 'boolean' ? this.preventUnregister : false;
  }

  /**
   * Flag indicating whether the current unregistration prevention is temporary or not.
   * @returns boolean
   */
  public isPreventUnregisterTemp(): boolean {
    if (this.isRegisterable() === false) {
      return false;
    }
    return this.preventUnregisterTemp;
  }

  public setPreventUnregister(preventUnregister: boolean, temp = false): this {
    const triggerEvent = this.preventUnregister && !preventUnregister && this.preventUnregisterTemp;
    this.preventUnregister = preventUnregister;
    this.preventUnregisterTemp = preventUnregister ? temp : false;
    if (triggerEvent) {
      this.onAllowUnregisterSubject.next();
    }
    return this;
  }

  /**
   * Observable which gets triggered exactly 1 time after template unregistration got allowed again.
   */
  public get onAllowUnregister(): Observable<void> {
    return this.onAllowUnregisterSubject.asObservable().pipe(take(1));
  }

  /**
   * executed if ui of the element is destroyed
   * @returns void
   */
  public unregisterUi(): void {
    const eventType: ETemplateEvents = ETemplateEvents.UNREGISTER;
    Object.keys(this.getEventListener()[`${eventType}`] || {}).forEach((id: string) => {
      const ev: CustomEvent = new CustomEvent(eventType);
      this.getEventListener()[`${eventType}`][id](ev);
    });
  }

  public getInvalidTemplates(): Template[] {
    return [];
  }

  /**
   * whether the template is valid or not
   * @returns boolean
   */
  public isValid(): boolean {
    return typeof this.valid === 'boolean' ? this.valid : true;
  }

  /**
   * set validity of the template
   * @param {boolean} valid validity of the template
   * @returns this
   */
  public setValid(valid: boolean): this {
    this.valid = valid;
    return this;
  }

  public setEdited(edited: boolean): this {
    this.edited = edited;
    return this;
  }

  /**
   * @returns True if the entry element is marked as touched
   */
  public isEdited(): boolean {
    return typeof this.edited === 'boolean' ? this.edited : false;
  }

  public getHotkeys(): Hotkey[] {
    return this.hotkeys || [];
  }

  public setHotkeys(hotkeys: Hotkey[]): this {
    this.hotkeys = hotkeys;
    return this;
  }

  public isDraggable(): boolean {
    return typeof this.draggable === 'boolean' ? this.draggable : true;
  }

  public setDraggable(draggable: boolean): this {
    this.draggable = draggable;
    return this;
  }

  public isDragResize(): boolean {
    return typeof this.dragResize === 'boolean' ? this.dragResize : false;
  }

  public setDragResize(dragResize: boolean): this {
    this.dragResize = dragResize;
    return this;
  }

  public isResizeable(): boolean {
    return typeof this.resizeable === 'boolean' ? this.resizeable : false;
  }

  public setResizeable(resizeable: boolean): this {
    this.resizeable = resizeable;
    return this;
  }

  public isNeedsUpdate(): boolean {
    return typeof this.needsUpdate === 'boolean' ? this.needsUpdate : false;
  }

  public setNeedsUpdate(needsUpdate: boolean): this {
    this.needsUpdate = needsUpdate;
    return this;
  }

  public getParentId(): string {
    return this.parentId;
  }

  public setParentId(parentId: string): this {
    this.parentId = parentId;
    return this;
  }

  public setElementRef(elementRef: ElementRef): void {
    if (this.getEventListener()['initialized']) {
      Object.keys(this.getEventListener()['initialized']).forEach((key: string) => {
        this.getEventListener()['initialized'][key](new CustomEvent('initialized', { detail: this }));
      });
    }

    this.elementRef = elementRef;
  }
  public getElementRef(): ElementRef {
    return this.elementRef;
  }
  private elementRef: ElementRef;

  public isAutomaticallySetValues(): boolean {
    return typeof this.automaticallySetValues === 'boolean' ? this.automaticallySetValues : false;
  }

  public setAutomaticallySetValues(automaticallySetValues: boolean): this {
    this.automaticallySetValues = automaticallySetValues;
    return this;
  }

  public getUpdateTemplateRestUrl(): string {
    return this.updateTemplateRestUrl;
  }

  public setUpdateTemplateRestUrl(updateTemplateRestUrl: string): this {
    this.updateTemplateRestUrl = updateTemplateRestUrl;
    return this;
  }

  public isAlreadyGotData(): boolean {
    return this.alreadyGotData;
  }

  public setAlreadyGotData(alreadyGotData: boolean): this {
    this.alreadyGotData = alreadyGotData;
    return this;
  }

  public setEnabledByExternalCondition(condition: () => boolean): this {
    this.enabledByExternalCondition = condition;
    return this;
  }
  public setVisibleByExternalCondition(condition: () => boolean): this {
    this.visibleByExternalCondition = condition;
    return this;
  }

  private getEnabledByExternalCondition(): boolean {
    if (!this.enabledByExternalCondition) {
      return null;
    }
    return this.enabledByExternalCondition();
  }

  private getVisibleByExternalCondition(): boolean {
    if (!this.visibleByExternalCondition) {
      return null;
    }

    return this.visibleByExternalCondition();
  }

  /**
   * provides a map of droppable template ids with actions which
   * will be executed after element was dropped
   * @returns { [key: string]: Action[] }
   */
  public getDropZoneActions(): { [key: string]: Action[] } {
    return this.dropZoneActions;
  }

  public setDropZoneActions(zoneActions: { [key: string]: Action[] }): this {
    this.dropZoneActions = zoneActions;
    return this;
  }

  public isGetDataAutomatically(): boolean {
    return typeof this.getDataAutomatically === 'boolean' ? this.getDataAutomatically : true;
  }

  public setGetDataAutomatically(getDataAutomatically: boolean): this {
    this.getDataAutomatically = getDataAutomatically;
    return this;
  }

  public addClass(clazz: string): this {
    if (this.getClassList().indexOf(clazz) === -1) {
      if (!this.classList) {
        this.classList = [];
      }
      this.classList.push(clazz);
    }
    return this;
  }

  public removeClass(clazz: string): this {
    const idx: number = this.getClassList().indexOf(clazz);
    if (idx !== -1) {
      this.getClassList().splice(idx, 1);
    }
    return this;
  }

  public getClassList(): string[] {
    return this.classList || [];
  }

  public getOnFrontendValueChangeActions(): Action[] {
    return this.onFrontendValueChangeActions || [];
  }

  public setOnFrontendValueChangeActions(onFrontendValueChangeActions: Action[]): this {
    this.onFrontendValueChangeActions = onFrontendValueChangeActions;
    return this;
  }

  /**
   * acces property by key
   * @param key string
   * @returns any
   */
  public access(key: string): any {
    return this[key];
  }

  public getVisibilityConditions(): { [key: string]: string } {
    return this.visibilityConditions;
  }

  public setVisibilityConditions(conditions: { [key: string]: string }): this {
    this.visibilityConditions = conditions;
    return this;
  }

  public isDoNotTrackChanges(): boolean {
    return typeof this.doNotTrackChanges === 'boolean' ? this.doNotTrackChanges : false;
  }

  public setDoNotTrackChanges(doNotTrackChanges: boolean): this {
    this.doNotTrackChanges = doNotTrackChanges;
    return this;
  }

  public getContextmenu(): ContextMenu {
    return this.contextMenu;
  }

  public setContextmenu(contextmenu: ContextMenu): this {
    this.contextMenu = contextmenu;
    return this;
  }

  setUuid(uuid: string): this {
    this.uuid = uuid;
    return this;
  }

  setAlwaysEnabled(alwaysEnabled: boolean): this {
    this.alwaysEnabled = alwaysEnabled;
    return this;
  }

  setUpdateOnChange(updateOnChange: boolean): this {
    this.updateOnChange = updateOnChange;
    return this;
  }

  public isEnabledBy(): boolean {
    return this.enableBy ? this.enableBy() : true;
  }

  public getEnabledBy(): () => boolean {
    return this.enableBy;
  }

  public setEnableBy(cb: () => boolean): this {
    this.enableBy = cb;
    return this;
  }

  public getEnableConditions(): any {
    return this.enableConditions;
  }

  public setEnableConditions(enableConditions: any): this {
    this.enableConditions = enableConditions;
    return this;
  }

  private enableCondition: () => boolean = () => true;

  public getFileUploadActions(): Action[] {
    return this.fileUploadActions || [];
  }

  public setFileUploadActions(fileUploadActions: Action[]): this {
    this.fileUploadActions = fileUploadActions;
    return this;
  }

  public getDirectoryUploadActions(): Action[] {
    return this.directoryUploadActions || [];
  }

  public setDirectoryUploadActions(directoryUploadActions: Action[]): this {
    this.directoryUploadActions = directoryUploadActions;
    return this;
  }

  public isEnabledByCondition(): boolean {
    return this.enableCondition.bind(this);
  }

  public setEnableCondition(enableCondition: () => boolean): this {
    this.enableCondition = enableCondition;
    return this;
  }

  public getTemplateMode(): ETemplateMode {
    return this.templateMode;
  }

  public setTemplateMode(templateMode: ETemplateMode): this {
    this.templateMode = templateMode;
    return this;
  }

  /**
   * whether the template is enabled or not
   * @returns boolean
   */
  public isEnabled(): boolean {
    return typeof this.enabled === 'boolean' ? this.enabled : true;
  }

  /**
   * set enabled state of template
   * @param enabled boolean
   * @returns this
   */
  public setEnabled(enabled: boolean): this {
    this.enabled = enabled;
    return this;
  }

  /**
   * get field identifier
   * @returns string
   */
  public getFieldIdentifier(): string {
    return this.fieldIdentifier;
  }

  /**
   * get field identifier
   * @returns string
   */
  public setFieldIdentifier(identifier: string): this {
    this.fieldIdentifier = identifier;
    return this;
  }

  /**
   * whether template is shown in DOM or not
   * @returns boolean
   */
  public isShow(): boolean {
    if (typeof this.getVisibleByExternalCondition() === 'boolean') {
      return this.getVisibleByExternalCondition();
    }

    return typeof this.show === 'boolean' ? this.show : true;
  }

  /**
   * set show state of template
   * @param state boolean
   * @returns this
   */
  public setShow(state: boolean): this {
    this.show = state;
    return this;
  }

  /**
   * whethter update on change is enabled or not
   * @returns boolean
   */
  public isUpdateOnChange(): boolean {
    return this.updateOnChange;
  }

  /**
   * whether the template is always enabled or not
   * @returns boolean
   */
  public isAlwaysEnabled(): boolean {
    return typeof this.alwaysEnabled === 'boolean' ? this.alwaysEnabled : false;
  }

  /**
   * get templtes resize mode
   * @returns EResizeMode
   */
  public getResizeMode(): EResizeMode {
    return this.resizeMode;
  }

  /**
   * set templates resize mode
   * @param resizeMode EResizeMode
   * @returns this
   */
  public setResizeMode(resizeMode: EResizeMode): this {
    this.resizeMode = resizeMode;
    return this;
  }
  /**
   * get order in which templates are saved
   * @returns number
   */
  public getSaveOrder(): number {
    return this.saveOrder;
  }

  /**
   * set save order
   * @param saveOrder number
   * @returns this
   */
  public setSaveOrder(saveOrder: number): this {
    this.saveOrder = saveOrder;
    return this;
  }

  /**
   * get template type
   * @returns ETemplateType
   */
  public getType(): ETemplateType {
    return this.type;
  }

  /**
   * set template type
   * @param type v
   * @returns this
   */
  public setType(type: ETemplateType): this {
    this.type = type;
    return this;
  }

  /**
   * get uuid of template
   * @returns string
   */
  public getUuid(): string {
    return this.uuid;
  }

  /**
   * whether the template is editable or not
   * @returns boolean
   */
  public isEditable(): boolean {
    return typeof this.enabledByExternalCondition === 'boolean'
      ? this.enabledByExternalCondition
      : typeof this.editable === 'boolean'
      ? this.editable
      : true;
  }

  /**
   * enable or disable editability of template
   * @param editable boolean
   * @returns this
   */
  public setEditable(editable: boolean): this {
    this.editable = editable;
    return this;
  }

  /**
   * whether the template is disabled or not
   * @returns this
   */
  public isDisabled(): boolean {
    // is needed to ensure, that visibilityConditions/enableConditions will work
    const editable: boolean =
      typeof this.getEnabledByExternalCondition() === 'boolean'
        ? this.getEnabledByExternalCondition()
        : this.isEditable();

    return (
      !this.isAlwaysEnabled() &&
      (!this.isEnabledByCondition() ||
        this.disabled === true ||
        !this.isEnabledBy() ||
        editable === false ||
        this.isEnabled() === false)
    );
  }

  /**
   * set disabled state of template
   * @param disabled boolean
   * @returns this
   */
  public setDisabled(disabled: boolean): this {
    this.disabled = disabled;
    return this;
  }

  /**
   * get file to upload
   * @returns File
   */
  public getFileToUpload(): FileData {
    return this.fileToUpload;
  }

  /**
   * set file to upload
   * @param fileToUpload File
   * @returns this
   */
  public setFileToUpload(fileToUpload: FileData): this {
    this.fileToUpload = fileToUpload;
    return this;
  }

  /**
   * get rest url of template
   * @returns string
   */
  public getRestUrl(): string {
    return this.restUrl;
  }

  /**
   * set rest url of template
   * @param restUrl string
   * @returns this
   */
  public setRestUrl(restUrl: string): this {
    this.restUrl = restUrl;
    return this;
  }
}
