import { BestGantt, EGanttInstance, GanttDataRow, GanttDataShift } from '@gantt/public-api';
import { GanttLibService } from 'frontend/src/dashboard/gantt/gantt/gantt-lib.service';
import { GanttChildren, IGanttAttributeMapping } from 'frontend/src/dashboard/gantt/general/generator/gantt-input.data';
import { GanttEssentialPlugIns } from 'frontend/src/dashboard/gantt/general/plugin/e-gantt-essential-plugins';
import { GanttPluginHandlerService } from 'frontend/src/dashboard/gantt/general/plugin/gantt-plugin-handler.service';
import { GanttColorizerByAttributePlugIn } from 'frontend/src/dashboard/gantt/general/plugin/plugin-list/block-colorizer/by-attribute/colorizer-by-attribute';
import { GanttRestrictBlockDragPlugIn } from '../../../../plugin/plugin-list/edit-block/restrictions/restrict-block-drag';
import { IGanttResponse } from '../../../gantt-response';
import { IAddedGanttEntryResponse } from './entry-response.interface';

export class GanttAddEntriesResponse {
  private blockColorizeByAttributePlugIn: GanttColorizerByAttributePlugIn;

  constructor(
    private _ganttDiagram: BestGantt,
    private _pluginHandlerService: GanttPluginHandlerService,
    private _attributeMapping: IGanttAttributeMapping,
    private _ganttLibService: GanttLibService
  ) {
    this.blockColorizeByAttributePlugIn = _pluginHandlerService.getEssentialPlugIn(
      GanttEssentialPlugIns.BlockColorizeByAttributePlugIn
    );
  }

  /**
   * Extracts new row data from response and adds the new rows to gantt.
   * Pays attention to shift overlapping algorithm.
   * Returns false if response has not block deletion.
   * @param response Server response.
   */
  public handleResponse(response: IGanttResponse): boolean {
    if (!response.addedGanttEntries) return false;
    const addedGanttEntries: IAddedGanttEntryResponse[] = response.addedGanttEntries;

    for (const addedRowResponse of addedGanttEntries) {
      const mappedIndex = this._mapIndex(addedRowResponse.index, addedRowResponse.parentID);
      this.addGanttRowByIndexAndParentRow(addedRowResponse.ganttEntry, mappedIndex, addedRowResponse.parentID);
    }

    for (const entryResponse of addedGanttEntries) {
      for (const actionId of entryResponse.ganttActionIds) {
        this._updateGanttAction(actionId, entryResponse.ganttEntry.id);
      }
    }

    this.updateBlockRestrictions(addedGanttEntries);

    return true;
  }

  public addGanttRowByIndexAndParentRow(entry: GanttChildren, index: number, parentRowId: string): void {
    this._addGanttRowByIndexAndParentRowToGanttLib(entry, index, parentRowId);
    this._addGanttRowByIndexAndParentRowToHierarchicalPlan(entry, index, parentRowId);
  }

  /**
   * Maps the position index of gantt entry by parent ID.
   * Indices can be negative or positive.
   * Negative indices are converted into a valid index.
   * Also validates whether the position to insert exists.
   * @param index Index to map.
   * @param parentRowId Entry ID of parent entry.
   * @returns Valid index.
   */
  private _mapIndex(index: number, parentRowId: string, useGanttLibData = false): number {
    let entriesOfParentRow: GanttChildren[] | GanttDataRow[];
    if (useGanttLibData) {
      entriesOfParentRow = parentRowId
        ? this._ganttLibService.ganttInstanceService
            .getInstance(EGanttInstance.Y_AXIS_DATA_FINDER)
            .getRowById(this._ganttDiagram.getDataHandler().getOriginDataset().ganttEntries, parentRowId).data?.child
        : this._ganttDiagram.getDataHandler().getOriginDataset().ganttEntries;
    } else {
      const templateData = this._pluginHandlerService.getTemplateData();
      entriesOfParentRow = parentRowId
        ? templateData.getEntryByIdFromHierarchicalPlan(parentRowId)?.children
        : templateData.getHierarchicalPlan()?.ganttEntries;
    }
    let mappedIndex = 0;
    if (!entriesOfParentRow) {
      return 0;
    }
    if (index > 0) {
      mappedIndex = index < entriesOfParentRow.length + 1 ? index : entriesOfParentRow.length;
    } else if (index < 0) {
      // negative index means, -1 indicates the last position. -2 indicates the second to last position and so on
      const newIndex = entriesOfParentRow.length + index + 1;
      mappedIndex = newIndex < 0 ? 0 : newIndex;
    }
    return mappedIndex;
  }

  /**
   * Handles adding the gantt rows to hierarchical plan in template data.
   * @param row Row data to add
   * @param index Position of row in gantt
   * @param parentRowId Parent in which the row is placed
   */
  private _addGanttRowByIndexAndParentRowToHierarchicalPlan(
    row: GanttChildren,
    index: number,
    parentRowId: string
  ): void {
    let parentRowChildren;

    if (!parentRowId) {
      // no parent -> top level
      parentRowChildren = this._pluginHandlerService.getTemplateData().getHierarchicalPlan()?.ganttEntries;
    } else {
      parentRowChildren = this._pluginHandlerService
        .getTemplateData()
        .getEntryByIdFromHierarchicalPlan(parentRowId)?.children;
    }

    if (parentRowChildren) {
      const existingRow = parentRowChildren.find((r) => r.id === row.id);
      if (existingRow) {
        // row already exists -> check if children need to be added
        (row.children || []).forEach((child) => {
          const mappedIndex = this._mapIndex(child.index, row.id);
          this._addGanttRowByIndexAndParentRowToHierarchicalPlan(child, mappedIndex, row.id);
        });
        return;
      }
      parentRowChildren.splice(index, 0, row); // add row to hierarchical plan data set
    }
  }

  /**
   * Handles adding the gantt rows to the gantt lib.
   * @param row Row data to add
   * @param index Position of row in gantt
   * @param parentRowId Parent in which the row is placed
   */
  private _addGanttRowByIndexAndParentRowToGanttLib(row: GanttChildren, index: number, parentRowId: string) {
    const searchResult = this._ganttLibService.ganttInstanceService
      .getInstance(EGanttInstance.Y_AXIS_DATA_FINDER)
      .getRowById(this._ganttDiagram.getDataHandler().getOriginDataset().ganttEntries, row.id);

    if (searchResult && searchResult.data) {
      // row already exists -> check if children need to be added
      (row.children || []).forEach((child) => {
        const mappedIndex = this._mapIndex(child.index, row.id, true);
        this._addGanttRowByIndexAndParentRowToGanttLib(child, mappedIndex, row.id);
      });
      return;
    }

    const mapper = this._ganttLibService.backendToGanttOriginInputMapper;
    const ganttLibRow: GanttDataRow = mapper.childrenToInput(
      [row],
      this._attributeMapping,
      this._pluginHandlerService.getTemplateData().getDefaultBlockTooltipSettings(),
      this._pluginHandlerService.getTemplateData().getGanttEntryAttributeMappings()
    )[0];
    this._ganttDiagram.getDataHandler().getDataAdder().addRowToOriginDataSet(ganttLibRow, parentRowId, index);

    mapper.reinitaliseBlockClones(this._ganttDiagram.getDataHandler().getOriginDataset().ganttEntries);

    // update colorize plugin
    const allBlocksOfRow = this._getAllBlocksOfRow(ganttLibRow);
    if (allBlocksOfRow.length) {
      allBlocksOfRow.forEach((block) => {
        const clonedShiftIds = mapper.getShiftClonesByShiftId(block.id);
        if (clonedShiftIds.length) {
          clonedShiftIds.forEach((id) => this.blockColorizeByAttributePlugIn.addNewShiftToColorize(id, block.color));
        }
      });
    }
  }

  private _getAllBlocksOfRow(ganttLibRow: GanttDataRow): GanttDataShift[] {
    const allBlocks = [];
    const allShifts = (row: GanttDataRow) => {
      if (row.child?.length) {
        for (const rowChild of row.child) {
          allShifts(rowChild);
        }
      }
      if (row.shifts?.length) {
        for (const block of row.shifts) {
          allBlocks.push(block);
        }
      }
    };
    allShifts(ganttLibRow);
    return allBlocks;
  }

  /**
   * Function adds new row id to target row list of a gantt action by id.
   */
  private _updateGanttAction(ganttActionId: string, newEntryId: string) {
    const actionTriggers = this._pluginHandlerService.getExternalActionHandler().currentActionTriggers;
    for (const actionTrigger of actionTriggers) {
      if (!actionTrigger.action) continue;
      const action = actionTrigger.action;
      if (action.id === ganttActionId) {
        if (action.targetRowIDs && !action.targetRowIDs.includes(newEntryId)) {
          action.targetRowIDs.push(newEntryId);
        }
        break;
      }
    }
  }

  /**
   * Updates block restrictions for the Gantt chart based on the added Gantt entries.
   * @param addedGanttEntries An array of added Gantt entries.
   */
  private updateBlockRestrictions(addedGanttEntries: IAddedGanttEntryResponse[]): void {
    // update block restrictions
    const restrictBlockDragPlugIn: GanttRestrictBlockDragPlugIn = this._pluginHandlerService.getEssentialPlugIn(
      GanttEssentialPlugIns.RestrictBlockDragPlugIn
    );

    const entries = addedGanttEntries.map((e) => e.ganttEntry);
    restrictBlockDragPlugIn.addBlockDragRestrictionByGanttResponseData(entries);
  }
}
