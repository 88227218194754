import { Resource } from 'frontend/src/dashboard/model/resource/resource';
import { Tenant } from 'frontend/src/dashboard/view/template-footer/default-footer-components/tenant-footer/tenant';

export class User extends Resource {
  private addititionalTenants: Tenant[];
  private email: string;
  private external: boolean;
  private firstname: string;
  private fullname: string;
  private tenantId: string;
  private language: string;
  private password: string;
  private tenant: Tenant; // main tenant of the user

  /**
   * gets the main tenant of the user
   * @returns Tenant
   */
  public getTenant(): Tenant {
    return this.tenant;
  }

  /**
   * sets the main tenant of the user
   * @param {Tenant} tenant main tenant of user
   * @returns this
   */
  public setTenant(tenant: Tenant): this {
    this.tenant = tenant;
    return this;
  }

  public getPassword(): string {
    return this.password;
  }

  public setPassword(password: string): this {
    this.password = password;
    return this;
  }

  public getLanguage(): string {
    return this.language;
  }

  public setLanguage(language: string): this {
    this.language = language;
    return this;
  }

  public getAddititionalTenants(): Tenant[] {
    return this.addititionalTenants || [];
  }

  public setAddititionalTenants(addititionalTenants: Tenant[]): this {
    this.addititionalTenants = addititionalTenants;
    return this;
  }

  public getEmail(): string {
    return this.email;
  }

  public setEmail(email: string): this {
    this.email = email;
    return this;
  }

  public isExternal(): boolean {
    return typeof this.external === 'boolean' ? this.external : false;
  }

  public setExternal(external: boolean): this {
    this.external = external;
    return this;
  }

  public getFirstname(): string {
    return this.firstname;
  }

  public setFirstname(firstname: string): this {
    this.firstname = firstname;
    return this;
  }

  public getFullname(): string {
    return this.fullname;
  }

  public setFullname(fullname: string): this {
    this.fullname = fullname;
    return this;
  }

  public getTenantId(): string {
    return this.tenantId;
  }

  public setTenantId(tenantId: string): this {
    this.tenantId = tenantId;
    return this;
  }
}
