import * as d3 from 'd3';
import { GanttCallBackStackExecuter } from '../callback-tools/callback-stack-executer';

/**
 * Animation handler for opening and closing gantt rows with translate animation.
 * @keywords animation, animator, transition, handler, manager, steven spielberg
 * @class
 * @constructor
 * @param {GanttConfig} config
 */
export class GanttAnimator {
  callBack: any;

  constructor() {
    this.callBack = {
      beforeGroupTranslation: {},
      afterGroupTranslation: {},
      // only one function, no stack
      _afterGroupTranslationPrio: null,
    };
  }

  /**
   * Combines all (shift) rect elements into one group to translate them together.
   * @keywords translate, combine, merge, rect, group, change, position, animation
   * @param {selection} parentNodeSel Parent node of all elements to translate/combine in one group.
   * @param {number} yStart Vertical position on which all elements should be selected.
   * @param {number} translationY Vertical translation of group.
   * @param {MouseEvent} event Click event from row click.
   * @param {any} additionalData Data which will be given to all callback functions before group translation.
   */
  translateShiftAnimation(parentNodeSel, yStart, translationY, event, additionalData) {
    const s = this;
    const nextElements = s._getAllElementsGreaterY(parentNodeSel, yStart, 'rect');
    s._createSvgAnimationGroup(parentNodeSel, nextElements, translationY, event, additionalData);
  }

  /**
   * Combines all (shift) text elements into one group to translate them together.
   * @keywords translate, combine, merge, text, group, change, position, animation
   * @param {selection} parentNodeSel Parent node of all elements to translate/combine in one group.
   * @param {number} yStart Vertical position on which all elements should be selected.
   * @param {number} translationY Vertical translation of group.
   * @param {MouseEvent} event Click event from row click.
   * @param {any} additionalData Data which will be given to all callback functions before group translation.
   */
  translateTextOverlayAnimation(parentNodeSel, yStart, translationY, event, additionalData) {
    const s = this;
    const nextElements = s._getAllElementsGreaterY(parentNodeSel, yStart, '.gantt-text-overlay-item');
    s._createDivAnimationGroup(parentNodeSel, nextElements, translationY, event, additionalData);
  }

  /**
   * Combines all row elements into one group to translate them together.
   * @keywords translate, combine, merge, list, rows, yaxis, y axis, group, animation
   * @param rectParentNode D3 selection of group of row rects.
   * @param textParentNode D3 selection of group of row text.
   * @param yStart Vertical position on which all elements should be selected.
   * @param translationY Vertical translation of group.
   * @param event Click event from row click.
   * @param additionalData Data which will be given to all callback functions before group translation.
   */
  public translateListAnimation(
    rectParentNode: d3.Selection<HTMLDivElement, undefined, d3.BaseType, undefined>,
    textParentNode: d3.Selection<HTMLDivElement, undefined, d3.BaseType, undefined>,
    yStart: number,
    translationY: number,
    event,
    additionalData
  ): void {
    let nextRects = this._getAllElementsGreaterY(rectParentNode, yStart, '.rowElement');
    nextRects = nextRects.concat(this._getAllElementsGreaterY(rectParentNode, yStart, '.strokeContainer'));
    this._createDivAnimationGroup(rectParentNode, nextRects, translationY, null, null);

    const nextTexts = this._getAllElementsGreaterY(textParentNode, yStart, '.textElement');
    this._createDivAnimationGroup(textParentNode, nextTexts, translationY, null, null, true);
  }

  /**
   * Finds all elements which have a bigger vartical position than given y position.
   * @param parentNodeSel Parent in which the CSS selector will be executed.
   * @param y Vertical start position.
   * @param cssSelector CSS selection to detect elements.
   * @return List of found DOM elements.
   */
  private _getAllElementsGreaterY(
    parentNodeSel: d3.Selection<HTMLDivElement, undefined, d3.BaseType, undefined>,
    y: number,
    cssSelector: string
  ): HTMLElement[] {
    return parentNodeSel
      .selectAll<HTMLElement, { y: number }>(cssSelector)
      .filter(function (d) {
        return d.y > y;
      })
      .nodes();
  }

  /**
   * Change opacity of multiple elements.
   * @keywords opacity, highlight, dehighlight, animation, transition, fadein, fade, fadeout, change
   * @param {Selection} parentNodeSel D3 Selection: Parent for opactiy group.
   * @param {HTMLNode[]} nodeArray Nodes which will be affected by opacity change.
   * @param {number} startOpacity Animation start value of opacity.
   * @param {number} endOpacity Animation end value of opacity.
   */
  opacityAnimationByGroup(parentNodeSel, nodeArray, startOpacity, endOpacity) {
    const s = this;

    const animationGroup = parentNodeSel.append('g');

    for (let i = 0; i < nodeArray.length; i++) {
      animationGroup.node().appendChild(nodeArray[i]);
    }

    animationGroup.style('opacity', startOpacity).transition().style('opacity', endOpacity);
  }

  /**
   * Combines DOM elements into one group to translate them together.
   * @param parentNodeSel D3 Selection: Parent for translation group.
   * @param nodeArray Nodes which will be affected by translation.
   * @param translationY Vertical translation of group.
   * @param event Event which will be given to all callback functions before/after group translation.
   * @param additionalData Data which will be given to all callback functions before/after group translation.
   */
  private _createDivAnimationGroup(
    parentNodeSel: d3.Selection<HTMLDivElement, undefined, d3.BaseType, undefined>,
    nodeArray: HTMLElement[],
    translationY: number,
    event,
    additionalData,
    noEvents = false
  ): void {
    const s = this;

    const translationGroup = parentNodeSel
      .append('div')
      .style('position', 'absolute')
      .style('top', '0px')
      .style('width', '100%');

    for (let i = 0; i < nodeArray.length; i++) {
      translationGroup.node().appendChild(nodeArray[i]);
    }

    translationGroup
      .transition()
      .duration(300)
      .style('top', translationY + 'px')
      .on('start', function () {
        if (!noEvents) {
          GanttCallBackStackExecuter.execute(
            s.callBack.beforeGroupTranslation,
            new GanttAnimatorTranslationEvent(event, additionalData)
          );
        }
      })
      .on('end', function () {
        if (!noEvents) {
          if (s.callBack._afterGroupTranslationPrio) s.callBack._afterGroupTranslationPrio();
          GanttCallBackStackExecuter.execute(
            s.callBack.afterGroupTranslation,
            new GanttAnimatorTranslationEvent(event, additionalData)
          );
        }

        d3.select(this).remove();
      });
  }

  /**
   * Combines DOM elements into one group to translate them together.
   * @private
   * @param {Selection} parentNodeSel D3 Selection: Parent for translation group.
   * @param {HTMLNode[]} nodeArray Nodes which will be affected by translation.
   * @param {number} translationY Vertical translation of group.
   * @param {any} event Event which will be given to all callback functions before/after group translation.
   * @param {any} additionalData Data which will be given to all callback functions before/after group translation.
   */
  private _createSvgAnimationGroup(parentNodeSel, nodeArray, translationY, event, additionalData) {
    const s = this;

    const translationGroup = parentNodeSel.append('g');

    for (let i = 0; i < nodeArray.length; i++) {
      translationGroup.node().appendChild(nodeArray[i]);
    }

    translationGroup
      .transition()
      .duration(300)
      .attr('transform', 'translate(0,' + translationY + ')')
      .on('start', function () {
        GanttCallBackStackExecuter.execute(
          s.callBack.beforeGroupTranslation,
          new GanttAnimatorTranslationEvent(event, additionalData)
        );
      })
      .on('end', function () {
        if (s.callBack._afterGroupTranslationPrio) s.callBack._afterGroupTranslationPrio();
        GanttCallBackStackExecuter.execute(
          s.callBack.afterGroupTranslation,
          new GanttAnimatorTranslationEvent(event, additionalData)
        );
        d3.select(this).remove();
      });
  }

  /**
   * Translates given svg node with animation by changing transform attribute.
   * @keywords translate, change, position, transform, selection
   * @param {Selection} d3Selection SVG node to translate.
   * @param {number} xStart Horizontal translation start.
   * @param {number} yStart Vertical translation start.
   * @param {number} xEnd Horizontal translation end.
   * @param {number} yEnd Vertical translation end.
   */
  translateSVGNode(d3Selection, xStart, yStart, xEnd, yEnd) {
    d3Selection
      .attr('transform', 'translate(' + xStart + ',' + yStart + ')')
      .transition()
      .attr('transform', 'translate(' + xEnd + ',' + yEnd + ')');
  }

  /**
   * Fades in svg node by using SVG.
   * @keywords css, fadein, fade in, fade, animation, style
   * @param {Selection} d3Selection SVG node to fade in.
   */
  fadeInSVGNode(d3Selection) {
    d3Selection.node().classList.add('fade-in');
  }

  //
  // CALLBACKS
  //

  addGroupTranslationEndCallBack(id, func) {
    this.callBack.afterGroupTranslation[id] = func;
  }

  removeGroupTranslationEndCallBack(id) {
    delete this.callBack.afterGroupTranslation[id];
  }

  addGroupTranslationBeginCallBack(id, func) {
    this.callBack.beforeGroupTranslation[id] = func;
  }

  removeGroupTranslationBeginCallBack(id) {
    delete this.callBack.beforeGroupTranslation[id];
  }

  /**
   * Special callback: Only for one function which will be executed directly before afterGroupTranslation.
   */
  setGroupTranslationEndPrioCallBack(func) {
    this.callBack._afterGroupTranslationPrio = func;
  }
}

/**
 * Data class to store translation event data.
 * @class
 * @constructor
 */
export class GanttAnimatorTranslationEvent {
  event: any;
  data: any;

  constructor(event, data) {
    this.event = event;
    this.data = data;
  }
}
