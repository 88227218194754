<div #dragObject class="dragWrapper">
  <ng-container *ngIf="resourceCommunicationService.currentSelectedResources.length < 2">
    <div class="infoRect" [style.backgroundColor]="resource.color">
      <app-image [label]="resourcesDataStorageService.getResourceGroupByResourceId(resource.id)?.icon"></app-image>
      {{ resource.name }}
    </div>
    <div class="handle" [style.backgroundColor]="resource.color"></div>
  </ng-container>
  <ng-container *ngIf="resourceCommunicationService.currentSelectedResources.length > 1">
    <div class="infoRect multipleResources">
      <div class="badge">{{ resourceCommunicationService.currentSelectedResources.length }}</div>
    </div>
    <div class="handle multipleResources"></div>
  </ng-container>
</div>
