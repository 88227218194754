<div [ngClass]="globalStyle" *ngIf="display && !advanced">
  <button mat-button [matMenuTriggerFor]="menuElement" (click)="stopPropagation($event)" (onMenuOpen)="onOpen($event)">
    <span *ngIf="!showSelected">{{ title | translate | capitalize }}</span>
    <ng-container *ngIf="showSelected">
      {{ selected ? selected.name : '-' }}<mat-icon>arrow_drop_down</mat-icon>
    </ng-container>
  </button>

  <!--<button *ngIf="hidden" mat-menu-item [matMenuTriggerFor]="menuElement" (onMenuOpen)="onOpen()">{{title|translate|capitalize}}</button>
    -->
</div>

<div *ngIf="advanced" class="saxms-selector">
  <mat-list>
    <ng-container *ngFor="let item of items | sort : 'created' : true; let i = index">
      <mat-list-item *ngIf="item" [class.is-active]="selected?.id === item.id">
        <!-- [matTooltipShowDelay]="1000"
          [matTooltip]="item.name + ' - ' + (item.created|date:'dd.MM.yyyy HH:mm')" -->
        <button mat-button (click)="onClick($event, item)">
          <span class="name">{{ item.name }} </span>
          <span *ngIf="item.created" class="date">
            {{ item.created | date : 'dd.MM.yyyy HH:mm' }}
            <!--{{item.created.substring(0,18)|date:'dd.MM.yyyy HH:mm'}}-->
          </span>
        </button>
      </mat-list-item>
      <mat-divider></mat-divider>
    </ng-container>
  </mat-list>
  <button [matMenuTriggerFor]="menuElement" mat-button class="expand">
    <mat-icon>arrow_drop_down</mat-icon>
  </button>
</div>

<mat-menu [overlapTrigger]="false" #menuElement="matMenu" [yPosition]="'below'">
  <mat-divider></mat-divider>
  <button
    class="saxms-selector-menu-item"
    mat-menu-item
    [disabled]="disabled"
    *ngFor="let item of items | sort : 'created' : true; let i = index"
    [class.is-active]="selected?.id === item.id"
    (click)="onClick($event, item)"
  >
    <div *ngIf="item.created" class="leftItem item-creation-date">
      {{ item.created | date : 'dd.MM.yyyy HH:mm' }}
      <!--{{item.created.substring(0,18)|date:'dd.MM.yyyy HH:mm'}}-->
    </div>
    <div class="leftItem item-name" [matTooltipShowDelay]="1000" [matTooltip]="tooltip">
      {{ item.name | capitalize }}
    </div>
    <mat-icon *ngIf="item.icon" class="iconItem">{{ item.icon }}</mat-icon>
  </button>
  <mat-divider></mat-divider>

  <button *ngFor="let action of actions" mat-menu-item (click)="action.callback()">
    <mat-icon>{{ action.icon }}</mat-icon
    >{{ action.title | translate | capitalize }}
  </button>
</mat-menu>
