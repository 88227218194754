import { EGanttScrollContainer } from '../scroll-container.enum';
import { NodeProportionsState } from './node-proportion-state';
import { INodeProportion } from './node-proportion.interface';

/**
 * Helper class to use {@link NodeProportionsState} without the need to specify the scroll container id for every method call.
 */
export class NodeProportionsStateConnector {
  /**
   * @param _nodeProportionsState Reference to the {@link NodeProportionsState} which should be used by this {@link NodeProportionsStateConnector}.
   * @param _scrollContainerId Id of the scroll container this {@link NodeProportionsStateConnector} should provide access to.
   */
  constructor(
    protected _nodeProportionsState: NodeProportionsState,
    protected readonly _scrollContainerId: EGanttScrollContainer = EGanttScrollContainer.DEFAULT
  ) {}

  public getShiftCanvasProportions(): INodeProportion {
    return this._nodeProportionsState.getShiftCanvasProportions(this._scrollContainerId);
  }

  public setShiftCanvasProportions(shiftCanvasProportions: INodeProportion): void {
    this._nodeProportionsState.setShiftCanvasProportions(shiftCanvasProportions, this._scrollContainerId);
  }

  public getShiftViewPortProportions(): INodeProportion {
    return this._nodeProportionsState.getShiftViewPortProportions(this._scrollContainerId);
  }

  public setShiftViewPortProportions(shiftViewPortProportions: INodeProportion): void {
    this._nodeProportionsState.setShiftViewPortProportions(shiftViewPortProportions, this._scrollContainerId);
  }

  public getXAxisProportions(): INodeProportion {
    return this._nodeProportionsState.getXAxisProportions();
  }

  public setXAxisProportions(xAxisProportions: INodeProportion): void {
    this._nodeProportionsState.setXAxisProportions(xAxisProportions);
  }

  public getYAxisProportions(): INodeProportion {
    return this._nodeProportionsState.getYAxisProportions(this._scrollContainerId);
  }

  public setYAxisProportions(yAxisProportions: INodeProportion): void {
    this._nodeProportionsState.setYAxisProportions(yAxisProportions, this._scrollContainerId);
  }

  public getScrollTopPosition(): number {
    return this._nodeProportionsState.getScrollTopPosition(this._scrollContainerId);
  }

  public setScrollTopPosition(scrollTopPosition: number): void {
    this._nodeProportionsState.setScrollTopPosition(scrollTopPosition, this._scrollContainerId);
  }

  public getCurrentTopRowId(): string {
    return this._nodeProportionsState.getCurrentTopRowId(this._scrollContainerId);
  }

  public setCurrentTopRowId(currentTopRowId: string): void {
    this._nodeProportionsState.setCurrentTopRowId(currentTopRowId, this._scrollContainerId);
  }

  public getCurrentTopRowOffset(): number {
    return this._nodeProportionsState.getCurrentTopRowOffset(this._scrollContainerId);
  }

  public setCurrentTopRowOffset(currentTopRowOffset: number): void {
    this._nodeProportionsState.setCurrentTopRowOffset(currentTopRowOffset, this._scrollContainerId);
  }

  public getCurrentTopOriginRowId(): string {
    return this._nodeProportionsState.getCurrentTopOriginRowId(this._scrollContainerId);
  }

  public setCurrentTopOriginRowId(currentTopOriginRowId: string): void {
    this._nodeProportionsState.setCurrentTopOriginRowId(currentTopOriginRowId, this._scrollContainerId);
  }
}
