import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatRippleModule } from '@angular/material/core';
import { ImageModule } from '@app-modeleditor/components/image/image.module';
import { MySharedModule } from 'frontend/src/dashboard/shared/shared.module';
import { SaxmsTooltipModule } from './../tooltip/tooltip.module';
import { TileButtonComponent } from './tile-button.component';

@NgModule({
  imports: [CommonModule, MySharedModule, MatBadgeModule, ImageModule, SaxmsTooltipModule, MatRippleModule],
  declarations: [TileButtonComponent],
  exports: [TileButtonComponent],
})
export class TemplateTileButtonModule {}
