import { GanttColorConverter } from './color-converter/color-converter';

/**
 * Calculator which generates color codes based on numbers/strings.
 */
export abstract class GanttColorizer {
  /**
   * Generates hex color code by string.
   * @param {string} str
   */
  private static _getHashCodeByString(str: string): number {
    let hash = 0;
    // convert to string
    str += '';
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
  }

  /**
   * Calculates color based on hsla color wheel.
   * Colors are at least 2 degrees apart and can by brightened or darkened, all base on the hash value
   * @param {numer} hash a big number used for caluclating the color
   */
  public static getBrightColor(hash: number): string {
    const hue = (Math.abs(hash) % 180) / 180;
    const saturation = 1 - (Math.abs(hash) % 11) / 100;
    const lightness = ((Math.abs(hash) % 41) + 30) / 100;
    return GanttColorConverter.hslToHex({ h: hue * 360, s: saturation * 100, l: lightness * 100 });
  }

  /**
   * Calculates a color statically bases on the string
   * @param {string} s
   */
  public static getColorValueByString(s: string): string {
    s = 'ckya' + s + 'w'; // to get a more different result
    let n = s;
    const idx = 18 - s.length;
    if (idx > 0) {
      // make the string longer to get better color results
      for (let i = 0; i < idx; i += s.length) {
        n = `${n}${s}`;
      }
    }
    const newString = n; // to get a more different result
    const color = this.getBrightColor(this._getHashCodeByString(newString)).toUpperCase();
    return color;
  }
}
