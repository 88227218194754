import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { SaxmsTooltipModule } from '@app-modeleditor/components/tooltip/tooltip.module';
import { MySharedModule } from 'frontend/src/dashboard/shared/shared.module';
import { ErrorlogComponent } from './errorlog.component';

@NgModule({
  imports: [CommonModule, MySharedModule, MatProgressSpinnerModule, SaxmsTooltipModule],
  declarations: [ErrorlogComponent],
  exports: [ErrorlogComponent],
})
export class ErrorlogModule {}
