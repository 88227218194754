import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { RxState } from '@rx-angular/state';
import { selectSlice } from '@rx-angular/state/selections';
import { RxLet } from '@rx-angular/template/let';
import { map } from 'rxjs/operators';

interface LocalState {
  title: string;
}

@Component({
  selector: 'spinner',
  styleUrls: ['./spinner.component.scss'],
  standalone: true,
  imports: [RxLet],
  template: `
    <div class="container" style="display: flex; flex-direction: column; align-items: center; justify-content: center;">
      <div class="spinner">
        <div class="spinner-item"></div>
        <div class="spinner-item"></div>
        <div class="spinner-item"></div>
      </div>
      <div class="text" *rxLet="title$ as title">{{ title }}</div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RxState],
})
export class SpinnerComponent {
  @Input() set title(title: string) {
    this.state.set({ title });
  }

  private readonly state = inject(RxState<LocalState>);

  title$ = this.state.select(
    selectSlice(['title']),
    map(({ title }) => title)
  );
}
