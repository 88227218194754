import { Content } from '@app-modeleditor/components/content/content';
import { ContentElement } from '@app-modeleditor/components/content/content-element/content-element';
import { EDisplayOrientation } from '@app-modeleditor/components/content/content-element/display-orientation.enum';
import { EMenuMode } from '@app-modeleditor/components/content/content-element/menu-mode.enum';
import { ContentPart } from '@app-modeleditor/components/content/content-part/content-part';
import { EntryCollection } from '@app-modeleditor/components/entry-collection/entry-collection';
import { EntryElement } from '@app-modeleditor/components/entry-collection/entry-element';
import { EntryElementValue } from '@app-modeleditor/components/entry-collection/entry-element-value';
import { EFieldType } from '@app-modeleditor/components/entry-collection/field-type.enum';
import { StartAndEndTimeLink } from '@app-modeleditor/components/entry-collection/link/start-and-end-time-link';
import { IPrintItem } from '@app-modeleditor/components/print/print-item.interface';
import { PrintLightbox } from '@app-modeleditor/components/print/print-lightbox';
import { IPrintLightboxStrategy } from '@app-modeleditor/components/print/print-lightbox-strategies/print-lightbox-strategy.interface';
import { ColumnLayout } from '@app-modeleditor/components/structure/column-layout/column-layout';
import { TemplatePicker } from '@app-modeleditor/components/template-picker/template-picker';
import { TemplatePickerValue } from '@app-modeleditor/components/template-picker/template-picker-value';
import { DateUtils } from '@app-modeleditor/utils/date-utils';
import { BestGantt, EGanttInstance, EGanttTextStrategy, GanttDataContainer } from '@gantt/public-api';
import domtoimage from 'dom-to-image-more';
import { Observable, Subject, from, of } from 'rxjs';
import { bufferCount, delay, switchMap, take, tap } from 'rxjs/operators';
import { PrintFormat } from '../../../../modeleditor/components/print/print-format';
import { LegendToolbarStates } from '../../../gantt/dock/views/legend/legend-communication.service';
import { Gantt_General } from '../../general.gantt.component';
import { GanttEssentialPlugIns } from '../../plugin/e-gantt-essential-plugins';
import { GanttShiftColorByAttributeExecuter } from '../../plugin/plugin-list/block-colorizer/by-attribute/colorizer-by-attribute';
import { GanttPlugInTimePeriodGroupExecuter } from '../../plugin/plugin-list/blocking-intervals/blocking-intervals.plugin';
import { GanttImageGenerator, IGanttImageGenratorTimePeriod } from '../gantt-image-generator';

enum EPrintFormat {
  ALL_ON_ONE_PAGE = 'ALL_ON_ONE_PAGE',
  ONE_PAGE_PER_WEEK = 'ONE_PAGE_PER_WEEK',
}

export class PrintLightboxStrategyGanttLW implements IPrintLightboxStrategy {
  private printGanttRef: BestGantt;
  private printGanttScope: Gantt_General;
  private originGanttRef: BestGantt;
  private originGanttScope: Gantt_General;

  private lightboxRef: PrintLightbox;
  private imageGenerator: GanttImageGenerator;
  private readonly noRenderId = 'PrintLightboxStrategyGanttLW_NoRender';
  private pageWidth = 1570;

  private readonly lightboxFullscreen = false;
  private readonly lightboxResizeable = false;

  private formatSelection: EntryElement = null;
  private startWeekPicker: TemplatePicker = null;
  private endWeekPicker: TemplatePicker = null;
  private printLegendCheckbox: EntryElement;
  private defaultSettingsCheckbox: EntryElement;

  private selectedStartDate: number;
  private selectedEndDate: number;
  private isUsingClonedGanttState = false;

  constructor(
    originGanttScope: Gantt_General,
    originGanttRef: BestGantt,
    printGanttScope: Gantt_General = null,
    printGanttRef: BestGantt = null
  ) {
    this.originGanttScope = originGanttScope;
    this.originGanttRef = originGanttRef;
    this.printGanttScope = printGanttScope;
    this.printGanttRef = printGanttRef;
    this.imageGenerator = new GanttImageGenerator(this.printGanttRef);
  }

  public createContent(): Content {
    const printer = this.lightboxRef.getPrinter();
    printer.setLivePreview(false);

    this.lightboxRef.setWidth(35);

    const content = new Content().setContentParts([
      new ContentPart()
        .setDisplayContentpartContainer(false)
        .setContentElements([
          this.createSettingsEntries(),
          this.createCheckboxEntries(),
          this.lightboxRef.getPrinter(),
        ]),
    ]);
    return content;
  }

  private createSettingsEntries(): ContentElement {
    this.formatSelection = new EntryElement()
      .setName('PRINT.format')
      .setFieldType(EFieldType.COMBO_BOX)
      .setValue(
        new EntryElementValue().setAvailableValues(this.getAvailableFormats()).setValue(this.getAvailableFormats()[0])
      )
      .onChanges((e: EntryElementValue) => {
        // this.selectedFormat = e.getValue();
      });

    this.startWeekPicker = new TemplatePicker()
      .setId('PrintLightboxStrategyGanttLW_startWeekPicker')
      .setName('@start-week@')
      .setFieldType(EFieldType.CALENDAR_WEEK_PICKER)
      .setValue(new TemplatePickerValue().setValue(this.selectedStartDate))
      .useMillisForCalendarWeeks(true)
      .onChanges((e: TemplatePickerValue) => {
        this.setSelectedStartDate(new Date(e.getValue()));
        if (this.selectedStartDate !== e.getValue()) {
          this.startWeekPicker.setValue(
            this.startWeekPicker.getValue<TemplatePickerValue>().setValue(this.selectedStartDate)
          );
        }
      });
    this.endWeekPicker = new TemplatePicker()
      .setId('PrintLightboxStrategyGanttLW_endWeekPicker')
      .setName('@end-week@')
      .setFieldType(EFieldType.CALENDAR_WEEK_PICKER)
      .setValue(new TemplatePickerValue().setValue(this.selectedEndDate))
      .useMillisForCalendarWeeks(true)
      .onChanges((e: TemplatePickerValue) => {
        this.setSelectedEndDate(new Date(e.getValue()));
        const firstDayOfEndWeek = DateUtils.getFirstDay(new Date(this.selectedEndDate));
        firstDayOfEndWeek.setHours(0, 0, 0, 0);
        if (firstDayOfEndWeek.getTime() !== e.getValue()) {
          this.endWeekPicker.setValue(
            this.endWeekPicker.getValue<TemplatePickerValue>().setValue(this.selectedEndDate)
          );
        }
      });

    const settingsEntries = new EntryCollection()
      .setDisplayOrientation(EDisplayOrientation.VERTICAL)
      .setEntryElements([this.formatSelection, this.startWeekPicker, this.endWeekPicker])
      .setLinks([new StartAndEndTimeLink(this.startWeekPicker.getId(), this.endWeekPicker.getId())]);

    return settingsEntries;
  }

  private createCheckboxEntries(): ContentElement {
    this.printLegendCheckbox = new EntryElement()
      .setName('PRINT.printGanttLegend')
      .setFieldType(EFieldType.CHECK_BOX)
      .setValue(new EntryElementValue().setValue(true));
    this.defaultSettingsCheckbox = new EntryElement()
      .setName('PRINT.useDefaultSettings')
      .setFieldType(EFieldType.CHECK_BOX)
      .setValue(new EntryElementValue().setValue(false));
    // apply initial checkbox settings
    if (this.printGanttScope) {
      this.defaultSettingsCheckbox.executeChanges(this.defaultSettingsCheckbox.getValue<EntryElementValue>());
    }

    const checkboxEntries = new EntryCollection()
      .setLayout(
        new ColumnLayout()
          .setColumnCount(2)
          .setColumnWidths({ 0: 50, 1: 50 })
          .setContent({ 0: [this.printLegendCheckbox.getId()], 1: [this.defaultSettingsCheckbox.getId()] })
      )
      .setEntryElements([this.printLegendCheckbox, this.defaultSettingsCheckbox]);

    return checkboxEntries;
  }

  /**
   * Applies the selected print format to the print gantt.
   * @param timePeriod Reference to the printed time period (used to adjust the gantt height).
   * @returns Observable which will be triggered after all asynchronous actions are completed.
   */
  private setSelectedFormat(timePeriod: IGanttImageGenratorTimePeriod): Observable<void> {
    const selectedPrintFormat: PrintFormat = this.formatSelection.getValue<EntryElementValue>().getValue();
    return of(null).pipe(
      tap(() => {
        // adjust zoom to selected format because height depends on it
        this.printGanttRef.getXAxisBuilder().zoomToTimeSpan(timePeriod.start, timePeriod.end, false);
      }),
      delay(500),
      tap(() => {
        const nodeProportionState = this.printGanttRef.getNodeProportionsState();
        const xAxisContainerHeight = this.printGanttRef.getConfig().isXAxisVisible()
          ? this.printGanttRef.getConfig().xAxisContainerHeight()
          : this.printGanttRef.getConfig().xAxisScrollBarHeight();
        const newFormat = new PrintFormat();

        newFormat.setName(selectedPrintFormat.getName());
        newFormat.setValue(selectedPrintFormat.getValue());
        newFormat.setHeight(xAxisContainerHeight + nodeProportionState.getShiftCanvasProportions().height + 7);
        newFormat.setWidth(this.pageWidth);
        this.lightboxRef.getPrinter().setToggled(false);
        this.lightboxRef.getPrinter().setSelectedFormat(newFormat);
      }),
      delay(500)
    );
  }

  public print(): Observable<IPrintItem[]> {
    // 1. Modify print gantt based on the specified settings
    const printLegend = this.printLegendCheckbox.getValue<EntryElementValue>().getValue<boolean>();
    const defaultPrintSettings = this.defaultSettingsCheckbox.getValue<EntryElementValue>().getValue<boolean>();

    if (defaultPrintSettings) {
      this._removeClonedGanttState();
      this._applyDefaultPrintSettings();
    } else {
      this._removeDefaultPrintSettings();
      this._cloneGanttState();
    }

    // 2. Determine time periods to print
    const timePeriods: IGanttImageGenratorTimePeriod[] = [];
    const selectedPrintFormat = this.formatSelection.getValue<EntryElementValue>().getValue<PrintFormat>();
    if (selectedPrintFormat.getValue<EPrintFormat>() === EPrintFormat.ALL_ON_ONE_PAGE) {
      timePeriods.push({ start: new Date(this.selectedStartDate), end: new Date(this.selectedEndDate) });
    } else {
      timePeriods.push(...this.generatePrintWeeks());
    }
    const printBufferLength = printLegend ? timePeriods.length * 2 : timePeriods.length;

    // 3. Create buffer subject to store generated images (will be released if all images are generated)
    const printBufferSubject = new Subject<IPrintItem>();
    const printBufferObservable = printBufferSubject.asObservable().pipe(bufferCount(printBufferLength), take(1));

    // 4. Generate images of gantt and legend for each time period (and pass them to buffer subject)
    const printGanttInitiatorSubject = new Subject<IGanttImageGenratorTimePeriod>();
    let i = 0;

    printGanttInitiatorSubject.asObservable().subscribe((timePeriod) => {
      // print gantt for time period
      this._printGantt(timePeriod).subscribe((ganttImage: IPrintItem) => {
        // pass generated image to buffer
        printBufferSubject.next(ganttImage);
        // if specified -> print legend
        if (printLegend) {
          this._printLegend().subscribe((legendImage: IPrintItem) => {
            // pass generated image to buffer
            printBufferSubject.next(legendImage);
            // trigger printing of next time period
            if (i < timePeriods.length) printGanttInitiatorSubject.next(timePeriods[i++]);
          });
        }
        // else -> trigger printing of next time period
        else {
          if (i < timePeriods.length) printGanttInitiatorSubject.next(timePeriods[i++]);
        }
      });
    });
    printGanttInitiatorSubject.next(timePeriods[i++]);

    return printBufferObservable;
  }

  /**
   * Clones the current gantt state and applies it to the print gantt.
   */
  private _cloneGanttState(): void {
    // clone gantt lib data
    const originOverlappingShiftsPlugIn = this.originGanttScope.ganttPluginHandlerService.getEssentialPlugIn(
      GanttEssentialPlugIns.OverlappingShiftsPlugIn
    );
    const printOverlappingShiftsPlugIn = this.printGanttScope.ganttPluginHandlerService.getEssentialPlugIn(
      GanttEssentialPlugIns.OverlappingShiftsPlugIn
    );
    originOverlappingShiftsPlugIn.resetSplitOverlappingShifts(false);
    printOverlappingShiftsPlugIn.resetSplitOverlappingShifts(false);

    const ganttDataSetClone: GanttDataContainer = structuredClone(
      this.originGanttRef.getDataHandler().getOriginDataset()
    );
    this.printGanttRef.getDataHandler().setOriginDataset(ganttDataSetClone);

    originOverlappingShiftsPlugIn.splitOverlappingShifts();
    printOverlappingShiftsPlugIn.splitOverlappingShifts();

    // clone legend state
    const originLegendService = this.originGanttScope.getLegendCommunicationService();
    const printLegendService = this.printGanttScope.getLegendCommunicationService();
    originLegendService.getLegendData().legendEntries.forEach((legendEntry) => {
      // hide hidden legend entries
      const printLegendEntry = printLegendService.getLegendData().legendEntries.find((e) => e.id === legendEntry.id);
      if (legendEntry && printLegendEntry && legendEntry.isActive !== printLegendEntry.isActive) {
        printLegendEntry.isActive = legendEntry.isActive;
      }
    });
    const legendStates: LegendToolbarStates = structuredClone(originLegendService.getAllToolbarStates());
    printLegendService.setAllToolbarStates(legendStates);
    printLegendService.updateLegendUI();

    this.isUsingClonedGanttState = true;
  }

  /**
   * Removes the cloned gantt state from the print gantt and applies the original state.
   */
  private _removeClonedGanttState(): void {
    if (!this.isUsingClonedGanttState) return;

    // reset gantt lib data
    const printOverlappingShiftsPlugIn = this.printGanttScope.ganttPluginHandlerService.getEssentialPlugIn(
      GanttEssentialPlugIns.OverlappingShiftsPlugIn
    );
    printOverlappingShiftsPlugIn.resetSplitOverlappingShifts();

    const printGanttTemplateData = this.printGanttScope.ganttTemplateDataService.getTemplateData();
    this.printGanttRef
      .getDataHandler()
      .setOriginDataset(
        this.printGanttScope.ganttLibService.backendToGanttOriginInputMapper.ganttToInput(
          printGanttTemplateData.getHierarchicalPlan(),
          printGanttTemplateData.getAttributeMapping(),
          printGanttTemplateData.getDefaultBlockTooltipSettings(),
          printGanttTemplateData.getGanttEntryAttributeMappings()
        )
      );

    printOverlappingShiftsPlugIn.splitOverlappingShifts();

    // reset legend state
    const printLegendService = this.printGanttScope.getLegendCommunicationService();
    printLegendService.getLegendData().legendEntries.forEach((legendEntry) => {
      // show all legend entries
      if (!legendEntry.isActive) legendEntry.isActive = true;
    });
    printLegendService.setAllToolbarStates(new LegendToolbarStates());
    printLegendService.updateLegendUI();

    this.isUsingClonedGanttState = false;
  }

  /**
   * Prints the gantt legend as image.
   * @returns Observable returning the generated image as data url.
   */
  private _printLegend(): Observable<IPrintItem> {
    this._applyFullLegendHeight();
    this.printGanttScope.restoreGanttLegend();
    const legendElementRef = this.printGanttScope.getLegendDockWrapper();
    return from(domtoimage.toJpeg(legendElementRef.nativeElement) as string).pipe(
      switchMap((dataUrl: string) => {
        this.printGanttScope.getGanttDockService().resetGanttDock();
        return of({
          dataUrl: dataUrl,
          pageBreakBefore: false,
          pageBreakInside: false,
        });
      })
    );
  }

  /**
   * Prints the gantt of the specified time period as image.
   * @param timePeriod Time period to print the gantt for.
   * @returns Observable returning the generated image as data url.
   */
  private _printGantt(timePeriod: IGanttImageGenratorTimePeriod): Observable<IPrintItem> {
    const ShiftDataFinder = this.printGanttScope.ganttLibService.ganttInstanceService.getInstance(
      EGanttInstance.SHIFT_DATA_FINDER
    );

    // filter gantt dataset to display only shifts in current time period
    this.printGanttScope.getLegendCommunicationService().ignoredNoRenderIds.add(this.noRenderId);
    ShiftDataFinder.filterShiftsByTimespan(
      this.printGanttRef.getDataHandler().getOriginDataset().ganttEntries,
      timePeriod,
      this.noRenderId
    );
    const overlappingShiftsPlugIn = this.printGanttScope.ganttPluginHandlerService.getEssentialPlugIn(
      GanttEssentialPlugIns.OverlappingShiftsPlugIn
    );
    overlappingShiftsPlugIn.resplitOverlappingShifts();

    // apply print format
    return this.setSelectedFormat(timePeriod).pipe(
      switchMap(() => {
        // generate image
        return this.imageGenerator.generateImage();
      }),
      switchMap((dataUrl: string) => {
        // reset gantt dataset filter
        ShiftDataFinder.removeShiftNoRender(
          this.printGanttRef.getDataHandler().getOriginDataset().ganttEntries,
          this.noRenderId
        );
        // generate print item
        return of({
          dataUrl: dataUrl,
          pageBreakBefore: true,
          pageBreakInside: true,
        });
      })
    );
  }

  /**
   * Applies default settings on print gantt.
   */
  private _applyDefaultPrintSettings(): void {
    // activate index cards
    const indexCardsPlugIn = this.printGanttScope.ganttPluginHandlerService.getEssentialPlugIn(
      GanttEssentialPlugIns.IndexCardBuilderPlugIn
    );
    indexCardsPlugIn.activateIndexCardView();
    // activate text strategy "SHOW_ALWAYS_LABELS_SPLIT"
    const activeTextStrategy = this.printGanttRef.getTextOverlay().getActiveStrategyType();
    if (activeTextStrategy !== EGanttTextStrategy.SHOW_ALWAYS_LABELS_SPLIT) {
      this.printGanttRef.getShiftFacade().changeTextOverlayStrategy(EGanttTextStrategy.SHOW_ALWAYS_LABELS_SPLIT);
    }
  }

  /**
   * Remove applied default settings from print gantt.
   */
  private _removeDefaultPrintSettings(): void {
    const printGanttSettings = this.printGanttScope.ganttSettingsService.getGanttSettings();
    // reset index cards
    const indexCardsPlugIn = this.printGanttScope.ganttPluginHandlerService.getEssentialPlugIn(
      GanttEssentialPlugIns.IndexCardBuilderPlugIn
    );
    if (indexCardsPlugIn.isActive() !== printGanttSettings.enableIndexCardView) {
      if (printGanttSettings.enableIndexCardView) indexCardsPlugIn.activateIndexCardView();
      else indexCardsPlugIn.deactivateIndexCardView();
    }
    // reset text strategy
    if (this.printGanttRef.getTextOverlay().getActiveStrategyType() !== printGanttSettings.labelMode) {
      this.printGanttRef.getShiftFacade().changeTextOverlayStrategy(printGanttSettings.labelMode);
    }
  }

  /**
   * Calculates and applies the height the gantt legend must have to be able to display all
   * legend entries on the printed gantt.
   */
  private _applyFullLegendHeight(): void {
    const legendProportions = {
      entries: { width: 240, height: 24 },
      menubar: { height: 24 },
      width: parseFloat(this.printGanttScope.rootWidth),
    };

    let legendHeight = legendProportions.menubar.height;

    const entryCountPerLine = Math.floor(legendProportions.width / legendProportions.entries.width);
    const entryCount =
      this.printGanttScope
        .getLegendCommunicationService()
        .getLegendData()
        .legendEntries?.filter((legendEntry) => (legendEntry.noRender?.length || 0) <= 0).length || 0;

    legendHeight += Math.ceil(entryCount / entryCountPerLine) * legendProportions.entries.height;

    this.printGanttScope.ganttSettingsService.changeSettings({ dockBelowSize: legendHeight });
  }

  public getLightboxFullscreen(): boolean {
    return this.lightboxFullscreen;
  }

  public getLightboxResizeable(): boolean {
    return this.lightboxResizeable;
  }

  public setLightboxRef(lightboxRef: PrintLightbox): void {
    this.lightboxRef = lightboxRef;
  }

  private getAvailableFormats(): PrintFormat[] {
    return [
      new PrintFormat()
        .setName('PRINT.specialFormats.onePagePerWeek')
        .setWidth(297)
        .setHeight(0)
        .setValue(EPrintFormat.ONE_PAGE_PER_WEEK),
      new PrintFormat()
        .setName('PRINT.specialFormats.allOnOnePage')
        .setWidth(297)
        .setHeight(0)
        .setValue(EPrintFormat.ALL_ON_ONE_PAGE),
    ];
  }

  public setPrintGanttRef(printGanttRef: BestGantt): void {
    this.printGanttRef = printGanttRef;
    this.lightboxRef.getPrinter().setToggled(false);
    this.lightboxRef
      .getPrinter()
      .setSelectedFormat(
        new PrintFormat()
          .setName('PRINT.specialFormats.onePagePerWeek')
          .setWidth(this.pageWidth)
          .setHeight(500)
          .setValue(EPrintFormat.ONE_PAGE_PER_WEEK)
      );
    this.printGanttRef.collapseAllRows(false);
    this.imageGenerator.setGanttRef(this.printGanttRef);
    this._initSelectedDates();
    // reapply selection of gantt settings checkbox
    this.defaultSettingsCheckbox.executeChanges(this.defaultSettingsCheckbox.getValue<EntryElementValue>());
  }

  public setPrintGanttScope(printGanttScope: Gantt_General): void {
    this.printGanttScope = printGanttScope;

    printGanttScope.toolbar.setMenuMode(EMenuMode.HIDE);
    printGanttScope.getGanttDockService().resetGanttDock();

    // reapply selection of gantt settings checkbox
    this.defaultSettingsCheckbox.executeChanges(this.defaultSettingsCheckbox.getValue<EntryElementValue>());
  }

  public applyColorByAttribute(colorizeParams, colorizeStrategy): void {
    const colorizerPlugIn: any = this.printGanttRef.getPlugInHandler().getPlugIns()[GanttShiftColorByAttributeExecuter];
    colorizerPlugIn.colorizeByAttribute(colorizeParams, colorizeStrategy);
    this.printGanttRef.update();
  }

  public applyFilteredIntervals(filteredIntervals: { [id: string]: boolean }): void {
    const periodGroupExecuter = this.printGanttRef.getPlugInHandler().getPlugIns()[GanttPlugInTimePeriodGroupExecuter];
    if (!periodGroupExecuter) return;
    Object.keys(filteredIntervals || {}).forEach((id) => {
      periodGroupExecuter.setRenderById(id, filteredIntervals[id]);
    });
  }

  /**
   * Initializes selected start and end dates for week pickers.
   */
  private _initSelectedDates(): void {
    // set new selected start/end dates
    if (this.printGanttRef) {
      const now = new Date();
      this.setSelectedStartDate(now);
      this.setSelectedEndDate(now);
    } else {
      this.selectedStartDate = null;
      this.selectedEndDate = null;
    }

    // update week picker values
    if (this.startWeekPicker) {
      this.startWeekPicker.setValue(
        this.startWeekPicker.getValue<TemplatePickerValue>().setValue(this.selectedStartDate)
      );
    }
    if (this.endWeekPicker) {
      this.endWeekPicker.setValue(this.endWeekPicker.getValue<TemplatePickerValue>().setValue(this.selectedEndDate));
    }
  }

  /**
   * Sets a new selected start date after checking its validity.
   * @param selectedDate New selected date.
   */
  private setSelectedStartDate(selectedDate: Date): void {
    selectedDate = this.getClosestDateInGantt(selectedDate);
    selectedDate.setHours(0, 0, 0, 0);
    this.selectedStartDate = DateUtils.getFirstDay(new Date(selectedDate)).getTime();
  }

  /**
   * Sets a new selected end date after checking its validity.
   * @param selectedDate New selected date.
   */
  private setSelectedEndDate(selectedDate: Date): void {
    selectedDate = this.getClosestDateInGantt(selectedDate);
    selectedDate.setHours(0, 0, 0, 0);
    const firstDayOfWeek = DateUtils.getFirstDay(selectedDate);
    this.selectedEndDate = firstDayOfWeek.getTime() + this.getMillisOfWeek(firstDayOfWeek) - 1;
  }

  /**
   * Checks a specific date if it is in gantt or not.
   * @param date Date to check.
   * @returns Checked date (if in gantt) or the closest date in gantt.
   */
  private getClosestDateInGantt(date: Date): Date {
    if (!this.printGanttRef) return date;
    const ganttStartMs = this.printGanttRef.getDataHandler().getOriginDataset().minValue.getTime();
    const ganttEndMs = this.printGanttRef.getDataHandler().getOriginDataset().maxValue.getTime();
    const dateMs = date.getTime();

    if (dateMs < ganttStartMs) {
      return new Date(ganttStartMs);
    }
    if (dateMs > ganttEndMs) {
      return new Date(ganttEndMs);
    }
    return date;
  }

  /**
   * Generates a list of time periods representing all weeks between the specified dates.
   */
  private generatePrintWeeks(): IGanttImageGenratorTimePeriod[] {
    const startDate = new Date(this.selectedStartDate);
    const endDate = new Date(this.selectedEndDate);

    const printWeeks: IGanttImageGenratorTimePeriod[] = [];
    for (
      let firstDay = startDate.getTime();
      firstDay <= endDate.getTime();
      firstDay += this.getMillisOfWeek(new Date(firstDay))
    ) {
      printWeeks.push(this.getRangeOfWeek(new Date(firstDay)));
    }
    return printWeeks;
  }

  /**
   * Calculates the length of the week which contains the specified date.
   * @param dayInWeek Date in week.
   * @returns Length of week in ms.
   */
  private getMillisOfWeek(dayInWeek: Date): number {
    const rangeOfWeek = this.getRangeOfWeek(dayInWeek);
    const rangeIsFrom = rangeOfWeek.start.getTime();
    const rangeIsTo = rangeOfWeek.end.getTime();
    return rangeIsTo - rangeIsFrom + 1;
  }

  /**
   * Calculates the range of the week which contains the specified date.
   * @param dayInWeek Date in week.
   * @returns Range of the week as time period between first and last ms.
   */
  private getRangeOfWeek(dayInWeek: Date): IGanttImageGenratorTimePeriod {
    const simple = new Date(dayInWeek.getFullYear(), dayInWeek.getMonth(), dayInWeek.getDate());
    const ISOweekStart = simple;
    ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    const dateFrom = ISOweekStart;
    let dateTo = new Date(dateFrom);
    dateTo.setDate(dateFrom.getDate() + 7);
    dateTo = new Date(dateTo.getTime() - 1);
    return { end: dateTo, start: dateFrom };
  }
}
