import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { MatLegacySliderChange as MatSliderChange } from '@angular/material/legacy-slider';
import { AttributeCategory } from '../../hotspot/hotspot';
import { EColorSchemenStyles } from './colorscheme-color-slider/colorscheme';

@Component({
  selector: 'template-hotspot-colorscheme-sidebar',
  templateUrl: './hotspot-colorscheme-sidebar.component.html',
  styleUrls: ['./hotspot-colorscheme-sidebar.component.scss'],
  providers: [],
})
export class HotspotColorschemeSidebarComponent implements OnInit, OnDestroy {
  @Input() attributeCategories: AttributeCategory[] = [];
  @Input() currentAttribute: AttributeCategory;
  @Output() afterChangeCurrentAttribute: EventEmitter<AttributeCategory> = new EventEmitter();
  @Output() afterColorValueChanges: EventEmitter<any> = new EventEmitter();

  value = 0;
  ngOnInit(): void {
    this.getColorSchemestyles();
  }

  ngOnDestroy(): void {}

  public changeAttributeCategory(change: MatSelectChange) {
    this.currentAttribute = this.attributeCategories.find((attributes) => attributes.getId() === change.value);
    this.afterChangeCurrentAttribute.emit(this.currentAttribute);
  }

  public changeColorSchemeStyle(change: MatSelectChange) {
    this.currentAttribute.getColorScheme().setStyle(change.value);
    if (change.value === EColorSchemenStyles.LINEAR) {
      this.currentAttribute.getColorScheme().calcLinearColorString();
    }
    this.afterChangeCurrentAttribute.emit(this.currentAttribute);
    this.afterColorValueChanges.emit();
  }

  public getColorSchemestyles() {
    const enumStringArrray = [];
    for (const key in EColorSchemenStyles) {
      enumStringArrray.push(EColorSchemenStyles[key]);
    }
    return enumStringArrray;
  }

  public getStyleSelectDisabled() {
    if (this.currentAttribute) {
      this.currentAttribute
        .getColorScheme()
        .getColoredValues()
        .forEach((colorValue) => {
          if (isNaN(parseInt(colorValue.getValue()))) {
            return true;
          }
        });
    }
    return false;
  }

  public afterColorValueChange() {
    this.afterColorValueChanges.emit();
  }

  public changeOpacity(event: MatSliderChange) {
    this.currentAttribute.getColorScheme().setOpacity(event.value);
    this.afterColorValueChanges.emit();
  }
}
