<ng-container *ngIf="template">
  <h2>{{ template.headline }}</h2>
  <form class="example-form" *ngIf="isVerticalOrientation">
    <mat-form-field class="example-full-width">
      <mat-label>{{ '@search@' | translate }}</mat-label>
      <input matInput [placeholder]="'@search@' | translate" [value]="filterValue" (keyup)="handleFilterList($event)" />
      <span class="item-count" matSuffix>{{
        (template.value.length !== displayValues.length ? displayValues.length + '|' : '') + template.value.length
      }}</span>
    </mat-form-field>
  </form>
  <ng-container *ngIf="!template.multiSelect">
    <mat-radio-group (change)="onChangePerformed($event)" [disabled]="disabled" class="scrollport-wrapper">
      <cdk-virtual-scroll-viewport class="scrollport" itemSize="46">
        <ng-container *cdkVirtualFor="let entry of displayValues">
          <mat-radio-button [checked]="entry.selected" [value]="entry" class="log-entry">
            <ng-container *ngIf="!entry.simpleElement; else simpleElement">
              <div
                *ngFor="let content of entry.content.contentParts"
                style="float: left"
                [style.width]="100 / entry.content.contentParts.length + '%'"
              >
                <ng-container *ngFor="let cElem of content.contentElement">
                  <div *ngFor="let elem of cElem.entryElements" style="word-wrap: break-word">
                    <ng-container *ngIf="elem.entry">
                      <mat-form-field *ngIf="elem.fieldType === 'TEXT'" class="form-control">
                        <input
                          matInput
                          [disabled]="!elem.editable"
                          placeholder="{{ elem.name }}"
                          [(ngModel)]="elem.entry.value"
                        />
                      </mat-form-field>
                      <span *ngIf="elem.fieldType === 'LABELED_TEXT'">{{ elem.entry.value }}</span>
                    </ng-container>
                    <ng-container *ngIf="!elem.entry">{{ '@missing entry.entry@' }}</ng-container>
                  </div>
                </ng-container>
              </div>
            </ng-container>
            <ng-template #simpleElement>
              <span
                *ngIf="entry.floatLeft || (entry.floatLeftLines && entry.floatLeftLines.length > 0)"
                class="floating-container"
                style="float: left; width: 49%; text-overflow: ellipsis; text-align: left"
              >
                <ng-container *ngFor="let line of entry.floatLeftLines">
                  <div
                    [matTooltip]="line"
                    class="floating-line"
                    [style.float]="entry.displayOrientation === 'VERTICAL' ? '' : 'left'"
                  >
                    {{ line }}
                  </div>
                </ng-container>
                <ng-container *ngIf="entry.floatLeft">
                  <span [matTooltip]="entry.floatLeft" class="table-cell">
                    <span class="vertical-aligned-text">{{ entry.floatLeft }}</span>
                  </span>
                </ng-container>
              </span>
              <span
                *ngIf="entry.floatRight || (entry.floatRightLines && entry.floatRightLines.length > 0)"
                class="floating-container"
                style="float: right; width: 49%; text-overflow: ellipsis; text-align: right"
              >
                <ng-container *ngFor="let line of entry.floatRightLines">
                  <div
                    [matTooltip]="line"
                    class="floating-line"
                    [style.float]="entry.displayOrientation === 'VERTICAL' ? '' : 'left'"
                  >
                    {{ line }}
                  </div>
                </ng-container>
                <ng-container *ngIf="entry.floatRight">
                  <span [matTooltip]="entry.floatRight" style="float: right" class="table-cell">
                    <span class="vertical-aligned-text">{{ entry.floatRight }}</span></span
                  >
                </ng-container>
              </span>
              <span
                *ngIf="entry.floatFullLines || entry.floatFull"
                class="floating-container"
                style="float: left; width: 100%; text-overflow: ellipsis; text-align: left"
              >
                <ng-container *ngFor="let line of entry.floatFullLines">
                  <div
                    [matTooltip]="line"
                    class="floating-line"
                    [style.float]="entry.displayOrientation === 'VERTICAL' ? '' : 'left'"
                  >
                    {{ line }}
                  </div>
                </ng-container>
                <ng-container *ngIf="entry.floatFull">
                  <span class="vertical-aligned-text" [matTooltip]="entry.floatFull">
                    <span class="vertical-aligned-text">{{ entry.floatFull }}</span></span
                  >
                </ng-container>
              </span>
            </ng-template>
          </mat-radio-button>
        </ng-container>
      </cdk-virtual-scroll-viewport>
    </mat-radio-group>
  </ng-container>
  <ng-container *ngIf="template.multiSelect && isFinished">
    <mat-checkbox
      style="padding-left: 10px; padding-right: 10px"
      class="all-select full-width"
      [disabled]="disabled"
      (change)="toggleCheck()"
      [(ngModel)]="allSelected"
      [(indeterminate)]="indeterminate"
      [ngClass]="template.getDisplayOrientation()"
    >
      <div style="display: flex; justify-content: center; flex-direction: column; height: 100%">
        <span style="line-height: 16px">{{ '@select_all@' | translate | capitalize }}</span>
        <span
          [translate]="'LABEL.selected_x_of_y'"
          [translateParams]="{ x: numberOfSelected, y: template.value.length }"
          *ngIf="numberOfSelected && template.getDisplayOrientation() !== 'HORIZONTAL'"
          style="line-height: 16px; font-size: 12px; color: rgb(150, 150, 150)"
        >
        </span>
      </div>
    </mat-checkbox>
    <hr *ngIf="isVerticalOrientation" />
    <div class="scrollport-wrapper-checkbox" [ngClass]="template.getDisplayOrientation()">
      <cdk-virtual-scroll-viewport
        [orientation]="template.getDisplayOrientation().toLowerCase()"
        class="scrollport"
        [itemSize]="!isVerticalOrientation ? 119 : 49"
      >
        <ng-container *cdkVirtualFor="let entry of displayValues">
          <mat-checkbox
            [disabled]="disabled"
            class="list-item full-width bubble"
            [(ngModel)]="entry.selected"
            (change)="onChangePerformed(null)"
            [ngClass]="template.getDisplayOrientation()"
          >
            <ng-container *ngIf="!entry.floatFull; else floatFull">
              <span
                class="text table-cell"
                [matTooltip]="entry.floatLeft"
                #floatLeftContainer
                style="float: left; width: 50%; white-space: normal"
              >
                <span class="vertical-aligned-text">{{ entry.floatLeft }}</span>
              </span>
              <span
                [matTooltip]="entry.floatRight"
                class="text table-cell"
                style="float: right; width: 50%; text-align: right; white-space: normal"
              >
                <span class="vertical-aligned-text" style="float: right">{{ entry.floatRight }}</span></span
              >
            </ng-container>
            <ng-template #floatFull>
              <span
                class="text table-cell"
                [matTooltip]="entry.floatFull"
                style="float: left; width: 100%; white-space: normal"
              >
                <span class="vertical-aligned-text">{{ entry.floatFull }}</span></span
              >
            </ng-template>
          </mat-checkbox>
        </ng-container>
      </cdk-virtual-scroll-viewport>
    </div>
  </ng-container>
</ng-container>
