import * as d3 from 'd3';
import { GanttConfig } from '../../config/gantt-config';
import { TextOverlay } from '../../shifts/text-overlay';
import { GanttTexturizer } from './texturizer-executer';
import { takeUntil } from 'rxjs';

/**
 * Builder for shift headline texture.
 * @keywords plugin, helper, text, headline, element, builder, render
 * @plugin texturizer
 * @class
 * @constructor
 * @param {TextOverlay} textOverlay
 */
export class GanttTextureCreator {
  config: GanttConfig;
  textOverlay: TextOverlay;
  defParent: any;
  backgroundPatternRects: d3.Selection<any, any, null, undefined>[];
  backgroundPattern: d3.Selection<any, any, null, undefined>[];
  UUID: string;
  patternSuffix: string;
  headlines: any;

  constructor(textOverlay) {
    this.config = null;

    this.textOverlay = textOverlay;

    this.defParent = null;
    this.backgroundPatternRects = [];
    this.backgroundPattern = [];
    this.UUID = '_PlugIn_' + (Math.random().toString(36) + '00000000000000000').slice(2, 11 + 2);

    this.patternSuffix = null;

    this.headlines = {};
  }

  /**
   * Initializes texturize-builder.
   * @param executer Executing texturizer plug-in.
   * @param config Gantt config.
   * @param defParent Node which holds texture def-node.
   * @param patternSuffix Suffix which will be added to declare user of def node indside gantt DOM structure.
   */
  public init(executer: GanttTexturizer, config: GanttConfig, defParent: SVGDefsElement, patternSuffix: string): void {
    const s = this;
    s.config = config;
    s.defParent = defParent;
    s.patternSuffix = patternSuffix;

    // init callbacks
    this.config
      .onShiftHeightChanged()
      .pipe(takeUntil(executer.onDestroy))
      .subscribe((shiftHeight) => this.recalculatePatternHeight(shiftHeight));
  }

  addSelectedAndHighlightedPatterns() {
    const s = this;
    s.createRectHeadline('selected', s.config.colorSelect());
    s.createRectHeadline('highlighted', s.config.colorHighlight());
  }

  /**
   * Changes headline texture to fit into new shift height.
   * @param {number} shiftHeight
   */
  recalculatePatternHeight(shiftHeight) {
    const s = this;
    for (var i = 0; i < s.backgroundPatternRects.length; i++) {
      const backgroundRect = s.backgroundPatternRects[i];
      if (backgroundRect && backgroundRect.attr('height')) backgroundRect.attr('height', shiftHeight);
    }
    for (var i = 0; i < s.backgroundPattern.length; i++) {
      const backgroundPattern = s.backgroundPattern[i];
      backgroundPattern.attr('height', shiftHeight);
    }
    s.updateHeadlineHeight();
  }

  /**
   * Creates SVG-pattern-nodes for shift headline texture.
   * @param {string} patternId Unique id of pattern.
   * @param {string} color Color of headline background.
   */
  createRectHeadline(patternId, color) {
    const s = this;

    if (s.headlines[patternId.replace('#', 'C')]) return;

    const pattern = d3
      .select(s.defParent)
      .append('pattern')
      .attr('id', patternId.replace('#', 'C') + s.patternSuffix)
      .attr('width', 1)
      .attr('height', 10) // row height can not be used anymore
      .attr('patternUnits', 'userSpaceOnUse');

    const backgroundRect = pattern
      .append('rect')
      .attr('height', 10) // row height can not be used anymore
      .attr('width', 1)
      .attr('fill', 'white');

    // foreground
    pattern
      .append('rect')
      .attr('class', 'texturizer-rect-headline')
      .attr('height', s._calcHeadlineHeight() + s.config.getLineTop())
      .attr('width', 1)
      .attr('fill', color);

    s.headlines[patternId.replace('#', 'C')] = pattern;
    s.backgroundPatternRects.push(backgroundRect);
    s.backgroundPattern.push(pattern);
  }

  /**
   * @private
   */
  private _calcHeadlineHeight() {
    const s = this;
    const headLineheight = s.config.getTextOverlayHeight();
    return headLineheight;
  }

  /**
   * Handles building a overlay rect into shifts in canvas.
   * @param {object} canvasNode D3Selection of canvas node.
   * @param {object[]} canvasShiftData Array of shift data in canvas.
   * @param {object} lastZoomTransformation Data of the current zoom transformation.
   */
  buildCanvasOverlayRectsInsideShift(canvasNode, canvasShiftData, lastZoomTransformation) {
    const canvas = canvasNode.node().getContext('2d');
    for (const canvasShift of canvasShiftData) {
      if (canvasShift.hasOwnProperty('noRender') && canvasShift.noRender.length) continue;
      const rectBoundings = this._getBoundingsOfRectOverlayInsideShift(canvasShift, canvasNode, lastZoomTransformation);
      const roundCornerRadius = this.config.getRoundedCorners();
      this._drawCanvasOverlayRectsInsideShift(canvas, rectBoundings, '#FFFFFF', roundCornerRadius);
    }
  }

  /**
   * Draws the rect overlays into shifts.
   * @param {object} canvas Canvas in which the overlays are to be drawn.
   * @param {object} rectBoundings Object with bounding information of overlay.
   * @param {string} color Color of overlay.
   * @param {number} radius Radius of bottom corners.
   */
  private _drawCanvasOverlayRectsInsideShift(canvas, rectBoundings, color, radius) {
    const x = rectBoundings.x,
      y = rectBoundings.y,
      height = rectBoundings.height,
      width = rectBoundings.width;

    if (width > 4) {
      if (typeof radius === 'number') {
        radius = { tl: 0, tr: 0, br: radius, bl: radius };
      } else {
        const defaultRadius = { tl: 0, tr: 0, br: 0, bl: 0 };
        for (const side in defaultRadius) {
          radius[side] = radius[side] || defaultRadius[side];
        }
      }
      canvas.beginPath();
      canvas.moveTo(x + radius.tl, y);
      canvas.lineTo(x + width - radius.tr, y);
      canvas.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
      canvas.lineTo(x + width, y + height - radius.br);
      canvas.quadraticCurveTo(x + width, y + height, x + width - radius.br, y + height);
      canvas.lineTo(x + radius.bl, y + height);
      canvas.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
      canvas.lineTo(x, y + radius.tl);
      canvas.quadraticCurveTo(x, y, x + radius.tl, y);
      canvas.closePath();
    } else {
      canvas.beginPath();
      canvas.rect(x, y, width, height);
    }
    canvas.fillStyle = color;
    canvas.fill();
  }

  /**
   * Calculates the boundings of rect to be build.
   * @param {object} canvasShiftData Canvas shift data of rect to be build.
   * @param {object} canvasNode D3Selection of canvas node.
   * @param {object} lastZoomTransformation Data of the current zoom transformation.
   *
   * @returns {object} Object with bounding information.
   */
  private _getBoundingsOfRectOverlayInsideShift(canvasShiftData, canvasNode, lastZoomTransformation) {
    const headlineHeight = this._calcHeadlineHeight();
    const strokeWidth = this.config.getShiftStrokeWidth() / 1.5;
    return {
      x: canvasShiftData.x * lastZoomTransformation.k + lastZoomTransformation.x + strokeWidth,
      y: canvasShiftData.y - parseFloat(canvasNode.style('top')) + headlineHeight,
      width: canvasShiftData.width * lastZoomTransformation.k - 2 * strokeWidth,
      height: canvasShiftData.height - headlineHeight - strokeWidth,
    };
  }

  /**
   * Refreshes all headline height by calculating it from gantt row height.
   */
  updateHeadlineHeight() {
    const s = this;
    for (let i = 0; i < s.backgroundPattern.length; i++) {
      const pattern = s.backgroundPattern[i];
      pattern.select('.texturizer-rect-headline').attr('height', s._calcHeadlineHeight() + s.config.getLineTop());
    }
  }

  /**
   * Checks if headline does already exist by given headlineString.
   * @param {string} patternId PatternID of headline. Note: All "#" are internal (in this.headlines) replaced with "C".
   */
  headlineExists(patternId) {
    const s = this;
    return !!s.headlines[patternId];
  }

  /**
   * Removes all headlines and headline data.
   */
  removeAllHeadlines() {
    const s = this;
    for (const key in s.headlines) {
      s.headlines[key].remove();
    }
    s.headlines = {};
  }

  /**
   * Removes all headline data.
   */
  removeAllHeadlineData() {
    const s = this;
    s.headlines = {};
  }

  //
  //  GETTER & SETTER
  //
  /**
   * @return {Map<string, Selection>} Headline dataset which contains the patternId as key and the D3 selection of the pattern node.
   */
  getHeadlineData() {
    return this.headlines;
  }
}
