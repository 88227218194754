<mat-list style="max-height: 90vh; overflow-y: auto">
  <ng-container *ngFor="let action of menu.getContextMenuItems()">
    <ng-container *ngTemplateOutlet="tContextMenuItem; context: { $implicit: action }"></ng-container>
  </ng-container>
  <ng-container *ngIf="menu.getContextMenuGroups()?.length > 0">
    <ng-container *ngFor="let group of menu.getContextMenuGroups(); let idx = index">
      <h3 matSubheader>{{ group.getName() | translate }}</h3>
      <ng-container *ngFor="let action of group.getContextMenuItems()">
        <ng-container *ngTemplateOutlet="tContextMenuItem; context: { $implicit: action }"></ng-container>
      </ng-container>
      <mat-divider *ngIf="idx !== menu.getContextMenuGroups()?.length - 1"></mat-divider>
    </ng-container>
  </ng-container>
</mat-list>

<ng-template #tContextMenuItem let-item>
  <mat-list-item *ngIf="checkVisibility(item)" class="list-item">
    <app-button (click)="handleItemClick($event, item)" [data]="item"></app-button>
  </mat-list-item>
</ng-template>
