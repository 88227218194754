import { Injectable, NgZone } from '@angular/core';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { of, Subject } from 'rxjs';
import { takeUntil, takeWhile } from 'rxjs/operators';
import { EPredefinedAction } from '../button/action/predefined-action.enum';
import { Lightbox } from './lightbox';
import { LightboxComponent } from './lightbox.component';
import { ConfirmLightbox } from './predefined/confirm-lightbox';

@Injectable({
  providedIn: 'root',
})
export class LightboxService {
  // private stack: MatDialogRef<LightboxComponent>[];
  private stack: string[];
  private _openedLightboxes: Subject<Lightbox[]> = new Subject<Lightbox[]>();
  private _onDialogClosed: Subject<Lightbox> = new Subject<Lightbox>();
  private _onDialogOpened: Subject<Lightbox> = new Subject<Lightbox>();

  public getOpenedLightboxes(): Subject<Lightbox[]> {
    return this._openedLightboxes;
  }

  public getStack(): string[] {
    return this.stack || [];
  }

  public changeOpenedLightbox(): void {
    this._openedLightboxes.next(
      this.dialog.openDialogs.map((m: MatDialogRef<LightboxComponent>) => m.componentInstance.lightbox)
    );
  }

  public onDialogClosed(): Subject<Lightbox> {
    return this._onDialogClosed;
  }

  public onDialogOpened(): Subject<Lightbox> {
    return this._onDialogOpened;
  }

  constructor(private dialog: MatDialog, private zone: NgZone) {
    this.stack = [];
  }

  /**
   * close the most top lightbox
   * @param result result of the closing lightbox
   * @returns void
   */
  public close(result?: { type: EPredefinedAction }): void {
    if (this.stack.length <= 0) {
      return;
    }
    const dialogId: string = this.stack.pop();

    const m: MatDialogRef<LightboxComponent> = this.dialog.openDialogs.find(
      (ref: MatDialogRef<LightboxComponent>) => ref.id === dialogId
    );
    m.close(result);
  }

  /**
   * open lightbox
   * @param lightbox Lightbox
   * @returns MatDialogRef<DialogComponent>
   */
  public open(lightbox: Lightbox): MatDialogRef<LightboxComponent> {
    const config: MatDialogConfig<Lightbox> = {
      data: lightbox,
      panelClass: 'app-lightbox',
      disableClose: true,
      minWidth: '100px',
    };

    if (lightbox.isFullscreen() === true) {
      config.panelClass = 'app-lightbox-fullscreen';
      config.maxWidth = '100vw';
      config.maxHeight = '100vh';
      config.width = '100vw';
      config.height = '100vh';
    }

    let d: MatDialogRef<LightboxComponent>;
    this.zone.run((_) => {
      d = this.dialog.open(LightboxComponent, config);
    });

    lightbox.setRef(d);
    this.stack.push(d.id);
    this.changeOpenedLightbox();

    d.backdropClick()
      .pipe(takeUntil(d.componentInstance.onDestroy))
      .subscribe((m: MouseEvent) => {
        if (lightbox.isBackdropClose()) {
          const conf: ConfirmLightbox = new ConfirmLightbox('LABEL.confirm_close');
          conf.setCustomConfirmAction(() => of(d.close({ type: EPredefinedAction.CANCEL_LIGHTBOX })));
          this.open(conf);
        }
      });

    // wait for close event to remove reference from stack
    d.afterClosed()
      .pipe(takeWhile(() => d.componentInstance.alive, true))
      .subscribe(() => {
        lightbox.setRef(null);
        this.stack = this.stack.filter((ref: string) => ref !== d.id);
        this.changeOpenedLightbox();
      });

    d.beforeClosed()
      .pipe(takeUntil(d.componentInstance.onDestroy))
      .subscribe(() => {
        lightbox.setRef(null);
        this._onDialogClosed.next(lightbox);
        this.stack = this.stack.filter((ref: string) => ref !== d.id);
      });

    this._onDialogOpened.next(lightbox);
    return d;
  }
}
