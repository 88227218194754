import { EntryElement } from '@app-modeleditor/components/entry-collection/entry-element';
import { EntryElementValue } from '@app-modeleditor/components/entry-collection/entry-element-value';
import { EFieldType } from '@app-modeleditor/components/entry-collection/field-type.enum';
import { GlobalUtils } from 'frontend/src/dashboard/global-utils';
import { takeUntil } from 'rxjs/operators';
import { Gantt_General } from '../../general.gantt.component';
import { GanttEssentialPlugIns } from '../../plugin/e-gantt-essential-plugins';
import { GanttYAxisSearchPlugIn } from '../../plugin/plugin-list/y-axis-search/y-axis-search';

export class YAxisSearchHideRowsElement extends EntryElement {
  private indicatorIdentifier: string = GlobalUtils.generateUUID();

  constructor(private scope: Gantt_General) {
    super();
    this.listenToExternalChanges();
  }

  get(data: any): this {
    this.setFieldType(EFieldType.SLIDE_TOGGLE)
      .setAlwaysEnabled(true)
      .setName(this.scope.translate.instant('@show-results-only@'))
      .setId('hideRows_' + data.id)
      .setValue(new EntryElementValue().setValue(false))
      .onChanges((change) => this.handleHideRowsToggle(change.value));
    return this;
  }

  private handleHideRowsToggle(value: boolean) {
    const yAxisSearchPlugIn: GanttYAxisSearchPlugIn = this.scope.ganttPluginHandlerService.getEssentialPlugIn(
      GanttEssentialPlugIns.YAxisSearchPlugIn
    );

    this.handleMenuIndicator(value);
    yAxisSearchPlugIn.setShowOnlyResults(value);
    yAxisSearchPlugIn.searchRowByTitleHierarchical(yAxisSearchPlugIn.getCurrentSearchText());
  }

  /**
   * Handles the display of an indicator in menu tab when the filter is active.
   * @param visible Indicates whether the filter is activated
   */
  public handleMenuIndicator(visible: boolean) {
    this.scope.toolbar.updateMenuIndicator(visible, this.getId(), this.indicatorIdentifier);
  }

  /**
   * Updates the value if it gets changed from outside.
   */
  private listenToExternalChanges(): void {
    const yAxisSearchPlugIn: GanttYAxisSearchPlugIn = this.scope.ganttPluginHandlerService.getEssentialPlugIn(
      GanttEssentialPlugIns.YAxisSearchPlugIn
    );

    yAxisSearchPlugIn
      .getShowOnlyResultsChangeObservable()
      .pipe(takeUntil(this.scope.onDestroy))
      .subscribe((newValue) => {
        this.scope.ganttLibService.ngZone.run(() => {
          const currentValue: EntryElementValue = this.getValue();
          if (currentValue.getValue() !== newValue) {
            currentValue.setValue(newValue);
            this.executeChanges();
          }
        });
      });
  }
}
