import { ComponentPortal } from '@angular/cdk/portal';
import {
  AfterContentInit,
  Component,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ConfigService } from '@core/config/config.service';
import { Chart, ChartOptions, ChartType, ScaleOptions, TooltipItem } from 'chart.js';
import { ChartEvent } from 'chart.js/dist/core/core.plugins';
import annotationPlugin, { AnnotationOptions } from 'chartjs-plugin-annotation';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { GridGloablService } from 'frontend/src/dashboard/moving-grid/grid-global.service';
import { GeneralPopUpService } from 'frontend/src/dashboard/popups-services/general-popup.service';
import { BaseChartDirective } from 'ng2-charts';
import { Subject, of } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';
import {
  CHART_TOOLTIP_ITEMS,
  CHART_TOOLTIP_LABEL,
  CHART_TOOLTIP_LABELINDEX,
  TooltipPopupComponent,
} from '../tooltip-popup/tooltip-popup.component';
import AxisTickPlugin from './chart.extensions/axis-tick-plugin/axis-tick';
import { ColorScheme } from './chart.extensions/color.handler';
import { ChartData, ChartDataGroup } from './chart.mapper';
@Component({
  selector: 'generalChart',
  templateUrl: './generalChart.html',
  styleUrls: ['./generalChart.scss'],
})
export class GeneralChartComponent implements OnChanges, AfterContentInit, OnDestroy {
  @ViewChild(BaseChartDirective) chartComponent: BaseChartDirective;

  @Input() chartData: ChartData;
  @Input() sortByChartData: any[] = null;
  @Input() chartIndex = 0;
  @Input() combined = false;
  @Input() labelXAxisVisible = true;
  @Input() hiddenDatasets = new Set();
  @Input() toggleChartType = false;
  @Input() containerPaddingRight: number = null;
  @Output() changeDataOfElement: EventEmitter<any> = new EventEmitter();
  @Output() rightClick: EventEmitter<any> = new EventEmitter();
  @Output() afterInit: EventEmitter<number> = new EventEmitter();
  @Output() afterUpdate: EventEmitter<any> = new EventEmitter();

  public chartLabels: string[] = [];
  public chartType: ChartType = 'bar';

  public chartLegend = false;
  public chartDisplayOptions: ChartOptions;

  public chartDatasets: any[] = [
    {
      label: '',
      data: [],
    },
  ];
  compareChart = false;
  reloadChart = false;

  public chartColors: Array<any> = [];

  public loadingData = true;
  public updatingData = false;

  public maxValue = 0;
  public minValue = 0;
  public refreshTimeoutId: Subject<void> = new Subject<void>();

  public allPointsHidden = true;
  public colorChanged = false;
  public indeterminateAllPoints = false;
  public heightRef = 100;
  private tooltipTimeoutId: Subject<void> = new Subject<void>();

  constructor(
    public elRef: ElementRef,
    public globalGridService: GridGloablService,
    public generalPopupService: GeneralPopUpService,
    public injector: Injector,
    private configApi: ConfigService,
    private zone: NgZone
  ) {}

  ngOnDestroy(): void {
    this.refreshTimeoutId.next();
    this.refreshTimeoutId.complete();
    this.tooltipTimeoutId.next();
    this.tooltipTimeoutId.complete();
  }

  ngOnInit() {
    // Plugins:
    // - annotation: bar-chart background color
    // - dataLabels
    // - additional axis ticks: categorize bigger ticks below
    Chart.register(annotationPlugin, ChartDataLabels, new AxisTickPlugin());
  }

  ngAfterContentInit(): void {
    this.loadingData = false;
    this.afterInit.emit(this.chartIndex);
  }

  /**
   * refresh the chart in the current state
   */
  refreshView() {
    if (this.loadingData) {
      return;
    }
    this.refreshTimeoutId.next();
    of(null)
      .pipe(delay(50), takeUntil(this.refreshTimeoutId))
      .subscribe(() => {
        /** Timeout refreshes chart in next cycle after changes were made because of chartjs
       needs an own lifecycle to refresh
      */
        if (this.chartComponent) {
          this.chartComponent.update();
          this.afterUpdate.emit();
        }
      });
  }

  /**
   * update the chart data of the chart
   * @param chartData new chart data
   */
  setChartData(chartData: ChartData) {
    // setTimeout(() => {
    this.chartData = chartData;
    this.chartComponent.labels = this.chartLabels.slice();
    this.configureChartData();
    // }, 0);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.chartData) {
      // this.chartDatasets = this.chartData.chartData;
      this.getChartDataset();
      this.configureChartData();
    }

    if (changes.sortByChartData) {
      if (this.chartData) {
        if (this.chartData.chartData != this.sortByChartData && this.sortByChartData != null && this.chartIndex != 0) {
          this.chartData.chartData = this.sortData(this.chartData.chartData);
          this.refreshChartDatasets();
        }
      }
    }

    if (changes.labelXAxisVisible) {
      this.setOption();
    }
  }

  /**
   * handle the init state of the datasets of the chart data
   */
  public setupInitHiddenDataset(): void {
    if (this.chartDatasets) {
      for (const dataset of this.chartData.chartData) {
        if (dataset.stacklessVisible) {
          dataset.hidden = false;
        }

        for (const hiddenDatsset of Array.from(this.hiddenDatasets.values())) {
          if (dataset.localID === hiddenDatsset) {
            if (dataset.stacklessVisible) {
              dataset.hidden = !dataset.hidden;
            } else {
              dataset.stackedLineLegendHidden = !dataset.stackedLineLegendHidden;
            }
            if (dataset.stack && dataset.originType == 'line') {
              dataset.calc = !dataset.calc;
            }
          }
        }
      }

      this.maxValue = this.chartData.getMaxValue(this.chartDatasets);
      this.minValue = this.chartData.getMinValue(this.chartDatasets);
      this.chartData.refreshStackedLineData();

      // this.refreshChartDatasets();
      this.setOption();
    }
  }

  /**
   * trigger the change detection of the chartdata for the chart
   */
  public refreshChartDatasets(): void {
    if (this.chartData.chartType === 'pie' || this.chartData.chartType === 'doughnut') {
      this.chartDatasets = this.chartData.getPieChartDataGroups();
    }
    this.updateIntegralColors();
    this.chartDatasets = this.chartDatasets.slice();
  }

  /**
   * sorts the data groups so that the line data groups are drawn in the foreground
   */
  private getChartDataset() {
    const chartDatasets = [];
    const lineChartDatasets = [];
    const otherChartDatasets = [];
    for (let index = this.chartData.chartData.length - 1; index >= 0; index--) {
      const data = this.chartData.chartData[index];
      switch (data.originType) {
        case 'line':
          lineChartDatasets.push(this.chartData.chartData[index]);
          break;
        default:
          otherChartDatasets.push(this.chartData.chartData[index]);
          break;
      }
    }
    lineChartDatasets.forEach((data) => {
      chartDatasets.push(data);
    });
    otherChartDatasets.forEach((data) => {
      chartDatasets.push(data);
    });
    this.updateIntegralColors();
    this.chartDatasets = chartDatasets.slice();
  }

  /**
   * manages the configuration of the data
   */
  public configureChartData() {
    if (this.chartData) {
      if (!this.combined) {
        if (this.sortByChartData) {
          // this.chartData.chartData = this.sortData(this.chartData.chartData);
        } else {
          this.changeDataOfElement.emit({
            type: this.chartType,
            sortData: this.chartData.chartData.slice(),
          });
        }
      }
      this.chartColors = this.chartData.chartColor;
      this.setChartType();
      this.fillLables();
      this.setupInitHiddenDataset();

      // this.chartDatasets = this.chartData.chartData;
      this.getChartDataset();
      this.refreshChartDatasets();
      this.getMaxMinValue();
      this.setOption();

      // this.refreshView();
    }
  }

  /**
   * @returns the start of the chart axes from the chart
   */
  getStartPixelOfXAxis(): number {
    const axis = this.chartData.axes.find((axis) => axis.direction === 'horizontal');
    if (this.chartComponent.chart && axis) {
      return (this.chartComponent.chart as any).scales.x._startPixel;
    } else {
      return 0;
    }
  }

  /**
   * update the type of the chart
   */
  setChartType() {
    // set the chart type to bar for the case that only 1 datapoint are visible (example: pagesize === 1)
    if ((this.chartData.chartType == 'line' || this.chartData.mixed) && this.chartData.chartData[0].data.length == 1) {
      this.chartType = 'bar';
      for (const data of this.chartData.chartData) {
        data.type = 'bar';
      }
    } else {
      // set the chart type to bar if teh chart are mixed
      if (this.chartData.stacked || this.chartData.mixed) {
        this.chartType = 'bar';
      } else {
        this.chartType = this.chartData.chartType;
      }
      for (const data of this.chartData.chartData) {
        data.type = data.originType;
      }
    }
  }

  /**
   * update the min/max-value of the chart
   * @param minValue new min value
   * @param maxValue new max value
   */
  public setMaxMinValue(minValue, maxValue) {
    this.maxValue = maxValue;
    this.minValue = minValue;
    this.setOption();
  }

  /**
   * calculate the min/max value from the current displayed datasets
   */
  public getMaxMinValue() {
    this.maxValue = this.chartData.getMaxValue(this.chartDatasets);
    this.minValue = this.chartData.getMinValue(this.chartDatasets);
  }

  /**
   * sorts the passed chart data based on given sorting information
   * @param inputData chart data which should be used sorted
   * @returns sorted chart data
   */
  sortData(inputData) {
    if (!this.combined) {
      if (this.chartIndex == 0) {
        return;
      }

      const newElement: any[] = [];
      const sortedInput: any[] = new Array(this.sortByChartData.length);
      const result: any[] = [];

      for (const item of inputData) {
        let found = false;
        this.sortByChartData.forEach((sortItem, index) => {
          if (item.localID == sortItem.localID) {
            found = true;
            sortedInput[index] = item;
            sortedInput[index].backgroundColor = sortItem.backgroundColor;
            sortedInput[index].borderColor = sortItem.borderColor;
          }
        });
        if (!found) {
          newElement.push(item);
        }
      }
      sortedInput.forEach((item) => {
        if (item != null) {
          result.push(item);
        }
      });
      newElement.forEach((item) => {
        result.push(item);
      });

      return result;
    }
  }

  /**
   * refresh all labels of the chart
   */
  public fillLables() {
    this.chartLabels = [];
    this.chartData.chartLabels.forEach((element) => {
      this.chartLabels.push(element);
    });

    this.chartLabels = this.chartLabels.slice();
  }

  /**
   * update the option of the chart
   */
  setOption() {
    if (this.chartData.chartType === 'pie' || this.chartData.chartType === 'doughnut') {
      this.setPieOptions();
    } else {
      this.setChartOptions();
    }

    this.refreshView();
  }

  /**
   * set the option of chart in the case it is a pie chart
   */
  public setPieOptions() {
    this.chartDisplayOptions = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        title: {
          display: true,
          text: this.chartData.title,
        },
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            label: function (context: TooltipItem<any>) {
              let label = context.dataset[context.datasetIndex].label[context.dataIndex] + ' - ' + context.label || '';

              if (label) {
                label += ': ';
              }
              label += Math.round(context.dataset[context.datasetIndex].label[context.dataIndex] * 100) / 100;
              return label;
            },
          },
        },
      },
    };
  }

  /**
   * set the option of a chart
   * add special configuration of the ticks
   * add background draws
   */
  public setChartOptions() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const scales = {};
    if (this.chartData.axes.length > 1) {
      for (const axes of this.chartData.axes) {
        const axis: any = {
          display: true,
        };
        if (axes.direction === 'horizontal') {
          if (axes.valueTime) {
            axis.type = 'time';
          } else {
            axis.type = 'category';
          }
          axis.display = this.labelXAxisVisible;
          axis.title = { display: false };
          axis.grid = {
            offset: true,
          };
          axis.beginAtZero = true;
          axis.ticks = {
            maxRotation: 0,
            padding: 0,
            backdropPadding: 0,
            font: {
              size: 12,
              lineHeight: 1,
            },
            callback: (tickValue, index, ticks) => {
              const chartLabel = this.chartData.categoriesLabelsObjectInsideZoom[index];
              return chartLabel?.label || '';
            },
          };
          scales['x'] = axis;
        } else {
          if (this.configApi.access().templates?.Chart?.fixedYAxisPosition) {
            axis[0].afterFit = (scaleInstance) => {
              scaleInstance.width = 60; // sets fixed width to 60px
            };
          }

          axis.title = { display: true, text: axes.label };
          axis.type = 'linear';
          scales['y'] = axis;
          scales['y'].ticks = {
            beginAtZero: true,
            max: Math.ceil(this.maxValue * 1.1),
            min: Math.ceil(this.minValue * 1.1),
            callback: function (value, index, values) {
              let labelString = '';
              if (index === 0 && values.length > 5) {
                return labelString;
              }

              if (value > 0) {
                const diffValue = Math.round(value / 100) / 10;
                if (diffValue >= 1) {
                  labelString = diffValue + 'K';
                } else {
                  labelString = value;
                }
              } else {
                labelString = Math.round(value) + '';
              }

              return labelString;
            },
          };
        }
      }
    }

    // HIDDEN TIME AXIS
    // used to display the exact current time with annotation
    if (this.chartData.currentTime) {
      // 'this.chartData.initialScrollStart' and 'this.chartData.initialScrollEnd' represents only
      // the first server response not interactions afterwards with the time range
      const visibleTime = this.chartData.categoriesLabelsObjectInsideZoom.map((value) => value.categoryValue).sort();
      const firstTime = visibleTime[0];
      let timeSteps = visibleTime[1] - visibleTime[0];
      if (isNaN(timeSteps)) timeSteps = 86499405 * 31; // DAY * 31 - Month
      const lastTime = visibleTime[visibleTime.length - 1] + timeSteps;

      const hiddenTimeAxis: ScaleOptions = {
        display: true,
        type: 'linear',
        min: new Date(firstTime).getTime(),
        max: new Date(lastTime).getTime(),
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
        ticks: {
          display: false,
          stepSize: timeSteps / 100,
          //alt Prec.: day hour min  sec  ms
          // stepSize: 1 * 3 * 60 * 60 * 1000,
        },
      };
      scales['xTime'] = hiddenTimeAxis;
    }

    this.chartDisplayOptions = {
      responsive: true,
      maintainAspectRatio: false,
      animation: false,
      layout: {
        padding: {
          top: 0,
          left: 0,
          bottom: this.labelXAxisVisible ? 12 : 0, // additional label space
          right: 0,
        },
      },
      plugins: {
        datalabels: {
          display: false,
        },
        title: {
          display: false,
          text: this.chartData.title,
        },
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      onHover: (event: ChartEvent, activeElements: Array<any>, chart: Chart) => {
        const dataset = chart.getElementsAtEventForMode(event.native, 'dataset', { intersect: true }, false)[0];
        if (!dataset || activeElements.length <= 0) {
          this.closeTooltip();
          this.tooltipTimeoutId.next();
        } else {
          this.tooltipTimeoutId.next();
          of(null)
            .pipe(delay(400), takeUntil(this.tooltipTimeoutId))
            .subscribe(() => {
              if (!dataset) {
                return;
              }
              const labelIndex = activeElements[0].index;
              const dataMeta = chart.getDatasetMeta(dataset.datasetIndex);
              const datagroups: ChartDataGroup[] = this.chartData.chartData.filter((data) => {
                return data.stack === (dataMeta.stack as any) && !data.hidden;
              });
              this.openTooltip(
                (event.native as MouseEvent).pageX + 5,
                (event.native as MouseEvent).pageY + 5,
                datagroups,
                labelIndex
              );
            });
        }
      },
    };
    if (this.chartData.axes.length > 1) {
      this.chartDisplayOptions.scales = scales;
    } else {
      if (this.chartData.stacked) {
        this.chartDisplayOptions.scales = {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
          },
        };
      }
    }

    if (this.chartData.categoriesLabelsObjectInsideZoom.filter((label) => label.additionalName).length > 0) {
      const lastDayOfMonth = this.chartData.categoriesLabelsObjectInsideZoom.filter((chartLabel, index) => {
        return (
          index !== this.chartData.categoriesLabelsObjectInsideZoom.length - 1 &&
          chartLabel.additionalName !== this.chartData.categoriesLabelsObjectInsideZoom[index + 1].additionalName
        );
      });

      const additionalNamesLayer = this.chartData.categoriesLabelsObjectInsideZoom.filter((chartLabel, index) => {
        return (
          index === this.chartData.categoriesLabelsObjectInsideZoom.length - 1 ||
          chartLabel.additionalName !== this.chartData.categoriesLabelsObjectInsideZoom[index + 1]?.additionalName
        );
      });

      // ADDITIONAL NAMES AT X-AXIS
      // custom plugin: "chartAdditionalAxisTicks"
      (this.chartDisplayOptions as any).axisTick = {
        ticks: lastDayOfMonth.map((label) => this.chartData.categoriesLabelsObjectInsideZoom.indexOf(label)),
        additionalLabelTicks: additionalNamesLayer.map((label) => {
          return {
            index: this.chartData.categoriesLabelsObjectInsideZoom.indexOf(label),
            label: label.additionalName,
          };
        }),
        tickLength: 25,
        tickColor: '#acacac',
        scaleID: 'x',
      };
    }

    // ANNOTATION
    // colored background
    this.chartDisplayOptions.plugins.annotation = {
      annotations: {},
    };

    if (this.chartData.backgroundDraws.length > 0 && this.chartData.categoriesLabelsObjectInsideZoom.length > 0) {
      this.chartData.backgroundDraws.forEach((backgroundDraw, index) => {
        const c = backgroundDraw.backgroundColor
          ? new ColorScheme().hexToRgbA(backgroundDraw.backgroundColor, 0.1)
          : 'rgba(0, 0, 0, 0.1)';
        const annotation: AnnotationOptions = {
          type: 'box',
          drawTime: 'beforeDatasetsDraw',
          backgroundColor: backgroundDraw.backgroundColor,
          borderWidth: 0.2,
        };

        if (
          this.chartData.axes.find((axis) => axis.id === backgroundDraw.axisRef && axis.direction === 'horizontal') &&
          (this.chartData.categoriesLabelsObjectInsideZoom.find(
            (label) => label.idx === backgroundDraw.startCoordinate.idx
          ) ||
            this.chartData.categoriesLabelsObjectInsideZoom.find(
              (label) => label.idx === backgroundDraw.endCoordinate.idx
            ))
        ) {
          annotation['xScaleID'] = 'x'; // TODO backgroundDraw.axisRef;
          const minCategory = this.chartData.categoriesLabelsObjectInsideZoom.find(
            (label) => label.idx === backgroundDraw.startCoordinate.idx
          );
          const minNextCategory = this.chartData.categoriesLabelsObjectInsideZoom.find(
            (label) => label.idx === backgroundDraw.startCoordinate.idx + 1
          );
          const maxCategory = this.chartData.categoriesLabelsObjectInsideZoom.find(
            (label) => label.idx === backgroundDraw.endCoordinate.idx
          );

          const minCategoryIndex = this.chartData.categoriesLabelsObjectInsideZoom.indexOf(minCategory);
          const minNextCategoryIndex = this.chartData.categoriesLabelsObjectInsideZoom.indexOf(minNextCategory);
          const maxCategoryIndex = this.chartData.categoriesLabelsObjectInsideZoom.indexOf(maxCategory);

          annotation['xMin'] = minCategoryIndex - 0.5;
          if (minNextCategory) {
            annotation['xMax'] =
              (maxCategoryIndex === minCategoryIndex ? minNextCategoryIndex : maxCategoryIndex) - 0.5;
          }
          this.chartDisplayOptions.plugins.annotation.annotations[`backgroundBox${index}`] = annotation;
        }
      });
    }

    // CURRENT TIME LINE
    if (this.chartData.currentTime) {
      this.chartDisplayOptions.plugins.annotation.annotations['currentTimeLine'] = {
        type: 'line',
        mode: 'vertical',
        scaleID: 'xTime',
        value: this.chartData.currentTime || 0,
        borderColor: 'rgba(171, 0, 14,0.8)',
        borderWidth: 1,
      };
    }
  }

  // EVENT HANDLING AREA
  /**
   * toogle the current chart type
   */
  public toggleType() {
    this.chartData.toogleChartType(this.chartData.chartData[0].data.length);
    this.chartType = this.chartData.chartType;
    this.refreshView();
  }

  private getUseData() {
    let useData = [];
    if (this.chartData.chartType === 'pie' || this.chartData.chartType === 'doughnut') {
      useData = this.chartData.chartData;
    } else {
      useData = this.chartDatasets;
    }
    return useData;
  }

  /**
   * update the visibilty of the passed chart data groups
   * @param chartData chartDataGroups to change the visibilty
   */
  public handleHiddenDataSet(chartData: ChartDataGroup[]): void {
    const useData = this.getUseData();
    for (const dataset of chartData) {
      const data = useData.find((data) => data.localID === dataset.localID);

      if (data) {
        data.hidden = this.hiddenDatasets.has(data.localID);
        data.stackedLineLegendHidden = dataset.stackedLineLegendHidden;
        data.calc = dataset.calc;
      }
    }
    this.refreshChartwithNewMaxMin();
  }

  /**
   * refresh the mix/max-value, the stackedline and update teh chart
   * @param skipUpdate flag to skip the update of the chart
   */
  public refreshChartwithNewMaxMin(skipUpdate?: boolean): void {
    this.chartData.refreshStackedLineData();
    this.maxValue = this.chartData.getMaxValue(this.chartDatasets);
    this.minValue = this.chartData.getMinValue(this.chartDatasets);
    this.refreshChartDatasets();
    if (!skipUpdate) {
      this.setOption();
    }
  }

  /**
   * handle the right click of the chart
   * @param event right click mmouse event
   */
  public handleRightClick(event): void {
    event.preventDefault();
    this.rightClick.emit(event);
  }

  /**
   * open a tooltip as a overwie for the passed chart-data-groups
   * @param positionX x position of the tooltip
   * @param positionY y position of the tooltip
   * @param chartDataGroups chart-data-groups to display insid ethe tooltip
   * @param labelIndex index of the label
   */
  openTooltip(positionX: number, positionY: number, chartDataGroups: ChartDataGroup[], labelIndex: number): void {
    this.zone.run(() => {
      const portalComponent = this.buildPortalComponent(chartDataGroups, labelIndex);
      this.generalPopupService.createPopupOnMouse(positionX, positionY, portalComponent);
    });
  }

  /**
   * close the tooltip of the chart
   */
  closeTooltip() {
    this.generalPopupService.close();
  }

  /**
   * cretae the tooltip component of the chart
   * @param chartDataGroups chart-data-groups for the tooltip
   * @param labelIndex index of the label
   * @returns ComponentPortal of the TooltipPopupComponent
   */
  private buildPortalComponent(
    chartDataGroups: ChartDataGroup[],
    labelIndex: number
  ): ComponentPortal<TooltipPopupComponent> {
    const injector = Injector.create({
      providers: [
        { provide: CHART_TOOLTIP_ITEMS, useValue: chartDataGroups },
        {
          provide: CHART_TOOLTIP_LABEL,
          useValue: this.chartData.categoriesLabelsObjectInsideZoom[labelIndex],
        },
        { provide: CHART_TOOLTIP_LABELINDEX, useValue: labelIndex },
      ],
      parent: this.injector,
    });

    const componentPortal = new ComponentPortal(TooltipPopupComponent, null, injector);
    return componentPortal;
  }

  /**
   * Updates the colors of the integrals, because the order of the datasets might have changed
   */
  private updateIntegralColors() {
    this.chartDatasets.forEach((data: ChartDataGroup) => {
      if (data.integralData && data.integralData.destinationId) {
        const destination =
          data.integralData.destinationId && !data.integralData.connectToOrigin
            ? this.chartDatasets.findIndex((elem) => elem.id === data.integralData.destinationId)
            : 'origin';
        data.fill = destination;
      }
    });
  }
}
