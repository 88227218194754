import { GanttCallBackStackExecuter } from '../../../callback-tools/callback-stack-executer';
import { ComponentEvent } from '../../../history/component-event';

/**
 * @constructor
 * @class
 * @extends ComponentEvent
 * @requires ComponentEvent
 * @plugin timeperiod-marker
 * */
export class GanttTimePeriodEvent extends ComponentEvent {
  constructor() {
    super();
    /**
     * @type {GanttTimePeriodExecuter}
     */
    this.ref = null;
  }

  /**
   * @override
   * @returns {boolean} true if undo was succesfull, else false
   */
  undo() {
    const s = this;

    function handle(type) {
      const types = {
        addTimePeriodToRow: function () {
          return s._undoTPMarker();
        },
        removeSelectedTPs: function () {
          return s._undoRemoveTP();
        },
        changeTPColor: function () {
          return s._undoSetColor();
        },
        updateTimePeriod: function () {
          return s._undoUpdateTimePeriod();
        },
        default: function () {
          console.warn('can not undo: ' + type + '. May not be implemented yet.');
          return false;
        },
      };
      return (types[type] || types['default'])();
    }
    return handle(s.eventName);
  }

  /**
   * @override
   * @returns {boolean} true if redo was succesfull, else false
   */
  redo() {
    const s = this;
    function handle(type) {
      const types = {
        addTimePeriodToRow: function () {
          return s._redoTPMarker();
        },
        removeSelectedTPs: function () {
          return s._redoRemoveTP();
        },
        changeTPColor: function () {
          return s._redoSetColor();
        },
        updateTimePeriod: function () {
          return s._redoUpdateTimePeriod();
        },
        default: function () {
          console.warn('can not redo: ' + type + '. May not be implemented yet.');
          return false;
        },
      };
      return (types[type] || types['default'])();
    }
    return handle(s.eventName);
  }

  /**
   * @overrides
   * @return {String}
   */
  getEventDescription() {
    const s = this;
    function handle(type) {
      const types = {
        /* 'addTimePeriodToRow': function () { return GanttTimePeriodEventDescription.getAddTimePeriodToRowDescription(s.ref.ganttDiagram.getConfig().getLanguage(), s.arguments); },
        'removeSelectedTPs': function () { return GanttTimePeriodEventDescription.getRemoveSelectedTPsDescription(s.ref.ganttDiagram.getConfig().getLanguage(), s.arguments); },
        'changeTPColor': function () { return GanttTimePeriodEventDescription.getChangeTPColorDescription(s.ref.ganttDiagram.getConfig().getLanguage(), s.arguments); }, */
        default: function () {
          console.warn('no description for: ' + s.eventName + ' set. May not be implemented yet.');
          return '';
        },
      };
      return (types[type] || types['default'])();
    }
    return handle(s.eventName);
  }

  private _undoTPMarker() {
    const s = this;
    s.ref.activateEditMode();
    s.ref._removeTimePeriod(s.arguments[0].id);
    s.ref.deactivateEditMode();
    GanttCallBackStackExecuter.execute(s.ref.callBack.afterRemove);
    return true;
  }

  private _redoTPMarker() {
    const s = this;
    s.ref.disableLogging();
    s.ref.addTimePeriodToRow(
      s.arguments[0].yId,
      s.arguments[0].dateStart,
      s.arguments[0].dateEnd,
      s.arguments[0].id,
      s.arguments[3],
      s.arguments[2]
    );
    s.ref.enableLogging();
    return true;
  }

  private _undoRemoveTP() {
    const s = this;

    s.ref.disableLogging();
    s.ref.activateEditMode();
    for (let i = 0; i < s.arguments[0].length; i++)
      s.ref.addTimePeriodToRow(
        s.arguments[0][i].yId,
        new Date(s.arguments[0][i].dateStart),
        new Date(s.arguments[0][i].dateEnd),
        s.arguments[0][i].id
      ); // stroke

    s.ref.deactivateEditMode(); //  needed so that readded TPs are selected for edit mode again.
    s.ref.activateEditMode();

    s.ref.enableLogging();
    return true;
  }
  private _redoRemoveTP() {
    const s = this;
    s.ref.activateEditMode();
    for (let i = 0; i < s.arguments[0].length; i++) s.ref._removeTimePeriod(s.arguments[0][i].id);

    GanttCallBackStackExecuter.execute(s.ref.callBack.afterRemove);
    s.ref.deactivateEditMode();
    return true;
  }

  private _undoSetColor() {
    const s = this;
    s.ref.disableLogging();
    s.ref.setColor(s.arguments[0]);
    s.ref.enableLogging();
    return true;
  }
  private _redoSetColor() {
    const s = this;
    s.ref.disableLogging();
    s.ref.setColor(s.arguments[1]);
    s.ref.enableLogging();
    return true;
  }

  private _undoUpdateTimePeriod() {
    const s = this;

    s.ref.activate();
    s.ref.disableLogging();
    s.ref.activateEditMode();

    const log = s.arguments[0];
    s.ref.updateTimePeriodPosById(log.id, log.oldX, log.oldX + log.oldWidth, log.oldY, log.oldRowId);

    s.ref.enableLogging();
    s.ref.deactivateEditMode();
    s.ref.updateAllTimePeriodyPos();
    s.ref.deactivate();

    return true;
  }
  private _redoUpdateTimePeriod() {
    const s = this;

    s.ref.activate();
    s.ref.disableLogging();
    s.ref.activateEditMode();

    const log = s.arguments[0];
    s.ref.updateTimePeriodPosById(log.id, log.newXStart, log.newXEnd, log.newY, log.newRowId);

    s.ref.enableLogging();
    s.ref.deactivateEditMode();
    s.ref.updateAllTimePeriodyPos();
    s.ref.deactivate();

    return true;
  }
}

// GETTER and SETTER inherited from Main Class

export class GanttTimePeriodEventDescription {
  // TODO: Event explanations.
  private constructor() {}

  static getAddTimePeriodToRowDescription(language, args) {
    return '';
  }
  static getRemoveSelectedTPsDescription(language, args) {
    return '';
  }
  static getChangeTPColorDescription(language, args) {
    return '';
  }
}
