<div
  [class]="
    slideOut ? 'node-network-general-content-wrapper slide-out' : 'node-network-general-content-wrapper slide-in'
  "
>
  <!-- <generell-content [data]="data" [renderDataImmediatly]="true"></generell-content> -->
  <app-template-ui [model]="data"></app-template-ui>
  <button mat-icon-button class="node-network-general-content-back-button" (click)="closeWrapper($event)">
    <mat-icon>close</mat-icon>
  </button>
</div>
