import { EntryElement } from '@app-modeleditor/components/entry-collection/entry-element';
import { EntryElementValue } from '@app-modeleditor/components/entry-collection/entry-element-value';
import { EFieldType } from '@app-modeleditor/components/entry-collection/field-type.enum';
import { Gantt_General } from '../../general.gantt.component';
import { GanttEssentialPlugIns } from '../../plugin/e-gantt-essential-plugins';
import { GanttIndexCardsPlugIn } from '../../plugin/plugin-list/index-cards/index-cards.plugin';

export class LabelModelSelector extends EntryElement {
  constructor(private scope: Gantt_General, private entryElement: EntryElement) {
    super();
  }

  get(): this {
    return this.setFieldType(EFieldType.COMBO_BOX)
      .setOptionsFavorite(false)
      .setValue(new EntryElementValue().setAvailableValues(this.getAvailableValues()))
      .setAlwaysEnabled(true)
      .setDisabled(false)
      .setName(this.entryElement.getName() || '@change-block-strategy@')
      .setId('GanttChangeBlockTextStrategySubmenuElementID')
      .onChanges((val: EntryElementValue) => this.selectTextStrategy(val.getValue()))
      .setValue(new EntryElementValue().setValue(false));
  }

  private getAvailableValues() {
    const availableValues: EntryElementValue[] = [];
    availableValues.push(new EntryElementValue().setValue(-1).setName('@use-settings@').setId('-1'));
    availableValues.push(new EntryElementValue().setValue(0).setName('@hide-overlapping-labels@').setId('0'));
    availableValues.push(new EntryElementValue().setValue(1).setName('@use-settings@').setId('1'));
    availableValues.push(new EntryElementValue().setValue(2).setName('@show-always-labels@').setId('2'));
    availableValues.push(new EntryElementValue().setValue(3).setName('@show-never-labels@').setId('3'));
    return availableValues;
  }

  private selectTextStrategy(val: EntryElementValue) {
    // if (data.event.value == null) return;
    // const textStrategy: 0 | 1 | 2 | 3 | -1 = data.event.value.value;
    // const gantt = this.scope.ganttLibService.bestGantt;
    // this.integrateTextStrategyByNumber(textStrategy);
    // gantt.rerenderShiftsVertical();
  }
}
