import { EntryElement } from '../entry-collection/entry-element';
import { EntryElementValue } from '../entry-collection/entry-element-value';
import { EFieldType } from '../entry-collection/field-type.enum';

export class MultiObjectSelector extends EntryElement {
  private availableValuesFieldIdentifier: string;
  private colorFieldIdentifier: string;
  private dataSourceRestriction: string;
  private internUploadButtonAvailible: boolean;
  private loadAllOptionsObjectSelector: boolean;
  private objectFieldIdentifier: string;
  private showChipsBelow: boolean;
  private availableOptions: EntryElementValue[];
  private initialSearchValue: EntryElementValue | string;

  public getInitialSearchValue<T extends EntryElementValue | string>(): T {
    return this.initialSearchValue as T;
  }

  public setInitialSearchValue(initialValue: EntryElementValue | string): this {
    this.initialSearchValue = initialValue;
    return this;
  }

  public getAvailableOptions(): EntryElementValue[] {
    return this.availableOptions;
  }

  public setAvailableOptions(availableOptions: EntryElementValue[]): MultiObjectSelector {
    this.availableOptions = availableOptions;
    return this;
  }

  /**
   * whether to show selected chips below the picker or not
   * @returns boolean
   */
  public isShowChipsBelow(): boolean {
    return typeof this.showChipsBelow === 'boolean' ? this.showChipsBelow : true;
  }

  public setShowChipsBelow(showChipsBelow: boolean): this {
    this.showChipsBelow = showChipsBelow;
    return this;
  }

  constructor() {
    super();
    this.setFieldType(EFieldType.MULTI_OBJECT_SELECTOR);
  }

  public getAvailableValuesFieldIdentifier(): string {
    return this.availableValuesFieldIdentifier;
  }

  public setAvailableValuesFieldIdentifier(availableValuesFieldIdentifier: string): this {
    this.availableValuesFieldIdentifier = availableValuesFieldIdentifier;
    return this;
  }

  public getColorFieldIdentifier(): string {
    return this.colorFieldIdentifier;
  }

  public setColorFieldIdentifier(colorFieldIdentifier: string): this {
    this.colorFieldIdentifier = colorFieldIdentifier;
    return this;
  }

  public getDataSourceRestriction(): string {
    return this.dataSourceRestriction;
  }

  public setDataSourceRestriction(dataSourceRestriction: string): this {
    this.dataSourceRestriction = dataSourceRestriction;
    return this;
  }

  public isInternUploadButtonAvailible(): boolean {
    return this.internUploadButtonAvailible;
  }

  public setInternUploadButtonAvailible(internUploadButtonAvailible: boolean): this {
    this.internUploadButtonAvailible = internUploadButtonAvailible;
    return this;
  }

  public isLoadAllOptionsObjectSelector(): boolean {
    return this.loadAllOptionsObjectSelector;
  }

  public setLoadAllOptionsObjectSelector(loadAllOptionsObjectSelector: boolean): this {
    this.loadAllOptionsObjectSelector = loadAllOptionsObjectSelector;
    return this;
  }

  public getObjectFieldIdentifier(): string {
    return this.objectFieldIdentifier;
  }

  public setObjectFieldIdentifier(objectFieldIdentifier: string): this {
    this.objectFieldIdentifier = objectFieldIdentifier;
    return this;
  }
}
