import { Resource } from 'frontend/src/dashboard/model/resource/resource';

export class TemplateValue extends Resource {
  private value: any | boolean | string | Date | number | TemplateValue | TemplateValue[] = null;
  private required: boolean;
  private index: number;
  private checked: boolean;
  private icon: string;
  private show: boolean;

  public isShow(): boolean {
    return this.show;
  }

  public setShow(show: boolean): this {
    this.show = show;
    return this;
  }

  public getIcon(): string {
    return this.icon;
  }

  public setIcon(icon: string): this {
    this.icon = icon;
    return this;
  }

  public isChecked(): boolean {
    return typeof this.checked === 'boolean' ? this.checked : false;
  }

  public setChecked(checked: boolean): this {
    this.checked = checked;
    return this;
  }

  public getIndex(): number {
    return this.index;
  }

  public setIndex(index: number): this {
    this.index = index;
    return this;
  }

  public isRequired(): boolean {
    return this.required;
  }

  public setRequired(required: boolean): this {
    this.required = required;
    return this;
  }

  public getValue<T extends any | boolean | Date | string | number | TemplateValue | TemplateValue[]>(): T {
    return this.value as T;
  }

  public setValue(value: any | Date | boolean | string | number | TemplateValue | TemplateValue[]): this {
    this.value = value;
    return this;
  }
}
