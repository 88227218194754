import { YAxisDataFinder } from '../data-handler/data-finder/yaxis-data-finder';
import { GanttShiftDataMapper } from '../data-handler/data-mapper/shift-data-mapper';
import { YAxisDataMapper } from '../data-handler/data-mapper/yaxis-data-mapper';
import { GanttDataShift } from '../data-handler/data-structure/data-structure';

/**
 * Class to add gantt shifts.
 * @class
 * @constructor
 * @static
 */
export class GanttShiftAdding {
  private constructor() {}

  static addShiftByYPosition = function (timeStart, timeEnd, y, shiftData, ganttDiagram, parentRow) {
    const newShift: GanttDataShift = {
      id: null,
      name: null,
      originName: '',
      timePointStart: null,
      timePointEnd: null,
      tooltip: null,
      color: null,
      firstColor: null,
      secondColor: null,
      noRender: null,
      highlighted: null,
      editable: null,
      additionalData: null,
      modificationRestriction: null,
      opacity: null,
      weaken: null,
      symbols: null,
    };
    const timeStamp = new Date();
    const config = ganttDiagram.getConfig();
    const rowHeight = ganttDiagram.getRowHeightStorage().getRowHeightById(parentRow.id);

    const ganttXAxisHandler = ganttDiagram.getXAxisBuilder(),
      ganttDataHandler = ganttDiagram.getDataHandler(),
      ganttShiftBuilder = ganttDiagram.getShiftFacade().getShiftBuilder(),
      scale = ganttXAxisHandler.getGlobalScale(),
      zoom = ganttXAxisHandler.getLastZoomEvent();

    // add all data to new shift
    for (const key in shiftData) {
      newShift[key] = shiftData[key];
    }

    newShift.timePointStart = new Date(timeStart);
    newShift.timePointEnd = new Date(timeEnd);

    if (!newShift.id) newShift.id = 'new_' + timeStamp.getTime();
    if (!newShift.name) newShift.name = 'New shift';

    const roundedY = parseInt(y);

    const rowCanvasData = YAxisDataFinder.getRowByYPosition(ganttDataHandler.getYAxisDataset(), roundedY),
      rowId = rowCanvasData.id;

    // 1. add shift to origin dataset
    const originData = ganttDataHandler.getOriginDataset(),
      foundRow = YAxisDataFinder.getRowById(originData.ganttEntries, rowId).data;

    foundRow.shifts.push(newShift);

    // 2. add shift to canvas render data
    ganttDataHandler.initCanvasShiftData(originData.ganttEntries);

    // 3. add shift to current render
    // get shift in canvas building format
    const canvasShift = GanttShiftDataMapper.mapOriginToCanvas(
      newShift,
      rowHeight,
      scale,
      rowCanvasData.y,
      rowCanvasData.layer
    );
    ganttShiftBuilder.addShift(canvasShift);
  };

  static addShiftByRowID = function (timeStart, timeEnd, rowId, shiftData, ganttDiagram) {
    const newShift: GanttDataShift = {
      id: null,
      originName: '',
      name: null,
      timePointStart: null,
      timePointEnd: null,
      tooltip: null,
      color: null,
      firstColor: null,
      secondColor: null,
      noRender: null,
      highlighted: null,
      editable: null,
      additionalData: null,
      modificationRestriction: null,
      opacity: null,
      weaken: null,
      symbols: null,
    };
    const timeStamp = new Date();
    const config = ganttDiagram.getConfig();

    const ganttXAxisHandler = ganttDiagram.getXAxisBuilder(),
      ganttDataHandler = ganttDiagram.getDataHandler(),
      ganttShiftBuilder = ganttDiagram.getShiftFacade().getShiftBuilder(),
      scale = ganttXAxisHandler.getGlobalScale(),
      zoom = ganttXAxisHandler.getLastZoomEvent();

    // add all data to new shift
    for (const key in shiftData) {
      newShift[key] = shiftData[key];
    }

    newShift.timePointStart = new Date(timeStart);
    newShift.timePointEnd = new Date(timeEnd);

    if (!newShift.id) newShift.id = 'new_' + timeStamp.getTime();
    if (!newShift.name) newShift.name = 'New shift';

    // 1. add shift to origin dataset
    const originData = ganttDataHandler.getOriginDataset(),
      foundRow = YAxisDataFinder.getRowById(originData.ganttEntries, rowId).data;

    foundRow.shifts.push(newShift);

    // 2. add shift to canvas render data
    ganttDataHandler.initCanvasShiftData(originData.ganttEntries);

    // 3. add shift to current render
    // get shift in canvas building format

    const rowCanvasDataSet = YAxisDataMapper.getCanvasYAxisData(
      ganttDiagram.getDataHandler().getOriginDataset().ganttEntries,
      ganttDiagram.getDataHandler()
    );
    const rowCanvasData = this.getYPositionOfRowID(rowCanvasDataSet, rowId);
    if (rowCanvasData == null) return;

    const canvasShift = GanttShiftDataMapper.mapOriginToCanvas(
      newShift,
      rowCanvasData.height,
      scale,
      rowCanvasData.y,
      rowCanvasData.layer
    );
    ganttShiftBuilder.addShift(canvasShift);
  };

  static getYPositionOfRowID = function (rowCanvasDataSet, rowId) {
    for (let i = 0; i < rowCanvasDataSet.length; i++) {
      if (rowCanvasDataSet[i].id == rowId) {
        return rowCanvasDataSet[i];
      }
    }
  };
}
