import { ZoomTransform } from 'd3';
import { Subscription } from 'rxjs';
import { GanttCanvasShift } from '../../data-handler/data-structure/data-structure';
import { GanttUtilities } from '../../gantt-utilities/gantt-utilities';
import { TextOverlay } from '../text-overlay';
import { IGanttShiftTextOverlayBuilding } from './text-overlay-interface';

/**
 * Strategy which keeps the text inside the shift and shortens it but distributes the text on multiple lines if possible.
 */
export class TextOverlayInsideMultipleLines implements IGanttShiftTextOverlayBuilding {
  public readonly UUID = GanttUtilities.generateUniqueID();
  private readonly _widthCache = new Map<string, number>();

  private _onFilterRenderDataSetShiftsSubscription: Subscription = undefined;

  public init(executer: TextOverlay): void {
    // register callbacks
    this._registerCallbacks(executer);
  }

  public render(shiftDataset: GanttCanvasShift[], zoomTransformation: ZoomTransform, executer: TextOverlay): void {
    const s = executer;

    if (!shiftDataset) return;

    shiftDataset = shiftDataset.filter((d) => {
      return 10 < zoomTransformation.k * d.width;
    });

    s.canvas
      .selectAll('dummy')
      .data(shiftDataset)
      .enter()
      .append('div')
      .style('top', (d) => {
        const y = s.ganttDiagram.getRenderDataHandler().getStateStorage().getYPositionShift(d.id);
        return y + 'px';
      })
      .style('left', (d) => {
        let x = d.x * zoomTransformation.k + zoomTransformation.x + s.config.textOverflowLeft();
        if (x < 0) {
          x = s.config.textOverflowLeft();
        }
        return x + 'px';
      })
      .style('width', (d, i) => {
        this._widthCache.set(d.id, this._getTextContainerWidth(shiftDataset, d, i, zoomTransformation, s));
        return this._widthCache.get(d.id) + 'px';
      })
      .style('height', (d) => {
        const lineHeight = this._getTextContainerLineHeight(executer);
        const labelHeight = d.label?.labelHeight
          ? s.ganttDiagram.getShiftFacade().getShiftBuilder().getShiftCalculationStrategy().getShiftLabelHeight(d)
          : this._getTextContainerHeight(d, this._widthCache.get(d.id), executer);
        // tolerate up to 0.1px overflow because of rounding errors
        const labelHeightClip = lineHeight - (labelHeight % lineHeight) <= 0.1 ? 0 : labelHeight % lineHeight;
        return `${labelHeight - labelHeightClip}px`;
      })
      .style('-webkit-line-clamp', (d) => {
        const lineHeight = this._getTextContainerLineHeight(executer);
        const labelHeight = d.label?.labelHeight
          ? s.ganttDiagram.getShiftFacade().getShiftBuilder().getShiftCalculationStrategy().getShiftLabelHeight(d)
          : this._getTextContainerHeight(d, this._widthCache.get(d.id), executer);
        return Math.floor(labelHeight / lineHeight);
      })
      .attr('class', `${executer.config.css.shifts.text_overlay_item} gantt-text-overlay-item-multiple-lines`)
      .text((d) => d.name)
      .style('font-size', () => s.config.getShiftFontSize() + 'px')
      .style('color', s.config.getShiftTextColor())
      .style('text-shadow', (d) => s.getTextOverlayShadow(d));

    this._widthCache.clear();
  }

  public cleanUp(): void {
    // unregister callbacks
    this._unregisterCallbacks();
  }

  public buildSmallerText(
    shiftDataSet: GanttCanvasShift[],
    shiftData: GanttCanvasShift,
    textElement: d3.Selection<HTMLDivElement, GanttCanvasShift, d3.BaseType, undefined>,
    zoomTransformation: d3.ZoomTransform,
    executer: TextOverlay
  ): void {
    const s = executer;
    if (!shiftData.name) return;
    textElement ? textElement.remove() : null;
    s.render([shiftData], zoomTransformation);
  }

  /**
   * Registers all callbacks which are necessary for this text overlay strategy.
   * @param executer Executer that handles text overlays.
   */
  private _registerCallbacks(executer: TextOverlay): void {
    // hook into render data generation to calculate shift label heights
    executer.ganttDiagram
      .getRenderDataHandler()
      .onFilterRenderDataSetShifts.subscribe(() => this._calculateShiftLabelHeights(executer));
  }

  /**
   * Unregisters all registered callbacks.
   */
  private _unregisterCallbacks(): void {
    this._onFilterRenderDataSetShiftsSubscription?.unsubscribe();
    this._onFilterRenderDataSetShiftsSubscription = undefined;
  }

  /**
   * Callback method to manipulate shift heights during the generation of the render dataset.
   * @param executer Text overlay executer.
   */
  private _calculateShiftLabelHeights(executer: TextOverlay): void {
    const renderDataSet = executer.ganttDiagram.getRenderDataHandler().getRenderDataShifts(executer.scrollContainerId);
    const zoomTransform = executer.ganttDiagram.getXAxisBuilder().getLastZoomEvent();

    for (let i = 0; i < renderDataSet.length; i++) {
      const shiftData = renderDataSet[i];
      const labelWidth = this._getTextContainerWidth(renderDataSet, shiftData, i, zoomTransform, executer);
      if (!shiftData.label) shiftData.label = {};
      shiftData.label.labelHeight = this._getTextContainerHeight(shiftData, labelWidth, executer);
      shiftData.label.lineHeight = this._getTextContainerLineHeight(executer);
    }
  }

  /**
   * Calculates the width of the text container for the specified shift.
   * @param shiftDataset Reference to the canvas shift dataset the shift is part of.
   * @param shiftData Canvas shift data of the shift to calculate the text container width for.
   * @param index Index of the specified shift inside the specifued cavas shift dataset.
   * @param zoomTransformation Current d3 zoom transform of the x axis.
   * @param executer Executer that handles text overlays.
   * @returns Width of the text container for the specified shift.
   */
  private _getTextContainerWidth(
    shiftDataset: GanttCanvasShift[],
    shiftData: GanttCanvasShift,
    index: number,
    zoomTransformation: d3.ZoomTransform,
    executer: TextOverlay
  ): number {
    const s = executer;
    const textContainerWidth = executer.nodeProportionState.getShiftViewPortProportions().width;

    let width = shiftData.width * zoomTransformation.k - s.config.textOverflowLeft();

    const neighbor = s.findNeighbor(shiftDataset, index, shiftData, s.config);
    const next = neighbor.next;
    let nextWidth;
    if (next) {
      if (s.isElemOverlapping(shiftDataset, shiftData, s.config, 'next')) {
        nextWidth = 0;
      } else {
        nextWidth = next.x * zoomTransformation.k - shiftData.x * zoomTransformation.k;
      }
    }
    let preWidth;
    const prev = neighbor.prev;
    if (prev) {
      if (s.isElemOverlapping(shiftDataset, shiftData, s.config, 'prev')) {
        preWidth = 0; // if prev with higher priority is overlapping, we don't show the text
      } else {
        preWidth = shiftData.x * zoomTransformation.k - prev.x * zoomTransformation.k;
      }
    }

    if (!isNaN(nextWidth)) {
      if (nextWidth < width && (nextWidth !== 0 || shiftData.renderPriority < next.renderPriority)) {
        width = nextWidth;
      } else if (
        neighbor.nextNotSameStart &&
        neighbor.nextNotSameStart.id !== next.id &&
        s.isOverlapping(shiftData, neighbor.nextNotSameStart)
      ) {
        width = neighbor.nextNotSameStart.x * zoomTransformation.k - shiftData.x * zoomTransformation.k;
      }
    }
    const x = shiftData.x * zoomTransformation.k + zoomTransformation.x + s.config.textOverflowLeft();
    if (x < 0) {
      width = width - -1 * x; // compensate shift partly behind y Axis
    }
    if (width < 10 || (preWidth === 0 && shiftData.renderPriority < prev.renderPriority)) {
      width = 0; // on very small widths the text-overflow is glitching, so we set a minimum to fix this
    }
    if (width > textContainerWidth) {
      // on very big widths the div disappers, so we set max width to the text container width
      width = textContainerWidth;
    }
    return width;
  }

  /**
   * Calculates the height of the text container for the specified shift.
   * @param shiftData Canvas shift data of the shift to calculate the text container height for.
   * @param width If specified, this value will be used as text container width.
   * @param executer Executer that handles text overlays.
   * @returns Height of the text container for the specified shift.
   */
  private _getTextContainerHeight(
    shiftData: GanttCanvasShift,
    width: number = undefined,
    executer: TextOverlay
  ): number {
    const fontSizeCalculator = executer.getFontSizeCalculator();

    // determine required lines to fully display the shift label
    const charWidths: number[] = [];
    for (let i = 0; i < shiftData.name.length; i++) {
      charWidths[i] = fontSizeCalculator.getTextWidth(shiftData.name[i]);
    }

    let linesRequired = 1;
    let lineOverflow = 0;
    for (const charWidth of charWidths) {
      if (lineOverflow + charWidth > (isNaN(width) ? shiftData.width : width)) {
        lineOverflow = 0;
        linesRequired++;
      }
      lineOverflow += charWidth;
    }

    // determine the height of the text container
    const lineHeight = this._getTextContainerLineHeight(executer);
    const linesPossible = Math.floor((shiftData.height || executer.config.shiftHeight()) / lineHeight);

    const height = linesRequired > linesPossible ? linesPossible * lineHeight : linesRequired * lineHeight;
    return height;
  }

  /**
   * Calculates the height of one line in px.
   * @param executer Executer that handles text overlays.
   * @returns Height of one line in px.
   */
  private _getTextContainerLineHeight(executer: TextOverlay): number {
    const lineHeight = executer.getFontSizeCalculator().getFontSize() * 1.15;
    return lineHeight;
  }
}
