import { ZoomTransform } from 'd3';
import { GanttCanvasShift } from '../../data-handler/data-structure/data-structure';
import { TextOverlay } from '../text-overlay';

/**
 * Strategie keeps the text inside the shift and shortens it.
 * Use this strategie for a better look.
 * @implements {IGanttShiftTextOverlayBuilding}
 * @class
 */
export class TextOverlayInside {
  constructor() {}

  /**
   * @override
   */
  init(reference) {}

  /**
   * @override
   */
  render(shiftDataset: GanttCanvasShift[], zoomTransformation: ZoomTransform, reference: TextOverlay) {
    const s = reference;

    const self = this;
    if (!shiftDataset) return;

    shiftDataset = shiftDataset.filter(function (d) {
      return 10 < zoomTransformation.k * d.width;
    });

    // let textContainerWidth = s.canvas.node().parentNode.getBoundingClientRect().width;
    const textContainerWidth = reference.nodeProportionState.getShiftViewPortProportions().width;
    const textOverlay = s.canvas
      .selectAll('dummy')
      .data(shiftDataset)
      .enter()
      .append('div')
      .style('top', (d) => {
        const y = s.ganttDiagram.getRenderDataHandler().getStateStorage().getYPositionShift(d.id);
        return y + 'px';
      })
      .style('left', (d) => {
        let x = d.x * zoomTransformation.k + zoomTransformation.x + s.config.textOverflowLeft();
        if (x < 0) {
          x = s.config.textOverflowLeft();
        }
        return x + 'px';
      })
      .style('height', (d) => s.config.getTextOverlayHeight() + 'px')
      .style('width', (d, i) => {
        let width = d.width * zoomTransformation.k - s.config.textOverflowLeft();

        const neighbor = s.findNeighbor(shiftDataset, i, d, s.config);
        const next = neighbor.next;
        let nextWidth;
        if (next) {
          if (s.isElemOverlapping(shiftDataset, d, s.config, 'next')) {
            nextWidth = 0;
          } else {
            nextWidth = next.x * zoomTransformation.k - d.x * zoomTransformation.k;
          }
        }

        let preWidth;
        const prev = neighbor.prev;
        if (prev) {
          if (s.isElemOverlapping(shiftDataset, d, s.config, 'prev')) {
            preWidth = 0; // if prev with higher priority is overlapping, we don't show the text
          } else {
            preWidth = d.x * zoomTransformation.k - prev.x * zoomTransformation.k;
          }
        }

        if (!isNaN(nextWidth)) {
          if (nextWidth < width && (nextWidth !== 0 || d.renderPriority < next.renderPriority)) {
            width = nextWidth;
          } else if (
            neighbor.nextNotSameStart &&
            neighbor.nextNotSameStart.id !== next.id &&
            s.isOverlapping(d, neighbor.nextNotSameStart)
          ) {
            width = neighbor.nextNotSameStart.x * zoomTransformation.k - d.x * zoomTransformation.k;
          }
        }
        const x = d.x * zoomTransformation.k + zoomTransformation.x + s.config.textOverflowLeft();
        if (x < 0) {
          width = width - -1 * x; // compensate shift partly behind y Axis
        }
        if (width < 10 || (preWidth === 0 && d.renderPriority < prev.renderPriority)) {
          width = 0; // on very small widths the text-overflow is glitching, so we set a minimum to fix this
        }
        if (width > textContainerWidth) {
          // on very big widths the div disappers, so we set max width to the text container width
          width = textContainerWidth;
        }
        return width + 'px';
      })
      .attr('class', () => {
        return reference.config.css.shifts.text_overlay_item;
      })
      .text((d) => d.name)
      .style('font-size', function () {
        return s.config.getShiftFontSize() + 'px';
      })
      .style('color', s.config.getShiftTextColor())
      .style('text-shadow', (d) => s.getTextOverlayShadow(d));
  }

  /**
   * @override
   */
  cleanUp(reference) {}

  public buildSmallerText(
    shiftDataSet: GanttCanvasShift[],
    shiftData: GanttCanvasShift,
    textElement: d3.Selection<HTMLDivElement, GanttCanvasShift, d3.BaseType, undefined>,
    zoomTransformation: d3.ZoomTransform,
    executer: TextOverlay
  ): void {
    const s = executer;
    if (!shiftData.name) return;
    textElement ? textElement.remove() : null;
    s.render([shiftData], zoomTransformation);
  }
}
