import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { SaxmsTooltipModule } from '@app-modeleditor/components/tooltip/tooltip.module';
import { TranslateModule } from '@ngx-translate/core';
import { SortMenuComponent } from './sort-menu.component';

@NgModule({
  declarations: [SortMenuComponent],
  exports: [SortMenuComponent],
  imports: [CommonModule, MatButtonModule, MatIconModule, SaxmsTooltipModule, TranslateModule],
})
export class SortMenuModule {}
