import { NgModule } from '@angular/core';
import { GanttBrokenConstraintsNavigatorOverlayModule } from './best_gantt/script/plug-ins/broken-constraints-navigator/navigator-overlay/navigator-overlay.module';
import { GanttComponent } from './gantt.component';

@NgModule({
  declarations: [GanttComponent],
  imports: [GanttBrokenConstraintsNavigatorOverlayModule],
  exports: [GanttComponent],
})
export class GanttModule {}
