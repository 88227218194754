import { GanttScrollContainerEvent } from '../../html-structure/scroll-container-event';
import { BestGantt } from '../../main';
import { ElementSelectorProportions, SelectionBoxDragEvent } from '../../selector/element-selector';
import { GanttSelectShiftCalculator } from '../../selector/select-shifts-calculator';
import { ETimeMarkerAnchor } from '../../x-axis/x-axis';

/**
 * Handler methods for selection box events.
 */
export class GanttSelectionBoxHandler {
  private ignoreSelectionIds: string[] = [];

  constructor(private _ganttDiagram: BestGantt) {}

  /**
   * Callback to set/mark selected shifts after using selection box.
   * @keywords callback, shift, selection, mark, box, select, rect
   * @param event
   */
  public selectShiftsBySelectionBox(event: GanttScrollContainerEvent<SelectionBoxDragEvent>): void {
    const s = this._ganttDiagram;

    const selectionBoxProportions = event.event.selectionBoxProportions;
    if (
      selectionBoxProportions &&
      selectionBoxProportions.topLeft[0] == selectionBoxProportions.bottomRight[0] &&
      selectionBoxProportions.topLeft[1] == selectionBoxProportions.bottomRight[1] &&
      s.getSelectionBoxFacade().getSelectedShifts().length == 0
    ) {
      s.getXAxisBuilder().removeAllDateMarkers();
      return;
    }

    const canvasShiftDataset = s.getDataHandler().getCanvasShiftDataset();
    const originShiftDataset = s.getDataHandler().getOriginDataset().ganttEntries;

    const originEvent = event.event.event;
    if (!originEvent.sourceEvent.shiftKey && !originEvent.sourceEvent.ctrlKey) {
      s.getSelectionBoxFacade().deselectAllShifts(
        canvasShiftDataset,
        s.getDataHandler().getOriginDataset().ganttEntries
      );
    }

    const selectedShifts = GanttSelectShiftCalculator.selectShiftsByCoordinates(
      selectionBoxProportions.topLeft,
      selectionBoxProportions.bottomRight,
      s.getXAxisBuilder().getLastZoomEvent(),
      s.getRenderDataHandler().getShiftDataFinder().getShiftsByScrollContainer(event.source),
      originShiftDataset,
      s.config.colorSelect(),
      s.getDataHandler(),
      s.getRenderDataHandler(),
      this.ignoreSelectionIds
    );

    s.getSelectionBoxFacade().getSelectionBoxBuilder(event.source).addSelectedShifts(selectedShifts);
    s.getXAxisBuilder().removeAllDateMarkers();
    s.rerenderShiftsVertical();
  }

  /**
   * Callback during dragging of the selection box
   * @keywords callback, shift, selection, box, select, rect
   * @param event
   */
  public draggingSelectionBox(event: GanttScrollContainerEvent<ElementSelectorProportions>): void {
    const s = this._ganttDiagram;
    s.getXAxisBuilder().removeAllDateMarkers();
    s.getShiftResizer().removeAllResizeHandles();

    const selectionBoxWidth = event.event.bottomRight[0] - event.event.topLeft[0];
    let startPoint = event.event.topLeft[0];
    if (selectionBoxWidth >= 0) {
      if (event.event.timeSpan) {
        s.xAxisBuilder.addMarkerByDate(event.event.timeSpan[0], ETimeMarkerAnchor.END);
        s.xAxisBuilder.addMarkerByDate(event.event.timeSpan[1], ETimeMarkerAnchor.START);
      } else {
        if (event.event.timeSpan) {
          s.xAxisBuilder.addMarkerByDate(event.event.timeSpan[1], ETimeMarkerAnchor.END);
          s.xAxisBuilder.addMarkerByDate(event.event.timeSpan[0], ETimeMarkerAnchor.START);
        } else {
          s.markShiftXAxisByPosition(startPoint, selectionBoxWidth);
        }
      }
    } else {
      startPoint = event.event.topLeft[0] + selectionBoxWidth;
      s.markShiftXAxisByPosition(startPoint, -selectionBoxWidth);
    }
  }

  public deSelectAllShifts(): void {
    const s = this._ganttDiagram;
    s.getSelectionBoxFacade().deselectAllShifts(
      s.getDataHandler().getCanvasShiftDataset(),
      s.getDataHandler().getOriginDataset().ganttEntries
    );
    s.rerenderShiftsVertical();
  }

  /**
   * Sets ids of shifts which should be ignored during selection.
   */
  public setIgnoreSelectionIds(ids: string[]): void {
    this.ignoreSelectionIds = ids;
  }
}
