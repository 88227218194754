import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { SaxMsSubmenuService } from '@app-modules/saxms-submenu-elements/saxms-submenu.service';
import { BestGantt, GanttComponent, GanttDataContainer, GanttEditPermissions } from '@gantt/public-api';
import { ExperimentService2 } from 'frontend/src/dashboard/data/experiment/experiment.service';
import { HorizonService } from 'frontend/src/dashboard/horizonselecter/horizon.service';
import { ModelService } from 'frontend/src/dashboard/model/model.service';
import { GridGloablService } from 'frontend/src/dashboard/moving-grid/grid-global.service';
import { Subscription } from 'rxjs';
import { GanttEditService } from '../general/gantt-edit/gantt-edit.service';
import { GanttSettingsService } from '../general/gantt-settings/service/gantt-settings.service';
import { DockWindowService } from './dock/dock-window/dock-window.service';
import { DockComponent } from './dock/dock.component';
import { EDockViewMode } from './dock/dock.enum';
import { GanttDockService } from './dock/gantt-dock.service';
import { LegendCommunicationService } from './dock/views/legend/legend-communication.service';
import { GanttDataStorageService } from './gantt-data/data-storage.service';
import { GanttLibService } from './gantt-lib.service';
import { SaxMsBestGanttCustomPlugIn } from './plugin/saxms-best-gantt.plugin';
import { GanttProgressInfo } from './progress/progress';
import { SaveButtonRestriction } from './save-button-restriction';
import { SaxMsBestGanttToolbarHandler } from './saxms-best-gantt-submenu-handler';
import { SaxMsBestGanttSettings } from './saxms-best-gantt.settings';
import { GanttComparator } from './saxms-gantt-comparator';

/**
 * Class to define submenu action.
 */
export class GenericGanttAction {
  private label: string;
  private id: string;
  private action: () => void;
  private icon: string;

  constructor() {}

  getId(): string {
    return this.id;
  }
  setId(id: string): GenericGanttAction {
    this.id = id;
    return this;
  }
  getLabel(): string {
    return this.label;
  }
  setLabel(label: string): GenericGanttAction {
    this.label = label;
    return this;
  }

  getIcon(): string {
    return this.icon;
  }
  setIcon(icon: string): GenericGanttAction {
    this.icon = icon;
    return this;
  }

  executeAction(): () => void {
    return this.action;
  }
  setAction(action: () => void): GenericGanttAction {
    this.action = action;
    return this;
  }
}

@Component({
  selector: 'saxms-best-gantt',
  templateUrl: './saxms-best-gantt.handler.html',
  styleUrls: ['./saxms-best-gantt.scss'],
  providers: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

/**
 * Main component for angular interface to JavaScript BestGantt.
 */
export class SaxMsBestGanttComponent implements OnDestroy, OnInit {
  public toolbarHandler: SaxMsBestGanttToolbarHandler;
  public bestGantt: BestGantt;

  public settingsData: SaxMsBestGanttSettings;
  public dockViewModes = EDockViewMode;
  public showSettingsButton = true;
  public showRefreshButton = false;
  public showDockRight = true;
  public showDockBottom = true;
  public splitDirection: ESplitDirection = ESplitDirection.VERTICAL;

  public customPlugins: SaxMsBestGanttCustomPlugIn[];

  public ganttSplitHeight = 90;
  public prevSplitHeight = 0;

  public isFavoriteSelected = false;
  public currentSubmenu;
  public toolbarMode;

  private _onDockViewChangeSubscription: Subscription = null;
  private editModeSubscription: Subscription = null;
  private _ganttOnZoomEndSubscription: Subscription = null;

  constructor(
    public ganttDataStorageService: GanttDataStorageService,
    public ganttDockService: GanttDockService,
    public ganttSettingsService: GanttSettingsService,
    private experimentService: ExperimentService2,
    private modelService: ModelService,
    private horizonService: HorizonService,
    private globalGridService: GridGloablService,
    private submenuService: SaxMsSubmenuService,
    private ganttLibService: GanttLibService,
    private _legendCommunicationService: LegendCommunicationService,
    private _cd: ChangeDetectorRef,
    private _ganttDockWindowService: DockWindowService,
    private ganttEditService: GanttEditService,
    private injector: Injector
  ) {}

  @Input() template;
  @Input() disableCurrentZoomSpanSaveCallback = false;
  @ViewChild('splitter') splitter: any;
  @ViewChild('ganttLib') ganttLib: GanttComponent;
  @ViewChild('dockBottom') dockBottom: DockComponent;
  @ViewChild('dockRight') dockRight: DockComponent;
  @Input() genericActions: GenericGanttAction[];

  // gantt data
  @Input() public set ganttData(ganttData: GanttDataInput) {
    const data = this.ganttDataStorageService.storeGanttData(ganttData);
  }

  @Input() disableToolbar = false;

  @Input() widgetData: any;
  @Input('settingsData') set setSettingsData(settingsData: SaxMsBestGanttSettings) {
    this.settingsData = settingsData;
    this.ganttLibService.updateSettings(this.settingsData);
  }
  @Input('showSettingsButton') set setShowSettingsButton(showSettingsButton: boolean) {
    this.showSettingsButton = showSettingsButton;
  }

  @Input() loadingSpinner = false;

  @Input() saveButtonRestriction: SaveButtonRestriction = new SaveButtonRestriction();

  @Input() printView = false;

  @Output() ganttBuilder: EventEmitter<BestGantt> = new EventEmitter();
  // handler for data which will be saved by using save button
  @Output() save: EventEmitter<{ ganttData: any; plugInChanges: any; saveAs: boolean }> = new EventEmitter();
  // handler for data which will be saved without using save button
  @Output() ganttMenu: EventEmitter<SaxMsBestGanttToolbarHandler> = new EventEmitter();
  @Output() progressInformation: EventEmitter<GanttProgressInfo> = new EventEmitter();
  @Output() refreshGantt: EventEmitter<boolean> = new EventEmitter();
  @Output() afterChangeToolbarVisibile: EventEmitter<boolean> = new EventEmitter();

  ngOnInit() {
    this._listenOnDockViewChange();
    this.initGantt();
  }

  ngOnDestroy(): void {
    this.bestGantt?.getShiftFacade().removeAfterGanttVerticalResizeCallBack('dashboard_changeGanttHeight');
    this._onDockViewChangeSubscription.unsubscribe();
    this.editModeSubscription.unsubscribe();
    this._ganttOnZoomEndSubscription?.unsubscribe();
  }

  /**
   * Start point to create the gantt.
   * Will be executed by html template if gantt data has been given and parent node of gantt has been rendered.
   * @keywords gantt, ganttdiagram, init, create, build, begin, start
   * @param ganttNode Parent div for BestGantt.
   */
  public initGantt(): void {
    if (!this.ganttDataStorageService.mappedGanttData) {
      return;
    }
    this.ganttLibService.init(this.ganttLib.instance);
    this.bestGantt = this.ganttLibService.bestGantt;

    this.ganttLibService.build(
      this.ganttDataStorageService.mappedGanttData,
      this.ganttDataStorageService.editPermissions,
      this.settingsData,
      new SaxMsBestGanttOutputHandler(this.ganttBuilder)
    );

    const compareService = new GanttComparator(
      this.experimentService,
      this.modelService,
      this.horizonService,
      this.globalGridService.getGridService(),
      this.widgetData
    );
    this.toolbarHandler = new SaxMsBestGanttToolbarHandler(this.ganttLibService, compareService, this.submenuService);
    this.ganttBuilder.emit(this.bestGantt);
    this.ganttMenu.emit(this.toolbarHandler);

    if (!this.disableCurrentZoomSpanSaveCallback) {
      this.registerCurrentZoomSpanSaveCallback();
    }
    this.editModeSubscription = this.listenToEditModeChange();
  }

  private _listenOnDockViewChange() {
    this._onDockViewChangeSubscription = this.ganttDockService.listenForDockViewChange().subscribe(() => {
      this._checkForDockWindow();
      this._cd.detectChanges();
    });
  }

  private listenToEditModeChange(): Subscription {
    return this.ganttEditService.onEditModeChange().subscribe((isEditable: boolean) => {
      const permissions = isEditable ? this.ganttDataStorageService.editPermissions : new GanttEditPermissions();
      this.ganttLibService.applyGanttRestrictions(permissions);
    });
  }

  private _checkForDockWindow(): void {
    if (this.ganttDockService.isDockFree() && !this._ganttDockWindowService.isWindowActive()) {
      this._ganttDockWindowService.generateDockWindow(this.injector);
    } else if (!this.ganttDockService.isDockFree() && this._ganttDockWindowService.isWindowActive()) {
      this._ganttDockWindowService.closeWindow();
    }
  }

  /**
   * Is called after submenu is collapsed or expanded.
   * @param visible Menu visible state.
   */
  public handleMenumode(visible: boolean) {
    this.afterChangeToolbarVisibile.emit(visible);
  }

  /**
   * Registers a callback in which the changed time period of gantt settings is saved and sent to the backend.
   */
  private registerCurrentZoomSpanSaveCallback() {
    this._ganttOnZoomEndSubscription = this.bestGantt.getXAxisBuilder().onZoomEnd.subscribe(() => {
      this.addCurrentZoomSpanToSettingsAndSave();
    });
  }

  /**
   * Gets the current zoom span values ​​from the Gantt and adds them to the settings.
   * After adding to the settings, a post to the backend is triggered.
   */
  private addCurrentZoomSpanToSettingsAndSave(): void {
    const currentZoom = this.bestGantt.getCurrentZoomedTimeSpan();
    const newZoomStart = currentZoom.from.getTime();
    const newZoomEnd = currentZoom.to.getTime();
    if (newZoomEnd <= newZoomStart) {
      return; // return if date is invalid
    }
    if (
      newZoomStart === newZoomEnd ||
      this.settingsData.scrollStart !== newZoomStart ||
      this.settingsData.scrollEnd !== newZoomEnd
    ) {
      this.ganttSettingsService.changeSettings({
        scrollStart: currentZoom.from.getTime(),
        scrollEnd: currentZoom.to.getTime(),
      });
      this.ganttSettingsService.saveSettings().subscribe();
    }
  }

  /**
   * Notifies outer component to refresh gantt.
   */
  public refreshGanttEvent(): void {
    this.refreshGantt.emit(true);
  }

  /**
   * Checks if there exists legend data.
   */
  public hasLegend(): boolean {
    return !!this._legendCommunicationService.getLegendData().legendEntries;
  }

  /**
   * Calculates height between resizeable legend and gantt containers.
   * Does not set new size if it doesnt has changed.
   */
  public getGanttSplitAreaSize(): number {
    return 10;
    const newSplitHeight = !this.hasLegend() ? 222 : this.ganttSplitHeight + 1;
    if (this.prevSplitHeight != newSplitHeight) this.prevSplitHeight = newSplitHeight;
    return this.prevSplitHeight;
  }

  /**
   * Called if dock container will be resized.
   */
  public resizeDockStart(): void {
    if (!this.bestGantt) return;
    this.ganttDockService.onResizeDockStart();
  }

  /**
   * Called if dock container will be resized.
   */
  public resizeDock(): void {
    if (!this.bestGantt) return;
    this.ganttDockService.onResizeDock();
  }

  /**
   * Called if dock container will be resized.
   */
  public resizeDockEnd(event: any, viewMode: EDockViewMode): void {
    switch (viewMode) {
      case EDockViewMode.RIGHT:
        this.ganttSettingsService.changeSettings({ dockRightSize: event.sizes[1] });
        this.ganttSettingsService.saveSettings().subscribe();
        break;
      case EDockViewMode.BOTTOM:
        this.ganttSettingsService.changeSettings({ dockBelowSize: event.sizes[1] });
        this.ganttSettingsService.saveSettings().subscribe();
        break;
    }

    if (!this.bestGantt) return;
    this.ganttDockService.onResizeDockEnd();
  }
}

/**
 * Conatainer which bundles different component outputs.
 */
export class SaxMsBestGanttOutputHandler {
  public saveSettings: EventEmitter<any>;
  public ganttBuilder: EventEmitter<BestGantt>;

  constructor(ganttBuilder: EventEmitter<BestGantt>) {
    this.ganttBuilder = ganttBuilder;
  }
}

export enum ESplitDirection {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
}

/**
 * External data input to render gantt.
 */
export interface GanttDataInput {
  editAllowSettings: GanttEditPermissions;
  ganttOrigindata: GanttDataContainer;
}
