import { HttpErrorResponse } from '@angular/common/http';
// errors-handler.ts
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { ERequestMethod, RequestOptions, RequestService } from '@app-modeleditor/request.service';
import { EMessageType, Message } from '@core/message/message';
import { MessageService } from '@core/message/message.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ErrorStack } from '../shared/error-handler/error-stack';
import { ErrorType } from '../shared/error-handler/error-types.enum';
import { ErrorStack as Stack } from '../shared/error-handler/stack';

@Injectable({
  providedIn: 'root',
})
export class AppErrorsHandler implements ErrorHandler {
  private errorStack: ErrorStack; // ErrorStack
  private stack: Stack;
  private debug: any;
  private error: Subject<Error | HttpErrorResponse> = new Subject<Error | HttpErrorResponse>();

  // handle notification events
  private _errorSubject: Subject<any>;
  private _errorObservable: Observable<any>;

  constructor(private requestApi: RequestService, private injector: Injector, private translate: TranslateService) {
    this.errorStack = ErrorStack.getInstance();

    this._errorSubject = new Subject<any>();
    this._errorObservable = this._errorSubject.asObservable();

    this.subscribe((err: { error: Error; type: ErrorType }) => {
      this.errorStack.add(err.error, null, err.type);
    });
  }

  public onError(): Observable<Error | HttpErrorResponse> {
    return this.error.asObservable();
  }

  private sendReport(error: Error) {
    if (!this.debug || this.debug.enabled !== true) {
      return of(null);
    }

    return this.requestApi
      .call(
        ERequestMethod.POST,
        this.debug.debugUrl,
        new RequestOptions().setHttpOptions({
          body: {
            error: JSON.stringify(error, Object.getOwnPropertyNames(error)),
            stack: JSON.stringify(this.stack, Object.getOwnPropertyNames(this.stack || new Stack())),
          },
        })
      )
      .pipe(
        tap((response) => {
          console.info('[sendReport]', 'success', response);
        })
      );
  }

  public getErrorStack(): ErrorStack {
    return this.errorStack;
  }

  /**
   * handles all uncatched errors
   * @param error Error | HttpErrorRespons
   * @returns void
   */
  public handleError(error: Error | HttpErrorResponse): void {
    this.errorStack.add(error, null, ErrorType.DASHBOARD);
    const notificationApi: MessageService = this.injector.get(MessageService);
    this.error.next(error);
    // HTTP errors
    if (error instanceof HttpErrorResponse) {
      // Server or connection error happened
      if (!navigator.onLine) {
        // Handle offline error
        notificationApi.show(
          new Message().setType(EMessageType.ERROR).setText('SYSTEM.MESSAGE.CONNECTION.offline').setDuration(5000)
        );
      } else {
        // if ((error.statusText === 'Unknown Error' || error?.error?.exceptionType === 'UnknownException') && error.status === 0) {
        //   notificationApi.show(new Message().setType(EMessageType.ERROR).setTitle('ERROR.unknown_title').setText('ERROR.unknown_text').setDuration(5000));
        // } else {
        const noErrorAvailable = this.translate.instant('LABEL.no_error_available');
        // Handle Http Error (error.status === 403, 404...);
        const _text: string = error.error
          ? error.error.localizedMessage
            ? error.error.localizedMessage
            : error.error.exceptionType
            ? error.error.exceptionType
            : error.error.message
            ? error.error.message
            : noErrorAvailable
          : error.message;
        notificationApi.show(new Message().setType(EMessageType.ERROR).setText(_text).setDuration(5000));
        // }
      }
      // client errors
    } else {
      if (this.debug && this.debug.enabled && this.debug.local.enabled) {
        notificationApi.show(new Message().setType(EMessageType.ERROR).setText(`${error.message}`).setDuration(5000));
      }
    }
    this.sendReport(error).subscribe();

    // Log the error anyway
    console.error(error);
  }

  public notifyServerError(error: Error) {
    this._errorSubject.next({ error: error, type: ErrorType.SERVER });
  }

  public notifyDashboardError(error: Error) {
    this._errorSubject.next({ error: error, type: ErrorType.DASHBOARD });
  }

  public notifyUndefinedError(error: Error) {
    this._errorSubject.next({ error: error, type: ErrorType.UNDEFINED });
  }

  public subscribe(func: any) {
    this._errorObservable.subscribe(func);
  }

  public unsubscribe(subject: Subject<any>) {
    subject.unsubscribe();
  }
}
