import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { ImageModule } from '@app-modeleditor/components/image/image.module';
import { SaxmsSidebarComponent } from './saxms-sidebar.component';

@NgModule({
  imports: [CommonModule, MatButtonModule, MatIconModule, TranslateModule, MatTooltipModule, ImageModule],
  exports: [SaxmsSidebarComponent],
  declarations: [SaxmsSidebarComponent],
})
export class SaxmsSidebarModule {}
