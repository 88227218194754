<div class="statebar-wrapper">
  <div class="statebar-headline">{{ template.getName() }}</div>
  <template-statebar-icon
    class="item"
    *ngFor="let icon of template.getValues()"
    [element]="icon"
    [id]="template.getId()"
  >
  </template-statebar-icon>
</div>
