import { OverlayRef } from '@angular/cdk/overlay';
import { ElementRef, Injectable, NgZone } from '@angular/core';
import { OverlayService } from '@app-modeleditor/components/lightbox/overlay/overlay.service';
import { Subject, of } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';
import { IOverlayData } from '../lightbox/overlay/overlay-data.interface';
import { IOverlayPosition } from '../lightbox/overlay/overlay-position.interface';
import { TooltipComponent } from './tooltip.component';

@Injectable({
  providedIn: 'root',
})
export class TooltipService {
  private refs: OverlayRef[] = [];
  private mouse: MouseEvent;
  private createSub: Subject<void> = new Subject<void>();

  public constructor(private zone: NgZone, private overlayApi: OverlayService) {
    this.zone.runOutsideAngular(() => {
      document.addEventListener('mousemove', this.mousemove.bind(this));
    });
  }

  private mousemove(event: MouseEvent): void {
    if (!event) {
      return;
    }
    this.mouse = event;
  }

  public getMouse(): MouseEvent {
    return this.mouse;
  }

  public createTooltip(
    el: ElementRef | HTMLElement | IOverlayPosition,
    showDelay: number,
    options: { text: string; clazz?: string }
  ): void {
    this.zone.run(() => {
      if (!el || !options?.text) {
        return undefined;
      }
      this.closeAll();
      options = Object.assign(options, { parent: el });
      this.createSub.next();
      of(null)
        .pipe(delay(showDelay), takeUntil(this.createSub))
        .subscribe(() => {
          const data: IOverlayData = this.overlayApi.create(TooltipComponent, el, options, {
            backdrop: false,
          });

          this.refs.push(data.overlayRef);
        });
    });
  }

  private closeAll(force = false): void {
    // if (!force) {
    this.createSub.next();
    // }
    this.zone.run(() => {
      while (this.refs.length > 0) {
        const ref: OverlayRef = this.refs.shift();
        ref.dispose();
        ref.detach();
      }
    });
  }

  public closeTooltip(force = false): void {
    this.closeAll(force);
  }
}
