import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { of, Subject } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';
import { EntryElementValue } from '../entry-collection/entry-element-value';
import { FilterNodeToViewerConverter } from './filter-node-to-viewer-converter';
import { FilterNodeViewer } from './filter-node-viewer';
import { IFilterNodeViewerInputData } from './filter-node-viewer-input-data.interface';
import { IFilterNodeViewerData } from './filter-node-viewer.interface';
/**
 * A viewer to visualize a filter node condition.
 */
@Component({
  selector: 'app-filter-node-viewer',
  templateUrl: './filter-node-viewer.component.html',
  styleUrls: ['./filter-node-viewer.component.scss'],
})
export class FilterNodeViewerComponent implements OnInit, OnDestroy {
  @Input() viewer: FilterNodeViewer;
  @Output() change: EventEmitter<null> = new EventEmitter();
  @ViewChild('nodeContainer') set setNodeContainer(item: ElementRef) {
    this.nodeContainer = item;
    this.checkScrollActions();
  }
  public filterNodeViewerData: IFilterNodeViewerData[];
  public viewerClassname = '';
  private converter: FilterNodeToViewerConverter = new FilterNodeToViewerConverter();
  private _ngOverride: Subject<void> = new Subject<void>();
  private nodeContainer: ElementRef;
  public canScrollLeft: boolean;
  public canScrollRight: boolean;

  get data() {
    if (!this.viewer || !this.viewer.getValue<EntryElementValue>()) {
      return;
    }
    const inputData: IFilterNodeViewerInputData = this.viewer.getValue<EntryElementValue>().getValue();

    this.filterNodeViewerData = this.converter
      .convertNodeToViewerFormat(inputData.node, inputData.attributeData)
      .map((value) => {
        value.colorValue = this.getColorByLevel(value.level);
        return value;
      });

    return this.filterNodeViewerData || [];
  }

  constructor() {}

  ngOnInit() {
    this.viewerClassname = this.getViewerSizeClass();
  }

  ngOnDestroy(): void {
    this._ngOverride.next();
    this._ngOverride.complete();
  }

  /**
   * Returns a gray gradated color by item level.
   */
  public getColorByLevel(itemLevel: number) {
    const colorValue = itemLevel == 1 ? 0 : (itemLevel * 40) % 255;
    return `rgb(${colorValue},${colorValue},${colorValue})`;
  }

  public onClick() {
    if (this.viewer.getViewerData().length) {
      this.change.emit();
    }
  }

  public getViewerSizeClass() {
    return `${this.viewer.getDisplaySize()}-viewer`;
  }

  public scrollLeft(event: MouseEvent): void {
    this.nodeContainer.nativeElement.scrollLeft -= 200;
    this.checkScrollActions();
  }
  public scrollRight(event: MouseEvent): void {
    this.nodeContainer.nativeElement.scrollLeft += 200;
    this.checkScrollActions();
  }

  checkScrollActions(): void {
    this._ngOverride.next();
    of(null)
      .pipe(delay(750), takeUntil(this._ngOverride))
      .subscribe(() => {
        this.canScrollRight =
          !this.nodeContainer ||
          this.nodeContainer.nativeElement.scrollLeft + this.nodeContainer.nativeElement.clientWidth >=
            this.nodeContainer.nativeElement.scrollWidth
            ? false
            : true;

        this.canScrollLeft = !this.nodeContainer || this.nodeContainer.nativeElement.scrollLeft === 0 ? false : true;
      });
  }
}
