export const countDecimals = (value: number) => {
  const stepStr = `${value}`;
  const pointIndex = stepStr.indexOf('.');
  if (pointIndex < 0) return 0;

  return stepStr.length - pointIndex - 1;
};

// avoiding float rounding errors

export const saveMultiply = (a: number, b: number) =>
  areFinit(a, b) ? precisionRound(a * b, getPrecisionsSum(a, b)) : a * b;

export const saveDivide = (a: number, b: number) =>
  areFinit(a, b) ? precisionRound(a / b, getPrecisionsSum(a, b) + Math.ceil(Math.log10(b))) : a / b;

export const saveAdd = (a: number, b: number) =>
  areFinit(a, b) ? precisionRound(a + b, getPrecisionsMax(a, b)) : a + b;

export const saveSubtract = (a: number, b: number) =>
  areFinit(a, b) ? precisionRound(a - b, getPrecisionsMax(a, b)) : a - b;

/**
 * Corrects value to be in raster defined by step
 * @returns rounded value being in raster
 */
export const correctStepRaster = (value: number, step: number) => {
  if (!areFinit(value, step)) return undefined;
  return saveMultiply(Math.round(value / step), step);
};

/**
 * Checks if value is out of raster defined by step
 * @returns true if out of raster, false if in raster
 */
export const isIncorrectStepRaster = (value: number, step: number) => {
  const correctedValue = correctStepRaster(value, step);
  if (correctedValue === undefined) {
    return false;
  }
  return correctedValue !== value;
};

const precisionRound = (number: number, precision: number) => {
  const factor = Math.pow(10, precision);
  return Math.round(number * factor) / factor;
};

const getPrecisionsSum = (a: number, b: number) =>
  [a, b].map((val) => countDecimals(val)).reduce((pre, count) => pre + count);

const getPrecisionsMax = (a: number, b: number) => Math.max(...[a, b].map((val) => countDecimals(val)));

const areFinit = (a: number, b: number) => [a, b].every((val) => isFinite(val));
