import { NodeNetworkColorAttribute } from '@app-nodenetwork/color/color-attribute.data';

declare let MapperUniversal: any;

/**
 * Mapper for node network nodes and the different type descriptions.
 */
export class NodeNetworkMapper {
  // Get node-network fassade-class (from assets/external_JS/node-diagram).
  mapperUniversal = new MapperUniversal();

  /**
   * Mapper-function to map raw data from backend to data for front-end.
   * @param rawData Raw data of the node-network (from backend).
   * @param scaleX Horizontal scale of diagram.
   * @param scaleY Vertical scale of diagram.
   * @param parentID Element id for highest layer.
   */
  public mapNodeNetworkData(
    rawData: any,
    scaleX: number,
    scaleY: number,
    parentID?: string,
    colorAttributeData?: NodeNetworkColorAttribute
  ): any {
    if (!parentID) parentID = 'home';
    const mappedData = this.mapperUniversal.mapData(rawData, scaleX, scaleY, parentID);

    if (colorAttributeData) {
      return this.mapColor(mappedData, colorAttributeData);
    } else return mappedData;
  }

  public mapColor(mappedData: any, colorAttributeData: NodeNetworkColorAttribute) {
    return this.mapperUniversal.mapColor(mappedData, colorAttributeData);
  }

  /**
   * Translates backend node type to node network short type.
   * @param type
   */
  private getNodeType(type: string): string {
    let nodeType = '';

    switch (type) {
      case 'ActionNode':
        nodeType = 'W';
        break;

      case 'InitialNode':
        nodeType = 'I';
        break;

      case 'ForkJoin':
        nodeType = 'F';
        break;

      case 'DecisionMerge':
        nodeType = 'D';
        break;

      case 'ActivityFinal':
        nodeType = 'FA';
        break;

      case 'FlowFinal':
        nodeType = 'FX';
        break;

      default:
        nodeType = 'W';
        break;
    }

    return nodeType;
  }

  /**
   * Translates full node name from backend to node network short type.
   * @param type Full node name from backend.
   */
  public getNodeTypeByFullName(type: string): string {
    let nodeType = '';

    switch (type) {
      case 'de.saxms.protocol.simulation.step.ProcessStep':
        nodeType = 'W';
        break;

      case 'de.saxms.protocol.simulation.step.InitialStep':
        nodeType = 'I';
        break;

      case 'de.saxms.protocol.simulation.step.SplitStep':
        nodeType = 'F';
        break;

      case 'de.saxms.protocol.simulation.step.DecisionStep':
      case 'de.saxms.protocol.simulation.step.MergeStep':
        nodeType = 'D';
        break;

      case 'de.saxms.protocol.simulation.step.FinalStep':
        nodeType = 'FA';
        break;

      case 'de.saxms.protocol.simulation.step.FlowFinalStep':
        // todo
        nodeType = 'FX';
        break;

      default:
        nodeType = 'W';
        console.warn("node type '" + type + "' not found");
        break;
    }
    return nodeType;
  }

  /**
   * Adds/refresh label of connection.
   * @param connectionId Id of affected connection.
   * @param originData Backend dataset of node network.
   * @param labelText New label text.
   * @param scaleX Horizontal scale.
   * @param scaleY Vertical scale.
   */
  public getEndLabelByConnectionId(connectionId, originData, labelText, scaleX, scaleY) {
    return this.mapperUniversal.getEndLabelByConnectionId(connectionId, originData, labelText, scaleX, scaleY);
  }
}
