import { EntryElementValue } from '@app-modeleditor/components/entry-collection/entry-element-value';
import { EGanttSubMenuUpdateMessageType } from '@app-modules/saxms-submenu-elements/saxms-submenu.enum';
import { IGanttSubMenuUpdateMessage } from '@app-modules/saxms-submenu-elements/saxms-submenu.interface';
import { GanttDataRow } from '@gantt/public-api';
import { GanttResponse } from 'frontend/src/dashboard/gantt/general/response/responses/response';

/**
 * Handles the backend response to update block connections.
 */
export class GanttResponseColorizerByAttribute extends GanttResponse {
  private _entryElementId = 'template.entryelement.attributecolorselector';

  public handleResponse(response: any): boolean {
    let handledResponse = false;

    if (response.ganttEntries || response.deletedBlocks || response.addedGanttEntries || response.deletedGanttEntries) {
      const newAttributeList = this._generateNewAttributeList();
      const message: IGanttSubMenuUpdateMessage = {
        type: EGanttSubMenuUpdateMessageType.UPDATE,
        elementId: this._entryElementId,
        value: newAttributeList,
      };
      this.toolbarHandler.submenuService.triggerElementById(message);
    }

    handledResponse = true;

    return handledResponse;
  }

  /**
   * Generates a new attribute list for drop down selection in sub menu.
   */
  private _generateNewAttributeList(): EntryElementValue[] {
    const detailKeys: number[] = [];
    let availableValues: EntryElementValue[] = [];
    const ganttDiagram = this._ganttLibService.bestGantt;

    const iterateOverJSGanttEntries = (children: GanttDataRow[]) => {
      for (const child of children) {
        if (child.child.length) {
          iterateOverJSGanttEntries(child.child);
        }

        if (!child.shifts) continue;
        for (const block of child.shifts) {
          if (block.disableColorization) continue; // ignore shifts with disabled colorization
          const additionalDetails = block.additionalData.additionalData.additionalDetails;
          const additionalDetailsKeys = Object.keys(additionalDetails);
          for (const detailKey of additionalDetailsKeys) {
            const keyAsNumber = +detailKey; // faster parseInt
            if (!isNaN(keyAsNumber) && !detailKeys.includes(keyAsNumber)) {
              detailKeys.push(keyAsNumber);
              const attributeData = this.attributeMapping[detailKey];

              // do not add attribute colorizment if localization is empty.
              if (attributeData && attributeData.localization == '') {
                continue;
              }
              availableValues.push(
                new EntryElementValue()
                  .setValue(detailKey)
                  .setName(attributeData ? attributeData.localization : detailKey)
                  .setId(detailKey)
              );
            }
          }
        }
      }
    };

    // get all available attributes
    iterateOverJSGanttEntries(ganttDiagram.getDataHandler().getOriginDataset().ganttEntries);

    // sort by name
    availableValues = availableValues.sort((a, b) => {
      if (a.getName() < b.getName()) {
        return -1;
      }
      if (a.getName() > b.getName()) {
        return 1;
      }
    });
    return availableValues;
  }
}
