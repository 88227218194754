import { Resource } from 'frontend/src/dashboard/model/resource/resource';
import { SortOrder } from '../elements/sort-menu/sort-order.enum';
import { EntryElement } from '../entry-collection/entry-element';

export class Filter extends Resource {
  private disableFreeTextSearch: boolean;
  private fieldIdentifier: string;
  private filterOperator: string;
  private freeTextSearchFilter: boolean;
  private ignoreNullValues: boolean;
  private requireFullAssignment: boolean;
  private viewFieldIdentifier: string;
  private value: any;
  private displayFilterElement: EntryElement;
  private availableValues: string[];
  private enableSort: boolean;
  private sortOrder: SortOrder;

  public getSortOrder(): SortOrder {
    return this.sortOrder || SortOrder.none;
  }

  public setSortOrder(sortOrder: SortOrder): this {
    this.sortOrder = sortOrder;
    return this;
  }

  public isEnableSort(): boolean {
    return typeof this.enableSort === 'boolean' ? this.enableSort : false;
  }

  public setEnableSort(enableSort: boolean): this {
    this.enableSort = enableSort;
    return this;
  }

  public getAvailableValues(): string[] {
    return this.availableValues || [];
  }

  public setAvailableValues(availableValues: string[]): this {
    this.availableValues = availableValues;
    return this;
  }

  public getDisplayFilterElement(): EntryElement {
    return this.displayFilterElement;
  }

  public setDisplayFilterElement(displayFilterElement: EntryElement): this {
    this.displayFilterElement = displayFilterElement;
    return this;
  }

  public getValue(): any {
    return this.value;
  }

  public setValue(value: any): this {
    this.value = value;
    return this;
  }

  public isDisableFreeTextSearch(): boolean {
    return this.disableFreeTextSearch;
  }

  public setDisableFreeTextSearch(disableFreeTextSearch: boolean): this {
    this.disableFreeTextSearch = disableFreeTextSearch;
    return this;
  }

  public getFieldIdentifier(): string {
    return this.fieldIdentifier;
  }

  public setFieldIdentifier(fieldIdentifier: string): this {
    this.fieldIdentifier = fieldIdentifier;
    return this;
  }

  public getFilterOperator(): string {
    return this.filterOperator;
  }

  public setFilterOperator(filterOperator: string): this {
    this.filterOperator = filterOperator;
    return this;
  }

  public isFreeTextSearchFilter(): boolean {
    return this.freeTextSearchFilter;
  }

  public setFreeTextSearchFilter(freeTextSearchFilter: boolean): this {
    this.freeTextSearchFilter = freeTextSearchFilter;
    return this;
  }

  public isIgnoreNullValues(): boolean {
    return this.ignoreNullValues;
  }

  public setIgnoreNullValues(ignoreNullValues: boolean): this {
    this.ignoreNullValues = ignoreNullValues;
    return this;
  }

  public isRequireFullAssignment(): boolean {
    return this.requireFullAssignment;
  }

  public setRequireFullAssignment(requireFullAssignment: boolean): this {
    this.requireFullAssignment = requireFullAssignment;
    return this;
  }

  public getViewFieldIdentifier(): string {
    return this.viewFieldIdentifier;
  }

  public setViewFieldIdentifier(viewFieldIdentifier: string): this {
    this.viewFieldIdentifier = viewFieldIdentifier;
    return this;
  }
}
