import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ILegendEntry } from 'frontend/src/dashboard/legend/legend.interface';
import { Subscription } from 'rxjs';
import { LegendCommunicationService } from './legend-communication.service';
import { LegendFilterService } from './legend-filter.service';
import { LegendToolbarComponent } from './legend-toolbar/legend-toolbar.component';

@Component({
  selector: 'saxms-best-gantt-legend',
  templateUrl: './saxms-best-gantt.legend.html',
  styleUrls: ['./saxms-best-gantt.legend.scss'],
  providers: [],
})

/**
 * Angular component to visualize legend of the gantt.
 * Based on JavaScript LegendViewerExecuter.
 * @keywords gantt, legend, color, colors, colorize, d3, component
 */
export class SaxMsBestGanttLegend implements OnInit, OnDestroy {
  private _detectChangesSubscription: Subscription = null;

  /**
   * @param cd Angular change detection to trigger datachange manually.
   */
  constructor(
    public legendCommunicationService: LegendCommunicationService,
    private _legendFilterService: LegendFilterService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this._detectChangesSubscription = this._legendFilterService.listenToDetectChangesEvents().subscribe((_) => {
      this.cd.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this._detectChangesSubscription.unsubscribe();
  }

  /**
   * Root node to include legend via JavaScript.
   */
  @ViewChild('legendToolbar') legendToolbar: LegendToolbarComponent;
  @Input() menuOpen = false;

  /**
   * Function which connects material search bar with legend.
   * Will be triggered inside HTML-template.
   * @param event
   */
  public searchForEntry(event: KeyboardEvent): void {
    this._legendFilterService.searchForEntry((event.target as HTMLInputElement).value);
  }

  /**
   * Resets all legend filters.
   * Will be triggered inside HTML-template.
   */
  public resetLegendInDashboard(): void {
    this._legendFilterService.resetLegendInDashboard();
  }

  /**
   * Resets the current search.
   */
  public clearSearch(): void {
    this._legendFilterService.clearSearch();
  }

  /**
   * Is called if display option has changed.
   */
  public onDisplayOptionChange() {
    this._legendFilterService.updateDisplayOption();
  }

  /**
   * Validates whether legend entries exist or are visible in the Gantt.
   */
  public validateLegendVisibility() {
    this._legendFilterService.validateLegendVisibility();
  }

  /**
   * Is called by slider item and handles the option that only matched shifts attributes wich found in the search are visible.
   * Will be triggered inside HTML-template.
   * @param event slider onChange event.
   */
  public toggleHideFilteredOutEntries(event: any): void {
    this._legendFilterService.toggleHideFilteredOutEntries(event);
  }

  /**
   * Handles the visibilty checkbox item.
   * Handles the activation or deactivation of all legend entries and shift visibility.
   * @param visibilityChange
   */
  public toggleVisibility(visibilityChange: boolean): void {
    this._legendFilterService.toggleVisibility(visibilityChange);
  }

  /**
   * Handles the functions wich are executed by clicking on a legend enty.
   * Is registered in onClick callback in legendViewerExecuter.
   */
  public onItemClick(legendEntry: ILegendEntry): void {
    legendEntry.isActive = !legendEntry.isActive;
    this._legendFilterService.update();
  }

  /**
   * Handles the functions wich are executed by double clicking on a legend enty.
   * Is registered in onDoubleClick callback in legendViewerExecuter.
   */
  public onItemDoubleClick(legendEntry: ILegendEntry): void {
    this._legendFilterService.toggleLegendEntryFocus(legendEntry);
  }

  protected onResetIgnoredEntries(): void {
    this._legendFilterService.onResetIgnoredRows();
  }

  protected onIgnoreEditedEntries(): void {
    this._legendFilterService.onResetIgnoredRows();
  }
}
