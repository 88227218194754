<mat-form-field class="filter-wrapper" #filterWrapper>
  <span matPrefix></span>
  <input
    #filterfield
    (ngModelChange)="freetextFilter()"
    matInput
    [placeholder]="data.placeholder"
    [(ngModel)]="data.value"
  />
  <mat-icon matPrefix>search</mat-icon>
  <button
    mat-icon-button
    matSuffix
    *ngIf="showExtendedFilter"
    [disabled]="c?.getEntryElements().length == 0"
    (click)="onOpen()"
    #filterMenu
    [matMenuTriggerFor]="menu"
    #filterTrigger
  >
    <mat-icon>keyboard_arrow_down</mat-icon>
  </button>
</mat-form-field>
<!--<button [disabled]="data.filters.length==0" (click)="onOpen()" #filterMenu [matMenuTriggerFor]="menu" #filterTrigger
  mat-icon-button>
  <mat-icon>more_horiz</mat-icon>
</button>-->
<mat-menu #menu="matMenu" class="filter-overlay-panel" (closed)="isOpen = false" xPosition="before">
  <mat-card class="filter-menu" (click)="onPreventClick($event)" *ngIf="isOpen">
    <mat-card-header>
      <mat-card-title>{{ 'FILTER.EXTENDED' | translate }}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <ng-container *ngIf="c">
        <template-slot [source]="c" (afterInit)="afterSlotInit($event)"></template-slot>
      </ng-container>
    </mat-card-content>
    <mat-card-actions>
      <button mat-button [id]="'saxms-dialog-clear'" (click)="clearAll()">
        {{ 'BUTTON.CLEAR' | translate | capitalize }}
      </button>
      <button mat-flat-button [id]="'saxms-dialog-confirm'" (click)="complexFilter()">
        {{ 'BUTTON.APPLY' | translate | capitalize }}
      </button>

      <div
        class="filter-resizer"
        [class.leftSide]="isMenuPositionLeft()"
        cdkDragLockAxis="x"
        cdkDrag
        (cdkDragMoved)="onMove($event)"
        (cdkDragEnded)="afterDrag($event)"
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
          <title>Icon_Inputfield-Resize</title>
          <path
            d="M12.41,11.21a.5.5,0,0,1,.71,0,.48.48,0,0,1,0,.7l-.71.71a.48.48,0,0,1-.7,0,.5.5,0,0,1,0-.71Z"
            fill-rule="evenodd"
          />
          <path d="M12.41,8.38a.5.5,0,1,1,.71.71L9.59,12.62a.5.5,0,1,1-.71-.71Z" fill-rule="evenodd" />
          <path d="M12.77,5.2a.49.49,0,1,1,.7.7L6.4,13a.49.49,0,1,1-.7-.7Z" fill-rule="evenodd" />
        </svg>
      </div>
    </mat-card-actions>
  </mat-card>
</mat-menu>
