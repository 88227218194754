import { EPredefinedAction } from '@app-modeleditor/components/button/action/predefined-action.enum';
import { EntryElementValue } from '@app-modeleditor/components/entry-collection/entry-element-value';
import { EGanttInstance, GanttDataRow, IGanttAdditionalDetails } from '@gantt/public-api';
import { GanttLibService } from 'frontend/src/dashboard/gantt/gantt/gantt-lib.service';
import { SaxMsBestGanttActiveSubmenuEntryElementSetting } from 'frontend/src/dashboard/gantt/gantt/saxms-best-gantt.settings';
import { GeneralGanttAdditionalBlockInfo } from 'frontend/src/dashboard/gantt/general/generator/mapper/gantt-to-gantt-origin.mapper';
import { GanttPluginHandlerService } from 'frontend/src/dashboard/gantt/general/plugin/gantt-plugin-handler.service';
import { GanttResponseHandler } from 'frontend/src/dashboard/gantt/general/response/response-handler';
import { GanttTemplateData } from 'frontend/src/dashboard/gantt/helper/gantt';
import { Observable, of } from 'rxjs';
import { GeneralGanttActionHandler } from '../../../action-handling/action-handler';
import { IGanttDefaultFrontendFilter } from '../../../generator/gantt-input.data';
import { GanttEssentialPlugIns } from '../../e-gantt-essential-plugins';
import { ExternalGanttPlugin } from '../../external-plugin';
import { GanttOverlappingShiftsPlugIn } from '../overlapping-shifts/overlapping-shifts';
import { GanttResponseBlockFilterUpdate } from './responses/block-filter-update.response';

/**
 * This PlugIn is NOT based on any JS-PlugIn.
 * It gives the possibility to hide shifts and show them by local action.
 */
export class GanttBlockFilterPlugIn extends ExternalGanttPlugin {
  private alive: boolean;
  private defaultFrontEndFilter: IGanttDefaultFrontendFilter[] = [];

  private activeFilterIds: string[] = [];
  private prevActiveFilters = 0;

  private shiftSplitter: GanttOverlappingShiftsPlugIn;

  constructor(
    protected _ganttPluginHandlerService: GanttPluginHandlerService,
    protected _ganttLibService: GanttLibService,
    actionHandler: GeneralGanttActionHandler,
    private responseHandler: GanttResponseHandler
  ) {
    super(_ganttPluginHandlerService, _ganttLibService, actionHandler);
    this.alive = true;
  }

  /**
   * Extracts default block filter by backend data.
   * @param templateData Gantt template data.
   * @param responseData Backend Hierarchical plan.
   */
  public onInit(templateData: GanttTemplateData, responseData: any): void {
    this.defaultFrontEndFilter = templateData.getDefaultFrontEndFilter();
    // init default filter
    if (!this.defaultFrontEndFilter) return;
    this.shiftSplitter = this._ganttPluginHandlerService.getEssentialPlugIn(
      GanttEssentialPlugIns.OverlappingShiftsPlugIn
    );
    this.activateDefaultFilterByDefault(this.defaultFrontEndFilter);
    this.responseHandler.addResponse(GanttResponseBlockFilterUpdate, this);
  }

  public onDestroy(): void {}

  public onAction(action: any) {}

  public resetFilters(): void {
    this.activeFilterIds = [];
    this.filterAllBlocks();
    this.deactivateAllSlideTogglesInToolbar();
  }

  public resetFilterById(filterId: string): void {
    this.activeFilterIds = this.activeFilterIds.filter((id) => id != filterId);
    this.filterAllBlocks();
    this.deactivateSlideTogglesInToolbarByFilterId(filterId);
  }

  private deactivateAllSlideTogglesInToolbar(): void {
    for (const menuItem of this.actionHandler.newToolbar.getMenuItems()) {
      // iterate over menu groups
      for (const group of menuItem.getToolbarGroups()) {
        // iterate over menu group items
        for (const element of group.getEntryElements()) {
          if (element.getLocalID() === EPredefinedAction.TOGGLE_DEFAULT_FILTER) {
            element.getValue<EntryElementValue>().setValue(true); // true means -> show filtered blocks
          }
        }
      }
    }
  }

  private deactivateSlideTogglesInToolbarByFilterId(filterId: string): void {
    for (const menuItem of this.actionHandler.newToolbar.getMenuItems()) {
      // iterate over menu groups
      for (const group of menuItem.getToolbarGroups()) {
        // iterate over menu group items
        for (const element of group.getEntryElements()) {
          if (element.getLocalID() === EPredefinedAction.TOGGLE_DEFAULT_FILTER) {
            if (element.getFieldIdentifier() === filterId) {
              element.getValue<EntryElementValue>().setValue(true); // true means -> show filtered blocks
            }
          }
        }
      }
    }
  }

  /**
   * Activates filter function of given default filters.
   * Use this initial to hide shift blocks.
   * @param defaultFrontEndFilter
   */
  private activateDefaultFilterByDefault(defaultFrontEndFilter: IGanttDefaultFrontendFilter[]): void {
    if (!defaultFrontEndFilter) return;
    for (const filter of defaultFrontEndFilter) {
      if (this.activeFilterIds.indexOf(filter.id) == -1) this.activeFilterIds.push(filter.id);
    }
    this.filterAllBlocks(false);
  }

  /**
   * Handles local action execution to (de-)activate filters.
   * @param action Local action.
   */
  public executeAction(action: any): Observable<any> {
    if (!action.filterId || !this.defaultFrontEndFilter) {
      return of(null);
    }
    switch (action.id) {
      case EPredefinedAction.TOGGLE_DEFAULT_FILTER:
        this.toggleFilterId(action.filterId);
        this.filterAllBlocks();
        this.gantt.getDataHandler().initCanvasShiftData();
        this.gantt.rerenderShiftsVertical();
        break;
    }
    return of(null);
  }

  /**
   * Adds/removes filter id to list of active filters.
   * @param filterId
   */
  private toggleFilterId(filterId: string): boolean {
    const filterIndex: number = this.activeFilterIds.indexOf(filterId);
    let hasBeenFiltered: boolean;
    if (filterIndex == -1) {
      this.activeFilterIds.push(filterId);
      hasBeenFiltered = true;
    } else {
      this.activeFilterIds.splice(filterIndex, 1);
      hasBeenFiltered = false;
    }
    return hasBeenFiltered;
  }

  /**
   * Filters all blocks and rows by paying attention to active filter list.
   * @param payAttentionToOverlappingShifts If false, overlapping shifts handling will be ignored.
   */
  public filterAllBlocks(payAttentionToOverlappingShifts = true): void {
    if (!this.defaultFrontEndFilter) return;
    if (this.prevActiveFilters == 0 && this.activeFilterIds.length == 0) return;
    this.prevActiveFilters = this.activeFilterIds.length;
    if (payAttentionToOverlappingShifts) this.shiftSplitter.resetSplitOverlappingShifts(false);

    // deactivate rendering by blocks
    const parents: GanttDataRow[] = [];
    const filterAllBlocks = (child: GanttDataRow, level: number, parent: GanttDataRow) => {
      this._hideBlocksByAttribute(child);
      this._hideRowByAttribute(child, parents);
      parents.push(child);
    };
    const afterGoingDeeper = () => {
      parents.pop();
    };

    this._ganttLibService.ganttInstanceService
      .getInstance(EGanttInstance.DATA_MANIPULATOR)
      .iterateOverDataSet(
        this.gantt.getDataHandler().getOriginDataset().ganttEntries,
        { filterAllBlocks: filterAllBlocks.bind(this) },
        { afterGoingDeeper: afterGoingDeeper.bind(this) }
      );
    if (payAttentionToOverlappingShifts) this.shiftSplitter.splitOverlappingShifts();
  }

  /**
   * Callback to check and hide all shifts of a given row.
   * @param child Row to check the shifts for.
   */
  private _hideBlocksByAttribute(child: GanttDataRow): void {
    for (const shift of child.shifts) {
      const details: GeneralGanttAdditionalBlockInfo = shift.additionalData as GeneralGanttAdditionalBlockInfo;
      if (!details.additionalData || !details.additionalData.additionalDetails) continue;

      this.defaultFrontEndFilter
        .map((filter) => filter.id)
        .forEach((filterId) => {
          this._ganttLibService.ganttInstanceService
            .getInstance(EGanttInstance.GANTT_UTILITIES)
            .removeNoRenderId(shift, filterId);
        });

      for (const filter of this.defaultFrontEndFilter) {
        const isFilterActive = !!this.activeFilterIds.find((filterId) => filterId === filter.id);
        if (isFilterActive) {
          if (this.blockMatchesFilter(details.additionalData.additionalDetails, filter)) {
            this._ganttLibService.ganttInstanceService
              .getInstance(EGanttInstance.GANTT_UTILITIES)
              .registerNoRenderId(shift, filter.id);
            break;
          }
        } else if (filter.invertible) {
          if (!this.blockMatchesFilter(details.additionalData.additionalDetails, filter)) {
            this._ganttLibService.ganttInstanceService
              .getInstance(EGanttInstance.GANTT_UTILITIES)
              .registerNoRenderId(shift, filter.id);
            break;
          }
        }
      }
    }
  }

  /**
   * Callback to check and hide a given row.
   * @param child Row to check.
   */
  private _hideRowByAttribute(child: GanttDataRow, parents: GanttDataRow[]): void {
    const details = child.additionalData;

    this.defaultFrontEndFilter
      .map((filter) => filter.id)
      .forEach((filterId) => {
        this._ganttLibService.ganttInstanceService
          .getInstance(EGanttInstance.GANTT_UTILITIES)
          .removeNoRenderId(child, filterId);
      });

    let isRowVisible = true;
    for (const filter of this.defaultFrontEndFilter) {
      const isFilterActive = !!this.activeFilterIds.find((filterId) => filterId === filter.id);
      if (isFilterActive) {
        if (this.blockMatchesFilter(details.additionalData, filter)) {
          this._ganttLibService.ganttInstanceService
            .getInstance(EGanttInstance.GANTT_UTILITIES)
            .registerNoRenderId(child, filter.id);
          isRowVisible = false;
          break;
        }
      } else if (filter.invertible) {
        if (!this.blockMatchesFilter(details.additionalData, filter)) {
          this._ganttLibService.ganttInstanceService
            .getInstance(EGanttInstance.GANTT_UTILITIES)
            .registerNoRenderId(child, filter.id);
          isRowVisible = false;
          break;
        }
      }
    }
    // remove possible noRender ids from parents
    if (isRowVisible) this._unhideRows(parents);
  }

  /**
   * Removes all noRender ids added by this plug-in from the specified rows.
   * @param childs Rows to remove the noRender ids for.
   */
  private _unhideRows(childs: GanttDataRow[]): void {
    if (!childs || childs.length <= 0) return;
    for (const child of childs) {
      if (!child.noRender || child.noRender.length <= 0) continue;
      for (const filter of this.defaultFrontEndFilter) {
        this._ganttLibService.ganttInstanceService
          .getInstance(EGanttInstance.GANTT_UTILITIES)
          .removeNoRenderId(child, filter.id);
      }
    }
  }

  /**
   * Check for given block details if block is affected by given filter.
   * @param blockDetails additionalData.additionalDetails of block.
   * @param filter Backend definition of filter.
   */
  private blockMatchesFilter(blockDetails: IGanttAdditionalDetails, filter: IGanttDefaultFrontendFilter): boolean {
    if (!blockDetails) return false;
    const filterId = filter.ganttAttributeMapping.id;
    if (blockDetails[filterId]) return blockDetails[filterId].t2 == filter.value;
    else return false;
  }

  public injectSettings(submenuElements: SaxMsBestGanttActiveSubmenuEntryElementSetting[]): void {}

  public getNoRenderIds(): string[] {
    return (this.defaultFrontEndFilter || []).map((filter) => filter.id);
  }

  public getFilterValueById(filterId: string): string | number | boolean {
    const value = (this.defaultFrontEndFilter || []).find((filter) => filter.id === filterId)?.value;
    if (!value && value !== 0 && value !== false) return 'unknown filter';
    return value;
  }

  public isFilterInvertibleById(filterId: string): boolean {
    const filter = this.defaultFrontEndFilter.find((filter) => filter.id === filterId);
    if (!filter) return false;
    return filter.invertible;
  }
}
