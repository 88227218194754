import { ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { Subscription } from 'rxjs';
import { ResourceCommunicationService } from '../../resource-communication.service';
import { ResourcesDataService } from '../../resources-data.service';
import { ISelectionBoxFenceCoordinates } from '../../selection-box/selection-box-fence-coordinates';
import { IResourceElement } from './resource-element.interface';

@Component({
  selector: 'app-resource-element',
  templateUrl: './resource-element.component.html',
  styleUrls: ['./resource-element.component.scss'],
})
export class ResourceElementComponent implements OnInit, OnDestroy {
  @Input('element') elementData: IResourceElement = null;
  @Input() visibleAttributes: string[] = [];
  @ViewChild('resource') resourceElement: ElementRef;
  @ViewChild(MatMenuTrigger, { static: true }) matMenuTrigger: MatMenuTrigger;

  public selected = false;
  public disabled = false;

  private _onSingleSelectionSubscription: Subscription = null;
  private _onHighlightSubscription: Subscription = null;
  private _onMultiSelectionSubscription: Subscription = null;
  private _onSelectionFenceSubscription: Subscription = null;

  constructor(
    private _resourceCommunicationService: ResourceCommunicationService,
    private _resourcesDataService: ResourcesDataService,
    private _cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this._subscribeToSelection();
    this._subscribeToSelectionFence();
    this._subscribeToMultiSelection();
    this._subscribeToHighlight();
  }

  ngOnDestroy(): void {
    this._onSingleSelectionSubscription.unsubscribe();
    this._onHighlightSubscription.unsubscribe();
    this._onSelectionFenceSubscription.unsubscribe();
    this._onMultiSelectionSubscription.unsubscribe();
  }

  public onClick(event: PointerEvent): void {
    const bounding: DOMRect = this.resourceElement.nativeElement.getBoundingClientRect();
    const position = [bounding.x, bounding.y];

    if (event.ctrlKey) {
      if (this.selected) {
        this._resourceCommunicationService.removeFromSelectedResources(this.elementData.id);
      } else {
        this._resourceCommunicationService.addToSelectedResources(this.elementData.id);
      }
      this._resourceCommunicationService.lastClickedResourcePosition = position;
    } else if (event.shiftKey) {
      this._resourceCommunicationService.emitMultiSelection(position);
    } else {
      this._resourceCommunicationService.clearSelectedResources();
      this._resourceCommunicationService.addToSelectedResources(this.elementData.id);
      this._resourceCommunicationService.lastClickedResourcePosition = position;
    }
    this._resourceCommunicationService.emitSingleSelection();
    event.stopPropagation();
  }

  public onRightClick(event: MouseEvent): void {
    event.preventDefault();

    if (!this.selected) {
      this._resourceCommunicationService.clearSelectedResources();
      this._resourceCommunicationService.emitSingleSelection();
    }

    this.matMenuTrigger.openMenu();
  }

  public isFavorite(): boolean {
    return this._resourcesDataService.isFavorite(this.elementData.id);
  }

  public setFavorite(bool: boolean): void {
    if (bool) {
      if (this.selected) {
        this._resourceCommunicationService.currentSelectedResources.forEach((resId) =>
          this._resourcesDataService.addFavorite(resId)
        );
      } else {
        this._resourcesDataService.addFavorite(this.elementData.id);
      }
    } else {
      if (this.selected) {
        this._resourceCommunicationService.currentSelectedResources.forEach((resId) =>
          this._resourcesDataService.removeFavorite(resId)
        );
      } else {
        this._resourcesDataService.removeFavorite(this.elementData.id);
      }
    }
  }

  public getAttributeValueByKey(key: string): string {
    const result = this.elementData.attributes.find((elem) => elem.property === key)?.value;
    return result || '-';
  }

  private _subscribeToSelection(): void {
    this._onSingleSelectionSubscription = this._resourceCommunicationService
      .listenForSingleSelection()
      .subscribe((resourceIds: string[]) => {
        if (!resourceIds) {
          return;
        }
        this.selected = !!resourceIds.find((id) => id === this.elementData.id);
        this._cd.detectChanges();
      });
  }

  private _subscribeToHighlight(): void {
    this._onHighlightSubscription = this._resourceCommunicationService
      .listenForHighlight()
      .subscribe((resourceIds: string[]) => {
        if (!resourceIds) {
          if (this.disabled) {
            this.disabled = false;
            this._cd.detectChanges();
          }
          return;
        }
        this.disabled = !resourceIds.find((id) => id === this.elementData.id);
        this._cd.detectChanges();
      });
  }

  private _subscribeToSelectionFence(): void {
    this._onSelectionFenceSubscription = this._resourceCommunicationService
      .listenForSelectionFence()
      .subscribe((fence: ISelectionBoxFenceCoordinates) => {
        if (!fence) {
          return;
        }
        const isInFence = this._isElementInSelectionFence(fence);
        const isAlreadySelected = this._resourceCommunicationService.currentSelectedResources.find(
          (id) => id === this.elementData.id
        );

        if (isInFence && !isAlreadySelected) {
          this.selected = true;
          this._resourceCommunicationService.addToSelectedResources(this.elementData.id);
        } else if (isInFence && isAlreadySelected) {
          this.selected = false;
          this._resourceCommunicationService.removeFromSelectedResources(this.elementData.id);
        }
        this._cd.detectChanges();
      });
  }

  private _subscribeToMultiSelection(): void {
    this._onMultiSelectionSubscription = this._resourceCommunicationService
      .listenForMultiSelection()
      .subscribe((selectionPosition: number[]) => {
        const bounding: DOMRect = this.resourceElement.nativeElement.getBoundingClientRect();
        const ownPosition = [bounding.x, bounding.y];
        const firstClickPosition = this._resourceCommunicationService.lastClickedResourcePosition;
        let startSelectionPosition, endSelectionPosition;

        if (
          firstClickPosition[1] < selectionPosition[1] ||
          (firstClickPosition[1] === selectionPosition[1] && firstClickPosition[0] <= selectionPosition[0])
        ) {
          // selection downwards
          startSelectionPosition = firstClickPosition;
          endSelectionPosition = selectionPosition;
        } else {
          // selection upwards
          startSelectionPosition = selectionPosition;
          endSelectionPosition = firstClickPosition;
        }

        const isSameRow = startSelectionPosition[1] === endSelectionPosition[1];

        if (
          (ownPosition[1] > startSelectionPosition[1] && ownPosition[1] < endSelectionPosition[1]) ||
          (isSameRow &&
            ownPosition[1] === startSelectionPosition[1] &&
            ownPosition[0] >= startSelectionPosition[0] &&
            ownPosition[1] === endSelectionPosition[1] &&
            ownPosition[0] <= endSelectionPosition[0]) ||
          (!isSameRow &&
            ((ownPosition[1] === startSelectionPosition[1] && ownPosition[0] >= startSelectionPosition[0]) ||
              (ownPosition[1] === endSelectionPosition[1] && ownPosition[0] <= endSelectionPosition[0])))
        ) {
          this._resourceCommunicationService.addToSelectedResources(this.elementData.id);
        } else {
          this._resourceCommunicationService.removeFromSelectedResources(this.elementData.id);
        }
      });
  }

  private _isElementInSelectionFence(fence: ISelectionBoxFenceCoordinates): boolean {
    const bounding: DOMRect = this.resourceElement.nativeElement.getBoundingClientRect();

    const smallestValue = [0, 0],
      biggestValue = [0, 0];

    if (fence.topLeft[0] < fence.bottomRight[0]) {
      smallestValue[0] = fence.topLeft[0];
      biggestValue[0] = fence.bottomRight[0];
    } else {
      smallestValue[0] = fence.bottomRight[0];
      biggestValue[0] = fence.topLeft[0];
    }

    if (fence.topLeft[1] < fence.bottomRight[1]) {
      smallestValue[1] = fence.topLeft[1];
      biggestValue[1] = fence.bottomRight[1];
    } else {
      smallestValue[1] = fence.bottomRight[1];
      biggestValue[1] = fence.topLeft[1];
    }

    const middleX = bounding.x + bounding.width / 2;
    const middleY = bounding.y + bounding.height / 2;

    if (
      middleX >= smallestValue[0] &&
      middleX <= biggestValue[0] &&
      middleY >= smallestValue[1] &&
      middleY <= biggestValue[1]
    ) {
      return true;
    } else {
      return false;
    }
  }
}
