import { ETemplateType } from 'frontend/src/dashboard/model/resource/template-type';
import { ContentElement } from '../content-element/content-element';
import { StructuralElement } from '../structural-element';

export class ContentPart extends StructuralElement {
  private contentElement: ContentElement[];
  private displayContentpartContainer: boolean;
  private expanded: boolean;

  constructor() {
    super();
    this.setType(ETemplateType.CONTENT_PART);
  }

  /**
   * whether the content-part is expanded or not
   * @returns boolean
   */
  public isExpanded(): boolean {
    return typeof this.expanded === 'boolean' ? this.expanded : true;
  }

  /**
   * expand or shrink content-part
   * @param {boolean} expanded whether to expand or shrink content-part
   * @returns ContentPart
   */
  public setExpanded(expanded: boolean): this {
    this.expanded = expanded;
    return this;
  }

  /**
   * get content elements
   * @returns ContentElement[]
   */
  public getContentElements<T extends ContentElement[]>(): T {
    return (this.contentElement || []) as T;
  }

  /**
   * set content elements
   * @param contentElements ContentElement[]
   * @returns this
   */
  public setContentElements(contentElements: ContentElement[]): this {
    this.contentElement = contentElements;
    this.getContentElements().forEach((ce: ContentElement) => ce.setParentId(this.getId()));
    return this;
  }

  public addContentElements(...contentElements: ContentElement[]): this {
    this.contentElement = this.getContentElements().concat(contentElements);
    return this;
  }

  /**
   * whether styles of content part container are visbile or not
   * @returns boolean
   */
  public isDisplayContentpartContainer(): boolean {
    return typeof this.displayContentpartContainer === 'boolean' ? this.displayContentpartContainer : true;
  }

  /**
   * enables or disables styles or content part container
   * @param displayContentpartContainer boolean
   * @returns this
   */
  public setDisplayContentpartContainer(displayContentpartContainer: boolean): this {
    this.displayContentpartContainer = displayContentpartContainer;
    return this;
  }
}
