/**
 * Data container to colorize node network fields by attribute.
 * Saves attribute name and all color-attribute-allocations.
 */
export class NodeNetworkColorAttribute {
  public attributeName: string;

  /**
   * @type Map<string, string>
   * A map with 'attribute-value' as key and (hex-)color as value
   */
  public attributeColors: any;
}
