export enum EBadgePosition {
  ABOVE_AND_AFTER = 'above after',
  ABOVE_AND_BEFORE = 'above before',
  BELOW_AND_BEFORE = 'below before',
  BELOW_AND_AFTER = 'below after',
  BEFORE = 'before',
  AFTER = 'after',
  ABOVE = 'above',
  BELOW = 'below',
}
