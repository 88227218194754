import { Injectable } from '@angular/core';
import { ShiftDataFinder } from '../../best_gantt/script/data-handler/data-finder/shift-data-finder';
import { YAxisDataFinder } from '../../best_gantt/script/data-handler/data-finder/yaxis-data-finder';
import { DataManipulator } from '../../best_gantt/script/data-handler/data-tools/data-manipulator';
import { ShiftDataSorting } from '../../best_gantt/script/data-handler/data-tools/data-sorting';
import { GanttUtilities } from '../../best_gantt/script/gantt-utilities/gantt-utilities';
import { GanttYAxisTableBuilder } from '../../best_gantt/script/plug-ins/add-y-axis-table/add-y-axis-table';
import { GanttBlockHighlighter } from '../../best_gantt/script/plug-ins/block-highlighter/block-highlighter';
import { GanttBrokenConstraintsNavigator } from '../../best_gantt/script/plug-ins/broken-constraints-navigator/broken-constraints-navigator-executer';
import { CompareGantts } from '../../best_gantt/script/plug-ins/compare-gantts/compare-gantts-executer';
import { GanttEarliestStartLatestEndVisualizer } from '../../best_gantt/script/plug-ins/earliest-start-latest-end-visualizer/earliest-start-latest-end-visualizer';
import { GanttEdgeHandler } from '../../best_gantt/script/plug-ins/edges/edge-executer';
import { FilterShiftsByAttribute } from '../../best_gantt/script/plug-ins/filter-shifts-by-attribute/filter-shifts-by-attribute';
import { FilterShiftsByCondition } from '../../best_gantt/script/plug-ins/filter-shifts-by-condition/filter-shifts-by-condition-executer';
import { GanttGridLabels } from '../../best_gantt/script/plug-ins/grid-labels/grid-labels';
import { IndexCardExecuter } from '../../best_gantt/script/plug-ins/index-card/index-card-executer';
import { GanttOverlay } from '../../best_gantt/script/plug-ins/overlay/overlay-executer';
import { GanttPrinterExecuter } from '../../best_gantt/script/plug-ins/print-view/gantt-print-view-executer';
import { GanttPrintViewStrategieEFW } from '../../best_gantt/script/plug-ins/print-view/print-strategies/efw-printing-strategie';
import { GanttPrintViewStrategyLW } from '../../best_gantt/script/plug-ins/print-view/print-strategies/lw-printing-strategy';
import { RowColorHierarchieExecuter } from '../../best_gantt/script/plug-ins/row-color-hierarchie/row-color-hierarchie';
import { GanttRowColorizer } from '../../best_gantt/script/plug-ins/row-colorizer/row-colorizer';
import { GanttRowLimiterMarker } from '../../best_gantt/script/plug-ins/row-limiter/mark-rows-executer';
import { RowRasterColorExecuter } from '../../best_gantt/script/plug-ins/row-raster-color/row-raster-color-executer';
import { GanttRowSearchExecuter } from '../../best_gantt/script/plug-ins/row-search/row-search-executer';
import { SettingBubblesExecuter } from '../../best_gantt/script/plug-ins/setting-bubbles/setting-bubble-executer';
import { GanttShiftComponentGroup } from '../../best_gantt/script/plug-ins/shift-components/shift-component-group';
import { GanttShiftComponents } from '../../best_gantt/script/plug-ins/shift-components/shift-components';
import { GanttShiftCreator } from '../../best_gantt/script/plug-ins/shift-creator/shift-creator';
import { GanttShiftDragLimiter } from '../../best_gantt/script/plug-ins/shift-drag-limiter/shift-drag-limiter-executer';
import { GanttShiftDragVisualizer } from '../../best_gantt/script/plug-ins/shift-drag-visualizer/shift-drag-visualizer';
import { GanttShiftLoading } from '../../best_gantt/script/plug-ins/shift-loading/shift-loading';
import { GanttShiftMover } from '../../best_gantt/script/plug-ins/shift-mover/shift-mover-executer';
import { ShiftSplitByClick } from '../../best_gantt/script/plug-ins/shift-splitter/shift-split-by-click';
import {
  GanttShiftColorByAttribute,
  GanttShiftColorByAttributeParams,
} from '../../best_gantt/script/plug-ins/shiftcolor-by-attribute/shiftcolor-by-attribute-executer';
import { GanttShowShiftTimeStamps } from '../../best_gantt/script/plug-ins/show-shift-timestamps/show-shift-timestamps-executer';
import { GanttSplitOverlappingShifts } from '../../best_gantt/script/plug-ins/split-overlapping-shifts/split-overlapping-shifts-executer';
import { GanttSplitOverlappingShiftsDefaultStrategy } from '../../best_gantt/script/plug-ins/split-overlapping-shifts/strategies/default';
import { GanttSplitOverlappingShiftsPrioritizedStrategy } from '../../best_gantt/script/plug-ins/split-overlapping-shifts/strategies/priorised';
import { GanttSplitOverlappingShiftsByAttributeStrategy } from '../../best_gantt/script/plug-ins/split-overlapping-shifts/strategies/sortByAttribute';
import { GanttStickyBlocks } from '../../best_gantt/script/plug-ins/sticky-blocks/sticky-blocks';
import { GanttTexturizer } from '../../best_gantt/script/plug-ins/texturizer/texturizer-executer';
import { GanttXAxisZoomTransformator } from '../../best_gantt/script/plug-ins/time-manipulator/x-axis-zoom-transformator-executer';
import { GanttTimePeriodGroupExecuter } from '../../best_gantt/script/plug-ins/timeperiod-marker/executer-timeperiod-marker-group';
import { GanttCurrentTimePointMarker } from '../../best_gantt/script/plug-ins/timepoint-marker/executer-current-timepoint-marker';
import { GanttTimePointExecuter } from '../../best_gantt/script/plug-ins/timepoint-marker/executer-timepoint-marker';
import { TextOverlayDefault } from '../../best_gantt/script/shifts/text-overlay-strategies/text-overlay-default';
import { TextOverlayInside } from '../../best_gantt/script/shifts/text-overlay-strategies/text-overlay-inside';
import { TextOverlayNoText } from '../../best_gantt/script/shifts/text-overlay-strategies/text-overlay-no-text';
import { TextOverlayOutside } from '../../best_gantt/script/shifts/text-overlay-strategies/text-overlay-outside';
import { TextOverlayOutsideSplit } from '../../best_gantt/script/shifts/text-overlay-strategies/text-overlay-outside-split';
import { GanttXAxisFormatGeneral } from '../../best_gantt/script/x-axis/axis-formats/x-axis-format-general';
import { EGanttInstance } from './gantt-instance.enum';

export type EnumToInstanceMap<T> = T extends EGanttInstance.DATA_MANIPULATOR
  ? typeof DataManipulator
  : T extends EGanttInstance.GANTT_UTILITIES
  ? typeof GanttUtilities
  : T extends EGanttInstance.SHIFT_DATA_FINDER
  ? typeof ShiftDataFinder
  : T extends EGanttInstance.SHIFT_DATA_SORTING
  ? typeof ShiftDataSorting
  : T extends EGanttInstance.Y_AXIS_DATA_FINDER
  ? typeof YAxisDataFinder
  : T extends EGanttInstance.COMPARE_GANTTS
  ? CompareGantts
  : T extends EGanttInstance.FILTER_SHIFTS_BY_ATTRIBUTE
  ? FilterShiftsByAttribute
  : T extends EGanttInstance.CURRENT_TIME_POINT_MARKER
  ? GanttCurrentTimePointMarker
  : T extends EGanttInstance.GANTT_OVERLAY
  ? GanttOverlay
  : T extends EGanttInstance.PRINTER
  ? GanttPrinterExecuter
  : T extends EGanttInstance.PRINT_VIEW_STRATEGY_EFW
  ? GanttPrintViewStrategieEFW
  : T extends EGanttInstance.PRINT_VIEW_STRATEGY_LW
  ? GanttPrintViewStrategyLW
  : T extends EGanttInstance.ROW_SEARCH
  ? GanttRowSearchExecuter
  : T extends EGanttInstance.SHIFT_COMPONENTS_GROUP
  ? GanttShiftComponentGroup
  : T extends EGanttInstance.SHIFT_COMPONENTS
  ? GanttShiftComponents
  : T extends EGanttInstance.SHIFT_DRAG_LIMITER
  ? GanttShiftDragLimiter
  : T extends EGanttInstance.SHIFT_MOVER
  ? GanttShiftMover
  : T extends EGanttInstance.SHOW_SHIFT_TIME_STAMP
  ? GanttShowShiftTimeStamps
  : T extends EGanttInstance.SHIFT_COLOR_BY_ATTRIBUTE
  ? GanttShiftColorByAttribute
  : T extends EGanttInstance.SHIFT_COLOR_BY_ATTRIBUTE_PARAMS
  ? GanttShiftColorByAttributeParams
  : T extends EGanttInstance.SPLIT_OVERLAPPING_SHIFTS
  ? GanttSplitOverlappingShifts
  : T extends EGanttInstance.SPLIT_OVERLAPPING_SHIFTS_DEFAULT_STRATEGY
  ? GanttSplitOverlappingShiftsDefaultStrategy
  : T extends EGanttInstance.SPLIT_OVERLAPPING_SHIFTS_PRIORITIZED_STRATEGY
  ? GanttSplitOverlappingShiftsPrioritizedStrategy
  : T extends EGanttInstance.SPLIT_OVERLAPPING_SHIFTS_BY_ATTRIBUTE_STRATEGY
  ? GanttSplitOverlappingShiftsByAttributeStrategy
  : T extends EGanttInstance.TEXTURIZER
  ? GanttTexturizer
  : T extends EGanttInstance.TIME_PERIOD_GROUP
  ? GanttTimePeriodGroupExecuter
  : T extends EGanttInstance.TIME_POINT
  ? GanttTimePointExecuter
  : T extends EGanttInstance.EDGE_HANDLER
  ? GanttEdgeHandler
  : T extends EGanttInstance.X_AXIS_FORMAT_GENERAL
  ? GanttXAxisFormatGeneral
  : T extends EGanttInstance.X_AXIS_ZOOM_TRANSFORMATOR
  ? GanttXAxisZoomTransformator
  : T extends EGanttInstance.Y_AXIS_TABLE_BUILDER
  ? GanttYAxisTableBuilder
  : T extends EGanttInstance.INDEX_CARD
  ? IndexCardExecuter
  : T extends EGanttInstance.SETTING_BUBBLES
  ? SettingBubblesExecuter
  : T extends EGanttInstance.SHIFT_SPLIT_BY_CLICK
  ? ShiftSplitByClick
  : T extends EGanttInstance.TEXT_OVERLAY_DEFAULT
  ? TextOverlayDefault
  : T extends EGanttInstance.TEXT_OVERLAY_INSIDE
  ? TextOverlayInside
  : T extends EGanttInstance.TEXT_OVERLAY_NO_TEXT
  ? TextOverlayNoText
  : T extends EGanttInstance.TEXT_OVERLAY_OUTSIDE
  ? TextOverlayOutside
  : T extends EGanttInstance.TEXT_OVERLAY_OUTSIDE_SPLIT
  ? TextOverlayOutsideSplit
  : T extends EGanttInstance.SHIFT_CREATOR
  ? GanttShiftCreator
  : T extends EGanttInstance.SHIFT_DRAG_VISUALIZER
  ? GanttShiftDragVisualizer
  : T extends EGanttInstance.FILTER_SHIFTS_BY_CONDITION
  ? FilterShiftsByCondition
  : T extends EGanttInstance.STICKY_BLOCKS
  ? GanttStickyBlocks
  : T extends EGanttInstance.ROW_COLORIZER
  ? GanttRowColorizer
  : T extends EGanttInstance.GANTT_BLOCK_HIGHLIGHTER
  ? GanttBlockHighlighter
  : T extends EGanttInstance.GANTT_EARLIEST_START_LATEST_END_VISUALIZER
  ? GanttEarliestStartLatestEndVisualizer
  : T extends EGanttInstance.GANTT_BLOCK_LOADING
  ? GanttShiftLoading
  : T extends EGanttInstance.GANTT_GRID_LABELS
  ? GanttGridLabels
  : T extends EGanttInstance.GANTT_ROW_LIMITER_MARKER
  ? GanttRowLimiterMarker
  : T extends EGanttInstance.ROW_RASTER_COLOR_EXECUTER
  ? RowRasterColorExecuter
  : T extends EGanttInstance.ROW_COLOR_HIERARCHIE
  ? RowColorHierarchieExecuter
  : T extends EGanttInstance.BROKEN_CONSTRAINTS_NAVIGATOR
  ? GanttBrokenConstraintsNavigator
  : never;

/**
 * Service that creates instances of classes for gantt diagram.
 * Replaces SaxMsBestGanttJSFactory.
 */
@Injectable({
  providedIn: 'root',
})
export class GanttInstanceService {
  /**
   * Creates an instance of a requested class or returns a reference to a static class
   * @param instanceType Class name of target instance.
   * @param args Additional args which will be propagated into constructor.
   */
  public getInstance<T extends EGanttInstance>(instanceType: T, ...args: any): EnumToInstanceMap<T> {
    switch (instanceType) {
      // static classes
      case EGanttInstance.DATA_MANIPULATOR:
        return DataManipulator as EnumToInstanceMap<T>;
      case EGanttInstance.GANTT_UTILITIES:
        return GanttUtilities as EnumToInstanceMap<T>;
      case EGanttInstance.SHIFT_DATA_FINDER:
        return ShiftDataFinder as EnumToInstanceMap<T>;
      case EGanttInstance.SHIFT_DATA_SORTING:
        return ShiftDataSorting as EnumToInstanceMap<T>;
      case EGanttInstance.Y_AXIS_DATA_FINDER:
        return YAxisDataFinder as EnumToInstanceMap<T>;
      // nonstatic classes
      case EGanttInstance.COMPARE_GANTTS:
        return new CompareGantts() as EnumToInstanceMap<T>;
      case EGanttInstance.FILTER_SHIFTS_BY_ATTRIBUTE:
        return new FilterShiftsByAttribute() as EnumToInstanceMap<T>;
      case EGanttInstance.CURRENT_TIME_POINT_MARKER:
        return new GanttCurrentTimePointMarker(...args) as EnumToInstanceMap<T>;
      case EGanttInstance.GANTT_OVERLAY:
        return new GanttOverlay() as EnumToInstanceMap<T>;
      case EGanttInstance.PRINTER:
        return new GanttPrinterExecuter() as EnumToInstanceMap<T>;
      case EGanttInstance.PRINT_VIEW_STRATEGY_EFW:
        return new GanttPrintViewStrategieEFW() as EnumToInstanceMap<T>;
      case EGanttInstance.PRINT_VIEW_STRATEGY_LW:
        return new GanttPrintViewStrategyLW() as EnumToInstanceMap<T>;
      case EGanttInstance.ROW_SEARCH:
        return new GanttRowSearchExecuter() as EnumToInstanceMap<T>;
      case EGanttInstance.SHIFT_COMPONENTS_GROUP:
        return new GanttShiftComponentGroup() as EnumToInstanceMap<T>;
      case EGanttInstance.SHIFT_COMPONENTS:
        return new GanttShiftComponents(...args) as EnumToInstanceMap<T>;
      case EGanttInstance.SHIFT_DRAG_LIMITER:
        return new GanttShiftDragLimiter() as EnumToInstanceMap<T>;
      case EGanttInstance.SHIFT_MOVER:
        return new GanttShiftMover() as EnumToInstanceMap<T>;
      case EGanttInstance.SHOW_SHIFT_TIME_STAMP:
        return new GanttShowShiftTimeStamps() as EnumToInstanceMap<T>;
      case EGanttInstance.SHIFT_COLOR_BY_ATTRIBUTE:
        return new GanttShiftColorByAttribute() as EnumToInstanceMap<T>;
      case EGanttInstance.SHIFT_COLOR_BY_ATTRIBUTE_PARAMS:
        return new GanttShiftColorByAttributeParams(
          args[0],
          args[1],
          args[2],
          args[3],
          args[4]
        ) as EnumToInstanceMap<T>;
      case EGanttInstance.SPLIT_OVERLAPPING_SHIFTS:
        return new GanttSplitOverlappingShifts() as EnumToInstanceMap<T>;
      case EGanttInstance.SPLIT_OVERLAPPING_SHIFTS_DEFAULT_STRATEGY:
        return new GanttSplitOverlappingShiftsDefaultStrategy() as EnumToInstanceMap<T>;
      case EGanttInstance.SPLIT_OVERLAPPING_SHIFTS_PRIORITIZED_STRATEGY:
        return new GanttSplitOverlappingShiftsPrioritizedStrategy() as EnumToInstanceMap<T>;
      case EGanttInstance.SPLIT_OVERLAPPING_SHIFTS_BY_ATTRIBUTE_STRATEGY:
        return new GanttSplitOverlappingShiftsByAttributeStrategy() as EnumToInstanceMap<T>;
      case EGanttInstance.TEXTURIZER:
        return new GanttTexturizer() as EnumToInstanceMap<T>;
      case EGanttInstance.TIME_PERIOD_GROUP:
        return new GanttTimePeriodGroupExecuter() as EnumToInstanceMap<T>;
      case EGanttInstance.TIME_POINT:
        return new GanttTimePointExecuter(...args) as EnumToInstanceMap<T>;
      case EGanttInstance.EDGE_HANDLER:
        return new GanttEdgeHandler() as EnumToInstanceMap<T>;
      case EGanttInstance.X_AXIS_FORMAT_GENERAL:
        return new GanttXAxisFormatGeneral(
          args[0],
          args[1] ? args[1] : null,
          args[2] ? args[2] : null
        ) as EnumToInstanceMap<T>;
      case EGanttInstance.X_AXIS_ZOOM_TRANSFORMATOR:
        return new GanttXAxisZoomTransformator() as EnumToInstanceMap<T>;
      case EGanttInstance.Y_AXIS_TABLE_BUILDER:
        return new GanttYAxisTableBuilder() as EnumToInstanceMap<T>;
      case EGanttInstance.INDEX_CARD:
        return new IndexCardExecuter() as EnumToInstanceMap<T>;
      case EGanttInstance.SETTING_BUBBLES:
        return new SettingBubblesExecuter() as EnumToInstanceMap<T>;
      case EGanttInstance.SHIFT_SPLIT_BY_CLICK:
        return new ShiftSplitByClick(args[0], args[1]) as EnumToInstanceMap<T>;
      case EGanttInstance.TEXT_OVERLAY_DEFAULT:
        return new TextOverlayDefault() as EnumToInstanceMap<T>;
      case EGanttInstance.TEXT_OVERLAY_INSIDE:
        return new TextOverlayInside() as EnumToInstanceMap<T>;
      case EGanttInstance.TEXT_OVERLAY_NO_TEXT:
        return new TextOverlayNoText() as EnumToInstanceMap<T>;
      case EGanttInstance.TEXT_OVERLAY_OUTSIDE:
        return new TextOverlayOutside() as EnumToInstanceMap<T>;
      case EGanttInstance.TEXT_OVERLAY_OUTSIDE_SPLIT:
        return new TextOverlayOutsideSplit() as EnumToInstanceMap<T>;
      case EGanttInstance.SHIFT_CREATOR:
        return new GanttShiftCreator() as EnumToInstanceMap<T>;
      case EGanttInstance.SHIFT_DRAG_VISUALIZER:
        return new GanttShiftDragVisualizer(args[0], args[1]) as EnumToInstanceMap<T>;
      case EGanttInstance.FILTER_SHIFTS_BY_CONDITION:
        return new FilterShiftsByCondition() as EnumToInstanceMap<T>;
      case EGanttInstance.STICKY_BLOCKS:
        return new GanttStickyBlocks() as EnumToInstanceMap<T>;
      case EGanttInstance.ROW_COLORIZER:
        return new GanttRowColorizer() as EnumToInstanceMap<T>;
      case EGanttInstance.GANTT_BLOCK_HIGHLIGHTER:
        return new GanttBlockHighlighter() as EnumToInstanceMap<T>;
      case EGanttInstance.GANTT_EARLIEST_START_LATEST_END_VISUALIZER:
        return new GanttEarliestStartLatestEndVisualizer() as EnumToInstanceMap<T>;
      case EGanttInstance.GANTT_BLOCK_LOADING:
        return new GanttShiftLoading() as EnumToInstanceMap<T>;
      case EGanttInstance.GANTT_GRID_LABELS:
        return new GanttGridLabels() as EnumToInstanceMap<T>;
      case EGanttInstance.GANTT_ROW_LIMITER_MARKER:
        return new GanttRowLimiterMarker() as EnumToInstanceMap<T>;
      case EGanttInstance.ROW_RASTER_COLOR_EXECUTER:
        return new RowRasterColorExecuter() as EnumToInstanceMap<T>;
      case EGanttInstance.ROW_COLOR_HIERARCHIE:
        return new RowColorHierarchieExecuter() as EnumToInstanceMap<T>;
      case EGanttInstance.BROKEN_CONSTRAINTS_NAVIGATOR:
        return new GanttBrokenConstraintsNavigator() as EnumToInstanceMap<T>;
    }
  }
}
