import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { TranslateModule } from '@ngx-translate/core';
import { ErrorMenuComponent } from './error-menu.component';

@NgModule({
  imports: [CommonModule, MatIconModule, MatMenuModule, TranslateModule, MatFormFieldModule],
  declarations: [ErrorMenuComponent],
  exports: [ErrorMenuComponent],
})
export class ErrorMenuModule {}
