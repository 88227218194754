import { MatIconModule } from '@angular/material/icon';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DateSelectorElementComponent } from './date-selector-element.component';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, MatButtonToggleModule, MatIconModule, MatButtonModule, TranslateModule],
  exports: [DateSelectorElementComponent],
  declarations: [DateSelectorElementComponent],
})
export class DateSelectorElementModule {}
