import { takeUntil } from 'rxjs';
import { BestGantt } from '../../main';
import { GanttShiftScrollEventsHandler } from '../shift-scroll-events/shift-scroll-events-handler';

/**
 * Callback registrations on shifts during scroll events.
 */
export class MainShiftScrollEventCallbacks {
  private _callbackHandler: GanttShiftScrollEventsHandler;

  constructor(private _ganttDiagram: BestGantt) {
    this._callbackHandler = new GanttShiftScrollEventsHandler(this._ganttDiagram);
  }

  /**
   * Adds shift scroll event callbacks.
   */
  public initCallbacks(): void {
    this._ganttDiagram
      .getVerticalScrollHandler()
      .onScrollVerticalStart.pipe(takeUntil(this._ganttDiagram.onDestroy))
      .subscribe(() => this._ganttDiagram.removeShiftAreaSVGElements());
    this._ganttDiagram
      .getVerticalScrollHandler()
      .onScrollVerticalUpdate.pipe(takeUntil(this._ganttDiagram.onDestroy))
      .subscribe(() => this._ganttDiagram.rerenderYAxisByScroll());
    this._ganttDiagram
      .getVerticalScrollHandler()
      .onScrollVerticalEnd.pipe(takeUntil(this._ganttDiagram.onDestroy))
      .subscribe(() => this._ganttDiagram.rerenderShiftsVertical());
    this._ganttDiagram
      .getVerticalScrollHandler()
      .onScrollVerticalEnd.pipe(takeUntil(this._ganttDiagram.onDestroy))
      .subscribe(() => this._ganttDiagram.rerenderYAxisByScroll());
    this._ganttDiagram
      .getVerticalScrollHandler()
      .onScrollVerticalUpdate.pipe(takeUntil(this._ganttDiagram.onDestroy))
      .subscribe(() => this._callbackHandler.animateShiftCanvasByScroll());
    this._ganttDiagram
      .getVerticalScrollHandler()
      .onScrollVerticalStart.pipe(takeUntil(this._ganttDiagram.onDestroy))
      .subscribe(() => this._callbackHandler.handleBehaviorOnScrollStart());
    this._ganttDiagram
      .getVerticalScrollHandler()
      .onScrollVerticalEnd.pipe(takeUntil(this._ganttDiagram.onDestroy))
      .subscribe((event) => this._ganttDiagram.getShiftFacade().getCanvasAnimator(event.source).removeCanvasContent());
    this._ganttDiagram
      .getVerticalScrollHandler()
      .onScrollVerticalEnd.pipe(takeUntil(this._ganttDiagram.onDestroy))
      .subscribe((event) => this._callbackHandler.storeCurrentTopRow(event.source));
  }
}
