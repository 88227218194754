export class Routing {
  private routes: string[];
  private targetContent: boolean;

  public getRoutes(): string[] {
    return this.routes || [];
  }

  public setRoutes(routes: string[]): this {
    this.routes = routes;
    return this;
  }

  public isTargetContent(): boolean {
    return this.targetContent;
  }

  public setTargetContent(targetContent: boolean): this {
    this.targetContent = targetContent;
    return this;
  }
}
