import { BestGanttPlugIn } from '../gantt-plug-in';
import { EGanttTimePointAnimationType, GanttTimePointExecuter } from './executer-timepoint-marker';

/**
 * Marks current timepoint inside gantt by drawing a line. Supports animation.
 * @keywords plugin, executer, time, point, marker, date, line, vertical
 * @plugin current-timepoint-marker
 * @class
 * @constructor
 * @extends BestGanttPlugIn
 *
 * @param {boolean} [renderOn = true] Activates/Deactivates Timepoint marking. Defaults to true.
 * @param {string} [color = "#000000"] Color of timepoint marking lines. Defaults black.
 * @param {boolean} [animation = false] Wether or not the timePoint is animated or not.
 * @param {Date} [startTime = Date.now()] Start time / Current time. Defaults to local / device time if undefined.
 *
 * @requires BestGanttPlugIn
 * @requires GanttTimePointExecuter
 */
export class GanttCurrentTimePointMarker extends BestGanttPlugIn {
  renderOn: boolean;
  color: string;
  startTime: Date;
  animation: boolean;
  animationType: EGanttTimePointAnimationType;
  initTimeSeconds: Date;
  timePointMarker: GanttTimePointExecuter;

  constructor(renderOn = true, color = '#ff0000', animation = false, startTime = new Date(Date.now())) {
    super(); //call super-constructor

    /**
     * @type {BestGantt}
     */
    this.ganttDiagram = null;

    this.renderOn = renderOn;
    this.color = color;
    this.startTime = startTime;
    this.animation = animation;
    this.animationType = EGanttTimePointAnimationType.CLOCK;
    this.initTimeSeconds;
  }

  /**
   * @override
   */
  initPlugIn(ganttDiagram) {
    const s = this;
    s.ganttDiagram = ganttDiagram;
    this.startTime = this.roundDateToSecond(new Date(this.startTime));
    this.initTimeSeconds = this.roundDateToSecond(new Date(Date.now()));
    s.timePointMarker = new GanttTimePointExecuter(s.renderOn, s.color);
    s.timePointMarker.initPlugIn(ganttDiagram);
    s.timePointMarker.toggleAnimation(s.animation);
    s.timePointMarker.setAnimationType(s.animationType);
    s.timePointMarker.renderToolTips(false);

    s.timePointMarker.addTimePoint('CurrentTime', s.startTime);
    s.timePointMarker.build();
    s.keepMarkerUpdated();
  }

  /**
   * Called from dashboard in runOutsideAngular to fix angular performance hit if we update this.
   */
  keepMarkerUpdated() {
    const s = this;

    s.timePointMarker.removeTimePoint('CurrentTime');
    s.timePointMarker.addTimePoint(
      'CurrentTime',
      s.roundDateToSecond(
        new Date(
          new Date(
            s.startTime.getTime() + (s.roundDateToSecond(new Date(Date.now())).getTime() - s.initTimeSeconds.getTime())
          )
        )
      ),
      'Current Time',
      this.color
    );
    s.timePointMarker.build();
  }

  /**
   * @override
   */
  update() {
    const s = this;
    s.timePointMarker.update();
  }

  /**
   * @override
   */
  removePlugIn() {
    const s = this;
    s.timePointMarker.removePlugIn();
  }

  roundDateToSecond(date) {
    const s = this;
    date = new Date(date);
    return new Date(Math.round(date.getTime() / 1000) * 1000);
  }

  /**
   * Sets the animation Type for TimePoint Animations.
   * @param {"CLOCK"|"CONTINUOUS"} [animationType="CLOCK"] Animation Type, invalid values default to clock.
   */
  setAnimationType(type = EGanttTimePointAnimationType.CLOCK) {
    const s = this;
    const animationTypes = Object.values(EGanttTimePointAnimationType);
    if (!animationTypes.includes(type)) type = EGanttTimePointAnimationType.CLOCK;
    s.animationType = type;
    s.timePointMarker.setAnimationType(s.animationType);
  }

  /**
   * Enable / disable the animation
   * These animations use a lot of resources due to the constant change
   * @param {boolean} boolean
   */
  toggleAnimation(boolean) {
    const s = this;
    s.animation = boolean;
    s.timePointMarker.toggleAnimation(s.animation);
  }

  /**
   * changes the marker color
   * @param {string} color css Color Value
   */
  changeMarkerColor(color) {
    const s = this;
    s.color = color;
  }

  setRender(boolean) {
    const s = this;
    s.renderOn = boolean;
    s.timePointMarker.setRender(s.renderOn);
  }
}
