import { Template } from 'frontend/src/dashboard/model/resource/template';
import { ETemplateType } from 'frontend/src/dashboard/model/resource/template-type';
import { Content } from '../content/content';

export class IndexPage extends Template {
  private index: number;
  private content: Content;
  private icon: string;

  constructor() {
    super();
    this.setType(ETemplateType.INDEX_PAGE);
  }

  public getContent(): Content {
    return this.content;
  }

  public setContent(content: Content): this {
    this.content = content;
    return this;
  }

  public getIndex(): number {
    return this.index;
  }

  public setIndex(index: number): this {
    this.index = index;
    return this;
  }

  public getIcon(): string {
    return this.icon;
  }

  public setIcon(icon: string): this {
    this.icon = icon;
    return this;
  }
}
