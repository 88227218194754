import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Observable, Observer, Subject } from 'rxjs';
import { SharedToolbarService } from '../../../view/navbar/toolbar/shared-toolbar.service';
import { ToolbarAction } from '../../../view/navbar/toolbar/toolbar-action.enum';
import { UiCreateDialog } from '../dialogs/create-dialog/ui.create.dialog';
import { UiDialog } from '../dialogs/ui-dialog/ui.dialog';

@Injectable()
export class SharedUiService {
  public activeElement: any;
  public currentItem: any;
  public currentHierarchicalMenuItem: any;
  public forwardStack: Array<any> = [];
  public pathStack: Array<any> = ['zero'];
  public pathStackBlocked = false;
  public pathLastItemWasMenuItem = true;
  public entryElements = {};
  public localConditions = {};

  public resourceIds = {};

  _onWidgetChange: Observable<any>;
  public _onWidgetsChangeSub: Subject<void>;

  _onSave: Observable<any>;
  public _onSaveSub: Subject<any>;

  _onSaveLightBox: Observable<any>;
  public _onSaveLightBoxSub: Subject<any>;

  _onChangeName: Observable<any>;
  public _onChangeNameSub: Subject<any>;

  _onContentElementsChange: Observable<any>;
  public _onContentElementsChangeSub: Subject<any>;

  _onMenuItemClicked: Observable<any>;
  public _onMenuItemClickedSub: Subject<any>;

  _onBdeElementClicked: Observable<any>;
  public _onBdeElementClickedSub: Subject<any>;

  _onPrevious: Observable<any>;
  public _onPreviousSub: Subject<any>;

  _onNext: Observable<any>;
  public _onNextSub: Subject<any>;

  public onAction: Observable<any>;
  onActionSub: Subject<any>;

  ngOnDestroy(): void {
    this.onActionSub.complete();
    this._onNextSub.complete();
    this._onPreviousSub.complete();
    this._onBdeElementClickedSub.complete();
    this._onMenuItemClickedSub.complete();
    this._onContentElementsChangeSub.complete();
    this._onChangeNameSub.complete();
    this._onSaveLightBoxSub.complete();
    this._onSaveSub.complete();
    this._onWidgetsChangeSub.complete();
  }

  constructor(public dialog: MatDialog, private sharedToolbar: SharedToolbarService) {
    this.onActionSub = new Subject<any>();
    this.onAction = this.onActionSub.asObservable();

    this._onWidgetsChangeSub = new Subject<any>();
    this._onWidgetChange = this._onWidgetsChangeSub.asObservable();

    this._onNextSub = new Subject<any>();
    this._onNext = this._onNextSub.asObservable();

    this._onPreviousSub = new Subject<any>();
    this._onPrevious = this._onPreviousSub.asObservable();

    this._onBdeElementClickedSub = new Subject<any>();
    this._onBdeElementClicked = this._onBdeElementClickedSub.asObservable();

    this._onChangeNameSub = new Subject<any>();
    this._onChangeName = this._onChangeNameSub.asObservable();

    this._onMenuItemClickedSub = new Subject<any>();
    this._onMenuItemClicked = this._onMenuItemClickedSub.asObservable();

    this._onSaveSub = new Subject<any>();
    this._onSave = this._onSaveSub.asObservable();

    this._onSaveLightBoxSub = new Subject<any>();
    this._onSaveLightBox = this._onSaveLightBoxSub.asObservable();

    this._onContentElementsChangeSub = new Subject<any>();
    this._onContentElementsChange = this._onContentElementsChangeSub.asObservable();
  }

  public menuItemClicked(menuItem): void {
    this._onMenuItemClickedSub.next(menuItem);
  }

  public onBdeElementClicked(element: any): void {
    this._onBdeElementClickedSub.next(element);
  }

  public changeWidgets(): void {
    this._onWidgetsChangeSub.next();
  }

  public previous(): void {
    const el = this.pathStack.pop();
    this._onPreviousSub.next({
      goal: this.pathStack[this.pathStack.length - 1],
      cur: el,
    });

    if (el) {
      this.forwardStack.push(el);
    }
  }

  public next(): void {
    const el = this.forwardStack.pop();
    this._onNextSub.next({
      goal: el,
    });

    if (el) {
      this.pathStack.push(el);
    }
  }

  public changeName(changes: any, uuid: string): void {
    this._onChangeNameSub.next({ changes: changes, uuid: uuid });
  }

  public contentElements: Array<any> = [];

  /**
   * adds content element to array of selected
   * @param contentElement content element
   */
  public addContentElementForSave(contentElement: any): void {
    const index = this.contentElements.indexOf(contentElement.id);
    if (index == -1) {
      this.contentElements.push(contentElement.id);
    }

    this._onContentElementsChangeSub.next(this.contentElements);
  }

  /**
   * clears array of selected
   */
  public clearContentElementsForSave(): void {
    this.contentElements = [];
    this._onContentElementsChangeSub.next(this.contentElements);
  }

  /**
   * removes content element from array of selected
   * @param contentElement content element
   */
  public removeContentElementForSave(contentElement: any): void {
    if (this.contentElements.length === 0) {
      this._onContentElementsChangeSub.next(this.contentElements);
      return;
    }

    const index = this.contentElements.indexOf(contentElement.id);
    if (index != -1) {
      this.contentElements.splice(index, 1);
    }

    if (this.contentElements.length === 0) {
      this.sharedToolbar.trigger({ type: ToolbarAction.FINISH_SAVE, data: this.contentElements });
    }

    this._onContentElementsChangeSub.next(this.contentElements);
  }

  public getElements(): any {
    return this.contentElements;
  }

  public createElement(element: any, urls: any, dialog?: any): Observable<any> {
    return new Observable<any>((observer: Observer<any>) => {
      if (!dialog) {
        dialog = UiCreateDialog;
      }

      const dialogRef: MatDialogRef<any> = this.dialog.open(dialog, {
        data: element,
      });

      dialogRef.beforeClosed().subscribe((result) => {
        if (result) {
          observer.next(result);
        }
        observer.complete();
      });
    });
  }

  public saveLightbox(): void {
    this._onSaveLightBoxSub.next(this.contentElements);
  }

  public openDialog(element: any, options?: any): Promise<any> {
    return new Promise((resolve) => {
      const data = options
        ? options
        : {
            discard: false,
          };

      const dialogRef = this.dialog.open(UiDialog, {
        data: data,
      });

      dialogRef.afterClosed().subscribe((result) => {
        switch (result) {
          case 'all':
            this._onSaveSub.next(this.contentElements);
            break;

          case 'selected':
            this._onSaveSub.next([element.id]);
            break;
        }
        resolve(result);
      });
    });
  }

  public trigger(action) {
    this.onActionSub.next(action);
  }

  /**
   * save contentelement
   */
  public save(element): void {
    this.openDialog(element);
  }

  public saveAllWithoutAsking(): void {
    this._onSaveSub.next(this.contentElements);
  }
}
