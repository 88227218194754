import { Injectable, Type } from '@angular/core';
import { Action } from '@app-modeleditor/components/button/action/action';
import { EntryElementFactory } from '@app-modeleditor/components/entry-collection/entry-factory.service';
import { ContentElementAdapter } from '@app-modeleditor/components/structure/content-element-adapter.service';
import { TemplateAdapter } from '@app-modeleditor/utils/template-factory.service';
import { Adapter } from '@core/adapter';
import { ResourceAdapter } from 'frontend/src/dashboard/model/resource/resource-adapter.service';
import { ActionAdapter } from '../../button/action-adapter.service';
import { Table } from './table';
import { TableHeader } from './table-header';
import { SaxMsSpreadsheetRow } from './table-row';
import { TableValue } from './table-values';

@Injectable({
  providedIn: 'root',
})
export class TableAdapter implements Adapter<Table> {
  private templateAdapter: TemplateAdapter;
  constructor(
    private contentElementAdapter: ContentElementAdapter,
    private actionFactory: ActionAdapter,
    private entryElementFactory: EntryElementFactory,
    private resourceAdapter: ResourceAdapter
  ) {}

  adapt(item: Record<string, any>): Table {
    throw new Error('Method not implemented.');
  }

  applyValues<T extends Table>(scope: TemplateAdapter, item: T, values: Record<string, any>): T {
    throw new Error('Method not implemented.');
  }

  public inherit<T extends Table>(type: Type<T>, data: Record<string, any>): T {
    const t: T = this.getDetails(this.contentElementAdapter.inherit<T>(type, data), data);
    return t;
  }

  public inheritFrom<T extends Table>(scope: TemplateAdapter, type: Type<T>, data: Record<string, any>): T {
    this.templateAdapter = scope;
    const t: T = this.getDetails(this.contentElementAdapter.inheritFrom<T>(scope, type, data), data);
    return t;
  }

  private getDetails<T extends Table>(t: T, data: Record<string, any>): T {
    t.setAddNewRows(data.addNewRows)
      .setAddTableRowType(data.addTableRowType)
      .setAdditionalSubMenus(data.additionalSubMenus)
      .setAdditionalSubMenuGroups(data.additionalSubMenuGroups)
      .setApplyUserTimeFilter(data.applyUserTimeFilter)
      .setAutoScrollableIndex(data.autoScrollableIndex)
      .setBatchTable(data.batchTable)
      .setDisableDefaultTableMenuButtons(data.disableDefaultTableMenuButtons)
      .setAllwaysEnableDefaultTableMenuButtons(data.allwaysEnableDefaultTableMenuButtons)
      .setCellEdit(data.cellEdit)
      .setEnableTenantSelection(data.enableTenantSelection)
      .setCreateInlineRow(data.createInlineRow)
      .setCreateViewTable(data.createViewTable)
      .setDefaultColumns(data.defaultColumns)
      .setDefaultColumns(data.defaultColumns)
      .setDefaultNumberColumns(data.defaultNumberColumns)
      .setDefaultPageSize(data.defaultPageSize)
      .setDisabledSubMenues(data.disabledSubMenues)
      .setDynamicTableHeaders(data.dynamicTableHeaders)
      .setHiddenHeaders(data.hiddenHeaders)
      .setHierarchical(data.hierarchical)
      .setNameRequired(data.nameRequired)
      .setNameUnique(data.nameUnique)
      .setHeadline(data.headline)
      .setNumberFixedColumns(data.numberFixedColumns)
      .setNumberFixedRowsBottom(data.numberFixedRowsBottom)
      .setNumberFixedRowsTop(data.numberFixedRowsTop)
      .setScrollableTable(data.scrollableTable)
      .setSearchable(data.searchable)
      .setSelectable(data.selectable)
      .setSettings(data.settings)
      .setEnableAsyncUpdate(data.enableAsyncUpdate)
      .setEnableSingleClickEdit(data.enableSingleClickEdit)
      .setTableColumnCount(data.tableColumnCount)
      .setTableHeaders((data.tableHeaders || []).map((h) => this.parseTableHeader<TableHeader>(TableHeader, h)))
      .setValuesRestUrl(data.valuesRestUrl)
      .setCreateNewEntryRestUrls(data.createNewEntryRestUrls)
      .setUpdateRowsRestUrl(data.updateRowsRestUrl)
      .setCustomOpenAction(this.actionFactory.parseAction(Action, data.customOpenAction))
      .setDefaultSorting(data.defaultSorting)
      .setEditEntryTemplateRestUrlTemplates(data.editEntryTemplateRestUrlTemplates)
      .setMenuMode(data.menuMode)
      .setShowNameDialog(data.showNameDialog)
      .setViewObjectName(data.viewObjectName)
      .setViewTableName(data.viewTableName)
      .setListensToTime(data.listensToTime)
      .setHideHeader(data.hideHeader)
      .setColorLegendMap(data.colorLegendMap)
      .setColumnReorder(data.columnReorder)
      .setValues(this.parseValues(TableValue, data.values || data.value))
      .setFontSizeAutoScale(data.fontSizeAutoScale)
      .setAltNegativeRounding(data.altNegativeRounding)
      .setSyncVerticalScrolling(data.syncVerticalScrolling)
      .setSyncHorizontalScrolling(data.syncHorizontalScrolling)
      .setMaxTableRows(data.maxTableRows)
      .setRefreshAfterSave(data.refreshAfterSave);

    if (t.getContextmenu()) {
      t.getContextmenu().setDefaultBehaviour(false);
    }

    return t;
  }

  public parseValues<T extends TableValue>(type: Type<T>, value: Record<string, any>): any {
    if (!value) {
      return null;
    }
    const t: T = this.resourceAdapter
      .inherit<T>(type, value)
      .setRowCount(value.rowCount)
      .setScrolledIndex(value.scrolledIndex)
      .setTableRows((value.tableRows || []).map((r) => this.parseTableRow(SaxMsSpreadsheetRow, r)))
      .setMessageBars(value.messageBars);

    return value;
  }

  private parseTableRow<T extends SaxMsSpreadsheetRow>(type: Type<T>, data: Record<string, any>): SaxMsSpreadsheetRow {
    const row: SaxMsSpreadsheetRow = this.resourceAdapter.inherit<T>(type, data);
    if (typeof data.spreadsheetRowEntries === 'object') {
      row.setSpreadsheetRowEntries(data.spreadsheetRowEntries);
    }

    return row;
  }

  public parseTableHeader<T extends TableHeader>(type: Type<T>, data: Record<string, any>): T {
    const t: T = this.entryElementFactory
      .inherit<T>(type, data)
      .setColumnIndex(data.columnIndex)
      .setColumnName(data.columnName)
      .setAutoSave(data.autoSave)
      .setFilter(data.filter)
      .setNestedTableHeader(data.nestedTableHeader)
      .setValidationRegex(data.validationRegex)
      .setValidationRegexInfoText(data.validationRegexInfoText)
      .setWindUpOperation(data.windUpOperation)
      .setPreferredWidth(data.preferredWidth)
      .setGeneratedAutomatically(data.generatedAutomatically)
      .setRequireFullAssignment(data.requireFullAssignment)
      .setSearchable(data.searchable)
      .setSortable(data.sortable)
      .setValue(data.value)
      .setSortType(data.sortType)
      .setThousandsSeparator(data.thousandsSeparator)
      .setTimeValue(data.timeValue)
      .setEntryElement(this.templateAdapter.adapt(data.displayElement))
      .setAvailableValues(data.availableValues)
      .setTableHeaders((data.tableHeaders || []).map((h) => this.parseTableHeader<TableHeader>(TableHeader, h)))
      .setGeneralInfoText(data.generalInfoText)
      .setDateTimeFormat(data.dateTimeFormat);

    if (typeof data.nullable === 'boolean') {
      t.setNullable(data.nullable);
    }

    return t;
  }
}
