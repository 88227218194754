import { take, takeUntil } from 'rxjs';
import { EGanttScrollContainer } from '../../html-structure/scroll-container.enum';
import { BestGantt } from '../../main';

/**
 * Handles a clip path to clip the overflow of rendered svg shifts.
 * The clip path will be rendered in the given `defs` element and can be used for the specified scroll container.
 */
export class GanttShiftClipPathHandler {
  /**
   * @param _ganttDiagram {@link BestGantt} reference which provides all neccesary data for the clip path.
   * @param _defs D3 selection of the `defs` element in which the clip path should be rendered.
   * @param _scrollContainerId Id of the scroll container the clip path should be created for.
   */
  constructor(
    private _ganttDiagram: BestGantt,
    private _defs: d3.Selection<SVGDefsElement, undefined, d3.BaseType, undefined>,
    private readonly _scrollContainerId: EGanttScrollContainer
  ) {
    this._init();
  }

  /**
   * Initializes the clip path handling.
   */
  private _init() {
    const clipPath = this._defs.append('clipPath').attr('id', this.clipPathId);

    const clipRect = clipPath.append('rect');
    this._updateClipRect(clipRect);

    this._ganttDiagram
      .getNodeProportionsState()
      .select(
        this._scrollContainerId === EGanttScrollContainer.STICKY_ROWS
          ? 'shiftViewPortProportionsSticky'
          : 'shiftViewPortProportions'
      )
      .pipe(takeUntil(this._ganttDiagram.onDestroy))
      .subscribe(() => this._updateClipRect(clipRect));

    if (this._scrollContainerId === EGanttScrollContainer.DEFAULT) {
      this._ganttDiagram
        .getNodeProportionsState()
        .select('shiftViewPortProportionsSticky')
        .pipe(takeUntil(this._ganttDiagram.onDestroy))
        .subscribe(() => this._updateClipRect(clipRect));
    }

    this._ganttDiagram.onDestroy.pipe(take(1)).subscribe(() => {
      clipRect.remove();
      clipPath.remove();
    });
  }

  /**
   * Updates the given clip `rect` by the given clip `rect` proportions.
   * @param clipRect D3 selection of the clip `rect` to be updated.
   */
  private _updateClipRect(clipRect: d3.Selection<SVGRectElement, undefined, d3.BaseType, undefined>): void {
    clipRect
      .attr('x', this._clipRectX)
      .attr('y', this._clipRectY)
      .attr('width', this._clipRectWidth)
      .attr('height', this._clipRectHeight);
  }

  //
  // CLIP RECT CALCULATION
  //

  private get _clipRectX(): number {
    return 0;
  }

  private get _clipRectY(): number {
    let y = 0;

    if (this._scrollContainerId === EGanttScrollContainer.DEFAULT) {
      const shiftViewPortProportions = this._ganttDiagram
        .getNodeProportionsState()
        .getShiftViewPortProportions(EGanttScrollContainer.STICKY_ROWS);
      if (shiftViewPortProportions.height > 0) {
        y = shiftViewPortProportions.height + 1;
      }
    }

    return y;
  }

  private get _clipRectWidth(): number {
    const shiftViewPortProportions = this._ganttDiagram
      .getNodeProportionsState()
      .getShiftViewPortProportions(this._scrollContainerId);
    const width = shiftViewPortProportions.width;

    return width;
  }

  private get _clipRectHeight(): number {
    const shiftViewPortProportions = this._ganttDiagram
      .getNodeProportionsState()
      .getShiftViewPortProportions(this._scrollContainerId);
    let height = shiftViewPortProportions.height;

    if (this._scrollContainerId === EGanttScrollContainer.DEFAULT) {
      const shiftViewPortProportions = this._ganttDiagram
        .getNodeProportionsState()
        .getShiftViewPortProportions(EGanttScrollContainer.STICKY_ROWS);
      if (shiftViewPortProportions.height > 0) {
        height -= shiftViewPortProportions.height + 1;
      }
    }

    return height;
  }

  //
  // GETTER & SETTER
  //

  /**
   * Id of the generated clip path for use in the attribute `clip-path` of shift `rect`s.
   */
  public get clipPathId(): string {
    return `clipPath_${this._scrollContainerId}`;
  }

  /**
   * URL to the generated clip path for use in the attribute `clip-path` of shift `rect`s.
   */
  public get clipPathUrl(): string {
    return `url(#${this.clipPathId})`;
  }

  /**
   * Generates a value for the CSS property `clip-path` for an HTML element with the specified proportions.
   * @param htmlRectProportions Proportions of the HTML element to generate the `clip-path` value for.
   * @returns The generated value for the CSS property `clip-path` for an HTML element with the specified proportions.
   */
  public getHtmlClipPath(htmlRectProportions: IRectProportions): string {
    const left = 0;
    const right = htmlRectProportions.width;
    const top = Math.max(this._clipRectY - htmlRectProportions.y, 0);
    const bottom = Math.min(this._clipRectHeight + this._clipRectY - htmlRectProportions.y, htmlRectProportions.height);
    return this._getCssPolygon(left, right, top, bottom, 'px');
  }

  /**
   * Generates a rectangular CSS polygon with the specified proportions and unit.
   * @param left Left x value of the polygon.
   * @param right Right x value of the polygon.
   * @param top Upper y value of the polygon.
   * @param bottom Lower y value of the polygon.
   * @param unit Unit which should be appended to the values.
   * @returns A rectangular CSS polygon with the specified proportions and unit.
   */
  private _getCssPolygon(left: number, right: number, top: number, bottom: number, unit = ''): string {
    return `polygon(${left}${unit} ${top}${unit}, ${right}${unit} ${top}${unit}, ${right}${unit} ${bottom}${unit}, ${left}${unit} ${bottom}${unit})`;
  }
}

/**
 * Data structure defining all values {@link GanttShiftClipPathHandler} needs to render a clip path.
 */
interface IRectProportions {
  x: number;
  y: number;
  width: number;
  height: number;
}
