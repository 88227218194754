import { IndexCardConfig } from '../index-card-config';
import { IndexCardExecuterBuildData } from '../index-card-executer';
import { IndexCardTextBuilder } from '../text-creator';
import { IIndexCardTextInterface } from './text-creator-interface';

/**
 * Keeps index card text on the shift by truncating it.
 */
export class IndexCardTextInside implements IIndexCardTextInterface {
  public init(): void {
    // nothing
  }

  public build(
    dataSet: { [id: string]: string[] },
    buildData: IndexCardExecuterBuildData,
    config: IndexCardConfig,
    executer: IndexCardTextBuilder
  ): void {
    const s = executer;
    const ctx = s.indexCardExecuter.getCanvas(s.scrollContainerId).node().getContext('2d');
    const ctxFontConfig = `${config.fontSize}px Arial`;
    if (ctx.font !== ctxFontConfig) ctx.font = ctxFontConfig;
    const ellipsis = '…';
    const ellipsisWidth = s.fontSizeCalc.getTextWidth(ellipsis);
    const textContainerWidth = s.ganttDiagram
      .getNodeProportionsState()
      .getShiftViewPortProportions(s.scrollContainerId).width;

    if (!buildData.transform) {
      buildData.transform = { k: 1, x: 0, y: 0 };
    }

    for (const textParent of buildData.shifts) {
      const lines = s.getMatchingLineSet(dataSet, textParent);
      const x = textParent.x * buildData.transform.k + buildData.transform.x + config.padding_left;
      const shiftWidth = textParent.width * buildData.transform.k - config.padding_left;
      const widthToNextShift = s.getWidthToNextShift(buildData, textParent, textContainerWidth, config);
      const shiftLabelHeight = s.ganttDiagram
        .getShiftFacade()
        .getShiftBuilder(s.scrollContainerId)
        .getShiftCalculationStrategy()
        .getShiftLabelHeight(textParent);

      for (let i = 0; i < lines.length; i++) {
        const line = lines[i];
        let maxTextWidth = 0;
        const y =
          s.ganttDiagram.getRenderDataHandler().getStateStorage().getYPositionShift(textParent.id) +
          shiftLabelHeight -
          5 +
          config.padding_top +
          config.fontSize +
          config.lineHeight * i -
          s.ganttDiagram.getNodeProportionsState().getScrollTopPosition(s.scrollContainerId);
        if (widthToNextShift === undefined || widthToNextShift > shiftWidth) {
          maxTextWidth = shiftWidth;
        } else {
          maxTextWidth = widthToNextShift;
        }
        const wrappedText = s.fittingString(line.text, maxTextWidth, ellipsis, ellipsisWidth);
        if (wrappedText) {
          ctx.fillText(wrappedText, x, y);
        }
      }
    }
  }

  public cleanUp(): void {
    // nothing
  }
}
