import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatLegacyFormFieldAppearance as MatFormFieldAppearance } from '@angular/material/legacy-form-field';
import { EntryElement } from '@app-modeleditor/components/entry-collection/entry-element';
import { EntryElementValue } from '@app-modeleditor/components/entry-collection/entry-element-value';
import { GlobalUtils } from 'frontend/src/dashboard/global-utils';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

/**
 * abstract class and base of each entry-element created inside an entry-collection
 */
@Component({
  selector: 'app-default-entry-element',
  template: '',
})
export abstract class DefaultEntryElement implements OnInit, OnChanges, OnDestroy {
  protected componentId: string; // unique id for this component
  @Input() entryElement: EntryElement; // data of the entry element itself
  @Input() disabled: boolean; // whether the mask is disabled by its parent components or not
  appearance: MatFormFieldAppearance = 'legacy'; // "legacy" | "standard" | "fill" | "outline"
  formGroup: UntypedFormGroup; // form group in which the entry is held in
  value: string; // current displayed value of the mask
  private _ngAlive: Subject<void> = new Subject<void>(); // subject to determine if component still lives or not

  constructor(private _formBuilder: UntypedFormBuilder) {
    this.componentId = GlobalUtils.generateUUID();
    this.formGroup = this._formBuilder.group({
      inputControl: new UntypedFormControl({ value: '', disabled: true }),
    });

    // subscribes to value changes on control
    this.control.valueChanges.pipe(takeUntil(this._ngAlive)).subscribe((val: string) => this.onModelChanged(val));
  }

  /**
   * set value of form control
   * @param value string
   * @returns void
   */
  protected setValue(value: string): void {
    if (this.control.value !== value) {
      this.value = value;
      this.control.setValue(this.value);
    }
  }

  /**
   * abstract class which handles changes to the value of the formfield
   * @param val string
   * @returns void
   */
  abstract onModelChanged(val: string): void;

  /**
   * get form control element
   * @returns FormControl
   */
  get control(): UntypedFormControl {
    return this.formGroup.get('inputControl') as UntypedFormControl;
  }

  /**
   * whether the mask is disabled or not
   * @returns boolean
   */
  get isDisabled(): boolean {
    return this.disabled === true || this.entryElement.isDisabled() === true ? true : false;
  }

  /**
   * get text color
   * @returns string
   */
  get textColor(): string {
    return this.entryElement.getValue<EntryElementValue>().getColor();
  }

  /**
   * get hint text
   * @returns string
   */
  get hintText(): string {
    return this.entryElement.getValue<EntryElementValue>().getHint();
  }

  /**
   * get hint color
   * @returns string
   */
  get hintColor(): string {
    return this.entryElement.getValue<EntryElementValue>().getHintColor();
  }

  /**
   * angular lifecycle for init
   * @returns void
   */
  ngOnInit(): void {}

  /**
   * angular lifecycle for changes
   * @param changes SimpleChanges
   * @returns void
   */
  ngOnChanges(changes: SimpleChanges): void {}

  /**
   * angular lifecycle for destroy
   * @returns void
   */
  ngOnDestroy(): void {
    this._ngAlive.next();
    this._ngAlive.complete();
  }
}
