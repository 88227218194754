export class MDUI {
  public queue: Array<string>;
  public options: any;

  constructor(_options: any) {
    this.queue = [];
    this.options = _options;
  }

  public removeFromQueue(id: string): number {
    for (let i = 0; i < this.queue.length; i++) {
      if (this.queue[i] == id) {
        this.queue.splice(i, 1);
        return i;
      }
    }
    return -1;
  }

  public getQueue(): Array<string> {
    return this.queue;
  }

  public getOptions(): any {
    return this.options;
  }

  public fillQueue(): void {
    if (this.options.settingsEntries) {
      for (const entry of this.options.settingsEntries) {
        if (this.queue.indexOf(entry.id) == -1) {
          this.queue.push(entry.id);
        }
      }
    }

    if (this.options.subSettingsCategories) {
      for (const category of this.options.subSettingsCategories) {
        if (this.queue.indexOf(category.id) == -1) {
          this.queue.push(category.id);
        }
      }
    }

    if (this.options.settingsCategories) {
      for (const category of this.options.settingsCategories) {
        if (this.queue.indexOf(category.id) == -1) {
          this.queue.push(category.id);
        }
      }
    }
  }
  public fillQueueByIndex(from, to): void {
    if (this.options.settingsEntries) {
      for (let i = 0; i < this.options.settingsEntries.length; i++) {
        if (this.queue.indexOf(this.options.settingsEntries[i].id) == -1) {
          if (i >= from && i <= to) {
            this.queue.push(this.options.settingsEntries[i].id);
          }
        }
      }
    }

    if (this.options.subSettingsCategories) {
      for (let i = 0; i < this.options.subSettingsCategories.length; i++) {
        if (this.queue.indexOf(this.options.subSettingsCategories[i].id) == -1) {
          if (i >= from && i <= to) {
            this.queue.push(this.options.subSettingsCategories[i].id);
          }
        }
      }
    }

    if (this.options.settingsCategories) {
      for (let i = 0; i < this.options.settingsCategories.length; i++) {
        if (this.queue.indexOf(this.options.settingsCategories[i].id) == -1) {
          if (i >= from && i <= to) {
            this.queue.push(this.options.settingsCategories[i].id);
          }
        }
      }
    }
  }

  public fillOptions(event) {
    for (let i = 0; i < this.options.settingsEntries; i++) {
      if (this.options.settingsEntries[i].id == event.id) {
        this.options.settingsEntries[i] = event;
      }
    }
    for (let i = 0; i < this.options.subSettingsCategories; i++) {
      if (this.options.subSettingsCategories[i].id == event.id) {
        this.options.subSettingsCategories[i] = event;
      }
    }
    for (let i = 0; i < this.options.settingsCategories; i++) {
      if (this.options.settingsCategories[i].id == event.id) {
        this.options.settingsCategories[i] = event;
      }
    }
  }
}
