/**
 * Data structure for node network node.
 */
export class NodeNetworkNode {
  id: string;
  info: NodeNetworkNodeInfo;
  label: string;
  parentId: string;
  screen: NodeNetworkNodeScreen;
  type: string;
  x: number;
  y: number;
  additionalData: NodeNetworkAdditionalData;
}

/**
 * Info container for node network node.
 */
export class NodeNetworkNodeInfo {
  outOfProduct: boolean;
  productId: string[] = [];
}

/**
 * Screen settings for network node.
 */
export class NodeNetworkNodeScreen {
  id: string;
  selected: boolean;
  show: boolean;
}

/**
 * Additional data for network node.
 */
export class NodeNetworkAdditionalData {
  headline: string;
  resourceRequirements: ResourceRequirement[];
  responseProcess: ResponseProcess[];
  editNodeType: string;
  editProcessType: string;
  editProcessName: string;
  editHierarchicalProcessName: string;
}

/**
 * Superclass for requirements and response process for additional data.
 */
export abstract class NodeNetworkAttribute {
  type: string;
  value: string;
}

export class ResourceRequirement extends NodeNetworkAttribute {}
export class ResponseProcess extends NodeNetworkAttribute {}
