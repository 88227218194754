<div
  class="generalChartContainer"
  [style.width]="containerPaddingRight ? 'calc(100% - ' + containerPaddingRight + 'px)' : null"
>
  <!-- <canvas
    class="chart"
    #canvasElem
    baseChart
    (contextmenu)="handleRightClick($event)"
    [datasets]="chartDatasets"
    [labels]="chartLabels"
    [options]="chartDisplayOptions"
    [legend]="chartLegend"
    [colors]="chartColors"
    [chartType]="chartType"
  >
  </canvas> -->
  <canvas
    class="chart"
    #canvasElem
    baseChart
    [type]="chartType"
    (contextmenu)="handleRightClick($event)"
    [datasets]="chartDatasets"
    [labels]="chartLabels"
    [options]="chartDisplayOptions"
    [legend]="chartLegend"
  >
  </canvas>
</div>
