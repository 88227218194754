import { GanttCanvasShift } from '../../../data-handler/data-structure/data-structure';
import { BestGantt } from '../../../main';
import { GanttShiftDragLimiter } from '../../../plug-ins/shift-drag-limiter/shift-drag-limiter-executer';
import {
  ETimeGradationEnumStepSize,
  ETimeGradationTimeGrid,
  TimeGradationHandler,
} from '../../../time-gradation-handler/time-gradation-handler';

/**
 * General restriction data handler providing data and methods which are relevant for all types of shift editing.
 * @keywords shift, edit, limit, limitation, restriction, drag, no
 */
export class GanttShiftEditLimiter {
  private _ganttDiagram: BestGantt;
  private _timeGradationHandler: TimeGradationHandler;

  private _dragLimiterPlugIns: { [id: string]: GanttShiftDragLimiter } = {};

  constructor(ganttDiagram: BestGantt) {
    this._ganttDiagram = ganttDiagram;
  }

  //
  // EARLIEST START / LATEST END
  //

  /**
   * Determines whether ES or LE should be handled during the editing of the specified shift or not.
   * @param shift Shift to check the restrcitions for.
   */
  public handleEsLeForShift(shift: GanttCanvasShift): boolean {
    return this.handleEarliestStartForShift(shift) || this.handleLatestEndForShift(shift);
  }

  /**
   * Determines whether ES should be handled during the editing of the specified shift or not.
   * @param shift Shift to check the restrcitions for.
   */
  public handleEarliestStartForShift(shift: GanttCanvasShift): boolean {
    // check if ealiestStartTime/latestEndTime have to be handled
    if (shift && shift.modificationRestriction && !shift.modificationRestriction.free_movement) {
      // check for earliestStartTime
      if (shift.modificationRestriction.earliestStartTime) return true;
    }
    return false;
  }

  /**
   * Determines whether LE should be handled during the editing of the specified shift or not.
   * @param shift Shift to check the restrcitions for.
   */
  public handleLatestEndForShift(shift: GanttCanvasShift): boolean {
    // check if ealiestStartTime/latestEndTime have to be handled
    if (shift && shift.modificationRestriction && !shift.modificationRestriction.free_movement) {
      // check for latestEndTime
      if (shift.modificationRestriction.latestEndTime) return true;
    }
    return false;
  }

  /**
   * Checks the specified date if it violates earliest start restrictions and corrects it if necessary.
   * @param date Date to check.
   * @param shift Canvas shift data containing the restrictions.
   * @returns Checked and corrected date.
   */
  public getEarliestStartConformingDate(date: Date, shift: GanttCanvasShift): Date {
    if (this.handleEarliestStartForShift(shift) && date.getTime() < shift.modificationRestriction.earliestStartTime) {
      return new Date(shift.modificationRestriction.earliestStartTime);
    }
    return date;
  }

  /**
   * Checks the specified date if it violates latest end restrictions and corrects it if necessary.
   * @param date Date to check.
   * @param shift Canvas shift data containing the restrictions.
   * @returns Checked and corrected date.
   */
  public getLatestEndConformingDate(date: Date, shift: GanttCanvasShift): Date {
    if (this.handleLatestEndForShift(shift) && date.getTime() > shift.modificationRestriction.latestEndTime) {
      return new Date(shift.modificationRestriction.latestEndTime);
    }
    return date;
  }

  /**
   * Checks the specified x coordinate if it violates earliest start restrictions and corrects it if necessary.
   * @param x X coordinate to check.
   * @param shift Canvas shift data containing the restrictions.
   * @returns Checked and corrected x coordinate.
   */
  public getEarliestStartConformingXCoordinate(x: number, shift: GanttCanvasShift): number {
    if (this.handleEarliestStartForShift(shift)) {
      const xAxisBuilder = this._ganttDiagram.getXAxisBuilder();
      const currentScale = xAxisBuilder.getCurrentScale();
      const earliestStartX = currentScale(shift.modificationRestriction.earliestStartTime);
      if (x < earliestStartX) return earliestStartX;
    }
    return x;
  }

  /**
   * Checks the specified x coordinate if it violates latest end restrictions and corrects it if necessary.
   * @param x X coordinate to check.
   * @param shift Canvas shift data containing the restrictions.
   * @returns Checked and corrected x coordinate.
   */
  public getLatestEndConformingXCoordinate(x: number, shift: GanttCanvasShift): number {
    if (this.handleLatestEndForShift(shift)) {
      const xAxisBuilder = this._ganttDiagram.getXAxisBuilder();
      const currentScale = xAxisBuilder.getCurrentScale();
      const latestEndX = currentScale(shift.modificationRestriction.latestEndTime);
      if (x > latestEndX) return latestEndX;
    }
    return x;
  }

  //
  // TIME GRADATION
  //

  /**
   * Initiatlizes the time gradation handler with the current gantt data.
   * Should only be called once from {@link BestGantt}.
   */
  public initTimeGradationHandler(): void {
    this._timeGradationHandler = new TimeGradationHandler(this._ganttDiagram.getXAxisBuilder());

    const dataSet = this._ganttDiagram.getDataHandler().getOriginDataset();
    if ((!dataSet.timeGrid || dataSet.timeGrid === 'CUSTOM') && dataSet.minStepWidth) {
      // time in number gradation
      this._timeGradationHandler.setDefaultTimeGrid(ETimeGradationTimeGrid.CUSTOMIZED);
      this._timeGradationHandler.setCustomizedTimeGrid(dataSet.gridRef || 0, dataSet.minStepWidth);
    } else if (dataSet.timeGrid) {
      // enum gradation
      this._timeGradationHandler.setDefaultTimeGrid(ETimeGradationTimeGrid.CUSTOMIZED);
      const customizedStepSize = <ETimeGradationEnumStepSize>(dataSet.timeGrid + '').toUpperCase();
      this._timeGradationHandler.setCustomizedStepSize(customizedStepSize); // time grid always uppercase
    } else {
      // nothing set -> no gradation
      this._timeGradationHandler.setDefaultTimeGrid(ETimeGradationTimeGrid.NONE);
    }
  }

  //
  // DRAG LIMITER PLUG-IN REGISTRATION
  //

  /**
   * Registers the specified drag limiter plug-in under the specified id.
   * @param id Id for which the plug-in should be registered.
   * @param plugIn Plug-in to register.
   */
  public registerDragLimiterPlugIn(id: string, plugIn: GanttShiftDragLimiter): void {
    this._dragLimiterPlugIns[id] = plugIn;
  }

  /**
   * Unregisters the drag limiter plug-in with the specified id.
   * @param id Id for which the plug-in is registered.
   */
  public unregisterDragLimiterPlugIn(id: string): void {
    delete this._dragLimiterPlugIns[id];
  }

  /**
   * Returns a map of all registered drag limiter plug-ins.
   * @returns Map of all registered drag limiter plug-ins.
   */
  public getRegisteredDragLimiterPlugIns(): { [id: string]: GanttShiftDragLimiter } {
    return this._dragLimiterPlugIns;
  }

  //
  // GETTER & SETTER
  //

  /**
   * Returns the time gradation handler instance for shift editing.
   * @returns Time gradation handler instance for shift editing.
   */
  public get timeGradationHandler(): TimeGradationHandler {
    return this._timeGradationHandler;
  }
}
